define("adept-iq/pods/components/iq-widgets/routes-start-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/routes-start-form-widget/config", "moment", "adept-iq/mixins/version-check"], function (_exports, _component, _config, _moment, _versionCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_versionCheck.default, {
    classNames: ['routes-start-form-widget'],
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const currentTimestamp = (0, _moment.default)().toISOString();
        const actualStartTime = record.get('timestamp');
        const plannedEndTime = record.get('dispatchRoute.plannedEndTime');
        const tripStartTime = record.get('dispatchRoute.tripStartTime');
        const plannedStartTime = record.get('dispatchRoute.plannedStartTime');
        const routeMaxParam = this.get('store').peekRecord('cs-config-item', 'config-Route_parameters/routeMaxExtension');
        const routeMaxExtension = routeMaxParam ? parseInt(routeMaxParam.get('value'), 10) : 60; // minutes

        const routeMinTimeLimit = (0, _moment.default)(plannedStartTime).subtract(routeMaxExtension, 'minutes');
        record.set('odometer', null);

        if ((0, _moment.default)(currentTimestamp).isSameOrBefore(actualStartTime)) {
          this.get('editModal.errors').pushObject('Actual Start Time should be less than now.');
          valid = false;
        }

        if ((0, _moment.default)(plannedEndTime).isSameOrBefore(actualStartTime)) {
          this.get('editModal.errors').pushObject('Actual Start Time should be less than planned end time.');
          valid = false;
        }

        if ((0, _moment.default)(tripStartTime).isSameOrBefore(actualStartTime)) {
          this.get('editModal.errors').pushObject('Actual Start Time should be less than planned trip start time.');
          valid = false;
        }

        if ((0, _moment.default)(actualStartTime).isBefore(routeMinTimeLimit)) {
          valid = false;
          this.get('editModal.errors').pushObject('Actual Start Time cannot be in the future and cannot cause route to extend past its configured allowed length.');
        }
      });
      return valid;
    },

    actions: {
      onUndoClick() {
        this.get('editModal').undoAll();
        this.set('editModal.errors', []);
      },

      async onApplyClick() {
        const dispatchRoute = this.get('editableRecords.firstObject.dispatchRoute');
        const lockRouteAction = this.get('lockRouteAction').bind(this);
        const unlockRouteAction = this.get('unlockRouteAction').bind(this);
        this.set('dispatchRouteToCheck', dispatchRoute);

        const closeCallback = () => {
          this.send('onCloseClick');
        }; // Check dispatchRoute version first


        await this.get('versionCheckOperation').perform(closeCallback.bind(this));

        if (this.validateRecords()) {
          try {
            await lockRouteAction();
            const title = this.get('title');
            this.get('service').applyWithCallback(title, unlockRouteAction);
          } catch (error) {
            await unlockRouteAction();
            return;
          }
        }
      }

    }
  });

  _exports.default = _default;
});