define("adept-iq/tests/factories/travel-need-type", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  /* eslint-disable no-use-before-define */
  _emberDataFactoryGuy.default.define('travel-need-type', {
    polymorphic: false,
    // manually flag this model as NOT polymorphic
    sequences: {
      ids: num => travelNeedTypes[num % travelNeedTypes.length].id,
      Names: num => travelNeedTypes[num % travelNeedTypes.length].name,
      vehicleCapacityCounts: num => travelNeedTypes[num % travelNeedTypes.length].vehicleCapacityCount,
      vehicleCapacityTypes: num => travelNeedTypes[num % travelNeedTypes.length].vehicleCapacityType,
      loadTimes: num => travelNeedTypes[num % travelNeedTypes.length].loadTime,
      unloadTimes: num => travelNeedTypes[num % travelNeedTypes.length].unloadTime,
      displayNames: num => travelNeedTypes[num % travelNeedTypes.length].displayName,
      displayOrders: num => travelNeedTypes[num % travelNeedTypes.length].displayOrder,
      descriptions: num => travelNeedTypes[num % travelNeedTypes.length].description
    },
    default: {
      id: _emberDataFactoryGuy.default.generate('ids'),
      name: _emberDataFactoryGuy.default.generate('Names'),
      vehicleCapacityCount: _emberDataFactoryGuy.default.generate('vehicleCapacityCounts'),
      vehicleCapacityType: _emberDataFactoryGuy.default.generate('vehicleCapacityTypes'),
      loadTime: _emberDataFactoryGuy.default.generate('loadTimes'),
      unloadTime: _emberDataFactoryGuy.default.generate('unloadTimes'),
      displayName: _emberDataFactoryGuy.default.generate('displayNames'),
      displayOrder: _emberDataFactoryGuy.default.generate('displayOrders'),
      description: _emberDataFactoryGuy.default.generate('descriptions')
    },
    traits: {
      ambulatory: {
        name: 'ambulatory',
        vehicleCapacityType: 'ambulatory',
        vehicleCapacityCount: 1,
        loadTime: 240,
        unloadTime: 60,
        displayName: 'Ambulatory',
        displayOrder: 1,
        description: 'Description for Ambulatory'
      }
    }
  });

  const travelNeedTypes = [{
    id: 'ambulatory',
    name: 'ambulatory',
    vehicleCapacityType: 'ambulatory',
    vehicleCapacityCount: 1,
    loadTime: 240,
    unloadTime: 60,
    displayName: 'Ambulatory',
    displayOrder: 1,
    description: 'Description for Ambulatory'
  }, {
    id: 'wideAmbulatory',
    name: 'wideAmbulatory',
    vehicleCapacityType: 'wideAmbulatory',
    vehicleCapacityCount: 1,
    loadTime: 420,
    unloadTime: 240,
    displayName: 'Wide Ambulatory',
    displayOrder: 2,
    description: 'Description for Wide Ambulatory'
  }, {
    id: 'wheelchair',
    name: 'wheelchair',
    vehicleCapacityType: 'wheelchair',
    vehicleCapacityCount: 1,
    loadTime: 420,
    unloadTime: 240,
    displayName: 'Wheelchair',
    displayOrder: 3,
    description: 'Description for Wheelchair'
  }, {
    id: 'wideWheelchair',
    name: 'wideWheelchair',
    vehicleCapacityType: 'wheelchair',
    vehicleCapacityCount: 2,
    loadTime: 420,
    unloadTime: 240,
    displayName: 'Wide Wheelchair',
    displayOrder: 4,
    description: 'Description for Wide Wheelchair'
  }, {
    id: 'serviceAnimal',
    name: 'serviceAnimal',
    vehicleCapacityType: 'serviceAnimal',
    vehicleCapacityCount: 1,
    loadTime: 60,
    unloadTime: 60,
    displayName: 'Service Animal',
    displayOrder: 5,
    description: 'Description for Service Animal'
  }];
});