define("adept-iq/pods/components/form-components/addresses/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nY9LUjYA",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\"],[11,\"class\",\"cell edit\"],[11,\"style\",\"max-width: 210px;\"],[11,\"data-test-address-field\",\"\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"class\",\"options\",\"disabled\",\"placeholder\",\"renderInPlace\",\"search\",\"selected\",\"onkeydown\",\"onopen\",\"onchange\"],[\"data-test-power-select form-control\",[23,[\"addressOptions\"]],[23,[\"isDisabledAddressSelect\"]],[23,[\"placeholder\"]],[23,[\"renderInPlace\"]],[27,\"perform\",[[23,[\"searchAddress\"]]],null],[27,\"readonly\",[[23,[\"address\",\"address\",\"address\"]]],null],[27,\"action\",[[22,0,[]],\"handleKeydown\",[23,[\"address\"]],[23,[\"addressType\"]]],null],[27,\"action\",[[22,0,[]],\"onDropDownOpen\"],null],[27,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"onAddressSelected\",[23,[\"address\"]],[23,[\"addressType\"]]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[22,1,[\"address\",\"freeformAddress\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showPinButton\"]]],null,{\"statements\":[[7,\"button\"],[11,\"class\",\"cell button\"],[12,\"disabled\",[27,\"or\",[[23,[\"disabled\"]],[23,[\"disableAddress\"]]],null]],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"fa fa-map-marker\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"onPickOnMap\",[23,[\"address\"]],[23,[\"activeGeo\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/addresses/template.hbs"
    }
  });

  _exports.default = _default;
});