define("adept-iq/pods/components/form-components/vehicle-selection/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.vehiclesTableColumns = void 0;
  const vehiclesTableColumns = [{
    width: '40px',
    resizable: false,
    cellComponent: 'table/cells/radio-button'
  }, {
    label: 'Vehicle ID',
    valuePath: 'vehicle.name',
    resizable: true,
    width: '90px',
    minResizeWidth: 40,
    component: 'form-components/vehicle-selection/vehicle-header',
    cellComponent: 'form-components/vehicle-selection/vehicle-cell'
  }, {
    label: 'Wheelchairs',
    valuePath: 'vehicle.noOfWheelChairFlexSeats',
    resizable: true,
    width: '100px',
    minResizeWidth: 40,
    component: 'form-components/vehicle-selection/vehicle-header',
    cellComponent: 'form-components/vehicle-selection/vehicle-cell'
  }, {
    label: 'Ambulatory Seats',
    valuePath: 'vehicle.noOfAmbulatoryFlexSeats',
    resizable: true,
    width: '100px',
    minResizeWidth: 40,
    component: 'form-components/vehicle-selection/vehicle-header',
    cellComponent: 'form-components/vehicle-selection/vehicle-cell'
  }, {
    label: 'Service Animals',
    valuePath: 'vehicle.noOfServiceAnimalSeats',
    resizable: true,
    width: '100px',
    minResizeWidth: 40,
    component: 'form-components/vehicle-selection/vehicle-header',
    cellComponent: 'form-components/vehicle-selection/vehicle-cell'
  }, {
    label: 'Vehicle Type',
    valuePath: 'vehicle.vehicleType.name',
    resizable: true,
    minResizeWidth: 40,
    component: 'form-components/vehicle-selection/vehicle-header',
    cellComponent: 'form-components/vehicle-selection/vehicle-cell'
  }];
  _exports.vehiclesTableColumns = vehiclesTableColumns;
});