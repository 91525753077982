define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/perform-cell-item", ["exports", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-item"], function (_exports, _cellItem) {
  'use strict';

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PerformCellItem extends _cellItem.default {
    constructor(props) {
      super(props);
    }

  }

  _exports.default = PerformCellItem;
});