define("adept-iq/serializers/avlm-stop-point", ["exports", "adept-iq/serializers/-avlm-avlmService"], function (_exports, _avlmAvlmService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    normalize(modelClass, resourceHash) {
      const relationships = resourceHash.relationships ? resourceHash.relationships : {};

      if (relationships.cluster) {
        this.dasherizeRelationType(relationships.cluster);
        relationships.avlmCluster = relationships.cluster;
        delete relationships.cluster;
      }

      if (relationships.trip) {
        this.dasherizeRelationType(relationships.trip);
        relationships.avlmTrip = relationships.trip;
        delete relationships.trip;
      }

      if (relationships.address) {
        this.dasherizeRelationType(relationships.address);
        relationships.avlmAddress = relationships.address;
        delete relationships.address;
      }

      if (relationships.schedule) {
        this.dasherizeRelationType(relationships.schedule);
        relationships.avlmSchedule = relationships.schedule;
        delete relationships.address;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      if (payload.data.attributes) {
        try {
          const relationships = payload.data.relationships ? payload.data.relationships : {}; //Adjust id

          if (payload.data.id.startsWith('SP')) {
            payload.data.id = payload.data.id.replace('SP', 'P');
          }

          if (payload.data.id.startsWith('SD')) {
            payload.data.id = payload.data.id.replace('SD', 'D');
          }

          payload.data.attributes.arriveLat = payload.data.attributes.actualArriveLat ? payload.data.attributes.actualArriveLat : null;
          payload.data.attributes.arriveLng = payload.data.attributes.actualArriveLng ? payload.data.attributes.actualArriveLng : null;
          payload.data.attributes.actualArriveTime = payload.data.attributes.actualArriveTimestamp ? payload.data.attributes.actualArriveTimestamp : null;
          payload.data.attributes.otpValue = payload.data.attributes.otp ? payload.data.attributes.otp : null;
          payload.data.attributes.otp = payload.data.attributes.otpStatus ? payload.data.attributes.otpStatus : null;
          payload.data.attributes.odometer = payload.data.attributes.odometerOnArrival ? payload.data.attributes.odometerOnArrival : null;
          payload.data.attributes.actualDepartTime = payload.data.attributes.actualDepartTimestamp ? payload.data.attributes.actualDepartTimestamp : null;
          payload.data.attributes.departLat = payload.data.attributes.actualDepartLat ? payload.data.attributes.actualDepartLat : null;
          payload.data.attributes.departLng = payload.data.attributes.actualDepartLng ? payload.data.attributes.actualDepartLng : null;

          if (payload.data.attributes.lat) {
            payload.data.attributes._lat = payload.data.attributes.lat;
            delete payload.data.attributes.lat;
          }

          if (payload.data.attributes.lng) {
            payload.data.attributes._lng = payload.data.attributes.lng;
            delete payload.data.attributes.lng;
          } // Computed Fields now directly taken from Assembly data


          payload.data.attributes.currentStatus = payload.data.attributes.status; // Stop type

          const refType = payload.data.attributes.refType;

          if (payload.data.attributes.refType && (payload.data.attributes.refType === 'pullIn' || payload.data.attributes.refType === 'pullOut')) {
            switch (refType) {
              case 'pullIn':
                payload.data.attributes._stopType = 'pullinGarage';
                payload.data.attributes.parentType = 'pullinGarage';
                break;

              case 'pullOut':
                payload.data.attributes._stopType = 'pulloutGarage';
                payload.data.attributes.parentType = 'pulloutGarage';
                payload.data.attributes.routeOrdinal = '0';
                break;

              default:
                payload.data.attributes._stopType = payload.data.attributes.type;
            }
          } else if (!payload.data.attributes.type && payload.data.id) {
            if (payload.data.id.includes('GP')) {
              payload.data.attributes._stopType = 'pulloutGarage';
            } else if (payload.data.id.includes('GD')) {
              payload.data.attributes._stopType = 'pullinGarage';
            }
          } else {
            payload.data.attributes._stopType = payload.data.attributes.type;
          } // breaks


          if (payload.data.attributes.type === 'break') {
            payload.data.attributes.odometer = payload.data.attributes.odometerOnDeparture ? payload.data.attributes.odometerOnDeparture : payload.data.attributes.odometerOnArrival;
            relationships.routeBreak = {
              data: {
                id: payload.data.attributes.breakId,
                type: 'avlm-route-break'
              }
            };
          }

          delete payload.data.attributes.type;
          const routeId = payload.data.attributes.routeId;
          const stopPointId = payload.data.id; // etanavstoppoint

          if (payload.data.attributes.routeId) {
            relationships.stopRoute = {
              data: {
                type: 'avlm-route',
                id: routeId
              }
            };
          }

          if (stopPointId) {
            relationships.etaNavStopPoint = {
              data: {
                type: 'etanav-stop-point',
                id: stopPointId
              }
            };
          } // polylines


          if (payload.data.attributes.polyline) {
            const stopPolyline = payload.data.attributes.polyline;
            const plannedPolyline = {
              type: 'polyline',
              id: `${routeId}-${stopPointId}`,
              attributes: {
                coords: stopPolyline
              }
            };
            payload.included = payload.included || [];
            payload.included.push(plannedPolyline);
            relationships.polyline = {
              data: {
                id: `${routeId}-${stopPointId}`,
                type: 'polyline'
              }
            };
          }

          payload.data.relationships = relationships;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }

      return payload;
    }

  });

  _exports.default = _default;
});