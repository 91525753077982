define("adept-iq/pods/components/form-components/number-mask/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-component-input-mask-layout'],
    value: null,
    disabled: false,
    placeholder: null,
    mask: '',
    // default mask is empty
    inputClass: '',
    decimal: false,
    complete: false,
    onChange: null,
    onComplete: null,
    requireCompleteInput: false,
    actions: {
      onInput(unmasked, masked) {
        const requiredCompleteInput = this.get('requireCompleteInput');
        const onChange = this.get('onChange');
        const complete = this.get('complete');

        if (requiredCompleteInput) {
          if (complete && onChange) onChange({
            unmasked,
            masked
          });
          return;
        }

        if (onChange) onChange({
          unmasked,
          masked
        });
      },

      onComplete() {
        this.toggleProperty('complete');
      }

    }
  });

  _exports.default = _default;
});