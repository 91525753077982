define("adept-iq/pods/components/iq-widgets/reportlist-widget/avlmConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/reportlist-widget',
    rowComponent: 'table/rows/zonestat-formatted-row',
    modelName: 'report-list',
    title: 'Reports',
    defaultSortId: 'reportName',
    defaultSortAsc: true,
    columns: [{
      id: 'reportName',
      index: 1,
      type: 'text',
      label: 'Report Name',
      valuePath: 'name',
      searchable: true,
      defaultWidth: 480
    }, {
      id: 'description',
      index: 2,
      type: 'text',
      label: 'Description',
      valuePath: 'description',
      searchable: true,
      defaultWidth: 1000
    }, {
      id: 'category',
      index: 3,
      type: 'text',
      label: 'Category',
      valuePath: 'category',
      searchable: true,
      defaultWidth: 412
    } // {
    //   id: 'sharedWith',
    //   index: 4,
    //   type: 'text',
    //   label: 'Shared With',
    //   valuePath: 'sharedWith',
    //   searchable: true,
    //   defaultWidth: 100
    // }
    ]
  };
  _exports.default = _default;
});