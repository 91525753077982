define("adept-iq/tests/factories/avlm-cluster", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  _emberDataFactoryGuy.default.define('avlm-cluster', {
    sequences: {
      startTimes: () => (0, _moment.default)().startOf('day').toDate(),
      endTimes: () => (0, _moment.default)().startOf('day').add(8, 'hours').toDate()
    },
    default: {
      startTime: _emberDataFactoryGuy.default.generate('startTimes'),
      endTime: _emberDataFactoryGuy.default.generate('endTimes'),
      clusterindex: '1',
      status: 'A'
    }
  });
});