define("adept-iq/pods/components/side-drawer/reconcile-close-dates/component", ["exports", "ember-light-table", "adept-iq/pods/components/side-drawer/reconcile-close-dates/config", "moment", "ember-concurrency", "adept-iq/utils/format-function"], function (_exports, _emberLightTable, _config, _moment, _emberConcurrency, _formatFunction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    config: _config.default,
    title: 'Close Dates to Editing',
    onCloseClick: null,
    allProviderRouteList: null,
    nonVerifiedProviderList: null,
    verifiedProviderList: null,
    dateErrorMessage: '',
    errorMessage: '',
    isNonVerifiedProvidersEmpty: false,
    isNonVerifiedRoutesEmpty: false,
    isVerifiedProvidersEmpty: false,
    canUndo: Ember.computed.or('selectedDateTimeRage.{startDate,endDate}'),
    selectedProviders: Ember.computed.alias('verifiedProviderTable.selectedRows.[]'),
    canSave: Ember.computed.notEmpty('selectedProviders'),
    canSearchEnable: Ember.computed.and('selectedDateTimeRage.{startDate,endDate}'),
    reconcileRouteId: Ember.computed.alias('reconcile.reconcileSelectedRouteId'),
    updateProviderTable: Ember.observer('allProviderRouteList', function () {
      const providerRouteList = Ember.get(this, 'allProviderRouteList');
      const providerRouteArray = [];
      let nonVerifiedArray = [];

      if (!Ember.isEmpty(providerRouteList)) {
        providerRouteList.map(item => {
          item.set('plannedStartTime', (0, _moment.default)(item.plannedStartTime).format('MM/DD/YYYY'));
          providerRouteArray.push(item);
        });
        nonVerifiedArray = this.createNonVerifiedProviderList(providerRouteArray);
        this.createVerifiedProviderList(providerRouteArray, nonVerifiedArray);
      }

      if (Ember.isEmpty(nonVerifiedArray)) {
        Ember.set(this, 'isNonVerifiedProvidersEmpty', true);
      } else {
        Ember.set(this, 'isNonVerifiedProvidersEmpty', false);
      }
    }),

    init() {
      this._super(...arguments);

      this.onVerifiedProviderPanelClick = this.onVerifiedProviderPanelClick.bind(this);
      Ember.set(this, 'selectedDateTimeRage', {
        today: (0, _moment.default)().subtract(1, 'day').format('MM-DD-YYYY'),
        date: null,
        startDate: null,
        endDate: null,
        startforendtime: (0, _moment.default)().startOf('day').toISOString(),
        startofday: (0, _moment.default)().startOf('day').toISOString(),
        endofday: (0, _moment.default)().endOf('day').toISOString(),
        endDateSelection: (0, _moment.default)().subtract(7, 'years').format('MM-DD-YYYY')
      }); // non verified proivers table

      const nonVerifiedProviderTable = new _emberLightTable.default(this.config.nonVerifiedProviderTable);
      this.set('nonVerifiedProviderTable', nonVerifiedProviderTable); // non verified routes table

      const nonVerifiedRouteTable = new _emberLightTable.default(this.config.nonVerifiedRouteTable);
      this.set('nonVerifiedRouteTable', nonVerifiedRouteTable); // verified providers table

      const verifiedProviderTable = new _emberLightTable.default(this.config.verifiedProviderTable);
      this.set('verifiedProviderTable', verifiedProviderTable);
    },

    createNonVerifiedProviderList(responseList) {
      const nonVerifiedArray = [];
      const nonVerifiedProviders = responseList.filter(item => !item.isCompleted);

      if (!Ember.isEmpty(nonVerifiedProviders)) {
        nonVerifiedProviders.map(item => {
          nonVerifiedArray.push(item);
        });
        const nonVerifiedUniqueListWithDate = this.getUniqueDateAndProviderList(nonVerifiedArray);
        Ember.set(this, 'nonVerifiedProviderList', nonVerifiedArray);
        Ember.get(this, 'nonVerifiedProviderTable').setRows(nonVerifiedUniqueListWithDate);
      }

      if (Ember.isEmpty(nonVerifiedProviders)) {
        Ember.set(this, 'isNonVerifiedProvidersEmpty', true);
      } else {
        Ember.set(this, 'isNonVerifiedProvidersEmpty', false);
      }

      return nonVerifiedArray;
    },

    createVerifiedProviderList(responseList, nonVerifiedArray) {
      const verifiedProviders = responseList.filter(item => item.isCompleted && !item.closedDate);

      if (!Ember.isEmpty(verifiedProviders)) {
        const verifiedProvidersArray = [];
        const verifiedProvidersFinalArray = [];
        verifiedProviders.map(item => {
          verifiedProvidersArray.push(item);
        });
        const verifiedUniqueListWithDate = this.getUniqueDateAndProviderList(verifiedProvidersArray);
        verifiedUniqueListWithDate.map(item => {
          const nonVerifiedList = nonVerifiedArray.filter(elm => elm.providerName === item.providerName
          /*&& elm.plannedStartTime === item.plannedStartTime*/
          );

          if (!nonVerifiedList.length) {
            verifiedProvidersFinalArray.push(item);
          }
        });
        const verifiedUniqueList = this.getUniqueList(verifiedProvidersFinalArray, 'providerName');
        Ember.set(this, 'verifiedProviderUniqueList', verifiedUniqueList);
        Ember.set(this, 'verifiedProviderList', verifiedProviders);
      }
    },

    getUniqueDateAndProviderList(list) {
      return list.filter(function (a) {
        var key = a.plannedStartTime + '|' + a.providerName;

        if (!this[key]) {
          this[key] = true;
          return true;
        }
      }, Object.create(null));
    },

    getUniqueList(list, keyType) {
      return [...new Map(list.map(item => [item[keyType], item])).values()];
    },

    getErrorMessage(error, typeMsg) {
      let message;

      switch (error.status) {
        case 400:
          message = 'There was a problem with one of the fields. Please check over the form and try again.';
          break;

        default:
          message = `There was a problem in ${typeMsg}, please try again.`;
          break;
      }

      return message;
    },

    saveCloseDatesTask: (0, _emberConcurrency.task)(function* () {
      try {
        const store = this.get('store');
        const reconcileRouteAdapter = store.adapterFor('reconcile-route');
        const fullVerifiedList = this.verifiedProviderList;
        const routes = [];
        let routeId = 0;
        let rowData = null;
        this.selectedProviders.map(row => {
          rowData = row.content;
          routeId = rowData.routeId;
          const matchedRecords = fullVerifiedList.filter(item => item.providerName === rowData.providerName && item.plannedStartTime === rowData.plannedStartTime);
          matchedRecords.map(record => {
            routes.push({
              routeId: record.routeId,
              providerName: record.providerName,
              closedDate: (0, _moment.default)(new Date()).toISOString(),
              closedBy: this.session.data.authenticated.tokenInfo.firstName
            });
          });
        });
        const payload = {
          id: routeId,
          routes
        };
        yield reconcileRouteAdapter.patchRoute(payload, 'close dates');
        const isExists = routes.filter(route => parseInt(route.routeId, 10) === parseInt(this.reconcileRouteId, 10))[0];

        if (isExists) {
          yield Ember.get(this, 'reconcile.fetchRouteData').perform();
        }
      } catch (error) {
        throw error;
      }
    }),
    fetchNonVerifiedProvidersTask: (0, _emberConcurrency.task)(function* () {
      const providerRouteList = [];
      const startDate = (0, _moment.default)(this.get('selectedDateTimeRage.startDate'));
      const endDate = (0, _moment.default)(this.get('selectedDateTimeRage.endDate'));
      const dateRange = (0, _formatFunction.getDateRangeForSearch)(startDate, endDate);
      const queryOptions = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        option: 'close dates'
      };
      let providerRoutes = [];

      try {
        providerRoutes = yield this.get('store').query('reconcile-route-id', queryOptions);
      } catch (error) {
        let message;

        switch (error.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          default:
            message = 'There was a problem in close dates, please try again.';
            break;
        }

        this.get('notifications').warning(message);
      }

      if (Ember.isEmpty(providerRoutes)) {
        Ember.set(this, 'allProviderRouteList', []);
        return;
      }

      providerRoutes.map(data => {
        providerRouteList.push(data);
      });
      Ember.set(this, 'allProviderRouteList', providerRouteList);
    }),

    checkDateValidation() {
      const isValid = true;

      if (!Ember.get(this, 'selectedDateTimeRage.startDate')) {
        Ember.set(this, 'dateErrorMessage', 'Please select start date');
        return false;
      }

      if (!Ember.get(this, 'selectedDateTimeRage.endDate')) {
        Ember.set(this, 'dateErrorMessage', 'Please select end date');
        return false;
      }

      return isValid;
    },

    checkProviderSelected() {
      const rows = Ember.get(this, 'verifiedProviderTable.rows');
      let isValid = false;
      rows.map(row => {
        if (row.get('selected')) {
          isValid = true;
        }
      });

      if (!isValid) {
        Ember.set(this, 'errorMessage', 'Please select provider for close date');
      }

      return isValid;
    },

    showToolTip() {
      const tooltip = Ember.get(this, 'tooltip');
      const title = 'Close Dates';
      const tip = 'Would you like to close the selected providers?';
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          return Ember.get(this, 'saveCloseDatesTask').perform().then(() => {
            Ember.get(this, 'workspace').popState('reconcileCloseDates');
            Ember.get(this, 'notifications').success('SELECTED PROVIDERS HAVE BEEN SUCCESSFULLY CLOSED');
            tooltip.reset();
          }).catch(error => {
            Ember.get(this, 'notifications').warning(this.getErrorMessage(error, 'close dates'));
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    handleNonVerifiedProviderSearch() {
      return this.get('fetchNonVerifiedProvidersTask').perform();
    },

    handleVerifiedProviderSearch() {
      const verifiedList = Ember.get(this, 'verifiedProviderUniqueList');

      if (!Ember.isEmpty(verifiedList)) {
        verifiedList.map(item => {
          item.isChecked = false;
        });
        Ember.get(this, 'verifiedProviderTable').setRows(verifiedList);
      }

      if (Ember.isEmpty(verifiedList)) {
        Ember.set(this, 'isVerifiedProvidersEmpty', true);
      } else {
        Ember.set(this, 'isVerifiedProvidersEmpty', false);
      }
    },

    setOpenRoutesCount() {
      const columns = Ember.get(this, 'nonVerifiedRouteTable.columns');
      const routeList = Ember.get(this, 'nonVerifiedRouteList');
      columns.map(column => {
        if (!Ember.isEmpty(routeList)) {
          Ember.set(column, 'label', `Open Routes (${routeList.length})`);
        } else {
          Ember.set(column, 'label', 'Open Routes');
        }
      });
    },

    bindNonVerifiedRouteTable(rowData) {
      let nonVerifiedRouteList = [];

      if (!rowData) {
        return;
      }

      const nonVerifiedList = Ember.get(this, 'nonVerifiedProviderList');

      if (!Ember.isEmpty(nonVerifiedList)) {
        nonVerifiedRouteList = nonVerifiedList.filter(item => item.providerName === rowData.providerName && item.plannedStartTime === rowData.plannedStartTime);

        if (!Ember.isEmpty(nonVerifiedRouteList)) {
          nonVerifiedRouteList.map(item => {
            item.set('isChecked', false);
          });
          Ember.set(this, 'nonVerifiedRouteList', nonVerifiedRouteList);
          Ember.get(this, 'nonVerifiedRouteTable').setRows(nonVerifiedRouteList);
        }

        if (Ember.isEmpty(nonVerifiedRouteList)) {
          Ember.set(this, 'isNonVerifiedRoutesEmpty', true);
        } else {
          Ember.set(this, 'isNonVerifiedRoutesEmpty', false);
        }
      }

      this.setOpenRoutesCount();
    },

    resetProperties() {
      this.setProperties({
        'selectedDateTimeRage.startDate': null,
        'selectedDateTimeRage.endDate': null,
        searchClicked: false
      });
      this.resetTableProperties();
    },

    resetTableProperties() {
      this.setProperties({
        nonVerifiedProviderList: null,
        nonVerifiedRouteList: null,
        verifiedProviderUniqueList: null,
        isNonVerifiedProvidersEmpty: false,
        isNonVerifiedRoutesEmpty: false,
        isVerifiedProvidersEmpty: false
      });
      Ember.get(this, 'nonVerifiedProviderTable').setRows([]);
      Ember.get(this, 'nonVerifiedRouteTable').setRows([]);
      Ember.get(this, 'verifiedProviderTable').setRows([]);
      this.setOpenRoutesCount();
    },

    onVerifiedProviderPanelClick() {
      const rows = Ember.get(this, 'verifiedProviderTable.rows');

      if (rows) {
        rows.map(row => {
          if (row.get('selected')) {
            row.set('isChecked', true);
          }
        });
      }
    },

    actions: {
      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        Ember.set(this, valuePath, momentTime.toISOString());
        Ember.set(this, 'searchClicked', false);
        this.resetTableProperties();
      },

      onProviderRowClick(row) {
        const rows = Ember.get(this, 'nonVerifiedProviderTable.rows');
        const selectedRow = rows.findBy('selected');
        if (row === selectedRow) return;

        if (selectedRow) {
          Ember.set(selectedRow, 'selected', false);
        }

        Ember.set(row, 'selected', true);
        this.bindNonVerifiedRouteTable(row.content);
      },

      onSearchButtonClick() {
        Ember.set(this, 'searchClicked', true);
        this.resetTableProperties();
        this.handleNonVerifiedProviderSearch();
      },

      onCloseDateButtonClick() {
        this.handleVerifiedProviderSearch();
      },

      onSaveClick() {
        this.showToolTip();
      },

      onUndoClick() {
        this.resetProperties();
      }

    }
  });

  _exports.default = _default;
});