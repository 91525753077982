define("adept-iq/pods/components/reconcile-column-editor/stubs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editColumnTableDate: [{
      fieldId: 'Actual Arrival Time'
    }, {
      fieldId: 'Actual Depart Time'
    }, {
      fieldId: 'Load/Unload Mins'
    }, {
      fieldId: 'Odometer On Arrival'
    }]
  };
  _exports.default = _default;
});