define("adept-iq/pods/components/form-components/time/component", ["exports", "moment", "adept-iq/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TIME_FORMAT = /^\d\d:\d\d (AM|PM)$/;

  var _default = Ember.Component.extend({
    classNames: ['form-components-time'],
    value: null,
    disabled: false,
    dateTimeFormat: _environment.default.dateTimeFormat,
    actions: {
      onInput(value) {
        if (!TIME_FORMAT.test(value)) return;
        const date = (0, _moment.default)(this.get('value')).format(_environment.default.dateTimeFormat.dateMoment);
        const newValue = (0, _moment.default)(`${date} ${value}`, _environment.default.dateTimeFormat.dateTimeMoment).toDate();
        this.get('onChange')(newValue);
      }

    }
  });

  _exports.default = _default;
});