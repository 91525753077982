define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-vehicle/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    defaultSortId: 'vehicleId',
    defaultSortAsc: true,
    reconcileSearchTables: true,
    showSelectedRowLength: false,
    noRecordsFound: false,
    columns: [{
      label: 'Provider ID',
      width: '85px',
      id: 'providerName',
      valuePath: 'provider',
      index: 0,
      type: 'text',
      resizable: true,
      minResizeWidth: 50,
      searchable: true
    }, {
      label: 'Trip Date',
      id: 'tripDate',
      valuePath: 'attributes.schedulename',
      index: 1,
      type: 'text',
      width: '100px',
      resizable: true,
      minResizeWidth: 100,
      searchable: true
    }, {
      label: 'Route ID',
      id: 'routeId',
      valuePath: 'attributes.routeName',
      index: 2,
      type: 'number',
      width: '60px',
      resizable: true,
      minResizeWidth: 60,
      searchable: true
    }, {
      label: 'Route Type',
      id: 'routeType',
      valuePath: 'attributes.type',
      index: 3,
      type: 'text',
      width: '100px',
      resizable: true,
      minResizeWidth: 100,
      searchable: true
    }, {
      id: 'driverId',
      index: 4,
      type: 'number',
      label: 'Driver ID',
      valuePath: 'attributes.driverId',
      width: '85px',
      resizable: true,
      minResizeWidth: 147,
      searchable: true
    }, {
      id: 'vehicleId',
      index: 5,
      type: 'number',
      label: 'Vehicle ID',
      valuePath: 'attributes.vehicleName',
      width: '85px',
      resizable: true,
      minResizeWidth: 147,
      searchable: true
    }, {
      label: 'Actual Start Time',
      id: 'actualStartTime',
      valuePath: 'attributes.actualstarttime',
      index: 6,
      type: 'time',
      format: 'hh:mm A',
      width: '80px',
      resizable: true,
      minResizeWidth: 80
    }, {
      label: 'Actual End Time',
      id: 'actualEndTime',
      valuePath: 'attributes.actualendtime',
      index: 7,
      type: 'time',
      format: 'hh:mm A',
      width: '80px',
      resizable: true,
      minResizeWidth: 80
    }]
  };
  _exports.default = _default;
});