define("adept-iq/tests/factories/rider-place-type", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('rider-place-type', {
    default: {},
    traits: {
      favorite: {
        id: 'favorite'
      },
      primary: {
        id: 'primary'
      }
    }
  });
});