define("adept-iq/tests/factories/address", ["ember-data-factory-guy", "faker"], function (_emberDataFactoryGuy, _faker) {
  "use strict";

  _emberDataFactoryGuy.default.define('address', {
    sequences: {
      externalIds: num => num,
      premises: () => _faker.default.address.secondaryAddress(),
      streetNumbers: () => _faker.default.address.streetPrefix(),
      streetAddresses: () => _faker.default.address.streetAddress(),
      localities: () => _faker.default.address.city(),
      subLocalities: () => _faker.default.address.citySuffix(),
      subRegions: () => _faker.default.address.county(),
      postalCodes: () => _faker.default.address.zipCode()
    },
    default: {
      alias: 'home',
      premise: _emberDataFactoryGuy.default.generate('premises'),
      streetNumber: _emberDataFactoryGuy.default.generate('streetNumbers'),
      streetAddress: _emberDataFactoryGuy.default.generate('streetAddresses'),
      locality: _emberDataFactoryGuy.default.generate('localities'),
      subLocality: _emberDataFactoryGuy.default.generate('subLocalities'),
      subRegion: _emberDataFactoryGuy.default.generate('subRegions'),
      region: 'NY',
      postOfficeBoxNumber: null,
      postalCode: _emberDataFactoryGuy.default.generate('postalCodes'),
      country: 'United States of America',
      notes: 'Some Place'
    }
  });
});