define("adept-iq/data/providerData", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.data = void 0;
  const data = [{
    'id': '1',
    'attributes': {
      'id': 'AVL',
      'name': 'AVL TEST',
      'isChecked': 'true'
    }
  }, {
    'id': '2',
    'attributes': {
      'id': 'BCI',
      'name': 'BCI',
      'isChecked': 'true'
    }
  }, {
    'id': '3',
    'attributes': {
      'id': 'BCH',
      'name': 'BCH',
      'isChecked': 'true'
    }
  }, {
    'id': '4',
    'attributes': {
      'id': 'MAG',
      'name': 'MAG',
      'isChecked': 'true'
    }
  }, {
    'id': '5',
    'attributes': {
      'id': 'PTC',
      'name': 'PTC',
      'isChecked': 'true'
    }
  }];
  _exports.data = data;
});