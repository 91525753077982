define("adept-iq/classes/rules/eligibility-rules/rider-prefer-taxi-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type"], function (_exports, _baseRule, _ruleResultType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RiderPreferTaxiRule = void 0;

  class RiderPreferTaxiRule extends _baseRule.default {
    doEvaluate() {
      return _ruleResultType.RESULT_TYPE_YES;
    }

  }

  _exports.RiderPreferTaxiRule = RiderPreferTaxiRule;
  RiderPreferTaxiRule.RULE_NAME = 'RiderPreferTaxiRule';
});