define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/driver-provider-notes/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/driver-provider-notes/config", "adept-iq/utils/sorts", "adept-iq/utils/filters"], function (_exports, _component, _config, _sorts, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['driver-provider-notes-table'],
    config: _config.default,
    store: Ember.inject.service(),
    paginationEnabled: true,
    enableWarningRowChangeAlert: true,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    rollbackRecord() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      if (this.get('usingRadioRowComponent')) {
        if (record && !record.get('isNew')) record.rollbackAttributes();
      }
    },

    fetchDataQuery() {
      const params = {
        sorts: []
      };

      try {
        // this only applies for server-side pagination
        if (this.get('paginationEnabled')) {
          params.page = {
            offset: this.get('records.length'),
            limit: this.get('limit')
          };
        }

        const sort = this.buildSortParam();

        if (sort) {
          params.sorts.push(sort);
        }

        const includes = this.get('defaultIncludes');

        if (!Ember.isEmpty(includes)) {
          params.includes = includes;
        }

        const filters = this.setupFilterType();
        params.filter = (0, _filters.buildCompoundFilterNode)('and', filters);
        const modelName = this.get('config.modelName');
        return this.query(modelName, params);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Workspace view is not refreshed on selecting or deselecting' + error);
      }
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const store = this.get('store');
      const queryParams = this.get('queryParams');
      const newDriverProviderNotes = this.get('newDriverProviderNotes');
      const activeData = await store.query(modelName, queryParams);
      const filteredData = activeData.filter(filterFn).sort(compareFn);
      if (newDriverProviderNotes.length) newDriverProviderNotes.filter(pn => !filteredData.includes(pn)).forEach(dn => filteredData.pushObject(dn));
      return filteredData.filter(model => !model.get('isDeleted'));
    }

  });

  _exports.default = _default;
});