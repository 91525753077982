define("adept-iq/scenarios/dispatch-service", ["exports", "ember-data-factory-guy"], function (_exports, _emberDataFactoryGuy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Just for fun, set the log level ( to 1 ) and see all FactoryGuy response info in console
  _emberDataFactoryGuy.Scenario.settings({
    logLevel: 0 // 1 is the max for now, default is 0

  });

  class _default extends _emberDataFactoryGuy.Scenario {
    run() {
      const provider = this.store.peekAll('provider').firstObject;
      const schedule = this.store.peekAll('schedule').firstObject;
      const routes = this.store.peekAll('route');
      const drivers = this.store.peekAll('driver').toArray();
      const breakType = this.store.peekAll('breakType').firstObject;
      routes.forEach((route, index) => {
        const driver = drivers[index];
        const vehicle = route.get('vehicle.content');
        const dispatchSchedule = (0, _emberDataFactoryGuy.make)('dispatch-schedule', {
          schedule
        });
        const dispatchRoute = (0, _emberDataFactoryGuy.make)('dispatch-route', {
          route,
          provider,
          dispatchSchedule
        });
        (0, _emberDataFactoryGuy.make)('etanav-route', {
          dispatchRoute
        });
        (0, _emberDataFactoryGuy.make)('route-vehicle-driver', {
          vehicle,
          dispatchRoute,
          driver
        });
        (0, _emberDataFactoryGuy.make)('polyline', {
          route
        });
        const trips = route.get('trips');
        const cluster = (0, _emberDataFactoryGuy.make)('cluster', {
          dispatchRoute,
          dispatchSchedule
        });
        let clusterOrdinal = 1;
        let routeOrdinal = 0;
        const routeBreaks = route.get('routeBreaks.content');
        const startGarage = vehicle.get('startGarage.content');
        const endGarage = vehicle.get('endGarage.content');
        const routeBreakStart = (0, _emberDataFactoryGuy.make)('route-break', 'morning', {
          route,
          place: startGarage,
          breakType
        });
        const pullout = (0, _emberDataFactoryGuy.make)('stop-point', 'pulloutGarage', {
          cluster,
          routeBreak: routeBreakStart,
          clusterOrdinal,
          routeOrdinal
        });
        clusterOrdinal++;
        routeOrdinal++;
        (0, _emberDataFactoryGuy.make)('etanav-stop-point', {
          stopPoint: pullout
        });
        (0, _emberDataFactoryGuy.make)('polyline', {
          stopPoint: pullout
        });
        const routeBreakEnd = (0, _emberDataFactoryGuy.make)('route-break', 'evening', {
          route,
          place: endGarage,
          breakType
        });
        const pullin = (0, _emberDataFactoryGuy.make)('stop-point', 'pullinGarage', {
          cluster,
          routeBreak: routeBreakEnd,
          clusterOrdinal,
          routeOrdinal
        });
        (0, _emberDataFactoryGuy.make)('etanav-stop-point', {
          stopPoint: pullin
        });
        (0, _emberDataFactoryGuy.make)('polyline', {
          stopPoint: pullin
        });
        trips.forEach(trip => {
          const pickPlace = (0, _emberDataFactoryGuy.make)('place');
          const dropPlace = (0, _emberDataFactoryGuy.make)('place');
          const pickAddress = (0, _emberDataFactoryGuy.make)('address', {
            places: [pickPlace]
          });
          const dropAddress = (0, _emberDataFactoryGuy.make)('address', {
            places: [dropPlace]
          });
          (0, _emberDataFactoryGuy.make)('location', {
            address: pickAddress,
            places: [pickPlace]
          });
          (0, _emberDataFactoryGuy.make)('location', {
            address: dropAddress,
            places: [dropPlace]
          });
          const pick = trip.get('pick.content');
          const drop = trip.get('drop.content');
          let stopPoint = null;
          stopPoint = (0, _emberDataFactoryGuy.make)('stop-point', 'pick', {
            place: dropPlace,
            cluster,
            trip,
            tripStop: pick,
            clusterOrdinal,
            routeOrdinal
          });
          (0, _emberDataFactoryGuy.make)('etanav-stop-point', {
            stopPoint
          });
          clusterOrdinal++;
          routeOrdinal++;
          stopPoint = (0, _emberDataFactoryGuy.make)('stop-point', 'drop', {
            place: dropPlace,
            cluster,
            trip,
            tripStop: drop,
            clusterOrdinal,
            routeOrdinal
          });
          (0, _emberDataFactoryGuy.make)('etanav-stop-point', {
            stopPoint
          });
          (0, _emberDataFactoryGuy.make)('polyline', {
            stopPoint
          });
        });
        routeBreaks.forEach(routeBreak => {
          routeOrdinal++;
          const br = (0, _emberDataFactoryGuy.make)('stop-point', 'Break', {
            cluster,
            routeBreak,
            routeOrdinal
          });
          (0, _emberDataFactoryGuy.make)('etanav-stop-point', 'break', {
            stopPoint: br
          });
        });
      });
    }

  }

  _exports.default = _default;
});