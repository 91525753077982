define("adept-iq/models/travel-need", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    count: attr('number'),
    // these probably should be aliased through belongsTo relationships, but
    // streaming topics don't supply IDs
    passengerTypeName: attr('string'),
    travelNeedTypeName: attr('string'),
    travelNeedType: belongsTo(),
    passengerType: belongsTo(),
    // Not sure why this was a Has Many Relationship?
    trip: belongsTo('trip')
  });

  _exports.default = _default;
});