define("adept-iq/pods/components/change-password-form/component", ["exports", "md5"], function (_exports, _md) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PASSWORD__SHOULD_CONTAIN_ATLEAST_ONE_UPPERCASE = 'Password contains at least 1 uppercase';
  const PASSWORD__SHOULD_CONTAIN_ATLEAST_ONE_LOWERCASE = 'Password contains at least 1 lowercase';
  const PASSWORD_SHOULD_CONTAIN_ATLEAST_ONE_NUMBER = 'Password contains at least 1 digit';
  const PASSWORD_DO_NOT_HAVE_MINIMUM_LENGTH = 'Password contains minimum 6 characters';
  const PASSWORDS_DO_NOT_MATCH = 'Two password entries match';
  const PASSWORD_SHOULD_CONTAIN_ATLEAST_ONE_SPECIAL_CHAR = 'Password contains at least 1 special character [~!@#$%^&*()_-+={};:\'"|<>,.?/]';
  const PASSWORD_DIFFERENT_FROM_CURRENT = 'Password is different from current password';
  const newPasswordCriteriaCheckList = [{
    isValid: (newPassword, confirmPassword) => {
      return Ember.isPresent(newPassword) && Ember.isPresent(confirmPassword) && newPassword === confirmPassword;
    },
    text: PASSWORDS_DO_NOT_MATCH
  }, {
    isValid: newPassword => /[A-Z]+/.test(newPassword) === true,
    text: PASSWORD__SHOULD_CONTAIN_ATLEAST_ONE_UPPERCASE
  }, {
    isValid: newPassword => /[a-z]+/.test(newPassword) === true,
    text: PASSWORD__SHOULD_CONTAIN_ATLEAST_ONE_LOWERCASE
  }, {
    isValid: newPassword => /\d/.test(newPassword) === true,
    text: PASSWORD_SHOULD_CONTAIN_ATLEAST_ONE_NUMBER
  }, {
    isValid: newPassword => /[~!@#$%^&*()_\-+={};:'"|\\<>,.?/]/.test(newPassword) === true,
    text: PASSWORD_SHOULD_CONTAIN_ATLEAST_ONE_SPECIAL_CHAR
  }, {
    isValid: newPassword => newPassword && newPassword.length >= 6,
    text: PASSWORD_DO_NOT_HAVE_MINIMUM_LENGTH
  }, {
    isValid: newPassword => (0, _md.default)(newPassword) !== localStorage.getItem('currentPassword'),
    text: PASSWORD_DIFFERENT_FROM_CURRENT
  }];

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    errors: null,
    password: null,
    newPassword: null,
    confirmPassword: null,
    newPasswordCriteriaList: newPasswordCriteriaCheckList.map(item => ({
      text: item.text,
      isValid: false
    })),
    isValid: Ember.computed('newPasswordCriteriaList', 'password', function () {
      const newPasswordCriteriaList = this.get('newPasswordCriteriaList');
      const password = this.get('password');
      const storedPassword = localStorage.currentPassword;
      const isCurrentPasswordValid = Ember.isPresent(password) && (0, _md.default)(password) === storedPassword;
      return newPasswordCriteriaList.every(item => item.isValid) && isCurrentPasswordValid;
    }),
    isCurrentPasswordValid: Ember.computed('password', function () {
      const password = this.get('password');
      return Ember.isPresent(password) && (0, _md.default)(password) === localStorage.currentPassword;
    }),

    init() {
      this._super(...arguments);
    },

    actions: {
      redirectToLogin() {
        window.location.assign('/require-new-password');
      },

      checkCriteria(e) {
        const newPassword = this.get('newPassword');
        const confirmPassword = this.get('confirmPassword');
        const validationList = newPasswordCriteriaCheckList.map(item => ({
          text: item.text,
          isValid: item.isValid(newPassword, confirmPassword)
        }));
        this.set('newPasswordCriteriaList', validationList);
        e.preventDefault();
      },

      onSubmit(password, newPassword, confirmPassword, e) {
        e.preventDefault();
        this.get('onSubmit')(password, newPassword, confirmPassword);
      }

    }
  });

  _exports.default = _default;
});