define("adept-iq/pods/components/reoptimize-modal/operation-tables/release-routes-provider-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/reoptimize-modal/operation-tables/release-routes-provider-table/config", "adept-iq/utils/status-error-code"], function (_exports, _component, _config, _statusErrorCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ILLEGAL_STATUS = 'illegal';

  var _default = _component.default.extend({
    classNames: ['release-routes-table', 'release-routes-provider-table'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    config: _config.default,
    selectedProvider: null,
    totalRecords: null,
    providerName: Ember.observer('records', function () {
      const recordLength = this.get('records.length');
      const providerName = `Provider ${this.get('selectedProvider')} (${recordLength} Trips)`;
      this.set('config.title', providerName);
    }),
    failedStatusRow: Ember.observer('records', function () {
      const records = this.get('records');
      records.forEach(trip => {
        const statusCode = this.getStatusCode(trip);
        const errorCode = this.getErrorCode(statusCode);
        const errorDescription = this.getErrorDescription(statusCode, trip);
        this.get('table.rows').forEach(row => {
          row.set('statusCode', statusCode);
          row.set('errorCode', errorCode);
          row.set('errorDescription', errorDescription);
        });
      });
    }),

    async init() {
      this._super(...arguments);

      this.set('isSearchAllowed', false);
      this.set('onCancelButtonEnable', true);
      const scheduleId = this.get('failedScheduleId');
      const selectedRow = this.get('selectedRow');
      const failedProvider = selectedRow.map(row => row.get('provider.name')).firstObject;
      this.set('selectedProvider', failedProvider);
      await this.set('queryParams', {
        filter: `and(eq(scheduleId,${scheduleId}),eq(currentProviderName,${failedProvider}),ne(status,${ILLEGAL_STATUS}))`
      });
    },

    /**
     * NYAAR-19295: returns status code (like: 400, 500,.etc)
     * @param {*Object} trip
     * @returns {*Number}
     */
    getStatusCode(trip) {
      const isJSON = this.isJson(trip.get('failedStatus'));

      if (isJSON) {
        const statusCode = JSON.parse(trip.get('failedStatus')).statusCode;
        return statusCode;
      }

      return null;
    },

    /**
     * NYAAR-19295: return errorCode status
     * @param {*Number} errorStatusCode
     * @returns {*String}
     */
    getErrorCode(errorStatusCode) {
      const statusCode = `${errorStatusCode}`;
      return _statusErrorCode.default[statusCode];
    },

    /**
     * NYAAR-19295: return errorDescription related to statusCode
     * @param {*Number} statusCode
     * @param {*Array} trip
     * @returns {*String}
     */
    getErrorDescription(statusCode, trip) {
      if (statusCode === 400) {
        const failedStatus = JSON.parse(trip.get('failedStatus'));
        const errorMessage = JSON.parse(failedStatus.message);
        const errors = JSON.parse(errorMessage.errorMessage);
        const errorDetails = JSON.parse(errors.errorMessage);
        return errorDetails.errors.get('firstObject.technical_details.reason');
      }

      return '';
    },

    /**
     * checks the given value is in JSON-format or not
     * @param {*String} str
     * @returns {*Boolean}
     */
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }

      return true;
    },

    actions: {
      onCancelButtonClick() {
        this.toggleProperty('isProviderTableShow');
      }

    }
  });

  _exports.default = _default;
});