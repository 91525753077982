define("adept-iq/pods/components/side-drawer/perform-stop/pick/component", ["exports", "adept-iq/models/stop-point"], function (_exports, _stopPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    classNames: ['perform-stop-drawer'],
    componentName: 'performStop',
    onShrinkClick: null,
    currentState: null,
    stopPoint: Ember.computed.readOnly('stackFrame.options.stopPoint'),
    dispatchRouteVersionSnapshot: Ember.computed.readOnly('stackFrame.options.dispatchRouteVersionSnapshot'),
    stopUIReference: null,
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),
    isArrived: Ember.computed.equal('stopPoint.computedStatus', _stopPoint.ARRIVE_STOP_STATUS),

    init() {
      this._super(...arguments);
    },

    actions: {
      onShrinkClick() {
        const stopUIReference = this.get('stopUIReference');
        this.set('stopPoint.actualArriveTime', this.get('actualArriveTime'));
        this.set('stopPoint.actualDepartTime', this.get('actualDepartTime'));

        if (stopUIReference) {
          stopUIReference.backUpCurrentState();
        }

        this.onShrinkClick();
      },

      setStopUIReference(stopUIReference) {
        if (stopUIReference) {
          this.set('stopUIReference', stopUIReference);
        }
      },

      getValueFromchild(depart) {
        this.set('depart', depart);
        this.set('arrive', depart);
      },

      getValueFromchildArrive(arrive) {
        this.set('arrive', arrive);
      },

      getValueFromchildTravelNeeds(travelNeedItems) {
        this.set('travelNeedItems', travelNeedItems);
      }

    }
  });

  _exports.default = _default;
});