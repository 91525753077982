define("adept-iq/pods/components/table/cells/text-break/component", ["exports", "adept-iq/pods/components/table/cells/base-cell/component", "adept-iq/pods/components/table/cells/text-break/template", "adept-iq/mixins/table-row-value"], function (_exports, _component, _template, _tableRowValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_tableRowValue.default, {
    classNameBindings: ['overrideClassName'],
    attributeBindings: ['tabindex'],
    layout: _template.default,
    textContent: Ember.computed('value', function () {
      const value = this.get('value.values');
      if (!value || value.length < 1) return '';
      const length = value.filter(val => val.className === 'valueItem').length; // show the actual value when there is only one item

      if (length > 1) {
        return `${length} ${this.get('column.cellDesc')}`;
      } else if (length === 1) {
        return value.firstObject.value;
      }

      return '';
    }),
    columns: Ember.computed('table.allColumns', function () {
      const tableColumns = this.get('table.allColumns');
      return tableColumns;
    }),
    overrideClassName: Ember.computed('config', 'value', function () {
      const value = this.get('value');
      const tableColumn = this.get('columns');
      const found = tableColumn.find(tc => tc.id === 'description');
      let baseClassTestName = 'data-test-table-base-cell';

      if (value === 'Success') {
        baseClassTestName = 'highlightable-success';
      }

      if (!Ember.isPresent(found)) {
        //coming from here
        return [`table-base-cell1  ${baseClassTestName}`];
      }

      return [`table-base-cell-white-space ${baseClassTestName}`];
    }),
    overrideResult: Ember.computed('config', 'config.result', function () {
      const tableColumn = this.get('columns');
      const found = tableColumn.find(tc => tc.id === 'description');
      const baseClassTestName = 'data-test-table-base-cell';

      if (!Ember.isPresent(found)) {
        //coming from here
        return [`table-base-cell1  ${baseClassTestName}`];
      }

      return [`table-base-cell-white-space ${baseClassTestName}`];
    }),
    tooltipText: Ember.computed('row.isLocked', function () {
      const isLocked = this.get('row.isLocked');

      if (Ember.isNone(isLocked)) {
        return null;
      }

      if (isLocked) {
        return `Locked by ${this.get('row.lockedBy')}`;
      }
    }),
    displayTooltip: Ember.computed('tooltipText', 'row.isLocked', function () {
      const tooltipText = this.get('tooltipText');
      const isLocked = this.get('row.isLocked');
      return Ember.isPresent(tooltipText) && isLocked;
    }),
    tabindex: 0
  });

  _exports.default = _default;
});