define("adept-iq/pods/components/generic-components/vertical-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['vertical-panel'],
    // passed from parent component
    models: null,
    onItemSelect: null,
    disableList: null,
    // initial selected item
    selectedModel: null,
    initialIndex: 0,

    init() {
      this._super(...arguments);

      const models = this.get('models') || [];
      const initialModel = models.length ? models[this.initialIndex] : null;
      const selectedModel = this.get('selectedModel') || initialModel;

      if (selectedModel) {
        this.onSelect(selectedModel);
      }
    },

    onSelect(model) {
      const selectedModel = this.get('selectedModel');
      if (!model || model === selectedModel) return;
      if (selectedModel) selectedModel.set('selected', false);
      model.set('selected', true);
      this.set('selectedModel', model);
      this.get('onItemSelect')(model);
    },

    willDestroyElement() {
      this._super(...arguments);

      const selectedModel = this.get('selectedModel');
      selectedModel.set('selected', false);
    },

    actions: {
      onItemSelect(model) {
        this.onSelect(model);
      }

    }
  });

  _exports.default = _default;
});