define("adept-iq/serializers/vehicle-equipment", ["exports", "adept-iq/serializers/-vehicle"], function (_exports, _vehicle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _vehicle.default.extend({
    serialize() {
      const json = this._super(...arguments);

      return json;
    }

  });

  _exports.default = _default;
});