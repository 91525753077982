define("adept-iq/pods/components/iq-widgets/route-information-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: 'Reconcile',
    DRIVER_PHONE_NUMBER: 100,
    ODOMETER_START: 101,
    ODOMETER_END: 102,
    ROUTE_START_IDENTIFIER: 104,
    ROUTE_START_NOTES: 105,
    ROUTE_END_IDENTIFIER: 106,
    ROUTE_END_NOTES: 107,
    RECONCILE_NOTES: 108,
    routeActionTypes: [{
      id: 1,
      name: 'Verify Route'
    }, {
      id: 2,
      name: 'Reopen Route'
    }, {
      id: 3,
      name: 'Report Breakdown'
    }, {
      id: 4,
      name: 'Breakdown Replacement'
    }, {
      id: 5,
      name: 'Rescue'
    }],
    routeTypes: [{
      id: 1,
      name: 'Regular'
    }, {
      id: 2,
      name: 'Breakdown'
    }, {
      id: 3,
      name: 'Breakdown Replacement'
    }, {
      id: 4,
      name: 'Rescue'
    }, {
      id: 5,
      name: 'Rescue Replacement'
    }, {
      id: 6,
      name: 'Keeper'
    }],
    RECONCILE_ROUTE_EDITABLE_FIELDS_OBJ: [{
      fieldKey: 'driverPhoneNumber',
      fieldPath: 'dispatchroutevehicledriver.driverPhoneNumber',
      topic: 'tiq_dispatch_transportiq_routevehicledriver_s3',
      isValueChanged: (oldVal, newValue) => {
        return oldVal !== newValue;
      }
    }, {
      fieldKey: 'odoMeterStart',
      fieldPath: 'routeStartEvent.odo',
      topic: 'tiq_avlm_transportiq_Event_s3',
      isValueChanged: (oldVal, newValue) => {
        return parseInt(oldVal, 10) !== parseInt(newValue, 10);
      }
    }, {
      fieldKey: 'odoMeterEnd',
      fieldPath: 'routeEndEvent.odo',
      topic: 'tiq_avlm_transportiq_Event_s3',
      isValueChanged: (oldVal, newValue) => {
        return parseInt(oldVal, 10) !== parseInt(newValue, 10);
      }
    }, {
      fieldKey: 'selectedVehicle',
      fieldPath: 'vehicle',
      topic: 'tiq_transportiq_routevehicledriver_s3',
      isValueChanged: (oldVal, newValue) => {
        return JSON.stringify(oldVal) !== JSON.stringify(newValue);
      }
    }, {
      fieldKey: 'selectedDriver',
      fieldPath: 'driver',
      topic: 'tiq_transportiq_routevehicledriver_s3',
      isValueChanged: (oldVal, newValue) => {
        return JSON.stringify(oldVal) !== JSON.stringify(newValue);
      }
    }, {
      fieldKey: 'startName',
      fieldPath: 'startplace.address.firstObject.alias',
      topic: 'tiq_address_transportiq_Place_s3',
      isValueChanged: (oldVal, newValue) => {
        return oldVal !== newValue;
      }
    }, {
      fieldKey: 'endName',
      fieldPath: 'endplace.alias',
      topic: 'tiq_address_transportiq_Place_s3',
      isValueChanged: (oldVal, newValue) => {
        return oldVal !== newValue;
      }
    }, {
      fieldKey: 'routeStartAddressGeoNode',
      fieldPath: 'startAddress',
      topic: 'tiq_avlm_transportiq_address_s3',
      isValueChanged: (oldVal, newValue) => {
        return JSON.stringify(oldVal) !== JSON.stringify(newValue);
      }
    }, {
      fieldKey: 'routeEndAddressGeoNode',
      fieldPath: 'endAddress',
      topic: 'tiq_avlm_transportiq_address_s3',
      isValueChanged: (oldVal, newValue) => {
        return JSON.stringify(oldVal) !== JSON.stringify(newValue);
      }
    }, {
      fieldKey: 'routeStartNotes',
      fieldPath: 'startplace.address.firstObject.notes',
      topic: 'tiq_address_transportiq_Address_s3',
      isValueChanged: (oldVal, newValue) => {
        return oldVal !== newValue;
      }
    }, {
      fieldKey: 'routeEndNotes',
      fieldPath: 'endplace.notes',
      topic: 'tiq_address_transportiq_Address_s3',
      isValueChanged: (oldVal, newValue) => {
        return oldVal !== newValue;
      }
    }],
    routeFields: [{
      type: 'text',
      label: 'Driver ID',
      valuePath: 'driverId'
    }, {
      type: 'text',
      label: 'Driver Phone Number',
      valuePath: 'driverPhoneNumber'
    }, {
      type: 'text',
      label: 'Vehicle ID',
      valuePath: 'vehicleName'
    }, {
      type: 'time',
      label: 'Planned Start Time',
      valuePath: 'plannedStartTime'
    }, {
      type: 'time',
      label: 'Planned End Time',
      valuePath: 'plannedEndTime'
    }, {
      type: 'time',
      label: 'Actual Start Time',
      valuePath: 'actualstarttime'
    }, {
      type: 'time',
      label: 'Actual End Time',
      valuePath: 'actualendtime'
    }, {
      type: 'text',
      label: 'Odometer Start',
      valuePath: 'startGarage.actualodometer'
    }, {
      type: 'text',
      label: 'Odometer End',
      valuePath: 'endGarage.actualodometer'
    }, {
      type: 'options',
      label: 'Route Type',
      valuePath: 'type',
      options: 'routeTypeOptions'
    }]
  };
  _exports.default = _default;
});