define("adept-iq/tests/factories/lock", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  _emberDataFactoryGuy.default.define('lock', {
    default: {
      entityType: 'dispatchRoute',
      entityId: '1',
      timeToLive: 10,
      // for testing purposes locks will be very short, use traits to extend a lock
      status: 'unLocked',
      expirationTime: (0, _moment.default)().add(8, 'hours').toDate(),
      persist: false
    },
    traits: {
      locked: {
        status: 'locked'
      },
      acquired: {
        status: 'acquired'
      },
      acquiredNormal: {
        status: 'acquired',
        timeToLive: 180
      },
      acquiredLong: {
        status: 'acquired',
        timeToLive: 1800
      }
    }
  });
});