define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel/pause", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel"], function (_exports, _react, _panel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Pause = () => {
    const {
      pausePlay
    } = (0, _panel.usePanel)();
    return _react.default.createElement("button", {
      className: "btn circle-btn",
      disabled: false,
      onClick: () => pausePlay()
    }, _react.default.createElement("span", null, _react.default.createElement("svg", {
      width: "32",
      height: "32",
      viewBox: "0 0 32 32",
      xmlns: "http://www.w3.org/2000/svg"
    }, _react.default.createElement("defs", null, _react.default.createElement("linearGradient", {
      x1: "50%",
      y1: "0%",
      x2: "50%",
      y2: "100%",
      id: "a"
    }, _react.default.createElement("stop", {
      stopColor: "#F9F9F9",
      offset: "0%"
    }), _react.default.createElement("stop", {
      stopColor: "#D1D1D1",
      offset: "100%"
    }))), _react.default.createElement("title", null, "pause"), _react.default.createElement("g", {
      fill: "none",
      fillRule: "evenodd"
    }, _react.default.createElement("g", null, _react.default.createElement("circle", {
      stroke: "#B4B4B4",
      fill: "url(#a)",
      cx: "15.552",
      cy: "15.552",
      r: "15.052"
    }), _react.default.createElement("path", {
      d: "M13.181 9a1 1 0 011 1v11a1 1 0 01-1 1h-1a1 1 0 01-1-1V10a1 1 0 011-1h1zm5 0a1 1 0 011 1v11a1 1 0 01-1 1h-1a1 1 0 01-1-1V10a1 1 0 011-1h1z",
      fill: "#263238"
    }))))));
  };

  var _default = Pause;
  _exports.default = _default;
});