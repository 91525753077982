define("adept-iq/pods/components/form-components/select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-select'],
    value: null,
    disabled: false,
    extra: null,
    store: Ember.inject.service(),
    dataFromModel: false,
    options: Ember.computed.alias('extra.options'),

    init() {
      this._super(...arguments);

      if (!Ember.isNone(this.get('extra.optionModelName'))) {
        this.set('dataFromModel', true);
        this.set('options', this.get('store').peekAll(this.get('extra.optionModelName')));
      }
    },

    actions: {
      onChange(option) {
        this.set('selected', option);
      }

    }
  });

  _exports.default = _default;
});