define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    vehiclesTableColumns: [{
      label: 'Vehicle ID',
      valuePath: 'vehicleName',
      dataType: 'uuid',
      resizable: true,
      minResizeWidth: 50,
      id: 'vehicleId',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Driver ID',
      valuePath: 'badgenumber',
      dataType: 'uuid',
      resizable: true,
      minResizeWidth: 50,
      id: 'driverId',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Driver Name',
      valuePath: 'driverName',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      id: 'driverName',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Provider',
      valuePath: 'provider',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      id: 'provider',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'OTP',
      valuePath: 'otp',
      dataType: 'text',
      resizable: true,
      searchable: true,
      minResizeWidth: 50,
      id: 'otp',
      component: 'iq-widgets/playback-widget/table-header'
    }],
    vehicleEventsTableColumns: [{
      label: 'Time',
      valuePath: 'localTimeDisplay',
      dataType: 'time',
      format: `${_environment.default.dateTimeFormat.timeMoment}`,
      resizable: true,
      minResizeWidth: 20,
      searchable: true,
      id: 'time',
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Route',
      valuePath: 'routeName',
      dataType: 'uuid',
      resizable: true,
      minResizeWidth: 20,
      id: 'routeId',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Vehicle ID',
      valuePath: 'vehicleId',
      dataType: 'uuid',
      resizable: true,
      minResizeWidth: 20,
      id: 'vehicleId',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Latitude',
      valuePath: 'displayLat',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 20,
      searchable: true,
      id: 'lat',
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Longitude',
      valuePath: 'displayLng',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 20,
      searchable: true,
      id: 'lng',
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Passenger ID',
      valuePath: 'passengerId',
      dataType: 'uuid',
      resizable: true,
      minResizeWidth: 30,
      id: 'passengerId',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Passenger First Name',
      valuePath: 'passengerFirstName',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      id: 'passengerFName',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Passenger Last Name',
      valuePath: 'passengerLastName',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      id: 'passengerLName',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Stop Address',
      valuePath: 'stopAddress',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 70,
      id: 'stopAddress',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Stop Type',
      valuePath: 'stopType',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      id: 'stopType',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Event Type',
      valuePath: 'eventType',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      id: 'eventType',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Vehicle Speed',
      valuePath: 'vehicleSpeed',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 20,
      id: 'speed',
      component: 'iq-widgets/playback-widget/table-header'
    }],
    vehicleEvents: ['pick', 'wheelchair-pick', 'wheelchair-drop', 'onBreak', 'offBreak', 'drop', 'pullOut', 'pullIn'],
    tableTypes: {
      events: 'events',
      vehicles: 'vehicles'
    }
  };
  _exports.default = _default;
});