define("adept-iq/pods/components/iq-widgets/vehicles-breakdown-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'vehicleId',
        type: 'text',
        label: 'ID',
        valuePath: 'name',
        editable: false
      }]
    }, {
      title: 'Detail',
      fields: [{
        id: 'breakdownPlace',
        type: 'breakdown-location',
        label: 'Location',
        valuePath: 'breakdownPlace',
        modelName: 'place',
        isMultiType: true,
        extra: {
          aliasPath: 'address.content.alias',
          notesPath: 'address.content.notes',
          premisePath: 'address.content.premise',
          streetNumberPath: 'address.content.streetNumber',
          streetNamePath: 'address.content.streetName',
          localityPath: 'address.content.locality',
          regionPath: 'address.content.region',
          subRegionPath: 'address.content.subRegion',
          postalCodePath: 'address.content.postalCode',
          countryPath: 'address.content.country',
          freeformAddressPath: 'address.content.freeformAddress',
          tomtomFormattedAddressPath: 'address.content.tomtomFormattedAddress',
          useOptionRecord: true,
          streetAddressPath: 'address.content.streetAddress',
          locationsPath: 'address.locations.content.firstObject.latlng'
        }
      }, {
        id: 'breakdownTime',
        type: 'timeflatpickr',
        label: 'Breakdown Time',
        readonlyCell: false,
        valuePath: 'breakdownTime'
      }, {
        id: 'breakdownDate',
        type: 'dateflatpickr',
        label: 'Date',
        valuePath: 'breakdownTime'
      }]
    }, {
      title: 'Breakdown reasons',
      fields: [{
        id: 'breakdownType',
        type: 'enum',
        label: 'Reasons',
        valuePath: 'breakdownType',
        cellDesc: 'breakdownType',
        editable: true,
        hidden: false,
        extra: {
          optionModelName: 'breakdown-type',
          optionSearchPath: 'name',
          optionDisplayName: 'displayName'
        }
      }]
    }]
  };
  _exports.default = _default;
});