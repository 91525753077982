define("adept-iq/utils/sorts", ["exports", "adept-iq/config/comparison-functions"], function (_exports, _comparisonFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildCompareFunction = buildCompareFunction;
  _exports.buildSimpleArrayCompareFunction = buildSimpleArrayCompareFunction;
  _exports.buildSimpleCompareFunction = buildSimpleCompareFunction;

  function buildCompareFunction(params) {
    return (a, b) => {
      const cmp = Ember.makeArray(params).reduce((acc, _ref) => {
        let {
          path,
          asc,
          compare
        } = _ref;
        if (acc !== 0) return acc;
        const sign = asc ? 1 : -1;
        const aValue = a.get ? a.get(path) : a[path];
        const bValue = b.get ? b.get(path) : b[path];
        return sign * compare(aValue, bValue);
      }, 0); // tie breaker uses `guidFor`

      if (cmp === 0) {
        return (0, _comparisonFunctions.nullCompare)(a, b);
      }

      return cmp;
    };
  }

  function buildSimpleCompareFunction(params) {
    return (a, b) => {
      const cmp = Ember.makeArray(params).reduce((acc, _ref2) => {
        let {
          path,
          asc,
          compare
        } = _ref2;
        if (acc !== 0) return acc;
        const sign = asc ? 1 : -1;
        const aValue = a.get ? a.get(path) : a[path];
        const bValue = b.get ? b.get(path) : b[path];
        return sign * compare(aValue, bValue);
      }, 0);
      return cmp;
    };
  }

  function buildSimpleArrayCompareFunction(params) {
    return (a, b) => {
      const cmp = Ember.makeArray(params).reduce((acc, _ref3) => {
        let {
          path,
          asc,
          compare,
          arraySortPath
        } = _ref3;
        if (acc !== 0) return acc;
        const sign = asc ? 1 : -1;
        let aValue = a.get ? a.get(path) : a[path];
        let bValue = b.get ? b.get(path) : b[path];

        if (arraySortPath && aValue) {
          aValue = aValue.get ? aValue.get(arraySortPath) : aValue;
        }

        if (arraySortPath && bValue) {
          bValue = bValue.get ? bValue.get(arraySortPath) : bValue;
        }

        return sign * compare(aValue, bValue);
      }, 0);
      return cmp;
    };
  }
});