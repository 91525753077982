define("adept-iq/adapters/dwh-route", ["exports", "adept-iq/adapters/-dwh-dwhService", "adept-iq/config/api-urls", "adept-iq/utils/rql-generators"], function (_exports, _dwhDwhService, _apiUrls, _rqlGenerators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable camelcase */
  var _default = _dwhDwhService.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),

    findRecord(store, type, id) {
      return this.getDwhRouteById(id);
    },

    updateRecord(store, type, snapshot) {
      const serialized = {};
      const serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(serialized, type, snapshot, {
        includeId: true
      }); // TODO: use real API to update

      return Promise.resolve(serialized);
    },

    async getDwhRoutesByDate(date) {
      const session = this.get('session');
      const apiUrl = `${this.get('host')}/route`;
      const fields = `
      id,
      provider,
      attributes.routeName,
      attributes.tenantId,
      attributes.vehicleName,
      attributes.driverId,
      attributes.driverPhoneNumber,
      attributes.plannedStartTime,
      attributes.plannedEndTime,
      attributes.actualstarttime,
      attributes.actualendtime`.replace(/(\r\n|\n|\r|\s)/gm, '');
      const json = {
        data: {
          schedulename_str: JSON.stringify([date]),
          data_source_str: JSON.stringify(['DB']),
          usage_str: JSON.stringify(['165']),
          fields
        }
      };
      const res = await this.get('ajaxService').request(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json',
        data: json
      });
      const data = res.data;
      return data;
    },

    async getDwhRouteById(id) {
      const session = this.get('session');
      const apiUrl = `${_apiUrls.API.dwhService.host}/route`;
      const fields = `
      id,
      provider,
      startGarage,
      endGarage,
      breaks,
      trips,
      attributes.schedulename,
      attributes.routeName,
      attributes.tenantId,
      attributes.vehicleName,
      attributes.driverId,
      attributes.driverFullName,
      attributes.driverPhoneNumber,
      attributes.plannedStartTime,
      attributes.plannedEndTime,
      attributes.actualstarttime,
      attributes.actualendtime`.replace(/(\r\n|\n|\r|\s)/gm, '');
      const json = {
        data: {
          data_source_str: JSON.stringify(['DB']),
          usage_str: JSON.stringify(['165']),
          fields,
          rql_filter: (0, _rqlGenerators.eqRQL)('routeAdeptId', [id])
        }
      };
      const res = await this.get('ajaxService').request(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json',
        data: json
      });
      const data = res.data && res.data.length > 0 ? res.data.firstObject : null;
      return data;
    }

  });

  _exports.default = _default;
});