define("adept-iq/pods/components/rider-management-widget/rider-contact-information/rider-contact-form/component", ["exports", "adept-iq/config/placeholders", "adept-iq/utils/validator"], function (_exports, _placeholders, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CONTACT_TYPE = {
    emergency: 'emergency',
    alternative: 'alternative'
  };

  var _default = Ember.Component.extend({
    classNames: ['rider-relationship-options-select'],
    rider: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    PHONE_NUMBER_PLACEHOLDER: _placeholders.PHONE_NUMBER_PLACEHOLDER,
    riderRelationshipOptions: null,
    contactTypeOptions: null,
    selectedContactType: null,
    selectedReasonType: null,

    async init() {
      this._super(...arguments);

      const riderRelationshipTypes = await this.get('rider').getConfigRelationshipTypes();
      const riderRelationshipOptions = riderRelationshipTypes.map(r => r.get('name'));
      const riderContactReasonTypes = await this.get('rider').getConfigContactReasonTypes();
      const contactReasonTypeOptions = riderContactReasonTypes.toArray();
      const riderContactRecord = this.get('riderContactRecord');
      const reasonName = riderContactRecord.get('reason');

      if (reasonName) {
        const reasonType = riderContactReasonTypes.find(record => record.get('name') === reasonName);
        this.set('selectedReasonType', reasonType);
      }

      this.setProperties({
        riderRelationshipOptions,
        contactTypeOptions: [CONTACT_TYPE.emergency, CONTACT_TYPE.alternative],
        contactReasonTypeOptions
      });
    },

    actions: {
      closeContactInfoDialog() {
        this.set('canShowRiderContactFormModal', false);
      },

      onPhoneValueChange(record, maskObj) {
        // match[0] = area code
        // match[1] = first part of 3 digit phone number
        // match[2] = second part of 4 digit phone number
        const match = maskObj.masked.replace(/_/, '').split('-');
        const phone = record;
        phone.set('phoneNumber', match[0] + match[1] + match[2]);
        phone.set('phoneNumber', maskObj.unmasked);
      },

      onReasonValueChange(record, value) {
        this.set('selectedReasonType', value);
        record.set('reason', value.name);
      },

      onTick() {
        const tooltip = this.get('tooltip');
        const riderContactRecord = this.get('riderContactRecord');
        const name = riderContactRecord.get('name').trim();
        const relationship = riderContactRecord.get('relationship').trim();
        const phone = riderContactRecord.get('phoneNumber');
        const email = riderContactRecord.get('email').trim();
        const emailValid = (0, _validator.isEmailValid)(email);
        const errors = [];

        if (Ember.isEmpty(name)) {
          errors.push({
            validation: 'Name is required'
          });
        }

        if (Ember.isEmpty(relationship)) {
          errors.push({
            validation: 'Relationship is required'
          });
        }

        if (!phone || phone.length !== 10) {
          errors.push({
            validation: 'Phone number is required'
          });
        }

        if (!Ember.isBlank(email) && !emailValid) {
          errors.push({
            validation: `"${email}" is not a valid email.`
          });
        }

        if (errors.length > 0) {
          tooltip.pushConfirmation({
            title: 'Rider Management',
            tip: '',
            errors,
            primaryActionText: 'OK',

            primaryAction() {
              return tooltip.reset();
            }

          });
        } else {
          tooltip.pushConfirmation({
            title: 'Rider Management',
            tip: 'Are you sure you want to save these changes?',
            primaryActionText: 'Confirm',
            primaryAction: () => {
              const notifications = this.get('notifications');
              const contactType = riderContactRecord.get('contactType');
              const selectedContactType = contactType === CONTACT_TYPE.emergency ? CONTACT_TYPE.emergency : CONTACT_TYPE.alternative;
              const editOrNewRecord = this.get('isContactInfoEdit') ? 'edited' : 'added';
              notifications.success(`Rider ${selectedContactType} Contact successfully ${editOrNewRecord}`);
              this.get('onRiderContactsSave')();
              this.set('canShowRiderContactFormModal', false);
              tooltip.reset();
            },
            secondaryActionText: 'Cancel',
            secondaryAction: () => {
              tooltip.reset();
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});