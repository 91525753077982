define("adept-iq/utils/turn-by-turn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.renderTurnByTurnGuidance = _exports.MANEUVER_MAP = void 0;
  const MANEUVER_MAP = {
    'ARRIVE': 'You have arrived',
    'ARRIVE_LEFT': 'You have arrived. Your destination is on the left',
    'ARRIVE_RIGHT': 'You have arrived. Your destination is on the right',
    'DEPART': 'Leave',
    'STRAIGHT': 'Keep straight',
    'KEEP_RIGHT': 'Keep right',
    'BEAR_RIGHT': 'Bear right',
    'TURN_RIGHT': 'Turn right',
    'SHARP_RIGHT': 'Turn sharp right',
    'KEEP_LEFT': 'Keep left',
    'BEAR_LEFT': 'Bear left',
    'TURN_LEFT': 'Turn left',
    'SHARP_LEFT': 'Turn sharp left',
    'MAKE_UTURN': 'Make a U-turn',
    'ENTER_MOTORWAY': 'Take the motorway',
    'ENTER_FREEWAYIVE': 'Take the freeway',
    'ENTER_HIGHWAY': 'Take the highway',
    'TAKE_EXIT': 'Take the exit',
    'MOTORWAY_EXIT_LEFT': 'Take the left exit',
    'MOTORWAY_EXIT_RIGHT': 'Take the right exit',
    'TAKE_FERRY': 'Take the ferry',
    'ROUNDABOUT_CROSS': 'Cross the roundabout',
    'ROUNDABOUT_RIGHT': 'At the roundabout take the exit on the right',
    'ROUNDABOUT_LEFT': 'At the roundabout take the exit on the left',
    'ROUNDABOUT_BACK': 'Go around the roundabout',
    'TRY_MAKE_UTURN': 'Try to make a U-turn',
    'FOLLOW': 'Follow',
    'SWITCH_PARALLEL_ROAD': 'Switch to the parallel road',
    'SWITCH_MAIN_ROAD': 'Switch to the main road',
    'ENTRANCE_RAMP': 'Take the ramp',
    'WAYPOINT_LEFT': 'You have reached the waypoint. It is on the left',
    'WAYPOINT_RIGHT': 'You have reached the waypoint. It is on the right',
    'WAYPOINT_REACHED': 'You will reach the waypoint'
  };
  _exports.MANEUVER_MAP = MANEUVER_MAP;

  const renderTurnByTurnGuidance = guidance => {
    return MANEUVER_MAP[guidance.maneuver];
  };

  _exports.renderTurnByTurnGuidance = renderTurnByTurnGuidance;
});