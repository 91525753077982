define("adept-iq/services/socket", ["exports", "moment", "md5", "adept-iq/config/environment", "adept-iq/classes/socket-stream", "adept-iq/classes/work-queue"], function (_exports, _moment, _md, _environment, _socketStream, _workQueue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SOCKET_WORKER_PATH = '/workers/socket.js'; // overrides model name for deletion based on topic message model; optional

  const deleteModelNameForTopicModel = {
    'message': 'avlm-canned-message',
    noShow: 'no-show'
  };

  var _default = Ember.Service.extend({
    locks: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    work: Ember.inject.service(),
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    widget: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    avlQueue: null,
    isClientRegistered: false,
    isGlobalFilterRegistered: false,
    sockets: null,
    _digestLookup: null,
    isConnected: false,
    filterSocket: null,
    socketReconnectCount: Ember.computed.alias('filterSocket.reconnectCount'),
    isSocketConnected: Ember.computed.alias('filterSocket.isConnected'),
    socketState: Ember.computed.alias('filterSocket.socketState'),
    resetSocketFlags: Ember.observer('socketState', function () {
      if (this.get('socketState') !== 'open') {
        this.set('isClientRegistered', false);
        this.set('isGlobalFilterRegistered', false);
      }
    }),

    init() {
      this._super(...arguments);

      this.initializeSockets();
      this.initializeAVLQueue();
      this.resetSocketFlags(); // convenience console methods

      window.disconnectSocket = () => this.disconnect();

      window.connectSocket = (startDate, endDate) => {
        this.connect({
          startDate,
          endDate
        });
      };

      this._digestLookup = {};
    },

    destroy() {
      this.destroySockets();
      this.destroyAVLQueue();
      window.disconnectSocket = null;
      window.connectSocket = null;
      this._digestLookup = null;

      this._super(...arguments);
    },

    initializeSockets() {
      this._onSocketUnauthorized = socket => {
        Ember.run.schedule('afterRender', this, 'onSocketUnauthorized', socket);
      };

      this._onSocketData = (socket, data) => {
        Ember.run.schedule('afterRender', this, 'onSocketData', socket, data);
      };

      const sockets = _environment.default.APP.SUBSCRIPTION_TOPICS.map(_ref => {
        let {
          topic
        } = _ref;
        const worker = new Worker(SOCKET_WORKER_PATH); // share environment variables with worker

        worker.postMessage({
          action: 'setEnv',
          ENV: _environment.default
        });

        const socketStream = _socketStream.default.create({
          topic,
          worker
        });

        this.set('filterSocket', socketStream);
        socketStream.on('unauthorized', this._onSocketUnauthorized);
        socketStream.on('data', this._onSocketData);
        return socketStream;
      });

      this.set('sockets', sockets);
    },

    destroySockets() {
      const sockets = this.get('sockets');
      sockets.forEach(socket => {
        socket.off('unauthorized', this._onSocketUnauthorized);
        socket.off('data', this._onSocketData);
        socket.get('worker').terminate();
        socket.destroy();
      });
      this._onSocketUnauthorized = null;
      this._onSocketData = null;
      this.disconnect();
      sockets.forEach(socket => {
        socket.destroy();
      });
      sockets.clear();
      this.set('sockets', null);
    },

    initializeAVLQueue() {
      const avlQueueOptions = Object.assign({}, _environment.default.work['skt-avl'], {
        name: 'skt-avl',
        perform: jobs => {
          jobs.forEach(_ref2 => {
            let {
              payload
            } = _ref2;
            this.processAVLPayload(payload);
          });
        },
        // debounce by vehicle ID
        uniqBy: 'payload.message.data.relationships.vehicle.data.id'
      });

      const avlQueue = _workQueue.default.create(avlQueueOptions);

      this.get('work').registerQueue(avlQueue);
      this.set('avlQueue', avlQueue);
    },

    destroyAVLQueue() {
      const avlQueue = this.get('avlQueue');
      this.get('work').unregisterQueue(avlQueue);
      avlQueue.destroy();
      this.set('avlQueue', null);
    },

    connect() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const startDate = options.startDate || this.get('workspace.startDate');
      const endDate = options.endDate || this.get('workspace.endDate');
      const alreadyConnected = this.get('isConnected');

      if (Ember.isNone(startDate) || Ember.isNone(endDate)) {
        throw new Error('startDate and endDate required');
      }

      const token = this.get('session.data.authenticated.token');

      if (Ember.isNone(token) && !_environment.default.APP.test) {
        throw new Error('token required');
      }

      this.set('isConnected', 'true');
      this.get('sockets').forEach(socket => {
        // if it is already connected, we just need to send a new filter
        if (alreadyConnected) {
          return;
        }

        socket.connect(startDate, endDate, token);
      });
    },

    getSocketClientId() {
      return this.get('filterSocket').clientId;
    },

    disconnect() {
      this.set('isConnected', false);
      this.get('sockets').forEach(socket => {
        socket.disconnect();
      });
    },

    onSocketUnauthorized() {
      const session = this.get('session');
      if (!this.get('isConnected')) return;
      this.disconnect(); // if any one socket is unauthorized, nuke them all and log out

      this.get('tooltip').pushConfirmation({
        tip: 'You do not have the appropriate privileges to view this page. Check with your administrator.',
        title: 'Permissions',
        hasOverlay: true,
        primaryActionText: 'OK',
        primaryAction: () => {
          Ember.run.later(this, function () {
            session.invalidate();
          }, 300);
          this.get('tooltip').reset();
        },
        closeAction: () => {
          Ember.run.later(this, function () {
            session.invalidate();
          }, 300);
          this.get('tooltip').reset();
        }
      });
    },

    onSocketData(socket, data) {
      const action = data.header && data.header.action;
      const batchedEvents = data.body; // Batched Events

      if (batchedEvents && action === 'WIDGET_ENTITY_BATCH_UPDATE_EVENT') {
        for (let i = 0; i < batchedEvents.length; i++) {
          this.onSocketPayload(socket, batchedEvents[i]);
        }

        return;
      }

      if (batchedEvents && action === 'CORE_ENTITY_BATCH_UPDATE_EVENT') {
        for (let i = 0; i < batchedEvents.length; i++) {
          this.onSocketPayload(socket, batchedEvents[i]);
        }

        return;
      }

      this.onSocketPayload(socket, data);
    },

    onSocketPayload(socket, payload) {
      const action = payload.header && payload.header.action;

      if (action === 'HEARTBEAT_RESPONSE') {
        return;
      }

      if (action === 'CLIENT_REGISTERED') {
        this.set('isClientRegistered', true);
        return;
      }

      if (action === 'GLOBAL_FILTER_REGISTERED') {
        this.set('isGlobalFilterRegistered', true);
        return;
      }

      const eventType = payload.eventType;

      if (eventType === 'WIDGET_EVENT') {
        this.get('widget').receiveWidgetUpdate(payload);
        return;
      }

      if (eventType === 'CORE_ENTITY_UPDATE_EVENT') {
        this.get('widget').receiveCoreEntityUpdate(payload);
        return;
      }

      const workspaceContext = this.get('workspaceContext'); // update diagnostics

      if (this.get('workspace.isDiagnosticToolShown')) {
        const packetPayload = this._createDiagnosticPacketPayload(payload);

        if (packetPayload) {
          workspaceContext.pushPayload(packetPayload);
        }
      } // handle some models differently


      switch (payload.model) {
        case 'avl':
          {
            // queue AVL updates, use addJob so we can filter by vehicle id.
            this.get('avlQueue').addJob({
              payload
            });
            this.get('work').start();
            return;
          }

        case 'avlmRouteUpdate':
        case 'avlmTripPayment':
          // explicitly not handled
          console.warn(`Unused ${payload.model} model, currently not cached in the ember data store`); // eslint-disable-line no-console

          return;

        case 'avlmDriverStateEvent':
          if (!_environment.default.APP.avlmLite) return;
          break;

        default:
          break;
      } // handle based on payload action


      switch (payload.action) {
        case 'INIT':
          break;

        case 'UPDATE':
          {
            if (payload.transformedPayloads) {
              workspaceContext.pushPayloads(payload.transformedPayloads, payload.model);
            } else if (payload.payloads) {
              workspaceContext.pushPayloads(payload.payloads, payload.model);
            } else if (payload.message) {
              workspaceContext.pushPayload(payload.message);
            }

            break;
          }

        case 'DELETE':
          {
            const id = payload.message.id;
            const castModelName = deleteModelNameForTopicModel[payload.model];
            const modelName = castModelName || Ember.String.dasherize(payload.model);
            workspaceContext.removeRecord(modelName, id);
            break;
          }

        default:
          workspaceContext.pushPayloads(payload, payload.model);
      }
    },

    processAVLPayload(payload) {
      if (payload.action !== 'UPDATE') {
        console.warn(`unhandled avlWidget action '${payload.action}'`); // eslint-disable-line no-console

        return;
      }

      const store = this.get('store');
      const {
        message
      } = payload;
      const {
        id
      } = message.data.relationships.vehicle.data;
      let {
        lat,
        lng,
        speed,
        heading,
        odo,
        timestamp
      } = message.data.attributes;
      lat = Ember.isPresent(lat) ? parseFloat(lat) : null;
      lng = Ember.isPresent(lng) ? parseFloat(lng) : null;
      heading = Ember.isPresent(heading) ? parseFloat(heading) : null;
      speed = Ember.isPresent(speed) ? parseFloat(speed) : null;
      odo = Ember.isPresent(odo) ? parseFloat(odo) : null;
      timestamp = Ember.isPresent(timestamp) ? new Date(timestamp) : null;
      const vehicle = store.peekRecord(_environment.default.APP.avlmLite ? 'avlm-vehicle' : 'vehicle', id);
      if (Ember.isNone(vehicle)) return;

      if (vehicle.get('avlLocation')) {
        vehicle.get('avlLocation').setProperties({
          lat,
          lng,
          heading,
          speed,
          odo,
          timestamp
        });
      } else {
        vehicle.set('avlLocation', store.createFragment('avl-location', {
          lat,
          lng,
          heading,
          speed,
          odo,
          timestamp
        }));
      }
    },

    _createDiagnosticPacketPayload(payload) {
      const digest = (0, _md.default)(JSON.stringify(payload));
      if (this._digestLookup[digest]) return;
      this._digestLookup[digest] = true;
      return {
        data: {
          id: digest,
          type: 'packet',
          attributes: {
            actionType: payload.action,
            topic: payload.model,
            message: payload.message,
            timestamp: (0, _moment.default)().format()
          }
        }
      };
    },

    sendGlobalFilter(startDate, endDate, selectedProviders, startDateUTC, endDateUTC, scheduleId) {
      const alreadyConnected = this.get('isConnected');

      if (alreadyConnected) {
        this.get('filterSocket').sendGlobalFilter(startDate, endDate, selectedProviders, startDateUTC, endDateUTC, scheduleId);
      }
    },

    sendWidgetFilter(widgetInstanceId, modelName, filter, offset, limit, sort) {
      const alreadyConnected = this.get('isConnected');

      if (alreadyConnected) {
        this.get('filterSocket').sendWidgetFilter(widgetInstanceId, modelName, filter, offset, limit, sort);
      }
    }

  });

  _exports.default = _default;
});