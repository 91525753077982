define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/vehicle/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALIDATIONS = void 0;
  const VALIDATIONS = {
    name: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Vehicle ID',
      message: '{description} is required'
    })],
    modelYear: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Year',
      message: '{description} is required'
    })],
    vehicleType: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Vehicle Type',
      message: '{description} is required'
    })],
    startGarage: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Start Garage',
      message: '{description} is required'
    })],
    endGarage: [(0, _validators.validatePresence)({
      presence: true,
      description: 'End Garage',
      message: '{description} is required'
    })]
  };
  _exports.VALIDATIONS = VALIDATIONS;
});