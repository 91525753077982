define("adept-iq/pods/components/timeline-picker/relative-time-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['timeline-relative-time-picker', 'data-test-timeline-relative-time-picker'],
    classNameBindings: ['isValid', 'isInvalid', 'isDirty'],
    value: null,
    isValid: Ember.computed('value', function () {
      // The <input type="number"> will return '' when the input is invalid.
      // The <input type="number"> will allow floating point numbers: decimal point and engineering notation.
      return /^[0-9]+$/.test(this.get('value'));
    }),
    isInvalid: Ember.computed.not('isValid'),
    actions: {
      setValue() {
        const val = this.$('input').val();
        this.set('value', val);
        this.set('isDirty', true);
      },

      update(event) {
        if (event.key === 'Enter' || event.type === 'blur') {
          this.set('isDirty', false);

          if (this.get('isValid')) {
            this.sendAction('onUpdateValue', this.get('value')); // eslint-disable-line ember/closure-actions
          } else {
            this.sendAction('onUpdateValue', null); // eslint-disable-line ember/closure-actions
          }
        }
      }

    }
  });

  _exports.default = _default;
});