define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/provider/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "moment", "adept-iq/utils/unwrapProxy", "lodash", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/provider/validation", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _moment, _unwrapProxy, _lodash, _validation, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_PROVIDER_TYPE_MAP = {
    addDriverProvider: true,
    editDriverProvider: true,
    deleteDriverProvider: true
  };
  const PROVIDER_FIELDS = ['classroomTrainingDate', 'refresherTrainingDate', 'sensitivityTrainingDate', 'pointsReductionTraining', 'firstAidTrainingDate', 'employmentStartDate', 'employmentEndDate'];
  const ACTIVE_STATUS = 'active';

  var _default = Ember.Component.extend({
    classNames: ['driver-provider-view'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    tableRef: null,
    activityProviderTableRef: null,
    providerNotesTableRef: null,
    disableForm: true,
    disableNoteForm: true,
    disableProviderNoteAddBtn: false,
    // placeholders
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    NOTES_PLACEHOLDER: _placeholders.NOTES_PLACEHOLDER,
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    // passed into view
    editAPI: null,
    selectedDriver: Ember.computed.readOnly('editAPI.selectedRecord'),
    selectedProvider: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    selectedProviderNote: Ember.computed.readOnly('providerNotesTableRef.checkedRows.firstObject'),
    selectedRowNote: Ember.computed.alias('providerNotesTableRef.table.selectedRows.firstObject'),
    isDriverProviderNew: Ember.computed.readOnly('driverProviderChangeSet.isNew'),
    isNewProvider: Ember.computed.readOnly('editAPI.newBtn'),
    noNotes: Ember.computed('selectedRowNote', function () {
      const notes = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRowNote'));
      if (notes) return false;
      return true;
    }),
    isnewprovidernewnotes: Ember.computed('driverProviderChangeSet.data', function () {
      const driverProvider = this.get('driverProviderChangeSet.data');
      if (driverProvider && driverProvider.id) return false;
      return true;
    }),
    isEdited: false,
    isNewProviderNote: false,
    newDriverProviderNotes: null,
    deletedDriverProviderNotes: null,

    async init() {
      this._super(...arguments);

      const driver = this.get('selectedDriver');
      this.set('providerQueryParams', {
        filter: `eq(driverId,${driver.get('id')})`
      });
      this.set('activityQueryParams', {
        driverId: `${driver.get('id')}`,
        eventLog: true
      });
      this.get('refreshTask').perform().then(() => {
        Ember.run.later(() => {
          this.refreshProviderNoteTable();
        }, 1000);
      });
      this.get('setupDropDowns').perform();
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onSaveDriverRecord = this.get('onSaveDriverProviderRecord');
      const tableRef = this.get('tableRef');
      const onDeleteDriverProviderRecord = this.get('onDeleteDriverProviderRecord');
      const onValidateAction = this.get('validateDriverProvider'); // normal functions

      const onUndoDriverRecordFun = this.get('onUndoDriverRecord').bind(this);
      const onNewDriverProviderRecord = this.get('onNewDriverProviderRecord').bind(this);
      const onEditDriverProviderRecord = this.get('onEditDriverProviderRecord').bind(this);
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverRecord,
          newAction: onNewDriverProviderRecord,
          deleteAction: onDeleteDriverProviderRecord,
          editAction: onEditDriverProviderRecord,
          undoAction: onUndoDriverRecordFun,
          selectedDriverBelongsTableRef: tableRef,
          hasChanges,
          onValidateAction,
          isChangesHappened: false
        });
      });
      this.addObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
      this.get('refreshTask').perform().then(() => {
        Ember.run.later(() => {
          this.resetForm();
        }, 1000);
      });
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedProvider = this.get('selectedProvider');

      if (selectedProvider) {
        if (!selectedProvider.get('isNew') && !selectedProvider.get('isDeleted')) {
          yield selectedProvider.reload();
        }
      }

      const tableRef = this.get('tableRef');
      const activityProviderTableRef = this.get('activityProviderTableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      if (activityProviderTableRef) Ember.run.scheduleOnce('afterRender', activityProviderTableRef, 'refreshData');
    }),

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_PROVIDER_TYPE_MAP[event.get('actionType')];
    },

    driverProviderChangeSet: Ember.computed('selectedProvider', function () {
      const provider = (0, _unwrapProxy.unwrapProxy)(this.get('selectedProvider'));
      const driverProviderChangeSet = this.setDefaultProperties(provider);
      this.refreshProviderNoteTable();
      return driverProviderChangeSet;
    }),
    hasOverallPendingChanges: Ember.computed('driverProviderNoteChangeSet.changes.length', 'driverProviderChangeSet.changes.length', 'newDriverProviderNotes.[]', function () {
      const driverProviderChangeSet = this.get('driverProviderChangeSet');
      const providerChanges = driverProviderChangeSet.get('changes') || [];
      const noteChanges = this.get('driverProviderNoteChanges');
      const newDriverProviderNotes = this.get('newDriverProviderNotes');
      return noteChanges + providerChanges.length + newDriverProviderNotes.length;
    }),
    driverProviderNoteChanges: Ember.computed('driverProviderNoteChangeSet.changes.length', 'disableNoteForm', function () {
      const driverProviderNoteChangeSet = this.get('driverProviderNoteChangeSet');
      const noteChanges = driverProviderNoteChangeSet.get('changes') || [];
      const disableNoteForm = this.get('disableNoteForm');
      return !disableNoteForm && noteChanges.length;
    }),

    hasChanges() {
      const driverProviderChangeSet = this.get('driverProviderChangeSet');

      if (driverProviderChangeSet.get('isNew')) {
        const pendingChanges = this.get('driverProviderChangeSet');
        const noteChanges = this.get('driverProviderNoteChanges');
        const newDriverProviderNotes = this.get('newDriverProviderNotes');
        const driverProviderNoteChangeSet = this.get('driverProviderNoteChangeSet');
        const fields = (0, _lodash.flatten)(pendingChanges.changes).map(change => change.key);

        if (noteChanges) {
          const noteFields = (0, _lodash.flatten)(driverProviderNoteChangeSet.changes).map(change => change.key);
          fields.push(...noteFields);
        } else if (newDriverProviderNotes.length > 0) {
          fields.push('notes');
        }

        const isEveyPrePoulatedFieldsChange = fields.length ? (0, _lodash.every)(fields, field => PROVIDER_FIELDS.includes(field)) : false;
        if (isEveyPrePoulatedFieldsChange) return !isEveyPrePoulatedFieldsChange;
        return !!this.get('hasOverallPendingChanges');
      }

      return !!this.get('hasOverallPendingChanges');
    },

    hasPendingChangesObserver() {
      const editAPI = this.get('editAPI');
      const hasOverallPendingChanges = this.get('hasOverallPendingChanges');
      Ember.run.scheduleOnce('afterRender', () => {
        editAPI.setProperties({
          isChangesHappened: hasOverallPendingChanges
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
    },

    refreshProviderNoteTable() {
      const providerNotesTableRef = this.get('providerNotesTableRef');
      if (providerNotesTableRef) Ember.run.scheduleOnce('afterRender', providerNotesTableRef, 'refreshData');
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const driver = this.get('selectedDriver');
      let pendingDriverProvider = this.get('pendingDriverProvider');
      const isNewProvider = this.get('isNewProvider');
      const defaultDate = isNewProvider ? new Date() : null;

      if (record) {
        pendingDriverProvider = record;
        this.resetForm();
      } else if (!pendingDriverProvider) {
        pendingDriverProvider = store.createRecord('driver-provider', {
          driver
        });
        this.set('pendingDriverProvider', pendingDriverProvider);
      }

      if (pendingDriverProvider.get('isNew')) {
        pendingDriverProvider.setProperties({
          provider: null,
          employStatusName: null,
          positionName: null,
          classroomTrainingDate: defaultDate,
          refresherTrainingDate: defaultDate,
          sensitivityTrainingDate: defaultDate,
          pointsReductionTraining: defaultDate,
          firstAidTrainingDate: defaultDate,
          employmentStartDate: defaultDate,
          employmentEndDate: defaultDate
        });
        this.set('newDriverProviderNotes', []);
        this.set('deletedDriverProviderNotes', []);
      }

      this.set('showValidations', false);
      this.set('providerNoteQueryParams', {
        filter: `eq(driverProviderId,${pendingDriverProvider.get('id')})`
      });
      return new _emberChangeset.default(pendingDriverProvider, (0, _emberChangesetValidations.default)(_validation.DRIVER_PROVIDER_VALIDATION), _validation.DRIVER_PROVIDER_VALIDATION);
    },

    driverProviderNoteChangeSet: Ember.computed('selectedProviderNote', 'isNewProviderNote', function () {
      const provider = (0, _unwrapProxy.unwrapProxy)(this.get('selectedProviderNote'));
      return this.setProviderNoteDefaultProperties(provider);
    }),

    setProviderNoteDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      let pendingDriverProviderNote = this.get('pendingDriverProviderNote');

      if (record) {
        pendingDriverProviderNote = record;
      } else if (!pendingDriverProviderNote) {
        pendingDriverProviderNote = store.createRecord('driver-provider-note');
        this.set('pendingDriverProviderNote', pendingDriverProviderNote);
      }

      if (pendingDriverProviderNote.get('isNew')) {
        pendingDriverProviderNote.setProperties();
      }

      this.set('showValidations', false);
      return new _emberChangeset.default(pendingDriverProviderNote, (0, _emberChangesetValidations.default)(_validation.DRIVER_PROVIDER_NOTES_VALIDATION), _validation.DRIVER_PROVIDER_NOTES_VALIDATION);
    },

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        const records = this.get('tableRef.records');

        if (!records.length) {
          editAPI.buttonSettings(true, false, true, true, true);
        } else {
          editAPI.buttonSettings();
        }
      });
    },

    setupDropDowns: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const providerOptions = this.get('store').peekAll('provider').filter(provider => {
        return provider.get('userCanAccessProvider') && provider.get('status') && provider.get('status').toLowerCase() === ACTIVE_STATUS;
      });
      this.set('providerOptions', providerOptions);
      const driverPositionOptions = yield store.findAll('driver-position-name');
      this.set('driverPositionOptions', driverPositionOptions);
      const driverStatusOptions = yield store.findAll('employment-status-name');
      this.set('driverStatusOptions', driverStatusOptions);
      const driverTerminationOptions = yield store.findAll('termination-reason-name');
      this.set('driverTerminationOptions', driverTerminationOptions);
    }),
    // Driver Provider CRUD OPERATIONS
    onSaveDriverProviderRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const selectedDriver = this.get('selectedDriver');
      const pendingDriverProvider = this.get('driverProviderChangeSet');
      const pendingDriverProviderNote = this.get('driverProviderNoteChangeSet');
      const tooltip = this.get('tooltip'); // validation

      const isValidDriverProvider = yield this.validateDriverProvider.perform();

      if (isValidDriverProvider) {
        pendingDriverProvider.set('driver', selectedDriver);

        try {
          yield pendingDriverProvider.save();
          const driverProvider = this.get('driverProviderChangeSet.data');

          if (driverProvider && driverProvider.id) {
            const newDriverProviderNotes = this.get('newDriverProviderNotes');
            const deletedDriverProviderNotes = this.get('deletedDriverProviderNotes');
            newDriverProviderNotes.forEach(driverProviderNote => {
              driverProviderNote.set('driverProvider', driverProvider);
              driverProviderNote.save();
            });
            deletedDriverProviderNotes.forEach(driverProviderNote => {
              driverProviderNote.save();
            });
          }

          notifications.success('Record successfully saved.');
          this.set('pendingDriverProvider', null);
          this.resetPendingDriverProviderNote();
          this.set('driverProviderNoteChangeSet.notes', null);
          this.set('disableNoteForm', true);
          this.set('newDriverProviderNotes', []);
          this.set('deletedDriverProviderNotes', []);

          if (pendingDriverProviderNote) {
            pendingDriverProviderNote.rollback();
          }

          Ember.run.later(() => {
            this.refreshProviderNoteTable();
            this.get('refreshTask').perform().then(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
              this.resetForm();
              tooltip.reset();
            });
          }, 1000);
          return pendingDriverProvider;
        } catch (e) {
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line
        }
      }
    }),

    onUndoDriverProviderRecord() {
      const driverProviderChangeSet = this.get('driverProviderChangeSet');

      if (driverProviderChangeSet) {
        driverProviderChangeSet.rollback();
      }
    },

    onUndoDriverRecord() {
      this.onUndoDriverProviderRecord();
      this.onUndoDriverProviderNoteRecord();
    },

    onNewDriverProviderRecord() {
      const selectedRow = this.get('selectedRow');
      const defaultDate = new Date();

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.get('editAPI').buttonSettings(true, true, true, false, false); //False to enable the button, TRUE to disable

      const driverLogChangeSet = this.get('driverProviderChangeSet');
      driverLogChangeSet.setProperties({
        classroomTrainingDate: defaultDate,
        refresherTrainingDate: defaultDate,
        sensitivityTrainingDate: defaultDate,
        pointsReductionTraining: defaultDate,
        firstAidTrainingDate: defaultDate,
        employmentStartDate: defaultDate,
        employmentEndDate: defaultDate
      });
      this.set('disableForm', false);
      this.set('disableProviderNoteAddBtn', false);
    },

    onEditDriverProviderRecord() {
      const selectedRow = this.get('selectedRow');
      if (Ember.isEmpty(selectedRow)) return;
      this.set('disableForm', false);
      this.set('disableProviderNoteAddBtn', false);
      this.get('editAPI').buttonSettings(true, true, true, false, false);
    },

    onDeleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedProvider = this.get('selectedProvider');
      const notifications = this.get('notifications');

      try {
        yield selectedProvider.destroyRecord();
        notifications.success('Record successfully deleted');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.resetForm();
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
          });
        }, 1000);
      } catch (e) {
        notifications.warning('Record failed to delete');
        console.warn(e); // eslint-disable-line
      }
    }),
    onDeleteDriverProviderRecord: (0, _emberConcurrency.task)(function* () {
      const selectedProvider = this.get('selectedProvider');
      const tooltip = this.get('tooltip');
      if (!selectedProvider) return;
      yield tooltip.pushConfirmation({
        title: 'Manage Driver',
        tip: 'Are you sure you want to delete this record?',
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        },

        primaryActionText: 'Confirm',
        primaryAction: () => {
          this.get('onDeleteRecord').perform();
          return tooltip.reset();
        }
      });
    }),
    validateDriverProvider: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverProviderChangeSet = this.get('driverProviderChangeSet');
      yield driverProviderChangeSet.validate();
      const {
        incidentDate,
        licenseMonitorDate,
        convictionDate,
        convictionNotificationDate
      } = driverProviderChangeSet.getProperties('incidentDate', 'licenseMonitorDate', 'convictionDate', 'convictionNotificationDate');

      if (licenseMonitorDate && incidentDate) {
        if ((0, _moment.default)(licenseMonitorDate).isSameOrBefore(incidentDate)) {
          driverProviderChangeSet.addError('licenseMonitorDate', {
            value: licenseMonitorDate,
            validation: 'License Monitor Date must be on or after the Incident Date.'
          });
        }
      }

      if (convictionDate && incidentDate) {
        if ((0, _moment.default)(convictionDate).isSameOrBefore(incidentDate)) {
          driverProviderChangeSet.addError('convictionDate', {
            value: convictionDate,
            validation: 'The Conviction Date must be on or after the Incident Date.'
          });
        }
      }

      if (convictionNotificationDate && convictionDate) {
        if ((0, _moment.default)(convictionNotificationDate).isSameOrBefore(convictionDate)) {
          driverProviderChangeSet.addError('convictionNotificationDate', {
            value: convictionNotificationDate,
            validation: 'The Conviction Notification Date must be on or after the Conviction Date. '
          });
        }
      }

      const driverProviderValid = driverProviderChangeSet.errors;
      const errors = driverProviderValid;

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.reset();
          }

        });
        return false;
      }

      return true;
    }),

    // Driver Provider Note Operations
    async onSaveProviderNotesRecord() {
      const driverProviderNoteChangeSet = this.get('driverProviderNoteChangeSet');
      const newDriverProviderNotes = this.get('newDriverProviderNotes');
      const driverProviderNote = driverProviderNoteChangeSet.get('data');
      const isValidDriverProviderNote = await this.validateDriverProviderNote.perform();
      if (!isValidDriverProviderNote) return;
      driverProviderNoteChangeSet.execute();
      if (!newDriverProviderNotes.includes(driverProviderNote)) newDriverProviderNotes.pushObject(driverProviderNote);
      this.set('isEdited', false);
      this.set('isNewProviderNote', false);
      this.resetPendingDriverProviderNote();
      this.set('providerNotesTableRef.config.selectFirstRow', true);
      this.set('disableNoteForm', true);
      this.set('disableProviderNoteAddBtn', false);
      this.refreshProviderNoteTable();
    },

    onUndoDriverProviderNoteRecord() {
      const driverProviderNoteChangeSet = this.get('driverProviderNoteChangeSet');
      const newDriverProviderNotes = this.get('newDriverProviderNotes');
      const deletedDriverProviderNotes = this.get('deletedDriverProviderNotes');

      if (driverProviderNoteChangeSet) {
        driverProviderNoteChangeSet.rollback();
      }

      if (newDriverProviderNotes) {
        const filterDriverProviderNotes = newDriverProviderNotes.filter(driverProvider => Ember.isPresent(driverProvider.get('id')));
        this.set('newDriverProviderNotes', filterDriverProviderNotes);
        deletedDriverProviderNotes.forEach(driverProvider => {
          driverProvider.rollbackAttributes();
        });
        this.set('deletedDriverProviderNotes', []);
        this.refreshProviderNoteTable();
      }
    },

    onNewDriverProviderNoteRecord() {
      const selectedRow = this.get('selectedRowNote');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('providerNotesTableRef.config.selectFirstRow', false);
      }

      this.set('disableNoteForm', false);
      this.set('disableProviderNoteAddBtn', true);
      this.set('isNewProviderNote', true);
      this.resetPendingDriverProviderNote();
      this.get('editAPI').buttonSettings(true, true, true, false, false); //False to enable the button, TRUE to disable
    },

    onEditDriverProviderNoteRecord() {
      const selectedRow = this.get('selectedRowNote');
      if (Ember.isEmpty(selectedRow)) return;
      this.set('isEdited', true);
      this.set('disableNoteForm', false);
      this.get('editAPI').buttonSettings(true, true, true, false, false);
    },

    onDeleteDriverProviderNoteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedProviderNote = this.get('selectedProviderNote');
      const notifications = this.get('notifications');
      const tooltip = this.get('tooltip');
      const newDriverProviderNotes = this.get('newDriverProviderNotes');
      const deletedDriverProviderNotes = this.get('deletedDriverProviderNotes');
      const refreshProviderNoteTable = this.get('refreshProviderNoteTable').bind(this);
      if (!selectedProviderNote) return;
      yield tooltip.pushConfirmation({
        title: 'Manage Driver',
        tip: 'Are you sure you want to delete this record?',
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        },

        primaryActionText: 'Confirm',

        async primaryAction() {
          try {
            await selectedProviderNote.deleteRecord();
            const deletedNote = newDriverProviderNotes.find(note => note.get('notes') === selectedProviderNote.get('notes'));
            newDriverProviderNotes.removeObject(deletedNote);
            if (selectedProviderNote.get('id')) deletedDriverProviderNotes.pushObject(selectedProviderNote);
            notifications.success('Record successfully deleted');
            refreshProviderNoteTable();
          } catch (e) {
            notifications.warning('Record failed to delete');
          }

          return tooltip.reset();
        }

      });
    }),

    resetPendingDriverProviderNote() {
      this.set('pendingDriverProviderNote', null);
    },

    validateDriverProviderNote: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverProviderNoteChangeSet = this.get('driverProviderNoteChangeSet');
      yield driverProviderNoteChangeSet.validate();
      const driverProviderValid = driverProviderNoteChangeSet.errors;
      const errors = driverProviderValid;

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      return true;
    }),
    actions: {
      onSaveProviderRecord() {
        return this.onSaveDriverProviderRecord.perform();
      },

      onPowerSelectChange(valuePath, selected) {
        this.set(valuePath, selected);
      },

      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onDateValueChange(record, valuePath, value) {
        const isoDate = (0, _moment.default)(value).toISOString();
        record.set(valuePath, isoDate);
      }

    }
  });

  _exports.default = _default;
});