define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/driver/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DRIVER_VALIDATION = _exports.DRIVER_PROVIDER_VALIDATION = _exports.DRIVER_PHONE_VALIDATION = _exports.DRIVER_LICENSE_VALIDATION = _exports.DRIVER_EMERGENCY_CONTACT_VALIDATION = void 0;
  const DRIVER_VALIDATION = {
    firstName: [(0, _validators.validatePresence)({
      presence: true,
      description: 'First Name',
      message: '{description} is required.'
    })],
    lastName: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Last Name',
      message: '{description} is required.'
    })],
    ssn: [(0, _validators.validateLength)({
      is: 11,
      allowBlank: true,
      description: 'Social Security Number',
      message: '{description} 9 digit is required.'
    })]
  };
  _exports.DRIVER_VALIDATION = DRIVER_VALIDATION;
  const DRIVER_PHONE_VALIDATION = {
    unformattedPhoneNumber: [(0, _validators.validateLength)({
      is: 10,
      allowBlank: true,
      description: 'Phone Number',
      message: '{description} 10 digit is required.'
    })]
  };
  _exports.DRIVER_PHONE_VALIDATION = DRIVER_PHONE_VALIDATION;
  const DRIVER_LICENSE_VALIDATION = {
    license: [(0, _validators.validateLength)({
      is: 11,
      allowBlank: true,
      description: 'Number',
      message: '{description} 9 digit is required.'
    })]
  };
  _exports.DRIVER_LICENSE_VALIDATION = DRIVER_LICENSE_VALIDATION;
  const DRIVER_EMERGENCY_CONTACT_VALIDATION = {
    unformattedPhoneNumber: [(0, _validators.validateLength)({
      is: 10,
      allowBlank: true,
      description: 'Phone Number',
      message: '{description} 10 digit is required.'
    })]
  };
  _exports.DRIVER_EMERGENCY_CONTACT_VALIDATION = DRIVER_EMERGENCY_CONTACT_VALIDATION;
  const DRIVER_PROVIDER_VALIDATION = {
    'provider': [(0, _validators.validatePresence)({
      presence: true,
      description: 'Provider',
      message: '{description} is required.'
    })]
  };
  _exports.DRIVER_PROVIDER_VALIDATION = DRIVER_PROVIDER_VALIDATION;
});