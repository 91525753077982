define("adept-iq/pods/components/generic-components/table/classes/checkbox-row", ["exports", "ember-light-table/classes/Row"], function (_exports, _Row) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CheckboxRow extends _Row.default {}

  _exports.default = CheckboxRow;
  CheckboxRow.reopen({
    isLastChecked: false,
    isSelected: false,
    isAlertCheckBox: false,
    selected: false,
    isPreviouslyChecked: false,
    record: Ember.computed.alias('content'),
    id: Ember.computed.alias('content.id'),
    isChecked: Ember.computed.readOnly('selected')
  });
});