define("adept-iq/tests/factories/provider", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const providerNames = ['MAC', 'EPC', 'AMG'];

  _emberDataFactoryGuy.default.define('provider', {
    sequences: {
      names: num => providerNames[num % providerNames.length]
    },
    default: {
      name: _emberDataFactoryGuy.default.generate('names')
    }
  });
});