define("adept-iq/pods/components/workspace-navbar/notification-results/component", ["exports", "ember-concurrency", "moment", "lodash"], function (_exports, _emberConcurrency, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notificationInitiationService: Ember.inject.service('notification-initiation'),
    workspace: Ember.inject.service(),
    showInProgress: true,
    showSuccessful: true,
    showFailed: true,
    responseSourceIds: null,
    showDialog: false,
    onClose: null,
    onResend: null,
    notificationResponseRecords: null,

    init() {
      this._super(...arguments);

      const responseSourceIds = this.get('responseSourceIds');
      const showDialog = this.get('showDialog');

      if (_lodash.default.isNil(responseSourceIds) && showDialog) {
        this.reloadNotificationResponses.perform();
      }
    },

    filteredNotificationResponses: Ember.computed('notificationResponses', 'showInProgress', 'showSuccessful', 'showFailed', function () {
      const notificationResponses = this.get('notificationResponses');
      const showInProgress = this.get('showInProgress');
      const showSuccessful = this.get('showSuccessful');
      const showFailed = this.get('showFailed');
      const filteredNotificationResponses = notificationResponses.filter(notificationResponse => {
        switch (notificationResponse.status) {
          case 'In Progress':
            return showInProgress;

          case 'Successful':
            return showSuccessful;

          case 'Failed':
            return showFailed;

          default:
            return false;
        }
      });
      return filteredNotificationResponses;
    }),
    notificationResponses: Ember.computed('responseSourceIds', function () {
      const responseSourceIds = this.get('responseSourceIds', {});

      const notificationResponses = _lodash.default.flatMap(responseSourceIds, (responses, sourceId) => {
        return _lodash.default.compact(_lodash.default.map(responses, response => {
          if (response.status.code === 'errorResent' || _lodash.default.isNil(_lodash.default.get(response, 'destination', null))) {
            return null;
          }

          const notificationResponse = this.createNotificationResponseFromResponseSourceId(response, sourceId);
          return notificationResponse;
        }));
      });

      return notificationResponses;
    }),
    count: Ember.computed('notificationResponses', function () {
      const notificationResponses = this.get('notificationResponses');

      const statusCounts = _lodash.default.countBy(notificationResponses, 'status');

      return {
        total: _lodash.default.size(notificationResponses),
        inProgress: _lodash.default.defaultTo(statusCounts['In Progress'], 0),
        successful: _lodash.default.defaultTo(statusCounts.Successful, 0),
        failed: _lodash.default.defaultTo(statusCounts.Failed, 0)
      };
    }),
    isResendDisabled: Ember.computed('count', function () {
      const count = this.get('count');
      return count.inProgress > 0 || count.inProgress === 0 && count.failed === 0;
    }),

    createNotificationResponseFromResponseSourceId(response, sourceId) {
      const parsedSourceId = JSON.parse(sourceId);

      const riderId = _lodash.default.join(parsedSourceId.externalRiderIds, ',');

      const status = this.formatStatusCode(response.status.code);

      const errorMessage = _lodash.default.get(response, 'status.message', '');

      const destination = _lodash.default.join(_lodash.default.map(response.destination, singleDestination => this.formatPhoneNumber(singleDestination)), ',');

      return Ember.Object.create({
        riderId,
        destination,
        status,
        errorMessage
      });
    },

    formatStatusCode(statusCode) {
      switch (statusCode) {
        case 'error':
          return 'Failed';

        case 'complete':
          return 'Successful';

        case 'in-progress':
          return 'In Progress';

        default:
          return statusCode;
      }
    },

    formatPhoneNumber(phone) {
      if (!phone) {
        return;
      }

      const phoneNoStr = phone.toString().substring(0, 10).replace(/[^\d]/g, '');

      if (phoneNoStr.length === 10) {
        return phoneNoStr.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }

      return phoneNoStr;
    },

    reloadNotificationResponses: (0, _emberConcurrency.task)(function* () {
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        const notificationInitiationService = this.get('notificationInitiationService');
        const scheduleDate = (0, _moment.default)(this.get('workspace.currentSchedule.name'));
        const scheduleTag = `schedule-${(0, _moment.default)(scheduleDate).format('YYYY-MM-DD')}`;
        const newSourceIds = yield notificationInitiationService.getResponsesByTag('advanceTripReminderGroupedByTextToSpeech', scheduleTag);
        this.set('responseSourceIds', newSourceIds);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      } catch (error) {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    }).drop(),
    actions: {
      onEligibleWarningClose() {
        this.get('onClose')();
      },

      onResend() {
        this.get('onResend')();
      },

      onRefresh() {
        this.get('reloadNotificationResponses').perform(this.get('scheduleDate'));
      },

      onClose() {
        this.get('onClose')();
      }

    }
  });

  _exports.default = _default;
});