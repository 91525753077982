define("adept-iq/pods/components/side-drawer/system-config/ada-boundaries/ada-boundary-information-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/side-drawer/system-config/ada-boundaries/ada-boundary-information-table/config", "lodash", "adept-iq/utils/sorts", "adept-iq/utils/filters"], function (_exports, _component, _config, _lodash, _sorts, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['ada-boundary-information-table'],
    config: _config.default,
    adaService: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const store = this.get('store');
      const queryParams = this.get('queryParams') || {};

      if ((0, _lodash.isEmpty)(queryParams)) {
        return [];
      }

      const activeData = await store.query(modelName, queryParams);
      return activeData.filter(filterFn).sort(compareFn);
    },

    actions: {
      onRowClick(currentRow) {
        this._super(currentRow); // there will be only one checkedRow so we can use firstObject


        const calendarId = this.get('tableRef.checkedRows.firstObject.id');
        this.get('adaService').setActiveCalender(calendarId);
      }

    }
  });

  _exports.default = _default;
});