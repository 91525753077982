define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/driver-violations/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'driver-violation',
    title: 'Violations',
    defaultSortId: 'id',
    defaultSortAsc: true,
    selectFirstRow: true,
    queryModelData: true,
    tooltipTitle: 'Manage Driver',
    columns: [{
      id: 'driverCdlNumber',
      index: 1,
      type: 'string',
      label: 'Driver CDL Number',
      valuePath: 'driverLicense.license',
      defaultWidth: 135,
      searchable: true
    }, {
      id: 'incidentDate',
      index: 2,
      type: 'date',
      label: 'Incident Date',
      valuePath: 'incidentDate',
      defaultWidth: 70,
      searchable: true,
      format: `${_environment.default.dateTimeFormat.dateMoment}`
    }, {
      id: 'licenseMonitorDate',
      index: 3,
      type: 'date',
      label: 'License Monitor Date',
      valuePath: 'licenseMonitorDate',
      defaultWidth: 70,
      searchable: true,
      format: `${_environment.default.dateTimeFormat.dateMoment}`
    }, {
      id: 'description',
      index: 4,
      type: 'text',
      label: 'Description',
      valuePath: 'description',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'convictionDate',
      index: 5,
      type: 'date',
      label: 'Conviction Date',
      valuePath: 'convictionDate',
      defaultWidth: 70,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }, {
      id: 'convictionNotificationDate',
      index: 6,
      type: 'date',
      label: 'Conviction Notification Date',
      valuePath: 'convictionNotificationDate',
      defaultWidth: 70,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }, {
      id: 'points',
      index: 7,
      type: 'number',
      label: 'Points',
      valuePath: 'points',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'vehicleId',
      index: 8,
      type: 'text',
      label: 'Vehicle ID',
      valuePath: 'vehicle.name',
      defaultWidth: 100,
      searchable: true
    }]
  };
  _exports.default = _default;
});