define("adept-iq/pods/components/generic-widgets/form-widget/section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['generic-panel'],
    classNameBindings: ['isCollapsible', 'isInitial', 'isCollapsed', 'isExpanded'],
    title: null,
    section: null,
    isCollapsible: false,
    collapseState: 'initial',
    isInitial: Ember.computed.equal('collapseState', 'initial'),
    isCollapsed: Ember.computed.equal('collapseState', 'collapsed'),
    isExpanded: Ember.computed.equal('collapseState', 'expanded'),

    toggleCollapse() {
      const collapseState = this.get('collapseState');

      if (collapseState === 'collapsed') {
        this.set('collapseState', 'expanded');
      } else {
        this.set('collapseState', 'collapsed');
      }
    },

    actions: {
      onHeaderClick() {
        if (this.get('isCollapsible')) this.toggleCollapse();
      }

    }
  });

  _exports.default = _default;
});