define("adept-iq/pods/components/workspace-navbar/notification-results/notification-responses-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/workspace-navbar/notification-results/notification-responses-table/config", "lodash"], function (_exports, _component, _config, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['notification-response-table'],
    config: _config.default,
    store: Ember.inject.service(),
    hasPendingChanges: false,
    enableWarningRowChangeAlert: false,
    notificationResponses: null,

    init() {
      this._super(...arguments);
    },

    updateNotificationResponseRecords: Ember.observer('notificationResponses', function () {
      this.refreshData();
    }),

    fetchDataQuery() {
      return this.query();
    },

    async query() {
      const searchText = this.get('searchText');
      const notificationResponses = this.get('notificationResponses');

      const filteredNotificationResponses = _lodash.default.filter(notificationResponses, notificationResponse => {
        if (_lodash.default.size(searchText) > 0) {
          const textFound = _lodash.default.find(notificationResponse, value => {
            return value.includes(searchText);
          });

          if (_lodash.default.isNil(textFound)) {
            return null;
          }
        }

        return true;
      });

      const state = this.get('state');
      const tableConfig = this.get('config');

      const sortField = _lodash.default.get(state, 'sortId', _lodash.default.get(tableConfig, 'defaultSortId'));

      const asc = _lodash.default.get(state, 'sortAsc', _lodash.default.get(tableConfig, 'defaultSortAsc'));

      const allSortFields = ['riderId', 'destination', 'status', 'errorMessage'];

      const otherSortFields = _lodash.default.filter(allSortFields, allSortField => allSortField !== sortField);

      const sortFields = _lodash.default.concat([sortField], otherSortFields);

      const sortedNotificationResponses = _lodash.default.sortBy(filteredNotificationResponses, sortFields);

      if (!asc) {
        return _lodash.default.reverse(sortedNotificationResponses);
      }

      this.set('records', sortedNotificationResponses);
      return sortedNotificationResponses;
    }

  });

  _exports.default = _default;
});