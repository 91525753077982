define("adept-iq/serializers/reconcile-stop", ["exports", "adept-iq/serializers/-reconciliationService"], function (_exports, _reconciliationService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reconciliationService.default.extend({
    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    pushPayloads(store, item) {
      if (!Ember.isEmpty(item.attributes.address.id)) {
        store.pushPayload({
          data: {
            id: item.id,
            type: 'reconcile-address',
            attributes: { ...item.attributes.address,
              addressId: item.id,
              stopId: item.id
            }
          }
        });
      }
    },

    normalizeQueryResponse(store, clazz, payload) {
      // workaround for this API not returns payload type
      const resultedPayload = [];

      const _data = [].concat(payload.data);

      payload.data = _data;

      if (!Ember.isEmpty(payload.data)) {
        payload.data.forEach(item => {
          this.pushPayloads(store, item);
          item.attributes.stopId = item.id;
          resultedPayload.push(item);
        });
        payload.data = resultedPayload;
      }

      return this._super(store, clazz, payload);
    },

    modelNameFromPayloadKey(key) {
      if (key === 'stop') {
        return 'reconcile-stop';
      }

      return this._super(key);
    }

  });

  _exports.default = _default;
});