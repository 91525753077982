define("adept-iq/adapters/-ss-schedulingService", ["exports", "adept-iq/adapters/application", "adept-iq/config/api-urls"], function (_exports, _application, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    defaultSerializer: '-ss-schedulingService',
    host: _apiUrls.API.schedulingService.host,
    pathForType: function (type) {
      // trim 'ss' prefix from model type
      const regEx = /^(?:ss-)?(.*)$/;
      const match = regEx.exec(type)[1];
      return this._super(match);
    }
  });

  _exports.default = _default;
});