define("adept-iq/topic-transforms/polyline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload, ENV) {
    const transformedPayloads = [];
    if (!payload.data || !payload.data.relationships) return transformedPayloads;
    const {
      route,
      stopPoint
    } = payload.data.relationships;

    if (route && ENV.APP.avlmLite) {
      delete payload.data.relationships.route;
      payload.data.relationships.avlmRoute = route;
    }

    if (stopPoint && ENV.APP.avlmLite) {
      delete payload.data.relationships.stopPoint;
      payload.data.relationships.avlmStopPoint = stopPoint;
    }

    transformedPayloads.push(payload);
    return transformedPayloads;
  }
});