define("adept-iq/adapters/internal-ui-config", ["exports", "adept-iq/adapters/cs-config-lookup-model"], function (_exports, _csConfigLookupModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigLookupModel.default.extend({
    categoryId: 'config-System_Configuration-internal_ui_configs'
  });

  _exports.default = _default;
});