define("adept-iq/pods/components/table/cells/trip-eta/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EBBWAzSU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"moment-format\",[[23,[\"value\"]],\"HH:mm\"],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"displayTooltip\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"ember-tooltip\",null,[[\"side\",\"popperContainer\",\"text\"],[\"bottom\",\"body\",[23,[\"tooltipText\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/table/cells/trip-eta/template.hbs"
    }
  });

  _exports.default = _default;
});