define("adept-iq/services/api-context", ["exports", "lodash", "moment"], function (_exports, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_QUERY_PARAMETER_LIMIT = 800;

  var _default = Ember.Service.extend(Ember.Evented, {
    booking: Ember.inject.service(),
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    widgetService: Ember.inject.service('widget'),
    externalRiderSystemToken: null,

    async loadSubscriptionsForSelectedExternalRiders() {
      return new Promise(async (resolve, reject) => {
        try {
          const selectedRiders = this.get('booking.selectedRiders');
          const selectedIds = selectedRiders.map(r => r.get('riderId'));
          const ridersIdsClause = selectedIds.map(r => `'${r}'`).join(',');
          this.get('workspace').set('isGlobalSpinnerVisible', true);
          const ridersQuery = `in(riderId,(${ridersIdsClause}))&include=phoneNumbers`;
          const riders = await this.get('store').query('rider', {
            filter: ridersQuery
          });

          if (Ember.isBlank(riders)) {
            this.get('workspace').set('isGlobalSpinnerVisible', false);
            resolve([]);
          }

          const riderIDs = riders.map(function (a) {
            return a.get('id');
          });
          const riderIdsInClause = riderIDs.map(r => `'${r}'`).join(',');
          const subscriptionsByRiderIdQuery = `in(riderId,(${riderIdsInClause}))&include=travelNeeds,exclusions,recurrencePatterns,originPhoneNumber,destinationPhoneNumber`;
          const subscriptions = await this.get('store').query('subscription', {
            filter: subscriptionsByRiderIdQuery
          });
          const subscriptionIds = subscriptions.map(s => s.get('id'));
          const allPlaceIds = subscriptions.map(s => [s.get('origin.id'), s.get('destination.id')]);

          const placeIds = _lodash.default.flatMap(allPlaceIds);

          const unipIds = _lodash.default.uniq(placeIds);

          const placeIdsInClause = unipIds.map(p => `'${p}'`).join(',');
          const subscriptionIdsInClause = subscriptionIds.map(p => `'${p}'`).join(',');
          const placesQuery = `in(id,(${placeIdsInClause}))&include=address,location`;
          const subscriptionQuery = `in(subscriptionId,(${subscriptionIdsInClause}))&include=subscription`;
          await this.get('store').query('place', {
            filter: placesQuery
          });
          await this.get('store').query('subscription-service-need', {
            filter: subscriptionQuery
          });
          const filteredSubscriptions = this.filteredPastSubscriptionRecords(subscriptions);
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          resolve(filteredSubscriptions);
        } catch (e) {
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          reject(e);
        }
      });
    },

    async loadBookingsForSelectedExternalRiders() {
      const selectedRiders = this.get('booking.selectedRiders');
      const selectedIds = selectedRiders.map(r => r.get('id'));
      const selectedRiderIds = selectedRiders.map(r => r.get('riderId'));
      let bookingIDs = [];
      let riderIDs = [];
      let riderQuery;
      const ridersIdsClause = selectedRiderIds.map(r => `'${r}'`).join(',');
      return new Promise(async (resolve, reject) => {
        this.get('workspace').set('isGlobalSpinnerVisible', true);

        try {
          //NYAAR-18860 (fixing - travel needs is undefined(external API call issue))
          await this.get('booking').fetchExternalRidersAndSetToSelectedRiders(selectedIds);
          riderQuery = `in(riderId,(${ridersIdsClause}))`;
          const riders = await this.get('store').query('rider', {
            filter: riderQuery
          });

          if (Ember.isBlank(riders)) {
            this.get('workspace').set('isGlobalSpinnerVisible', false);
            resolve([[], []]); // first is bookings and second is trips

            return;
          }

          riderIDs = riders.map(function (a) {
            return a.get('id');
          });
          const riderIdsInClause = riderIDs.map(r => `'${r}'`).join(',');
          const bookingByRiderID = `in(legs.rider,(${riderIdsInClause}))&include=group,legs,legs.segments,legs.legTravelNeeds,legs.segments.pick,legs.segments.drop,subscription,cancellation&fields[booking]=id,status`;
          const bookings = await this.get('store').query('booking', {
            filter: bookingByRiderID
          });
          const currentDate = (0, _moment.default)().format('YYYY-MM-DD');
          const filteredBookings = bookings.filter(booking => {
            const tripStartTime = (0, _moment.default)(booking.tripStartTime).format('YYYY-MM-DD');
            return (0, _moment.default)(tripStartTime).isAfter(currentDate) || (0, _moment.default)(tripStartTime).isSame(currentDate);
          });
          let legsId = filteredBookings.map(function (bkng) {
            return bkng.get('legs').map(leg => leg.get('id'));
          });
          legsId = _lodash.default.flatten(legsId);
          const legIds = legsId.filter(l => Ember.isPresent(l)).map(l => `'${l}'`);
          await this.getLegServiceNeed(legIds);
          let segmentStopsId = bookings.map(function (bkng) {
            return bkng.get('legs').map(function (leg) {
              const segmentStops = leg.get('segment.stops');

              if (Ember.isPresent(segmentStops)) {
                return segmentStops.map(stop => stop.get('id'));
              }

              return null;
            });
          });
          segmentStopsId = _lodash.default.flattenDeep(segmentStopsId);
          const segmentStopsIds = segmentStopsId.filter(segmentStopId => Ember.isPresent(segmentStopId)).map(s => `'${s}'`);
          await this.getSegmentStopPhoneNumber(segmentStopsIds);
          bookingIDs = filteredBookings.map(function (bkng) {
            return bkng.get('id');
          });
          let placeIds = '';
          filteredBookings.forEach(booking => {
            const legsInBooking = booking.get('legs');
            legsInBooking.forEach(leg => {
              const segmentsInLeg = leg.get('segments');
              segmentsInLeg.forEach(segment => {
                if (Ember.isPresent(segment.get('pick.place.id'))) {
                  placeIds = placeIds + segment.get('pick.place.id') + ',';
                }

                if (Ember.isPresent(segment.get('drop.place.id'))) {
                  placeIds = placeIds + segment.get('drop.place.id') + ',';
                }
              });
            });
          });
          const query = 'in(\'id\',(' + placeIds.slice(0, placeIds.length - 1) + '))&include=address,location';
          await this.store.query('place', {
            filter: query
          });

          for (const booking of filteredBookings.toArray()) {
            if (booking.get('isGroupBooking')) {
              const bookin = await this.get('store').findRecord('booking', booking.get('id'));
              const legs = bookin.get('legs');
              const allRiderIds = legs.map(leg => {
                return leg.get('rider.id');
              });

              const riderIds = _lodash.default.uniq(allRiderIds).filter(rId => rId); // unpersisted record will have null


              const allRiders = await this.get('booking').fetchAndReturnRiders(riderIds);
              const riderExternalIds = allRiders.map(r => r.get('id'));
              await this.get('booking').fetchExternalRidersAndSetToSelectedRiders(riderExternalIds);
            }
          }

          const bookingIdsInClause = bookingIDs.map(bId => `'${bId}'`).join(',');

          if (!Ember.isEmpty(bookingIdsInClause)) {
            const bookingAndTrips = await this.loadDataOnBasiesOfTripID(null, bookingIdsInClause, bookings);
            const filteredBookingAndTrips = this.filteredBookingAndTrips(bookingAndTrips);
            resolve(filteredBookingAndTrips);
          } else {
            resolve([[], []]); // first is bookings and second is trips
          }

          this.get('workspace').set('isGlobalSpinnerVisible', false);
        } catch (error) {
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          reject(error);
        }
      });
    },

    async getSegmentStopPhoneNumber(segmentStopIds) {
      //when the query parameters are more than 800(i.e exactly 847) we will get 414 status error,so split them into parts
      if (segmentStopIds.length > MAX_QUERY_PARAMETER_LIMIT) {
        //splitting the given array using given size
        const splittedSegmentStopIds = _lodash.default.chunk(segmentStopIds, MAX_QUERY_PARAMETER_LIMIT);

        await splittedSegmentStopIds.reduce((acu, segmentStopIdValues) => {
          return acu.then(async () => {
            const segmentStopIdsInClause = segmentStopIdValues.join(',');
            const segmentStopPhoneNumberById = `in(segmentStopId,(${segmentStopIdsInClause}))&include=segmentStop`;
            await this.queryModelData('segment-stop-phone-number', segmentStopPhoneNumberById);
          });
        }, Promise.resolve());
        return;
      }

      const segmentStopIdsInClause = segmentStopIds.join(',');
      const segmentStopPhoneNumberById = `in(segmentStopId,(${segmentStopIdsInClause}))&include=segmentStop`;
      await this.queryModelData('segment-stop-phone-number', segmentStopPhoneNumberById);
    },

    async getLegServiceNeed(legIds) {
      //when the query parameters are more than 800(i.e exactly 847) we will get 414 status error,so split them into parts
      if (legIds.length > MAX_QUERY_PARAMETER_LIMIT) {
        //splitting the given array using given size
        const splittedLegIds = _lodash.default.chunk(legIds, MAX_QUERY_PARAMETER_LIMIT);

        await splittedLegIds.reduce((acu, legIdValues) => {
          return acu.then(async () => {
            const legIdsInClause = legIdValues.join(',');
            const legServiceNeedByLegId = `in(legId,(${legIdsInClause}))&include=leg`;
            await this.queryModelData('leg-service-need', legServiceNeedByLegId);
          });
        }, Promise.resolve());
        return;
      }

      const legIdsInClause = legIds.join(',');
      const legServiceNeedByLegId = `in(legId,(${legIdsInClause}))&include=leg`;
      await this.queryModelData('leg-service-need', legServiceNeedByLegId);
    },

    //fetching model using query
    async queryModelData(modelName, query) {
      await this.get('store').query(modelName, {
        filter: query
      });
    },

    filteredBookingAndTrips(bookingAndTrips) {
      const currentDate = (0, _moment.default)().format('YYYY-MM-DD');
      const [bookings, trips] = bookingAndTrips;
      const filteredCurrentBookings = bookings.filter(booking => {
        const tripStartTime = (0, _moment.default)(booking.tripStartTime).format('YYYY-MM-DD');
        return (0, _moment.default)(tripStartTime).isAfter(currentDate) || (0, _moment.default)(tripStartTime).isSame(currentDate);
      });
      const filteredCurrentTrips = trips.filter(trip => {
        const tripStartTime = (0, _moment.default)(trip.tripStartTime).format('YYYY-MM-DD');
        return (0, _moment.default)(tripStartTime).isAfter(currentDate) || (0, _moment.default)(tripStartTime).isSame(currentDate);
      });
      return [filteredCurrentBookings, filteredCurrentTrips];
    },

    filteredPastSubscriptionRecords(subscriptions) {
      const currentDate = (0, _moment.default)().format('YYYY-MM-DD');
      return subscriptions.filter(booking => {
        const endDate = (0, _moment.default)(booking.endDate).format('YYYY-MM-DD');
        return (0, _moment.default)(endDate).isAfter(currentDate) || (0, _moment.default)(endDate).isSame(currentDate);
      });
    },

    loadDataOnBasiesOfTripID(bookingTripIds, bookingSearchIds, alreadyFetchedbookings) {
      let segmentIds = []; // let bookingIDs = [];

      let riderIDs = [];
      let tripIds = [];
      return new Promise(async (resolve, reject) => {
        this.get('workspace').set('isGlobalSpinnerVisible', true);

        try {
          let tripsByBookingID = [];

          if (!Ember.isEmpty(bookingSearchIds)) {
            tripsByBookingID = `and(eq(schedule.type,'primary'),in(booking,(${bookingSearchIds})))&include=schedule,route,pick,drop`;
          } else {
            tripsByBookingID = `and(eq(schedule.type,'primary'),in(booking,eq(id,'${bookingTripIds}')(&include=schedule,route,pick,drop`;
          }

          const trips = await this.get('store').query('trip', {
            filter: tripsByBookingID
          });

          if (Ember.isBlank(trips)) {
            resolve([alreadyFetchedbookings, []]);
            this.get('workspace').set('isGlobalSpinnerVisible', false);
          }

          tripIds = trips.map(function (trp) {
            return trp.get('id');
          });
          const tripIdStrArray = tripIds.map(t => `'${t}'`).join(',');
          segmentIds = trips.map(function (a) {
            return a.get('segment.id');
          }); // bookingIDs = trips.map(function(a) {
          //   return a.get('booking.id');
          // });

          const stopByTripId = `in(trip,(${tripIdStrArray}))&include=cluster.dispatchRoute`;
          await this.get('store').query('stop-point', {
            filter: stopByTripId
          }); // const bookingByBookingID = `and(in(id,(${bookingIDs.toString()})),eq(cancellationId,null))&include=legs,legs.legTravelNeeds,legs.segments,legs.segments.pick,legs.segments.drop,`;

          /*const bookings = await this.get('store').query('booking', {
            filter: bookingByBookingID
          });*/

          riderIDs = trips.map(function (a) {
            return a.get('rider.id');
          }); // some riderIds return undefined following is to remove them

          riderIDs = riderIDs.filter(r => !!r); // some riderIds return duplicate ids so uniq them;

          riderIDs = _lodash.default.uniq(riderIDs);
          const segmentBysegmentID = `in(id,(${segmentIds.toString()}))&include=pick,drop`;
          const segments = await this.get('store').query('segment', {
            filter: segmentBysegmentID
          });
          const pickPlaceIDs = segments.map(function (a) {
            return a.get('stops.0.place.id');
          });
          const dropPlaceIDs = segments.map(function (a) {
            return a.get('stops.1.place.id');
          });
          let placeIDs = pickPlaceIDs.concat(dropPlaceIDs); // some segment-stops are returnin null, following is to remove them

          placeIDs = placeIDs.filter(placeId => !!placeId);
          const placeByPlaceID = `in(id,(${placeIDs.toString()}))&include=address,location`;
          await this.get('store').query('place', {
            filter: placeByPlaceID
          });
          const riderByRiderID = `in(id,(${riderIDs.toString()}))`;
          await this.get('store').query('rider', {
            filter: riderByRiderID
          });
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          resolve([alreadyFetchedbookings, trips]);
        } catch (error) {
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          reject(error);
        }
      });
    },

    getExportedSchedule(bookingDateTime) {
      const schedules = this.get('store').peekAll('schedule');
      const schedule = schedules.find(s => {
        return s.get('start') <= bookingDateTime && bookingDateTime <= s.get('end');
      });
      if (!schedule) return;
      return schedule;
    },

    getExportBookingPayload(bookingId, bookingDateTime) {
      const schedule = this.getExportedSchedule(bookingDateTime);

      if (schedule) {
        return {
          'data': {
            'attributes': {
              'schedulingMode': 'automatic'
            },
            'relationships': {
              'schedule': {
                'data': {
                  'id': schedule.get('id'),
                  'type': 'schedule'
                }
              }
            },
            'id': bookingId,
            'type': 'booking'
          }
        };
      }

      return;
    },

    getSchedule() {
      const schedules = this.get('store').peekAll('schedule');
      const schedule = schedules.find(s => {
        return s.get('start') <= new Date() && new Date() <= s.get('end');
      });
      if (!schedule) return;
      return schedule;
    },

    getSameDayBookingPayload(bookingId) {
      const schedule = this.getSchedule();

      if (schedule) {
        return {
          'data': {
            'attributes': {
              'schedulingMode': 'automatic'
            },
            'relationships': {
              'schedule': {
                'data': {
                  'id': schedule.get('id'),
                  'type': 'schedule'
                }
              }
            },
            'id': bookingId,
            'type': 'booking'
          }
        };
      }

      return;
    },

    async getTripsForRider(riderId, validStatuses, schedule) {
      const store = this.get('store');
      const validStatusArray = validStatuses.map(validStatus => `%27${validStatus}%27`);
      const validStatusInArray = validStatusArray.join(',');
      const tripFilter = `and(eq(passengerPk,${riderId}),in(tripStatus,(${validStatusInArray})))`;
      const widgetService = this.get('widgetService');
      const modelName = 'tripWidget';
      const offset = 0;
      const limit = 10;
      const dateQueryFields = {
        startDateUTC: schedule.get('start').toISOString(),
        endDateUTC: null
      };
      const selectedProviders = widgetService.getAllProviders();
      const tripWidgets = await widgetService.requestWidgetApi(null, modelName, tripFilter, offset, limit, null, dateQueryFields, selectedProviders);
      const tripsPromises = tripWidgets.map(trip => {
        return store.findRecord('trip', trip.id);
      });
      const riderTrips = await Promise.all(tripsPromises);
      return riderTrips;
    },

    async updateTripsFromIQUXService(trips) {
      const store = this.get('store');
      let include = `subscription,
      booking,
      booking.cancellation,
      segment,
      pick,
      drop,
      pick.segmentStop,
      pick.segmentStop.place,
      pick.segmentStop.place.address,
      pick.segmentStop.place.location,
      drop.segmentStop,
      drop.segmentStop.place,
      drop.segmentStop.place.address,
      drop.segmentStop.place.location,
      segment.leg,
      segment.leg.travelNeeds,
      segment.leg.serviceNeeds,
      stopPoints,
      stopPoints.cluster,
      stopPoints.cluster.dispatchRoute,
      stopPoints.cluster.dispatchRoute.route,
      stopPoints.cluster.dispatchSchedule,
      schedule,
      routeAssignments,
      routeAssignments.route,
      routeAssignments.trip`;
      include = include.replace(/(\r\n|\n|\r|\s)/gm, '');
      return trips.map(async trip => {
        const tripIsDeleted = trip.get('isDeleted');

        if (!tripIsDeleted) {
          const currTrip = await store.findRecord('trip', trip.get('id'), {
            include
          }).catch(e => {
            console.log(`missing trip from iqux service ${e.message}`); // eslint-disable-line no-console

            return null;
          });

          if (!Ember.isBlank(currTrip) && trip.get('booking.isSameDayBooking')) {
            const schedule = currTrip.get('schedule');
            const widgetService = this.get('widgetService');
            const modelName = 'tripWidget';
            const offset = 0;
            const limit = 10;
            const dateQueryFields = {
              schedulePk: schedule.get('id'),
              startDateUTC: schedule.get('start').toISOString(),
              endDateUTC: schedule.get('end').toISOString()
            };
            const selectedProviders = widgetService.getAllProviders();
            const filter = `eq(id,${trip.get('id')})`;
            const tripWidgets = await widgetService.requestWidgetApi(null, modelName, filter, offset, limit, null, dateQueryFields, selectedProviders);
            const tripWidget = tripWidgets[0];

            if (!Ember.isBlank(tripWidget)) {
              trip.set('sdbRouteName', tripWidget.routeName);
              trip.set('status', tripWidget.tripStatus);
              trip.set('drop.sdbPlannedEta', tripWidget.dropEta);
            }

            const stopPointModelName = 'stopWidget';
            const pickStopPointId = trip.get('pickStopPoint.id');

            if (!Ember.isBlank(pickStopPointId)) {
              const stopPointFilter = `eq(stopPointPk,'${pickStopPointId}')`;
              const stopWidgets = await widgetService.requestWidgetApi(null, stopPointModelName, stopPointFilter, offset, limit, null, dateQueryFields, selectedProviders);
              const stopWidget = stopWidgets[0];

              if (!Ember.isBlank(stopWidget)) {
                trip.set('pickStopPoint.status', stopWidget.status);
              }
            }
          }
        }

        return new Promise(function (resolve) {
          resolve();
        });
      });
    }

  });

  _exports.default = _default;
});