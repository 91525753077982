define("adept-iq/classes/markers/vehicles/base", ["exports", "adept-iq/classes/markers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // vehicles need to render on top of everything else
  const Z_INDEX_OFFSET = 999;

  var _default = _base.default.extend({
    label: 'Vehicle',
    style: Ember.computed.readOnly('vehicleStyle'),
    zIndexOffset: Z_INDEX_OFFSET,
    // local properties
    otp: null,
    isMoving: false,
    isEmergencyVehicle: false,
    isApprovedEmergency: false,
    isRoadSupervisor: false,
    isLoggedInRoadSupervisor: false,
    inCluster: false,
    isGrouped: false,
    isBreakdown: false,
    isOnBreak: false,
    isCommFailure: false,
    isLeaveGarageSignOff: false,
    isGpsFailure: false,
    isPoweredOn: false,
    vehicleStyle: Ember.computed('otp', 'isMoving', 'isEmergencyVehicle', 'isApprovedEmergency', 'isRoadSupervisor', 'isLoggedInRoadSupervisor', 'isBreakdown', 'isOnBreak', 'isCommFailure', 'isLeaveGarageSignOff', 'isGpsFailure', 'isPoweredOn', function () {
      const otp = this.get('otp');
      const isMoving = this.get('isMoving');
      const isEmergency = this.get('isEmergencyVehicle');
      const isApprovedEmergency = this.get('isApprovedEmergency');
      const isRoadSupervisor = this.get('isRoadSupervisor');
      const isLoggedInRoadSupervisor = this.get('isLoggedInRoadSupervisor');
      const isBreakdown = this.get('isBreakdown');
      const isOnBreak = this.get('isOnBreak');
      const isCommFailure = this.get('isCommFailure');
      const isLeaveGarageSignOff = this.get('isLeaveGarageSignOff');
      const isPoweredOn = this.get('isPoweredOn');
      const isGpsFailure = this.get('isGpsFailure');
      let style = 'vehicle';

      if (isBreakdown) {
        style += 'Breakdown';
        return style;
      } // TODO: are both of these cases needed?


      if (isEmergency || isApprovedEmergency) {
        if (isMoving) {
          style += 'Driving';
        }

        style += 'Emergency';
        return style;
      }

      if (isCommFailure) {
        style += 'CommunicationFailed';
        return style;
      }

      if (isOnBreak) {
        style += 'OnBreak';
        return style;
      } // These events priority are lower than emergency;


      switch (otp) {
        case 'D':
        case 'In Danger':
          if (isMoving) {
            style += 'Driving';
          }

          style += 'Danger';
          return style;

        case 'L':
        case 'Late':
          if (isMoving) {
            style += 'Driving';
          }

          style += 'Late';
          return style;

        default:
          break;
      }

      if (isLeaveGarageSignOff || isPoweredOn) {
        style += 'NotSigned';
        return style;
      }

      if (isGpsFailure) {
        style += 'GPSFailed';
        return style;
      }

      if (isRoadSupervisor && isLoggedInRoadSupervisor) {
        if (isMoving) {
          style += 'Driving';
        }

        style += 'LoggedRoadSupervisor';
      } else if (isRoadSupervisor) {
        if (isMoving) {
          style += 'Driving';
        }

        style += 'RoadSupervisor';
      }

      return style;
    })
  });

  _exports.default = _default;
});