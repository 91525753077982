define("adept-iq/utils/unwrapProxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unwrapProxy = void 0;

  const isProxy = obj => {
    return obj instanceof Ember.ObjectProxy || obj instanceof Ember.ArrayProxy;
  };

  const unwrapProxy = obj => {
    if (!obj) return null;
    if (isProxy(obj)) return obj.get('content');
    return obj;
  };

  _exports.unwrapProxy = unwrapProxy;
});