define("adept-iq/utils/password-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isValidPassword = void 0;

  /**
   *
   * @param {*} password
   * @returns true if the password is valid, false if invalid
   * True password meaning the password is not empty AND passes the regex constrians
   */
  const isValidPassword = password => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,20}$/;
    return !Ember.isEmpty(password) && passwordRegex.test(password);
  };

  _exports.isValidPassword = isValidPassword;
});