define("adept-iq/scenarios/vehicle-service", ["exports", "ember-data-factory-guy"], function (_exports, _emberDataFactoryGuy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Just for fun, set the log level ( to 1 ) and see all FactoryGuy response info in console
  _emberDataFactoryGuy.Scenario.settings({
    logLevel: 0 // 1 is the max for now, default is 0

  });

  class _default extends _emberDataFactoryGuy.Scenario {
    run() {
      const drivers = this.store.peekAll('driver');
      const vehicleTypes = this.store.peekAll('vehicle-type').toArray();
      const providers = this.store.peekAll('provider').toArray();
      drivers.forEach(driver => {
        const random = Math.floor(Math.random() * (drivers.length - 1));
        const randomVehicleType = vehicleTypes[random];
        const randomProvider = providers[random];
        const startPlace = (0, _emberDataFactoryGuy.make)('place');
        const startAddress = (0, _emberDataFactoryGuy.make)('address', {
          places: [startPlace]
        });
        const endPlace = (0, _emberDataFactoryGuy.make)('place');
        const endAddress = (0, _emberDataFactoryGuy.make)('address', {
          places: [endPlace]
        });
        (0, _emberDataFactoryGuy.make)('location', {
          address: startAddress,
          places: [startPlace]
        });
        (0, _emberDataFactoryGuy.make)('location', {
          address: endAddress,
          places: [endPlace]
        });
        const vehicle = (0, _emberDataFactoryGuy.make)('vehicle', {
          driver,
          vehicleType: randomVehicleType,
          provider: randomProvider,
          startGarage: startPlace,
          endGarage: endPlace
        });
        (0, _emberDataFactoryGuy.make)('vehicle-availability', {
          vehicle
        });
      });
    }

  }

  _exports.default = _default;
});