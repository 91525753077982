define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/physical-type/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/physical-type/validation", "adept-iq/config/placeholders", "lodash", "adept-iq/utils/guid"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset, _emberChangesetValidations, _validation, _placeholders, _lodash, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['driver-lookup-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // Global Placeholder
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    tableRef: null,
    description: null,
    editAPI: null,
    disableForm: true,
    showErrorMsg: false,
    currentDisplayName: null,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    driverPhysicalTypeChangeSet: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),
    hasPendingChanges: Ember.computed('driverPhysicalTypeChangeSet.changes.length', function () {
      const driverPhysicalTypeChangeSet = this.get('driverPhysicalTypeChangeSet');
      const changes = driverPhysicalTypeChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      return this.get('hasPendingChanges');
    },

    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveDriverPhysicalTypeRecord = this.get('onSaveDriverPhysicalTypeRecord');
      const onNewDriverPhysicalTypeRecord = this.get('onNewDriverPhysicalTypeRecord').bind(this);
      const onEditDriverPhysicalTypeRecord = this.get('onEditDriverPhysicalTypeRecord').bind(this);
      const refreshTask = this.get('refreshTask');
      const onUndoDriverPhysicalTypeRecord = this.get('onUndoDriverPhysicalTypeRecord').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      const hasRelationship = this.get('hasRelationship').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverPhysicalTypeRecord,
          newAction: onNewDriverPhysicalTypeRecord,
          refreshTask: refreshTask,
          editAction: onEditDriverPhysicalTypeRecord,
          undoAction: onUndoDriverPhysicalTypeRecord,
          deleteAction: null,
          onValidateAction,
          hasChanges,
          hasRelationship
        });
        refreshTask.perform();
      });
    },

    willDestroyElement() {
      const records = this.get('tableRef.records') || [];
      records.map(record => {
        if (record.get('tableRef')) {
          record.set('tableRef');
        }
      });

      this._super(...arguments);
    },

    hasRelationship() {
      const store = this.get('store');
      const tooltip = this.get('tooltip');
      const description = this.get('driverPhysicalTypeChangeSet.displayName') || '';
      const driversHealth = store.peekAll('driver-health').filter(record => record.get('driverPhysicalTypeName.displayName') === description);

      if (driversHealth.length) {
        tooltip.pushConfirmation({
          title: 'Lookup',
          hasoverlay: true,
          tip: `There are ${driversHealth.length} active Driver(s) with this Driver Physical Type description. You must either change the Driver Physical Type description on these Driver(s) or delete these Driver(s) prior to changing the Driver Physical Type description.`,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.reset();
          }

        });
      }

      return driversHealth.length;
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const tableRef = this.get('tableRef');
      let driverPhysicalTypeObj = this.get('pendingRecord');

      if (record) {
        driverPhysicalTypeObj = record;
        this.resetForm();
      }

      if (Ember.isEmpty(record)) {
        const data = {
          category: 'config-System_Configuration-driver_physical_types',
          type: 'object'
        };
        driverPhysicalTypeObj = store.createRecord('cs-config-item', data);
        this.set('pendingRecord', driverPhysicalTypeObj);
      }

      if (driverPhysicalTypeObj.get('isNew')) {
        driverPhysicalTypeObj.setProperties({
          description: null,
          displayName: null,
          tableRef: tableRef
        });
      }

      this.set('showErrorMsg', false);
      const tableRefRecords = this.get('tableRef.records') || [];
      tableRefRecords.map(tableRefRecord => {
        if (!tableRefRecord.get('tableRef')) {
          tableRefRecord.set('tableRef', tableRef);
        }
      });
      return new _emberChangeset.default(driverPhysicalTypeObj, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('driverPhysicalTypeChangeSet');

      if (selectedRecord) {
        if (!selectedRecord.get('isNew') && !selectedRecord.get('isDeleted')) {
          yield selectedRecord.data.reload();
        }
      }

      const tableRef = this.get('tableRef');
      this.resetForm();
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        editAPI.buttonSettings();
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverPhysicalTypeRecord = this.get('driverPhysicalTypeChangeSet');
      yield driverPhysicalTypeRecord.validate();
      const errors = driverPhysicalTypeRecord.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveDriverPhysicalTypeRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');
      const activityIdGuid = (0, _guid.guid)();
      if (!isValid) return;
      const notifications = this.get('notifications');
      const description = this.get('driverPhysicalTypeChangeSet.displayName') || '';
      const value = {
        displayName: description
      };
      const nameGuidConverted = `${description}-${activityIdGuid}`;
      const name = (0, _lodash.camelCase)(nameGuidConverted);
      const driverPhysicalTypeChangeSet = this.get('driverPhysicalTypeChangeSet');
      let driverPhysicalTypeRecord = this.get('driverPhysicalTypeChangeSet');

      if (driverPhysicalTypeRecord.get('isNew')) {
        driverPhysicalTypeRecord = (0, _unwrapProxy.unwrapProxy)(this.get('driverPhysicalTypeChangeSet'));
        driverPhysicalTypeRecord.set('name', name);
      } else {
        const id = this.get('driverPhysicalTypeChangeSet.id');
        const store = this.get('store');
        const record = `config-System_Configuration-driver_physical_types/${id}`;
        driverPhysicalTypeRecord = store.peekRecord('cs-config-item', record);
      }

      driverPhysicalTypeRecord.set('displayName', description);
      driverPhysicalTypeRecord.set('description', description);
      driverPhysicalTypeRecord.set('value', value);

      try {
        yield driverPhysicalTypeRecord.save(); // changeset holds changes, execute the changes due to lookups using unorthodox saving practice
        // execute to change underlying object and rollback to remove internal changes list

        driverPhysicalTypeChangeSet.execute();
        driverPhysicalTypeChangeSet.rollback();
        notifications.success('Lookup record successfully saved.');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
            tooltip.reset();
          });
        }, 500);
      } catch (response) {
        if (response.errors[0].status === '409') {
          tooltip.pushConfirmation({
            title: 'Manage Driver/Vehicle',
            tip: 'Description cannot duplicate an existing Description.',
            primaryActionText: 'OK',
            primaryAction: () => {
              return tooltip.reset();
            }
          });
        } else {
          notifications.warning('Lookup record failed to save.');
        }

        if (driverPhysicalTypeRecord.rollbackAttributes) {
          driverPhysicalTypeRecord.rollbackAttributes();
        }

        console.warn(response); // eslint-disable-line
      }
    }).drop(),

    onEditDriverPhysicalTypeRecord() {
      const editAPI = this.get('editAPI');
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onUndoDriverPhysicalTypeRecord() {
      const driverPhysicalTypeChangeSet = this.get('driverPhysicalTypeChangeSet');
      const editAPI = this.get('editAPI');

      if (driverPhysicalTypeChangeSet) {
        driverPhysicalTypeChangeSet.rollback();
      }

      this.set('disableForm', true);
      editAPI.buttonSettings(false, false, false, true, true);
    },

    onNewDriverPhysicalTypeRecord() {
      const editAPI = this.get('editAPI');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    actions: {
      onSaveDriverPhysicalTypeRecord() {
        return this.onSaveDriverPhysicalTypeRecord.perform();
      },

      onInputValueChange(record, valuePath, value) {
        this.set('currentDisplayName', value);
        record.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});