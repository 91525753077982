define("adept-iq/pods/components/iq-widgets/edit-trip-form-widget/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'tripId',
        editable: false
      }, {
        id: 'fullName',
        type: 'text',
        label: 'Passenger First Name',
        valuePath: 'segment.leg.rider.fullName',
        editable: false
      }, {
        id: 'riderId',
        type: 'text',
        label: 'Passenger ID',
        valuePath: 'segment.leg.rider.riderId',
        editable: false
      }]
    }, {
      title: 'Trip Details',
      fields: [{
        id: 'requestedTime',
        type: 'datetimeflatpickr',
        label: 'Request Time',
        readonlyCell: true,
        valuePath: 'segment.leg.requestTime',
        editable: true,
        hidden: false,
        format: `${_environment.default.dateTimeFormat.dateTimeFlatPickr}`,
        defaultWidth: 50
      }, {
        id: 'anchor',
        type: 'enum',
        label: 'Anchor',
        valuePath: 'segment.anchor',
        cellDesc: 'anchor',
        editable: true,
        hidden: false,
        extra: {
          options: ['pick', 'drop'],
          allowMultiple: false
        }
      }]
    }, {
      title: 'Pickup Address',
      fields: [{
        id: 'originaddresses',
        type: 'addresses',
        label: 'Pickup Address',
        valuePath: 'pick.segmentStop.place',
        modelName: 'place',
        markerType: 'pick',
        extra: {
          aliasPath: 'address.content.alias',
          notesPath: 'address.content.notes',
          premisePath: 'address.content.premise',
          streetNumberPath: 'address.content.streetNumber',
          streetNamePath: 'address.content.streetName',
          localityPath: 'address.content.city',
          regionPath: 'address.content.region',
          subRegionPath: 'address.content.county',
          postalCodePath: 'address.content.postalCode',
          countryPath: 'address.content.country',
          freeformAddressPath: 'address.content.freeformAddress',
          tomtomFormattedAddressPath: 'address.content.tomtomFormattedAddress',
          useOptionRecord: true,
          streetAddressPath: 'address.content.streetAddress',
          addressesPath: 'address.addresses.content.firstObject.latlng'
        }
      }]
    }, {
      title: 'Dropoff Address',
      fields: [{
        id: 'destinationaddresses',
        type: 'addresses',
        label: 'Drop-off Address',
        valuePath: 'drop.segmentStop.place',
        modelName: 'place',
        markerType: 'drop',
        extra: {
          aliasPath: 'address.content.alias',
          notesPath: 'address.content.notes',
          premisePath: 'address.content.premise',
          streetNumberPath: 'address.content.streetNumber',
          streetNamePath: 'address.content.streetName',
          localityPath: 'address.content.city',
          regionPath: 'address.content.region',
          subRegionPath: 'address.content.county',
          postalCodePath: 'address.content.postalCode',
          countryPath: 'address.content.country',
          tomtomFormattedAddressPath: 'address.content.tomtomFormattedAddress',
          useOptionRecord: true,
          streetAddressPath: 'address.content.streetAddress',
          addressesPath: 'address.addresses.content.firstObject.latlng'
        }
      }]
    }, {
      title: 'Travel Needs',
      fields: [{
        id: 'travelNeeds',
        type: 'bs-travel-needs',
        label: '',
        valuePath: 'segment.leg.legTravelNeeds',
        modelName: 'leg-travel-need',
        isMultiType: true,
        extra: {
          travelNeedTypeModelName: 'travel-need-type',
          passengerTypeModelName: 'passenger-type',
          countPath: 'count',
          travelNeedTypePath: 'travelNeedType',
          passengerTypePath: 'passengerType'
        }
      }]
    }, {
      title: 'Notes',
      fields: [{
        id: 'passengerNotes',
        type: 'text',
        label: 'Passenger Notes',
        valuePath: 'segment.leg.rider.notes',
        editable: true
      }, {
        id: 'driverNotes',
        type: 'text',
        label: 'Driver Notes',
        valuePath: 'segment.leg.notes',
        editable: true
      }]
    }, {
      title: 'Fare',
      fields: [{
        id: 'type',
        type: 'enum',
        label: 'Type',
        valuePath: 'segment.fareType',
        cellDesc: 'fareType',
        editable: true,
        hidden: false,
        extra: {
          optionModelName: 'fare-type',
          optionSearchPath: 'name'
        }
      }, {
        id: 'fare',
        type: 'number',
        label: 'Fare',
        valuePath: 'segment.fare',
        editable: true,
        extra: {
          isFloat: true
        }
      }]
    }, {
      title: 'Scheduling Mode',
      fields: [{
        id: 'schedulingMode',
        type: 'enum',
        label: 'Scheduling Mode',
        valuePath: 'schedulingMode',
        editable: true,
        hidden: false,
        extra: {
          options: ['automatic', 'manual'],
          allowMultiple: false
        }
      }]
    }]
  };
  _exports.default = _default;
});