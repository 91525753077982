define("adept-iq/tests/factories/subscription-exclusion", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  _emberDataFactoryGuy.default.define('subscription-exclusion', {
    sequences: {
      randomStartDate: () => (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
      randomEndDate: () => (0, _moment.default)().startOf('day').add(16, 'hours').toDate()
    },
    default: {
      startDate: _emberDataFactoryGuy.default.generate('randomStartDate'),
      endDate: _emberDataFactoryGuy.default.generate('randomEndDate')
    }
  });
});