define("adept-iq/pods/components/rider-management-widget/rider-contact-information/rider-contact-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'rider-management-widget/rider-contact-table',
    rowComponent: 'table/rows/text-formatted-row',
    rowSelectionType: 'radio',
    modelName: 'rider-contact-info',
    defaultSortAsc: false,
    checkBoxColumn: false,
    isFormControls: true,
    title: 'Contacts',
    columns: [{
      id: 'Type',
      index: 0,
      type: 'text',
      isKey: true,
      label: 'Type',
      valuePath: 'displayContactType',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 150
    }, {
      id: 'Name',
      index: 1,
      type: 'text',
      label: 'Name',
      valuePath: 'name',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 150,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'Relationship',
      index: 2,
      type: 'text',
      label: 'Relationship',
      valuePath: 'relationship',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 150,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'Address',
      index: 3,
      type: 'text',
      label: 'Address',
      valuePath: 'address',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 300,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'PhoneNumber',
      index: 4,
      type: 'text',
      label: 'PhoneNumber',
      valuePath: 'formattedPhoneNumber',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 180,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'Ext',
      index: 5,
      type: 'text',
      label: 'Ext',
      valuePath: 'extension',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 80,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'Email',
      index: 6,
      type: 'text',
      label: 'Email',
      valuePath: 'email',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 150,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'Reason',
      index: 7,
      type: 'text',
      label: 'Reason',
      valuePath: 'reasonDisplayName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 150,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }]
  };
  _exports.default = _default;
});