define("adept-iq/models/maintenance-type-name", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    displayName: attr('string'),
    description: attr('string'),
    scheduled: attr('boolean'),
    isScheduled: Ember.computed('scheduled', function () {
      const scheduled = this.get('scheduled');

      if (!scheduled) {
        return 'false';
      }

      return scheduled;
    })
  });

  _exports.default = _default;
});