define("adept-iq/pods/index/modals/create-dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tBTijs5F",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"class\",\"targetAttachment\",\"translucentOverlay\"],[\"toggleModal\",\"create-dashboard-wrapper\",\"center\",true]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n    \"],[7,\"h6\"],[9],[0,\"Save Dashboard\"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"x-button\"],[9],[0,\"\\n      ✕\\n    \"],[3,\"action\",[[22,0,[]],\"toggleModal\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"row mt-3\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n        \"],[1,[27,\"form-components/input\",null,[[\"label\",\"value\",\"placeholder\",\"type\"],[\"Dashboard Name\",[23,[\"model\",\"name\"]],\"name\",\"text\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"row mt-3\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-default col-4 offset-4 mt-3 mb-3\"],[9],[0,\"\\n        Discard Changes\\n      \"],[3,\"action\",[[22,0,[]],\"toggleModal\"]],[10],[0,\"\\n\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-primary-simple col-4 mt-3 mb-3\"],[9],[0,\"\\n        Save\\n      \"],[3,\"action\",[[22,0,[]],\"createDashboard\",[23,[\"model\"]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/index/modals/create-dashboard/template.hbs"
    }
  });

  _exports.default = _default;
});