define("adept-iq/utils/help-page-redirect", ["exports", "adept-iq/config/api-urls"], function (_exports, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.helpRedirect = helpRedirect;
  _exports.pdfMapping = _exports.htmlPageMapping = void 0;
  const htmlPageMapping = {
    overview: 'Overview.htm',
    resetPassword: 'Reset_Your_Password.htm',
    driversWidget: 'Manage_Driver_Records.htm',
    vehiclesWidget: 'Manage_Vehicle_Records.htm',
    routesWidget: 'Manage_Routes.htm',
    tripsWidget: 'Manage_Trips.htm',
    stopsWidget: 'Manage_Stops.htm',
    subscriptionsWidget: 'Manage_Trip_Subscriptions.htm',
    mapWidget: 'Use_the_Map.htm',
    alertsWidget: 'Manage_an_Emergency_Alarm.htm',
    messagesWidget: 'Manage_Messages.htm',
    passengersWidget: 'Manage_Passengers.htm',
    zonesWidget: 'Manage_Zone.htm',
    usersWidget: 'Manage_Users.htm',
    rolesWidget: 'Manage_Roles.htm',
    filterSidePanel: 'Filter_Data_in_Widgets.htm',
    filterMap: 'Filter_Data_on_Map.htm',
    generatePrintWidget: 'Generate_Print-Out_of_Data_in_Widgets.htm',
    configureWorkspace: 'Work_with_Widgets.htm',
    setupUserAccount: 'Set_Up_User_Accounts.htm',
    systemConfigurations: 'Set_Up_Configurations_in_ADEPTIQ.htm',
    bookTripPassenger: 'Book_Trip_for_Passenger.htm',
    editTrip: 'Edit_Trip.htm',
    cancelTrip: 'Cancel_Trip.htm',
    noShowTrip: 'Update_Trip_to_NoShow.htm',
    tripTransfer: 'Move_a_Trip.htm',
    manualAssignTrip: 'Assign_Trip_to_Route_Manually.htm',
    activityLogs: 'Activity_Logs.htm',
    startRoute: 'Start_Route.htm',
    endRoute: 'End_Route.htm',
    createRoute: 'Create_Route.htm',
    addBreak: 'Add_Break_to_Route.htm',
    addVehicle: 'Add_a_Vehicle_Record.htm',
    addDriver: 'Add_a_Driver_Record.htm',
    sendCannedMessage: 'Send_Canned_Messages_to_Driver.htm',
    configViewGaugeChart: 'Config_View_Gauge_Chart.htm',
    configViewBoxChart: 'Config_View_Box_Chart.htm',
    configViewDonutChart: 'Config_View_Donut_Chart.htm',
    configViewBarChart: 'Config_View_Bar_Chart.htm',
    performStop: 'Perform_Pick_Drop_Stops_Manually.htm',
    assignDriverVehicleRoute: 'Assign_Driver_Vehicle_to_Route.htm',
    vehicleBreakdown: 'Report_Vehicle_Breakdown_Info.htm',
    breakdownReplacement: 'Add_Breakdown_Replacement_Route.htm',
    rescueReplacement: 'Create_Rescue_Replacement_Route.htm'
  };
  _exports.htmlPageMapping = htmlPageMapping;
  const pdfMapping = {
    'Rider Management': 'RiderManagement.pdf',
    'Booking': 'Booking.pdf',
    'Schedule': 'Scheduling.pdf',
    'Dispatch': 'Dispatch.pdf',
    'Driver & Vehicle Management_driver': 'SystemConfigurationDrivers.pdf',
    'Driver & Vehicle Management_vehicle': 'SystemConfigurationVehicles.pdf',
    'Reconcile': 'Reconcile.pdf',
    'Permissions': 'Permissions.pdf',
    'Playback': 'Playback.pdf',
    'Reports': 'Reports.pdf',
    'SystemConfig_route-master': 'SystemConfigurationRouteMaster.pdf',
    'SystemConfig_places': 'SystemConfigurationPlaces.pdf'
  };
  _exports.pdfMapping = pdfMapping;

  function doesFileExist(urlToFile) {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    xhr.send();

    if (xhr.status === 404) {
      return false;
    }

    return true;
  }

  function helpRedirect(componentName) {
    if (Ember.isEmpty(componentName)) return;
    const fileName = pdfMapping[componentName];
    const url = `${_apiUrls.API.helpPage.host}/${encodeURIComponent(fileName)}`;
    const result = doesFileExist(url);

    if (result === true) {
      window.open(url, '_blank');
    } else {
      window.open('/help-files/under_construction.html', '_blank');
    }
  }
});