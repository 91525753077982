define("adept-iq/adapters/cluster", ["exports", "adept-iq/adapters/-dispatch"], function (_exports, _dispatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    preventSave: true // We don't save clusters to server from UI

  });

  _exports.default = _default;
});