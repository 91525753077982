define("adept-iq/classes/active-contexts/dispatch/map-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.zoneNode = _exports.vehicleNode = _exports.ssTripStopNode = _exports.ssTripNode = _exports.riderNode = _exports.mapContextNodes = _exports.mapContextModels = _exports.mapContextGraph = _exports.dsStopNode = _exports.dsRouteNode = _exports.driverNode = _exports.default = _exports.avlmMessageNode = _exports.alertNode = void 0;
  // Rider Managament Related Nodes
  const riderNode = {
    id: 'rider',
    modelName: 'rider',
    links: [{
      type: 'hasMany',
      nodeId: 'ss-trip',
      path: 'trips'
    }],
    providerFilterKey: 'providers'
  }; // Driver Related Nodes

  _exports.riderNode = riderNode;
  const driverNode = {
    id: 'driver',
    modelName: 'driver',
    links: [{
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'latestVehicle'
    }],
    providerFilterKey: 'provider'
  }; // Vehicle Related Nodes

  _exports.driverNode = driverNode;
  const vehicleNode = {
    id: 'vehicle',
    modelName: 'vehicle',
    links: [],
    providerFilterKey: 'provider'
  }; // Zone Related Nodes

  _exports.vehicleNode = vehicleNode;
  const zoneNode = {
    id: 'zone',
    modelName: 'zone',
    links: [],
    providerFilterKey: false
  }; // Dispatch Related Nodes
  // used to traverse local data model

  _exports.zoneNode = zoneNode;
  const dsRouteNode = {
    id: 'ds-route',
    modelName: 'dispatch-route',
    links: [{
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'assignedVehicle'
    }, {
      type: 'hasMany',
      nodeId: 'ds-stop',
      path: 'orderedStopsWithGaragesAndBreaks'
    }, {
      type: 'hasMany',
      nodeId: 'ss-trip-stop',
      path: 'tripStops'
    }],
    isActive: true,
    providerFilterKey: 'provider',
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.dsRouteNode = dsRouteNode;
  const dsStopNode = {
    id: 'ds-stop',
    modelName: 'stop-point',
    links: [],
    isActive: true,
    providerFilterKey: 'trip.provider',
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime'
  };
  _exports.dsStopNode = dsStopNode;
  const ssTripStopNode = {
    id: 'ss-trip-stop',
    modelName: 'trip-stop',
    links: [],
    isActive: true,
    providerFilterKey: 'dispatchRoute.provider',
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime'
  }; // Scheduling Related Nodes

  _exports.ssTripStopNode = ssTripStopNode;
  const ssTripNode = {
    id: 'ss-trip',
    modelName: 'trip',
    links: [{
      type: 'hasMany',
      nodeId: 'ds-stop',
      path: 'stopPoints'
    }, {
      type: 'hasMany',
      nodeId: 'ss-trip-stop',
      path: 'stops'
    }],
    isActive: true,
    providerFilterKey: 'provider',
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime'
  };
  _exports.ssTripNode = ssTripNode;
  const avlmMessageNode = {
    id: 'avlm-message',
    modelName: 'avlm-canned-message',
    links: [{
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'vehicle'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.avlmMessageNode = avlmMessageNode;
  const alertNode = {
    id: 'avlm-alert',
    modelName: 'avlm-alert',
    links: [{
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'vehicle'
    }],
    isActive: true,
    providerFilterKey: 'provider',
    leftTimeConstraint: 'timestamp',
    rightTimeConstraint: 'timestamp'
  };
  _exports.alertNode = alertNode;
  const mapContextNodes = [// vehicle graph
  vehicleNode, // driver graph
  driverNode, // zone graph,
  zoneNode, // dispatch graph
  dsRouteNode, dsStopNode, // scheduling graph
  ssTripNode, ssTripStopNode, // rider management graph
  riderNode, // avlm graph
  avlmMessageNode, // alerts
  alertNode];
  _exports.mapContextNodes = mapContextNodes;
  const mapContextGraph = mapContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.mapContextGraph = mapContextGraph;
  const mapContextModels = mapContextNodes.reduce((obj, node) => {
    obj[node.modelName] = node;
    return obj;
  }, {});
  _exports.mapContextModels = mapContextModels;
  var _default = mapContextGraph;
  _exports.default = _default;
});