define("adept-iq/pods/components/form-components/travel-needs/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // status of travel needs within the UI
  const STATUS_TYPE_EXISTING = 'existing'; // already part of rider

  const STATUS_TYPE_AVAILABLE = 'available'; // available for rider to add

  const STATUS_TYPE_ADDED = 'added'; // it will be added to rider once it is saved

  var _default = Ember.Component.extend({
    classNames: ['form-components-travel-need'],
    store: Ember.inject.service(),
    editModal: Ember.inject.service(),
    value: null,
    disabled: false,
    pcaState: false,
    serviceAnimalState: false,
    travelNeedTypes: null,
    passengerTypes: null,
    travelNeeds: Ember.computed.readOnly('value'),
    countPath: Ember.computed.readOnly('extra.countPath'),
    travelNeedTypePath: Ember.computed.readOnly('extra.travelNeedTypePath'),
    passengerTypePath: Ember.computed.readOnly('extra.passengerTypePath'),
    modelName: Ember.computed.readOnly('field.modelName'),
    equipments: Ember.computed('record.{equipments,newTravelNeeds}', function () {
      this.get('record.equipments').map(travelNeed => {
        travelNeed.set('status', STATUS_TYPE_EXISTING);
      });
      const recordNewTravelNeeds = this.get('record.newTravelNeeds') || [];
      const recordEquipments = this.get('record.equipments') || [];
      recordNewTravelNeeds.map(travelNeed => {
        travelNeed.set('status', STATUS_TYPE_AVAILABLE);
      });
      return _lodash.default.concat(recordEquipments, recordNewTravelNeeds);
    }),
    newEquipments: Ember.computed('record.newTravelNeeds', function () {
      const newTravelNeeds = this.get('record.newTravelNeeds') || [];
      newTravelNeeds.filter(travelNeed => {
        return travelNeed.get('passengerType.isClient') && !travelNeed.get('travelNeedType.isServiceAnimal') && travelNeed.get('status') === STATUS_TYPE_AVAILABLE;
      });
    }),
    extraPassengers: Ember.computed('record.{extraPassengers,newTravelNeeds}', function () {
      const recordExtraPassengers = this.get('record.extraPassengers') || [];
      const recordNewTravelNeed = this.get('record.newTravelNeeds') || [];

      const tempArray = _lodash.default.concat(recordExtraPassengers, recordNewTravelNeed);

      const extraPassObjects = []; // arrange in specific order

      extraPassObjects.push(tempArray.find(travelNeed => travelNeed.get('travelNeedType.isServiceAnimal')));
      extraPassObjects.push(tempArray.find(travelNeed => travelNeed.get('passengerType.isPca')));
      extraPassObjects.push(tempArray.find(travelNeed => travelNeed.get('passengerType.isCompanion')));
      return extraPassObjects;
    }),
    init: function () {
      this._super(...arguments);

      const travelNeedTypeModelName = this.get('extra.travelNeedTypeModelName') || [];
      const passengerTypeModelName = this.get('extra.passengerTypeModelName') || [];
      this.set('travelNeedTypes', this.get('store').peekAll(travelNeedTypeModelName));
      this.set('passengerTypes', this.get('store').peekAll(passengerTypeModelName));
      this.setupNewEquipments();
      this.setupNewExtraPassengers();
    },
    clientPassengerType: function () {
      const passengerTypes = this.get('passengerTypes') || [];
      return passengerTypes.find(types => types.isClient);
    },
    serviceAnimalTravelNeedType: function () {
      const travelNeedTypes = this.get('travelNeedTypes') || [];
      return travelNeedTypes.find(types => types.isServiceAnimal);
    },
    ambulatoryTravelNeedType: function () {
      const travelNeedTypes = this.get('travelNeedTypes') || [];
      return travelNeedTypes.find(types => types.isAmbulatory);
    },
    pcaPassengerType: function () {
      const passengerTypes = this.get('passengerTypes') || [];
      return passengerTypes.find(types => types.isPca);
    },
    companionPassengerType: function () {
      const passengerTypes = this.get('passengerTypes') || [];
      return passengerTypes.find(types => types.isCompanion);
    },
    setupNewEquipments: function () {
      const currentTravelNeeds = this.get('record.equipments') || [];
      const newTravelNeeds = []; // add other travel need types that this person does not have
      // with empty count

      this.get('travelNeedTypes').map(travelNeedType => {
        if (!travelNeedType.isServiceAnimal && _lodash.default.findIndex(currentTravelNeeds, travelNeed => {
          return travelNeed.get('travelNeedType.id') === travelNeedType.id;
        }) < 0) {
          const newRecord = this.get('store').createRecord(this.get('modelName'));
          newRecord.set('travelNeedType', travelNeedType);
          newRecord.set('passengerType', this.clientPassengerType());
          newTravelNeeds.push(newRecord);
        }
      });
      this.set('record.newTravelNeeds', newTravelNeeds);
    },
    setupNewExtraPassengers: function () {
      const currentTravelNeeds = this.get('record.extraPassengers') || [];
      const newTravelNeeds = [];
      let retVal; // add service animal travel need type if this rider does not have
      // in current travel needs. leave count as empty

      retVal = currentTravelNeeds.find(travelNeed => travelNeed.get('travelNeedType.isServiceAnimal'));

      if (_lodash.default.isUndefined(retVal)) {
        const newRecord = this.get('store').createRecord(this.get('modelName'));
        this.set('serviceAnimalState', false);
        newRecord.set('travelNeedType', this.serviceAnimalTravelNeedType());
        newRecord.set('passengerType', this.clientPassengerType());
        newTravelNeeds.push(newRecord);
      } else {
        this.set('serviceAnimalState', retVal.get('count') > 0);
      } // add pca passenger type for this rider if it does not have
      // in current travel needs. leave count as empty


      retVal = currentTravelNeeds.find(travelNeed => {
        return travelNeed.get('passengerType.isPca');
      });

      if (_lodash.default.isUndefined(retVal)) {
        const newRecord = this.get('store').createRecord(this.get('modelName'));
        this.set('pcaState', false);
        newRecord.set('travelNeedType', this.ambulatoryTravelNeedType());
        newRecord.set('passengerType', this.pcaPassengerType());
        newTravelNeeds.push(newRecord);
      } else {
        this.set('pcaState', retVal.get('count') > 0);
      } // add companion passenger type for this rider if it does not have
      // in current travel needs. leave count as empty


      retVal = currentTravelNeeds.find(travelNeed => travelNeed.get('passengerType.isCompanion'));

      if (_lodash.default.isUndefined(retVal)) {
        const newRecord = this.get('store').createRecord(this.get('modelName'));
        newRecord.set('travelNeedType', this.ambulatoryTravelNeedType());
        newRecord.set('passengerType', this.companionPassengerType());
        newTravelNeeds.push(newRecord);
      }

      this.get('record.newTravelNeeds').addObjects(newTravelNeeds);
    },
    actions: {
      onInput(record, valuePath, value) {
        const options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(parseInt(value, 10), options);
      },

      onAddNewTravelNeed() {
        const travelNeed = this.get('newEquipments').find(currTravelNeed => {
          return currTravelNeed.status === STATUS_TYPE_AVAILABLE && currTravelNeed.isEquipment;
        });

        if (!Ember.isNone(travelNeed)) {
          travelNeed.set('status', STATUS_TYPE_ADDED);
        }
      },

      onTravelNeedTypeChange(oldTravelNeed, newTravelNeed) {
        oldTravelNeed.set('status', STATUS_TYPE_AVAILABLE);
        oldTravelNeed.set('count', 0);
        newTravelNeed.set('status', STATUS_TYPE_ADDED);
      },

      onRemoveTravelNeed(record, valuePath) {
        const options = {
          record,
          valuePath
        };

        if (!Ember.isNone(record)) {
          record.set('status', STATUS_TYPE_AVAILABLE);
        }

        this.get('onChange')(0, options);
      },

      onPCAChange(record, valuePath
      /*, value*/
      ) {
        this.toggleProperty('pcaState');
        const options = {
          record,
          valuePath
        };
        const pcaState = this.get('pcaState') ? 1 : 0;
        this.set('record.isForceDirty', true);
        this.get('onChange')(pcaState, options);
      },

      onServiceAnimalChange(record, valuePath
      /*, value*/
      ) {
        this.toggleProperty('serviceAnimalState');
        const options = {
          record,
          valuePath
        };
        const serviceAnimalState = this.get('serviceAnimalState') ? 1 : 0;
        this.set('record.isForceDirty', true);
        this.get('onChange')(serviceAnimalState, options);
      },

      onPassengerTypeChange(record, valuePath, value) {
        const options = {
          record,
          valuePath
        };
        this.get('onChange')(value, options);
      }

    }
  });

  _exports.default = _default;
});