define("adept-iq/classes/rules/eligibility-rules/eligibility-rule-context", ["exports", "adept-iq/classes/rules/base-rule-context"], function (_exports, _baseRuleContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EligibilityRuleContext extends _baseRuleContext.default {
    // pass whatever model or services this needs
    // Keep the individual rule instances  stateless
    constructor(store, bookingService, tooltip, systemConfigService, permissionLayer) {
      super();
      this.store = store;
      this.bookingService = bookingService;
      this.tooltip = tooltip;
      this.systemConfigService = systemConfigService;
      this.permissionLayer = permissionLayer;
      this.itpRequestResults = []; // each leg will have list of segments [{legIndex , result}, {legIndex , result}]

      this.itpResultType = null;
      this.errorInfo = null;
      this.hasOverrideITP = null;
    }

  }

  _exports.default = EligibilityRuleContext;
});