define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/road-call-code/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/road-call-code/validation", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset, _emberChangesetValidations, _validation, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['vehicle-lookup-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // Global Placeholder
    CODE_PLACEHOLDER: _placeholders.CODE_PLACEHOLDER,
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    tableRef: null,
    code: null,
    description: null,
    editAPI: null,
    disableForm: true,
    showErrorMsg: false,
    currentDisplayName: null,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    vehicleRoadCallCodeChangeSet: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),
    hasPendingChanges: Ember.computed('vehicleRoadCallCodeChangeSet.changes.length', function () {
      const vehicleRoadCallCodeChangeSet = this.get('vehicleRoadCallCodeChangeSet');
      const changes = vehicleRoadCallCodeChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      return this.get('hasPendingChanges');
    },

    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleRoadCallCodeRecord = this.get('onSaveVehicleRoadCallCodeRecord');
      const onNewVehicleRoadCallCodeRecord = this.get('onNewVehicleRoadCallCodeRecord').bind(this);
      const onEditVehicleRoadCallCodeRecord = this.get('onEditVehicleRoadCallCodeRecord').bind(this);
      const refreshTask = this.get('refreshTask');
      const onUndoVehicleRoadCallCodeRecord = this.get('onUndoVehicleRoadCallCodeRecord').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      const hasRelationship = this.get('hasRelationship').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleRoadCallCodeRecord,
          newAction: onNewVehicleRoadCallCodeRecord,
          editAction: onEditVehicleRoadCallCodeRecord,
          undoAction: onUndoVehicleRoadCallCodeRecord,
          deleteAction: null,
          onValidateAction,
          hasChanges,
          refreshTask,
          hasRelationship
        });
        refreshTask.perform();
      });
    },

    willDestroyElement() {
      const records = this.get('tableRef.records') || [];
      records.map(record => {
        if (record.get('tableRef')) {
          record.set('tableRef');
        }
      });

      this._super(...arguments);
    },

    hasRelationship() {
      const store = this.get('store');
      const tooltip = this.get('tooltip');
      const description = this.get('vehicleRoadCallCodeChangeSet.description') || '';
      const vehicle = store.peekAll('vehicle').filter(record => record.get('active') && record.get('roadCallCode.description') === description);

      if (vehicle.length) {
        tooltip.pushConfirmation({
          title: 'Lookup',
          hasoverlay: true,
          tip: `There are ${vehicle.length} active vehicle(s) with this Vehicle Road Call Code description. You must either change the Vehicle Road Call Code description on these vehicle(s) or delete these vehicle(s) prior to changing the Vehicle Road Call Code description.`,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.reset();
          }

        });
      }

      return vehicle.length;
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const tableRef = this.get('tableRef');
      let vehicleRoadCallCodeNameObj = this.get('pendingRecord');

      if (record) {
        vehicleRoadCallCodeNameObj = record;
        this.resetForm();
      }

      if (Ember.isEmpty(record)) {
        const data = {
          category: 'config-System_Configuration-vehicle_road_call_codes',
          type: 'object'
        };
        vehicleRoadCallCodeNameObj = store.createRecord('cs-config-item', data);
        this.set('pendingRecord', vehicleRoadCallCodeNameObj);
      }

      if (vehicleRoadCallCodeNameObj.get('isNew')) {
        vehicleRoadCallCodeNameObj.setProperties({
          code: null,
          description: null,
          tableRef: tableRef
        });
      }

      this.set('showErrorMsg', false);
      const tableRefRecords = this.get('tableRef.records') || [];
      tableRefRecords.map(tableRefRecord => {
        if (!tableRefRecord.get('tableRef')) {
          tableRefRecord.set('tableRef', tableRef);
        }
      });
      return new _emberChangeset.default(vehicleRoadCallCodeNameObj, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('vehicleRoadCallCodeChangeSet');

      if (selectedRecord) {
        if (!selectedRecord.get('isNew') && !selectedRecord.get('isDeleted')) {
          yield selectedRecord.data.reload();
        }
      }

      const tableRef = this.get('tableRef');
      this.resetForm();
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        editAPI.buttonSettings();
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const VehicleRoadCallCodeRecord = this.get('vehicleRoadCallCodeChangeSet');
      yield VehicleRoadCallCodeRecord.validate();
      const errors = VehicleRoadCallCodeRecord.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveVehicleRoadCallCodeRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');
      if (!isValid) return;
      const notifications = this.get('notifications');
      const code = this.get('vehicleRoadCallCodeChangeSet.code') || '';
      const description = this.get('vehicleRoadCallCodeChangeSet.description') || '';
      const value = {
        description: description,
        code: code
      };
      const vehicleRoadCallCodeChangeSet = this.get('vehicleRoadCallCodeChangeSet');
      let vehicleRoadCallCodeRecord = this.get('vehicleRoadCallCodeChangeSet');

      if (vehicleRoadCallCodeRecord.get('isNew')) {
        vehicleRoadCallCodeRecord = (0, _unwrapProxy.unwrapProxy)(this.get('vehicleRoadCallCodeChangeSet'));
        vehicleRoadCallCodeRecord.set('name', code);
      } else {
        const id = this.get('vehicleRoadCallCodeChangeSet.id');
        const store = this.get('store');
        const record = `config-System_Configuration-vehicle_road_call_codes/${id}`;
        vehicleRoadCallCodeRecord = store.peekRecord('cs-config-item', record);
      }

      vehicleRoadCallCodeRecord.set('description', description);
      vehicleRoadCallCodeRecord.set('code', code);
      vehicleRoadCallCodeRecord.set('value', value);

      try {
        yield vehicleRoadCallCodeRecord.save(); // changeset holds changes, execute the changes due to lookups using unorthodox saving practice
        // execute to change underlying object and rollback to remove internal changes list

        vehicleRoadCallCodeChangeSet.execute();
        vehicleRoadCallCodeChangeSet.rollback();
        notifications.success('Lookup record successfully saved.');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
            tooltip.reset();
          });
        }, 500);
      } catch (response) {
        if (response.errors[0].status === '409') {
          tooltip.pushConfirmation({
            title: 'Manage Driver/Vehicle',
            tip: 'Code cannot duplicate an existing Code.',
            primaryActionText: 'OK',
            primaryAction: () => {
              return tooltip.reset();
            }
          });
        } else {
          notifications.warning('Lookup record failed to save.');
        }

        if (vehicleRoadCallCodeRecord.rollbackAttributes) {
          vehicleRoadCallCodeRecord.rollbackAttributes();
        }

        console.warn(response); // eslint-disable-line
      }
    }).drop(),

    onEditVehicleRoadCallCodeRecord() {
      const editAPI = this.get('editAPI');
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onUndoVehicleRoadCallCodeRecord() {
      const vehicleRoadCallCodeChangeSet = this.get('vehicleRoadCallCodeChangeSet');
      const editAPI = this.get('editAPI');

      if (vehicleRoadCallCodeChangeSet) {
        vehicleRoadCallCodeChangeSet.rollback();
      }

      this.set('disableForm', true);
      editAPI.buttonSettings(false, false, false, true, true);
    },

    onNewVehicleRoadCallCodeRecord() {
      const editAPI = this.get('editAPI');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    actions: {
      onSaveVehicleRoadCallCodeRecord() {
        return this.onSaveVehicleRoadCallCodeRecord.perform();
      },

      onInputValueChange(record, valuePath, value) {
        this.set('currentDisplayName', value);
        record.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});