define("adept-iq/pods/components/workspace-navbar/notification-results/notification-responses-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'spacer',
    modelName: 'notificationResponse',
    title: 'Notifications',
    defaultSortId: 'riderId',
    defaultSortAsc: true,
    queryModelData: true,
    selectFirstRow: false,
    showSelectedRowLength: false,
    columns: [{
      id: 'destination',
      index: 0,
      type: 'test',
      label: 'Destination',
      valuePath: 'destination',
      defaultWidth: 110,
      searchable: true
    }, {
      id: 'riderId',
      index: 1,
      type: 'text',
      label: 'Rider ID',
      valuePath: 'riderId',
      defaultWidth: 110,
      searchable: true
    }, {
      id: 'status',
      index: 2,
      type: 'text',
      label: 'Status',
      valuePath: 'status',
      defaultWidth: 80,
      searchable: true
    }, {
      id: 'errorMessage',
      index: 3,
      type: 'text',
      label: 'Error Message',
      valuePath: 'errorMessage',
      defaultWidth: 450,
      searchable: true
    }]
  };
  _exports.default = _default;
});