define("adept-iq/mixins/active-context-binding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      const config = this.get('config');
      const refreshData = this.refreshData;
      const clearData = this.clearData;
      (true && !(Ember.typeOf(config) === 'object') && Ember.assert('mixin active-context-binding requires components, routes, controllers to provide a `config` object', Ember.typeOf(config) === 'object'));
      (true && !(Ember.isPresent(refreshData)) && Ember.assert('mixin active-context-binding requires components, routes, controllers to provide a refreshData function', Ember.isPresent(refreshData)));
      (true && !(Ember.isPresent(clearData)) && Ember.assert('mixin active-context-binding requires components, routes, controllers to provide a clearData function', Ember.isPresent(clearData)));
      this.addActiveDataBindings();
    },

    willDestroyElement() {
      this.removeActiveDataBindings();

      this._super(...arguments);
    },

    addActiveDataBindings() {
      this._onActiveContextRefresh = modelNames => {
        if (!modelNames.includes(this.get('config.modelName'))) {
          // not refreshing this model. give control back
          this.set('activeContext.topActiveContext.refreshInProgress', false);
          return;
        }

        this.refreshData();
      };

      this._onActiveContextClear = () => {
        this.clearData();
      };

      this.get('activeContext.topActiveContext').on('refresh', this._onActiveContextRefresh);
      this.get('activeContext.topActiveContext').on('clear', this._onActiveContextClear);
    },

    removeActiveDataBindings() {
      this.get('activeContext.topActiveContext').off('refresh', this._onActiveContextRefresh);
      this.get('activeContext.topActiveContext').off('clear', this._onActiveContextClear);
      this._onActiveContextRefresh = null;
      this._onActiveContextClear = null;
    }

  });

  _exports.default = _default;
});