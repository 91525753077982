define("adept-iq/pods/components/reoptimize-modal/operation-views/reoptimize/table/provider/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/reoptimize-modal/operation-views/reoptimize/table/provider/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['reoptimize-providers-table'],
    config: _config.default,
    paginationEnabled: false,
    showTableHeaderRow: false,
    showSelectedRowLength: false,
    showTableActions: false,

    init() {
      this._super(...arguments);
    },

    async didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    async query() {
      return this.get('allProvidersList');
    },

    actions: {
      onRowClick(row) {
        const isWaitlistedGenerate = this.get('isWaitlistedGenerate');
        if (isWaitlistedGenerate) return;

        this._super(row);
      }

    }
  });

  _exports.default = _default;
});