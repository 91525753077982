define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/equipment/component", ["exports", "moment", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/equipment/equipment-validation", "adept-iq/config/placeholders", "lodash", "adept-iq/config/environment"], function (_exports, _moment, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _equipmentValidation, _placeholders, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_EQUIPMENT_TYPE_MAP = {
    addVehicleEquipment: true,
    editVehicleEquipment: true,
    deleteVehicleEquipment: true
  };

  var _default = Ember.Component.extend({
    classNames: ['equipment'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // Placeholders
    MAINTENANCE_LOCATION_PLACEHOLDER: _placeholders.MAINTENANCE_LOCATION_PLACEHOLDER,
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    TYPE_PLACEHOLDER: _placeholders.TYPE_PLACEHOLDER,
    disableBtns: null,
    tableRef: null,
    equipmentTableRef: null,
    refreshRecord: true,
    //To refresh on undoRecord and newRecord
    isNewRecord: false,
    disableForm: true,
    deleteBtn: true,
    showErrorMsg: false,
    timeFormat: 'm/d/Y',
    // passed into view
    editAPI: null,
    selectedVehicle: Ember.computed.readOnly('editAPI.selectedRecord'),
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedEquipmentRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    vehicleEquipment: Ember.computed('selectedRow', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRow'));
      return this.setDefaultProperties(record);
    }),
    hasPendingChanges: Ember.computed('vehicleEquipment.changes.length', function () {
      const vehicleEquipment = this.get('vehicleEquipment');
      const changes = vehicleEquipment.get('changes') || [];
      return changes.length;
    }),

    init() {
      this._super(...arguments);

      const vehicle = this.get('selectedVehicle');
      this.set('timePlaceHolder', _environment.default.dateTimeFormat.dateMoment);
      this.set('equipmentQueryParams', {
        filter: `eq(vehicleId,${vehicle.get('id')})`
      });
      this.set('activityQueryParams', {
        vehicleId: `${vehicle.get('id')}`,
        eventLog: true
      });
      this.setupDropDownValues();
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleEquipmentRecord = this.get('onSaveVehicleEquipmentRecord');
      const onNewVehicleEquipmentRecord = this.get('onNewVehicleEquipmentRecord').bind(this);
      const onEditVehicleEquipmentRecord = this.get('onEditVehicleEquipmentRecord').bind(this);
      const onDeleteVehicleEquipmentRecord = this.get('onDeleteVehicleEquipmentRecord');
      const onUndoVehicleEquipmentRecord = this.get('onUndoVehicleEquipmentRecord').bind(this);
      const hasChanges = this.get('hasChanges').bind(this);
      const onValidateAction = this.get('validate');
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleEquipmentRecord,
          newAction: onNewVehicleEquipmentRecord,
          deleteAction: onDeleteVehicleEquipmentRecord,
          editAction: onEditVehicleEquipmentRecord,
          undoAction: onUndoVehicleEquipmentRecord,
          onValidateAction,
          hasChanges,
          isChangesHappened: false
        });
      });
      this.addObserver('hasPendingChanges', this.hasPendingChangesObserver);
      this.get('refreshTask').perform().then(() => {
        Ember.run.later(() => {
          this.resetForm();
        }, 1000);
      });
    },

    hasChanges() {
      const vehicleEquipment = this.get('vehicleEquipment');

      if (vehicleEquipment.get('isNew')) {
        const pendingChanges = this.get('vehicleEquipment');
        const fields = (0, _lodash.flatten)(pendingChanges.changes).map(change => change.key);
        const isEveyPrePoulatedFieldsChange = fields.length ? fields.every(field => field.includes('installDate')) : false;
        if (isEveyPrePoulatedFieldsChange) return !isEveyPrePoulatedFieldsChange;
        return !!this.get('hasPendingChanges');
      }

      return !!this.get('hasPendingChanges');
    },

    hasPendingChangesObserver() {
      const editAPI = this.get('editAPI');
      const hasPendingChanges = this.get('hasPendingChanges');
      Ember.run.scheduleOnce('afterRender', () => {
        editAPI.setProperties({
          isChangesHappened: hasPendingChanges
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let equipmentRecord = record || this.get('pendingEquipmentRecord');
      const selectedVehicle = this.get('selectedVehicle');

      if (record) {
        this.resetForm();
      } else if (Ember.isEmpty(equipmentRecord)) {
        const store = this.get('store');
        equipmentRecord = store.createRecord('vehicle-equipment', {
          vehicle: selectedVehicle
        });
        this.set('pendingEquipmentRecord', equipmentRecord);
      }

      if (equipmentRecord.get('isNew')) {
        const today = (0, _moment.default)(new Date()).toISOString();
        equipmentRecord.set('installDate', today);
        equipmentRecord.setProperties({
          installDate: today,
          notes: null,
          type: null,
          specialized: false
        });
      }

      this.set('showErrorMsg', false);
      return new _emberChangeset.default(equipmentRecord, (0, _emberChangesetValidations.default)(_equipmentValidation.VALIDATIONS), _equipmentValidation.VALIDATIONS);
    },

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_EQUIPMENT_TYPE_MAP[event.get('actionType')];
    },

    setupDropDownValues() {
      const store = this.get('store');
      const dropDownModels = ['maintenance-location-name'];
      dropDownModels.forEach(model => {
        const records = store.peekAll(model);
        this.set(model, records);
      });
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const vehicleEquipment = this.get('vehicleEquipment');

      if (vehicleEquipment) {
        if (!vehicleEquipment.get('isNew') && !vehicleEquipment.get('isDeleted')) {
          yield vehicleEquipment.data.reload();
        }
      }

      const tableRef = this.get('tableRef');
      const equipmentTableRef = this.get('equipmentTableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      if (equipmentTableRef) Ember.run.scheduleOnce('afterRender', equipmentTableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        const records = this.get('tableRef.records');

        if (!records.length) {
          editAPI.buttonSettings(true, false, true, true, true);
        } else {
          editAPI.buttonSettings();
        }
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const vehicleEquipment = this.get('vehicleEquipment');
      yield vehicleEquipment.validate();
      const errors = vehicleEquipment.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveVehicleEquipmentRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');

      if (isValid) {
        const notifications = this.get('notifications');
        const vehicleEquipmentRecord = (0, _unwrapProxy.unwrapProxy)(this.get('vehicleEquipment'));

        try {
          yield vehicleEquipmentRecord.save();
          notifications.success('Record successfully saved.');
          this.set('pendingEquipmentRecord', null);
          this.get('refreshTask').perform().then(() => {
            Ember.run.later(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
              this.resetForm();
              tooltip.reset();
            }, 1000);
          });
          return vehicleEquipmentRecord;
        } catch (e) {
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line
        }
      }
    }),

    onEditVehicleEquipmentRecord() {
      const editAPI = this.get('editAPI');
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onDeleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRow = this.get('selectedRow');
      const notifications = this.get('notifications');

      try {
        yield selectedRow.destroyRecord();
        notifications.success('Record successfully deleted');
        this.get('refreshTask').perform().then(() => {
          Ember.run.later(() => {
            this.resetForm();
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
          }, 1000);
        });
      } catch (e) {
        notifications.warning('Record failed to delete');
        console.warn(e); // eslint-disable-line
      }
    }),
    onDeleteVehicleEquipmentRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRow = this.get('selectedRow');
      const tooltip = this.get('tooltip');
      const vehicleName = this.get('selectedVehicle.name');
      if (!selectedRow) return;
      yield tooltip.pushConfirmation({
        title: 'Manage Vehicle',
        tip: `Are you sure you want to delete equipment record for vehicle ${vehicleName}?`,
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        },

        primaryActionText: 'Confirm',
        primaryAction: () => {
          this.get('onDeleteRecord').perform();
          return tooltip.reset();
        }
      });
    }),

    onUndoVehicleEquipmentRecord() {
      const vehicleEquipment = this.get('vehicleEquipment');

      if (vehicleEquipment) {
        vehicleEquipment.rollback();
      }
    },

    onNewVehicleEquipmentRecord() {
      const selectedEquipmentRow = this.get('selectedEquipmentRow');
      const editAPI = this.get('editAPI');

      if (selectedEquipmentRow) {
        selectedEquipmentRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    actions: {
      onSaveVehicleEquipmentRecord() {
        return this.onSaveVehicleEquipmentRecord.perform();
      },

      onDeleteVehicleEquipmentRecord() {
        return this.onDeleteVehicleEquipmentRecord.perform();
      },

      onDateValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});