define("adept-iq/serializers/subscription", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    attrs: {
      remainingOccurrences: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      if (json.data && json.data.attributes) delete json.data.attributes.serviceWindowName;

      if (json.data && json.data.relationships) {
        const fareTypeRelationship = json.data.relationships.fareType;
        const serviceWindow = json.data.relationships.serviceWindow;
        const origin = json.data.relationships.origin;
        const destination = json.data.relationships.destination;
        const phoneNumbers = json.data.relationships.phoneNumbers;
        const fundingAgency = json.data.relationships.fundingAgency;
        const fareCategoryRelationship = json.data.relationships.fareCategory || {};
        const tripPurposeRelationship = json.data.relationships.tripPurpose || {};
        const routeTemplateRelationship = json.data.relationships.routeTemplate;

        if (!Ember.isNone(routeTemplateRelationship) && Ember.isNone(routeTemplateRelationship.data)) {
          json.data.relationships.routeTemplate.data = {
            type: 'routeTemplate',
            id: 'deleted'
          };
        }

        if (!Ember.isNone(fareCategoryRelationship) && !Ember.isNone(fareCategoryRelationship.data)) {
          fareCategoryRelationship.data.type = 'fareCategoryName';
          json.data.relationships.fareCategoryName = json.data.relationships.fareCategory;
          delete json.data.relationships.fareCategory;
        }

        if (!Ember.isNone(fareTypeRelationship)) {
          fareTypeRelationship.data.type = 'fareTypeName';
          json.data.relationships.fareTypeName = json.data.relationships.fareType;
          delete json.data.relationships.fareType;
        }

        if (serviceWindow) {
          delete json.data.relationships.serviceWindow;
        }

        if (fundingAgency) {
          delete json.data.relationships.fundingAgency;
        }

        if (!Ember.isNone(tripPurposeRelationship) && !Ember.isNone(tripPurposeRelationship.data)) {
          tripPurposeRelationship.data.type = 'tripPurposeName';
          json.data.relationships.tripPurposeName = json.data.relationships.tripPurpose;
          delete json.data.relationships.tripPurpose;
        }

        if (origin) {
          json.data.relationships.originPlace = json.data.relationships.origin;
          delete json.data.relationships.origin;
        }

        if (destination) {
          json.data.relationships.destinationPlace = json.data.relationships.destination;
          delete json.data.relationships.destination;
        }

        if (phoneNumbers) {
          const originPhoneNumber = phoneNumbers.data.firstObject;
          const destinationPhoneNumber = phoneNumbers.data.lastObject;

          if (originPhoneNumber) {
            json.data.relationships.originPhoneNumber = {
              data: {
                type: originPhoneNumber.type,
                id: originPhoneNumber.id
              }
            };
          }

          if (destinationPhoneNumber) {
            json.data.relationships.destinationPhoneNumber = {
              data: {
                type: destinationPhoneNumber.type,
                id: destinationPhoneNumber.id
              }
            };
          }
        }

        delete json.data.relationships.phoneNumbers;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes)) {
        if (!Ember.isNone(resourceHash.attributes.fareTypeName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.fareType = {
            data: {
              type: 'fare-type',
              id: resourceHash.attributes.fareTypeName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.fareTypeName;
        }

        if (!Ember.isNone(resourceHash.attributes.serviceWindowName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.serviceWindow = {
            data: {
              type: 'service-window',
              id: resourceHash.attributes.serviceWindowName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.serviceWindowName;
        }

        if (!Ember.isNone(resourceHash.attributes.providerName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.provider = {
            data: {
              type: 'provider',
              id: resourceHash.attributes.providerName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.providerName;
        }
      }

      if (!Ember.isNone(resourceHash.relationships)) {
        if (!Ember.isNone(resourceHash.relationships.originPlace)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.origin = resourceHash.relationships.originPlace;
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.originPlace;
        }

        if (!Ember.isNone(resourceHash.relationships.destinationPlace)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.destination = resourceHash.relationships.destinationPlace;
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.destinationPlace;
        } //regarding this ticket NYAAR-14603


        if (!Ember.isNone(resourceHash.relationships.serviceWindowName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.serviceWindow = {
            data: {
              type: 'service-window',
              id: resourceHash.relationships.serviceWindowName.data.id
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.serviceWindowName;
        }

        if (!Ember.isNone(resourceHash.relationships.fundingAgencyName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.fundingAgency = {
            data: {
              type: 'fundingAgency',
              id: resourceHash.relationships.fundingAgencyName.data.id
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.fundingAgencyName;
        }

        if (!Ember.isNone(resourceHash.relationships.tripPurposeName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.tripPurpose = {
            data: {
              type: 'trip-purpose',
              id: resourceHash.relationships.tripPurposeName.data.id
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.tripPurposeName;
        }

        if (!Ember.isNone(resourceHash.relationships.fareCategoryName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.fareCategory = {
            data: {
              type: 'fare-category',
              id: resourceHash.relationships.fareCategoryName.data.id
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.fareCategoryName;
        }

        if (!Ember.isNone(resourceHash.relationships.recurrencePatterns)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          const dataArray = relationships.recurrencePatterns.data;
          dataArray.map(data => {
            data.type = 'subscription-recurrence-pattern';
          });
        }

        if (!Ember.isNone(resourceHash.relationships.exclusions) && !Ember.isNone(resourceHash.relationships.exclusions) && !Ember.isNone(resourceHash.relationships.exclusions.data)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          const dataArray = relationships.exclusions.data;
          dataArray.map(data => {
            data.type = 'subscription-exclusion';
          });
        }

        if (!Ember.isNone(resourceHash.relationships.originPhoneNumber) || !Ember.isNone(resourceHash.relationships.destinationPhoneNumber)) {
          const phoneNumberData = {
            data: []
          };
          const originPhoneNumberData = resourceHash.relationships.originPhoneNumber ? resourceHash.relationships.originPhoneNumber.data : {};
          const destinationPhoneNumberData = resourceHash.relationships.destinationPhoneNumber ? resourceHash.relationships.destinationPhoneNumber.data : {};
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};

          if (Object.keys(originPhoneNumberData).length !== 0) {
            phoneNumberData.data.push(originPhoneNumberData);
          }

          if (Object.keys(destinationPhoneNumberData).length !== 0) {
            phoneNumberData.data.push(destinationPhoneNumberData);
          }

          relationships.phoneNumbers = phoneNumberData;
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.originPhoneNumber;
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeArrayResponse(store, primaryModelClass, payload) {
      if (!Ember.isNone(payload.included) && !Ember.isNone(payload.included.filterBy('type', 'segmentStop'))) {
        const newIncluded = payload.included.filter(include => {
          return include.type !== 'fareCategoryName';
        });
        payload.included = newIncluded;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});