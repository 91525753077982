define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/read-time-cell-item", ["exports", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-item"], function (_exports, _cellItem) {
  'use strict';

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const READ_TIME_PROPERTY_NAME = 'readTime';
  const YES_VALUE = 'Yes';
  const NO_VALUE = 'No';

  class ReadTimeCellItem extends _cellItem.default {
    constructor(props) {
      super(props);
    }

    getColumnValue(data, columnInfo, rowIndex) {
      const currRowData = this.getRowData(data, rowIndex);
      const columnValue = currRowData[READ_TIME_PROPERTY_NAME] ? YES_VALUE : NO_VALUE;
      return columnValue;
    }

  }

  _exports.default = ReadTimeCellItem;
});