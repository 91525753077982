define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map-filter/legend-options", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel"], function (_exports, _react, _panel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const LegendOptions = () => {
    const {
      mapEventFilters,
      toggleMapEventFilter
    } = (0, _panel.usePanel)();
    return _react.default.createElement("div", {
      className: "map-legend-filter ember-view"
    }, _react.default.createElement("table", {
      id: "map-legend-table"
    }, _react.default.createElement("thead", null, _react.default.createElement("tr", null, _react.default.createElement("th", null, _react.default.createElement("h4", null, "Legend")))), _react.default.createElement("tbody", null, mapEventFilters.filter(filter => filter.canFilter).map(filter => {
      return _react.default.createElement("tr", {
        key: `legend-${filter.id}`
      }, _react.default.createElement("td", {
        className: "legend-checkbox"
      }, _react.default.createElement("input", {
        id: "ember2049",
        className: "data-test-type-visibility-checkbox ember-checkbox ember-view",
        type: "checkbox",
        checked: filter.isChecked,
        onChange: e => toggleMapEventFilter(filter.id, e.target.checked)
      })), _react.default.createElement("td", null, _react.default.createElement("div", {
        className: "map-legend-filter-icon"
      }, filter.img)), _react.default.createElement("td", null, _react.default.createElement("b", null, filter.displayName)));
    }))));
  };

  var _default = LegendOptions;
  _exports.default = _default;
});