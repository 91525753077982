define("adept-iq/pods/components/form-components/quick-filters/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    maximizer: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    classNames: ['column-widget-filter'],
    classNameBindings: ['isActive'],
    isActive: false,

    init() {
      this._super(...arguments);

      this.filter.quickFilterComponent = this;
      this.setIsActive();
      this.get('maximizer.isWidgetMinified');
    },

    didInsertElement() {
      this._super(...arguments);

      const widgetInstanceId = `${this.get('widget.typeId')}_${this.get('widget.id')}`;
      const newlyCreatedQuickFilter = this.get('workspace.newlyCreatedQuickFilter');
      const filter = this.get('filter');

      if (Ember.isPresent(newlyCreatedQuickFilter) && widgetInstanceId === newlyCreatedQuickFilter.widgetInstanceId && filter.id === newlyCreatedQuickFilter.id) {
        this.toggleFilter();
        this.set('workspace.newlyCreatedQuickFilter', null);
      }
    },

    updateWidgetStateQuickFilter(isActive) {
      const filterId = this.get('filter.id');
      const widgetType = this.get('widget.typeId');
      const widgetStates = { ...this.get('workspace.widgetStates')
      };
      const widgetState = widgetStates[widgetType] || {};
      const quickFilters = widgetState.quickFilters || [];
      const foundQuickFilter = quickFilters.find(quickFilter => quickFilter.id === filterId);
      foundQuickFilter.active = isActive;
    },

    clearWidgetStateQuickFilter() {
      const widgetType = this.get('widget.typeId');
      const widgetStates = { ...this.get('workspace.widgetStates')
      };
      const widgetState = widgetStates[widgetType] || {};
      const quickFilters = widgetState.quickFilters || [];
      quickFilters.forEach(quickFilter => {
        quickFilter.active = false;
      });
    },

    getWidgetStateQuickFilter() {
      const filterId = this.get('filter.id');
      const widgetType = this.get('widget.typeId');
      const widgetStates = { ...this.get('workspace.widgetStates')
      };
      const widgetState = widgetStates[widgetType] || {};
      const quickFilters = widgetState.quickFilters || [];
      const foundQuickFilter = quickFilters.find(quickFilter => quickFilter.id === filterId);
      return foundQuickFilter;
    },

    onFilterChanged: Ember.observer('isActive', function () {
      this.set('filter.active', this.get('isActive'));
    }),
    isWidgetMinifiedChanged: Ember.observer('maximizer.isWidgetMinified', function () {
      const isWidgetMinified = this.get('maximizer.isWidgetMinified');

      if (isWidgetMinified) {
        const maximizedWidget = this.get('maximizer.maximizedWidget');
        const maximizedWidgetInstanceId = `${maximizedWidget.typeId}_${maximizedWidget.id}`;
        const activeQuickFilter = this.get('maximizer.activeQuickFilter');
        const widgetInstanceId = `${this.get('widget.typeId')}_${this.get('widget.id')}`;

        if (maximizedWidgetInstanceId === widgetInstanceId) {
          if (Ember.isPresent(activeQuickFilter) && activeQuickFilter.id === this.get('filter.id')) {
            this.set('isActive', true);
          } else {
            this.set('isActive', false);
          }
        }

        this.get('onRefresh')();
      }
    }),

    toggleFilter() {
      const active = this.get('isActive');
      this.clearFilters();

      if (!active) {
        this.set('isActive', true);
        this.updateWidgetStateQuickFilter(this.get('isActive'));
      }

      this.get('onRefresh')();
    },

    clearFilters() {
      const columnList = this.get('tableColumns') || [];
      const quickFilters = this.get('quickFilters') || [];
      const columns = columnList.reduce((obj, column) => {
        obj[column.id] = {
          filterTypeId: 'null',
          // this is actually the id of the null filter
          filterValues: null
        };
        return obj;
      }, {});
      quickFilters.forEach(filter => {
        const active = filter.quickFilterComponent.get('isActive');

        if (active) {
          const quickFilterComponent = filter.quickFilterComponent;

          if (!quickFilterComponent.isDestroyed) {
            quickFilterComponent.set('isActive', false);
          }
        }
      });
      this.clearWidgetStateQuickFilter();
      this.get('widget').mergeState({
        columns
      });
    },

    clearSpecificFilter(removeFilter) {
      const quickFilters = this.get('quickFilters') || [];
      quickFilters.forEach(filter => {
        const active = filter.quickFilterComponent.get('isActive');

        if (active && filter.id === removeFilter.id) {
          const quickFilterComponent = filter.quickFilterComponent;

          if (!quickFilterComponent.isDestroyed) {
            quickFilterComponent.set('isActive', false);
            quickFilterComponent.updateWidgetStateQuickFilter(false);
            quickFilterComponent.get('onRefresh')();
          }
        }
      });
    },

    removeQuickFilter() {
      const currentId = this.get('filter.id');
      const widgetTypeId = this.get('widget.typeId');
      const widgetStates = { ...this.get('workspace.widgetStates')
      };
      const widgetState = widgetStates[widgetTypeId] || {};
      const quickFilters = widgetState.quickFilters || []; // remove from local copy

      _lodash.default.remove(quickFilters, filter => {
        const removeFilter = filter.id === currentId;
        if (removeFilter && filter.widgetInstanceId) this.clearSpecificFilter(filter);
        return removeFilter;
      });

      this.set('workspace.widgetStates', widgetStates); // remove from dashboard model

      const dashboardWidgetStates = { ...this.get('workspace.dashboardInstance.widgetStates')
      };
      const dashboardWidgetState = dashboardWidgetStates[widgetTypeId] || {};
      const dashboardQuickFilters = dashboardWidgetState.quickFilters || [];

      _lodash.default.remove(dashboardQuickFilters, filter => {
        return filter.id === currentId;
      });

      this.set('workspace.dashboardInstance.widgetStates', dashboardWidgetStates);
    },

    setIsActive() {
      const quickFilterComponent = this.filter.quickFilterComponent;

      if (!quickFilterComponent.isDestroyed) {
        quickFilterComponent.set('isActive', this.get('filter.active'));
      }
    },

    actions: {
      onRemoveFilterClick() {
        const tooltip = this.get('tooltip');
        const filterName = this.get('filter.displayName');
        tooltip.pushConfirmation({
          title: 'Manage Filter',
          tip: `Are you sure you want to delete the ${filterName} filter?`,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            this.removeQuickFilter();
            return tooltip.reset();
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          }
        });
      },

      onFilterApplyAction() {
        this.toggleFilter();
      }

    }
  });

  _exports.default = _default;
});