define("adept-iq/serializers/reconcile-verify-route", ["exports", "adept-iq/serializers/-reconciliationService"], function (_exports, _reconciliationService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reconciliationService.default.extend({
    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeQueryResponse(store, clazz, payload) {
      // workaround for this API not returns payload type
      const _data = [].concat(payload.data);

      payload.data = _data;
      const resultedPayload = [];

      if (!Ember.isEmpty(payload.data)) {
        payload.data.forEach(item => {
          resultedPayload.push(item);
        });
        payload.data = resultedPayload;
      }

      return this._super(store, clazz, payload);
    },

    modelNameFromPayloadKey(key) {
      if (key === 'verify') {
        return 'reconcile-verify-route';
      }

      return this._super(key);
    }

  });

  _exports.default = _default;
});