define("adept-iq/pods/components/maximize-widget/component", ["exports", "adept-iq/config/widget-types"], function (_exports, _widgetTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    maximizer: Ember.inject.service(),
    widgetTypesHash: _widgetTypes.widgetTypesHash,
    widget: Ember.computed.alias('maximizer.maximizedWidget'),

    didInsertElement() {
      this._super(...arguments); // set maximize widget content to size of the wrapper


      if (this.get('widget')) {
        const height = Ember.$('.dashboard-wrapper').height(); // if set min-hight will trigger widget resize infinitely

        Ember.$('.maximized-widget-content').css('height', `${height}px`);
        Ember.$('.maximized-widget-content').css('overflow', 'hidden');
      }
    }

  });

  _exports.default = _default;
});