define("adept-iq/pods/components/generic-components/table/component", ["exports", "adept-iq/pods/components/generic-components/base-table/component", "ember-light-table", "ember-concurrency", "moment", "papaparse", "adept-iq/classes/data-joins/table", "adept-iq/pods/components/generic-components/table/classes/sort-column", "adept-iq/utils/compute-ordered-ids", "adept-iq/utils/mobile", "adept-iq/utils/remove-focus", "adept-iq/pods/components/generic-components/table/classes/checkbox-row", "adept-iq/pods/components/generic-components/table/template", "adept-iq/config/column-types", "adept-iq/utils/sorts", "adept-iq/config/environment", "adept-iq/utils/filters"], function (_exports, _component, _emberLightTable, _emberConcurrency, _moment, _papaparse, _table, _sortColumn, _computeOrderedIds, _mobile, _removeFocus, _checkboxRow, _template, _columnTypes, _sorts, _environment, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // default currently
  const logger = {
    log: () => null,
    warn: console.warn // eslint-disable-line no-console

  };
  const DEFAULT_COLUMN_WIDTH = 100;
  const MIN_COLUMN_WIDTH = 50;
  const CHECKBOX_COLUMN_WIDTH = 30; // number of records to load at a time while scrolling

  const PAGINATION_LIMIT = 50; // how close to bottom do we need to scroll before loading more

  const PAGINATION_TRIGGER_OFFSET = 100;
  const ENUM_TYPE = 'enum';

  const toPixels = x => `${x}px`;

  const fromPixels = x => parseInt(x.split('px')[0], 10);

  const isValidDateConfig = config => {
    return ['date', 'datetime', 'time', 'datetimeflatpickr'].includes(config.type) && config.format;
  };

  const formatDateConfigType = (value, config) => {
    const prefixExportDateFormat = config.prefixExportDateFormat;
    const exportFormat = config.exportFormat;

    if (Ember.isEmpty(value) || !isValidDateConfig(config)) {
      return value;
    } // for export data. prefix of ' ' will take this format value instead
    // of excel converting it to it's own default format


    if (!Ember.isEmpty(exportFormat)) {
      if (!Ember.isEmpty(prefixExportDateFormat)) {
        return `${prefixExportDateFormat}${(0, _moment.default)(value).format(exportFormat)}`;
      }

      return (0, _moment.default)(value).format(exportFormat);
    }

    return (0, _moment.default)(value).format(config.format);
  };

  const RADIO_ROW_SELECTION_TYPE = 'radio';
  const TABLE_PARENT_ELEMENT_TAG = 'OCCLUDED-CONTENT';

  var _default = _component.default.extend({
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    interaction: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    work: Ember.inject.service(),
    classNameBindings: ['disabled'],
    // necessary to allow derived table components to inherit template
    layout: _template.default,
    // overload this with a static config object
    config: null,
    // overload this with row class
    Row: _checkboxRow.default,
    // override for tables that don't need a header
    showHeader: true,
    // override for table that don't need a table header row
    showTableHeaderRow: true,
    // override for tableAction
    showTableActions: true,
    // append to table actions
    tableActions: null,
    // override to trigger loading icon on table
    isTaskRunning: null,
    // table is using radio button on changing rows a popup will ask user to continue editing or not
    // any dirty record is used to tell the table there are unsaved changes
    hasPendingChanges: false,
    // enable warning tooltip (action modal)
    enableWarningRowChangeAlert: false,
    defaultCheckboxRowSelected: false,
    // allow the developer the ability to disable all row selections
    disableRowSelection: false,
    queryParams: null,
    limit: PAGINATION_LIMIT,
    offset: 0,
    paginationEnabled: false,
    // pagination disabled by default
    records: null,
    visibleRecords: null,
    searchText: '',
    isSearchEnabled: false,
    isSearchAllowed: true,
    // set for demo
    isSavingInProgress: false,
    table: null,
    dataJoin: null,
    lastRowClicked: false,
    inlineRowMenu: null,
    inlineRowMenuPosition: null,
    refreshSync: false,
    resetScroll: false,
    isPaginating: false,
    showSelectedRowLength: true,
    disableHeaderActions: null,
    disableSaveBtn: false,
    disableEditBtn: false,
    disableDeleteBtn: false,
    disableAdditionalBtn: false,
    onCancelButtonEnable: false,
    sortId: Ember.computed.alias('state.sortId'),
    sortAsc: Ember.computed.alias('state.sortAsc'),
    isLoading: Ember.computed.alias('fetchDataTask.isRunning'),
    isError: Ember.computed.alias('fetchDataTask.last.isError'),
    usingRadioRowComponent: Ember.computed.equal('config.rowSelectionType', RADIO_ROW_SELECTION_TYPE),
    checkedRows: Ember.computed('table.selectedRows.[]', function () {
      const checkedRows = this.get('table.selectedRows');
      return checkedRows.map(row => row.get('record'));
    }),
    tableColumns: Ember.computed('config.{columns,columns.[]}', function () {
      let isCheckBox = true;
      const tableColumns = [];
      const columnConfigs = this.get('config.columns');
      const orderedColumnIds = (0, _computeOrderedIds.default)(columnConfigs) || [];
      const radioSelction = this.get('usingRadioRowComponent');
      const hideCheckBox = this.get('config.hideCheckBox');

      if (!Ember.isEmpty(this.get('config.rowSelectionType'))) {
        isCheckBox = this.get('config.rowSelectionType') === 'checkbox';
      }

      if (isCheckBox) {
        tableColumns.push(hideCheckBox ? {
          id: 'checkbox',
          sortable: false,
          isResizable: true,
          width: '0px'
        } : {
          id: 'checkbox',
          valuePath: 'checked',
          cellComponent: 'generic-components/table/cells/check-box',
          component: 'generic-components/table/columns/check-box',
          sortable: false,
          isResizable: true,
          width: _mobile.isTouchDevice ? '70px' : toPixels(CHECKBOX_COLUMN_WIDTH)
        });
      } else if (radioSelction) {
        tableColumns.push(hideCheckBox ? {
          id: 'checkbox',
          sortable: false,
          isResizable: true,
          width: '0px'
        } : {
          id: 'radio',
          valuePath: 'checked',
          cellComponent: 'generic-components/table/cells/radio-button',
          sortable: false,
          isResizable: true,
          width: _mobile.isTouchDevice ? '70px' : toPixels(CHECKBOX_COLUMN_WIDTH)
        });
      }

      orderedColumnIds.forEach(id => {
        const config = columnConfigs.findBy('id', id);
        const tableColumn = {
          id: id,
          component: 'generic-components/table/columns/sort-capable',
          cellClassNames: config.id,
          classNames: config.id,
          label: config.label,
          cellDesc: config.cellDesc,
          valuePath: `record.${config.valuePath}`,
          valuePaths: config.valuePaths,
          hidden: config.hidden || config.unAvailable,
          resizable: Ember.isPresent(config.resizable) ? config.resizable : true,
          minResizeWidth: MIN_COLUMN_WIDTH,
          width: toPixels(config.defaultWidth || DEFAULT_COLUMN_WIDTH),
          config
        };

        if (config.type.includes('enum')) {
          tableColumn.cellComponent = 'table/cells/text-extension';
        } else if (config.type.includes('text-break')) {
          tableColumn.cellComponent = 'table/cells/text-break';
        } else {
          tableColumn.cellComponent = 'table/cells/base-cell';
        }

        if (config.highlightable) {
          tableColumn.cellClassNames = ['highlightable'];
        }

        if (['date', 'datetime', 'time', 'datetimeflatpickr'].includes(config.type) && config.format) {
          tableColumn.format = value => {
            return Ember.isEmpty(value) ? value : (0, _moment.default)(value).format(config.format);
          };

          switch (config.type) {
            case 'date':
            case 'datetimeflatpickr':
            case 'datetime':
              tableColumn.parse = value => {
                return (0, _moment.default)(value, config.format).toDate();
              };

              break;

            case 'time':
              tableColumn.parse = (timeString, originalValue) => {
                const date = (0, _moment.default)(originalValue).format(_environment.default.dateTimeFormat.dateMoment);
                const newValue = (0, _moment.default)(`${date} ${timeString}`, _environment.default.dateTimeFormat.dateTimeMoment).toDate();
                return newValue;
              };

              break;

            default:
              break;
          }
        }

        tableColumns.push(tableColumn);
      }); // fills the rest of the space with an empty column

      tableColumns.push({
        id: 'spacer',
        width: '100%',
        sortable: false,
        isResizable: true
      });
      return tableColumns;
    }),
    click: function (event) {
      if (['fa fa-search active-button', 'table-component-search-box data-test-search-box'].includes(event.target.className)) {
        return false;
      }

      (0, _removeFocus.removeFocus)();
    },

    init() {
      this._super(...arguments);

      const config = this.get('config');
      (true && !(Ember.typeOf(config) === 'object') && Ember.assert('{{generic-components/table}} requires extending components to provide a `config` object', Ember.typeOf(config) === 'object'));
      const Row = this.get('Row');
      const table = new _emberLightTable.default([], [], {
        enableSync: false
      });

      const dataJoin = _table.default.create({
        table,
        activeContext: this.get('activeContext'),
        work: this.get('work'),
        Row
      });

      this.setProperties({
        table,
        dataJoin,
        records: [],
        visibleRecords: []
      });
      this.set('tableActions', [{
        id: 'print',
        name: 'Download',
        action: () => {
          this.exportData();
        }
      }]);

      if (!Ember.isEmpty(this.get('config.showSelectedRowLength'))) {
        this.set('showSelectedRowLength', this.get('config.showSelectedRowLength'));
      }

      if (this.get('config.showSelectedRowLength') === false) {
        this.set('showSelectedRowLength', this.get('config.showSelectedRowLength'));
      }

      this.set('selectFirstRowCache', this.get('config.selectFirstRow'));
      this.cancelRow = this.cancelRow.bind(this);
    },

    destroy() {
      const tableColumns = this.get('table.columns') || [];
      this.get('dataJoin').destroy(); // destroy data join to exit each row

      tableColumns.forEach(column => column.destroy()); // destroy column component

      this.get('table').destroy(); // destroy table component

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.onTableColumnsChange();
      this.onRecordsChange();
      Ember.run.scheduleOnce('afterRender', this, 'refreshData');
      this.onKeyDownEvent = this.onKeyDownEvent.bind(this); // listen for column resize

      if (typeof MutationObserver !== 'undefined') {
        const mutationObserver = new MutationObserver(mutationList => {
          if (mutationList[0].target.classList.contains('is-resizing')) {
            this.get('interaction').didStartResizingColumn();
          } else {
            this.get('interaction').didStopResizingColumn();
          }
        });
        const el = this.$('.ember-light-table')[0];

        if (el) {
          mutationObserver.observe(el, {
            attributeFilter: ['class']
          });
          this.set('mutationObserver', mutationObserver);
        }
      }

      document.addEventListener('keydown', this.onKeyDownEvent);
    },

    didRender() {
      this._super(...arguments);

      if (this.get('table.selectedRows.length') === 0 && this.get('table.rows.length') > 0) {
        Ember.run.scheduleOnce('afterRender', this, () => this.firstRowSelected());
      }
    },

    willDestroyElement() {
      const selectFirstRowCache = this.get('selectFirstRowCache');
      this.$().off('keydown', this._onKeyDown);
      this.$().off('mousedown', this._onMouseDown);
      document.removeEventListener('keydown', this.onKeyDownEvent);
      this.onUncheckAll(); // unselect each row

      this.get('dataJoin').clear();
      this.clearData();
      this.set('config.selectFirstRow', selectFirstRowCache);
      const mutationObserver = this.get('mutationObserver');

      if (typeof MutationObserver !== 'undefined' && mutationObserver) {
        mutationObserver.disconnect();
      }

      this._super(...arguments);
    },

    onKeyDownEvent(event) {
      const ltCell = event.target.closest('.lt-cell');

      if (!ltCell) {
        return;
      }

      event.preventDefault();
      const row = ltCell.closest('.lt-row');
      let siblingElementRow = null; // eslint-disable-next-line default-case

      switch (event.key) {
        case 'ArrowDown':
          {
            siblingElementRow = row.nextElementSibling;
            break;
          }

        case 'ArrowUp':
          {
            siblingElementRow = row.previousElementSibling;
            break;
          }
      }

      if (siblingElementRow && siblingElementRow.tagName !== TABLE_PARENT_ELEMENT_TAG) {
        const tableRows = this.get('table.rows'); // move table selection down one row

        const recordId = siblingElementRow.getAttribute('data-record-id');
        const siblingTableElementRow = tableRows.findBy('content.id', recordId);
        tableRows.setEach('selected', false);
        siblingTableElementRow.set('selected', true);
      }
    },

    setTableColumns() {
      const oldColumns = this.get('table.columns').slice() || [];
      const tableColumns = this.get('tableColumns') || [];
      this.set('table.columns', tableColumns.map(col => new _sortColumn.default(col)));
      oldColumns.forEach(column => column.destroy());
    },

    updateTableColumns() {
      const state = this.get('state') || {};
      const config = this.get('config') || {};
      const columns = this.get('table.columns') || [];
      let shouldResize = false;
      let scrollLeft;
      const body = this.$('.table-component-body');

      if (body) {
        scrollLeft = this.$('.table-component-body').scrollLeft();
      }

      if (columns) {
        const sortId = state.sortId || config.defaultSortId;
        columns.forEach(column => {
          let sortAsc = column.ascending;
          const sorted = column.sorted;
          column.set('sorted', sorted);
          column.set('ascending', sortAsc);

          if (Ember.isPresent(sortId) && sortId === column.get('id')) {
            if (Ember.isPresent(state.sortAsc)) {
              sortAsc = state.sortAsc;
            } else if (Ember.isPresent(config.defaultSortAsc)) {
              sortAsc = config.defaultSortAsc;
            }

            column.set('sorted', true);
            column.set('ascending', sortAsc);
          }
        });
      }

      const columnsHash = columns || {};
      Object.entries(columnsHash).forEach(_ref => {
        let [id, columnState] = _ref;
        const column = columns.findBy('id', id);

        if (!column) {
          return;
        }

        if (Ember.isPresent(columnState.width)) {
          column.set('width', toPixels(columnState.width));
          shouldResize = true;
        }
      });

      if (shouldResize) {
        // resize at end of _next_ loop so that all changes are applied
        Ember.run.next(() => {
          if (Ember.isPresent(scrollLeft)) {
            // wait an additional loop before restoring scroll position
            Ember.run.next(() => {
              Ember.run.scheduleOnce('afterRender', this, 'setScrollLeft', scrollLeft);
            });
          }
        });
      }

      Ember.run.debounce(this, 'refreshData', 250);
    },

    refreshData() {
      if (this.get('isDestroyed')) return;
      const rows = this.get('table.rows');
      const defaultCheckboxRowSelected = this.get('defaultCheckboxRowSelected');
      this.get('fetchDataTask').perform().then(() => {
        if (rows.length && defaultCheckboxRowSelected) {
          Ember.run.scheduleOnce('actions', this, 'onCheckAll');
          this.set('defaultCheckboxRowSelected', false);
        }
      }).catch(err => {
        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        }
      });
    },

    // derived table components can override function to do a different type of fetch
    fetchDataQuery() {
      const params = {
        sorts: []
      };
      const recordLength = this.get('records.length');

      try {
        // this only applies for server-side pagination
        if (this.get('paginationEnabled')) {
          params.page = {
            offset: this.get('records.length'),
            limit: recordLength
          };
        } else {
          params.page = {
            offset: this.get('records.length'),
            limit: this.get('limit')
          };
        }

        const sort = this.buildSortParam();

        if (sort) {
          params.sorts.push(sort);
        }

        const includes = this.get('defaultIncludes');

        if (!Ember.isEmpty(includes)) {
          params.includes = includes;
        }

        const filters = this.setupFilterType();
        params.filter = (0, _filters.buildCompoundFilterNode)('and', filters);
        const modelName = this.get('config.modelName');
        return this.query(modelName, params);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Workspace view is not refreshed on selecting or deselecting' + error);
      }
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      let activeData = store.peekAll(modelName) || [];

      if (Ember.isPresent(this.get('config.fetchModelData'))) {
        activeData = (await store.findAll(modelName)) || [];
      }

      if (Ember.isPresent(this.get('config.queryModelData'))) {
        const queryParams = this.get('queryParams') || {};
        activeData = await store.query(modelName, queryParams);
      }

      if (Ember.isPresent(this.get('config.inactiveProviderFilterPath'))) {
        const providerPath = this.get('config.inactiveProviderFilterPath');
        const providers = await this.get('store').findAll('provider');
        const activeProviders = providers.filter(provider => provider.get('status') === 'Active');
        const activeProviderHash = activeProviders.reduce((hash, p) => {
          hash[p.id] = true;
          return hash;
        }, {}); // If there is no provider then should be able to show

        activeData = activeData.filter(d => !d.get(providerPath) || activeProviderHash[d.get(providerPath)]);
      }

      const filteredData = activeData.filter(filterFn).sort(compareFn);
      return filteredData;
    },

    buildSortParam() {
      const config = this.get('config');
      const state = this.get('state');
      let columnConfig; // user-specified sort order always takes precedence

      if (Ember.isPresent(state) && Ember.isPresent(state.sortId)) {
        columnConfig = config.columns.findBy('id', state.sortId);

        if (!columnConfig) {
          // this can occur naturally if the user sorts by a column, saves, and
          // that column is later removed from the widget's configuration
          logger.warn(`workspace configuration specifies that ${config.title} widget should be sorted by column with id '${state.sortId}', but no such column exists`);
        }
      } // fall back to default sort order; this is optional in widget config


      if (!columnConfig && Ember.isPresent(config.defaultSortId)) {
        columnConfig = config.columns.findBy('id', config.defaultSortId);

        if (!columnConfig) {
          logger.warn(`${config.title} widget is configured with default sort ID '${config.defaultSortId}', but has no column with matching ID`);
        }
      } // collection will be unsorted; this is expected when there is no specified
      // workspace sort or widget default sort


      if (!columnConfig) {
        return null;
      }

      const columnType = _columnTypes.default.findBy('id', columnConfig.type);

      if (!columnType) {
        logger.warn(`tried to sort by column with ID '${columnConfig.id}', but its type '${columnConfig.type}' is not a valid column type`);
        return null;
      }

      if (!columnType.compare) {
        logger.warn(`column type '${columnType.id}' does not have a compare function configured`);
        return null;
      }

      let sortAsc = true;

      if (Ember.isPresent(state) && Ember.isPresent(state.sortAsc)) {
        sortAsc = state.sortAsc;
      } else if (Ember.isPresent(config.defaultSortAsc)) {
        sortAsc = config.defaultSortAsc;
      }

      return {
        path: columnConfig.type === ENUM_TYPE ? columnConfig.mapValuePath : columnConfig.valuePath,
        asc: sortAsc,
        compare: columnType.compare
      };
    },

    setupFilterType() {
      const searchText = this.get('searchText');
      const res = searchText.split('=');

      if (Ember.isEmpty(res[0]) || Ember.isEmpty(res[1])) {
        return [this.buildSearchFilter()];
      }

      return [this.buildSearchFilterByParameter()];
    },

    buildSearchFilterByParameter() {
      const searchText = this.get('searchText');
      const res = searchText.split('=');
      const attr = res[0].toLowerCase().trim();
      const value = res[1].trim();
      const columns = this.get('table.columns') || [];

      function configFilter(config) {
        return config.label.toLowerCase().trim() === attr;
      }

      if (Ember.isEmpty(searchText)) {
        return null;
      }

      const searchableConfigs = columns.mapBy('config').compact().filter(configFilter);
      const args = searchableConfigs.map(config => {
        const columnType = _columnTypes.default.findBy('id', config.type) || {};
        const filterType = columnType.searchFilterType;
        const valuePath = config.alternateValuePath ? config.alternateValuePath : config.valuePath;
        return (0, _filters.buildValueFilterNode)(filterType, valuePath, [value], config.format);
      });
      return (0, _filters.buildCompoundFilterNode)('or', args);
    },

    buildSearchFilter() {
      const searchText = this.get('searchText');
      const columns = this.get('table.columns') || [];

      if (Ember.isEmpty(searchText)) {
        return null;
      }

      const searchableConfigs = columns.rejectBy('hidden').mapBy('config').compact().filterBy('searchable');
      const args = searchableConfigs.map(config => {
        const columnType = _columnTypes.default.findBy('id', config.type) || {};
        const filterType = columnType.searchFilterType;
        const valuePath = config.alternateValuePath ? config.alternateValuePath : config.valuePath;
        return (0, _filters.buildValueFilterNode)(filterType, valuePath, [searchText], config.format);
      });
      return (0, _filters.buildCompoundFilterNode)('or', args);
    },

    clearData() {
      const paginationEnabled = this.get('paginationEnabled');
      this.set('records', []);

      if (paginationEnabled) {
        this.set('offset', 0);
      }

      this.get('dataJoin').clear();
    },

    exportData() {
      const table = this.get('table');
      const columns = table.get('columns') || [];
      const rows = table.get('rows') || [];
      const records = this.get('records') || [];
      const paginationEnabled = this.get('paginationEnabled');
      const dataArray = paginationEnabled ? records : rows;
      const fields = columns.reduce((arr, column) => {
        const id = column.get('id');

        if (['checkbox', 'spacer', 'radio'].includes(id)) {
          return arr;
        }

        const label = column.get('label');
        arr.push(label);
        return arr;
      }, []);
      const data = dataArray.map(row => {
        return columns.reduce((arr, column) => {
          const config = column.get('config');
          const id = column.get('id');

          if (['checkbox', 'spacer', 'radio'].includes(id)) {
            return arr;
          } // If getting results directly from records, we need to remove 'record.' from valuePath.


          const valuePath = paginationEnabled ? column.get('valuePath').replace('record.', '') : column.get('valuePath');
          let value = row.get(valuePath);

          if (config.type.includes('enum') && Ember.isPresent(value)) {
            let values = null;

            if (Ember.isPresent(value.values)) {
              values = value.values.map(obj => obj.value);
              value = values.join(';');
            }
          } // format date based on config


          value = formatDateConfigType(value, config);
          arr.push(value);
          return arr;
        }, []);
      });

      const csv = _papaparse.default.unparse({
        fields,
        data
      }, {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"'
      });

      const link = document.createElement('a');
      const timestamp = (0, _moment.default)().format('YYYY-MM-DD_HH-MM-SS');
      const {
        title
      } = this.get('config');
      const filename = `${timestamp}_${title}.csv`;
      const blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
      });
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    setScrollLeft(scrollLeft) {
      if (!this.$('.table-component-body')) {
        return;
      }

      this.$('.table-component-body').scrollLeft(scrollLeft);
    },

    fetchDataTask: (0, _emberConcurrency.task)(function* () {
      if (this.skipQuery) {
        this.set('records', []);
      } else {
        const records = yield this.fetchDataQuery();
        this.set('records', records);
      }
    }).restartable(),
    onTableColumnsChange: Ember.observer('tableColumns', function () {
      Ember.run.scheduleOnce('afterRender', this, 'setTableColumns');
      Ember.run.scheduleOnce('afterRender', this, 'updateTableColumns');
    }),
    onWidgetStateChange: Ember.observer('state', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateTableColumns');
    }),
    onRecordsChange: Ember.observer('records', 'offset', function () {
      const limit = this.get('limit');
      const offset = this.get('offset');
      const records = this.get('records');
      const paginationEnabled = this.get('paginationEnabled');
      let visibleRecords = [];

      if (records && paginationEnabled) {
        visibleRecords = this.get('records').slice(0, offset + limit);
      } else visibleRecords = records;

      this.set('visibleRecords', visibleRecords);
      this.set('refreshSync', true);
      const refreshSync = this.get('refreshSync');
      const resetScroll = this.get('resetScroll');
      this.get('dataJoin').join(visibleRecords, refreshSync);
      this.set('refreshSync', false);

      if (resetScroll) {
        // reset scroll and pagination to top
        if (paginationEnabled) this.set('offset', 0);
        this.$('.table-component-body').scrollTop(0);
      }

      this.set('resetScroll', false);
    }),

    keyDown(event) {
      const ltCell = event.target.closest('.lt-cell'); // need to exempt checkboxes to allow right arrow from checkbox col

      const targetIsControl = event.target.tagName === 'INPUT' && event.target.type !== 'checkbox';

      switch (event.key) {
        case 'Escape':
          {
            const targetIsSearchBox = event.target.classList.contains('table-component-search-box');

            if (targetIsSearchBox) {
              event.preventDefault();
              this.set('isSearchEnabled', false);
              this.$('.table-component-search-button').focus();
            }

            break;
          }

        case 'ArrowLeft':
          {
            if (!ltCell || targetIsControl) {
              return;
            }

            event.preventDefault();
            const previousCell = ltCell.previousElementSibling;

            if (!previousCell) {
              return;
            }

            const focusElement = previousCell.querySelector('.can-focus') || previousCell.firstElementChild;

            if (focusElement) {
              focusElement.focus();
            }

            break;
          }

        case 'ArrowRight':
          {
            if (!ltCell || targetIsControl) {
              return;
            }

            event.preventDefault();
            const nextCell = ltCell.nextElementSibling;

            if (!nextCell) {
              return;
            }

            const focusElement = nextCell.querySelector('.can-focus') || nextCell.firstElementChild;

            if (focusElement) {
              focusElement.focus();
            }

            break;
          }

        case 'ArrowDown':
          {
            if (!ltCell) {
              return;
            }

            event.preventDefault();
            const row = ltCell.closest('.lt-row'); // an HTMLCollection isn't really an array

            const rowChildren = [].slice.call(row.children);
            const index = rowChildren.indexOf(ltCell);
            const nextRow = row.nextElementSibling;

            if (nextRow && nextRow.tagName !== 'OCCLUDED-CONTENT') {
              // move table selection down one row
              const tableRows = this.get('table.rows');
              const recordId = nextRow.getAttribute('data-record-id');
              const nextTableRow = tableRows.findBy('content.id', recordId);
              nextTableRow.set('selected', true); // move browser focus to same column in next row

              const nextLtCell = nextRow.children[index];
              const focusElement = nextLtCell.querySelector('.can-focus') || nextLtCell.firstElementChild;

              if (focusElement) {
                focusElement.focus();
              }
            }

            break;
          }

        case 'ArrowUp':
          {
            if (!ltCell) {
              return;
            }

            event.preventDefault();
            const row = ltCell.closest('.lt-row'); // an HTMLCollection isn't really an array

            const rowChildren = [].slice.call(row.children);
            const index = rowChildren.indexOf(ltCell);
            const previousRow = row.previousElementSibling;

            if (previousRow && previousRow.tagName !== 'OCCLUDED-CONTENT') {
              // move table selection down one row
              const tableRows = this.get('table.rows');
              const recordId = previousRow.getAttribute('data-record-id');
              const previousTableRow = tableRows.findBy('content.id', recordId);
              previousTableRow.set('selected', true); // move browser focus to same column in previous row

              const previousLtCell = previousRow.children[index];
              const focusElement = previousLtCell.querySelector('.can-focus') || previousLtCell.firstElementChild;

              if (focusElement) {
                event.preventDefault();
                focusElement.focus();
              }
            }

            break;
          }

        case ' ':
        case 'Enter':
          {
            const isColumn = event.target.classList.contains('table-base-column');

            if (isColumn) {
              event.preventDefault();
              this.$(event.target).trigger('click');
            }

            break;
          }

        default:
          break;
      }
    },

    onCheckAll() {
      this.get('table.rows').setEach('selected', true);
      this.get('table.rows').setEach('isPreviouslyChecked', true);
    },

    onUncheckAll() {
      this.get('table.rows').setEach('selected', false);
      this.get('table.rows').setEach('isPreviouslyChecked', false);
    },

    // override this function to provide tables the ability to extend the rollback to belongTo / hasMany associations
    rollbackRecord() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      if (this.get('usingRadioRowComponent')) {
        if (record) record.rollbackAttributes();
      }
    },

    onClearSearchText() {
      this.set('searchText', '');
      this.set('refreshSync', true);
      Ember.run.debounce(this, 'refreshData', 250);
    },

    //

    /**
     * @summary
     * table component will provide a generic save functionality for the intended record
     * however if there are belongsTo / hasMany relationship and those must also be saved alongside the record being saved
     * then the table that extends from this component will require either closure actions or overloading the function
     *
     * @param {record} record - intended record to be saved
     * @return {Promise} - Promise resolves with saved record otherwise bubbles up error
     */
    saveRecord(record) {
      if (record) {
        return record.save().catch(e => {
          throw e;
        });
      }
    },

    selectRow(curRow, newRow) {
      if (curRow) {
        curRow.set('selected', false);
      }

      if (newRow) {
        newRow.set('selected', true);
      }
    },

    /**
     * TODO: Since the manual click of radio button does not changing the data, not able to uncheck it while cancelling
     * Hence unChecking using event via the element
     * @param event
     */
    cancelRow(event) {
      if (this.isRadioBtn(event)) {
        event.target.checked = false;
      }
    },

    isRadioBtn(event) {
      if (Ember.isPresent(event) && Ember.isPresent(event.target)) {
        return event.target.type === 'radio';
      }

      return false;
    },

    /**
     * Before saving the selected record this method will check the validate record method.
     * @param params
     * @returns {Promise<void>}
     */
    async primaryTooltipSave(params) {
      const {
        selectRow,
        cancelRow,
        saveRecord,
        currentRecord,
        currentRow,
        newRow,
        tooltip
      } = params;
      const onSelectRow = this.get('onSelectRow');

      const saveRecordFun = async () => {
        tooltip.reset();

        try {
          await saveRecord(currentRecord);
          if (Ember.isPresent(onSelectRow)) onSelectRow();

          if (Ember.isPresent(newRow)) {
            selectRow(currentRow, newRow);
          } else {
            selectRow(newRow, currentRow);
          }
        } catch (err) {
          cancelRow();
        }
      };

      const valid = true;

      try {
        this.set('isSavingInProgress', true);
        await saveRecordFun();
        this.set('isSavingInProgress', false);
        return valid;
      } catch (err) {
        console.log(err); // eslint-disable-line
      }

      return valid;
    },

    promptChangeWarning(newRow, event) {
      const currentRow = this.get('table.selectedRows.firstObject');
      const rollbackRecord = this.get('rollbackRecord').bind(this);
      const saveRecord = this.get('saveRecord').bind(this);
      const currentRecord = currentRow ? currentRow.get('record') : null;
      const tooltip = this.get('tooltip');
      const tableName = this.get('config.tooltipTitle');
      const hasPendingChanges = this.get('hasPendingChanges');
      const onSelectRow = this.get('onSelectRow');
      const selectRow = this.get('selectRow');
      const cancelRow = this.get('cancelRow').bind(null, event);
      const primaryTooltipSaveInfo = {
        cancelRow,
        selectRow,
        saveRecord,
        currentRecord,
        currentRow,
        newRow,
        tooltip
      };
      const primaryTooltipSave = this.get('primaryTooltipSave').bind(this, primaryTooltipSaveInfo);
      const isMaxBrokerProviderRecords = this.get('checkMaxBrokerProviderRecords'); // user has no pending changes meaning the user is trying to move to a different row

      if (!hasPendingChanges) {
        tooltip.reset();
        if (!newRow) return;
        return selectRow(currentRow, newRow);
      }

      return tooltip.pushConfirmation({
        title: tableName,
        tip: 'There are unsaved changes',
        warning: true,
        hasOverlay: true,
        // covers interface so user must able to select only those three function
        thirdActionText: 'Cancel',
        thirdAction: () => {
          cancelRow(); // we don't need to adjust selected properties as they are already set correctly

          return tooltip.reset();
        },
        secondaryActionText: 'Do Not Save',
        secondaryAction: () => {
          // we don't need to undo because every time we switch to a different row,
          // we create a new changeset object from the unedited ember data model
          rollbackRecord(currentRecord);
          if (Ember.isPresent(onSelectRow)) onSelectRow();

          if (Ember.isPresent(newRow)) {
            selectRow(currentRow, newRow);
          } else if (Ember.isPresent(currentRow)) {
            selectRow(newRow, currentRow);
          }

          if (!currentRow && !newRow) {
            this.set('tableRef.config.selectFirstRow', this.get('selectFirstRowCache'));
            Ember.run.scheduleOnce('afterRender', this, () => this.firstRowSelected());
          }

          return tooltip.reset();
        },
        primaryActionText: 'Save',
        primaryAction: () => {
          if (isMaxBrokerProviderRecords) {
            if (isMaxBrokerProviderRecords()) {
              return this.get('maxBrokerProviderWarning')();
            }
          }

          this.set('tableRef.config.selectFirstRow', this.get('selectFirstRowCache'));
          primaryTooltipSave();
        }
      });
    },

    async tableRowSelect(newRow, event) {
      const selectRow = this.get('selectRow');
      const cancelRow = this.get('cancelRow').bind(null, event);
      const hasPendingChanges = this.get('hasPendingChanges');
      const onSelectRow = this.get('onSelectRow');
      const validateRecord = this.get('validateRecord');
      const tooltip = this.get('tooltip');
      if (event) event.stopPropagation();
      if (this.get('disableRowSelection')) return;

      if (this.get('usingRadioRowComponent')) {
        const currentRow = this.get('table.selectedRows.firstObject');
        const enableWarningRowChangeAlert = this.get('enableWarningRowChangeAlert');
        if (newRow === currentRow) return; // If there is no event, it means trigger by didRender, do not show promp messages.

        if (hasPendingChanges && enableWarningRowChangeAlert && event) {
          try {
            //If validation fails it displays the error pop-up and remain in the current editable screen
            if (validateRecord) {
              const valid = await validateRecord.perform();

              if (valid) {
                this.promptChangeWarning(newRow, event);
              } else {
                tooltip.set('confirmations.lastObject.hasOverlay', true);
                cancelRow();
              }
            } else {
              this.promptChangeWarning(newRow, event);
            }
          } catch (err) {
            console.log(err); // eslint-disable-line
          }

          return;
        }

        if (Ember.isPresent(onSelectRow)) onSelectRow();
        selectRow(currentRow, newRow);
      }
    },

    async searchBtnClick() {
      const validateRecord = this.get('validateRecord');

      const searchFun = () => {
        this.send('onSearchTextChange', '');
        this.toggleProperty('isSearchEnabled');
        Ember.run.schedule('afterRender', () => {
          if (this.get('isSearchEnabled')) {
            this.$('.table-component-search-box').focus();
          }
        });
      };

      if (this.get('usingRadioRowComponent')) {
        const enableWarningRowChangeAlert = this.get('enableWarningRowChangeAlert');
        const hasPendingChanges = this.get('hasPendingChanges');

        if (enableWarningRowChangeAlert) {
          if (hasPendingChanges) {
            if (validateRecord) {
              const valid = await validateRecord.perform();
              if (!valid) return;
            }
          }

          if (!hasPendingChanges) {
            this.moveSelectedRecordToNormalState();
            searchFun();
          } else {
            this.promptChangeWarning();
          }
        } else {
          searchFun();
        }
      } else {
        searchFun();
      }
    },

    firstRowSelected() {
      if (this.get('isSavingInProgress')) return;
      const rows = this.get('table.rows');
      const selectedRow = this.get('checkedRows.firstObject'); // selecting first row
      // needs more refactoring as it should only occur after render, but no longer selects rows afterwards

      if ((this.get('config.selectFirstRow') || this.get('config.selectFirstConfigRow')) && !selectedRow && rows.length) {
        this.tableRowSelect(rows.firstObject);
      }
    },

    firstRowSelectedTask: (0, _emberConcurrency.task)(function* () {
      if (this.get('isSavingInProgress')) return;
      const rows = yield this.get('table.rows');
      const selectedRow = this.get('checkedRows.firstObject'); // selecting first row
      // needs more refactoring as it should only occur after render, but no longer selects rows afterwards

      if ((this.get('config.selectFirstRow') || this.get('config.selectFirstConfigRow')) && !selectedRow && rows.length) {
        this.tableRowSelect(rows.firstObject);
      }
    }).drop(),
    selectFirstRowTask: (0, _emberConcurrency.task)(function* () {
      if (this.get('isSavingInProgress')) return;
      const rows = yield this.get('table.rows');
      this.tableRowSelect(rows.firstObject);
    }).drop(),

    /**
     *when user click the remove search icon while editing show the unsaved changes pop-up
     */
    onRemoveSearchClick() {
      const searchTextChange = this.searchTextChange.bind(this);

      const removeSearchText = () => {
        searchTextChange('');
        this.toggleProperty('isSearchEnabled');
        this.$('.table-component-search-button').focus();
      };

      this.promptSearchOrSortWarning(removeSearchText);
    },

    onColumnClick(column) {
      if (!column.sorted) {
        return;
      }

      const onColumnSortClick = () => {
        this.onColumnSortClick(column);
      };

      this.promptSearchOrSortWarning(onColumnSortClick);
    },

    searchTextChange(searchText) {
      this.set('searchText', searchText);
      this.set('refreshSync', true);
      Ember.run.debounce(this, 'refreshData', 250);
    },

    /**
     * when user searching for another record while editing show the unsaved changes pop-up
     * @param event
     * @returns {Promise<void>}
     */
    onSearchTextChange(event) {
      const previousSearchText = this.get('searchText');
      const searchTextElement = event ? event.target : null;
      const searchText = searchTextElement ? searchTextElement.value : '';

      if (searchText === previousSearchText) {
        return;
      }

      this.searchTextChange(searchText);
    },

    //This warning is used while searching in search bar or sorting the grid columns
    async promptSearchOrSortWarning(searchOrSortCb) {
      const newRow = this.get('table.rows.firstObject');
      const currentRow = this.get('table.selectedRows.firstObject');
      const rollbackRecord = this.get('rollbackRecord').bind(this);
      const saveRecord = this.get('saveRecord').bind(this);
      const currentRecord = currentRow ? currentRow.get('record') : null;
      const tooltip = this.get('tooltip');
      const tableName = this.get('config.tooltipTitle');
      const selectRow = this.get('selectRow');
      const cancelRow = this.get('cancelRow');
      const validateRecord = this.get('validateRecord');
      const primaryTooltipSaveInfo = {
        selectRow,
        cancelRow,
        saveRecord,
        currentRecord,
        currentRow,
        newRow,
        tooltip
      };
      const primaryTooltipSave = this.get('primaryTooltipSave').bind(this, primaryTooltipSaveInfo);
      const {
        enableWarningRowChangeAlert,
        usingRadioRowComponent,
        hasPendingChanges
      } = this.getProperties('enableWarningRowChangeAlert', 'usingRadioRowComponent', 'hasPendingChanges');
      const isMaxBrokerProviderRecords = this.get('checkMaxBrokerProviderRecords');

      if (usingRadioRowComponent && enableWarningRowChangeAlert && hasPendingChanges) {
        if (validateRecord) {
          const valid = await validateRecord.perform();

          if (!valid) {
            return;
          }
        }

        tooltip.pushConfirmation({
          title: tableName,
          tip: 'There are unsaved changes',
          warning: true,
          hasOverlay: true,
          thirdActionText: 'Cancel',
          thirdAction: () => {
            return tooltip.reset();
          },
          secondaryActionText: 'Do Not Save',
          secondaryAction: () => {
            // we don't need to undo because every time we switch to a different row,
            // we create a new changeset object from the unedited ember data model
            rollbackRecord(currentRecord);
            searchOrSortCb();
            return tooltip.reset();
          },
          primaryActionText: 'Save',
          primaryAction: () => {
            if (isMaxBrokerProviderRecords) {
              if (isMaxBrokerProviderRecords()) {
                return this.get('maxBrokerProviderWarning')();
              }
            }

            this.set('tableRef.config.selectFirstRow', this.get('selectFirstRowCache'));
            primaryTooltipSave().then(valid => {
              if (valid) searchOrSortCb();
            });
          }
        });
        return;
      }

      searchOrSortCb();
    },

    onColumnSortClick(column) {
      setTimeout(() => {
        this.set('state', {
          sortAsc: column.ascending,
          sortId: column.get('id')
        });
      }, 1000);
      this.set('refreshSync', true);
      this.set('resetScroll', true);
      Ember.run.scheduleOnce('afterRender', this, 'refreshData');
    },

    /**
     * Regarding NYAAR-16611 -> When a row is selected and edited but the fields are not updated and when the search
     * icon is clicked then the selected record is moved out from edit mode
     */
    moveSelectedRecordToNormalState() {
      const selectedRow = this.get('checkedRows.firstObject');
      const config = this.get('config');
      const rows = this.get('table.rows');
      this.onUncheckAll();
      config.selectFirstRow = false;
      config.selectFirstConfigRow = false;

      if (Ember.isPresent(selectedRow)) {
        const previouslySelectedRecord = rows.find(r => r.id === selectedRow.id);
        previouslySelectedRecord.set('selected', true);
      }
    },

    onCheckRows(rows) {
      rows.forEach(row => {
        const isPreviouslyChecked = row.isPreviouslyChecked;
        row.set('selected', isPreviouslyChecked);
      });
    },

    /**
     * NYAAR-20346 - Record should be deselected after searching and deselecting in Adjust Driver Break Reoptimization screen
     */
    fetchCheckedRows() {
      if (this.get('isDestroyed')) return;
      const rows = this.get('table.rows');
      const defaultCheckboxRowSelected = this.get('defaultCheckboxRowSelected');
      const isTableLoadedForFirstTime = this.get('isTableLoadedForFirstTime');
      this.get('fetchDataTask').perform().then(() => {
        //when loading for the first time select all the checkboxes
        if (rows.length && defaultCheckboxRowSelected && isTableLoadedForFirstTime) {
          this.onCheckAll();
          this.set('defaultCheckboxRowSelected', false);
        } //set the unchecked checkboxes as selected false


        if (rows.length && defaultCheckboxRowSelected && !isTableLoadedForFirstTime) {
          this.onCheckRows(rows);
          this.set('defaultCheckboxRowSelected', false);
        }
      }).catch(err => {
        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        }
      });
    },

    actions: {
      // Header Actions
      onClearSearchText() {
        this.onClearSearchText();
      },

      onSearchTextChange(event) {
        this.onSearchTextChange(event);
      },

      onTableActionClick(action, dropdown) {
        dropdown.actions.close();

        if (action.action) {
          action.action();
        }
      },

      // Ember Light Table Actions
      async onColumnClick(column) {
        this.onColumnClick(column);
      },

      onColumnResize(column, pixels) {
        const scrollLeft = this.$('.table-component-body').scrollLeft();
        const id = column.get('id');
        const columns = {};
        columns[id] = {
          width: fromPixels(pixels)
        };
        Ember.run.next(() => {
          Ember.run.scheduleOnce('afterRender', this, 'setScrollLeft', scrollLeft);
        });
      },

      onSearchButtonClick() {
        this.searchBtnClick();
      },

      onEditButtonClick() {
        const onEditButtonClick = this.get('onEditButtonClick');
        if (onEditButtonClick) onEditButtonClick();
      },

      onDeleteButtonClick() {
        const onDeleteButtonClick = this.get('onDeleteButtonClick');
        if (onDeleteButtonClick) onDeleteButtonClick();
      },

      onRemoveSearchClick() {
        this.onRemoveSearchClick();
      },

      onScroll() {
        // pause background processing while scrolling
        this.get('interaction').didScrollWidget(); // close any open dropdown

        const dropdown = this.get('inlineRowMenu');
        const paginationEnabled = this.get('paginationEnabled');

        if (dropdown) {
          dropdown.actions.close(); //this.set('inlineRowMenu', null);
        } // figure out if we need to increase pagination


        const body = this.$('.table-component-body')[0];
        const scrollPosition = body.scrollTop + body.offsetHeight;
        const triggerPosition = body.scrollHeight - PAGINATION_TRIGGER_OFFSET; // scroll height takes a while to update, so we don't reset pagination
        // flag until we observe it change in a subsequent `onScroll()` action

        if (body.scrollHeight !== this._scrollHeight) {
          this._scrollHeight = body.scrollHeight;
          this.set('isPaginating', false);
        }

        if (scrollPosition >= triggerPosition && paginationEnabled && !this.isPaginating) {
          const visibleCount = this.get('visibleRecords.length');
          const offset = this.get('offset');
          const limit = this.get('limit');

          if (offset < visibleCount) {
            this.set('offset', offset + limit);
            this.set('isPaginating', true);
            this.set('refreshSync', true);
          }
        }
      },

      onCheckAll() {
        this.onCheckAll();
      },

      onUncheckAll() {
        this.onUncheckAll();
      },

      onRowClick(row, event) {
        this.tableRowSelect(row, event);
      },

      hasPendingChanges(record) {
        return this.hasPendingChanges(record);
      },

      onCancelButtonClick() {}

    }
  });

  _exports.default = _default;
});