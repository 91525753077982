define("adept-iq/pods/reset-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xYliN0HW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"reset-password-template\"],[9],[0,\"\\n\\n  \"],[7,\"h3\"],[9],[0,\"\\n    Reset Password\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"reset-form\",null,[[\"errors\",\"success\",\"onSubmit\"],[[27,\"readonly\",[[23,[\"errors\"]]],null],[27,\"readonly\",[[23,[\"success\"]]],null],[27,\"route-action\",[\"reset\"],null]]]],false],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/reset-password/template.hbs"
    }
  });

  _exports.default = _default;
});