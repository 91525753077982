define("adept-iq/helpers/capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalize = capitalize;
  _exports.default = void 0;

  function capitalize(str) {
    if (!str[0]) return '';
    const s = str[0].replace(/\s+/g, '').capitalize();
    return s;
  }

  var _default = Ember.Helper.helper(capitalize);

  _exports.default = _default;
});