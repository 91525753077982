define("adept-iq/classes/rules/eligibility-rules/user-override-permissions-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/config/mapped-permIds", "adept-iq/classes/rules/rule-result-type"], function (_exports, _baseRule, _mappedPermIds, _ruleResultType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UserOverridePermissionsRule = void 0;

  class UserOverridePermissionsRule extends _baseRule.default {
    doEvaluate(ruleContext) {
      const {
        permissionLayer
      } = ruleContext;
      const permGranted = permissionLayer.permInUserHash(_mappedPermIds.default.overrideEligibilityConditions, null);

      if (permGranted) {
        return _ruleResultType.RESULT_TYPE_YES;
      }

      return _ruleResultType.RESULT_TYPE_NO;
    }

  }

  _exports.UserOverridePermissionsRule = UserOverridePermissionsRule;
  UserOverridePermissionsRule.RULE_NAME = 'UserOverridePermissionsRule';
});