define("adept-iq/pods/components/iq-widgets/drivers-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/drivers-widget/config", "adept-iq/pods/components/iq-widgets/drivers-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/config/mapped-permIds", "adept-iq/models/dashboard"], function (_exports, _component, _config, _avlmConfig, _environment, _mappedPermIds, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['drivers-widget', 'data-test-drivers-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspace: Ember.inject.service(),
    // need to sub-class this in specific widget
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const role = this.get('workspace.dashboardModel.role');

      switch (role) {
        case _dashboard.SCHEDULE_ROLENAME:
          this._initScheduleSingleActions();

          break;

        case _dashboard.DISPATCH_ROLENAME:
        default:
          this._initDispatchSingleActions();

          break;
      }
    },

    _initDispatchSingleActions() {
      // disable new/edit driver for 4.2. This will be added back
      // later in 5.0
      this.set('tableActions', [{
        id: 'newDriver',
        name: 'New Driver',
        permId: _mappedPermIds.default.newDriver,
        action: function () {
          const modelName = this.get('config.modelName');
          const driverPhoneNumber = this.get('store').createRecord('driver-phone-number');
          const provider = this.get('store').createRecord('provider');
          const newModel = this.get('store').createRecord(modelName, {
            phoneNumbers: [driverPhoneNumber],
            provider: provider
          });
          this.get('records').pushObject(newModel);
          this.set('editModal.editableRecords', []);
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [newModel], this.get('saveRecordFunction').bind(this));
        }.bind(this)
      }, {
        id: 'print',
        permIds: [_mappedPermIds.default.downloadDrivers],
        name: 'Download',
        action: () => {
          this.exportData();
        }
      }]);
      const singleActions = [{
        id: 'sendMessage',
        name: 'Send Message',
        permId: [_mappedPermIds.default.cannedMessageToDriver, _mappedPermIds.default.freeTypeMessageToDriver],
        action: model => {
          this.sendMessageToDrivers([model], 'singleMessage');
        }
      }];
      this.set('singleActions', singleActions); // disable new vehicle for 4.2. This will be added back
      // later in 5.0

      this.set('bulkActions', [// {
      //   id: 'bulkEdit',
      //   name: 'Bulk Edit',
      //   action: (models) => {
      //     const editComponent = this.get('editComponent');
      //     this.get('editModal').open(editComponent, models);
      //   }
      // },
      {
        id: 'bulkMessage',
        name: 'Bulk Message',
        permId: _mappedPermIds.default.editDriver,
        action: drivers => {
          this.sendMessageToDrivers(drivers, 'bulkMessage');
        }
      }]);
    },

    _initScheduleSingleActions() {
      // Clearing Add and edit driver functionality in R5
      // As it will manage through Driver and Vehicle Management
      this.set('bulkActions', [// {
      //   id: 'bulkEdit',
      //   name: 'Bulk Edit',
      //   action: (models) => {
      //     const editComponent = this.get('editComponent');
      //     this.get('editModal').open(editComponent, models);
      //   }
      // },
      {
        id: 'bulkMessage',
        name: 'Bulk Message',
        permId: _mappedPermIds.default.editDriver,
        action: drivers => {
          this.sendMessageToDrivers(drivers, 'bulkMessage');
        }
      }]);
    },

    async sendMessageToDrivers(drivers, messageType) {
      const driverList = drivers ? drivers : [];
      const draftMessages = driverList.map(driver => {
        const trueDriver = driver;
        const driverId = driver.get('id');
        const badgeNumber = driver.get('driverId');

        if (messageType === 'singleMessage' && !badgeNumber) {
          this.get('notifications').warning('Can not send message due to missing driver id');
          return null;
        }

        if (messageType === 'bulkMessage' && !badgeNumber) {
          return null;
        }

        trueDriver.set('driver', {
          id: driverId,
          endPointType: 'recipient',
          fullName: driver.get('fullName'),
          badgeNumber: badgeNumber
        });
        const vehicleId = trueDriver.get('latestVehicle.id') ? trueDriver.get('latestVehicle.id') : trueDriver.get('latestRouteVehicleDriver.vehicle.id');
        const vehicleName = trueDriver.get('latestVehicle.name') ? trueDriver.get('latestVehicle.name') : trueDriver.get('latestRouteVehicleDriver.vehicle.name');
        trueDriver.set('driverVehicle', {
          id: vehicleId,
          name: vehicleName
        });
        const routeId = trueDriver.get('latestRouteVehicleDriver.dispatchRoute.route.id');
        const routeName = trueDriver.get('latestRouteVehicleDriver.dispatchRoute.route.name');
        trueDriver.set('route', {
          id: routeId,
          name: routeName
        });

        if (_environment.default.APP.avlmLite) {
          trueDriver.set('avlmDriver', {
            id: driverId,
            endPointType: 'recipient',
            fullName: driver.get('fullName'),
            badgeNumber: badgeNumber
          });
          trueDriver.set('avlmVehicle', {
            id: vehicleId
          });
        }

        return trueDriver;
      }).filter(driver => !Ember.isNone(driver));

      if (messageType === 'singleMessage' && draftMessages.firstObject) {
        this.get('workspace').pushState('addNewMessage', {
          draftMessages
        });
      } else if (messageType === 'bulkMessage') {
        if (draftMessages.lenght < drivers.length) {
          this.get('notifications').warning('Message could not be sent to some drivers which do not have driver id.');
        }

        if (!Ember.isEmpty(draftMessages)) {
          this.get('workspace').pushState('addNewMessage', {
            draftMessages
          });
        }
      }
    }

  });

  _exports.default = _default;
});