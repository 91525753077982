define("adept-iq/adapters/avl-location", ["exports", "adept-iq/adapters/application", "adept-iq/config/api-urls"], function (_exports, _application, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    reportVehicleLocation(vehicleId, latLng) {
      const ajax = this.get('ajax');
      const [lat, lng] = latLng;
      const timestamp = new Date().toISOString();
      const token = this.get('session.data.authenticated.token');
      const json = {
        data: {
          type: 'avl',
          attributes: {
            vehicleId,
            timestamp,
            lat,
            lng
          }
        }
      }; // disabled for demo

      return;
      /* eslint-disable no-unreachable */

      return ajax.post(`${_apiUrls.API.avlService.host}/avl`, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: json
      });
      /* eslint-enable no-unreachable */
    }

  });

  _exports.default = _default;
});