define("adept-iq/pods/components/iq-widgets/cancel-type-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/cancel-type-form-widget/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    tripId: null,
    classNames: ['cancel-type-form-widget'],

    init() {
      this._super(...arguments);

      const records = this.get('editableRecords') || [];
      const segment = records[0];

      if (Ember.isPresent(segment)) {
        this.set('tripId', segment.trips.firstObject.get('tripId'));
      }
    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        if (Ember.isNone(record.get('cancelType'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Reason cannot be blank.');
        }
      });
      return valid;
    },

    actions: {
      onCellValueChange(record, valuePath, value, options) {
        if (record) {
          record.set('isForceDirty', true);
          this.get('service').setRecordValue(record, valuePath, value, options);
        }
      },

      onUndoClick() {
        const records = this.get('editableRecords');
        records.forEach(record => {
          record.set('cancelNotes', null);
          record.set('cancelType', null);
        });
      },

      onApplyClick() {
        if (this.validateRecords()) {
          const title = this.get('title');
          const tip = `Are you sure you want to cancel trip ${this.get('tripId')}?`;
          const primaryActionText = 'Confirm';
          this.get('service').apply(title, tip, primaryActionText);
        }
      }

    }
  });

  _exports.default = _default;
});