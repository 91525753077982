define("adept-iq/tests/factories/avlm-vehicle-event", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  /* eslint-disable no-use-before-define */
  _emberDataFactoryGuy.default.define('avlm-vehicle-event', {
    polymorphic: false,
    // manually flag this model as NOT polymorphic
    sequences: {
      ids: num => avlmVehicleEvents[num % avlmVehicleEvents.length].id,
      Types: num => avlmVehicleEvents[num % avlmVehicleEvents.length].type,
      EventTypes: num => avlmVehicleEvents[num % avlmVehicleEvents.length].eventType,
      VehicleIds: num => avlmVehicleEvents[num % avlmVehicleEvents.length].vehicleId,
      Lats: num => avlmVehicleEvents[num % avlmVehicleEvents.length].lat,
      Lngs: num => avlmVehicleEvents[num % avlmVehicleEvents.length].lng,
      Headings: num => avlmVehicleEvents[num % avlmVehicleEvents.length].heading,
      Odos: num => avlmVehicleEvents[num % avlmVehicleEvents.length].odo,
      ReceivedAts: num => avlmVehicleEvents[num % avlmVehicleEvents.length].receivedAt,
      Timestamps: num => avlmVehicleEvents[num % avlmVehicleEvents.length].timestamp,
      Route: num => avlmVehicleEvents[num % avlmVehicleEvents.length].route,
      StopType: num => avlmVehicleEvents[num % avlmVehicleEvents.length].stopType,
      StopAddress: num => avlmVehicleEvents[num % avlmVehicleEvents.length].stopAddress,
      PassengerLName: num => avlmVehicleEvents[num % avlmVehicleEvents.length].passengerLName,
      PassengerFName: num => avlmVehicleEvents[num % avlmVehicleEvents.length].passengerFName,
      PassengerId: num => avlmVehicleEvents[num % avlmVehicleEvents.length].passengerId
    },
    default: {
      id: _emberDataFactoryGuy.default.generate('ids'),
      type: _emberDataFactoryGuy.default.generate('Types'),
      eventType: _emberDataFactoryGuy.default.generate('EventTypes'),
      vehicleId: _emberDataFactoryGuy.default.generate('VehicleIds'),
      lat: _emberDataFactoryGuy.default.generate('Lats'),
      lng: _emberDataFactoryGuy.default.generate('Lngs'),
      heading: _emberDataFactoryGuy.default.generate('Headings'),
      odo: _emberDataFactoryGuy.default.generate('Odos'),
      receivedAt: _emberDataFactoryGuy.default.generate('ReceivedAts'),
      timestamp: _emberDataFactoryGuy.default.generate('Timestamps'),
      route: _emberDataFactoryGuy.default.generate('Route'),
      stopType: _emberDataFactoryGuy.default.generate('StopType'),
      stopAddress: _emberDataFactoryGuy.default.generate('StopAddress'),
      passengerLName: _emberDataFactoryGuy.default.generate('PassengerLName'),
      passengerFName: _emberDataFactoryGuy.default.generate('PassengerFName'),
      passengerId: _emberDataFactoryGuy.default.generate('PassengerId')
    }
  });

  const avlmVehicleEvents = [{
    id: 453,
    type: 'avl',
    eventType: 'GPS Ping',
    vehicleId: 513,
    lat: 49.13161,
    lng: -123.10324,
    heading: 1,
    odo: 0,
    receivedAt: '2020-06-23T18:32:23.813Z',
    timestamp: '2020-06-23T18:32:22.456Z',
    route: 351,
    stopType: 'pick up',
    stopAddress: 's2-428 sector 12',
    passengerLName: 'Jackson',
    passengerFName: 'Micheal',
    passengerId: 121
  }, {
    id: 454,
    type: 'avl',
    eventType: 'stop',
    vehicleId: 513,
    lat: 49.13161,
    lng: -123.10324,
    heading: 1,
    odo: 0,
    receivedAt: '2020-06-23T18:32:23.813Z',
    timestamp: '2020-06-23T18:32:22.456Z',
    route: 351,
    stopType: 'drop off',
    stopAddress: 's142 sector 11',
    passengerLName: 'Jackson',
    passengerFName: 'Micheal',
    passengerId: 121
  }, {
    id: 455,
    type: 'avl',
    eventType: 'brake',
    vehicleId: 513,
    lat: 49.13161,
    lng: -123.10324,
    heading: 1,
    odo: 0,
    receivedAt: '2020-06-23T18:32:23.813Z',
    timestamp: '2020-06-23T18:32:22.456Z',
    route: 351,
    stopType: 'pick up',
    stopAddress: 'buckingam palace sector 9',
    passengerLName: 'Sharma',
    passengerFName: 'Chirage',
    passengerId: 122
  }, {
    id: 456,
    type: 'avl',
    eventType: 'GPS Ping',
    vehicleId: 513,
    lat: 49.13161,
    lng: -123.10324,
    heading: 1,
    odo: 0,
    receivedAt: '2020-06-23T18:32:23.813Z',
    timestamp: '2020-06-23T18:32:22.456Z',
    route: 351,
    stopType: 'drop off',
    stopAddress: 'church gate sector 20',
    passengerLName: 'Sharma',
    passengerFName: 'Chirage',
    passengerId: 122
  }, {
    id: 457,
    type: 'avl',
    eventType: 'GPS Ping',
    vehicleId: 513,
    lat: 49.13161,
    lng: -123.10324,
    heading: 1,
    odo: 0,
    receivedAt: '2020-06-23T18:32:23.813Z',
    timestamp: '2020-06-23T18:32:22.456Z',
    route: 351,
    stopType: 'pick up',
    stopAddress: '437 baker street',
    passengerLName: 'Homes',
    passengerFName: 'Sherlock',
    passengerId: 123
  }];
});