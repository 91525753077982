define("adept-iq/models/dwh-trip", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    tripId: attr('string'),
    routeId: attr('number'),
    routeName: attr('string'),
    provider: attr('string'),
    tenantId: attr('number'),
    driver: attr('object'),
    vehicle: attr('object'),
    rider: attr('object'),
    start: attr('object'),
    end: attr('object'),
    anchor: attr('string'),
    tripStatus: attr('string'),
    statusReason: attr('string'),
    tripPurpose: attr('string'),
    fare: attr('number'),
    fareCategoryDisplayName: attr('string'),
    type: attr('string'),
    paymentTypeName: attr('string'),
    serviceCalcStartTime: attr('date'),
    serviceCalcEndTime: attr('date'),
    pca: attr('number'),
    companions: attr('number'),
    wcSeats: attr('number'),
    requestTime: attr('date'),
    promiseTime: attr('date'),
    pickTime: attr('date'),
    dropTime: attr('date'),
    actualPickTime: attr('date'),
    actualDropTime: attr('date'),
    actualMiles: attr('number'),
    reconcileNotes: attr('string'),
    dwhRoute: belongsTo('dwh-route'),
    dwhStops: hasMany('dwh-stop-point', {
      inverse: 'dwhTrip'
    }),
    startAddress: Ember.computed('start.address.{pickStreetNumber,pickStreetAddress}', function () {
      const streetNumber = Ember.get(this, 'start.address.pickStreetNumber');
      const streetAddress = Ember.get(this, 'start.address.pickStreetAddress');
      const address = [];
      address.push(streetNumber);
      address.push(streetAddress);
      return address.join(' ');
    }).readOnly(),
    endAddress: Ember.computed('end.address.{dropStreetNumber,dropStreetAddress}', function () {
      const streetNumber = Ember.get(this, 'end.address.dropStreetNumber');
      const streetAddress = Ember.get(this, 'end.address.dropStreetAddress');
      const address = [];
      address.push(streetNumber);
      address.push(streetAddress);
      return address.join(' ');
    }).readOnly()
  });

  _exports.default = _default;
});