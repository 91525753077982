define("adept-iq/pods/components/side-drawer/edit-trip-widget/component", ["exports", "adept-iq/utils/unwrapProxy", "adept-iq/utils/location-validation", "adept-iq/pods/components/side-drawer/edit-trip-widget/config", "ember-concurrency", "adept-iq/mixins/async-schedule-operation", "ember-changeset", "adept-iq/config/mapped-permIds", "adept-iq/config/api-urls", "adept-iq/models/rider-phone-number", "adept-iq/utils/geolib", "adept-iq/pods/components/iq-widgets/map-widget/config/polyline", "moment", "adept-iq/config/environment", "adept-iq/models/trip"], function (_exports, _unwrapProxy, _locationValidation, _config, _emberConcurrency, _asyncScheduleOperation, _emberChangeset, _mappedPermIds, _apiUrls, _riderPhoneNumber, _geolib, _polyline, _moment, _environment, _trip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  const ANCHOR_OPTIONS = ['pick', 'drop'];
  const SCHEDULING_MODE_OPTIONS = ['automatic', 'manual'];
  const LOCATION_PATH = 'latlng';
  const ZONE_NAME_PATH = 'address.zoneName';
  const PICK = 'pick';
  const TRIP_FIELDS = {
    driverNotes: '',
    passengerNotes: '',
    schedulingMode: '',
    noSharing: '',
    fare: '',
    pickAddressNotes: '',
    dropAddressNotes: '',
    requestTime: '',
    anchor: ''
  };
  const TAXI_SERVICE_MODE = 'taxi';
  const FIELDS_TO_CHECK = ['driverNotes', 'dropPlace', 'noSharing', 'passengerNotes', 'pickPlace', 'schedulingMode', 'pickPhoneNumber', 'dropPhoneNumber', 'pickAddressNotes', 'dropAddressNotes', 'requestTime', 'anchor', 'fareType', 'fare'];
  const PICK_PLACE_TEXT = 'pickPlace';
  const DROP_PLACE_TEXT = 'dropPlace';
  const PICK_PHONE_NUMBER_TEXT = 'pickPhoneNumber';
  const DROP_PHONE_NUMBER_TEXT = 'dropPhoneNumber';
  const REQUEST_TIME_TEXT = 'requestTime';
  const FARE_TYPE_TEXT = 'fareType';
  const FARE_TEXT = 'fare';
  const TRIP_ACTIVITY_LOG_FIELDS = ['Driver notes', 'Dropoff address', 'Rideshare value', 'Passenger notes', 'Pickup address', 'Scheduling mode', 'Pick phone number', 'Drop phone number', 'Pickup address notes', 'Dropoff address notes', 'Request time', 'Request type', 'Fare type', 'Fare'];
  const ELIGIBLE_TRIP_STATUS_TO_EDIT_SCHEDULE_MODE = [_trip.TRIP_SCHEDULED_STATUS.toLowerCase(), _trip.TRIP_CALLBACK_STATUS.toLowerCase(), _trip.TRIP_WAITLIST_STATUS.toLowerCase()];
  const NO_SHARING = 'noSharing';
  const MAX_PHONE_NUMBER_LENGTH = 10;
  const {
    bemap
  } = window;

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    geocode: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    booking: Ember.inject.service(),
    map: Ember.inject.service(),
    title: 'Edit Trip',
    anchorOptions: ANCHOR_OPTIONS,
    schedulingModeOptions: SCHEDULING_MODE_OPTIONS,
    fareTypeOptions: null,
    rideshareOptions: null,
    pickLocationFieldConfig: _config.pickLocationFieldConfig,
    dropLocationFieldConfig: _config.dropLocationFieldConfig,
    classNames: ['form-widget'],
    isSearchEnabled: false,
    searchText: '',
    errors: null,
    record: null,
    previousTripInfo: null,
    trip: Ember.computed.readOnly('stackFrame.options.trip'),
    showMapModal: Ember.computed.readOnly('stackFrame.options.showMapModal'),
    previousFareType: null,
    previousFare: null,
    previousPickPlace: null,
    previousDropPlace: null,
    previousPickAddress: null,
    previousDropAddress: null,
    previousPickLocation: null,
    previousDropLocation: null,
    previousPickPhoneNumber: null,
    previousDropPhoneNumber: null,
    maxXYDelta: null,
    isInvalidAddressSelected: null,
    currentState: null,
    previousTripDetails: null,
    previousClonedPickPlace: null,
    previousClonedDropPlace: null,
    previousClonedPickPhoneNumber: null,
    previousClonedDropPhoneNumber: null,
    editPassengerNotesSchedulingGranted: false,
    editRideshareSchedulingGranted: false,
    isComputingDistance: false,
    distancePt1: null,
    distancePt2: null,
    dateTimeFormat: _environment.default.dateTimeFormat,
    hasPromiseTime: Ember.computed('trip.promiseTime', function () {
      const promiseTime = this.get('trip.promiseTime');
      return Ember.isPresent(promiseTime) || false;
    }),
    canSave: Ember.computed('record.{requestTime,pickAddressNotes,dropAddressNotes,anchor,fare,fareType,pickPlace.location.geoNode,dropPlace.location.geoNode,passengerNotes,driverNotes,schedulingMode,noSharing,pickPlace.address.fullAddress,dropPlace.address.fullAddress,pickPhoneNumber.fullPhoneNumber,dropPhoneNumber.fullPhoneNumber}', 'geocode.isGeoNodeResultsFetched', function () {
      const record = this.get('record');
      const isInvalidAddressSelected = this.get('isInvalidAddressSelected');
      const isGeoNodeResultsFetched = this.get('geocode.isGeoNodeResultsFetched');
      return this.isTripChanged() || record.get('pickPhoneNumber.hasDirtyAttributes') || record.get('dropPhoneNumber.hasDirtyAttributes') || record.get('fareType.id') !== this.get('previousFareType.id') || this.isPlaceChanged(record.get('pickPlace.address'), this.get('previousPickAddress')) && isGeoNodeResultsFetched || this.isPlaceChanged(record.get('dropPlace.address'), this.get('previousDropAddress')) && isGeoNodeResultsFetched || isInvalidAddressSelected;
    }),
    editRideshareGranted: Ember.computed('', function () {
      let editRideshareGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editRideshareScheduling, null);
      const isDispatchDashboard = this.get('workspace.isDispatchDashboard');

      if (isDispatchDashboard) {
        editRideshareGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editRideshareDispatch, null);
      }

      return editRideshareGranted;
    }),
    editPassengerNotesGranted: Ember.computed('', function () {
      let editPassengerNotesGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editPassengerNotesScheduling);
      const isDispatchDashboard = this.get('workspace.isDispatchDashboard');

      if (isDispatchDashboard) {
        editPassengerNotesGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editPassengerNotesDispatch);
      }

      return editPassengerNotesGranted;
    }),
    editPhoneNumberGranted: Ember.computed('', function () {
      let editPhoneNumberGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editFuture);
      const isDispatchDashboard = this.get('workspace.isDispatchDashboard');

      if (isDispatchDashboard) {
        editPhoneNumberGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editSameDay);
      }

      return editPhoneNumberGranted;
    }),
    hasTripChange: Ember.observer('record.{anchor,requestTime,pickAddressNotes,dropAddressNotes,pickPlace.location.geoNode,dropPlace.location.geoNode,passengerNotes,driverNotes,schedulingMode,noSharing,pickPlace.address.fullAddress,dropPlace.address.fullAddress}', function () {
      this.setPreviousTripInfo();
    }),

    init() {
      this._super(...arguments);

      this.setDefaultProperties(this.get('trip'));
      this.loadCurrentState();
      this.setProperties({
        rideshareOptions: [{
          id: 0,
          name: 'Allowed'
        }, {
          id: 2,
          name: 'Not Allowed'
        }]
      });
      this.setPreviousClonedTripData();
      this.set('previousTripDetails', this.getPreviousTripDetails());
      this.set('geocode.isInvalidAddress', true);
      this.set('editRideshareSchedulingGranted', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editRideshareScheduling, null));
      this.set('editPassengerNotesSchedulingGranted', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editPassengerNotesScheduling));
      this.set('booking.tripOverlap');
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.$('.side-drawer-modal').css('min-width', '470px');
      const editmodelwidth = Ember.$('.edit-trip-side-drawer').width(); // $('.edit-trip-side-drawer').width('25%');

      Ember.$('.booking-trip-map-container').css({
        'width': 'calc(97% - ' + editmodelwidth + 'px)'
      }).css({
        'height': 'calc(100% - 15%)',
        'left': editmodelwidth + 'px)'
      });
      this.loadCurrentState();
    },

    willDestroyElement() {
      this.set('isInvalidAddress', false);

      this._super(...arguments);
    },

    //this method used to clone the address in place model
    createPlaceRecord(place) {
      const store = this.get('store');
      const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
      const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
      const addressAttr = address.toJSON();
      const placeAttr = place.toJSON();
      const locationAttr = location.toJSON(); //const location = store.createRecord('location',);

      const clonedAddress = store.createRecord('address', addressAttr);
      const clonePlace = store.createRecord('place', placeAttr);
      const cloneLocation = store.createRecord('location', locationAttr);
      clonedAddress.set('freeformAddress', clonedAddress.get('fullAddress'));
      clonePlace.set('address', clonedAddress); // if clone the location it will update the location in map

      clonePlace.set('location', place.get('location'));
      clonePlace.set('cloneLocation', cloneLocation);
      return new _emberChangeset.default(clonePlace);
    },

    //this method used to clone the phone number model
    createPhoneNumberRecord(phoneNumber) {
      const store = this.get('store');
      const phoneNumberAttr = phoneNumber ? phoneNumber.toJSON() : null;
      const clonePhoneNumber = store.createRecord('segment-stop-phone-number', phoneNumberAttr);
      return new _emberChangeset.default(clonePhoneNumber);
    },

    async setDefaultProperties(trip) {
      const store = this.get('store');
      const unwrapPickPlace = (0, _unwrapProxy.unwrapProxy)(trip.get('pick.segmentStop.place'));
      const unwrapDropPlace = (0, _unwrapProxy.unwrapProxy)(trip.get('drop.segmentStop.place')); // In dispatch workspace, core entity for stopWidget is stopPoint, so phonenumbers stored in stopPoint model

      const pickPhoneNumber = trip.get('pick.segmentStopPhoneNumbers') || trip.get('pickStopPoint.segmentStopPhoneNumbers') || trip.get('segment.pick.phoneNumbers');
      const dropPhoneNumber = trip.get('drop.segmentStopPhoneNumbers') || trip.get('dropStopPoint.segmentStopPhoneNumbers') || trip.get('segment.drop.phoneNumbers');
      const unwrapPickPhoneNumber = pickPhoneNumber && (0, _unwrapProxy.unwrapProxy)(pickPhoneNumber.firstObject) ? store.peekRecord('segment-stop-phone-number', (0, _unwrapProxy.unwrapProxy)(pickPhoneNumber.get('firstObject.id'))) : null;
      const unwrapDropPhoneNumber = dropPhoneNumber && (0, _unwrapProxy.unwrapProxy)(dropPhoneNumber.firstObject) ? store.peekRecord('segment-stop-phone-number', (0, _unwrapProxy.unwrapProxy)(dropPhoneNumber.get('firstObject.id'))) : null;
      const pickPlace = this.createPlaceRecord(unwrapPickPlace);
      const dropPlace = this.createPlaceRecord(unwrapDropPlace);
      this.set('previousPickPlace', unwrapPickPlace);
      this.set('previousDropPlace', unwrapDropPlace);
      this.set('previousPickAddress', unwrapPickPlace.get('address'));
      this.set('previousDropAddress', unwrapDropPlace.get('address'));
      this.set('previousPickLocation', pickPlace.get('cloneLocation'));
      this.set('previousDropLocation', dropPlace.get('cloneLocation'));
      this.set('previousPickPhoneNumber', unwrapPickPhoneNumber);
      this.set('previousDropPhoneNumber', unwrapDropPhoneNumber); //set fare type

      const fareTypes = store.peekAll('fare-type');
      this.set('fareTypeOptions', fareTypes); //set maxXYDelta

      const tripMaxXYDelta = this.get('store').peekRecord('cs-config-item', 'config-Scheduling_parms/MODTRIP_MAX_XY_DELTA');
      const maxXYDelta = tripMaxXYDelta ? parseFloat(tripMaxXYDelta.get('value')) : 0;
      this.set('maxXYDelta', maxXYDelta);
      this.set('pickSegmentStopPlace', unwrapPickPlace);
      this.set('dropSegmentStopPlace', unwrapDropPlace);
      const noSharing = (0, _unwrapProxy.unwrapProxy)(trip.get('segment.noSharing')) || 0;
      const driverNotes = trip.get('segment.leg.notes') ? trip.get('segment.leg.notes').trim() : '';
      const passengerNotes = trip.get('segment.leg.rider.notes') ? trip.get('segment.leg.rider.notes').trim() : '';
      const isScheduleModeShouldDisable = this.checkScheduleModeIsEditableForTrip(trip); //create emberObject with trip attribute values

      const record = Ember.Object.create({
        driverNotes,
        passengerNotes,
        tripId: trip.get('tripId'),
        riderId: trip.get('segment.leg.rider.riderId'),
        pick: (0, _unwrapProxy.unwrapProxy)(trip.get('pick')),
        drop: (0, _unwrapProxy.unwrapProxy)(trip.get('drop')),
        fare: trip.get('segment.fare'),
        requestTime: trip.get('segment.leg.requestTime'),
        anchor: trip.get('segment.anchor'),
        schedulingMode: trip.get('schedulingMode').toLowerCase(),
        fareType: trip.get('segment.fareType'),
        pickPlace: pickPlace,
        dropPlace: dropPlace,
        noSharing: noSharing,
        disabledSchedulingMode: isScheduleModeShouldDisable,
        pickPhoneNumber: unwrapPickPhoneNumber,
        dropPhoneNumber: unwrapDropPhoneNumber,
        isScheduled: trip.get('isScheduled'),
        pickAddressNotes: trip.get('segment.pick.notes'),
        dropAddressNotes: trip.get('segment.drop.notes')
      });
      const previousTripInfo = Ember.Object.create({
        driverNotes: record.get('driverNotes'),
        passengerNotes: record.get('passengerNotes'),
        schedulingMode: trip.get('schedulingMode').toLowerCase(),
        noSharing: noSharing,
        fare: record.get('fare'),
        pickAddressNotes: record.get('pickAddressNotes'),
        dropAddressNotes: record.get('dropAddressNotes'),
        requestTime: record.get('requestTime'),
        anchor: record.get('anchor')
      });
      const unwrapFareType = (0, _unwrapProxy.unwrapProxy)(record.get('fareType'));
      const fare = record.get('fare');
      this.set('previousFareType', unwrapFareType);
      this.set('previousFare', fare);
      this.set('previousTripInfo', previousTripInfo);
      this.set('record', record);
    },

    isTripChanged() {
      const record = this.get('record');
      const previousTripInfo = this.get('previousTripInfo');
      const fields = {};
      Object.entries(TRIP_FIELDS).forEach(key => {
        fields[key[0]] = record.get(key[0]);
      });
      const {
        passengerNotes,
        driverNotes,
        pickAddressNotes,
        dropAddressNotes
      } = fields;
      fields.passengerNotes = passengerNotes ? passengerNotes.trim() : passengerNotes;
      fields.driverNotes = driverNotes ? driverNotes.trim() : driverNotes;
      fields.pickAddressNotes = pickAddressNotes ? pickAddressNotes.trim() : pickAddressNotes;
      fields.dropAddressNotes = dropAddressNotes ? dropAddressNotes.trim() : dropAddressNotes;
      this.set('isInvalidAddressSelected', false);
      return JSON.stringify(previousTripInfo) !== JSON.stringify(fields);
    },

    isPlaceChanged(address, previousPlaceAddress) {
      if (address && previousPlaceAddress && previousPlaceAddress.get('fullAddress') !== address.get('fullAddress')) {
        return true;
      }

      return false;
    },

    /**
     * NYAAR-20181: validates is the phoneNumber is 10 digit long
     * @returns {*Boolean}
     */
    validatePhoneNumber() {
      const record = this.get('record');
      const phoneNumberArray = [];
      if ((0, _unwrapProxy.unwrapProxy)(record.get('pickPhoneNumber.isForceDirty'))) phoneNumberArray.push((0, _unwrapProxy.unwrapProxy)(record.get('pickPhoneNumber')));
      if ((0, _unwrapProxy.unwrapProxy)(record.get('dropPhoneNumber.isForceDirty'))) phoneNumberArray.push((0, _unwrapProxy.unwrapProxy)(record.get('dropPhoneNumber')));
      const validatePhoneNumber = phoneNumberArray.every(phoneNumber => {
        return !Ember.isPresent(phoneNumber.get('fullPhoneNumber')) || (phoneNumber.get('areaCode') + phoneNumber.get('phoneNumber')).length === MAX_PHONE_NUMBER_LENGTH;
      });
      return validatePhoneNumber;
    },

    async validateRecord() {
      let valid = true;
      const record = this.get('record');
      const selectedRider = this.get('trip.rider');
      const promiseTime = this.get('trip.promiseTime');
      const requestTimes = [record.get('requestTime')];
      const tripIds = [this.get('trip.id')];
      const tripOverlap = Ember.isEmpty(promiseTime) ? await this.get('booking').overlapCheck(selectedRider.get('id'), requestTimes, tripIds) : null;
      this.set('errors', []);
      const requestedTime = record.get('requestTime');

      if (Ember.isNone(requestedTime)) {
        valid = false;
        this.get('errors').pushObject('Date should be there');
      }

      if (Ember.isEmpty(record.get('pickPlace.location.latlng'))) {
        valid = false;
        this.get('errors').pushObject('Pickup address should be there');
      }

      if (Ember.isEmpty(record.get('dropPlace.location.latlng'))) {
        valid = false;
        this.get('errors').pushObject('Drop address should be there');
      }

      if (!this.validatePhoneNumber()) {
        valid = false;
        this.get('errors').pushObject('Please enter 10 digit in the Phone number field.');
      }

      if (Ember.isPresent(tripOverlap) && tripOverlap.overlaps.find(overlap => overlap.isOverlapping)) {
        valid = await this.get('booking').showOverlapWarningMessage(tripOverlap);
      }

      return valid;
    },

    updatePlaceInTrip: (0, _emberConcurrency.task)(function* (trip, pickPlaceId, dropPlaceId) {
      const schedule = trip.get('route.schedule') || trip.get('schedule');
      const tripId = trip.get('id');
      let result;

      try {
        const operationData = {
          tripId,
          pickPlaceId,
          dropPlaceId
        };
        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, 'tripPlaceUpdate', options);

        if (!result) {
          throw new Error('Failed to update place');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),

    async savePlaceObject(place) {
      if (!place.get('isForceDirty')) {
        return place;
      }

      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
      const location = (0, _unwrapProxy.unwrapProxy)(place.get('location')); // if zone name is missing for any reason we are giving default value to eliminate edit failure

      if (Ember.isNone(address.get('zoneName.id'))) {
        address.set('zoneName', store.peekAll('zone-type').get('firstObject'));
      }

      const locationResponse = await locationAdapter.postLocation(location);
      const addressResponse = await addressAdapter.postAddress(address);
      const placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
      const createdLocation = await store.findRecord('location', locationResponse.data.id);
      const createdAddress = await store.findRecord('address', addressResponse.data.id);
      const createdPlace = await store.findRecord('place', placeResponse.data.id);
      createdPlace.set('location', createdLocation);
      createdPlace.set('address', createdAddress);
      return createdPlace;
    },

    async getSegmentStopRecord(segmentStopId) {
      const store = this.get('store');
      await store.findRecord('segment-stop', segmentStopId);
      const segmentStopRecord = await store.peekRecord('segment-stop', segmentStopId);
      return segmentStopRecord;
    },

    async createNewPlace(record) {
      const pickSegmentStop = (0, _unwrapProxy.unwrapProxy)(record.get('pick.segmentStop'));
      const dropSegmentStop = (0, _unwrapProxy.unwrapProxy)(record.get('drop.segmentStop'));
      const pickPlace = (0, _unwrapProxy.unwrapProxy)(pickSegmentStop.get('place'));
      const dropPlace = (0, _unwrapProxy.unwrapProxy)(dropSegmentStop.get('place'));
      const segmentStopPick = await this.getSegmentStopRecord(pickSegmentStop.get('id'));
      const segmentStopDrop = await this.getSegmentStopRecord(dropSegmentStop.get('id'));
      pickSegmentStop.set('dwell', segmentStopPick.get('dwell'));
      dropSegmentStop.set('dwell', segmentStopDrop.get('dwell')); //if both pick and drop address are not changed no need to update

      if (!pickPlace.get('isForceDirty') && !dropPlace.get('isForceDirty')) {
        return Promise.resolve();
      } // create new pick and drop place


      const newPickPlace = await this.savePlaceObject(pickPlace);
      const newDropPlace = await this.savePlaceObject(dropPlace); //set created  place in pick and drop

      this.set('pickSegmentStopPlace', newPickPlace);
      this.set('dropSegmentStopPlace', newDropPlace);
      return Promise.resolve();
    },

    saveNewAddressObject(record) {
      return this.createNewPlace(record);
    },

    async updatePlaceInTripRecord(record) {
      const pickSegmentStop = (0, _unwrapProxy.unwrapProxy)(record.get('pick.segmentStop'));
      const dropSegmentStop = (0, _unwrapProxy.unwrapProxy)(record.get('drop.segmentStop'));
      const pickPlace = (0, _unwrapProxy.unwrapProxy)(pickSegmentStop.get('place'));
      const dropPlace = (0, _unwrapProxy.unwrapProxy)(dropSegmentStop.get('place'));
      const isDispatchDashboard = this.get('workspace.isDispatchDashboard'); //if both pick and drop address are not changed no need to update

      if (!pickPlace.get('isForceDirty') && !dropPlace.get('isForceDirty')) {
        return Promise.resolve();
      }

      const pickSegmentStopPlace = this.get('pickSegmentStopPlace');
      const dropSegmentStopPlace = this.get('dropSegmentStopPlace');
      pickSegmentStop.set('place', pickSegmentStopPlace);
      dropSegmentStop.set('place', dropSegmentStopPlace);
      await (0, _unwrapProxy.unwrapProxy)(record.get('pick.segmentStop')).save();
      await (0, _unwrapProxy.unwrapProxy)(record.get('drop.segmentStop')).save();

      if (isDispatchDashboard) {
        return this.updatePlaceInTripForDispatch.perform(record, parseInt(pickSegmentStopPlace.get('id'), 10), parseInt(dropSegmentStopPlace.get('id'), 10));
      }

      return this.updatePlaceInTrip.perform(record, pickSegmentStopPlace.get('id'), dropSegmentStopPlace.get('id'));
    },

    async syncAVLM() {
      const store = this.get('store');
      const trip = this.get('trip');
      const dispatchRouteId = trip.get('route.dispatchRoute.id');
      const dispatchAdapter = store.adapterFor('dispatch-route');
      return Ember.isPresent(dispatchRouteId) ? dispatchAdapter.syncAVLM(dispatchRouteId) : null;
    },

    //save edit trip details
    saveTask: (0, _emberConcurrency.task)(function* () {
      this.copyRecordDataToTrip();
      const record = this.get('trip');
      const isDispatchDashboard = this.get('workspace.isDispatchDashboard'); // while PATCH the trip for edit we don't need currentProviderName attribute

      record.set('currentProviderName', '');

      try {
        yield this.saveNewAddressObject(record);
        yield (0, _unwrapProxy.unwrapProxy)(record.get('segment.pick')).save();
        yield (0, _unwrapProxy.unwrapProxy)(record.get('segment.drop')).save();
        yield (0, _unwrapProxy.unwrapProxy)(record.get('segment.leg.rider')).save();
        yield (0, _unwrapProxy.unwrapProxy)(record.get('segment.leg')).save();
        yield (0, _unwrapProxy.unwrapProxy)(record.get('segment')).save();
        yield this.updatePlaceInTripRecord(record);
        yield this.savePhoneNumbers();

        if (isDispatchDashboard && record.get('isScheduled')) {
          yield this.syncAVLM();
        }

        return record.save();
      } catch (e) {
        throw e;
      }
    }),

    /**
     * NYAAR-20181: to save both pick and drop phoneNumber
     */
    async savePhoneNumber(phoneNumberRecordName) {
      const record = this.get('record');
      const phoneNumber = (0, _unwrapProxy.unwrapProxy)(record.get(phoneNumberRecordName));

      if (phoneNumber) {
        if (!Ember.isPresent(phoneNumber.get('fullPhoneNumber'))) {
          phoneNumber.deleteRecord();
        }

        if (phoneNumber.get('isForceDirty')) {
          await phoneNumber.save();
        }
      }
    },

    async savePhoneNumbers() {
      await this.savePhoneNumber(PICK_PHONE_NUMBER_TEXT);
      await this.savePhoneNumber(DROP_PHONE_NUMBER_TEXT);
    },

    copyRecordDataToTrip() {
      const trip = this.get('trip');
      const record = this.get('record');
      const pickPlace = record.get('pickPlace');
      const dropPlace = record.get('dropPlace');
      trip.set('segment.leg.notes', record.get('driverNotes'));
      trip.set('segment.leg.rider.notes', record.get('passengerNotes'));
      trip.set('segment.fare', record.get('fare'));
      trip.set('segment.anchor', record.get('anchor'));
      trip.set('segment.noSharing', record.get('noSharing'));
      trip.set('schedulingMode', record.get('schedulingMode'));
      trip.set('segment.fareType', record.get('fareType'));
      trip.set('segment.pick.notes', record.get('pickAddressNotes'));
      trip.set('segment.drop.notes', record.get('dropAddressNotes'));
      trip.set('segment.leg.requestTime', record.get('requestTime'));

      if (pickPlace.get('isForceDirty')) {
        trip.set('pick.segmentStop.place.address', pickPlace.get('address'));
        trip.set('pick.segmentStop.place.address.zoneName', pickPlace.get('address.zoneName'));
        trip.set('pick.segmentStop.place.isForceDirty', pickPlace.get('isForceDirty'));
      }

      if (dropPlace.get('isForceDirty')) {
        trip.set('drop.segmentStop.place.address', dropPlace.get('address'));
        trip.set('drop.segmentStop.place.address.zoneName', dropPlace.get('address.zoneName'));
        trip.set('drop.segmentStop.place.isForceDirty', dropPlace.get('isForceDirty'));
      }
    },

    //to avoid two-way binding used local ember object that's why reset manually
    resetTripModel(trip) {
      const record = this.get('record');
      const previousTripInfo = this.get('previousTripInfo');
      const pickPlace = record.get('pickPlace');
      const dropPlace = record.get('dropPlace');
      const tripPickPlace = trip.get('pick.segmentStop.place');
      const tripDropPlace = trip.get('drop.segmentStop.place');
      const tripPickLocation = (0, _unwrapProxy.unwrapProxy)(tripPickPlace.get('location'));
      const tripDropLocation = (0, _unwrapProxy.unwrapProxy)(tripDropPlace.get('location'));
      record.set('driverNotes', previousTripInfo.get('driverNotes'));
      record.set('passengerNotes', previousTripInfo.get('passengerNotes'));
      record.set('noSharing', previousTripInfo.get('noSharing') || 0);
      record.set('pickAddressNotes', previousTripInfo.get('pickAddressNotes'));
      record.set('dropAddressNotes', previousTripInfo.get('dropAddressNotes'));
      record.set('schedulingMode', trip.get('schedulingMode'));
      record.set('fare', trip.get('segment.fare'));
      record.set('fareType', trip.get('segment.fareType'));
      record.set('pickPhoneNumber', this.get('previousClonedPickPhoneNumber'));
      record.set('dropPhoneNumber', this.get('previousClonedDropPhoneNumber'));

      if (pickPlace.get('isForceDirty')) {
        this.resetRecordAddress((0, _unwrapProxy.unwrapProxy)(tripPickPlace.get('address')), pickPlace.get('address'));
        tripPickLocation.rollbackAttributes();
        record.set('pickPlace.isForceDirty', false);
      }

      if (dropPlace.get('isForceDirty')) {
        this.resetRecordAddress((0, _unwrapProxy.unwrapProxy)(tripDropPlace.get('address')), dropPlace.get('address'));
        tripDropLocation.rollbackAttributes();
        record.set('dropPlace.isForceDirty', false);
      } //NYAAR-18905 On reload on the panel the map markers should get removed from the map.


      this.get('geocode').deactivateGeocode();
    },

    //to update trip previous addresses/location, if trip update fails
    async resetTrip(trip) {
      const pickSegmentStop = (0, _unwrapProxy.unwrapProxy)(trip.get('pick.segmentStop'));
      const dropSegmentStop = (0, _unwrapProxy.unwrapProxy)(trip.get('drop.segmentStop'));
      const pickPlace = this.get('previousPickPlace');
      const dropPlace = this.get('previousDropPlace');
      pickPlace.set('address', this.get('previousPickAddress'));
      pickPlace.set('location', this.get('previousPickLocation'));
      dropPlace.set('address', this.get('previousDropAddress'));
      dropPlace.set('location', this.get('previousDropLocation'));
      pickSegmentStop.set('place', pickPlace);
      dropSegmentStop.set('place', dropPlace);
      await (0, _unwrapProxy.unwrapProxy)(trip.get('pick.segmentStop')).save();
      await (0, _unwrapProxy.unwrapProxy)(trip.get('drop.segmentStop')).save();
      this.resetTripModel(trip);
    },

    resetRecordAddress(tripAddress, recordAddress) {
      const addressAttr = tripAddress.toJSON();
      Object.entries(addressAttr).forEach(_ref => {
        let [key, val] = _ref;
        recordAddress.set(key, val);
      });
    },

    getGeoNode(place) {
      const geocode = this.get('geocode');
      const address = place.get('address');
      return geocode.calculateZone(place.get('location.lat'), place.get('location.lng')).then(result => {
        if (!Ember.isNone(result)) {
          place.set('location.geoNode', result.geonode);
        } else {
          this.get('notifications').warning(`CAN'T GET GEONODE FOR ${address.get('fullAddress')}`);
        }
      });
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        const record = this.get('record');
        record.set('pickPlace', currentState.pickPlace);
        record.set('dropPlace', currentState.dropPlace);
        record.set('driverNotes', currentState.driverNotes);
        record.set('passengerNotes', currentState.passengerNotes);
        record.set('pickAddressNotes', currentState.pickAddressNotes);
        record.set('dropAddressNotes', currentState.dropAddressNotes);
        record.set('schedulingMode', currentState.schedulingMode);
        record.set('noSharing', currentState.noSharing);
        record.set('pickPhoneNumber', currentState.pickPhoneNumber);
        record.set('dropPhoneNumber', currentState.dropPhoneNumber);
        record.set('pickAddressNotes', currentState.pickAddressNotes);
        record.set('dropAddressNotes', currentState.dropAddressNotes);
        record.set('requestTime', currentState.requestTime);
        record.set('anchor', currentState.anchor);
      }
    },

    setPreviousTripInfo() {
      const previousTripInfo = this.get('previousTripInfo');
      const currentState = this.getPreviousTripDetails();
      this.set('currentState', currentState);
      this.set('previousTripInfo', previousTripInfo);
    },

    //dispatch workspace edit trip sync call
    updatePlaceInTripForDispatch: (0, _emberConcurrency.task)(function* (trip, pickPlaceId, dropPlaceId) {
      const dispatchSchedule = trip.get('route.schedule.dispatchSchedule') || trip.get('schedule.dispatchSchedule');
      const tripId = parseInt(trip.get('id'), 10);
      const dispatchScheduleId = parseInt(dispatchSchedule.get('id'), 10);
      const session = this.get('session');
      const continuousOptHost = _apiUrls.API.continuousOptService.host;
      let result;

      try {
        const payload = {
          data: {
            type: 'tripPlaceUpdate',
            attributes: {
              dispatchScheduleId: dispatchScheduleId,
              tripId: tripId,
              pickPlaceId: pickPlaceId,
              dropPlaceId: dropPlaceId
            }
          }
        };
        result = yield this.get('ajax').post(continuousOptHost + '/optimizer/trip-place-update', {
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          },
          data: payload
        });

        if (!result) {
          throw new Error('Failed to update place');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),

    getPreviousTripDetails() {
      const record = this.get('record');
      let currentState = this.get('currentState');
      const {
        pickPlace,
        dropPlace,
        driverNotes,
        passengerNotes,
        schedulingMode,
        noSharing,
        pickPhoneNumber,
        dropPhoneNumber,
        pickAddressNotes,
        dropAddressNotes,
        requestTime,
        anchor
      } = record.getProperties('pickPlace', 'dropPlace', 'driverNotes', 'passengerNotes', 'schedulingMode', 'noSharing', 'pickPhoneNumber', 'dropPhoneNumber', 'pickAddressNotes', 'dropAddressNotes', 'requestTime', 'anchor');
      currentState = {
        pickPlace,
        dropPlace,
        driverNotes,
        passengerNotes,
        schedulingMode,
        noSharing,
        pickPhoneNumber,
        dropPhoneNumber,
        pickAddressNotes,
        dropAddressNotes,
        requestTime,
        anchor
      };
      return currentState;
    },

    //checking the edited fields and creating activity logs for that
    async checkEditedFieldsAndCreateActivityLog() {
      const record = this.get('record');
      const previousTripDetails = this.get('previousTripDetails');
      const tripId = this.get('trip.tripId');
      const tripActivity = 'Edit';
      const routeTripActivity = 'Trip Edited';
      const actualTime = (0, _moment.default)().toDate();
      const type = 'genericLogging';
      const userId = this.session.data.authenticated.userId;
      const tripActivityLog = {
        details: '',
        activity: tripActivity,
        actualTime: actualTime,
        type: type,
        userId: userId
      };
      const routeTripActivityLog = {
        details: `Trip ${tripId} edited.`,
        activity: routeTripActivity,
        actualTime: actualTime,
        type: type,
        userId: userId
      };
      FIELDS_TO_CHECK.forEach((field, index) => {
        this.prepareTripAndRouteActivityLogs(index, field, previousTripDetails, record, tripActivityLog, routeTripActivityLog);
      });
      await this.createEditTripActivityLog(tripActivityLog);
      await this.createEditRouteTripActivityLog(routeTripActivityLog);
    },

    //creating trip activity log while trip edited
    createEditTripActivityLog(tripActivityLog) {
      const trip = this.get('trip');
      const activityLogAdapter = this.get('store').adapterFor('activity-log');
      const tripInfo = Ember.Object.create({
        context: {
          trip: trip
        }
      });
      const userInfo = {
        content: tripActivityLog
      };
      return activityLogAdapter.createUserTripActivity(userInfo, tripInfo);
    },

    //creating route activity log while trip edited
    createEditRouteTripActivityLog(routeTripActivityLog) {
      const route = (0, _unwrapProxy.unwrapProxy)(this.get('trip.route'));

      if (route) {
        const activityLogAdapter = this.get('store').adapterFor('activity-log');
        const tripInfo = Ember.Object.create({
          context: {
            route: route
          }
        });
        const userInfo = {
          content: routeTripActivityLog
        };
        return activityLogAdapter.createUserRouteActivity(userInfo, tripInfo, 'route');
      }
    },

    //backup the previous cloned address
    setPreviousClonedTripData() {
      const previousPickPlace = this.get('previousPickPlace');
      const previousDropPlace = this.get('previousDropPlace');
      const previousPickPhoneNumber = this.get('previousPickPhoneNumber');
      const previousDropPhoneNumber = this.get('previousDropPhoneNumber');
      const previousClonedPickPlace = this.createPlaceRecord(previousPickPlace);
      const previousClonedDropPlace = this.createPlaceRecord(previousDropPlace);
      const previousClonedPickPhoneNumber = previousPickPhoneNumber ? this.createPhoneNumberRecord(previousPickPhoneNumber) : null;
      const previousClonedDropPhoneNumber = previousDropPhoneNumber ? this.createPhoneNumberRecord(previousDropPhoneNumber) : null;
      this.set('previousClonedPickPlace', previousClonedPickPlace);
      this.set('previousClonedDropPlace', previousClonedDropPlace);
      this.set('previousClonedPickPhoneNumber', previousClonedPickPhoneNumber);
      this.set('previousClonedDropPhoneNumber', previousClonedDropPhoneNumber);
    },

    //preparing the edited trip fields activity log details
    prepareTripAndRouteActivityLogs(index, field, previousTripDetails, record, tripActivityLog, routeTripActivityLog) {
      const value = TRIP_ACTIVITY_LOG_FIELDS[index];
      const rideshareOptions = this.get('rideshareOptions');
      let changedFromField = previousTripDetails[field];
      let changedFrom, changedTo;

      switch (field) {
        case PICK_PLACE_TEXT:
          changedFromField = this.get('previousClonedPickPlace');
          changedFrom = changedFromField.get('address.aliasAddress');
          changedTo = record[field].get('address.aliasAddress');
          break;

        case DROP_PLACE_TEXT:
          changedFromField = this.get('previousClonedDropPlace');
          changedFrom = changedFromField.get('address.aliasAddress');
          changedTo = record[field].get('address.aliasAddress');
          break;

        case PICK_PHONE_NUMBER_TEXT:
          changedFromField = this.get('previousClonedPickPhoneNumber');
          changedFrom = changedFromField ? changedFromField.get('fullPhoneNumber') : null;
          changedTo = record[field] ? record[field].get('fullPhoneNumber') : null;
          break;

        case DROP_PHONE_NUMBER_TEXT:
          changedFromField = this.get('previousClonedDropPhoneNumber');
          changedFrom = changedFromField ? changedFromField.get('fullPhoneNumber') : null;
          changedTo = record[field] ? record[field].get('fullPhoneNumber') : null;
          break;

        case REQUEST_TIME_TEXT:
          changedFrom = changedFromField ? (0, _moment.default)(changedFromField).format(_environment.default.dateTimeFormat.timeMoment) : null;
          changedTo = record[field] ? (0, _moment.default)(record[field]).format(_environment.default.dateTimeFormat.timeMoment) : null;
          break;

        case FARE_TYPE_TEXT:
          changedFromField = this.get('previousFareType');
          changedFrom = changedFromField ? changedFromField.get('id') : null;
          changedTo = record[field] ? record[field].get('id') : null;
          break;

        case FARE_TEXT:
          changedFromField = this.get('previousFare');
          changedFrom = changedFromField ? changedFromField : null;
          changedTo = record[field] ? parseFloat(record[field]) : null;
          break;

        default:
          changedFrom = changedFromField;
          changedTo = record[field];
          break;
      }

      if (changedFrom !== changedTo) {
        if (field === NO_SHARING) {
          changedFrom = rideshareOptions.findBy('id', changedFrom).name;
          changedTo = rideshareOptions.findBy('id', changedTo).name;
        }

        const tripDetails = `${value} changed from "${changedFrom}" to "${changedTo}". `;
        const routeTripDetails = `${value} changed. `;
        tripActivityLog.details = tripActivityLog.details + tripDetails;
        routeTripActivityLog.details = routeTripActivityLog.details + routeTripDetails;
      }
    },

    //Editing trip status Scheduled, Scheduling Mode field should be enabled and allowed to update
    checkScheduleModeIsEditableForTrip(trip) {
      const isTaxiTrip = trip.get('segment.travelMode') === TAXI_SERVICE_MODE;
      const isScheduleModeShouldDisable = !ELIGIBLE_TRIP_STATUS_TO_EDIT_SCHEDULE_MODE.includes(trip.get('status')) || isTaxiTrip;
      return isScheduleModeShouldDisable;
    },

    setDistances(coord) {
      if (this.get('distancePt1') === null) {
        this.set('distancePt1', this.markerPinPoint(coord));
      } else if (this.get('distancePt2') === null) {
        this.set('distancePt2', this.markerPinPoint(coord));
        const map = this.get('booking.bookingMap');
        let distance = (0, _geolib.getDistance)(this.get('distancePt1.native._latlng'), coord); // eslint-disable-line
        //convert from metre -> feet -> mile

        distance = distance * 3.281 / 5280;
        this.set('computedDistance', `${distance.toFixed(3)} mi`);
        const polyLineOptions = _polyline.default['dottedDistance']; // eslint-disable-line

        const distancePoints = [{
          lat: this.get('distancePt1.native._latlng.lat'),
          lon: this.get('distancePt1.native._latlng.lng')
        }, {
          lat: this.get('distancePt2.native._latlng.lat'),
          lon: this.get('distancePt2.native._latlng.lng')
        }];
        const distancePolyline = new bemap.Polyline(distancePoints, {
          style: polyLineOptions
        });
        map.addPolyline(distancePolyline);
        this.set('distancePolyline', distancePolyline);
      } else {
        this.distanceReset();
      }
    },

    distanceReset() {
      const map = this.get('booking.bookingMap');
      const distancePt1 = this.get('distancePt1');
      const distancePt2 = this.get('distancePt2');
      const distancePolyline = this.get('distancePolyline');

      if (distancePt1) {
        map.removeMarker(distancePt1);
      }

      if (distancePt2) {
        map.removeMarker(distancePt2);
      }

      if (distancePolyline) {
        map.removePolyline(distancePolyline);
      }

      this.setProperties({
        distancePt1: null,
        distancePt2: null,
        computedDistance: null,
        distancePolyline: null
      });
    },

    markerPinPoint(coord) {
      const map = this.get('booking.bookingMap');
      if (!coord) return;
      const bemapCoords = new bemap.Coordinate(coord.lng, coord.lat);
      const pintPointIcon = {
        icon: {
          src: '/map-icons/icon-pinpoint.svg',
          anchorX: 0.50,
          anchorY: 1,
          height: 23,
          width: 23,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          scale: 1
        }
      };
      const bemapIcon = new bemap.Icon(pintPointIcon.icon);
      const marker = new bemap.Marker(bemapCoords, {
        icon: bemapIcon
      });
      map.addMarker(marker);
      return marker;
    },

    actions: {
      async onSaveClick() {
        const tooltip = this.get('tooltip');
        const notifications = this.get('notifications');
        const saveTask = this.get('saveTask');
        const tripId = this.get('trip.tripId');
        if (!(await this.validateRecord())) return; //copy record data to trip

        tooltip.pushConfirmation({
          tip: 'Would you like to commit your changes?',
          title: 'Edit Trip',
          hasOverlay: true,
          primaryActionText: 'Commit',
          primaryAction: () => {
            return saveTask.perform().then(async () => {
              await this.checkEditedFieldsAndCreateActivityLog();
              this.get('booking').createOverlapActivityLogs([this.get('trip')]);
              this.onCloseClick();
              notifications.success(`Trip ${tripId} was edited successfully`);
              tooltip.reset();
            }).catch(async error => {
              notifications.warning(`Edit to Trip ${tripId} Failed`);
              const trip = this.get('trip');
              trip.rollbackAttributes();
              await this.resetTrip(trip);
              tooltip.reset(); // eslint-disable-next-line no-console

              console.log(error);
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => tooltip.reset()
        });
      },

      onRefreshClick() {
        const trip = this.get('trip');
        this.resetTripModel(trip);
      },

      onSearchClick() {
        const isSearchEnabled = this.get('isSearchEnabled');

        if (isSearchEnabled) {
          this.set('isSearchEnabled', false);
          return;
        }

        this.set('searchText', '');
        this.set('isSearchEnabled', true);
      },

      onSelectDropDown(record, path, value) {
        // eslint-disable-next-line no-param-reassign
        value = path === 'noSharing' ? value.id : value;
        record.set(path, value);
      },

      // overriding the parent method and validate addresses
      onCellValueChange(record, valuePath, value, options) {
        const maxXYDelta = this.get('maxXYDelta');
        const isScheduled = record.get('isScheduled');

        if (isScheduled && Ember.isPresent(options) && Ember.isPresent(value) && Ember.isPresent(value.lat) && Ember.isPresent(value.lon) && options.addressTypeOption && (options.valuePath === ZONE_NAME_PATH || options.valuePath === LOCATION_PATH)) {
          const pickPlacePosition = this.get('previousPickLocation');
          const dropPlacePosition = this.get('previousDropLocation');
          const previousLocation = options.addressTypeOption === PICK ? pickPlacePosition : dropPlacePosition;
          const distance = (0, _locationValidation.getDistanceBetweenFromLatLon)(previousLocation.lat, previousLocation.lng, value.lat, value.lon);
          this.set('isInvalidAddressSelected', false);

          if (distance > maxXYDelta) {
            this.get('notifications').warning('New location is too far away from original location');
            this.set('isInvalidAddressSelected', true);
          }
        }

        if (this.get('isInvalidAddressSelected')) {
          record.set(`${valuePath}.isForceDirty`, false);
          return;
        }

        record.set(`${valuePath}.isForceDirty`, true);
        return record.set(`${valuePath}.${options.valuePath}`, value);
      },

      onCloseClick() {
        this._super(...arguments);

        const trip = this.get('trip');
        const pickLocation = (0, _unwrapProxy.unwrapProxy)(trip.get('pick.segmentStop.place.location'));
        const dropLocation = (0, _unwrapProxy.unwrapProxy)(trip.get('drop.segmentStop.place.location'));
        const leg = (0, _unwrapProxy.unwrapProxy)(trip.get('segment.leg'));
        const segment = (0, _unwrapProxy.unwrapProxy)(trip.get('segment'));
        const rider = (0, _unwrapProxy.unwrapProxy)(trip.get('segment.leg.rider'));
        leg && leg.rollbackAttributes();
        segment && segment.rollbackAttributes();
        rider && rider.rollbackAttributes();

        if (pickLocation) {
          pickLocation.rollbackAttributes();
        }

        if (dropLocation) {
          dropLocation.rollbackAttributes();
        }

        this.onCloseClick();
      },

      onNumberChange(record, valuePath, maskObj) {
        const segmentStop = valuePath === 'pickPhoneNumber' ? (0, _unwrapProxy.unwrapProxy)(record.get('pick.segmentStop')) : (0, _unwrapProxy.unwrapProxy)(record.get('drop.segmentStop'));
        const segStopPhoneNumber = segmentStop.get('phoneNumbers.firstObject'); // there may be 0-many phoneNumbers to 1 segment stop

        const areaCode = maskObj.unmasked.substring(0, 3).trim();
        const phoneNumber = maskObj.unmasked.substring(3).trim(); // this case doesn't make sense, having phone number object but no id

        if (segStopPhoneNumber && !segStopPhoneNumber.get('id')) {
          segStopPhoneNumber.set('areaCode', areaCode);
          segStopPhoneNumber.set('phoneNumber', phoneNumber);
          segStopPhoneNumber.set('isForceDirty', true);
          record.set(valuePath, segStopPhoneNumber);
        } // existing segment stop phone number


        if (segStopPhoneNumber && segStopPhoneNumber.get('id')) {
          const segmentStopPhoneNumberId = segStopPhoneNumber.get('id');
          const segmentStopPhoneNumber = this.get('store').peekRecord('segment-stop-phone-number', segmentStopPhoneNumberId);
          segmentStopPhoneNumber.set('areaCode', areaCode);
          segmentStopPhoneNumber.set('phoneNumber', phoneNumber);
          segStopPhoneNumber.set('isForceDirty', true);
          record.set(valuePath, segmentStopPhoneNumber);
          return;
        } // if the segment stop doesn't have a phone number


        if (!segStopPhoneNumber) {
          const phoneNumberObj = {
            type: _riderPhoneNumber.HOME_PHONE_TYPE,
            areaCode: areaCode,
            phoneNumber: phoneNumber,
            segmentStop: segmentStop,
            extension: '',
            description: 'HOME CONTACT #',
            mobileCarrier: 'yes',
            useForSmsNotifications: false,
            useForVoiceNotifications: true
          };
          const segmentStopPhoneNumber = this.get('store').createRecord('segment-stop-phone-number', phoneNumberObj);
          record.set(valuePath, segmentStopPhoneNumber);
          return;
        }
      },

      onNotesChange(record, valuePath, maskObj) {
        record.set(valuePath, maskObj);
        return;
      },

      onDateChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onDistanceButtonClick() {
        const map = this.get('booking.bookingMap.native');

        this._bodyClickFunction = event => {
          this.actions.onBodyClickFunction(event, this);
        };

        document.body.addEventListener('click', this._bodyClickFunction);
        this.set('isComputingDistance', true);
        map.on('click', e => {
          this.setDistances(e.latlng);
        });
        Ember.$('.tomtom-map').addClass('pinpoint-cursor');
      },

      onBodyClickFunction(e, thisObject) {
        const node = e.target;
        let isInPage = true;

        if (document.getElementsByClassName('map-widget')[0]) {
          isInPage = document.getElementsByClassName('map-widget')[0].contains(node);
        }

        if (isInPage === false) {
          thisObject.distanceReset();
        }
      },

      onDistanceClose() {
        const map = this.get('booking.bookingMap.native');
        document.body.removeEventListener('click', this._bodyClickFunction);
        this.set('isComputingDistance', false);
        this.distanceReset();
        map.off('click');
        Ember.$('.tomtom-map').removeClass('pinpoint-cursor');
      },

      onDistanceReset() {
        this.distanceReset();
      }

    }
  });

  _exports.default = _default;
});