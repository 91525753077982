define("adept-iq/pods/components/iq-widgets/new-booking-form/booking-details/trip-alternative/columnConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    columns: [{
      valuePath: 'icon',
      width: '55px',
      sortable: false,
      cellComponent: 'table/cells/icon-cell',
      resizable: true
    }, {
      label: 'Action',
      valuePath: 'action',
      width: '100px',
      sortable: false,
      cellComponent: 'table/cells/base-cell',
      resizable: true
    }, {
      label: 'From',
      valuePath: 'from',
      width: '150px',
      cellComponent: 'table/cells/base-cell',
      resizable: true
    }, {
      label: 'Start Time',
      valuePath: 'startTime',
      width: '100px',
      cellComponent: 'table/cells/base-cell',
      resizable: true
    }, {
      label: 'To',
      valuePath: 'to',
      width: '100px',
      cellComponent: 'table/cells/base-cell',
      resizable: true
    }, {
      label: 'Arrive Time',
      valuePath: 'arriveTime',
      width: '100px',
      cellComponent: 'table/cells/base-cell',
      resizable: true
    }, {
      label: 'Promise Time',
      valuePath: 'promiseTime',
      width: '100px',
      cellComponent: 'table/cells/base-cell',
      resizable: true
    }]
  };
  _exports.default = _default;
});