define("adept-iq/pods/components/side-drawer/filter-settings/component", ["exports", "adept-iq/config/filter-types", "adept-iq/utils/filters", "moment", "lodash"], function (_exports, _filterTypes, _filters, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SPACER_COLUMN_ID = 'spacer';

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    booking: Ember.inject.service(),
    tagName: '',
    title: 'Filter Settings',
    componentName: 'filterSidePanel',
    allVisbility: true,
    filterTypes: _filterTypes.filterTypes,
    quickFilterName: null,
    allowDisplayHash: null,
    columns: Ember.computed.readOnly('stackFrame.options.columns'),
    widget: Ember.computed.readOnly('stackFrame.options.widget'),
    dataColumns: Ember.computed('columns.[]', function () {
      const columns = this.get('columns');
      const slicedColumns = columns.slice(1);
      (0, _lodash.remove)(slicedColumns, col => {
        const allowDisplayAttr = Ember.isPresent(col.config) ? col.config.allowDisplayAttr : null;
        let allowColumn = this.get('allowDisplayHash')[allowDisplayAttr]; // disable a specific column depending if allowDisplayAttr exist in config

        allowColumn = Ember.isPresent(allowColumn) ? allowColumn : true;
        return !allowColumn || col.id === SPACER_COLUMN_ID;
      });
      return slicedColumns;
    }),
    isClearAllDisabled: Ember.computed('dataColumns.@each.{filterTypeId,filterValues}', 'quickFilterName', function () {
      const quickFilterName = this.get('quickFilterName');
      const dataColumns = this.get('dataColumns');
      const length = dataColumns.length;
      return Ember.isEmpty(quickFilterName) && dataColumns.filter(column => (Ember.isEmpty(column.filterTypeId) || column.filterTypeId === 'null') && Ember.isEmpty(column.filterValues)).length === length;
    }),
    isQuickFiltersAllowed: Ember.computed('workspace.{isBookingDashboard,isReconcileDashboard,isReportsDashboard}', function () {
      return !this.get('workspace.isBookingDashboard') && !this.get('workspace.isReconcileDashboard') && !this.get('workspace.isReportsDashboard');
    }),

    reOrderFilterSettings(columns) {
      this.get('widget').mergeState({
        columns
      });
      const tableColumns = this.get('columns') || []; // checkbox and spacer are always in the same location

      tableColumns[0].sortOrder = 0;
      tableColumns[tableColumns.length - 1].sortOrder = tableColumns.length - 1;
      tableColumns.forEach(column => {
        if (columns[column.id]) {
          column.sortOrder = columns[column.id].index + 1;
        }
      });
      tableColumns.sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      });
      this.notifyPropertyChange('columns');
    },

    // populate allowDisplayHash for columns in column widget attribute called allowDisplayAttr
    configureAllowDisplayHash() {
      this.set('allowDisplayHash', {
        allowFundingAgency: this.get('booking').getAllowFundingAgency()
      });
    },

    init() {
      this._super(...arguments);

      this.configureAllowDisplayHash();
    },

    saveFilters(widgetState, widgetStates) {
      const widgetId = this.get('widget.id');
      const widgetTypeId = this.get('widget.typeId');
      const widgetInstanceId = `${widgetTypeId}_${widgetId}`;
      const columnList = this.get('dataColumns') || [];
      const quickFilters = widgetState.quickFilters || [];
      const existingFilterNames = quickFilters.map(obj => obj.displayName);
      const filterName = this.get('quickFilterName') || (0, _filters.generateFilterName)(existingFilterNames);
      const args = columnList.map(column => {
        return column.get('filterNode');
      }).compact();
      const newFilterId = (0, _moment.default)().unix();
      const newFilter = {
        id: newFilterId,
        displayName: filterName,
        filters: args,
        widgetInstanceId
      }; // insert new filter to local

      quickFilters.push(newFilter);
      this.set('workspace.newlyCreatedQuickFilter', newFilter);
      this.set('workspace.widgetStates', widgetStates);
      this.set('quickFilterName', ''); // insert new filter to dashboard model

      const dashboardwidgetStates = { ...this.get('workspace.dashboardInstance.widgetStates')
      };
      let dashboardWidgetState = dashboardwidgetStates[widgetTypeId] || {};
      let dashBoardQuickFilters = dashboardWidgetState.quickFilters || [];

      if (Ember.isNone(dashboardWidgetState.quickFilters)) {
        dashboardwidgetStates[widgetTypeId] = {
          widgetInstanceId,
          quickFilters: []
        };
        dashboardWidgetState = dashboardwidgetStates[widgetTypeId];
        dashBoardQuickFilters = dashboardWidgetState.quickFilters;
      }

      this.set('workspace.dashboardInstance.widgetStates', dashboardwidgetStates);
      const newDashboardFilter = {
        id: newFilterId,
        displayName: filterName,
        filters: args,
        widgetInstanceId
      };
      dashBoardQuickFilters.push(newDashboardFilter);
    },

    actions: {
      onQuickFilterNameInput(text) {
        const quickFilterName = text || '';
        this.set('quickFilterName', quickFilterName.trim());
      },

      onCloseClick() {
        const workspace = this.get('workspace'); // save any filter changes i.e columns reorders, visibility, and filter changes

        workspace.saveTempDashboardState();
        this.onCloseClick();
      },

      onReorderItems(tableColumns) {
        const columns = tableColumns.reduce((obj, column, index) => {
          const id = column.id;
          obj[id] = {
            index
          };
          return obj;
        }, {});
        this.reOrderFilterSettings(columns);
      },

      onClickVisibilityCheckbox(column, event) {
        const id = column.get('id');

        if (id !== 'reportName') {
          const columns = {};
          columns[id] = {
            hidden: !event.target.checked
          };

          if (columns[id].hidden) {
            columns[id].filterTypeId = 'null'; // this is actually the id of the null filter

            columns[id].filterValues = null;
          }

          this.get('widget').mergeState({
            columns
          });
        }
      },

      onToggleAllVisibility(tableColumns) {
        const allVisbility = !this.get('allVisbility');
        this.set('allVisbility', allVisbility);
        const columns = tableColumns.reduce((obj, column) => {
          const id = column.id;
          obj[id] = {
            hidden: id === 'reportName' ? false : allVisbility
          };

          if (allVisbility) {
            obj[id].filterTypeId = 'null'; // this is actually the id of the null filter

            obj[id].filterValues = null;
          }

          return obj;
        }, {});
        Ember.run(() => this.get('widget').mergeState({
          columns
        }));
      },

      onSelectFilterType(column, filterType) {
        const id = column.get('id');
        const columns = {};
        columns[id] = {
          filterTypeId: filterType.id
        };
        this.get('widget').mergeState({
          columns
        }); // @TODO: focus on filter value input
      },

      onFilterValueChange(column, index, event) {
        let filterValues = column.get('filterValues') || [];
        filterValues = filterValues.slice();
        filterValues[index] = event.target.value;
        const id = column.get('id');
        const columns = {};
        columns[id] = {
          filterValues
        };
        this.get('widget').mergeState({
          columns
        });
      },

      clearFilters() {
        const columnList = this.get('columns') || [];
        const columns = columnList.reduce((obj, column) => {
          obj[column.id] = {
            filterTypeId: 'null',
            // this is actually the id of the null filter
            filterValues: null
          };
          return obj;
        }, {});
        this.get('widget').mergeState({
          columns
        });
        this.set('quickFilterName', '');
      },

      onSaveFilters() {
        const widgetTypeId = this.get('widget.typeId');
        const widgetStates = { ...this.get('workspace.widgetStates')
        };
        let widgetState = widgetStates[widgetTypeId];

        if (Ember.isNone(widgetState)) {
          widgetStates[widgetTypeId] = {
            quickFilters: []
          };
          widgetState = widgetStates[widgetTypeId];
        }

        const quickFilters = widgetState.quickFilters || [];
        const tooltip = this.get('tooltip');
        const title = 'Manage Filter'; // a new one would put the number above 21

        if (quickFilters.length > 19) {
          tooltip.pushConfirmation({
            tip: 'You have reached the limit of 20 filters. To add new filters, you must delete existing ones.',
            title,
            primaryActionText: 'OK',
            primaryAction: () => {
              tooltip.reset();
            }
          });
          return;
        }

        this.saveFilters(widgetState, widgetStates);
      }

    }
  });

  _exports.default = _default;
});