define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/otp-cell-item", ["exports", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-item"], function (_exports, _cellItem) {
  'use strict';

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line no-undef
  const _ = require("lodash");

  const OTP_LATE = 'Late';
  const OTP_DANGER = 'In Danger';

  class OTPCellItem extends _cellItem.default {
    constructor(props) {
      super(props);
    }

    getCellClasses(data, columnInfo, rowIndex, columnIndex, columnValue) {
      const cellClasses = [];
      const otpLateColor = this.isOtpLate(columnInfo, columnValue) ? 'otp-late-color' : '';

      if (!_.isEmpty(otpLateColor)) {
        cellClasses.push(otpLateColor);
      }

      const otpDangerColor = this.isOtpDanger(columnInfo, columnValue) ? 'otp-danger-color' : '';

      if (!_.isEmpty(otpDangerColor)) {
        cellClasses.push(otpDangerColor);
      }

      return cellClasses.join(' ');
    }

    isOtpLate(_ref, columnValue) {
      let {
        valuePath
      } = _ref;
      return valuePath === _cellItem.OTP_LABEL_PROPERTY_NAME && columnValue === OTP_LATE;
    }

    isOtpDanger(_ref2, columnValue) {
      let {
        valuePath
      } = _ref2;
      return valuePath === _cellItem.OTP_LABEL_PROPERTY_NAME && columnValue === OTP_DANGER;
    }

  }

  _exports.default = OTPCellItem;
});