define("adept-iq/pods/components/form-components/datetime/component", ["exports", "moment", "adept-iq/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DATETIME_FORMAT = /^\d\d\\d\d\\d\d\\d\d\d\d \d\d:\d\d$/;

  var _default = Ember.Component.extend({
    classNames: ['form-components-datetime'],
    value: null,
    disabled: false,
    dateTimeFormat: _environment.default.dateTimeFormat,
    actions: {
      onInput(value) {
        if (!DATETIME_FORMAT.test(value)) return;
        this.get('onChange')((0, _moment.default)(value, _environment.default.dateTimeFormat.dateTimeMoment).toDate());
      }

    }
  });

  _exports.default = _default;
});