define("adept-iq/adapters/route-template-availability", ["exports", "adept-iq/adapters/-ss-schedulingService", "adept-iq/config/api-urls"], function (_exports, _ssSchedulingService, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    iquxServiceHost: _apiUrls.API.iquxService.host,

    getPath(url, defaults) {
      // eslint-disable-next-line no-useless-escape
      var reUrlPath = /(?:\w+:)?\/\/[^\/]+([^?#]+)/;
      var urlParts = url.match(reUrlPath) || [url, defaults];
      return urlParts.pop();
    },

    findBelongsTo(store, snapshot, url, relationship) {
      const path = this.getPath(url);
      const newUrl = this.iquxServiceHost + path;
      return this._super(store, snapshot, newUrl, relationship);
    }

  });

  _exports.default = _default;
});