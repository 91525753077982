define("adept-iq/pods/components/iq-widgets/donut-chart-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/chartjs-widget/component", "adept-iq/utils/filters", "adept-iq/pods/components/generic-widgets/column-widget/classes/filter-column"], function (_exports, _component, _filters, _filterColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Chart */
  var _default = _component.default.extend({
    workspaceContext: Ember.inject.service(),
    classNames: ['donut-chart-widget', 'data-test-donut-chart-widget'],
    widget: null,
    options: Ember.computed.alias('widget.state.dataType'),

    /* Applies the choice of data source. */
    chartRecords: Ember.computed('options.modelName', 'workspaceContext.workspaceData.[]', function () {
      const modelName = this.get('options.modelName');
      if (Ember.isBlank(modelName)) return [];
      return Ember.makeArray(this.get('workspaceContext.workspaceData')).filterBy('modelName', modelName).map(a => a.record);
    }),

    /* Builds the chosen filters. */
    filterFunction: Ember.computed('options.columns', function () {
      const filterColumns = Object.values(this.get('options.columns')).map(col => new _filterColumn.default(col));
      const args = filterColumns.map(column => {
        return column.get('filterNode');
      });
      return (0, _filters.buildFilterFunction)((0, _filters.buildCompoundFilterNode)('and', args));
    }),

    /* Applies the choice of filters. */
    filteredRecords: Ember.computed('chartRecords', 'filterFunction', function () {
      const chartRecords = this.get('chartRecords') || [];
      return chartRecords.filter(this.get('filterFunction'));
    }),

    /* Gets the choice for slicing the data. */
    slicedBy: Ember.computed('options.displayOptions.[]', function () {
      if (Ember.isBlank(this.get('options.displayOptions'))) return;
      /*eslint-disable */

      return this.get('options.displayOptions').findBy('id', 'slicedBy').selected;
      /*eslint-enable */
    }),
    chartSum: Ember.computed.alias('filteredRecords.length'),

    /* Applies the choice for slicing the data. */
    recordSlices: Ember.computed('filteredRecords', 'slicedBy', function () {
      const categories = {};
      const filteredRecords = this.get('filteredRecords');
      const slicedBy = this.get('slicedBy');
      const valueCategories = this.get('slicedBy.valueCategories') || [];
      const filterKey = this.get('slicedBy.valueKey');
      if (Ember.isBlank(slicedBy)) return categories;
      valueCategories.forEach(category => {
        categories[category.label] = filteredRecords.filterBy(filterKey, category.value);
      }); // { 'label1': [], 'label2': [] }

      return categories;
    }),

    /* Applies the choices for displaying data counts and/or percentages. */
    aggregatedSliceData: Ember.computed('recordSlices', 'options.visualOptions.@each.isChecked', function () {
      const visualOptions = this.get('options.visualOptions');
      const showValues = visualOptions.findBy('id', 'value').isChecked;
      const showPercentage = visualOptions.findBy('id', 'percentage').isChecked;
      const totalCount = this.get('chartSum');
      const data = [];
      Object.entries(this.get('recordSlices')).forEach(slice => {
        let label = slice[0];
        const numRecords = slice[1].length;

        if (showValues) {
          label += ', ' + numRecords;
        }

        if (showPercentage && totalCount !== 0) {
          label += ', ' + Math.floor(100 * numRecords / totalCount) + '%';
        }

        data.push({
          label: label,
          value: numRecords
        });
      }); // [{...}, {...}, ...]

      return data;
    }),

    /* Applies the choice for sorting the data. */
    sortedData: Ember.computed('aggregatedSliceData', 'options.sortOrder.selected.sortAsc', function () {
      if (Ember.isBlank(this.get('aggregatedSliceData'))) return [];
      const data = this.get('aggregatedSliceData');
      const sortAsc = this.get('options.sortOrder.selected.sortAsc');

      if (sortAsc) {
        return data.sortBy('value');
      } // [{...}, {...}, ...]


      return data.sortBy('value').reverse();
    }),
    chartData: Ember.computed('sortedData', function () {
      const sortedData = this.get('sortedData');
      if (Ember.isBlank(sortedData)) return {
        data: [],
        labels: []
      };
      const data = [];
      const labels = [];
      const backgroundColor = [];
      const colors = this.get('colors');
      sortedData.forEach((dataSlice, i) => {
        data.push(dataSlice.value);
        labels.push(dataSlice.label);
        backgroundColor.push(colors[i % colors.length]);
      });
      const datasets = [{
        data,
        backgroundColor
      }];
      return {
        datasets,
        labels
      };
    }),

    /* Applies the chart title input. */
    chartTitle: Ember.computed('widget.state.dataType.{name,title}', function () {
      const title = this.get('widget.state.dataType.title');
      if (Ember.isPresent(title)) return title;
      const name = this.get('widget.state.dataType.name');

      if (Ember.isPresent(name)) {
        return `${name} Donut Chart`;
      }

      return 'Donut Chart';
    }),

    /* Applies the choices for display of legend and record count total. */
    chartOptions: Ember.computed('options.{legendPlacement,visualOptions.@each.isChecked}', function () {
      if (Ember.isBlank(this.get('widget.state'))) return {};
      const options = {
        legend: {
          position: this.get('options.legendPlacement.selected.position')
        },
        elements: {
          center: {
            text: this.get('chartSum'),
            fontStyle: 'AvenirNext',
            //Default Arial
            sidePadding: 15 //Default 20 (as a percentage)

          }
        },
        tooltips: {
          enabled: false
        }
      };
      const showTotal = this.get('options.visualOptions').findBy('id', 'total').isChecked;

      if (!showTotal) {
        delete options.elements.center;
      }

      return options;
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      Chart.pluginService.register({
        beforeDraw: function (chart) {
          if (chart.config.options.elements.center) {
            //Get ctx from string
            const ctx = chart.chart.ctx; //Get options from the center object in options

            const centerConfig = chart.config.options.elements.center;
            const fontStyle = centerConfig.fontStyle || 'Arial';
            const txt = centerConfig.text;
            const color = centerConfig.color || '#000';
            const sidePadding = centerConfig.sidePadding || 20;
            const sidePaddingCalculated = sidePadding / 100 * (chart.innerRadius * 2); //Start with a base font of 30px

            ctx.font = '45px ' + fontStyle; //Get the width of the string and also the width of the element minus 10 to give it 5px side padding

            const stringWidth = ctx.measureText(txt).width;
            const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated; // Find out how much the font can grow in width.

            const widthRatio = elementWidth / stringWidth;
            const newFontSize = Math.floor(30 * widthRatio);
            const elementHeight = chart.innerRadius * 2; // Pick a new font size so it will not be larger than the height of label.

            const fontSizeToUse = Math.min(newFontSize, elementHeight); //Set font settings to draw it correctly.

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.font = fontSizeToUse + 'px ' + fontStyle;
            ctx.fillStyle = color; //Draw text in center

            ctx.fillText(txt, centerX, centerY);
          }
        }
      });
    },

    actions: {
      onFilterButtonClick() {
        const workspace = this.get('workspace');
        let topState = workspace.get('topState');

        while (topState === 'donutChartWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        const displayName = 'Donut Chart';
        const widget = this.get('widget');
        workspace.pushState('donutChartWidget', {
          displayName,
          widget
        });
      }

    }
  });

  _exports.default = _default;
});