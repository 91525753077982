define("adept-iq/utils/name-parser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parse = parse;

  /**
   * File created by bbarnum on 2/26/16.
   *
   * based on PHP Name Parser by Josh Fraser (joshfraser.com)
   * http://www.onlineaspect.com/2009/08/17/splitting-names/
   * ported to JavaScript by Mark Pemburn (pemburnia.com)
   * https://github.com/joshfraser/JavaScript-Name-Parser/blob/master/parse-names.js
   * released under Apache 2.0 license
   *
   */

  /* eslint-disable no-param-reassign */
  function removeIgnoredChars(word) {
    // ignore periods
    return word ? word.replace('.', '') : '';
  }

  function isSalutation(word) {
    word = removeIgnoredChars(word).toLowerCase(); // returns normalized values

    if (word === 'mr' || word === 'master' || word === 'mister') {
      return 'Mr.';
    } else if (word === 'mrs') {
      return 'Mrs.';
    } else if (word === 'miss' || word === 'ms') {
      return 'Ms.';
    } else if (word === 'dr') {
      return 'Dr.';
    } else if (word === 'rev') {
      return 'Rev.';
    } else if (word === 'fr') {
      return 'Fr.';
    }

    return false;
  } //  detect and format common suffixes


  function isSuffix(word) {
    word = removeIgnoredChars(word).toLowerCase(); // these are some common suffixes - what am I missing?

    const suffixArray = ['I', 'II', 'III', 'IV', 'V', 'Senior', 'Junior', 'Jr', 'Sr', 'PhD', 'APR', 'RPh', 'PE', 'MD', 'MA', 'DMD', 'CME', 'BVM', 'CFRE', 'CLU', 'CPA', 'CSC', 'CSJ', 'DC', 'DD', 'DDS', 'DO', 'DVM', 'EdD', 'Esq', 'JD', 'LLD', 'OD', 'OSB', 'PC', 'Ret', 'RGS', 'RN', 'RNC', 'SHCJ', 'SJ', 'SNJM', 'SSMO', 'USA', 'USAF', 'USAFR', 'USAR', 'USCG', 'USMC', 'USMCR', 'USN', 'USNR'];
    const suffixIndex = suffixArray.map(function (suffix) {
      return suffix.toLowerCase();
    }).indexOf(word);

    if (suffixIndex >= 0) {
      return suffixArray[suffixIndex];
    }

    return false;
  } // detect compound last names like "Von Fange"


  function isCompoundLastName(word) {
    word = word.toLowerCase(); // these are some common prefixes that identify a compound last names - what am I missing?

    const words = ['vere', 'von', 'van', 'de', 'del', 'della', 'di', 'da', 'pietro', 'vanden', 'du', 'st.', 'st', 'la', 'lo', 'ter'];
    return words.indexOf(word) >= 0;
  } // single letter, possibly followed by a period


  function isInitial(word) {
    word = removeIgnoredChars(word);
    return word.length === 1;
  } // detect mixed case words like "McDonald"
  // returns false if the string is all one case


  function isCamelCase(word) {
    var ucReg = /[A-Z]+/;
    var lcReg = /[a-z]+/;
    return ucReg.exec(word) && lcReg.exec(word);
  } // helper for fixCase
  // uppercase words split by the separator (ex. dashes or periods)


  function safeUpperCaseFirst(separator, word) {
    return word.split(separator).map(function (thisWord) {
      if (isCamelCase(thisWord)) {
        return thisWord;
      }

      return thisWord.substr(0, 1).toUpperCase() + thisWord.substr(1).toLowerCase();
    }, this).join(separator);
  } // ucfirst words split by dashes or periods
  // ucfirst all upper/lower strings, but leave camelcase words alone


  function fixCase(word) {
    // uppercase words split by dashes, like "Kimura-Fay"
    word = safeUpperCaseFirst('-', word); // uppercase words split by periods, like "J.P."

    word = safeUpperCaseFirst('.', word);
    return word;
  }
  /* eslint-disable complexity */


  function parse(fullLastName) {
    if (fullLastName === null) {
      return {
        salutation: null,
        firstName: null,
        middleName: null,
        lastName: null,
        suffix: null
      };
    }

    fullLastName = fullLastName.trim();
    let lastName = '';
    let firstName = '';
    let middleName = '';
    let j = 0;
    let i = 0; // split into words
    // completely ignore any words in parentheses

    const nameParts = fullLastName.split(' ').filter(function (namePart) {
      return namePart.indexOf('(') === -1;
    });
    const numWords = nameParts.length; // is the first word a title? (Mr. Mrs, etc)

    const salutation = isSalutation(nameParts[0]);
    const suffix = isSuffix(nameParts[numWords - 1]); // set the range for the middle part of the name (trim prefixes & suffixes)

    const start = salutation ? 1 : 0;
    const end = suffix ? numWords - 1 : numWords;
    let word = nameParts[start]; // if we start off with an initial, we'll call it the first name

    if (isInitial(word)) {
      // if so, do a look-ahead to see if they go by their middle name
      // for ex: "R. Jason Smith" => "Jason Smith" & "R." is stored as an initial
      // but "R. J. Smith" => "R. Smith" and "J." is stored as an initial
      if (isInitial(nameParts[start + 1])) {
        firstName += ' ' + word.toUpperCase();
      } else {
        middleName += ' ' + word.toUpperCase();
      }
    } else {
      firstName += ' ' + fixCase(word);
    } // concat the first name


    for (i = start + 1; i < end - 1; i++) {
      word = nameParts[i]; // move on to parsing the last name if we find an indicator of a compound
      // last name (Von, Van, etc)
      // we do not check earlier to allow for rare cases where an indicator is actually
      // the first name (like "Von Fabella")

      if (isCompoundLastName(word)) {
        break;
      }

      if (isInitial(word)) {
        middleName += ' ' + word.toUpperCase();
      } else {
        firstName += ' ' + fixCase(word);
      }
    } // check that we have more than 1 word in our string


    if (end - start > 1) {
      // concat the last name
      for (j = i; j < end; j++) {
        lastName += ' ' + fixCase(nameParts[j]);
      }
    } // return the various parts in an array


    return {
      salutation: salutation || '',
      firstName: firstName.trim(),
      middleName: middleName.trim(),
      lastName: lastName.trim(),
      suffix: suffix || ''
    };
  }
});