define("adept-iq/pods/components/side-drawer/system-config/ada-boundaries/ada-boundary-map/component", ["exports", "adept-iq/pods/components/generic-widgets/map-widget/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RecenterControl = void 0;
  const config = {
    title: 'Map'
  };
  const recenterControlOptions = {
    position: 'topleft',
    recenterAction: null
  };
  var RecenterControl = window.L.Control.extend({
    options: recenterControlOptions,
    onAdd: function
      /* map */
    () {
      const name = 'leaflet-control-recenter';
      const container = window.L.DomUtil.create('div', 'leaflet-control-recenter leaflet-bar');
      this._recenterButton = this._createButton('<i class="fa fa-crosshairs fa-lg crosshairs-big" aria-hidden="true"></i>', 'Recenter', name, container, this._recenterMap);
      return container;
    },
    _recenterMap: function () {
      this.options.mapWidget.onRecenterClick();
    },
    _createButton: function (html, title, className, container, fn) {
      var link = window.L.DomUtil.create('a', className, container);
      link.innerHTML = html;
      link.href = '#';
      link.title = title;
      /*
           * Will force screen readers like VoiceOver to read this as "Zoom in - button"
           */

      link.setAttribute('role', 'button');
      link.setAttribute('aria-label', title);
      window.L.DomEvent.disableClickPropagation(link);
      window.L.DomEvent.on(link, 'click', window.L.DomEvent.stop);
      window.L.DomEvent.on(link, 'click', fn, this);
      window.L.DomEvent.on(link, 'click', this._refocusOnMap, this);
      return link;
    }
  });
  _exports.RecenterControl = RecenterControl;

  var _default = _component.default.extend(Ember.Evented, {
    mapZoomMethodHandler: null,
    activeContext: Ember.inject.service(),
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    address: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    work: Ember.inject.service(),
    adaService: Ember.inject.service(),
    config,
    componentName: null,
    lat: Ember.computed.alias('mapContext.lat'),
    lng: Ember.computed.alias('mapContext.lng'),
    isVisible: Ember.computed.alias('mapContext.isVisible'),
    agencyMarkers: Ember.computed.alias('mapContext.agencyMarkers'),
    _mapContext: null,
    layerGroup: null,
    // all map widgets are bound to the top context in stack
    mapContext: Ember.computed.readOnly('mapService.topMapContext'),
    layers: Ember.computed.readOnly('mapContext.layers'),
    bounds: Ember.computed.readOnly('mapContext.bounds'),
    boundsID: Ember.computed.readOnly('mapContext.boundsID'),
    polylines: Ember.computed.readOnly('mapContext.polylines'),
    shapes: Ember.computed.alias('mapContext.shapes'),
    centerLat: Ember.computed.oneWay('mapContext.centerLat'),
    centerLng: Ember.computed.oneWay('mapContext.centerLng'),
    isSearchAllowed: Ember.computed.readOnly('mapContext.isSearchAllowed'),
    isSearchEnabled: Ember.computed.readOnly('mapContext.isSearchEnabled'),
    searchText: Ember.computed.readOnly('mapContext.searchText'),
    clearButtonEnabled: Ember.computed.gt('activeContext.checkedItems.length', 0),
    //map observer. this observers the calendarId in adaService and renders map
    activeCalendarObserver: Ember.observer('adaService.activeCalendarId', function () {
      const activeCalendarId = this.get('adaService.activeCalendarId');

      if (activeCalendarId) {
        this.get('adaService').fetchRouteShapeByCalendarId(activeCalendarId).then(geoJsonData => {
          this.get('renderAdaBoundary')(geoJsonData);
        });
      }
    }),

    init() {
      this._super(...arguments);

      this.renderAdaBoundary = this.get('renderAdaBoundary').bind(this);
      const typeId = this.get('widget.typeId');
      this.set('componentName', `${typeId}Widget`); // callback wrappers

      this._onMapContextRefresh = () => this.onMapContextRefresh();

      this._mapZoomMethodHandler = e => this.mapZoomMethod(e); // initialize observer


      this.mapContextChanged();
      this.activeCalendarObserver();
      this.setProperties({
        layerGroup: new window.L.LayerGroup()
      });
    },

    destroy() {
      this._onMapContextRefresh = null;
      this._mapZoomMethodHandler = null;

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments); // set up location control; map was initialized in `_super`


      const map = this.get('map');
      const locationControl = new RecenterControl({
        mapWidget: this
      });
      locationControl.addTo(map.native);
      this.set('locationControl', locationControl);
      map.native.on('zoom', this._mapZoomMethodHandler);
      window.L.control.scale({
        position: 'bottomright'
      }).addTo(map.native);
      window.L.DomEvent.on(map.native, 'wheel', this._didWheelZoom); // build data joins
      // schedule initial map render

      Ember.run.scheduleOnce('afterRender', this, 'updateMap');
      Ember.run.scheduleOnce('afterRender', this, 'reCenterMapView');
      Ember.run.scheduleOnce('afterRender', this, 'updateMapView');
      this.$('a').removeAttr('title');
      window.L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map.native);
      this.get('map.native').setView([-122.422996877403506, 47.255597113139743], 3);
    },

    mapContextChanged: Ember.observer('mapContext', function () {
      const oldMapContext = this.get('_mapContext');
      const newMapContext = this.get('mapContext'); // need to swap out refresh listener!

      if (oldMapContext) {
        oldMapContext.off('refresh', this._onMapContextRefresh);
      }

      if (newMapContext) {
        newMapContext.on('refresh', this._onMapContextRefresh);
      }

      this.set('_mapContext', newMapContext); // force a refresh immediately

      this.onMapContextRefresh();
    }),

    onMapContextRefresh() {
      Ember.run.scheduleOnce('afterRender', this, 'updateMap');
    },

    mapZoomMethod(event) {
      const map = event.target;
      if (!map) return;
      const zoom = map.getZoom();
      const geocodeService = this.get('geocode');
      geocodeService.set('zoom', zoom);
    },

    updateMap() {
      // don't try to update until map is initialized
      if (!this.get('map')) return;
      const map = this.get('map');
      map.on('zoom', this._mapZoomMethodHandler);
    },

    onRecenterClick() {
      this.set('autoZoom', false);
      return this.reCenterMapView(true);
    },

    renderAdaBoundary(geoJsonData) {
      const map = this.get('map');
      this.get('layerGroup').clearLayers();
      const geoJson = window.L.geoJSON(geoJsonData);
      this.get('layerGroup').addLayer(geoJson).addTo(map.native);
      map.native.fitBounds(geoJson.getBounds());
    },

    actions: {}
  });

  _exports.default = _default;
});