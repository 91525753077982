define("adept-iq/tests/factories/etanav-stop-point", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const stops = [// stops-pick-P207494508 lat: 40.65954 lng: -73.93221
  {
    isDelete: false,
    type: 'pick',
    eta: (0, _moment.default)().startOf('day').add(8, 'hours').toDate(),
    actualArrival: null,
    actualDepart: null,
    note: null,
    odo: null,
    polyline: null,
    ordinal: 14,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.65954,
    lng: -73.93221
  }, // stops-drop-D207494508 lat: 40.64108 lng: -73.9456
  {
    isDelete: false,
    type: 'drop',
    eta: (0, _moment.default)().startOf('day').add(9, 'hours').toDate(),
    actualArrival: null,
    actualDepart: null,
    note: null,
    odo: null,
    polyline: null,
    ordinal: 15,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.64108,
    lng: -73.9456
  }, // stops-drop-D207493009 lat: 40.66288 lng: -73.9325
  {
    isDelete: false,
    type: 'drop',
    eta: (0, _moment.default)().startOf('day').add(9, 'hours').toDate(),
    actualArrival: null,
    actualDepart: null,
    note: null,
    odo: null,
    polyline: null,
    ordinal: 13,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.66288,
    lng: -73.9325
  }, // stops-pick-P207493009 lat: 40.74082 lng: -73.9838
  {
    isDelete: false,
    type: 'pick',
    eta: (0, _moment.default)().startOf('day').add(10, 'hours').toDate(),
    actualArrival: null,
    actualDepart: null,
    note: null,
    odo: null,
    polyline: null,
    ordinal: 10,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.74082,
    lng: -73.9838
  }, // stops-drop-D207494359 lat: 40.68936 lng: -73.97942
  {
    isDelete: false,
    type: 'drop',
    eta: (0, _moment.default)().startOf('day').add(11, 'hours').toDate(),
    actualArrival: '2019-07-08T21:12:26.265Z',
    actualDepart: '2019-07-08T21:12:27.962Z',
    note: null,
    odo: '593847',
    polyline: null,
    ordinal: 4,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.68936,
    lng: -73.97942
  }, // stops-pick-P207494359 lat: 40.68448 lng: -73.94628
  {
    isDelete: false,
    type: 'pick',
    eta: (0, _moment.default)().startOf('day').add(12, 'hours').toDate(),
    actualArrival: '2019-07-08T21:07:52.537Z',
    actualDepart: '2019-07-08T21:07:56.449Z',
    note: null,
    odo: '593847',
    polyline: null,
    ordinal: 2,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.68448,
    lng: -73.94628
  }, // stops-pick-P207496170 lat: 40.61552 lng: -73.96913
  {
    isDelete: false,
    type: 'pick',
    eta: (0, _moment.default)().startOf('day').add(13, 'hours').toDate(),
    actualArrival: null,
    actualDepart: null,
    note: null,
    odo: null,
    polyline: null,
    ordinal: 11,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.61552,
    lng: -73.96913
  }, // stops-drop-D207496170 lat: 40.61545 lng: -73.96126
  {
    isDelete: false,
    type: 'drop',
    eta: (0, _moment.default)().startOf('day').add(14, 'hours').toDate(),
    actualArrival: null,
    actualDepart: null,
    note: null,
    odo: null,
    polyline: null,
    ordinal: 12,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.61545,
    lng: -73.96126
  }, // stops-pick-P207496912 lat: 40.68431 lng: -73.79013
  {
    isDelete: false,
    type: 'pick',
    eta: (0, _moment.default)().startOf('day').add(15, 'hours').toDate(),
    actualArrival: null,
    actualDepart: null,
    note: null,
    odo: null,
    polyline: null,
    ordinal: 5,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.68431,
    lng: -73.79013
  }, // stops-drop-D207496912 lat: 40.70408 lng: -74.01318
  {
    isDelete: false,
    type: 'drop',
    eta: (0, _moment.default)().startOf('day').add(16, 'hours').toDate(),
    actualArrival: null,
    actualDepart: null,
    note: null,
    odo: null,
    polyline: null,
    ordinal: 7,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.70408,
    lng: -74.01318
  }, // stops-pick-P207493284 lat: 40.689 lng: -73.79482
  {
    isDelete: false,
    type: 'pick',
    eta: (0, _moment.default)().startOf('day').add(17, 'hours').toDate(),
    actualArrival: null,
    actualDepart: null,
    note: null,
    odo: null,
    polyline: null,
    ordinal: 6,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.689,
    lng: -73.79482
  }, // stops-drop-D207493284 lat: 40.70988 lng: -74.01277
  {
    isDelete: false,
    type: 'drop',
    eta: (0, _moment.default)().startOf('day').add(12, 'hours').toDate(),
    actualArrival: null,
    actualDepart: null,
    note: null,
    odo: null,
    polyline: null,
    ordinal: 8,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.70988,
    lng: -74.01277
  }, // stops-drop-D207495587 lat: 40.68936 lng: -73.97942
  {
    isDelete: false,
    type: 'drop',
    eta: (0, _moment.default)().startOf('day').add(9, 'hours').toDate(),
    actualArrival: '2019-07-08T21:12:20.628Z',
    actualDepart: '2019-07-08T21:12:22.542Z',
    note: null,
    odo: '593847',
    polyline: null,
    ordinal: 3,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.68936,
    lng: -73.97942
  }, // stops-pick-P207495587 lat: 40.63737 lng: -73.94329
  {
    isDelete: false,
    type: 'pick',
    eta: (0, _moment.default)().startOf('day').add(11, 'hours').toDate(),
    actualArrival: '2019-07-08T20:53:00.623Z',
    actualDepart: '2019-07-08T20:55:48.884Z',
    note: null,
    odo: '593847',
    polyline: null,
    ordinal: 1,
    providerId: 'EPC',
    plannedTime: (0, _moment.default)().startOf('day').add(7, 'hours').toDate(),
    stopStatus: 'Active',
    lat: 40.63737,
    lng: -73.94329
  }];

  _emberDataFactoryGuy.default.define('etanav-stop-point', {
    polymorphic: false,
    // manually flag this model as NOT polymorphic
    sequences: {
      etas: num => stops[num % stops.length].eta,
      ordinals: num => stops[num % stops.length].ordinal,
      plannedTimes: num => stops[num % stops.length].plannedTime,
      types: num => stops[num % stops.length].type
    },
    default: {
      eta: _emberDataFactoryGuy.default.generate('etas'),
      notBefore: _emberDataFactoryGuy.default.generate('plannedTimes'),
      status: 'performed',
      travelTimeToNext: '465',
      type: 'stop'
    },
    traits: {
      break: {
        type: 'break'
      }
    }
  });
});