define("adept-iq/adapters/activity-log", ["exports", "adept-iq/adapters/-avlm-avlmService", "moment"], function (_exports, _avlmAvlmService, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    getActivityLogs(query) {
      const session = this.get('session');
      var url = this.buildURL('event', null, null, 'query', query);
      return this.get('ajax').post(url, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: query
      });
    },

    createEventActivity(data) {
      const session = this.get('session');
      const json = {
        data: data
      };
      const url = this.buildURL('event');
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    createRouteActivity(userInfo, routeInfo) {
      let loggingType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'route';
      const session = this.get('session');
      const routeJson = this.buildRouteJson(userInfo, routeInfo, loggingType);
      var url = this.buildURL('event');
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: routeJson
      });
    },

    createUserRouteActivity(userInfo, routeInfo) {
      let loggingType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'user';
      const session = this.get('session');
      const routeJson = this.buildUserRouteJson(userInfo, routeInfo, loggingType);
      var url = this.buildURL('event');
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: routeJson
      });
    },

    createUserTripActivity(userInfo, tripInfo) {
      let addUserActionType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      const session = this.get('session');
      const tripJson = this.buildTripJson(userInfo, tripInfo, addUserActionType);
      var url = this.buildURL('event');
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: tripJson
      });
    },

    createUserVehicleActivity(userInfo, vehicleInfo, actionKey) {
      const session = this.get('session');
      const vehicleJson = this.buildVehicleJson(userInfo, vehicleInfo, actionKey);
      var url = this.buildURL('event');
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: vehicleJson
      });
    },

    createUserbookingActivity(userInfo, bookingInfo, isOverrideEligible) {
      const session = this.get('session');
      const json = this.buildBookingJson(userInfo, bookingInfo, isOverrideEligible);
      var url = this.buildURL('event');
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    createUserSubscriptionActivity(userInfo, subscriptioInfo) {
      const session = this.get('session');
      const json = this.buildSubscriptionJson(userInfo, subscriptioInfo);
      var url = this.buildURL('event');
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    createGenericBookingActivity(bookingId, actions) {
      const context = {
        bookingId: bookingId
      };
      const content = {
        userId: this.session.data.authenticated.userId,
        actualTime: (0, _moment.default)().toDate(),
        actions
      };
      return this.createGenericLoggingActivity(content, context, 'BE');
    },

    createGenericRiderActivity(riderId, actions) {
      const context = {
        riderId: riderId
      };
      const content = {
        userId: this.session.data.authenticated.userId,
        actualTime: (0, _moment.default)().toDate(),
        actions
      };
      return this.createGenericLoggingActivity(content, context, 'UE');
    },

    createUserRiderActivity(userInfo, actionType, eventType) {
      const session = this.get('session');
      const json = this.buildRiderJson(userInfo, actionType, eventType);
      var url = this.buildURL('event');
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    createUserActivityLog(activity, details) {
      const eventLog = {
        type: 'event',
        attributes: {
          source: 'webApp',
          eventType: 'UE',
          actionType: 'genericLogging',
          userId: this.get('session.data.authenticated.userId'),
          receivedAt: (0, _moment.default)().toDate(),
          eventLog: {
            genericLogging: [{
              activity: activity,
              loggingType: 'user',
              details: details
            }]
          }
        }
      };
      return this.createEventActivity(eventLog);
    },

    createGenericLoggingActivity(userInfo, context) {
      let eventType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'UE';
      const session = this.get('session');
      const json = this.buildGenericLoggingJson(userInfo, context, eventType);
      const url = this.buildURL('event');
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    buildGenericLoggingJson(activityContent, context, eventType) {
      const riderId = Ember.isPresent(context) ? context.riderId : null;
      const bookingId = Ember.isPresent(context) ? context.bookingId : null;
      const data = {
        type: 'event',
        attributes: {
          eventType: eventType,
          actionType: 'genericLogging',
          userId: activityContent.userId,
          riderId,
          bookingId,
          time: activityContent.actualTime,
          createdAt: activityContent.actualTime,
          receivedAt: activityContent.actualTime,
          alertFlag: false,
          alertFormat: null,
          alertFlagDelete: false,
          alertFlagInsert: false,
          deleteAlertActionTypes: null,
          eventLog: {
            genericLogging: activityContent.actions
          }
        }
      };
      const eventJson = {
        data: data
      };
      return eventJson;
    },

    createBookingActivity(userInfo, bookingInfo) {
      const session = this.get('session');
      const json = this.buildBookingJson2(userInfo, bookingInfo);
      var url = this.buildURL('event');
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    buildUpdateSubscriptionJson(activityContent, subscriptionId) {
      const subscriptionJson = {
        data: {
          type: 'event',
          attributes: {
            eventType: 'SE',
            actionType: 'change',
            routeId: null,
            subscriptionId: subscriptionId,
            stopPointId: null,
            scheduleId: null,
            userId: activityContent.content.userId,
            vehicleId: null,
            time: activityContent.content.actualTime,
            createdAt: activityContent.content.actualTime,
            receivedAt: activityContent.content.actualTime,
            alertFlag: false,
            alertFormat: null,
            alertFlagDelete: false,
            alertFlagInsert: false,
            deleteAlertActionTypes: null,
            eventLog: {
              'change': [{
                activity: activityContent.content.activity,
                loggingType: 'subscription',
                details: activityContent.content.details
              }]
            }
          }
        }
      };
      return subscriptionJson;
    },

    createUpdateSubscriptionActivity(activityContent, subscriptionId) {
      const session = this.get('session');
      const json = this.buildUpdateSubscriptionJson(activityContent, subscriptionId);
      var url = this.buildURL('event');
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    buildBookingJson2(userInfo, bookingInfo) {
      const bookingId = bookingInfo.booking.get('id');
      const actionType = 'change';
      const bookingJson = {
        data: {
          type: 'event',
          attributes: {
            eventType: 'BE',
            actionType: actionType,
            bookingId: bookingId,
            userId: userInfo.content.userId,
            time: userInfo.content.actualTime,
            createdAt: userInfo.content.actualTime,
            receivedAt: userInfo.content.actualTime,
            alertFlag: false,
            alertFormat: null,
            alertFlagDelete: false,
            alertFlagInsert: false,
            deleteAlertActionTypes: null,
            eventLog: {
              actionType: [{
                activity: userInfo.content.activity,
                loggingType: 'user',
                details: userInfo.content.details
              }]
            }
          }
        }
      };
      return bookingJson;
    },

    buildRiderJson(userInfo, actionType, eventType) {
      const riderId = userInfo.content.riderId;
      const bookingJson = {
        data: {
          type: 'event',
          attributes: {
            eventType: eventType,
            actionType: actionType,
            userId: userInfo.content.userId,
            riderId: riderId,
            time: userInfo.content.actualTime,
            createdAt: userInfo.content.actualTime,
            receivedAt: userInfo.content.actualTime,
            alertFlag: false,
            alertFormat: null,
            alertFlagDelete: false,
            alertFlagInsert: false,
            deleteAlertActionTypes: null,
            eventLog: {
              actionType: [{
                activity: userInfo.content.activity,
                loggingType: 'user',
                details: userInfo.content.details
              }]
            }
          }
        }
      };
      return bookingJson;
    },

    buildBookingJson(userInfo, bookingInfo, isOverrideEligible) {
      const stopPointId = isOverrideEligible ? bookingInfo.booking.get('trips.firstObject.route.stopPoints.firstObject.id') : bookingInfo.booking.get('currentRoute.stopPoints.id');
      const scheduleId = isOverrideEligible ? bookingInfo.booking.get('trips.firstObject.schedule.id') : bookingInfo.booking.get('schedule.id');
      const vehicleId = isOverrideEligible ? bookingInfo.booking.get('trips.firstObject.route.vehicle.id') : bookingInfo.booking.get('currentRoute.vehicle.id');
      const bookingId = isOverrideEligible ? bookingInfo.booking.get('id') : bookingInfo.booking.get('id');
      const actionType = isOverrideEligible ? 'overrideEligibility' : 'change';
      const bookingJson = {
        data: {
          type: 'event',
          attributes: {
            eventType: 'BE',
            actionType: actionType,
            bookingId: bookingId,
            stopPointId: stopPointId,
            scheduleId: scheduleId,
            userId: userInfo.content.userId,
            vehicleId: vehicleId,
            time: userInfo.content.actualTime,
            createdAt: userInfo.content.actualTime,
            receivedAt: userInfo.content.actualTime,
            alertFlag: false,
            alertFormat: null,
            alertFlagDelete: false,
            alertFlagInsert: false,
            deleteAlertActionTypes: null,
            eventLog: {
              actionType: [{
                activity: userInfo.content.activity,
                loggingType: 'booking',
                details: userInfo.content.details
              }, {
                activity: userInfo.content.activity,
                loggingType: 'user',
                details: userInfo.content.details
              }]
            }
          }
        }
      };
      return bookingJson;
    },

    buildSubscriptionJson(userInfo, subscriptionInfo) {
      const subscriptionJson = {
        data: {
          type: 'event',
          attributes: {
            eventType: 'SE',
            actionType: 'create',
            routeId: subscriptionInfo.get('context.subscription.currentRoute.id'),
            subscriptionId: subscriptionInfo.get('context.subscription.id'),
            stopPointId: subscriptionInfo.get('context.subscription.currentRoute.stopPoints.id'),
            scheduleId: subscriptionInfo.get('context.subscription.schedule.id'),
            userId: userInfo.content.userId,
            vehicleId: subscriptionInfo.get('context.subscription.currentRoute.vehicle.id'),
            time: userInfo.content.actualTime,
            createdAt: userInfo.content.actualTime,
            receivedAt: userInfo.content.actualTime,
            alertFlag: false,
            alertFormat: null,
            alertFlagDelete: false,
            alertFlagInsert: false,
            deleteAlertActionTypes: null,
            eventLog: {
              'create': [{
                activity: userInfo.content.activity,
                loggingType: 'subscription',
                details: userInfo.content.details
              }]
            }
          }
        }
      };
      return subscriptionJson;
    },

    buildTripJson(userInfo, tripInfo, addUserActionType) {
      const userId = userInfo.content.userId;
      const time = userInfo.content.actualTime;
      const createdAt = userInfo.content.actualTime;
      const receivedAt = userInfo.content.actualTime;
      const actionType = userInfo.content.type;
      const actionKey = this.getEventLogs(userInfo, actionType, 'trip', addUserActionType);
      const tripJson = {
        data: {
          type: 'event',
          attributes: {
            eventType: 'UE',
            actionType: actionType,
            routeId: tripInfo.get('context.trip.currentRoute.id'),
            tripId: tripInfo.get('context.trip.id'),
            stopPointId: tripInfo.get('context.trip.currentRoute.stopPoints.id'),
            scheduleId: tripInfo.get('context.trip.schedule.id'),
            userId: userId,
            vehicleId: tripInfo.get('context.trip.currentRoute.vehicle.id'),
            time: time,
            createdAt: createdAt,
            receivedAt: receivedAt,
            alertFlag: false,
            alertFormat: null,
            alertFlagDelete: false,
            alertFlagInsert: false,
            deleteAlertActionTypes: null,
            eventLog: actionKey
          }
        }
      };
      return tripJson;
    },

    buildUserRouteJson(userInfo, routeInfo, loggingType) {
      const userId = userInfo.content.userId;
      const time = userInfo.content.actualTime;
      const createdAt = userInfo.content.actualTime;
      const receivedAt = userInfo.content.actualTime;
      const actionType = userInfo.content.type;
      const actionKey = this.getEventLogs(userInfo, actionType, loggingType);
      const routeJson = {
        data: {
          type: 'event',
          attributes: {
            eventType: 'UE',
            actionType: actionType,
            routeId: routeInfo ? routeInfo.get('context.route.id') : null,
            scheduleId: routeInfo ? routeInfo.get('context.route.schedule.id') : null,
            userId: userId,
            vehicleId: routeInfo ? routeInfo.get('context.route.currentRoute.vehicle.id') : null,
            time: time,
            createdAt: createdAt,
            receivedAt: receivedAt,
            alertFlag: false,
            alertFormat: null,
            alertFlagDelete: false,
            alertFlagInsert: false,
            deleteAlertActionTypes: null,
            eventLog: actionKey
          }
        }
      };
      return routeJson;
    },

    buildRouteJson(userInfo, routeInfo, loggingType) {
      const userId = userInfo.content.userId;
      const time = userInfo.content.actualTime;
      const createdAt = userInfo.content.actualTime;
      const receivedAt = userInfo.content.actualTime;
      const actionType = userInfo.content.type;
      const actionKey = this.getEventLogs(userInfo, actionType, loggingType);
      const routeJson = {
        data: {
          type: 'event',
          attributes: {
            eventType: 'REE',
            actionType: actionType,
            routeId: routeInfo ? routeInfo.get('context.route.id') : null,
            scheduleId: routeInfo ? routeInfo.get('context.route.schedule.id') : null,
            userId: userId,
            vehicleId: routeInfo ? routeInfo.get('context.route.currentRoute.vehicle.id') : null,
            time: time,
            createdAt: createdAt,
            receivedAt: receivedAt,
            alertFlag: false,
            alertFormat: null,
            alertFlagDelete: false,
            alertFlagInsert: false,
            deleteAlertActionTypes: null,
            eventLog: actionKey
          }
        }
      };
      return routeJson;
    },

    buildVehicleJson(userInfo, vehicleInfo, actionKey) {
      const userId = userInfo.content.userId;
      const time = userInfo.content.actualTime;
      const actionType = userInfo.content.type;
      const vehicleJson = {
        data: {
          type: 'event',
          attributes: {
            source: 'webApp',
            eventType: 'VE',
            actionType: actionType,
            userId: userId,
            vehicleId: vehicleInfo.get('context.vehicle.id'),
            time: time,
            createdAt: time,
            receivedAt: time,
            eventLog: actionKey
          }
        }
      };
      return vehicleJson;
    },

    getEventLogs(userInfo, actionType, loggingType) {
      let addUserActionType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      const actionKey = {};
      actionKey[actionType] = [{
        activity: userInfo.content.activity,
        loggingType: loggingType,
        details: userInfo.content.details
      }];

      if (addUserActionType) {
        actionKey.user = [{
          activity: userInfo.content.activity,
          loggingType: 'user',
          details: userInfo.content.details
        }];
      }

      return actionKey;
    }

  });

  _exports.default = _default;
});