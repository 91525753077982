define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/search-button", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const SearchButton = props => {
    const {
      searchText,
      setSearchText
    } = props;

    const [searchEnabled, setSearchEnabled] = _react.default.useState(false);

    _react.default.useEffect(() => {}, []);

    return _react.default.createElement(_react.default.Fragment, null, searchEnabled && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("input", {
      className: "column-widget-search-box data-test-search-box",
      name: "searchInput",
      value: searchText,
      onChange: event => setSearchText(event.target.value),
      placeholder: "Search"
    }), _react.default.createElement("button", {
      className: "column-widget-button",
      onClick: () => {
        setSearchEnabled(false);
        setSearchText('');
      }
    }, _react.default.createElement("i", {
      className: "fa fa-times-circle active-button",
      "aria-hidden": "true"
    }))), _react.default.createElement("button", {
      className: "playback-widget-button-new ",
      onClick: () => setSearchEnabled(true)
    }, _react.default.createElement("i", {
      className: "fa fa-search",
      "aria-hidden": "true"
    })));
  };

  var _default = SearchButton;
  _exports.default = _default;
});