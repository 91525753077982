define("adept-iq/models/leg", ["exports", "ember-data", "adept-iq/utils/unwrapProxy", "lodash"], function (_exports, _emberData, _unwrapProxy, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  const TRAVEL_NEEDS_TO_FILTER = [];
  const PCA = 'PCA';
  const SERVICE_ANIMAL = 'serviceAnimal';
  const AMBULATORY = 'AMBULATORY';

  var _default = Model.extend({
    store: Ember.inject.service(),
    bookingService: Ember.inject.service('booking'),
    requestTime: attr('date'),
    anchor: attr('string'),
    purpose: attr('string'),
    notes: attr('string'),
    brokerEligible: attr('boolean'),
    rider: belongsTo('rider', {
      async: true
    }),
    booking: belongsTo('booking'),
    serviceWindow: belongsTo('service-window'),
    fundingAgency: belongsTo('funding-agency'),
    segments: hasMany('segment'),
    legTravelNeeds: hasMany('leg-travel-need'),
    legServiceNeeds: hasMany('leg-service-need'),
    tripPurpose: belongsTo('trip-purpose'),
    riderNotes: Ember.computed.alias('rider.notes'),
    tripStartTime: Ember.computed.alias('booking.tripStartTime'),
    tripEndTime: Ember.computed.alias('booking.tripEndTime'),
    equipments: Ember.computed('legTravelNeeds.[]', function () {
      const legTravelNeeds = Ember.get(this, 'legTravelNeeds');
      const equipments = [];

      if (!Ember.isEmpty(legTravelNeeds)) {
        legTravelNeeds.forEach(travelNeed => {
          if (travelNeed.get('isEquipment')) {
            equipments.push(travelNeed);
          }
        });
      }

      return equipments;
    }),
    extraPassengers: Ember.computed.filterBy('legTravelNeeds', 'isExtraPassenger', true),
    legEquipments: Ember.computed('leg', function () {
      const legTravelNeeds = Ember.get(this, 'leg.legTravelNeeds');
      const equipments = legTravelNeeds.get('equipments');
      return equipments ? equipments : null;
    }),
    legExtraPassengers: Ember.computed('leg', function () {
      const legTravelNeeds = Ember.get(this, 'leg.legTravelNeeds');
      const extraPassengers = legTravelNeeds.get('extraPassengers');
      return extraPassengers ? extraPassengers : null;
    }),
    segment: Ember.computed('segments', function () {
      const segments = this.get('segments');

      if (segments) {
        return segments.firstObject;
      }

      return null;
    }),
    noOfTravelNeeds: Ember.computed('legTravelNeeds.[]', function () {
      const noOfTravelNeeds = Ember.get(this, 'legTravelNeeds');

      if (!Ember.isEmpty(noOfTravelNeeds)) {
        return noOfTravelNeeds.length;
      }

      return 0;
    }),
    sortedLegTravelNeeds: Ember.computed('legTravelNeeds', function () {
      const travelNeeds = Ember.get(this, 'legTravelNeeds');

      if (!Ember.isEmpty(travelNeeds)) {
        return travelNeeds.sortBy('travelNeedType.name');
      }

      return null;
    }),
    vehicleCapacityCounts: Ember.computed('legTravelNeeds.@each.{travelNeedTypeName,count}', function () {
      const initialCounts = {
        flexSeats: [],
        nonConsumables: {}
      };
      const legTravelNeeds = this.get('legTravelNeeds');
      if (Ember.isEmpty(legTravelNeeds)) return initialCounts;
      return legTravelNeeds.reduce((obj, legTravelNeed) => {
        const travelNeedtype = legTravelNeed.get('travelNeedType');
        const tntVehicleCapacityCount = parseInt(travelNeedtype.get('vehicleCapacityCount'), 10);
        const tntVehicleCapacityName = travelNeedtype.get('vehicleCapacityType');
        const travelNeedTypeName = legTravelNeed.get('travelNeedTypeName');
        const isTypeCountable = tntVehicleCapacityCount > 0;
        const count = legTravelNeed.get('count') || 0;
        const capacityCount = count * tntVehicleCapacityCount;
        const originalCount = obj[tntVehicleCapacityName] ? obj[tntVehicleCapacityName] : 0; // If it is array or object, let's keep it's original data.

        obj[tntVehicleCapacityName] = isTypeCountable ? originalCount + capacityCount : originalCount;

        if (legTravelNeed.get('isNonConsumable')) {
          obj.nonConsumables = { ...obj.nonConsumables,
            [travelNeedTypeName]: count
          };
        }

        return obj;
      }, initialCounts);
    }),
    travelNeedCounts: Ember.computed('legTravelNeeds.@each.{travelNeedTypeName,count}', function () {
      const initialCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0,
        pca: 0,
        extraWideLift: 0,
        flexSeats: [],
        nonConsumables: {}
      };
      const legTravelNeeds = this.get('legTravelNeeds');
      if (Ember.isEmpty(legTravelNeeds)) return initialCounts;
      return legTravelNeeds.reduce((obj, legTravelNeed) => {
        const travelNeedTypeName = legTravelNeed.get('travelNeedTypeName');
        const isTypeCountable = !(0, _lodash.isNaN)(parseInt(initialCounts[travelNeedTypeName], 10));
        const count = legTravelNeed.get('count') || 0; // If it is array or object, let's keep it's original data.

        obj[travelNeedTypeName] = isTypeCountable ? obj[travelNeedTypeName] + count : obj[travelNeedTypeName];

        if (legTravelNeed.get('isNonConsumable')) {
          obj.nonConsumables = { ...obj.nonConsumables,
            [travelNeedTypeName]: count
          };
        }

        return obj;
      }, initialCounts);
    }),
    legOrdinal: Ember.computed('segment.legOrdinal', function () {
      return this.get('segment.legOrdinal');
    }),
    nonClonedTravelNeeds: Ember.computed('legTravelNeeds.[]', function () {
      const filteredTravelNeeds = this.get('legTravelNeeds').filter(legtravelNeed => {
        return !TRAVEL_NEEDS_TO_FILTER.includes(legtravelNeed.get('travelNeedTypeNameUppercase')) && !legtravelNeed.get('isCloned');
      });
      return filteredTravelNeeds;
    }),
    isPcaAvailable: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const pcaTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedType.upperCaseName') === PCA;
      });
      return !!pcaTravelNeed;
    }),
    isPcaEligible: Ember.computed('isPcaAvailable', function () {
      const isPcaAvailable = this.get('isPcaAvailable');
      return !!isPcaAvailable;
    }),
    isServiceAnimalChecked: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const serviceAnimalTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedType.name') === SERVICE_ANIMAL;
      });
      return !!serviceAnimalTravelNeed;
    }),
    ambulatoryTravelNeed: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const ambulatoryTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === AMBULATORY;
      });
      return ambulatoryTravelNeed;
    }),
    configLoadUnloadTimes: Ember.computed('legTravelNeeds.[]', 'legTravelNeeds.@each.{travelNeedType}', function () {
      const selectedTRs = this.get('legTravelNeeds').map(tr => tr.get('travelNeedTypeNameUppercase'));
      const travelNeedTypes = this.store.peekAll('travel-need-type');
      const filteredTravelNeedTypes = travelNeedTypes.toArray().filter(ctr => ctr.get('name'));
      const selectedConfigTRs = filteredTravelNeedTypes.filter(ctr => selectedTRs.includes(ctr.get('name').toUpperCase()));
      return {
        loadTimes: selectedConfigTRs.map(item => parseInt(item.get('loadTime'), 10)),
        unloadTimes: selectedConfigTRs.map(item => parseInt(item.get('unloadTime'), 10))
      };
    }),
    loadTime: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      let pickDwellTime;
      const segments = this.get('segments');
      const rider = this.store.peekRecord('rider', this.get('bookingService.selectedRiders.firstObject.id'));
      const riderTravelNeeds = rider.get('riderTravelNeeds');
      segments.forEach(segment => {
        if (segment.get('id')) {
          pickDwellTime = segment.get('pick.dwell');
        }
      });

      if (pickDwellTime) {
        return pickDwellTime / 60;
      }

      const allTravelNeedLoadTimes = this.get('legTravelNeeds').map(travelNeed => {
        const travelNeedTypePresent = !Ember.isEmpty((0, _unwrapProxy.unwrapProxy)(travelNeed.get('travelNeedType')));

        if (travelNeedTypePresent) {
          const travelNeedTypeName = travelNeed.get('travelNeedType.name');
          const foundRiderTravelNeed = riderTravelNeeds.find(riderTravelNeed => {
            return riderTravelNeed.get('travelNeedTypeName') === travelNeedTypeName;
          });
          const configTravelNeedTypeLoadTime = travelNeed.get('travelNeedType.loadTime') ? parseInt(travelNeed.get('travelNeedType.loadTime'), 10) : 0;
          const loadTime = Ember.isEmpty(foundRiderTravelNeed) ? configTravelNeedTypeLoadTime : foundRiderTravelNeed.get('loadTime');
          return (0, _lodash.isNumber)(loadTime) && !(0, _lodash.isNaN)(loadTime) ? loadTime : 0;
        }

        return 0;
      });
      const maxLoadTime = Math.max(...allTravelNeedLoadTimes);
      return maxLoadTime;
    }),
    unloadTime: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      let dropDwellTime;
      const segments = this.get('segments');
      const rider = this.store.peekRecord('rider', this.get('bookingService.selectedRiders.firstObject.id'));
      const riderTravelNeeds = rider.get('riderTravelNeeds');
      segments.forEach(segment => {
        if (segment.get('id')) {
          dropDwellTime = segment.get('drop.dwell');
        }
      });

      if (dropDwellTime) {
        return dropDwellTime / 60;
      }

      const allTravelNeedUnLoadTimes = this.get('legTravelNeeds').map(travelNeed => {
        const travelNeedTypePresent = !Ember.isEmpty((0, _unwrapProxy.unwrapProxy)(travelNeed.get('travelNeedType')));

        if (travelNeedTypePresent) {
          const travelNeedTypeName = travelNeed.get('travelNeedType.name');
          const foundRiderTravelNeed = riderTravelNeeds.find(riderTravelNeed => {
            return riderTravelNeed.get('travelNeedTypeName') === travelNeedTypeName;
          });
          const configTravelNeedTypeUnloadTime = travelNeed.get('travelNeedType.unloadTime') ? parseInt(travelNeed.get('travelNeedType.unloadTime'), 10) : 0;
          const unloadTime = Ember.isEmpty(foundRiderTravelNeed) ? configTravelNeedTypeUnloadTime : foundRiderTravelNeed.get('unloadTime');
          return (0, _lodash.isNumber)(unloadTime) && !(0, _lodash.isNaN)(unloadTime) ? unloadTime : 0;
        }

        return 0;
      });
      const maxUnLoadTime = Math.max(...allTravelNeedUnLoadTimes);
      return maxUnLoadTime;
    }),
    ambulatorySeatCount: Ember.computed('ambulatoryTravelNeed', function () {
      const ambulatoryTravelNeed = this.get('ambulatoryTravelNeed');
      return ambulatoryTravelNeed.get('count');
    }),
    companionTravelNeed: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const companionTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return this.bookingService.get('isCompanionTravelNeed')(travelNeed);
      });
      return companionTravelNeed;
    }),
    companionTravelNeeds: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const companionTravelNeeds = this.get('legTravelNeeds').filter(travelNeed => {
        return this.bookingService.get('isCompanionTravelNeed')(travelNeed);
      });
      return companionTravelNeeds;
    }),
    pcaTravelNeed: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const pcaTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === PCA;
      });
      return pcaTravelNeed;
    }),
    sortedSegment: Ember.computed('segments', function () {
      const segments = this.get('segments').sortBy('id');

      if (segments) {
        return segments.firstObject;
      }

      return null;
    })
  });

  _exports.default = _default;
});