define("adept-iq/models/rider-certification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    appSentDate: attr('date', {
      defaultValue: null
    }),
    appReceivedDate: attr('date', {
      defaultValue: null
    }),
    sentToMDDate: attr('date', {
      defaultValue: null
    }),
    receivedFromMDDate: attr('date', {
      defaultValue: null
    }),
    appealedDate: attr('date', {
      defaultValue: null
    }),
    appealHearingDate: attr('date', {
      defaultValue: null
    }),
    appealDecisionDate: attr('date', {
      defaultValue: null
    }),
    appealNotes: attr('string'),
    appealLocation: attr('string'),
    appealPhone: attr('string'),
    approvedDate: attr('date', {
      defaultValue: null
    }),
    deniedDate: attr('date', {
      defaultValue: null
    }),
    deniedBy: attr('string'),
    reasonDenied: attr('string'),
    approvalComments: attr('string'),
    assessmentDate: attr('date', {
      defaultValue: null
    }),
    assessmentHearingDate: attr('date', {
      defaultValue: null
    }),
    assessmentDecisionDate: attr('date', {
      defaultValue: null
    }),
    assessmentNotes: attr('string'),
    assessmentLocation: attr('string'),
    assessmentPhone: attr('string'),
    verificationMethod: attr('string'),
    verifiedBy: attr('string'),
    doctorName: attr('string'),
    doctorPhone: attr('string'),
    doctorEmail: attr('string'),
    doctorExtension: attr('string'),
    doctorAddress: attr('string'),
    rider: belongsTo('rider')
  });

  _exports.default = _default;
});