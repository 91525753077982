define("adept-iq/classes/rules/eligibility-rules/itp-resulttype-rule", ["exports", "lodash", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type", "adept-iq/classes/rules/itinerary-type"], function (_exports, _lodash, _baseRule, _ruleResultType, _itineraryType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ItpResultTypeRule = void 0;

  class ItpResultTypeRule extends _baseRule.default {
    async doEvaluate(ruleContext) {
      const {
        itpRequestResults
      } = ruleContext;
      const ruleResultMapper = {}; // Reg- NYAAR-17442 - To Support ITP for Multi Legs

      ruleContext.itpResultType = {};
      (0, _lodash.forEach)(itpRequestResults, (value, key) => {
        const resultType = this.getResultType(value);
        ruleContext.itpResultType[key] = resultType;
        let ruleResult;

        switch (resultType) {
          case _itineraryType.NO_SOLUTION:
            ruleResult = _ruleResultType.RESULT_TYPE_NO;
            break;

          case _itineraryType.FEEDER:
            ruleResult = _ruleResultType.RESULT_TYPE_FEEDER;
            break;

          case _itineraryType.FIXED_ROUTE:
            ruleResult = _ruleResultType.RESULT_TYPE_FIXED_ROUTE;
            break;

          default:
            ruleResult = _ruleResultType.RESULT_TYPE_NO;
        }

        ruleResultMapper[key] = ruleResult;
      });
      return ruleResultMapper;
    }

    getResultType(itpRequestResult) {
      const isFeederType = itpRequestResult.result.type === _itineraryType.FEEDER;
      if (isFeederType) return _itineraryType.FEEDER;
      const isFixedRouteType = itpRequestResult.result.type === _itineraryType.FIXED_ROUTE;
      if (isFixedRouteType) return _itineraryType.FIXED_ROUTE;
      return _itineraryType.NO_SOLUTION;
    }

  }

  _exports.ItpResultTypeRule = ItpResultTypeRule;
  ItpResultTypeRule.RULE_NAME = 'ItpResultTypeRule';
});