define("adept-iq/scenarios/config-service", ["exports", "ember-data-factory-guy"], function (_exports, _emberDataFactoryGuy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // file: app/scenarios/main.js
  // Just for fun, set the log level ( to 1 ) and see all FactoryGuy response info in console
  _emberDataFactoryGuy.Scenario.settings({
    logLevel: 0 // 1 is the max for now, default is 0

  });

  const CONFIG_LIMIT = 40;

  class _default extends _emberDataFactoryGuy.Scenario {
    run() {
      const vehicleCapacityType = (0, _emberDataFactoryGuy.make)('vehicle-capacity-type');
      const vehicleCapacityConfig = (0, _emberDataFactoryGuy.makeList)('vehicle-capacity-config', 5, {
        vehicleCapacityType
      });
      (0, _emberDataFactoryGuy.makeList)('cs-config-item', CONFIG_LIMIT);
      (0, _emberDataFactoryGuy.makeList)('travel-need-type', 5);
      (0, _emberDataFactoryGuy.makeList)('passenger-type', 3);
      (0, _emberDataFactoryGuy.makeList)('eligibility-type', 3);
      (0, _emberDataFactoryGuy.makeList)('provider', 5);
      (0, _emberDataFactoryGuy.make)('no-show-reason-code'); // currently this will take priority over actual config permission in guy environment

      (0, _emberDataFactoryGuy.make)('cs-config-permission', 'admin');
      vehicleCapacityConfig.forEach(config => {
        (0, _emberDataFactoryGuy.make)('vehicle-type', {
          vehicleCapacityConfigs: config
        });
      });
      (0, _emberDataFactoryGuy.makeList)('fare-type', 3);
      (0, _emberDataFactoryGuy.make)('cancel-type');
      (0, _emberDataFactoryGuy.make)('break-type', 'fixed');
      (0, _emberDataFactoryGuy.make)('break-type', 'floating');
      (0, _emberDataFactoryGuy.make)('service-window', 'appointment');
      (0, _emberDataFactoryGuy.make)('service-window', 'fixed');
      (0, _emberDataFactoryGuy.make)('service-window', 'float');
      (0, _emberDataFactoryGuy.make)('schedule-configuration');
      (0, _emberDataFactoryGuy.make)('rider-place-type', 'favorite');
      (0, _emberDataFactoryGuy.make)('rider-place-type', 'primary');
      (0, _emberDataFactoryGuy.make)('metric'); // @TODO might need to make hard coded values or dynamically needs routes, trips, and all them ahead of time
    }

  }

  _exports.default = _default;
});