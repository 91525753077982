define("adept-iq/pods/components/side-drawer/reconcile-close-dates/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: 'Reconcile',
    nonVerifiedProviderTable: [{
      label: 'Provider ID',
      valuePath: 'providerName',
      resizable: true,
      minResizeWidth: 50
    }, {
      label: 'Trip Date',
      valuePath: 'plannedStartTime',
      resizable: true,
      minResizeWidth: 50
    }],
    nonVerifiedRouteTable: [{
      label: 'Open Routes',
      valuePath: 'routeId',
      resizable: true,
      minResizeWidth: 50
    }],
    verifiedProviderTable: [{
      label: 'Provider',
      valuePath: 'providerName',
      resizable: true,
      minResizeWidth: 50
    }, {
      label: 'Close',
      resizable: false,
      minResizeWidth: 50,
      cellComponent: 'generic-components/table/cells/check-box'
    }]
  };
  _exports.default = _default;
});