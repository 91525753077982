define("adept-iq/pods/components/iq-widgets/delete-subscriptions-form/subscription-information/component", ["exports", "adept-iq/config/environment", "adept-iq/pods/components/iq-widgets/form-widget/component", "moment"], function (_exports, _environment, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    subscriptionId: null,
    passengerID: null,
    fName: null,
    lName: null,
    dateReqTime: null,
    riderDetail: null,
    bookDate: null,

    init() {
      this._super(...arguments);

      const subscriptionData = this.get('editModal.editableRecords')[0];
      this.setProperties({
        'riderDetail': {
          'subscriptionId': subscriptionData.get('id'),
          'passengerID': subscriptionData.get('rider.riderId'),
          'fName': subscriptionData.get('rider.firstName'),
          'lName': subscriptionData.get('rider.lastName'),
          'dateReqTime': subscriptionData.get('requestTime')
        }
      });
      this.set('bookDate', (0, _moment.default)(this.riderDetail.dateReqTime).format(_environment.default.dateTimeFormat.dateMoment));
    }

  });

  _exports.default = _default;
});