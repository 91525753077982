define("adept-iq/serializers/rider-certification", ["exports", "adept-iq/serializers/-rms-riderManagement"], function (_exports, _rmsRiderManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rmsRiderManagement.default.extend({
    attrs: {},

    serialize() {
      const json = this._super(...arguments);

      if (Ember.isEmpty(json.data.attributes.appealPhone)) {
        delete json.data.attributes.appealPhone;
      }

      if (Ember.isEmpty(json.data.attributes.doctorPhone)) {
        delete json.data.attributes.doctorPhone;
      }

      if (Ember.isEmpty(json.data.attributes.assessmentPhone)) {
        delete json.data.attributes.assessmentPhone;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});