define("adept-iq/pods/index/modals/settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TILE_SIZE_OPTIONS = [100, 150, 200];
  const TILE_SIZE_LABELS = {
    '100': 'Small',
    '150': 'Medium',
    '200': 'Large'
  };
  const TILE_SPACING_OPTIONS = [2, 4, 6];
  const TILE_SPACING_LABELS = {
    '2': 'Small',
    '4': 'Medium',
    '6': 'Large'
  };
  const COLOR_MODE_OPTIONS = ['light', 'dark'];
  const COLOR_MODE_LABELS = {
    'light': 'Light mode',
    'dark': 'Dark mode'
  };

  var _default = Ember.Controller.extend({
    workspace: Ember.inject.service(),
    tileSizeOptions: TILE_SIZE_OPTIONS,
    tileSizeLabels: TILE_SIZE_LABELS,
    tileSpacingOptions: TILE_SPACING_OPTIONS,
    tileSpacingLabels: TILE_SPACING_LABELS,
    colorModeOptions: COLOR_MODE_OPTIONS,
    colorModeLabels: COLOR_MODE_LABELS,
    selectedTileSize: Ember.computed.alias('workspace.tileSize'),
    selectedTileSpacing: Ember.computed.alias('workspace.tileSpacing'),
    selectedColorMode: Ember.computed('workspace.isLightMode', function () {
      const isLight = this.get('workspace.isLightMode');

      if (isLight) {
        return 'light';
      }

      return 'dark';
    }),
    actions: {
      toggleModal() {
        this.transitionToRoute('index');
      },

      didSelectColorMode(colorMode) {
        if (colorMode === 'dark') {
          this.set('workspace.isLightMode', false);
        } else {
          this.set('workspace.isLightMode', true);
        }
      }

    }
  });

  _exports.default = _default;
});