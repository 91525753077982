define("adept-iq/pods/components/generic-components/availabilities/component", ["exports", "ember-changeset-cp-validations", "ember-changeset", "lodash", "adept-iq/config/environment", "moment"], function (_exports, _emberChangesetCpValidations, _emberChangeset, _lodash, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_DATE = (0, _moment.default)().add(1000, 'years');

  var _default = Ember.Component.extend({
    classNames: ['availabilities'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    availabilities: null,
    parentModel: null,
    shouldDisplayValidations: true,
    dateTimeFormat: _environment.default.dateTimeFormat,
    daysOfWeek: Ember.computed('filteredAvailabilities', function () {
      const availabilities = this.get('filteredAvailabilities') || [];
      const dayOptions = [{
        day: 'monday',
        display: 'Mon',
        disabled: false
      }, {
        day: 'tuesday',
        display: 'Tues',
        disabled: false
      }, {
        day: 'wednesday',
        display: 'Wed',
        disabled: false
      }, {
        day: 'thursday',
        display: 'Thurs',
        disabled: false
      }, {
        day: 'friday',
        display: 'Fri',
        disabled: false
      }, {
        day: 'saturday',
        display: 'Sat',
        disabled: false
      }, {
        day: 'sunday',
        display: 'Sun',
        disabled: false
      }, {
        day: 'holiday',
        display: 'Holiday',
        disabled: false
      }];
      availabilities.forEach(availability => {
        dayOptions.forEach(option => {
          if (availability.get(option.day)) {
            option.disabled = true;
          }
        });
      });
      return dayOptions;
    }),
    filteredAvailabilities: Ember.computed('availabilities.@each.hasDeleted', function () {
      const availabilities = this.get('availabilities') || [];
      return availabilities.filter(avail => !avail.get('hasDeleted'));
    }),
    disableAddAvailabilityBtn: Ember.computed('filteredAvailabilities', 'daysOfWeek.@each.disabled', function () {
      const options = this.get('daysOfWeek');
      const totalDOWSelection = this.getDOWCount(this.get('filteredAvailabilities')) || [];
      const everyOptionsIsSelected = options.every(option => option.disabled);
      return everyOptionsIsSelected || totalDOWSelection === options.length;
    }),

    getDOWCount(availabilities) {
      return availabilities.reduce((totalDOW, avail) => {
        const DOWLength = avail.data && avail.data.DOWSelected ? avail.data.DOWSelected.length : 0;
        return totalDOW + DOWLength;
      }, 0);
    },

    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('availabilityRef', this);
    },

    willDestroyElement() {
      const dayOptions = this.get('daysOfWeek') || [];
      dayOptions.forEach(day => {
        Ember.set(day, 'disabled', false);
      });

      this._super(...arguments);
    },

    configureAvailabilities(availability) {
      const {
        validateFn,
        validationMap
      } = (0, _emberChangesetCpValidations.buildChangeset)(availability);
      return new _emberChangeset.default(availability, validateFn, validationMap);
    },

    calcEndDate(availabilities, routeLength) {
      const start = availabilities.get('shiftStart');
      if (!start || !routeLength) return null;
      const clone = (0, _moment.default)(start);
      const duration = routeLength.split(':');
      const hours = duration[0];
      const minutes = duration.length > 1 ? duration[1] : 0;
      clone.add(hours, 'hours');
      clone.add(minutes, 'minutes');
      return clone.toDate();
    },

    async onDateChange(record, path, dateStr) {
      record.set(path, dateStr);
      const calcEndDate = this.get('calcEndDate')(record, record.get('routeLength'));
      record.set('calcEndDate', calcEndDate);
    },

    actions: {
      onRouteLengthChange: async function (availabilities, maskObj) {
        const routeLength = maskObj.masked;
        const oldVal = availabilities.get('routeLength'); // This method gets called multiple times (even while hitting a ENTER key). So If oldValue is equal to newValue then no need to validate

        if (routeLength === oldVal) {
          return;
        }

        let value = maskObj.masked.replace(/_/img, '0');
        if (!value) value = '00:00';
        availabilities.set('routeLength', routeLength);
        const calcEndDate = this.get('calcEndDate')(availabilities, value);
        availabilities.set('calcEndDate', calcEndDate);
      },

      async onDOWChange(availability, options) {
        const filteredAvailabilities = this.get('filteredAvailabilities').filter(avail => avail !== availability);
        const dayOptions = this.get('daysOfWeek') || [];
        const DOWSelected = [];
        dayOptions.forEach(day => {
          availability.set(day.day, false);
        });
        let deselectedDayOptions = dayOptions; // updated DOWs on availability

        options.forEach(option => {
          availability.set(option.day, true);
          const findDay = dayOptions.find(dayOption => dayOption.day === option.day);

          if (findDay) {
            Ember.set(findDay, 'disabled', true);
            DOWSelected.pushObject(findDay);
          }
        });
        availability.set('DOWSelected', DOWSelected); // any days missing re-enable those days

        filteredAvailabilities.forEach(avail => {
          const curDOW = avail.get('DOWSelected');
          deselectedDayOptions = (0, _lodash.differenceBy)(deselectedDayOptions, curDOW, 'day');
        });
        deselectedDayOptions = (0, _lodash.differenceBy)(deselectedDayOptions, DOWSelected, 'day');
        deselectedDayOptions.forEach(option => {
          Ember.set(option, 'disabled', false);
        });
      },

      onDateChange(record, path, value) {
        this.onDateChange(record, path, value);
      },

      addNewAvailability() {
        const store = this.get('store');
        const modelName = this.get('modelName');
        const addAvailability = store.createRecord(modelName, {
          shiftStart: new Date()
        });
        const availability = this.configureAvailabilities(addAvailability);
        this.get('availabilities').pushObject(availability);
        this.get('availabilities').forEach(avail => {
          const shiftStart = avail.get('shiftStart');
          const calcEndDate = avail.get('calcEndDate');
          avail.setProperties({
            startTime: shiftStart,
            shiftEnd: calcEndDate,
            endTime: MAX_DATE.toDate()
          });
        });
      },

      onRemoveAvailability(availability) {
        const dayOptions = this.get('daysOfWeek');
        const DOWSelected = availability.get('DOWSelected');
        const deselectedDayOptions = (0, _lodash.intersectionBy)(dayOptions, DOWSelected, 'day') || [];
        deselectedDayOptions.forEach(option => {
          Ember.set(option, 'disabled', false);
        });
        availability.set('hasDeleted', true);
      }

    }
  });

  _exports.default = _default;
});