define("adept-iq/models/role-permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    permSsoRole: belongsTo('perm-sso-role'),
    roleId: attr('string'),
    permissionId: attr('number'),
    category: attr('string')
  });

  _exports.default = _default;
});