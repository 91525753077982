define("adept-iq/tests/factories/route-template", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('route-template', {
    polymorphic: false,
    // manually flag this model as NOT polymorphic
    sequences: {
      routeNames: num => num
    },
    default: {
      name: _emberDataFactoryGuy.default.generate('routeNames'),
      schedulingMode: 'automatic',
      type: 'regular',
      notes: 'some notes',
      tripLimit: 9,
      distanceLimit: 99,
      active: true,
      optimizationFlags: 0,
      // bit field
      affinities: '[1,2,3,4]' // string array ex "[1,2,3,4]"

    },
    traits: {}
  });
});