define("adept-iq/adapters/cs-config-lookup-model", ["exports", "adept-iq/adapters/cs-config-model"], function (_exports, _csConfigModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigModel.default.extend({
    namespace: 'config',

    // TODO: remove hack when ember data upgraded
    //it doesn't call the delete API just remove the record form the ember store
    deleteRecord() {}

  });

  _exports.default = _default;
});