define("adept-iq/pods/components/reports-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "klK2UJ+C",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"report-wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"maximized-widget-content\"],[11,\"style\",\"height: calc(100vh - 112px)\"],[9],[0,\"\\n        \"],[1,[27,\"iq-widgets/reportlist-widget\",null,[[\"widget\"],[[23,[\"widget\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/reports-widget/template.hbs"
    }
  });

  _exports.default = _default;
});