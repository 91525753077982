define("adept-iq/models/avlm-event", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    lat: attr('number'),
    lng: attr('number'),
    timestamp: attr('date'),
    code: attr('string'),
    message: attr('string'),
    description: attr('string'),
    providerName: attr('string'),
    createdAt: attr('date'),
    deletedAt: attr('date'),
    updateAt: attr('date'),
    receivedAt: attr('date'),
    heading: attr('number'),
    speed: attr('number'),
    odo: attr('number'),
    actionType: attr('string'),
    avlmVehicle: belongsTo('avlm-vehicle'),
    avlmAlert: belongsTo('avlm-alert'),
    avlmDriver: belongsTo('avlm-driver'),
    avlmRoute: belongsTo('avlm-route'),
    avlmSchedule: belongsTo('avlm-schedule'),
    time: Ember.computed('timestamp', function () {
      return (0, _moment.default)(this.get('timestamp')).format('hh:mm A');
    }),
    latlng: Ember.computed('lat', 'lng', function () {
      if (!Ember.isNone(this.get('lat')) && !Ember.isNone(this.get('lng'))) {
        return [`${this.get('lat')}`, `${this.get('lng')}`];
      }

      return null;
    }),
    eventType: attr('string')
  });

  _exports.default = _default;
});