define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/movingMarker", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/map", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/config/icon-paths", "moment"], function (_exports, _react, _map, _api, _panel, _iconPaths, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_TIMESCALE = 10;
  const MININUM_DURATION = 200;

  const MovingMarker = () => {
    const {
      map,
      setMovingMarkerLatLng
    } = _react.default.useContext(_map.MapContext);

    const {
      eventData
    } = (0, _api.useAPI)();
    const {
      setMovingVehicle,
      movingVehicle,
      sliderIndex,
      setSliderIndex,
      setIsEnded,
      setIsRunning,
      setIsStarted,
      setIsPaused
    } = (0, _panel.usePanel)();

    const markerLabelGenerator = event => {
      let label = 'Vehicle<br/>';
      label = label.bold() + 'Vehicle ID: ' + event.vehicleId + '<br/>';
      label = label + 'Vehicle Speed: ' + event.vehicleSpeed + '<br/>';
      label = label + 'Time:' + event.localTime + '<br/>';
      return label;
    };

    let skippedPointCount = 0;
    const points = eventData.reduce((pointArray, event) => {
      if (event.displayLat !== 'N/A' && event.displayLng !== 'N/A') {
        pointArray.push([Number(event.displayLat), Number(event.displayLng), event.timestamp]); // Fill same gps ping for points without lat/lng

        for (let i = skippedPointCount + 1; i > 1; i--) {
          const skippedPoint = pointArray.at(0 - i);

          if (skippedPoint[0] === null && skippedPoint[1] === null) {
            skippedPoint[0] = Number(event.displayLat);
            skippedPoint[1] = Number(event.displayLng);
          }
        }

        skippedPointCount = 0;
      } else {
        const lastPoint = pointArray.at(-1);
        const lastLat = lastPoint ? lastPoint[0] : null;
        const lastLng = lastPoint ? lastPoint[1] : null;
        pointArray.push([lastLat, lastLng, event.timestamp]);
        if (!lastLat && !lastLng) skippedPointCount++;
      }

      return pointArray;
    }, []);

    _react.default.useEffect(() => {
      if (!map || eventData.length < 2) return;
      if (points.filter(_ref => {
        let [lat, lng] = _ref;
        return lat && lng;
      }).length < 2) return;
      const vehicleIcon = window.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicle,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5]
      });
      const [startLat, startLng, startTimestamp] = points[0];
      const [nextLat, nextLng, nextTimestamp] = points[1];
      let timeDiff = Math.max(((0, _moment.default)(nextTimestamp).valueOf() - (0, _moment.default)(startTimestamp).valueOf()) / DEFAULT_TIMESCALE, MININUM_DURATION);
      const movingMarker = window.L.Marker.movingMarker([[startLat, startLng], [nextLat, nextLng]], [timeDiff], {
        autostart: false,
        loop: false,
        icon: vehicleIcon,
        rotationAngle: null,
        rotationOrigin: '11.5px 11.5px'
      });
      let previousPointsTime = (0, _moment.default)(nextTimestamp).valueOf();

      for (let i = 2; i < points.length; i++) {
        const [lat, lng, timestamp] = points[i];
        const currentPointsTime = (0, _moment.default)(timestamp).valueOf();
        timeDiff = Math.max((currentPointsTime - previousPointsTime) / DEFAULT_TIMESCALE, MININUM_DURATION);
        movingMarker.addLatLng([lat, lng], timeDiff);
        previousPointsTime = currentPointsTime;
      }

      movingMarker.on('lineUpdate', lineUpdate => {
        setSliderIndex(lineUpdate.lineIndex);
        setMovingMarkerLatLng(movingMarker.getLatLng());
      });
      movingMarker.on('end', () => {
        setIsStarted(movingMarker.isStarted());
        setIsRunning(movingMarker.isRunning());
        setIsEnded(movingMarker.isEnded());
        setIsPaused(movingMarker.isPaused()); // set to the last event when ended

        setSliderIndex(eventData.length - 1);
      });
      const popup = markerLabelGenerator(eventData[0]);
      movingMarker.bindPopup(popup, {
        closeButton: true
      });
      movingMarker.addTo(map.native);
      setMovingVehicle(movingMarker);
      return () => {
        map.native.removeLayer(movingMarker);
        setMovingVehicle(null);
        setIsRunning(false);
        setIsStarted(false);
        setIsPaused(false);
        setIsEnded(false);
      };
    }, [eventData]);

    _react.default.useEffect(() => {
      if (sliderIndex >= eventData.length) return;
      const popup = markerLabelGenerator(eventData[sliderIndex]);

      if (movingVehicle) {
        movingVehicle.setPopupContent(popup);
      }
    }, [sliderIndex]);

    return _react.default.createElement(_react.default.Fragment, null);
  };

  var _default = MovingMarker;
  _exports.default = _default;
});