define("adept-iq/classes/map-contexts/base", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(Ember.Evented, {
    isSearchAllowed: false,
    isFilterAllowed: false,
    // can be ignored if unneeded
    isSearchEnabled: false,
    searchText: null,
    // override these in subclasses
    isCenterReadOnly: false,
    centerLat: _environment.default.tomtom.search.center.lat,
    centerLng: _environment.default.tomtom.search.center.lon,
    markers: Ember.computed('', () => []),
    polylines: Ember.computed('', () => []),
    contextMenuOptions: null,
    mapStateProvider: null,
    mapState: Ember.computed.readOnly('mapStateProvider.mapState'),
    onChange: Ember.observer('markers', 'polylines', 'shapes', function () {
      this.trigger('refresh');
    }),

    async init() {
      this._super(...arguments);

      const center = this.get('map.center');

      if (center) {
        if (!this.get('isCenterReadOnly')) {
          this.set('centerLat', center.lat);
          this.set('centerLng', center.lon);
        }
      } // initialize observer


      this.onChange();
    }

  });

  _exports.default = _default;
});