define("adept-iq/utils/config-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateNode = validateNode;

  /*eslint-disable */
  const isValueNumeric = value => {
    return /^-?\d+$/.test(value);
  };
  /*eslint-enable */


  function validateNode(node) {
    const validator = node.validator;
    if (Ember.isEmpty(validator) || Ember.isEmpty(validator.validation)) return true;
    const validation = validator.validation;
    const type = validation.type;
    const value = node.value;
    let retval = false;

    if (type === 'int') {
      const number = Number(value);

      if (isValueNumeric(value) && number >= validation.min && number <= validation.max) {
        retval = true;
      }
    }

    return retval;
  }
});