define("adept-iq/mixins/table-row-value", ["exports", "lodash", "moment"], function (_exports, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    row: null,
    column: {},
    computedValue: Ember.computed('column', 'row', function () {
      const row = this.get('row');
      const column = this.get('column');
      let valuePath = column.get('valuePath');

      if (valuePath && valuePath !== 'record.undefined') {
        return row.get(column.get('valuePath'));
      }

      const modelName = row.get('record.constructor.modelName');
      const configValuePaths = column.get('valuePaths');
      valuePath = configValuePaths.findBy('modelName', modelName).valuePath;
      return row.get(`record.${valuePath}`);
    }),

    sortSolutionPreview(column) {
      const valuePath = column.valuePath;
      const sortOrder = column.ascending ? 'asc' : 'desc';
      const sortType = column.sortType ? column.sortType : 'text';
      const solutionPreviewTable = this.get('solutionPreviewTable');
      const selectedRow = solutionPreviewTable.get('selectedRows.firstObject');
      const rows = solutionPreviewTable.get('rows').toArray();

      const sortedvalues = _lodash.default.orderBy(rows, row => {
        const value = Ember.get(row, valuePath);

        if (sortType === 'number') {
          const currValue = parseInt(value, 10);
          return currValue;
        }

        return value;
      }, sortOrder);

      solutionPreviewTable.setRows(sortedvalues);
      const newSortedRows = this.get('solutionPreviewTable.rows');
      const newSelectedRow = selectedRow ? newSortedRows.findBy('id', selectedRow.get('id')) : null;

      if (newSelectedRow) {
        newSelectedRow.set('selected', true);
      }
    },

    sortImpactPreview(column, tripIds) {
      const valuePath = column.valuePath;
      const sortOrder = column.ascending ? 'asc' : 'desc';
      const sortType = column.sortType ? column.sortType : 'text';
      const sortFormat = column.sortFormat ? column.sortFormat : 'HH:mm';
      const solutionImpactTable = this.get('solutionImpactTable');
      const rows = solutionImpactTable.get('rows').toArray();

      const sortedvalues = _lodash.default.orderBy(rows, row => {
        const value = Ember.get(row, valuePath);

        if (sortType === 'number') {
          const currValue = parseInt(value, 10);
          return currValue;
        } else if (sortType === 'time') {
          const momentValue = value ? (0, _moment.default)(value).format(sortFormat) : '00:00';
          const currValue = (0, _moment.default)(momentValue, sortFormat).unix();
          return currValue;
        }

        return value;
      }, sortOrder);

      const sortedRows = solutionImpactTable.setRows(sortedvalues);
      sortedRows.forEach(stop => {
        if (!Ember.isNone(stop.get('tripStop')) && tripIds.includes(stop.get('tripStop.trip.id'))) {
          stop.set('selected', true);
        }

        if (!Ember.isNone(stop.get('stopPoint')) && tripIds.includes(stop.get('stopPoint.trip.id'))) {
          stop.set('selected', true);
        } //adding redRow class to row for showing ETA value in impact table related to lateness flag


        if (stop.get('stopStatus') === 1) {
          stop.set('classNames', 'redRow');
        }
      });
    }

  });

  _exports.default = _default;
});