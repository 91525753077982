define("adept-iq/pods/components/form-components/vehicleenum/component", ["exports", "adept-iq/mixins/fetchAssignableVehicleDrivers"], function (_exports, _fetchAssignableVehicleDrivers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fetchAssignableVehicleDrivers.default, {
    classNames: ['form-components-select'],
    store: Ember.inject.service(),
    vehicles: null,
    value: null,
    disabled: false,
    extra: null,
    dataFromModel: false,
    // record is dispatchRoute
    options: Ember.computed.alias('extra.options'),
    allowMultiple: Ember.computed.alias('extra.allowMultiple'),
    optionModelName: Ember.computed.alias('extra.optionModelName'),
    onlyAssignableVehicles: Ember.computed.readOnly('extra.onlyAssignableVehicles'),
    optionTravelNeedCounts: Ember.computed.alias('extra.optionTravelNeedCounts'),
    dispatchRoute: Ember.computed.readOnly('record'),
    provider: Ember.computed.readOnly('record.route.provider'),
    //used in fetch vehicle driver mixin
    plannedStartTime: Ember.computed.readOnly('record.replacementStartTime'),
    //used in fetch vehicle driver mixin
    plannedEndTime: Ember.computed.readOnly('record.replacementEndTime'),

    //used in fetch vehicle driver mixin
    init() {
      this._super(...arguments);

      const onlyAssignableVehicles = this.get('onlyAssignableVehicles');

      if (onlyAssignableVehicles) {
        this.get('fetchVehiclesTask').perform();
      }
    },

    availableVehicles: Ember.computed('plannedStartTime', 'plannedEndTime', 'vehicles.[]', function () {
      const onlyAssignableVehicles = this.get('onlyAssignableVehicles');
      if (!onlyAssignableVehicles) return this.get('store').peekAll(this.get('extra.optionModelName'));
      const vehicles = this.get('vehicles');
      if (!vehicles || !vehicles.length) return [];
      return vehicles;
    }),
    selected: Ember.computed('value', 'options', function () {
      if (!Ember.isNone(this.get('extra.optionModelName'))) {
        return this.get('options').find(option => {
          return option.id === this.get('value.id');
        });
      }

      return false;
    }),
    actions: {
      onChange(option) {
        this.get('onChange')(option);
      }

    }
  });

  _exports.default = _default;
});