define("adept-iq/classes/polygons/zone", ["exports", "adept-iq/classes/polygons/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    style: 'zone',
    label: 'Zones',
    parentLabel: 'Zones',
    isActive: true,
    isTraversal: false,
    zonePolygon: Ember.computed.alias('record'),
    lat: Ember.computed.alias('zonePolygon.lat'),
    lng: Ember.computed.alias('zonePolygon.lng'),
    options: Ember.computed.alias('zonePolygon.options'),
    points: Ember.computed.alias('zonePolygon.polypoints'),
    caption: Ember.computed.alias('zonePolygon.name'),
    status: Ember.computed.alias('zonePolygon.status')
  });

  _exports.default = _default;
});