define("adept-iq/pods/components/form-components/dateflatpickr/component", ["exports", "moment", "adept-iq/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-datetimeflatpickr'],
    editModal: Ember.inject.service(),
    value: null,
    disabled: false,
    defaultDate: null,
    minDate: null,

    init() {
      this._super(...arguments);

      const value = this.get('value') || (0, _moment.default)().toDate();
      this.set('minDate', (0, _moment.default)(this.get('value')).startOf('day').toDate());
      this.set('value', value);
      this.set('dateFormat', _environment.default.dateTimeFormat.dateFlatPickr);
      this.set('datePlaceholder', _environment.default.dateTimeFormat.dateMoment);
    },

    actions: {
      onChangeDate(value) {
        this.get('onChange')(value[0]);
      },

      onClose(currentValue, stringValue, datePicker) {
        const errMessage = 'Date entered is invalid.';
        const currentValueMoment = (0, _moment.default)(currentValue[0]);
        const newValueMoment = (0, _moment.default)(stringValue);

        if (!newValueMoment._isValid) {
          this.set('editModal.errors', [errMessage]);
        } else {
          const errors = this.get('editModal.errors') || [];
          this.set('editModal.errors', errors.filter(item => item !== errMessage));
        }

        if (datePicker.config.allowInput && datePicker._input.value && newValueMoment._isValid && !currentValueMoment.isSame(newValueMoment)) {
          datePicker.setDate(newValueMoment.toDate(), true);
        }
      }

    }
  });

  _exports.default = _default;
});