define("adept-iq/tests/factories/driver", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const names = ['Arthur', 'Haze', 'Joseph', 'mcWhiny', 'Dick', 'Grayson', 'Lisa', 'Mayberry'];
  const phoneNumbers = ['234-345-4356', '555-564-6456', '234-565-3452'];

  _emberDataFactoryGuy.default.define('driver', {
    sequences: {
      firstNames: num => names[num % names.length],
      phoneNumbers: num => phoneNumbers[num % phoneNumbers.length],
      externalIds: num => num
    },
    default: {
      driverId: _emberDataFactoryGuy.default.generate('externalIds'),
      firstName: _emberDataFactoryGuy.default.generate('firstNames'),
      middleName: _emberDataFactoryGuy.default.generate('firstNames'),
      lastName: _emberDataFactoryGuy.default.generate('firstNames'),
      travelTimeAdjustment: 5,
      active: true,
      notes: 'some notes',
      preferredVehicles: _emberDataFactoryGuy.default.generate('externalIds'),
      phoneNumber: _emberDataFactoryGuy.default.generate('phoneNumbers')
    }
  });
});