define("adept-iq/pods/components/side-drawer/reconcile-noshow-trip/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editableSections: null,
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    session: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    noshowReason: null,
    noShowTripNote: '',
    errors: null,
    classNames: ['reconcile-widget-base'],
    //computed properties
    selectedTrip: Ember.computed.alias('stackFrame.options.trip'),
    title: Ember.computed.readOnly('stackFrame.options.title'),
    stopDetails: Ember.computed.alias('reconcile.reconcileStops'),
    tripDetails: Ember.computed.alias('reconcile.reconcileTrip'),
    passengerTripDetails: Ember.computed.alias('reconcile.selectedTripRow'),
    reconcileStopPointMap: Ember.computed.alias('reconcile.reconcileStopPointMap'),
    selectedTripId: Ember.computed.alias('reconcile.reconcileSelectedTripId'),
    passengerDetails: Ember.computed.alias('reconcile.reconcilePassenger'),
    callBack: Ember.computed.readOnly('stackFrame.options.action'),
    reconcileTripDate: Ember.computed('reconcile.reconcileDate', function () {
      const reconcileDate = this.get('reconcile.reconcileDate');
      return reconcileDate ? (0, _moment.default)(reconcileDate).format('MM-DD-YYYY') : '';
    }),
    tripDate: Ember.computed('tripDetails.promiseTime', function () {
      const requestTime = this.get('tripDetails.promiseTime');
      return requestTime ? (0, _moment.default)(requestTime).format('hh:mm A') : '';
    }),
    canUndo: Ember.computed.or('selectedReason', 'noShowTripNote', 'selectedDateTimeRage.date', 'shiftStartTimeRange.starttime'),
    canSave: Ember.computed.and('selectedReason', 'shiftStartTimeRange.starttime'),
    reasonOptions: Ember.computed('selectedReason', function () {
      const noShowReasons = this.store.peekAll('cs-config-item').filter(message => {
        return message.category === 'config-System_Configuration-no_show_reason_codes';
      });
      return noShowReasons.map(reason => reason.displayName);
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'selectedDateTimeRage', {
        today: (0, _moment.default)().hours(0).minutes(0).format('MM-DD-YYYY'),
        date: null,
        starttime: null,
        endtime: null,
        startforendtime: (0, _moment.default)().startOf('day').toISOString(),
        startofday: (0, _moment.default)().startOf('day').toISOString(),
        endofday: (0, _moment.default)().endOf('day').toISOString(),
        endDateSelection: (0, _moment.default)().subtract(7, 'years').format('MM-DD-YYYY')
      });
      Ember.set(this, 'shiftStartTimeRange', {
        today: (0, _moment.default)().hours(0).minutes(0).format('YYYY-MM-DD'),
        date: null,
        starttime: null,
        endtime: null,
        startforendtime: (0, _moment.default)().startOf('day').toISOString(),
        startofday: (0, _moment.default)().startOf('day').toISOString(),
        endofday: (0, _moment.default)().endOf('day').toISOString(),
        endDateSelection: (0, _moment.default)().subtract(7, 'years').format('YYYY-MM-DD')
      });
      this.set('pickAddress', this.getSelectedPickOrDropAddress('pick'));
      this.set('dropAddress', this.getSelectedPickOrDropAddress('drop'));
    },

    didInsertElement() {
      this._super(...arguments);

      this.setSideDrawerWidth('470px');
      this.get('systemConfig').set('showRightPanel', true);
    },

    getSelectedPickOrDropAddress(type) {
      const reconcileStopPointMap = this.get('reconcileStopPointMap');
      const selectedTripId = this.get('selectedTripId');
      return reconcileStopPointMap[selectedTripId].find(ele => ele.type.toLowerCase() === type);
    },

    saveNoShowTripTask: (0, _emberConcurrency.task)(function* () {
      try {
        const store = this.get('store');
        const reconcileTripAdapter = store.adapterFor('reconcile-trip'); // const date = moment(this.get('selectedDateTimeRage').date).format('MM-DD-YYYY');

        const date = (0, _moment.default)(this.reconcileTripDate).format('MM-DD-YYYY');
        const startTime = (0, _moment.default)(this.get('shiftStartTimeRange').starttime).format('hh:mm a');
        const dt = (0, _moment.default)(date + ' ' + startTime).toISOString();
        const payload = {
          tripId: this.passengerTripDetails.tripId,
          reason: this.get('selectedReason'),
          date: dt,
          note: this.noShowTripNote,
          userId: this.session.data.authenticated.userId
        };
        yield reconcileTripAdapter.patchTrip(payload, 'noshow trip');
      } catch (error) {
        throw error;
      }
    }),

    saveNoShowTrips() {
      const tooltip = this.get('tooltip');
      const title = 'No Show Trip';
      const tip = `No show trip ${this.passengerTripDetails.tripId}?`;
      const successMsg = `SUCCESSFULLY NO SHOWED TRIP ${this.passengerTripDetails.tripId}`;
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          return Ember.get(this, 'saveNoShowTripTask').perform().then(() => {
            this.send('cancel');
            this.get('notifications').success(successMsg);
            this.get('workspace').popState('reconcileNoShowTrip');
            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in no-show trip, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    checkValidations() {
      let isValid = true;
      Ember.set(this, 'errors', []);

      if (!Ember.get(this, 'selectedReason')) {
        isValid = false;
        this.errors.push('Please select reason');
      } // if (!get(this, 'selectedDateTimeRage.date')) {
      //   isValid = false;
      //   this.errors.push('Please select date');
      // }


      if (!Ember.get(this, 'shiftStartTimeRange.starttime')) {
        isValid = false;
        this.errors.push('Please select time');
      }

      return isValid;
    },

    actions: {
      onChangeShiftStartTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());

        if (valuePath === 'shiftStartTimeRange.starttime') {
          this.set('shiftStartTimeRange.startforendtime', momentTime.toISOString());
        }

        if (valuePath === 'shiftStartTimeRange.date') {
          this.set('shiftStartTimeRange.startime', null);
          this.set('shiftStartTimeRange.endtime', null);
          this.set('shiftStartTimeRange.startforendtime', momentTime.startOf('day').toISOString());
          this.set('shiftStartTimeRange.startofday', momentTime.startOf('day').toISOString());
          this.set('shiftStartTimeRange.endofday', momentTime.endOf('day').toISOString());
        }
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());

        if (valuePath === 'selectedDateTimeRage.starttime') {
          this.set('selectedDateTimeRage.startforendtime', momentTime.toISOString());
        }

        if (valuePath === 'selectedDateTimeRage.date') {
          this.set('selectedDateTimeRage.startime', null);
          this.set('selectedDateTimeRage.endtime', null);
          this.set('selectedDateTimeRage.startforendtime', momentTime.startOf('day').toISOString());
          this.set('selectedDateTimeRage.startofday', momentTime.startOf('day').toISOString());
          this.set('selectedDateTimeRage.endofday', momentTime.endOf('day').toISOString());
        }
      },

      onUndoClick() {
        this.set('shiftStartTimeRange.starttime', null);
        this.set('selectedDateTimeRage.date', null);
        this.set('selectedReason', null);
        this.set('noShowTripNote', '');
      },

      onSaveClick() {
        const isValid = this.checkValidations();

        if (isValid) {
          this.saveNoShowTrips();
        }
      },

      onChangeReason(option) {
        this.set('selectedReason', option);
      },

      cancel() {
        if (this.callBack) {
          this.callBack(true);
        }
      }

    }
  });

  _exports.default = _default;
});