define("adept-iq/topic-transforms/vehicle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload
  /*, ENV*/
  ) {
    if (!payload.data || !payload.data.relationships) return payload;
    const {
      availabilities,
      startGaragePlace,
      endGaragePlace
    } = payload.data.relationships;

    if (availabilities) {
      // rename key availabilities -> vehicleAvailabilities
      delete payload.data.relationships.availabilities;
      payload.data.relationships.vehicleAvailabilities = availabilities;
    }

    if (startGaragePlace) {
      delete payload.data.relationships.startGaragePlace;
      payload.data.relationships.startGarage = startGaragePlace;
    }

    if (endGaragePlace) {
      delete payload.data.relationships.endGaragePlace;
      payload.data.relationships.endGarage = endGaragePlace;
    }

    return payload;
  }
});