define("adept-iq/classes/polylines/routes/planned/base", ["exports", "adept-iq/classes/polylines/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // dynamic polylines are expected to change coordinates at any time,
    isDynamic: true,
    // `BasePolyline` overrides
    style: Ember.computed.readOnly('plannedRouteStyle'),
    // local properties
    plannedRouteStyle: Ember.computed('baseRouteStyle', function () {
      return this.get('baseRouteStyle') + 'Planned';
    })
  });

  _exports.default = _default;
});