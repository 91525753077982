define("adept-iq/adapters/dispatch-route", ["exports", "adept-iq/adapters/-dispatch", "adept-iq/config/api-urls", "lodash"], function (_exports, _dispatch, _apiUrls, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // export default DispatchAdapter;
  var _default = _dispatch.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    // not using "ajax" so there won't be conflict with the base's ajax calls
    session: Ember.inject.service(),
    iquxServiceHost: _apiUrls.API.iquxService.host,

    urlForFindRecord(id
    /*modelName*/
    ) {
      //modelName = camelize(modelName);
      const model = 'dispatchRoute';
      const iquxServiceHost = this.get('iquxServiceHost');
      const baseUrl = `${iquxServiceHost}/${model}/${id}`;
      return baseUrl;
    },

    // TODO: This is a temporary solution for creating a route
    // dispatch backend will have a proper endpoint for POST route
    buildURL: function (modelType, id, snapshot, requestType) {
      if (requestType === 'createRecord') {
        return `${this.host}/schedule/${snapshot.record.get('schedule.id')}/route/clusterization`;
      }

      return this._super(...arguments);
    },

    createDispatchRoute(routePayload, dispatchRoute, dispatchSchedule) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const {
        providerName
      } = routePayload.data.relationships;
      const json = {
        data: {
          type: 'dispatchRoute',
          attributes: {
            lockedForOptimization: false,
            schedulingMode: dispatchRoute.get('schedulingMode'),
            affinities: dispatchRoute.get('affinities')
          },
          relationships: {
            route: {
              data: {
                id: routePayload.data.id,
                type: 'route'
              }
            },
            dispatchSchedule: {
              data: {
                id: dispatchSchedule.get('id'),
                type: 'dispatchSchedule'
              }
            },
            providerName: {
              data: {
                id: providerName.data.id,
                type: 'providerName'
              }
            }
          }
        }
      };
      return ajax.post(_apiUrls.API.dispatchService.host + '/dispatch-route/', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    updateDispatchRoute(dispatchRoute) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const providerId = dispatchRoute.get('provider.id');
      const dispatchRouteId = dispatchRoute.get('id');
      const dispatchRouteVersion = dispatchRoute.get('version');
      const json = {
        data: {
          id: dispatchRouteId,
          type: 'dispatchRoute',
          attributes: {
            lockedForOptimization: false,
            schedulingMode: dispatchRoute.get('schedulingMode'),
            affinities: dispatchRoute.get('affinities'),
            version: dispatchRouteVersion
          },
          relationships: {
            providerName: {
              data: {
                id: providerId,
                type: 'providerName'
              }
            }
          }
        }
      };
      return ajax.post(_apiUrls.API.dispatchService.host + `/dispatch-route/${dispatchRouteId}`, {
        method: 'PATCH',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    createDriverBreak(dispatchRoute, plannedRouteOrdinal, routeId, dispatchRouteVersion) {
      const session = this.get('session');
      const json = {
        data: {
          type: 'stopPoint',
          attributes: {
            routeOrdinal: plannedRouteOrdinal
          },
          relationships: {
            routeBreak: {
              data: {
                id: routeId,
                type: 'routeBreak'
              }
            }
          }
        }
      };

      if (dispatchRouteVersion) {
        json.data.relationships.dispatchRoute = {
          //the function "createDriverBreak" would call from different sources. as we are adding versioning gradually to the services and functions
          // we want to be sure that we dont block other calling operation. so i add this "IF" to include this relation only if we provide the version.
          data: {
            type: 'dispatchRoute',
            id: dispatchRoute.get('id')
          }
        };
        json.included = [{
          type: 'dispatchRoute',
          id: dispatchRoute.get('id'),
          attributes: {
            version: dispatchRouteVersion
          }
        }];
      }

      return this.get('ajaxService').post(_apiUrls.API.dispatchService.host + `/dispatch-route/${dispatchRoute.get('id')}/break`, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      }).catch(_ref => {
        let {
          payload
        } = _ref;
        const error = new Error();
        if (payload.message) error.message = payload.message;
        if (payload.status) error.status = payload.status;
        if (payload.statusCode) error.status = payload.statusCode;
        throw error;
      });
    },

    removeDriverBreak(dispatchRouteId, breakId, dispatchRouteVersion) {
      const session = this.get('session');
      const json = {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        }
      };

      if (dispatchRouteVersion) {
        json.data = {
          data: {
            type: 'stopPoint',
            id: breakId,
            relationships: {
              dispatchRoute: {
                data: {
                  type: 'dispatchRoute',
                  id: dispatchRouteId
                }
              }
            }
          },
          included: [{
            type: 'dispatchRoute',
            id: dispatchRouteId,
            attributes: {
              version: dispatchRouteVersion
            }
          }]
        };
      }

      return this.get('ajaxService').post(_apiUrls.API.dispatchService.host + `/dispatch-route/${dispatchRouteId}/break/${breakId}`, json).catch(_ref2 => {
        let {
          payload
        } = _ref2;
        const error = new Error();
        if (payload.message) error.message = payload.message;
        if (payload.status) error.status = payload.status;
        if (payload.statusCode) error.status = payload.statusCode;
        throw error;
      });
    },

    putRouteBreak(routeBreak) {
      const session = this.get('session');
      const dispatchRouteId = routeBreak.get('route.dispatchRoute.id');
      const dispatchRouteVersion = routeBreak.get('route.dispatchRoute.version');
      const json = {
        method: 'PUT',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        }
      };

      if (dispatchRouteVersion) {
        json.data = {
          data: {
            type: 'stopPoint',
            id: routeBreak.id,
            relationships: {
              dispatchRoute: {
                data: {
                  type: 'dispatchRoute',
                  id: dispatchRouteId
                }
              }
            }
          },
          included: [{
            type: 'dispatchRoute',
            id: dispatchRouteId,
            attributes: {
              version: dispatchRouteVersion
            }
          }]
        };
      }

      return this.get('ajaxService').put(`${_apiUrls.API.dispatchService.host}/dispatch-route/${dispatchRouteId}/break/${routeBreak.id}?ordinal=${routeBreak.get('plannedRouteOrdinal')}`, json).catch(_ref3 => {
        let {
          payload
        } = _ref3;
        const error = new Error();
        if (payload.message) error.message = payload.message;
        if (payload.status) error.status = payload.status;
        if (payload.statusCode) error.status = payload.statusCode;
        throw error;
      });
    },

    postBreakdown(dispatchRoute, breakdownPlaceId, vehicle) {
      const session = this.get('session');
      const firstUnDepartedStop = dispatchRoute.get('firstUnDepartedStop'); // edge case a user decides to mark a vehicle breakdown on a route with no trips

      const breakdownOrdinal = firstUnDepartedStop ? firstUnDepartedStop.get('routeOrdinal') : 1;
      const json = {
        type: 'breakdown',
        attributes: {
          vehicleId: vehicle.get('id'),
          description: vehicle.get('breakdownDescription'),
          breakdownTypeName: vehicle.get('breakdownType.id'),
          breakdownOrdinal,
          breakdownTime: vehicle.get('breakdownTime'),
          breakdownPlaceId: breakdownPlaceId
        },
        relationships: {
          dispatchRoute: {
            data: {
              type: 'dispatchRoute',
              id: dispatchRoute.id
            }
          }
        }
      };
      const included = [{
        type: 'dispatchRoute',
        id: dispatchRoute.id,
        attributes: {
          version: dispatchRoute.version
        }
      }];

      if (firstUnDepartedStop && firstUnDepartedStop.get('promisedTime')) {
        json.attributes.pickupTime = firstUnDepartedStop.get('promisedTime');
      }

      return this.get('ajaxService').post(_apiUrls.API.dispatchService.host + `/dispatch-route/${dispatchRoute.id}/breakdown`, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json,
          included: included
        }
      }).catch(_ref4 => {
        let {
          payload
        } = _ref4;
        const error = new Error();
        if (payload.message) error.message = payload.message;
        if (payload.status) error.status = payload.status;
        if (payload.statusCode) error.status = payload.statusCode;
        throw error;
      });
    },

    formatSpecialRoutePayload(dispatchRoute
    /**breakdownPlace = null*/
    ) {
      const included = [];
      const data = {
        data: {
          type: 'specialRoute',
          attributes: {
            name: 'a route name',
            routeType: 'breakdown replacement',
            shiftStartTime: '2019-06-27T07:00:00-07:00',
            shiftEndTime: '2019-06-27T17:00:00-07:00'
          },
          relationships: {}
        }
      };
      data.data.attributes.name = dispatchRoute.get('replacementRouteId');
      data.data.attributes.shiftStartTime = dispatchRoute.get('replacementStartTime');
      data.data.attributes.shiftEndTime = dispatchRoute.get('replacementEndTime');

      if (dispatchRoute.get('provider.id')) {
        data.data.relationships.providerName = {
          data: {
            id: dispatchRoute.get('provider.id'),
            type: 'providerName'
          }
        };
      }

      if (dispatchRoute.get('dispatchSchedule.id')) {
        data.data.relationships.dispatchSchedule = {
          data: {
            id: dispatchRoute.get('dispatchSchedule.id'),
            type: 'dispatchSchedule'
          }
        };
      }

      if (dispatchRoute.get('replacementDriver.id')) {
        data.data.relationships.driver = {
          data: {
            id: dispatchRoute.get('replacementDriver.id'),
            type: 'driver'
          }
        };
      }

      if (dispatchRoute.get('replacementVehicle.id')) {
        data.data.relationships.vehicle = {
          data: {
            id: dispatchRoute.get('replacementVehicle.id'),
            type: 'vehicle'
          }
        };
      }

      if (dispatchRoute.get('id')) {
        data.data.relationships.replacedRoute = {
          data: {
            id: dispatchRoute.get('id'),
            type: 'dispatchRoute'
          }
        };
        included.push({
          type: 'dispatchRoute',
          id: dispatchRoute.get('id'),
          attributes: {
            version: dispatchRoute.get('version')
          }
        });
      } // starting location should be where the dispatcher marked the breakdown at
      // if not found, we use the start garage
      // Update: AIQ-3880 we don't want to update vehicle start/end garage so remove garage relationships.


      if (included.length) data.included = included;
      return data;
    },

    postSpecialRoute(dispatchRoute) {
      let breakdownPlace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      const session = this.get('session');
      const data = this.formatSpecialRoutePayload(dispatchRoute, breakdownPlace);
      const json = JSON.stringify(data);
      return this.get('ajaxService').post(_apiUrls.API.dispatchService.host + '/special-route', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    postSupervisor(body) {
      const session = this.get('session');
      const json = JSON.stringify(body);
      return this.get('ajaxService').post(_apiUrls.API.dispatchService.host + '/special-route', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    transferAllTripsToRouteFunction(dispatchRoute, newDispatchRoute, remainingStopPoints) {
      let ignoreCapacityViolations = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      const data = {
        data: {
          type: 'recipe',
          attributes: {
            instructions: []
          }
        }
      };

      function formatBulkTransfer(remainingStops, sourceId, destinationId) {
        let newOrdinal = 1;
        remainingStops.forEach(stop => {
          data.data.attributes.instructions.push({
            id: stop.id,
            type: 'stop',
            source: sourceId,
            destination: destinationId,
            ordinal: newOrdinal
          });
          newOrdinal++;
        });
      }

      return new Promise((resolve, reject) => {
        formatBulkTransfer(remainingStopPoints, dispatchRoute.id, newDispatchRoute.data.id);
        const bulkOperationAdapter = this.get('store').adapterFor('bulk-operation');
        return bulkOperationAdapter.createBulkAsyncOperation.perform({
          instructions: data.data.attributes.instructions
        }, ignoreCapacityViolations).then(result => {
          if (result.state === 'failure') {
            reject(result);
          } else {
            return resolve(null);
          }
        }).catch(function (error) {
          reject(error);
        });
      });
    },

    manualTripTransferForDispatch(routeId, tripId, pickOrdinal, dropOrdinal, ignoreCapacityViolation) {
      const session = this.get('session');
      const url = `${_apiUrls.API.dispatchService.host}/dispatch-route/${routeId}/tripTransfer/${tripId}?pickOrdinal=${pickOrdinal}&dropOrdinal=${dropOrdinal}&ignoreCapacityViolations=${ignoreCapacityViolation}`;
      return this.get('ajaxService').put(url, {
        method: 'PUT',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    async getDispatchRoute(dispatchRouteId) {
      const session = this.get('session');
      const url = `${_apiUrls.API.dispatchService.host}/dispatch-route/${dispatchRouteId}`;
      return this.get('ajaxService').request(url, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    async getVersion(routeId) {
      const session = this.get('session');
      const url = `${_apiUrls.API.dispatchService.host}/dispatch-route/${routeId}`;
      const response = await this.get('ajaxService').request(url, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        }
      });

      const version = _lodash.default.get(response, 'data.attributes.version', null);

      return version;
    },

    async updateVersion(routeId, version) {
      const session = this.get('session');
      const url = `${_apiUrls.API.dispatchService.host}/dispatch-route/${routeId}`;
      const data = {
        data: {
          id: routeId,
          type: 'dispatchRoute',
          attributes: {
            version: version
          }
        }
      };
      const response = await this.get('ajaxService').patch(url, {
        method: 'PATCH',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data
      });
      return response;
    },

    async syncAVLM(dispatchRouteId) {
      const session = this.get('session');
      const url = `${_apiUrls.API.dispatchService.host}/dispatch-route/${dispatchRouteId}/sync`;
      return await this.get('ajaxService').request(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    async fetchStopPoints(routeId) {
      const session = this.get('session');
      const url = `${_apiUrls.API.dispatchService.host}/dispatch-route/${routeId}?include=clusters,clusters.stopPoints`;
      let stopPoints = [];

      try {
        const response = await this.get('ajaxService').request(url, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${session.data.authenticated.token}`
          }
        });
        stopPoints = stopPoints.concat(response.included.filter(s => s.type === 'stopPoint'));
        stopPoints.sort((a, b) => {
          const ordinalA = a.attributes && a.attributes.routeOrdinal ? a.attributes.routeOrdinal : 0;
          const ordinalB = b.attributes && b.attributes.routeOrdinal ? b.attributes.routeOrdinal : 0;
          return ordinalA - ordinalB;
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      return stopPoints;
    }

  });

  _exports.default = _default;
});