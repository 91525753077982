define("adept-iq/utils/convertDateTime", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertTo33Hour = _exports.convert33HourToDateTime = void 0;

  function pad(num, size) {
    let numString = num.toString();

    while (numString.length < size) numString = '0' + numString;

    return numString;
  }

  const convertTo33Hour = requestTime => {
    const requestTimeMoment = (0, _moment.default)(requestTime);
    const hours = requestTimeMoment.hours();
    const minutes = requestTimeMoment.minutes();
    const currentDay = (0, _moment.default)().startOf('day');
    requestTimeMoment.startOf('day');
    const diff = requestTimeMoment.diff(currentDay, 'days');
    const multiplier = diff > 0 ? diff - 1 : 0;
    const convertedHours = hours + multiplier * 24;
    return `${pad(convertedHours, 2)}:${pad(minutes, 2)}`;
  };

  _exports.convertTo33Hour = convertTo33Hour;

  const convert33HourToDateTime = requestTime => {
    const timeArray = requestTime.split(':');
    const today = (0, _moment.default)().startOf('day');
    today.add(timeArray[0], 'hours');
    today.add(timeArray[1], 'minutes');
    return today.toISOString();
  };

  _exports.convert33HourToDateTime = convert33HourToDateTime;
});