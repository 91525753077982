define("adept-iq/pods/components/iq-widgets/passengers-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/passengers-form-widget/config", "moment"], function (_exports, _component, _config, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editModal: Ember.inject.service(),
    notifications: Ember.inject.service(),
    editableSections: _config.default.editableSections,
    classNames: ['passenger-form-widget', 'data-test-passenger-form-widget'],

    init() {
      this._super(...arguments);
    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const eligibilities = record.riderEligibilities || [];
        const riderTravelNeeds = record.riderTravelNeeds;
        const newTravelNeeds = record.newTravelNeeds || {};
        const riderPhoneNumbers = record.riderPhoneNumbers || [];

        if (Ember.isEmpty(record.firstName) || Ember.isEmpty(record.lastName) || record.firstName.length === 0 || record.lastName.length === 0) {
          valid = false;
          this.get('editModal.errors').pushObject('First name and last name cannot be blank.');
        }

        riderPhoneNumbers.forEach(phoneNumber => {
          if (Ember.isBlank(phoneNumber.get('areaCode')) || Ember.isBlank(phoneNumber.get('phoneNumber'))) {
            valid = false;
            this.get('editModal.errors').pushObject('Phone number cannot be blank.');
          }
        });

        if (riderTravelNeeds.length <= 0) {
          const found = newTravelNeeds.find(travelNeed => {
            if (!Ember.isNone(travelNeed.get('count')) && travelNeed.get('count') > 0) {
              return true;
            }

            return false;
          });

          if (!found) {
            valid = false;
            this.get('editModal.errors').pushObject('Must have at least 1 travel need.');
          }
        } else {
          const found = riderTravelNeeds.find(travelNeed => {
            if (!Ember.isNone(travelNeed.get('count')) && travelNeed.get('count') > 0) {
              return true;
            }

            return false;
          });

          if (!found) {
            valid = false;
            this.get('editModal.errors').pushObject('Must have at least 1 travel need.');
          }
        }

        eligibilities.forEach(eligibility => {
          const from = (0, _moment.default)(eligibility.from);
          const to = (0, _moment.default)(eligibility.to);

          if (to.isBefore(from)) {
            valid = false;
            this.get('editModal.errors').pushObject('Eligibility to date cannot be before from date');
          }
        });
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        const lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          const records = this.get('editableRecords') || [];
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(_ref => {
            let {
              record,
              properties
            } = _ref;
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onApplyClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
        }
      }

    }
  });

  _exports.default = _default;
});