define("adept-iq/pods/components/rider-management-widget/rider-contact-information/rider-address-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'rider-management-widget/rider-address-table',
    rowComponent: 'table/rows/text-formatted-row',
    rowSelectionType: 'radio',
    modelName: 'rider-place',
    defaultSortAsc: false,
    checkBoxColumn: false,
    isFormControls: true,
    title: 'Addresses',
    columns: [{
      id: 'Type',
      index: 0,
      type: 'text',
      isKey: true,
      label: 'Type',
      valuePath: 'riderPlaceTypeName',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 90
    }, {
      id: 'Name',
      index: 1,
      type: 'text',
      label: 'Name',
      valuePath: 'place.address.alias',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 90,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'Address',
      index: 2,
      type: 'text',
      label: 'Address',
      valuePath: 'place.address.simpleAddress',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 250
    }, {
      id: 'City',
      index: 3,
      type: 'text',
      label: 'City',
      valuePath: 'place.address.locality',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'State',
      index: 4,
      type: 'text',
      label: 'State',
      valuePath: 'place.address.region',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'Zip',
      index: 5,
      type: 'text',
      label: 'Zip',
      valuePath: 'place.address.postalCode',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 70,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'Lat',
      index: 6,
      type: 'text',
      label: 'Lat',
      valuePath: 'place.location.lat',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'Lng',
      index: 7,
      type: 'text',
      label: 'Lng',
      valuePath: 'place.location.lng',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'Zone',
      index: 8,
      type: 'text',
      label: 'Zone',
      valuePath: 'place.address.zoneName.displayName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 50,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }]
  };
  _exports.default = _default;
});