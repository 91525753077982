define("adept-iq/utils/graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.breadthFirstSearch = breadthFirstSearch;

  function breadthFirstSearch(params) {
    const {
      queue = [],
      visited = [],
      getNeighbours,
      visitNode
    } = params;

    while (queue.length > 0) {
      const v = queue.shift();
      if (visitNode) visitNode(v);
      visited.push(v);
      getNeighbours(v).reject(w => visited.includes(w)).forEach(w => queue.push(w));
    }
  }
});