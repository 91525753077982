define("adept-iq/tests/factories/cancel-type", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('cancel-type', {
    default: {
      code: 'C',
      externalCode: 'C',
      description: 'Cancel',
      name: 'Generic Cancel Option'
    }
  });
});