define("adept-iq/pods/components/side-drawer/add-edit-dispatch-route/component", ["exports", "ember-concurrency", "lodash", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/mixins/fetchAssignableVehicleDrivers", "adept-iq/mixins/version-check", "adept-iq/config/environment", "adept-iq/models/route-template", "adept-iq/models/zone"], function (_exports, _emberConcurrency, _lodash, _moment, _unwrapProxy, _fetchAssignableVehicleDrivers, _versionCheck, _environment, _routeTemplate, _zone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NUMBER_FORMAT = /^\d*$/;
  const IN_ACTIVE_STATUS = 'inactive';
  const extraTripLimitOptions = {
    min: 1,
    max: 1
  };
  const extraDistanceLimitOptions = {
    min: 1,
    max: 2
  };

  var _default = Ember.Component.extend(_fetchAssignableVehicleDrivers.default, _versionCheck.default, {
    tagName: '',
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    drivers: null,
    vehicles: null,
    providers: null,
    schedules: null,
    componentName: 'createRoute',
    selectedVehicle: null,
    selectedDriver: null,
    selectedSchedule: null,
    scheduleOption: null,
    onShrinkClick: null,
    onCloseClick: null,
    usePlaceHolderTask: true,
    // used by fetchVehicle Mixin
    dispatchSchedule: null,
    selectedDateTime: null,
    extraDistanceLimitOptions,
    extraTripLimitOptions,
    displayName: Ember.computed.alias('stackFrame.options.displayName'),
    route: Ember.computed.alias('stackFrame.options.routeModel'),
    dispatchRoute: Ember.computed.alias('route.dispatchRoute'),
    routeVehicleDriver: Ember.computed.alias('dispatchRoute.latestRouteVehicleDriver'),
    _rvdStartTime: Ember.computed.alias('dispatchRoute.latestRouteVehicleDriver.startTime'),
    _rvdEndTime: Ember.computed.alias('dispatchRoute.latestRouteVehicleDriver.endTime'),
    _routeStartTime: Ember.computed.alias('route.plannedStartTime'),
    _routeEndTime: Ember.computed.alias('route.plannedEndTime'),
    startTime: Ember.computed('routeVehicleDriver', '_routeStartTime', '_rvdStartTime', {
      get() {
        const startTime = this.get('routeVehicleDriver') ? this.get('_rvdStartTime') : this.get('_routeStartTime');
        return startTime;
      },

      set(key, value) {
        if (this.get('routeVehicleDriver')) {
          this.set('_rvdStartTime', value);
        }

        this.set('_routeStartTime', value);
        return value;
      }

    }),
    endTime: Ember.computed('routeVehicleDriver', '_routeEndTime', '_rvdEndTime', {
      get() {
        const endTime = this.get('routeVehicleDriver') ? this.get('_rvdEndTime') : this.get('_routeEndTime');
        return endTime;
      },

      set(key, value) {
        if (this.get('routeVehicleDriver')) {
          this.set('_rvdEndTime', value);
        }

        this.set('_routeEndTime', value);
        return value;
      }

    }),
    plannedStartTime: Ember.computed.readOnly('startTime'),
    //used in fetch vehicle driver mixin
    plannedEndTime: Ember.computed.readOnly('endTime'),
    //used in fetch vehicle driver mixin
    routeName: Ember.computed.alias('route.name'),
    schedulingMode: Ember.computed.alias('dispatchRoute.schedulingMode'),
    availableVehicles: Ember.computed.readOnly('vehicles'),
    availableDrivers: Ember.computed.readOnly('drivers'),
    provider: Ember.computed.readOnly('selectedProvider'),
    // used by fetchVehicle Mixin
    affinities: Ember.computed.alias('dispatchRoute.affinities'),
    tripLimit: Ember.computed.alias('route.tripLimit'),
    distanceLimit: Ember.computed.alias('route.distanceLimit'),
    isEditMode: Ember.computed.notEmpty('route.id'),
    isNewRoute: Ember.computed.empty('route.id'),
    dateTimeFormat: _environment.default.dateTimeFormat,
    noneDriverModel: null,
    zoneTableRef: null,
    hasAllSelectedValues: Ember.computed('routeName', 'tripLimit', 'distanceLimit', 'selectedProvider', 'selectedSchedule', 'selectedVehicle', 'dispatchRoute.schedulingMode', 'startTime', 'endTime', 'hasZones', 'hasRouteChanged', function () {
      const {
        routeName,
        tripLimit,
        distanceLimit,
        selectedProvider,
        selectedSchedule,
        selectedVehicle,
        startTime,
        endTime,
        hasZones,
        hasRouteChanged
      } = this.getProperties('routeName', 'tripLimit', 'distanceLimit', 'selectedProvider', 'selectedSchedule', 'selectedVehicle', 'startTime', 'endTime', 'hasZones', 'hasRouteChanged');
      const schedulingMode = this.get('dispatchRoute.schedulingMode');
      return routeName && tripLimit && distanceLimit && selectedProvider && selectedSchedule && selectedVehicle && schedulingMode && startTime && endTime && hasZones && hasRouteChanged;
    }),
    allAvailableDrivers: Ember.computed('availableDrivers', 'noneDriverModel', function () {
      const availableDrivers = this.get('availableDrivers') || [];
      const noneDriverModel = this.get('noneDriverModel');

      if (Ember.isPresent(noneDriverModel)) {
        return (0, _lodash.concat)([noneDriverModel], availableDrivers);
      }

      return availableDrivers;
    }),
    selectedProvider: Ember.computed({
      set(key, value) {
        this.set('selectedDriver');
        this.set('selectedVehicle');
        return value;
      }

    }),
    availabilityEndTime: Ember.computed('endTime', 'schedule.end', 'startTime', function () {
      const plannedStart = (0, _moment.default)(this.get('startTime'));
      const plannedEndTime = this.get('endTime') || this.get('schedule.end') || plannedStart.clone().endOf('day');
      return plannedEndTime;
    }),
    availableSchedules: Ember.computed('schedules.[]', 'startTime', 'endTime', function () {
      let schedules = this.get('schedules');
      const plannedStart = (0, _moment.default)(this.get('startTime'));
      const plannedEnd = (0, _moment.default)(this.get('endTime'));
      const minDate = this.get('stackFrame.options.minDate');
      const date = (0, _moment.default)(minDate);
      if (!schedules) return null; // only exported schedules

      schedules = schedules.filter(schedule => {
        const dsSchedule = (0, _unwrapProxy.unwrapProxy)(schedule.get('dispatchSchedule'));
        return Ember.isPresent(dsSchedule);
      }); // make sure any existing schedule fits new route planned times

      const sortedSchedules = schedules.filter(schedule => {
        const start = (0, _moment.default)(schedule.get('start'));
        const end = (0, _moment.default)(schedule.get('end'));

        if (plannedStart.isValid() && plannedEnd.isValid()) {
          return start.isSameOrBefore(plannedStart) && end.isSameOrAfter(plannedEnd);
        } // list all schedules having end later than current.


        return end.isAfter(date);
      }).sortBy('name');
      return sortedSchedules;
    }),
    hasRouteChanged: Ember.computed('selectedVehicle', 'selectedDriver', 'selectedProvider', 'startTime', 'endTime', 'affinities', 'tripLimit', 'distanceLimit', 'schedulingMode', function () {
      if (!this.get('isEditMode')) return true;
      return this.isRouteChanged();
    }),

    isRouteChanged() {
      const {
        selectedProvider,
        selectedVehicle,
        selectedDriver,
        startTime,
        endTime,
        schedulingMode,
        affinities,
        tripLimit,
        distanceLimit
      } = this.getProperties('selectedProvider', 'selectedVehicle', 'selectedDriver', 'startTime', 'endTime', 'schedulingMode', 'affinities', 'tripLimit', 'distanceLimit'); // eslint-disable-next-line no-undefined

      const driverId = Ember.isPresent(selectedDriver) ? selectedDriver.get('id') : undefined;
      const routeInfo = {
        startTime,
        endTime,
        providerId: selectedProvider.get('id'),
        vehicleId: selectedVehicle.get('id'),
        driverId: driverId,
        schedulingMode,
        affinities,
        tripLimit,
        distanceLimit
      };
      const previousRouteInfo = this.get('previousRouteInfo');
      return JSON.stringify(previousRouteInfo) !== JSON.stringify(routeInfo);
    },

    currentSchedule: Ember.computed('availableSchedules', function () {
      const availableSchedules = this.get('availableSchedules') ? this.get('availableSchedules').sortBy('start') : null;

      if (!Ember.isPresent(availableSchedules)) {
        return null;
      }

      const schedule = availableSchedules.firstObject;
      this.onSelScheduleTimeChange(schedule);
      const start = (0, _moment.default)(schedule.get('start'));
      const end = (0, _moment.default)(schedule.get('end'));
      const plannedStart = (0, _moment.default)(this.get('startTime'));
      const plannedEnd = (0, _moment.default)(this.get('availabilityEndTime'));

      if (!(start.isAfter(plannedEnd) || end.isBefore(plannedStart))) {
        return schedule;
      }

      return null;
    }),
    //user must select at least one  zone and selected zone affinity value other than no-service.
    hasZones: Ember.computed('zoneTableRef.table.rows.@each.selected', 'selectedAffinityType.type', function () {
      const zones = this.get('isEditMode') ? this.get('zoneTableRef.table.rows') : this.get('zoneTableRef.checkedRows');
      return Ember.isPresent(zones) && zones.some(zone => zone.get('convertAffinityValue') !== _zone.AFFINITY_NO_SERVICE.type);
    }),

    init() {
      const store = this.get('store');
      const isEditMode = this.get('isEditMode');

      this._super();

      this.set('schedulingModeList', [_routeTemplate.MANUAL_SCHEDULING_MODE, _routeTemplate.AUTOMATIC_SCHEDULING_MODE]);
      this.set('affinityTypes', [_zone.AFFINITY_PREFERRED, _zone.AFFINITY_NORMAL, _zone.AFFINITY_LAST_RESORT, _zone.AFFINITY_NO_SERVICE]);
      const zones = store.peekAll('zone');
      this.set('availableZones', zones);
      this.set('timePlaceHolder', _environment.default.dateTimeFormat.dateTimeMoment);
      this.setDateTimeRange();
      const noneDriverModel = this.get('noneDriverModel');

      if (Ember.isNone(noneDriverModel)) {
        const newRecord = store.createRecord('driver', {
          driverId: 'None'
        });
        this.set('noneDriverModel', newRecord);
      }

      if (isEditMode) {
        this.set('selectedProvider', this.get('route.provider'));
        this.set('selectedVehicle', this.get('route.dispatchRoute.latestRouteVehicleDriver.vehicle'));
        this.set('selectedDriver', this.get('route.dispatchRoute.latestRouteVehicleDriver.driver'));
        this.set('selectedSchedule', this.get('route.schedule'));
        this.set('startTime', this.get('dispatchRoute.plannedStartTime'));
        this.set('endTime', this.get('dispatchRoute.plannedEndTime'));
      }

      this.setPreviousRouteInfo();
    },

    setPreviousRouteInfo() {
      const isEditMode = this.get('isEditMode');
      const currentState = this.get('currentState'); // we want to keep the original previousRouteInfo and not create a new one

      if (currentState && currentState.previousRouteInfo) {
        this.set('previousRouteInfo', currentState.previousRouteInfo);
        return;
      }

      const dispatchRoute = this.get('dispatchRoute');
      const route = this.get('route');
      const previousRouteInfo = {
        startTime: isEditMode ? dispatchRoute.get('plannedStartTime') : route.get('plannedStartTime'),
        endTime: isEditMode ? dispatchRoute.get('plannedEndTime') : route.get('plannedEndTime'),
        providerId: route.get('provider.id'),
        vehicleId: route.get('vehicle.id'),
        driverId: route.get('driver.id'),
        schedulingMode: route.get('dispatchRoute.schedulingMode'),
        affinities: route.get('dispatchRoute.affinities'),
        tripLimit: route.get('tripLimit'),
        distanceLimit: route.get('distanceLimit')
      };
      this.set('previousRouteInfo', previousRouteInfo);
    },

    cleanupNoneDriverModel() {
      const noneDriverModel = this.get('noneDriverModel');

      if (Ember.isPresent(noneDriverModel)) {
        noneDriverModel.destroyRecord();
        this.set('noneDriverModel');
      }
    },

    async willDestroy() {
      this.cleanupNoneDriverModel();

      this._super(...arguments);
    },

    didInsertElement() {
      const isEditMode = this.get('isEditMode');
      this.setSideDrawerWidth('550px');
      this.loadOptionsTask.perform().then(() => {
        if (isEditMode) {
          this.set('selectedDateTime.minDateSelection', this.get('selectedSchedule.start'));
        } else {
          this.set('selectedSchedule', this.get('currentSchedule'));
          this.set('selectedDateTime.minDateSelection', this.get('currentSchedule.start'));
        }
      });
    },

    loadOptionsTask: (0, _emberConcurrency.task)(function* () {
      yield this.fetchDriversTask.perform();
      yield this.fetchDispachAssignableVehiclesTask.perform();
      yield this.fetchProvidersTask.perform();
      yield this.fetchSchedulesTask.perform();
    }),
    fetchProvidersTask: (0, _emberConcurrency.task)(function* () {
      const providers = yield this.store.findAll('provider');
      const userProviders = this.get('session.data.authenticated.tokenInfo.providerNames');
      const userHasAllProviders = userProviders.includes('ALL');
      const filterProviderTypes = providers.filter(provider => {
        const providerName = provider.get('name');
        const ProviderStatus = provider.get('status').toLowerCase();
        return ProviderStatus !== IN_ACTIVE_STATUS && (userHasAllProviders || userProviders.includes(providerName));
      });
      this.set('providers', filterProviderTypes);
    }),
    fetchSchedulesTask: (0, _emberConcurrency.task)(function* () {
      // eslint-disable-next-line
      const filterQuery = `eq(type,'primary')`;
      const schedules = yield this.store.query('schedule', {
        filter: filterQuery
      });
      this.set('schedules', schedules);
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const isEditMode = this.get('isEditMode');
      const {
        selectedProvider,
        selectedVehicle,
        selectedDriver,
        selectedSchedule,
        route
      } = this.getProperties('selectedProvider', 'selectedVehicle', 'selectedDriver', 'selectedSchedule', 'route');
      const store = this.get('store');
      const routeAdapter = store.adapterFor('route');
      const dispatchRouteAdapter = store.adapterFor('dispatch-route');
      const rvdAdapter = store.adapterFor('route-vehicle-driver');
      const dispatchSchedule = (0, _unwrapProxy.unwrapProxy)(selectedSchedule.get('dispatchSchedule'));
      const dispatchRoute = this.get('dispatchRoute');
      const lockRouteAction = this.get('lockRouteAction').bind(this);
      const unlockRouteAction = this.get('unlockRouteAction').bind(this);
      const originalVehicle = dispatchRoute.get('latestRouteVehicleDriver.vehicle');
      const vehicle = selectedVehicle || originalVehicle;
      route.set('provider', selectedProvider);
      route.set('schedule', (0, _unwrapProxy.unwrapProxy)(selectedSchedule));

      try {
        if (isEditMode) {
          lockRouteAction();
          const dispatchRouteResponse = yield dispatchRouteAdapter.updateDispatchRoute(dispatchRoute, dispatchSchedule);
          const newDispatchRouteVersion = dispatchRouteResponse && dispatchRouteResponse.data ? dispatchRouteResponse.data.attributes.version : dispatchRoute.get('version');
          const rvdResponse = yield rvdAdapter.updateRouteVehicleDriver(dispatchRoute, vehicle, selectedDriver, newDispatchRouteVersion);
          unlockRouteAction();
          return rvdResponse;
        }

        route.set('schedulingMode', dispatchRoute.get('schedulingMode'));
        route.set('affinities', dispatchRoute.get('affinities'));

        if (selectedVehicle) {
          route.set('vehicle', selectedVehicle);
        }

        const scheduleRouteResponse = yield routeAdapter.createScheduleRoute(route);
        const dispatchRouteResponse = yield dispatchRouteAdapter.createDispatchRoute(scheduleRouteResponse, dispatchRoute, dispatchSchedule);
        const rvdResponse = yield rvdAdapter.createRouteVehicleDriver(dispatchRouteResponse, route, selectedDriver);
        return rvdResponse;
      } catch (e) {
        unlockRouteAction(); // if dispatch route was updated but rvd patch failed, we need to use the latest version number

        const currentVersion = yield dispatchRouteAdapter.getVersion(dispatchRoute.get('id'));

        if (currentVersion !== dispatchRoute.get('version')) {
          dispatchRoute.set('version', currentVersion);
        }

        throw e;
      }
    }),

    resetSelections() {
      const zoneTableRef = this.get('zoneTableRef');
      const isEditMode = this.get('isEditMode');
      const previousRouteInfo = this.get('previousRouteInfo');

      if (isEditMode) {
        (0, _unwrapProxy.unwrapProxy)(this.get('route')).rollbackAttributes();
        this.set('selectedProvider', this.get('route.provider'));
        this.set('selectedVehicle', this.get('route.dispatchRoute.latestRouteVehicleDriver.vehicle'));
        this.set('selectedDriver', this.get('route.dispatchRoute.latestRouteVehicleDriver.driver'));
        this.set('selectedSchedule', this.get('route.schedule'));
        this.set('affinities', this.get('dispatchRoute.affinities'));
      } else {
        this.setProperties({
          routeName: null,
          selectedVehicle: null,
          selectedDriver: null,
          selectedSchedule: null,
          selectedProvider: null,
          endTime: null,
          errorMessage: null
        });
        this.set('dispatchRoute.affinities', []);
      }

      this.set('startTime', previousRouteInfo.startTime);
      this.set('endTime', previousRouteInfo.endTime);
      this.setDateTimeRange();
      zoneTableRef.refreshData();
      zoneTableRef.onUncheckAll();
      this.setPreviousRouteInfo();
    },

    onSelScheduleTimeChange(schedule) {
      const selectedScheduleStartTime = (0, _moment.default)(schedule.get('start'));
      const plannedStart = (0, _moment.default)(this.get('startTime'));

      if (plannedStart.isBefore(selectedScheduleStartTime)) {
        Ember.run.schedule('afterRender', () => {
          this.set('startTime', selectedScheduleStartTime.toDate());
          this.set('selectedDateTime.startTime', (0, _moment.default)(schedule.get('start')).toISOString());
        });
      }
    },

    setDateTimeRange() {
      const isEditMode = this.get('isEditMode');
      const date = this.get('stackFrame.options.minDate');
      const endDate = this.get('dispatchSchedule.end');
      const startTime = isEditMode ? this.get('dispatchRoute.plannedStartTime') : this.get('route.plannedStartTime');
      const minDate = (0, _moment.default)(date).toISOString() < (0, _moment.default)(startTime).toISOString() ? (0, _moment.default)(date).toISOString() : (0, _moment.default)(startTime).toISOString();
      this.set('selectedDateTime', {
        minDateSelection: minDate,
        maxDateSelection: (0, _moment.default)(endDate).toISOString(),
        startTime: (0, _moment.default)(startTime).toISOString()
      });
    },

    validateSelectedDriver() {
      const isEditMode = this.get('isEditMode');
      const selectedDriver = this.get('selectedDriver');
      const availableDrivers = this.get('availableDrivers');

      if (!isEditMode && Ember.isPresent(selectedDriver) && selectedDriver.get('id')) {
        const driverExist = availableDrivers.find(driver => {
          return driver.get('id') === selectedDriver.get('id');
        });

        if (Ember.isNone(driverExist)) {
          this.set('selectedDriver');
        }
      }
    },

    validateEditRecords(dispatchRoute) {
      const valid = true;
      const route = dispatchRoute.get('route');
      const hasAllSelectedValues = this.get('hasAllSelectedValues'); // make sure we apply the date to the plannedStartTime and plannedEndTime

      const plannedStartTime = (0, _moment.default)(route.get('plannedStartTime'));
      const plannedEndTime = (0, _moment.default)(route.get('plannedEndTime'));
      const firstStopMaxTime = dispatchRoute.get('firstStopMaxTime');
      const lastStopTime = dispatchRoute.get('lastStopTime');
      const routeMaxTimeLimit = dispatchRoute.get('routeMaxTimeLimit');
      const routeTimeDiff = plannedEndTime.diff(plannedStartTime, 'minutes');

      if (firstStopMaxTime && plannedStartTime.isSameOrAfter(firstStopMaxTime)) {
        this.set('errorMessage', 'Route start time cannot be after start time of first Stop');
        return false;
      }

      if (lastStopTime && lastStopTime.isSameOrAfter(plannedEndTime)) {
        this.set('errorMessage', 'Route end time cannot be before end time of last stop');
        return false;
      }

      if (routeTimeDiff > routeMaxTimeLimit) {
        this.set('errorMessage', `Route length of ${routeTimeDiff} exceeds config Route Max Time Limit of ${routeMaxTimeLimit} minutes`);
        return false;
      }

      if (plannedStartTime.isAfter(plannedEndTime)) {
        this.set('errorMessage', 'Start Time cannot be after End Time');
        return false;
      }

      if (hasAllSelectedValues) {
        // reset error message
        this.set('errorMessage');
      } else {
        // show error message, missing properties
        this.set('errorMessage', 'Please fill out all of the fields before saving.');
        return false;
      }

      return valid;
    },

    validateAddRecords() {
      const valid = true;
      const hasAllSelectedValues = this.get('hasAllSelectedValues');
      const routeName = this.get('routeName');
      const plannedStart = this.get('startTime');
      const plannedEndTime = this.get('endTime');
      const routeMaxTimeLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeMaxTimeLimit');
      const routeMaxTimeLimit = routeMaxTimeLimitParam ? parseInt(routeMaxTimeLimitParam.get('value'), 10) : 720; // minutes

      const routeTimeDiff = (0, _moment.default)(plannedEndTime).diff((0, _moment.default)(plannedStart), 'minutes');

      if (routeTimeDiff > routeMaxTimeLimit) {
        this.set('errorMessage', `Route length of ${routeTimeDiff} exceeds config Route Max Time Limit of ${routeMaxTimeLimit} minutes`);
        return false;
      }

      if (!isNaN(parseInt(routeName, 10)) && (parseInt(routeName, 10) > 2147483648 || parseInt(routeName, 10) < 1)) {
        this.set('errorMessage', 'Route ID must be between 1 to 2147483648');
        return false;
      }

      if (plannedStart > plannedEndTime) {
        this.set('errorMessage', 'start time must always be less than the end time');
        return false;
      }

      if (hasAllSelectedValues) {
        // reset error message
        this.set('errorMessage');
      } else {
        // show error message, missing properties
        this.set('errorMessage', 'Please fill out all of the fields before saving.');
        return false;
      }

      return valid;
    },

    actions: {
      onSelectScheduleOption(option) {
        this.set('selectedSchedule', option);
        const scheduleStart = option.get('start');
        this.set('selectedDateTime.minDateSelection', scheduleStart);
      },

      onSelectProviderOption(option) {
        this.set('selectedProvider', option);
        this.fetchDriversTask.perform();
        this.fetchDispachAssignableVehiclesTask.perform();
      },

      onRouteNameInput(name) {
        if (!NUMBER_FORMAT.test(name) || name.length > 10) {
          return this.notifyPropertyChange('routeName');
        }

        this.set('routeName', name);
        return name;
      },

      onAffinitySelect(option) {
        const availableZones = this.get('availableZones');
        const zones = this.get('zoneTableRef.checkedRows');
        const zoneIds = availableZones.map(zone => parseInt(zone.get('id'), 10)).filter(zoneId => Ember.isPresent(zoneId) && !isNaN(zoneId));
        const affinities = this.get('affinities') || [];
        const maxZoneId = Math.max(...zoneIds, affinities.length);
        const newSize = Math.max(maxZoneId, affinities.length);
        const newAffinities = Array(newSize).fill(0);
        this.set('selectedAffinityType', option);
        zones.setEach('affinityValue', option.val); // configured affinities to contain all imaginable zone changes

        affinities.forEach((aff, index) => {
          newAffinities[index] = aff;
        });
        zones.forEach(zone => {
          //affinities index start from [0-16] and zone Ids starts from 1-17
          const zoneId = zone.get('zoneId') - 1;
          newAffinities[zoneId] = option.val;
        });
        this.set('affinities', newAffinities);
      },

      onChangeTime(valuePath, value) {
        Ember.run.debounce(this, 'validateSelectedDriver', 250);
        this.set(valuePath, value[0]);
        const isEditMode = this.get('isEditMode');

        if (!isEditMode) {
          // Clear the schedule selection and reselect it.
          this.set('selectedSchedule', this.get('currentSchedule'));
        }
      },

      refresh() {
        const isEditMode = this.get('isEditMode');
        this.resetSelections();
        this.loadOptionsTask.perform().then(() => {
          if (isEditMode) {
            this.set('selectedDateTime.minDateSelection', this.get('selectedSchedule.start'));
          } else {
            this.set('selectedSchedule', this.get('currentSchedule'));
            this.set('selectedDateTime.minDateSelection', this.get('currentSchedule.start'));
          }
        });
      },

      async save() {
        const isEditMode = this.get('isEditMode');
        const tooltip = this.get('tooltip');
        const saveTask = this.get('saveTask');
        const dispatchRoute = this.get('route.dispatchRoute');
        const routeName = this.get('routeName');
        const notifications = this.get('notifications');
        const title = isEditMode ? 'Edit Route' : 'Add New Route';
        const tip = isEditMode ? `Edit route name ${routeName}` : `Create route name ${routeName}`;

        if (isEditMode) {
          this.set('dispatchRouteToCheck', dispatchRoute);

          const closeCallback = () => {
            this.send('onCloseClick');
          }; // Check dispatchRoute version first


          await this.get('versionCheckOperation').perform(closeCallback.bind(this));

          if (!this.validateEditRecords(dispatchRoute)) {
            return false;
          }
        } else {
          if (!this.validateAddRecords()) {
            // eslint-disable-line no-lonely-if
            return false;
          }
        }

        tooltip.pushConfirmation({
          tip,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return saveTask.perform().then(() => {
              this.onCloseClick();

              if (isEditMode) {
                notifications.success(`Successfully edited route ${routeName}`);
              } else {
                notifications.success(`Successfully created new route ${routeName}`);
              }

              tooltip.reset();
            }).catch(error => {
              let message;

              switch (error.status) {
                case 400:
                  if (Ember.isPresent(error.payload) && Ember.isPresent(error.payload.detail) && error.payload.detail.includes('it is assigned to placeholder')) {
                    message = 'Cannot edit route because vehicle is a placeholder and a driver is assigned. Either select a non-placeholder vehicle or remove driver and try again.';
                  } else {
                    message = 'There was a problem with one of the fields. Please check over the form and try again.';
                  }

                  break;

                case 409:
                  if (error.message === 'Error saving data that must be unique') {
                    message = `The route name ${this.get('routeName')} is already taken, please try another.`;
                  } else if (error.message.includes('modified by another request')) {
                    message = `UNABLE TO UPDATE ROUTE ${this.get('route.name')}, BECAUSE IT HAS BEEN MODIFIED BY ANOTHER REQUEST.`;
                  } else if (error.message.includes('it is a placeholder vehicle') || Ember.isPresent(error.payload) && Ember.isPresent(error.payload.message) && error.payload.message.includes('it is a placeholder vehicle')) {
                    message = 'Cannot edit route because vehicle is a placeholder and a driver is assigned. Either select a non-placeholder vehicle or remove driver and try again.';
                  } else {
                    message = error.message;
                  }

                  break;

                default:
                  message = 'There was a problem creating the route, please try again.'; // eslint-disable-next-line no-console

                  console.error(error);
                  break;
              }

              this.set('errorMessage', message);
              notifications.warning(message);
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => tooltip.reset()
        });
      },

      onCloseClick() {
        this._super(...arguments);

        if (this.get('isEditMode')) {
          (0, _unwrapProxy.unwrapProxy)(this.get('route')).rollbackAttributes();
          (0, _unwrapProxy.unwrapProxy)(this.get('route.dispatchRoute')).rollbackAttributes();
        }

        this.onCloseClick();
      }

    }
  });

  _exports.default = _default;
});