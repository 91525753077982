define("adept-iq/pods/components/iq-widgets/roles-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/roles-widget/config", "adept-iq/config/api-urls", "adept-iq/config/mapped-permIds"], function (_exports, _component, _config, _apiUrls, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['roles-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    editComponent: 'iq-widgets/roles-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({
        id: 'newRole',
        permId: _mappedPermIds.default.newRole,
        name: 'New Role',
        action: () => {
          const store = this.get('store');
          const newModel = store.createRecord(this.get('config.modelName'), {
            permissionList: []
          });
          this.get('workspace').pushState('composeRole', {
            displayName: 'Create Role',
            roles: [newModel]
          }, {
            replaceSelf: true
          });
        }
      });
      this.set('singleActions', [{
        id: 'edit',
        permId: _mappedPermIds.default.editRole,
        name: 'Edit',
        action: async model => {
          const session = this.get('session');
          let permissionList = [];
          const roleName = model.get('roleName');

          if (roleName) {
            permissionList = await this.get('ajax').request(`${_apiUrls.API.ssoService.host}/role/${roleName}/role-permission/`, {
              method: 'GET',
              contentType: 'application/json',
              headers: {
                'Authorization': `Bearer ${session.data.authenticated.token}`
              }
            }).catch(() => []);
          }

          permissionList = permissionList.map(perm => perm.permissionId);
          model.set('permissionList', permissionList);
          this.get('workspace').pushState('composeRole', {
            displayName: 'Edit Role',
            roles: [model]
          }, {
            replaceSelf: true
          });
        }
      }]);
      this.set('bulkActions', [{
        id: 'bulkEdit',
        permId: _mappedPermIds.default.editRole,
        name: 'Bulk Edit',
        action: async models => {
          const session = this.get('session');
          const displayName = models.length > 1 ? 'Edit Roles' : 'Edit Role';
          await models.reduce(async (acu, model) => {
            let permissionList = [];
            const roleName = model.get('roleName');

            if (roleName) {
              permissionList = await this.get('ajax').request(`${_apiUrls.API.ssoService.host}/role/${roleName}/role-permission/`, {
                method: 'GET',
                contentType: 'application/json',
                headers: {
                  'Authorization': `Bearer ${session.data.authenticated.token}`
                }
              }).catch(() => []);
            }

            permissionList = permissionList.map(perm => perm.permissionId);
            model.set('permissionList', permissionList);
            return acu;
          }, Promise.resolve());
          this.get('workspace').pushState('composeRole', {
            displayName,
            roles: models
          }, {
            replaceSelf: true
          });
        }
      }]);
    }

  });

  _exports.default = _default;
});