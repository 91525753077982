define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/portal", ["exports", "react", "react-dom"], function (_exports, _react, _reactDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Portal = void 0;

  const Portal = _ref => {
    let {
      children,
      className = 'root-portal',
      el = 'div'
    } = _ref;

    const [container] = _react.default.useState(() => {
      // This will be executed only on the initial render
      // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
      const _el = document.createElement(el);

      _el.style.display = 'contents';
      return _el;
    });

    _react.default.useEffect(() => {
      container.classList.add(className);
      document.body.appendChild(container);
      return () => {
        document.body.removeChild(container);
      };
    }, []);

    return _reactDom.default.createPortal(children, container);
  };

  _exports.Portal = Portal;
  var _default = Portal;
  _exports.default = _default;
});