define("adept-iq/pods/register/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gliyhbfg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"register-template\"],[9],[0,\"\\n\\n  \"],[7,\"h1\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"invitation\",\"isAdmin\"]]],null,{\"statements\":[[0,\"      Create Admin Account\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Register\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"invitation\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"register-form\",null,[[\"invitation\",\"errors\",\"onSubmit\"],[[27,\"readonly\",[[23,[\"invitation\"]]],null],[27,\"readonly\",[[23,[\"errors\"]]],null],[27,\"route-action\",[\"register\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"An invite code is required to use IQUX.\"],[10],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"Please check your inbox or contact your system adminstrator for support.\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/register/template.hbs"
    }
  });

  _exports.default = _default;
});