define("adept-iq/adapters/reconcile-route", ["exports", "adept-iq/adapters/-reconciliationService"], function (_exports, _reconciliationService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reconciliationService.default.extend({
    buildURL: function (modelType) {
      if (modelType === 'reconcile-route') {
        const host = this.get('host');
        return `${host}/route`;
      }

      return this._super(...arguments);
    },

    getActivityLogs(query) {
      const session = this.get('session');
      var url = this.buildURL('event', null, null, 'query', query);
      return this.get('ajaxService').post(url, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: query
      });
    },

    createActivityLog(routePayload, type) {
      const ajax = this.get('ajaxService');
      const token = this.get('session.data.authenticated.token');
      const json = {
        type: type,
        attributes: routePayload
      };
      var url = this.buildURL('event');

      if (routePayload) {
        return ajax.post(url, {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: {
            data: json
          }
        });
      }
    },

    putRoute(routePayload, type) {
      const ajax = this.get('ajaxService');
      const token = this.get('session.data.authenticated.token');
      const json = {
        type: type,
        attributes: routePayload
      };

      if (routePayload) {
        return ajax.put(this.get('host') + '/route/' + routePayload.id, {
          method: 'PUT',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: {
            data: json
          }
        });
      }
    },

    patchRoute(routePayload, type) {
      const session = this.get('session');
      const json = {
        type,
        attributes: routePayload
      };
      return this.get('ajaxService').patch(this.get('host') + '/route/' + routePayload.id, {
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    },

    postRoute(routePayload, type, replacedRouteId) {
      const token = this.get('session.data.authenticated.token');
      const json = {
        type: type,
        attributes: routePayload
      };
      const url = replacedRouteId ? `/route?oldRouteId=${replacedRouteId}` : '/route';
      return this.get('ajaxService').post(this.get('host') + url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: {
          data: json
        }
      });
    }

  });

  _exports.default = _default;
});