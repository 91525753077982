define("adept-iq/pods/components/road-supervisor-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OibkqdQD",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[1,[27,\"iq-widgets/navigation-widget\",null,[[\"onExitButtonClick\",\"location\",\"bearing\",\"navigationPolyline\",\"isAtDestination\",\"isOffRoute\",\"navigationPolylineLocation\",\"destination\",\"guidance\"],[[27,\"action\",[[22,0,[]],\"onExitButtonClick\"],null],[23,[\"instance\",\"location\"]],[23,[\"instance\",\"bearing\"]],[23,[\"instance\",\"navigationPolyline\"]],[23,[\"instance\",\"isAtDestination\"]],[23,[\"instance\",\"isOffRoute\"]],[23,[\"instance\",\"navigationPolylineLocation\"]],[23,[\"instance\",\"destination\"]],[23,[\"instance\",\"guidanceMessage\"]]]]],false],[0,\"\\n\"],[0,\"\\n\"],[4,\"if\",[[23,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"global-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[11,\"class\",\"road-supervisor-title-container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"road-supervisor-title\"],[9],[0,\"\\n    Navigation To \"],[1,[21,\"address\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-outline-primary btn-close-road-supervisor\"],[9],[0,\"\\n    Exit\\n  \"],[3,\"action\",[[22,0,[]],\"onExitButtonClick\"]],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/road-supervisor-modal/template.hbs"
    }
  });

  _exports.default = _default;
});