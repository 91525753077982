define("adept-iq/serializers/agency-marker", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    JSONAPISerializer
  } = _emberData.default;

  var _default = JSONAPISerializer.extend({
    keyForAttribute: function (attr) {
      return attr;
    },

    keyForRelationship(key) {
      return key;
    },

    serialize(snapshot) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      options.includeId = true;

      const json = this._super(snapshot, options);

      json.data.type = 'travelNeedType';
      return json;
    },

    normalizeResponse: function (store, primaryModelClass, payload) {
      const data = Ember.makeArray(payload.data);
      const newData = data.map(m => {
        const newIcon = window.L.icon({
          icon: m.attributes.options.icon
        });
        m.attributes.options.icon = newIcon;
        return m;
      });
      payload.data = newData;
      return payload;
    }
  });

  _exports.default = _default;
});