define("adept-iq/adapters/-driver", ["exports", "adept-iq/adapters/application", "adept-iq/config/api-urls"], function (_exports, _application, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    defaultSerializer: '-driver',
    host: _apiUrls.API.driverService.host
  });

  _exports.default = _default;
});