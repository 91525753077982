define("adept-iq/pods/components/side-drawer/system-config/ivr/component", ["exports", "ember-changeset", "ember-concurrency", "adept-iq/pods/components/side-drawer/system-config/ivr/messages"], function (_exports, _emberChangeset, _emberConcurrency, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ivr-messages-view'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    disableEditing: true,
    disableBtns: null,
    ivrMessages: null,
    tabOption: null,
    displayOnlyMessages: _messages.displayOnlyMessages,
    displayOnlyTab: Ember.computed('tabOption', function () {
      return this.get('tabOption') === 'display-only-values';
    }),
    initialGreetingMessage: Ember.computed('ivrMessages', function () {
      return this.get('ivrMessages').find(ivrMessage => ivrMessage.get('name') === 'initial_greeting');
    }),
    optionalGreetingMessage: Ember.computed('ivrMessages', function () {
      return this.get('ivrMessages').find(ivrMessage => ivrMessage.get('name') === 'optional_greeting');
    }),
    disclaimerMessage: Ember.computed('ivrMessages', function () {
      return this.get('ivrMessages').find(ivrMessage => ivrMessage.get('name') === 'disclaimer_message');
    }),
    representativePhoneNumberMessage: Ember.computed('ivrMessages', function () {
      return this.get('ivrMessages').find(ivrMessage => ivrMessage.get('name') === 'representative_phone_number');
    }),
    schedulePhoneNumberMessage: Ember.computed('ivrMessages', function () {
      return this.get('ivrMessages').find(ivrMessage => ivrMessage.get('name') === 'schedule_phone_number');
    }),
    tripCancelFailurePhoneNumberMessage: Ember.computed('ivrMessages', function () {
      return this.get('ivrMessages').find(ivrMessage => ivrMessage.get('name') === 'trip_cancel_failure_phone_number');
    }),
    clientHelpMessage: Ember.computed('ivrMessages', function () {
      return this.get('ivrMessages').find(ivrMessage => ivrMessage.get('name') === 'client_help_message');
    }),
    pickupCancelFailureMessage: Ember.computed('ivrMessages', function () {
      return this.get('ivrMessages').find(ivrMessage => ivrMessage.get('name') === 'pickup_cancel_failure_message');
    }),
    disableUndobtn: Ember.computed('ivrMessages.@each.isDirty', 'disableEditing', 'displayOnlyTab', function () {
      const ivrMessages = this.get('ivrMessages') || [];
      const displayOnlyTab = this.get('displayOnlyTab');
      let disableUndoButton = true;
      if (displayOnlyTab) return displayOnlyTab;
      ivrMessages.forEach(ivrMessage => {
        const isDirty = ivrMessage.get('isDirty');

        if (isDirty) {
          disableUndoButton = false;
        }
      });
      this.hasChanges(!disableUndoButton);
      return disableUndoButton;
    }),
    disableSavebtn: Ember.computed('ivrMessages.@each.isDirty', 'disableEditing', 'displayOnlyTab', function () {
      const ivrMessages = this.get('ivrMessages') || [];
      const displayOnlyTab = this.get('displayOnlyTab');
      let disableSaveButton = true;
      if (displayOnlyTab) return displayOnlyTab;
      ivrMessages.forEach(ivrMessage => {
        const isDirty = ivrMessage.get('isDirty');

        if (isDirty) {
          disableSaveButton = false;
        }
      });
      this.hasChanges(!disableSaveButton);
      return disableSaveButton;
    }),

    hasChanges(value) {
      this.set('systemConfig.hasChanges', value);
    },

    async init() {
      this._super(...arguments);

      this.hasChanges = this.get('hasChanges').bind(this);
      this.refreshModelsTask.perform();
    },

    didInsertElement() {
      this._super(...arguments);
    },

    async willDestroy() {
      const ivrMessages = this.get('ivrMessages') || [];
      ivrMessages.forEach(ivrMessage => {
        if (ivrMessage.get('isDirty')) {
          ivrMessage.rollback();
        }
      });

      this._super(...arguments);
    },

    willDestroyElement() {},

    validatePhone(phoneChangeSet, errors, validate) {
      const displayName = phoneChangeSet.get('displayName');
      const value = phoneChangeSet.get('value') || '';
      let returnVal = validate;

      if (value.length > 0 && value.length < 10) {
        phoneChangeSet.addError('value', {
          value: value,
          validation: `${displayName} must have 10 digits`
        });

        if (!Ember.isNone(errors) && !Ember.isNone(validate)) {
          returnVal = false;
          errors.pushObject({
            key: null,
            validation: `${displayName} must have 10 digits`,
            value: ''
          });
        }
      }

      return returnVal;
    },

    validateRecord() {
      const representativePhoneNumberMessage = this.get('representativePhoneNumberMessage');
      const schedulePhoneNumberMessage = this.get('schedulePhoneNumberMessage');
      const tripCancelFailurePhoneNumberMessage = this.get('tripCancelFailurePhoneNumberMessage');
      const tooltip = this.get('tooltip');
      const errors = [];
      let validate = true;
      validate = this.validatePhone(representativePhoneNumberMessage, errors, validate);
      validate = this.validatePhone(schedulePhoneNumberMessage, errors, validate);
      validate = this.validatePhone(tripCancelFailurePhoneNumberMessage, errors, validate);

      if (!validate) {
        tooltip.pushConfirmation({
          title: 'System Configuration',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
      }

      return validate;
    },

    async onSaveIVRConfig() {
      const valid = this.validateRecord();
      const notifications = this.get('notifications');

      if (valid) {
        const ivrMessages = this.get('ivrMessages');

        try {
          ivrMessages.forEach(ivrMessage => {
            if (ivrMessage.get('isDirty')) {
              ivrMessage.save();
            }
          });
          this.resetForm();
          notifications.success('Successfully Saved IVR messages');
        } catch (e) {
          notifications.failure('Failed to save IVR messages');
        }
      }

      return false;
    },

    doUndoIVRConfig() {
      const ivrMessages = this.get('ivrMessages');
      ivrMessages.forEach(ivrMessage => {
        if (ivrMessage.get('isDirty')) {
          ivrMessage.rollback();
        }
      });
      this.resetForm();
      return false;
    },

    resetForm() {
      const disableBtns = {
        new: true,
        edit: false,
        delete: true
      };
      Ember.run.scheduleOnce('afterRender', this, () => this.set('disableBtns', disableBtns));
      this.set('disableEditing', true);
    },

    refreshModelsTask: (0, _emberConcurrency.task)(function* () {
      const systemConfig = this.get('systemConfig');
      const ivrMessages = yield systemConfig.findConfigByCategory('config-System_Configuration-IVR_Messages');
      const changesets = ivrMessages.map(ivrMessage => {
        return new _emberChangeset.default(ivrMessage);
      });
      this.set('ivrMessages', changesets);
      this.hasChanges(false);
    }).drop(),
    actions: {
      onTabChange(options) {
        const {
          newStep,
          w
        } = options;
        const disableEditing = this.get('disableEditing');
        const disableBtns = {
          new: true,
          edit: true,
          delete: true
        };
        this.set('tabOption', newStep);
        w['transition-to'](newStep);

        if (newStep === 'display-only-values') {
          disableBtns.edit = true;
          this.set('disableBtns', disableBtns);
        } else {
          disableBtns.edit = !disableEditing;
          this.set('disableBtns', disableBtns);
        }
      },

      editIVRConfig() {
        const disableBtns = {
          new: true,
          edit: true,
          delete: true
        };
        this.set('disableBtns', disableBtns);
        this.set('disableEditing', false);
      },

      undoIVRConfig() {
        this.doUndoIVRConfig();
      },

      async saveIVRConfig() {
        return this.onSaveIVRConfig();
      },

      onInputChanges() {},

      onPhoneValueChange(record, maskObj) {
        record.set('value', maskObj.unmasked);
        this.validatePhone(record, null);
      }

    }
  });

  _exports.default = _default;
});