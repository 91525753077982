define("adept-iq/pods/components/side-drawer/unperform-drop-stop/component", ["exports", "ember-concurrency", "adept-iq/config/environment", "adept-iq/models/stop-point", "adept-iq/mixins/version-check"], function (_exports, _emberConcurrency, _environment, _stopPoint, _versionCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_versionCheck.default, {
    classNames: ['perform-stop-drawer'],
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    dateTimeFormat: _environment.default.dateTimeFormat,
    // passed in by side-drawer-container component
    onShrinkClick: null,
    onCloseClick: null,
    errorText: null,
    unperformTitle: 'Unperform',
    unperformMessage: 'Unperforming Stop will NOT change Appointment Time.',

    didReceiveAttrs() {
      this._super(...arguments);

      this.get('refreshTask').perform();
    },

    stopPoint: Ember.computed.readOnly('stackFrame.options.stopPoint'),
    dispatchRouteVersionSnapshot: Ember.computed.readOnly('stackFrame.options.dispatchRouteVersionSnapshot'),
    title: Ember.computed.readOnly('stackFrame.options.title'),
    trip: Ember.computed.readOnly('stopPoint.trip'),
    route: Ember.computed.readOnly('stopPoint.route'),
    rider: Ember.computed.readOnly('trip.rider'),
    address: Ember.computed.readOnly('stopPoint.tripStop.segmentStop.place.address'),
    routeVehicleDriver: Ember.computed.readOnly('route.dispatchRoute.latestRouteVehicleDriver'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    isArrived: Ember.computed.equal('stopPoint.computedStatus', _stopPoint.ARRIVE_STOP_STATUS),
    isPerformed: Ember.computed.equal('stopPoint.computedStatus', _stopPoint.PERFORMED_STOP_STATUS),
    canSave: Ember.computed('arrive', 'depart', function () {
      return this.get('arrive') || this.get('depart');
    }),
    refreshTask: (0, _emberConcurrency.task)(function* () {
      // TODO: should this be a no-op?
      const isArrived = this.get('isArrived');
      const isPerformed = this.get('isPerformed');

      if (isArrived) {
        this.set('arrive', isArrived);
      } else if (isPerformed) {
        this.set('arrive', isPerformed);
        this.set('depart', isPerformed);
      }

      yield (0, _emberConcurrency.timeout)(300);
    }).keepLatest(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const stopPoint = this.get('stopPoint');
      const routeExecEventAdapter = store.adapterFor('route-exec-event'); // eslint-disable-next-line no-unused-vars

      let avlmVersion = null; // if user checks the arrive then it also means
      // that the depart is also checked. Send accept
      // to remove both arrive and depart in AVLM

      if (this.get('arrive')) {
        avlmVersion = yield routeExecEventAdapter.createAcceptEvent(stopPoint, avlmVersion);
      } else if (this.get('depart')) {
        avlmVersion = yield routeExecEventAdapter.createAcceptEvent(stopPoint, avlmVersion);
        let arriveTimestamp = stopPoint.get('actualArriveTime');

        if (Ember.isNone(arriveTimestamp)) {
          arriveTimestamp = stopPoint.get('actualDepartTime');
        }

        stopPoint.set('arriveTimestamp', arriveTimestamp); // sending another arrive event will unperform the depart
        // for this stop

        avlmVersion = yield routeExecEventAdapter.createStopArriveEvent(stopPoint, avlmVersion);
      }

      yield this.get('versionUpdateOperation').perform();
    }).drop(),
    actions: {
      noop() {
        return false;
      },

      onRefreshButtonClick() {
        this.get('refreshTask').perform();
      },

      onCancelButtonClick() {
        this.onCloseClick();
      },

      async onPerformButtonClick() {
        const lockRouteAction = this.get('lockRouteAction').bind(this);
        const unlockRouteAction = this.get('unlockRouteAction').bind(this);

        try {
          // Check dispatchRoute version first
          await this.get('versionCheckOperation').perform(this.onCloseClick.bind(this));
          await lockRouteAction(); // Reset error text

          this.set('errorText');
          const title = 'Unperform Drop Stop';
          this.get('tooltip').pushConfirmation({
            tip: 'Unperform Drop stop?',
            title,
            hasOverlay: true,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return this.get('saveTask').perform().then(() => {
                this.get('notifications').success('Unperformed stop successful');
              }).catch(response => {
                const payloadMessage = Ember.get(response, 'payload.message') ? JSON.parse(Ember.get(response, 'payload.message')) : null;
                let messageText = 'Unperformed stop failed';

                if (response.status === 400 || response.status === 500) {
                  if (payloadMessage.errorDetail.includes('Execution version is different')) {
                    messageText = `UNPERFORM STOP FAILED. THE ROUTE (${this.get('route.name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`;
                  }
                }

                this.get('notifications').warning(`${messageText}`);
              }).finally(async () => {
                await unlockRouteAction(0);
                this.get('tooltip').reset();
                return this.onCloseClick();
              });
            },
            secondaryActionText: 'Cancel',
            secondaryAction: async () => {
              await unlockRouteAction(0);
              this.get('tooltip').reset();
              return;
            },
            closeAction: async () => {
              await unlockRouteAction(0);
            }
          });
        } catch (e) {
          await unlockRouteAction();
        }
      }

    }
  });

  _exports.default = _default;
});