define("adept-iq/pods/components/iq-widgets/booking-trips-widget/avlmConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/passengers-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'avlm-rider',
    defaultSortId: 'riderId',
    defaultSortAsc: false,
    title: 'Passengers',
    columns: [{
      id: 'riderId',
      index: 2,
      type: 'uuid',
      isKey: true,
      label: 'ID',
      valuePath: 'id',
      editable: false,
      hidden: false,
      defaultWidth: 30
    }, {
      id: 'firstName',
      index: 0,
      type: 'text',
      label: 'First Name',
      valuePath: 'firstName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 60,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'lastName',
      index: 1,
      type: 'text',
      label: 'Last Name',
      valuePath: 'lastName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 75,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'fullPhoneNumber',
      index: 3,
      type: 'enum',
      label: 'Phone Numbers',
      valuePath: 'formattedPhoneNumbers',
      cellDesc: 'Phone Numbers',
      editable: false,
      hidden: true,
      defaultWidth: 90
    }, {
      id: 'email',
      index: 4,
      type: 'text',
      label: 'Email',
      valuePath: 'email',
      editable: true,
      searchable: true,
      hidden: false
    }, {
      id: 'middleName',
      index: 5,
      type: 'text',
      label: 'Middle Name',
      valuePath: 'middleName',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 75,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }]
  };
  _exports.default = _default;
});