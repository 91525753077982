define("adept-iq/scenarios/subscription-booking-service", ["exports", "ember-data-factory-guy"], function (_exports, _emberDataFactoryGuy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Just for fun, set the log level ( to 1 ) and see all FactoryGuy response info in console
  _emberDataFactoryGuy.Scenario.settings({
    logLevel: 0 // 1 is the max for now, default is 0

  });

  class _default extends _emberDataFactoryGuy.Scenario {
    run() {
      const provider = this.store.peekAll('provider').firstObject;
      const serviceWindow = this.store.peekAll('service-window').firstObject;
      let riders = this.store.peekAll('rider');
      const ridersLength = riders.length;
      const travelNeedType = this.store.peekAll('travel-need-type').firstObject;
      const passengerType = this.store.peekAll('passenger-type').firstObject;
      const fareType = this.store.peekAll('fare-type').firstObject;
      const group = (0, _emberDataFactoryGuy.make)('group');
      riders = riders.slice(ridersLength / 2, ridersLength);
      riders.forEach(rider => {
        let pickPlace = (0, _emberDataFactoryGuy.make)('place');
        let dropPlace = (0, _emberDataFactoryGuy.make)('place');
        let pickAddress = (0, _emberDataFactoryGuy.make)('address', {
          places: [pickPlace]
        });
        let dropAddress = (0, _emberDataFactoryGuy.make)('address', {
          places: [dropPlace]
        });
        (0, _emberDataFactoryGuy.make)('location', {
          address: pickAddress,
          places: [pickPlace]
        });
        (0, _emberDataFactoryGuy.make)('location', {
          address: dropAddress,
          places: [dropPlace]
        });
        const subscription = (0, _emberDataFactoryGuy.make)('subscription', {
          provider,
          origin: pickPlace,
          destination: dropPlace,
          serviceWindow,
          rider,
          fareType
        });
        const booking = (0, _emberDataFactoryGuy.make)('booking', {
          provider,
          group,
          subscription
        });
        const leg = (0, _emberDataFactoryGuy.make)('leg', {
          booking,
          serviceWindow,
          rider
        });
        (0, _emberDataFactoryGuy.make)('subscription-group', {
          subscription
        });
        (0, _emberDataFactoryGuy.make)('subscription-travel-need', {
          travelNeedType,
          passengerType,
          subscription
        });
        (0, _emberDataFactoryGuy.make)('subscription-exclusion', {
          subscription
        });
        (0, _emberDataFactoryGuy.make)('subscription-recurrence-pattern', {
          subscription
        });
        (0, _emberDataFactoryGuy.make)('generated-subscription-booking', {
          subscription
        });
        (0, _emberDataFactoryGuy.make)('leg-travel-need', {
          leg,
          travelNeedType,
          passengerType
        });
        const segments = (0, _emberDataFactoryGuy.makeList)('segment', 3, {
          leg,
          fareType
        });
        segments.forEach(segment => {
          pickPlace = (0, _emberDataFactoryGuy.make)('place');
          dropPlace = (0, _emberDataFactoryGuy.make)('place');
          pickAddress = (0, _emberDataFactoryGuy.make)('address', {
            places: [pickPlace]
          });
          dropAddress = (0, _emberDataFactoryGuy.make)('address', {
            places: [dropPlace]
          });
          (0, _emberDataFactoryGuy.make)('location', {
            address: pickAddress,
            places: [pickPlace]
          });
          (0, _emberDataFactoryGuy.make)('location', {
            address: dropAddress,
            places: [dropPlace]
          });
          const pickSegmentStop = (0, _emberDataFactoryGuy.make)('segment-stop', 'pick', {
            pickSegment: segment,
            place: pickPlace
          });
          const dropSegmentStop = (0, _emberDataFactoryGuy.make)('segment-stop', 'drop', {
            dropSegment: segment,
            place: dropPlace
          });
          (0, _emberDataFactoryGuy.make)('segment-stop-phone-number', {
            segmentStop: pickSegmentStop
          });
          (0, _emberDataFactoryGuy.make)('segment-stop-phone-number', {
            segmentStop: dropSegmentStop
          });
        });
      });
    }

  }

  _exports.default = _default;
});