define("adept-iq/pods/components/iq-widgets/schedule-column-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/schedule-column-widget/config", "adept-iq/utils/sorts", "adept-iq/utils/unwrapProxy", "adept-iq/utils/filters"], function (_exports, _component, _config, _sorts, _unwrapProxy, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SCHEDULE_MODEL = 'schedule';

  var _default = _component.default.extend({
    classNames: ['analytics-widget', 'schedule-column-widget'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    table: null,
    config: _config.default,
    currentScheduleId: Ember.computed.readOnly('workspace.currentSchedule.id'),
    queryParams: Ember.computed('currentScheduleId', function () {
      const currentScheduleId = this.get('currentScheduleId');
      return {
        filter: `eq(id,${currentScheduleId})?include=metric`
      };
    }),

    init() {
      this._super(...arguments);

      this.set('isSearchAllowed', false);
    },

    // @FIXME: using an observer is a band aid, and there could be a way to refactor this
    //after the reoptimazition need to refresh the metric widget
    scheduleSuccessfullyReoptimized: Ember.observer('workspace.reoptimizeInProgress', function () {
      if (!this.get('workspace.reoptimizeInProgress')) {
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['metric']);
      }
    }),

    fetchDataQuery() {
      const params = {
        sorts: []
      };

      try {
        // this only applies for server-side pagination
        if (this.get('paginationEnabled')) {
          params.page = {
            offset: this.get('records.length'),
            limit: this.get('limit')
          };
        }

        const sort = this.buildSortParam();

        if (sort) {
          params.sorts.push(sort);
        }

        const includes = this.get('defaultIncludes');

        if (!Ember.isEmpty(includes)) {
          params.includes = includes;
        }

        const filters = this.setupFilterType();
        params.filter = (0, _filters.buildCompoundFilterNode)('and', filters);
        const modelName = SCHEDULE_MODEL;
        return this.query(modelName, params);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Workspace view is not refreshed on selecting or deselecting' + error);
      }
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      let activeData = store.peekAll(modelName) || [];

      if (Ember.isPresent(this.get('queryParams'))) {
        const queryParams = this.get('queryParams');
        activeData = await store.query(modelName, queryParams);

        if (Ember.isPresent(activeData)) {
          await activeData.firstObject.get('metric').reload();
          const metric = (0, _unwrapProxy.unwrapProxy)(activeData.firstObject.get('metric'));
          activeData = Ember.isPresent(metric) ? Ember.makeArray(metric) : [];
        }
      }

      const filteredData = activeData.filter(filterFn).sort(compareFn);
      return filteredData;
    },

    actions: {
      //NYAAR-17671  widgets are not maximized and cross icon should also not display in this widgets.
      onHeaderDoubleClick() {
        const maximizer = this.get('maximizer');
        maximizer.minimize();
      }

    }
  });

  _exports.default = _default;
});