define("adept-iq/pods/components/reconcile-search-passenger/active-passenger-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});