define("adept-iq/models/route-template", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ROUTE_TEMPLATE_TYPE_REGULAR = _exports.ROUTE_TEMPLATE_TYPE_KEEPER = _exports.MANUAL_SCHEDULING_MODE = _exports.CONSOLIDATE_ROUTE_FLAG = _exports.AUTOMATIC_SCHEDULING_MODE = _exports.ADJUST_BREAK_FLAG = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  // scheduling modes
  const MANUAL_SCHEDULING_MODE = 'manual';
  _exports.MANUAL_SCHEDULING_MODE = MANUAL_SCHEDULING_MODE;
  const AUTOMATIC_SCHEDULING_MODE = 'automatic'; // route types

  _exports.AUTOMATIC_SCHEDULING_MODE = AUTOMATIC_SCHEDULING_MODE;
  const ROUTE_TEMPLATE_TYPE_KEEPER = 'keeper';
  _exports.ROUTE_TEMPLATE_TYPE_KEEPER = ROUTE_TEMPLATE_TYPE_KEEPER;
  const ROUTE_TEMPLATE_TYPE_REGULAR = 'regular'; // optimization flag bit fields

  _exports.ROUTE_TEMPLATE_TYPE_REGULAR = ROUTE_TEMPLATE_TYPE_REGULAR;
  const ADJUST_BREAK_FLAG = 0b00000001;
  _exports.ADJUST_BREAK_FLAG = ADJUST_BREAK_FLAG;
  const CONSOLIDATE_ROUTE_FLAG = 0b00000010; // model validation

  _exports.CONSOLIDATE_ROUTE_FLAG = CONSOLIDATE_ROUTE_FLAG;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: '{description} cannot be blank'
      }), (0, _emberCpValidations.validator)('length', {
        max: 32
      }), (0, _emberCpValidations.validator)('unique-route-template-name', {
        message: 'Route name cannot duplicate an existing route name.'
      })]
    },
    tripLimit: {
      description: 'Trip Limit',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 3,
        message: '{description} maximum number allowed is 999'
      })]
    },
    distanceLimit: {
      description: 'Distance Limit',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 4,
        message: '{description} maximum number allowed is 9999'
      })]
    }
  });

  var _default = Model.extend(Validations, {
    name: attr('string'),
    schedulingMode: attr('string', {
      defaultValue: AUTOMATIC_SCHEDULING_MODE
    }),
    type: attr('string', {
      defaultValue: ROUTE_TEMPLATE_TYPE_REGULAR
    }),
    notes: attr('string'),
    tripLimit: attr('number'),
    distanceLimit: attr('number'),
    active: attr('boolean'),
    optimizationFlags: attr('number', {
      defaultValue: 3
    }),
    // bit field
    affinities: attr(),
    // string array ex "[1,2,3,4]"
    availabilities: hasMany('route-template-availability'),
    routes: hasMany('route'),
    isManualTrips: Ember.computed.equal('schedulingMode', MANUAL_SCHEDULING_MODE),
    isKeeperType: Ember.computed.equal('type', ROUTE_TEMPLATE_TYPE_KEEPER),
    isAdjustBreak: Ember.computed('optimizationFlags', function () {
      const optimizationFlags = this.get('optimizationFlags');
      return ADJUST_BREAK_FLAG & optimizationFlags;
    }),
    isConsolidateRoute: Ember.computed('optimizationFlags', function () {
      const optimizationFlags = this.get('optimizationFlags');
      return CONSOLIDATE_ROUTE_FLAG & optimizationFlags;
    }),
    mondayAvailability: Ember.computed('availabilities.@each.{monday,isNew}', function () {
      const availabilities = this.get('availabilities') || [];
      return availabilities.find(avail => avail.get('monday') && !avail.get('isNew'));
    }),
    tuesdayAvailability: Ember.computed('availabilities.@each.{tuesday,isNew}', function () {
      const availabilities = this.get('availabilities') || [];
      return availabilities.find(avail => avail.get('tuesday') && !avail.get('isNew'));
    }),
    wednesdayAvailability: Ember.computed('availabilities.@each.{wednesday,isNew}', function () {
      const availabilities = this.get('availabilities') || [];
      return availabilities.find(avail => avail.get('wednesday') && !avail.get('isNew'));
    }),
    thursdayAvailability: Ember.computed('availabilities.@each.{thursday,isNew}', function () {
      const availabilities = this.get('availabilities') || [];
      return availabilities.find(avail => avail.get('thursday') && !avail.get('isNew'));
    }),
    fridayAvailability: Ember.computed('availabilities.@each.{friday,isNew}', function () {
      const availabilities = this.get('availabilities') || [];
      return availabilities.find(avail => avail.get('friday') && !avail.get('isNew'));
    }),
    saturdayAvailability: Ember.computed('availabilities.@each.{saturday,isNew}', function () {
      const availabilities = this.get('availabilities') || [];
      return availabilities.find(avail => avail.get('saturday') && !avail.get('isNew'));
    }),
    sundayAvailability: Ember.computed('availabilities.@each.{sunday,isNew}', function () {
      const availabilities = this.get('availabilities') || [];
      return availabilities.find(avail => avail.get('sunday') && !avail.get('isNew'));
    }),
    holidayAvailability: Ember.computed('availabilities.@each.{holiday,isNew}', function () {
      const availabilities = this.get('availabilities') || [];
      return availabilities.find(avail => avail.get('holiday') && !avail.get('isNew'));
    })
  });

  _exports.default = _default;
});