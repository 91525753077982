define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/dot-inspection/dot-inspection-validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALIDATIONS = void 0;
  const VALIDATIONS = {
    mileage: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Mileage',
      message: '{description} is required'
    })],
    failedDot: [(0, _validators.validatePresence)({
      presence: true,
      on: 'oosDate',
      description: 'Failed Dot',
      message: '{description} is required if OOS Date is selected'
    })]
  };
  _exports.VALIDATIONS = VALIDATIONS;
});