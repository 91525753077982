define("adept-iq/serializers/avlm-route", ["exports", "adept-iq/serializers/-avlm-avlmService"], function (_exports, _avlmAvlmService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      const relationships = payload.data.relationships ? payload.data.relationships : {};
      payload.data.attributes.otpValue = payload.data.attributes.otp;
      payload.data.attributes.otp = payload.data.attributes.otpRouteStatus;
      payload.data.attributes.actualStartTimestamp = payload.data.attributes.actualStartDate + 'T' + payload.data.attributes.actualStartTime;
      payload.data.attributes.actualEndTimestamp = payload.data.attributes.actualEndDate + 'T' + payload.data.attributes.actualEndTime;

      if (payload.data.attributes.vehicleId) {
        relationships.avlmVehicle = {
          data: {
            type: 'avlm-vehicle',
            id: `${payload.data.attributes.vehicleId}`
          }
        };
      }

      if (payload.data.attributes.polyline) {
        const routeId = payload.data.attributes.id;
        const routePolyline = payload.data.attributes.polyline;
        const navigationPolyline = {
          type: 'polyline',
          id: `${routeId}`,
          attributes: {
            coords: routePolyline
          }
        };
        relationships.navigationPolyline = {
          data: {
            type: 'polyline',
            id: `${payload.data.id}`
          }
        };
        payload.included = payload.included || [];
        payload.included.push(navigationPolyline);
      }

      payload.data.relationships = relationships;
      return payload;
    }

  });

  _exports.default = _default;
});