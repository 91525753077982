define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/pinCursor", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/map", "tomtom", "adept-iq/config/environment"], function (_exports, _react, _panel, _map, _tomtom, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PinCursor = () => {
    const {
      map
    } = _react.default.useContext(_map.MapContext);

    const {
      setLocation,
      setIsPinCursor
    } = (0, _panel.usePanel)();

    const reverseGeocode = async geocode => {
      const result = await _tomtom.default.reverseGeocode().key(_environment.default.tomtom.search.searchKey).position(geocode).go();
      const latlngArray = [geocode.lat, geocode.lng];
      return {
        address: result.address.freeformAddress || 'Unknown',
        streetNumber: result.address.streetNumber || '1',
        streetAddress: result.address.streetName || 'Unknown',
        region: result.address.countrySubdivisionName || 'Unknown',
        locality: result.address.countrySecondarySubdivision || 'Unknown',
        subLocality: result.address.countryTertiarySubdivision || 'Unknown',
        postalCode: result.address.postalCode || 'Unknown',
        country: result.address.country || 'US',
        position: {
          lat: latlngArray[0],
          lon: latlngArray[1]
        },
        lat: latlngArray[0],
        lng: latlngArray[1]
      };
    };

    _react.default.useEffect(() => {
      if (!map) return;
      Ember.$('html,body,.tomtom-map').addClass('pinpoint-cursor');
      map.native.on('click', async e => {
        const coord = e.latlng;

        const _location = await reverseGeocode({ ...coord,
          lon: coord.lng
        });

        setLocation(_location);
        Ember.$('html,body,.tomtom-map').removeClass('pinpoint-cursor');
        map.native.off('click');
        setIsPinCursor(false);
      });
      return () => {
        map.native.off('click');
        Ember.$('html,body,.tomtom-map').removeClass('pinpoint-cursor');
      };
    }, []);

    return _react.default.createElement(_react.default.Fragment, null);
  };

  var _default = PinCursor;
  _exports.default = _default;
});