define("adept-iq/services/locks", ["exports", "lodash", "moment", "adept-iq/config/api-urls"], function (_exports, _lodash, _moment, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    lockData() {
      const lockData = {
        'action': 'UPDATE',
        'model': 'lock',
        'message': {
          'data': {
            'type': 'lock',
            'id': '1',
            'attributes': {
              'status': 'locked',
              'user': '',
              'expirationTime': '2019-11-18T21:21:04.944Z'
            },
            'relationships': {
              'dispatchRoute': {
                'data': {
                  'type': 'dispatchRoute',
                  'id': '1'
                }
              }
            }
          }
        }
      };
      return lockData;
    },

    // HACK: Doing an api get for all the locks because
    // kafka topics does not do this. It will only do
    // updates. This would go away once Kafka topics can send
    // all locks at initial connection.
    // TODO: Remove this later when lock topic sends the full version
    // of information.
    getAllLocks() {
      const session = this.get('session');
      const currentLocks = this.get('store').peekAll('lock').content;
      const currentLocksIds = currentLocks.map(lock => lock.id);
      return this.get('ajax').request(_apiUrls.API.dispatchService.host + '/lock', {
        method: 'GET',
        contentType: 'text',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).then(response => {
        const {
          data
        } = response;
        const newLocks = data.filter(lock => lock.attributes.entityType === 'dispatchRoute');
        const locks = [];
        const currentTime = (0, _moment.default)();
        const newLockIds = newLocks.map(lock => lock.attributes.entityId);

        const removeLockIds = _lodash.default.difference(currentLocksIds, newLockIds); // add or update locks


        newLocks.forEach(lock => {
          const lockData = this.lockData();
          lockData.message.data.attributes.status = lock.attributes.status;

          if (!lock.attributes.status) {
            lockData.message.data.attributes.status = 'locked';
          }

          lockData.message.data.attributes.user = lock.attributes.owner;
          lockData.message.data.attributes.expirationTime = currentTime.add(lock.attributes.timeToLive, 'seconds').format();
          lockData.message.data.relationships.dispatchRoute.data.id = lock.attributes.entityId;
          locks.push(lockData);
        }); // remove old locks, change status to unlocked

        currentLocks.forEach(lock => {
          const id = lock._record.get('id');

          const lockData = this.lockData();

          if (removeLockIds.includes(id)) {
            lockData.message.data.attributes.status = 'unlocked';
            delete lockData.message.data.attributes.user;
            delete lockData.message.data.attributes.expirationTime;
            delete lockData.message.data.relationships;
            locks.push(lockData);
          }
        });
        return locks;
      }).catch(() => {
        this.get('notifications').warning('Get locks failed');
        return [];
      });
    }

  });

  _exports.default = _default;
});