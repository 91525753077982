define("adept-iq/tests/factories/route-break", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const dayMoment = (0, _moment.default)().startOf('day').add(6, 'hours');
  const eveningMoment = (0, _moment.default)().startOf('day').add(14, 'hours');
  const nightMoment = (0, _moment.default)().startOf('day').add(18, 'hours');

  _emberDataFactoryGuy.default.define('route-break', {
    default: {
      state: 'planned',
      plannedRouteOrdinal: 0,
      // change based on options hash
      requestTime: dayMoment.toDate(),
      promisedStart: dayMoment.toDate(),
      estimatedStart: dayMoment.format('HH:mm'),
      estimatedEnd: dayMoment.add(30, 'minutes').format('HH:mm'),
      plannedDuration: 30,
      notes: 'Some Route Break Notes'
    },
    traits: {
      morning: {
        promisedStart: dayMoment.toDate(),
        estimatedStart: dayMoment.format('HH:mm'),
        estimatedEnd: dayMoment.add(30, 'minutes').format('HH:mm')
      },
      evening: {
        promisedStart: eveningMoment.toDate(),
        requestTime: eveningMoment.toDate(),
        estimatedStart: eveningMoment.format('HH:mm'),
        estimatedEnd: eveningMoment.add(30, 'minutes').format('HH:mm')
      },
      night: {
        promisedStart: nightMoment.toDate(),
        requestTime: nightMoment.toDate(),
        estimatedStart: nightMoment.format('HH:mm'),
        estimatedEnd: nightMoment.add(30, 'minutes').format('HH:mm')
      }
    }
  });
});