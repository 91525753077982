define("adept-iq/initializers/deprecations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  const DEPRECATION_IDS_BLACKLIST = ['ember-simple-auth.session.authorize', 'ember-simple-auth.baseAuthorizer', 'ember-component.send-action', 'ember-polyfills.deprecate-merge', 'object.new-constructor', 'ember-runtime.deprecate-copy-copyable', 'remove-handler-infos', 'events.remove-all-listeners', 'action-deprecation', 'computed-deprecations'];

  function initialize() {
    Ember.Debug.registerDeprecationHandler((message, options, next) => {
      if (DEPRECATION_IDS_BLACKLIST.includes(options.id)) return;
      next(message, options);
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});