define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel/confirm-box", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel"], function (_exports, _react, _panel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ConfirmBox = () => {
    const {
      confirmStack,
      setConfirmStack
    } = (0, _panel.usePanel)();

    const [confirm, setConfirm] = _react.default.useState();

    const [cancel, setCancel] = _react.default.useState();

    const [message, setMessage] = _react.default.useState();

    const _confirm = () => {
      if (confirm) confirm();
      confirmStack.shift();
      setConfirmStack([...confirmStack]);
    };

    const _cancel = () => {
      if (cancel) cancel();
      confirmStack.shift();
      setConfirmStack([...confirmStack]);
    };

    _react.default.useEffect(() => {
      const firstElement = confirmStack.length > 0 ? confirmStack[0] : {};
      setConfirm(firstElement.confirm);
      setCancel(firstElement.cancel);
      setMessage(firstElement.message);
    }, [confirmStack]);

    return _react.default.createElement(_react.default.Fragment, null, message && _react.default.createElement("div", {
      className: "confirm-dialog top_0"
    }, _react.default.createElement("a", {
      href: "#",
      className: "dialog-close",
      onClick: () => _cancel()
    }, "X"), _react.default.createElement("p", null, message), _react.default.createElement("div", {
      className: "popup-footer"
    }, _react.default.createElement("button", {
      className: "confirm-cancel",
      onClick: () => _cancel()
    }, "Cancel"), _react.default.createElement("button", {
      className: "confirm-submit",
      type: "button",
      onClick: () => _confirm()
    }, "Confirm"))));
  };

  var _default = ConfirmBox;
  _exports.default = _default;
});