define("adept-iq/pods/components/iq-widgets/reconcile-verify-route/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: 'Verify Route',
    tripTableColumns: [{
      label: 'Trip ID',
      valuePath: 'tripId',
      resizable: true,
      defaultWidth: 20,
      component: 'iq-widgets/reconcile-verify-route/table-header'
    }, {
      label: 'Message',
      valuePath: 'message',
      resizable: false,
      defaultWidth: 80,
      component: 'iq-widgets/reconcile-verify-route/table-header'
    }],
    routeTableColumns: [{
      label: 'Route ID',
      valuePath: 'routeId',
      resizable: true,
      defaultWidth: 20,
      component: 'iq-widgets/reconcile-verify-route/table-header'
    }, {
      label: 'Message',
      valuePath: 'message',
      resizable: false,
      defaultWidth: 80,
      component: 'iq-widgets/reconcile-verify-route/table-header'
    }]
  };
  _exports.default = _default;
});