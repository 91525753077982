define("adept-iq/tests/factories/rider-eligibility", ["ember-data-factory-guy", "faker"], function (_emberDataFactoryGuy, _faker) {
  "use strict";

  const date = new Date();

  _emberDataFactoryGuy.default.define('rider-eligibility', {
    sequences: {
      froms: () => _faker.default.date.past(1, date),
      tos: () => _faker.default.date.future(3, date)
    },
    default: {
      from: _emberDataFactoryGuy.default.generate('froms'),
      to: _emberDataFactoryGuy.default.generate('tos')
    }
  });
});