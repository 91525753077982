define("adept-iq/classes/tree-nodes/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    // config
    label: null,
    type: null,
    depth: 0,
    parent: null,
    children: null,
    radioButton: null,
    searchText: null,
    isSearchEnabled: false,
    // this node specifically matches search query
    isSearchMatch: false,
    isTreeModified: Ember.computed('tree.@each.isModified', function () {
      return this.get('tree').any(node => node.get('isModified'));
    }),
    isTreeOutdated: Ember.computed('tree.@each.isOutdated', function () {
      return this.get('tree').any(node => node.get('isOutdated'));
    }),
    isTreeDirty: Ember.computed.or('isTreeModified', 'isTreeOutdated'),
    tree: Ember.computed('children.@each.tree', function () {
      const nodes = [this];
      const children = this.get('children');

      if (Ember.isEmpty(children)) {
        return nodes;
      }

      children.sortBy('label').forEach(child => {
        const tree = child.get('tree');
        nodes.push(...tree);
      });
      return nodes;
    }),
    visibleTree: Ember.computed('isRemoved', 'isExpanded', 'children.@each.visibleTree', function () {
      if (this.get('isRemoved')) return [];
      if (!this.get('isExpanded')) return [this];
      const children = this.get('children');
      if (Ember.isEmpty(children)) return [this];
      const visibleTree = children.sortBy('label').reduce((arr, child) => {
        const tree = child.get('visibleTree');
        arr.push(...tree);
        return arr;
      }, [this]);
      return visibleTree;
    }),
    visibleSearchTree: Ember.computed('isRemoved', 'isExpanded', 'isSearchMatch', 'isUpstreamSearchMatch', 'isDownstreamSearchMatch', 'children.@each.visibleSearchTree', function () {
      if (!this.get('isSearchMatch') && !this.get('isUpstreamSearchMatch') && !this.get('isDownstreamSearchMatch')) {
        return [];
      }

      if (this.get('isRemoved')) return [];
      if (!this.get('isExpanded')) return [this];
      const children = this.get('children');
      if (Ember.isEmpty(children)) return [this];
      return children.sortBy('label').reduce((arr, child) => {
        const tree = child.get('visibleSearchTree');
        arr.push(...tree);
        return arr;
      }, [this]);
    }),
    visibleChildren: Ember.computed('children.@each.isRemoved', function () {
      const children = this.get('children');

      if (Ember.isEmpty(children)) {
        return [];
      }

      return children.rejectBy('isRemoved');
    }),
    // this node is the ancestor of a matched node
    isUpstreamSearchMatch: Ember.computed('children.@each.{isSearchMatch,isUpstreamSearchMatch}', function () {
      const children = this.get('children');
      if (Ember.isEmpty(children)) return false;
      return children.any(child => {
        return child.get('isSearchMatch') || child.get('isUpstreamSearchMatch');
      });
    }),
    // this node is the descendant of a matched node
    isDownstreamSearchMatch: Ember.computed('parent.{isSearchMatch,isDownstreamSearchMatch}', function () {
      const parent = this.get('parent');
      if (Ember.isNone(parent)) return false;
      return parent.get('isSearchMatch') || parent.get('isDownstreamSearchMatch');
    }),

    bfs(callback) {
      callback(this);
      const children = this.get('children');
      if (Ember.isEmpty(children)) return;
      children.forEach(child => {
        child.bfs(...arguments);
      });
    },

    dfs(callback) {
      const children = this.get('children');

      if (!Ember.isEmpty(children)) {
        children.forEach(child => {
          child.dfs(...arguments);
        });
      }

      callback(this);
    }

  });

  _exports.default = _default;
});