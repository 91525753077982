define("adept-iq/tests/factories/break-type", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('break-type', {
    default: {
      name: 'fixed',
      defaultDuration: 15,
      isPaid: true,
      isRecognized: true,
      category: 'generic fixed break'
    },
    traits: {
      floating: {
        name: 'floating'
      },
      fixed: {
        name: 'fixed'
      }
    }
  });
});