define("adept-iq/serializers/trim-slack-contract-management", ["exports", "adept-iq/serializers/-ss-schedulingService"], function (_exports, _ssSchedulingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    serialize() {
      const json = this._super(...arguments);

      const id = json.data.attributes.providerName;
      const type = 'providerName';
      const providerRelationship = {
        providerName: {
          data: {
            id,
            type
          }
        }
      };
      json.data.relationships = providerRelationship;
      delete json.data.attributes.providerName;
      return json;
    },

    normalizeResponse: function (store, primaryModelClass, payload) {
      if (!Ember.isEmpty(payload.data)) {
        if (Ember.isArray(payload.data)) {
          payload.data.forEach(record => {
            const providerName = record.relationships.providerName.data.id;
            record.attributes.providerName = providerName;
          });
        } else {
          const providerName = payload.data.relationships.providerName.data.id;
          payload.data.attributes.providerName = providerName;
        }
      }

      return payload;
    }
  });

  _exports.default = _default;
});