define("adept-iq/transforms/iso-date", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.DateTransform.extend({
    serialize(date) {
      if (Ember.isEmpty(date)) return null;
      return (0, _moment.default)(date).toISOString();
    },

    deserialize(serialized) {
      if (Ember.isEmpty(serialized)) return null;
      return (0, _moment.default)(serialized).toDate();
    }

  });

  _exports.default = _default;
});