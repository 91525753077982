define("adept-iq/pods/components/side-drawer/add-widget/widget-config-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    classNames: ['add-widget-config-section add-widget-section'],
    displayButtons: true,
    widgetSection: null,
    isOpen: true,

    init() {
      this._super(...arguments);

      this.set('isClicked', this.get('workspace.showGrid'));
    },

    actions: {
      onSectionButtonClick() {
        // expand / contract the button
        this.toggleProperty('displayButtons');
      },

      onConfigButtonClick(configButtonType) {
        this.set('isClicked', !this.get('isClicked'));
        this.get('onConfigButtonClick')(configButtonType, this.get('isClicked'));
      },

      onHeaderClick() {
        this.toggleProperty('isOpen');
      }

    }
  });

  _exports.default = _default;
});