define("adept-iq/classes/tree-nodes/object", ["exports", "adept-iq/classes/tree-nodes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    type: 'object',
    configItem: null,
    isExpandable: true,
    isObject: true,
    isRemoved: false,
    isModified: Ember.computed('children.@each.isModified', 'isNew', function () {
      const children = this.get('children');

      if (this.get('isNew')) {
        return true;
      }

      return Ember.makeArray(children).any(child => {
        return child.get('isModified');
      });
    }),
    isNew: Ember.computed('configItem.id', function () {
      return !this.get('configItem.id');
    }),
    isSearchMatch: Ember.computed('searchText', 'label', function () {
      // otherwise, only show if label or value matches query
      const searchText = this.get('searchText');
      if (Ember.isBlank(searchText)) return true;
      const normalizedSearchText = searchText.toLowerCase();
      const label = this.get('label');

      if (Ember.isPresent(label)) {
        const normalizedLabel = label.toLowerCase();
        if (normalizedLabel.includes(normalizedSearchText)) return true;
      }
    })
  });

  _exports.default = _default;
});