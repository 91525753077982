define("adept-iq/mixins/version-check", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/config/api-urls"], function (_exports, _emberConcurrency, _unwrapProxy, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    _routeLocked: false,
    _routeLockedTimeStamp: null,
    _dispatchRouteToCheck: null,
    _routeLockedTimeoutId: null,
    dispatchRouteToCheck: Ember.computed('_dispatchRouteToCheck', 'stopPoint', {
      get() {
        let dispatchRouteToCheck = this.get('_dispatchRouteToCheck');

        if (!dispatchRouteToCheck) {
          const stopPoint = this.get('stopPoint');
          if (stopPoint) dispatchRouteToCheck = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('dispatchRoute'));
          /*eslint-disable */

          this.set('_dispatchRouteToCheck', dispatchRouteToCheck);
          /*eslint-enable */
        }

        return dispatchRouteToCheck;
      },

      set(key, value) {
        this.set('_dispatchRouteToCheck', value);
        return value;
      }

    }),
    versionCheckOperation: (0, _emberConcurrency.task)(function* (versionInavlidCallback) {
      const dispatchRoute = this.get('dispatchRouteToCheck');
      const dispatchRouteAdapter = this.get('store').adapterFor('dispatch-route');
      const currentVersion = yield dispatchRouteAdapter.getVersion(dispatchRoute.get('id'));
      const dispatchRouteVersionSnapshot = this.get('dispatchRouteVersionSnapshot');

      if (dispatchRouteVersionSnapshot && currentVersion && dispatchRouteVersionSnapshot !== currentVersion) {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          tip: 'Perform failed, Because route had been modified by another request.',
          title: 'Route record changed',
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            tooltip.reset();

            if (versionInavlidCallback) {
              versionInavlidCallback();
            }

            return;
          }
        });
        throw new Error('dispatchRoute version invalid');
      }

      return true;
    }).enqueue(),
    versionUpdateOperation: (0, _emberConcurrency.task)(function* () {
      const dispatchRoute = this.get('dispatchRouteToCheck');
      const dispatchRouteAdapter = this.get('store').adapterFor('dispatch-route');

      if (dispatchRoute) {
        yield dispatchRoute.reload();
        yield dispatchRouteAdapter.updateVersion(dispatchRoute.get('id'), dispatchRoute.get('version'));
      }
    }).enqueue(),
    lockStatusCheck: async function () {
      const session = this.get('session');
      const dispatchRoute = this.get('dispatchRouteToCheck');
      const dispatchRouteLock = this.get('store').peekRecord('lock', dispatchRoute.get('id'));
      const userId = session.data.authenticated.userId;

      if (Ember.isNone(dispatchRouteLock) || !dispatchRouteLock.get('isLocked') || userId !== dispatchRouteLock.get('owner.id')) {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          tip: 'Confirmation timeout, please retry operation.',
          title: 'Confirmation timeout',
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            tooltip.reset();
            return;
          }
        });
        throw new Error('Confirmation timeout');
      }
    },
    lockRouteAction: async function () {
      let persist = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let ttl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 120;
      const lockAdapter = this.get('store').adapterFor('lock');
      const dispatchRoute = this.get('dispatchRouteToCheck');
      const lock = await lockAdapter.doLockRoute(dispatchRoute, persist, true, false, 45, ttl);
      this.set('_routeLockedTimeStamp', new Date().getTime());

      if (!persist) {
        const routeLockedTimeoutId = setTimeout(() => {
          if (this.get('_routeLockedTimeoutId')) {
            clearTimeout(this.get('_routeLockedTimeoutId'));
            this.set('_routeLockedTimeoutId', null);
          }

          this.unlockRouteAction(0);
          const tooltip = this.get('tooltip');

          if (!tooltip.get('isRunning')) {
            tooltip.reset();
            tooltip.pushConfirmation({
              tip: 'Confirmation timeout, please retry operation.',
              title: 'Confirmation timeout',
              hasOverlay: true,
              primaryActionText: 'Confirm',
              primaryAction: () => {
                tooltip.reset();
                return;
              }
            });
          }
        }, 45000);
        this.set('_routeLockedTimeoutId', routeLockedTimeoutId);
      }

      if (lock && lock.attributes.exist !== true) {
        this.set('routeLocked', true);
      }
    },
    lockScheduleRouteAction: async function () {
      let persist = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let ttl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 120;
      const route = this.get('routeToCheck');
      const lock = await this.doScheduleLockRoute(route, persist, ttl);
      this.set('_routeLockedTimeStamp', new Date().getTime());

      if (!persist) {
        const routeLockedTimeoutId = setTimeout(() => {
          if (this.get('_routeLockedTimeoutId')) {
            clearTimeout(this.get('_routeLockedTimeoutId'));
            this.set('_routeLockedTimeoutId', null);
          }

          this.unlockScheduleRouteAction(0);
          const tooltip = this.get('tooltip');

          if (!tooltip.get('isRunning')) {
            tooltip.reset();
            tooltip.pushConfirmation({
              tip: 'Confirmation timeout, please retry operation.',
              title: 'Confirmation timeout',
              hasOverlay: true,
              primaryActionText: 'Confirm',
              primaryAction: () => {
                tooltip.reset();
                return;
              }
            });
          }
        }, 45000);
        this.set('_routeLockedTimeoutId', routeLockedTimeoutId);
      }

      if (lock && lock.data[0].attributes.status === 'acquired') {
        this.set('routeLocked', true);
      }
    },

    doScheduleLockRoute(route, persist, ttl) {
      const notifications = this.get('notifications');
      const session = this.get('session');
      const json = {
        data: {
          type: 'lock',
          attributes: {
            entityType: 'route',
            entityId: route.get('id'),
            timeToLive: ttl,
            persist: persist
          }
        }
      };
      return this.get('ajax').post(_apiUrls.API.schedulingService.host + '/lock', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      }).catch(function (error) {
        notifications.warning(`Error creating route lock, ${error.message}`);
      });
    },

    invokeScheduleUnlock(routeId) {
      const notifications = this.get('notifications');
      const session = this.get('session');
      return this.get('ajax').delete(_apiUrls.API.schedulingService.host + '/lock/force/route/' + routeId, {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).catch(function (error) {
        notifications.warning(`Error removing route lock, ${error.message}`);
      });
    },

    unlockRouteAction: async function () {
      let delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 3000;
      const lockAdapter = this.get('store').adapterFor('lock');
      const dispatchRoute = this.get('dispatchRouteToCheck');
      const currentTimestamp = new Date().getTime();
      const routeLockedTimeStamp = this.get('_routeLockedTimeStamp');
      const minDelay = routeLockedTimeStamp ? Math.max(3000 - (currentTimestamp - routeLockedTimeStamp), delay) : delay;
      this.set('_routeLockedTimeStamp', null);

      if (this.get('_routeLockedTimeoutId')) {
        clearTimeout(this.get('_routeLockedTimeoutId'));
        this.set('_routeLockedTimeoutId', null);
      }

      if (this.get('routeLocked')) {
        try {
          if (minDelay > 0) {
            // Avoid it is too close no getting lock updated.
            Ember.run.later(async () => {
              await lockAdapter.doUnlockRoute(dispatchRoute, false);
            }, minDelay);
          } else {
            await lockAdapter.doUnlockRoute(dispatchRoute);
          }
        } catch (e) {
          return;
        } finally {
          if (!this.get('isDestroyed')) {
            this.set('routeLocked', false);
          }
        }
      }
    },
    unlockScheduleRouteAction: async function () {
      let delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 3000;
      const route = this.get('routeToCheck');
      const currentTimestamp = new Date().getTime();
      const routeLockedTimeStamp = this.get('_routeLockedTimeStamp');
      const minDelay = routeLockedTimeStamp ? Math.max(3000 - (currentTimestamp - routeLockedTimeStamp), delay) : delay;
      this.set('_routeLockedTimeStamp', null);

      if (this.get('_routeLockedTimeoutId')) {
        clearTimeout(this.get('_routeLockedTimeoutId'));
        this.set('_routeLockedTimeoutId', null);
      }

      if (this.get('routeLocked')) {
        try {
          if (minDelay > 0) {
            // Avoid it is too close no getting lock updated.
            Ember.run.later(async () => {
              await this.invokeScheduleUnlock(route.get('id'));
            }, minDelay);
          } else {
            await this.invokeScheduleUnlock(route.get('id'));
          }
        } catch (e) {
          return;
        } finally {
          if (!this.get('isDestroyed')) {
            this.set('routeLocked', false);
          }
        }
      }
    },
    clearRouteLockTimeoutId: function () {
      if (this.get('_routeLockedTimeoutId')) {
        clearTimeout(this.get('_routeLockedTimeoutId'));
        this.set('_routeLockedTimeoutId', null);
      }
    }
  });

  _exports.default = _default;
});