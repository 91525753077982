define("adept-iq/pods/components/side-drawer/reconcile-vehicle-breakdown/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    onCloseClick: null,
    errors: null,
    // Reset map and address picker
    canResetAddress: Ember.computed.alias('workspace.activeContext.topActiveContext.resetLocation'),
    routeDetails: Ember.computed.readOnly('reconcile.reconcileRouteInfo'),
    callBack: Ember.computed.readOnly('stackFrame.options.action'),
    canUndo: Ember.computed('selectedReason', 'selectedLocation', 'selectedDateTimeRage.starttime', function () {
      if (Ember.get(this, 'selectedReason') || Ember.get(this, 'selectedLocation')) {
        return true;
      }

      const selectedTime = Ember.get(this, 'selectedDateTimeRage.starttime');

      if (selectedTime) {
        return (0, _moment.default)(selectedTime).format('hh:mm A') !== (0, _moment.default)(this.todayTime).format('hh:mm A');
      }

      return false;
    }),
    canSave: Ember.computed.and('selectedLocation', 'selectedReason'),
    reasonOptions: Ember.computed('selectedReason', function () {
      const breakdownReasonTypes = this.store.peekAll('cs-config-item').filter(message => {
        return message.category === 'config-System_Configuration-breakdown_types';
      });
      return breakdownReasonTypes.map(reason => reason.displayName);
    }),

    init() {
      this._super();

      this.todayTime = (0, _moment.default)(new Date()).toISOString('hh:mm a');
      this.set('selectedDateTimeRage', {
        date: Ember.get(this, 'reconcile.reconcileDate'),
        starttime: this.todayTime,
        startofday: (0, _moment.default)().startOf('day').toISOString(),
        endofday: (0, _moment.default)().endOf('day').toISOString()
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.setSideDrawerWidth('470px');
      this.get('systemConfig').set('showRightPanel', true);
    },

    checkValidations() {
      let isValid = true;
      this.set('errors', []);

      if (!this.get('selectedLocation')) {
        this.errors.push('Location can not be empty');
        isValid = false;
      }

      if (!this.get('selectedReason')) {
        this.errors.push('Must have a breakdown reason');
        isValid = false;
      }

      return isValid;
    },

    showToolTip() {
      const tooltip = this.get('tooltip');
      const successMsg = `VEHICLE ${this.get('routeDetails.vehicle.vehicleId')} BREAKDOWN CONFIRMED`;
      tooltip.pushConfirmation({
        title: 'Report Breakdown',
        tip: 'Would you like to commit your changes?',
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('saveReportBreakdownTask').perform().then(() => {
            this.send('cancel');
            this.get('workspace').popState('reconcileVehicleBreakdown');
            this.get('notifications').success(successMsg);
            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem creating the route, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    saveReportBreakdownTask: (0, _emberConcurrency.task)(function* () {
      try {
        const store = this.get('store');
        const reconcileRouteAdapter = store.adapterFor('reconcile-route');
        const dt = (0, _moment.default)(this.get('selectedDateTimeRage').date).format('MM-DD-YYYY');
        const tm = (0, _moment.default)(this.get('selectedDateTimeRage').starttime).format('hh:mm a');
        const date = (0, _moment.default)(dt + ' ' + tm).toISOString();
        const payload = {
          id: this.routeDetails.get('routeId'),
          breakdownTime: date,
          breakdownReason: this.get('selectedReason'),
          breakdownLocation: this.get('selectedLocation').fullAddress,
          lat: this.get('selectedLocation').lat,
          lng: this.get('selectedLocation').lng,
          userId: this.session.data.authenticated.userId
        };
        yield reconcileRouteAdapter.patchRoute(payload, 'Breakdown');
      } catch (error) {
        throw error;
      }
    }),
    actions: {
      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
      },

      save() {
        const isValid = this.checkValidations();

        if (isValid) {
          this.showToolTip();
        }
      },

      onUndoClick() {
        if (this.get('selectedLocation')) {
          this.set('canResetAddress', true);
        }

        this.set('selectedReason', null);
        this.set('selectedLocation', null);
        this.set('selectedDateTimeRage.starttime', this.todayTime);
      },

      onChangeReason(reason) {
        this.set('selectedReason', reason);
      },

      onChangeLocation(location) {
        this.set('selectedLocation', location);
      },

      cancel() {
        if (this.callBack) {
          this.callBack(true);
        }
      }

    }
  });

  _exports.default = _default;
});