define("adept-iq/errors/version-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const VersionError = function (errors) {
    let message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'This error is result of missing avlm routeVersion.';
    Ember.Error.call(this, message);
    this.errors = errors || [{
      title: 'This is version error.',
      detail: message
    }];
  };

  VersionError.prototype = Object.create(Ember.Error.prototype);
  var _default = VersionError;
  _exports.default = _default;
});