define("adept-iq/pods/components/iq-widgets/ember-react-playback/hooks/usePrevious", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const usePrevious = values => {
    const ref = _react.default.useRef();

    _react.default.useEffect(() => {
      ref.current = values;
    });

    return ref.current;
  };

  var _default = usePrevious;
  _exports.default = _default;
});