define("adept-iq/pods/components/login-form/component", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    errors: null,
    email: null,
    password: null,
    resetPasswordAllowed: false,
    isChrome: null,
    isValid: Ember.computed('email', 'password', function () {
      const email = this.get('email');
      const password = this.get('password');
      return Ember.isPresent(email) && Ember.isPresent(password);
    }),

    checkBrowser() {
      let operaAgent = false;
      let safariAgent = false;
      let chromeAgent = false;
      let IExplorerAgent = false;
      let edgeAgent = false;
      let firefoxAgent = false;

      if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
        operaAgent = true;
      } else if (navigator.userAgent.indexOf('Edg') !== -1) {
        edgeAgent = true;
      } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
        chromeAgent = true;
      } else if (navigator.userAgent.indexOf('Safari') !== -1) {
        safariAgent = true;
      } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
        firefoxAgent = true;
      } else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) //IF IE > 10
        {
          IExplorerAgent = true;
        }

      return {
        safariAgent,
        edgeAgent,
        chromeAgent,
        IExplorerAgent,
        operaAgent,
        firefoxAgent
      };
    },

    init() {
      this._super(...arguments);

      if (_environment.default.APP.avlmLite) {
        this.set('resetPasswordAllowed', false);
      }

      this.set('browserWarning', 'Unable to sign on. You must use Chrome as your browser when working in ADEPT IQ.');
      const {
        chromeAgent
      } = this.checkBrowser();

      if (chromeAgent) {
        this.set('isChrome', true);
      } else {
        this.set('isChrome', false);
      }
    },

    clearPassword: function () {
      setTimeout(() => {
        this.$('input[type=text]').val('');
        this.$('input[type=password]').val('');
      }, 200);
    },

    didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, this.clearPassword);
    },

    actions: {
      onSubmit(email, password, e) {
        e.preventDefault();
        this.get('onSubmit')(email, password);
      }

    }
  });

  _exports.default = _default;
});