define("adept-iq/pods/components/iq-widgets/reconcile-trips-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/reconcile-trips-widget',
    rowComponent: 'table/rows/action-row',
    modelName: 'dwh-trip',
    defaultSortId: 'requestTime',
    defaultSortAsc: true,
    title: 'Trips',
    columns: [{
      id: 'tripId',
      type: 'text',
      label: 'ID',
      valuePath: 'id',
      // TODO: extenalId might not match id
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'routeName',
      type: 'text',
      label: 'Route Name',
      valuePath: 'routeName',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'provider',
      type: 'text',
      label: 'Provider',
      valuePath: 'provider',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'riderId',
      type: 'text',
      label: 'Passenger ID',
      valuePath: 'rider.id',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'riderFirstName',
      type: 'text',
      label: 'Passenger First Name',
      valuePath: 'rider.attributes.firstName',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'riderLastName',
      type: 'text',
      label: 'Passenger Last Name',
      valuePath: 'rider.attributes.lastName',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'type',
      type: 'text',
      label: 'Type',
      valuePath: 'type',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'anchor',
      type: 'text',
      label: 'Anchor',
      valuePath: 'anchor',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'requestTime',
      type: 'time',
      format: 'HH:mm',
      label: 'Request Time',
      valuePath: 'requestTime',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'promiseTime',
      type: 'time',
      format: 'HH:mm',
      label: 'Promise Time',
      valuePath: 'promiseTime',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'pickupAddress',
      type: 'text',
      label: 'Pickup Address',
      valuePath: 'startAddress',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'pickupCity',
      type: 'text',
      label: 'Pickup City',
      valuePath: 'start.address.pickCity',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'pickupZone',
      type: 'text',
      label: 'Pickup Zone',
      valuePath: 'start.address.pickupZone',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'dropoffAddress',
      type: 'text',
      label: 'Dropoff Address',
      valuePath: 'endAddress',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'dropoffCity',
      type: 'text',
      label: 'Dropoff City',
      valuePath: 'end.address.dropCity',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'dropoffZone',
      type: 'text',
      label: 'Dropoff Zone',
      valuePath: 'end.address.dropoffZone',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'status',
      type: 'text',
      label: 'Status',
      valuePath: 'tripStatus',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'statusReason',
      type: 'text',
      label: 'Status Reason',
      valuePath: 'statusReason',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'purpose',
      type: 'text',
      label: 'Purpose',
      valuePath: 'tripPurpose',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'fare',
      type: 'text',
      label: 'Fare',
      valuePath: 'fare',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'fareCategory',
      type: 'text',
      label: 'Fare Category',
      valuePath: 'fareCategoryDisplayName',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'paymentType',
      type: 'text',
      label: 'Payment Type',
      valuePath: 'paymentTypeName',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'wcSeats',
      type: 'text',
      label: 'WC Count',
      valuePath: 'wcSeats',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'pca',
      type: 'text',
      label: 'PCA Count',
      valuePath: 'pca',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'companions',
      type: 'text',
      label: 'Comp Count',
      valuePath: 'companions',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'serviceStart',
      type: 'time',
      format: 'HH:MM',
      label: 'Service Start Time',
      valuePath: 'serviceCalcStartTime',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'serviceEnd',
      type: 'time',
      format: 'HH:MM',
      label: 'Service End Time',
      valuePath: 'serviceCalcEndTime',
      defaultWidth: 85,
      searchable: true
    }]
  };
  _exports.default = _default;
});