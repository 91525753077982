define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/employment-status/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/employment-status/validation", "adept-iq/config/placeholders", "lodash", "adept-iq/utils/guid"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset, _emberChangesetValidations, _validation, _placeholders, _lodash, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['driver-lookup-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // Global Placeholder
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    tableRef: null,
    description: null,
    editAPI: null,
    disableForm: true,
    showErrorMsg: false,
    currentDisplayName: null,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    driverEmploymentStatusChangeSet: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),
    hasPendingChanges: Ember.computed('driverEmploymentStatusChangeSet.changes.length', function () {
      const driverEmploymentStatusChangeSet = this.get('driverEmploymentStatusChangeSet');
      const changes = driverEmploymentStatusChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      return this.get('hasPendingChanges');
    },

    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveDriverEmploymentStatusRecord = this.get('onSaveDriverEmploymentStatusRecord');
      const onNewDriverEmploymentStatusRecord = this.get('onNewDriverEmploymentStatusRecord').bind(this);
      const onEditDriverEmploymentStatusRecord = this.get('onEditDriverEmploymentStatusRecord').bind(this);
      const onUndoDriverEmploymentStatusRecord = this.get('onUndoDriverEmploymentStatusRecord').bind(this);
      const hasRelationship = this.get('hasRelationship').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      const refreshTask = this.get('refreshTask');
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverEmploymentStatusRecord,
          newAction: onNewDriverEmploymentStatusRecord,
          editAction: onEditDriverEmploymentStatusRecord,
          undoAction: onUndoDriverEmploymentStatusRecord,
          deleteAction: null,
          hasChanges,
          onValidateAction,
          refreshTask,
          hasRelationship
        });
        refreshTask.perform();
      });
    },

    willDestroyElement() {
      const records = this.get('tableRef.records') || [];
      records.map(record => {
        if (record.get('tableRef')) {
          record.set('tableRef');
        }
      });

      this._super(...arguments);
    },

    hasRelationship() {
      const store = this.get('store');
      const tooltip = this.get('tooltip');
      const description = this.get('driverEmploymentStatusChangeSet.displayName') || '';
      const driverProviders = store.peekAll('driver-provider').filter(record => record.get('employmentStatusName.displayName') === description);

      if (driverProviders.length) {
        tooltip.pushConfirmation({
          title: 'Lookup',
          hasoverlay: true,
          tip: `There are ${driverProviders.length} active Driver(s) with this Driver Employment Status description. You must either change the Driver Employment Status description on these Driver(s) or delete these Driver(s) prior to changing the Driver Employment Status description.`,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.reset();
          }

        });
      }

      return driverProviders.length;
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const tableRef = this.get('tableRef');
      let driverEmploymentStatusObj = this.get('pendingRecord');

      if (record) {
        driverEmploymentStatusObj = record;
        this.resetForm();
      }

      if (Ember.isEmpty(record)) {
        const data = {
          category: 'config-System_Configuration-driver_statuses',
          type: 'object'
        };
        driverEmploymentStatusObj = store.createRecord('cs-config-item', data);
        this.set('pendingRecord', driverEmploymentStatusObj);
      }

      if (driverEmploymentStatusObj.get('isNew')) {
        driverEmploymentStatusObj.setProperties({
          description: null,
          displayName: null,
          tableRef: tableRef
        });
      }

      this.set('showErrorMsg', false);
      const tableRefRecords = this.get('tableRef.records') || [];
      tableRefRecords.map(tableRefRecord => {
        if (!tableRefRecord.get('tableRef')) {
          tableRefRecord.set('tableRef', tableRef);
        }
      });
      return new _emberChangeset.default(driverEmploymentStatusObj, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('driverEmploymentStatusChangeSet');

      if (selectedRecord) {
        if (!selectedRecord.get('isNew') && !selectedRecord.get('isDeleted')) {
          yield selectedRecord.data.reload();
        }
      }

      const tableRef = this.get('tableRef');
      this.resetForm();
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        editAPI.buttonSettings();
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverEmploymentStatusRecord = this.get('driverEmploymentStatusChangeSet');
      yield driverEmploymentStatusRecord.validate();
      const errors = driverEmploymentStatusRecord.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveDriverEmploymentStatusRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');
      const activityIdGuid = (0, _guid.guid)();
      if (!isValid) return;
      const notifications = this.get('notifications');
      const description = this.get('driverEmploymentStatusChangeSet.displayName') || '';
      const value = {
        displayName: description
      };
      const nameGuidConverted = `${description}-${activityIdGuid}`;
      const name = (0, _lodash.camelCase)(nameGuidConverted);
      const driverEmploymentStatusChangeSet = this.get('driverEmploymentStatusChangeSet');
      let driverEmploymentStatusRecord = this.get('driverEmploymentStatusChangeSet');

      if (driverEmploymentStatusRecord.get('isNew')) {
        driverEmploymentStatusRecord = (0, _unwrapProxy.unwrapProxy)(this.get('driverEmploymentStatusChangeSet'));
        driverEmploymentStatusRecord.set('name', name);
      } else {
        const id = this.get('driverEmploymentStatusChangeSet.id');
        const store = this.get('store');
        const record = `config-System_Configuration-driver_statuses/${id}`;
        driverEmploymentStatusRecord = store.peekRecord('cs-config-item', record);
      }

      driverEmploymentStatusRecord.set('displayName', description);
      driverEmploymentStatusRecord.set('description', description);
      driverEmploymentStatusRecord.set('value', value);

      try {
        const isDuplicateDescription = this.hasDuplicateDescription(description, driverEmploymentStatusChangeSet);

        if (isDuplicateDescription) {
          yield driverEmploymentStatusRecord.save(); // changeset holds changes, execute the changes due to lookups using unorthodox saving practice
          // execute to change underlying object and rollback to remove internal changes list

          driverEmploymentStatusChangeSet.execute();
          driverEmploymentStatusChangeSet.rollback();
          notifications.success('Lookup record successfully saved.');
          Ember.run.later(() => {
            this.get('refreshTask').perform().then(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
              tooltip.reset();
            });
          }, 500);
        } else {
          this.showDuplicateDescriptionWarning(tooltip);
        }
      } catch (response) {
        if (response.errors[0].status === '409') {
          this.showDuplicateDescriptionWarning(tooltip);
        } else {
          notifications.warning('Lookup record failed to save.');
        }

        console.warn(response); // eslint-disable-line
      }
    }).drop(),

    onEditDriverEmploymentStatusRecord() {
      const editAPI = this.get('editAPI');
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onUndoDriverEmploymentStatusRecord() {
      const driverEmploymentStatusChangeSet = this.get('driverEmploymentStatusChangeSet');
      const editAPI = this.get('editAPI');

      if (driverEmploymentStatusChangeSet) {
        driverEmploymentStatusChangeSet.rollback();
      }

      this.set('disableForm', true);
      editAPI.buttonSettings(false, false, false, true, true);
    },

    onNewDriverEmploymentStatusRecord() {
      const editAPI = this.get('editAPI');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    showDuplicateDescriptionWarning(tooltip) {
      tooltip.pushConfirmation({
        title: 'Manage Driver/Vehicle',
        tip: 'Description cannot duplicate an existing Description.',
        primaryActionText: 'OK',
        primaryAction: () => {
          return tooltip.reset();
        }
      });
      this.onUndoDriverEmploymentStatusRecord();
    },

    hasDuplicateDescription(description, driverEmploymentStatusChangeSet) {
      const id = driverEmploymentStatusChangeSet.get('id');
      const driverEmploymentStatus = this.get('tableRef.records').filter(record => {
        return id !== record.get('id') && record.get('displayName').toLowerCase() === description.toLowerCase();
      });
      return !(driverEmploymentStatus && driverEmploymentStatus.length > 0);
    },

    actions: {
      onSaveDriverEmploymentStatusRecord() {
        return this.onSaveDriverEmploymentStatusRecord.perform();
      },

      onInputValueChange(record, valuePath, value) {
        this.set('currentDisplayName', value);
        record.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});