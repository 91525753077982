define("adept-iq/utils/generate-unique-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uniqueId = uniqueId;

  function uniqueId() {
    let IdLength = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
    let options = arguments.length > 1 ? arguments[1] : undefined;
    const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    let filteredAlphabet = ALPHABET;
    let res = '';
    const {
      usedChars
    } = options;
    if (usedChars) filteredAlphabet = ALPHABET.filter(char => !usedChars[char]);
    if (!filteredAlphabet.length) return null;
    filteredAlphabet = filteredAlphabet.join('');

    for (let i = 0; i < IdLength; i++) {
      res += filteredAlphabet.charAt(Math.floor(Math.random() * ALPHABET.length));
    }

    return res;
  }
});