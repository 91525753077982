define("adept-iq/tests/factories/trip-stop", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const dayMoment = (0, _moment.default)().startOf('day').add(6, 'hours');

  _emberDataFactoryGuy.default.define('trip-stop', {
    default: {
      plannedDistanceToNext: 5,
      // in miles
      plannedTravelTimeToNext: 5,
      // in minutes
      plannedEta: dayMoment.toDate(),
      plannedRouteOrdinal: 2
    }
  });
});