define("adept-iq/pods/components/side-drawer/cancel-trip-form/validations", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // validators/cancel-trip-form
  // validation used for segment model
  var _default = {
    cancelType: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Reason',
      message: '{description} cannot be blank'
    })]
  };
  _exports.default = _default;
});