define("adept-iq/pods/components/side-drawer/perform-stop/break/ui/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    fields: {
      id: 'location',
      type: 'addresses',
      label: '',
      valuePath: 'startPlace',
      modelName: 'place',
      addressType: 'originlocations',
      isMultiType: true,
      extra: {
        aliasPath: 'address.content.alias',
        notesPath: 'address.content.notes',
        displayPath: 'address.address',
        premisePath: 'address.premise',
        streetNumberPath: 'address.streetNumber',
        streetNamePath: 'address.streetName',
        localityPath: 'address.locality',
        regionPath: 'address.region',
        subRegionPath: 'address.county',
        postalCodePath: 'address.postalCode',
        countryPath: 'address.country',
        freeformAddressPath: 'address.freeformAddress',
        tomtomFormattedAddressPath: 'address.tomtomFormattedAddress',
        useOptionRecord: true,
        streetAddressPath: 'address.streetAddress',
        lat: 'location.lat',
        lng: 'location.lng',
        geoNode: 'location.geoNode',
        locationsPath: 'address.locations.content.firstObject.latlng',
        markerTypeOption: 'pick'
      }
    },
    endFields: {
      id: 'endLocation',
      type: 'addresses',
      label: '',
      valuePath: 'endPlace',
      modelName: 'place',
      addressType: 'originlocations',
      isMultiType: true,
      extra: {
        aliasPath: 'address.content.alias',
        notesPath: 'address.content.notes',
        displayPath: 'address.address',
        premisePath: 'address.premise',
        streetNumberPath: 'address.streetNumber',
        streetNamePath: 'address.streetName',
        localityPath: 'address.locality',
        regionPath: 'address.region',
        subRegionPath: 'address.county',
        postalCodePath: 'address.postalCode',
        countryPath: 'address.country',
        freeformAddressPath: 'address.freeformAddress',
        tomtomFormattedAddressPath: 'address.tomtomFormattedAddress',
        useOptionRecord: true,
        streetAddressPath: 'address.streetAddress',
        locationsPath: 'address.locations.content.firstObject.latlng',
        markerTypeOption: 'drop',
        lat: 'location.lat',
        lng: 'location.lng',
        geoNode: 'location.geoNode'
      }
    }
  };
  _exports.default = _default;
});