define("adept-iq/models/driver-health", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    bloodPressure: attr('string', {
      defaultValue: null
    }),
    otherHealthCheck: attr('string'),
    otherHealthCheckNotes: attr('string', {
      defaultValue: null
    }),
    diabetes: attr('boolean'),
    highBloodPressure: attr('boolean'),
    correctiveLenses: attr('boolean'),
    lastPhysicalDate: attr('iso-date', {
      defaultValue: null
    }),
    nextPhysicalDate: attr('iso-date', {
      defaultValue: null
    }),
    lastDiabetesCheckDate: attr('iso-date', {
      defaultValue: null
    }),
    nextDiabetesCheckDate: attr('iso-date', {
      defaultValue: null
    }),
    lastBPDate: attr('iso-date', {
      defaultValue: null
    }),
    nextBPDate: attr('iso-date', {
      defaultValue: null
    }),
    prevPhysicalDate: attr('iso-date', {
      defaultValue: null
    }),
    prevBloodPressureDate: attr('iso-date', {
      defaultValue: null
    }),
    prevDiabetesCheckDate: attr('iso-date', {
      defaultValue: null
    }),
    prevOtherHealthCheckDate: attr('iso-date', {
      defaultValue: null
    }),
    lastOtherHealthCheckDate: attr('iso-date', {
      defaultValue: null
    }),
    nextOtherHealthCheckDate: attr('iso-date', {
      defaultValue: null
    }),
    driver: belongsTo('driver'),
    provider: belongsTo('provider'),
    driverPhysicalTypeName: belongsTo('driver-physical-type-name')
  });

  _exports.default = _default;
});