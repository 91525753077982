define("adept-iq/models/vehicle", ["exports", "ember-data", "moment", "adept-iq/utils/format-text-extension", "adept-iq/config/environment", "adept-iq/mixins/otp-label", "ember-data-model-fragments/attributes", "ember-cp-validations"], function (_exports, _emberData, _moment, _formatTextExtension, _environment, _otpLabel, _attributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.VEHICLE_BREAKDOWN_STATUS = _exports.ROAD_SUPERVISOR = _exports.COMMUNICATION_FAILURE_STATUS = void 0;
  const {
    Model,
    belongsTo,
    hasMany,
    attr
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Name',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 32
      })]
    }
  });
  const VEHICLE_BREAKDOWN_STATUS = 'Breakdown';
  _exports.VEHICLE_BREAKDOWN_STATUS = VEHICLE_BREAKDOWN_STATUS;
  const ROAD_SUPERVISOR = 'SUP';
  _exports.ROAD_SUPERVISOR = ROAD_SUPERVISOR;
  const COMMUNICATION_FAILURE_STATUS = 'offline';
  _exports.COMMUNICATION_FAILURE_STATUS = COMMUNICATION_FAILURE_STATUS;

  var _default = Model.extend(Validations, _otpLabel.default, {
    workspace: Ember.inject.service(),
    name: attr('string'),
    displayStatus: attr('string'),
    mobileId: attr('string', {
      defaultValue: null
    }),
    active: attr('boolean', {
      defaultValue: true
    }),
    status: attr('string', {
      defaultValue: 'operational'
    }),
    placeholder: attr('boolean', {
      defaultValue: false
    }),
    nonRevenueVehicle: attr('boolean'),
    inService: attr('boolean'),
    displayInManager: attr('boolean'),
    displayInArchive: attr('boolean'),
    modelYear: attr('number'),
    leaseEndDate: attr('iso-date', {
      defaultValue: null
    }),
    vin: attr('string'),
    licensePlate: attr('string'),
    wheelChairLiftSerialNumber: attr('string'),
    engineType: attr('string'),
    siteDef: attr('string'),
    inServiceDate: attr('iso-date', {
      defaultValue: null
    }),
    avlmInstallDate: attr('iso-date', {
      defaultValue: null
    }),
    avlmRemoveDate: attr('iso-date', {
      defaultValue: null
    }),
    endServiceDate: attr('iso-date', {
      defaultValue: null
    }),
    voipId: attr('string'),
    voipPasscode: attr('string'),
    comments: attr('string'),
    version: attr('number', {
      defaultValue: null
    }),
    vehicleRouteExecEvent: attr('string'),
    // startingGarage/endingGarage were generated from IQUX-service
    startingGarage: attr('string'),
    endingGarage: attr('string'),
    driverAppVersion: attr('string'),
    otp: attr('string'),
    otpWithSign: attr('string'),
    revenue: attr('boolean'),
    rvdStartTime: attr('date'),
    rvdEndTime: attr('date'),
    rvdHistory: attr('object'),
    inProgress: attr('boolean'),
    routeStatus: attr('string'),
    isCompleted: attr('boolean', {
      defaultValue: false
    }),
    hasPowerOn: attr('boolean'),
    isGpsFailure: attr('boolean'),
    isCommFailure: attr('boolean'),
    isLeaveGarageSignOff: attr('boolean'),
    isOnBreak: attr('boolean'),
    // Vehicle Widget emergency related fields
    replyToMessageId: attr('string'),
    preReplyId: attr('string'),
    emergency: attr('string'),
    schedule: belongsTo(),
    provider: belongsTo('provider'),
    vehicleType: belongsTo('vehicle-type'),
    routes: hasMany('route'),
    startGarage: belongsTo('place'),
    endGarage: belongsTo('place'),
    etaRoute: belongsTo('etanav-route'),
    vehicleAvailabilities: hasMany('vehicle-availability'),
    inspections: hasMany('vehicle-dot-inspection'),
    vehicleMaintenances: hasMany('vehicle-maintenance'),
    vehicleRoadCall: hasMany('vehicle-road-call'),
    shiftBreaks: hasMany('shift-break'),
    // drivers: hasMany('driver'),
    routeVehicleDrivers: hasMany('route-vehicle-driver'),
    cannedMessages: hasMany('avlm-canned-message'),
    avlmAlert: hasMany('avlm-alert'),
    emergencyMessages: hasMany('avlm-emergency-message'),
    // Vehicle Lookup
    manufacturerName: belongsTo('manufacturer-name'),
    vehicleStatusName: belongsTo('vehicle-status-name'),
    leaseCompany: belongsTo('lease-company'),
    vehicleMaintLocation: belongsTo('maintenance-location-name'),
    vehicleInspectionCategory: belongsTo('inspection-category'),
    maintenanceStatus: belongsTo('maintenance-status-name'),
    problemIdentified: belongsTo('problem-identified-name'),
    roadCallCode: belongsTo('road-call-code'),
    vehicleBodyType: belongsTo('vehicle-body-type'),
    vehicleChassis: belongsTo('vehicle-chassis'),
    bodyWidth: belongsTo('vehicle-body-width'),
    vehicleFuelCaps: belongsTo('vehicle-fuel-cap'),
    // vehicle breakdown
    breakdowns: hasMany('vehicle-breakdown'),
    address: belongsTo('address'),
    // only store most current ping
    avlLocation: (0, _attributes.fragment)('avl-location'),
    lat: Ember.computed.readOnly('avlLocation.lat'),
    lng: Ember.computed.readOnly('avlLocation.lng'),
    heading: Ember.computed.readOnly('avlLocation.heading'),
    speed: Ember.computed.readOnly('avlLocation.speed'),
    odo: Ember.computed.readOnly('avlLocation.odo'),
    timestamp: Ember.computed.readOnly('avlLocation.timestamp'),
    latestRoute: Ember.computed.readOnly('latestDsRoute'),
    providerId: Ember.computed.readOnly('provider.id'),
    providerName: Ember.computed.readOnly('provider.name'),
    isPlaceholder: Ember.computed.readOnly('placeholder'),
    vehicleTypeName: Ember.computed.readOnly('vehicleType.name'),
    vehicleTypeId: Ember.computed.readOnly('vehicleType.id'),
    leaseCompanyName: Ember.computed.readOnly('leaseCompany.displayName'),
    workspaceStartTime: Ember.computed.alias('workspace.startDate'),
    workspaceEndTime: Ember.computed.alias('workspace.endDate'),
    isWidgetEmergency: Ember.computed.notEmpty('emergency'),
    isWidgetApprovedEmergency: Ember.computed('isWidgetEmergency', 'emergency', function () {
      return this.get('isWidgetEmergency') && this.get('emergency').includes('Acknowledged');
    }),
    widgetEmergencyReplyMsgId: Ember.computed('isWidgetEmergency', 'preReplyId', 'replyToMessageId', function () {
      const isWidgetEmergency = this.get('isWidgetEmergency');

      if (isWidgetEmergency) {
        return this.get('replyToMessageId');
      }

      return null;
    }),
    isEmergency: Ember.computed.alias('lastEmergencyMessage.isEmergency'),
    isApprovedEmergency: Ember.computed.alias('lastEmergencyMessage.isAcknowledged'),
    isApprovedEmergencyPending: Ember.computed.alias('lastEmergencyMessage.isAcknowledgedPending'),
    currentDriverId: Ember.computed.alias('driverId'),
    isEmergencyVehicle: Ember.computed('isEmergency', 'isApprovedEmergency', function () {
      if (this.get('isEmergency') || this.get('isApprovedEmergency')) {
        return true;
      }

      return false;
    }),
    latestVehicleBreakdown: Ember.computed('breakdowns.@each.time', function () {
      const breakdowns = this.get('breakdowns');
      if (!breakdowns) return [];
      return breakdowns.sortBy('time').get('lastObject');
    }),
    callSign: Ember.computed.alias('name'),
    // iqux-service related attributes
    currentDriver: belongsTo('driver'),
    currentRouteName: attr('string'),
    currentRoutePk: attr('number'),
    driverId: attr('string'),
    revenueVehicleStatus: Ember.computed('nonRevenueVehicle', function () {
      return !this.get('nonRevenueVehicle');
    }),
    isRoadSupervisor: Ember.computed('currentRouteName', function () {
      const routeName = this.get('currentRouteName');
      return routeName && routeName.includes(ROAD_SUPERVISOR);
    }),
    sortedEmergencyMessages: Ember.computed('emergencyMessages.[]', function () {
      return this.get('emergencyMessages').sortBy('sortedId', 'createdTime');
    }),
    point: Ember.computed.collect('lat', 'lng'),
    // contains an emergency message with status unresolved or acknowledged
    lastEmergencyMessage: Ember.computed('sortedEmergencyMessages.[]', function () {
      const emergencyMessage = this.get('sortedEmergencyMessages').lastObject;
      return emergencyMessage;
    }),
    latestReceivedEmergencyMessage: Ember.computed('sortedEmergencyMessages.[]', function () {
      const emergencyMessages = this.get('sortedEmergencyMessages').toArray();

      for (let i = emergencyMessages.length - 1; i >= 0; i--) {
        if (emergencyMessages[i].get('isEmergency') || emergencyMessages[i].get('isAcknowledged')) {
          return emergencyMessages[i];
        }
      }

      return null;
    }),
    latestDriver: Ember.computed('currentDriver', 'latestRouteVehicleDriver', function () {
      const currentDriver = this.get('currentDriver');
      if (currentDriver && currentDriver.get('id')) return currentDriver;
      const latestRouteVehicleDriver = this.get('latestRouteVehicleDriver.driver');
      return latestRouteVehicleDriver;
    }),
    latestDsRoute: Ember.computed.alias('latestRouteVehicleDriver.route'),
    latestRouteVehicleDriver: Ember.computed('routeVehicleDrivers.[]', 'rvdHistory', function () {
      const routeVehicleDrivers = this.get('routeVehicleDrivers');
      if (!routeVehicleDrivers) return null;
      const rvdHistory = this.get('rvdHistory') ? Object.values(this.get('rvdHistory')) : []; // Find the route been started

      if (rvdHistory.length > 0) {
        const currentRvd = rvdHistory.find(rvd => rvd.isStarted && !rvd.isCompleted);
        const latestDpRouteId = currentRvd ? currentRvd.dpRouteId : null;

        if (latestDpRouteId) {
          const latestRvd = routeVehicleDrivers.findBy('dispatchRoute.id', latestDpRouteId);
          if (latestRvd) return latestRvd;
        }
      } // Otherwise just sort by startTime


      return routeVehicleDrivers.sortBy('startTime').get('lastObject');
    }),
    // Remove the start/end time filter cause iqux-perf dynamically fetch this from server
    currentRouteVehicleDrivers: Ember.computed.alias('routeVehicleDrivers'),
    currentAssignment: Ember.computed('routeVehicleDrivers', function () {
      const routeVehicleDrivers = Ember.get(this, 'routeVehicleDrivers');
      const now = (0, _moment.default)();

      if (!Ember.isEmpty(routeVehicleDrivers)) {
        const currentRvd = routeVehicleDrivers.find(Rvd => {
          const {
            startTime,
            endTime
          } = Ember.getProperties(Rvd, 'startTime', 'endTime');
          return (0, _moment.default)(endTime).isAfter(now) && (0, _moment.default)(startTime).isBefore(now);
        });

        if (!Ember.isEmpty(currentRvd)) {
          return currentRvd;
        }
      }

      return null;
    }),
    isAssignable: Ember.computed('routeVehicleDrivers.[]', function () {
      return Ember.get(this, 'routeVehicleDrivers.length') === 0;
    }),
    isBroken: Ember.computed('status', function () {
      return Ember.get(this, 'status') === 'breakdown';
    }),

    isVehicleAvailable(startTime, endTime) {
      let retVal = true;
      const routeVehicleDrivers = Ember.get(this, 'routeVehicleDrivers');

      if (!Ember.isEmpty(routeVehicleDrivers)) {
        routeVehicleDrivers.forEach(routeVehicleDriver => {
          if (startTime.isSameOrAfter(routeVehicleDriver.get('startTime')) && startTime.isSameOrBefore(routeVehicleDriver.get('endTime')) || endTime.isSameOrAfter(routeVehicleDriver.get('startTime')) && endTime.isSameOrBefore(routeVehicleDriver.get('endTime'))) {
            retVal = false;
          }
        });
      }

      return retVal;
    },

    noOfAmbulatorySeats: Ember.computed.readOnly('vehicleType.noOfAmbulatorySeats'),
    noOfWideAmbulatorySeats: Ember.computed.readOnly('vehicleType.noOfWideAmbulatorySeats'),
    noOfWheelChairSeats: Ember.computed.readOnly('vehicleType.noOfWheelChairSeats'),
    noOfWideWheelChairSeats: Ember.computed.readOnly('vehicleType.noOfWideWheelChairSeats'),
    noOfServiceAnimalSeats: Ember.computed.readOnly('vehicleType.noOfServiceAnimalSeats'),
    noOfAmbulatoryFlexSeats: Ember.computed.readOnly('vehicleType.noOfAmbulatoryFlexSeats'),
    noOfWheelChairFlexSeats: Ember.computed.readOnly('vehicleType.noOfWheelChairFlexSeats'),
    formattedAvailabilities: Ember.computed('vehicleAvailabilities', 'active', function () {
      const active = this.get('active');
      const formattedObject = {
        header: null,
        values: [],
        fieldName: 'Availabilities'
      };
      const availabilities = [];
      const vehicleAvailabilities = this.get('vehicleAvailabilities');

      if (!Ember.isEmpty(vehicleAvailabilities)) {
        vehicleAvailabilities.forEach(function (item) {
          const startTime = (0, _moment.default)(item.get('startTime'));
          const endTime = (0, _moment.default)(item.get('endTime'));
          const now = (0, _moment.default)();

          if (active && now.isBetween(startTime, endTime)) {
            formattedObject.header = null;
            availabilities.push((0, _formatTextExtension.formatTextExtension)('mainItem', `${startTime.format(_environment.default.dateTimeFormat.dateMoment)} - ${endTime.format(_environment.default.dateTimeFormat.dateMoment)}`));

            if (item.monday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Monday ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
            }

            if (item.tuesday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Tuesday  ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
            }

            if (item.wednesday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Wednesday ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
            }

            if (item.thursday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Thursday ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
            }

            if (item.friday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Friday ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
            }

            if (item.saturday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Saturday ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
            }

            if (item.sunday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Sunday ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
            }
          }

          formattedObject.values = availabilities;
        });
      }

      return formattedObject;
    }),
    formattedBreaks: Ember.computed('shiftBreaks', 'active', function () {
      const active = this.get('active');
      const shiftBreaks = this.get('shiftBreaks');
      const formattedObject = {
        header: null,
        values: [],
        fieldName: 'Breaks'
      };
      const breaks = [];
      if (!shiftBreaks) return formattedObject;
      shiftBreaks.forEach(function (item) {
        const startTime = (0, _moment.default)(item.get('startTime'));
        const endTime = (0, _moment.default)(item.get('endTime'));
        const now = (0, _moment.default)();

        if (active && now.isBetween(startTime, endTime)) {
          formattedObject.header = null;
          breaks.push((0, _formatTextExtension.formatTextExtension)('mainItem', `${startTime.format(_environment.default.dateTimeFormat.dateMoment)} - ${endTime.format(_environment.default.dateTimeFormat.dateMoment)}`));

          if (item.monday) {
            breaks.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Monday ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
          }

          if (item.tuesday) {
            breaks.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Tuesday  ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
          }

          if (item.wednesday) {
            breaks.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Wednesday ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
          }

          if (item.thursday) {
            breaks.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Thursday ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
          }

          if (item.friday) {
            breaks.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Friday ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
          }

          if (item.saturday) {
            breaks.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Saturday ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
          }

          if (item.sunday) {
            breaks.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Sunday ${item.formattedShiftStart} - ${item.formattedShiftEnd}`));
          }
        }

        formattedObject.values = breaks;
      });
      return formattedObject;
    }),
    capacityCounts: Ember.computed.readOnly('vehicleType.capacityCounts'),
    vehicleStatus: Ember.computed('lastEmergencyMessage.{isEmergency,isAcknowledged,isCanceled,isAcknowledgedPending}', 'status', function () {
      const message = this.get('lastEmergencyMessage');
      const status = this.get('status');

      if (status === 'breakdown') {
        return VEHICLE_BREAKDOWN_STATUS;
      }

      if (!message) return '';

      if (message.get('isEmergency')) {
        return 'Emergency Received';
      }

      if (message.get('isAcknowledged')) {
        return 'Emergency Acknowledged';
      }

      return '';
    }),
    mapId: Ember.computed('lastEmergencyMessage.{isEmergency,isAcknowledged,isCanceled}', 'vehicleStatus', 'hasPowerOn', 'isGpsFailure', 'isRoadSupervisor', 'isCommFailure', 'isLeaveGarageSignOf', 'isOnBreak', function () {
      let id = 'iq-vehicle';
      const otp = this.get('otp');
      const message = this.get('lastEmergencyMessage');
      const vehicleStatus = this.get('vehicleStatus');
      const hasPowerOn = this.get('hasPowerOn');
      const isGpsFailure = this.get('isGpsFailure');
      const isRoadSupervisor = this.get('isRoadSupervisor');
      const isCommFailure = this.get('isCommFailure');
      const isEmergency = message && (message.get('isEmergency') || message.get('isAcknowledged'));
      const isLeaveGarageSignOff = this.get('isLeaveGarageSignOff');
      const isOnBreak = this.get('isOnBreak');

      if (vehicleStatus === VEHICLE_BREAKDOWN_STATUS) {
        id += '-breakdown';
        return id;
      }

      if (isEmergency && (otp === 'D' || 'In Danger')) {
        id += '-emergency-danger';
        return id;
      }

      if (isEmergency && (otp === 'L' || 'Late')) {
        id += '-emergency-late';
        return id;
      }

      if (isEmergency) {
        id += '-emergency';
        return id;
      }

      if (isCommFailure) {
        id += '-communication-failure';
        return id;
      } // These events priority are lower than emergency;


      switch (otp) {
        case 'D':
        case 'In Danger':
          id += '-danger';
          return id;

        case 'L':
        case 'Late':
          id += '-late';
          return id;

        default:
          break;
      }

      if (isOnBreak) {
        id += '-break';
        return id;
      }

      if (hasPowerOn || isLeaveGarageSignOff) {
        id += '-powered-on';
        return id;
      }

      if (isGpsFailure) {
        id += '-gps-failure';
        return id;
      }

      if (isRoadSupervisor) {
        id += '-roadsup';
        return id;
      }

      return id;
    })
  });

  _exports.default = _default;
});