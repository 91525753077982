define("adept-iq/pods/components/side-drawer/activity-log/vehicle-activity-log/config", ["exports", "moment", "adept-iq/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.columns = void 0;
  const columns = [{
    label: 'Activity',
    valuePath: 'activity'
  }, {
    label: 'Activity Time',
    valuePath: 'date',
    format: date => (0, _moment.default)(date).format(_environment.default.dateTimeFormat.dateTimeMoment)
  }, {
    label: 'Log Time',
    valuePath: 'logTime',
    format: logTime => (0, _moment.default)(logTime).format(_environment.default.dateTimeFormat.dateTimeMoment)
  }, {
    label: 'Details',
    valuePath: 'details'
  }, {
    label: 'User',
    valuePath: 'user'
  }];
  _exports.columns = columns;
});