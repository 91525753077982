define("adept-iq/pods/components/rider-management-widget/rider-certification/appeal-process/component", ["exports", "adept-iq/config/placeholders"], function (_exports, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    rider: Ember.inject.service(),
    PHONE_NUMBER_PLACEHOLDER: _placeholders.PHONE_NUMBER_PLACEHOLDER,

    init() {
      this._super(...arguments);
    },

    appealedDate: Ember.computed('riderCertificationRecord.appealedDate', function () {
      const appealDate = this.get('riderCertificationRecord.appealedDate');
      return appealDate ? appealDate : null;
    }),
    appealHearingDate: Ember.computed('riderCertificationRecord.appealHearingDate', function () {
      const appealHearingDate = this.get('riderCertificationRecord.appealHearingDate');
      return appealHearingDate ? appealHearingDate : null;
    }),
    appealDecisionDate: Ember.computed('riderCertificationRecord.appealDecisionDate', function () {
      const appealDecisionDate = this.get('riderCertificationRecord.appealDecisionDate');
      return appealDecisionDate ? appealDecisionDate : null;
    }),
    actions: {
      onDateValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onPhoneValueChange(record, maskObj) {
        // match[0] = area code
        // match[1] = first part of 3 digit phone number
        // match[2] = second part of 4 digit phone number
        const match = maskObj.masked.replace(/_/, '').split('-');
        const setPhoneNumber = record;
        setPhoneNumber.set('appealPhone', match[0] + match[1] + match[2]);
        setPhoneNumber.set('appealPhone', maskObj.unmasked);
      }

    }
  });

  _exports.default = _default;
});