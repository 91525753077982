define("adept-iq/tests/factories/vehicle-capacity-type", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('vehicle-capacity-type', {
    default: {
      name: 'ambulatory',
      engineId: '1',
      engineName: 'some engine name',
      constrainedByLIFO: false,
      dwellFactor: 5,
      description: 'some description'
    }
  });
});