define("adept-iq/models/avlm-vehicle-event", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    lat: attr('number'),
    lng: attr('number'),
    timestamp: attr('date'),
    vehicleId: attr('string'),
    receivedAt: attr('date'),
    heading: attr('number'),
    speed: attr('number'),
    odo: attr('number'),
    eventType: attr('string', {
      defaultValue: 'GPS Ping'
    }),
    routeId: attr('number'),
    routeName: attr('number'),
    stopType: attr('string', {
      defaultValue: 'N/A'
    }),
    stopAddress: attr('string', {
      defaultValue: 'N/A'
    }),
    passengerLName: attr('string', {
      defaultValue: 'N/A'
    }),
    passengerFName: attr('string', {
      defaultValue: 'N/A'
    }),
    passengerId: attr('string', {
      defaultValue: 'N/A'
    }),
    isReconciled: attr('boolean', {
      defaultValue: false
    }),
    vehicle: belongsTo('avlm-vehicle'),
    tripId: attr('number'),
    stopId: attr('string'),
    type: attr('string', {
      defaultValue: 'GPS Ping'
    }),
    time: Ember.computed('timestamp', function () {
      return (0, _moment.default)(this.get('timestamp')).format('hh:mm A');
    }),
    latlng: Ember.computed('lat', 'lng', function () {
      if (!Ember.isNone(this.get('lat')) && !Ember.isNone(this.get('lng'))) {
        return [`${this.get('lat')}`, `${this.get('lng')}`];
      }

      return null;
    }),
    eventId: Ember.computed('eventId', function () {
      return (0, _moment.default)(this.get('timestamp')).format() + this.get('vehicleId');
    }),
    formattedStopAddress: Ember.computed('eventType', function () {
      return this.get('eventType') !== 'GPS Ping' ? this.get('stopAddress') : 'N/A';
    })
  });

  _exports.default = _default;
});