define("adept-iq/serializers/rider-place", ["exports", "adept-iq/serializers/-rms-riderManagement"], function (_exports, _rmsRiderManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rmsRiderManagement.default.extend({
    attrs: {
      name: {
        serialize: false
      },
      isSoftDeleted: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      if (!Ember.isNone(json.data.relationships)) {
        const riderPlaceTypeRelationship = json.data.relationships.riderPlaceType;

        if (!Ember.isNone(riderPlaceTypeRelationship)) {
          riderPlaceTypeRelationship.data.type = 'riderPlaceTypeName';
          json.data.relationships.riderPlaceTypeName = json.data.relationships.riderPlaceType;
          delete json.data.relationships.riderPlaceType;
        }
      }

      return json;
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      return this._super(...arguments);
    },

    normalize(modelClass, resourceHash) {
      if (resourceHash.relationships) {
        if (!Ember.isNone(resourceHash.relationships.riderPlaceTypeName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.riderPlaceType = {
            data: {
              type: 'rider-place-type',
              id: resourceHash.relationships.riderPlaceTypeName.data.id
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.riderPlaceTypeName;
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});