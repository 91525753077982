define("adept-iq/models/avlm-rider-event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    anchor: attr('string'),
    type: attr('string'),
    noteForDriver: attr('string'),
    scheduledTime: attr('date'),
    promisedTime: attr('date'),
    serviceStartTime: attr('date'),
    serviceEndTime: attr('date'),
    avlmRider: belongsTo('avlm-rider'),
    avlmStopPoint: belongsTo('avlm-stop-point'),
    avlmSchedule: belongsTo('avlm-schedule')
  });

  _exports.default = _default;
});