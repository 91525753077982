define("adept-iq/pods/components/side-drawer/system-config/system-config-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NEabbjaG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[11,\"class\",\"header-title\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"header-buttons\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"newRecord\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[12,\"disabled\",[23,[\"disableBtns\",\"new\"]]],[9],[7,\"span\"],[9],[0,\"New\"],[10],[3,\"action\",[[22,0,[]],\"new\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"button\"],[12,\"disabled\",[23,[\"disableBtns\",\"edit\"]]],[9],[7,\"span\"],[9],[0,\"Edit\"],[10],[3,\"action\",[[22,0,[]],\"edit\"]],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"deleteRecord\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[12,\"disabled\",[23,[\"disableBtns\",\"delete\"]]],[9],[7,\"span\"],[9],[0,\"Delete\"],[10],[3,\"action\",[[22,0,[]],\"delete\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"button\"],[12,\"disabled\",[21,\"disableSavebtn\"]],[9],[7,\"span\"],[9],[0,\"Save\"],[10],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"undoRecord\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[12,\"disabled\",[21,\"disableUndobtn\"]],[9],[7,\"span\"],[9],[0,\"Undo\"],[10],[3,\"action\",[[22,0,[]],\"undo\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/side-drawer/system-config/system-config-header/template.hbs"
    }
  });

  _exports.default = _default;
});