define("adept-iq/models/agency-service", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    agencyServiceName: attr('string'),
    agency: belongsTo('agency'),
    calendars: hasMany('route-calendar')
  });

  _exports.default = _default;
});