define("adept-iq/pods/components/side-drawer/bar-chart-settings/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COLUMNS = {
    routeColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'status',
          editable: true,
          hidden: false,
          format: 'hh:mm A',
          defaultWidth: 75
        },
        filterTypeId: 'stringEq',
        filterValues: ['Scheduled']
      }
    },
    tripColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'status',
          editable: true,
          hidden: false,
          defaultWidth: 75
        },
        filterTypeId: null,
        filterValues: []
      }
    }
  };
  const VisualOptions = [{
    id: 'value',
    name: 'Show Value',
    isChecked: true
  }, {
    id: 'percentage',
    name: 'Show Percentage',
    isChecked: false
  }, {
    id: 'total',
    name: 'Show Total',
    isChecked: true
  }];
  var _default = [{
    name: 'Trips',
    modelName: 'iq-trip',
    columns: COLUMNS.tripColumns,
    displayOptions: {
      selected: null,
      options: [{
        id: 'otp',
        name: 'OTP Status',
        label: 'OTP',
        valueKey: 'otp',
        valueCategories: [{
          label: 'On Time',
          value: 'O'
        }, {
          label: 'Late',
          value: 'L'
        }, {
          label: 'In Danger',
          value: 'D'
        }, {
          label: 'Early',
          value: 'E'
        }],
        metricOption: {
          id: 'count',
          name: 'Count'
        },
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      }, {
        id: 'plannedTravelNeeds',
        name: 'Planned Travel Needs',
        label: 'Planned Travel Needs',
        valueKey: 'plannedTravelNeeds',
        valueCategories: [{
          label: 'Ambulatory',
          value: 'ambulatory'
        }, {
          label: 'Wide Wheelchair',
          value: 'wideWheelchair'
        }, {
          label: 'Wheelchair',
          value: 'wheelchair'
        }, {
          label: 'Wide Ambulatory',
          value: 'wideAmbulatory'
        }, {
          label: 'Service Animal',
          value: 'serviceAnimal'
        }],
        metricOption: {
          id: 'sumOccurrences',
          name: 'Sum'
        },
        metricOptions: [{
          id: 'sumOccurrences',
          name: 'Sum'
        }]
      }]
    },
    title: '',
    visualOptions: VisualOptions
  }, {
    name: 'Routes',
    modelName: 'route',
    columns: COLUMNS.routeColumns,
    displayOptions: {
      selected: {
        id: 'otp',
        name: 'OTP Status',
        label: 'OTP',
        valueKey: 'otp',
        valueCategories: [{
          label: 'On Time',
          value: 'O'
        }, {
          label: 'Late',
          value: 'L'
        }, {
          label: 'In Danger',
          value: 'D'
        }, {
          label: 'Early',
          value: 'E'
        }],
        metricOption: {
          id: 'count',
          name: 'Count'
        },
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      },
      options: [{
        id: 'otp',
        name: 'OTP Status',
        label: 'OTP',
        valueKey: 'otp',
        valueCategories: [{
          label: 'On Time',
          value: 'O'
        }, {
          label: 'Late',
          value: 'L'
        }, {
          label: 'In Danger',
          value: 'D'
        }, {
          label: 'Early',
          value: 'E'
        }],
        metricOption: null,
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      }]
    },
    title: '',
    visualOptions: VisualOptions
  }];
  _exports.default = _default;
});