define("adept-iq/classes/rules/eligibility-rules/conditional-eligibility-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type", "adept-iq/models/cs-config-category", "lodash"], function (_exports, _baseRule, _ruleResultType, _csConfigCategory, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ConditionalEligibilityRule = void 0;

  class ConditionalEligibilityRule extends _baseRule.default {
    doEvaluate(ruleContext) {
      const {
        bookingService,
        systemConfigService
      } = ruleContext;
      const selectedRiders = bookingService.get('selectedRiders');
      const conditionalEligibilityCategories = systemConfigService.findEligibilityCategories(_csConfigCategory.ELIGIBILITY_CATEGORY_TYPES.CONDITIONAL); //if  system  is not configured the conditional eligibility,  it return true;

      if (conditionalEligibilityCategories.length === 0) {
        return _ruleResultType.RESULT_TYPE_YES;
      } // filter only conditional eligibility riders


      const conditionalRiders = selectedRiders.filter(rider => {
        const allConditions = (0, _lodash.flatten)(rider.get('eligibility.categories').map(category => category.conditions || []));
        return allConditions.length !== 0;
      }); // check any one of rider is having the conditional eligibility

      const containsConditionalEligibility = conditionalRiders.every(rider => {
        return this.checkRiderConditionalEligibility(rider, conditionalEligibilityCategories);
      });
      if (containsConditionalEligibility) return _ruleResultType.RESULT_TYPE_YES;
      return _ruleResultType.RESULT_TYPE_NO;
    } // compare rider's category.type value with conditional eligibility configuration value  (value.name)
    // category.type should equal to value.name


    checkRiderConditionalEligibility(riderExternal, conditionalEligibilityCategories) {
      const CONDITIONAL_CATEGORY_NAMES = conditionalEligibilityCategories.map(category => {
        const categoryName = category.value.name || category.value.Name;
        return categoryName.trim().toLowerCase();
      });
      const riderEligibilityCategories = riderExternal.get('eligibility.categories');
      return riderEligibilityCategories.every(category => {
        return CONDITIONAL_CATEGORY_NAMES.includes(category.type.toLowerCase());
      });
    }

  }

  _exports.ConditionalEligibilityRule = ConditionalEligibilityRule;
  ConditionalEligibilityRule.RULE_NAME = 'ConditionalEligibilityRule';
});