define("adept-iq/data/routeInformationData", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.data = void 0;
  const data = [{
    'routeId': 123456,
    'routeType': 'Regular',
    'notes': 'Notes abc',
    'travelTimeInSeconds': 111,
    'vehicle': {
      'id': 759901,
      'odometerStart': 123,
      'odometerEnd': 456,
      'location': {
        'identifier': 'abc',
        'address': '1976 René-Lévesque Blvd',
        'zone': 'Blvd',
        'notes': 'notes 123'
      }
    },
    'driver': {
      'id': 7590,
      'driverPhoneNo': '0123456789',
      'driverName': 'John',
      'shiftStartTime': '12:05',
      'shiftEndTime': '22:10',
      'driverStartTime': '12:05',
      'driverEndTime': '22:10'
    }
  }];
  _exports.data = data;
});