define("adept-iq/models/etanav-stop-point", ["exports", "ember-data", "adept-iq/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    activeContext: Ember.inject.service(),
    eta: attr('date'),
    notBefore: attr('date'),
    status: attr('string'),
    lat: attr('number'),
    lng: attr('number'),
    secondsToNext: attr('number'),
    slackSeconds: attr('number'),
    dwell: attr('number'),
    type: attr('string'),
    stopPoint: belongsTo('stop-point'),
    avlmStopPoint: belongsTo('avlm-stop-point'),
    etaNavRoute: belongsTo('etanav-route'),
    minutesToNext: Ember.computed('secondsToNext', function () {
      const secondsToNext = this.get('secondsToNext') ? this.get('secondsToNext') : '0'; //convert secondsToNext into minutes

      return Math.floor(parseInt(secondsToNext, 10) / 60);
    }),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    etaChange: !_environment.default.APP.avlmLite ? Ember.observer('eta', function () {
      const oldETA = this.get('_oldETA');
      const eta = this.get('eta');
      if (Ember.isEmpty(eta)) return;else if (Ember.isEmpty(oldETA) && Ember.isPresent(eta)) {
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['stop-point']);
        this.set('_oldETA', eta);
      } else if (oldETA.getTime() !== eta.getTime()) {
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['stop-point']);
        this.set('_oldETA', eta);
      }
    }) : null
  });

  _exports.default = _default;
});