define("adept-iq/pods/components/table/cells/user-editable-enum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "diTR3eCj",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[23,[\"isDoubleClick\"]]],null,{\"statements\":[[4,\"component\",[[27,\"if\",[[23,[\"allowMultiple\"]],\"power-select-multiple\",\"power-select\"],null]],[[\"selected\",\"options\",\"disabled\",\"searchEnabled\",\"triggerClass\",\"dropdownClass\",\"registerAPI\",\"onkeydown\",\"onchange\"],[[27,\"readonly\",[[23,[\"value\"]]],null],[27,\"readonly\",[[23,[\"options\"]]],null],[27,\"readonly\",[[23,[\"disabled\"]]],null],[27,\"readonly\",[[23,[\"allowMultiple\"]]],null],\"can-focus\",[27,\"readonly\",[[23,[\"dropdownClass\"]]],null],[27,\"action\",[[22,0,[]],\"onDidInsertDropdownElement\"],null],[27,\"action\",[[22,0,[]],\"onKeydown\"],null],[27,\"action\",[[22,0,[]],\"onChange\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/table/cells/user-editable-enum/template.hbs"
    }
  });

  _exports.default = _default;
});