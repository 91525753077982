define("adept-iq/pods/components/rider-management-widget/rider-funding/component", ["exports", "ember-concurrency", "lodash", "adept-iq/utils/unwrapProxy", "moment"], function (_exports, _emberConcurrency, _lodash, _unwrapProxy, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    rider: Ember.inject.service(),
    booking: Ember.inject.service(),
    riderFundingRecord: null,
    classNames: ['rider-reasonDenied-options-select'],
    riderFundingNameOptions: null,
    riderId: Ember.computed.readOnly('riderChangeSet.id'),
    tableRef: null,
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    startDate: Ember.computed('riderFundingRecord.startDate', function () {
      const startDate = this.get('riderFundingRecord.startDate');
      return startDate ? startDate : null;
    }),
    endDate: Ember.computed('riderFundingRecord.endDate', function () {
      const endDate = this.get('riderFundingRecord.endDate');
      return endDate ? endDate : null;
    }),
    selectedFundingNameObject: Ember.computed('riderFundingRecord.fundingAgencyName', function () {
      const riderFundingNameOptions = this.get('riderFundingNameOptions');
      const fundingAgencyName = this.get('riderFundingRecord.fundingAgencyName');
      if (Ember.isEmpty(riderFundingNameOptions)) return null;
      return riderFundingNameOptions.find(option => {
        return option.name === fundingAgencyName;
      });
    }),

    async init() {
      this._super(...arguments);

      this.onNewRiderFundingRecord = this.onNewRiderFundingRecord.bind(this);
      this.onEditRiderFundingRecord = this.onEditRiderFundingRecord.bind(this);
      this.onDeleteRiderFundingRecord = this.onDeleteRiderFundingRecord.bind(this);
      this.onUndoRiderFundingRecord = this.onUndoRiderFundingRecord.bind(this);
      this.OnTableRowClick = this.OnTableRowClick.bind(this);
      this.validateRiderFundingRequiredField = this.validateRiderFundingRequiredField.bind(this);
      this.checkFundedFutureTripsAndBookings = this.checkFundedFutureTripsAndBookings.bind(this);
      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onNewRiderFundingRecord = this.get('onNewRiderFundingRecord');
      const onSaveRiderFundingRecord = this.get('onSaveRiderFundingRecord');
      const onEditRiderFundingRecord = this.get('onEditRiderFundingRecord');
      const onDeleteRiderFundingRecord = this.get('onDeleteRiderFundingRecord');
      const onUndoRiderFundingRecord = this.get('onUndoRiderFundingRecord');
      const riderFundingAgencies = await this.get('rider').getConfigFundingAgencies();
      const riderFundingNameOptions = riderFundingAgencies.map(Rfr => {
        return {
          displayName: Rfr.get('value.displayName'),
          name: Rfr.get('name')
        };
      });
      this.setProperties({
        riderFundingNameOptions
      });
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveRiderFundingRecord,
          newAction: onNewRiderFundingRecord,
          deleteAction: onDeleteRiderFundingRecord,
          editAction: onEditRiderFundingRecord,
          undoAction: onUndoRiderFundingRecord,
          isNewBtnDisabled: false,
          isEditBtnDisabled: true,
          isDeleteBtnDisabled: true,
          isEditing: false
        });
      });
    },

    OnTableRowClick(selectedRiderFunding) {
      this.get('onUndoRiderFundingRecord')();
      this.set('riderFundingRecord', (0, _unwrapProxy.unwrapProxy)(selectedRiderFunding));
      this.set('editAPI.isEditBtnDisabled', false);
      this.set('editAPI.isDeleteBtnDisabled', false);
    },

    actions: {
      onDateValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onRiderNameChange(record, value) {
        const riderFundingAgency = this.get('store').peekRecord('funding-agency', value.name);
        record.set('fundingAgencyName', value.name);
        record.set('fundingAgency', riderFundingAgency);
      }

    },

    onNewRiderFundingRecord() {
      const riderFundingRecord = this.get('store').createRecord('rider-funding');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
      }

      this.set('riderFundingRecord', riderFundingRecord);
      this.set('editAPI.isNewBtnDisabled', true);
      this.set('editAPI.isEditBtnDisabled', true);
      this.set('editAPI.isDeleteBtnDisabled', true);
      this.set('editAPI.isEditing', true);
    },

    onEditRiderFundingRecord() {
      const riderFundingRecordToEdit = this.get('tableRef.checkedRows.firstObject');
      this.set('riderFundingRecord', riderFundingRecordToEdit);
      this.set('editAPI.isNewBtnDisabled', true);
      this.set('editAPI.isEditBtnDisabled', true);
      this.set('editAPI.isDeleteBtnDisabled', true);
      this.set('editAPI.isEditing', true);
    },

    confirmRiderFundingDeletion(canDelete, riderFundingRecord) {
      const tip = canDelete ? 'Are you sure you want to delete this funding record?' : 'Unable to delete. Rider has active trips that use this funding agency.';
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');

      if (canDelete) {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: tip,
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            await riderFundingRecord.destroyRecord();
            this.get('tableRef').refreshData(); //to refresh table data

            notifications.success('Funding information successfully deleted');
            this.set('editAPI.isEditBtnDisabled', true);
            this.set('editAPI.isDeleteBtnDisabled', true);
            tooltip.reset();
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            tooltip.reset();
          }
        });
      } else {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: tip,
          warning: true,
          hasOverlay: true,
          primaryActionText: 'Ok',
          primaryAction: () => {
            tooltip.reset();
          },
          closeAction: () => {
            tooltip.reset();
          }
        });
      }
    },

    async checkFundedFutureTripsAndBookings(riderFundingRecord) {
      const currentDate = (0, _moment.default)().format();
      const rider = this.get('riderChangeSet').get('_internalModel');
      const legFilter = `and(eq('riderId','${rider.id}'),eq('fundingAgencyName',${riderFundingRecord.get('fundingAgencyName')}),gt('requestTime',${currentDate}))`;
      const futureBookingIds = await this.get('booking').getFutureBookingsByLeg(legFilter);
      const tripFilter = `in(booking,('${futureBookingIds}'))`;
      const futureTrips = await this.get('store').query('trip', {
        filter: tripFilter
      });
      const subscriptionFilter = `and(eq('riderId','${rider.id}'),eq('fundingAgencyName',${riderFundingRecord.get('fundingAgencyName')}),lt('startDate',${currentDate}),gt('endDate',${currentDate}))`;
      const subscriptionCandidates = await this.get('booking').getSubscriptions(subscriptionFilter);
      const futureBookingOccurrences = subscriptionCandidates.find(subscription => subscription.get('remainingOccurrences') > 0);
      return futureBookingIds.length < 1 && futureTrips.length < 1 && !futureBookingOccurrences;
    },

    async onDeleteRiderFundingRecord() {
      const riderFundingRecordToDelete = this.get('tableRef.checkedRows.firstObject');
      this.set('riderFundingRecord', riderFundingRecordToDelete);
      const canDelete = await this.checkFundedFutureTripsAndBookings(riderFundingRecordToDelete);
      return this.confirmRiderFundingDeletion(canDelete, riderFundingRecordToDelete);
    },

    async onUndoRiderFundingRecord() {
      const riderFundingId = this.get('rider').setLastAccessedRiderFundingId();
      const riderFundingRecord = this.get('riderFundingRecord');
      const selectedRow = this.get('selectedRow');

      if (riderFundingId !== null) {
        this.set('editAPI.isEditBtnDisabled', false);
        this.set('editAPI.isDeleteBtnDisabled', false);
      }

      if (Ember.isEmpty(selectedRow)) {
        this.set('editAPI.isEditBtnDisabled', true);
        this.set('editAPI.isDeleteBtnDisabled', true);
      }

      if (Ember.isPresent(riderFundingRecord)) {
        riderFundingRecord.rollbackAttributes();
        const fundingAgencyName = riderFundingRecord.get('fundingAgencyName');

        if (Ember.isPresent(fundingAgencyName)) {
          const riderFundingAgency = this.get('store').peekRecord('funding-agency', fundingAgencyName);
          riderFundingRecord.set('fundingAgency', riderFundingAgency);
        }
      }

      this.set('editAPI.isNewBtnDisabled', false);
      this.set('editAPI.isEditing', false);
      this.set('templateErrors', {});
    },

    validateRiderFundingRequiredField(riderFundingRecord) {
      const tooltip = this.get('tooltip');
      const nameAndstartAndEndDateErrors = this.validateFundingStartAndEndDate(riderFundingRecord);
      const errors = (0, _lodash.compact)([].concat(nameAndstartAndEndDateErrors));
      const errorsObject = errors.reduce(function (result, item) {
        const key = item.key;
        result[key] = true;
        return result;
      }, {});
      this.set('templateErrors', errorsObject);

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.popConfirmation();
          }

        });
        return false;
      }

      return true;
    },

    validateFundingStartAndEndDate(riderFundingRecord) {
      const fundingAgencyName = riderFundingRecord.get('fundingAgencyName');
      const startDate = riderFundingRecord.get('startDate');
      const endDate = riderFundingRecord.get('endDate');
      const errors = [];

      if (!fundingAgencyName) {
        errors.push({
          key: 'fundingAgencyName',
          validation: ['Name is required']
        });
      }

      if (!startDate) {
        errors.push({
          key: 'startDate',
          validation: ['Start Date is required']
        });
      }

      if (!endDate) {
        errors.push({
          key: 'endDate',
          validation: ['End Date is required']
        });
      }

      if (startDate && endDate) {
        const startDateObj = new Date(riderFundingRecord.startDate);
        const endDateObj = new Date(riderFundingRecord.endDate);
        const endDateValidate = (endDateObj.getTime() - startDateObj.getTime()) / (1000 * 3600 * 24);

        if (endDateValidate < 0) {
          errors.push({
            key: 'endDate',
            validation: ['End Date must be after Start Date.']
          });
        }
      }

      return errors;
    },

    onSaveRiderFundingRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const rider = this.get('riderChangeSet').get('_internalModel');
      const riderFundingRecord = this.get('riderFundingRecord');
      const isValidFundingRequiredField = this.validateRiderFundingRequiredField(riderFundingRecord);
      const successMsg = 'Funding information successfully saved';
      const selectedRow = this.get('selectedRow');

      if (isValidFundingRequiredField) {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: 'Are you sure you want to save these changes?',
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            try {
              riderFundingRecord.set('rider', rider);
              const rS = await riderFundingRecord.save();
              this.get('rider').setLastAccessedRiderFundingId(rS.id);
              this.get('tableRef').refreshData();

              if (this.get('editAPI.isEditing')) {
                notifications.success(successMsg);
                this.set('editAPI.isEditing', false);
                this.set('editAPI.isNewBtnDisabled', false);

                if (Ember.isEmpty(selectedRow)) {
                  this.set('editAPI.isEditBtnDisabled', true);
                  this.set('editAPI.isDeleteBtnDisabled', true);
                } else {
                  this.set('editAPI.isEditBtnDisabled', false);
                  this.set('editAPI.isDeleteBtnDisabled', false);
                }
              } else {
                notifications.success(successMsg);
                this.set('editAPI.isNewBtnDisabled', false);
              }

              tooltip.reset();
            } catch (e) {
              notifications.warning('Funding record failed to save');
              tooltip.reset();
              console.warn(e); // eslint-disable-line
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          }
        });
      }

      yield Promise.resolve();
    })
  });

  _exports.default = _default;
});