define("adept-iq/models/cs-config-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    // these determine the `id` when persisted
    category: attr('string'),
    name: attr('string'),
    displayName: attr('string', {
      defaultValue: ''
    }),
    description: attr('string'),
    fieldConstraint: attr('string'),
    unit: attr('string'),
    type: attr('string'),
    value: attr('object'),
    defaultValue: attr('object'),
    configCategory: belongsTo('cs-config-category'),
    valueString: Ember.computed('value', function () {
      return JSON.stringify(this.get('value'));
    })
  });

  _exports.default = _default;
});