define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/category/table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    title: 'Category',
    defaultSortId: 'description',
    modelName: 'inspection-category',
    defaultSortAsc: true,
    queryModelData: true,
    selectFirstRow: true,
    tooltipTitle: 'Manage Lookup',
    columns: [{
      id: 'description',
      index: 0,
      type: 'text',
      label: 'Description',
      valuePath: 'description',
      defaultWidth: 200,
      searchable: true
    }]
  };
  _exports.default = _default;
});