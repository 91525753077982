define("adept-iq/tests/factories/service-window", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const serviceWindowTypes = [{
    name: 'Floating Break',
    serviceType: '9',
    searchWindowStart: 30,
    searchWindowEnd: 30,
    promiseWindowStart: 30,
    promiseWindowEnd: 30,
    dropWindowStart: 60,
    dropWindowEnd: 60,
    scheduleConfigurationName: 'default'
  }, {
    name: 'Fixed Break',
    serviceType: '8',
    searchWindowStart: 30,
    searchWindowEnd: 30,
    promiseWindowStart: 30,
    promiseWindowEnd: 30,
    dropWindowStart: 60,
    dropWindowEnd: 60,
    scheduleConfigurationName: 'default'
  }, {
    name: 'Appointment Anchored Trips',
    serviceType: '2',
    searchWindowStart: 30,
    searchWindowEnd: 30,
    promiseWindowStart: 30,
    promiseWindowEnd: 30,
    dropWindowStart: 20,
    dropWindowEnd: -5,
    scheduleConfigurationName: 'default'
  }];

  _emberDataFactoryGuy.default.define('service-window', {
    default: {},
    traits: {
      float: f => {
        f.name = serviceWindowTypes[0].name;
        f.serviceType = serviceWindowTypes[0].serviceType;
        f.searchWindowStart = serviceWindowTypes[0].searchWindowStart;
        f.searchWindowEnd = serviceWindowTypes[0].searchWindowEnd;
        f.promiseWindowStart = serviceWindowTypes[0].promiseWindowStart;
        f.promiseWindowEnd = serviceWindowTypes[0].promiseWindowEnd;
        f.dropWindowStart = serviceWindowTypes[0].dropWindowStart;
        f.dropWindowEnd = serviceWindowTypes[0].dropWindowEnd;
      },
      fixed: f => {
        f.name = serviceWindowTypes[1].name;
        f.serviceType = serviceWindowTypes[1].serviceType;
        f.searchWindowStart = serviceWindowTypes[1].searchWindowStart;
        f.searchWindowEnd = serviceWindowTypes[1].searchWindowEnd;
        f.promiseWindowStart = serviceWindowTypes[1].promiseWindowStart;
        f.promiseWindowEnd = serviceWindowTypes[1].promiseWindowEnd;
        f.dropWindowStart = serviceWindowTypes[1].dropWindowStart;
        f.dropWindowEnd = serviceWindowTypes[1].dropWindowEnd;
      },
      appointment: f => {
        f.name = serviceWindowTypes[2].name;
        f.serviceType = serviceWindowTypes[2].serviceType;
        f.searchWindowStart = serviceWindowTypes[2].searchWindowStart;
        f.searchWindowEnd = serviceWindowTypes[2].searchWindowEnd;
        f.promiseWindowStart = serviceWindowTypes[2].promiseWindowStart;
        f.promiseWindowEnd = serviceWindowTypes[2].promiseWindowEnd;
        f.dropWindowStart = serviceWindowTypes[2].dropWindowStart;
        f.dropWindowEnd = serviceWindowTypes[2].dropWindowEnd;
      }
    }
  });
});