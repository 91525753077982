define("adept-iq/pods/components/iq-widgets/map-widget/config/arrow", ["exports", "adept-iq/config/icon-paths"], function (_exports, _iconPaths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // this is only used by the Geocode service now; it should be removed
  var _default = {
    tripArrowPlanned: {
      icon: {
        src: _iconPaths.default.tripArrow.ontime,
        anchorX: 20,
        anchorY: 10,
        height: 20,
        width: 20,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      },
      rotationAngle: null,
      rotationOrigin: 'center right',
      color: '#555e63'
    },
    tripArrowPlannedDanger: {
      icon: {
        src: _iconPaths.default.tripArrow.danger,
        anchorX: 20,
        anchorY: 10,
        height: 20,
        width: 20,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      },
      rotationAngle: null,
      rotationOrigin: 'center right',
      color: '#E3621B'
    },
    tripArrowPlannedLate: {
      icon: {
        src: _iconPaths.default.tripArrow.late,
        anchorX: 20,
        anchorY: 10,
        height: 20,
        width: 20,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      },
      rotationAngle: null,
      rotationOrigin: 'center right',
      color: '#d13730'
    },
    tripArrowPlannedOffset: {
      icon: {
        src: _iconPaths.default.tripArrow.ontime,
        anchorX: 35,
        anchorY: 10,
        height: 35,
        width: 20,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      },
      rotationAngle: null,
      rotationOrigin: 'center right',
      color: '#555e63'
    },
    tripArrowPlannedOffsetLate: {
      icon: {
        src: _iconPaths.default.tripArrowOffset.late,
        anchorX: 35,
        anchorY: 10,
        height: 35,
        width: 20,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      },
      rotationAngle: null,
      rotationOrigin: 'center right',
      color: '#d13730'
    },
    tripArrowPlannedOffsetDanger: {
      icon: {
        src: _iconPaths.default.tripArrowOffset.danger,
        anchorX: 35,
        anchorY: 10,
        height: 35,
        width: 20,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      },
      rotationAngle: null,
      rotationOrigin: 'center right',
      color: '#E3621B'
    }
  };
  _exports.default = _default;
});