define("adept-iq/models/speed-region-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DAILY_RECUR_TYPE = _exports.CONTINUOUS_RECUR_TYPE = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  const CONTINUOUS_RECUR_TYPE = 'continuous';
  _exports.CONTINUOUS_RECUR_TYPE = CONTINUOUS_RECUR_TYPE;
  const DAILY_RECUR_TYPE = 'daily';
  _exports.DAILY_RECUR_TYPE = DAILY_RECUR_TYPE;

  var _default = Model.extend({
    name: attr('string'),
    start: attr('date'),
    end: attr('date'),
    startTime: attr('date'),
    endTime: attr('date'),
    message: attr('string'),
    recurrence: attr('string'),
    // daily or continuous
    blockout: attr('boolean', {
      defaultValue: false
    }),
    addresses: attr(),
    regions: attr(),
    isBlockoutRegionType: Ember.computed('blockout', function () {
      return this.get('blockout');
    })
  });

  _exports.default = _default;
});