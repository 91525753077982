define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map-filter/label-options", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel"], function (_exports, _react, _panel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const LabelOptions = () => {
    const {
      eventTableColumns,
      toggleEventTableLabels,
      toggleAllEventTableLabels,
      labelDisplayMode,
      setLabelDisplayMode
    } = (0, _panel.usePanel)();

    const [isEyeOpened, setIsEyeOpened] = _react.default.useState(true);

    const toggleEye = () => {
      toggleAllEventTableLabels(!isEyeOpened);
      setIsEyeOpened(!isEyeOpened);
    };

    return _react.default.createElement("div", {
      className: "filter-settings ember-view"
    }, _react.default.createElement("table", {
      className: "filter-settings-table map-filter-settings"
    }, _react.default.createElement("thead", null, _react.default.createElement("tr", null, _react.default.createElement("th", {
      className: "header-title",
      colSpan: "3"
    }, _react.default.createElement("h4", null, "Labels ", _react.default.createElement("span", {
      className: "visibility-column",
      onClick: () => toggleEye()
    }, isEyeOpened ? _react.default.createElement("i", {
      className: "fa fa-eye",
      "aria-hidden": "true"
    }) : _react.default.createElement("i", {
      className: "fa fa-eye-slash",
      "aria-hidden": "true"
    })))))), _react.default.createElement("thead", null, _react.default.createElement("tr", null, _react.default.createElement("th", {
      className: "reorder-column"
    }), _react.default.createElement("th", {
      className: "visibility-column"
    }))), _react.default.createElement("thead", null, _react.default.createElement("tr", null, _react.default.createElement("th", {
      colSpan: "3"
    }, _react.default.createElement("div", {
      style: {
        display: 'flex',
        alignItems: 'center'
      }
    }, _react.default.createElement("label", {
      style: {
        height: '24px',
        margin: '0 10px 0 0'
      }
    }, "Display \xA0"), _react.default.createElement("select", {
      id: "isdisplay",
      className: "filter_dropdown",
      onChange: e => setLabelDisplayMode(e.target.value),
      value: labelDisplayMode
    }, _react.default.createElement("option", {
      value: _panel.labelDisplayModes.onLeftClick
    }, "On Left Click"), _react.default.createElement("option", {
      value: _panel.labelDisplayModes.always
    }, "Always")))))), _react.default.createElement("tbody", {
      className: "ember-view"
    }, eventTableColumns && eventTableColumns.map(column => {
      return _react.default.createElement("tr", {
        key: `label-${column.id}`,
        className: "sortable-item ember-view"
      }, _react.default.createElement("td", {
        className: "reorder-column handle"
      }, _react.default.createElement("div", {
        role: "button",
        "data-sortable-handle": "",
        className: "ember-view"
      }, _react.default.createElement("img", {
        src: "/handles.png",
        className: "reorder-handles"
      }))), _react.default.createElement("td", {
        className: "visibility-column"
      }, _react.default.createElement("input", {
        id: column.id,
        className: "ember-checkbox ember-view",
        type: "checkbox",
        checked: column.isLabeledInMap,
        onChange: e => toggleEventTableLabels(column.id, e.target.checked)
      })), _react.default.createElement("td", {
        className: "label-column"
      }, column.label));
    }))));
  };

  var _default = LabelOptions;
  _exports.default = _default;
});