define("adept-iq/pods/components/rider-management-widget/rider-certification/component", ["exports", "lodash", "ember-concurrency", "adept-iq/utils/validator", "adept-iq/utils/unwrapProxy"], function (_exports, _lodash, _emberConcurrency, _validator, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    rider: Ember.inject.service(),
    riderCertificationRecord: null,
    tableRef: null,
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    riderId: Ember.computed.readOnly('riderChangeSet.id'),

    async init() {
      this._super(...arguments);

      this.onNewRiderCertificationRecord = this.onNewRiderCertificationRecord.bind(this);
      this.createRiderCertificationRecord = this.createRiderCertificationRecord.bind(this);
      this.onDeleteRiderCertificationRecord = this.onDeleteRiderCertificationRecord.bind(this);
      this.onEditRiderCertificationRecord = this.onEditRiderCertificationRecord.bind(this);
      this.onUndoRiderCertificationRecord = this.onUndoRiderCertificationRecord.bind(this);
      this.OnTableRowClick = this.OnTableRowClick.bind(this);
      const editAPI = this.get('editAPI');
      const onNewRiderCertificationRecord = this.get('onNewRiderCertificationRecord');
      const onSaveRiderCertificationsRecord = this.get('onSaveRiderCertificationsRecord');
      const onDeleteRiderCertificationRecord = this.get('onDeleteRiderCertificationRecord');
      const onEditRiderCertificationRecord = this.get('onEditRiderCertificationRecord');
      const onUndoRiderCertificationRecord = this.get('onUndoRiderCertificationRecord');
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveRiderCertificationsRecord,
          newAction: onNewRiderCertificationRecord,
          deleteAction: onDeleteRiderCertificationRecord,
          editAction: onEditRiderCertificationRecord,
          undoAction: onUndoRiderCertificationRecord,
          isNewBtnDisabled: false,
          isEditBtnDisabled: true,
          isDeleteBtnDisabled: true,
          isEditing: false
        });
      });
      await this.fetchCertifications();
    },

    onNewRiderCertificationRecord() {
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
      }

      this.set('editAPI.isNewBtnDisabled', true);
      this.set('editAPI.isEditBtnDisabled', true);
      this.set('editAPI.isDeleteBtnDisabled', true);
      this.set('editAPI.isEditing', true);
      this.createRiderCertificationRecord();
    },

    OnTableRowClick(selectedRiderCertification) {
      this.get('onUndoRiderCertificationRecord')();
      this.set('riderCertificationRecord', (0, _unwrapProxy.unwrapProxy)(selectedRiderCertification));
      this.set('editAPI.isNewBtnDisabled', false);
      this.set('editAPI.isEditBtnDisabled', false);
      this.set('editAPI.isDeleteBtnDisabled', false);
      this.set('editAPI.isEditing', false);
    },

    createRiderCertificationRecord() {
      const riderCertificationRecord = this.get('store').createRecord('rider-certification');
      this.set('riderCertificationRecord', riderCertificationRecord);
    },

    onEditRiderCertificationRecord() {
      const riderCertificationRecordToEdit = this.get('tableRef.checkedRows.firstObject');
      this.set('riderCertificationRecord', riderCertificationRecordToEdit);
      this.set('editAPI.isNewBtnDisabled', true);
      this.set('editAPI.isEditBtnDisabled', true);
      this.set('editAPI.isDeleteBtnDisabled', true);
      this.set('editAPI.isEditing', true);
    },

    confirmRiderCertificationDeletion(tip, riderCertificationRecord) {
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: tip,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          await riderCertificationRecord.destroyRecord();
          notifications.success('Rider CERTIFICATION Successfully Deleted');
          this.get('tableRef').refreshData();
          this.set('editAPI.isNewBtnDisabled', false);
          this.set('editAPI.isEditBtnDisabled', true);
          this.set('editAPI.isDeleteBtnDisabled', true);
          this.set('editAPI.isEditing', false);
          tooltip.reset();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        }
      });
    },

    onDeleteRiderCertificationRecord() {
      const riderCertificationRecord = this.get('tableRef.checkedRows.firstObject');
      this.set('riderCertificationRecord', riderCertificationRecord);
      const tip = 'Are you sure you want to delete this rider certification record?';
      this.confirmRiderCertificationDeletion(tip, riderCertificationRecord);
    },

    onUndoRiderCertificationRecord() {
      const riderChangeSet = this.get('riderChangeSet');
      const riderCertificationRecord = this.get('riderCertificationRecord');
      let isNewRecord = false;

      if (Ember.isPresent(riderCertificationRecord)) {
        isNewRecord = riderCertificationRecord.isNew;
        riderCertificationRecord.rollbackAttributes();
      }

      if (isNewRecord) {
        this.set('editAPI.isEditBtnDisabled', true);
        this.set('editAPI.isDeleteBtnDisabled', true);
      } else {
        this.set('editAPI.isEditBtnDisabled', false);
        this.set('editAPI.isDeleteBtnDisabled', false);
      }

      this.set('editAPI.isNewBtnDisabled', false);
      this.set('editAPI.isEditing', false);
      riderChangeSet.rollback();
    },

    async fetchCertifications() {
      const riderId = this.get('riderChangeSet').get('_internalModel').id;
      const certificationQuery = `in(id,(${riderId}))&include=certifications`;
      await this.get('store').query('rider', {
        filter: certificationQuery
      });
    },

    isValidAppReceivedDate(riderCertificationRecord) {
      return !_lodash.default.isNil(riderCertificationRecord.get('appReceivedDate'));
    },

    onSaveRiderCertificationsRecord: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      const rider = this.get('riderChangeSet').get('_internalModel');
      const riderCertificationRecord = this.get('riderCertificationRecord');
      const isValidAppReceivedDate = yield this.isValidAppReceivedDate(riderCertificationRecord);
      const isNewRecord = riderCertificationRecord.get('isNew');
      const doctorEmail = riderCertificationRecord.get('doctorEmail') || '';
      const emailValid = Ember.isEmpty(doctorEmail) || (0, _validator.isEmailValid)(doctorEmail.trim());

      if (!isValidAppReceivedDate) {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: 'Application Received Date is required.',
          primaryActionText: 'Ok',
          primaryAction: () => {
            return tooltip.reset();
          }
        });
      } else if (!emailValid) {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: `"${doctorEmail}" is not a valid email.`,
          primaryActionText: 'Ok',
          primaryAction: () => {
            return tooltip.reset();
          }
        });
      } else {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: 'Are you sure you want to save these changes?',
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            try {
              riderCertificationRecord.set('rider', rider);
              const cResponse = await riderCertificationRecord.save();
              const cId = cResponse.id;
              this.get('rider').setLastAccessedCertificationId(cId);
              const certQuery = `in(id,(${cId}))`;
              await this.get('store').query('rider-certification', {
                filter: certQuery
              });

              if (this.get('editAPI.isEditing')) {
                notifications.success('RIDER CERTIFICATION SUCCESSFULLY EDITED');
              }

              this.get('tableRef').refreshData();
              notifications.success('RIDER CERTIFICATION SUCCESSFULLY ADDED');

              if (isNewRecord) {
                this.set('editAPI.isNewBtnDisabled', false);
                this.set('editAPI.isEditBtnDisabled', true);
                this.set('editAPI.isDeleteBtnDisabled', true);
                this.set('editAPI.isEditing', false);
              } else {
                this.set('editAPI.isNewBtnDisabled', false);
                this.set('editAPI.isEditBtnDisabled', false);
                this.set('editAPI.isDeleteBtnDisabled', false);
                this.set('editAPI.isEditing', false);
              }

              tooltip.reset();
            } catch (e) {
              notifications.warning('Rider Certification Record failed to save');
              tooltip.reset();
              console.warn(e); // eslint-disable-line
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            tooltip.reset();
          }
        });
      }

      yield Promise.resolve();
    })
  });

  _exports.default = _default;
});