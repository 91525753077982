define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/termination-reason/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aDBJgqCK",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"iq-widgets/vehicle-driver-manager-widget/tabs/lookup/termination-reason/table\",null,[[\"saveRecord\",\"hasPendingChanges\",\"validateRecord\",\"tableRef\"],[[27,\"action\",[[22,0,[]],\"onSaveDriverTerminationRecord\"],null],[23,[\"hasPendingChanges\"]],[23,[\"validate\"]],[23,[\"tableRef\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"mt-2\"],[9],[0,\"\\n\"],[4,\"generic-components/panel\",null,[[\"class\",\"title\",\"isCollapsible\"],[\"maint-type-panel\",\"Termination Reason\",false]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-auto\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[0,\" Description\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-5\"],[9],[0,\"\\n    \"],[1,[27,\"form-components/text\",null,[[\"class\",\"placeholder\",\"value\",\"disabled\",\"extra\",\"onChange\"],[[27,\"if\",[[23,[\"driverTerminationChangeSet\",\"error\",\"description\"]],\"error\"],null],[23,[\"DESCRIPTION_PLACEHOLDER\"]],[23,[\"driverTerminationChangeSet\",\"description\"]],[23,[\"disableForm\"]],[27,\"hash\",null,[[\"maxWithSpace\"],[\"32\"]]],[27,\"action\",[[22,0,[]],\"onInputValueChange\",[23,[\"driverTerminationChangeSet\"]],\"description\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/termination-reason/template.hbs"
    }
  });

  _exports.default = _default;
});