define("adept-iq/pods/components/side-drawer/map-legend-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eskQUppI",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[7,\"table\"],[11,\"id\",\"map-legend-table\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"th\"],[9],[0,\"\\n        \"],[7,\"h4\"],[9],[0,\"Legend\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"layer\",\"types\"]]],null,{\"statements\":[[0,\"    \"],[7,\"tr\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"canFilter\"]]],null,{\"statements\":[[0,\"          \"],[7,\"td\"],[11,\"class\",\"legend-checkbox\"],[9],[0,\"\\n              \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"checked\",\"change\"],[\"checkbox\",\"data-test-type-visibility-checkbox\",[27,\"readonly\",[[22,1,[\"isVisible\"]]],null],[27,\"action\",[[22,0,[]],\"setVisibility\",[22,1,[]]],null]]]],false],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"td\"],[11,\"class\",\"legend-checkbox\"],[9],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[22,1,[\"display\"]]],null,{\"statements\":[[0,\"\\n      \"],[7,\"td\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"map-legend-filter-icon\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"linePath\"]]],null,{\"statements\":[[0,\"              \"],[7,\"img\"],[12,\"src\",[22,1,[\"linePath\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"              \"],[7,\"img\"],[12,\"src\",[22,1,[\"iconPath\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"td\"],[9],[7,\"b\"],[9],[1,[22,1,[\"label\"]],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/side-drawer/map-legend-filter/template.hbs"
    }
  });

  _exports.default = _default;
});