define("adept-iq/pods/components/iq-widgets/cancel-subscription-trips/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['cancel-booking-form-side-drawer'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    booking: Ember.inject.service(),
    notifications: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    subscriptionService: Ember.inject.service('subscription'),
    subscriptionId: null,
    isProgressShown: false,
    editableSections: null,
    note: null,
    selectedCancelType: null,
    canSave: true,
    subTitle: null,
    isAllChecked: false,

    init() {
      this._super(...arguments);

      this.set('subscriptionId', this.get('editModal.editableRecords')[0].get('id'));
      this.set('editableSections', []);
      this.getSubscriptionTrips();
    },

    didInsertElement() {
      this._super(...arguments);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeCheckedProperyForSelectedSubscriptionTrips();
    },

    async cancelSubscriptionTrips() {
      try {
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        await this.get('subscriptionService').cancelFutureTrips(this.get('subscriptionId'));
        this.get('notifications').success('Subscription trip(s) successfully canceled');
        const [bookings, trips] = await this.get('apiContext').loadBookingsForSelectedExternalRiders();
        const subscriptions = await this.get('apiContext').loadSubscriptionsForSelectedExternalRiders();
        this.set('booking.currentBookings', bookings);
        this.set('booking.currentTrips', trips);
        this.set('booking.currentSubscriptions', subscriptions);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('editModal').reset();
        this.get('editModal').close();
      } catch (error) {
        this.get('notifications').warning(error.message);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('editModal').reset();
        this.get('editModal').close();
      }
    },

    async getSubscriptionTrips() {
      try {
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        const futureTrips = await this.get('subscriptionService').fetchTripsForCancelSubscription(this.get('subscriptionId'));
        this.set('subscriptionTrips', futureTrips);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      } catch (error) {
        this.get('workspace').set('isGlobalSpinnerVisible', false); // eslint-disable-next-line no-console

        console.error(error.message);
      }
    },

    //NYAAR-19594-remove the checked property for can cancel releated trips when closing the Cancel Subscription Trips Panel
    removeCheckedProperyForSelectedSubscriptionTrips() {
      const subscriptionTrips = this.get('subscriptionTrips');
      subscriptionTrips.setEach('isChecked', false);
    },

    actions: {
      onUndoClick() {
        const lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          const records = this.get('editableRecords') || [];
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(_ref => {
            let {
              record,
              properties
            } = _ref;
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onSaveClick() {
        const message = this.get('booking').cancelSubscriptionMessage();
        this.set('subTitle', message);
        this.toggleProperty('isProgressShown');
      },

      onXButtonClick() {
        this.toggleProperty('isProgressShown');
      },

      onCloseClick() {
        this.get('editModal').close();
      },

      onCancelClick() {
        this.toggleProperty('isProgressShown');
      },

      onConfirmClick() {
        if (this.get('booking').tripIds.length > 0) {
          return this.cancelSubscriptionTrips();
        }

        this.get('editModal').reset();
        this.get('editModal').close();
      }

    }
  });

  _exports.default = _default;
});