define("adept-iq/tests/factories/shift-break", ["ember-data-factory-guy", "faker", "moment"], function (_emberDataFactoryGuy, _faker, _moment) {
  "use strict";

  const date = new Date();

  _emberDataFactoryGuy.default.define('shift-break', {
    sequences: {
      randomBoolean: () => _faker.default.random.boolean(),
      randomStartTime: () => _faker.default.date.past(1, date),
      randomEndTime: () => _faker.default.date.future(3, date),
      randomShiftStart: () => (0, _moment.default)().startOf('day').add(6, 'hours').format('HH:mm A'),
      randomShiftEnd: () => (0, _moment.default)().endOf('day').subtract(6, 'hours').format('HH:mm A')
    },
    default: {
      state: 'planned',
      promisedStart: _emberDataFactoryGuy.default.generate('randomStartTime'),
      plannedDuration: 15,
      notes: 'Shift break notes',
      startTime: _emberDataFactoryGuy.default.generate('randomStartTime'),
      endTime: _emberDataFactoryGuy.default.generate('randomEndTime'),
      estimatedStart: _emberDataFactoryGuy.default.generate('randomShiftStart'),
      estimatedEnd: _emberDataFactoryGuy.default.generate('randomShiftEnd'),
      sunday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      monday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      tuesday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      wednesday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      thursday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      friday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      saturday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      promisedEnd: _emberDataFactoryGuy.default.generate('randomEndTime')
    }
  });
});