define("adept-iq/serializers/subscription-group", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    keyForAttribute: function (attr) {
      return attr;
    },

    serialize(snapshot, options) {
      const json = this._super(snapshot, options);

      return json;
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});