define("adept-iq/serializers/stop-point", ["exports", "adept-iq/serializers/-dispatch"], function (_exports, _dispatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    normalize(modelClass, resourceHash) {
      const relationships = resourceHash.relationships || {}; // If stopPoint having place relationship then this is a waypoint

      if (relationships.place) {
        relationships.waypointPlace = relationships.place;
        delete relationships.place;
      }

      const normalized = this._super(modelClass, resourceHash);

      if (normalized.data.attributes.parentType === 'break') {
        normalized.data.attributes.stopType = 'break';
      }

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      const relationships = payload.data.relationships ? payload.data.relationships : {};

      if (Ember.isPresent(payload.data.attributes.polyline)) {
        const polyline = {
          type: 'polyline',
          id: `${payload.data.attributes.routeId}-${payload.data.attributes.id}`,
          attributes: {
            coords: payload.data.attributes.polyline
          },
          relationships: {
            route: {
              data: {
                type: 'route',
                id: payload.data.attributes.routeId
              }
            },
            stopPoint: {
              data: {
                type: 'stopPoint',
                id: payload.data.attributes.id
              }
            }
          }
        };
        payload.included = payload.included || [];
        payload.included.push(polyline);
      }

      if (Ember.isPresent(payload.data.attributes.provider)) {
        relationships.provider = {
          data: {
            type: 'provider',
            id: payload.data.attributes.provider
          }
        };
        delete payload.data.attributes.provider;
      }

      if (Ember.isPresent(payload.data.attributes.stopType) && Ember.isPresent(payload.data.attributes.routeId)) {
        const stopType = payload.data.attributes.stopType;

        if (stopType === 'pullinGarage' || stopType === 'pulloutGarage') {
          relationships.stopRoute = {
            data: {
              type: 'route',
              id: payload.data.attributes.routeId
            }
          };
        }
      }

      payload.data.attributes.actualArriveLat = payload.data.attributes.actualArriveLat || null;
      payload.data.attributes.actualArriveLng = payload.data.attributes.actualArriveLng || null;
      payload.data.attributes.actualDepartLat = payload.data.attributes.actualDepartLat || null;
      payload.data.attributes.actualDepartLng = payload.data.attributes.actualDepartLng || null;
      payload.data.attributes.routeOrdinal = payload.data.attributes.ordinal;
      payload.data.relationships = relationships;
      return payload;
    }

  });

  _exports.default = _default;
});