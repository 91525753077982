define("adept-iq/classes/rules/eligibility-rules/deny-eligibility-suspension-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type", "adept-iq/models/cs-config-category"], function (_exports, _baseRule, _ruleResultType, _csConfigCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DenyEligibilitySuspensionRule = void 0;

  class DenyEligibilitySuspensionRule extends _baseRule.default {
    doEvaluate(ruleContext) {
      const {
        bookingService,
        systemConfigService
      } = ruleContext;
      const denyEligibilityCategories = systemConfigService.findEligibilityCategories(_csConfigCategory.ELIGIBILITY_CATEGORY_TYPES.DENY);

      if (denyEligibilityCategories.length === 0) {
        return _ruleResultType.RESULT_TYPE_YES;
      }

      const selectedRiders = bookingService.get('selectedRiders');
      const containsDenyEligibility = selectedRiders.some(rider => {
        return this.checkDenyEligibility(rider, denyEligibilityCategories);
      });
      if (containsDenyEligibility) return _ruleResultType.RESULT_TYPE_YES;
      return _ruleResultType.RESULT_TYPE_NO;
    }

    checkDenyEligibility(riderExternal, eligibilityCategories) {
      const DENY_CATEGORY_NAMES = eligibilityCategories.map(category => {
        const categoryName = category.value.name || category.value.Name;
        return categoryName.trim().toLowerCase();
      });
      const riderEligibilityCategories = riderExternal.get('eligibility.categories');
      return riderEligibilityCategories.every(category => {
        return DENY_CATEGORY_NAMES.includes(category.type.toLowerCase());
      });
    }

  }

  _exports.DenyEligibilitySuspensionRule = DenyEligibilitySuspensionRule;
  DenyEligibilitySuspensionRule.RULE_NAME = 'DenyEligibilitySuspensionRule';
});