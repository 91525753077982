define("adept-iq/pods/components/side-drawer/system-config/speed-region-groups/table/addresses/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/side-drawer/system-config/speed-region-groups/table/addresses/config", "adept-iq/utils/sorts", "adept-iq/utils/filters"], function (_exports, _component, _config, _sorts, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['blockout-addresses-table'],
    store: Ember.inject.service(),
    config: _config.default,
    paginationEnabled: true,
    enableWarningRowChangeAlert: true,
    configType: null,
    // passed in attributes
    additionalTableActions: null,

    init() {
      this._super(...arguments);
    },

    async didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');
      this.set('tableActions', this.get('additionalTableActions'));

      if (table) {
        this.set('tableRef', this);
      }
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      let activeData = store.peekAll(modelName) || [];

      if (Ember.isPresent(this.get('config.fetchModelData'))) {
        activeData = (await store.findAll(modelName)) || [];
      }

      if (Ember.isPresent(this.get('config.queryModelData'))) {
        const queryParams = this.get('queryParams') || {};
        activeData = await store.query(modelName, queryParams);
      }

      let filteredData = activeData.filter(filterFn).sort(compareFn);
      filteredData = filteredData.filter(data => {
        return data.get('isSoftDeletedRow') === false;
      });
      return filteredData;
    }

  });

  _exports.default = _default;
});