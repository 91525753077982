define("adept-iq/serializers/driver-availability", ["exports", "adept-iq/serializers/-driver", "moment"], function (_exports, _driver, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const refTime = (0, _moment.default)(0).startOf('day');
  const DAYS_OF_WEEK = [{
    day: 'monday',
    display: 'Mon',
    disabled: false
  }, {
    day: 'tuesday',
    display: 'Tues',
    disabled: false
  }, {
    day: 'wednesday',
    display: 'Wed',
    disabled: false
  }, {
    day: 'thursday',
    display: 'Thurs',
    disabled: false
  }, {
    day: 'friday',
    display: 'Fri',
    disabled: false
  }, {
    day: 'saturday',
    display: 'Sat',
    disabled: false
  }, {
    day: 'sunday',
    display: 'Sun',
    disabled: false
  }, {
    day: 'holiday',
    display: 'Holiday',
    disabled: false
  }];

  var _default = _driver.default.extend({
    attrs: {
      calcEndDate: {
        serialize: false
      },
      DOWSelected: {
        serialize: false
      },
      routeLength: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      const {
        shiftStart,
        shiftEnd
      } = json.data.attributes;
      const momentShiftStart = (0, _moment.default)(shiftStart).startOf('minute');
      const momentShiftEnd = (0, _moment.default)(shiftEnd).startOf('minute');
      const diffStart = Math.abs(momentShiftStart.diff(momentShiftStart.clone().startOf('day')));

      const durationStart = _moment.default.duration(diffStart);

      const formattedShiftStart = refTime.clone().add(durationStart);
      const diffEnd = Math.abs(momentShiftEnd.diff(momentShiftStart.clone().startOf('day')));

      const durationEnd = _moment.default.duration(diffEnd);

      const formattedShiftEnd = refTime.clone().add(durationEnd);
      json.data.attributes.shiftStart = formattedShiftStart;
      json.data.attributes.shiftEnd = formattedShiftEnd;
      return json;
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      return this._super(...arguments);
    },

    normalize(modelClass, resourceHash) {
      // handle topic payload
      const {
        days
      } = resourceHash.attributes;
      const DOWSelected = [];

      if (days) {
        delete resourceHash.attributes.days;
        days.forEach((value, i) => {
          const weekday = _moment.default.weekdays(i).toLowerCase();

          resourceHash.attributes[weekday] = value;
        });
      }

      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};
      const {
        shiftStart,
        shiftEnd
      } = resourceHash.attributes;

      if (shiftStart && shiftEnd) {
        const startMoment = (0, _moment.default)(shiftStart);
        const endMoment = (0, _moment.default)(shiftEnd);
        const diff = Math.abs(startMoment.diff(endMoment));

        const duration = _moment.default.duration(diff);

        const hours = Math.floor(duration.asHours());
        const formattedHours = hours < 10 ? `0${hours}` : hours;

        const routeLength = formattedHours + _moment.default.utc(diff).format(':mm');

        resourceHash.attributes.routeLength = routeLength;
      }

      if (shiftStart && resourceHash.attributes.routeLength) {
        const clone = (0, _moment.default)(shiftStart);
        const routeLength = resourceHash.attributes.routeLength.split(':');
        clone.add(routeLength[0], 'hours');
        clone.add(routeLength[1], 'minutes');
        resourceHash.attributes.calcEndDate = clone.toISOString();
      }

      DAYS_OF_WEEK.forEach(option => {
        if (resourceHash.attributes[option.day]) {
          DOWSelected.pushObject(option);
        }
      });
      resourceHash.attributes.DOWSelected = DOWSelected;
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});