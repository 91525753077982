define("adept-iq/pods/components/wootric-survey/component", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    user: Ember.inject.service(),
    session: Ember.inject.service(),
    // eslint-disable-next-line ember/no-function-prototype-extensions
    setup: function () {
      const email = this.get('session.data.authenticated.tokenInfo.email') || localStorage.getItem('currentUser');
      const workspace = localStorage.getItem('lastDashboardId');
      const roles = this.get('user.roles').join(',');
      window.wootricSettings = {
        account_token: _environment.default.APP.WOOTRIC_TOKEN,
        email: email,
        // created_at: Math.floor(Date.now() / 1000),
        properties: {
          workspace,
          roles
        },
        event_name: ''
      };
    }.on('init')
  });

  _exports.default = _default;
});