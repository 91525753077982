define("adept-iq/pods/components/side-drawer/system-config/ivr/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IVR_PHONE_NUMBER_VALIDATION = void 0;
  const IVR_PHONE_NUMBER_VALIDATION = {
    unformattedPhoneNumber: [(0, _validators.validateLength)({
      is: 10,
      allowBlank: true,
      description: 'Phone Number',
      message: '{description} 10 digit is required.'
    })]
  };
  _exports.IVR_PHONE_NUMBER_VALIDATION = IVR_PHONE_NUMBER_VALIDATION;
});