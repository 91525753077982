define("adept-iq/pods/components/side-drawer/system-config/modals/driver-master/component", ["exports", "ember-concurrency", "moment", "adept-iq/utils/number-to-words", "lodash", "adept-iq/utils/unwrapProxy", "adept-iq/models/break-type", "adept-iq/pods/components/side-drawer/system-config/modals/driver-master/config", "adept-iq/config/api-urls", "adept-iq/mixins/async-schedule-operation", "adept-iq/config/environment"], function (_exports, _emberConcurrency, _moment, _numberToWords, _lodash, _unwrapProxy, _breakType, _config, _apiUrls, _asyncScheduleOperation, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const extraBreakLengthOptions = {
    min: 1,
    max: 3
  };
  const MINUTES_PLANNED_DURATION = 'minutesPlannedDuration';

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    session: Ember.inject.service(),
    workspace: Ember.inject.service(),
    ajax: Ember.inject.service(),
    // passed in attributes
    isDriverBreakModalEnabled: false,
    availability: null,
    breakTypes: null,
    // properties
    tableRef: null,
    searchText: null,
    selectedBreakType: null,
    undoShiftBreak: false,
    isSearchEnabled: false,
    disableForms: true,
    showValidations: false,
    disableBtns: null,
    addressPublicApi: null,
    hasOverlay: true,
    // default to true
    isTaskRunning: false,
    locationConfig: _config.locationFieldConfig,
    extraBreakLengthOptions,
    errorModel: null,
    canShowDaysModal: false,
    modalData: null,
    dateTimeFormat: _environment.default.dateTimeFormat,
    disableUndobtn: Ember.computed.readOnly('disableSaveBtn'),
    selectedRow: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    selectedRecord: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    isFixedBreak: Ember.computed.equal('selectedBreakType.name', _breakType.FIXED_BREAK),
    isFloatingBreak: Ember.computed.equal('selectedBreakType.name', _breakType.FLOATING_BREAK),
    shouldDisplayValidations: Ember.computed.or('showValidations').readOnly(),
    disableEditDeleteBtn: Ember.computed('selectedRecord', 'disableBtns.{edit,delete}', function () {
      const editBtn = this.get('disableBtns.edit');
      const selectedRecord = this.get('selectedRecord');
      return !selectedRecord || editBtn;
    }),
    disableAddressSearch: Ember.computed('disableForms', 'isFloatingBreak', function () {
      const disableForms = this.get('disableForms');
      const isFloatingBreak = this.get('isFloatingBreak');
      return isFloatingBreak || disableForms;
    }),
    disableSaveBtn: Ember.computed('disableForms', 'shiftBreakChangeSet.{requestTime,minutesPlannedDuration,notes,breakType,place,place.address.freeformAddress}', function () {
      const disableForms = this.get('disableForms');
      const selectedRow = this.get('selectedRow');
      const shiftBreakChangeSet = this.get('shiftBreakChangeSet');
      if (disableForms) return true;

      if (selectedRow) {
        return !this.hasShiftBreakChanged(shiftBreakChangeSet, selectedRow);
      }

      return !this.hasValues(shiftBreakChangeSet);
    }),

    hasValues(shiftBreakChangeSet) {
      const requestTime = shiftBreakChangeSet.get('requestTime');
      const breakType = shiftBreakChangeSet.get('breakType');
      const minutesPlannedDuration = parseInt(shiftBreakChangeSet.get('minutesPlannedDuration'), 10);
      return requestTime && breakType && minutesPlannedDuration;
    },

    hasShiftBreakChanged(shiftBreakChangeSet, selectedRow) {
      const breakType = (0, _unwrapProxy.unwrapProxy)(shiftBreakChangeSet.get('breakType'));
      const address = (0, _unwrapProxy.unwrapProxy)(shiftBreakChangeSet.get('place.address'));
      const isFixedBreak = this.get('isFixedBreak');
      const shiftBreakChanged = this.isShiftBreakChanged(shiftBreakChangeSet, selectedRow) || this.isBreakTypeChanged(breakType, selectedRow) || this.isPlaceChanged(address, isFixedBreak);
      return shiftBreakChanged;
    },

    isShiftBreakChanged(shiftBreakChangeSet, selectedRow) {
      const requestTime = shiftBreakChangeSet.get('requestTime');
      const minutesPlannedDuration = parseInt(shiftBreakChangeSet.get('minutesPlannedDuration'), 10);
      const notes = shiftBreakChangeSet.get('notes');

      if (requestTime !== selectedRow.get('requestTime') || minutesPlannedDuration !== parseInt(selectedRow.get('minutesPlannedDuration'), 10) || notes !== selectedRow.get('notes')) {
        return true;
      }

      return false;
    },

    isBreakTypeChanged(breakType, selectedRow) {
      const previousBreakType = (0, _unwrapProxy.unwrapProxy)(selectedRow.get('breakType'));
      return previousBreakType !== breakType;
    },

    isPlaceChanged(address, isFixedBreak) {
      if (isFixedBreak && address) {
        return true;
      }

      return false;
    },

    title: Ember.computed('availability.{routeTemplate.name,DOWSelected}', function () {
      const name = this.get('availability.routeTemplate.name');
      const DOW = this.get('availability.DOWSelected') || [];
      const DOWSelected = DOW.map(option => Ember.String.capitalize(option.day)).join(', ');
      const title = name ? `Driver Break(s) for Route ${name} - ${DOWSelected}` : `Driver Break(s) for New Route - ${DOWSelected}`;
      return title;
    }),
    shiftBreakChangeSet: Ember.computed('selectedRecord', function () {
      const shiftBreak = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      const addressPublicApi = this.get('addressPublicApi'); // REG-NYAAR-19112 When changing the record the changedPlace should update in address component

      if (addressPublicApi && shiftBreak) {
        addressPublicApi.onValueChange(shiftBreak.get('place'));
      }

      return this.setDefaultProperties(shiftBreak);
    }),

    init() {
      this._super(...arguments);

      this.set('disableBtns', {
        new: false,
        save: true,
        edit: true,
        delete: true,
        undo: true
      });
      this.get('refreshTask').perform();
      const defaultBreakType = this.get('breakTypes').find(b => b.get('name') === _breakType.FLOATING_BREAK);
      this.set('defaultBreakType', defaultBreakType);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('dropdownActions', [{
        id: 'print',
        name: 'Download',
        action: () => {
          const tableRef = this.get('tableRef');
          tableRef.exportData();
        }
      }]);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {}),

    onCloseModal() {
      this.set('isDriverBreakModalEnabled', false);
    },

    createPlaceRecord(perviousPlace) {
      const store = this.get('store'); // required type @TODO hacky might need to refactor for the future

      const placeCategoryType = store.peekAll('place-category-type').firstObject;
      const perviousAddress = perviousPlace ? (0, _unwrapProxy.unwrapProxy)(perviousPlace.get('address')) : null;
      const perviousLocation = perviousPlace ? (0, _unwrapProxy.unwrapProxy)(perviousPlace.get('location')) : null;
      let addressAttr = {};
      let locationAttr = {};

      if (perviousAddress) {
        addressAttr = perviousAddress.toJSON();
      }

      if (perviousLocation) {
        locationAttr = perviousLocation.toJSON();
      }

      const location = store.createRecord('location', locationAttr);
      const address = store.createRecord('address', addressAttr);
      const place = store.createRecord('place', {
        address: address,
        location: location,
        placeCategoryType
      });
      return place;
    },

    rollbackPlaceRecord(place) {
      if (place) {
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
        if (address) address.rollbackAttributes();
        if (location) location.rollbackAttributes();
      }
    },

    regenratePlaceRecord() {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      const shiftBreakChangeSet = this.get('shiftBreakChangeSet');
      const place = record ? (0, _unwrapProxy.unwrapProxy)(record.get('place')) : null;
      const newRecord = this.createPlaceRecord(place);
      shiftBreakChangeSet.set('place', newRecord);
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let disableForms = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      const store = this.get('store');
      const modelClass = store.modelFor('shift-break');
      const floatBreakType = this.get('defaultBreakType');
      const fields = Array.from(modelClass.attributes.keys()).reduce((acu, key) => {
        acu[key] = null;
        return acu;
      }, {});
      fields.place = null;
      fields.breakType = floatBreakType;
      fields.requestTime = null;

      if (!Ember.isEmpty(record)) {
        Object.entries(fields).forEach(_ref => {
          let [key] = _ref;
          fields[key] = record.get(key);
        });
        fields.id = record.get('id');
        fields.place = this.createPlaceRecord((0, _unwrapProxy.unwrapProxy)(record.get('place'))); // relationship

        fields.breakType = (0, _unwrapProxy.unwrapProxy)(record.get('breakType')); // relationship
      }

      if (!(0, _unwrapProxy.unwrapProxy)(fields.place)) fields.place = this.createPlaceRecord(null);
      this.onBreakTypeChange((0, _unwrapProxy.unwrapProxy)(fields.breakType));

      if (record) {
        this.resetForm(disableForms);
        this.resetErrorModel();
      }

      this.set('pendingRecord', null);
      return Ember.Object.create(fields);
    },

    resetForm() {
      let disableForms = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      const disableBtns = {
        new: false,
        edit: false,
        delete: false,
        undo: true
      };
      Ember.run.scheduleOnce('afterRender', this, () => {
        this.set('disableBtns', disableBtns);
        this.set('disableForms', disableForms);
      });
    },

    saveShiftBreak() {
      const tableRef = this.get('tableRef');
      const availabilityChangeSet = this.get('availability');
      const shiftBreakChangeSet = this.get('shiftBreakChangeSet');
      const availability = this.getAvailability(availabilityChangeSet);
      const selectedRow = this.get('selectedRow');
      const selectedRecord = this.get('selectedRecord');
      const store = this.get('store');
      const shiftBreaks = availability.get('shiftBreaks');
      let pendingRecord = this.get('pendingRecord');

      if (selectedRecord) {
        selectedRecord.setProperties(shiftBreakChangeSet);
        pendingRecord = selectedRecord;
      } else if (!pendingRecord) {
        pendingRecord = store.createRecord('shift-break', shiftBreakChangeSet);
        this.set('pendingRecord', pendingRecord);
      } else {
        pendingRecord.setProperties(shiftBreakChangeSet);
      }

      pendingRecord.set('routeTemplateAvailability', availability);

      if (pendingRecord.get('isNew')) {
        shiftBreaks.pushObject(pendingRecord);
      }

      availabilityChangeSet.set('shiftBreaks', shiftBreaks);
      this.resetForm();
      availabilityChangeSet.execute();
      Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      availabilityChangeSet.execute();
      Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      this.selectFirstShiftBreak(selectedRow);
    },

    onSaveShiftBreakTask: (0, _emberConcurrency.task)(function* () {
      const availabilityChangeSet = this.get('availability');
      const availability = this.getAvailability(availabilityChangeSet);
      const shiftBreakChangeSet = this.get('shiftBreakChangeSet');
      const valid = yield this.validateRecord(shiftBreakChangeSet, availability);

      if (valid) {
        this.saveShiftBreak();
      }
    }),

    /**
     *after user create a new record,first record should be selected in shift break table ;
     */
    selectFirstShiftBreak(selectedRow) {
      if (Ember.isEmpty(selectedRow)) this.set('tableRef.config.selectFirstRow', true);
    },

    //availabilityChangeSet.data will not return the availability model with latest changed attributes
    getAvailability(availabilityChangeSet) {
      const availability = availabilityChangeSet.get('data');
      const availabilitySnapshot = availabilityChangeSet.snapshot();
      const availabilitychagedAttr = availabilitySnapshot.changes;
      Object.entries(availabilitychagedAttr).forEach(_ref2 => {
        let [key] = _ref2;
        availability.set(key, availabilitychagedAttr[key]);
      });
      return availability;
    },

    onDeleteShiftBreakTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('selectedRecord');
      const tableRef = this.get('tableRef');
      const availabilityChangeSet = this.get('availability');

      if (selectedRecord) {
        const shiftBreaks = availabilityChangeSet.get('shiftBreaks');
        const deletedShiftBreaks = availabilityChangeSet.get('deletedShiftBreaks') || [];
        shiftBreaks.removeObject(selectedRecord);
        deletedShiftBreaks.pushObject(selectedRecord);
        availabilityChangeSet.set('shiftBreaks', shiftBreaks);
        availabilityChangeSet.set('deletedShiftBreaks', deletedShiftBreaks);
        yield Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      }
    }),

    async validateShiftBreak(errors, shiftBreakChangeSet, availability) {
      const isFixedBreak = this.get('isFixedBreak');
      const calcEndDate = availability.get('calcShiftEnd');
      const minutesPlannedDuration = parseInt(shiftBreakChangeSet.get('minutesPlannedDuration'), 10);
      const startTime = this.getTime(shiftBreakChangeSet.get('requestTime'));
      const endTime = this.getTimeAfterDuration(startTime, minutesPlannedDuration);
      const isBreakEndTimeIsBeforeShiftEndTime = this.checkBreakEndTimeIsBeforeShiftEndTime(endTime, calcEndDate);
      const isBreakStartTimeAfterShiftStartTime = this.checkTimeIsAfterShiftStartTime(startTime);
      const isBreakStartTimeBeforeShiftEndTime = this.checkTimeIsBeforeShiftEndTime(startTime);
      let isBreakStartTimeIsEnoughToReachGarageBeforeShiftEnd = true;

      if (isFixedBreak && !Ember.isEmpty(shiftBreakChangeSet.get('place.location.latlng'))) {
        isBreakStartTimeIsEnoughToReachGarageBeforeShiftEnd = await this.checkBreakStartTimeIsEnoughToReachGarageBeforeShiftEndTime(shiftBreakChangeSet, calcEndDate);
      }

      const shiftBreakEndTime = this.getTimeAfterDuration(startTime, minutesPlannedDuration);
      const numberOfDays = this.getNumberOfDays(shiftBreakEndTime, calcEndDate);
      let validShiftBreakRecord = true;
      let canShowDaysModal = false;
      this.set('canShowDaysModal', false);

      if (!minutesPlannedDuration) {
        validShiftBreakRecord = false;
        errors.pushObject({
          'validation': 'Break Length cannot be blank'
        });
        this.set('errorModel.invalidLength', !validShiftBreakRecord);
        return validShiftBreakRecord;
      }

      if (!startTime) {
        validShiftBreakRecord = false;
        errors.pushObject({
          'validation': 'Start Time cannot be blank'
        });
        this.set('errorModel.invalidRequestTime', !validShiftBreakRecord);
        return validShiftBreakRecord;
      }

      if (!isBreakStartTimeAfterShiftStartTime) {
        validShiftBreakRecord = false;
        errors.pushObject({
          'validation': 'Break start time cannot be before route start time.'
        });
        this.set('errorModel.invalidRequestTime', !validShiftBreakRecord);
        return validShiftBreakRecord;
      }

      if (!isBreakStartTimeBeforeShiftEndTime) {
        validShiftBreakRecord = false;
        errors.pushObject({
          'validation': 'Break start time cannot be after route end time.'
        });
        this.set('errorModel.invalidRequestTime', !validShiftBreakRecord);
        return validShiftBreakRecord;
      }

      if (!this.get('errorModel.invalidRequestTime') && !isBreakStartTimeIsEnoughToReachGarageBeforeShiftEnd) {
        validShiftBreakRecord = false;
        errors.pushObject({
          'validation': 'Break time must allow enough time for vehicle to return to garage.'
        });
        this.set('errorModel.invalidRequestTime', !validShiftBreakRecord);
        return validShiftBreakRecord;
      }

      if (isFixedBreak && Ember.isEmpty(shiftBreakChangeSet.get('place.location.latlng'))) {
        validShiftBreakRecord = false;
        errors.pushObject({
          'validation': 'Address cannot be blank'
        });
        this.set('errorModel.invalidAddress', !validShiftBreakRecord);
        return validShiftBreakRecord;
      }

      if (this.requestTimeIsOverlap(shiftBreakChangeSet.get('requestTime'), minutesPlannedDuration)) {
        validShiftBreakRecord = false;
        errors.pushObject({
          'validation': 'Break time cannot overlap existing break time.'
        });
        this.set('errorModel.invalidRequestTime', !validShiftBreakRecord);
        return validShiftBreakRecord;
      }

      if (!this.get('errorModel.invalidLength') && !isBreakEndTimeIsBeforeShiftEndTime) {
        validShiftBreakRecord = false;
        errors.pushObject({
          'validation': 'Break length cannot extend past route end time.'
        });
        this.set('errorModel.invalidLength', !validShiftBreakRecord);
        return validShiftBreakRecord;
      } // Reg - NYAAR-16786


      if (numberOfDays > 1) {
        this.set('canShowDaysModal', true);
        errors.pushObject({
          'validation': `Since the shift time spans ${numberOfDays} days, you must specify the day on which the break should occur.`
        });
        this.setModalData({
          numberOfDays,
          errors,
          shiftBreakChangeSet
        });
        canShowDaysModal = true;
      }

      return {
        validShiftBreakRecord,
        canShowDaysModal
      };
    },

    /**
     * requestTime cannot overlap the existing routes break time.
     * @param requestTime
     * @param minutesPlannedDuration
     */
    requestTimeIsOverlap(requestTime, minutesPlannedDuration) {
      const selectedRow = this.get('selectedRow');
      const shiftBreaks = this.get('tableRef.table.rows').filter(sb => {
        return sb !== selectedRow;
      }); // requestTime with minutesPlannedDuration

      const formatRequestTime = (0, _moment.default)(requestTime).add(minutesPlannedDuration, 'minute').toDate();
      return shiftBreaks.some(shiftBreak => {
        const plannedStart = (0, _moment.default)(shiftBreak.get('requestTime')).toDate();
        const plannedEnd = (0, _moment.default)(shiftBreak.get('requestTime')).add('minute', shiftBreak.get('minutesPlannedDuration')).toDate();
        const requestedTime = (0, _moment.default)(requestTime).toDate();
        const isOverlapExististingRoute = (0, _moment.default)(requestTime).isSame(plannedStart) || (0, _moment.default)(requestTime).isSame(plannedEnd) || (0, _moment.default)(formatRequestTime).isSame(plannedEnd) || (0, _moment.default)(plannedStart).isBetween(requestedTime, formatRequestTime) || (0, _moment.default)(plannedEnd).isBetween(requestedTime, formatRequestTime);
        return isOverlapExististingRoute;
      });
    },

    // Checks if requestTime  is between dates in start and end.
    requestTimeInrange(requestTime, startDate, endDate) {
      return (0, _moment.default)(requestTime).isBetween(startDate, endDate);
    },

    getTransformedDate(date) {
      if (!date) return null;
      const recordDate = (0, _moment.default)(date);
      const momentTime = (0, _moment.default)(new Date(null));
      momentTime.set('hour', recordDate.get('hour'));
      momentTime.set('minute', recordDate.get('minute'));
      momentTime.set('second', recordDate.get('second'));
      return momentTime.toDate();
    },

    getTransformedCurrentDate(date) {
      if (!date) return null;
      const recordDate = (0, _moment.default)(date);
      const momentTime = (0, _moment.default)();
      momentTime.set('hour', recordDate.get('hour'));
      momentTime.set('minute', recordDate.get('minute'));
      momentTime.set('second', recordDate.get('second'));
      return momentTime.toDate();
    },

    /**
     * add planned break duration to requestTime
     * @param requestTime
     * @param minutesPlannedDuration
     * @returns {Date}
     */
    getTimeAfterDuration(requestTime, minutesPlannedDuration) {
      let formattedRequestTime = (0, _moment.default)(this.getTransformedCurrentDate(requestTime));

      if (formattedRequestTime && minutesPlannedDuration) {
        formattedRequestTime = formattedRequestTime.add(minutesPlannedDuration, 'minutes');
        return formattedRequestTime;
      }

      return formattedRequestTime;
    },

    /**
     * get the difference between the requestTime and ShiftEndTime
     * @param requestTime
     * @param calcEndDate
     * @returns {number}
     */
    getNumberOfDays(requestTime, calcEndDate) {
      return (0, _moment.default)((0, _moment.default)(calcEndDate)).diff((0, _moment.default)(requestTime), 'days') + 1;
    },

    /**
     * get the difference between the curren and requestTime
     * @param requestTime
     * @returns Number{*}
     */
    getDateDifference(requestTime) {
      const currentDate = (0, _moment.default)();
      return (0, _moment.default)(requestTime).diff(currentDate, 'days');
    },

    setModalData(_ref3) {
      let {
        numberOfDays,
        errors,
        shiftBreakChangeSet
      } = _ref3;
      const numbers = (0, _lodash.fill)(Array(numberOfDays), numberOfDays);
      const days = numbers.map((num, index) => ({
        day: (0, _numberToWords.stringifyNumber)(index + 1)
      }));
      this.set('modalData', {
        numberOfDays: days,
        title: 'System Configuration',
        tip: '',
        errors,
        primaryActionText: 'Cancel',
        closeAction: () => {
          this.set('canShowDaysModal', false);
        },
        onDayClick: index => {
          const requestTime = this.getTransformedCurrentDate(shiftBreakChangeSet.get('requestTime'));
          this.setRequestTimeDate(requestTime, index);
          this.onDateChange(shiftBreakChangeSet, 'requestTime', requestTime);
          this.set('canShowDaysModal', false);
          this.saveShiftBreak();
        }
      });
    },

    setRequestTimeDate(requestTime, count) {
      requestTime.setDate(requestTime.getDate() + count);
    },

    async validateRecord(shiftBreakChangeSet, availability) {
      const tooltip = this.get('tooltip');
      const errors = [];
      this.resetErrorModel();
      const {
        validShiftBreakRecord,
        canShowDaysModal
      } = await this.validateShiftBreak(errors, shiftBreakChangeSet, availability);
      const canShowOtherValidations = !validShiftBreakRecord && !canShowDaysModal;

      if (canShowOtherValidations) {
        tooltip.pushConfirmation({
          title: 'System Configuration',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
      }

      this.set('showValidations', canShowOtherValidations);
      return validShiftBreakRecord && !canShowDaysModal;
    },

    clearRecordAttributes(record) {
      const store = this.get('store');
      const modelClass = store.modelFor(record.constructor.modelName);
      Array.from(modelClass.attributes.keys()).forEach(key => {
        record.set(key, null);
      });
    },

    onBreakTypeChange(breakType) {
      if (breakType) {
        this.set('selectedBreakType', breakType);
      }
    },

    onDateChange(record, path, dateStr) {
      record.set(path, dateStr);
    },

    //highlighting the error fields after rollback shift-break
    setErrorModel(model) {
      const errorModel = this.get('errorModel');
      this.set('errorModel', {
        invalidRequestTime: errorModel.invalidRequestTime,
        invalidAddress: errorModel.invalidAddress,
        invalidLength: model.minutesPlannedDuration.isInvalid,
        invalidNotes: model.notes.isInvalid
      });
    },

    resetErrorModel() {
      const errorModel = {
        invalidRequestTime: false,
        invalidLength: false,
        invalidAddress: false,
        invalidNotes: false
      };
      this.set('errorModel', errorModel);
    },

    resetDriverBreakDOM() {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      const shiftBreakChangeSet = this.get('shiftBreakChangeSet'); //onst place = shiftBreakChangeSet.get('place');

      const floatBreakType = this.get('defaultBreakType');
      const breakTypes = this.get('breakTypes'); //fields.breakType = unwrapProxy(record.get('breakType')); // relationship

      if (record) {
        shiftBreakChangeSet.set('minutesPlannedDuration', record.get('minutesPlannedDuration'));
        shiftBreakChangeSet.set('requestTime', record.get('requestTime'));
        shiftBreakChangeSet.set('notes', record.get('notes'));
        this.set('selectedBreakType', breakTypes.toArray().find(bt => bt.get('name') === record.get('breakType.name')));
        shiftBreakChangeSet.set('breakType', breakTypes.toArray().find(bt => bt.get('name') === record.get('breakType.name')));
      } else {
        shiftBreakChangeSet.set('minutesPlannedDuration', null);
        shiftBreakChangeSet.set('requestTime', null);
        shiftBreakChangeSet.set('notes', null);
        this.set('selectedBreakType', breakTypes.toArray().find(bt => bt.get('name') === floatBreakType.get('name')));
        shiftBreakChangeSet.set('breakType', breakTypes.toArray().find(bt => bt.get('name') === floatBreakType.get('name')));
      }

      this.regenratePlaceRecord();
    },

    getSchedule() {
      const schedules = this.get('store').peekAll('schedule');
      const schedule = schedules.find(s => {
        return s.get('start') <= new Date() <= s.get('end');
      });
      if (!schedule) return;
      return schedule;
    },

    //NYAAR-16426-for for fixed break, if user adds a break that ends at a time that doesn't allow the vehicle to return to the garage before shift end time we have to show the popup
    async checkBreakStartTimeIsEnoughToReachGarageBeforeShiftEndTime(shiftBreakChangeSet, shiftEndTime) {
      await this.queryEndGaragePlace();
      const breakTime = shiftBreakChangeSet.requestTime;
      const minutesPlannedDuration = parseInt(shiftBreakChangeSet.get('minutesPlannedDuration'), 10);
      const breakLocationLat = shiftBreakChangeSet.get('place.location.lat');
      const breakLocationLng = shiftBreakChangeSet.get('place.location.lng');
      const breakEndTime = (0, _moment.default)(breakTime).add(minutesPlannedDuration, 'minute').toDate();
      const endGarageLat = this.get('availability.vehicle.endGarage.location.lat');
      const endGarageLng = this.get('availability.vehicle.endGarage.location.lng');
      const payload = {
        'data': {
          'type': 'queryTravelData',
          'attributes': {
            'pick': {
              'lat': breakLocationLat,
              'lng': breakLocationLng,
              'nominalTime': breakEndTime
            },
            'drop': {
              'lat': endGarageLat,
              'lng': endGarageLng,
              'nominalTime': shiftEndTime
            }
          }
        }
      };
      const schedule = this.get('workspace.currentSchedule') ? this.get('workspace.currentSchedule') : this.getSchedule();
      const options = {
        operationData: payload.data.attributes,
        outputResult: true
      };
      const res = await this.createScheduleAsyncOperation.perform(schedule, 'queryTravelData', options);
      const results = res.isJobSuccess ? JSON.parse(res.jsonData.attributes.results) : null;
      const travelData = Ember.isPresent(results) ? results.travelData : [];
      const adjustedRoundedTime = travelData.length ? travelData[0].adjustedRoundedTime : 0;
      const isGarageReachTimeBeforeShiftEndTime = this.checkGarageReachBeforeShiftEndTime(adjustedRoundedTime, breakEndTime, shiftEndTime);
      return isGarageReachTimeBeforeShiftEndTime;
    },

    async queryTravelData(payload) {
      const session = this.get('session');
      const scheduleHost = _apiUrls.API.schedulingService.host;
      const currentScheduleId = await this.getScheduleId();
      const url = `${scheduleHost}/schedule/${currentScheduleId}/query-travel-data`;
      const {
        results
      } = await this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: payload
      });
      const resultData = JSON.parse(results);
      const adjustedRoundedTime = resultData.travelData.length ? resultData.travelData[0].adjustedRoundedTime : 0;
      return adjustedRoundedTime;
    },

    async queryEndGaragePlace() {
      //some times garage place location is not fetched
      if (!this.get('availability.vehicle.endGarage.location.id')) {
        const placeId = this.get('availability.vehicle.endGarage.id');
        const placeQuery = `in(id,(${placeId}))&include=address,location`;
        await this.get('store').query('place', {
          filter: placeQuery
        });
      }
    },

    getTime(date) {
      if (!date) return null;
      const recordDate = (0, _moment.default)(date);
      const momentTime = (0, _moment.default)(new Date());
      momentTime.set('hour', recordDate.get('hour'));
      momentTime.set('minute', recordDate.get('minute'));
      momentTime.set('second', recordDate.get('second'));
      return momentTime.toDate();
    },

    //to check whether the time to reach to garage from break location is before the shift end time
    checkGarageReachBeforeShiftEndTime(adjustedRoundedTime, breakEndTime, shiftEndTime) {
      const garageReachTime = (0, _moment.default)(breakEndTime).add(adjustedRoundedTime, 'minute').toDate();
      return (0, _moment.default)(garageReachTime).isBefore(shiftEndTime, 'minutes');
    },

    //NYAAR-16426-If the user enters a break time plus length that causes the break to extend past the shift end time, then after user clicks Save button, we need to show a popup.
    checkBreakEndTimeIsBeforeShiftEndTime(requestTime, calcEndDate) {
      return (0, _moment.default)(requestTime).isBefore(calcEndDate, 'minutes');
    },

    checkTimeIsBeforeShiftEndTime(time) {
      const shiftEndTime = this.get('availability.calcShiftEnd');
      return (0, _moment.default)(time).isBefore(shiftEndTime, 'minutes');
    },

    checkTimeIsAfterShiftStartTime(time) {
      const shiftStartTime = this.getTime(this.get('availability.shiftStart'));
      return (0, _moment.default)(time).isAfter(shiftStartTime, 'minutes');
    },

    async getScheduleId() {
      const routeTemplateId = this.get('availability.routeTemplate.id');
      const routeQuery = `eq(routeTemplateId,${routeTemplateId})`;
      const routes = await this.get('store').query('route', {
        filter: routeQuery
      });
      const scheduleAvailableRoutes = routes.toArray().filter(route => {
        return (0, _unwrapProxy.unwrapProxy)(route.get('schedule'));
      });
      const scheduleId = scheduleAvailableRoutes.get('firstObject.schedule.id');
      return scheduleId;
    },

    actions: {
      // header button functions
      newDriverBreak() {
        const disableBtns = {
          new: true,
          edit: true,
          delete: true,
          undo: true
        };
        const selectedRow = this.get('selectedRow');

        if (selectedRow) {
          selectedRow.set('selected', false); // triggers cp and on save it should create a new record unless user selects a new record

          this.set('tableRef.config.selectFirstRow', false);
        }

        this.set('disableBtns', disableBtns);
        this.set('disableForms', false);
        this.resetErrorModel();
      },

      editDriverBreak() {
        const disableBtns = {
          new: true,
          edit: true,
          delete: true,
          undo: false
        };
        this.set('disableBtns', disableBtns);
        this.set('disableForms', false);
      },

      undoDriverBreak() {
        //Resetting address
        if (this.get('shiftBreakChangeSet.breakType') !== _breakType.FLOATING_BREAK) {
          this.get('addressPublicApi').onValueChange(null);
        }

        this.resetDriverBreakDOM();
        this.resetErrorModel();
      },

      saveDriverBreak() {
        this.get('onSaveShiftBreakTask').perform();
      },

      deleteDriverBreak() {
        this.get('onDeleteShiftBreakTask').perform();
      },

      onPrimaryActionButtonClick() {
        const selectedRecord = this.get('selectedRecord');

        if (selectedRecord) {
          this.get('queryModal.primaryActionTask').perform(selectedRecord).then(() => {
            this.onCloseModal();
          });
        }
      },

      onCloseModal() {
        this.onCloseModal();
      },

      onClickOverlayModal() {
        const hasOverlay = this.get('hasOverlay');

        if (hasOverlay) {
          return false;
        }

        this.get('queryModal').reset();
      },

      onHelp() {},

      toggleSearchInput() {
        this.toggleProperty('isSearchEnabled');
      },

      onSearchTextChange(event) {
        const previousSearchText = this.get('searchText');

        if (event.target.value === previousSearchText) {
          return;
        }

        const tableRef = this.get('tableRef');

        if (!tableRef) {
          return;
        }

        this.set('searchText', event.target.value);
        tableRef.onSearchTextChange(event);
      },

      onClearSearchText() {
        this.set('searchText', '');
        const tableRef = this.get('tableRef');

        if (!tableRef) {
          return;
        }

        tableRef.onClearSearchText();
      },

      onDropDownActionClick(action, dropdown) {
        dropdown.actions.close();
        if (action.action) action.action();
      },

      onDateChange(record, path, value) {
        const currentDate = (0, _moment.default)();
        currentDate.hour(value.getHours());
        currentDate.minute(value.getMinutes());
        this.onDateChange(record, path, currentDate.toDate());
      },

      onCellValueChange(record, valuePath, value, options) {
        const freeformAddressPath = this.get('locationConfig.extra.freeformAddressPath');

        if (options.valuePath) {
          //NYAAR-20190-we have to set the address when every address related properties are updated to the record
          if (options.valuePath === freeformAddressPath) {
            this.get('addressPublicApi').onValueChange(record.get(`${valuePath}`));
          }

          return record.set(`${valuePath}.${options.valuePath}`, value);
        }

        return record.set(valuePath, value);
      },

      onInputValueChange(record, valuePath, value) {
        // NYAAR-19109-refix Converting Length from string to integer
        const formattedValue = valuePath === MINUTES_PLANNED_DURATION && value !== '' ? parseInt(value, 10) : value;
        return record.set(valuePath, formattedValue);
      },

      // power select event handler
      onBreakTypeChange(option) {
        this.set('shiftBreakChangeSet.breakType', option);
        this.onBreakTypeChange(option);
        let placeModel = this.get('shiftBreakChangeSet.place');

        if (option.get('name') === _breakType.FLOATING_BREAK) {
          const shiftBreakChangeSet = this.get('shiftBreakChangeSet');
          const place = (0, _unwrapProxy.unwrapProxy)(shiftBreakChangeSet.get('place'));
          const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
          const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
          if (place) this.clearRecordAttributes(place);
          if (address) this.clearRecordAttributes(address);
          if (location) this.clearRecordAttributes(location);
          place.set('address', address);
          place.set('location', location);
          placeModel = place;
        } //Setting the selected address


        if (this.get('addressPublicApi')) {
          this.get('addressPublicApi').onValueChange(placeModel);
        }
      },

      registerAddressPublicApi(publicApi) {
        this.set('addressPublicApi', publicApi);
      }

    }
  });

  _exports.default = _default;
});