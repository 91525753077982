define("adept-iq/pods/components/side-drawer/system-config/route-master/table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/side-drawer/system-config/route-master/table/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LOAD_INTERVAL = 1000;

  var _default = _component.default.extend({
    classNames: ['route-master-table'],
    config: _config.default,
    store: Ember.inject.service(),
    paginationEnabled: true,
    enableWarningRowChangeAlert: true,
    workspace: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    async didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      } //Before loading route-template records, fetch the models(route-template-availability, vehicle,shift-break )


      this.set('isLoading', true);
      await this.fetchModels();
      Ember.run.later(() => {
        if (this.get('isDestroyed')) return;
        this.set('isLoading', false);
        Ember.run.scheduleOnce('afterRender', this, 'refreshData');
      }, LOAD_INTERVAL);
    },

    async fetchModels() {
      const currentScheduleId = this.get('workspace.currentSchedule.id');
      const store = this.get('store');
      const modelPromises = [store.findAll('route-template-availability'), store.findAll('driver'), store.query('vehicle', {
        useVehicleService: true
      }), store.findAll('shift-break'), store.query('route', {
        filter: `eq(scheduleId,${currentScheduleId})&include=trips`
      })];
      await Promise.all(modelPromises);
    },

    fetchDataQuery() {
      if (this.get('isLoading')) {
        return Promise.resolve([]);
      }

      return this._super(...arguments).then(records => {
        return records.filter(record => !record.get('isNew'));
      });
    },

    hasPendingChanges: Ember.computed('hasRouteTemplateChanged', function () {
      const hasRouteTemplateChanged = this.get('hasRouteTemplateChanged') || false;
      return hasRouteTemplateChanged;
    }),

    saveRecord(record) {
      const saveRecordTask = this.get('saveRecordTask');
      if (saveRecordTask) return saveRecordTask();
      return record.save();
    },

    actions: {
      onRowClick(currentRow, event) {
        this._super(currentRow, event);
      }

    }
  });

  _exports.default = _default;
});