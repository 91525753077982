define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map-filter/index", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/side-drawer", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map-filter/layer-options", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map-filter/legend-options", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map-filter/label-options"], function (_exports, _react, _panel, _sideDrawer, _layerOptions, _legendOptions, _labelOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const MapFilter = () => {
    const {
      mapFilterEnabled,
      setMapFilterEnabled
    } = (0, _panel.usePanel)();
    const visibilityControlEnabled = false;
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", null, _react.default.createElement("button", {
      className: "playback-widget-button border-0",
      onClick: () => setMapFilterEnabled(true),
      "data-test-map-filter-button": true
    }, _react.default.createElement("i", {
      className: "fa fa-filter ",
      "aria-hidden": "true"
    }))), mapFilterEnabled && _react.default.createElement(_sideDrawer.default, {
      title: 'Map Layers',
      setFilterEnabled: setMapFilterEnabled,
      className: 'map-filter'
    }, _react.default.createElement("div", {
      "data-test-map-filter": ""
    }, _react.default.createElement("div", {
      className: "map-filter-subheader"
    }, _react.default.createElement("div", {
      className: "layer-subheader-section"
    }, _react.default.createElement(_layerOptions.default, null)), _react.default.createElement("div", {
      className: "visibility-subheader-section"
    }, visibilityControlEnabled && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: "side-drawer-checkbox"
    }, _react.default.createElement("input", {
      type: "checkbox"
    }), _react.default.createElement("label", null, "Show")), _react.default.createElement("div", {
      className: "map-layer-transparency"
    }, _react.default.createElement("label", null, "Transparency:"))))), _react.default.createElement("div", {
      className: "vehicle-events"
    }, _react.default.createElement(_legendOptions.default, null), _react.default.createElement(_labelOptions.default, null)))));
  };

  var _default = MapFilter;
  _exports.default = _default;
});