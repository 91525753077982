define("adept-iq/tests/factories/avlm-attribute", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  /* eslint-disable no-use-before-define */
  _emberDataFactoryGuy.default.define('avlm-attribute', {
    sequences: {
      types: num => attributeTypes[num % attributeTypes.length].type
    },
    default: {
      type: _emberDataFactoryGuy.default.generate('types')
    }
  });

  const attributeTypes = [{
    type: 'ambulatory'
  }, {
    type: 'wheelchair'
  }];
});