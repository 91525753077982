define("adept-iq/pods/components/dashboard-picker/component", ["exports", "ember-concurrency", "adept-iq/models/dashboard", "adept-iq/config/mapped-permIds"], function (_exports, _emberConcurrency, _dashboard, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    workspace: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    classNames: ['dashboard-picker'],
    dashboards: null,
    selectedSection: null,
    inlineRowModel: null,
    workspacePermissions: Ember.computed.readOnly('workspace.configPermissions.workspaces'),
    isLoading: Ember.computed.or('loadDashboardsTask.isRunning', 'transitionTask.isRunning'),
    roles: _dashboard.DASHBOARD_ROLES,

    didInsertElement() {
      this._super(...arguments);

      this.createContextMenu();
      this.get('loadDashboardsTask').perform().then(() => {
        Ember.run.schedule('afterRender', () => {
          this.$('.open-last-saved').focus();
          this.setupDefaultSection();
        });
      });
    },

    createContextMenu() {
      this.set('singleActions', this.createSingleActions());
    },

    createSingleActions() {
      return [{
        id: 'editWorkspaceAs',
        permId: _mappedPermIds.default.saveWorkspaceAs,
        name: 'Edit Name',
        action: () => {
          this.get('workspace').pushState('editWorkspaceAs', {
            displayName: 'Edit Workspace As',
            workspaceName: this.get('inlineRowModel.name')
          });
        }
      }];
    },

    setupDefaultSection() {
      const dashboardModel = this.get('workspace.dashboardModel');
      const sections = this.get('sections');
      if (Ember.isEmpty(sections) || Ember.isEmpty(dashboardModel)) return;
      sections.forEach(section => {
        if (Ember.isEmpty(section.dashboards)) return;
        const found = section.dashboards.find(dashboard => dashboard.name === dashboardModel.name);

        if (Ember.isPresent(found)) {
          this.set('selectedSection', section.title);
        }
      });
    },

    loadDashboardsTask: (0, _emberConcurrency.task)(function* () {
      const userId = this.get('session').data.authenticated.userId.replace(/-/g, '_');
      const dashboards = yield this.get('store').query('dashboard', {
        privateWorkspace: `workspaces-${userId}`
      });
      this.set('dashboards', dashboards);
    }).drop(),
    transitionTask: (0, _emberConcurrency.task)(function* (dashboard) {
      const workspace = this.get('workspace');
      const maximizer = this.get('maximizer');

      if (maximizer.get('maximizedWidget')) {
        maximizer.minimize();
      }

      yield workspace.popState('openWorkspace');

      if (dashboard === workspace.get('dashboardModel')) {
        // setting again will trigger reload
        workspace.set('dashboardModel', dashboard);
      } else {
        yield this.get('router').transitionTo('dashboard', dashboard);
      }
    }).drop(),
    dashboardDefaultLength: Ember.computed('selectedDashboards', 'selectedSection', function () {
      const dashboards = this.get('dashboards');
      const selectedSection = this.get('selectedSection');
      const count = [];

      if (dashboards) {
        dashboards.filter(data => {
          if (data && data.role === selectedSection && data.isDefaultWorkspace) {
            count.push(data);
          }
        });
      }

      return count.length;
    }),
    dashboardPublicLength: Ember.computed('selectedDashboards', 'selectedSection', function () {
      const dashboards = this.get('dashboards');
      const selectedSection = this.get('selectedSection');
      const count = [];

      if (dashboards) {
        dashboards.filter(data => {
          if (data && data.role === selectedSection && !data.isDefaultWorkspace && data.isPublicWorkspace) {
            count.push(data);
          }
        });
      }

      return count.length;
    }),
    dashboardPrivateLength: Ember.computed('selectedDashboards', 'selectedSection', function () {
      const dashboards = this.get('dashboards');
      const selectedSection = this.get('selectedSection');
      const count = [];

      if (dashboards) {
        dashboards.filter(data => {
          if (data && data.role === selectedSection && data.isPrivateWorkspace) {
            count.push(data);
          }
        });
      }

      return count.length;
    }),
    sections: Ember.computed('dashboards.[]', function () {
      let workspacePermissions = this.get('workspacePermissions');
      const dashboards = this.get('dashboards');
      if (Ember.isEmpty(dashboards)) return [];

      if (Ember.isPresent(workspacePermissions)) {
        workspacePermissions = workspacePermissions.sortBy('priority');
      }

      return workspacePermissions.map(workspacePermission => {
        const permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default[workspacePermission.permission], null);
        const isRoadSupEnable = this.get('user').isRoadSupEnable();
        const disabledInRoadSupMode = isRoadSupEnable && workspacePermission.permission !== 'accessRoadSupervisorWorkspace';

        if (permGranted && !disabledInRoadSupMode) {
          return {
            title: workspacePermission.displayName,
            dashboards: dashboards.filterBy('role', workspacePermission.role).sortBy('isDefaultWorkspace:asc')
          };
        }
      }).filter(Boolean);
    }),
    selectedDashboards: Ember.computed('sections', 'selectedSection', function () {
      const sections = this.get('sections');
      const selectedSection = this.get('selectedSection');
      if (Ember.isEmpty(sections) || Ember.isEmpty(selectedSection)) return [];
      return sections.filter(section => {
        return section.title === selectedSection;
      });
    }),
    actions: {
      onXButtonClick() {
        this.get('workspace').popState('openWorkspace');
      },

      selectSectionTab(tab) {
        this.set('selectedSection', tab);
      },

      openContextMenu(name, row) {
        this.set('inlineRowMenuPosition', () => {
          return {
            style: {
              top: row.clientY,
              left: row.clientX
            }
          };
        });
        const dashboards = this.get('dashboards');
        const dashboardModel = dashboards.findBy('name', name);
        this.set('inlineRowModel', dashboardModel);
        this.inlineRowMenu.actions.open();
        return false;
      },

      setInlineRowMenu(dropdown) {
        this.set('inlineRowMenu', dropdown);
      },

      onSingleRowAction(action) {
        this.inlineRowMenu.actions.close();

        if (action.action) {
          action.action();
        }
      }

    }
  });

  _exports.default = _default;
});