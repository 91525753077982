define("adept-iq/pods/components/iq-widgets/alert-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/alert-widget/config", "adept-iq/pods/components/iq-widgets/alert-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/config/mapped-permIds", "adept-iq/utils/rql-generators"], function (_exports, _component, _config, _avlmConfig, _environment, _mappedPermIds, _rqlGenerators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['alert-widget', 'data-test-alerts-widget'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    config: _config.default,

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('tableActions', [{
        id: 'showAll',
        name: 'Show All',
        permId: _mappedPermIds.default.showAllAlerts,
        action: () => {
          this.set('hiddenRowIds', []);
        }
      }]);
      this.set('hideAction', {
        id: 'hide',
        name: 'Hide',
        permId: _mappedPermIds.default.hideAlerts,
        action: alertRecord => {
          const hiddenRowIds = this.get('hiddenRowIds').concat(alertRecord.id);
          this.set('hiddenRowIds', hiddenRowIds);
        }
      });
    },

    dynamicSingleActions: Ember.computed('table.rows.@each.selected}', 'selectedWidgetRecord', function () {
      const hideAction = this.get('hideAction');
      const row = this.getSelectedCoreModel();

      if (!row) {
        return [];
      }

      return [hideAction];
    }),

    calculateTimeQuery() {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      const dateRangeFilterQuery = (0, _rqlGenerators.dateBetweenRQLUsingFormat)('time', [startTime, endTime], 'YYYY-MM-DDTHH:mm:ss.SSS');
      return dateRangeFilterQuery;
    },

    applyTransformationData(widgetRecords) {
      const priorityIdMapper = {
        'critical': 'A',
        'high': 'B',
        'medium': 'C',
        'low': 'D'
      };
      widgetRecords.forEach(widgetRecord => {
        if (!widgetRecord._transformed) {
          if (widgetRecord.priority && priorityIdMapper[widgetRecord.priority.toLowerCase()]) {
            widgetRecord.priorityId = priorityIdMapper[widgetRecord.priority.toLowerCase()];
          }

          widgetRecord._transformed = true;
        }
      });
      return widgetRecords;
    },

    rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currRowData) {
      if (currRowData.category && currRowData.category.toLowerCase() === 'emergency' || currRowData.priorityId && currRowData.priorityId === 'A') {
        return 'danger-row';
      }

      return '';
    },

    /**
     * Push all messages to Store
     */
    postHandleReceiveDataEventHandler(records) {
      const widgetService = this.get('widgetService');
      const widgetName = this.get('config.widgetName');
      widgetService.updateAllCoreEntityStoreAction(widgetName, records);
    },

    /**
     * Alert with completed state must be treated as Deleted
     * @param header
     * @param body
     * @returns {string|*}
     */
    determineWidgetRecordAction(widgetRecords, header, data) {
      if (data.state === 'completed') {
        return 'DELETE';
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});