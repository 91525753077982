define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dwhService: Ember.inject.service(),
    reconcileDate: Ember.computed.readOnly('dwhService.reconcileDate'),
    selectedRoute: Ember.computed.alias('dwhService.selectedRoute'),
    routeFound: Ember.computed.alias('dwhService.routeFound'),
    routeToSelect: Ember.computed.alias('dwhService.routeToSelect'),
    canSelect: Ember.computed('routeFound', function () {
      const routeFound = Ember.get(this, 'routeFound');
      if (routeFound && routeFound !== null) return true;
      return false;
    }),

    init() {
      this._super(...arguments);
    },

    actions: {
      async onSelectRouteClick() {
        const routeToSelect = this.get('routeToSelect');
        this.set('selectedRoute', routeToSelect);
        await this.get('dwhService.fetchTripsWithBreaks').perform(routeToSelect.id);
        Ember.set(this, 'selectedSearchMode', null);
        this.get('dwhService').toggleProperty('showSearchPopUp');
      },

      cancel() {
        Ember.set(this, 'selectedSearchMode', null);
        this.get('dwhService').toggleProperty('showSearchPopUp');
      }

    }
  });

  _exports.default = _default;
});