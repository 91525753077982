define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/otp-value-cell-item", ["exports", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-item"], function (_exports, _cellItem) {
  'use strict';

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line no-undef
  const _ = require("lodash");

  const OTP_LATE = 'Late';
  const OTP_DANGER = 'In Danger';
  const OTP_ON_TIME = 'On Time';

  class OTPValueCellItem extends _cellItem.default {
    constructor(props) {
      super(props);
    }

    getCellClasses(data, columnInfo, rowIndex, columnIndex, columnValue) {
      const cellClasses = [];
      const otpLateColor = columnValue === OTP_LATE ? 'otp-danger' : '';

      if (!_.isEmpty(otpLateColor)) {
        cellClasses.push(otpLateColor);
      }

      const otpDangerColor = columnValue === OTP_DANGER ? 'otp-warning' : '';

      if (!_.isEmpty(otpDangerColor)) {
        cellClasses.push(otpDangerColor);
      }

      const otpOnTimeColor = columnValue === OTP_ON_TIME ? 'otp-ontime' : '';

      if (!_.isEmpty(otpOnTimeColor)) {
        cellClasses.push(otpOnTimeColor);
      }

      return cellClasses.join(' ');
    }

  }

  _exports.default = OTPValueCellItem;
});