define("adept-iq/pods/components/form-components/boolean/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-boolean'],
    value: null,
    disabled: false,
    options: Ember.computed.alias('extra.options'),
    selected: Ember.computed('value', 'options', function () {
      const options = this.get('options');
      if (!Ember.isEmpty(options)) return {};
      return options.find(option => {
        return option.value === this.get('value');
      });
    }),
    actions: {
      onChange(option) {
        this.get('onChange')(Ember.get(option, 'value'));
      }

    }
  });

  _exports.default = _default;
});