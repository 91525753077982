define("adept-iq/pods/components/generic-components/vertical-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "62LuY0Mg",
    "block": "{\"symbols\":[\"model\",\"index\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"vertical-menu\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"models\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"generic-components/vertical-menu/item\",null,[[\"model\",\"index\",\"disableList\",\"onItemSelect\"],[[22,1,[]],[22,2,[]],[23,[\"disableList\"]],[27,\"action\",[[22,0,[]],\"onItemSelect\",[22,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/generic-components/vertical-menu/template.hbs"
    }
  });

  _exports.default = _default;
});