define("adept-iq/pods/components/side-drawer/activity-log/booking-activity-log/component", ["exports", "ember-concurrency", "adept-iq/pods/components/side-drawer/activity-log/booking-activity-log/config", "adept-iq/pods/components/side-drawer/activity-log/base-activity-log/component", "adept-iq/config/environment"], function (_exports, _emberConcurrency, _config, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const BOOKING_LOGGING_TYPE = 'booking';
  const NO_SHOW_ACTION_TYPE = 'noShow';

  var _default = _component.default.extend({
    tagName: '',
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    columns: _config.columns,
    table: null,
    userActivitySelected: null,
    errors: null,
    details: null,
    mockedUserEntries: null,
    addActivityLog: false,
    config: _config.default,
    isAvlmLite: _environment.default.APP.avlmLite,
    booking: Ember.computed.readOnly('stackFrame.options.booking'),
    bookingId: Ember.computed.readOnly('booking.id'),
    displayName: Ember.computed.readOnly('stackFrame.options.displayName'),
    isPlayback: Ember.computed.alias('workspace.isPlaybackWorkspace'),
    isLoading: Ember.computed.alias('fetchEventsTask.isRunning'),
    startDate: Ember.computed.alias('workspace.startDate'),
    endDate: Ember.computed.alias('workspace.endDate'),
    title: Ember.computed.alias('displayName'),
    isShrinkState: false,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this.loadCurrentState();
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('userActivitySelected', currentState.userActivity);
        this.set('details', currentState.details);
        this.set('isShrinkState', currentState.isShrinkState);
        this.set('addActivityLog', currentState.addActivityLog);
        this.set('errors', currentState.errors);
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      this.setCurrentStateInfo();
    },

    fetchEventsTask: (0, _emberConcurrency.task)(function* () {
      const store = this.store;
      const bookingId = this.get('booking.id');
      const adaptor = this.get('booking.adaptor') ? this.get('booking.adaptor') : 'activity-log';
      const activityLogAdapter = this.get('store').adapterFor(adaptor);

      function fetchFullRecords(activityLogs) {
        let driverDetails = null;
        return Promise.all(activityLogs.data.map(activityLog => {
          const driver = store.peekRecord('driver', activityLog.attributes.driverId);
          const vehicle = store.peekRecord('vehicle', activityLog.attributes.vehicleId);

          if (driver) {
            activityLog.attributes.driver = driver;
            driverDetails = driver;
          } else if (activityLog.attributes.driverId && parseInt(activityLog.attributes.driverId, 10) > 0) {
            store.findRecord('driver', activityLog.attributes.driverId).then(driverModel => {
              activityLog.attributes.driver = driverModel;
              driverDetails = driver;
            });
          }

          if (activityLog.attributes.actionType === NO_SHOW_ACTION_TYPE && driverDetails) {
            activityLog.attributes.driver = driverDetails;
          }

          if (vehicle) {
            activityLog.attributes.vehicle = vehicle;
          } else if (activityLog.attributes.vehicleId && parseInt(activityLog.attributes.vehicleId, 10) > 0) {
            store.findRecord('vehicle', activityLog.attributes.vehicleId).then(vehicleModel => {
              activityLog.attributes.vehicle = vehicleModel;
            });
          }
        })).then(() => {
          return activityLogs;
        });
      } // clear events table


      this.set('events', []);
      yield activityLogAdapter.getActivityLogs({
        bookingId,
        eventLog: true
      }).then(async activityLogs => {
        return fetchFullRecords(activityLogs);
      }).then(activityLogs => {
        this.processActivityLogs(activityLogs, BOOKING_LOGGING_TYPE);
      });
    }).drop(),

    setCurrentStateInfo() {
      const userActivity = this.get('userActivitySelected');
      const details = this.get('details');
      let currentState = this.get('currentState');
      this.set('isShrinkState', true);
      const isShrinkState = this.get('isShrinkState');
      const addActivityLog = this.get('addActivityLog');
      const errors = this.get('errors');
      currentState = {
        userActivity,
        details,
        isShrinkState,
        addActivityLog,
        errors
      };
      this.set('currentState', currentState);
    },

    actions: {
      onSaveClick() {
        if (!this.validateRecords()) return;
        this.set('errors', null);
        const activity = this.get('userActivitySelected');
        const details = this.get('details');
        const tooltip = this.get('tooltip');
        const title = this.get('title');
        const bookingId = this.get('fetchEventsTask.context.bookingId');
        const notifications = this.get('notifications');
        const self = this;
        const actions = [{
          activity,
          loggingType: 'booking',
          details
        }];
        tooltip.pushConfirmation({
          tip: 'Are you sure you want to add an entry to the activity log?',
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          secondaryActionText: 'Cancel',
          primaryAction: () => {
            const activityLogAdapter = self.get('store').adapterFor('activity-log');
            const createBookingActivity = activityLogAdapter.createGenericBookingActivity(bookingId, actions);
            return createBookingActivity.then(() => {
              notifications.success('New Activity Log Entry Added Successfully');
              tooltip.reset();
              self.toggleProperty('addActivityLog');
              self.fetchEventsTask.perform();
              self.set('userActivitySelected', '');
              self.set('details', '');
            }).catch(() => {
              notifications.warning('Failed to add Activity Log');
              tooltip.reset();
            });
          },

          secondaryAction() {
            tooltip.reset();
          }

        });
      },

      onAddClick() {
        this.set('errors', null);
        this.set('userActivitySelected', null);
        this.set('details', null);
        this.set('isShrinkState', false);
        this.toggleProperty('addActivityLog');
      },

      onShrinkClick() {
        this.setCurrentStateInfo();
        this.onShrinkClick();
      }

    }
  });

  _exports.default = _default;
});