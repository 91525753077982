define("adept-iq/pods/components/reset-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*eslint-disable */
    errors: undefined,
    success: undefined,
    newPassword: undefined,
    confirmPassword: undefined,

    /*eslint-enable */
    containsCaptial: Ember.computed('newPassword', function () {
      const pass = this.get('newPassword');
      if (!pass) return false;
      return pass.match(/[A-Z]/);
    }),
    containsNumber: Ember.computed('newPassword', function () {
      const pass = this.get('newPassword');
      if (!pass) return false;
      return pass.match(/[0-9]/);
    }),
    containsMoreThanEight: Ember.computed.gte('newPassword.length', 8),
    matchPassword: Ember.computed('newPassword', 'confirmPassword', function () {
      if (!this.get('newPassword')) return false;
      return this.get('newPassword') === this.get('confirmPassword');
    }),
    containsSymbol: Ember.computed('newPassword', function () {
      const pass = this.get('newPassword');
      if (!pass) return false;
      return pass.match(/[~!@#$%^&*()_\-+={};:'"|\\<>,.?/]/);
    }),
    isValid: Ember.computed.and('containsCaptial', 'containsNumber', 'containsMoreThanEight', 'matchPassword', 'containsSymbol'),
    actions: {
      onSubmit(newPassword, e) {
        e.preventDefault();
        this.get('onSubmit')(newPassword);
      }

    }
  });

  _exports.default = _default;
});