define("adept-iq/models/segment-stop", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    type: attr('string'),
    dwell: attr('number', {
      defaultValue: 0
    }),
    //TODO: what value for dwell
    notes: attr('string'),
    onboardCount: attr('number'),
    plannedEta: attr('date'),
    place: belongsTo('place'),
    // Ember-Data needs explicit inverses
    tripStop: belongsTo('trip-stop', {
      inverse: 'segmentStop'
    }),
    pickSegment: belongsTo('segment', {
      inverse: 'pick'
    }),
    dropSegment: belongsTo('segment', {
      inverse: 'drop'
    }),
    phoneNumbers: hasMany('segment-stop-phone-number'),
    tomtomFormattedAddress: Ember.computed.alias('place.address.tomtomFormattedAddress'),
    // use this to get the booking (e.g. from stops widget)
    segment: Ember.computed('pickSegment', 'dropSegment', function () {
      return this.get('pickSegment.content') || this.get('dropSegment.content');
    }),
    tripStartTime: Ember.computed('segment.tripStartTime', 'plannedTime', function () {
      const tripStartTime = this.get('segment.tripStartTime');
      return tripStartTime ? tripStartTime : this.get('tripStop.plannedEta');
    }),
    tripEndTime: Ember.computed('segment.tripEndTime', 'plannedTime', function () {
      const tripEndTime = this.get('segment.tripEndTime');
      return tripEndTime ? tripEndTime : this.get('tripStop.plannedEta');
    }),
    lat: Ember.computed('place.location.lat', function () {
      const lat = this.get('place.location.lat');
      return lat;
    }),
    lng: Ember.computed('place.location.lng', function () {
      const lng = this.get('place.location.lng');
      return lng;
    })
  });

  _exports.default = _default;
});