define("adept-iq/pods/components/reoptimize-modal/operation-tables/release-routes-provider-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    modelName: 'trip',
    defaultSortId: 'tripId',
    showSelectedRowLength: false,
    defaultSortAsc: false,
    queryModelData: true,
    columns: [{
      index: 0,
      id: 'tripId',
      type: 'uuid',
      label: 'Trip ID',
      valuePath: 'tripId',
      hidden: false,
      searchable: true
    }, {
      index: 1,
      id: 'httpStatusCode',
      type: 'number',
      label: 'HTTP Status Code',
      valuePath: 'statusCode',
      hidden: false,
      searchable: true
    }, {
      index: 2,
      id: 'errorCode',
      type: 'text',
      label: 'Error Code',
      valuePath: 'errorCode',
      hidden: false,
      searchable: true
    }, {
      index: 3,
      id: 'errorDescription',
      type: 'text',
      label: 'Error Description',
      valuePath: 'errorDescription',
      hidden: false,
      searchable: true
    }]
  };
  _exports.default = _default;
});