define("adept-iq/topic-transforms/avlm-stop-point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload, ENV) {
    const transformedPayloads = [];

    if (ENV.APP.avlmLite) {
      delete payload.data.attributes.status;
    }

    const {
      schedule,
      trip,
      cluster,
      address
    } = payload.data.relationships;

    if (schedule) {
      payload.data.relationships.avlmSchedule = schedule;
      delete payload.data.relationships.schedule;
    }

    if (cluster) {
      payload.data.relationships.avlmCluster = cluster;
      delete payload.data.relationships.cluster;
    }

    if (trip) {
      payload.data.relationships.avlmTrip = trip;
      delete payload.data.relationships.trip;
    }

    if (address) {
      payload.data.relationships.avlmAddress = address;
      delete payload.data.relationships.address;
    }

    transformedPayloads.push(payload);
    return transformedPayloads;
  }
});