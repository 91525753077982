define("adept-iq/pods/components/rider-management-widget/rider-contact-information/new-address-map/component", ["exports", "adept-iq/pods/components/generic-widgets/map-widget/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RecenterControl = void 0;
  const pintPointIcon = {
    icon: window.L.icon({
      iconUrl: '/map-icons/icon-pinpoint.svg',
      iconSize: [48, 48],
      iconAnchor: [24, 48]
    }),
    keyboard: false,
    interactive: false
  };
  const recenterControlOptions = {
    position: 'topleft',
    recenterAction: null
  };
  const defaultZoom = 12;
  var RecenterControl = window.L.Control.extend({
    options: recenterControlOptions,
    onAdd: function
      /* map */
    () {
      const name = 'leaflet-control-recenter';
      const container = window.L.DomUtil.create('div', 'leaflet-control-recenter leaflet-bar');
      this._recenterButton = this._createButton('<i class="fa fa-crosshairs fa-lg crosshairs-big" aria-hidden="true"></i>', 'Recenter', name, container, this._recenterMap);
      return container;
    },
    _recenterMap: function () {
      this.options.mapWidget.onRecenterClick();
    },
    _createButton: function (html, title, className, container, fn) {
      var link = window.L.DomUtil.create('a', className, container);
      link.innerHTML = html;
      link.href = '#';
      link.title = title;
      /*
       * Will force screen readers like VoiceOver to read this as 'Zoom in - button'
       */

      link.setAttribute('role', 'button');
      link.setAttribute('aria-label', title);
      window.L.DomEvent.disableClickPropagation(link);
      window.L.DomEvent.on(link, 'click', window.L.DomEvent.stop);
      window.L.DomEvent.on(link, 'click', fn, this);
      window.L.DomEvent.on(link, 'click', this._refocusOnMap, this);
      return link;
    }
  });
  _exports.RecenterControl = RecenterControl;

  var _default = _component.default.extend(Ember.Evented, {
    rider: Ember.inject.service(),
    booking: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    mapService: Ember.inject.service('map'),
    layerGroup: null,

    init() {
      this._super(...arguments);

      this.setProperties({
        layerGroup: new window.L.LayerGroup()
      });
      this.get('rider.activePlace');
    },

    didInsertElement() {
      this._super(...arguments);

      const map = this.get('map.native');
      const locationControl = new RecenterControl({
        mapWidget: this
      });
      locationControl.addTo(map);
      this.set('locationControl', locationControl);
      this.activeGeocode();
      this.onChangeActivePlace();
      this.recenterMap();
    },

    recenterMap() {
      const map = this.get('map.native');
      if (!map) return;
      const mapService = this.get('mapService');
      const center = [mapService.get('center.lat'), mapService.get('center.lon')];
      map.setView(center, defaultZoom);
    },

    destroy() {
      this._super(...arguments);

      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
      this.get('rider').setActivePlace(null);
    },

    async getGeoNodeForPickedLocation(lat, lng, beNomadAddress) {
      const notificationService = this.get('notifications');

      try {
        const geoNode = await this.get('geocode').calculateZone(lat, lng);

        if (Ember.isNone(geoNode)) {
          notificationService.warning('Unknown Geonode. Please enter a valid address.');
        }

        this.get('workspace').set('isGlobalSpinnerVisible', false);
        return geoNode;
      } catch (e) {
        notificationService.error(`Error while getting genode for ${beNomadAddress}.`);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    drawMarker(lat, lng) {
      const map = this.get('map.native');

      if (!Ember.isPresent(map) || Ember.isNone(lat) || Ember.isNone(lng)) {
        return;
      }

      this.get('layerGroup').clearLayers();
      const marker = window.L.marker([lat, lng], pintPointIcon);
      this.get('layerGroup').addLayer(marker).addTo(map);
      map.setView(marker._latlng, map.getZoom());
    },

    activeGeocode: Ember.observer('geocode.activeGeo', function () {
      const map = this.get('map.native');
      const geocodeService = this.get('geocode');
      const riderService = this.get('rider');
      const activeGeocode = geocodeService.get('activeGeo');
      if (!map) return;

      if (activeGeocode) {
        map.on('click', async function (event) {
          await geocodeService.saveNewGeocode(event.latlng);
          const place = geocodeService.get('currentRecord');
          riderService.setActivePlace(place);
        });
      } else {
        map.off('click');
      }
    }),

    onRecenterClick() {
      this.set('autoZoom', false);
      return this.reCenterMapView(true);
    },

    onChangeActivePlace: Ember.observer('rider.activePlace.location.lng', function () {
      const activePlace = this.get('rider.activePlace');

      if (!Ember.isPresent(activePlace)) {
        return;
      }

      const lat = activePlace.get('location.lat');
      const lng = activePlace.get('location.lng');
      this.drawMarker(lat, lng);
    })
  });

  _exports.default = _default;
});