define("adept-iq/pods/components/rider-management-widget/rider-contact-information/new-address-dialog/component", ["exports", "adept-iq/pods/components/rider-management-widget/rider-contact-information/new-address-dialog/config", "adept-iq/pods/components/rider-management-widget/rider-contact-information/new-address-dialog/states", "lodash", "adept-iq/utils/unwrapProxy"], function (_exports, _config, _states, _lodash, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ADDRESS_TYPE = {
    primary: 'primary',
    favorite: 'favorite'
  };

  var _default = Ember.Component.extend({
    classNames: ['add-rider-phone-detail-form'],
    workspace: Ember.inject.service(),
    booking: Ember.inject.service(),
    geocode: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    rider: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    addressConfig: _config.addressConfig,
    states: _states.states,
    addressTypeOptions: null,
    stateOptions: null,
    state: null,

    async init() {
      this._super(...arguments);

      const riderPlace = this.get('riderPlaceRecord.place');
      this.set('place', riderPlace);
      const addressTypeOptions = this.get('store').peekAll('rider-place-type').toArray();

      const stateOptions = _states.states.map(state => state.abbreviation);

      this.setProperties({
        addressTypeOptions,
        stateOptions
      });

      if (Ember.isPresent(riderPlace.get('location.lat'))) {
        this.get('rider').setActivePlace(riderPlace);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('setZName', this.get('setZName').bind(this));
      this.setState = this.setState.bind(this);
    },

    async setZName(placeRecord) {
      const addressRecord = placeRecord.get('address'); //const notificationService = this.get('notifications');

      if (!Ember.isEmpty(placeRecord.get('location.lat')) && !Ember.isEmpty(placeRecord.get('location.lng'))) {
        const geoNode = await this.get('geocode').calculateZone(placeRecord.get('location.lat'), placeRecord.get('location.lng'));
        const zoneName = this.store.peekRecord('zone-type', geoNode.zone.zoneName);
        addressRecord.set('zName', zoneName.get('displayName'));
        addressRecord.set('zoneName', zoneName);
      }
      /* if (isInsideAvailZones && !zoneName) {
        notificationService.warning('Unknown Address. Please enter a valid address.');
        return;
      }
      if (zoneName) {
        addressRecord.set('zName', zoneName);
      }
      if (!addressRecord.get('zName')) {
        notificationService.warning('Unknown Zone. Please enter a valid address.');
        return;
      } */

    },

    setState(placeRecord, benomadAddress) {
      const addressRecord = placeRecord.get('address');

      const state = _states.states.find(s => {
        return s.name === benomadAddress.address.state;
      });

      if (state) {
        addressRecord.set('region', state.abbreviation);
      }
    },

    validateRiderPrimaryAndFavoriteAddress() {
      const riderChangeSet = this.get('riderChangeSet');
      const riderPlaceRecord = this.get('riderPlaceRecord');
      const address = (0, _unwrapProxy.unwrapProxy)(riderPlaceRecord.get('place.address'));
      const addressId = riderPlaceRecord.get('place.address.id');
      const errors = [];
      const primaryAddresses = riderChangeSet.get('primaryAddresses');
      const favoriteAddresses = riderChangeSet.get('favoriteAddresses');

      if (Ember.isEmpty(address.get('freeformAddress')) && Ember.isEmpty(address.get('streetAddress')) && Ember.isEmpty(address.get('streetNumber'))) {
        const key = riderPlaceRecord.riderPlaceTypeName === ADDRESS_TYPE.primary ? 'primaryAddresses' : 'favoriteAddresses';
        errors.push({
          key: key,
          validation: ['Address cannot be empty']
        });
      } else if (!addressId && riderPlaceRecord.riderPlaceTypeName === ADDRESS_TYPE.primary && primaryAddresses.length > 1) {
        errors.push({
          key: 'primaryAddresses',
          validation: ['More than one Primary Address cannot be added']
        });
      } else if (!addressId && riderPlaceRecord.riderPlaceTypeName === ADDRESS_TYPE.favorite && favoriteAddresses.length > 20) {
        errors.push({
          key: 'favoriteAddresses',
          validation: ['More than 20 Favorite Address cannot be added']
        });
      } else if (Ember.isEmpty(address.get('zName'))) {
        const key = riderPlaceRecord.riderPlaceTypeName === ADDRESS_TYPE.primary ? 'primaryAddresses' : 'favoriteAddresses';
        errors.push({
          key: key,
          validation: ['Unable to save address. Location falls outside configured service zone(s).']
        });
      }

      return errors;
    },

    undoAddressChange() {
      const riderPlaceRecord = this.get('riderPlaceRecord');
      const isCreatingNewAddress = this.get('isCreatingNewAddress');

      if (riderPlaceRecord && isCreatingNewAddress) {
        const placeRecord = (0, _unwrapProxy.unwrapProxy)(riderPlaceRecord.get('place'));
        const addressRecord = (0, _unwrapProxy.unwrapProxy)(placeRecord.get('address'));
        const locationRecord = (0, _unwrapProxy.unwrapProxy)(placeRecord.get('location'));
        riderPlaceRecord.set('rider');
        locationRecord.deleteRecord();
        addressRecord.deleteRecord();
        placeRecord.deleteRecord();
        riderPlaceRecord.deleteRecord();
      }
    },

    actions: {
      onTypeChange(record, option) {
        record.set('riderPlaceType', option);
      },

      closeAddressDialog() {
        this.undoAddressChange();
        this.set('canShowNewAddressModal', false);
      },

      onCellValueChange(record, value, options) {
        record.set(`${options.valuePath}`, value);
      },

      onPlaceRecordChanged(record, benomadAddress) {
        this.setZName(record);
        this.setState(record, benomadAddress); //Addresses component use different values for region and locality. So we are setting it here.

        record.set('address.locality', benomadAddress.address.city);
        this.get('rider').setActivePlace(record);
      },

      onPickAddress(place) {
        this.get('rider').setActiveDragRiderPlace(place);
      },

      onTick() {
        const tooltip = this.get('tooltip');
        const primaryAndFavoriteAddressesErrors = this.validateRiderPrimaryAndFavoriteAddress();
        const errors = (0, _lodash.compact)([].concat(primaryAndFavoriteAddressesErrors));

        if (errors.length > 0) {
          tooltip.pushConfirmation({
            title: 'Rider Management',
            tip: '',
            errors,
            primaryActionText: 'OK',

            primaryAction() {
              tooltip.popConfirmation();
            }

          });
          return false;
        }

        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: 'Are you sure you want to save these changes?',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            this.set('isCreatingNewAddress', false);
            this.set('canShowNewAddressModal', false);
            tooltip.reset();
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            tooltip.reset();
          }
        });
      }

    }
  });

  _exports.default = _default;
});