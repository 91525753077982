define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-item", ["exports", "@babel/runtime/helpers/esm/extends", "react", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/base-cell-item", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-check-box", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/portal"], function (_exports, _extends2, _react, _baseCellItem, _cellCheckBox, _portal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OTP_LABEL_PROPERTY_NAME = void 0;
  const OTP_LABEL_PROPERTY_NAME = 'otpStatus';
  _exports.OTP_LABEL_PROPERTY_NAME = OTP_LABEL_PROPERTY_NAME;

  class CellItem extends _baseCellItem.default {
    constructor(props) {
      super(props);
      this.state = {
        isHovering: false,
        positionX: 0,
        positionY: 0
      };
      this.handleMouseHover = this.handleMouseHover.bind(this);
      this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    handleMouseHover(event) {
      this.setState({
        positionX: window.pageXOffset + event.clientX,
        positionY: window.pageYOffset + event.clientY,
        isHovering: true
      });
    }

    handleMouseOut() {
      this.setState({
        positionX: 0,
        positionY: 0,
        isHovering: false
      });
    }

    toggleHoverState(state) {
      const clonedState = { ...state
      };
      clonedState.isHovering = !state.isHovering;
      return clonedState;
    }

    render() {
      const {
        data,
        style,
        columnIndex,
        rowIndex,
        onContextMenuClick,
        onTableCellClick,
        columnInfo,
        showGearIcon,
        scrollToColumn,
        scrollToRow
      } = this.props;
      const {
        isHovering
      } = this.state;
      const {
        reordering
      } = this.props;
      const columnValue = this.getColumnValue(data, columnInfo, rowIndex, columnIndex);
      let classNames = this.getStylesClasses(data, rowIndex, columnInfo, columnIndex, scrollToRow, scrollToColumn, columnValue);
      const rowSelClasses = this.getRowStyleClasses(data, rowIndex, columnInfo, columnIndex, scrollToRow, scrollToColumn, columnValue);
      classNames = rowSelClasses + '  ' + classNames;
      return _react.default.createElement("span", {
        role: "none",
        className: classNames,
        style: style,
        onContextMenu: e => {
          e.preventDefault();
          onContextMenuClick(e, rowIndex, columnIndex);
        },
        onMouseEnter: this.handleMouseHover,
        onMouseLeave: this.handleMouseOut,
        onClick: () => onTableCellClick(rowIndex, columnIndex)
      }, this.renderCellCheckBox(), showGearIcon && this.renderCellCogButton(), this.renderCellValueItem(columnValue), isHovering && !reordering && _react.default.createElement(_portal.default, {
        className: 'tooltip'
      }, this.renderToolTipContent(this.props)));
    }

    renderToolTipContent(props) {
      const {
        positionX,
        positionY
      } = this.state;
      const toolTipStyles = {
        width: '150px',
        backgroundColor: '#000',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '6px',
        fontSize: '14px',
        padding: '2px 0',
        position: 'absolute',
        left: `${positionX}px`,
        top: `${positionY}px`,
        zIndex: 1000
      };
      const {
        data,
        rowIndex
      } = props;
      const rowData = data[rowIndex - 1];

      if (rowData.isLocked) {
        return _react.default.createElement("div", {
          style: toolTipStyles
        }, " Locked by ", rowData.lock.fullName);
      }
    }

    renderCellCheckBox() {
      const {
        data,
        columnIndex,
        rowIndex,
        checkedRowIds,
        disabledRowIds
      } = this.props;
      const selectedRowData = this.getRowData(data, rowIndex);
      const isDisabled = disabledRowIds.includes(selectedRowData.id);
      const isChecked = checkedRowIds.findIndex(obj => {
        if (selectedRowData.coreModel) {
          return obj.model === selectedRowData.coreModel && obj.id === selectedRowData.id;
        }

        return obj.id === selectedRowData.id;
      }) > -1;

      if (columnIndex === 0) {
        return _react.default.createElement(_cellCheckBox.default, (0, _extends2.default)({}, this.props, {
          isChecked: isChecked,
          isDisabled: isDisabled
        }));
      }
    }

    renderCellCogButton() {
      const {
        columnIndex,
        rowIndex,
        cellRightClickForTablet
      } = this.props;

      if (columnIndex === 1) {
        return _react.default.createElement("button", {
          className: 'btn-cog',
          onClick: e => {
            e.preventDefault();
            cellRightClickForTablet(e, rowIndex, columnIndex);
          }
        }, _react.default.createElement("i", {
          className: 'fa fa-cog ml-1'
        }));
      }
    }

    renderCellValueItem(columnValue) {
      const {
        columnIndex,
        showGearIcon
      } = this.props;
      const actionableColumnIndex = showGearIcon ? 1 : 0; // for checkbox and gear

      if (columnIndex > actionableColumnIndex) {
        return _react.default.createElement("span", {
          className: 'column-label'
        }, " ", columnValue, " ");
      }
    }

    getRowStyleClasses(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue) {
      const {
        rowStyleHandler
      } = this.props;

      if (rowStyleHandler) {
        const currRowData = this.getRowData(data, rowIndex) || {};
        return rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currRowData) || '';
      }

      return '';
    }

  }

  _exports.default = CellItem;
});