define("adept-iq/pods/components/iq-widgets/box-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aLD/xXuy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"analytics-widget-header\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"to-constrain analytics-widget-title\"],[9],[0,\"\\n    \"],[1,[21,\"chartTitle\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"analytics-widget-button\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-filter\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"onFilterButtonClick\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"analytics-widget-body box-widget-body\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"metric-container\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"iq-widgets/box-widget/box-metric\",null,[[\"metric\"],[[23,[\"boxMetric\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/box-widget/template.hbs"
    }
  });

  _exports.default = _default;
});