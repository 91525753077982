define("adept-iq/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mgoetpDl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"login-template\"],[9],[0,\"\\n\\n  \"],[7,\"h1\"],[9],[0,\"\\n    Sign On\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"login-form\",null,[[\"errors\",\"onSubmit\"],[[27,\"readonly\",[[23,[\"errors\"]]],null],[27,\"route-action\",[\"login\"],null]]]],false],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/login/template.hbs"
    }
  });

  _exports.default = _default;
});