define("adept-iq/pods/components/toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "57cLJDZT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"label\"],[9],[1,[21,\"offLabel\"],false],[3,\"action\",[[22,0,[]],\"off\"]],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"track\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"toggle\"],null]],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"button \",[27,\"if\",[[23,[\"disabled\"]],\"is-disabled\"],null]]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"label\"],[9],[1,[21,\"onLabel\"],false],[3,\"action\",[[22,0,[]],\"on\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/toggle-switch/template.hbs"
    }
  });

  _exports.default = _default;
});