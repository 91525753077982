define("adept-iq/pods/components/register-form/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    password: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
        on: 'password'
      })]
    },
    confirmPassword: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Passwords do not match'
    }),
    acceptTerms: (0, _emberCpValidations.validator)(value => value === true)
  }, {
    debounce: 500
  });

  var _default = Ember.Component.extend(Validations, {
    /*eslint-disable */
    invitaton: undefined,
    errors: undefined,
    password: undefined,
    confirmPassword: undefined,
    acceptTerms: false,

    /*eslint-enable */
    isValid: Ember.computed.alias('validations.isValid'),
    actions: {
      onSubmit(email, password, e) {
        e.preventDefault();
        this.get('onSubmit')(email, password);
      }

    }
  });

  _exports.default = _default;
});