define("adept-iq/pods/components/side-drawer/perform-stop/break/ui/component", ["exports", "adept-iq/pods/components/side-drawer/perform-stop/break/ui/config", "adept-iq/config/environment", "moment", "adept-iq/models/stop-point"], function (_exports, _config, _environment, _moment, _stopPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INTERVAL_DELAY = 60000;

  var _default = Ember.Component.extend({
    classNames: ['perform-break-drawer'],
    timeFormat: _environment.default.dateTimeFormat.dateTimeFlatPickr,
    shouldArrive: true,
    // pass in data to component
    stopPoint: null,
    driverBreak: null,
    errorText: null,
    actualArriveTime: null,
    arrive: false,
    depart: false,
    isArrived: null,
    isNextDisabled: null,
    maxDate: null,
    fields: _config.default.fields,
    endFields: _config.default.endFields,
    dateTimeFormat: _environment.default.dateTimeFormat,
    // pass in perform-all panel
    previousStop: null,
    // progress interval every minute
    _interval: null,
    dispatchRoute: Ember.computed.readOnly('stopPoint.dispatchRoute'),
    route: Ember.computed.readOnly('stopPoint.dispatchRoute.route'),
    routeVehicleDriver: Ember.computed.readOnly('route.dispatchRoute.latestRouteVehicleDriver'),
    address: Ember.computed.readOnly('driverBreak.place.address'),
    fixedBreakAddress: Ember.computed.readOnly('driverBreak.address'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),
    isLocationsDisabled: Ember.computed.equal('driverBreak.breakType.name', 'fixed'),
    lastPerformedStop: Ember.computed('dispatchRoute.orderedStops.@each.{arrive,depart,computedStatus}', function () {
      const stops = this.get('dispatchRoute.orderedStops');
      const order = [];
      if (Ember.isEmpty(stops)) return null;
      stops.filter(function (stop) {
        if (stop.get('depart') || stop.get('computedStatus') === _stopPoint.PERFORMED_STOP_STATUS || stop.get('computedStatus') === _stopPoint.ARRIVE_STOP_STATUS || stop.get('computedStatus') === _stopPoint.OFF_BREAK_STOP_STATUS) {
          order.push(stop);
        }
      });
      return order.pop();
    }),
    minTime: Ember.computed('lastPerformedStop.{arrive,depart,actualArriveTime,actualDepartTime,routeBreak.actualArriveTime,routeBreak.actualDepartTime}', 'previousStop.actualDepartTime', function () {
      const prevDepartTime = this.get('previousStop.actualDepartTime');
      if (prevDepartTime) return prevDepartTime;
      const prevStop = this.get('lastPerformedStop');
      let minTime = this.get('route.dispatchRoute.actualStartTimestamp') || this.get('route.plannedStartTime');
      if (!prevStop) return minTime;
      const actualArriveTime = prevStop.get('computedActualArriveTime'); // takes into account break stops

      const actualDepartTime = prevStop.get('computedActualDepartTime'); // takes into account break stops

      if (actualArriveTime) minTime = actualArriveTime;
      if (actualDepartTime) minTime = actualDepartTime;
      return minTime ? (0, _moment.default)(minTime).startOf('minute').toDate() : null; // we need something to separate stops
    }),
    unperformedIQStops: Ember.computed('dispatchRoute', 'stopPoint', function () {
      const dispatchRoute = this.get('dispatchRoute');
      const stopPoint = this.get('stopPoint');
      const unperformedIQStops = dispatchRoute.findUnperformedStopsInSequence(stopPoint);
      return unperformedIQStops;
    }),
    outOfOrderPerform: Ember.computed('isBulkPeform', 'unperformedIQStops.length', function () {
      return !this.get('isBulkPeform') && this.get('unperformedIQStops.length') > 1;
    }),

    init() {
      this._super(...arguments);

      const currentDate = new Date();
      currentDate.setMinutes(currentDate.getMinutes() + 1);
      this.set('maxDate', currentDate); // create a maxDate ticker that progresses by a minute

      this._interval = setInterval(() => {
        Ember.run.schedule('afterRender', () => {
          if (this.get('isDestroyed')) return;
          const maxDate = new Date();
          maxDate.setMinutes(maxDate.getMinutes() + 1);
          this.set('maxDate', maxDate);
        });
      }, INTERVAL_DELAY);
      const setStopUIReference = this.get('setStopUIReference');

      if (setStopUIReference) {
        setStopUIReference(this);
      }

      const breakType = this.get('driverBreak.breakType.name');

      if (!this.arrive && breakType !== 'fixed') {
        this.set('driverBreak.place', null);
      }
    },

    destroy() {
      clearInterval(this._interval);
      this._interval = null;
    },

    didInsertElement() {
      this.loadCurrentState();
    },

    setDepartTimeInArrivalTime() {
      const departTime = this.get('actualDepartTime');
      const arriveTime = this.get('actualArriveTime');

      if ((0, _moment.default)(departTime).isBefore(arriveTime, 'minutes')) {
        this.set('actualDepartTime', arriveTime);
      }
    },

    backUpCurrentState() {
      const {
        stopPoint,
        driverBreak,
        travelNeedOptions,
        travelNeedItems,
        arrive,
        depart,
        isArrived,
        isNextDisabled,
        actualArriveTime,
        actualDepartTime
      } = this;
      const odometerInMiles = stopPoint.get('odometerInMiles');
      const currentState = {
        driverBreak,
        travelNeedOptions,
        travelNeedItems,
        arrive,
        depart,
        isArrived,
        odometerInMiles,
        isNextDisabled,
        actualArriveTime,
        actualDepartTime
      };
      this.set('currentState', currentState);
    },

    loadCurrentState() {
      const currentState = this.get('currentState');
      const stopPoint = this.get('stopPoint');

      if (currentState) {
        const {
          odometerInMiles,
          driverBreak,
          travelNeedOptions,
          travelNeedItems,
          arrive,
          depart,
          isArrived,
          isNextDisabled,
          actualArriveTime,
          actualDepartTime
        } = currentState;
        stopPoint.set('odometerInMiles', odometerInMiles);
        this.setProperties({
          driverBreak,
          travelNeedOptions,
          travelNeedItems,
          arrive,
          depart,
          isArrived,
          isNextDisabled,
          actualArriveTime,
          actualDepartTime
        });
        this.set('currentState', null);
      }
    },

    actions: {
      onClickArrive(arrive) {
        this.set('arrive', arrive);

        if (arrive) {
          // cascade to stopPoint so ember-changeset will be triggered.
          this.get('stopPoint').set('arrive', arrive);
        } else {
          this.get('stopPoint').rollbackProperty('arrive');
        }
      },

      onClickDepart(depart) {
        if (depart) {
          this.set('arrive', depart);
          this.set('depart', depart); // cascade to stopPoint so ember-changeset will be triggered.

          this.get('stopPoint').set('depart', depart);
          this.set('isNextDisabled', false);
          return;
        }

        this.set('depart', depart); // cascade to stopPoint so ember-changeset will be triggered.

        this.get('stopPoint').rollbackProperty('depart');
        this.set('isNextDisabled', true);
      },

      onActualDepartTimeChange(dateValues) {
        const momentTime = (0, _moment.default)(dateValues[0]);
        this.set('actualDepartTime', momentTime.startOf('minute').toISOString());
        this.setDepartTimeInArrivalTime();
      },

      onActualArriveTimeChange(dateValues) {
        const momentTime = (0, _moment.default)(dateValues[0]);
        this.set('actualArriveTime', momentTime.startOf('minute').toISOString());
        this.setDepartTimeInArrivalTime();
      },

      onCellValueChange(record, valuePath, value, options) {
        record.set(`${valuePath}.${options.valuePath}`, value);
      }

    }
  });

  _exports.default = _default;
});