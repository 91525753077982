define("adept-iq/mixins/fetch-vehicle-events", ["exports", "ember-concurrency", "adept-iq/utils/geolib", "moment"], function (_exports, _emberConcurrency, _geolib, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const maxLenght = 4000; // max response value

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    radiusOptions: Ember.computed(function () {
      const options = [];

      for (let i = 1; i <= 10; i++) {
        options.push({
          id: i,
          value: i,
          title: i + (i === 1 ? ' mile' : ' miles')
        });
      }

      return options;
    }),
    fetchVehiclesTask: (0, _emberConcurrency.task)(function* () {
      const selectedProvider = this.get('selectedProvider');

      if (selectedProvider !== null) {
        const queryOptions = {
          provider: selectedProvider.id
        };
        const vehicles = yield this.get('store').query('playback-vehicle', queryOptions);

        if (!vehicles || !vehicles.length) {
          this.set('vehicles', []);
          return;
        }

        this.set('vehicles', vehicles.sortBy('vehicleId'));
      } else {
        const userProviders = this.get('session.data.authenticated.tokenInfo.providerNames') || [];
        const vehicles = yield this.get('store').query('playback-vehicle', {});

        if (!vehicles || !vehicles.length) {
          this.set('vehicles', []);
          return;
        }

        if (userProviders[0] === 'ALL') {
          this.set('vehicles', vehicles.sortBy('vehicleId'));
        } else if (userProviders && userProviders.length > 0) {
          const filteredVehicles = vehicles.filter(data => {
            return userProviders.includes(data.provider);
          });
          this.set('vehicles', filteredVehicles.sortBy('vehicleId'));
        }
      }
    }),
    fetchRoutesTask: (0, _emberConcurrency.task)(function* () {
      const selectedProvider = this.get('selectedProvider');

      if (selectedProvider !== null) {
        const queryOptions = {
          provider: selectedProvider.id
        };
        const routes = yield this.get('store').query('playback-route', queryOptions);

        if (!routes || !routes.length) {
          this.set('routes', []);
          return;
        }

        this.set('routes', routes.sortBy('routeId'));
      } else {
        const userProviders = this.get('session.data.authenticated.tokenInfo.providerNames') || [];
        const routes = yield this.get('store').query('playback-route', {});

        if (!routes || !routes.length) {
          this.set('routes', []);
          return;
        }

        if (userProviders[0] === 'ALL') {
          this.set('routes', routes.sortBy('routeId'));
        } else if (userProviders && userProviders.length > 0) {
          const filteredRoutes = routes.filter(data => {
            return userProviders.includes(data.provider);
          });
          this.set('routes', filteredRoutes.sortBy('routeId'));
        }
      }
    }),
    fetchDriversTask: (0, _emberConcurrency.task)(function* () {
      const selectedProvider = this.get('selectedProvider');

      if (selectedProvider !== null) {
        const providerId = selectedProvider.id;
        const queryOptions = {
          provider: providerId
        };
        const drivers = yield this.get('store').query('playback-driver', queryOptions);

        if (!drivers || !drivers.length) {
          this.set('drivers', []);
          return;
        }

        this.set('drivers', drivers.sortBy('driverId'));
      } else {
        const userProviders = this.get('session.data.authenticated.tokenInfo.providerNames') || [];
        const drivers = yield this.get('store').query('playback-driver', {});

        if (!drivers || !drivers.length) {
          this.set('drivers', []);
          return;
        }

        if (userProviders[0] === 'ALL') {
          this.set('drivers', drivers.sortBy('driverId'));
        } else if (userProviders && userProviders.length > 0) {
          const filteredDrivers = drivers.filter(data => {
            return userProviders.includes(data.provider);
          });
          this.set('drivers', filteredDrivers.sortBy('driverId'));
        }
      }
    }),
    fetchRadiusVehiclesTask: (0, _emberConcurrency.task)(function* () {
      const selectedDateTimeRage = this.get('selectedDateTimeRage');
      const selectedAddress = this.get('selectedAddress');
      const selectedRadius = this.get('selectedRadius');
      const queryOptions = {
        lat: selectedAddress.lat,
        lng: selectedAddress.lng,
        radius: selectedRadius.value,
        startTime: selectedDateTimeRage.starttime,
        endTime: selectedDateTimeRage.endtime
      };
      const radiusVehicles = yield this.get('store').query('avlm-vehicle-radius', queryOptions);
      radiusVehicles.forEach(event => {
        event.set('lat', event.get('lat') ? Number(event.get('lat')).toFixed(6) : event.get('lat'));
        event.set('lng', event.get('lng') ? Number(event.get('lng')).toFixed(6) : event.get('lng'));
      });

      if (!radiusVehicles || !radiusVehicles.length) {
        this.set('radiusVehicles', []);
        return;
      } else if (radiusVehicles.length > maxLenght) {
        this.set('radiusVehicles', []);
        this.set('tooLargeData', 'tooLargeData');
      } else {
        this.set('radiusVehicles', radiusVehicles.sortBy('vehicleId'));
      }
    }).restartable(),
    fetchEventsTask: (0, _emberConcurrency.task)(function* () {
      this.set('durationInSeconds', null);
      this.set('totolTimeInSeconds', null);
      this.set('bufferSize', 200);
      const selectedVehicle = this.get('selectedVehicle');
      const selectedRoute = this.get('selectedRoute');
      const selectedDriver = this.get('selectedDriver');
      const selectedRadiusVehicle = this.get('selectedRadiusVehicle');
      const selectedDateTimeRage = this.get('selectedDateTimeRage');
      const activeTabModeId = this.get('activeTabModeId');
      if (Ember.isNone(selectedVehicle) && Ember.isNone(selectedRadiusVehicle)) return;
      yield this.get('store').unloadAll('avlm-vehicle-event');
      const vehicleId = activeTabModeId === 'byVehicle' ? selectedVehicle.id : selectedRadiusVehicle.vehicleId;
      const routeId = selectedRoute === null ? '' : selectedRoute.name;
      const driverId = selectedDriver === null ? '' : selectedDriver.id;
      const queryOptions = {
        routeIds: routeId,
        // Added according to NYAAR-9664
        driverIds: driverId,
        // Added according to NYAAR-9664
        vehicle: vehicleId,
        startTime: selectedDateTimeRage.starttime,
        endTime: selectedDateTimeRage.endtime
      };
      const events = yield this.get('store').query('avlm-vehicle-event', queryOptions);
      events.forEach(event => {
        event.set('isChecked', true);
        event.set('lat', event.get('lat') ? Number(event.get('lat')).toFixed(6) : event.get('lat'));
        event.set('lng', event.get('lng') ? Number(event.get('lng')).toFixed(6) : event.get('lng'));
      });

      if (events.length > maxLenght) {
        this.set('tooLargeData', 'tooLargeData');
      } else {
        this.set('tooLargeData', '');
        this.set('vehicleEvents', events.sortBy('timestamp').toArray());
      }
    }).restartable(),
    fetchPolylineTask: (0, _emberConcurrency.task)(function* () {
      const route = yield this.get('store').peekRecord('avlm-route', this.get('selectedRoute.model.id')); // route is layerRecords.

      const events = [];
      const stopPoints = route.get('orderedStopsWithGaragesAndBreaks');
      let timebase;
      stopPoints.forEach(stopPoint => {
        const points = stopPoint.get('polylinePoints');
        const start = stopPoint.get('tripStartTime');
        const end = stopPoint.get('tripEndTime');

        if (!timebase) {
          timebase = start;
        }

        if (points && points.length > 0) {
          const totalDistance = _geolib.default.computePolylineLength(points);

          const diff = (0, _moment.default)(end).unix() - (0, _moment.default)(start).unix();
          points.forEach((point, i) => {
            const travelPoints = points.slice(0, i + 1);

            const travelDistance = _geolib.default.computePolylineLength(travelPoints);

            const seconds = diff * travelDistance / totalDistance;
            const timestamp = (0, _moment.default)(timebase).add(seconds, 'seconds').format();
            const event = this.get('store').createRecord('avlm-vehicle-event', {
              lat: point[0],
              lng: point[1],
              timestamp,
              vehicleId: route.get('latestVehicle.id'),
              isChecked: true
            });
            events.push(event);
          }); // continue to next stopPoints.

          timebase = (0, _moment.default)(timebase).add(diff, 'seconds').format();
        }
      });
      this.set('vehicleEvents', events);
    })
  });

  _exports.default = _default;
});