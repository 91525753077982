define("adept-iq/instance-initializers/work", ["exports", "adept-iq/classes/work-queues/widget-refresh"], function (_exports, _widgetRefresh) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  // instantiates the shared widget refresh queue; used to synchronize updates
  // to all column widgets
  function initialize(applicationInstance) {
    const work = applicationInstance.lookup('service:work');
    const workspace = applicationInstance.lookup('service:workspace');

    const widgetRefreshQueue = _widgetRefresh.default.create({
      workspace
    });

    work.registerQueue(widgetRefreshQueue);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});