define("adept-iq/tests/factories/vehicle", ["ember-data-factory-guy", "faker"], function (_emberDataFactoryGuy, _faker) {
  "use strict";

  const date = new Date();

  _emberDataFactoryGuy.default.define('vehicle', {
    sequences: {
      randomMileage: () => _faker.default.random.number({
        min: 0,
        max: 94830
      }),
      randomBoolean: () => _faker.default.random.boolean(),
      nums: num => `vehicle ${num}`
    },
    default: {
      name: _emberDataFactoryGuy.default.generate('nums'),
      mobileId: _emberDataFactoryGuy.default.generate(i => `${i}`),
      xmin: 'xmin',
      serviceStatus: 'some service status',
      lastGpsPing: date,
      mileage: _emberDataFactoryGuy.default.generate('randomMileage'),
      // added for vehicleWidget streaming topic
      active: _emberDataFactoryGuy.default.generate('randomBoolean'),
      status: 'some status',
      // routeNames: attr('object'),
      lat: _faker.default.address.latitude(),
      lng: _faker.default.address.longitude()
    }
  });
});