define("adept-iq/pods/components/reoptimize-modal/operation-views/open-zones/component", ["exports", "adept-iq/config/api-urls", "adept-iq/models/zone"], function (_exports, _apiUrls, _zone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IN_ACTIVE_STATUS = 'inactive';

  var _default = Ember.Component.extend({
    classNames: ['open-zones'],
    workspaceContext: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // Passed In Attributes
    title: 'Open Zones',
    currentSchedule: null,
    selectedProvider: null,
    zonesList: null,
    currentlyProcessing: false,
    isTaskRunning: Ember.computed.alias('currentlyProcessing'),

    init() {
      this._super(...arguments);

      this.get('store').findAll('zone').then(zones => {
        const sortedZones = zones.toArray().sort((a, b) => {
          const aId = parseInt(a.id, 10);
          const bId = parseInt(b.id, 10);
          return aId - bId;
        });
        sortedZones.map(zone => {
          zone.set('openZoneSelected', false);
        });
        this.set('zonesList', sortedZones);
      });
    },

    providersList: Ember.computed('workspaceContext.providersList', function () {
      const providers = this.get('workspaceContext.providersList');
      const activeProviders = providers.filter(provider => {
        return !(provider.get('status').toLowerCase() === IN_ACTIVE_STATUS);
      });
      return activeProviders;
    }),
    isDisabled: Ember.computed('selectedProvider', 'zonesList.@each.openZoneSelected', 'currentlyProcessing', function () {
      const zonesList = this.get('zonesList');
      return !Ember.isPresent(this.get('selectedProvider')) || !zonesList.any(zone => zone.openZoneSelected) || this.get('currentlyProcessing');
    }),

    async getCurrentScheduleProviderRoute(scheduleId, providerId) {
      const session = this.get('session');
      return this.get('ajax').post(`${_apiUrls.API.schedulingService.host}/route?filter=and(eq(scheduleId,'${scheduleId}'),eq(providerName,'${providerId}'))`, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).then(response => {
        return response.data;
      });
    },

    async patchScheduleRoute(scheduleRoute, affinities) {
      const session = this.get('session');
      const id = scheduleRoute.id;
      const json = {
        id,
        type: 'route',
        attributes: {
          affinities
        }
      };
      return this.get('ajax').patch(_apiUrls.API.schedulingService.host + '/route/' + id, {
        method: 'PATCH',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      }).then(response => {
        return response;
      }).catch(error => {
        return error;
      });
    },

    processAffinitiesToChange(affinitiesToChange, currentAffinities) {
      const newAffinities = [];
      let needsUpdate = false; // only change affinities that are in no service to last resort

      affinitiesToChange.forEach((change, index) => {
        const currentAffinity = Ember.isPresent(currentAffinities[index]) ? currentAffinities[index] : _zone.AFFINITY_NO_SERVICE.val;

        if (change && currentAffinity === _zone.AFFINITY_NO_SERVICE.val) {
          newAffinities.push(_zone.AFFINITY_LAST_RESORT.val);
          needsUpdate = true;
        } else {
          newAffinities.push(currentAffinity);
        }
      });
      return needsUpdate ? newAffinities : null;
    },

    async onOpenZoneBtnClick() {
      this.set('currentlyProcessing', true);
      const patchScheduleRoute = this.get('patchScheduleRoute').bind(this);
      const processAffinitiesToChange = this.get('processAffinitiesToChange').bind(this);
      const currentSchedule = this.get('currentSchedule');
      const selectedProvider = this.get('selectedProvider');
      const affinitiesToChange = this.get('zonesList').map(zone => zone.openZoneSelected);
      const responseData = await this.getCurrentScheduleProviderRoute(currentSchedule.id, selectedProvider.id);
      const results = await Ember.RSVP.Promise.all(responseData.map(async routeData => {
        const currentAffinities = routeData.attributes.affinities;
        const newAffinities = processAffinitiesToChange(affinitiesToChange, currentAffinities);

        if (Ember.isPresent(newAffinities)) {
          return await patchScheduleRoute(routeData, newAffinities);
        }
      }));
      const failedResult = results.filter(result => Ember.isPresent(result));

      if (failedResult.length > 0) {
        this.get('notifications').warning(`FAILED TO OPEN ZONES FOR ${failedResult.length} ROUTE(S).`);
      } else {
        this.get('notifications').success('SUCCESSFULLY OPENED ZONES.');
      }

      this.set('currentlyProcessing', false);
    },

    actions: {
      onXButtonClick() {
        this.get('workspace').popState('reoptimize');
      },

      selectOneClick(zone, event) {
        zone.set('openZoneSelected', event.srcElement.checked);
      },

      onProviderSelected(val) {
        this.set('selectedProvider', val);
      },

      onOpenZonesClick() {
        this.onOpenZoneBtnClick();
      },

      selectAllClick(event) {
        const check = event.srcElement.checked;
        const zonesList = this.get('zonesList');
        zonesList.map(zone => zone.set('openZoneSelected', check));
      }

    }
  });

  _exports.default = _default;
});