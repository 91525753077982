define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/driver-providers/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/driver-providers/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['driver-provider-table'],
    config: _config.default,
    store: Ember.inject.service(),
    paginationEnabled: true,
    enableWarningRowChangeAlert: true,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    rollbackRecord() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      if (this.get('usingRadioRowComponent')) {
        if (record && !record.get('isNew')) record.rollbackAttributes();
      }
    }

  });

  _exports.default = _default;
});