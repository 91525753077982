define("adept-iq/utils/datetime-filters", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildDateFilter = buildDateFilter;
  _exports.buildTimeFilter = buildTimeFilter;

  const dateContainsTime = givenDate => (0, _moment.default)(givenDate).format('HH:mm') !== '00:00';

  function dateBetweenForEqualAndNotEqual(filternode) {
    const givenDate = filternode.local.values[0];
    const isTimeGiven = dateContainsTime(givenDate);

    if (isTimeGiven) {
      const startDate = (0, _moment.default)(givenDate).utc().startOf('minute').toDate();
      const endDate = (0, _moment.default)(givenDate).utc().endOf('minute').toDate();
      filternode.local.values[0] = startDate;
      filternode.local.values[1] = endDate;
    } else {
      const startDay = (0, _moment.default)(givenDate).startOf('day').utc().toDate();
      const endDay = (0, _moment.default)(givenDate).endOf('day').utc().toDate();
      filternode.local.values[0] = startDay;
      filternode.local.values[1] = endDay;
    }
  }

  function dateEqualFilter(filternode) {
    filternode.type = 'dateBetween';
    dateBetweenForEqualAndNotEqual(filternode);
  }

  function dateStrNotEqualFilter(filternode) {
    filternode.type = 'dateStrNotBetween';
    dateBetweenForEqualAndNotEqual(filternode);
  }

  function dateNotEqualFilter(filternode) {
    filternode.type = 'dateNotBetween';
    dateBetweenForEqualAndNotEqual(filternode);
  }

  function dateLessThanConverter(filternode) {
    const givenDate = filternode.local.values[0];
    const isTimeGiven = dateContainsTime(givenDate);

    if (isTimeGiven) {
      filternode.local.values[0] = _moment.default.utc(givenDate).toDate();
    } else {
      filternode.local.values[0] = (0, _moment.default)(givenDate).startOf('day').utc().toDate();
    }
  }

  function dateLessThanEqualConverter(filternode) {
    const givenDate = filternode.local.values[0];
    const isTimeGiven = dateContainsTime(givenDate);

    if (isTimeGiven) {
      filternode.local.values[0] = _moment.default.utc(givenDate).endOf('minute').toDate();
    } else {
      filternode.local.values[0] = (0, _moment.default)(givenDate).endOf('day').utc().toDate();
    }
  }

  function dateGreaterThanConverter(filternode) {
    const givenDate = filternode.local.values[0];
    const isTimeGiven = dateContainsTime(givenDate);

    if (isTimeGiven) {
      filternode.local.values[0] = _moment.default.utc(givenDate).endOf('minute').toDate();
    } else {
      filternode.local.values[0] = (0, _moment.default)(givenDate).endOf('day').utc().toDate();
    }
  }

  function dateGreaterThanEqualConverter(filternode) {
    const givenDate = filternode.local.values[0];
    const isTimeGiven = dateContainsTime(givenDate);

    if (isTimeGiven) {
      filternode.local.values[0] = _moment.default.utc(givenDate).toDate();
    } else {
      filternode.local.values[0] = (0, _moment.default)(givenDate).endOf('day').utc().toDate();
    }
  }

  function dateBetweenThanConverter(filternode) {
    const givenDate = filternode.local.values[0];
    const isTimeGiven = dateContainsTime(givenDate);

    if (isTimeGiven) {
      const startDate = (0, _moment.default)(filternode.local.values[0]).subtract(1, 'minutes').utc().toDate();
      const endDate = (0, _moment.default)(filternode.local.values[1]).add(1, 'minutes').utc().toDate();
      filternode.local.values[0] = startDate;
      filternode.local.values[1] = endDate;
    } else {
      const startDay = (0, _moment.default)(filternode.local.values[0]).startOf('day').utc().toDate();
      const endDay = (0, _moment.default)(filternode.local.values[1]).endOf('day').utc().toDate();
      filternode.local.values[0] = startDay;
      filternode.local.values[1] = endDay;
    }
  }

  function defaultDateConverter(filternode) {
    const utcValues = filternode.local.values.map(givenDate => {
      return _moment.default.utc(givenDate).toDate();
    });
    filternode.local.values = utcValues;
    filternode.rql.values = utcValues;
  }

  function buildDateFilter(filternode) {
    switch (filternode.type) {
      case 'dateStrEq':
        return dateEqualFilter(filternode);

      case 'dateStrNe':
        return dateStrNotEqualFilter(filternode);

      case 'dateNe':
        return dateNotEqualFilter(filternode);

      case 'dateLt':
        return dateLessThanConverter(filternode);

      case 'dateLte':
        return dateLessThanEqualConverter(filternode);

      case 'dateGt':
      case 'datGte':
        return dateGreaterThanConverter(filternode);

      case 'dateBetween':
        return dateBetweenThanConverter(filternode);

      default:
        return defaultDateConverter(filternode);
    }
  }

  function buildTimeFilter(filternode) {
    switch (filternode.type) {
      case 'timeEq':
        return dateEqualFilter(filternode);

      case 'timeLt':
        return dateLessThanConverter(filternode);

      case 'timeLte':
        return dateLessThanEqualConverter(filternode);

      case 'timeGt':
        return dateGreaterThanConverter(filternode);

      case 'timeGte':
        return dateGreaterThanEqualConverter(filternode);

      case 'timeBetween':
        return dateBetweenThanConverter(filternode);

      default:
        return defaultDateConverter(filternode);
    }
  }
});