define("adept-iq/adapters/booking", ["exports", "adept-iq/adapters/-bs-bookingManagement", "adept-iq/config/api-urls"], function (_exports, _bsBookingManagement, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingManagement.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),

    queryRecord(store, type, id) {
      const session = this.get('session');
      return this.get('ajaxService').request(_apiUrls.API.bookingService.host + '/booking/' + id, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    postBooking(json) {
      const session = this.get('session');
      return this.get('ajaxService').post(_apiUrls.API.tasService.host + '/booking', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`,
          'x-api-key': 'PXS7q1lfrt5RV2heb5ySc2QIQdqJ6I0M6C16kdXq'
        },
        data: json
      });
    }

  });

  _exports.default = _default;
});