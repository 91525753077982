define("adept-iq/pods/components/side-drawer/system-config/providers/component", ["exports", "ember-concurrency", "moment", "lodash", "adept-iq/config/api-urls", "adept-iq/utils/regex-functions"], function (_exports, _emberConcurrency, _moment, _lodash, _apiUrls, _regexFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NUMBER_FORMAT = /^[\d]*$/;
  const REMOVE_TEXT_FORMAT = /[^0-9.]+/;
  const SPECIAL_CHARACTER_FORMAT = /[^a-zA-Z0-9]/g;
  const daysOfService = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Holidays', null];
  const costLimit = {
    min: -1,
    isFloat: true,
    digitPrecision: 2
  };
  const BROKER_PROVIDER_TYPE = 'broker';
  const MAX_BROKER_PROVIDER = 10;
  const ACTIVE_STATUS = 'active';
  const PROVIDER_ASSOCIATIONS = [{
    model: 'subscription',
    providerName: 'providerName'
  }, {
    model: 'driver',
    providerName: 'providerName'
  }, {
    model: 'vehicle',
    providerName: 'providerName'
  }, {
    model: 'trip',
    providerName: 'currentProviderName'
  }, {
    model: 'route',
    providerName: 'providerName'
  }];

  var _default = Ember.Component.extend({
    classNames: ['providers'],
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    workspace: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    daysOfService,
    dayOfWeeks: null,
    value: null,
    selected: null,
    status: null,
    disableBtns: null,
    minTripCountDate: null,
    maxTripCountDate: null,
    tripCountWeekDays: null,
    zipCodes: null,
    zipCodesSelected: null,
    zoneNames: null,
    zones: null,
    selectedZone: null,
    selectedCostZoneOne: null,
    selectedCostZoneTwo: null,
    newZoneName: null,
    firstZoneName: null,
    secondZoneName: null,
    zoneCost: null,
    cost: null,
    providersList: null,
    isNewProvider: false,
    disableForm: true,
    providerChanges: false,
    inValidEmail: false,
    inValidId: false,
    disableTripCount: true,
    addZoneName: false,
    addCostName: false,
    inValidZone: false,
    inValidCostZone: false,
    isNewZoneCost: true,
    isNewBroker: false,
    mandatoryError: false,
    isZipCodeAvailabel: true,
    duplicateIDError: false,
    duplicateNameError: false,
    inValidZoneError: false,
    disableBrokerForm: true,
    disableZoneRemoveAction: true,
    disableZoneSaveAction: true,
    disableCostRemoveAction: true,
    disableCostSaveAction: true,
    disableCostForm: true,
    configType: null,
    costLimit,
    isDeleteProvider: false,
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    disableUndobtn: Ember.computed('disableForm', 'providerFormChanges', function () {
      const disableForm = this.get('disableForm');
      const providerFormChanges = this.get('providerFormChanges');
      const returnValue = !(!disableForm && providerFormChanges);
      this.hasChanges(!returnValue);
      return returnValue;
    }),
    showBrokerPanel: Ember.computed('provider.providerTypeName', function () {
      const type = this.get('provider.providerTypeName');
      return type === 'broker';
    }),
    providerFormChanges: Ember.computed('providerChanges', 'provider.{id,name,status,providerTypeName,contactName,contactPhone,contactEmail}', function () {
      const providerChanges = this.get('providerChanges');

      if (providerChanges) {
        return true;
      }

      return false;
    }),
    disableSavebtn: Ember.computed('disableForm', 'provider.{id,name,status,providerTypeName,contactName,contactPhone,contactEmail}', function () {
      const disableForm = this.get('disableForm');

      if (disableForm) {
        return true;
      }

      const hasValues = Object.values(this.get('provider')).some(input => !Ember.isEmpty(input));
      return !hasValues;
    }),
    disableSelectedZone: Ember.computed('disableForm', 'addZoneName', function () {
      const disableForm = this.get('disableForm');
      const addZoneName = this.get('addZoneName');
      if (addZoneName) return true;
      return disableForm;
    }),
    // @TODO: transform into computed property similar to route master
    selectedRowChange: Ember.observer('selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      this.clearPerviousRecordPropertites();
      this.setDefaultProperties(selectedRow);
      this.buttonSettings();

      if (!selectedRow) {
        this.buttonSettings(false, true, true);
      }
    }),

    hasChanges(value) {
      this.set('systemConfig.hasChanges', value);
    },

    init() {
      this._super(...arguments);

      this.set('status', ['Active', 'Inactive']);
      this.setupProviderList();
      this.setDefaultProperties();
      this.setupZipCodes();
      this.buttonSettings();
      const today = new Date().toDateString();
      const minDate = (0, _moment.default)(today).add(0, 'days').toDate();
      const maxDate = (0, _moment.default)(today).add(2, 'days').toDate(); // The following line added to initialize the service reference

      this.get('providerChanges');
      this.set('maxTripCountDate', maxDate);
      this.set('minTripCountDate', minDate);
      this.undoProvider = this.undoProvider.bind(this);
      this.saveProvider = this.saveProvider.bind(this);
      this.hasChanges = this.get('hasChanges').bind(this);
      this.maxBrokerProviderWarning = this.maxBrokerProviderWarning.bind(this);
      this.checkMaxBrokerProviderRecords = this.checkMaxBrokerProviderRecords.bind(this);
    },

    willDestroyElement() {
      const providerChanges = this.get('providerChanges');

      if (providerChanges) {
        this.undoProvider();
      }

      this._super(...arguments);
    },

    clearPerviousRecordPropertites() {
      this.setProperties({
        zipCodesSelected: null,
        selectedZone: null,
        modifiedZoneName: null,
        selectedCostZoneOne: null,
        selectedCostZoneTwo: null,
        cost: null,
        originalTripCount: null,
        originalServiceZones: null,
        originalCosts: null
      });
    },

    /**
     * for returning tripCountValues
     * @param {Array} tripCountWeekDays
     * @returns {*Array}  tripCountWeekDays
     */
    tripCountWeekDaysValues(tripCountWeekDays) {
      this.get('daysOfService').forEach(day => {
        const item = Ember.Object.create({
          day,
          values: {
            targetCount: 0,
            underage: 0,
            overage: 0
          }
        });
        tripCountWeekDays.push(item);
      });
      return tripCountWeekDays;
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      const fields = {
        id: '',
        name: '',
        displayName: '',
        status: '',
        providerTypeName: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        costs: [],
        serviceZones: []
      };
      const tripCountWeekDays = [];

      if (Ember.isPresent(record)) {
        Object.entries(fields).forEach(key => {
          fields[key[0]] = record.get(key[0]);
        });

        if (record.get('providerTypeName') === 'broker') {
          if (Ember.isPresent(this.get('originalTripCount'))) {
            record.set('tripCount', (0, _lodash.cloneDeep)(this.get('originalTripCount')));
          }

          if (Ember.isPresent(this.get('originalServiceZones'))) {
            record.set('serviceZones', (0, _lodash.cloneDeep)(this.get('originalServiceZones')));
          }

          if (Ember.isPresent(this.get('originalCosts'))) {
            record.set('costs', (0, _lodash.cloneDeep)(this.get('originalCosts')));
          }

          const tripCountWeekDaysValues = this.tripCountWeekDaysValues(tripCountWeekDays);
          const tripCount = record.get('tripCount') || tripCountWeekDaysValues;
          const serviceZones = record.get('serviceZones') || [];
          const zipCodes = serviceZones.length ? serviceZones.firstObject.zipCodes : [];
          const costs = record.get('costs') || [];
          this.set('zipCodesSelected', zipCodes);
          this.set('tripCountWeekDays', tripCount);
          this.set('zones', serviceZones);
          this.set('zoneCost', costs);

          if (Ember.isPresent(serviceZones)) {
            this.set('selectedZone', serviceZones.firstObject);
            this.set('modifiedZoneName', serviceZones.firstObject.name);
          }

          if (Ember.isPresent(costs)) {
            const firstServiceZone = serviceZones.firstObject;

            if (Ember.isPresent(firstServiceZone)) {
              const isZonesPresent = this.get('zones').some(zone => {
                return Ember.isPresent(costs.find(cost => cost.group.includes(zone.name)));
              });

              if (isZonesPresent) {
                const firstGroupCost = costs.firstObject;
                const zones = Ember.isPresent(firstGroupCost) ? firstGroupCost.group.split('-') : null;

                if (zones) {
                  const zoneOne = serviceZones.filter(zone => zone.name === parseInt(zones.firstObject, 10));
                  const zoneTwo = serviceZones.filter(zone => zone.name === parseInt(zones.lastObject, 10));

                  if (zoneOne.firstObject || zoneTwo.firstObject) {
                    this.set('selectedCostZoneOne', zoneOne.firstObject);
                    this.set('selectedCostZoneTwo', zoneTwo.firstObject);
                    this.set('cost', firstGroupCost.cost);
                  }
                }
              }
            }
          }
        }

        this.set('disableForm', true);
        this.set('providerChanges', false);
        this.hasChanges(false);
      } else {
        const tripCountWeekDaysValues = this.tripCountWeekDaysValues(tripCountWeekDays);
        this.set('tripCountWeekDays', tripCountWeekDaysValues);
        this.set('zones', null);
        this.set('zoneCost', null);
        this.set('selectedCostZoneOne', '');
        this.set('selectedCostZoneTwo', '');
        this.set('cost', '');
        this.set('providerChanges', false);
        this.set('configType', null);
      }

      this.setProperties({
        'provider': Ember.Object.create(fields),
        'inValidEmail': false,
        'inValidId': false,
        'mandatoryError': false,
        'duplicateID': false,
        'duplicateName': false,
        'disableTripCount': true,
        'newZoneName': '',
        'addZoneName': false,
        'addCostName': true,
        'disableZoneAdd': true,
        'disableCostForm': true,
        'disableZoneRemoveAction': true,
        'disableCostRemoveAction': true,
        'disableBrokerForm': true,
        'disableZoneSaveAction': true,
        'duplicateIDError': false,
        'duplicateNameError': false
      });
      this.set('systemConfig.hasChanges', false);
    },

    setupZipCodes() {
      const store = this.get('store');
      const configZipCodeList = store.peekAll('zip-code');
      const zipcodes = configZipCodeList.map(zipCode => zipCode.name) || [];
      this.set('zipCodes', zipcodes.sort());
    },

    buttonSettings() {
      let newbtn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let editbtn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      let deletebtn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      const disableBtns = {
        new: newbtn,
        edit: editbtn,
        delete: deletebtn
      };
      this.set('disableBtns', disableBtns);
    },

    validateEmail() {
      const email = this.get('provider.contactEmail');
      const error = 'Invalid Email';
      if (Ember.isEmpty(email)) return false;

      const isValid = _regexFunctions.REGEX_EMAIL.test(email);

      if (isValid) {
        this.set('inValidEmail', false);
        return false;
      }

      this.set('inValidEmail', true);
      return error;
    },

    validateId() {
      const id = this.get('provider.id');
      const error = 'Provider ID must be letters only.';
      if (Ember.isEmpty(id)) return false;

      const isValid = _regexFunctions.REGEX_ONLY_TEXT_FORMAT.test(id);

      if (isValid) {
        this.set('inValidId', false);
        return false;
      }

      this.set('inValidId', true);
      return error;
    },

    duplicateCheck() {
      const store = this.get('store');
      const providers = store.peekAll('provider');
      const id = this.get('provider.id').toLowerCase();
      const name = this.get('provider.name').toLowerCase();
      const isNewProvider = this.get('isNewProvider');
      let duplicateIds = [];
      let duplicateNames = [];

      if (isNewProvider) {
        duplicateIds = providers.filter(record => Ember.isPresent(record.get('id')) && record.get('id').toLowerCase() === id);
        duplicateNames = providers.filter(record => {
          const providerName = record.get('name') || '';
          return providerName.toLowerCase() === name;
        });
      } else {
        duplicateNames = providers.filter(record => {
          const providerName = record.get('name') || '';
          return record.get('id').toLowerCase() !== id && providerName.toLowerCase() === name;
        });
      }

      this.set('duplicateID', duplicateIds.length);
      this.set('duplicateName', duplicateNames.length);

      if (Ember.isEmpty(duplicateIds) && Ember.isEmpty(duplicateNames)) {
        return false;
      }

      const tip = [];

      if (!Ember.isEmpty(duplicateNames) && !Ember.isEmpty(name)) {
        tip.push('Provider Name cannot duplicate an existing provider Name');
      }

      if (!Ember.isEmpty(duplicateIds)) {
        tip.push('Provider ID cannot duplicate an existing provider ID');
      }

      return tip;
    },

    getZoneName() {
      const addZoneName = this.get('addZoneName');
      let newZoneName = '';

      if (addZoneName) {
        newZoneName = this.get('newZoneName');
      } else {
        newZoneName = this.get('selectedZone.name') !== this.get('modifiedZoneName') ? this.get('modifiedZoneName') : '';
      }

      return newZoneName;
    },

    validateServiceZone() {
      const newZoneName = this.getZoneName();
      let zones = this.get('zones') || [];

      if (this.get('addZoneName')) {
        zones = zones.slice(0, -1);
      }

      const availableZone = zones.filter(zone => zone.name.toString() === newZoneName.toString()) || [];
      if (Ember.isEmpty(availableZone)) return true;
      const existingZipCodes = availableZone.firstObject.zipCodes || [];

      if (!existingZipCodes.length) {
        return true;
      }

      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Providers-Broker Zone',
        hasoverlay: true,
        tip: `${newZoneName} already exist`,
        primaryActionText: 'OK',
        primaryAction: () => {
          this.set('inValidZone', true);
          tooltip.reset();
        }
      });
      this.set('zones', zones);
      return false;
    },

    mandatoryValidation() {
      const displayName = this.get('provider.displayName');
      const contactPhone = this.get('provider.contactPhone');
      const errors = [];
      const providerTypeName = this.get('provider.providerTypeName');
      const providerStatus = this.get('provider.status');
      const contactName = this.get('provider.contactName');
      const providerId = this.get('provider.id');
      this.set('inValidZoneError', false);
      if (Ember.isEmpty(displayName.trim())) errors.pushObject({
        validation: 'Provider Name is required'
      });
      if (Ember.isEmpty(providerTypeName)) errors.pushObject({
        validation: 'Provider Type is required'
      });
      if (Ember.isEmpty(providerStatus)) errors.pushObject({
        validation: 'Provider Status is required'
      });
      if (Ember.isEmpty(contactPhone)) errors.pushObject({
        validation: 'Contact Phone Number is required'
      });
      if (Ember.isEmpty(contactName)) errors.pushObject({
        validation: 'Contact Name is required'
      });
      if (Ember.isEmpty(providerId)) errors.pushObject({
        validation: 'Provider ID is required'
      });
      const isInValidProviderId = this.validateId();
      if (isInValidProviderId) errors.pushObject({
        validation: isInValidProviderId
      });

      if (displayName || this.get('provider.id')) {
        const isDuplicate = this.duplicateCheck();

        if (isDuplicate) {
          isDuplicate.forEach(tip => {
            errors.pushObject({
              validation: tip
            });
          });
        }
      }

      const isInValidEmail = this.validateEmail();
      if (isInValidEmail) errors.pushObject({
        validation: isInValidEmail
      });
      const isValidProvider = this.validateProviderType();

      if (isValidProvider) {
        if (providerTypeName === 'broker') {
          const zones = this.setupPayloadForZone() || [];

          if (Ember.isEmpty(zones)) {
            errors.pushObject({
              validation: 'User must enter at least one zone in order to save.'
            });
            this.set('inValidZoneError', true);
          }
        }
      } else {
        return false;
      }

      if (!errors.length) return true;
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'System Configuration',
        hasoverlay: true,
        errors,
        primaryActionText: 'OK',
        primaryAction: () => {
          this.set('mandatoryError', true);
          this.set('duplicateIDError', this.get('duplicateID') || this.get('inValidId'));
          this.set('duplicateNameError', this.get('duplicateName'));
          this.set('inValidZone', this.get('inValidZoneError'));
          tooltip.reset();
        }
      });
      return false;
    },

    validateRecordTask: (0, _emberConcurrency.task)(function* () {
      return yield this.validateProvider();
    }).drop(),

    validateZone() {
      const newZoneName = this.get('newZoneName');
      const addZoneName = this.get('addZoneName');
      const zones = this.get('zones') || [];
      const modifiedZoneName = this.get('modifiedZoneName');
      const errors = [];
      let isValid = Ember.isEmpty(zones.filter(zone => Ember.isEmpty(zone.zipCodes)));

      if (Ember.isEmpty(newZoneName) && this.get('addZoneName')) {
        errors.pushObject({
          validation: 'Zone cannot be empty'
        });
        isValid = !Ember.isEmpty(newZoneName);
      }

      if (Ember.isEmpty(modifiedZoneName) && !this.get('addZoneName')) {
        errors.pushObject({
          validation: 'Zone cannot be empty'
        });
        isValid = !Ember.isEmpty(modifiedZoneName);
      }

      if (!Ember.isEmpty(newZoneName) || !Ember.isEmpty(modifiedZoneName)) {
        const zone = zones.find(z => Ember.isEmpty(z.zipCodes));
        const selectedZipcodes = this.get('zipCodesSelected') || [];
        let zoneName = zone ? zone.name : '';

        if (addZoneName && Ember.isEmpty(selectedZipcodes)) {
          zoneName = newZoneName ? newZoneName : modifiedZoneName;
        }

        if (Ember.isPresent(zoneName)) {
          isValid = false;
          errors.pushObject({
            validation: `Zone ${zoneName} zipcode cannot be empty`
          });
        }
      }

      if (isValid) {
        const serviceZones = this.setupPayloadForZone();

        if (Ember.isEmpty(serviceZones)) {
          errors.pushObject({
            validation: 'User must enter at least one zone in order to save.'
          });
          this.set('inValidZoneError', true);
        }
      }

      if (!errors.length) return true;
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'System Configuration',
        hasoverlay: true,
        errors,
        primaryActionText: 'OK',
        primaryAction: () => {
          this.set('mandatoryError', true);
          this.set('duplicateIDError', this.get('duplicateID') || this.get('inValidId'));
          this.set('duplicateNameError', this.get('duplicateName'));
          this.set('inValidZone', this.get('inValidZoneError'));
          tooltip.reset();
        }
      });
      return false;
    },

    fetchZoneCost() {
      const zoneCost = this.get('zoneCost');
      const zone1 = this.get('selectedCostZoneOne') || '';
      const zone2 = this.get('selectedCostZoneTwo') || '';
      this.set('cost', null);
      this.set('isNewZoneCost', true);
      if (Ember.isEmpty(zone1) || Ember.isEmpty(zone2)) return;
      const zoneCombination1 = `${zone1.name}-${zone2.name}`;
      const zoneCombination2 = `${zone2.name}-${zone1.name}`;
      const selectedCost = zoneCost.filter(item => item.group === zoneCombination1 || item.group === zoneCombination2);

      if (!Ember.isEmpty(selectedCost)) {
        const value = selectedCost.firstObject.cost;
        this.set('cost', value);
        this.set('isNewZoneCost', false);
      }
    },

    setupProviderList() {
      const providerTypes = this.get('store').peekAll('provider-type');
      const list = [];
      providerTypes.forEach(item => {
        // AIQ-2087 - ADEPT IQ currently does not support the scheduing of 'broker' trips
        // This should be re-visited in the future when 'broker' trips are supported
        if (item.id !== BROKER_PROVIDER_TYPE) {
          list.push(item.id);
        }
      });
      this.set('providersList', list);
    },

    setupPayloadForZone() {
      const zones = this.get('zones') || [];
      const selectedZone = this.get('selectedZone.name') || '';
      const name = this.get('newZoneName');
      const modifiedZoneName = this.get('modifiedZoneName');
      const zipCodes = this.get('zipCodesSelected') || [];
      const newZone = {
        name,
        zipCodes
      };

      if (!Ember.isEmpty(selectedZone) && this.validateServiceZone()) {
        const newZones = [];
        zones.forEach(zone => {
          if (zone.name.toString() === selectedZone.toString()) {
            newZones.push({
              name: modifiedZoneName,
              zipCodes
            });
          } else {
            newZones.push(zone);
          }
        });
        return newZones;
      } else if (!Ember.isEmpty(name) && !this.isExistingZone(zones, newZone) && !Ember.isEmpty(zipCodes)) {
        zones.push(newZone);
      }

      return zones;
    },

    isExistingZone(zones, newZone) {
      return zones.some(zone => JSON.stringify(zone) === JSON.stringify(newZone));
    },

    setupPayloadForCost() {
      const zoneCost = this.get('zoneCost');
      const zone1 = this.get('selectedCostZoneOne') || '';
      const zone2 = this.get('selectedCostZoneTwo') || '';
      const cost = this.get('cost');
      if (Ember.isEmpty(zone1) || Ember.isEmpty(zone2) || Ember.isEmpty(cost)) return zoneCost;
      const group = `${zone1.name}-${zone2.name}`;
      const group1 = `${zone2.name}-${zone1.name}`;
      let costUpdated = false;
      zoneCost.forEach(item => {
        if (item.group === group || item.group === group1) {
          item.cost = parseFloat(cost);
          costUpdated = true;
          return;
        }
      });

      if (!costUpdated) {
        zoneCost.push({
          group,
          cost
        });
      }

      return zoneCost;
    },

    save: (0, _emberConcurrency.task)(function* () {
      const record = this.buildProviderData();
      const notifications = this.get('notifications');
      let isSaveSuccessful = true;

      try {
        this.set('providerChanges', false);
        return yield record.save();
      } catch (error) {
        isSaveSuccessful = false;
        this.set('providerChanges', true); // eslint-disable-next-line no-console

        console.error(error);
        notifications.warning('Record failed to save');
      } finally {
        if (isSaveSuccessful) {
          yield this.updateSsoProviderData();
          const tableRef = this.get('tableRef');
          const selectedRow = this.get('selectedRow');

          if (tableRef) {
            Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData'); // refresh data

            if (!selectedRow) {
              tableRef.set('config.selectFirstRow', true);
              tableRef.get('firstRowSelectedTask').perform();
            }

            this.buttonSettings();
            this.setProperties({
              'disableForm': true,
              'disableCostForm': true,
              'disableBrokerForm': true,
              'addZoneName': true,
              'addCostName': true,
              'disableZoneAdd': true,
              'disableZoneRemoveAction': true,
              'disableCostRemoveAction': true,
              'disableZoneSaveAction': true,
              'inValidZone': false
            });
          }

          this.resetPreviousRecordProperities(record.get('value'));
        } else {
          throw new Error('Record failed to save'); // eslint-disable-line
        }
      }
    }).drop(),

    buildProviderData() {
      let record = null;
      const store = this.get('store');
      const value = this.get('provider');
      const displayName = value.get('displayName');
      const name = value.get('id');
      const providerType = this.get('provider.providerTypeName');
      const isNewProvider = this.get('isNewProvider');
      value.set('name', name);

      if (providerType === BROKER_PROVIDER_TYPE) {
        value.tripCount = this.get('tripCountWeekDays');
        value.costs = this.setupPayloadForCost();
        value.serviceZones = this.setupPayloadForZone();
      } else {
        //provider type is dedicated carrier set empty values to this fields
        value.tripCount = [];
        value.costs = [];
        value.serviceZones = [];
      }

      if (isNewProvider) {
        const data = {
          category: 'config-System_Configuration-providers',
          type: 'object',
          displayName,
          name,
          value
        };
        record = store.createRecord('cs-config-item', data);
      } else {
        const id = `config-System_Configuration-providers/${name}`;
        record = store.peekRecord('cs-config-item', id);
        record.set('value', value);
        record.set('displayName', displayName);
      }

      return record;
    },

    async updateSsoProviderData() {
      const value = this.get('provider');
      const providerId = value.get('id');
      const adaptor = 'perm-sso-user';
      const token = this.get('session.data.authenticated.token');
      const permUserAdapter = this.get('store').adapterFor(adaptor);
      const payload = {
        'providerName': providerId
      };
      let providerExists = false; //check if the current provider name is already exists in SSO service

      const providersList = await permUserAdapter.getSSOProvider(); //if there is a new record this part will run
      //finding the id of new provider in sso db

      providerExists = providersList.find(item => item.provider === providerId); //if the new provider name exists in sso db it return true

      if (providerExists) return true; //else it would request to insert it

      return this.get('ajax').post(_apiUrls.API.ssoService.host + '/provider', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: payload
      }).catch(() => {
        return false;
      });
    },

    delete: (0, _emberConcurrency.task)(function* () {
      const record = this.get('selectedRow');
      const notifications = this.get('notifications');
      const tableRef = this.get('tableRef');
      const store = this.get('store');
      const value = this.get('provider');
      const name = value.get('id');
      const checkedRow = tableRef.get('checkedRows.firstObject');
      const checkedRowRecord = store.peekRecord(checkedRow.constructor.modelName, checkedRow.get('id'));
      const id = `config-System_Configuration-providers/${name}`;
      const configRecord = store.peekRecord('cs-config-item', id);

      try {
        if (checkedRow.isDeleted) {
          yield configRecord.destroyRecord();
          yield checkedRowRecord.unloadRecord();
        } else {
          yield record.destroyRecord();
          yield configRecord.unloadRecord();
        }

        yield this.deleteSsoProviderData(); //removes the provider models record from ember store

        store._removeFromIdMap(record._internalModel);

        store._removeFromIdMap(configRecord._internalModel); // NYAAR-18959 delete table cache by ID when delete the record


        this.removeIdFromTableCache(name);
      } catch (error) {
        console.error(error); // eslint-disable-line

        notifications.warning('Record failed to delete.');
      } finally {
        if (tableRef) {
          Ember.run.scheduleOnce('afterRender', tableRef, 'onUncheckAll');
          Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData'); // refresh data

          notifications.success('Record successfully deleted.');
          tableRef.set('config.selectFirstRow', true);
          tableRef.get('firstRowSelectedTask').perform();
        }
      }
    }).drop(),

    async deleteSsoProviderData() {
      const value = this.get('provider');
      const providerId = value.get('id');
      const adaptor = 'perm-sso-user';
      const token = this.get('session.data.authenticated.token');
      const permUserAdapter = this.get('store').adapterFor(adaptor); //check if the current provider name is already exists in SSO service

      const providersList = await permUserAdapter.getSSOProvider();
      const providerExists = providersList.find(item => item.provider === providerId);
      if (!providerExists) return true;
      return this.get('ajax').delete(_apiUrls.API.ssoService.host + '/provider/' + providerExists.id, {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).catch(() => {
        return true;
      });
    },

    deleteServiceZone: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const selectedRow = this.get('selectedRow');
      const selectedZone = this.get('selectedZone');
      const value = this.get('provider');

      if (selectedRow && selectedRow.get('id')) {
        const id = `config-System_Configuration-providers/${selectedRow.get('id')}`;
        const record = store.peekRecord('cs-config-item', id);
        const serviceZones = record.get('value.serviceZones');
        value.tripCount = selectedRow.get('tripCount');
        value.serviceZones = serviceZones.filter(zone => zone.name !== selectedZone.name);
        const zoneCost = selectedRow.get('costs');
        value.costs = zoneCost.filter(item => !item.group.includes(selectedZone.name));
        record.set('value', value);
        return yield record.save();
      }

      this.set('zones', value.serviceZones.filter(zone => zone.name !== selectedZone.name));
      return this.buildProviderData();
    }).drop(),
    deleteServiceCostZone: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const selectedRow = this.get('selectedRow');
      const value = this.get('provider');
      const zone1 = this.get('selectedCostZoneOne') || '';
      const zone2 = this.get('selectedCostZoneTwo') || '';
      let zoneCost = this.get('zoneCost');
      const zoneCombination1 = `${zone1.name}-${zone2.name}`;
      const zoneCombination2 = `${zone2.name}-${zone1.name}`;

      if (selectedRow && selectedRow.get('id')) {
        const id = `config-System_Configuration-providers/${selectedRow.get('id')}`;
        const record = store.peekRecord('cs-config-item', id);
        zoneCost = selectedRow.get('costs');
        value.costs = zoneCost.filter(item => item.group !== zoneCombination1 && item.group !== zoneCombination2);
        record.set('value', value);
        return yield record.save();
      }

      this.set('zoneCost', zoneCost.filter(item => item.group !== zoneCombination1 && item.group !== zoneCombination2));
      this.set('selectedCostZoneOne', '');
      this.set('selectedCostZoneTwo', '');
      this.set('cost', '');
      return this.buildProviderData();
    }).drop(),

    async refreshProviderDOM(provider) {
      await provider.reload();
      const serviceZones = provider.get('value.serviceZones') || [];
      const costs = provider.get('value.costs') || [];
      Ember.run.next(() => {
        // power select requires all selected items to remove to update collection options
        this.set('zones', serviceZones); // update zone options

        this.set('zoneCost', costs);
        const firstServiceZone = serviceZones.firstObject;

        if (provider.get('isBrokerProviderType')) {
          this.set('selectedZone', firstServiceZone);
          this.set('modifiedZoneName', firstServiceZone.name);
          this.set('newZoneName', firstServiceZone.name);
          this.set('zipCodesSelected', firstServiceZone.zipCodes);
        }

        this.set('configType', null);
      });
    },

    async refreshZoneBrokerData(provider) {
      let serviceZones = provider.get('serviceZones') || provider.get('value.serviceZones');
      let zoneCost = this.get('zoneCost') || [];
      zoneCost = zoneCost.filter(cost => !cost.group.includes(this.get('selectedZone.name')));
      serviceZones = serviceZones.map(_ref => {
        let {
          name,
          zipCodes
        } = _ref;
        return Ember.Object.create({
          name,
          zipCodes
        });
      });
      Ember.run.next(() => {
        // power select requires all selected items to remove to update collection options
        this.set('zones', serviceZones); // update zone options

        if (Ember.isEmpty(this.get('zones'))) {
          this.set('selectedZone', null);
          this.set('modifiedZoneName', '');
          this.set('zipCodesSelected', []);
        } else {
          const firstServiceZone = serviceZones.firstObject;
          this.set('selectedZone', firstServiceZone);
          this.set('modifiedZoneName', firstServiceZone.name);
          this.set('zipCodesSelected', firstServiceZone.zipCodes);
        }

        if (Ember.isEmpty(this.get('zones')) || Ember.isEmpty(zoneCost)) {
          this.set('selectedCostZoneOne', '');
          this.set('selectedCostZoneTwo', '');
          this.set('cost', null);
          this.set('zoneCost', zoneCost);
        } else {
          const firstServiceZone = serviceZones.firstObject;

          if (Ember.isPresent(firstServiceZone)) {
            const isZonesPresent = this.get('zones').some(zone => {
              return Ember.isPresent(zoneCost.find(cost => cost.group.includes(zone.name)));
            });

            if (isZonesPresent) {
              const firstGroupCost = zoneCost.firstObject;
              const zones = Ember.isPresent(firstGroupCost) ? firstGroupCost.group.split('-') : null;

              if (zones) {
                const zoneOne = serviceZones.filter(zone => zone.name === parseInt(zones.firstObject, 10));
                const zoneTwo = serviceZones.filter(zone => zone.name === parseInt(zones.lastObject, 10));

                if (zoneOne.firstObject || zoneTwo.firstObject) {
                  this.set('selectedCostZoneOne', zoneOne.firstObject);
                  this.set('selectedCostZoneTwo', zoneTwo.firstObject);
                  this.set('cost', firstGroupCost.cost);
                  this.set('zoneCost', zoneCost);
                }
              }
            }
          }
        }
      });
    },

    refreshCostBrokerData(provider) {
      let serviceZones = provider.get('value.serviceZones') || [];
      const costs = provider.get('value.costs') || [];
      serviceZones = serviceZones.map(_ref2 => {
        let {
          name,
          zipCodes
        } = _ref2;
        return Ember.Object.create({
          name,
          zipCodes
        });
      });
      Ember.run.next(() => {
        // power select requires all selected items to remove to update collection options
        this.set('zones', serviceZones); // update zone options

        if (Ember.isEmpty(this.get('zones'))) {
          this.set('selectedZone', null);
          this.set('modifiedZoneName', '');
          this.set('zipCodesSelected', []);
        } else {
          const firstServiceZone = serviceZones.firstObject;
          this.set('selectedZone', firstServiceZone);
          this.set('modifiedZoneName', firstServiceZone.name);
          this.set('zipCodesSelected', firstServiceZone.zipCodes);
        }

        this.set('selectedCostZoneOne', '');
        this.set('selectedCostZoneTwo', '');
        this.set('cost', null);
        this.set('zoneCost', costs);
      });
    },

    /**
     * First, validate the mandatory fields. If the provider type is broker
     * then it validates zone cost and zone
     * @returns {boolean}
     */
    async validateProvider() {
      let valid = this.mandatoryValidation();
      const providerType = this.get('provider.providerTypeName');

      if (valid && providerType === 'broker') {
        valid = this.validateCostZone() && this.validateZone() && this.validateServiceZone();
      }

      return valid;
    },

    validateProviderType() {
      const provider = this.get('provider');
      const store = this.get('store');
      const isNewProvider = this.get('isNewProvider');
      const providerTypeName = this.get('provider.providerTypeName');
      const routes = store.peekAll('route');
      const scheduledRoutes = routes.filter(route => route.get('provider.id') === provider.get('id') && route.get('isScheduled'));
      if (isNewProvider) return true;

      if (this.get('selectedRow.providerTypeName') !== providerTypeName && !Ember.isEmpty(scheduledRoutes)) {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'System Configuration',
          hasoverlay: true,
          tip: 'Unable to save because a schedule exists that contains routes assigned to the provider',
          primaryActionText: 'OK',
          primaryAction: () => {
            tooltip.reset();
          }
        });
        return false;
      }

      return true;
    },

    saveProvider() {
      const notifications = this.get('notifications');
      this.set('providerChanges', false);
      return this.get('save').perform().then(provider => {
        notifications.success('Record successfully saved');
        return this.refreshProviderDOM(provider);
      }).catch(() => {
        notifications.warning('Record failed to save');
      });
    },

    onSaveProviders() {
      const notifications = this.get('notifications');
      this.validateProvider().then(isValid => {
        if (isValid) {
          const tooltip = this.get('tooltip');
          tooltip.pushConfirmation({
            title: 'System Configuration',
            hasOverlay: true,
            tip: 'Are you sure you want to save these changes?',
            primaryActionText: 'Confirm',
            primaryAction: () => {
              this.set('providerChanges', false);
              this.set('isZipCodeAvailabel', true);
              tooltip.reset();
              return this.get('save').perform().then(async provider => {
                notifications.success('Record successfully saved');
                await this.get('workspaceContext').fetchProviderList();
                return this.refreshProviderDOM(provider);
              });
            },
            secondaryActionText: 'Cancel',

            secondaryAction() {
              return tooltip.reset();
            }

          });
        }
      });
    },

    validateCostZone() {
      const zone1 = this.get('selectedCostZoneOne');
      const zone2 = this.get('selectedCostZoneTwo');
      const cost = this.get('cost');
      const errors = [];
      if (Ember.isEmpty(zone1) && Ember.isEmpty(zone2) && Ember.isEmpty(cost)) errors.pushObject({
        'validation': 'User must enter at least one cost in order to save'
      });else {
        if (Ember.isEmpty(zone1) || Ember.isEmpty(zone2)) errors.pushObject({
          'validation': 'Zone names must be selected'
        });
        if (Ember.isEmpty(cost)) errors.pushObject({
          'validation': 'Cost is required'
        });
      }
      const tooltip = this.get('tooltip');

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'System Configuration',
          hasoverlay: true,
          errors,
          tip: '',
          primaryActionText: 'OK',
          primaryAction: () => {
            tooltip.reset();
          }
        });
        return false;
      }

      return true;
    },

    async providerHasScheduedRoutes() {
      const currentProviderName = this.get('provider.id');
      const scheduledRoutes = await this.checkAvailableProvider(currentProviderName);
      const tipMessage = this.get('isDeleteProvider') ? 'Unable to delete. The provider is associated with other records, such as vehicles, trips. You may want to try setting the provider to \'Inactive\' instead.' : 'Unable to set provider to Inactive. The provider is currently associated with future records, such as routes, trips. You must first remove those associations and then try again.';

      if (scheduledRoutes) {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'System Configuration',
          hasoverlay: true,
          tip: tipMessage,
          primaryActionText: 'OK',
          primaryAction: () => {
            tooltip.reset();
          }
        });
        return false;
      }

      return true;
    },

    async checkAvailableProvider(currentProviderName) {
      let isProviderPresent = false;

      for (const provider of PROVIDER_ASSOCIATIONS) {
        const modelProvider = await this.fetchProvidersTask.perform(provider.model, provider.providerName, currentProviderName);

        if (Ember.isPresent(modelProvider)) {
          isProviderPresent = true;
          break;
        }
      }

      return isProviderPresent;
    },

    fetchProvidersTask: (0, _emberConcurrency.task)(function* (model, modelProviderName, currentProvider) {
      const store = this.get('store');
      const fetchProvider = yield store.query(model, {
        filter: `eq(${modelProviderName},${currentProvider})`,
        'page[limit]': 1
      });
      return fetchProvider;
    }),

    undoProvider() {
      const selectedRow = this.get('selectedRow');
      const isNewProvider = this.get('isNewProvider');

      if (isNewProvider) {
        this.set('provider', null);
      }

      this.setDefaultProperties(selectedRow);
      this.setProperties({
        disableForm: false,
        disableCostForm: false,
        disableBrokerForm: false,
        disableZoneRemoveAction: false,
        disableZoneAdd: false,
        disableZoneSaveAction: false,
        disableCostRemoveAction: false,
        addCostName: false
      });
    },

    resetPreviousRecordProperities(_ref3) {
      let {
        tripCount,
        serviceZones,
        costs
      } = _ref3;
      this.setProperties({
        originalTripCount: tripCount,
        originalServiceZones: serviceZones,
        originalCosts: costs
      });
    },

    checkMaxBrokerProviderRecords() {
      const providerRecord = this.get('tableRef.records');
      const brokerProviderRecords = providerRecord.filter(provider => provider.isBrokerProviderType);
      const currentProvider = this.get('provider');
      const isNewProvider = this.get('isNewProvider');
      return brokerProviderRecords.length === MAX_BROKER_PROVIDER && currentProvider.providerTypeName === BROKER_PROVIDER_TYPE && (isNewProvider || !isNewProvider && providerRecord.length > MAX_BROKER_PROVIDER);
    },

    // NYAAR-19080 One zone should be deleted from the Broker Provider Record.
    deleteSelectedBrokerServiceZone(provider) {
      const store = this.get('store');
      const selectedRow = this.get('selectedRow');
      const selectedZone = this.get('selectedZone');
      const value = provider;

      if (selectedRow && selectedRow.get('id')) {
        const id = `config-System_Configuration-providers/${selectedRow.get('id')}`;
        const record = store.peekRecord('cs-config-item', id);
        const serviceZones = record.get('value.serviceZones');
        value.tripCount = selectedRow.get('tripCount');
        value.serviceZones = serviceZones.filter(zone => zone.name !== selectedZone.name);
        const zoneCost = selectedRow.get('costs');
        value.costs = zoneCost.filter(item => !item.group.includes(selectedZone.name));
        record.set('value', value);
      }

      this.set('zones', value.serviceZones.filter(zone => zone.name !== selectedZone.name));
      return this.buildProviderData();
    },

    maxBrokerProviderWarning() {
      const tooltip = this.get('tooltip');
      return tooltip.pushConfirmation({
        title: 'System Configuration',
        hasoverlay: true,
        tip: `Unable to create provider. This exceeds the maximum number of allowed broker providers (${MAX_BROKER_PROVIDER}).`,
        primaryActionText: 'OK',
        primaryAction: () => {
          tooltip.reset();
        }
      });
    },

    removeIdFromTableCache(id) {
      const tableRef = this.get('tableRef');
      const tableCache = tableRef.dataJoin.cache;
      delete tableCache[id];
    },

    actions: {
      newProviders() {
        const selectedRow = this.get('selectedRow');

        if (selectedRow) {
          selectedRow.set('selected', false);
          this.set('tableRef.config.selectFirstRow', false);
        }

        this.setDefaultProperties();
        this.setProperties({
          isNewProvider: true,
          disableForm: false,
          providerChanges: false,
          addZoneName: false,
          addCostName: false,
          disableZoneRemoveAction: true,
          disableZoneSaveAction: true,
          disableCostRemoveAction: true,
          disableCostSaveAction: true,
          disableCostForm: true,
          zones: null,
          selectedCostZoneOne: null,
          selectedCostZoneTwo: null,
          configType: null
        });
        this.get('tableRef').onUncheckAll();
        this.send('onAddServiceZone');
        this.send('onAddCostZone');
        this.buttonSettings(true, true, true);
        this.set('systemConfig.hasChanges', true);
        this.set('disableZoneAdd', false);
        this.setupZipCodes();
      },

      editProviders() {
        const selectedRow = this.get('selectedRow');

        if (Ember.isPresent(selectedRow)) {
          this.set('originalTripCount', (0, _lodash.cloneDeep)(selectedRow.get('tripCount')));
        }

        this.setDefaultProperties(selectedRow);
        this.setProperties({
          isNewProvider: false,
          disableForm: false,
          providerChanges: false,
          addZoneName: false,
          addCostName: false,
          disableCostForm: false,
          disableCostRemoveAction: false,
          disableCostSaveAction: true,
          disableZoneAdd: false,
          disableZoneRemoveAction: true,
          disableZoneSaveAction: true,
          configType: null,
          originalServiceZones: null,
          originalCosts: null
        });

        if (Ember.isPresent(selectedRow.get('serviceZones'))) {
          this.set('disableZoneSaveAction', false);
          this.set('disableBrokerForm', false);
          this.set('disableZoneRemoveAction', false);
          this.set('originalServiceZones', (0, _lodash.cloneDeep)(selectedRow.get('serviceZones')));
          this.set('originalCosts', (0, _lodash.cloneDeep)(selectedRow.get('costs')));
        }

        this.buttonSettings(true, true, true);
        this.set('systemConfig.hasChanges', true);
      },

      async deleteProviders() {
        this.set('isDeleteProvider', true);
        const tooltip = this.get('tooltip');
        const isValidProvider = await this.providerHasScheduedRoutes();

        if (isValidProvider) {
          tooltip.pushConfirmation({
            title: 'System Configuration',
            hasoverlay: true,
            tip: 'Are you sure you want to Delete these changes?',
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return this.get('delete').perform().then(async () => {
                await this.get('workspaceContext').fetchProviderList();
                tooltip.reset();
                this.set('isDeleteProvider', false);
              });
            },
            secondaryActionText: 'Cancel',

            secondaryAction() {
              return tooltip.reset();
            }

          });
        }

        this.set('isDeleteProvider', false);
      },

      async saveProviders() {
        if (this.checkMaxBrokerProviderRecords()) {
          return this.maxBrokerProviderWarning();
        }

        this.onSaveProviders();
      },

      undoProvider() {
        this.undoProvider();
      },

      async onStatusChange(val) {
        if (this.get('provider.status').toLowerCase() === ACTIVE_STATUS && !this.get('isNewProvider')) {
          const isValidProvider = await this.providerHasScheduedRoutes();
          if (!isValidProvider) return;
        }

        this.set('provider.status', val);
        this.set('providerChanges', true);
      },

      onProviderTypeChange(val) {
        this.set('provider.providerTypeName', val);
        this.set('providerChanges', true);

        if (val === BROKER_PROVIDER_TYPE) {
          const serviceZones = this.get('zones') || [];
          if (Ember.isEmpty(serviceZones)) this.setProperties({
            zipCodesSelected: null,
            selectedZone: null,
            newZoneName: null,
            selectedCostZoneOne: null,
            selectedCostZoneTwo: null,
            cost: null,
            addZoneName: true,
            disableBrokerForm: true,
            disableCostForm: true,
            disableZoneRemoveAction: true,
            disableZoneSaveAction: true,
            disableCostRemoveAction: true,
            disableCostSaveAction: true,
            disableZoneAdd: false
          });
        }
      },

      onUpdateTripCountDate(day) {
        const tripDays = this.get('tripCountWeekDays');
        this.set('tripCountWeekDays', []);
        tripDays.pop();
        tripDays.push({
          day,
          values: {
            targetCount: 0,
            underage: 0,
            overage: 0
          }
        });
        this.set('tripCountWeekDays', tripDays);
        this.set('disableTripCount', false);
      },

      onAddServiceZone() {
        this.setProperties({
          zipCodesSelected: null,
          modifiedZoneName: null,
          selectedZone: null,
          addZoneName: true,
          disableZoneAdd: true,
          disableZoneRemoveAction: true,
          disableZoneSaveAction: false,
          disableBrokerForm: false,
          inValidZone: false
        });
      },

      onRemoveServiceZone() {
        const tooltip = this.get('tooltip');
        const selectedZone = this.get('selectedZone');
        const notifications = this.get('notifications');
        const addZoneName = this.get('addZoneName');
        const provider = this.get('provider');

        if (!selectedZone && addZoneName) {
          this.set('newZoneName', '');
          this.set('zipCodesSelected', '');
        } else {
          tooltip.pushConfirmation({
            title: 'Providers-Broker Zone',
            hasoverlay: true,
            tip: `Do you want to delete zone ${selectedZone.name}?`,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              this.deleteSelectedBrokerServiceZone(provider);
              this.refreshZoneBrokerData(provider);
              notifications.success(`successfully deleted zone ${selectedZone.name}.`);
              this.set('providerChanges', true);
              this.set('disableZoneSaveAction', true);
              this.set('disableZoneAdd', false);

              if (Ember.isEmpty(provider.get('serviceZones'))) {
                this.set('disableBrokerForm', true);
                this.set('disableZoneRemoveAction', true);
              } else {
                this.set('disableZoneRemoveAction', false);
              }

              tooltip.reset();
            },
            secondaryActionText: 'Cancel',

            secondaryAction() {
              return tooltip.reset();
            }

          });
        }
      },

      onSaveServiceZone() {
        let isValid = this.validateZone();

        if (isValid) {
          const tooltip = this.get('tooltip');
          const zoneName = this.getZoneName();
          const isNewProvider = this.get('isNewProvider');
          const notifications = this.get('notifications');
          let tip = 'Are you sure you want to save these changes?';

          if (!Ember.isEmpty(zoneName) && isValid) {
            isValid = this.validateServiceZone();

            if (!isNewProvider) {
              tip = `Do you want to save ${zoneName}?`;
            }
          }

          if (isValid) {
            tooltip.pushConfirmation({
              title: 'Providers-Broker Zone',
              hasoverlay: true,
              tip,
              primaryActionText: 'Confirm',
              primaryAction: () => {
                this.set('addZoneName', false);
                const provider = this.buildProviderData();

                if (provider) {
                  const zonesExist = provider.get('value').serviceZones;
                  this.set('newZoneName', '');
                  this.set('zipCodesSelected', []);

                  if (zonesExist && zonesExist.length >= 1) {
                    this.setProperties({
                      'disableZoneAdd': false,
                      'disableZoneRemoveAction': false,
                      'disableZoneSaveAction': true,
                      'addCostName': false,
                      'disableCostRemoveAction': true,
                      'disableCostSaveAction': true
                    });
                  } else {
                    this.set('disableZoneAdd', false);
                    this.set('disableZoneRemoveAction', true);
                    this.set('disableZoneSaveAction', true);
                  }

                  this.set('isZipCodeAvailabel', true);
                  this.set('disableBrokerForm', true);
                  this.set('providerChanges', true);
                  this.refreshZoneBrokerData(provider);
                  notifications.success(`${zoneName} saved successfully`);
                }

                tooltip.reset();
              },
              secondaryActionText: 'Cancel',

              secondaryAction() {
                return tooltip.reset();
              }

            });
          }
        }
      },

      removeSelectedZipCode(zipCode) {
        const selectedZipcodes = this.get('zipCodesSelected') || [];
        selectedZipcodes.removeObject(zipCode);
        this.set('zipCodesSelected', selectedZipcodes);
        if (!this.get('zipCodesSelected').length) this.set('isZipCodeAvailabel', false);
        this.set('disableZoneSaveAction', false);
        this.set('providerChanges', true);
      },

      onZoneChange(selectedZone) {
        const selectedZipCodes = selectedZone.zipCodes || [];
        this.set('selectedZone', selectedZone);
        this.set('modifiedZoneName', selectedZone.name);
        this.set('zipCodesSelected', selectedZipCodes);

        if (this.get('selectedZone')) {
          this.set('disableBrokerForm', false);
        }

        this.set('disableZoneSaveAction', false);
      },

      onZipCodeChange(selectedZipCode) {
        if (!selectedZipCode.lastObject) {
          return;
        }

        const serviceZones = this.get('zones') || [];
        const zipCode = parseInt(selectedZipCode.lastObject, 10);
        const assignedZipCodes = serviceZones.filter(zone => zone.zipCodes.includes(zipCode));

        if (!Ember.isEmpty(assignedZipCodes)) {
          const tooltip = this.get('tooltip');
          tooltip.pushConfirmation({
            title: 'Providers-Broker Zone',
            hasoverlay: true,
            tip: `${zipCode} already assigned to ${assignedZipCodes.firstObject.name}`,
            primaryActionText: 'OK',
            primaryAction: () => {
              tooltip.reset();
            }
          });
        } else {
          const zipCodesSelected = this.get('zipCodesSelected') || [];

          if (!zipCodesSelected.includes(zipCode)) {
            this.set('zipCodesSelected', null);
            zipCodesSelected.push(zipCode);
          }

          this.set('zipCodesSelected', zipCodesSelected);
          this.set('disableZoneSaveAction', false);
          this.set('providerChanges', true);
        }
      },

      onZoneOneChange(selectedZone) {
        this.set('selectedCostZoneOne', selectedZone);
        this.fetchZoneCost();
        this.set('providerChanges', true);
        this.set('disableCostSaveAction', false);
      },

      onZoneTwoChange(selectedZone) {
        this.set('selectedCostZoneTwo', selectedZone);
        this.fetchZoneCost();
        this.set('providerChanges', true);
        this.set('disableCostSaveAction', false);
      },

      onZoneCostChange(path, value) {
        this.set(path, value);
        const zone1 = this.get('selectedCostZoneOne');
        const zone2 = this.get('selectedCostZoneTwo');

        if (zone1 && zone2) {
          this.set('providerChanges', true);
          this.set('disableCostSaveAction', false);
        }
      },

      findZipCode(newZoneName) {
        let zoneName = newZoneName.value; // Automatic removal of characters other than numbers

        if (!NUMBER_FORMAT.test(zoneName)) {
          zoneName = zoneName.replace(REMOVE_TEXT_FORMAT, '');
          zoneName = zoneName.replace(SPECIAL_CHARACTER_FORMAT, '');
          this.set('newZoneName', zoneName ? parseInt(zoneName, 10) : '');
          return this.notifyPropertyChange('newZoneName');
        } // Replacing '0' and undefined value


        if (!zoneName || zoneName === '0') {
          this.set('newZoneName', zoneName = '');
          return this.notifyPropertyChange('newZoneName');
        }

        this.set('newZoneName', parseInt(zoneName, 10));
        this.set('inValidZone', false);
        this.set('zipCodesSelected', []);
      },

      removeCharacters(contactPhone) {
        let phone = contactPhone.value;
        this.send('onProviderFormChanges'); // Automatic removal of characters other than numbers

        if (!NUMBER_FORMAT.test(phone)) {
          phone = phone.replace(REMOVE_TEXT_FORMAT, '');
          phone = phone.replace(SPECIAL_CHARACTER_FORMAT, '');
          this.set('provider.contactPhone', phone ? parseInt(phone, 10) : '');
          return this.notifyPropertyChange('provider');
        }

        this.set('provider.contactPhone', phone ? parseInt(phone, 10) : '');
      },

      onRemoveCostZone() {
        const tooltip = this.get('tooltip');
        const zone1 = this.get('selectedCostZoneOne');
        const zone2 = this.get('selectedCostZoneTwo');
        const notifications = this.get('notifications');
        const addCostName = this.get('addCostName');

        if (addCostName) {
          this.set('selectedCostZoneOne', '');
          this.set('selectedCostZoneTwo', '');
          this.set('cost', '');
        } else {
          tooltip.pushConfirmation({
            title: 'Providers-Cost',
            hasoverlay: true,
            tip: `Do you want to delete cost for ${zone1.name} to ${zone2.name}?`,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return this.get('deleteServiceCostZone').perform().then(provider => {
                this.refreshCostBrokerData(provider);
                notifications.success(`Cost for ${zone1.name} to ${zone2.name} deleted successfully`);
                this.set('providerChanges', true);
              }).catch(() => {
                notifications.warning(`Failed to delete cost for ${zone1.name} to ${zone2.name}`);
              }).finally(() => {
                tooltip.reset();
              });
            },
            secondaryActionText: 'Cancel',

            secondaryAction() {
              return tooltip.reset();
            }

          });
        }
      },

      onAddCostZone() {
        this.setProperties({
          selectedCostZoneOne: null,
          selectedCostZoneTwo: null,
          cost: null,
          addCostName: true,
          disableCostRemoveAction: true,
          disableCostSaveAction: false,
          disableCostForm: false
        });
      },

      onSaveCostZone() {
        const isValid = this.validateCostZone();

        if (isValid) {
          const tooltip = this.get('tooltip');
          const zone1 = this.get('selectedCostZoneOne');
          const zone2 = this.get('selectedCostZoneTwo');
          const tip = `Do you want to modify cost for ${zone1.name} to ${zone2.name}?`;
          const notifications = this.get('notifications');
          tooltip.pushConfirmation({
            title: 'Providers-Cost',
            hasoverlay: true,
            tip,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              const provider = this.buildProviderData();

              if (provider) {
                if (zone1 || zone2) {
                  this.set('addCostName', false);
                  this.set('disableCostRemoveAction', false);
                  this.set('disableCostSaveAction', true);
                  this.set('disableCostForm', false);
                } else {
                  this.set('addCostName', false);
                  this.set('disableCostRemoveAction', true);
                  this.set('disableCostSaveAction', true);
                  this.set('disableCostForm', true);
                }

                notifications.success(`Cost for ${zone1.name} to ${zone2.name} saved successfully`);
                tooltip.reset();
              }
            },
            secondaryActionText: 'Cancel',

            secondaryAction() {
              return tooltip.reset();
            }

          });
        }
      },

      onTripCountChange(record, valuePath, val) {
        const value = parseInt(val, 10) || 0;
        Ember.set(record, valuePath, value);
        this.set('providerChanges', true);
      },

      onProviderFormChanges() {
        this.set('providerChanges', true);
      },

      onInputChange(value) {
        let zoneName = value; // Automatic removal of characters other than numbers

        if (!NUMBER_FORMAT.test(zoneName)) {
          zoneName = zoneName.replace(REMOVE_TEXT_FORMAT, '');
          zoneName = zoneName.replace(SPECIAL_CHARACTER_FORMAT, '');
          this.set('modifiedZoneName', zoneName ? parseInt(zoneName, 10) : '');
          return;
        } // Replacing '0' and undefined value


        if (!zoneName || zoneName === '0') {
          this.set('modifiedZoneName', zoneName = '');
          return;
        }

        this.set('modifiedZoneName', parseInt(zoneName, 10));
      }

    }
  });

  _exports.default = _default;
});