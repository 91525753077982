define("adept-iq/helpers/has-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasRole = hasRole;

  function hasRole(roles, roleName) {
    if (!roleName || !roles) {
      return false;
    }

    for (let i = 0; i < roles.length; i++) {
      if (roles[i].toLowerCase() === roleName.toLowerCase()) {
        return true;
      }
    }

    return false;
  }

  var _default = Ember.Helper.extend({
    user: Ember.inject.service(),
    userChanged: Ember.observer('user.roles', function () {
      this.recompute();
    }),

    compute(roleNames) {
      const roles = Ember.get(this, 'user.roles');
      return roleNames.any(roleName => hasRole(roles, roleName));
    }

  });

  _exports.default = _default;
});