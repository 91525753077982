define("adept-iq/pods/components/iq-widgets/subscription-form/subscription-changes-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/iq-widgets/subscription-form/subscription-changes-table/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['Subscription-changes-table'],
    config: _config.default,
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    async didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    fetchDataQuery() {
      const params = {
        sorts: []
      };
      const sort = this.buildSortParam();

      if (sort) {
        params.sorts.push(sort);
      }

      return this._super(...arguments).then(records => {
        return records;
      });
    },

    async query() {
      return this.get('subscriptionBookings');
    },

    actions: {
      onPlusIconClick() {
        this.get('addRiderActivityLog')();
      },

      onColumnClick(column) {
        this.set('state', {
          sortAsc: column.ascending,
          sortId: column.get('id')
        });
      },

      onSubscriptionChangesModalClose() {
        this.onSubscriptionChangesModalClose();
      },

      onSubscriptionChangesAccept() {
        this.onSubscriptionChangesAccept();
      }

    }
  });

  _exports.default = _default;
});