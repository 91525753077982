define("adept-iq/models/trim-slack-contract-management", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  const {
    attr
  } = _emberData.default;

  var _default = Model.extend({
    startRouteName: attr('string'),
    endRouteName: attr('string'),
    trimSlack: attr('boolean'),
    adjustMins: attr('string'),
    minRouteLength: attr('string'),
    pullOutMinCap: attr('string'),
    pullOutMaxCap: attr('string'),
    pullInMinCap: attr('string'),
    pullInMaxCap: attr('string'),
    providerName: attr('string'),
    convertToMinutes: Ember.computed('minRouteLength', function () {
      const minRouteLength = this.get('minRouteLength');
      const fieldArray = minRouteLength.split(':');
      return parseInt(fieldArray[0], 10) + 60 * parseInt(fieldArray[1], 10);
    }),
    convertToHourMinutes: Ember.computed('minRouteLength', function () {
      const minRouteLength = this.get('minRouteLength');
      const minsNum = parseFloat(minRouteLength, 10);
      let hours = Math.floor(minsNum / 60);
      let minutes = Math.floor(minsNum - hours * 3600 / 60);

      if (hours < 10) {
        hours = '0' + hours;
      }

      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      return hours + ':' + minutes;
    })
  });

  _exports.default = _default;
});