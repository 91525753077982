define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map-filter/layer-options", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const LayerOptions = () => {
    // TODO: handel multi layers
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("label", null, "Layer \xA0 "), _react.default.createElement("select", {
      className: "filter_dropdown"
    }, _react.default.createElement("option", {
      value: "event"
    }, "Vehicle Event")));
  };

  var _default = LayerOptions;
  _exports.default = _default;
});