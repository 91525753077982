define("adept-iq/classes/rules/eligibility-rules/itp-enabled-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type"], function (_exports, _baseRule, _ruleResultType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ItpEnabledRule = void 0;
  const ITP_CONFIG_ID = 'config-System_Configuration-ITP_Configurations/ITP_Enabled';

  class ItpEnabledRule extends _baseRule.default {
    doEvaluate(ruleContext) {
      if (this.isItpEnabled(ruleContext)) {
        return _ruleResultType.RESULT_TYPE_YES;
      }

      return _ruleResultType.RESULT_TYPE_NO;
    }

    isItpEnabled(ruleContext) {
      const {
        store
      } = ruleContext;
      const {
        value: itpEnableConfig
      } = store.peekRecord('cs-config-item', ITP_CONFIG_ID);
      return itpEnableConfig.value;
    }

  }

  _exports.ItpEnabledRule = ItpEnabledRule;
  ItpEnabledRule.RULE_NAME = 'ItpEnabledRule';
});