define("adept-iq/models/avlm-cluster", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    startTime: attr('date'),
    endTime: attr('date'),
    clusterIndex: attr('number'),
    status: attr('string'),
    route: belongsTo('avlm-route'),
    schedule: belongsTo('avlm-schedule'),
    stopPoints: hasMany('avlm-stop-point'),
    orderedStops: Ember.computed('stopPoints.@each.routeIndex', function () {
      const ordinalStops = this.get('stopPoints').reduce((arr, stop) => {
        arr.push(stop);
        return arr;
      }, []);
      return ordinalStops.sortBy('routeIndex');
    }),
    tripStartTime: Ember.computed('stopPoints.@each.tripStartTime', 'startTime', function () {
      const stopPoints = this.get('stopPoints').toArray();
      let min = this.get('startTime') ? this.get('startTime') : null;
      stopPoints.forEach(stopPoint => {
        if (min) {
          const tripStartTime = stopPoint.get('tripStartTime');

          if (tripStartTime) {
            min = Math.min(tripStartTime.getTime(), min);
          }
        } else if (stopPoint.get('tripStartTime')) {
          min = stopPoint.get('tripStartTime').getTime();
        }
      });
      return min ? new Date(min) : null;
    }),
    tripEndTime: Ember.computed('stopPoints.@each.tripEndTime', 'endTime', function () {
      const stopPoints = this.get('stopPoints').toArray();
      let max = this.get('endTime') ? this.get('endTime') : null;
      stopPoints.forEach(stopPoint => {
        if (stopPoint.get('tripEndTime')) {
          max = Math.max(stopPoint.get('tripEndTime').getTime(), max);
        }
      });
      return max ? new Date(max) : null;
    })
  });

  _exports.default = _default;
});