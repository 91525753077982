define("adept-iq/pods/components/generic-components/table/rows/row/component", ["exports", "adept-iq/pods/components/generic-components/table/rows/base-row/component", "adept-iq/pods/components/generic-components/table/rows/row/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['table-action-row', 'data-test-table-action-row'],
    classNameBindings: ['status'],
    attributeBindings: ['data-record-id'],
    layout: _template.default,
    // this property name must be dasherized
    'data-record-id': Ember.computed.alias('row.content.id'),
    status: Ember.computed.alias('row.content.status'),
    modelName: Ember.computed.alias('row.content.constructor.modelName')
  });

  _exports.default = _default;
});