define("adept-iq/pods/components/iq-widgets/booking-trips-widget/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const hoverCellComponent = 'iq-widgets/booking-trips-widget/cells/hover-cell';
  var _default = {
    component: 'iq-widgets/previous-trips-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'trip',
    defaultSortId: 'viewAllTripRequestedTime',
    defaultSortAsc: true,
    hideWidgetControls: false,
    hideHeader: true,
    checkBoxColumn: true,
    isFormControls: true,
    title: 'View All Trips',
    columns: [{
      id: 'viewAllTripRequestedTime',
      index: 1,
      type: 'text',
      label: 'Date / Request / Time',
      valuePath: 'requestTimeForDisplay',
      customSortPath: 'legRequestTimeFormat',
      valuePreview: '08:00',
      editable: true,
      hidden: false,
      format: `${_environment.default.dateTimeFormat.DateMoment} / / ${_environment.default.dateTimeFormat.timeMoment}`,
      defaultWidth: 200
    }, {
      id: 'viewAllArriveTime',
      index: 2,
      type: 'time',
      label: 'Arrive Time',
      valuePath: 'drop.computedPlannedEta',
      valuePreview: '08:00',
      editable: true,
      hidden: false,
      format: `${_environment.default.dateTimeFormat.timeMoment}`,
      defaultWidth: 90
    }, {
      id: 'viewAllTripId',
      index: 3,
      type: 'uuid',
      isKey: true,
      label: 'Trip ID',
      valuePath: 'tripId',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 90
    }, {
      id: 'viewAllTripStatus',
      index: 4,
      type: 'text',
      label: 'Status',
      valuePath: 'computedStatus',
      hidden: false,
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 70
    }, {
      id: 'viewAllTripServiceMode',
      index: 5,
      type: 'text',
      label: 'Service Mode',
      valuePath: 'segment.travelMode',
      hidden: false,
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 120
    }, {
      id: 'viewAllRouteName',
      index: 6,
      type: 'uuid',
      label: 'Route Name',
      valuePath: 'computedRouteName',
      hidden: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'viewAllTripLogId',
      index: 7,
      type: 'uuid',
      label: 'Leg ID',
      valuePath: 'segment.leg.id',
      editable: true,
      hidden: false,
      defaultWidth: 85
    }, {
      id: 'viewAllTripPassengerId',
      index: 8,
      type: 'text',
      label: 'Passenger ID',
      valuePath: 'rider.riderId',
      editable: true,
      hidden: false,
      defaultWidth: 120
    }, {
      id: 'viewAllTripFirstName',
      index: 9,
      type: 'text',
      label: 'First Name',
      valuePath: 'rider.firstName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'viewAllTripLastName',
      index: 10,
      type: 'text',
      label: 'Last Name',
      valuePath: 'rider.lastName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'viewAllTripPickupaddress',
      index: 11,
      type: 'text',
      label: 'Pickup Address',
      valuePath: 'segment.pick.place.address.simpleAddress',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 140
    }, {
      id: 'viewAllTripPickupCity',
      index: 12,
      type: 'text',
      label: 'Pickup City',
      valuePath: 'tripPickUpAddress',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 120
    }, {
      id: 'viewAllPickupZone',
      index: 13,
      type: 'number',
      label: 'Pickup Zone',
      valuePath: 'segment.pick.place.address.zoneName.valueDisplayName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 120
    }, {
      id: 'viewAllTripDropaddress',
      index: 14,
      type: 'text',
      label: 'Drop off Address',
      valuePath: 'segment.drop.place.address.simpleAddress',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 140
    }, {
      id: 'viewAllTripDropCity',
      index: 15,
      type: 'text',
      label: 'Drop off City',
      valuePath: 'tripDropOffAddress',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 120
    }, {
      id: 'viewAllDropoffZone',
      index: 16,
      type: 'number',
      label: 'Drop off Zone',
      valuePath: 'segment.drop.place.address.zoneName.valueDisplayName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 120
    }, {
      id: 'viewAllFare',
      index: 17,
      type: 'number',
      label: 'Fare',
      valuePath: 'segment.fare',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 120
    }, {
      id: 'viewAllFareCategoryName',
      index: 18,
      type: 'text',
      label: 'Fare Category',
      valuePath: 'segment.fareCategory.displayName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 120
    }, {
      id: 'viewAllTravelNeeds',
      index: 19,
      type: 'enum',
      label: 'Travel Needs',
      valuePath: 'formattedTravelNeeds',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 120,
      cellComponent: hoverCellComponent,
      cellDesc: 'Travel Needs'
    }, {
      id: 'viewAllFundingAgencyName',
      index: 20,
      type: 'text',
      label: 'Funding Agency',
      valuePath: 'fundingAgencyDisplayName',
      editable: true,
      hidden: true,
      searchable: true,
      allowDisplayAttr: 'allowFundingAgency',
      defaultWidth: 140
    }, {
      id: 'promiseTime',
      index: 21,
      type: 'time',
      label: 'Promise Time',
      valuePath: 'promiseTime',
      valuePreview: '08:00',
      editable: true,
      hidden: true,
      format: `${_environment.default.dateTimeFormat.timeMoment}`,
      defaultWidth: 90
    }, {
      id: 'serviceWindowStartTime',
      index: 22,
      type: 'time',
      label: 'Pickup Window Start',
      valuePath: 'serviceWindowStartTime',
      valuePreview: '08:00',
      editable: true,
      hidden: true,
      format: `${_environment.default.dateTimeFormat.timeMoment}`,
      defaultWidth: 90
    }, {
      id: 'serviceWindowEndTime',
      index: 23,
      type: 'time',
      label: 'Pickup Window End',
      valuePath: 'serviceWindowEndTime',
      valuePreview: '08:00',
      editable: true,
      hidden: true,
      format: `${_environment.default.dateTimeFormat.timeMoment}`,
      defaultWidth: 90
    }, {
      id: 'anchor',
      index: 24,
      type: 'text',
      label: 'Anchor',
      valuePath: 'segment.leg.anchor',
      hidden: true,
      editable: true,
      searchable: true,
      defaultWidth: 70
    }]
  };
  _exports.default = _default;
});