define("adept-iq/models/event", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    lat: attr('number'),
    lng: attr('number'),
    time: attr('iso-date'),
    code: attr('string'),
    message: attr('string'),
    description: attr('string'),
    providerName: attr('string'),
    createdAt: attr('date'),
    deletedAt: attr('date'),
    updateAt: attr('date'),
    receivedAt: attr('date'),
    heading: attr('number'),
    speed: attr('number'),
    odo: attr('number'),
    actionType: attr('string'),
    eventType: attr(),
    eventLog: attr(),
    eventAttributes: attr(),
    vehicle: belongsTo('vehicle'),
    avlmAlert: belongsTo('avlm-alert'),
    driver: belongsTo('driver'),
    route: belongsTo('route'),
    schedule: belongsTo('schedule'),
    user: belongsTo('sso-user'),
    latlng: Ember.computed('lat', 'lng', function () {
      if (!Ember.isNone(this.get('lat')) && !Ember.isNone(this.get('lng'))) {
        return [`${this.get('lat')}`, `${this.get('lng')}`];
      }

      return null;
    }),
    collectedEventLog: Ember.computed('eventLog', function () {
      const eventLog = this.get('eventLog');
      const collective = [];

      if (eventLog) {
        (0, _lodash.forOwn)(eventLog, function (value) {
          collective.push(...value);
        });
      }

      return collective;
    })
  });

  _exports.default = _default;
});