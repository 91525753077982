define("adept-iq/pods/components/side-drawer/reconcile-close-dates/stubs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    reopenproviderIdTable: [{
      'providerId': '12342',
      'tripDate': '12'
    }, {
      'providerId': '2312',
      'tripDate': '12'
    }, {
      'providerId': '12313',
      'tripDate': '12'
    }, {
      'providerId': '12342',
      'tripDate': '12'
    }, {
      'providerId': '12342',
      'tripDate': '12'
    }, {
      'providerId': '12342',
      'tripDate': '12'
    }, {
      'providerId': '12342',
      'tripDate': '12'
    }, {
      'providerId': '12342',
      'tripDate': '12'
    }, {
      'providerId': '12342',
      'tripDate': '12'
    }],
    openRouteTable: [{
      'openRoute': '1234'
    }, {
      'openRoute': '1234'
    }],
    closeDateProviderTable: [{
      'providerId': '12342'
    }, {
      'providerId': '2312'
    }, {
      'providerId': '12313'
    }, {
      'providerId': '12342'
    }, {
      'providerId': '12342'
    }]
  };
  _exports.default = _default;
});