define("adept-iq/pods/components/generic-widgets/map-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "adept-iq/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bemap
  } = window;

  var _default = _component.default.extend({
    interaction: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    mapService: Ember.inject.service('map'),
    classNames: ['map-widget'],
    layoutName: 'components/generic-widgets/map-widget',
    classNameBindings: ['testMapWidget'],
    testMapWidget: false,
    centerLat: _environment.default.tomtom.search.center.lat,
    centerLng: _environment.default.tomtom.search.center.lon,
    zoom: 12,
    autoZoom: true,
    map: null,
    isWidgetMaximized: Ember.computed.not('maximizer.isWidgetMinified'),
    // tomtom will only accept an element ID, so create a unique one
    mapElementId: Ember.computed('elementId', function () {
      const elementId = this.get('elementId');
      return `${elementId}-map`;
    }),

    init() {
      const mapService = this.get('mapService');

      this._super(...arguments);

      this._didZoomMap = () => {
        Ember.run.scheduleOnce('afterRender', this, 'didZoomMap');
      };

      this._didWheelZoom = () => {
        Ember.run.scheduleOnce('afterRender', this, 'didWheelZoom');
      };

      this._didPanMap = () => {
        Ember.run.scheduleOnce('afterRender', this, 'didPanMap');
      };

      this.set('centerLat', mapService.get('center.lat'));
      this.set('centerLng', mapService.get('center.lon'));
    },

    didInsertElement() {
      this._super(...arguments);

      const mapElementId = this.get('mapElementId');
      const bemapMainCtx = this.get('mapService.bemapMainCtx');
      const map = new bemap.LeafletMap(bemapMainCtx, mapElementId).defaultLayers();
      map.native.on('zoomstart', this._didZoomMap);
      map.native.on('mouseup', this._didPanMap);
      window.L.DomEvent.on(map.native._container, 'mousewheel', this._didWheelZoom);
      this.set('map', map);
    },

    willDestroyElement() {
      const map = this.get('map');
      map.native.off('zoomstart', this._didZoomMap);
      map.native.off('mouseup', this._didPanMap);
      map.native.remove();
      this.set('map', null);

      this._super(...arguments);
    },

    destroy() {
      this._didZoomMap = null;

      this._super(...arguments);
    },

    didZoomMap() {
      this.get('interaction').didZoomMap();
    },

    didPanMap() {
      this.set('autoZoom', false);
    },

    didWheelZoom() {
      this.set('autoZoom', false);
    },

    enableAutoZoom() {
      this.set('autoZoom', true);
    },

    onWidgetResize() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, 'invalidateMapSize');
    },

    boundDistance: Ember.computed('bounds._southWest', function () {
      const bounds = this.get('bounds');
      const map = this.get('map');

      if (bounds._southWest && map) {
        const northEastCorner = bounds.getNorthEast();
        const southWestCorner = bounds.getSouthWest();
        const distance = Math.abs(map.native.distance(northEastCorner, southWestCorner));
        return distance;
      }

      return null;
    }),
    mapBoundDistance: Ember.computed('mapBounds._southWest', function () {
      const bounds = this.get('mapBounds');
      const map = this.get('map');
      if (!bounds || !map) return;

      if (bounds._southWest) {
        const northEastCorner = bounds.getNorthEast();
        const southWestCorner = bounds.getSouthWest();
        const distance = Math.abs(map.native.distance(northEastCorner, southWestCorner));
        return distance;
      }

      return null;
    }),
    shouldRefreshMapZoom: Ember.computed('mapBoundsID', 'mapBoundDistance', 'boundsID', 'boundDistance', function () {
      const mapBoundId = this.get('mapBoundsID');
      const boundsID = this.get('boundsID');

      if (boundsID === mapBoundId) {
        // singular vehicle(s) moving might create different boundaries with negligible difference in distance
        // we want to capture a boundary actually shrinking or enlarging when user selects more items or deselects less items while vehicle(s) are in motion
        const boundDistance = Math.floor(this.get('boundDistance'));
        const mapBoundDistance = Math.floor(this.get('mapBoundDistance'));
        return boundDistance !== mapBoundDistance;
      }

      return true;
    }),
    onCenterOrZoomChange: Ember.observer('bounds._southWest', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateMapView');
    }),
    // bound id is a computed property determined by changes in the checked item array
    onCheckedItemsChange: Ember.observer('boundsID', function () {
      this.enableAutoZoom();
    }),

    invalidateMapSize() {
      const map = this.get('map');
      if (!map) return;
      map.native.invalidateSize();
      Ember.run.scheduleOnce('afterRender', this, 'resetMapView');
    },

    /**
     *
     * @summary A generic function to reset map view with correct zoom and center value unless re-center button is clicked only center map view
     * @param {bool} manual - recenter map view without zoom level.
    */
    reCenterMapView() {
      let manual = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const map = this.get('map');
      const bounds = this.get('bounds');
      const boundId = this.get('boundsID');
      let center = null;
      if (!map) return;
      let zoom = map.getZoom(); // get user's current zoom level

      if (bounds && bounds._southWest) {
        center = bounds.getCenter();
      } // map view needs to be at least 12 or greater or zoom will be recalculated


      if (bounds && !bounds._southWest && !manual) {
        zoom = zoom <= this.get('zoom') ? this.get('zoom') : zoom;
      }

      const centerLat = center ? center.lat : this.get('centerLat');
      const centerLng = center ? center.lng : this.get('centerLng');
      if (Ember.isNone(centerLat) || Ember.isNone(centerLng) || Ember.isNone(zoom)) return;
      const mapViewDigest = JSON.stringify([boundId, centerLat, centerLng]);

      if (mapViewDigest !== this._mapViewDigest || manual) {
        map.move(centerLng, centerLat, zoom);
        this._mapViewDigest = mapViewDigest;
        this.set('mapBoundsID', boundId);
        this.set('mapBounds', bounds);
      }
    },

    /**
     *
     * @summary A generic function to reset map view
    */
    resetMapView() {
      const map = this.get('map');
      const bounds = this.get('bounds');
      if (!map || !bounds) return;

      if (Ember.isNone(bounds._southWest)) {
        return this.reCenterMapView(); // either we default to hard-coded center or use the current center lat / center lng
      }

      if (bounds._southWest) {
        const northeast = bounds.getNorthEast();
        const southwest = bounds.getSouthWest();
        const bbox = new bemap.BoundingBox(southwest.lng, southwest.lat, northeast.lng, northeast.lat);
        map.moveToBoundingBox(bbox);
      }
    },

    /**
     *
     * @summary A generic function to update map view based on a new boundary
    */
    updateMapView() {
      const map = this.get('map');
      const bounds = this.get('bounds');
      const boundId = this.get('boundsID');
      const autoZoom = this.get('autoZoom');
      if (!map || !autoZoom || !bounds) return;

      if (Ember.isNone(bounds._southWest)) {
        return this.reCenterMapView(); // either we default to hard-coded center or use the current center lat / center lng
      }

      const shouldRefreshMapZoom = this.get('shouldRefreshMapZoom');
      const center = bounds.getCenter();
      const centerLat = center.lat;
      const centerLng = center.lng;
      const northeast = bounds.getNorthEast();
      const southwest = bounds.getSouthWest();
      const mapViewDigest = JSON.stringify([boundId, centerLat, centerLng]); // don't do anything if center hasn't changed

      if (mapViewDigest !== this._mapViewDigest && shouldRefreshMapZoom) {
        const bbox = new bemap.BoundingBox(southwest.lng, southwest.lat, northeast.lng, northeast.lat);
        map.moveToBoundingBox(bbox);
        this._mapViewDigest = mapViewDigest;
        this.set('mapBoundsID', boundId);
        this.set('mapBounds', bounds);
      }
    }

  });

  _exports.default = _default;
});