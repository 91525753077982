define("adept-iq/pods/components/toggle-switch/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['toggle-switch'],
    classNameBindings: ['isActive'],
    toggleProperty: null,
    onValue: true,
    onLabel: null,
    offValue: false,
    offLabel: null,
    disabled: false,
    isActive: Ember.computed('toggleProperty', 'onValue', function () {
      return this.get('toggleProperty') === this.get('onValue');
    }),

    setOn() {
      this.set('toggleProperty', this.get('onValue'));
    },

    setOff() {
      this.set('toggleProperty', this.get('offValue'));
    },

    actions: {
      on() {
        this.setOn();
      },

      off() {
        this.setOff();
      },

      toggle() {
        const disabled = this.get('disabled');

        if (!disabled) {
          const currentValue = this.get('toggleProperty');

          if (currentValue === this.get('onValue')) {
            this.setOff();
          } else {
            this.setOn();
          }
        }
      }

    }
  });

  _exports.default = _default;
});