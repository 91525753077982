define("adept-iq/models/avlm-canned-message", ["exports", "ember-data", "adept-iq/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MESSAGE_STATUS_SENT = _exports.MESSAGE_STATUS_SENDING = _exports.MESSAGE_STATUS_RECEIVED = _exports.MESSAGE_STATUS_FAILED = _exports.MESSAGE_STATUS_DELETED = _exports.MESSAGE_STATUSES = _exports.DRIVER_ENDPOINT_SENDER = _exports.DRIVER_ENDPOINT_RECIPIENT = _exports.DRIVER_ENDPOINTS = void 0;
  var belongsTo = _emberData.default.belongsTo;
  const {
    Model,
    attr
  } = _emberData.default;
  const MESSAGE_STATUS_SENDING = 'Sending';
  _exports.MESSAGE_STATUS_SENDING = MESSAGE_STATUS_SENDING;
  const MESSAGE_STATUS_SENT = 'Sent';
  _exports.MESSAGE_STATUS_SENT = MESSAGE_STATUS_SENT;
  const MESSAGE_STATUS_RECEIVED = 'Received';
  _exports.MESSAGE_STATUS_RECEIVED = MESSAGE_STATUS_RECEIVED;
  const MESSAGE_STATUS_FAILED = 'Failed';
  _exports.MESSAGE_STATUS_FAILED = MESSAGE_STATUS_FAILED;
  const MESSAGE_STATUS_DELETED = 'Deleted';
  _exports.MESSAGE_STATUS_DELETED = MESSAGE_STATUS_DELETED;
  const DRIVER_ENDPOINT_RECIPIENT = 'Recipient';
  _exports.DRIVER_ENDPOINT_RECIPIENT = DRIVER_ENDPOINT_RECIPIENT;
  const DRIVER_ENDPOINT_SENDER = 'Sender';
  _exports.DRIVER_ENDPOINT_SENDER = DRIVER_ENDPOINT_SENDER;
  const MESSAGE_STATUSES = [MESSAGE_STATUS_SENDING, MESSAGE_STATUS_SENT, MESSAGE_STATUS_RECEIVED, MESSAGE_STATUS_FAILED, MESSAGE_STATUS_DELETED];
  _exports.MESSAGE_STATUSES = MESSAGE_STATUSES;
  const DRIVER_ENDPOINTS = {
    'Sender': 'S',
    'Recipient': 'R'
  };
  _exports.DRIVER_ENDPOINTS = DRIVER_ENDPOINTS;

  var _default = Model.extend({
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    body: attr('string'),
    subject: attr('string'),
    messageNr: attr('string'),
    replyToMsgId: attr('string'),
    priority: attr('string', {
      defaultValue: 'normal'
    }),
    isNewMessageReceived: attr('boolean'),
    createdTime: attr('date', {
      defaultValue: () => new Date()
    }),
    deliveredTime: attr('date'),
    readTime: attr('date'),
    deletedTime: attr('date'),
    responseType: attr('object'),
    responseTypeText: attr('string'),
    responseMessage: attr('string'),
    ackTime: attr('date'),
    driverEndPointType: attr('string'),
    type: attr('string'),
    // use for sending canned message to avlm
    // driver.id uses the externalId of dsDriver
    rider: belongsTo('avlm-rider'),
    template: belongsTo('avlm-canned-message-template'),
    replyToMessage: belongsTo('avlm-canned-message', {
      inverse: null
    }),
    // avlm models for avlm-only at most three relationships will be used
    avlmDriver: belongsTo('avlm-driver'),
    avlmTrip: belongsTo('avlm-trip'),
    avlmVehicle: belongsTo('avlm-vehicle'),
    avlmRoute: belongsTo('avlm-route'),
    alert: belongsTo('avlm-alert'),
    avlmRouteVehicleDriver: belongsTo('avlm-route-vehicle-driver'),
    // core models required for mvpplus + avlm at most three relationships will be used
    driver: belongsTo('driver'),
    vehicle: belongsTo('vehicle'),
    route: belongsTo('route'),
    vehicleName: Ember.computed('avlmVehicle', 'vehicle', function () {
      const dsName = this.get('vehicle.name');
      if (dsName) return dsName;
      const avlmName = this.get('avlmVehicle.callSign');
      return avlmName;
    }),
    tripStartTime: Ember.computed('ssRoute.tripStartTime', 'avlmRoute.tripStartTime', 'deliveredTime', function () {
      const dsRouteTime = this.get('ssRoute.tripStartTime');
      const avlmRouteTime = this.get('avlmRoute.tripStartTime');
      const deliveredTime = this.get('deliveredTime');
      if (avlmRouteTime) return avlmRouteTime;
      if (dsRouteTime) return dsRouteTime;
      return deliveredTime;
    }),
    tripEndTime: Ember.computed('ssRoute.tripEndTime', 'avlmRoute.tripEndTime', 'deliveredTime', function () {
      const avlmRouteTime = this.get('avlmRoute.tripEndTime');
      const dsRouteTime = this.get('ssRoute.tripEndTime');
      const deliveredTime = this.get('deliveredTime');
      if (avlmRouteTime) return avlmRouteTime;
      if (dsRouteTime) return dsRouteTime;
      return deliveredTime;
    }),
    isEmergency: Ember.computed('type', function () {
      return this.get('priority') === 'E' && this.get('body') === 'EMERGENCY';
    }),
    isAcknowledged: Ember.computed('type', function () {
      return this.get('priority') === 'E' && this.get('body') === 'Acknowledge';
    }),
    isResolved: Ember.computed('type', function () {
      return this.get('priority') === 'E' && this.get('body') === 'Resolve';
    }),
    isCanceled: Ember.computed('type', function () {
      return this.get('priority') === 'E' && this.get('body') === 'Canceled';
    }),
    isDelivered: Ember.computed('deliveredTime', function () {
      return Ember.isPresent(this.get('deliveredTime'));
    }),
    isRead: Ember.computed('readTime', function () {
      return Ember.isPresent(this.get('readTime'));
    }),
    isDeleted: Ember.computed('deletedTime', function () {
      return Ember.isPresent(this.get('deletedTime'));
    }),
    isRecieved: Ember.computed('driverEndPointType', function () {
      if (this.user.isRoadSupEnable()) {
        if (this.get('driverEndPointType') === DRIVER_ENDPOINTS[DRIVER_ENDPOINT_SENDER]) {
          return false;
        }

        return true;
      }

      if (_environment.default.APP.avlmLite) {
        return this.get('driverEndPointType') === DRIVER_ENDPOINTS[DRIVER_ENDPOINT_SENDER];
      }

      return this.get('driverEndPointType') === DRIVER_ENDPOINTS[DRIVER_ENDPOINT_SENDER];
    }),
    isSent: Ember.computed('driverEndPointType', function () {
      if (this.user.isRoadSupEnable()) {
        if (this.get('driverEndPointType') === DRIVER_ENDPOINTS[DRIVER_ENDPOINT_SENDER]) {
          return true;
        }

        return false;
      }
    }),
    status: Ember.computed('isDelivered', 'isRead', 'isDeleted', function () {
      if (this.get('isDeleted')) {
        return MESSAGE_STATUS_DELETED;
      }

      if (this.get('isDelivered') && this.get('isRecieved')) {
        return MESSAGE_STATUS_RECEIVED;
      }

      if (this.get('isDelivered') && this.get('isSent')) {
        return MESSAGE_STATUS_SENT;
      }

      if (this.get('isDelivered')) {
        return MESSAGE_STATUS_SENT;
      }

      return MESSAGE_STATUS_SENDING;
    }),
    readString: Ember.computed('isRead', function () {
      return this.get('isRead') ? 'Yes' : 'No';
    }),
    driverId: Ember.computed('avlmDriver', 'avlmRoute', function () {
      const routeName = this.get('avlmRoute.trackingId');
      if (routeName && routeName.indexOf('SUP-') > -1) return '';
      return this.get('avlmDriver.id');
    })
  });

  _exports.default = _default;
});