define("adept-iq/pods/components/iq-widgets/ember-react-playback/context/api", ["exports", "react", "adept-iq/config/api-urls", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel"], function (_exports, _react, _apiUrls, _panel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.useAPI = _exports.APIContextProvider = void 0;
  const MAX_RETRY = 150;
  const PAGE_LIMIT = 10000;
  const DELAY = 2000;
  const PAGE_DELAY = 2000;

  const pause = async function () {
    let delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 100;
    return new Promise(resolve => setTimeout(resolve, delay));
  };

  const retrier = async function* (_ref) {
    let {
      attempts = Infinity,
      delay = 100
    } = _ref;

    for (let i = 0; i < attempts; i++) {
      yield i;
      await pause(delay);
    }
  };

  const apiCall = (url, token) => {
    try {
      const apiHost = _apiUrls.API.playbackNewService.host;
      const apiUrl = apiHost + url;
      const controller = new AbortController();
      const signal = controller.signal;
      const promise = new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(apiUrl, {
            headers: {
              Authorization: `Bearer ${token}`
            },
            signal
          });
          const data = await response.json();
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });

      promise.cancel = () => controller.abort();

      return promise;
    } catch (err) {
      // Simulate null data.
      return Promise.resolve({
        data: []
      });
    }
  };

  const APIContext = _react.default.createContext();

  const APIContextProvider = _ref2 => {
    let {
      children
    } = _ref2;

    const [rvdData, setRvdData] = _react.default.useState([]);

    const [rvdToDisplay, setRvdToDisplay] = _react.default.useState({});

    const [eventData, setEventData] = _react.default.useState([]);

    const [vehicleData, setVehicleData] = _react.default.useState([]);

    const [isRvdPreloaded, setIsRvdPreloaded] = _react.default.useState(false);

    const [query, setQuery] = _react.default.useState(false);

    const eventTypeTranslater = function (actionType, eventType) {
      let isRouteBreak = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      switch (actionType) {
        case 'accept':
          return 'Accept';

        case 'arrive':
          return 'Arrive';

        case 'depart':
          return 'Depart';

        case 'pullOut':
          return 'Start Route';

        case 'pullIn':
          return 'End Route';

        case 'signOn':
          return 'Sign On';

        case 'signOff':
          return 'Sign Off';

        case 'preCheckDone':
          return 'Pre Check';

        case 'postCheckDone':
          return 'Post Check';

        case 'powerOn':
          return 'Power On';

        case 'leaveGarageSignOff':
          return 'Moved Without Sign On';

        case 'commRestored':
          return 'Communication Restored';

        case 'commFailure':
          return 'Communication Failure';

        case 'onBreak':
          return 'Start Break';

        case 'offBreak':
          return 'End Break';

        case 'applicationRestart':
          return 'App Restarted';

        case 'signOnFailure':
          return 'Sign On Failed';

        case 'noShow':
          return 'Stop No Showed';

        case 'noShowRequest':
          return ' No Show Request';

        case 'noShowCancelled':
          return ' No Show Cancelled';

        case 'noShowDeclined':
          return ' No Show Declined';

        case 'removed':
          if (isRouteBreak) return 'Break Removed';
          return 'Stop Removed';

        case 'added':
          if (isRouteBreak) return 'Break Added';
          return 'Stop Added';

        case 'emergencyOn':
          return 'Emergency Initiated';

        case 'emergencyOff':
          return 'Emergency Canceled by Driver';

        case 'emergencyClear':
          return 'Emergency Resolved';

        case 'emergencyAck':
          return 'Emergency Acknowledged';

        case 'emergencyCancel':
          return 'Emergency Canceled By Dispatch';

        case 'dispatchToDriverMessage':
          return 'Message From Dispatch';

        case 'driverToDispatchMessage':
          return 'Message From Driver';

        case 'undoNoShow':
          return 'Unperform NoShow';

        default:
          if (eventType === 'avl') {
            return 'GPS Ping';
          }

          return actionType;
      }
    };

    const transformEvents = (events, selectedRvd, pageNo, lastSection) => {
      let section = lastSection;
      return events.map((event, _index) => {
        const pick = event.stoptype === 'P' ? 'Pick' : null;
        const drop = event.stoptype === 'D' ? 'Drop' : null;
        const breakStop = event.event?.routebreakid ? 'Break' : null;
        const speedInMeterPerSecond = event.speed || event.event?.speed;
        const speedInMilesPerHour = speedInMeterPerSecond ? 2.23694 * Number(speedInMeterPerSecond) : null;
        const eventType = eventTypeTranslater(event.event?.actiontype, event.eventtype, !!event.event?.routebreakid);
        let stopAddress = 'N/A';

        if (eventType === 'Start Route') {
          stopAddress = event.route?.startaddress[0].formattedaddress;
        } else if (eventType === 'End Route') {
          stopAddress = event.route?.endaddress[0].formattedaddress;
        } else if (event.routebreak?.address) {
          stopAddress = event.routebreak?.address[0].formattedaddress;
        } else if (event.stoppoint?.address) {
          stopAddress = event.stoppoint?.address[0].formattedaddress;
        }

        if (event.stoptype === 'P' || event.stoptype === 'D') section = section + 1;

        if (event.locLat === 40.0 && event.locLng === -73.0) {
          // Special case to handle 'bogus' lat/lng
          event.locLat = null;
          event.locLng = null;
        }

        return { ...event,
          _index: pageNo * PAGE_LIMIT + _index,
          section,
          // Below are transformed values for table display
          localTime: new Date(event.timestamp).toLocaleTimeString(),
          displayLat: event.locLat ? Number.parseFloat(event.locLat).toFixed(6) : 'N/A',
          displayLng: event.locLng ? Number.parseFloat(event.locLng).toFixed(6) : 'N/A',
          vehicleId: event.vehicle?.callsign || selectedRvd.vehicleName,
          routeName: event.route?.trackingid || 'N/A',
          passengerId: event.rider?.bookingclientid || 'N/A',
          passengerFirstName: event.rider?.firstname || 'N/A',
          passengerLastName: event.rider?.lastname || 'N/A',
          eventType: eventType,
          actionType: event.event?.actiontype,
          stopType: pick || drop || breakStop || 'N/A',
          stopAddress,
          vehicleSpeed: speedInMilesPerHour ? Number.parseFloat(speedInMilesPerHour).toFixed(0) : '0'
        };
      });
    };

    const transformVehicles = vehicles => {
      return vehicles.map(data => {
        return { ...data,
          displayLat: data.locLat ? Number.parseFloat(data.locLat).toFixed(6) : 'N/A',
          displayLng: data.locLng ? Number.parseFloat(data.locLng).toFixed(6) : 'N/A',
          vehicleId: data.vehicleid,
          vehicleName: data.vehicle?.callsign || 'N/A',
          driverId: data.driver?.id,
          driverName: data.driver?.firstname || 'N/A',
          badgenumber: data.driver?.badgenumber || 'N/A',
          routeId: data.route?.id,
          routeName: data.route?.trackingid || 'N/A',
          provider: data.vehicle?.providername || 'N/A',
          otp: data.route?.otpvalue || 'N/A',
          isChecked: false
        };
      });
    };

    const filterVehiclesByProviders = (vehicles, providers) => {
      const providerIds = providers.map(p => p.id);
      const validVehicles = vehicles.filter(v => providerIds.includes(v.provider));
      return validVehicles.map(v => {
        return { ...v,
          totalcount: validVehicles.length
        };
      });
    };

    const getRvdByVehicle = async (startTime, endTime, timezone, token, providers) => {
      let apiUrl = `/route-vehicle-driver?startdate=${startTime}&enddate=${endTime}&timezone=${timezone}`;
      const providerIds = providers.map(p => p.id); // eslint-disable-next-line no-unused-vars

      for await (const _ of retrier({
        attempts: 4,
        delay: DELAY
      })) {
        const response = await apiCall(apiUrl, token);

        if (response && response.data && response.data.length > 0) {
          if (!response.data[0].QueryExecutionId) {
            // TODO: check why it has recordtype === 'avl';
            const rvds = response.data.filter(rvd => rvd.recordtype === 'rvd').filter(rvd => providerIds.includes(rvd.vehicle?.providername));
            setRvdData(rvds);
            break;
          }

          apiUrl = `/route-vehicle-driver?startdate=${startTime}&enddate=${endTime}&timezone=${timezone}&queryExecutionId=${response.data[0].QueryExecutionId}`;
        } else if (response && response.data && response.data.length === 0) {
          break;
        }
      }
    };

    const getVehicleByLocation = async (startTime, endTime, location, radius, feetOrMile, timezone, token, providers) => {
      let apiUrl = `/vehicle?startdate=${startTime}&enddate=${endTime}&lat=${location.position.lat}&lng=${location.position.lon}&radius=${radius}&unit=${feetOrMile}&timezone=${timezone}&pageno=1&pagelimit=${PAGE_LIMIT}`; // eslint-disable-next-line no-unused-vars

      for await (const _ of retrier({
        attempts: 4,
        delay: DELAY
      })) {
        let request = apiCall(apiUrl, token);
        setQuery(request);
        const response = await request;
        setQuery(null);

        if (response && response.data && response.data.length > 0) {
          if (!response.data[0].QueryExecutionId) {
            let transformedVehicles = transformVehicles(response.data);

            if (response.data[0].totalcount && response.data[0].totalcount > transformedVehicles.length) {
              const remainPages = Math.ceil(response.data[0].totalcount / PAGE_LIMIT) - 1;
              await pause(PAGE_DELAY);

              for await (const count of retrier({
                attempts: remainPages,
                delay: PAGE_DELAY
              })) {
                const pagedApiUrl = `/vehicle?startdate=${startTime}&enddate=${endTime}&lat=${location.position.lat}&lng=${location.position.lon}&radius=${radius}&unit=${feetOrMile}&timezone=${timezone}&pageno=${count + 2}&pagelimit=${PAGE_LIMIT}&queryExecutionId=${response.data[0].QueryExecutionId}`;
                request = apiCall(pagedApiUrl, token);
                setQuery(request);
                const pagedResponse = await request;
                setQuery(null);

                if (pagedResponse.data && pagedResponse.data.length > 0 && !pagedResponse.data[0].QueryExecutionId) {
                  transformedVehicles = [...transformedVehicles, ...transformVehicles(pagedResponse.data)];
                }
              }
            }

            setVehicleData(filterVehiclesByProviders(transformedVehicles, providers));
            break;
          }

          apiUrl = `/vehicle?startdate=${startTime}&enddate=${endTime}&lat=${location.position.lat}&lng=${location.position.lon}&radius=${radius}&unit=${feetOrMile}&timezone=${timezone}&pageno=1&pagelimit=${PAGE_LIMIT}&queryExecutionId=${response.data[0].QueryExecutionId}`;
        } else if (response && response.data && response.data.length === 0) {
          break;
        }
      }
    };

    const preloadRvd = async (startTime, endTime, timezone, token) => {
      let apiUrl = `/event?startdate=${startTime}&enddate=${endTime}&pageno=1&pagelimit=${1000}&isCallVehicle=true&timezone=${timezone}`;
      setIsRvdPreloaded(false);

      for await (const _ of retrier({
        attempts: MAX_RETRY,
        delay: DELAY
      })) {
        const response = await apiCall(apiUrl, token);

        if (response && response.data && response.data.length > 0) {
          if (!response.data[0].QueryExecutionId) {
            setIsRvdPreloaded(true);
            break;
          } else if (_ === MAX_RETRY - 1) {
            // Still processing, but we can set preload done.
            setIsRvdPreloaded(true);
          }

          apiUrl = `/event?startdate=${startTime}&enddate=${endTime}&pageno=1&pagelimit=${1000}&isCallVehicle=true&timezone=${timezone}&queryExecutionId=${response.data[0].QueryExecutionId}`;
        } else if (response && response.data && response.data.length === 0) {
          break;
        }
      }
    };

    const getRelatedRouteEvents = (data, selectedRoute) => {
      if (!data) return [];
      if (!selectedRoute) return data;
      return data.filter(d => {
        return d.routeName === selectedRoute || d.routeName === 'N/A';
      });
    };

    const getEventByVehicle = async (startTime, endTime, timezone, selectedRvd, token, searchMode) => {
      const vehicleQuery = selectedRvd.vehicleId ? '&vehicleid=' + selectedRvd.vehicleId : ''; // VE events can send routeid/driverid

      const routeQuery = ''; //selectedRvd.routeId ? '&routeid=' + selectedRvd.routeId : '';

      const driverQuery = ''; //selectedRvd.driverId ? '&driverid=' + selectedRvd.driverId : '';

      setRvdToDisplay(selectedRvd);
      let apiUrl = `/event?startdate=${startTime}&enddate=${endTime}${vehicleQuery}${routeQuery}${driverQuery}&pageno=1&pagelimit=${PAGE_LIMIT}&timezone=${timezone}`; // eslint-disable-next-line no-unused-vars

      for await (const _ of retrier({
        attempts: MAX_RETRY,
        delay: DELAY
      })) {
        let request = apiCall(apiUrl, token);
        setQuery(request);
        const response = await request;
        setQuery(null);

        if (response && response.data && response.data.length > 0) {
          if (!response.data[0].QueryExecutionId) {
            let transFormedEvents = transformEvents(response.data, selectedRvd, 0, 0);

            if (response.data[0].totalcount && response.data[0].totalcount > transFormedEvents.length) {
              const remainPages = Math.ceil(response.data[0].totalcount / PAGE_LIMIT) - 1;
              await pause(PAGE_DELAY);

              for await (const count of retrier({
                attempts: remainPages,
                delay: PAGE_DELAY
              })) {
                const pagedApiUrl = `/event?startdate=${startTime}&enddate=${endTime}${vehicleQuery}${routeQuery}${driverQuery}&pageno=${count + 2}&pagelimit=${PAGE_LIMIT}&timezone=${timezone}&queryExecutionId=${response.data[0].QueryExecutionId}`;
                request = apiCall(pagedApiUrl, token);
                setQuery(request);
                const pagedResponse = await request;
                setQuery(null);

                if (pagedResponse.data && pagedResponse.data.length > 0 && !pagedResponse.data[0].QueryExecutionId) {
                  const lastSection = transFormedEvents.length > 0 ? transFormedEvents[transFormedEvents.length - 1].section : 0;
                  transFormedEvents = [...transFormedEvents, ...transformEvents(pagedResponse.data, selectedRvd, count + 1, lastSection)];
                }
              }
            } // When search byVehicle, we would filter events base on rvd's route. However, when search byLocationVehicle, we would return all events of the vehicle regardless of route.


            const relatedRouteEvents = searchMode === _panel.searchModes.byLocationVehicle ? transFormedEvents : getRelatedRouteEvents(transFormedEvents, selectedRvd.routeName);
            setEventData(relatedRouteEvents);
            break;
          }

          apiUrl = `/event?startdate=${startTime}&enddate=${endTime}${vehicleQuery}${routeQuery}${driverQuery}&pageno=1&pagelimit=${PAGE_LIMIT}&timezone=${timezone}&queryExecutionId=${response.data[0].QueryExecutionId}`;
        } else if (response && response.data && response.data.length === 0) {
          break;
        }
      }
    };

    const submitDateTime = async (startTime, endTime, timezone, token, providers, notifications, searchMode, callback) => {
      try {
        if (searchMode === _panel.searchModes.byVehicle) {
          await getRvdByVehicle(startTime, endTime, timezone, token, providers);
        } else {
          await preloadRvd(startTime, endTime, timezone, token);
        }
      } catch (e) {
        if (!(e instanceof DOMException)) {
          notifications.warning(e);
        }
      }

      if (callback) callback();
    };

    const doSearch = async (startTime, endTime, timezone, token, providers, notifications, searchMode, selectedRvd, location, radius, feetOrMile, callback) => {
      try {
        if (searchMode === _panel.searchModes.byVehicle || searchMode === _panel.searchModes.byLocationVehicle) {
          await getEventByVehicle(startTime, endTime, timezone, selectedRvd, token, searchMode);
        } else {
          await getVehicleByLocation(startTime, endTime, location, radius, feetOrMile, timezone, token, providers);
        }
      } catch (e) {
        if (!(e instanceof DOMException)) {
          notifications.warning(e);
        }
      }

      if (callback) callback();
    };

    return _react.default.createElement(APIContext.Provider, {
      value: {
        rvdData,
        setRvdData,
        eventData,
        setEventData,
        vehicleData,
        setVehicleData,
        rvdToDisplay,
        setRvdToDisplay,
        isRvdPreloaded,
        setIsRvdPreloaded,
        submitDateTime,
        query,
        setQuery,
        doSearch
      }
    }, children);
  };

  _exports.APIContextProvider = APIContextProvider;

  const useAPI = () => {
    const context = _react.default.useContext(APIContext);

    return context;
  };

  _exports.useAPI = useAPI;
});