define("adept-iq/pods/components/form-components/vehicle-selection/vehicle-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-vehicle-header-column'],
    sortIconProperty: Ember.computed('column.{sortable,sorted,ascending}', function () {
      const sorted = this.get('column.sorted');

      if (sorted) {
        const ascending = this.get('column.ascending');
        return ascending ? 'iconAscending' : 'iconDescending';
      }

      return '';
    })
  });

  _exports.default = _default;
});