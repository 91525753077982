define("adept-iq/pods/components/side-drawer/permissions-add-role/permission-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['permission-section'],
    record: null,
    extra: null,
    disabled: false,
    toggleAll: false,
    valuePath: null,
    store: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    category: Ember.computed.readOnly('extra.category'),
    permissions: Ember.computed.readOnly('extra.groupedPermissions'),
    rolePermHash: Ember.computed.readOnly('record.permissionHash'),
    actions: {
      onCheckboxChange(record, valuePath) {
        const onChange = this.get('onChange');
        const switchState = !record.get('state');
        const id = parseInt(record.get('id'), 10);
        const options = {
          id,
          state: switchState
        };
        onChange(valuePath, options);
        record.set('state', switchState);
      }

    }
  });

  _exports.default = _default;
});