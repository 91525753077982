define("adept-iq/pods/components/form-components/boolean-slider/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    booleanValue: Ember.computed.alias('value'),
    sliderIdPath: Ember.computed.readOnly('extra.sliderId'),
    actions: {
      onSliderToggle(event) {
        this.set('booleanValue', event.target.checked);
        this.get('onChange')(this.get('booleanValue'));
      }

    }
  });

  _exports.default = _default;
});