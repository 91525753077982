define("adept-iq/classes/data-joins/map/shape", ["exports", "adept-iq/classes/data-joins/map/base", "lodash", "adept-iq/pods/components/iq-widgets/map-widget/config/circle", "adept-iq/pods/components/iq-widgets/map-widget/config/polygon"], function (_exports, _base, _lodash, _circle, _polygon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bemap
  } = window;

  var _default = _base.default.extend({
    queueName: 'map-shape',

    refresh(node) {
      const isMaximized = this.get('options.isMaximized');
      const isJoined = node.get('isJoined');
      const isHidden = node.get('isHidden');
      const points = node.get('points');
      const shape = isMaximized ? node.get('shapeMaximized') : node.get('shape');

      if (!isJoined || isHidden || Ember.isEmpty(points)) {
        if (shape) {
          return this.removeShape(node, isMaximized);
        }

        return false;
      }

      if (!shape) {
        return this.createShape(node, isMaximized);
      }

      return this.updateShape(node, isMaximized);
    },

    createShape(node, isMaximized) {
      const map = this.map;
      const label = node.get('label');
      const record = node.get('record');
      let style = node.get('style');
      const parentLabel = node.get('parentLabel');
      let points = node.get('points');
      const radius = node.get('radius');
      const noOfPoints = node.get('noOfPoints');

      if (typeof record.get('style') !== 'undefined' && record.get('style')) {
        style = record.get('style');
      }

      let defaultOptions, shape;
      const nodeOptions = node.get('options') || {};
      const modelName = record.constructor.modelName;
      let parentLabelFormatted = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 2em;text-align: center;">' + parentLabel + '</td><td rowspan="2">&nbsp;</td><td rowspan="2">' + label + '</td></tr></table>';

      if (noOfPoints === 1) {
        defaultOptions = _circle.default[style] || {};

        const options = _lodash.default.merge(defaultOptions, nodeOptions, {
          label,
          opacity: node.get('opacity'),
          // for active context clicks
          isActive: node.get('isActive'),
          isTraversal: node.get('isTraversal'),
          modelId: record.get('id'),
          modelName
        });

        options.isMaximized = this.get('options.isMaximized');
        shape = new bemap.Circle([points.lat, points.lng], radius, options);
        parentLabelFormatted = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 0.7em;text-align: center;">' + parentLabel + '</td><td rowspan="2">&nbsp;</td><td rowspan="2">' + label + '</td></tr></table>';
      } else {
        defaultOptions = _polygon.default[style] || {};
        points = [node.get('points')];

        const options = _lodash.default.merge(defaultOptions, nodeOptions, {
          label,
          opacity: node.get('opacity'),
          // for active context clicks
          isActive: node.get('isActive'),
          isTraversal: node.get('isTraversal'),
          modelId: record.get('id'),
          modelName
        });

        shape = new bemap.Polygon(points, options);
      }

      shape.setStyle(style);

      if (isMaximized) {
        node.set('shapeMaximized', shape);
      } else {
        node.set('shape', shape);
      }

      shape.addTo(map);
      shape.bindTooltip(parentLabelFormatted, {
        permanent: true,
        direction: 'center',
        opacity: 0.4
      }).openTooltip();
      return true;
    },

    updateShape(node, isMaximized) {
      const opacity = node.get('opacity');
      const shape = isMaximized ? node.get('shapeMaximized') : node.get('shape');
      const label = node.get('label');
      const parentLabel = node.get('parentLabel');
      let style = node.get('style');
      const record = node.get('record');
      const noOfPoints = node.get('noOfPoints');

      if (typeof record.get('style') !== 'undefined' && record.get('style')) {
        style = record.get('style');
      }

      let parentLabelFormatted = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 2em;text-align: center;">' + parentLabel + '</td><td rowspan="2">&nbsp;</td><td rowspan="2">' + label + '</td></tr></table>';
      let defaultOptions;

      if (noOfPoints === 1) {
        defaultOptions = _circle.default[style] || {};
        parentLabelFormatted = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 0.7em;text-align: center;">' + parentLabel + '</td><td rowspan="2">&nbsp;</td><td rowspan="2">' + label + '</td></tr></table>';
      } else {
        defaultOptions = _polygon.default[style] || {};
      }

      const nodeOptions = node.get('options') || {};
      const modelName = record.constructor.modelName;

      const options = _lodash.default.merge(defaultOptions, nodeOptions, {
        label,
        opacity: opacity,
        // for active context clicks
        isActive: node.get('isActive'),
        isTraversal: node.get('isTraversal'),
        modelId: record.get('id'),
        modelName
      });

      shape.setStyle(options);
      shape.setTooltipContent(parentLabelFormatted);
      return true;
    },

    removeShape(node, isMaximized) {
      const shape = isMaximized ? node.get('shapeMaximized') : node.get('shape');
      shape.remove();

      if (isMaximized) {
        node.set('shapeMaximized', null);
      } else {
        node.set('shape', null);
      }

      return true;
    }

  });

  _exports.default = _default;
});