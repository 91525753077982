define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel/datetimePicker", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/session", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api", "react-playback-components-library", "adept-iq/config/environment", "moment"], function (_exports, _react, _panel, _session, _api, _reactPlaybackComponentsLibrary, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IqFlatPickr = _reactPlaybackComponentsLibrary.default.IqFlatPickr;

  const DatetimePicker = () => {
    const {
      date,
      setDate,
      startTime,
      setStartTime,
      endTime,
      setEndTime,
      canSubmit,
      searchMode,
      setIsLoadingRvd,
      setSelectedRvd
    } = (0, _panel.usePanel)();

    const {
      token,
      providers,
      notifications
    } = _react.default.useContext(_session.SessionContext);

    const {
      submitDateTime,
      setRvdData,
      setEventData,
      setVehicleData,
      query,
      setQuery
    } = (0, _api.useAPI)();

    const timezone = _moment.default.tz.guess();

    const maxDate = new Date(); // Allow user to select today
    // maxDate.setDate(maxDate.getDate() - 1);

    const submit = () => {
      setRvdData([]);
      setEventData([]);
      setVehicleData([]);
      setSelectedRvd({});
      setIsLoadingRvd(true);

      if (query) {
        query.cancel();
        setQuery(null);
      }

      const callback = () => setIsLoadingRvd(false);

      const utcStartTime = (0, _moment.default)(startTime).toISOString();
      const utcEndTime = (0, _moment.default)(endTime).toISOString();
      submitDateTime(utcStartTime, utcEndTime, timezone, token, providers, notifications, searchMode, callback);
    };

    _react.default.useEffect(() => {
      if (startTime && endTime && (0, _moment.default)(endTime).isSameOrBefore((0, _moment.default)(startTime))) {
        const _endTime = (0, _moment.default)(startTime).add(59, 'seconds').toISOString();

        setEndTime(_endTime);
      }
    }, [startTime]);

    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: "form-panel-header"
    }, _react.default.createElement("span", {
      className: "form-panel-header-caret"
    }, _react.default.createElement("svg", {
      width: "16",
      height: "16",
      viewBox: "0 0 16 16",
      xmlns: "http://www.w3.org/2000/svg",
      className: "icon-block"
    }, _react.default.createElement("title", null, "Combined Shape Copy"), _react.default.createElement("path", {
      d: "M7.992 0C12.416 0 16 3.584 16 8s-3.584 8-8.008 8C3.576 16 0 12.416 0 8s3.576-8 7.992-8zM8 1.6A6.398 6.398 0 001.6 8c0 3.536 2.864 6.4 6.4 6.4 3.536 0 6.4-2.864 6.4-6.4 0-3.536-2.864-6.4-6.4-6.4zM8.4 4v4.2l3.6 2.136-.6.984L7.2 8.8V4h1.2z",
      fill: "#303E44",
      fillRule: "evenodd"
    }))), _react.default.createElement("h2", {
      className: "form-panel-title "
    }, "Select Date and Time range")), _react.default.createElement("div", {
      className: "form-panel-body"
    }, _react.default.createElement("table", {
      className: "form-panel-form mb-0"
    }, _react.default.createElement("tbody", null, _react.default.createElement("tr", null, _react.default.createElement("td", {
      className: "td:first-child left"
    }, "Date"), _react.default.createElement("td", null, _react.default.createElement(IqFlatPickr, {
      placeholder: "MM/DD/YYYY",
      className: "virtualized_select",
      onChange: selectedDates => setDate(selectedDates[0]),
      options: {
        maxDate,
        dateFormat: `${_environment.default.dateTimeFormat.dateFlatPickr}`
      },
      value: date
    }))), _react.default.createElement("tr", null, _react.default.createElement("td", {
      className: "td:first-child left"
    }, "Start Time"), _react.default.createElement("td", null, _react.default.createElement(IqFlatPickr, {
      key: date,
      onChange: times => setStartTime(times[0]),
      placeholder: "HH:MM",
      options: {
        minuteIncrement: 1,
        allowInput: false,
        noCalendar: true,
        // eslint-disable-next-line camelcase
        time_24hr: true,
        defaultHour: 0,
        placeholder: 'HH:MM',
        enableTime: true,
        dateFormat: `${_environment.default.dateTimeFormat.timeFlatPickr}`,
        defaultDate: date
      },
      value: startTime
    }))), _react.default.createElement("tr", null, _react.default.createElement("td", {
      className: "td:first-child left"
    }, "End Time"), _react.default.createElement("td", null, _react.default.createElement(IqFlatPickr, {
      key: date,
      onChange: times => {
        const _endTime = (0, _moment.default)(times[0]).add(59, 'seconds').toISOString();

        setEndTime(_endTime);
      },
      placeholder: "HH:MM",
      options: {
        locale: 'en',
        minuteIncrement: 1,
        allowInput: false,
        noCalendar: true,
        // eslint-disable-next-line camelcase
        time_24hr: true,
        defaultHour: 0,
        placeholder: 'HH:MM',
        enableTime: true,
        dateFormat: `${_environment.default.dateTimeFormat.timeFlatPickr}`,
        defaultDate: date,
        minDate: startTime
      },
      value: endTime
    })))))), _react.default.createElement("div", {
      className: "form-row mt-4"
    }, _react.default.createElement("div", {
      className: "btn-group"
    }, _react.default.createElement("button", {
      type: "button",
      className: "btn default",
      disabled: !canSubmit,
      onClick: () => submit()
    }, "Submit Date/Time"))));
  };

  var _default = DatetimePicker;
  _exports.default = _default;
});