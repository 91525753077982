define("adept-iq/classes/rules/eligibility-rules/taxi-allowed-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type"], function (_exports, _baseRule, _ruleResultType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TaxiAllowedRule = void 0;
  const TAXI_SERVICE_MODE = 'taxi';

  class TaxiAllowedRule extends _baseRule.default {
    doEvaluate(ruleContext) {
      const {
        bookingService
      } = ruleContext;
      const selectedRiders = bookingService.get('selectedRiders');
      const taxiAllowed = selectedRiders.every(this.checkTaxiAllowed);
      if (taxiAllowed) return _ruleResultType.RESULT_TYPE_YES;
      return _ruleResultType.RESULT_TYPE_NO;
    }

    checkTaxiAllowed(riderExternal) {
      const allowedServiceModes = riderExternal.get('eligibility.allowedServiceModes');
      return allowedServiceModes.includes(TAXI_SERVICE_MODE);
    }

  }

  _exports.TaxiAllowedRule = TaxiAllowedRule;
  TaxiAllowedRule.RULE_NAME = 'TaxiAllowedRule';
});