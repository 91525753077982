define("adept-iq/pods/components/iq-widgets/users-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSectionsAdd: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'id',
        editable: false
      }]
    }, {
      title: 'Add User',
      fields: [{
        id: 'firstName',
        type: 'text',
        label: 'First Name',
        editable: true,
        valuePath: 'firstName'
      }, {
        id: 'lastName',
        type: 'text',
        label: 'Last Name',
        editable: true,
        valuePath: 'lastName'
      }, {
        id: 'email',
        type: 'text',
        label: 'Email',
        editable: true,
        valuePath: 'email'
      }, {
        id: 'status',
        type: 'enum',
        label: 'Status',
        valuePath: 'calculatedStatus',
        extra: {
          options: ['active', 'inactive']
        }
      }, {
        id: 'role',
        type: 'searchable-enum',
        label: 'Role',
        valuePath: 'roleNames',
        editable: true,
        extra: {
          optionModelName: 'sso-role',
          optionSearchPath: 'displayName',
          optionSelectedPath: 'firstObject',
          optionIncludes: '',
          optionSearchEnabled: false
        }
      }]
    }],
    editableSectionsEdit: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'id',
        editable: false
      }]
    }, {
      title: 'Edit User',
      fields: [{
        id: 'firstName',
        type: 'text',
        label: 'First Name',
        editable: false,
        valuePath: 'firstName'
      }, {
        id: 'lastName',
        type: 'text',
        label: 'Last Name',
        editable: false,
        valuePath: 'lastName'
      }, {
        id: 'email',
        type: 'text',
        label: 'Email',
        editable: false,
        valuePath: 'email'
      }, {
        id: 'status',
        type: 'enum',
        label: 'Status',
        valuePath: 'calculatedStatus',
        extra: {
          options: ['active', 'inactive']
        }
      }, {
        id: 'role',
        type: 'searchable-enum',
        label: 'Role',
        valuePath: 'roleNames',
        editable: true,
        extra: {
          optionModelName: 'sso-role',
          optionSearchPath: 'displayName',
          optionSelectedPath: 'firstObject',
          optionIncludes: '',
          optionSearchEnabled: false
        }
      }]
    }]
  };
  _exports.default = _default;
});