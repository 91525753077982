define("adept-iq/pods/components/form-components/on-board-trips/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-onboard'],
    replacementMoveAllTripsPath: Ember.computed.readOnly('extra.replacementMoveAllTripsPath'),

    init() {
      this._super(...arguments);
    },

    actions: {
      onCheckboxChange(record, valuePath) {
        const useOptionRecord = this.get('useOptionRecord');
        let switchState = record.get(valuePath);
        const options = {
          record,
          valuePath,
          useOptionRecord
        };
        switchState = !switchState;
        this.set('record.isForceDirty', true);
        this.get('onChange')(switchState, options);
      }

    }
  });

  _exports.default = _default;
});