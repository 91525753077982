define("adept-iq/pods/components/form-components/permission/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-permission'],
    value: null,
    disabled: false,
    extra: null,
    store: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    toggelAll: false,
    category: Ember.computed.readOnly('extra.category'),
    permissionsPath: Ember.computed.alias('extra.permissionsPath'),
    useOptionRecord: Ember.computed.readOnly('extra.useOptionRecord'),
    rolePermIds: Ember.computed.readOnly('extra.rolePermIds'),
    permissions: null,

    init() {
      const groupedPermissions = this.get('record.groupedPermissions') || [];
      const category = this.get('category') || [];
      const permissionHash = this.get('record.permissionHash') || [];
      groupedPermissions[category].forEach(perm => {
        if (!permissionHash) {
          perm.set('state', false);
        } else if (permissionHash[perm.get('id')] || perm.get('state')) {
          perm.set('state', true);
        } else perm.set('state', false);
      });
      this.set('permissions', groupedPermissions[category]);

      this._super(...arguments);
    },

    actions: {
      onCheckboxChange(record, valuePath) {
        let switchState = record.state;
        const useOptionRecord = this.get('useOptionRecord');
        const options = {
          record,
          valuePath,
          useOptionRecord
        };
        switchState = !switchState;
        this.set('record.isForceDirty', true);
        this.get('onChange')(switchState, options);
      },

      onToggelAll(checkbox, records, valuePath) {
        const toggel = !checkbox;

        if (!Ember.isEmpty(records)) {
          records.forEach(record => {
            const useOptionRecord = this.get('useOptionRecord');
            const options = {
              record,
              valuePath,
              useOptionRecord
            };
            this.set('record.isForceDirty', true);
            this.get('onChange')(toggel, options);
          });
        }
      }

    }
  });

  _exports.default = _default;
});