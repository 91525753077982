define("adept-iq/classes/polylines/routes/navigation/iq", ["exports", "adept-iq/classes/polylines/routes/navigation/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // `BasePolyline` overrides
    isActive: true,
    points: Ember.computed.readOnly('iqRoute.navigationPolyline.coords'),
    // local properties
    record: null,
    iqRoute: Ember.computed.alias('record'),
    bemapCoords: Ember.computed('points', function () {
      const points = this.get('points') || [];
      return points.map(point => {
        return {
          lat: point[0],
          lon: point[1]
        };
      });
    })
  });

  _exports.default = _default;
});