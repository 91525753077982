define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel/searchByVehicle", ["exports", "react", "react-playback-components-library", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/hooks/usePrevious"], function (_exports, _react, _reactPlaybackComponentsLibrary, _api, _panel, _usePrevious) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IqSelect = _reactPlaybackComponentsLibrary.default.IqSelect;

  const SearchByVehicle = () => {
    const {
      rvdData
    } = (0, _api.useAPI)();
    const {
      setSelectedRvd
    } = (0, _panel.usePanel)();

    const [providerOptions, setProviderOptions] = _react.default.useState([]);

    const [vehicleOptions, setVehicleOptions] = _react.default.useState([]);

    const [selectedProvider, setSelectedProvider] = _react.default.useState(null);

    const [selectedVehicle, setSelectedVehicle] = _react.default.useState(null);

    const prevStates = (0, _usePrevious.default)({
      selectedProvider
    });
    const providers = new Set();
    rvdData.forEach(rvd => {
      if (rvd.vehicle && rvd.driver && rvd.route) {
        if (rvd.vehicle.providername) {
          providers.add(rvd.vehicle.providername);
        }
      }
    });

    const _providerOptions = [...providers].reduce((options, provider) => {
      options.push({
        value: provider,
        label: provider
      });
      return options;
    }, []);

    const _vehicleOptions = [...rvdData].reduce((options, rvd) => {
      options.push({
        value: `${rvd.vehicleid}-${rvd.routeid}`,
        label: `${rvd.route.trackingid} / ${rvd.vehicle.callsign} / ${rvd.driver.badgenumber}`,
        vehicleId: rvd.vehicleid,
        vehicleName: rvd.vehicle.callsign,
        driverId: rvd.driverid,
        badgenumber: rvd.driver.badgenumber,
        routeId: rvd.routeid,
        routeName: rvd.route.trackingid,
        provider: rvd.vehicle.providername
      });
      return options;
    }, []);

    const resetAll = () => {
      setProviderOptions(_providerOptions);
      setVehicleOptions(_vehicleOptions);
      setSelectedProvider(null);
      setSelectedVehicle(null);
      setSelectedRvd({});
    };

    _react.default.useEffect(() => {
      resetAll();
    }, [rvdData]);

    _react.default.useEffect(() => {
      if (!selectedProvider) return;
      setVehicleOptions(_vehicleOptions.filter(o => !selectedProvider || o.provider === selectedProvider.value)); // Avoid circular updates, only clear up selections when prevStates provider is not matching

      if (prevStates && prevStates.selectedProvider && prevStates.selectedProvider.value && prevStates.selectedProvider.value !== selectedProvider.value) {
        setSelectedVehicle(null);
      }
    }, [selectedProvider]);

    _react.default.useEffect(() => {
      if (!selectedVehicle) {
        setSelectedRvd({});
        return;
      }

      setSelectedRvd(selectedVehicle);

      if (selectedVehicle.value) {
        const _selectedProvider = _providerOptions.find(o => o.value === selectedVehicle.provider);

        setSelectedProvider(_selectedProvider);
      }
    }, [selectedVehicle]);

    return _react.default.createElement("fieldset", {
      disabled: rvdData.length === 0
    }, _react.default.createElement("div", {
      className: "form-panel-header"
    }, _react.default.createElement("span", {
      className: "form-panel-header-caret"
    }, _react.default.createElement("svg", {
      width: "15",
      height: "13",
      viewBox: "0 0 15 13",
      xmlns: "http://www.w3.org/2000/svg",
      className: "icon-block"
    }, _react.default.createElement("title", null, "Shape Copy"), _react.default.createElement("path", {
      d: "M12.736.808A1.195 1.195 0 0011.6 0H2.8c-.528 0-.968.336-1.136.808L0 5.6V12c0 .44.36.8.8.8h.8c.44 0 .8-.36.8-.8v-.8H12v.8c0 .44.36.8.8.8h.8c.44 0 .8-.36.8-.8V5.6L12.736.808zM2.8 8.8c-.664 0-1.2-.536-1.2-1.2 0-.664.536-1.2 1.2-1.2.664 0 1.2.536 1.2 1.2 0 .664-.536 1.2-1.2 1.2zm8.8 0c-.664 0-1.2-.536-1.2-1.2 0-.664.536-1.2 1.2-1.2.664 0 1.2.536 1.2 1.2 0 .664-.536 1.2-1.2 1.2zm-10-4l1.2-3.6h8.8l1.2 3.6H1.6z",
      fill: "#303E44",
      fillRule: "evenodd"
    }))), _react.default.createElement("h2", {
      className: "form-panel-title"
    }, "Select Vehicle")), _react.default.createElement("div", {
      className: "form-panel-body"
    }, _react.default.createElement("table", {
      className: "form-panel-form mb-0"
    }, _react.default.createElement("tbody", null, _react.default.createElement("tr", null, _react.default.createElement("td", {
      className: "td:first-child left"
    }, "Provider"), _react.default.createElement("td", null, _react.default.createElement(IqSelect, {
      className: "virtualized_select",
      optionHeight: () => ({
        option: {
          height: '20px'
        }
      }),
      theme: theme => ({ ...theme,
        colors: { ...theme.colors,
          primary25: '#689f38'
        }
      }),
      onChange: option => {
        option.style = {
          background: 'green'
        };
        setSelectedProvider(option);
      },
      style: {
        background: 'red'
      },
      value: selectedProvider,
      placeholder: "Select Provider",
      options: providerOptions
    }))))), _react.default.createElement("br", null), _react.default.createElement("span", null, "Route / Vehicle / Driver"), _react.default.createElement(IqSelect, {
      className: "virtualized_select_rvd",
      onChange: option => {
        setSelectedVehicle(option);
      },
      theme: theme => ({ ...theme,
        colors: { ...theme.colors,
          primary25: '#689f38'
        }
      }),
      value: selectedVehicle,
      placeholder: "Select Route / Vehicle / Driver",
      filterOption: (option, filter) => option.label.includes(filter),
      options: vehicleOptions
    }), _react.default.createElement("br", null), _react.default.createElement("span", null, "Selected Vehicle"), _react.default.createElement("div", null, _react.default.createElement("input", {
      disabled: true,
      value: selectedVehicle?.vehicleName || ''
    }))));
  };

  var _default = SearchByVehicle;
  _exports.default = _default;
});