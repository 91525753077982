define("adept-iq/adapters/etanav-route", ["exports", "adept-iq/adapters/-iqux-iquxService", "adept-iq/config/api-urls"], function (_exports, _iquxIquxService, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _iquxIquxService.default.extend({
    ajaxService: Ember.inject.service('ajax'),

    async fetchUnperformedStopPoints(routeId) {
      const session = this.get('session');
      const url = `${_apiUrls.API.etaService.host}/route/${routeId}`;
      let stopPoints = [];

      try {
        const response = await this.get('ajaxService').request(url, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${session.data.authenticated.token}`
          }
        });

        if (response.data.attributes.stopPoints) {
          stopPoints = stopPoints.concat(response.data.attributes.stopPoints.filter(s => !s.id.startsWith('G') && s.status === 'pending'));
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      return stopPoints;
    }

  });

  _exports.default = _default;
});