define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const header = 'iq-widgets/reconcile-search-mode-pop-up/table-header';
  var _default = {
    title: 'Reconcile',
    component: 'iq-widgets/search-mode-pop-up/',
    addressTableColumns: [{
      width: '40px',
      resizable: false,
      cellComponent: 'table/cells/radio-button',
      valuePath: 'none'
    }, {
      label: 'Trip Date',
      valuePath: 'tripDate',
      width: '100px',
      resizable: true,
      minResizeWidth: 100,
      component: header
    }, {
      label: 'Trip ID',
      valuePath: 'tripId',
      width: '60px',
      resizable: true,
      minResizeWidth: 60,
      component: header
    }, {
      label: 'Status',
      valuePath: 'status',
      width: '80px',
      resizable: true,
      minResizeWidth: 80,
      component: header
    }, {
      label: 'Client Name',
      valuePath: 'passenger.fullName',
      width: '147px',
      resizable: true,
      minResizeWidth: 147,
      component: header
    }, {
      label: 'Pick Time',
      valuePath: 'actualDepartTime',
      dataType: 'time',
      format: 'hh:mm A',
      width: '80px',
      resizable: true,
      minResizeWidth: 80,
      component: header
    }, {
      label: 'Drop Time',
      valuePath: 'actualArrivalTime',
      dataType: 'time',
      format: 'hh:mm A',
      width: '80px',
      resizable: true,
      minResizeWidth: 80,
      component: header
    }, {
      label: 'Pick Address',
      valuePath: 'stopDetails.pick.address.formattedAddress',
      width: '270px',
      resizable: true,
      minResizeWidth: 279,
      component: header
    }, {
      label: 'Drop Address',
      valuePath: 'stopDetails.drop.address.formattedAddress',
      width: '290px',
      resizable: true,
      minResizeWidth: 290,
      component: header
    }, {
      label: 'Provider ID',
      valuePath: 'route.providerName',
      width: '100px',
      resizable: true,
      minResizeWidth: 100,
      component: header
    }, {
      label: 'Route ID',
      valuePath: 'routeid',
      width: '100px',
      resizable: true,
      minResizeWidth: 100,
      component: header
    }],
    clientTableColumns: [{
      width: '40px',
      resizable: false,
      cellComponent: 'table/cells/radio-button',
      valuePath: 'none'
    }, {
      label: 'ID',
      valuePath: 'riderId',
      resizable: true,
      // width: '150px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Last Name',
      valuePath: 'passengerLastName',
      resizable: true,
      // width: '170px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'First Name',
      valuePath: 'passengerFirstName',
      resizable: true,
      // width: '170px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Phone',
      valuePath: 'phoneNumber',
      resizable: true,
      // width: '150px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Address',
      valuePath: 'stopAddress',
      resizable: true,
      // width: '278px',
      minResizeWidth: 50,
      component: header
    }],
    driverAndVehicleTableColumns: [{
      width: '40px',
      resizable: false,
      cellComponent: 'table/cells/radio-button',
      valuePath: 'none'
    }, {
      label: 'Provider ID',
      valuePath: 'providerName',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Trip Date',
      valuePath: 'tripDate',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Zone',
      valuePath: 'start.address.zone',
      resizable: true,
      width: '120px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Route ID',
      valuePath: 'routeid',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Manual Trips Only',
      valuePath: 'manualTripsOnly',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Driver ID',
      valuePath: 'driverId',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Vehicle ID',
      valuePath: 'vehicleId',
      resizable: true,
      minResizeWidth: 50,
      width: '85px',
      component: header
    }, {
      label: 'Driver Start Time',
      valuePath: 'actualStartTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      minResizeWidth: 50,
      width: '115px',
      component: header
    }, {
      label: 'Driver End Time',
      valuePath: 'actualEndTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '115px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Shift',
      valuePath: 'shiftLength',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Original Shift Start Time',
      valuePath: 'formattedActualStartTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '115px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Shift Start Time',
      valuePath: 'plannedStartTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '115px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Original Shift End Time',
      valuePath: 'formattedActualEndTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '115px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Shift End Time',
      valuePath: 'plannedEndTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '115px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Dead Head Miles',
      valuePath: 'deadHeadMiles',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Dead Head Time',
      valuePath: 'deadHeadTime',
      resizable: true,
      width: '115px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Revenue Miles',
      valuePath: 'revenueMiles',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Revenue Time',
      valuePath: 'revenueTime',
      resizable: true,
      width: '115px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Total Time',
      valuePath: 'totalTime',
      resizable: true,
      width: '115px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start Mileage',
      valuePath: 'odometerStart',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End Mileage',
      valuePath: 'odometerEnd',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start Places ID',
      valuePath: 'startPlacesID',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'County',
      valuePath: 'county',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      hidden: true,
      component: header
    }, {
      label: 'Logged On',
      valuePath: 'loggedOn',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Status',
      valuePath: 'formattedStatus',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Run Status',
      valuePath: 'runStatus',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Notes',
      valuePath: 'notes',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Last Update',
      valuePath: 'lastUpdate',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Status Date',
      valuePath: 'statusDate',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start Description',
      valuePath: 'start.address.identifier',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start House Number',
      valuePath: 'startHouseNumber',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start Address1',
      valuePath: 'start.address.formattedAddress',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start Address2',
      valuePath: 'startAddress2',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start State',
      valuePath: 'startState',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start ZIP',
      valuePath: 'startZIP',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start Zone ID',
      valuePath: 'start.address.zone',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start River Region',
      valuePath: 'startRiverRegion',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start Country',
      valuePath: 'startCountry',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start Directions',
      valuePath: 'startDirections',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start GridX',
      valuePath: 'startGridX',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start GridY',
      valuePath: 'startGridY',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start Method',
      valuePath: 'startMethod',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start Map Page',
      valuePath: 'startMapPage',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start Mail',
      valuePath: 'startMail',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Start Phone',
      valuePath: 'driver.phoneNumber',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End Places ID',
      valuePath: 'endPlacesID',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End Description',
      valuePath: 'end.address.identifier',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End House Number',
      valuePath: 'endHouseNumber',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End Address1',
      valuePath: 'end.address.formattedAddress',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End Address2',
      valuePath: 'endAddress2',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End City',
      valuePath: 'endCity',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End State',
      valuePath: 'endState',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End ZIP',
      valuePath: 'endZIP',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End Zone ID',
      valuePath: 'end.address.zone',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End River Region',
      valuePath: 'endRiverRegion',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End County',
      valuePath: 'endCounty',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End Directions',
      valuePath: 'endDirections',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End GridX',
      valuePath: 'endGridX',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End GridY',
      valuePath: 'endGridY',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End Method',
      valuePath: 'endMethod',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End Mail',
      valuePath: 'endMail',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End Map Page',
      valuePath: 'endMapPage',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'End Phone',
      valuePath: 'driver.phoneNumber',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Route Type ID',
      valuePath: 'routeTypeId',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Breakdown Time',
      valuePath: 'formattedBreakdownTime',
      resizable: true,
      width: '115px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Keeper',
      valuePath: 'keeper',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Trip Limit Per Hour',
      valuePath: 'tripLimit',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Mile Limit Per Hour',
      valuePath: 'maxMilesPerHour',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Startegy',
      valuePath: 'startegy',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Driver Phone Number',
      valuePath: 'driver.phoneNumber',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Version',
      valuePath: 'version',
      resizable: true,
      width: '80px',
      hidden: true,
      minResizeWidth: 50,
      component: header
    }],
    tripTableColumns: [{
      width: '40px',
      resizable: false,
      cellComponent: 'table/cells/radio-button',
      valuePath: 'none'
    }, {
      label: 'Trip ID',
      valuePath: 'tripId',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Group ID',
      valuePath: 'groupId',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Subscription ID',
      valuePath: 'SubscriptionId',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Client ID',
      valuePath: 'passenger.id',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Status',
      valuePath: 'status',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Trip State',
      valuePath: 'formattedStatus',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Trip to Work ID',
      valuePath: 'triptoWorkId',
      resizable: true,
      minResizeWidth: 50,
      width: '85px',
      component: header
    }, {
      label: 'Trip Date',
      valuePath: 'tripDate',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Zone',
      valuePath: 'stopDetails.pick.address.zone',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Anchor',
      valuePath: 'anchor',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Request Time',
      valuePath: 'formattedRequestTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Promise Time',
      valuePath: 'promisedTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Service Window ID',
      valuePath: 'serviceWindowId',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Previous Route ID',
      valuePath: 'previousRouteId',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Route ID',
      valuePath: 'routeid',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Time',
      valuePath: 'formattedPickupPromiseTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Time',
      valuePath: 'formattedDropoffPromiseTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Original Pick Time',
      valuePath: 'formattedPickupActualArriveTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Original Drop Time',
      valuePath: 'formattedDropoffActualArriveTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Perform Pick up',
      valuePath: 'performedPickup',
      //stopDetails.pick.performed
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Perform Drop off',
      valuePath: 'performedDropoff',
      // stopDetails.drop.performed
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Callback',
      valuePath: 'callBack',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Equipment',
      valuePath: 'equipment',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Amb Seats',
      valuePath: 'travelRequirements.ambulatory',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'WC Seats',
      valuePath: 'travelRequirements.wheelChair',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'XWide',
      valuePath: 'travelRequirements.wideWheelChair',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Vehicle Type ID',
      valuePath: 'vehicleTypeId',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Load Time',
      valuePath: 'travelRequirements.loadTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Unload Time',
      valuePath: 'travelRequirements.unloadTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Companions',
      valuePath: 'travelRequirements.companion',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'PCA',
      valuePath: 'travelRequirements.pca',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Service Animals',
      valuePath: 'travelRequirements.serviceAnimal',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Address',
      valuePath: 'stopDetails.pick.address.formattedAddress',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Zone ID',
      valuePath: 'stopDetails.pick.address.zone',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick GridX',
      valuePath: 'pickGridX',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick GridY',
      valuePath: 'pickGridY',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Address',
      valuePath: 'stopDetails.drop.address.formattedAddress',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Zone ID',
      valuePath: 'stopDetails.drop.address.zone',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop GridX',
      valuePath: 'dropGridX',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop GridY',
      valuePath: 'dropGridY',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Provider ID',
      valuePath: 'route.providerName',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Fare',
      valuePath: 'fare',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Wait Rate',
      valuePath: 'waitRate',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Mile Rate',
      valuePath: 'mileRate',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'PCount',
      valuePath: 'pCount',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'DCount',
      valuePath: 'dCount',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Travel Direction',
      valuePath: 'Pick Travel Direction',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Travel Direction',
      valuePath: 'dropTravelDirection',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pmiles',
      valuePath: 'pMiles',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pmins',
      valuePath: 'pMins',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Dmiles',
      valuePath: 'dMiles',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Dmins',
      valuePath: 'dMins',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pwait',
      valuePath: 'pWait',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Dwait',
      valuePath: 'dWait',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pgap',
      valuePath: 'pGap',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Dgap',
      valuePath: 'dGap',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'PTNX',
      valuePath: 'pTNX',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'DTNX',
      valuePath: 'dTNX',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Trip Time',
      valuePath: 'tripTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Trip Miles',
      valuePath: 'tripMiles',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'AP Time',
      valuePath: 'formattedPickupActualDepartTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'AP Time1',
      valuePath: 'formattedPickupActualArriveTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'AD Time',
      valuePath: 'formattedDropoffActualDepartTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'AD Time1',
      valuePath: 'formattedDropoffActualArriveTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'AP Miles',
      valuePath: 'stopDetails.pick.odometerArrival',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'AD Miles',
      valuePath: 'stopDetails.drop.odometerArrival',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Wait Time',
      valuePath: 'waitTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'PComments',
      valuePath: 'pComments',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'DComments',
      valuePath: 'dComments',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Same Day Change',
      valuePath: 'sameDayChange',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Status',
      valuePath: 'stopDetails.pick.otpStatus',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Status',
      valuePath: 'stopDetails.drop.otpStatus',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Order',
      valuePath: 'stopDetails.pick.ordinal',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Order',
      valuePath: 'stopDetails.drop.ordinal',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Charter Minutes',
      valuePath: 'charterMinutes',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Create Date',
      valuePath: 'formattedCreatedDate',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Scheduled Pick up',
      valuePath: 'scheduledPickup',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Scheduled Drop off',
      valuePath: 'scheduledDropOff',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Booking Leg ID',
      valuePath: 'bookingLegID',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Segment Order',
      valuePath: 'segmentOrder',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Itinerary ID',
      valuePath: 'itineraryID',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Travel Mode ID',
      valuePath: 'travelModeID',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Broker Eligible',
      valuePath: 'brokerEligible',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Fare Type',
      valuePath: 'price.type',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Arrive Type',
      valuePath: 'stopDetails.pick.arriveType',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Arrive Type',
      valuePath: 'stopDetails.drop.arriveType',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }],
    clientTableColumnsSearch: [{
      width: '40px',
      resizable: false,
      cellComponent: 'table/cells/radio-button',
      valuePath: 'none'
    }, {
      label: 'Trip Id',
      valuePath: 'tripId',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Group ID',
      valuePath: 'groupId',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Subscription ID',
      valuePath: 'subscriptionId',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Client ID',
      valuePath: 'riderId',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Status',
      valuePath: 'status',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Trip State',
      valuePath: 'formattedStatus',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Master Trip',
      valuePath: 'masterTrip',
      resizable: true,
      minResizeWidth: 50,
      width: '85px',
      component: header
    }, {
      label: 'Trip to Work ID',
      valuePath: 'externalTripId',
      resizable: true,
      minResizeWidth: 50,
      width: '85px',
      component: header
    }, {
      label: 'Non Stop',
      valuePath: 'nonStop',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Trip Date',
      valuePath: 'tripDate',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Trip Mode ID',
      valuePath: 'tripModeId',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Zone',
      valuePath: 'stopDetails.pick.address.zone',
      resizable: true,
      width: '85px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Anchor',
      valuePath: 'anchor',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Request Time',
      valuePath: 'formattedRequestTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Promise Time',
      valuePath: 'formattedPromiseTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Service Window ID',
      valuePath: 'serviceWindowId',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Trip Type',
      valuePath: 'type',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Previous Route ID',
      valuePath: 'previousRouteId',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Route ID',
      valuePath: 'routeid',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Time',
      valuePath: 'serviceStartTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Time',
      valuePath: 'serviceEndTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Original Pick Time',
      valuePath: 'serviceStartTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Original Drop Time',
      valuePath: 'serviceEndTime',
      dataType: 'time',
      format: 'hh:mm A',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Perform Pick up',
      valuePath: 'performedPickup',
      // stopDetails.pick.performed
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Perform Drop off',
      valuePath: 'performedDropoff',
      // stopDetails.drop.performed
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Callback',
      valuePath: 'callBack',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Equipment',
      valuePath: 'equipment',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Amb Seats',
      valuePath: 'travelRequirements.ambulatory',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'WC Seats',
      valuePath: 'travelRequirements.wheelChair',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'XWide',
      valuePath: 'travelRequirements.wideWheelChair',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Vehicle Type ID',
      valuePath: 'vehicleTypeId',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Load Time',
      valuePath: 'travelRequirements.loadTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Unload Time',
      valuePath: 'travelRequirements.unloadTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Clients',
      valuePath: 'clients',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Companions',
      valuePath: 'travelRequirements.companion',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'PCA',
      valuePath: 'travelRequirements.pca',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Service Animals',
      valuePath: 'travelRequirements.serviceAnimal',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Break Type ID',
      valuePath: 'breakTypeId',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Description',
      valuePath: 'pickDescription',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick House Number',
      valuePath: 'pickHouseNumber',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Address1',
      valuePath: 'stopDetails.pick.address.formattedAddress',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Address2',
      valuePath: 'pickAddress2',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick City',
      valuePath: 'pickCity',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick State',
      valuePath: 'pickStatee',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick ZIP',
      valuePath: 'pickZIP',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Zone ID',
      valuePath: 'stopDetails.pick.address.zone',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick River Region',
      valuePath: 'pickRiverRegion',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick County',
      valuePath: 'pickCounty',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Directions',
      valuePath: 'pickDirections',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick GridX',
      valuePath: 'pickGridX',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick GridY',
      valuePath: 'pickGridY',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Method',
      valuePath: 'pickMethod',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Map Page',
      valuePath: 'pickMapPage',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Phone',
      valuePath: 'pickPhone',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Description',
      valuePath: 'dropDescription',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Address1',
      valuePath: 'stopDetails.drop.address.formattedAddress',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Address2',
      valuePath: 'dropAddress2',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop City',
      valuePath: 'dropCity',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop State',
      valuePath: 'dropState',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop ZIP',
      valuePath: 'dropZIP',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Zone ID',
      valuePath: 'stopDetails.drop.address.zone',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop River Region',
      valuePath: 'dropRiverRegion',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop County',
      valuePath: 'dropCounty',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Directions',
      valuePath: 'dropDirections',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop GridX',
      valuePath: 'dropGridX',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop GridY',
      valuePath: 'dropGridY',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Method',
      valuePath: 'dropMethod',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Map Page',
      valuePath: 'dropMapPage',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Phone',
      valuePath: 'dropPhone',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Trip Purpose ID',
      valuePath: 'tripPurposeID',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Agency',
      valuePath: 'Agency',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Agency2',
      valuePath: 'agency2',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Bill Code',
      valuePath: 'billCode',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Provider ID',
      valuePath: 'route.providerName',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Fare',
      valuePath: 'price.fare',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Fare Collected',
      valuePath: 'fareCollected',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pass',
      valuePath: 'pass',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Contract',
      valuePath: 'contract',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Wait Rate',
      valuePath: 'waitRate',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Mile Rate',
      valuePath: 'mileRate',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'PCount',
      valuePath: 'pCount',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'DCount',
      valuePath: 'dCount',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Travel Direction',
      valuePath: 'pickTravelDirection',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Travel Direction',
      valuePath: 'dropTravelDirection',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pmiles',
      valuePath: 'pMiles',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pmins',
      valuePath: 'pMins',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Dmiles',
      valuePath: 'dMiles',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Dmins',
      valuePath: 'dMins',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pwait',
      valuePath: 'pWait',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Dwait',
      valuePath: 'dWait',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pgap',
      valuePath: 'pGap',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Dgap',
      valuePath: 'dGap',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'PTNX',
      valuePath: 'pTNX',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'DTNX',
      valuePath: 'dTNX',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Trip Time',
      valuePath: 'tripTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'AD Miles',
      valuePath: 'stopDetails.drop.odometerArrival',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'AP Miles',
      valuePath: 'stopDetails.pick.odometerArrival',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Trip Miles',
      valuePath: 'tripMiles',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'AP Time',
      valuePath: 'formattedPickupActualDepartTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'AD Time',
      valuePath: 'formattedDropoffActualDepartTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'AD Time1',
      valuePath: 'formattedDropoffActualArriveTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Wait Time',
      valuePath: 'waitTime',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'PComments',
      valuePath: 'pComments',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'DComments',
      valuePath: 'dComments',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Same Day Change',
      valuePath: 'sameDayChange',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Status',
      valuePath: 'stopDetails.pick.otpStatus',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Status',
      valuePath: 'stopDetails.drop.otpStatus',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Order',
      valuePath: 'stopDetails.pick.ordinal',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Order',
      valuePath: 'stopDetails.drop.ordinal',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Charter Minutes',
      valuePath: 'charterMinutes',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Create Date',
      valuePath: 'formattedCreatedDate',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Last Update',
      valuePath: 'lastUpdate',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Scheduled Pick up',
      valuePath: 'scheduledPickup',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Scheduled Drop off',
      valuePath: 'scheduledDropOff',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Booking Leg ID',
      valuePath: 'bookingLegID',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Segment Order',
      valuePath: 'segmentOrder',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Itinerary ID',
      valuePath: 'itineraryID',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Travel Mode ID',
      valuePath: 'travelModeID',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Version',
      valuePath: 'version',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Broker Eligible',
      valuePath: 'brokerEligible',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Fare Type',
      valuePath: 'price.type',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Pick Arrive Type',
      valuePath: 'stopDetails.pick.arriveType',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'Drop Arrive Type',
      valuePath: 'stopDetails.drop.arriveType',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }, {
      label: 'MultiSegment',
      valuePath: 'multiSegment',
      resizable: true,
      width: '80px',
      minResizeWidth: 50,
      component: header
    }],
    trip: {
      model: 'reconcile-trip',
      indexPath: 'tripId'
    },
    passenger: {
      model: 'reconcile-passenger',
      indexPath: 'passengerId'
    },
    driver: {
      model: 'reconcile-driver',
      indexPath: 'driverId'
    },
    vehicle: {
      model: 'reconcile-vehicle',
      indexPath: 'vehicleId'
    },
    stop: {
      model: 'reconcile-stop',
      indexPath: 'address.formattedAddress'
    },
    route: {
      model: 'reconcile-route',
      indexPath: 'routeId'
    }
  };
  _exports.default = _default;
});