define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/driver/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "moment", "lodash", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/driver/config", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/driver/validation", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _moment, _lodash, _config, _validation, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DRIVER_FIELDS = ['startDateDs870', 'seniorityDate', 'birthDate', 'peDate1', 'peDate2'];
  const CDL_FIELDS = ['issueDate', 'expiryDate'];
  const ACTIVE_STATUS = 'active';
  const EVENT_DRIVER_TYPE_MAP = {
    addDriver: true,
    editDriver: true,
    deleteDriver: true
  };

  var _default = Ember.Component.extend({
    classNames: ['driver-view'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    addressConfig: _config.addressConfig,
    place: null,
    classificationOptions: null,
    providers: null,
    providerList: null,
    pendingDriverProvider: null,
    selectedProvider: null,
    // placeholders
    FIRST_NAME_PLACEHOLDER: _placeholders.FIRST_NAME_PLACEHOLDER,
    LAST_NAME_PLACEHOLDER: _placeholders.LAST_NAME_PLACEHOLDER,
    MIDDLE_INITIAL_PLACEHOLDER: _placeholders.MIDDLE_INITIAL_PLACEHOLDER,
    CDL_NUMBER_PLACEHOLDER: _placeholders.CDL_NUMBER_PLACEHOLDER,
    PROVIDER_PLACEHOLDER: _placeholders.PROVIDER_PLACEHOLDER,
    PHONE_NUMBER_EXT_PLACEHOLDER: _placeholders.PHONE_NUMBER_EXT_PLACEHOLDER,
    PHONE_NUMBER_PLACEHOLDER: _placeholders.PHONE_NUMBER_PLACEHOLDER,
    SOCIAL_SECURITY_NUMBER_PLACEHOLDER: _placeholders.SOCIAL_SECURITY_NUMBER_PLACEHOLDER,
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    DRIVER_ID_PLACEHOLDER: _placeholders.DRIVER_ID_PLACEHOLDER,
    // passed into view
    editAPI: null,
    selectedDriver: Ember.computed.readOnly('editAPI.selectedRecord'),
    currentDate: (0, _moment.default)().startOf('day').toDate(),

    async init() {
      this._super(...arguments);

      const store = this.get('store');
      const driver = this.get('selectedDriver');
      const driverId = driver.get('id');
      const driverAddress = driver.get('address');
      const location = store.createRecord('location');
      const place = store.createRecord('place', {
        address: driverAddress,
        location
      });
      this.set('place', place);

      if (Ember.isPresent(driverId)) {
        this.set('activityQueryParams', {
          driverId: `${driverId}`,
          eventLog: true
        });
        this.set('providerQueryParams', {
          filter: `eq(driverId,${driver.get('id')})`
        });
      } else {
        // skip query of activity log if it is a new record
        this.set('skipQuery', true);
      }

      this.get('refreshTask').perform();
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onSaveDriverRecord = this.get('onSaveDriverRecord');
      const onValidateAction = this.get('validateDriver'); // normal functions

      const onUndoDriverRecord = this.get('onUndoDriverRecord').bind(this);
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverRecord,
          newAction: null,
          deleteAction: null,
          editAction: null,
          undoAction: onUndoDriverRecord,
          hasChanges,
          onValidateAction,
          isChangesHappened: false
        });
        editAPI.buttonSettings(false, false, false, false, false);
      });
      this.addObserver('hasPendingChanges', this.hasPendingChangesObserver);
    },

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_DRIVER_TYPE_MAP[event.get('actionType')];
    },

    willDestroyElement() {
      this._super(...arguments);

      const place = (0, _unwrapProxy.unwrapProxy)(this.get('place'));
      const location = (0, _unwrapProxy.unwrapProxy)(this.get('place.location'));
      place.set('address', null);
      if (location) location.rollbackAttributes();

      if (place) {
        place.rollbackAttributes();
      }

      this.removeObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
    },

    driverSuspendedText: Ember.computed('driverChangeSet.driverSuspensions', function () {
      const driverSuspensions = this.get('driverChangeSet.driverSuspensions') || [];
      const suspensions = driverSuspensions.filter(suspension => suspension.get('isSuspended'));

      if (suspensions.length > 0) {
        return 'Driver IS suspended';
      }

      return 'Driver is NOT suspended';
    }),
    driverAmountOfViolationsText: Ember.computed('driverChangeSet.driverSuspensions', function () {
      const driverSuspensions = this.get('driverChangeSet.driverSuspensions') || [];
      const suspensions = driverSuspensions.filter(suspension => suspension.get('isSuspended'));
      const violationPts = suspensions.reduce((acu, suspension) => {
        const points = parseInt(suspension.get('driverViolation.points') || 0, 10);
        const sum = acu + points;
        return sum;
      }, 0);
      return `Driver has ${violationPts} points`;
    }),
    driverChangeSet: Ember.computed('selectedDriver', function () {
      const driver = (0, _unwrapProxy.unwrapProxy)(this.get('selectedDriver'));
      return this.setDefaultProperties(driver);
    }),
    fetchDriverProvider: (0, _emberConcurrency.task)(function* (driver) {
      const store = this.get('store');
      const selectedProviders = yield store.query('driver-provider', this.get('providerQueryParams'));
      const selectedProvider = selectedProviders.firstObject;

      if (selectedProvider) {
        this.set('pendingDriverProvider', selectedProvider);
      } else {
        this.set('pendingDriverProvider', store.createRecord('driver-provider', {
          driver
        }));
      }
    }).keepLatest(),
    driverPhoneChangeSet: Ember.computed('driverChangeSet', 'driverChangeSet.phoneNumbers.firstObject.areaCode', function () {
      const driverChangeSet = this.get('driverChangeSet');
      let driverPhone = (0, _unwrapProxy.unwrapProxy)(driverChangeSet.get('phoneNumbers.firstObject')); //fix NYAAR-18084 after destroying the driverPhone , we need to create a new driverPhone changeSet

      if (!driverPhone) {
        driverPhone = this.get('store').createRecord('driver-phone-number');
      }

      return new _emberChangeset.default(driverPhone, (0, _emberChangesetValidations.default)(_validation.DRIVER_PHONE_VALIDATION), _validation.DRIVER_PHONE_VALIDATION);
    }),
    driverEmergencyContactChangeSet: Ember.computed('driverChangeSet', function () {
      const driverChangeSet = this.get('driverChangeSet');
      const driverEmergencyContact = (0, _unwrapProxy.unwrapProxy)(driverChangeSet.get('emergencyContacts.firstObject'));
      return new _emberChangeset.default(driverEmergencyContact, (0, _emberChangesetValidations.default)(_validation.DRIVER_EMERGENCY_CONTACT_VALIDATION, _validation.DRIVER_EMERGENCY_CONTACT_VALIDATION));
    }),
    driverLicenseChangeSet: Ember.computed('driverChangeSet', function () {
      const driverChangeSet = this.get('driverChangeSet');
      const driverLicense = (0, _unwrapProxy.unwrapProxy)(driverChangeSet.get('license'));
      const defaulDate = new Date();

      if (driverChangeSet.get('isNew')) {
        driverLicense.setProperties({
          issueDate: defaulDate,
          expiryDate: defaulDate
        });
      }

      const driverLicenseChangeSet = new _emberChangeset.default(driverLicense, (0, _emberChangesetValidations.default)(_validation.DRIVER_LICENSE_VALIDATION), _validation.DRIVER_LICENSE_VALIDATION);
      return driverLicenseChangeSet;
    }),
    hasPendingChanges: Ember.computed('driverChangeSet.changes.length', 'driverEmergencyContactChangeSet.changes.length', 'driverPhoneChangeSet.changes.length', 'driverLicenseChangeSet.changes.length', function () {
      const {
        driverChangeSet,
        driverEmergencyContactChangeSet,
        driverPhoneChangeSet,
        driverLicenseChangeSet
      } = this.getProperties('driverChangeSet', 'driverEmergencyContactChangeSet', 'driverPhoneChangeSet', 'driverLicenseChangeSet');
      return [driverChangeSet, driverEmergencyContactChangeSet, driverLicenseChangeSet, driverPhoneChangeSet].reduce((acu, changeset) => {
        const changes = changeset.get('changes') || [];
        acu.changes.push(changes);
        acu.changeCount = acu.changeCount + changes.length;
        return acu;
      }, {
        changes: [],
        changeCount: 0
      });
    }),

    hasChanges() {
      const driverChangeSet = this.get('driverChangeSet');

      if (driverChangeSet.get('isNew')) {
        const totalFields = DRIVER_FIELDS.concat(CDL_FIELDS);
        const pendingChanges = this.get('hasPendingChanges');
        const fields = (0, _lodash.flatten)(pendingChanges.changes).map(change => change.key);
        const isEveyPrePoulatedFieldsChange = fields.length ? (0, _lodash.every)(fields, field => totalFields.includes(field)) : false;
        if (isEveyPrePoulatedFieldsChange) return !isEveyPrePoulatedFieldsChange;
        return !!this.get('hasPendingChanges.changeCount');
      }

      return !!this.get('hasPendingChanges.changeCount');
    },

    hasPendingChangesObserver() {
      const editAPI = this.get('editAPI');
      const hasPendingChanges = this.get('hasPendingChanges.changeCount');
      Ember.run.scheduleOnce('afterRender', () => {
        editAPI.setProperties({
          isChangesHappened: hasPendingChanges
        });
      });
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      let pendingDriverRecord = this.get('pendingDriverRecord');
      const defaultDate = new Date();
      const birthDate = null;

      if (record) {
        pendingDriverRecord = record;
        this.resetForm();
      } else if (!pendingDriverRecord) {
        pendingDriverRecord = store.createRecord('driver');
        this.set('pendingDriverRecord', pendingDriverRecord);
      }

      if (pendingDriverRecord.get('isNew')) {
        pendingDriverRecord.setProperties({
          peDate1: defaultDate,
          peDate2: defaultDate,
          startDateDs870: defaultDate,
          seniorityDate: defaultDate,
          birthDate: birthDate
        });
      }

      this.set('showValidations', false);
      return new _emberChangeset.default(pendingDriverRecord, (0, _emberChangesetValidations.default)(_validation.DRIVER_VALIDATION), _validation.DRIVER_VALIDATION);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedDriver = this.get('selectedDriver');
      const store = this.get('store');
      const driverId = selectedDriver.get('driverId');
      const classificationOptions = yield store.findAll('classification-name');
      const providers = this.get('store').peekAll('provider').filter(provider => {
        return provider.get('userCanAccessProvider') && (provider.get('status') && provider.get('status').toLowerCase() === ACTIVE_STATUS || !provider.get('status'));
      });
      const providerList = providers.map(provider => provider.providerName);
      const inspectCategories = yield store.findAll('inspection-category');
      this.set('classificationOptions', classificationOptions);
      this.set('providers', providers);
      this.set('providerList', providerList);
      this.set('inspectionCategories', inspectCategories);

      if (!selectedDriver.get('isNew')) {
        this.set('skipQuery', false);
        yield selectedDriver.reload();
      }

      if (Ember.isPresent(driverId) && !selectedDriver.get('isNew')) {
        this.set('activityQueryParams', {
          driverId: `${driverId}`,
          eventLog: true
        });
      }

      const tableRef = this.get('tableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        editAPI.buttonSettings(false, false, false, false, false);
      });
    },

    onSaveDriverRecord: (0, _emberConcurrency.task)(function* () {
      let undoOnException = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const notifications = this.get('notifications');
      const pendingDriverRecord = this.get('driverChangeSet');
      const driverAddress = (0, _unwrapProxy.unwrapProxy)(pendingDriverRecord.get('address'));
      const driverPhoneChangeSet = this.get('driverPhoneChangeSet');
      const emergencyContactChangeSet = this.get('driverEmergencyContactChangeSet');
      const driverLicenseChangeSet = this.get('driverLicenseChangeSet');
      const tooltip = this.get('tooltip'); // validation

      const isValidDriver = yield this.validateDriver.perform();

      if (isValidDriver) {
        try {
          yield pendingDriverRecord.save(); // save address if its present

          if (driverAddress.get('address')) {
            yield driverAddress.save();
          }

          if (driverPhoneChangeSet.get('isDirty') && driverPhoneChangeSet.get('isValid')) {
            const driverChangeSet = this.get('driverChangeSet');
            const driverPhones = (0, _unwrapProxy.unwrapProxy)(driverChangeSet.get('phoneNumbers'));
            const driverPhone = (0, _unwrapProxy.unwrapProxy)(driverPhones.get('firstObject'));
            const areaCode = driverPhoneChangeSet.get('areaCode'); // NYAAR-16005 - delete driver-phone-number for empty phone number instead of doing a patched because
            // api requires area code to not be empty

            if (Ember.isEmpty(areaCode)) {
              yield driverPhone.destroyRecord();
            } else {
              yield driverPhoneChangeSet.save();
            }
          }

          if (emergencyContactChangeSet.get('isDirty') && emergencyContactChangeSet.get('isValid')) {
            yield emergencyContactChangeSet.save();
          }

          if (driverLicenseChangeSet.get('isDirty') && driverLicenseChangeSet.get('isValid')) {
            yield driverLicenseChangeSet.save();
          }

          notifications.success('Record successfully saved.');
          tooltip.reset();
          this.resetForm();
          Ember.run.later(() => {
            this.get('refreshTask').perform();
          }, 1000);
          return pendingDriverRecord;
        } catch (e) {
          notifications.warning('Driver record failed to save');

          if (undoOnException) {
            const selectedDriver = this.get('selectedDriver');
            const driverPhoneNumber = this.get('driverChangeSet.phoneNumbers.firstObject');
            selectedDriver.rollbackAttributes();
            driverPhoneNumber.rollbackAttributes();
          }

          console.warn(e); // eslint-disable-line
        }
      }
    }),

    onUndoDriverRecord() {
      const driverChangeSet = this.get('driverChangeSet');
      const driverAddress = (0, _unwrapProxy.unwrapProxy)(driverChangeSet.get('address'));
      const driverPhoneChangeSet = this.get('driverPhoneChangeSet');
      const emergencyContactChangeSet = this.get('driverEmergencyContactChangeSet');
      const driverLicenseChangeSet = this.get('driverLicenseChangeSet');

      if (driverChangeSet) {
        driverChangeSet.rollback();
        driverPhoneChangeSet.rollback();
        emergencyContactChangeSet.rollback();
        driverLicenseChangeSet.rollback();
      }

      if (driverAddress.get('isNew')) {
        driverAddress.setProperties({
          streetNumber: null,
          streetAddress: null,
          locality: null,
          region: null,
          postalCode: null
        });
      } else {
        driverAddress.rollbackAttributes();
      }
    },

    validateDriver: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverChangeSet = this.get('driverChangeSet');
      const driverPhoneChangeSet = this.get('driverPhoneChangeSet');
      const emergencyContactChangeSet = this.get('driverEmergencyContactChangeSet');
      const driverLicenseChangeSet = this.get('driverLicenseChangeSet');
      yield driverChangeSet.validate();
      yield driverPhoneChangeSet.validate();
      yield emergencyContactChangeSet.validate();
      yield driverLicenseChangeSet.validate();
      const driverValid = driverChangeSet.errors;
      const driverPhoneValid = driverPhoneChangeSet.errors;
      const emergencyContactValid = emergencyContactChangeSet.errors;
      const driverLicenseValid = driverLicenseChangeSet.errors;
      const errors = (0, _lodash.compact)([].concat(driverValid, driverPhoneValid, emergencyContactValid, driverLicenseValid));

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.reset();
          }

        });
        return false;
      }

      return true;
    }),
    actions: {
      onPowerSelectChange(valuePath, selected) {
        this.set(valuePath, selected);
      },

      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onInputMaskValueChange(record, valuePath, useMaskVal, maskObj) {
        if (useMaskVal) record.set(valuePath, maskObj.masked.replace(/_/im, ''));else record.set(valuePath, maskObj.unmasked);
      },

      onPhoneValueChange(record, maskObj) {
        // match[0] = area code
        // match[1] = first part of 3 digit phone number
        // match[2] = second part of 4 digit phone number
        // mask value is empty register all values empty
        if (Ember.isEmpty(maskObj.masked)) {
          record.set('areaCode', '');
          record.set('phoneNumber', '');
          record.set('phone', '');
          record.set('unformattedPhoneNumber', maskObj.unmasked);
          return;
        }

        const match = maskObj.masked.replace(/_/img, '').split('-');
        record.set('areaCode', match[0]);
        record.set('phoneNumber', match[1] + match[2]);
        record.set('phone', match[1] + match[2]);
        record.set('unformattedPhoneNumber', maskObj.unmasked);
      },

      onDateValueChange(record, valuePath, value) {
        const date = (0, _moment.default)(value).isValid() ? (0, _moment.default)(value).toDate() : null;
        record.set(valuePath, date);
      },

      onCellValueChange(record, value, options) {
        record.set(`${options.valuePath}`, value);
      },

      onProviderChange(selected) {
        const providers = this.get('providers');
        const provider = providers.find(p => p.providerName === selected);
        this.set('driverChangeSet.provider', provider);
      },

      onCheckboxChange(record, valuePath, event) {
        const checked = event.target.checked;
        record.set(valuePath, checked);
      }

    }
  });

  _exports.default = _default;
});