define("adept-iq/pods/components/timeline-picker/time-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kxsg8yHc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"timeline-time-picker \",[27,\"if\",[[23,[\"isValid\"]],\"\",\"is-invalid\"],null]]]],[9],[0,\"\\n  \"],[1,[27,\"ember-timepicker\",null,[[\"onChange\",\"options\"],[[27,\"action\",[[22,0,[]],\"onTimeChange\"],null],[23,[\"timePickerOptions\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/timeline-picker/time-picker/template.hbs"
    }
  });

  _exports.default = _default;
});