define("adept-iq/pods/components/side-drawer/reconcile-replacement-breakdown/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    session: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    classNames: ['reconcile-widget-base'],
    errors: null,
    title: 'Add Replacement Route',
    breakdownRouteId: null,
    breakdownRouteType: 'Breakdown Replacement',
    replacementRouteNote: '',
    today: (0, _moment.default)().toISOString(),
    routeDetails: Ember.computed.readOnly('stackFrame.options.route'),
    callBack: Ember.computed.readOnly('stackFrame.options.action'),
    canUndo: Ember.computed.or('breakdownRouteId', 'selectedDateTimeRage.{starttime,endtime}', 'replacementRouteNote', 'selectedVehicle', 'selectedDriver'),
    canSave: Ember.computed.and('breakdownRouteId', 'selectedDateTimeRage.{starttime,endtime}', 'selectedVehicle', 'selectedDriver'),
    vehicleOptions: Ember.computed.alias('reconcile.reconcileVehicle'),
    driverOptions: Ember.computed.alias('reconcile.reconcileDriver'),

    init() {
      this._super(...arguments);

      this.set('selectedDateTimeRage', {
        today: (0, _moment.default)().hours(0).minutes(0).format('YYYY-MM-DD'),
        date: null,
        starttime: null,
        endtime: null,
        startforendtime: (0, _moment.default)().startOf('day').toISOString(),
        startofday: (0, _moment.default)().startOf('day').toISOString(),
        endofday: (0, _moment.default)().endOf('day').toISOString(),
        endDateSelection: (0, _moment.default)().subtract(7, 'years').format('MM-DD-YYYY')
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.setSideDrawerWidth('470px');
      this.get('systemConfig').set('showRightPanel', true);
    },

    showToolTip() {
      const tooltip = this.get('tooltip');
      const title = 'Add Replacement Route';
      const tip = 'Would you like to commit your changes?';
      const successMsg = `YOU REPLACED ROUTE ${this.routeDetails.routeId} WITH ROUTE ${this.breakdownRouteId} SUCCESSFULLY`;
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          return this.get('saveReplacementRouteTask').perform().then(() => {
            this.send('cancel');
            this.get('workspace').popState('reconcileReplacementBreakdown');
            this.get('notifications').success(successMsg);
            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              case 409:
                message = `The route name ${this.breakdownRouteId} is already taken, please try another.`;
                break;

              default:
                message = 'There was a problem creating the route, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    saveReplacementRouteTask: (0, _emberConcurrency.task)(function* () {
      try {
        const store = this.get('store');
        const reconcileRouteAdapter = store.adapterFor('reconcile-route');
        const dt = (0, _moment.default)(this.routeDetails.breakdownTime).format('MM-DD-YYYY');
        let startTime = this.get('selectedDateTimeRage').starttime;
        let endTime = this.get('selectedDateTimeRage').endtime;

        if (startTime && endTime) {
          const st = (0, _moment.default)(this.get('selectedDateTimeRage').starttime).format('hh:mm a');
          const end = (0, _moment.default)(this.get('selectedDateTimeRage').endtime).format('hh:mm a');
          startTime = (0, _moment.default)(dt + ' ' + st).toISOString();
          endTime = (0, _moment.default)(dt + ' ' + end).toISOString();
        }

        const breakdownTime = (0, _moment.default)(this.routeDetails.breakdownTime).toISOString();
        const payload = {
          id: Ember.get(this, 'breakdownRouteId'),
          type: Ember.get(this, 'breakdownRouteType'),
          providerName: Ember.get(this, 'routeDetails').providerName,
          plannedStartTime: startTime,
          plannedEndTime: endTime,
          breakdownLocation: Ember.get(this, 'routeDetails').breakdownLocation,
          breakdownTime: breakdownTime,
          note: this.replacementRouteNote,
          userId: this.session.data.authenticated.userId,
          vehicle: {
            id: Ember.get(this, 'selectedVehicle').vehicleId
          },
          driver: {
            id: Ember.get(this, 'selectedDriver').driverId
          }
        };
        yield reconcileRouteAdapter.postRoute(payload, payload.type, Ember.get(this, 'routeDetails').routeId);
      } catch (error) {
        throw error;
      }
    }),

    checkValidations() {
      let isValid = true;
      Ember.set(this, 'errors', []);

      if (!this.get('breakdownRouteId')) {
        this.errors.push('Route ID is required');
        isValid = false;
      }

      if (isNaN(parseInt(this.get('breakdownRouteId'), 10))) {
        this.errors.push('Route ID must be a numerical entry');
        isValid = false;
      }

      const startTime = this.get('selectedDateTimeRage').starttime;
      const endTime = this.get('selectedDateTimeRage').endtime;

      if (startTime && endTime) {
        const replacementStartTime = (0, _moment.default)(this.get('selectedDateTimeRage').starttime);
        const replacementEndTime = (0, _moment.default)(this.get('selectedDateTimeRage').endtime);

        if (replacementStartTime.isSameOrAfter(replacementEndTime)) {
          this.errors.push('Start Time cannot be after End Time');
          isValid = false;
        }
      } else {
        this.errors.push('Start and end time can not be empty');
        isValid = false;
      }

      if (Ember.isEmpty(this.get('selectedVehicle'))) {
        this.errors.push('Vehicle ID is required');
        isValid = false;
      }

      if (Ember.isEmpty(this.get('selectedDriver'))) {
        this.errors.push('Driver ID is required');
        isValid = false;
      }

      return isValid;
    },

    unloadPendingTrips() {
      const trips = Ember.get(this, 'store').peekAll('reconcile-trip').filter(trip => {
        return trip.routeId === this.routeDetails.get('routeId');
      });

      if (trips) {
        const pendingStatusTypes = ['scheduled', 's'];
        trips.map(trip => {
          const stops = Ember.get(this, 'store').peekAll('reconcile-stop').filter(stop => {
            return stop.tripId === trip.tripId && !Ember.isEmpty(stop.status) && pendingStatusTypes.includes(stop.status.toLowerCase());
          });

          if (stops.length) {
            stops.map(stop => {
              this.store.unloadRecord(stop);
            });
            this.store.unloadRecord(trip);
          }
        });
        Ember.set(this, 'reconcile.reconcileSelectedTripId', null);
      }
    },

    resetTimeProperties() {
      this.setProperties({
        driverShiftStart: null,
        driverShiftEnd: null,
        driverShiftStartTime: null,
        driverShiftEndTime: null
      });
    },

    actions: {
      onDriverOptionSelect(option) {
        Ember.set(this, 'selectedDriver', option);
        Ember.set(this, 'driverShiftStart', option.shiftStartTime);
        Ember.set(this, 'driverShiftEnd', option.shiftEndTime);
      },

      onVehicleOptionSelect(option) {
        Ember.set(this, 'selectedVehicle', option);
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
      },

      onUndoClick() {
        this.set('breakdownRouteId', '');
        this.set('replacementRouteNote', '');
        this.set('selectedDateTimeRage.starttime', null);
        this.set('selectedDateTimeRage.endtime', null);
        this.set('selectedDriver', null);
        this.set('selectedVehicle', null);
      },

      onSaveClick() {
        const isValid = this.checkValidations();

        if (isValid) {
          this.showToolTip();
        }
      },

      cancel() {
        this.unloadPendingTrips();

        if (this.callBack) {
          this.callBack(true);
        }
      }

    }
  });

  _exports.default = _default;
});