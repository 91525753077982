define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/vehicle-capacity-type/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/vehicle-capacity-type/validation", "adept-iq/utils/generate-unique-id", "adept-iq/config/placeholders", "lodash"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset, _emberChangesetValidations, _validation, _generateUniqueId, _placeholders, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_FACTORY_VALUES = ['Ambulatory', 'Wheelchair'];
  const NAME_PLACEHOLDER = 'Name';

  var _default = Ember.Component.extend({
    classNames: ['vehicle-capacity-type-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // placeholder
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    NUMBER_PERCENT_PLACEHOLDER: _placeholders.NUMBER_PERCENT_PLACEHOLDER,
    NAME_PLACEHOLDER,
    DISPLAY_NAME_PLACEHOLDER: _placeholders.DISPLAY_NAME_PLACEHOLDER,
    // default factory values
    DEFAULT_FACTORY_VALUES,
    tableRef: null,
    description: null,
    editAPI: null,
    disableForm: true,
    showErrorMsg: false,
    disableNameField: true,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    vehicleCapacityTypeChangeSet: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),
    isFactoryDefaultValues: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      if (!record) return false;
      return DEFAULT_FACTORY_VALUES.some(value => value === record.get('displayName'));
    }),
    isAmbulatory: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      if (!record) return false;
      return record.get('displayName') === 'Ambulatory';
    }),
    hasPendingChanges: Ember.computed('vehicleCapacityTypeChangeSet.changes.length', function () {
      const vehicleCapacityTypeChangeSet = this.get('vehicleCapacityTypeChangeSet');
      const changes = vehicleCapacityTypeChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      return this.get('hasPendingChanges');
    },

    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleCapacityTypeRecord = this.get('onSaveVehicleCapacityTypeRecord');
      const onNewVehicleCapacityTypeRecord = this.get('onNewVehicleCapacityTypeRecord').bind(this);
      const onEditVehicleCapacityTypeRecord = this.get('onEditVehicleCapacityTypeRecord').bind(this);
      const onDeleteVehicleCapacityTypeRecord = this.get('onDeleteVehicleCapacityTypeRecord');
      const refreshTask = this.get('refreshTask');
      const onUndoVehicleCapacityTypeRecord = this.get('onUndoVehicleCapacityTypeRecord').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      const hasRelationship = this.get('hasRelationship').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleCapacityTypeRecord,
          newAction: onNewVehicleCapacityTypeRecord,
          deleteAction: onDeleteVehicleCapacityTypeRecord,
          editAction: onEditVehicleCapacityTypeRecord,
          undoAction: onUndoVehicleCapacityTypeRecord,
          hasChanges,
          onValidateAction,
          refreshTask,
          hasRelationship
        });
        refreshTask.perform();
      });
    },

    willDestroyElement() {
      const records = this.get('tableRef.records') || [];
      records.map(record => {
        if (record.get('tableRef')) {
          record.set('tableRef');
        }
      });

      this._super(...arguments);
    },

    hasRelationship() {
      const store = this.get('store');
      const tooltip = this.get('tooltip');
      const description = this.get('CapacityTypeRecord.description') || ''; // in the future we should actually use an rql query to figure out which vehicles are using this maintTypeName
      // and have the backend process it for us, currently and name field and description can be different though,
      // so we might need to think about it this a bit more

      const vehicle = store.peekAll('vehicle').filter(record => record.get('active') && record.get('CapacityType.description') === description);

      if (vehicle.length) {
        tooltip.pushConfirmation({
          title: 'Lookup',
          hasoverlay: true,
          tip: `There are ${vehicle.length} active vehicle(s) with this Vehicle Maintenance Type description. You must either change the Vehicle Maintenance Type description on these vehicle(s) or delete these vehicle(s) prior to changing the Vehicle Maintenance Type description.`,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.reset();
          }

        });
      }

      return vehicle.length;
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const tableRef = this.get('tableRef');
      let CapacityTypeObj = this.get('pendingRecord');

      if (record) {
        CapacityTypeObj = record;
        this.resetForm();
      }

      if (Ember.isEmpty(record)) {
        const data = {
          category: 'config-System_Configuration-vehicle_capacity_types',
          type: 'object'
        };
        CapacityTypeObj = store.createRecord('cs-config-item', data);
        this.set('pendingRecord', CapacityTypeObj);
      }

      if (CapacityTypeObj.get('isNew')) {
        CapacityTypeObj.setProperties({
          description: null,
          tableRef: tableRef
        });
      }

      this.set('showErrorMsg', false);
      const tableRefRecords = this.get('tableRef.records') || [];
      tableRefRecords.map(tableRefRecord => {
        if (!tableRefRecord.get('tableRef')) {
          tableRefRecord.set('tableRef', tableRef);
        }
      });
      return new _emberChangeset.default(CapacityTypeObj, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('vehicleCapacityTypeChangeSet');
      const vehicleCapacityTypes = this.get('store').peekAll('vehicle-capacity-type');

      if (selectedRecord) {
        if (!selectedRecord.get('isNew') && !selectedRecord.get('isDeleted')) {
          yield selectedRecord.data.reload();
        }
      }

      this.set('vehicleCapacityTypes', vehicleCapacityTypes);
      const tableRef = this.get('tableRef');
      this.resetForm();

      if (tableRef) {
        Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');

        if (tableRef.get('table.rows').length) {
          this.get('tableRef.firstRowSelectedTask').perform();
        }
      }
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);

        if (this.get('isFactoryDefaultValues')) {
          editAPI.buttonSettings(true);
        } else {
          editAPI.buttonSettings();
        }
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const vehicleCapacityTypeRecord = this.get('vehicleCapacityTypeChangeSet');
      yield vehicleCapacityTypeRecord.validate();
      const errors = vehicleCapacityTypeRecord.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    vehicleCapacityEngineIdHash: Ember.computed('vehicleCapacityTypes.@each.engineId', function () {
      const vehicleCapacityTypes = this.get('vehicleCapacityTypes') || [];
      return vehicleCapacityTypes.reduce((acu, capacity) => {
        acu[capacity.get('engineId')] = true;
        return acu;
      }, {});
    }),
    onSaveVehicleCapacityTypeRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const vehicleCapacityTypeChangeSet = this.get('vehicleCapacityTypeChangeSet');
      const tooltip = this.get('tooltip');
      if (!isValid) return;
      let vehicleCapacityTypeRecord = this.get('vehicleCapacityTypeChangeSet');
      const {
        description,
        displayName,
        constrainedByLIFO,
        dwellFactor
      } = vehicleCapacityTypeRecord.getProperties('description', 'displayName', 'constrainedByLIFO', 'dwellFactor');
      const notifications = this.get('notifications');
      const usedChars = this.get('vehicleCapacityEngineIdHash'); // Passing the unique engineid

      const engineId = vehicleCapacityTypeRecord.get('engineId') || (0, _generateUniqueId.uniqueId)(1, {
        usedChars
      }); // Dwell Factory Number

      const parsedDwellFactor = !isNaN(parseInt(dwellFactor, 10)) ? parseInt(dwellFactor, 10) : 0;
      const value = {
        description,
        engineId: engineId,
        engineName: displayName.replace(/\s/img, '').toLowerCase(),
        displayName,
        dwellFactor: parsedDwellFactor,
        constrainedByLIFO: constrainedByLIFO || false
      };

      if (vehicleCapacityTypeRecord.get('isNew')) {
        vehicleCapacityTypeRecord.set('name', (0, _lodash.camelCase)(displayName));
      } else {
        const id = this.get('vehicleCapacityTypeChangeSet.id');
        const store = this.get('store');
        const record = `config-System_Configuration-vehicle_capacity_types/${id}`;
        vehicleCapacityTypeRecord = store.peekRecord('cs-config-item', record);
        vehicleCapacityTypeRecord.set('displayName', displayName);
      }

      vehicleCapacityTypeRecord.set('value', value);

      try {
        yield vehicleCapacityTypeRecord.save(); // changeset holds changes, execute the changes due to lookups using unorthodox saving practice
        // execute to change underlying object and rollback to remove internal changes list

        vehicleCapacityTypeChangeSet.execute();
        vehicleCapacityTypeChangeSet.rollback();
        notifications.success('Lookup record successfully saved.');
        this.set('disableForm', true);
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
            tooltip.reset();
          });
        }, 500);
      } catch (response) {
        if (response.errors[0].status === '409') {
          tooltip.pushConfirmation({
            title: 'Manage Driver/Vehicle',
            tip: 'Description cannot duplicate an existing Description.',
            primaryActionText: 'OK',
            primaryAction: () => {
              return tooltip.reset();
            }
          });
        } else {
          notifications.warning('Lookup record failed to save.');
        }

        if (vehicleCapacityTypeRecord.rollbackAttributes) {
          vehicleCapacityTypeRecord.rollbackAttributes();
        }

        console.warn(response); // eslint-disable-line
      }
    }).drop(),
    deleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRow = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      const notifications = this.get('notifications');
      const store = this.get('store');
      const csConfigVehicleCapacityTypeId = `config-System_Configuration-vehicle_capacity_types/${selectedRow.get('id')}`;

      if (selectedRow) {
        try {
          const csConfigVehicleCapacityType = store.peekRecord('cs-config-item', csConfigVehicleCapacityTypeId); // delete config-vehicle_capacity_types in cs-config-item

          yield csConfigVehicleCapacityType.destroyRecord().then(cs => {
            store._removeFromIdMap(cs._internalModel);
          }); // delete vehicle capacity type record from store

          yield selectedRow.destroyRecord().then(cs => {
            store._removeFromIdMap(cs._internalModel);
          });
          notifications.success('Lookup record successfully deleted.');
          this.get('refreshTask').perform().then(() => {
            Ember.run.later(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
            }, 500);
          });
        } catch (e) {
          notifications.warning('Lookup record failed to delete.');
          console.warn(e); // eslint-disable-line
        }
      }
    }).drop(),
    onDeleteVehicleCapacityTypeRecord: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const deleteRecord = this.get('deleteRecord');
      yield tooltip.pushConfirmation({
        title: 'Lookup',
        hasOverlay: true,
        tip: 'Are you sure you want to delete this record?',
        primaryActionText: 'Confirm',
        primaryAction: () => {
          const store = this.get('store');
          const vehicleCapacityTypeName = this.get('vehicleCapacityTypeChangeSet.name') || null;
          const configType = 'config-System_Configuration-vehicle_capacity_configs';

          if (vehicleCapacityTypeName) {
            const vehicleCapacityTypeRecords = store.peekAll('cs-config-item').filter(configItem => {
              return configItem.category === configType;
            });
            const capacityConfigs = vehicleCapacityTypeRecords.filter(record => record.get('value').vehicleCapacityType === vehicleCapacityTypeName);

            if (capacityConfigs.length) {
              return tooltip.pushConfirmation({
                title: 'Manage Vehicle Type',
                hasOverlay: true,
                tip: `There are ${capacityConfigs.length} active Vehicle Type(s)  with this Vehicle Capacity Type name. You must either change the Vehicle Capacity Type on these Vehicle Type(s)  or delete these Vehicle Type(s) prior to deleting the Vehicle Capacity Type.`,
                primaryActionText: 'OK',

                primaryAction() {
                  tooltip.reset();
                }

              });
            }

            return deleteRecord.perform().then(() => {
              tooltip.reset();
            });
          }
        },
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        }

      });
    }).drop(),

    onEditVehicleCapacityTypeRecord() {
      const editAPI = this.get('editAPI');
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onUndoVehicleCapacityTypeRecord() {
      const vehicleCapacityTypeChangeSet = this.get('vehicleCapacityTypeChangeSet');
      const editAPI = this.get('editAPI');

      if (vehicleCapacityTypeChangeSet) {
        vehicleCapacityTypeChangeSet.rollback();
      }

      this.set('disableForm', true);
      editAPI.buttonSettings(false, false, false, true, true);
    },

    onNewVehicleCapacityTypeRecord() {
      const editAPI = this.get('editAPI');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    actions: {
      onSaveVehicleCapacityTypeRecord() {
        return this.onSaveVehicleCapacityTypeRecord.perform();
      },

      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onInputMaskValueChange(record, valuePath, useMaskVal, maskObj) {
        if (useMaskVal) return record.set(valuePath, maskObj.masked.replace(/_/im, ''));
        return record.set(valuePath, maskObj.unmasked);
      }

    }
  });

  _exports.default = _default;
});