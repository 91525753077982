define("adept-iq/pods/components/iq-widgets/view-itinerary-modal/trip-alternative/component", ["exports", "ember-light-table", "moment", "adept-iq/pods/components/iq-widgets/view-itinerary-modal/trip-alternative/columnConfig", "adept-iq/config/itinerary-action", "adept-iq/config/icon-paths"], function (_exports, _emberLightTable, _moment, _columnConfig, _itineraryAction, _iconPaths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ActionIconMap = {
    'taxi': _iconPaths.default.requestdetails.taxiicon,
    'paratransit': _iconPaths.default.requestdetails.paratransiticon
  };
  const ITP_TRAVEL_MODE = 'itp';
  const BUS_TYPE = 'bus';

  var _default = Ember.Component.extend({
    classNames: ['trip-alternative'],
    canShowHeader: true,
    isSearchEnabled: false,
    isCellEditAllowed: false,
    model: Ember.computed('selectedTravelMode', function () {
      const selectedTravelMode = this.get('selectedTravelMode');

      if (selectedTravelMode === ITP_TRAVEL_MODE) {
        return this.getItpSegments();
      } // taxi or paratransist


      const actionIcon = ActionIconMap[selectedTravelMode];
      const firstSegment = this.get('tripSegments.firstObject');
      const lastSegment = this.get('tripSegments.lastObject');
      const promiseTime = this.get('tripBooking.promiseTime');
      const formattedPromiseTime = promiseTime ? (0, _moment.default)(promiseTime).format('hh:mm A') : '';
      const segment = {
        'icon': actionIcon,
        'action': selectedTravelMode,
        'from': firstSegment.tripRequestPickAddress,
        'startTime': formattedPromiseTime,
        'to': lastSegment.tripRequestDropAddress,
        'arriveTime': '',
        'promiseTime': formattedPromiseTime
      };
      return [segment];
    }),
    table: Ember.computed('model', function () {
      return new _emberLightTable.default(_columnConfig.default.columns, this.get('model'), {
        enableSync: false
      });
    }),

    /**
    *
    * @returns itp segments for itp booking
    */
    getItpSegments() {
      return this.get('itinerarySegments').map(itinerarySegment => {
        const startAddress = itinerarySegment.start.address;
        const endAddress = itinerarySegment.end.address;
        const {
          action,
          icon
        } = _itineraryAction.ITINERARY_ACTION_MAP[itinerarySegment.action];
        const description = itinerarySegment.description;
        const promiseTime = itinerarySegment.promiseTime;
        const formattedPromiseTime = (0, _moment.default)(promiseTime).format('hh:mm A');
        return {
          'icon': icon,
          'action': action === BUS_TYPE ? `${action} ${description}` : action,
          'from': startAddress,
          'startTime': (0, _moment.default)(itinerarySegment.start.time).format('hh:mm A'),
          'to': endAddress,
          'arriveTime': (0, _moment.default)(itinerarySegment.end.time).format('hh:mm A'),
          'promiseTime': promiseTime ? formattedPromiseTime : ''
        };
      });
    }

  });

  _exports.default = _default;
});