define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/component", ["exports", "ember-concurrency", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/config", "adept-iq/utils/unwrapProxy", "adept-iq/config/cs-config-model-category", "lodash", "moment"], function (_exports, _emberConcurrency, _config, _unwrapProxy, _csConfigModelCategory, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PASSENGER_TYPE_PLACE_CATEGORY = 'passenger';

  var _default = Ember.Component.extend({
    classNames: ['vehicle-driver-manager-container'],
    classNameBindings: ['dropDownClass'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    // its possible to have a singular table ref
    // but let's keep it simple for now and singularly have a reference per each tab
    tableRef: null,
    disableEditing: true,
    showValidations: false,
    isEditing: false,
    editAPI: null,
    csConfigModelCategoryIdMap: null,
    isLookupTabActive: Ember.computed.notEmpty('lookupComponentRef'),
    selectedMenuTitle: Ember.computed('editAPI.selected', function () {
      const selectedMenu = this.get('editAPI.selected');
      return selectedMenu ? selectedMenu.toLowerCase() : '';
    }),
    selectedTab: _config.DRIVER_TAB,
    dropDownClass: Ember.computed('selectedTab', function () {
      const selectedTab = this.get('selectedTab');
      return selectedTab !== _config.LOOKUP_TAB ? 'driver-vehicle-drop-down' : '';
    }),
    isVehicleTabSelected: Ember.computed.equal('selectedTab', _config.VEHICLE_TAB),

    /**
     * this observer used to disable the driver related
     * driverEditOptions  screen buttons while delete the table records
     */
    disableBtnSettings: Ember.observer('editAPI.selectedDriverBelongsTableRef.checkedRows.[]', function () {
      const editAPI = this.get('editAPI');
      const selectedDriverBelongsTableRef = editAPI.get('selectedDriverBelongsTableRef');
      const records = selectedDriverBelongsTableRef ? selectedDriverBelongsTableRef.get('records') : [];

      if (_config.driverEditOptions.includes(editAPI.selected) && !records.length) {
        Ember.run.later(() => {
          editAPI.buttonSettings(true, false, true, true, true);
        }, 500);
      }
    }),
    disableUndoButton: Ember.computed('editAPI.isChangesHappened', function () {
      return !this.get('editAPI.hasChanges')();
    }),

    async init() {
      this._super(...arguments);

      const store = this.get('store');
      this.set('editAPI', Ember.Object.create({
        hasChanges: false,
        buttonSettings: function () {
          let deleteBtn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
          let newBtn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
          let editBtn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
          let undoBtn = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
          let saveBtn = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
          this.setProperties({
            deleteBtn,
            editBtn,
            saveBtn,
            undoBtn,
            newBtn
          });
        },
        updateHasChanges: function () {
          let update = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
          this.set('hasChanges', update);
        }
      }));
      this.setConfigModelCategoryIdMap(); // load vehicle related entities

      store.findAll('route').then(this.buildRouteVehicleMap.bind(this));
      store.query('place', {
        filter: `ne(placeCategoryTypeName,${PASSENGER_TYPE_PLACE_CATEGORY})`
      });
      store.findAll('route-vehicle-driver');
      store.findAll('driver-provider');
      store.findAll('driver-health');
      store.findAll('route-template-availability').then(this.buildRouteTemplateAvailabilities.bind(this));
    },

    didInsertElement() {
      const selectedTab = this.get('selectedTab');
      this.set('workspace.helpSelectedTab', selectedTab);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.set('workspace.helpSelectedTab', '');
    },

    buildRouteTemplateAvailabilities(data) {
      const routeTemplateAvailabilityMap = (0, _lodash.groupBy)(data.toArray(), 'vehicleId');
      this.set('routeTemplateAvailabilityMap', routeTemplateAvailabilityMap);
    },

    /**
     *this method is used to build the future routes map
     * @param data
     */
    buildRouteVehicleMap(routeData) {
      const routes = routeData.toArray();
      const schedules = this.get('store').peekAll('schedule');
      const currentDate = new Date();
      const futureSchedulesIds = schedules.filter(schedule => (0, _moment.default)(schedule.get('start')).isAfter(currentDate)).map(s => s.get('id'));
      const futureRoutes = routes.filter(route => futureSchedulesIds.includes(route.get('schedule.id')));
      const routeVehicleMap = (0, _lodash.groupBy)(futureRoutes, 'vehicleId');
      this.set('editAPI.routeVehicleMap', routeVehicleMap);
    },

    setConfigModelCategoryIdMap() {
      const csConfigModelCategoryIdMap = {};
      Object.entries(_csConfigModelCategory.modelNameForCategory).forEach(_ref => {
        let [key, val] = _ref;
        csConfigModelCategoryIdMap[val] = key;
      });
      this.set('csConfigModelCategoryIdMap', csConfigModelCategoryIdMap);
    },

    resetEditAPI() {
      const editAPI = this.get('editAPI');
      editAPI.setProperties({
        vehicleEditOptions: null,
        driverEditOptions: null,
        editAction: null,
        newAction: null,
        deleteAction: null,
        undoAction: null,
        saveAction: null,
        saveBtn: false,
        deleteBtn: false,
        editBtn: false,
        undoBtn: false,
        newBtn: false,
        hasChanges: false,
        selectedDriverBelongsTableRef: null
      });
    },

    newVehicleRecord() {
      const title = 'New Vehicle';
      const editAPI = this.get('editAPI');
      let pendingVehicleRecord = this.get('pendingVehicleRecord'); // pending record is no longer new or doesn't exist

      if (!pendingVehicleRecord || pendingVehicleRecord.get('id')) {
        pendingVehicleRecord = this.get('store').createRecord('vehicle');
        this.set('pendingVehicleRecord', pendingVehicleRecord);
      } else {// reset vehicle record properties
      }

      this.toggleProperty('isEditing');
      editAPI.setProperties({
        options: _config.vehicleEditOptions,
        selected: _config.vehicleEditOptions.firstObject,
        selectedRecord: pendingVehicleRecord,
        title: title
      });
    },

    editVehicleRecord() {
      // checked rows will be only one because it a radio type
      const checkedRow = this.get('tableRef.checkedRows.firstObject');
      if (!checkedRow) return;
      const editAPI = this.get('editAPI');
      this.toggleProperty('isEditing');
      let title = '';
      title = `Edit Vehicle ${checkedRow.get('name')}`;
      editAPI.setProperties({
        options: _config.vehicleEditOptions,
        selected: _config.vehicleEditOptions.firstObject,
        selectedRecord: checkedRow,
        title: title
      });
    },

    deleteVehicleRecord() {},

    newDriverRecord() {
      const store = this.get('store');
      const editAPI = this.get('editAPI');
      const title = 'New Driver';
      const selectedDriverRow = this.get('tableRef.checkedRows.firstObject');
      let pendingDriverRecord = this.get('pendingDriverRecord'); // pending record is no longer new or doesn't exist

      if (!pendingDriverRecord || pendingDriverRecord.get('id')) {
        pendingDriverRecord = store.createRecord('driver');
        this.set('pendingDriverRecordRecord', pendingDriverRecord);
      }

      if (selectedDriverRow) selectedDriverRow.set('selected', false);
      this.createDriverAssociations(pendingDriverRecord);
      editAPI.setProperties({
        options: _config.driverEditOptions,
        selected: _config.driverEditOptions.firstObject,
        selectedRecord: pendingDriverRecord,
        title: title
      });
      this.toggleProperty('isEditing');
    },

    async editDriverRecord() {
      // checked rows will be only one because it a radio type
      const checkedRow = this.get('tableRef.checkedRows.firstObject');
      const editAPI = this.get('editAPI');
      if (!checkedRow) return;
      await this.loadDriverAssociations(checkedRow);
      this.createDriverAssociations(checkedRow);
      const title = `Edit Driver ${checkedRow.get('driverId')} - ${checkedRow.get('fullName')}`;
      editAPI.setProperties({
        options: _config.driverEditOptions,
        selected: _config.driverEditOptions.firstObject,
        selectedRecord: checkedRow,
        title: title
      });
      this.toggleProperty('isEditing');
    },

    createDriverAssociations(driverRecord) {
      const store = this.get('store');
      const driverAddress = (0, _unwrapProxy.unwrapProxy)(driverRecord.get('address'));
      const driverPhones = (0, _unwrapProxy.unwrapProxy)(driverRecord.get('phoneNumbers'));
      const emergencyContacts = (0, _unwrapProxy.unwrapProxy)(driverRecord.get('emergencyContacts'));
      const driverLicense = (0, _unwrapProxy.unwrapProxy)(driverRecord.get('license'));

      if (Ember.isEmpty(driverAddress)) {
        driverRecord.set('address', store.createRecord('address'));
      }

      if (Ember.isEmpty(driverLicense)) {
        driverRecord.set('license', store.createRecord('driver-license'));
      }

      if (Ember.isEmpty(driverPhones) || Ember.isEmpty(driverPhones.length)) {
        driverRecord.set('phoneNumbers', [store.createRecord('driver-phone-number')]);
      }

      if (Ember.isEmpty(emergencyContacts) || Ember.isEmpty(emergencyContacts.length)) {
        driverRecord.set('emergencyContacts', [store.createRecord('driver-emergency-contact')]);
      }
    },

    loadDriverAssociations(driverRecord) {
      const store = this.get('store');

      if (driverRecord && !driverRecord.get('isNew')) {
        return store.query('driver-health', {
          filter: `eq(driverId,${driverRecord.get('id')})`
        });
      }
    },

    deleteRecord: (0, _emberConcurrency.task)(function* () {
      const tableRef = this.get('tableRef');
      const selectedTab = this.get('selectedTab');
      const isVehicleTabSelected = this.get('isVehicleTabSelected');
      const checkedRow = tableRef.get('checkedRows.firstObject');
      const notifications = this.get('notifications');

      if (Ember.isEmpty(checkedRow)) {
        console.warn('Workflow shouldn\'t reach this line'); // eslint-disable-line
      }

      const tooltip = this.get('tooltip');
      if (!checkedRow) return;

      if (isVehicleTabSelected && this.get('vehicleHaveActiveRoute').call(this, checkedRow)) {
        return yield tooltip.pushConfirmation({
          title: `Manage ${Ember.String.capitalize(selectedTab)}`,
          tip: `Unable to delete vehicle ${checkedRow.get('name')}.
        This vehicle is currently assigned  to either an active route in Dispatch or a route template in Route Master.`,
          warning: true,
          hasOverlay: true,
          primaryActionText: 'OK',
          primaryAction: async () => {
            return tooltip.reset();
          }
        });
      }

      return yield tooltip.pushConfirmation({
        title: `Manage ${Ember.String.capitalize(selectedTab)}`,
        tip: 'Are you sure you want to delete this record?',
        warning: true,
        hasOverlay: true,
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          await checkedRow.destroyRecord();
          tableRef.refreshData();

          if (`${selectedTab}` !== 'vehicle') {
            notifications.success(`${selectedTab} record successfully deleted.`);
          } else {
            notifications.success(`${checkedRow.get('name')} record successfully deleted.`);
          }

          return tooltip.reset();
        }
      });
    }),

    //check the vehicle have a relationship with route or route-template-availability
    vehicleHaveActiveRoute(checkedRow) {
      const routeVehicleDrivers = checkedRow.get('routeVehicleDrivers');
      const vehicleId = checkedRow.get('id');
      const routeTemplateAvailabilityMap = this.get('routeTemplateAvailabilityMap');
      const routeTemplateAvailabilities = routeTemplateAvailabilityMap[vehicleId];
      return routeVehicleDrivers.length || routeTemplateAvailabilities;
    },

    deleteLookUpRecord: (0, _emberConcurrency.task)(function* () {
      const tableRef = this.get('tableRef');
      const tooltip = this.get('tooltip');
      const store = this.get('store');
      const csConfigModelCategoryIdMap = this.get('csConfigModelCategoryIdMap');
      const notifications = this.get('notifications');
      const checkedRow = tableRef.get('checkedRows.firstObject');
      const editAPI = this.get('editAPI');
      const hasRelationship = this.get('editAPI.hasRelationship');
      const refreshTask = editAPI.get('refreshTask');
      const csConfigModelId = `${csConfigModelCategoryIdMap[checkedRow.constructor.modelName]}/${checkedRow.get('id')}`;
      const csConfigItemModel = store.peekRecord('cs-config-item', csConfigModelId);

      if (Ember.isEmpty(checkedRow)) {
        console.warn('Workflow shouldn\'t reach this line'); // eslint-disable-line

        return;
      }

      return yield tooltip.pushConfirmation({
        title: 'Lookup',
        tip: 'Are you sure you want to delete this record?',
        warning: true,
        hasOverlay: true,
        secondaryActionText: 'Cancel',
        secondaryAction: () => tooltip.reset(),
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          //if the selected  look-up model has a relationship with any other model restrict to delete
          if (hasRelationship()) {
            return;
          }

          try {
            //look-up models is built by config-item model deleting an look-up
            //record remove the record only in look-up models, not in the cs-config-item model
            //so below code removes record from the cs-config-item
            await csConfigItemModel.destroyRecord().then(cs => {
              store._removeFromIdMap(cs._internalModel);
            }); //removes the look-up models record from ember store

            await checkedRow.destroyRecord().then(emp => {
              store._removeFromIdMap(emp._internalModel);
            });
            notifications.success('Lookup record successfully deleted.');
            refreshTask.perform().then(() => {
              Ember.run.later(() => {
                tableRef.set('config.selectFirstRow', true);
                tableRef.get('firstRowSelectedTask').perform();
              }, 500);
            });
            return tooltip.reset();
          } catch (e) {
            notifications.warning('Lookup record failed to delete.');
            console.warn(e); // eslint-disable-line

            tooltip.reset();
          }
        }
      });
    }),
    backTo: (0, _emberConcurrency.task)(function* () {
      const editAPI = this.get('editAPI');
      const tooltip = this.get('tooltip');
      const selectedTab = this.get('selectedTab') === null ? 'Driver' : this.get('selectedTab');
      const {
        undoAction,
        saveAction,
        hasChanges
      } = editAPI.getProperties('undoAction', 'saveAction', 'hasChanges');
      const changesMade = hasChanges();

      if (changesMade) {
        return tooltip.pushConfirmation({
          title: `Manage ${Ember.String.capitalize(selectedTab)}`,
          tip: 'There are unsaved changes',
          warning: true,
          thirdActionText: 'Cancel',
          thirdAction: () => {
            return tooltip.reset();
          },
          secondaryActionText: 'Do Not Save',
          secondaryAction: () => {
            undoAction();
            this.set('isEditing', false);
            this.resetEditAPI();
            return tooltip.reset();
          },
          primaryActionText: 'Save',
          primaryAction: async () => {
            const undoOnException = true;
            const savedRecord = await saveAction.perform(undoOnException);

            if (savedRecord.get('id')) {
              this.set('isEditing', false);
              this.resetEditAPI();
              return tooltip.reset();
            }
          }
        });
      } // there going back to the edit screen, undo even though this shouldn't matter


      yield undoAction();
      this.set('isEditing', false);
      this.resetEditAPI();
    }),
    onNavigationChange: (0, _emberConcurrency.task)(function* (currentStep, option) {
      const editAPI = this.get('editAPI');
      const tooltip = this.get('tooltip');
      const {
        onValidateAction,
        undoAction,
        saveAction,
        hasChanges
      } = editAPI.getProperties('onValidateAction', 'undoAction', 'saveAction', 'hasChanges');
      const saveBtn = editAPI.get('saveBtn');
      const newBtn = editAPI.get('newBtn');
      const changesMade = hasChanges(); // enable popup for any user changes

      if (changesMade) {
        const isValid = onValidateAction ? yield onValidateAction.perform() : true;
        if (!isValid) return;
        return tooltip.pushConfirmation(this.getTooltipInfo(undoAction, tooltip, currentStep, editAPI, saveAction, option));
      } // NYAAR-16312 when navigating to otherScreen after clicking new without changing any fields


      if (newBtn) {
        return tooltip.pushConfirmation(this.getTooltipInfo(undoAction, tooltip, currentStep, editAPI, saveAction, option, onValidateAction));
      } // even if the user hasn't made changes if there are fields required, the user needs to fill them in


      yield undoAction(); // otherwise if theres a table in the view, the user doesn't necessarily need to create / or edit and can move to a different view
      // save btn disables the button so if its enabled it will be false

      if (!saveBtn) {
        let isValid = true;
        isValid = yield onValidateAction.perform();

        if (isValid) {
          return editAPI.set('selected', option);
        }

        return;
      }

      return editAPI.set('selected', option);
    }),

    getTooltipInfo(undoAction, tooltip, currentStep, editAPI, saveAction, option, onValidateAction) {
      const isNewRecord = editAPI.get('selectedRecord.isNew');
      const selectedTab = this.get('selectedTab');
      const tabs = [_config.VEHICLE_TAB, _config.DRIVER_TAB];
      const tooltipInfo = {
        title: `Manage ${Ember.String.capitalize(currentStep)}`,
        tip: 'There are unsaved changes.',
        warning: true,
        thirdActionText: 'Cancel',
        thirdAction: () => {
          return tooltip.reset();
        },
        secondaryAction: async () => {
          await undoAction();
          tooltip.popConfirmation();
          editAPI.set('selected', option);
        },
        secondaryActionText: 'Do Not Save',
        primaryActionText: 'Save',
        primaryAction: async () => {
          const undoOnException = true;
          const isValid = onValidateAction ? await onValidateAction.perform() : true;

          if (isValid) {
            return saveAction.perform(undoOnException).then(() => {
              tooltip.reset();
              editAPI.set('selected', option);
            }).catch(() => {
              tooltip.popConfirmation();
            });
          }
        }
      }; //NYAAR-17503 If vehicle or driver  record is not saved then the user is not able to navigate to the other vehicle or driver screen.

      if (isNewRecord && tabs.includes(selectedTab)) {
        tooltipInfo.secondaryActionText = 'Cancel';

        tooltipInfo.secondaryAction = () => {
          return tooltip.reset();
        };

        delete tooltipInfo.thirdActionText;
        delete tooltipInfo.thirdAction;
      }

      return tooltipInfo;
    },

    onTabChange: (0, _emberConcurrency.task)(function* (options) {
      const editAPI = this.get('editAPI');
      const tooltip = this.get('tooltip');
      const {
        onValidateAction,
        undoAction,
        saveAction,
        hasChanges
      } = editAPI.getProperties('onValidateAction', 'undoAction', 'saveAction', 'hasChanges');
      const {
        curStep,
        newStep,
        w
      } = options;
      const changesMade = hasChanges();

      if (changesMade) {
        const isValid = onValidateAction ? yield onValidateAction.perform() : true;
        if (!isValid) return;
        return tooltip.pushConfirmation({
          title: `${Ember.String.capitalize(curStep)} Management`,
          tip: 'There are unsaved changes',
          warning: true,
          thirdActionText: 'Cancel',
          thirdAction: () => {
            return tooltip.reset();
          },
          secondaryActionText: 'Do Not Save',
          secondaryAction: () => {
            undoAction();
            this.set('isEditing', false);
            this.resetEditAPI();
            w['transition-to'](newStep);
            this.set('selectedTab', newStep);
            this.set('workspace.helpSelectedTab', newStep);
            return tooltip.reset();
          },
          primaryActionText: 'Save',
          primaryAction: () => {
            const undoOnException = true;
            return saveAction.perform(undoOnException).then(() => {
              w['transition-to'](newStep);
              this.set('selectedTab', newStep);
              this.set('workspace.helpSelectedTab', newStep);
              tooltip.reset();
              this.set('isEditing', false);
              this.resetEditAPI();
            }).catch(() => {
              tooltip.popConfirmation();
            });
          }
        });
      } // there going back to the edit screen, undo even though this shouldn't matter


      yield undoAction();
      this.set('isEditing', false);
      this.resetEditAPI();
      w['transition-to'](newStep);
      this.set('selectedTab', newStep);
      this.set('workspace.helpSelectedTab', newStep);
    }).drop(),
    actions: {
      newRecord(selectedTab) {
        if (selectedTab === _config.DRIVER_TAB) {
          this.newDriverRecord();
        }

        if (selectedTab === _config.VEHICLE_TAB) {
          this.newVehicleRecord();
        }
      },

      saveRecord() {},

      editRecord(selectedTab) {
        if (selectedTab === _config.VEHICLE_TAB) {
          this.editVehicleRecord();
        } else {
          this.editDriverRecord();
        }

        this.set('disableEditing', false);
      },

      deleteRecord(selectedTab) {
        this.set('selectedTab', selectedTab);
        this.set('workspace.helpSelectedTab', selectedTab);
        this.get('deleteRecord').perform();
        this.set('disableEditing', false);
      },

      deleteLookUpRecord() {
        this.get('deleteLookUpRecord').perform();
        this.set('disableEditing', false);
      },

      editAPISaveAction() {
        const editAPI = this.get('editAPI');

        if (editAPI.saveAction) {
          editAPI.saveAction.perform();
        }
      },

      onSelectTableRow() {
        Ember.run.scheduleOnce('afterRender', this, () => {
          this.set('disableEditing', true);
        });
      },

      onTabChange(options) {
        const {
          newStep,
          w
        } = options;

        if (options.curStep === _config.LOOKUP_TAB) {
          return this.onTabChange.perform(options);
        }

        w['transition-to'](newStep);
        this.set('selectedTab', newStep);
        this.set('workspace.helpSelectedTab', newStep);
      },

      onLookupTabClick(options) {
        const editAPI = this.get('editAPI');
        const {
          newStep,
          w
        } = options;
        editAPI.setProperties({
          options: _config.lookupOptions,
          selected: _config.lookupOptions[0].options[0]
        });
        w['transition-to'](newStep);
        this.set('selectedTab', newStep);
        this.set('workspace.helpSelectedTab', newStep);
      },

      editAPIUndoAction() {
        const editAPI = this.get('editAPI');

        if (editAPI.undoAction) {
          editAPI.undoAction();
        }
      }

    }
  });

  _exports.default = _default;
});