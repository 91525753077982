define("adept-iq/pods/components/form-components/vehicle-selection/vehicle-row/component", ["exports", "ember-light-table/components/lt-row", "adept-iq/utils/vehicleCapacity"], function (_exports, _ltRow, _vehicleCapacity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    classNameBindings: ['isUnderCapacity', 'isDisabled'],
    vehicle: Ember.computed.readOnly('row.vehicle'),
    isDisabled: Ember.computed.readOnly('vehicle.placeholder'),
    // we need to check maximum capacity per clusters of a route
    isUnderCapacity: Ember.computed('extra.dispatchRoute.clusters.@each.maxTravelNeedCounts', 'vehicle.capacityCounts', function () {
      const dispatchRoute = this.get('extra.dispatchRoute');
      const vehicleCapacityCounts = this.get('vehicle.capacityCounts');
      return (0, _vehicleCapacity.isUnderCapacity)(dispatchRoute, vehicleCapacityCounts);
    })
  });

  _exports.default = _default;
});