define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback/index", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map-filter", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback/loader", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map"], function (_exports, _react, _mapFilter, _searchPanel, _playbackPanel, _playbackTables, _loader, _api, _panel, _map) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Playback = () => {
    return _react.default.createElement(_api.APIContextProvider, null, _react.default.createElement(_panel.PanelContextProvider, null, _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: "playback-widget-header"
    }, _react.default.createElement("h5", {
      className: "playback-widget-title"
    }, "Playback"), _react.default.createElement("div", {
      className: "playback-widget-controls"
    }, _react.default.createElement(_mapFilter.default, null))), _react.default.createElement("div", {
      className: "d-flex flex-column"
    }, _react.default.createElement("div", {
      className: "playback-widget-body"
    }, _react.default.createElement(_loader.default, {
      message: "Please wait while the data is loading."
    }), _react.default.createElement(_searchPanel.default, null), _react.default.createElement(_map.default, null))), _react.default.createElement(_playbackPanel.default, null), _react.default.createElement("div", {
      className: "playback-widget-table"
    }, _react.default.createElement(_playbackTables.default, null)))));
  };

  var _default = Playback;
  _exports.default = _default;
});