define("adept-iq/classes/active-contexts/schedule/schedule", ["exports", "lodash", "adept-iq/classes/active-contexts/base/base", "ember-concurrency", "adept-iq/utils/remove-focus", "adept-iq/config/environment", "adept-iq/classes/active-contexts/schedule/graph", "adept-iq/classes/active-contexts/schedule/map-graph", "adept-iq/utils/flattenData"], function (_exports, _lodash, _base, _emberConcurrency, _removeFocus, _environment, _graph, _mapGraph, _flattenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // overridden values based on extending sub classes
    // computed.readOnly('workspaceContext._structuredWorkspace')
    graph: null,
    nodes: null,
    emptyImplicitHash: null,
    workspaceData: Ember.computed.readOnly('workspaceContext._structuredWorkspace'),
    consumedModelNames: Ember.computed('workspace.dashboardInstance.widgets.[]', function () {
      const widgets = this.get('workspace.dashboardInstance.widgets');

      const widgetModels = _lodash.default.reduce(widgets, (acc, widget) => _lodash.default.union(acc, widget.get('models')), []);

      return widgetModels;
    }),
    // structured data sets (grouped by model name)
    structuredActiveData: Ember.computed('workspaceData', 'checkedItems.[]', function () {
      try {
        const workspaceData = this.get('workspaceData') || {};
        const checkedItems = this.get('checkedItems') || [];
        return this._computeActiveContext(workspaceData, checkedItems);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Exception occured while refreshing Workspace Data in structuredActiveData' + e);
      }
    }),
    structuredImplicitData: Ember.computed('workspaceData', 'checkedItems.[]', function () {
      try {
        const workspaceData = this.get('workspaceData') || {};
        const checkedItems = this.get('checkedItems') || [];
        return this._computeImplicitContext(workspaceData, checkedItems);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Exception occured while refreshing Workspace Data in structuredImplicitData' + e);
      }
    }),
    // flattened data sets for easy binding & interation
    activeData: Ember.computed('structuredActiveData', function () {
      const structuredActiveData = this.get('structuredActiveData');
      return (0, _flattenData.flattenData)(structuredActiveData);
    }),
    activeVehicles: Ember.computed('workspaceData', function () {
      const workspaceData = this.get('workspaceData');
      if (!workspaceData) return [];
      return workspaceData.vehicle;
    }),
    // map-context uses to construct layered records
    structuredMapData: Ember.computed('workspaceData', 'checkedItems.[]', function () {
      const workspaceData = this.get('workspaceData') || {};
      const checkedItems = this.get('checkedItems') || [];
      const isTraversalEnabled = this.get('isTraversalEnabled');
      if (!_environment.default.APP.test && !isTraversalEnabled) return [];
      return this._computeMapContext(workspaceData, checkedItems);
    }),
    // map-context binds to this
    implicitData: Ember.computed('structuredMapData', function () {
      const structuredMapData = this.get('structuredMapData') || {};
      return (0, _flattenData.flattenData)(structuredMapData);
    }),
    hashImplicitData: Ember.computed('structuredImplicitData', function () {
      const structuredImplicitData = this.get('structuredImplicitData') || {};
      const hash = this.get('emptyImplicitHash');

      _lodash.default.forOwn(structuredImplicitData, (value, key) => {
        hash[key] = {};
        value.forEach(v => {
          const id = v.get('id');
          hash[key][id] = true;
        });
      });

      return hash;
    }),
    // bound to computed property to implicitMarkers
    implicitStops: Ember.computed('implicitData', function () {
      const implicitData = Ember.makeArray(this.get('implicitData'));
      const stops = implicitData.filter(data => data.modelName === 'trip-stop' || data.modelName === 'route-break').mapBy('record');
      return stops;
    }),
    // bound to computed property to implicitMarkers
    implicitVehicles: Ember.computed('workspaceData', 'implicitData', function () {
      const implicitData = Ember.makeArray(this.get('implicitData'));
      const vehicles = implicitData.filterBy('modelName', 'vehicle').mapBy('record');
      const implicitVehicles = vehicles;
      return implicitVehicles;
    }),
    // bind implicitContext property to boundary
    implicitMarkers: Ember.computed('implicitVehicles.[]', 'implicitStops.[]', function () {
      const implicitStops = this.get('implicitStops');
      const implicitVehicles = this.get('implicitVehicles');
      return implicitStops.concat(implicitVehicles);
    }),

    tripActiveContext() {
      return this.get('checkedItems').some(record => {
        return record.modelName === 'trip';
      });
    },

    init() {
      this._super(...arguments);

      this.set('checkedItems', []);
      this.set('refreshedModelNames', []);
      this.set('mapGraph', _mapGraph.mapContextGraph);
      this.set('mapNodes', _mapGraph.mapContextNodes);
      this.set('graph', _graph.activeContextGraph);
      this.set('nodes', _graph.activeContextNodes);

      const nodes = _graph.activeContextNodes.reduce((acu, node) => {
        acu[node.modelName] = {};
        return acu;
      }, {});

      this.set('emptyImplicitHash', nodes);
      this.startRefreshQueue();
    },

    destroy() {
      this._super(...arguments);
    },

    willDestroyElement() {
      if (this.get('setWidgetRecordsTask.isRunning')) {
        this.get('setWidgetRecordsTask').cancelAll();
      }
    },

    // Overwrite base clearCheckedItems
    clearCheckedItems() {
      let modelName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const modelNames = Array.isArray(modelName) ? modelName : [modelName];
      const checkedItems = this.get('checkedItems');

      for (const checkedItem of checkedItems) {
        if (Ember.isNone(modelName) || modelNames.includes(checkedItem.modelName)) {
          this.toggleHighLight(checkedItem.record, false);
        }
      }

      if (Ember.isNone(modelName)) {
        this.set('checkedItems', []);
      } else {
        const updatedCheckedItems = checkedItems.filter(checkedItem => {
          return !modelName.includes(checkedItem.modelName);
        });
        this.set('checkedItems', updatedCheckedItems);
      }

      const currentCheckedItems = this.get('checkedItems'); //if any of the widget records are selected,we dont need to deselect all widgets

      if (!currentCheckedItems.length) {
        this.get('widget').deselectAllWidgets();
      }

      this.notifyPropertyChange('implicitData');
      this.refreshAll();
    },

    setWidgetRecordsTask: (0, _emberConcurrency.task)(function* (widgetRecords, widgetModelName, coreModelArgName, widgetInstanceId, checked, selectedRowLimit, maxLimitRouteAssociation, isTraversalEnabled, isNewCheck) {
      this.set('isTraversalEnabled', isTraversalEnabled);
      yield this.setWidgetRecords(widgetRecords, widgetModelName, coreModelArgName, widgetInstanceId, checked, selectedRowLimit, maxLimitRouteAssociation, isTraversalEnabled, isNewCheck);
    }).enqueue(),

    async setWidgetRecords(widgetRecords, widgetModelName, coreModelArgName, widgetInstanceId, checked, selectedRowLimit, maxLimitRouteAssociation, isTraversalEnabled, isNewCheck) {
      let checkedItems = this.get('checkedItems');
      const recordsToTraversal = [];
      const widgetId = widgetInstanceId.split('_')[1];
      (0, _removeFocus.cursorWait)();

      try {
        const store = this.get('store');

        for (const widgetRecord of widgetRecords) {
          const coreModelName = this.getCoreModelNameByWidgetRecord(coreModelArgName, widgetRecord);

          if (checked) {
            let storeRecord = store.peekRecord(coreModelName, widgetRecord.id); // call populator if route exist but tripStops does not

            if (Ember.isNone(storeRecord) || Ember.isPresent(storeRecord) && coreModelName === 'route' && _lodash.default.get(storeRecord.get('tripStops'), 'length', 0) === 0) {
              await this.get('coreEntityPopulator').populateEntityGraph(widgetModelName, widgetRecord, {});
              storeRecord = store.peekRecord(coreModelName, widgetRecord.id);
            }

            this.get('workspaceContext').manualReloadContext();

            if (storeRecord) {
              if (coreModelName === 'avlm-alert') {
                const vehicleRecord = store.peekRecord('avlm-vehicle', widgetRecord.vehicleId);
                this.toggleHighLight(vehicleRecord, checked);
              }

              if (coreModelName === 'avlm-trip') {
                const pickStopPointRecord = store.peekRecord('avlm-stop-point', widgetRecord.pickStopPointId);
                const dropStopPointRecord = store.peekRecord('avlm-stop-point', widgetRecord.dropStopPointId);
                this.toggleHighLight(pickStopPointRecord, checked);
                this.toggleHighLight(dropStopPointRecord, checked);
              }

              this.toggleHighLight(storeRecord, checked);
              const checkedWidgetRecords = checkedItems.filter(item => item.modelName === coreModelName); //filtering out checkedItems, if the storedRecord is already present in checkedItems

              const foundItem = checkedItems.find(item => item.modelName === coreModelName && storeRecord && item.record.id === storeRecord.id && widgetId === item.widgetId); // a newly added row check or an updated row that's already been checked before
              // in a different widget of same type

              if (!foundItem && isNewCheck) {
                if (coreModelName === 'trip' || coreModelName === 'trip-stop' || coreModelName === 'route-break') {
                  const selectedRouteSet = new Set();
                  checkedWidgetRecords.forEach(data => {
                    const routeId = data.record.get('route.id');
                    if (routeId) selectedRouteSet.add(routeId);
                  });

                  if (!isTraversalEnabled || selectedRouteSet.size < selectedRowLimit && checkedWidgetRecords.length < maxLimitRouteAssociation) {
                    checkedItems.push({
                      modelName: coreModelName,
                      record: storeRecord,
                      widgetId
                    });
                    recordsToTraversal.push(widgetRecord);
                  }
                } else if (!isTraversalEnabled || checkedWidgetRecords.length < selectedRowLimit) {
                  checkedItems.push({
                    modelName: coreModelName,
                    record: storeRecord,
                    widgetId
                  });
                  recordsToTraversal.push(widgetRecord);
                }
              } else {
                // Even it is not a new check, we need to update its data.
                recordsToTraversal.push(widgetRecord);
              }
            }
          } else {
            const storeRecord = store.peekRecord(coreModelName, widgetRecord.id);

            if (storeRecord) {
              if (coreModelName === 'avlm-alert') {
                const vehicleRecord = store.peekRecord('avlm-vehicle', widgetRecord.vehicleId);
                this.toggleHighLight(vehicleRecord, checked);
              }

              if (coreModelName === 'avlm-trip') {
                const pickStopPointRecord = store.peekRecord('avlm-stop-point', widgetRecord.pickStopPointId);
                const dropStopPointRecord = store.peekRecord('avlm-stop-point', widgetRecord.dropStopPointId);
                this.toggleHighLight(pickStopPointRecord, checked);
                this.toggleHighLight(dropStopPointRecord, checked);
              }

              this.toggleHighLight(storeRecord, checked);
            }

            checkedItems = checkedItems.filter(data => !(data.modelName === coreModelName && storeRecord && data.record.id === storeRecord.id && widgetId === data.widgetId));
            recordsToTraversal.push(widgetRecord);
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error occurred while populating data for the selected widget-record ' + e);
      }

      this.set('checkedItems', checkedItems.concat([])); // add traversal if traversal flag is turned on for this widget

      if (checked && isTraversalEnabled) {
        this.get('widget').addTraversalFilter(widgetInstanceId, widgetModelName, recordsToTraversal);
      } else {
        this.get('widget').removeTraversalFilter(widgetInstanceId, widgetModelName, recordsToTraversal);
      }

      this.get('widget').updateUserSelection();
      (0, _removeFocus.cursorDefault)();
    },

    setWidgetTraversalTask: (0, _emberConcurrency.task)(function* (widgetRecords, widgetModelName, widgetInstanceId, isTraversalEnabled) {
      yield this.setWidgetTraversal(widgetRecords, widgetModelName, widgetInstanceId, isTraversalEnabled);
    }).enqueue(),

    async setWidgetTraversal(widgetRecords, widgetModelName, widgetInstanceId, isTraversalEnabled) {
      if (isTraversalEnabled) {
        this.get('widget').addTraversalFilter(widgetInstanceId, widgetModelName, widgetRecords);
      } else {
        this.get('widget').removeTraversalFilter(widgetInstanceId, widgetModelName, widgetRecords);
      }
    },

    getCoreModelNameByWidgetRecord(coreModelName, widgetRecord) {
      if (coreModelName === 'avlm-canned-message' && widgetRecord.priority === 'E') {
        return 'avlm-emergency-message';
      }

      if (Array.isArray(coreModelName)) {
        if (widgetRecord.type === 'break') {
          return 'route-break';
        } else if (widgetRecord.type === 'pick' || widgetRecord.type === 'drop') {
          return 'trip-stop';
        }

        return 'stop-point';
      }

      return coreModelName;
    },

    getCoreModelNameByStoreRecord(coreModelName, storeRecord) {
      // The storeRecord not match the coreModelName
      if (coreModelName === 'avlm-canned-message' && storeRecord.modelName === 'avlm-emergency-message') {
        return 'avlm-emergency-message';
      }

      return coreModelName;
    }

  });

  _exports.default = _default;
});