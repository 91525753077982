define("adept-iq/pods/components/form-components/multi-select-dropdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dropdown-provider'],
    store: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    user: Ember.inject.service(),
    widgetService: Ember.inject.service('widget'),
    isEnable: Ember.computed('isEnabled', 'workspaceContext.providerNames', function () {
      const providerNames = this.get('workspaceContext.providerNames');
      return providerNames.includes('ALL') || providerNames.length > 1;
    }),
    isEnabled: Ember.computed.oneWay('isEnable'),
    title: '',
    isOpen: false,
    isOpened: Ember.computed.oneWay('isOpen'),
    dropDownList: Ember.computed.alias('enumList'),
    allSelectedText: Ember.computed.readOnly('allSelectedTitle'),
    multipleSelectedText: Ember.computed.readOnly('multipleSelectedTitle'),
    isDropDownListDisabled: Ember.computed.alias('isObjectDisabled'),
    disabledObject: null,
    allObjects: true,
    hasOneObject: Ember.computed.lte('dropDownList.length', 1),

    resetDisableProvider() {
      const disabledObject = this.get('disabledObject');

      if (disabledObject) {
        Ember.set(disabledObject, 'isDisabled', false);
        this.set('disabledObject', null);
      }
    },

    updateTitle: Ember.observer('dropDownList', function () {
      const dropDownList = this.get('dropDownList') || [];
      const results = dropDownList.filterBy('isChecked', true);

      if (results.length === 1) {
        this.set('title', results[0].name);
        Ember.set(results[0], 'isDisabled', true);
        this.set('disabledObject', results[0]);
        this.set('allObjects', false);
      } else if (results.length < dropDownList.length && results.length > 1) {
        this.set('title', this.get('multipleSelectedText'));
        this.set('allObjects', false);
        this.resetDisableProvider();
      } else if (dropDownList.length > 0 && results.length === dropDownList.length) {
        this.set('title', this.get('allSelectedText'));
        this.set('allObjects', true);
        this.resetDisableProvider();
      }
    }),

    init() {
      this._super(...arguments);

      this.set('allObjects', true);
      this.updateTitle();
    },

    toggleSelect() {
      this.toggleProperty('isOpen');
    },

    actions: {
      onToggleSelect() {
        this.toggleSelect();
      },

      onOpen() {
        this.toggleSelect();
      },

      onClose() {
        this.toggleSelect();
      },

      async onClickCheckBox(object, event) {
        Ember.set(object, 'isChecked', event.target.checked);
        this.updateTitle();
      }

    }
  });

  _exports.default = _default;
});