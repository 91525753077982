define("adept-iq/pods/components/iq-widgets/breakdown-replacement-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/breakdown-replacement-form-widget/config", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/utils/vehicleCapacity"], function (_exports, _component, _config, _moment, _unwrapProxy, _vehicleCapacity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    editableSections: _config.default.editableSections,
    originalReplacementStartTime: null,
    originalReplacementEndTime: null,
    classNames: ['breakdown-replacement-form-widget'],

    init() {
      this._super(...arguments);

      const records = this.get('editableRecords') || [];
      const dispatchRoute = this.get('editableRecords.firstObject');
      records.forEach(record => {
        record.set('vehicleSelectionRefreshFlag', false);
      });
      this.set('originalReplacementStartTime', dispatchRoute.get('replacementStartTime'));
      this.set('originalReplacementEndTime', dispatchRoute.get('replacementEndTime'));
    },

    didInsertElement() {
      this._super(...arguments); // side panel width just for assign-vehicle-driver-to-route


      const windowWidth = Ember.$(window).width();
      const right = windowWidth - '600';
      Ember.$('.ember-modal-dialog-target-attachment-left').css({
        'right': `${right}px`
      });
    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      const dispatchRoute = this.get('editableRecords.firstObject');
      const routeMaxTimeLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeMaxTimeLimit');
      const routeMaxTimeLimit = routeMaxTimeLimitParam ? parseInt(routeMaxTimeLimitParam.get('value'), 10) : 720; // minutes

      this.set('editModal.errors', []);
      records.forEach(record => {
        const replacementRouteId = record.get('replacementRouteId');
        const replacementStartTime = (0, _moment.default)(record.get('replacementStartTime'));
        const replacementEndTime = (0, _moment.default)(record.get('replacementEndTime'));
        const vehicleId = record.get('replacementVehicle.id');
        const driverId = record.get('replacementDriver.id');
        const routeTimeDiff = replacementEndTime.diff(replacementStartTime, 'minutes');
        const schedule = record.get('route.schedule');
        const scheduleStart = (0, _moment.default)(schedule.get('start'));
        const scheduleEnd = (0, _moment.default)(schedule.get('end'));

        if (isNaN(parseInt(replacementRouteId, 10))) {
          valid = false;
          this.get('editModal.errors').pushObject('Route ID must be a numerical entry');
        }

        if (!isNaN(parseInt(replacementRouteId, 10)) && (parseInt(replacementRouteId, 10) > 2147483648 || parseInt(replacementRouteId, 10) < 1)) {
          valid = false;
          this.get('editModal.errors').pushObject('Route ID must be between 1 to 2147483648');
        }

        if (routeTimeDiff > routeMaxTimeLimit) {
          valid = false;
          this.get('editModal.errors').pushObject(`Route length of ${routeTimeDiff} exceeds config Route Max Time Limit of ${routeMaxTimeLimit} minutes`);
        }

        if (replacementStartTime.isSameOrAfter(replacementEndTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Start Time cannot be after End Time');
        }

        if (scheduleStart.isValid() && scheduleEnd.isValid() && !(scheduleStart.isSameOrBefore(replacementStartTime) && scheduleEnd.isSameOrAfter(replacementEndTime))) {
          valid = false;
          this.get('editModal.errors').pushObject('Route duration should be within the current schedule time');
        }

        if (Ember.isEmpty(vehicleId)) {
          valid = false;
          this.get('editModal.errors').pushObject('Vehicle is required');
        } else {
          const maxTravelNeedCounts = record.get('maxTravelNeedCounts');
          const vehicleType = (0, _unwrapProxy.unwrapProxy)(record.get('replacementVehicle.vehicleType'));
          let isTravelNeedsNotMatched = false;

          if (Ember.isPresent(maxTravelNeedCounts) && Ember.isPresent(vehicleType.capacityCounts)) {
            isTravelNeedsNotMatched = (0, _vehicleCapacity.isUnderCapacity)(dispatchRoute, vehicleType.capacityCounts);
          }

          if (vehicleType.noOfAmbulatorySeats < maxTravelNeedCounts.ambulatory || vehicleType.noOfServiceAnimalSeats < maxTravelNeedCounts.serviceAnimal || vehicleType.noOfWheelChairSeats < maxTravelNeedCounts.wheelchair || vehicleType.noOfWideAmbulatorySeats < maxTravelNeedCounts.wideAmbulatory || vehicleType.noOfWideWheelChairSeats < maxTravelNeedCounts.wideWheelchair || isTravelNeedsNotMatched) {
            const tooltip = this.get('tooltip');
            const title = this.get('title');
            const editModal = this.get('service');
            tooltip.pushConfirmation({
              tip: 'You are about to assign an incompatible vehicle. All trips that cannot be serviced will be waitlisted.',
              title,
              hasOverlay: true,
              warning: true,
              primaryActionText: 'Confirm',
              primaryAction: () => {
                record.set('ignoreCapacityViolations', true);
                editModal.apply(title);
              },
              secondaryActionText: 'Cancel',
              secondaryAction: () => {
                tooltip.reset();
              }
            });
            valid = false;
          }
        }

        if (Ember.isEmpty(driverId)) {
          valid = false;
          this.get('editModal.errors').pushObject('Driver ID required');
        }
      });
      return valid;
    },

    onEnter() {
      if (this.validateRecords()) {
        const title = this.get('title');
        this.get('service').apply(title);
      }
    },

    actions: {
      onUndoClick() {
        this.set('editModal.errors', []);
        const dispatchRoute = this.get('editableRecords.firstObject');
        const originalReplacementStartTime = this.get('originalReplacementStartTime');
        const originalReplacementEndTime = this.get('originalReplacementEndTime');

        if (Ember.isPresent(dispatchRoute)) {
          dispatchRoute.rollbackAttributes();
          dispatchRoute.set('replacementDriver', null);
          dispatchRoute.set('replacementVehicle', null);
          dispatchRoute.set('replacementStartTime', originalReplacementStartTime);
          dispatchRoute.set('replacementEndTime', originalReplacementEndTime);
          dispatchRoute.set('breakdownTime', originalReplacementStartTime); // update the vehicle selection component

          dispatchRoute.toggleProperty('vehicleSelectionRefreshFlag');
        }
      },

      onApplyClick() {
        if (this.validateRecords()) {
          const title = this.get('title');
          this.get('service').apply(title);
        }
      }

    }
  });

  _exports.default = _default;
});