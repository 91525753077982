define("adept-iq/serializers/driver-phone-number", ["exports", "adept-iq/serializers/-driver"], function (_exports, _driver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _driver.default.extend({
    attrs: {
      unformattedPhoneNumber: {
        serialize: false
      }
    },

    normalize(modelClass, resourceHash) {
      resourceHash.relationships = resourceHash.relationships || {};
      resourceHash.attributes = resourceHash.attributes || {};
      const {
        phoneNumber,
        areaCode
      } = resourceHash.attributes;

      if (phoneNumber) {
        resourceHash.attributes.unformattedPhoneNumber = areaCode + phoneNumber;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});