define("adept-iq/adapters/rider-external-eligibility", ["exports", "ember-data", "adept-iq/config/api-urls"], function (_exports, _emberData, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTAdapter = _emberData.default.RESTAdapter;

  var _default = RESTAdapter.extend({
    host: _apiUrls.API.riderManagementService.host,
    session: Ember.inject.service(),

    buildURL(type, id) {
      return `${this.host}/nyct-rider-eligibility/${id}`;
    },

    headers: Ember.computed('session.data.authenticated.token', function () {
      const session = this.get('session');
      return {
        'Authorization': `Bearer ${session.data.authenticated.token}`
      };
    })
  });

  _exports.default = _default;
});