define("adept-iq/pods/components/iq-widgets/routes-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/routes-widget/config", "adept-iq/pods/components/iq-widgets/routes-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/routes-widget/scheduleConfig", "adept-iq/config/environment", "adept-iq/utils/find-row-record", "adept-iq/config/api-urls", "adept-iq/utils/unwrapProxy", "adept-iq/config/mapped-permIds", "moment", "adept-iq/utils/rql-generators", "adept-iq/mixins/version-check", "lodash", "ember-concurrency", "adept-iq/mixins/async-schedule-operation", "adept-iq/utils/filters", "adept-iq/utils/regex-functions", "adept-iq/models/route-template", "adept-iq/models/dispatch-route", "adept-iq/models/stop-point", "adept-iq/models/dashboard"], function (_exports, _component, _config, _avlmConfig, _scheduleConfig, _environment, _findRowRecord, _apiUrls, _unwrapProxy, _mappedPermIds, _moment, _rqlGenerators, _versionCheck, _lodash, _emberConcurrency, _asyncScheduleOperation, _filters, _regexFunctions, _routeTemplate, _dispatchRoute, _stopPoint, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-console */
  // display statuses
  const pathToModel = {
    'vehicleType': 'vehicle-type'
  };
  const specialRouteHost = _apiUrls.API.dispatchService.host;
  const INCLUDES = ['clusters', 'vehicleRoutes', 'vehicleRoutes.driverShifts', 'vehicleRoutes.vehicle', 'vehicleRoutes.driverShifts.driver', 'vehicleRoutes.vehicle.vehicleType', 'driverBreaks', 'driverBreaks.driverBreakEvents', 'vehicleRoutes.vehicle.vehicleEvents'];

  var _default = _component.default.extend(_asyncScheduleOperation.default, _versionCheck.default, {
    classNames: ['routes-widget', 'data-test-routes-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    dragCoordinator: Ember.inject.service(),
    routeexecevent: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspace: Ember.inject.service(),
    booking: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    widgetService: Ember.inject.service('widget'),
    providersList: Ember.computed.alias('workspaceContext._selectedProviders'),
    newComponent: 'iq-widgets/routes-widget/new',
    routesEndComponent: 'iq-widgets/routes-end-form-widget',
    routesStartComponent: 'iq-widgets/routes-start-form-widget',
    breakdownReplacementComponent: 'iq-widgets/breakdown-replacement-form-widget',
    driverBreakEditComponent: 'iq-widgets/driver-breaks-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,
    isWorkspaceExported: Ember.computed.or('workspace.isExported'),
    isDispatchDashboard: Ember.computed.readOnly('workspace.isDispatchDashboard'),
    isScheduleDashboard: Ember.computed.readOnly('workspace.isScheduleDashboard'),
    providersDict: Ember.computed('providersList', function () {
      const ProvidersList = this.get('providersList');
      const dict = {};
      ProvidersList.forEach(provider => {
        dict[provider.get('id')] = provider.get('name');
      });
      return dict;
    }),
    onScheduleExported: Ember.observer('isWorkspaceExported', function () {
      if (this.get('isWorkspaceExported')) {
        this.get('disabledTableActions').addObject('newRoute');
      } else {
        this.get('disabledTableActions').removeObject('newRoute');
      }
    }),

    init() {
      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            this.set('config', _scheduleConfig.default);
            break;

          case _dashboard.DISPATCH_ROLENAME:
          default:
            this.set('config', _config.default);
            break;
        }
      }

      this._super(...arguments);
    },

    saveRouteForDispatch(records) {
      return Ember.RSVP.all(records.map(async dispatchRoute => {
        try {
          const route = (0, _unwrapProxy.unwrapProxy)(dispatchRoute.get('route'));
          const rdv = dispatchRoute.get('latestRouteVehicleDriver');
          await rdv.save();
          await route.save();
          this.get('notifications').success(`ROUTE ${dispatchRoute.get('route.name')} WAS EDITED SUCCESSFULLY`);
        } catch (error) {
          let msg = `EDIT TO ROUTE ${dispatchRoute.get('route.name')} FAILED`;
          const e = error.errors.get('firstObject');
          if (!_lodash.default.isEmpty(e.detail) && e.detail.includes('modified by another request')) msg = `UNABLE TO UPDATE ROUTE ${dispatchRoute.get('route.name')}, BECAUSE IT HAS BEEN MODIFIED BY ANOTHER REQUEST.`;
          this.get('notifications').warning(msg);
        }
      }));
    },

    saveRouteForSchedule(records) {
      return Ember.RSVP.all(records.map(async route => {
        try {
          await route.save();
          this.get('notifications').success(`ROUTE ${route.get('name')} WAS EDITED SUCCESSFULLY`);
        } catch (error) {
          this.get('notifications').warning(`EDIT TO ROUTE ${route.get('name')} FAILED`);
        }
      }));
    },

    saveRouteStartRecordFunction(records) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      return Ember.RSVP.all(records.map(record => {
        if (Ember.isNone(record.get('dispatchRoute.assignedDriver.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot start a route that does not have a driver.'));
        }

        if (Ember.isNone(record.get('dispatchRoute.assignedVehicle.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot start a route that does not have a vehicle.'));
        }

        return routeExecEventAdapter.createStartRouteEvent(record).then(() => {
          record.set('isForceDirty', false);
          this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
          this.get('notifications').success('Route started successfully');
          return Ember.RSVP.Promise.resolve();
        }).catch(e => {
          const payloadMessage = Ember.get(e, 'payload.message') ? JSON.parse(Ember.get(e, 'payload.message')) : null;
          let messageText = 'Start route failed';

          if (e.status === 400 || e.status === 500) {
            if (payloadMessage.errorDetail.includes('Execution version is different') || payloadMessage.errorDetail.includes('it was changed by another request')) {
              messageText = `START ROUTE FAILED. THE ROUTE (${record.get('name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`;
            }
          }

          this.get('notifications').warning(messageText);
          return Ember.RSVP.Promise.reject(e);
        });
      }));
    },

    confirmSaveRouteEndFunction(records, callback) {
      let specialCallbackWhenSuccess = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      const tooltip = this.get('tooltip');
      const title = 'End Route';
      let tip = 'The route end time selected is outside the configured limit of the planned route end time. Do you want to continue?';
      return Ember.RSVP.all(records.map(record => {
        const extensionExceeded = record.get('extensionExceeded');

        if (Ember.isNone(record.get('dispatchRoute.assignedDriver.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot end a route that does not have a driver.'));
        }

        if (Ember.isNone(record.get('dispatchRoute.assignedVehicle.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot end a route that does not have a vehicle.'));
        }

        if (!extensionExceeded) {
          tip = 'Would you like to commit your changes?';
        }

        tooltip.pushConfirmation({
          tip,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            try {
              await this.saveRouteEndRecordFunction(record);
            } catch (err) {
              return;
            } finally {
              if (specialCallbackWhenSuccess) {
                await specialCallbackWhenSuccess();
              } else if (callback) {
                await callback();
              }

              tooltip.reset();
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: async () => {
            if (callback) await callback();
            tooltip.reset();
          },
          closeAction: async () => {
            if (callback) await callback();
          }
        });
      }));
    },

    async confirmSaveUndoRouteStartFunction(route) {
      const tooltip = this.get('tooltip');
      const title = 'Undo Route Start';
      const tip = 'Would you like to undo route start?';
      const dispatchRoute = route.get('dispatchRoute');
      const lockRouteAction = this.get('lockRouteAction').bind(this);
      const unlockRouteAction = this.get('unlockRouteAction').bind(this);
      this.set('dispatchRouteToCheck', dispatchRoute); // Check dispatchRoute version first

      try {
        await this.get('versionCheckOperation').perform();
        await lockRouteAction();
        tooltip.pushConfirmation({
          tip,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            try {
              await this.saveUndoRouteStartRecordFunction(route);
            } catch (e) {
              return;
            } finally {
              await unlockRouteAction();
              tooltip.reset();
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: async () => {
            await unlockRouteAction();
            return tooltip.reset();
          },
          closeAction: async () => {
            await unlockRouteAction();
            return tooltip.reset();
          }
        });
      } catch (err) {
        await unlockRouteAction();
      }
    },

    saveUndoRouteStartRecordFunction(record) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      return routeExecEventAdapter.createUndoStartRouteEvent(record).then(() => {
        record.set('isForceDirty', false);
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
        this.get('notifications').success('Started Route was undone successfully');
        return Ember.RSVP.Promise.resolve();
      }).catch(e => {
        const payloadMessage = Ember.get(e, 'payload.message') ? JSON.parse(Ember.get(e, 'payload.message')) : null;
        let messageText = 'Failed to undo started route';

        if (e.status === 400 || e.status === 500) {
          if (payloadMessage.errorDetail.includes('Execution version is different') || payloadMessage.errorDetail.includes('it was changed by another request')) {
            messageText = `FAILED TO UNDO STARTED ROUTE. THE ROUTE (${record.get('name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`;
          }
        }

        this.get('notifications').warning(messageText);
        return Ember.RSVP.Promise.reject(e);
      });
    },

    saveRouteEndRecordFunction(record) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      return routeExecEventAdapter.createEndRouteEvent(record).then(avlmVersion => routeExecEventAdapter.createCompleteRouteEvent(record, avlmVersion)).then(() => {
        record.set('isForceDirty', false);
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
        this.get('notifications').success('Route completed successfully');
        this.get('editModal').close();
        return Ember.RSVP.Promise.resolve();
      }).catch(e => {
        const payloadMessage = Ember.get(e, 'payload.message') ? JSON.parse(Ember.get(e, 'payload.message')) : null;
        let messageText = 'Failed to complete route';

        if (e.status === 400 || e.status === 500) {
          if (payloadMessage.errorDetail.includes('Execution version is different') || payloadMessage.errorDetail.includes('it was changed by another request')) {
            messageText = `FAILED TO COMPLETE ROUTE. THE ROUTE (${record.get('name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`;
          }
        }

        this.get('notifications').warning(messageText);
        this.get('editModal').close();
        return Ember.RSVP.Promise.reject(e);
      });
    },

    // 1. Create a new place in the Address service that represents the breakdown location, address and place
    // 2. Mark the vehicle as broken down in the Transit Vehicle service.
    // 3. Create a replacement route in Transit Dispatch service
    // 4. Move trips from the broken down route to the replacement route. Optional
    // 5. undo stops that have arrive status
    saveBreakdownReplacementFunction(dispatchRoutes) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const dispatchRouteAdapter = store.adapterFor('dispatch-Route');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      this.set('editModal.errors', []);
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (!Ember.isEmpty(dispatchRoutes)) {
          return dispatchRoutes.forEach(async dispatchRoute => {
            const vehicleBreakdown = (0, _unwrapProxy.unwrapProxy)(dispatchRoute.get('assignedVehicle.latestVehicleBreakdown'));
            const breakdownPlace = (0, _unwrapProxy.unwrapProxy)(vehicleBreakdown.get('place'));
            let activePicksAndDrops = [];
            let partialPerformedTrips = [];
            let address = (0, _unwrapProxy.unwrapProxy)(breakdownPlace.get('address'));

            if (Ember.isNone(address.get('streetNumber'))) {
              address.set('streetNumber', '');
            }

            try {
              // save location for start garage and end garage
              const locationResponse = await locationAdapter.postLocation(breakdownPlace.get('location.content')); // temporary assignment of zone type until we figure out how to deal with zone types

              address.set('zoneName', store.peekAll('zone-type').get('firstObject'));
              address = await addressAdapter.postAddress(breakdownPlace.get('address'));
              await addressAdapter.postAddressLocation(locationResponse, address);
              const newDispatchRoute = await dispatchRouteAdapter.postSpecialRoute(dispatchRoute, breakdownPlace);

              if (dispatchRoute.get('replacementMoveAllTrips')) {
                const stops = dispatchRoute.get('orderedStops'); // get all pick / drop stop points

                const pickOrDropStops = stops.filter(stop => stop.get('isPickOrDrop')) || []; // get all trips

                const trips = pickOrDropStops.filter(stop => stop.get('isPick')).map(stop => (0, _unwrapProxy.unwrapProxy)(stop.get('trip'))); // get all trips that are still scheduled and active

                const unperformedTrips = trips.filter(trip => !trip.get('isPickPerformed') && !trip.get('isNoShow')) || [];
                partialPerformedTrips = trips.filter(trip => trip.get('isPickPerformed') && !trip.get('isDropPerformed')) || []; // get all active stops

                activePicksAndDrops = unperformedTrips.reduce((acu, trip) => {
                  const pickStopPoint = trip.get('pickStopPoint');
                  const dropStopPoint = trip.get('dropStopPoint');
                  acu.push(...[pickStopPoint, dropStopPoint]);
                  return acu;
                }, []);
                await dispatchRouteAdapter.transferAllTripsToRouteFunction(dispatchRoute, newDispatchRoute, activePicksAndDrops, dispatchRoute.get('ignoreCapacityViolations'));
              } // give it a second to refresh the widgets because socket updates takes awhile to update
              // multiple records


              Ember.run.later(async () => {
                let avlmVersion = null; // active picks that have Arrived status should be reversed

                for (const stopPoint of activePicksAndDrops) {
                  if (stopPoint.get('stopType') === 'pick' && stopPoint.get('computedStatus') === _stopPoint.ARRIVE_STOP_STATUS) {
                    avlmVersion = await routeExecEventAdapter.createAcceptEvent(stopPoint, avlmVersion);
                  }
                } // trips that are partially performed should be completed


                for (const trip of partialPerformedTrips) {
                  const dropStopPoint = trip.get('dropStopPoint');

                  if (dropStopPoint.get('computedStatus') !== _stopPoint.PERFORMED_STOP_STATUS) {
                    if (!dropStopPoint.get('isArrived')) {
                      dropStopPoint.set('actualArriveTime', (0, _moment.default)().toDate());
                      avlmVersion = await routeExecEventAdapter.createStopArriveEvent(dropStopPoint, avlmVersion);
                    }
                  }

                  dropStopPoint.set('actualDepartTime', (0, _moment.default)().toDate());
                  avlmVersion = await routeExecEventAdapter.createStopDepartEvent(dropStopPoint, avlmVersion);
                }

                this.get('activeContext.topActiveContext').refreshTableContent.perform('vehicle');
              }, 1000);
              this.get('notifications').success(`YOU REPLACED ROUTE ${dispatchRoute.get('route.name')} WITH ROUTE ${dispatchRoute.get('replacementRouteId')} SUCCESSFULLY`);
              this.get('workspace').requestWootricSurvey('on_breakdownRoute');
              return resolve(null);
            } catch (error) {
              this.get('notifications').warning(`ROUTE BREAKDOWN REPLACEMENT FOR ROUTE ${dispatchRoute.get('route.name')} FAILED`);
              console.log(error);
              reject(error);
            }
          });
        }
      });
    },

    saveDriverBreakFunction(records) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const routeBreakAdapter = store.adapterFor('route-break');
      const dispatchRouteAdapter = store.adapterFor('dispatch-route');
      this.set('editModal.errors', []);
      return records.reduce(async (acu, record) => {
        const requestTime = record.get('requestTime');
        const projectedEndDate = record.get('projectedEndDate');
        const place = (0, _unwrapProxy.unwrapProxy)(record.get('place'));
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
        const locationExist = !Ember.isEmpty(location.get('lat'));
        let placeResponse;
        place.set('type', 'driverBreak');
        place.set('schedule', (0, _unwrapProxy.unwrapProxy)(record.get('schedule')));
        record.set('estimatedStart', requestTime);
        record.set('estimatedEnd', projectedEndDate); // temporary assignment of zone type until we figure out how to deal with
        // zone types

        address.set('zoneName', store.peekAll('zone-type').get('firstObject'));
        const rollbackOperations = []; // creating new driver break

        try {
          if (locationExist) {
            record.set('place', place);
            const locationResponse = await locationAdapter.postLocation(location);
            const addressResponse = await addressAdapter.postAddress(address);
            await addressAdapter.postAddressLocation(locationResponse, addressResponse);
            placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');

            const locationId = _lodash.default.get(locationResponse, 'data.id', null);

            const addressId = _lodash.default.get(addressResponse, 'data.id', null);

            const placeId = _lodash.default.get(placeResponse, 'data.id', null);

            if (locationId) {
              rollbackOperations.unshift(_lodash.default.bind(locationAdapter.deleteLocation, locationAdapter, locationId));
            }

            if (addressId) {
              rollbackOperations.unshift(_lodash.default.bind(addressAdapter.deleteAddress, addressAdapter, addressId));
              rollbackOperations.unshift(_lodash.default.bind(addressAdapter.deleteAddressLocation, addressAdapter, addressId));
            }

            if (placeId) {
              rollbackOperations.unshift(_lodash.default.bind(placeAdapter.deletePlace, placeAdapter, placeId));
            }
          }

          const placeId = Ember.isNone(placeResponse) ? '' : placeResponse.data.id;
          const routeBreak = await routeBreakAdapter.postRouteBreak(record, placeId);

          const routeBreakId = _lodash.default.get(routeBreak, 'data.id', null);

          if (routeBreakId) {
            rollbackOperations.unshift(_lodash.default.bind(routeBreakAdapter.deleteRouteBreak, routeBreakAdapter, routeBreak.data.id));
          }

          await acu;
          const dispatchRouteVersion = record.get('route.dispatchRoute.version');
          await dispatchRouteAdapter.createDriverBreak((0, _unwrapProxy.unwrapProxy)(record.get('route.dispatchRoute')), routeBreak.data.attributes.plannedRouteOrdinal || 1, routeBreak.data.id, dispatchRouteVersion);
          this.get('notifications').success(`Successfully added driver break to route ${record.get('route.name')}`);
          record.destroyRecord();
          this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route-break,stop-point']);
          this.get('workspace').requestWootricSurvey('on_addEditDriverBreak');
          return;
        } catch (e) {
          for (const operation of rollbackOperations) {
            await operation();
          }

          let msg = `Failed to add driver break to route ${record.get('route.name')}`;
          if (e.message && e.message.includes('modified by another request')) msg = `UNABLE TO SAVE BREAK, BECAUSE ITS ROUTE ${record.get('route.name')} HAS BEEN MODIFIED BY ANOTHER REQUEST.`;
          this.get('notifications').warning(msg);
          throw e;
        }
      }, Ember.RSVP.Promise.resolve());
    },

    async confirmSaveUndoRouteEndFunction(route) {
      const tooltip = this.get('tooltip');
      const title = 'Undo Route End';
      const tip = 'Would you like to undo route end?';
      const dispatchRoute = route.get('dispatchRoute');
      const lockRouteAction = this.get('lockRouteAction').bind(this);
      const unlockRouteAction = this.get('unlockRouteAction').bind(this);
      this.set('dispatchRouteToCheck', dispatchRoute);

      try {
        // Check dispatchRoute version first
        await this.get('versionCheckOperation').perform();
        await lockRouteAction();
        tooltip.pushConfirmation({
          tip,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            try {
              await this.saveUndoRouteEndRecordFunction(route);
            } catch (e) {
              return;
            } finally {
              await unlockRouteAction();
              tooltip.reset();
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: async () => {
            await unlockRouteAction();
            return tooltip.reset();
          },
          closeAction: async () => {
            await unlockRouteAction();
            return tooltip.reset();
          }
        });
      } catch (e) {
        await unlockRouteAction();
      }
    },

    saveUndoRouteEndRecordFunction(record) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      return routeExecEventAdapter.createUndoEndRouteEvent(record).then(() => {
        record.set('isForceDirty', false);
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
        this.get('notifications').success('Undid Route end successfully');
        return Ember.RSVP.Promise.resolve();
      }).catch(e => {
        const payloadMessage = Ember.get(e, 'payload.message') ? JSON.parse(Ember.get(e, 'payload.message')) : null;
        let messageText = 'Failed to undo ended route';

        if (e.status === 400 || e.status === 500) {
          if (payloadMessage.errorDetail.includes('Execution version is different') || payloadMessage.errorDetail.includes('it was changed by another request')) {
            messageText = `FAILED TO UNDO ENDED ROUTE. THE ROUTE (${record.get('name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`;
          }
        }

        this.get('notifications').warning(messageText);
        return Ember.RSVP.Promise.reject(e);
      });
    },

    didInsertElement() {
      this._super(...arguments);

      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        this._initDispatchSingleActions();
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            this._initScheduleSingleActions(); // Init the observer;


            this.onScheduleExported();
            break;

          case _dashboard.DISPATCH_ROLENAME:
          default:
            this._initDispatchSingleActions();

            break;
        }
      }
    },

    dragRowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),
    dynamicSingleActions: Ember.computed('table.rows.@each.{selected,status}', 'selectedWidgetRecord', function () {
      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        return this._dynamicAVLMActions();
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            return this._dynamicScheduleActions();

          case _dashboard.DISPATCH_ROLENAME:
          default:
            return this._dynamicDispatchActions();
        }
      }
    }),

    dragStart(event) {
      const row = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('dragRowGroup'));
      if (Ember.isBlank(row)) return;
      const dragId = Ember.guidFor(row);
      this.get('dragCoordinator').setSourceRow(dragId, row, 'routes');
      event.dataTransfer.setData('text', dragId);
    },

    dragEnd() {
      this.get('dragCoordinator').clearSourceRows();
    },

    saveRecordFunction(records) {
      return Ember.RSVP.all(records.map(record => {
        const {
          name,
          selectedProvider,
          selectedDriver,
          selectedVehicle,
          plannedStartTime,
          plannedEndTime
        } = record.getProperties('name', 'selectedProvider', 'selectedDriver', 'selectedVehicle', 'plannedStartTime', 'plannedEndTime');
        const ajax = this.get('ajax');
        const token = this.get('session.data.authenticated.token'); // const scheduleId = record.get('schedule.id');

        const vehicleId = selectedVehicle ? selectedVehicle.get('id') : null;
        const driverId = selectedDriver ? selectedDriver.get('id') : null;
        const providerNameId = selectedProvider ? selectedProvider.get('id') : null;
        const json = {
          data: {
            type: 'specialRoute',
            attributes: {
              name,
              routeType: 'supervisor',
              shiftStartTime: plannedStartTime,
              shiftEndTime: plannedEndTime
            },
            relationships: {
              dispatchSchedule: {
                data: {
                  id: '1',
                  // TODO: fetch this
                  type: 'dispatchSchedule'
                }
              },
              driver: {
                data: {
                  id: driverId,
                  type: 'driver'
                }
              },
              providerName: {
                data: {
                  id: providerNameId,
                  type: 'providerName'
                }
              },
              vehicle: {
                data: {
                  id: vehicleId,
                  type: 'vehicle'
                }
              }
            }
          }
        };
        return ajax.post(`${specialRouteHost}/special-route`, {
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: json
        }).then(() => {// TODO: handle success
        }).catch(() => {// TODO: show errors?
        });
      }));
    },

    async sendMessagesToRoutesAvlmLite(avlmRoutes) {
      const avlmRouteList = avlmRoutes ? avlmRoutes : [];
      const draftMessages = avlmRouteList.map(avlmRoute => {
        const driverId = avlmRoute.get('latestDriver') ? avlmRoute.get('latestDriver.id') : null;
        const vehicleId = avlmRoute.get('latestVehicle') ? avlmRoute.get('latestVehicle.callSign') : null;
        const routeId = avlmRoute.get('latestRouteVehicleDriver.route.id') ? avlmRoute.get('latestRouteVehicleDriver.route.id') : avlmRoute.get('id');

        if (!driverId) {
          this.get('notifications').warning(`Can not send message due to missing driver id on route id = ${routeId}`);
          return null;
        }

        avlmRoute.set('route', {
          id: routeId
        });
        avlmRoute.set('messageNr', '0'); // conflict due to merge r4 and r4.2.12
        //     this.get('workspace').pushState('routeActivityLog', {
        //       displayName: `Route ${routeName} Activity Log`,
        //       route: ENV.APP.avlmLite ? unwrapProxy(route) : unwrapProxy(route.get('route'))
        //     });
        //   },
        //   disableOnLocked: false,
        //   disableOnLockedRoute: false
        // });

        avlmRoute.set('avlmDriver', {
          id: driverId,
          endPointType: 'recipient',
          fullName: avlmRoute.get('latestDriver') ? avlmRoute.get('latestDriver.fullName') : null,
          badgeNumber: avlmRoute.get('latestDriver') ? avlmRoute.get('latestDriver.badgeNumber') : null
        });
        avlmRoute.set('avlmVehicle.content', {
          id: vehicleId
        });
        return avlmRoute;
      }).filter(route => !Ember.isNone(route));

      if (!Ember.isEmpty(draftMessages)) {
        this.get('workspace').pushState('addNewMessage', {
          draftMessages
        });
      }
    },

    sendMessagesToRoutes(dsRoutes, messageType) {
      const dsRouteList = dsRoutes ? dsRoutes : [];
      const draftMessages = dsRouteList.map(dsRoute => {
        const trueVehicle = (0, _unwrapProxy.unwrapProxy)(dsRoute.get('assignedVehicle'));
        const assignedDriver = trueVehicle.get('latestDriver');
        let badgeNumber = null;

        if (Ember.isNone(trueVehicle)) {
          return [];
        }

        const driverId = assignedDriver ? assignedDriver.get('id') : null;

        if (assignedDriver) {
          if (assignedDriver.get('badgeNumber')) {
            badgeNumber = assignedDriver.get('badgeNumber');
          } else {
            badgeNumber = assignedDriver.get('driver.badgeNumber');
          }
        }

        if (messageType === 'singleMessage' && !badgeNumber) {
          this.get('notifications').warning(`Can not send message due to missing driver id on vehicle id = ${trueVehicle.get('id')}`);
        }

        if (messageType === 'bulkMessage' && !badgeNumber) {
          this.get('notifications').warning('MESSAGE COULD NOT BE SENT TO SOME VEHICLES WHICH DO NOT HAVE DRIVER ASSOCIATED.');
        }

        trueVehicle.set('driver', {
          id: driverId,
          endPointType: 'recipient',
          fullName: trueVehicle.get('latestDriver.fullName'),
          badgeNumber: badgeNumber
        });
        const vehicleId = trueVehicle.get('id');
        const vehicleName = trueVehicle.get('name');
        trueVehicle.set('vehicle', {
          id: vehicleId,
          name: vehicleName
        });
        const routeId = trueVehicle.get('latestRouteVehicleDriver.route.id');
        const routeName = trueVehicle.get('latestRouteVehicleDriver.dispatchRoute.route.name');
        trueVehicle.set('route', {
          id: routeId,
          name: routeName
        });
        trueVehicle.set('messageNr', '0');

        if (_environment.default.APP.avlmLite) {
          trueVehicle.set('avlmDriver', {
            id: driverId,
            endPointType: 'recipient'
          });
          trueVehicle.set('avlmVehicle', {
            id: vehicleId
          });
        }

        return trueVehicle;
      });

      if (messageType === 'singleMessage' && draftMessages.firstObject.driver.badgeNumber) {
        this.get('workspace').pushState('addNewMessage', {
          draftMessages
        });
      } else if (messageType === 'bulkMessage') {
        this.get('workspace').pushState('addNewMessage', {
          draftMessages
        });
      }
    },

    /**
     *
     * @param route
     * @returns {*}  --> returns length of trips
     */
    routeTripLength(route) {
      if (this.isDispatchDashboard) {
        const trips = route.get('dispatchRoute.trips');
        return trips.length;
      }

      const trips = route.get('trips');
      const scheduledTrips = trips.toArray().filter(trip => trip.get('status') === 'scheduled');
      return scheduledTrips.length;
    },

    doDeleteDispatchRoute(model) {
      const route = (0, _unwrapProxy.unwrapProxy)(model.get('route'));
      const istripsAvailable = this.routeTripLength(route);
      const rvd = model.get('assignedVehicle');
      const isRVDAvailable = rvd && rvd.get('id');
      const isRouteDefaultVehicle = isRVDAvailable ? rvd.get('placeholder') === true || rvd.get('id') === route.vehicleId : true;
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      const routeName = route.get('name');
      const store = this.get('store');
      const dispatchRouteAdapter = store.adapterFor('dispatch-Route');

      if (istripsAvailable) {
        tooltip.pushConfirmation({
          title: 'Delete Route',
          tip: 'Unable to delete route because there are active trip(s) on it.',
          primaryActionText: 'Ok',
          primaryAction: () => {
            tooltip.reset();
          },
          hasOverlay: true
        });
      } else if (isRVDAvailable && !isRouteDefaultVehicle) {
        tooltip.pushConfirmation({
          title: 'Delete Route',
          tip: 'Unable to delete route because it\'s assigned to a vehicle/driver',
          primaryActionText: 'Ok',
          primaryAction: () => {
            tooltip.reset();
          },
          hasOverlay: true
        });
      } else {
        tooltip.pushConfirmation({
          title: 'Delete Route',
          tip: 'Are you sure you want to delete this route?',
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            const dispatchRouteId = model.get('id');

            try {
              if (model.get('routeBreaks.length')) {
                const routebreaks = model.get('routeBreaks');
                await Ember.RSVP.Promise.all(routebreaks.map(async stop => {
                  await dispatchRouteAdapter.removeDriverBreak(dispatchRouteId, stop.get('routeBreak.id'));
                }));
              }

              await model.destroyRecord();
              notifications.success(`SUCCESSFULLY DELETED ROUTE ${routeName}`);
              await this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
              tooltip.reset();
            } catch (error) {
              notifications.warning(`FAILED TO DELETE ROUTE ${routeName}`);
              tooltip.reset();
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          }
        });
      }
    },

    doDeleteRoute(route) {
      const istripsAvailable = this.routeTripLength(route);
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      const routeName = route.get('name');

      if (istripsAvailable) {
        tooltip.pushConfirmation({
          title: 'Delete Route',
          tip: 'Unable to delete route because there are active trip(s) on it.',
          primaryActionText: 'Ok',
          primaryAction: () => {
            tooltip.reset();
          },
          hasOverlay: true
        });
      } else {
        tooltip.pushConfirmation({
          title: 'Delete Route',
          tip: 'Are you sure you want to delete this route?',
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            try {
              if (route.get('routeBreaks.length')) {
                await this.performScheduleCancelRouteBreak.perform(route);
              }

              await route.destroyRecord();
              notifications.success(`SUCCESSFULLY DELETED ROUTE ${routeName}`);
              await this.get('activeContext.topActiveContext').clearCheckedItems(['route']);
              tooltip.reset();
            } catch (error) {
              notifications.warning(`FAILED TO DELETE ROUTE ${routeName}`);
              tooltip.reset();
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          }
        });
      }
    },

    performScheduleCancelRouteBreak: (0, _emberConcurrency.task)(function* (route) {
      const workspace = this.get('workspace');
      let currentSchedule = workspace.get('currentSchedule');

      if (this.isDispatchDashboard && Ember.isEmpty(currentSchedule)) {
        currentSchedule = (0, _unwrapProxy.unwrapProxy)(route.get('schedule'));
      }

      return yield route.get('routeBreaks').reduce(async (previousPromise, driverBreak) => {
        const routeBreakId = Number(driverBreak.get('id'));
        const operationData = {
          routeBreakId
        };
        const options = {
          operationData,
          outputResult: true
        };

        try {
          const {
            isJobSuccess
          } = await this.createScheduleAsyncOperation.perform(currentSchedule, 'cancelRouteBreak', options);

          if (!isJobSuccess) {
            throw new Error();
          } else {
            return Ember.RSVP.Promise.resolve();
          }
        } catch (e) {
          return Ember.RSVP.Promise.reject();
        }
      }, Ember.RSVP.Promise.resolve());
    }).drop(),

    doScheduleLockRoute(route) {
      const session = this.get('session');
      const notifications = this.get('notifications');
      const timeoutParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeLockTimeout');
      const routeLockTimeout = timeoutParam ? parseInt(timeoutParam.get('value'), 10) : 1800;
      const json = {
        data: {
          type: 'lock',
          attributes: {
            entityType: 'route',
            entityId: route.get('id'),
            timeToLive: routeLockTimeout,
            persist: false
          }
        }
      };
      this.get('ajax').post(_apiUrls.API.schedulingService.host + '/lock', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      }).catch(function (error) {
        notifications.warning(`Error creating route lock, ${error.message}`);
      });
    },

    lockRouteConfirmation(dispatchRoute) {
      const lockAdapter = this.get('store').adapterFor('lock');
      const tooltip = this.get('tooltip');
      const tip = `Are you sure you want to lock Route ${dispatchRoute.get('route.name')}?`;
      const title = 'Lock Route';
      tooltip.pushConfirmation({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          tooltip.reset();
          return lockAdapter.doLockRoute(dispatchRoute, false);
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    scheduleRouteLockConfirmation(route) {
      const tooltip = this.get('tooltip');
      const tip = `Are you sure you want to lock Route ${route.get('name')}?`;
      const title = 'Lock Route';
      tooltip.pushConfirmation({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          tooltip.reset();
          return this.doScheduleLockRoute(route);
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    unLockRouteConfirmation(dispatchRoute) {
      const lockAdapter = this.get('store').adapterFor('lock');
      const tooltip = this.get('tooltip');
      const tip = `Route Unlocked ${dispatchRoute.get('route.name')}`;
      const title = 'Unlock Route';
      const selectedWidgetRecord = this.get('selectedWidgetRecord');

      if (!selectedWidgetRecord.isLocked) {
        return;
      }

      tooltip.pushConfirmation({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          tooltip.reset();
          return lockAdapter.forceUnlockRoute(dispatchRoute);
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    scheduleRouteUnLockConfirmation(route) {
      const tooltip = this.get('tooltip');
      const tip = `Are you sure you want to unlock Route ${route.get('name')}?`;
      const title = 'Unlock Route';
      const selectedWidgetRecord = this.get('selectedWidgetRecord');

      if (!selectedWidgetRecord.isLocked) {
        return;
      }

      tooltip.pushConfirmation({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          tooltip.reset();
          return this.scheduleDoUnlockRoute(route);
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    scheduleDoUnlockRoute(route) {
      const session = this.get('session');
      const notifications = this.get('notifications');
      const userId = session.data.authenticated.userId;
      const scheduleRouteLock = route.get('scheduleRouteLock');
      const user = this.get('store').peekRecord('sso-user', userId);
      const permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.unlockOtherUserLocks, null);

      if (!permGranted && userId !== scheduleRouteLock.get('owner.id') && !user.get('isAdmin')) {
        notifications.info('You do not have permission to unlock this route.');
        return;
      }

      if (!Ember.isNone(scheduleRouteLock)) {
        return this.invokeScheduleUnlock(route.get('id'));
      }
    },

    _initDispatchSingleActions() {
      this.set('tableActions', [{
        id: 'newRoute',
        permId: _mappedPermIds.default.createRoute,
        name: 'New Route',
        action: () => {
          const tripLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeTripLimit');
          const distanceLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeDistanceLimit');
          let tripLimit = 9;
          let distanceLimit = 99; // prepare getting values from config service for tripLimit and distanceLimit
          // Special Route creation does not accept these 2 new variables yet.

          if (!Ember.isNone(tripLimitParam) && !Ember.isNone(tripLimitParam.get('value')) && _regexFunctions.REGEX_NUMBER.test(tripLimitParam.get('value'))) {
            tripLimit = parseInt(tripLimitParam.get('value'), 10);
          }

          if (!Ember.isNone(distanceLimitParam) && !Ember.isNone(distanceLimitParam.get('value')) && _regexFunctions.REGEX_NUMBER.test(distanceLimitParam.get('value'))) {
            distanceLimit = parseInt(distanceLimitParam.get('value'), 10);
          }

          const dispatchRouteModel = Ember.Object.create({
            schedulingMode: _routeTemplate.AUTOMATIC_SCHEDULING_MODE,
            affinities: []
          });
          const routeModel = Ember.Object.create({
            routeDate: new Date(this.workspace.startDate),
            plannedStartTime: (0, _moment.default)().toDate(),
            plannedEndTime: null,
            tripLimit: tripLimit,
            distanceLimit: distanceLimit,
            dispatchRoute: dispatchRouteModel
          });
          let minDate = null;
          minDate = new Date().getTime();
          this.get('workspace').pushState('addEditDispatchRoute', {
            displayName: 'Add Route',
            routeModel,
            minDate: (0, _moment.default)(minDate).toDate()
          }, {
            replaceSelf: true
          });
        },
        disableOnLockedRoute: true
      }, {
        id: 'print',
        permIds: [_mappedPermIds.default.downloadRoutes],
        name: 'Download',
        action: () => {
          this.exportData();
        }
      }]);
      this.set('assignVehicleDriver', {
        id: 'assignVehicleDriver',
        permId: _mappedPermIds.default.assignVehicleDriver,
        name: 'Assign Vehicle & Driver',
        action: dispatchRoute => {
          const store = this.get('store');
          const name = dispatchRoute.get('route.name');
          const routeVehicleDrivers = (0, _unwrapProxy.unwrapProxy)(dispatchRoute.get('routeVehicleDrivers')); // possibility of a route not having assigned driver and vehicle

          if (!routeVehicleDrivers.length || Ember.isNone(routeVehicleDrivers)) {
            const routeVehicleDriver = store.createRecord('route-vehicle-driver');
            dispatchRoute.get('routeVehicleDrivers').pushObject(routeVehicleDriver);
          }

          this.get('workspace').pushState('assignVehicleDriverToRoute', {
            displayName: `Assign Vehicle & Driver to Route #${name}`,
            dispatchRoute
          }, {
            replaceSelf: true
          });
        },
        disableOnLockedRoute: true
      });
      this.set('sendMessage', {
        id: 'sendMessage',
        permId: [_mappedPermIds.default.cannedMessageToDriver, _mappedPermIds.default.freeTypeMessageToDriver],
        name: 'Send Message',
        action: route => {
          if (_environment.default.APP.avlmLite) {
            this.sendMessagesToRoutesAvlmLite([route]);
          } else {
            this.sendMessagesToRoutes([route], 'singleMessage');
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('startRoute', {
        id: 'startRoute',
        permId: _mappedPermIds.default.startRoute,
        name: 'Start Route',
        model: 'vehicle-event',
        action: model => {
          const route = model.get('route');
          const routesStartComponent = this.get('routesStartComponent');
          const stops = model.get('orderedStopsWithBreaks'); //not sure if we ever have only 1 stop, or if its possible to have 0 stops

          const firstStopTime = stops !== null && stops.length > 0 ? (0, _moment.default)(stops[0].get('computedStartTime')) : (0, _moment.default)();
          const routeMaxParam = this.get('store').peekRecord('cs-config-item', 'config-Route_parameters/routeMaxExtension');
          const routeMaxExtension = routeMaxParam ? parseInt(routeMaxParam.get('value'), 10) : 60; // minutes

          const computedMinDate = (0, _moment.default)(model.get('plannedStartTime')).subtract(routeMaxExtension, 'minutes');

          const computedMaxDate = _moment.default.min([(0, _moment.default)(model.get('plannedEndTime')), (0, _moment.default)(firstStopTime), (0, _moment.default)()]);

          route.set('computedMinDate', computedMinDate);
          route.set('computedMaxDate', computedMaxDate);
          route.set('odometerInMiles', '');
          route.set('timestamp', new Date());
          this.get('editModal').open(routesStartComponent, [route], this.get('saveRouteStartRecordFunction').bind(this), null, null, {
            componentName: 'startRoute'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('undoStartRoute', {
        id: 'undoStartRoute',
        permId: _mappedPermIds.default.undoRouteStart,
        name: 'Undo Route Start',
        action: async model => {
          this.set('dispatchRouteToCheck', model); // Check dispatchRoute version first

          await this.get('versionCheckOperation').perform();
          const route = model.get('route');
          route.set('odometerInMiles', '');
          route.set('timestamp', new Date());
          this.confirmSaveUndoRouteStartFunction(route);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('endRoute', {
        id: 'endRoute',
        permId: _mappedPermIds.default.endRoute,
        name: 'End Route',
        action: model => {
          const route = model.get('route');
          const plannedEndTime = new Date();
          const routesEndComponent = this.get('routesEndComponent');
          route.set('odometerInMiles', '');
          route.set('timestamp', plannedEndTime);
          this.get('editModal').open(routesEndComponent, [route], null, null, this.get('confirmSaveRouteEndFunction').bind(this), {
            componentName: 'endRoute'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('undoEndRoute', {
        id: 'undoEndRoute',
        permId: _mappedPermIds.default.undoRouteEnd,
        name: 'Undo Route End',
        action: async model => {
          this.set('dispatchRouteToCheck', model); // Check dispatchRoute version first

          await this.get('versionCheckOperation').perform();
          const route = model.get('route');
          route.set('odometerInMiles', '');
          route.set('timestamp', new Date());
          this.confirmSaveUndoRouteEndFunction(route);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('activityLog', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLogRoute,
        name: 'Activity Log',
        action: model => {
          let routeName = model.get('route.name');

          if (_environment.default.APP.avlmLite) {
            routeName = model.get('trackingId');
          }

          this.get('workspace').pushState('routeActivityLog', {
            displayName: `Route ${routeName} Activity Log`,
            route: _environment.default.APP.avlmLite ? (0, _unwrapProxy.unwrapProxy)(model) : (0, _unwrapProxy.unwrapProxy)(model.get('route'))
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
      this.set('deleteRoute', {
        id: 'deleteRoute',
        permId: _mappedPermIds.default.deleteRoute,
        name: 'Delete Route',
        model: 'vehicle-event',
        action: model => {
          this.doDeleteDispatchRoute(model);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('addBreakdownReplacement', {
        id: 'addBreakdownReplacement',
        permId: _mappedPermIds.default.createSameDayReplacement,
        name: 'Breakdown Replacement',
        action: async model => {
          const dispatchRoute = model;
          const breakdownTime = (0, _moment.default)(dispatchRoute.get('assignedVehicle.latestVehicleBreakdown.time'));
          const lastDropStop = dispatchRoute.get('lastDropStop');
          const replacementEndTime = lastDropStop ? (0, _moment.default)(lastDropStop.get('computedEta')).add(1, 'hours') : (0, _moment.default)().add(1, 'hours');
          const breakdownReplacementComponent = this.get('breakdownReplacementComponent'); // setup new route information

          dispatchRoute.set('replacementRouteId', '');
          dispatchRoute.set('replacementStartTime', breakdownTime.toDate());
          dispatchRoute.set('breakdownTime', breakdownTime.toDate());
          dispatchRoute.set('replacementEndTime', replacementEndTime.toDate());
          dispatchRoute.set('replacementRouteType', 'breakdown replacement');
          dispatchRoute.set('replacementDriver', null);
          dispatchRoute.set('replacementVehicle', null);
          dispatchRoute.set('replacementMoveAllTrips', true); // As this option had been removed from UI, set it to true as default.

          dispatchRoute.set('breakdownPlace', dispatchRoute.get('assignedVehicle.latestVehicleBreakdown.place'));
          dispatchRoute.set('ignoreCapacityViolations', false);
          this.get('editModal').open(breakdownReplacementComponent, [dispatchRoute], this.get('saveBreakdownReplacementFunction').bind(this), null, null, {
            componentName: 'breakdownReplacement'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('rescueRoute', {
        id: 'rescueRoute',
        permId: _mappedPermIds.default.reportRescue,
        name: 'Rescue',
        action: dsRoute => {
          this.get('workspace').pushState('rescueRoute', {
            dsRoute
          }, {
            replaceSelf: true
          });
        },
        disableOnLockedRoute: true
      });
      this.set('lockRoute', {
        id: 'lockRoute',
        permId: _mappedPermIds.default.lockUnlockRoute,
        name: 'Lock Route',
        action: model => {
          this.lockRouteConfirmation((0, _unwrapProxy.unwrapProxy)(model));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('unlockRoute', {
        id: 'unlockRoute',
        permIds: [_mappedPermIds.default.lockUnlockRoute, _mappedPermIds.default.unlockOtherUserLocks],
        name: 'Unlock Route',
        action: model => {
          this.unLockRouteConfirmation((0, _unwrapProxy.unwrapProxy)(model));
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
      this.set('editRoute', {
        id: 'editRoute',
        permId: _mappedPermIds.default.editRoute,
        name: 'Edit',
        action: async model => {
          const rdv = model.get('latestRouteVehicleDriver');
          const stops = model.get('orderedStopsWithBreaks'); //not sure if we ever have only 1 stop, or if its possible to have 0 stops

          const firstStopTime = stops.length ? (0, _moment.default)(stops[0].get('computedStartTime')) : null;
          const lastStopTime = stops.length ? (0, _moment.default)(stops[stops.length - 1].get('computedStartTime')) : null;
          const firstStopMaxTime = stops.length ? (0, _moment.default)(stops[0].get('computedMaximumStartTime')) : null; // eslint-disable-next-line no-unneeded-ternary

          const computedReadOnlyCell = model.get('route.actualStartTimestamp') ? true : false;
          const routeMaxTimeLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeMaxTimeLimit');
          const routeMaxTimeLimit = routeMaxTimeLimitParam ? parseInt(routeMaxTimeLimitParam.get('value'), 10) : 720; // minutes

          const plannedStartTime = (0, _moment.default)(model.get('latestRouteVehicleDriver.startTime'));
          const plannedEndTime = (0, _moment.default)(model.get('latestRouteVehicleDriver.endTime'));
          const dispatchRouteVersion = model.get('version');
          const routeModel = model.get('route');
          const affinities = model.get('affinities');
          rdv.set('dispatchRouteVersion', dispatchRouteVersion);
          model.set('computedReadOnlyCell', computedReadOnlyCell);
          model.set('routeMaxTimeLimit', routeMaxTimeLimit);
          model.set('firstStopTime', firstStopTime);
          model.set('lastStopTime', lastStopTime);
          model.set('firstStopMaxTime', firstStopMaxTime);
          model.set('plannedTimeMin', plannedStartTime.subtract(routeMaxTimeLimit, 'minutes'));
          model.set('plannedTimeMax', plannedEndTime.add(routeMaxTimeLimit, 'minutes'));

          if (rdv) {
            if (Ember.typeOf(affinities) === 'string') {
              model.set('affinities', JSON.parse(affinities));
            }

            this.get('workspace').pushState('addEditDispatchRoute', {
              displayName: `Edit Route ${routeModel.get('name')}`,
              routeModel: routeModel
            }, {
              replaceSelf: true
            });
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('addDriverBreakAction', {
        id: 'addDriverBreak',
        permId: _mappedPermIds.default.addBreak,
        name: 'Add Driver Break',
        action: model => {
          const store = this.get('store');
          const route = (0, _unwrapProxy.unwrapProxy)(model.get('route'));
          const trips = (0, _unwrapProxy.unwrapProxy)(model.get('trip'));
          let routeOrdinal = 1; // default routeOrdianl

          if (!Ember.isEmpty(route) || !Ember.isNone(route)) {
            let plannedStartTime = (0, _moment.default)(route.get('computedPlannedStartTime'));
            const currentTime = (0, _moment.default)();

            if (currentTime.isAfter(plannedStartTime)) {
              plannedStartTime = currentTime;
            }

            if (route.get('dispatchRoute.orderedStopsWithBreaks')) {
              const lastStop = route.get('dispatchRoute.orderedStopsWithBreaks').lastObject;

              if (!Ember.isEmpty(lastStop)) {
                routeOrdinal = lastStop.get('routeOrdinal') + 1;
              }
            }

            const dispatchRouteVersion = route.get('dispatchRoute.version');
            const driverBreak = store.createRecord('route-break', {
              estimatedStart: plannedStartTime.toDate(),
              requestTime: plannedStartTime.toDate(),
              route: route,
              schedule: route.get('schedule'),
              notes: 'Driver Break',
              plannedRouteOrdinal: routeOrdinal,
              plannedDuration: 15,
              // This need to be seconds, but for UI to change it, we keep it mins for here
              state: 'planned',
              status: 'scheduled',
              promisedStart: plannedStartTime.toDate(),
              dispatchRouteVersion: dispatchRouteVersion
            });

            if (Ember.isNone(driverBreak.get('place.id'))) {
              const locationModel = store.createRecord('location');
              const addressModel = store.createRecord('address', {
                locations: [locationModel]
              });
              const placeModel = store.createRecord('place', {
                address: addressModel,
                location: locationModel
              });
              driverBreak.set('place', placeModel);
            }

            this.get('records').pushObject(driverBreak);

            if (Ember.isNone(driverBreak.get('stopPoint.id'))) {
              const stopPoint = store.createRecord('stop-point');
              driverBreak.set('stopPoint', stopPoint);
            }

            const saveDriverBreakFunction = this.get('saveDriverBreakFunction').bind(this);
            this.get('workspace').pushState('addBreak', {
              driverBreak,
              trips,
              saveDriverBreakFunction
            });
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('bulkActions', [{
        id: 'bulkMessage',
        permId: [_mappedPermIds.default.cannedMessageToDriver, _mappedPermIds.default.freeTypeMessageToDriver],
        name: 'Bulk Message',
        action: routes => {
          if (_environment.default.APP.avlmLite) {
            this.sendMessagesToRoutesAvlmLite(routes.map(route => route));
          } else {
            this.sendMessagesToRoutes(routes, 'bulkMessage');
          }
        },
        disableOnLockedRoute: true
      }]);
    },

    _initScheduleSingleActions() {
      this.get('tableActions').unshiftObject({
        id: 'newRoute',
        permId: _mappedPermIds.default.createRoute,
        name: 'New Route',
        action: () => {
          const tripLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeTripLimit');
          const distanceLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeDistanceLimit');
          let tripLimit = 9;
          let distanceLimit = 99; // prepare getting values from config service for tripLimit and distanceLimit
          // Special Route creation does not accept these 2 new variables yet.

          if (!Ember.isNone(tripLimitParam) && !Ember.isNone(tripLimitParam.get('value')) && _regexFunctions.REGEX_NUMBER.test(tripLimitParam.get('value'))) {
            tripLimit = parseInt(tripLimitParam.get('value'), 10);
          }

          if (!Ember.isNone(distanceLimitParam) && !Ember.isNone(distanceLimitParam.get('value')) && _regexFunctions.REGEX_NUMBER.test(distanceLimitParam.get('value'))) {
            distanceLimit = parseInt(distanceLimitParam.get('value'), 10);
          }

          const newRouteModel = Ember.Object.create({
            routeDate: new Date(this.workspace.startDate),
            plannedStartTime: (0, _moment.default)().toDate(),
            plannedEndTime: null,
            tripLimit: tripLimit,
            distanceLimit: distanceLimit,
            type: _routeTemplate.ROUTE_TEMPLATE_TYPE_KEEPER,
            schedulingMode: _routeTemplate.AUTOMATIC_SCHEDULING_MODE,
            optimizationFlags: 3,
            affinities: []
          });
          const minDate = new Date().getTime();
          this.get('workspace').pushState('addeditScheduleRoute', {
            displayName: 'Add Route',
            routeModel: newRouteModel,
            minDate: (0, _moment.default)(minDate).toDate()
          }, {
            replaceSelf: true
          });
        }
      });
      this.set('bulkActions', [{
        id: 'bulkEdit',
        permId: [_mappedPermIds.default.editRoute],
        name: 'Bulk Edit',
        action: async models => {
          const isTraversalEnabled = this.get('isTraversalEnabled');
          const widgetService = this.get('widgetService');
          const widgetInstanceId = widgetService.GLOBAL_WIDGET_INST;
          const modelName = 'routeWidget';
          const offset = 0;
          const limit = 5000;
          const sort = null;
          const dateQueryFields = widgetService.getDateQueryFields();
          const selectedProviders = widgetService.getAllProviders();
          const filter = this.get('widgetCompoundFilter');
          const newRouteModel = Ember.Object.create({
            routeDate: new Date(this.workspace.startDate),
            plannedStartTime: (0, _moment.default)().toDate(),
            plannedEndTime: (0, _moment.default)().endOf('day').toDate(),
            tripLimit: 0,
            distanceLimit: 0
          });
          let fullFilteredRouteIds = [];
          fullFilteredRouteIds = models.map(route => route.id);

          if (!isTraversalEnabled) {
            const fullFilteredRoutes = await widgetService.requestWidgetApi(widgetInstanceId, modelName, filter, offset, limit, sort, dateQueryFields, selectedProviders);
            const selectedRoutes = fullFilteredRoutes.filter(filteredRoute => fullFilteredRouteIds.includes(filteredRoute.id));
            fullFilteredRouteIds = selectedRoutes.map(route => route.id);
          }

          this.get('workspace').pushState('addeditScheduleRoute', {
            displayName: 'Bulk Edit',
            routeModel: newRouteModel,
            bulkRouteIds: fullFilteredRouteIds
          }, {
            replaceSelf: true
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      }]);
      this.set('activityLog', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLogRoute,
        name: 'View Activity Log',
        action: model => {
          let routeName = model.get('name');

          if (_environment.default.APP.avlmLite) {
            routeName = model.get('trackingId');
          }

          this.get('workspace').pushState('routeActivityLog', {
            displayName: `Route ${routeName} Activity Log`,
            route: _environment.default.APP.avlmLite ? (0, _unwrapProxy.unwrapProxy)(model) : model
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
      this.set('deleteScheduleRoute', {
        id: 'deleteRoute',
        permId: _mappedPermIds.default.deleteRoute,
        name: 'Delete Route',
        model: 'vehicle-event',
        action: model => {
          this.doDeleteRoute(model);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editScheduleRoute', {
        id: 'editRoute',
        permId: _mappedPermIds.default.editRoute,
        name: 'Edit',
        action: model => {
          const affinities = model.get('affinities');

          if (Ember.typeOf(affinities) === 'string') {
            model.set('affinities', JSON.parse(affinities));
          }

          this.get('workspace').pushState('addeditScheduleRoute', {
            displayName: `Edit Route ${model.get('name')}`,
            routeModel: model
          }, {
            replaceSelf: true
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('lockRoute', {
        id: 'lockRoute',
        permId: _mappedPermIds.default.lockUnlockRoute,
        name: 'Lock Route',
        action: model => {
          this.scheduleRouteLockConfirmation(model);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('unlockRoute', {
        id: 'unlockRoute',
        permId: _mappedPermIds.default.unlockOtherUserLocks,
        name: 'Unlock Route',
        action: model => {
          this.scheduleRouteUnLockConfirmation(model);
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
      this.set('schedulingAddDriverBreakAction', {
        id: 'addDriverBreak',
        permId: _mappedPermIds.default.addBreak,
        name: 'Add Driver Break',
        action: async model => {
          const routeId = model.get('id');
          const route = await this.get('store').findRecord('route', routeId, {
            include: 'trips,routeBreaks'
          });
          setTimeout(() => {
            this.get('workspace').pushState('schedulingAddBreak', {
              route
            });
          }, 1000);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
    },

    _dynamicAVLMActions() {
      const sendMessage = this.get('sendMessage');
      const startRoute = this.get('startRoute');
      const endRoute = this.get('endRoute');
      const activityLog = this.get('activityLog');
      const addBreakdownReplacement = this.get('addBreakdownReplacement');
      const rescueRoute = this.get('rescueRoute');
      const lockRoute = this.get('lockRoute');
      const unlockRoute = this.get('unlockRoute');
      const editRoute = this.get('editRoute');
      const addDriverBreakAction = this.get('addDriverBreakAction');
      const row = this.getSelectedCoreModel();
      if (!row) return [];
      const userId = this.get('session').data.authenticated.userId;
      const assignVehicleDriver = this.get('assignVehicleDriver');
      const vehicle = row.get('assignedVehicle');
      const isLocked = row.get('isLocked');
      const isLockedByOtherUser = row.get('isLockedByOtherUser');
      const computedStatus = row.get('computedStatus');
      const record = (0, _unwrapProxy.unwrapProxy)(row);
      if (Ember.isNone(record)) return [];
      const allStopsPerformed = _environment.default.APP.avlmLite ? record.allStopsPerformedAvlmlite() : record.allStopsPerformed();
      const genericActions = [sendMessage, activityLog, editRoute];
      let user;

      if (userId) {
        user = this.get('store').peekRecord('sso-user', userId);
      } // only display the locked route action for "unlocked routes"


      if (!isLocked) {
        genericActions.push(lockRoute);
      } // Check if the route is locked.  If it is, then:
      //  * if the user is an admin, or is the user that locked the route, add the 'unlock' entry, but go ahead and
      //    get the rest of the actions
      //  * if the user is not the user that locked the route, or an admin, then just show the actions that are
      //    always allowed (sendMessage and activityLog)


      if (isLocked) {
        // should not show unlock if locked by another user and non-admin
        const isUserAdmin = user && user.get('isAdmin');

        if (isLockedByOtherUser && !isUserAdmin) {
          return [sendMessage, activityLog];
        } // User is an admin, or was the user that locked the route, allow unlock


        genericActions.push(unlockRoute);
      }

      if (vehicle && vehicle.get('isBroken')) {
        genericActions.push(addBreakdownReplacement);
      }

      if (allStopsPerformed && computedStatus !== _dispatchRoute.ROUTE_COMPLETED_STATUS) {
        genericActions.push(endRoute);
      }

      switch (computedStatus) {
        case _dispatchRoute.ROUTE_SIGNED_ON_STATUS:
          genericActions.push(...[startRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_STARTED_STATUS:
        case _dispatchRoute.ROUTE_IN_PROGRESS_STATUS:
        case _dispatchRoute.ROUTE_ON_BREAK_STATUS:
          genericActions.push(...[rescueRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_COMPLETED_STATUS:
          break;

        case _dispatchRoute.ROUTE_AWAITING_STATUS:
          genericActions.push(...[assignVehicleDriver, startRoute, rescueRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_EMERGENCY_STATUS:
          genericActions.push(...[assignVehicleDriver, startRoute, rescueRoute, addDriverBreakAction]);
          break;

        default:
          genericActions.push(...[assignVehicleDriver, rescueRoute, addDriverBreakAction]);
      }

      return genericActions;
    },

    _dynamicDispatchActions() {
      const sendMessage = this.get('sendMessage');
      const startRoute = this.get('startRoute');
      const undoStartRoute = this.get('undoStartRoute');
      const endRoute = this.get('endRoute');
      const activityLog = this.get('activityLog');
      const addBreakdownReplacement = this.get('addBreakdownReplacement');
      const rescueRoute = this.get('rescueRoute');
      const lockRoute = this.get('lockRoute');
      const unlockRoute = this.get('unlockRoute');
      const editRoute = this.get('editRoute');
      const deleteRoute = this.get('deleteRoute');
      const addDriverBreakAction = this.get('addDriverBreakAction');
      const undoEndRoute = this.get('undoEndRoute');
      const row = this.getSelectedCoreModel();
      const selectedWidgetRecord = this.get('selectedWidgetRecord');
      if (!row) return [];
      const assignVehicleDriver = this.get('assignVehicleDriver');
      const vehicle = row.get('latestRouteVehicleDriver.vehicle.content');
      const driver = row.get('assignedDriver.driverId');
      const isLocked = selectedWidgetRecord.isLocked;
      const isLockedByOtherUser = this.isLockedByOtherUser(isLocked, selectedWidgetRecord.lock);
      const routeStatus = selectedWidgetRecord.routeStatus;
      const routeEnded = [_dispatchRoute.ROUTE_ENDED_STATUS].includes(row.get('computedStatus')) || row.get('pullinGarageStop.isPerformed');
      const routeStarted = row.get('hasStarted');
      const actualEndDateTime = selectedWidgetRecord.actualEndDateTime;
      const record = (0, _unwrapProxy.unwrapProxy)(row);
      const allStopsPerformed = _environment.default.APP.avlmLite ? record.allStopsPerformedAvlmlite() : record.allStopsPerformed();
      const allStopsUnPerformed = record.allStopsUnPerformed();
      const genericActions = [activityLog];
      const isBreakdown = routeStatus === _dispatchRoute.ROUTE_BREAKDOWN_STATUS;
      const isReplacementCreated = record.isReplacementRouteCreated(this.widgetRecords, selectedWidgetRecord.name);
      const doTripsExist = row.trips.length > 0; // If status is no driver and no driverId is assigned then no send message.

      if (driver && !routeEnded && !(isBreakdown && isReplacementCreated)) {
        genericActions.push(sendMessage);
      } // only display the locked route action for "unlocked routes"


      if (!isLocked && !routeEnded) {
        genericActions.push(lockRoute);
      }

      if (routeEnded && !isBreakdown) {
        genericActions.push(undoEndRoute);
      } // Check if the route is locked.  If it is, then:
      //  * if the user is an admin, or is the user that locked the route, add the 'unlock' entry, but go ahead and
      //    get the rest of the actions
      //  * if the user is not the user that locked the route, or an admin, then just show the actions that are
      //    always allowed (sendMessage and activityLog)


      if (vehicle && vehicle.get('isBroken') && routeStatus === _dispatchRoute.ROUTE_BREAKDOWN_STATUS && !routeEnded && !isReplacementCreated && routeStarted) {
        genericActions.push(addBreakdownReplacement);
      }

      if (allStopsPerformed && routeStatus !== _dispatchRoute.ROUTE_COMPLETED_STATUS && !routeEnded) {
        genericActions.push(endRoute);
      }

      if (routeStatus !== _dispatchRoute.ROUTE_COMPLETED_STATUS && !routeEnded && !isBreakdown) {
        genericActions.push(editRoute);
      }

      if (allStopsPerformed && routeStatus === _dispatchRoute.ROUTE_ENDED_STATUS) {
        genericActions.splice(2);
      }

      switch (routeStatus) {
        case _dispatchRoute.ROUTE_SIGNED_ON_STATUS:
          genericActions.push(...[startRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_SIGNED_OFF_STATUS:
          // signed off status with an empty actualEndDateTime is just a temporary signed off
          // route is not done.
          if (Ember.isNone(actualEndDateTime)) {
            if (routeStarted && allStopsUnPerformed) {
              genericActions.push(...[undoStartRoute, rescueRoute, addDriverBreakAction]);
            } else {
              genericActions.push(...[rescueRoute, addDriverBreakAction]);
            }
          }

          break;

        case _dispatchRoute.ROUTE_STARTED_STATUS:
          genericActions.push(...[undoStartRoute, rescueRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_IN_PROGRESS_STATUS:
        case _dispatchRoute.ROUTE_ON_BREAK_STATUS:
          genericActions.push(...[rescueRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_COMPLETED_STATUS:
        case _dispatchRoute.ROUTE_ENDED_STATUS:
          genericActions.push(...[sendMessage, activityLog, lockRoute]);
          break;

        case _dispatchRoute.ROUTE_NEW_STATUS:
          genericActions.push(...[assignVehicleDriver, rescueRoute, addDriverBreakAction]);
          break;
        // eslint-disable-next-line no-fallthrough

        case _dispatchRoute.ROUTE_AWAITING_STATUS:
        case _dispatchRoute.ROUTE_DISPATCHED_STATUS:
          if (driver) {
            genericActions.push(...[assignVehicleDriver, startRoute, rescueRoute, addDriverBreakAction]);
          } else {
            genericActions.push(...[assignVehicleDriver, rescueRoute, addDriverBreakAction]);
          }

          break;

        case _dispatchRoute.ROUTE_EMERGENCY_STATUS:
          genericActions.push(...[rescueRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_BREAKDOWN_STATUS:
          break;

        case _dispatchRoute.ROUTE_SCHEDULED_STATUS:
        default:
          genericActions.push(...[assignVehicleDriver, rescueRoute, addDriverBreakAction]);
      }

      if (isLocked) {
        const permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.unlockOtherUserLocks, null);

        if ((!isLockedByOtherUser || isLockedByOtherUser && permGranted) && !isBreakdown) {
          genericActions.push(unlockRoute);
        }
      }

      const deleteRouteGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.deleteRoute, null);
      const vehicleNotExistOrPlaceHolder = vehicle && vehicle.get('isPlaceholder') || !vehicle;

      if (deleteRouteGranted && vehicleNotExistOrPlaceHolder && !driver && !doTripsExist) {
        genericActions.push(deleteRoute);
      }

      return genericActions;
    },

    _dynamicScheduleActions() {
      const activityLog = this.get('activityLog');
      const editRoute = this.get('editScheduleRoute');
      const lockRoute = this.get('lockRoute');
      const unlockRoute = this.get('unlockRoute');
      const schedulingAddDriverBreakAction = this.get('schedulingAddDriverBreakAction');
      const row = this.getSelectedCoreModel();
      const deleteRoute = this.get('deleteScheduleRoute');
      if (!row) return [];
      const selectedWidgetRecord = this.get('selectedWidgetRecord');
      const isLocked = selectedWidgetRecord.isLocked;
      const isLockedByOtherUser = this.isLockedByOtherUser(isLocked, selectedWidgetRecord.lock);
      const genericActions = [activityLog, editRoute, deleteRoute];
      const isBrokerProviderType = row.get('provider.isBrokerProviderType');

      if (!isBrokerProviderType) {
        genericActions.push(schedulingAddDriverBreakAction);
      } // only display the locked route action for "unlocked routes"


      if (!isLocked) {
        genericActions.push(lockRoute);
      }

      if (isLocked) {
        const permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.unlockOtherUserLocks, null);

        if (!isLockedByOtherUser || isLockedByOtherUser && permGranted) {
          genericActions.push(unlockRoute);
        }
      }

      return genericActions;
    },

    // ember-react-table overwrites
    applyTransformationData(widgetRecords) {
      widgetRecords.forEach(widgetRecord => {
        if (!widgetRecord._transformed) {
          this.applyOtpStatusTransformation(widgetRecord);
          this.applyActualDateTransformation(widgetRecord);
          this.applyPlannedBreakTransformation(widgetRecord);
          this.applyProviderTransformation(widgetRecord);
          this.applyRouteLockInfoTransformation(widgetRecord);

          if (!_environment.default.APP.avlmLite) {
            this.applyDispatchRouteIdTransformation(widgetRecord);
            this.applyRoutePlanedTimeTransformation(widgetRecord);
          }

          widgetRecord._transformed = true;
        }
      });
      return widgetRecords;
    },

    // filtering the route based on plannedStartDate greater than current  schedule date
    applyPostFetchFilter(widgetRecords) {
      if (this.get('isScheduleDashboard')) {
        const filterRecords = this.filterScheduleRecordByPlannedStartDate(widgetRecords);
        return filterRecords;
      }

      return widgetRecords;
    },

    async getUserName(currRowData) {
      const lock = currRowData.lock;
      const store = this.get('store');
      let ssoUser = store.peekRecord('sso-user', lock.user);

      if (Ember.isNone(ssoUser)) {
        ssoUser = await store.find('sso-user', lock.user).catch(error => {
          const errorCode = Ember.isPresent(error.errors.get('firstObject').status) ? error.errors.get('firstObject').status : null; // This is a temporary hack because continuous otimization user key does not exist in sso
          // must remove after a permanent solution is found in locking in continuous optimization

          if (errorCode === '404') {
            currRowData.lock.fullName = 'Continuous Optimization';

            if (!store.hasRecordForId('sso-user', lock.user)) {
              return store.createRecord('sso-user', {
                id: lock.user,
                userId: lock.user,
                fullName: 'Continuous Optimization'
              });
            }
          }

          return null;
        });
      }

      if (!Ember.isNone(ssoUser)) {
        currRowData.lock.fullName = ssoUser.fullName;
      }
    },

    applyRouteLockInfoTransformation(widgetRecord) {
      if (widgetRecord.isLocked) {
        this.getUserName(widgetRecord);
      }
    },

    applyDispatchRouteIdTransformation(widgetRecord) {
      if (!widgetRecord.routeId) {
        widgetRecord.routeId = widgetRecord.id;
      }

      if (widgetRecord.dispatchRoutePk && this.get('isDispatchDashboard')) {
        widgetRecord.id = widgetRecord.dispatchRoutePk;
      }
    },

    applyRoutePlanedTimeTransformation(widgetRecord) {
      widgetRecord.plannedStartDateTime = widgetRecord.rvdStartTime || widgetRecord.plannedStartDateTime;
      widgetRecord.plannedEndDateTime = widgetRecord.rvdEndTime || widgetRecord.plannedEndDateTime;
    },

    applyPlannedBreakTransformation(widgetRecord) {
      if (widgetRecord.plannedBreaks && _lodash.default.isObject(widgetRecord.plannedBreaks)) {
        const objectLength = Object.keys(widgetRecord.plannedBreaks).length;
        widgetRecord.plannedBreaks = objectLength;
      }
    },

    applyProviderTransformation(widgetRecord) {
      if (widgetRecord.provider) {
        const providersList = this.get('providersDict');
        widgetRecord.provider = providersList[widgetRecord.provider];
      }
    },

    applyActualDateTransformation(widgetRecord) {
      // Need to have full datetime to do timezone transform
      if (widgetRecord.actualStartDate && widgetRecord.actualStartTime) {
        widgetRecord.actualStartDate = widgetRecord.actualStartDate + ' ' + widgetRecord.actualStartTime;
      }

      if (widgetRecord.actualEndDate && widgetRecord.actualEndTime) {
        widgetRecord.actualEndDate = widgetRecord.actualEndDate + ' ' + widgetRecord.actualEndTime;
      }
    },

    filterScheduleRecordByPlannedStartDate(widgetRecords) {
      const workspace = this.get('workspace');
      const currentScheduleStartDate = workspace.get('currentSchedule.start');
      const filterWidgetRecords = widgetRecords.filter(widgetRecord => {
        const plannedStartDate = (0, _moment.default)(widgetRecord.plannedStartDateTime).format('MM/DD/YYYY');
        const currentScheduleDate = (0, _moment.default)(currentScheduleStartDate).format('MM/DD/YYYY');
        return !(0, _moment.default)(plannedStartDate).isAfter(currentScheduleDate);
      });
      return filterWidgetRecords;
    },

    calculateTimeQuery() {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      const plannedStartDateQuery = (0, _rqlGenerators.dateGteFormatRQL)('plannedStartDate', [startTime], 'YYYY-MM-DD');
      const plannedEndDateQuery = (0, _rqlGenerators.dateLteFormatRQL)('plannedEndDate', [endTime], 'YYYY-MM-DD');
      const plannedStartTimeQuery = (0, _rqlGenerators.dateGteFormatRQL)('plannedStartTime', [startTime], 'HH:mm:ss.SSS');
      const plannedEndTimeQuery = (0, _rqlGenerators.dateLteFormatRQL)('plannedEndTime', [endTime], 'HH:mm:ss.SSS');
      const dateRangeFilterQuery = (0, _rqlGenerators.andRQL)(plannedStartDateQuery, plannedEndDateQuery);
      const timeRangeFilterQuery = (0, _rqlGenerators.andRQL)(plannedStartTimeQuery, plannedEndTimeQuery);
      const rangeFilterQuery = (0, _rqlGenerators.andRQL)(dateRangeFilterQuery, timeRangeFilterQuery);
      return rangeFilterQuery;
    },

    rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currRowData) {
      let rowStyleHandler = '';

      if (currRowData.isLocked) {
        rowStyleHandler += ' is-locked';
      }

      if (columnIndex === 0) {
        if (currRowData.otpStatus === 'Late') {
          rowStyleHandler += ' otp-late-first-column';
        }

        if (currRowData.otpStatus === 'On Time') {
          rowStyleHandler += ' otp-on-time-first-column';
        }

        if (currRowData.otpStatus === 'In Danger') {
          rowStyleHandler += ' otp-in-danger-first-column';
        }
      }

      return rowStyleHandler;
    },

    invokeScheduleUnlock(routeId) {
      const notifications = this.get('notifications');
      const session = this.get('session');
      return this.get('ajax').delete(_apiUrls.API.schedulingService.host + '/lock/force/route/' + routeId, {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).catch(function (error) {
        notifications.warning(`Error removing route lock, ${error.message}`);
      });
    },

    applyFilterQueryConversion(filters) {
      this._super(...arguments);

      filters.forEach(filter => {
        this.applyVehicleTypeFilterConversion(filter);
      });
    },

    applyVehicleTypeFilterConversion(filter) {
      if (!filter || filter.local.path !== 'vehicleType') {
        return;
      }

      const modelName = pathToModel[filter.local.path];
      const modelTypeMapper = this.get('store').peekAll(modelName).map(record => {
        return {
          id: record.id,
          name: record.name,
          displayName: record.displayName.trim().toLowerCase()
        };
      });
      const newValues = (0, _filters.applyFilterConversionToID)(filter.local.values, filter.type, modelTypeMapper);
      filter.local.values = newValues;
      filter.rql.values = newValues;
    }

  });

  _exports.default = _default;
});