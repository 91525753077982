define("adept-iq/pods/components/table/rows/base-row/component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    classNames: ['table-base-row', 'data-test-table-base-row'],
    isEditing: false
  });

  _exports.default = _default;
});