define("adept-iq/adapters/bulk-operation", ["exports", "ember-concurrency", "adept-iq/adapters/-dispatch"], function (_exports, _emberConcurrency, _dispatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TIMEOUT_DELAY = 1000; // 1 second delay

  var _default = _dispatch.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    postRecipe(recipe) {
      const session = this.get('session');
      const url = `${this.host}/bulk-operation`;
      const json = {
        data: {
          type: 'recipe',
          attributes: recipe
        }
      };
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    createBulkAsyncOperation: (0, _emberConcurrency.task)(function* (instructions) {
      let ignoreCapacityViolation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const session = this.get('session');
      const url = `${this.host}/bulk-operation?async=true&ignoreCapacityViolations=${ignoreCapacityViolation}`;
      const json = {
        data: {
          type: 'recipe',
          attributes: instructions
        }
      };
      const job = yield this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
      return yield this.get('asyncBulkOperationState').perform(job);
    }).enqueue(),
    asyncBulkOperationState: (0, _emberConcurrency.task)(function* (job) {
      const session = this.get('session');
      const url = `${this.host}/bulk-operation/`;

      while (job.data.attributes.state !== 'success' && job.data.attributes.state !== 'failure') {
        yield (0, _emberConcurrency.timeout)(TIMEOUT_DELAY);

        try {
          // eslint-disable-next-line no-param-reassign
          job = yield this.get('ajax').request(url + job.data.id, {
            method: 'GET',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          });
        } catch (e) {
          throw e;
        }
      }

      const isJobSuccess = job.data.attributes.state === 'success';
      return { ...job.data.attributes,
        isJobSuccess
      };
    }).drop()
  });

  _exports.default = _default;
});