define("adept-iq/adapters/-cs-configService", ["exports", "adept-iq/adapters/application", "adept-iq/config/api-urls"], function (_exports, _application, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    defaultSerializer: '-cs-configService',
    host: _apiUrls.API.configService.host,
    namespace: 'config',

    pathForType(type) {
      // trim 'cs' prefix from model type
      const regEx = /^(?:cs-)?(.*)$/;
      const match = regEx.exec(type)[1];
      return this._super(match);
    }

  });

  _exports.default = _default;
});