define("adept-iq/models/cluster", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    state: attr('string'),
    routeOrdinal: attr('number'),
    dispatchRoute: belongsTo('dispatch-route', {
      aysnc: false
    }),
    dispatchSchedule: belongsTo('dispatch-schedule'),
    stopPoints: hasMany('stop-point'),
    startTime: Ember.computed.alias('dispatchRoute.plannedStartTime'),
    endTime: Ember.computed.alias('dispatchRoute.plannedEndTime'),
    orderedStops: Ember.computed.sort('stopPoints', 'orderedStopsSortKeys'),
    orderedStopsSortKeys: ['routeOrdinal'],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    tripStartTime: Ember.computed('stopPoints.@each.tripStartTime', 'startTime', function () {
      const stopPoints = this.get('stopPoints').toArray();
      const validDate = this.get('isValidDate');
      let min = null;

      if (!Ember.isEmpty(stopPoints)) {
        stopPoints.forEach(stopPoint => {
          const tripStartTime = stopPoint.get('tripStartTime');

          if (min && validDate(tripStartTime)) {
            const tripTime = new Date(tripStartTime);
            min = Math.min(tripTime.getTime(), min);
          } else if (validDate(tripStartTime)) {
            const tripTime = new Date(tripStartTime);
            min = tripTime.getTime();
          }
        });
      } else {
        min = validDate(this.get('startTime')) ? new Date(this.get('startTime')).getTime() : null;
      }

      return min ? new Date(min) : null;
    }),
    tripEndTime: Ember.computed('stopPoints.@each.tripEndTime', 'endTime', function () {
      const stopPoints = this.get('stopPoints').toArray();
      const validDate = this.get('isValidDate');
      let max = validDate(this.get('endTime')) ? new Date(this.get('endTime')).getTime() : null;

      if (!Ember.isEmpty(stopPoints)) {
        stopPoints.forEach(stopPoint => {
          if (validDate(stopPoint.get('tripEndTime'))) {
            const tripEndTime = new Date(stopPoint.get('tripEndTime'));
            max = Math.max(tripEndTime.getTime(), max);
          }
        });
      }

      return max ? new Date(max) : null;
    }),
    tnVehicleCapacityItinerary: Ember.computed('orderedStops', function () {
      const initialCounts = {};
      const orderedStops = this.get('orderedStops');
      if (Ember.isEmpty(orderedStops)) return null;
      const itinerary = orderedStops.reduce((it, stopPoint) => {
        const vehicleCapacityCounts = stopPoint.get('trip.vehicleCapacityCounts');
        const stopType = stopPoint.get('stopType');
        if (stopType === 'break') return it;
        Object.entries(vehicleCapacityCounts).forEach(_ref => {
          let [travelNeedTypeName, travelNeedTypeValue] = _ref;

          // We only care about count, some travelNeedType is not count we just ignored.
          if (!isNaN(parseInt(travelNeedTypeValue, 10))) {
            if (!initialCounts[travelNeedTypeName]) {
              initialCounts[travelNeedTypeName] = travelNeedTypeValue;
            } else {
              const count = travelNeedTypeValue;

              if (stopType === 'pick') {
                initialCounts[travelNeedTypeName] += count;
              } else if (stopType === 'drop') {
                initialCounts[travelNeedTypeName] -= count;
              }
            }
          }
        });
        it.push({ ...initialCounts
        });
        return it;
      }, [{ ...initialCounts
      }]);
      return itinerary;
    }),
    totalTravelNeedCounts: Ember.computed('orderedStops', function () {
      const initialCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0,
        pca: 0,
        extraWideLift: 0,
        flexSeats: []
      };
      const maxCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0,
        pca: 0,
        extraWideLift: 0,
        flexSeats: []
      };
      let nonConsumableTravelNeeds = {};
      const orderedStops = this.get('orderedStops');
      if (Ember.isEmpty(orderedStops)) return null;
      orderedStops.forEach(stopPoint => {
        const travelNeedCounts = stopPoint.get('trip.travelNeedCounts');
        const stopType = stopPoint.get('stopType');
        if (Ember.isEmpty(travelNeedCounts)) return maxCounts;

        if (stopType === 'pick') {
          Object.entries(travelNeedCounts).forEach(_ref2 => {
            let [travelNeedTypeName] = _ref2;
            if (Ember.isNone(initialCounts[travelNeedTypeName])) return;
            initialCounts[travelNeedTypeName] += travelNeedCounts[travelNeedTypeName];
          });
          nonConsumableTravelNeeds = (0, _lodash.merge)(nonConsumableTravelNeeds, travelNeedCounts.nonConsumables);
        } else if (stopType === 'drop') {
          Object.entries(travelNeedCounts).forEach(_ref3 => {
            let [travelNeedTypeName] = _ref3;
            if (Ember.isNone(initialCounts[travelNeedTypeName])) return;
            initialCounts[travelNeedTypeName] -= travelNeedCounts[travelNeedTypeName];
          });
        }

        Object.entries(initialCounts).forEach(_ref4 => {
          let [travelNeedTypeName, count] = _ref4;
          maxCounts[travelNeedTypeName] = Math.max(maxCounts[travelNeedTypeName], count);
        });
      });
      return {
        nonConsumableTravelNeeds,
        maxTravelNeedCounts: maxCounts
      };
    }),
    nonConsumableTravelNeeds: Ember.computed.alias('totalTravelNeedCounts.nonConsumableTravelNeeds'),
    maxTravelNeedCounts: Ember.computed.alias('totalTravelNeedCounts.maxTravelNeedCounts'),
    maxVehicleCapacityNeedCounts: Ember.computed('tnVehicleCapacityItinerary', function () {
      const tnVehicleCapacityItinerary = this.get('tnVehicleCapacityItinerary');
      return tnVehicleCapacityItinerary.reduce((result, vehicleCapacity) => {
        Object.keys(vehicleCapacity).forEach(key => {
          result[key] = result[key] ? Math.max(result[key], vehicleCapacity[key]) : vehicleCapacity[key];
        });
        return result;
      }, {});
    }),

    isValidDate(dateTime) {
      const daTime = new Date(dateTime);
      return daTime instanceof Date && !isNaN(daTime);
    },

    // Consider adding this into Mixins
    saveWithoutSubmit() {
      const modelName = this.constructor.modelName;
      const adapter = this.get('store').adapterFor(modelName);
      adapter.set('preventSave', true);
      return this.save();
    }

  });

  _exports.default = _default;
});