define("adept-iq/pods/components/iq-widgets/vehicles-widget/avlmConfig", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/vehicles-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'avlm-vehicle',
    title: 'Vehicles',
    defaultSortId: 'otpStatus',
    defaultSortAsc: false,
    // performance table attributes
    widgetName: 'vehicleWidget',
    initialLimit: 5000,
    paginationLimit: 100,
    maxLimitByRouteSelection: 5,
    maxLimitWithoutRouteAssociation: 20,
    selectedRowLimit: 20,
    columns: [{
      id: 'name',
      index: 0,
      type: 'text',
      label: 'ID',
      valuePath: 'id',
      valuePreview: '1',
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'typeName',
      index: 1,
      type: 'text',
      label: 'Type',
      valuePath: 'type',
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 60,
      unAvailable: true
    }, {
      id: 'availability',
      index: 2,
      type: 'enum',
      label: 'Availabilities',
      valuePath: 'vehicleAvailibities',
      cellDesc: 'Availabilities',
      isMapLabelVisible: true,
      defaultWidth: 100,
      unAvailable: true
    }, {
      id: 'breaks',
      index: 3,
      type: 'enum',
      label: 'Breaks',
      valuePath: 'formattedBreaks',
      cellDesc: 'Breaks',
      isMapLabelVisible: true,
      defaultWidth: 100,
      unAvailable: true
    }, {
      id: 'noOfAmbulatorySeats',
      index: 4,
      type: 'number',
      label: 'Ambulatory seats',
      valuePath: 'noOfAmbulatorySeats',
      editable: true,
      defaultWidth: 60,
      highlightable: false,
      unAvailable: true
    }, {
      id: 'noOfWheelChairSeats',
      index: 5,
      type: 'number',
      label: 'Wheelchair spaces',
      valuePath: 'noOfWheelChairSeats',
      editable: true,
      defaultWidth: 60,
      highlightable: false,
      unAvailable: true
    }, {
      id: 'active',
      index: 6,
      type: 'boolean',
      label: 'Active',
      valuePath: 'active',
      editable: true,
      defaultWidth: 45,
      unAvailable: true
    }, {
      id: 'routeName',
      index: 7,
      type: 'text',
      label: 'Route name',
      valuePath: 'routeName',
      mapValuePath: 'currentRoute.id',
      editable: true,
      hidden: true // unAvailable: true

    }, {
      id: 'driverId',
      index: 8,
      type: 'text',
      label: 'Driver Id',
      valuePath: 'driverId',
      mapValuePath: 'currentDriver.driverId',
      editable: true,
      isMapLabelVisible: true,
      defaultWidth: 45,
      hidden: true
    }, {
      id: 'driverName',
      index: 9,
      type: 'text',
      label: 'Driver Name',
      valuePath: 'driverName',
      mapValuePath: 'driverFullName',
      editable: true,
      hidden: true
    }, {
      id: 'garageStartAddress',
      index: 10,
      type: 'text',
      label: 'Starting Garage',
      valuePath: 'startingGarage',
      editable: true,
      hidden: true,
      unAvailable: true
    }, {
      id: 'garageEndAddress',
      index: 11,
      type: 'text',
      label: 'Ending Garage',
      valuePath: 'endingGarage',
      editable: true,
      hidden: true,
      unAvailable: true
    }, {
      id: 'driverAPPVersion',
      index: 13,
      type: 'text',
      label: 'Driver App Version',
      valuePath: 'driverAppVersion',
      editable: true,
      hidden: true,
      unAvailable: true
    }, {
      id: 'cellularCarrier',
      index: 16,
      type: 'text',
      label: 'Cellular Carrier',
      valuePath: 'cellularCarrier',
      editable: true,
      unAvailable: true
    }, {
      id: 'otpStatus',
      index: 18,
      type: 'text',
      label: 'OTP Status',
      valuePath: 'otpStatus',
      mapValuePath: 'otp',
      highlightable: true
    }, {
      id: 'driverAppId',
      index: 14,
      type: 'text',
      label: 'Driver  App Id',
      valuePath: 'driverAppId',
      editable: true,
      hidden: true,
      unAvailable: true
    }, {
      id: 'hardewareId',
      index: 15,
      type: 'text',
      label: 'Hardware Id',
      valuePath: 'hardwareId',
      editable: true,
      hidden: true,
      unAvailable: true
    }, {
      id: 'cellularType',
      index: 17,
      type: 'text',
      label: 'Cellular Type',
      valuePath: 'cellularType',
      editable: true,
      hidden: true,
      unAvailable: true
    }, {
      id: 'provider',
      index: 19,
      type: 'string',
      label: 'Provider',
      valuePath: 'provider',
      mapValuePath: 'provider.name',
      valuePreview: 'MAG',
      hidden: true,
      highlightable: false
    }, {
      id: 'vehicleStatus',
      index: 20,
      type: 'string',
      label: 'Vehicle Status',
      valuePath: 'status',
      mapValuePath: 'vehicleStatus',
      hidden: false,
      highlightable: false,
      isMapLabelVisible: true
    }, {
      id: 'gpsTimeStamp',
      index: 21,
      type: 'datetime',
      label: 'Last GPS Ping Time',
      valuePath: 'lastGpsPingTime',
      mapValuePath: 'timestamp',
      format: `${_environment.default.dateTimeFormat.dateTimeMoment}`,
      cellItem: 'DateLocalCellItem',
      valuePreview: '',
      defaultWidth: 110,
      hidden: false,
      highlightable: false,
      unAvailable: false
    }, {
      id: 'lat',
      index: 22,
      type: 'float',
      label: 'Last GPS Ping Lat',
      valuePath: 'lastGpsPingLat',
      mapValuePath: 'lat',
      valuePreview: '',
      hidden: false,
      highlightable: false,
      unAvailable: false
    }, {
      id: 'lng',
      index: 23,
      type: 'float',
      label: 'Last GPS Ping Lng',
      valuePath: 'lastGpsPingLng',
      mapValuePath: 'lng',
      valuePreview: '',
      hidden: false,
      highlightable: false,
      unAvailable: false
    }],
    traversal: {
      tripWidget: {
        links: [{
          type: 'stringEq',
          field: 'routeName',
          valuePath: 'routeName'
        }]
      },
      routeWidget: {
        links: [{
          type: 'stringEq',
          field: 'routeName',
          valuePath: 'name'
        }]
      },
      alertWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'vehicleId'
        }]
      },
      stopWidget: {
        links: [{
          type: 'stringEq',
          field: 'routeName',
          valuePath: 'routeName'
        }]
      },
      messageWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'vehicleId'
        }]
      }
    }
  };
  _exports.default = _default;
});