define("adept-iq/topic-transforms/avlm-trip-rider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload
  /*, ENV*/
  ) {
    const transformedPayloads = [];
    const {
      schedule,
      trip,
      rider
    } = payload.data.relationships;

    if (schedule) {
      payload.data.relationships.avlmSchedule = schedule;
      delete payload.data.relationships.schedule;
    }

    if (rider) {
      payload.data.relationships.avlmRider = rider;
      delete payload.data.relationships.rider;
    }

    if (trip) {
      payload.data.relationships.avlmTrip = trip;
      delete payload.data.relationships.trip;
    }

    transformedPayloads.push(payload);
    return transformedPayloads;
  }
});