define("adept-iq/models/driver-provider", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    siteId: attr('string'),
    pointsReductionTraining: attr('iso-date', {
      defaultValue: null
    }),
    classroomTrainingDate: attr('iso-date', {
      defaultValue: null
    }),
    refresherTrainingDate: attr('iso-date', {
      defaultValue: null
    }),
    sensitivityTrainingDate: attr('iso-date', {
      defaultValue: null
    }),
    firstAidTrainingDate: attr('iso-date', {
      defaultValue: null
    }),
    employmentStartDate: attr('iso-date', {
      defaultValue: null
    }),
    employmentEndDate: attr('iso-date', {
      defaultValue: null
    }),
    driver: belongsTo('driver'),
    provider: belongsTo('provider'),
    driverPositionName: belongsTo('driver-position-name'),
    employmentStatusName: belongsTo('employment-status-name'),
    terminationReasonName: belongsTo('termination-reason-name')
  });

  _exports.default = _default;
});