define("adept-iq/serializers/cs-config-category", ["exports", "adept-iq/serializers/-cs-configService"], function (_exports, _csConfigService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigService.default.extend({
    normalizeResponse(store, primaryModelClass, payload) {
      Ember.makeArray(payload.data).setEach('type', 'cs-config-category');
      return payload;
    }

  });

  _exports.default = _default;
});