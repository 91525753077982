define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/current-polyline", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/map", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api"], function (_exports, _react, _map, _panel, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const CurrentPolyline = () => {
    const {
      map
    } = _react.default.useContext(_map.MapContext);

    const {
      sliderIndex
    } = (0, _panel.usePanel)();
    const {
      eventData
    } = (0, _api.useAPI)();

    _react.default.useEffect(() => {
      if (!map || eventData.length <= sliderIndex) return;
      const currentSection = eventData[sliderIndex].section;
      const points = eventData.filter(event => event.displayLat !== 'N/A' && event.displayLng !== 'N/A' && event.section === currentSection).map(event => [Number(event.displayLat), Number(event.displayLng)]);
      const polyline = window.L.polyline(points, {
        color: 'blue',
        opacity: 1,
        weight: 3
      });
      polyline.addTo(map.native);
      return () => map.native.removeLayer(polyline);
    }, [eventData, sliderIndex]);

    return _react.default.createElement(_react.default.Fragment, null);
  };

  var _default = CurrentPolyline;
  _exports.default = _default;
});