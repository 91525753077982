define("adept-iq/pods/components/side-drawer/unperform-pick-stop/component", ["exports", "ember-concurrency", "adept-iq/config/environment", "adept-iq/utils/unwrapProxy", "adept-iq/models/stop-point", "adept-iq/mixins/version-check"], function (_exports, _emberConcurrency, _environment, _unwrapProxy, _stopPoint, _versionCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const paymentTypeOptions = ['Cash', 'Credit Card', 'Smart Card'];
  const TravelNeedItem = Ember.Object.extend({
    type: null,
    count: null
  }); // "service animal" is the one client travel need not shown in dropdowns

  const dropdownExemptedTravelNeedTypeNames = ['serviceAnimal'];

  var _default = Ember.Component.extend(_versionCheck.default, {
    classNames: ['perform-stop-drawer'],
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    timeFormat: _environment.default.dateTimeFormat.timeFlatPickr,
    // passed in by side-drawer-container component
    onShrinkClick: null,
    onCloseClick: null,
    pcaCount: null,
    serviceAnimalCount: null,
    companionCount: null,
    travelNeedItems: null,
    _travelNeedTypesQuery: null,
    errorText: null,
    unperformTitle: 'Unperform',
    unperformMessage: 'Unperforming Stop will NOT change Promise Time.',
    canSave: Ember.computed('arrive', 'depart', function () {
      return this.get('arrive') || this.get('depart');
    }),

    init() {
      this._super(...arguments);

      const stopPoint = this.get('stopPoint'); // only need to do this query once

      const travelNeedTypesQuery = this.get('store').findAll('travel-need-type');
      this.set('amount', stopPoint.get('trip.fare'));
      this.set('selectedPaymentType', stopPoint.get('trip.paymentType'));
      this.set('_travelNeedTypesQuery', travelNeedTypesQuery);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.get('refreshTask').perform();
    },

    stopPoint: Ember.computed.readOnly('stackFrame.options.stopPoint'),
    dispatchRouteVersionSnapshot: Ember.computed.readOnly('stackFrame.options.dispatchRouteVersionSnapshot'),
    title: Ember.computed.readOnly('stackFrame.options.title'),
    trip: Ember.computed.readOnly('stopPoint.trip'),
    route: Ember.computed.readOnly('stopPoint.route'),
    rider: Ember.computed.readOnly('trip.rider'),
    address: Ember.computed.readOnly('stopPoint.tripStop.segmentStop.place.address'),
    routeVehicleDriver: Ember.computed.readOnly('route.dispatchRoute.latestRouteVehicleDriver'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    isArrived: Ember.computed.equal('stopPoint.computedStatus', _stopPoint.ARRIVE_STOP_STATUS),
    isPerformed: Ember.computed.equal('stopPoint.computedStatus', _stopPoint.PERFORMED_STOP_STATUS),
    refreshTask: (0, _emberConcurrency.task)(function* () {
      const isArrived = this.get('isArrived');
      const isPerformed = this.get('isPerformed');

      if (isArrived) {
        this.set('arrive', isArrived);
      } else if (isPerformed) {
        this.set('arrive', isPerformed);
        this.set('depart', isPerformed);
      }

      const travelNeedTypes = yield this.get('_travelNeedTypesQuery');
      const stopPoint = this.get('stopPoint'); // "Travel Needs" panel

      const travelNeedOptions = travelNeedTypes.reduce((arr, travelNeedType) => {
        const name = travelNeedType.get('name');
        const displayName = travelNeedType.get('displayName'); // e.g. don't include "serviceAnimal" since it has its own UI

        if (dropdownExemptedTravelNeedTypeNames.includes(name)) return arr;
        arr.push({
          type: name,
          label: displayName || name
        });
        return arr;
      }, []);
      this.set('travelNeedOptions', travelNeedOptions); // dropdown travel needs

      const travelNeeds = stopPoint.get('trip.segment.leg.legTravelNeeds');
      const clientTravelNeeds = travelNeeds.filterBy('isClient', true);
      const travelNeedItems = travelNeedOptions.reduce((arr, _ref) => {
        let {
          type
        } = _ref;
        const travelNeed = clientTravelNeeds.findBy('travelNeedTypeName', type);
        const count = travelNeed ? travelNeed.get('count') : 0;
        if (Ember.isNone(count) || count === 0) return arr;
        const travelNeedItem = TravelNeedItem.create({
          type,
          count
        });
        arr.push(travelNeedItem);
        return arr;
      }, []);
      this.set('travelNeedItems', travelNeedItems); // additional travel needs

      const serviceAnimalTravelNeed = clientTravelNeeds.findBy('isServiceAnimal', true);
      const ambulatoryTravelNeeds = travelNeeds.filterBy('isAmbulatory', true);
      const pcaTravelNeed = ambulatoryTravelNeeds.findBy('isPca', true);
      const companionTravelNeed = ambulatoryTravelNeeds.findBy('isCompanion', true);
      this.set('serviceAnimalCount', serviceAnimalTravelNeed ? serviceAnimalTravelNeed.get('count') : 0);
      this.set('pcaCount', pcaTravelNeed ? pcaTravelNeed.get('count') : 0);
      this.set('companionCount', companionTravelNeed ? companionTravelNeed.get('count') : 0); // "Fare" panel

      this.set('paymentTypeOptions', paymentTypeOptions);
    }).keepLatest(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const stopPoint = (0, _unwrapProxy.unwrapProxy)(this.get('stopPoint'));
      const routeExecEventAdapter = store.adapterFor('route-exec-event'); // eslint-disable-next-line no-unused-vars

      let avlmVersion = null; // if user checks the arrive then it also means
      // that the depart is also checked. Send accept
      // to remove both arrive and depart in AVLM

      if (this.get('arrive')) {
        avlmVersion = yield routeExecEventAdapter.createAcceptEvent(stopPoint, avlmVersion);
      } else if (this.get('depart')) {
        avlmVersion = yield routeExecEventAdapter.createAcceptEvent(stopPoint, avlmVersion);
        let arriveTimestamp = stopPoint.get('actualArriveTime');

        if (Ember.isNone(arriveTimestamp)) {
          arriveTimestamp = stopPoint.get('actualDepartTime');
        }

        stopPoint.set('arriveTimestamp', arriveTimestamp); // sending another arrive event will unperform the depart
        // for this stop

        avlmVersion = yield routeExecEventAdapter.createStopArriveEvent(stopPoint, avlmVersion);
      }

      yield this.get('versionUpdateOperation').perform();
    }).drop(),
    actions: {
      noop() {
        return false;
      },

      onRefreshButtonClick() {
        this.get('refreshTask').perform();
      },

      onCancelButtonClick() {
        this.onCloseClick();
      },

      async onPerformButtonClick() {
        const lockRouteAction = this.get('lockRouteAction').bind(this);
        const unlockRouteAction = this.get('unlockRouteAction').bind(this);

        try {
          // Check dispatchRoute version first
          await this.get('versionCheckOperation').perform(this.onCloseClick.bind(this));
          await lockRouteAction(); // Reset error text

          this.set('errorText');
          const tooltip = this.get('tooltip');
          const stopPoint = (0, _unwrapProxy.unwrapProxy)(this.get('stopPoint'));
          const trip = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('trip'));
          const otherStopPoint = (0, _unwrapProxy.unwrapProxy)(trip.get('dropStopPoint'));
          const title = 'Unperform Pick Stop';
          let tip = 'Unperforming pick stop?';
          let message = 'Unperformed stop';

          if (otherStopPoint.get('isPerformed')) {
            tip = 'Unperforming trip';
            message = 'Unperformed Trip';
          }

          this.get('tooltip').pushConfirmation({
            tip: tip,
            title,
            hasOverlay: true,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return this.get('saveTask').perform().then(() => {
                this.get('notifications').success(`${message} successfully`);
              }).catch(response => {
                const payloadMessage = Ember.get(response, 'payload.message') ? JSON.parse(Ember.get(response, 'payload.message')) : null;
                let messageText = 'Unperformed stop failed';

                if (response.status === 400 || response.status === 500) {
                  if (payloadMessage.errorDetail.includes('Execution version is different') || payloadMessage.errorDetail.includes('it was changed by another request')) {
                    messageText = `UNPERFORM STOP FAILED. THE ROUTE (${this.get('route.name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`;
                  }
                }

                this.get('notifications').warning(`${messageText}`);
              }).finally(async () => {
                await unlockRouteAction(0);
                this.get('tooltip').reset();
                return this.onCloseClick();
              });
            },
            secondaryActionText: 'Cancel',
            secondaryAction: async () => {
              await unlockRouteAction(0);
              tooltip.reset();
              return;
            },
            closeAction: async () => {
              await unlockRouteAction(0);
            }
          });
        } catch (e) {
          await unlockRouteAction();
        }
      }

    }
  });

  _exports.default = _default;
});