define("adept-iq/pods/components/iq-widgets/history-routes-widget/cells/editable/component", ["exports", "adept-iq/config/environment", "moment", "lodash"], function (_exports, _environment, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['editable-cell', 'no-spinner'],
    notifications: Ember.inject.service(),
    editModal: Ember.inject.service(),
    isEditing: false,
    buffer: null,
    isNumberType: false,
    isDateType: false,
    dateTimeFormat: _environment.default.dateTimeFormat,
    isEditClick: Ember.computed.and('row.isEditing', 'column.isEditing'),
    onIsEditingChange: Ember.observer('isEditClick', function () {
      if (this.get('isEditClick')) {
        this.startEditing();
      } else {
        this.discardChanges();
      }
    }),

    init() {
      this._super(...arguments);

      this.resetProperties();
      const reconcileTripDate = new Date((0, _moment.default)(this.get('reconcile.reconcileDate')).format('YYYY-MM-DD'));
      Ember.set(this, 'timeRange', {
        startofday: (0, _moment.default)(reconcileTripDate).add(+1, 'day').endOf('day').toISOString(),
        endofday: (0, _moment.default)(reconcileTripDate).startOf('day').toISOString()
      });
    },

    startEditing() {
      if (this.get('isEditing')) return;
      this.set('isEditing', true);
      const row = this.get('row');
      const column = this.get('column');
      const valuePath = column.get('valuePath');
      const columnId = column.get('id');
      this.setValuesTypeFlags(columnId);
      const value = row.get(valuePath);
      const buffer = value ? value.toString() : '';
      this.set('buffer', buffer);
      column.set('newValue', buffer);

      if (columnId === 'actualArrivalTime' || columnId === 'actualDepartTime') {
        const startTimeFormat = (0, _moment.default)(buffer).subtract(-2, 'days').toISOString();
        this.set('timeRange.startTime', buffer);
        this.set('timeRange.startOfDay', startTimeFormat);
      }
    },

    resetProperties() {
      this.setProperties({
        isNumberType: false,
        isDateType: false
      });
    },

    setColumnValue(value) {
      const column = this.get('column');

      if (column) {
        column.set('newValue', value);
      }

      this.enableDisableSaveBtn(column);
    },

    enableDisableSaveBtn(column) {
      const oldValue = this.getFormattedValue(column, 'oldValue');
      const newValue = this.getFormattedValue(column, 'newValue');

      if (oldValue !== newValue) {
        column.set('editingStart', true);
      } else {
        column.set('editingStart', false);
      }
    },

    getFormattedValue(column, valueType) {
      let formattedValue = '';
      const value = column.get(valueType);
      const format = column.get('format');
      const formatValue = _lodash.default.isFunction(format) ? format(value) : format;
      const dataType = column.get('dataType');

      if (dataType === 'time') {
        formattedValue = (0, _moment.default)(value).format(formatValue);
      }

      if (dataType === 'number') {
        formattedValue = value ? value.toString() : '';
      }

      return formattedValue;
    },

    setValuesTypeFlags(columnId) {
      switch (columnId) {
        case 'actualArrivalTime':
        case 'actualDepartTime':
          Ember.set(this, 'isDateType', true);
          break;

        case 'odometerOnArrival':
          Ember.set(this, 'isNumberType', true);
          break;

        default:
          break;
      }
    },

    discardChanges() {
      if (!this.get('isEditing')) return;
      this.set('isEditing', false);
      this.set('row.isEditing', false);
      this.set('column.isEditing', false);
      this.set('column.newValue', null);
      this.set('column.editingStart', null);
    },

    actions: {
      onInput(text) {
        this.set('buffer', text);
        this.setColumnValue(text);
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
        this.setColumnValue((0, _moment.default)(this.timeRange.startTime));
      }

    }
  });

  _exports.default = _default;
});