define("adept-iq/serializers/segment", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    serialize() {
      const json = this._super(...arguments);

      const fareTypeRelationship = json.data.relationships.fareType || {};

      if (!Ember.isNone(fareTypeRelationship) && !Ember.isNone(fareTypeRelationship.data)) {
        fareTypeRelationship.data.type = 'fareTypeName';
        json.data.relationships.fareTypeName = json.data.relationships.fareType;
      }

      if (!Ember.isNone(fareTypeRelationship)) {
        delete json.data.relationships.fareType;
      }

      const fareCategoryRelationship = json.data.relationships.fareCategory || {};

      if (!Ember.isNone(fareCategoryRelationship)) {
        if (!Ember.isNone(fareCategoryRelationship.data)) {
          fareCategoryRelationship.data.type = 'fareCategoryName';
          json.data.relationships.fareCategoryName = json.data.relationships.fareCategory;
        }

        delete json.data.relationships.fareCategory;
      }

      if (!Ember.isNone(json.data.attributes)) {
        const noSharing = json.data.attributes.noSharing;
        const segmentType = json.data.attributes.segmentType;
        const fare = json.data.attributes.fare;
        json.data.attributes.noSharing = noSharing ? noSharing : 0;
        json.data.attributes.segmentType = segmentType ? segmentType : 0;
        json.data.attributes.fare = fare ? Math.round(fare * 1000) / 1000 : 0;
        delete json.data.attributes.tripMinDate;
        delete json.data.attributes.tripMaxDate;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.relationships)) {
        if (!Ember.isNone(resourceHash.relationships.fareTypeName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.fareType = {
            data: {
              type: 'fare-type',
              id: resourceHash.relationships.fareTypeName.data.id
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.fareTypeName;
        }

        if (!Ember.isNone(resourceHash.relationships.fareCategoryName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.fareCategory = {
            data: {
              type: 'fare-category',
              id: resourceHash.relationships.fareCategoryName.data.id
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.fareCategoryName;
        }
      }

      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.fareTypeName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.fareType = {
          data: {
            type: 'fare-type',
            id: resourceHash.attributes.fareTypeName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.fareTypeName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});