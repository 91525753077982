define("adept-iq/pods/components/rider-management-widget/rider-suspension/component", ["exports", "ember-concurrency", "lodash", "adept-iq/utils/unwrapProxy", "moment"], function (_exports, _emberConcurrency, _lodash, _unwrapProxy, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    rider: Ember.inject.service(),
    riderSuspensionRecord: null,
    classNames: ['rider-reasonDenied-options-select'],
    riderSuspensionReasons: null,
    riderSuspensionReasonOptions: null,
    riderId: Ember.computed.readOnly('riderChangeSet.id'),
    tableRef: null,
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    startDate: Ember.computed('riderSuspensionRecord.startDate', function () {
      const startDate = this.get('riderSuspensionRecord.startDate');
      return startDate ? startDate : null;
    }),
    endDate: Ember.computed('riderSuspensionRecord.endDate', function () {
      const endDate = this.get('riderSuspensionRecord.endDate');
      return endDate ? endDate : null;
    }),
    selectedReasonObject: Ember.computed('riderSuspensionRecord.reason', function () {
      const riderSuspensionReasonOptions = this.get('riderSuspensionReasonOptions');
      const reason = this.get('riderSuspensionRecord.reason');
      if (Ember.isEmpty(riderSuspensionReasonOptions)) return null;
      return riderSuspensionReasonOptions.find(option => {
        return option.name === reason;
      });
    }),

    async init() {
      this._super(...arguments);

      this.onNewRiderSuspensionRecord = this.onNewRiderSuspensionRecord.bind(this);
      this.onEditRiderSuspensionRecord = this.onEditRiderSuspensionRecord.bind(this);
      this.onDeleteRiderSuspensionRecord = this.onDeleteRiderSuspensionRecord.bind(this);
      this.onUndoRiderSuspensionRecord = this.onUndoRiderSuspensionRecord.bind(this);
      this.OnTableRowClick = this.OnTableRowClick.bind(this);
      this.validateRiderSuspensionRequiredField = this.validateRiderSuspensionRequiredField.bind(this);
      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onNewRiderSuspensionRecord = this.get('onNewRiderSuspensionRecord');
      const onSaveRiderSuspensionRecord = this.get('onSaveRiderSuspensionRecord');
      const onEditRiderSuspensionRecord = this.get('onEditRiderSuspensionRecord');
      const onDeleteRiderSuspensionRecord = this.get('onDeleteRiderSuspensionRecord');
      const onUndoRiderSuspensionRecord = this.get('onUndoRiderSuspensionRecord');
      const riderSuspensionReasons = await this.get('rider').getConfigSuspensionReasons();
      const riderSuspensionReasonOptions = riderSuspensionReasons.map(Rsr => {
        return {
          displayName: Rsr.get('value.displayName'),
          name: Rsr.get('name')
        };
      });
      this.setProperties({
        riderSuspensionReasons,
        riderSuspensionReasonOptions
      });
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveRiderSuspensionRecord,
          newAction: onNewRiderSuspensionRecord,
          deleteAction: onDeleteRiderSuspensionRecord,
          editAction: onEditRiderSuspensionRecord,
          undoAction: onUndoRiderSuspensionRecord,
          isNewBtnDisabled: false,
          isEditBtnDisabled: true,
          isDeleteBtnDisabled: true,
          isEditing: false
        });
      });
    },

    OnTableRowClick(selectedRiderSuspension) {
      this.get('onUndoRiderSuspensionRecord')();
      this.set('riderSuspensionRecord', (0, _unwrapProxy.unwrapProxy)(selectedRiderSuspension));
      this.set('editAPI.isEditBtnDisabled', false);
      this.set('editAPI.isDeleteBtnDisabled', false);
    },

    actions: {
      onDateValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onRiderReasonChange(record, value) {
        const riderSuspensionReasons = this.get('riderSuspensionReasons');
        const riderSuspensionReason = riderSuspensionReasons.find(reason => reason.name === value.name);
        const suspensionReasonLength = riderSuspensionReason.get('value.length(days)');
        const startDate = (0, _moment.default)();
        const endDate = (0, _moment.default)().add(suspensionReasonLength, 'd');
        record.set('startDate', startDate.toDate());
        record.set('endDate', endDate.toDate());
        record.set('reason', value.name);
      }

    },

    onNewRiderSuspensionRecord() {
      const riderSuspensionRecord = this.get('store').createRecord('rider-suspension');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
      }

      this.set('riderSuspensionRecord', riderSuspensionRecord);
      this.set('editAPI.isNewBtnDisabled', true);
      this.set('editAPI.isEditBtnDisabled', true);
      this.set('editAPI.isDeleteBtnDisabled', true);
      this.set('editAPI.isEditing', true);
    },

    onEditRiderSuspensionRecord() {
      const riderSuspensionRecordToEdit = this.get('tableRef.checkedRows.firstObject');
      this.set('riderSuspensionRecord', riderSuspensionRecordToEdit);
      this.set('editAPI.isNewBtnDisabled', true);
      this.set('editAPI.isEditBtnDisabled', true);
      this.set('editAPI.isDeleteBtnDisabled', true);
      this.set('editAPI.isEditing', true);
    },

    confirmRiderSuspensionDeletion(tip, riderSuspensionRecord) {
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: tip,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          await riderSuspensionRecord.destroyRecord();
          this.get('tableRef').refreshData(); //to refresh table data

          notifications.success('Suspension record successfully deleted');
          this.set('editAPI.isEditBtnDisabled', true);
          this.set('editAPI.isDeleteBtnDisabled', true);
          tooltip.reset();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        }
      });
    },

    onDeleteRiderSuspensionRecord() {
      const riderSuspensionRecordToDelete = this.get('tableRef.checkedRows.firstObject');
      this.set('riderSuspensionRecord', riderSuspensionRecordToDelete);
      const tip = 'Are you sure you want to delete this suspension record?';
      this.confirmRiderSuspensionDeletion(tip, riderSuspensionRecordToDelete);
    },

    async onUndoRiderSuspensionRecord() {
      const riderSuspensionId = this.get('rider').getLastAccessedRiderSuspensionId();
      const riderSuspensionRecord = this.get('riderSuspensionRecord');
      const selectedRow = this.get('selectedRow');

      if (riderSuspensionId !== null) {
        this.set('editAPI.isEditBtnDisabled', false);
        this.set('editAPI.isDeleteBtnDisabled', false);
      }

      if (Ember.isEmpty(selectedRow)) {
        this.set('editAPI.isEditBtnDisabled', true);
        this.set('editAPI.isDeleteBtnDisabled', true);
      }

      if (Ember.isPresent(riderSuspensionRecord)) {
        riderSuspensionRecord.rollbackAttributes();
      }

      this.set('editAPI.isNewBtnDisabled', false);
      this.set('editAPI.isEditing', false);
    },

    validateEndDateGreaterThanStartDate(riderSuspensionRecord) {
      const startDate = new Date(riderSuspensionRecord.startDate);
      const endDate = new Date(riderSuspensionRecord.endDate);
      const endDateValidate = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

      if (endDateValidate < 0) {
        return true;
      }

      return false;
    },

    showValidateToolTip(message) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: message,
        primaryActionText: 'Ok',
        primaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    validateRiderSuspensionRequiredField(riderSuspensionRecord) {
      const tooltip = this.get('tooltip');
      const ReasonAndstartAndEndDateErrors = this.validateReasonStartAndEndDate(riderSuspensionRecord);
      const errors = (0, _lodash.compact)([].concat(ReasonAndstartAndEndDateErrors));

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.popConfirmation();
          }

        });
        return false;
      }

      return true;
    },

    validateReasonStartAndEndDate(riderSuspensionRecord) {
      const reason = riderSuspensionRecord.get('reason');
      const startDate = riderSuspensionRecord.get('startDate');
      const endDate = riderSuspensionRecord.get('endDate');
      const errors = [];

      if (!reason) {
        errors.push({
          key: 'reason',
          validation: ['Reason is required']
        });
      }

      if (!startDate) {
        errors.push({
          key: 'startDate',
          validation: ['Start Date is required']
        });
      }

      if (!endDate) {
        errors.push({
          key: 'endDate',
          validation: ['End Date is required']
        });
      }

      return errors;
    },

    onSaveRiderSuspensionRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const rider = this.get('riderChangeSet').get('_internalModel');
      const riderSuspensionRecord = this.get('riderSuspensionRecord');
      const isValidStarDateEndDate = this.validateEndDateGreaterThanStartDate(riderSuspensionRecord);
      const isValidSuspensionRequiredField = this.validateRiderSuspensionRequiredField(riderSuspensionRecord);
      const selectedRow = this.get('selectedRow');

      if (isValidStarDateEndDate) {
        this.showValidateToolTip('End Date must be after Start Date');
      } else if (isValidSuspensionRequiredField) {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: 'Are you sure you want to save these changes?',
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            try {
              riderSuspensionRecord.set('rider', rider);
              const rS = await riderSuspensionRecord.save();
              this.get('rider').setLastAccessedRiderSuspensionId(rS.id);
              this.get('tableRef').refreshData();

              if (this.get('editAPI.isEditing')) {
                notifications.success('Rider Suspension Record successfully edited');
                this.set('editAPI.isEditing', false);
                this.set('editAPI.isNewBtnDisabled', false);

                if (Ember.isEmpty(selectedRow)) {
                  this.set('editAPI.isEditBtnDisabled', true);
                  this.set('editAPI.isDeleteBtnDisabled', true);
                } else {
                  this.set('editAPI.isEditBtnDisabled', false);
                  this.set('editAPI.isDeleteBtnDisabled', false);
                }
              } else {
                notifications.success('Rider SuspensionRecord successfully saved');
                this.set('editAPI.isNewBtnDisabled', false);
              }

              tooltip.reset();
            } catch (e) {
              notifications.warning('Rider Suspension Record failed to save');
              tooltip.reset();
              console.warn(e); // eslint-disable-line
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          }
        });
      }

      yield Promise.resolve();
    })
  });

  _exports.default = _default;
});