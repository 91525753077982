define("adept-iq/pods/components/form-components/activity-log-entry/enum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "09+wvVg5",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"dataFromModel\"]],true],null]],null,{\"statements\":[[4,\"component\",[[27,\"if\",[[23,[\"allowMultiple\"]],\"power-select-multiple\",\"power-select\"],null]],[[\"selected\",\"searchEnabled\",\"options\",\"onchange\",\"searchField\"],[[27,\"readonly\",[[23,[\"selected\"]]],null],false,[27,\"readonly\",[[23,[\"options\"]]],null],[27,\"action\",[[22,0,[]],\"onChange\"],null],[27,\"readonly\",[[23,[\"optionSearchPath\"]]],null]]],{\"statements\":[[0,\"   \"],[1,[27,\"get\",[[22,1,[]],[23,[\"optionSearchPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/activity-log-entry/enum/template.hbs"
    }
  });

  _exports.default = _default;
});