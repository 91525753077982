define("adept-iq/pods/components/iq-widgets/delete-booking-form/booking-details/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/utils/unwrapProxy"], function (_exports, _component, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    bookingID: null,
    riderDetails: null,
    bookDate: null,

    init() {
      this._super(...arguments);

      const bookingData = this.get('editModal.editableRecords')[0];
      const selectedRiderDetails = (0, _unwrapProxy.unwrapProxy)(bookingData.legs.map(ele => ele.rider)).uniqBy('id').map(ele => {
        return {
          'passengerID': ele.get('riderId'),
          'name': ele.get('fullName')
        };
      });
      this.set('riderDetails', selectedRiderDetails);
      this.set('bookingID', bookingData.get('id'));
      this.set('bookDate', bookingData.get('requestTimeForDisplay'));
    }

  });

  _exports.default = _default;
});