define("adept-iq/pods/components/side-drawer/no-show-form/text-area/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-text-area'],
    value: null,
    disabled: false,
    actions: {
      onInput() {
        const value = this.get('value');
        this.get('onChange')(value.trim());
      }

    }
  });

  _exports.default = _default;
});