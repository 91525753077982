define("adept-iq/adapters/trip-transfer", ["exports", "adept-iq/adapters/-cos-continuousOptService", "ember-concurrency"], function (_exports, _cosContinuousOptService, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TIMEOUT_DELAY = 1000; // 1 second delay

  const MAX_RETRIES = 180;

  var _default = _cosContinuousOptService.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    createTripTransferAsyncOperation: (0, _emberConcurrency.task)(function* (tripIds, dispatchScheduleId, destinationRouteId, destinationProviderIds) {
      const job = yield this.postTransfer(tripIds, dispatchScheduleId, destinationRouteId, destinationProviderIds, true);
      return yield this.get('asyncTripTransferOperationState').perform(job);
    }).enqueue(),
    asyncTripTransferOperationState: (0, _emberConcurrency.task)(function* (job) {
      const session = this.get('session');
      const url = `${this.host}/cos-async-operation/`;
      let count = 0;

      while (job.data.attributes.state !== 'success' && job.data.attributes.state !== 'failure' && job.data.attributes.state !== 'rejected' && count < MAX_RETRIES) {
        yield (0, _emberConcurrency.timeout)(TIMEOUT_DELAY);
        count++;

        try {
          // eslint-disable-next-line no-param-reassign
          job = yield this.get('ajax').request(url + job.data.id, {
            method: 'GET',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          });
        } catch (e) {
          throw e;
        }
      }

      const isJobSuccess = job.data.attributes.state === 'success';

      if (isJobSuccess) {
        const results = JSON.parse(job.data.attributes.results);
        return { ...results,
          isJobSuccess
        };
      }

      return {
        payload: {
          // if it is 'rejected' then there is no results.
          message: job.data.attributes.results ? `${job.data.attributes.results}` : `${job.data.attributes.state}`
        },
        isJobSuccess
      };
    }).drop(),

    postTransfer(tripIds, dispatchScheduleId, destinationRouteId, destinationProviderIds) {
      let async = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      const session = this.get('session');
      const url = async ? `${this.host}/cos-async-operation` : `${this.host}/optimizer/trip-transfer`;
      const json = async ? {
        data: {
          type: 'cosAsyncOperation',
          attributes: {
            operationType: 'autoTripTransfer',
            entityType: 'trip',
            entityId: tripIds,
            dispatchScheduleId,
            destinationRouteId
          }
        }
      } : {
        data: {
          type: 'autoTripTransfer',
          attributes: {
            dispatchScheduleId,
            tripIds,
            destinationRouteId
          }
        }
      };

      if (Ember.isPresent(destinationProviderIds)) {
        json.data.attributes.destinationProviderIds = destinationProviderIds;
      }

      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    postManualTransfer(trips, dispatchScheduleId, destinationRouteId) {
      const session = this.get('session');
      const url = `${this.host}/optimizer/manual-trip-transfer`;
      const json = {
        data: {
          type: 'manualTripTransfer',
          attributes: {
            dispatchScheduleId,
            trips,
            destinationRouteId
          }
        }
      };
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    }

  });

  _exports.default = _default;
});