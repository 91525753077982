define("adept-iq/serializers/sso-role", ["exports", "adept-iq/serializers/-sso-ssoService"], function (_exports, _ssoSsoService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssoSsoService.default.extend({
    attrs: {
      permissions: {
        serialize: false
      }
    },

    serialize(snapshot) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      options.includeId = true;

      const json = this._super(snapshot, options);

      return json;
    },

    normalizeResponse: function (store, primaryModelClass, payload) {
      const currentPayload = {
        data: []
      };
      const data = payload.length ? payload : [payload];

      for (let i = 0; i < data.length; i++) {
        currentPayload.data.push({
          id: data[i].roleName,
          type: 'sso-role',
          attributes: data[i]
        });
      }

      return currentPayload;
    }
  });

  _exports.default = _default;
});