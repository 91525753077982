define("adept-iq/tests/factories/trim-slack-contract-management", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const minRouteLength = '';
  const minRoute = '930';
  const characterMinRoute = 'as:as';

  _emberDataFactoryGuy.default.define('trim-slack-contract-management', {
    default: {
      startRouteName: '57000',
      endRouteName: '57004',
      trimSlack: true,
      adjustMins: '10',
      minRouteLength: 140,
      pullOutMinCap: false,
      pullOutMaxCap: '1',
      pullInMinCap: '1',
      pullInMaxCap: '1',
      providerName: 'MVT'
    },
    traits: {
      minRouteLength: {
        minRouteLength
      },
      minRoute: {
        minRoute
      },
      characterMinRoute: {
        characterMinRoute
      }
    }
  });
});