define("adept-iq/services/schedule-generation", ["exports", "moment", "ember-concurrency", "adept-iq/config/api-urls"], function (_exports, _moment, _emberConcurrency, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const scheduleHost = _apiUrls.API.schedulingService.host; // TODO: derive from agency

  const TIME_ZONE = 'America/Los_Angeles';
  const NUM_TRIES = 5;

  var _default = Ember.Service.extend({
    activeContext: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    isRunning: false,
    timeout: 5000,

    init() {
      this._super(...arguments);
    },

    task: (0, _emberConcurrency.task)(function* () {
      return yield this.performTask();
    }).drop(),

    performTask() {
      let dispatchSchedules;
      let dates = this.getScheduleDates();
      this.set('isRunning', true);
      return this.fetchDSSchedulesForDates(dates).then(_schedules => {
        dispatchSchedules = _schedules;
        return this.notifyDispatchScheduleGenerated(dispatchSchedules);
      }).then(() => {
        dates = this.rejectDSSchedulesDates(dates, dispatchSchedules);
        const schedules = this.fetchOrCreateSSSchedulesForDates(dates);
        return Ember.RSVP.hash({
          ssSchedules: schedules
        });
      }).then(_ref => {
        let {
          ssSchedules
        } = _ref;
        return ssSchedules.reduce(async (acu, ssSchedule) => {
          this.set('isRunning', true);
          let res = await this.createScheduleAsyncOperation.perform(ssSchedule, 'import');
          res = res ? await this.createScheduleAsyncOperation.perform(ssSchedule, 'generate') : false;
          res = res ? await this.createScheduleAsyncOperation.perform(ssSchedule, 'export') : false;
          if (!res) this.get('notifications').warning(`Failed To Generate Schedule ${ssSchedule.get('name')}`);
          return acu.then(() => {
            this.get('notifications').success(`Successfully Generated Schedule ${ssSchedule.get('name')}`);
          });
        }, Promise.resolve());
      }).then(() => {
        // no promise to return here; refresh extends well beyond task
        this.get('activeContext').refreshAll();
        this.set('isRunning', false);
      }).catch(err => {
        this.set('isRunning', false);
        if (!err || !err.message) return;
        this.get('notifications').warning(err.message);
      });
    },

    getScheduleDates() {
      const endDate = this.get('workspace.endDate');
      const startDate = this.get('workspace.startDate');
      const momentStartDate = (0, _moment.default)(startDate).tz(TIME_ZONE).startOf('day');
      const maxTime = endDate.valueOf(); // regenerate all days including today and within end time and booking window

      const dates = [];
      let date = momentStartDate;

      while (date.valueOf() <= maxTime) {
        dates.push(date);
        date = date.clone().add(1, 'day');
      }

      return dates;
    },

    async fetchOrCreateSSSchedulesForDates(dates) {
      const filterQuery = 'eq(type,\'primary\')';
      const ssSchedulesQuery = this.store.query('schedule', {
        filter: filterQuery
      });
      const scheduleConfiguration = this.get('store').peekAll('schedule-configuration').firstObject;
      return Ember.RSVP.all(dates.map(date => {
        return ssSchedulesQuery.then(ssSchedules => {
          const dateStart = _moment.default.tz(date, TIME_ZONE).startOf('day');

          const dateEnd = _moment.default.tz(date, TIME_ZONE).endOf('day'); // find any schedule, but not generated


          let ssSchedule = ssSchedules.find(curSchedule => {
            const scheduleStart = _moment.default.tz(curSchedule.get('start'), TIME_ZONE);

            return scheduleStart.startOf('day').valueOf() === dateStart.valueOf();
          }); // if schedule hasn't been created, create one

          if (ssSchedule) {
            return Ember.RSVP.resolve(ssSchedule);
          } // ensure name is unique


          const baseName = dateStart.format('YYYY-MM-DD');
          let name = baseName;

          for (let i = 1;; i++) {
            if (!ssSchedules.findBy('name', name)) break;
            name = `${baseName} (${i})`;
          }

          ssSchedule = this.get('store').createRecord('schedule', {
            start: dateStart.toDate(),
            end: dateEnd.toDate(),
            name,
            scheduleConfiguration
          });
          return ssSchedule.save();
        });
      }));
    },

    rejectDSSchedulesDates(dates, dispatchSchedules) {
      return dates.reduce((arr, date) => {
        const dateStart = _moment.default.tz(date, TIME_ZONE).startOf('day');

        const schedule = dispatchSchedules.find(currSchedule => {
          const scheduleStart = _moment.default.tz(currSchedule.get('schedule.start'), TIME_ZONE);

          return scheduleStart.startOf('day').valueOf() === dateStart.valueOf();
        });

        if (!schedule) {
          arr.push(date);
        }

        return arr;
      }, []);
    },

    fetchDSSchedulesForDates(dates) {
      // TODO: add node to filter for future-only and ditch days altogether
      const filterQuery = 'eq(type,\'primary\')';
      const SchedulingSchedules = this.store.query('schedule', {
        filter: filterQuery
      });
      const schedulesQuery = this.get('store').findAll('dispatch-schedule');
      return SchedulingSchedules.then(() => {
        return schedulesQuery.then(schedules => {
          return dates.reduce((arr, date) => {
            const dateStart = _moment.default.tz(date, TIME_ZONE).startOf('day');

            const schedule = schedules.find(currSchedule => {
              const scheduleStart = _moment.default.tz(currSchedule.get('schedule.start'), TIME_ZONE);

              return scheduleStart.startOf('day').valueOf() === dateStart.valueOf();
            });

            if (schedule) {
              arr.push(schedule);
            }

            return arr;
          }, []);
        });
      });
    },

    notifyDispatchScheduleGenerated(schedules) {
      if (Ember.isEmpty(schedules)) return Ember.RSVP.resolve();
      const notifications = this.get('notifications');
      schedules.forEach(schedule => {
        notifications.info(`schedule ${schedule.get('schedule.name')} has already been exported`);
      });
    },

    createScheduleAsyncOperation: (0, _emberConcurrency.task)(function* (schedule, operation) {
      const session = this.get('session');
      const op = {
        'data': {
          'type': 'scheduleAsyncOperation',
          'attributes': {
            'operationType': operation
          },
          'relationships': {
            'schedule': {
              'data': {
                'type': 'schedule',
                'id': schedule.get('id')
              }
            }
          }
        }
      };
      const job = yield this.get('ajax').post(scheduleHost + '/schedule-async-operation', {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: op
      });
      if (!job) return false;
      return yield this.get('asyncOperationState').perform(job);
    }).enqueue(),
    asyncOperationState: (0, _emberConcurrency.task)(function* (job) {
      const session = this.get('session');
      let times = 0;

      while (job.data.attributes.state !== 'success' && job.data.attributes.state !== 'failure' && times++ <= NUM_TRIES) {
        const delay = this.get('timeout');
        yield (0, _emberConcurrency.timeout)(delay); // eslint-disable-next-line no-param-reassign

        job = yield this.get('ajax').request(scheduleHost + '/schedule-async-operation/' + job.data.id, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        });
      }

      return job.data.attributes.state === 'success';
    }).drop()
  });

  _exports.default = _default;
});