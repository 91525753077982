define("adept-iq/tests/factories/stop-point", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('stop-point', {
    default: {
      stopType: 'pick',
      parentType: 'trip',
      waypointType: 'fixed',
      waypointSource: 'address',
      waypoint: '1',
      // id for segment stop's place or vehicle id dependent on waypoint type
      onBoardCount: 2,
      routeOrdinal: 1,
      clusterOrdinal: 2,
      // use the options hash to create correct ordinals
      lockedForOptimization: false
    },
    traits: {
      Break: {
        parentType: 'break',
        stopType: 'break',
        onBoardCount: 0
      },
      OnBreak: {
        stopType: 'break'
      },
      pick: {
        stopType: 'pick'
      },
      drop: {
        stopType: 'drop'
      },
      pullinGarage: {
        stopType: 'pullinGarage',
        status: 'pending',
        parentType: 'pullinGarage'
      },
      pulloutGarage: {
        stopType: 'pulloutGarage',
        status: 'performed',
        parentType: 'pulloutGarage'
      },
      noshow: {
        status: 'No Show'
      },
      noshowdrop: {
        status: 'No Show'
      },
      pickArrive: {
        stopType: 'pick',
        status: 'Arrive'
      },
      dropArrive: {
        stopType: 'drop',
        status: 'Arrive'
      }
    }
  });
});