define("adept-iq/pods/components/side-drawer/reconcile-rescue-route/table/cells/check-box/component", ["exports", "adept-iq/pods/components/side-drawer/reconcile-rescue-route/table/cells/base-cell/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['table-check-box-cell', 'data-test-table-check-box-cell'],

    init() {
      this._super(...arguments);
    },

    activeContext: Ember.inject.service(),
    isChecked: Ember.computed.alias('row.isChecked'),
    actions: {
      onToggleCheckbox(event) {
        const row = this.get('row');
        const rows = this.get('table.rows');
        const lastCheckedRow = rows.findBy('isLastChecked', true);
        const affectedRows = [];

        if (event.shiftKey && lastCheckedRow) {
          const lastCheckedIndex = rows.indexOf(lastCheckedRow);
          const checkedIndex = rows.indexOf(row);
          const start = Math.min(checkedIndex, lastCheckedIndex);
          const end = Math.max(checkedIndex, lastCheckedIndex);

          for (let i = start; i <= end; i++) {
            rows.objectAt(i).set('isChecked', event.target.checked);
            rows.objectAt(i).set('selected', event.target.checked);
            affectedRows.push(rows.objectAt(i));
          }
        } else {
          row.set('isChecked', event.target.checked);
          row.set('selected', event.target.checked);
          affectedRows.push(row);
        }

        rows.setEach('isLastChecked', false);
        row.set('isLastChecked', true);
      }

    }
  });

  _exports.default = _default;
});