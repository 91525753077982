define("adept-iq/pods/components/rider-management-widget/rider-certification/functional-assessment/component", ["exports", "adept-iq/config/placeholders"], function (_exports, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    PHONE_NUMBER_PLACEHOLDER: _placeholders.PHONE_NUMBER_PLACEHOLDER,
    assessmentDate: Ember.computed('riderCertificationRecord.assessmentDate', function () {
      const assessmentDate = this.get('riderCertificationRecord.assessmentDate');
      return assessmentDate ? assessmentDate : null;
    }),
    assessmentHearingDate: Ember.computed('riderCertificationRecord.assessmentHearingDate', function () {
      const assessmentHearingDate = this.get('riderCertificationRecord.assessmentHearingDate');
      return assessmentHearingDate ? assessmentHearingDate : null;
    }),
    assessmentDecisionDate: Ember.computed('riderCertificationRecord.assessmentDecisionDate', function () {
      const assessmentDecisionDate = this.get('riderCertificationRecord.assessmentDecisionDate');
      return assessmentDecisionDate ? assessmentDecisionDate : null;
    }),
    actions: {
      onPhoneValueChange(record, maskObj) {
        // match[0] = area code
        // match[1] = first part of 3 digit phone number
        // match[2] = second part of 4 digit phone number
        const match = maskObj.masked.replace(/_/, '').split('-');
        const setPhoneNumber = record;
        setPhoneNumber.set('assessmentPhone', match[0] + match[1] + match[2]);
        setPhoneNumber.set('assessmentPhone', maskObj.unmasked);
      }

    }
  });

  _exports.default = _default;
});