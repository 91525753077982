define("adept-iq/models/rider-service-need", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    isSoftDeleted: attr('boolean', {
      defaultValue: false
    }),
    serviceNeedType: belongsTo('service-need-type'),
    rider: belongsTo('rider')
  });

  _exports.default = _default;
});