define("adept-iq/pods/components/iq-widgets/permissions-users-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/permissions-users-widget/config", "adept-iq/config/mapped-permIds", "ember-concurrency", "lodash", "adept-iq/utils/filters", "adept-iq/utils/sorts"], function (_exports, _component, _config, _mappedPermIds, _emberConcurrency, _lodash, _filters, _sorts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['users-widget', 'data-test-users-widget'],
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    config: _config.default,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.initContextMenu();
    },

    initContextMenu() {
      this.initTableActions();
      this.initSingleActions();
      this.initBulkActions();
    },

    // isLoggedInUserSelected() {
    //   let isExists = false;
    //   this.selectedUsers.map((user) => {
    //     if (user.content.id === this.session.data.authenticated.userId) {
    //       isExists = true;
    //     }
    //   });
    //   return isExists;
    // },
    // handleUserLogout() {
    //   if (this.isLoggedInUserSelected()) {
    //     this.get('session').invalidate();
    //   }
    // },
    contextMenuHandler(optionId, isSaved) {
      if (isSaved) {
        this.refresh();
      }
    },

    initTableActions() {
      this.get('tableActions').unshiftObject({
        id: 'newUser',
        permId: _mappedPermIds.default.newUser,
        name: 'Add User',
        action: () => {
          this.get('workspace').pushState('permissionsAddNewUser', {
            action: this.contextMenuHandler.bind(this, 'permissionsAddNewUser'),
            userRecords: this.get('records')
          });
        }
      });
    },

    initSingleActions() {
      this.set('singleActions', [{
        id: 'edit',
        permId: _mappedPermIds.default.editUser,
        name: 'Edit User',
        action: user => {
          this.get('workspace').pushState('permissionsEditUser', {
            user,
            action: this.contextMenuHandler.bind(this, 'permissionsEditUser'),
            userRecords: this.get('records')
          });
        }
      }, {
        id: 'delete',
        permId: _mappedPermIds.default.editUser,
        // temporary for test, we don't have delteUser define
        name: 'Delete User',
        action: model => {
          this.deleteUser([model]);
        }
      }, {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLogUser,
        name: 'View Activity Log',
        action: model => {
          const name = model.get('fullName');
          this.get('workspace').pushState('userActivityLog', {
            displayName: `User #${name} Activity Log`,
            user: model
          });
        }
      }]);
    },

    initBulkActions() {
      this.set('dynamicBulkActions', [{
        id: 'bulkDelete',
        permId: _mappedPermIds.default.editUser,
        // temporary for test, we don't have delteUser define
        name: 'Bulk Delete Users',
        action: models => {
          this.deleteUser(models);
        }
      }]);
    },

    deleteUser(users) {
      const tooltip = this.get('tooltip');
      const title = users.length < 2 ? 'Delete User' : 'Bulk Delete Users';
      const tip = users.length < 2 ? 'Do you want to delete this user?' : 'Do you want to bulk delete users?';
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          return this.get('deleteUserTask').perform(users).then(isDeleted => {
            if (isDeleted) {
              this.get('activeContext.topActiveContext').setRecordsChecked(users, false);
              this.refresh();
              this.get('notifications').success(users.length < 2 ? 'DELETED USER SUCCESSFULLY' : 'BULK DELETED USERS SUCCESSFULLY');
            }

            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in delete user, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        },
        hasOverlay: true
      });
    },

    deleteUserTask: (0, _emberConcurrency.task)(function* (users) {
      try {
        const store = this.get('store');
        const userAdapter = store.adapterFor('perm-sso-user');

        if (!Ember.isEmpty(users)) {
          for (let i = 0; i < users.length; i++) {
            yield userAdapter.deleteUser(users[i].id);
          }

          return true;
        }

        return false;
      } catch (error) {
        throw error;
      }
    }),

    fetchDataQuery() {
      const params = {
        sorts: []
      };

      try {
        // this only applies for server-side pagination
        if (this.get('paginationEnabled')) {
          params.page = {
            offset: this.get('records.length'),
            limit: this.get('limit')
          };
        }

        const sort = this.buildSortParam();

        if (sort) {
          params.sorts.push(sort);
        }

        const includes = this.get('defaultIncludes');

        if (!Ember.isEmpty(includes)) {
          params.includes = includes;
        }

        const filters = this.setupFilterType();
        params.filter = (0, _filters.buildCompoundFilterNode)('and', filters);
        const modelName = this.get('config.modelName');
        return this.query(modelName, params);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Workspace view is not refreshed on selecting or deselecting' + error);
      }
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const activeData = (await store.query(modelName, params)) || [];
      const storeData = store.peekAll(modelName);

      _lodash.default.differenceBy(storeData.toArray(), activeData.toArray(), i => {
        return i.get('id');
      }).forEach(record => {
        store.unloadRecord(record);
      });

      this.get('activeContext.topActiveContext').set('permSsoUsers', activeData);
      const filteredData = activeData.filter(filterFn).sort(compareFn);
      return filteredData;
    },

    refresh() {
      this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['perm-sso-user', 'perm-sso-role']);
    }

  });

  _exports.default = _default;
});