define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/violation/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "moment", "adept-iq/utils/unwrapProxy", "lodash", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/violation/validation", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/violation/vehicle-search-config", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _moment, _unwrapProxy, _lodash, _validation, _vehicleSearchConfig, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_VIOLATION_TYPE_MAP = {
    addDriverViolation: true,
    editDriverViolation: true,
    deleteDriverViolation: true
  };
  const extraPointsLimitOptions = {
    min: 1,
    max: 3,
    isFloat: false
  }; //since current date is set as max date, default date(today) is not displayed in the Incident and Monitor date

  const CURRENT_DATE = (0, _moment.default)(new Date()).endOf('day').toDate();
  const VIOLATION_FIELDS = ['incidentDate', 'convictionDate', 'licenseMonitorDate', 'convictionNotificationDate'];

  var _default = Ember.Component.extend({
    classNames: ['driver-violation-view'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    tableRef: null,
    activityViolationTableRef: null,
    disableForm: true,
    CURRENT_DATE,
    // placeholders
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    NOTES_PLACEHOLDER: _placeholders.NOTES_PLACEHOLDER,
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    // passed into view
    editAPI: null,
    selectedDriver: Ember.computed.readOnly('editAPI.selectedRecord'),
    selectedDriverLicense: Ember.computed.readOnly('selectedDriver.license'),
    selectedViolation: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    extraPointsLimitOptions,

    async init() {
      this._super(...arguments);

      const driver = this.get('selectedDriver');
      const driverLicense = driver.get('license');
      this.set('activityQueryParams', {
        driverId: `${driver.get('id')}`,
        eventLog: true
      });
      this.set('queryParams', {
        filter: `eq(driverLicenseId,${driverLicense.get('id')})`
      });
      this.get('refreshTask').perform();
      await this.buildDriverSuspensionMap(driver.get('id'));
    },

    async buildDriverSuspensionMap(driverId) {
      const driverSuspensions = await this.get('store').query('driver-suspension', {
        filter: `eq(driverId,${driverId})`
      });
      const driverSuspensionMap = (0, _lodash.groupBy)(driverSuspensions.toArray(), 'driverViolationId'); //key :driverViolationId ,value:driverSuspensions

      this.set('driverSuspensionMap', driverSuspensionMap);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const tableRef = this.get('tableRef'); // ember concurrency tasks

      const onSaveDriverRecord = this.get('onSaveDriverViolationRecord');
      const onDeleteDriverViolationRecord = this.get('onDeleteDriverViolationRecord');
      const onValidateAction = this.get('validateDriverViolation'); // normal functions

      const onUndoDriverRecord = this.get('onUndoDriverViolationRecord').bind(this);
      const onNewDriverViolationRecord = this.get('onNewDriverViolationRecord').bind(this);
      const onEditDriverViolationRecord = this.get('onEditDriverViolationRecord').bind(this);
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverRecord,
          newAction: onNewDriverViolationRecord,
          deleteAction: onDeleteDriverViolationRecord,
          editAction: onEditDriverViolationRecord,
          undoAction: onUndoDriverRecord,
          selectedDriverBelongsTableRef: tableRef,
          hasChanges,
          onValidateAction,
          isChangesHappened: false
        });
      });
      this.addObserver('hasPendingChanges', this.hasPendingChangesObserver);
      this.get('refreshTask').perform().then(() => {
        Ember.run.later(() => {
          this.resetForm();
        }, 1000);
      });
    },

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_VIOLATION_TYPE_MAP[event.get('actionType')];
    },

    driverViolationChangeSet: Ember.computed('selectedViolation', function () {
      const Violation = (0, _unwrapProxy.unwrapProxy)(this.get('selectedViolation'));
      return this.setDefaultProperties(Violation);
    }),
    hasPendingChanges: Ember.computed('driverViolationChangeSet.changes.length', function () {
      const driverViolationChangeSet = this.get('driverViolationChangeSet');
      const changes = driverViolationChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      const driverViolationChangeSet = this.get('driverViolationChangeSet');

      if (driverViolationChangeSet.get('isNew')) {
        const pendingChanges = this.get('driverViolationChangeSet');
        const fields = (0, _lodash.flatten)(pendingChanges.changes).map(change => change.key);
        const isEveyPrePoulatedFieldsChange = fields.length ? (0, _lodash.every)(fields, field => VIOLATION_FIELDS.includes(field)) : false;
        if (isEveyPrePoulatedFieldsChange) return !isEveyPrePoulatedFieldsChange;
        return !!this.get('hasPendingChanges');
      }

      return !!this.get('hasPendingChanges');
    },

    hasPendingChangesObserver() {
      const editAPI = this.get('editAPI');
      const hasPendingChanges = this.get('hasPendingChanges');
      Ember.run.scheduleOnce('afterRender', () => {
        editAPI.setProperties({
          isChangesHappened: hasPendingChanges
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const driverLicense = this.get('selectedDriverLicense');
      const driver = this.get('selectedDriver');
      let pendingDriverViolation = this.get('pendingDriverViolation');

      if (record) {
        pendingDriverViolation = record;
        this.resetForm();
      } else if (!pendingDriverViolation) {
        pendingDriverViolation = store.createRecord('driver-violation', {
          driverLicense,
          driver
        });
        this.set('pendingDriverViolation', pendingDriverViolation);
      }

      if (pendingDriverViolation.get('isNew')) {
        const defaultDate = (0, _moment.default)().startOf('day').toISOString();
        pendingDriverViolation.setProperties({
          incidentDate: defaultDate,
          convictionDate: defaultDate,
          licenseMonitorDate: defaultDate,
          convictionNotificationDate: defaultDate,
          description: null,
          points: null,
          vehicle: null
        });
      }

      this.setMinDate(pendingDriverViolation.get('incidentDate'));
      this.set('showValidations', false);
      return new _emberChangeset.default(pendingDriverViolation, (0, _emberChangesetValidations.default)(_validation.VALIDATION), _validation.VALIDATION);
    },

    /**
     * This method is used to set incident date as a min date to license monitorDate
     * and conviction Date.If we set min date in template both date pikers are not display the past saved date
     * @param minDate
     */
    setMinDate(minDate) {
      const licenseMonitorDateInstance = this.get('licenseMonitorDateInstance');
      const convictionDateInstance = this.get('convictionDateInstance');

      if (licenseMonitorDateInstance) {
        licenseMonitorDateInstance.set('minDate', minDate);
      }

      if (convictionDateInstance) {
        convictionDateInstance.set('minDate', minDate);
      }
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedViolation = this.get('selectedViolation');

      if (selectedViolation) {
        if (!selectedViolation.get('isNew') && !selectedViolation.get('isDeleted')) {
          yield selectedViolation.reload();
        }
      }

      const tableRef = this.get('tableRef');
      const activityViolationTableRef = this.get('activityViolationTableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      if (activityViolationTableRef) Ember.run.scheduleOnce('afterRender', activityViolationTableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        const records = this.get('tableRef.records');

        if (!records.length) {
          editAPI.buttonSettings(true, false, true, true, true);
        } else {
          editAPI.buttonSettings();
        }
      });
    },

    onSaveDriverViolationRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const selectedDriver = this.get('selectedDriver');
      const pendingDriverViolation = this.get('driverViolationChangeSet');
      const tooltip = this.get('tooltip'); // validation

      const isValidDriverViolation = yield this.validateDriverViolation.perform();

      if (isValidDriverViolation) {
        pendingDriverViolation.set('driver', selectedDriver);

        try {
          yield pendingDriverViolation.save();
          notifications.success('Record successfully saved.');
          this.set('pendingDriverViolation', null);
          Ember.run.later(() => {
            this.get('refreshTask').perform().then(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
              this.resetForm();
              tooltip.reset();
            });
          }, 1000);
          return pendingDriverViolation;
        } catch (e) {
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line
        }
      }
    }),

    onUndoDriverViolationRecord() {
      const driverViolationChangeSet = this.get('driverViolationChangeSet');

      if (driverViolationChangeSet) {
        driverViolationChangeSet.rollback();
      }

      this.setMinDate(driverViolationChangeSet.get('incidentDate'));
    },

    onNewDriverViolationRecord() {
      const selectedRow = this.get('selectedRow');
      const defaultDate = (0, _moment.default)().startOf('day').toDate();

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      this.setMinDate(defaultDate);
      this.get('editAPI').buttonSettings(true, true, true, false, false); //False to enable the button, TRUE to disable
    },

    onEditDriverViolationRecord() {
      const selectedRow = this.get('selectedRow');
      if (Ember.isEmpty(selectedRow)) return;
      this.set('disableForm', false);
      this.get('editAPI').buttonSettings(true, true, true, false, false);
    },

    onDeleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedViolation = this.get('selectedViolation');
      const notifications = this.get('notifications');

      try {
        yield selectedViolation.destroyRecord();
        notifications.success('Record successfully deleted');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.resetForm();
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
          });
        }, 1000);
      } catch (e) {
        notifications.warning('Record failed to delete');
        console.warn(e); // eslint-disable-line
      }
    }),
    onDeleteDriverViolationRecord: (0, _emberConcurrency.task)(function* () {
      const selectedViolation = this.get('selectedViolation');
      const tooltip = this.get('tooltip');
      const driverSuspensionMap = this.get('driverSuspensionMap') || {};
      const driverSuspensions = driverSuspensionMap[selectedViolation.get('id')];
      if (!selectedViolation) return;

      if (Ember.isPresent(driverSuspensions)) {
        yield tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: 'This violation is associated with a suspension record. You must delete the suspension before deleting this violation.',
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.reset();
          }

        });
        return;
      }

      yield tooltip.pushConfirmation({
        title: 'Manage Driver',
        tip: 'Are you sure you want to delete this record?',
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        },

        primaryActionText: 'Confirm',
        primaryAction: () => {
          this.get('onDeleteRecord').perform();
          return tooltip.reset();
        }
      });
    }),
    validateDriverViolation: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverViolationChangeSet = this.get('driverViolationChangeSet');
      yield driverViolationChangeSet.validate();
      const {
        incidentDate,
        licenseMonitorDate,
        convictionDate,
        convictionNotificationDate
      } = driverViolationChangeSet.getProperties('incidentDate', 'licenseMonitorDate', 'convictionDate', 'convictionNotificationDate');

      if (convictionDate && incidentDate) {
        if ((0, _moment.default)(convictionDate).isBefore((0, _moment.default)(incidentDate))) {
          driverViolationChangeSet.addError('convictionDate', {
            value: convictionDate,
            validation: 'The Conviction Date must be on or after the Incident Date.'
          });
        }
      }

      if (licenseMonitorDate && incidentDate) {
        if ((0, _moment.default)(licenseMonitorDate).isBefore((0, _moment.default)(incidentDate))) {
          driverViolationChangeSet.addError('licenseMonitorDate', {
            value: licenseMonitorDate,
            validation: 'License Monitor Date must be on or after the Incident Date.'
          });
        }
      }

      if (convictionNotificationDate && convictionDate) {
        if ((0, _moment.default)(convictionNotificationDate).isBefore((0, _moment.default)(convictionDate))) {
          driverViolationChangeSet.addError('convictionNotificationDate', {
            value: convictionNotificationDate,
            validation: 'The Conviction Notification Date must be on or after the Conviction Date.'
          });
        }
      }

      const driverViolationValid = driverViolationChangeSet.errors;
      const errors = driverViolationValid;

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.reset();
          }

        });
        return false;
      }

      return true;
    }),

    async onVehicleChange(vehicle) {
      let path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'vehicle';
      const driverViolationChangeSet = this.get('driverViolationChangeSet');
      driverViolationChangeSet.set(path, vehicle);
    },

    actions: {
      onSaveRecord() {
        return this.onSaveDriverViolationRecord.perform();
      },

      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onDatePickerReady(valuPath, instance) {
        this.set(valuPath, instance);
      },

      onDateValueChange(record, valuePath, value) {
        const isoDate = (0, _moment.default)(value).startOf('day').toISOString();
        record.set(valuePath, isoDate);
      },

      onIncidentDateValueChange(record, valuePath, value) {
        const isoDate = (0, _moment.default)(value).startOf('day').toISOString();
        record.set(valuePath, isoDate);
        this.setMinDate(isoDate);
      },

      openVehicleModal() {
        const onVehicleChange = this.get('onVehicleChange').bind(this);
        const searchVehicleObj = Ember.Object.create({
          title: 'Vehicles',
          hasOverlay: true,
          config: _vehicleSearchConfig.default,
          primaryActionText: 'Select',
          className: 'vehicle-search-modal',
          primaryAction: record => {
            onVehicleChange(record);
          }
        });
        this.set('modalAPI', searchVehicleObj);
      }

    }
  });

  _exports.default = _default;
});