define("adept-iq/services/active-context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    user: Ember.inject.service(),
    // stack
    activeContexts: null,
    // "top" active-context in stack; this is what the widgets, workspace context, and map context bind to
    topActiveContext: Ember.computed.readOnly('activeContexts.lastObject'),
    isEmpty: Ember.computed.equal('activeContexts.length', 0),

    init() {
      this._super(...arguments);

      this.set('activeContexts', []);
    },

    pushContext(activeContext) {
      const structuredWorkspace = activeContext.get('nodes').reduce((obj, node) => {
        obj[node.modelName] = [];
        return obj;
      }, {});
      this.set('workspaceContext._structuredWorkspace', structuredWorkspace);
      this.get('activeContexts').pushObject(activeContext);
    },

    popContext() {
      const topActiveContext = this.get('topActiveContext');
      topActiveContext.trigger('unbind');
      return this.get('activeContexts').popObject();
    },

    /**
     * Safely removes context in stack in place.
     * @param {RecordSet} identifier dashboard role, unless avlm determined by environment
     */
    removeContext(identifier) {
      const activeContexts = this.get('activeContexts');
      const activeContext = typeof identifier === 'string' ? activeContexts.findBy('id', identifier) : identifier;
      return this.get('activeContexts').removeObject(activeContext);
    }

  });

  _exports.default = _default;
});