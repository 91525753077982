define("adept-iq/pods/components/rider-management-widget/rider-contact-information/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RIDER_VALIDATION = _exports.RIDER_PHONE_VALIDATION = void 0;

  /* eslint-disable no-unused-vars */
  const RIDER_VALIDATION = {
    firstName: [(0, _validators.validatePresence)({
      presence: true,
      description: 'First Name',
      message: '{description} is required'
    })],
    lastName: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Last Name',
      message: '{description} is required'
    })],
    dateOfBirth: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Date of Birth',
      message: '{description} is required'
    })]
  };
  _exports.RIDER_VALIDATION = RIDER_VALIDATION;
  const RIDER_PHONE_VALIDATION = {
    unformattedPhoneNumber: [(0, _validators.validateLength)({
      is: 10,
      allowBlank: true,
      description: 'Phone Number',
      message: '{description} 10 digit is required'
    })]
  };
  _exports.RIDER_PHONE_VALIDATION = RIDER_PHONE_VALIDATION;
});