define("adept-iq/classes/polylines/trips/iq", ["exports", "adept-iq/classes/polylines/trips/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    record: null,
    // `BasePolyline` overrides
    isActive: true,
    // `BaseTripPolyline` overrides
    otp: Ember.computed.readOnly('record.otp'),
    points: Ember.computed('record.polyline', function () {
      const points = this.get('record.polyline');
      return points;
    }),
    bemapCoords: Ember.computed('points', function () {
      const points = this.get('points') || [];
      return points.map(point => {
        return {
          lat: point[0],
          lon: point[1]
        };
      });
    })
  });

  _exports.default = _default;
});