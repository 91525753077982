define("adept-iq/pods/components/rider-management-widget/rider-activity-log/rider-log-form/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.editableSections = void 0;
  const editableSections = [{
    title: 'Add Activity Log Entry',
    fields: [{
      id: 'activity-type',
      type: 'enum',
      label: 'Activity',
      valuePath: 'userActivitySelected',
      cellDesc: 'Activity',
      editable: true,
      hidden: false,
      extra: {
        optionModelName: 'trip-activity-log-type',
        optionSearchPath: 'displayName',
        optionConfigCategoryName: 'config-System_Configuration-Activity_Log_Types-rider'
      }
    }, {
      id: 'Details',
      type: 'text-area',
      editable: true,
      label: 'Details',
      valuePath: 'details'
    }]
  }];
  _exports.editableSections = editableSections;
});