define("adept-iq/pods/components/generic-widgets/base-widget/component", ["exports", "resize-observer-polyfill"], function (_exports, _resizeObserverPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    classNames: ['dashboard-widget'],
    classNameBindings: ['disabled'],
    // override with per-widget static configuration
    config: null,
    // widget model (see: `app/classes/widget.js`)
    widget: null,
    width: Ember.computed.alias('widget.width'),
    height: Ember.computed.alias('widget.height'),
    state: Ember.computed.alias('widget.state'),
    isEditing: Ember.computed.alias('workspace.isEditing'),
    disabled: Ember.computed.readOnly('isEditing'),
    widgetTypeId: Ember.computed.alias('widget.typeId'),
    dashboardwidgets: Ember.computed.alias('workspace.dashboard.widgets'),
    isMaximized: Ember.computed('maximizer.maximizedWidget', 'widget', function () {
      return this.get('maximizer.maximizedWidget') === this.get('widget');
    }),

    didInsertElement() {
      this._super(...arguments);

      this._resizeObserver = new _resizeObserverPolyfill.default(() => {
        Ember.run.debounce(this, 'onWidgetResize', 250);
      });

      this._resizeObserver.observe(this.$()[0]);
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this._resizeObserver) {
        this._resizeObserver.disconnect();

        this._resizeObserver = null;
      }
    },

    removeMaximizedPostFix(widgetInstanceId) {
      const buffer = Ember.isPresent(widgetInstanceId) ? widgetInstanceId : '';
      return buffer.replace('_maximized', '');
    },

    dashboardWidget: Ember.computed('widgetTypeId', 'dashboardwidgets', function () {
      const widgetInstanceId = this.removeMaximizedPostFix(this.get('widgetInstanceId'));
      const dashboardwidgets = this.get('dashboardwidgets');
      const dashboardWidget = dashboardwidgets.find(widget => {
        const widgetId = `${this.get('config.modelName')}_${widget.get('id')}`; // this will be unique id

        return widgetId === widgetInstanceId;
      });

      if (!Ember.isNone(dashboardWidget) && Ember.isNone(dashboardWidget.get('isTraversalEnabled'))) {
        dashboardWidget.set('isTraversalEnabled', true);
      }

      return dashboardWidget;
    }),
    isTraversalEnabled: Ember.computed.alias('dashboardWidget.isTraversalEnabled'),

    // override this to resize 3rd party integrations (e.g. map)
    onWidgetResize() {}

  });

  _exports.default = _default;
});