define("adept-iq/models/break-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FLOATING_BREAK = _exports.FIXED_BREAK = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;
  const FLOATING_BREAK = 'floating';
  _exports.FLOATING_BREAK = FLOATING_BREAK;
  const FIXED_BREAK = 'fixed';
  _exports.FIXED_BREAK = FIXED_BREAK;

  var _default = Model.extend({
    name: attr('string'),
    category: attr('string'),
    defaultDuration: attr('number'),
    isPaid: attr('boolean'),
    isRecognized: attr('boolean'),
    shiftBreaks: hasMany('shift-break'),
    routeBreaks: hasMany('route-break'),
    isFloating: Ember.computed.equal('name', FLOATING_BREAK),
    isFixed: Ember.computed.equal('name', FIXED_BREAK)
  });

  _exports.default = _default;
});