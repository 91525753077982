define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/accident/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "lodash", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/accident/validation", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/accident/vehicle-search-config"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _lodash, _validation, _vehicleSearchConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_ACCIDENT_TYPE_MAP = {
    addDriverAccident: true,
    editDriverAccident: true,
    deleteDriverAccident: true
  };
  const ACCIDENT_FIELDS = ['notificationDate', 'accidentDate'];

  var _default = Ember.Component.extend({
    classNames: ['driver-accident-view'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    tableRef: null,
    activityAccidentTableRef: null,
    disableForm: true,
    // passed into view
    editAPI: null,
    selectedDriver: Ember.computed.readOnly('editAPI.selectedRecord'),
    selectedDriverAccident: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    accidentQueryParams: null,

    async init() {
      this._super(...arguments);

      const driver = this.get('selectedDriver');
      this.set('activityQueryParams', {
        driverId: `${driver.get('id')}`,
        eventLog: true
      });
      this.set('accidentQueryParams', {
        filter: `eq(driverId,${driver.get('id')})`
      });
      this.get('refreshTask').perform();
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const tableRef = this.get('tableRef'); // ember concurrency tasks

      const onSaveDriverAccidentRecord = this.get('onSaveDriverAccidentRecord');
      const onDeleteDriverAccidentRecord = this.get('onDeleteDriverAccidentRecord');
      const onValidateAction = this.get('validateDriverAccident'); // normal functions

      const onUndoDriverAccidentRecord = this.get('onUndoDriverAccidentRecord').bind(this);
      const onNewDriverAccidentRecord = this.get('onNewDriverAccidentRecord').bind(this);
      const onEditDriverAccidentRecord = this.get('onEditDriverAccidentRecord').bind(this);
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverAccidentRecord,
          newAction: onNewDriverAccidentRecord,
          deleteAction: onDeleteDriverAccidentRecord,
          editAction: onEditDriverAccidentRecord,
          undoAction: onUndoDriverAccidentRecord,
          selectedDriverBelongsTableRef: tableRef,
          hasChanges,
          onValidateAction,
          isChangesHappened: false
        });
      });
      this.addObserver('hasPendingChanges', this.hasPendingChangesObserver);
      this.get('refreshTask').perform().then(() => {
        Ember.run.later(() => {
          this.resetForm();
        }, 1000);
      });
    },

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_ACCIDENT_TYPE_MAP[event.get('actionType')];
    },

    driverAccidentChangeSet: Ember.computed('selectedDriverAccident', function () {
      const driverAccident = (0, _unwrapProxy.unwrapProxy)(this.get('selectedDriverAccident'));
      return this.setDefaultProperties(driverAccident);
    }),
    hasPendingChanges: Ember.computed('driverAccidentChangeSet.changes.length', function () {
      const driverAccidentChangeSet = this.get('driverAccidentChangeSet');
      const changes = driverAccidentChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      const driverAccidentChangeSet = this.get('driverAccidentChangeSet');

      if (driverAccidentChangeSet.get('isNew')) {
        const pendingChanges = this.get('driverAccidentChangeSet');
        const fields = (0, _lodash.flatten)(pendingChanges.changes).map(change => change.key);
        const isEveyPrePoulatedFieldsChange = fields.length ? (0, _lodash.every)(fields, field => ACCIDENT_FIELDS.includes(field)) : false;
        if (isEveyPrePoulatedFieldsChange) return !isEveyPrePoulatedFieldsChange;
        return !!this.get('hasPendingChanges');
      }

      return !!this.get('hasPendingChanges');
    },

    hasPendingChangesObserver() {
      const editAPI = this.get('editAPI');
      const hasPendingChanges = this.get('hasPendingChanges');
      Ember.run.scheduleOnce('afterRender', () => {
        editAPI.setProperties({
          isChangesHappened: hasPendingChanges
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      let pendingDriverAccidentRecord = this.get('pendingDriverAccidentRecord');

      if (record) {
        pendingDriverAccidentRecord = record;
        this.resetForm();
      } else if (!pendingDriverAccidentRecord) {
        pendingDriverAccidentRecord = store.createRecord('driver-accident');
        this.set('pendingDriverAccidentRecord', pendingDriverAccidentRecord);
      }

      if (pendingDriverAccidentRecord.get('isNew')) {
        pendingDriverAccidentRecord.setProperties({
          notification: false,
          notificationDate: new Date(),
          accidentDate: new Date(),
          transitVehicleInvolved: false,
          description: null,
          vehicle: null,
          driver: this.get('selectedDriver')
        });
      }

      return new _emberChangeset.default(pendingDriverAccidentRecord, (0, _emberChangesetValidations.default)(_validation.VALIDATION), _validation.VALIDATION);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedDriverAccident = this.get('selectedDriverAccident');

      if (selectedDriverAccident) {
        if (!selectedDriverAccident.get('isNew') && !selectedDriverAccident.get('isDeleted')) {
          yield selectedDriverAccident.reload();
        }
      }

      const tableRef = this.get('tableRef');
      const activityAccidentTableRef = this.get('activityAccidentTableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      if (activityAccidentTableRef) Ember.run.scheduleOnce('afterRender', activityAccidentTableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        const records = this.get('tableRef.records');

        if (!records.length) {
          editAPI.buttonSettings(true, false, true, true, true);
        } else {
          editAPI.buttonSettings();
        }
      });
    },

    validateDriverAccident: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverAccidentChangeSet = this.get('driverAccidentChangeSet');
      yield driverAccidentChangeSet.validate();
      const errors = driverAccidentChangeSet.errors;

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.reset();
          }

        });
        return false;
      }

      return true;
    }),
    onSaveDriverAccidentRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const pendingDriverAccidentRecord = this.get('driverAccidentChangeSet');
      const vehicle = (0, _unwrapProxy.unwrapProxy)(pendingDriverAccidentRecord.get('vehicle'));
      const tooltip = this.get('tooltip'); // validation

      const isValidDriverAccident = yield this.validateDriverAccident.perform();

      if (isValidDriverAccident) {
        try {
          if (Ember.isEmpty(vehicle)) {
            pendingDriverAccidentRecord.set('transitVehicleInvolved', false);
          } else {
            pendingDriverAccidentRecord.set('transitVehicleInvolved', true);
          }

          yield pendingDriverAccidentRecord.save();
          notifications.success('Record successfully saved.');
          this.set('pendingDriverAccidentRecord', null);
          Ember.run.later(() => {
            this.get('refreshTask').perform().then(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
              this.resetForm();
              tooltip.reset();
            });
          }, 1000);
          return pendingDriverAccidentRecord;
        } catch (e) {
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line
        }
      }
    }),
    onDeleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedDriverAccident = this.get('selectedDriverAccident');
      const notifications = this.get('notifications');

      try {
        yield selectedDriverAccident.destroyRecord();
        notifications.success('Record successfully deleted');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.resetForm();
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
          });
        }, 1000);
      } catch (e) {
        notifications.warning('Record failed to delete');
        console.warn(e); // eslint-disable-line
      }
    }),
    onDeleteDriverAccidentRecord: (0, _emberConcurrency.task)(function* () {
      const selectedDriverAccident = this.get('selectedDriverAccident');
      const tooltip = this.get('tooltip');
      if (!selectedDriverAccident) return;
      yield tooltip.pushConfirmation({
        title: 'Manage Driver',
        tip: 'Are you sure you want to delete this record?',
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        },

        primaryActionText: 'Confirm',
        primaryAction: () => {
          this.get('onDeleteRecord').perform();
          return tooltip.reset();
        }
      });
    }),

    onUndoDriverAccidentRecord() {
      const driverAccidentChangeSet = this.get('driverAccidentChangeSet');

      if (driverAccidentChangeSet) {
        driverAccidentChangeSet.rollback();
      }
    },

    onNewDriverAccidentRecord() {
      const selectedRow = this.get('selectedRow');
      const driverAccidentChangeSet = this.get('driverAccidentChangeSet');
      const defaultDate = new Date();

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      driverAccidentChangeSet.setProperties({
        notificationDate: defaultDate,
        accidentDate: defaultDate
      });
      this.set('disableForm', false);
      this.get('editAPI').buttonSettings(true, true, true, false, false); //False to enable the button, TRUE to disable
    },

    onEditDriverAccidentRecord() {
      const selectedRow = this.get('selectedRow');
      if (Ember.isEmpty(selectedRow)) return;
      this.set('disableForm', false);
      this.get('editAPI').buttonSettings(true, true, true, false, false);
    },

    onVehicleChange(vehicle) {
      let path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'vehicle';
      const driverAccidentChangeSet = this.get('driverAccidentChangeSet');
      driverAccidentChangeSet.set(path, vehicle);
    },

    actions: {
      onSaveRecord() {
        return this.onSaveDriverAccidentRecord.perform();
      },

      openVehicleModal() {
        const onVehicleChange = this.get('onVehicleChange').bind(this);
        const searchVehicleObj = Ember.Object.create({
          title: 'Vehicles',
          hasOverlay: true,
          config: _vehicleSearchConfig.default,
          primaryActionText: 'Select',
          className: 'vehicle-search-modal',
          primaryAction: record => {
            onVehicleChange(record);
          }
        });
        this.set('modalAPI', searchVehicleObj);
      }

    }
  });

  _exports.default = _default;
});