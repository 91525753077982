define("adept-iq/pods/components/form-components/vehicle-selection/component", ["exports", "lodash", "ember-light-table", "adept-iq/pods/components/form-components/vehicle-selection/config", "adept-iq/mixins/fetchAssignableVehicleDrivers", "adept-iq/utils/unwrapProxy"], function (_exports, _lodash, _emberLightTable, _config, _fetchAssignableVehicleDrivers, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // sort out proxies
  var _default = Ember.Component.extend(_fetchAssignableVehicleDrivers.default, {
    classNames: ['form-components-vehicle-selection'],
    store: Ember.inject.service(),
    vehiclesTable: null,
    vehicleIdSearchText: '',
    vehicleSort: null,
    // dispatchRoute: readOnly('stackFrame.options.dispatchRoute'),
    route: Ember.computed.readOnly('dispatchRoute.route'),
    // scheduling route,
    placeholderVehicle: Ember.computed.readOnly('route.vehicle'),
    provider: Ember.computed.readOnly('route.provider'),
    plannedStartTime: Ember.computed.readOnly('route.plannedStartTime'),
    //used in fetch vehicle driver mixin
    plannedEndTime: Ember.computed.readOnly('route.plannedEndTime'),
    //used in fetch vehicle driver mixin
    hasSelectedVehicle: Ember.computed.bool('selectedVehicle.id'),
    hasSelectedDriver: Ember.computed.bool('selectedDriver.id'),
    routeVehicleDriver: Ember.computed.alias('dispatchRoute.latestRouteVehicleDriver'),
    refreshFlagPath: Ember.computed.readOnly('extra.refreshFlagPath'),
    selectedVehiclePath: Ember.computed.readOnly('extra.selectedVehiclePath'),
    previousSelectedVehicle: Ember.computed.readOnly('extra.previousSelectedVehicle'),
    refreshFlag: null,
    isUnselectAssignedVehicle: Ember.computed.readOnly('unselectAssignedVehicle'),
    notShowAssignedVehicle: Ember.computed.readOnly('extra.notShowAssignedVehicle'),

    // do not show assigned vehicle for rescue and breakdown routes.
    init() {
      this._super(...arguments);

      const dispatchRoute = this.get('dispatchRoute');
      const record = this.get('record');
      const vehiclesTable = new _emberLightTable.default(_config.vehiclesTableColumns);
      const vehicleSort = {
        valuePath: '',
        sortOrder: 'asc'
      };
      this.set('vehicleSort', vehicleSort);
      this.set('vehiclesTable', vehiclesTable);

      if (Ember.isNone(dispatchRoute) && Ember.isPresent(record)) {
        this.set('dispatchRoute', record);
      } // initialize observer


      this.updateVehiclesTable();
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('selectedDriver', currentState.selectedDriver);
        this.set('selectedVehicle', currentState.selectedVehicle);
        this.set('driverPhoneNumber', currentState.driverPhoneNumber);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.setVehicle();
      this.refresh();
      const refreshFlagPath = this.get('refreshFlagPath');

      if (Ember.isPresent(refreshFlagPath)) {
        const propertyName = `record.${refreshFlagPath}`;
        this.addObserver(propertyName, this.refresh);
      }
    },

    willDestroyElement() {
      const refreshFlagPath = this.get('refreshFlagPath');

      if (Ember.isPresent(refreshFlagPath)) {
        const propertyName = `record.${refreshFlagPath}`;
        this.removeObserver(propertyName, this.refresh);
      }

      this._super(...arguments);
    },

    didReceiveAttrs() {
      this._super(...arguments);
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.setVehicle();
      const oldRefreshFlag = this.get('_previousRefreshFlag');
      const newRefreshFlag = this.get('refreshFlag');

      if (oldRefreshFlag && oldRefreshFlag !== newRefreshFlag) {
        this.refresh();
      }

      this.set('_previousRefreshFlag', newRefreshFlag);
    },

    setVehicle() {
      // Avoid circular updates: need to store previousSelectedVehicle, so we can restore previous selection.
      if (this.get('previousSelectedVehicle.id') && this.get('previousSelectedVehicle.id') === this.get('selectedVehicle.id')) {
        return;
      } // Avoid select None and set the default vehicle back.


      if (this.get('isNoVehicle')) return; // vehicle can come from dispatch or scheduling

      let vehicle = this.get('previousSelectedVehicle');

      if (!this.get('notShowAssignedVehicle') && Ember.isNone(vehicle)) {
        vehicle = (0, _unwrapProxy.unwrapProxy)(this.get('routeVehicleDriver.vehicle')) || (0, _unwrapProxy.unwrapProxy)(this.get('placeholderVehicle'));
      }

      this.setProperties({
        selectedVehicle: (0, _unwrapProxy.unwrapProxy)(vehicle)
      });
      this.updateVehiclesTable();
    },

    refresh() {
      const selectedVehiclePath = this.get('selectedVehiclePath');

      if (Ember.isPresent(selectedVehiclePath)) {
        const selectedVehicle = (0, _unwrapProxy.unwrapProxy)(this.get(`record.${selectedVehiclePath}`));

        if (Ember.isEmpty(selectedVehicle)) {
          this.setProperties({
            selectedVehicle: null
          });
        }
      }

      this.set('vehicleSort.valuePath', null);
      this.set('vehicleSort.sortOrder', null);
      this.set('vehicleIdSearchText', '');
      this.get('vehiclesTable.rows').clear();
      this.get('fetchVehiclesTask').perform();
    },

    sortVehicleOptions(vehicles, valuePath, sortOrder) {
      if (valuePath) {
        return _lodash.default.orderBy(vehicles, vehicle => {
          return Ember.get(vehicle, valuePath);
        }, sortOrder);
      }

      const sortedVehicles = _lodash.default.sortBy(vehicles, 'vehicle.name');

      return sortedVehicles;
    },

    filterVehicleOptions(vehicles, searchText) {
      if (Ember.isEmpty(searchText)) {
        return vehicles;
      }

      const filteredVehicles = vehicles.filter(option => {
        // Don't filter out None option
        if (option.isNoVehicle) return true;
        const vehicle = option.vehicle;

        if (vehicle) {
          if (vehicle.name.startsWith(searchText)) {
            return true;
          }
        }

        return false;
      });
      return filteredVehicles;
    },

    vehicleOptions: Ember.computed('vehicles.[]', 'vehicle.capacityCounts', 'vehicleSort.{valuePath,sortOrder}', 'vehicleIdSearchText', function () {
      const vehicles = this.get('vehicles') || [];
      const valuePath = this.get('vehicleSort.valuePath');
      const sortOrder = this.get('vehicleSort.sortOrder');
      const vehicleIdSearchText = this.get('vehicleIdSearchText');
      const selectedVehicle = this.get('selectedVehicle') || {
        noOfWheelChairSeats: 0,
        noOfAmbulatorySeats: 0,
        noOfServiceAnimalSeats: 0
      };
      const options = [{
        vehicle: null,
        isNoVehicle: true
      }];

      if (!Ember.isEmpty(vehicles)) {
        vehicles.forEach(vehicle => {
          options.push({
            vehicle,
            isNoVehicle: false
          });
        });
      }

      const filteredVehicles = this.filterVehicleOptions(options, vehicleIdSearchText);
      const sortedVehicles = this.sortVehicleOptions(filteredVehicles, valuePath, sortOrder); // always show selected vehicle

      if (selectedVehicle && !this.get('notShowAssignedVehicle') && Ember.isEmpty(sortedVehicles.findBy('vehicle.id', selectedVehicle.id)) && !selectedVehicle.isBroken && !this.get('isNoVehicle')) {
        sortedVehicles.unshift({
          vehicle: selectedVehicle,
          isNoVehicle: false
        });
      }

      return sortedVehicles;
    }),
    updateVehiclesTable: Ember.observer('vehicleOptions', function () {
      const vehicleOptions = this.get('vehicleOptions');
      const selectedVehicle = this.get('selectedVehicle');
      this.get('vehiclesTable').setRows(vehicleOptions);
      const rows = this.get('vehiclesTable.rows');
      const selectedRow = selectedVehicle ? rows.findBy('vehicle', selectedVehicle) : rows.findBy('isNoVehicle', true);

      if (selectedRow && !this.get('isUnselectAssignedVehicle')) {
        selectedRow.set('selected', true);
      }
    }),

    updateVehicleSearch(searchText) {
      this.set('vehicleIdSearchText', searchText);
    },

    actions: {
      onColumnClick(column) {
        const valuePath = column.valuePath;
        const sortOrder = column.ascending ? 'asc' : 'desc';
        this.set('vehicleSort.valuePath', valuePath);
        this.set('vehicleSort.sortOrder', sortOrder);
      },

      onSearchTextChange(searchText) {
        const previousSearchText = this.get('vehicleIdSearchText');

        if (searchText === previousSearchText) {
          return;
        }

        Ember.run.debounce(this, 'updateVehicleSearch', searchText, 250);
      },

      onClearSearchClick() {
        this.set('vehicleIdSearchText', '');
      },

      onSolutionRowClick(row
      /*, event */
      ) {
        const rows = this.get('vehiclesTable.rows');
        const selectedRow = rows.findBy('selected');
        if (row === selectedRow) return;

        if (selectedRow) {
          selectedRow.set('selected', false);
        }

        row.set('selected', true);
        const vehicle = row.get('vehicle');
        const selectedVehiclePath = this.get('selectedVehiclePath');
        this.set('selectedVehicle', vehicle);
        this.set('isNoVehicle', row.get('isNoVehicle'));

        if (Ember.isPresent(selectedVehiclePath)) {
          this.set(`record.${selectedVehiclePath}`, vehicle);
        } else {
          this.onSelectedVehicle(vehicle);
        }
      }

    }
  });

  _exports.default = _default;
});