define("adept-iq/services/ajax", ["exports", "ember-ajax/services/ajax", "adept-iq/config/api-urls"], function (_exports, _ajax, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  var _default = _ajax.default.extend({
    activityId: null,
    // We need to set a host so that the underlying methods will consume the headers map
    //  for everything that goes to any IQ endpoint.  Since it's just comparing hosts (not full URL),
    //  we can use any host from the API map.  Since config service should be present all the time,
    //  use it.
    host: _apiUrls.API.configService.host,

    setActivityId(aid) {
      this.set('activityId', aid);
    },

    headers: Ember.computed('activityId', function () {
      const aid = this.get('activityId');
      const result = aid ? {
        'AIQ-Client-Activity-ID': aid
      } : {};
      return result;
    }),

    /**
     * by default retry the api 2 more times
     * @param url
     * @param retries
     * @param delayTime
     * @returns {*}
     */
    retryGet(url, alternateURL) {
      let retries = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3;
      let delayTime = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
      let getURL = url;

      if (retries === 1) {
        getURL = alternateURL;
      }

      return this.request(getURL, {
        method: 'GET'
      }).catch(error => {
        if (retries === 0) {
          throw new Error(error);
        }

        return delay(delayTime).then(() => {
          return this.retryGet(url, alternateURL, retries - 1, delayTime);
        });
      });
    }

  });

  _exports.default = _default;
});