define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/lease-company/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/lease-company/validation", "adept-iq/config/placeholders", "lodash", "adept-iq/utils/guid"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset, _emberChangesetValidations, _validation, _placeholders, _lodash, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['vehicle-lookup-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // Global Placeholder
    CODE_PLACEHOLDER: _placeholders.CODE_PLACEHOLDER,
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    tableRef: null,
    code: null,
    description: null,
    editAPI: null,
    disableForm: true,
    showErrorMsg: false,
    currentDisplayName: null,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    vehicleLeaseCompanyChangeSet: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),
    hasPendingChanges: Ember.computed('vehicleLeaseCompanyChangeSet.changes.length', function () {
      const vehicleLeaseCompanyChangeSet = this.get('vehicleLeaseCompanyChangeSet');
      const changes = vehicleLeaseCompanyChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      return this.get('hasPendingChanges');
    },

    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleLeaseCompanyRecord = this.get('onSaveVehicleLeaseCompanyRecord');
      const onNewVehicleLeaseCompanyRecord = this.get('onNewVehicleLeaseCompanyRecord').bind(this);
      const onEditVehicleLeaseCompanyRecord = this.get('onEditVehicleLeaseCompanyRecord').bind(this);
      const refreshTask = this.get('refreshTask');
      const onUndoVehicleLeaseCompanyRecord = this.get('onUndoVehicleLeaseCompanyRecord').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      const hasRelationship = this.get('hasRelationship').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleLeaseCompanyRecord,
          newAction: onNewVehicleLeaseCompanyRecord,
          editAction: onEditVehicleLeaseCompanyRecord,
          undoAction: onUndoVehicleLeaseCompanyRecord,
          deleteAction: null,
          hasChanges,
          onValidateAction,
          refreshTask,
          hasRelationship
        });
        refreshTask.perform();
      });
    },

    willDestroyElement() {
      const records = this.get('tableRef.records') || [];
      records.map(record => {
        if (record.get('tableRef')) {
          record.set('tableRef');
        }
      });

      this._super(...arguments);
    },

    hasRelationship() {
      const store = this.get('store');
      const tooltip = this.get('tooltip');
      const description = this.get('vehicleLeaseCompanyChangeSet.description') || '';
      const vehicle = store.peekAll('vehicle').filter(record => record.get('active') && record.get('leaseCompany.description') === description);

      if (vehicle.length) {
        tooltip.pushConfirmation({
          title: 'Lookup',
          hasoverlay: true,
          tip: `There are ${vehicle.length} active vehicle(s) with this Vehicle Lease Company description. You must either change the Vehicle Lease Company description on these vehicle(s) or delete these vehicle(s) prior to changing the Vehicle Lease Company description.`,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.reset();
          }

        });
      }

      return vehicle.length;
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const tableRef = this.get('tableRef');
      let vehicleLeaseCompanyObj = this.get('pendingRecord');

      if (record) {
        vehicleLeaseCompanyObj = record;
        this.resetForm();
      }

      if (Ember.isEmpty(record)) {
        const data = {
          category: 'config-System_Configuration-vehicle_lease_companies',
          type: 'object'
        };
        vehicleLeaseCompanyObj = store.createRecord('cs-config-item', data);
        this.set('pendingRecord', vehicleLeaseCompanyObj);
      }

      if (vehicleLeaseCompanyObj.get('isNew')) {
        vehicleLeaseCompanyObj.setProperties({
          code: null,
          description: null,
          tableRef: tableRef
        });
      }

      this.set('showErrorMsg', false);
      const tableRefRecords = this.get('tableRef.records') || [];
      tableRefRecords.map(tableRefRecord => {
        if (!tableRefRecord.get('tableRef')) {
          tableRefRecord.set('tableRef', tableRef);
        }
      });
      return new _emberChangeset.default(vehicleLeaseCompanyObj, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('vehicleLeaseCompanyChangeSet');

      if (selectedRecord) {
        if (!selectedRecord.get('isNew') && !selectedRecord.get('isDeleted')) {
          yield selectedRecord.data.reload();
        }
      }

      const tableRef = this.get('tableRef');
      this.resetForm();
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        editAPI.buttonSettings();
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const VehicleLeaseCompanyRecord = this.get('vehicleLeaseCompanyChangeSet');
      yield VehicleLeaseCompanyRecord.validate();
      const errors = VehicleLeaseCompanyRecord.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveVehicleLeaseCompanyRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');
      const activityIdGuid = (0, _guid.guid)();
      if (!isValid) return;
      const notifications = this.get('notifications');
      const description = this.get('vehicleLeaseCompanyChangeSet.description') || '';
      const value = {
        description: description
      };
      const nameGuidConverted = `${description}-${activityIdGuid}`;
      const name = (0, _lodash.camelCase)(nameGuidConverted);
      const vehicleLeaseCompanyChangeSet = this.get('vehicleLeaseCompanyChangeSet');
      let vehicleLeaseCompanyRecord = this.get('vehicleLeaseCompanyChangeSet');

      if (vehicleLeaseCompanyRecord.get('isNew')) {
        vehicleLeaseCompanyRecord = (0, _unwrapProxy.unwrapProxy)(this.get('vehicleLeaseCompanyChangeSet'));
        vehicleLeaseCompanyRecord.set('name', name);
      } else {
        const id = this.get('vehicleLeaseCompanyChangeSet.id');
        const store = this.get('store');
        const record = `config-System_Configuration-vehicle_lease_companies/${id}`;
        vehicleLeaseCompanyRecord = store.peekRecord('cs-config-item', record);
      }

      vehicleLeaseCompanyRecord.set('displayName', description);
      vehicleLeaseCompanyRecord.set('description', description);
      vehicleLeaseCompanyRecord.set('value', value);

      try {
        yield vehicleLeaseCompanyRecord.save(); // changeset holds changes, execute the changes due to lookups using unorthodox saving practice
        // execute to change underlying object and rollback to remove internal changes list

        vehicleLeaseCompanyChangeSet.execute();
        vehicleLeaseCompanyChangeSet.rollback();
        notifications.success('Lookup record successfully saved.');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
            tooltip.reset();
          });
        }, 500);
      } catch (response) {
        if (response.errors[0].status === '409') {
          tooltip.pushConfirmation({
            title: 'Manage Driver/Vehicle',
            tip: 'Description cannot duplicate an existing Description.',
            primaryActionText: 'OK',
            primaryAction: () => {
              return tooltip.reset();
            }
          });
        } else {
          notifications.warning('Lookup record failed to save.');
        }

        if (vehicleLeaseCompanyRecord.rollbackAttributes) {
          vehicleLeaseCompanyRecord.rollbackAttributes();
        }

        console.warn(response); // eslint-disable-line
      }
    }).drop(),

    onEditVehicleLeaseCompanyRecord() {
      const editAPI = this.get('editAPI');
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onUndoVehicleLeaseCompanyRecord() {
      const vehicleLeaseCompanyChangeSet = this.get('vehicleLeaseCompanyChangeSet');
      const editAPI = this.get('editAPI');

      if (vehicleLeaseCompanyChangeSet) {
        vehicleLeaseCompanyChangeSet.rollback();
      }

      this.set('disableForm', true);
      editAPI.buttonSettings(false, false, false, true, true);
    },

    onNewVehicleLeaseCompanyRecord() {
      const editAPI = this.get('editAPI');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    actions: {
      onSaveVehicleLeaseCompanyRecord() {
        return this.onSaveVehicleLeaseCompanyRecord.perform();
      },

      onInputValueChange(record, valuePath, value) {
        this.set('currentDisplayName', value);
        record.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});