define("adept-iq/router", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('register');
    this.route('login');
    this.route('forgot-password');
    this.route('reset-password');
    this.route('dashboard', {
      path: '/dashboard/:role/:id'
    }, function () {
      this.route('modals', function () {
        this.route('edit-form');
      });
    });
    this.route('maximized');
    this.route('index', {
      path: '/'
    }, function () {
      this.route('modals', function () {
        this.route('create-dashboard');
        this.route('settings');
      });
    });
    this.route('dashboard-info');
    this.route('terms-of-service');
    this.route('change-password');
    this.route('change-password-confirmation');
    this.route('require-new-password');
  });
  var _default = Router;
  _exports.default = _default;
});