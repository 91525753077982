define("adept-iq/tests/factories/avl-location", ["ember-data-factory-guy", "faker"], function (_emberDataFactoryGuy, _faker) {
  "use strict";

  const date = new Date();

  _emberDataFactoryGuy.default.define('avl-location', {
    default: {
      lat: () => _faker.default.address.latitude(),
      lng: () => _faker.default.address.longitude(),
      heading: () => _faker.default.random.number({
        min: 0,
        max: 359,
        precision: 0
      }),
      speed: () => _faker.default.random.number({
        min: 0,
        max: 100,
        precision: 0
      }),
      odo: () => _faker.default.random.number({
        min: 0,
        max: 100000,
        precision: 0
      }),
      timestamp: () => _faker.default.date.past(1, date)
    }
  });
});