define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/time-cell-item", ["exports", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-item", "moment"], function (_exports, _cellItem, _moment) {
  'use strict';

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TimeCellItem extends _cellItem.default {
    constructor(props) {
      super(props);
    }

    getColumnValue(data, columnInfo, rowIndex, columnIndex) {
      let columnValue = super.getColumnValue(data, columnInfo, rowIndex, columnIndex);
      const {
        format
      } = columnInfo;
      columnValue = columnValue ? this.getTimeStampValue(columnValue, format) : '';
      return columnValue;
    }

    getTimeStampValue(columnValue, format) {
      if (_moment.default.utc(columnValue).isValid()) {
        return _moment.default.utc(columnValue).format(format);
      }

      const currentDate = (0, _moment.default)();
      const splittedTimes = columnValue.split(':'); //set time in current moment date

      return currentDate.set({
        hour: splittedTimes[0],
        minute: splittedTimes[1]
      }).format(format);
    }

  }

  _exports.default = TimeCellItem;
});