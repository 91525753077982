define("adept-iq/tests/factories/subscription-recurrence-pattern", ["ember-data-factory-guy", "faker"], function (_emberDataFactoryGuy, _faker) {
  "use strict";

  _emberDataFactoryGuy.default.define('subscription-recurrence-pattern', {
    polymorphic: false,
    // manually flag this model as NOT polymorphic
    sequences: {
      randomBoolean: () => _faker.default.random.boolean()
    },
    default: {
      type: 'daily',
      separationCount: 1,
      sunday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      monday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      tuesday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      wednesday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      thursday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      friday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      saturday: _emberDataFactoryGuy.default.generate('randomBoolean'),
      dayOfMonth: null,
      weekOfMonth: null,
      monthOfYear: null,
      everyweekday: null,
      pattern: null,
      recurring: true
    }
  });
});