define("adept-iq/tests/factories/avlm-route-break", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const date = (0, _moment.default)();
  const ahead = (0, _moment.default)().add(30, 'minute');
  const evening = (0, _moment.default)().startOf('day').add(16, 'hours');

  _emberDataFactoryGuy.default.define('avlm-route-break', {
    default: {
      plannedStartTime: date.toDate(),
      plannedEndTime: evening.toDate(),
      routeIndex: '3',
      scheduledTime: date.toDate(),
      promisedTime: date.toDate(),
      serviceStartTime: date.toDate(),
      serviceEndTime: ahead.toDate(),
      eta: date.toDate(),
      status: 'L'
    }
  });
});