define("adept-iq/pods/components/iq-widgets/passengers-booking-selection-widget/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/passengers-booking-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'rider',
    defaultSortId: 'referenceCode',
    defaultSortAsc: false,
    hideWidgetControls: true,
    hideHeader: true,
    checkBoxColumn: false,
    isFormControls: true,
    title: 'Passengers',
    columns: [{
      id: 'id',
      index: 0,
      type: 'uuid',
      isKey: true,
      label: 'ID',
      valuePath: 'id',
      editable: false,
      searchable: true,
      hidden: true,
      defaultWidth: 30
    }, {
      id: 'firstName',
      index: 1,
      type: 'text',
      label: 'First Name',
      valuePath: 'firstName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 77,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'lastName',
      index: 2,
      type: 'text',
      label: 'Last Name',
      valuePath: 'lastName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 76,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'phoneNumber',
      index: 4,
      type: 'text',
      label: 'Phone Number',
      valuePath: 'notificationPhoneNumbers.0.phoneNumber',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 121,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'dateOfBirth',
      index: 5,
      type: 'date',
      label: 'DOB',
      valuePath: 'dateOfBirth',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 80,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }]
  };
  _exports.default = _default;
});