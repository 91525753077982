define("adept-iq/models/avlm-phone-number", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    areaCode: attr('string'),
    phoneNumber: attr('string'),
    extension: attr('string'),
    type: attr('string'),
    avlmSchedule: belongsTo('avlm-schedule'),
    formattedPhoneNumber: Ember.computed('areaCode', 'phoneNumber', 'extension', function () {
      const phone = this.get('phoneNumber');
      const extension = this.get('extension');
      const areaCode = this.get('areaCode');

      if (extension) {
        return `(${areaCode})${phone}-#${extension}`;
      }

      return `(${areaCode})${phone}`;
    })
  });

  _exports.default = _default;
});