define("adept-iq/pods/components/form-components/quick-filters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jXZ8caAh",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[7,\"button\"],[11,\"class\",\"column-widget-filter-button\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-times-circle active-button\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"onRemoveFilterClick\"]],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"column-widget-filter-action\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onFilterApplyAction\"],null]],[9],[0,\"\\n    \"],[1,[23,[\"filter\",\"displayName\"]],false],[0,\"\\n  \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/quick-filters/template.hbs"
    }
  });

  _exports.default = _default;
});