define("adept-iq/tests/factories/sso-user", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('sso-user', {
    default: {
      email: 'some-random@email.com',
      userId: 'abc',
      firstName: 'some',
      lastName: 'random',
      status: 'active',
      lastLoginAt: new Date(),
      roleNames: ['admin']
    },
    traits: {
      otherUser: {
        email: 'other-random@email.com',
        userId: 'def',
        firstName: 'other',
        lastName: 'random',
        status: 'active',
        lastLoginAt: new Date(),
        roleNames: ['admin']
      }
    }
  });
});