define("adept-iq/pods/components/side-drawer/perform-stop/break/container/component", ["exports", "adept-iq/utils/unwrapProxy", "ember-concurrency", "moment", "adept-iq/mixins/version-check"], function (_exports, _unwrapProxy, _emberConcurrency, _moment, _versionCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STOP_ONBREAK_STATUS = 'On break';

  var _default = Ember.Component.extend(_versionCheck.default, {
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    geocode: Ember.inject.service(),
    // passed in by side-drawer-container component
    onShrinkClick: null,
    onCloseClick: null,
    stopPoint: null,
    driverBreak: null,
    arrive: false,
    depart: false,
    shouldArrive: true,
    currentState: null,
    initStatus: null,
    route: Ember.computed.readOnly('stopPoint.dispatchRoute.route'),
    routeVehicleDriver: Ember.computed.readOnly('route.dispatchRoute.latestRouteVehicleDriver'),
    address: Ember.computed.readOnly('driverBreak.place.address'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    defaultActualArriveTime: Ember.computed('stopPoint.actualArriveTime', function () {
      const arriveTime = this.get('stopPoint.actualArriveTime');
      const lastContainerDepartTime = this.get('lastContainerDepartTime');

      if (Ember.isNone(arriveTime) && !Ember.isNone(this.get('currentState.actualArriveTime'))) {
        return this.get('currentState.actualArriveTime');
      }

      if (Ember.isNone(arriveTime) && !Ember.isNone(lastContainerDepartTime)) {
        return lastContainerDepartTime;
      }

      if (Ember.isNone(arriveTime)) {
        return (0, _moment.default)().startOf('minute').toDate();
      }

      return (0, _moment.default)(arriveTime).toDate();
    }),
    defaultActualDepartTime: Ember.computed('stopPoint.actualDepartTime', function () {
      const departTime = this.get('stopPoint.actualDepartTime'); // This is only for inital value, so not update even when actualArriveTIme had been changed.
      // If changing minDate and date at same time will make flatpickr remove its value.

      const curDate = this.get('actualArriveTime');
      const lastContainerDepartTime = this.get('lastContainerDepartTime');

      if (Ember.isNone(departTime) && !Ember.isNone(this.get('currentState.actualDepartTime'))) {
        return this.get('currentState.actualDepartTime');
      }

      if (Ember.isNone(departTime) && !Ember.isNone(lastContainerDepartTime)) {
        return lastContainerDepartTime;
      }

      if (Ember.isNone(departTime)) {
        return curDate;
      }

      return (0, _moment.default)(departTime).toDate();
    }),

    setupDriverBreak(stopPoint) {
      const driverBreak = this.store.peekRecord('route-break', stopPoint.get('routeBreak.id'));
      const breakType = driverBreak.get('breakType.content');
      const isOffBreak = stopPoint.get('isBreakPerformed');
      const notPerformed = stopPoint.get('notPerformed');
      let startPlace = driverBreak.get('startPlace');
      const currentState = this.get('currentState'); // If we have currentState means this is restore from shrink or multiPerformed, do not reset break start/end.

      if (!Ember.isEmpty(currentState)) {
        this.set('driverBreak', driverBreak);
        return;
      } // If we have currentState means this is restore from shrink or multiPerformed, do not reset break start/end.


      if (!Ember.isEmpty(currentState)) {
        this.set('driverBreak', driverBreak);
        return;
      } // clear the on break address if status is pending


      if (!Ember.isEmpty(startPlace) && Ember.isEmpty(currentState) && notPerformed && breakType.get('name') === 'floating') {
        this.resetAddressLocation(startPlace);
      } // we don't want to show start break address once it's been arrived
      // then we are doing the depart


      if (!Ember.isEmpty(startPlace)) {
        startPlace.set('address.freeformAddress', '');
      }

      if (breakType.get('name') === 'floating' && Ember.isEmpty(startPlace)) {
        const startOrigin = this.store.createRecord('location', {
          lat: stopPoint.get('lat'),
          lng: stopPoint.get('lng')
        });
        const startAddress = this.store.createRecord('address', {
          type: 'vehicle',
          locations: [startOrigin]
        });
        startPlace = this.store.createRecord('place', {
          address: startAddress,
          location: startOrigin
        });
        driverBreak.set('startPlace', startPlace);
      }

      let endPlace = driverBreak.get('endPlace'); // clear off break address if status is pending or on break

      if (!Ember.isEmpty(endPlace) && Ember.isEmpty(currentState) && (notPerformed || !isOffBreak) && breakType.get('name') === 'floating') {
        this.resetAddressLocation(endPlace);
      }

      if (breakType.get('name') === 'floating' && Ember.isEmpty(endPlace)) {
        const endOrigin = this.store.createRecord('location');
        const endAddress = this.store.createRecord('address', {
          type: 'vehicle',
          locations: [endOrigin]
        });
        endPlace = this.store.createRecord('place', {
          address: endAddress,
          location: endOrigin
        });
        driverBreak.set('endPlace', endPlace);
      }

      this.set('driverBreak', driverBreak);

      if (breakType.get('name') === 'fixed') {
        driverBreak.set('endPlace', driverBreak.get('place'));
        driverBreak.set('startPlace', driverBreak.get('place'));
      }
    },

    init() {
      this._super(...arguments);

      const stopPoint = this.get('stopPoint');
      this.setupDriverBreak(stopPoint);
      this.set('arrive', this.get('isArrived'));
      this.set('initStatus', this.get('stopPoint.status'));
      this.set('actualArriveTime', this.get('defaultActualArriveTime'));
      this.set('actualDepartTime', this.get('defaultActualDepartTime'));
    },

    isArrived: Ember.computed('stopPoint.computedStatus', function () {
      const status = this.get('stopPoint.computedStatus');

      if (status) {
        return status === STOP_ONBREAK_STATUS;
      }

      return false;
    }),
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),

    resetAddressLocation(place) {
      const address = place.get('address');
      const location = place.get('location');

      if (Ember.isPresent(address)) {
        address.set('streetNumber', '');
        address.set('streetAddress', '');
        address.set('streetName', '');
        address.set('locality', '');
        address.set('region', '');
        address.set('subRegion', '');
        address.set('postalCode', '');
        address.set('country', '');
        address.set('county', '');
        address.set('freeformAddress', '');
      }

      if (Ember.isPresent(location)) {
        location.set('lat');
        location.set('lng');
        location.set('geoNode');
      }
    },

    resetStartEndPlace(stopPoint) {
      const startPlace = stopPoint.get('routeBreak.startPlace');
      const endPlace = stopPoint.get('routeBreak.endPlace');
      this.resetAddressLocation(startPlace);

      if (Ember.isPresent(stopPoint.get('routeBreak.place.id'))) {
        startPlace.set('address.freeFormAddress', stopPoint.get('routeBreak.place.address.fullAddress'));
      }

      this.resetAddressLocation(endPlace);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const stopPoint = this.get('stopPoint');
      const routeBreak = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('routeBreak'));
      const currentDate = (0, _moment.default)().startOf('minute').toDate();
      stopPoint.rollback();
      routeBreak.rollbackAttributes();
      this.resetStartEndPlace(stopPoint);
      stopPoint.set('actualDepartTime', currentDate);
      routeBreak.set('actualArriveTime', currentDate);
      this.set('actualArriveTime', currentDate);
      this.set('actualDepartTime', currentDate);
      const isArrived = this.get('isArrived');
      stopPoint.set('odometer');
      this.set('arrive', isArrived);
      this.set('depart', false); // call geocode service to remove all points selected on map

      this.get('geocode').deactivateGeocode();
      yield this.setupDriverBreak(stopPoint);
    }).keepLatest(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const stopPoint = this.get('stopPoint');
      const isArrived = this.get('isArrived'); // eslint-disable-next-line no-unused-vars

      let avlmVersion = null;
      stopPoint.set('odometer', null);

      if (this.get('arrive') && !isArrived) {
        avlmVersion = yield routeExecEventAdapter.createStartBreakEvent(stopPoint, avlmVersion);
      }

      if (this.get('depart')) {
        avlmVersion = yield routeExecEventAdapter.createEndBreakEvent(stopPoint, avlmVersion);
      }

      yield this.get('versionUpdateOperation').perform();
    }).drop(),

    didInsertElement() {
      const setStopContainerReference = this.get('setStopContainerReference');
      const stopPoint = this.get('stopPoint');
      this.setupDriverBreak(stopPoint);

      if (setStopContainerReference) {
        setStopContainerReference(this);
      }
    },

    actions: {
      onRefreshButtonClick() {
        this.get('refreshTask').perform();
      },

      onCancelButtonClick() {
        this.onCloseClick();
      },

      async onPerformButtonClick() {
        const lockRouteAction = this.get('lockRouteAction').bind(this);
        const unlockRouteAction = this.get('unlockRouteAction').bind(this);

        try {
          const tooltip = this.get('tooltip');
          const route = this.get('route');
          const stopPoint = this.get('stopPoint');
          const arrive = this.get('arrive');
          const depart = this.get('depart');
          const isArrived = this.get('isArrived');
          const arriveChanged = this.get('stopPoint.change.arrive');
          const dispatchRoute = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('dispatchRoute'));

          if (!dispatchRoute) {
            tooltip.pushConfirmation({
              tip: 'Perform break failed, Because break had been modified by another request.',
              title: 'Routebreak record changed',
              hasOverlay: true,
              primaryActionText: 'Confirm',
              primaryAction: async () => {
                const close = this.onCloseClick.bind(this);
                tooltip.reset();
                return close();
              }
            });
            return;
          }

          const routeEndTime = route.get('dispatchRoute.plannedEndTime');
          const arriveTime = (0, _moment.default)(this.get('actualArriveTime'));
          const departTime = (0, _moment.default)(this.get('actualDepartTime'));
          const actualStartTime = route.get('dispatchRoute.computedStartTime');
          const routeOrdinal = stopPoint.get('routeOrdinal');
          const routeStops = route.get('dispatchRoute.orderedStopsWithGaragesAndBreaks');
          const lastPerformedStop = routeStops.filter(stop => stop.isPerformed && !stop.isSoftPerformed).get('lastObject');
          const currentDateTime = (0, _moment.default)();
          const title = 'Perform Break';
          let messageText = '';
          let tip = ''; // Reset error text

          this.set('errorText');
          this.set('stopPoint.actualArriveTime', this.get('actualArriveTime'));
          this.set('stopPoint.actualDepartTime', this.get('actualDepartTime'));
          this.set('stopPoint.routeBreak.actualArriveTime', this.get('actualArriveTime'));
          this.set('stopPoint.routeBreak.actualDepartTime', this.get('actualDepartTime'));

          if (arrive && depart && !isArrived) {
            messageText = 'Performed break';
            tip = 'Perform break?';
          } else if (arrive && !isArrived) {
            messageText = 'Started break';
            tip = 'Start break?';
          } else if (depart) {
            messageText = 'Ended break';
            tip = 'End break?';
          } else {
            this.set('errorText', 'Please fill in either Actual Start or Actual End.');
            return false;
          }

          if (arriveChanged && arriveTime.isBefore(actualStartTime) || depart && departTime.isBefore(actualStartTime)) {
            this.set('errorText', "Performed Break Stop Failed. The stop cannot be performed before the route's start time");
            return false;
          }

          if (arriveChanged && arriveTime.isAfter(routeEndTime) || depart && departTime.isAfter(routeEndTime)) {
            this.set('errorText', "Performed Break Stop Failed. The stop cannot be performed after the route's end time.");
            return false;
          }

          if (arriveChanged && arriveTime.isAfter(currentDateTime) || depart && departTime.isAfter(currentDateTime)) {
            this.set('errorText', 'Performed Break Stop Failed. The stop cannot be performed after the current time.');
            return false;
          }

          if (routeOrdinal > 0 && lastPerformedStop && !lastPerformedStop.get('isNoShow')) {
            if (arriveChanged && arriveTime.isBefore(lastPerformedStop.get('actualDepartTime')) || depart && departTime.isBefore(lastPerformedStop.get('actualDepartTime'))) {
              this.set('errorText', 'Performed Break Stop Failed. The stop cannot be performed before the previous Actual drop time.');
              return false;
            }
          }

          const arriveTimeStamp = this.get('driverBreak.stopPoint.actualArriveTime');

          if (Ember.isEmpty(arriveTimeStamp) && arrive) {
            const startCoord = this.get('stopPoint.routeBreak.startPlace.location');

            if (Ember.isEmpty(startCoord.get('lat')) || Ember.isEmpty(startCoord.get('lng'))) {
              this.set('errorText', 'Performed Break Stop Failed. Cannot perform break without start location.');
              return false;
            }
          }

          if (depart) {
            const endCoord = this.get('stopPoint.routeBreak.endPlace.location');

            if (Ember.isEmpty(endCoord.get('lat')) || Ember.isEmpty(endCoord.get('lng'))) {
              this.set('errorText', 'Performed Break Stop Failed. Cannot perform break without end location.');
              return false;
            }
          } // Check dispatchRoute version first


          await this.get('versionCheckOperation').perform(this.onCloseClick.bind(this));
          await lockRouteAction();
          tooltip.pushConfirmation({
            tip,
            title,
            hasOverlay: true,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return this.get('saveTask').perform().then(() => {
                this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['stop-point']);
                this.get('notifications').success(`${messageText} successfully`);
              }).catch(response => {
                // clear odometer in stop point model
                stopPoint.set('odometer');
                const payloadMessage = Ember.get(response, 'payload.message') ? JSON.parse(Ember.get(response, 'payload.message')) : null;
                messageText = messageText + ' failed';

                if (response.status === 400 || response.status === 500) {
                  if (payloadMessage.errorDetail.includes('cannot be in state arrived unless all previous stops are performed') || payloadMessage.errorDetail.includes('with status arrived cannot come after')) {
                    messageText = 'Performed Break Failed. You cannot perform this break until previous stops are performed';
                  }

                  if (payloadMessage.errorDetail.includes('Execution version is different')) {
                    messageText = `PERFORM BREAK FAILED. THE ROUTE (${this.get('route.name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`;
                  }
                }

                this.get('notifications').warning(`${messageText}`);
              }).finally(async () => {
                await unlockRouteAction(0);
                this.get('workspace').requestWootricSurvey('on_breakPerform');
                tooltip.reset();
                this.onCloseClick();
              });
            },
            secondaryActionText: 'Cancel',
            secondaryAction: async () => {
              await unlockRouteAction(0);
              return tooltip.reset();
            },
            closeAction: async () => {
              await unlockRouteAction(0);
            }
          });
        } catch (e) {
          await unlockRouteAction();
        }
      }

    }
  });

  _exports.default = _default;
});