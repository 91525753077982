define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel/play", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api"], function (_exports, _react, _panel, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Play = () => {
    const {
      startPlay
    } = (0, _panel.usePanel)();
    const {
      eventData
    } = (0, _api.useAPI)();

    const [isDisabled, setIsDisabled] = _react.default.useState(true);

    _react.default.useEffect(() => {
      if (eventData.length > 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }, [eventData]);

    return _react.default.createElement("button", {
      className: "btn circle-btn",
      disabled: isDisabled,
      onClick: () => startPlay()
    }, _react.default.createElement("span", null, isDisabled ? _react.default.createElement("svg", {
      width: "32",
      height: "32",
      viewBox: "0 0 32 32",
      xmlns: "http://www.w3.org/2000/svg"
    }, _react.default.createElement("title", null, "play/disabled"), _react.default.createElement("defs", null, _react.default.createElement("linearGradient", {
      x1: "50%",
      y1: "0%",
      x2: "50%",
      y2: "100%",
      id: "a"
    }, _react.default.createElement("stop", {
      stopColor: "#F9F9F9",
      offset: "0%"
    }), _react.default.createElement("stop", {
      stopColor: "#D1D1D1",
      offset: "100%"
    }))), _react.default.createElement("g", {
      fill: "none",
      fillRule: "evenodd"
    }, _react.default.createElement("g", {
      transform: "translate(.819)"
    }, _react.default.createElement("circle", {
      stroke: "#B4B4B4",
      fill: "url(#a)",
      cx: "15.552",
      cy: "15.552",
      r: "15.052"
    }), _react.default.createElement("path", {
      d: "M21.98 14.988l-8.493-4.851c-.252-.136-.563-.113-.799-.113-.942 0-.938.659-.938.826v9.915c0 .14-.004.826.938.826.236 0 .548.022.799-.113l8.493-4.85c.697-.377.577-.82.577-.82s.12-.444-.577-.82z",
      fill: "#263238",
      fillRule: "nonzero",
      opacity: ".495"
    })))) : _react.default.createElement("svg", {
      width: "32px",
      height: "32px",
      viewBox: "0 0 32 32",
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg"
    }, _react.default.createElement("title", null, "play"), _react.default.createElement("desc", null, "Created with Sketch."), _react.default.createElement("defs", null, _react.default.createElement("linearGradient", {
      x1: "50%",
      y1: "0%",
      x2: "50%",
      y2: "100%",
      id: "linearGradient-1"
    }, _react.default.createElement("stop", {
      stopColor: "#F9F9F9",
      offset: "0%"
    }), _react.default.createElement("stop", {
      stopColor: "#D1D1D1",
      offset: "100%"
    }))), _react.default.createElement("g", {
      id: "Playback-2.4-Release",
      stroke: "none",
      fill: "none",
      fillRule: "evenodd"
    }, _react.default.createElement("g", {
      id: "play",
      transform: "translate(0.596794, 0.000000)"
    }, _react.default.createElement("circle", {
      id: "Oval",
      stroke: "#B4B4B4",
      fill: "url(#linearGradient-1)",
      cx: "15.5519981",
      cy: "15.5519981",
      r: "15.0519981"
    }), _react.default.createElement("path", {
      d: "M21.9803704,14.9876334 L13.4865596,10.136741 C13.2354577,10.0012326 12.9236558,10.023809 12.6881687,10.023809 C11.7462202,10.023809 11.7503986,10.6830109 11.7503986,10.8500166 L11.7503986,20.7647076 C11.7503986,20.9058975 11.7462752,21.590965 12.6881687,21.590965 C12.9236558,21.590965 13.2355127,21.6134418 13.4865596,21.4779832 L21.9803154,16.6271407 C22.6774848,16.251116 22.5570197,15.8073621 22.5570197,15.8073621 C22.5570197,15.8073621 22.6775398,15.3636082 21.9803704,14.9876334 Z",
      id: "Path",
      fill: "#263238",
      fillRule: "nonzero"
    }))))));
  };

  var _default = Play;
  _exports.default = _default;
});