define("adept-iq/pods/components/reconcile-column-editor/component", ["exports", "ember-light-table", "adept-iq/pods/components/reconcile-column-editor/config", "adept-iq/pods/components/reconcile-column-editor/stubs"], function (_exports, _emberLightTable, _config, _stubs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    classNames: ['reconcile-column-editor'],
    layoutName: 'components/iq-widgets/reconcile-widget',
    config: _config.default,
    onSaveClick: null,
    onCloseClick: null,
    editableColumnList: null,
    canSave: Ember.computed('editColumnTable.rows.@each.selected', function () {
      const rows = Ember.get(this, 'editColumnTable.rows');
      let isSaveEnable = false;
      rows.map(row => {
        const selectedElm = this.editableColumnList.filter(elm => {
          return elm.label === row.content.fieldId && elm.isEditable === row.selected;
        })[0];

        if (!selectedElm) {
          isSaveEnable = true;
        }
      });
      return isSaveEnable;
    }),

    init() {
      this._super(...arguments);

      const editColumnTable = new _emberLightTable.default(this.config.editColumnTableColumns);
      this.set('editColumnTable', editColumnTable);
      this.get('editColumnTable').setRows(_stubs.default.editColumnTableDate);
    },

    didInsertElement() {
      const rows = this.get('editColumnTable.rows');

      if (this.editableColumnList) {
        this.editableColumnList.map(editableElm => {
          rows.map(row => {
            if (editableElm.label === row.content.fieldId) {
              row.set('selected', editableElm.isEditable);
              row.set('isChecked', editableElm.isEditable);
            }
          });
        });
      } else {
        rows.map(row => {
          row.set('selected', false);
          row.set('isChecked', false);
        });
      }
    },

    showToolTip() {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Column Editor',
        tip: 'Would you like to commit your changes?',
        primaryActionText: 'Confirm',
        primaryAction: () => {
          this.send('confirm');
          return tooltip.reset();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    actions: {
      confirm() {
        const editColumnTable = this.get('editColumnTable');
        const editColumnTableList = editColumnTable.get('rows') || [];
        const columnEditRowList = [];
        editColumnTableList.map(row => {
          columnEditRowList.push({
            label: row.content.fieldId,
            isEditable: row.get('selected')
          });
        });
        this.onSaveClick(columnEditRowList);
        this.get('notifications').success('You have successfully changed the column editor settings');
      },

      onSaveButtonClick() {
        this.showToolTip();
      },

      onCloseButtonClick() {
        this.onCloseClick();
      }

    }
  });

  _exports.default = _default;
});