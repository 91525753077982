define("adept-iq/pods/components/table/cells/date-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8k1MdeiT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"moment-format\",[[23,[\"value\"]],[23,[\"dateFormat\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/table/cells/date-cell/template.hbs"
    }
  });

  _exports.default = _default;
});