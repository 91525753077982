define("adept-iq/serializers/cs-config-item", ["exports", "adept-iq/serializers/-cs-configService", "adept-iq/config/cs-config-model-category"], function (_exports, _csConfigService, _csConfigModelCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line no-undef
  const _ = require("lodash");

  const JSON_REGEX = /^\s*[{[]/;
  const OPTIONAL_ATTRS = ['description', 'unit', 'type'];
  const CATEGORY_DELIMITER = '-';
  const BOOLEAN_TYPE = 'Boolean';
  const YES_STRING = 'Yes';
  const NO_STRING = 'No';
  const TRUE_STRING = 'true';
  const FALSE_STRING = 'false';
  const logger = {
    warn: console.warn // eslint-disable-line no-console

  }; // merges arr2 onto arr1 in place

  const mergeRecordsInPlace = (arr1, arr2) => {
    const lookup = {};
    arr1.forEach(a => {
      lookup[`${a.type}-${a.id}`] = true;
    });
    arr2.forEach(b => {
      const id = `${b.type}-${b.id}`;
      if (lookup[id]) return;
      lookup[id] = true;
      arr1.push(b);
    });
    return arr1;
  };

  var _default = _csConfigService.default.extend({
    fixedRouteEngine: Ember.inject.service(),
    widget: Ember.inject.service(),
    attrs: {
      // do not list `type` or `value` here; these are need in `serialize()`
      category: {
        serialize: false
      },
      configCategory: {
        serialize: false
      },
      fieldConstraint: {
        serialize: false
      }
    },

    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      // this occurs when the client specifically asks for cs-config-items
      //   i.e. `store.findAll('cs-config-item')`
      // in this case, we need to substitute the correct serializer for any
      // config-items that correspond to config-models (e.g. vehicly-type) in IQUX
      if (primaryModelClass.modelName === 'cs-config-item') {
        const normalized = {
          data: [],
          included: []
        }; // split payloads into sets by config-model name

        const separatedData = Ember.makeArray(payload.data).reduce((obj, datum) => {
          const [categoryId] = datum.id.split('/');
          const modelName = _csConfigModelCategory.modelNameForCategory[categoryId] || 'cs-config-item';

          if (modelName) {
            obj[modelName] = obj[modelName] || [];
            obj[modelName].push(datum);
          }

          return obj;
        }, {});
        Object.entries(separatedData).forEach(_ref => {
          let [modelName, dataPayloads] = _ref;

          if (modelName !== 'cs-config-item') {
            // these config items back IQUX models (e.g. vehicle-type)
            const serializer = store.serializerFor(modelName);
            const modelClass = store.modelFor(modelName);
            const normed = serializer.normalizeArrayResponse(store, modelClass, {
              data: dataPayloads
            }, id, requestType);
            normed.data = normed.data || [];
            normed.included = normed.included || []; // because we used a specific serializer, the models will be in "data"
            // and the config-items will be in "included"; we need to swap these
            // because the client requested records of type `cs-config-item`

            mergeRecordsInPlace(normalized.data, normed.included.filterBy('type', 'cs-config-item'));
            mergeRecordsInPlace(normalized.included, normed.data);
            mergeRecordsInPlace(normalized.included, normed.included.rejectBy('type', 'cs-config-item'));
            return;
          } // these are just generic config-items, so process them normally


          const normed = this._super(store, primaryModelClass, {
            data: dataPayloads
          }, id, requestType);

          const augmented = this._addCategoriesToArrayPayload(normed);

          mergeRecordsInPlace(normalized.data, augmented.data);
          mergeRecordsInPlace(normalized.included, augmented.included);
        });
        return normalized;
      } // if execution reaches here, it is usually because a config-model (e.g.
      // vehicle-type) serializer called `this._super(...)`


      const normalized = this._super(...arguments);

      const augmented = this._addCategoriesToArrayPayload(normalized);

      return augmented;
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      const currentId = payload.data.id;
      const [categoryId, modelId] = currentId.split('/');
      const modelName = _csConfigModelCategory.modelNameForCategory[categoryId];

      if (primaryModelClass.modelName === 'cs-config-item' && !(modelName === 'cs-config-item' && requestType === 'createRecord')) {
        // try to substitute model-specific serializer
        if (modelName) {
          const serializer = store.serializerFor(modelName);
          const modelClass = store.modelFor(modelName); // by invoking the specific serializer, the specific model will be "data"

          const normalized = serializer.normalizeSingleResponse(store, modelClass, payload, modelId, requestType); // swap so that the generic config item is the "data" and the specific
          // model is in "included"

          const configItem = normalized.included.find(hash => {
            return hash.id === currentId && hash.type === 'cs-config-item';
          });
          normalized.included.removeObject(configItem);
          normalized.included.push(normalized.data);
          normalized.data = configItem;
          return normalized;
        }
      } // process as a normal config-item


      const normalized = this._super(...arguments);

      const augmented = this._addCategoriesToSinglePayload(normalized);

      return augmented;
    },

    normalize() {
      const normalized = this._super(...arguments); // extract category and name from id


      const {
        id
      } = normalized.data;
      const segments = id.split('/');
      const name = segments[segments.length - 1];
      const category = segments.slice(0, segments.length - 1).join('/');
      normalized.data.attributes.name = name;
      normalized.data.attributes.category = category;
      const {
        type,
        value,
        defaultValue
      } = normalized.data.attributes;

      if (Ember.typeOf(value) === 'string' && JSON_REGEX.test(value)) {
        try {
          normalized.data.attributes.value = JSON.parse(value);
        } catch (err) {
          logger.warn('failed to parse JSON-like value', value);
          normalized.data.attributes.value = {}; // empty hash gracefully protect ui component
        }
      } // booleans are encoded as "Yes" or "No" if defaultValue is "Yes" or "No"
      // booleans are encoded as "true" or "false" if defaultValue is "true" or "false"


      if (type === BOOLEAN_TYPE) {
        if (defaultValue === YES_STRING || defaultValue === NO_STRING) {
          normalized.data.attributes.value = value === YES_STRING;
        } else {
          normalized.data.attributes.value = value === TRUE_STRING;
        }
      }

      return normalized;
    },

    serialize() {
      const categoryId = arguments[0].record.get('category');

      const serialized = this._super(...arguments);

      OPTIONAL_ATTRS.forEach(attr => {
        if (Ember.isEmpty(serialized.data.attributes[attr])) {
          delete serialized.data.attributes[attr];
        }
      });
      const {
        name,
        displayName,
        type,
        value,
        defaultValue
      } = serialized.data.attributes;

      if (!name) {
        const camelizedName = Ember.String.camelize(displayName);
        serialized.data.attributes.name = camelizedName.replace(/[_\W]+/g, '_');
      } // booleans are encoded as "Yes" or "No" if defaultValue is "Yes" or "No"
      // booleans are encoded as "true" or "false" if defaultValue is "true" or "false"


      if (type === BOOLEAN_TYPE) {
        if (defaultValue === YES_STRING || defaultValue === NO_STRING) {
          serialized.data.attributes.value = value ? YES_STRING : NO_STRING;
        } else {
          serialized.data.attributes.value = value ? TRUE_STRING : FALSE_STRING;
        }
      } else if (Ember.typeOf(value) !== 'string') {
        // values must always be strings
        serialized.data.attributes.value = JSON.stringify(value);
      }

      if (categoryId === 'config-System_Configuration-speed_region_groups') {
        const updateValue = JSON.parse(serialized.data.attributes.value);
        updateValue.regions.forEach(region => {
          delete region.id;
          delete region.originalName;
        });
        serialized.data.attributes.value = JSON.stringify(updateValue);
      } // API doesn't accept relationships hash at all


      delete serialized.data.relationships;
      return serialized;
    },

    _addCategoriesToArrayPayload(payload) {
      payload.data = payload.data || [];
      payload.included = payload.included || [];
      payload.data.forEach(datum => {
        const categoryPayloads = this._buildCategoryPayloads(datum);

        mergeRecordsInPlace(payload.included, categoryPayloads);
        datum.relationships = datum.relationships || {};
        datum.relationships.configCategory = {
          data: {
            id: categoryPayloads[categoryPayloads.length - 1].id,
            type: 'cs-config-category'
          }
        };
      });
      return payload;
    },

    _addCategoriesToSinglePayload(payload) {
      payload.included = payload.included || [];

      const categoryPayloads = this._buildCategoryPayloads(payload.data);

      mergeRecordsInPlace(payload.included, categoryPayloads);
      payload.data = payload.data || {};
      payload.data.relationships = payload.data.relationships || {};
      payload.data.relationships.configCategory = {
        data: {
          id: categoryPayloads[categoryPayloads.length - 1].id,
          type: 'cs-config-category'
        }
      };
      return payload;
    },

    _buildCategoryPayloads(payload) {
      payload.attributes = payload.attributes || {};
      payload.attributes.category = payload.attributes.category || '';
      const segments = payload.attributes.category.split(CATEGORY_DELIMITER); // the last payload will be the most specific

      return segments.map((segment, i) => {
        const newPayload = {
          id: segments.slice(0, i + 1).join(CATEGORY_DELIMITER),
          type: 'cs-config-category',
          attributes: {
            name: segment,
            depth: i
          },
          relationships: {}
        };

        if (i > 0) {
          newPayload.relationships.parent = {
            data: {
              id: segments.slice(0, i).join(CATEGORY_DELIMITER),
              type: 'cs-config-category'
            }
          };
        }

        return newPayload;
      });
    },

    normalizeQueryResponse(store, clazz, payload) {
      if (this.get('widget.isConfigLoaded')) {
        const fixedRouteParams = this.get('fixedRouteEngine.fixedRouteEngineParams');
        if (_.isEmpty(fixedRouteParams)) return this._super(store, clazz, payload);
        payload.data.forEach(datum => {
          const [categoryId] = datum.id.split('/');
          const fixedRouteEngineId = 'config-Fixed_Route_Engine_Parameters';

          if (categoryId === fixedRouteEngineId && !Ember.isEmpty(fixedRouteParams)) {
            const FRESParams = fixedRouteParams[datum.attributes.displayName];
            datum.attributes.value = FRESParams.value;
            datum.attributes.defaultValue = FRESParams.defaultValue;
          }
        });
      }

      return this._super(store, clazz, payload);
    }

  });

  _exports.default = _default;
});