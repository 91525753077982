define("adept-iq/tests/factories/avlm-phone-number", ["ember-data-factory-guy", "faker"], function (_emberDataFactoryGuy, _faker) {
  "use strict";

  _emberDataFactoryGuy.default.define('rider-phone-number', {
    sequences: {
      areaCodes: () => _faker.default.phone.phoneNumber('###'),
      phoneNumbers: () => _faker.default.phone.phoneNumber('###-####'),
      extensions: () => _faker.default.phone.phoneNumber('###')
    },
    default: {
      areaCode: _emberDataFactoryGuy.default.generate('areaCodes'),
      phoneNumber: _emberDataFactoryGuy.default.generate('phoneNumbers'),
      extension: _emberDataFactoryGuy.default.generate('extensions'),
      type: 'cell'
    }
  });
});