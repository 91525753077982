define("adept-iq/pods/components/iq-widgets/vehicles-breakdown-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/vehicles-breakdown-form-widget/config", "moment", "adept-iq/utils/zone-validation", "adept-iq/mixins/version-check"], function (_exports, _component, _config, _moment, _zoneValidation, _versionCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_versionCheck.default, {
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    map: Ember.inject.service(),
    classNames: ['vehicle-breakdown-form-widget', 'data-test-vehicle-breakdown-form-widget'],

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      const boundingBox = this.get('map.boundingBox');
      this.set('editModal.errors', []);
      records.forEach(record => {
        const breakdownPlace = record.get('breakdownPlace');
        const breakdownDate = (0, _moment.default)(record.get('breakdownTime'));
        const breakdownType = record.get('breakdownType');
        const currentDate = (0, _moment.default)();

        if (Ember.isNone(breakdownPlace.get('address.address'))) {
          this.get('editModal.errors').pushObject('Address must be valid');
          valid = false;
        }

        if (Ember.isNone(breakdownPlace.get('location.lat')) || Ember.isNone(breakdownPlace.get('location.lng'))) {
          this.get('editModal.errors').pushObject('Location cannot be empty');
          valid = false;
        }

        if (!(0, _zoneValidation.insideZone)([breakdownPlace.get('location.lat'), breakdownPlace.get('location.lng')], boundingBox)) {
          this.get('editModal.errors').pushObject('Location must be within the agency service boundary');
          valid = false;
        }

        if (breakdownDate.isAfter(currentDate)) {
          this.get('editModal.errors').pushObject('Breakdown time cannot be set in the future');
          valid = false;
        }

        if (Ember.isNone(breakdownType)) {
          this.get('editModal.errors').pushObject('Must have a breakdown reason');
          valid = false;
        }
      });
      return valid;
    },

    undoForBreakDown() {
      const lastUndoState = this.get('editModal.undoHistory').popObject();
      lastUndoState.forEach(_ref => {
        let {
          record,
          properties
        } = _ref;

        if (record.location) {
          record.location.set('lat', null);
          record.location.set('lng', null);
        }

        if (properties.hasOwnProperty('breakdownType')) {
          record.set('breakdownType', properties.breakdownType);
          delete properties.breakdownType;
        }

        if (record.address && properties.hasOwnProperty('country')) {
          record.address.setProperties(properties);
        }

        Object.keys(properties).forEach(propertyName => {
          if (record.hasOwnProperty(propertyName)) {
            record.set(propertyName, properties[propertyName]);
          }
        });
      });
      this.set('isLastUndoStateCommitted', true);
      this.set('editModal.errors', []); // call geocode service to remove all points selected on map

      this.get('geocode').deactivateGeocode();
    },

    undoAllForBreakDown() {
      const undoHistory = this.get('editModal.undoHistory');
      if (Ember.isPresent(undoHistory)) while (undoHistory.length > 0) this.undoForBreakDown();
    },

    actions: {
      async onApplyClick() {
        const vehicle = this.get('editableRecords.firstObject');
        const dispatchRoute = vehicle ? vehicle.get('latestRouteVehicleDriver.dispatchRoute') : null;
        const lockRouteAction = this.get('lockRouteAction').bind(this);
        const unlockRouteAction = this.get('unlockRouteAction').bind(this);
        const clearRouteLockTimeoutId = this.get('clearRouteLockTimeoutId').bind(this);
        this.set('dispatchRouteToCheck', dispatchRoute);

        const closeCallback = () => {
          this.send('onCloseClick');
        };

        await this.get('versionCheckOperation').perform(closeCallback.bind(this));

        if (this.validateRecords()) {
          try {
            await lockRouteAction();
            const title = this.get('title'); // don't send unlockRouteAction as callback, cause if report breakdown successfully, we don't unlock the route.

            this.get('service').applyWithCallback(title, unlockRouteAction, clearRouteLockTimeoutId);
          } catch (err) {
            await unlockRouteAction(0);
            return;
          }
        }
      },

      onUndoClick() {
        this.undoAllForBreakDown();
      }

    }
  });

  _exports.default = _default;
});