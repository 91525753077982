define("adept-iq/pods/components/reconcile-unscheduled-trips/config", ["exports", "adept-iq/config/mapped-permIds"], function (_exports, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const componentTableHeader = 'reconcile-unscheduled-trips/table-header';
  var _default = {
    title: 'Unscheduled Trips',
    WAITLISTED: 'waitlisted',
    WILLCALL: 'willcall',
    CALLBACK: 'callback',
    CANCELLED: 'cancelled',
    FAILED: 'failed',
    ILLEGAL: 'illegal',
    component: 'side-drawer/reconcile-schedule-unscheduled-trips',
    rowComponent: 'table/rows/action-row',
    modelName: 'reconcile-trip',
    tripTableColumnsUnscheduledTrips: [{
      width: '40px',
      resizable: false,
      cellComponent: 'table/cells/radio-button',
      valuePath: 'none'
    }, {
      label: 'Trip ID',
      valuePath: 'tripId',
      width: '100px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Client ID',
      valuePath: 'passenger.id',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'First Name',
      valuePath: 'passenger.firstName',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Last Name',
      valuePath: 'passenger.lastName',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Anchor',
      valuePath: 'anchor',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Request Time',
      valuePath: 'promiseTime',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Pick Address1',
      valuePath: 'stopDetails.pick.address.formattedAddress',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Pick Address 2',
      valuePath: 'pickAddress2',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Pick City',
      valuePath: 'pickStopFormattedCity',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Pick Directions',
      valuePath: 'stopDetails.pick.direction',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Drop Address',
      valuePath: 'stopDetails.drop.address.formattedAddress',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Drop Address2',
      valuePath: 'dropAddress2',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Drop City',
      valuePath: 'dropStopFormattedCity',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Drop Directions',
      valuePath: 'stopDetails.drop.direction',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Amb Seats',
      valuePath: 'travelRequirements.ambulatory',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'WC Seats',
      valuePath: 'travelRequirements.wheelChair',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Companions',
      valuePath: 'travelRequirements.companion',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Fare',
      valuePath: 'price.fare',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Status',
      valuePath: 'statusLabel',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'PCA',
      valuePath: 'travelRequirements.pca',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Provider ID',
      valuePath: 'route.providerName',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Trip Type',
      valuePath: 'type',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Previous Route ID',
      valuePath: 'route.routeId',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }, {
      label: 'Travel Mode ID',
      valuePath: 'route.mode',
      width: '85px',
      resizable: true,
      minResizeWidth: 50,
      component: componentTableHeader
    }],
    contextMenuOptionsList: [{
      id: 'reconcileScheduleUnscheduledTrip',
      caption: 'Schedule Trip to a Route',
      dialogId: _mappedPermIds.default.transferTrip,
      dialogTitle: 'Schedule Trip to a Route'
    }, {
      id: 'reconcileTripLog',
      caption: 'Show Trip Log',
      dialogId: _mappedPermIds.default.activityLogTrip,
      dialogTitle: 'Show Trip Log'
    }]
  };
  _exports.default = _default;
});