define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/post-polyline", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/map", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api"], function (_exports, _react, _map, _panel, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PostPolyline = () => {
    const {
      map,
      movingMarkerLatLng
    } = _react.default.useContext(_map.MapContext);

    const {
      sliderIndex
    } = (0, _panel.usePanel)();
    const {
      eventData
    } = (0, _api.useAPI)();
    const {
      lat,
      lng
    } = movingMarkerLatLng;

    _react.default.useEffect(() => {
      if (!map || !lat || !lng) return;
      const points = eventData.slice(0, sliderIndex + 1).filter(event => event.displayLat !== 'N/A' && event.displayLng !== 'N/A').map(event => [Number(event.displayLat), Number(event.displayLng)]);
      const polyline = window.L.polyline(points, {
        color: '#555E63',
        opacity: 1,
        weight: 3
      });
      polyline.addTo(map.native);
      return () => map.native.removeLayer(polyline);
    }, [eventData, movingMarkerLatLng, sliderIndex]);

    return _react.default.createElement(_react.default.Fragment, null);
  };

  var _default = PostPolyline;
  _exports.default = _default;
});