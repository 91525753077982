define("adept-iq/tests/factories/dashboard", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('dashboard', {
    default: {
      name: null,
      role: null,
      category: null
    }
  });
});