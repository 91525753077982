define("adept-iq/pods/components/iq-widgets/cancel-subscription-trips/cancel-related-trips/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    booking: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    selectedTrips: Ember.observer('subscriptionTrips.@each.{isChecked}', function () {
      const checkedTrips = this.get('subscriptionTrips').filterBy('isChecked', true);
      const isAllChecked = this.get('isAllChecked');
      const isAllSubscriptionTripsChecked = this.get('subscriptionTrips').isEvery('isChecked');

      if (isAllSubscriptionTripsChecked && !isAllChecked) {
        this.toggleProperty('isAllChecked');
      }

      if (!isAllSubscriptionTripsChecked && isAllChecked) {
        this.toggleProperty('isAllChecked');
      }

      this.get('booking').setCheckedSubscriptionTrips(checkedTrips);
    }),
    actions: {
      onSelectedTrip(trip, event) {
        // eslint-disable-next-line no-unused-expressions
        event.target.checked ? Ember.set(trip, 'isChecked', true) : Ember.set(trip, 'isChecked', false);
      },

      onCheckAllTrips() {
        const trips = this.get('subscriptionTrips');
        this.toggleProperty('isAllChecked');
        trips.setEach('isChecked', this.get('isAllChecked'));
      }

    }
  });

  _exports.default = _default;
});