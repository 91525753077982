define("adept-iq/models/stop-point", ["exports", "ember-data", "adept-iq/mixins/otp-label", "moment", "lodash", "adept-iq/utils/convertDistance"], function (_exports, _emberData, _otpLabel, _moment, _lodash, _convertDistance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SOFT_PERFORM = _exports.SCHEDULED_STOP_STATUS = _exports.PULLED_OUT_STOP_STATUS = _exports.PULLED_IN_STOP_STATUS = _exports.PLANNED_STOP_STATUS = _exports.PERFORMED_STOP_STATUS = _exports.PENDING_STOP_STATUS = _exports.ON_BREAK_STOP_STATUS = _exports.OFF_BREAK_STOP_STATUS = _exports.NOSHOW_STOP_STATUS = _exports.CANCELLED_STOP_STATUS = _exports.ARRIVE_STOP_STATUS = _exports.ANCHOR_DROP_TYPE = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const SCHEDULED_STOP_STATUS = 'Scheduled';
  _exports.SCHEDULED_STOP_STATUS = SCHEDULED_STOP_STATUS;
  const CANCELLED_STOP_STATUS = 'Cancelled';
  _exports.CANCELLED_STOP_STATUS = CANCELLED_STOP_STATUS;
  const PERFORMED_STOP_STATUS = 'Departed';
  _exports.PERFORMED_STOP_STATUS = PERFORMED_STOP_STATUS;
  const ARRIVE_STOP_STATUS = 'Arrived';
  _exports.ARRIVE_STOP_STATUS = ARRIVE_STOP_STATUS;
  const NOSHOW_STOP_STATUS = 'No showed';
  _exports.NOSHOW_STOP_STATUS = NOSHOW_STOP_STATUS;
  const PLANNED_STOP_STATUS = 'planned';
  _exports.PLANNED_STOP_STATUS = PLANNED_STOP_STATUS;
  const PENDING_STOP_STATUS = 'Pending';
  _exports.PENDING_STOP_STATUS = PENDING_STOP_STATUS;
  const ON_BREAK_STOP_STATUS = 'On break';
  _exports.ON_BREAK_STOP_STATUS = ON_BREAK_STOP_STATUS;
  const OFF_BREAK_STOP_STATUS = 'Off break';
  _exports.OFF_BREAK_STOP_STATUS = OFF_BREAK_STOP_STATUS;
  const PULLED_OUT_STOP_STATUS = 'Pulled out';
  _exports.PULLED_OUT_STOP_STATUS = PULLED_OUT_STOP_STATUS;
  const PULLED_IN_STOP_STATUS = 'Pulled in';
  _exports.PULLED_IN_STOP_STATUS = PULLED_IN_STOP_STATUS;
  const ANCHOR_DROP_TYPE = 'drop';
  _exports.ANCHOR_DROP_TYPE = ANCHOR_DROP_TYPE;
  const otpStatusMapper = {
    'O': 'On Time',
    'D': 'In Danger',
    'L': 'Late',
    'E': 'Early'
  };
  const SOFT_PERFORM = 'Soft Perform';
  _exports.SOFT_PERFORM = SOFT_PERFORM;

  var _default = Model.extend(_otpLabel.default, {
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspace: Ember.inject.service(),
    stopType: attr('string'),
    parentType: attr('string'),
    waypointType: attr('string'),
    waypointSource: attr('string'),
    waypoint: attr('string'),
    onBoardCount: attr('number'),
    routeOrdinal: attr('number'),
    clusterOrdinal: attr('number'),
    lockedForOptimization: attr('boolean'),
    cluster: belongsTo('cluster'),
    noShow: belongsTo('no-show'),
    trip: belongsTo('trip'),
    tripStop: belongsTo('trip-stop'),
    etaNavStopPoint: belongsTo('etanav-stop-point'),
    routeBreak: belongsTo('route-break'),
    stopRoute: belongsTo('route'),
    dispatchSchedule: belongsTo('dispatch-schedule'),
    polyline: belongsTo('polyline'),
    // contains the polyline from one stop-point to another stop-point
    waypointPlace: belongsTo('place'),
    onboardPassengers: attr('number'),
    odometerOnArrival: attr('number'),
    widgetEta: attr('date'),
    // populated by route-exec-event
    arriveStatus: attr('string'),
    status: attr('string'),
    otp: attr('string'),
    otpValue: attr('number'),
    odometer: attr('string'),
    arriveLat: attr('string'),
    arriveLng: attr('string'),
    departLat: attr('string'),
    departLng: attr('string'),
    actualArriveTime: attr('date'),
    actualDepartTime: attr('date'),
    noShowTime: attr('date'),
    perform: attr('string'),
    arrivePerformedBy: attr('string'),
    departPerformedBy: attr('string'),
    actualArriveLat: attr('number'),
    actualArriveLng: attr('number'),
    actualDepartLat: attr('number'),
    actualDepartLng: attr('number'),
    slackInMinutes: attr('number'),
    // populated from stops-widget iqux-service
    segmentStopPhoneNumbers: attr('object'),
    formattedSegmentStopPhoneNumbers: attr('object'),
    // We need to always find the scheduling route information by going through dispatch route, in case the stop has been moved onto a new route
    route: Ember.computed.alias('dispatchRoute.route.content'),
    routeName: Ember.computed.alias('route.name'),
    serviceWindow: Ember.computed.alias('trip.segment.leg.serviceWindow'),
    polylinePoints: Ember.computed.readOnly('polyline.coords'),
    // note `promisedTime` vs. `promiseTime`
    promisedTime: Ember.computed.readOnly('trip.promiseTime'),
    isPick: Ember.computed.equal('stopType', 'pick'),
    isDrop: Ember.computed.equal('stopType', 'drop'),
    isPickOrDrop: Ember.computed.or('isPick', 'isDrop'),
    isGaragePullin: Ember.computed.equal('stopType', 'pullinGarage'),
    isGaragePullout: Ember.computed.equal('stopType', 'pulloutGarage'),
    isGarage: Ember.computed.or('isGaragePullin', 'isGaragePullout'),
    isArrived: Ember.computed.equal('computedStatus', ARRIVE_STOP_STATUS),
    isBreakPerformed: Ember.computed.equal('computedStatus', OFF_BREAK_STOP_STATUS),
    notPerformed: Ember.computed.equal('computedStatus', PENDING_STOP_STATUS),
    isNoShow: Ember.computed.equal('computedStatus', NOSHOW_STOP_STATUS),
    isBreak: Ember.computed.equal('stopType', 'break'),
    isDispatchDashboard: Ember.computed.readOnly('workspace.isDispatchDashboard'),
    isScheduleDashboard: Ember.computed.readOnly('workspace.isScheduleDashboard'),
    // Update when trying to perform 'noShow'
    noShowTimeThreshold: null,
    // Set by side-drawer perform pick/drop, only defined attributes can be rollback.
    // If not having these values, side-drawer edit stopPoint will get nonempty values.
    arriveTimestamp: attr('date'),
    departTimestamp: attr('date'),
    // populated from stops-widget iqux-service
    scheduleStopStatus: attr('string'),
    isPerformed: Ember.computed('computedStatus', 'isGaragePullout', 'etaNavStopPoint.status', function () {
      const computedStatus = this.get('computedStatus');

      if (this.get('isGaragePullout')) {
        return computedStatus === PULLED_OUT_STOP_STATUS;
      }

      if (this.get('isGaragePullin')) {
        return computedStatus === PULLED_IN_STOP_STATUS;
      }

      if (this.get('isBreak')) {
        return computedStatus === OFF_BREAK_STOP_STATUS;
      }

      return computedStatus === PERFORMED_STOP_STATUS || computedStatus === NOSHOW_STOP_STATUS;
    }),
    isSoftPerformed: Ember.computed('perform', 'isPerformed', function () {
      const perform = this.get('perform');
      const isPerformed = this.get('isPerformed');
      return isPerformed && perform === SOFT_PERFORM;
    }),
    isPerformable: Ember.computed('stopType', 'trip.stopPoints.@each.isPerformed', function () {
      const stopType = this.get('stopType');

      if (stopType === 'drop') {
        return this.get('trip.stopPoints').filter(s => s.get('stopType') === 'pick').every(s => s.get('isPerformed'));
      }

      return !this.get('isPerformed');
    }),
    isRealPerformable: Ember.computed('isSoftPerformed', 'stopType', 'trip.stopPoints.@each.isSoftPerformed', function () {
      const stopType = this.get('stopType');

      if (stopType === 'drop') {
        return this.get('trip.stopPoints').filter(s => s.get('stopType') === 'pick').every(s => s.get('isPerformed') && !s.get('isSoftPerformed'));
      }

      return this.get('isSoftPerformed');
    }),
    isPerformedOrBeingPerformed: Ember.computed('computedStatus', 'isGaragePullout', 'etaNavStopPoint.status', 'stopType', function () {
      const stopType = this.get('stopType');
      const isDrop = stopType === 'drop'; // Drop should not consider no show status as performed.

      const performedDrops = [PERFORMED_STOP_STATUS, ARRIVE_STOP_STATUS];
      const performedOthers = [PERFORMED_STOP_STATUS, ARRIVE_STOP_STATUS, NOSHOW_STOP_STATUS, OFF_BREAK_STOP_STATUS, ON_BREAK_STOP_STATUS, PULLED_IN_STOP_STATUS];
      const computedStatus = this.get('computedStatus');
      return isDrop ? performedDrops.includes(computedStatus) : performedOthers.includes(computedStatus);
    }),
    computedProvider: Ember.computed('trip.{isUnscheduled,provider}', 'dispatchRoute.provider', function () {
      if (this.get('trip.isUnscheduled')) {
        return this.get('trip.provider');
      }

      return this.get('dispatchRoute.provider');
    }),
    computedArrivePerformedBy: Ember.computed('arrivePerformedBy', function () {
      const arrivePerformedBy = this.get('arrivePerformedBy');
      return arrivePerformedBy ? `by ${(0, _lodash.upperCase)(arrivePerformedBy)}` : '';
    }),
    computedDepartPerformedBy: Ember.computed('departPerformedBy', function () {
      const departPerformedBy = this.get('departPerformedBy');
      return departPerformedBy ? `by ${(0, _lodash.upperCase)(departPerformedBy)}` : '';
    }),
    computedPerformedBy: Ember.computed('computedArrivePerformedBy', 'computedDepartPerformedBy', function () {
      const computedArrivePerformedBy = this.get('computedArrivePerformedBy');
      const computedDepartPerformedBy = this.get('computedDepartPerformedBy');
      return Ember.isPresent(computedDepartPerformedBy) ? computedDepartPerformedBy : computedArrivePerformedBy;
    }),
    computedStatus: Ember.computed('status', function () {
      const status = this.get('status') !== '' ? this.get('status') : null;
      return status;
    }),
    computedOdometer: Ember.computed('convertedOdometer', 'routeBreak.convertedOdometer', function () {
      const odometer = this.get('convertedOdometer');
      if (this.get('isBreak')) return this.get('routeBreak.convertedOdometer');
      return odometer;
    }),
    computedArriveLat: Ember.computed('arriveLat', 'actualArriveLat', function () {
      const arriveLat = this.get('arriveLat');
      if (this.get('isBreak')) return this.get('actualArriveLat');
      return arriveLat;
    }),
    computedArriveLng: Ember.computed('arriveLng', 'actualArriveLng', function () {
      const arriveLng = this.get('arriveLng');
      if (this.get('isBreak')) return this.get('actualArriveLng');
      return arriveLng;
    }),
    computedDepartLat: Ember.computed('departLat', 'actualDepartLat', function () {
      const departLat = this.get('departLat');
      if (this.get('isBreak')) return this.get('actualDepartLat');
      return departLat;
    }),
    computedDepartLng: Ember.computed('departLng', 'actualDepartLng', function () {
      const departLng = this.get('departLng');
      if (this.get('isBreak')) return this.get('actualDepartLng');
      return departLng;
    }),
    computedActualArriveTime: Ember.computed('actualArriveTime', 'routeBreak.actualArriveTime', 'computedStatus', 'stopType', function () {
      const actualArriveTime = this.get('actualArriveTime');
      if (this.get('isBreak')) return this.get('routeBreak.actualArriveTime') || actualArriveTime;
      if (this.get('arrive')) return actualArriveTime;
      if (this.get('computedStatus') === '') return '';
      return actualArriveTime;
    }),
    computedActualDepartTime: Ember.computed('actualDepartTime', 'routeBreak.actualDepartTime', 'computedStatus', 'stopType', function () {
      const actualDepartTime = this.get('actualDepartTime');
      if (this.get('isBreak')) return this.get('routeBreak.actualDepartTime') || actualDepartTime;
      if (this.get('depart')) return actualDepartTime;
      if (this.get('computedStatus') === '') return '';
      return actualDepartTime;
    }),
    computedPromiseTime: Ember.computed('isPick', 'promisedTime', function () {
      const isPick = this.get('isPick');

      if (isPick) {
        return this.get('promisedTime');
      }

      return '';
    }),
    computedApptTime: Ember.computed('isDrop', 'trip.segment.leg.requestTime', function () {
      const anchor = this.get('trip.segment.anchor');
      const isDrop = this.get('isDrop');

      if (anchor === ANCHOR_DROP_TYPE && isDrop) {
        return this.get('trip.segment.leg.requestTime');
      }

      return '';
    }),
    slackSecondsToMin: Ember.computed('etaNavStopPoint.slackSeconds', function () {
      const seconds = this.get('etaNavStopPoint.slackSeconds');
      if (Ember.isEmpty(seconds) && isNaN(seconds)) return '';
      return Math.floor(seconds / 60);
    }),
    slackTimeToSeconds: Ember.computed('etaNavStopPoint.slackSeconds', 'routeBreak.etaNavRouteBreak.slackSeconds', function () {
      const slackNavStopPoint = this.get('etaNavStopPoint.slackSeconds');
      const slackNavRouteBreak = this.get('routeBreak.etaNavRouteBreak.slackSeconds');

      if (this.get('isBreak')) {
        // Show EMPTY slack value if it is none
        if ((0, _lodash.isNil)(slackNavRouteBreak) || Ember.isEmpty(slackNavRouteBreak)) return '';
        return Math.floor(slackNavRouteBreak / 60);
      } // Show EMPTY slack value if it is none


      if ((0, _lodash.isNil)(slackNavStopPoint) || Ember.isEmpty(slackNavStopPoint)) return '';
      return Math.floor(slackNavStopPoint / 60);
    }),
    slackTime: Ember.computed('etaNavStopPoint.slackSeconds', 'routeBreak.etaNavRouteBreak.slackSeconds', function () {
      const slackNavStopPoint = this.get('etaNavStopPoint.slackSeconds');
      const slackNavRouteBreak = this.get('routeBreak.etaNavRouteBreak.slackSeconds');

      if (this.get('isBreak')) {
        return slackNavRouteBreak;
      }

      return slackNavStopPoint;
    }),
    arrivalTime: Ember.computed('tripStop.plannedEta', function () {
      return this.get('tripStop.plannedEta');
    }),
    departTime: Ember.computed('tripStop.{plannedEta,trip.promiseTime}', 'trip.rider.{loadTime,unloadTime}', function () {
      const anchor = this.get('trip.segment.anchor'); // some stops with serviceTypeId = 8 or 9 won't have plannedEta

      const plannedEta = this.get('tripStop.plannedEta') || this.get('tripStop.trip.promiseTime');
      const mPlannedEta = (0, _moment.default)(plannedEta);

      if (!Ember.isPresent(plannedEta)) {
        return '';
      }

      if (anchor === 'pick') {
        const loadTime = this.get('trip.rider.loadTime');

        if (loadTime) {
          mPlannedEta.add(loadTime, 'seconds');
        }

        return mPlannedEta.toDate();
      }

      if (anchor === 'drop') {
        const unloadTime = this.get('trip.rider.loadTime');

        if (unloadTime) {
          mPlannedEta.add(unloadTime, 'seconds');
        }

        return mPlannedEta.toDate();
      }

      return '';
    }),
    otpValueWithSign: Ember.computed('otp', 'otpValue', function () {
      const otp = this.get('otp');
      const otpValue = this.get('otpValue');
      const sign = otp === 'O' || otp === 'E' || otp === otpStatusMapper.O || otp === otpStatusMapper.E ? '-' : '+';

      if (!Ember.isEmpty(otpValue)) {
        return otpValue !== 0 ? `${sign}${Math.abs(otpValue)}` : Math.abs(otpValue);
      }

      return '';
    }),
    convertedOdometer: Ember.computed('odometer', function () {
      const odometer = this.get('odometer');

      if (odometer) {
        return (0, _convertDistance.convertMetersToMiles)(odometer);
      }

      return '';
    }),
    dispatchRoute: Ember.computed('cluster.dispatchRoute', 'stopRoute.dispatchRoute', function () {
      const dispatchRoute = this.get('stopRoute.dispatchRoute.content');
      const dispatchRouteFromCluster = this.get('cluster.dispatchRoute.content');

      if (dispatchRouteFromCluster) {
        return dispatchRouteFromCluster;
      }

      return dispatchRoute;
    }),
    place: Ember.computed('dispatchRoute.{startGarage,endGarage}', 'trip.segment.{pick.place,drop.place}', 'waypointPlace', 'stopType', 'routeBreak', 'isNoShow', 'stopRoute.vehicle.{startGarage,endGarage}', function () {
      const routeBreak = this.get('routeBreak.content');
      const trip = this.get('trip.content');
      const stopType = this.get('stopType');
      const isNoShow = this.get('isNoShow');
      const isScheduleDashboard = this.get('isScheduleDashboard');
      if (isNoShow && this.get('isDrop')) return null;
      if (routeBreak) return routeBreak.get('place');
      if (trip && trip.get('status') !== 'canceled' && stopType) return trip.get(`segment.${stopType}.place`);

      if (this.get('isGaragePullout')) {
        if (isScheduleDashboard) {
          return this.get('stopRoute.vehicle.startGarage');
        }

        return this.get('dispatchRoute.startGarage');
      }

      if (this.get('isGaragePullin')) {
        if (isScheduleDashboard) {
          return this.get('stopRoute.vehicle.endGarage');
        }

        return this.get('dispatchRoute.endGarage');
      }

      if (this.get('waypointPlace')) return this.get('waypointPlace');
      return null;
    }),
    notes: Ember.computed('trip', 'stopType', function () {
      const trip = this.get('trip.content');
      const stopType = this.get('stopType');
      if (trip && stopType) return trip.get(`segment.${stopType}.notes`);
      return null;
    }),
    tripStartTime: Ember.computed('trip.tripStartTime', 'actualDepartTime', 'tripStop.plannedEta', 'routeBreak.estimatedStart', 'etaNavStopPoint.{eta,notBefore}', 'widgetEta', function () {
      const tripStartTime = this.get('trip.tripStartTime');
      const minTripStartTime = this.get('dispatchRoute.route.tripStartTime');
      const actualDepartTime = this.get('actualDepartTime');
      const plannedEta = this.get('tripStop.plannedEta');
      const estimatedStart = this.get('routeBreak.estimatedStart');
      const parentType = this.get('parentType');
      const eta = this.get('etaNavStopPoint.eta') || this.get('widgetEta');

      switch (parentType) {
        case 'break':
          return estimatedStart;

        case 'pulloutGarage':
          return minTripStartTime ? minTripStartTime : this.get('etaNavStopPoint.notBefore') || this.get('widgetEta');

        case 'pullinGarage':
          return minTripStartTime ? minTripStartTime : eta;

        default:
          // when the stop is performed
          if (actualDepartTime) return actualDepartTime; // currently happening
          else if (eta) return eta; // planned time is given as route hasn't started
          else if (tripStartTime) return tripStartTime;
          return plannedEta;
      }
    }),
    tripEndTime: Ember.computed('trip.tripEndTime', 'actualDepartTime', 'tripStop.plannedEta', 'routeBreak.estimatedEnd', 'etaNavStopPoint.{eta,notBefore}', function () {
      const tripEndTime = this.get('trip.tripEndTime');
      const maxTripEndTime = this.get('dispatchRoute.route.tripEndTime');
      const plannedEta = this.get('tripStop.plannedEta');
      const actualDepartTime = this.get('actualDepartTime');
      const estimatedEnd = this.get('routeBreak.estimatedEnd');
      const parentType = this.get('parentType');
      const eta = this.get('etaNavStopPoint.eta');

      switch (parentType) {
        case 'break':
          return maxTripEndTime ? maxTripEndTime : estimatedEnd;

        case 'pulloutGarage':
          return maxTripEndTime ? maxTripEndTime : this.get('etaNavStopPoint.notBefore');

        case 'pullinGarage':
          return maxTripEndTime ? maxTripEndTime : eta;

        default:
          // when the stop is performed
          if (actualDepartTime) return actualDepartTime; // currently happening
          else if (eta) return eta; // planned end time is given as route hasn't started
          else if (maxTripEndTime) return maxTripEndTime;else if (tripEndTime) return tripEndTime;
          return plannedEta;
      }
    }),
    noShowAvailableTime: Ember.computed('noShowTimeThreshold', 'promisedTime', 'actualArriveTime', function () {
      const noShowTimeThreshold = this.get('noShowTimeThreshold');
      const promisedTime = this.get('promisedTime'); // When actualArriveTime is null, avoid noShowAvailableTime become NaN

      const actualArriveTime = this.get('actualArriveTime') || this.get('promisedTime');

      const noShowAvailableTime = _moment.default.max((0, _moment.default)(promisedTime), (0, _moment.default)(actualArriveTime));

      if (noShowTimeThreshold) {
        noShowAvailableTime.add(parseInt(noShowTimeThreshold.value, 10), noShowTimeThreshold.unit);
      }

      return noShowAvailableTime.toDate();
    }),
    routeTripStartTime: Ember.computed('dispatchRoute.tripStartTime', 'tripStartTime', function () {
      const routeStartTime = this.get('dispatchRoute.tripStartTime');
      const tripStartTime = this.get('tripStartTime');

      if (routeStartTime) {
        return routeStartTime;
      }

      return tripStartTime;
    }),
    routeTripEndTime: Ember.computed('dispatchRoute.tripEndTime', 'tripEndTime', function () {
      const routeEndTime = this.get('dispatchRoute.tripEndTime');
      const tripEndTime = this.get('tripEndTime');

      if (routeEndTime) {
        return routeEndTime;
      }

      return tripEndTime;
    }),
    lat: Ember.computed('estimatedBreakLatLng', 'place.location.lat', 'etaNavStopPoint.lat', 'isNoShow', 'routeBreak.place.location.lat', function () {
      const dsStopLat = this.get('place.location.lat');
      const breakLatLng = this.get('estimatedBreakLatLng');
      const etaLat = this.get('etaNavStopPoint.lat');
      const hypoBreakLat = this.get('routeBreak.place.location.lat');
      if (this.get('isNoShow') && this.get('isDrop')) return null; // Remove For AVLM 2.1

      if (!dsStopLat && breakLatLng) {
        return breakLatLng[0];
      }

      if (dsStopLat) return dsStopLat;
      if (etaLat) return etaLat;
      return hypoBreakLat;
    }),
    lng: Ember.computed('estimatedBreakLatLng', 'place.location.lng', 'etaNavStopPoint.lng', 'isNoShow', 'routeBreak.place.location.lng', function () {
      const dsStopLng = this.get('place.location.lng');
      const breakLatLng = this.get('estimatedBreakLatLng');
      const etaLng = this.get('etaNavStopPoint.lng');
      const hypoBreakLng = this.get('routeBreak.place.location.lng');
      if (this.get('isNoShow') && this.get('isDrop')) return null;

      if (!dsStopLng && breakLatLng) {
        return breakLatLng[1];
      }

      if (dsStopLng) return dsStopLng;
      if (etaLng) return etaLng;
      return hypoBreakLng;
    }),
    scheduleLocked: Ember.computed('dispatchSchedule.locked', function () {
      return this.get('dispatchSchedule.locked');
    }),
    stopDate: Ember.computed('dispatchRoute.route.plannedStartTime', function () {
      return this.get('dispatchRoute.route.plannedStartTime');
    }),
    mapId: Ember.computed('computedStatus', 'stopType', function () {
      const status = this.get('computedStatus');
      const stopType = this.get('stopType');
      if (status === NOSHOW_STOP_STATUS) return 'noshow';
      if (status === PERFORMED_STOP_STATUS) return 'performedStop';
      return stopType;
    }),
    isLocked: Ember.computed('dispatchRoute.isLocked', function () {
      return this.get('dispatchRoute.isLocked');
    }),
    lockedBy: Ember.computed('dispatchRoute.lockedBy', function () {
      return this.get('dispatchRoute.lockedBy');
    }),
    lockedId: Ember.computed('isLocked', function () {
      return this.get('dispatchRoute.id');
    }),
    isLockedByOtherUser: Ember.computed('dispatchRoute.isLockedByOtherUser', function () {
      return this.get('dispatchRoute.isLockedByOtherUser');
    }),
    eta: Ember.computed('isNoShow', 'etaNavStopPoint.eta', 'routeBreak.etaNavRouteBreak.eta', function () {
      const etaNavStopPoint = this.get('etaNavStopPoint.eta');
      const etaNavRouteBreak = this.get('routeBreak.etaNavRouteBreak.eta'); // discard eta if stop becomes no-show

      if (this.get('isNoShow')) return this.get('noShowTime');

      if (this.get('isBreak')) {
        return etaNavRouteBreak;
      }

      return etaNavStopPoint;
    }),
    computedEta: Ember.computed('isNoShow', 'etaNavStopPoint.eta', 'routeBreak.etaNavRouteBreak.eta', function () {
      const etaNavStopPoint = this.get('etaNavStopPoint.eta');
      const etaNavRouteBreak = this.get('routeBreak.etaNavRouteBreak.eta');

      if (this.get('isBreak')) {
        return etaNavRouteBreak;
      }

      return etaNavStopPoint;
    }),
    stopCloseToBreak: Ember.computed('routeOrdinal', 'dispatchRoute.orderedStopsWithGaragesAndBreaks', function () {
      const ordinal = this.get('routeOrdinal');
      const orderedStops = this.get('dispatchRoute.orderedStopsWithGaragesAndBreaks') || [];
      const orderedStopsLength = orderedStops.length;

      if (this.get('parentType') === 'break' && ordinal && ordinal > 0 && orderedStops) {
        // either a stop in front or behind the break
        const behind = ordinal - 1;
        const inFront = ordinal + 1;
        const isBreakAtLast = orderedStopsLength - 1 === inFront; // get behind stop if break is last

        let stop = orderedStops.find(s => s.get('routeOrdinal') === behind);

        if (stop) {
          return stop;
        } // get infront stop first as the closer stop


        stop = orderedStops.find(s => s.get('routeOrdinal') === inFront);

        if (stop && !isBreakAtLast) {
          return stop;
        }
      }

      return null;
    }),
    estimatedBreakLatLng: Ember.computed('stopCloseToBreak', 'actualArriveLat', 'actualArriveLng', 'routeBreak.breakType', function () {
      const isFloating = this.get('routeBreak.breakType.isFloating');
      const stop = this.get('stopCloseToBreak'); // this is only used by lat/lng and the break on marker for the map.

      const breakLat = this.get('actualArriveLat') || this.get('routeBreak.arriveLat');
      const breakLng = this.get('actualArriveLng') || this.get('routeBreak.arriveLng');

      if (breakLat && breakLng) {
        return [breakLat, breakLng];
      }

      if (isFloating && stop) {
        // otherwise return an estimated lat,lng
        const polyline = stop.get('polyline.coords');

        if (polyline) {
          const median = Math.floor(polyline.length / 2);
          return polyline[median];
        }

        const lat = stop.get('lat');
        const lng = stop.get('lng');
        return [lat, lng];
      }

      return null;
    }),
    computedMaximumStartTime: Ember.computed('computedStartTime', 'promisedTime', function () {
      const computedStartTime = this.get('computedStartTime');
      const promisedTime = this.get('promisedTime');

      if (!Ember.isEmpty(promisedTime)) {
        return promisedTime;
      }

      return computedStartTime;
    }),
    computedStartTime: Ember.computed('widgetEta', 'isNoShow', 'etaNavStopPoint.eta', 'tripStop.plannedEta', 'routeBreak.etaNavRouteBreak.eta', 'tripStartTime', function () {
      const stopType = this.get('stopType');
      const plannedEta = this.get('tripStop.plannedEta');
      const eta = this.get('widgetEta') || this.get('etaNavStopPoint.eta');
      const breakPlannedEta = this.get('routeBreak.etaNavRouteBreak.eta');
      const tripStartTime = this.get('tripStartTime'); // discard eta if stop becomes no-show

      if (this.get('isNoShow')) return null;

      if (stopType === 'pulloutGarage') {
        return tripStartTime;
      }

      if (stopType !== 'break') {
        if (Ember.isEmpty(eta)) {
          return plannedEta;
        }

        return eta;
      }

      return breakPlannedEta;
    }),
    availableTimeBeforeStop: Ember.computed('tripStop.plannedEta', 'etaNavStopPoint.eta', 'promisedTime', 'computedStartTime', 'actualDepartTime', 'actualArriveTime', function () {
      const isPick = this.get('isPick');
      const isGarage = this.get('isGarage');
      const computedStartTime = this.get('computedStartTime');
      const sources = [this.get('tripStop.plannedEta'), this.get('etaNavStopPoint.eta'), this.get('promisedTime')];
      const isPerformed = Ember.isPresent(this.get('actualDepartTime')) || Ember.isPresent(this.get('actualArriveTime'));
      const times = sources.reduce((a, s) => {
        if (Ember.isPresent(s)) {
          a.push((0, _moment.default)(s));
        }

        return a;
      }, []);

      if (isGarage) {
        return this.get('widgetEta');
      }

      if (isPick && !isPerformed) {
        return _moment.default.max(times).toDate();
      }

      return computedStartTime;
    })
  });

  _exports.default = _default;
});