define("adept-iq/serializers/dispatch-route", ["exports", "adept-iq/serializers/-dispatch", "@mapbox/polyline"], function (_exports, _dispatch, _polyline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    attrs: {
      replacementEndTime: {
        serialize: false
      },
      replacementMoveAllTrips: {
        serialize: false
      },
      replacementRouteId: {
        serialize: false
      },
      replacementStartTime: {
        serialize: false
      },
      routeStatus: {
        serialize: false
      },
      avlmVersion: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      const relationshipsToBeDeleted = ['provider', 'route', 'dispatchRouteLock', 'dispatchSchedule', 'scheduledPolyline'];

      if (json.data && json.data.relationships) {
        relationshipsToBeDeleted.forEach(relationship => {
          const relationshipData = json.data.relationships[relationship];

          if (Ember.isPresent(relationshipData)) {
            delete json.data.relationships[relationship];
          }
        });
      }

      return json;
    },

    normalize(typeClass, resourceHash) {
      // de-code polylines; for `navigationPolyline`, this is done by an
      // ember-data transform, but the nested structure here means we have to
      // do it manually
      if (resourceHash.attributes) {
        // Handles error if attributes is undefined
        const {
          stopPolylines,
          mode
        } = resourceHash.attributes;

        if (mode) {
          resourceHash.attributes.schedulingMode = mode;
        }

        if (stopPolylines) {
          stopPolylines.forEach(obj => {
            if (!obj.polyline) return;
            obj.polyline = _polyline.default.decode(obj.polyline);
          });
        }

        if (!Ember.isNone(resourceHash.attributes.providerName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.provider = {
            data: {
              type: 'provider',
              id: resourceHash.attributes.providerName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.providerName;
        }
      }

      const normalized = this._super(...arguments);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      const relationships = payload.data.relationships ? payload.data.relationships : {}; // Roadsup probably won't open routesWidget so it is not transformed.

      const routeId = payload.data.attributes._transformed ? payload.data.attributes.routeId : payload.data.attributes.id;

      if (Ember.isPresent(routeId)) {
        // Route need to use push directly, so null value can update ember store.
        const route = {
          data: {
            type: 'route',
            id: routeId,
            attributes: {
              otpValue: payload.data.attributes.otp || null,
              otp: payload.data.attributes.otpStatus || null,
              actualStartTimestamp: payload.data.attributes.actualStartDateTime || null,
              // Set null so it will clean up the value
              actualEndTimestamp: payload.data.attributes.actualEndDateTime || null
            }
          }
        };
        store.push(route);
      }

      if (Ember.isPresent(payload.data.attributes.polyline)) {
        const navigationPolyline = {
          type: 'polyline',
          id: routeId,
          attributes: {
            coords: payload.data.attributes.polyline
          },
          relationships: {
            route: {
              data: {
                type: 'route',
                id: routeId
              }
            }
          }
        };
        payload.included = payload.included || [];
        payload.included.push(navigationPolyline);
      }

      if (Ember.isPresent(payload.data.attributes.lock) && payload.data.attributes.dispatchRoutePk) {
        const dispatchRouteLock = {
          type: 'lock',
          id: payload.data.attributes.dispatchRoutePk,
          attributes: {
            entityType: 'dispatchRoute',
            entityId: payload.data.attributes.dispatchRoutePk,
            timeToLive: payload.data.attributes.lock.ttl,
            expirationTime: payload.data.attributes.lock.expiredAt,
            status: 'locked',
            persist: payload.data.attributes.lock.persist
          },
          relationships: {
            dispatchRoute: {
              data: {
                type: 'dispatchRoute',
                id: payload.data.attributes.dispatchRoutePk
              }
            },
            owner: {
              data: {
                type: 'sso-user',
                id: payload.data.attributes.lock.user
              }
            }
          }
        };
        relationships.dispatchRouteLock = {
          data: {
            type: 'lock',
            id: payload.data.attributes.dispatchRoutePk
          }
        };
        payload.included = payload.included || [];
        payload.included.push(dispatchRouteLock);
      } else if (Ember.isPresent(payload.data.attributes.isLocked) && !payload.data.attributes.isLocked && payload.data.attributes.dispatchRoutePk) {
        const dispatchRouteLock = {
          type: 'lock',
          id: payload.data.attributes.dispatchRoutePk,
          attributes: {
            entityType: 'dispatchRoute',
            entityId: payload.data.attributes.dispatchRoutePk,
            status: 'unlocked'
          },
          relationships: {
            dispatchRoute: {
              data: {
                type: 'dispatchRoute',
                id: payload.data.attributes.dispatchRoutePk
              }
            }
          }
        };
        relationships.dispatchRouteLock = {
          data: {
            type: 'lock',
            id: payload.data.attributes.dispatchRoutePk
          }
        };
        payload.included = payload.included || [];
        payload.included.push(dispatchRouteLock);
      }

      payload.data.relationships = relationships; // TODO: we need to store navigation polyline from widgetRecord

      return payload;
    }

  });

  _exports.default = _default;
});