define("adept-iq/utils/rql-generators", ["exports", "moment", "adept-iq/utils/encoding"], function (_exports, _moment, _encoding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeNeRQL = _exports.timeLteRQL = _exports.timeLtRQL = _exports.timeGteRQL = _exports.timeGtRQL = _exports.timeEqRQL = _exports.stringStartsWithRQL = _exports.stringNotLikeRQL = _exports.stringNeRQL = _exports.stringLtRQL = _exports.stringLikeRQL = _exports.stringIeqRQL = _exports.stringGtRQL = _exports.stringEqRQL = _exports.stringEnumRQL = _exports.stringBetweenRQL = _exports.strictlyNotBetweenRQL = _exports.strictlyBetweenRQL = _exports.orRQL = _exports.nullRQL = _exports.neRQL = _exports.lteRQL = _exports.ltRQL = _exports.inRQL = _exports.gteRQL = _exports.gtRQL = _exports.eqRQL = _exports.emptyRQL = _exports.dateStrictlyBetweenRQL = _exports.dateNotBetweenRQL = _exports.dateNeRQL = _exports.dateLteRQL = _exports.dateLtRQL = _exports.dateGteRQL = _exports.dateGtRQL = _exports.dateEqRQL = _exports.dateBetweenRQL = _exports.betweenRQL = _exports.andRQL = void 0;

  // compound RQL generators
  const andRQL = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return `and(${args.join(',')})`;
  };

  _exports.andRQL = andRQL;

  const orRQL = function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return `or(${args.join(',')})`;
  }; // elementary RQL generators


  _exports.orRQL = orRQL;

  const nullRQL = () => null;

  _exports.nullRQL = nullRQL;

  const emptyRQL = p => `or(eq(${p},null),eq(${p},[]))`;

  _exports.emptyRQL = emptyRQL;

  const eqRQL = (p, _ref) => {
    let [v] = _ref;
    return `eq(${p},${v})`;
  };

  _exports.eqRQL = eqRQL;

  const neRQL = (p, _ref2) => {
    let [v] = _ref2;
    return `ne(${p},${v})`;
  };

  _exports.neRQL = neRQL;

  const ltRQL = (p, _ref3) => {
    let [v] = _ref3;
    return `lt(${p},${v})`;
  };

  _exports.ltRQL = ltRQL;

  const lteRQL = (p, _ref4) => {
    let [v] = _ref4;
    return `le(${p},${v})`;
  };

  _exports.lteRQL = lteRQL;

  const gtRQL = (p, _ref5) => {
    let [v] = _ref5;
    return `gt(${p},${v})`;
  };

  _exports.gtRQL = gtRQL;

  const gteRQL = (p, _ref6) => {
    let [v] = _ref6;
    return `ge(${p},${v})`;
  };

  _exports.gteRQL = gteRQL;

  const betweenRQL = (p, _ref7) => {
    let [v, w] = _ref7;
    return andRQL(gteRQL(p, [v]), lteRQL(p, [w]));
  };

  _exports.betweenRQL = betweenRQL;

  const strictlyBetweenRQL = (p, _ref8) => {
    let [v, w] = _ref8;
    return andRQL(gtRQL(p, [v]), ltRQL(p, [w]));
  };

  _exports.strictlyBetweenRQL = strictlyBetweenRQL;

  const strictlyNotBetweenRQL = (p, _ref9) => {
    let [v, w] = _ref9;
    return orRQL(ltRQL(p, [v]), gtRQL(p, [w]));
  };

  _exports.strictlyNotBetweenRQL = strictlyNotBetweenRQL;

  const inRQL = (p, values) => {
    return `in(${p},(${values.join(',')}))`;
  }; // quoted values! API will break if types don't match


  _exports.inRQL = inRQL;

  const stringEqRQL = (p, _ref10) => {
    let [v] = _ref10;
    return `eq(${p},'${v}')`;
  };

  _exports.stringEqRQL = stringEqRQL;

  const stringNeRQL = (p, _ref11) => {
    let [v] = _ref11;
    return `ne(${p},'${v}')`;
  };

  _exports.stringNeRQL = stringNeRQL;

  const stringLtRQL = (p, _ref12) => {
    let [v] = _ref12;
    return `lt(${p},'${v}')`;
  };

  _exports.stringLtRQL = stringLtRQL;

  const stringGtRQL = (p, _ref13) => {
    let [v] = _ref13;
    return `gt(${p},'${v}')`;
  };

  _exports.stringGtRQL = stringGtRQL;

  const stringIeqRQL = (p, _ref14) => {
    let [v] = _ref14;
    return `ilike(${p},'${v}')`;
  };

  _exports.stringIeqRQL = stringIeqRQL;

  const stringLikeRQL = (p, values) => {
    if (values.length < 2) {
      return `ilike(${p},'%${values[0]}%')`;
    }

    return orRQL(values.map(value => `ilike(${p},'%${value}%')`));
  };

  _exports.stringLikeRQL = stringLikeRQL;

  const stringStartsWithRQL = (p, values) => {
    if (values.length < 2) {
      return `ilike(${p},'${values[0]}%')`;
    }

    return orRQL(values.map(value => `ilike(${p},'${value}%')`));
  };

  _exports.stringStartsWithRQL = stringStartsWithRQL;

  const stringEnumRQL = (p, values) => {
    return orRQL(values.map(value => `eq(${p},'${value}')`));
  };

  _exports.stringEnumRQL = stringEnumRQL;

  const stringNotLikeRQL = (p, _ref15) => {
    let [v] = _ref15;
    return `notilike(${p},'%${v}%')`;
  }; // need %...%


  _exports.stringNotLikeRQL = stringNotLikeRQL;

  const stringBetweenRQL = (p, _ref16) => {
    let [v, w] = _ref16;
    return andRQL(stringGtRQL(p, [v]), stringLtRQL(p, [w]));
  };

  _exports.stringBetweenRQL = stringBetweenRQL;

  const dateGtRQL = (p, _ref17) => {
    let [v] = _ref17;
    return `gt(${p},'${_moment.default.utc(v).format(_encoding.FORMAT_WITH_DATE_AND_TIME)}Z')`;
  };

  _exports.dateGtRQL = dateGtRQL;

  const dateGteRQL = (p, _ref18) => {
    let [v] = _ref18;
    return `ge(${p},'${_moment.default.utc(v).format(_encoding.FORMAT_WITH_DATE_AND_TIME)}Z')`;
  };

  _exports.dateGteRQL = dateGteRQL;

  const dateLtRQL = (p, _ref19) => {
    let [v] = _ref19;
    return `lt(${p},'${_moment.default.utc(v).format(_encoding.FORMAT_WITH_DATE_AND_TIME)}Z')`;
  };

  _exports.dateLtRQL = dateLtRQL;

  const dateLteRQL = (p, _ref20) => {
    let [v] = _ref20;
    return `le(${p},'${_moment.default.utc(v).format(_encoding.FORMAT_WITH_DATE_AND_TIME)}Z')`;
  };

  _exports.dateLteRQL = dateLteRQL;

  const dateEqRQL = (p, _ref21) => {
    let [v] = _ref21;
    return `eq(${p},'${_moment.default.utc(v).format(_encoding.FORMAT_WITH_DATE_AND_TIME)}Z')`;
  };

  _exports.dateEqRQL = dateEqRQL;

  const dateNeRQL = (p, _ref22) => {
    let [v] = _ref22;
    return `ne(${p},'${_moment.default.utc(v).format(_encoding.FORMAT_WITH_DATE_AND_TIME)}Z')`;
  };

  _exports.dateNeRQL = dateNeRQL;

  const timeEqRQL = (p, _ref23) => {
    let [v] = _ref23;
    return `eq(${p},'${_moment.default.utc(v).format(_encoding.FORMAT_WITH_DATE_AND_TIME)}Z')`;
  };

  _exports.timeEqRQL = timeEqRQL;

  const timeGtRQL = (p, _ref24) => {
    let [v] = _ref24;
    return `gt(${p},'${_moment.default.utc(v).format(_encoding.FORMAT_WITH_DATE_AND_TIME)}Z')`;
  };

  _exports.timeGtRQL = timeGtRQL;

  const timeLteRQL = (p, _ref25) => {
    let [v] = _ref25;
    return `le(${p},'${_moment.default.utc(v).format(_encoding.FORMAT_WITH_DATE_AND_TIME)}Z')`;
  };

  _exports.timeLteRQL = timeLteRQL;

  const timeLtRQL = (p, _ref26) => {
    let [v] = _ref26;
    return `lt(${p},'${_moment.default.utc(v).format(_encoding.FORMAT_WITH_DATE_AND_TIME)}Z')`;
  };

  _exports.timeLtRQL = timeLtRQL;

  const timeNeRQL = (p, _ref27) => {
    let [v] = _ref27;
    return `ne(${p},'${_moment.default.utc(v).format(_encoding.FORMAT_WITH_DATE_AND_TIME)}Z')`;
  };

  _exports.timeNeRQL = timeNeRQL;

  const timeGteRQL = (p, _ref28) => {
    let [v] = _ref28;
    return `ge(${p},'${_moment.default.utc(v).format(_encoding.FORMAT_WITH_DATE_AND_TIME)}Z')`;
  };

  _exports.timeGteRQL = timeGteRQL;

  const dateBetweenRQL = (p, _ref29) => {
    let [v, w] = _ref29;
    return andRQL(dateGteRQL(p, [v]), dateLteRQL(p, [w]));
  };

  _exports.dateBetweenRQL = dateBetweenRQL;

  const dateStrictlyBetweenRQL = (p, _ref30) => {
    let [v, w] = _ref30;
    return andRQL(dateGtRQL(p, [v]), dateLtRQL(p, [w]));
  };

  _exports.dateStrictlyBetweenRQL = dateStrictlyBetweenRQL;

  const dateNotBetweenRQL = (p, _ref31) => {
    let [v, w] = _ref31;
    return orRQL(dateLtRQL(p, [v]), dateGtRQL(p, [w]));
  };

  _exports.dateNotBetweenRQL = dateNotBetweenRQL;
});