define("adept-iq/scenarios/main", ["exports", "ember-data-factory-guy", "adept-iq/scenarios/config-service", "adept-iq/scenarios/rider-management-service", "adept-iq/scenarios/driver-service", "adept-iq/scenarios/vehicle-service", "adept-iq/scenarios/dispatch-service", "adept-iq/scenarios/booking-service", "adept-iq/scenarios/scheduling-service", "adept-iq/scenarios/zone-service", "adept-iq/scenarios/avlm-service", "adept-iq/scenarios/avlm-core-service", "adept-iq/scenarios/subscription-booking-service", "adept-iq/scenarios/sso-service", "adept-iq/config/environment"], function (_exports, _emberDataFactoryGuy, _configService, _riderManagementService, _driverService, _vehicleService, _dispatchService, _bookingService, _schedulingService, _zoneService, _avlmService, _avlmCoreService, _subscriptionBookingService, _ssoService, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Just for fun, set the log level ( to 1 ) and see all FactoryGuy response info in console
  _emberDataFactoryGuy.Scenario.settings({
    logLevel: 0 // 1 is the max for now, default is 0

  });

  class _default extends _emberDataFactoryGuy.Scenario {
    run() {
      if (!_environment.default.APP.avlmLite) this.include([_ssoService.default, _configService.default, _riderManagementService.default, _bookingService.default, _subscriptionBookingService.default, _driverService.default, _vehicleService.default, _schedulingService.default, _dispatchService.default, _zoneService.default, _avlmCoreService.default]);else this.include([_ssoService.default, _configService.default, _avlmService.default]);
    }

  }

  _exports.default = _default;
});