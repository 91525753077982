define("adept-iq/utils/zone-validation", ["exports", "adept-iq/config/zone-polygon"], function (_exports, _zonePolygon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.insideZone = insideZone;
  _exports.isJsonString = isJsonString;
  _exports.isPointInsidePolygon = isPointInsidePolygon;

  function isPointInsidePolygon(point, polygon) {
    const x = point[1];
    const y = point[0];
    let inside = false;
    if (x === '' || y === '') return inside;
    if (Ember.isEmpty(polygon)) return inside;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0];
      const yi = polygon[i][1];
      const xj = polygon[j][0];
      const yj = polygon[j][1];
      const intersect = yi > y !== yj > y && x < (xj - xi) * (y - yi) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }

  function insideZone(point, bbox) {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
    return isPointInsidePolygon(point, Ember.isPresent(bbox) ? bbox : _zonePolygon.default);
  }

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  }
});