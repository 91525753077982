define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/19a-review/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "lodash", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/19a-review/validation"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _lodash, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_19AREVIEW_TYPE_MAP = {
    addDriver19AReview: true,
    editDriver19AReview: true,
    deleteDriver19AReview: true
  };
  const ACTIVE_STATUS = 'active';

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    tableRef: null,
    activityReviewtableRef: null,
    disableForm: true,
    pendingDriverReviewRecord: null,
    selectedDriver: Ember.computed.alias('editAPI.selectedRecord'),
    selectedRecord: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    review19AQueryParams: null,
    providerOptions: Ember.computed('', function () {
      return this.get('store').peekAll('provider').filter(provider => {
        return provider.get('userCanAccessProvider') && provider.get('status') && provider.get('status').toLowerCase() === ACTIVE_STATUS;
      });
    }),
    typeOfDateOptions: Ember.computed('', function () {
      return this.get('store').peekAll('exam-type-name');
    }),
    driverReviewChangeSet: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),
    hasPendingChanges: Ember.computed('driverReviewChangeSet.changes.length', function () {
      const driverReviewChangeSet = this.get('driverReviewChangeSet');
      const changes = driverReviewChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      const driverReviewChangeSet = this.get('driverReviewChangeSet');

      if (driverReviewChangeSet.get('isNew')) {
        const pendingChanges = this.get('driverReviewChangeSet');
        const fields = (0, _lodash.flatten)(pendingChanges.changes).map(change => change.key);
        const isEveyPrePoulatedFieldsChange = fields.length ? fields.every(field => field.includes('examDate')) : false;
        if (isEveyPrePoulatedFieldsChange) return !isEveyPrePoulatedFieldsChange;
        return !!this.get('hasPendingChanges');
      }

      return !!this.get('hasPendingChanges');
    },

    init() {
      this._super(...arguments);

      const driver = this.get('selectedDriver');
      this.set('activityQueryParams', {
        driverId: `${driver.get('id')}`,
        eventLog: true
      });
      this.set('review19AQueryParams', {
        filter: `eq(driverId,${driver.get('id')})`
      });
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const tableRef = this.get('tableRef'); // ember concurrency tasks

      const onDeleteRecord = this.get('onDeleteRecord');
      const onSaveRecord = this.get('onSaveRecord');
      const onValidateAction = this.get('validateDriverReview'); // normal functions

      const onUndoRecord = this.get('onUndoRecord').bind(this);
      const onNewRecord = this.get('onNewRecord').bind(this);
      const onEditRecord = this.get('onEditRecord').bind(this);
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveRecord,
          newAction: onNewRecord,
          deleteAction: onDeleteRecord,
          editAction: onEditRecord,
          undoAction: onUndoRecord,
          selectedDriverBelongsTableRef: tableRef,
          hasChanges,
          onValidateAction,
          isChangesHappened: false
        });
      });
      this.addObserver('hasPendingChanges', this.hasPendingChangesObserver);
      this.get('refreshTask').perform().then(() => {
        Ember.run.later(() => {
          this.resetForm();
        }, 1000);
      });
    },

    hasPendingChangesObserver() {
      const editAPI = this.get('editAPI');
      const hasPendingChanges = this.get('hasPendingChanges');
      Ember.run.scheduleOnce('afterRender', () => {
        editAPI.setProperties({
          isChangesHappened: hasPendingChanges
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
    },

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_19AREVIEW_TYPE_MAP[event.get('actionType')];
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const editAPI = this.get('editAPI');
      let pendingDriverReviewRecord = this.get('pendingDriverReviewRecord');

      if (record) {
        pendingDriverReviewRecord = record;
        this.resetForm();
      } else if (!pendingDriverReviewRecord) {
        pendingDriverReviewRecord = store.createRecord('driver-19-a-review');
        this.set('pendingDriverReviewRecord', pendingDriverReviewRecord);
      }

      if (pendingDriverReviewRecord.get('isNew')) {
        pendingDriverReviewRecord.setProperties({
          provider: '',
          examTypeName: '',
          examDate: new Date(),
          driver: editAPI.selectedRecord
        });
      }

      return new _emberChangeset.default(pendingDriverReviewRecord, (0, _emberChangesetValidations.default)(_validation.DRIVER_REVIEW_VALIDATION), _validation.DRIVER_REVIEW_VALIDATION);
    },

    validateDriverReview: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverReviewChangeSet = this.get('driverReviewChangeSet');
      yield driverReviewChangeSet.validate();

      if (Ember.isEmpty(driverReviewChangeSet.get('provider.name'))) {
        driverReviewChangeSet.addError('provider', {
          value: driverReviewChangeSet.get('provider'),
          validation: 'Provider is required..'
        });
      }

      const errors = driverReviewChangeSet.errors;

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.reset();
          }

        });
        return false;
      }

      return true;
    }),
    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedDriverReview = this.get('selectedRecord');

      if (selectedDriverReview) {
        if (!selectedDriverReview.get('isNew') && !selectedDriverReview.get('isDeleted')) {
          yield selectedDriverReview.reload();
        }
      }

      const tableRef = this.get('tableRef');
      const activityReviewtableRef = this.get('activityReviewtableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      if (activityReviewtableRef) Ember.run.scheduleOnce('afterRender', activityReviewtableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        const records = this.get('tableRef.records');

        if (!records.length) {
          editAPI.buttonSettings(true, false, true, true, true);
        } else {
          editAPI.buttonSettings();
        }
      });
    },

    onSaveRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const pendingDriverReviewRecord = this.get('driverReviewChangeSet');
      const tooltip = this.get('tooltip'); // validation

      const isValidDriverReview = yield this.validateDriverReview.perform();

      if (isValidDriverReview) {
        try {
          yield pendingDriverReviewRecord.save();
          notifications.success('Record successfully saved.');
          this.set('pendingDriverReviewRecord', null);
          Ember.run.later(() => {
            this.get('refreshTask').perform().then(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
              this.resetForm();
              tooltip.reset();
            });
          }, 1000);
          return pendingDriverReviewRecord;
        } catch (e) {
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line
        }
      }
    }),
    onDelete19AReviewRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('selectedRecord');
      const notifications = this.get('notifications');

      try {
        yield selectedRecord.destroyRecord();
        this.set('pendingDriverReviewRecord', null);
        notifications.success('Record successfully deleted');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
            this.resetForm();
          });
        }, 1000);
      } catch (e) {
        notifications.warning('Record failed to delete');
        console.warn(e); // eslint-disable-line
      }
    }),
    onDeleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('selectedRecord');
      const tooltip = this.get('tooltip');
      if (!selectedRecord) return;
      yield tooltip.pushConfirmation({
        title: 'Manage Driver',
        tip: 'Are you sure you want to delete this record?',
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        },

        primaryActionText: 'Confirm',
        primaryAction: () => {
          this.get('onDelete19AReviewRecord').perform();
          return tooltip.reset();
        }
      });
    }),

    onUndoRecord() {
      const driverReviewChangeSet = this.get('driverReviewChangeSet');

      if (driverReviewChangeSet) {
        driverReviewChangeSet.rollback();
      }
    },

    onNewRecord() {
      const selectedRow = this.get('selectedRow');
      const editAPI = this.get('editAPI');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onEditRecord() {
      const selectedRow = this.get('selectedRow');
      const editAPI = this.get('editAPI');
      if (Ember.isEmpty(selectedRow)) return;
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    actions: {
      onSaveRecord() {
        this.onSaveRecord.perform();
      }

    }
  });

  _exports.default = _default;
});