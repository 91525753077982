define("adept-iq/models/zone-type", ["exports", "ember-data", "adept-iq/config/config-object-template"], function (_exports, _emberData, _configObjectTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    hasMany,
    attr
  } = _emberData.default;

  const ZONE_TYPE_DISPLAY_NAME = _configObjectTemplate.default['config-System_Configuration-zones'].fields.find(field => field.fieldName === 'displayName');

  var _default = Model.extend({
    name: attr('string'),
    displayName: attr('string'),
    value: attr(),
    addresses: hasMany('address'),
    zoneId: Ember.computed('name', function () {
      return parseInt(this.get('name'), 10);
    }),
    valueDisplayName: Ember.computed('value', function () {
      const value = this.get('value');
      if (Ember.isEmpty(value)) return '';
      const displayName = value[ZONE_TYPE_DISPLAY_NAME.displayName];
      return displayName ? displayName : value.displayName;
    })
  });

  _exports.default = _default;
});