define("adept-iq/pods/components/side-drawer/system-config/route-master/zone-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/side-drawer/system-config/route-master/zone-table/config", "adept-iq/models/zone"], function (_exports, _component, _config, _zone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['zone-table'],
    config: _config.default,
    store: Ember.inject.service(),
    // passed in from parent component
    routeTemplateChangeSet: null,
    disableRowSelection: false,
    hasZoneSelected: Ember.computed.gte('tableRef.checkedRows.length', 1),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    fetchDataQuery() {
      const affinities = this.get('routeTemplateChangeSet.affinities') || [];
      return this._super(...arguments).then(records => {
        return records.filter(record => {
          //affinities index start from [0-16] and zone Ids starts from 1-17
          const zoneId = record.get('zoneId') - 1;
          const affVal = affinities[zoneId] || _zone.AFFINITY_NO_SERVICE.val;
          record.set('affinityValue', affVal);
          return record;
        });
      });
    }

  });

  _exports.default = _default;
});