define("adept-iq/pods/components/iq-widgets/history-routes-widget/cells/hover-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-text-extension-cell'],
    textContent: Ember.computed('value', function () {
      const value = this.get('value');
      return value;
    }),
    actions: {
      showDropdown(dropdown, event) {
        dropdown.actions.open(event);
      },

      hideDropdown(dropdown, event) {
        dropdown.actions.close(event);
      }

    }
  });

  _exports.default = _default;
});