define("adept-iq/scenarios/driver-service", ["exports", "ember-data-factory-guy"], function (_exports, _emberDataFactoryGuy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Just for fun, set the log level ( to 1 ) and see all FactoryGuy response info in console
  _emberDataFactoryGuy.Scenario.settings({
    logLevel: 0 // 1 is the max for now, default is 0

  });

  class _default extends _emberDataFactoryGuy.Scenario {
    run() {
      const bookings = this.store.peekAll('booking');
      const provider = this.store.peekAll('provider').firstObject;
      const drivers = (0, _emberDataFactoryGuy.makeList)('driver', bookings.length, {
        provider
      });
      drivers.forEach(driver => {
        (0, _emberDataFactoryGuy.make)('driver-availability', {
          driver
        });
        (0, _emberDataFactoryGuy.make)('driver-attribute', {
          driver
        });
        (0, _emberDataFactoryGuy.make)('driver-phone-number', {
          driver
        });
      });
    }

  }

  _exports.default = _default;
});