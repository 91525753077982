define("adept-iq/pods/components/generic-components/content-overlay/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EEtvGW5g",
    "block": "{\"symbols\":[],\"statements\":[[2,\" reuse overlay from secondary window \"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/generic-components/content-overlay/template.hbs"
    }
  });

  _exports.default = _default;
});