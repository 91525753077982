define("adept-iq/models/reconcile-route", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    routeId: attr('string'),
    type: attr('string'),
    plannedStartTime: attr('date'),
    plannedEndTime: attr('date'),
    actualStartTime: attr('date'),
    actualEndTime: attr('date'),
    serviceMode: attr('string', {
      defaultValue: 'Paratransit / Broker'
    }),
    odometerStart: attr('number'),
    odometerEnd: attr('number'),
    status: attr('string'),
    notes: attr('string'),
    driverId: attr('string'),
    vehicleId: attr('string'),
    providerName: attr('string'),
    maxMilesPerHour: attr('number'),
    maxTripsPerHour: attr('number'),
    breakdownLocation: attr('string'),
    breakdownTime: attr('date'),
    breakdownReason: attr('string'),
    isDeleted: attr('boolean', {
      defaultValue: false
    }),
    isCompleted: attr('boolean', {
      defaultValue: false
    }),
    verifiedDate: attr('date'),
    verifiedBy: attr('string'),
    closedDate: attr('date'),
    closedBy: attr('string'),
    reopenedDate: attr('date'),
    reopenedBy: attr('string'),
    routeReopenedDate: attr('date'),
    routeReopenedBy: attr('string'),
    // Relationship bindings
    vehicle: belongsTo('reconcile-vehicle'),
    driver: belongsTo('reconcile-driver'),
    start: belongsTo('reconcile-stop'),
    end: belongsTo('reconcile-stop'),
    trips: hasMany('reconcile-trip'),
    breaks: hasMany('reconcile-stop'),
    isRouteDateClosed: Ember.computed.notEmpty('closedDate'),
    isKeeperRoute: Ember.computed('type', function () {
      return Ember.get(this, 'type') && Ember.get(this, 'type').toLowerCase() === 'keeper' ? 'Yes' : 'No';
    }),
    isRegularRoute: Ember.computed('type', function () {
      return !Ember.get(this, 'type') || Ember.get(this, 'type').toLowerCase() === 'regular';
    }),
    isBreakdownRoute: Ember.computed('type', function () {
      return Ember.get(this, 'type') && Ember.get(this, 'type').toLowerCase() === 'breakdown';
    }),
    isRescueRoute: Ember.computed('type', function () {
      return Ember.get(this, 'type') && Ember.get(this, 'type').toLowerCase() === 'rescue';
    }),
    isRescueReplacementRoute: Ember.computed('type', function () {
      return Ember.get(this, 'type') && Ember.get(this, 'type').toLowerCase() === 'rescue replacement';
    }),
    isBreakdownReplacementRoute: Ember.computed('type', function () {
      return Ember.get(this, 'type') && Ember.get(this, 'type').toLowerCase() === 'breakdown replacement';
    })
  });

  _exports.default = _default;
});