define("adept-iq/pods/components/side-drawer/system-config/speed-region-groups/speed-region-map/component", ["exports", "adept-iq/config/environment", "lodash", "turfHelpers", "geometryDifference", "booleanOverlap"], function (_exports, _environment, _lodash, _turfHelpers, _geometryDifference, _booleanOverlap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bemap
  } = window;
  const SEARCH_PLACE_TYPE = 'search-place';
  const SPEED_ADDRESS_TYPE = 'speed-address';
  const SPEED_REGION_TYPE = 'speed-region';
  const SPEED_REGION_GROUP_TYPE = 'speed-region-group';
  const MAX_NUMBER_POLYGON = 1;

  var _default = Ember.Component.extend(Ember.Evented, {
    booking: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    mapService: Ember.inject.service('map'),
    zoom: 10,
    softDeletedPolygons: null,
    // passed on
    isEditMode: false,
    speedAddress: null,
    speedRegion: null,
    speedRegionGroup: null,
    isBlockoutRegionSetType: false,
    showErrorPopupMessage: null,
    searchPlace: null,
    onSpeedRegionGroupFormChanges: null,
    drawnItems: null,
    errorInfoControl: null,
    restorePosition: null,

    init() {
      this._super(...arguments);

      this.set('softDeletedPolygons', []);
      this.set('geocode.isInvalidAddress', true);
    },

    didInsertElement() {
      this._super(...arguments);

      this.setupMapDrawControl();
      this.activeGeocodeChange();
    },

    activeGeocodeChange: Ember.observer('geocode.activeGeo', function () {
      const map = this.get('map');
      const geocodeService = this.get('geocode');
      const activeGeocode = geocodeService.get('activeGeo');
      if (!map) return;

      if (activeGeocode) {
        map.on('click', function (event) {
          geocodeService.saveNewGeocode(event.latlng);
        });
      } else {
        map.off('click');
      }
    }),

    setupMapDrawControl() {
      const mapService = this.get('mapService');
      const validateBlockoutAddresses = this.validateBlockoutAddresses.bind(this);
      const undoPolygon = this.undoPolygon.bind(this);
      const updateInfoControl = this.updateInfoControl.bind(this);
      const validatePolygon = this.validatePolygon.bind(this);
      const createNewPolygon = this.createNewPolygon.bind(this);
      const mapElementId = this.get('elementId');
      const bemapMainCtx = this.get('mapService.bemapMainCtx');
      const zoomLevel = this.get('zoom');
      const bnomadmap = new bemap.LeafletMap(bemapMainCtx, mapElementId, {
        zoom: zoomLevel
      }).defaultLayers();
      const map = bnomadmap.native;
      const drawnItems = new window.L.FeatureGroup();
      const errorInfoControl = this.addInfoControl(map);
      map.setView({
        lat: mapService.get('center.lat'),
        lng: mapService.get('center.lon')
      }, zoomLevel);
      map.addLayer(drawnItems);
      this.set('drawnItems', drawnItems);
      map.pm.addControls({
        position: 'topright',
        drawCircle: false,
        drawMarker: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawText: false,
        cutPolygon: false,
        rotateMode: false
      });
      map.pm.setPathOptions({
        color: 'blue',
        allowSelfIntersection: false
      });
      map.pm.setGlobalOptions({
        allowSelfIntersection: false,
        templineStyle: {
          color: 'blue'
        },
        hintlineStyle: {
          color: 'blue'
        },
        snappable: true,
        snapDistance: 5
      });
      map.on('pm:create', e => {
        const layer = e.layer;

        if (validatePolygon(layer)) {
          const geoJSON = layer.toGeoJSON();
          const latlngs = geoJSON.geometry.coordinates[0].map(point => {
            const newPoint = [point[1], point[0]];
            return newPoint;
          });
          createNewPolygon(latlngs);
          layer.pm.remove();
        } else {
          updateInfoControl('Zone polygon cannot intersect with other zones.');
          undoPolygon(layer);
        }
      });
      map.on('pm:globaleditmodetoggled', e => {
        validateBlockoutAddresses(e);
      });
      map.on('pm:globaldragmodetoggled', e => {
        validateBlockoutAddresses(e);
      });
      map.on('pm:globalremovalmodetoggled', e => {
        validateBlockoutAddresses(e);
      });
      map.on('pm:globaldrawmodetoggled', e => {
        validateBlockoutAddresses(e);
      });
      this.set('map', map);
      this.set('errorInfoControl', errorInfoControl);
      this.toggleToolbarButton(this.get('isEditMode'));
    },

    validateBlockoutAddresses(e) {
      if (!e.enabled) return;
      const map = this.get('map');
      const isBlockoutRegionSetType = this.get('isBlockoutRegionSetType');
      const speedRegionAddressModels = this.get('store').peekAll('speed-region-address');

      if (isBlockoutRegionSetType) {
        const speedRegionGroupAddresses = speedRegionAddressModels.filter(address => !address.isSoftDeletedRow);

        if (speedRegionGroupAddresses.length > 0) {
          map.pm.disableGlobalEditMode();
          map.pm.disableGlobalDragMode();
          map.pm.disableGlobalRemovalMode();
          map.pm.disableDraw();
          this.showErrorPopupMessage('Blockout region shapes cannot be modified if there are addresses associated with the blockout region set. Either delete the addresses and try again or create a new blockout region set.');
        }
      }
    },

    updateInfoControl(errorMessage) {
      const errorInfoControl = this.get('errorInfoControl');

      if (Ember.isEmpty(errorMessage)) {
        errorInfoControl.update();
        return;
      }

      errorInfoControl.update(errorMessage);
      Ember.run.later(this, function () {
        errorInfoControl.update();
      }, 2000);
    },

    createMarker(lat, lng, id, fullAddress, type) {
      const map = this.get('map');
      let addressMarker = null;

      if (type === SPEED_ADDRESS_TYPE) {
        addressMarker = window.L.AwesomeMarkers.icon({
          icon: 'plus',
          prefix: 'fa',
          markerColor: 'green'
        });
      } else if (type === SEARCH_PLACE_TYPE) {
        addressMarker = window.L.AwesomeMarkers.icon({
          icon: 'circle',
          prefix: 'fa',
          markerColor: 'red'
        });
      }

      const marker = window.L.marker([lat, lng], {
        pmIgnore: true,
        properties: {
          id: id,
          type: type
        },
        icon: addressMarker
      });

      if (type === SPEED_ADDRESS_TYPE) {
        marker.bindTooltip(fullAddress, {
          permanent: false,
          direction: 'auto',
          className: 'marker-labels'
        });
      } else if (type === SEARCH_PLACE_TYPE) {
        marker.bindTooltip(fullAddress, {
          permanent: false,
          direction: 'auto',
          className: 'marker-labels'
        });
      }

      return marker;
    },

    createPolygon(latlngs, color, speedRegionName, originalName, type) {
      const undoPolygon = this.undoPolygon.bind(this);
      const updateInfoControl = this.updateInfoControl.bind(this);
      const deletePolygon = this.deletePolygon.bind(this);
      const validatePolygon = this.validatePolygon.bind(this);
      const updatePolygon = this.updatePolygon.bind(this);
      const map = this.get('map');
      const self = this;
      const polygon = window.L.polygon([latlngs], {
        color: color,
        properties: {
          originalName: originalName,
          speedRegionName: speedRegionName,
          type: type
        }
      });

      if (type === SPEED_REGION_GROUP_TYPE) {
        polygon.pm.setOptions({
          allowEditing: false,
          allowRemoval: false,
          allowCutting: false,
          allowRotation: false,
          draggable: false
        });
      } else {
        polygon.on('pm:remove', function (e) {
          const layer = e.layer;
          deletePolygon(layer);
          map.pm.disableGlobalRemovalMode();
        });
        polygon.on('pm:markerdragstart', function (e) {
          const layer = e.layer;
          const restorePosition = layer.getLatLngs();
          self.set('restorePosition', restorePosition);
        });
        polygon.on('pm:markerdragend', function (e) {
          const layer = e.layer;

          if (validatePolygon(layer)) {
            updatePolygon(layer);
          } else {
            undoPolygon(layer);
            updateInfoControl('Zone polygon cannot intersect with other zones.');
          }
        });
        polygon.on('pm:dragstart', function (e) {
          const layer = e.layer;
          const restorePosition = layer.getLatLngs();
          self.set('restorePosition', restorePosition);
        });
        polygon.on('pm:dragend', function (e) {
          const layer = e.layer;

          if (validatePolygon(layer)) {
            updatePolygon(layer);
          } else {
            undoPolygon(layer);
            updateInfoControl('Zone polygon cannot intersect with other zones.');
          }
        });
      }

      if (type === SPEED_REGION_GROUP_TYPE) {
        polygon.bindTooltip(speedRegionName, {
          permanent: true,
          direction: 'center',
          className: 'polygon-labels'
        });
      }

      return polygon;
    },

    clearDrawnItems(type) {
      if (type === SPEED_REGION_TYPE) {
        const drawnItems = this.get('drawnItems');
        const polygonLayers = drawnItems.getLayers();
        polygonLayers.forEach(polygonLayer => {
          const currentType = polygonLayer?.options?.properties?.type;

          if (currentType === type) {
            drawnItems.removeLayer(polygonLayer);
          }
        });
        this.set('restorePosition');
      } else if (type === SPEED_REGION_GROUP_TYPE) {
        const map = this.get('map');
        map.eachLayer(layer => {
          const currLayerType = layer?.options?.properties?.type;

          if (currLayerType === SPEED_REGION_GROUP_TYPE) {
            map.removeLayer(layer);
          }
        });
      } else if (type === SPEED_ADDRESS_TYPE) {
        const map = this.get('map');
        map.eachLayer(polygonLayer => {
          const currentType = polygonLayer?.options?.properties?.type;

          if (currentType === type) {
            map.removeLayer(polygonLayer);
          }
        });
      } else if (type === SEARCH_PLACE_TYPE) {
        const map = this.get('map');
        map.eachLayer(polygonLayer => {
          const currentType = polygonLayer?.options?.properties?.type;

          if (currentType === type) {
            map.removeLayer(polygonLayer);
          }
        });
      }
    },

    addInfoControl(map) {
      const info = window.L.control({
        position: 'bottomleft'
      });

      info.onAdd = function () {
        this._div = window.L.DomUtil.create('div', 'speed_region_map_error'); // create a div with a class "info"

        this.update();
        return this._div;
      };

      info.update = function (message) {
        const infoDiv = Ember.$(this._div);

        if (message) {
          this._div.innerHTML = message;
          infoDiv.removeClass('disabled');
        } else {
          this._div.innerHTML = '';
          infoDiv.addClass('disabled');
        }
      };

      info.addTo(map);
      return info;
    },

    convertSpeedRegionToGeoJSON(speedRegion) {
      const lnglat = speedRegion.polygon.map(latlng => {
        return [latlng[1], latlng[0]];
      });
      if (lnglat.length <= 0) return null;

      const speedRegionGeoJson = _turfHelpers.default.polygon([lnglat], {
        speedRegionName: speedRegion.name,
        originalName: speedRegion.originalName
      });

      return speedRegionGeoJson;
    },

    validatePolygon(layer) {
      const newPolygonGeoJson = layer.toGeoJSON();
      const speedRegionGroupRegions = this.get('speedRegionGroup.regions');
      const softDeletedPolygons = this.get('softDeletedPolygons');
      newPolygonGeoJson.properties.speedRegionName = layer.options?.properties?.speedRegionName;
      newPolygonGeoJson.properties.originalName = layer.options?.properties?.originalName; // check other existing zones for intersects

      const intersectRegionPolygon = Ember.isPresent(speedRegionGroupRegions) ? speedRegionGroupRegions.find(region => {
        const currentRegionGeoJson = this.convertSpeedRegionToGeoJSON(region);

        if (Ember.isPresent(currentRegionGeoJson) && !softDeletedPolygons.includes(region.name) && newPolygonGeoJson.properties.originalName !== currentRegionGeoJson.properties.originalName && currentRegionGeoJson.geometry.coordinates[0].length > 1) {
          const diffInPolygon = (0, _geometryDifference.default)(newPolygonGeoJson, currentRegionGeoJson);

          if (Ember.isEmpty(diffInPolygon)) {
            return true;
          }

          return (0, _booleanOverlap.default)(diffInPolygon, newPolygonGeoJson);
        }

        return false;
      }) : null;
      return !Ember.isPresent(intersectRegionPolygon) && !layer.intersects();
    },

    undoPolygon(layer) {
      const restorePosition = this.get('restorePosition');

      if (Ember.isEmpty(restorePosition)) {
        layer.pm.remove();
      } else {
        layer.setLatLngs(restorePosition);
        layer.pm.disable();
        layer.pm.enable();
      }
    },

    // just dimming instead of compltely removing from map
    toggleToolbarButton(state) {
      // toggle button dimming and clickability
      const drawControl = Ember.$('.leaflet-buttons-control-button');

      if (Ember.isPresent(drawControl)) {
        if (state) {
          // enable toolbar control
          drawControl.removeClass('draw-control-disabled');
        } else {
          // disable toolbar control
          drawControl.addClass('draw-control-disabled');
        }
      }
    },

    // just dimming instead of compltely removing from map
    toggleIndividualToolbarButtons() {
      const layers = this.get('drawnItems._layers');
      const createControl = Ember.$('.leaflet-pm-draw');

      if (Ember.isPresent(layers) && Ember.isPresent(createControl)) {
        if (Object.keys(layers).length >= MAX_NUMBER_POLYGON) {
          // disable toolbar control
          createControl.addClass('draw-control-disabled');
        } else {
          // enable toolbar control
          createControl.removeClass('draw-control-disabled');
        }
      }

      const editControl = Ember.$('.leaflet-pm-edit');

      if (Ember.isPresent(layers) && Ember.isPresent(editControl)) {
        if (Object.keys(layers).length === 0) {
          // disable toolbar control
          editControl.addClass('draw-control-disabled');
        } else {
          // enable toolbar control
          editControl.removeClass('draw-control-disabled');
        }
      }
    },

    // isEditMode is triggered from main screen
    isEditModeChange: Ember.observer('isEditMode', function () {
      const isEditMode = this.get('isEditMode');
      const map = this.get('map');
      this.toggleToolbarButton(isEditMode);
      this.set('softDeletedPolygons', []);

      if (!isEditMode) {
        const speedRegionGroupRegions = this.get('speedRegionGroup.regions') || [];
        this.updateSpeedRegionGroupPolygons(speedRegionGroupRegions);
        map.pm.disableGlobalRemovalMode();
        map.pm.disableGlobalEditMode();
      }
    }),
    searchPlaceChange: Ember.observer('searchPlace.location.lat', 'searchPlace.location.lng', function () {
      const map = this.get('map');
      const searchPlace = this.get('searchPlace');
      const address = searchPlace.get('address');
      const location = searchPlace.get('location');
      const drawnItems = this.get('drawnItems');

      if (Ember.isPresent(drawnItems)) {
        this.clearDrawnItems(SEARCH_PLACE_TYPE);
      }

      if (Ember.isPresent(location)) {
        const lat = location.get('lat');
        const lng = location.get('lng');

        if (Ember.isPresent(lat) && Ember.isPresent(lng)) {
          const lmarker = this.createMarker(lat, lng, address.get('id'), address.get('fullAddress'), SEARCH_PLACE_TYPE);
          lmarker.addTo(map);
        }
      }
    }),
    speedAddressChange: Ember.observer('speedAddress', function () {
      const map = this.get('map');
      const speedAddress = this.get('speedAddress');
      const drawnItems = this.get('drawnItems');

      if (Ember.isPresent(drawnItems)) {
        this.clearDrawnItems(SPEED_ADDRESS_TYPE);
      }

      if (Ember.isPresent(speedAddress)) {
        const lat = speedAddress.get('lat');
        const lng = speedAddress.get('lng');

        if (Ember.isPresent(lat) && Ember.isPresent(lng)) {
          const lmarker = this.createMarker(lat, lng, speedAddress.get('id'), speedAddress.get('fullAddress'), SPEED_ADDRESS_TYPE);
          lmarker.addTo(map);
        }
      }
    }),
    speedRegionChange: Ember.observer('speedRegion', function () {
      const speedRegion = this.get('speedRegion');
      const drawnItems = this.get('drawnItems');

      if (Ember.isPresent(drawnItems)) {
        this.clearDrawnItems(SPEED_REGION_TYPE);
      }

      if (Ember.isPresent(speedRegion)) {
        const polygon = speedRegion.get('polygon') ? speedRegion.get('polygon') : [];

        if (!Ember.isEmpty(polygon)) {
          const lpolygon = this.createPolygon(polygon, 'blue', speedRegion.get('name'), speedRegion.get('originalName'), SPEED_REGION_TYPE);
          lpolygon.addTo(drawnItems);
        }

        this.toggleIndividualToolbarButtons();
      }
    }),
    speedRegionGroupChange: Ember.observer('speedRegionGroup', function () {
      const speedRegionGroupRegions = this.get('speedRegionGroup.regions');
      const drawnItems = this.get('drawnItems');

      if (Ember.isPresent(drawnItems)) {
        this.clearDrawnItems(SPEED_REGION_GROUP_TYPE);
      }

      if (Ember.isEmpty(speedRegionGroupRegions)) return;
      this.updateSpeedRegionGroupPolygons(speedRegionGroupRegions);
    }),

    createNewPolygon(polygon) {
      const speedRegion = this.get('speedRegion');
      const drawnItems = this.get('drawnItems');
      if (Ember.isEmpty(speedRegion)) return;
      this.onSpeedRegionGroupFormChanges();
      const newPolygon = this.createPolygon(polygon, 'blue', speedRegion.get('name'), speedRegion.get('originalName'), SPEED_REGION_TYPE);
      newPolygon.addTo(drawnItems);
      speedRegion.set('polygon', polygon);
      this.updateSpeedRegionGroup(speedRegion);
      this.toggleIndividualToolbarButtons();
    },

    updateSpeedRegionGroup(updateSpeedRegion) {
      const speedRegionGroupRegions = _lodash.default.cloneDeep(this.get('speedRegionGroup.regions'));

      const speedRegion = speedRegionGroupRegions.find(region => region.originalName === updateSpeedRegion.originalName);

      if (Ember.isPresent(speedRegion)) {
        speedRegion.polygon = updateSpeedRegion.polygon;
        this.set('speedRegionGroup.regions', speedRegionGroupRegions);
      }

      this.updateSpeedRegionGroupPolygons(speedRegionGroupRegions);
    },

    updatePolygon(layer) {
      const speedRegion = this.get('speedRegion');

      if (Ember.isPresent(speedRegion)) {
        const geoJSON = layer.toGeoJSON();
        this.onSpeedRegionGroupFormChanges();
        this.updateSpeedRegion(speedRegion, geoJSON.geometry.coordinates);
        this.updateSpeedRegionGroup(speedRegion);
      }
    },

    deletePolygon(layer) {
      const speedRegion = this.get('speedRegion');
      const drawnItems = this.get('drawnItems');

      if (Ember.isPresent(speedRegion)) {
        this.onSpeedRegionGroupFormChanges();
        this.updateSpeedRegion(speedRegion, [[]]);
        drawnItems.removeLayer(layer);
        this.flagRegionPolygonDeleted(speedRegion);
        this.updateSpeedRegionGroup(speedRegion);
        this.toggleIndividualToolbarButtons();
      }
    },

    flagRegionPolygonDeleted(speedRegion) {
      const softDeletedPolygons = this.get('softDeletedPolygons');

      if (Ember.isPresent(speedRegion)) {
        softDeletedPolygons.push(speedRegion.get('name'));
      }
    },

    updateSpeedRegion(speedRegion, latlngs) {
      const lpolygon = latlngs[0];
      const polygon = lpolygon.map(point => {
        const newPoint = [point[1], point[0]];
        return newPoint;
      });
      speedRegion.set('polygon', polygon);
    },

    updateSpeedRegionGroupPolygons(regions) {
      const map = this.get('map');
      this.clearDrawnItems(SPEED_REGION_GROUP_TYPE);
      regions.forEach(region => {
        const polygon = region.polygon;

        if (!Ember.isEmpty(polygon)) {
          const polygons = this.createPolygon([polygon], 'grey', region.name, region.originalName, SPEED_REGION_GROUP_TYPE);
          polygons.addTo(map);
        }
      });
      this.bringCurrentRegionToFront();
    },

    bringCurrentRegionToFront() {
      const drawnItems = this.get('drawnItems');
      const polygonLayers = drawnItems.getLayers();
      polygonLayers.forEach(layer => {
        const type = layer?.options?.properties?.type;

        if (type !== SPEED_ADDRESS_TYPE) {
          layer.bringToFront();
        }
      });
    },

    destroy() {
      this._super(...arguments);

      Ember.$('html,body,.tomtom-map,#speed-region').removeClass('custom-cursor');
    }

  });

  _exports.default = _default;
});