define("adept-iq/adapters/continous-opt-route-break", ["exports", "adept-iq/config/api-urls", "adept-iq/adapters/-cos-continuousOptService", "ember-concurrency"], function (_exports, _apiUrls, _cosContinuousOptService, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TIMEOUT_DELAY = 1000; // 1 second delay

  const MAX_RETRIES = 180;

  var _default = _cosContinuousOptService.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    continuousOptHost: _apiUrls.API.continuousOptService.host,

    postAddBreak(attributes) {
      let async = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const session = this.get('session');
      const url = async ? `${this.host}/cos-async-operation` : `${this.continuousOptHost}/optimizer/add-break`;
      const json = async ? {
        data: {
          type: 'cosAsyncOperation',
          attributes: {
            operationType: 'addBreak',
            entityType: 'route',
            entityId: [attributes.destinationRouteId],
            ...attributes
          }
        }
      } : {
        data: {
          type: 'addBreak',
          attributes
        }
      };
      return this.get('ajaxService').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    createAddBreakAsyncOperation: (0, _emberConcurrency.task)(function* (attributes) {
      const job = yield this.postAddBreak(attributes, true);
      return yield this.get('asyncAddBreakOperationState').perform(job);
    }).enqueue(),
    asyncAddBreakOperationState: (0, _emberConcurrency.task)(function* (job) {
      const session = this.get('session');
      const url = `${this.host}/cos-async-operation/`;
      let count = 0;

      while (job.data.attributes.state !== 'success' && job.data.attributes.state !== 'failure' && job.data.attributes.state !== 'rejected' && count < MAX_RETRIES) {
        yield (0, _emberConcurrency.timeout)(TIMEOUT_DELAY);
        count++;

        try {
          // eslint-disable-next-line no-param-reassign
          job = yield this.get('ajax').request(url + job.data.id, {
            method: 'GET',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          });
        } catch (e) {
          throw e;
        }
      }

      const isJobSuccess = job.data.attributes.state === 'success';

      if (isJobSuccess) {
        const results = JSON.parse(job.data.attributes.results);
        return { ...results,
          isJobSuccess
        };
      }

      return {
        payload: {
          // if it is 'rejected' then there is no results.
          message: job.data.attributes.results ? `${job.data.attributes.results}` : `${job.data.attributes.state}`
        },
        isJobSuccess
      };
    }).drop()
  });

  _exports.default = _default;
});