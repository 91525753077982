define("adept-iq/pods/components/generic-components/table/columns/sort-capable/component", ["exports", "adept-iq/pods/components/generic-components/table/columns/base-column/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['table-filter-capable-column']
  });

  _exports.default = _default;
});