define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/vehicle-type/table/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    title: 'Vehicle Type',
    defaultSortId: 'description',
    modelName: 'vehicle-type',
    defaultSortAsc: true,
    queryModelData: true,
    selectFirstRow: true,
    tooltipTitle: 'Manage Lookup',
    columns: [{
      id: 'displayName',
      index: 0,
      type: 'text',
      label: 'Display Name',
      valuePath: 'displayName',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'description',
      index: 1,
      type: 'text',
      label: 'Description',
      valuePath: 'description',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'inServiceDate',
      index: 3,
      type: 'date',
      label: 'In Service Date',
      valuePath: 'inServiceDate',
      defaultWidth: 200,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }, {
      id: 'endServiceDate',
      index: 4,
      type: 'date',
      label: 'End Service Date',
      valuePath: 'endServiceDate',
      defaultWidth: 200,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }, {
      id: 'vehicleModelName',
      index: 5,
      type: 'text',
      label: 'Model',
      valuePath: 'vehicleModel.description',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'vehicleBodyType',
      index: 6,
      type: 'text',
      label: 'Body',
      valuePath: 'vehicleBodyType.description',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'vehicleBodyWidth',
      index: 7,
      type: 'text',
      label: 'Body Width',
      valuePath: 'vehicleBodyWidth.displayName',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'vehicleBodyLength',
      index: 8,
      type: 'text',
      label: 'Body Length',
      valuePath: 'vehicleBodyLength',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'vehicleRearAxel',
      index: 9,
      type: 'text',
      label: 'Rear Axle',
      valuePath: 'rearAxel.description',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'vehicleWheelBaseType',
      index: 10,
      type: 'text',
      label: 'Wheelbase',
      valuePath: 'vehicleWheelBaseType.description',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'vehicleTireSize',
      index: 11,
      type: 'text',
      label: 'Tire Size',
      valuePath: 'vehicleTireSize.description',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'vehicleChassis',
      index: 12,
      type: 'text',
      label: 'Chassis',
      valuePath: 'vehicleChassis.description',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'vehicleFuelType',
      index: 13,
      type: 'text',
      label: 'Fuel Type',
      valuePath: 'vehicleFuelType.description',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'vehicleFuelCap',
      index: 14,
      type: 'text',
      label: 'Fuel Cap',
      valuePath: 'vehicleFuelCap.description',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'vehicleTransmission',
      index: 15,
      type: 'text',
      label: 'Transmission',
      valuePath: 'vehicleTransmission.description',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'capacityConfig',
      index: 16,
      type: 'enum',
      label: 'Capacity Config Group',
      valuePath: 'capacityConfigGroup',
      mapValuePath: 'capacityConfigGroupSortKey',
      defaultWidth: 200,
      searchable: true,
      cellDesc: 'Groups'
    }]
  };
  _exports.default = _default;
});