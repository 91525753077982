define("adept-iq/services/fare-calc", ["exports", "adept-iq/config/api-urls", "lodash"], function (_exports, _apiUrls, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COMPANION = 'companion';

  var _default = Ember.Service.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    booking: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    async calcFareByRiderIdAndFareCategory(riderId, fareCategory) {
      let companionCount = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      const json = {
        clientId: riderId
      };

      if (fareCategory) {
        json.fareCategory = fareCategory;
      }

      let fare = 0;

      try {
        const response = await this.get('ajaxService').post(_apiUrls.API.fareCalcService.host + '/calculate-fare/', {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
          },
          data: json
        });

        const fareObj = _lodash.default.find(response, obj => obj.companionCount === companionCount, {
          fare: 0
        });

        if (!fareObj) throw Error(`Unable to fetch fare for companion count ${companionCount}`);
        fare = fareObj.fare;
      } catch (e) {
        this.get('notifications').warning(`CALCULATE FARE FAILED. ${e.message}`);
      }

      return fare;
    },

    // TODO: send fareCategory to API
    async calcFareByRiderIdAndTravelNeeds(rider) {
      let travelNeedsRecords = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      let override = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let fareCategory = arguments.length > 3 ? arguments[3] : undefined;
      const riderRec = this.store.peekRecord('rider', rider.id);
      const riderTravelNeeds = riderRec.get('travelNeeds');
      const travelNeeds = travelNeedsRecords.filter(record => record.get('travelNeedTypeName') || record.get('travelNeedType.name')).map(record => {
        return {
          name: record.get('travelNeedTypeName') || record.get('travelNeedType.name'),
          count: parseInt(record.get('count'), 10),
          fareCalculationType: this.get('booking.getFareCalculationType')(record)
        };
      });

      const companionTravelNeeds = _lodash.default.filter(travelNeeds, travelNeed => travelNeed.fareCalculationType === COMPANION);

      const legCompanionCnt = companionTravelNeeds.length <= 0 ? 0 : companionTravelNeeds.reduce((n, _ref) => {
        let {
          count
        } = _ref;
        return n + count;
      }, 0); // check if leg companionTravelNeed is for this rider

      const riderCompanionNeeds = _lodash.default.filter(riderTravelNeeds, travelNeed => travelNeed.fareCalculationType === COMPANION);

      const riderCompanionCnt = riderCompanionNeeds.length <= 0 ? 0 : riderCompanionNeeds.reduce((n, _ref2) => {
        let {
          count
        } = _ref2;
        return n + count;
      }, 0); // the following logic works for:
      // * initial leg creation (single booking and group booking)
      // * travel need section companion override by decreasing companion count (single booking only)
      // IT CURRENTLY DOES NOT WORK FOR GROUP BOOKING COMPANION OVERRIDE,

      let companionCnt = !override ? Math.min(legCompanionCnt, riderCompanionCnt) : legCompanionCnt;

      if ((0, _lodash.isNaN)(companionCnt)) {
        companionCnt = 0;
      }

      const fare = await this.calcFareByRiderIdAndFareCategory(rider.get('riderId'), fareCategory, companionCnt);
      return fare;
    }

  });

  _exports.default = _default;
});