define("adept-iq/pods/components/iq-widgets/reportlist-widget/hyperlink-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q+yfYsYW",
    "block": "{\"symbols\":[],\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"hyperlink-button\"],[11,\"style\",\"width:auto !important\"],[11,\"type\",\"button\"],[9],[1,[21,\"value\"],false],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/reportlist-widget/hyperlink-cell/template.hbs"
    }
  });

  _exports.default = _default;
});