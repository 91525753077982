define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/driver-providers/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'driver-provider',
    title: 'Providers',
    defaultSortId: 'id',
    defaultSortAsc: true,
    selectFirstRow: true,
    queryModelData: true,
    tooltipTitle: 'Manage Driver',
    columns: [{
      id: 'siteID',
      index: 1,
      type: 'text',
      label: 'Site ID',
      valuePath: 'siteId',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'provider',
      index: 2,
      type: 'text',
      label: 'Provider',
      valuePath: 'provider.name',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'position',
      index: 3,
      type: 'text',
      label: 'Position',
      valuePath: 'driverPositionName.displayName',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'driverEmploymentStatus',
      index: 4,
      type: 'text',
      label: 'Driver Employment Status',
      valuePath: 'employmentStatusName.displayName',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'startDate',
      index: 5,
      type: 'date',
      label: 'Start Date',
      valuePath: 'employmentStartDate',
      defaultWidth: 70,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }, {
      id: 'endDate',
      index: 6,
      type: 'date',
      label: 'End Date',
      valuePath: 'employmentEndDate',
      defaultWidth: 100,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }, {
      id: 'terminationReason',
      index: 7,
      type: 'text',
      label: 'Termination Reason',
      valuePath: 'terminationReasonName.description',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'classroomTrainingDate',
      index: 8,
      type: 'date',
      label: 'Classroom Training Date',
      valuePath: 'classroomTrainingDate',
      defaultWidth: 100,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }, {
      id: 'refresherTrainingDate',
      index: 9,
      type: 'date',
      label: 'Refresher Training Date',
      valuePath: 'refresherTrainingDate',
      defaultWidth: 100,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }, {
      id: 'pointsReductionTrainingDate',
      index: 10,
      type: 'date',
      label: 'Points Reduction Training Date',
      valuePath: 'pointsReductionTraining',
      defaultWidth: 100,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }, {
      id: 'sensitivityTrainingDate',
      index: 11,
      type: 'date',
      label: 'Sensitivity Training Date',
      valuePath: 'sensitivityTrainingDate',
      defaultWidth: 100,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }, {
      id: 'firstAidTrainingDate',
      index: 12,
      type: 'date',
      label: 'First Aid Training Date',
      valuePath: 'firstAidTrainingDate',
      defaultWidth: 100,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }]
  };
  _exports.default = _default;
});