define("adept-iq/config/config-value-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // config value validation
  var _default = {
    'config-Route_parameters/routeMaxTimeLimit': {
      validation: {
        type: 'int',
        min: 0,
        max: 9999,
        errorMessage: 'should have a value of 0 to 9999'
      }
    }
  };
  _exports.default = _default;
});