define("adept-iq/models/route-calendar", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    monday: attr('string'),
    tuesday: attr('string'),
    wednesday: attr('string'),
    thursday: attr('string'),
    friday: attr('string'),
    saturday: attr('string'),
    sunday: attr('string'),
    startDate: attr(),
    endDate: attr('string'),
    routeShapes: hasMany('route-shape'),
    service: belongsTo('agency-service')
  });

  _exports.default = _default;
});