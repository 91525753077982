define("adept-iq/tests/factories/vehicle-capacity-config", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const vehicleCounts = [100, 200, 40, 15, 5];
  const vehicleCategories = ['Revenge', 'DeadReckoning', 'Bus', 'Taxi', 'Car'];

  _emberDataFactoryGuy.default.define('vehicle-capacity-config', {
    sequences: {
      vehicleTypeNames: num => vehicleCategories[num % vehicleCategories.length],
      counts: num => vehicleCounts[num % vehicleCounts.length]
    },
    default: {
      count: _emberDataFactoryGuy.default.generate('counts'),
      name: _emberDataFactoryGuy.default.generate('vehicleTypeNames'),
      group: _emberDataFactoryGuy.default.generate(i => i)
    }
  });
});