define("adept-iq/pods/components/side-drawer/no-show-form/enum/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-select'],
    value: null,
    disabled: false,
    extra: null,
    store: Ember.inject.service(),
    dataFromModel: false,
    options: Ember.computed.alias('extra.options'),
    allowMultiple: Ember.computed.alias('extra.allowMultiple'),
    optionModelName: Ember.computed.alias('extra.optionModelName'),

    init() {
      this._super(...arguments);

      const models = this.get('store').peekAll(this.get('optionModelName'));
      this.set('dataFromModel', true);
      this.set('options', models);
    },

    selected: Ember.computed('value', 'options', function () {
      return this.get('options').find(option => {
        return option.get('name') === this.get('value.name');
      });
    }),
    actions: {
      onChange(option) {
        this.get('onChange')(option);
      }

    }
  });

  _exports.default = _default;
});