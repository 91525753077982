define("adept-iq/adapters/-dwh-dwhService", ["exports", "adept-iq/adapters/application", "adept-iq/config/api-urls"], function (_exports, _application, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    defaultSerializer: '-dwh-dwhService',
    host: _apiUrls.API.dwhService.host,
    pathForType: function (type) {
      // trim 'sso' prefix from model type
      const regEx = /^(?:dwh-)?(.*)$/;
      const match = regEx.exec(type)[1];
      return this._super(match);
    },

    query(store, type, query) {
      const session = this.get('session');
      const apiUrl = this.buildURL(type.modelName, null, null);
      return this.get('ajaxService').request(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json',
        data: query.data
      }).then(res => {
        if (res.data && Array.isArray(res.data) && res.data.length > 0) {
          res.data.forEach(payload => {
            payload.type = type.modelName;
            payload.attributes = { ...payload.attributes,
              provider: payload.provider
            };
            delete payload.provider;
          });
        }

        return res;
      });
    }

  });

  _exports.default = _default;
});