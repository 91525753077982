define("adept-iq/pods/components/iq-widgets/box-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/chartjs-widget/component", "adept-iq/utils/filters", "adept-iq/pods/components/generic-widgets/column-widget/classes/filter-column"], function (_exports, _component, _filters, _filterColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['analytics-widget', 'box-widget'],
    classNameBindings: ['stateClass', 'widthConstrained'],
    workspaceContext: Ember.inject.service(),
    widget: null,
    options: Ember.computed.alias('widget.state.dataType'),

    /* Applies the choice of data source. */
    chartRecords: Ember.computed('options.modelName', 'workspaceContext.workspaceData.[]', function () {
      const modelName = this.get('options.modelName');
      if (Ember.isBlank(modelName)) return [];
      const wrappedRecords = this.get('workspaceContext.workspaceData').filterBy('modelName', modelName) || [];
      return wrappedRecords.map(a => a.record);
    }),

    /* Builds the chosen filters. */
    filterFunction: Ember.computed('options.columns', function () {
      const filterColumns = Object.values(this.get('options.columns')).map(col => new _filterColumn.default(col));
      const args = filterColumns.map(column => {
        return column.get('filterNode');
      });
      return (0, _filters.buildFilterFunction)((0, _filters.buildCompoundFilterNode)('and', args));
    }),

    /* Applies the choice of filters. */
    filteredRecords: Ember.computed('chartRecords', 'filterFunction', function () {
      const chartRecords = this.get('chartRecords') || [];
      return chartRecords.filter(this.get('filterFunction'));
    }),

    /* Applies the choices of fields and metrics. */
    boxMetric: Ember.computed('options.displayOptions.selected', 'filteredRecords', function () {
      const displaySelection = this.get('options.displayOptions.selected');
      if (Ember.isBlank(displaySelection) || Ember.isBlank(displaySelection.valueKey) || Ember.isBlank(displaySelection.valueCategory) || Ember.isBlank(displaySelection.metricOption)) return;
      const slicedRecords = this.get('filteredRecords').filterBy(displaySelection.valueKey, displaySelection.valueCategory.value);
      const value = this.processRecords(slicedRecords, displaySelection.metricOption.id);
      const name = displaySelection.label + ' - ' + displaySelection.valueCategory.label;
      /*eslint-disable */

      return {
        name,
        value
      };
      /*eslint-enable */
    }),
    stateClass: Ember.computed('boxMetrics', 'options.boxColor.{orange,red}', function () {
      if (Ember.isBlank(this.get('options'))) return;
      const value = this.get('boxMetric.value');
      /*eslint-disable */

      if (value >= this.get('options.boxColor.red')) {
        return 'red-state';
      } else if (value >= this.get('options.boxColor.orange')) {
        return 'orange-state';
      } else {
        return;
      }
      /*eslint-enable */

    }),
    widthConstrained: Ember.computed('widget.width', function () {
      if (this.get('widget.width') === 1) {
        return 'width-constrained';
      }

      return null;
    }),

    /* Applies the chart title input. */
    chartTitle: Ember.computed('widget.state.dataType.{name,title}', function () {
      const title = this.get('widget.state.dataType.title');
      if (Ember.isPresent(title)) return title;
      const name = this.get('widget.state.dataType.name');

      if (Ember.isPresent(name)) {
        return `${name} Box Widget`;
      }

      return 'Box Widget';
    }),

    buildColumnFilter() {
      const dataColumns = this.get('options.dataColumns') || [];
      const args = dataColumns.map(column => {
        return column.get('filterNode');
      });
      return (0, _filters.buildCompoundFilterNode)('and', args);
    },

    processRecords(records, metricOption
    /*, valueKey */
    ) {
      switch (metricOption) {
        case 'count':
          if (records) return records.length;
          break;

        default:
          return 0;
      }
    },

    actions: {
      onFilterButtonClick() {
        const workspace = this.get('workspace');
        let topState = workspace.get('topState');

        while (topState === 'boxWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        const displayName = 'Box Analytics';
        const widget = this.get('widget');
        workspace.pushState('boxWidget', {
          displayName,
          widget
        });
      }

    }
  });

  _exports.default = _default;
});