define("adept-iq/pods/components/side-drawer/add-break/component", ["exports", "ember-light-table", "adept-iq/utils/unwrapProxy", "moment", "ember-concurrency", "lodash", "adept-iq/pods/components/side-drawer/add-break/config", "adept-iq/utils/save-records", "adept-iq/models/break-type", "adept-iq/classes/impact-stop", "adept-iq/mixins/version-check", "adept-iq/config/environment"], function (_exports, _emberLightTable, _unwrapProxy, _moment, _emberConcurrency, _lodash, _config, _saveRecords, _breakType, _impactStop, _versionCheck, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_versionCheck.default, {
    classNames: ['add-break-side-drawer'],
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    coreEntityPopulator: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    canSave: false,
    onShrinkClick: null,
    onCloseClick: null,
    onSolutionRowClick: null,
    usePlaceHolderTask: true,
    dispatchSchedule: null,
    stopsTable: null,
    currentState: null,
    dateTimeFormat: _environment.default.dateTimeFormat,
    timeFormat: _environment.default.dateTimeFormat.dateTimeFlatPickr,
    selectedImpactPeriod: 'before',
    rowComponent: 'table/rows/colored-row',
    driverBreak: Ember.computed.readOnly('stackFrame.options.driverBreak'),
    trips: Ember.computed.readOnly('stackFrame.options.trips'),
    saveRecordFunction: Ember.computed.readOnly('stackFrame.options.saveDriverBreakFunction'),
    minDate: null,
    isError: Ember.computed.alias('getAddBreakRecords.last.isError'),
    isFloatingBreakType: Ember.computed.equal('driverBreak.breakType.id', _breakType.FLOATING_BREAK),
    isFixedBreakType: Ember.computed.equal('driverBreak.breakType.id', _breakType.FIXED_BREAK),
    isAfterTable: Ember.computed.equal('selectedImpactPeriod', 'after'),
    componentName: 'addBreak',
    requestTime: null,
    value: null,
    addressOptions: null,
    breakTypes: null,
    errorText: null,
    selectedBreakTypeId: null,
    collapseState: 'collapsed',
    viewImpactButton: false,
    disabled: false,
    invalidRequestTime: false,
    errorClass: false,
    fields: _config.fields,
    isViewImpactButton: Ember.computed('driverBreak.{breakType,place,requestTime,plannedDuration}', function () {
      if (Ember.isEmpty(this.get('driverBreak.breakType')) || Ember.isEmpty(this.get('driverBreak.plannedDuration')) || Ember.isEmpty(this.get('driverBreak.requestTime')) || Ember.isEmpty(this.get('driverBreak.place.address'))) {
        return false;
      }

      return true;
    }),
    projectedEndDate: Ember.computed('driverBreak.{plannedDuration,requestTime}', function () {
      const plannedDuration = this.get('driverBreak.plannedDuration') ? this.get('driverBreak.plannedDuration') : '0';
      const requestTime = this.get('driverBreak.requestTime');
      if (!requestTime) return;
      const requestTimeMoment = (0, _moment.default)(requestTime);
      const estimatedEndMoment = requestTimeMoment.clone();
      return estimatedEndMoment.add(parseInt(plannedDuration, 10), 'minutes').toDate();
    }),
    observeSelectImpactPeriod: Ember.observer('selectedImpactPeriod', function () {
      const period = this.get('selectedImpactPeriod'); // TODO: Can the table rows be updated without an observer?

      if (period === 'before') {
        this.setBeforeTableRecords();
        return false;
      }

      this.get('stopsTable').setRows(this.get(`impact.${period}.stops`));
    }),
    fieldChangeObserver: Ember.observer('driverBreak.{breakType,place,requestTime,plannedDuration}', function () {
      this.validateRecords();
      this.set('collapseState', 'collapsed');
    }),

    init() {
      this._super(...arguments);

      const stopsTable = new _emberLightTable.default(_config.stopColumns);
      const breakTypes = this.get('store').peekAll('break-type');
      this.set('minDate', this.get('driverBreak.route.computedPlannedStartTime')); // eslint-disable-next-line new-cap

      this.set('solutions', Ember.A([]));
      this.set('stopsTable', stopsTable);
      this.set('driverBreak.notes', '');
      this.set('breakTypes', breakTypes);
      this.setBeforeTableRecords();
      this.setDefaultDriverBreak();
      this.initialValidation();
      this.availableBreakSlots(this.get('driverBreak.route.dispatchRoute'));
    },

    initialValidation() {
      const requestTime = this.get('driverBreak.requestTime');
      const duration = parseInt(this.get('driverBreak.plannedDuration'), 10);

      if (duration && duration !== 'NaN' && !Ember.isNone(requestTime)) {
        this.set('canSave', true);
      }
    },

    //set floatingBreak as a default driverrbreak
    setDefaultDriverBreak() {
      const breakTypes = this.get('breakTypes');
      const floatingBreak = breakTypes.find(breakType => breakType.id === _breakType.FLOATING_BREAK);
      this.onbreakTypeChange(floatingBreak);
    },

    didInsertElement() {
      this.loadCurrentState(); // side panel width for add-break

      this.setSideDrawerWidth('600px');
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    loadCurrentState() {
      const currentState = this.get('currentState');
      const currentDate = new Date();

      if (currentState) {
        const savePlace = currentState.savePlace;
        this.set('selectedBreakTypeId', currentState.selectedBreakTypeId);
        this.set('driverBreak.breakType', currentState.selectedBreakType);
        this.set('driverBreak.requestTime', currentDate.toISOString());
        this.set('driverBreak.plannedDuration', currentState.duration);
        this.set('driverBreak.place.address.alias', savePlace.address.premise);
        this.set('driverBreak.place.address.premise', savePlace.address.alias);
        this.set('driverBreak.place.address.streetNumber', savePlace.address.streetNumber);
        this.set('driverBreak.place.address.streetAddress', savePlace.address.streetAddress);
        this.set('driverBreak.place.address.locality', savePlace.address.locality);
        this.set('driverBreak.place.address.subRegion', savePlace.address.subRegion);
        this.set('driverBreak.place.address.postalCode', savePlace.address.postalCode);
        this.set('driverBreak.place.address.country', savePlace.address.country);
        this.set('driverBreak.place.address.county', savePlace.address.county);
        this.set('driverBreak.place.address.freeformAddress', savePlace.address.freeformAddress);
        this.set('driverBreak.place.location.lat', savePlace.location.lat);
        this.set('driverBreak.place.location.lng', savePlace.location.lng);
        this.set('driverBreak.place.location.geoNode', savePlace.location.geoNode);
        this.set('driverBreak.place.location.lat', savePlace.location.lat);
      }
    },

    setBeforeTableRecords() {
      const store = this.get('store');
      const currentRouteStops = this.get('driverBreak.route.dispatchRoute.orderedStopsWithBreaks') || [];
      const stopPoints = currentRouteStops.map(stop => {
        const stopId = stop.id.replace(/(B)/img, '');
        const stopPoint = store.peekRecord('stop-point', stopId);

        if (!stopPoint) {
          // eslint-disable-next-line no-console
          console.log('Is the record Empty');
        }

        return _impactStop.default.create({
          id: stop.id,
          eta: stopPoint.get('eta'),
          otp: stop.get('otp'),
          promiseTime: stop.get('promisedTime'),
          otpValue: stop.get('otpValue'),
          polyline: null,
          mrt: null,
          routeOrdinal: stopPoint.get('routeOrdinal'),
          stopPoint: stopPoint
        });
      }) || [];
      this.get('stopsTable').setRows(stopPoints);
    },

    getRouteImpact(solutions) {
      const store = this.get('store');
      const currentSolution = solutions || {};
      const currentStops = solutions.stops || [];
      let routeOrdinal = 1; // @TODO we should refactor to make it more flexible for map context

      const impact = Ember.Object.create({
        routeOtp: currentSolution.otp,
        otpDelta: currentSolution.otp.deltaValue,
        after: {
          otp: currentSolution.otp.newStatus,
          stops: currentStops.map(stop => {
            const stopId = stop.id;
            let stopPoint = null;
            let isNew = false;

            if (stopId.includes('B')) {
              const id = stop.id.replace(/(B)/img, '');
              const record = store.peekRecord('route-break', id) || (0, _unwrapProxy.unwrapProxy)(this.get('driverBreak.stopPoint'));
              isNew = record.isNew;
              stopPoint = record.get('stopPoint') || record;
              stopPoint.set('stopType', 'break');
            } else {
              stopPoint = store.peekRecord('stop-point', stopId) || (0, _unwrapProxy.unwrapProxy)(this.get('driverBreak.stopPoint'));
            }

            return _impactStop.default.create({
              id: stop.id,
              eta: stop.eta.newValue,
              otp: stop.otp.newStatus,
              otpValue: stop.otp,
              mrt: stop.otp.delta,
              promiseTime: stop.promiseTime,
              polyline: stop.polyline,
              routeOrdinal: routeOrdinal++,
              stopPoint,
              coloredRow: isNew
            });
          })
        }
      });
      return impact;
    },

    getAddBreakRecords: (0, _emberConcurrency.task)(function* () {
      const continousOptRouteBreakAdapter = this.get('store').adapterFor('continous-opt-route-break');
      const dispatchScheduleId = this.get('driverBreak.route.dispatchRoute.dispatchSchedule.id');
      const destinationRouteId = this.get('driverBreak.route.id');
      const plannedDuration = this.get('driverBreak.plannedDurationSeconds'); //Payload request is passed in seconds

      const breakType = this.get('driverBreak.breakType.id');
      const requestTime = this.get('driverBreak.requestTime');
      let breakStop = {};

      if (breakType === _breakType.FIXED_BREAK) {
        const position = this.get('driverBreak.place.address.position');
        const geoNode = this.get('driverBreak.place.address.places.firstObject.location.geoNode');
        breakStop = {
          xyCoord: {
            x: position.lat,
            y: position.lng
          },
          geoNode
        };
      }

      const payload = {
        dispatchScheduleId,
        destinationRouteId,
        break: {
          requestTime,
          plannedDuration,
          breakStop
        }
      };

      if (breakType === _breakType.FLOATING_BREAK) {
        delete payload.break.breakStop;
      } // reset stops table data


      this.set('stopsTable.rows', []);
      const {
        data: {
          attributes: {
            destinationRoutes: solutions
          }
        }
      } = yield continousOptRouteBreakAdapter.createAddBreakAsyncOperation.perform(payload);
      return solutions;
    }).restartable(),
    addBreak: function () {
      const valid = this.validateRecords();

      if (valid) {
        const period = 'after';
        this.set('selectedImpactPeriod', period);
        this.get('getAddBreakRecords').perform().then(solutions => {
          if (!Ember.isEmpty(solutions)) {
            const impact = this.getRouteImpact(solutions[0]);
            this.set('impact', impact);
            this.get('stopsTable').setRows(impact[period].stops);
          }
        }).catch(() => {
          this.set('errorText', 'Cannot add break when riders are onboard.');
        });
      }
    },

    validateRecords() {
      let errorText = '';
      const record = this.get('driverBreak') || [];
      this.set('errorText', errorText);
      const routeBreakType = this.get('selectedBreakTypeId');
      const route = (0, _unwrapProxy.unwrapProxy)(record.get('route'));
      const estimatedStartMoment = (0, _moment.default)(record.get('requestTime'));
      const estimatedEndMoment = (0, _moment.default)(record.get('projectedEndDate'));
      const routePlannedStartTime = (0, _moment.default)(route.get('computedPlannedStartTime'));
      const routeOrdinal = (0, _unwrapProxy.unwrapProxy)(route.get('dispatchRoute')).findNewBreakRoutePosition(record);
      const routeMaxParam = this.get('store').peekRecord('cs-config-item', 'config-Route_parameters/routeMaxExtension');
      const routeMaxExtension = routeMaxParam ? parseInt(routeMaxParam.get('value'), 10) : 60; // minutes

      const plannedDuration = parseInt(record.get('plannedDuration'), 10);
      this.availableBreakSlots(this.get('driverBreak.route.dispatchRoute'));

      if (routeOrdinal === -1) {
        errorText = 'Cannot add break when riders are onboard.';
      } else if (routeOrdinal === -2) {
        errorText = 'Cannot find a route position for the break start time.';
      }

      record.set('plannedRouteOrdinal', routeOrdinal);

      if (Ember.isNone(routeBreakType)) {
        errorText = 'Must select a break type';
      }

      if (routeBreakType === _breakType.FIXED_BREAK && Ember.isEmpty(record.get('place.location.lat'))) {
        errorText = 'Fixed break type must have an address.';
      }

      if (estimatedStartMoment.isSameOrAfter(estimatedEndMoment)) {
        errorText = 'Break start time must be before Break end time.';
      }

      if (!plannedDuration || plannedDuration === 'NaN') {
        errorText = 'Break duration cannot be empty';
      }

      if (estimatedStartMoment.isBefore(routePlannedStartTime)) {
        errorText = "Break start time must be after the route's planned start time.";
      }

      if (plannedDuration > routeMaxExtension) {
        errorText = `Duration exceeds the route end time beyond max route extension (${routeMaxExtension})`;
      }

      record.set('estimatedStart', estimatedStartMoment.toDate());
      record.set('estimatedEnd', estimatedEndMoment.toDate());
      const valid = errorText === '';
      this.set('errorText', errorText);
      this.set('canSave', valid);
      return valid;
    },

    commit() {
      const records = [];
      const saveRecordFunction = this.get('saveRecordFunction');
      const driverBreak = this.get('driverBreak');
      const routeId = driverBreak.get('route.id');
      records.push(driverBreak);

      if (!(0, _lodash.isUndefined)(saveRecordFunction) && saveRecordFunction !== null) {
        return saveRecordFunction(records).then(() => {
          this.onCloseClick();
          const coreEntityPopulator = this.get('coreEntityPopulator');
          Ember.run.later(this, function () {
            coreEntityPopulator.populateRtsRoute(routeId);
            Ember.run.later(this, function () {
              this.get('workspaceContext').manualReloadContext();
            }, 3000);
          }, 3000);
        }).catch(e => {
          // we should handle these errors in the console for developers i.e adapter failures, network failure details
          // let form / widgets components handle error messages for end users to help ease user feedback
          // most of these errors are not percivable by the user anyways and are for developers
          console.warn(e.message, e); // eslint-disable-line no-console
        });
      }

      return (0, _saveRecords.default)(records).then(() => {
        this.onCloseClick();
      }).catch(e => {
        // we should handle these errors in the console for developers i.e adapter failures, network failure details
        // let form / widgets components handle error messages for end users to help ease user feedback
        // most of these errors are not percivable by the user anyways and are for developers
        console.warn(e.message, e); // eslint-disable-line no-console
      });
    },

    resetSelections() {
      const driverBreak = this.get('driverBreak');
      const currentDate = (0, _moment.default)();
      const routePlannedStartTime = (0, _moment.default)(driverBreak.get('route.plannedStartTime'));
      const requestTime = currentDate.isBefore(routePlannedStartTime) ? routePlannedStartTime : currentDate;
      driverBreak.set('requestTime', requestTime.toISOString());
      driverBreak.set('breakType', null);
      driverBreak.set('plannedDuration', 15);
      driverBreak.set('notes', null);
      this.setDefaultDriverBreak();
      this.set('errorText', ''); //clear error text

      this.set('viewImpactButton', false);
      this.set('canSave', false);
      this.initialValidation();
    },

    rollback(model) {
      const changedAttributeModel = model.changedAttributes();

      if (changedAttributeModel) {
        (0, _lodash.forEach)(changedAttributeModel, function (attributevalues, attributeName) {
          model.set(attributeName, attributevalues[0]);
        });
      }
    },

    validateBreakTime() {
      const tooltip = this.get('tooltip');
      const record = this.get('driverBreak');
      const workspace = this.get('workspace');
      const route = (0, _unwrapProxy.unwrapProxy)(record.get('route'));
      const trips = route.get('dispatchRoute.trips').map(t => t).sort((a, b) => a.get('dropStopPoint.eta') - b.get('dropStopPoint.eta'));
      const breakStartTime = (0, _moment.default)(record.get('requestTime'));
      const isBreakInMiddleOfTrip = workspace.get('isBreakInMiddleOfTrip').bind(workspace);
      const isValidBreakTime = workspace.get('isValidBreakTime').bind(workspace);
      const isFloatingBreakType = this.get('isFloatingBreakType');
      const breakEndTime = (0, _moment.default)(this.get('projectedEndDate'));
      const routeplannedEndTime = (0, _moment.default)(route.get('computedPlannedEndTime'));
      const routeMaxParam = this.get('store').peekRecord('cs-config-item', 'config-Route_parameters/routeMaxExtension');
      const routePlannedStartTime = (0, _moment.default)(route.get('computedPlannedStartTime'));
      const routeMaxExtension = routeMaxParam ? parseInt(routeMaxParam.get('value'), 10) : 60; // minutes

      const breakTripObj = {
        trips: trips,
        breakTime: breakStartTime,
        pickKey: 'pickStopPoint',
        dropKey: 'dropStopPoint',
        plannedEta: 'eta'
      };
      this.set('invalidRequestTime', false);

      if (isBreakInMiddleOfTrip(breakTripObj)) {
        tooltip.pushConfirmation({
          tip: 'Unable to save. Adding the break will place the break in the middle of a trip.',
          title: 'Add Driver Break',
          primaryActionText: 'OK',
          hasOverlay: true,
          primaryAction: () => {
            tooltip.reset();
          }
        });
        this.set('invalidRequestTime', true);
        return false;
      }

      const breakTimeInfo = {
        trips: trips,
        breakEndTime,
        pickKey: 'pickStopPoint',
        dropKey: 'dropStopPoint',
        plannedEta: 'arrivalTime',
        nextTravelTime: 'etaNavStopPoint.minutesToNext'
      };

      if (isFloatingBreakType && !isValidBreakTime(breakTimeInfo)) {
        tooltip.pushConfirmation({
          tip: 'Unable to save. Adding the break results in not enough time to travel from break to the next stop.',
          title: 'Add Driver Break',
          primaryActionText: 'OK',
          hasOverlay: true,
          primaryAction: () => {
            tooltip.reset();
          }
        });
        this.set('invalidRequestTime', true);
        return false;
      }

      breakTripObj.breakTime = breakEndTime;

      if (isBreakInMiddleOfTrip(breakTripObj)) {
        tooltip.pushConfirmation({
          tip: 'Unable to save. The break\'s end time will extend past the ETA of the stop after the break',
          title: 'Add Driver Break',
          primaryActionText: 'OK',
          hasOverlay: true,
          primaryAction: () => {
            tooltip.reset();
          }
        });
        this.set('invalidRequestTime', true);
        return false;
      }

      const routeActiveMinutes = routeplannedEndTime.diff(routePlannedStartTime, 'minutes');
      let remainingExtension = 0;

      if (routeActiveMinutes < routeMaxExtension) {
        remainingExtension = routeMaxExtension - routeActiveMinutes;
      }

      const routeMaxEndTime = (0, _moment.default)(routeplannedEndTime).add(remainingExtension, 'minute'); // the break end time must not exceed the maximum possible route end time.

      if (routeMaxEndTime.isBefore(breakEndTime)) {
        this.set('errorClass', true);
        tooltip.pushConfirmation({
          tip: 'Unable to save. Adding the break will extend the route past its allowed end time.',
          title: 'Add Driver Break',
          primaryActionText: 'OK',
          hasOverlay: true,
          primaryAction: () => {
            this.set('errorClass', false);
            tooltip.reset();
          }
        });
        this.set('invalidRequestTime', true);
        return false;
      }

      return true;
    },

    async validateBreakPlace() {
      const workspace = this.get('workspace');
      const tooltip = this.get('tooltip');
      const record = this.get('driverBreak');
      const route = (0, _unwrapProxy.unwrapProxy)(record.get('route'));
      const trips = route.get('trips').map(t => t).sort((a, b) => a.get('drop.plannedEta') - b.get('drop.plannedEta'));
      const breakEndTime = (0, _moment.default)(this.get('projectedEndDate'));
      const validaBreakPlace = workspace.get('validaBreakPlace').bind(workspace);
      const breakPlace = record.get('place');
      const breakTimeInfo = {
        trips: trips,
        breakEndTime,
        pickKey: 'pickStopPoint',
        dropKey: 'dropStopPoint',
        plannedEta: 'arrivalTime',
        breakPlace
      };
      const isValidBreakPlace = await validaBreakPlace(breakTimeInfo);
      this.set('invalidRequestTime', false);

      if (!isValidBreakPlace) {
        tooltip.pushConfirmation({
          tip: 'Unable to save. Adding the break results in not enough time to travel from break to the next stop.',
          title: 'Add Driver Break',
          primaryActionText: 'OK',
          hasOverlay: true,
          primaryAction: () => {
            tooltip.reset();
          }
        });
        this.set('invalidRequestTime', true);
        return false;
      }

      return true;
    },

    onViewImpactPanelClick() {
      const isValid = this.get('isViewImpactButton');
      const collapseState = this.get('collapseState');
      const isValidRecords = this.validateRecords();

      if (!isValid) {
        if (collapseState === 'expanded') {
          this.set('collapseState', 'collapsed');
        } else {
          this.set('collapseState', 'expanded');
        }
      }

      if (isValid && isValidRecords) {
        if (collapseState === 'collapsed') {
          return;
        }

        this.addBreak();
        this.set('canSave', true);
      }
    },

    onbreakTypeChange(breakType) {
      const record = this.get('driverBreak');
      this.set('selectedBreakTypeId', breakType.name);
      record.set('breakType', breakType);
      const routeBreakType = this.get('selectedBreakTypeId');

      if (routeBreakType === _breakType.FLOATING_BREAK) {
        this.rollback((0, _unwrapProxy.unwrapProxy)(record.get('place.address')));
        this.rollback((0, _unwrapProxy.unwrapProxy)(record.get('place.location')));
      }
    },

    availableBreakSlots(record) {
      const orderedStops = !Ember.isEmpty(record.get('orderedStops')) ? record.get('orderedStops') : record.get('orderedStopsWithGarages');
      const stops = !Ember.isNone(orderedStops) ? [...orderedStops] : null; // clone so it does not clobber computed property orderedStops

      let breakTime = [];

      if (Ember.isEmpty(stops)) {
        this.set('breakTimeSlots', breakTime);
        return;
      }

      const lastPerformedStop = record.get('lastPerformedStop') ? record.get('lastPerformedStop') : null;
      const stopIndexTobeIncluded = lastPerformedStop && lastPerformedStop.get('stopType') === 'pick' ? 1 : 0;
      const lastStopIndex = lastPerformedStop && lastPerformedStop.get('routeOrdinal') ? lastPerformedStop.get('routeOrdinal') - stopIndexTobeIncluded : 0;
      const lastStopIndexSplice = lastStopIndex > 0 ? 1 : 0;
      const remainingStops = stops.splice(lastStopIndex - lastStopIndexSplice); // must include last performed Stop for calculations

      remainingStops.reduce((acc, curr) => {
        const eta = !Ember.isEmpty(curr.get('availableTimeBeforeStop')) ? new Date(curr.get('availableTimeBeforeStop')) : new Date(curr.get('arrivalTime'));

        if (eta instanceof Date && isNaN(eta)) {
          return acc;
        }

        const tripId = curr.get('trip.id');
        const stopType = curr.get('stopType');

        if (stopType === 'pick') {
          if (!Ember.isEmpty(breakTime)) {
            if (breakTime.length - 1 >= 0 && Object.keys(acc).length === 0) {
              breakTime[breakTime.length - 1].to = eta;
            }
          }

          acc[tripId] = curr;
        } else if (stopType === 'drop') {
          const time = {
            from: eta
          }; // eslint-disable-next-line no-unused-vars

          const {
            [tripId]: rcrd,
            ...rest
          } = acc;

          if (Object.keys(acc).includes(tripId)) {
            if (Object.keys(rest).length === 0) {
              breakTime.push(time);
            }

            return rest;
          }

          breakTime = [time];
        }

        return acc;
      }, {});

      if (!Ember.isEmpty(breakTime)) {
        const routeEndTime = new Date(record.get('route.computedPlannedEndTime'));
        breakTime[breakTime.length - 1].to = routeEndTime;
      }

      if (!lastPerformedStop) {
        const eta = !Ember.isEmpty(remainingStops[0].get('availableTimeBeforeStop')) ? remainingStops[0].get('availableTimeBeforeStop') : remainingStops[0].get('arrivalTime');
        const time = {
          from: new Date(record.get('route.computedPlannedStartTime')),
          to: new Date(eta)
        };
        breakTime.push(time);
      }

      this.set('breakTimeSlots', breakTime);
    },

    actions: {
      onbreakTypeChange(val) {
        this.onbreakTypeChange(val);
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        const record = this.get('driverBreak');
        this.validateRecords();
        record.set(valuePath, momentTime.toDate());
      },

      onRefreshButtonClick() {
        this.resetSelections();
      },

      onCellValueChange(record, valuePath, value, options) {
        record.set(`${valuePath}.${options.valuePath}`, value);
        this.validateRecords();
      },

      async onSaveClick() {
        const isValidRecords = this.validateRecords();
        const validateBreakTime = this.get('validateBreakTime').call(this);
        const validateBreakPlace = this.get('validateBreakPlace').bind(this);
        const isValidateBreakPlace = this.get('isFixedBreakType') ? await validateBreakPlace() : true;
        const commitAction = this.commit.bind(this);
        const tooltip = this.get('tooltip');
        const dispatchRoute = this.get('driverBreak.route.dispatchRoute');
        const lockRouteAction = this.get('lockRouteAction').bind(this);
        const unlockRouteAction = this.get('unlockRouteAction').bind(this);
        this.set('dispatchRouteToCheck', dispatchRoute);

        const closeCallback = () => {
          this.send('onCloseClick');
        }; // Check dispatchRoute version first


        await this.get('versionCheckOperation').perform(closeCallback.bind(this));

        if (isValidRecords && validateBreakTime && isValidateBreakPlace) {
          try {
            await lockRouteAction();
            tooltip.pushConfirmation({
              tip: 'Would you like to Commit your changes',
              title: 'Add Driver Break',
              primaryActionText: 'Confirm',
              primaryAction: async () => {
                if (this.get('isError')) {
                  this.get('notifications').warning(`Failed to add driver break to route ${this.get('driverBreak.route.name')}`);
                  this.onCloseClick();
                  tooltip.reset();
                  await unlockRouteAction(0);
                  return false;
                }

                try {
                  await commitAction();
                } catch (err) {
                  return;
                } finally {
                  await unlockRouteAction(0);
                  tooltip.reset();
                }
              },
              secondaryActionText: 'Cancel',
              secondaryAction: async () => {
                await unlockRouteAction(0);
                tooltip.reset();
                return;
              },
              closeAction: async () => {
                await unlockRouteAction(0);
              }
            });
          } catch (err) {
            await unlockRouteAction(0);
          }
        }
      },

      onShrinkClick() {
        let currentState = this.get('currentState');
        const selectedBreakType = this.get('driverBreak.breakType');
        const requestTime = this.get('driverBreak.requestTime');
        const duration = this.get('driverBreak.plannedDuration');
        const selectedBreakTypeId = this.get('selectedBreakTypeId');
        const place = this.get('driverBreak.place');
        const savePlace = {
          address: {
            alias: place.get('address.alias'),
            premise: place.get('address.premise'),
            streetNumber: place.get('address.streetNumber'),
            streetAddress: place.get('address.streetAddress'),
            locality: place.get('address.locality'),
            sublocality: place.get('address.sublocality'),
            subRegion: place.get('address.subRegion'),
            postalCode: place.get('address.postalCode'),
            country: place.get('address.country'),
            county: place.get('address.county'),
            freeformAddress: place.get('address.freeformAddress')
          },
          location: {
            lat: place.get('location.lat'),
            lng: place.get('location.lng'),
            geoNode: place.get('location.geoNode')
          }
        }; // save current manual settings

        currentState = {
          selectedBreakType,
          requestTime,
          duration,
          selectedBreakTypeId,
          savePlace
        };
        this.set('currentState', currentState);
        this.onShrinkClick();
      },

      onViewImpactButtonClick() {
        this.set('viewImpactButton', true);
        this.addBreak();
      }

    }
  });

  _exports.default = _default;
});