define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/circleMarker", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/map", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api"], function (_exports, _react, _map, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const CircleMarker = props => {
    const {
      map
    } = _react.default.useContext(_map.MapContext);

    const {
      layer,
      position,
      style,
      color,
      popup,
      renderer
    } = props;
    const {
      eventData
    } = (0, _api.useAPI)();

    const [marker, setMarker] = _react.default.useState();

    _react.default.useEffect(() => {
      if (!map || !layer) return;

      const _marker = window.L.circleMarker(position, {
        renderer,
        radius: 3
      });

      _marker.setStyle({ ...style,
        color
      });

      _marker.bindPopup(popup, {
        closeButton: true
      });

      _marker.addTo(layer);

      setMarker(_marker);
      return () => layer.removeLayer(_marker);
    }, [layer, eventData]);

    _react.default.useEffect(() => {
      if (!marker) return;
      marker.setStyle({ ...style,
        color
      });
      setMarker(marker);
    }, [color]);

    return _react.default.createElement(_react.default.Fragment, null);
  };

  var _default = CircleMarker;
  _exports.default = _default;
});