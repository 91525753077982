define("adept-iq/tests/factories/avlm-canned-message", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const messages = ['Emergency', 'Bruh you safe yet?', 'Drop that foo at the bar he needs a drank'];
  const responseTypes = ['Yes', 'None', 'Acknowledge'];
  const date = new Date();

  _emberDataFactoryGuy.default.define('avlm-canned-message', {
    sequences: {
      messages: num => messages[num % messages.length],
      responseTypes: num => responseTypes[num % responseTypes.length]
    },
    default: {
      body: _emberDataFactoryGuy.default.generate('messages'),
      messageNr: '2',
      replyToMsgId: '1',
      priority: 'normal',
      createdTime: date,
      deliveredTime: date,
      readTime: null,
      deletedTime: null,
      responseType: null,
      responseTypeText: _emberDataFactoryGuy.default.generate('responseTypes'),
      responseMessage: 'Responding...',
      ackTime: date,
      driverEndPointType: 'Recieved' // create driver object dynamically

    },
    traits: {
      recieved: {
        driverEndPointType: 'Sender'
      }
    }
  });
});