define("adept-iq/tests/factories/avlm-vehicle", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('avlm-vehicle', {
    sequences: {
      badgeNumbers: num => Math.floor(Math.random() * num + 1000)
    },
    default: {
      callSign: _emberDataFactoryGuy.default.generate('badgeNumbers'),
      otp: 'L'
    }
  });
});