define("adept-iq/pods/components/side-drawer/system-config/route-master/zone-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    modelName: 'zone',
    title: 'Zones',
    defaultSortId: 'name',
    defaultSortAsc: true,
    fetchModelData: true,
    columns: [{
      id: 'name',
      index: 0,
      type: 'text',
      label: 'Name',
      valuePath: 'displayName',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'affinity',
      index: 1,
      type: 'text',
      label: 'Affinity',
      valuePath: 'convertAffinityValue',
      defaultWidth: 70,
      searchable: true
    }]
  };
  _exports.default = _default;
});