define("adept-iq/validators/unique-route-template-name", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-unused-vars */
  const UniqueRouteTemplatename = _base.default.extend({
    store: Ember.inject.service(),

    validate(value, options, model) {
      let routeTemplates = this.get('store').peekAll('route-template');
      routeTemplates = routeTemplates.filter(r => Ember.isPresent(r.get('id')) && r.get('id') !== model.get('id'));
      const routeNameMap = routeTemplates.reduce((acu, routeTemplate) => {
        acu[routeTemplate.get('name')] = routeTemplate;
        return acu;
      }, {});
      const duplicateRouteTemplate = routeNameMap[value];
      return Ember.isPresent(duplicateRouteTemplate) ? options.message : true;
    }

  });

  UniqueRouteTemplatename.reopenClass({
    getDependentsFor(attribute, options) {
      return [];
    }

  });
  var _default = UniqueRouteTemplatename;
  _exports.default = _default;
});