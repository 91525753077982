define("adept-iq/models/vehicle-availability", ["exports", "ember-data", "moment", "ember-cp-validations", "adept-iq/utils/regex-functions", "adept-iq/config/environment"], function (_exports, _emberData, _moment, _emberCpValidations, _regexFunctions, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const TIME_ZONE = _environment.default.moment.timeZone ? _environment.default.moment.timeZone : 'America/Los_Angeles'; // model validation

  const Validations = (0, _emberCpValidations.buildValidations)({
    shiftStart: {
      description: 'Shift Start',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: '{description} cannot be blank'
      })]
    },
    routeLength: {
      description: 'Shift Length',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: _regexFunctions.REGEX_HHMM,
        message: '{description} must be formatted hh:mm'
      })]
    },
    DOWSelected: {
      description: 'day of week',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Requires at least 1 {description} selected presence'
      }), (0, _emberCpValidations.validator)('length', {
        min: 1,
        message: 'Requires at least 1 {description} selected'
      })]
    }
  });

  var _default = Model.extend(Validations, {
    DOWSelected: attr(),
    startTime: attr('iso-date', {
      defaultValue: null
    }),
    endTime: attr(),
    calcEndDate: attr('iso-date', {
      defaultValue: null
    }),
    shiftStart: attr('iso-date', {
      defaultValue: null
    }),
    shiftEnd: attr('iso-date', {
      defaultValue: null
    }),
    routeLength: attr('string'),
    sunday: attr('boolean'),
    monday: attr('boolean'),
    tuesday: attr('boolean'),
    wednesday: attr('boolean'),
    thursday: attr('boolean'),
    friday: attr('boolean'),
    saturday: attr('boolean'),
    holiday: attr('boolean'),
    vehicle: belongsTo('vehicle'),
    vehicleId: Ember.computed.readOnly('vehicle.id'),
    localShiftStart: Ember.computed('shiftStart', {
      get() {
        const shiftStart = _moment.default.tz((0, _moment.default)().format('Y-MM-DDT') + this.get('shiftStart'), TIME_ZONE);

        return shiftStart.format(_environment.default.dateTimeFormat.timeMoment);
      },

      set(key, value) {
        const shiftStart = _moment.default.tz('2020-01-01T' + value, TIME_ZONE);

        this.set('shiftStart', shiftStart.utc().format('HH:mm:ssZ'));
        return value;
      }

    }),
    localShiftEnd: Ember.computed('shiftEnd', {
      get() {
        const shiftEnd = _moment.default.tz((0, _moment.default)().format('Y-MM-DDT') + this.get('shiftEnd'), TIME_ZONE);

        return shiftEnd.format(_environment.default.dateTimeFormat.timeMoment);
      },

      set(key, value) {
        const shiftEnd = _moment.default.tz('2020-01-01T' + value, TIME_ZONE);

        this.set('shiftEnd', shiftEnd.utc().format('HH:mm:ssZ'));
        return value;
      }

    }),
    formattedShiftStart: Ember.computed('shiftStart', function () {
      return (0, _moment.default)(this.get('shiftStart'), 'HH:mm:ss').format(_environment.default.dateTimeFormat.timeMoment);
    }),
    formattedShiftEnd: Ember.computed('shiftEnd', function () {
      return (0, _moment.default)(this.get('shiftEnd'), 'HH:mm:ss').format(_environment.default.dateTimeFormat.timeMoment);
    }),
    selectedDOWs: Ember.computed('sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'holiday', function () {
      const selectedDOWArray = [];
      if (this.get('sunday')) selectedDOWArray.push('sunday');
      if (this.get('monday')) selectedDOWArray.push('monday');
      if (this.get('tuesday')) selectedDOWArray.push('tuesday');
      if (this.get('wednesday')) selectedDOWArray.push('wednesday');
      if (this.get('thursday')) selectedDOWArray.push('thursday');
      if (this.get('friday')) selectedDOWArray.push('friday');
      if (this.get('saturday')) selectedDOWArray.push('saturday');
      if (this.get('holiday')) selectedDOWArray.push('holiday');
      return selectedDOWArray;
    })
  });

  _exports.default = _default;
});