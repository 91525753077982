define("adept-iq/pods/components/side-drawer/system-config/places/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fields = void 0;
  const fields = {
    id: 'location',
    type: 'addresses',
    label: '',
    valuePath: 'place',
    modelName: 'place',
    addressOptions: [],
    addressType: 'originlocations',
    extra: {
      displayPath: 'address.address',
      premisePath: 'address.premise',
      streetNumberPath: 'address.streetNumber',
      streetNamePath: 'address.streetName',
      localityPath: 'address.locality',
      regionPath: 'address.region',
      subRegionPath: 'address.county',
      postalCodePath: 'address.postalCode',
      countryPath: 'address.country',
      freeformAddressPath: 'address.freeformAddress',
      tomtomFormattedAddressPath: 'address.tomtomFormattedAddress',
      useOptionRecord: true,
      streetAddressPath: 'address.streetAddress',
      lat: 'location.lat',
      lng: 'location.lng',
      geoNode: 'location.geoNode'
    }
  };
  _exports.fields = fields;
});