define("adept-iq/topic-transforms/avlm-rider-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload
  /*, ENV*/
  ) {
    const transformedPayloads = [];
    const {
      rider,
      stopPoint,
      schedule
    } = payload.data.relationships;
    payload.data.attributes.otp = payload.data.attributes.otpStatus; // create placeholders so ember won't do a get

    if (rider) {
      payload.data.relationships.avlmRider = rider;
      delete payload.data.relationships.rider;
    }

    if (stopPoint) {
      // this is coming in as 'avlm-stopPoint' for some reason
      stopPoint.data.type = 'avlm-stop-point';
      payload.data.relationships.avlmStopPoint = stopPoint;
      delete payload.data.relationships.stopPoint;
    }

    if (schedule) {
      payload.data.relationships.avlmSchedule = schedule;
      delete payload.data.relationships.schedule;
    }

    transformedPayloads.push(payload);
    return transformedPayloads;
  }
});