define("adept-iq/pods/components/iq-widgets/subscription-form/subscription-changes-table/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    rowSelectionType: 'checkbox',
    modelName: 'subscription-booking',
    title: 'Bookings',
    defaultSortId: 'time',
    defaultSortAsc: true,
    queryModelData: false,
    showSelectedRowLength: false,
    columns: [{
      id: 'action',
      index: 0,
      type: 'text',
      label: 'Action',
      defaultWidth: 180,
      sortable: true,
      valuePath: 'actionDescription',
      searchable: true
    }, {
      id: 'time',
      index: 1,
      type: 'date',
      label: 'Date/Request/Time',
      defaultWidth: 130,
      format: `${_environment.default.dateTimeFormat.dateTimeMoment}`,
      valuePath: 'time',
      searchable: true,
      sortable: true
    }, {
      id: 'pickAddress',
      index: 2,
      type: 'text',
      label: 'Pickup Address',
      defaultWidth: 210,
      sortable: true,
      searchable: true,
      valuePath: 'pickAddress'
    }, {
      id: 'pickCity',
      index: 3,
      type: 'text',
      label: 'Pickup City',
      defaultWidth: 100,
      sortable: true,
      searchable: true,
      valuePath: 'pickCity'
    }, {
      id: 'dropAddress',
      index: 4,
      type: 'text',
      label: 'Drop off Address',
      defaultWidth: 210,
      sortable: true,
      searchable: true,
      valuePath: 'dropAddress'
    }, {
      id: 'dropCity',
      index: 5,
      type: 'text',
      label: 'Drop off Address',
      defaultWidth: 100,
      sortable: true,
      searchable: true,
      valuePath: 'dropCity'
    }]
  };
  _exports.default = _default;
});