define("adept-iq/pods/components/iq-widgets/alert-widget/alerts-row/component", ["exports", "adept-iq/pods/components/table/rows/text-formatted-row/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CATEGORY_EMERGENCY = 'emergency';
  const ACTIVE_EMERGENCY = 'active';

  var _default = _component.default.extend({
    alertCategory: Ember.computed('category', 'state', function () {
      const category = this.get('category');
      const state = this.get('state');

      if (category === CATEGORY_EMERGENCY && state === ACTIVE_EMERGENCY) {
        return 'otp-danger';
      }

      return '';
    }),
    category: Ember.computed.alias('row.category'),
    state: Ember.computed.alias('row.state')
  });

  _exports.default = _default;
});