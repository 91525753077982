define("adept-iq/pods/components/rider-management-widget/rider-certification/approval-process/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['rider-reasonDenied-options-select'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    rider: Ember.inject.service(),
    certificationDeniedReasons: null,
    CertificationDeniedReasonOptions: null,

    async init() {
      this._super(...arguments);

      const certificationDeniedReasons = await this.get('rider').getConfigCertificationDeniedReasons();
      const CertificationDeniedReasonOptions = certificationDeniedReasons.map(Cdn => {
        return {
          displayName: Cdn.get('value.displayName'),
          name: Cdn.get('name')
        };
      });
      this.setProperties({
        CertificationDeniedReasonOptions
      });
    },

    approvedDate: Ember.computed('riderCertificationRecord.approvedDate', function () {
      const approvedDate = this.get('riderCertificationRecord.approvedDate');
      return approvedDate ? approvedDate : null;
    }),
    deniedDate: Ember.computed('riderCertificationRecord.deniedDate', function () {
      const deniedDate = this.get('riderCertificationRecord.deniedDate');
      return deniedDate ? deniedDate : null;
    }),
    selectedReasonDeniedObject: Ember.computed('riderCertificationRecord.reasonDenied', function () {
      const CertificationDeniedReasonOptions = this.get('CertificationDeniedReasonOptions');
      const reasonDenied = this.get('riderCertificationRecord.reasonDenied');
      if (Ember.isEmpty(CertificationDeniedReasonOptions)) return null;
      return CertificationDeniedReasonOptions.find(option => {
        return option.name === reasonDenied;
      });
    }),
    actions: {
      onDateValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onRiderReasonDeniedChange(record, value) {
        record.set('reasonDenied', value.name);
      }

    }
  });

  _exports.default = _default;
});