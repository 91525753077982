define("adept-iq/pods/components/form-components/multientry/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    This component is based off the "eligibilities" and "places" components
  
    It generates a form of text fields based on a config that supplies "rows" in a collection
    For each key in a "row" object,
    it pulls out the column key
    and supplies a cell label and an input field from that key/value pair
  
    The intent is that a serializer would pull a model from an API,
    translate it into the desired form configuration object
    and supply new records based on these arbitrary fields
  
    TODO: hook this component/a model into a Mirage serializer
    to supply a backend and multiple records on page load
  
    Sample config entries:
    app/pods/components/iq-widgets/edit-settings-form-widget/config.js
    {
      title: 'Wifi Network',
      fields: [{
        id: 'wifiNetwork',
        type: 'multientry',
        label: '',
        valuePath: 'wifiNetwork',
        modelName: 'rms-address',
        isMultiType: true,
        extra: {
          rows: [
            {password: 'default', networkName: 'default'}
          ]
        }
      }]},
  */
  var _default = Ember.Component.extend({
    classNames: ['form-components-multientry'],
    store: Ember.inject.service(),
    value: null,
    editableRecords: null,
    editableSections: null,
    service: null,
    columns: Ember.computed('extra.rows.firstObject', function () {
      const row = this.get('extra.rows.firstObject');
      return Object.keys(row);
    }),
    rows: Ember.computed.readOnly('extra.rows'),
    model: null,
    apiService: null,

    init() {
      this._super(...arguments);
    },

    actions: {
      onInput() {// do something with typed values
      },

      onClose() {},

      onAddNew(model, valuePath, modelName) {
        // create a new table item
        const newItem = this.get('editModal').pushRecordValue(model, valuePath, modelName);
        this.get('editModal').pushRecordValue(newItem, 'multientry-catchall', 'rms-location');
      },

      onRemove() {// remove an item from a table
        // alert('Remove me', model)
        // let options = { record, valuePath };
        // this.get('onRemove')(value, options);
      }

    }
  });

  _exports.default = _default;
});