define("adept-iq/pods/components/reoptimize-modal/operation-tables/release-routes-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/reoptimize-modal/operation-tables/release-routes-table/config", "adept-iq/models/provider"], function (_exports, _component, _config, _provider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PROVIDER_CONFIG = [{
    id: 'failedProvider',
    index: 1,
    type: 'text',
    valuePath: 'provider.providerFailedReason',
    searchable: true,
    defaultWidth: 60
  }, {
    id: 'providerDetails',
    index: 2,
    type: 'text',
    valuePath: 'provider.details',
    searchable: true,
    defaultWidth: 60
  }];

  var _default = _component.default.extend({
    classNames: ['release-routes-table'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    config: _config.default,
    currentSchedule: null,
    configColumn: null,

    init() {
      this._super(...arguments);

      const currentScheduleId = this.get('workspace.currentSchedule.id');
      this.set('configColumn', [..._config.default.columns]);
      this.set('queryParams', {
        filter: `eq(scheduleId,${currentScheduleId})`
      });
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
        this.set('checkAll', false);
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      this.set('tableRef', null);
      this.set('config.columns', this.get('configColumn'));
    },

    fetchDataQuery() {
      return this._super(...arguments).then(records => {
        const uniqRecords = records.uniqBy('provider.id');
        const filteredRecords = uniqRecords.filter(record => {
          return record.get('provider.userCanAccessProvider') && record.get('provider.status').toLowerCase() === _provider.ACTIVE_STATUS_TYPE;
        });
        return filteredRecords;
      });
    },

    errorTripProviders: Ember.observer('failedTripProviders', function () {
      const failedTripProviders = this.get('failedTripProviders');
      const parsedFailedTripCounts = JSON.parse(failedTripProviders);
      const failedProviders = Object.keys(parsedFailedTripCounts);

      if (!Ember.isEmpty(failedTripProviders)) {
        const configColumn = this.get('config.columns');
        PROVIDER_CONFIG.forEach(col => {
          configColumn.pushObject(col);
        });
        failedProviders.forEach(provider => {
          this.get('table.rows').forEach(row => {
            if (row.get('provider.name') === provider) {
              row.set('provider.providerFailedReason', `${parsedFailedTripCounts[provider].count} trips failed to release`);
              row.set('provider.details', 'Details');
            }
          });
        });
      }
    }),
    actions: {
      onRowClick(row) {
        const isDetailsAvailable = row.get('provider.details');

        if (isDetailsAvailable) {
          this.set('selectedRow', [row]);
          this.set('isProviderTableShow', true);
        }
      }

    }
  });

  _exports.default = _default;
});