define("adept-iq/pods/components/iq-widgets/new-booking-form/booking-details/trip-request-details/component", ["exports", "moment", "adept-iq/config/environment", "adept-iq/models/segment"], function (_exports, _moment, _environment, _segment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PARA_TRANSIT_MODE = 'paratransit';
  const ITP_TRANSIT_MODE = 'itp';
  const BROKER_TRANSIT_MODE = 'broker';
  const NO_PROMISE_TIME_GENERATED = 'Unable to generate Promise Time';

  var _default = Ember.Component.extend({
    classNames: ['trip-details'],
    store: Ember.inject.service(),
    booking: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    tripDate: null,
    requestTime: null,
    pickAddress: null,
    dropAddress: null,
    isCallBackBookingModeType: false,
    showPromiseTime: Ember.computed('isCallBackBookingModeType', 'currentTrip.booking.isSameDayBooking', function () {
      return !this.get('isCallBackBookingModeType');
    }),
    promiseTime: Ember.computed('tripBooking.requestTime', 'selectedtravelMode', 'currentTrip.promiseTime', function () {
      const requestTime = this.get('tripBooking.requestTime');
      const travelMode = this.get('selectedtravelMode.displayName');
      const promiseTime = this.get('currentTrip.promiseTime'); //NYAAR-20351-If the service mode is 'taxi', then the trip has no assigned route and no promise time for the trip

      if (travelMode === _segment.TAXI_TYPE) {
        return '';
      }

      if (this.get('currentTrip.booking.isSameDayBooking')) {
        if (!this.get('currentTrip.promiseTime')) {
          return '';
        }

        return (0, _moment.default)(promiseTime).format(_environment.default.dateTimeFormat.timeMoment);
      }

      if (!this.get('booking.enableNewPromiseTimeApi')) {
        return requestTime && (0, _moment.default)(requestTime).format(_environment.default.dateTimeFormat.timeMoment);
      }

      const legIndex = this.get('legIndex');

      if (!this.get('booking').hasPromiseTime(legIndex) && this.get('booking').hasParaTransitOrItpFeederModeSelected(legIndex)) {
        return NO_PROMISE_TIME_GENERATED;
      }

      if (!this.get('booking').hasParaTransitOrItpFeederModeSelected(legIndex)) {
        return '';
      }

      if (!this.get('booking').canGetPromiseTime(legIndex)) {
        return NO_PROMISE_TIME_GENERATED;
      }

      const resolvedPromiseTime = this.get('booking').getPromiseTime(legIndex, this.get('tripBooking'));

      if (!resolvedPromiseTime) {
        return NO_PROMISE_TIME_GENERATED;
      }

      return (0, _moment.default)(resolvedPromiseTime).format(_environment.default.dateTimeFormat.timeMoment);
    }),
    promisePickupWindow: Ember.computed('promiseTime', function () {
      const promiseTime = (0, _moment.default)(this.get('promiseTime'), _environment.default.dateTimeFormat.timeMoment);

      if (!promiseTime.isValid()) {
        return {
          pickupWindowStart: '',
          pickupWindowEnd: ''
        };
      }

      const tempBookingRecord = this.get('booking.tempBookingInfo.tempBookingRecord');
      const legIndex = this.get('legIndex');
      const leg = tempBookingRecord.get('legs').toArray()[legIndex];
      const serviceWindow = leg.get('serviceWindow');
      const pickupWindowStart = promiseTime.clone().subtract(serviceWindow.get('promiseWindowStart'), 'minutes');
      const pickupWindowEnd = promiseTime.clone().add(serviceWindow.get('promiseWindowEnd'), 'minutes');
      return {
        pickupWindowStart: pickupWindowStart.format(_environment.default.dateTimeFormat.timeMoment),
        pickupWindowEnd: pickupWindowEnd.format(_environment.default.dateTimeFormat.timeMoment)
      };
    }),
    promiseTimeFailureReason: Ember.computed('legIndex', function () {
      const legIndex = this.get('legIndex');
      const allFailureReason = this.get('booking').promiseTimeFailureReason('ALL');
      const failureReason = this.get('booking').promiseTimeFailureReason(legIndex);
      const isSameDayBooking = this.get('currentTrip.booking.isSameDayBooking');

      if (isSameDayBooking) {
        return;
      }

      return allFailureReason ? allFailureReason : failureReason;
    }),
    // NYAAR-13400 added red-text paratransit = "Unable to generate Promise Time"
    // Incase promise time is generated  => For 'taxi' is empty  and   promise time will be displayed for paratransit;
    // Incase promise time is  not generated => For 'taxi' is empty and   promise time  'Unable to generate promise time' red-color-text will be displayed;
    isNotPromiseTime: Ember.computed('promiseTime', 'selectedtravelMode', function () {
      const legIndex = this.get('legIndex');
      const isSameDayBooking = this.get('currentTrip.booking.isSameDayBooking');

      if (this.get('promiseTime') === NO_PROMISE_TIME_GENERATED) {
        return true;
      }

      if (!isSameDayBooking && !this.get('booking').hasPromiseTime(legIndex) && this.get('booking').hasParaTransitOrItpFeederModeSelected(legIndex)) {
        return true;
      }

      return false;
    }),
    currentTrip: Ember.computed('legIndex', 'booking.tempBookingInfo.tempBookingRecord.trips', function () {
      const legIndex = this.get('legIndex');
      const trips = this.get('booking.tempBookingInfo.tempBookingRecord.trips');
      const currentTrip = Ember.isPresent(trips) ? trips.objectAt(legIndex) : null;

      if (Ember.isPresent(currentTrip)) {
        return currentTrip;
      }

      return null;
    }),
    disableServiceModeDropDown: Ember.computed('booking.itpInfo.hasOverrideITP', 'selectedtravelMode', function () {
      const hasOverrideITP = this.get('booking.itpInfo.hasOverrideITP');
      const selectedtravelMode = this.get('selectedtravelMode.displayName'); // if selected travel mode is itp and user doesn't have permission to override itp , disable service mode  dropdown

      if (selectedtravelMode.toLowerCase() === 'itp' && hasOverrideITP === false) {
        return true;
      } // always disabled since AIQ-3956


      return true;
    }),
    travelModes: null,
    selectedtravelMode: null,
    riderRecord: null,
    allowedServiceModes: null,

    init() {
      this._super(...arguments);

      this.setTravelModeOptions();
      this.setDefaultravelMode();
      this.set('isCallBackBookingModeType', this.get('booking').isCallBackBookingModeType());
    },

    didRender() {
      this._super(...arguments);

      const isNotPromiseTime = this.get('isNotPromiseTime');
      this.set('booking.isNotPromiseTime', isNotPromiseTime);
    },

    didInsertElement() {
      this._super(...arguments);

      this.checkPromiseTime();
    },

    /**
     * NYAAR-19074: Checking if any leg has no promise time generated
     */
    checkPromiseTime() {
      const promiseTime = this.get('promiseTime');
      this.set('tripBooking.promiseTime', promiseTime);
      this.setTripDetails();
      const filteredTripsBasedOnPromiseTime = this.filterTripDetails();
      this.get('setIsEveryLegHasPromiseTimeProp')(false); //NYAAR-19074: Setting isEveryLegHasPromiseTime true only if all legs have promise time generated

      if (!filteredTripsBasedOnPromiseTime) {
        this.get('setIsEveryLegHasPromiseTimeProp')(true);
      }
    },

    /**
    * Setting trip details for every leg in trip-request-details
    */
    setTripDetails() {
      const tripDetails = this.get('booking.tripDetails');
      const tripBooking = this.get('tripBooking');
      tripDetails.forEach(tripDetail => {
        if ((0, _moment.default)(tripDetail.requestTime).isSame(tripBooking.requestTime)) {
          tripDetails.removeObject(tripDetail);
        }
      });
      tripDetails.pushObject(tripBooking);
    },

    /**
     * Filtering trip details based on no promise generated
     * @returns number(filteredTripDetails length)
     */
    filterTripDetails() {
      const tripDetails = this.get('booking.tripDetails');
      const filteredTripsBasedOnPromiseTime = tripDetails.filter(trip => {
        return trip.promiseTime === NO_PROMISE_TIME_GENERATED;
      });
      return filteredTripsBasedOnPromiseTime.length;
    },

    setTravelModeOptions() {
      const allowedServiceModes = this.getAllowedServiceModesForDropDown();
      const configTravelModes = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-travel_modes';
      });
      this.setAllTravelModeDisplayNameToLower(configTravelModes);
      const travelModeOptions = configTravelModes.filter(tm => {
        return allowedServiceModes.includes(tm.get('name').toLowerCase());
      });
      this.set('travelModes', travelModeOptions);
    },

    setAllTravelModeDisplayNameToLower(travelModes) {
      travelModes.forEach(tm => {
        const displayName = tm.get('displayName').toLowerCase();
        tm.set('displayName', displayName);
      });
    },

    /**
     * checking if the ITP is available on the tripdate
     */
    isItpAvailable() {
      const itpBlockoutDates = this.get('systemConfig.findItpBlockoutDates')();
      const tripDate = this.get('tripBooking.tripDate');
      const tripBookingDate = (0, _moment.default)(tripDate).format(_environment.default.dateTimeFormat.dateMoment);
      let isItpBlockList = false;
      itpBlockoutDates.forEach(blockoutDate => {
        const fromDate = blockoutDate.firstObject;
        const toDate = blockoutDate.lastObject; //Setting isItpBlockList true if ITP is booked on ITP blockout dates

        if ((0, _moment.default)(tripBookingDate).isBetween(fromDate, toDate, 'days', '[]')) {
          isItpBlockList = true;
        }
      });
      return isItpBlockList;
    },

    /**
     * this will return list of service mode string example : ['itp', 'paratransit', 'broker', 'taxi']
     * @returns {*}
     */
    getAllowedServiceModesForDropDown() {
      const travelModes = this.get('travelModeMapper')[this.get('legIndex')]; //NYAAR-18686: removing ITP service mode if selected date comes within ITP Blockoutdate range

      if (travelModes && this.isItpAvailable()) {
        const filteredTravelmodes = travelModes.filter(serviceMode => serviceMode !== ITP_TRANSIT_MODE);
        this.get('travelModeMapper')[this.get('legIndex')] = filteredTravelmodes;
      }

      const riderTravelModes = travelModes || []; // if only itp model is evaluated in eligibility workflow, travel mode list should display only itp

      const isITPModeOnly = riderTravelModes.length === 1 && riderTravelModes.includes(ITP_TRANSIT_MODE);

      if (isITPModeOnly) {
        return [ITP_TRANSIT_MODE];
      }

      let allowedServiceModes = this.get('allowedServiceModes') || []; // ride allowedServiceModes doesnt contain para transit, add the para transit in travel mode  drop down
      // by default, paratransit is allowed

      if (!allowedServiceModes.includes(PARA_TRANSIT_MODE)) {
        allowedServiceModes.push(PARA_TRANSIT_MODE);
      } // if itp  travel mode is not eavluated by  eiligibity workflow, remove the itp service mode


      if (!riderTravelModes.includes(ITP_TRANSIT_MODE)) {
        allowedServiceModes = allowedServiceModes.filter(serviceMode => serviceMode !== ITP_TRANSIT_MODE);
      } // remove broker from  dropdown based on the comment in the story NYAAR-7795


      allowedServiceModes = allowedServiceModes.filter(serviceMode => serviceMode !== BROKER_TRANSIT_MODE);
      const normalizedServiceModes = allowedServiceModes.map(s => s.toLowerCase());
      return normalizedServiceModes;
    },

    setDefaultravelMode() {
      const travelModes = this.get('travelModeMapper')[this.get('legIndex')];
      const riderTravelModes = travelModes || [];
      const defaultTravelMode = riderTravelModes[0] || PARA_TRANSIT_MODE; // get previous travel-mode-map for edit-booking

      const previousTravelModeMap = this.get('legTravelModeMap');
      const previousTravelMode = previousTravelModeMap[this.get('legIndex')];
      const prevTravelModeConfig = this.get('travelModes').find(travelModelConfig => {
        return travelModelConfig.name.toLowerCase() === previousTravelMode;
      });
      const selectedTravelModeConfig = prevTravelModeConfig || this.get('travelModes').find(travelModelConfig => {
        return travelModelConfig.name.toLowerCase() === defaultTravelMode;
      });
      this.set('selectedtravelMode', selectedTravelModeConfig);
      const onSelectedTravelModeChange = this.get('onSelectedTravelModeChange');

      if (onSelectedTravelModeChange) {
        onSelectedTravelModeChange(this.get('legIndex'), selectedTravelModeConfig);
      }
    },

    actions: {
      onTravelModeChange(travelMode) {
        this.set('selectedtravelMode', travelMode);
        const onSelectedTravelModeChange = this.get('onSelectedTravelModeChange');

        if (onSelectedTravelModeChange) {
          onSelectedTravelModeChange(this.get('legIndex'), travelMode);
        }

        this.checkPromiseTime();
      }

    }
  });

  _exports.default = _default;
});