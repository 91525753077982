define("adept-iq/pods/components/iq-widgets/reconcile-routes-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/reconcile-routes-widget',
    rowComponent: 'table/rows/action-row',
    modelName: 'dwh-route',
    defaultSortId: 'name',
    defaultSortAsc: true,
    title: 'Routes',
    columns: [{
      id: 'name',
      type: 'text',
      label: 'Name',
      valuePath: 'routeName',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'status',
      type: 'text',
      label: 'Status',
      valuePath: 'status',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'provider',
      type: 'text',
      label: 'Provider',
      valuePath: 'provider',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'type',
      type: 'text',
      label: 'Type',
      valuePath: 'type',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'driverId',
      type: 'text',
      label: 'Driver ID',
      valuePath: 'driverId',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'driverFirstName',
      type: 'text',
      label: 'Driver First Name',
      valuePath: 'driverFirstName',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'driverLastName',
      type: 'text',
      label: 'Driver Last Name',
      valuePath: 'driverLastName',
      defaultWidth: 85,
      searchable: true
    }, // {
    //   id: 'driverPhoneNumber',
    //   type: 'text',
    //   label: 'Driver Phone Number',
    //   valuePath: 'driverPhoneNumBer',
    //   defaultWidth: 85,
    //   searchable: true
    // },
    {
      id: 'vehicleId',
      type: 'text',
      label: 'Vehicle ID',
      valuePath: 'vehicleName',
      defaultWidth: 85,
      searchable: true
    }, {
      id: 'plannedStartTime',
      type: 'time',
      format: 'HH:mm',
      label: 'Planned Start Time',
      valuePath: 'plannedStartTime',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'plannedEndTime',
      type: 'time',
      format: 'HH:mm',
      label: 'Planned End Time',
      valuePath: 'plannedEndTime',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'actualStartTime',
      type: 'time',
      format: 'HH:mm',
      label: 'Actual Start Time',
      valuePath: 'actualstarttime',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'actualEndTime',
      type: 'time',
      format: 'HH:mm',
      label: 'Actual End Time',
      valuePath: 'actualendtime',
      defaultWidth: 70,
      searchable: true
    }]
  };
  _exports.default = _default;
});