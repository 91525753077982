define("adept-iq/services/gps-manual", ["exports", "adept-iq/utils/geolib"], function (_exports, _geolib) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INITIAL_LOCATION = [43.670300, -79.435249]; // Isle of Code

  const FPS = 1;
  const LAT_NOISE = 0.0002;
  const LNG_NOISE = 0.0002;
  const T_NOISE = 100;

  var _default = Ember.Service.extend({
    speed: 0,
    location: null,
    bearing: 0,

    init() {
      this._super(...arguments);

      this.set('location', INITIAL_LOCATION); // don't wrap in run loop

      this._onKeydown = e => this.onKeydown(e); // but wrap this one


      this._onTimeout = () => Ember.run(() => this.onTimeout());

      this.start();
    },

    destroy() {
      if (this._timer) {
        this.stop();
      }

      this._onKeydown = null;
      this._onTimeout = null;

      this._super(...arguments);
    },

    start() {
      if (this._timeout) return;
      Ember.$(document).on('keydown', this._onKeydown);
      this.onTimeout();
    },

    stop() {
      clearTimeout(this._timeout);
      this._timeout = null;
      Ember.$(document).off('keydown', this._onKeydown);
    },

    onTimeout() {
      this.update();
      const r = Math.random() * 2 - 1;
      const t = 1000.0 / FPS + r * T_NOISE;
      this._timeout = setTimeout(this._onTimeout, t);
    },

    update() {
      const oldLocation = this.get('location');
      const bearing = this.get('bearing');
      const speed = this.get('speed');
      const distance = speed / FPS;
      const location = (0, _geolib.fromGeolib)(geolib.computeDestinationPoint((0, _geolib.toGeolib)(oldLocation), distance, bearing)); // add noise proportional to speed

      if (speed > 0) {
        location[0] += (2 * Math.random() - 1) * LAT_NOISE * (speed / 100.0);
        location[1] += (2 * Math.random() - 1) * LNG_NOISE * (speed / 100.0);
      }

      this.set('location', location);
      const eventHandler = this.get('eventHandler');
      if (eventHandler) eventHandler({
        coords: {
          latitude: location[0],
          longitude: location[1]
        }
      });
    },

    onKeydown(e) {
      switch (e.which) {
        case 37:
          {
            // left
            e.preventDefault();
            this.set('bearing', this.get('bearing') - 10);
            break;
          }

        case 38:
          {
            // up
            e.preventDefault();
            this.set('speed', this.get('speed') + 5);
            break;
          }

        case 39:
          {
            // right
            e.preventDefault();
            this.set('bearing', this.get('bearing') + 10);
            break;
          }

        case 40:
          {
            // down
            e.preventDefault();
            this.set('speed', this.get('speed') - 5);
            break;
          }

        case 32:
          {
            // sp
            e.preventDefault();
            this.set('speed', 0);
            break;
          }

        default:
          break;
      }
    },

    getLocation() {
      return Ember.RSVP.resolve(this.get('location'));
    },

    onLocationChange(eventHandler) {
      this.set('eventHandler', eventHandler);
    },

    offLocationChange() {
      this.set('eventHandler', null);
    }

  });

  _exports.default = _default;
});