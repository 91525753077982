define("adept-iq/classes/active-contexts/permission/map-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mapContextNodes = _exports.mapContextModels = _exports.mapContextGraph = _exports.default = void 0;
  const mapContextNodes = [];
  _exports.mapContextNodes = mapContextNodes;
  const mapContextGraph = mapContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.mapContextGraph = mapContextGraph;
  const mapContextModels = mapContextNodes.reduce((obj, node) => {
    obj[node.modelName] = node;
    return obj;
  }, {});
  _exports.mapContextModels = mapContextModels;
  var _default = mapContextGraph;
  _exports.default = _default;
});