define("adept-iq/classes/data-joins/base-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    cache: null,
    nodes: null,

    init() {
      this._super(...arguments);

      this.set('cache', {});
      this.set('nodes', []);
    },

    destroy() {
      this.clear();
      this.purgeCache();

      this._super(...arguments);
    },

    clear() {
      this.join([]);
    },

    // override initialize, enter, update, and exit with custom behaviour
    initialize: data => data,

    enter() {},

    update() {},

    exit() {},

    release() {},

    keyForNode: node => {
      const key = Ember.get(node, 'id') || Ember.guidFor(node);
      return key;
    },
    keyForData: data => {
      const key = Ember.get(data, 'id') || Ember.guidFor(data);
      return key;
    },

    join(collection
    /*, opts */
    ) {
      const cache = this.get('cache');
      const nodes = this.get('nodes'); // build lookup tables

      const wasInNodes = {};

      for (let i = 0; i < nodes.length; i++) {
        wasInNodes[this.keyForNode(nodes[i])] = true;
      }

      const isInCollection = {};

      for (let i = 0; i < collection.length; i++) {
        isInCollection[this.keyForNode(collection[i])] = true;
      } // exit outgoing nodes first


      for (let i = nodes.length - 1; i >= 0; i--) {
        const node = nodes[i];
        const key = this.keyForNode(node);

        if (!isInCollection[key]) {
          this.exit(node);
        }
      }

      nodes.clear();
      collection.forEach((data, i) => {
        const key = this.keyForData(data);
        let node = cache[key];

        if (!node) {
          node = this.initialize(data);
          cache[key] = node;
        }

        nodes.push(node);

        if (wasInNodes[key]) {
          this.update(node, data, i);
        } else {
          this.enter(node, i);
        }
      });
      return this;
    },

    // removes any exited nodes from cache
    purgeCache() {
      const nodes = this.get('nodes');
      const cache = this.get('cache'); // build lookup table

      const inNodes = {};

      for (let i = 0; i < nodes.length; i++) {
        inNodes[this.keyForNode(nodes[i])] = true;
      }

      Object.entries(cache).forEach(_ref => {
        let [key, node] = _ref;

        if (!inNodes[key]) {
          this.release(node);
          delete cache[key];
        }
      });
    }

  });

  _exports.default = _default;
});