define("adept-iq/pods/components/iq-widgets/permissions-roles-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/permissions-roles-widget',
    rowComponent: 'table/rows/action-row',
    modelName: 'perm-sso-role',
    title: 'Roles',
    defaultSortId: 'name',
    defaultSortAsc: false,
    hideCheckBox: true,
    columns: [{
      id: 'name',
      type: 'text',
      label: 'Role Name',
      valuePath: 'displayName',
      searchable: true,
      resizable: true,
      minResizeWidth: 70,
      hidden: false
    }, {
      id: 'workspacePermissions',
      type: 'text',
      label: 'Workspace Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.WORKSPACE',
      searchable: true,
      resizable: true,
      minResizeWidth: 70,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'dispatchPermissions',
      type: 'text',
      label: 'Dispatch Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.DISPATCH',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'schedulingPermissions',
      type: 'text',
      label: 'Scheduling Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.SCHEDULING',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'subscriptionPermissions',
      type: 'text',
      label: 'Subscription Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.SUBSCRIPTION',
      searchable: true,
      resizable: true,
      minResizeWidth: 70,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'routePermissions',
      type: 'text',
      label: 'Route Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.ROUTE',
      searchable: true,
      resizable: true,
      minResizeWidth: 70,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'widgetPermissions',
      type: 'text',
      label: 'Widget Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.WIDGET',
      searchable: true,
      resizable: true,
      minResizeWidth: 70,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'systemManagementPermissions',
      type: 'text',
      label: 'System Management Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.SYSTEM_MANAGEMENT',
      searchable: true,
      resizable: true,
      minResizeWidth: 70,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'rolePermissions',
      type: 'text',
      label: 'Role Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.ROLE',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'userPermissions',
      type: 'text',
      label: 'User Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.USER',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'timelinePermissions',
      type: 'text',
      label: 'Timeline Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.TIMELINE',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'tripPermissions',
      type: 'text',
      label: 'Trip Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.TRIP',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'stopPermissions',
      type: 'text',
      label: 'Stop Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.STOP',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'breakPermissions',
      type: 'text',
      label: 'Break Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.BREAK',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'passengerPermissions',
      type: 'text',
      label: 'Passenger Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.PASSENGER',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'vehiclePermissions',
      type: 'text',
      label: 'Vehicle Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.VEHICLE',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'messagesPermissions',
      type: 'text',
      label: 'Messages Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.MESSAGES',
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'schedulePermissions',
      type: 'text',
      label: 'Schedule Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.SCHEDULE',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'activityLogPermissions',
      type: 'text',
      label: 'Activity Log Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.ACTIVITY_LOG',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'bookingPermissions',
      type: 'text',
      label: 'Booking Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.BOOKING',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'archivePermissions',
      type: 'text',
      label: 'Archive Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.ARCHIVE',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'reconiliationPermissions',
      type: 'text',
      label: 'Reconciliation Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.RECONCILIATION',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'mapPermissions',
      type: 'text',
      label: 'Map Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.MAP',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'incidentReportPermissions',
      type: 'text',
      label: 'Incident Report Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.INCIDENT_REPORT',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }, {
      id: 'reportsPermissions',
      type: 'text',
      label: 'Reports Permissions',
      valuePath: 'rolePermissionsGroupByCatgories.REPORTS',
      searchable: true,
      resizable: true,
      minResizeWidth: 50,
      hidden: false,
      csvReplaceRegex: {
        pattern: '\\/',
        flag: 'g',
        replaceWith: '|'
      }
    }]
  };
  _exports.default = _default;
});