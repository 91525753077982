define("adept-iq/config/widget-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WidgetEvents = _exports.WIDGET_MINIMIZE_EVENT = _exports.WIDGET_MAXIMIZE_EVENT = _exports.WIDGET_DATA_ACTION = _exports.REMOVE_TRAVERSAL_SELECTION_EVENT = _exports.RECEIVE_DATA_LIST_EVENT = _exports.LOAD_MORE_DATA_LIST_EVENT = _exports.INITIAL_WIDGET_LOAD_EVENT = _exports.DESELECT_ALL_WIDGET_EVENT = _exports.CORE_ENTITY_SELECTION_EVENT = _exports.ADD_TRAVERSAL_SELECTION_EVENT = void 0;
  const WIDGET_DATA_ACTION = 'WidgetData'; // update , add, delete filter

  _exports.WIDGET_DATA_ACTION = WIDGET_DATA_ACTION;
  const RECEIVE_DATA_LIST_EVENT = 'ReceiveDataList'; // initial load, filter , sorting

  _exports.RECEIVE_DATA_LIST_EVENT = RECEIVE_DATA_LIST_EVENT;
  const INITIAL_WIDGET_LOAD_EVENT = 'InitialWidgetLoadEvent';
  _exports.INITIAL_WIDGET_LOAD_EVENT = INITIAL_WIDGET_LOAD_EVENT;
  const LOAD_MORE_DATA_LIST_EVENT = 'LoadMoreDataList'; // pagination

  _exports.LOAD_MORE_DATA_LIST_EVENT = LOAD_MORE_DATA_LIST_EVENT;
  const ADD_TRAVERSAL_SELECTION_EVENT = 'AddTraversalFilter'; // when user selects a checkbox

  _exports.ADD_TRAVERSAL_SELECTION_EVENT = ADD_TRAVERSAL_SELECTION_EVENT;
  const REMOVE_TRAVERSAL_SELECTION_EVENT = 'RemoveTraversalFilter'; // when user de-selects a checkbox

  _exports.REMOVE_TRAVERSAL_SELECTION_EVENT = REMOVE_TRAVERSAL_SELECTION_EVENT;
  const WIDGET_MAXIMIZE_EVENT = 'MaximizeWidget';
  _exports.WIDGET_MAXIMIZE_EVENT = WIDGET_MAXIMIZE_EVENT;
  const WIDGET_MINIMIZE_EVENT = 'MinimizeWidget';
  _exports.WIDGET_MINIMIZE_EVENT = WIDGET_MINIMIZE_EVENT;
  const DESELECT_ALL_WIDGET_EVENT = 'DeselectAllWidgetEvent';
  _exports.DESELECT_ALL_WIDGET_EVENT = DESELECT_ALL_WIDGET_EVENT;
  const CORE_ENTITY_SELECTION_EVENT = 'CoreEntitySelectionEvent';
  _exports.CORE_ENTITY_SELECTION_EVENT = CORE_ENTITY_SELECTION_EVENT;
  const WidgetEvents = {
    INITIAL_WIDGET_LOAD_EVENT,
    WIDGET_DATA_ACTION,
    RECEIVE_DATA_LIST_EVENT,
    LOAD_MORE_DATA_LIST_EVENT,
    ADD_TRAVERSAL_SELECTION_EVENT,
    REMOVE_TRAVERSAL_SELECTION_EVENT,
    WIDGET_MAXIMIZE_EVENT,
    WIDGET_MINIMIZE_EVENT,
    DESELECT_ALL_WIDGET_EVENT,
    CORE_ENTITY_SELECTION_EVENT
  };
  _exports.WidgetEvents = WidgetEvents;
});