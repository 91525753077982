define("adept-iq/pods/components/reoptimize-modal/operation-views/illegal-to-waitlist/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['illegal-to-waitlist'],
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    scheduling: Ember.inject.service(),
    // Passed In Attributes
    title: 'Illegal to Waitlist',
    updating: false,
    isTaskRunning: Ember.computed.alias('updating'),

    init() {
      this._super(...arguments);
    },

    /**
     *update illegal trips to waitlisted trips
     *
     */
    updateIllegalTripsToWaitlistTrips: (0, _emberConcurrency.task)(function* (scheduleId) {
      try {
        this.set('updating', true);
        const success = yield this.get('scheduling').updateIllegalTripsToWaitlistTrips(scheduleId);

        if (success) {
          this.get('notifications').success('Successfully set the status of all illegal trips to waitlisted');
        } else {
          throw new Error();
        }
      } catch (e) {
        this.get('notifications').warning('Failed to set the status of all illegal trips to waitlisted');
        throw e;
      } finally {
        this.set('updating', false);
      }
    }).drop(),
    actions: {
      onUpdateClick() {
        const currentScheduleId = this.get('workspace').get('currentSchedule.id');
        this.updateIllegalTripsToWaitlistTrips.perform(currentScheduleId);
      }

    }
  });

  _exports.default = _default;
});