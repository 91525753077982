define("adept-iq/pods/components/side-drawer/reconcile-reopen-dates/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: 'Reconcile',
    reopenDatesTableColumns: [{
      label: 'Dates',
      valuePath: 'plannedStartTime',
      resizable: true,
      minResizeWidth: 50
    }, {
      label: 'Reopen',
      resizable: false,
      minResizeWidth: 50,
      cellComponent: 'generic-components/table/cells/check-box'
    }]
  };
  _exports.default = _default;
});