define("adept-iq/pods/components/side-drawer/perform-stop/pick/travel-needs-picker/component", ["exports", "adept-iq/models/travel-need-type", "lodash"], function (_exports, _travelNeedType, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TravelNeedItem = Ember.Object.extend({
    type: null,
    count: null
  });
  const minimumTravelNeedCount = 0;
  const maximumTravelNeedCount = 20;

  var _default = Ember.Component.extend({
    classNames: ['travel-needs-picker'],
    pcaCount: null,
    serviceAnimalCount: null,
    companionCount: null,
    travelNeedOptions: null,
    travelNeedItems: null,
    minCompanionCount: 0,
    maxCompanionCount: 10,
    stopPoint: null,
    dispatchRoute: Ember.computed.readOnly('stopPoint.dispatchRoute'),
    rider: Ember.computed.readOnly('trip.rider'),
    routeVehicleDriver: Ember.computed.readOnly('dispatchRoute.latestRouteVehicleDriver'),
    vehicle: Ember.computed.readOnly('routeVehicleDriver.vehicle'),
    currentCapacity: Ember.computed('availableCapacity', function () {
      const availableCapacity = this.get('availableCapacity');
      let ambulatory = availableCapacity && availableCapacity.ambulatory ? availableCapacity.ambulatory.count : 0;
      let wheelchair = availableCapacity && availableCapacity.wheelchair ? availableCapacity.wheelchair.count : 0;

      if (ambulatory > 0) {
        ambulatory = `+${ambulatory}`;
      }

      if (wheelchair > 0) {
        wheelchair = `+${wheelchair}`;
      }

      return {
        ambulatory,
        wheelchair
      };
    }),
    ambulatoryOption: Ember.computed('travelNeedOptions', function () {
      return this.get('travelNeedOptions').filter(option => option.type === _travelNeedType.AMBULATORY_TRAVELNEED_TYPE) || [];
    }),
    wheelchairOption: Ember.computed('travelNeedOptions', function () {
      return this.get('travelNeedOptions').filter(option => option.type === _travelNeedType.WHEELCHAIR_TRAVELNEED_TYPE) || [];
    }),
    notEnoughTravelNeeds: Ember.computed('ambulatoryTravelNeed.count', 'wheelchairTravelNeed.count', 'pcaCount', 'companionCount', function () {
      const pcaCount = this.get('pcaCount') || 0;
      const companionCount = this.get('companionCount') || 0;
      const clientCount = 1; // minimum of 1 client

      const ambulatoryTravelNeed = this.get('ambulatoryTravelNeed');
      const wheelchairTravelNeed = this.get('wheelchairTravelNeed');
      return pcaCount + companionCount + clientCount > ambulatoryTravelNeed.count + wheelchairTravelNeed.count;
    }),
    ambulatoryTravelNeed: Ember.computed('travelNeedItems.[]', function () {
      const travelNeedItems = this.get('travelNeedItems') || [];
      const ambulatoryTravelNeedItem = travelNeedItems.find(item => item.type === _travelNeedType.AMBULATORY_TRAVELNEED_TYPE);

      if (Ember.isNone(ambulatoryTravelNeedItem)) {
        return this.addTravelNeedItem(_travelNeedType.AMBULATORY_TRAVELNEED_TYPE);
      }

      return ambulatoryTravelNeedItem;
    }),
    wheelchairTravelNeed: Ember.computed('travelNeedItems.[]', function () {
      const travelNeedItems = this.get('travelNeedItems') || [];
      const wheelchairTravelNeedItem = travelNeedItems.find(item => item.type === _travelNeedType.WHEELCHAIR_TRAVELNEED_TYPE);

      if (Ember.isNone(wheelchairTravelNeedItem)) {
        return this.addTravelNeedItem(_travelNeedType.WHEELCHAIR_TRAVELNEED_TYPE);
      }

      return wheelchairTravelNeedItem;
    }),

    setMinMaxTravelNeedCount(travelNeedItem) {
      // create min count for each travel need
      travelNeedItem.set('minCount', minimumTravelNeedCount); // create max count for each travel need

      travelNeedItem.set('maxCount', maximumTravelNeedCount);
    },

    addTravelNeedItem(type) {
      const travelNeedItems = this.get('travelNeedItems') || [];
      const travelNeedItem = TravelNeedItem.create({
        type: type,
        count: 0,
        oldCount: 0
      });
      travelNeedItems.pushObject(travelNeedItem);
      this.setMinMaxTravelNeedCount(travelNeedItem);
      return travelNeedItem;
    },

    actions: {
      onTravelNeedChange(travelNeed, event) {
        const value = event.currentTarget.value ? parseInt(event.currentTarget.value, 10) : null;
        const minCount = travelNeed.get('minCount');
        const maxCount = travelNeed.get('maxCount');

        if (Ember.isNone(value)) {
          travelNeed.set('count', value);
        } else if (value > maxCount) {
          travelNeed.set('count', maxCount);
        } else if (value < minCount) {
          travelNeed.set('count', minCount);
        } else travelNeed.set('count', value);

        const count = travelNeed.get('count');
        event.currentTarget.value = count;
        const stopPoint = this.get('stopPoint');

        if (stopPoint) {
          const ambulatoryTravelNeed = this.get('ambulatoryTravelNeed');
          const wheelchairTravelNeed = this.get('wheelchairTravelNeed');
          const ambNeedCount = Ember.isNone(ambulatoryTravelNeed.count) ? 0 : ambulatoryTravelNeed.count;
          const wcNeedCount = Ember.isNone(wheelchairTravelNeed.count) ? 0 : wheelchairTravelNeed.count;
          let customTravelNeeds = null;

          if (ambNeedCount > 0 || wcNeedCount > 0) {
            customTravelNeeds = _lodash.default.cloneDeep(this.get('stopPoint.trip.travelNeedCounts'));
            customTravelNeeds.ambulatory = ambNeedCount;
            customTravelNeeds.wheelchair = wcNeedCount;
          }

          this.getVehicleCapacityAvailable(customTravelNeeds);
        }
      },

      onCompanionChange(event) {
        const value = event.currentTarget.value ? parseInt(event.currentTarget.value, 10) : null;
        const minCount = this.get('minCompanionCount');
        const maxCount = this.get('maxCompanionCount');

        if (Ember.isNone(value)) {
          this.set('companionCount', value);
        } else if (value > maxCount) {
          this.set('companionCount', maxCount);
        } else if (value < minCount) {
          this.set('companionCount', minCount);
        } else this.set('companionCount', value);

        const companionCount = this.get('companionCount');
        this.companionChanged(companionCount);
        event.currentTarget.value = companionCount;
      },

      onPCAToggle(event) {
        this.set('pcaCount', event.target.checked ? 1 : 0);
      },

      onServiceAnimalToggle(event) {
        this.set('serviceAnimalCount', event.target.checked ? 1 : 0);
      }

    }
  });

  _exports.default = _default;
});