define("adept-iq/pods/components/reoptimize-modal/operation-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2kQpzNIq",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"generic-components/vertical-menu\",null,[[\"models\",\"selectedIndex\",\"disableList\",\"onItemSelect\"],[[23,[\"models\"]],0,[23,[\"disableList\"]],[27,\"action\",[[22,0,[]],\"onItemSelect\"],null]]]],false],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"operation-summary\"],[9],[0,\"\\n\"],[1,[23,[\"selectedModel\",\"summary\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"selectedModel\",\"summary1\"]]],null,{\"statements\":[[7,\"p\"],[11,\"class\",\"operation-summary\"],[9],[0,\"\\n\"],[1,[23,[\"selectedModel\",\"summary1\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/reoptimize-modal/operation-list/template.hbs"
    }
  });

  _exports.default = _default;
});