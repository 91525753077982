define("adept-iq/models/avl-location", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    lat: attr('number'),
    lng: attr('number'),
    heading: attr('number'),
    speed: attr('number'),
    odo: attr('number'),
    timestamp: attr('date')
  });

  _exports.default = _default;
});