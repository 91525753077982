define("adept-iq/pods/components/side-drawer/map-label-preview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['map-label-preview'],
    labels: null,
    // This computed property is required, as Ember is not updating the list order
    // when the passed in `labels` property changes order.
    labelData: Ember.computed('labels', 'labels.@each.index', function () {
      return this.get('labels');
    })
  });

  _exports.default = _default;
});