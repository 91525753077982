define("adept-iq/pods/components/side-drawer/reconcile-rescue-route/stubs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.driverVehicleMockData = void 0;
  const driverVehicleMockData = [{
    id: 400022
  }, {
    id: 400023
  }, {
    id: 400024
  }, {
    id: 400025
  }, {
    id: 400026
  }, {
    id: 400027
  }, {
    id: 400028
  }, {
    id: 400029
  }, {
    id: 400030
  }, {
    id: 400031
  }];
  _exports.driverVehicleMockData = driverVehicleMockData;
});