define("adept-iq/scenarios/scheduling-service", ["exports", "ember-data-factory-guy"], function (_exports, _emberDataFactoryGuy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Just for fun, set the log level ( to 1 ) and see all FactoryGuy response info in console
  _emberDataFactoryGuy.Scenario.settings({
    logLevel: 0 // 1 is the max for now, default is 0

  });

  class _default extends _emberDataFactoryGuy.Scenario {
    run() {
      const provider = this.store.peekAll('provider').firstObject;
      const metric = this.store.peekAll('metric').firstObject;
      const scheduleConfiguration = this.store.peekAll('schedule-configuration').firstObject;
      const breakType = this.store.peekAll('break-type').findBy('name', 'floating');
      const schedule = (0, _emberDataFactoryGuy.make)('schedule', {
        scheduleConfiguration,
        metric
      });
      const vehicles = this.store.peekAll('vehicle');
      const bookings = this.store.peekAll('booking').toArray();
      vehicles.forEach((vehicle, index) => {
        const route = (0, _emberDataFactoryGuy.make)('route', {
          schedule,
          provider,
          vehicle
        });
        const place = (0, _emberDataFactoryGuy.make)('place');
        const address = (0, _emberDataFactoryGuy.make)('address', {
          places: [place]
        });
        (0, _emberDataFactoryGuy.make)('location', {
          address: address,
          places: [place]
        });
        const booking = bookings[index];
        const subscription = booking.get('subscription.content');
        const leg = booking.get('legs').firstObject;
        const segments = leg.get('segments.content');
        segments.forEach(segment => {
          let trip = null;

          if (subscription) {
            trip = (0, _emberDataFactoryGuy.make)('trip', {
              schedule,
              route,
              booking,
              segment,
              subscription
            });
          } else trip = (0, _emberDataFactoryGuy.make)('trip', {
            schedule,
            route,
            booking,
            segment
          });

          const pickSegmentStop = segment.get('pick.content');
          const dropSegmentStop = segment.get('drop.content');
          (0, _emberDataFactoryGuy.make)('trip-stop', {
            pickTripStop: trip,
            segmentStop: pickSegmentStop
          });
          (0, _emberDataFactoryGuy.make)('trip-stop', {
            dropTripStop: trip,
            segmentStop: dropSegmentStop
          });
          (0, _emberDataFactoryGuy.make)('trip-route-assignment', {
            trip: trip,
            route: route
          });
        });
        (0, _emberDataFactoryGuy.make)('route-break', {
          route,
          place,
          breakType
        });
        (0, _emberDataFactoryGuy.make)('shift-break', {
          vehicle,
          place,
          breakType
        });
      });
    }

  }

  _exports.default = _default;
});