define("adept-iq/pods/components/form-components/text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SPECIAL_CHARACTER_FORMAT = /[^a-zA-Z0-9]/g;
  const SPACE_FORMAT = /\s/g;
  const NUMBER_FORMAT = /[^0-9]/g;

  var _default = Ember.Component.extend({
    classNames: ['form-components-text'],
    value: null,
    disabled: false,
    placeholder: null,
    extra: null,
    max: Ember.computed.readOnly('extra.max'),
    maxWithSpace: Ember.computed.readOnly('extra.maxWithSpace'),
    removeSpecialCharacter: Ember.computed.readOnly('extra.removeSpecialCharacter'),
    removeCharacter: Ember.computed.readOnly('extra.removeCharacter'),
    removeSpace: Ember.computed.readOnly('extra.removeSpace'),
    actions: {
      onInput: function (event) {
        const max = this.get('max');
        const maxWithSpace = this.get('maxWithSpace');
        const removeSpecialCharacter = this.get('removeSpecialCharacter');
        const removeCharacter = this.get('removeCharacter');
        const removeSpace = this.get('removeSpace');
        let textVal = event.target.value;

        if (maxWithSpace) {
          textVal = event.target.value.slice(0, maxWithSpace);
          event.target.value = textVal;
        }

        if (removeSpecialCharacter) {
          textVal = event.target.value.replace(SPECIAL_CHARACTER_FORMAT, '');
          event.target.value = textVal;
        }

        if (removeCharacter) {
          textVal = event.target.value.replace(NUMBER_FORMAT, '');
          event.target.value = textVal;
        }

        if (removeSpace) {
          textVal = event.target.value.replace(SPACE_FORMAT, '');
          event.target.value = textVal;
        }

        if (max) {
          textVal = event.target.value.slice(0, max);
          event.target.value = textVal;
        }

        this.get('onChange')(textVal);
      }
    }
  });

  _exports.default = _default;
});