define("adept-iq/pods/components/iq-widgets/trips-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/trips-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'trip',
    defaultSortId: 'name',
    defaultSortAsc: false,
    title: 'Trips',
    // ember-react-widget properties
    widgetName: 'tripWidget',
    selectedRowLimit: 50,
    maxLimitByRouteSelection: 5,
    maxLimitWithoutRouteAssociation: 20,
    restrictSwapColumns: false,
    columns: [{
      id: 'name',
      index: 3,
      type: 'uuid',
      label: 'ID',
      valuePath: 'tripId',
      valuePreview: '1',
      hidden: false,
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'riderFirstName',
      index: 0,
      type: 'text',
      label: 'Passenger First Name',
      valuePath: 'passengerFirstName',
      mapValuePath: 'rider.firstName',
      hidden: false,
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'riderLastName',
      index: 1,
      type: 'text',
      label: 'Passenger Last Name',
      valuePath: 'passengerLastName',
      mapValuePath: 'rider.lastName',
      hidden: false,
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'riderPhone',
      type: 'enum',
      index: 2,
      label: 'Passenger Phone Number',
      valuePath: 'legPhoneNumber',
      mapValuePath: 'rider.formattedPhoneNumbers',
      hidden: true,
      defaultWidth: 70
    }, {
      id: 'externalId',
      index: 11,
      type: 'uuid',
      label: 'Passenger ID',
      valuePath: 'passengerId',
      mapValuePath: 'rider.riderId',
      editable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'tripType',
      index: 9,
      type: 'text',
      label: 'Type',
      valuePath: 'type',
      editable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'routeName',
      index: 4,
      type: 'uuid',
      label: 'Route Name',
      valuePath: 'routeName',
      mapValuePath: 'currentRouteName',
      hidden: false,
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'status',
      index: 5,
      type: 'text',
      label: 'Status',
      valuePath: 'tripStatus',
      mapValuePath: 'computedStatus',
      hidden: false,
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 75
    }, {
      id: 'statusReason',
      index: 6,
      type: 'text',
      label: 'Status Reason',
      valuePath: 'statusReasonDisplay',
      customSortPath: 'statusReason',
      hidden: false,
      defaultWidth: 75,
      cellItem: 'StatusReasonCell'
    }, {
      id: 'statusNotes',
      index: 35,
      type: 'text',
      label: 'Status Notes',
      valuePath: 'statusNotes',
      hidden: true,
      defaultWidth: 75
    }, {
      id: 'otpStatus',
      index: 7,
      type: 'text',
      label: 'OTP Status',
      valuePath: 'otpStatus',
      mapValuePath: 'otpLabel',
      valuePreview: 'On Time',
      hidden: false,
      searchable: true,
      highlightable: true,
      cellItem: 'OTPValueCellItem'
    }, {
      id: 'otp',
      index: 29,
      type: 'number',
      label: 'OTP',
      valuePath: 'otpWithSign',
      mapValuePath: 'otpValueWithSign',
      customSortPath: 'otp',
      hidden: true,
      highlightable: true
    }, {
      id: 'promisedTime',
      index: 8,
      type: 'time',
      label: 'Promise Time',
      valuePath: 'promiseTime',
      valuePreview: '09:00',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 50,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'requestedTime',
      index: 10,
      type: 'time',
      label: 'Request Time',
      valuePath: 'requestTime',
      mapValuePath: 'segment.leg.requestTime',
      valuePreview: '08:00',
      editable: true,
      hidden: false,
      format: 'HH:mm',
      defaultWidth: 50,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'anchorETA',
      index: 12,
      type: 'time',
      label: 'Anchor ETA',
      valuePath: 'anchorEta',
      mapValuePath: 'anchorStop.stopPoint.etaNavStopPoint.eta',
      valuePreview: '16',
      hidden: true,
      format: 'HH:mm',
      defaultWidth: 50,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'noShowTime',
      index: 40,
      type: 'time',
      label: 'No Show Time',
      valuePath: 'noShowTime',
      mapValuePath: 'pickStopPoint.noShowTime',
      valuePreview: '08:00',
      editable: true,
      hidden: false,
      format: 'HH:mm',
      defaultWidth: 50,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'loadTime',
      index: 45,
      type: 'number',
      label: 'Load Time',
      valuePath: 'loadTime',
      editable: true,
      hidden: false,
      defaultWidth: 60
    }, {
      id: 'unloadTime',
      index: 46,
      type: 'number',
      label: 'Unload Time',
      valuePath: 'unloadTime',
      editable: true,
      hidden: false,
      defaultWidth: 60
    }, {
      id: 'serviceStartTime',
      index: 13,
      type: 'time',
      label: 'Service Start Time',
      valuePath: 'serviceStartTime',
      valuePreview: '?',
      editable: true,
      hidden: true,
      format: 'HH:mm',
      defaultWidth: 50,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'serviceEndTime',
      index: 14,
      type: 'time',
      label: 'Service End Time',
      valuePath: 'serviceEndTime',
      valuePreview: '?',
      editable: true,
      hidden: true,
      format: 'HH:mm',
      defaultWidth: 50,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'anchor',
      index: 15,
      type: 'text',
      label: 'Anchor',
      valuePath: 'anchor',
      mapValuePath: 'segment.leg.anchor',
      valuePreview: 'Pick',
      searchable: true,
      editable: true,
      hidden: false,
      defaultWidth: 60
    }, {
      id: 'pickupaddress',
      index: 16,
      type: 'text',
      label: 'Pickup Address',
      valuePath: 'pickAddress',
      mapValuePath: 'segment.pick.place.address.simpleAddress',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'pickupCity',
      index: 17,
      type: 'text',
      label: 'Pickup City',
      valuePath: 'pickCity',
      mapValuePath: 'segment.pick.place.address.locality',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'pickupCounty',
      index: 18,
      type: 'text',
      label: 'Pickup County',
      valuePath: 'pickCounty',
      mapValuePath: 'segment.pick.place.address.subRegion',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'pickupZone',
      index: 19,
      type: 'text',
      label: 'Pickup Zone',
      valuePath: 'pickZoneConf.nameToDisplay',
      mapValuePath: 'segment.pick.place.address.zoneName.displayName',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'dropaddress',
      index: 20,
      type: 'text',
      label: 'Drop off Address',
      valuePath: 'dropAddress',
      mapValuePath: 'segment.drop.place.address.simpleAddress',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'dropCity',
      index: 21,
      type: 'text',
      label: 'Drop off City',
      valuePath: 'dropCity',
      mapValuePath: 'segment.drop.place.address.locality',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'dropCounty',
      index: 22,
      type: 'text',
      label: 'Drop off County',
      valuePath: 'dropCounty',
      mapValuePath: 'segment.drop.place.address.subRegion',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'dropoffZone',
      index: 23,
      type: 'text',
      label: 'Drop off Zone',
      valuePath: 'dropZoneConf.nameToDisplay',
      mapValuePath: 'segment.drop.place.address.zoneName.displayName',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'passengerNotes',
      index: 24,
      type: 'text',
      label: 'Passenger Notes',
      valuePath: 'passengerNotes',
      mapValuePath: 'rider.notes',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'notes',
      index: 25,
      type: 'text',
      label: 'Driver Notes',
      valuePath: 'legNotes',
      mapValuePath: 'driver.notes',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'travelNeeds',
      index: 26,
      type: 'enum',
      label: 'Travel Needs',
      valuePath: 'travelNeeds',
      //This need to be special handled.
      mapValuePath: 'formattedTravelNeeds',
      cellDesc: 'Travel Needs',
      editable: false,
      hidden: true,
      cellItem: 'TravelNeedsCell'
    }, // STUB FOR AVA DEMO
    {
      id: 'plannedMiles',
      index: 27,
      type: 'number',
      label: 'Planned Trip Miles',
      valuePath: 'plannedTripMiles',
      mapValuePath: 'plannedMiles',
      editable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'fare',
      index: 28,
      type: 'number',
      label: 'Fare',
      valuePath: 'fare',
      mapValuePath: 'segment.fare',
      editable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'paymentType',
      index: 30,
      type: 'text',
      label: 'Payment Type',
      valuePath: 'paymentType',
      mapValuePath: 'segment.fareType.name',
      valuePreview: 'Single',
      editable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'purpose',
      index: 31,
      type: 'text',
      label: 'Purpose',
      valuePath: 'tripPurposeNameToDisplay',
      mapValuePath: 'leg.tripPurpose.name',
      valuePreview: 'Single',
      editable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'provider',
      index: 32,
      type: 'string',
      label: 'Provider',
      valuePath: 'provider',
      mapValuePath: 'providerName',
      valuePreview: 'MAG',
      hidden: true,
      searchable: true,
      highlightable: true
    }, {
      id: 'serviceMode',
      index: 33,
      type: 'string',
      label: 'Service Mode',
      valuePath: 'serviceMode',
      mapValuePath: 'segment.travelMode',
      hidden: true,
      highlightable: true
    }, {
      id: 'previousRouteName',
      index: 41,
      type: 'uuid',
      label: 'Previous Route Name',
      valuePath: 'previousRouteName',
      mapValuePath: 'previousRouteAssignment.route.name',
      hidden: true,
      highlightable: false,
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'mode',
      index: 42,
      type: 'text',
      label: 'Scheduling Mode',
      valuePath: 'schedulingMode',
      //missing
      mapValuePath: 'schedulingMode',
      hidden: true,
      searchable: false,
      highlightable: false
    }, {
      id: 'brokerEligible',
      index: 43,
      type: 'boolean',
      label: 'Broker Eligible',
      valuePath: 'brokerEligible',
      //missing
      searchable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'bookingId',
      index: 44,
      type: 'uuid',
      label: 'Booking ID',
      valuePath: 'bookingId',
      editable: true,
      valuePreview: '1',
      hidden: false,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'ambSeats',
      type: 'number',
      label: 'Amb Seats',
      valuePath: 'ambSeats',
      mapValuePath: 'travelNeedCounts.ambulatory',
      editable: false,
      hidden: true,
      defaultWidth: 66
    }, {
      id: 'wcSeats',
      type: 'number',
      label: 'WC Seats',
      valuePath: 'wcSeats',
      mapValuePath: 'travelNeedCounts.wheelchair',
      editable: false,
      hidden: true,
      defaultWidth: 66
    }, {
      id: 'passenger',
      type: 'number',
      label: 'Passengers',
      valuePath: 'passengers',
      editable: false,
      hidden: true,
      defaultWidth: 66
    }, // // temporary remove these columns aiq-2640
    // {
    //   id: 'companion',
    //   type: 'number',
    //   label: 'Companions',
    //   valuePath: 'companions',
    //   editable: false,
    //   hidden: true,
    //   defaultWidth: 66
    // },
    // {
    //   id: 'pca',
    //   type: 'number',
    //   label: 'PCA',
    //   valuePath: 'pca',
    //   editable: false,
    //   hidden: true,
    //   defaultWidth: 66
    // },
    {
      id: 'rideShare',
      type: 'text',
      label: 'Rideshare',
      valuePath: 'noSharingValue',
      mapValuePath: 'segment.noSharingText',
      editable: false,
      hidden: true,
      defaultWidth: 70
    }, {
      id: 'serviceNeeds',
      type: 'enum',
      label: 'Service Needs',
      valuePath: 'serviceNeeds',
      mapValuePath: 'formattedServiceNeeds',
      cellDesc: 'Service Needs',
      editable: false,
      hidden: true,
      cellItem: 'ServiceNeedsCell',
      arraySortPath: 'firstObject.name'
    }, {
      id: 'maxRideTime',
      type: 'number',
      label: 'Max Ride Time',
      valuePath: 'maxRideTime',
      mapValuePath: 'maxRideTime',
      hidden: true,
      defaultWidth: 66
    }, {
      id: 'fundingAgency',
      type: 'text',
      label: 'Funding Agency',
      valuePath: 'fundingAgencyDisplayName',
      mapValuePath: 'fundingAgencyDisplayName',
      hidden: true,
      highlightable: false,
      searchable: true,
      allowDisplayAttr: 'allowFundingAgency',
      defaultWidth: 150
    }],
    traversal: {
      stopWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'tripPk'
        }]
      },
      routeWidget: {
        links: [{
          type: 'eq',
          field: 'routeId',
          valuePath: 'routeId'
        }]
      },
      alertWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'tripPk'
        }]
      },
      vehicleWidget: {
        links: [{
          type: 'stringEq',
          field: 'vehicleId',
          valuePath: 'id'
        }]
      },
      messageWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'tripPk'
        }, {
          type: 'stringEq',
          field: 'routeName',
          valuePath: 'routeName'
        }]
      },
      riderWidget: {
        links: [{
          type: 'eq',
          field: 'passengerPk',
          valuePath: 'id'
        }]
      }
    },
    // TODO: fix implicit filter
    implicit: {
      conditions: [{
        type: 'neRQL',
        field: 'scheduleType',
        value: ['booking']
      }, {
        type: 'neRQL',
        field: 'pickStopPointId',
        value: [null]
      }]
    }
  };
  _exports.default = _default;
});