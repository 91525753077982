define("adept-iq/pods/reset-password/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    errors: Ember.computed.alias('data.errors'),
    success: Ember.computed.alias('data.success')
  });

  _exports.default = _default;
});