define("adept-iq/pods/components/help-link/component", ["exports", "adept-iq/utils/help-page-redirect"], function (_exports, _helpPageRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['help-link-component'],
    componentName: null,
    actions: {
      onHelpButtonClick() {
        (0, _helpPageRedirect.helpRedirect)(this.get('componentName'));
      }

    }
  });

  _exports.default = _default;
});