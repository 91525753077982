define("adept-iq/pods/components/iq-widgets/map-widget/config/polygon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    solid: {
      dashArray: null,
      color: '#97a3a9',
      opacity: 0.5,
      fillOpacity: 0.2
    },
    solidLate: {
      dashArray: null,
      // matches 'text-danger' in CSS
      color: 'orange',
      opacity: 0.5,
      fillOpacity: 0.2
    },
    solidDanger: {
      dashArray: null,
      color: 'red',
      opacity: 0.5,
      fillOpacity: 0.2
    },
    normal: {
      dashArray: 'null',
      color: '#97a3a9',
      opacity: 0.1,
      fillOpacity: 0
    },
    medium: {
      dashArray: 'null',
      color: 'orange',
      opacity: 0.1,
      fillOpacity: 0.05
    },
    high: {
      dashArray: 'null',
      color: 'red',
      opacity: 0.1,
      fillOpacity: 0.05
    }
  };
  _exports.default = _default;
});