define("adept-iq/models/cs-config-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ELIGIBILITY_CONDITIONS = _exports.ELIGIBILITY_CATEGORY_TYPES = _exports.CONFIG_ELIGIBILITY_CONDITIONS = _exports.CONFIG_ELIGIBILITY_CATEGORY = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  const CONFIG_ELIGIBILITY_CATEGORY = 'config-System_Configuration-eligibility_categories';
  _exports.CONFIG_ELIGIBILITY_CATEGORY = CONFIG_ELIGIBILITY_CATEGORY;
  const CONFIG_ELIGIBILITY_CONDITIONS = 'config-System_Configuration-eligibility_conditions';
  _exports.CONFIG_ELIGIBILITY_CONDITIONS = CONFIG_ELIGIBILITY_CONDITIONS;
  const ELIGIBILITY_CATEGORY_TYPES = {
    FULL: 'full',
    DENY: 'deny',
    CONDITIONAL: 'conditional'
  };
  _exports.ELIGIBILITY_CATEGORY_TYPES = ELIGIBILITY_CATEGORY_TYPES;
  const ELIGIBILITY_CONDITIONS = {
    CITY_BLOCKS_1_TO_2: '1 TO 2 CITY BLOCKS',
    CITY_BLOCKS_3_TO_4: '3 TO 4 CITY BLOCKS',
    CITY_BLOCKS_5_MORE: '5 OR MORE CITY BLOCKS',
    CITY_BLOCKS_LESS_THAN_1: 'LESS THAN 1 CITY BLOCK',
    EXTREME_COLD: 'EXTREME COLD',
    EXTREME_HEAT: 'EXTREME HEAT',
    STAIR_RESTRICTED: 'STAIRS RESTRICTED'
  };
  _exports.ELIGIBILITY_CONDITIONS = ELIGIBILITY_CONDITIONS;

  var _default = Model.extend({
    name: attr('string'),
    displayName: attr('string', {
      defaultValue: ''
    }),
    path: attr('string'),
    depth: attr('number'),
    canDisplay: attr('boolean'),
    canAdd: attr('boolean'),
    canDelete: attr('boolean'),
    minimumItem: attr('number'),
    parent: belongsTo('cs-config-category', {
      inverse: 'children'
    }),
    children: hasMany('cs-config-category', {
      inverse: 'parent'
    }),
    configItems: hasMany('cs-config-item'),
    categoryChain: Ember.computed('parent.categoryChain', function () {
      let categoryChain = [];
      const parent = this.get('parent');

      if (parent && parent.get('id')) {
        categoryChain = parent.get('categoryChain');
      }

      categoryChain.push(this);
      return categoryChain;
    })
  });

  _exports.default = _default;
});