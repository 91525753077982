define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/stops-layer", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/map", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/marker", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel"], function (_exports, _react, _map, _api, _marker, _panel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const StopsLayer = () => {
    const {
      map
    } = _react.default.useContext(_map.MapContext);

    const [layer, setLayer] = _react.default.useState();

    const [stopPoints, setStopPoints] = _react.default.useState([]);

    const [vehicles, setVehicles] = _react.default.useState([]);

    const {
      eventData,
      vehicleData
    } = (0, _api.useAPI)();
    const {
      mapEventFilters,
      eventTableColumns,
      vehicleTableColumns,
      selectedRvd
    } = (0, _panel.usePanel)();
    const activeEventFilters = mapEventFilters.filter(f => f.isChecked && (f.stopType || f.actionType));

    const labelGenerator = (labeledColumns, stop) => {
      let label = '';
      labeledColumns.forEach(column => {
        label += `${column.label}: ${stop[column.valuePath]}<br/>`;
      });
      return label;
    };

    _react.default.useEffect(() => {
      if (!map) return;

      const _layer = window.L.layerGroup();

      setLayer(_layer);

      _layer.addTo(map.native);

      return () => map.native.removeLayer(_layer);
    }, [eventData, vehicleData]);

    _react.default.useEffect(() => {
      const _vehiclePoints = [];

      const _vehicles = vehicleData.filter(vehicle => vehicle.displayLat !== 'N/A' && vehicle.displayLng !== 'N/A').map(vehicle => {
        _vehiclePoints.push(window.L.latLng(vehicle.displayLat, vehicle.displayLng));

        return { ...vehicle,
          label: labelGenerator(vehicleTableColumns, vehicle)
        };
      });

      setVehicles(_vehicles);

      if (_vehiclePoints.length > 0) {
        let bounds = window.L.latLngBounds(_vehiclePoints);
        bounds = bounds.pad(0.1);
        map.native.pbounds = bounds;
        map.native.fitBounds(bounds);
      }
    }, [vehicleTableColumns, vehicleData]);

    _react.default.useEffect(() => {
      const labeledColumns = eventTableColumns.filter(column => column.isLabeledInMap);
      const _stopLatLngs = [];

      const _stopPoints = eventData.map((event, index) => {
        return { ...event,
          index
        };
      }).filter(event => event.displayLat !== 'N/A' && event.displayLng !== 'N/A').map(stop => {
        _stopLatLngs.push(window.L.latLng(stop.displayLat, stop.displayLng));

        return { ...stop,
          label: labelGenerator(labeledColumns, stop)
        };
      });

      setStopPoints(_stopPoints);

      if (_stopLatLngs.length > 0) {
        let bounds = window.L.latLngBounds(_stopLatLngs);
        bounds = bounds.pad(0.1);
        map.native.pbounds = bounds;
        map.native.fitBounds(bounds);
      }
    }, [eventTableColumns, eventData]);

    return _react.default.createElement(_react.default.Fragment, null, stopPoints.map(stop => {
      const filter = !stop.actionType || stop.actionType === 'depart' ? activeEventFilters.find(f => f.stopType === stop.stopType) : activeEventFilters.find(f => f.actionType === stop.actionType);

      if (filter) {
        return _react.default.createElement(_marker.default, {
          key: `stop-${filter.id}-${stop._id}`,
          layer: layer,
          position: [stop.displayLat, stop.displayLng],
          popup: stop.label,
          iconCfg: filter.iconCfg,
          pIconCfg: filter.pIconCfg,
          index: stop.index
        });
      }
    }), vehicles.map(vehicle => {
      const filter = activeEventFilters.find(f => f.stopType === 'vehicle'); // After vehicle selected, hide vehicle icons

      if (filter && !selectedRvd.vehicleId) {
        return _react.default.createElement(_marker.default, {
          key: `vehicle-${vehicle._id}`,
          layer: layer,
          position: [vehicle.displayLat, vehicle.displayLng],
          popup: vehicle.label,
          iconCfg: filter.iconCfg,
          vehicleId: vehicle._id
        });
      }
    }));
  };

  var _default = StopsLayer;
  _exports.default = _default;
});