define("adept-iq/services/reconcile-lambda", ["exports", "ember-concurrency", "moment", "adept-iq/config/api-urls"], function (_exports, _emberConcurrency, _moment, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DELAY = 2000;
  const NUM_TRIES = 8;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    apiHost: _apiUrls.API.ReconciliationNewService.host,
    getRouteVehicleDriversByTripDate: (0, _emberConcurrency.task)(function* () {
      const reconcileRVDResponse = null;
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        let apiUrl = `${this.get('apiHost')}/route-vehicle-driver?`;
        apiUrl = this.buildUrlWithStartAndEndDate(apiUrl);
        return yield this.lambdaAsyncOperation.perform(apiUrl);
      } catch (e) {
        this.showErrorWarning(e, 'route-vehicle-driver');
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }

      return reconcileRVDResponse;
    }),
    getReconcileRouteById: (0, _emberConcurrency.task)(function* (routeId) {
      let routePayload = null;
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        let apiUrl = `${this.get('apiHost')}/route?routeId=${routeId}&`;
        apiUrl = this.buildUrlWithStartAndEndDate(apiUrl);
        routePayload = yield this.lambdaAsyncOperation.perform(apiUrl);
      } catch (e) {
        this.showErrorWarning(e, 'route info');
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }

      return routePayload;
    }),
    getReconcileTripsByRouteId: (0, _emberConcurrency.task)(function* (routeId) {
      let reconcileTripsResponse = null;
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        let apiUrl = `${this.get('apiHost')}/trip?routeId=${routeId}&`;
        apiUrl = this.buildUrlWithStartAndEndDate(apiUrl);
        reconcileTripsResponse = yield this.lambdaAsyncOperation.perform(apiUrl);
      } catch (e) {
        this.showErrorWarning(e, 'trips info');
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }

      return reconcileTripsResponse;
    }),
    getReconcileRoutesAndTripsInfoByVehicleId: (0, _emberConcurrency.task)(function* (type, vehicleId) {
      let reconcileVehicleResponse = null;
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        let vehicleApiUrl = `${this.get('apiHost')}/search?type=${type}&vehicleId=${vehicleId}&`;
        vehicleApiUrl = this.buildUrlWithStartAndEndDate(vehicleApiUrl);
        reconcileVehicleResponse = yield this.lambdaAsyncOperation.perform(vehicleApiUrl);
      } catch (e) {
        this.showErrorWarning(e, 'route info');
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }

      return reconcileVehicleResponse;
    }),
    getReconcileRoutesAndTripsInfoByDriverId: (0, _emberConcurrency.task)(function* (type, driverId) {
      let routeAndTripPayload = null;
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        let driverApiUrl = `${this.get('apiHost')}/search?type=${type}&driverId=${driverId}&`;
        driverApiUrl = this.buildUrlWithStartAndEndDate(driverApiUrl);
        routeAndTripPayload = yield this.lambdaAsyncOperation.perform(driverApiUrl);
      } catch (e) {
        this.showErrorWarning(e, 'route info');
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }

      return routeAndTripPayload;
    }),
    getReconcileRoutesAndTripsInfoByTripId: (0, _emberConcurrency.task)(function* (type, tripId) {
      let routeAndTripPayload = null;
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        let tripApiUrl = `${this.get('apiHost')}/search?type=${type}&tripId=${tripId}&`;
        tripApiUrl = this.buildUrlWithStartAndEndDate(tripApiUrl);
        routeAndTripPayload = yield this.lambdaAsyncOperation.perform(tripApiUrl);
      } catch (e) {
        this.showErrorWarning(e, 'route info');
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }

      return routeAndTripPayload;
    }),
    getReconcileRoutesAndTripsInfoByClientId: (0, _emberConcurrency.task)(function* (type, queryParams) {
      let routeAndTripPayload = null;
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        let clientApiUrl = `${this.get('apiHost')}/search?type=${type}&searchFor=client&`;
        Object.entries(queryParams).forEach(_ref => {
          let [cfk, cfv] = _ref;
          clientApiUrl = clientApiUrl + `${cfk}=${cfv}&`;
        });
        clientApiUrl = this.buildUrlWithStartAndEndDate(clientApiUrl);
        routeAndTripPayload = yield this.lambdaAsyncOperation.perform(clientApiUrl);
      } catch (e) {
        this.showErrorWarning(e, 'route info');
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }

      return routeAndTripPayload;
    }),
    getReconcileTripsInfoByClientId: (0, _emberConcurrency.task)(function* (type, clientId) {
      let routeAndTripPayload = null;
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        let tripByClientIdApiUrl = `${this.get('apiHost')}/search?type=${type}&searchFor=trip&clientId=${clientId}&`;
        tripByClientIdApiUrl = this.buildUrlWithStartAndEndDate(tripByClientIdApiUrl);
        routeAndTripPayload = yield this.lambdaAsyncOperation.perform(tripByClientIdApiUrl);
      } catch (e) {
        this.showErrorWarning(e, 'route info');
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }

      return routeAndTripPayload;
    }),
    getReconcileRoutesAndTripsInfoByAddress: (0, _emberConcurrency.task)(function* (type, address) {
      let routeAndTripPayload = null;
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        let addressApiUrl = `${this.get('apiHost')}/search?type=${type}&address=${address}&`;
        addressApiUrl = this.buildUrlWithStartAndEndDate(addressApiUrl);
        routeAndTripPayload = yield this.lambdaAsyncOperation.perform(addressApiUrl);
      } catch (e) {
        this.showErrorWarning(e, 'route info');
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }

      return routeAndTripPayload;
    }),
    getDataFromConfig: (0, _emberConcurrency.task)(function* (category) {
      let configResponse = null;
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        const configApiUrl = `${this.get('apiHost')}/config?category=${category}&`;
        configResponse = yield this.lambdaAsyncOperation.perform(configApiUrl);
      } catch (e) {
        this.showErrorWarning(e, 'config info');
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }

      return configResponse;
    }),
    validateRouteBeforeDeleting: (0, _emberConcurrency.task)(function* (routeId, type) {
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        const validateRouteApiUrl = `${this.get('apiHost')}/route/${routeId}?type=${type}`;
        const response = yield this.ValidateRouteAsyncOperation.perform(validateRouteApiUrl);
        return response;
      } catch (e) {
        this.showErrorWarning(e, 'route validated');
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    }),
    ValidateRouteAsyncOperation: (0, _emberConcurrency.task)(function* (reconcileLamdaAPI) {
      const session = this.get('session');
      const payload = this.buildPayloadWithStartAndEndDate();

      try {
        const response = yield this.get('ajax').patch(reconcileLamdaAPI, {
          method: 'PATCH',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          },
          data: payload
        });
        if (!response) return false;
        return yield this.get('validateRouteLambdaAsyncOperationState').perform(response, payload, reconcileLamdaAPI);
      } catch (e) {
        throw e;
      }
    }).enqueue(),
    validateRouteLambdaAsyncOperationState: (0, _emberConcurrency.task)(function* (response, payload, reconcileLamdaAPI) {
      const session = this.get('session');
      let times = 0;

      while (response && response.data && response.data.length && response.data[0].QueryExecutionId && times++ <= NUM_TRIES) {
        yield (0, _emberConcurrency.timeout)(DELAY);

        if (!payload.queryExecutionId) {
          payload.queryExecutionId = response.data[0].QueryExecutionId;
        }

        try {
          // eslint-disable-next-line no-param-reassign
          response = yield this.get('ajax').request(reconcileLamdaAPI, {
            method: 'PATCH',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: payload
          });
        } catch (e) {
          throw e;
        }
      }

      return response.data;
    }).drop(),
    lambdaAsyncOperation: (0, _emberConcurrency.task)(function* (reconcileLamdaAPI) {
      const session = this.get('session');

      try {
        const response = yield this.get('ajax').request(reconcileLamdaAPI, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        });
        if (!response) return false;
        return yield this.get('lambdaAsyncOperationState').perform(response, reconcileLamdaAPI);
      } catch (e) {
        throw e;
      }
    }).enqueue(),
    lambdaAsyncOperationState: (0, _emberConcurrency.task)(function* (response, reconcileLamdaAPI) {
      const session = this.get('session');
      let times = 0;

      while (response && response.data && response.data.length && response.data[0].QueryExecutionId && times++ <= NUM_TRIES) {
        yield (0, _emberConcurrency.timeout)(DELAY);
        const reconcileLamdaQueryExecutionIdAPI = reconcileLamdaAPI.includes('queryExecutionId') ? reconcileLamdaAPI : reconcileLamdaAPI + `&queryExecutionId=${response.data[0].QueryExecutionId}`;

        try {
          // eslint-disable-next-line no-param-reassign
          response = yield this.get('ajax').request(reconcileLamdaQueryExecutionIdAPI, {
            method: 'GET',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          });
        } catch (e) {
          throw e;
        }
      }

      return response.data;
    }).drop(),

    /* As discussed with backend team , we send an API call to get route and trip Info when we send a request for getting route-vehicle-driver to load the data in Mongo Db. So when we need to get the route info and trip info then the data can be retrieved from the Monge Db instead of S3 which improves the performance.
     * @params Start time, End time, Timezone
     * return { void }
     */
    loadReconcileRoutesInMongoDb: (0, _emberConcurrency.task)(function* () {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const session = this.get('session');

      try {
        let apiUrl = `${this.get('apiHost')}/route?`;
        apiUrl = this.buildUrlWithStartAndEndDate(apiUrl);
        const routeResponse = yield this.get('ajax').request(apiUrl, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        });
        return routeResponse;
      } catch (e) {
        this.showErrorWarning(e, 'routes');
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    }),
    loadReconcileTripsInMongoDb: (0, _emberConcurrency.task)(function* () {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const session = this.get('session');

      try {
        let apiUrl = `${this.get('apiHost')}/trip?`;
        apiUrl = this.buildUrlWithStartAndEndDate(apiUrl);
        return yield this.get('ajax').request(apiUrl, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        });
      } catch (e) {
        this.showErrorWarning(e, 'trips');
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    }),

    // create buildUrlWithStartAndEndDate method to avoid duplication of startDate, endDate, timezone
    buildUrlWithStartAndEndDate(url) {
      const selectedDate = this.get('reconcile.reconcileDate');
      const startDate = (0, _moment.default)(selectedDate).add(0, 'days').startOf('day').toISOString();
      const endDate = (0, _moment.default)(selectedDate).add(0, 'days').endOf('day').toISOString();

      const timezone = _moment.default.tz.guess();

      return url + `startdate=${startDate}&timezone=${timezone}&enddate=${endDate}`;
    },

    buildPayloadWithStartAndEndDate() {
      const selectedDate = this.get('reconcile.reconcileDate');
      const startDate = (0, _moment.default)(selectedDate).add(0, 'days').startOf('day').toISOString();
      const endDate = (0, _moment.default)(selectedDate).add(1, 'days').endOf('day').toISOString();

      const timezone = _moment.default.tz.guess();

      return {
        'startdate': startDate,
        'timezone': timezone,
        'enddate': endDate
      };
    },

    showErrorWarning(e, reconDet) {
      let message;

      switch (e.status) {
        case 400:
          message = 'There was a problem with one of the fields. Please check over and try again.';
          break;

        default:
          message = `There was a problem in getting reconcile ${reconDet}, please try again.`;
          break;
      }

      this.get('notifications').warning(message);
    }

  });

  _exports.default = _default;
});