define("adept-iq/classes/rules/base-rule-context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BaseRuleContext {
    constructor() {
      this.outComeMap = [];
    }
    /**
     *
     * @param ruleName
     * @param result {string}
     */


    recordRuleResult(ruleName, result) {
      this.outComeMap.push({
        ruleName,
        result
      });
    }

  }

  _exports.default = BaseRuleContext;
});