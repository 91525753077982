define("adept-iq/serializers/no-show-reason-code", ["exports", "adept-iq/serializers/cs-config-model"], function (_exports, _csConfigModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigModel.default.extend({
    modelName: 'no-show-reason-code',

    normalizeArrayResponse() {
      const normalized = this._super(...arguments);

      if (!Ember.isEmpty(normalized.data)) {
        normalized.data.forEach(datum => {
          datum.attributes = datum.attributes || {}; // adding specific attribute driverAppDefault for selecting
          // an only default value.
          // This will eventually used by avlm service.

          if (datum.attributes && datum.attributes.driverAppDefault) {
            datum.attributes.driverAppDefault = false;
          }
        });
      }

      return normalized;
    }

  });

  _exports.default = _default;
});