define("adept-iq/tests/factories/location", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const locs = [// stops-pick-P207494508 lat: 40.65954 lng: -73.93221
  {
    lat: 40.65954,
    lng: -73.93221
  }, // stops-drop-D207494508 lat: 40.64108 lng: -73.9456
  {
    lat: 40.64108,
    lng: -73.9456
  }, // stops-drop-D207493009 lat: 40.66288 lng: -73.9325
  {
    lat: 40.66288,
    lng: -73.9325
  }, // stops-pick-P207493009 lat: 40.74082 lng: -73.9838
  {
    lat: 40.74082,
    lng: -73.9838
  }, // stops-drop-D207494359 lat: 40.68936 lng: -73.97942
  {
    lat: 40.68936,
    lng: -73.97942
  }, // stops-pick-P207494359 lat: 40.68448 lng: -73.94628
  {
    lat: 40.68448,
    lng: -73.94628
  }, // stops-pick-P207496170 lat: 40.61552 lng: -73.96913
  {
    lat: 40.61552,
    lng: -73.96913
  }, // stops-drop-D207496170 lat: 40.61545 lng: -73.96126
  {
    lat: 40.61545,
    lng: -73.96126
  }, // stops-pick-P207496912 lat: 40.68431 lng: -73.79013
  {
    lat: 40.68431,
    lng: -73.79013
  }, // stops-drop-D207496912 lat: 40.70408 lng: -74.01318
  {
    lat: 40.70408,
    lng: -74.01318
  }, // stops-pick-P207493284 lat: 40.689 lng: -73.79482
  {
    lat: 40.689,
    lng: -73.79482
  }, // stops-drop-D207493284 lat: 40.70988 lng: -74.01277
  {
    lat: 40.70988,
    lng: -74.01277
  }, // stops-drop-D207495587 lat: 40.68936 lng: -73.97942
  {
    lat: 40.68936,
    lng: -73.97942
  }, // stops-pick-P207495587 lat: 40.63737 lng: -73.94329
  {
    lat: 40.63737,
    lng: -73.94329
  }];

  _emberDataFactoryGuy.default.define('location', {
    default: {
      lat: _emberDataFactoryGuy.default.generate(i => locs[i % locs.length].lat),
      lng: _emberDataFactoryGuy.default.generate(i => locs[i % locs.length].lng),
      geoNode: 5444
    }
  });
});