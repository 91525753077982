define("adept-iq/pods/components/generic-widgets/dummy-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['dummy-widget'],
    classNameBindings: ['color'],
    color: Ember.computed.readOnly('widget.state.color')
  });

  _exports.default = _default;
});