define("adept-iq/pods/components/iq-widgets/passengers-booking-selection-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/passengers-booking-selection-widget/config", "adept-iq/pods/components/iq-widgets/passengers-booking-selection-widget/avlmConfig", "adept-iq/config/environment"], function (_exports, _component, _config, _avlmConfig, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['passengers-widget', 'data-test-passengers-widget'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    booking: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    session: Ember.inject.service(),
    editComponent: 'iq-widgets/passengers-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    canShowHeader: false,
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);

      this.get('tableColumns').firstObject.component = null;
      this.get('tableColumns').popObject();
      this.get('tableColumns').lastObject.resizable = false;
    },

    actions: {
      onRowClick(row) {
        this.get('onPassengerSelectionWidgetRowClick')(this.get('records').findBy('id', row.get('id')));
      }

    }
  });

  _exports.default = _default;
});