define("adept-iq/utils/guid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.guid = void 0;

  const guid = () => {
    const S4 = () => {
      return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
    };
    /*eslint-disable */


    return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
    /*eslint-enable */
  };

  _exports.guid = guid;
});