define("adept-iq/adapters/avlm-canned-message", ["exports", "adept-iq/adapters/-avlm-avlmService", "adept-iq/config/api-urls"], function (_exports, _avlmAvlmService, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    iquxServiceHost: _apiUrls.API.iquxService.host,

    /**
       * Modified Performance Improvement, the API in the backend uses avlmMessage rather than
       * avlmCannedMessage, and avlm-canned-message need to update avlmService.
       * @param id
       * @param modelName
       * @param snapshot
       * @returns {string}
       */
    urlForFindRecord(id
    /*modelName*/
    ) {
      //modelName = camelize(modelName);
      const model = 'avlmMessage';
      const iquxServiceHost = this.get('iquxServiceHost');
      const baseUrl = `${iquxServiceHost}/${model}/${id}`;
      return baseUrl;
    }

  });

  _exports.default = _default;
});