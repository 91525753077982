define("adept-iq/pods/components/generic-components/solution-preview-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5hFwfQmx",
    "block": "{\"symbols\":[\"t\",\"body\"],\"statements\":[[4,\"light-table\",[[23,[\"solutionPreviewTable\"]]],[[\"occlusion\",\"estimatedRowHeight\"],[true,200]],{\"statements\":[[0,\"\\n\"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"head\"]],\"expected `t.head` to be a contextual component but found a string. Did you mean `(component t.head)`? ('adept-iq/pods/components/generic-components/solution-preview-table/template.hbs' @ L6:C2) \"],null]],[[\"onColumnClick\",\"fixed\",\"resizeOnDrag\"],[[27,\"action\",[[22,0,[]],\"onColumnClick\"],null],true,false]]],false],[0,\"\\n\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"body\"]],\"expected `t.body` to be a contextual component but found a string. Did you mean `(component t.body)`? ('adept-iq/pods/components/generic-components/solution-preview-table/template.hbs' @ L11:C3) \"],null]],[[\"canSelect\",\"multiSelect\",\"rowComponent\"],[false,false,[27,\"component\",[[23,[\"rowComponent\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,[\"solutionPreviewTable\",\"isEmpty\"]]],null,{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,2,[\"no-data\"]],\"expected `body.no-data` to be a contextual component but found a string. Did you mean `(component body.no-data)`? ('adept-iq/pods/components/generic-components/solution-preview-table/template.hbs' @ L18:C3) \"],null]],null,{\"statements\":[[0,\"No solutions found.\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[2]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/generic-components/solution-preview-table/template.hbs"
    }
  });

  _exports.default = _default;
});