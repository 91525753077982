define("adept-iq/pods/components/generic-components/number-format/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zxkFqBUZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[11,\"class\",\"ember-text-field\"],[12,\"value\",[27,\"readonly\",[[23,[\"value\"]]],null]],[12,\"placeholder\",[27,\"readonly\",[[23,[\"placeholder\"]]],null]],[12,\"disabled\",[27,\"readonly\",[[23,[\"disabled\"]]],null]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"onInput\"],[[\"value\"],[\"target.value\"]]]],[12,\"oninput\",[27,\"action\",[[22,0,[]],\"onInput\"],[[\"value\"],[\"target.value\"]]]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/generic-components/number-format/template.hbs"
    }
  });

  _exports.default = _default;
});