define("adept-iq/tests/factories/cs-config-permission", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const adminCSConfigPermission = {
    name: 'restrictions',
    category: 'config-UI_restrictions',
    value: {
      headerFunctions: ['configureWorkspace', 'saveWorkspace', 'saveAsWorkspace', 'deleteWorkspace', 'openWorkspace', 'generateSchedule', 'closeSchedule', 'systemConfiguration', 'resetPassword', 'logout'],
      timeline: ['fullAccess'],
      trips: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['print'],
        singleActions: ['assignRoute', 'noShow', 'approveNoShowRequest', 'denyNoShowRequest', 'waitlist', 'cancelTrip', 'edit', 'activityLog', 'tripTransfer']
      },
      stops: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['print'],
        singleActions: ['arrive', 'depart', 'noShow', 'addDriverBreak', 'deleteDriverBreak', 'editDriverBreak', 'startBreak', 'endBreak', 'reorderStops', 'perform']
      },
      routes: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newRoute', 'print'],
        singleActions: ['sendMessage', 'startRoute', 'endRoute', 'activityLog', 'deleteRoute']
      },
      map: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        singleActions: ['edit']
      },
      alerts: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        singleActions: ['print']
      },
      drivers: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newDriver', 'print'],
        singleActions: ['sendMessage', 'edit']
      },
      vehicles: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newVehicle', 'print'],
        singleActions: ['sendMessage', 'edit', 'resetDevice', 'emergencyAcknowledge', 'emergencyCancel', 'emergencyResolve']
      },
      passengers: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newPassenger', 'print'],
        singleActions: ['addTrip', 'edit']
      },
      messages: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['showAll', 'print'],
        singleActions: ['read', 'hide']
      },
      subscriptions: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['print'],
        singleActions: ['edit']
      },
      users: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newUser', 'print'],
        singleActions: ['edit']
      },
      zones: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: [],
        singleActions: []
      },
      metrics: {
        show: true
      },
      gauge: {
        show: true
      },
      box: {
        show: true
      },
      'donut-chart': {
        show: true
      },
      'bar-chart': {
        show: true
      }
    }
  };
  const avlmCSConfigPermission = {
    name: 'restrictions',
    category: 'config-UI_restrictions',
    value: {
      headerFunctions: ['configureWorkspace', 'saveWorkspace', 'saveAsWorkspace', 'deleteWorkspace', 'openWorkspace', 'generateSchedule', 'closeSchedule', 'systemConfiguration', 'resetPassword', 'logout'],
      timeline: ['fullAccess'],
      trips: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['print'],
        singleActions: ['assignRoute', 'noShow', 'waitlist', 'cancelTrip', 'approveNoShowRequest', 'denyNoShowRequest', 'edit', 'activityLog']
      },
      stops: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['print'],
        singleActions: ['arrive', 'depart', 'noShow', 'addDriverBreak', 'deleteDriverBreak', 'editDriverBreak', 'perform', 'startBreak', 'endBreak', 'reorderStops']
      },
      routes: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newRoute', 'print'],
        singleActions: ['sendMessage', 'startRoute', 'endRoute', 'activityLog', 'deleteRoute']
      },
      map: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        singleActions: []
      },
      alerts: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        singleActions: ['print']
      },
      drivers: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newDriver', 'print'],
        singleActions: ['sendMessage', 'edit']
      },
      vehicles: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newVehicle', 'print'],
        singleActions: ['sendMessage', 'edit', 'resetDevice']
      },
      passengers: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newPassenger', 'print'],
        singleActions: ['addTrip', 'edit']
      },
      messages: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['showAll', 'print'],
        singleActions: ['read', 'hide']
      },
      subscriptions: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['print'],
        singleActions: ['edit']
      },
      users: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newUser', 'print'],
        singleActions: ['edit']
      },
      zones: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: [],
        singleActions: []
      },
      metrics: {
        show: true
      },
      gauge: {
        show: true
      },
      box: {
        show: true
      },
      'donut-chart': {
        show: true
      },
      'bar-chart': {
        show: true
      }
    }
  };

  _emberDataFactoryGuy.default.define('cs-config-permission', {
    default: {
      name: null,
      category: null,
      value: null
    },
    traits: {
      avlm: f => {
        f.name = avlmCSConfigPermission.name;
        f.category = avlmCSConfigPermission.category;
        f.value = avlmCSConfigPermission.value;
      },
      admin: f => {
        f.name = adminCSConfigPermission.name;
        f.category = adminCSConfigPermission.category;
        f.value = adminCSConfigPermission.value;
      }
    }
  });
});