define("adept-iq/pods/components/side-drawer-container/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RESIZE_CURSOR_OFFSET = -2;
  const SIDE_DRAWER_SHRUNKEN_WIDTH = 20;
  const AUTO_CLOSE_MODAL_WIDTH = 213; //sideDrawerComponent that exists in this array will be prevented to create more than once

  const PREVENT_DUPLICATE_DRAWERS = ['side-drawer/system-config'];

  var _default = Ember.Component.extend({
    // NOTE: actual styling classes are applied to the modal in template;
    // it's element is not a child of this component's element
    classNames: ['side-drawer-container'],
    workspace: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    geocode: Ember.inject.service(),
    lastModalWidth: null,
    currentStateHash: null,
    currentState: Ember.computed('currentStateHash', 'sideDrawerComponent', {
      get() {
        const currentStateHash = this.get('currentStateHash');
        const sideDrawerComponent = this.get('sideDrawerComponent');

        if (sideDrawerComponent) {
          return currentStateHash[sideDrawerComponent];
        }

        return null;
      },

      set(key, value) {
        const currentStateHash = this.get('currentStateHash');
        const sideDrawerComponent = this.get('sideDrawerComponent');

        if (sideDrawerComponent) {
          const _currentStateHash = { ...currentStateHash,
            [sideDrawerComponent]: value
          };
          this.set('currentStateHash', _currentStateHash);
        }

        return value;
      }

    }),
    showRightPanel: Ember.computed.readOnly('systemConfig.showRightPanel'),
    sideDrawerTitle: Ember.computed.alias('workspace.topStateDisplayName'),
    isShrunken: Ember.computed.alias('workspace.isDrawerShrunken'),
    sideDrawerComponents: null,
    sideDrawers: Ember.computed.alias('sideDrawerComponents.[]'),
    targetAttachment: Ember.computed('systemConfig.targetAttachment', function () {
      const targetAttachment = this.get('systemConfig.targetAttachment');
      if ((0, _lodash.isEmpty)(targetAttachment)) return 'left';
      return targetAttachment;
    }),
    containerClassNames: Ember.computed('workspace.topOptions', 'showRightPanel', 'isShrunken', function () {
      let classes;
      const isShrunken = this.get('isShrunken');

      if (isShrunken) {
        classes = ['side-drawer-modal', 'side-drawer-shrunken'];
      } else {
        classes = ['ember-modal-dialog', 'side-drawer-modal'];
        const topOptions = this.get('workspace.topOptions');
        const additionalClasses = topOptions.classNames || [];
        const showRightPanel = this.get('showRightPanel');

        if (showRightPanel) {
          classes.push('show-right-panel');
        }

        classes = classes.concat(additionalClasses);
      }

      return classes;
    }),

    init() {
      this._super(...arguments);

      Ember.$(window).on('resize', Ember.run.bind(this, this.handleWindowResize));
    },

    willDestroyElement() {
      this._super(...arguments);

      Ember.$(window).off('resize');
    },

    addSideDrawerComponent() {
      //Adding each side drawer to first of sideDrawerComponents array so that multiple sidedrawer could
      // open at the same time without loosing their changes.
      // in case of closing sidedrawer the first opened drawer would pop out and next one would be shown.
      const sideDrawerComponent = this.get('sideDrawerComponent');
      const sideDrawerStackFrame = this.get('workspace.stack').lastObject;
      let sideDrawerComponents = this.get('sideDrawerComponents');
      let bringToFront = false;
      let bringToFrontIndex = null;
      let sideDrawerWidth = '600px'; //default SideDrawer Width

      let sideDrawerMinWidth = '600px';
      if ((0, _lodash.isNull)(sideDrawerComponents)) sideDrawerComponents = [];
      sideDrawerComponents.map((item, index) => {
        //if drawer is unique and should not have duplicate instances we just bring it to front instead of adding new one.
        if (PREVENT_DUPLICATE_DRAWERS.includes(item.name) && sideDrawerComponent === item.name) {
          bringToFront = true;
          bringToFrontIndex = index;
        }
      });
      const drawerExists = sideDrawerComponents && sideDrawerComponents.length > 0 && sideDrawerStackFrame.activityId === sideDrawerComponents[0].activityId;

      if (!drawerExists || bringToFront) {
        sideDrawerComponents.forEach((item, i) => {
          this.setSideDrawerVisibility(i, 'hidden');
        });

        if (bringToFront) {
          if (sideDrawerComponents.length > 1) {
            //move the existing drawer to the top
            sideDrawerComponents.unshiftObject(sideDrawerComponents.splice(bringToFrontIndex, 1)[0]);
          }
        } else {
          sideDrawerComponents.unshiftObject({
            name: sideDrawerComponent,
            visibility: 'visible',
            activityId: sideDrawerStackFrame.activityId,
            stackFrame: sideDrawerStackFrame,
            currentState: sideDrawerStackFrame.currentState,
            drawerWidth: sideDrawerWidth,
            drawerMinWidth: sideDrawerMinWidth
          });
        }
      }

      sideDrawerWidth = sideDrawerComponents[0].drawerWidth;
      sideDrawerMinWidth = sideDrawerComponents[0].drawerMinWidth;
      this.set('sideDrawerComponents', sideDrawerComponents);
      this.setSideDrawerVisibility(0, 'visible');
      Ember.$('.side-drawer-modal').css('width', sideDrawerWidth);
      Ember.$('.side-drawer-modal').css('min-width', sideDrawerMinWidth);
      Ember.$('.side-drawer-modal').css('right', '');
    },

    setSideDrawerVisibility(sideDrawerIndex, visibility) {
      const sideDrawerComponents = this.get('sideDrawerComponents');
      const drawer = !(0, _lodash.isEmpty)(sideDrawerComponents) ? sideDrawerComponents.objectAt(sideDrawerIndex) : null;

      if (drawer) {
        Ember.set(drawer, 'visibility', visibility);
        this.set('sideDrawerComponents', sideDrawerComponents);
      }
    },

    removeSideDrawerComponent() {
      const sideDrawerComponents = this.get('sideDrawerComponents');
      sideDrawerComponents.shiftObject();
      this.setSideDrawerVisibility(0, 'hidden');
    },

    didReceiveAttrs() {
      this.addSideDrawerComponent();
    },

    handleWindowResize() {
      const windowWidth = Ember.$(window).width();
      const sideDrawerComponents = this.get('sideDrawerComponents');
      const size = windowWidth - 10;

      if (windowWidth < sideDrawerComponents[0].drawerWidth) {
        Ember.$('.side-drawer-modal').css('width', size);
      }

      sideDrawerComponents.map(sideDrawerComponent => {
        if (windowWidth < sideDrawerComponent.drawerWidth) {
          Ember.set(sideDrawerComponent, 'drawerWidth', windowWidth - 10);
        }
      });
      this.set('sideDrawerComponents', sideDrawerComponents);
    },

    updateSideDrawerWidth(size) {
      let initial = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      const sideDrawerComponents = this.get('sideDrawerComponents');
      const stack = sideDrawerComponents[0];
      if (stack && stack.drawerWidth) Ember.set(stack, 'drawerWidth', size);
      Ember.$('.side-drawer-modal').width(size);

      if (stack && stack.drawerMinWidth && initial && size !== 'auto') {
        Ember.set(stack, 'drawerMinWidth', size);
        Ember.$('.side-drawer-modal').css('min-width', size);
      }

      this.set('sideDrawerComponents', sideDrawerComponents);
    },

    actions: {
      setSideDrawerWidth(size) {
        this.updateSideDrawerWidth(size);
      },

      resizeModal(position) {
        if (this.get('isShrunken')) {
          return;
        }

        const windowWidth = Ember.$(window).width(); // Resize cursor offset ensures cursor is centered on handle.

        const modalRight = windowWidth - position.x + RESIZE_CURSOR_OFFSET;
        const modalWidth = windowWidth - modalRight;

        if (modalRight > 10) {
          Ember.run.scheduleOnce('afterRender', () => {
            if (windowWidth - modalRight > AUTO_CLOSE_MODAL_WIDTH) {
              Ember.$('.side-drawer-modal').css('right', `${modalRight}px`);
              this.updateSideDrawerWidth(modalWidth, false);
            } else {
              let preventShrink = this.get('systemConfig.sideDrawerPreventCollapse');
              if ((0, _lodash.isNull)(preventShrink)) preventShrink = false;

              if (!preventShrink) {
                this.send('onShrinkClick');
              }
            }
          });
        }
      },

      onCloseClick() {
        const options = this.get('workspace.topOptions');
        this.get('geocode').deactivateGeocode();
        this.get('workspace').popState();
        this.removeSideDrawerComponent();

        if (options && options.action) {
          options.action();
        }
      },

      onShrinkClick() {
        const isShrunken = this.get('isShrunken');

        if (isShrunken) {
          // Let's restore the old modal width
          const modalWidth = this.get('lastModalWidth');
          Ember.$('.side-drawer').css('right', `${modalWidth}px`);
          Ember.$('.side-drawer').css('left', '0px');
          this.setSideDrawerVisibility(0, 'visible');
        } else {
          // Shrink the modal and save the old minWidth
          const currentModalWidth = parseInt(Ember.$('.side-drawer').css('right'), 10);
          this.set('lastModalWidth', currentModalWidth);
          const windowWidth = Ember.$(window).width();
          const rightPosition = windowWidth - SIDE_DRAWER_SHRUNKEN_WIDTH; // this has to be the former modal width, minus the side drawer side

          const leftPosition = 0 - parseInt(currentModalWidth, 10) + SIDE_DRAWER_SHRUNKEN_WIDTH;
          Ember.$('.side-drawer').css('right', `${rightPosition}px`);
          Ember.$('.side-drawer').css('left', `${leftPosition}px`);
          this.setSideDrawerVisibility(0, 'hidden');
        }

        this.toggleProperty('isShrunken');
      }

    }
  });

  _exports.default = _default;
});