define("adept-iq/pods/components/reoptimize-modal/operation-list/component", ["exports", "adept-iq/pods/components/reoptimize-modal/operation-list/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['operation-list'],
    permissionLayer: Ember.inject.service(),
    // passed from reoptimize-modal
    onItemSelect: null,
    currentSchedule: null,
    models: null,
    selectedModel: null,
    disableList: null,

    init() {
      this._super(...arguments);

      const models = _config.default.filter(model => {
        if (Ember.isPresent(model.permId)) {
          return this.get('permissionLayer').permInUserHash(model.permId, null);
        }

        return true;
      });

      if (models) {
        models.forEach(model => {
          model.set('currentSchedule', this.get('currentSchedule'));
        });
      }

      if (!models || !models.length) {
        this.set('models', []);
      } else {
        this.set('models', models);
      }
    },

    actions: {
      onItemSelect(model) {
        if (!model) return;
        this.set('selectedModel', model);
        const onItemSelect = this.get('onItemSelect');

        if (onItemSelect) {
          onItemSelect(model);
        }
      }

    }
  });

  _exports.default = _default;
});