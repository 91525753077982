define("adept-iq/tests/factories/rider", ["ember-data-factory-guy", "faker"], function (_emberDataFactoryGuy, _faker) {
  "use strict";

  const date = new Date();

  _emberDataFactoryGuy.default.define('rider', {
    sequences: {
      externalIds: num => num,
      firstNames: () => _faker.default.name.firstName(),
      lastNames: () => _faker.default.name.lastName(),
      middleNames: () => _faker.default.name.firstName(),
      dateOfBirths: () => _faker.default.date.past(30, date)
    },
    default: {
      externalId: _emberDataFactoryGuy.default.generate('externalIds'),
      // TRA-255 related; riderId can't be null because kafka takes the new rider before we update the actual riderId as the database Id
      riderId: _emberDataFactoryGuy.default.generate('externalIds'),
      firstName: _emberDataFactoryGuy.default.generate('firstNames'),
      lastName: _emberDataFactoryGuy.default.generate('lastNames'),
      middleName: _emberDataFactoryGuy.default.generate('middleNames'),
      dateOfBirth: _emberDataFactoryGuy.default.generate('dateOfBirths'),
      loadTime: 23,
      unloadTime: 45,
      notes: 'notes'
    }
  });
});