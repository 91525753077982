define("adept-iq/adapters/cs-config-category", ["exports", "adept-iq/adapters/-cs-configService"], function (_exports, _csConfigService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_CATEGORY = 'category';

  var _default = _csConfigService.default.extend({
    findRecord() {
      throw new Error('adapter method unsupported');
    },

    query() {
      throw new Error('adapter method unsupported');
    },

    urlForFindAll() {
      // this isn't _really_ a findAll
      return this._buildUrlForPath(DEFAULT_CATEGORY);
    },

    _buildUrlForPath(path) {
      const host = this.get('host');
      const namespace = this.get('namespace');
      return `${host}/${namespace}/${path}`;
    }

  });

  _exports.default = _default;
});