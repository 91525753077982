define("adept-iq/pods/components/iq-widgets/new-booking-form/ada-eligibility/component", ["exports", "adept-iq/config/environment", "adept-iq/config/mapped-permIds"], function (_exports, _environment, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bemap
  } = window;
  const ADA_BOUNDARY_TYPE = 'ada-boundary-polygons';
  const STOP_MARKER_TYPE = 'stop-marker';
  const arrowHeadLineConfig = {
    patterns: [{
      offset: 0,
      repeat: 0,
      symbol: window.L.Symbol.arrowHead({
        headAngle: -290,
        // flips arrow around so it looks right
        pixelSize: 19,
        polygon: true,
        pathOptions: {
          weight: 2,
          stroke: true,
          fill: true,
          fillColor: 'black',
          fillOpacity: 1,
          color: 'black',
          opacity: 1
        }
      })
    }]
  };

  var _default = Ember.Component.extend({
    classNames: ['ada-eligibility-popup'],
    store: Ember.inject.service(),
    mapService: Ember.inject.service('map'),
    permissionLayer: Ember.inject.service(),
    zoom: 12,
    arrayADABoundaries: null,
    riderADAEligibility: null,
    overrideADABoundariesGranted: false,

    async init() {
      this._super(...arguments);

      this.set('overrideADABoundariesGranted', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.overrideADABoundaries, null));
    },

    didInsertElement() {
      this._super(...arguments);

      this.setupMapDrawControl();
      const arrayADABoundaries = this.get('arrayADABoundaries');

      if (Ember.isPresent(arrayADABoundaries)) {
        this.updateADABoundaries(arrayADABoundaries.geoms);
        this.updateLegMarkers(arrayADABoundaries.legs);
      }
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    updateADABoundaries(geoms) {
      const drawnItems = this.get('drawnItems');
      const map = this.get('map');

      if (Ember.isPresent(geoms)) {
        geoms.map(geom => {
          const lpolygon = this.createPolygon(geom, 'green', ADA_BOUNDARY_TYPE);
          lpolygon.addTo(drawnItems);
          map.fitBounds(lpolygon.getBounds());
        });
      }
    },

    updateLegMarkers(legs) {
      const map = this.get('map');
      const stops = [];
      const latlngs = [];

      if (Ember.isPresent(legs)) {
        legs.forEach(leg => {
          stops.push({
            requestTime: leg.requestTime,
            type: 'pick',
            lat: leg.pick.lat,
            lng: leg.pick.lng,
            adaEligible: leg.pick.adaEligible,
            address: leg.pick.address
          });
          stops.push({
            requestTime: leg.requestTime,
            type: 'drop',
            lat: leg.drop.lat,
            lng: leg.drop.lng,
            adaEligible: leg.drop.adaEligible,
            address: leg.drop.address
          });
        });
        stops.forEach(stop => {
          const lmarker = this.createMarker(stop, STOP_MARKER_TYPE);
          lmarker.addTo(map);
        });

        for (let i = 0; i < stops.length; i++) {
          const prevStop = stops[i - 1];
          const currStop = stops[i]; // generate the direction arrow

          if (i > 0) {
            latlngs.push([[currStop.lat, currStop.lng], [prevStop.lat, prevStop.lng]]);
          }
        }

        const lpolylines = this.createPolylines(latlngs);
        const polylineDecorator = window.L.polylineDecorator(lpolylines, arrowHeadLineConfig);
        lpolylines.addTo(map);
        polylineDecorator.addTo(map);
      }
    },

    setupMapDrawControl() {
      const mapElementId = 'boundary-map';
      const bemapMainCtx = this.get('mapService.bemapMainCtx');
      const zoomLevel = this.get('zoom');
      const bnomadmap = new bemap.LeafletMap(bemapMainCtx, mapElementId, {
        zoom: zoomLevel
      }).defaultLayers();
      const map = bnomadmap.native;
      const drawnItems = new window.L.FeatureGroup();
      map.setView({
        lat: _environment.default.tomtom.search.center.lat,
        lng: _environment.default.tomtom.search.center.lon
      }, zoomLevel);
      map.addLayer(drawnItems);
      this.set('drawnItems', drawnItems);
      this.set('map', map);
    },

    clearDrawnItems(type) {
      if (type === ADA_BOUNDARY_TYPE) {
        const drawnItems = this.get('drawnItems');
        const polygonLayers = drawnItems.getLayers();
        polygonLayers.forEach(polygonLayer => {
          const currentType = polygonLayer?.options?.properties?.type;

          if (currentType === type) {
            drawnItems.removeLayer(polygonLayer);
          }
        });
      }
    },

    createPolygon(latlngs, color, type) {
      const polygon = window.L.polygon([latlngs], {
        color: color,
        properties: {
          type: type
        }
      });
      return polygon;
    },

    createMarker(stop, type) {
      let addressMarker = null;
      const color = stop.adaEligible ? 'black' : 'red';
      addressMarker = window.L.AwesomeMarkers.icon({
        icon: 'circle',
        prefix: 'fa',
        markerColor: color
      });
      const marker = window.L.marker([stop.lat, stop.lng], {
        pmIgnore: true,
        properties: {
          stop: stop,
          type: type
        },
        icon: addressMarker
      });

      if (!Ember.isEmpty(stop.address)) {
        marker.bindTooltip(stop.address, {
          permanent: false,
          direction: 'auto',
          className: `ada-${color}-marker-labels`
        });
      }

      return marker;
    },

    createPolylines(latlngs) {
      const lpolyline = window.L.polyline(latlngs, {
        color: 'black'
      });
      return lpolyline;
    },

    actions: {
      async closeDialog() {
        this.get('onClickX')();
      },

      async onRefuseClick() {
        this.get('onDeny')();
      },

      async onAcceptClick() {
        this.get('onOverride')();
      }

    }
  });

  _exports.default = _default;
});