define("adept-iq/services/core-entity-populator", ["exports", "adept-iq/config/environment", "lodash"], function (_exports, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    workspace: Ember.inject.service(),
    widgetService: Ember.inject.service('widget'),
    polylineService: Ember.inject.service('polyline'),

    async populateEntityGraph(widgetModelName, record, options) {
      switch (widgetModelName) {
        case 'routeWidget':
          return this.populateRoute(record, options);

        case 'tripWidget':
          return this.populateTrip(record, options);

        case 'vehicleWidget':
          return this.populateVehicle(record, options);

        case 'stopWidget':
          return this.populateStop(record, options);

        case 'alertWidget':
          return this.populateAlert(record, options);

        case 'riderWidget':
          return this.populateRider(record, options);

        case 'driverWidget':
          return this.populateDriver(record, options);

        case 'messageWidget':
          return this.populateMessage(record, options);

        case 'reportlistWidget':
          return this.populateMessage(record, options);

        default:
          return;
      }
    },

    async populateDispatchRouteEndHash(scheduleId) {
      try {
        const routeWidgetRecords = await this.extractRouteRecordsFromWidgets(scheduleId);
        const routeEndHash = routeWidgetRecords.filter(route => !!route.dispatchRoutePk && route.actualEndDateTime).reduce((hash, route) => {
          return { ...hash,
            [route.id]: route.actualEndDateTime
          };
        }, {});
        return routeEndHash;
      } catch (e) {
        console.error('Error from populateDispatchRoutesList: ' + e);
        return {};
      }
    },

    async populateRoute(routeRecord, options) {
      if (_environment.default.APP.avlmLite) {
        await this.populateRouteById(routeRecord.id);
      } else {
        const popOptions = options || {};

        if (routeRecord.name && routeRecord.name.startsWith('SUP')) {
          popOptions.isRoadsupRoute = true;
        }

        await this.populateRtsRoute(routeRecord.routeId, popOptions);
        await this.get('widgetService').processWidgetRecordsForStore('routeWidget', [routeRecord]); //get computed routeStatus
      }
    },

    async popluateStopAddressById(stopId) {
      const model = 'avlm-stop-point';
      let include = 'address';
      include = include.replace(/(\r\n|\n|\r|\s)/gm, '');

      try {
        await this.get('store').findRecord(model, stopId, {
          include
        });
      } catch (e) {
        console.error('Error from popluateStopAddressById: ' + e);
      }
    },

    async populateDispatchRoute(routeId, options) {
      //   let include = `vehicle,routeBreaks,trips.subscription,
      // trips.subscription.originPlace,trips.booking,trips.booking.cancellation,trips.segment,
      // trips.pick,trips.drop,trips.pick.segmentStop.place.address,trips.pick.segmentStop.place.location,
      // trips.drop.segmentStop.place.address,trips.drop.segmentStop.place.location,trips.segment.leg,
      // trips.booking,trips.segment.leg.rider,trips.segment.leg.rider.eligibilities,
      // trips.segment.leg.rider.travelNeeds,trips.segment.leg.rider.phoneNumbers,trips.segment.pick,
      // trips.segment.drop,trips.segment.leg.rider.places,trips.segment.leg.travelNeeds,
      // trips.stopPoints,trips.booking.legs,trips.stopPoints.cluster.dispatchRoute,
      // trips.stopPoints.cluster.dispatchSchedule`;
      let include = 'route,dispatchSchedule'; //'dispatchRoute,dispatchRoute.dispatchSchedule,dispatchRoute.routeVehicleDrivers,dispatchRoute.routeVehicleDrivers.dispatchRoute.vehicle,routeVehicleDrivers.driver'; // TODO: make populate with trips working

      const model = 'dispatch-route';
      include = include.replace(/(\r\n|\n|\r|\s)/gm, '');

      try {
        const dsRoute = await this.get('store').findRecord(model, routeId, {
          include
        });
        const stopWidgetRecords = await this.extractStopRecordsFromWidgets(dsRoute);

        if (stopWidgetRecords && stopWidgetRecords.length) {
          await this.get('widgetService').processWidgetRecordsForStore('stopWidget', stopWidgetRecords);
        }
      } catch (e) {
        console.error('Error from populateDispatchRoute: ' + e);
      }
    },

    getRtsRouteInclude1(options) {
      if (options && options.isRoadsupRoute) {
        return `dispatchRoute,
      dispatchRoute.dispatchSchedule,
      dispatchRoute.routeVehicleDrivers,
      dispatchRoute.routeVehicleDrivers.driver,
      dispatchRoute.routeVehicleDrivers.vehicle,
      dispatchRoute.routeVehicleDrivers.vehicle.startGaragePlace,
      dispatchRoute.routeVehicleDrivers.vehicle.endGaragePlace,
      dispatchRoute.routeVehicleDrivers.vehicle.startGaragePlace.address,
      dispatchRoute.routeVehicleDrivers.vehicle.startGaragePlace.location,
      dispatchRoute.routeVehicleDrivers.vehicle.endGaragePlace.address,
      dispatchRoute.routeVehicleDrivers.vehicle.endGaragePlace.location,
      dispatchRoute.routeVehicleDrivers.vehicle.breakdowns,
      dispatchRoute.routeVehicleDrivers.vehicle.breakdowns.place,
      dispatchRoute.routeVehicleDrivers.vehicle.breakdowns.place.address,
      dispatchRoute.routeVehicleDrivers.vehicle.breakdowns.place.location,
      dispatchRoute.dispatchClusters,
      dispatchRoute.dispatchClusters.clusterStopPoints,
      dispatchRoute.dispatchClusters.clusterStopPoints.routeBreak,
      dispatchRoute.dispatchClusters.clusterStopPoints.noShow,
      dispatchRoute.dispatchClusters.clusterStopPoints.place,
      dispatchRoute.dispatchClusters.clusterStopPoints.place.address,
      dispatchRoute.dispatchClusters.clusterStopPoints.place.location,
      vehicle,
      vehicle.startGaragePlace,
      vehicle.startGaragePlace.address,
      vehicle.startGaragePlace.location,
      vehicle.endGaragePlace,
      vehicle.endGaragePlace.address,
      vehicle.endGaragePlace.location`;
      }

      return `dispatchRoute,
    dispatchRoute.dispatchSchedule,
    dispatchRoute.routeVehicleDrivers,
    dispatchRoute.routeVehicleDrivers.driver,
    dispatchRoute.routeVehicleDrivers.vehicle,
    dispatchRoute.routeVehicleDrivers.vehicle.startGaragePlace,
    dispatchRoute.routeVehicleDrivers.vehicle.endGaragePlace,
    dispatchRoute.routeVehicleDrivers.vehicle.startGaragePlace.address,
    dispatchRoute.routeVehicleDrivers.vehicle.startGaragePlace.location,
    dispatchRoute.routeVehicleDrivers.vehicle.endGaragePlace.address,
    dispatchRoute.routeVehicleDrivers.vehicle.endGaragePlace.location,
    dispatchRoute.routeVehicleDrivers.vehicle.breakdowns,
    dispatchRoute.routeVehicleDrivers.vehicle.breakdowns.place,
    dispatchRoute.routeVehicleDrivers.vehicle.breakdowns.place.address,
    dispatchRoute.routeVehicleDrivers.vehicle.breakdowns.place.location,
    dispatchRoute.dispatchClusters,
    dispatchRoute.dispatchClusters.clusterStopPoints,
    dispatchRoute.dispatchClusters.clusterStopPoints.routeBreak,
    dispatchRoute.dispatchClusters.clusterStopPoints.noShow,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.segment,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.segment.leg,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.segment.leg.rider,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.segment.leg.rider.eligibilities,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.segment.leg.rider.phoneNumbers,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.segment.leg.rider.places,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.segment.leg.travelNeeds,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.segment.leg.serviceNeeds,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.pick,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.pick.segmentStop,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.pick.segmentStop.place,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.pick.segmentStop.place.address,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.pick.segmentStop.place.location,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.drop,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.drop.segmentStop,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.drop.segmentStop.place,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.drop.segmentStop.place.address,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.drop.segmentStop.place.location,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.subscription,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.subscription.originPlace,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.booking,
    dispatchRoute.dispatchClusters.clusterStopPoints.trip.booking.cancellation,
    driver,
    vehicle,
    vehicle.startGaragePlace,
    vehicle.startGaragePlace.address,
    vehicle.startGaragePlace.location,
    vehicle.endGaragePlace,
    vehicle.endGaragePlace.address,
    vehicle.endGaragePlace.location`;
    },

    getRtsRouteInclude1b(options) {
      return `trips,
    trips.pick,
    trips.drop,
    trips.segment,
    trips.segment.leg,
    trips.segment.leg.rider,
    trips.segment.pick,
    trips.segment.pick.place,
    trips.segment.pick.place.address,
    trips.segment.pick.place.location,
    trips.segment.drop,
    trips.segment.drop.place,
    trips.segment.drop.place.address,
    trips.segment.drop.place.location,
    routeBreaks,
    routeBreaks.place,
    routeBreaks.place.address,
    routeBreaks.place.location`;
    },

    getRtsRouteInclude2(options) {
      return `dispatchRoute,
    dispatchRoute.dispatchSchedule,
    dispatchRoute.routeVehicleDrivers,
    dispatchRoute.routeVehicleDrivers.vehicle,
    dispatchRoute.routeVehicleDrivers.vehicle.startGaragePlace,
    dispatchRoute.routeVehicleDrivers.vehicle.endGaragePlace,
    dispatchRoute.routeVehicleDrivers.vehicle.startGaragePlace.address,
    dispatchRoute.routeVehicleDrivers.vehicle.startGaragePlace.location,
    dispatchRoute.routeVehicleDrivers.vehicle.endGaragePlace.address,
    dispatchRoute.routeVehicleDrivers.vehicle.endGaragePlace.location,
    dispatchRoute.routeVehicleDrivers.vehicle.breakdowns,
    dispatchRoute.routeVehicleDrivers.vehicle.breakdowns.place,
    dispatchRoute.routeVehicleDrivers.vehicle.breakdowns.place.address,
    dispatchRoute.routeVehicleDrivers.vehicle.breakdowns.place.location`;
    },

    async populateRtsRoute(routeId, options) {
      const isScheduleDashboard = this.get('workspace.isScheduleDashboard');
      let include1 = this.getRtsRouteInclude1(options);
      let include1b = this.getRtsRouteInclude1b(options);
      const model = 'route';
      include1 = include1.replace(/(\r\n|\n|\r|\s)/gm, '');
      include1b = include1b.replace(/(\r\n|\n|\r|\s)/gm, '');
      const etaInclude = 'stopPoints,stopPoints.stopPoint,routeBreaks';

      try {
        const extractFromWidgetPromises = [];
        let route = await this.get('store').findRecord(model, routeId, {
          include: include1
        });
        route = await this.get('store').findRecord(model, routeId, {
          include: include1b
        }); // we are splitting up the includes and have 2 calls because
        // api endpoint does not return all data for some reason

        if (options && !options.isRoadsupRoute) {
          let include2 = this.getRtsRouteInclude2(options);
          include2 = include2.replace(/(\r\n|\n|\r|\s)/gm, '');
          route = await this.get('store').findRecord(model, routeId, {
            include: include2
          });
        }

        if (!isScheduleDashboard) {
          // Workaround: as iqux-service behavior changed, no matter we have etanavRoute or not we keep processing.
          await this.get('store').findRecord('etanav-route', routeId, {
            include: etaInclude
          }).catch(e => {
            return;
          });
        } // we need to keep the status coming from stopWidget. We could not get this
        // from the core data. Belongs to REE


        extractFromWidgetPromises.push(this.extractStopRecordsFromWidgets(route));
        extractFromWidgetPromises.push(this.extractTripRecordsFromRoute(route));
        let [stopWidgetRecords, tripWidgetRecords] = await Promise.all(extractFromWidgetPromises);

        if (stopWidgetRecords && stopWidgetRecords.length) {
          stopWidgetRecords = stopWidgetRecords.map(stopWidget => {
            if (isScheduleDashboard) {
              return {
                id: stopWidget.stopPointPk || stopWidget.id,
                //Garage Points do not have stopPointPk
                routeId: stopWidget.routeId,
                status: stopWidget.status,
                perform: stopWidget.perform || null,
                arrivePerformedBy: stopWidget.arrivePerformedBy,
                departPerformedBy: stopWidget.departPerformedBy,
                actualArriveTime: stopWidget.actualArriveTimestamp || null,
                actualDepartTime: stopWidget.actualDepartTimestamp || null,
                type: stopWidget.type,
                tripStopId: stopWidget.tripStopId,
                actualArriveLat: stopWidget.actualArriveLat || null,
                actualArriveLng: stopWidget.actualArriveLng || null,
                actualDepartLat: stopWidget.actualDepartLat || null,
                actualDepartLng: stopWidget.actualDepartLng || null,
                polyline: stopWidget.polyline,
                stopType: stopWidget.type,
                scheduleOrdinal: stopWidget.scheduleOrdinal,
                plannedSlackTime: stopWidget.plannedSlackTime,
                eta: stopWidget.eta,
                segmentStopPhoneNumbers: stopWidget.segmentStopPhoneNumbers,
                scheduleStopStatus: stopWidget.scheduleStopStatus
              };
            }

            return {
              id: stopWidget.stopPointPk || stopWidget.id,
              //Garage Points do not have stopPointPk
              routeId: stopWidget.routeId,
              status: stopWidget.status,
              perform: stopWidget.perform || null,
              arrivePerformedBy: stopWidget.arrivePerformedBy,
              departPerformedBy: stopWidget.departPerformedBy,
              actualArriveTime: stopWidget.actualArriveTimestamp || null,
              actualDepartTime: stopWidget.actualDepartTimestamp || null,
              type: stopWidget.type,
              tripStopId: stopWidget.tripStopId,
              actualArriveLat: stopWidget.actualArriveLat || null,
              actualArriveLng: stopWidget.actualArriveLng || null,
              actualDepartLat: stopWidget.actualDepartLat || null,
              actualDepartLng: stopWidget.actualDepartLng || null,
              polyline: stopWidget.polyline,
              slackInMinutes: stopWidget.slack || 0,
              odometerOnArrival: stopWidget.odometerOnArrival,
              stopType: stopWidget.type,
              widgetEta: stopWidget.eta,
              segmentStopPhoneNumbers: stopWidget.segmentStopPhoneNumbers
            };
          });
          await this.get('widgetService').processWidgetRecordsForStore('stopWidget', stopWidgetRecords);
        }

        if (tripWidgetRecords && tripWidgetRecords.length) {
          tripWidgetRecords = tripWidgetRecords.map(tripWidget => {
            return {
              id: tripWidget.id,
              routeId: tripWidget.routeId,
              status: tripWidget.status,
              otpCode: tripWidget.otpStatus,
              paymentType: tripWidget.paymentType,
              fare: tripWidget.fare
            };
          });
          await this.get('widgetService').processWidgetRecordsForStore('tripWidget', tripWidgetRecords); //get the performed polyline here so it shows up on the map at first selection

          if (route.get('dispatchRoute.isCompleted')) {
            await this.get('polylineService.fetchPerformedPolyline').perform(routeId, route.get('dispatchRoute.id'));
          }
        }
      } catch (e) {
        console.error('Error from populateRtsRoute: ' + e);
      }
    },

    async populateRouteById(routeId, options) {
      const isScheduleDashboard = this.get('workspace.isScheduleDashboard');
      let include = `
      trips,
      trips.stopPoints,
      trips.stopPoints.address,
      trips.stopPoints.cluster,
      startAddress,
      endAddress,
      routeBreaks,
      routeBreaks.address,
      routeVehicleDrivers,
      routeVehicleDrivers.driver,
      routeVehicleDrivers.vehicle`;
      const model = 'avlm-route';
      include = include.replace(/(\r\n|\n|\r|\s)/gm, '');

      try {
        if (!isScheduleDashboard) {
          await this.get('store').findRecord('etanav-route', routeId);
        }

        const route = await this.get('store').findRecord(model, routeId, {
          include
        });
        const stopWidgetRecords = await this.extractStopRecordsFromWidgets(route);

        if (stopWidgetRecords && stopWidgetRecords.length) {
          await this.get('widgetService').processWidgetRecordsForStore('stopWidget', stopWidgetRecords);
        }
      } catch (e) {
        console.error('Error from populateRouteById: ' + e);
      }
    },

    /**
     * Not used
     * @param route
     * @returns {*|[]}
     * @private
     */
    _extractStopPointsFromStore(route) {
      let stopPointIds = [];

      if (_environment.default.APP.avlmLite) {
        stopPointIds = route.get('trips').map(function (item) {
          const avlmStopPoints = item.get('avlmStopPoints');

          if (avlmStopPoints) {
            return avlmStopPoints.mapBy('id');
          }

          return [];
        });
        const routeStopPointIds = route.get('stopPoints').mapBy('id');
        stopPointIds = stopPointIds.concat(routeStopPointIds);
      } else {
        stopPointIds = route.get('trips').map(function (item) {
          const avlmStopPoints = item.get('stopPoints');

          if (avlmStopPoints) {
            return avlmStopPoints.mapBy('id');
          }

          return [];
        });
        const routeStopPointIds = route.get('stopPoints').mapBy('id');
        stopPointIds = stopPointIds.concat(routeStopPointIds);
      }

      stopPointIds = (0, _lodash.flatten)(stopPointIds);
      return stopPointIds;
    },

    async extractRouteRecordsFromWidgets(scheduleId) {
      const eqFieldQueries = [];
      const inFilterIds = [];
      eqFieldQueries.push(this.get('widgetService').getEqualQuery('schedulePk', scheduleId));
      const generatedRQL = this.get('widgetService').combineInAndEqualFilters('id', inFilterIds, eqFieldQueries); // We need to fetch all route records, so set limit to 1000

      const widgetRecords = await this.get('widgetService').widgetsByFilterQuery('routeWidget', generatedRQL, 1000);
      return widgetRecords;
    },

    async extractStopRecordsFromWidgets(route) {
      const eqFieldQueries = [];
      const inFilterIds = [];
      eqFieldQueries.push(this.get('widgetService').getEqualQuery('routeId', route.get('id')));
      const generatedRQL = this.get('widgetService').combineInAndEqualFilters('id', inFilterIds, eqFieldQueries);
      const widgetRecords = await this.get('widgetService').widgetsByFilterQuery('stopWidget', generatedRQL);
      return widgetRecords;
    },

    async extractTripRecordsFromRoute(route) {
      const eqFieldQueries = [];
      const inFilterIds = [];
      eqFieldQueries.push(this.get('widgetService').getEqualQuery('routeId', route.get('id')));
      const generatedRQL = this.get('widgetService').combineInAndEqualFilters('id', inFilterIds, eqFieldQueries);
      const widgetRecords = await this.get('widgetService').widgetsByFilterQuery('tripWidget', generatedRQL);
      return widgetRecords;
    },

    async extractTripRecordsFromWidgets(tripId) {
      const eqFieldQueries = [];
      const inFilterIds = [];
      eqFieldQueries.push(this.get('widgetService').getEqualQuery('id', tripId));
      const generatedRQL = this.get('widgetService').combineInAndEqualFilters('id', inFilterIds, eqFieldQueries);
      const widgetRecords = await this.get('widgetService').widgetsByFilterQuery('tripWidget', generatedRQL);
      return widgetRecords;
    },

    async populateTrip(tripRecord, options) {
      if (_environment.default.APP.avlmLite && tripRecord.routeId) {
        await this.populateRouteById(tripRecord.routeId);
        return;
      }

      if (tripRecord.routeId) {
        await this.populateRtsRoute(tripRecord.routeId);
      } else {
        await this.populateTripById(tripRecord.id);
      }

      await this.get('store').findRecord('trip', tripRecord.id);
    },

    /**
     *
     * @param recordId
     * @param options
     * @returns {Promise<void>}
     */
    async populateTripById(tripId, options) {
      let model = 'trip';

      if (_environment.default.APP.avlmLite) {
        model = 'avlm-trip';
      } // let include = `subscription,booking,booking.cancellation,segment,
      // pick,drop,pick.segmentStop,pick.segmentStop.place.address,pick.segmentStop.place.location,drop.segmentStop,drop.segmentStop.place.address,
      // drop.segmentStop.place.location,booking,segment.leg,booking,segment.leg.rider,segment.leg.rider.eligibilities,
      // segment.leg.rider.travelNeeds,segment.leg.rider.phoneNumbers,segment.pick,segment.drop,segment.leg.rider.places,booking,
      // segment.leg.travelNeeds,stopPoints,stopPoints.cluster,stopPoints.cluster.dispatchRoute,stopPoints.cluster.dispatchRoute.route,
      // schedule,stopPoints.cluster.dispatchSchedule`;
      // remove segment.pick and segment.drop cause those will override pick and drop.


      let include = `
      subscription,
      booking,
      booking.cancellation,
      segment,
      pick,
      drop,
      pick.segmentStop,
      pick.segmentStop.place,
      pick.segmentStop.place.address,
      pick.segmentStop.place.location,
      drop.segmentStop,
      drop.segmentStop.place,
      drop.segmentStop.place.address,
      drop.segmentStop.place.location,
      segment.leg,
      segment.leg.rider,
      segment.leg.rider.eligibilities,
      segment.leg.rider.travelNeeds,
      segment.leg.rider.phoneNumbers,
      segment.leg.rider.places,
      segment.leg.travelNeeds,
      segment.leg.serviceNeeds,
      stopPoints,
      stopPoints.cluster,
      stopPoints.cluster.dispatchRoute,
      stopPoints.cluster.dispatchRoute.route,
      stopPoints.cluster.dispatchSchedule,
      schedule,
      routeAssignments,
      routeAssignments.route,
      routeAssignments.trip`;

      if (_environment.default.APP.avlmLite) {
        include = '';
      }

      include = include.replace(/(\r\n|\n|\r|\s)/gm, '');

      try {
        await this.get('store').findRecord(model, tripId, {
          include,
          reload: true
        });
        let tripWidgetRecords = await this.extractTripRecordsFromWidgets(tripId);

        if (tripWidgetRecords && tripWidgetRecords.length) {
          tripWidgetRecords = tripWidgetRecords.map(tripWidget => {
            return {
              id: tripWidget.id,
              noShowRequestReasonCode: tripWidget.noShowRequestReasonCode
            };
          });
          await this.get('widgetService').processWidgetRecordsForStore('tripWidget', tripWidgetRecords);
        }
      } catch (e) {
        console.error('Error from populateTrip: ' + e);
        throw e;
      }
    },

    async populateVehicle(vehicleRecord, options) {
      if (vehicleRecord.routePk) {
        if (_environment.default.APP.avlmLite) {
          await this.populateRouteById(vehicleRecord.routePk);
        } else {
          await this.populateRtsRoute(vehicleRecord.routePk);
        }
      }

      try {
        let model = 'vehicle';

        if (_environment.default.APP.avlmLite) {
          model = 'avlm-vehicle';
        }

        await this.get('store').findRecord(model, vehicleRecord.id);
      } catch (e) {
        console.error('Error from populateVehicle: ' + e);
      }
    },

    async populateStop(stopRecord, options) {
      const isScheduleDashboard = this.get('workspace.isScheduleDashboard');

      try {
        if (stopRecord.routeId) {
          if (this.get('user').isRoadSupEnable() && _environment.default.APP.avlmLite) {
            // workaround to avoid getting new data from server for road supervisor.
            await this.get('widgetService').processWidgetRecordsForStore('stopWidget', [stopRecord]); // not await for address back

            this.popluateStopAddressById(stopRecord.id);
          } else if (_environment.default.APP.avlmLite) {
            await this.populateRouteById(stopRecord.routeId);
          } else {
            await this.populateRtsRoute(stopRecord.routeId);
          }
        } else {
          await this.populateTripById(stopRecord.tripPk);
        }

        if (_environment.default.APP.avlmLite) {
          await this.get('store').findRecord('avlm-stop-point', stopRecord.id);
        } else if (isScheduleDashboard && stopRecord.tripPk) {
          let include = `
          booking,
          segment,
          segment.leg,
          segment.leg.rider,
          pick,
          pick.segmentStop,
          pick.segmentStop.place,
          pick.segmentStop.place.address,
          drop,
          drop.segmentStop,
          drop.segmentStop.place,
          drop.segmentStop.place.address`;
          include = include.replace(/(\r\n|\n|\r|\s)/gm, '');
          await this.get('store').findRecord('trip', stopRecord.tripPk, {
            include
          });
        } else if (isScheduleDashboard) {
          if (stopRecord.routeBreakId) {
            const include = 'route';
            await this.get('store').findRecord('route-break', stopRecord.routeBreakId, {
              include
            });
          }
        } else if (stopRecord.type === 'pick' || stopRecord.type === 'drop') {
          let include = `
          tripStop,
          tripStop.segmentStop,
          tripStop.segmentStop.place,
          tripStop.segmentStop.place.address,
          tripStop.segmentStop.place.location`;
          include = include.replace(/(\r\n|\n|\r|\s)/gm, '');
          await this.get('store').findRecord('stop-point', stopRecord.id, {
            include
          });
        } else if (stopRecord.type === 'deployStop') {
          const include = 'place,place.address,place.location';
          await this.get('store').findRecord('stop-point', stopRecord.id, {
            include
          });
        }
      } catch (ex) {
        console.error(ex);
      }
    },

    async populateRider(record, options) {
      try {
        const rider = await this.get('store').findRecord('rider', record.id);
      } catch (ex) {
        console.error(ex);
      }
    },

    async populateDriver(record, options) {
      try {
        let model = 'driver';

        if (_environment.default.APP.avlmLite) {
          model = 'avlm-driver';
        }

        const driver = await this.get('store').findRecord(model, record.id);
      } catch (ex) {
        console.error(ex);
      }
    },

    async populateAlert(record, options) {
      try {
        const alert = await this.get('store').peekRecord('avlm-alert', record.id);
      } catch (ex) {
        console.error(ex);
      }
    },

    /**
     * All messages are loaded into the store as and when it comes
     * @param record
     * @param options
     * @returns {Promise<DS.Model>}
     */
    async populateMessage(record, options) {
      let modelType = 'avlm-canned-message';

      if (record.priority === 'E') {
        modelType = 'avlm-emergency-message';
      }

      const storeRecord = this.get('store').peekRecord(modelType, record.id);
      return storeRecord;
    },

    async tripsByRoute(routeId) {
      let modelName = 'route';
      const includeModelName = 'trips';
      const refModelName = 'trips';

      if (_environment.default.APP.avlmLite) {
        modelName = 'avlm-route';
      }

      const route = await this.get('store').findRecord(modelName, routeId, {
        include: includeModelName
      });
      const trips = route.get(refModelName);

      if (trips) {
        const tripIds = trips.mapBy('id');
        return tripIds;
      }

      return [];
    },

    // tripId and tripModelId both are different.
    // id and tripModelId both are same
    async stopsByTrip(tripModelId) {
      let modelName = 'trip';
      const includeModelName = 'stopPoints';
      let refModelName = 'stopPoints';

      if (_environment.default.APP.avlmLite) {
        modelName = 'avlm-trip';
        refModelName = 'avlmStopPoints';
      }

      const trip = await this.get('store').findRecord(modelName, tripModelId, {
        include: includeModelName
      });
      const stopPoints = await trip.get(refModelName);

      if (stopPoints) {
        const stopIds = stopPoints.mapBy('id');
        return stopIds;
      }

      return [];
    },

    async stopsByRoute(routeId) {
      const tripIds = await this.tripsByRoute(routeId);
      let allStopIds = [];

      for (const tripId of tripIds) {
        const stopIds = await this.stopsByTrip(tripId);

        if (stopIds.length) {
          allStopIds = allStopIds.concat(stopIds);
        }
      }

      return allStopIds;
    },

    async vehicleByRoute(routeId) {
      let modelName = 'route';
      let refModelName = 'vehicle';

      if (_environment.default.APP.avlmLite) {
        modelName = 'avlm-route';
        refModelName = 'avlmVehicle';
      }

      const route = await this.get('store').findRecord(modelName, routeId);
      const vehicle = route.get(refModelName);

      if (vehicle) {
        const vehicleId = vehicle.id;
        return [vehicleId];
      }

      return [];
    },

    async tripsByRider(riderId) {
      /*const route = await this.get('store').findRecord('route', riderId, {include: 'trips'});
      const trips = await route.trips;
      const tripIds = trips.mapBy('id');*/
      return [];
    }

  });

  _exports.default = _default;
});