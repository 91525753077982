define("adept-iq/pods/components/table/cells/radio-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r4yNtNrL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[12,\"disabled\",[21,\"isPlaceholder\"]],[12,\"checked\",[23,[\"row\",\"selected\"]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onRadioButtonClick\"],null]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/table/cells/radio-button/template.hbs"
    }
  });

  _exports.default = _default;
});