define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/travel-needs-cell", ["exports", "react", "lodash", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/portal", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-item", "adept-iq/utils/format-text-extension", "adept-iq/models/travel-need-type"], function (_exports, _react, _lodash, _portal, _cellItem, _formatTextExtension, _travelNeedType) {
  'use strict';

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TravelNeedsCell extends _cellItem.default {
    constructor(props) {
      super(props);
      this.state = {
        isHovering: false,
        positionX: 0,
        positionY: 0
      };
      this.handleMouseHover = this.handleMouseHover.bind(this);
    }

    handleMouseHover() {
      this.setState({
        positionX: window.pageXOffset + event.clientX,
        positionY: window.pageYOffset + event.clientY
      });
      this.setState(this.toggleHoverState);
    }

    toggleHoverState(state) {
      const clonedState = { ...state
      };
      clonedState.isHovering = !state.isHovering;
      return clonedState;
    }

    render() {
      const {
        data,
        style,
        columnIndex,
        rowIndex,
        columnInfo,
        scrollToColumn,
        scrollToRow
      } = this.props;
      const {
        isHovering,
        positionX,
        positionY
      } = this.state;
      const toolTipStyles = {
        width: '200px',
        backgroundColor: '#fff',
        color: 'black',
        textAlign: 'center',
        borderRadius: '6px',
        padding: '5px 0',
        position: 'absolute',
        left: `${positionX}px`,
        top: `${positionY}px`,
        zIndex: 1000
      };
      const currentRowData = data[rowIndex - 1];
      const columnValue = '';
      const classNames = this.getStylesClasses(data, rowIndex, columnInfo, columnIndex, scrollToRow, scrollToColumn, columnValue);
      const travelNeeds = this.getTravelNeeds(currentRowData);
      const length = travelNeeds.filter(val => val.className === 'valueItem').length;
      let columnVal = '';

      if (length > 1) {
        columnVal = `${length} ${this.props.columnInfo.cellDesc}`;
      } else if (length === 1) {
        columnVal = travelNeeds.firstObject.value;
      }

      return _react.default.createElement("span", {
        role: "none",
        className: classNames,
        style: style,
        onMouseEnter: this.handleMouseHover,
        onMouseLeave: this.handleMouseHover
      }, _react.default.createElement("span", {
        "class": "column-label"
      }, columnVal), isHovering && _react.default.createElement(_portal.default, null, _react.default.createElement("div", {
        style: toolTipStyles
      }, this.renderToolTipContent(travelNeeds))));
    }

    renderToolTipContent(travelNeeds) {
      const toolTipStylesULStyle = {
        maxHeight: '200px',
        overflowY: 'auto',
        padding: 0,
        listStyleType: 'none'
      };
      return _react.default.createElement("ul", {
        style: toolTipStylesULStyle
      }, travelNeeds.map(function (item, key) {
        return _react.default.createElement("li", {
          key: key,
          style: {
            padding: '7px'
          }
        }, item.value);
      }));
    }

    getTravelNeeds(currentRowData) {
      const travelNeeds = [];
      /* eslint-disable newline-after-var */

      let totalAmbulatory = 0;
      let totalWheelchair = 0;
      let tripTravelNeeds = currentRowData.travelNeeds;
      let ambulatoryDisplayName = 'Ambulatory';
      let wheelchairDisplayName = 'Wheelchair';

      if (!_lodash.default.isEmpty(tripTravelNeeds)) {
        if (!_lodash.default.isArray(tripTravelNeeds)) {
          // Only one item
          tripTravelNeeds = [tripTravelNeeds];
        }

        tripTravelNeeds.forEach(function (item) {
          const count = parseInt(item.count, 10) || 1;
          const name = item.travelNeedTypeName;
          const displayName = item.displayName;

          if (name === _travelNeedType.AMBULATORY_TRAVELNEED_TYPE) {
            ambulatoryDisplayName = displayName;
            totalAmbulatory += count;
          } else if (name === _travelNeedType.WHEELCHAIR_TRAVELNEED_TYPE) {
            wheelchairDisplayName = displayName;
            totalWheelchair += count;
          } else {
            let text = {};

            if (count === 1 || typeof item.count === 'undefined') {
              text = (0, _formatTextExtension.formatTextExtension)('valueItem', `${displayName}`);
            } else {
              text = (0, _formatTextExtension.formatTextExtension)('valueItem', `${count} ${displayName}`);
            }

            travelNeeds.push(text);
          }
        });

        if (totalAmbulatory > 0) {
          let text = {};
          text = (0, _formatTextExtension.formatTextExtension)('valueItem', `${totalAmbulatory} ${ambulatoryDisplayName}`);
          travelNeeds.push(text);
        }

        if (totalWheelchair > 0) {
          let text = {};
          text = (0, _formatTextExtension.formatTextExtension)('valueItem', `${totalWheelchair} ${wheelchairDisplayName}`);
          travelNeeds.push(text);
        }
      }

      return travelNeeds;
      /* eslint-enable newline-after-var */
    }

  }

  _exports.default = TravelNeedsCell;
});