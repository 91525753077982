define("adept-iq/utils/save-records", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(records) {
    return Promise.all(records.map(record => record.save()));
  }
});