define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/action-button", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/csv-file", "adept-iq/pods/components/iq-widgets/ember-react-playback/hooks/useOutsideCanceler"], function (_exports, _react, _csvFile, _useOutsideCanceler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ActionButton = props => {
    const {
      table
    } = props;

    const [actionEnabled, setActionEnabled] = _react.default.useState(false);

    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("button", {
      className: "playback-widget-button-new ",
      onClick: () => setActionEnabled(true)
    }, _react.default.createElement("i", {
      className: "fa fa-plus-circle",
      "aria-hidden": "true"
    })), actionEnabled && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_useOutsideCanceler.default, {
      cancel: () => setActionEnabled(false)
    }, _react.default.createElement("div", {
      className: "download-dropdown",
      onClick: () => setActionEnabled(false)
    }, _react.default.createElement(_csvFile.default, {
      table: table
    })))));
  };

  var _default = ActionButton;
  _exports.default = _default;
});