define("adept-iq/pods/components/reoptimize-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['reoptimize'],
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tableRef: null,
    currentComponentPath: null,
    currentAPITask: null,
    disableActions: Ember.computed.readOnly('currentAPITask.isRunning'),
    // Passed In Attributes
    currentSchedule: Ember.computed.readOnly('stackFrame.options.currentSchedule'),

    init() {
      this._super(...arguments);
    },

    actions: {
      onXButtonClick() {
        this.get('workspace').popState('reoptimize');
      },

      onItemSelect(model) {
        const componentPath = model.get('componentPath');
        this.set('currentComponentPath', componentPath);
      },

      onToggleDisableActions() {
        this.toggleDisableActions();
      }

    }
  });

  _exports.default = _default;
});