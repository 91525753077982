define("adept-iq/pods/components/side-drawer/add-edit-schedule-route/zone-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/side-drawer/add-edit-schedule-route/zone-table/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TABLE_DOWNLOAD_ACTION = 'Download';

  var _default = _component.default.extend({
    classNames: ['zone-table'],
    config: _config.default,
    disableRowSelection: Ember.computed.alias('isBulkEdit'),

    init() {
      this._super(...arguments); // To remove DOWNLOAD action in Zones Table


      const tableActions = this.get('tableActions').filter(action => action.name !== TABLE_DOWNLOAD_ACTION);
      this.set('tableActions', tableActions);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    fetchDataQuery() {
      const affinities = this.get('route.affinities') || [];
      return this._super(...arguments).then(records => {
        return records.filter(record => {
          //affinities index start from [0-16] and zone Ids starts from 1-17
          const zoneId = record.get('zoneId') - 1;
          const affVal = affinities[zoneId] || 0;
          record.set('affinityValue', affVal);
          return record;
        });
      });
    },

    /**
     * NYAAR-20254-Selected record are getting cleared on perform search in Zones table in Add Route side panel
     * override parent method
     */
    refreshData() {
      this._super(...arguments);

      this.set('defaultCheckboxRowSelected', true);
      this.set('isTableLoadedForFirstTime', false);
      this.fetchCheckedRows();
    }

  });

  _exports.default = _default;
});