define("adept-iq/serializers/address", ["exports", "adept-iq/serializers/-address"], function (_exports, _address) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _address.default.extend({
    attrs: {
      freeformAddress: {
        serialize: false
      },
      zName: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      const {
        zoneName
      } = json.data.relationships;

      if (zoneName && zoneName.data) {
        zoneName.data.type = 'zoneName';
      } else {
        delete json.data.relationships.zoneName;
      } // TEMPORARY FIX FOR ROAD SUPERVISOR


      delete json.data.attributes.postOfficeBoxNumber;
      delete json.data.attributes.type;
      Object.keys(json.data.attributes).forEach(key => {
        // Currently, there is no generic solution to this, lack isChanged flag (field intentionally changed to blank)
        if (key !== 'premise' && key !== 'notes' && Ember.isBlank(json.data.attributes[key])) {
          delete json.data.attributes[key];
        }
      });
      return json;
    },

    normalizeSingleResponse(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      // remove zone-name object to avoid warning messages
      if (!Ember.isEmpty(payload.included)) {
        payload.included = payload.included.filter(hash => hash.type !== 'zoneName');
      }

      const normalized = this._super(...arguments);

      return normalized;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.relationships) && !Ember.isNone(resourceHash.relationships.zoneName)) {
        const zoneName = resourceHash.relationships.zoneName;
        zoneName.data.type = 'zone-type';
      } else if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.zoneName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.zoneName = {
          data: {
            type: 'zone-type',
            id: resourceHash.attributes.zoneName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.zoneName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      const {
        modelName
      } = primaryModelClass; // eslint-disable-next-line no-param-reassign

      id = this.extractId(primaryModelClass, payload.data);

      if (store.hasRecordForId(modelName, id)) {
        const record = store.peekRecord(modelName, id);
        store.unloadRecord(record);
        return this._super(store, primaryModelClass, payload, id, requestType);
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});