define("adept-iq/utils/filters", ["exports", "adept-iq/config/filter-types"], function (_exports, _filterTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.applyFilterByID = applyFilterByID;
  _exports.applyFilterConversion = applyFilterConversion;
  _exports.applyFilterConversionToID = applyFilterConversionToID;
  _exports.buildCompoundFilterNode = void 0;
  _exports.buildFilterFunction = buildFilterFunction;
  _exports.buildValueFilterNode = void 0;
  _exports.generateFilterName = generateFilterName;
  _exports.testFilterValues = testFilterValues;

  const alwaysTrue = () => true;

  function testFilterValues(filterType, values) {
    if (!filterType || filterType === _filterTypes.nullFilter) return false;
    const {
      arity,
      isValue,
      parseValue,
      validateValues
    } = filterType;

    if (!values) {
      if (arity === '+') return false;
      if (Ember.typeOf(arity) === 'number' && arity > 0) return false;
      if (validateValues) return validateValues([]);
      return true;
    }

    if (arity === '+' && values.length === 0) return false;
    if (Ember.typeOf(arity) === 'number' && values.length < arity) return false;
    let args = values.slice(0, arity);
    if (isValue && !args.every(isValue)) return false;

    if (parseValue) {
      args = args.map(parseValue);
    }

    if (validateValues) return validateValues(args);
    return true;
  }

  const buildValueFilterNode = (filterType, path, values, dateFormat) => {
    if (!filterType || filterType === _filterTypes.nullFilter) return null;
    /*eslint-disable */

    if (Ember.isNone(values)) {
      values = [];
    } else if (Ember.typeOf(values) !== 'array') {
      values = [values];
    }
    /*eslint-enable */


    const filterTypeFromHash = _filterTypes.filterTypesHash[filterType.id];
    const isValue = filterTypeFromHash.isValue;
    const parseValue = filterTypeFromHash.parseValue;

    if (filterType.id === 'booleanEq') {
      if (isValue(values[0])) {
        const coercedValue = parseValue(values[0]); // eslint-disable-next-line no-param-reassign

        values = [coercedValue];
      }
    }

    if (filterType.id === 'numberEq') {
      if (isValue(values[0])) {
        const coercedValue = parseValue(values[0]); // eslint-disable-next-line no-param-reassign

        values = [coercedValue];
      }
    }

    return {
      type: filterType.id,
      rql: {
        path,
        values
      },
      local: {
        dateFormat,
        path,
        values
      }
    };
  };

  _exports.buildValueFilterNode = buildValueFilterNode;

  const buildCompoundFilterNode = (type, args) => {
    if (Ember.isEmpty(args)) return null;
    /*eslint-disable */

    args = args.compact();
    /*eslint-enable */

    if (args.length === 0) return null;
    if (args.length === 1) return args[0];
    return {
      type,
      args
    };
  };

  _exports.buildCompoundFilterNode = buildCompoundFilterNode;

  const buildValueFilterFunction = node => {
    if (!node || !node.local) return alwaysTrue;
    const {
      type,
      local: {
        dateFormat,
        path,
        values
      }
    } = node;
    const filterType = _filterTypes.filterTypesHash[type];
    const fn = filterType.fn;
    const formatTargetValue = filterType.formatTargetValue;

    const filterFunction = x => {
      const value = x.get ? x.get(path) : x[path];
      const targetValue = formatTargetValue ? formatTargetValue(value, dateFormat) : value;
      return fn(targetValue, values, dateFormat);
    };

    return filterFunction;
  };

  const buildCompoundFilterFunction = _ref => {
    let {
      type,
      args
    } = _ref;
    if (!args) return alwaysTrue;
    /*eslint-disable */

    const functions = args.map(buildFilterFunction).compact();
    /*eslint-enable */

    if (functions.length === 0) return alwaysTrue;
    if (functions.length === 1) return functions[0];

    switch (type) {
      case 'and':
        return x => functions.every(f => f(x));

      case 'or':
        return x => functions.any(f => f(x));

      default:
        /*eslint-disable */
        throw 'invalid compound filter type';

      /*eslint-enable */
    }
  };

  function buildFilterFunction(node) {
    if (!node) return alwaysTrue;

    switch (node.type) {
      case 'and':
      case 'or':
        return buildCompoundFilterFunction(node);

      default:
        return buildValueFilterFunction(node);
    }
  }

  function generateFilterName(names) {
    let filterName;
    let foundName = false;
    let index = 0;

    do {
      index = index + 1;
      filterName = `Filter ${index}`;
      foundName = !names.includes(filterName);
    } while (!foundName);

    return filterName;
  }

  function applyFilterConversion(filterValues, filterType, modelTypeMapper) {
    const filterValue = filterValues[0].trim().toLowerCase();
    const modelTypes = modelTypeMapper.filter(record => {
      if (filterType === _filterTypes.enumEq.id) {
        return record.displayName === filterValue;
      }

      if (filterType === _filterTypes.enumNotEq.id) {
        return record.displayName === filterValue;
      }

      if (filterType === _filterTypes.enumContains.id) {
        return record.displayName.includes(filterValue);
      }

      return null;
    });
    return modelTypes.map(object => object.id);
  }

  function applyFilterConversionToID(filterValues, filterType, modelTypeMapper) {
    const filterValue = filterType === _filterTypes.enumStringEq.id ? filterValues[0] : filterValues[0].trim().toLowerCase();
    const modelTypes = modelTypeMapper.filter(record => {
      if (filterType === _filterTypes.enumStringEq.id) {
        return filterValue.includes(record.name);
      }

      if (filterType === _filterTypes.enumEq.id) {
        return record.displayName === filterValue;
      }

      if (filterType === _filterTypes.enumNotEq.id) {
        return record.displayName === filterValue;
      }

      if (filterType === _filterTypes.enumContains.id) {
        return record.displayName.includes(filterValue);
      }

      if (filterType === _filterTypes.stringEq.id) {
        return record.displayName === filterValue;
      }

      if (filterType === _filterTypes.stringContains.id) {
        return record.displayName.includes(filterValue);
      }

      if (filterType === _filterTypes.stringStartsWith.id) {
        return record.displayName.startsWith(filterValue);
      }

      return null;
    });
    return modelTypes.map(object => object.id);
  }

  function applyFilterByID(filterValues, filterType, modelTypeMapper) {
    const filterValue = filterType === _filterTypes.enumStringEq.id ? filterValues[0] : filterValues[0].trim().toLowerCase();
    const modelTypes = modelTypeMapper.filter(record => {
      if (filterType === _filterTypes.enumStringEq.id) {
        return filterValue.includes(record.idLowerCase);
      }

      if (filterType === _filterTypes.enumEq.id) {
        return record.idLowerCase === filterValue;
      }

      if (filterType === _filterTypes.enumNotEq.id) {
        return record.idLowerCase === filterValue;
      }

      if (filterType === _filterTypes.enumContains.id) {
        return record.idLowerCase.includes(filterValue);
      }

      if (filterType === _filterTypes.stringEq.id) {
        return record.idLowerCase === filterValue;
      }

      if (filterType === _filterTypes.stringContains.id) {
        return record.idLowerCase.includes(filterValue);
      }

      return null;
    });
    return modelTypes.map(object => object.id);
  }
});