define("adept-iq/pods/components/generic-widgets/side-drawer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CcDDW1sk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"body\",\"header\",\"subHeader\",\"panel\",\"actions\"],[[27,\"component\",[\"generic-widgets/side-drawer/side-drawer-body\"],null],[27,\"component\",[\"generic-widgets/side-drawer/side-drawer-header\"],null],[27,\"component\",[\"generic-widgets/side-drawer/side-drawer-sub-header\"],null],[27,\"component\",[\"generic-widgets/side-drawer/side-drawer-panel\"],null],[27,\"component\",[\"generic-widgets/side-drawer/side-drawer-actions\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/generic-widgets/side-drawer/template.hbs"
    }
  });

  _exports.default = _default;
});