define("adept-iq/services/subscription-activitylog-generation", ["exports", "moment", "lodash"], function (_exports, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RECCURENCE_PATTERNS_DATA = [{
    sunday: 'sunday'
  }, {
    monday: 'monday'
  }, {
    tuesday: 'tuesday'
  }, {
    wednesday: 'wednesday'
  }, {
    thursday: 'thursday'
  }, {
    friday: 'friday'
  }, {
    saturday: 'saturday'
  }, {
    holiday: 'holiday'
  }, {
    suspendType: 'type'
  }];
  const EXCLUSION_DATA = [{
    suspend: 'exclusions.firstObject.suspend'
  }, {
    suspendStart: 'exclusions.firstObject.startDate'
  }, {
    suspendEnd: 'exclusions.firstObject.endDate'
  }, {
    type: 'exclusions.firstObject.type'
  }];
  const ANCHOR_FARE_DATA = [{
    anchor: 'anchor'
  }, {
    fare: 'fare'
  }];
  const PICK_AND_DROP_DATA = [{
    'PickUp Address': 'activeBooking.firstSegmentInFistLeg.pick.place.address.fullAddress'
  }, {
    'DropOff Address': 'activeBooking.firstSegmentInFistLeg.drop.place.address.fullAddress'
  }, {
    'PickUp Notes': 'activeBooking.firstSegmentInFistLeg.pick.notes'
  }, {
    'DropOff Notes': 'activeBooking.firstSegmentInFistLeg.drop.notes'
  }];
  const OTHER_TR_NEEDS = [{
    loadTime: 'activeBooking.legs.firstObject.loadTime'
  }, {
    unloadTime: 'activeBooking.legs.firstObject.unloadTime'
  }, {
    isPca: 'activeBooking.legs.firstObject.isPcaAvailable'
  }];

  var _default = Ember.Service.extend({
    booking: Ember.inject.service(),
    subscription: Ember.inject.service(),

    /**
     * NYAAR-19927: create activityLog for edit-subscription
     * @param {*Object} update
     * @param {*Object} previous
     * @param {*} exclude
     * @returns
     */
    activityLogDifference(update, previous, exclude) {
      const activityLogMessages = [];
      const saveSubscriptionRecord = [];

      const changes = (object, base, ancestors, curr) => {
        ancestors.push(curr);
        return _lodash.default.transform(object, (result, value, key) => {
          if (!Array.isArray(value)) {
            const currentValue = Array.isArray(value.activityLogKey) ? JSON.stringify(value.activityLogKey) : value.activityLogKey;
            const previousValue = Array.isArray(base[key].activityLogKey) ? JSON.stringify(base[key].activityLogKey) : base[key].activityLogKey;

            if (!_lodash.default.isEqual(currentValue, previousValue) && _lodash.default.indexOf(exclude, key) === -1) {
              if (_lodash.default.isObject(currentValue) && _lodash.default.isObject(previousValue)) {
                changes(currentValue, previousValue, ancestors, key);
              } else {
                result[key] = [currentValue, previousValue];
                const editSubscriptionRecord = value.editSubscription;
                this.prepareLogMessageAndEditedRecord(key, previousValue, currentValue, activityLogMessages, saveSubscriptionRecord, editSubscriptionRecord);
              }
            }
          } else {
            _lodash.default.forEach(value, element => {
              const currentValue = element.activityLogKey;
              const currentLogKey = element.logKey;
              const previousValue = base[key].filter(item => item.logKey === currentLogKey)[0].activityLogKey;

              if (!_lodash.default.isEqual(currentValue, previousValue) && _lodash.default.indexOf(exclude, key) === -1) {
                if (_lodash.default.isObject(currentValue) && _lodash.default.isObject(previousValue) && !(currentValue instanceof Date) && !(previousValue instanceof Date)) {
                  changes(currentValue, previousValue, ancestors, key);
                } else {
                  result[key] = [currentValue, previousValue];
                  const editSubscriptionRecord = element.editSubscription;
                  this.prepareLogMessageAndEditedRecord(element.logKey, previousValue, currentValue, activityLogMessages, saveSubscriptionRecord, editSubscriptionRecord);
                }
              }
            });
          }
        });
      };

      changes(update, previous, [], null);
      return {
        activityLogMessages,
        saveSubscriptionRecord
      };
    },

    /**
     * NYAAR-19927: creating activityLog of changed values during editing subscription record
     * @param {*String} key
     * @param {*String} previousValue
     * @param {*String} currentValue
     * @param {*Array} activityLogMessages
     * @param {*Array} saveSubscriptionRecord
     * @param {*Object} editSubscriptionRecord
     */
    prepareLogMessageAndEditedRecord(key, previousValue, currentValue, activityLogMessages, saveSubscriptionRecord, editSubscriptionRecord) {
      if (key !== 'id' && key !== 'travelNeeds') {
        activityLogMessages.push(` ${_lodash.default.capitalize(key)} changed from ${previousValue === '' ? '<empty>' : previousValue} to ${currentValue === '' ? '<empty>' : currentValue}`);
      }

      if (key !== 'id' && editSubscriptionRecord) {
        saveSubscriptionRecord.push(editSubscriptionRecord);
      }

      if (key === 'travelNeeds') {
        this.set('subscription.isTravelNeedEdited', true);
      }
    },

    /**
     * NYAAR-19927: creating previous and current value of subscription data for activityLog purpose
     * @param {*Object} subscription
     * @param {*Object} subscriptionData
     * @returns
     */
    subscriptionDataForActivityLog(subscription, subscriptionData) {
      const passengerNotes = {
        activityLogKey: subscription.get('rider.notes'),
        editSubscription: this.booking.get('selectedRiders.firstObject')
      };
      const passengerPhoneNumber = {
        activityLogKey: subscription.get('rider.riderPhoneNumber.formattedPhoneNumber'),
        editSubscription: this.booking.get('selectedRiders.firstObject')
      };
      const pickAndDropData = this.createObjectArrayDataForLog(PICK_AND_DROP_DATA, this.booking, subscription);
      const pickPhoneNumber = {};
      const dropPhoneNumber = {};
      subscription.get('phoneNumbers').filter(phoneNumber => phoneNumber.description === 'ORIGIN NUMBER').map(number => {
        pickPhoneNumber.activityLogKey = number.fullPhoneNumber;
      });
      subscription.get('phoneNumbers').filter(phoneNumber => phoneNumber.description === 'DESTINATION NUMBER').map(number => {
        dropPhoneNumber.activityLogKey = number.fullPhoneNumber;
      });
      const AnchorFareData = this.createObjectArrayDataForLog(ANCHOR_FARE_DATA, subscription, subscription);
      const serviceType = {
        activityLogKey: subscription.get('serviceWindow.name'),
        editSubscription: subscription.get('serviceWindow')
      };
      const schedulingMode = {
        activityLogKey: subscription.get('schedulingMode'),
        editSubscription: subscription
      };
      const noSharing = {
        activityLogKey: subscription.get('noSharing'),
        editSubscription: subscription
      };
      const originPlannedEta = {
        activityLogKey: (0, _moment.default)(subscription.get('originPlannedEta')).format('HH:mm'),
        editSubscription: subscription
      };
      const destinationPlannedEta = {
        activityLogKey: (0, _moment.default)(subscription.get('destinationPlannedEta')).format('HH:mm'),
        editSubscription: subscription
      };
      const routeTemplate = {
        activityLogKey: subscription.get('routeTemplate.name'),
        editSubscription: subscription
      };
      const travelNeeds = {};
      const filteredTravelNeeds = subscription.get('subscriptionTravelNeeds').map(travelNeed => {
        const travelNeedObj = {};
        const travelNeedTypeName = travelNeed.get('travelNeedTypeNameUppercase');
        const countName = `${travelNeedTypeName}_count`;
        travelNeedObj.travelNeedTypeName = travelNeed.get('travelNeedTypeNameUppercase');
        travelNeedObj[countName] = travelNeed.get('count');
        travelNeedObj.compareKey = 'travelNeedTypeName';
        return travelNeedObj;
      });
      travelNeeds.activityLogKey = filteredTravelNeeds;
      const otherTravelNeeds = this.createObjectArrayDataForLog(OTHER_TR_NEEDS, this.booking, this.booking.get('selectedRiders.firstObject'));
      const companionCount = {
        activityLogKey: this.booking.get('selectedCompanionCount')
      };
      const subscriptionServiceNeeds = {
        activityLogKey: subscription.get('subscriptionServiceNeeds').map(serviceNeed => serviceNeed.get('serviceNeedType.displayName'))
      };
      const startDate = {
        activityLogKey: (0, _moment.default)(subscription.get('startDate')).format('DD/MM/YYYY'),
        editSubscription: subscription
      };
      const endDate = {
        activityLogKey: (0, _moment.default)(subscription.get('endDate')).format('DD/MM/YYYY'),
        editSubscription: subscription
      };
      const requestTime = {
        activityLogKey: (0, _moment.default)(subscription.get('requestTime')).format('DD/MM/YYYY LTS'),
        editSubscription: subscription
      };
      const reccurencePatternsData = this.createObjectArrayDataForLog(RECCURENCE_PATTERNS_DATA, subscriptionData, subscription.get('latestRecurrence'));
      const suspendData = this.createObjectArrayDataForLog(EXCLUSION_DATA, subscription, subscription.get('exclusions.firstObject'));
      return {
        'Passenger Notes': passengerNotes,
        'Passenger PhoneNumber': passengerPhoneNumber,
        serviceType,
        companionCount,
        subscriptionServiceNeeds,
        startDate,
        endDate,
        'PickUp PhoneNumber': pickPhoneNumber,
        'DropOff PhoneNumber': dropPhoneNumber,
        requestTime,
        travelNeeds,
        reccurencePatternsData,
        suspendData,
        AnchorFareData,
        pickAndDropData,
        otherTravelNeeds,
        'Scheduling Mode': schedulingMode,
        'Manual Pick Time': originPlannedEta,
        'Manual Drop Time': destinationPlannedEta,
        'Manual Route': routeTemplate,
        'Ride Share': noSharing
      };
    },

    /**
     * NYAAR-19927: creating array of object for activityLog purpose
     * @param {*Array} arrayData
     * @param {*Array} activityData
     * @param {*Object} editSubscriptionData
     * @returns {*Array}
     */
    createObjectArrayDataForLog(arrayData, activityData, editSubscriptionData) {
      return arrayData.map(reccurencePattern => {
        const keyValuePair = Object.entries(reccurencePattern)[0];
        return {
          activityLogKey: activityData.get(keyValuePair[1]),
          editSubscription: editSubscriptionData,
          logKey: keyValuePair[0]
        };
      });
    }

  });

  _exports.default = _default;
});