define("adept-iq/pods/components/side-drawer/reconcile-add-break/component", ["exports", "moment", "ember-concurrency", "adept-iq/config/environment"], function (_exports, _moment, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FLOATING_BREAK_TYPE = 'Floating';
  const FIXED_BREAK_TYPE = 'Fixed';

  var _default = Ember.Component.extend({
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    classNames: ['reconcile-widget-base'],
    dateTimeFormat: _environment.default.dateTimeFormat,
    // Reset map and address picker
    canResetAddress: Ember.computed.alias('workspace.activeContext.topActiveContext.resetLocation'),
    trip: Ember.computed.readOnly('stackFrame.options.trip'),
    routeDetails: Ember.computed.readOnly('stackFrame.options.route'),
    tripDetails: Ember.computed.alias('reconcile.reconcileTrip'),
    canUndo: Ember.computed.or('selectedBreakType', 'selectedDateTimeRage.startTime', 'breakDuration', 'breakNote', 'selectedLocation', 'breakEndTime'),
    canSave: Ember.computed.and('selectedBreakType', 'selectedDateTimeRage.startTime', 'breakDuration'),
    routes: Ember.computed.readOnly('reconcile.reconcileRouteInfo'),
    tripDate: Ember.computed.alias('reconcile.reconcileDate'),
    callBack: Ember.computed.readOnly('stackFrame.options.action'),
    disableAddress: Ember.computed('selectedBreakType', function () {
      if (this.selectedBreakType === FLOATING_BREAK_TYPE) {
        return true;
      }

      return false;
    }),
    breakTypeOptions: Ember.computed('selectedBreakType', function () {
      const breakTypes = this.store.peekAll('cs-config-item').filter(message => {
        return message.category === 'config-System_Configuration-break_types';
      });
      return breakTypes.map(reason => reason.displayName);
    }),
    actualStartTime: Ember.computed('routeDetails.actualStartTime', function () {
      const actualStartTime = this.get('routeDetails.actualStartTime');
      return actualStartTime ? (0, _moment.default)(actualStartTime).format('hh:mm A') : '';
    }),
    actualEndTime: Ember.computed('routeDetails.actualEndTime', function () {
      const actualEndTime = this.get('routeDetails.actualEndTime');
      return actualEndTime ? (0, _moment.default)(actualEndTime).format('hh:mm A') : '';
    }),

    init() {
      Ember.set(this, 'selectedDateTimeRage', {
        startTime: null,
        startofday: (0, _moment.default)(this.reconcileTripDate).startOf('day').toISOString()
      });

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.setSideDrawerWidth('470px');
      this.get('systemConfig').set('showRightPanel', true);
    },

    generateRandomId() {
      return Math.floor(Math.random() * 10000);
    },

    buildEventPayloadForAddBreak(routeResponse, rowRes, reconcileDate) {
      return [{
        'id': routeResponse.routeStartEvent.id,
        'routeId': routeResponse.routeStartEvent.routeid,
        'driverId': routeResponse.routeStartEvent.driverid,
        'vehicleId': routeResponse.routeStartEvent.vehicleid,
        'scheduleId': routeResponse.routeStartEvent.scheduleid,
        'stopPointId': routeResponse.routeStartEvent.stoppointid,
        'tripId': rowRes.tripId,
        'odo': routeResponse.routeStartEvent.odo,
        'speed': routeResponse.routeStartEvent.speed,
        'heading': routeResponse.routeStartEvent.heading,
        'eventType': routeResponse.routeStartEvent.eventtype,
        'actionType': routeResponse.routeStartEvent.actiontype,
        'createdAt': routeResponse.routeStartEvent.createdat,
        'updatedAt': routeResponse.routeStartEvent.updatedat,
        'receivedAt': routeResponse.routeStartEvent.receivedat,
        'dbVersion': null,
        'op$1': routeResponse.routeStartEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      }, {
        'id': routeResponse.routeEndEvent.id,
        'routeId': routeResponse.routeEndEvent.routeid,
        'driverId': routeResponse.routeEndEvent.driverid,
        'vehicleId': routeResponse.routeEndEvent.vehicleid,
        'scheduleId': routeResponse.routeEndEvent.scheduleid,
        'stopPointId': routeResponse.routeEndEvent.stoppointid,
        'tripId': rowRes.tripId,
        'odo': routeResponse.routeEndEvent.odo,
        'speed': routeResponse.routeEndEvent.speed,
        'heading': routeResponse.routeEndEvent.heading,
        'eventType': routeResponse.routeEndEvent.eventtype,
        'actionType': routeResponse.routeEndEvent.actiontype,
        'createdAt': routeResponse.routeEndEvent.createdat,
        'updatedAt': routeResponse.routeEndEvent.updatedat,
        'receivedAt': routeResponse.routeEndEvent.receivedat,
        'dbVersion': null,
        'op$1': routeResponse.routeEndEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      }];
    },

    buildRouteBreakPayloadForAddBreak(rowRes, rvdResponse, reconcileDate) {
      return [{
        'id': this.generateRandomId(),
        'plannedStartTime': rvdResponse.route.plannedstarttime,
        'plannedEndTime': rvdResponse.route.plannedendtime,
        'breakType': this.get('selectedBreakType'),
        'routeIndex': null,
        'scheduledTime': rowRes.riderEvent.scheduledtime,
        'promisedTime': rowRes.promisedTime,
        'serviceStartTime': this.get('selectedDateTimeRage.startTime'),
        'serviceEndTime': this.get('breakEndTime'),
        'routeId': rvdResponse.route.id,
        'eta': rowRes.eta,
        'status': rvdResponse.route.id,
        'scheduleId': rvdResponse.route.scheduleid,
        'version': null,
        'op$1': rvdResponse.route.__op,
        'createdAt': rvdResponse.route.createdat,
        'updatedAt': rvdResponse.route.updatedat,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      }];
    },

    buildRoutePayloadForAddBreak(rvdResponse, reconcileDate) {
      return {
        'deleted$1': false,
        'endAddressId': rvdResponse.route.endaddressid,
        'startAddressId': rvdResponse.route.startaddressid,
        'version': null,
        'op$1': rvdResponse.route.__op,
        'createdAt': rvdResponse.route.createdat,
        'plannedStartTime': rvdResponse.route.plannedstarttime,
        'plannedEndTime': rvdResponse.route.plannedendtime,
        'id': rvdResponse.route.id,
        'providerName': rvdResponse.route.providername,
        'scheduleId': rvdResponse.route.scheduleid,
        'status': rvdResponse.route.status,
        'trackingId': rvdResponse.route.trackingid,
        'updatedAt': rvdResponse.route.updatedat,
        'actualStartTime': rvdResponse.route.actualstarttime,
        'actualEndTime': rvdResponse.route.actualendtime,
        'alertId': rvdResponse.route.alertid,
        'otpValue': rvdResponse.route.otpvalue,
        'otpStatus': rvdResponse.route.otpstatus,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      };
    },

    buildPayloadForAddBreak() {
      const selectedTripRow = this.get('reconcile.lastRowClickedInTripTable');
      const reconcileDate = new Date().toISOString();
      const reconcileRouteInfo = this.get('reconcile.reconcileRouteInfo');
      const reconcileRVDResponse = this.get('reconcile.reconcileRVDResponse').find(route => route.routeid === parseInt(reconcileRouteInfo.routeid, 10));
      return {
        'events': this.buildEventPayloadForAddBreak(reconcileRouteInfo, selectedTripRow, reconcileDate),
        'routeBreak': this.buildRouteBreakPayloadForAddBreak(selectedTripRow, reconcileRVDResponse, reconcileDate),
        'route': this.buildRoutePayloadForAddBreak(reconcileRVDResponse, reconcileDate)
      };
    },

    saveDriverBreakTask: (0, _emberConcurrency.task)(function* () {
      try {
        const recon = this.get('reconcile');
        const payload = this.buildPayloadForAddBreak();
        return yield recon.addRouteBreak(payload, 'break');
      } catch (error) {
        throw error;
      }
    }),

    showToolTip() {
      const tooltip = this.get('tooltip');
      const title = 'Insert Driver Break';
      const tip = 'Add driver break?';
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          return Ember.get(this, 'saveDriverBreakTask').perform().then(response => {
            const breakId = response && response.data && response.data.find(ele => ele.data.id).data.id ? response.data.find(ele => ele.data.id).data.id : '';
            this.send('cancel');
            this.get('workspace').popState('reconcileAddBreak');
            this.get('notifications').success(`SUCCESSFULLY ADDED DRIVER BREAK ${breakId}`);
            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in driver break, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    checkValidations() {
      let isValid = true;
      Ember.set(this, 'errors', []);

      if (!Ember.get(this, 'selectedBreakType')) {
        isValid = false;
        this.errors.push('Please select driver break type');
      }

      if (!Ember.get(this, 'selectedLocation') && Ember.get(this, 'selectedBreakType') === FIXED_BREAK_TYPE) {
        isValid = false;
        this.errors.push('Please select break address');
      }

      if (!Ember.get(this, 'selectedDateTimeRage').startTime) {
        isValid = false;
        this.errors.push('Please select break time');
      }

      if (!Ember.get(this, 'breakDuration')) {
        isValid = false;
        this.errors.push('Please select break duration');
      }

      if (Ember.get(this, 'breakDuration') === '0') {
        isValid = false;
        this.errors.push('Break duration should be greater than 0');
      }

      return isValid;
    },

    setBreakEndTime() {
      if (this.get('selectedDateTimeRage').startTime) {
        const startTime = (0, _moment.default)(this.get('selectedDateTimeRage').startTime).format('hh:mm A');
        const endTime = (0, _moment.default)(startTime, 'h:mm A').add(Ember.get(this, 'breakDuration'), 'minutes').format('hh:mm A');
        Ember.set(this, 'breakEndTime', endTime);
      }
    },

    actions: {
      onUndoClick() {
        Ember.set(this, 'breakDuration', null);
        Ember.set(this, 'breakNote', null);
        Ember.set(this, 'selectedBreakType', null);

        if (Ember.get(this, 'selectedLocation')) {
          Ember.set(this, 'canResetAddress', true);
        }

        Ember.set(this, 'selectedDateTimeRage.startTime', null);
        Ember.set(this, 'breakEndTime', null);
        Ember.set(this, 'selectedLocation', null);
      },

      onSaveClick() {
        const isValid = this.checkValidations();

        if (isValid) {
          this.showToolTip();
        }
      },

      onbreakTypeChange(option) {
        Ember.set(this, 'selectedBreakType', option);
      },

      onChangeLocation(record) {
        Ember.set(this, 'selectedLocation', record);
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        Ember.set(this, valuePath, momentTime.toISOString());
        this.setBreakEndTime();
      },

      onDurationChange(value) {
        Ember.set(this, 'breakDuration', value);
        this.setBreakEndTime();
      },

      cancel() {
        if (this.callBack) {
          this.callBack(true);
        }
      }

    }
  });

  _exports.default = _default;
});