define("adept-iq/pods/components/iq-widgets/eligibility-restriction-warning/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    eligibilityConditions: null,
    showOnlyOk: true,
    overrideEligibilityConditionsGranted: false,

    init() {
      this._super(...arguments);

      this.set('eligibilityConditions', []);
      this.setEligibilityConditionsForEachRider();
    },

    // this is required because the conditions need to be updated so cant be a computed property in rider external model
    setEligibilityConditionsForEachRider() {
      const ridersWithConditionalEligibility = this.get('ridersWithConditionalEligibility');
      ridersWithConditionalEligibility.forEach(r => {
        const conditions = this.getEligibilityConditionsForRider(r);
        r.set('eligibilityConditions', conditions);
      });
    },

    getEligibilityConditionsForRider(rider) {
      const riderIdConditionsMap = this.get('riderIdConditionsMap');
      const riderId = rider.get('id');
      return riderIdConditionsMap[riderId];
    },

    actions: {
      onEligibleWarningClose() {
        this.get('onClose')();
      },

      onEligibleWarningOK() {
        this.get('onEligibleWarningOK')();
      },

      onEligibilityWarningYes() {
        if (this.get('onEligibilityWarningYes')) {
          this.get('onEligibilityWarningYes')();
        }
      },

      onEligibilityWarningNo() {
        if (this.get('onEligibilityWarningNo')) {
          this.get('onEligibilityWarningNo')();
        }
      }

    }
  });

  _exports.default = _default;
});