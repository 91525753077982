define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/driver-log/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/driver-log/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['driver-log-table'],
    config: _config.default,
    store: Ember.inject.service(),
    enableWarningRowChangeAlert: true,

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    fetchDataQuery() {
      return this._super(...arguments).then(records => {
        return records.filter(record => !record.get('isNew'));
      });
    }

  });

  _exports.default = _default;
});