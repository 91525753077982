define("adept-iq/classes/markers/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const dynamicProperties = ['lat', 'lng', 'rotationAngle', 'style', 'isHidden', 'label'];

  var _default = Ember.Object.extend(Ember.Evented, {
    // array of properties to be observed for repaint
    // e.g. ['lat', 'lng', 'rotationAngle'] for vehicles
    dynamicProperties,
    // markers must be provided a UNIQUE id; this req't should be refactored out
    id: null,
    lat: null,
    lng: null,
    isHidden: false,
    opacity: 1.0,
    rotationAngle: null,
    zOffset: null,
    // see `app/pods/components/iq-widgets/map-widget/config/marker.js` for styles
    style: null,
    label: null,
    // injected by layered map context
    isActive: false,
    layer: null,
    layerType: null,
    // these will be applied _after_ style defaults
    options: null
  });

  _exports.default = _default;
});