define("adept-iq/classes/rules/eligibility-rules/itp-conditions-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type", "adept-iq/models/cs-config-category", "lodash", "moment"], function (_exports, _baseRule, _ruleResultType, _csConfigCategory, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ItpConditionsRule = void 0;

  class ItpConditionsRule extends _baseRule.default {
    doEvaluate(ruleContext) {
      const {
        bookingService,
        store,
        systemConfigService
      } = ruleContext;
      const selectedRiders = bookingService.get('selectedRiders');
      const conditionConfigList = systemConfigService.findConfigByCategory(_csConfigCategory.CONFIG_ELIGIBILITY_CONDITIONS);
      const {
        value: itpWheelChairConfig
      } = store.peekRecord('cs-config-item', 'config-System_Configuration-ITP_Configurations/ITP_Wheelchair');
      const {
        value: itpAgeConfig
      } = store.peekRecord('cs-config-item', 'config-System_Configuration-ITP_Configurations/ITP_Age');
      const {
        value: itpMinWalkDistConfig
      } = store.peekRecord('cs-config-item', 'config-System_Configuration-ITP_Configurations/ITP_MinimumWalkingDistance');
      const itpConditionPass = selectedRiders.every(rider => {
        const riderAgeConditionPass = this.isAgeConditionPass(rider, itpAgeConfig);
        const wheelChairConditionPass = this.isWheelChairConditionPass(rider, bookingService, itpWheelChairConfig);
        const minWalkConditionPass = this.isMinWalkDistancePass(rider, itpMinWalkDistConfig, conditionConfigList);
        /* eslint-disable no-console */

        console.log(`riderAgeConditionPass :  ${riderAgeConditionPass} , wheelChairConditionPass: ${wheelChairConditionPass}, distanceConditionPass : ${minWalkConditionPass}`);
        return riderAgeConditionPass && wheelChairConditionPass && minWalkConditionPass;
      });
      if (itpConditionPass) return _ruleResultType.RESULT_TYPE_YES;
      return _ruleResultType.RESULT_TYPE_NO;
    }

    isWheelChairConditionPass(rider, bookingService, itpWheelChairConfig) {
      const isItpWheechairAllowed = itpWheelChairConfig.value;

      if (isItpWheechairAllowed) {
        return true;
      }

      const activeBooking = bookingService.get('activeBooking');
      const legRecords = activeBooking.get('legs');
      const legTravelNeedRecords = legRecords.firstObject.legTravelNeeds;
      const containsRiderWheelChairTravelNeeds = !!legTravelNeedRecords.find(legTravelNeedRecord => {
        const travelneedtype = legTravelNeedRecord.get('travelNeedType.id').toLowerCase();
        const count = legTravelNeedRecord.get('count');
        return travelneedtype === 'wheelchair' && count > 0;
      });

      if (!isItpWheechairAllowed && !containsRiderWheelChairTravelNeeds) {
        return true;
      }

      return false;
    }

    isAgeConditionPass(rider, itpAgeConfig) {
      const dateOfBirth = rider.get('dateOfBirth');
      const riderAge = (0, _moment.default)().diff(dateOfBirth, 'years');
      const itpAgeConfigValue = itpAgeConfig.value;
      if (riderAge < itpAgeConfigValue) return true;
      return false;
    }
    /**
     *  In order to be considered for an ITP solution trip, a client must be assigned an eligibility condition
     *  that has its ‘Blocks’ parameter set to a value greater than or equal to the ITP_MinimumWalkingDistance setting in the System Config.
     * @param rider
     * @param itpMinWalkDistConfig
     * @param conditionConfigList
     * @returns {*}
     */


    isMinWalkDistancePass(rider, itpMinWalkDistConfig, conditionConfigList) {
      const itpMinWalkDistConfigValue = parseInt(itpMinWalkDistConfig.value, 10);
      const allConditions = (0, _lodash.flatten)(rider.get('eligibility.categories').map(category => category.conditions || []));
      const distanceConditions = allConditions.filter(condition => condition.toUpperCase().includes('BLOCKS') || condition.toUpperCase().includes('STAIRS'));
      return distanceConditions.every(riderCondition => {
        const configItem = this.getConditionConfig(riderCondition, conditionConfigList);
        const configuredValue = this.getConfiguredValue(configItem); //its ‘Blocks’ parameter set to a value greater than or equal to the ITP_MinimumWalkingDistance

        return configuredValue >= itpMinWalkDistConfigValue;
      });
    }

    getConditionConfig(condition, conditionConfigList) {
      const configItem = conditionConfigList.find(config => {
        const value = config.value;
        const conditionName = value.name || value.Name || '';
        return conditionName.trim().toLowerCase() === condition.toLowerCase();
      });
      return configItem;
    }

    getConfiguredValue(configItem) {
      const configuredValueObj = configItem.value;
      const configuredValue = configuredValueObj['Configured value'] || configuredValueObj.configuredValue;
      return parseInt(configuredValue, 10);
    }

  }

  _exports.ItpConditionsRule = ItpConditionsRule;
  ItpConditionsRule.RULE_NAME = 'ItpConditionsRule';
});