define("adept-iq/tests/factories/avlm-route", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const date = (0, _moment.default)().startOf('day').add(8, 'hours');
  const evening = (0, _moment.default)().startOf('day').add(16, 'hours');

  _emberDataFactoryGuy.default.define('avlm-route', {
    sequences: {
      names: num => `${num}0-${num}`
    },
    default: {
      status: 'Not Started',
      otp: 'L',
      otpValue: 300,
      trackingId: _emberDataFactoryGuy.default.generate('names'),
      timestamp: (0, _moment.default)().startOf('day').toDate(),
      actualStartTime: (0, _moment.default)().startOf('day').toDate(),
      actualEndTime: null,
      plannedStartTime: date.toDate(),
      plannedEndTime: evening.toDate(),
      providerId: 'EPC',
      alertId: '8605499635271922'
    },
    traits: {
      pullout: {
        status: 'Pullout',
        actualStartTime: date.toDate()
      }
    }
  });
});