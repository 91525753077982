define("adept-iq/serializers/driver-provider", ["exports", "adept-iq/serializers/-driver", "lodash"], function (_exports, _driver, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _driver.default.extend({
    serialize() {
      const json = this._super(...arguments);

      if (json.data && json.data.relationships) {
        const providerRelationship = json.data.relationships.provider;

        if (Ember.isPresent(providerRelationship)) {
          providerRelationship.data.type = 'providerName';
          json.data.relationships.providerName = json.data.relationships.provider;
          delete json.data.relationships.provider;
        }
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};

      if (Ember.isPresent(resourceHash.attributes.providerName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.provider = {
          data: {
            type: 'provider',
            id: resourceHash.attributes.providerName
          }
        };
        resourceHash.relationships = relationships;
      }

      if (resourceHash.relationships.providerName) {
        resourceHash.relationships.providerName.data.type = 'provider';
        resourceHash.relationships.provider = (0, _lodash.cloneDeep)(resourceHash.relationships.providerName);
        delete resourceHash.relationships.providerName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});