define("adept-iq/pods/components/generic-widgets/form-widget/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m6F0k8kD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"panel-header \",[27,\"if\",[[23,[\"isCollapsible\"]],\"is-collapsible\",\"\"],null]]]],[12,\"disabled\",[27,\"not\",[[23,[\"isCollapsible\"]]],null]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isCollapsible\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"isCollapsed\"]]],null,{\"statements\":[[0,\"          \"],[7,\"i\"],[11,\"class\",\"fa fa-caret-right\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"i\"],[11,\"class\",\"fa fa-caret-down\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"panel-title\"],[9],[0,\"\\n      \"],[1,[21,\"title\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"onHeaderClick\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"panel-body\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/generic-widgets/form-widget/section/template.hbs"
    }
  });

  _exports.default = _default;
});