define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-driver/component", ["exports", "adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-driver/config", "lodash"], function (_exports, _config, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dwhService: Ember.inject.service(),
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    driverTable: null,
    selectedDriver: null,
    selectedCdl: null,
    searchButtonEnabled: Ember.computed.or('selectedDriver', 'selectedCdl'),
    clearButtonEnabled: Ember.computed.or('selectedDriver', 'selectedCdl'),
    config: _config.default,

    init() {
      this._super(...arguments);

      this.set('onRowClick', this.actions.onRowClick.bind(this));
      this.set('fetchRoutesByDriver', this.get('fetchRoutesByDriver').bind(this));
      this.set('config.title', '');
    },

    driverOptions: Ember.computed.alias('dwhService.driverOptions'),

    fetchRoutesByDriver() {
      const routes = this.get('dwhService.routes');
      const selectedDriverId = this.get('selectedDriver.badgenumber');
      if (!selectedDriverId) return [];
      return routes.filter(r => _lodash.default.get(r, 'attributes.driverId') === selectedDriverId);
    },

    actions: {
      onRowClick(selectedRow) {
        if (Ember.isEmpty(selectedRow)) return;
        const routeId = selectedRow.get('id');
        this.set('dwhService.routeFound', routeId);
      },

      onSearchRoutesByDriver() {
        if (this.selectedDriver && this.selectedCdl) {
          const tooltip = this.get('tooltip');
          tooltip.pushConfirmation({
            title: 'Search',
            tip: 'Both Driver and CDL Number can not be supplied. Choose one or the other.',
            primaryActionText: 'OK',
            primaryAction: () => {
              tooltip.reset();
            },
            hasOverlay: true
          });
        }

        this.get('driverTable').refreshData();
      },

      onOptionsSelect(item, option) {
        Ember.set(this, item, option);
      },

      clearSearchDriver() {
        this.set('selectedDriver', '');
        this.get('driverTable').clearData();
        this.set('config.title', '');
        this.set('config.noRecordsFound', false);
        this.set('routeId', null);
      }

    }
  });

  _exports.default = _default;
});