define("adept-iq/pods/components/rider-management-widget/rider-modal-search/rider-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/rider-management-widget/rider-modal-search/rider-table/config", "adept-iq/utils/sorts"], function (_exports, _component, _config, _sorts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    config: _config.default,

    init() {
      this._super(...arguments);

      this.set('isSearchAllowed', false);
    },

    // this empty method is used to over-ride parent method as we dont need the functionality
    refreshData() {
      const params = {
        sorts: []
      };
      const sort = this.buildSortParam();

      if (sort && this.get('records.length') > 0) {
        params.sorts.push(sort);
        const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
        this.set('records', this.get('records').sort(compareFn)); // Same array sorting need extra trigger changes.

        this.notifyPropertyChange('records');
      }
    },

    actions: {
      onRowClick(currentRow) {
        this._super(currentRow);

        this.get('onRiderSelect')(currentRow);
      }

    }
  });

  _exports.default = _default;
});