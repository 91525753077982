define("adept-iq/serializers/dwh-stop-point", ["exports", "adept-iq/serializers/-dwh-dwhService"], function (_exports, _dwhDwhService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dwhDwhService.default.extend({
    modelName: 'dwh-stop-point',

    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};

      if (resourceHash.attributes) {
        if (Ember.isPresent(resourceHash.attributes.routeid)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.dwhRoute = {
            data: {
              type: 'dwh-route',
              id: resourceHash.attributes.routeid
            }
          };
          resourceHash.relationships = relationships;
        }

        if (Ember.isPresent(resourceHash.attributes.tripId)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.dwhTrip = {
            data: {
              type: 'dwh-trip',
              id: resourceHash.attributes.tripId
            }
          };
          resourceHash.relationships = relationships;
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});