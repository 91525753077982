define("adept-iq/pods/components/iq-widgets/reportlist-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/reportlist-widget/config", "adept-iq/utils/filters", "adept-iq/utils/sorts", "adept-iq/config/mapped-permIds"], function (_exports, _component, _config, _filters, _sorts, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const fromPixels = x => parseInt(x.split('px')[0], 10);

  var _default = _component.default.extend({
    classNames: ['reportlist-widget'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    router: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    report: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    isReportViewEnabled: false,
    allReportList: null,
    config: _config.default,

    init() {
      this._super(...arguments);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.reportList();
    },

    didInsertElement() {
      this._super(...arguments);

      this.onWidgetStateChange();
      this.get('workspace').set('isGlobalSpinnerVisible', false);
      this.set('openAction', {
        id: 'open',
        permId: _mappedPermIds.default.accessReportsWorkspace,
        name: 'Open Report',
        action: () => {
          //this.set('isLoading', true);
          //this.get('workspace').set('isGlobalSpinnerVisible', true);
          const lastRowClicked = this.get('lastRowClicked');
          this.get('allReportList').forEach(item => {
            if (item.id === lastRowClicked.id) {
              this.getUrl(item, false);
            }
          });
        }
      });
      this.set('shareAction', {
        id: 'share',
        permId: _mappedPermIds.default.accessReportsWorkspace,
        name: 'Schedule Report',
        action: () => {
          const lastRowClicked = this.get('lastRowClicked');
          this.get('allReportList').forEach(item => {
            if (item.id === lastRowClicked.id) {
              this.getUrl(item, true);
            }
          });
        }
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    async reportList() {
      const resultList = await this.report.reportList();

      if (!Ember.isEmpty(resultList)) {
        this.set('allReportList', resultList);
      }

      Ember.run.scheduleOnce('afterRender', this, 'refreshData');
    },

    refreshData() {
      if (this.get('isDestroyed')) return;
      this.get('fetchDataTask').perform().then(() => {
        if (this.get('activeContext.topActiveContext.refreshInProgress')) {
          this.set('activeContext.topActiveContext.refreshInProgress', false);
        }
      }).catch(err => {
        if (this.get('activeContext.topActiveContext.refreshInProgress')) {
          this.set('activeContext.topActiveContext.refreshInProgress', false); // eslint-disable-next-line no-console

          console.log('Error occoured when refreshing the Data' + err);
        }
      });
    },

    actions: {
      onRowClick(row, event) {
        let xpos = 0;
        let i = 1;

        while (this.table.columns[i].id !== 'reportName') {
          if (this.table.columns[i].hidded !== true) {
            xpos += fromPixels(this.table.columns[i].width);
          }

          i++;
        }

        this.get('allReportList').forEach(item => {
          if (item.id === row.get('record').id && event.clientX > xpos && event.clientX < xpos + fromPixels(this.table.columns[i].width)) {
            this.getUrl(item, false);
          }
        });
      },

      onBackButtonClick() {
        this.set('isReportViewEnabled', false);
        this.set('isLoading', false);
      },

      onIFrameLoad() {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        const notifications = this.get('notifications');
        const that = document.getElementById('reportViewFrame');

        try {
          const isLoaded = that.contentWindow.window.length; // 0 or 1

          if (isLoaded === 0) {
            notifications.warning('Error related to the url link of the selected report');
          }
        } catch (err) {
          notifications.warning('Error into the try/catch iframe onload section');
        }
      }

    },

    async getUrl(item, runshedule) {
      const result = await this.report.getUrl(item, runshedule);

      if (!Ember.isEmpty(result)) {
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        this.set('iframeSrc', result);
        this.set('reportTitleName', item.name);
        this.set('isReportViewEnabled', true);
      }
    },

    fetchDataQuery() {
      const params = {
        sorts: []
      };

      try {
        // this only applies for server-side pagination
        if (this.get('paginationEnabled')) {
          params.page = {
            offset: this.get('records.length'),
            limit: this.get('limit')
          };
        }

        const sort = this.buildSortParam();

        if (sort) {
          params.sorts.push(sort);
        }

        const includes = this.get('defaultIncludes');

        if (!Ember.isEmpty(includes)) {
          params.includes = includes;
        }

        const filters = this.setupFilterType();
        params.filter = (0, _filters.buildCompoundFilterNode)('and', filters);
        const modelName = this.get('config.modelName');
        return this.query(modelName, params);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Workspace view is not refreshed on selecting or deselecting' + error);
      }
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const activeData = store.peekAll(modelName) || [];
      const filteredData = activeData.filter(filterFn).sort(compareFn);

      if (activeData.content.length === 0) {
        return [];
      } //filteredData = store.findAll('report-list');


      return filteredData;
    },

    dynamicSingleActions: Ember.computed('lastRowClicked', 'table.rows', 'table.rows.@each.{selected,status}', 'selectedWidgetRecord', function () {
      const openAction = this.get('openAction');
      const shareAction = this.get('shareAction');
      const permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.designReports, null);

      if (permGranted) {
        return [openAction, shareAction];
      }

      return [openAction];
    })
  });

  _exports.default = _default;
});