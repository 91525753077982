define("adept-iq/pods/components/rider-management-widget/rider-activity-log/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canShowActivityLogFormModal: false,
    riderId: null,
    tableRef: null,

    init() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const id = this.get('riderChangeSet.id');
      this.set('riderId', id);
      this.set('activityQueryParams', {
        riderId: `${id}`,
        eventLog: true
      });
      Ember.run.next(() => {
        editAPI.setProperties({
          newAction: null,
          deleteAction: null,
          isNewBtnDisabled: false,
          isEditBtnDisabled: true,
          isDeleteBtnDisabled: true,
          isEditing: false
        });
      });
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const tableRef = this.get('tableRef');
      if (tableRef) yield Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      Ember.$('.vertical-collection').scrollTop(0);
    })
  });

  _exports.default = _default;
});