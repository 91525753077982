define("adept-iq/utils/format-text-extension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertToPascalCase = _exports.convertToCapitalize = void 0;
  _exports.formatTextExtension = formatTextExtension;

  function formatTextExtension(className, value) {
    return {
      className: className,
      value: value
    };
  }

  const convertToPascalCase = str => {
    if (str) {
      return str.replace(/\w\S*/g, s => {
        if (s) {
          return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
        }
      });
    }

    return null;
  };

  _exports.convertToPascalCase = convertToPascalCase;

  const convertToCapitalize = str => {
    if (typeof str !== 'string') return null;

    if (str && str.trim()) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return null;
  };

  _exports.convertToCapitalize = convertToCapitalize;
});