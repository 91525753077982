define("adept-iq/scenarios/rider-management-service", ["exports", "ember-data-factory-guy"], function (_exports, _emberDataFactoryGuy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Just for fun, set the log level ( to 1 ) and see all FactoryGuy response info in console
  _emberDataFactoryGuy.Scenario.settings({
    logLevel: 0 // 1 is the max for now, default is 0

  });

  class _default extends _emberDataFactoryGuy.Scenario {
    run() {
      const travelNeedType = this.store.peekAll('travel-need-type').firstObject;
      const passengerType = this.store.peekAll('passenger-type').firstObject;
      const eligibilityType = this.store.peekAll('eligibility-type').firstObject;
      const primaryType = this.store.peekRecord('rider-place-type', 'primary');
      const addresses = (0, _emberDataFactoryGuy.makeList)('address', 10);
      addresses.forEach(address => {
        const location = (0, _emberDataFactoryGuy.make)('location', {
          address
        });
        const place = (0, _emberDataFactoryGuy.make)('place', {
          address,
          location
        });
        const riderTravelNeed = (0, _emberDataFactoryGuy.make)('rider-travel-need', {
          passengerType,
          travelNeedType
        });
        const riderEligibility = (0, _emberDataFactoryGuy.make)('rider-eligibility', {
          eligibilityType
        });
        const riderEligibility2 = (0, _emberDataFactoryGuy.make)('rider-eligibility', {
          eligibilityType
        });
        const riderPhoneNumber = (0, _emberDataFactoryGuy.make)('rider-phone-number');
        const rider = (0, _emberDataFactoryGuy.make)('rider', {
          riderTravelNeeds: [riderTravelNeed],
          riderEligibilities: [riderEligibility, riderEligibility2],
          riderPhoneNumbers: [riderPhoneNumber]
        });
        (0, _emberDataFactoryGuy.make)('rider-place', {
          place,
          rider,
          riderPlaceType: primaryType
        });
      });
    }

  }

  _exports.default = _default;
});