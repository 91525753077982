define("adept-iq/pods/components/iq-widgets/delete-subscriptions-form/cancel-related-trips/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N2INixfh",
    "block": "{\"symbols\":[\"trip\"],\"statements\":[[7,\"div\"],[11,\"class\",\"passanger-list-form\"],[9],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"cancel-trip-para\"],[9],[0,\"This passenger has trip scheduled for this subscription. You may choose to cancel any of\\n    them individually, or\\n    cancel all of them by selecting the checkbox\"],[10],[0,\"\\n  \"],[7,\"table\"],[11,\"class\",\"g-side-drawer-panel-form  w-100 m-0\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"subscriptionTrips\",\"length\"]],0],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"form-widget-readonly-section\"],[9],[0,\"\\n        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"td\"],[11,\"class\",\"delete-sub\"],[9],[0,\"\\n            \"],[7,\"label\"],[9],[0,\"\\n              \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"checked\",\"click\"],[\"checkbox\",\"mr-1\",[23,[\"isAllChecked\"]],[27,\"action\",[[22,0,[]],\"onCheckAllTrips\"],null]]]],false],[0,\"\\n              \"],[7,\"strong\"],[9],[0,\"All\"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"subscriptionTrips\"]]],null,{\"statements\":[[0,\"              \"],[7,\"label\"],[11,\"class\",\"ml-4 d-block\"],[9],[0,\"\\n                \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"checked\",\"click\"],[\"checkbox\",\"mr-2\",[22,1,[\"isChecked\"]],[27,\"action\",[[22,0,[]],\"onSelectedTrip\",[22,1,[]]],null]]]],false],[0,\"\\n                \"],[7,\"strong\"],[9],[0,\"Trip \"],[1,[22,1,[\"tripId\"]],false],[10],[0,\"\\n              \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/delete-subscriptions-form/cancel-related-trips/template.hbs"
    }
  });

  _exports.default = _default;
});