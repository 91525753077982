define("adept-iq/models/shift-break", ["exports", "ember-data", "adept-iq/models/break-type", "ember-cp-validations"], function (_exports, _emberData, _breakType, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default; // model validation

  const Validations = (0, _emberCpValidations.buildValidations)({
    minutesPlannedDuration: {
      description: 'Break Length',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: '{description} cannot be blank'
      }), (0, _emberCpValidations.validator)('length', {
        max: 3
      })]
    },
    requestTime: {
      description: 'Start Time',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: '{description} cannot be blank'
      })]
    },
    notes: {
      description: 'notes',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 4096
      })]
    }
  });

  var _default = Model.extend(Validations, {
    requestTime: attr('date', {
      defaultValue: null
    }),
    promisedStart: attr('string', {
      defaultValue: '06:00:00'
    }),
    plannedDuration: attr('number', {
      defaultValue: 15
    }),
    // seconds
    minutesPlannedDuration: attr('number', {
      defaultValue: 15
    }),
    notes: attr('string'),
    breakType: belongsTo('break-type'),
    place: belongsTo('place'),
    routeTemplateAvailability: belongsTo('route-template-availability'),
    isFixedBreak: Ember.computed.equal('breakType.name', _breakType.FIXED_BREAK),
    isFloatingBreak: Ember.computed.equal('breakType.name', _breakType.FLOATING_BREAK)
  });

  _exports.default = _default;
});