define("adept-iq/pods/components/form-components/boolean-slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C4JHDoT0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"onoffswitch\"],[9],[0,\"\\n  \"],[7,\"input\"],[12,\"id\",[21,\"sliderIdPath\"]],[11,\"class\",\"form-control onoffswitch-checkbox\"],[12,\"checked\",[21,\"booleanValue\"]],[12,\"oninput\",[27,\"action\",[[22,0,[]],\"onSliderToggle\"],null]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n\\n  \"],[7,\"label\"],[11,\"class\",\"onoffswitch-label\"],[12,\"for\",[21,\"sliderIdPath\"]],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"onoffswitch-inner\"],[9],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"onoffswitch-switch\"],[11,\"tabindex\",\"0\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/boolean-slider/template.hbs"
    }
  });

  _exports.default = _default;
});