define("adept-iq/pods/components/workspace-navbar-reconcile/component", ["exports", "adept-iq/pods/components/workspace-navbar-reconcile/stub", "moment", "adept-iq/config/mapped-permIds"], function (_exports, _stub, _moment, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['workspace-navbar'],
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    dwhService: Ember.inject.service(),
    timeFormat: 'm-d-Y',
    submitBtnClicked: false,
    selectedProvider: Ember.computed.alias('dwhService.selectedProvider'),
    selectedRoute: Ember.computed.alias('dwhService.selectedRoute'),
    routeOptions: Ember.computed.alias('dwhService.routeOptions'),
    providerOptions: Ember.computed('dwhService.routes', function () {
      const routes = this.get('dwhService.routes');
      const providerSet = routes.reduce((pSet, route) => {
        pSet.add(route.provider);
        return pSet;
      }, new Set());
      return Array.from(providerSet).map(p => {
        return {
          id: p,
          name: p
        };
      });
    }),
    submitBtnDisabled: Ember.computed.empty('dwhService.reconcileDate'),
    providerOptionEnabled: Ember.computed.gt('providerOptions.length', 0),
    routeOptionEnabled: Ember.computed.gt('routeOptions.length', 0),
    searchOptionEnabled: Ember.computed.not('submitBtnDisabled'),
    disableAddRouteOption: Ember.computed.empty('selectedProvider'),
    searchOptions: Ember.computed('selectedSearchMode', function () {
      const searchModes = _stub.default.searchOptions;
      return this.enableDisableOption(searchModes);
    }),

    enableDisableOption(options) {
      const optionArray = [];
      options.map(opt => {
        const option = {
          disabled: false,
          name: opt
        };

        switch (opt) {
          case 'Client':
            option.disabled = !this.isSearchByClientAllowed;
            break;

          case 'Driver':
            option.disabled = !this.isSearchByDriverAllowed;
            break;

          case 'Vehicle':
            option.disabled = !this.isSearchByVehicleAllowed;
            break;

          case 'Address':
            option.disabled = !this.isSearchByAddressAllowed;
            break;

          case 'Trip':
            option.disabled = !this.isSearchByTripAllowed;
            break;

          default:
            break;
        }

        optionArray.push(option);
      });
      return optionArray;
    },

    updateReconcileSearchRoute: Ember.observer('dwhService.selectedSearchRoute', function () {
      const selectedSearchRoute = this.get('dwhService.selectedSearchRoute');

      if (selectedSearchRoute) {
        this.set('selectedRoute', selectedSearchRoute);
      }
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'selectedDateTimeRage', {
        today: (0, _moment.default)().subtract(1, 'day').format('MM-DD-YYYY'),
        date: null,
        starttime: null,
        endtime: null,
        startforendtime: (0, _moment.default)().startOf('day').toISOString(),
        startofday: (0, _moment.default)().startOf('day').toISOString(),
        endofday: (0, _moment.default)().endOf('day').toISOString(),
        endDateSelection: (0, _moment.default)().subtract(7, 'years').format('MM-DD-YYYY')
      });
      this.configurePermission(); // this.set('providerOptions', this.get('store').peekAll('provider'));

      this.set('dwhService.routes', []);
      this.resetTripFromMap();
    },

    configurePermission() {
      Ember.set(this, 'isAddNewRouteAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.addNewRouteRecon, null));
      Ember.set(this, 'isCloseDateAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.closeEditDateRecon, null));
      Ember.set(this, 'isReopenDateAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.reopenEditDateRecon, null));
      Ember.set(this, 'isSearchByClientAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByClientRecon, null));
      Ember.set(this, 'isSearchByDriverAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByDriverRecon, null));
      Ember.set(this, 'isSearchByVehicleAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByVehicleRecon, null));
      Ember.set(this, 'isSearchByAddressAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByAddrRecon, null));
      Ember.set(this, 'isSearchByTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByTripRecon, null));
    },

    didInsertElement() {
      this._super(...arguments);
    },

    callbackHandler(optionId) {
      if (optionId === 'reconcileAddNewRoute') {
        Ember.set(this, 'workspace.activeContext.topActiveContext.resetMapFlag', true);
      }
    },

    resetTripFromMap() {
      Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', {
        'reconcile-trip': [],
        'reconcile-trip-stops': []
      });
    },

    async fetchRoutesOnTripDate() {
      const date = this.get('dwhService.reconcileDate');
      const dateString = (0, _moment.default)(date).format('YYYY-MM-DD'); // const data = await this.get('dwhService').fetchDwhRoutes(dateString);

      const dwhRouteAdapter = this.get('store').adapterFor('dwh-route');
      const routes = await dwhRouteAdapter.getDwhRoutesByDate(dateString);
      this.set('dwhService.routes', routes);
    },

    actions: {
      onSearchOptionSelect(option) {
        this.resetTripFromMap();
        Ember.set(this, 'selectedMode', option);
        Ember.set(this, 'selectedSearchMode', option.name.toLowerCase());
        this.dwhService.closePopups();
        this.get('dwhService').toggleProperty('showSearchPopUp');
        Ember.set(this, 'dwhService.selectedRoute', null);
        Ember.set(this, 'dwhService.seletectedProvider', null);
      },

      onProviderOptionSelect(option) {
        this.resetTripFromMap();
        this.set('dwhService.selectedProvider', option);
        this.dwhService.closePopups();
      },

      async onRouteOptionSelect(option) {
        this.resetTripFromMap();
        Ember.set(this, 'dwhService.selectedRoute', option);
        await this.get('dwhService.fetchTripsWithBreaks').perform(option.id);
        Ember.set(this, 'selectedSearchMode', null);
        this.dwhService.closePopups();
      },

      onChangeTripDate(valuePath, value) {
        // this.resetTripFromMap();
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
      },

      onSubmitButtonClick() {
        // this.set('submitBtnClicked', true);
        // this.fetchRoutesOnTripDate();
        this.get('activeContext.topActiveContext').refreshAll();
      },

      onAddRouteButtonClick() {
        const provider = Ember.get(this, 'selectedProvider') ? Ember.get(this, 'selectedProvider') : '';
        const routeId = Ember.get(this, 'selectedRoute') ? Ember.get(this, 'selectedRoute').routeId : '';

        if (provider) {
          this.get('workspace').pushState('reconcileAddNewRoute', {
            provider,
            routeId,
            action: this.callbackHandler.bind(this, 'reconcileAddNewRoute')
          });
        }
      },

      reopenDatesClick() {
        this.get('workspace').pushState('reconcileOpenDates', {});
      },

      closeDatesClick() {
        this.get('workspace').pushState('reconcileCloseDates', {});
      },

      closePopupHandler() {
        if (this.dwhService.closePopupHandler) {
          this.dwhService.closePopupHandler();
        }
      }

    }
  });

  _exports.default = _default;
});