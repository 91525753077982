define("adept-iq/mixins/otp-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    otpLabel: Ember.computed('otp', function () {
      const otp = this.get('otp');

      switch (otp) {
        case 'O':
          return 'On Time';

        case 'D':
          return 'In Danger';

        case 'L':
          return 'Late';

        case 'E':
          return 'Early';

        default:
          return '';
      }
    })
  });

  _exports.default = _default;
});