define("adept-iq/adapters/dispatch-schedule", ["exports", "adept-iq/adapters/-dispatch", "adept-iq/config/api-urls"], function (_exports, _dispatch, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    iquxServiceHost: _apiUrls.API.iquxService.host,

    waitListTrip(tripId) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const url = `${this.host}/trip/${tripId}/waitlist`;
      return ajax.delete(url, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    urlForFindRecord(id, modelName
    /*snapshot*/
    ) {
      const iquxServiceHost = this.get('iquxServiceHost');
      const baseUrl = `${iquxServiceHost}/${modelName}/${id}`;
      return baseUrl;
    }

  });

  _exports.default = _default;
});