define("adept-iq/models/avlm-address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    description: attr('string'),
    premise: attr('string', {
      defaultValue: ''
    }),
    streetNumber: attr('string'),
    streetName: attr('string'),
    locality: attr('string', {
      defaultValue: ''
    }),
    subRegion: attr('string', {
      defaultValue: ''
    }),
    region: attr('string', {
      defaultValue: ''
    }),
    postalCode: attr('string', {
      defaultValue: ''
    }),
    country: attr('string', {
      defaultValue: ''
    }),
    latitude: attr('number'),
    longitude: attr('number'),
    // needed to syncronize attribute name with tomtom
    countrySecondarySubdivision: Ember.computed('region', function () {
      return this.get('region');
    }),
    // computed position is needed because of dealing with tomtom address format
    // and other format model from booking service, rider management,...
    position: Ember.computed('latitude', 'longitude', function () {
      return {
        lat: this.get('latitude'),
        lng: this.get('longitude')
      };
    }),
    tomtomFormattedAddress: Ember.computed('premise', 'streetNumber', 'streetName', 'locality', function () {
      let address = null;

      if (!Ember.isNone(this.get('streetName'))) {
        let tempAddress = `${this.get('streetNumber')} ${this.get('streetName')} ${this.get('locality')}`;

        if (!Ember.isNone(this.get('premise'))) {
          tempAddress = `${this.get('premise')} - ` + tempAddress;
        }

        address = {
          address: {
            freeformAddress: tempAddress
          }
        };
      }

      return address;
    }),
    streetAddress: Ember.computed('description', 'premise', 'streetNumber', 'streetName', function () {
      let address = null;

      if (!Ember.isNone(this.get('streetName'))) {
        address = `${this.get('streetNumber')} ${this.get('streetName')}`;

        if (!Ember.isNone(this.get('alias'))) {
          address = `${this.get('alias')} ${this.get('streetNumber')} ${this.get('streetName')}`;
        }

        if (!Ember.isNone(this.get('premise')) && !Ember.isNone(this.get('description'))) {
          address = `${this.get('description')} ${this.get('premise')} - ${this.get('streetNumber')} ${this.get('streetName')}`;
        }
      }

      return address;
    }),
    address: Ember.computed('description', 'premise', 'streetNumber', 'streetName', 'locality', function () {
      let address = null;

      if (!Ember.isNone(this.get('streetName'))) {
        let tempAddress = `${this.get('streetNumber')} ${this.get('streetName')}`;

        if (!Ember.isNone(this.get('alias'))) {
          tempAddress = `${this.get('alias')} ${this.get('streetNumber')} ${this.get('streetName')}`;
        }

        if (!Ember.isNone(this.get('premise')) && !Ember.isNone(this.get('description'))) {
          tempAddress = `${this.get('description')} ${this.get('premise')} - ${this.get('streetNumber')} ${this.get('streetName')} ${this.get('locality')}`;
        }

        address = {
          freeformAddress: tempAddress
        };
      }

      return address;
    }),
    location: Ember.computed('locations.[]', function () {
      return this.get('locations').firstObject;
    }),
    fullAddress: Ember.computed('streetNumber', 'streetName', 'locality', 'region', 'postalCode', 'alias', function () {
      const alias = null;
      const streetNumber = Ember.get(this, 'streetNumber');
      const streetName = Ember.get(this, 'streetName');
      const locality = Ember.get(this, 'locality');
      const region = Ember.get(this, 'region');
      const postalCode = Ember.get(this, 'postalCode');
      const address = [];

      if (alias) {
        address.push(alias + ',');
      }

      address.push(streetNumber);
      address.push(streetName);
      const result = [address.join(' ')];
      result.push(locality);
      result.push(region);
      result.push(postalCode);
      return result.join(', ');
    }).readOnly()
  });

  _exports.default = _default;
});