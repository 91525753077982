define("adept-iq/adapters/-reconciliationService", ["exports", "adept-iq/adapters/application", "adept-iq/config/api-urls"], function (_exports, _application, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    defaultSerializer: '-reconciliationService',
    host: _apiUrls.API.reconciliationService.host,

    pathForType(type) {
      // trim 'bs' prefix from model type
      const regEx = /^(?:avlm-)?(.*)$/;
      const match = regEx.exec(type)[1];
      return this._super(match);
    }

  });

  _exports.default = _default;
});