define("adept-iq/services/scheduling", ["exports", "adept-iq/config/api-urls"], function (_exports, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    updateIllegalTripsToWaitlistTrips(scheduleId) {
      return this.get('ajax').request(_apiUrls.API.schedulingService.host + `/schedule/${scheduleId}/waitlist-illegal`, {
        method: 'PATCH',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
        },
        data: {}
      }).then(() => {
        return true;
      }).catch(() => {
        return false;
      });
    }

  });

  _exports.default = _default;
});