define("adept-iq/config/phonetic-substitutions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.phoneticSubstitutions = void 0;
  const phoneticSubstitutions = [{
    abbr: 'N',
    regex: / N(\W|$)/g,
    replacement: ' north$1'
  }, {
    abbr: 'S',
    regex: / S(\W|$)/g,
    replacement: ' south$1'
  }, {
    abbr: 'E',
    regex: / E(\W|$)/g,
    replacement: ' east$1'
  }, {
    abbr: 'W',
    regex: / W(\W|$)/g,
    replacement: ' west$1'
  }, {
    abbr: 'Apt',
    regex: / A[pP][tT](\W|$)/g,
    replacement: ' apartment$1'
  }, {
    abbr: 'Ave',
    regex: / [aA]ve(\W|$)/g,
    replacement: ' avenue$1'
  }, {
    abbr: 'Av',
    regex: / A[vV](\W|$)/g,
    replacement: ' avenue$1'
  }, {
    abbr: 'Blvd',
    regex: / B[lL][vV][dD](\W|$)/g,
    replacement: ' boulevard$1'
  }, {
    abbr: 'Bldg',
    regex: / B[lL][dD][gG](\W|$)/g,
    replacement: ' building$1'
  }, {
    abbr: 'Ctr',
    regex: / C[tT][rR](\W|$)/g,
    replacement: ' center$1'
  }, {
    abbr: 'Cir',
    regex: / C[iI][rR](\W|$)/g,
    replacement: ' circle$1'
  }, {
    abbr: 'Ct',
    regex: / C[tT](\W|$)/g,
    replacement: ' court$1'
  }, {
    abbr: 'Dr',
    regex: / D[rR](\W|$)/g,
    replacement: ' drive$1'
  }, {
    abbr: 'Expy',
    regex: / E[xX][pP][yY](\W|$)/g,
    replacement: ' expressway$1'
  }, {
    abbr: 'Ext',
    regex: / E[xX][tT](\W|$)/g,
    replacement: ' extension$1'
  }, {
    abbr: 'Ft',
    regex: / F[tT](\W|$)/g,
    replacement: ' fort$1'
  }, {
    abbr: 'Fwy',
    regex: / F[wW][yY](\W|$)/g,
    replacement: ' freeway$1'
  }, {
    abbr: 'Hts',
    regex: / H[tT][sS](\W|$)/g,
    replacement: ' heights$1'
  }, {
    abbr: 'Hwy',
    regex: / H[wW][yY](\W|$)/g,
    replacement: ' highway$1'
  }, {
    abbr: 'Is',
    regex: / I[sS](\W|$)/g,
    replacement: ' island$1'
  }, {
    abbr: 'Jct',
    regex: / J[cC][tT](\W|$)/g,
    replacement: ' junction$1'
  }, {
    abbr: 'Ln',
    regex: / L[nN](\W|$)/g,
    replacement: ' lane$1'
  }, {
    abbr: 'Mt',
    regex: / M[tT](\W|$)/g,
    replacement: ' mountain$1'
  }, {
    abbr: 'NE',
    regex: / N[eE](\W|$)/g,
    replacement: ' northeast$1'
  }, {
    abbr: 'NW',
    regex: / N[wW](\W|$)/g,
    replacement: ' northwest$1'
  }, {
    abbr: 'Pky',
    regex: / P[kK][yY](\W|$)/g,
    replacement: ' parkway$1'
  }, {
    abbr: 'Pl',
    regex: / P[lL](\W|$)/g,
    replacement: ' place$1'
  }, {
    abbr: 'PO',
    regex: / P[oO](\W|$)/g,
    replacement: ' post pffice$1'
  }, {
    abbr: 'Rd',
    regex: / R[dD](\W|$)/g,
    replacement: ' road$1'
  }, {
    abbr: 'RR',
    regex: / R[rR](\W|$)/g,
    replacement: ' rural route$1'
  }, {
    abbr: 'Rte',
    regex: / R[tT][eE](\W|$)/g,
    replacement: ' rural route$1'
  }, {
    abbr: 'SE',
    regex: / S[eE](\W|$)/g,
    replacement: ' southeast$1'
  }, {
    abbr: 'SW',
    regex: / S[wW](\W|$)/g,
    replacement: ' southwest$1'
  }, {
    abbr: 'Spg',
    regex: / S[pP][gG](\W|$)/g,
    replacement: ' spring$1'
  }, {
    abbr: 'Spgs',
    regex: / S[pP][gG][sS](\W|$)/g,
    replacement: ' springs$1'
  }, {
    abbr: 'Sq',
    regex: / S[qQ](\W|$)/g,
    replacement: ' square$1'
  }, {
    abbr: 'St',
    regex: / S[tT](\W|$)/g,
    replacement: ' street$1'
  }, {
    abbr: 'St',
    regex: / S[tT][.](\W|$)/g,
    replacement: ' saint$1'
  }, {
    abbr: 'Ste',
    regex: / S[tT][eE](\W|$)/g,
    replacement: ' suite$1'
  }, {
    abbr: 'Ter',
    regex: / T[eE][rR](\W|$)/g,
    replacement: ' terrace$1'
  }, {
    abbr: 'Tpke',
    regex: / T[pP][kK][eE](\W|$)/g,
    replacement: ' turnpike$1'
  }, {
    abbr: 'Bdwy',
    regex: / B[dD][wW][yY](\W|$)/g,
    replacement: ' broadway$1'
  }, {
    abbr: 'Pkwy',
    regex: / P[kK][wW][yY](\W|$)/g,
    replacement: ' parkway$1'
  }, {
    abbr: 'Jctn',
    regex: / J[cC][tT][nN](\W|$)/g,
    replacement: ' junction$1'
  }, {
    abbr: 'No',
    regex: / N[oO](\W|$)/g,
    replacement: ' number$1'
  }];
  _exports.phoneticSubstitutions = phoneticSubstitutions;
});