define("adept-iq/pods/components/dashboard-edit-as/component", ["exports", "adept-iq/config/mapped-permIds", "ember-concurrency"], function (_exports, _mappedPermIds, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    session: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    classNames: ['dashboard-save-as'],
    name: null,
    displayName: Ember.computed.readOnly('workspace.topOptions.displayName'),
    workspaceName: Ember.computed.readOnly('workspace.topOptions.workspaceName'),
    isLoading: Ember.computed.oneWay('findUnusedNameTask.isRunning'),
    isRunning: Ember.computed.or('saveTask.isRunning', 'cancelTask.isRunning'),

    didInsertElement() {
      this._super(...arguments);

      const baseName = this.get('workspaceName');
      this.set('name', baseName);

      this._onKeyDown = event => {
        Ember.run.schedule('afterRender', this, 'onKeyDown', event);
      };

      this.$().on('keydown', this._onKeyDown);
    },

    willDestroyElement() {
      this.$().off('keydown', this._onKeyDown);
      this._onKeyDown = null;

      this._super(...arguments);
    },

    cancelTask: (0, _emberConcurrency.task)(function* () {
      yield this.get('workspace').popState('editWorkspaceAs');
    }).drop(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const permissionLayer = this.get('permissionLayer');
      const userId = this.get('session').data.authenticated.userId.replace(/-/g, '_');
      const baseName = this.get('workspaceName');
      const notifications = this.get('notifications');
      const dashboards = this.get('store').peekAll('dashboard');
      const dashboardModel = dashboards.findBy('name', baseName);
      const currentDashboard = this.get('workspace.dashboardModel');
      const savePrivateWorkspacePerm = permissionLayer.permInUserHash(_mappedPermIds.default.savePrivateWorkspace);
      const saveWorkspacePerm = permissionLayer.permInUserHash(_mappedPermIds.default.saveWorkspace);
      const category = `workspaces-${userId}`;

      if (dashboardModel.get('isPublicWorkspace') && !saveWorkspacePerm && dashboardModel.get('userId') !== userId || dashboardModel.get('isPrivateWorkspace') && (dashboardModel.get('category') !== category || !savePrivateWorkspacePerm)) {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'Save Workspace',
          tip: 'Unable to save. You must use the "Save Workspace As" option to save changes made to a public workspace.',
          warning: true,
          primaryActionText: 'Ok',

          primaryAction() {
            tooltip.reset();
          }

        });
        return;
      }

      const isUpdatingCurrent = dashboardModel === currentDashboard;
      const options = dashboardModel.getOptionsForClone();
      const clone = this.get('store').createRecord('dashboard', options); // update name using value in input box

      clone.set('name', this.get('name'));
      clone.set('userId', userId);

      if (!this.get('isPublic')) {
        clone.set('category', `workspaces-${userId}`);
      }

      try {
        yield clone.save();
        yield dashboardModel.destroyRecord();
        yield dashboardModel.unloadRecord();

        if (isUpdatingCurrent) {
          yield this.get('router').transitionTo('dashboard', clone);
        }

        notifications.success(`Successfully Saved Workspace ${this.get('name')}`);
        yield this.get('workspace').popState('editWorkspaceAs');
      } catch (e) {
        notifications.warning(`Failed to Save Workspace ${this.get('name')}`);
        clone.destroyRecord();
        clone.unloadRecord();
      } finally {
        this.get('tooltip').reset();
      }
    }).drop(),

    onSaveWorkspace() {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        tip: 'Are you sure you want to commit these changes?',
        title: 'Workspace',
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('saveTask').perform();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
          return;
        }
      });
    },

    onKeyDown(event) {
      switch (event.key) {
        case 'Enter':
          this.onSaveWorkspace();
          break;

        default:
          return null;
      }
    },

    actions: {
      onSaveClick() {
        this.onSaveWorkspace();
      }

    }
  });

  _exports.default = _default;
});