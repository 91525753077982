define("adept-iq/transforms/array", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Converts centigrade in the JSON to fahrenheit in the app
  var _default = _emberData.default.Transform.extend({
    deserialize: function (serialized) {
      return Array.isArray(serialized) ? serialized : [];
    },
    serialize: function (deserialized) {
      if (Array.isArray(deserialized)) {
        return deserialized;
      } else if (typeof deserialized === 'string') {
        return deserialized.split(',').map(function (item) {
          return Ember.$.trim(item);
        });
      }

      return [];
    }
  });

  _exports.default = _default;
});