define("adept-iq/pods/components/iq-widgets/previous-bookings-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/previous-bookings-widget/config", "adept-iq/pods/components/iq-widgets/previous-bookings-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/config/mapped-permIds", "lodash", "moment", "adept-iq/config/api-urls", "adept-iq/utils/sorts"], function (_exports, _component, _config, _avlmConfig, _environment, _mappedPermIds, _lodash, _moment, _apiUrls, _sorts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];
  const SEGMENT_TYPE_VALUE_FOR_ITP = 3;
  const SCHEDULE_USER_ROLES = ['scheduler', 'scheduling'];
  const BOOKING_USER_ROLES = ['booking', 'bookingagent'];
  const CANCEL_TEXT = 'CANCEL';
  const EDIT_TEXT = 'EDIT';
  const DELETE_TEXT = 'DELETE';
  const UPDATE_WAIT_TIME = 15000;

  var _default = _component.default.extend({
    classNames: ['previous-booking-widget'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    booking: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    widgetService: Ember.inject.service('widget'),
    editComponent: 'iq-widgets/passengers-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    canShowHeader: true,
    config: _config.default,
    defaultIncludes: INCLUDES,
    isDeleteConfirm: false,
    selectedRiders: null,
    cancelAction: null,
    editAction: null,
    deleteAction: null,
    viewItineraryAction: null,
    bookingForEdit: null,
    bookingForCancel: null,
    cutOffTime4LateCancelTrip: 0,
    lateTripCancelMessage: null,
    cancelBookingComponent: 'iq-widgets/cancel-booking-form',
    deleteBookingComponent: 'iq-widgets/delete-booking-form',
    newBookingComponent: 'iq-widgets/new-booking-form',
    viewItineraryComponent: 'iq-widgets/view-itinerary-modal',
    updateRecords: true,

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);

      this.onBookingEdit = this.onBookingEdit.bind(this);
      this.onCopyBooking = this.onCopyBooking.bind(this);
      this.set('selectedRiders', []);
      this.get('tableColumns').popObject();
      this.get('tableColumns').lastObject.resizable = false;
      this.onGroupBookingEditConfirm = this.onGroupBookingEditConfirm.bind(this);
      this.lateTripCancelBooking = this.lateTripCancelBooking.bind(this);
      this.onLateTripCancelBooking = this.onLateTripCancelBooking.bind(this);
      this.getCurrentBookingRecords = this.getCurrentBookingRecords.bind(this);
      this.onPreviousBookingsChange();
      this.set('getRecords', this.getCurrentBookingRecords);
      this.updateSelectedBooking();
    },

    willDestroyElement() {
      this.set('updateRecords');

      this._super(...arguments);
    },

    async updateSelectedBooking() {
      const currentTrips = this.get('booking.currentTrips') || [];
      if (!this.get('updateRecords')) return;

      try {
        const currTripUpdatePromises = await this.get('apiContext').updateTripsFromIQUXService(currentTrips);
        Promise.all(currTripUpdatePromises).then(() => {
          Ember.run.later(() => this.updateSelectedBooking(), UPDATE_WAIT_TIME);
        });
      } catch (e) {
        this.get('notifications').warning('missing trip from iqux service ' + e.message);
      }
    },

    onPreviousBookingsChange: Ember.observer('booking.currentBookings.[]', 'booking.currentBookings', function () {
      this.sortLegsInAllBookings();
    }),
    onCurrentTripsChange: Ember.observer('booking.currentTrips.[]', 'booking.currentTrips', function () {
      this.filterTripsForselectedRiders();
    }),

    sortLegsInAllBookings() {
      const currentBookings = this.getCurrentBookingRecords();
      const sort = this.buildSortParam();
      const compareFn = (0, _sorts.buildCompareFunction)([sort]);
      this.set('records', currentBookings.sort(compareFn));
    },

    getCurrentBookingRecords() {
      const currentBookings = this.get('booking.currentBookings') || [];
      return currentBookings.map(booking => {
        const legs = booking.get('legs');
        const sortedLegs = legs.sortBy('legOrdinal');
        booking.set('legs', sortedLegs);
        return booking;
      });
    },

    showGroupBookingWarning() {
      this.get('tooltip').pushConfirmation({
        title: 'Edit Booking',
        tip: 'Are you sure you want to edit this Group booking?',
        primaryActionText: 'Confirm',
        primaryAction: this.onGroupBookingEditConfirm,
        secondaryActionText: 'Cancel',
        hasOverlay: true,
        secondaryAction: () => {
          this.get('tooltip').reset();
        }
      });
    },

    showLateTripCancelBookingWarning() {
      this.get('tooltip').pushConfirmation({
        title: 'LateTripCancel',
        tip: this.get('lateTripCancelMessage').get('value'),
        primaryActionText: 'Ok',
        primaryAction: this.lateTripCancelBooking,
        hasOverlay: true,
        secondaryAction: () => {
          this.get('tooltip').reset();
        }
      });
    },

    async getRequestDetail(url) {
      const session = this.get('session');
      const data = await this.get('ajax').request(url, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
      return data;
    },

    async checkRouteLockedByUnspecifiedUser(trips) {
      const roleNames = this.get('session.data.authenticated.tokenInfo.roleNames');
      const isCurrentUserIsBookingUser = roleNames.some(roleName => {
        return BOOKING_USER_ROLES.includes(roleName.toLowerCase());
      });
      if (!isCurrentUserIsBookingUser) return false;
      const routeData = trips.filter(trip => trip.get('route.id')).map(async filteredTrip => {
        return await this.getRequestDetail(_apiUrls.API.iquxService.host + '/routeWidget/' + filteredTrip.get('route.id'));
      });
      const resolvedRoute = await Promise.all(routeData);
      const filteredRouteLock = resolvedRoute.filter(route => route.data.attributes.isLocked ? route.data.attributes.isLocked : false);
      let lockedUserDetails = [];

      if (filteredRouteLock.length) {
        const ssoUserDetails = filteredRouteLock.map(async route => {
          const lockDetails = route.data.attributes.lock;
          return await this.getRequestDetail(_apiUrls.API.ssoService.host + '/user/' + lockDetails.user);
        });
        lockedUserDetails = await Promise.all(ssoUserDetails);
      }

      let isRoleSchedule = false;
      lockedUserDetails.forEach(user => {
        const roleArray = user.UserRoleRoles.filter(role => SCHEDULE_USER_ROLES.includes(role.roleName.toLowerCase()));
        if (roleArray.length) isRoleSchedule = true;
      });
      return isRoleSchedule;
    },

    async onLateTripCancelBooking(booking) {
      const trips = booking.get('trips');
      const isTripRelatedRouteIsLocked = await this.checkTripRelatedRouteIsLocked(trips);

      if (isTripRelatedRouteIsLocked) {
        this.showRouteLockWarning(CANCEL_TEXT);
        return;
      }

      this.set('bookingForCancel', booking);
      const isRouteLockedByUnspecifiedUser = await this.checkRouteLockedByUnspecifiedUser(booking.get('trips'));

      if (isRouteLockedByUnspecifiedUser) {
        this.get('notifications').warning('UNABLE TO CANCEL BOOKING. THE ROUTE IS CURRENTLY LOCKED.');
        return;
      }

      const requestTime = booking.get('legs.firstObject.requestTime');
      const value = Number(this.get('cutOffTime4LateCancelTrip').get('value')) / 60 || Number(this.get('cutOffTime4LateCancelTrip').get('defaultValue')) / 60;
      const bookingDate = (0, _moment.default)(requestTime);
      const currentDate = (0, _moment.default)().startOf('day');
      /*if this is set to 120 minutes (2 hours), then trip with a 9:00 AM promise time will be considered a late cancel if the cancellation occurs anytime after 7:00 AM*/

      const lessTwoHour = bookingDate.subtract(value, 'hours');
      const currentTime = (0, _moment.default)();

      if (bookingDate.date() <= currentDate.date() && (0, _moment.default)(lessTwoHour).isBefore(currentTime)) {
        this.showLateTripCancelBookingWarning();
      } else {
        this.onOpenCancelBooking(booking, 'cancelled');
      }
    },

    lateTripCancelBooking() {
      this.onOpenCancelBooking(this.get('bookingForCancel'), 'lateCancelled');
      this.get('tooltip').reset();
    },

    async onGroupBookingEditConfirm() {
      await this.openBookingForEdit(this.get('bookingForEdit'));
      this.get('tooltip').reset();
    },

    onOpenCancelBooking(booking, status) {
      const cancelBookingComponent = this.get('cancelBookingComponent');
      this.get('editModal').open(cancelBookingComponent, [booking, status]);
    },

    dedupeLegs(legsIncludingDupe, riderExternalIds) {
      const anyOneRider = riderExternalIds[0];
      const legsArray = legsIncludingDupe.toArray();
      const legsGroupedByRider = (0, _lodash.groupBy)(legsArray, leg => {
        return leg.get('rider.id');
      });
      return legsGroupedByRider[anyOneRider];
    },

    async fetchPlaces(legsInBooking) {
      const placeIds = (0, _lodash.compact)((0, _lodash.uniq)((0, _lodash.flattenDeep)(legsInBooking.map(leg => {
        return leg.get('segments').map(segment => [segment.get('pick.place.id'), segment.get('drop.place.id')]);
      }))));
      if (!placeIds.length) return;
      const query = 'in(\'id\',(' + placeIds + '))&include=address,location';
      return await this.store.query('place', {
        filter: query
      });
    },

    openBookingForEdit: async function (booking) {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const bookingId = booking.get('id');

      try {
        await this.get('store').queryRecord('booking', bookingId);
        const bookingRecord = this.get('store').peekRecord('booking', bookingId); // todo :should be handled differently

        const dummySubscription = this.get('booking').createSubscriptionRecord();
        await bookingRecord.set('subscription', dummySubscription);
        this.set('booking.activeBooking', bookingRecord);
        const legsInBooking = await bookingRecord.get('legs');
        await this.fetchPlaces(legsInBooking);
        this.get('booking').clearSelectedRiders();
        const legsIncludingDupes = bookingRecord.get('legs');
        const allRiderIdsIncludingDupes = legsIncludingDupes.map(leg => {
          return leg.get('rider.id');
        });
        const riderIds = (0, _lodash.uniq)(allRiderIdsIncludingDupes).filter(rId => rId); // unpersisted record will have null

        const riders = await this.get('booking').fetchAndReturnRiders(riderIds);
        const riderExternalIds = riders.map(r => r.get('id'));
        await this.get('booking').fetchExternalRidersAndSetToSelectedRiders(riderExternalIds, true);
        await this.get('booking').fetchAllSelectedRidersForTravelNeeds();
        await this.get('booking').fetchAndStoreEligibilityForAllSelectedRiders();
        const legsAfterDedupe = this.dedupeLegs(legsIncludingDupes, riderExternalIds);
        const legsWithZName = legsAfterDedupe.map(async leg => {
          const pickZName = leg.get('segments.firstObject.pick.place.address.zoneName.id');
          const dropZName = leg.get('segments.lastObject.drop.place.address.zoneName.id');
          const pickZone = this.store.peekRecord('zone', parseInt(pickZName, 10));
          const dropZone = this.store.peekRecord('zone', parseInt(dropZName, 10));
          const pickZoneName = await this.store.createRecord('zone-type', {
            name: pickZone.get('name'),
            displayName: pickZone.get('displayName')
          });
          const dropZoneName = await this.store.createRecord('zone-type', {
            name: dropZone.get('name'),
            displayName: dropZone.get('displayName')
          });
          leg.set('segments.firstObject.pick.place.address.zName', pickZone.get('displayName'));
          leg.set('segments.lastObject.drop.place.address.zName', dropZone.get('displayName'));
          leg.set('segments.firstObject.pick.place.address.zoneName', pickZoneName);
          leg.set('segments.lastObject.drop.place.address.zoneName', dropZoneName);
          return leg;
        });
        await bookingRecord.get('legs').clear();
        const legsWithZoneName = await Promise.all(legsWithZName);
        bookingRecord.get('legs').pushObjects(legsWithZoneName.sortBy('legOrdinal'));
        const bookingLegs = bookingRecord.get('legs').toArray();
        const isBookingEditMode = true;
        const selectedRider = this.get('booking.selectedRiders').firstObject;
        const newBookingComponent = this.get('newBookingComponent');
        this.get('booking').setIsAllLegField(bookingLegs);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('editModal').open(newBookingComponent, [isBookingEditMode, selectedRider, bookingRecord]);
      } catch (e) {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('notifications').warning('error while editing booking ' + e.message);
      }
    },

    //NYAAR-19188 &NYAAR-19285 this method used for segmentStop pick and drop Phone No field empty from Ui while when we Edit the created booking and Phone Number field displayed blank in Create booking side panel on copying booking
    setSegmentSavedPhoneNumber() {
      const booking = this.get('booking');
      booking.get('segmentStopPhoneNumberIds').forEach(phone => {
        const id = phone.get('id');
        const segmentStopPhoneNumberRecord = this.get('store').peekRecord('segment-stop-phone-number', id);
        const segmentStopId = segmentStopPhoneNumberRecord.get('segmentStop.id');
        const segmentStop = this.get('store').peekRecord('segment-stop', segmentStopId);
        const phoneNumbers = segmentStop.get('phoneNumbers');
        phoneNumbers.pushObject(segmentStopPhoneNumberRecord);
        segmentStop.set('phoneNumbers', phoneNumbers);
      });
    },

    async onBookingEdit(booking) {
      const trips = booking.get('trips');
      const isTripRelatedRouteIsLocked = await this.checkTripRelatedRouteIsLocked(trips);

      if (isTripRelatedRouteIsLocked) {
        this.showRouteLockWarning(EDIT_TEXT);
        return;
      }

      this.set('bookingForEdit', booking);
      const isRouteLockedByUnspecifiedUser = await this.checkRouteLockedByUnspecifiedUser(booking.get('trips'));

      if (isRouteLockedByUnspecifiedUser) {
        this.get('notifications').warning('UNABLE TO EDIT BOOKING. THE ROUTE IS CURRENTLY LOCKED.');
        return;
      }

      this.setSegmentSavedPhoneNumber();

      if (booking.get('isGroupBooking')) {
        this.showGroupBookingWarning();
      } else {
        this.openBookingForEdit(booking);
      }
    },

    async onCopyBooking(selectedBooking) {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      this.setSegmentSavedPhoneNumber();
      const selectedBookingId = selectedBooking.get('id');
      await this.get('store').queryRecord('booking', selectedBookingId);
      const selectedBookingRecord = this.get('store').peekRecord('booking', selectedBookingId); //if the legs are not in ascending order we have to sort that by leg id

      const sortedLegs = selectedBookingRecord.get('legs').sortBy('id');
      selectedBookingRecord.set('legs', sortedLegs);
      const isBookingEditMode = false;
      const newBookingComponent = this.get('newBookingComponent');
      const legsToCopy = selectedBookingRecord.get('legs').toArray();
      const booking = await this.get('booking').createBookingRecord(false, false, selectedBookingRecord.get('id'));
      const isGroupBooking = selectedBookingRecord.get('isGroupBooking');
      const legsIncludingDupes = selectedBookingRecord.get('legs');
      const allRiderIdsIncludingDupes = legsIncludingDupes.map(leg => {
        return leg.get('rider.id');
      });

      if (isGroupBooking) {
        const selectedRiderId = selectedBookingRecord.get('legs.firstObject.rider.id');
        const filteredLegs = legsIncludingDupes.filter(leg => {
          return leg.get('rider.id') === selectedRiderId;
        }).sortBy('id');
        selectedBookingRecord.set('legs', filteredLegs);
      }

      this.set('booking.copiedBookingRecord', selectedBookingRecord); //set selectedRiders for copy-booking

      this.get('booking').clearSelectedRiders();
      const riderIds = (0, _lodash.uniq)(allRiderIdsIncludingDupes).filter(rId => rId); // unpersisted record will have null

      const riders = await this.get('booking').fetchAndReturnRiders(riderIds);
      const riderExternalIds = riders.map(r => r.get('id'));
      await this.get('booking').fetchExternalRidersAndSetToSelectedRiders(riderExternalIds, true);
      await this.get('booking').fetchAllSelectedRidersForTravelNeeds();
      await this.get('booking').fetchAndStoreEligibilityForAllSelectedRiders();
      const copiedLegs = await this.get('booking').cloneLegs(legsToCopy, booking);
      booking.set('legs', copiedLegs);
      this.set('booking.activeBooking', booking);
      this.get('editModal').open(newBookingComponent, [isBookingEditMode, this.get('booking.selectedRiders.firstObject'), booking]);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('cancelAction', {
        id: 'cancel',
        name: 'Cancel Booking',
        permIds: [_mappedPermIds.default.cancelFutureBookings, _mappedPermIds.default.cancelSameDayBookings],
        action: this.onLateTripCancelBooking
      });
      this.set('editAction', {
        id: 'edit',
        name: 'Edit Booking',
        permId: [_mappedPermIds.default.editFutureBookings, _mappedPermIds.default.editSameDayBookings],
        action: this.onBookingEdit
      });
      this.set('deleteAction', {
        id: 'delete',
        name: 'Delete Booking',
        permId: _mappedPermIds.default.deleteBooking,
        action: async model => {
          const deleteBookingComponent = this.get('deleteBookingComponent');
          const trips = model.get('trips');
          const isTripRelatedRouteIsLocked = await this.checkTripRelatedRouteIsLocked(trips);

          if (isTripRelatedRouteIsLocked) {
            this.showRouteLockWarning(DELETE_TEXT);
            return;
          }

          this.get('editModal').open(deleteBookingComponent, [model]);
        }
      });
      this.set('viewItineraryAction', {
        id: 'view',
        name: 'View Itinerary',
        permId: _mappedPermIds.default.createFutureBookings,
        action: model => {
          const viewItineraryComponent = this.get('viewItineraryComponent');
          this.get('editModal').open(viewItineraryComponent, [model]);
        }
      });
      this.set('copyBookingAction', {
        id: 'copy',
        name: 'Copy Booking',
        permId: _mappedPermIds.default.copyBooking,
        action: this.onCopyBooking
      });
      this.set('activityLogAction', {
        id: 'activityLog',
        name: 'View Activity Log',
        permId: _mappedPermIds.default.activityLog,
        action: booking => {
          const bookingId = booking.get('id');
          this.get('workspace').pushState('bookingActivityLog', {
            displayName: `Booking ${bookingId} Activity Log`,
            booking
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.get('booking.isPassengerRowChecked')) {
        this.filterTripsForselectedRiders();
        this.selectAndDeselectTableRows(false);
        this.set('booking.isPassengerRowChecked', false);
      }
    },

    selectAndDeselectTableRows(isSelected) {
      const bookingWidgetTableRows = this.get('table.rows');
      bookingWidgetTableRows.setEach('selected', isSelected);
      bookingWidgetTableRows.setEach('isChecked', isSelected);
    },

    filterTripsForselectedRiders() {
      const bookingRows = this.get('table.rows');
      const selectedBookings = bookingRows.filter(row => row.get('isChecked'));
      const selectedBookingIds = selectedBookings.map(booking => booking.get('id'));
      const filteredTrips = this.get('booking.currentTrips').filter(trip => selectedBookingIds.some(bookingId => bookingId === trip.get('booking.id')));
      this.set('booking.filteredCurrentTrips', filteredTrips);
    },

    filterTripsForUncheckedRow(bookingId) {
      const subscriptionRows = this.get('subscriptionTableRef.table.rows');
      const selectedSubscriptions = subscriptionRows.filter(subscriptionRow => subscriptionRow.get('isChecked'));
      const selectedSubscriptionIds = selectedSubscriptions.map(subscription => subscription.get('id'));
      const currentTrips = this.get('booking.filteredCurrentTrips');
      return currentTrips.filter(trip => {
        if (selectedSubscriptionIds.includes(trip.get('booking.subscription.id'))) {
          return true;
        }

        return bookingId !== trip.get('booking.id');
      });
    },

    checkIsItpBooking(row) {
      const trips = row.get('trips');
      const filteredTrips = trips.filter(trip => {
        return trip.get('segment.segmentType') === SEGMENT_TYPE_VALUE_FOR_ITP;
      });
      return filteredTrips.length;
    },

    async checkTripRelatedRouteIsLocked(trips) {
      const tripsWithRoute = trips.filter(trip => trip.get('route.id'));
      const routeDetails = tripsWithRoute.map(async trip => {
        const routeId = trip.get('route.id');
        const iquxServiceHost = _apiUrls.API.iquxService.host;
        const routeRequestUrl = `${iquxServiceHost}/routeWidget/${routeId}`;
        const routeData = await this.getRouteData(routeRequestUrl);
        return routeData;
      });
      const resolvedRoutes = (await Promise.all(routeDetails)) || [];
      const lockedRoutes = resolvedRoutes.filter(route => route.data.attributes.isLocked);
      return lockedRoutes.length;
    },

    async getRouteData(routeRequestUrl) {
      const session = this.get('session');
      const routeData = await this.get('ajax').request(routeRequestUrl, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
      return routeData;
    },

    showRouteLockWarning(triedAction) {
      const warningMessage = `UNABLE TO ${triedAction} BOOKING. THE ROUTE IS CURRENTLY LOCKED.`;
      this.get('notifications').warning(warningMessage);
    },

    async updateBookingTripGrid() {
      const bookingService = this.get('booking'); // eslint-disable-next-line no-unused-vars

      const [bookings, trips] = await this.get('apiContext').loadBookingsForSelectedExternalRiders();
      bookingService.set('currentTrips', trips || []);
    },

    actions: {
      async onUpdateIQUXRow(row) {
        const tripPromisses = await this.get('apiContext').updateTripsFromIQUXService(row.get('trips'));
        await Promise.all(tripPromisses);
      },

      async onRowClick(row) {
        const isChecked = row.get('isChecked');
        const currentTrips = this.get('booking.filteredCurrentTrips');
        const bookingId = row.get('id');

        if (isChecked) {
          await this.updateBookingTripGrid();
          await this.get('apiContext').updateTripsFromIQUXService(row.get('trips'));
        }

        if (isChecked) {
          const filteredTrips = this.get('booking.currentTrips').filter(trip => bookingId === trip.get('booking.id'));
          this.set('booking.filteredCurrentTrips', (0, _lodash.uniq)(currentTrips.concat(filteredTrips)));
          return;
        }

        const filteredTrips = this.filterTripsForUncheckedRow(bookingId);
        this.set('booking.filteredCurrentTrips', filteredTrips);
      },

      onRowRightClick(row) {
        const trips = row.get('trips');
        const dynamicSingleActions = [];
        const rowStatus = row.get('status');
        const cancelStatus = rowStatus === 'cancelled';
        const lateCancelStatus = rowStatus === 'lateCancelled';
        const isItpBooking = this.checkIsItpBooking(row);
        const anyTripsInServiceOrCompleted = trips.filter(trip => {
          const isCanceled = trip.get('isCanceled');
          const isCompleted = trip.get('isCompleted');
          const inService = trip.get('inService');
          const isNoShowed = trip.get('isNoShowed');
          const isArrived = trip.get('pickStopPoint.isArrived');
          return isCanceled || isCompleted || inService || isNoShowed || isArrived;
        });

        if (cancelStatus || lateCancelStatus) {
          if (anyTripsInServiceOrCompleted.length === 0) {
            dynamicSingleActions.push(this.get('deleteAction'));
          }

          dynamicSingleActions.push(this.get('copyBookingAction'), this.get('activityLogAction'), this.get('copyBookingAction'), this.get('activityLogAction'));
        } else if (row.get('isSameDayBooking') || rowStatus === 'scheduled') {
          if (anyTripsInServiceOrCompleted.length === 0) {
            dynamicSingleActions.push(this.get('cancelAction'), this.get('deleteAction'));
          }

          dynamicSingleActions.push(this.get('copyBookingAction'), this.get('activityLogAction'));
        } else if (rowStatus === 'scheduled' && !isItpBooking) {
          if (anyTripsInServiceOrCompleted.length === 0) {
            dynamicSingleActions.push(this.get('cancelAction'), this.get('deleteAction'));
          }

          dynamicSingleActions.push(this.get('copyBookingAction'), this.get('activityLogAction'), this.get('copyBookingAction'), this.get('activityLogAction'));
        } else if (isItpBooking && rowStatus === 'scheduled') {
          if (anyTripsInServiceOrCompleted.length === 0) {
            dynamicSingleActions.push(this.get('cancelAction'), this.get('deleteAction'));
          }

          dynamicSingleActions.push(this.get('viewItineraryAction'), this.get('copyBookingAction'), this.get('activityLogAction'));
        } else if (isItpBooking && rowStatus === 'unscheduled') {
          if (anyTripsInServiceOrCompleted.length === 0) {
            dynamicSingleActions.push(this.get('cancelAction'), this.get('deleteAction'));
          }

          dynamicSingleActions.push(this.get('editAction'), this.get('viewItineraryAction'), this.get('copyBookingAction'), this.get('activityLogAction'));
        } else if (rowStatus === 'refused') {
          if (anyTripsInServiceOrCompleted.length === 0) {
            dynamicSingleActions.push(this.get('deleteAction'));
          }

          dynamicSingleActions.push(this.get('deleteAction'), this.get('copyBookingAction'), this.get('activityLogAction'));
        } else {
          if (anyTripsInServiceOrCompleted.length === 0) {
            dynamicSingleActions.push(this.get('cancelAction'), this.get('deleteAction'));
          }

          dynamicSingleActions.push(this.get('editAction'), this.get('copyBookingAction'), this.get('activityLogAction'), this.get('copyBookingAction'), this.get('activityLogAction'));
        }

        this.set('dynamicSingleActions', dynamicSingleActions);
        const cutOffTime4LateCancelTrip = this.store.peekRecord('cs-config-item', 'config-Cancel_Trips/cutOffTime4LateCancelTrip');
        const lateTripCancelMessage = this.store.peekRecord('cs-config-item', 'config-Cancel_Trips/Late_Trip_Cancel_Message');
        this.set('lateTripCancelMessage', lateTripCancelMessage);
        this.set('cutOffTime4LateCancelTrip', cutOffTime4LateCancelTrip);

        this._super(...arguments);
      },

      async onCheckAll() {
        const isRowHeadChecked = true;
        if (isRowHeadChecked) await this.updateBookingTripGrid();
        this.selectAndDeselectTableRows(isRowHeadChecked);
        this.filterTripsForselectedRiders();
      },

      onUncheckAll() {
        const isRowHeadUnchecked = false;
        this.selectAndDeselectTableRows(isRowHeadUnchecked);
        this.set('booking.filteredCurrentTrips', []);
      }

    }
  });

  _exports.default = _default;
});