define("adept-iq/pods/components/rider-management-widget/rider-eligibility/eligibility-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/rider-management-widget/rider-eligibility/eligibility-table/config", "adept-iq/utils/sorts", "adept-iq/utils/filters"], function (_exports, _component, _config, _sorts, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    config: _config.default,
    disableIcons: false,
    editAPI: null,
    disableIconsPlus: Ember.computed('disableIcons', 'editAPI.isEditBtnDisabled', function () {
      return this.get('disableIcons') || !this.get('editAPI.isEditBtnDisabled');
    }),
    disableIconsEdit: Ember.computed('disableIcons', 'editAPI.isEditBtnDisabled', 'checkedRows.[]', function () {
      return this.get('disableIcons') || !this.get('editAPI.isEditBtnDisabled') || !this.get('checkedRows.firstObject');
    }),
    recordSetter: Ember.observer('riderId', async function () {
      const filteredData = await this.getRiderEligibilities('rider-eligibility');
      this.set('records', filteredData);
    }),

    init() {
      this._super(...arguments);

      this.set('isSearchAllowed', false);
      const riderChangeSet = this.get('riderChangeSet');

      if (riderChangeSet.get('status') === 'Archived') {
        this.set('disableIcons', true);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }

      this.get('selectedRow');
    },

    async getRiderEligibilities(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const riderId = this.get('riderChangeSet.id');
      let activeData = store.peekAll(modelName) || [];

      if (Ember.isPresent(this.get('config.fetchModelData'))) {
        activeData = (await store.findAll(modelName)) || [];
      }

      if (Ember.isPresent(this.get('config.queryModelData'))) {
        const queryParams = this.get('queryParams') || {};
        activeData = await store.query(modelName, queryParams);
      }

      let filteredData = activeData.filter(filterFn).sort(compareFn);
      filteredData = filteredData.filter(data => {
        return data.get('rider.id') === riderId && !data.get('isSoftDeleted');
      });
      return filteredData;
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const filteredData = await this.getRiderEligibilities(modelName, params);
      return filteredData;
    },

    actions: {
      onPlusIconClick() {
        this.set('isCreatingNewEligibility', true);
        this.set('canShowRiderEligibilityFormModal', true);
        this.get('createRiderReasonEligibilityRecord')();
      },

      onDeleteIconClick() {
        const eligibilityRecord = this.get('checkedRows.firstObject');
        this.get('onDeleteRiderReasonEligibility')(eligibilityRecord);
      },

      onEditIconClick() {
        const eligibilityRecord = this.get('checkedRows.firstObject');
        this.set('isCreatingNewEligibility', false);
        this.get('onEditRiderReasonEligibility')(eligibilityRecord);
      }

    }
  });

  _exports.default = _default;
});