define("adept-iq/pods/components/side-drawer/system-config/ada-boundaries/ada-boundary-information-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    title: 'Active Period',
    modelName: 'route-calendar',
    selectFirstRow: true,
    defaultSortAsc: true,
    checkBoxColumn: true,
    hideWidgetControls: true,
    showSelectedRowLength: false,
    columns: [{
      id: 'startTime',
      index: 0,
      type: 'time',
      label: 'Start time',
      valuePath: 'startDate',
      format: 'hh:mm A',
      defaultWidth: 70,
      searchable: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'endTime',
      index: 1,
      type: 'time',
      label: 'End time',
      valuePath: 'endDate',
      format: 'hh:mm A',
      defaultWidth: 70,
      searchable: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'Mon',
      index: 2,
      type: 'checkbox',
      label: 'Mon',
      valuePath: 'monday',
      defaultWidth: 50,
      searchable: true,
      editable: true,
      isChecked: true,
      cellComponent: 'side-drawer/system-config/ada-boundaries/ada-boundary-information-table/active-cell'
    }, {
      id: 'Tue',
      index: 3,
      type: 'checkbox',
      label: 'Tue',
      valuePath: 'tuesday',
      defaultWidth: 50,
      searchable: true,
      cellComponent: 'side-drawer/system-config/ada-boundaries/ada-boundary-information-table/active-cell'
    }, {
      id: 'Wed',
      index: 4,
      type: 'checkbox',
      label: 'Wed',
      valuePath: 'wednesday',
      defaultWidth: 50,
      searchable: true,
      cellComponent: 'side-drawer/system-config/ada-boundaries/ada-boundary-information-table/active-cell'
    }, {
      id: 'Thu',
      index: 5,
      type: 'checkbox',
      label: 'Thu',
      valuePath: 'thursday',
      defaultWidth: 50,
      searchable: true,
      cellComponent: 'side-drawer/system-config/ada-boundaries/ada-boundary-information-table/active-cell'
    }, {
      id: 'Fri',
      index: 6,
      type: 'checkbox',
      label: 'Fri',
      valuePath: 'friday',
      defaultWidth: 50,
      searchable: true,
      cellComponent: 'side-drawer/system-config/ada-boundaries/ada-boundary-information-table/active-cell'
    }, {
      id: 'sat',
      index: 7,
      type: 'checkbox',
      label: 'Sat',
      valuePath: 'saturday',
      defaultWidth: 50,
      searchable: true,
      cellComponent: 'side-drawer/system-config/ada-boundaries/ada-boundary-information-table/active-cell'
    }, {
      id: 'Sun',
      index: 8,
      type: 'checkbox',
      label: 'Sun',
      valuePath: 'sunday',
      defaultWidth: 50,
      searchable: true,
      cellComponent: 'side-drawer/system-config/ada-boundaries/ada-boundary-information-table/active-cell'
    }]
  };
  _exports.default = _default;
});