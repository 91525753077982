define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/vehicle-type/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/vehicle-type/validation", "adept-iq/utils/format-text-extension", "lodash", "adept-iq/config/placeholders", "moment", "adept-iq/utils/guid"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset, _emberChangesetValidations, _validation, _formatTextExtension, _lodash, _placeholders, _moment, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // third party libraries
  const VEHICLE_TYPE_PROPS = ['description', 'displayName', 'rearAxel', 'inServiceDate', 'endServiceDate', 'vehicleWheelBaseType', 'vehicleTireSize', 'vehicleModel', 'vehicleChassis', 'vehicleBodyWidth', 'vehicleFuelType', 'vehicleBodyType', 'vehicleFuelCap', 'vehicleBodyLength', 'vehicleTransmission', 'notes', 'vehicleCapacityConfigs'];
  const DROPDOWN_MODELS = [{
    optionName: 'vehicleModelOptions',
    modelName: 'vehicle-model-name'
  }, {
    optionName: 'vehicleBodyTypeOptions',
    modelName: 'vehicle-body-type'
  }, {
    optionName: 'vehicleBodyWidthOptions',
    modelName: 'vehicle-body-width'
  }, {
    optionName: 'vehicleRearAxelOptions',
    modelName: 'rear-axel-name'
  }, {
    optionName: 'vehicleWheelBaseOptions',
    modelName: 'vehicle-wheelbase-type'
  }, {
    optionName: 'vehicleTireSizeOptions',
    modelName: 'vehicle-tire-size'
  }, {
    optionName: 'vehicleChassisOptions',
    modelName: 'vehicle-chassis'
  }, {
    optionName: 'vehicleFuelTypeOptions',
    modelName: 'vehicle-fuel-type'
  }, {
    optionName: 'vehicleFuelCapOptions',
    modelName: 'vehicle-fuel-cap'
  }, {
    optionName: 'vehicleTransmissionOptions',
    modelName: 'vehicle-transmission'
  }];

  var _default = Ember.Component.extend({
    classNames: ['vehicle-type-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // placeholder
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    DISPLAY_NAME_PLACEHOLDER: _placeholders.DISPLAY_NAME_PLACEHOLDER,
    NOTES_PLACEHOLDER: _placeholders.NOTES_PLACEHOLDER,
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    VEHICLE_BODY_LENGTH_PLACEHOLDER: _placeholders.VEHICLE_BODY_LENGTH_PLACEHOLDER,
    VEHICLE_BODY_WIDTH_PLACEHOLDER: _placeholders.VEHICLE_BODY_WIDTH_PLACEHOLDER,
    VEHICLE_BODY_TYPE_PLACEHOLDER: _placeholders.VEHICLE_BODY_TYPE_PLACEHOLDER,
    VEHICLE_CHASSIS_PLACEHOLDER: _placeholders.VEHICLE_CHASSIS_PLACEHOLDER,
    VEHICLE_FUEL_TYPE_PLACEHOLDER: _placeholders.VEHICLE_FUEL_TYPE_PLACEHOLDER,
    VEHICLE_FUEL_CAP_PLACEHOLDER: _placeholders.VEHICLE_FUEL_CAP_PLACEHOLDER,
    VEHICLE_MODEL_PLACEHOLDER: _placeholders.VEHICLE_MODEL_PLACEHOLDER,
    VEHICLE_REAR_AXEL_PLACEHOLDER: _placeholders.VEHICLE_REAR_AXEL_PLACEHOLDER,
    VEHICLE_TIRE_SIZE_PLACEHOLDER: _placeholders.VEHICLE_TIRE_SIZE_PLACEHOLDER,
    VEHICLE_TRANSMISSION_PLACEHOLDER: _placeholders.VEHICLE_TRANSMISSION_PLACEHOLDER,
    VEHICLE_WHEELBASE_PLACEHOLDER: _placeholders.VEHICLE_WHEELBASE_PLACEHOLDER,
    tableRef: null,
    capacityGroupTableRef: null,
    description: null,
    modalAPI: null,
    editAPI: null,
    disableForm: true,
    showErrorMsg: false,
    radioFlag: false,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    selectedCapacityGroup: Ember.computed.readOnly('capacityGroupTableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    selectedCapacityGroupRow: Ember.computed.readOnly('capacityGroupTableRef.table.selectedRows.firstObject'),
    disableCapacityPanelBtns: Ember.computed.notEmpty('selectedCapacityRow'),
    vehicleTypeChangeSet: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      this.setPreviousCapacityConfigGroups();
      return this.setDefaultProperties(record);
    }),

    setPreviousCapacityConfigGroups() {
      const capacityConfigs = this.get('selectedRecord.vehicleCapacityConfigs') ? this.get('selectedRecord.vehicleCapacityConfigs').toArray() : [];
      const previousCapacityConfigs = this.getVehicleCapacityConfigGroups(capacityConfigs);
      this.set('previousCapacityConfigs', previousCapacityConfigs);
    },

    hasPendingChanges: Ember.computed('vehicleTypeChangeSet.changes.length', 'capacityGroups.@each.isSoftDeleted', 'previousCapacityConfigs', function () {
      const vehicleTypeChangeSet = this.get('vehicleTypeChangeSet');
      const changes = vehicleTypeChangeSet.get('changes') || [];
      return changes.length || this.isCapacityGroupChanged();
    }),

    hasChanges() {
      return this.get('hasPendingChanges');
    },

    capacityGroupChangeSets: Ember.computed('capacityGroups', function () {
      const capacityGroups = this.get('capacityGroups');
      return capacityGroups.map(capacityGroup => {
        return new _emberChangeset.default(capacityGroup);
      });
    }),
    hasCapacityGroupsChanges: Ember.computed('capacityGroupChangeSets.changes.length', function () {
      const capacityGroupChangeSets = this.get('capacityGroupChangeSets');
      const changes = capacityGroupChangeSets.get('changes') || [];
      return changes.length;
    }),
    capacityGroups: Ember.computed('vehicleTypeChangeSet.vehicleCapacityConfigs.@each.{group,count,vehicleCapacityType}', function () {
      const capacityConfigs = this.get('vehicleTypeChangeSet.vehicleCapacityConfigs') ? this.get('vehicleTypeChangeSet.vehicleCapacityConfigs').toArray() : [];
      return this.getVehicleCapacityConfigGroups(capacityConfigs);
    }),

    getVehicleCapacityConfigGroups(capacityConfigList) {
      const capacityConfigs = capacityConfigList.filter(capacityConfig => {
        return Ember.isPresent(capacityConfig.get('name')) || !capacityConfig.get('isSavedNewRecord');
      });
      const sortedCapacityConfigs = (0, _lodash.sortBy)(capacityConfigs, ['vehicleCapacityTypeName', 'count']);
      const groups = (0, _lodash.groupBy)(sortedCapacityConfigs, capacity => {
        return capacity.get('group');
      });
      const capacityGroups = Object.entries(groups).map(_ref => {
        let [group, capacities] = _ref;
        const details = {
          header: null,
          values: capacities.map(cap => (0, _formatTextExtension.formatTextExtension)('valueItem', `${cap.get('count')} ${cap.get('vehicleCapacityType.displayName')}`))
        };
        const configurationSortKey = details.values.length > 1 ? `${details.values.length} Configurations` : details.values[0].value;
        return Ember.Object.create({
          group,
          details,
          capacities,
          configurationSortKey
        });
      });
      return capacityGroups;
    },

    capacityGroupSet: Ember.computed('vehicleTypeChangeSet.vehicleCapacityConfigs.@each.group', function () {
      const capacityConfigs = this.get('vehicleTypeChangeSet.vehicleCapacityConfigs') ? this.get('vehicleTypeChangeSet.vehicleCapacityConfigs').toArray() : [];
      const groupSet = new Set();
      const groups = (0, _lodash.groupBy)(capacityConfigs, capacity => {
        return capacity.get('group');
      });
      Object.entries(groups).forEach(_ref2 => {
        let [group] = _ref2;
        groupSet.add(group);
      });
      return groupSet;
    }),
    nextVehicleCapacityId: Ember.computed('vehicleCapacityConfigs.[]', function () {
      const vehicleCapacityConfigs = this.get('vehicleCapacityConfigs') || [];
      let max = 0;
      vehicleCapacityConfigs.forEach(capacity => {
        let id = parseInt(capacity.get('id'), 10);
        if (isNaN(id)) id = 0;
        max = Math.max(id, max);
      });
      return max;
    }),

    init() {
      this._super(...arguments);

      this.set('modalAPI', Ember.Object.create());
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleTypeRecord = this.get('onSaveVehicleTypeRecord');
      const onNewVehicleTypeRecord = this.get('onNewVehicleTypeRecord').bind(this);
      const onEditVehicleTypeRecord = this.get('onEditVehicleTypeRecord').bind(this);
      const onDeleteVehicleTypeRecord = this.get('onDeleteVehicleTypeRecord');
      const onUndoVehicleTypeRecord = this.get('onUndoVehicleTypeRecord').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleTypeRecord,
          newAction: onNewVehicleTypeRecord,
          deleteAction: onDeleteVehicleTypeRecord,
          editAction: onEditVehicleTypeRecord,
          undoAction: onUndoVehicleTypeRecord,
          onValidateAction,
          hasChanges
        });
        this.get('refreshTask').perform();
        this.setupDropDownValues();
      });
      this.set('onUndoVehicleTypeRecord', onUndoVehicleTypeRecord);
    },

    willDestroyElement() {
      const records = this.get('tableRef.records') || [];
      records.map(record => {
        if (record.get('tableRef')) {
          record.set('tableRef');
        }
      });

      this._super(...arguments);
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const tableRef = this.get('tableRef');
      let typeObj = this.get('pendingRecord');

      if (record) {
        typeObj = record;
        this.resetForm();
      }

      if (Ember.isEmpty(record)) {
        const data = {
          category: 'config-System_Configuration-vehicle_types',
          type: 'object'
        };
        typeObj = store.createRecord('cs-config-item', data);
        this.set('pendingRecord', typeObj);
      }

      if (typeObj.get('isNew')) {
        typeObj.setProperties({
          description: null,
          displayName: null,
          inServiceDate: new Date(),
          endServiceDate: new Date(),
          vehicleCapacityConfigs: [],
          softDeletedCapacityRecords: [],
          tableRef: tableRef
        });
      }

      this.set('showErrorMsg', false);
      const tableRefRecords = this.get('tableRef.records') || [];
      tableRefRecords.map(tableRefRecord => {
        if (!tableRefRecord.get('tableRef')) {
          tableRefRecord.set('tableRef', tableRef);
        }
      });
      const capacityGroupTableRef = this.get('capacityGroupTableRef');
      if (capacityGroupTableRef) Ember.run.scheduleOnce('afterRender', capacityGroupTableRef, 'refreshData');
      return new _emberChangeset.default(typeObj, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('vehicleTypeChangeSet');

      if (selectedRecord) {
        if (!selectedRecord.get('isNew') && !selectedRecord.get('isDeleted')) {
          yield selectedRecord.data.reload();
        }
      }

      const tableRef = this.get('tableRef');
      const capacityGroupTableRef = this.get('capacityGroupTableRef');
      this.resetForm();
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      if (capacityGroupTableRef) Ember.run.scheduleOnce('afterRender', capacityGroupTableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        editAPI.buttonSettings();
      });
    },

    async setupDropDownValues() {
      const store = this.get('store');
      const dropDownModels = DROPDOWN_MODELS;
      dropDownModels.forEach(dropdown => {
        const records = store.peekAll(dropdown.modelName);
        this.set(dropdown.optionName, records);
      });
      const vehicleCapacityConfigs = await this.get('store').findAll('vehicle-capacity-config');
      this.set('vehicleCapacityConfigs', vehicleCapacityConfigs);
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const vehicleTypeRecord = this.get('vehicleTypeChangeSet');
      const capacityGroups = this.get('capacityGroups').filter(capacityGroup => !capacityGroup.get('isSoftDeleted'));
      if (!this.validCapacityGroups()) return false;
      yield vehicleTypeRecord.validate();
      const inServiceDate = vehicleTypeRecord.get('inServiceDate');
      const endServiceDate = vehicleTypeRecord.get('endServiceDate');

      if (inServiceDate && endServiceDate) {
        if ((0, _moment.default)(endServiceDate).isSameOrBefore((0, _moment.default)(inServiceDate))) {
          vehicleTypeRecord.addError('endServiceDate', {
            value: endServiceDate,
            validation: 'End-Service Date must be after In-Service Date'
          });
        }
      } //check if display name exists


      let displayName = vehicleTypeRecord.get('displayName');
      const id = vehicleTypeRecord.get('id');

      if (displayName) {
        displayName = displayName.toLowerCase();
        const tableRefRecords = this.get('tableRef.records') || [];
        const nameExists = tableRefRecords.some(record => record.displayName.toLowerCase() === displayName && record.id !== id);

        if (nameExists) {
          vehicleTypeRecord.addError('displayName', {
            value: vehicleTypeRecord.get('displayName'),
            validation: 'The vehicle type\'s Display Name duplicates an existing vehicle type\'s Display Name.'
          });
        }
      }

      const errors = vehicleTypeRecord.errors;

      if (!capacityGroups.length) {
        errors.pushObject({
          'validation': 'At least one Capacity Configuration Group is required.'
        });
      }

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),

    validateVehicleType() {
      const tooltip = this.get('tooltip');
      const errors = [];
      const vehicles = this.get('vehicleTypeChangeSet.vehicles');
      const futureRoutes = this.getFutureRoute(vehicles);

      if (futureRoutes.length) {
        errors.pushObject({
          'validation': 'Unable to delete. This vehicle type is used by vehicle(s) assigned to future' + ' routes. Unassign the vehicles using this vehicle type from future routes and try again.'
        });
      }

      if (errors.length) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    },

    validCapacityGroups() {
      const tooltip = this.get('tooltip');
      const errors = [];
      const vehicles = this.get('vehicleTypeChangeSet.vehicles');
      const futureRoutes = vehicles ? this.getFutureRoute(vehicles) : [];
      if (!this.isCapacityGroupChanged() && !futureRoutes.length) return true;

      if (futureRoutes.length) {
        errors.pushObject({
          'validation': 'Unable to save. This vehicle type is used by vehicle(s) assigned to future ' + 'routes. Unassign the vehicles using this vehicle type from future routes and try again.'
        });
      }

      if (errors.length) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    },

    getFutureRoute(vehicles) {
      const routeVehicleMap = this.get('editAPI.routeVehicleMap');
      const routes = [];
      vehicles.forEach(vehicle => {
        const routesList = routeVehicleMap[vehicle.get('id')];
        if (routesList) routes.push(routesList);
      });
      const futureRoutes = (0, _lodash.flatten)(routes);
      return futureRoutes;
    },

    isCapacityGroupChanged() {
      const previousCapacityConfigs = this.get('previousCapacityConfigs');
      const capacityGroups = this.get('capacityGroups');
      const vehicleTypeChangeSet = this.get('vehicleTypeChangeSet');
      const vehicles = this.get('vehicleTypeChangeSet.vehicles');
      if (vehicleTypeChangeSet.get('isNew') || !vehicles) return false;
      if (previousCapacityConfigs.length !== capacityGroups.length) return true;
      return JSON.stringify(previousCapacityConfigs) !== JSON.stringify(capacityGroups);
    },

    onSaveVehicleTypeRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');
      const softDeletedCapacityRecords = this.get('pendingRecord.softDeletedCapacityRecords');
      const activityIdGuid = (0, _guid.guid)();

      if (!isValid) {
        this.set('showErrorMsg', true);
        return;
      }

      const vehicleTypeChangeSet = this.get('vehicleTypeChangeSet');
      let vehicleTypeRecord = this.get('vehicleTypeChangeSet');
      const {
        description,
        displayName,
        rearAxel,
        inServiceDate,
        endServiceDate,
        vehicleWheelBaseType,
        vehicleTireSize,
        vehicleModel,
        vehicleChassis,
        vehicleBodyWidth,
        vehicleFuelType,
        vehicleBodyType,
        vehicleFuelCap,
        vehicleBodyLength,
        vehicleTransmission,
        notes,
        vehicleCapacityConfigs
      } = vehicleTypeRecord.getProperties(VEHICLE_TYPE_PROPS);
      const notifications = this.get('notifications');
      const capacityGroups = this.get('capacityGroups');
      const vehicleWheelBaseTypeId = vehicleWheelBaseType ? vehicleWheelBaseType.get('id') : null;
      const rearAxelId = rearAxel ? rearAxel.get('id') : null;
      const vehicleTireSizeId = vehicleTireSize ? vehicleTireSize.get('id') : null;
      const vehicleModelId = vehicleModel ? vehicleModel.get('id') : null;
      const vehicleChassisId = vehicleChassis ? vehicleChassis.get('id') : null;
      const vehicleBodyWidthId = vehicleBodyWidth ? vehicleBodyWidth.get('id') : null;
      const vehicleFuelTypeId = vehicleFuelType ? vehicleFuelType.get('id') : null;
      const vehicleBodyTypeId = vehicleBodyType ? vehicleBodyType.get('id') : null;
      const vehicleFuelCapId = vehicleFuelCap ? vehicleFuelCap.get('id') : null;
      const vehicleTransmissionId = vehicleTransmission ? vehicleTransmission.get('id') : null;
      const value = {
        description,
        displayName,
        rearAxelId,
        inServiceDate,
        endServiceDate,
        vehicleBodyLength,
        notes,
        vehicleWheelBaseTypeId,
        vehicleTireSizeId,
        vehicleModelId,
        vehicleChassisId,
        vehicleBodyWidthId,
        vehicleFuelCapId,
        vehicleFuelTypeId,
        vehicleBodyTypeId,
        vehicleTransmissionId
      };

      if (vehicleTypeRecord.get('isNew')) {
        const nameGuidConverted = `${description}-${activityIdGuid}`;
        vehicleTypeRecord.set('name', (0, _lodash.camelCase)(nameGuidConverted));
      } else {
        const id = this.get('vehicleTypeChangeSet.id');
        const store = this.get('store');
        const record = `config-System_Configuration-vehicle_types/${id}`;
        vehicleTypeRecord = store.peekRecord('cs-config-item', record);
      }

      vehicleTypeRecord.set('displayName', displayName);
      vehicleTypeRecord.set('value', value);

      if (softDeletedCapacityRecords.length > 0) {
        this.deleteConfigurationTask.perform(softDeletedCapacityRecords);
        this.set('pendingRecord.softDeletedCapacityRecords', []);
      }

      if (capacityGroups.length > 0) {
        this.onSaveVehicleConfigConfigurations.perform(capacityGroups, vehicleTypeRecord);
      }

      if (vehicleCapacityConfigs) {
        try {
          yield vehicleTypeRecord.save(); // changeset holds changes, execute the changes due to lookups using unorthodox saving practice
          // execute to change underlying object and rollback to remove internal changes list

          vehicleTypeChangeSet.execute();
          vehicleTypeChangeSet.rollback();
          notifications.success('Record successfully saved.');
          this.set('disableForm', true);
          Ember.run.later(() => {
            this.get('refreshTask').perform().then(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
              this.setPreviousCapacityConfigGroups();
              tooltip.reset();
            });
          }, 500);
        } catch (response) {
          if (response.errors[0].status === '409') {
            tooltip.pushConfirmation({
              title: 'Manage Vehicle',
              tip: 'Description cannot duplicate an existing Description.',
              primaryActionText: 'OK',
              primaryAction: () => {
                return tooltip.reset();
              }
            });
          } else {
            notifications.warning('Record failed to save.');
          }

          if (vehicleTypeRecord.rollbackAttributes) {
            vehicleTypeRecord.rollbackAttributes();
          }

          console.warn(response); // eslint-disable-line
        }
      }
    }).drop(),
    onSaveVehicleConfigConfigurations: (0, _emberConcurrency.task)(function* () {
      let capacityGroups = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let vehicleTypeRecord = arguments.length > 1 ? arguments[1] : undefined;
      const nextVehicleCapacityId = this.get('nextVehicleCapacityId');
      const filteredVehicleCapacityConfigs = [];
      const store = this.get('store');
      capacityGroups.forEach(capacityGroup => {
        if (!capacityGroup.get('isSoftDeleted')) {
          filteredVehicleCapacityConfigs.push(...capacityGroup.get('capacities'));
        }
      });
      yield filteredVehicleCapacityConfigs.reduce((acu, capacity, index) => {
        const {
          count,
          group,
          vehicleCapacityType
        } = capacity.getProperties('count', 'group', 'vehicleCapacityType');
        const value = {
          count: parseInt(count, 10),
          group,
          vehicleCapacityType: vehicleCapacityType.get('id'),
          vehicleType: vehicleTypeRecord.get('name')
        };
        let capacityToSave;

        if (capacity.get('isNew')) {
          const id = nextVehicleCapacityId + index + 1; // eslint-disable-next-line no-param-reassign

          capacityToSave = store.createRecord('cs-config-item', {
            category: 'config-System_Configuration-vehicle_capacity_configs',
            type: 'object',
            name: id
          });
        } else {
          const id = capacity.get('id');
          const record = `config-System_Configuration-vehicle_capacity_configs/${id}`; // eslint-disable-next-line no-param-reassign

          capacityToSave = store.peekRecord('cs-config-item', record);
        }

        capacityToSave.set('value', value);
        return acu.then(() => {
          capacityToSave.save();
          capacity.set('isSavedNewRecord', true);
        });
      }, Promise.resolve());
    }).drop(),

    onEditVehicleTypeRecord() {
      const editAPI = this.get('editAPI');
      const selectedRow = this.get('selectedRow');
      if (!selectedRow) return;
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onUndoVehicleTypeRecord() {
      const vehicleTypeChangeSet = this.get('vehicleTypeChangeSet');
      const editAPI = this.get('editAPI');
      const softDeletedCapacityRecords = this.get('pendingRecord.softDeletedCapacityRecords');
      if (softDeletedCapacityRecords.length) this.set('pendingRecord.softDeletedCapacityRecords', []);

      if (vehicleTypeChangeSet) {
        vehicleTypeChangeSet.rollback();
        this.undoCapacities(vehicleTypeChangeSet);
      }

      this.set('disableForm', true);
      editAPI.buttonSettings(false, false, false, true, true);
    },

    undoCapacities(vehicleTypeChangeSet) {
      let vehicleCapacityConfigs = vehicleTypeChangeSet.get('vehicleCapacityConfigs') || [];
      const capacityGroupTableRef = this.get('capacityGroupTableRef');
      const selectedVehicleRecord = this.get('selectedRecord');

      if (!this.get('radioFlag')) {
        vehicleCapacityConfigs = vehicleCapacityConfigs.filter(capacity => !capacity.get('isNew'));
      }

      vehicleCapacityConfigs.forEach(capacity => capacity.rollbackAttributes());

      if (Ember.isPresent(selectedVehicleRecord)) {
        selectedVehicleRecord.set('vehicleCapacityConfigs', vehicleCapacityConfigs);
        this.setPreviousCapacityConfigGroups();
      }

      if (capacityGroupTableRef) Ember.run.scheduleOnce('afterRender', capacityGroupTableRef, 'refreshData');
    },

    onNewVehicleTypeRecord() {
      const editAPI = this.get('editAPI');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    deleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRow = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      const notifications = this.get('notifications');
      const store = this.get('store');
      const capacityGroups = this.get('capacityGroups') || [];
      const csConfigVehicleTypeId = `config-System_Configuration-vehicle_types/${selectedRow.get('id')}`;

      if (selectedRow) {
        try {
          const capacities = capacityGroups.reduce((acu, capacityGroup) => {
            return [...acu, ...capacityGroup.capacities];
          }, []);
          const vehicleCapacityConfigs = capacities.map(cap => {
            const id = cap.get('id');
            const vehicleCapacityConfigId = `config-System_Configuration-vehicle_capacity_configs/${id}`;
            return store.peekRecord('cs-config-item', vehicleCapacityConfigId);
          });
          const csConfigVehicleType = store.peekRecord('cs-config-item', csConfigVehicleTypeId); // delete vehicle_capacity_configs in cs-config-item

          yield vehicleCapacityConfigs.reduce((acu, vehicleCapacityConfig) => {
            return acu.then(() => {
              return vehicleCapacityConfig.destroyRecord().then(cs => {
                store._removeFromIdMap(cs._internalModel);
              });
            });
          }, Promise.resolve()); // delete config-vehicle_types in cs-config-item

          yield csConfigVehicleType.destroyRecord().then(cs => {
            store._removeFromIdMap(cs._internalModel);
          }); // remove capacities from store

          yield capacities.reduce((acu, capacity) => {
            return acu.then(() => {
              capacity.destroyRecord().then(cs => {
                store._removeFromIdMap(cs._internalModel);
              });
            });
          }, Promise.resolve()); // delete vehicle type record from store

          yield selectedRow.destroyRecord().then(cs => {
            store._removeFromIdMap(cs._internalModel);
          });
          notifications.success('Record successfully deleted.');
          this.get('refreshTask').perform().then(() => {
            Ember.run.later(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
            }, 500);
          });
        } catch (e) {
          notifications.warning('Record failed to delete.');
          console.warn(e); // eslint-disable-line
        }
      }
    }).drop(),
    onDeleteVehicleTypeRecord: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const deleteRecord = this.get('deleteRecord');
      const isVehicleTypeValid = this.get('validateVehicleType').call(this);

      if (!isVehicleTypeValid) {
        return;
      }

      yield tooltip.pushConfirmation({
        title: 'Manage Vehicle',
        hasOverlay: true,
        tip: 'Are you sure you want to delete this record?',
        primaryActionText: 'Confirm',
        primaryAction: () => {
          const store = this.get('store');
          const vehicleTypeId = this.get('vehicleTypeChangeSet.id') || null;

          if (vehicleTypeId) {
            // in the future we should actually use an rql query to figure out which vehicles are using this maintTypeName
            // and have the backend process it for us, currently and name field and description can be different though,
            // so we might need to think about it this a bit more
            const vehicle = store.peekAll('vehicle').filter(record => record.get('active') && record.get('vehicleType.id') === vehicleTypeId);

            if (vehicle.length) {
              return tooltip.pushConfirmation({
                title: 'Manage Vehicle',
                hasOverlay: true,
                tip: `There are ${vehicle.length} active vehicle(s) with this Vehicle Type description. You must either change the Vehicle Type on these vehicle(s) or delete these vehicle(s) prior to deleting the Vehicle Type.`,
                primaryActionText: 'OK',

                primaryAction() {
                  tooltip.reset();
                }

              });
            }

            return deleteRecord.perform().then(() => {
              tooltip.reset();
            });
          }
        },
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        }

      });
    }).drop(),
    deleteConfigurationTask: (0, _emberConcurrency.task)(function* (configurationGroups) {
      const notifications = this.get('notifications');
      const store = this.get('store');
      yield configurationGroups.forEach(async configurationGroup => {
        const capacityGroups = configurationGroup.capacities || [];

        if (configurationGroup) {
          try {
            const vehicleCapacityGroup = capacityGroups.map(cap => {
              if (Ember.isPresent(cap)) {
                const id = cap.get('id');
                const vehicleCapacityGroupId = `config-System_Configuration-vehicle_capacity_configs/${id}`;
                return store.peekRecord('cs-config-item', vehicleCapacityGroupId);
              }
            }); // delete vehicle_capacity_configs in cs-config-item

            await vehicleCapacityGroup.reduce(async (acu, vehicleCapacityConfig) => {
              if (Ember.isPresent(vehicleCapacityConfig)) {
                return acu.then(() => {
                  return vehicleCapacityConfig.destroyRecord().then(cs => {
                    store._removeFromIdMap(cs._internalModel);
                  });
                });
              }
            }, Promise.resolve()); // remove capacities from store

            await capacityGroups.reduce((acu, capacity) => {
              if (Ember.isPresent(capacity)) {
                return acu.then(() => {
                  capacity.destroyRecord().then(cs => {
                    store._removeFromIdMap(cs._internalModel);
                  });
                });
              }
            }, Promise.resolve());
          } catch (e) {
            let softDeletedCapacityRecords = this.get('pendingRecord.softDeletedCapacityRecords');

            if (softDeletedCapacityRecords.length > 0) {
              softDeletedCapacityRecords = [];
            }

            notifications.warning('Capacity Configuration Group failed to delete.');
            console.warn(e); // eslint-disable-line
          }
        }
      });
    }).drop(),

    onCloseCapacityModal() {
      const capacityGroupTableRef = this.get('capacityGroupTableRef');
      if (capacityGroupTableRef) Ember.run.scheduleOnce('afterRender', capacityGroupTableRef, 'refreshData');
    },

    actions: {
      onSaveVehicleTypeRecord() {
        return this.onSaveVehicleTypeRecord.perform();
      },

      onDateValueChange(record, valuePath, value) {
        const isoDate = (0, _moment.default)(value).toISOString();
        record.set(valuePath, isoDate);
      },

      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onDeleteConfiguration() {
        const selectedCapacityGroup = this.get('selectedCapacityGroup');
        const softDeletedCapacityRecords = this.get('pendingRecord.softDeletedCapacityRecords');
        const selectedRow = this.get('selectedRow');
        const capacityConfigs = this.get('vehicleTypeChangeSet.vehicleCapacityConfigs') ? this.get('vehicleTypeChangeSet.vehicleCapacityConfigs').toArray() : [];
        let removedCapacityConfigs;
        const self = this;
        const tooltip = this.get('tooltip');
        if (!selectedCapacityGroup) return;
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          hasOverlay: true,
          tip: 'Delete capacity group?',
          primaryActionText: 'Confirm',

          primaryAction() {
            try {
              const capacityGroupTableRef = self.get('capacityGroupTableRef');
              selectedCapacityGroup.set('isSoftDeleted', true);
              softDeletedCapacityRecords.push(selectedCapacityGroup);

              if (Ember.isEmpty(selectedRow)) {
                removedCapacityConfigs = capacityConfigs.filter(cap => cap.get('group') !== selectedCapacityGroup.get('group'));
                self.set('vehicleTypeChangeSet.vehicleCapacityConfigs', removedCapacityConfigs);
              }

              if (capacityGroupTableRef) Ember.run.scheduleOnce('afterRender', capacityGroupTableRef, 'refreshData');
            } catch (e) {
              console.warn(e); //eslint-disable-line
            }

            return tooltip.reset();
          },

          secondaryActionText: 'Cancel',

          secondaryAction() {
            tooltip.reset();
          }

        });
      },

      onAddConfiguration() {
        const modalAPI = this.get('modalAPI');
        const vehicleTypeChangeSet = this.get('vehicleTypeChangeSet');
        const selectedCapacityGroupRow = this.get('selectedCapacityGroupRow');
        const onCloseCapacityModal = this.get('onCloseCapacityModal').bind(this);
        const capacityGroupSet = this.get('capacityGroupSet');
        if (selectedCapacityGroupRow) selectedCapacityGroupRow.set('selected', false);
        Ember.run.next(() => {
          modalAPI.setProperties({
            title: 'Add Capacity Configuration Group',
            vehicleTypeChangeSet,
            onCloseButtonClick: onCloseCapacityModal,
            selectedCapacityGroup: {
              capacities: []
            },
            group: null,
            capacityGroupSet,
            hasOverlay: true,
            isNewGroup: true
          });
        });
      },

      onEditConfiguration() {
        const modalAPI = this.get('modalAPI');
        const vehicleTypeChangeSet = this.get('vehicleTypeChangeSet');
        const selectedCapacityGroup = this.get('selectedCapacityGroup');
        const onCloseCapacityModal = this.get('onCloseCapacityModal').bind(this);
        const capacityGroupSet = this.get('capacityGroupSet');
        if (!selectedCapacityGroup) return;
        capacityGroupSet.delete(selectedCapacityGroup.group);
        modalAPI.setProperties({
          title: 'Edit Capacity Configuration Group',
          vehicleTypeChangeSet,
          selectedCapacityGroup,
          onCloseButtonClick: onCloseCapacityModal,
          capacityGroupSet,
          group: selectedCapacityGroup.group,
          hasOverlay: true,
          isNewGroup: false
        });
      }

    }
  });

  _exports.default = _default;
});