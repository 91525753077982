define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/fetch-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchWidgetData = _exports.fetchDataPart = void 0;

  const fetchDataPart = url => {
    return fetch(url).then(response => response.json()).then(response => {
      return response.data;
    }).then(data => {
      const result = data.map(value => {
        return value.attributes;
      });
      return result;
    });
  };

  _exports.fetchDataPart = fetchDataPart;

  const spinMultipleRequests = (url, pageLimit, totalPages) => {
    const listOfRequests = [];

    for (let offset = 0; offset < totalPages; offset++) {
      const paginatedUrl = `${url}?page[limit]=${pageLimit}&page[offset]=${offset}&sort=id`;
      const request = fetchDataPart(paginatedUrl);
      listOfRequests.push(request);
    }

    return Promise.all(listOfRequests);
  };

  const mergeAllData = dataList => {
    let alldata = [];
    dataList.forEach(data => {
      alldata = alldata.concat(data);
    });
    return alldata;
  };

  const fetchWidgetData = (url, pageLimit, totalPages) => {
    return spinMultipleRequests(url, pageLimit, totalPages).then(mergeAllData).catch(error => {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
      return [];
    });
  };

  _exports.fetchWidgetData = fetchWidgetData;
});