define("adept-iq/pods/components/form-components/date/component", ["exports", "moment", "adept-iq/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-datetimeflatpickr'],
    editModal: Ember.inject.service(),
    record: null,
    value: null,
    disabled: false,
    defaultDate: null,
    readonlyCell: false,
    format: null,
    maxDate: null,
    minDate: null,
    minDatePath: Ember.computed.readOnly('extra.minDatePath'),

    init() {
      this._super(...arguments);

      const value = this.get('value') || '';
      const disabled = this.get('disabled');
      const format = this.get('format') || 'm/d/Y';
      const record = this.get('record');
      const minDatePath = this.get('minDatePath');
      const minDate = minDatePath ? (0, _moment.default)(record.get(this.get('minDatePath'))).startOf('day') : (0, _moment.default)().subtract(125, 'years');
      const placeholder = this.get('readonlyCell') ? '' : _environment.default.dateTimeFormat.dateMoment;
      this.set('minDate', minDate.toDate());
      this.set('value', value);
      this.set('format', format);
      this.set('disabled', disabled);
      this.set('datePlaceholder', placeholder);
    },

    didUpdateAttrs() {
      this.set('datePlaceHolder', (0, _moment.default)(this.value).format(_environment.default.dateTimeFormat.dateMoment));
    },

    actions: {
      onOpenDatePickr(value) {
        this.get('onOpen')(value[0]);
      },

      onChangeDate(value) {
        // If it is readOnly we should not allow the value to be overwritten.
        if (!this.get('readonlyCell')) {
          this.get('onChange')(value[0]);
        }
      },

      onClose(currentValue, stringValue, datePicker) {
        this.set('minDate', null);
        this.set('maxDate', null);
        const currentValueMoment = (0, _moment.default)(currentValue[0]);
        const newValueMoment = (0, _moment.default)(stringValue);

        if (!newValueMoment._isValid && stringValue) {
          this.set('editModal.errors', ['Date entered is invalid.']);
        }

        if (datePicker.config.allowInput && datePicker._input.value && newValueMoment._isValid && !currentValueMoment.isSame(newValueMoment)) {
          datePicker.setDate(newValueMoment.toDate(), true);
        }
      }

    }
  });

  _exports.default = _default;
});