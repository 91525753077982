define("adept-iq/models/reconcile-address", ["exports", "ember-data", "adept-iq/utils/format-text-extension"], function (_exports, _emberData, _formatTextExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    addressId: attr('number'),
    formattedAddress: attr('string'),
    streetName: attr('string'),
    streetNr: attr('string'),
    unitNr: attr('string'),
    region: attr('string'),
    postCode: attr('number'),
    city: attr('string'),
    state: attr('string'),
    country: attr('string'),
    lat: attr('number'),
    lng: attr('number'),
    identifier: attr('string'),
    zone: attr('string'),
    notes: attr('string'),
    stopId: attr('string'),
    stop: belongsTo('reconcile-stop'),
    address: Ember.computed('formattedAddress', function () {
      return (0, _formatTextExtension.convertToPascalCase)(Ember.get(this, 'formattedAddress'));
    }),
    formattedCity: Ember.computed('city', function () {
      const c = Ember.get(this, 'city');

      if (Ember.isEmpty(c)) {
        const add = Ember.get(this, 'formattedAddress');

        if (!Ember.isEmpty(add)) {
          const addStr = (0, _formatTextExtension.convertToPascalCase)(add);
          const addArray = addStr.split(',');
          return addArray[addArray.length - 2];
        }
      }

      return (0, _formatTextExtension.convertToPascalCase)(c);
    }),
    formattedState: Ember.computed('state', function () {
      return (0, _formatTextExtension.convertToPascalCase)(Ember.get(this, 'state'));
    }),
    formattedCountry: Ember.computed('country', function () {
      return (0, _formatTextExtension.convertToPascalCase)(Ember.get(this, 'country'));
    }),
    fullAddress: Ember.computed('formattedAddress', 'city', 'state', 'country', function () {
      const address = Ember.get(this, 'formattedAddress') + ' ' + Ember.get(this, 'city') + ' ' + Ember.get(this, 'state') + ' ' + Ember.get(this, 'country');
      return (0, _formatTextExtension.convertToPascalCase)(address.trim());
    })
  });

  _exports.default = _default;
});