define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel/addressPicker", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/session", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/config/environment", "tomtom", "adept-iq/pods/components/iq-widgets/ember-react-playback/hooks/useOutsideCanceler"], function (_exports, _react, _session, _panel, _environment, _tomtom, _useOutsideCanceler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const AddressPreview = props => {
    const {
      index,
      location,
      setSeletedLocation
    } = props;
    return _react.default.createElement("div", {
      onClick: () => setSeletedLocation(location),
      className: `search-preview ${index === 0 ? 'start' : ''}`
    }, _react.default.createElement("div", {
      className: "first"
    }, _react.default.createElement("p", {
      className: "name"
    }, location.address), _react.default.createElement("p", {
      className: "sub-header"
    }, `${location.position.lat}, ${location.position.lon}`)));
  };

  const AddressPicker = () => {
    const [suggestions, setSuggestions] = _react.default.useState([]);

    const [text, setText] = _react.default.useState('');

    const {
      location,
      setLocation
    } = (0, _panel.usePanel)();

    const {
      bemapCenter
    } = _react.default.useContext(_session.SessionContext);

    const setSeletedLocation = _location => {
      setLocation(_location);
      setSuggestions([]);
    };

    const fuzzySearch = async keyword => {
      const limit = 10;
      const idxSet = 'POI,PAD,Str,Xstr,Geo,Addr'; // default to point addresses

      const center = bemapCenter || _environment.default.tomtom.search.center;
      const radius = _environment.default.tomtom.search.radius;

      const _addresses = await _tomtom.default.fuzzySearch().key(_environment.default.tomtom.search.searchKey).query(keyword).countrySet(_environment.default.tomtom.search.countrySet).typeahead(false).limit(limit).center(center).radius(radius).idxSet(idxSet).go();

      const _suggestions = _addresses.map(_address => {
        return {
          address: _address.address.freeformAddress,
          position: _address.position
        };
      });

      setSuggestions(_suggestions);
    };

    const onChangeHandler = keyword => {
      setText(keyword);

      if (keyword !== '') {
        fuzzySearch(keyword);
      } else {
        // Clear location
        setLocation({});
      }
    };

    _react.default.useEffect(() => {
      if (!location || !location.address) {
        setText('');
        return;
      }

      setText(location.address);
    }, [location]);

    return _react.default.createElement(_useOutsideCanceler.default, {
      cancel: () => setSuggestions([])
    }, _react.default.createElement("div", {
      className: "auto"
    }, _react.default.createElement("input", {
      className: "search-bar",
      placeholder: "Enter Address",
      value: text,
      onChange: e => onChangeHandler(e.target.value)
    }), suggestions.length > 0 && _react.default.createElement("div", {
      className: "search-results"
    }, suggestions.map((suggestion, index) => {
      return _react.default.createElement(AddressPreview, {
        key: `${suggestion.address}-${index}`,
        index: index,
        location: suggestion,
        setSeletedLocation: setSeletedLocation
      });
    }))));
  };

  var _default = AddressPicker;
  _exports.default = _default;
});