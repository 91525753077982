define("adept-iq/pods/components/iq-widgets/scatter-chart-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZLKEuf1E",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"analytics-widget-header\"],[9],[0,\"\\n  \"],[7,\"h5\"],[11,\"class\",\"analytics-widget-title\"],[9],[1,[21,\"chartTitle\"],false],[0,\"       \\n\"],[4,\"if\",[[23,[\"refreshChart\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"fa fa-1x fa-circle-o-notch fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n    \"]],\"parameters\":[]},null],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"analytics-widget-button\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-filter\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"onFilterButtonClick\"]],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"onHeaderDoubleClick\"],[[\"on\"],[\"doubleClick\"]]],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"scatter-chart-widget-body\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"scatter-chart-container\"],[9],[0,\"\\n      \"],[1,[27,\"ember-chart-fix\",null,[[\"type\",\"data\",\"options\",\"width\",\"height\"],[\"scatter\",[23,[\"chartData\"]],[23,[\"chartOptions\"]],350,170]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/scatter-chart-widget/template.hbs"
    }
  });

  _exports.default = _default;
});