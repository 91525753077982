define("adept-iq/pods/components/iq-widgets/navigation-widget/config/markers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const stopIconSize = [23.0, 23.0];
  var _default = {
    destinationStop: {
      icon: {
        src: '/stops-icons/stop_next@2x.png',
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    },
    projectedStop: {
      icon: {
        src: '/location-26.png',
        anchorX: 0.50,
        anchorY: 0.50,
        height: stopIconSize[0],
        width: stopIconSize[1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1
      }
    }
  };
  _exports.default = _default;
});