define("adept-iq/pods/components/get-special-permission/component", ["exports", "adept-iq/config/api-urls"], function (_exports, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    booking: Ember.inject.service(),
    userId: null,
    password: null,
    isRunning: false,
    // pass in data to component
    haveSpecialPermission: false,
    // value returned if it has permission
    isShowSpecialPermission: false,
    // close or open the modal dialog box
    requiredPermission: null,
    // permission needed
    headerTitle: null,
    bodyMessage: null,

    getTokenPermissions(userId, password) {
      const session = this.get('session');
      const notifications = this.get('notifications');
      const ajax = this.get('ajax');
      const {
        host
      } = _apiUrls.API.ssoService;
      const url = host + '/auth-token';
      const json = {
        plugin: 'internal;adept4;basic',
        internal: {
          email: userId,
          password: password
        },
        basic: {
          username: userId,
          password: password
        },
        adept4: {
          username: userId,
          password: password
        }
      };
      return ajax.post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      }).then(response => {
        if (Ember.isPresent(response) && Ember.isPresent(response.token)) {
          const tokenParts = response.token.split('.');
          const tokenInfo = JSON.parse(atob(tokenParts[1]));
          const permissions = tokenInfo.permissions;
          return permissions;
        }

        return null;
      }).catch(function (error) {
        notifications.warning(`Error validating user. ${error.message}. Ensure credentials were entered properly.`);
        return null;
      });
    },

    getRequestPermission(userId, password) {
      const notifications = this.get('notifications');
      const requiredPermission = this.get('requiredPermission');
      return this.getTokenPermissions(userId, password).then(async permissions => {
        if (Ember.isPresent(permissions)) {
          const hasPermission = permissions.includes(requiredPermission);
          if (!hasPermission) notifications.warning('User does not have permission to override.');
          return hasPermission;
        }

        return false;
      });
    },

    actions: {
      onCloseRequestPermission() {
        this.set('haveSpecialPermission', false); // turn off working icon

        this.set('isRunning', false);
        this.toggleProperty('isShowSpecialPermission');
      },

      async onConfirmRequestPermission() {
        const userId = this.get('userId');
        const password = this.get('password');
        const overrideTravelNeedsDetails = this.get('booking.overrideTravelNeedsDetails');
        const overrideTravelNeed = this.get('overrideTravelNeed'); // turn on working icon

        this.set('isRunning', true);
        const hasPermission = await this.getRequestPermission(userId, password);

        if (hasPermission) {
          this.set('haveSpecialPermission', true);
          this.set('booking.travelNeedsOverride', true);
          overrideTravelNeedsDetails.pushObject(overrideTravelNeed);
          this.toggleProperty('isShowSpecialPermission'); // close modal
        } else {
          this.set('haveSpecialPermission', false);
        } // turn off working icon


        this.set('isRunning', false);
      }

    }
  });

  _exports.default = _default;
});