define("adept-iq/models/reconcile-price", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    priceId: attr('number'),
    type: attr('string'),
    currencyCode: attr('string'),
    fare: attr('number'),
    tip: attr('number'),
    tripId: attr('number'),
    // Relationship bindings
    components: hasMany('reconcile-component')
  });

  _exports.default = _default;
});