define("adept-iq/classes/rules/eligibility-rules/itp-include-feeder-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type"], function (_exports, _baseRule, _ruleResultType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ItpIncludeFeederRule = void 0;

  class ItpIncludeFeederRule extends _baseRule.default {
    doEvaluate(ruleContext) {
      const {
        itpRequestResults
      } = ruleContext;
      const containsFeeder = this.hasFeederIncluded(itpRequestResults);

      if (containsFeeder) {
        return _ruleResultType.RESULT_TYPE_YES;
      }

      return _ruleResultType.RESULT_TYPE_NO;
    }

    hasFeederIncluded() {
      return true;
    }

  }

  _exports.ItpIncludeFeederRule = ItpIncludeFeederRule;
  ItpIncludeFeederRule.RULE_NAME = 'ItpIncludeFeederRule';
});