define("adept-iq/pods/components/iq-widgets/booking-trips-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/booking-trips-widget/config", "adept-iq/pods/components/iq-widgets/booking-trips-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/utils/unwrapProxy", "adept-iq/utils/sorts", "adept-iq/config/mapped-permIds", "moment"], function (_exports, _component, _config, _avlmConfig, _environment, _unwrapProxy, _sorts, _mappedPermIds, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['view-alltrips-widget'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    booking: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    session: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    editComponent: 'iq-widgets/passengers-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    canShowHeader: true,
    config: _config.default,
    defaultIncludes: INCLUDES,
    changeRecords: Ember.observer('previousTrips', function () {
      const previousTrips = this.get('previousTrips');
      const sort = this.buildSortParam();
      const compareFn = (0, _sorts.buildCompareFunction)([sort]);
      this.set('records', previousTrips.sort(compareFn));
    }),

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);

      this.get('tableColumns').popObject();
      this.get('tableColumns').lastObject.resizable = false;
      this.getbookingTripsRecords = this.getbookingTripsRecords.bind(this);
      this.set('getRecords', this.getbookingTripsRecords);
    },

    getbookingTripsRecords() {
      const filteredCurrentTrips = this.get('booking.filteredCurrentTrips') || [];
      const uniqTrips = this.get('booking').uniqTrips(filteredCurrentTrips);
      return uniqTrips;
    },

    didReceiveAttrs() {
      this._super(...arguments);

      setTimeout(() => {
        this.selectAndDeselectTableRows(false);
      }, 100);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('cancelAction', {
        id: 'cancel',
        permIds: [_mappedPermIds.default.cancelFuture, _mappedPermIds.default.cancelSameDay],
        name: 'Cancel Trip',
        action: trip => {
          const cutOffTime4LateCancelTrip = this.store.peekRecord('cs-config-item', 'config-Cancel_Trips/cutOffTime4LateCancelTrip');
          const value = Number(cutOffTime4LateCancelTrip.get('value'));
          const promiseTime = trip.get('promiseTime');
          const tripDate = promiseTime ? (0, _moment.default)(promiseTime) : (0, _moment.default)(trip.get('segment.leg.requestTime'));
          const currentDate = (0, _moment.default)().startOf('day');
          const endTimeForCancellation = tripDate.subtract(value, 'minutes');
          const currentTime = (0, _moment.default)();
          const tripId = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
          const booking = (0, _unwrapProxy.unwrapProxy)(trip.get('booking'));
          const segment = (0, _unwrapProxy.unwrapProxy)(trip.get('segment'));
          booking.set('timestamp', new Date());
          const cancelTripPanelDetails = {
            displayName: 'Cancel Trip',
            trip,
            booking,
            segment,
            tripId
          };

          if (tripDate.date() <= currentDate.date() && (0, _moment.default)(endTimeForCancellation).isBefore(currentTime)) {
            cancelTripPanelDetails.lateCancel = true;
            return this.showLateTripCancelBookingWarning(cancelTripPanelDetails);
          }

          this.get('workspace').pushState('cancelTripForm', cancelTripPanelDetails);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editAction', {
        id: 'edit',
        permIds: [_mappedPermIds.default.editFuture, _mappedPermIds.default.editPast, _mappedPermIds.default.editSameDay],
        name: 'Edit Trip',
        action: async trip => {
          if (!trip.get('pick.segmentStop.place.address.id') || !trip.get('drop.segmentStop.place.address.id')) {
            const placeIdsInClause = [trip.get('pick.segmentStop.place.id'), trip.get('drop.segmentStop.place.id')].map(p => `'${p}'`).join(',');
            const placesQuery = `in(id,(${placeIdsInClause}))&include=address,location`;
            await this.get('store').query('place', {
              filter: placesQuery
            });
          }

          this.get('workspace').pushState('editTripWidget', {
            trip,
            showMapModal: true
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('activityLogAction', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLog,
        name: 'View Activity Log',
        action: trip => {
          const id = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
          this.get('workspace').pushState('tripActivityLog', {
            displayName: `Trip ${id} Activity Log`,
            trip
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
    },

    showLateTripCancelBookingWarning(cancelTripPanelDetails) {
      const lateTripCancelMessage = this.store.peekRecord('cs-config-item', 'config-Cancel_Trips/Late_Trip_Cancel_Message');
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Late Trip Cancel',
        tip: lateTripCancelMessage.get('value'),
        primaryActionText: 'Ok',
        primaryAction: () => {
          tooltip.reset();
          this.get('workspace').pushState('cancelTripForm', cancelTripPanelDetails);
        },
        hasOverlay: true,
        closeAction: () => {
          tooltip.reset();
        }
      });
    },

    selectAndDeselectTableRows(isRow) {
      const tripsWidgetTableRows = this.get('table.rows');
      tripsWidgetTableRows.setEach('selected', isRow);
      tripsWidgetTableRows.setEach('isChecked', isRow);
    },

    actions: {
      onRowClick() {},

      async onUpdateIQUXRow(row) {
        const tripPromisses = await this.get('apiContext').updateTripsFromIQUXService([row]);
        await Promise.all(tripPromisses);
      },

      onCheckAll() {
        const isRowHeadChecked = true;
        this.selectAndDeselectTableRows(isRowHeadChecked);
      },

      onUncheckAll() {
        const isRowHeadUnchecked = false;
        this.selectAndDeselectTableRows(isRowHeadUnchecked);
      },

      onRowRightClick(row) {
        const isCanceled = row.get('isCanceled');
        const isCompleted = row.get('isCompleted');
        const inService = row.get('inService');
        const isNoShowed = row.get('isNoShowed');
        const isArrived = row.get('pickStopPoint.isArrived');
        this.set('dynamicSingleActions', []);
        if (!isCanceled) this.get('dynamicSingleActions').push(this.get('editAction'));
        this.get('dynamicSingleActions').push(this.get('activityLogAction'));
        if (!isCanceled && !isCompleted && !inService && !isNoShowed && !isArrived) this.get('dynamicSingleActions').push(this.get('cancelAction'));

        this._super(...arguments);
      }

    }
  });

  _exports.default = _default;
});