define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-trip/component", ["exports", "adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-trip/config", "moment", "lodash"], function (_exports, _config, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    config: _config.default,
    dwhService: Ember.inject.service(),
    tripTable: null,
    selectedTripId: null,
    selectedRow: null,
    searchButtonEnabled: Ember.computed.empty('selectedTripId'),
    clearButtonEnabled: Ember.computed.empty('selectedTripId'),
    routeOptions: Ember.computed.alias('dwhService.routeOptions'),
    routeToSelect: Ember.computed('routeOptions', 'selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      if (Ember.isEmpty(selectedRow)) return;

      const routeId = _lodash.default.get(selectedRow, 'attributes.routeId', null);

      if (!routeId) return;
      const routeOption = this.get('routeOptions').find(route => `${route.id}` === `${routeId}`);
      return routeOption;
    }),

    init() {
      this._super(...arguments);

      this.set('fetchTripsByDateAndTripId', this.get('fetchTripsByDateAndTripId').bind(this));
      this.set('onRowClick', this.actions.onRowClick.bind(this));
      this.set('config.title', '');
    },

    updateSearchProperty(value, item) {
      Ember.set(this, item, value);
      this.notifyPropertyChange(item);
    },

    async fetchTripsByDateAndTripId() {
      const selectedTripId = this.get('selectedTripId');
      const searchMode = this.get('selectedSearchMode');

      if (!selectedTripId || !searchMode) {
        return [];
      }

      const date = this.get('dwhService.reconcileDate');
      const dateString = (0, _moment.default)(date).format('YYYY-MM-DD');
      const trips = await this.get('dwhService').findTripsByDateAndTripId(dateString, selectedTripId);
      return trips;
    },

    actions: {
      onRowClick(selectedRow) {
        if (Ember.isEmpty(selectedRow)) return;
        const routeId = selectedRow.get('attributes.routeId');
        this.set('dwhService.routeFound', routeId);
      },

      onSearchRoutesByTrip() {
        this.get('tripTable').refreshData();
      },

      onInputTextChange(valuePath, searchText) {
        Ember.run.debounce(this, 'updateSearchProperty', searchText, valuePath, 200);
      },

      clearSearchTrip() {
        this.set('selectedTripId', null);
        this.get('tripTable').clearData();
        this.set('config.title', '');
        this.set('config.noRecordsFound', false);
        this.set('routeId', null);
      }

    }
  });

  _exports.default = _default;
});