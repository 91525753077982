define("adept-iq/pods/components/side-drawer/perform-stop/drop/ui/component", ["exports", "adept-iq/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INTERVAL_DELAY = 60000;

  var _default = Ember.Component.extend({
    classNames: ['perform-stop-drawer'],
    timeFormat: _environment.default.dateTimeFormat.dateTimeFlatPickr,
    shouldArrive: true,
    // pass in data to component
    currentState: null,
    stopPoint: null,
    errorText: null,
    actualDepartTime: null,
    actualArriveTime: null,
    arrive: false,
    depart: false,
    isArrived: null,
    isNextDisabled: null,
    isBulkPeform: false,
    maxDate: null,
    dateTimeFormat: _environment.default.dateTimeFormat,
    // pass in perform-all panel
    previousStop: null,
    // progress interval every minute
    _interval: null,
    trip: Ember.computed.readOnly('stopPoint.trip'),
    dispatchRoute: Ember.computed.readOnly('stopPoint.dispatchRoute'),
    route: Ember.computed.readOnly('dispatchRoute.route'),
    rider: Ember.computed.readOnly('trip.rider'),
    address: Ember.computed.readOnly('stopPoint.tripStop.segmentStop.place.address'),
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),
    routeVehicleDriver: Ember.computed.readOnly('dispatchRoute.latestRouteVehicleDriver'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    lastPerformedStop: Ember.computed.readOnly('dispatchRoute.lastPerformedStop'),
    minTime: Ember.computed('lastPerformedStop.{arrive,depart,actualArriveTime,actualDepartTime,routeBreak.actualArriveTime,routeBreak.actualDepartTime}', 'previousStop.actualDepartTime', function () {
      const prevDepartTime = this.get('previousStop.actualDepartTime');
      if (prevDepartTime) return prevDepartTime;
      const prevStop = this.get('lastPerformedStop');
      let minTime = this.get('dispatchRoute.computedStartTime') || this.get('dispatchRoute.plannedStartTime');
      if (Ember.isEmpty(prevStop)) return minTime;
      const actualArriveTime = prevStop.get('actualArriveTime'); // takes into account break stops

      const actualDepartTime = prevStop.get('actualDepartTime'); // takes into account break stops

      const stopArriveTime = this.get('actualArriveTime');
      if (actualArriveTime) minTime = actualArriveTime;
      if (actualDepartTime) minTime = actualDepartTime;
      if (minTime > stopArriveTime) minTime = stopArriveTime;
      return minTime ? (0, _moment.default)(minTime).startOf('minute').toDate() : null; // we need something to separate stops
    }),
    unperformedIQStops: Ember.computed('dispatchRoute', 'stopPoint', function () {
      const dispatchRoute = this.get('dispatchRoute');
      const stopPoint = this.get('stopPoint');
      const unperformedIQStops = dispatchRoute.findUnperformedStopsInSequence(stopPoint);
      return unperformedIQStops;
    }),
    outOfOrderPerform: Ember.computed('isBulkPeform', 'unperformedIQStops.length', function () {
      return !this.get('isBulkPeform') && this.get('unperformedIQStops.length') > 1;
    }),

    init() {
      this._super(...arguments);

      const max = new Date();
      max.setMinutes(max.getMinutes() + 1);
      this.set('maxDate', max); // create a maxDate ticker that progresses by a minute

      this._interval = setInterval(() => {
        Ember.run.schedule('afterRender', () => {
          if (this.get('isDestroyed')) return;
          this.set('maxDate', max);
        });
      }, INTERVAL_DELAY);
      const setStopUIReference = this.get('setStopUIReference');

      if (setStopUIReference) {
        setStopUIReference(this);
      }
    },

    destroy() {
      clearInterval(this._interval);
      this._interval = null;
    },

    didInsertElement() {
      this.loadCurrentState();
    },

    setDropTimeInArrivalTime() {
      const dropTime = this.get('actualDepartTime');
      const arriveTime = this.get('actualArriveTime');

      if ((0, _moment.default)(dropTime).isBefore(arriveTime, 'minutes')) {
        this.set('actualDepartTime', arriveTime);
      }
    },

    backUpCurrentState() {
      const {
        stopPoint,
        driverBreak,
        travelNeedOptions,
        travelNeedItems,
        arrive,
        depart,
        isArrived,
        isNextDisabled,
        actualArriveTime,
        actualDepartTime
      } = this;
      const odometerInMiles = stopPoint.get('odometerInMiles');
      const currentState = {
        driverBreak,
        travelNeedOptions,
        travelNeedItems,
        arrive,
        depart,
        isArrived,
        odometerInMiles,
        isNextDisabled,
        actualArriveTime,
        actualDepartTime
      };
      this.set('currentState', currentState);
    },

    loadCurrentState() {
      const currentState = this.get('currentState');
      const stopPoint = this.get('stopPoint');

      if (currentState) {
        const {
          odometerInMiles,
          driverBreak,
          travelNeedOptions,
          travelNeedItems,
          arrive,
          depart,
          isArrived,
          isNextDisabled,
          actualArriveTime,
          actualDepartTime
        } = currentState;
        stopPoint.set('odometerInMiles', odometerInMiles);
        this.setProperties({
          driverBreak,
          travelNeedOptions,
          travelNeedItems,
          arrive,
          depart,
          isArrived,
          isNextDisabled,
          actualArriveTime,
          actualDepartTime
        });
        this.set('currentState', null);
      }
    },

    actions: {
      onClickDepart(depart) {
        if (depart) {
          this.set('arrive', depart);
          this.set('depart', depart);
          this.set('isNextDisabled', false);
          return;
        }

        this.set('depart', depart);
        this.set('isNextDisabled', true);
      },

      onActualDropTimeChange(dateValues) {
        const momentTime = (0, _moment.default)(dateValues[0]);
        this.set('actualDepartTime', momentTime.startOf('minute').toISOString());
        this.setDropTimeInArrivalTime();
      },

      onActualArriveTimeChange(dateValues) {
        const momentTime = (0, _moment.default)(dateValues[0]);
        this.set('actualArriveTime', momentTime.startOf('minute').toISOString());
        this.setDropTimeInArrivalTime();
      }

    }
  });

  _exports.default = _default;
});