define("adept-iq/adapters/subscription", ["exports", "adept-iq/adapters/-bs-bookingManagement", "adept-iq/config/api-urls"], function (_exports, _bsBookingManagement, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingManagement.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),

    getPath(url, defaults) {
      // eslint-disable-next-line no-useless-escape
      var reUrlPath = /(?:\w+:)?\/\/[^\/]+([^?#]+)/;
      var urlParts = url.match(reUrlPath) || [url, defaults];
      return urlParts.pop();
    },

    findBelongsTo(store, snapshot, url, relationship) {
      // this is because api endpoint returns the wrong relationshiop url
      if (relationship._type === 'place') {
        const path = this.getPath(url);
        const newUrl = _apiUrls.API.addressService.host + path;
        return this._super(store, snapshot, newUrl, relationship);
      }

      return this._super(store, snapshot, url, relationship);
    },

    queryRecord(store, type, id) {
      const session = this.get('session');
      return this.get('ajaxService').request(_apiUrls.API.bookingService.host + '/subscription/' + id, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    }

  });

  _exports.default = _default;
});