define("adept-iq/pods/components/side-drawer/reconcile-schedule-unscheduled-trips/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const cellComponent = 'table/cells/base-cell';
  var _default = {
    title: 'Schedule Trip to a Route',
    routeIdModel: 'reconcile-route-id',
    routeModel: 'reconcile-route',
    tripModel: 'reconcile-trip',
    stopModel: 'reconcile-stop',
    passengerModel: 'reconcile-passenger',
    addressModel: 'reconcile-address',
    stopColumns: [{
      label: 'Trip ID',
      valuePath: 'tripId',
      cellComponent,
      resizable: true,
      minResizeWidth: 50 // width: '80px'

    }, {
      label: 'Last Name',
      valuePath: 'passenger.lastName',
      cellComponent,
      resizable: true,
      minResizeWidth: 50 // width: '80px'

    }, {
      label: 'Address',
      valuePath: 'address.formattedAddress',
      cellComponent,
      minResizeWidth: 50,
      resizable: true // width: '80px'

    }, {
      label: 'City',
      valuePath: 'address.formattedCity',
      cellComponent,
      minResizeWidth: 50,
      resizable: true // width: '60px'

    }, {
      label: 'Stop',
      valuePath: 'type',
      cellComponent,
      minResizeWidth: 50,
      resizable: true // width: '60px'

    }, {
      label: 'ETA',
      valuePath: 'updatedEtaAsTime',
      cellComponent,
      minResizeWidth: 50,
      resizable: true // width: '60px'

    }, {
      label: 'Promise Time',
      valuePath: 'formattedPromiseTimeAsTime',
      cellComponent,
      minResizeWidth: 50,
      resizable: true // width: '80px'

    }, {
      label: 'Appt Time',
      valuePath: 'formattedRequestTimeAsTime',
      cellComponent,
      resizable: true,
      minResizeWidth: 50 // width: '80px'

    }, {
      label: 'Stop Break Ord',
      valuePath: 'ordinal',
      cellComponent,
      minResizeWidth: 60,
      resizable: true // width: '80px'

    }]
  };
  _exports.default = _default;
});