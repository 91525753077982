define("adept-iq/classes/impact-stop", ["exports", "adept-iq/utils/unwrapProxy", "@mapbox/polyline"], function (_exports, _unwrapProxy, _polyline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // will need this to decode impact payload polylines
  var _default = Ember.Object.extend({
    // passed by the impact payload
    id: null,
    eta: null,
    promiseTime: null,
    otpValue: null,
    polyline: null,
    mrt: null,
    stopPoint: null,
    // original values obtained by wrapping stopPoint class
    status: Ember.computed.readOnly('stopPoint.status'),
    stopType: Ember.computed.readOnly('stopPoint.stopType'),
    lat: Ember.computed('stopPoint.{estimatedBreakLatLng,place.location.lat,etaNavStopPoint.lat}', function () {
      const stopPointLat = this.get('stopPoint.place.location.lat');
      const breakLatLng = this.get('stopPoint.estimatedBreakLatLng');
      const etaLat = this.get('etaNavStopPoint.lat'); // Remove For AVLM 2.1

      if (!stopPointLat && breakLatLng) {
        return breakLatLng[0];
      }

      if (etaLat) return etaLat;
      return stopPointLat;
    }),
    lng: Ember.computed('stopPoint.{estimatedBreakLatLng,place.location.lng,etaNavStopPoint.lng}', function () {
      const stopPointLng = this.get('stopPoint.place.location.lng');
      const breakLatLng = this.get('stopPoint.estimatedBreakLatLng');
      const etaLng = this.get('etaNavStopPoint.lng');

      if (!stopPointLng && breakLatLng) {
        return breakLatLng[1];
      }

      if (etaLng) return etaLng;
      return stopPointLng;
    }),
    currentPolyline: Ember.computed('stopPoint.polyline', 'polyline', function () {
      const polyline = this.get('polyline');
      const stopPointPolyline = (0, _unwrapProxy.unwrapProxy)(this.get('stopPoint.polyline'));

      if (polyline) {
        return {
          coords: _polyline.default.decode(polyline)
        };
      }

      return stopPointPolyline;
    })
  });

  _exports.default = _default;
});