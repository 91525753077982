define("adept-iq/mixins/fetchAssignableVehicleDrivers", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/utils/vehicleCapacity", "adept-iq/config/mapped-permIds", "adept-iq/mixins/version-check", "adept-iq/config/api-urls", "adept-iq/config/environment", "lodash", "moment"], function (_exports, _emberConcurrency, _unwrapProxy, _vehicleCapacity, _mappedPermIds, _versionCheck, _apiUrls, _environment, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DATE_FORMAT = 'YYYY-MM-DD';

  var _default = Ember.Mixin.create(_versionCheck.default, {
    permissionLayer: Ember.inject.service(),
    workspace: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    disptachSchedule: Ember.computed.readOnly('record.dispatchRoute.dispatchSchedule'),
    isScheduleDashboard: Ember.computed.readOnly('workspace.isScheduleDashboard'),
    resetVehicles: Ember.observer('plannedStartTime', 'plannedEndTime', 'provider', 'usePlaceHolderTask', function () {
      if (this.get('usePlaceHolderTask')) {
        return this.get('fetchPlaceHolderVehiclesTask').perform();
      }

      return this.get('fetchVehiclesTask').perform();
    }),
    resetDrivers: Ember.observer('plannedStartTime', 'plannedEndTime', 'provider', function () {
      if (!this.get('isScheduleDashboard')) {
        return this.get('fetchDriversTask').perform();
      }

      if (this.get('isScheduleDashboard')) {
        return this.get('fetchScheduleDriversTask').perform();
      }
    }),
    fetchNonPlaceholderVehiclesToVariableTask: (0, _emberConcurrency.task)(function* () {
      let vehicles = this.get('store').peekAll('vehicle');
      const filterVehiclesByHoliday = this.get('filterVehiclesByHoliday');

      if (!vehicles || vehicles.length <= 1) {
        vehicles = yield this.get('store').query('vehicle', {
          useVehicleService: true
        });
      }

      const provider = (0, _unwrapProxy.unwrapProxy)(this.get('provider'));
      const plannedStart = (0, _moment.default)(this.get('plannedStartTime'));
      const plannedEnd = (0, _moment.default)(this.get('plannedEndTime'));
      const assignVehicleOverCapacityPerm = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.assignVehicleOverCapacity, null);
      const dpScheduleId = this.get('dispatchRoute.dispatchSchedule.id') || this.get('record.dispatchSchedule.id') || this.get('selectedSchedule.dispatchSchedule.id');
      if (!vehicles || !vehicles.length) return []; // make sure vehicles are active and stay in the same provider as route

      if (provider) {
        vehicles = vehicles.filter(vehicle => {
          return vehicle.get('active') && vehicle.get('displayStatus') !== 'Breakdown' && provider.get('id') === vehicle.get('provider.id');
        });
      } // make sure any existing route->vehicle assignments in dispatch domain
      // do not intersect with destination route's planned window;


      vehicles = vehicles.filter(vehicle => {
        // data coming from vehicleWidget
        if (vehicle.get('displayStatus') === 'Breakdown') return false;
        if (vehicle.get('isCompleted')) return true;
        let rvdHistory = vehicle.get('rvdHistory') ? Object.values(vehicle.get('rvdHistory')) : [];
        let isAvailable = true;
        rvdHistory = rvdHistory.filter(rvd => rvd.dpScheduleId === dpScheduleId && !rvd.isCompleted);
        rvdHistory.forEach(rvd => {
          const startMoment = (0, _moment.default)(rvd.startTime);
          const endMoment = (0, _moment.default)(rvd.endTime);

          if (startMoment.isBetween(plannedStart, plannedEnd, null, '[)') || endMoment.isBetween(plannedStart, plannedEnd, null, '(]') || plannedStart.isBetween(startMoment, endMoment, null, '[)') || plannedEnd.isBetween(startMoment, endMoment, null, '(]')) {
            isAvailable = false;
          }
        });
        return isAvailable;
      }); // availability is disabled we should still filter for valid vehicles to execute a route

      const tnVehicleCapacityItinerary = this.get('dispatchRoute.tnVehicleCapacityItinerary'); // show vehicles that does not have enough capacity if user have permission

      if (!assignVehicleOverCapacityPerm && Ember.isPresent(tnVehicleCapacityItinerary)) {
        vehicles = vehicles.filter(function (vehicle) {
          const capacityCounts = vehicle.get('capacityCounts');
          return tnVehicleCapacityItinerary.every(travelNeedCounts => !(0, _vehicleCapacity.notEnoughCapacity)(travelNeedCounts, capacityCounts));
        });
      } // filter unneeded placeholder vehicles


      vehicles = vehicles.filter(vehicle => !vehicle.get('placeholder'));

      if (filterVehiclesByHoliday) {
        vehicles = yield this.filterDriverAndVehicleByHolidayAvailability(vehicles, 'vehicle-availability', 'vehicleId');
      }

      this.set('nonPlaceholderVehicles', vehicles);
    }),
    fetchVehiclesTask: (0, _emberConcurrency.task)(function* () {
      yield this.fetchNonPlaceholderVehiclesToVariableTask.perform();
      this.set('vehicles', this.get('nonPlaceholderVehicles').sortBy('name'));
    }),
    fetchPlaceHolderVehiclesToVariableTask: (0, _emberConcurrency.task)(function* () {
      const provider = this.get('provider');
      let vehicles = this.get('store').peekAll('vehicle');

      if (!vehicles || vehicles.length <= 1) {
        vehicles = yield this.get('store').query('vehicle', {
          useVehicleService: true
        });
      }

      if (provider) {
        vehicles = vehicles.filter(vehicle => vehicle.get('provider.id') === provider.get('id'));
      }

      const placeholderVehicles = vehicles.filterBy('placeholder', true);
      this.set('placeholderVehicles', placeholderVehicles);
    }),
    fetchPlaceHolderVehiclesTask: (0, _emberConcurrency.task)(function* () {
      yield this.fetchPlaceHolderVehiclesToVariableTask.perform();
      this.set('vehicles', this.get('placeholderVehicles').sortBy('name'));
    }),
    fetchDispachAssignableVehiclesTask: (0, _emberConcurrency.task)(function* () {
      yield this.fetchNonPlaceholderVehiclesToVariableTask.perform();
      yield this.fetchPlaceHolderVehiclesToVariableTask.perform();
      this.set('vehicles', (0, _lodash.concat)(this.get('nonPlaceholderVehicles'), this.get('placeholderVehicles')).sortBy('name'));
    }),
    fetchAssignableVehiclesTask: (0, _emberConcurrency.task)(function* () {
      const ajax = this.get('ajax');
      const session = this.get('session');
      const schedules = this.get('store').peekAll('schedule');
      const plannedStart = (0, _moment.default)(this.get('plannedStartTime'));
      const plannedEnd = (0, _moment.default)(this.get('plannedEndTime'));
      const plannedStartFormat = plannedStart.format();
      const plannedEndFormat = plannedEnd.format();
      const provider = this.get('provider');
      const selectedVehicle = this.get('selectedVehicle');
      let vehicles = this.get('store').peekAll('vehicle');

      if (!vehicles || vehicles.length <= 1) {
        vehicles = yield this.get('store').query('vehicle', {
          useVehicleService: true
        });
      } // filter out vehicles that are for a different provider


      if (provider) {
        vehicles = vehicles.filter(vehicle => vehicle.get('provider.id') === provider.get('id'));
      }

      const placeHolderVehicles = vehicles.filterBy('placeholder', true);
      let nonPlaceHolderVehicles = vehicles.filterBy('placeholder', false);
      const startDate = (0, _moment.default)(plannedStart).format(DATE_FORMAT);
      const holidayDates = this.getHolidayDates();
      const isHoliday = holidayDates.some(holiday => (0, _moment.default)(holiday).isSame(startDate));
      const dayOfWeekOrHoliday = isHoliday ? 'holiday' : plannedStart.format('dddd').toLowerCase();
      const availabilities = yield this.get('store').query('vehicle-availability', {});
      const availabilityMap = (0, _lodash.groupBy)(availabilities.toArray(), 'vehicleId'); // filter out vehicles that are not available for this route

      nonPlaceHolderVehicles = nonPlaceHolderVehicles.filter(vehicle => {
        const availabilityList = availabilityMap[vehicle.get('id')] || [];
        return availabilityList.some(vehicleAvailability => {
          const availableForDay = vehicleAvailability.get(dayOfWeekOrHoliday);

          if (!availableForDay) {
            return false;
          }

          const availabilityStartMoment = (0, _moment.default)(vehicleAvailability.get('startTime'));
          const availabilityEndMoment = (0, _moment.default)(vehicleAvailability.get('endTime'));
          const availableAtTime = !availabilityStartMoment.isBetween(plannedStart, plannedEnd, 'minute', '(]') && !availabilityEndMoment.isBetween(plannedStart, plannedEnd, 'minute', '[)') && plannedStart.isBetween(availabilityStartMoment, availabilityEndMoment, 'minute', '[)') && plannedEnd.isBetween(availabilityStartMoment, availabilityEndMoment, 'minute', '(]');

          if (!availableAtTime) {
            return false;
          }

          const shiftStartTime = (0, _moment.default)(vehicleAvailability.formattedShiftStart, _environment.default.dateTimeFormat.timeMoment);

          if (!vehicleAvailability.routeLength) {
            return false;
          }

          const shiftLength = vehicleAvailability.routeLength.split(':');

          if (!shiftLength || shiftLength.length !== 2) {
            return false;
          }

          const shiftHour = shiftLength[0];
          const shiftMinute = shiftLength[1];
          const vehicleShiftStartTime = plannedStart.clone().set({
            h: shiftStartTime.hour(),
            m: shiftStartTime.minute()
          });
          const vehicleShiftEndTime = vehicleShiftStartTime.clone().add(shiftHour, 'hour').add(shiftMinute, 'minute');
          const isShiftMatches = parseInt(shiftHour, 10) >= 24 || plannedStart.isBetween(vehicleShiftStartTime, vehicleShiftEndTime, 'minute', '[]') && plannedEnd.isBetween(vehicleShiftStartTime, vehicleShiftEndTime, 'minute', '[]');

          if (!isShiftMatches) {
            return false;
          }

          return true;
        });
      });

      if (provider) {
        const excludedVehicleIds = [];
        const queryParams = `and(ne(vehicleId,null),eq(providerName,${provider.get('id')}),or(and(ge(plannedStartTime,'${plannedStartFormat}'),le(plannedEndTime,'${plannedEndFormat}')),and(le(plannedStartTime,'${plannedStartFormat}'),ge(plannedEndTime,'${plannedStartFormat}')),and(le(plannedStartTime,'${plannedEndFormat}'),ge(plannedEndTime,'${plannedEndFormat}'))))`;
        const excludedRoutes = yield ajax.request(_apiUrls.API.schedulingService.host + `/route?filter=${queryParams}`, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        });
        excludedRoutes.data.forEach(excludedRoute => {
          let scheduleId = 0;
          let vehicleId = 0;

          if (Ember.isPresent(excludedRoute.relationships)) {
            if (Ember.isPresent(excludedRoute.relationships.schedule) && Ember.isPresent(excludedRoute.relationships.schedule.data)) {
              scheduleId = excludedRoute.relationships.schedule.data.id;
            }

            if (Ember.isPresent(excludedRoute.relationships.vehicle) && Ember.isPresent(excludedRoute.relationships.vehicle.data)) {
              vehicleId = excludedRoute.relationships.vehicle.data.id;
            }
          }

          const aPrimarySchedule = schedules.find(schedule => schedule.get('id') === scheduleId); // exclude booking schedule from filters

          if (Ember.isPresent(aPrimarySchedule)) {
            excludedVehicleIds.push(vehicleId);
          }
        }); // Remove vehicles assigned to routes that overlap this route

        nonPlaceHolderVehicles = nonPlaceHolderVehicles.filter(vehicle => {
          const currVehicleId = vehicle.get('id');

          if (selectedVehicle) {
            return !excludedVehicleIds.includes(currVehicleId) || selectedVehicle.get('id') === currVehicleId;
          }

          return !excludedVehicleIds.includes(currVehicleId);
        });
      }

      this.set('vehicles', (0, _lodash.concat)(placeHolderVehicles, nonPlaceHolderVehicles).sortBy('name'));
    }),
    fetchDriversTask: (0, _emberConcurrency.task)(function* () {
      let drivers = this.get('store').peekAll('driver');
      const filterDriversByHoliday = this.get('filterDriversByHoliday');
      const filterDriversByShiftTime = this.get('filterDriversByShiftTime');

      if (!drivers || drivers.length <= 1) {
        drivers = yield this.get('store').query('driver');
      }

      const dispatchRouteId = this.get('dispatchRoute.id');
      const provider = (0, _unwrapProxy.unwrapProxy)(this.get('provider'));
      const plannedStart = (0, _moment.default)(this.get('plannedStartTime'));
      const plannedEnd = (0, _moment.default)(this.get('plannedEndTime'));
      const dpScheduleId = this.get('dispatchRoute.dispatchSchedule.id') || this.get('record.dispatchSchedule.id') || this.get('selectedSchedule.dispatchSchedule.id');
      if (!drivers || !drivers.length) return []; // make sure vehicles are active and stay in the same provider as route

      if (provider) {
        drivers = drivers.filter(driver => {
          return driver.get('active') && provider.get('id') === driver.get('provider.id');
        });
      } // make sure any existing route->vehicle assignments in dispatch domain
      // do not intersect with destination route's planned window;


      drivers = drivers.filter(driver => {
        // data coming from driverWidget
        if (driver.get('isCompleted')) return true;
        let rvdHistory = driver.get('rvdHistory') ? Object.values(driver.get('rvdHistory')) : [];
        let isAvailable = true;

        if (Ember.isPresent(dispatchRouteId)) {
          // accessed by Assigned vehicle driver
          rvdHistory = rvdHistory.filter(rvd => rvd.dpScheduleId === dpScheduleId && !rvd.isCompleted);
        } else {
          // access by add new route in dispatch workspace.Route dispatch does not exist yet.
          rvdHistory = rvdHistory.filter(rvd => !rvd.isCompleted);
        }

        rvdHistory.forEach(rvd => {
          const startMoment = (0, _moment.default)(rvd.startTime);
          const endMoment = (0, _moment.default)(rvd.endTime);

          if (startMoment.isBetween(plannedStart, plannedEnd, null, '[)') || endMoment.isBetween(plannedStart, plannedEnd, null, '(]') || plannedStart.isBetween(startMoment, endMoment, null, '[)') || plannedEnd.isBetween(startMoment, endMoment, null, '(]')) {
            isAvailable = false;
          }
        });
        return isAvailable;
      });

      if (filterDriversByHoliday) {
        drivers = yield this.filterDriverAndVehicleByHolidayAvailability(drivers, 'driver-availability', 'driverId');
      }

      if (filterDriversByShiftTime) {
        drivers = yield this.filterDriversByShitTimeAvailability(drivers, plannedStart, plannedEnd);
      }

      this.set('drivers', drivers.sortBy('badgeNumber'));
    }),
    fetchScheduleDriversTask: (0, _emberConcurrency.task)(function* () {
      const ajax = this.get('ajax');
      const session = this.get('session');
      const excludedDriverIds = [];
      const schedules = this.get('store').peekAll('schedule');
      let drivers = this.get('store').peekAll('driver');
      let excludedRoutes = [];
      const filterDriversByHoliday = this.get('filterDriversByHoliday');
      const filterDriversByShiftTime = this.get('filterDriversByShiftTime');

      if (!drivers || drivers.length <= 1) {
        drivers = yield this.get('store').query('driver');
      }

      const provider = (0, _unwrapProxy.unwrapProxy)(this.get('provider'));
      const plannedStart = (0, _moment.default)(this.get('plannedStartTime'));
      const plannedEnd = (0, _moment.default)(this.get('plannedEndTime'));
      const plannedStartFormat = plannedStart.format();
      const plannedEndFormat = plannedEnd.format();
      if (!drivers || !drivers.length) return [];

      if (Ember.isPresent(provider)) {
        const queryParams = `and(ne(driverId,null),eq(providerName,${provider.get('id')}),or(and(ge(plannedStartTime,'${plannedStartFormat}'),le(plannedEndTime,'${plannedEndFormat}')),and(le(plannedStartTime,'${plannedStartFormat}'),ge(plannedEndTime,'${plannedStartFormat}')),and(le(plannedStartTime,'${plannedEndFormat}'),ge(plannedEndTime,'${plannedEndFormat}'))))`;
        excludedRoutes = yield ajax.request(_apiUrls.API.schedulingService.host + `/route?filter=${queryParams}`, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        });
        excludedRoutes.data.forEach(excludedRoute => {
          let scheduleId = 0;
          let driverId = 0;

          if (Ember.isPresent(excludedRoute.relationships)) {
            if (Ember.isPresent(excludedRoute.relationships.schedule) && Ember.isPresent(excludedRoute.relationships.schedule.data)) {
              scheduleId = excludedRoute.relationships.schedule.data.id;
            }

            if (Ember.isPresent(excludedRoute.relationships.driver) && Ember.isPresent(excludedRoute.relationships.driver.data)) {
              driverId = excludedRoute.relationships.driver.data.id;
            }
          }

          const aPrimarySchedule = schedules.find(schedule => schedule.get('id') === scheduleId); // exclude booking schedule from filters

          if (Ember.isPresent(aPrimarySchedule)) {
            excludedDriverIds.push(driverId);
          }
        });
      } // make sure vehicles are active and stay in the same provider as route


      if (provider) {
        drivers = drivers.filter(driver => {
          return driver.get('active') && provider.get('id') === driver.get('provider.id') && !excludedDriverIds.includes(driver.get('id'));
        });
      }

      if (filterDriversByHoliday) {
        drivers = yield this.filterDriverAndVehicleByHolidayAvailability(drivers, 'driver-availability', 'driverId');
      }

      if (filterDriversByShiftTime) {
        drivers = yield this.filterDriversByShitTimeAvailability(drivers, plannedStart, plannedEnd);
      }

      this.set('drivers', drivers.sortBy('badgeNumber'));
    }),

    /**
     * In dispatch workspace  routes are holidays routes then we should filter the vehicles and drivers by holiday availabities
     * @param records
     * @param availabilityModel
     * @param groupByKey
     * @returns {Promise<*>}
     */
    async filterDriverAndVehicleByHolidayAvailability(records, availabilityModel, groupByKey) {
      const startDate = (0, _moment.default)(this.get('workspace.startDate')).format(DATE_FORMAT);
      const holidayDates = this.getHolidayDates();
      const isHoliday = holidayDates.some(holiday => (0, _moment.default)(holiday).isSame(startDate));
      if (!isHoliday) return records;
      const availabilities = await this.get('store').query(availabilityModel, {});
      const availabilityMap = (0, _lodash.groupBy)(availabilities.toArray(), groupByKey);

      if (availabilityModel === 'driver-availability' && groupByKey === 'driverId') {
        //Save the driverAvailabilityMap for further uses
        this.set('driverAvailabilityMap', availabilityMap);
      }

      return records.filter(record => {
        const availabilityList = availabilityMap[record.get('id')] || [];
        return availabilityList.some(vehicleAvailability => vehicleAvailability.get('holiday'));
      });
    },

    getHolidayDates() {
      const csConfigItems = this.get('store').peekAll('cs-config-item').filter(csItem => csItem.get('category') === 'config-System_Configuration-holidays');
      const holidayDates = csConfigItems.filter(cs => Ember.isPresent(cs.value.date || cs.value.Date)).map(csConfigItem => {
        const dateString = csConfigItem.value.date || csConfigItem.value.Date;
        const momentDate = (0, _moment.default)();
        const dates = dateString.split('/');
        const month = parseInt(dates[0], 10);
        const date = parseInt(dates[1], 10);
        momentDate.set('month', month - 1);
        momentDate.set('date', date);
        return momentDate.format(DATE_FORMAT);
      });
      return holidayDates;
    },

    /**
     * We should filter the drivers by their shiftTime availabilities
     * @param records
     * @param plannedStart
     * @param plannedEnd
     * @returns {Promise<*>}
     */
    async filterDriversByShitTimeAvailability(records, plannedStart, plannedEnd) {
      let driverAvailabilityMap = this.get('driverAvailabilityMap');

      if ((0, _lodash.isEmpty)(driverAvailabilityMap)) {
        const availabilities = await this.get('store').query('driver-availability', {});
        driverAvailabilityMap = (0, _lodash.groupBy)(availabilities.toArray(), 'driverId');
      }

      return records.filter(record => {
        const rvdDayOfWeek = plannedStart.format('dddd').toLowerCase();
        const availabilityList = driverAvailabilityMap[record.get('id')] || [];
        return availabilityList.some(availability => {
          const driverStartMoment = availability.startTime;
          const driverEndMoment = availability.endTime;
          const shiftStartTime = (0, _moment.default)(availability.formattedShiftStart, _environment.default.dateTimeFormat.timeMoment);
          if (!availability.routeLength) return false;
          const shiftLength = availability.routeLength.split(':');
          if (!shiftLength || shiftLength.length !== 2) return false;
          const shiftHour = shiftLength[0];
          const shiftMinute = shiftLength[1];
          const driverShiftStartTime = plannedStart.clone().set({
            h: shiftStartTime.hour(),
            m: shiftStartTime.minute()
          });
          const driverShiftEndTime = driverShiftStartTime.clone().add(shiftHour, 'hour').add(shiftMinute, 'minute');
          const isDayOfWeekAvailable = availability[rvdDayOfWeek];
          const isDriverAvailableDates = plannedStart.isSameOrAfter(driverStartMoment, 'day') && plannedEnd.isSameOrBefore(driverEndMoment, 'day');
          const isShiftMatches = parseInt(shiftHour, 10) >= 24 || plannedStart.isBetween(driverShiftStartTime, driverShiftEndTime, null, '[]') && plannedEnd.isBetween(driverShiftStartTime, driverShiftEndTime, null, '[]');
          return isDayOfWeekAvailable && isShiftMatches && isDriverAvailableDates;
        });
      });
    }

  });

  _exports.default = _default;
});