define("adept-iq/config/api-urls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BUILD_VERSION = _exports.API = void 0;
  const BUILD_VERSION = {
    commitId: 'c549ef6'
  }; // Build c549ef6
  // S3 bucket iq.ui.aiqgreen.ddswireless.net

  _exports.BUILD_VERSION = BUILD_VERSION;
  const API = {
    aboutPageService: {
      host: 'https://iq.aiqgreen.ddswireless.net/1.0/versions'
    },
    addressService: {
      host: 'https://iq.aiqgreen.ddswireless.net/address'
    },
    avlService: {
      host: 'https://iq.aiqgreen.ddswireless.net/avl'
    },
    avlmService: {
      host: 'https://iq.aiqgreen.ddswireless.net/avlm'
    },
    avlmBookingService: {
      host: 'https://iq.aiqgreen.ddswireless.net/rider-booking'
    },
    bookingService: {
      host: 'https://iq.aiqgreen.ddswireless.net/booking'
    },
    configService: {
      host: 'https://iq.aiqgreen.ddswireless.net/config'
    },
    dispatchService: {
      host: 'https://iq.aiqgreen.ddswireless.net/dispatch'
    },
    driverService: {
      host: 'https://iq.aiqgreen.ddswireless.net/driver'
    },
    etaService: {
      host: 'https://iq.aiqgreen.ddswireless.net/eta'
    },
    geocodeService: {
      host: 'https://iq.aiqgreen.ddswireless.net/geocoding'
    },
    riderManagementService: {
      host: 'https://iq.aiqgreen.ddswireless.net/rider-management'
    },
    schedulingService: {
      host: 'https://iq.aiqgreen.ddswireless.net/scheduling'
    },
    ssoService: {
      host: 'https://iq.aiqgreen.ddswireless.net/sso',
      useMirage: false
    },
    vehicleService: {
      host: 'https://iq.aiqgreen.ddswireless.net/vehicle'
    },
    streamingService: {
      host: 'wss://iquxsvc.aiqgreen.ddswireless.net:443'
    },
    continuousOptService: {
      host: 'https://iq.aiqgreen.ddswireless.net/continuous-opt'
    },
    tomtom: {
      host: 'https://api.tomtom.com/'
    },
    iquxService: {
      host: 'https://iq.aiqgreen.ddswireless.net/iqux-service'
    },
    playbackService: {
      host: 'https://iq.aiqgreen.ddswireless.net/playback'
    },
    reconciliationService: {
      host: 'https://iq.aiqgreen.ddswireless.net/reconciliation'
    },
    tableauService: {
      host: 'https://aiq-tableau.ddswireless.com'
    },
    helpPage: {
      host: '/help-files'
    },
    fixedRouteEngineService: {
      host: 'https://iq.aiqgreen.ddswireless.net/fres'
    },
    benomad: {
      host: 'https://serviceohio.mbtabenomad.ddsdeploytest.com',
      domain: 'serviceohio.mbtabenomad.ddsdeploytest.com'
    },
    weatherAPI: {
      host: 'https://api.weather.gov/points/'
    },
    playbackNewService: {
      host: 'https://iq.aiqgreen.ddswireless.net/iq-s3-data-server'
    },
    reportService: {
      host: 'https://manage2.ddswireless.com/report-service',
      baseURL: 'https://jasper.ddswireless.net/jasperserver-pro/flow.html',
      companyName: 'iq.aiqgreen.ddswireless.net'
    },
    ReconciliationNewService: {
      host: 'https://iq.aiqgreen.ddswireless.net/s3reconciliation'
    },
    fareCalcService: {
      host: 'https://iq.aiqgreen.ddswireless.net/fcs'
    },
    dwhService: {
      host: 'https://iq.aiqgreen.ddswireless.net/data-service'
    },
    iqMap: {
      host: 'https://iq.aiqgreen.ddswireless.net/iq-map'
    },
    initiationService: {
      host: 'https://iq.aiqgreen.ddswireless.net/notification-initiation'
    }
  };
  _exports.API = API;
});