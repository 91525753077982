define("adept-iq/adapters/-iqux-iquxService", ["exports", "adept-iq/adapters/application", "adept-iq/config/api-urls"], function (_exports, _application, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _apiUrls.API.iquxService.host,

    pathForType(type) {
      // trim 'bs' prefix from model type
      const regEx = /^(?:avlm-)?(.*)$/;
      const match = regEx.exec(type)[1];
      return this._super(match);
    },

    urlForFindRecord(id, modelName) {
      const model = Ember.String.camelize(modelName);
      const iquxServiceHost = this.get('host');
      const baseUrl = `${iquxServiceHost}/${model}/${id}`;
      return baseUrl;
    },

    urlForFindAll(modelName) {
      const model = Ember.String.camelize(modelName);
      const iquxServiceHost = this.get('host');
      const baseUrl = `${iquxServiceHost}/${model}`;
      return baseUrl;
    },

    /**
     * this method is used to fetch the vehicle records directly from vehicle service
     * @param query
     * @param model
     * @returns {string}
     * @private
     */
    urlForQuery(query, modelName) {
      const model = Ember.String.camelize(modelName);
      return this._buildUrlForPath(query, model);
    },

    _buildUrlForPath(query, model) {
      const iquxServiceHost = this.get('host');
      const vehicleServiceHost = _apiUrls.API.vehicleService.host;
      const baseUrl = `${iquxServiceHost}/${model}`;

      if (query.useVehicleService) {
        return `${vehicleServiceHost}/${model}`;
      }

      return baseUrl;
    }

  });

  _exports.default = _default;
});