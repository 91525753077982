define("adept-iq/pods/components/generic-components/vertical-menu/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sRwo2GFU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onItemSelect\"],null]],[9],[0,\"\\n\"],[7,\"span\"],[12,\"disabled\",[21,\"disabled\"]],[9],[1,[23,[\"model\",\"title\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/generic-components/vertical-menu/item/template.hbs"
    }
  });

  _exports.default = _default;
});