define("adept-iq/pods/components/rider-management-widget/rider-contact-information/rider-address-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/rider-management-widget/rider-contact-information/rider-address-table/config", "adept-iq/utils/sorts", "adept-iq/utils/filters"], function (_exports, _component, _config, _sorts, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    config: _config.default,
    isNewOrEditing: false,
    recordSetter: Ember.observer('riderPlaces', 'riderPlaces.@each.isSoftDeleted', function () {
      Ember.run.debounce(this, 'refreshTable', 500);
    }),

    refreshTable() {
      this.clearData();
      this.refreshData();
    },

    getRiderPlaces(modelName, params) {
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const riderId = this.get('riderChangeSet.id');
      let activeData = store.peekAll(modelName) || [];
      activeData = activeData.filter(riderPlace => riderPlace.get('rider.id') === riderId && !riderPlace.get('isSoftDeleted'));
      return activeData.filter(filterFn).sort(compareFn);
    },

    init() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const filteredData = this.getRiderPlaces(modelName, params);
      return filteredData;
    },

    actions: {
      onPlusIconClick() {
        this.set('isCreatingNewAddress', true);
        this.set('canShowNewAddressModal', true);
        this.get('createNewRiderPlaceRecord')();
      },

      onEditIconClick() {
        const riderPlaceRecord = this.get('checkedRows.firstObject');
        this.get('onEditRiderPlace')(riderPlaceRecord);
      },

      onDeleteIconClick() {
        const riderPlaceRecord = this.get('checkedRows.firstObject');
        this.get('onDeleteRiderPlace')(riderPlaceRecord);
      }

    }
  });

  _exports.default = _default;
});