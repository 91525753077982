define("adept-iq/authorizers/sso", ["exports", "ember-simple-auth/authorizers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    session: Ember.inject.service(),

    authorize(data, block) {
      if (Ember.isPresent(data.token)) {
        block('Authorization', `Bearer ${data.token}`);
      }
    }

  });

  _exports.default = _default;
});