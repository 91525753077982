define("adept-iq/pods/components/side-drawer/rescue-route/component", ["exports", "moment", "ember-light-table", "ember-concurrency", "adept-iq/config/api-urls", "adept-iq/pods/components/side-drawer/rescue-route/config", "adept-iq/utils/availability", "adept-iq/utils/unwrapProxy", "adept-iq/mixins/fetchAssignableVehicleDrivers", "lodash", "adept-iq/config/environment"], function (_exports, _moment, _emberLightTable, _emberConcurrency, _apiUrls, _config, _availability, _unwrapProxy, _fetchAssignableVehicleDrivers, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const dispatchRouteHost = _apiUrls.API.dispatchService.host;
  const NUMBER_FORMAT = /^\d*$/;

  var _default = Ember.Component.extend(_fetchAssignableVehicleDrivers.default, {
    tagName: '',
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    componentName: 'rescueReplacement',
    currentState: null,
    routeName: null,
    startTime: null,
    endTime: null,
    selectedVehicle: null,
    selectedDriver: null,
    dateTimeFormat: _environment.default.dateTimeFormat,
    timeFormat: _environment.default.dateTimeFormat.dateTimeFlatPickr,
    provider: Ember.computed.readOnly('selectedVehicle.provider'),
    plannedStartTime: Ember.computed.readOnly('startTime'),
    //used in fetch vehicle driver mixin
    plannedEndTime: Ember.computed.readOnly('endTime'),
    //used in fetch vehicle driver mixin
    dispatchRoute: Ember.computed.readOnly('stackFrame.options.dsRoute'),
    dispatchRouteVersion: null,
    route: Ember.computed.readOnly('dispatchRoute.route'),
    assignedVehicle: Ember.computed.readOnly('dispatchRoute.assignedVehicle.name'),
    assignedDriver: Ember.computed.readOnly('dispatchRoute.assignedDriver.driverId'),
    assignedProvider: Ember.computed.readOnly('dispatchRoute.provider.id'),
    minEndTime: Ember.computed('startTime', function () {
      const plannedStart = (0, _moment.default)(this.get('startTime'));
      return plannedStart.add(1, 'minutes').toDate();
    }),
    drivers: null,
    vehicles: null,
    refreshFlag: false,
    onShrinkClick: null,
    onCloseClick: null,
    ignoreCapacityViolations: false,
    rowComponent: _config.rowComponent,

    init() {
      this._super(...arguments);

      const dispatchRoute = this.get('dispatchRoute');

      if (!Ember.isEmpty(_config.tripColumns)) {
        _config.tripColumns.forEach(tripColumn => {
          if (tripColumn.highlightable) {
            tripColumn.cellClassNames = ['highlightable'];
          }
        });
      }

      const trips = this.get('dispatchRoute.unPerformedTrips');
      const table = new _emberLightTable.default(_config.tripColumns, trips, {
        enableSync: false
      });
      this.set('timePlaceHolder', _environment.default.dateTimeFormat.dateTimeMoment);
      this.set('tripsTable', table);
      this.set('dispatchRouteVersion', dispatchRoute.version);
      const today = (0, _moment.default)().startOf('minute');
      this.set('date', today.toISOString());
      this.set('startTime', today.toISOString());
    },

    didInsertElement() {
      this._super(...arguments); // side panel width just for assign-vehicle-driver-to-route


      this.setSideDrawerWidth('600px');

      if (!this.getSchedule()) {
        this.onCloseClick();
        this.get('notifications').warning('Please export a schedule to use rescue route');
      }

      this.fetchDriversTask.perform();
      this.fetchVehiclesTask.perform(); // this.set('endTime', moment().endOf('day').toISOString());
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('routeName', currentState.routeName);
        this.set('startTime', currentState.startTime);
        this.set('endTime', currentState.endTime);
        this.set('selectedDriver', currentState.selectedDriver);
      }
    },

    hasAllSelectedValues: Ember.computed.and('routeName', 'selectedDriver', 'selectedVehicle', 'startTime', 'endTime'),
    selectedStopPoints: Ember.computed('tripsTable.rows.@each.isChecked', function () {
      return (0, _lodash.flatten)(this.get('tripsTable.rows').filterBy('isChecked', true).map(trip => trip.get('stopPoints').toArray()));
    }),
    availableDrivers: Ember.computed('startTime', 'endTime', 'drivers.[]', function () {
      const drivers = this.get('drivers') ? this.get('drivers').filter(driver => {
        return driver.providerName === this.get('assignedProvider');
      }) : null;
      const selectedDriver = this.get('selectedDriver'); // always show selected driver

      if (selectedDriver && !drivers.includes(selectedDriver)) {
        drivers.unshift(selectedDriver);
      }

      if (!drivers || !drivers.length) return [];
      return drivers;
    }),
    availableVehicles: Ember.computed('startTime', 'endTime', 'vehicles.[]', function () {
      const vehicles = this.get('vehicles') ? this.get('vehicles').filter(vehicle => {
        return vehicle.providerId === this.get('assignedProvider');
      }) : null;
      const selectedVehicle = this.get('selectedVehicle'); // always show selected vehicle

      if (selectedVehicle && !vehicles.includes(selectedVehicle) && !selectedVehicle.get('placeholder') && !selectedVehicle.isBroken) {
        vehicles.unshift(selectedVehicle);
      }

      if (!vehicles || !vehicles.length) return [];
      return vehicles;
    }),
    driverAvailability: Ember.computed('startTime', 'endTime', 'selectedDriver.driverAvailabilities.@each.{startTime,endTime}', function () {
      const driverAvailabilities = this.get('selectedDriver.driverAvailabilities');
      if (Ember.isEmpty(driverAvailabilities) || !this.get('startTime') || !this.get('endTime')) return null;
      const plannedStart = (0, _moment.default)(this.get('startTime'));
      const plannedEnd = (0, _moment.default)(this.get('endTime'));
      const solution = (0, _availability.solveAvailability)(plannedStart, plannedEnd, driverAvailabilities);
      if (Ember.isEmpty(solution)) return null;
      return solution[0];
    }),
    driverAvailabilityShiftStart: Ember.computed('driverAvailability', function () {
      const availability = this.get('driverAvailability');
      if (Ember.isEmpty(availability) || Ember.isEmpty(availability.matchingAvailability)) return null;
      return availability.matchingAvailability.get('formattedShiftStart');
    }),
    driverAvailabilityShiftEnd: Ember.computed('driverAvailability', function () {
      const availability = this.get('driverAvailability');
      if (Ember.isEmpty(availability) || Ember.isEmpty(availability.matchingAvailability)) return null;
      return availability.matchingAvailability.get('formattedShiftEnd');
    }),

    getSchedule() {
      const schedule = (0, _unwrapProxy.unwrapProxy)(this.get('route.schedule'));
      if (!schedule) return false;
      this.set('schedule', schedule);
      return true;
    },

    formatBulkTransfer(stopPoints, sourceId, destinationId) {
      return stopPoints.map((stop, index) => {
        return {
          id: stop.id,
          type: 'stop',
          source: sourceId,
          destination: destinationId,
          ordinal: index + 1
        };
      });
    },

    saveRescueRouteTask: (0, _emberConcurrency.task)(function* () {
      const {
        routeName,
        selectedDriver,
        selectedVehicle,
        startTime,
        endTime,
        schedule
      } = this.getProperties('routeName', 'selectedDriver', 'selectedVehicle', 'startTime', 'endTime', 'schedule');
      const ajax = this.get('ajax');
      const token = this.get('session.data.authenticated.token');
      const startGarage = this.get('dispatchRoute.startGarage');
      const endGarage = this.get('dispatchRoute.endGarage');
      const dispatchRouteId = this.get('dispatchRoute.id');
      const vehicleId = selectedVehicle ? selectedVehicle.get('id') : null;
      const driverId = selectedDriver ? selectedDriver.get('id') : null;
      const providerNameId = selectedVehicle ? selectedVehicle.get('provider.id') : null;
      const dispatchScheduleId = schedule ? schedule.get('dispatchSchedule.id') : null;
      const startGarageId = startGarage ? startGarage.get('id') : null;
      const endGarageId = endGarage ? endGarage.get('id') : null;
      const dispatchRouteVersion = this.get('dispatchRouteVersion');
      const json = {
        data: {
          type: 'specialRoute',
          attributes: {
            name: routeName,
            routeType: 'rescue replacement',
            shiftStartTime: startTime,
            shiftEndTime: endTime
          },
          relationships: {
            dispatchSchedule: {
              data: {
                id: dispatchScheduleId,
                type: 'dispatchSchedule'
              }
            },
            driver: {
              data: {
                id: driverId,
                type: 'driver'
              }
            },
            providerName: {
              data: {
                id: providerNameId,
                type: 'providerName'
              }
            },
            vehicle: {
              data: {
                id: vehicleId,
                type: 'vehicle'
              }
            },
            startGaragePlace: {
              data: {
                id: startGarageId,
                type: 'place'
              }
            },
            endGaragePlace: {
              data: {
                id: endGarageId,
                type: 'place'
              }
            },
            replacedRoute: {
              data: {
                id: dispatchRouteId,
                type: 'dispatchRoute'
              }
            }
          }
        },
        included: [{
          id: dispatchRouteId,
          type: 'dispatchRoute',
          attributes: {
            version: dispatchRouteVersion
          }
        }]
      };
      return yield ajax.post(`${dispatchRouteHost}/special-route`, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: json
      }).catch(_ref => {
        let {
          payload
        } = _ref;
        const error = new Error();
        if (payload.message) error.message = payload.message;
        if (payload.status) error.status = payload.status;
        if (payload.statusCode) error.status = payload.statusCode;
        throw error;
      });
    }).keepLatest(),
    saveTripsTask: (0, _emberConcurrency.task)(function* (newDispatchRoute) {
      const selectedStopPoints = this.get('selectedStopPoints');
      const sourceId = this.get('dispatchRoute.id');
      const destinationId = newDispatchRoute.data.id;
      const tripInstructions = this.formatBulkTransfer(selectedStopPoints, sourceId, destinationId);
      const bulkOperationAdapter = this.get('store').adapterFor('bulk-operation');
      const ignoreCapacityViolations = this.get('ignoreCapacityViolations');
      return yield bulkOperationAdapter.createBulkAsyncOperation.perform({
        instructions: tripInstructions
      }, ignoreCapacityViolations).then(result => {
        if (result.isJobSuccess) {
          return Promise.resolve();
        }

        return Promise.reject(result.result);
      }).catch(function (error) {
        return Promise.reject(error);
      });
    }).keepLatest(),

    isValidEntry() {
      const selectedStopPoints = this.get('selectedStopPoints');
      const hasAllSelectedValues = this.get('hasAllSelectedValues');
      const tripCount = selectedStopPoints.length / 2; // 2 stops per trip

      const isAfter = (0, _moment.default)(this.get('endTime')).isAfter((0, _moment.default)(this.get('startTime')));
      const maxTravelNeedCounts = this.get('dispatchRoute.maxTravelNeedCounts');
      const vehicleType = (0, _unwrapProxy.unwrapProxy)(this.get('selectedVehicle.vehicleType'));
      const arrivedPerformedStops = [];
      const routeName = this.get('routeName');
      const routeMaxTimeLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeMaxTimeLimit');
      const plannedStart = (0, _moment.default)(this.get('startTime'));
      const plannedEnd = (0, _moment.default)(this.get('endTime'));
      const routeMaxTimeLimit = routeMaxTimeLimitParam ? parseInt(routeMaxTimeLimitParam.get('value'), 10) : 720; // minutes

      const routeTimeDiff = plannedEnd.diff(plannedStart, 'minutes');
      const schedule = this.get('schedule');
      const scheduleStart = (0, _moment.default)(schedule.get('start'));
      const scheduleEnd = (0, _moment.default)(schedule.get('end'));

      if (!isNaN(parseInt(routeName, 10)) && (parseInt(routeName, 10) > 2147483648 || parseInt(routeName, 10) < 1)) {
        this.set('errorMessage', 'Route ID must be between 1 to 2147483648');
        return false;
      }

      if (routeTimeDiff > routeMaxTimeLimit) {
        this.set('errorMessage', `Route length of ${routeTimeDiff} exceeds config Route Max Time Limit of ${routeMaxTimeLimit} minutes`);
        return false;
      }

      if (scheduleStart.isValid() && scheduleEnd.isValid() && !(scheduleStart.isSameOrBefore(plannedStart) && scheduleEnd.isSameOrAfter(plannedEnd))) {
        this.set('errorMessage', 'Route duration should be within the current schedule time');
        return false;
      }

      selectedStopPoints.forEach(selectedStop => {
        if (!selectedStop.get('notPerformed') && selectedStop.get('isPick')) {
          arrivedPerformedStops.push(selectedStop);
        }
      });

      if (Ember.isPresent(arrivedPerformedStops)) {
        const tripIds = arrivedPerformedStops.map(stop => stop.get('trip.tripId'));
        this.set('errorMessage', `Trip(s) ${tripIds.join(', ')} already in progress.`);
        return false;
      }

      if (!tripCount) {
        this.set('errorMessage', 'Please select at least one trip.');
        return false;
      }

      if (!isAfter) {
        this.set('errorMessage', 'Please set end time after start time');
        return false;
      }

      if (hasAllSelectedValues) {
        // reset error message
        this.set('errorMessage');
      } else {
        // show error message, missing properties
        this.set('errorMessage', 'Please fill out all of the fields before saving.');
        return false;
      }

      if (vehicleType.noOfAmbulatorySeats < maxTravelNeedCounts.ambulatory || vehicleType.noOfServiceAnimalSeats < maxTravelNeedCounts.serviceAnimal || vehicleType.noOfWheelChairSeats < maxTravelNeedCounts.wheelchair || vehicleType.noOfWideAmbulatorySeats < maxTravelNeedCounts.wideAmbulatory || vehicleType.noOfWideWheelChairSeats < maxTravelNeedCounts.wideWheelchair) {
        const tooltip = this.get('tooltip');
        const title = 'Rescue Route';
        tooltip.pushConfirmation({
          tip: 'You are about to assign an incompatible vehicle. All trips that cannot be serviced will be waitlisted.',
          title,
          hasOverlay: true,
          warning: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            this.set('ignoreCapacityViolations', true);
            this.confirmSave();
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            tooltip.reset();
          }
        });
        return false;
      }

      return true;
    },

    async confirmSave() {
      const tooltip = this.get('tooltip');
      const saveRescueRouteTask = this.get('saveRescueRouteTask');
      const routeName = this.get('routeName');
      const oldRouteName = this.get('route.name');
      const selectedStopPoints = this.get('selectedStopPoints');
      const notifications = this.get('notifications');
      const title = 'Rescue Route';
      const tripCount = selectedStopPoints.length / 2; // 2 stops per trip

      const tripCountPlural = tripCount > 1;
      const dispatchRoute = this.get('dispatchRoute');
      const lockRouteAction = this.get('lockRouteAction').bind(this);
      const unlockRouteAction = this.get('unlockRouteAction').bind(this);
      this.set('dispatchRouteToCheck', dispatchRoute);
      let newRouteCreated = false;

      const closeCallback = () => {
        this.send('onCloseClick');
      };

      try {
        // Check dispatchRoute version first
        await this.get('versionCheckOperation').perform(closeCallback.bind(this));
        await lockRouteAction();
        tooltip.pushConfirmation({
          tip: `You are rescuing ${tripCount} late ${tripCountPlural ? 'trips' : 'trip'} from route ${oldRouteName} to new route ${routeName}`,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            try {
              const newDispatchRoute = await saveRescueRouteTask.perform();
              newRouteCreated = true;
              await this.saveTripsTask.perform(newDispatchRoute);
              notifications.success(`Successfully transferred ${tripCount} late ${tripCountPlural ? 'trips' : 'trip'} to new route ${routeName}`);
            } catch (error) {
              let message;

              if (newRouteCreated) {
                message = `Failed to transfer ${tripCount} late ${tripCountPlural ? 'trips' : 'trip'} to new route ${routeName}`;
              } else {
                switch (error.status) {
                  case 400:
                    message = 'There was a problem with one of the fields. Please check over the form and try again.';
                    break;

                  case 409:
                    if (error.message === 'Error saving data that must be unique') message = `The route name ${this.get('routeName')} is already taken`;else if (error.message.includes('modified by another request')) message = `UNABLE TO UPDATE ROUTE ${this.get('route.name')}, BECAUSE IT HAS BEEN MODIFIED BY ANOTHER REQUEST.`;else message = error.message;
                    break;

                  default:
                    message = 'There was a problem creating the route, please try again.';
                    break;
                }
              }

              notifications.warning(message);
            } finally {
              this.get('workspace').requestWootricSurvey('on_rescueRoute');
              await unlockRouteAction(0);
              this.send('onCloseClick');
              tooltip.reset();
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: async () => {
            await unlockRouteAction(0);
            return tooltip.reset();
          },
          closeAction: async () => {
            await unlockRouteAction(0);
          }
        });
      } catch (e) {
        await unlockRouteAction();
      }
    },

    actions: {
      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
      },

      onCancel() {
        this.onCloseClick();
      },

      onCloseClick() {
        const tripsTable = this.get('tripsTable');

        if (tripsTable) {
          const trips = tripsTable.get('rows') || [];
          trips.forEach(row => {
            row.set('isChecked', false);
          });
        }

        this.onCloseClick();
      },

      onRouteNameInput(name) {
        if (!NUMBER_FORMAT.test(name) || name.length > 10) {
          return this.notifyPropertyChange('routeName');
        }

        this.set('routeName', name);
      },

      onSaveClick() {
        if (!this.isValidEntry()) {
          return false;
        }

        this.confirmSave();
      },

      onRefreshClick() {
        this.fetchDriversTask.perform();
        this.fetchVehiclesTask.perform(); // Reset form

        this.setProperties({
          routeName: null,
          startTime: null,
          endTime: null,
          selectedVehicle: null,
          selectedDriver: null
        });
        this.toggleProperty('refreshFlag');
        const tripsTable = this.get('tripsTable');

        if (tripsTable) {
          const trips = tripsTable.get('rows') || [];
          trips.forEach(row => {
            row.set('isChecked', false);
          });
        } // Set init startTime/endTime on next lifecycle


        Ember.run.next(() => {
          this.setProperties({
            startTime: (0, _moment.default)().toISOString(),
            endTime: (0, _moment.default)().endOf('day').toISOString()
          });
        });
      },

      onShrinkClick() {
        let currentState = this.get('currentState');
        const routeName = this.get('routeName');
        const startTime = this.get('startTime');
        const endTime = this.get('endTime');
        const selectedDriver = this.get('selectedDriver'); // save current manual settings

        currentState = {
          routeName,
          startTime,
          endTime,
          selectedDriver
        };
        this.set('currentState', currentState);
        this.onShrinkClick();
      },

      onCheckAll() {
        const tripsTable = this.get('tripsTable');
        const tripsTableList = tripsTable.get('rows') || [];
        tripsTableList.forEach(row => {
          row.set('isChecked', true);
        });
      },

      onUncheckAll() {
        const tripsTable = this.get('tripsTable');
        const trips = tripsTable.get('rows') || [];
        trips.forEach(row => {
          row.set('isChecked', false);
        });
      },

      onSelectedVehicle(vehicle) {
        this.set('selectedVehicle', vehicle);
      }

    }
  });

  _exports.default = _default;
});