define("adept-iq/pods/components/iq-widgets/scatter-chart-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/chartjs-widget/component", "ember-concurrency", "lodash"], function (_exports, _component, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    workspace: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    classNames: ['scatter-chart-widget'],
    widget: null,
    options: Ember.computed.alias('widget.state.dataType'),
    records: null,
    isEmpty: false,
    refreshInProgress: Ember.computed.readOnly('activeContext.refreshInProgress'),

    init() {
      this._super(arguments);

      this.set('records', []);
    },

    willDestroyElement() {
      this._super(arguments);
    },

    /* Applies the choice of data source. */
    chartRecords: Ember.computed('records.[]', function () {
      // const modelName = this.get('options.modelName');
      const wrappedRecords = this.get('records');
      return wrappedRecords;
    }),
    refreshChart: Ember.computed('refreshInProgress', function () {
      this.get('updateChartRecordsTask').perform();
      return this.get('refreshInProgress');
    }),
    updateChartRecordsTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(400);
      this.set('records', this.store.peekAll('packet').toArray());
    }).keepLatest(),

    /* Builds the chosen filters. */
    filterFunction: Ember.computed('options.columns', function () {// const filterColumns = Object.values(this.get('options.columns')).map((col) => new FilterColumn(col));
      // const args = filterColumns.map((column) => {
      //   return column.get('filterNode');
      // });
      // return buildFilterFunction(buildCompoundFilterNode('and', args));
    }),

    /* Applies the choice of filters. */
    filteredRecords: Ember.computed('chartRecords', 'filterFunction', function () {
      // return this.get('chartRecords').filter(this.get('filterFunction'));
      return this.get('chartRecords');
    }),
    chartSum: Ember.computed.alias('filteredRecords.length'),

    /* Gets the choice for slicing the data. */
    slicedBy: Ember.computed('options.displayOptions', function () {
      if (Ember.isBlank(this.get('options.displayOptions'))) return;
      /*eslint-disable */

      return this.get('options.displayOptions').selected;
      /*eslint-enable */
    }),

    /* Applies the choice for slicing the data. */
    recordSlices: Ember.computed('filteredRecords', 'slicedBy', function () {
      // const categories = {};
      const filteredRecords = this.get('filteredRecords') || [];
      const data = [];
      filteredRecords.forEach(packet => {
        data.push({
          t: new Date(packet.timestamp),
          y: packet.topic
        });
      });
      return data; // const slicedBy = this.get('slicedBy');
      // if (isBlank(slicedBy)) return categories;
      // const filterKey = this.get('slicedBy.id');
      // const metricOption = this.get('slicedBy.metricOption.id');
      // this.get('slicedBy.valueCategories').forEach((category) => {
      //   switch (metricOption) {
      //   case 'count':
      //     categories[category.label] = filteredRecords.filterBy(filterKey, category.value).length;
      //     break;
      //   case 'sumOccurrences':
      //     categories[category.label] = filteredRecords.reduce((a, b) => {
      //       return a + b.get(filterKey).filter((item) => item === category.value).length;
      //     }, 0);
      //     break;
      //   default:
      //     return;
      //   }
      // });
      // { 'label1': <value>, 'label2': <value> }
      // return categories;
    }),

    /* Applies the choices for displaying data counts and/or percentages. */
    aggregatedSliceData: Ember.computed('recordSlices', 'options.visualOptions.@each.isChecked', function () {
      // const visualOptions = this.get('options.visualOptions');
      // const showValues = visualOptions.findBy('id', 'value').isChecked;
      // const showPercentage = visualOptions.findBy('id', 'percentage').isChecked;
      // const totalCount = this.get('chartSum');
      const data = [];
      const recordSlices = this.get('recordSlices') || [];
      Object.entries(recordSlices).forEach(slice => {
        const label = slice[1].y;
        const timestamp = slice[1].t; // if (showValues) {
        //   label += (', ' + numRecords);
        // }
        // if (showPercentage && totalCount !== 0) {
        //   label += (', ' + Math.floor(100 * numRecords / totalCount) + '%');
        // }

        data.push({
          label: label,
          value: timestamp
        });
      }); // [{...}, {...}, ...]

      return data;
    }),
    chartData: Ember.computed('aggregatedSliceData', function () {
      const aggregatedData = this.get('aggregatedSliceData');
      if (Ember.isBlank(aggregatedData)) return {
        data: {
          datasets: []
        },
        labels: [],
        datasets: []
      };
      const data = [];
      const labels = {}; // const backgroundColor = [];
      // const colors = this.get('colors');

      aggregatedData.forEach(dataSlice => {
        data.push({
          t: dataSlice.value,
          y: dataSlice.label
        });
        labels[dataSlice.label] = null;
      });

      const yLabels = _lodash.default.keys(labels);

      const datasets = [{
        data: data,
        labels: [],
        label: 'Number of Network Packets for Each Updated Topic'
      }];
      return {
        datasets,
        yLabels
      };
    }),

    /* Applies the chart title input. */
    chartTitle: Ember.computed('widget.state.dataType.{name,title}', function () {
      const title = this.get('widget.state.dataType.title');
      if (Ember.isPresent(title)) return title;
      const name = this.get('widget.state.dataType.name');

      if (Ember.isPresent(name)) {
        return `${name} Scatter Chart`;
      } // this.get('updateChartRecordsTask').perform();


      return 'Scatter Chart';
    }),
    chartOptions: Ember.computed('', function () {
      const options = {
        scales: {
          xAxes: [{
            type: 'time',
            distribution: 'series',
            time: {
              displayFormats: {
                second: 'h:mm:ss a'
              }
            },
            ticks: {
              autoSkip: false,
              fontSize: 11,
              precision: 2,
              suggestedMin: 0,
              minRotation: 30
            }
          }],
          yAxes: [{
            type: 'category',
            position: 'left',
            display: true
          }]
        },
        responsive: true,
        maintainAspectRatio: true
      };
      return options;
    }),
    actions: {
      onHeaderDoubleClick() {
        const maximizer = this.get('maximizer');

        if (maximizer.maximizedWidget === this.get('widget')) {
          maximizer.minimize();
          return;
        }

        maximizer.maximize(this.get('widget'));
      },

      onExitMaximizedClick() {
        const maximizer = this.get('maximizer');
        maximizer.minimize();
      },

      onFilterButtonClick() {
        const workspace = this.get('workspace');
        let topState = workspace.get('topState');

        while (topState === 'scatterChartWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        const displayName = 'Scatter Chart';
        const widget = this.get('widget');
        workspace.pushState('scatterChartWidget', {
          displayName,
          widget
        });
      }

    }
  });

  _exports.default = _default;
});