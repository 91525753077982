define("adept-iq/classes/socket-stream", ["exports", "adept-iq/config/api-urls"], function (_exports, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TIME_FILTERING_ENABLED = false;
  const SOCKET_BASE_URL = _apiUrls.API.streamingService.host; // closure codes

  const SOCKET_CLEAN_CLOSURE_CODE = 1000;
  const SOCKET_BAD_JWT = 4001;
  const SOCKET_BAD_PREFIX = 4002;
  const SOCKET_DUPLICATE_CONSUMER = 4003; // reconnection defaults

  const DEFAULT_RECONNECT_SLEEP_TIME = 5000;
  const DEFAULT_MAX_RECONNECT_COUNT = Infinity;
  const START_PHASE = 'START';

  const generateUUID = () => '_' + Math.random().toString(36).substr(2, 9);

  var _default = Ember.Object.extend(Ember.Evented, {
    clientId: null,
    token: null,
    // legacy support
    topic: null,
    // filter params
    startDate: null,
    endDate: null,
    // stream state
    phase: START_PHASE,
    offset: 0,
    socketBaseUrl: SOCKET_BASE_URL,
    socketId: null,
    reconnectCount: 0,
    errorCode: null,
    closeCode: null,
    reconnectSleepTime: DEFAULT_RECONNECT_SLEEP_TIME,
    maxReconnectCount: DEFAULT_MAX_RECONNECT_COUNT,
    // this is logical state; it reflects whether or not the socket service
    // believes this connection is "on" or "off", not the actual socket state
    isConnected: false,
    // this reflects the _actual_ state of the socket; it may connect and
    // disconnect throughout the lifetime of a logical streaming connection
    socketState: 'init',
    // init, pending, open, error, closed
    isSocketOpen: Ember.computed.equal('socketState', 'open'),
    isSocketClosed: Ember.computed.equal('socketState', 'closed'),
    isSocketError: Ember.computed.equal('socketState', 'error'),
    _worker: null,
    _openTimeout: null,
    _reconnectTimeout: null,

    destroy() {
      if (this.get('isConnected')) {
        this.disconnect();
      }

      if (this._reconnectTimeout) {
        Ember.run.cancel(this._reconnectTimeout);
      } // this will tear down listener


      this.set('worker', null);

      this._super(...arguments);
    },

    // adds and removes listeners to worker; this is called during init when a
    // worker property is passed to `create()`
    worker: Ember.computed({
      get() {
        return this._worker;
      },

      set(key, value) {
        if (this._worker) {
          this._worker.removeEventListener('message', this.__onWorkerMessage);

          this.__onWorkerMessage = null;
        }

        this._worker = value;

        if (this._worker) {
          this.__onWorkerMessage = e => {
            Ember.run.schedule('afterRender', this, '_onWorkerMessage', e.data);
          };

          this._worker.addEventListener('message', this.__onWorkerMessage);
        }

        return value;
      }

    }),

    connect(startDate, endDate, token) {
      let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      let reconnectCount = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
      const clientId = options.clientId || generateUUID();
      const offset = options.offset || 0;
      const phase = options.phase || START_PHASE; // needs to be unique _every_ time we connect

      const socketId = new Date().getTime().toString();
      this.setProperties({
        clientId,
        // auth
        token,
        // filter params
        startDate,
        endDate,
        // stream state
        offset,
        phase,
        // local state
        isConnected: true,
        socketState: 'pending',
        reconnectCount,
        socketId: socketId
      });
      this.get('worker').postMessage({
        action: 'connect',
        socketId,
        token,
        url: this._generateURL(),
        startDate,
        endDate
      });
    },

    sendGlobalFilter(startDate, endDate, selectedProviders, startDateUTC, endDateUTC, scheduleId) {
      this.get('worker').postMessage({
        action: 'sendGlobalFilter',
        socketId: this.get('socketId'),
        startDate,
        endDate,
        selectedProviders,
        startDateUTC,
        endDateUTC,
        scheduleId
      });
    },

    sendWidgetFilter(widgetInstanceId, modelName, filter, offset, limit, sort) {
      this.get('worker').postMessage({
        action: 'sendWidgetFilter',
        socketId: this.get('socketId'),
        widgetInstanceId,
        modelName,
        filter,
        offset,
        limit,
        sort
      });
    },

    sendFilter(startDate, endDate) {
      this.get('worker').postMessage({
        action: 'sendFilter',
        socketId: this.get('socketId'),
        startDate,
        endDate
      });
    },

    disconnect() {
      this.set('isConnected', false);
      this.get('worker').postMessage({
        action: 'disconnect',
        socketId: this.get('socketId')
      });
    },

    // re-uses current config; probably only for internal use
    _reconnect() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const startDate = options.startDate || this.get('startDate');
      const endDate = options.endDate || this.get('endDate');
      const clientId = options.clientId || this.get('clientId');
      const token = options.token || this.get('token');
      const offset = options.offset || this.get('offset');
      const phase = options.phase || this.get('phase');
      this.connect(startDate, endDate, token, {
        offset,
        phase,
        clientId
      }, this.get('reconnectCount') + 1);
    },

    _onWorkerMessage(message) {
      if (message.socketId !== this.get('socketId')) {
        // this is just our old socket closing out; ignore it
        if (message.action === 'close') return; // still receiving messages from old socket; kill it

        return this.get('worker').postMessage({
          action: 'disconnect',
          socketId: message.socketId
        });
      }

      switch (message.action) {
        case 'open':
          return this._onSocketOpenMessage(message);

        case 'error':
          return this._onSocketErrorMessage(message);

        case 'data':
          return this._onSocketDataMessage(message);

        case 'close':
          return this._onSocketCloseMessage(message);

        default:
          break;
      }
    },

    _onSocketOpenMessage() {
      this.set('socketState', 'open');

      this._log('socket connection open');

      this.set('closeCode', null);
      this.set('errorCode', null);
    },

    _onSocketErrorMessage(message) {
      this._warn('socket connection error', message.code); // failed to make connection, e.g. remote host not found


      this.set('socketState', 'error');
      this.set('errorCode', message.code);

      this._attemptReconnect();
    },

    _onSocketDataMessage(message) {
      // once we see data, we can consider reconnection successful
      this.set('reconnectCount', 0);
      this.trigger('data', this, message.payloads);
    },

    _onSocketCloseMessage(message) {
      const {
        code
      } = message;
      this.set('socketState', 'closed');
      this.set('closeCode', code);

      switch (code) {
        case SOCKET_BAD_JWT:
          return this._onSocketCloseUnauthorized(message);

        case SOCKET_DUPLICATE_CONSUMER:
        case SOCKET_BAD_PREFIX:
        case SOCKET_CLEAN_CLOSURE_CODE:
        default:
          this._warn('socket connection closed', code);

          break;
      } // already disconnecting; nothing more to do


      if (!this.get('isConnected')) return;

      this._attemptReconnect();
    },

    _onSocketCloseUnauthorized() {
      this._warn('socket connection unauthorized');

      this.set('isConnected', false);
      this.trigger('unauthorized', this);
    },

    _attemptReconnect() {
      if (this.get('reconnectCount') === this.get('maxReconnectCount')) {
        this._warn('socket connection failed; max reconnects reached');

        this.set('isConnected', false);
        return;
      } // wait a little bit, and if still warnically_ connected, try to re-
      // establish web socket connection


      if (!this._reconnectTimeout) {
        this._reconnectTimeout = Ember.run.later(() => {
          if (this.get('isConnected')) {
            const count = this.get('reconnectCount');

            this._log(`socket re-connection attempt #${count + 1}...`);

            this._reconnect();
          }

          this._reconnectTimeout = null;
        }, this.get('reconnectSleepTime'));
      }
    },

    _generateURL() {
      const clientId = this.get('clientId');
      const topic = this.get('topic');
      const startDate = this.get('startDate');
      const endDate = this.get('endDate');
      const queryTerms = [`clientId=${clientId}`]; // `topic` is not used in new ESS

      if (topic && topic !== 'all') {
        queryTerms.push(`topic=${topic}`);
      } // filtering will be updated later. currently disabled until we get the new specs


      if (TIME_FILTERING_ENABLED) {
        if (topic === 'tripWidget' || topic === 'avlmTripWidget') {
          const filterString = [`requestTime=ge=${startDate.toISOString()}`, `requestTime=le=${endDate.toISOString()}`].join('%26');
          queryTerms.push(`filter=${filterString}`);
        }

        if (topic === 'routeWidget' || topic === 'avlmRouteWidget') {
          const filterString = [`plannedStartTime=lt=${endDate.toISOString()}`, `plannedEndTime=gt=${startDate.toISOString()}`].join('%26');
          queryTerms.push(`filter=${filterString}`);
        }
      }

      const baseUrl = this.get('socketBaseUrl');
      const queryString = queryTerms.join('&');
      return `${baseUrl}/?${queryString}`;
    },

    _log() {
      const clientId = this.get('clientId');

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      console.info(`socket[${clientId}]:`, ...args); // eslint-disable-line no-console
    },

    _warn() {
      const clientId = this.get('clientId');

      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      console.warn(`socket[${clientId}]:`, ...args); // eslint-disable-line no-console
    }

  });

  _exports.default = _default;
});