define("adept-iq/tests/factories/polyline", ["ember-data-factory-guy", "@mapbox/polyline"], function (_emberDataFactoryGuy, _polyline) {
  "use strict";

  const stopPolylines = [{
    polyline: _polyline.default.decode('aidwFl{fbMgETCu@Ao@EaBfAGDApG[x@En@CrAGzCQ`BKtAIdBI~@EpAGxCSjAGrBKvESnAIfBIrBMhBKdBGb@CpDSbAE\\Ad@Ed@CfEUbAGtAG|DUJAl@Ch@CJjEJtDLbEJ`EJzDJ~DH`EJzDFhC@v@J~DLzDHzDJvDJbEL~DHzDJ`ElMq@')
  }, {
    polyline: _polyline.default.decode('aetwFt}pbMDBzBvAbCdBjAwDTs@b@{Al@eB\\eAPo@FO@ALc@FWPe@n@mBfAqDh@_BL_@Tw@j@iBl@cBH]Rm@r@sBZcAX_AHUn@uBp@uBX?J?hB[hBY`Dg@pB]t@ObF{@LC`@GZGP]N[hBeCd@y@vBmCl@WhBe@bBA|@JzDZh@DXD~A\\p@XbI~DHH^R|EjCzCnA~B\\b@B`@@\\BdFZbEVn@DdAJxDn@rEvAr@Z~B~@^PPFh@TDBD@HBDDD@DBHBh@VPDzErBFBLDjDxAr@d@`BfBbApBHXZfAXfBJz@PdFDlAPbGB~@PvE@\\BT@TRhDNpCh@xIRrDBTdAlNBV@XBT@FFr@l@pIr@lKF`ABd@h@dF\\bBj@bBz@rBFLBFBFDBr@~AxEbLBFnAdCZn@BDb@z@`AbB^p@xFhKrA`CXj@tAhCDJP\\bAhBjAzBR\\z@`CNl@PpA`@rC^pCD\\BNDNDb@Lx@ZtBDxAA`@E`@MdA[jAEFU^GJEFm@t@MPq@v@GJEDk@b@cAh@kA^eCR{DTK?UAKAa@KgEiAYIgA]sAm@Be@Ly@R]JMr@QfABx@Nz@TPDp@PlAZPDbB\\x@Nh@HrANd@DrC?r@Ah@ITChB[FAJALCLCxEsAbSmFpT_G|Cy@`Bc@jBm@|F}CfD_CrQcNJGhAq@p@]vIaEj@Oz@QtBe@zAi@zBcAFEXMjAe@lAw@z@k@\\Yz@cATYVYv@u@RMFIl@i@PODCDGFEfCqBDEh@_@`DuB~@k@tBiAnAs@TOh@WHEtAu@xAu@lBgAFCz@c@FC|CyA`B{@p@_@hBcAtAu@FALKx@c@tC}AXOdB}@t@a@bAg@`Ai@f@UlDiAvAy@NO`@o@~@kBXi@`DoGn@oAFQT_@FSpB}DpB}DVg@v@uAfBsCzCwCdKyGFCHIdAu@fCqCtBsDR_@PYfCeFfAsBTe@lA}BhA_C`AkBn@mA~D_IlAoBLOlCmChA{@fAu@b@UpCmArDaB^OpHcDHEFA`@O@ANENCdAWp@MVEpC[vEi@tAOvGw@JAn@Kd@EdD_@PQ`@ExH{@v@IlBSbBSj@GlD_@t@IhBSzASp@IdD_@bAMdBSbBQl@GdGm@`AKbCWhAM|@M^EPAb@EdBUdAMXCr@In@IVEnAMlBUd@E|@KfAOhBQzAOZCp@K`@EzAOnAOTCz@IlCY`@GjCY|BWp@KpHw@vGs@v@KhC[~BU\\GrC[j@G~BW@JBj@fCW^ERCPAlAOD?')
  }, {
    polyline: _polyline.default.decode('}u`wFdoibMKaEK{DkMp@M_EKcEKwDI{DM{DK_EAw@GiCK{DIaEK_EK{DKaEMcEKuDKkEEuAEqBKwDKeEK_ECaBA[?GAKCSaCYuBW{@IkAK{AQ_BOsBQe@Ea@GoDu@sCs@gA[oBo@mA[}Bk@eAYSEcDaAiAq@_@MmA[GaAGkB?[@sB@i@BwC@aAFcCDuCDaFBmBDqB@s@HgH@yA@gA@aB?g@CoE?q@Ck@KsAOoB[wDOoBImAMsAMeBYkDQoBQ}B[eEWoC_@aA}@cBaDuFgBoBMSKu@CUCYAME]Jq@Zs@h@i@p@Gt@F`Af@p@f@Q}CAOAKAMWaD[wD[wDe@mBq@oBxCqBDCbCkB~@]rFuDmAaDCG')
  }, {
    polyline: _polyline.default.decode('{diwFdsibMNtCjAjStA|Un@pLb@xHXbFqC\\qCb@qCXsCXqC\\oCZuC\\cBRk@HuC\\mCZHxARjDBZDv@@P@ZVvEBd@B^DfAFh@@x@FrB@RZvDV`EV`ECjEInEGdEEjEIbEAb@E|CEfEEbEGbE?TAVEvBAZCfBAL@FAxA?PA~@bBO')
  }, {
    polyline: _polyline.default.decode('_~dwF`}fbMHXf@dAAfALbFnCMjCOrCQhH_@~COMcGGsBkFTQ@E?')
  }, {
    polyline: _polyline.default.decode('mu{vFxplbMOsCImBIiAIaB?GAG?IG}@S_EW_ES_EUaFAKrOcBnKkAtAQVC_@yDCSGe@QcBBMC]_@}BKSO_@eBsBg@m@kByBg@m@W_@}AkCiAiBYg@OWqBgDeAcBm@cAkAgBw@mAY]{BkCaBsAa@]cDoC_Au@qDyCUYOS@IAOUi@KM{@a@E?M?MBWIg@_@k@m@]c@a@o@y@mBW}@y@uDOm@UaAK]SgAi@mBs@}AQY_@]_EsA[UoAaBeDyDeAsAGECEEEEGqD_DoAi@}ADS?cAJoBTeAHmEDg@IMI_@[}@gAmAuA]_@QQa@g@oBkBcAcA_@_@cAaAgAcAuBsBcAgA_B{AoAkAaAaA}BuA_@SQM}A}@cFaAu@E]AIAWGs@KiCW_AEiAGgAOqCW{D]kAKkFo@wCYoAIaCYuBW{@IkAK{AQ_BOsBQe@Ea@GoDu@sCs@gA[oBo@mA[}Bk@eAYSEcDaAqBi@cAYQGo@UEAERKLgCtCyAbBi@l@{@`AkC|CeAlAw@z@{BjCaAhAm@p@oAvAIJcDvDcCnC]b@s@x@qDfEiCjCs@x@_@r@?N|@vCBH')
  }, {
    polyline: _polyline.default.decode('}ciwFhckaMMHwBtAwBtAyBvAkD|B}BzAsBfAwBnAaBfARbBr@zB')
  }, {
    polyline: _polyline.default.decode('ucnwFvrvbMm@UnA}DnBcFnAsD\\q@pDaFfBcDvAeCvBwCo@aA}AgCuB}D_@_@a@c@{@y@_@e@{BuCwB_DMKIGEEOMGE@KD[Fs@bAwAX[NMb@MVELAFAPG^MPOXa@LY@GH_@?MAUASQs@aAqCMq@{@}FWyCa@aFIs@McAE{@o@iJAIASEYCWgA{NSwDg@wIQqCS_E?IAQEWMwECaAQ_GCy@M}EKsAAGUyAg@mBoAeCyA_BgAs@s@Y}BaAKE}EsBy@]GCGCECGAEEGCECcAa@g@S{Ak@eBs@aCu@eF_A}AO_@CsLq@YAu@K_AI}Bk@cIcEa@U}EkCkDwAUGuAWMAu@GqBO_AIUAmA]q@EcBP]LsBnAoBnCuBzBUVABATE^Ib@mFbAy@L]DgEr@o@LuBRa@Am@IEAa@C@JAXK`@q@tBo@tBITY~@[bAs@rBSl@I\\m@bBk@hBUv@M^i@~AgApDo@lBQd@GVMb@A@GNQn@]dAm@dBcCyAyByAkAy@q@c@{BuAi@hBINsAfEzBvAvBrA')
  }, {
    polyline: _polyline.default.decode('q~_wFt`ibMg@BMDETFzADz@BDL?fESIyDMaEqMp@kCLgCJyBLcAFmMr@kERiDPiAHsBHgCLuBLsCN_@BaAFeADCc@Cq@A}@Cm@MBsI`@}Jd@EwDEyBCaAKyDG_CC_A_@@wG\\_GVeCPc@By@DoBKUgIK}E{CNaDNmCNoAD}@FkCPuCNmBFq@OoB_@qDMwAKyAEyAGwAKwACyAIcDOuCMc@CS?YCQ?aCMeDMiBKiAGsDKqDOqDMqDU{CM{CMoCOK?E?E?KAyBIIAqAc@oCmCs@m@iA]eANoCXuC\\qCZqCZqC\\qCZsC^t@zMJlBz@fOFj@~@vPn@|K')
  }, {
    polyline: _polyline.default.decode('k_mwFjuvbMF}HxA@T?p@~DP|A@FKrGwAEi@G[E}@Ks@IIBADQRENCPUrA}FoBYUUc@CIk@}@k@c@[OWOkC_BiEaCg@YQKo@a@w@`CUp@a@O')
  }, {
    polyline: _polyline.default.decode('_v{vF`bnbMxDe@Ci@AMCw@WyEWuEU{DQwDWyDAUEeBMyAQwDEe@')
  }, {
    polyline: _polyline.default.decode('ocjwFpbpbM~Ec@jAyDbAiDdAsDZ_Az@uCrAqEpAkEJ]Lg@^iAd@_BTDf@?LE`DWbFs@NKx@BVDBOTaBZcCBY^cDPkAVoBL_Ab@sDf@uDh@wEL}@^sCBSJaA^oCJy@LeAt@cGz@}GHw@RwAd@wD~AoM^qCp@aFHi@F_@^{CL{@JcCXqMH{EBeANeG@OFsDTeJBkBBsAHgDFkC@w@H}CFsCJsFD{A@e@?O@k@FkB@s@BkAB{@BeARiKF}CBeAT}IFcC@s@PkJ@u@DuAByABgBRqH@s@DsBDuAHaE@_@@o@DsBD_BBmAHiDHkDDqDBI?o@@e@B_BHyD@}@@]ByAFmB@gABk@FsCF_EJgD@w@FgCFyDH{B@s@F_E?[N{JLcGVcG@QJsADe@Bm@V{DJcE?_E?aAC{BAs@A{B?i@CaAGaBOqEUsDSmDAKWsDYyD]qDM_AUqBa@qDc@qDg@kDi@kDg@_DMs@]mBk@}CQ{@[_BSiA[aBWsAY{AEQScAY_BYwAY{AMo@Gc@YwAe@yBMy@WsAQgAI]WoAU{AEm@Ai@@w@ToBh@wBFKnAuBlCmBr@c@ZUPORYp@gARg@VkAf@cCBMP}@^oBBQLw@H[F_@BSDSDKLi@H]v@yCJYF]ZiALg@DQFSJc@Tw@XiALg@J]VcAH_@\\mAH]^uAVeAT{@n@gCLi@BODSH]XkAd@eCn@yCz@{D|@sEn@_DLm@ZcBD]pAoInDgPl@aDbAuE`AyD^wAd@wB`@oBZ}AH]XaBDOHe@DOh@eC\\aB`@_Ej@uFj@qCTeANu@Jg@fAgDJW|BoFzAgERg@FSFQH[f@yAd@cD~AoLdB_M@I@K@KRcA@MJMl@_Dh@eCF[x@cDAI\\uDC{BAUEo@[cBCQGYYqBUgCEsBNqIHcD?{@FsHMqGE{@Y{AY_CsAkH_BuGUeAkAeHYcE?{JHeHBkBBsAp@oJ?QVcIb@qMDs@@e@RiK~@aPG{Go@eP?oBFuFPmFCu@s@KQCKAYA[CGAOAUA_AIYEm@Go@GaBQkAKUEeC[mC_@YGuASyDm@OAAAmDe@a@Ca@GcAMMCg@IM@KJ}@hAaDlDmCfBq@ZuB|@aC`AaCx@YHi@PUFi@NKB_@He@Ns@PiCt@oA\\qCj@iCd@kCf@sCb@?UCUoBaGwB{GiBlA')
  }, {
    polyline: _polyline.default.decode('eajwFp`laMNb@n@~Bh@|An@jBbAfDfA`DdAdDfAbD^pA`@tAj@dB\\dAf@xBNv@f@lCHd@p@lDn@nDDVj@vCa@PsC|AgHjDcAd@mFbCgClAiFjCcCnAOJiCnAc@R{ApAgCnA}Ax@e@`@g@^GB[LqJxEsDlBSLaClAYLE@GDE@GDGBEBMDOHGD]NqIpDSJeA`@_@LyHlC[JsFnB[LUHoDpAeDxA]N]PSHmB`Ac@PiCvAiAj@mB`AGDeBx@]P_@Pq@b@SJIFkAz@YZyB~BkDrEOTk@j@y@vAcCpBkAz@{AbAeD`CcCjCSVUVMTW\\EHa@n@g@z@O^y@xCAFCHGRE\\{@bH]lB_@jAk@bBcBpCyF`GgC|B_CrBmHrFSNu@d@oHlEk@V}ClAkBj@aA\\eC|@iEpBwAv@iGlE[VmCfCsEbFeEdEg@`@aAr@iF|CqAj@}C`AcEv@cBG}CF{BBuI|@YDG@G@UDMB]D_@FwAVa@BkAJECWKOSQg@C]Dq@JSV_@RM\\Gb@BTHv@bAT\\d@lAL^DHL^R`@HXh@rAb@jAvApDzEzLNh@|@tD^lBR|@DTF`@Jn@PjA|@hJpAbJ\\lB`BfIH^BHF`@fA~Ej@vClErTNp@BLHb@j@jCxCzMH^Lf@Jb@H^HXF\\H\\HVFX`B~HNn@Lv@BJNn@XrA\\hBb@rB`@tBPx@Jj@t@nD@HBLDJ~@zE|AfGRn@\\dAJ\\fAlDj@fCb@dDXbG`@`LXlFD\\j@hDr@vCl@dBzBzEpCpFXr@Z|@\\lAXfAR`Ah@zERnFAHI|DAb@QtD[tHQjEUlFShFQ~DWvFALIzAg@pDmAdFqBbFQZOZoI`PO^mAnDk@dCIVsCpQs@dEQz@sAlHMt@_BlFCFGNWh@W^aD|EQRINW\\y@fAKPKJOVoAdBOTGHCFEDCDEJEBCF_B|CKVkAzC{AxGSx@eAxIc@`EcBxNAH[|DEv@KlAEf@OjBEh@M`Ae@hEOfACd@QbCGr@Ef@AV]|DIl@]dCy@vDUx@_ArCkAhDi@dB_AvC_AvCqAzEEHMr@oAnJO~AcAhIGn@Ep@ANAH?F?HAzCFxAHn@TtC@ZB|AAz@EnACxAA|@?`@?H?b@At@GlB?Po@rHCZETEf@YfCGj@]|Cy@tEADSn@g@~AO\\EFENGLm@jAyWdb@[h@_B`DCNENABAJGNETCVQnCN~BLl@`AnBFNxB~Av@h@|@~@X^DJTp@P|@Ff@?`@?ZA`@Kf@Sr@c@tA@^]~@gAnDERAn@Hj@FNVXv@j@bAt@nBsGt@b@pAx@v@cC`AaDlAsDdAeDh@cBHDJFtBv@lDl@~BTbA?dALxBXfCt@nCzAvAbArAz@PJJFj@^FDNHjBh@r@Hn@AhBOtAUpEq@hASnEu@dCa@vAa@TOdAo@fEeFlAeAl@WhBe@bBA|@JzDZh@DXD~A\\p@XbI~DHH^R|EjCzCnA~B\\b@B`@@\\BdFZbEVn@DdAJxDn@rEvAr@Z~B~@^PPFh@TDBD@HBDDD@DBHBh@VPDzErBFBLDjDxAr@d@`BfBbApBHXZfAXfBJz@PdFDlAPbGB~@PvE@\\BT@TRhDNpCh@xIRrDBTdAlNBV@XBT@FFr@l@pIr@lKF`ABd@h@dF\\bBj@bBz@rBFLBFBFDBr@~AxEbLBFnAdCZn@BDb@z@`AbB^p@xFhKrA`CXj@tAhCDJP\\bAhBjAzBR\\z@`CNl@E|@ZhB@PD^k@J}Dv@eBPU?yAAs@C{ACo@C@L@HEbEArCxAM`BM?I')
  }, {
    polyline: _polyline.default.decode('ocjwFpbpbM??')
  }];

  _emberDataFactoryGuy.default.define('polyline', {
    sequences: {
      polylines: num => stopPolylines[num % stopPolylines.length].polyline
    },
    default: {
      coords: _emberDataFactoryGuy.default.generate('polylines')
    }
  });
});