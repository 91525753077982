define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/vehicle-type/capacity-modal/component", ["exports", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/vehicle-type/capacity-modal/validation", "ember-concurrency"], function (_exports, _emberChangeset, _emberChangesetValidations, _validation, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DROPDOWN_MODELS = [{
    optionName: 'vehicleCapacityTypeOptions',
    modelName: 'vehicle-capacity-type'
  }];

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    tableRef: null,
    modalAPI: null,
    group: null,
    newCapacity: null,
    newConfigurationAction: null,
    disableConfigActions: false,
    disableAdditionalBtn: false,
    // capacityTypeSet: null,
    capacities: Ember.computed.alias('modalAPI.selectedCapacityGroup.capacities'),
    vehicleTypeChangeSet: Ember.computed.readOnly('modalAPI.vehicleTypeChangeSet'),
    capacityGroupSet: Ember.computed.readOnly('modalAPI.capacityGroupSet'),
    title: Ember.computed.readOnly('modalAPI.title'),
    hasOverlay: Ember.computed.readOnly('modalAPI.hasOverlay'),
    // covers interface so user must confirm or cancel
    selectedCapacityConfig: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedCapacityRow: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    disableForm: true,
    disableCount: true,
    capacityConfigChangeSet: Ember.computed('selectedCapacityConfig', function () {
      const selectedRecord = this.get('selectedCapacityConfig');
      this.selectedConfigRowRecord(selectedRecord);
      return this.setDefaultProperties(selectedRecord);
    }),
    capacityTypeSet: Ember.computed('capacities.[]', function () {
      const capacityRows = this.get('capacities') || [];
      return capacityRows.reduce((acu, capacity) => {
        acu[capacity.get('vehicleCapacityType.id')] = true;
        return acu;
      }, {});
    }),
    filteredVehicleCapacityTypeOptions: Ember.computed('capacityTypeSet', function () {
      const capacityTypeSet = this.get('capacityTypeSet');
      const vehicleCapacityTypeOptions = this.get('vehicleCapacityTypeOptions');
      const filteredCapacityTypes = vehicleCapacityTypeOptions.filter(capacity => !capacityTypeSet[capacity.get('id')]);
      return filteredCapacityTypes;
    }),

    init() {
      this._super(...arguments);

      const newConfigurationAction = {
        id: 'newConfiguration',
        name: 'New Configuration',
        action: () => {
          this.addNewConfiguration();
        }
      };
      this.set('additionalTableActions', [newConfigurationAction]);
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('refreshTask').perform();
      this.setupDropDownValues();
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      let capacityTypeRecord = this.get('pendingRecord');

      if (record) {
        capacityTypeRecord = record;
        this.set('disableForm', true);
        this.set('disableCount', true);
      }

      if (Ember.isEmpty(record)) {
        capacityTypeRecord = store.createRecord('vehicle-capacity-config');
        this.set('pendingRecord', capacityTypeRecord);
      }
      /*run.next(() => {
        this.set('newCapacity', false);
      }); */


      return new _emberChangeset.default(capacityTypeRecord, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
    },

    setupDropDownValues() {
      const store = this.get('store');
      const dropDownModels = DROPDOWN_MODELS;
      dropDownModels.forEach(dropdown => {
        const records = store.peekAll(dropdown.modelName);
        this.set(dropdown.optionName, records);
      });
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const tableRef = this.get('tableRef');
      if (tableRef) yield Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),

    selectedConfigRowRecord(record) {
      const selectedRecord = record;

      if (Ember.isEmpty(selectedRecord)) {
        this.set('disableConfigActions', true);
      } else {
        this.set('disableConfigActions', false);
        this.set('disableAdditionalBtn', false);
      }
    },

    reset() {
      const modalAPI = this.get('modalAPI');
      modalAPI.setProperties({
        title: null,
        hasOverlay: false,
        capacities: [],
        vehicleTypeChangeSet: null,
        capacityGroupSet: null,
        group: null,
        selectedCapacityConfig: null,
        selectedCapacityRow: null
      });
    },

    addNewConfiguration() {
      const selectedCapacityRow = this.get('selectedCapacityRow');
      if (selectedCapacityRow) selectedCapacityRow.set('selected', false);
      this.set('newCapacity', true);
      this.set('disableForm', false);
      this.set('disableConfigActions', true);
      this.set('tableRef.config.selectFirstRow', false);
    },

    deleteConfiguration() {
      const capacityConfigChangeSet = this.get('capacityConfigChangeSet');
      const vehicleTypeChangeSet = this.get('vehicleTypeChangeSet');
      const capacities = this.get('capacities');
      const vehicleCapacityConfigs = vehicleTypeChangeSet.get('vehicleCapacityConfigs') || [];

      if (capacityConfigChangeSet) {
        vehicleCapacityConfigs.removeObject(capacityConfigChangeSet.data);
        capacities.removeObject(capacityConfigChangeSet.data);
        capacityConfigChangeSet.data.destroyRecord();
      }

      vehicleTypeChangeSet.set('vehicleCapacityConfigs', vehicleCapacityConfigs);
      this.refreshTask.perform();
      this.set('disableForm', true);
      this.set('disableCount', true);
      this.set('tableRef.config.selectFirstRow', true);
    },

    editConfiguration() {
      const selectedCapacityRow = this.get('selectedCapacityRow');
      const capacityConfigChangeSet = this.get('capacityConfigChangeSet');
      if (Ember.isEmpty(selectedCapacityRow)) return;

      if (!capacityConfigChangeSet.get('count') || capacityConfigChangeSet.get('count') === '0') {
        this.set('disableCount', true);
      } else {
        this.set('disableCount', false);
      }

      this.set('disableForm', false);
      this.set('newCapacity', false);
      this.set('disableConfigActions', true);
      this.set('disableAdditionalBtn', true);
    },

    async saveConfiguration() {
      const vehicleTypeChangeSet = this.get('vehicleTypeChangeSet');
      const capacityConfigChangeSet = this.get('capacityConfigChangeSet');
      const capacities = this.get('capacities');
      const group = this.get('group');
      const newCapacity = this.get('newCapacity');
      capacityConfigChangeSet.set('group', group);
      const isValid = await this.get('validateConfiguration').perform();

      if (isValid) {
        capacityConfigChangeSet.execute();
        const vehicleCapacityConfigs = vehicleTypeChangeSet.get('vehicleCapacityConfigs') || [];

        if (newCapacity) {
          vehicleCapacityConfigs.pushObject(capacityConfigChangeSet.data);
          capacities.pushObject(capacityConfigChangeSet.data);
          this.set('pendingRecord', null);
        }

        vehicleTypeChangeSet.set('vehicleCapacityConfigs', vehicleCapacityConfigs);
        this.refreshTask.perform();
        this.setProperties({
          'disableForm': true,
          'disableCount': true,
          'disableConfigActions': false,
          'disableAdditionalBtn': false,
          'tableRef.config.selectFirstRow': true
        });
      }
    },

    validateConfiguration: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const capacityConfigChangeSet = this.get('capacityConfigChangeSet');
      yield capacityConfigChangeSet.validate();
      const group = this.get('modalAPI.group');
      const capacityGroupSet = this.get('capacityGroupSet');

      if (capacityGroupSet.has(group)) {
        capacityConfigChangeSet.addError('group', {
          value: group,
          validation: 'Group already exists.'
        });
      }

      if (!group) {
        capacityConfigChangeSet.addError('group', {
          value: group,
          validation: 'Group is blank.'
        });
      }

      const errors = capacityConfigChangeSet.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),

    undoCapacities() {
      const capacities = this.get('capacities') || [];
      const vehicleTypeChangeSet = this.get('vehicleTypeChangeSet');
      const vehicleCapacityConfigs = vehicleTypeChangeSet.get('vehicleCapacityConfigs') || [];
      capacities.forEach(capacity => {
        if (capacity.get('isNew')) {
          vehicleCapacityConfigs.removeObject(capacity);
        } else {
          capacity.rollbackAttributes();
        }
      });
      vehicleTypeChangeSet.set('vehicleCapacityConfigs', vehicleCapacityConfigs);
    },

    closingModalSaveFunction() {
      const onCloseButtonClick = this.get('modalAPI.onCloseButtonClick');
      const capacities = this.get('capacities');
      const capacityConfigChangeSet = this.get('capacityConfigChangeSet');
      const group = this.get('modalAPI.group');
      const capacityGroupSet = this.get('capacityGroupSet');
      const tooltip = this.get('tooltip');
      let errors = [];

      if (Ember.isEmpty(group) || capacityGroupSet.has(group)) {
        const errorMessage = Ember.isEmpty(group) ? 'Group is required.' : 'Group already exists.';
        capacityConfigChangeSet.addError('group', {
          value: group,
          validation: errorMessage
        });
        errors = capacityConfigChangeSet.errors;

        if (errors) {
          return tooltip.pushConfirmation({
            title: 'Manage Vehicle',
            tip: '',
            errors,
            primaryActionText: 'OK',
            primaryAction: () => {
              this.set('showErrorMsg', true);
              return tooltip.reset();
            }
          });
        }
      }

      capacities.forEach(capacity => {
        capacity.set('group', group);
      });
      if (onCloseButtonClick) onCloseButtonClick();
      this.reset();
    },

    setVehicleProperties(vehicleCapacityType) {
      const capacityConfigChangeSet = this.get('capacityConfigChangeSet');
      const vehicleCapacityTypeDisplayName = vehicleCapacityType.get('displayName');
      const vehicleCapacityTypeDisplayID = vehicleCapacityType.get('id');
      const vehicleCapacityTypeRecord = this.store.peekRecord('vehicle-capacity-type', vehicleCapacityTypeDisplayID);
      const filteredTravelNeedType = this.store.peekAll('cs-config-item').filter(configItem => configItem.category === 'config-System_Configuration-travel_need_types' && configItem.displayName === vehicleCapacityTypeDisplayName);
      let vehicleCapacityTypeCount = '0';

      if (filteredTravelNeedType.length > 0) {
        vehicleCapacityTypeCount = filteredTravelNeedType.firstObject.value.vehicleCapacityCount === '' ? '0' : filteredTravelNeedType.firstObject.value.vehicleCapacityCount;
      }

      capacityConfigChangeSet.set('count', vehicleCapacityTypeCount);
      capacityConfigChangeSet.set('vehicleCapacityType', vehicleCapacityTypeRecord);

      if (capacityConfigChangeSet.get('count') !== '0') {
        this.set('disableCount', false);
      } else {
        this.set('disableCount', true);
      }
    },

    actions: {
      onCloseModal() {
        const closingModalSaveFunction = this.get('closingModalSaveFunction').bind(this);
        const tooltip = this.get('tooltip');
        const undoCapacities = this.get('undoCapacities').bind(this);
        const isNewGroup = this.get('modalAPI.isNewGroup');

        if (isNewGroup) {
          return tooltip.pushConfirmation({
            title: 'Manage Lookup',
            tip: 'There are unsaved changes',
            warning: false,
            thirdActionText: 'Cancel',
            thirdAction: () => {
              return tooltip.reset();
            },
            secondaryActionText: 'Do Not Save',
            secondaryAction: () => {
              undoCapacities();
              this.reset();
              return tooltip.reset();
            },
            primaryActionText: 'Save',
            primaryAction: async () => {
              tooltip.reset();
              return closingModalSaveFunction();
            }
          });
        }

        return closingModalSaveFunction();
      },

      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onClickOverlayModal() {
        const hasOverlay = this.get('hasOverlay');

        if (hasOverlay) {
          return false;
        }

        this.reset();
      },

      onVehicleCapacityTypeChange(record) {
        this.setVehicleProperties(record);
      }

    }
  });

  _exports.default = _default;
});