define("adept-iq/pods/components/generic-widgets/column-widget/classes/checkbox-row", ["exports", "ember-light-table/classes/Row"], function (_exports, _Row) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CheckboxRow extends _Row.default {}

  _exports.default = CheckboxRow;
  CheckboxRow.reopen({
    activeContext: null,
    isLastChecked: false,
    isSelected: false,
    isAlertCheckBox: false,
    record: Ember.computed.alias('content'),
    id: Ember.computed.alias('content.id'),
    isDisabled: Ember.computed.readOnly('record.isDisabled'),
    isChecked: Ember.computed('activeContext.checkedItems.[]', {
      get() {
        const record = this.get('record');
        return this.get('activeContext').isRecordChecked(record);
      },

      set(key, value) {
        const record = this.get('record');
        this.get('activeContext').setRecordsChecked([record], value);
        return value;
      }

    })
  });
});