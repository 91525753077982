define("adept-iq/serializers/travel-need-type", ["exports", "adept-iq/serializers/cs-config-model"], function (_exports, _csConfigModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function updateRelationship(relationships, relationshipName, id, type, store) {
    if (id) {
      relationships[relationshipName] = {
        data: {
          id,
          type
        }
      };
      store.push({
        data: {
          id,
          type
        }
      });
    }
  }

  var _default = _csConfigModel.default.extend({
    modelName: 'travel-need-type',

    normalizeArrayResponse(store
    /*, primaryModelClass, payload, id, requestType */
    ) {
      const normalized = this._super(...arguments);

      if (Ember.isPresent(normalized.data)) {
        normalized.data.forEach(datum => {
          datum.attributes = datum.attributes || {};
          datum.relationships = datum.relationships || {};
          const {
            vehicleCapacityType
          } = datum.attributes;

          if (Ember.isPresent(vehicleCapacityType)) {
            // Avoid ember store send out query requests.
            updateRelationship(datum.relationships, 'vehicleCapacityType', vehicleCapacityType, 'vehicle-capacity-type', store);
          }
        });
      }

      return normalized;
    }

  });

  _exports.default = _default;
});