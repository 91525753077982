define("adept-iq/pods/components/timeline-picker/time-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HoCYBhqi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[1,[21,\"startDateAbbrev\"],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"time-bar-handle-left\"],[11,\"draggable\",\"true\"],[11,\"id\",\"left-handle\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"time-bar-handle-right\"],[11,\"draggable\",\"true\"],[11,\"id\",\"right-handle\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"time-bar-now-marker\"],[11,\"draggable\",\"true\"],[11,\"id\",\"now-marker\"],[9],[10],[0,\"\\n\\n\"],[7,\"table\"],[9],[0,\"\\n  \"],[7,\"tr\"],[11,\"id\",\"time-scale-row\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"table\"],[9],[0,\"\\n  \"],[7,\"tr\"],[11,\"id\",\"increment-row\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"time-bar-operating-box-background\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"time-bar-operating-box\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/timeline-picker/time-bar/template.hbs"
    }
  });

  _exports.default = _default;
});