define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/regionWithRadius", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/map", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel"], function (_exports, _react, _map, _panel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const RegionWithRadius = () => {
    const {
      map
    } = _react.default.useContext(_map.MapContext);

    const {
      radiusMode,
      radiusInMiles,
      radiusInFeet,
      location
    } = (0, _panel.usePanel)();

    _react.default.useEffect(() => {
      if (!map || !location || !location.position) return;
      const radius = radiusMode === _panel.radiusModes.miles ? radiusInMiles : radiusInFeet;
      const radiusInMeter = Math.max(1, radius) * (radiusMode === _panel.radiusModes.miles ? 1609.344 : 0.3048);
      const circle = window.L.circle(location.position, {
        radius: radiusInMeter
      }).addTo(map.native);
      return () => map.native.removeLayer(circle);
    }, [location, radiusMode, radiusInMiles, radiusInFeet]);

    return _react.default.createElement(_react.default.Fragment, null);
  };

  var _default = RegionWithRadius;
  _exports.default = _default;
});