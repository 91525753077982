define("adept-iq/mixins/async-initiation-operation", ["exports", "ember-concurrency", "adept-iq/config/api-urls", "lodash"], function (_exports, _emberConcurrency, _apiUrls, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const initiationHost = _apiUrls.API.initiationService.host;
  const TIMEOUT_DELAY = 3000; // 3 seconds tries

  var _default = Ember.Mixin.create({
    timeout: TIMEOUT_DELAY,

    init() {
      this._super(...arguments);

      const ajax = this.get('ajax');
      const session = this.get('session');
      (true && !(Ember.isPresent(ajax)) && Ember.assert('mixin requires components, routes, controllers to provide a ajax service', Ember.isPresent(ajax)));
      (true && !(Ember.isPresent(session)) && Ember.assert('mixin requires components, routes, controllers to provide a session service', Ember.isPresent(session)));
    },

    /**
     * @param schedule,
     * @param notificationType -> 'advanceTripReminders'
     * @param options -> { outputResult }
     */
    createInitiationAsyncOperation: (0, _emberConcurrency.task)(function* (scheduleDate, riderId, template, notificationType, tags, options) {
      const session = this.get('session');
      const op = {
        'data': {
          'type': 'initiationAsyncOperation',
          'attributes': {
            'operationType': 'manualInitiation',
            'payload': {
              'data': {
                'type': 'manualInitiation',
                'attributes': {
                  'notificationType': notificationType
                }
              }
            }
          }
        }
      };

      if (_lodash.default.size(tags) > 0) {
        op.data.attributes.payload.data.attributes.tags = tags;
      }

      if (notificationType === 'advanceTripReminders' || notificationType === 'advanceTripRemindersGroupedByTextToSpeech') {
        op.data.attributes.payload.data.attributes.tripDate = scheduleDate;
      } else if (notificationType === 'smsToClient') {
        op.data.attributes.payload.data.attributes.riderId = riderId;
        op.data.attributes.payload.data.attributes.template = template;
      }

      const {
        outputResult
      } = options || {};
      const job = yield this.get('ajax').post(initiationHost + '/initiation-async-operation', {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: op
      });
      if (!job) return false;
      return yield this.get('asyncOperationState').perform(job, outputResult);
    }).enqueue(),

    /**
     * @param outputResult => boolean
     */
    asyncOperationState: (0, _emberConcurrency.task)(function* (job, outputResult) {
      const session = this.get('session');
      const workspace = this.get('workspace');
      if (job.data.attributes.progress && workspace) workspace.set('progressValue', job.data.attributes.progress);
      if (job.data.attributes.operationType && workspace) workspace.set('operationType', job.data.attributes.operationType);

      while (job.data.attributes.state !== 'success' && job.data.attributes.state !== 'failure') {
        const delay = this.get('timeout');
        yield (0, _emberConcurrency.timeout)(delay);

        try {
          // eslint-disable-next-line no-param-reassign
          job = yield this.get('ajax').request(initiationHost + '/initiation-async-operation/' + job.data.id, {
            method: 'GET',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          });
          if (job.data.attributes.progress && workspace) workspace.set('progressValue', job.data.attributes.progress);
          if (job.data.attributes.operationType && workspace) workspace.set('operationType', job.data.attributes.operationType);
        } catch (e) {
          throw e;
        }
      }

      const isJobSuccess = job.data.attributes.state === 'success';
      const jsonData = job.data;

      if (outputResult) {
        return { ...job.data.attributes,
          jsonData,
          isJobSuccess
        };
      }

      return isJobSuccess;
    }).drop()
  });

  _exports.default = _default;
});