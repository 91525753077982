define("adept-iq/serializers/playback-route", ["exports", "adept-iq/serializers/-playbackService"], function (_exports, _playbackService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _playbackService.default.extend({
    normalize(modelClass, resourceHash) {
      resourceHash.id = resourceHash.attributes.routeid ? resourceHash.attributes.routeid : resourceHash.id;

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeQueryResponse(store, clazz, payload) {
      // workaround for this API not returns payload type
      if (!Ember.isEmpty(payload.data)) {
        const resultedPayload = [];

        const _data = [].concat(payload.data);

        payload.data = _data;
        payload.data.forEach(item => {
          const data = item.attributes;

          if (!Ember.isEmpty(data)) {
            item.attributes = { ...data,
              routeId: data.routeid,
              routeName: data.routename
            };
            resultedPayload.push(item);
          }
        });
        payload.data = resultedPayload;
      }

      return this._super(store, clazz, payload);
    },

    modelNameFromPayloadKey(key) {
      if (key === 'route') {
        return 'playback-route';
      }

      return this._super(key);
    }

  });

  _exports.default = _default;
});