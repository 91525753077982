define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/vehicle-type/capacity-group-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    title: 'Capacity Configuration Groups',
    defaultSortId: 'details',
    defaultSortAsc: true,
    selectFirstConfigRow: true,
    queryModelData: true,
    tooltipTitle: 'Manage Lookup',
    columns: [{
      id: 'group',
      index: 0,
      type: 'number',
      label: 'Group',
      valuePath: 'group',
      defaultWidth: 200,
      searchable: true
    }, {
      id: 'details',
      index: 1,
      type: 'enum',
      label: 'Configuration',
      valuePath: 'details',
      mapValuePath: 'configurationSortKey',
      defaultWidth: 200,
      searchable: true,
      cellDesc: 'Configurations'
    }]
  };
  _exports.default = _default;
});