define("adept-iq/services/time-span", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    workspace: Ember.inject.service(),
    minusValue: 0,
    plusValue: 0,
    intervalTimer: null,
    timeInterval: 900000,
    // 15 minutes
    isTimeSpanRunning: Ember.computed.alias('intervalTimer'),

    startInterval() {
      // Run the update now.
      this._updateWorkspace(); // Schedule the update to run every X minutes thereafter.


      this.set('intervalTimer', setInterval(() => {
        this._updateWorkspace();
      }, this.get('timeInterval')));
    },

    stopInterval() {
      clearInterval(this.get('intervalTimer'));
      this.set('intervalTimer', null);
    },

    /* Input unit is hours. */
    setSpan(minusValue, plusValue) {
      this.set('minusValue', minusValue * 3600000);
      this.set('plusValue', plusValue * 3600000);

      this._resetSpan();
    },

    /* Input unit is milliseconds. */
    setSpanMillis(minusValue, plusValue) {
      this.set('minusValue', minusValue);
      this.set('plusValue', plusValue);

      this._resetSpan();
    },

    _updateWorkspace() {
      const now = Date.now();
      const startDate = new Date(now - this.get('minusValue'));
      const endDate = new Date(now + this.get('plusValue'));
      const changeSet = {};
      changeSet.startDate = startDate;
      changeSet.endDate = endDate;
      Ember.run.scheduleOnce('afterRender', () => {
        this.get('workspace').setProperties(changeSet);
      });
    },

    _resetSpan() {
      this.stopInterval();
      this.startInterval();
    }

  });

  _exports.default = _default;
});