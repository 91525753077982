define("adept-iq/classes/rules/rule-result-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RESULT_TYPE_YES = _exports.RESULT_TYPE_NO = _exports.RESULT_TYPE_FIXED_ROUTE = _exports.RESULT_TYPE_FEEDER = _exports.RESULT_TYPE_ERROR = void 0;
  const RESULT_TYPE_YES = 'yes';
  _exports.RESULT_TYPE_YES = RESULT_TYPE_YES;
  const RESULT_TYPE_NO = 'no';
  _exports.RESULT_TYPE_NO = RESULT_TYPE_NO;
  const RESULT_TYPE_ERROR = 'error';
  _exports.RESULT_TYPE_ERROR = RESULT_TYPE_ERROR;
  const RESULT_TYPE_FEEDER = 'feeder';
  _exports.RESULT_TYPE_FEEDER = RESULT_TYPE_FEEDER;
  const RESULT_TYPE_FIXED_ROUTE = 'fixedroute';
  _exports.RESULT_TYPE_FIXED_ROUTE = RESULT_TYPE_FIXED_ROUTE;
});