define("adept-iq/tests/factories/cs-config-category", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const categories = [{
    id: 'config-Canned_Messages',
    name: 'Canned_Messages',
    listAction: 'full-json',
    displayName: 'Canned Messages',
    path: 'config-Canned_Messages',
    canDisplay: true,
    canAdd: false,
    canDelete: false,
    depth: 1
  }, {
    id: 'config-Canned_Messages-to_dispatch',
    name: 'to_dispatch',
    listAction: 'full-json',
    displayName: 'To Dispatch',
    path: 'config-Canned_Messages-to_dispatch',
    canDisplay: true,
    canAdd: true,
    canDelete: true,
    minimumItem: 1,
    depth: 1
  }, {
    id: 'config-Canned_Messages-to_driver',
    name: 'to_driver',
    listAction: 'full-json',
    displayName: 'To Driver',
    path: 'config-Canned_Messages-to_driver',
    canDisplay: true,
    canAdd: true,
    canDelete: true,
    minimumItem: 1,
    depth: 1
  }, {
    id: 'config-Driver_App_Look',
    name: 'Driver_App_Look',
    listAction: 'full-json',
    displayName: 'Driver App Look',
    path: 'config-Driver_App_Look',
    canDisplay: true,
    canAdd: false,
    canDelete: false,
    depth: 1
  }, {
    id: 'config-Passwords',
    name: 'Passwords',
    listAction: 'full-json',
    displayName: 'Passwords',
    path: 'config-Passwords',
    canDisplay: false,
    canAdd: false,
    canDelete: false,
    depth: 1
  }];

  _emberDataFactoryGuy.default.define('cs-config-category', {
    sequences: {
      ids: num => categories[num % categories.length].id,
      names: num => categories[num % categories.length].name,
      listActions: num => categories[num % categories.length].listAction,
      displayNames: num => categories[num % categories.length].displayName,
      paths: num => categories[num % categories.length].path,
      canDisplays: num => categories[num % categories.length].canDisplay,
      canAdds: num => categories[num % categories.length].canAdd,
      canDeletes: num => categories[num % categories.length].canDelete,
      depths: num => categories[num % categories.length].depth
    },
    default: {
      name: _emberDataFactoryGuy.default.generate('names'),
      id: _emberDataFactoryGuy.default.generate('ids'),
      listActions: _emberDataFactoryGuy.default.generate('listActions'),
      displayName: _emberDataFactoryGuy.default.generate('displayNames'),
      path: _emberDataFactoryGuy.default.generate('paths'),
      canDisplay: _emberDataFactoryGuy.default.generate('canDisplays'),
      canAdd: _emberDataFactoryGuy.default.generate('canAdds'),
      canDelete: _emberDataFactoryGuy.default.generate('canDeletes'),
      depth: 1
    },
    traits: {
      parent: {
        id: 'config',
        name: 'config',
        listAction: 'full-json',
        displayName: 'Config',
        path: 'config',
        depth: 0,
        canDisplay: true,
        canAdd: false,
        canDelete: false
      }
    }
  });
});