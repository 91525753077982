define("adept-iq/pods/components/iq-widgets/map-widget/config/polyline", ["exports", "adept-iq/pods/components/iq-widgets/map-widget/config/colors"], function (_exports, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // don't use opacity in these presets; it may cause polyline opacity to be
  // reset if the active style changes
  const {
    bemap
  } = window; //example of bemap linestyle for polylines
  // new bemap.LineStyle({
  //   width: 3,
  //   color: new bemap.Color(2, 208, 255, 0.8)
  // })

  var _default = {
    // these generic styles should go away
    solidPlanned: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_COLOR
    }),
    solidNavigation: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PENDING_COLOR
    }),
    solidPerformed: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PERFORMED_COLOR
    }),
    solid: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_COLOR
    }),
    solidLate: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_LATE_COLOR
    }),
    solidDanger: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_DANGER_COLOR
    }),
    dotted: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_DARK_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    dottedLate: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_LATE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    dottedDanger: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_DANGER_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    dottedDistance: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_DISTANCE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    // better to use descriptive styles like these below:
    trip: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_DARK_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripCompleted: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_TRIP_COMPLETED_COLOR
    }),
    tripScheduled: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_PLANNED_SCHEDULE_COLOR
    }),
    tripUnscheduled: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_PLANNED_UNSCHEDULE_COLOR
    }),
    tripWaitlisted: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_WAITLISTED_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripCanceled: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_CANCELED_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripCanceledLate: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_OTP_LATE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripLatecanceled: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_LATE_CANCELED_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripLatecanceledLate: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_OTP_LATE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripCallback: new bemap.LineStyle({
      width: 1,
      color: _colors.BENOMAD_CALLBACK_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripDispatched: new bemap.LineStyle({
      width: 0.5,
      color: _colors.BENOMAD_DISPATCHED_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripDispatchedLate: new bemap.LineStyle({
      width: 0.7,
      color: _colors.BENOMAD_OTP_LATE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripScheduledLate: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_OTP_LATE_COLOR
    }),
    tripCompletedLate: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_OTP_LATE_COLOR
    }),
    tripWaitlistedLate: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_OTP_LATE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripDispatchedDanger: new bemap.LineStyle({
      width: 0.7,
      color: _colors.BENOMAD_OTP_LATE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripScheduledDanger: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_OTP_LATE_COLOR
    }),
    tripCompletedDanger: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_OTP_LATE_COLOR
    }),
    tripIllegal: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_ILLEGAL_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripActive: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_ACTIVE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripActiveLate: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_LATE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripEnroute: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_ENROUTE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripEnrouteLate: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_LATE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripNoshowed: new bemap.LineStyle({
      width: 1,
      color: _colors.BENOMAD_NOSHOWED_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripNoshowrequested: new bemap.LineStyle({
      width: 2,
      color: _colors.BENOMAD_NOSHOWREQUESTED_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripDanger: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_DANGER_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripLate: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_LATE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    tripSolid: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_DARK_COLOR
    }),
    tripSolidDanger: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_DANGER_COLOR
    }),
    tripSolidLate: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_LATE_COLOR
    }),
    tripPerformed: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PERFORMED_COLOR
    }),
    // otp doesn't affect stop polylines
    stopPlanned: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_COLOR
    }),
    stopPlannedDanger: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_COLOR
    }),
    stopPlannedLate: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_COLOR
    }),
    stopPending: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_COLOR
    }),
    stopPendingDanger: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_COLOR
    }),
    stopPendingLate: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_COLOR
    }),
    stopPerformed: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_COLOR
    }),
    // otp does not affect route polylines
    route: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_COLOR
    }),
    routePlanned: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_COLOR
    }),
    routeNavigation: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PENDING_COLOR
    }),
    routePerformed: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PERFORMED_COLOR
    }),
    routeScheduled: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_SCHEDULE_COLOR
    }),
    routeFloatingBreak: new bemap.LineStyle({
      color: _colors.BENOMAD_FLOATING_BREAK_COLOR,
      width: 3,
      type: bemap.LineStyle.TYPE.DASH
    })
  };
  _exports.default = _default;
});