define("adept-iq/adapters/vehicle-breakdown", ["exports", "adept-iq/adapters/-vehicleService", "adept-iq/config/api-urls"], function (_exports, _vehicleService, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _vehicleService.default.extend({
    ajax1: Ember.inject.service('ajax'),
    session: Ember.inject.service(),

    getAllVehicleBreakdown() {
      const session = this.get('session');
      return this.get('ajax1').post(_apiUrls.API.vehicleService.host + '/vehicle-breakdown', {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    }

  });

  _exports.default = _default;
});