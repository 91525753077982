define("adept-iq/models/reconcile-driver", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    driverId: attr('string'),
    firstName: attr('string'),
    middleName: attr('string'),
    lastName: attr('string'),
    phoneNumber: attr('string'),
    shiftStartTime: attr('string'),
    shiftEndTime: attr('string'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return Ember.get(this, 'firstName') + ' ' + Ember.get(this, 'lastName');
    })
  });

  _exports.default = _default;
});