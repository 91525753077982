define("adept-iq/services/fixed-route-engine", ["exports", "adept-iq/config/api-urls"], function (_exports, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FRES_ENDPOINT = '/configuration/settings';

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    fixedRouteEngineParams: null,

    async save(configItem) {
      const session = this.get('session');
      const payload = {
        'value': configItem.get('value')
      }; //TODO - Temporary fix need to rollback once got NYAAR-11301 fix

      const buildURL = _apiUrls.API.fixedRouteEngineService.host + FRES_ENDPOINT + '/' + configItem.get('name'); //this.get('ajax').set('headers',null);

      return await this.get('ajax').put(buildURL, {
        method: 'PUT',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: JSON.stringify(payload)
      }).then(async () => {
        await this.fetchFixedRouteEngine();
        return true;
      }).catch(error => {
        let message = '';

        if (!Ember.isNone(error) && !Ember.isNone(error.payload) && !Ember.isNone(error.payload.message)) {
          message = error.payload.message;
        }

        this.get('notifications').warning(`Failed to save Fixed Route Engine Parameter. ${message}`);
        return false;
      });
    },

    async fetchFixedRouteEngine() {
      const session = this.get('session');
      return await this.get('ajax').request(_apiUrls.API.fixedRouteEngineService.host + FRES_ENDPOINT, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        }
      }).then(fres => {
        this.set('fixedRouteEngineParams', fres);
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.error(err.message);
        this.set('fixedRouteEngineParams', {});
      });
    }

  });

  _exports.default = _default;
});