define("adept-iq/serializers/subscription-service-need", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    serialize() {
      const json = this._super(...arguments);

      const serviceNeedTypeNameRelationship = json.data.relationships.serviceNeedType;

      if (!Ember.isNone(serviceNeedTypeNameRelationship) && !Ember.isNone(serviceNeedTypeNameRelationship.data)) {
        serviceNeedTypeNameRelationship.data.type = 'serviceNeedTypeName';
        json.data.relationships.serviceNeedTypeName = json.data.relationships.serviceNeedType;
        delete json.data.relationships.serviceNeedType;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.relationships.serviceNeedTypeName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.serviceNeedType = {
          data: {
            type: 'service-need-type',
            id: resourceHash.relationships.serviceNeedTypeName.data.id
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.relationships.serviceNeedTypeName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});