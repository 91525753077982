define("adept-iq/pods/components/form-components/location-pick/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y4V9Ipoo",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"table\"],[11,\"class\",\"w-100\"],[9],[0,\"\\n  \"],[7,\"tr\"],[9],[0,\"\\n    \"],[7,\"td\"],[12,\"class\",[28,[\"edit-cell p-0 \",[21,\"styleAddressCell\"]]]],[11,\"data-test-address-field\",\"\"],[12,\"aria-hidden\",[28,[[21,\"removeAddress\"]]]],[11,\"width\",\"90%\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"location-box d-flex align-items-center\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"class\",\"placeholder\",\"options\",\"disabled\",\"search\",\"selected\",\"onchange\"],[\"data-test-power-select\",\"Address\",[23,[\"searchResults\"]],[23,[\"disabled\"]],[27,\"perform\",[[23,[\"searchTask\"]]],null],[27,\"readonly\",[[23,[\"selectedAddress\"]]],null],[27,\"action\",[[22,0,[]],\"onAddressSelected\"],null]]],{\"statements\":[[0,\"        \"],[1,[22,1,[\"freeformAddress\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[27,\"not\",[[23,[\"isHidePicker\"]]],null]],null,{\"statements\":[[0,\"          \"],[7,\"button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"pickOnMap\",[23,[\"address\"]]],null]],[12,\"disabled\",[21,\"disabled\"]],[12,\"aria-hidden\",[28,[[21,\"removeMarker\"]]]],[12,\"class\",[28,[\"btn btn-outline-secondary places-button \",[21,\"styleMarker\"]]]],[9],[0,\"\\n            \"],[7,\"i\"],[11,\"class\",\"fa fa-map-marker\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/location-pick/template.hbs"
    }
  });

  _exports.default = _default;
});