define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/csv-file", ["exports", "react", "react-playback-components-library", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "moment", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/config"], function (_exports, _react, _reactPlaybackComponentsLibrary, _panel, _moment, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CSVLink = _reactPlaybackComponentsLibrary.default.IqCSVLink;

  const CSVFile = props => {
    const {
      table
    } = props;
    const {
      eventTableColumns,
      vehicleTableColumns,
      eventTableData,
      vehicleTableData
    } = (0, _panel.usePanel)();

    const [csvData, setCsvData] = _react.default.useState('');

    const eventFileHeaders = eventTableColumns.filter(column => column.isChecked).map(column => {
      return {
        label: column.label,
        key: column.valuePath
      };
    });
    const vehicleFileHeaders = vehicleTableColumns.filter(column => column.isChecked).map(column => {
      return {
        label: column.label,
        key: column.valuePath
      };
    });
    const csvHeaders = table === _config.default.tableTypes.events ? [eventFileHeaders.map(header => header.label).join(',')] : [vehicleFileHeaders.map(header => header.label).join(',')];
    const date = (0, _moment.default)().format('YYYY-MM-DD');

    _react.default.useEffect(() => {
      const _csvData = [];

      if (table === _config.default.tableTypes.events) {
        eventTableData.forEach(event => {
          const values = eventFileHeaders.map(header => `"${event[header.key]}"`).join(',');

          _csvData.push(values);
        });
      } else {
        vehicleTableData.forEach(vehicle => {
          const values = vehicleFileHeaders.map(header => `"${vehicle[header.key]}"`).join(',');

          _csvData.push(values);
        });
      }

      setCsvData(_csvData.join('\r\n'));
    }, [eventTableData, vehicleTableData]);

    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(CSVLink, {
      data: csvData,
      headers: csvHeaders,
      filename: `${date}_${table}_Playback.csv`
    }, _react.default.createElement("button", {
      className: "ember-basic-dropdown-content table-component-content data-test-dropdown  ember-basic-dropdown-content--right ember-basic-dropdown-content--below ember-basic-dropdown--transitioned-in ember-view"
    }, "Download")));
  };

  var _default = CSVFile;
  _exports.default = _default;
});