define("adept-iq/pods/components/form-components/activity-log-entry/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "40cB/nZq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"colspan\",\"100\"],[11,\"class\",\"sub-header\"],[9],[0,\"\\n    \"],[7,\"h4\"],[9],[0,\"\\n      \"],[1,[23,[\"section\",\"title\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n  \"],[14,1,[[23,[\"section\",\"fields\"]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/activity-log-entry/section/template.hbs"
    }
  });

  _exports.default = _default;
});