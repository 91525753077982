define("adept-iq/tests/factories/avlm-alert", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const messages = ['Some Route is Super Late Bruh', 'Arthur Rojas is the Death Reckoning', 'Have you seen Jose?'];
  const CATEGORY_EMERGENCY = 'emergency';
  const ACTIVE_EMERGENCY = 'active';

  _emberDataFactoryGuy.default.define('avlm-alert', {
    sequences: {
      messages: num => messages[num % messages.length]
    },
    default: {
      timestamp: new Date(),
      body: _emberDataFactoryGuy.default.generate('messages'),
      category: 'Normal',
      state: 'active'
    },
    traits: {
      emergency: {
        category: CATEGORY_EMERGENCY,
        state: ACTIVE_EMERGENCY
      }
    }
  });
});