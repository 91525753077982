define("adept-iq/pods/components/form-components/address-place-search/component", ["exports", "lodash", "ember-concurrency", "adept-iq/utils/unwrapProxy"], function (_exports, _lodash, _emberConcurrency, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLACE_CATEGORY = ['ad_hoc', 'certification_location'];

  var _default = Ember.Component.extend({
    classNames: ['address-place-search'],
    store: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    isExpanded: false,
    addressesOfRiderToBook: null,
    recentAddress: null,
    favoriteAddress: null,
    dropDownAction: null,
    limitedPreviousAddress: null,
    searchRecentAddress: null,
    searchFavoriteAddress: null,
    searchConfigureResults: null,
    isConfigExpanded: false,
    recentAddressText: '',
    configAddressText: '',
    favoriteAddressText: '',

    init() {
      this._super(...arguments);

      this.setRiderRecentAddress();
      this.setRiderFavoriteAddress();
    },

    didInsertElement() {
      this._bodyClickHandler = event => {
        if (this.get('isDestroyed')) return; // close dropdown if we click elsewhere in application

        if (!event.target.closest('.address-search-component')) {
          this.set('isExpanded', false);
          this.set('recentChecked', false);
          this.set('configuredChecked', false);
          this.set('isConfigExpanded', false);
          this.set('searchConfigureResults', null);
        }
      };

      document.body.addEventListener('click', this._bodyClickHandler);
    },

    async setRiderRecentAddress() {
      const rootNode = this.get('systemConfig.rootNodes');
      const configBookTrips = rootNode.filter(ele => ele.label === 'Book Trips');
      const maxPreviousAddress = configBookTrips.get('firstObject.children').filter(ele => ele.label === 'Maximum previous addresses');
      const maxPreviousAddressValue = parseInt(maxPreviousAddress.get('firstObject.value'), 10);
      const riderQueryRecord = await this.recentAddressQuery.perform();
      const segments = riderQueryRecord.map(ele => ele.segments).flat();
      const segmentStops = segments.map(ele => ele.stops).flat();
      const recentStopAddresses = segmentStops.map(stop => {
        const stopAddress = (0, _unwrapProxy.unwrapProxy)(stop.get('place.address'));
        const stopNotes = stop.get('notes');

        if (Ember.isPresent(stopAddress)) {
          stopAddress.set('notes', stopNotes);
        }

        return stopAddress;
      }).filter(segmentStop => segmentStop);
      const subscriptionsMap = riderQueryRecord.map(ele => ele.subscriptions).flat();
      const recentSubscriptionAddresses = subscriptionsMap.map(subscriptions => {
        const addresses = [];
        subscriptions.map(subscription => {
          const origin = (0, _unwrapProxy.unwrapProxy)(subscription.get('origin'));
          const originAddress = (0, _unwrapProxy.unwrapProxy)(origin.get('address'));
          const originNotes = subscription.get('originNotes');
          originAddress.set('notes', originNotes);
          addresses.push(originAddress);
          const destination = (0, _unwrapProxy.unwrapProxy)(subscription.get('destination'));
          const destinationAddress = (0, _unwrapProxy.unwrapProxy)(destination.get('address'));
          const destinationNotes = subscription.get('destinationNotes');
          destinationAddress.set('notes', destinationNotes);
          addresses.push(destinationAddress);
        });
        return addresses;
      }).flat(); //NYAAR-19476-most recent addresses need to show at the top of the Recent Addresses drop-down

      const recentAddresses = _lodash.default.concat(recentStopAddresses, recentSubscriptionAddresses).sortBy('id').reverse();

      const placeAddress = _lodash.default.uniq(recentAddresses);

      const filteredAddress = placeAddress.filter(ele => {
        return ele && ele.get('address');
      }); // remove duplicate address

      const uniqAddresses = _lodash.default.uniqBy(filteredAddress, 'fullAddress');

      const limitedPreviousAddress = uniqAddresses.slice(0, maxPreviousAddressValue);
      this.set('recentAddress', limitedPreviousAddress);
      this.set('limitedPreviousAddress', limitedPreviousAddress);
    },

    async setRiderFavoriteAddress() {
      const store = this.get('store');
      const riderId = this.get('riderRecord.id');
      const rider = store.peekRecord('rider', riderId);
      const riderPlaces = rider.get('favoriteAddresses');
      const addressIdsClause = riderPlaces.map(place => `'${place.get('place.id')}'`).join(',');
      const placeQuery = `in(id,(${addressIdsClause}))&include=address,location`;
      const favoritePlaces = riderPlaces.length > 0 ? await store.query('place', {
        filter: placeQuery
      }) : [];
      const addresses = favoritePlaces.map(favoriteAddress => {
        return (0, _unwrapProxy.unwrapProxy)(favoriteAddress.get('address'));
      });
      this.set('favoriteAddress', addresses);
    },

    toggleSelect() {
      this.toggleProperty('isOpen');
    },

    searchRecentAddressTask: (0, _emberConcurrency.task)(function* () {
      const text = this.get('recentAddressText');
      const recentAddress = this.get('limitedPreviousAddress');
      let results; // can't do a blank search

      if (Ember.isEmpty(text)) {
        this.set('searchRecentAddress', recentAddress);
        return;
      }

      this.set('isExpanded', true);

      if (!Ember.isEmpty(recentAddress)) {
        results = recentAddress;
      }

      if (Ember.isEmpty(results)) {
        return;
      } // Show Assesment Center Place into Address lookup NYAAR-10385


      results = yield this.insertAssesmentAddressList(results, text);
      const matchedAddresses = results.filter(result => {
        return result.get('fullAddress') === text;
      });

      if (matchedAddresses.length > 0) {
        Ember.run.schedule('afterRender', () => {
          this.onAddressSelect(matchedAddresses[0]);
          this.set('searchRecentAddress', matchedAddresses);
        });
        return;
      }

      this.set('searchRecentAddress', results);
    }).restartable(),
    searchFavoriteAddressTask: (0, _emberConcurrency.task)(function* () {
      const text = this.get('favoriteAddressText').toLowerCase();
      const favoriteAddress = this.get('favoriteAddress');
      let results; // can't do a blank search

      if (Ember.isEmpty(text)) {
        this.set('searchFavoriteAddress', favoriteAddress);
        return;
      }

      this.set('isExpanded', true);

      if (!Ember.isEmpty(favoriteAddress)) {
        results = favoriteAddress;
      }

      if (Ember.isEmpty(results)) {
        return;
      }

      const matchedAddresses = yield results.filter(result => {
        return result.get('aliasPlaceAddress').toLowerCase().includes(text);
      });
      this.set('searchFavoriteAddress', matchedAddresses);
    }).restartable(),

    insertAssesmentAddressList(searchResults, searchText) {
      const assesmentAddressList = searchResults.filter(result => {
        return result.get('fullAddress').includes(searchText);
      }); // remove duplicate address

      const uniqSearchResults = _lodash.default.uniqBy(assesmentAddressList, function (address) {
        return address.get('aliasAddress');
      });

      return uniqSearchResults;
    },

    recentAddressQuery: (0, _emberConcurrency.task)(function* () {
      const riderId = this.get('riderRecord.id');
      const query = `eq('id','${riderId}')`;
      const results = yield this.store.query('rider', {
        filter: query
      });
      return results;
    }).restartable(),

    /**
    *
    * @param {* string} value
    * @param {* string} addressColumn
    * @returns queried address results
    */
    addressQuery(query) {
      return this.get('store').query('address', {
        filter: query
      });
    },

    searchConfigurePlaceAddressTask: (0, _emberConcurrency.task)(function* () {
      const searchText = this.get('configAddressText');

      if (searchText.length) {
        const splitedSearchText = searchText.trim().split(' ');
        const aliasQuery = `ilike('alias','${splitedSearchText[0]}%')`;
        const aliasAddressList = yield this.addressQuery(aliasQuery);
        const addresses = aliasAddressList.toArray(); //NYAAR-19063 searchText must have a length greater than 1, need to query streetAddress with searchText.

        let streetAddressList = [];

        if (searchText.length > 1) {
          streetAddressList = parseInt(splitedSearchText[0], 10) && splitedSearchText.length > 1 ? yield this.addressQuery(`ilike('streetAddress','${splitedSearchText[1]}%')`) : yield this.addressQuery(`ilike('streetAddress','${searchText}%')`);
        }

        const streetAddresses = streetAddressList.toArray();
        const concatAddresses = addresses.concat(streetAddresses); //if there is no results for streetAddress, need to query for streetNumber

        if (!streetAddresses.length) {
          const streetNumberList = yield this.addressQuery(`ilike('streetNumber','${splitedSearchText[0]}')`);
          const streetNumbers = streetNumberList.toArray();
          concatAddresses.push(...streetNumbers);
        }

        const addressIdsClause = concatAddresses.map(address => `'${address.get('id')}'`).join(',');
        const placeQuery = `in(addressId,(${addressIdsClause}))&include=address,location`;
        const places = yield this.store.query('place', {
          filter: placeQuery
        });
        const placeList = places.toArray();
        const placeAddresses = placeList.filter(place => PLACE_CATEGORY.includes(place.get('placeCategoryType.name').toLowerCase())).map(place => {
          const location = place.get('location');
          const address = (0, _unwrapProxy.unwrapProxy)(place.get('address')); //Reg - NYAAR-20228 Place Name is displayed twice in configured places

          address.rollbackAttributes();
          address.set('lat', location.get('lat'));
          address.set('lng', location.get('lng'));
          return address;
        });
        this.set('searchConfigureResults', (0, _unwrapProxy.unwrapProxy)(placeAddresses));
        this.set('isConfigExpanded', true);
      } else {
        this.set('searchConfigureResults', null);
        this.set('isConfigExpanded', false);
      }
    }).restartable(),
    actions: {
      onRadioRecentButtonClick(value, dropdown) {
        this.set('recentAddressText', '');
        this.set('recentChecked', value);
        this.set('configuredChecked', !value);
        this.set('favoriteChecked', !value);
        this.set('isExpanded', true);
        this.set('dropDownAction', dropdown);
      },

      onRadioFavoriteButtonClick(value, dropdown) {
        this.set('favoriteAddressText', '');
        this.set('favoriteChecked', value);
        this.set('configuredChecked', !value);
        this.set('recentChecked', !value);
        this.set('isExpanded', true);
        this.set('dropDownAction', dropdown);
      },

      onRadioConfiguredButtonClick(value, dropdown) {
        this.set('configAddressText', '');
        this.set('configuredChecked', value);
        this.set('recentChecked', !value);
        this.set('favoriteChecked', !value);
        this.set('isExpanded', true);
        this.set('dropDownAction', dropdown);
      },

      onAddressSelect(address, close) {
        const addressType = this.get('addressType');
        const configuredChecked = this.get('configuredChecked');

        if (address) {
          address.set('lat', address.get('position.lat'));
          address.set('lng', address.get('position.lng'));
          address.set('freeformAddress', address.get('address.address.freeformAddress'));
          this.updateLocationForAddressSelect(address, close, addressType); //NYAAR-18629 configured Places on create booking side panel After address is selected, address is displayed as Place name-Place address.

          if (configuredChecked) {
            const freeformAddress = !address.get('alias') ? `${address.get('freeformAddress')}` : `${address.get('alias')} - ${address.get('freeformAddress')}`;
            address.set('freeformAddress', freeformAddress);
          }
        }

        if (address !== null) {
          const dropDownAction = this.get('dropDownAction');
          dropDownAction.actions.close();
          this.set('isExpanded', false);
          this.set('configuredChecked', false);
          this.set('recentChecked', false);
          this.set('favoriteChecked', false);
        }
      },

      onOpen() {
        this.toggleSelect();
      },

      onClose() {
        this.toggleSelect();
      }

    }
  });

  _exports.default = _default;
});