define("adept-iq/models/subscription", ["exports", "ember-data", "adept-iq/utils/format-text-extension", "adept-iq/config/mapped-status", "adept-iq/models/route-template", "moment", "adept-iq/config/environment"], function (_exports, _emberData, _formatTextExtension, _mappedStatus, _routeTemplate, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    status: attr('string'),
    requestTime: attr('date'),
    anchor: attr('string'),
    purpose: attr('string'),
    fare: attr('number'),
    noSharing: attr('number'),
    originNotes: attr('string'),
    destinationNotes: attr('string'),
    legNotes: attr('string'),
    startDate: attr('date', {
      defaultValue: null
    }),
    endDate: attr('date', {
      defaultValue: null
    }),
    maximumOccurrences: attr('number'),
    serviceWindowName: attr('string'),
    remainingOccurrences: attr('number', {
      defaultValue: 0
    }),
    schedulingMode: attr('string', {
      defaultValue: _routeTemplate.AUTOMATIC_SCHEDULING_MODE
    }),
    originPlannedEta: attr('date', {
      defaultValue: null
    }),
    destinationPlannedEta: attr('date', {
      defaultValue: null
    }),
    origin: belongsTo('place'),
    destination: belongsTo('place'),
    rider: belongsTo('rider'),
    group: belongsTo('subscription-group'),
    serviceWindow: belongsTo('service-window'),
    fundingAgency: belongsTo('funding-agency'),
    fareType: belongsTo('fare-type'),
    fareCategory: belongsTo('fare-category'),
    bookings: hasMany('generated-subscription-booking'),
    exclusions: hasMany('subscription-exclusion'),
    recurrencePatterns: hasMany('subscription-recurrence-pattern'),
    subscriptionTravelNeeds: hasMany('subscription-travel-need'),
    subscriptionServiceNeeds: hasMany('subscription-service-need'),
    phoneNumbers: hasMany('subscription-phone-number'),
    provider: belongsTo(),
    tripPurpose: belongsTo('trip-purpose'),
    routeTemplate: belongsTo('route-template'),
    isManualSchedulingMode: Ember.computed('schedulingMode', function () {
      return this.get('schedulingMode') === _routeTemplate.MANUAL_SCHEDULING_MODE;
    }),
    origins: Ember.computed('origin', function () {
      return [this.get('origin.content')];
    }),
    destinations: Ember.computed('destination', function () {
      return [this.get('destination.content')];
    }),
    noOfTravelNeeds: Ember.computed.alias('subscriptionTravelNeeds.length'),
    equipments: Ember.computed.filterBy('subscriptionTravelNeeds', 'isEquipment', true),
    extraPassengers: Ember.computed.filterBy('subscriptionTravelNeeds', 'isExtraPassenger', true),
    formattedRecurrence: Ember.computed('latestRecurrence', function () {
      const formattedObject = {
        header: null,
        values: []
      };
      const recurrencePatterns = this.get('latestRecurrence');
      const selectedDOWArray = [];
      if (Ember.isEmpty(recurrencePatterns)) return [];

      if (recurrencePatterns.get('sunday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'sunday'));
      }

      if (recurrencePatterns.get('monday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'monday'));
      }

      if (recurrencePatterns.get('tuesday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'tuesday'));
      }

      if (recurrencePatterns.get('wednesday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'wednesday'));
      }

      if (recurrencePatterns.get('thursday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'thursday'));
      }

      if (recurrencePatterns.get('friday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'friday'));
      }

      if (recurrencePatterns.get('saturday')) {
        selectedDOWArray.push((0, _formatTextExtension.formatTextExtension)('valueItem', 'saturday'));
      }

      formattedObject.values = selectedDOWArray;
      return formattedObject;
    }),
    latestRecurrence: Ember.computed('recurrencePatterns', function () {
      const recurrencePatterns = this.get('recurrencePatterns');
      const sortedRecurrencePatterns = recurrencePatterns.sortBy('id');
      return sortedRecurrencePatterns.get('lastObject');
    }),
    requestTimeForDisplay: Ember.computed('requestTime', 'anchor', function () {
      const anchor = this.get('anchor') === 'pick' ? 'pick' : 'appt';
      const requestTimeObject = this.get('requestTime');
      const requestTime = (0, _moment.default)(requestTimeObject).format(_environment.default.dateTimeFormat.timeMoment);
      return `${anchor} / ${requestTime}`;
    }),
    computedStatus: Ember.computed('status', function () {
      const result = _mappedStatus.convertSubscriptionStatus[this.get('status')];

      return result ? result : this.get('status');
    })
  });

  _exports.default = _default;
});