define("adept-iq/validators/validate-date", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-changeset-validations/utils/with-defaults", "moment"], function (_exports, _validationErrors, _withDefaults, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateDate = validateDate;
  // Current version of ember changeset validations does not current contain validateDate function
  // when we update our ember version and other relevant libraries we can remove this file
  const errorFormat = 'MMM Do, YYYY';

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  function validateDate() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    // eslint-disable-next-line no-param-reassign
    options = (0, _withDefaults.default)(options, {
      allowBlank: false,
      errorFormat: errorFormat
    });
    return (key, value) => {
      const {
        allowBlank
      } = options;
      let {
        before,
        onOrBefore,
        after,
        onOrAfter,
        message
      } = options;
      let type = 'date';

      if (allowBlank && (typeof value === 'undefined' || value === null)) {
        return true;
      }

      const date = (0, _moment.default)(value).toDate();

      if (!isValidDate(date)) {
        return (0, _validationErrors.default)(key, {
          type,
          value: 'not a date',
          context: {
            value,
            message
          }
        });
      }

      if (before) {
        before = (0, _moment.default)(before).toDate();
        message = message || `[BEFORE] date is NOT before ${value}`;
        type = 'before';

        if (date >= before) {
          return (0, _validationErrors.default)(key, {
            type,
            value,
            context: {
              before,
              message
            }
          });
        }
      }

      if (onOrBefore) {
        onOrBefore = (0, _moment.default)(onOrBefore).toDate();
        message = message || `[ON OR BEFORE] date is NOT on or before ${value}`;
        type = 'onOrBefore';

        if (date > onOrBefore) {
          return (0, _validationErrors.default)(key, {
            type,
            value,
            context: {
              onOrBefore,
              message
            }
          });
        }
      }

      if (after) {
        after = (0, _moment.default)(after).toDate();
        message = message || `[AFTER] date is NOT after ${value}`;
        type = 'after';

        if (date <= after) {
          return (0, _validationErrors.default)(key, {
            type,
            value,
            context: {
              after,
              message
            }
          });
        }
      }

      if (onOrAfter) {
        onOrAfter = (0, _moment.default)(onOrAfter).toDate();
        message = message || `[ON OR AFTER] date is NOT on or after ${value}`;
        type = 'onOrAfter';

        if (date < onOrAfter) {
          return (0, _validationErrors.default)(key, {
            type,
            value,
            context: {
              onOrAfter,
              message
            }
          });
        }
      }

      return true;
    };
  }
});