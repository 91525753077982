define("adept-iq/utils/location-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDistanceBetweenFromLatLon = getDistanceBetweenFromLatLon;

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  } // return miles form two points


  function getDistanceBetweenFromLatLon(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km

    const MILES = 0.621371; // default miles for 1 KM

    const dLat = deg2rad(lat2 - lat1); // deg2rad below

    const dLon = deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const dist = R * c; // Distance in km

    return dist * MILES;
  }
});