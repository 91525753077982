define("adept-iq/pods/components/form-components/vehicle-selection/vehicle-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EF2PUC6z",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"and\",[[27,\"eq\",[[23,[\"column\",\"label\"]],\"Vehicle ID\"],null],[23,[\"row\",\"isNoVehicle\"]]],null]],null,{\"statements\":[[0,\"  None\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"is-empty\",[[23,[\"value\"]]],null]],null,{\"statements\":[[0,\"  -\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/vehicle-selection/vehicle-cell/template.hbs"
    }
  });

  _exports.default = _default;
});