define("adept-iq/mixins/role-provider-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    selectedProviders: Ember.computed('providersList', function () {
      const providersList = this.get('providersList');
      return providersList.filter(p => p.isChecked);
    }),
    hasOneSelectedRoles: Ember.computed('selectedRoles', function () {
      const selectedRoles = this.get('selectedRoles');
      return Array.isArray(selectedRoles) && selectedRoles.length >= 1;
    }),
    hasOneSelectedProviders: Ember.computed('providersTitle', function () {
      const providersTitle = this.get('providersTitle');
      return providersTitle !== 'Select Providers';
    })
  });

  _exports.default = _default;
});