define("adept-iq/serializers/event", ["exports", "adept-iq/serializers/-avlm-avlmService"], function (_exports, _avlmAvlmService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};

      if (Ember.isPresent(resourceHash.attributes.userId)) {
        const relationships = resourceHash.relationships;
        relationships.user = {
          data: {
            type: 'sso-user',
            id: resourceHash.attributes.userId
          }
        };
        resourceHash.relationships = relationships;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});