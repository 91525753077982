define("adept-iq/pods/components/side-drawer/add-break/stubs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currentRouteImpactDataStub = void 0;
  const currentRouteImpactDataStub = [{
    'id': '8',
    'otp': {
      'originalStatus': 'O',
      'originalValue': 0,
      'newStatus': 'L',
      'newValue': 5139,
      'deltaValue': 5139
    },
    'stops': [{
      'id': '85',
      'tripId': 14,
      'ordinal': 1,
      'promiseTime': '2020-06-19T04:28:00-07:00',
      'requestTime': null,
      'otp': {
        'originalStatus': 'O',
        'originalValue': 0,
        'newStatus': 'L',
        'newValue': 5139,
        'deltaValue': 5139
      },
      'eta': {
        'originalValue': '2020-06-19T04:29:00-07:00',
        'newValue': '2020-06-22T19:06:29-07:00',
        'deltaValue': 5197
      },
      'polyline': null
    }, {
      'id': '88',
      'tripId': 14,
      'ordinal': 2,
      'promiseTime': null,
      'requestTime': '2020-06-19T05:30:00-07:00',
      'otp': {
        'originalStatus': 'O',
        'originalValue': 0,
        'newStatus': 'L',
        'newValue': 5158,
        'deltaValue': 5158
      },
      'eta': {
        'originalValue': '2020-06-19T04:58:00-07:00',
        'newValue': '2020-06-22T18:41:42-07:00',
        'deltaValue': 5143
      },
      'polyline': null
    }, {
      'id': 'B101',
      'tripId': 101,
      'ordinal': 3,
      'promiseTime': '2020-06-19T08:13:00-07:00',
      'requestTime': null,
      'otp': {
        'originalStatus': null,
        'originalValue': null,
        'newStatus': null,
        'newValue': null,
        'deltaValue': null
      },
      'eta': {
        'originalValue': null,
        'newValue': '2020-06-22T18:33:23-07:00',
        'deltaValue': null
      },
      'polyline': null
    }, {
      'id': 'B99',
      'tripId': 99,
      'ordinal': 5,
      'promiseTime': '2020-06-19T08:30:00-07:00',
      'requestTime': null,
      'otp': {
        'originalStatus': null,
        'originalValue': null,
        'newStatus': null,
        'newValue': null,
        'deltaValue': null
      },
      'eta': {
        'originalValue': '2020-06-19T08:30:00-07:00',
        'newValue': '2020-06-22T18:03:23-07:00',
        'deltaValue': 4893
      },
      'polyline': null
    }]
  }];
  _exports.currentRouteImpactDataStub = currentRouteImpactDataStub;
});