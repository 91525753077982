define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/config", ["exports", "adept-iq/config/icon-paths"], function (_exports, _iconPaths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VehicleEventOptions = _exports.PlaybackIcons = void 0;
  const stopIconSize = [23.0, 23.0];
  const stopIconAnchor = stopIconSize.map(x => x / 2.0);
  const PlaybackIcons = {
    vehicle: {
      iconUrl: _iconPaths.default.vehicles.vehicle,
      iconSize: [23, 23],
      iconAnchor: [11.5, 11.5]
    },
    pick: {
      iconUrl: _iconPaths.default.stops.pickPlanned,
      iconSize: stopIconSize,
      iconAnchor: stopIconAnchor
    },
    pickWheelchair: {
      iconUrl: _iconPaths.default.stops.pickPlannedWheelchair,
      iconSize: stopIconSize,
      iconAnchor: stopIconAnchor
    },
    pickPerformed: {
      iconUrl: _iconPaths.default.stops.pickCompleted,
      iconSize: stopIconSize,
      iconAnchor: stopIconAnchor
    },
    drop: {
      iconUrl: _iconPaths.default.stops.dropPlanned,
      iconSize: stopIconSize,
      iconAnchor: stopIconAnchor
    },
    dropWheelchair: {
      iconUrl: _iconPaths.default.stops.dropPlannedWheelchair,
      iconSize: stopIconSize,
      iconAnchor: stopIconAnchor
    },
    dropPerformed: {
      iconUrl: _iconPaths.default.stops.dropCompleted,
      iconSize: stopIconSize,
      iconAnchor: stopIconAnchor
    },
    garage: {
      iconUrl: _iconPaths.default.stops.garagePlanned,
      iconSize: stopIconSize,
      iconAnchor: stopIconAnchor
    },
    garagePerformed: {
      iconUrl: _iconPaths.default.stops.garageCompleted,
      iconSize: stopIconSize,
      iconAnchor: stopIconAnchor
    },
    break: {
      iconUrl: _iconPaths.default.stops.break,
      iconSize: stopIconSize,
      iconAnchor: stopIconAnchor
    },
    noshow: {
      iconUrl: _iconPaths.default.stops.noshow,
      iconSize: stopIconSize,
      iconAnchor: stopIconAnchor
    },
    pinPoint: {
      iconUrl: _iconPaths.default.maps.pinPoint,
      iconSize: [23, 23],
      iconAnchor: [11.5, 11.5]
    },
    direction: {
      iconUrl: _iconPaths.default.vehicleEvents.arrow,
      iconSize: [10, 10],
      iconAnchor: [5, 5]
    }
  };
  _exports.PlaybackIcons = PlaybackIcons;
  const VehicleEventOptions = [{
    id: 1,
    displayName: 'Pullin Garage',
    actionType: 'pullIn',
    img: React.createElement("img", {
      src: "/stops-icons/garage/garage-unperformed.svg"
    }),
    iconCfg: PlaybackIcons.garage,
    pIconCfg: PlaybackIcons.garagePerformed,
    canFilter: true
  }, {
    id: 2,
    displayName: 'Vehicle',
    stopType: 'vehicle',
    img: React.createElement("img", {
      src: "/vehicles-icons/icon-vehicle.svg"
    }),
    iconCfg: PlaybackIcons.vehicle
  }, {
    id: 3,
    displayName: 'Pullout Garage',
    actionType: 'pullOut',
    img: React.createElement("img", {
      src: "/stops-icons/garage/garage-unperformed.svg"
    }),
    iconCfg: PlaybackIcons.garage,
    pIconCfg: PlaybackIcons.garagePerformed,
    canFilter: true
  }, {
    id: 4,
    displayName: 'Off Break',
    actionType: 'offBreak',
    img: React.createElement("img", {
      src: "/stops-icons/break.svg"
    }),
    iconCfg: PlaybackIcons.break,
    canFilter: true
  }, {
    id: 5,
    displayName: 'On Break',
    actionType: 'onBreak',
    img: React.createElement("img", {
      src: "/stops-icons/break.svg"
    }),
    iconCfg: PlaybackIcons.break,
    canFilter: true
  }, {
    id: 6,
    displayName: 'Pickup',
    stopType: 'Pick',
    actionType: 'depart',
    img: React.createElement("img", {
      src: "/stops-icons/pick/pick-unperformed.svg"
    }),
    iconCfg: PlaybackIcons.pick,
    pIconCfg: PlaybackIcons.pickPerformed,
    canFilter: true
  }, {
    id: 7,
    displayName: 'Drop Off',
    stopType: 'Drop',
    actionType: 'depart',
    img: React.createElement("img", {
      src: "/stops-icons/drop/drop-unperformed.svg"
    }),
    iconCfg: PlaybackIcons.drop,
    pIconCfg: PlaybackIcons.dropPerformed,
    canFilter: true
  }, {
    id: 8,
    displayName: 'Wheelchair Pickup',
    value: 'wheelchair-pick',
    img: React.createElement("img", {
      src: "/stops-icons/wc-pick/wc-pick-unperformed.svg"
    }),
    iconCfg: PlaybackIcons.pickWheelchair
  }, {
    id: 9,
    displayName: 'Wheelchair Dropoff',
    value: 'wheelchair-drop',
    img: React.createElement("img", {
      src: "/stops-icons/wc-drop/wc-drop-unperformed.svg"
    }),
    iconCfg: PlaybackIcons.dropWheelchair
  }, {
    id: 10,
    displayName: 'Performed Pickup',
    value: '',
    img: React.createElement("img", {
      src: "/stops-icons/pick/pick-performed.svg"
    }),
    iconCfg: PlaybackIcons.pickPerformed
  }, {
    id: 11,
    displayName: 'Performed Dropoff',
    value: '',
    img: React.createElement("img", {
      src: "/stops-icons/drop/drop-performed.svg"
    }),
    iconCfg: PlaybackIcons.dropPerformed
  }, {
    id: 12,
    displayName: 'No Show Stop',
    actionType: 'noShow',
    img: React.createElement("img", {
      src: "/stops-icons/noshow.svg"
    }),
    iconCfg: PlaybackIcons.noshow,
    canFilter: true
  }];
  _exports.VehicleEventOptions = VehicleEventOptions;
});