define("adept-iq/serializers/classification-name", ["exports", "adept-iq/serializers/cs-config-model"], function (_exports, _csConfigModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigModel.default.extend({
    modelName: 'classification-name',

    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash); // trim 'cs' prefix from model type
      // not sure why its the only model to be attached with a cs prefix?
      // maybe its due to the spaces in the id will need to fix that problem in config service


      const regEx = /^(?:cs-)?(.*)$/;
      const match = regEx.exec(normalized.data.type)[1];
      normalized.data.type = match;
      return normalized;
    }

  });

  _exports.default = _default;
});