define("adept-iq/models/cs-config-permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    name: attr('string'),
    value: attr('object'),
    isFullAccessTimeline: Ember.computed('value.timeline', function () {
      if (this.get('value') && this.get('value').timeline) {
        return this.get('value').timeline[0] === 'fullAccess';
      }

      return false;
    })
  });

  _exports.default = _default;
});