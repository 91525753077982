define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/tables/maintenance/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'vehicle-maintenance',
    title: 'Vehicle Maintenance',
    defaultSortId: 'id',
    defaultSortAsc: true,
    selectFirstRow: true,
    queryModelData: true,
    tooltipTitle: 'Manage Vehicle',
    columns: [{
      id: 'schedule',
      index: 1,
      type: 'boolean',
      label: 'Scheduled',
      valuePath: 'scheduled',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'mileage',
      index: 2,
      type: 'text',
      label: 'Mileage',
      valuePath: 'mileage',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'maintenanceTypeName',
      index: 3,
      type: 'string',
      label: 'Maintenance Type',
      valuePath: 'maintenanceTypeName.description',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'maintenanceDate',
      index: 4,
      type: 'date',
      label: 'Maintenance Date',
      valuePath: 'maintenanceDate',
      defaultWidth: 70,
      valuePreview: '2020-05-15',
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }, {
      id: 'maintenanceLocationName',
      index: 5,
      type: 'string',
      label: 'Maintenance Location',
      valuePath: 'maintenanceLocationName.description',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'maintenanceStatusName',
      index: 6,
      type: 'string',
      label: 'Maintenance Status',
      valuePath: 'maintenanceStatusName.description',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'problemIdentifiedName',
      index: 7,
      type: 'string',
      label: 'Problem Identified',
      valuePath: 'problemIdentifiedName.description',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'inspectorName',
      index: 8,
      type: 'text',
      label: 'Inspector',
      valuePath: 'inspectorName.description',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'notes',
      index: 9,
      type: 'text',
      label: 'Notes',
      valuePath: 'notes',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'serviceDate',
      index: 10,
      type: 'date',
      label: 'Fix Date',
      valuePath: 'serviceDate',
      defaultWidth: 70,
      valuePreview: '2020-05-15',
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }, {
      id: 'acceptedDate',
      index: 11,
      type: 'date',
      label: 'Fix Accepted Date',
      valuePath: 'acceptedDate',
      defaultWidth: 70,
      valuePreview: '2020-05-15',
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      searchable: true
    }, {
      id: 'accepted',
      index: 12,
      type: 'boolean',
      label: 'Fix Accepted',
      valuePath: 'accepted',
      defaultWidth: 70,
      searchable: true
    }]
  };
  _exports.default = _default;
});