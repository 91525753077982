define("adept-iq/adapters/-ada-adaManagement", ["exports", "adept-iq/adapters/application", "adept-iq/config/api-urls"], function (_exports, _application, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    defaultSerializer: '-ada-adaManagement',
    host: _apiUrls.API.adaService.host,
    ajaxService: Ember.inject.service('ajax'),
    headers: Ember.computed('ajaxService.headers', function () {
      const result = this.get('ajaxService.headers');
      delete result['AIQ-Client-Activity-ID'];
      return result;
    })
  });

  _exports.default = _default;
});