define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-address/component", ["exports", "adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-address/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PICK_TYPE = 'Pick';
  const DROP_TYPE = 'Drop';

  var _default = Ember.Component.extend({
    reconcileLambda: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    selectedAddress: null,
    addressTable: null,
    searchButtonEnabled: Ember.computed.empty('selectedAddress'),
    clearButtonEnabled: Ember.computed.empty('selectedAddress'),
    config: _config.default,

    init() {
      this._super(...arguments);

      Ember.set(this, 'selectedRadioValue', 'pick');
      this.set('fetchRoutesByAddress', this.get('fetchRoutesByAddress').bind(this));
      this.set('config.title', '');
    },

    async fetchRoutesByAddress() {
      const address = this.get('selectedAddress.streetNumber');
      const searchMode = this.get('selectedSearchMode');

      if (!address || !searchMode) {
        return [];
      }

      let routes = await this.reconcileLambda.getReconcileRoutesAndTripsInfoByAddress.perform(searchMode, address);
      routes = this.computeFullName(routes);

      if (!routes.length) {
        this.set('config.title', '');
        this.set('config.noRecordsFound', true);
      } else {
        this.set('config.title', `${Ember.String.capitalize(searchMode)}`);
        this.set('config.noRecordsFound', false);
      }

      const updatedResponse = routes.map(res => {
        if (res.type === PICK_TYPE) {
          return { ...res,
            tripDate: this.get('reconcile.reconcileDate'),
            pickAddress: res.stopAddress
          };
        }

        if (res.type === DROP_TYPE) {
          return { ...res,
            tripDate: this.get('reconcile.reconcileDate'),
            dropAddress: res.stopAddress
          };
        }
      });
      return updatedResponse;
    },

    computeFullName(response) {
      return response.map(address => {
        const firstName = address.passengerFirstName ? address.passengerFirstName : '';
        const lastName = address.passengerLastName ? address.passengerLastName : '';
        const fullName = `${firstName} ${lastName}`;
        address.fullName = fullName;
        return address;
      });
    },

    actions: {
      onChangeLocation(record) {
        Ember.set(this, 'selectedAddress', record);
      },

      async onSearchRoutesByAddress() {
        this.get('addressTable').refreshData();
      },

      clearSearchAddress() {
        Ember.set(this, 'selectedAddress', null);
        this.get('addressTable').clearData();
        this.set('config.title', '');
        this.set('config.noRecordsFound', false);
        this.set('routeId', null);
      },

      onRadioButtonClick(val) {
        this.set('selectedRadioValue', val);
      }

    }
  });

  _exports.default = _default;
});