define("adept-iq/config/column-types", ["exports", "adept-iq/config/filter-types", "adept-iq/config/comparison-functions"], function (_exports, _filterTypes, _comparisonFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uuidColumnType = _exports.timeColumnType = _exports.textColumnType = _exports.stringColumnType = _exports.phoneColumnType = _exports.numberColumnType = _exports.integerColumnType = _exports.floatColumnType = _exports.enumDateColumnType = _exports.enumColumnType = _exports.default = _exports.dateTimeColumnType = _exports.dateColumnType = _exports.columnTypesHash = _exports.columnTypes = _exports.booleanColumnType = void 0;
  const uuidColumnType = {
    id: 'uuid',
    filterTypes: [_filterTypes.nullFilter, _filterTypes.stringEq, _filterTypes.stringNotEq, _filterTypes.stringContains, _filterTypes.stringStartsWith, _filterTypes.emptyFilter],
    searchFilterType: _filterTypes.stringContains,
    compare: _comparisonFunctions.numberCompare
  };
  _exports.uuidColumnType = uuidColumnType;
  const booleanColumnType = {
    id: 'boolean',
    filterTypes: [_filterTypes.nullFilter, _filterTypes.booleanEq, _filterTypes.booleanNe, _filterTypes.emptyFilter],
    searchFilterType: _filterTypes.booleanEq,
    compare: _comparisonFunctions.booleanCompare
  };
  _exports.booleanColumnType = booleanColumnType;
  const stringColumnType = {
    id: 'string',
    filterTypes: [_filterTypes.nullFilter, _filterTypes.stringEq, _filterTypes.stringNotEq, _filterTypes.stringContains, _filterTypes.stringStartsWith, _filterTypes.emptyFilter],
    searchFilterType: _filterTypes.stringContains,
    compare: _comparisonFunctions.stringCompare
  }; // alias for string

  _exports.stringColumnType = stringColumnType;
  const textColumnType = {
    id: 'text',
    filterTypes: stringColumnType.filterTypes,
    searchFilterType: stringColumnType.searchFilterType,
    compare: stringColumnType.compare
  };
  _exports.textColumnType = textColumnType;
  const phoneColumnType = {
    id: 'phone',
    filterTypes: [_filterTypes.nullFilter, _filterTypes.stringEq, _filterTypes.stringContains, _filterTypes.stringStartsWith, _filterTypes.emptyFilter],
    searchFilterType: _filterTypes.stringContains,
    compare: _comparisonFunctions.stringCompare
  };
  _exports.phoneColumnType = phoneColumnType;
  const numberColumnType = {
    id: 'number',
    filterTypes: [_filterTypes.nullFilter, _filterTypes.numberEq, _filterTypes.numberNotEq, _filterTypes.numberLt, _filterTypes.numberLte, _filterTypes.numberGt, _filterTypes.numberGte, _filterTypes.numberBetween, _filterTypes.numberStrictlyBetween, _filterTypes.emptyFilter],
    searchFilterType: _filterTypes.numberEq,
    compare: _comparisonFunctions.numberCompare
  };
  _exports.numberColumnType = numberColumnType;
  const integerColumnType = {
    id: 'integer',
    filterTypes: [_filterTypes.nullFilter, _filterTypes.integerEq, _filterTypes.numberNotEq, _filterTypes.numberLt, _filterTypes.numberLte, _filterTypes.numberGt, _filterTypes.numberGte, _filterTypes.numberBetween, _filterTypes.numberStrictlyBetween, _filterTypes.emptyFilter],
    searchFilterType: _filterTypes.integerEq,
    compare: _comparisonFunctions.numberCompare
  };
  _exports.integerColumnType = integerColumnType;
  const floatColumnType = {
    id: 'float',
    filterTypes: [_filterTypes.nullFilter, _filterTypes.numberEq, _filterTypes.numberNotEq, _filterTypes.numberLt, _filterTypes.numberLte, _filterTypes.numberGt, _filterTypes.numberGte, _filterTypes.numberBetween, _filterTypes.numberStrictlyBetween, _filterTypes.emptyFilter],
    searchFilterType: _filterTypes.numberEq,
    compare: _comparisonFunctions.numberCompare
  };
  _exports.floatColumnType = floatColumnType;
  const dateColumnType = {
    id: 'date',
    filterTypes: [_filterTypes.nullFilter, _filterTypes.dateStrEq, _filterTypes.dateStrNe, _filterTypes.dateLt, _filterTypes.dateLte, _filterTypes.dateGt, _filterTypes.dateGte, _filterTypes.dateBetween, _filterTypes.dateStrictlyBetween, _filterTypes.emptyFilter],
    searchFilterType: _filterTypes.dateStrEq,
    compare: _comparisonFunctions.dateCompare
  }; // alias for date

  _exports.dateColumnType = dateColumnType;
  const dateTimeColumnType = {
    id: 'datetime',
    filterTypes: [_filterTypes.nullFilter, _filterTypes.dateStrEq, _filterTypes.dateStrNe, _filterTypes.dateLt, _filterTypes.dateAndTimeLte, _filterTypes.dateAndTimeGt, _filterTypes.dateGte, _filterTypes.dateBetween, _filterTypes.dateStrictlyBetween, _filterTypes.emptyFilter],
    searchFilterType: dateColumnType.searchFilterType,
    compare: dateColumnType.compare
  }; // alias for date

  _exports.dateTimeColumnType = dateTimeColumnType;
  const timeColumnType = {
    id: 'time',
    filterTypes: [_filterTypes.nullFilter, _filterTypes.timeNe, _filterTypes.timeEq, _filterTypes.timeLt, _filterTypes.timeLte, _filterTypes.timeGt, _filterTypes.timeGte, _filterTypes.emptyFilter],
    searchFilterType: _filterTypes.timeStrEq,
    compare: dateColumnType.compare
  };
  _exports.timeColumnType = timeColumnType;
  const enumColumnType = {
    id: 'enum',
    filterTypes: [_filterTypes.nullFilter, _filterTypes.enumEq, _filterTypes.enumNotEq, _filterTypes.enumContains, _filterTypes.emptyFilter],
    searchFilterType: _filterTypes.enumContains,
    compare: _comparisonFunctions.stringCompare
  };
  _exports.enumColumnType = enumColumnType;
  const enumDateColumnType = {
    id: 'enum-date',
    filterTypes: [_filterTypes.nullFilter, _filterTypes.dateContains, _filterTypes.dateContainsGte, _filterTypes.dateContainsLte, _filterTypes.emptyFilter],
    searchFilterType: _filterTypes.dateContains,
    compare: _comparisonFunctions.stringCompare
  };
  _exports.enumDateColumnType = enumDateColumnType;
  const columnTypes = [uuidColumnType, booleanColumnType, stringColumnType, textColumnType, phoneColumnType, numberColumnType, integerColumnType, floatColumnType, timeColumnType, dateColumnType, dateTimeColumnType, enumColumnType, enumDateColumnType];
  _exports.columnTypes = columnTypes;
  const columnTypesHash = columnTypes.reduce((obj, columnType) => {
    obj[columnType.id] = columnType;
    return obj;
  }, {});
  _exports.columnTypesHash = columnTypesHash;
  var _default = columnTypes;
  _exports.default = _default;
});