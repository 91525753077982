define("adept-iq/pods/components/side-drawer/reconcile-trip-transfer/stubs", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tripMockPayload = _exports.routeMockData = _exports.providerMockData = _exports.pickOderMockData = _exports.dropOderMockData = void 0;
  const tripMockPayload = {
    id: 291220489,
    route: {
      id: 568866,
      driver: {
        id: 32453,
        badgeNr: 32453,
        name: 'David John'
      },
      vehicle: {
        id: 124567,
        callSign: 124567,
        status: 'N/A'
      }
    },
    promiseTime: (0, _moment.default)('12:50 AM', 'hh:mm A'),
    status: {
      otpStatus: 'Late',
      otpValue: +60
    },
    rider: {
      id: 6565124567,
      name: 'Mark S.'
    },
    provider: {
      name: 'MVT'
    },
    stops: [{
      id: 'P291220489',
      type: 'pickup',
      eta: '2020-03-07T02:09:00Z',
      address: {
        formattedAddress: '8409 35TH AVE, JACKSON HEIGHTS, NY 11372, US',
        streetName: '35TH AVE',
        streetNr: 8409,
        unitNr: '1E',
        region: 'Queens',
        postCode: 11372,
        city: 'JACKSON HEIGHTS',
        state: 'NY',
        country: 'US',
        coord: {
          lat: 40.75201,
          lng: -73.88272
        }
      }
    }, {
      id: 'D291220489',
      type: 'dropoff',
      eta: '2020-03-07T02:09:00Z',
      address: {
        formattedAddress: '3706 77TH ST TRANSVERSE, JACKSON HEIGHTS, NY 11372, US',
        streetName: '77TH ST TRANSVERSE',
        streetNr: 3706,
        unitNr: 'N/A',
        region: 'Queens',
        postCode: 11372,
        city: 'JACKSON HEIGHTS',
        state: 'NY',
        country: 'US',
        description: '***SYNAGOGUE***',
        coord: {
          lat: 40.74881,
          lng: -73.88901
        }
      }
    }]
  };
  _exports.tripMockPayload = tripMockPayload;
  const providerMockData = [{
    name: 'MAGGIES TRANSPORTATION',
    providerTypeName: 'provider',
    status: 'Active'
  }, {
    name: 'ADVANCE TRANSIT',
    providerTypeName: 'provider',
    status: 'Active'
  }, {
    name: 'ADVANCE TRANSIT',
    providerTypeName: 'provider',
    status: 'Active'
  }, {
    name: 'EMPIRE PARATRANSIT CORP',
    providerTypeName: 'provider',
    status: 'Active'
  }, {
    name: 'PREMIER PARATRANSIT LLC',
    providerTypeName: 'provider',
    status: 'Active'
  }, {
    name: 'Provider 1',
    providerTypeName: 'provider',
    status: 'Active'
  }, {
    name: 'PROFESSIONAL TRANSPORATION',
    providerTypeName: 'provider',
    status: 'Active'
  }, {
    name: 'STAR CRUISER TRANSPORTION',
    providerTypeName: 'provider',
    status: 'Active'
  }, {
    name: 'Sub Provider 1 1',
    providerTypeName: 'provider',
    status: 'Active'
  }];
  _exports.providerMockData = providerMockData;
  const routeMockData = [{
    id: 400022,
    provider: 'EMPIRE PARATRANSIT CORP',
    plannedStartTime: '2020-10-06T15:00:00.000Z',
    plannedEndTime: '2020-10-06T23:00:00.000Z',
    actualEndTimestamp: null,
    actualStartTimestamp: null
  }, {
    id: 400023,
    provider: 'EMPIRE PARATRANSIT CORP',
    plannedStartTime: '2020-10-06T15:00:00.000Z',
    plannedEndTime: '2020-10-06T23:00:00.000Z',
    actualEndTimestamp: null,
    actualStartTimestamp: null
  }, {
    id: 400024,
    provider: 'EMPIRE PARATRANSIT CORP',
    plannedStartTime: '2020-10-06T15:00:00.000Z',
    plannedEndTime: '2020-10-06T23:00:00.000Z',
    actualEndTimestamp: null,
    actualStartTimestamp: null
  }, {
    id: 400025,
    provider: 'EMPIRE PARATRANSIT CORP',
    plannedStartTime: '2020-10-06T15:00:00.000Z',
    plannedEndTime: '2020-10-06T23:00:00.000Z',
    actualEndTimestamp: null,
    actualStartTimestamp: null
  }, {
    id: 400026,
    provider: 'EMPIRE PARATRANSIT CORP',
    plannedStartTime: '2020-10-06T15:00:00.000Z',
    plannedEndTime: '2020-10-06T23:00:00.000Z',
    actualEndTimestamp: null,
    actualStartTimestamp: null
  }, {
    id: 400027,
    provider: 'EMPIRE PARATRANSIT CORP',
    plannedStartTime: '2020-10-06T15:00:00.000Z',
    plannedEndTime: '2020-10-06T23:00:00.000Z',
    actualEndTimestamp: null,
    actualStartTimestamp: null
  }, {
    id: 400028,
    provider: 'EMPIRE PARATRANSIT CORP',
    plannedStartTime: '2020-10-06T15:00:00.000Z',
    plannedEndTime: '2020-10-06T23:00:00.000Z',
    actualEndTimestamp: null,
    actualStartTimestamp: null
  }, {
    id: 400029,
    provider: 'EMPIRE PARATRANSIT CORP',
    plannedStartTime: '2020-10-06T15:00:00.000Z',
    plannedEndTime: '2020-10-06T23:00:00.000Z',
    actualEndTimestamp: null,
    actualStartTimestamp: null
  }, {
    id: 400030,
    provider: 'EMPIRE PARATRANSIT CORP',
    plannedStartTime: '2020-10-06T15:00:00.000Z',
    plannedEndTime: '2020-10-06T23:00:00.000Z',
    actualEndTimestamp: null,
    actualStartTimestamp: null
  }, {
    id: 400031,
    provider: 'EMPIRE PARATRANSIT CORP',
    plannedStartTime: '2020-10-06T15:00:00.000Z',
    plannedEndTime: '2020-10-06T23:00:00.000Z',
    actualEndTimestamp: null,
    actualStartTimestamp: null
  }];
  _exports.routeMockData = routeMockData;
  const pickOderMockData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
  _exports.pickOderMockData = pickOderMockData;
  const dropOderMockData = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
  _exports.dropOderMockData = dropOderMockData;
});