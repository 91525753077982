define("adept-iq/classes/active-contexts/booking/map-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.zoneNode = _exports.vehicleNode = _exports.ssTripNode = _exports.riderNode = _exports.riderExternalNode = _exports.mapContextNodes = _exports.mapContextModels = _exports.mapContextGraph = _exports.dsStopNode = _exports.dsRouteNode = _exports.driverNode = _exports.default = _exports.bsSubscriptionNode = _exports.bsStopNode = _exports.bsSegmentNode = _exports.bsLegNode = _exports.bsBookingNode = _exports.avlmMessageNode = _exports.alertNode = void 0;
  // Rider Managament Related Nodes
  const riderNode = {
    id: 'rider',
    modelName: 'rider',
    links: [{
      type: 'hasMany',
      nodeId: 'ss-trip',
      path: 'trips'
    }],
    providerFilterKey: 'providers'
  };
  _exports.riderNode = riderNode;
  const riderExternalNode = {
    id: 'riderExternal',
    modelName: 'rider-external',
    links: []
  }; // Driver Related Nodes

  _exports.riderExternalNode = riderExternalNode;
  const driverNode = {
    id: 'driver',
    modelName: 'driver',
    links: [{
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'latestVehicle'
    }],
    providerFilterKey: 'provider'
  }; // Vehicle Related Nodes

  _exports.driverNode = driverNode;
  const vehicleNode = {
    id: 'vehicle',
    modelName: 'vehicle',
    links: [],
    providerFilterKey: 'provider'
  }; // Zone Related Nodes

  _exports.vehicleNode = vehicleNode;
  const zoneNode = {
    id: 'zone',
    modelName: 'zone',
    links: [],
    providerFilterKey: false
  }; // Dispatch Related Nodes
  // used to traverse local data model

  _exports.zoneNode = zoneNode;
  const dsRouteNode = {
    id: 'ds-route',
    modelName: 'dispatch-route',
    links: [{
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'assignedVehicle'
    }, {
      type: 'hasMany',
      nodeId: 'ds-stop',
      path: 'orderedStopsWithGaragesAndBreaks'
    }],
    isActive: true,
    providerFilterKey: 'provider',
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.dsRouteNode = dsRouteNode;
  const dsStopNode = {
    id: 'ds-stop',
    modelName: 'stop-point',
    links: [],
    isActive: true,
    providerFilterKey: 'trip.provider',
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime'
  }; // Scheduling Related Nodes

  _exports.dsStopNode = dsStopNode;
  const ssTripNode = {
    id: 'ss-trip',
    modelName: 'trip',
    links: [{
      type: 'hasMany',
      nodeId: 'ds-stop',
      path: 'stopPoints'
    }],
    isActive: true,
    providerFilterKey: 'provider',
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime'
  };
  _exports.ssTripNode = ssTripNode;
  const avlmMessageNode = {
    id: 'avlm-message',
    modelName: 'avlm-canned-message',
    links: [{
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'vehicle'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.avlmMessageNode = avlmMessageNode;
  const alertNode = {
    id: 'avlm-alert',
    modelName: 'avlm-alert',
    links: [{
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'vehicle'
    }],
    isActive: true,
    providerFilterKey: 'provider',
    leftTimeConstraint: 'timestamp',
    rightTimeConstraint: 'timestamp'
  }; // booking service related nodes

  _exports.alertNode = alertNode;
  const bsBookingNode = {
    id: 'bs-booking',
    modelName: 'booking',
    links: [{
      type: 'belongsTo',
      nodeId: 'bs-subscription',
      path: 'subscription'
    }, {
      type: 'hasMany',
      nodeId: 'ss-trip',
      path: 'trips'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.bsBookingNode = bsBookingNode;
  const bsLegNode = {
    id: 'bs-leg',
    modelName: 'leg',
    links: [{
      type: 'belongsTo',
      nodeId: 'bs-booking',
      path: 'booking'
    }, {
      type: 'belongsTo',
      nodeId: 'rider',
      path: 'rider'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.bsLegNode = bsLegNode;
  const bsSegmentNode = {
    id: 'bs-segment',
    modelName: 'segment',
    links: [{
      type: 'belongsTo',
      nodeId: 'bs-leg',
      path: 'leg'
    }, {
      type: 'hasMany',
      nodeId: 'bs-stop',
      path: 'stops'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.bsSegmentNode = bsSegmentNode;
  const bsStopNode = {
    id: 'bs-stop',
    modelName: 'segment-stop',
    links: [{
      type: 'belongsTo',
      nodeId: 'bs-segment',
      path: 'segment'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.bsStopNode = bsStopNode;
  const bsSubscriptionNode = {
    id: 'bs-subscription',
    modelName: 'subscription',
    links: [{
      type: 'hasMany',
      nodeId: 'bs-booking',
      path: 'bookings'
    }],
    providerFilterKey: false,
    isActive: false
  };
  _exports.bsSubscriptionNode = bsSubscriptionNode;
  const mapContextNodes = [// vehicle graph
  vehicleNode, // driver graph
  driverNode, // zone graph,
  zoneNode, // dispatch graph
  dsRouteNode, dsStopNode, // scheduling graph
  ssTripNode, // rider management graph
  riderNode, riderExternalNode, // booking service graph
  bsBookingNode, bsLegNode, bsSegmentNode, bsStopNode, bsSubscriptionNode, // avlm graph
  avlmMessageNode, // alerts
  alertNode];
  _exports.mapContextNodes = mapContextNodes;
  const mapContextGraph = mapContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.mapContextGraph = mapContextGraph;
  const mapContextModels = mapContextNodes.reduce((obj, node) => {
    obj[node.modelName] = node;
    return obj;
  }, {});
  _exports.mapContextModels = mapContextModels;
  var _default = mapContextGraph;
  _exports.default = _default;
});