define("adept-iq/pods/components/form-components/combobox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-combobox'],
    value: null,
    disabled: false,
    extra: null,
    store: Ember.inject.service(),
    dataFromModel: false,
    options: Ember.computed.alias('extra.options'),

    init() {
      this._super(...arguments);

      if (!Ember.isNone(this.get('extra.optionModelName'))) {
        this.set('dataFromModel', true);
        this.set('options', this.get('store').peekAll(this.get('extra.optionModelName')));
      }
    },

    actions: {
      onChange(option) {
        this.set('selected', option);
      },

      createNew(option) {
        console.log('New option'); // eslint-disable-line no-console

        this.options.push(option);
      },

      deleteOption(option) {
        console.log('Delete option', option); // eslint-disable-line no-console
      }

    }
  });

  _exports.default = _default;
});