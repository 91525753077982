define("adept-iq/pods/components/side-drawer/system-config/contract-management/component", ["exports", "ember-concurrency", "lodash", "adept-iq/utils/regex-functions"], function (_exports, _emberConcurrency, _lodash, _regexFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NUMBER_FORMAT = /^\d*$/;
  const REMOVE_TEXT_FORMAT = /[^0-9]+/g;
  const REMOVE_SPECIAL_FORMAT = /[^a-zA-Z0-9]/g;
  const CONTRACT_MANAGEMENT_FIELDS = {
    providerName: '',
    startRouteName: '',
    endRouteName: '',
    adjustMins: '',
    convertToHourMinutes: '',
    pullOutMinCap: '',
    pullOutMaxCap: '',
    pullInMinCap: '',
    pullInMaxCap: '',
    minRouteLength: ''
  };
  const CONTRACT_MANAGEMENT_EXCLUSION_FIELDS = ['convertToHourMinutes'];
  const ZERO_MIN = '00';
  const IN_ACTIVE_STATUS = 'inactive';

  var _default = Ember.Component.extend({
    classNames: ['contract-management'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    providers: null,
    selectedProvider: null,
    disableBtns: null,
    contractManagement: null,
    isNewContract: false,
    routeError: false,
    disableForm: true,
    contractChanges: false,
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    contractFormChanges: Ember.computed('contractChanges', 'contractManagement.{trimSlack,providerName,startRouteName,endRouteName,adjustMins,minRouteLength,convertToHourMinutes,pullOutMinCap,pullOutMaxCap,pullInMinCap,pullInMaxCap}', function () {
      const contractChanges = this.get('contractChanges');
      const contractManagement = this.get('contractManagement');
      const selectedRow = this.get('selectedRow');

      if (selectedRow && this.isContractManagementChanged(contractManagement, selectedRow)) {
        return true;
      }

      if (contractChanges) {
        return true;
      }

      return false;
    }),
    disableSavebtn: Ember.computed('disableForm', 'contractManagement.{providerName,startRouteName,endRouteName,adjustMins,minRouteLength,pullOutMinCap,pullOutMaxCap,pullInMinCap,pullInMaxCap,convertToHourMinutes}', function () {
      const disableForm = this.get('disableForm');
      const contractManagement = this.get('contractManagement');

      if (disableForm) {
        return true;
      }

      const hasValues = Object.values(contractManagement).every(input => Ember.isPresent(input));
      return !hasValues;
    }),

    isContractManagementChanged(contractManagement, selectedRow) {
      const fields = {};
      Object.entries(CONTRACT_MANAGEMENT_FIELDS).forEach(key => {
        if (!CONTRACT_MANAGEMENT_EXCLUSION_FIELDS.includes(key)) {
          fields[key[0]] = selectedRow.get(key[0]);
        }
      });
      return JSON.stringify(contractManagement) !== JSON.stringify(fields);
    },

    disableUndobtn: Ember.computed('disableForm', 'contractFormChanges', function () {
      const disableForm = this.get('disableForm');
      const contractFormChanges = this.get('contractFormChanges');
      const returnValue = !(!disableForm && contractFormChanges);
      this.hasChanges(!returnValue);
      return returnValue;
    }),
    selectedRowChange: Ember.observer('selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      if (selectedRow) this.set('disableForm', true);
      this.setDefaultProperties(selectedRow);
      this.defaultSettings();
    }),

    hasChanges(value) {
      this.set('systemConfig.hasChanges', value);
    },

    init() {
      this._super(...arguments);

      const providers = this.get('store').peekAll('provider');
      const activeProviders = providers.filter(provider => !(provider.get('status').toLowerCase() === IN_ACTIVE_STATUS));
      const providerNames = activeProviders.map(val => val.get('name'));
      this.get('contractChanges');
      this.set('providers', providerNames);
      this.hasChanges = this.get('hasChanges').bind(this);
      this.setDefaultProperties();
      this.defaultSettings();
    },

    setDefaultProperties(record) {
      const fields = {};
      Object.entries(CONTRACT_MANAGEMENT_FIELDS).forEach(key => {
        fields[key[0]] = record ? record.get(key[0]) : CONTRACT_MANAGEMENT_FIELDS[key[0]];
      });
      this.resetErrors();
      this.set('contractManagement', Ember.Object.create(fields));
      this.set('contractChanges', false);
      this.hasChanges(false);
    },

    resetErrors() {
      this.setProperties({
        'providerNameError': false,
        'startRouteError': false,
        'endrouteError': false,
        'routeStartError': false,
        'minRouteLenError': false,
        'routeOverlapError': false,
        'adjustMinsError': false,
        'pullOutMinError': false,
        'pullOutMaxError': false,
        'pullInMinError': false,
        'pullInMaxError': false
      });
    },

    defaultSettings() {
      const disableBtns = {
        new: false,
        edit: false,
        delete: false
      };
      this.set('disableBtns', disableBtns);
    },

    convertToMinutes(hours) {
      const fieldArray = hours.split(':');
      return parseInt(fieldArray[1], 10) + 60 * parseInt(fieldArray[0], 10);
    },

    save: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const convertToHourMinutes = this.get('contractManagement.convertToHourMinutes');
      let isSaveSuccessful = true;
      const mins = this.convertToMinutes(convertToHourMinutes);
      this.set('contractManagement.minRouteLength', mins);
      const attributes = this.get('contractManagement');
      const notifications = this.get('notifications');
      attributes.set('trimSlack', true);
      let record = null;

      if (!this.get('isNewContract')) {
        record = this.get('selectedRow');
        const fields = this.get('contractManagement');
        Object.entries(fields).forEach(_ref => {
          let [key, val] = _ref;

          // exclude attributes that are computed properties in request payload
          if (!CONTRACT_MANAGEMENT_EXCLUSION_FIELDS.includes(key)) {
            record.set(key, val);
          }
        });
      } else {
        record = store.createRecord('trim-slack-contract-management', attributes);
      }

      try {
        yield record.save(); // Update the value in record so that it shows the latest changes in table

        record.set('convertToHourMinutes', convertToHourMinutes);
        notifications.success('Record successfully saved');
      } catch (error) {
        isSaveSuccessful = false;
        record.rollbackAttributes(); // eslint-disable-next-line no-console

        console.error(error);
        notifications.warning('Record failed to save');
        throw error;
      } finally {
        if (isSaveSuccessful) {
          const tableRef = this.get('tableRef');
          const selectedRow = this.get('selectedRow');
          this.set('contractChanges', false);

          if (tableRef) {
            Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData'); // refresh data

            if (!selectedRow) {
              tableRef.set('config.selectFirstRow', true);
              tableRef.get('firstRowSelectedTask').perform();
            }
          }
        }

        this.set('contractManagement.trimSlack');
      }
    }).drop(),
    delete: (0, _emberConcurrency.task)(function* () {
      const record = this.get('selectedRow');
      const notifications = this.get('notifications');

      try {
        record.deleteRecord();
        yield record.save();
        notifications.success('Record successfully deleted.');
      } catch (error) {
        notifications.warning('Record failed to delete.');
      } finally {
        const tableRef = this.get('tableRef');

        if (tableRef) {
          Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData'); // refresh data

          tableRef.set('config.selectFirstRow', true);
          tableRef.get('firstRowSelectedTask').perform();
        }
      }
    }).drop(),

    validateRecords() {
      const store = this.get('store');
      const record = this.get('contractManagement') || [];
      const convertToHourMinutes = record.get('convertToHourMinutes');
      const startRouteName = record.get('startRouteName');
      const endRouteName = record.get('endRouteName');
      const startRouteNameTime = parseInt(startRouteName, 10);
      const endRouteNameTime = parseInt(endRouteName, 10);
      const providerName = record.get('providerName');
      const adjustMins = record.get('adjustMins');
      const pullOutMaxCap = record.get('pullOutMaxCap');
      const pullOutMinCap = record.get('pullOutMinCap');
      const pullInMaxCap = record.get('pullInMaxCap');
      const pullInMinCap = record.get('pullInMinCap');

      const isHHMMformat = _regexFunctions.REGEX_HHMM.test(convertToHourMinutes);

      const isValidMinRouteLength = this.validateMinRouteLengthField(convertToHourMinutes);
      let errors = [];
      this.setProperties({
        'providerNameError': false,
        'startRouteError': false,
        'endrouteError': false,
        'routeStartError': false,
        'minRouteLenError': false,
        'routeOverlapError': false,
        'adjustMinsError': false,
        'pullOutMinError': false,
        'pullOutMaxError': false,
        'pullInMinError': false,
        'pullInMaxError': false
      });
      const id = this.get('selectedRow.id') || '';

      if (!providerName) {
        errors.pushObject({
          'validation': 'Provider cannot be empty.'
        });
        this.set('providerNameError', true);
      }

      if (!startRouteName) {
        errors.pushObject({
          'validation': 'Start Route cannot be empty.'
        });
        this.set('startRouteError', true);
      }

      if (!endRouteName) {
        errors.pushObject({
          'validation': 'End Route cannot be empty.'
        });
        this.set('endrouteError', true);
      }

      if (startRouteNameTime >= endRouteNameTime) {
        errors.pushObject({
          'validation': 'Start Route must be less than End Route.'
        });
        this.set('routeStartError', true);
      } else {
        let data = store.peekAll('trim-slack-contract-management');

        if (!this.get('isNewContract')) {
          data = data.filter(item => item.get('id') !== id);
        }

        data.forEach(contract => {
          if (startRouteNameTime >= contract.get('startRouteName') && startRouteNameTime <= contract.get('endRouteName') || endRouteNameTime >= contract.get('startRouteName') && endRouteNameTime <= contract.get('endRouteName')) {
            errors.pushObject({
              'validation': 'Route Number range cannot overlap an existing Route Number range.'
            });
            this.set('routeOverlapError', true);
            return false;
          }
        });
      }

      if (Ember.isEmpty(adjustMins)) {
        errors.pushObject({
          'validation': 'Adjust Minutes cannot be empty.'
        });
        this.set('adjustMinsError', true);
      }

      if (!isHHMMformat) {
        errors.pushObject({
          'validation': 'Min Route Length value must be entered in hh:mm format.'
        });
        this.set('minRouteLenError', true);
      }

      if (!isValidMinRouteLength) {
        errors.pushObject({
          'validation': 'Min Route Length must be at least 00:01.'
        });
        this.set('minRouteLenError', true);
      }

      if (Ember.isEmpty(pullOutMinCap)) {
        errors.pushObject({
          'validation': 'Pull Out Min Cap cannot be empty.'
        });
        this.set('pullOutMinError', true);
      }

      if (Ember.isEmpty(pullOutMaxCap)) {
        errors.pushObject({
          'validation': 'Pull Out Max Cap cannot be empty.'
        });
        this.set('pullOutMaxError', true);
      }

      if (Ember.isEmpty(pullInMinCap)) {
        errors.pushObject({
          'validation': 'Pull In Min Cap cannot be empty.'
        });
        this.set('pullInMinError', true);
      }

      if (Ember.isEmpty(pullInMaxCap)) {
        errors.pushObject({
          'validation': 'Pull In Max Cap cannot be empty.'
        });
        this.set('pullInMaxError', true);
      }

      const tooltip = this.get('tooltip'); //both start route and end route range can overlap existing routes
      // range error message will display twice

      errors = (0, _lodash.uniqBy)(errors, 'validation');

      if (errors.length) {
        tooltip.pushConfirmation({
          title: 'System Configuration',
          hasoverlay: true,
          errors,
          primaryActionText: 'OK',
          primaryAction: () => tooltip.reset()
        });
      }

      return !errors.length;
    },

    validateRecordTask: (0, _emberConcurrency.task)(function* () {
      return yield this.validateRecords();
    }).drop(),

    /**
     * Input Value(10:11)
     * @param value
     * @returns {boolean}
     */
    validateMinRouteLengthField(value) {
      let valid = true;
      const splits = value.split(':');
      const isValidMinRouteLength = splits.every(ele => ele === ZERO_MIN);

      if (isValidMinRouteLength) {
        valid = false;
      }

      if (splits.length !== 2) {
        valid = false;
      }

      splits.forEach(str => {
        if (str.length !== 2) {
          valid = false;
        }
      });
      return valid;
    },

    actions: {
      onProviderChange(item) {
        this.set('contractManagement.providerName', item);
        this.set('contractChanges', true);
      },

      onRouteLengthChange(contractManagement, maskObj) {
        const value = maskObj.masked;
        const mins = this.convertToMinutes(value);
        contractManagement.set('convertToHourMinutes', value);
        contractManagement.set('minRouteLength', mins);
        this.set('contractChanges', true);
      },

      newContract() {
        const selectedRow = this.get('selectedRow');

        if (selectedRow) {
          selectedRow.set('selected', false);
          this.set('tableRef.config.selectFirstRow', false);
        }

        this.setDefaultProperties();
        this.set('isNewContract', true);
        this.set('disableForm', false);
        this.set('contractChanges', false);
        this.get('tableRef').onUncheckAll();
        this.set('disableBtns.delete', true);
        this.set('disableBtns.edit', true);
        this.set('disableBtns.new', true);
      },

      editContract() {
        const selectedRow = this.get('selectedRow');
        this.setDefaultProperties(selectedRow);
        this.set('isNewContract', false);
        this.set('disableForm', false);
        this.set('contractChanges', false);
        this.set('disableBtns.delete', true);
        this.set('disableBtns.edit', true);
        this.set('disableBtns.new', true);
      },

      deleteContract() {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'System Configuration',
          hasoverlay: true,
          tip: 'Are you sure you want to delete this record?',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('delete').perform().then(() => {
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',

          secondaryAction() {
            return tooltip.reset();
          }

        });
        this.resetErrors();
      },

      saveContract() {
        this.resetErrors();

        if (this.validateRecords()) {
          const tooltip = this.get('tooltip');
          tooltip.pushConfirmation({
            title: 'System Configuration',
            hasoverlay: true,
            tip: 'Are you sure you want to save these changes?',
            primaryActionText: 'Confirm',
            primaryAction: () => {
              tooltip.reset();
              return this.get('save').perform().then(() => {
                this.set('disableForm', true);
                this.defaultSettings();
              });
            },
            secondaryActionText: 'Cancel',

            secondaryAction() {
              tooltip.reset();
            }

          });
        }
      },

      undoContract() {
        this.resetErrors();

        if (this.get('isNewContract')) {
          this.setDefaultProperties();
        } else {
          const selectedRow = this.get('selectedRow');
          this.setDefaultProperties(selectedRow);
        }
      },

      onContractFormChanges(value) {
        let finalValue;

        if (!NUMBER_FORMAT.test(value)) {
          finalValue = value.replace(REMOVE_SPECIAL_FORMAT, '');
          finalValue = value.replace(REMOVE_TEXT_FORMAT, '');
          this.set('contractManagement.adjustMins', finalValue);
          this.set('contractChanges', true);
        } else {
          this.set('contractChanges', true);
        }
      }

    }
  });

  _exports.default = _default;
});