define("adept-iq/pods/components/iq-widgets/subscription-form/travel-needs/component", ["exports", "ember-concurrency", "lodash", "adept-iq/config/mapped-permIds", "adept-iq/models/travel-need-type"], function (_exports, _emberConcurrency, _lodash, _mappedPermIds, _travelNeedType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const travelNeedsToFilter = [];
  const MAX_LOAD_TIME = 15;

  var _default = Ember.Component.extend({
    classNames: ['travel-needs-form'],
    booking: Ember.inject.service(),
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    travelNeedTypes: null,
    passengerTypes: null,
    ambulatoryCount: 1,
    countPath: 'count',
    pcaTravelNeed: null,
    travelNeedTypePath: 'travelNeedType',
    previousSelectedCompanionCount: 0,
    travelNeedTypeOptions: null,
    // need to populate this,
    totalRidersCount: 0,
    bigSeats: null,
    editLoadUnloadSubscriptionGranted: false,
    isShowSpecialPermission: false,
    haveSpecialPermission: false,
    specialPermissionTitle: 'Subscription - Wheelchair Override',
    bodyMessage: 'Override privileges are required to assign a lift and/or wheelchair to a trip for a client that is not approved for this equipment.',
    overrideWheelchairRequest: _mappedPermIds.default.overrideWheelchairRequest,
    disableLoadAndUnloadTime: Ember.computed.readOnly('booking.isOpenErrorModal'),
    maximumCompanionCount: Ember.computed.alias('maxCompanionCount'),

    init() {
      this._super(...arguments);

      const travelNeedTypes = this.store.peekAll('travel-need-type');
      const passengerTypes = this.store.peekAll('passenger-type');
      const maximumCompanionConfig = this.store.peekRecord('cs-config-item', 'config-Book_Trips/maximum_companions');
      const maximumCompanionValue = maximumCompanionConfig ? parseInt(maximumCompanionConfig.get('value'), 10) : 1;
      const bigSeatsTN = travelNeedTypes.toArray().filter(tr => parseInt(tr.get('vehicleCapacityCount'), 10) === 2); //we have to set Maximum companion value based on defined in System configuration should match with the Companion field in the drop down of travel needs of Create subscription.

      const companionArray = [...Array(maximumCompanionValue + 1).keys()];
      const editableRecords = this.get('editableRecords');
      this.set('booking.travelNeedItems', travelNeedTypes);
      this.setProperties({
        companionOptions: companionArray,
        bigSeats: bigSeatsTN.map(tr => tr.get('name').toUpperCase()),
        passengerTypes,
        travelNeedTypes,
        travelNeedTypeOptions: travelNeedTypes,
        totalRidersCount: [editableRecords[1]].length
      });
      this.getAndSetCompanionTravelNeed();
      this.countDisabledObserver();
      this.validateLoadAndUnLoadTimeToBeNumber();
      this.validateLoadAndUnLoadTime();
      this.loadTimeValue();
      this.unloadTimeValue();
      this.set('editLoadUnloadSubscriptionGranted', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editLoadUnloadSubscription, null));
    },

    selectedCompanionCount: Ember.computed.alias('booking.selectedCompanionCount'),
    countDisabledObserver: Ember.observer('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const isEditMode = this.get('isEditMode');
      this.get('legTravelNeeds').forEach(legTravelNeed => {
        const isConsumable = legTravelNeed.get('travelNeedType.isConsumable');
        let isCountDisabled = true;

        if (!isEditMode && isConsumable) {
          isCountDisabled = false;
        }

        legTravelNeed.set('isCountDisabled', isCountDisabled);
      });
    }),

    // get wheelchair count for single and multiple riders NYAAR-11938
    getAndSetWheelChairSeatCount() {
      let wheelChairCount = 0;
      this.get('booking.selectedRiders').forEach(rider => {
        const riderTravelNeeds = rider.get('travelNeeds');
        riderTravelNeeds.forEach(riderTravelNeed => {
          if (riderTravelNeed.type === 'wheelchair') {
            wheelChairCount += riderTravelNeed.cnt;
          }
        });
      });
      const wheelChairTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'WHEELCHAIR';
      });

      if (wheelChairTravelNeed) {
        wheelChairTravelNeed.set('count', wheelChairCount);
      }
    },

    getAndSetCompanionTravelNeed() {
      const companionCount = this.get('booking').getTravelNeedsCompanionCount(this.get('legTravelNeeds'));
      this.get('booking').setCompanionCount(companionCount);
    },

    ambulatorySeatCount: Ember.computed('ambulatoryTravelNeed', function () {
      const ambulatoryTravelNeed = this.get('ambulatoryTravelNeed');
      return ambulatoryTravelNeed.get('count');
    }),
    ambulatoryTravelNeed: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const ambulatoryTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'AMBULATORY';
      });
      return ambulatoryTravelNeed;
    }),
    loadTime: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', 'riderToBook.loadTime', function () {
      let pickDwellTime;
      const rider = this.store.peekRecord('rider', this.get('riderToBook.id'));
      const riderTravelNeeds = rider.get('riderTravelNeeds');
      this.get('legs').forEach(leg => {
        const segments = leg.get('segments');
        segments.forEach(segment => {
          if (segment.get('id')) {
            pickDwellTime = segment.get('pick.dwell');
          }
        });
      });

      if (pickDwellTime) {
        return pickDwellTime / 60;
      }

      const allTravelNeedLoadTimes = this.get('legTravelNeeds').map(travelNeed => {
        const travelNeedTypeName = travelNeed.get('travelNeedType.name');
        const foundRiderTravelNeed = riderTravelNeeds.find(riderTravelNeed => {
          return riderTravelNeed.get('travelNeedTypeName') === travelNeedTypeName;
        });
        const configTravelNeedTypeLoadTime = travelNeed.get('travelNeedType.loadTime') ? parseInt(travelNeed.get('travelNeedType.loadTime'), 10) : 0;
        const loadTime = (0, _lodash.isEmpty)(foundRiderTravelNeed) ? configTravelNeedTypeLoadTime : foundRiderTravelNeed.get('loadTime');
        return (0, _lodash.isNumber)(loadTime) && !(0, _lodash.isNaN)(loadTime) ? loadTime : 0;
      });
      const maxLoadTime = Math.max(...allTravelNeedLoadTimes);
      return maxLoadTime;
    }),
    unloadTime: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', 'riderToBook.unloadTime', function () {
      let dropDwellTime;
      const rider = this.store.peekRecord('rider', this.get('riderToBook.id'));
      const riderTravelNeeds = rider.get('riderTravelNeeds');
      this.get('legs').forEach(leg => {
        const segments = leg.get('segments');
        segments.forEach(segment => {
          if (segment.get('id')) {
            dropDwellTime = segment.get('drop.dwell');
          }
        });
      });

      if (dropDwellTime) {
        return dropDwellTime / 60;
      }

      const allTravelNeedUnLoadTimes = this.get('legTravelNeeds').map(travelNeed => {
        const travelNeedTypeName = travelNeed.get('travelNeedType.name');
        const foundRiderTravelNeed = riderTravelNeeds.find(riderTravelNeed => {
          return riderTravelNeed.get('travelNeedTypeName') === travelNeedTypeName;
        });
        const configTravelNeedTypeUnloadTime = travelNeed.get('travelNeedType.unloadTime') ? parseInt(travelNeed.get('travelNeedType.unloadTime'), 10) : 0;
        const unloadTime = (0, _lodash.isEmpty)(foundRiderTravelNeed) ? configTravelNeedTypeUnloadTime : foundRiderTravelNeed.get('unloadTime');
        return (0, _lodash.isNumber)(unloadTime) && !(0, _lodash.isNaN)(unloadTime) ? unloadTime : 0;
      });
      const maxUnLoadTime = Math.max(...allTravelNeedUnLoadTimes);
      return maxUnLoadTime;
    }),
    loadTimeValue: Ember.observer('loadTime', function () {
      //converting the loadTime to seconds
      const loadTime = this.get('loadTime') * 60;
      this.get('legs').forEach(leg => {
        const segments = leg.get('segments');
        segments.forEach(segment => {
          segment.set('pick.dwell', loadTime);
        });
      });
    }),
    unloadTimeValue: Ember.observer('unloadTime', function () {
      //converting the unloadTime to seconds
      const unloadTime = this.get('unloadTime') * 60;
      this.get('legs').forEach(leg => {
        const segments = leg.get('segments');
        segments.forEach(segment => {
          segment.set('drop.dwell', unloadTime);
        });
      });
    }),
    pcaEligible: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const pcaRequired = this.get('riderToBook.eligibility.pcaRequired'); // if pcaRequired then disable the checkbox

      if (pcaRequired) {
        return false;
      }

      const totalPcaRider = this.getNoOfPcaRiders();
      const pcaTravelNeed = this.getPcaTravelNeed();

      if (totalPcaRider > 0 || pcaTravelNeed) {
        return true;
      }

      return false;
    }),
    pcaStateChecked: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const pcaRequired = this.get('riderToBook.eligibility.pcaRequired');

      if (pcaRequired) {
        return true;
      }

      const pcaTravelNeed = this.getPcaTravelNeed();

      if (pcaTravelNeed) {
        return true;
      }

      return false;
    }),
    serviceAnimalChecked: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const serviceAnimalTravelNeed = this.getServiceAnimalTravelNeed();

      if (serviceAnimalTravelNeed) {
        return true;
      }

      return false;
    }),
    availableTravelNeedTypes: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const allTravelTypes = this.store.peekAll('travel-need-type');
      const existingTravelNeedsTypes = this.get('legTravelNeeds').map(travelNeed => travelNeed.get('travelNeedTypeNameUppercase'));
      const travelNeedTypesOptionsThatShouldNotBeInOptions = existingTravelNeedsTypes.filter(tr => tr).concat(travelNeedsToFilter);
      const filteredArray = allTravelTypes.filter(travelType => {
        return !travelNeedTypesOptionsThatShouldNotBeInOptions.includes(travelType.name.toUpperCase()) || this.get('bigSeats').includes(travelType.name.toUpperCase());
      });
      return filteredArray;
    }),
    nonClonedTravelNeeds: Ember.computed('legTravelNeeds.[]', function () {
      const filteredTravelNeeds = this.get('legTravelNeeds').filter(legtravelNeed => {
        return !travelNeedsToFilter.includes(legtravelNeed.get('travelNeedTypeNameUppercase')) && !legtravelNeed.get('isCloned');
      });
      return filteredTravelNeeds;
    }),
    validateLoadAndUnLoadTime: Ember.observer('loadTime', 'unloadTime', function () {
      this.set('booking.canSave', true);
      const loadTime = this.get('loadTime');
      const unLoadTime = this.get('unloadTime');

      if (loadTime > MAX_LOAD_TIME || unLoadTime > MAX_LOAD_TIME) {
        this.get('setLoadAndUnLoadTimeFlag')(false);
        this.get('booking').showErrorMessage('Load/Unload time value must be 15 min or less.');
      } else {
        this.get('setLoadAndUnLoadTimeFlag')(true);
      }
    }),
    validateLoadAndUnLoadTimeToBeNumber: Ember.observer('loadTime', 'unloadTime', function () {
      const loadTime = this.get('loadTime');
      const unLoadTime = this.get('unloadTime');
      const isLoadUnloadTimesNumber = this.get('booking').isNumber(loadTime) && this.get('booking').isNumber(unLoadTime);

      if (isLoadUnloadTimesNumber) {
        this.get('setIsLoadAndUnLoadTimeNumberFlag')(true);
      } else {
        this.get('setIsLoadAndUnLoadTimeNumberFlag')(false);
        this.get('booking').showErrorMessage('Please enter valid numbers for load/unload time.');
      }
    }),
    //NYAAR-12930  verify if pca and serviceAnimal are present in the config item
    isPcaPresentInConfig: Ember.computed('legTravelNeeds.[]', function () {
      return this.get('booking').isCheckPca();
    }),

    getPcaTravelNeed() {
      return this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedType.upperCaseName') === 'PCA';
      });
    },

    getServiceAnimalTravelNeed() {
      return this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedType.name') === 'serviceAnimal';
      });
    },

    getNoOfPcaRiders() {
      const pcaRiders = [];
      this.get('booking.selectedRiders').forEach(rider => {
        const riderTravelNeeds = rider.get('travelNeeds');
        riderTravelNeeds.forEach(riderTravelNeed => {
          if (riderTravelNeed.type === 'pca') {
            pcaRiders.push(rider);
          }
        });
      });
      return pcaRiders.length;
    },

    getPermission: (0, _emberConcurrency.task)(function* () {
      this.set('isShowSpecialPermission', true);
      yield (0, _emberConcurrency.waitForProperty)(this, 'isShowSpecialPermission', p => p === false);
    }),

    // check for vehicle capacity type of wheelchair and lift required travel needs only
    async isTravelNeedTypeAllowed(travelNeedType) {
      const overrideWheelchairRequestGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.overrideWheelchairRequest, null);
      const riderTravelNeeds = this.get('riderTravelNeeds');
      const travelNeedTypeId = travelNeedType.get('vehicleCapacityType.id');
      const needsValidation = [_travelNeedType.LIFTREQUIRED_TRAVELNEED_TYPE, _travelNeedType.WHEELCHAIR_TRAVELNEED_TYPE].includes(travelNeedTypeId); // we don't require validation for other travel need types except for liftRequired and wheelchair

      if (!needsValidation) {
        return true;
      }

      const existInRiderTravelNeeds = riderTravelNeeds.filter(riderTravelNeed => riderTravelNeed.get('vehicleCapacityType.id') === travelNeedTypeId); // exist in the rider's travel needs

      if (existInRiderTravelNeeds.length > 0) {
        return true;
      } // does not exist in rider's travel needs but user have overrideWheelchairRequest permission


      if (overrideWheelchairRequestGranted) {
        return true;
      } // get permission from another user


      await this.get('getPermission').perform();
      return this.get('haveSpecialPermission');
    },

    async changeTravelNeedType(travelNeed, travelNeedType) {
      const travelNeedTypePath = this.get('travelNeedTypePath');
      const isTravelNeedTypeAllowed = await this.isTravelNeedTypeAllowed(travelNeedType);
      const isConsumable = travelNeedType.get('isConsumable');
      const isPrevConsumable = travelNeed.get('travelNeedType.isConsumable');

      if (isTravelNeedTypeAllowed) {
        travelNeed.set(travelNeedTypePath, travelNeedType);
      } // The "seat" changes from 0 to 1 as the travel need changes from non-consumable to consumable.
      // and vice versa


      if (!isConsumable && isPrevConsumable) {
        travelNeed.set('count', 0);
      }

      if (isConsumable && !isPrevConsumable) {
        travelNeed.set('count', 1);
      }
    },

    actions: {
      onTravelNeedCountChange(travelNeed, value) {
        travelNeed.set('count', value);
        this.getAndSetCompanionTravelNeed();
        this.get('updateFare')();
      },

      onTravelNeedChange(travelNeed, travelNeedType) {
        this.changeTravelNeedType(travelNeed, travelNeedType);
        this.getAndSetCompanionTravelNeed();
        this.get('updateFare')();
      },

      onLoadTimeChange(loadTime) {
        const loadTimeInSeconds = loadTime * 60;
        this.get('legs').forEach(leg => {
          const segments = leg.get('segments');
          segments.forEach(segment => {
            segment.set('pick.dwell', loadTimeInSeconds);
          });
        });
      },

      onUnLoadTimeChange(unloadTime) {
        const unloadTimeInSeconds = unloadTime * 60;
        this.get('legs').forEach(leg => {
          const segments = leg.get('segments');
          segments.forEach(segment => {
            segment.set('drop.dwell', unloadTimeInSeconds);
          });
        });
      },

      onServiceAnimalChange(event) {
        if (event.target.checked) {
          const allTravelTypes = this.store.peekAll('travel-need-type');
          const serviceAnimalType = allTravelTypes.find(travelType => {
            return travelType.name.toUpperCase() === 'SERVICEANIMAL';
          });
          const newServiceAnimalTravelNeed = this.store.createRecord('subscription-travel-need', {
            count: 1,
            travelNeedType: this.store.peekRecord('travel-need-type', serviceAnimalType.get('id')),
            passengerType: this.store.peekRecord('passenger-type', 'client')
          });
          this.get('legTravelNeeds').pushObject(newServiceAnimalTravelNeed);
        } else {
          const sAnimalTravelNeed = this.get('legTravelNeeds').find(travelNeed => travelNeed.get('travelNeedType.name') === 'serviceAnimal');
          this.get('legTravelNeeds').removeObject(sAnimalTravelNeed);
        }
      },

      onAddTravelNeed() {
        const store = this.get('store');
        const subscription = this.get('subscription');
        const newLegTravelNeed = store.createRecord('subscription-travel-need', {
          count: 0,
          travelNeedType: null,
          passengerType: store.peekRecord('passenger-type', 'client')
        });
        newLegTravelNeed.set('subscription', subscription);
        this.get('legTravelNeeds').pushObject(newLegTravelNeed);
        this.getAndSetCompanionTravelNeed();
        this.get('updateFare')();
      },

      onRemoveTravelNeed(legTravelNeed) {
        legTravelNeed.deleteRecord();
        this.get('legTravelNeeds').removeObject(legTravelNeed);
        this.getAndSetCompanionTravelNeed();
        this.get('updateFare')();
      }

    }
  });

  _exports.default = _default;
});