define("adept-iq/utils/stringifyNumber", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringifyNumber = void 0;
  const SPECIAL = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
  const DECA = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

  const stringifyNumber = number => {
    if (number < 20) return SPECIAL[number];
    if (number % 10 === 0) return DECA[Math.floor(number / 10) - 2] + 'ieth';
    return `${DECA[Math.floor(number / 10) - 2]}y-${SPECIAL[number % 10]}`;
  };

  _exports.stringifyNumber = stringifyNumber;
});