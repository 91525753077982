define("adept-iq/pods/components/rider-management-widget/rider-certification/certification-process/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);
    },

    appSentDate: Ember.computed('riderCertificationRecord.appSentDate', function () {
      const appSentDate = this.get('riderCertificationRecord.appSentDate');
      return appSentDate ? appSentDate : null;
    }),
    appReceivedDate: Ember.computed('riderCertificationRecord.appReceivedDate', function () {
      const appReceivedDate = this.get('riderCertificationRecord.appReceivedDate');
      return appReceivedDate ? appReceivedDate : null;
    }),
    sentToMDDate: Ember.computed('riderCertificationRecord.sentToMDDate', function () {
      const sentToMDDate = this.get('riderCertificationRecord.sentToMDDate');
      return sentToMDDate ? sentToMDDate : null;
    }),
    receivedFromMDDate: Ember.computed('riderCertificationRecord.receivedFromMDDate', function () {
      const receivedFromMDDate = this.get('riderCertificationRecord.receivedFromMDDate');
      return receivedFromMDDate ? receivedFromMDDate : null;
    }),
    actions: {
      onDateValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});