define("adept-iq/pods/components/iq-widgets/stops-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/stops-widget/config", "adept-iq/pods/components/iq-widgets/stops-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/stops-widget/scheduleConfig", "adept-iq/config/environment", "adept-iq/utils/find-row-record", "ember-changeset", "adept-iq/config/api-urls", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/utils/stringifyNumber", "ember-concurrency", "adept-iq/config/mapped-permIds", "adept-iq/utils/rql-generators", "react-sortable-hoc", "lodash", "adept-iq/mixins/async-schedule-operation", "adept-iq/utils/format-function", "adept-iq/utils/format-text-extension", "adept-iq/mixins/version-check", "adept-iq/models/dispatch-route", "adept-iq/models/stop-point", "adept-iq/models/dashboard", "adept-iq/models/trip", "adept-iq/models/route-break"], function (_exports, _component, _config, _avlmConfig, _scheduleConfig, _environment, _findRowRecord, _emberChangeset, _apiUrls, _moment, _unwrapProxy, _stringifyNumber, _emberConcurrency, _mappedPermIds, _rqlGenerators, _reactSortableHoc, _lodash, _asyncScheduleOperation, _formatFunction, _formatTextExtension, _versionCheck, _dispatchRoute, _stopPoint, _dashboard, _trip, _routeBreak) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const arrayMove = _reactSortableHoc.default.arrayMove;
  const INCLUDES = ['clusters', 'vehicleRoutes', 'vehicleRoutes.driverShifts', 'vehicleRoutes.vehicle', 'vehicleRoutes.driverShifts.driver', 'vehicleRoutes.vehicle.vehicleType', 'driverBreaks', 'driverBreaks.driverBreakEvents', 'vehicleRoutes.vehicle.vehicleEvents', 'clusters.trips', 'clusters.trips.stops'];

  var _default = _component.default.extend(_asyncScheduleOperation.default, _versionCheck.default, {
    classNames: ['stops-widget'],
    classNameBindings: ['isReordering'],
    editModal: Ember.inject.service(),
    dragCoordinator: Ember.inject.service(),
    stopsReorder: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    coreEntityPopulator: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    routeexecevent: Ember.inject.service(),
    user: Ember.inject.service(),
    getCoreModelNameByWidgetRecord: Ember.computed('activeContext.topActiveContext.getCoreModelNameByWidgetRecord', function () {
      const func = this.get('activeContext.topActiveContext.getCoreModelNameByWidgetRecord');
      return func;
    }),
    permissionLayer: Ember.inject.service(),
    editComponent: 'iq-widgets/stops-form-widget',
    driverBreakEditComponent: 'iq-widgets/driver-breaks-form-widget',
    vehicleEventComponent: 'iq-widgets/vehicle-event-form-widget',
    noShowComponent: 'iq-widgets/noshow-event-stop-form-widget',
    routesEndComponent: 'iq-widgets/routes-end-form-widget',
    routesStartComponent: 'iq-widgets/routes-start-form-widget',
    cancelTripComponent: 'iq-widgets/cancel-type-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,
    sourceRowId: null,
    dragTarget: null,
    isReordering: Ember.computed.alias('stopsReorder.reorderInProgress'),
    rightClickDelay: 250,
    // Check if we still need this after iqux-perf merged
    // onReorderChange: observer('stopsReorder.reorderInProgress', function() {
    //   run.scheduleOnce('afterRender', this, 'refreshData');
    // }),
    dynamicSingleActions: Ember.computed('table.rows.@each.{selected,computedStatus}', 'selectedWidgetRecord', 'isLoading', function () {
      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        return this._dynamicAVLMActions();
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            return this._dynamicScheduleActions();

          case _dashboard.DISPATCH_ROLENAME:
          default:
            return this._dynamicDispatchActions();
        }
      }
    }),
    dragRowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),
    dragDirection: Ember.computed('dragTarget', 'sourceRowId', function () {
      const targetRow = this.get('dragTarget');

      if (targetRow) {
        const rows = this.get('dragRowGroup');
        const targetIdx = rows.indexOf((0, _findRowRecord.findRowRecordByElement)(targetRow, rows));
        const sourceIdx = rows.indexOf(rows.findBy('rowId', this.get('sourceRowId')));
        return sourceIdx - targetIdx;
      }

      return null;
    }).readOnly(),
    dragOverClass: Ember.computed('dragDirection', function () {
      const direction = this.get('dragDirection') < 0 ? 'below' : 'above';
      return ` drag-target-${direction} drag-target`;
    }),

    init() {
      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            this.set('config', _scheduleConfig.default);
            break;

          case _dashboard.DISPATCH_ROLENAME:
          default:
            this.set('config', _config.default);
            break;
        }
      }

      this._super(...arguments);
    },

    fetchDataQuery() {
      return this._super(...arguments).then(records => {
        // if it is a trip active context
        // only include the pick and drop stops
        if (this.get('activeContext.topActiveContext').tripActiveContext()) {
          return records.filter(record => {
            return ['pick', 'drop'].includes(record.get('stopType'));
          });
        }

        return records;
      });
    },

    cursorWait() {
      Ember.$('html,body').css('cursor', 'wait');
    },

    cursorDefault() {
      Ember.$('html,body').css('cursor', 'default');
    },

    saveCancelRecordFunction(records) {
      /*eslint-disable */
      const me = this;
      /*eslint-enable */

      return new Ember.RSVP.Promise((resolve, reject) => {
        if (!Ember.isEmpty(records)) {
          records.forEach(record => {
            const bookingId = record.get('leg.booking.id');
            const cancelData = this.get('routeexecevent').cancelData();
            const prepareCancelData = this.get('routeexecevent').prepareTripCancelData(cancelData, record);
            const json = JSON.stringify(prepareCancelData);
            const session = this.get('session');
            return this.get('ajax').post(_apiUrls.API.bookingService.host + '/booking/' + bookingId + '/cancellation', {
              method: 'POST',
              contentType: 'application/json',
              headers: {
                'Authorization': `Bearer ${session.data.authenticated.token}`
              },
              data: json
            }).then(() => {
              record.set('isForceDirty', false);
              me.get('notifications').success('Trip successfully canceled');
              me.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
              resolve();
            }).catch(function (error) {
              me.get('notifications').warning('Failed to cancel Trip');
              reject(error);
            });
          });
        }
      });
    },

    saveRouteStartRecordFunction(records) {
      return Ember.RSVP.all(records.map(record => {
        if (Ember.isNone(record.get('dispatchRoute.assignedDriver.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot start a route that does not have a driver.'));
        }

        if (Ember.isNone(record.get('dispatchRoute.assignedVehicle.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot start a route that does not have a vehicle.'));
        }

        const routeStartData = this.get('routeexecevent').routeStartData();
        const preparedRouteStartData = this.get('routeexecevent').prepareRouteStartData(routeStartData, record);
        const json = JSON.stringify(preparedRouteStartData);
        const session = this.get('session');
        return this.get('ajax').post(_apiUrls.API.avlmService.host + '/route-exec-event', {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          },
          data: json
        }).then(() => {
          record.set('isForceDirty', false);
          this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
          this.get('notifications').success('Start route successful');
        }).catch(() => {
          this.get('notifications').warning('Start route failed');
        });
      }));
    },

    confirmSaveRouteEndFunction(records, callback) {
      let specialCallbackWhenSuccess = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      const tooltip = this.get('tooltip');
      const title = 'End Route';
      let tip = 'The route end time selected is outside the configured limit of the planned route end time. Do you want to continue?';
      return Ember.RSVP.all(records.map(record => {
        const extensionExceeded = record.get('extensionExceeded');

        if (Ember.isNone(record.get('dispatchRoute.assignedDriver.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot end a route that does not have a driver.'));
        }

        if (Ember.isNone(record.get('dispatchRoute.assignedVehicle.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot end a route that does not have a vehicle.'));
        }

        if (!extensionExceeded) {
          tip = 'Would you like to commit your changes?';
        }

        tooltip.pushConfirmation({
          tip,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            try {
              await this.saveRouteEndRecordFunction(record);
            } catch (err) {
              return;
            } finally {
              if (specialCallbackWhenSuccess) {
                await specialCallbackWhenSuccess();
              } else if (callback) {
                await callback();
              }

              tooltip.reset();
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: async () => {
            if (callback) await callback();
            tooltip.reset();
          },
          closeAction: async () => {
            if (callback) await callback();
          }
        });
      }));
    },

    saveRouteEndRecordFunction(record) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      return routeExecEventAdapter.createEndRouteEvent(record).then(avlmVersion => routeExecEventAdapter.createCompleteRouteEvent(record, avlmVersion)).then(() => {
        record.set('isForceDirty', false);
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
        this.get('notifications').success('Route completed successfully');
        this.get('editModal').close();
        return Ember.RSVP.Promise.resolve();
      }).catch(e => {
        const payloadMessage = Ember.get(e, 'payload.message') ? JSON.parse(Ember.get(e, 'payload.message')) : null;
        let messageText = 'Failed to complete route';

        if (e.status === 400 || e.status === 500) {
          if (payloadMessage.errorDetail.includes('Execution version is different')) {
            messageText = `FAILED TO COMPLETE ROUTE. THE ROUTE (${record.get('name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`;
          }
        }

        this.get('notifications').warning(messageText);
        this.get('editModal').close();
        return Ember.RSVP.Promise.reject(e);
      });
    },

    saveDriverBreakFunction(records) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const routeBreakAdapter = store.adapterFor('route-break');
      const dispatchRouteAdapter = store.adapterFor('dispatch-route');
      this.set('editModal.errors', []);
      const rollbackOperations = [];
      return records.reduce(async (acu, record) => {
        const requestTime = record.get('requestTime');
        const projectedEndDate = record.get('projectedEndDate');
        const place = (0, _unwrapProxy.unwrapProxy)(record.get('place'));
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
        const locationExist = !Ember.isEmpty(location.get('lat'));
        let placeResponse;
        place.set('type', 'driverBreak');
        place.set('schedule', (0, _unwrapProxy.unwrapProxy)(record.get('schedule')));
        record.set('estimatedStart', requestTime);
        record.set('estimatedEnd', projectedEndDate); // creating new driver break

        if (record.get('isNew')) {
          try {
            if (locationExist) {
              record.set('place', place);
              const locationResponse = await locationAdapter.postLocation(location);
              const addressResponse = await addressAdapter.postAddress(address);
              await addressAdapter.postAddressLocation(locationResponse, addressResponse);
              placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');

              const locationId = _lodash.default.get(locationResponse, 'data.id', null);

              const addressId = _lodash.default.get(addressResponse, 'data.id', null);

              const placeId = _lodash.default.get(placeResponse, 'data.id', null);

              if (locationId) {
                rollbackOperations.unshift(_lodash.default.bind(locationAdapter.deleteLocation, locationAdapter, locationId));
              }

              if (addressId) {
                rollbackOperations.unshift(_lodash.default.bind(addressAdapter.deleteAddress, addressAdapter, addressId));
                rollbackOperations.unshift(_lodash.default.bind(addressAdapter.deleteAddressLocation, addressAdapter, addressId));
              }

              if (placeId) {
                rollbackOperations.unshift(_lodash.default.bind(placeAdapter.deletePlace, placeAdapter, placeId));
              }
            }

            const placeId = Ember.isNone(placeResponse) ? '' : placeResponse.data.id;
            const routeBreak = await routeBreakAdapter.postRouteBreak(record, placeId);

            const routeBreakId = _lodash.default.get(routeBreak, 'data.id', null);

            if (routeBreakId) {
              rollbackOperations.unshift(_lodash.default.bind(routeBreakAdapter.deleteRouteBreak, routeBreakAdapter, routeBreak.data.id));
            }

            await acu;
            const dispatchRouteVersion = record.get('route.dispatchRoute.version');
            await dispatchRouteAdapter.createDriverBreak((0, _unwrapProxy.unwrapProxy)(record.get('route.dispatchRoute')), routeBreak.data.attributes.plannedRouteOrdinal, routeBreak.data.id, dispatchRouteVersion);
            this.get('notifications').success(`Break successfully added to route ${record.get('route.name')}`);
            record.destroyRecord();
            this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['stop-point']);
            this.get('workspace').requestWootricSurvey('on_addEditDriverBreak');
            return;
          } catch (e) {
            // async function reject promise by throwing an error
            let msg = `Break failed to be added to route ${record.get('route.name')}`;

            if (e.message) {
              if (e.message.includes('modified by another request')) msg = `UNABLE TO SAVE BREAK, BECAUSE ITS ROUTE ${record.get('route.name')} HAS BEEN MODIFIED BY ANOTHER REQUEST.`;else if (e.message.includes('Unable to lock entities')) msg = 'Obtain route lock failed at this time, please retry operation.';
            }

            this.get('notifications').warning(msg);
            throw e;
          }
        } // patching driver break


        try {
          let placeId;

          if (locationExist) {
            if (!Ember.isEmpty(place.id)) {
              await place.save();
              await location.save();
              await address.save();
              placeId = place.get('id');
            } else {
              const locationResponse = await locationAdapter.postLocation(location);
              const addressResponse = await addressAdapter.postAddress(address);
              await addressAdapter.postAddressLocation(locationResponse, addressResponse);
              placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
              placeId = Ember.isNone(placeResponse) ? '' : placeResponse.data.id;

              const locationId = _lodash.default.get(locationResponse, 'data.id', null);

              const addressId = _lodash.default.get(addressResponse, 'data.id', null);

              if (locationId) {
                rollbackOperations.unshift(_lodash.default.bind(locationAdapter.deleteLocation, locationAdapter, locationId));
              }

              if (addressId) {
                rollbackOperations.unshift(_lodash.default.bind(addressAdapter.deleteAddress, addressAdapter, addressId));
                rollbackOperations.unshift(_lodash.default.bind(addressAdapter.deleteAddressLocation, addressAdapter, addressId));
              }

              if (placeId) {
                rollbackOperations.unshift(_lodash.default.bind(placeAdapter.deletePlace, placeAdapter, placeId));
              }
            }
          }

          await routeBreakAdapter.patchRouteBreak(record, placeId);
          rollbackOperations.unshift(_lodash.default.bind(this.rollBackEditDriverBreak, this, record));
          await dispatchRouteAdapter.putRouteBreak(record);
          this.get('notifications').success('SUCCESSFULLY EDITED DRIVER BREAK');
          this.get('workspace').requestWootricSurvey('on_addEditDriverBreak');
          return;
        } catch (e) {
          // async function reject promise by throwing an error
          let msg = 'FAILED TO EDIT DRIVER BREAK';

          if (e.message) {
            if (e.message.includes('modified by another request')) msg = `UNABLE TO SAVE BREAK, BECAUSE ITS ROUTE ${record.get('route.name')} HAS BEEN MODIFIED BY ANOTHER REQUEST.`;else if (e.message.includes('Unable to lock entities')) msg = 'Obtain route lock failed at this time, please retry operation.';
          }

          this.get('notifications').warning(msg);
          throw e;
        }
      }, Ember.RSVP.Promise.resolve()).catch(async e => {
        for (const operation of rollbackOperations) {
          await operation();
        }

        let msg = `Failed to add/edit driver break to route ${records[0].get('route.name')}`;
        if (e.message) msg = e.message;
        this.get('notifications').warning(msg);
      });
    },

    rollBackEditDriverBreak(record) {
      const store = this.get('store');
      const routeBreakAdapter = store.adapterFor('route-break');
      record.rollbackAttributes();
      return routeBreakAdapter.patchRouteBreak(record, record.get('place.id'));
    },

    saveNoShowRecordFunction(records) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const tripNoShowAdapter = store.adapterFor('trip-noshow');
      const notifications = this.get('notifications');
      return records.reduce((acu, record) => {
        const dsStop = (0, _unwrapProxy.unwrapProxy)(record);
        const noshow = (0, _unwrapProxy.unwrapProxy)(dsStop.get('noShow'));
        const noshowReason = (0, _unwrapProxy.unwrapProxy)(noshow.get('noShowReasonCode'));
        const trip = (0, _unwrapProxy.unwrapProxy)(dsStop.get('trip'));
        trip.set('noshowDetails', noshow.get('notes'));
        return acu.then(() => routeExecEventAdapter.createStopNoShowEvent(record)).then(() => tripNoShowAdapter.createTripNoShow(trip, noshowReason)).then(() => {
          notifications.success(`Successfully No Showed Trip ${dsStop.get('trip.tripId')}`);
        }).catch(e => {
          const payloadMessage = Ember.get(e, 'payload.message') ? JSON.parse(Ember.get(e, 'payload.message')) : null;

          if (payloadMessage.errorDetail.includes('Execution version is different')) {
            notifications.warning(`PERFORM NOSHOW FAILED. THE ROUTE (${dsStop.get('route.name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`);
          } else {
            notifications.warning(`Failed to No Show Trip ${dsStop.get('trip.tripId')}`);
          } // throw new Error(e);

        });
      }, Ember.RSVP.Promise.resolve());
    },

    async confirmSaveUndoRouteEndFunction(route) {
      const tooltip = this.get('tooltip');
      const title = 'Undo Route End';
      const tip = 'Would you like to undo route end?';
      const dispatchRoute = route.get('dispatchRoute');
      const lockRouteAction = this.get('lockRouteAction').bind(this);
      const unlockRouteAction = this.get('unlockRouteAction').bind(this);
      this.set('dispatchRouteToCheck', dispatchRoute);

      try {
        // Check dispatchRoute version first
        await this.get('versionCheckOperation').perform();
        await lockRouteAction();
        tooltip.pushConfirmation({
          tip,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            try {
              await this.saveUndoRouteEndRecordFunction(route);
            } catch (e) {
              return;
            } finally {
              await unlockRouteAction();
              tooltip.reset();
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: async () => {
            await unlockRouteAction();
            return tooltip.reset();
          },
          closeAction: async () => {
            await unlockRouteAction();
            return tooltip.reset();
          }
        });
      } catch (e) {
        await unlockRouteAction();
      }
    },

    saveUndoRouteEndRecordFunction(record) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      return routeExecEventAdapter.createUndoEndRouteEvent(record).then(() => {
        record.set('isForceDirty', false);
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
        this.get('notifications').success('Undid Route end successfully');
        return Ember.RSVP.Promise.resolve();
      }).catch(e => {
        const payloadMessage = Ember.get(e, 'payload.message') ? JSON.parse(Ember.get(e, 'payload.message')) : null;
        let messageText = 'Failed to undo ended route';

        if (e.status === 400 || e.status === 500) {
          if (payloadMessage.errorDetail.includes('Execution version is different')) {
            messageText = `FAILED TO UNDO ENDED ROUTE. THE ROUTE (${record.get('name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`;
          }
        }

        this.get('notifications').warning(messageText);
        return Ember.RSVP.Promise.reject(e);
      });
    },

    async reorderBreakInRoute(dispatchRoute, driverBreak) {
      // eslint-disable-next-line consistent-this
      const notifications = this.get('notifications');
      const store = this.get('store');
      const dispatchRouteAdapter = store.adapterFor('dispatch-route');
      const routeBreak = Ember.Object.create({
        id: driverBreak.routeBreakId,
        plannedRouteOrdinal: driverBreak.stopBreakOrd,
        route: {
          dispatchRoute
        }
      });

      try {
        const newBreakDetails = await dispatchRouteAdapter.putRouteBreak(routeBreak);
        notifications.success('Successfully reordered driver break');
        return newBreakDetails;
      } catch (error) {
        let message = '';

        if (!Ember.isNone(error) && !Ember.isNone(error.payload) && !Ember.isNone(error.payload.message)) {
          message = error.payload.message;
        }

        notifications.warning(`Failed to Reorder Driver Break. ${message}`);
        return false;
      }
    },

    validateScheduleCapacity(widgetRecords, vehicle) {
      const capacityCounts = vehicle ? vehicle.get('capacityCounts') : {};
      const flexSeats = capacityCounts.flexSeats;
      let tripsInDanger = [];
      let minimumTripsInDanger = []; // go through all the flex seats possibilities

      const capacityFits = flexSeats.some(flexSeat => {
        const currentTravelNeedCapacity = {
          ambulatory: flexSeat.ambulatory || 0,
          wheelchair: flexSeat.wheelchair || 0
        };
        tripsInDanger = [];

        for (const widgetRecord of widgetRecords) {
          const stop = this.get('store').peekRecord('trip-stop', widgetRecord.id);
          const tripTravelNeedCounts = stop ? stop.get('trip.travelNeedCounts') : {
            ambulatory: 0,
            wheelchair: 0
          };
          const stopTripId = stop ? stop.get('trip.tripId') : 0;

          if (stop) {
            if (stop.get('isPick')) {
              currentTravelNeedCapacity.ambulatory -= tripTravelNeedCounts.ambulatory;
              currentTravelNeedCapacity.wheelchair -= tripTravelNeedCounts.wheelchair;
            } else if (stop.get('isDrop')) {
              currentTravelNeedCapacity.ambulatory += tripTravelNeedCounts.ambulatory;
              currentTravelNeedCapacity.wheelchair += tripTravelNeedCounts.wheelchair;
            }

            if (stop.get('isPick')) {
              if (currentTravelNeedCapacity.ambulatory < 0 || currentTravelNeedCapacity.wheelchair < 0) {
                tripsInDanger.pushObject(stopTripId);
              }
            }
          }
        } // this flex seat fits. exit.


        if (tripsInDanger.length === 0) {
          return true;
        } // does not fit for this flex seat. keep the best possibilities


        if (minimumTripsInDanger.length === 0 || minimumTripsInDanger.length > tripsInDanger.length) {
          minimumTripsInDanger = tripsInDanger;
        }

        return false;
      });
      return capacityFits ? [] : minimumTripsInDanger;
    },

    validateCapacity(widgetRecords, vehicle) {
      const capacityCounts = vehicle ? vehicle.get('capacityCounts') : {};
      const flexSeats = capacityCounts.flexSeats;
      let tripsInDanger = [];
      let minimumTripsInDanger = []; // go through all the flex seats possibilities

      const capacityFits = flexSeats.some(flexSeat => {
        const currentTravelNeedCapacity = {
          ambulatory: flexSeat.ambulatory || 0,
          wheelchair: flexSeat.wheelchair || 0
        };
        tripsInDanger = [];

        for (const widgetRecord of widgetRecords) {
          const stop = this.get('store').peekRecord('stop-point', widgetRecord.id);
          const tripTravelNeedCounts = stop.get('trip.travelNeedCounts');
          const stopTripId = stop.get('trip.tripId');

          if (stop.get('isPick')) {
            currentTravelNeedCapacity.ambulatory -= tripTravelNeedCounts.ambulatory;
            currentTravelNeedCapacity.wheelchair -= tripTravelNeedCounts.wheelchair;
          } else if (stop.get('isDrop')) {
            currentTravelNeedCapacity.ambulatory += tripTravelNeedCounts.ambulatory;
            currentTravelNeedCapacity.wheelchair += tripTravelNeedCounts.wheelchair;
          }

          if (stop.get('isPick')) {
            if (currentTravelNeedCapacity.ambulatory < 0 || currentTravelNeedCapacity.wheelchair < 0) {
              tripsInDanger.pushObject(stopTripId);
            }
          }
        } // this flex seat fits. exit.


        if (tripsInDanger.length === 0) {
          return true;
        } // does not fit for this flex seat. keep the best possibilities


        if (minimumTripsInDanger.length === 0 || minimumTripsInDanger.length > tripsInDanger.length) {
          minimumTripsInDanger = tripsInDanger;
        }

        return false;
      });
      return capacityFits ? [] : minimumTripsInDanger;
    },

    validateReorderBeforeCommit(sourceType, sourceRowRecord, targetRowRecord, pickOrdinal, dropOrdinal) {
      const widgetRecords = this.get('widgetRecords').sortBy('stopBreakOrd');

      if (sourceType !== 'break' && sourceRowRecord.get('computedStatus') !== _stopPoint.PENDING_STOP_STATUS) {
        this.get('notifications').warning('Cannot move stop because trip have already been started.');
        return false;
      }

      if (sourceType === 'break' && (sourceRowRecord.get('computedStatus') === _stopPoint.ON_BREAK_STOP_STATUS || sourceRowRecord.get('computedStatus') === 'OffBreak')) {
        this.get('notifications').warning('Cannot move break because it have already been started.');
        return false;
      }

      if (targetRowRecord.get('stopType') !== 'break' && (sourceRowRecord.get('computedStatus') !== _stopPoint.PENDING_STOP_STATUS || targetRowRecord.get('computedStatus') !== _stopPoint.PENDING_STOP_STATUS)) {
        this.get('notifications').warning('Cannot move stop before a trip that have already been started.');
        return false;
      }

      if (targetRowRecord.get('stopType') === 'break' && (targetRowRecord.get('computedStatus') === _stopPoint.ON_BREAK_STOP_STATUS || targetRowRecord.get('computedStatus') === 'OffBreak')) {
        this.get('notifications').warning('Cannot move stop before a break that have already been started.');
        return false;
      }

      if (sourceType !== 'break' && dropOrdinal <= pickOrdinal) {
        this.get('notifications').warning('Drop-off cannot be before the Pickup');
        return false;
      }

      let onBoardCount = 0;
      let invalidBreakPostion = false;
      widgetRecords.forEach(widgetRecord => {
        if (widgetRecord.type === 'pick') {
          onBoardCount++;
        } else if (widgetRecord.type === 'drop') {
          onBoardCount--;
        } else if (widgetRecord.type === 'break') {
          if (onBoardCount > 0) invalidBreakPostion = true;
        }
      });

      if (invalidBreakPostion) {
        this.get('notifications').warning('Breaks cannot be in-between a pick and a drop stop');
        return false;
      }

      return true;
    },

    validateScheduleReorderBeforeCommit(sourceType, sourceRowRecord, targetRowRecord, pickOrdinal, dropOrdinal) {
      const widgetRecords = this.get('widgetRecords').sortBy('scheduleOrdinal');

      if (sourceType !== 'break' && dropOrdinal <= pickOrdinal) {
        this.get('notifications').warning('Drop-off cannot be before the Pickup');
        return false;
      }

      let onBoardCount = 0;
      let invalidBreakPostion = false;
      widgetRecords.forEach(widgetRecord => {
        if (widgetRecord.type === 'pick') {
          onBoardCount++;
        } else if (widgetRecord.type === 'drop') {
          onBoardCount--;
        } else if (widgetRecord.type === 'break') {
          if (onBoardCount > 0) invalidBreakPostion = true;
        }
      });

      if (invalidBreakPostion) {
        this.get('notifications').warning('Breaks cannot be in-between a pick and a drop stop');
        return false;
      }

      return true;
    },

    doReorder(sourceRowRecord, targetRowRecord, previousOrdinalArray) {
      const tooltip = this.get('tooltip');
      const sourceType = sourceRowRecord.get('stopType');
      const targetType = targetRowRecord.get('stopType');
      const title = 'Reordering Stops';
      const breaks = previousOrdinalArray.filter(stop => stop.type === _routeBreak.BREAK_TYPE);
      const minBreakLength = 1;
      let pickOrdinal = null;
      let dropOrdinal = null;
      let breakOrdinal = null;

      if (sourceType === 'pulloutGarage' || sourceType === 'pullinGarage' || targetType === 'pulloutGarage' || targetType === 'pullinGarage' || sourceRowRecord.get('routeOrdinal') === targetRowRecord.get('routeOrdinal')) {
        return false;
      }

      if (sourceType === 'drop') {
        pickOrdinal = previousOrdinalArray.find(obj => obj.id === sourceRowRecord.get('trip.pick.stopPoint.id')).stopBreakOrd; //sourceRowRecord.get('trip.pick.stopPoint.routeOrdinal');

        dropOrdinal = previousOrdinalArray.find(obj => obj.id === targetRowRecord.get('id')).stopBreakOrd; //targetRowRecord.get('routeOrdinal');
      } else if (sourceType === 'pick') {
        pickOrdinal = previousOrdinalArray.find(obj => obj.id === targetRowRecord.get('id')).stopBreakOrd; //targetRowRecord.get('routeOrdinal');

        dropOrdinal = previousOrdinalArray.find(obj => obj.id === sourceRowRecord.get('trip.drop.stopPoint.id')).stopBreakOrd; //sourceRowRecord.get('trip.drop.stopPoint.routeOrdinal');
      } else if (sourceType === 'break') {
        breakOrdinal = previousOrdinalArray.find(obj => obj.id === targetRowRecord.get('id')).stopBreakOrd; //parseInt(targetRowRecord.get('routeOrdinal'), 10);
      } else {
        this.get('notifications').warning('Invalid Source');
        return false;
      }

      let confirmMessage = `Move ${sourceRowRecord.get('stopType')} stop of Trip ${sourceRowRecord.get('trip.tripId')} to position ${targetRowRecord.get('routeOrdinal')}`;

      if (sourceRowRecord.get('stopType') === 'break') {
        const breakIndexText = this.getBreakIndexPositionByText(sourceRowRecord, previousOrdinalArray);
        confirmMessage = `Move ${breakIndexText} ${sourceRowRecord.get('stopType')} to position ${breakOrdinal}`; //NYAAR-19436-if only one break in the stops widget means we need a different confirm message

        if (breaks.length === minBreakLength) {
          confirmMessage = `Move ${sourceRowRecord.get('stopType')} to position ${breakOrdinal}`;
        }
      }

      if (!this.validateReorderBeforeCommit(sourceType, sourceRowRecord, targetRowRecord, pickOrdinal, dropOrdinal, previousOrdinalArray)) {
        return false;
      }

      tooltip.pushConfirmation({
        tip: confirmMessage,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          this.forceUpdateGrids();
          tooltip.reset();
          return true;
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          const widgetRecords = this.get('widgetRecords');
          widgetRecords.map(record => {
            const origOrdinalObj = previousOrdinalArray.find(obj => obj.id === record.id);

            if (Ember.isPresent(origOrdinalObj)) {
              record.stopBreakOrd = origOrdinalObj.stopBreakOrd;
            }
          }); // This function only works for ember-react-widget

          this.rollbackCache();
          tooltip.reset();
          return false;
        }
      });
    },

    doScheduleReorder(sourceRowRecord, targetRowRecord, previousOrdinalArray) {
      if (Ember.isBlank(sourceRowRecord) || Ember.isBlank(targetRowRecord)) {
        return false;
      }

      const tooltip = this.get('tooltip');
      const sourceType = sourceRowRecord.get('stopType');
      const targetType = targetRowRecord.get('stopType');
      const targetWidget = previousOrdinalArray.find(obj => obj.id === targetRowRecord.get('id'));
      const title = 'Reordering Stops';
      const breaks = previousOrdinalArray.filter(stop => stop.type === _routeBreak.BREAK_TYPE);
      const minBreakLength = 1;
      let pickOrdinal = null;
      let dropOrdinal = null;
      let breakOrdinal = null;

      if (sourceType === 'pulloutGarage' || sourceType === 'pullinGarage' || targetType === 'pulloutGarage' || targetType === 'pullinGarage' || sourceRowRecord.get('scheduleOrdinal') === targetRowRecord.get('scheduleOrdinal')) {
        return false;
      }

      if (sourceType === 'drop') {
        pickOrdinal = previousOrdinalArray.find(obj => obj.id === sourceRowRecord.get('trip.pick.id')).scheduleOrdinal;
        dropOrdinal = previousOrdinalArray.find(obj => obj.id === targetRowRecord.get('id')).scheduleOrdinal;
      } else if (sourceType === 'pick') {
        pickOrdinal = previousOrdinalArray.find(obj => obj.id === targetRowRecord.get('id')).scheduleOrdinal;
        dropOrdinal = previousOrdinalArray.find(obj => obj.id === sourceRowRecord.get('trip.drop.id')).scheduleOrdinal;
      } else if (sourceType === 'break') {
        breakOrdinal = previousOrdinalArray.find(obj => obj.id === targetRowRecord.get('id')).scheduleOrdinal;
      } else {
        this.get('notifications').warning('Invalid Source');
        return false;
      }

      let confirmMessage = `Move ${sourceRowRecord.get('stopType')} stop of Trip ${sourceRowRecord.get('trip.tripId')} to position ${targetWidget.scheduleOrdinal}`;

      if (sourceRowRecord.get('stopType') === 'break') {
        const breakIndexText = this.getBreakIndexPositionByText(sourceRowRecord, previousOrdinalArray);
        confirmMessage = `Move ${breakIndexText} ${sourceRowRecord.get('stopType')} to position ${breakOrdinal}`; //NYAAR-19436-if only one break in the stops widget means we need a different confirm message

        if (breaks.length === minBreakLength) {
          confirmMessage = `Move ${sourceRowRecord.get('stopType')} to position ${breakOrdinal}`;
        }
      }

      if (!this.validateScheduleReorderBeforeCommit(sourceType, sourceRowRecord, targetRowRecord, pickOrdinal, dropOrdinal, previousOrdinalArray)) {
        return false;
      }

      tooltip.pushConfirmation({
        tip: confirmMessage,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          this.forceUpdateGrids();
          tooltip.reset();
          return true;
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          const widgetRecords = this.get('widgetRecords');
          widgetRecords.map(record => {
            const origOrdinalObj = previousOrdinalArray.find(obj => obj.id === record.id);

            if (Ember.isPresent(origOrdinalObj)) {
              record.scheduleOrdinal = origOrdinalObj.scheduleOrdinal;
            }
          }); // This function only works for ember-react-widget

          this.rollbackCache();
          tooltip.reset();
          return false;
        }
      });
    },

    formatBulkTransfer(stopPoints, sourceId, destinationId) {
      return stopPoints.map(stop => {
        return {
          id: stop.id,
          type: 'stop',
          source: sourceId,
          destination: destinationId,
          ordinal: stop.stopBreakOrd
        };
      });
    },

    formatReorderManufest(stops) {
      const formattedStops = stops.map(stop => {
        return {
          id: stop.id,
          type: stop.type,
          ordinal: stop.scheduleOrdinal
        };
      });
      return formattedStops.sortBy('ordinal');
    },

    reorderBreak(breakRecords, dispatchRoute) {
      return Ember.RSVP.Promise.all(breakRecords.map(breakRecord => {
        // only send out break update of it was changed.
        if (breakRecord.ordinal !== breakRecord.stopBreakOrd) {
          return this.reorderBreakInRoute(dispatchRoute, breakRecord);
        }
      }));
    },

    async doReorderConfirm() {
      const coreEntityPopulator = this.get('coreEntityPopulator');
      const notifications = this.get('notifications');
      const widgetRecords = this.get('widgetRecords');
      const sourceId = this.get('stopsReorder.dispatchRoute.id');
      const destinationId = sourceId;
      const tripStopRecords = widgetRecords.filter(stopPoint => ['pick', 'drop'].includes(stopPoint.type));
      const tripInstructions = this.formatBulkTransfer(tripStopRecords, sourceId, destinationId);
      const dispatchRoute = this.get('stopsReorder.dispatchRoute');
      const routeVersion = this.get('stopsReorder.dispatchRouteVersion'); // change pointer to working so user knows and disable the header button

      this.cursorWait();
      this.get('stopsReorder').toggleHiddenFlag();

      if (tripInstructions.length > 0) {
        const bulkOperationAdapter = this.get('store').adapterFor('bulk-operation');
        await bulkOperationAdapter.createBulkAsyncOperation.perform({
          instructions: tripInstructions,
          prerequisites: [{
            id: sourceId,
            type: 'route',
            version: routeVersion
          }]
        }).then(async result => {
          if (result.isJobSuccess) {
            notifications.success('Successfully reordered stops');
            this.get('stopsReorder').stopReorder();
            Ember.run.later(this, function () {
              coreEntityPopulator.populateRtsRoute(dispatchRoute.get('route.id'), {});
            }, 3000);
          } else {
            if (result.result.includes('violated the capacity')) {
              notifications.warning('Reorder failed. Exceeds route capacity.');
            } else if (result.result.includes('modified by another request') || result.result.includes('but has version')) {
              const routeName = dispatchRoute.get('route.name');
              notifications.warning(`Reorder failed. The Route (${routeName}) has already been modified with another request`);
            } else if (result.result.includes('connect ECONNREFUSED')) {
              notifications.warning('UNABLE TO REORDER STOPS. SERVICE CONNECTION IS DOWN. CONTACT ADMINISTRATOR.');
            } else {
              notifications.warning('Reorder failed. Check logs for result.');
            }

            console.log(result.result); //eslint-disable-line no-console

            this.get('stopsReorder').cancelConfirmReorder();
          }
        }).catch(function (error) {
          notifications.warning(`Reorder failed. ${error}`);
          console.log(error); //eslint-disable-line no-console
        });
      } // switch pointer back to default


      this.cursorDefault();
      this.get('stopsReorder').toggleHiddenFlag();
    },

    async doScheduleReorderConfirm() {
      const coreEntityPopulator = this.get('coreEntityPopulator');
      const notifications = this.get('notifications');
      const widgetRecords = this.get('widgetRecords');
      const sourceId = this.get('stopsReorder.route.id');
      const tripStopRecords = widgetRecords.filter(stopPoint => ['pick', 'drop', 'break'].includes(stopPoint.type));
      const tripInstructions = this.formatReorderManufest(tripStopRecords); // change pointer to working so user knows and disable the header button

      this.cursorWait();
      this.get('stopsReorder').toggleHiddenFlag();

      if (tripInstructions.length > 0) {
        const currentSchedule = this.get('workspace.currentSchedule');
        const operationData = {
          routeId: sourceId,
          stops: tripInstructions
        };
        const options = {
          operationData,
          outputResult: true
        };
        await this.createScheduleAsyncOperation.perform(currentSchedule, 'reorderManifest', options).then(result => {
          if (result.isJobSuccess) {
            notifications.success('Successfully reordered stops/breaks');
            this.get('stopsReorder').stopReorder();
            Ember.run.later(this, function () {
              coreEntityPopulator.populateRtsRoute(sourceId, {});
            }, 3000);
          } else {
            if (result.results.includes('Reorder Manifest in SES fails with error:')) {
              notifications.warning('STOP CANNOT BE MOVED BECAUSE THE ROUTE HAS SINCE CHANGED.');
            } else {
              notifications.warning('Reorder failed.');
            }

            console.log(result.result); //eslint-disable-line no-console

            this.get('stopsReorder').cancelConfirmReorder();
          }
        }).catch(function (error) {
          notifications.warning(`Reorder failed. ${error}`);
          console.log(error); //eslint-disable-line no-console
        });
      } // switch pointer back to default


      this.cursorDefault();
      this.get('stopsReorder').toggleHiddenFlag();
    },

    validateReorderAfterCommit() {
      const isScheduleDashboard = this.get('workspace.isScheduleDashboard');
      const widgetRecords = isScheduleDashboard ? this.get('widgetRecords').sortBy('scheduleOrdinal') : this.get('widgetRecords').sortBy('stopBreakOrd');
      const dispatchRoute = this.get('stopsReorder.dispatchRoute');
      const route = this.get('stopsReorder.route');
      const vehicle = isScheduleDashboard ? route.get('vehicle') : dispatchRoute.get('assignedVehicle');
      const tripsInDanger = isScheduleDashboard ? this.validateScheduleCapacity(widgetRecords, vehicle) : this.validateCapacity(widgetRecords, vehicle);

      if (tripsInDanger.length > 0) {
        this.get('notifications').warning(`Reorder failed. Trip ${tripsInDanger[0]} Exceeds route capacity`);
        return false;
      }

      return true;
    },

    onReorderConfirmObserver: Ember.observer('stopsReorder.confirmReorderInProgress', function () {
      const isScheduleDashboard = this.get('workspace.isScheduleDashboard');
      if (!this.get('stopsReorder.confirmReorderInProgress')) return;

      if (this.validateReorderAfterCommit()) {
        if (isScheduleDashboard) {
          this.doScheduleReorderConfirm();
        } else {
          this.doReorderConfirm();
        }
      } else {
        this.get('stopsReorder').cancelConfirmReorder();
      }
    }),

    // generic function to append the stop type to an action label
    appendStopTypeToActionLabel(action, stopPoint) {
      switch (action.id) {
        case 'unperform':
          {
            this.set('unPerformAction.name', 'Unperform');

            if (stopPoint.get('isNoShow')) {
              this.set('unPerformAction.name', 'Unperform No Show');
            }

            break;
          }

        default:
      }
    },

    doScheduleRowReorder(oldIndex, newIndex) {
      const stopBreakOrdinals = this.get('stopsReorder.stopBreakOrdinals');
      const widgetRecords = this.get('widgetRecords').sortBy('scheduleOrdinal');
      const sourceRowId = widgetRecords[oldIndex].id;
      const sourceRowRecord = widgetRecords[oldIndex].type === 'break' ? this.get('store').peekRecord('route-break', sourceRowId) : this.get('store').peekRecord('trip-stop', sourceRowId);
      const targetRowId = widgetRecords[newIndex].id;
      const targetRowRecord = widgetRecords[newIndex].type === 'break' ? this.get('store').peekRecord('route-break', targetRowId) : this.get('store').peekRecord('trip-stop', targetRowId);
      const previousOrdinalArray = widgetRecords.map(widgetRecord => {
        return {
          id: widgetRecord.id,
          scheduleOrdinal: widgetRecord.scheduleOrdinal,
          type: widgetRecord.type
        };
      });
      const reorderWidgetRecords = arrayMove(widgetRecords, oldIndex, newIndex); // calculate new ordinals

      reorderWidgetRecords.map((record, index) => {
        this.get('widgetRecords').findBy('id', record.id).scheduleOrdinal = index;
        stopBreakOrdinals[record.id] = index;
      });
      const result = this.doScheduleReorder(sourceRowRecord, targetRowRecord, previousOrdinalArray); // reverse changes if doScheduleReorder return false validation

      if (Ember.isPresent(result) && !result) {
        widgetRecords.map((record, index) => {
          this.get('widgetRecords').findBy('id', record.id).scheduleOrdinal = previousOrdinalArray[index].scheduleOrdinal;
          stopBreakOrdinals[record.id] = previousOrdinalArray[index].scheduleOrdinal;
        });
        this.rollbackCache();
      }
    },

    doDispatchRowReorder(oldIndex, newIndex) {
      const stopBreakOrdinals = this.get('stopsReorder.stopBreakOrdinals');
      const widgetRecords = this.get('widgetRecords').sortBy('stopBreakOrd');
      const coreModelName = this.get('config.modelName');
      const sourceRowId = widgetRecords[oldIndex].id;
      const sourceRowRecord = this.get('store').peekRecord(coreModelName, sourceRowId);
      const targetRowId = widgetRecords[newIndex].id;
      const targetRowRecord = this.get('store').peekRecord(coreModelName, targetRowId);
      const previousOrdinalArray = widgetRecords.map(widgetRecord => {
        return {
          id: widgetRecord.id,
          stopBreakOrd: widgetRecord.stopBreakOrd,
          type: widgetRecord.type
        };
      });
      const reorderWidgetRecords = arrayMove(widgetRecords, oldIndex, newIndex); // calculate new ordinals

      reorderWidgetRecords.map((record, index) => {
        this.get('widgetRecords').findBy('id', record.id).stopBreakOrd = index;
        stopBreakOrdinals[record.id] = index;
      });
      const result = this.doReorder(sourceRowRecord, targetRowRecord, previousOrdinalArray); // reverse changes if doReorder return false validation

      if (Ember.isPresent(result) && !result) {
        widgetRecords.map((record, index) => {
          this.get('widgetRecords').findBy('id', record.id).stopBreakOrd = previousOrdinalArray[index].stopBreakOrd;
          stopBreakOrdinals[record.id] = previousOrdinalArray[index].stopBreakOrd;
        });
        this.rollbackCache();
      }
    },

    actions: {
      onColumnClick() {
        if (this.get('isReordering')) return;

        this._super(...arguments);
      },

      onSearchButtonClick() {
        if (this.get('isReordering')) return;

        this._super(...arguments);
      },

      onFilterButtonClick() {
        if (this.get('isReordering')) return;

        this._super(...arguments);
      },

      async onRowRightClick() {
        if (this.get('isReordering')) return;
        return this._super(...arguments);
      },

      rowReorderHandler(oldIndex, newIndex) {
        const isScheduleDashboard = this.get('workspace.isScheduleDashboard');

        if (isScheduleDashboard) {
          this.doScheduleRowReorder(oldIndex, newIndex);
        } else {
          this.doDispatchRowReorder(oldIndex, newIndex);
        }
      }

    },

    didInsertElement() {
      this._super(...arguments);

      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        this._initDispatchSingleActions();
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            this._initScheduleSingleActions();

            break;

          case _dashboard.DISPATCH_ROLENAME:
          default:
            this._initDispatchSingleActions();

            break;
        }
      }
    },

    dragStart(event) {
      const row = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('dragRowGroup'));
      if (Ember.isBlank(row)) return;
      this.set('sourceRowId', row.get('rowId'));
      const dragId = Ember.guidFor(row);
      this.get('dragCoordinator').setSourceRow(dragId, row, 'stops');
      event.dataTransfer.setData('text', dragId);
    },

    dragOver(event) {
      event.preventDefault();
      if (this.get('dragCoordinator.widgetType') !== 'stops') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drag over other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.set('dragTarget', rowElement);
      this.$(`#${rowElement.id}`).addClass(this.get('dragOverClass'));
    },

    dragLeave(event) {
      event.preventDefault();
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drag over other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.$(`#${rowElement.id}`).removeClass(['drag-target-above', 'drag-target-below', 'drag-target']);
    },

    drop(event) {
      if (this.get('dragCoordinator.widgetType') !== 'stops') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drop onto other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.$(`#${rowElement.id}`).removeClass(['drag-target-above', 'drag-target-below', 'drag-target']);
      const table = this.get('table');
      const rows = this.get('dragRowGroup'); // let sourceRowRecord = rows.findBy('rowId', this.get('sourceRowId'));
      // // User might drop elements that are not Stops widget rows.
      // if (isBlank(sourceRowRecord)) {
      //   return;
      // }

      const sourceRowRecord = this.get('dragCoordinator').getSourceRow(event.dataTransfer.getData('text'));
      if (Ember.isBlank(sourceRowRecord)) return;
      const targetRowRecord = (0, _findRowRecord.findRowRecordByElement)(event.target, rows);

      const _rows = rows.toArray();

      const targetRowIdx = _rows.indexOf(targetRowRecord) + (this.get('dragDirection') < 0 ? 0 : 0);
      event.dataTransfer.dropEffect = 'move';
      event.preventDefault();
      event.stopPropagation();
      table.notifyPropertyChange('rows');

      _rows.removeObject(sourceRowRecord);

      _rows.insertAt(targetRowIdx, sourceRowRecord);

      const result = this.doReorder(sourceRowRecord, targetRowRecord);

      if (result) {
        rows.setObjects(_rows);
      }

      table.notifyPropertyChange('rows');
    },

    dragEnd() {
      this.set('sourceRowId', null);
      this.set('dragTarget', null);
      this.get('dragCoordinator').clearSourceRows();
    },

    performScheduleCancelRouteBreak: (0, _emberConcurrency.task)(function* (routeBreakId) {
      const workspace = this.get('workspace');
      const currentSchedule = workspace.get('currentSchedule');
      const operationData = {
        routeBreakId
      };
      const options = {
        operationData,
        outputResult: true
      };
      return yield new Ember.RSVP.Promise(async (resolve, reject) => {
        try {
          const {
            isJobSuccess
          } = await this.createScheduleAsyncOperation.perform(currentSchedule, 'cancelRouteBreak', options);

          if (!isJobSuccess) {
            throw new Error();
          } else {
            return resolve();
          }
        } catch (e) {
          return reject();
        }
      });
    }).drop(),

    _initDispatchSingleActions() {
      this.set('tableActions', [{
        id: 'print',
        permIds: [_mappedPermIds.default.downloadStops],
        name: 'Download',
        action: () => {
          this.exportData();
        }
      }]);
      this.set('noShowAction', {
        id: 'noShow',
        permId: _mappedPermIds.default.noShowTrip,
        name: 'No Show',
        action: model => {
          const store = this.get('store');
          const noShowReasonCodes = this.get('store').peekAll('no-show-reason-category');
          const overrideNoShowReason = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.overrideNoShowReason); // sort all no show reason code by the promise time start/end

          const sortedNoShowReasonCode = noShowReasonCodes.toArray().sort(function (a, b) {
            const aMinStartTime = Ember.isPresent(a.get('minutesFromPromiseTimeStart')) ? parseInt(a.get('minutesFromPromiseTimeStart'), 10) : 999;
            const aMinEndTime = Ember.isPresent(a.get('minutesFromPromiseTimeEnd')) ? parseInt(a.get('minutesFromPromiseTimeEnd'), 10) : 999;
            const bMinStartTime = Ember.isPresent(b.get('minutesFromPromiseTimeStart')) ? parseInt(b.get('minutesFromPromiseTimeStart'), 10) : 999;
            const bMinEndTime = Ember.isPresent(b.get('minutesFromPromiseTimeEnd')) ? parseInt(b.get('minutesFromPromiseTimeEnd'), 10) : 999;

            if (aMinStartTime > bMinStartTime && aMinEndTime > bMinEndTime) {
              return 1;
            } else if (aMinStartTime < bMinStartTime && aMinEndTime < bMinEndTime) {
              return -1;
            }

            return 0;
          }); // calculate the duration for current time and stop's ETA

          const actualArriveTime = model.get('actualArriveTime') ? (0, _moment.default)(model.get('actualArriveTime')) : (0, _moment.default)();
          const promisedTime = (0, _moment.default)(model.get('promisedTime'));

          const minDurations = _moment.default.duration(actualArriveTime.diff(promisedTime)).asMinutes();

          let calculatedNoShowReason = sortedNoShowReasonCode.find(code => {
            const aMinStartTime = Ember.isPresent(code.get('minutesFromPromiseTimeStart')) ? parseInt(code.get('minutesFromPromiseTimeStart'), 10) : 999;
            const aMinEndTime = Ember.isPresent(code.get('minutesFromPromiseTimeEnd')) ? parseInt(code.get('minutesFromPromiseTimeEnd'), 10) : 999;

            if (minDurations >= aMinStartTime && minDurations <= aMinEndTime) {
              return true;
            }

            return false;
          });

          if (!Ember.isPresent(calculatedNoShowReason)) {
            // if it's really early then pick one with the biggest range
            if (minDurations > 0) {
              calculatedNoShowReason = sortedNoShowReasonCode.lastObject;
            } else {
              calculatedNoShowReason = sortedNoShowReasonCode.firstObject;
            }
          }

          model.set('noShow', store.createRecord('no-show', {
            noShowReasonCode: calculatedNoShowReason,
            timestamp: new Date()
          }));

          if (!Ember.isPresent(calculatedNoShowReason) || overrideNoShowReason) {
            model.set('noShowDropDownReadonly', false);
          } else {
            model.set('noShowDropDownReadonly', true);
          }

          const noShowComponent = this.get('noShowComponent');
          this.get('editModal').open(noShowComponent, [model], this.get('saveNoShowRecordFunction').bind(this), null, null, {
            title: 'No Show Stop'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('removeAction', {
        id: 'remove',
        name: 'Remove',
        action: () => {},
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editAction', {
        id: 'edit',
        name: 'Edit',
        action: () => {},
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('cancelAction', {
        id: 'cancelTrip',
        permIds: [_mappedPermIds.default.cancelFuture, _mappedPermIds.default.cancelSameDay],
        name: 'Cancel Trip',
        action: model => {
          const selectedWidgetRecord = this.get('selectedWidgetRecord');
          const selectedRowTripId = selectedWidgetRecord ? parseInt(selectedWidgetRecord.tripId, 10) : '';
          const selectedRowTrip = selectedRowTripId ? this.get('store').peekRecord('trip', selectedRowTripId) : '';
          const trip = (0, _unwrapProxy.unwrapProxy)(model.get('trip')) ? (0, _unwrapProxy.unwrapProxy)(model.get('trip')) : selectedRowTrip;
          const tripId = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
          const booking = (0, _unwrapProxy.unwrapProxy)(trip.get('booking'));
          const segment = (0, _unwrapProxy.unwrapProxy)(trip.get('segment'));
          booking.set('timestamp', new Date());
          this.get('workspace').pushState('cancelTripForm', {
            displayName: 'Cancel Trip',
            trip,
            booking,
            segment,
            tripId
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('addDriverBreakAction', {
        id: 'addDriverBreak',
        permId: _mappedPermIds.default.addBreak,
        name: 'Add Driver Break',
        action: model => {
          const store = this.get('store');
          const route = (0, _unwrapProxy.unwrapProxy)(model.get('dispatchRoute.route'));

          if (!Ember.isEmpty(route) || !Ember.isNone(route)) {
            let plannedStartTime = (0, _moment.default)(route.get('computedPlannedStartTime'));
            const currentTime = (0, _moment.default)();

            if (currentTime.isAfter(plannedStartTime)) {
              plannedStartTime = currentTime;
            }

            const dispatchRouteVersion = route.get('dispatchRoute.version');
            const lastStop = route.get('dispatchRoute.orderedStopsWithBreaks').lastObject;
            const trips = (0, _unwrapProxy.unwrapProxy)(model.get('trip'));
            const driverBreak = store.createRecord('route-break', {
              estimatedStart: plannedStartTime.toDate(),
              requestTime: plannedStartTime.toDate(),
              route: route,
              schedule: route.get('schedule'),
              notes: '',
              plannedRouteOrdinal: Ember.isPresent(lastStop) ? lastStop.get('routeOrdinal') + 1 : 1,
              plannedDuration: 15,
              state: 'planned',
              status: 'scheduled',
              promisedStart: plannedStartTime.toDate(),
              dispatchRouteVersion: dispatchRouteVersion
            });

            if (Ember.isNone(driverBreak.get('place.id'))) {
              const locationModel = store.createRecord('location');
              const addressModel = store.createRecord('address', {
                locations: [locationModel]
              });
              const placeModel = store.createRecord('place', {
                address: addressModel,
                location: locationModel
              });
              driverBreak.set('place', placeModel);
            }

            if (Ember.isNone(driverBreak.get('stopPoint.id'))) {
              const stopPoint = store.createRecord('stop-point');
              driverBreak.set('stopPoint', stopPoint);
            }

            const saveDriverBreakFunction = this.get('saveDriverBreakFunction').bind(this);
            this.get('workspace').pushState('addBreak', {
              driverBreak,
              trips,
              saveDriverBreakFunction
            });
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('delDriverBreakAction', {
        id: 'deleteDriverBreak',
        permId: _mappedPermIds.default.deleteBreak,
        name: 'Remove Driver Break',
        action: async model => {
          const dispatchRouteAdapter = this.get('store').adapterFor('dispatch-route');
          const routeBreak = model.get('routeBreak');
          const routeBreakId = routeBreak.get('id');
          const dispatchRouteId = routeBreak.get('route.dispatchRoute.id');
          const tooltip = this.get('tooltip');
          const tip = 'Are you sure you want to Remove Driver Break';
          const title = 'Remove Driver Break';
          const dispatchRouteVersion = routeBreak.get('route.dispatchRoute.version');
          const dispatchRoute = routeBreak.get('route.dispatchRoute');
          const lockRouteAction = this.get('lockRouteAction').bind(this);
          const unlockRouteAction = this.get('unlockRouteAction').bind(this);
          this.set('dispatchRouteToCheck', dispatchRoute);

          try {
            // Check dispatchRoute version first
            await this.get('versionCheckOperation').perform();
            await lockRouteAction();
            tooltip.pushConfirmation({
              tip,
              title,
              hasOverlay: true,
              primaryActionText: 'Confirm',
              secondaryActionText: 'Cancel',
              primaryAction: async () => {
                try {
                  await dispatchRouteAdapter.removeDriverBreak(dispatchRouteId, routeBreakId, dispatchRouteVersion);
                  this.get('notifications').success(`Successfully removed driver break B${routeBreakId} from route ${routeBreak.get('route.name')}`);
                } catch (error) {
                  let msg = `Failed to remove driver break B${routeBreakId}`;

                  if (error.message) {
                    if (error.message.includes('modified by another request')) msg = `UNABLE TO REMOVE BREAK B${routeBreakId}, BECAUSE ITS ROUTE ${routeBreak.get('route.name')} HAS BEEN MODIFIED BY ANOTHER REQUEST.`;else if (error.message.includes('Unable to lock entities')) msg = 'Obtain route lock failed at this time, please retry operation.';
                  }

                  this.get('notifications').warning(msg);
                } finally {
                  await unlockRouteAction(0);
                  tooltip.reset();
                }
              },
              secondaryAction: async () => {
                await unlockRouteAction(0);
                tooltip.reset();
                return;
              },
              closeAction: async () => {
                await unlockRouteAction(0);
              }
            });
          } catch (e) {
            await unlockRouteAction();
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editDriverBreakAction', {
        id: 'editDriverBreak',
        permId: _mappedPermIds.default.editBreak,
        name: 'Edit',
        action: model => {
          const store = this.get('store');
          const driverBreak = (0, _unwrapProxy.unwrapProxy)(model.get('routeBreak'));
          const estimatedStart = (0, _moment.default)(Ember.get(driverBreak, 'estimatedStart'));
          const estimatedEnd = (0, _moment.default)(Ember.get(driverBreak, 'estimatedEnd'));
          const dispatchRouteVersion = driverBreak.get('route.dispatchRoute.version');
          const stopPointETA = Ember.isPresent(driverBreak.get('stopPoint.eta')) ? driverBreak.get('stopPoint.eta') : driverBreak.get('requestTime');
          driverBreak.set('plannedDuration', estimatedEnd.diff(estimatedStart, 'minutes')); // keep the original planned Break times

          driverBreak.set('originalPlannedBreakStartTime', estimatedStart);
          driverBreak.set('originalPlannedBreakEndTime', estimatedEnd);
          driverBreak.set('originalPlannedDuration', driverBreak.get('plannedDuration'));
          driverBreak.set('dispatchRouteVersion', dispatchRouteVersion);
          driverBreak.set('requestTime', stopPointETA); // for edit break, use the ETA instead of original requestTime. This is for validation when modifying the duration

          if (Ember.isNone(driverBreak.get('place.id'))) {
            const locationModel = store.createRecord('location');
            const addressModel = store.createRecord('address', {
              locations: [locationModel]
            });
            const placeModel = store.createRecord('place', {
              address: addressModel,
              location: locationModel
            });
            driverBreak.set('place', placeModel);
          } else {
            driverBreak.set('place.address.freeformAddress', driverBreak.get('place.address.fullAddress'));
          }

          this.get('records').pushObject(driverBreak);
          const editComponent = this.get('driverBreakEditComponent');
          this.get('editModal').open(editComponent, [driverBreak], this.get('saveDriverBreakFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('reorderStopsAction', {
        id: 'reorderStops',
        permId: _mappedPermIds.default.reorderStops,
        name: 'Reorder Stops',
        action: model => {
          const widgetRecords = this.get('widgetRecords');
          const widget = this.get('widget');
          const sortByStr = 'stopBreakOrd'; // route ordinal

          const dispatchRoute = model.get('dispatchRoute');
          const stopBreakOrdinals = {}; // keep the original stopBreakOrd before going to re-ordering mode

          for (let i = 0; i < widgetRecords.length; i++) {
            stopBreakOrdinals[widgetRecords[i].id] = widgetRecords[i].stopBreakOrd;
          }

          this.get('stopsReorder').startReorder(widget, sortByStr, dispatchRoute, null, stopBreakOrdinals);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('navigateToAction', {
        id: 'navigateTo',
        permId: _mappedPermIds.default.navigateStop,
        name: 'Navigate To',
        action: model => {
          const tooltip = this.get('tooltip');
          const fullAddress = _environment.default.APP.avlmLite ? model.get('place.fullAddress') : model.get('place.address.fullAddress');
          const selectedWidgetRecord = this.get('selectedWidgetRecord');
          const recordAddress = `${selectedWidgetRecord.address || ''}, ${selectedWidgetRecord.city || ''}`.trim();
          const displayAddress = recordAddress || fullAddress;
          tooltip.pushConfirmation({
            title: 'Navigate To',
            tip: 'Navigate To ' + displayAddress,
            primaryActionText: 'Yes',
            secondaryActionText: 'No',
            secondaryAction: () => {
              tooltip.reset();
            },
            primaryAction: () => {
              tooltip.reset();
              this.set('loading', true);
              this.roadSupervisor.enter().finally(() => this.set('loading', false));
              this.get('roadSupervisor').navigateToStop(model);
            }
          });
        },
        disableOnLockedRoute: true
      });
      this.set('bulkPerformAction', {
        id: 'bulkPerform',
        permIds: [_mappedPermIds.default.markStopArrived, _mappedPermIds.default.markStopDeparted],
        name: 'Bulk Perform',
        action: model => {
          const dispatchRoute = model.get('dispatchRoute');
          const unperformedIQStops = dispatchRoute.findUnperformedStopsInSequence(model);
          const changesetIQStops = unperformedIQStops.map(stop => new _emberChangeset.default(stop));
          const changesetModel = new _emberChangeset.default(model);
          const driverBreak = (0, _unwrapProxy.unwrapProxy)(model.get('routeBreak'));
          const store = this.get('store');
          const type = model.get('stopType');

          if (type === 'break') {
            if (Ember.isNone(driverBreak.get('place.id'))) {
              const locationModel = store.createRecord('location');
              const addressModel = store.createRecord('address', {
                locations: [locationModel]
              });
              const placeModel = store.createRecord('place', {
                address: addressModel,
                location: locationModel
              });
              driverBreak.set('startPlace', placeModel);
            } else if (driverBreak.get('startPlace')) {
              driverBreak.set('startPlace.address.freeformAddress', driverBreak.get('place.address.fullAddress'));
            }

            const locationModel = store.createRecord('location');
            const addressModel = store.createRecord('address', {
              locations: [locationModel]
            });
            const endPlaceModel = store.createRecord('place', {
              address: addressModel,
              location: locationModel
            });
            driverBreak.set('endPlace', endPlaceModel);
          }

          if (unperformedIQStops.length > 1) {
            this.get('workspace').pushState('performAllStop', {
              stopPoints: changesetIQStops,
              dispatchRoute: dispatchRoute,
              lastStopPoint: model,
              dispatchRouteVersionSnapshot: dispatchRoute.get('version')
            }, {
              replaceSelf: true
            });
            return;
          }

          switch (model.get('stopType')) {
            case 'pick':
              {
                this.get('workspace').pushState('performPickStop', {
                  stopPoint: changesetModel,
                  dispatchRouteVersionSnapshot: dispatchRoute.get('version')
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'drop':
              {
                this.get('workspace').pushState('performDropStop', {
                  stopPoint: changesetModel,
                  dispatchRouteVersionSnapshot: dispatchRoute.get('version')
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'pulloutGarage':
              {
                const route = model.get('dispatchRoute.route');
                const routesStartComponent = this.get('routesStartComponent');
                route.set('odometer', '');
                route.set('timestamp', new Date());
                this.get('editModal').open(routesStartComponent, [route], this.get('saveRouteStartRecordFunction').bind(this));
                break;
              }

            case 'pullinGarage':
              {
                const route = model.get('dispatchRoute.route');
                const routesEndComponent = this.get('routesEndComponent');
                route.set('odometerInMiles', '');
                route.set('odometer', '');
                route.set('timestamp', new Date());
                this.get('editModal').open(routesEndComponent, [route], null, null, this.get('confirmSaveRouteEndFunction').bind(this));
                break;
              }

            case 'break':
              {
                this.get('workspace').pushState('performDriverBreak', {
                  stopPoint: changesetModel,
                  dispatchRouteVersionSnapshot: dispatchRoute.get('version')
                }, {
                  replaceSelf: true
                });
                break;
              }

            default:
              break;
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('performAction', {
        id: 'perform',
        permIds: [_mappedPermIds.default.markStopArrived, _mappedPermIds.default.markStopDeparted],
        name: 'Perform',
        action: model => {
          const dispatchRoute = model.get('dispatchRoute');
          const changesetModel = new _emberChangeset.default(model);
          const driverBreak = (0, _unwrapProxy.unwrapProxy)(model.get('routeBreak'));
          const store = this.get('store');
          const type = model.get('stopType');

          if (type === 'break') {
            if (Ember.isNone(driverBreak.get('place.id'))) {
              const locationModel = store.createRecord('location');
              const addressModel = store.createRecord('address', {
                locations: [locationModel]
              });
              const placeModel = store.createRecord('place', {
                address: addressModel,
                location: locationModel
              });
              driverBreak.set('startPlace', placeModel);
            } else if (driverBreak.get('startPlace')) {
              driverBreak.set('startPlace.address.freeformAddress', driverBreak.get('place.address.fullAddress'));
            }

            const locationModel = store.createRecord('location');
            const addressModel = store.createRecord('address', {
              locations: [locationModel]
            });
            const endPlaceModel = store.createRecord('place', {
              address: addressModel,
              location: locationModel
            });
            driverBreak.set('endPlace', endPlaceModel);
          }

          switch (model.get('stopType')) {
            case 'pick':
              {
                this.get('workspace').pushState('performPickStop', {
                  stopPoint: changesetModel,
                  dispatchRouteVersionSnapshot: dispatchRoute.get('version')
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'drop':
              {
                this.get('workspace').pushState('performDropStop', {
                  stopPoint: changesetModel,
                  dispatchRouteVersionSnapshot: dispatchRoute.get('version')
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'pulloutGarage':
              {
                const route = model.get('dispatchRoute.route');
                const routesStartComponent = this.get('routesStartComponent');
                route.set('odometer', '');
                route.set('timestamp', new Date());
                this.get('editModal').open(routesStartComponent, [route], this.get('saveRouteStartRecordFunction').bind(this));
                break;
              }

            case 'pullinGarage':
              {
                const route = model.get('dispatchRoute.route');
                const routesEndComponent = this.get('routesEndComponent');
                route.set('odometerInMiles', '');
                route.set('odometer', '');
                route.set('timestamp', new Date());
                this.get('editModal').open(routesEndComponent, [route], null, null, this.get('confirmSaveRouteEndFunction').bind(this));
                break;
              }

            case 'break':
              {
                this.get('workspace').pushState('performDriverBreak', {
                  stopPoint: changesetModel,
                  dispatchRouteVersionSnapshot: dispatchRoute.get('version')
                }, {
                  replaceSelf: true
                });
                break;
              }

            default:
              break;
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('unPerformAction', {
        id: 'unperform',
        permId: _mappedPermIds.default.unperformStop,
        name: 'Unperform',
        action: model => {
          const workspace = this.get('workspace');
          const dispatchRoute = model.get('dispatchRoute');
          let stopType = model.get('stopType');
          let title = `Unperform ${Ember.String.capitalize(stopType)} Stop`;

          if (model.get('isNoShow')) {
            const trip = (0, _unwrapProxy.unwrapProxy)(model.get('trip'));
            stopType = trip.get('pickStopPoint.stopType'); // eslint-disable-next-line no-param-reassign

            model = (0, _unwrapProxy.unwrapProxy)(trip.get('pickStopPoint'));
            title = 'Unperform No Show';
            workspace.pushState('unPerformNoShow', {
              stopPoint: model,
              title,
              dispatchRouteVersionSnapshot: dispatchRoute.get('version')
            }, {
              replaceSelf: true
            });
            return;
          }

          if (stopType === 'break') {
            title = 'Unperform Break';
          }

          switch (stopType) {
            case 'pick':
              {
                workspace.pushState('unPerformPickStop', {
                  stopPoint: model,
                  title,
                  dispatchRouteVersionSnapshot: dispatchRoute.get('version')
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'drop':
              {
                workspace.pushState('unPerformDropStop', {
                  stopPoint: model,
                  title,
                  dispatchRouteVersionSnapshot: dispatchRoute.get('version')
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'break':
              {
                workspace.pushState('unPerformBreak', {
                  stopPoint: model,
                  title,
                  dispatchRouteVersionSnapshot: dispatchRoute.get('version')
                }, {
                  replaceSelf: true
                });
                break;
              }

            default:
              break;
          }
        },
        disableOnLockedRoute: true
      });
      this.set('undoEndRoute', {
        id: 'undoEndRoute',
        permId: _mappedPermIds.default.undoRouteEnd,
        name: 'Undo Route End',
        action: model => {
          const route = model.get('route');
          route.set('odometerInMiles', '');
          route.set('timestamp', new Date());
          this.confirmSaveUndoRouteEndFunction(route);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('bulkActions', [{
        id: 'bulkEdit',
        permId: _mappedPermIds.default.editStop,
        name: 'Bulk Edit',
        action: models => {
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, models);
        },
        disableOnLockedRoute: true
      }]);
    },

    _initScheduleSingleActions() {
      this.set('cancelAction', {
        id: 'cancelTrip',
        permIds: [_mappedPermIds.default.cancelFuture, _mappedPermIds.default.cancelSameDay],
        name: 'Cancel Trip',
        action: model => {
          const trip = (0, _unwrapProxy.unwrapProxy)(model.get('trip'));
          const tripId = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
          const booking = (0, _unwrapProxy.unwrapProxy)(trip.get('booking'));
          const segment = (0, _unwrapProxy.unwrapProxy)(trip.get('segment'));
          booking.set('timestamp', new Date());
          this.get('workspace').pushState('cancelTripForm', {
            displayName: 'Cancel Trip',
            trip,
            booking,
            segment,
            tripId
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('schedulingAddDriverBreakAction', {
        id: 'addDriverBreak',
        permId: _mappedPermIds.default.addBreak,
        name: 'Add Driver Break',
        action: model => {
          // stopPoint.type of garage pullout and pullin does not have trip stop model, just stopPoint model
          // and has a relationship with stopRoute.
          const route = (0, _unwrapProxy.unwrapProxy)(model.get('route') ? model.get('route') : model.get('stopRoute'));
          this.get('workspace').pushState('schedulingAddBreak', {
            route,
            displayName: 'Add Driver Break',
            isEditMode: false
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editDriverBreakAction', {
        id: 'editDriverBreak',
        permId: _mappedPermIds.default.editBreak,
        name: 'Edit',
        action: model => {
          const route = model;
          this.get('workspace').pushState('schedulingAddBreak', {
            route,
            displayName: 'Edit Driver Break',
            isEditMode: true
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('reorderStopsAction', {
        id: 'reorderStops',
        permId: _mappedPermIds.default.reorderStops,
        name: 'Reorder Stops',
        action: model => {
          const widgetRecords = this.get('widgetRecords');
          const widget = this.get('widget');
          const sortByStr = 'scheduleOrdinal'; // route ordinal

          const route = model.get('route');
          const stopBreakOrdinals = {}; // keep the original stopBreakOrd before going to re-ordering mode

          for (let i = 0; i < widgetRecords.length; i++) {
            stopBreakOrdinals[widgetRecords[i].id] = widgetRecords[i].scheduleOrdinal;
          }

          this.get('stopsReorder').startReorder(widget, sortByStr, null, route, stopBreakOrdinals);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('delDriverBreakAction', {
        id: 'deleteDriverBreak',
        permId: _mappedPermIds.default.deleteBreak,
        name: 'Remove Driver Break',
        action: model => {
          const routeBreakId = model.get('id');
          const routeName = model.get('routeName');
          const tooltip = this.get('tooltip');
          const tip = 'Are you sure you want to Remove Driver Break';
          const title = 'Remove Driver Break';
          const notifications = this.get('notifications');
          tooltip.pushConfirmation({
            tip,
            title,
            hasOverlay: true,
            primaryActionText: 'Confirm',
            secondaryActionText: 'Cancel',
            primaryAction: async () => {
              return this.performScheduleCancelRouteBreak.perform(routeBreakId).then(() => {
                notifications.success(`Successfully removed driver break from route ${routeName}`);
              }).catch(() => {
                notifications.warning(`Failed to remove driver break from route ${routeName}`);
              }).finally(() => {
                tooltip.reset();
                this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['model']);
              });
            },

            secondaryAction() {
              tooltip.reset();
              return;
            }

          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
    },

    _dynamicAVLMActions() {
      const noshowAction = this.get('noShowAction');
      const breakEditAction = this.get('editDriverBreakAction');
      const breakAddAction = this.get('addDriverBreakAction');
      const breakDelAction = this.get('delDriverBreakAction');
      const reorderAction = this.get('reorderStopsAction');
      const navigateToAction = this.get('navigateToAction');
      const performAction = this.get('performAction');
      const unperformAction = this.get('unPerformAction');
      const row = this.getSelectedCoreModel();
      const record = row;
      const stopType = row ? row.get('stopType') : null;
      if (!row) return [];

      if (this.get('isReordering')) {
        return [];
      }

      this.appendStopTypeToActionLabel(unperformAction, record); // there's an off chance the avlm-route-exec event won't update the route status to started / in-progress to keep no-show available

      let routeStarted = [_dispatchRoute.ROUTE_IN_PROGRESS_STATUS, _dispatchRoute.ROUTE_STARTED_STATUS, _dispatchRoute.ROUTE_AWAITING_STATUS].includes(row.get('dispatchRoute.computedStatus'));
      let route = record.get('dispatchRoute') || record.get('avlmCluster.route');
      let routeChecked = false;
      let isPerformedTripStops = false;

      if (route) {
        const model = route;
        routeChecked = this.get('activeContext.topActiveContext').isRecordChecked(model);
      }

      const multiRecordsChecked = this.get('activeContext.topActiveContext').isMultiRecordsChecked(route);
      const isTimelineSpanMultipleDays = this.get('workspace.isTimelineSpanMultipleDays');
      let reorderEligible = false;

      if (routeChecked && !multiRecordsChecked && !isTimelineSpanMultipleDays) {
        reorderEligible = true;
      }

      const isRoleSupervisor = this.user.isRoadSupEnable(false); //Update isPerformedTripStops flag and Check whether any stop is performed after current stop.

      if (row.get('computedStatus') && !Ember.isEmpty(route) && !Ember.isEmpty(route.get('orderedStopsWithGaragesAndBreaks'))) {
        const allStops = route.get('orderedStopsWithGaragesAndBreaks');
        isPerformedTripStops = allStops.some(item => item.get('routeOrdinal') > row.get('routeOrdinal') && item.get('isPerformedOrBeingPerformed'));
      }

      switch (stopType) {
        case 'pick':
          {
            switch (row.get('status')) {
              case 'Arrive':
                {
                  if (isRoleSupervisor) {
                    return [breakAddAction, noshowAction, performAction, navigateToAction];
                  }

                  return [breakAddAction, noshowAction, performAction];
                }

              case 'Depart':
                {
                  if (isRoleSupervisor) {
                    if (isPerformedTripStops) {
                      return [breakAddAction, navigateToAction];
                    }

                    return [breakAddAction, navigateToAction, unperformAction];
                  }

                  if (isPerformedTripStops) {
                    return [breakAddAction];
                  }

                  return [breakAddAction, unperformAction];
                }

              case 'Scheduled':
                {
                  if (row.get('actualArrivalTime') === null || Ember.isNone(row.get('actualArrivalTime'))) {
                    return [breakAddAction];
                  }

                  return [breakAddAction];
                }

              case 'Cancelled':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              case 'No Show':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  if (isPerformedTripStops) {
                    return [breakAddAction];
                  }

                  return [unperformAction, breakAddAction];
                }

              case 'Pending':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              default:
                {
                  if (routeStarted) {
                    return [breakAddAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (reorderEligible) {
                      if (routeStarted) {
                        return [breakAddAction, performAction];
                      }

                      return [breakAddAction, reorderAction, performAction];
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [breakAddAction, performAction];
                  }

                  return [performAction];
                }
            }
          }

        case 'drop':
          {
            switch (row.get('status')) {
              case 'Arrive':
                {
                  if (isRoleSupervisor) {
                    return [breakAddAction, performAction, navigateToAction];
                  }

                  return [breakAddAction, performAction];
                }

              case 'Depart':
                {
                  if (isRoleSupervisor) {
                    if (isPerformedTripStops) {
                      return [breakAddAction, navigateToAction];
                    }

                    return [breakAddAction, navigateToAction, unperformAction];
                  }

                  if (isPerformedTripStops) {
                    return [breakAddAction];
                  }

                  return [breakAddAction, unperformAction];
                }

              case 'Cancelled':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              case 'No Show':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  if (isPerformedTripStops) {
                    return [breakAddAction];
                  }

                  return [unperformAction, breakAddAction];
                }

              case 'Pending':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              default:
                {
                  if (routeStarted) {
                    return [breakAddAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (reorderEligible) {
                      if (routeStarted) {
                        return [breakAddAction, performAction];
                      }

                      return [breakAddAction, reorderAction, performAction];
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [breakAddAction, performAction];
                  }

                  return [];
                }
            }
          }

        case 'break':
          {
            routeStarted = ['In Progress', 'Started'].includes(row.get('dispatchRoute.computedStatus'));
            route = row.get('dispatchRoute');

            switch (row.get('routeBreak.status')) {
              case 'OnBreak':
                return [breakAddAction, performAction];

              case 'OffBreak':
                return [breakAddAction];

              default:
                {
                  if (routeStarted) {
                    return [breakAddAction, breakDelAction, breakEditAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (routeChecked && !multiRecordsChecked && !routeStarted) {
                      return [breakAddAction, breakDelAction, breakEditAction, reorderAction, performAction];
                    }

                    return [breakAddAction, breakDelAction, breakEditAction, performAction];
                  }

                  return [];
                }
            }
          }

        case 'pulloutGarage':
          {
            routeStarted = ['In Progress', 'Started'].includes(row.get('dispatchRoute.computedStatus'));

            if (isRoleSupervisor) {
              return [breakAddAction, navigateToAction];
            }

            if (!routeStarted) {
              return [breakAddAction, performAction];
            }

            return [breakAddAction];
          }

        case 'pullinGarage':
          {
            routeStarted = ['In Progress', 'Started'].includes(row.get('dispatchRoute.computedStatus'));

            if (isRoleSupervisor) {
              return [breakAddAction, navigateToAction];
            }

            if (routeStarted) {
              return [breakAddAction, performAction];
            }

            return [breakAddAction];
          }

        case 'deployStop':
          {
            if (isRoleSupervisor) {
              return [navigateToAction];
            }

            return [];
          }

        default:
          {
            return [];
          }
      }
    },

    _dynamicDispatchActions() {
      const noshowAction = this.get('noShowAction');
      const breakEditAction = this.get('editDriverBreakAction');
      const breakAddAction = this.get('addDriverBreakAction');
      const cancelAction = this.get('cancelAction');
      const breakDelAction = this.get('delDriverBreakAction');
      const reorderAction = this.get('reorderStopsAction');
      const navigateToAction = this.get('navigateToAction');
      const performAction = this.get('performAction');
      const unperformAction = this.get('unPerformAction');
      const undoEndRoute = this.get('undoEndRoute');
      const row = this.getSelectedCoreModel();
      const selectedWidgetRecord = this.get('selectedWidgetRecord');
      const dispatchRoute = row.get('dispatchRoute');
      const unperformedIQStops = Ember.isPresent(dispatchRoute) ? dispatchRoute.findUnperformedStopsInSequence(row) : [];
      const isNextUnperformed = unperformedIQStops.length === 1;
      if (!row) return [];
      const record = row;
      const stopType = record.get('stopType') || selectedWidgetRecord.type;

      if (this.get('isReordering')) {
        return [];
      }

      this.appendStopTypeToActionLabel(unperformAction, record);
      let route = record.get('dispatchRoute') || record.get('avlmCluster.route');
      let routeStarted = [_dispatchRoute.ROUTE_IN_PROGRESS_STATUS, _dispatchRoute.ROUTE_STARTED_STATUS, _dispatchRoute.ROUTE_AWAITING_STATUS, _dispatchRoute.ROUTE_ON_BREAK_STATUS, _dispatchRoute.ROUTE_SIGNED_OFF_STATUS].includes(row.get('dispatchRoute.computedStatus'));
      const routeEnded = [_dispatchRoute.ROUTE_ENDED_STATUS].includes(row.get('dispatchRoute.computedStatus')) || row.get('dispatchRoute.pullinGarageStop.isPerformed');
      let routeChecked = false;
      let isPerformedTripStops = false;
      let isPickPerformedTripStops = false;

      if (route) {
        const model = route;
        routeChecked = this.get('activeContext.topActiveContext').isRecordChecked(model);
      }

      const multiRecordsChecked = this.get('activeContext.topActiveContext').isMultiRecordsChecked(route);
      let reorderEligible = false;

      if (routeChecked && !multiRecordsChecked) {
        reorderEligible = true;
      }

      const isRoleSupervisor = this.user.isRoadSupEnable(false); //Update isPerformedTripStops flag and Check whether any stop is performed after current stop.

      if (row.get('computedStatus') && !Ember.isEmpty(route) && !Ember.isEmpty(route.get('orderedStopsWithGaragesAndBreaks'))) {
        const allStops = route.get('orderedStopsWithGaragesAndBreaks');
        isPerformedTripStops = allStops.some(item => item.get('routeOrdinal') > row.get('routeOrdinal') && item.get('isPerformedOrBeingPerformed'));
      }

      if (row.get('computedStatus') && !Ember.isEmpty(route) && !Ember.isEmpty(route.get('orderedStopsWithGaragesAndBreaks'))) {
        const allStops = route.get('orderedStopsWithGaragesAndBreaks');
        isPickPerformedTripStops = allStops.some(item => item.get('isPerformedOrBeingPerformed') && !item.get('isSoftPerformed') && row.get('trip.id') === item.get('trip.id'));
      }

      switch (stopType) {
        case 'pick':
          {
            switch (selectedWidgetRecord.status) {
              case _stopPoint.ARRIVE_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return [breakAddAction, noshowAction, performAction, navigateToAction];
                  }

                  const currentTime = new Date();
                  const noShowTimeThreshold = this.get('store').peekRecord('cs-config-item', 'config-Driving_Configuration/noShowTimeThreshold');
                  const promisedTime = row.get('promisedTime');
                  const actualArriveTime = row.get('actualArriveTime');
                  const noShowAvailableTime = promisedTime && actualArriveTime ? _moment.default.max((0, _moment.default)(promisedTime), (0, _moment.default)(actualArriveTime)) : (0, _moment.default)(currentTime);

                  if (noShowTimeThreshold) {
                    row.set('noShowTimeThreshold', noShowTimeThreshold);
                    noShowAvailableTime.add(parseInt(noShowTimeThreshold.value, 10), noShowTimeThreshold.unit);
                  }

                  if ((0, _moment.default)(currentTime).isBefore(noShowAvailableTime)) {
                    return [breakAddAction, performAction];
                  }

                  return [breakAddAction, noshowAction, performAction];
                }

              case _stopPoint.PERFORMED_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    if (isPerformedTripStops) {
                      return [breakAddAction, navigateToAction];
                    }

                    return [breakAddAction, navigateToAction, unperformAction];
                  }

                  if (routeEnded) {
                    return this.addPerformOptionForSoftPerformedStops([], row);
                  }

                  if (isPerformedTripStops) {
                    return this.addPerformOptionForSoftPerformedStops([breakAddAction], row);
                  }

                  return this.addPerformOptionForSoftPerformedStops([breakAddAction, unperformAction], row);
                }

              case _stopPoint.SCHEDULED_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  if (row.get('actualArrivalTime') === null || Ember.isNone(row.get('actualArrivalTime'))) {
                    return this.addOptionsByRowConditions([breakAddAction], row);
                  }

                  return this.addOptionsByRowConditions([breakAddAction], row);
                }

              case _stopPoint.CANCELLED_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              case _stopPoint.NOSHOW_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  if (routeEnded) {
                    return this.addOptionsByRowConditions([], row);
                  }

                  if (isPerformedTripStops) {
                    return this.addOptionsByRowConditions([breakAddAction], row);
                  }

                  return this.addOptionsByRowConditions([unperformAction, breakAddAction], row);
                }

              case _stopPoint.PENDING_STOP_STATUS:
                {
                  if (routeStarted) {
                    if (reorderEligible) {
                      return this.addOptionsByRowConditions([breakAddAction, reorderAction], row);
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return this.addOptionsByRowConditions([], row);
                  }

                  if (!Ember.isNone(route)) {
                    if (reorderEligible) {
                      return routeStarted ? this.addOptionsByRowConditions([breakAddAction], row) : this.addOptionsByRowConditions([breakAddAction, reorderAction], row);
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [breakAddAction, cancelAction];
                  }

                  if (isRoleSupervisor) {
                    return this.addOptionsByRowConditions([navigateToAction], row);
                  }

                  return [cancelAction];
                }

              default:
                {
                  // const nextUnperformedStop = row.get('dispatchRoute.firstUnperformedStop');
                  if (routeStarted) {
                    if (reorderEligible) {
                      return this.addOptionsByRowConditions([breakAddAction, performAction, reorderAction], row);
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return this.addOptionsByRowConditions([], row);
                  }

                  if (!Ember.isNone(route)) {
                    if (reorderEligible) {
                      return routeStarted ? this.addOptionsByRowConditions([breakAddAction], row) : this.addOptionsByRowConditions([breakAddAction, reorderAction], row);
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [breakAddAction, cancelAction];
                  }

                  if (isRoleSupervisor) {
                    return this.addOptionsByRowConditions([navigateToAction], row);
                  }

                  return this.addOptionsByRowConditions([], row);
                }
            }
          }

        case 'drop':
          {
            switch (selectedWidgetRecord.status) {
              case _stopPoint.ARRIVE_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return this.addOptionsByRowConditions([breakAddAction, navigateToAction], row);
                  }

                  return this.addOptionsByRowConditions([breakAddAction], row);
                }

              case _stopPoint.PERFORMED_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    if (isPerformedTripStops) {
                      return [breakAddAction, navigateToAction];
                    }

                    return [breakAddAction, navigateToAction, unperformAction];
                  }

                  if (routeEnded) {
                    return this.addPerformOptionForSoftPerformedStops([], row);
                  }

                  if (isPerformedTripStops) {
                    return this.addPerformOptionForSoftPerformedStops([breakAddAction], row);
                  }

                  return this.addPerformOptionForSoftPerformedStops([breakAddAction, unperformAction], row);
                }

              case _stopPoint.CANCELLED_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              case _stopPoint.NOSHOW_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  if (routeEnded) {
                    //NYAAR-18659: In stop widget, On performing pick-stop, the cancel trip option should not be displayed for the drop stop
                    if (isPickPerformedTripStops) {
                      return [];
                    }

                    return this.addOptionsByRowConditions([], row);
                  }

                  if (isPickPerformedTripStops) {
                    return [breakAddAction];
                  }

                  return this.addOptionsByRowConditions([breakAddAction], row);
                }

              case _stopPoint.PENDING_STOP_STATUS:
                {
                  if (!Ember.isNone(route)) {
                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    if (reorderEligible) {
                      return routeStarted ? this.addOptionsByRowConditions([breakAddAction], row) : this.addOptionsByRowConditions([breakAddAction, reorderAction], row);
                    }

                    return this.addOptionsByRowConditions([breakAddAction, cancelAction], row);
                  }

                  return [cancelAction];
                }

              default:
                {
                  if (routeStarted) {
                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    if (reorderEligible) {
                      return this.addOptionsByRowConditions([breakAddAction, reorderAction], row);
                    }

                    return this.addOptionsByRowConditions([breakAddAction], row);
                  }

                  if (!Ember.isNone(route)) {
                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    if (reorderEligible) {
                      return routeStarted ? this.addOptionsByRowConditions([breakAddAction], row) : this.addOptionsByRowConditions([breakAddAction, reorderAction], row);
                    }

                    return this.addOptionsByRowConditions([breakAddAction, cancelAction], row);
                  }

                  return this.addOptionsByRowConditions([], row);
                }
            }
          }

        case 'break':
          {
            routeStarted = row.get('dispatchRoute.hasStarted');
            route = row.get('dispatchRoute');

            switch (row.get('status')) {
              case _stopPoint.ON_BREAK_STOP_STATUS:
                if (isPerformedTripStops) {
                  if (isNextUnperformed) {
                    return [breakAddAction, performAction];
                  }

                  return [breakAddAction];
                }

                if (isNextUnperformed) {
                  return [breakAddAction, performAction, unperformAction];
                }

                return [breakAddAction, performAction, unperformAction];

              case _stopPoint.OFF_BREAK_STOP_STATUS:
                if (routeEnded) {
                  return this.addOptionsByRowConditions([], row);
                }

                if (route.get('orderedStopsWithBreaks')[row.get('routeOrdinal')] && route.get('orderedStopsWithBreaks')[row.get('routeOrdinal')].isNoShow) {
                  return this.addOptionsByRowConditions([breakAddAction], row);
                }

                if (isPerformedTripStops) {
                  return this.addOptionsByRowConditions([breakAddAction], row);
                }

                return this.addOptionsByRowConditions([breakAddAction, unperformAction], row);

              case _stopPoint.PENDING_STOP_STATUS:
                {
                  if (routeStarted) {
                    if (reorderEligible) {
                      return [breakAddAction, breakDelAction, breakEditAction, performAction, reorderAction];
                    }

                    return [breakAddAction, breakDelAction, breakEditAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (routeChecked && !multiRecordsChecked && !routeStarted && reorderEligible) {
                      return [breakAddAction, breakDelAction, breakEditAction, reorderAction];
                    }

                    return [breakAddAction, breakDelAction, breakEditAction];
                  }

                  return [];
                }

              default:
                {
                  if (routeStarted) {
                    if (reorderEligible) {
                      return [breakAddAction, breakDelAction, breakEditAction, performAction, reorderAction];
                    }

                    return [breakAddAction, breakDelAction, breakEditAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (routeChecked && !multiRecordsChecked && !routeStarted) {
                      return [breakAddAction, breakDelAction, breakEditAction, reorderAction];
                    }

                    return [breakAddAction, breakDelAction, breakEditAction];
                  }

                  return [];
                }
            }
          }

        case 'pulloutGarage':
          {
            routeStarted = row.get('dispatchRoute.hasStarted');

            if (isRoleSupervisor) {
              return [breakAddAction, navigateToAction];
            }

            if (row.get('dispatchRoute.assignedDriver.content') && row.get('dispatchRoute.assignedVehicle.content') && !row.get('dispatchRoute.assignedVehicle.content.placeholder') && !routeStarted) {
              return [breakAddAction, performAction];
            }

            if (routeEnded) {
              return [];
            }

            return [breakAddAction];
          }

        case 'pullinGarage':
          {
            routeStarted = row.get('dispatchRoute.hasStarted');
            const allStopsPerformed = row.get('dispatchRoute').allStopsPerformed();

            if (isRoleSupervisor) {
              return [breakAddAction, navigateToAction];
            }

            if (routeStarted) {
              if (routeEnded || row.get('computedStatus') === _stopPoint.PULLED_IN_STOP_STATUS) {
                return [undoEndRoute];
              }

              if (allStopsPerformed) {
                return [breakAddAction, performAction];
              }

              return [breakAddAction];
            }

            return [breakAddAction];
          }

        default:
          {
            if (isRoleSupervisor) {
              return [navigateToAction];
            }

            return [];
          }
      }
    },

    _dynamicScheduleActions() {
      const row = this.getSelectedCoreModel();
      const selectedWidgetRecord = this.get('selectedWidgetRecord');
      if (!row) return [];
      const record = row;
      const stopType = record.get('stopType') || selectedWidgetRecord.type;
      const isBusOrWalkTravelMode = row.get('trip.segment.isBusOrWalkTravelMode'); //NYAAR-19327:No right click context menu options for bus/or walk stops

      if (isBusOrWalkTravelMode) return [];

      switch (stopType) {
        case 'pick':
        case 'drop':
          {
            const trip = (0, _unwrapProxy.unwrapProxy)(record.get('trip'));
            const route = (0, _unwrapProxy.unwrapProxy)(trip.get('route'));
            const tripStatus = trip ? `${trip.get('computedStatus')}`.toLowerCase() : selectedWidgetRecord.status.toLowerCase();
            return this.getScheduleTripActions(tripStatus, stopType, trip, route);
          }

        case 'pullinGarage':
        case 'pulloutGarage':
        case 'break':
          {
            const route = (0, _unwrapProxy.unwrapProxy)(record.get('route'));
            const tripStatus = selectedWidgetRecord.status.toLowerCase();
            return this.getScheduleTripActions(tripStatus, stopType, null, route);
          }

        default:
          return [];
      }
    },

    getScheduleTripActions: function (tripStatus, stopType, trip, route) {
      const cancelTripAction = this.get('cancelAction');
      const editDriverBreakAction = this.get('editDriverBreakAction');
      const delDriverBreakAction = this.get('delDriverBreakAction');
      const schedulingAddDriverBreakAction = this.get('schedulingAddDriverBreakAction');
      const reorderAction = this.get('reorderStopsAction');
      const widgetModelName = this.get('config.widgetName');
      const isMultiRecordsChecked = this.get('widgetService').isMultiRecordsSelected(widgetModelName);
      const isTaxiTravelMode = !trip ? false : trip.get('segment.isTaxiTravelMode');
      let routeChecked = false;

      if (route) {
        routeChecked = this.get('activeContext.topActiveContext').isRecordChecked(route);
      }

      const multiRecordsChecked = this.get('activeContext.topActiveContext').isMultiRecordsChecked(route);
      let reorderEligible = false;

      if (routeChecked && !multiRecordsChecked) {
        reorderEligible = true;
      }

      switch (tripStatus) {
        //trip status is completed,canceled and no show restrict cancel-trip and add-driver-break actions
        case 'completed':
        case 'canceled':
        case 'no show':
          return [];
        //trip status is illegal show cancel-trip action

        case 'waitlisted':
        case 'illegal':
        case 'callback':
          {
            return [cancelTripAction];
          }

        default:
          if (isMultiRecordsChecked) {
            return [];
          } else if (stopType === 'break') {
            if (reorderEligible) {
              return [schedulingAddDriverBreakAction, editDriverBreakAction, delDriverBreakAction, reorderAction];
            }

            return [schedulingAddDriverBreakAction, editDriverBreakAction, delDriverBreakAction];
          } else if (stopType === 'pullinGarage' || stopType === 'pulloutGarage') {
            return [schedulingAddDriverBreakAction];
          } else if (isTaxiTravelMode) {
            return [cancelTripAction];
          }

          if (reorderEligible) {
            return [cancelTripAction, schedulingAddDriverBreakAction, reorderAction];
          }

          return [cancelTripAction, schedulingAddDriverBreakAction];
      }
    },

    calculateTimeQuery() {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      const dateRangeFilterQuery = (0, _rqlGenerators.dateBetweenRQLUsingFormat)('promiseTime', [startTime, endTime], 'YYYY-MM-DDTHH:mm:ss.SSS');
      return dateRangeFilterQuery;
    },

    async getUserName(currRowData) {
      const lock = currRowData.lock;
      const store = this.get('store');
      let ssoUser = store.peekRecord('sso-user', lock.user);

      if (Ember.isNone(ssoUser)) {
        ssoUser = await store.find('sso-user', lock.user).catch(error => {
          const errorCode = Ember.isPresent(error.errors.get('firstObject').status) ? error.errors.get('firstObject').status : null; // This is a temporary hack because continuous otimization user key does not exist in sso
          // must remove after a permanent solution is found in locking in continuous optimization

          if (errorCode === '404') {
            currRowData.lock.fullName = 'Continuous Optimization';

            if (!store.hasRecordForId('sso-user', lock.user)) {
              return store.createRecord('sso-user', {
                id: lock.user,
                userId: lock.user,
                fullName: 'Continuous Optimization'
              });
            }
          }

          return null;
        });
      }

      if (!Ember.isNone(ssoUser)) {
        currRowData.lock.fullName = ssoUser.fullName;
      }
    },

    applyRouteLockInfoTransformation(widgetRecord) {
      if (widgetRecord.isLocked) {
        this.getUserName(widgetRecord);
      }
    },

    // server dependency table overwrites
    applyTransformationData(widgetRecords) {
      const isScheduleDashboard = this.get('workspace.isScheduleDashboard');
      widgetRecords.forEach(widgetRecord => {
        if (!widgetRecord._transformed) {
          this.applyOtpStatusTransformation(widgetRecord);
          this.applyStopTypeTransformation(widgetRecord);
          this.applyOdometerTransformation(widgetRecord);
          this.applyStopSegmentPhoneNumbersTransformation(widgetRecord);
          this.applyRouteLockInfoTransformation(widgetRecord);

          if (_environment.default.APP.avlmLite) {
            this.applySlackTimeTransformation(widgetRecord);
            this.applyStopIdTransformation(widgetRecord);
          } else if (isScheduleDashboard) {
            this.applySchedulingSlackTimeTransformation(widgetRecord);
            this.applyScheduleStopIdTransformation(widgetRecord);
            this.applyCoreModelTransformation(widgetRecord);
            this.applyTripCancelTransformation(widgetRecord);
            this.applyEtaTransformation(widgetRecord);
          } else {
            this.applyStopPointPkTransformation(widgetRecord);
          }

          this.applyStopBreakOrd(widgetRecord);
          widgetRecord._transformed = true;
        }
      });
      return widgetRecords;
    },

    applyStopSegmentPhoneNumbersTransformation(widgetRecord) {
      const segmentStopPhoneNumbers = widgetRecord.segmentStopPhoneNumbers;
      const phoneNumbers = [];

      if (Ember.isPresent(segmentStopPhoneNumbers)) {
        segmentStopPhoneNumbers.forEach(function (item) {
          phoneNumbers.push((0, _formatTextExtension.formatTextExtension)('valueItem', `${(0, _formatFunction.formatPhoneNumber)(item.areaCode, item.phoneNumber, item.extension)}`));
        });
        widgetRecord.formattedSegmentStopPhoneNumbers = {
          values: phoneNumbers
        };
        widgetRecord.segmentStopPhoneNumbers = segmentStopPhoneNumbers.map(phoneNumber => `${(0, _formatFunction.formatPhoneNumber)(phoneNumber.areaCode, phoneNumber.phoneNumber, phoneNumber.extension)}`);
      }
    },

    applyCoreModelTransformation(widgetRecord) {
      const coreModelName = this.get('config.modelName');

      if (this.get('getCoreModelNameByWidgetRecord')) {
        widgetRecord.coreModel = this.get('getCoreModelNameByWidgetRecord')(coreModelName, widgetRecord);
      }
    },

    rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currRowData) {
      let rowStyleHandler = '';

      if (currRowData.isLocked) {
        rowStyleHandler += ' is-locked';
      }

      if (columnIndex === 0) {
        if (currRowData.otpStatus === 'Late') {
          rowStyleHandler += ' otp-late-first-column';
        }

        if (currRowData.otpStatus === 'On Time') {
          rowStyleHandler += ' otp-on-time-first-column';
        }

        if (currRowData.otpStatus === 'In Danger') {
          rowStyleHandler += ' otp-in-danger-first-column';
        }
      }

      return rowStyleHandler;
    },

    canFurtherSelectionAllowed() {
      const gridConfig = this.get('config');
      const maxLimitByRouteSelection = gridConfig.maxLimitByRouteSelection;
      const maxLimitWithoutRouteAssociation = gridConfig.maxLimitWithoutRouteAssociation;
      const selectedStopRecords = this.get('widgetService').getSelectedWidgetRecordsByModel('stopWidget');
      const selectedRouteSet = new Set();
      selectedStopRecords.forEach(data => {
        if (data.routeId) {
          selectedRouteSet.add(data.routeId);
        }
      });

      if (selectedRouteSet.size >= maxLimitByRouteSelection || selectedStopRecords.length >= maxLimitWithoutRouteAssociation) {
        return false;
      }

      return true;
    },

    applySchedulingSlackTimeTransformation(widgetRecord) {
      if (!widgetRecord.plannedSlackTime || typeof widgetRecord.plannedSlackTime !== 'number') {
        // set default to 0
        widgetRecord.plannedSlackTime = 0;
      }
    },

    applySlackTimeTransformation(widgetRecord) {
      if (widgetRecord.slack && typeof widgetRecord.slack === 'number') {
        // truncate instead of round so we can filter properly because of min to sec conversion (applySlackFilterConversion).
        widgetRecord.slack = Math.trunc(widgetRecord.slack / 60);
      } else {
        // set default to 0
        widgetRecord.slack = 0;
      }
    },

    // use stopBreakOrd for stopBreakOrd column instead of the original ordinal from
    // iqux-service when not in re-ordering mode.
    // Use stopBreakOrdinals from stopsReorder service. This is to prevent updates of ordinal
    // coming from iqux-service while in re-ordering mode
    applyStopBreakOrd(widgetRecord) {
      const isScheduleDashboard = this.get('workspace.isScheduleDashboard'); // If the record were updated by redis, ordinal could be a string which will affect sorting.

      widgetRecord.ordinal = isNaN(widgetRecord.ordinal) ? widgetRecord.ordinal : Number(widgetRecord.ordinal);
      widgetRecord.scheduleOrdinal = isNaN(widgetRecord.scheduleOrdinal) ? widgetRecord.scheduleOrdinal : Number(widgetRecord.scheduleOrdinal);

      if (!this.get('isReordering')) {
        widgetRecord.stopBreakOrd = isScheduleDashboard ? widgetRecord.scheduleOrdinal : widgetRecord.ordinal;
      } else {
        const stopBreakOrdinals = this.get('stopsReorder.stopBreakOrdinals');

        if (Ember.isPresent(stopBreakOrdinals)) {
          widgetRecord.stopBreakOrd = stopBreakOrdinals[widgetRecord.id];
        }
      }
    },

    applyOdometerTransformation(widgetRecord) {
      if (widgetRecord.odometerOnArrival) {
        widgetRecord.odometerOnArrival = Math.round(widgetRecord.odometerOnArrival * 0.000621371192);
      }

      if (widgetRecord.odometerOnArrival === 0) {
        widgetRecord.odometerOnArrival = null;
      }
    },

    applyStopIdTransformation(widgetRecord) {
      if (!widgetRecord.id) return;

      if (widgetRecord.id.startsWith('SP')) {
        widgetRecord.id = widgetRecord.id.replace('SP', 'P');
      }

      if (widgetRecord.id.startsWith('SD')) {
        widgetRecord.id = widgetRecord.id.replace('SD', 'D');
      } // TODO: remove workaround for break point id not matched.


      if (widgetRecord.type === 'break' && !widgetRecord.id.startsWith('B')) {
        widgetRecord.id = 'B' + widgetRecord.id;
      }
    },

    applyStopPointPkTransformation(widgetRecord) {
      widgetRecord.stopPointId = widgetRecord.id;

      if (widgetRecord.stopPointPk) {
        widgetRecord.id = widgetRecord.stopPointPk;
      }
    },

    applyScheduleStopIdTransformation(widgetRecord) {
      if (widgetRecord.type === 'break' || widgetRecord.type === 'pick' || widgetRecord.type === 'drop') {
        widgetRecord.stopPk = widgetRecord.id;
        widgetRecord.id = widgetRecord.id.substr(1);
      }
    },

    applyStopTypeTransformation(widgetRecord) {
      try {
        if (!widgetRecord.type && widgetRecord.id) {
          if (widgetRecord.id.includes('GP')) {
            widgetRecord.type = 'pulloutGarage';
          } else if (widgetRecord.id.includes('GD')) {
            widgetRecord.type = 'pullinGarage';
          } else if (widgetRecord.id.includes('B')) {
            widgetRecord.type = 'break';
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },

    /**
     * Regarding NYAAR-16231
     * @param widgetRecord
     */
    applyTripCancelTransformation(widgetRecord) {
      if (!widgetRecord.hasOwnProperty('tripCanceled')) {
        widgetRecord.tripCanceled = 'null';
      }
    },

    /**
     * Stop record with status 'R' should not be displayed
     * @param header
     * @param body
     * @returns {string|*}
     */
    determineWidgetRecordAction(widgetRecords, header, data) {
      if (data.status === 'R') {
        return 'DELETE';
      }

      return this._super(...arguments);
    },

    // This no longer need to implement, we have cluster entity updates now.
    removeDeletedItems() {},

    changeTripStatusToLowerCase(status) {
      return status.toLowerCase();
    },

    addCancelOptionForPickAndDrop(defaultOptions, row) {
      const cancelAction = this.get('cancelAction');
      const rowTripStatus = row.get('trip.status');
      const selectedWidgetRecord = this.get('selectedWidgetRecord');
      const tripId = selectedWidgetRecord.tripId;
      const trip = this.get('store').peekRecord('trip', parseInt(tripId, 10));
      const tripStatus = rowTripStatus ? rowTripStatus : trip.get('status');
      const tripStatusOptions = [_trip.TRIP_SCHEDULED_STATUS, _trip.TRIP_WAITLIST_STATUS, _trip.TRIP_DISPATCH_STATUS, _trip.TRIP_ENROUTE_STATUS, _trip.TRIP_ILLEGAL_STATUS, _trip.TRIP_UNSCHEDULED_STATUS];
      const filteredTripStatusOptions = tripStatusOptions.map(status => {
        return this.changeTripStatusToLowerCase(status);
      });

      if (filteredTripStatusOptions.includes(tripStatus)) {
        defaultOptions.push(cancelAction);
        return defaultOptions;
      }

      return defaultOptions;
    },

    addBulkPerformOption(defaultOptions, row) {
      const bulkPerformAction = this.get('bulkPerformAction');
      const dispatchRoute = row.get('dispatchRoute');
      const unperformedIQStops = Ember.isPresent(dispatchRoute) ? dispatchRoute.findUnperformedStopsInSequence(row) : [];
      const isNextUnperformed = unperformedIQStops.length === 1;

      if (!isNextUnperformed) {
        defaultOptions.push(bulkPerformAction);
      }

      return defaultOptions;
    },

    addPerformOptionForSoftPerformedStops(defaultOptions, row) {
      const performAction = this.get('performAction');
      const dispatchRoute = row.get('dispatchRoute');
      const firstUnperformedStop = Ember.isPresent(dispatchRoute) ? dispatchRoute.getFirstUnPeformedStop() : null;
      const isRealPerformable = row.get('isRealPerformable');

      if (defaultOptions.includes(performAction)) {
        return defaultOptions;
      }

      if (isRealPerformable && (!firstUnperformedStop || firstUnperformedStop && !firstUnperformedStop.get('isArrived'))) {
        defaultOptions.push(performAction);
      }

      return defaultOptions;
    },

    addPerformOptionForStops(defaultOptions, row) {
      const performAction = this.get('performAction');
      const dispatchRoute = row.get('dispatchRoute');
      const firstUnperformedStop = Ember.isPresent(dispatchRoute) ? dispatchRoute.getFirstUnPeformedStop() : null;
      const isPerformable = row.get('isPerformable');

      if (defaultOptions.includes(performAction)) {
        return defaultOptions;
      } // 1. if firstUnperformedStop is current row the add perform
      // 2. if firstUnperfromedStop is not current row but it has not arrvied yet.


      if (isPerformable && (!firstUnperformedStop || firstUnperformedStop && (firstUnperformedStop.get('id') === row.get('id') || !firstUnperformedStop.get('isArrived')))) {
        defaultOptions.push(performAction);
      }

      return defaultOptions;
    },

    addOptionsByRowConditions(defaultOptions, row) {
      const optionsFn = [this.addPerformOptionForStops.bind(this), this.addBulkPerformOption.bind(this), this.addCancelOptionForPickAndDrop.bind(this)];
      return optionsFn.reduce((options, fn) => {
        return fn(options, row);
      }, defaultOptions);
    },

    /**
     * find break index position by text Eg: 1 - first , 2- second
     * @param sourceRowRecord
     * @param previousOrdinalArray
     * @returns {string}
     */
    getBreakIndexPositionByText(sourceRowRecord, previousOrdinalArray) {
      const sortedBreakRecords = previousOrdinalArray.filter(rec => rec.type === _routeBreak.BREAK_TYPE).sortBy('stopBreakOrd');
      const sourceBreakRecord = sortedBreakRecords.find(rec => rec.id === sourceRowRecord.get('id'));
      let sourceBreakIndex = sortedBreakRecords.indexOf(sourceBreakRecord);
      let numberInWords = '';

      if (sourceBreakIndex !== -1) {
        ++sourceBreakIndex;
        numberInWords = (0, _stringifyNumber.stringifyNumber)(sourceBreakIndex);
      }

      return numberInWords;
    },

    //NYAAR-20192-A break stop ETA is equal to the break's planned start time
    applyEtaTransformation(widgetRecord) {
      if (widgetRecord.type === _routeBreak.BREAK_TYPE) {
        widgetRecord.scheduleEta = widgetRecord.plannedBreakStartTime;
      }
    }

  });

  _exports.default = _default;
});