define("adept-iq/pods/components/workspace-navbar-reconcile-csv-only/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    modelName: 'stop-point',
    defaultSortId: 'tripId',
    defaultSortAsc: false,
    title: 'Stops',
    columns: [{
      id: 'id',
      type: 'text',
      label: 'id',
      valuePath: 'id'
    }, {
      id: 'iqid',
      type: 'text',
      label: 'iqid',
      valuePath: 'attributes.iqid'
    }, {
      id: 'routename',
      type: 'text',
      label: 'routename',
      valuePath: 'attributes.routename'
    }, {
      id: 'stoptype',
      type: 'text',
      label: 'stoptype',
      valuePath: 'attributes.stoptype'
    }, {
      id: 'requesttime',
      type: 'text',
      label: 'requesttime',
      valuePath: 'attributes.requesttime'
    }, {
      id: 'promisetime',
      type: 'text',
      label: 'promisetime',
      valuePath: 'attributes.promisetime'
    }, {
      id: 'passengerfirstname',
      type: 'text',
      label: 'passengerfirstname',
      valuePath: 'attributes.passengerfirstname'
    }, {
      id: 'passengerlastname',
      type: 'text',
      label: 'passengerlastname',
      valuePath: 'attributes.passengerlastname'
    }, {
      id: 'streetnumber',
      type: 'text',
      label: 'streetnumber',
      valuePath: 'attributes.streetnumber'
    }, {
      id: 'streetaddress',
      type: 'text',
      label: 'streetaddress',
      valuePath: 'attributes.streetaddress'
    }, {
      id: 'sublocality',
      type: 'text',
      label: 'sublocality',
      valuePath: 'attributes.sublocality'
    }, {
      id: 'locality',
      type: 'text',
      label: 'locality',
      valuePath: 'attributes.locality'
    }, {
      id: 'subregion',
      type: 'text',
      label: 'subregion',
      valuePath: 'attributes.subregion'
    }, {
      id: 'region',
      type: 'text',
      label: 'region',
      valuePath: 'attributes.region'
    }, {
      id: 'postalcode',
      type: 'text',
      label: 'postalcode',
      valuePath: 'attributes.postalcode'
    }, {
      id: 'actualodometer',
      type: 'text',
      label: 'actualodometer',
      valuePath: 'attributes.actualodometer'
    }, {
      id: 'actualarrivetime',
      type: 'text',
      label: 'actualarrivetime',
      valuePath: 'attributes.actualarrivetime'
    }, {
      id: 'actualdeparttime',
      type: 'text',
      label: 'actualdeparttime',
      valuePath: 'attributes.actualdeparttime'
    }, {
      id: 'tripId',
      type: 'text',
      label: 'tripId',
      valuePath: 'attributes.tripId'
    }, {
      id: 'tripStatus',
      type: 'text',
      label: 'tripStatus',
      valuePath: 'attributes.tripStatus'
    }, {
      id: 'tripStatusReason',
      type: 'text',
      label: 'tripStatusReason',
      valuePath: 'attributes.tripStatusReason'
    }, {
      id: 'tripPurpose',
      type: 'text',
      label: 'tripPurpose',
      valuePath: 'attributes.tripPurpose'
    }, {
      id: 'fareCategory',
      type: 'text',
      label: 'fareCategory',
      valuePath: 'attributes.fareCategory'
    }, {
      id: 'tripWCCount',
      type: 'text',
      label: 'tripWCCount',
      valuePath: 'attributes.tripWCCount'
    }, {
      id: 'tripPCACount',
      type: 'text',
      label: 'tripPCACount',
      valuePath: 'attributes.tripPCACount'
    }, {
      id: 'tripCompCount',
      type: 'text',
      label: 'tripCompCount',
      valuePath: 'attributes.tripCompCount'
    }, {
      id: 'vehicleId',
      type: 'text',
      label: 'vehicleId',
      valuePath: 'attributes.vehicleId'
    }, {
      id: 'driverId',
      type: 'text',
      label: 'driverId',
      valuePath: 'attributes.driverId'
    }]
  };
  _exports.default = _default;
});