define("adept-iq/models/avlm-emergency-message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EMERGENCY_PRIORITY_CODE = _exports.CANNED_RESPONSE_TYPE_CODES = _exports.CANNED_RESPONSE_TYPES = void 0;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  const {
    Model,
    attr
  } = _emberData.default;
  const CANNED_RESPONSE_TYPE_CODES = {
    'None': 'X',
    'Acknowledge': 'A',
    'acknowledged': 'A',
    'Yes/No': 'Y',
    'canceled': 'C',
    'resolved': 'R'
  }; // these must be kept in sync with `topic-transforms/avlm-message.js`

  _exports.CANNED_RESPONSE_TYPE_CODES = CANNED_RESPONSE_TYPE_CODES;
  const CANNED_RESPONSE_TYPES = {
    'X': 'None',
    'A': 'Acknowledge',
    'Y': 'Yes/No',
    'C': 'Cancel',
    'R': 'Resolve'
  };
  _exports.CANNED_RESPONSE_TYPES = CANNED_RESPONSE_TYPES;
  const EMERGENCY_PRIORITY_CODE = 'E';
  _exports.EMERGENCY_PRIORITY_CODE = EMERGENCY_PRIORITY_CODE;

  var _default = Model.extend({
    body: attr('string'),
    messageNr: attr('string'),
    replyToMsgId: attr('string'),
    priority: attr('string', {
      defaultValue: 'normal'
    }),
    createdTime: attr('date', {
      defaultValue: () => new Date()
    }),
    deliveredTime: attr('date'),
    readTime: attr('date'),
    deletedTime: attr('date'),
    responseType: attr('string'),
    responseMessage: attr('string'),
    ackTime: attr('date'),
    driverEndPointType: attr('string'),
    type: attr('string'),
    // use for sending canned message to avlm
    // driver.id uses the externalId of dsDriver
    // As the IQ system + Avlm, Messages is shared, so at most driver, vehicle, route at most three relationships will be used either prefixed with avlm or not
    // avlm models for avlm-only at most
    avlmDriver: belongsTo('avlm-driver'),
    avlmTrip: belongsTo('avlm-trip'),
    avlmVehicle: belongsTo('avlm-vehicle'),
    avlmRoute: belongsTo('avlm-route'),
    alert: belongsTo('avlm-alert'),
    // avlmRouteVehicleDriver: belongsTo('avlm-route-vehicle-driver'),
    replyToMsg: belongsTo('avlm-emergency-message', {
      inverse: 'replyMessages'
    }),
    replyMessages: hasMany('avlm-emergency-message', {
      inverse: 'replyToMsg'
    }),
    // core models required for mvpplus + avlm
    driver: belongsTo('driver'),
    vehicle: belongsTo('vehicle'),
    route: belongsTo('route'),
    routeVehicleDriver: belongsTo('route-vehicle-driver'),
    sortedId: Ember.computed('replyToMsgId', 'id', function () {
      const sortedId = this.get('replyToMsgId') || this.get('id');
      return parseInt(sortedId, 10);
    }),
    latestReplyMessage: Ember.computed('replyMessages', 'replyMessages.@each.deliveredTime', function () {
      const replyMessages = this.get('replyMessages');
      if (!replyMessages) return null;
      return replyMessages.sortBy('startdeliveredTimeTime').get('lastObject');
    }),
    updatedBody: Ember.computed('latestReplyMessage', function () {
      const latestReplyMessageBody = this.get('latestReplyMessage.body');

      if (latestReplyMessageBody) {
        return latestReplyMessageBody;
      }

      return this.get('body');
    }),
    isEmergency: Ember.computed('priority', 'updatedBody', function () {
      return this.get('priority') === EMERGENCY_PRIORITY_CODE && this.get('updatedBody') === 'emergency';
    }),
    isAcknowledged: Ember.computed('priority', 'updatedBody', 'ackTime', function () {
      return this.get('priority') === EMERGENCY_PRIORITY_CODE && this.get('updatedBody') === 'acknowledged';
    }),
    isAcknowledgedPending: Ember.computed('priority', 'updatedBody', 'ackTime', function () {
      return this.get('priority') === EMERGENCY_PRIORITY_CODE && this.get('updatedBody') === 'acknowledged' && !this.get('ackTime');
    }),
    isResolved: Ember.computed('priority', 'updatedBody', function () {
      return this.get('priority') === EMERGENCY_PRIORITY_CODE && this.get('updatedBody') === 'resolved';
    }),
    isCanceled: Ember.computed('priority', 'updatedBody', function () {
      return this.get('priority') === EMERGENCY_PRIORITY_CODE && this.get('updatedBody') === 'canceled';
    })
  });

  _exports.default = _default;
});