define("adept-iq/pods/components/iq-widgets/delete-booking-form/delete-reason/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    notesCharactersLength: Ember.computed('note', function () {
      if (!Ember.isEmpty(this.get('note'))) {
        return this.get('note').length;
      }

      return 0;
    }),
    actions: {
      onNotesChange(note) {
        this.get('onNoteChange')(note.trim());
      }

    }
  });

  _exports.default = _default;
});