define("adept-iq/models/user-event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr; // created new model to display dispatchToDriverMessage, driverToDispatchMessage in activity-log of vehicle widget- IQUX-2947

  var _default = Model.extend({
    content: attr('object'),
    vehicle: attr('object'),
    message: attr('string'),
    driver: attr('object')
  });

  _exports.default = _default;
});