define("adept-iq/pods/components/side-drawer/reconcile-add-new-trip/stubs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    PICK_NOTES: 1,
    DROP_NOTES: 2,
    SELECTEDNEED_CNT: 3,
    LOAD_TIME: 4,
    UNLOAD_TIME: 5,
    anchorData: [{
      'id': 'pick',
      'name': 'Pick'
    }, {
      'id': 'drop',
      'name': 'Drop'
    }],
    companions: [{
      'id': 0,
      'name': 0,
      'value': 0
    }, {
      'id': 1,
      'name': 1,
      'value': 1
    }, {
      'id': 2,
      'name': 2,
      'value': 2
    }]
  };
  _exports.default = _default;
});