define("adept-iq/adapters/provider", ["exports", "adept-iq/adapters/cs-config-model", "fuzzysearch"], function (_exports, _csConfigModel, _fuzzysearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigModel.default.extend({
    categoryId: 'config-System_Configuration-providers',

    query(store, type, query) {
      return this._super(...arguments).then(response => {
        if (Ember.isEmpty(query.filter)) return response;
        const needle = query.filter.split('%')[1];
        response.data = response.data.filter(datum => {
          try {
            const value = JSON.parse(datum.attributes.value);
            return (0, _fuzzysearch.default)(needle, value.name.toLowerCase());
          } catch (e) {
            return false;
          }
        });
        return response;
      });
    }

  });

  _exports.default = _default;
});