define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel/speed", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel"], function (_exports, _react, _panel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Speed = () => {
    const {
      speedMode,
      speedPlay,
      isPaused
    } = (0, _panel.usePanel)();

    const changeSpeedMode = () => {
      switch (speedMode) {
        case _panel.speedModes.x1:
          speedPlay(_panel.speedModes.x2);
          break;

        case _panel.speedModes.x2:
          speedPlay(_panel.speedModes.x4);
          break;

        case _panel.speedModes.x4:
          speedPlay(_panel.speedModes.x8);
          break;

        case _panel.speedModes.x8:
        default:
          speedPlay(_panel.speedModes.x1);
      }
    };

    return _react.default.createElement("button", {
      className: "btn circle default",
      type: "button",
      disabled: isPaused,
      onClick: () => changeSpeedMode()
    }, speedMode);
  };

  var _default = Speed;
  _exports.default = _default;
});