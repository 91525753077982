define("adept-iq/pods/components/side-drawer/reconcile-trip-transfer/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stopColumns = void 0;
  const cellComponent = 'table/cells/base-cell';
  const stopColumns = [{
    label: 'Trip ID',
    valuePath: 'tripId',
    cellComponent,
    resizable: true,
    minResizeWidth: 50,
    dataType: 'number',
    width: '80px'
  }, {
    label: 'Last Name',
    valuePath: 'riderDetails.lastname',
    cellComponent,
    resizable: true,
    minResizeWidth: 50,
    dataType: 'text',
    width: '100px'
  }, {
    label: 'Address',
    valuePath: 'address.streetname',
    cellComponent,
    minResizeWidth: 50,
    resizable: true,
    dataType: 'text',
    width: '150px'
  }, {
    label: 'City',
    valuePath: 'address.locality',
    cellComponent,
    minResizeWidth: 50,
    resizable: true,
    dataType: 'text',
    width: '120px'
  }, {
    label: 'Type',
    valuePath: 'type',
    cellComponent,
    minResizeWidth: 50,
    resizable: true,
    dataType: 'text',
    width: '60px'
  }, {
    label: 'Actual Arrival Time',
    valuePath: 'arriveEvent.receivedat',
    cellComponent,
    minResizeWidth: 50,
    resizable: true,
    dataType: 'time',
    format: 'hh:mm A',
    width: '150px'
  }, {
    label: 'Actual Depart Time',
    valuePath: 'departEvent.receivedat',
    cellComponent,
    minResizeWidth: 50,
    resizable: true,
    dataType: 'time',
    format: 'hh:mm A',
    width: '150px'
  }];
  _exports.stopColumns = stopColumns;
});