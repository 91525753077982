define("adept-iq/pods/components/iq-widgets/playback-widget/config", ["exports", "moment", "adept-iq/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/playback-widget',
    rowComponent: 'table/rows/action-row',
    title: 'Playback',
    filters: {
      text: [{
        id: 'equalTo',
        label: '=',
        arity: 1,
        exp: function (item, values) {
          return item.toString().trim().toLowerCase() === values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'notEqualTo',
        label: '!=',
        arity: 1,
        exp: function (item, values) {
          return item.toString().trim().toLowerCase() !== values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'contains',
        label: 'Contains',
        arity: 1,
        exp: function (item, values) {
          return item.toString().trim().toLowerCase().indexOf(values[0].toString().trim().toLowerCase()) !== -1;
        }
      }, {
        id: 'empty',
        label: 'Empty',
        arity: 1,
        exp: function (item) {
          if (item && item !== null) return false;
          return true;
        }
      }],
      uuid: [{
        id: 'equalTo',
        label: '=',
        arity: 1,
        exp: function (item, values) {
          return item.toString().trim().toLowerCase() === values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'notEqualTo',
        label: '!=',
        arity: 1,
        exp: function (item, values) {
          return item.toString().trim().toLowerCase() !== values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'contains',
        label: 'Contains',
        arity: 1,
        exp: function (item, values) {
          return item.toString().trim().toLowerCase().indexOf(values[0].toString().trim().toLowerCase()) !== -1;
        }
      }, {
        id: 'empty',
        label: 'Empty',
        arity: 1,
        exp: function (item) {
          if (item && item !== null) return false;
          return true;
        }
      }],
      number: [{
        id: 'equalTo',
        label: '=',
        arity: 1,
        exp: function (item, values) {
          return parseFloat(item) === parseFloat(values[0]);
        }
      }, {
        id: 'notEqualTo',
        label: '!=',
        arity: 1,
        exp: function (item, values) {
          return parseFloat(item) !== parseFloat(values[0]);
        }
      }, {
        id: 'greaterThan',
        label: '>',
        exp: function (item, values) {
          return parseFloat(item) > parseFloat(values[0]);
        }
      }, {
        id: 'lessThan',
        label: '<',
        arity: 1,
        exp: function (item, values) {
          return parseFloat(item) < parseFloat(values[0]);
        }
      }, {
        id: 'between',
        label: 'Between',
        arity: 2,
        exp: function (item, values) {
          return values[1] > values[0] && item <= values[1] && item >= values[0];
        }
      }, {
        id: 'contains',
        label: 'Contains',
        arity: 1,
        exp: function (item, values) {
          return item.toString().trim().toLowerCase().indexOf(values[0].toString().trim().toLowerCase()) !== -1;
        }
      }, {
        id: 'empty',
        label: 'Empty',
        arity: 1,
        exp: function (item) {
          if (item && item !== null) return false;
          return true;
        }
      }],
      time: [{
        id: 'equalTo',
        label: '=',
        arity: 1,
        exp: function (item, values) {
          if ((0, _moment.default)(item, 'hh:mm A', true).isValid() && (0, _moment.default)(values[0], 'hh:mm A', true).isValid()) return item === values[0];
          return false;
        }
      }, {
        id: 'after',
        label: 'After',
        arity: 1,
        exp: function (item, values) {
          if ((0, _moment.default)(item, 'hh:mm A', true).isValid() && (0, _moment.default)(values[0], 'hh:mm A', true).isValid()) return item > values[0];
          return false;
        }
      }, {
        id: 'before',
        label: 'Before',
        arity: 1,
        exp: function (item, values) {
          if ((0, _moment.default)(item, 'hh:mm A', true).isValid() && (0, _moment.default)(values[0], 'hh:mm A', true).isValid()) return item < values[0];
          return false;
        }
      }, {
        id: 'atOrBefore',
        label: 'At or Before',
        arity: 1,
        exp: function (item, values) {
          if ((0, _moment.default)(item, 'hh:mm A', true).isValid() && (0, _moment.default)(values[0], 'hh:mm A', true).isValid()) return item <= values[0];
          return false;
        }
      }, {
        id: 'atOrAfter',
        label: 'At or After',
        arity: 1,
        exp: function (item, values) {
          if ((0, _moment.default)(item, 'hh:mm A', true).isValid() && (0, _moment.default)(values[0], 'hh:mm A', true).isValid()) return item >= values[0];
          return false;
        }
      }, {
        id: 'between',
        label: 'Between',
        arity: 2,
        exp: function (item, values) {
          if ((0, _moment.default)(item, 'hh:mm A', true).isValid() && (0, _moment.default)(values[0], 'hh:mm A', true).isValid() && (0, _moment.default)(values[1], 'hh:mm A', true).isValid()) return values[1] > values[0] && item <= values[1] && item >= values[0];
          return false;
        }
      }, {
        id: 'strictlyBetween',
        label: 'Strictly Between',
        arity: 2,
        exp: function (item, values) {
          if ((0, _moment.default)(item, 'hh:mm A', true).isValid() && (0, _moment.default)(values[0], 'hh:mm A', true).isValid() && (0, _moment.default)(values[1], 'hh:mm A', true).isValid()) return values[1] > values[0] && item < values[1] && item > values[0];
          return false;
        }
      }, {
        id: 'empty',
        label: 'Empty',
        arity: 1,
        exp: function (item) {
          if (item && item !== null) return false;
          return true;
        }
      }]
    },
    vehiclesTableColumns: [{
      width: '40px',
      resizable: false,
      cellComponent: 'table/cells/radio-button',
      valuePath: 'none'
    }, {
      label: 'Vehicle ID',
      valuePath: 'vehicleId',
      dataType: 'uuid',
      resizable: true,
      minResizeWidth: 50,
      id: 'vehicleId',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Driver ID',
      valuePath: 'driverId',
      dataType: 'uuid',
      resizable: true,
      minResizeWidth: 50,
      id: 'driverId',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Driver Name',
      valuePath: 'driverName',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      id: 'driverName',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'OTP',
      valuePath: 'otp',
      dataType: 'text',
      resizable: true,
      searchable: true,
      minResizeWidth: 50,
      id: 'otp',
      component: 'iq-widgets/playback-widget/table-header'
    }],
    vehicleEventsTableColumns: [{
      label: 'Time',
      valuePath: 'time',
      dataType: 'time',
      format: `${_environment.default.dateTimeFormat.timeMoment}`,
      resizable: true,
      minResizeWidth: 20,
      searchable: true,
      id: 'time',
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Route',
      valuePath: 'routeName',
      dataType: 'uuid',
      resizable: true,
      minResizeWidth: 20,
      id: 'routeId',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Vehicle ID',
      valuePath: 'vehicleId',
      dataType: 'uuid',
      resizable: true,
      minResizeWidth: 20,
      id: 'vehicleId',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Latitude',
      valuePath: 'lat',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 20,
      searchable: true,
      id: 'lat',
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Longitude',
      valuePath: 'lng',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 20,
      searchable: true,
      id: 'lng',
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Passenger ID',
      valuePath: 'passengerId',
      dataType: 'uuid',
      resizable: true,
      minResizeWidth: 30,
      id: 'passengerId',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Passenger First Name',
      valuePath: 'passengerFName',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      id: 'passengerFName',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Passenger Last Name',
      valuePath: 'passengerLName',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      id: 'passengerLName',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Stop Address',
      valuePath: 'formattedStopAddress',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 70,
      id: 'stopAddress',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Stop Type',
      valuePath: 'stopType',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      id: 'stopType',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Event Type',
      valuePath: 'eventType',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 50,
      id: 'eventType',
      searchable: true,
      component: 'iq-widgets/playback-widget/table-header'
    }, {
      label: 'Vehicle Speed',
      valuePath: 'speed',
      dataType: 'text',
      resizable: true,
      minResizeWidth: 20,
      id: 'speed',
      component: 'iq-widgets/playback-widget/table-header'
    }],
    route: {
      model: 'playback-route',
      indexPath: 'routeId'
    },
    routeName: {
      model: 'playback-route',
      indexPath: 'routeName'
    },
    vehicle: {
      model: 'playback-vehicle',
      indexPath: 'vehicleId'
    },
    driver: {
      model: 'playback-driver',
      indexPath: 'driverId'
    },
    activitylog: {
      adaptor: 'playback-activity-log'
    },
    vehicleEvents: ['pick', 'wheelchair-pick', 'wheelchair-drop', 'onBreak', 'offBreak', 'drop', 'pullOut', 'pullIn']
  };
  _exports.default = _default;
});