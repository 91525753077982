define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/table-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eKP+GcFM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"column-label\"],[9],[0,\"\\n  \"],[1,[23,[\"column\",\"label\"]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"column-controls\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"sortIconProperty\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\"],[12,\"class\",[28,[\"lt-sort-icon \",[27,\"get\",[[23,[\"sortIcons\"]],[23,[\"sortIconProperty\"]]],null]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/table-header/template.hbs"
    }
  });

  _exports.default = _default;
});