define("adept-iq/models/avlm-alert", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    timestamp: attr('date'),
    body: attr('string'),
    category: attr('string'),
    state: attr('string'),
    companyId: attr('string'),
    title: attr('string'),
    isNewEmergencyReceived: attr('boolean'),
    routeId: attr('string'),
    vehicleId: attr('string'),
    driverId: attr('string'),
    tripId: attr('string'),
    stopId: attr('string'),
    priority: attr('string'),
    priorityId: attr('string'),
    avlmRoute: belongsTo('avlm-route'),
    avlmVehicle: belongsTo('avlm-vehicle'),
    // core models required for mvpplus + avlm at most three relationships will be used
    emergency: belongsTo('avlm-emergency-message'),
    vehicle: belongsTo('vehicle'),
    route: belongsTo('route'),
    driver: belongsTo('driver'),
    provider: Ember.computed('route.provider', 'vehicle.provider', function () {
      const routeProvider = this.get('route.provider');
      if (routeProvider) return routeProvider;
      const vehicleProvider = this.get('vehicle.provider');
      return vehicleProvider;
    })
  });

  _exports.default = _default;
});