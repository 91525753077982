define("adept-iq/pods/components/iq-widgets/map-widget/decorations/arrow", ["exports", "adept-iq/utils/get-bearing"], function (_exports, _getBearing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(points, arrowOptions) {
    const arrow = window.L.marker(points[1], arrowOptions); // need to find who is using this
    // relies on `vendor/leaflet/leaflet.rotatedMarker.js`

    arrow.setRotationAngle((0, _getBearing.default)(points) - 90);
    return arrow;
  }
});