define("adept-iq/pods/components/iq-widgets/ember-react-playback/context/map", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MapContext = void 0;

  const MapContext = _react.default.createContext();

  _exports.MapContext = MapContext;
});