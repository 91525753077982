define("adept-iq/pods/components/iq-widgets/subscription-form/booking-map/component", ["exports", "adept-iq/pods/components/generic-widgets/map-widget/component", "adept-iq/config/environment", "adept-iq/utils/geolib"], function (_exports, _component, _environment, _geolib) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-unused-vars */
  const pintPointIcon = {
    icon: window.L.icon({
      iconUrl: '/map-icons/icon-pinpoint.svg',
      iconSize: [48, 48],
      iconAnchor: [24, 48]
    }),
    keyboard: false,
    interactive: false
  };
  const arrowHeadLineConfig = {
    patterns: [{
      offset: 0,
      repeat: 0,
      symbol: window.L.Symbol.arrowHead({
        headAngle: -290,
        // flips arrow around so it looks right
        pixelSize: 16,
        polygon: true,
        pathOptions: {
          weight: 2,
          stroke: true,
          fill: true,
          fillColor: 'black',
          fillOpacity: 1,
          color: 'black',
          opacity: 1
        }
      })
    }]
  };
  const thickArrowHeadLineConfig = {
    patterns: [{
      offset: 0,
      repeat: 0,
      symbol: window.L.Symbol.arrowHead({
        headAngle: -290,
        // flips arrow around so it looks right
        pixelSize: 16,
        polygon: true,
        pathOptions: {
          weight: 2,
          stroke: true,
          fill: true,
          fillOpacity: 0,
          opacity: 0
        }
      })
    }]
  };
  const polyAndDecKeyCoordinates = [{
    path: '_parts',
    x1: 'x',
    y1: 'y'
  }, {
    path: '_paths',
    x1: 'lat',
    y1: 'lng'
  }, {
    path: '_parts',
    x1: 'x',
    y1: 'y'
  }, {
    path: '_paths',
    x1: 'lat',
    y1: 'lng'
  }];

  var _default = _component.default.extend(Ember.Evented, {
    booking: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    notifications: Ember.inject.service(),
    mapService: Ember.inject.service('map'),
    defaultPickMarker: null,
    zoom: 12,
    layerGroup: null,
    activeBookingSegments: null,
    allActivePlaces: null,

    init() {
      this._super(...arguments);

      this.setProperties({
        layerGroup: new window.L.LayerGroup()
      });
    },

    didInsertElement() {
      const mapService = this.get('mapService');

      this._super(...arguments);

      this.get('map.native').setView({
        lat: mapService.get('center.lat'),
        lng: mapService.get('center.lon')
      }, 12);
      this.activeGeocode();
      this.onChangeActiveAddress();
      this.onChangeActiveLegsInBooking();
      const editmodelwidth = Ember.$('.edit-widget-modal').width();
      Ember.$('.edit-widget-modal').width('25%');
      Ember.$('.booking-map-container').css({
        'width': 'calc(95% - ' + editmodelwidth + 'px)'
      }).css({
        'height': 'calc(100% - 20%)',
        'left': editmodelwidth + 'px)'
      });
      this.set('booking.bookingMap', this.get('map'));
    },

    activeGeocode: Ember.observer('booking.activeDragPlace', 'booking.addressSelectType', function () {
      const activeDragPlace = this.get('booking.activeDragPlace');
      const map = this.get('map');
      const addressSelectType = this.get('booking.addressSelectType');

      if (!activeDragPlace) {
        map.native.off('click');
        return;
      }

      const bookingService = this.get('booking');
      Ember.$('html,body,.tomtom-map').addClass('custom-cursor');
      map.native.on('click', async event => {
        try {
          const bookingAddress = await bookingService.fetchAddressForLatLng(event.latlng);
          const legType = 'Leg';

          if (!bookingAddress) {
            this.booking.showErrorMessage('Location must be within the agency service boundary.');
            this.drawMarkersAndPolylines();
            return;
          }

          const beNomadAddress = bookingAddress.address.beNomadAddress;
          const latlng = event.latlng;
          const geoNode = await this.booking.getAddressGeoNode(latlng.lat, latlng.lng, beNomadAddress, addressSelectType);

          if (!geoNode || Ember.isEmpty(geoNode.zone.zoneName) || geoNode && geoNode.zone.zoneName <= '0') {
            this.booking.showErrorMessage('Unable to use address because it falls outside the service area.');
            return;
          }

          const activeDragAddress = activeDragPlace.get('address'); //Getting the zone type using the id present in the geoNode and setting it to address

          const zoneName = this.store.peekRecord('zone-type', geoNode.zone.zoneName);
          activeDragAddress.set('zName', zoneName.get('displayName'));
          activeDragAddress.set('zoneName', zoneName);

          if (!activeDragAddress.get('zoneName.name')) {
            this.booking.showErrorMessage('Unable to use address because it falls outside the service area.');
            return;
          }

          const locationRecord = this.store.createRecord('location', {
            lat: parseFloat(latlng.lat.toFixed(5)),
            lng: parseFloat(latlng.lng.toFixed(5))
          });
          locationRecord.set('geoNode', geoNode.geonode);
          bookingService.updateAddress(activeDragAddress, bookingAddress, geoNode.zone);
          activeDragPlace.set('location', locationRecord);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        } finally {
          Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
          bookingService.setActiveDragPlace(null);
          map.native.off('click');
        }
      });
    }),

    doDrawMarkersAndPolylines() {
      const booking = this.get('booking');
      const skipSetView = booking.get('skipSetView');
      this.drawMarkersAndPolylines(skipSetView);
      booking.set('skipSetView', false);
    },

    onChangeActiveLegsInBooking: Ember.observer('booking.activeLegsInBooking', 'booking.activeDragPlace', function () {
      Ember.run.debounce(this, this.doDrawMarkersAndPolylines, 300);
    }),
    onChangeActiveAddress: Ember.observer('booking.activeAddress', function () {
      this.drawMarkersAndPolylines();
    }),

    drawMarkersAndPolylines() {
      let skipSetView = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (!Ember.isPresent(this.get('map'))) {
        return;
      }

      this.get('layerGroup').clearLayers();
      const legsInBooking = this.booking.get('activeLegsInBooking') ? this.booking.get('activeLegsInBooking').toArray() : [];
      const allSegments = [];
      const latLngs = [];
      legsInBooking.forEach(leg => {
        const segments = leg.get('segments').toArray();
        const validSegments = segments.filter(segment => {
          return segment.get('pick.place.location.lat') && segment.get('drop.place.location.lat');
        });
        allSegments.push(...validSegments);
        segments.forEach(segment => {
          const legMarkers = [segment.get('pick.place.location'), segment.get('drop.place.location')];
          const validMarkers = legMarkers.filter(marker => {
            return marker.get('lat') && marker.get('lng');
          });
          validMarkers.forEach(marker => {
            latLngs.push([marker.get('lat'), marker.get('lng')]);
            this.drawMarker(marker, skipSetView);
          });
        });
      });
      this.drawPloylinePath(allSegments);

      if (!skipSetView) {
        Ember.run.later(this, () => {
          this.boundLatLng(latLngs);
        }, 500);
      }
    },

    boundLatLng(latLngs) {
      const map = this.get('map.native');

      if (map && latLngs.length > 1) {
        const bound = window.L.latLngBounds(latLngs);
        map.fitBounds(bound);
      }
    },

    drawMarker(latLngObj) {
      let skipSetView = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const map = this.get('map');
      const marker = window.L.marker([latLngObj.get('lat'), latLngObj.get('lng')], pintPointIcon);
      this.get('layerGroup').addLayer(marker).addTo(this.get('map.native'));

      if (!skipSetView) {
        this.get('map.native').setView(marker._latlng, map.getZoom());
      }
    },

    drawPolyline(pick, drop) {
      const tooltipOptions = {
        opacity: 0.7,
        direction: 'top',
        sticky: true
      }; // Drawing reversly to make arrow head correct

      const from = pick.get('place.location');
      const to = drop.get('place.location');
      const toLatLng = {
        lat: to.get('lat'),
        lng: to.get('lng')
      };
      const fromLatLng = {
        lat: from.get('lat'),
        lng: from.get('lng')
      };
      const polyline = window.L.polyline([[to.get('lat'), to.get('lng')], [from.get('lat'), from.get('lng')]], {
        color: 'black',
        endOffset: '300'
      });
      const thickInvisiblePolyline = window.L.polyline([[to.get('lat'), to.get('lng')], [from.get('lat'), from.get('lng')]], {
        opacity: 0,
        weight: 25,
        endOffset: '300'
      });
      const polylineDecorator = window.L.polylineDecorator(polyline, arrowHeadLineConfig);
      const thickPolylineDecorator = window.L.polylineDecorator(thickInvisiblePolyline, thickArrowHeadLineConfig);
      const distance = (0, _geolib.getDistance)(toLatLng, fromLatLng) * 3.281 / 5280; // convert to miles
      // create a thicker invisible line so hovering around the polyline will be friendlier. We don't want to remove popup
      // as we move the mouse a tiny bit.

      thickInvisiblePolyline.bindTooltip(`<span>Distance: <br>${distance.toFixed(3)} mi</span>`, tooltipOptions);
      this.get('layerGroup').addLayer(polyline).addTo(this.get('map.native'));
      this.get('layerGroup').addLayer(polylineDecorator).addTo(this.get('map.native'));
      this.get('layerGroup').addLayer(thickInvisiblePolyline).addTo(this.get('map.native'));
      this.get('layerGroup').addLayer(thickPolylineDecorator).addTo(this.get('map.native'));
      const polyAndDecorators = [polyline, polylineDecorator, thickInvisiblePolyline, thickPolylineDecorator]; //set arrow head - NYAAR-14549

      this.get('layerGroup').removeLayer(polyline).addTo(this.get('map.native'));
      this.get('layerGroup').removeLayer(polylineDecorator).addTo(this.get('map.native'));
      this.get('layerGroup').removeLayer(thickInvisiblePolyline).addTo(this.get('map.native'));
      this.get('layerGroup').removeLayer(thickPolylineDecorator).addTo(this.get('map.native'));
      this.shortenPolylineAndDecorator(polyAndDecorators);
      this.get('layerGroup').addLayer(polyline).addTo(this.get('map.native'));
      this.get('layerGroup').addLayer(polylineDecorator).addTo(this.get('map.native'));
      this.get('layerGroup').addLayer(thickInvisiblePolyline).addTo(this.get('map.native'));
      this.get('layerGroup').addLayer(thickPolylineDecorator).addTo(this.get('map.native'));
    },

    shortenPolylineAndDecorator(polyAndDecorators) {
      let factor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.2;
      const isParts = !polyAndDecorators[0]._parts || polyAndDecorators[0]._parts.length === 0;
      const isPaths = !polyAndDecorators[1]._paths || polyAndDecorators[1]._paths.length === 0;
      const isThickParts = !polyAndDecorators[2]._parts || polyAndDecorators[2]._parts.length === 0;
      const isThickPaths = !polyAndDecorators[3]._paths || polyAndDecorators[3]._paths.length === 0;

      if (isParts || isPaths || isThickParts || isThickPaths) {
        return;
      }

      polyAndDecKeyCoordinates.forEach((pdk, index) => {
        const firstCoordinate = polyAndDecorators[index][pdk.path][0][0];
        const secondCoordinate = polyAndDecorators[index][pdk.path][0][1];
        const x1 = firstCoordinate[pdk.x1];
        const y1 = firstCoordinate[pdk.y1];
        const x2 = secondCoordinate[pdk.x1];
        const y2 = secondCoordinate[pdk.y1];
        const newStartX = x1 + factor * (x2 - x1);
        const newStartY = y1 + factor * (y2 - y1);
        const endFactor = 1 - factor;
        const newEndX = x1 + endFactor * (x2 - x1);
        const newEndY = y1 + endFactor * (y2 - y1);
        polyAndDecorators[index][pdk.path][0][0][pdk.x1] = newStartX;
        polyAndDecorators[index][pdk.path][0][0][pdk.y1] = newStartY;
        polyAndDecorators[index][pdk.path][0][1][pdk.x1] = newEndX;
        polyAndDecorators[index][pdk.path][0][1][pdk.y1] = newEndY;
      });
    },

    drawPloylinePath(segments) {
      if (segments.length === 0) {
        return;
      }

      for (let i = 0; i < segments.length; i++) {
        this.drawPolyline(segments[i].get('pick'), segments[i].get('drop'));
      }
    },

    destroy() {
      this._super(...arguments);

      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
    }

  });

  _exports.default = _default;
});