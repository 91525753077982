define("adept-iq/models/service-window", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    displayName: attr('string'),
    searchWindowStart: attr(),
    searchWindowEnd: attr(),
    promiseWindowStart: attr(),
    promiseWindowEnd: attr(),
    dropWindowStart: attr(),
    dropWindowEnd: attr()
  });

  _exports.default = _default;
});