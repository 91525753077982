define("adept-iq/pods/components/table/cells/base-cell/component", ["exports", "adept-iq/pods/components/table/cells/base-cell/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['overrideClassName'],
    attributeBindings: ['tabindex'],
    layout: _template.default,
    columns: Ember.computed('table.allColumns', function () {
      const tableColumns = this.get('table.allColumns');
      return tableColumns;
    }),
    overrideClassName: Ember.computed('config', function () {
      const tableColumn = this.get('columns');
      const found = tableColumn.find(tc => tc.id === 'description');
      const baseClassTestName = 'data-test-table-base-cell';

      if (!Ember.isPresent(found)) {
        return [`table-base-cell  ${baseClassTestName}`];
      }

      return [`table-base-cell-white-space ${baseClassTestName}`];
    }),
    tooltipText: Ember.computed('row.isLocked', function () {
      const isLocked = this.get('row.isLocked');

      if (Ember.isNone(isLocked)) {
        return null;
      }

      if (isLocked) {
        return `Locked by ${this.get('row.lockedBy')}`;
      }
    }),
    displayTooltip: Ember.computed('tooltipText', 'row.isLocked', function () {
      const tooltipText = this.get('tooltipText');
      const isLocked = this.get('row.isLocked');
      return Ember.isPresent(tooltipText) && isLocked;
    }),
    tabindex: 0
  });

  _exports.default = _default;
});