define("adept-iq/pods/components/rider-management-widget/rider-activity-log/activity-log-table/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    // component: 'rider-management-widget/rider-activity-log',
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: null,
    modelName: 'event',
    title: 'Activity Log',
    defaultSortId: 'time',
    defaultSortAsc: false,
    queryModelData: true,
    showSelectedRowLength: false,
    hideCheckBox: true,
    columns: [{
      id: 'activity',
      index: 0,
      type: 'text',
      label: 'Activity',
      defaultWidth: 180,
      sortable: true,
      valuePath: 'activity',
      searchable: true
    }, {
      id: 'time',
      index: 1,
      type: 'date',
      label: 'Activity Time',
      defaultWidth: 160,
      format: `${_environment.default.dateTimeFormat.dateTimeMoment}`,
      valuePath: 'time',
      searchable: true,
      sortable: true
    }, {
      id: 'createdAt',
      index: 2,
      type: 'date',
      label: 'Log Time',
      defaultWidth: 160,
      format: `${_environment.default.dateTimeFormat.dateTimeMoment}`,
      valuePath: 'createdAt',
      searchable: true,
      sortable: true
    }, {
      id: 'detail',
      index: 3,
      type: 'text',
      label: 'Details',
      defaultWidth: 800,
      sortable: true,
      searchable: true,
      valuePath: 'details'
    }, {
      id: 'user',
      index: 4,
      type: 'text',
      label: 'User',
      defaultWidth: 160,
      sortable: true,
      searchable: true,
      valuePath: 'user.fullName'
    }]
  };
  _exports.default = _default;
});