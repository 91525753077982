define("adept-iq/pods/components/table/rows/colored-row/component", ["exports", "adept-iq/pods/components/table/rows/action-row/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNameBindings: ['coloredRow', 'selectedOrdinalRow'],
    coloredRow: Ember.computed('row.coloredRow', function () {
      const color = this.get('row.coloredRow');

      if (color) {
        return 'yellow-table-row';
      }

      return '';
    }),
    selectedOrdinalRow: Ember.computed('row.selectedOrdinalRow', function () {
      const selectedOrdinalRow = this.get('row.selectedOrdinalRow');

      if (selectedOrdinalRow) {
        return 'yellow-table-row';
      }

      return '';
    })
  });

  _exports.default = _default;
});