define("adept-iq/pods/components/form-components/searchable-enum/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Updated isFloating value to true if
  const BREAKTYPE = {
    NAME: 'break-type',
    VALUE: 'floating'
  };

  var _default = Ember.Component.extend({
    classNames: ['form-components-select'],
    store: Ember.inject.service(),
    editModal: Ember.inject.service(),
    value: null,
    disabled: false,
    extra: null,
    options: null,
    allowMultiple: Ember.computed.alias('extra.allowMultiple'),
    optionSearchPath: Ember.computed.alias('extra.optionSearchPath'),
    optionModelName: Ember.computed.alias('extra.optionModelName'),
    optionSearchEnabled: Ember.computed.alias('extra.optionSearchEnabled'),
    optionTempRecordSelected: Ember.computed.alias('extra.optionTempRecordSelected'),
    optionFilterFunction: Ember.computed.alias('extra.optionFilterFunction'),
    includes: Ember.computed('extra.optionIncludes', function () {
      return !Ember.isNone(this.get('extra.optionIncludes')) ? this.get('extra.optionIncludes') : '';
    }),

    init() {
      const selectedPath = this.get('extra.optionSelectedPath');

      if (!this.get('optionSearchEnabled')) {
        if (Ember.isEmpty(selectedPath)) {
          this.set('selected', this.get('value'));
        } else {
          this.set('selected', this.get(`value.${selectedPath}`));
        }
      } else {
        const object = {};
        object[this.get('optionSearchPath')] = this.get('value'); // this.set('selected', object);

        this.set('selected', this.get('value'));
      }

      if (!this.get('optionSearchEnabled')) {
        this.loadEnum();
      }

      this._super(...arguments);
    },

    loadEnum() {
      this.store.findAll(this.get('optionModelName')).then(results => {
        this.searchResults(results);
      });
    },

    searchResults(results) {
      if (this.get('optionFilterFunction')) {
        const filteredResults = results.filter(result => {
          const available = this.get('record')[this.get('optionFilterFunction')](result);
          return available;
        });
        this.set('options', filteredResults);
      } else {
        this.set('options', results);
      }
    },

    // will do this when optionSearchEnabled is true
    // otherwise it will do loadEnum
    search: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(500);
      const query = {
        filter: `ilike(${this.get('optionSearchPath')},'%${term}%')`,
        include: this.get('includes')
      };
      const results = yield this.store.query(this.get('optionModelName'), query);
      this.searchResults(results);
    }),

    updateBreakType(record) {
      if (this.optionModelName === BREAKTYPE.NAME && record.get('name') === BREAKTYPE.VALUE) this.set('editModal.isFloatingType', true);else this.set('editModal.isFloatingType', false);
    },

    actions: {
      onSelected(newRecord) {
        this.set('selected', newRecord);

        if (this.get('optionSearchEnabled')) {
          this.set(`record.${this.get('valuePath')}`, newRecord);
          this.set(`record.${this.get('optionTempRecordSelected')}`, newRecord);
        } else {
          this.set(`record.${this.get('valuePath')}`, newRecord);
        }

        this.set('record.isForceDirty', true);
        this.updateBreakType(newRecord);
      },

      handleKeydown(dropdown, e) {
        if (e.keyCode !== 13) {
          return;
        } // TODO: add code to store data
        //  to correct api host when kafka topic is done.

      }

    }
  });

  _exports.default = _default;
});