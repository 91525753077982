define("adept-iq/tests/factories/metric", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('metric', {
    // all attributes can be change based on a passed options hash
    default: {
      routes: 10,
      unusedRoutes: 10,
      scheduledTrips: 10,
      breakTrips: 10,
      manualTrips: 10,
      brokerTrips: 10,
      waitlistedTrips: 10,
      riders: 10,
      revenueHours: 10,
      revenueMiles: 10,
      totalHours: 10,
      totalMiles: 10,
      percentageTripsRiderSharing: 10,
      percentageTimeEmpty: 10,
      percenageTimeOnBreak: 10,
      averageRiderTimePerTrip: 10
    }
  });
});