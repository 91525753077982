define("adept-iq/models/rider-place", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    mailPreferenceEnabled: attr('boolean'),
    isSoftDeleted: attr('boolean', {
      defaultValue: false
    }),
    loggingMeta: attr(),
    rider: belongsTo('rider'),
    place: belongsTo('place'),
    riderPlaceType: belongsTo('rider-place-type'),
    riderPlaceTypeName: Ember.computed.alias('riderPlaceType.id')
  });

  _exports.default = _default;
});