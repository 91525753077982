define("adept-iq/config/cs-config-model-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.modelNameForCategory = void 0;
  // you must register any model that is serialized to a config item here
  const modelNameForCategory = {
    'config-Scheduling-schedule_configurations': 'schedule-configuration',
    'config-Scheduling-service_windows': 'service-window',
    'config-System_Configuration-Activity_Log_Types-trip': 'trip-activity-log-type',
    'config-Activity_Log_Types-vehicle': 'vehicle-activity-log-type',
    'config-System_Configuration-Activity_Log_Types-route': 'route-activity-log-type',
    'config-Activity_Log_Types-user': 'user-activity-log-type',
    'config-System_Configuration-break_types': 'break-type',
    'config-System_Configuration-cancel_types': 'cancel-type',
    'config-System_Configuration-eligibility_types': 'eligibility-type',
    'config-System_Configuration-eligibility_categories': 'eligibility-category',
    'config-System_Configuration-fare_types': 'fare-type',
    'config-System_Configuration-no_show_reason_codes': 'no-show-reason-code',
    'config-System_Configuration-passenger_types': 'passenger-type',
    'config-System_Configuration-place_category_types': 'place-category-type',
    'config-System_Configuration-providers': 'provider',
    'config-System_Configuration-travel_need_types': 'travel-need-type',
    'config-System_Configuration-vehicle_capacity_configs': 'vehicle-capacity-config',
    'config-System_Configuration-vehicle_capacity_types': 'vehicle-capacity-type',
    'config-System_Configuration-vehicle_types': 'vehicle-type',
    'config-System_Configuration-breakdown_types': 'breakdown-type',
    'config-System_Configuration-zones': 'zone-type',
    'config-System_Configuration-rider_place_types': 'rider-place-type',
    'config-System_Configuration-provider_types': 'provider-type',
    'config-System_Configuration-driver_physical_types': 'driver-physical-type-name',
    'config-System_Configuration-driver_positions': 'driver-position-name',
    'config-System_Configuration-driver_statuses': 'employment-status-name',
    'config-System_Configuration-vehicle_maint_types': 'maintenance-type-name',
    'config-System_Configuration-vehicle_maint_statuses': 'maintenance-status-name',
    'config-System_Configuration-vehicle_maint_locations': 'maintenance-location-name',
    'config-System_Configuration-vehicle_problem_identified': 'problem-identified-name',
    'config-System_Configuration-vehicle_inspectors': 'inspector-name',
    'config-System_Configuration-CDL_classifications': 'classification-name',
    'config-System_Configuration-vehicle_road_call_codes': 'road-call-code-name',
    'config-System_Configuration-typeOfDate': 'exam-type-name',
    'config-System_Configuration-vehicle_manufacturers': 'manufacturer-name',
    'config-System_Configuration-vehicle_statuses': 'vehicle-status-name',
    'config-System_Configuration-vehicle_inspector_categories': 'inspection-category',
    'config-System_Configuration-vehicle_tire-sizes': 'vehicle-tire-size',
    'config-System_Configuration-termination_reasons': 'termination-reason-name',
    'config-System_Configuration-vehicle_body_types': 'vehicle-body-type',
    'config-System_Configuration-vehicle_fuel_caps': 'vehicle-fuel-cap',
    'config-System_Configuration-vehicle_chassis': 'vehicle-chassis',
    'config-System_Configuration-vehicle_body_widths': 'vehicle-body-width',
    'config-System_Configuration-vehicle_lease_companies': 'lease-company',
    'config-System_Configuration-vehicle_models': 'vehicle-model-name',
    'config-System_Configuration-vehicle_fuel_types': 'vehicle-fuel-type',
    'config-System_Configuration-vehicle_wheel_base_types': 'vehicle-wheelbase-type',
    'config-System_Configuration-vehicle_rear_axels': 'rear-axel-name',
    'config-System_Configuration-vehicle_transmission_types': 'vehicle-transmission',
    'config-System_Configuration-wc_lift_types': 'vehicle-wc-lift-type',
    'config-System_Configuration-no_show_reason_categories': 'no-show-reason-category',
    'config-System_Configuration-Zip_Codes': 'zip-code',
    'config-System_Configuration-service_needs_types': 'service-need-type',
    'config-System_Configuration-Activity_Log_Types-booking': 'booking-activity-log-type',
    'config-System_Configuration-Activity_Log_Types-subscription': 'subscription-activity-log-type',
    'config-System_Configuration-speed_region_groups': 'speed-region-group',
    'config-System_Configuration-fare_categories': 'fare-category',
    'config-System_Configuration-eligibility_conditions': 'eligibility-condition',
    'config-System_Configuration-funding_agencies': 'funding-agency',
    'config-System_Configuration-internal_ui_configs': 'internal-ui-config',
    'config-System_Configuration-Trip_Purposes': 'trip-purpose'
  };
  _exports.modelNameForCategory = modelNameForCategory;
});