define("adept-iq/tests/factories/avlm-route-vehicle-driver", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const normalStart = (0, _moment.default)().startOf('day').add(6, 'hours');
  const evening = (0, _moment.default)().startOf('day').add(13, 'hours');
  const night = (0, _moment.default)().startOf('day').add(18, 'hours');
  const normalEnd = (0, _moment.default)().startOf('day').add(26, 'hours');

  _emberDataFactoryGuy.default.define('avlm-route-vehicle-driver', {
    default: {
      startTime: normalStart.toDate(),
      endTime: normalEnd.toDate()
    },
    traits: {
      evening: {
        startTime: evening.toDate()
      },
      night: {
        startTime: night.toDate()
      }
    }
  });
});