define("adept-iq/pods/components/iq-widgets/ember-react-playback/hooks/useOutsideCanceler", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const useOutsideCanceler = (ref, cancel) => {
    _react.default.useEffect(() => {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          cancel();
        }
      }; // Bind the event listener


      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const OutSideCanceler = props => {
    const wrapperRef = _react.default.useRef(null);

    useOutsideCanceler(wrapperRef, props.cancel);
    return _react.default.createElement("div", {
      ref: wrapperRef
    }, props.children);
  };

  var _default = OutSideCanceler;
  _exports.default = _default;
});