define("adept-iq/pods/components/ember-chart-fix/component", ["exports", "ember-cli-chart/components/ember-chart"], function (_exports, _emberChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberChart.default.extend({
    updateChart() {
      const chart = this.get('chart');
      const data = this.get('data');
      const options = this.get('options');
      const animate = this.get('animate');

      if (!data.labels) {
        data.labels = [];
      }

      if (chart) {
        chart.config.data = data;
        chart.options = options;

        if (animate) {
          chart.update();
        } else {
          chart.update(0);
        }
      }
    }

  });

  _exports.default = _default;
});