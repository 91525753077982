define("adept-iq/serializers/reconcile-trip", ["exports", "adept-iq/serializers/-reconciliationService"], function (_exports, _reconciliationService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reconciliationService.default.extend({
    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeQueryResponse(store, clazz, payload) {
      // workaround for this API not returns payload type
      const resultedPayload = [];

      if (!Ember.isEmpty(payload.data)) {
        payload.data.forEach(item => {
          const data = item.attributes;
          let routeId = null;
          if (Object.keys(data).includes('routeId') && data.routeId !== null) routeId = data.routeId;else if (data.route) routeId = data.route.routeId;
          item.attributes = { ...data,
            passengerId: data.passenger ? data.passenger.id : data.passengerId,
            routeId: routeId
          };
          resultedPayload.push(item);
        });
        payload.data = resultedPayload;
      }

      return this._super(store, clazz, payload);
    },

    modelNameFromPayloadKey(key) {
      if (key === 'trip') {
        return 'reconcile-trip';
      }

      return this._super(key);
    }

  });

  _exports.default = _default;
});