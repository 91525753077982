define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/availability-cell", ["exports", "react", "moment", "lodash", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-item", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/portal", "adept-iq/utils/format-text-extension"], function (_exports, _react, _moment, _lodash, _cellItem, _portal, _formatTextExtension) {
  'use strict';

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AVAILABILITY_PROPERTY_NAME = 'availability';
  const VEHICLE_AVAILABILITY_PROPERTY_NAME = 'availabilities';

  class AvailabilityCell extends _cellItem.default {
    constructor(props) {
      super(props);
      this.state = {
        isHovering: false,
        positionX: 0,
        positionY: 0
      };
      this.handleMouseHover = this.handleMouseHover.bind(this);
    }

    handleMouseHover() {
      this.setState({
        positionX: window.pageXOffset + event.clientX,
        positionY: window.pageYOffset + event.clientY
      });
      this.setState(this.toggleHoverState);
    }

    toggleHoverState(state) {
      const clonedState = { ...state
      };
      clonedState.isHovering = !state.isHovering;
      return clonedState;
    }

    render() {
      const {
        data,
        style,
        columnIndex,
        rowIndex,
        columnInfo,
        selectedRowIndex,
        selectedColumnIndex
      } = this.props;
      const {
        isHovering,
        positionX,
        positionY
      } = this.state;
      const toolTipStyles = {
        width: '200px',
        backgroundColor: '#fff',
        color: 'black',
        textAlign: 'center',
        borderRadius: '6px',
        padding: '5px 0',
        position: 'absolute',
        left: `${positionX}px`,
        top: `${positionY}px`,
        zIndex: 1000
      };
      const currentRowData = data[rowIndex - 1];
      const columnValue = currentRowData[VEHICLE_AVAILABILITY_PROPERTY_NAME] || '';
      const classNames = this.getStylesClasses(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue);
      const vehicleAvailabilities = this.getVehicleAvailabilities(currentRowData.data || currentRowData);
      const length = vehicleAvailabilities.filter(val => val.className === 'valueItem').length;
      let columnVal = '';

      if (length > 1) {
        columnVal = `${length} ${this.props.columnInfo.cellDesc}`;
      } else if (length === 1) {
        columnVal = vehicleAvailabilities.firstObject.value;
      }

      return _react.default.createElement("span", {
        role: "none",
        className: classNames,
        style: style,
        onMouseEnter: this.handleMouseHover,
        onMouseLeave: this.handleMouseHover
      }, columnVal, isHovering && _react.default.createElement(_portal.default, null, _react.default.createElement("div", {
        style: toolTipStyles
      }, this.renderToolTipContent(vehicleAvailabilities))));
    }

    renderToolTipContent(vehicleAvailabilities) {
      const mainItemStyle = {
        'font-weight': '600',
        'margin-top': '1px',
        'margin-bottom': '3px',
        'font-size': '13px'
      };
      const valueItemStyle = {
        'margin-bottom': '3px',
        'font-size': '13px'
      };
      if (!vehicleAvailabilities || vehicleAvailabilities.length === 0) return null;
      return _react.default.createElement("div", {
        "class": "cell-text-content-extension"
      }, vehicleAvailabilities.map(item => {
        const style = item.className === 'mainItem' ? mainItemStyle : valueItemStyle;
        return _react.default.createElement("label", {
          style: style
        }, item.value);
      }));
    }

    getVehicleAvailabilities(currentRowData) {
      const active = currentRowData.active;
      const availabilities = [];
      let vehicleAvailabilities = currentRowData[AVAILABILITY_PROPERTY_NAME];

      if (this.props.config.widgetName === 'vehicleWidget') {
        vehicleAvailabilities = currentRowData[VEHICLE_AVAILABILITY_PROPERTY_NAME];
      }

      if (!_lodash.default.isEmpty(vehicleAvailabilities)) {
        if (!_lodash.default.isArray(vehicleAvailabilities)) {
          // Only one item
          vehicleAvailabilities = [vehicleAvailabilities];
        }

        vehicleAvailabilities.forEach(function (item) {
          const startTime = (0, _moment.default)(item.startTime);
          const endTime = (0, _moment.default)(item.endTime);
          const now = (0, _moment.default)();

          if (active && now.isBetween(startTime, endTime)) {
            const formattedShiftStart = item.shiftStart ? (0, _moment.default)(item.shiftStart).format('HH:mm') : '';
            const formattedShiftEnd = item.shiftEnd ? (0, _moment.default)(item.shiftEnd).format('HH:mm') : '';
            availabilities.push((0, _formatTextExtension.formatTextExtension)('mainItem', `${startTime.format('MM-DD-YYYY')} - ${endTime.format('MM-DD-YYYY')}`));

            if (item.monday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Monday ${formattedShiftStart} - ${formattedShiftEnd}`));
            }

            if (item.tuesday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Tuesday  ${formattedShiftStart} - ${formattedShiftEnd}`));
            }

            if (item.wednesday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Wednesday ${formattedShiftStart} - ${formattedShiftEnd}`));
            }

            if (item.thursday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Thursday ${formattedShiftStart} - ${formattedShiftEnd}`));
            }

            if (item.friday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Friday ${formattedShiftStart} - ${formattedShiftEnd}`));
            }

            if (item.saturday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Saturday ${formattedShiftStart} - ${formattedShiftEnd}`));
            }

            if (item.sunday) {
              availabilities.push((0, _formatTextExtension.formatTextExtension)('valueItem', `Sunday ${formattedShiftStart} - ${formattedShiftEnd}`));
            }
          }
        });
      }

      return availabilities;
    }

  }

  _exports.default = AvailabilityCell;
});