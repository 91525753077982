define("adept-iq/pods/components/side-drawer/activity-log/user-activity-log/component", ["exports", "ember-concurrency", "adept-iq/pods/components/side-drawer/activity-log/base-activity-log/component", "adept-iq/pods/components/side-drawer/activity-log/user-activity-log/config"], function (_exports, _emberConcurrency, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const USER_LOGGING_TYPE = 'user';

  var _default = _component.default.extend({
    tagName: '',
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    table: null,
    user: Ember.computed.readOnly('stackFrame.options.user'),
    displayName: Ember.computed.readOnly('stackFrame.options.displayName'),
    isLoading: Ember.computed.alias('fetchEventsTask.isRunning'),
    startDate: Ember.computed.alias('workspace.startDate'),
    endDate: Ember.computed.alias('workspace.endDate'),
    title: Ember.computed.alias('displayName'),
    config: _config.default,
    columns: _config.columns,
    fetchEventsTask: (0, _emberConcurrency.task)(function* () {
      const store = this.store;
      const userId = this.get('user.userId');
      const adaptor = this.get('perm-user.adaptor') ? this.get('perm-user.adaptor') : 'activity-log';
      const activityLogAdapter = this.get('store').adapterFor(adaptor);

      function fetchFullRecords(activityLogs) {
        let driverDetails = null;
        return Promise.all(activityLogs.data.map(activityLog => {
          const driver = store.peekRecord('driver', activityLog.attributes.driverId);
          const vehicle = store.peekRecord('vehicle', activityLog.attributes.vehicleId);

          if (driver) {
            activityLog.attributes.driver = driver;
            driverDetails = driver;
          } else if (activityLog.attributes.driverId && parseInt(activityLog.attributes.driverId, 10) > 0) {
            store.findRecord('driver', activityLog.attributes.driverId).then(driverModel => {
              activityLog.attributes.driver = driverModel;
              driverDetails = driver;
            });
          }

          if (activityLog.attributes.actionType === 'noShow' && driverDetails) {
            activityLog.attributes.driver = driverDetails;
          }

          if (vehicle) {
            activityLog.attributes.vehicle = vehicle;
          } else if (activityLog.attributes.vehicleId && parseInt(activityLog.attributes.vehicleId, 10) > 0) {
            store.findRecord('vehicle', activityLog.attributes.vehicleId).then(vehicleModel => {
              activityLog.attributes.vehicle = vehicleModel;
            });
          }
        })).then(() => {
          return activityLogs;
        });
      } // clear events table


      this.set('events', []);
      yield activityLogAdapter.getActivityLogs({
        userId,
        eventLog: true,
        type: 'user'
      }).then(async activityLogs => {
        return fetchFullRecords(activityLogs);
      }).then(activityLogs => {
        this.processActivityLogs(activityLogs, USER_LOGGING_TYPE);
      });
    }).drop()
  });

  _exports.default = _default;
});