define("adept-iq/models/perm-sso-role", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    permissionLayer: Ember.inject.service(),
    permissionHash: null,
    groupedPermissions: null,
    roleName: attr('string'),
    name: attr('string'),
    displayName: attr('string'),
    status: attr('string'),
    permissions: attr(),
    permissionList: attr(),
    users: hasMany('sso-user'),
    rolePermissions: hasMany('role-permission'),
    validRolePermissons: Ember.computed('rolePermissions', function () {
      const rolePermissions = this.get('rolePermissions');
      return rolePermissions.filter(role => role && role.get('category'));
    }),
    permissionsGroupByCatgories: Ember.computed('permissionLayer.permissions', function () {
      const allPermissions = this.get('permissionLayer.permissions').toArray();
      return (0, _lodash.default)(allPermissions).groupBy(p => p.get('category').replace(/\s/g, '_').toUpperCase()).value();
    }),
    rolePermissionsGroupByCatgories: Ember.computed('validRolePermissons', 'permissionsGroupByCatgories', function () {
      const permissionsGroupByCatgories = this.get('permissionsGroupByCatgories');
      const rolePermissions = this.get('validRolePermissons');
      const result = {};
      Object.keys(permissionsGroupByCatgories).forEach(category => {
        const permissionsGranted = rolePermissions.filter(p => p.get('category').replace(/\s/g, '_').toUpperCase() === category.replace(/\s/g, '_').toUpperCase()).length;
        const allPermissions = permissionsGroupByCatgories[category.toUpperCase()].length;
        result[category.toUpperCase()] = `${permissionsGranted}/${allPermissions}`;
      });
      return result;
    }),

    reconfigurePermissionHash(permissions) {
      const permissionList = this.get('permissionList');
      const permHash = permissions.reduce((acu, perm) => {
        acu[perm.parsedId] = false;
        return acu;
      }, {});
      permissionList.forEach(id => {
        permHash[id] = true;
      });
      this.set('permissionHash', permHash);
    },

    reconfigurePermissionCheck(permissions) {
      const permissionList = this.get('permissionList');
      permissions.map(data => {
        permissionList.map(item => {
          if (item === data.parsedId) {
            data.state = true;
          }
        });
      });
    }

  });

  _exports.default = _default;
});