define("adept-iq/pods/components/form-components/on-board-trips/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lG3mdbAQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"class\",\"replacement-onboard-trips-label\"],[9],[0,\"if the checkbox is deselected, you will need to move these trips manually in the trip widget\"],[10],[0,\"\\n\"],[7,\"br\"],[9],[10],[0,\"\\n\"],[1,[27,\"input\",null,[[\"class\",\"type\",\"checked\",\"click\"],[\"replacement-onboard-trips-checkbox\",\"checkbox\",[23,[\"value\"]],[27,\"action\",[[22,0,[]],\"onCheckboxChange\",[23,[\"record\"]],[23,[\"replacementMoveAllTripsPath\"]]],null]]]],false],[0,\"\\n\"],[7,\"label\"],[11,\"class\",\"replacement-onboard-trips-label2\"],[9],[0,\"Move all On-board Trips on to the Replacement Route.\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/on-board-trips/template.hbs"
    }
  });

  _exports.default = _default;
});