define("adept-iq/serializers/rider-funding", ["exports", "adept-iq/serializers/-rms-riderManagement"], function (_exports, _rmsRiderManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rmsRiderManagement.default.extend({
    attrs: {
      fundingAgency: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      const attributes = json.data.attributes;
      const relationships = json.data.relationships;
      const fundingAgencyName = Ember.isPresent(attributes) ? attributes.fundingAgencyName : null;

      if (Ember.isPresent(fundingAgencyName)) {
        if (Ember.isPresent(relationships)) {
          relationships.fundingAgencyName = {
            data: {
              id: fundingAgencyName,
              type: 'fundingAgencyName'
            }
          };
        }

        delete attributes.fundingAgencyName;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.relationships) && !Ember.isNone(resourceHash.relationships.fundingAgencyName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.fundingAgency = {
          data: {
            type: 'funding-agency',
            id: relationships.fundingAgencyName.data.id
          }
        };
        resourceHash.attributes.fundingAgencyName = relationships.fundingAgencyName.data.id;
        resourceHash.relationships = relationships;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});