define("adept-iq/services/drag-coordinator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    sourceRows: null,
    widgetType: null,

    init() {
      this._super(...arguments);

      this.set('sourceRows', {});
    },

    // called when a widget registers dragStart
    setSourceRow(dragId, row, widgetType) {
      Ember.set(this.get('sourceRows'), dragId, row);
      this.set('widgetType', widgetType);
    },

    // called when a widget registers drop
    getSourceRow(dragId) {
      if (Ember.isBlank(dragId)) return;
      const sourceRow = this.get(`sourceRows.${dragId}`);
      this.set('sourceRows', {});
      return sourceRow;
    },

    // called when a widget registers dragEnd
    clearSourceRows() {
      this.set('sourceRows', {});
      this.set('widgetType', null);
    }

  });

  _exports.default = _default;
});