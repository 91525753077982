define("adept-iq/mixins/async-booking-operation", ["exports", "ember-concurrency", "adept-iq/config/api-urls"], function (_exports, _emberConcurrency, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const BOOKING_HOST = _apiUrls.API.bookingService.host;
  const TIMEOUT_DELAY = 1000; // 1 seconds tries

  var _default = Ember.Mixin.create({
    /**
     * @param booking,
     * @param operation -> 'import or 'export'
     * @param options -> { operationData, outputResult }
     */
    createBookingAsyncOperation: (0, _emberConcurrency.task)(function* (booking, operationType, options) {
      const session = this.get('session');
      const isPrimaryBookingType = operationType === 'primaryBooking';
      const op = {
        'data': {
          'type': 'bookingAsyncOperation',
          'attributes': {
            'operationType': operationType
          },
          'relationships': {
            'booking': {
              'data': {
                'type': 'booking',
                'id': isPrimaryBookingType ? booking.data.attributes.temporaryBookingId : booking.get('id')
              }
            }
          }
        }
      };
      const {
        operationData,
        outputResult
      } = options || {};

      if (operationData) {
        op.data.attributes.operationData = operationData;
      }

      const job = yield this.get('ajax').post(BOOKING_HOST + '/booking-async-operation', {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: op
      });
      if (!job) return false;
      return yield this.get('asyncBookingOperationState').perform(job, outputResult);
    }).enqueue(),
    createTripsBookingAsyncOperation: (0, _emberConcurrency.task)(function* (payload, operationType, options) {
      const session = this.get('session');
      const {
        outputResult
      } = options || {};
      const job = yield this.get('ajax').post(BOOKING_HOST + '/booking-async-operation', {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: payload
      });
      if (!job) return false;
      return yield this.get('asyncBookingOperationState').perform(job, outputResult);
    }).enqueue(),

    /**
     * @param outputResult => boolean
     */
    asyncBookingOperationState: (0, _emberConcurrency.task)(function* (job, outputResult) {
      const session = this.get('session');

      while (job.data.attributes.state !== 'success' && job.data.attributes.state !== 'failure') {
        yield (0, _emberConcurrency.timeout)(TIMEOUT_DELAY);

        try {
          // eslint-disable-next-line no-param-reassign
          job = yield this.get('ajax').request(BOOKING_HOST + '/booking-async-operation/' + job.data.id, {
            method: 'GET',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          });
        } catch (e) {
          throw e;
        }
      }

      const isJobSuccess = job.data.attributes.state === 'success';

      if (outputResult) {
        return { ...job.data.attributes,
          isJobSuccess
        };
      }

      return isJobSuccess;
    }).drop()
  });

  _exports.default = _default;
});