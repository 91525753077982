define("adept-iq/tests/factories/subscription", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const dayMoment = (0, _moment.default)().startOf('day').add(6, 'hours');
  const eveningMoment = (0, _moment.default)().startOf('day').add(14, 'hours');
  const nightMoment = (0, _moment.default)().startOf('day').add(18, 'hours');
  const purposeArray = ['work', 'medical', 'school'];

  _emberDataFactoryGuy.default.define('subscription', {
    sequences: {
      purposes: num => purposeArray[num % purposeArray.length]
    },
    default: {
      requestTime: dayMoment.toDate(),
      anchor: 'pick',
      purpose: _emberDataFactoryGuy.default.generate('purposes'),
      fare: 2,
      originNotes: 'Some origin Notes',
      destinationNotes: 'Some Destination notes',
      startDate: dayMoment.toDate(),
      endDate: nightMoment.toDate(),
      maximumOccurrences: 0,
      serviceWindowName: 'default',
      remainingOccurrences: 0
    },
    traits: {
      morning: {
        requestTime: dayMoment.toDate()
      },
      evening: {
        requestTime: eveningMoment.toDate()
      },
      night: {
        requestTime: nightMoment.toDate()
      }
    }
  });
});