define("adept-iq/models/trip-stop", ["exports", "ember-data", "adept-iq/utils/unwrapProxy", "adept-iq/models/trip"], function (_exports, _emberData, _unwrapProxy, _trip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  const TRIP_STATUSES_TO_IGNORE = [_trip.TRIP_CANCELED_STATUS, _trip.TRIP_LATE_CANCELED_STATUS];

  var _default = Model.extend({
    plannedDistanceToNext: attr('string'),
    // in miles
    plannedTravelTimeToNext: attr('string'),
    // in minutes
    plannedEta: attr('date'),
    status: attr('string'),
    slack: attr('string'),
    plannedSlackTime: attr('number'),
    plannedRouteOrdinal: attr('number'),
    stopPoint: belongsTo('stop-point', {
      inverse: 'tripStop'
    }),
    pickTripStop: belongsTo('trip', {
      inverse: 'pick'
    }),
    dropTripStop: belongsTo('trip', {
      inverse: 'drop'
    }),
    segmentStop: belongsTo('segment-stop', {
      inverse: 'tripStop'
    }),
    travelNeeds: Ember.computed.readOnly('trip.segment.leg.legTravelNeeds'),
    lat: Ember.computed.readOnly('segmentStop.lat'),
    lng: Ember.computed.readOnly('segmentStop.lng'),
    type: Ember.computed.readOnly('segmentStop.type'),
    stopType: Ember.computed.readOnly('type'),
    // stops-widget uses stopType property for active context filtering
    notes: Ember.computed.alias('segmentStop.notes'),
    address: Ember.computed.alias('segmentStop.place.address.readableAddress'),
    city: Ember.computed.alias('segmentStop.place.address.locality'),
    routeName: Ember.computed.readOnly('trip.route.name'),
    scheduleOrdinal: attr('number'),
    eta: attr('string'),
    scheduleEta: attr('string'),
    // populated from stops-widget iqux-service
    segmentStopPhoneNumbers: attr('object'),
    formattedSegmentStopPhoneNumbers: attr('object'),
    scheduleStopStatus: attr('string'),
    sdbPlannedEta: attr('date'),
    computedPlannedEta: Ember.computed('plannedEta', 'sdbPlannedEta', 'trip.computedStatus', function () {
      const computedStatus = this.get('trip.computedStatus');
      const sdbPlannedEta = this.get('sdbPlannedEta');
      const plannedEta = this.get('plannedEta');
      if (TRIP_STATUSES_TO_IGNORE.includes(computedStatus)) return '';
      if (!Ember.isEmpty(sdbPlannedEta)) return sdbPlannedEta;
      return plannedEta;
    }),
    segmentStopPhoneNumber: Ember.computed('segmentStop.phoneNumbers.[]', function () {
      const phoneNumber = this.get('segmentStop.phoneNumbers.firstObject');
      return phoneNumber ? phoneNumber : null;
    }),
    trip: Ember.computed('pickTripStop', 'dropTripStop', function () {
      const trip = (0, _unwrapProxy.unwrapProxy)(this.get('pickTripStop'));
      return trip ? trip : (0, _unwrapProxy.unwrapProxy)(this.get('dropTripStop'));
    }),
    mapId: Ember.computed('type', function () {
      const stopType = this.get('type');
      return stopType;
    }),
    isPick: Ember.computed('stopType', function () {
      return this.get('stopType') === 'pick';
    }),
    isDrop: Ember.computed('stopType', function () {
      return this.get('stopType') === 'drop';
    }),
    route: Ember.computed('trip', function () {
      const route = this.get('trip.route');
      return route;
    }),
    isLocked: Ember.computed('route.isLocked', function () {
      return this.get('route.isLocked');
    }),
    lockedBy: Ember.computed('route.lockedBy', function () {
      return this.get('route.lockedBy');
    }),
    lockedId: Ember.computed('isLocked', function () {
      return this.get('route.id');
    }),
    isLockedByOtherUser: Ember.computed('route.isLockedByOtherUser', function () {
      return this.get('route.isLockedByOtherUser');
    }),
    computedStatus: Ember.computed('status', function () {
      const status = this.get('status') !== '' ? this.get('status') : null;
      return status;
    }),
    computedSlack: Ember.computed('slack', function () {
      const slack = this.get('slack') !== '' ? this.get('slack') : null;
      return slack;
    })
  });

  _exports.default = _default;
});