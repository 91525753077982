define("adept-iq/pods/components/rider-management-widget/rider-contact-information/new-image-dialog/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    rider: Ember.inject.service(),
    isThumbnailEditing: false,

    init() {
      this._super(...arguments);
    },

    actions: {
      closeImageDialog() {
        this.set('canShowNewImageModal', false);
      },

      onRiderImageChange(event) {
        const isImageEditing = this.get('isImageEditing');

        if (isImageEditing) {
          this.get('previewImage')(event);
        }
      },

      async onRiderImageSave() {
        this.set('canShowNewImageModal', false);
        this.set('isThumbnailEditing', true);
        const thumbnailData = this.get('thumbnailData');
        const riderChangeSet = this.get('riderChangeSet');
        const thumbnail = JSON.stringify(thumbnailData);
        riderChangeSet.set('thumbnail', thumbnail);
        Ember.$('#thumbnailPreview').attr('src', thumbnailData.image);
      }

    }
  });

  _exports.default = _default;
});