define("adept-iq/models/avlm-trip", ["exports", "ember-data", "adept-iq/mixins/otp-label", "moment"], function (_exports, _emberData, _otpLabel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.REMOVE_STATUS_CONVERTER = void 0;
  const ESTIMATED_TRIP_GUESS_BY_HOURS = 24;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  const REMOVE_STATUS_CONVERTER = {
    'X': 'R'
  };
  _exports.REMOVE_STATUS_CONVERTER = REMOVE_STATUS_CONVERTER;
  const REMOVE_STATUS = 'R';

  var _default = Model.extend(_otpLabel.default, {
    activeContext: Ember.inject.service(),
    providerName: attr('string'),
    status: attr('string'),
    otp: attr('string'),
    otpValue: attr('number'),
    fare: attr('string'),
    noShowCodeId: attr('string'),
    externalId: attr('string'),
    otpStatus: attr('string'),
    reason: belongsTo('no-show-reason-code'),
    avlmRoute: belongsTo('avlm-route'),
    avlmSchedule: belongsTo('avlm-schedule'),
    avlmStopPoints: hasMany('avlm-stop-point'),
    avlmTripRider: belongsTo('avlm-trip-rider'),
    requestedTime: Ember.computed.alias('avlmTripRider.avlmRider.anchoredRiderEvent.scheduledTime'),
    statusChanged: Ember.observer('status', function () {
      const status = REMOVE_STATUS_CONVERTER[this.get('status')];
      const oldStatus = this.get('_oldStatus');

      if (status === REMOVE_STATUS && oldStatus !== REMOVE_STATUS) {
        // cache old value
        this.set('_oldStatus', status);
        this.get('activeContext.topActiveContext').refreshTableContent.perform(['avlm-trip']);
      } else if (oldStatus === REMOVE_STATUS && status !== REMOVE_STATUS) {
        // cache old value
        this.set('_oldStatus', status);
        this.get('activeContext.topActiveContext').refreshTableContent.perform(['avlm-trip']);
      }
    }),
    mapId: Ember.computed('status', function () {
      const statusLabel = this.get('statusLabel');
      return statusLabel;
    }),
    actualMiles: Ember.computed('pick.odometer', 'drop.odometer', function () {
      const pickOdometer = this.get('pick.convertedOdometer');
      const dropOdometer = this.get('drop.convertedOdometer');
      if (pickOdometer && dropOdometer) return Math.abs(pickOdometer - dropOdometer);
    }),
    otpValueWithSign: Ember.computed('otp', 'otpValue', function () {
      const otp = this.get('otp');
      const otpValue = this.get('otpValue');
      const sign = otp === 'O' || otp === 'E' ? '-' : '+';

      if (otpValue) {
        return `${sign}${otpValue}`;
      }

      return '';
    }),
    pick: Ember.computed('avlmStopPoints.@each.type', function () {
      return this.get('avlmStopPoints').sortBy('routeIndex')[0];
    }),
    removedStatus: Ember.computed('status', function () {
      return REMOVE_STATUS_CONVERTER[this.get('status')];
    }),
    statusLabel: Ember.computed('status', function () {
      const status = this.status;
      const noShowCodeId = this.get('noShowCodeId');
      let statusLabel = '';

      switch (status) {
        case 'A':
          statusLabel = 'Active';
          break;

        case 'N':
          statusLabel = 'No show';
          break;

        case 'C':
          statusLabel = 'Completed';
          break;

        case 'X':
          statusLabel = 'Cancelled';
          break;

        case 'R':
          statusLabel = 'Driver Arrived';
          break;

        case 'P':
          statusLabel = 'Passenger on board';
          break;

        case 'O':
          statusLabel = 'On time';
          break;

        case 'D':
          statusLabel = 'In danger';
          break;

        case 'L':
          statusLabel = 'Late';
          break;

        case 'E':
          statusLabel = 'Early';
          break;

        default:
          statusLabel = status;
          break;
      }

      if (noShowCodeId !== '' && noShowCodeId !== null && typeof noShowCodeId !== 'undefined') {
        statusLabel = 'No show';
      }

      return statusLabel;
    }),
    tripStartTime: Ember.computed('pick.{actualArrivalTime,eta}', 'requestedTime', function () {
      const requestedTime = this.get('requestedTime');
      const eta = this.get('pick.eta');
      const pickActualTime = this.get('pick.actualArrivalTime');
      let actual = null;
      let planned = null;

      if (requestedTime) {
        planned = requestedTime.getTime();
        actual = planned;
      }

      if (pickActualTime) {
        actual = pickActualTime.getTime();
      }

      if (!planned && eta) {
        planned = eta.getTime();
      }

      if (!actual) {
        actual = planned;
      }

      return new Date(Math.min(actual, planned));
    }),
    drop: Ember.computed('avlmStopPoints.@each.type', function () {
      return this.get('avlmStopPoints').sortBy('routeIndex')[1];
    }),
    tripEndTime: Ember.computed('drop.{actualArrivalTime,actualDepartTime,eta}', 'requestedTime', function () {
      const requestedTime = this.get('requestedTime');
      const eta = this.get('drop.eta');
      const actualArrivalTime = this.get('drop.actualArrivalTime');
      const actualDepartTime = this.get('drop.actualDepartTime');
      let planned = null;
      let actualStart = null;
      let actualEnd = null;

      if (requestedTime) {
        planned = this.get('requestedTime').getTime();
        actualStart = planned;
      }

      if (eta && !actualStart) {
        actualStart = eta.getTime();
      }

      if (!actualEnd && actualStart) {
        return (0, _moment.default)(actualStart).add(ESTIMATED_TRIP_GUESS_BY_HOURS, 'hours').toDate();
      }

      if (actualArrivalTime) {
        actualStart = actualArrivalTime.getTime();
      }

      if (actualDepartTime) {
        actualEnd = actualDepartTime.getTime();
      }

      return new Date(Math.max(actualEnd, actualStart));
    }),
    routeTripStartTime: Ember.computed('tripStartTime', 'route.tripStartTime', function () {
      const routeStart = this.get('route.tripStartTime');
      const tripStart = this.get('tripStartTime');

      if (routeStart) {
        return routeStart;
      }

      return tripStart;
    }),
    routeTripEndTime: Ember.computed('tripEndTime', 'route.tripEndTime', function () {
      const routeEnd = this.get('route.tripEndTime');
      const tripEnd = this.get('tripEndTime');

      if (routeEnd) {
        return routeEnd;
      }

      return tripEnd;
    }),
    polyline: Ember.computed('pick.{lat,lng}', 'drop.{lat,lng}', function () {
      const pickLat = this.get('pick.lat');
      const pickLng = this.get('pick.lng');
      const dropLat = this.get('drop.lat');
      const dropLng = this.get('drop.lng');

      if ([pickLat, pickLng, dropLat, dropLng].any(Ember.isNone)) {
        return null;
      } // Should display like P----->D, but decorator(>) can only set on start point, so we need to make D as start point


      return [[dropLat, dropLng], [pickLat, pickLng]];
    })
  });

  _exports.default = _default;
});