define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/availability/component", ["exports", "ember-concurrency", "lodash", "ember-changeset-cp-validations", "ember-changeset"], function (_exports, _emberConcurrency, _lodash, _emberChangesetCpValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_AVAILABILITY_TYPE_MAP = {
    addVehicleAvailability: true,
    editVehicleAvailability: true,
    deleteVehicleAvailability: true
  };

  var _default = Ember.Component.extend({
    classNames: ['availability'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    editAPI: null,
    availabilities: null,
    resetRecords: true,
    selectedVehicle: Ember.computed.readOnly('editAPI.selectedRecord'),
    modelName: 'vehicle-availability',
    // this observer used for undo enabled and disable in availability screen NYAAR-17165
    onAvailabilityChange: Ember.observer('availabilities.@each.changes.length', function () {
      const availabilities = this.get('availabilities');
      const availabilitiesChanges = availabilities.reduce((acu, availability) => {
        const changes = availability.get('changes') || [];
        return acu + changes.length;
      }, 0);

      if (availabilitiesChanges > 0) {
        const editAPI = this.get('editAPI');
        editAPI.setProperties({
          isChangesHappened: true
        });
      }
    }),

    init() {
      this._super(...arguments);

      const vehicle = this.get('selectedVehicle');
      this.set('activityQueryParams', {
        vehicleId: `${vehicle.get('id')}`,
        eventLog: true
      });
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const vehicleId = this.get('selectedVehicle.id');
      const onSaveAvailabilityRecord = this.get('onSaveAvailabilityRecord');
      const onUndoAvailabilityRecord = this.get('onUndoAvailabilityRecord').bind(this);
      const hasChanges = this.get('hasChanges').bind(this);
      const onValidateAction = this.get('validate');
      if (vehicleId) this.fetchVehicleAvailablities(vehicleId);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveAvailabilityRecord,
          undoAction: onUndoAvailabilityRecord,
          undoBtn: true,
          saveBtn: false,
          newAction: false,
          deleteAction: false,
          editAction: false,
          onValidateAction,
          hasChanges,
          isChangesHappened: false
        });
      });
    },

    async refreshTask() {
      const tableRef = this.get('tableRef');
      const vehicleId = this.get('selectedVehicle.id');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      if (vehicleId) this.fetchVehicleAvailablities(vehicleId);
    },

    async fetchVehicleAvailablities(vehicleId) {
      const vehicleAvailabilities = await this.get('store').query('vehicle-availability', {
        filter: `eq('vehicleId','${vehicleId}')`
      });
      this.set('availabilities', this.configureVehicleAvailabilities(vehicleAvailabilities));
    },

    configureVehicleAvailabilities(availabilities) {
      const changesets = availabilities.map(availability => {
        const {
          validateFn,
          validationMap
        } = (0, _emberChangesetCpValidations.buildChangeset)(availability);
        return new _emberChangeset.default(availability, validateFn, validationMap);
      });
      return changesets;
    },

    hasPendingChanges: Ember.computed('availabilities.@each.changes.length', function () {
      const availabilities = this.get('availabilities') || [];
      return availabilities.reduce((acu, availability) => {
        const changes = availability.get('changes') || [];
        return acu + changes.length;
      }, 0);
    }),

    hasChanges() {
      return this.get('hasPendingChanges');
    },

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_AVAILABILITY_TYPE_MAP[event.get('actionType')];
    },

    onUndoAvailabilityRecord() {
      const store = this.get('store');
      const availabilities = store.peekAll(this.get('modelName')) || [];
      const editAPI = this.get('editAPI');
      availabilities.forEach(availability => {
        availability.rollbackAttributes();
      });
      const vehicleAvailabilities = this.get('editAPI.selectedRecord.vehicleAvailabilities') || [];
      this.set('availabilities', this.configureVehicleAvailabilities(vehicleAvailabilities));
      editAPI.setProperties({
        undoBtn: true
      });
      editAPI.setProperties({
        isChangesHappened: false
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const availabilities = this.get('availabilities').filter(avail => !avail.get('hasDeleted')) || [];
      let errors = [];
      yield availabilities.reduce(async (acu, availability) => {
        await availability.validate();
        errors.push(...availability.errors);
        return acu.then(() => Promise.resolve());
      }, Promise.resolve());
      errors = (0, _lodash.uniqBy)(errors, error => error.key);

      if (errors.length) {
        this.set('shouldDisplayValidations', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.reset();
          }

        });
        return false;
      }

      return true;
    }),

    getAvailabilities() {
      const availabilities = this.get('availabilities');
      return availabilities.filter(avail => {
        if (avail.get('isNew')) return avail.get('isNew');
        const availabilityChanges = avail.get('changes');
        const filteredAvailabilityChanges = availabilityChanges.filter(availabilityChange => availabilityChange.key !== 'endTime' && availabilityChange.key !== 'startTime');

        if (!Ember.isEmpty(filteredAvailabilityChanges)) {
          return avail.data;
        }
      });
    },

    onSaveAvailabilityRecord: (0, _emberConcurrency.task)(function* () {
      const validate = this.get('validate');
      const isValid = yield validate.perform();
      const selectedVehicle = this.get('selectedVehicle');
      const notifications = this.get('notifications');
      const changedAvailabilities = this.getAvailabilities() || [];
      const editAPI = this.get('editAPI');
      const tooltip = this.get('tooltip');
      const deletedAvailabilities = this.get('availabilities').filter(avail => avail.get('hasDeleted')) || [];
      deletedAvailabilities.forEach(avail => avail.data.deleteRecord());
      const availabilities = changedAvailabilities.map(avail => {
        avail.set('vehicle', selectedVehicle);
        if (!avail.data.isDeleted) avail.execute();
        return avail.data;
      }) || [];

      if (isValid) {
        try {
          const newAvailability = yield availabilities.reduce((acu, availability) => {
            const calcEndDate = availability.get('calcEndDate');
            if (!availability.get('isDeleted')) availability.set('shiftEnd', calcEndDate);
            return acu.then(() => availability.save());
          }, Promise.resolve());
          notifications.success('Record successfully saved.');
          editAPI.setProperties({
            undoBtn: true
          });
          this.refreshTask();
          this.set('shouldDisplayValidations', false);
          tooltip.reset();
          return newAvailability;
        } catch (e) {
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line
        }
      }
    })
  });

  _exports.default = _default;
});