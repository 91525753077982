define("adept-iq/services/ada-service", ["exports", "adept-iq/config/api-urls"], function (_exports, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    // this is used for rendering map
    activeCalendarId: null,

    init() {
      this._super(...arguments);
    },

    fetchAgencyServiceByAgencyId() {
      return new Promise(async (resolve, reject) => {
        this.get('ajax').request(_apiUrls.API.adaService.host + '/agency-service/', {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
          }
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      });
    },

    fetchRouteCalendarByServiceId(selectedAgencyServiceId) {
      return new Promise(async (resolve, reject) => {
        this.get('ajax').request(_apiUrls.API.adaService.host + '/route-calendar/' + selectedAgencyServiceId, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
          }
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      });
    },

    fetchRouteShapeByCalendarId(selectedRouteCalendarId) {
      return new Promise(async (resolve, reject) => {
        this.get('ajax').request(_apiUrls.API.adaService.host + '/route-shape/' + selectedRouteCalendarId, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
          }
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      });
    },

    setActiveCalender(calendarId) {
      this.set('activeCalendarId', calendarId);
    }

  });

  _exports.default = _default;
});