define("adept-iq/pods/components/side-drawer-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YCw3d1AH",
    "block": "{\"symbols\":[\"sideDrawer\"],\"statements\":[[4,\"modal-dialog\",null,[[\"containerClassNames\",\"overlayClass\",\"targetAttachment\",\"hasOverlay\"],[[23,[\"containerClassNames\"]],\"edit-widget-overlay\",[23,[\"targetAttachment\"]],false]],{\"statements\":[[4,\"if\",[[27,\"not\",[[23,[\"showRightPanel\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"dashboard/modals/edit-form/resize-handle\",null,[[\"onResizeModal\"],[[27,\"action\",[[22,0,[]],\"resizeModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"     \"],[7,\"div\"],[11,\"class\",\"side-drawer-buttons btn-toolbar justify-content-between side-drawer-shrink-btn\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"side-drawer-button\"],[11,\"data-test-toggle-side-drawer\",\"\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onShrinkClick\"],null]],[9],[0,\"\\n        \"],[7,\"span\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-angle-double-right\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"sideDrawers\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"style\",[28,[\"height:inherit;  visibility:\",[22,1,[\"visibility\"]]]]],[9],[0,\"\\n        \"],[1,[27,\"component\",[[22,1,[\"name\"]]],[[\"stackFrame\",\"currentState\",\"setSideDrawerWidth\",\"onCloseClick\",\"onShrinkClick\"],[[22,1,[\"stackFrame\"]],[22,1,[\"currentState\"]],[27,\"action\",[[22,0,[]],\"setSideDrawerWidth\"],null],[27,\"action\",[[22,0,[]],\"onCloseClick\"],null],[27,\"action\",[[22,0,[]],\"onShrinkClick\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/side-drawer-container/template.hbs"
    }
  });

  _exports.default = _default;
});