define("adept-iq/pods/components/side-drawer/scheduling-add-break/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fields = void 0;
  const fields = {
    id: 'destinationaddresses',
    type: 'addresses',
    valuePath: 'place',
    modelName: 'place',
    extra: {
      aliasPath: 'address.alias',
      notesPath: 'address.notes',
      premisePath: 'address.premise',
      streetNumberPath: 'address.streetNumber',
      streetNamePath: 'address.streetName',
      localityPath: 'address.locality',
      regionPath: 'address.region',
      subRegionPath: 'address.county',
      postalCodePath: 'address.postalCode',
      countryPath: 'address.country',
      freeformAddressPath: 'address.freeformAddress',
      tomtomFormattedAddressPath: 'address.tomtomFormattedAddress',
      useOptionRecord: true,
      streetAddressPath: 'address.streetAddress'
    }
  };
  _exports.fields = fields;
});