define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/maint-type/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/maint-type/validation", "adept-iq/config/placeholders", "lodash", "adept-iq/utils/guid"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset, _emberChangesetValidations, _validation, _placeholders, _lodash, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['vehicle-lookup-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // placeholder
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    tableRef: null,
    description: null,
    editAPI: null,
    disableForm: true,
    showErrorMsg: false,
    currentDisplayName: null,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    vehicleMaintTypeChangeSet: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),
    hasPendingChanges: Ember.computed('vehicleMaintTypeChangeSet.changes.length', function () {
      const vehicleMaintTypeChangeSet = this.get('vehicleMaintTypeChangeSet');
      const changes = vehicleMaintTypeChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      return this.get('hasPendingChanges');
    },

    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleMaintTypeNameRecord = this.get('onSaveVehicleMaintTypeNameRecord');
      const onNewVehicleMaintTypeNameRecord = this.get('onNewVehicleMaintTypeNameRecord').bind(this);
      const onEditVehicleMaintTypeNameRecord = this.get('onEditVehicleMaintTypeNameRecord').bind(this);
      const refreshTask = this.get('refreshTask');
      const onUndoVehicleMaintTypeNameRecord = this.get('onUndoVehicleMaintTypeNameRecord').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      const hasRelationship = this.get('hasRelationship').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleMaintTypeNameRecord,
          newAction: onNewVehicleMaintTypeNameRecord,
          editAction: onEditVehicleMaintTypeNameRecord,
          undoAction: onUndoVehicleMaintTypeNameRecord,
          deleteAction: null,
          onValidateAction,
          hasChanges,
          refreshTask,
          hasRelationship
        });
        refreshTask.perform();
      });
    },

    willDestroyElement() {
      const records = this.get('tableRef.records') || [];
      records.map(record => {
        if (record.get('tableRef')) {
          record.set('tableRef');
        }
      });

      this._super(...arguments);
    },

    hasRelationship() {
      const store = this.get('store');
      const tooltip = this.get('tooltip');
      const description = this.get('vehicleMaintTypeChangeSet.description') || ''; // in the future we should actually use an rql query to figure out which vehicles are using this maintTypeName
      // and have the backend process it for us, currently and name field and description can be different though,
      // so we might need to think about it this a bit more

      const vehicle = store.peekAll('vehicle').filter(record => record.get('active') && record.get('vehicleMaintTypeName.description') === description);

      if (vehicle.length) {
        tooltip.pushConfirmation({
          title: 'Lookup',
          hasoverlay: true,
          tip: `There are ${vehicle.length} active vehicle(s) with this Vehicle Maintenance Type description. You must either change the Vehicle Maintenance Type description on these vehicle(s) or delete these vehicle(s) prior to changing the Vehicle Maintenance Type description.`,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.reset();
          }

        });
      }

      return vehicle.length;
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const tableRef = this.get('tableRef');
      let vehicleMaintTypeNameObj = this.get('pendingRecord');

      if (record) {
        vehicleMaintTypeNameObj = record;
        this.resetForm();
      }

      if (Ember.isEmpty(record)) {
        const data = {
          category: 'config-System_Configuration-vehicle_maint_types',
          type: 'object'
        };
        vehicleMaintTypeNameObj = store.createRecord('cs-config-item', data);
        this.set('pendingRecord', vehicleMaintTypeNameObj);
      }

      if (vehicleMaintTypeNameObj.get('isNew')) {
        vehicleMaintTypeNameObj.setProperties({
          description: null,
          tableRef: tableRef
        });
      }

      this.set('showErrorMsg', false);
      const tableRefRecords = this.get('tableRef.records') || [];
      tableRefRecords.map(tableRefRecord => {
        if (!tableRefRecord.get('tableRef')) {
          tableRefRecord.set('tableRef', tableRef);
        }
      });
      return new _emberChangeset.default(vehicleMaintTypeNameObj, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('vehicleMaintTypeChangeSet');

      if (selectedRecord) {
        if (!selectedRecord.get('isNew') && !selectedRecord.get('isDeleted')) {
          yield selectedRecord.data.reload();
        }
      }

      const tableRef = this.get('tableRef');
      this.resetForm();
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        editAPI.buttonSettings();
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const VehicleMaintTypeNameRecord = this.get('vehicleMaintTypeChangeSet');
      yield VehicleMaintTypeNameRecord.validate();
      const errors = VehicleMaintTypeNameRecord.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveVehicleMaintTypeNameRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');
      const activityIdGuid = (0, _guid.guid)();
      if (!isValid) return;
      const notifications = this.get('notifications');
      const description = this.get('vehicleMaintTypeChangeSet.description') || '';
      let scheduled = this.get('vehicleMaintTypeChangeSet.scheduled');

      if (_lodash.default.isUndefined(scheduled)) {
        scheduled = false;
      }

      const value = {
        description: description,
        scheduled: scheduled
      };
      const nameGuidConverted = `${description}-${activityIdGuid}`;

      const name = _lodash.default.camelCase(nameGuidConverted);

      const vehicleMaintTypeChangeSet = this.get('vehicleMaintTypeChangeSet');
      let vehicleMaintTypeNameRecord = this.get('vehicleMaintTypeChangeSet');

      if (vehicleMaintTypeNameRecord.get('isNew')) {
        vehicleMaintTypeNameRecord = (0, _unwrapProxy.unwrapProxy)(this.get('vehicleMaintTypeChangeSet'));
        vehicleMaintTypeNameRecord.set('name', name);
      } else {
        const id = this.get('vehicleMaintTypeChangeSet.id');
        const store = this.get('store');
        const record = `config-System_Configuration-vehicle_maint_types/${id}`;
        vehicleMaintTypeNameRecord = store.peekRecord('cs-config-item', record);
      }

      vehicleMaintTypeNameRecord.set('displayName', description);
      vehicleMaintTypeNameRecord.set('description', description);
      vehicleMaintTypeNameRecord.set('value', value);

      try {
        yield vehicleMaintTypeNameRecord.save(); // changeset holds changes, execute the changes due to lookups using unorthodox saving practice
        // execute to change underlying object and rollback to remove internal changes list

        vehicleMaintTypeChangeSet.execute();
        vehicleMaintTypeChangeSet.rollback();
        notifications.success('Lookup record successfully saved.');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
            tooltip.reset();
          });
        }, 500);
      } catch (response) {
        if (response.errors[0].status === '409') {
          tooltip.pushConfirmation({
            title: 'Manage Driver/Vehicle',
            tip: 'Description cannot duplicate an existing Description.',
            primaryActionText: 'OK',
            primaryAction: () => {
              return tooltip.reset();
            }
          });
        } else {
          notifications.warning('Lookup record failed to save.');
        }

        if (vehicleMaintTypeNameRecord.rollbackAttributes) {
          vehicleMaintTypeNameRecord.rollbackAttributes();
        }

        console.warn(response); // eslint-disable-line
      }
    }).drop(),

    onEditVehicleMaintTypeNameRecord() {
      const editAPI = this.get('editAPI');
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onUndoVehicleMaintTypeNameRecord() {
      const vehicleMaintTypeChangeSet = this.get('vehicleMaintTypeChangeSet');
      const editAPI = this.get('editAPI');

      if (vehicleMaintTypeChangeSet) {
        vehicleMaintTypeChangeSet.rollback();
      }

      this.set('disableForm', true);
      editAPI.buttonSettings(false, false, false, true, true);
    },

    onNewVehicleMaintTypeNameRecord() {
      const editAPI = this.get('editAPI');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    actions: {
      onSaveVehicleMaintTypeNameRecord() {
        return this.onSaveVehicleMaintTypeNameRecord.perform();
      },

      onInputValueChange(record, valuePath, value) {
        this.set('currentDisplayName', value);
        record.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});