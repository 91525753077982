define("adept-iq/models/rider-funding", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    riderService: Ember.inject.service('rider'),
    fundingAgencyName: attr('string'),
    notes: attr('string'),
    startDate: attr('date'),
    endDate: attr('date'),
    rider: belongsTo('rider'),
    fundingAgency: belongsTo('funding-agency'),
    fundingAgencyDisplayName: Ember.computed('fundingAgency.displayName', function () {
      const fundingAgency = this.get('fundingAgency');
      return fundingAgency.get('displayName');
    })
  });

  _exports.default = _default;
});