define("adept-iq/utils/range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = range;

  function range(num) {
    const arr = [];

    for (let i = 0; i < num; i += 1) {
      arr.push(0);
    }

    return arr;
  }
});