define("adept-iq/pods/components/generic-components/vertical-menu/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item'],
    classNameBindings: ['isSelected', 'disabled'],
    // passed from vertical-menu component
    model: null,
    selectedIndex: null,
    index: null,
    onItemSelect: null,
    disableList: null,
    disabled: Ember.computed.or('model.disabled', 'disableList'),
    isSelected: Ember.computed.readOnly('model.selected'),

    init() {
      this._super(...arguments);
    },

    actions: {
      onItemSelect() {
        if (this.get('isSelected') || this.get('disabled')) return;
        this.get('onItemSelect')();
      }

    }
  });

  _exports.default = _default;
});