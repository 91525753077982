define("adept-iq/adapters/perm-sso-user", ["exports", "adept-iq/adapters/-sso-ssoService"], function (_exports, _ssoSsoService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssoSsoService.default.extend({
    buildURL(modelName
    /*, id, snapshot, requestType, query*/
    ) {
      return this._buildUrlForPath(modelName);
    },

    _buildUrlForPath() {
      const host = this.get('host');
      return `${host}/user`;
    },

    postUser(payload) {
      const token = this.get('session.data.authenticated.token');
      return this.get('ajaxService').post(this.get('host') + '/user', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: payload
      }).catch(e => {
        const {
          payload: epayload,
          status
        } = e;
        const error = new Error();
        if (epayload.message) error.message = epayload.message;
        if (status) error.status = status;
        if (epayload.statusCode) error.status = payload.statusCode;
        throw error;
      });
    },

    patchUser(payload, userId) {
      const token = this.get('session.data.authenticated.token');
      return this.get('ajaxService').post(this.get('host') + '/user/' + userId, {
        method: 'PATCH',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: payload
      });
    },

    deleteUser(userId) {
      const token = this.get('session.data.authenticated.token');
      return this.get('ajaxService').post(this.get('host') + '/user/' + userId + '/status/delete', {
        method: 'PUT',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    },

    getRolesByUser(userId) {
      const token = this.get('session.data.authenticated.token');
      return this.get('ajaxService').post(this.get('host') + '/user/' + userId, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    },

    deleteRolesByUser(userId, roleId) {
      const token = this.get('session.data.authenticated.token');
      return this.get('ajaxService').post(this.get('host') + '/user/' + userId + '/roles/' + roleId, {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    },

    updateRolesByUser(userId, roleId) {
      const token = this.get('session.data.authenticated.token');
      return this.get('ajaxService').request(this.get('host') + '/user/' + userId + '/roles/' + roleId, {
        dataType: 'text',
        method: 'PUT',
        contentType: 'text',
        headers: {
          'Content-Type': 'text',
          Accept: 'text',
          Authorization: `Bearer ${token}`
        },
        data: null
      }).catch(e => {
        const {
          payload,
          status
        } = e;
        const error = new Error();
        if (payload.message) error.message = payload.message;
        if (status) error.status = status;
        if (payload.statusCode) error.status = payload.statusCode;
        throw error;
      });
    },

    updateStatusByUser(userId, status) {
      const token = this.get('session.data.authenticated.token');
      return this.get('ajaxService').post(this.get('host') + '/user/' + userId + '/status/' + status, {
        method: 'PUT',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: {
          status
        }
      });
    },

    getSSOProvider() {
      const token = this.get('session.data.authenticated.token');
      return this.get('ajaxService').post(this.get('host') + '/provider', {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    },

    updateUsernameAndPassword(payload, userId) {
      const token = this.get('session.data.authenticated.token');
      return this.get('ajaxService').post(this.get('host') + '/basicUser/' + userId, {
        method: 'PUT',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: payload
      });
    },

    updateProviders(userId, providerId) {
      const token = this.get('session.data.authenticated.token');
      return this.get('ajaxService').request(this.get('host') + '/user/' + userId + '/providers/' + providerId, {
        dataType: 'text',
        method: 'PUT',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    },

    deleteProviders(userId, providerId) {
      const token = this.get('session.data.authenticated.token');
      return this.get('ajaxService').request(this.get('host') + '/user/' + userId + '/providers/' + providerId, {
        dataType: 'text',
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    }

  });

  _exports.default = _default;
});