define("adept-iq/pods/dashboard/modals/edit-form/resize-handle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);

      this._onMousedown = e => {
        Ember.run.scheduleOnce('afterRender', this, 'onMousedown', e);
      };

      this.$().on('mousedown', this._onMousedown);
    },

    willDestroyElement() {
      this.$().off('mousedown', this._onMousedown);
      this._onMousedown = null;

      this._super(...arguments);
    },

    onMousedown() {
      const onMousemove = e => {
        Ember.run.scheduleOnce('afterRender', this, 'resizeModal', e);
      };

      const onMouseup = () => {
        Ember.run.schedule('afterRender', () => {
          Ember.$(document).off('mouseup', onMouseup);
          Ember.$(document).off('mousemove', onMousemove);
        });
      };

      Ember.$(document).on('mousemove', onMousemove); //rm listeners to avoid bloat

      Ember.$(document).on('mouseup', onMouseup);
    },

    resizeModal(e) {
      const coords = {
        x: e.pageX
      };
      this.get('onResizeModal')(coords);
    }

  });

  _exports.default = _default;
});