define("adept-iq/pods/components/side-drawer/reconcile-rescue-route/table/cells/base-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-base-cell', 'data-test-table-base-cell'],
    classNameBindings: ['alertCategory', 'data-test-alertCategory'],
    alertCategory: Ember.computed('row.otpCaption', function () {
      if (Ember.get(this, 'row.otpCaption').toLowerCase() === 'late') {
        return 'highlightable';
      }

      return '';
    })
  });

  _exports.default = _default;
});