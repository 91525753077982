define("adept-iq/pods/components/reoptimize-modal/operation-views/release-routes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LUSRG+M1",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"reoptimize-modal/operation-tables/release-routes-table\",null,[[\"tableRef\",\"failedTripProviders\",\"isTaskRunning\",\"isProviderTableShow\",\"failedScheduleId\",\"selectedRow\"],[[23,[\"tableRef\"]],[23,[\"failedTripProviders\"]],[23,[\"isTaskRunning\"]],[23,[\"isProviderTableShow\"]],[23,[\"failedScheduleId\"]],[23,[\"selectedRow\"]]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"release-routes button-holder\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"release-routes-button\"],[12,\"disabled\",[21,\"isTaskRunning\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onReleaseRoutesClick\"],null]],[9],[7,\"span\"],[9],[0,\"Release\"],[10],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"providerTableEnable\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"overlayClass\",\"translucentOverlay\"],[\"driver-break-master-styles-overlay\",true]],{\"statements\":[[0,\"    \"],[1,[27,\"reoptimize-modal/operation-tables/release-routes-provider-table\",null,[[\"failedScheduleId\",\"selectedRow\",\"isProviderTableShow\"],[[23,[\"failedScheduleId\"]],[23,[\"selectedRow\"]],[23,[\"isProviderTableShow\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/reoptimize-modal/operation-views/release-routes/template.hbs"
    }
  });

  _exports.default = _default;
});