define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-trip/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    defaultSortId: 'vehicleId',
    defaultSortAsc: true,
    reconcileSearchTables: true,
    showSelectedRowLength: false,
    noRecordsFound: false,
    routeIdValuePath: 'attributes.routeId',
    columns: [{
      id: 'tripId',
      index: 0,
      type: 'number',
      label: 'Trip ID',
      valuePath: 'id',
      width: '85px',
      resizable: true,
      minResizeWidth: 50
    }, {
      id: 'tripDate',
      index: 1,
      type: 'text',
      label: 'Trip Date',
      valuePath: 'attributes.schedulename',
      resizable: true,
      width: '85px',
      minResizeWidth: 50
    }, {
      id: 'SubscriptionId',
      index: 2,
      type: 'text',
      label: 'Subscription ID',
      valuePath: 'attributes.subscriptionId',
      resizable: true,
      width: '85px',
      minResizeWidth: 50
    }, {
      id: 'groupId',
      index: 3,
      type: 'text',
      label: 'Group ID',
      valuePath: 'attributes.groupId',
      resizable: true,
      width: '85px',
      minResizeWidth: 50
    }, {
      id: 'passengerId',
      index: 4,
      type: 'number',
      label: 'Client ID',
      valuePath: 'attributes.rider.id',
      resizable: true,
      width: '85px',
      minResizeWidth: 50
    }, {
      id: 'lastname',
      index: 5,
      type: 'text',
      label: 'Last Name',
      valuePath: 'attributes.rider.attributes.lastName',
      resizable: true,
      width: '85px',
      minResizeWidth: 50
    }, {
      id: 'firstname',
      index: 6,
      type: 'text',
      label: 'First Name',
      valuePath: 'attributes.rider.attributes.firstName',
      resizable: true,
      width: '85px',
      minResizeWidth: 50
    }, {
      id: 'status',
      index: 7,
      type: 'text',
      label: 'Status',
      valuePath: 'attributes.tripStatus',
      resizable: true,
      width: '80px',
      minResizeWidth: 50
    }, {
      id: 'routeId',
      index: 8,
      type: 'number',
      label: 'Route ID',
      valuePath: 'attributes.routeName',
      resizable: true,
      width: '80px',
      minResizeWidth: 50
    }]
  };
  _exports.default = _default;
});