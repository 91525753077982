define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/gpsPingLayer", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/map", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/circleMarker", "lodash"], function (_exports, _react, _map, _api, _panel, _circleMarker, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const GPSPingLayer = () => {
    const circleMarkerRenderer = window.L.canvas({
      padding: 0.5
    });

    const {
      map
    } = _react.default.useContext(_map.MapContext);

    const [layer, setLayer] = _react.default.useState();

    const [points, setPoints] = _react.default.useState([]);

    const {
      eventData
    } = (0, _api.useAPI)();
    const {
      sliderIndex
    } = (0, _panel.usePanel)();

    _react.default.useEffect(() => {
      if (!map) return;

      const _layer = window.L.layerGroup();

      setLayer(_layer);

      _layer.addTo(map.native);

      const _points = _lodash.default.chain(eventData).map((point, index) => {
        return { ...point,
          index
        };
      }).filter(event => event.displayLat && event.displayLng && event.eventtype === 'avl').reduce((dict, event) => {
        dict[[event.displayLat, event.displayLng]] = event;
        return dict;
      }, {}).values().map(point => {
        let popup = 'Gps Ping<br/>';
        const timeLabel = point.timestamp ? new Date(point.timestamp).toLocaleTimeString() : ' ';
        popup = popup.bold() + 'Time: ' + timeLabel + '<br/>';
        popup = popup + 'Latitude: ' + Number(point.locLat).toFixed(6) + '<br/>';
        popup = popup + 'Longitude: ' + Number(point.locLng).toFixed(6) + '<br/>';
        const currentSection = eventData[sliderIndex].section;
        const color = point.section === currentSection ? 'blue' : '#A9B2B5';
        return { ...point,
          popup,
          color
        };
      }).value();

      setPoints(_points);
      return () => map.native.removeLayer(_layer);
    }, [eventData]);

    _react.default.useEffect(() => {
      if (eventData.length <= sliderIndex) return;
      const currentSection = eventData[sliderIndex].section;

      const _points = points.map(point => {
        let color = point.section === currentSection ? 'blue' : '#A9B2B5';

        if (point.index <= sliderIndex) {
          color = '#555E63';
        }

        return { ...point,
          color
        };
      });

      setPoints(_points);
    }, [sliderIndex]);

    return _react.default.createElement(_react.default.Fragment, null, // Circle marker could be more than 5000, so need to render in canvas and can only render once.
    points.map(point => {
      return _react.default.createElement(_circleMarker.default, {
        key: `avl-${point._id}`,
        layer: layer,
        renderer: circleMarkerRenderer,
        position: [point.locLat, point.locLng],
        style: {
          opacity: 0.5,
          fillOpacity: 0.5
        },
        color: point.color,
        popup: point.popup
      });
    }));
  };

  var _default = GPSPingLayer;
  _exports.default = _default;
});