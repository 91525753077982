define("adept-iq/models/driver-accident", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    notification: attr('boolean'),
    notificationDate: attr('date'),
    accidentDate: attr('date'),
    transitVehicleInvolved: attr('boolean'),
    description: attr('string'),
    driver: belongsTo('driver'),
    vehicle: belongsTo('vehicle')
  });

  _exports.default = _default;
});