define("adept-iq/models/dwh-stop-point", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    iqid: attr('string'),
    tripId: attr('string'),
    stoptype: attr('string'),
    routeid: attr('string'),
    routename: attr('string'),
    routeordinal: attr('number'),
    provider: attr('string'),
    tenantId: attr('number'),
    promisetime: attr('date'),
    plannedEndTime: attr('date'),
    actualarrivetime: attr('date'),
    actualdeparttime: attr('date'),
    passengerfirstname: attr('string'),
    passengerlastname: attr('string'),
    streetnumber: attr('string'),
    streetaddress: attr('string'),
    sublocality: attr('string'),
    locality: attr('string'),
    subregion: attr('string'),
    region: attr('string'),
    postalcode: attr('string'),
    actualodometer: attr('number'),
    dwhTrip: belongsTo('dwh-trip'),
    dwhRoute: belongsTo('dwh-route'),
    readableAddress: Ember.computed('streetnumber', 'streetaddress', 'locality', 'sublocality', 'region', 'postalcode', function () {
      const streetNumber = Ember.get(this, 'streetnumber');
      const streetAddress = Ember.get(this, 'streetaddress'); // const localityOrSubLocality = this.get('locality') || this.get('subLocality') || '';
      // const region = get(this, 'region');
      // const postalCode = get(this, 'postalcode');

      const address = [];
      address.push(streetNumber);
      address.push(streetAddress);
      return address.join(' '); // const result = [address.join(' ')];
      // result.push(localityOrSubLocality);
      // result.push(region);
      // result.push(postalCode);
      // return result.join(', ');
    }).readOnly()
  });

  _exports.default = _default;
});