define("adept-iq/adapters/playback-driver", ["exports", "adept-iq/adapters/-playbackService"], function (_exports, _playbackService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _playbackService.default.extend({
    findRecord() {
      throw new Error('adapter method unsupported');
    },

    buildURL() {
      const host = this.get('host');
      return `${host}/driver`;
    }

  });

  _exports.default = _default;
});