define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel/backward", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api"], function (_exports, _react, _panel, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Backward = () => {
    const {
      backwardPlay
    } = (0, _panel.usePanel)();
    const {
      eventData
    } = (0, _api.useAPI)();

    const [isDisabled, setIsDisabled] = _react.default.useState(true);

    _react.default.useEffect(() => {
      if (eventData.length > 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }, [eventData]);

    return _react.default.createElement("button", {
      id: "backward-button",
      className: "btn circle-btn",
      disabled: isDisabled,
      onClick: () => backwardPlay()
    }, _react.default.createElement("span", null, isDisabled ? _react.default.createElement("svg", {
      width: "26",
      height: "25",
      viewBox: "0 0 26 25",
      xmlns: "http://www.w3.org/2000/svg"
    }, _react.default.createElement("title", null, "back/disabled"), _react.default.createElement("defs", null, _react.default.createElement("linearGradient", {
      x1: "50%",
      y1: "0%",
      x2: "50%",
      y2: "100%",
      id: "a"
    }, _react.default.createElement("stop", {
      stopColor: "#F9F9F9",
      offset: "0%"
    }), _react.default.createElement("stop", {
      stopColor: "#D1D1D1",
      offset: "100%"
    }))), _react.default.createElement("g", {
      transform: "matrix(-1 0 0 1 25.105 .11)",
      fill: "none",
      fillRule: "evenodd"
    }, _react.default.createElement("circle", {
      stroke: "#B4B4B4",
      fill: "url(#a)",
      cx: "12.442",
      cy: "12.442",
      r: "11.942"
    }), _react.default.createElement("path", {
      d: "M11.8 9.371a.547.547 0 01.586.115l2.412 2.331a.679.679 0 01-.002.986l-2.418 2.331a.534.534 0 01-.578.115.508.508 0 01-.32-.485V9.856c0-.212.115-.404.32-.485z",
      fill: "#263238",
      fillRule: "nonzero",
      opacity: ".495"
    }))) : _react.default.createElement("svg", {
      width: "25px",
      height: "25px",
      viewBox: "0 0 25 25",
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg"
    }, _react.default.createElement("title", null, "back"), _react.default.createElement("desc", null, "Created with Sketch."), _react.default.createElement("defs", null, _react.default.createElement("linearGradient", {
      x1: "50%",
      y1: "0%",
      x2: "50%",
      y2: "100%",
      id: "linearGradient-1"
    }, _react.default.createElement("stop", {
      stopColor: "#F9F9F9",
      offset: "0%"
    }), _react.default.createElement("stop", {
      stopColor: "#D1D1D1",
      offset: "100%"
    }))), _react.default.createElement("g", {
      id: "Playback-2.4-Release",
      stroke: "none",
      fill: "none",
      fillRule: "evenodd"
    }, _react.default.createElement("g", {
      id: "back",
      transform: "translate(0.000000, 0.110400)"
    }, _react.default.createElement("circle", {
      id: "Oval-Copy",
      stroke: "#B4B4B4",
      fill: "url(#linearGradient-1)",
      transform: "translate(12.441598, 12.441598) scale(-1, 1) translate(-12.441598, -12.441598) ",
      cx: "12.4415977",
      cy: "12.4415977",
      r: "11.9415977"
    }), _react.default.createElement("g", {
      id: "rewind-button",
      transform: "translate(9.573118, 8.881918)",
      fill: "#263238",
      fillRule: "nonzero"
    }, _react.default.createElement("path", {
      d: "M3.51026848,0.489046647 C3.30562524,0.407943299 3.07977719,0.453180226 2.92360208,0.60391613 L0.511639127,2.93555661 C0.229413207,3.20794754 0.231769297,3.64803822 0.514163509,3.92075227 L2.93201669,6.25223119 C3.08852838,6.40280553 3.30512036,6.44820402 3.51010019,6.36710067 C3.71457514,6.28583576 3.82935038,6.09438662 3.82935038,5.88177306 L3.82935038,0.974374253 C3.82918209,0.761599134 3.71508002,0.570149995 3.51026848,0.489046647 Z",
      id: "Path"
    })))))));
  };

  var _default = Backward;
  _exports.default = _default;
});