define("adept-iq/pods/components/iq-widgets/add-route-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editModal: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    tripId: null,

    init() {
      this._super(...arguments); // const records = this.get('editableRecords') || [];
      // const segment = records[0];
      // if (isPresent(segment)) {
      //   this.set('tripId', segment.trips.firstObject.get('tripId'));
      // }

    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        if (Ember.isNone(record.get('cancelType'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Reason cannot be blank.');
        }
      });
      return valid;
    },

    actions: {
      onCellValueChange(record, valuePath, value, options) {
        if (record) {
          record.set('isForceDirty', true);
          this.get('service').setRecordValue(record, valuePath, value, options);
        }
      },

      onUndoClick() {},

      onApplyClick() {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'Add New Route',
          tip: 'Create Route ID 123456 ?',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            this.send('confirm');
            return tooltip.reset();
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          }
        });
      },

      confirm() {
        this.get('notifications').success('SUCCESSFULLY CREATED NEW ROUTE 123456');
      }

    }
  });

  _exports.default = _default;
});