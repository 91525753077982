define("adept-iq/pods/components/iq-widgets/permissions-roles-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/permissions-roles-widget/config", "adept-iq/config/mapped-permIds", "ember-concurrency", "adept-iq/config/api-urls", "lodash", "adept-iq/utils/filters", "adept-iq/utils/sorts"], function (_exports, _component, _config, _mappedPermIds, _emberConcurrency, _apiUrls, _lodash, _filters, _sorts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['roles-widget', 'data-test-roles-widget'],
    config: _config.default,
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.initContextMenu();
    },

    deleteTask: (0, _emberConcurrency.task)(function* () {
      // using custom api call because sso api is non standard
      // and in-consistent.
      const ajax = this.get('ajax');
      const session = this.get('session');
      const role = this.get('lastRowClicked') || [];
      return yield ajax.request(`${_apiUrls.API.ssoService.host}/role/${role.roleName}`, {
        contentType: 'application/json',
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    }),

    initContextMenu() {
      this.initTableActions();
      this.initSingleActions();
    },

    contextMenuHandler(optionId, isSaved) {
      if (isSaved) {
        this.refresh();
      }

      if (!optionId) {
        return;
      }
    },

    initTableActions() {
      this.get('tableActions').unshiftObject({
        id: 'newRole',
        permId: _mappedPermIds.default.newRole,
        name: 'Add Role',
        action: function () {
          this.get('workspace').pushState('permAddRole', {
            displayName: 'Add Role',
            roles: [],
            roleData: this.get('records'),
            action: this.contextMenuHandler.bind(this, 'permAddRole')
          }, {
            replaceSelf: true
          });
        }.bind(this)
      });
    },

    initSingleActions() {
      this.set('singleActions', [{
        id: 'edit',
        permId: _mappedPermIds.default.editRole,
        name: 'Edit Role',
        action: async function (model) {
          const session = this.get('session');
          let permissionList = [];
          const roleName = model.get('roleName');

          if (roleName) {
            permissionList = await this.get('ajax').request(`${_apiUrls.API.ssoService.host}/role/${roleName}/role-permission/`, {
              method: 'GET',
              contentType: 'application/json',
              headers: {
                'Authorization': `Bearer ${session.data.authenticated.token}`
              }
            }).catch(() => []);
          }

          permissionList = permissionList.map(perm => perm.permissionId);
          model.set('permissionList', permissionList);
          this.get('workspace').pushState('permAddRole', {
            displayName: 'Edit Role',
            roles: [model],
            roleData: this.get('records'),
            action: this.contextMenuHandler.bind(this, 'permAddRole')
          }, {
            replaceSelf: true
          });
        }.bind(this)
      }, {
        id: 'delete',
        permId: _mappedPermIds.default.deleteRole,
        name: 'Delete Role',
        action: async function (model) {
          const tooltip = this.get('tooltip');
          const roleName = model.get('roleName');
          const permUsers = await this.get('store').query('perm-sso-user', {});
          const users = permUsers.toArray().filter(u => u.get('roles').some(r => r.roleName === roleName));
          tooltip.pushConfirmation({
            tip: 'There are ' + users.length + ' users assigned to "' + model.displayName + '" role. Are you sure you want to delete?',
            title: 'Deleting Role',
            hasOverlay: true,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              this.get('deleteTask').perform().then(() => {
                this.refresh();
                this.get('notifications').success('DELETED ROLE SUCCESSFULLY');
                return tooltip.reset();
              });
            },
            secondaryActionText: 'Cancel',
            secondaryAction: () => {
              tooltip.reset();
            }
          });
        }.bind(this)
      }]);
    },

    fetchDataQuery() {
      const params = {
        sorts: []
      };

      try {
        // this only applies for server-side pagination
        if (this.get('paginationEnabled')) {
          params.page = {
            offset: this.get('records.length'),
            limit: this.get('limit')
          };
        }

        const sort = this.buildSortParam();

        if (sort) {
          params.sorts.push(sort);
        }

        const includes = this.get('defaultIncludes');

        if (!Ember.isEmpty(includes)) {
          params.includes = includes;
        }

        const filters = this.setupFilterType();
        params.filter = (0, _filters.buildCompoundFilterNode)('and', filters);
        const modelName = this.get('config.modelName');
        return this.query(modelName, params);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Workspace view is not refreshed on selecting or deselecting' + error);
      }
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const activeData = (await store.query(modelName, params)) || [];
      const storeData = store.peekAll(modelName);

      _lodash.default.differenceBy(storeData.toArray(), activeData.toArray(), i => {
        return i.get('id');
      }).forEach(record => {
        store.unloadRecord(record);
      });

      const rolePermissions = await store.query('role-permission', {});
      const storeRolePermissions = store.peekAll('role-permission');

      _lodash.default.differenceBy(storeRolePermissions.toArray(), rolePermissions.toArray(), i => {
        return i.get('id');
      }).forEach(record => {
        store.unloadRecord(record);
      });

      const nonSuperuserRoles = activeData.filter(d => d.id !== 'superuser');
      this.get('activeContext.topActiveContext').set('permSsoRoles', nonSuperuserRoles);
      const dataTraversal = this.get('activeContext.topActiveContext.permissionActiveData');
      const filteredData = dataTraversal['perm-sso-role'].filter(filterFn).sort(compareFn);
      return filteredData;
    },

    refresh() {
      this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['perm-sso-user', 'perm-sso-role']);
    }

  });

  _exports.default = _default;
});