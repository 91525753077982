define("adept-iq/pods/components/side-drawer/system-config/ada-boundaries/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serviceTableRef: null,
    boundaryInformationTableRef: null,
    adaService: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.buildAdaBoundaryInfo = this.buildAdaBoundaryInfo.bind(this);
    },

    buildAdaBoundaryInfo(selectedAgencyServiceId) {
      const boundaryInformationTableRef = this.get('boundaryInformationTableRef');
      boundaryInformationTableRef.set('queryParams', {
        agencyServiceId: selectedAgencyServiceId
      });
      boundaryInformationTableRef.refreshData();
    },

    actions: {
      newAdaBoundaries() {},

      editAdaBoundaries() {},

      deleteAdaBoundaries() {},

      undoAdaBoundaries() {},

      saveAdaBoundaries() {}

    }
  });

  _exports.default = _default;
});