define("adept-iq/classes/active-contexts/permission/graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ssoUserNode = _exports.ssoRoleNode = _exports.default = _exports.activeContextNodes = _exports.activeContextGraph = void 0;
  const ssoUserNode = {
    id: 'perm-sso-user',
    modelName: 'perm-sso-user',
    links: [{
      type: 'hasMany',
      nodeId: 'perm-sso-role',
      path: 'ssoRoles'
    }],
    providerFilterKey: false
  };
  _exports.ssoUserNode = ssoUserNode;
  const ssoRoleNode = {
    id: 'perm-sso-role',
    modelName: 'perm-sso-role',
    isActive: false,
    links: [{
      type: 'hasMany',
      nodeId: 'perm-sso-user',
      path: 'users'
    }],
    providerFilterKey: false
  };
  _exports.ssoRoleNode = ssoRoleNode;
  const activeContextNodes = [// sign-on
  ssoUserNode, ssoRoleNode];
  _exports.activeContextNodes = activeContextNodes;
  const activeContextGraph = activeContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.activeContextGraph = activeContextGraph;
  var _default = activeContextGraph;
  _exports.default = _default;
});