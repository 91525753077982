define("adept-iq/serializers/sso-permission", ["exports", "adept-iq/serializers/-sso-ssoService"], function (_exports, _ssoSsoService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function createPermissionPayload(permission) {
    return {
      data: {
        attributes: {
          category: permission.category,
          description: permission.description
        },
        type: 'sso-permission',
        id: ''
      }
    };
  }

  var _default = _ssoSsoService.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // sso endpoint is not JSON-API compliant
      if (Ember.isArray(payload)) {
        // eslint-disable-next-line no-param-reassign
        const payloads = payload.map(pay => {
          return {
            attributes: {
              category: pay.category,
              description: pay.description
            },
            type: 'sso-permission',
            id: pay.id
          };
        }); // eslint-disable-next-line no-param-reassign

        payload = {
          data: payloads
        };
      } else if (!payload.hasOwnProperty('jsonapi')) {
        // single record returns as a POJO for editing a user's status or role
        const permissionPayload = createPermissionPayload(payload);
        permissionPayload.data.id = payload.id; // eslint-disable-next-line no-param-reassign

        payload = permissionPayload;
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});