define("adept-iq/transforms/polyline", ["exports", "ember-data", "@mapbox/polyline"], function (_exports, _emberData, _polyline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize(serialized) {
      if (Ember.isEmpty(serialized)) return null;
      return _polyline.default.decode(serialized);
    },

    serialize(deserialized) {
      if (Ember.isEmpty(deserialized)) return null;
      return _polyline.default.encode(deserialized);
    }

  });

  _exports.default = _default;
});