define("adept-iq/serializers/route-template-availability", ["exports", "adept-iq/serializers/-ss-schedulingService", "moment"], function (_exports, _ssSchedulingService, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const refTime = (0, _moment.default)(0).startOf('day');

  var _default = _ssSchedulingService.default.extend({
    attrs: {
      calcEndDate: {
        serialize: false
      },
      DOWSelected: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      if (json.data.attributes.tripLimit) {
        delete json.data.attributes.tripLimit;
      }

      if (json.data.attributes.routeLength) {
        delete json.data.attributes.routeLength;
      }

      if (json.data && json.data.relationships && json.data.relationships.driver) {
        if (json.data.relationships.driver.data === null) {
          json.data.relationships.driver = {
            data: {
              type: 'driver',
              id: 'deleted'
            }
          };
        }
      }

      const {
        shiftStart,
        shiftEnd
      } = json.data.attributes;
      const momentShiftStart = (0, _moment.default)(shiftStart).startOf('minute');
      const momentShiftEnd = (0, _moment.default)(shiftEnd).startOf('minute');
      const diffStart = Math.abs(momentShiftStart.diff(momentShiftStart.clone().startOf('day')));

      const durationStart = _moment.default.duration(diffStart);

      const formattedShiftStart = refTime.clone().add(durationStart);
      const diffEnd = Math.abs(momentShiftEnd.diff(momentShiftStart.clone().startOf('day')));

      const durationEnd = _moment.default.duration(diffEnd);

      const formattedShiftEnd = refTime.clone().add(durationEnd);
      json.data.attributes.shiftStart = formattedShiftStart;
      json.data.attributes.shiftEnd = formattedShiftEnd;
      return json;
    },

    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};
      const {
        shiftStart,
        shiftEnd,
        startTime
      } = resourceHash.attributes;

      if (shiftStart && shiftEnd) {
        const startMoment = (0, _moment.default)(`${shiftStart}`);
        const endMoment = (0, _moment.default)(`${shiftEnd}`);
        const diff = Math.abs(startMoment.diff(endMoment));

        const duration = _moment.default.duration(diff);

        const hours = Math.floor(duration.asHours());
        const formattedHours = hours < 10 ? `0${hours}` : hours;

        const routeLength = formattedHours + _moment.default.utc(diff).format(':mm');

        resourceHash.attributes.routeLength = routeLength;
      }

      if (startTime && resourceHash.attributes.routeLength) {
        const clone = (0, _moment.default)(shiftStart);
        const routeLength = resourceHash.attributes.routeLength.split(':');
        clone.add(routeLength[0], 'hours');
        clone.add(routeLength[1], 'minutes');
        resourceHash.attributes.calcEndDate = clone.toISOString();
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});