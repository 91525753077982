define("adept-iq/initializers/warnings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  const WARNING_IDS_BLACKLIST = ['ember-light-table.height-attribute', 'ember-htmlbars.style-xss-warning'];

  function initialize() {
    Ember.Debug.registerWarnHandler((message, options, next) => {
      if (WARNING_IDS_BLACKLIST.includes(options.id)) {
        console.trace = null; // eslint-disable-line no-console

        return;
      }

      next(message, options);
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});