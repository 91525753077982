define("adept-iq/services/user", ["exports", "adept-iq/helpers/has-role", "adept-iq/classes/road-supervisor-api", "adept-iq/classes/road-supervisor-special-route-api", "adept-iq/config/mapped-permIds", "adept-iq/config/environment"], function (_exports, _hasRole, _roadSupervisorApi, _roadSupervisorSpecialRouteApi, _mappedPermIds, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SCHEDULER_ROLE = 'scheduler';
  const SCHEDULER_MANAGER_ROLE = 'schedulemanager';
  const SCHEDULER_SUPERVISOR_ROLE = 'schedulesupervisor';
  const SCHEDULER_ADMIN_ROLE = 'scheduleadmin';

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    userId: Ember.computed.alias('session.data.authenticated.tokenInfo.userId'),
    roles: Ember.computed.alias('session.data.authenticated.tokenInfo.roleNames'),

    hasRole(roleName) {
      return (0, _hasRole.hasRole)(this.roles, roleName);
    },

    isScheduler: Ember.computed('roles', function () {
      const roleNames = this.get('roles');
      return (0, _hasRole.hasRole)(roleNames, SCHEDULER_ROLE) || (0, _hasRole.hasRole)(roleNames, SCHEDULER_MANAGER_ROLE) || (0, _hasRole.hasRole)(roleNames, SCHEDULER_SUPERVISOR_ROLE) || (0, _hasRole.hasRole)(roleNames, SCHEDULER_ADMIN_ROLE);
    }),
    isRoadSupOnTablet: Ember.computed('roles', function () {
      return this.isRoadSupEnable();
    }),

    isRoadSupEnable() {
      let checkRoadSup = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      if (!checkRoadSup) {
        return false;
      }

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const roleName = _environment.default.APP.avlmLite ? _roadSupervisorApi.default.getRoleName() : _roadSupervisorSpecialRouteApi.default.getRoleName();
      return isMobile && this.roles && (0, _hasRole.hasRole)(this.roles, roleName);
    },

    isDispatcher() {
      return this.get('permissionLayer').permInUserHash(_mappedPermIds.default.accessDispatchWorkspace, null);
    },

    isRoadSupDesktop() {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const roleName = _environment.default.APP.avlmLite ? _roadSupervisorApi.default.getRoleName() : _roadSupervisorSpecialRouteApi.default.getRoleName();
      return !isMobile && this.roles && (0, _hasRole.hasRole)(this.roles, roleName);
    },

    isAdmin() {
      return (0, _hasRole.hasRole)(this.roles, 'admin');
    }

  });

  _exports.default = _default;
});