define("adept-iq/pods/dashboard/modals/edit-form/controller", ["exports", "resize-observer-polyfill"], function (_exports, _resizeObserverPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RESIZE_CURSOR_OFFSET = -2;
  const MAX_WIDTH = 800;
  const RESIZE_DEBOUNCE_TIME = 250;

  var _default = Ember.Controller.extend({
    editModal: Ember.inject.service(),
    editableRecords: Ember.computed.alias('editModal.editableRecords'),

    init() {
      this._super(...arguments);

      this._onResize = () => {
        if (this.get('isDragging') || this.get('isResizing')) return;
        Ember.run.debounce(this, 'resizedWindow', RESIZE_DEBOUNCE_TIME);
      };

      this._resizeObserver = new _resizeObserverPolyfill.default(this._onResize);

      this._resizeObserver.observe(Ember.$('body')[0]); // TODO: saveRecordFunction shall be moved to a service later on
      // Once that occurs its possible to save the edit modal state and this can be removed for refreshing for updated edit modal state


      if (this.get('editModal.editableRecords.length') === 0) {
        this.get('editModal').close();
      }
    },

    resizedWindow() {
      const position = Ember.$('.edit-widget-modal').width();
      const coords = {
        x: position
      };

      if (this.get('isDestroyed')) {
        return;
      }

      this.send('resizeModal', coords);
    },

    willDestroyElement() {
      this._resizeObserver.disconnect();

      this._resizeObserver = null;
      this._onResize = null;
    },

    actions: {
      resizeModal(position) {
        const windowWidth = Ember.$(window).width(); // Resize cursor offset ensures cursor is centered on handle.

        const modalWidth = windowWidth - position.x + RESIZE_CURSOR_OFFSET;
        Ember.run.scheduleOnce('afterRender', () => {
          if (windowWidth - modalWidth >= MAX_WIDTH) {
            Ember.$('.edit-widget-modal').css('right', `${windowWidth - MAX_WIDTH}px`);
          }

          Ember.$('.edit-widget-modal').css('right', `${modalWidth}px`);
        });
      }

    }
  });

  _exports.default = _default;
});