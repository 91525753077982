define("adept-iq/utils/mime-types", ["exports", "ember-hifi/utils/mime-types"], function (_exports, _mimeTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "getMimeType", {
    enumerable: true,
    get: function () {
      return _mimeTypes.getMimeType;
    }
  });
});