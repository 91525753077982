define("adept-iq/pods/components/iq-widgets/subscriptions-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/subscriptions-widget/config", "lodash", "adept-iq/config/mapped-permIds", "moment", "adept-iq/utils/filters", "adept-iq/utils/sorts"], function (_exports, _component, _config, _lodash, _mappedPermIds, _moment, _filters, _sorts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    dragCoordinator: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['subscriptions-widget', 'data-test-subscriptions-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    editComponent: 'iq-widgets/subscriptions-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,
    limit: 500,

    saveRecordFunction(records) {
      this.set('editModal.errors', []);

      function saveDeleteAllTravelNeeds(objects) {
        const deleteObjects = _lodash.default.difference(objects.content.canonicalState, objects.content.currentState) || []; // delete objects that have been removed from the array

        return Promise.all(deleteObjects.map(deleteObject => {
          deleteObject._record.deleteRecord();

          return deleteObject.save();
        })).then(() => {
          return Promise.all(objects.map(object => {
            object.save();
          }));
        });
      }

      function saveNewTravelNeedObjects(subscription, oldObjects, newObjects) {
        /*eslint-disable */
        return Promise.all(newObjects.map(newObject => {
          if (newObject.get('count') !== null && newObject.get('count') > 0) {
            newObject.set('subscription', subscription);
            newObject.save();
            return newObject;
          }
        }));
        /*eslint-enable */
      }

      function saveRecurrencePatterns(objects) {
        return Promise.all(objects.map(object => {
          object.save();
        }));
      }

      function saveExlusions(objects) {
        return Promise.all(objects.map(object => {
          if (!Ember.isNone(object.get('startDate')) && !Ember.isNone(object.get('endDate'))) {
            object.save();
          }
        }));
      }

      async function saveLocationObject(object) {
        await object.address.save();
        await object.location.save();
        return object.save();
      }

      return new Promise((resolve, reject) => {
        if (records) {
          records.forEach(record => {
            record.save().then(() => {
              saveLocationObject(record.origin.content).then(() => {
                saveLocationObject(record.destination.content).then(() => {
                  saveRecurrencePatterns(record.get('recurrencePatterns')).then(() => {
                    saveExlusions(record.get('exclusions')).then(() => {
                      saveDeleteAllTravelNeeds(record.get('subscriptionTravelNeeds')).then(() => {
                        saveNewTravelNeedObjects(record, record.get('subscriptionTravelNeeds'), record.get('newTravelNeeds')).then(() => {
                          record.set('isForceDirty', false);
                          this.get('activeContext.topActiveContext').get('refreshTableContent').perform([this.get('modelName')]);
                        });
                      });
                    });
                  });
                });
              });
            }).catch(e => {
              reject(e);
            });
          });
        }
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('singleActions', [{
        id: 'edit',
        permId: _mappedPermIds.default.editSubscriptions,
        name: 'Edit',
        action: model => {
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [model], this.get('saveRecordFunction').bind(this));
        }
      }, {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLog,
        name: 'View Activity Log',
        action: subscription => {
          const id = subscription.get('id');
          this.get('workspace').pushState('subscriptionActivityLog', {
            displayName: `Subscription ${id} Activity Log`,
            subscription
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      }]);
      this.set('bulkActions', [{
        name: 'Bulk Edit',
        action: () => {}
      }]);
    },

    rowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),

    fetchDataQuery() {
      const start = (0, _moment.default)(this.get('workspace.startDate')).format();
      const end = (0, _moment.default)(this.get('workspace.endDate')).format();
      const filter = `and(and(lt(startDate,'${start}'),ge(endDate,'${end}')),or(eq(status,'active'),eq(status,'Active')))`;
      const queryParams = {
        filter: filter
      };
      const params = {
        sorts: []
      };

      try {
        // this only applies for server-side pagination
        if (this.get('paginationEnabled')) {
          params.page = {
            offset: this.get('records.length'),
            limit: this.get('limit')
          };
        }

        const sort = this.buildSortParam();

        if (sort) {
          params.sorts.push(sort);
        }

        const includes = this.get('defaultIncludes');

        if (!Ember.isEmpty(includes)) {
          params.includes = includes;
        }

        const filters = this.setupFilterType();
        params.filter = (0, _filters.buildCompoundFilterNode)('and', filters);
        const modelName = this.get('config.modelName');
        return this.query(modelName, params, queryParams);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Workspace view is not refreshed on selecting or deselecting' + error);
      }
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let queryParams = arguments.length > 2 ? arguments[2] : undefined;
      const store = this.get('store');
      const testSubscriptionsWidget = this.get('testSubscriptionsWidget');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      let activeData = store.peekAll(modelName) || [];

      if (testSubscriptionsWidget) {
        return activeData.filter(filterFn).sort(compareFn);
      }

      activeData = await store.query(modelName, queryParams);
      const filteredData = activeData.filter(filterFn).sort(compareFn);
      return filteredData;
    },

    actions: {
      //NYAAR-17671  widgets are not maximized and cross icon should also not display in this widgets.
      onHeaderDoubleClick() {
        const maximizer = this.get('maximizer');
        maximizer.minimize();
      }

    }
  });

  _exports.default = _default;
});