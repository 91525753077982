define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/road-call/road-call-driver-search-config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'driver',
    title: 'Find Driver',
    defaultSortId: 'driverId',
    defaultSortAsc: true,
    fetchModelData: false,
    selectFirstRow: true,
    hideWidgetControls: true,
    columns: [{
      id: 'driverId',
      index: 0,
      type: 'text',
      label: 'Driver ID',
      valuePath: 'driverId',
      defaultWidth: 50,
      searchable: true
    }, {
      id: 'providerId',
      index: 1,
      type: 'text',
      label: 'Provider',
      valuePath: 'provider.name',
      defaultWidth: 50,
      searchable: true
    }, {
      id: 'driverLastName',
      index: 2,
      type: 'text',
      label: 'Last Name',
      valuePath: 'lastName',
      defaultWidth: 50,
      readonlyCell: true,
      editable: true,
      searchable: true
    }, {
      id: 'driverFirstName',
      index: 3,
      type: 'text',
      label: 'First Name',
      valuePath: 'firstName',
      defaultWidth: 50,
      readonlyCell: true,
      editable: true,
      searchable: true
    }, {
      id: 'driverMiddleName',
      index: 4,
      type: 'text',
      label: 'MI',
      valuePath: 'middleName',
      defaultWidth: 50,
      readonlyCell: true,
      editable: true
    }, {
      id: 'placeholder',
      index: 5,
      type: 'boolean',
      label: 'Placeholder',
      valuePath: 'placeholder',
      defaultWidth: 50,
      searchable: true
    }, {
      id: 'dateOfBirth',
      type: 'date',
      index: 6,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      label: 'Date Of Birth',
      defaultWidth: 50,
      valuePath: 'birthDate'
    }, {
      id: 'employmentStartDate',
      type: 'date',
      index: 7,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      label: 'Start Date',
      defaultWidth: 50,
      valuePath: 'employmentStartDate'
    }, {
      id: 'employmentEndDate',
      type: 'date',
      index: 8,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      label: 'End Date',
      defaultWidth: 50,
      valuePath: 'employmentEndDate'
    }, {
      id: 'termination',
      index: 9,
      type: 'boolean',
      label: 'Termination Reason',
      valuePath: 'termination',
      defaultWidth: 50,
      searchable: true
    }, {
      id: 'fingerprint',
      index: 10,
      type: 'boolean',
      label: 'Fingerprint',
      valuePath: 'fingerprint',
      defaultWidth: 50,
      searchable: true
    }, {
      id: 'driverCdlNumber',
      index: 11,
      type: 'string',
      label: 'CDL Number',
      valuePath: 'license.license',
      defaultWidth: 50,
      searchable: false
    }, {
      id: 'classificationName',
      index: 12,
      type: 'text',
      label: 'CDL Classification',
      valuePath: 'license.classificationName.description',
      defaultWidth: 50,
      searchable: true
    }, {
      id: 'address',
      index: 13,
      type: 'text',
      label: 'Address',
      valuePath: 'address.address',
      defaultWidth: 50,
      searchable: true
    }, {
      id: 'phoneNumber',
      index: 14,
      type: 'text',
      label: 'Phone Number',
      valuePath: 'phoneNumbers.phoneNumber',
      defaultWidth: 50,
      searchable: true
    }, {
      id: 'ext',
      index: 15,
      type: 'text',
      label: 'Ext',
      valuePath: 'phoneNumbers.extension',
      defaultWidth: 50,
      searchable: true
    }, {
      id: 'socialSecurityNumber',
      index: 16,
      type: 'text',
      label: 'Social Security Number',
      valuePath: 'ssn',
      defaultWidth: 50,
      searchable: true
    }]
  };
  _exports.default = _default;
});