define("adept-iq/pods/components/rider-management-widget/rider-suspension/suspension-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/rider-management-widget/rider-suspension/suspension-table/config", "adept-iq/utils/sorts", "adept-iq/utils/filters"], function (_exports, _component, _config, _sorts, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    config: _config.default,
    store: Ember.inject.service(),
    rider: Ember.inject.service(),
    recordSetter: Ember.observer('riderId', async function () {
      const filteredData = await this.getRiderSuspensions('rider-suspension');
      this.set('records', filteredData);
    }),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    async getRiderSuspensions(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      let suspensionReasons = this.get('suspensionReasons');

      if (Ember.isEmpty(suspensionReasons)) {
        suspensionReasons = await this.get('rider').getConfigSuspensionReasons();
        this.set('suspensionReasons', suspensionReasons);
      }

      let activeData = store.peekAll(modelName) || [];
      activeData = activeData.filter(suspension => suspension.get('rider.id') === this.get('riderId'));
      const filteredData = activeData.filter(filterFn).sort(compareFn);
      filteredData.map(data => {
        const suspensionReason = suspensionReasons.find(rfa => rfa.name === data.reason);

        if (Ember.isPresent(suspensionReason)) {
          data.set('reasonDisplayName', suspensionReason.get('value.displayName'));
        }
      });
      return filteredData;
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const filteredData = await this.getRiderSuspensions(modelName, params);
      return filteredData;
    },

    actions: {
      onRowClick(currentRow) {
        this._super(currentRow);

        this.get('OnTableRowClick')(currentRow);
        this.get('rider').setLastAccessedRiderSuspensionId(currentRow.get('id'));
      }

    }
  });

  _exports.default = _default;
});