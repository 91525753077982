define("adept-iq/utils/format-function", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDateRangeForSearch = _exports.formatTargetTimeValue = _exports.formatPhoneNumber = _exports.formatEnumValue = void 0;

  const formatTargetTimeValue = (targetValue, dateFormat) => {
    const targetDateStr = (0, _moment.default)(targetValue).format(dateFormat);
    const targetDate = (0, _moment.default)(targetDateStr, dateFormat);
    return targetDate.isValid() ? targetDate.toDate() : targetValue;
  };

  _exports.formatTargetTimeValue = formatTargetTimeValue;

  const formatEnumValue = targetValue => {
    if (typeof targetValue !== 'object') {
      return targetValue;
    }

    const length = targetValue.values.filter(val => val.className === 'valueItem').length;
    const value = `${length} ${targetValue.fieldName}`;
    return value;
  };

  _exports.formatEnumValue = formatEnumValue;

  const getDateRangeForSearch = (fromDt, toDt) => {
    let finalFromDate = null;
    let finalToDate = null;

    if (fromDt) {
      finalFromDate = (0, _moment.default)(fromDt).startOf('day');
      finalToDate = toDt ? (0, _moment.default)(toDt).endOf('day') : (0, _moment.default)(fromDt).endOf('day');
    }

    return {
      startDate: (0, _moment.default)(finalFromDate).toISOString(),
      endDate: (0, _moment.default)(finalToDate).toISOString()
    };
  };

  _exports.getDateRangeForSearch = getDateRangeForSearch;

  const formatPhoneNumber = (areaCode, phoneNumber, extension) => {
    const phone = phoneNumber.match(/\d{3}(?=\d{2,3})|\d+/g).join('-');

    if (extension) {
      return `(${areaCode}) ${phone}-#${extension}`;
    }

    return `(${areaCode}) ${phone}`;
  };

  _exports.formatPhoneNumber = formatPhoneNumber;
});