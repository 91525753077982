define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.vehicleEditOptions = _exports.lookupOptions = _exports.driverEditOptions = _exports.VEHICLE_TAB = _exports.LOOKUP_TAB = _exports.DRIVER_TAB = void 0;
  const VEHICLE_TAB = 'vehicle';
  _exports.VEHICLE_TAB = VEHICLE_TAB;
  const DRIVER_TAB = 'driver';
  _exports.DRIVER_TAB = DRIVER_TAB;
  const LOOKUP_TAB = 'lookup';
  _exports.LOOKUP_TAB = LOOKUP_TAB;
  const driverEditOptions = ['Driver', //'Provider',  #hide as per AIQ-1054
  '19A-Review', 'Log', 'Health', 'Violation', 'Suspension', 'Accident', 'Availability'];
  _exports.driverEditOptions = driverEditOptions;
  const vehicleEditOptions = ['Vehicle', 'Maintenance', 'DOT-Inspection', 'Road Call', 'Equipment', 'Availability'];
  _exports.vehicleEditOptions = vehicleEditOptions;
  const lookupOptions = [{
    groupName: 'DRIVER LOOKUP',
    options: [{
      id: 'cdl-classification',
      name: 'CDL Classification'
    }, {
      id: 'employment-status',
      name: 'Employment Status'
    }, {
      id: 'position',
      name: 'Position'
    }, {
      id: 'physical-type',
      name: 'Physical Type'
    }, {
      id: 'termination-reason',
      name: 'Termination Reason'
    }]
  }, {
    groupName: 'VEHICLE LOOKUP',
    options: [{
      id: 'body',
      name: 'Body'
    }, {
      id: 'body-width',
      name: 'Body Width'
    }, {
      id: 'category',
      name: 'Category'
    }, {
      id: 'chassis',
      name: 'Chassis'
    }, {
      id: 'fuel-cap',
      name: 'Fuel Cap'
    }, {
      id: 'fuel-type',
      name: 'Fuel Type'
    }, {
      id: 'inspector',
      name: 'Inspector'
    }, {
      id: 'lease-company',
      name: 'Lease Company'
    }, {
      id: 'maint-location',
      name: 'Maintenance Location'
    }, {
      id: 'maint-status',
      name: 'Maintenance Status'
    }, {
      id: 'maint-type',
      name: 'Maintenance Type'
    }, {
      id: 'manufacturer',
      name: 'Manufacturer'
    }, {
      id: 'vehicle-model',
      name: 'Model'
    }, {
      id: 'problem-identified',
      name: 'Problem Identified'
    }, {
      id: 'rear-axel',
      name: 'Rear Axle'
    }, {
      id: 'road-call-code',
      name: 'Road Call Code'
    }, {
      id: 'status',
      name: 'Status'
    }, {
      id: 'transmission',
      name: 'Transmission'
    }, {
      id: 'tire-size',
      name: 'Tire Size'
    }, {
      id: 'vehicle-type',
      name: 'Vehicle Type'
    }, {
      id: 'vehicle-capacity-type',
      name: 'Vehicle Capacity Type'
    }, {
      id: 'wheelbase',
      name: 'Wheelbase'
    }]
  }];
  _exports.lookupOptions = lookupOptions;
});