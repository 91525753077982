define("adept-iq/services/rider", ["exports", "adept-iq/config/api-urls"], function (_exports, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function cleanFieldString(text) {
    return typeof text === 'undefined' ? '' : text;
  }

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    activeDragRiderPlace: null,
    activePlace: null,
    lastAccessedRiderId: null,
    lastAccessedCertificationId: null,
    lastAccessedRiderSuspensionId: null,
    lastAccessedRiderTravelNeedId: null,

    init() {
      this._super(...arguments);
    },

    fetchRiderInfoById(riderId) {
      return new Promise(async (resolve, reject) => {
        this.get('ajax').request(_apiUrls.API.riderManagementService.host + '/rider-with-subentities/' + riderId, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
          }
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      });
    },

    setActiveDragRiderPlace(place) {
      this.set('activeDragRiderPlace', place);
    },

    setActivePlace(place) {
      this.set('activePlace', place);
    },

    setlastAccessedRiderId(riderId) {
      this.set('lastAccessedRiderId', riderId);
    },

    getLastAccessedRiderId() {
      return this.get('lastAccessedRiderId');
    },

    getLastAccessedCertificationId() {
      return this.get('lastAccessedCertificationId');
    },

    setLastAccessedCertificationId(certificationId) {
      this.set('lastAccessedCertificationId', certificationId);
    },

    setLastAccessedRiderSuspensionId(riderSuspensionId) {
      this.set('lastAccessedRiderSuspensionId', riderSuspensionId);
    },

    getLastAccessedRiderSuspensionId() {
      return this.get('lastAccessedRiderSuspensionId');
    },

    setLastAccessedRiderFundingId(riderFundingId) {
      this.set('lastAccessedRiderFundingId', riderFundingId);
    },

    getLastAccessedRiderFundingId() {
      return this.get('lastAccessedRiderFundingId');
    },

    setLastAccessedRiderTravelNeedId(riderTravelNeedId) {
      this.set('lastAccessedRiderTravelNeedId', riderTravelNeedId);
    },

    getLastAccessedRiderTravelNeedId() {
      return this.get('lastAccessedRiderTravelNeedId');
    },

    updateAddress(record, benomadAddress) {
      record.set('streetNumber', cleanFieldString(benomadAddress.address.streetNumber));
      record.set('premise', cleanFieldString(benomadAddress.premise));
      record.set('streetAddress', cleanFieldString(benomadAddress.address.streetName));
      record.set('locality', cleanFieldString(benomadAddress.address.city));
      record.set('region', cleanFieldString(benomadAddress.address.state));
      record.set('subRegion', cleanFieldString(benomadAddress.address.countrySecondarySubdivision));
      record.set('postalCode', cleanFieldString(benomadAddress.address.postalCode));
      record.set('country', cleanFieldString(benomadAddress.address.countryCode));
      record.set('freeformAddress', cleanFieldString(benomadAddress.address.freeformAddress));
    },

    async getConfigRelationshipTypes() {
      return this.store.query('cs-config-item', {
        category: 'config-System_Configuration-rider_relationship_types'
      });
    },

    async getConfigRiderPhoneTypes() {
      return this.store.query('cs-config-item', {
        category: 'config-System_Configuration-rider_phone_types'
      });
    },

    async getConfigAddressTypes() {
      return this.store.query('cs-config-item', {
        category: 'config-System_Configuration-rider_address_types'
      });
    },

    async getConfigTravelNeedTypes() {
      return this.store.query('cs-config-item', {
        category: 'config-System_Configuration-travel_need_types'
      });
    },

    async getConfigProviderTypes() {
      return this.store.query('cs-config-item', {
        category: 'config-System_Configuration-providers'
      });
    },

    async getConfigContactReasonTypes() {
      return this.store.query('cs-config-item', {
        category: 'config-System_Configuration-contact_reason_types'
      });
    },

    async getConfigEligibilityCategories() {
      return this.store.query('cs-config-item', {
        category: 'config-System_Configuration-eligibility_categories'
      });
    },

    async getConfigEligibilityConditionsTypes() {
      return this.store.query('cs-config-item', {
        category: 'config-System_Configuration-eligibility_conditions'
      });
    },

    async getConfigRiderAgeLimit() {
      return this.store.query('cs-config-item', {
        category: 'config-System_Configuration-rider_age_limit'
      });
    },

    async getConfigSuspensionReasons() {
      return this.store.query('cs-config-item', {
        category: 'config-System_Configuration-suspension_reasons'
      });
    },

    async getConfigCertificationDeniedReasons() {
      return this.store.query('cs-config-item', {
        category: 'config-System_Configuration-certification_denied_reasons'
      });
    },

    async getConfigFundingAgencies() {
      return this.store.query('cs-config-item', {
        category: 'config-System_Configuration-funding_agencies'
      });
    },

    async getConfigRiderActivityLogTypes() {
      return this.store.query('cs-config-item', {
        category: 'config-System_Configuration-Activity_Log_Types-rider'
      });
    },

    createEditRiderActivityLog(activityLogs) {
      const activityLogAdapter = this.get('store').adapterFor('activity-log');
      activityLogs.forEach(async activityLog => {
        await activityLogAdapter.createUserRiderActivity(activityLog, activityLog.content.actionType, activityLog.content.eventType);
      });
    }

  });

  _exports.default = _default;
});