define("adept-iq/helpers/plus-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.plusOne = plusOne;

  function plusOne(index) {
    return parseInt(index, 10) + 1;
  }

  var _default = Ember.Helper.helper(plusOne);

  _exports.default = _default;
});