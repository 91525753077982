define("adept-iq/pods/components/side-drawer/system-config/modals/break-days-action-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender() {
      this._super(...arguments);

      this.$('.ember-modal-dialog').draggable();
    },

    actions: {
      onPrimaryActionButtonClick() {
        this.get('modalData').closeAction();
      },

      onCloseTooltipModal() {
        this.get('modalData').closeAction();
      },

      onClickOverlayTooltipModal() {
        this.get('modalData').closeAction();
      },

      onDayClick(index) {
        this.get('modalData').onDayClick(index + 1);
      }

    }
  });

  _exports.default = _default;
});