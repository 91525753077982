define("adept-iq/pods/components/reoptimize-modal/operation-views/remove-driver-break/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XpwrLpwX",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"reoptimize-modal/operation-tables/remove-driver-break-table\",null,[[\"tableRef\",\"isTaskRunning\"],[[23,[\"tableRef\"]],[23,[\"isTaskRunning\"]]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"remove-driver-break button-holder\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"remove-driver-break-button\"],[12,\"disabled\",[21,\"disableApplyButton\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onRemoveClick\"],null]],[9],[7,\"span\"],[9],[0,\"Remove\"],[10],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/reoptimize-modal/operation-views/remove-driver-break/template.hbs"
    }
  });

  _exports.default = _default;
});