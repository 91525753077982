define("adept-iq/pods/components/side-drawer/reconcile-trip-transfer/component", ["exports", "ember-light-table", "ember-concurrency", "adept-iq/pods/components/side-drawer/reconcile-trip-transfer/config", "moment", "lodash"], function (_exports, _emberLightTable, _emberConcurrency, _config, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CURRENT_TAB = 'current';
  const DESTINATION_TAB = 'destination';
  const TOGGLE_TYPE_BEFORE = 'before';
  const TOGGLE_TYPE_AFTER = 'after';
  const TRIP = 'trip';

  var _default = Ember.Component.extend({
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    reconcileLambda: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    selectedTabOption: DESTINATION_TAB,
    onCloseClick: null,
    selectedImpact: TOGGLE_TYPE_BEFORE,
    title: 'Transfer Trip to Another Route',
    routeListByProvider: null,
    selectedRoute: null,
    selectedProvider: null,
    stopsTable: null,
    config: _config.default,
    currentRouteStops: null,
    destinationRouteStops: null,
    destinationNewRouteStops: null,
    routeDetails: Ember.computed.readOnly('stackFrame.options.route'),
    tripDetails: Ember.computed.alias('reconcile.reconcileTrip'),
    reconcileTrips: null,
    selectedReconcileTrip: Ember.computed.alias('reconcile.selectedTripRow'),
    reconcileRouteDetails: Ember.computed.alias('reconcile.reconcileRouteInfo'),
    tripsDetails: Ember.computed.readOnly('reconcile.reconcileTripDetails'),
    reconcileTripDate: Ember.computed.alias('reconcile.reconcileDate'),
    reconcileRouteOptions: Ember.computed.alias('reconcile.reconcileRouteOptions'),
    enableTabbing: Ember.computed.alias('selectedRoute'),
    callBack: Ember.computed.readOnly('stackFrame.options.action'),
    pickArrivalTime: null,
    dropArrivalTime: null,
    pickAndDropEnable: Ember.computed.empty('selectedRoute'),
    canUndo: Ember.computed.or('selectedProvider', 'selectedRoute'),
    canSave: Ember.computed.and('selectedProvider', 'pickArrivalTime', 'dropArrivalTime', 'selectedRoute'),
    driverFullName: Ember.computed('routeDetails', function () {
      const firstName = this.get('routeDetails').driver.firstname;
      const lastName = this.get('routeDetails').driver.lastname;
      return `${firstName} ${lastName}`;
    }),
    providerOptions: Ember.computed('activeContext.topActiveContext', function () {
      return this.get('store').peekAll('provider');
    }),
    currentTripDate: Ember.computed('tripDetails', function () {
      const tripDetails = this.get('tripDetails');
      const formattedTime = tripDetails.serviceStartTime ? (0, _moment.default)(tripDetails.serviceStartTime).format('MM-DD-YYYY') : '';
      return formattedTime;
    }),
    routeOptions: Ember.computed('reconcile.reconcileRouteMap', 'selectedProvider', function () {
      const selectedProviderId = this.get('selectedProvider.id');
      const reconcileRouteMap = this.get('reconcile.reconcileRouteMap') || {};
      const reconcileRoutes = reconcileRouteMap[selectedProviderId] || [];
      const selectedRoute = this.get('reconcile.reconcileRouteInfo');
      const filteredRouteId = reconcileRoutes.filter(route => {
        return parseInt(route.id, 10) !== selectedRoute.routeid;
      });
      return filteredRouteId.map(route => {
        return {
          'id': route.trackingid,
          'routeId': route.id
        };
      });
    }),

    /*
    The toggle type after gets enabled when both pick and drop time is selected
     */
    enableToggleTypeAfter: Ember.computed('pickArrivalTime', 'dropArrivalTime', function () {
      const pickArrivalTime = this.get('pickArrivalTime');
      const dropArrivalTime = this.get('dropArrivalTime');
      const selectedTrip = this.get('selectedReconcileTrip');
      const enableToggle = selectedTrip.type.toLowerCase() === 'pick' ? pickArrivalTime === selectedTrip.arriveEvent.receivedat : dropArrivalTime === selectedTrip.arriveEvent.receivedat;
      return enableToggle;
    }),
    updateTableObserver: Ember.observer('selectedToggleButtonType', function () {
      this.manageRouteDetails();
    }),

    /*
    When trips are transferred the original array gets altered.
    So we are creating a copy and adding the transferred trip to the copy
    */
    reconcileTripsCopy: Ember.computed('reconcileTrips', function () {
      return _lodash.default.clone(this.get('reconcileTrips'));
    }),

    /*
    When the newly selected pick and drop time are set to the arriveEvent receivedat
    then the original object gets changed.
    So a copy is created and we are altering the copy to make the changes
    */
    tripsDetailCopy: Ember.computed('tripsDetails', function () {
      const tripsDetail = this.get('tripsDetails');
      return tripsDetail.map(trip => {
        const arriveEvent = trip.arriveEvent;
        return { ...trip,
          arriveEvent: { ...arriveEvent
          }
        };
      });
    }),

    init() {
      this._super(...arguments);

      this.createStopsTable();
      const stopsTable = this.get('stopsTable');
      this.setProperties({
        selectedRouteContainerType: CURRENT_TAB,
        selectedToggleButtonType: TOGGLE_TYPE_BEFORE,
        stopsTable,
        currentRouteStops: [],
        destinationRouteStops: [],
        destinationNewRouteStops: []
      });
      this.setCurrentRouteStopList();
    },

    didInsertElement() {
      this._super(...arguments);

      this.setSideDrawerWidth('720px');
      this.get('systemConfig').set('showRightPanel', true);
    },

    createStopsTable() {
      const configStopsTableColumns = this.config.stopColumns;
      /*
      To change the date format displayed in the table to hh:mm A
      */

      configStopsTableColumns.forEach(column => {
        if (['date', 'datetime', 'time', 'datetimeflatpickr'].includes(column.dataType) && column.format && typeof column.format === 'string') {
          const formatDate = column.format;

          column.format = value => {
            return Ember.isEmpty(value) ? value : (0, _moment.default)(value).format(formatDate);
          };
        }
      });
      const stopsTable = new _emberLightTable.default(configStopsTableColumns);
      this.set('stopsTable', stopsTable);
    },

    /*
    On opening the transfer trip side drawer we need to display the trips available for the
    selected route in the before impact of current route with the selected trip highlighted
    */
    setCurrentRouteStopList() {
      const currentRouteTripDetails = this.get('tripsDetails');
      const finalList = this.getSortedStopList(currentRouteTripDetails);
      const tripId = this.get('selectedReconcileTrip.tripId');
      const reconcileStopPointMap = this.get('reconcile.reconcileStopPointMap');
      const stops = reconcileStopPointMap[tripId] || [];
      /*
      To display the pick and drop time in the timepickr initially of the selected trip
      */

      stops.forEach(stop => {
        if (stop.type.toLowerCase() === 'pick') {
          this.set('pickArrivalTime', stop.trip.arriveEvent.receivedat);
        }

        this.set('dropArrivalTime', stop.trip.arriveEvent.receivedat);
      });
      this.set('currentRouteStops', finalList);
      this.setStopTable(finalList);
      this.setCurrentTripSelected();
    },

    getSortedStopList(tripList) {
      const sortedStopList = this.sortListByActualArriveTime(tripList);
      return sortedStopList;
    },

    /*
    The trips should be sorted based on the actualArrivalTime
    so that the transferred trip can be placed in the correct order
    in the after impact table of destination route.
    */
    sortListByActualArriveTime(list) {
      return list.sort(function (a, b) {
        const aArriveEventReceivedAt = a.arriveEvent.receivedat;
        const bArriveEventReceivedAt = b.arriveEvent.receivedat; // eslint-disable-next-line no-nested-ternary

        return (0, _moment.default)(aArriveEventReceivedAt) > (0, _moment.default)(bArriveEventReceivedAt) ? 1 : (0, _moment.default)(bArriveEventReceivedAt) > (0, _moment.default)(aArriveEventReceivedAt) ? -1 : 0;
      });
    },

    setStopTable(stopList) {
      this.get('stopsTable').setRows([]);

      if (stopList && stopList.length > 0) {
        this.get('stopsTable').setRows(stopList);
      }
    },

    manageRouteDetails() {
      const type = Ember.get(this, 'selectedToggleButtonType');
      const selectedRouteTab = Ember.get(this, 'selectedRouteContainerType');

      if (type === TOGGLE_TYPE_BEFORE) {
        if (selectedRouteTab === CURRENT_TAB) {
          this.setStopTable(this.currentRouteStops);
        } else {
          this.setStopTable(this.destinationRouteStops);
        }
      }

      if (type === TOGGLE_TYPE_AFTER) {
        if (selectedRouteTab === CURRENT_TAB) {
          this.manageRouteDetailsWithoutGvnTripDetails(this.currentRouteStops);
        } else {
          this.setStopTable(this.destinationNewRouteStops);
        }
      }

      this.setCurrentTripSelected();
    },

    getStopListExceptSelectedTrip(stopList) {
      return stopList.filter(stop => stop.tripId !== this.selectedReconcileTrip.tripId);
    },

    manageRouteDetailsWithoutGvnTripDetails(stopList) {
      const filteredList = this.getStopListExceptSelectedTrip(stopList);
      const sortedStopList = this.sortListByActualArriveTime(filteredList);
      this.setStopTable(sortedStopList);
    },

    reconcileTripsWithChangedPickAndDropTime(time, type) {
      const reconcileTrips = this.get('reconcileTripsCopy');
      const reconcileTripsDetail = this.get('tripsDetailCopy');
      const tripId = this.get('selectedReconcileTrip.tripId');
      const toggleBtnType = Ember.get(this, 'selectedToggleButtonType');
      const selectedRouteTab = Ember.get(this, 'selectedRouteContainerType');

      const reconcileStopPointMap = _lodash.default.groupBy(reconcileTripsDetail.map(tripDetails => {
        return {
          tripId: tripDetails.tripId,
          trip: tripDetails
        };
      }), 'tripId');

      const stops = reconcileStopPointMap[tripId] || [];
      const convertedStops = this.convertToEmberObject(stops);
      const selectedStop = convertedStops.find(stop => stop.trip.type.toLowerCase() === type);
      /*
      To check whether the trip is already there in the after impact table of the destination route.
      */

      const previousTripIndex = reconcileTrips.findIndex(trip => {
        return trip.tripId === tripId && trip.type.toLowerCase() === type;
      });

      if (previousTripIndex > -1) {
        reconcileTrips.splice(previousTripIndex, 1);
      }
      /*
      Setting the newly selected pick and drop time to the selected trip in the trip response
      to display it correctly in the after impact table of destination route.
      */


      const arriveEventTime = selectedStop.trip.type.toLowerCase() === type ? time : '';
      selectedStop.trip.set('arriveEvent.receivedat', arriveEventTime);
      reconcileTrips.pushObject(selectedStop.trip);
      const sortedStopList = this.getSortedStopList(reconcileTrips);
      this.set('destinationNewRouteStops', sortedStopList);

      if (toggleBtnType === TOGGLE_TYPE_AFTER && selectedRouteTab === DESTINATION_TAB) {
        this.setStopTable([]);
        this.setStopTable(sortedStopList);
      }
    },

    /*The changed pick and drop time should be displayed in the table.
    The normal object doesn't allow us to set the value of receivedat.
    So the stops and trip of the stops are converted to EmberObject to set the new value
    */
    convertToEmberObject(stops) {
      const convertedStops = stops.map(stop => {
        return Ember.Object.create(stop);
      });
      convertedStops.map(stop => {
        if (_lodash.default.isObject(stop[TRIP])) {
          const convertedTrip = Ember.Object.create(stop[TRIP]);
          stop.set(TRIP, convertedTrip);
        }
      });
      return convertedStops;
    },

    /*
    To get the trip details of the route to which the selected trips are going to be transferred.
    */
    async getTripsByRouteId(route) {
      const reconcileTripsByRoutes = await this.reconcileLambda.getReconcileTripsByRouteId.perform(route.routeId);
      this.set('reconcileTrips', reconcileTripsByRoutes);
    },

    /*
    To highlight the rows of the selected trip
    */
    setCurrentTripSelected() {
      const rows = Ember.get(this, 'stopsTable.rows');
      rows.map(row => {
        if (row.content.tripId === this.get('selectedReconcileTrip').tripId) {
          row.set('selected', true);
        }
      });
    },

    showToolTip() {
      const tooltip = this.get('tooltip');
      const title = 'Trip Transfer to Another Route';
      const tip = 'Transfer trip ' + this.selectedReconcileTrip.tripId + ' from Route ' + this.selectedReconcileTrip.routeId + ' to Route ' + this.selectedRoute.routeId;
      const successMsg = 'Trip ' + this.selectedReconcileTrip.tripId + ' was successfully transferred';
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('saveTask').perform().then(() => {
            this.send('cancel');
            this.get('workspace').popState('reconcileTripTransferToRoute');
            tooltip.reset();
            this.get('notifications').success(successMsg);
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in transfer trip, please try again.';
                break;
            }

            this.set('errorMessage', message);
            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    generateRandomId() {
      return Math.floor(Math.random() * 10000);
    },

    buildArriveAndDepartEventsFromPreviousTripDetails(pickTripResponse, dropTripResponse) {
      return [{
        'id': pickTripResponse.arriveEvent.id,
        'routeId': pickTripResponse.arriveEvent.routeid,
        'driverId': pickTripResponse.arriveEvent.driverid,
        'vehicleId': pickTripResponse.arriveEvent.vehicleid,
        'scheduleId': pickTripResponse.arriveEventscheduleid,
        'stopPointId': pickTripResponse.arriveEvent.stoppointid,
        'tripId': pickTripResponse.arriveEvent.tripid,
        'riderId': pickTripResponse.arriveEvent.riderid,
        'odo': pickTripResponse.arriveEvent.odo,
        'speed': pickTripResponse.arriveEvent.speed,
        'heading': pickTripResponse.arriveEvent.heading,
        'eventType': pickTripResponse.arriveEvent.eventtype,
        'actionType': pickTripResponse.arriveEvent.actiontype,
        'createdAt': pickTripResponse.arriveEvent.createdat,
        'updatedAt': pickTripResponse.arriveEvent.updatedat,
        'receivedAt': pickTripResponse.arriveEvent.receivedat,
        'dbVersion': null,
        'op$1': pickTripResponse.arriveEvent.__op,
        'deleted$1': false,
        'reconcileDate': pickTripResponse.arriveEvent.reconciledate,
        'isReconciled': true,
        'reconciliationNotes': null
      }, {
        'id': pickTripResponse.departEvent.id,
        'routeId': pickTripResponse.departEvent.routeid,
        'driverId': pickTripResponse.departEvent.driverid,
        'vehicleId': pickTripResponse.departEvent.vehicleid,
        'scheduleId': pickTripResponse.departEventscheduleid,
        'stopPointId': pickTripResponse.departEvent.stoppointid,
        'tripId': pickTripResponse.departEvent.tripid,
        'riderId': pickTripResponse.departEvent.riderid,
        'odo': pickTripResponse.departEvent.odo,
        'speed': pickTripResponse.departEvent.speed,
        'heading': pickTripResponse.departEvent.heading,
        'eventType': pickTripResponse.departEvent.eventtype,
        'actionType': pickTripResponse.departEvent.actiontype,
        'createdAt': pickTripResponse.departEvent.createdat,
        'updatedAt': pickTripResponse.departEvent.updatedat,
        'receivedAt': pickTripResponse.departEvent.receivedat,
        'dbVersion': null,
        'op$1': pickTripResponse.departEvent.__op,
        'deleted$1': false,
        'reconcileDate': pickTripResponse.departEvent.reconciledate,
        'isReconciled': true,
        'reconciliationNotes': null
      }, {
        'id': dropTripResponse.arriveEvent.id,
        'routeId': dropTripResponse.arriveEvent.routeid,
        'driverId': dropTripResponse.arriveEvent.driverid,
        'vehicleId': dropTripResponse.arriveEvent.vehicleid,
        'scheduleId': dropTripResponse.arriveEventscheduleid,
        'stopPointId': dropTripResponse.arriveEvent.stoppointid,
        'tripId': dropTripResponse.arriveEvent.tripid,
        'riderId': dropTripResponse.arriveEvent.riderid,
        'odo': dropTripResponse.arriveEvent.odo,
        'speed': dropTripResponse.arriveEvent.speed,
        'heading': dropTripResponse.arriveEvent.heading,
        'eventType': dropTripResponse.arriveEvent.eventtype,
        'actionType': dropTripResponse.arriveEvent.actiontype,
        'createdAt': dropTripResponse.arriveEvent.createdat,
        'updatedAt': dropTripResponse.arriveEvent.updatedat,
        'receivedAt': dropTripResponse.arriveEvent.receivedat,
        'dbVersion': null,
        'op$1': dropTripResponse.arriveEvent.__op,
        'deleted$1': false,
        'reconcileDate': dropTripResponse.arriveEvent.reconciledate,
        'isReconciled': true,
        'reconciliationNotes': null
      }, {
        'id': dropTripResponse.departEvent.id,
        'routeId': dropTripResponse.departEvent.routeid,
        'driverId': dropTripResponse.departEvent.driverid,
        'vehicleId': dropTripResponse.departEvent.vehicleid,
        'scheduleId': dropTripResponse.departEventscheduleid,
        'stopPointId': dropTripResponse.departEvent.stoppointid,
        'tripId': dropTripResponse.departEvent.tripid,
        'riderId': dropTripResponse.departEvent.riderid,
        'odo': dropTripResponse.departEvent.odo,
        'speed': dropTripResponse.departEvent.speed,
        'heading': dropTripResponse.departEvent.heading,
        'eventType': dropTripResponse.departEvent.eventtype,
        'actionType': dropTripResponse.departEvent.actiontype,
        'createdAt': dropTripResponse.departEvent.createdat,
        'updatedAt': dropTripResponse.departEvent.updatedat,
        'receivedAt': dropTripResponse.departEvent.receivedat,
        'dbVersion': null,
        'op$1': dropTripResponse.departEvent.__op,
        'deleted$1': false,
        'reconcileDate': dropTripResponse.departEvent.reconciledate,
        'isReconciled': true,
        'reconciliationNotes': null
      }];
    },

    buildTripResponseFromPreviousTripDetails(previousTripResponse) {
      return {
        'id': previousTripResponse.tripId,
        'routeId': previousTripResponse.routeId,
        'status': previousTripResponse.status,
        'externalId': previousTripResponse.externalTripId,
        'scheduleId': previousTripResponse.scheduleId,
        'createdAt': previousTripResponse.createdAt,
        'updatedAt': previousTripResponse.updatedAt,
        'op$1': previousTripResponse.op$1,
        'deleted$1': false
      };
    },

    buildEventAttributeFromPreviousTripDetails(previousTripResponse) {
      return [{
        'id': previousTripResponse.eventAttribute.id,
        'eventId': previousTripResponse.eventAttribute.eventid,
        'attributeId': previousTripResponse.eventAttribute.attributeid,
        'attributeCount': previousTripResponse.eventAttribute.attributecount,
        'scheduleId': previousTripResponse.eventAttribute.scheduleid,
        'createdAt': previousTripResponse.createdAt,
        'updatedAt': previousTripResponse.updatedAt,
        'deletedAt': null,
        'deleted$1': false,
        'dbVersion': null,
        'op$1': previousTripResponse.op$1
      }];
    },

    buildArriveAndDepartEventResForTransferTripPayload(reconcileDate, pickTripResponse, dropTripResponse, rvdResponse) {
      return [{
        'id': pickTripResponse.arriveEvent.id,
        'routeId': rvdResponse.routeid,
        'driverId': pickTripResponse.arriveEvent.driverid,
        'vehicleId': pickTripResponse.arriveEvent.vehicleid,
        'scheduleId': pickTripResponse.arriveEventscheduleid,
        'stopPointId': pickTripResponse.arriveEvent.stoppointid,
        'tripId': pickTripResponse.arriveEvent.tripid,
        'riderId': pickTripResponse.arriveEvent.riderid,
        'odo': pickTripResponse.arriveEvent.odo,
        'speed': pickTripResponse.arriveEvent.speed,
        'heading': pickTripResponse.arriveEvent.heading,
        'eventType': pickTripResponse.arriveEvent.eventtype,
        'actionType': pickTripResponse.arriveEvent.actiontype,
        'createdAt': pickTripResponse.arriveEvent.createdat,
        'updatedAt': reconcileDate,
        'receivedAt': pickTripResponse.arriveEvent.receivedat,
        'dbVersion': null,
        'op$1': pickTripResponse.arriveEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      }, {
        'id': pickTripResponse.departEvent.id,
        'routeId': rvdResponse.routeid,
        'driverId': pickTripResponse.departEvent.driverid,
        'vehicleId': pickTripResponse.departEvent.vehicleid,
        'scheduleId': pickTripResponse.departEventscheduleid,
        'stopPointId': pickTripResponse.departEvent.stoppointid,
        'tripId': pickTripResponse.departEvent.tripid,
        'riderId': pickTripResponse.departEvent.riderid,
        'odo': pickTripResponse.departEvent.odo,
        'speed': pickTripResponse.departEvent.speed,
        'heading': pickTripResponse.departEvent.heading,
        'eventType': pickTripResponse.departEvent.eventtype,
        'actionType': pickTripResponse.departEvent.actiontype,
        'createdAt': pickTripResponse.departEvent.createdat,
        'updatedAt': reconcileDate,
        'receivedAt': pickTripResponse.departEvent.receivedat,
        'dbVersion': null,
        'op$1': pickTripResponse.departEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      }, {
        'id': dropTripResponse.arriveEvent.id,
        'routeId': rvdResponse.routeid,
        'driverId': dropTripResponse.arriveEvent.driverid,
        'vehicleId': dropTripResponse.arriveEvent.vehicleid,
        'scheduleId': dropTripResponse.arriveEventscheduleid,
        'stopPointId': dropTripResponse.arriveEvent.stoppointid,
        'tripId': dropTripResponse.arriveEvent.tripid,
        'riderId': dropTripResponse.arriveEvent.riderid,
        'odo': dropTripResponse.arriveEvent.odo,
        'speed': dropTripResponse.arriveEvent.speed,
        'heading': dropTripResponse.arriveEvent.heading,
        'eventType': dropTripResponse.arriveEvent.eventtype,
        'actionType': dropTripResponse.arriveEvent.actiontype,
        'createdAt': dropTripResponse.arriveEvent.createdat,
        'updatedAt': reconcileDate,
        'receivedAt': dropTripResponse.arriveEvent.receivedat,
        'dbVersion': null,
        'op$1': dropTripResponse.arriveEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      }, {
        'id': dropTripResponse.departEvent.id,
        'routeId': rvdResponse.routeid,
        'driverId': dropTripResponse.departEvent.driverid,
        'vehicleId': dropTripResponse.departEvent.vehicleid,
        'scheduleId': dropTripResponse.departEventscheduleid,
        'stopPointId': dropTripResponse.departEvent.stoppointid,
        'tripId': dropTripResponse.departEvent.tripid,
        'riderId': dropTripResponse.departEvent.riderid,
        'odo': dropTripResponse.departEvent.odo,
        'speed': dropTripResponse.departEvent.speed,
        'heading': dropTripResponse.departEvent.heading,
        'eventType': dropTripResponse.departEvent.eventtype,
        'actionType': dropTripResponse.departEvent.actiontype,
        'createdAt': dropTripResponse.departEvent.createdat,
        'updatedAt': reconcileDate,
        'receivedAt': dropTripResponse.departEvent.receivedat,
        'dbVersion': null,
        'op$1': dropTripResponse.departEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      }];
    },

    buildStartAddressResForTransferTripPayload(reconcileDate, tripPickDetails) {
      return {
        'id': tripPickDetails.address.id,
        'streetName': tripPickDetails.address.streetname,
        'streetNumber': tripPickDetails.address.streetnumber,
        'postalCode': tripPickDetails.address.postalcode,
        'locality': tripPickDetails.address.locality,
        'region': tripPickDetails.address.region,
        'latitude': tripPickDetails.address.latitude,
        'longitude': tripPickDetails.address.longitude,
        'formattedAddress': tripPickDetails.address.formattedaddress,
        'zoneId': tripPickDetails.address.zoneid,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'op$1': tripPickDetails.address.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      };
    },

    buildEndAddressResForTransferTripPayload(reconcileDate, tripDropDetails) {
      return {
        'id': tripDropDetails.address.id,
        'streetName': tripDropDetails.address.streetname,
        'streetNumber': tripDropDetails.address.streetnumber,
        'postalCode': tripDropDetails.address.postalcode,
        'locality': tripDropDetails.address.locality,
        'region': tripDropDetails.address.region,
        'latitude': tripDropDetails.address.latitude,
        'longitude': tripDropDetails.address.longitude,
        'formattedAddress': tripDropDetails.address.formattedaddress,
        'zoneId': tripDropDetails.address.zoneid,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'op$1': tripDropDetails.address.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      };
    },

    buildRiderEventResForTransferTripPayload(reconcileRouteInfo, tripPickDetails, tripDropDetails, reconcileDate) {
      return [{
        'id': tripPickDetails.riderEvent.id,
        'stopPointId': tripPickDetails.riderEvent.stoppointid,
        'scheduledTime': tripPickDetails.riderEvent.scheduledtime,
        'type': tripPickDetails.riderEvent.type,
        'riderId': tripPickDetails.riderEvent.riderid,
        'anchor': tripPickDetails.riderEvent.anchor,
        'promisedTime': tripPickDetails.riderEvent.promisedtime,
        'serviceStartTime': tripPickDetails.riderEvent.servicestarttime,
        'serviceEndTime': tripPickDetails.riderEvent.serviceendtime,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'op$1': tripPickDetails.riderEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      }, {
        'id': tripDropDetails.riderEvent.id,
        'stopPointId': tripDropDetails.riderEvent.stoppointid,
        'scheduledTime': tripDropDetails.riderEvent.scheduledtime,
        'type': tripDropDetails.riderEvent.type,
        'riderId': tripDropDetails.riderEvent.riderid,
        'anchor': tripDropDetails.riderEvent.anchor,
        'promisedTime': tripDropDetails.riderEvent.promisedtime,
        'serviceStartTime': tripDropDetails.riderEvent.servicestarttime,
        'serviceEndTime': tripDropDetails.riderEvent.serviceendtime,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'op$1': tripDropDetails.riderEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      }];
    },

    buildAttributeResForTransferTripPayload(pickTransferTripDetails, reconcileDate) {
      const attribute = pickTransferTripDetails.eventAttribute.attribute;

      if (attribute) {
        return attribute.map(ele => {
          return {
            'id': ele.id,
            'type': ele.type.toLowerCase(),
            'createdAt': reconcileDate,
            'updatedAt': reconcileDate,
            'dbVersion': null,
            'op$1': ele.op$1,
            'deleted$1': false,
            'reconcileDate': reconcileDate,
            'isReconciled': true,
            'reconciliationNotes': null
          };
        });
      }

      return [{
        'id': this.generateRandomId(),
        'type': '',
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'dbVersion': null,
        'op$1': pickTransferTripDetails.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': null
      }];
    },

    buildStopPointResForTransferTripPayload(reconcileRouteInfo, reconcileDate, tripPickDetails, tripDropDetails) {
      return [{
        'id': tripPickDetails.stopPointId,
        'tripId': tripPickDetails.tripId,
        'routeIndex': null,
        'addressId': tripPickDetails.address.id,
        'clusterId': null,
        'eta': tripPickDetails.eta,
        'dwellTime': null,
        'clusterIndex': null,
        'scheduleId': tripPickDetails.scheduleId,
        'status': tripPickDetails.status,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'op$1': tripPickDetails.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      }, {
        'id': tripDropDetails.stopPointId,
        'tripId': tripDropDetails.tripId,
        'routeIndex': null,
        'addressId': tripDropDetails.address.id,
        'clusterId': null,
        'eta': tripDropDetails.eta,
        'dwellTime': null,
        'clusterIndex': null,
        'scheduleId': tripDropDetails.scheduleId,
        'status': tripDropDetails.status,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'op$1': tripDropDetails.op$1,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      }];
    },

    buildRouteResFromRVDForTransferTripPayload(rvdResponse, reconcileDate) {
      return {
        'deleted$1': false,
        'endAddressId': rvdResponse.route.endaddressid,
        'startAddressId': rvdResponse.route.startaddressid,
        'version': 1,
        'op$1': rvdResponse.op$1,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'plannedStartTime': rvdResponse.route.plannedstarttime,
        'plannedEndTime': rvdResponse.route.plannedendtime,
        'id': rvdResponse.route.id,
        'providerName': rvdResponse.route.providername,
        'scheduleId': rvdResponse.route.scheduleid,
        'status': rvdResponse.route.status,
        'trackingId': rvdResponse.route.trackingid,
        'actualStartTime': rvdResponse.route.actualstarttime,
        'actualEndTime': rvdResponse.route.actualendtime,
        'alertId': rvdResponse.route.alertid,
        'otpValue': rvdResponse.route.otpvalue,
        'otpStatus': rvdResponse.route.otpstatus,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': rvdResponse.route.reconciliationnotes
      };
    },

    buildTripResForTransferTripPayload(reconcileDate, previousTripResponse, rvdResponse) {
      return {
        'id': previousTripResponse.tripId,
        'routeId': rvdResponse.routeid,
        'status': previousTripResponse.status,
        'externalId': previousTripResponse.externalTripId,
        'scheduleId': previousTripResponse.scheduleId,
        'createdAt': previousTripResponse.createdAt,
        'updatedAt': reconcileDate,
        'op$1': previousTripResponse.op$1,
        'deleted$1': false
      };
    },

    buildTripRiderForTransferTripPayload(pickTransferTripDetails, reconcileDate) {
      return {
        'id': pickTransferTripDetails.externalTripId,
        'tripId': pickTransferTripDetails.tripId,
        'riderId': pickTransferTripDetails.riderDetails.id,
        'status': pickTransferTripDetails.status,
        'scheduleId': pickTransferTripDetails.scheduleId,
        'createdAt': pickTransferTripDetails.createdAt,
        'updatedAt': reconcileDate,
        'dbVersion': null,
        'op$1': pickTransferTripDetails.op$1,
        'deleted$1': pickTransferTripDetails.deleted$1,
        'reconcileDate': reconcileDate,
        'isReconciled': true
      };
    },

    buildRiderResForTransferTripPayload(pickTransferTripDetails, reconcileDate) {
      return {
        'id': pickTransferTripDetails.riderDetails.id,
        'name': pickTransferTripDetails.riderDetails.name,
        'firstName': pickTransferTripDetails.riderDetails.firstname,
        'lastName': pickTransferTripDetails.riderDetails.lastname,
        'middleName': pickTransferTripDetails.riderDetails.middlename,
        'passengerTypeName': pickTransferTripDetails.riderDetails.passengertypename,
        'bookingClientId': pickTransferTripDetails.riderDetails.bookingclientid,
        'tripId': pickTransferTripDetails.tripId,
        'providerName': pickTransferTripDetails.riderDetails.providername,
        'op$1': pickTransferTripDetails.riderDetails.__op,
        'deleted$1': false,
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'reconcileDate': reconcileDate,
        'isReconciled': true
      };
    },

    buildEventAttributesForTransferTripPayload(tripResponse, reconcileDate) {
      return [{
        'id': tripResponse.eventAttribute.id,
        'eventId': tripResponse.eventAttribute.eventid,
        'attributeId': tripResponse.eventAttribute.attributeid,
        'attributeCount': tripResponse.eventAttribute.attributecount,
        'scheduleId': tripResponse.eventAttribute.scheduleid,
        'createdAt': tripResponse.createdAt,
        'updatedAt': reconcileDate,
        'deletedAt': null,
        'deleted$1': false,
        'dbVersion': null,
        'op$1': tripResponse.op$1
      }];
    },

    buildPayloadForTripTransfer() {
      const reconcile = this.get('reconcile');
      const reconcileRouteInfo = reconcile.get('reconcileRouteInfo');
      const previousTripDetails = reconcile.get('selectedTripRow');
      const transferTripDetails = this.get('reconcile.reconcileTripDetails').filter(trip => trip.tripId === previousTripDetails.tripId);
      const dropTransferTripDetails = transferTripDetails.find(trip => trip.type.toLowerCase() === 'drop');
      const pickTransferTripDetails = transferTripDetails.find(trip => trip.type.toLowerCase() === 'pick');
      const rvdResponseForSelectedRoute = this.get('reconcile.reconcileRVDResponse').find(rvd => rvd.routeid.toString() === this.selectedRoute.routeId.toString());
      const reconcileDate = new Date().toISOString();
      return {
        'previousTrip': {
          'events': this.buildArriveAndDepartEventsFromPreviousTripDetails(pickTransferTripDetails, dropTransferTripDetails),
          'trip': this.buildTripResponseFromPreviousTripDetails(previousTripDetails),
          'eventAttributes': this.buildEventAttributeFromPreviousTripDetails(previousTripDetails)
        },
        'transferTrip': {
          'events': this.buildArriveAndDepartEventResForTransferTripPayload(reconcileDate, pickTransferTripDetails, dropTransferTripDetails, rvdResponseForSelectedRoute),
          'address': [this.buildStartAddressResForTransferTripPayload(reconcileDate, pickTransferTripDetails), this.buildEndAddressResForTransferTripPayload(reconcileDate, dropTransferTripDetails)],
          'riderEvents': this.buildRiderEventResForTransferTripPayload(reconcileRouteInfo, pickTransferTripDetails, dropTransferTripDetails, reconcileDate),
          'attributes': this.buildAttributeResForTransferTripPayload(pickTransferTripDetails, reconcileDate),
          'stopPoint': this.buildStopPointResForTransferTripPayload(reconcileRouteInfo, reconcileDate, pickTransferTripDetails, dropTransferTripDetails),
          'route': this.buildRouteResFromRVDForTransferTripPayload(rvdResponseForSelectedRoute, reconcileDate),
          'trip': this.buildTripResForTransferTripPayload(reconcileDate, previousTripDetails, rvdResponseForSelectedRoute),
          'tripRider': this.buildTripRiderForTransferTripPayload(pickTransferTripDetails, reconcileDate),
          'rider': this.buildRiderResForTransferTripPayload(pickTransferTripDetails, reconcileDate),
          'eventAttributes': this.buildEventAttributesForTransferTripPayload(previousTripDetails, reconcileDate)
        }
      };
    },

    //==========start of task section
    saveTask: (0, _emberConcurrency.task)(function* () {
      try {
        const reconcile = this.get('reconcile');
        const payload = this.buildPayloadForTripTransfer();
        return yield reconcile.updateTripTransferToAnotherRoute(payload);
      } catch (e) {
        throw e;
      }
    }),
    actions: {
      onChangeProvider(option) {
        this.setProperties({
          selectedProvider: option,
          'reconcile.reconcileProviderName': option.id,
          selectedSearchMode: null,
          selectedRoute: null,
          reconcileTrips: null,
          'reconcile.reconcileRouteOptions': this.get('routeOptions'),
          'reconcile.selectedProvider': option
        });
      },

      async onChangeRoute(route) {
        this.setProperties({
          selectedRoute: route
        });
        await this.getTripsByRouteId(route);
        const destinationRouteTrips = this.getSortedStopList(this.get('reconcileTrips'));
        this.set('destinationRouteStops', destinationRouteTrips);
      },

      onChangePickTime(value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set('pickArrivalTime', momentTime.toISOString());
        Ember.set(this, 'canUndo', true);
        this.reconcileTripsWithChangedPickAndDropTime(momentTime, 'pick');
      },

      onChangeDropTime(value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set('dropArrivalTime', momentTime.toISOString());
        Ember.set(this, 'canUndo', true);
        this.reconcileTripsWithChangedPickAndDropTime(momentTime, 'drop');
      },

      onChangeRouteTab(option) {
        this.set('selectedRouteContainerType', option);
        this.manageRouteDetails();
      },

      onUndoClick() {
        this.setProperties({
          reconcileTrips: null,
          selectedProvider: null,
          selectedRoute: null,
          destinationRouteStops: [],
          destinationNewRouteStops: [],
          selectedPickOrder: null,
          selectedDropOrder: null
        });
        Ember.set(this, 'canUndo', false);
        this.manageRouteDetails();
      },

      onSaveClick() {
        Ember.set(this, 'errorMessage', null);
        this.showToolTip();
      },

      cancel() {
        if (this.callBack) {
          this.callBack(true);
        }
      }

    }
  });

  _exports.default = _default;
});