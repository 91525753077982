define("adept-iq/pods/components/forgot-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    errors: null,
    success: null,
    email: null,
    isValid: Ember.computed('email', function () {
      const email = this.get('email');
      return Ember.isPresent(email);
    }),
    actions: {
      onSubmit(email, e) {
        e.preventDefault();
        this.get('onSubmit')(email);
      }

    }
  });

  _exports.default = _default;
});