define("adept-iq/serializers/avlm-vehicle-event", ["exports", "adept-iq/serializers/-playbackService"], function (_exports, _playbackService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _playbackService.default.extend({
    normalize(modelClass, resourceHash) {
      resourceHash.id = `${resourceHash.attributes.vehicleId}-${resourceHash.attributes.timestamp}-${resourceHash.id}`;

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeQueryResponse(store, clazz, payload) {
      const resultedPayload = [];

      if (payload.data) {
        payload.data.forEach(item => {
          const data = item.attributes;
          let eventType = null;
          let stopType = null;
          let isValidLatlng = true;

          if (data.lat && data.lng) {
            isValidLatlng = !!(data.lat % 1 !== 0 || data.lng % 1 !== 0);
          }

          if (data.type !== 'otpStatus' && isValidLatlng && data.type !== 'altPath') {
            if (data.type === 'noShow') eventType = 'Stop No Showed';
            if (data.type === 'pullOut') eventType = 'Start Route';
            if (data.type === 'pullIn') eventType = 'End Route';
            if (data.type === 'onBreak') eventType = 'Start Break';
            if (data.type === 'offBreak') eventType = 'End Break';
            if (data.type === 'signOn') eventType = 'Sign On';
            if (data.type === 'signOff') eventType = 'Sign Off';
            if (data.type === 'noShowRequest') eventType = 'No Show Request';
            if (data.type === 'accept') eventType = 'Unperform';
            if (data.type === 'arrive') eventType = 'Arrive';
            if (data.type === 'depart') eventType = 'Depart';
            if (data.type === 'commFailure') eventType = 'Communication Failure';
            if (data.type === 'leaveGarageSignOff') eventType = 'Moved Without Sign On';
            if (data.type === 'powerOn') eventType = 'Power On';
            if (data.type === 'commRestored') eventType = 'Communication Restored';
            if (data.type === 'applicationRestart') eventType = 'App Restarted';
            if (data.type === 'signOnFailure') eventType = 'Sign On Failed';
            if (data.type === 'noShowCancelled') eventType = 'No Show Request Canceled';
            if (data.type === 'postCheckDone') eventType = 'Post Check';
            if (data.type === 'preCheckDone') eventType = 'Pre Check';
            if (data.type === 'dispatchToDriverMessage') eventType = 'Message From Dispatch';
            if (data.type === 'driverToDispatchMessage') eventType = 'Message From Driver';
            if (data.type === 'emergencyOn') eventType = 'Emergency Initiated';
            if (data.type === 'emergencyOff') eventType = 'Emergency Canceled by Driver';
            if (data.type === 'emergencyAck') eventType = 'Emergency Acknowledged';
            if (data.type === 'emergencyClear') eventType = 'Emergency Resolved';
            if (data.type === 'emergencyCancel') eventType = 'Emergency Canceled By Dispatch';
            if (data.type === 'removed') eventType = 'Stop Removed';
            if (data.type === 'added') eventType = 'Stop Added';

            if (data.stop && data.stop.type && (data.stop.type === 'pick' || data.stop.type === 'P')) {
              stopType = 'pick';
              data.type = stopType;
            } else if (data.stop && data.stop.type && (data.stop.type === 'drop' || data.stop.type === 'D')) {
              stopType = 'drop';
              data.type = stopType;
            }

            item.attributes = { ...data,
              lat: Number(data.lat),
              lng: Number(data.lng),
              routeName: data.routename
            };
            if (stopType !== null) item.attributes.stopType = stopType.charAt(0).toUpperCase() + stopType.slice(1);
            if (eventType !== null) item.attributes.eventType = eventType;

            if (data.stop) {
              if (data.stop.address) item.attributes.stopAddress = data.stop.address;
              if (data.stop.stopid) item.attributes.stopId = data.stop.stopid;
            }

            if (data.trip) {
              if (data.trip.tripid) item.attributes.tripId = data.trip.tripid;
              if (data.trip.passengerid) item.attributes.passengerId = data.trip.passengerid;
              if (data.trip.passengerlastname) item.attributes.passengerLName = data.trip.passengerlastname;
              if (data.trip.passengerfirstname) item.attributes.passengerFName = data.trip.passengerfirstname;
            }

            resultedPayload.push(item);
          }
        });
        payload.data = resultedPayload;
      }

      return this._super(store, clazz, payload);
    },

    modelNameFromPayloadKey(key) {
      if (key === 'vehicleEvent') {
        return 'avlm-vehicle-event';
      }

      return this._super(key);
    }

  });

  _exports.default = _default;
});