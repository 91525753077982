define("adept-iq/models/rider-external-eligibility", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    status: attr('string'),
    categories: attr(),
    riderExternal: belongsTo('rider-external'),
    allowedServiceModes: attr(),
    disabilities: attr(),
    medicaidQualified: attr('boolean'),
    fareAmount: attr(),
    pcaRequired: attr('boolean'),
    certAppt: attr(),
    riderEligibilityStart: Ember.computed('categories.0.begin', function () {
      const eligibilityStartDTM = this.get('categories.0.begin');
      return new Date(eligibilityStartDTM);
    }),
    riderEligibilityEnd: Ember.computed('categories.0.end', function () {
      const eligibilityEndDTM = this.get('categories.0.end');
      return new Date(eligibilityEndDTM);
    }),
    riderEligibilityType: Ember.computed('categories.0.type', function () {
      return this.get('categories.0.type');
    })
  });

  _exports.default = _default;
});