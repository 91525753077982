define("adept-iq/pods/components/side-drawer/reconcile-trip-log/component", ["exports", "ember-concurrency", "adept-iq/pods/components/side-drawer/reconcile-trip-log/config", "moment", "adept-iq/pods/components/side-drawer/activity-log/base-activity-log/component", "adept-iq/config/mapped-permIds"], function (_exports, _emberConcurrency, _config, _moment, _component, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TRIP_LOGGING_TYPE = 'trip';

  var _default = _component.default.extend({
    tagName: '',
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    config: _config.default,
    userActivitySelected: null,
    errors: null,
    details: '',
    columns: _config.columns,
    table: null,
    addActivityLog: false,
    onShrinkClick: null,
    currentState: null,
    routeDetails: Ember.computed.readOnly('stackFrame.options.route'),
    tripDetails: Ember.computed.readOnly('stackFrame.options.trip'),
    displayName: Ember.computed.readOnly('stackFrame.options.displayName'),
    reconcileServiceMode: Ember.computed.readOnly('reconcile.reconcileMode'),
    isLoading: Ember.computed.alias('fetchEventsTask.isRunning'),
    title: Ember.computed('tripDetails', function () {
      const tripData = Ember.get(this, 'tripDetails');

      if (!Ember.isEmpty(tripData)) {
        return `Trip ${tripData.tripId} Activity Log`;
      }

      return 'Trip Activity Log';
    }),

    didInsertElement() {
      this._super(...arguments);

      this.loadCurrentState();
      this.configurePermissions();
    },

    configurePermissions() {
      this.set('isAddUserTripLogAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.addTripLogMsgRecon, null));
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('addActivityLog', currentState.addActivityLog);
        this.set('details', currentState.details);
        this.set('userActivitySelected', currentState.userActivitySelected);
      }
    },

    fetchEventsTask: (0, _emberConcurrency.task)(function* () {
      this.set('userActivitySelected', '');
      this.set('details', '');
      const tripId = this.tripDetails.tripId;
      const routeId = this.tripDetails.routeId;

      if (!tripId) {
        return;
      }

      const store = this.store;
      const routeAdapter = store.adapterFor('reconcile-route');

      function fetchFullRecords(activityLogs) {
        return Promise.all(activityLogs.data.map(activityLog => {
          if (activityLog.attributes.driverId) {
            const driver = store.peekRecord('reconcile-driver', activityLog.attributes.driverId); // replace driver.id with driverId

            if (driver) {
              activityLog.attributes.driver = driver;
            }
          }
        })).then(() => {
          return activityLogs;
        });
      }

      this.set('events', []);
      yield routeAdapter.getActivityLogs({
        routeId,
        tripId,
        eventLog: true,
        type: TRIP_LOGGING_TYPE,
        serviceMode: this.reconcileServiceMode
      }).then(async activityLogs => {
        return fetchFullRecords(activityLogs);
      }).then(activityLogs => {
        if (!Ember.isEmpty(activityLogs)) {
          this.processActivityLogs(activityLogs, TRIP_LOGGING_TYPE);
        }
      });
    }).drop(),
    saveActivityLogTask: (0, _emberConcurrency.task)(function* () {
      const newLogEntry = {
        details: this.get('details'),
        activity: this.get('userActivitySelected'),
        userId: this.session.data.authenticated.userId,
        actualTime: (0, _moment.default)().toDate()
      };
      const payload = this.createUserTripActivity(newLogEntry);
      const routeAdapter = this.get('store').adapterFor('reconcile-route');
      yield routeAdapter.createActivityLog(payload, 'event');
    }),

    createUserTripActivity(userInfo) {
      const payload = {
        eventType: 'UE',
        actionType: 'userAddedEntry',
        routeId: this.routeDetails.routeId,
        tripId: this.tripDetails.tripId,
        stopPointId: this.tripDetails.stopId,
        vehicleId: this.routeDetails.vehicleId,
        driverId: this.routeDetails.driverId,
        userId: userInfo.userId,
        createdAt: userInfo.actualTime,
        receivedAt: userInfo.actualTime,
        eventLog: {
          'userAddedEntry': [{
            activity: userInfo.activity,
            loggingType: TRIP_LOGGING_TYPE,
            details: userInfo.details
          }]
        }
      };
      return payload;
    },

    actions: {
      onShrinkClick() {
        this.set('currentState', {
          addActivityLog: Ember.get(this, 'addActivityLog'),
          details: Ember.get(this, 'details'),
          userActivitySelected: Ember.get(this, 'userActivitySelected')
        });
        this.onShrinkClick();
      },

      onSaveClick() {
        if (!this.validateRecords()) return;
        this.set('errors', null);
        const tooltip = this.get('tooltip');
        const notifications = this.get('notifications');
        tooltip.pushConfirmation({
          tip: 'Are you sure you want to add an entry to the activity log?',
          title: this.get('title'),
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('saveActivityLogTask').perform().then(() => {
              notifications.success('New Activity Log Entry Added Successfully');
              tooltip.reset();
              this.toggleProperty('addActivityLog');
              this.fetchEventsTask.perform();
            }).catch(error => {
              let message;

              switch (error.status) {
                case 400:
                  message = 'There was a problem with one of the fields. Please check over the form and try again.';
                  break;

                default:
                  message = 'There was a problem creating activity log, please try again.';
                  break;
              }

              this.get('notifications').warning(message);
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          },
          hasOverlay: true
        });
      }

    }
  });

  _exports.default = _default;
});