define("adept-iq/pods/components/rider-management-widget/rider-funding/funding-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/rider-management-widget/rider-funding/funding-table/config", "adept-iq/utils/sorts", "adept-iq/utils/filters"], function (_exports, _component, _config, _sorts, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    config: _config.default,
    store: Ember.inject.service(),
    rider: Ember.inject.service(),
    recordSetter: Ember.observer('riderId', async function () {
      const filteredData = await this.getRiderFundings('rider-funding');
      this.set('records', filteredData);
    }),

    init() {
      this._super(...arguments);
    },

    async didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    async getRiderFundings(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      let riderFundingAgencies = this.get('riderFundingAgencies');

      if (Ember.isEmpty(riderFundingAgencies)) {
        riderFundingAgencies = await this.get('rider').getConfigFundingAgencies();
        this.set('riderFundingAgencies', riderFundingAgencies);
      }

      let activeData = store.peekAll(modelName) || [];
      activeData = activeData.filter(funding => funding.get('rider.id') === this.get('riderId'));
      const filteredData = activeData.filter(filterFn).sort(compareFn);
      return filteredData;
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const filteredData = await this.getRiderFundings(modelName, params);
      return filteredData;
    },

    actions: {
      onRowClick(currentRow) {
        this._super(currentRow);

        this.get('OnTableRowClick')(currentRow);
        this.get('rider').setLastAccessedRiderFundingId(currentRow.get('id'));
      }

    }
  });

  _exports.default = _default;
});