define("adept-iq/pods/components/iq-widgets/vehicle-events-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/vehicle-events-widget',
    rowComponent: 'iq-widgets/vehicle-events-widget/stops-row',
    modelName: 'vehicle-event',
    defaultSortId: 'tripId',
    defaultSortAsc: false,
    title: 'Vehicle-event',
    columns: [{
      id: 'id',
      index: 18,
      type: 'uuid',
      label: 'ID',
      valuePath: 'id',
      valuePreview: '1',
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 60,
      hidden: true,
      unAvailable: true
    }, {
      id: 'time',
      index: 0,
      type: 'datetime',
      label: 'Time',
      valuePath: 'time',
      editable: true,
      format: 'hh:mm A',
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'route',
      index: 1,
      type: 'text',
      label: 'Route',
      valuePath: 'route',
      valuePreview: '40',
      defaultWidth: 70,
      searchable: true,
      hidden: true
    }, {
      id: 'vehicleId',
      index: 2,
      type: 'text',
      label: 'Vehicle ID',
      valuePath: 'vehicleId',
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'lat',
      index: 3,
      type: 'number',
      label: 'Latitude',
      valuePath: 'lat',
      editable: true,
      searchable: true,
      hidden: true
    }, {
      id: 'lng',
      index: 4,
      type: 'number',
      label: 'Longitude',
      valuePath: 'lng',
      editable: true,
      searchable: true,
      hidden: true
    }, {
      id: 'passengerId',
      index: 5,
      type: 'text',
      label: 'Passenger ID',
      valuePath: 'passengerId',
      searchable: true,
      defaultWidth: 60,
      hidden: true
    }, {
      id: 'passengerFName',
      index: 6,
      type: 'text',
      label: 'Passenger First Name',
      valuePath: 'passengerFName',
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'passengerLName',
      index: 7,
      type: 'text',
      label: 'Passenger Last Name',
      valuePath: 'passengerLName',
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'stopType',
      index: 8,
      type: 'text',
      label: 'Stop Type',
      valuePath: 'stopType',
      valuePreview: 'Pick',
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 50
    }, {
      id: 'stopAddress',
      index: 9,
      type: 'text',
      label: 'Stop Address',
      valuePath: 'stopAddress',
      searchable: true,
      editable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'speed',
      index: 10,
      type: 'number',
      label: 'Vehicle Speed',
      valuePath: 'speed',
      editable: true,
      searchable: true,
      hidden: true
    }]
  };
  _exports.default = _default;
});