define("adept-iq/pods/components/iq-widgets/history-routes-widget/cells/base-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-base-cell', 'data-test-table-base-cell'],
    classNameBindings: ['subscriptionTrip', 'data-test-alertCategory'],
    subscriptionTrip: Ember.computed('row.type', function () {
      const pickupTypesArray = ['pickup', 'pick', 'p'];
      const dropoffTypesArray = ['dropoff', 'drop', 'd'];
      const type = Ember.get(this, 'row.type');

      if (type && (pickupTypesArray.includes(type.toLowerCase()) || dropoffTypesArray.includes(type.toLowerCase()))) {
        return 'highlightable';
      }

      return '';
    })
  });

  _exports.default = _default;
});