define("adept-iq/serializers/rider-contact-info", ["exports", "adept-iq/serializers/-rms-riderManagement"], function (_exports, _rmsRiderManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rmsRiderManagement.default.extend({
    attrs: {
      isSoftDeleted: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      if (!Ember.isNone(json.data.attributes.reason)) {
        const contactReasonTypeName = json.data.attributes.reason;

        if (!Ember.isNone(contactReasonTypeName)) {
          const contactReasonTypeRelationship = {
            data: {
              type: 'contactReasonTypeName',
              id: contactReasonTypeName
            }
          };
          json.data.relationships.contactReasonTypeName = contactReasonTypeRelationship;
        }
      }

      delete json.data.attributes.reason;
      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.relationships.contactReasonTypeName)) {
        resourceHash.attributes.reason = resourceHash.relationships.contactReasonTypeName.data.id;
        delete resourceHash.relationships.contactReasonTypeName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});