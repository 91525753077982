define("adept-iq/serializers/avlm-trip", ["exports", "adept-iq/serializers/-avlm-avlmService"], function (_exports, _avlmAvlmService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const convertStatusReason = {
    'No Show No Answer': 'noShowNoAnswer',
    'No Show Same Day Cancel No Fault': 'noShowSameDayCancelNoFault',
    'No Show Same Day Cancel': 'noShowSameDayCancel',
    'No Show Cancel At Door': 'noShowCancelAtDoor',
    'No Show Not Ready': 'noShowNotReady',
    'No Show No Fault': 'noShowNoFault',
    NSNA: 'noShowNoAnswer',
    NSSDCNF: 'noShowSameDayCancelNoFault',
    NSSDC: 'noShowSameDayCancel',
    NSCD: 'noShowCancelAtDoor',
    NSNR: 'noShowNotReady',
    NSNF: 'noShowNoFault'
  };

  var _default = _avlmAvlmService.default.extend({
    normalize(modelClass, resourceHash) {
      if (resourceHash.attributes) {
        // the status reason arrive from either avlm-route-exec-event as a code or trip topic as the actual id of the no-show-reason
        // evaulate based on either and use the correct id to associate the no-show-reason-code to trip
        if (!Ember.isNone(resourceHash.attributes.statusReason)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          let {
            statusReason
          } = resourceHash.attributes;
          statusReason = convertStatusReason[statusReason] || statusReason;
          relationships.reason = {
            data: {
              type: 'no-show-reason-code',
              id: statusReason
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.statusReason;
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      if (payload.data.attributes) {
        try {
          const relationships = payload.data.relationships ? payload.data.relationships : {};
          payload.data.attributes.otpValue = payload.data.attributes.otp;
          payload.data.attributes.otp = payload.data.attributes.otpStatus;
          payload.data.relationships = relationships;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }

      return payload;
    }

  });

  _exports.default = _default;
});