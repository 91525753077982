define("adept-iq/pods/components/iq-widgets/stops-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Section 1',
      fields: [{
        id: 'id',
        type: 'uuid',
        label: 'ID',
        valuePath: 'id',
        editable: false
      }]
    }, {
      title: 'Section 2',
      fields: [{
        id: 'state',
        type: 'enum',
        label: 'State',
        valuePath: 'state',
        extra: {
          options: ['Not Started', 'Sign On', 'Start', 'In-Progress', 'On-Break', 'End', 'Signoff'],
          allowMultiple: true
        }
      }, {
        id: 'xmin',
        type: 'text',
        label: 'X Min',
        valuePath: 'xmin'
      }]
    }]
  };
  _exports.default = _default;
});