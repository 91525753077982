define("adept-iq/pods/components/rider-management-widget/rider-activity-log/activity-log-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/rider-management-widget/rider-activity-log/activity-log-table/config", "adept-iq/utils/sorts", "adept-iq/config/config-object-template"], function (_exports, _component, _config, _sorts, _configObjectTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const RiderActivityLogTypeName = _configObjectTemplate.default['config-System_Configuration-Activity_Log_Types-rider'].fields.find(field => field.fieldName === 'Name');

  const UPDATE_WAIT_TIME = 30000;
  const EVENT_RIDER_MAP = {
    'genericLogging': true
  };
  const LOGGING_TYPE_MAP = {
    'riderGeneric': true
  };

  var _default = _component.default.extend({
    classNames: ['rms-activity-log'],
    config: _config.default,
    store: Ember.inject.service(),
    updateRecords: true,

    init() {
      this._super(...arguments);

      this.addRiderActivityLog = this.addRiderActivityLog.bind(this);
      Ember.run.later(() => this.updateActivityLogView(), UPDATE_WAIT_TIME);
    },

    willDestroyElement() {
      this.set('updateRecords');

      this._super(...arguments);
    },

    async didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    updateActivityLogView() {
      if (!this.get('updateRecords')) return;
      this.refreshTask.perform();
      Ember.run.later(() => this.updateActivityLogView(), UPDATE_WAIT_TIME);
    },

    filterActionTypeFunction(event) {
      if (!event) return false;
      return EVENT_RIDER_MAP[event.get('actionType')];
    },

    filterActivityTypeFunction(event) {
      if (!event) return false;
      return LOGGING_TYPE_MAP[event.loggingType];
    },

    fetchDataQuery() {
      const params = {
        sorts: []
      };
      const filterActivityTypeFunction = this.get('filterActivityTypeFunction');
      const filterActionTypeFunction = this.get('filterActionTypeFunction');
      const riderActivityLogTypes = this.get('store').peekAll('cs-config-item').filter(csItem => csItem.get('category') === 'config-System_Configuration-Activity_Log_Types-rider');
      const sort = this.buildSortParam();

      if (sort) {
        params.sorts.push(sort);
      }

      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      return this._super(...arguments).then(records => {
        const filteredActionRecords = records.filter(record => {
          return filterActionTypeFunction(record);
        });
        const collectedEventLogs = filteredActionRecords.map(filteredActionRecord => {
          const filteredRecords = filteredActionRecord.get('collectedEventLog');
          return filteredRecords.map(filteredRecord => {
            const activity = riderActivityLogTypes.find(riderActivityLogType => {
              return riderActivityLogType.name === filteredRecord.activity;
            });
            filteredRecord.activity = Ember.isPresent(activity) ? activity.get('value')[RiderActivityLogTypeName.displayName] : filteredRecord.activity;
            filteredRecord.time = filteredActionRecord.get('time');
            filteredRecord.user = filteredActionRecord.get('user');
            filteredRecord.createdAt = filteredActionRecord.get('createdAt');
            return filteredRecord;
          });
        }).flat(Infinity);
        return collectedEventLogs.filter(filterActivityTypeFunction).sort(compareFn);
      });
    },

    addRiderActivityLog() {
      this.set('canShowActivityLogFormModal', true);
    },

    actions: {
      onPlusIconClick() {
        this.get('addRiderActivityLog')();
      },

      onColumnClick(column) {
        this.set('state', {
          sortAsc: column.ascending,
          sortId: column.get('id')
        });
      }

    }
  });

  _exports.default = _default;
});