define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/status-reason-cell", ["exports", "@babel/runtime/helpers/esm/extends", "react", "react-dom", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/base-cell-item", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-check-box", "adept-iq/config/api-urls"], function (_exports, _extends2, _react, _reactDom, _baseCellItem, _cellCheckBox, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RenderInBody = _exports.OTP_LABEL_PROPERTY_NAME = void 0;
  const OTP_LABEL_PROPERTY_NAME = 'otpStatus';
  _exports.OTP_LABEL_PROPERTY_NAME = OTP_LABEL_PROPERTY_NAME;

  class RenderInBody extends _react.default.PureComponent {
    constructor(props) {
      super(props);
      this.el = document.createElement('div');
      this.el.style.display = 'contents'; // The <div> is a necessary container for our content, but it should not affect our layout. Only works in some browsers, but generally doesn't matter since this is at the end anyway. Feel free to delete this line.
    }

    componentDidMount() {
      document.body.appendChild(this.el);
    }

    componentWillUnmount() {
      document.body.removeChild(this.el);
    }

    render() {
      return _reactDom.default.createPortal(this.props.children, this.el);
    }

  }

  _exports.RenderInBody = RenderInBody;

  class StatusReasonCell extends _baseCellItem.default {
    constructor(props) {
      super(props);
      this.state = {
        isHovering: false,
        positionX: 0,
        positionY: 0,
        user: ''
      };
      this.handleMouseHover = this.handleMouseHover.bind(this);
    }

    userList() {
      const localStorageTokenData = JSON.parse(localStorage.getItem('ember_simple_auth-session'));
      const {
        data,
        rowIndex
      } = this.props;
      const currRowData = this.getRowData(data, rowIndex);
      const lock = currRowData.lock;
      const token = localStorageTokenData.authenticated.token;

      if (lock) {
        fetch(`${_apiUrls.API.ssoService.host}/user/${lock.user}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => response.json()).then(userData => this.setState({
          user: userData
        }));
      }
    }

    handleMouseHover() {
      this.setState({
        positionX: window.pageXOffset + event.clientX,
        positionY: window.pageYOffset + event.clientY
      });
      this.userList();
      this.setState(this.toggleHoverState);
    }

    toggleHoverState(state) {
      const clonedState = { ...state
      };
      clonedState.isHovering = !state.isHovering;
      return clonedState;
    }

    render() {
      const {
        data,
        style,
        columnIndex,
        rowIndex,
        onContextMenuClick,
        onTableCellClick,
        columnInfo,
        selectedRowIndex,
        selectedColumnIndex,
        showGearIcon
      } = this.props;
      const {
        isHovering
      } = this.state;
      const {
        reordering
      } = this.props;
      const columnValue = this.getColumnValue(data, columnInfo, rowIndex, columnIndex);
      let classNames = this.getStylesClasses(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue);
      const rowSelClasses = this.getRowStyleClasses(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue);
      classNames = rowSelClasses + '  ' + classNames;
      return _react.default.createElement("span", {
        role: "none",
        className: classNames,
        style: style,
        onContextMenu: e => {
          e.preventDefault();
          onContextMenuClick(e, rowIndex, columnIndex);
        },
        onMouseEnter: this.handleMouseHover,
        onMouseLeave: this.handleMouseHover,
        onClick: () => onTableCellClick(rowIndex, columnIndex)
      }, this.renderCellCheckBox(), showGearIcon && this.renderCellCogButton(), this.renderCellValueItem(), isHovering && !reordering && _react.default.createElement(RenderInBody, null, this.renderToolTipContent(this.props, columnValue)));
    }

    renderToolTipContent(props, columnValue) {
      const {
        positionX,
        positionY
      } = this.state;
      const toolTipLockStyles = {
        width: '150px',
        backgroundColor: '#000',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '6px',
        fontSize: '14px',
        padding: '2px 0',
        position: 'absolute',
        left: `${positionX}px`,
        top: `${positionY}px`,
        zIndex: 1000
      };
      const toolTipStyles = {
        backgroundColor: '#fff',
        color: 'black',
        textAlign: 'left',
        borderRadius: '6px',
        position: 'absolute',
        left: `${positionX}px`,
        top: `${positionY}px`,
        'white-space': 'pre',
        padding: '0 10px 0 10px',
        'max-height': '100px',
        'overflow-y': 'auto',
        zIndex: 1000
      };
      const {
        data,
        rowIndex
      } = props;
      const rowData = data[rowIndex - 1];

      if (rowData.isLocked) {
        if (this.state.user) {
          return _react.default.createElement("div", {
            style: toolTipLockStyles
          }, " Locked by ", this.state.user.firstName);
        }
      }

      if (columnValue) {
        return _react.default.createElement("div", {
          style: toolTipStyles
        }, columnValue);
      }

      return '';
    }

    renderCellCheckBox() {
      const {
        data,
        columnIndex,
        rowIndex,
        checkedRowIds,
        disabledRowIds
      } = this.props;
      const selectedRowData = this.getRowData(data, rowIndex);
      const isDisabled = disabledRowIds.includes(selectedRowData.id);
      const isChecked = checkedRowIds.findIndex(obj => {
        if (selectedRowData.coreModel) {
          return obj.model === selectedRowData.coreModel && obj.id === selectedRowData.id;
        }

        return obj.id === selectedRowData.id;
      }) > -1;

      if (columnIndex === 0) {
        return _react.default.createElement(_cellCheckBox.default, (0, _extends2.default)({}, this.props, {
          isChecked: isChecked,
          isDisabled: isDisabled
        }));
      }
    }

    renderCellCogButton() {
      const {
        columnIndex,
        rowIndex,
        cellRightClickForTablet
      } = this.props;

      if (columnIndex === 1) {
        return _react.default.createElement("button", {
          className: 'btn-cog',
          onClick: e => {
            e.preventDefault();
            cellRightClickForTablet(e, rowIndex, columnIndex);
          }
        }, _react.default.createElement("i", {
          className: 'fa fa-cog ml-1'
        }));
      }
    }

    renderCellValueItem() {
      const {
        data,
        columnIndex,
        rowIndex,
        columnInfo,
        showGearIcon
      } = this.props;
      const actionableColumnIndex = showGearIcon ? 1 : 0; // for checkbox and gear

      const columnValue = this.getColumnValue(data, columnInfo, rowIndex, columnIndex);

      if (columnIndex > actionableColumnIndex) {
        return _react.default.createElement("span", {
          className: 'column-label'
        }, " ", columnValue, " ");
      }
    }

    getRowStyleClasses(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue) {
      const {
        rowStyleHandler
      } = this.props;

      if (rowStyleHandler) {
        const currRowData = this.getRowData(data, rowIndex) || {};
        return rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currRowData) || '';
      }

      return '';
    }

  }

  _exports.default = StatusReasonCell;
});