define("adept-iq/workers/socket", ["adept-iq/topic-transforms/avlm-alert", "adept-iq/topic-transforms/avlm-message", "adept-iq/topic-transforms/avlm-route-exec-event", "adept-iq/topic-transforms/driver", "adept-iq/topic-transforms/eta-route", "adept-iq/topic-transforms/lock", "adept-iq/topic-transforms/polyline", "adept-iq/topic-transforms/rider", "adept-iq/topic-transforms/subscription", "adept-iq/topic-transforms/trip", "adept-iq/topic-transforms/vehicle", "adept-iq/topic-transforms/stop-point", "adept-iq/topic-transforms/user", "adept-iq/topic-transforms/avlm-rider-event", "adept-iq/topic-transforms/avlm-route", "adept-iq/topic-transforms/avlm-trip", "adept-iq/topic-transforms/avlm-stop-point", "adept-iq/topic-transforms/avlm-trip-rider", "adept-iq/topic-transforms/avlm-route-break", "adept-iq/topic-transforms/avlm-event"], function (_avlmAlert, _avlmMessage, _avlmRouteExecEvent, _driver, _etaRoute, _lock, _polyline, _rider, _subscription, _trip, _vehicle, _stopPoint, _user, _avlmRiderEvent, _avlmRoute, _avlmTrip, _avlmStopPoint, _avlmTripRider, _avlmRouteBreak, _avlmEvent) {
  "use strict";

  /* global postMessage */

  /* CORE + Required AVLM Topic Transforms */

  /* AVLM Topic Transforms */
  // worker will throw an error if it encounters any model type that isn't
  // registered here; set to `null` if no transform required
  // eslint-disable-next-line no-unused-vars
  const TRANSFORMS = {
    'address': null,
    'avl': null,
    'avlm-address': null,
    'avlm-alert': _avlmAlert.default,
    'avlm-canned-message-template': null,
    'avlm-cluster': null,
    'avlm-driver': null,
    'avlm-driver-state-event': null,
    'avlm-event': _avlmEvent.default,
    'avlm-message': _avlmMessage.default,
    'avlm-rider': null,
    'avlm-rider-event': _avlmRiderEvent.default,
    'avlm-route': _avlmRoute.default,
    'avlm-route-exec-event': _avlmRouteExecEvent.default,
    'avlm-route-update': null,
    'avlm-trip': _avlmTrip.default,
    'avlm-trip-payment': null,
    'avlm-trip-rider': _avlmTripRider.default,
    'avlm-trip-rider-attribute': null,
    'avlm-schedule': null,
    'avlm-route-break': _avlmRouteBreak.default,
    'avlm-route-vehicle-driver': null,
    'avlm-stop-point': _avlmStopPoint.default,
    'avlm-vehicle': null,
    'avlm-vehicle-info': null,
    'avlm-zone': null,
    'booking': null,
    'cluster': null,
    'dispatch-route': null,
    'dispatch-schedule': null,
    'driver': _driver.default,
    'etanav-route': _etaRoute.default,
    'stop-point': _stopPoint.default,
    'leg': null,
    'location': null,
    'lock': _lock.default,
    'no-show': null,
    'place': null,
    'polyline': _polyline.default,
    'rider': _rider.default,
    'route': null,
    'route-vehicle-driver': null,
    'route-break': null,
    'segment': null,
    'segment-stop': null,
    'schedule': null,
    'subscription': _subscription.default,
    'trip': _trip.default,
    'trip-stop': null,
    'vehicle': _vehicle.default,
    'vehicle-breakdown': null,
    'user': _user.default,
    'zone': null
  };
  const FLUSH_INTERVAL = 500;
  const PING_TIMEOUT = 15000;
  const PONG_TIMEOUT = 10000;
  const HEARTBEAT_MSG = 'HEARTBEAT';
  let mainQueue = {};
  let avlQueue = {};
  let lockQueue = {};
  const sockets = {}; // eslint-disable-next-line no-unused-vars

  let ENV, pingTimeoutId, pongTimeoutId;
  const FILTER_TYPE = {
    ADD_GLOBAL_FILTER: 'ADD_GLOBAL_FILTER',
    ADD_WIDGET_FILTER: 'ADD_WIDGET_FILTER'
  };

  function setENV(env) {
    ENV = env;
  }

  function handlePayloadData(data) {
    let payloads;

    try {
      payloads = JSON.parse(data);
    } catch (err) {
      throw new Error(`bad topic json data: ${data}`);
    }

    if (!payloads) return [];
    return payloads;
  }

  function sendGlobalFilter(data) {
    const {
      socketId,
      startDate,
      endDate,
      selectedProviders,
      startDateUTC,
      endDateUTC,
      scheduleId
    } = data;
    const socket = sockets[socketId];
    sockets[socketId] = socket;
    const request = {
      action: FILTER_TYPE.ADD_GLOBAL_FILTER,
      startDate,
      endDate,
      selectedProviders,
      startDateUTC,
      endDateUTC,
      scheduleId
    };
    sockets[socketId].send(JSON.stringify(request));
  }

  function sendWidgetFilter(data) {
    const {
      socketId,
      widgetInstanceId,
      modelName,
      filter,
      offset,
      limit,
      sort
    } = data;
    const socket = sockets[socketId];
    sockets[socketId] = socket;
    const request = {
      action: FILTER_TYPE.ADD_WIDGET_FILTER,
      widgetInstanceId,
      widgetModelName: modelName,
      dataFilterQuery: filter,
      pageOffset: offset,
      pageLimit: limit,
      sortField: sort
    };
    sockets[socketId].send(JSON.stringify(request));
  }

  function heartReset() {
    clearTimeout(pingTimeoutId);
    clearTimeout(pongTimeoutId);
  }

  function heartStart(socketId) {
    const socket = sockets[socketId];
    pingTimeoutId = setTimeout(() => {
      const request = {
        action: HEARTBEAT_MSG
      };
      socket.send(JSON.stringify(request));
      pongTimeoutId = setTimeout(() => {
        socket.close();
      }, PONG_TIMEOUT);
    }, PING_TIMEOUT);
  }

  function heartCheck(socketId) {
    heartReset();
    heartStart(socketId);
  }

  function connectSocket(socketId, url, token
  /*startDate, endDate*/
  ) {
    if (sockets[socketId]) {
      throw new Error(`socketId '${socketId}' already used`);
    }

    const socket = new WebSocket(url, [token]);
    sockets[socketId] = socket;

    socket.onopen = () => {
      if (!sockets[socketId]) {
        // this socket has been removed
        socket.close();
        return;
      }

      heartCheck(socketId);
      postMessage({
        action: 'open',
        socketId
      });
    };

    socket.onmessage = e => {
      if (!sockets[socketId]) return; // parse & apply topic transforms

      const payloads = handlePayloadData(e.data); // iqux service websocket TODO

      if (payloads.header) {
        postMessage({
          action: 'data',
          payloads,
          socketId
        }); // Any message comes in, we reset heartBeat check

        heartCheck(socketId);
        return;
      }

      payloads.forEach(payload => {
        if (!payload.message) {
          mainQueue[socketId] = mainQueue[socketId] || [];
          mainQueue[socketId].push(payload);
          return;
        }
        /* eslint-disable indent */


        if (payload.message && payload.message.data && payload.message.data.type) {
          switch (payload.message.data.type) {
            case 'avl':
              {
                const {
                  id
                } = payload.message.data.relationships.vehicle.data;
                avlQueue[socketId] = avlQueue[socketId] || {};
                avlQueue[socketId][id] = payload;
                break;
              }

            case 'lock':
              {
                const {
                  id
                } = payload.message.data.relationships.dispatchRoute.data;
                lockQueue[socketId] = lockQueue[socketId] || {};
                lockQueue[socketId][id] = payload;
                break;
              }

            default:
              {
                mainQueue[socketId] = mainQueue[socketId] || [];
                mainQueue[socketId].push(payload);
                break;
              }
          }
        }
        /* eslint-enable indent */

      });
    };

    socket.onerror = e => {
      if (!sockets[socketId]) return;
      postMessage({
        action: 'error',
        code: e.code,
        socketId
      });
    };

    socket.onclose = e => {
      // clear out handlers
      socket.onopen = null;
      socket.onmessage = null;
      socket.onerror = null;
      socket.onclose = null;
      postMessage({
        action: 'close',
        code: e.code,
        socketId
      });
    };
  }

  function disconnectSocket(socketId) {
    const socket = sockets[socketId]; // socket was probably already terminated

    if (!socket) return;
    heartReset();
    socket.close();
    delete sockets[socketId];
  }

  self.addEventListener('message', function (e) {
    const {
      action,
      socketId,
      startDate,
      endDate
    } = e.data;

    switch (action) {
      case 'connect':
        return connectSocket(socketId, e.data.url, e.data.token, startDate, endDate);

      case 'disconnect':
        return disconnectSocket(socketId);

      case 'setEnv':
        return setENV(e.data.ENV);

      case 'sendGlobalFilter':
        return sendGlobalFilter(e.data);

      case 'sendWidgetFilter':
        return sendWidgetFilter(e.data);

      default:
        break;
    }
  }, false);
  setInterval(() => {
    Object.entries(mainQueue).forEach(_ref => {
      let [socketId, payloads] = _ref;
      postMessage({
        action: 'data',
        socketId,
        payloads
      });
    });
    mainQueue = {};
  }, FLUSH_INTERVAL); // stagger avl flushes between main flushes

  setTimeout(() => {
    setInterval(() => {
      Object.entries(avlQueue).forEach(_ref2 => {
        let [socketId, queueHash] = _ref2;
        const payloads = Object.values(queueHash);
        postMessage({
          action: 'data',
          socketId,
          payloads
        });
      });
      avlQueue = {};
    }, FLUSH_INTERVAL * 2);
  }, FLUSH_INTERVAL / 2); // stagger lock flushes between the other main flushes

  setTimeout(() => {
    setInterval(() => {
      Object.entries(lockQueue).forEach(_ref3 => {
        let [socketId, queueHash] = _ref3;
        const payloads = Object.values(queueHash);
        postMessage({
          action: 'data',
          socketId,
          payloads
        });
      });
      lockQueue = {};
    }, FLUSH_INTERVAL * 2);
  }, 3 * FLUSH_INTERVAL / 2);
});