define("adept-iq/adapters/playback-activity-log", ["exports", "adept-iq/adapters/-playbackService"], function (_exports, _playbackService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _playbackService.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    getActivityLogs(query) {
      const session = this.get('session');
      const host = this.get('host');
      const url = `${host}/trip-route-activity-log`;
      return this.get('ajax').post(url, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: query
      });
    }

  });

  _exports.default = _default;
});