define("adept-iq/pods/components/workspace-navbar-reconcile/stub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    routes: [{
      id: 746263,
      providerName: 'MAG'
    }, {
      id: 453454,
      providerName: 'MAG'
    }, {
      id: 232323,
      providerName: 'MAG'
    }, {
      id: 342342,
      providerName: 'MVC'
    }, {
      id: 746263,
      providerName: 'MVC'
    }, {
      id: 231231,
      providerName: 'EPC'
    }, {
      id: 897873,
      providerName: 'EPC'
    }, {
      id: 343534,
      providerName: 'PTC'
    }, {
      id: 575757,
      providerName: 'PTC'
    }, {
      id: 986988,
      providerName: 'PTC'
    }],
    providers: [{
      id: 1,
      name: 'MAG'
    }, {
      id: 2,
      name: 'MVC'
    }, {
      id: 3,
      name: 'MVP'
    }, {
      id: 4,
      name: 'EPC'
    }, {
      id: 5,
      name: 'PTC'
    }],
    serviceModes: ['Taxi', 'Paratransit / Broker'],
    searchOptions: ['Client', 'Driver', 'Vehicle', 'Address', 'Trip']
  };
  _exports.default = _default;
});