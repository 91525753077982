define("adept-iq/topic-transforms/trip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload
  /*, ENV*/
  ) {
    const transformedPayloads = [];
    if (!payload.data || !payload.data.attributes) return transformedPayloads;

    if (payload.data.attributes.reason) {
      payload.data.attributes.statusReason = payload.data.attributes.reason;
    }

    transformedPayloads.push(payload);
    return transformedPayloads;
  }
});