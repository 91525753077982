define("adept-iq/scenarios/avlm-service", ["exports", "ember-data-factory-guy"], function (_exports, _emberDataFactoryGuy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Just for fun, set the log level ( to 1 ) and see all FactoryGuy response info in console
  _emberDataFactoryGuy.Scenario.settings({
    logLevel: 0 // 1 is the max for now, default is 0

  });

  const amountDrivers = 5;

  class _default extends _emberDataFactoryGuy.Scenario {
    run() {
      // creating avlm-drivers
      const drivers = (0, _emberDataFactoryGuy.makeList)('avlm-driver', amountDrivers).toArray();
      const schedule = (0, _emberDataFactoryGuy.make)('avlm-schedule'); // creating avlm-vehicles

      const providers = this.store.peekAll('provider').toArray();
      drivers.forEach(driver => {
        const random = Math.floor(Math.random() * (drivers.length - 1));
        const randomProvider = providers[random];
        (0, _emberDataFactoryGuy.make)('avlm-vehicle', {
          driver,
          provider: randomProvider
        });
      });
      const vehicles = this.store.peekAll('avlm-vehicle').toArray(); // create avlm-routes

      vehicles.forEach((vehicle, index) => {
        const startAddress = (0, _emberDataFactoryGuy.make)('avlm-address');
        const driver = drivers[index];
        const endAddress = (0, _emberDataFactoryGuy.make)('avlm-address');
        const avlmRoute = (0, _emberDataFactoryGuy.make)('avlm-route', 'pullout', {
          vehicle,
          schedule,
          startAddress,
          endAddress
        });
        const avlmCluster = (0, _emberDataFactoryGuy.make)('avlm-cluster', {
          route: avlmRoute,
          schedule
        });
        (0, _emberDataFactoryGuy.make)('avlm-route-vehicle-driver', {
          route: avlmRoute,
          driver,
          vehicle
        });
        const etaNavRoute = (0, _emberDataFactoryGuy.make)('etanav-route', {
          avlmRoute
        });
        (0, _emberDataFactoryGuy.make)('polyline', {
          avlmRoute
        });
        const trips = (0, _emberDataFactoryGuy.makeList)('avlm-trip', 3, {
          avlmRoute
        });
        const routeBreaks = [(0, _emberDataFactoryGuy.make)('avlm-route-break', {
          avlmRoute
        })];
        (0, _emberDataFactoryGuy.make)('avlm-canned-message', {
          avlmVehicle: vehicle,
          avlmRoute: avlmRoute,
          avlmDriver: vehicle.get('latestDriver.content')
        });

        if (index % 3 === 0) {
          (0, _emberDataFactoryGuy.make)('avlm-emergency-message', {
            avlmVehicle: vehicle,
            avlmRoute: avlmRoute,
            avlmDriver: vehicle.get('latestDriver.content')
          });
        }

        let routeIndex = 0;
        trips.forEach(avlmTrip => {
          const pickAddress = (0, _emberDataFactoryGuy.make)('avlm-address');
          const dropAddress = (0, _emberDataFactoryGuy.make)('avlm-address');
          const avlmRider = (0, _emberDataFactoryGuy.make)('avlm-rider');
          (0, _emberDataFactoryGuy.make)('avlm-trip-rider', {
            avlmRider,
            avlmTrip
          });
          routeIndex++;
          let avlmStopPoint = (0, _emberDataFactoryGuy.make)('avlm-stop-point', 'pick', {
            avlmTrip,
            avlmCluster,
            avlmAddress: pickAddress,
            routeIndex
          });
          (0, _emberDataFactoryGuy.make)('etanav-stop-point', {
            avlmStopPoint,
            etaNavRoute
          });
          (0, _emberDataFactoryGuy.make)('polyline', {
            avlmStopPoint
          });
          (0, _emberDataFactoryGuy.make)('avlm-rider-event', {
            avlmRider,
            avlmStopPoint,
            avlmSchedule: schedule
          });
          avlmStopPoint = (0, _emberDataFactoryGuy.make)('avlm-stop-point', 'drop', {
            avlmTrip,
            avlmCluster,
            avlmAddress: dropAddress,
            routeIndex
          });
          (0, _emberDataFactoryGuy.make)('etanav-stop-point', {
            avlmStopPoint,
            etaNavRoute
          });
          (0, _emberDataFactoryGuy.make)('polyline', {
            avlmStopPoint
          });
          (0, _emberDataFactoryGuy.make)('avlm-rider-event', {
            avlmRider,
            avlmStopPoint,
            avlmSchedule: schedule
          });
        });
        routeBreaks.forEach(routeBreak => {
          routeIndex++;
          (0, _emberDataFactoryGuy.make)('avlm-stop-point', 'Break', {
            routeIndex,
            routeBreak
          });
        });
      });
    }

  }

  _exports.default = _default;
});