define("adept-iq/pods/components/rider-management-widget/rider-contact-information/new-address-dialog/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addressConfig = void 0;
  const addressConfig = {
    valuePath: 'place',
    modelName: 'place',
    extra: {
      aliasPath: 'address.content.alias',
      notesPath: 'address.content.notes',
      displayPath: 'address.content.address',
      premisePath: 'address.content.premise',
      streetNumberPath: 'address.content.streetNumber',
      streetNamePath: 'address.content.streetName',
      localityPath: 'address.content.locality',
      regionPath: 'address.content.region',
      subRegionPath: 'address.content.county',
      postalCodePath: 'address.content.postalCode',
      countryPath: 'address.content.country',
      freeformAddressPath: 'address.content.freeformAddress',
      tomtomFormattedAddressPath: 'address.content.tomtomFormattedAddress',
      useOptionRecord: true,
      streetAddressPath: 'address.content.streetAddress',
      locationsPath: 'address.locations.content.firstObject.latlng'
    }
  };
  _exports.addressConfig = addressConfig;
});