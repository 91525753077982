define("adept-iq/pods/components/side-drawer/system-config/route-master/component", ["exports", "ember-changeset-cp-validations", "ember-changeset", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/side-drawer/system-config/route-master/vehicle-search-config", "adept-iq/pods/components/side-drawer/system-config/route-master/driver-search-config", "lodash", "adept-iq/config/environment", "adept-iq/models/route-template", "adept-iq/models/zone", "moment"], function (_exports, _emberChangesetCpValidations, _emberChangeset, _emberConcurrency, _unwrapProxy, _vehicleSearchConfig, _driverSearchConfig, _lodash, _environment, _routeTemplate, _zone, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_DATE = (0, _moment.default)().add(1000, 'years');
  const MAX_TRIP_LIMIT = 9;
  const MAX_DISTANCE_LIMIT = 99;
  const MAX_ROUTE_VALUE = 2147483647;
  const daysOfService = {
    '0': 'monday',
    '1': 'tuesday',
    '2': 'wednesday',
    '3': 'thursday',
    '4': 'friday',
    '5': 'saturday',
    '6': 'sunday',
    '7': 'holiday'
  };
  const daysOfWeek = [{
    day: 'monday',
    display: 'Mon',
    disabled: false
  }, {
    day: 'tuesday',
    display: 'Tues',
    disabled: false
  }, {
    day: 'wednesday',
    display: 'Wed',
    disabled: false
  }, {
    day: 'thursday',
    display: 'Thurs',
    disabled: false
  }, {
    day: 'friday',
    display: 'Fri',
    disabled: false
  }, {
    day: 'saturday',
    display: 'Sat',
    disabled: false
  }, {
    day: 'sunday',
    display: 'Sun',
    disabled: false
  }, {
    day: 'holiday',
    display: 'Holiday',
    disabled: false
  }];
  const extraTripLimitOptions = {
    min: 1,
    max: 1
  };
  const extraDistanceLimitOptions = {
    min: 1,
    max: 2
  };

  var _default = Ember.Component.extend({
    classNames: ['route-master-view'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    daysOfService,
    daysOfWeek,
    tableRef: null,
    zoneTableRef: null,
    isDriverBreakModalEnabled: false,
    driverBreakAvailability: null,
    disableEditing: true,
    disableBtns: null,
    showValidations: false,
    breakTypes: null,
    zones: null,
    options: null,
    selectedRouteTemplateAvailabilities: null,
    selectedAvailability: null,
    extraDistanceLimitOptions,
    extraTripLimitOptions,
    noneDriverModel: null,
    noneRecord: null,
    dateTimeFormat: _environment.default.dateTimeFormat,
    routeTemplate: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    isEdited: false,
    isNew: false,
    isSavePerformed: false,
    selectedRecord: Ember.computed.readOnly('selectedRow.record'),
    error: Ember.computed.readOnly('routeTemplateChangeSet.error'),
    previousVehicleMap: null,
    hasZoneSelected: Ember.computed.readOnly('zoneTableRef.hasZoneSelected'),
    shouldDisplayValidations: Ember.computed.or('showValidations').readOnly(),
    hasRouteTemplateChanged: Ember.computed('routeTemplateChangeSet.{isDirty,changes}', 'routeTemplateAvailabilities.@each{isDirty,shiftBreaks.length}', 'selectedAvailability.{routeLength,DOWSelected}', function () {
      const routeTemplateChangeSet = this.get('routeTemplateChangeSet');
      const oldAvailabilitiesLength = routeTemplateChangeSet.get('availabilities').length;
      const avalabilityLengthChanged = oldAvailabilitiesLength !== this.get('routeTemplateAvailabilities').length;
      const routeTemplateChanged = routeTemplateChangeSet.get('isDirty') || routeTemplateChangeSet.get('changes').length;
      const availabilitiesChanged = this.get('routeTemplateAvailabilities').some(availability => {
        return availability.get('isDirty') || availability.get('changes').length || availability.get('isNew');
      });
      return routeTemplateChanged || availabilitiesChanged || avalabilityLengthChanged;
    }),
    isShiftLengthZero: Ember.computed('routeTemplateAvailabilities.[]', function () {
      const zeroShiftLengthAvailabilities = this.get('routeTemplateAvailabilities').filter(availability => {
        if (!availability.get('routeLength')) return 1;
        const avail = availability.get('routeLength').replace(/_/img, '0');
        const shiftLengthDuration = avail.split(':');
        const hours = shiftLengthDuration[0];
        const minutes = shiftLengthDuration.length > 1 ? shiftLengthDuration[1] : 0;
        return parseInt(minutes, 10) === 0 && parseInt(hours, 10) === 0 || avail === '';
      });
      const isShiftLengthZero = zeroShiftLengthAvailabilities.length ? 0 : 1;

      if (!this.get('routeTemplateAvailabilities').length) {
        return false;
      }

      return isShiftLengthZero;
    }),
    disableUndobtn: Ember.computed('routeTemplateChangeSet.{isDirty,changes}', 'routeTemplateAvailabilities.@each{isDirty,shiftBreaks.length}', 'selectedAvailability.{routeLength,DOWSelected}', 'isEdited', 'disableEditing', 'hasRouteTemplateChanged', function () {
      const routeTemplateChangeSet = this.get('routeTemplateChangeSet');
      const disableEditing = this.get('disableEditing');
      const hasRouteTemplateChanged = this.get('hasRouteTemplateChanged');
      const isEdited = this.get('isEdited');
      const isNew = this.get('isNew');
      const isSavePerformed = this.get('isSavePerformed');
      const currentAvailabilitiesLength = this.get('routeTemplateAvailabilities').length;
      const routeTemplateChanged = routeTemplateChangeSet.get('isDirty') || routeTemplateChangeSet.get('changes').length;

      if (isSavePerformed) {
        this.hasChanges(!isSavePerformed);
        return isSavePerformed;
      }

      if (!currentAvailabilitiesLength && !isNew) {
        this.hasChanges(!currentAvailabilitiesLength);
        return currentAvailabilitiesLength;
      }

      if ((isEdited || isNew) && routeTemplateChanged) {
        this.hasChanges(true);
        return false;
      }

      const returnValue = disableEditing || !hasRouteTemplateChanged || isNew;
      this.hasChanges(!returnValue);
      return returnValue;
    }),
    disableSavebtn: Ember.computed('routeTemplateChangeSet.{isDirty,changes}', 'routeTemplateAvailabilities.@each{isDirty,shiftBreaks.length}', 'selectedAvailability.{routeLength,DOWSelected}', 'isEdited', 'disableEditing', 'hasRouteTemplateChanged', function () {
      const disableEditing = this.get('disableEditing');
      const hasRouteTemplateChanged = this.get('hasRouteTemplateChanged');
      const isEdited = this.get('isEdited');
      const currentAvailabilitiesLength = this.get('routeTemplateAvailabilities').length;
      const isSavePerformed = this.get('isSavePerformed');
      const zeroShiftLengthAvailabilities = this.get('routeTemplateAvailabilities').filter(availability => {
        if (!availability.get('routeLength')) return 1;
        const avail = availability.get('routeLength').replace(/_/img, '0');
        const shiftLengthDuration = avail.split(':');
        const hours = shiftLengthDuration[0];
        const minutes = shiftLengthDuration.length > 1 ? shiftLengthDuration[1] : 0;
        return parseInt(minutes, 10) === 0 && parseInt(hours, 10) === 0 || avail === '';
      });
      const isShiftLengthZero = zeroShiftLengthAvailabilities.length ? 0 : 1;
      if (isSavePerformed) return isSavePerformed;
      if (!currentAvailabilitiesLength) return !currentAvailabilitiesLength;

      if (isEdited) {
        if (!isShiftLengthZero) return !isShiftLengthZero;
        return !isEdited;
      }

      return disableEditing || !hasRouteTemplateChanged;
    }),
    routeTemplateChangeSet: Ember.computed('routeTemplate', 'selectedRow.selected', 'isSavePerformed', function () {
      const routeTemplate = (0, _unwrapProxy.unwrapProxy)(this.get('routeTemplate'));
      return this.setDefaultProperties(routeTemplate);
    }),
    routeTemplateAvailabilities: Ember.computed('sortedAvailabilities.@each.{isDeleted,shiftBreaks.length}', function () {
      const sortedAvailabilities = this.get('sortedAvailabilities') || [];
      return sortedAvailabilities.filter(avail => !avail.get('isDeleted')).sortBy('DOWSelected.length').reverse();
    }),

    hasChanges(value) {
      this.set('systemConfig.hasChanges', value);
    },

    async init() {
      this._super(...arguments);

      const store = this.get('store');
      const breakTypes = store.peekAll('break-type');
      const zones = store.peekAll('zone');
      const noneDriverModel = this.get('noneDriverModel');
      this.set('options', {
        max: 10
      });
      this.set('breakTypes', breakTypes);
      this.set('availableZones', zones);
      this.set('affinityTypes', [_zone.AFFINITY_PREFERRED, _zone.AFFINITY_NORMAL, _zone.AFFINITY_LAST_RESORT, _zone.AFFINITY_NO_SERVICE]);
      this.hasChanges = this.get('hasChanges').bind(this);

      if (Ember.isNone(noneDriverModel)) {
        const newRecord = this.get('store').createRecord('driver', {
          driverId: 'None'
        });
        this.set('noneDriverModel', newRecord);
      }

      this.refreshModelsTask.perform();
    },

    didInsertElement() {
      this._super(...arguments);

      const routeMasterSaveFunction = this.get('onSaveRouteMaster').bind(this);
      const routeMasterEditFunction = this.get('enableEditRouteMaster').bind(this);
      const onSelectRow = this.get('onSelectRow').bind(this);
      this.set('routeMasterEditFunction', routeMasterEditFunction);
      this.set('routeMasterSaveFunction', routeMasterSaveFunction);
      this.set('onSelectRow', onSelectRow);
    },

    cleanupNoneDriverModel() {
      const noneDriverModel = this.get('noneDriverModel');

      if (Ember.isPresent(noneDriverModel)) {
        noneDriverModel.destroyRecord();
        this.set('noneDriverModel');
      }
    },

    async willDestroy() {
      const store = this.get('store');
      const routeTemplates = store.peekAll('route-template');
      routeTemplates.forEach(routeTemplate => {
        if (routeTemplate.get('isNew')) {
          routeTemplate.rollbackAttributes(); // delete any created records
        } //to remove the newly created or unsaved  route-template-availabilities relationship form route-template


        const filteredSortedAvailabilities = routeTemplate.get('availabilities').filter(availability => !availability.get('isNew'));
        routeTemplate.set('availabilities', filteredSortedAvailabilities);
      });
      this.cleanupNoneDriverModel();

      this._super(...arguments);
    },

    willDestroyElement() {
      const doUndoRouteMaster = this.get('doUndoRouteMaster').bind(this);
      doUndoRouteMaster();

      this._super(...arguments);
    },

    onSelectRow() {
      const disableBtns = {
        new: false,
        edit: false,
        delete: true // temporary disable deletion option

      };
      Ember.run.scheduleOnce('afterRender', this, () => this.set('disableBtns', disableBtns));
      this.set('disableEditing', true);
      this.set('isEdited', false);
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      let pendingRecord = this.get('pendingRecord');
      this.set('selectedAffinityType', null);

      if (record) {
        pendingRecord = record;
      } else if (!pendingRecord) {
        pendingRecord = store.createRecord('route-template');
        this.set('pendingRecord', pendingRecord);
        this.setRouteTemplateAffinity(pendingRecord);
      }

      if (pendingRecord.get('isNew')) {
        pendingRecord.setProperties({
          name: '',
          tripLimit: '',
          distanceLimit: '',
          shiftBreaks: [],
          vehicle: null
        });
      }

      if (record) {
        this.setPerviousVehicleMap(record.get('availabilities'));
      }

      const zoneTableRef = this.get('zoneTableRef');
      const {
        validateFn,
        validationMap
      } = (0, _emberChangesetCpValidations.buildChangeset)(pendingRecord);

      if (zoneTableRef) {
        Ember.run.scheduleOnce('afterRender', zoneTableRef, 'refreshData');
        Ember.run.later(() => zoneTableRef.onUncheckAll(), 1000);
      }

      this.set('showValidations', false);
      this.hasChanges(false);

      if (pendingRecord) {
        const availabilities = pendingRecord.get('availabilities') || [];
        this.configureRouteTemplateAvailabilities(availabilities);
      }

      return new _emberChangeset.default(pendingRecord, validateFn, validationMap, {
        skipValidate: true
      });
    },

    setRouteTemplateAffinity(routeTemplate) {
      const zones = this.get('availableZones');
      const affinities = Array(zones.length).fill(_zone.AFFINITY_NORMAL.val);
      routeTemplate.set('affinities', affinities);
    },

    setPerviousVehicleMap(availabilities) {
      const previousVehicleMap = {};
      availabilities.forEach((availability, availIndex) => {
        const vehicleKey = availIndex + availability.get('id');
        previousVehicleMap[vehicleKey] = availability.get('vehicle');
      });
      this.set('previousVehicleMap', previousVehicleMap);
    },

    configureOptimizationFlags(checked, bitFlag) {
      const routeTemplate = this.get('routeTemplateChangeSet');
      const optimizationFlags = routeTemplate.get('optimizationFlags');

      if (checked) {
        routeTemplate.set('optimizationFlags', optimizationFlags | bitFlag); // turn bit on
      } else {
        routeTemplate.set('optimizationFlags', optimizationFlags & ~bitFlag); // turn bit off
      }
    },

    configureRouteTemplateAvailabilities(availabilities) {
      const dayOptions = this.get('daysOfWeek');
      dayOptions.forEach(option => {
        Ember.set(option, 'disabled', false);
      });
      availabilities.forEach(availability => {
        const DOWSelected = [];
        dayOptions.forEach(option => {
          if (availability.get(option.day)) {
            Ember.set(option, 'disabled', true);
            DOWSelected.pushObject(option);
          }
        });
        availability.setProperties({
          DOWSelected
        });
      });
      const changesets = availabilities.map(availability => {
        const {
          validateFn,
          validationMap
        } = (0, _emberChangesetCpValidations.buildChangeset)(availability);
        return new _emberChangeset.default(availability, validateFn, validationMap);
      });
      this.set('sortedAvailabilities', changesets);
      const deSelectedDaysOfOptions = dayOptions.filter(option => option.disabled === false); // no available options remain

      if (!deSelectedDaysOfOptions.length) {
        this.set('disableAddAvailabilityBtn', true);
      } else {
        this.set('disableAddAvailabilityBtn', false);
      }
    },

    deleteRecords(records) {
      return records.reduce((acu, record) => {
        return acu.then(() => {
          if (record.get('isNew')) return record.rollbackAttributes();
          return record.destroyRecord();
        });
      }, Promise.resolve());
    },

    async savePlaceRecord(place) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');

      if (place) {
        const zoneType = (0, _unwrapProxy.unwrapProxy)(this.get('store').peekAll('zone-type').get('firstObject'));
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));

        if (address) {
          // TODO: We need to deal with how zone name is populated
          if (Ember.isNone(address.get('zoneName.id'))) {
            address.set('zoneName', zoneType);
          }

          await address.save();
        }

        if (location) {
          const locationResponse = await locationAdapter.postLocation(location);
          const locationRecord = await store.findRecord('location', locationResponse.data.id);
          place.set('location', locationRecord);
        }

        return place.save();
      }
    },

    rollbackPlaceRecord(place) {
      if (place) {
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
        if (address) address.rollbackAttributes();
        if (location) location.rollbackAttributes();
        place.rollbackAttributes();
      }
    },

    saveShiftBreakRecords(records) {
      return records.reduce((acu, record) => {
        return acu.then(async () => {
          const place = (0, _unwrapProxy.unwrapProxy)(record.get('place'));

          if (!record.get('isFloatingBreak')) {
            await this.savePlaceRecord(place);
          } else {
            this.rollbackPlaceRecord(place);
          }

          return record.save();
        });
      }, Promise.resolve());
    },

    async deletePlaceRecord(place) {
      if (place && !place.get('isNew')) {
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        if (address) await address.destroyRecord();
        return;
      }

      return this.rollbackPlaceRecord(place);
    },

    deleteShiftBreakRecords(records) {
      return records.reduce((acu, record) => {
        return acu.then(async () => {
          if (record.isDeleted) return;
          await this.deletePlaceRecord((0, _unwrapProxy.unwrapProxy)(record.get('place')));
          if (record.get('isNew')) return record.rollbackAttributes();
          return record.destroyRecord();
        });
      }, Promise.resolve());
    },

    getShiftEndDate(shiftStart, routeLength) {
      const momentShiftStart = (0, _moment.default)(shiftStart);
      const {
        hours,
        minutes
      } = this.getDateForRouteLength(routeLength);
      momentShiftStart.add('hour', hours);
      momentShiftStart.add('minute', minutes);
      return momentShiftStart.toDate();
    },

    rollbackModel() {
      const routeTemplateModel = this.get('routeTemplate');
      const availabilities = Ember.isPresent(routeTemplateModel) ? routeTemplateModel.get('availabilities') : [];
      const previousVehicleMap = this.get('previousVehicleMap');

      if (Ember.isPresent(routeTemplateModel)) {
        routeTemplateModel.rollbackAttributes();
      }

      availabilities.forEach((availability, availIndex) => {
        const shBrks = availability.get('shiftBreaks');
        const previousSelectedDOW = availability.get('DOWSelected');
        const previousVehicleMapKey = availIndex + availability.get('id');
        const previousVehicle = previousVehicleMap[previousVehicleMapKey]; // ember data records if a record is new, it will be removed from the store, otherwise reset it's attributes

        shBrks.forEach(shBrk => {
          shBrk.rollbackAttributes();
        });
        availability.rollbackAttributes();

        if (!availability.get('isDeleted')) {
          availability.set('DOWSelected', previousSelectedDOW);
          availability.set('vehicle', previousVehicle);
        }
      });
    },

    onRouteMasterSaveTask: (0, _emberConcurrency.task)(function* () {
      const routeTemplate = this.get('routeTemplateChangeSet');
      const availabilities = this.get('routeTemplateAvailabilities');
      const editedAvailabilities = availabilities.filter(availability => availability.get('isDirty') || availability.get('changes.length') || availability.get('isNew'));
      const deletedAvailabilities = this.get('sortedAvailabilities').filterBy('isDeleted');
      const notifications = this.get('notifications');
      const isNew = this.get('isNew');
      const isEdited = this.get('isEdited');
      let isRouteSaveError = '';
      this.validateTripAndDistanceLimit(routeTemplate);
      editedAvailabilities.forEach(avail => {
        const shiftStart = avail.get('shiftStart');
        avail.setProperties({
          startTime: shiftStart,
          shiftEnd: this.getShiftEndDate(shiftStart, avail.get('routeLength')),
          endTime: MAX_DATE.toDate()
        });
        if (avail.get('isNew')) avail.set('routeTemplate', routeTemplate.data);
      });

      try {
        if (isNew) {
          // save route template record
          yield this.saveRouteTemplateForNew.perform(); // save all shift breaks per availability
          // including all place, address, and location records

          yield this.saveRouteTemplateAvailabilityForNew.perform();
        }

        if (isEdited) {
          // save all shift breaks per availability
          // including all place, address, and location records
          yield this.saveRouteTemplateAvailabilityForEdit.perform(editedAvailabilities);
          const routeTemplateChanged = routeTemplate.get('isDirty') || routeTemplate.get('changes').length;

          if (routeTemplateChanged) {
            // update route template record when any changes happen
            yield this.saveRouteTemplateForEdit.perform();
          }
        }

        availabilities.map(avail => {
          const deletedShiftBreaks = avail.get('deletedShiftBreaks') || [];
          deletedShiftBreaks.map(deletedShiftBreak => {
            if (deletedShiftBreak.isDeleted) {
              deletedShiftBreaks.removeObject(deletedShiftBreak);
            }
          });
        });
        yield deletedAvailabilities.reduce((acu, avail) => {
          const shiftBreaks = avail.get('shiftBreaks') || [];
          const deletedShiftBreaks = avail.get('deletedShiftBreaks') || [];
          shiftBreaks.pushObjects(deletedShiftBreaks);
          return acu.then(() => avail.data.destroyRecord());
        }, Promise.resolve());
        this.get('sortedAvailabilities').removeObject(...deletedAvailabilities);
        notifications.success(`Successfully Saved Route ${routeTemplate.get('name')}`);
        this.set('isSavePerformed', true);
        this.set('isEdited', false);
        this.set('disableEditing', true);
      } catch (e) {
        // rollback model when changesets fails to get saved
        this.rollbackModel();
        console.warn(e); //eslint-disable-line

        isRouteSaveError = e;
        const error = Ember.isPresent(e.errors) ? e.errors.firstObject : {
          status: e.message
        };
        let message;

        switch (error.status) {
          case '409':
            message = 'Failed to save. Route Name already exists.';
            break;

          default:
            message = 'Failed to save.';
            break;
        }

        notifications.warning(message);
      } finally {
        if (isRouteSaveError === '') {
          this.set('pendingRecord', null); // newly record has been saved or been edited

          this.resetForm();
          this.refreshModelsTask.perform();
          const tableRef = this.get('tableRef');

          if (tableRef) {
            tableRef.set('config.selectFirstRow', true);
            tableRef.get('firstRowSelectedTask').perform();
          }
        }
      }
    }),
    saveRouteTemplateForNew: (0, _emberConcurrency.task)(function* () {
      const routeTemplate = this.get('routeTemplateChangeSet');

      try {
        return yield routeTemplate.save();
      } catch (e) {
        console.warn(e); //eslint-disable-line

        yield routeTemplate.destroyRecord();
        throw e;
      }
    }),
    saveRouteTemplateForEdit: (0, _emberConcurrency.task)(function* () {
      const routeTemplate = this.get('routeTemplateChangeSet');

      try {
        return yield routeTemplate.save();
      } catch (e) {
        console.warn(e); //eslint-disable-line

        throw e;
      }
    }),
    saveRouteTemplateAvailabilityForNew: (0, _emberConcurrency.task)(function* () {
      const availabilities = this.get('routeTemplateAvailabilities');

      try {
        return yield this.saveRouteTemplateAvailabilityRecords.perform(availabilities);
      } catch (e) {
        console.warn(e); //eslint-disable-line

        yield this.routeTemplateAvailabilityOnError.perform(availabilities);
        throw e;
      }
    }),
    saveRouteTemplateAvailabilityForEdit: (0, _emberConcurrency.task)(function* (changedAvailabilities) {
      try {
        return yield this.saveRouteTemplateAvailabilityRecords.perform(changedAvailabilities);
      } catch (e) {
        console.warn(e); //eslint-disable-line

        throw e;
      }
    }),
    saveRouteTemplateAvailabilityRecords: (0, _emberConcurrency.task)(function* (availabilities) {
      yield availabilities.reduce((acu, avail) => {
        const shiftBreaks = avail.get('shiftBreaks') || [];
        const deletedShiftBreaks = avail.get('deletedShiftBreaks') || [];
        return avail.save().then(() => this.saveShiftBreakRecords(shiftBreaks)).then(() => this.deleteShiftBreakRecords(deletedShiftBreaks)).then(() => {
          avail.set('deletedShiftBreaks', []);
        });
      }, Promise.resolve());
    }),
    routeTemplateAvailabilityOnError: (0, _emberConcurrency.task)(function* (availabilities) {
      // delete all shift breaks per availability
      // including all place, address, and location records
      yield availabilities.reduce((acu, avail) => {
        const shiftBreaks = avail.get('shiftBreaks') || [];
        const deletedShiftBreaks = avail.get('deletedShiftBreaks') || [];
        shiftBreaks.pushObjects(deletedShiftBreaks);
        return acu.then(() => this.deleteShiftBreakRecords(shiftBreaks));
      }, Promise.resolve()); // delete all availabilities

      yield availabilities.reduce((acu, record) => {
        return acu.then(() => {
          if (record.get('isNew')) return record.rollback();
          return record.data.destroyRecord();
        });
      }, Promise.resolve());
    }),

    resetForm() {
      const disableBtns = {
        new: false,
        edit: false,
        delete: true // temporary disable deletion option

      };
      Ember.run.scheduleOnce('afterRender', this, () => this.set('disableBtns', disableBtns));
      this.set('disableEditing', true);
    },

    onDeleteTask: (0, _emberConcurrency.task)(function* () {
      const routeTemplate = this.get('routeTemplate'); // selected record to delete

      const availabilities = this.get('routeTemplate.availabilities');
      const notifications = this.get('notifications');

      try {
        // delete all shift breaks per availability
        // including all place, address, and location records
        yield availabilities.reduce((acu, avail) => {
          const shiftBreaks = avail.get('shiftBreaks') || [];
          const deletedShiftBreaks = avail.get('deletedShiftBreaks') || [];
          shiftBreaks.pushObjects(deletedShiftBreaks);
          return acu.then(() => this.deleteShiftBreakRecords(shiftBreaks));
        }, Promise.resolve()); // delete all availabilities

        yield this.deleteRecords(availabilities); // delete route template record

        yield routeTemplate.destroyRecord();
        notifications.success('Route Master record successfully deleted');
      } catch (e) {
        console.warn(e); //eslint-disable-line

        notifications.warning('Route Master record failed to delete.');
      }

      this.set('pendingRecord', null); // newly record has been saved or been edited

      this.refreshModelsTask.perform();
      const tableRef = this.get('tableRef');

      if (tableRef) {
        tableRef.set('config.selectFirstRow', true);
        tableRef.get('firstRowSelectedTask').perform();
      }
    }),
    onRouteMasterDeleteTask: (0, _emberConcurrency.task)(function* () {
      const routes = this.get('routeTemplate.routes');
      const tooltip = this.get('tooltip');
      const onDeleteTask = this.get('onDeleteTask');
      const currentScheduleId = this.get('workspace.currentSchedule.id');
      const activeRoutes = routes.filter(route => route.get('schedule.id') === currentScheduleId);
      const sumTrips = activeRoutes.reduce((acu, route) => {
        const trips = route.get('trips').filter(trip => trip.get('schedule.id') === currentScheduleId);
        return trips.length + acu;
      }, 0);
      const tip = sumTrips > 0 ? `Are you sure you want to delete this Route Master record? Note: There are ${activeRoutes.length} future route(s) and ${sumTrips} future trip(s) associated with this route. Deleting this record will delete the routes and reschedule the trips the next time the schedule is reoptimized.` : `Are you sure you want to delete this Route Master record? There are ${activeRoutes.length} future route(s) associated with this route. Deleting this record will also delete the future route(s).`;

      if (!Ember.isEqual(activeRoutes.get('length'), 0)) {
        yield tooltip.pushConfirmation({
          title: 'System Configuration',
          tip: tip,
          warning: true,
          secondaryActionText: 'Cancel',

          secondaryAction() {
            return tooltip.reset();
          },

          primaryActionText: 'Confirm',

          primaryAction() {
            onDeleteTask.perform().then(() => {
              tooltip.reset();
            });
          }

        });
      } else {
        onDeleteTask.perform();
      }
    }),
    refreshModelsTask: (0, _emberConcurrency.task)(function* () {
      const tableRef = this.get('tableRef');
      const routeTemplate = this.get('routeTemplate');
      if (routeTemplate && routeTemplate.isDeleted) Ember.run.scheduleOnce('afterRender', tableRef, 'onUncheckAll'); // empty tableRef checkedrows

      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData'); // refresh data

      yield this.get('store').query('route-template', {});
      yield this.get('store').findAll('route-template-availability'); // find a better place for api

      yield this.get('store').findAll('shift-break'); // find a better place for api
    }).drop(),
    validateRecordTask: (0, _emberConcurrency.task)(function* () {
      const result = this.validateRecord();
      return yield result.then(valid => {
        this.set('showValidations', !valid);
        return valid;
      });
    }).drop(),

    checkShiftLength(availability) {
      //routeLength is required field,validate the routeLength if only routeLength is available
      if (Ember.isEmpty(availability.get('routeLength'))) return;
      const availableLength = availability.get('routeLength').replace(/_/img, '0');
      const shiftLengthDuration = availableLength.split(':');
      const shiftHours = shiftLengthDuration[0];
      const shiftMinutes = shiftLengthDuration.length > 1 ? shiftLengthDuration[1] : 0;

      if (parseInt(shiftHours, 10) === 0 && parseInt(shiftMinutes, 10) === 0) {
        availability.addError('routeLength', {
          value: availableLength,
          validation: 'Shift Length must be greater than 00:00.'
        });
      }
    },

    isEndSpanToFuture(shiftStart, routeLength) {
      var t1 = (0, _moment.default)(shiftStart, 'HH:mm');
      var t2 = routeLength.split(':'); // Extract hours and minutes

      var hours1 = t1.hours();
      var minutes1 = t1.minutes();
      var hours2 = parseInt(t2[0], 10);
      var minutes2 = parseInt(t2[1], 10); // Calculate total hours and minutes

      var totalMinutes = hours1 * 60 + minutes1 + (hours2 * 60 + minutes2);
      var totalHours = Math.floor(totalMinutes / 60);
      var remainingMinutes = totalMinutes % 60; // Check if the total hours are greater than 24

      if (totalHours > 24 || totalHours === 24 && remainingMinutes > 0) {
        return true;
      }

      return false;
    },

    getTransformedCurrentDate(date) {
      let spanToFuture = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!date) return null;
      const recordDate = (0, _moment.default)(date);
      const momentTime = (0, _moment.default)();

      if (spanToFuture) {
        momentTime.add(1, 'days');
      }

      momentTime.set('hour', recordDate.get('hour'));
      momentTime.set('minute', recordDate.get('minute'));
      momentTime.set('second', recordDate.get('second'));
      return momentTime.toDate();
    },

    async validateRecord() {
      const routeTemplateChangeSet = this.get('routeTemplateChangeSet');
      const sortedAvailabilities = this.get('routeTemplateAvailabilities');
      const routeName = this.get('routeTemplateChangeSet.name');
      const tooltip = this.get('tooltip');
      let errors = [];
      let validate = false;
      validate = await routeTemplateChangeSet.validate();
      errors = routeTemplateChangeSet.errors;
      await sortedAvailabilities.reduce((acu, availability) => {
        return acu.then(async () => {
          const shiftBreaks = availability.get('shiftBreaks') || [];
          const availableLength = availability.get('routeLength');
          await availability.validate(); // Checking the Vehicle name

          if (!(0, _unwrapProxy.unwrapProxy)(availability.get('vehicle'))) {
            availability.addError('vehicle', {
              value: null,
              validation: 'Vehicle cannot be blank.'
            });
          } // Chekcing the Shift length


          this.checkShiftLength(availability); // Prevents entering Shift End Time less than the Existing Break Time

          const isShiftBreaksValid = shiftBreaks.every(shiftBreak => {
            const shiftStartTime = this.getTransformedCurrentDate(availability.get('shiftStart'));
            const spanToFuture = this.isEndSpanToFuture(availability.get('shiftStart'), availability.get('routeLength'));
            const calcEndDate = this.getTransformedCurrentDate(availability.get('calcEndDate'), spanToFuture);
            const minutesPlannedDuration = parseInt(shiftBreak.get('minutesPlannedDuration'), 10);
            const requestTime = this.getTransformedCurrentDate(this.getRequestTime(shiftBreak.get('requestTime'), minutesPlannedDuration));
            return (0, _moment.default)(requestTime).isBetween(shiftStartTime, calcEndDate);
          });

          if (isShiftBreaksValid) {
            return;
          }

          availability.addError('routeLength', {
            value: availableLength,
            validation: 'Shift length must include existing break time.'
          });
        });
      }, Promise.resolve());
      sortedAvailabilities.forEach(availability => {
        if (availability.errors.length > 0) {
          errors.pushObjects(availability.errors);
        }
      });
      errors = (0, _lodash.uniqBy)(errors, error => error.key);
      validate = sortedAvailabilities.every(availability => availability.isValid);

      if (sortedAvailabilities.length <= 0) {
        errors.pushObject({
          key: '',
          validation: 'Must have at least one availability.',
          value: ''
        });
        validate = false;
      } // NYAAR-16513 maximum route name value length 10 digit and below 2,147,483,647.


      if (parseInt(routeName, 10) > MAX_ROUTE_VALUE) {
        errors.pushObject({
          key: '',
          validation: 'Route Name should not exceeds the maximum value 2147483647',
          value: ''
        });
        validate = false;
      }

      if (!validate || !routeTemplateChangeSet.isValid) {
        tooltip.pushConfirmation({
          title: 'System Configuration',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
      }

      return routeTemplateChangeSet.isValid && validate;
    },

    getRequestTime(requestTime, minutesPlannedDuration) {
      const formattedRequestTime = this.getTransformedDate(requestTime);

      if (formattedRequestTime && minutesPlannedDuration) {
        formattedRequestTime.setMinutes(formattedRequestTime.getMinutes() + minutesPlannedDuration);
        return formattedRequestTime;
      }

      return formattedRequestTime;
    },

    getTransformedDate(date) {
      if (!date) return null;
      const recordDate = (0, _moment.default)(date);
      const momentTime = (0, _moment.default)(new Date(null));
      momentTime.set('hour', recordDate.get('hour'));
      momentTime.set('minute', recordDate.get('minute'));
      momentTime.set('second', recordDate.get('second'));
      return momentTime.toDate();
    },

    async onVehicleChange(record, vehicle) {
      let path = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'vehicle';
      record.set(path, vehicle);

      if (!record.get('vehicle.provider.isBrokerProviderType')) {
        record.set('routeLimit', 0);
      } else {
        this.actions.onRouteLimitChange(record, 0);
      }
    },

    async onDriverChange(record, driver) {
      let path = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'driver';

      if (Ember.isEmpty(driver.get('id'))) {
        record.set(path, null);
      } else {
        record.set(path, driver);
      }
    },

    async onDateChange(record, path, dateStr) {
      record.set(path, dateStr);
      const calcEndDate = this.get('calcEndDate').call(this, dateStr, record.get('routeLength'));
      record.set('calcEndDate', calcEndDate);
    },

    async onSaveRouteMaster() {
      const valid = await this.validateRecord();

      if (valid) {
        this.set('showValidations', false);
        return this.onRouteMasterSaveTask.perform();
      }

      this.set('showValidations', true);
      return false;
    },

    validateTripAndDistanceLimit(routeTemplate) {
      // default values
      if (Ember.isEmpty(routeTemplate.get('tripLimit'))) {
        routeTemplate.set('tripLimit', MAX_TRIP_LIMIT);
      }

      if (Ember.isEmpty(routeTemplate.get('distanceLimit'))) {
        routeTemplate.set('distanceLimit', MAX_DISTANCE_LIMIT);
      }
    },

    enableEditRouteMaster() {
      const routeTemplate = this.get('routeTemplate');
      if (!routeTemplate) return;
      const disableBtns = {
        new: true,
        edit: true,
        delete: true
      };
      Ember.run.scheduleOnce('afterRender', this, () => {
        this.set('disableBtns', disableBtns);
        this.set('disableEditing', false);
      });
    },

    getShiftStartDate(shiftStart) {
      if (!shiftStart) return null;
      const momentShiftStart = (0, _moment.default)(shiftStart);
      const momentTime = (0, _moment.default)(new Date(null));
      momentTime.set('hour', momentShiftStart.get('hour'));
      momentTime.set('minute', momentShiftStart.get('minute'));
      momentTime.set('second', momentShiftStart.get('second'));
      return momentTime.toDate();
    },

    calcEndDate(shiftStart, routeLength) {
      const start = this.getShiftStartDate(shiftStart);
      if (!start || !routeLength) return null;
      const clone = (0, _moment.default)(start);
      const {
        hours,
        minutes
      } = this.getDateForRouteLength(routeLength);
      clone.add(hours, 'hours');
      clone.add(minutes, 'minutes');
      return clone.toDate();
    },

    getDateForRouteLength(routeLength) {
      const duration = routeLength.split(':');
      const hours = duration[0];
      const minutes = duration.length > 1 ? duration[1] : 0;
      return {
        hours,
        minutes
      };
    },

    setAvailability(availability) {
      this.set('selectedAvailability', availability);
    },

    doUndoRouteMaster() {
      const routeTemplateChangeSet = this.get('routeTemplateChangeSet');
      const routeTemplateAvailabilities = this.get('routeTemplateAvailabilities');
      const previousVehicleMap = this.get('previousVehicleMap');
      const zoneTableRef = this.get('zoneTableRef'); // On rollback, all changes are dropped and the underlying Object is left untouched for all changeset objects
      // ember data related records will be rolled back and if its new deleted

      routeTemplateChangeSet.rollback();
      const availabilities = routeTemplateChangeSet.get('availabilities') || [];
      availabilities.filter(avail => Ember.isPresent((0, _unwrapProxy.unwrapProxy)(avail))).forEach((availability, availIndex) => {
        const shBrks = availability.get('shiftBreaks');
        const previousSelectedDOW = availability.get('DOWSelected');
        const previousVehicleMapKey = availIndex + availability.get('id');
        const previousVehicle = previousVehicleMap[previousVehicleMapKey]; // ember data records if a record is new, it will be removed from the store, otherwise reset it's attributes

        shBrks.forEach(shBrk => {
          shBrk.rollbackAttributes();
        });
        availability.rollbackAttributes();

        if (Ember.isPresent(routeTemplateAvailabilities)) {
          routeTemplateAvailabilities.forEach(avail => {
            const shiftBreaks = avail.get('shiftBreaks') || [];
            const deletedShiftBreaks = avail.get('deletedShiftBreaks') || [];

            if (Ember.isPresent(deletedShiftBreaks)) {
              shiftBreaks.pushObjects(deletedShiftBreaks);
            }
          });
        }

        if (!availability.get('isDeleted')) {
          availability.set('DOWSelected', previousSelectedDOW);
          availability.set('vehicle', previousVehicle);
        }
      });
      this.configureRouteTemplateAvailabilities(availabilities);

      if (zoneTableRef !== null) {
        zoneTableRef.refreshData();
        zoneTableRef.onUncheckAll();
      }

      this.set('selectedAffinityType', null);
    },

    actions: {
      newRouteMaster() {
        const selectedRow = this.get('selectedRow');

        if (selectedRow) {
          selectedRow.set('selected', false);
          this.set('tableRef.config.selectFirstRow', false);
        }

        const disableBtns = {
          new: true,
          edit: true,
          delete: true
        };
        const dayOptions = this.get('daysOfWeek');
        dayOptions.forEach(option => {
          Ember.set(option, 'disabled', false);
        });
        this.set('disableBtns', disableBtns);
        this.set('disableEditing', false);
        this.set('isEdited', false);
        this.set('isSavePerformed', false);
        this.set('isNew', true);
      },

      editRouteMaster() {
        this.enableEditRouteMaster();
        const routeTemplate = this.get('routeTemplate');
        const selectedRecord = this.get('selectedRecord');
        this.setPerviousVehicleMap(selectedRecord.get('availabilities'));
        if (!routeTemplate) return;
        const disableBtns = {
          new: true,
          edit: true,
          delete: true
        };
        this.set('disableBtns', disableBtns);
        this.set('isEdited', true);
        this.set('isSavePerformed', false);
        this.set('disableEditing', false);
        this.set('isNew', false);
      },

      undoRouteMaster() {
        this.doUndoRouteMaster();
      },

      async saveRouteMaster() {
        return this.onSaveRouteMaster();
      },

      deleteRouteMaster() {
        this.onRouteMasterDeleteTask.perform();
      },

      openVehicleSearchModal(availability) {
        const onVehicleChange = this.get('onVehicleChange').bind(this);
        const searchVehicleObj = Ember.Object.create({
          title: 'Find Vehicles',
          hasOverlay: true,
          config: _vehicleSearchConfig.default,
          primaryActionText: 'Select',
          className: 'vehicle-search',
          primaryAction: record => {
            onVehicleChange(availability, record);
          },
          queryParams: {
            useVehicleService: true
          }
        });
        this.set('noneRecord');
        this.set('modalAPI', searchVehicleObj);
      },

      openDriverSearchModal(availability) {
        const onDriverChange = this.get('onDriverChange').bind(this);
        const noneDriverModel = this.get('noneDriverModel');
        const searchDriverObj = Ember.Object.create({
          title: 'Find Drivers',
          hasOverlay: true,
          config: _driverSearchConfig.default,
          primaryActionText: 'Select',
          className: 'vehicle-search',
          primaryAction: record => {
            onDriverChange(availability, record);
          }
        });
        this.set('noneRecord', noneDriverModel);
        this.set('modalAPI', searchDriverObj);
      },

      /**
       * REG-NYAAR-19112 fetch shift break place address by shift break place
       * @param availability - route-template-availablity
       * @param day
       * @returns {Promise<void>}
       */
      async openBreakModal(availability, day) {
        const shiftBreaks = availability.get('shiftBreaks').toArray();
        const breakPlaceIds = shiftBreaks.filter(shiftBreak => {
          const place = (0, _unwrapProxy.unwrapProxy)(shiftBreak.get('place'));
          return place && place.get('id');
        }).map(shiftBreak => shiftBreak.get('place.id')).join(',');
        const placesQuery = `in(id,(${breakPlaceIds}))&include=address,location`;
        await this.get('store').query('place', {
          filter: placesQuery
        });
        availability.set('day', day);
        this.set('isDriverBreakModalEnabled', true);
        this.set('driverBreakAvailability', availability);
      },

      onDateChange(record, path, value) {
        this.onDateChange(record, path, value);
      },

      onTextChange(record, path, value) {
        record.set(path, value);
      },

      onRouteLengthChange: async function (availability, maskObj) {
        const routeLength = maskObj.masked;
        const oldVal = availability.get('routeLength'); // This method gets called multiple times (even while hitting a ENTER key). So If oldValue is equal to newValue then no need to validate

        if (routeLength === oldVal) {
          return;
        }

        let value = maskObj.masked.replace(/_/img, '0');
        if (!value) value = '00:00';
        availability.set('routeLength', routeLength);
        const calcEndDate = this.get('calcEndDate').call(this, availability.get('shiftStart'), value);
        availability.set('calcEndDate', calcEndDate);
        this.setAvailability(availability);
      },

      onAffinitySelect(option) {
        const availableZones = this.get('availableZones');
        const pendingRecord = this.get('routeTemplateChangeSet');
        const zones = this.get('zoneTableRef.checkedRows');
        const zoneIds = availableZones.map(zone => parseInt(zone.get('id'), 10)).filter(zoneId => Ember.isPresent(zoneId) && !(0, _lodash.isNaN)(zoneId));
        const affinities = pendingRecord.get('affinities') || [];
        const maxZoneId = Math.max(...zoneIds, affinities.length);
        const newSize = Math.max(maxZoneId, affinities.length);
        const newAffinities = Array(newSize).fill(0);
        this.set('selectedAffinityType', option);
        zones.setEach('affinityValue', option.val); // configured affinities to contain all imaginable zone changes

        affinities.forEach((aff, index) => {
          newAffinities[index] = aff;
        });
        zones.forEach(zone => {
          //affinities index start from [0-16] and zone Ids starts from 1-17
          const zoneId = parseInt(zone.get('zoneId') - 1, 10);
          newAffinities[zoneId] = option.val;
        });
        pendingRecord.set('affinities', newAffinities);
      },

      onAdjustDriverBreakCheckBoxClick(event) {
        const checked = event.target.checked;
        this.configureOptimizationFlags(checked, _routeTemplate.ADJUST_BREAK_FLAG);
      },

      onConsolidateCheckBoxClick(event) {
        const checked = event.target.checked;
        this.configureOptimizationFlags(checked, _routeTemplate.CONSOLIDATE_ROUTE_FLAG);
      },

      onSchedulingModeClick(event) {
        const checked = event.target.checked;
        const routeTemplate = this.get('routeTemplateChangeSet');

        if (checked) {
          routeTemplate.set('schedulingMode', _routeTemplate.MANUAL_SCHEDULING_MODE);
        } else {
          routeTemplate.set('schedulingMode', _routeTemplate.AUTOMATIC_SCHEDULING_MODE);
        }
      },

      onKeeperTypeClick(event) {
        const checked = event.target.checked;
        const routeTemplate = this.get('routeTemplateChangeSet');

        if (checked) {
          routeTemplate.set('type', _routeTemplate.ROUTE_TEMPLATE_TYPE_KEEPER);
        } else {
          routeTemplate.set('type', _routeTemplate.ROUTE_TEMPLATE_TYPE_REGULAR);
        }
      },

      async onRouteLimitChange(availability, value) {
        let routeLimitValue = parseInt(value, 10);

        if ((0, _lodash.isNaN)(routeLimitValue)) {
          routeLimitValue = 0;
        }

        availability.set('routeLimit', routeLimitValue);
      },

      async onDOWChange(availability, options) {
        const routeTemplateAvailabilities = this.get('routeTemplateAvailabilities').filter(avail => avail !== availability);
        const dayOptions = this.get('daysOfWeek');
        const DOWSelected = [];
        dayOptions.forEach(day => {
          availability.set(day.day, false);
        });
        let deselectedDayOptions = (0, _lodash.difference)(dayOptions, options);
        options.forEach(option => {
          availability.set(option.day, true);
          const findDay = dayOptions.find(dayOption => dayOption.day === option.day);
          Ember.set(findDay, 'disabled', true);
          DOWSelected.pushObject(findDay);
        });
        routeTemplateAvailabilities.forEach(avail => {
          deselectedDayOptions = (0, _lodash.difference)(deselectedDayOptions, avail.get('DOWSelected'));
        });
        deselectedDayOptions.forEach(option => {
          Ember.set(option, 'disabled', false);
        }); // no available options remain

        if (!deselectedDayOptions.length) {
          this.set('disableAddAvailabilityBtn', true);
        } else {
          this.set('disableAddAvailabilityBtn', false);
        }

        availability.set('DOWSelected', DOWSelected);
        this.setAvailability(availability);
      },

      addNewAvailability() {
        const store = this.get('store');
        const routeTemplate = this.get('routeTemplate');
        const routeTemplateAvailability = store.createRecord('route-template-availability', {
          shiftStart: new Date(),
          routeTemplate
        });
        const {
          validateFn,
          validationMap
        } = (0, _emberChangesetCpValidations.buildChangeset)(routeTemplateAvailability);
        const changeset = new _emberChangeset.default(routeTemplateAvailability, validateFn, validationMap);
        this.get('sortedAvailabilities').pushObject(changeset);
      },

      onRemoveAvailability(availability) {
        const dayOptions = this.get('daysOfWeek');
        const DOWSelected = availability.get('DOWSelected');
        const deselectedDayOptions = (0, _lodash.intersection)(dayOptions, DOWSelected);
        deselectedDayOptions.forEach(option => {
          Ember.set(option, 'disabled', false);
        });
        availability.set('isDeleted', true);
        const remainingDays = dayOptions.filter(option => !option.disabled);

        if (remainingDays.length > 0) {
          this.set('disableAddAvailabilityBtn', false);
        }
      }

    }
  });

  _exports.default = _default;
});