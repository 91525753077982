define("adept-iq/pods/components/side-drawer/reconcile-reopen-dates/component", ["exports", "ember-light-table", "adept-iq/mixins/fetchAssignableVehicleDrivers", "adept-iq/pods/components/side-drawer/reconcile-reopen-dates/config", "ember-concurrency", "moment"], function (_exports, _emberLightTable, _fetchAssignableVehicleDrivers, _config, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fetchAssignableVehicleDrivers.default, {
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    config: _config.default,
    title: 'Reopen Dates to Editing',
    onCloseClick: null,
    closedProvidersList: null,
    errorMessage: '',
    isTableEmpty: false,
    selectedDates: Ember.computed.alias('closedProviderDatesTable.selectedRows.[]'),
    canSave: Ember.computed.and('selectedProvider', 'selectedDates'),
    canUndo: Ember.computed.alias('selectedProvider'),
    canSearch: Ember.computed.alias('selectedProvider'),
    reconcileRouteId: Ember.computed.alias('reconcile.reconcileSelectedRouteId'),
    providerOptions: Ember.computed('selectedProvider', function () {
      return this.get('store').peekAll('provider');
    }),

    init() {
      this._super(...arguments);

      const closedProviderDatesTable = new _emberLightTable.default(this.config.reopenDatesTableColumns);
      this.set('closedProviderDatesTable', closedProviderDatesTable);
      this.onClosedProviderPanelClick = this.onClosedProviderPanelClick.bind(this);
    },

    getUniqueList(list, keyType) {
      return [...new Map(list.map(item => [item[keyType], item])).values()];
    },

    onClosedProviderPanelClick() {
      this.uncheckAll();

      if (!Ember.isEmpty(this.selectedDates)) {
        this.selectedDates.map(row => {
          row.set('isChecked', true);
        });
      }
    },

    uncheckAll() {
      const rows = Ember.get(this, 'closedProviderDatesTable.rows');
      rows.map(row => {
        row.set('isChecked', false);
      });
    },

    resetTable() {
      Ember.set(this, 'isTableEmpty', false);
      Ember.set(this, 'closedProvidersList', null);
      this.get('closedProviderDatesTable').setRows([]);
    },

    showToolTip() {
      const tooltip = this.get('tooltip');
      const title = 'Reopen Dates';
      const tip = 'Are you sure you want to reopen the selected dates?';
      const successMsg = 'SELECTED DATES HAVE BEEN SUCCESSFULLY REOPENED';
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          return this.get('saveReopenDatesTask').perform().then(() => {
            this.get('workspace').popState('reconcileOpenDates');
            this.get('notifications').success(successMsg);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    getErrorMessage(error, typeMsg) {
      let message;

      switch (error.status) {
        case 400:
          message = 'There was a problem with one of the fields. Please try again.';
          break;

        default:
          message = `There was a problem in ${typeMsg}, please try again.`;
          break;
      }

      return message;
    },

    saveReopenDatesTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const reconcileRouteAdapter = store.adapterFor('reconcile-route');
      const routes = [];
      let routeId = 0;

      if (!Ember.isEmpty(this.selectedDates) && !Ember.isEmpty(this.closedProvidersList)) {
        this.selectedDates.map(selectedRow => {
          const record = selectedRow.get('content');
          const selectedDateRecords = this.closedProvidersList.filter(item => {
            return item.plannedStartTime === record.plannedStartTime && item.providerName === record.providerName;
          });

          if (!Ember.isEmpty(selectedDateRecords)) {
            selectedDateRecords.map(finalItem => {
              routeId = finalItem.routeId;
              routes.push({
                routeId: finalItem.routeId,
                providerName: finalItem.providerName,
                reopenedDate: (0, _moment.default)(new Date()).toISOString(),
                reopenedBy: this.session.data.authenticated.tokenInfo.firstName
              });
            });
          }
        });

        if (!Ember.isEmpty(routes)) {
          const payload = {
            id: routeId,
            routes
          };

          try {
            yield reconcileRouteAdapter.patchRoute(payload, 'reopen dates');
            const isExists = routes.filter(route => route.routeId === this.reconcileRouteId)[0];

            if (isExists) {
              yield Ember.get(this, 'reconcile.fetchRouteData').perform();
            }
          } catch (error) {
            this.get('notifications').warning(this.getErrorMessage(error, 'saving reopened dates'));
            this.get('tooltip').reset();
          }
        }
      }
    }),
    fetchClosedProvidersTask: (0, _emberConcurrency.task)(function* (providerName) {
      const closedDateList = [];
      const closedDateFinalList = [];
      const queryOptions = {
        isClosed: true,
        providerName
      };

      try {
        const closedProviders = yield this.get('store').query('reconcile-route-id', queryOptions);

        if (!Ember.isEmpty(closedProviders)) {
          closedProviders.map(data => {
            closedDateList.push(data);
          });
        }
      } catch (error) {
        this.get('notifications').warning(this.getErrorMessage(error, 'getting closed dates'));
        throw error;
      }

      if (!Ember.isEmpty(closedDateList)) {
        closedDateList.map(item => {
          item.set('plannedStartTime', (0, _moment.default)(item.plannedStartTime).format('MM/DD/YYYY'));
        });
        Ember.set(this, 'closedProvidersList', closedDateList);
        const uniqueDateList = this.getUniqueList(closedDateList, 'plannedStartTime');

        if (!Ember.isEmpty(uniqueDateList)) {
          uniqueDateList.map(item => {
            item.set('isChecked', false);
            closedDateFinalList.push({
              'plannedStartTime': item.plannedStartTime,
              'providerName': item.providerName
            });
          });
          this.get('closedProviderDatesTable').setRows(closedDateFinalList);
        }
      }

      if (Ember.isEmpty(closedDateFinalList)) {
        Ember.set(this, 'isTableEmpty', true);
      }
    }),
    actions: {
      onCancel() {
        this.onCloseClick();
      },

      onUndoClick() {
        this.set('selectedProvider', null);
        this.resetTable();
      },

      onProviderOptionSelect(option) {
        Ember.set(this, 'selectedProvider', option);
        this.resetTable();
      },

      onSearchButtonClick() {
        this.resetTable();
        this.get('fetchClosedProvidersTask').perform(this.selectedProvider.id);
      },

      async onSaveClick() {
        if (Ember.isEmpty(this.selectedDates)) {
          Ember.set(this, 'errorMessage', 'Please select atleast one date');
          return;
        }

        Ember.set(this, 'errorMessage', '');
        this.showToolTip();
      }

    }
  });

  _exports.default = _default;
});