define("adept-iq/pods/components/form-components/number-mask/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z3Boowvi",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"one-way-number-mask\",null,[[\"class\",\"mask\",\"value\",\"disabled\",\"decimal\",\"placeholder\",\"update\",\"options\"],[[27,\"concat\",[\"form-control \",[23,[\"inputClass\"]]],null],[23,[\"mask\"]],[23,[\"value\"]],[23,[\"disabled\"]],[23,[\"decimal\"]],[23,[\"placeholder\"]],[27,\"action\",[[22,0,[]],\"onInput\"],null],[27,\"hash\",null,[[\"oncomplete\"],[[27,\"action\",[[22,0,[]],\"onComplete\"],null]]]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/number-mask/template.hbs"
    }
  });

  _exports.default = _default;
});