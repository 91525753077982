define("adept-iq/classes/active-contexts/booking/booking", ["exports", "lodash", "adept-iq/classes/active-contexts/base/base", "adept-iq/classes/active-contexts/booking/graph", "adept-iq/classes/active-contexts/booking/map-graph", "adept-iq/utils/flattenData"], function (_exports, _lodash, _base, _graph, _mapGraph, _flattenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // overridden values based on extending sub classes
    // computed.readOnly('workspaceContext._structuredWorkspace')
    graph: null,
    nodes: null,
    emptyImplicitHash: null,
    workspaceData: Ember.computed.readOnly('workspaceContext._structuredWorkspace'),
    consumedModelNames: Ember.computed('workspace.dashboardInstance.widgets.[]', function () {
      const widgets = this.get('workspace.dashboardInstance.widgets');

      const widgetModels = _lodash.default.reduce(widgets, (acc, widget) => _lodash.default.union(acc, widget.get('models')), []);

      return widgetModels;
    }),
    // structured data sets (grouped by model name)
    structuredActiveData: Ember.computed('workspaceData', 'checkedItems.[]', function () {
      try {
        const workspaceData = this.get('workspaceData') || {};
        const checkedItems = this.get('checkedItems') || [];
        return this._computeActiveContext(workspaceData, checkedItems);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Exception occured while refreshing Workspace Data in structuredActiveData' + e);
      }
    }),
    structuredImplicitData: Ember.computed('workspaceData', 'checkedItems.[]', function () {
      try {
        const workspaceData = this.get('workspaceData') || {};
        const checkedItems = this.get('checkedItems') || [];
        return this._computeImplicitContext(workspaceData, checkedItems);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Exception occured while refreshing Workspace Data in structuredImplicitData' + e);
      }
    }),
    // flattened data sets for easy binding & interation
    activeData: Ember.computed('structuredActiveData', function () {
      const structuredActiveData = this.get('structuredActiveData');
      return (0, _flattenData.flattenData)(structuredActiveData);
    }),
    activeVehicles: Ember.computed('workspaceData', function () {
      const workspaceData = this.get('workspaceData');
      if (!workspaceData) return [];
      return workspaceData.vehicle;
    }),
    // map-context uses to construct layered records
    structuredMapData: Ember.computed('workspaceData', 'checkedItems.[]', function () {
      const workspaceData = this.get('workspaceData') || {};
      const checkedItems = this.get('checkedItems') || [];
      return this._computeMapContext(workspaceData, checkedItems);
    }),
    // map-context binds to this
    implicitData: Ember.computed('structuredMapData', function () {
      const structuredMapData = this.get('structuredMapData');
      return (0, _flattenData.flattenData)(structuredMapData);
    }),
    hashImplicitData: Ember.computed('structuredImplicitData', function () {
      const structuredImplicitData = this.get('structuredImplicitData');
      const hash = this.get('emptyImplicitHash');

      _lodash.default.forOwn(structuredImplicitData, (value, key) => {
        hash[key] = {};
        value.forEach(v => {
          const id = v.get('id');
          hash[key][id] = true;
        });
      });

      return hash;
    }),
    // bound to computed property to implicitMarkers
    implicitStops: Ember.computed('implicitData', function () {
      const implicitData = Ember.makeArray(this.get('implicitData'));
      return implicitData.filterBy('modelName', 'stop-point').mapBy('record');
    }),
    // bound to computed property to implicitMarkers
    implicitVehicles: Ember.computed('workspaceData', 'implicitData', function () {
      const implicitData = Ember.makeArray(this.get('implicitData'));
      const vehicles = implicitData.filterBy('modelName', 'vehicle').mapBy('record');
      const implicitVehicles = vehicles;
      return implicitVehicles;
    }),
    // bind implicitContext property to boundary
    implicitMarkers: Ember.computed('implicitVehicles.[]', 'implicitStops.[]', function () {
      const implicitStops = this.get('implicitStops');
      const implicitVehicles = this.get('implicitVehicles');
      return implicitStops.concat(implicitVehicles);
    }),

    tripActiveContext() {
      return this.get('checkedItems').some(record => {
        return record.modelName === 'trip';
      });
    },

    // Overwrite base clearCheckedItems
    async clearCheckedItems() {
      const checkedItems = this.get('checkedItems');

      for (const checkedItem of checkedItems) {
        this.toggleHighLight(checkedItem.record, false);
      }

      this.set('checkedItems', []);
      this.get('widget').deselectAllWidgets();
      this.notifyPropertyChange('implicitData');
      await this.refreshAll();
      const allRows = document.querySelectorAll('.passengers-booking-widget .column-widget-body .ember-light-table .lt-row');
      allRows.forEach(row => row.classList.remove('is-selected'));
    },

    init() {
      this._super(...arguments);

      this.set('checkedItems', []);
      this.set('refreshedModelNames', []);
      this.set('mapGraph', _mapGraph.mapContextGraph);
      this.set('mapNodes', _mapGraph.mapContextNodes);
      this.set('graph', _graph.activeContextGraph);
      this.set('nodes', _graph.activeContextNodes);

      const nodes = _graph.activeContextNodes.reduce((acu, node) => {
        acu[node.modelName] = {};
        return acu;
      }, {});

      this.set('emptyImplicitHash', nodes);
      this.startRefreshQueue();
    },

    destroy() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});