define("adept-iq/pods/components/side-drawer/cancel-trip-form/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @TODO refactor the form components to change the editable property into a disabled property as editable is an inverse boolean problem
  var _default = {
    editableSections: [{
      title: 'Cancel Reason',
      fields: [{
        id: 'cancelType',
        type: 'enum',
        label: 'Reason',
        valuePath: 'cancelType',
        cellDesc: 'cancelType',
        editable: false,
        hidden: false,
        extra: {
          optionModelName: 'cancel-type',
          optionSearchPath: 'displayName',
          optionDisplayName: 'displayName'
        }
      }, {
        id: 'notes',
        type: 'text',
        editable: false,
        readonlyCell: false,
        label: 'Notes',
        valuePath: 'cancelNotes'
      }]
    }, {
      title: 'Trip Information',
      fields: [{
        id: 'riderFirstName',
        label: 'First Name',
        type: 'text',
        valuePath: 'leg.rider.firstName',
        editable: false,
        readonlyCell: true
      }, {
        id: 'riderLastName',
        label: 'Last Name',
        type: 'text',
        valuePath: 'leg.rider.lastName',
        editable: false,
        readonlyCell: true
      }, {
        id: 'externalId',
        label: 'Trip ID',
        valuePath: 'tripId',
        type: 'text',
        editable: false,
        readonlyCell: true
      }, {
        id: 'requestedTime',
        label: 'Promise Time',
        type: 'text',
        valuePath: 'currentTrip.cancelTripFormattedPromiseTime',
        editable: false,
        readonlyCell: true
      }, {
        id: 'pickupaddress',
        label: 'Pick Up Address ',
        type: 'text',
        valuePath: 'pick.place.address.simpleAddress',
        editable: false,
        readonlyCell: true
      }, {
        id: 'dropaddress',
        label: 'Drop Off Address',
        type: 'text',
        valuePath: 'drop.place.address.simpleAddress',
        editable: false,
        readonlyCell: true
      }, {
        id: 'anchor',
        label: 'Anchor',
        type: 'text',
        valuePath: 'anchor',
        editable: false,
        readonlyCell: true
      }]
    }]
  };
  _exports.default = _default;
});