define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/rear-axel/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/rear-axel/validation", "adept-iq/config/placeholders", "lodash", "adept-iq/utils/guid"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset, _emberChangesetValidations, _validation, _placeholders, _lodash, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['vehicle-lookup-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // placeholder
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    tableRef: null,
    description: null,
    editAPI: null,
    disableForm: true,
    showErrorMsg: false,
    currentDisplayName: null,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    rearAxelChangeSet: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),
    hasPendingChanges: Ember.computed('rearAxelChangeSet.changes.length', function () {
      const rearAxelChangeSet = this.get('rearAxelChangeSet');
      const changes = rearAxelChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      return this.get('hasPendingChanges');
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleRearAxelNameRecord = this.get('onSaveVehicleRearAxelNameRecord');
      const refreshTask = this.get('refreshTask');
      const onNewVehicleRearAxelNameRecord = this.get('onNewVehicleRearAxelNameRecord').bind(this);
      const onEditVehicleRearAxelNameRecord = this.get('onEditVehicleRearAxelNameRecord').bind(this);
      const onUndoVehicleRearAxelNameRecord = this.get('onUndoVehicleRearAxelNameRecord').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);

      const hasRelationship = () => false;

      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleRearAxelNameRecord,
          newAction: onNewVehicleRearAxelNameRecord,
          editAction: onEditVehicleRearAxelNameRecord,
          undoAction: onUndoVehicleRearAxelNameRecord,
          deleteAction: null,
          onValidateAction,
          hasChanges,
          hasRelationship,
          refreshTask
        });
        refreshTask.perform();
      });
    },

    willDestroyElement() {
      const records = this.get('tableRef.records') || [];
      records.map(record => {
        if (record.get('tableRef')) {
          record.set('tableRef');
        }
      });

      this._super(...arguments);
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const tableRef = this.get('tableRef');
      let rearAxelObj = this.get('pendingRecord');

      if (record) {
        rearAxelObj = record;
        this.resetForm();
      }

      if (Ember.isEmpty(record)) {
        const data = {
          category: 'config-System_Configuration-vehicle_rear_axels',
          type: 'object'
        };
        rearAxelObj = store.createRecord('cs-config-item', data);
        this.set('pendingRecord', rearAxelObj);
      }

      if (rearAxelObj.get('isNew')) {
        rearAxelObj.setProperties({
          description: null,
          tableRef: tableRef
        });
      }

      this.set('showErrorMsg', false);
      const tableRefRecords = this.get('tableRef.records') || [];
      tableRefRecords.map(tableRefRecord => {
        if (!tableRefRecord.get('tableRef')) {
          tableRefRecord.set('tableRef', tableRef);
        }
      });
      return new _emberChangeset.default(rearAxelObj, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('rearAxelChangeSet');

      if (selectedRecord) {
        if (!selectedRecord.get('isNew') && !selectedRecord.get('isDeleted')) {
          yield selectedRecord.data.reload();
        }
      }

      const tableRef = this.get('tableRef');
      this.resetForm();
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        editAPI.buttonSettings();
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const vehicleRearAxelNameRecord = this.get('rearAxelChangeSet');
      yield vehicleRearAxelNameRecord.validate();
      const errors = vehicleRearAxelNameRecord.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveVehicleRearAxelNameRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');
      const activityIdGuid = (0, _guid.guid)();
      if (!isValid) return;
      const notifications = this.get('notifications');
      const description = this.get('rearAxelChangeSet.description') || '';
      const value = {
        description: description
      };
      const nameGuidConverted = `${description}-${activityIdGuid}`;
      const name = (0, _lodash.camelCase)(nameGuidConverted);
      const rearAxelChangeSet = this.get('rearAxelChangeSet');
      let vehicleRearAxelNameRecord = this.get('rearAxelChangeSet');

      if (vehicleRearAxelNameRecord.get('isNew')) {
        vehicleRearAxelNameRecord = (0, _unwrapProxy.unwrapProxy)(this.get('rearAxelChangeSet'));
        vehicleRearAxelNameRecord.set('name', name);
      } else {
        const id = this.get('rearAxelChangeSet.id');
        const store = this.get('store');
        const record = `config-System_Configuration-vehicle_rear_axels/${id}`;
        vehicleRearAxelNameRecord = store.peekRecord('cs-config-item', record);
      }

      vehicleRearAxelNameRecord.set('displayName', description);
      vehicleRearAxelNameRecord.set('description', description);
      vehicleRearAxelNameRecord.set('value', value);

      try {
        yield vehicleRearAxelNameRecord.save(); // changeset holds changes, execute the changes due to lookups using unorthodox saving practice
        // execute to change underlying object and rollback to remove internal changes list

        rearAxelChangeSet.execute();
        rearAxelChangeSet.rollback();
        notifications.success('Lookup record successfully saved.');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
            tooltip.reset();
          });
        }, 500);
      } catch (response) {
        if (response.errors[0].status === '409') {
          tooltip.pushConfirmation({
            title: 'Manage Driver/Vehicle',
            tip: 'Description cannot duplicate an existing Description.',
            primaryActionText: 'OK',
            primaryAction: () => {
              return tooltip.reset();
            }
          });
        } else {
          notifications.warning('Lookup record failed to save.');
        }

        console.warn(response); // eslint-disable-line
      }
    }).drop(),

    onEditVehicleRearAxelNameRecord() {
      const editAPI = this.get('editAPI');
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onUndoVehicleRearAxelNameRecord() {
      const rearAxelChangeSet = this.get('rearAxelChangeSet');
      const editAPI = this.get('editAPI');

      if (rearAxelChangeSet) {
        rearAxelChangeSet.rollback();
      }

      this.set('disableForm', true);
      editAPI.buttonSettings(false, false, false, true, true);
    },

    onNewVehicleRearAxelNameRecord() {
      const editAPI = this.get('editAPI');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    actions: {
      onSaveVehicleRearAxelNameRecord() {
        return this.onSaveVehicleRearAxelNameRecord.perform();
      },

      onInputValueChange(record, valuePath, value) {
        this.set('currentDisplayName', value);
        record.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});