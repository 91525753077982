define("adept-iq/models/avlm-driver-app", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    appVersion: attr('string'),
    deviceHardwareId: attr('string'),
    cellularProvider: attr('string'),
    cellularType: attr('string'),
    callsign: attr('string'),
    vehicleId: attr('string'),
    // ideally this should be linked through avlmDriver -> iqDriver, but we don't
    // currently get the vehicle's avlm ID through vehicle topic
    avlmVehicles: hasMany()
  });

  _exports.default = _default;
});