define("adept-iq/models/route-shape", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    calendarId: 'number',
    lat: attr('string'),
    lng: attr('string'),
    sequences: attr('string')
  });

  _exports.default = _default;
});