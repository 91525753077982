define("adept-iq/pods/components/dashboard-save-as/component", ["exports", "ember-concurrency", "adept-iq/config/mapped-permIds"], function (_exports, _emberConcurrency, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_NAME = 'Untitled';

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    session: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    classNames: ['dashboard-save-as'],
    name: null,
    isPublic: false,
    displayName: Ember.computed.readOnly('workspace.topOptions.displayName'),
    isLoading: Ember.computed.oneWay('findUnusedNameTask.isRunning'),
    isRunning: Ember.computed.or('saveTask.isRunning', 'cancelTask.isRunning'),
    saveWorkspaceAllowed: Ember.computed('permissionLayer.userPermissionHash', function () {
      return this.get('permissionLayer').permInUserHash(_mappedPermIds.default.saveWorkspace, null);
    }),

    didInsertElement() {
      this._super(...arguments);

      this.get('findUnusedNameTask').perform().then(name => {
        this.set('name', name);
        Ember.run.schedule('afterRender', () => {
          this.$('.workspace-name-input').focus();
        });
      });

      this._onKeyDown = event => {
        Ember.run.schedule('afterRender', this, 'onKeyDown', event);
      };

      this.$().on('keydown', this._onKeyDown);
    },

    willDestroyElement() {
      this.$().off('keydown', this._onKeyDown);
      this._onKeyDown = null;

      this._super(...arguments);
    },

    findUnusedNameTask: (0, _emberConcurrency.task)(function* () {
      let baseName = this.get('workspace.dashboard.name');
      let version = 0;

      if (Ember.isPresent(baseName)) {
        // extract base name and version from names like "My Dashboard (3)"
        const regEx = /^(.*)\s+\((\d+)\)\s*$/;
        const matches = regEx.exec(baseName);

        if (matches) {
          baseName = matches[1];
          version = parseInt(matches[2], 10) + 1;
        }
      } else {
        baseName = DEFAULT_NAME;
      }

      const dashboards = yield this.get('store').findAll('dashboard');
      let name = version === 0 ? baseName : `${baseName} (${version})`;

      while (dashboards.findBy('name', name)) {
        version += 1;
        name = `${baseName} (${version})`;
      }

      return name;
    }).drop(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const dashboardAdapter = store.adapterFor('dashboard');
      const userId = this.get('session').data.authenticated.userId.replace(/-/g, '_'); // clone persisted changes

      const dashboardModel = this.get('workspace.dashboardModel');
      const options = dashboardModel.getOptionsForClone();
      const clone = this.get('store').createRecord('dashboard', options);
      const notifications = this.get('notifications'); // clone unsaved changes

      const dashboardInstance = this.get('workspace.dashboardInstance');
      const workspaceId = this.get('workspace.dashboard.id');
      const includeWidgetStates = true;
      let dashboard;

      if (!this.get('isPublic')) {
        const privateWorkspaceName = `workspaces-${userId}`;
        const privateCategory = this.get('store').peekAll('cs-config-category').find(category => category.get('path') === privateWorkspaceName);
        clone.set('category', privateWorkspaceName);
        dashboard = yield dashboardAdapter.fetchConfigItemsByCategory(store, privateWorkspaceName);

        if (!Ember.isPresent(dashboard.data) && !Ember.isPresent(privateCategory)) {
          yield dashboardAdapter.postUserCategory();
        }
      }

      clone.set('userId', userId);
      clone.commitInstance(dashboardInstance, includeWidgetStates); // update name using value in input box

      clone.set('name', this.get('name'));

      try {
        yield clone.save();
        yield this.get('workspace').popState('saveWorkspaceAs');
        yield this.get('router').transitionTo('dashboard', clone);
        notifications.success(`Successfully Saved Workspace ${this.get('name')}`);
        this.get('tooltip').reset();
        Ember.run.next(async () => {
          this.get('store').peekRecord('dashboard', workspaceId).rollbackAttributes();
        });
      } catch (e) {
        const defaultWarningMessage = `Failed to Save Workspace ${this.get('name')}`;
        const errorCode = Ember.isPresent(e.errors.get('firstObject').status) ? e.errors.get('firstObject').status : null;

        if (errorCode === 409) {
          notifications.warning(`${defaultWarningMessage} - Workspace name already exists`);
        } else {
          notifications.warning(defaultWarningMessage);
        }

        clone.rollbackAttributes();
      }
    }).drop(),
    cancelTask: (0, _emberConcurrency.task)(function* () {
      // modal 'X' button can be clicked at any time, even during load
      this.get('findUnusedNameTask').cancelAll();
      this.get('saveTask').cancelAll();
      yield this.get('workspace').popState('saveWorkspaceAs');
    }).drop(),

    onKeyDown(event) {
      switch (event.key) {
        case 'Enter':
          {
            event.preventDefault();
            return this.get('saveTask').perform();
          }

        default:
          return null;
      }
    }

  });

  _exports.default = _default;
});