define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/body/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/body/validation", "adept-iq/config/placeholders", "lodash", "adept-iq/utils/guid"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset, _emberChangesetValidations, _validation, _placeholders, _lodash, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['vehicle-lookup-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // placeholder
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    tableRef: null,
    description: null,
    editAPI: null,
    disableForm: true,
    showErrorMsg: false,
    currentDisplayName: null,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    vehicleBodyTypeChangeSet: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),

    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleBodyTypeRecord = this.get('onSaveVehicleBodyTypeRecord');
      const onNewVehicleBodyTypeRecord = this.get('onNewVehicleBodyTypeRecord').bind(this);
      const onEditVehicleBodyTypeRecord = this.get('onEditVehicleBodyTypeRecord').bind(this);
      const refreshTask = this.get('refreshTask');
      const onUndoVehicleBodyTypeRecord = this.get('onUndoVehicleBodyTypeRecord').bind(this);
      const hasRelationship = this.get('hasRelationship').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleBodyTypeRecord,
          newAction: onNewVehicleBodyTypeRecord,
          editAction: onEditVehicleBodyTypeRecord,
          undoAction: onUndoVehicleBodyTypeRecord,
          deleteAction: null,
          hasChanges,
          hasRelationship,
          onValidateAction,
          refreshTask
        });
        refreshTask.perform();
      });
    },

    willDestroyElement() {
      const records = this.get('tableRef.records') || [];
      records.map(record => {
        if (record.get('tableRef')) {
          record.set('tableRef');
        }
      });

      this._super(...arguments);
    },

    hasPendingChanges: Ember.computed('vehicleBodyTypeChangeSet.changes.length', function () {
      const vehicleBodyTypeChangeSet = this.get('vehicleBodyTypeChangeSet');
      const changes = vehicleBodyTypeChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      return this.get('hasPendingChanges');
    },

    hasRelationship() {
      const store = this.get('store');
      const tooltip = this.get('tooltip');
      const description = this.get('vehicleBodyTypeChangeSet.description') || ''; // in the future we should actually use an rql query to figure out which vehicles are using this BodyType
      // and have the backend process it for us, currently and name field and description can be different though,
      // so we might need to think about it this a bit more

      const vehicleTypes = store.peekAll('vehicle-type').filter(record => record.get('vehicleBodyType.description') === description);

      if (vehicleTypes.length) {
        tooltip.pushConfirmation({
          title: 'Lookup',
          hasoverlay: true,
          tip: `There are ${vehicleTypes.length} active vehicle(s) with this Vehicle Body Type description. You must either change the Vehicle Body Type description on these vehicle(s) or delete these vehicle(s) prior to changing the Vehicle Body Type description.`,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.reset();
          }

        });
      }

      return vehicleTypes.length;
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const tableRef = this.get('tableRef');
      let vehicleBodyTypeObj = this.get('pendingRecord');

      if (record) {
        vehicleBodyTypeObj = record;
        this.resetForm();
      } else if (Ember.isEmpty(record)) {
        const data = {
          category: 'config-System_Configuration-vehicle_body_types',
          type: 'object'
        };
        vehicleBodyTypeObj = store.createRecord('cs-config-item', data);
        this.set('pendingRecord', vehicleBodyTypeObj);
      }

      if (vehicleBodyTypeObj.get('isNew')) {
        vehicleBodyTypeObj.setProperties({
          description: null,
          tableRef: tableRef
        });
      }

      this.set('showErrorMsg', false);
      const tableRefRecords = this.get('tableRef.records') || [];
      tableRefRecords.map(tableRefRecord => {
        if (!tableRefRecord.get('tableRef')) {
          tableRefRecord.set('tableRef', tableRef);
        }
      });
      return new _emberChangeset.default(vehicleBodyTypeObj, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('vehicleBodyTypeChangeSet');

      if (selectedRecord) {
        if (!selectedRecord.get('isNew') && !selectedRecord.get('isDeleted')) {
          yield selectedRecord.data.reload();
        }
      }

      const tableRef = this.get('tableRef');
      this.resetForm();
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        editAPI.buttonSettings();
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const VehicleBodyTypeRecord = this.get('vehicleBodyTypeChangeSet');
      yield VehicleBodyTypeRecord.validate();
      const errors = VehicleBodyTypeRecord.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveVehicleBodyTypeRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');
      const activityIdGuid = (0, _guid.guid)();
      if (!isValid) return;
      const notifications = this.get('notifications');
      const description = this.get('vehicleBodyTypeChangeSet.description') || '';
      const value = {
        description: description
      };
      const nameGuidConverted = `${description}-${activityIdGuid}`;
      const name = (0, _lodash.camelCase)(nameGuidConverted);
      const vehicleBodyTypeChangeSet = this.get('vehicleBodyTypeChangeSet');
      let vehicleBodyTypeRecord = this.get('vehicleBodyTypeChangeSet');

      if (vehicleBodyTypeRecord.get('isNew')) {
        vehicleBodyTypeRecord = (0, _unwrapProxy.unwrapProxy)(this.get('vehicleBodyTypeChangeSet'));
        vehicleBodyTypeRecord.set('name', name);
      } else {
        const id = this.get('vehicleBodyTypeChangeSet.id');
        const store = this.get('store');
        const record = `config-System_Configuration-vehicle_body_types/${id}`;
        vehicleBodyTypeRecord = store.peekRecord('cs-config-item', record);
      }

      vehicleBodyTypeRecord.set('displayName', description);
      vehicleBodyTypeRecord.set('description', description);
      vehicleBodyTypeRecord.set('value', value);

      try {
        yield vehicleBodyTypeRecord.save(); // changeset holds changes, execute the changes due to lookups using unorthodox saving practice
        // execute to change underlying object and rollback to remove internal changes list

        vehicleBodyTypeChangeSet.execute();
        vehicleBodyTypeChangeSet.rollback();
        notifications.success('Lookup record successfully saved.');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
            tooltip.reset();
          });
        }, 500);
      } catch (response) {
        if (response.errors[0].status === '409') {
          tooltip.pushConfirmation({
            title: 'Manage Driver/Vehicle',
            tip: 'Description cannot duplicate an existing Description.',
            primaryActionText: 'OK',
            primaryAction: () => {
              return tooltip.reset();
            }
          });
        } else {
          notifications.warning('Lookup record failed to save.');
        }

        if (vehicleBodyTypeRecord.rollbackAttributes) {
          vehicleBodyTypeRecord.rollbackAttributes();
        }

        console.warn(response); // eslint-disable-line
      }
    }).drop(),

    onEditVehicleBodyTypeRecord() {
      const editAPI = this.get('editAPI');
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onUndoVehicleBodyTypeRecord() {
      const vehicleBodyTypeChangeSet = this.get('vehicleBodyTypeChangeSet');
      const editAPI = this.get('editAPI');

      if (vehicleBodyTypeChangeSet) {
        vehicleBodyTypeChangeSet.rollback();
      }

      this.set('disableForm', true);
      editAPI.buttonSettings(false, false, false, true, true);
    },

    onNewVehicleBodyTypeRecord() {
      const editAPI = this.get('editAPI');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    actions: {
      onSaveVehicleBodyTypeRecord() {
        return this.onSaveVehicleBodyTypeRecord.perform();
      },

      onInputValueChange(record, valuePath, value) {
        this.set('currentDisplayName', value);
        record.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});