define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/tables/maintenance/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/tables/maintenance/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['maintenance-table'],
    config: _config.default,
    store: Ember.inject.service(),
    enableWarningRowChangeAlert: true,

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    }

  });

  _exports.default = _default;
});