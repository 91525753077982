define("adept-iq/models/dwh-route", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    routeName: attr('string'),
    routeStatus: attr('string'),
    status: attr('string'),
    provider: attr('string'),
    tenantId: attr('number'),
    driverId: attr('string'),
    vehicleName: attr('string'),
    driverPhoneNumber: attr('string'),
    driverFirstName: attr('string'),
    driverLastName: attr('string'),
    type: attr('string'),
    plannedStartTime: attr('date'),
    plannedEndTime: attr('date'),
    actualstarttime: attr('date'),
    actualendtime: attr('date'),
    breaks: attr('array'),
    trips: attr('array'),
    startGarage: attr('object'),
    endGarage: attr('object'),
    reconcileNotes: attr('string'),
    dwhTrips: hasMany('dwh-trip', {
      inverse: 'dwhRoute'
    }),
    dwhStops: hasMany('dwh-stop-point', {
      inverse: 'dwhRoute'
    })
  });

  _exports.default = _default;
});