define("adept-iq/services/road-supervisor", ["exports", "ember-concurrency", "moment", "adept-iq/classes/road-supervisor-instance", "adept-iq/classes/road-supervisor-api", "adept-iq/classes/road-supervisor-special-route-api", "adept-iq/config/environment"], function (_exports, _emberConcurrency, _moment, _roadSupervisorInstance, _roadSupervisorApi, _roadSupervisorSpecialRouteApi, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RoadSupervisorError = void 0;

  class RoadSupervisorError extends Error {
    constructor(message) {
      super(message);
      this.name = this.constructor.name;
    }

  }

  _exports.RoadSupervisorError = RoadSupervisorError;
  let id = 1;
  const nullInstance = {
    onGPSLocation() {}

  };
  const nullAddress = {
    displayNavigateToAddress() {}

  };

  var _default = Ember.Service.extend({
    addressService: Ember.inject.service('address'),
    user: Ember.inject.service(),
    gps: Ember.inject.service('gps'),
    speech: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    instance: nullInstance,
    address: nullAddress,
    isNavigating: false,
    rsVehicle: null,

    init() {
      this._super(...arguments);

      const user = this.get('user');

      if (user.isRoadSupEnable()) {
        const onGPSLocation = Ember.run.bind(this, 'onGPSLocation');
        this.gps.onLocationChange(onGPSLocation);
        this.api = _environment.default.APP.avlmLite ? _roadSupervisorApi.default.getSingleton() : _roadSupervisorSpecialRouteApi.default.getSingleton();
        this.set('rsVehicle', this.store.createRecord('road-supervisor-vehicle'));

        if (!_environment.default.APP.avlmLite) {
          // API is not a container, can't use store service.
          this.api.setStore(this.get('store'));
          this.api.setAddressService(this.get('addressService'));
          this.fetchSchedulesTask.perform().then(() => {
            this.api.setSchedule(this.get('schedules').firstObject);
            this.api.initRoute();
          });
        }
      }
    },

    getLocation() {
      const cord = this.api.get('cord');
      return {
        lat: cord[0],
        lon: cord[1]
      };
    },

    onGPSLocation(val) {
      const timestamp = val.timestamp;
      const lat = val.coords.latitude;
      const lng = val.coords.longitude;
      const heading = val.coords.heading;
      const speed = val.coords.speed;
      const rsVehicle = this.get('rsVehicle');
      rsVehicle.setProperties({
        lat,
        lng,
        heading,
        timestamp
      }); // Always update location, we need latest timestamp

      this.api.setLocationHistory(lat, lng, speed, heading, timestamp);
      this.instance.onGPSLocation(val);
      this.api.setLocation(val);
    },

    // don't interrupt, but only keep the latest queued instruction
    _speechTask: (0, _emberConcurrency.task)(function* (text) {
      return yield this.get('speech').speak(text);
    }).keepLatest(),

    onSpeechEvent(text) {
      if (this.get('isNavigating')) {
        this._speechTask.perform(text);
      }
    },

    onLogoutPerformPullIn() {
      return this.api.clearWayPoint(false);
    },

    onAtDestinationEvent() {
      return this.api.clearWayPoint(true);
    },

    getRSConfigurations() {
      const configItens = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-RoadSupervisor_Configuration';
      });
      var configurations = {};
      configItens.forEach(item => {
        configurations[item.get('name')] = item.get('value');
      });
      return configurations;
    },

    // TBD if we are using this to determine provider
    jwtProviderNames: Ember.computed('session.data.authenticated.token', function () {
      const token = this.get('session.data.authenticated.token');
      const tokenInfo = JSON.parse(atob(token.split('.')[1]));
      return tokenInfo.providerNames;
    }),

    handleError(err) {
      if (err instanceof RoadSupervisorError) {
        alert(err.message); // eslint-disable-line no-alert
      } else {
        throw err;
      }
    },

    async navigateToAddress(address) {
      this.set('isNavigating', true); // fuzzy-matched addresses are not persisted until needed

      if (_environment.default.APP.avlmLite && address.get('isNew')) {
        // address POST is not working
        // return address.save();
        address.set('id', '' + id++);
      } else {
        const waypoint = await this.get('addressService').createWayPoint(address);
        return this._buildInstanceWithWaypoint(waypoint);
      }

      return this._buildInstanceWithWaypoint(address);
    },

    displayNavigateToAddress(address) {
      return this._buildInstanceWithWaypointAddress(address);
    },

    navigateToStop(iqStop) {
      this.set('isNavigating', true);
      const lat = iqStop.get('lat');
      const lng = iqStop.get('lng');
      const fullAddress = _environment.default.APP.avlmLite ? iqStop.get('place.fullAddress') : iqStop.get('place.address.fullAddress');
      return Ember.RSVP.resolve().then(() => {
        if (_environment.default.APP.avlmLite) {
          return this._queryAddress(`${lat},${lng}`, {
            lat,
            lng
          });
        }

        return this.get('addressService').copyAddress(iqStop.get('place.address'));
      }).then(address => this.navigateToAddress(address)).then(() => this.displayNavigateToAddress(fullAddress)).catch(this.handleError);
    },

    navigateToAddressFromMap(address) {
      this.set('isNavigating', true);
      return Ember.RSVP.resolve().then(() => this.navigateToAddress(address)).then(() => this.displayNavigateToAddress(address.fullAddress)).catch(this.handleError);
    },

    navigateToVehicle(vehicle) {
      throw new RoadSupervisorError('navigating to a vehicle is disabled until further notice');
      return this._buildInstanceWithWaypoint(vehicle); // eslint-disable-line no-unreachable
    },

    enter() {
      return Promise.resolve();
    },

    exit() {
      this.api.clearWayPoint();
      const instance = this.get('instance');
      this.set('instance', nullInstance);

      if (instance !== nullInstance) {
        instance.destroy();
      }

      return this.get('workspace').popState('roadSupervisor');
    },

    // FIXME: this seems to be unused?
    // getAccessData() {
    //   return getAccessData(this.user.userId);
    // },
    async _queryAddress(addressString) {
      try {
        const addresses = await this.get('addressService').fuzzySearch(addressString, {
          limit: 1
        });
        const address = addresses.get('firstObject');

        if (!address) {
          this.set('isNavigating', false);
          throw new RoadSupervisorError('no matching address found');
        }

        return address;
      } catch (err) {
        throw new RoadSupervisorError(err);
      }
    },

    fetchSchedulesTask: (0, _emberConcurrency.task)(function* () {
      const filterQuery = 'eq(type,\'primary\')';
      let schedules = yield this.store.query('schedule', {
        filter: filterQuery
      });
      yield this.store.findAll('dispatchSchedule');
      if (!schedules) return null;
      const now = new Date().getTime();
      schedules = schedules.filter(schedule => {
        const start = (0, _moment.default)(schedule.get('start'));
        const end = (0, _moment.default)(schedule.get('end'));
        return start.isBefore(now) && end.isAfter(now);
      }).sortBy('name');
      this.set('schedules', schedules);
    }),

    _buildInstanceWithWaypointAddress(address) {
      this.set('address', address);
    },

    _buildInstanceWithWaypoint(waypoint) {
      let instance = this.get('instance');

      if (instance === nullInstance) {
        instance = _roadSupervisorInstance.default.create({
          api: this.api,
          delegate: this
        });
        this.set('instance', instance);
      }

      if (!this.workspace.isRoadSupervisorModeEnabled) {
        this.workspace.pushState('roadSupervisor');
      }

      Ember.RSVP.resolve().then(() => this.api.setWayPoint(waypoint));
    }

  });

  _exports.default = _default;
});