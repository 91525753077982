define("adept-iq/pods/components/workspace-navbar/component", ["exports", "ember-concurrency", "adept-iq/config/environment", "adept-iq/mixins/async-initiation-operation", "lodash", "moment"], function (_exports, _emberConcurrency, _environment, _asyncInitiationOperation, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    or,
    equal,
    and
  } = Ember.computed;
  const DEFAULT_TERM = 'default';
  const BOOKING_TIMELINE_CONFIG_PATH = 'config-Book_Trips/Future_Booking_Timeline';

  var _default = Ember.Component.extend(_asyncInitiationOperation.default, {
    classNames: ['workspace-navbar'],
    classNameBindings: ['reoptimizeInProgress', 'isExported'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    notifications: Ember.inject.service(),
    widget: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    schedules: null,
    scheduleDate: null,
    isReoptimizeOpen: false,
    minScheduleDate: null,
    isReconcileCsvOnly: true,
    showNotificationFailureResults: false,
    responseSourceIds: null,
    currentScheduleAdvanceNotificationSent: false,
    // TODO: saved in schedule table
    notificationEnabled: false,
    isScheduleDashboard: readOnly('workspace.isScheduleDashboard'),
    isDispatchDashboard: readOnly('workspace.isDispatchDashboard'),
    isBookingDashboard: readOnly('workspace.isBookingDashboard'),
    isRiderManagementDashboard: readOnly('workspace.isRiderManagementDashboard'),
    currentSchedule: readOnly('workspace.currentSchedule'),
    isExported: readOnly('workspace.isExported'),
    operationType: readOnly('workspace.operationType'),
    reoptimizeInProgress: or('workspace.reoptimizeInProgress'),
    systemConfigInProgress: or('workspace.systemConfigInProgress'),
    disableReoptimizeBtn: Ember.computed('isExported', 'reoptimizeInProgress', 'currentSchedule', function () {
      const currentSchedule = this.get('currentSchedule');
      if (Ember.isEmpty(currentSchedule)) return true;
      return this.get('isExported') || this.get('reoptimizeInProgress');
    }),
    isExporting: equal('operationType', 'export'),
    isBrokerExporting: equal('operationType', 'brokerExport'),
    isExportedAndEnabled: and('isExported', 'notificationEnabled'),

    async init() {
      this._super(...arguments);

      const {
        value: notificationEnabled
      } = _environment.default.APP.test ? {
        value: false
      } : await this.get('store').findRecord('cs-config-item', 'config-System_Configuration-notifications-advance_trip_reminder/enabled');
      this.set('notificationEnabled', notificationEnabled);
      this.set('minScheduleDate', (0, _moment.default)().startOf('day').add(1, 'day').toISOString());
    },

    didInsertElement() {
      this._super(...arguments);

      this.refreshTask.perform();
    },

    canSendAdvanceNotification: Ember.computed('workspace.currentSchedule', function () {
      const currentSchedule = this.get('currentSchedule');

      if (Ember.isNone(currentSchedule)) {
        return false;
      }

      const scheduleStart = (0, _moment.default)(currentSchedule.get('start'));
      const currentTime = (0, _moment.default)();
      const canSend = currentTime.isBefore(scheduleStart);
      return canSend;
    }),
    workspaceName: Ember.computed('workspace.dashboardModel.{category,role,name}', function () {
      const dashboard = this.get('workspace.dashboardModel');

      if (dashboard) {
        const dashboardRole = dashboard.get('role');
        const dashboardName = dashboard.get('name');
        const category = dashboard.get('category');

        if (category && category.includes(DEFAULT_TERM)) {
          return `${dashboardRole} [default]`;
        } else if (dashboardName && dashboardRole) return `${dashboardRole} - ${dashboardName}`;

        return dashboardRole;
      }

      return null;
    }),
    // in used by release schedule / reoptimize
    inProgressTitle: Ember.computed('reoptimizeInProgress', 'isExported', 'isExporting', 'isBrokerExporting', function () {
      const isExported = this.get('isExported');
      const isExporting = this.get('isExporting');
      const isBrokerExporting = this.get('isBrokerExporting');
      if (isExporting || isBrokerExporting) return 'RELEASE ROUTES IN PROGRESS';else if (isExported) return '';
      return 'REOPTIMIZATION IN PROGRESS';
    }),
    // in used by release schedule / reoptimize
    progress: Ember.computed('workspace.progressValue', function () {
      const progressValue = this.get('workspace.progressValue');

      if (progressValue) {
        // reoptimize progress value should not greater than 100
        return progressValue > 100 ? 100 : progressValue;
      }

      return 0;
    }),
    maxScheduleDate: Ember.computed('bookingWindow.value', function () {
      const bookingWindowVal = parseInt(this.get('bookingWindow.value'), 10);
      return bookingWindowVal ? (0, _moment.default)().endOf('day').add(bookingWindowVal, 'days').toDate() : null;
    }),
    refreshTask: (0, _emberConcurrency.task)(function* () {
      // incase today ever stretches into the next day, this will update once the user moves to that date
      // thus today -> tomorrow, and booking window will also slide forward
      this.get('fetchBookingWindow').perform();
      this.get('fetchSchedulesTask').perform();
      this.set('minScheduleDate', (0, _moment.default)().startOf('day').add(1, 'day').toISOString());
      yield;
    }),
    fetchBookingWindow: (0, _emberConcurrency.task)(function* () {
      const config = yield this.store.findRecord('cs-config-item', BOOKING_TIMELINE_CONFIG_PATH);

      if (config) {
        this.set('bookingWindow', config);
      }
    }),
    fetchSchedulesTask: (0, _emberConcurrency.task)(function* () {
      const filterQuery = 'eq(type,\'primary\')';
      let schedules = yield this.store.query('schedule', {
        filter: filterQuery
      });

      if (schedules) {
        schedules = schedules.sortBy('end');
      }

      this.set('schedules', schedules);
    }),

    async reInitializeStore() {
      const store = this.store;
      const workspace = this.get('workspace');
      const topActiveContext = this.get('activeContext.topActiveContext');
      const notifications = this.get('notifications'); // clear active context traversal

      Ember.run.scheduleOnce('actions', topActiveContext, 'reset'); // dump all widget data

      topActiveContext.trigger('clear');
      await notifications.get('monitorForEmergencyTask').cancelAll(); // dump ember store

      await Ember.run.scheduleOnce('destroy', store, 'init');
      await Ember.run.next(() => {
        store.findAll('cs-config-category');
        store.findAll('cs-config-item');
        store.findAll('route-template');
        store.findAll('cs-config-permission').then(configPermissions => {
          return workspace.set('configPermissions', configPermissions.get('firstObject.value'));
        });
        notifications.get('monitorForEmergencyTask').perform();
      });
    },

    async updateSchedulingWorkspaceTimeRange(date) {
      const momentDate = (0, _moment.default)(date);
      await this.reInitializeStore();
      await Ember.run.later(() => {
        this.workspace.setProperties({
          startDate: momentDate.startOf('day').toDate(),
          endDate: momentDate.endOf('day').toDate()
        });
        this.refreshTask.perform();
      }, 1000); // delay because of reInitializeStore.
    },

    async validateAndReoptimize(currentSchedule) {
      const tooltip = this.get('tooltip');
      const widget = this.get('widget');
      const widgetInstanceId = widget.GLOBAL_WIDGET_INST;
      const modelName = 'routeWidget';
      const offset = 0;
      const limit = 500;
      const sort = null;
      const dateQueryFields = widget.getDateQueryFields();
      const selectedProviders = widget.getAllProviders();
      const isLocked = true; // get locked records for this schedule

      const lockedRoutes = await widget.requestWidgetApi(widgetInstanceId, modelName, null, offset, limit, sort, dateQueryFields, selectedProviders, isLocked);

      if (Ember.isPresent(lockedRoutes) && lockedRoutes.length > 0) {
        tooltip.pushConfirmation({
          title: 'Reoptimize',
          tip: 'Unable to reoptimize because one or more routes are currently locked.',
          warning: true,
          primaryActionText: 'Ok',

          primaryAction() {
            tooltip.reset();
          }

        });
        return;
      }

      this.get('workspace').pushState('reoptimize', {
        currentSchedule
      });
    },

    performPostAdvanceTripReminder: (0, _emberConcurrency.task)(function* (currentSchedule) {
      const activityLogAdapter = this.get('store').adapterFor('activity-log');
      const activity = 'Notification Sent';
      const details = 'Sent out Advance Trip Reminders.';
      const scheduleDate = (0, _moment.default)(currentSchedule.get('start')).startOf('day');
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const options = {
        outputResult: true
      };

      try {
        const scheduleTag = `schedule-${(0, _moment.default)(scheduleDate).format('YYYY-MM-DD')}`;
        const {
          isJobSuccess,
          results
        } = yield this.createInitiationAsyncOperation.perform(scheduleDate, null, null, 'advanceTripRemindersGroupedByTextToSpeech', [scheduleTag], options);
        activityLogAdapter.createUserActivityLog(activity, details);

        if (isJobSuccess) {
          if (!currentSchedule.get('isNotificationInitiated')) {
            currentSchedule.set('notificationStatus', 'initiated');
            currentSchedule.save();
          }

          const response = JSON.parse(results);
          this.set('responseSourceIds', _lodash.default.get(response, 'data.attributes.sourceIds', {}));
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          this.actions.onShowAdvanceNotificationStatus.call(this);
          return;
        }

        this.get('notifications').warning('Processing Advance Trip Reminder failed.');
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      } catch (error) {
        console.log(error.message); // eslint-disable-line no-console

        this.get('notifications').warning('Processing Advance Trip Reminder failed.');
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    }).drop(),

    changeModalZIndex(zindex) {
      const modalPopup = Ember.$('.ember-modal-overlay.translucent.tooltip-modal-styles-overlay');
      modalPopup.css('z-index', zindex);
    },

    actions: {
      onUpdateTimeRangeScheduleWorkspace(selectedDate) {
        const currentWorkspaceDate = this.get('workspace.startDate');

        if (!(0, _moment.default)(selectedDate).isSame(currentWorkspaceDate)) {
          this.updateSchedulingWorkspaceTimeRange(selectedDate);
        }
      },

      openReoptimizeModal() {
        const currentSchedule = this.get('currentSchedule');
        this.validateAndReoptimize(currentSchedule);
      },

      onSendAdvanceNotification() {
        const currentSchedule = this.get('currentSchedule');
        this.performPostAdvanceTripReminder.perform(currentSchedule);
      },

      onShowAdvanceNotificationStatus() {
        this.changeModalZIndex(90);
        this.set('showNotificationResults', true);
      },

      onNotificationResultsClose() {
        this.set('showNotificationResults', false);
        this.set('responseSourceIds', null);
        this.changeModalZIndex(9001);
      }

    }
  });

  _exports.default = _default;
});