define("adept-iq/pods/components/generic-widgets/chartjs-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['analytics-widget'],
    colors: Ember.computed('', function () {
      return ['#D6E1EA', '#B8AFC0', '#D3DDE6', '#BADCF8', '#E3EBF0', '#CFD5DD', '#D6DEE3'];
    }),
    warningColor: Ember.computed('', function () {
      return '#BADCF8';
    }),
    urgentColor: Ember.computed('', function () {
      return '#B8AFC0';
    })
  });

  _exports.default = _default;
});