define("adept-iq/models/trip", ["exports", "ember-data", "moment", "adept-iq/utils/format-text-extension", "adept-iq/mixins/otp-label", "adept-iq/models/stop-point", "adept-iq/utils/unwrapProxy", "adept-iq/config/environment"], function (_exports, _emberData, _moment, _formatTextExtension, _otpLabel, _stopPoint, _unwrapProxy, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TRIP_WILLCALL_STATUS = _exports.TRIP_WAITLIST_STATUS = _exports.TRIP_UNSCHEDULED_STATUS = _exports.TRIP_SCHEDULED_STATUS = _exports.TRIP_REQUESTED_STATUS = _exports.TRIP_NOSHOW_STATUS = _exports.TRIP_NOSHOWREQUESTED_STATUS = _exports.TRIP_LATE_CANCELED_STATUS = _exports.TRIP_LATECANCELED_STATUS = _exports.TRIP_ILLEGAL_STATUS = _exports.TRIP_FAILED_STATUS = _exports.TRIP_ENROUTE_STATUS = _exports.TRIP_DISPATCH_STATUS = _exports.TRIP_COMPLETED_STATUS = _exports.TRIP_CANCELED_STATUS = _exports.TRIP_CALLBACK_STATUS = _exports.TRIP_ARCHIVED_STATUS = _exports.TRIP_ACTIVE_STATUS = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  // TRIP STATUS Notes
  // Requested :  New booking (eligibility verified) and Client (NYCT) agrees to fulfil the trip.
  // Promised :  time Promised (not scheduled) using Demand Smoother.
  // Scheduled : Time Promised and Assigned to Route & Provider (Service Mode / booking with eHail API)
  // Waitlisted : Trip could not be assigned to a route  - either it failed auto scheduling or dispatcher manually Waitlist-ed a Trip.
  // Dispatched : Route on which Trip was assigned now assigned to Vehicle / Driver
  // En route : Trip's route has started but the vehicle is not yet at the trip's pick up stop. Trip not started. (OTW to perform a pick)
  // Active : Pick performed
  // Completed : Drop performed
  // No showed  : Trip's pickup stop was saved as no-show
  // No show requested: driver has requested that dispatch save trip as noshow
  // Callback : More info needed from the passenger to confirm the Booking. Booking is saved and results in a Trip but not Scheduled yet.
  // Will call : Booking failed to be acknowledged and no Promise time added. Trip is saved in IQ but not yet scheduled. an orphaned booking.
  // Failed : Route or Trip failed for other reasons. Can be system failure. Need System Error codes to define this Status further.
  // Canceled : Trip was canceled
  // Late canceled : Trip was cancelled late (as per config definition)
  // Archived: TBD Driver/dispatcher has ended the trip's route.
  // Illegal : no vehicle to service the trip or Max Ride time violated or Leg of A (was on Voucher first or Broker, and then comes back to ADEPT to schedule) forcing promise time of Leg B to be violated
  // The following trip statuses will be displayed in IQ for version R4.x:
  // Scheduled: trip has a route ID and is not completed. In R4.x version only scheduled trips are exported from ADEPT 4.
  // Waitlisted: trip has no route ID
  // Active: the trip's pick stop has been arrived and departed
  // Completed: the trip's drop stop has been arrived and departed
  // Canceled: Trip was canceled. In R4.x version the trip must be cancelled in ADEPT 4 and then the status is sent to IQ.
  // Illegal: engine tried but was unable to schedule the trip.
  const convertStatusDisplay = {
    scheduled: 'Scheduled',
    requested: 'Requested',
    promised: 'Promised',
    dispatched: 'Dispatched',
    active: 'Active',
    completed: 'Completed',
    callback: 'Callback',
    willCall: 'Will call',
    failed: 'Failed',
    canceled: 'Canceled',
    cancelled: 'Canceled',
    latecanceled: 'Late canceled',
    lateCancelled: 'Late canceled',
    'late canceled': 'Late canceled',
    archived: 'Archived',
    unscheduled: 'Unscheduled',
    waitlisted: 'Waitlisted',
    illegal: 'Illegal',
    noShowRequest: 'No Show Request',
    noShow: 'No Show',
    'No Show': 'No Show',
    'noshow': 'No Show',
    'no showed': 'No Show',
    'En Route': 'En Route',
    'en route': 'En Route',
    'A': 'Active',
    'C': 'Completed'
  };
  const otpStatusMapper = {
    'O': 'On Time',
    'D': 'In Danger',
    'L': 'Late',
    'E': 'Early'
  }; // Trip status coming from iqux service based on route exec events

  const TRIP_CANCELED_STATUS = 'Canceled';
  _exports.TRIP_CANCELED_STATUS = TRIP_CANCELED_STATUS;
  const TRIP_LATE_CANCELED_STATUS = 'Late canceled';
  _exports.TRIP_LATE_CANCELED_STATUS = TRIP_LATE_CANCELED_STATUS;
  const TRIP_WAITLIST_STATUS = 'Waitlisted';
  _exports.TRIP_WAITLIST_STATUS = TRIP_WAITLIST_STATUS;
  const TRIP_NOSHOW_STATUS = 'No showed';
  _exports.TRIP_NOSHOW_STATUS = TRIP_NOSHOW_STATUS;
  const TRIP_SCHEDULED_STATUS = 'Scheduled';
  _exports.TRIP_SCHEDULED_STATUS = TRIP_SCHEDULED_STATUS;
  const TRIP_UNSCHEDULED_STATUS = 'Unscheduled';
  _exports.TRIP_UNSCHEDULED_STATUS = TRIP_UNSCHEDULED_STATUS;
  const TRIP_ILLEGAL_STATUS = 'Illegal';
  _exports.TRIP_ILLEGAL_STATUS = TRIP_ILLEGAL_STATUS;
  const TRIP_ENROUTE_STATUS = 'En route';
  _exports.TRIP_ENROUTE_STATUS = TRIP_ENROUTE_STATUS;
  const TRIP_ACTIVE_STATUS = 'Active';
  _exports.TRIP_ACTIVE_STATUS = TRIP_ACTIVE_STATUS;
  const TRIP_COMPLETED_STATUS = 'Completed';
  _exports.TRIP_COMPLETED_STATUS = TRIP_COMPLETED_STATUS;
  const TRIP_NOSHOWREQUESTED_STATUS = 'No show requested';
  _exports.TRIP_NOSHOWREQUESTED_STATUS = TRIP_NOSHOWREQUESTED_STATUS;
  const TRIP_CALLBACK_STATUS = 'Callback';
  _exports.TRIP_CALLBACK_STATUS = TRIP_CALLBACK_STATUS;
  const TRIP_WILLCALL_STATUS = 'Will call';
  _exports.TRIP_WILLCALL_STATUS = TRIP_WILLCALL_STATUS;
  const TRIP_FAILED_STATUS = 'Failed';
  _exports.TRIP_FAILED_STATUS = TRIP_FAILED_STATUS;
  const TRIP_LATECANCELED_STATUS = 'Late canceled';
  _exports.TRIP_LATECANCELED_STATUS = TRIP_LATECANCELED_STATUS;
  const TRIP_ARCHIVED_STATUS = 'Archived';
  _exports.TRIP_ARCHIVED_STATUS = TRIP_ARCHIVED_STATUS;
  const TRIP_DISPATCH_STATUS = 'Dispatched';
  _exports.TRIP_DISPATCH_STATUS = TRIP_DISPATCH_STATUS;
  const TRIP_REQUESTED_STATUS = 'Requested'; // Trip status coming from scheduling trip model

  _exports.TRIP_REQUESTED_STATUS = TRIP_REQUESTED_STATUS;
  const SCHEDULING_TRIP_COMPLETED_STATUS = 'completed';
  const SCHEDULING_TRIP_SCHEDULED_STATUS = 'scheduled';
  const UNSCHEDULING_TRIP_SCHEDULED_STATUS = 'unscheduled';
  const SCHEDULING_TRIP_WAITLISTED_STATUS = 'waitlisted';
  const SCHEDULING_TRIP_NOSHOW_STATUS = 'noShow';
  const SCHEDULING_TRIP_ILLEGAL_STATUS = 'illegal';
  const SCHEDULING_TRIP_CANCELLED_STATUS = 'cancelled';
  const SCHEDULING_TRIP_CALLBACK_STATUS = 'callback';
  const SCHEDULING_LATE_TRIP_CANCELLED_STATUS = 'lateCanceled';
  const MANUAL_SCHEDULING_MODE = 'manual';

  var _default = Model.extend(_otpLabel.default, {
    activeContext: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tripId: attr('string'),
    promiseTime: attr('date'),
    status: attr('string'),
    // 	'unscheduled', 'scheduled', 'waitlisted', 'illegal'
    otp: attr('string'),
    schedulingMode: attr('string'),
    statusReason: attr('string'),
    otpValue: attr('number'),
    plannedDirectDistance: attr('number'),
    currentProviderName: attr('string'),
    schedule: belongsTo('schedule'),
    route: belongsTo('route'),
    subscription: belongsTo('subscription'),
    booking: belongsTo('booking'),
    segment: belongsTo('segment'),
    reason: belongsTo('no-show-reason-category'),
    pick: belongsTo('trip-stop', {
      inverse: 'pickTripStop'
    }),
    drop: belongsTo('trip-stop', {
      inverse: 'dropTripStop'
    }),
    stopPoints: hasMany('stop-point'),
    routeAssignments: hasMany('trip-route-assignment'),
    provider: belongsTo('provider'),
    // populated by trips widget iqux service
    noShowRequestReasonCode: attr('string'),
    paymentType: attr('string'),
    fare: attr('number'),
    maxRideTime: attr('number'),
    failedStatus: attr('string'),
    tripStatus: attr('string'),
    sdbRouteName: attr('string'),
    anchor: Ember.computed.alias('segment.anchor'),
    rider: Ember.computed.alias('segment.leg.rider'),
    routeName: Ember.computed.alias('route.name'),
    dispatchRoute: Ember.computed.alias('pickStopPoint.dispatchRoute'),
    travelNeedCounts: Ember.computed.readOnly('segment.leg.travelNeedCounts'),
    vehicleCapacityCounts: Ember.computed.readOnly('segment.leg.vehicleCapacityCounts'),
    isScheduled: Ember.computed.equal('status', SCHEDULING_TRIP_SCHEDULED_STATUS),
    isUnScheduledTrip: Ember.computed.equal('status', UNSCHEDULING_TRIP_SCHEDULED_STATUS),
    isNoShow: Ember.computed.equal('status', SCHEDULING_TRIP_NOSHOW_STATUS),
    isIllegal: Ember.computed.equal('status', SCHEDULING_TRIP_ILLEGAL_STATUS),
    isCallback: Ember.computed.equal('status', SCHEDULING_TRIP_CALLBACK_STATUS),
    isManualSchedulingMode: Ember.computed.equal('schedulingMode', MANUAL_SCHEDULING_MODE),
    isPickPerformed: Ember.computed.readOnly('pickStopPoint.isPerformed'),
    isDropPerformed: Ember.computed.readOnly('dropStopPoint.isPerformed'),
    isWaitlisted: Ember.computed('status', function () {
      const status = this.get('status') ? this.get('status').toLowerCase() : '';
      return status === SCHEDULING_TRIP_WAITLISTED_STATUS;
    }),
    isCompleted: Ember.computed('status', function () {
      const status = this.get('status') ? this.get('status').toLowerCase() : '';
      if (status === SCHEDULING_TRIP_COMPLETED_STATUS.toLowerCase() || status === TRIP_COMPLETED_STATUS.toLowerCase()) return true;
      return false;
    }),
    computedRouteName: Ember.computed('routeName', 'sdbRouteName', 'isCanceled', 'isWaitlisted', function () {
      const isCanceled = this.get('isCanceled');
      const isWaitlisted = this.get('isWaitlisted');
      const sdbRouteName = this.get('sdbRouteName');
      const routeName = this.get('routeName');
      if (isCanceled || isWaitlisted) return '';
      if (!Ember.isEmpty(sdbRouteName)) return sdbRouteName;
      return routeName;
    }),
    providerName: Ember.computed('sortedRouteAssignments.[]', 'status', function () {
      return this.get('pickStopPoint.dispatchRoute.provider.name') ? this.get('currentRoute.provider.name') : this.get('pickStopPoint.dispatchRoute.provider.name');
    }),
    stops: Ember.computed('pick', 'drop', function () {
      const pick = (0, _unwrapProxy.unwrapProxy)(this.get('pick'));
      const drop = (0, _unwrapProxy.unwrapProxy)(this.get('drop'));
      return [pick, drop];
    }),
    sortedRouteAssignments: Ember.computed('routeAssignments.[]', function () {
      return this.get('routeAssignments').sortBy('ordinal');
    }),
    lastRouteAssignment: Ember.computed('sortedRouteAssignments.[]', function () {
      const routeAssignment = this.get('sortedRouteAssignments').lastObject;
      return routeAssignment;
    }),
    fundingAgencyDisplayName: Ember.computed('segment.leg.fundingAgency', function () {
      const fundingAgency = this.get('segment.leg.fundingAgency');
      return fundingAgency.get('displayName');
    }),
    polyline: Ember.computed('segment.polyline', 'pickStopPoint.{lat,lng}', 'dropStopPoint.{lat,lng,computedStatus}', function () {
      const dropStopPointStatus = this.get('dropStopPoint.computedStatus');
      if (dropStopPointStatus === _stopPoint.NOSHOW_STOP_STATUS) return null;
      const segmentPolyline = this.get('segment.polyline');
      if (segmentPolyline) return segmentPolyline; // segment polyline doesn't exist use, dispatch stop points to generate polyline

      const dropStopPoint = this.get('dropStopPoint');
      const pickStopPoint = this.get('pickStopPoint');

      if ([dropStopPoint, pickStopPoint].any(Ember.isNone)) {
        return null;
      }

      const pickLat = pickStopPoint.get('lat');
      const pickLng = pickStopPoint.get('lng');
      const dropLat = dropStopPoint.get('lat');
      const dropLng = dropStopPoint.get('lng');

      if ([pickLat, pickLng, dropLat, dropLng].any(Ember.isNone)) {
        return null;
      }

      return [[dropLat, dropLng], [pickLat, pickLng]];
    }),
    previousRouteAssignment: Ember.computed('sortedRouteAssignments.[]', 'status', function () {
      const assignmentLength = this.get('sortedRouteAssignments').length;
      let routeAssignment; // sortedRouteAssignments will contain at least one item if the trip was ever scheduled.
      // If the trip is scheduled or no showed then return the next to last assignment.
      // Otherwise, the trip is no longer on a route so return the last assignment.

      if (assignmentLength > 0) {
        routeAssignment = this.get('isScheduled') || this.get('isNoShow') ? this.get('sortedRouteAssignments').slice(0, assignmentLength - 1).lastObject : this.get('sortedRouteAssignments').lastObject;
      }

      return routeAssignment;
    }),
    isCanceled: Ember.computed('status', function () {
      const status = this.get('status') ? this.get('status').toLowerCase() : '';
      return status === SCHEDULING_TRIP_CANCELLED_STATUS.toLowerCase() || status === TRIP_CANCELED_STATUS.toLowerCase() || status === TRIP_LATE_CANCELED_STATUS.toLowerCase() || status === SCHEDULING_LATE_TRIP_CANCELLED_STATUS.toLowerCase();
    }),
    otpValueWithSign: Ember.computed('otp', 'otpValue', function () {
      const otp = this.get('otp');
      const otpValue = this.get('otpValue');
      const sign = otp === 'O' || otp === 'E' || otp === otpStatusMapper.O || otp === otpStatusMapper.E ? '-' : '+';

      if (!Ember.isEmpty(otpValue)) {
        return otpValue !== 0 ? `${sign}${Math.abs(otpValue)}` : Math.abs(otpValue);
      }

      return '';
    }),
    pickStopPoint: Ember.computed('stopPoints', function () {
      return this.get('stopPoints').findBy('stopType', 'pick');
    }),
    actualArriveTime: Ember.computed('pickStopPoint.actualArriveTime', 'id', function () {
      const stoppick = this.get('stopPoints').findBy('stopType', 'pick');

      if (stoppick.actualArriveTime) {
        return (0, _moment.default)(stoppick.actualArriveTime).format(_environment.default.dateTimeFormat.dateTimeMoment);
      }

      return null;
    }),
    dropStopPoint: Ember.computed('stopPoints', function () {
      return this.get('stopPoints').findBy('stopType', 'drop');
    }),
    //this property is used to find any stopoint has partially performed
    isStopPointPerformed: Ember.computed('stopPoints.@each.{isPerformed,isArrived}', function () {
      const performedStopPoints = this.get('stopPoints').filter(stopPoint => stopPoint.get('isPerformed') || stopPoint.get('isArrived'));
      return performedStopPoints.length;
    }),
    noShow: Ember.computed('pickStopPoint.noShow.noShowReasonCode', 'dropStopPoint.noShow.noShowReasonCode', function () {
      const pick = (0, _unwrapProxy.unwrapProxy)(this.get('pickStopPoint.noShow'));
      const drop = (0, _unwrapProxy.unwrapProxy)(this.get('dropStopPoint.noShow'));
      return pick ? pick : drop;
    }),
    ordinalStops: Ember.computed('stops.@each.{ordinal,status}', function () {
      // IQUX-1860 because of an issue in ESS to sent delete record from
      // a cancel or trip transfer, we are just hiding the trip and stop
      // for now
      if (this.get('status') === 'Removed') {
        return [];
      }

      const stops = this.get('stops');
      if (!Ember.isEmpty(stops)) return stops.filter(stop => Ember.isPresent(stop.get('ordinal')));
      return [];
    }),
    formattedTravelNeeds: Ember.computed('segment.leg.legTravelNeeds.[]', function () {
      const equipments = this.get('segment.leg.legTravelNeeds');
      const travelNeedsJson = {};
      let travelNeeds = [];

      if (!Ember.isEmpty(equipments)) {
        equipments.forEach(function (item) {
          const count = item.get('count');
          const name = item.get('travelNeedType.name');
          const displayName = item.get('travelNeedType.displayName');
          if (travelNeedsJson[name]) travelNeedsJson[name].count += count;else travelNeedsJson[name] = {
            count,
            displayName
          };
        });
        travelNeeds = Object.keys(travelNeedsJson).map(item => {
          if (travelNeedsJson[item].count > 0) {
            return (0, _formatTextExtension.formatTextExtension)('valueItem', `${travelNeedsJson[item].count} ${travelNeedsJson[item].displayName} `);
          }

          return (0, _formatTextExtension.formatTextExtension)('valueItem', `${travelNeedsJson[item].displayName} `);
        });
      }

      travelNeeds.push((0, _formatTextExtension.formatTextExtension)('mainItem', ''));
      return {
        values: travelNeeds
      };
    }),
    formattedServiceNeeds: Ember.computed('segment.leg.legServiceNeeds.[]', function () {
      const legServiceNeeds = this.get('segment.leg.legServiceNeeds');
      const serviceNeedsJson = {};
      let serviceNeeds = [];

      if (!Ember.isEmpty(legServiceNeeds)) {
        legServiceNeeds.forEach(function (item) {
          const count = 1;
          const name = item.get('serviceNeedType.name');
          const displayName = item.get('serviceNeedType.displayName');
          if (serviceNeedsJson[name]) serviceNeedsJson[name].count += count;else serviceNeedsJson[name] = {
            count,
            displayName
          };
        });
        serviceNeeds = Object.keys(serviceNeedsJson).map(item => {
          if (serviceNeedsJson[item].count > 1) {
            return (0, _formatTextExtension.formatTextExtension)('valueItem', `${serviceNeedsJson[item].count} ${serviceNeedsJson[item].displayName} `);
          }

          return (0, _formatTextExtension.formatTextExtension)('valueItem', ` ${serviceNeedsJson[item].displayName} `);
        });
      }

      serviceNeeds.push((0, _formatTextExtension.formatTextExtension)('mainItem', ''));
      return {
        values: serviceNeeds
      };
    }),
    noOfTravelNeeds: Ember.computed.alias('formattedTravelNeeds.length'),
    requestTimeForDisplay: Ember.computed('segment.{leg.requestTime,anchor}', function () {
      const segment = this.get('segment');
      const anchor = segment.get('leg.anchor') ? segment.get('leg.anchor') : 'pick';
      const requestTimeObject = segment.get('leg.requestTime');
      const requestDate = (0, _moment.default)(requestTimeObject).format(_environment.default.dateTimeFormat.dateMoment);
      const requestTime = (0, _moment.default)(requestTimeObject).format(_environment.default.dateTimeFormat.timeMoment);
      return `${requestDate} / ${anchor} / ${requestTime}`;
    }),
    legRequestTimeFormat: Ember.computed('segment.leg.requestTime', function () {
      const requestTime = this.get('segment.leg.requestTime');

      if (!Ember.isEmpty(requestTime)) {
        return (0, _moment.default)(requestTime).format(_environment.default.dateTimeFormat.dateTimeMoment);
      }

      return null;
    }),
    tripStartTime: Ember.computed('pick.segmentStop.tripStartTime', 'promiseTime', 'segment.leg.requestTime', function () {
      const pickTime = this.get('pick.content.tripStartTime');
      const requestTime = this.get('segment.leg.requestTime');
      const promiseTime = this.get('promiseTime');

      if (pickTime) {
        return pickTime;
      }

      if (promiseTime) {
        return promiseTime;
      }

      return requestTime;
    }),
    tripEndTime: Ember.computed('drop.segmentStop.tripStartTime', 'promiseTime', 'segment.leg.requestTime', function () {
      const dropTime = this.get('drop.content.tripEndTime');
      const requestTime = this.get('segment.leg.requestTime');
      const promiseTime = this.get('promiseTime');

      if (dropTime) {
        return dropTime;
      }

      if (promiseTime) {
        return promiseTime;
      }

      return requestTime;
    }),
    routeTripStartTime: Ember.computed('tripStartTime', 'dispatchRoute.tripStartTime', function () {
      const routeStart = this.get('dispatchRoute.tripStartTime');
      const tripStart = this.get('tripStartTime');

      if (routeStart) {
        return routeStart;
      }

      return tripStart;
    }),
    routeTripEndTime: Ember.computed('tripEndTime', 'dispatchRoute.tripEndTime', function () {
      const routeEnd = this.get('dispatchRoute.tripEndTime');
      const tripEnd = this.get('tripEndTime');

      if (routeEnd) {
        return routeEnd;
      }

      return tripEnd;
    }),
    isUnscheduled: Ember.computed('computedStatus', function () {
      const status = this.get('computedStatus').toLowerCase(); // Only set waitlisted trips for now

      if ([TRIP_WAITLIST_STATUS].includes(status)) {
        return true;
      }

      return false;
    }),
    tripProvider: Ember.computed('isUnscheduled', 'pickStopPoint.dispatchRoute.provider', 'provider', function () {
      // only unschedule trips use its own provider
      if (this.get('isUnscheduled')) {
        return this.get('provider');
      }

      return this.get('pickStopPoint.dispatchRoute.provider');
    }),
    anchorStop: Ember.computed('segment.anchor', function () {
      const anchor = this.get('segment.anchor');
      return this.get(`${anchor}`);
    }),
    serviceWindowStartTime: Ember.computed('segment.leg.serviceWindow', function () {
      const serviceWindow = this.get('segment.leg.serviceWindow');
      const promiseTime = this.get('promiseTime');
      if (Ember.isEmpty(promiseTime)) return null;
      const earliestPickTime = (0, _moment.default)(promiseTime);

      if (!Ember.isEmpty(serviceWindow)) {
        // if the service window information is available, the earliest pick time is the start of the promise window
        const promiseWindowStart = parseInt(this.get('segment.leg.serviceWindow.promiseWindowStart'), 10);
        earliestPickTime.subtract(promiseWindowStart, 'm');
      }

      return earliestPickTime;
    }),
    serviceWindowEndTime: Ember.computed('segment.leg.serviceWindow', function () {
      const serviceWindow = this.get('segment.leg.serviceWindow');
      const promiseTime = this.get('promiseTime');
      if (Ember.isEmpty(promiseTime)) return null;
      const earliestDropTime = (0, _moment.default)(promiseTime);

      if (serviceWindow && earliestDropTime !== null) {
        // if the service window information is available, the earliest drop time is the start of the promise window
        const dropWindowStart = parseInt(this.get('segment.leg.serviceWindow.promiseWindowEnd'), 10);
        earliestDropTime.add(dropWindowStart, 'm');
      }

      return earliestDropTime;
    }),
    driver: Ember.computed.alias('dispatchRoute.assignedDriver'),
    // currently status is a lowercase string
    // @TODO remove capitalize once status are more standardized
    mapId: Ember.computed('computedStatus', function () {
      const status = this.get('computedStatus');
      if (Ember.isPresent(status)) return Ember.String.capitalize(status);
      return null;
    }),
    type: Ember.computed('type', function () {
      const dsBooking = this.get('booking');
      if (dsBooking.get('subscription.content')) return 'Subscription';
      return 'single';
    }),
    plannedMiles: Ember.computed('pick.plannedDistanceToNext', 'drop.plannedDistanceToNext', function () {
      const pickMiles = parseFloat(this.get('pick.plannedDistanceToNext'));
      const dropMiles = parseFloat(this.get('drop.plannedDistanceToNext'));
      if (!isNaN(pickMiles) && !isNaN(dropMiles)) return pickMiles + dropMiles;else if (!isNaN(pickMiles)) return pickMiles;
      return !isNaN(dropMiles) ? dropMiles : 0;
    }),
    computedNoShowReason: Ember.computed('pickStopPoint.isNoShow', function () {
      const isNoShow = this.get('pickStopPoint.isNoShow');

      if (isNoShow) {
        return this.get('reason.name');
      }

      return '';
    }),
    // scheduling service / booking service trip statuses: 'unscheduled', 'scheduled', 'waitlisted', 'illegal'
    computedStatus: Ember.computed('status', 'isCompleted', 'pickStopPoint.isNoShow', 'isDropPerformed', function () {
      const isCompleted = this.get('isDropPerformed');
      const isNoShow = this.get('pickStopPoint.isNoShow');
      const status = this.get('status') ? this.get('status').toLowerCase() : this.get('status');
      const result = convertStatusDisplay[status];
      const enRouteTrip = this.get('tripStatus');

      if (enRouteTrip === 'En route') {
        return 'Enroute';
      }

      if (isCompleted) {
        return convertStatusDisplay.completed;
      }

      if (isNoShow) {
        return convertStatusDisplay.noShow;
      }

      return result ? result : '';
    }),
    inService: Ember.computed('status', function () {
      const status = this.get('status').toLowerCase();
      const inServiceArray = [TRIP_ACTIVE_STATUS.toLowerCase()];
      return inServiceArray.includes(status);
    }),
    isNoShowed: Ember.computed('status', function () {
      const status = this.get('status').toLowerCase();
      const noShowArray = [SCHEDULING_TRIP_NOSHOW_STATUS.toLowerCase(), TRIP_NOSHOW_STATUS.toLowerCase()];
      return noShowArray.includes(status);
    }),
    scheduleLocked: Ember.computed('schedule.locked', function () {
      return this.get('schedule.locked');
    }),
    isLocked: Ember.computed('dispatchRoute.isLocked', 'route.isLocked', function () {
      return this.get('dispatchRoute.isLocked') || this.get('route.isLocked');
    }),
    lockedBy: Ember.computed('dispatchRoute.lockedBy', 'route.lockedBy', function () {
      return this.get('dispatchRoute.lockedBy') || this.get('route.lockedBy');
    }),
    lockedId: Ember.computed('isLocked', function () {
      return this.get('dispatchRoute.id') || this.get('route.id');
    }),
    isLockedByOtherUser: Ember.computed('dispatchRoute.isLockedByOtherUser', 'route.isLockedByOtherUser', function () {
      return this.get('dispatchRoute.isLockedByOtherUser') || this.get('route.isLockedByOtherUser');
    }),
    currentRoute: Ember.computed('route', 'pickStopPoint.dispatchRoute.route', function () {
      const isScheduleDashboard = this.get('workspace.isScheduleDashboard'); // populating route for the schedule, if it is a schedule workspace

      if (isScheduleDashboard) {
        return this.get('route');
      } // if trip has reached dispatch, source its route via cluster


      const currentRoute = this.get('pickStopPoint.dispatchRoute.route');
      if (currentRoute && currentRoute.get('id')) return currentRoute;
    }),
    currentRouteName: Ember.computed('computedStatus', 'currentRoute.name', function () {
      const status = this.get('computedStatus').toLowerCase();

      if ([TRIP_WAITLIST_STATUS, TRIP_CANCELED_STATUS].includes(status)) {
        return '';
      }

      return this.get('currentRoute.name');
    }),
    formattedPromiseTime: Ember.computed('promiseTime', function () {
      return !Ember.isNone(Ember.get(this, 'promiseTime')) ? (0, _moment.default)(Ember.get(this, 'promiseTime')).format(_environment.default.dateTimeFormat.dateTimeMoment) : '';
    }),
    formattedScheduledPromiseTime: Ember.computed('promiseTime', 'isScheduled', function () {
      const promiseTime = this.get('promiseTime');
      const isScheduled = this.get('isScheduled');

      if (!Ember.isNone(promiseTime) && isScheduled) {
        return (0, _moment.default)(promiseTime).format(_environment.default.dateTimeFormat.dateTimeMoment);
      }
    }),
    cancelTripFormattedPromiseTime: Ember.computed('promiseTime', function () {
      return !Ember.isNone(Ember.get(this, 'promiseTime')) ? (0, _moment.default)(Ember.get(this, 'promiseTime')).format(_environment.default.dateTimeFormat.timeMoment) : '';
    }),
    cancelTripFormattedRequestTime: Ember.computed('segment.leg.requestTime', function () {
      return !Ember.isNone(Ember.get(this, 'segment.leg.requestTime')) ? (0, _moment.default)(Ember.get(this, 'segment.leg.requestTime')).format(_environment.default.dateTimeFormat.timeMoment) : '';
    }),
    currentRouteId: Ember.computed('currentRoute', function () {
      const currentRoute = this.get('currentRoute');
      return currentRoute.get('id');
    }),
    tripPickUpAddress: Ember.computed('pick', function () {
      const address = this.get('segment.pick.place.address');
      return address.get('locality') || address.get('subLocality') || address.get('region') || address.get('subRegion');
    }),
    tripDropOffAddress: Ember.computed('drop', function () {
      const address = this.get('segment.drop.place.address');
      return address.get('locality') || address.get('subLocality') || address.get('region') || address.get('subRegion');
    })
  });

  _exports.default = _default;
});