define("adept-iq/pods/components/form-components/playback-address-search/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-playback-address-search'],
    notifications: Ember.inject.service(),
    addressService: Ember.inject.service('address'),
    searchResults: null,
    selectedAddress: null,
    value: null,
    // callback function from playback widget
    onPickOnMap: null,
    searchCenter: Ember.computed.alias('value'),

    didInsertElement() {
      this._super(...arguments);
    },

    init() {
      this._super(...arguments);
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    searchTask: (0, _emberConcurrency.task)(function* (term) {
      // can't do a blank search
      if (Ember.isEmpty(term)) {
        return;
      } // TODO: change center for fuzzySearch


      const results = yield this.get('addressService').fuzzySearch(term, {
        limit: 25
      });
      return results;
    }),

    addressLocated(address) {
      this.set('selectedAddress', address);
    },

    searchCenterObserver: Ember.observer('searchCenter', function () {
      const searchCenter = this.get('searchCenter');
      this.get('addressService').reverseGeocode(searchCenter).then(this.addressLocated.bind(this));
    }),
    actions: {
      onAddressSelected(record) {
        this.set('selectedAddress', record);
      },

      pickOnMap(record) {
        this.onPickOnMap(record);
      }

    }
  });

  _exports.default = _default;
});