define("adept-iq/pods/components/side-drawer/rescue-route/table/columns/base-column/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-base-column'],
    attributeBindings: ['tabindex'],
    tabindex: 0,
    isEditing: false,
    sortIconProperty: Ember.computed('column.{sortable,sorted,ascending}', function () {
      const sorted = this.get('column.sorted');

      if (sorted) {
        const ascending = this.get('column.ascending');
        return ascending ? 'iconAscending' : 'iconDescending';
      }

      return '';
    })
  });

  _exports.default = _default;
});