define("adept-iq/pods/components/side-drawer/perform-stop/all/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/utils/convertDistance", "moment", "lodash", "adept-iq/mixins/version-check"], function (_exports, _emberConcurrency, _unwrapProxy, _convertDistance, _moment, _lodash, _versionCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_versionCheck.default, {
    classNames: ['perform-stop-drawer'],
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    refresh: true,
    // passed in by side-drawer-container component
    onShrinkClick: null,
    onCloseClick: null,
    componentName: 'performStop',
    currentStop: null,
    currentState: null,
    performedState: null,
    route: null,
    // passed in by stops widget
    stopPoints: Ember.computed.readOnly('stackFrame.options.stopPoints'),
    dispatchRoute: Ember.computed.readOnly('stackFrame.options.dispatchRoute'),
    lastStopPoint: Ember.computed.readOnly('stackFrame.options.lastStopPoint'),
    isNextDisabled: Ember.computed.not('currentStop.depart'),
    previousStop: Ember.computed('currentStop', function () {
      const stops = this.get('stopPoints');
      const currentStop = this.get('currentStop');
      const previousStopIndex = stops.indexOf(currentStop) - 1;

      if (previousStopIndex >= 0) {
        return stops[previousStopIndex];
      }

      return null;
    }),
    lastContainerDepartTime: Ember.computed('currentStopContainer', 'currentStopContainer.actualDepartTime', function () {
      const currentStopContainer = this.get('currentStopContainer');
      const curDate = new Date();

      if (currentStopContainer) {
        const lastDepartTime = currentStopContainer.get('actualDepartTime');
        return lastDepartTime;
      }

      return (0, _moment.default)(curDate).startOf('minute').toISOString();
    }),
    currentStopPosition: Ember.computed('currentStop', function () {
      const stops = this.get('stopPoints');
      const currentStop = this.get('currentStop');
      return stops.indexOf(currentStop) + 1;
    }),
    currentStopIndex: Ember.computed('currentStopPosition', function () {
      return this.get('currentStopPosition') - 1;
    }),
    hasNextStop: Ember.computed('currentStop', 'stopPoints', function () {
      const stops = this.get('stopPoints');
      return stops.indexOf(this.get('currentStop')) < stops.length - 1;
    }),
    title: Ember.computed('currentStop.stopType', function () {
      const stopType = this.getWithDefault('currentStop.stopType', '');
      return `Perform ${Ember.String.capitalize(stopType)} stop`;
    }),
    canSave: Ember.computed('hasNextStop', function () {
      const hasNextStop = !this.get('hasNextStop');
      return hasNextStop;
    }),
    nextButtonDisabled: Ember.computed('isNextDisabled', 'hasNextStop', function () {
      const isNextDisabled = this.get('isNextDisabled');
      const hasNextStop = !this.get('hasNextStop');
      return isNextDisabled || hasNextStop;
    }),

    init() {
      this._super(...arguments);
    },

    loadPerformedState() {
      const currentStopId = this.get('currentStop.id');
      const performedState = this.get('performedState') || {};
      const currentState = performedState[currentStopId];

      if (currentState) {
        this.set('currentState', currentState);
        delete performedState[currentStopId];
      }
    },

    loadCurrentState(stops) {
      const {
        currentStop,
        performedState
      } = this.get('currentState') || {};

      if (!Ember.isEmpty(currentStop)) {
        this.set('currentStop', currentStop);
        this.set('currentState.currentStop', null);
      } else {
        this.set('currentStop', stops.get('firstObject'));
      }

      if (!Ember.isEmpty(performedState)) {
        this.set('performedState', performedState);
        this.set('currentState.performedState', null);
      }
    },

    didInsertElement() {
      const stops = this.get('stopPoints') || [];
      const uniqueRouteIds = stops.map(stop => stop.get('dispatchRoute.route.name')).uniq();

      if (uniqueRouteIds.length > 1) {
        this.get('notifications').info('Please only select stops from the same route');
        this.onCloseClick();
      } else {
        this.set('routeId', uniqueRouteIds[0]);
        this.set('route', stops.get('firstObject.dispatchRoute.route'));
      }

      this.loadCurrentState(stops);
      this.set('isNextDisabled', true);
    },

    willDestroyElement() {
      const stopPoints = this.get('stackFrame.options.stopPoints'); // make sure to clean up stop point execute function

      stopPoints.forEach(stop => {
        stop.rollback();
      });

      this._super(...arguments);
    },

    saveTask() {
      const stopPoints = this.get('stopPoints');
      const canSave = this.get('canSave');
      const currentStopIndex = this.get('currentStopIndex');
      let avlmVersion = null;
      return stopPoints.filter((s, index) => canSave || index < currentStopIndex).reduce((acu, stopPoint) => {
        const stopType = Ember.String.capitalize(stopPoint.get('stopType')); // stopPoint is ember-changeset object, so has changes attributes.

        const change = stopPoint.change;

        if (Ember.isPresent(change.validation) && change.validation) {
          return acu.then(() => {
            // we don't want validation to be saved in store.
            stopPoint.rollbackProperty('validation');
            stopPoint.execute();
            return this.get(`save${stopType}Task`).perform(stopPoint, avlmVersion).then(resVersion => {
              avlmVersion = resVersion;
              return Promise.resolve();
            }).catch(e => {
              if (!(0, _emberConcurrency.didCancel)(e)) {
                const payloadMessage = Ember.get(e, 'payload.message') ? JSON.parse(Ember.get(e, 'payload.message')) : null;
                let messageText = 'Failed to Perform All Stops';

                if (e.status === 400 || e.status === 500) {
                  if (payloadMessage.errorDetail.includes('Execution version is different')) {
                    messageText = `FAILED TO PERFROM ALL STOPS. THE ROUTE (${this.get('route.name')}) HAS ALREADY BEEN MODIFIED WITH ANOTHER REQUEST`;
                  }
                }

                this.get('notifications').warning(`${messageText}`);
                throw e;
              }
            });
          });
        }

        return acu.then(() => Promise.resolve());
      }, Promise.resolve());
    },

    prepStopPointInSequence(stopPoint, stopContainer) {
      const trip = stopPoint.get('trip');
      const isArrived = stopContainer.get('isArrived');
      stopPoint.set('isArrived', isArrived);
      stopPoint.set('arrive', stopContainer.get('arrive'));
      stopPoint.set('depart', stopContainer.get('depart'));
      const actualArriveTime = stopContainer.get('actualArriveTime');
      const actualDepartTime = stopContainer.get('actualDepartTime');
      if (actualArriveTime) stopPoint.set('actualArriveTime', actualArriveTime);
      if (actualDepartTime) stopPoint.set('actualDepartTime', actualDepartTime);

      if (stopPoint.get('isBreak')) {
        stopPoint.set('routeBreak.actualArriveTime', actualArriveTime);
        stopPoint.set('routeBreak.actualDepartTime', actualDepartTime);
      }

      if (stopPoint.get('isPick')) {
        // Handle Travel Needs
        stopPoint.set('serviceAnimalCount', stopContainer.get('serviceAnimalCount'));
        stopPoint.set('pcaCount', stopContainer.get('pcaCount'));
        stopPoint.set('companionCount', stopContainer.get('companionCount'));
        stopPoint.set('travelNeeds', stopContainer.get('travelNeedItems').map(travelNeedItem => ({
          type: travelNeedItem.get('type'),
          cnt: travelNeedItem.get('count')
        })));
        stopPoint.set('travelNeedItems', stopContainer.get('travelNeedItems'));
        trip.set('fare', stopContainer.get('amount'));
        trip.set('fareName', stopContainer.get('selectedPaymentType'));
        const currentStopContainer = this.get('currentStopContainer');
        currentStopContainer.get('fetchStopPointCapacityTask').perform(stopPoint.get('id'));
      }

      stopPoint.set('odometer', (0, _convertDistance.convertMilesToMeters)(stopPoint.get('odometerInMiles')));
    },

    async processTripCapacityChange(travelNeedItems, stopPoint) {
      const activityLogAdapter = this.get('store').adapterFor('activity-log');
      const session = this.get('session');
      const route = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('route'));
      const trip = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('trip'));
      const vehicle = (0, _unwrapProxy.unwrapProxy)(route.get('dispatchRoute.assignedVehicle'));
      const driver = (0, _unwrapProxy.unwrapProxy)(route.get('dispatchRoute.assignedDriver'));
      const dispatchSchedule = (0, _unwrapProxy.unwrapProxy)(route.get('dispatchRoute.dispatchSchedule'));
      const providerId = route.get('provider.id');
      let attributeChanges = false;
      travelNeedItems.forEach(item => {
        if (item.count !== item.oldCount) {
          attributeChanges = true;
        }
      });

      if (attributeChanges) {
        const details = `Capacity for Trip ${trip.tripId} was modified`;
        const eventLog = {
          type: 'event',
          attributes: {
            routeId: parseInt(route.get('id'), 10),
            tripId: parseInt(trip.get('id'), 10),
            stopPointId: stopPoint.get('id'),
            driverId: parseInt(driver.get('id'), 10),
            vehicleId: parseInt(vehicle.get('id'), 10),
            scheduleId: parseInt(dispatchSchedule.get('id'), 10),
            source: 'webApp',
            providerName: providerId,
            priority: 'low',
            eventType: 'TEE',
            actionType: 'capacityChange',
            userId: session.data.authenticated.userId,
            eventLog: {
              capacityChange: [{
                activity: 'Trip Capacity Change',
                loggingType: 'route',
                details: details
              }, {
                activity: 'Trip Capacity Change',
                loggingType: 'trip',
                details: details
              }, {
                activity: 'Trip Capacity Change',
                loggingType: 'user',
                details: details
              }]
            }
          }
        };
        await activityLogAdapter.createEventActivity(eventLog);
      }
    },

    savePickTask: (0, _emberConcurrency.task)(function* (stopPoint, lastVersion) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const tripPaymentAdapter = store.adapterFor('trip-payment');
      const trip = stopPoint.get('trip');
      const isArrived = stopPoint.get('isArrived');
      const travelNeedItems = stopPoint.get('travelNeedItems'); // eslint-disable-next-line no-unused-vars

      let avlmVersion = lastVersion;

      if (stopPoint.get('arrive') && !isArrived) {
        avlmVersion = yield routeExecEventAdapter.createStopArriveEvent(stopPoint, avlmVersion);
      }

      if (stopPoint.get('depart')) {
        // added this activity log here because it was difficult to calculate
        // in avlm
        yield this.processTripCapacityChange(travelNeedItems, stopPoint);
        avlmVersion = yield routeExecEventAdapter.createStopDepartEvent(stopPoint, avlmVersion);
      }

      if (stopPoint.get('trip.fare')) {
        yield tripPaymentAdapter.createTripPaymentEvent(trip);
      }

      return avlmVersion;
    }).enqueue(),
    saveDropTask: (0, _emberConcurrency.task)(function* (stopPoint, lastVersion) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const isArrived = stopPoint.get('isArrived'); // eslint-disable-next-line no-unused-vars

      let avlmVersion = lastVersion;

      if (stopPoint.get('arrive') && !isArrived) {
        avlmVersion = yield routeExecEventAdapter.createStopArriveEvent(stopPoint, avlmVersion);
      }

      if (stopPoint.get('depart')) {
        avlmVersion = yield routeExecEventAdapter.createStopDepartEvent(stopPoint, avlmVersion);
      }

      return avlmVersion;
    }).enqueue(),
    saveBreakTask: (0, _emberConcurrency.task)(function* (stopPoint, lastVersion) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const isArrived = stopPoint.get('isArrived'); // eslint-disable-next-line no-unused-vars

      let avlmVersion = lastVersion;

      if (stopPoint.get('arrive') && !isArrived) {
        avlmVersion = yield routeExecEventAdapter.createStartBreakEvent(stopPoint, avlmVersion);
      }

      if (stopPoint.get('depart')) {
        avlmVersion = yield routeExecEventAdapter.createEndBreakEvent(stopPoint, avlmVersion);
      }

      return avlmVersion;
    }).enqueue(),

    validateStopPoint() {
      const currentStopContainer = this.get('currentStopContainer');
      const currentInUseStop = (0, _unwrapProxy.unwrapProxy)(this.get('currentStop'));
      const route = this.get('currentStopContainer.route');
      const arrive = currentStopContainer.get('arrive');
      const depart = currentStopContainer.get('depart');
      const arriveChanged = this.get('currentStop.change.arrive');
      const departChanged = this.get('currentStop.change.depart');
      const routeEndTime = route.get('plannedEndTime');
      const currentDateTime = (0, _moment.default)().format('YYYY-MM-DD HH:mm A');
      const arriveTime = currentStopContainer.get('actualArriveTime');
      const departTime = currentStopContainer.get('actualDepartTime');
      const momentArriveTime = (0, _moment.default)(arriveTime).startOf('minute');
      const momentDepartTime = (0, _moment.default)(departTime).startOf('minute');
      const actualStartTime = currentInUseStop.get('dispatchRoute.computedStartTime');
      const orderedStops = currentInUseStop.get('dispatchRoute.orderedStops');
      const routeOrdinal = currentInUseStop.get('routeOrdinal');
      const maxStopDepartTime = (0, _lodash.findLast)(orderedStops, stop => stop.get('isPerformed'));
      const currentStopType = Ember.String.capitalize(currentInUseStop.get('stopType'));
      const previousStop = this.get('previousStop'); // Reset error text

      currentStopContainer.set('errorText', null);

      if (!arrive && !depart) {
        if (currentStopType === 'Pick') {
          currentStopContainer.set('errorText', 'Please fill in either Actual Arrival Time or Actual Pick Time.');
        } else if (currentStopType === 'Drop') {
          currentStopContainer.set('errorText', 'Please fill in either Actual Arrival Time or Actual Drop Time.');
        } else {
          currentStopContainer.set('errorText', 'Please fill in either Actual Start or Actual End.');
        }

        return false;
      }

      if (momentArriveTime.isBefore(actualStartTime) || depart && momentDepartTime.isBefore(actualStartTime)) {
        currentStopContainer.set('errorText', `Performed ${currentStopType} Stop Failed. The stop cannot be performed before the route's start time`);
        return false;
      }

      if (momentArriveTime.isAfter(currentDateTime) || depart && momentDepartTime.isAfter(currentDateTime)) {
        currentStopContainer.set('errorText', `Performed ${currentStopType} Stop Failed. The stop cannot be performed after the previous current time.`);
        return false;
      }

      if (currentInUseStop.get('isPick') || currentInUseStop.get('isBreak')) {
        if (momentArriveTime.isAfter(routeEndTime) || depart && momentDepartTime.isAfter(routeEndTime)) {
          currentStopContainer.set('errorText', "Performed Pick Stop Failed. The stop cannot be performed after the route's end time.");
          return false;
        }
      }

      if (currentInUseStop.get('isDrop')) {
        if (momentArriveTime.isAfter(routeEndTime) || depart && momentDepartTime.isAfter(routeEndTime)) {
          this.get('notifications').warning('Stop performed after planned route end time.');
        }
      }

      if (currentInUseStop.get('isBreak')) {
        if (currentStopContainer.get('arrive')) {
          const coord = currentInUseStop.get('routeBreak.startPlace.location');

          if (Ember.isEmpty(coord.get('lat')) || Ember.isEmpty(coord.get('lng'))) {
            currentStopContainer.set('errorText', 'Performed Break Stop Failed. Cannot perform break without location.');
            return false;
          }
        }

        if (currentStopContainer.get('depart')) {
          const coord = currentInUseStop.get('routeBreak.endPlace.location');

          if (Ember.isEmpty(coord.get('lat')) || Ember.isEmpty(coord.get('lng'))) {
            currentStopContainer.set('errorText', 'Performed Break Stop Failed. Cannot perform break without location.');
            return false;
          }
        }
      }

      if (routeOrdinal > 0 && maxStopDepartTime) {
        if (arriveChanged && momentArriveTime.isBefore(maxStopDepartTime.get('actualDepartTime')) || departChanged && momentDepartTime.isBefore(maxStopDepartTime.get('actualDepartTime'))) {
          currentStopContainer.set('errorText', `Performed ${currentStopType} Stop Failed. The stop cannot be performed before the previous Actual drop time.`);
          return false;
        }
      }

      if (previousStop) {
        if (momentArriveTime.isBefore(previousStop.get('actualDepartTime')) || momentArriveTime.isBefore(previousStop.get('actualDepartTime'))) {
          currentStopContainer.set('errorText', `Performed ${currentStopType} Stop Failed. The stop cannot be performed before the previous Actual depart time.`);
          return false;
        }
      }

      return true;
    },

    actions: {
      onRefreshButtonClick() {
        const currentStopContainer = this.get('currentStopContainer');
        currentStopContainer.set('currentState', null);
        currentStopContainer.get('refreshTask').perform();
        this.set('isNextDisabled', true);
      },

      onShrinkClick() {
        const stopUIReference = this.get('stopUIReference');

        if (stopUIReference) {
          stopUIReference.backUpCurrentState();
        }

        const currentStop = this.get('currentStop');
        const currentState = this.get('currentState') || {};
        const performedState = this.get('performedState');
        this.set('currentState', { ...currentState,
          currentStop,
          performedState
        });
        this.onShrinkClick();
      },

      async onCloseButtonClick() {
        const tooltip = this.get('tooltip');
        const currentStopPosition = (0, _unwrapProxy.unwrapProxy)(this.get('currentStopPosition'));
        const notifications = this.get('notifications');
        const close = this.onCloseClick;
        const saveTask = this.get('saveTask').bind(this);
        const title = 'Progress will be saved';
        const dispatchRoute = this.get('dispatchRoute');
        const lockRouteAction = this.get('lockRouteAction').bind(this);
        const unlockRouteAction = this.get('unlockRouteAction').bind(this);
        this.set('dispatchRouteToCheck', dispatchRoute);

        if (currentStopPosition === 1) {
          return close(); // the user hasn't performed any stops.
        }

        try {
          // Check dispatchRoute version first
          await this.get('versionCheckOperation').perform(this.onCloseClick.bind(this));
          await lockRouteAction();
          tooltip.pushConfirmation({
            tip: 'All stops completed will be performed and saved. Do you want to close this panel?',
            title,
            hasOverlay: true,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return saveTask().then(() => {
                return this.get('versionUpdateOperation').perform();
              }).then(() => {
                notifications.success(`Performed ${currentStopPosition - 1} Stops Successfully`);
              }).catch(e => {
                console.warn(e); // eslint-disable-line
              }).finally(async () => {
                await unlockRouteAction(0);
                tooltip.reset();
                this.get('workspace').requestWootricSurvey('on_stopPerform');
                return close();
              });
            },
            secondaryActionText: 'Cancel',
            secondaryAction: async () => {
              await unlockRouteAction(0);
              return tooltip.reset();
            },
            closeAction: async () => {
              await unlockRouteAction(0);
            }
          });
        } catch (e) {
          await unlockRouteAction();
        }
      },

      async onPerformButtonClick() {
        const tooltip = this.get('tooltip');
        const currentStopContainer = this.get('currentStopContainer');
        const currentInUseStop = (0, _unwrapProxy.unwrapProxy)(this.get('currentStop'));
        const notifications = this.get('notifications');
        const close = this.onCloseClick;
        const title = 'Perform Stop';
        const saveTask = this.get('saveTask').bind(this);
        const dispatchRoute = this.get('dispatchRoute');
        const lockRouteAction = this.get('lockRouteAction').bind(this);
        const unlockRouteAction = this.get('unlockRouteAction').bind(this);
        this.set('dispatchRouteToCheck', dispatchRoute);

        try {
          const validation = this.validateStopPoint();

          if (validation) {
            this.get('currentStop').set('validation', true);
            this.prepStopPointInSequence(currentInUseStop, currentStopContainer); // Check dispatchRoute version first

            await this.get('versionCheckOperation').perform(this.onCloseClick.bind(this));
            await lockRouteAction();
            tooltip.pushConfirmation({
              tip: 'Perform all stops in the sequence?',
              title,
              hasOverlay: true,
              primaryActionText: 'Confirm',
              primaryAction: () => {
                return saveTask().then(() => {
                  const store = this.get('store');
                  const dispatchRouteAdapter = store.adapterFor('dispatch-route');
                  return dispatchRouteAdapter.updateVersion(dispatchRoute.get('id'), dispatchRoute.get('version'));
                }).then(() => {
                  notifications.success('Performed All Stops Successfully');
                }).finally(async () => {
                  await unlockRouteAction(0);
                  tooltip.reset();
                  this.get('workspace').requestWootricSurvey('on_stopPerform');
                  return close();
                });
              },
              secondaryActionText: 'Cancel',
              secondaryAction: async () => {
                await unlockRouteAction(0);
                return tooltip.reset();
              },
              closeAction: async () => {
                await unlockRouteAction(0);
              }
            });
          }
        } catch (e) {
          await unlockRouteAction();
        }
      },

      onSelectStop(stop) {
        stop.rollback(); // undo stop attributes back to square one

        this.set('currentStop', stop);
        this.loadPerformedState(); // If current is pick, then when click on pick it won't refresh the component
        // use refresh flag to force Ember to recreate the pick/drop/break UI

        this.set('refresh', false);
        Ember.run.next(() => {
          this.set('refresh', true);
        });
      },

      async nextStop() {
        this.set('dispatchRouteToCheck', this.get('dispatchRoute')); // Check dispatchRoute version first

        await this.get('versionCheckOperation').perform(this.onCloseClick.bind(this)); // will only prep all stop points before last stop point

        const currentStopContainer = this.get('currentStopContainer');
        const currentInUseStop = (0, _unwrapProxy.unwrapProxy)(this.get('currentStop'));
        const initStatus = currentStopContainer.get('initStatus');
        const dispatchRoute = this.get('dispatchRoute');
        const lastStopPoint = this.get('lastStopPoint');
        const stopsNeedUpdate = dispatchRoute.findUnperformedStopsInSequence(lastStopPoint);
        const currentInUseIndex = this.get('stopPoints').indexOf(currentInUseStop);

        if (stopsNeedUpdate.length !== this.get('stopPoints.length') || // only check first stop status
        currentInUseIndex === 0 && currentInUseStop.get('status') !== initStatus) {
          const tooltip = this.get('tooltip');
          tooltip.pushConfirmation({
            tip: 'Perform stop failed, Because stop had been modified by another request.',
            title: 'StopPoint record changed',
            hasOverlay: true,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              const close = this.onCloseClick.bind(this);
              tooltip.reset();
              return close();
            }
          });
          return;
        }

        const validation = this.validateStopPoint();

        if (validation) {
          const stopUIReference = this.get('stopUIReference');

          if (stopUIReference) {
            stopUIReference.backUpCurrentState();
            const performedState = this.get('performedState');
            this.set('performedState', {
              [currentInUseStop.get('id')]: this.get('currentState'),
              ...performedState
            });
          }

          this.get('currentStop').set('validation', true);
          this.prepStopPointInSequence(currentInUseStop, currentStopContainer);
          if (!this.get('hasNextStop')) return false;
          const stops = this.get('stopPoints');
          const currentStop = this.get('currentStop');
          const currentIndex = stops.indexOf(currentStop);
          const nextStop = stops[currentIndex + 1];
          this.set('currentState', null);
          this.set('currentStop', nextStop);
          this.set('isNextDisabled', true);
          this.set('currentStopContainer.arrive', false);
          this.set('currentStopContainer.depart', false);
        }
      },

      setStopContainerReference(stopContainerReference) {
        if (stopContainerReference) {
          this.set('currentStopContainer', stopContainerReference);
        }
      },

      setStopUIReference(stopUIReference) {
        if (stopUIReference) {
          this.set('stopUIReference', stopUIReference);
        }
      },

      getValueFromchildTravelNeeds(travelNeedItems) {
        this.set('travelNeedItems', travelNeedItems);
      },

      getVehicleCapacityAvailable(travelNeeds) {
        const currentStopContainer = this.get('currentStopContainer');
        const stopPoint = this.get('currentStop');

        if (stopPoint.get('isPick')) {
          currentStopContainer.get('fetchStopPointCapacityTask').perform(stopPoint.get('id'), travelNeeds);
        }
      }

    }
  });

  _exports.default = _default;
});