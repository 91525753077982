define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-vehicle/component", ["exports", "adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-vehicle/config", "lodash"], function (_exports, _config, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dwhService: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    vehicleTable: null,
    selectedVehicle: null,
    searchButtonEnabled: Ember.computed.empty('selectedVehicle'),
    clearButtonEnabled: Ember.computed.empty('selectedVehicle'),
    config: _config.default,

    init() {
      this._super(...arguments);

      this.set('onRowClick', this.actions.onRowClick.bind(this));
      this.set('fetchRoutesByVehicle', this.get('fetchRoutesByVehicle').bind(this));
      this.set('config.title', '');
    },

    vehicleOptions: Ember.computed.alias('dwhService.vehicleOptions'),

    fetchRoutesByVehicle() {
      const routes = this.get('dwhService.routes');
      const selectedVehicleName = this.get('selectedVehicle.callsign');
      if (!selectedVehicleName) return [];
      return routes.filter(r => _lodash.default.get(r, 'attributes.vehicleName') === selectedVehicleName);
    },

    actions: {
      onRowClick(selectedRow) {
        if (Ember.isEmpty(selectedRow)) return;
        const routeId = selectedRow.get('id');
        this.set('dwhService.routeFound', routeId);
      },

      onSearchRoutesByVehicle() {
        this.get('vehicleTable').refreshData();
      },

      onOptionsSelect(item, option) {
        Ember.set(this, item, option);
      },

      clearSearchVehicle() {
        this.set('selectedVehicle', '');
        this.get('vehicleTable').clearData();
        this.set('config.title', '');
        this.set('config.noRecordsFound', false);
        this.set('routeId', null);
      }

    }
  });

  _exports.default = _default;
});