define("adept-iq/pods/components/timeline-picker/relative-time-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    minusValue: null,
    plusValue: null,

    updateTimeline() {
      if (!(this.get('minusValue') === null || this.get('plusValue') === null)) {
        this.sendAction('onSpanChange', this.get('minusValue'), this.get('plusValue')); // eslint-disable-line ember/closure-actions
      }
    },

    actions: {
      onUpdateMinusValue(value) {
        this.set('minusValue', value);
        this.updateTimeline();
      },

      onUpdatePlusValue(value) {
        this.set('plusValue', value);
        this.updateTimeline();
      }

    }
  });

  _exports.default = _default;
});