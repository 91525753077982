define("adept-iq/serializers/vehicle-event", ["exports", "adept-iq/serializers/-avlm-avlmService"], function (_exports, _avlmAvlmService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    normalizeResponse(store, primaryModelClass, payload) {
      payload.data = payload.data.filter(record => {
        if (record.type === 'vehicleEvent' || record.type === 'routeExecEvent' || record.type === 'driverStateEvent' || record.type === 'userEvent') {
          return record;
        }
      }) || {};
      return payload;
    }

  });

  _exports.default = _default;
});