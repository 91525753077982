define("adept-iq/pods/components/reoptimize-modal/operation-views/reoptimize/component", ["exports", "moment", "adept-iq/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UNASSIGNED_PROVIDER = 'UNASSIGNED';

  var _default = Ember.Component.extend({
    classNames: ['reoptimize-options'],
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    // Passed In Attributes
    title: 'Reoptimize Options',
    currentSchedule: null,
    selectedVal: null,
    isWaitlistedGenerate: Ember.computed.equal('selectedVal', 'waitlist'),
    selectedProvider: Ember.computed.readOnly('providerTableRef.table.selectedRows.firstObject.content'),
    providersList: Ember.computed.alias('workspaceContext.providersList'),
    isAttemptToWaitlistEnabled: Ember.computed('currentSchedule.isGenerated', function () {
      return this.get('currentSchedule.isGenerated');
    }),
    allProvidersList: Ember.computed('workspaceContext.providersList', function () {
      const providersList = this.get('workspaceContext.providersList');
      const allList = [{
        id: 'all',
        display: 'All Providers'
      }];
      providersList.forEach(provider => {
        if (provider.get('id') !== UNASSIGNED_PROVIDER) {
          allList.push({
            id: provider.get('id'),
            display: provider.get('data.displayName')
          });
        }
      });
      return allList;
    }),

    init() {
      this._super(...arguments);

      this.onRadioButtonClick('reoptimize');
    },

    onRadioButtonClick(val) {
      this.set('selectedVal', val);
      const isWaitlistedGenerate = this.get('isWaitlistedGenerate');

      if (isWaitlistedGenerate) {
        this.get('providerTableRef.selectFirstRowTask').perform();
      }
    },

    //to check the current schedule is generated(in-progess)
    async fetchCurrentSchedule(currentSchedule) {
      const store = this.get('store');
      const workspace = this.get('workspace');
      const schedules = await store.query('schedule', {
        filter: `eq(id,${currentSchedule.get('id')})`
      });
      const isGenerated = schedules.firstObject.get('isGenerated');

      if (isGenerated) {
        workspace.set('reoptimizeInProgress', true);
      }

      return isGenerated;
    },

    async onReoptimizeBtnClick() {
      const tooltip = this.get('tooltip');
      const workspace = this.get('workspace');
      const date = (0, _moment.default)(workspace.startDate).format(_environment.default.dateTimeFormat.dateMoment);
      const currentSchedule = this.get('currentSchedule');
      const selectedVal = this.get('selectedVal');
      const selectedProvider = this.get('selectedProvider');
      const tip = selectedProvider.id === 'all' ? `Are you sure you want to Optimize the schedule for ${date}?` : `Are you sure you want to optimize ${selectedProvider.display}'s routes for ${date}?`;

      if (await this.fetchCurrentSchedule(currentSchedule)) {
        return;
      }

      tooltip.pushConfirmation({
        title: 'Reoptimization',
        hasOverlay: true,
        tip: tip,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          if (await this.fetchCurrentSchedule(currentSchedule)) {
            return;
          }

          workspace.popState('reoptimize');
          workspace.pushState('schedule-generate', {
            currentSchedule,
            radioValue: selectedVal,
            provider: selectedProvider.id
          });
          tooltip.reset();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    actions: {
      onXButtonClick() {
        this.get('workspace').popState('reoptimize');
      },

      onRadioButtonClick(val) {
        this.onRadioButtonClick(val);
      },

      onReoptimizeClick() {
        this.onReoptimizeBtnClick();
      }

    }
  });

  _exports.default = _default;
});