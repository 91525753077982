define("adept-iq/pods/components/side-drawer/permissions-add-role/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fzB5ayow",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[12,\"value\",[27,\"readonly\",[[23,[\"value\"]]],null]],[12,\"disabled\",[27,\"readonly\",[[23,[\"disabled\"]]],null]],[12,\"oninput\",[27,\"action\",[[22,0,[]],\"onInput\"],[[\"value\"],[\"target.value\"]]]],[12,\"onkeypress\",[21,\"filterTextInput\"]],[12,\"onpaste\",[21,\"filterPastedInput\"]],[11,\"maxlength\",\"32\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/side-drawer/permissions-add-role/text/template.hbs"
    }
  });

  _exports.default = _default;
});