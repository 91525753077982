define("adept-iq/pods/components/side-drawer/system-config/speed-region-groups/table/speed-region-groups/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/side-drawer/system-config/speed-region-groups/table/speed-region-groups/config", "adept-iq/utils/sorts", "adept-iq/utils/filters", "adept-iq/utils/remove-focus"], function (_exports, _component, _config, _sorts, _filters, _removeFocus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['speed-region-group-table'],
    store: Ember.inject.service(),
    config: _config.default,
    paginationEnabled: true,
    enableWarningRowChangeAlert: true,
    configType: null,
    // coming from caller
    isBlockoutRegionSetType: false,

    init() {
      this._super(...arguments);
    },

    async didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const isBlockoutRegionSetType = this.get('isBlockoutRegionSetType');
      let activeData = store.peekAll(modelName) || [];

      if (Ember.isPresent(this.get('config.fetchModelData'))) {
        activeData = (await store.findAll(modelName)) || [];
      }

      if (Ember.isPresent(this.get('config.queryModelData'))) {
        const queryParams = this.get('queryParams') || {};
        activeData = await store.query(modelName, queryParams);
      }

      let filteredData = activeData.filter(filterFn).sort(compareFn);
      filteredData = filteredData.filter(data => {
        const isSoftDeletedRow = data.get('isSoftDeletedRow') || false;

        if (isBlockoutRegionSetType) {
          return !isSoftDeletedRow && data.get('isBlockoutRegionType');
        }

        return !isSoftDeletedRow && !data.get('isBlockoutRegionType');
      });
      return filteredData;
    },

    workingInProgress() {
      const speedRegionGroup = Ember.$('.speed-region-groups');
      this.set('switchingRow', true);
      speedRegionGroup.addClass('speed-region-disabled');
      (0, _removeFocus.cursorWait)();
      Ember.run.later(() => {
        this.set('switchingRow', false);
        speedRegionGroup.removeClass('speed-region-disabled');
        (0, _removeFocus.cursorDefault)();
      }, 2000);
    },

    actions: {
      onRowClick(row, event) {
        if (this.get('switchingRow')) {
          this.cancelRow(event);
          return;
        }

        this.workingInProgress();
        this.tableRowSelect(row, event);
      }

    }
  });

  _exports.default = _default;
});