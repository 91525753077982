define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/vehicle/component", ["exports", "ember-changeset", "ember-changeset-validations", "ember-concurrency", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/vehicle/validation", "adept-iq/utils/unwrapProxy", "moment", "lodash", "adept-iq/config/placeholders"], function (_exports, _emberChangeset, _emberChangesetValidations, _emberConcurrency, _validation, _unwrapProxy, _moment, _lodash, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DROPDOWN_MODELS = [{
    optionName: 'providerOptions',
    modelName: 'provider'
  }, {
    optionName: 'vehicleStatusOptions',
    modelName: 'vehicle-status-name'
  }, {
    optionName: 'vehicleManufacturerOptions',
    modelName: 'manufacturer-name'
  }, {
    optionName: 'vehicleTypeOptions',
    modelName: 'vehicle-type'
  }, {
    optionName: 'leaseCompanyOptions',
    modelName: 'lease-company'
  }];
  const EVENT_VEHICLE_MAP = {
    addVehicle: true,
    editVehicle: true,
    deleteVehicle: true
  };
  const VEHICLE_FIELDS = ['inServiceDate', 'endServiceDate', 'leaseEndDate', 'avlmInstallDate', 'avlmRemoveDate'];

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    classNames: ['vehicle-view'],
    // placeholders
    VEHICLE_ID_PLACEHOLDER: _placeholders.VEHICLE_ID_PLACEHOLDER,
    VEHICLE_STATUS_PLACEHOLDER: _placeholders.VEHICLE_STATUS_PLACEHOLDER,
    VEHICLE_TYPE_PLACEHOLDER: _placeholders.VEHICLE_TYPE_PLACEHOLDER,
    PROVIDER_PLACEHOLDER: _placeholders.PROVIDER_PLACEHOLDER,
    VEHICLE_MODEL_YEAR_PLACEHOLDER: _placeholders.VEHICLE_MODEL_YEAR_PLACEHOLDER,
    VEHICLE_LICENCE_PLACEHOLDER: _placeholders.VEHICLE_LICENCE_PLACEHOLDER,
    VEHICLE_START_GARAGE_PLACEHOLDER: _placeholders.VEHICLE_START_GARAGE_PLACEHOLDER,
    VEHICLE_MANUFACTURER_PLACEHOLDER: _placeholders.VEHICLE_MANUFACTURER_PLACEHOLDER,
    NOTES_PLACEHOLDER: _placeholders.NOTES_PLACEHOLDER,
    VEHICLE_END_GARAGE_PLACEHOLDER: _placeholders.VEHICLE_END_GARAGE_PLACEHOLDER,
    VEHICLE_LEASE_COMPANY_PLACEHOLDER: _placeholders.VEHICLE_LEASE_COMPANY_PLACEHOLDER,
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    VEHICLE_ENGINE_TYPE_PLACEHOLDER: _placeholders.VEHICLE_ENGINE_TYPE_PLACEHOLDER,
    VEHICLE_SITE_DEF_PLACEHOLDER: _placeholders.VEHICLE_SITE_DEF_PLACEHOLDER,
    VIN_PLACEHOLDER: _placeholders.VIN_PLACEHOLDER,
    WHEELCHAIR_SERIAL_NUMBER_PLACEHOLDER: _placeholders.WHEELCHAIR_SERIAL_NUMBER_PLACEHOLDER,
    VEHILCE_VOIP_ID: _placeholders.VEHILCE_VOIP_ID,
    VEHILCE_VOIP_PASSCODE: _placeholders.VEHILCE_VOIP_PASSCODE,
    vehicleChangeset: null,
    places: null,
    showErrorMsg: false,
    vehicleRecord: Ember.computed('editAPI.selectedRecord', function () {
      const selectedVehicle = this.get('editAPI.selectedRecord');
      return this.setDefaultProperties(selectedVehicle);
    }),

    init() {
      this._super(...arguments);

      const vehicleId = this.get('vehicleRecord.id'); // Reg- NYAAR-20163 for new vehicle, the vehicle id will be undefined so dont fetch event

      if (vehicleId) {
        this.set('activityQueryParams', {
          vehicleId,
          eventLog: true
        });
      } else {
        // skip query of activity log if it is a new record
        this.set('skipQuery', true);
      }

      this.setupDropDownValues();
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleRecord = this.get('onSaveVehicleRecord');
      const onUndoVehicleRecord = this.get('onUndoVehicleRecord').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleRecord,
          undoAction: onUndoVehicleRecord,
          undoBtn: false,
          saveBtn: false,
          newAction: false,
          deleteAction: false,
          editAction: false,
          onValidateAction,
          hasChanges,
          isChangesHappened: false
        });
      });
      this.addObserver('hasPendingChanges', this.hasPendingChangesObserver);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const tableRef = this.get('tableRef');
      const vehicleRecord = this.get('vehicleRecord');

      if (vehicleRecord) {
        if (!vehicleRecord.get('isNew') && !vehicleRecord.get('isDeleted')) {
          yield vehicleRecord.data.reload();
        }
      }

      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),
    hasPendingChanges: Ember.computed('vehicleRecord.changes.length', function () {
      const vehicleRecord = this.get('vehicleRecord');
      const changes = vehicleRecord.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      const vehicleRecord = this.get('vehicleRecord');

      if (vehicleRecord.get('isNew')) {
        const pendingChanges = this.get('vehicleRecord');
        const fields = (0, _lodash.flatten)(pendingChanges.changes).map(change => change.key);
        const isEveyPrePoulatedFieldsChange = fields.length ? (0, _lodash.every)(fields, field => VEHICLE_FIELDS.includes(field)) : false;
        if (isEveyPrePoulatedFieldsChange) return !isEveyPrePoulatedFieldsChange;
        return !!this.get('hasPendingChanges');
      }

      return !!this.get('hasPendingChanges');
    },

    hasPendingChangesObserver() {
      const editAPI = this.get('editAPI');
      const hasPendingChanges = this.get('hasPendingChanges');
      Ember.run.scheduleOnce('afterRender', () => {
        editAPI.setProperties({
          isChangesHappened: hasPendingChanges
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.set('showErrorMsg', false);
      this.set('duplicateName', false);

      if (record) {
        const today = (0, _moment.default)().toISOString();
        const {
          inServiceDate,
          endServiceDate,
          leaseEndDate,
          avlmInstallDate,
          avlmRemoveDate
        } = record.getProperties('inServiceDate', 'endServiceDate', 'leaseEndDate', 'avlmInstallDate', 'avlmRemoveDate');
        if (Ember.isEmpty(inServiceDate)) record.set('inServiceDate', today);
        if (Ember.isEmpty(endServiceDate)) record.set('endServiceDate', today);
        if (Ember.isEmpty(leaseEndDate)) record.set('leaseEndDate', today);
        if (Ember.isEmpty(avlmInstallDate)) record.set('avlmInstallDate', today);
        if (Ember.isEmpty(avlmRemoveDate)) record.set('avlmRemoveDate', today);
        return new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
      }
    },

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_VEHICLE_MAP[event.get('actionType')];
    },

    setupDropDownValues() {
      const store = this.get('store');
      const dropDownModels = DROPDOWN_MODELS;
      const placeQueryParams = {
        filter: 'eq(placeCategoryTypeName,garage)'
      };
      dropDownModels.forEach(dropdown => {
        const records = store.peekAll(dropdown.modelName);

        if (dropdown.modelName === 'provider') {
          const activeProvider = records.filter(record => record.get('status').toLowerCase() === 'active');
          this.set(dropdown.optionName, activeProvider);
        } else {
          this.set(dropdown.optionName, records);
        }
      });
      store.query('place', placeQueryParams).then(data => {
        this.set('places', data);
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const vehicleRecord = this.get('vehicleRecord');
      yield vehicleRecord.validate();
      const errors = vehicleRecord.errors;
      const name = vehicleRecord.get('name');
      this.set('duplicateName', false);

      if (vehicleRecord.get('isNew') && name) {
        const params = {
          filter: `eq('name','${name.trim()}')`
        };
        yield this.get('store').query('vehicle', params).then(records => {
          if (records.length) {
            this.set('duplicateName', true);
            errors.pushObject({
              validation: 'The Vehicle ID already exists. Please specify a different Vehicle ID.'
            });
          }
        });
      }

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveVehicleRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');

      if (isValid) {
        const notifications = this.get('notifications');
        const vehicleRecord = (0, _unwrapProxy.unwrapProxy)(this.get('vehicleRecord'));
        const isNewVehicleRecord = vehicleRecord.get('isNew');

        try {
          yield vehicleRecord.save();

          if (isNewVehicleRecord) {
            this.set('vehicleRecord.isNew', false);
          }

          this.set('activityQueryParams', {
            vehicleId: `${vehicleRecord.get('id')}`,
            eventLog: true
          });
          yield this.refreshTask.perform();
          tooltip.reset();
          notifications.success('Record successfully saved.');
          return vehicleRecord;
        } catch (e) {
          vehicleRecord.data.rollbackAttributes();
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line

          return vehicleRecord;
        }
      }
    }),

    onUndoVehicleRecord() {
      const vehicleRecord = this.get('vehicleRecord');
      if (vehicleRecord) vehicleRecord.rollback();
    },

    actions: {
      onDateValueChange(record, valuePath, value) {
        const isoDate = (0, _moment.default)(value).toISOString();
        record.set(valuePath, isoDate);
      },

      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});