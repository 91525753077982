define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel/searchModeSwitcher", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel"], function (_exports, _react, _panel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const SearchModeSwitcher = () => {
    const {
      searchMode,
      setSearchMode,
      confirmStack,
      setConfirmStack
    } = (0, _panel.usePanel)();

    const switchMode = mode => {
      if (searchMode === mode) return;
      const confirmBox = {
        id: 'switchMode',
        message: mode === _panel.searchModes.byVehicle ? 'Switching to "Search by Vehicle" will reset the playback function. Are you sure you want to switch to "Search by Vehicle"?' : 'Switching to "Search by Location" will reset the playback function. Are you sure you want to switch to "Search by Location"?',
        confirm: () => () => setSearchMode(mode),
        cancel: () => () => {}
      };

      if (!confirmStack.find(box => box.id === 'switchMode')) {
        setConfirmStack([...confirmStack, confirmBox]);
      }
    };

    return _react.default.createElement("nav", {
      className: "playback-widget-tabs"
    }, _react.default.createElement("button", {
      id: "searchByVehicle",
      className: searchMode === _panel.searchModes.byVehicle ? 'tab active' : 'tab',
      onClick: () => switchMode(_panel.searchModes.byVehicle),
      type: "button"
    }, "Search by Vehicle"), _react.default.createElement("button", {
      id: "searchByLocation",
      className: searchMode === _panel.searchModes.byLocation ? 'tab active' : 'tab',
      onClick: () => switchMode(_panel.searchModes.byLocation),
      type: "button"
    }, "Search by Location"));
  };

  var _default = SearchModeSwitcher;
  _exports.default = _default;
});