define("adept-iq/adapters/reconcile-trip", ["exports", "adept-iq/adapters/-reconciliationService"], function (_exports, _reconciliationService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reconciliationService.default.extend({
    buildURL(modelName
    /*, id, snapshot, requestType, query*/
    ) {
      return this._buildUrlForPath(modelName);
    },

    _buildUrlForPath() {
      const host = this.get('host');
      return `${host}/trip`;
    },

    patchTrip(tripPayload, type, oldRouteId) {
      const session = this.get('session');
      const json = {
        type,
        attributes: tripPayload
      };
      return this.get('ajaxService').patch(this.get('host') + '/trip/' + tripPayload.tripId + '?oldRouteId=' + oldRouteId, {
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    },

    putTrip(tripPayload, type) {
      const session = this.get('session');
      const json = {
        type: type,
        attributes: tripPayload
      };
      return this.get('ajaxService').post(this.get('host') + '/trip/' + tripPayload.tripId, {
        method: 'PUT',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    },

    postTrip(routePayload) {
      const ajax = this.get('ajaxService');
      const token = this.get('session.data.authenticated.token');
      const json = {
        type: routePayload.type,
        attributes: routePayload
      };
      return ajax.post(this.get('host') + '/trip', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: {
          data: json
        }
      });
    }

  });

  _exports.default = _default;
});