define("adept-iq/utils/status-error-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    '200': 'OK',
    '201': 'Created',
    '204': 'No Content',
    '400': 'Bad Request',
    '401': 'Unauthorized',
    '403': 'Forbidden',
    '404': 'Not Found',
    '405': 'Method Not Allowed',
    '408': 'Request Timed-out',
    '409': 'Conflict',
    '500': 'Internal Server Error',
    '501': 'Not Implemented',
    '502': 'Bad Gateway'
  };
  _exports.default = _default;
});