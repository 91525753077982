define("adept-iq/pods/components/form-components/number/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FLOAT_FORMAT = /^\d*\.*\d*$/;
  const NUMBER_FORMAT = /^\d*$/;
  const {
    readOnly
  } = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['form-components-number'],
    value: null,
    disabled: false,
    extra: null,
    placeholder: null,
    numberFormat: NUMBER_FORMAT,
    min: readOnly('extra.min'),
    max: readOnly('extra.max'),
    length: readOnly('extra.length'),
    isFloat: readOnly('extra.isFloat'),
    setLimit: readOnly('extra.setLimit'),

    didInsertElement() {
      this._super(...arguments);

      const isFloat = this.get('isFloat');

      if (isFloat) {
        this.set('numberFormat', FLOAT_FORMAT);
      }
    },

    actions: {
      onInput(value) {
        const min = this.get('min');
        const max = this.get('max');
        const numFormat = this.get('numberFormat');
        const numVal = parseInt(value, 10);
        const setLimit = this.get('setLimit');
        const oldValue = this.get('value');
        const length = this.get('length') || 10; // Reset input value to prior value unless changed value matches format.

        if (!numFormat.test(value) || value.length > length) {
          return this.notifyPropertyChange('value');
        }

        if (isNaN(numVal) && !Ember.isPresent(setLimit)) {
          this.get('onChange')(min);
          return value;
        } // Reset input value to restricted max or min depending on config.


        if (numVal >= max && Ember.isPresent(max)) {
          this.set('value', max);

          if (Ember.isPresent(setLimit)) {
            this.set('value', oldValue);
            this.get('onChange')(oldValue);
            return;
          }
        }

        if (numVal <= min && Ember.isPresent(min)) {
          this.set('value', min);

          if (Ember.isPresent(setLimit)) {
            this.set('value', '');
            this.get('onChange')('');
            return;
          }
        }

        this.get('onChange')(value);
        return value;
      }

    }
  });

  _exports.default = _default;
});