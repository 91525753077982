define("adept-iq/serializers/trip", ["exports", "adept-iq/serializers/-ss-schedulingService", "adept-iq/models/trip"], function (_exports, _ssSchedulingService, _trip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const convertStatusReason = {
    'No Show No Answer': 'noShowNoAnswer',
    'No Show Same Day Cancel No Fault': 'noShowSameDayCancelNoFault',
    'No Show Same Day Cancel': 'noShowSameDayCancel',
    'No Show Cancel At Door': 'noShowCancelAtDoor',
    'No Show Not Ready': 'noShowNotReady',
    'No Show No Fault': 'noShowNoFault',
    NSNA: 'noShowNoAnswer',
    NSSDCNF: 'noShowSameDayCancelNoFault',
    NSSDC: 'noShowSameDayCancel',
    NSCD: 'noShowCancelAtDoor',
    NSNR: 'noShowNotReady',
    NSNF: 'noShowNoFault'
  };

  var _default = _ssSchedulingService.default.extend({
    attrs: {
      paymentType: {
        serialize: false
      },
      fare: {
        serialize: false
      },
      maxRideTime: {
        serialize: false
      },
      failedStatus: {
        serialize: false
      },
      tripStatus: {
        serialize: false
      },
      sdbRouteName: {
        serialize: false
      }
    },

    serialize(snapshot, options) {
      const json = this._super(snapshot, options);

      json.data.attributes = json.data.attributes || {};

      if (Ember.isPresent(json.data.relationships)) {
        const scheduleRelationship = json.data.relationships.schedule;
        const subscriptionRelationship = json.data.relationships.subscription;
        const segmentRelationship = json.data.relationships.segment;
        const bookingRelationship = json.data.relationships.booking;
        const providerRelationship = json.data.relationships.provider;
        const reason = json.data.relationships.reason;
        const currentProviderName = json.data.attributes.currentProviderName;

        if (Ember.isPresent(scheduleRelationship)) {
          delete json.data.relationships.schedule;
        }

        if (Ember.isPresent(subscriptionRelationship)) {
          delete json.data.relationships.subscription;
        }

        if (Ember.isPresent(segmentRelationship)) {
          delete json.data.relationships.segment;
        }

        if (Ember.isPresent(bookingRelationship)) {
          delete json.data.relationships.booking;
        }

        if (Ember.isPresent(providerRelationship)) {
          delete json.data.relationships.provider;
        }

        if (Ember.isPresent(reason)) {
          delete json.data.relationships.reason;
        }

        if (Ember.isPresent(currentProviderName)) {
          const currentProviderNameJson = {
            'data': {
              'type': 'providerName',
              'id': currentProviderName
            }
          };
          json.data.relationships.currentProviderName = currentProviderNameJson;
        }
      }

      if (Ember.isPresent(json.data.attributes)) {
        delete json.data.attributes.otp;
        delete json.data.attributes.otpValue;
        delete json.data.attributes.statusReason;
        delete json.data.attributes.currentProviderName;
        delete json.data.attributes.noShowRequestReasonCode;
      } // while trip-transfer we don't need to send a status attribute for PATCH


      if (Ember.isPresent(json.data.attributes) && Ember.isEmpty(json.data.attributes.status)) {
        delete json.data.attributes.status;
      }

      if (Ember.isPresent(json.data.attributes) && json.data.attributes.status === _trip.TRIP_WAITLIST_STATUS && Ember.isPresent(json.data.relationships)) {
        delete json.data.relationships.route;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};

      if (resourceHash.attributes) {
        // the status reason arrive from either avlm-route-exec-event as a code or trip topic as the actual id of the no-show-reason
        // evaulate based on either and use the correct id to associate the no-show-reason-code to trip
        if (Ember.isPresent(resourceHash.attributes.statusReason)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          let {
            statusReason
          } = resourceHash.attributes;
          statusReason = convertStatusReason[statusReason] || statusReason;
          relationships.reason = {
            data: {
              type: 'no-show-reason-code',
              id: statusReason
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.statusReason;
        } //NYAAR-19295: reason attribute comes form DB is set to failedStatus attribute in trip-model


        if (Ember.isPresent(resourceHash.attributes.reason)) {
          const attributes = resourceHash.attributes ? resourceHash.attributes : {};
          const {
            reason
          } = resourceHash.attributes;
          attributes.failedStatus = reason;
          resourceHash.attributes = attributes;
          delete resourceHash.attributes.reason;
        }

        if (!Ember.isNone(resourceHash.attributes.currentProviderName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {}; // UNASSIGNED is not a real provider. backend just use it for
          // waitlisted and cancelled trips

          if (resourceHash.attributes.currentProviderName === 'UNASSIGNED') {
            delete resourceHash.attributes.currentProviderName;
          } else {
            relationships.provider = {
              data: {
                type: 'provider',
                id: resourceHash.attributes.currentProviderName
              }
            };
          }

          resourceHash.relationships = relationships;
        }
      } //NYAAR-19295: if currentProviderName value present in relationship is set to attribute of trip-model


      if (resourceHash.relationships && resourceHash.relationships.currentProviderName) {
        const attributes = resourceHash.attributes ? resourceHash.attributes : {};
        const currentProviderName = resourceHash.relationships.currentProviderName ? resourceHash.relationships.currentProviderName : {};
        attributes.currentProviderName = currentProviderName.data.id;
        resourceHash.attributes = attributes;
        delete resourceHash.relationships.currentProviderName;
      }

      if (Ember.isPresent(resourceHash.attributes) && resourceHash.attributes.status === _trip.TRIP_WAITLIST_STATUS && Ember.isPresent(resourceHash.relationships.route)) {
        delete resourceHash.relationships.route;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      payload.data.relationships = payload.data.relationships || {};

      if (Ember.isPresent(payload.data.attributes.otpCode) || Ember.isPresent(payload.data.attributes.otpStatus)) {
        payload.data.attributes.otp = payload.data.attributes.otpCode || payload.data.attributes.otpStatus;
      } // sometimes route are in different timeline so we need to get lock from trip.


      if (Ember.isPresent(payload.data.attributes.lock)) {
        if (payload.data.attributes.lock && payload.data.attributes.lock.lockedBy.startsWith('dispatchRoute:')) {
          const dispatchRoutePk = payload.data.attributes.lock.lockedBy.split(':')[1];
          const dispatchRouteLock = {
            type: 'lock',
            id: dispatchRoutePk,
            attributes: {
              entityType: 'dispatchRoute',
              entityId: dispatchRoutePk,
              timeToLive: payload.data.attributes.lock.ttl,
              expirationTime: payload.data.attributes.lock.expiredAt,
              status: 'locked',
              persist: payload.data.attributes.lock.persist
            },
            relationships: {
              dispatchRoute: {
                data: {
                  type: 'dispatchRoute',
                  id: dispatchRoutePk
                }
              },
              owner: {
                data: {
                  type: 'sso-user',
                  id: payload.data.attributes.lock.user
                }
              }
            }
          };
          payload.included = payload.included || [];
          payload.included.push(dispatchRouteLock);
        }
      } else if (Ember.isPresent(payload.data.attributes.isLocked) && !payload.data.attributes.isLocked) {
        if (payload.data.attributes.lock && payload.data.attributes.lock.lockedBy.startsWith('dispatchRoute:')) {
          const dispatchRoutePk = payload.data.attributes.lock.lockedBy.split(':')[1];
          const dispatchRouteLock = {
            type: 'lock',
            id: dispatchRoutePk,
            attributes: {
              entityType: 'dispatchRoute',
              entityId: dispatchRoutePk,
              status: 'unlocked'
            },
            relationships: {
              dispatchRoute: {
                data: {
                  type: 'dispatchRoute',
                  id: dispatchRoutePk
                }
              }
            }
          };
          payload.included = payload.included || [];
          payload.included.push(dispatchRouteLock);
        }
      }

      return payload;
    }

  });

  _exports.default = _default;
});