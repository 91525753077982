define("adept-iq/models/route-template-availability", ["exports", "ember-data", "ember-cp-validations", "moment", "adept-iq/utils/regex-functions"], function (_exports, _emberData, _emberCpValidations, _moment, _regexFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ROUTE_TEMPLATE_DAYS = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  const ROUTE_TEMPLATE_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'holiday']; // model validation

  _exports.ROUTE_TEMPLATE_DAYS = ROUTE_TEMPLATE_DAYS;
  const Validations = (0, _emberCpValidations.buildValidations)({
    shiftStart: {
      description: 'Shift Start',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: '{description} cannot be blank'
      })]
    },
    routeLength: {
      description: 'Shift Length',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: _regexFunctions.REGEX_HHMM,
        message: '{description} must be formatted hh:mm'
      })]
    },
    DOWSelected: {
      description: 'day of week',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Requires at least 1 {description} selected'
      }), (0, _emberCpValidations.validator)('length', {
        min: 1,
        message: 'Requires at least 1 {description} selected'
      })]
    }
  });

  var _default = Model.extend(Validations, {
    DOWSelected: attr(),
    startTime: attr('date', {
      defaultValue: null
    }),
    endTime: attr('date'),
    calcEndDate: attr('date', {
      defaultValue: null
    }),
    shiftStart: attr('date'),
    shiftEnd: attr('date'),
    routeLength: attr('string'),
    routeLimit: attr('number'),
    sunday: attr('boolean', {
      defaultValue: false
    }),
    monday: attr('boolean', {
      defaultValue: false
    }),
    tuesday: attr('boolean', {
      defaultValue: false
    }),
    wednesday: attr('boolean', {
      defaultValue: false
    }),
    thursday: attr('boolean', {
      defaultValue: false
    }),
    friday: attr('boolean', {
      defaultValue: false
    }),
    saturday: attr('boolean', {
      defaultValue: false
    }),
    holiday: attr('boolean', {
      defaultValue: false
    }),
    vehicle: belongsTo('vehicle'),
    // place holder vehicle
    driver: belongsTo('driver'),
    // place holder driver
    routeTemplate: belongsTo('route-template'),
    // place holder vehicle
    shiftBreaks: hasMany('shift-break'),
    vehicleId: Ember.computed.readOnly('vehicle.id'),
    markedDeletedShiftBreaks: Ember.computed('shiftBreaks.@each.isDeleted', function () {
      return this.get('shiftBreaks').filter(shift => !shift.get('isDeleted'));
    }),
    calcShiftEnd: Ember.computed('shiftStart', 'routeLength', function () {
      const shiftStart = (0, _moment.default)(this.get('shiftStart'));
      const routeLength = this.get('routeLength').split(':');
      const shiftEnd = (0, _moment.default)();
      shiftEnd.set({
        h: shiftStart.hour(),
        m: shiftStart.minute(),
        s: 0
      });

      if (routeLength || routeLength.length === 2) {
        const routeLengthHour = parseInt(routeLength[0], 10) || 0;
        const routeLengthMinute = parseInt(routeLength[1], 10) || 0;
        shiftEnd.add({
          h: routeLengthHour,
          m: routeLengthMinute
        });
      }

      return shiftEnd;
    })
  });

  _exports.default = _default;
});