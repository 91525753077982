define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-mode-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DOWNLOAD_ACTION = 'Download';

  var _default = _component.default.extend({
    reconcileLambda: Ember.inject.service(),
    paginationEnabled: true,
    enableWarningRowChangeAlert: true,
    workspace: Ember.inject.service(),

    init() {
      this._super(...arguments);

      const tableActions = this.get('tableActions');
      this.set('tableActions', tableActions.filter(tableAction => tableAction.name !== DOWNLOAD_ACTION));
    },

    async didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    fetchDataQuery() {
      if (!this.get('fetchTableData')) {
        return [];
      }

      return this.get('fetchTableData')();
    },

    actions: {
      onRowClick(row, event) {
        this._super(row, event);

        if (this.get('onReconcileRowClick')) {
          this.get('onReconcileRowClick')(row);
        }
      }

    }
  });

  _exports.default = _default;
});