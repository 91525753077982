define("adept-iq/pods/components/side-drawer/scatter-chart-settings/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // const COLUMNS = {
  //   routeColumns: {
  //     status: {
  //       id: 'status',
  //       config: {
  //         id: 'status',
  //         index: 5,
  //         type: 'text',
  //         label: 'Status',
  //         valuePath: 'status',
  //         editable: true,
  //         hidden: false,
  //         format: 'HH:mm A',
  //         defaultWidth: 75
  //       },
  //       filterTypeId: 'stringEq',
  //       filterValues: ['Scheduled']
  //     }
  //   },
  //   tripColumns: {
  //     status: {
  //       id: 'status',
  //       config: {
  //         id: 'status',
  //         index: 5,
  //         type: 'text',
  //         label: 'Status',
  //         valuePath: 'status',
  //         editable: true,
  //         hidden: false,
  //         defaultWidth: 75
  //       },
  //       filterTypeId: null,
  //       filterValues: []
  //     }
  //   }
  // };
  const VisualOptions = [{
    id: 'value',
    name: 'Show Value',
    isChecked: true
  }, {
    id: 'percentage',
    name: 'Show Percentage',
    isChecked: false
  }, {
    id: 'total',
    name: 'Show Total',
    isChecked: true
  }];
  var _default = [{
    name: 'Packets',
    modelName: 'packet',
    title: '',
    visualOptions: VisualOptions
  }];
  _exports.default = _default;
});