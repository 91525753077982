define("adept-iq/pods/components/iq-widgets/subscriptions-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/subscriptions-form-widget/config", "moment"], function (_exports, _component, _config, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      const records = this.get('editableRecords') || [];
      records.forEach(record => {
        if (record.get('exclusions').length === 0) {
          const exclusionRecord = this.get('store').createRecord('subscription-exclusion');
          exclusionRecord.set('subscription', record);
          record.get('exclusions').pushObject(exclusionRecord);
        }
      });
    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const requestedTime = (0, _moment.default)(record.get('requestedTime'));
        const recurrencePatterns = record.recurrencePatterns || [];

        if (Ember.isNone(requestedTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Date should be there');
        }

        recurrencePatterns.forEach(recurrencePattern => {
          if (recurrencePattern.recurring) {
            if (recurrencePattern.type === 'monthly') {
              if (Ember.isNone(recurrencePattern.weekOfMonth) && Ember.isNone(recurrencePattern.dayOfMonth)) {
                valid = false;
                this.get('editModal.errors').pushObject('Day of month and week of month cannot be both blank.');
              } else if (!Ember.isNone(recurrencePattern.weekOfMonth) && recurrencePattern.selectedDOWs.length < 1) {
                valid = false;
                this.get('editModal.errors').pushObject('Select day of the week.');
              }
            } else if (recurrencePattern.selectedDOWs.length < 1) {
              valid = false;
              this.get('editModal.errors').pushObject('Select the recurring day for subscription.');
            }

            if (Ember.isNone(recurrencePattern.subscriptionStartDate)) {
              valid = false;
              this.get('editModal.errors').pushObject('Must select a start date for subscription.');
            }
          }
        });
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        const lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          const records = this.get('editableRecords') || [];
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(_ref => {
            let {
              record,
              properties
            } = _ref;
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onApplyClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
        }
      }

    }
  });

  _exports.default = _default;
});