define("adept-iq/pods/dashboard-info/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "adept-iq/config/environment", "adept-iq/config/api-urls"], function (_exports, _authenticatedRouteMixin, _environment, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LOCAL_STORAGE_KEY = 'dashboard-info';

  function getAccessData(userId) {
    let accessData;
    let data = localStorage.getItem(LOCAL_STORAGE_KEY) || '{}';

    try {
      let json = JSON.parse(data);

      if (json.userId === userId && json.accessData) {
        accessData = json.accessData;
      }
    } catch (e) {}

    return accessData;
  }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    classNames: ['road-supervisor-modal'],
    user: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    providerNames: Ember.computed.alias('session.data.authenticated.tokenInfo.providerNames'),

    resetController(controller) {
      controller.set('vehicleId', undefined);
      controller.set('driverId', undefined);
      controller.set('selectedVehicle', undefined);

      this._super(...arguments);
    },

    setupController(controller) {
      let category = 'supervisor_vehicle_list';
      controller.set('vehicleProvidersList', '');

      if (this.user.isRoadSupEnable()) {
        controller.set('isDisclaimerAgreed', false);
      }

      this.store.query('cs-config-item', {
        category
      }).then(configItems => {
        return {
          data: configItems.map(configItem => {
            const list = configItem.get('value').supervisorVehicleList;
            const matchedSupervisorVehicleList = list.filter(item => {
              if (this.providerNames.includes('ALL')) {
                return true;
              }

              return this.providerNames.includes(item.provider);
            });
            controller.set('vehicleProvidersList', matchedSupervisorVehicleList);
          })
        };
      });
    },

    apiVehicleSearch: async function (callSign) {
      const rql = `filter=eq(name,'${callSign}')`;
      const apiUrl = `${_apiUrls.API.vehicleService.host}/vehicle?${rql}`;
      const session = this.get('session');
      const response = await this.get('ajax').request(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json'
      });

      if (response.data && response.data.length > 0) {
        const vehicleRepo = response.data.map(vehicle => {
          return {
            vehicleId: vehicle.id,
            vehicle: vehicle.attributes.name,
            provider: vehicle.relationships.providerName.data.id
          };
        });
        return vehicleRepo[0];
      }

      return null;
    },
    apiDriverSearch: async function (badge) {
      const rql = `filter=eq(driverId,'${badge}')`;
      const apiUrl = `${_apiUrls.API.driverService.host}/driver?${rql}`;
      const session = this.get('session');
      const response = await this.get('ajax').request(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json'
      });

      if (response.data && response.data.length > 0) {
        const driverRepo = response.data.map(driver => {
          return {
            driverId: driver.id,
            driver: driver.attributes.driverId
          };
        });
        return driverRepo[0];
      }

      return null;
    },
    verifyVehicleDriver: async function (selectedProvider, route) {
      const vehicle = await route.apiVehicleSearch(selectedProvider.vehicleId);
      const driver = await route.apiDriverSearch(selectedProvider.driverId);

      if (vehicle && driver) {
        route.set('controller.vehicle', vehicle.vehicle);
        route.set('controller.providerId', vehicle.provider);
        route.set('controller.vehicleId', vehicle.vehicleId);
        route.set('controller.driver', driver.driver);
        route.set('controller.driverId', driver.driverId);
      } else {
        const message = `CAN'T GET VEHICLE ${selectedProvider.vehicleId} OR DRIVER ${selectedProvider.driverId}`;
        route.get('notifications').warning(message);
      }
    },

    beforeModel() {
      if (this.user.isRoadSupEnable()) {
        let accessData = getAccessData(this.user.userId);

        if (!accessData) {
          return;
        }
      }

      this.transitionTo('index');
    },

    actions: {
      onClickCheckBox(index) {
        if (index.target.value) {
          var selectedProvider = this.get('controller.vehicleProvidersList').objectAt(index.target.value);

          if (_environment.default.APP.avlmLite) {
            this.set('controller.vehicleId', selectedProvider.vehicleId);
            this.set('controller.providerId', selectedProvider.provider);
            this.set('controller.vehicle', selectedProvider.vehicleId);
            this.set('controller.driver', selectedProvider.driverId);
            this.set('controller.driverId', selectedProvider.driverId);
          } else {
            this.verifyVehicleDriver(selectedProvider, this);
          }
        } else {
          this.set('controller.vehicleId', '');
          this.set('controller.providerId', '');
          this.set('controller.driverId', '');
          this.set('controller.vehicle', '');
          this.set('controller.driver', '');
        }
      },

      setAccessData(vehicle, vehicleId, driver, driverId, providerId) {
        let provider = providerId;
        let accessData = {
          vehicle,
          vehicleId,
          driver,
          driverId,
          provider
        };
        let json = {
          userId: this.user.userId,
          accessData
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(json)); // this.transitionTo('index');
      },

      onAgreeButtonClick() {
        this.set('controller.isDisclaimerAgreed', true);
      }

    }
  });

  _exports.default = _default;
});