define("adept-iq/pods/components/rider-management-widget/rider-travel-needs/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy"], function (_exports, _emberConcurrency, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PCA = 'pca';
  const COMPANION = 'companion';

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    rider: Ember.inject.service(),
    riderTravelNeedRecord: null,
    tableRef: null,
    configTravelNeeds: null,
    vehicleCapacityType: null,
    vehicleCapacityCount: null,
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    origSelectedTravelNeedTypeName: null,
    riderTravelNeeds: Ember.computed('riderChangeSet.{riderTravelNeeds,riderTravelNeeds.[]}', function () {
      return this.get('riderChangeSet.riderTravelNeeds').toArray();
    }),
    maxLoadTime: Ember.computed('riderTravelNeeds.@each.loadTime', function () {
      return this.get('riderTravelNeeds').reduce((acc, rtn) => {
        const loadTime = parseInt(rtn.loadTime, 10);
        let maxTime = acc;
        maxTime = loadTime > maxTime ? loadTime : maxTime;
        return maxTime;
      }, 0);
    }),
    maxUnloadTime: Ember.computed('riderTravelNeeds.@each.unloadTime', function () {
      return this.get('riderTravelNeeds').reduce((acc, rtn) => {
        const unloadTime = parseInt(rtn.unloadTime, 10);
        let maxTime = acc;
        maxTime = unloadTime > maxTime ? unloadTime : maxTime;
        return maxTime;
      }, 0);
    }),
    travelNeedTypeOptions: Ember.computed('configTravelNeeds', 'riderChangeSet.{riderTravelNeeds,riderTravelNeeds.[]}', function () {
      const configTravelNeeds = this.get('configTravelNeeds');
      const existingRiderTravelNeeds = this.get('riderChangeSet.riderTravelNeeds');
      const existingTravelNeedIDs = existingRiderTravelNeeds.map(rtn => rtn.get('travelNeedType.id'));

      if (configTravelNeeds) {
        return configTravelNeeds.filter(ctnd => {
          return !existingTravelNeedIDs.includes(ctnd.get('name'));
        });
      }

      return [];
    }),

    async init() {
      this._super(...arguments);

      this.setTravelNeedTypeLoadTimeAndVehicleAttributes = this.setTravelNeedTypeLoadTimeAndVehicleAttributes.bind(this);
      this.setVehicleProperties = this.setVehicleProperties.bind(this);
      this.onNewRiderTravelNeedRecord = this.onNewRiderTravelNeedRecord.bind(this);
      this.onEditRiderTravelNeedRecord = this.onEditRiderTravelNeedRecord.bind(this);
      this.onDeleteRiderTravelNeedRecord = this.onDeleteRiderTravelNeedRecord.bind(this);
      this.resetForm = this.resetForm.bind(this);
      this.onUndoRiderTravelNeedsRecord = this.onUndoRiderTravelNeedsRecord.bind(this);
      this.OnTableRowClick = this.OnTableRowClick.bind(this);
      const configTravelNeeds = await this.get('rider').getConfigTravelNeedTypes();
      this.setProperties({
        configTravelNeeds
      });
      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onSaveRiderTravelNeedsRecord = this.get('onSaveRiderTravelNeedsRecord');
      const onNewRiderTravelNeedRecord = this.get('onNewRiderTravelNeedRecord');
      const onDeleteRiderTravelNeedRecord = this.get('onDeleteRiderTravelNeedRecord');
      const onEditRiderTravelNeedRecord = this.get('onEditRiderTravelNeedRecord');
      const onUndoRiderTravelNeedsRecord = this.get('onUndoRiderTravelNeedsRecord');
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveRiderTravelNeedsRecord,
          newAction: onNewRiderTravelNeedRecord,
          deleteAction: onDeleteRiderTravelNeedRecord,
          editAction: onEditRiderTravelNeedRecord,
          undoAction: onUndoRiderTravelNeedsRecord,
          isNewBtnDisabled: false,
          isEditBtnDisabled: true,
          isDeleteBtnDisabled: true,
          isEditing: false
        });
      });
    },

    async OnTableRowClick(selectedRiderTavelNeed) {
      await this.get('onUndoRiderTravelNeedsRecord')();
      this.set('riderTravelNeedRecord', (0, _unwrapProxy.unwrapProxy)(selectedRiderTavelNeed));
      const configTravelNeed = this.get('configTravelNeeds').find(cftnd => {
        return cftnd.get('name') === selectedRiderTavelNeed.get('travelNeedType.id');
      });
      this.get('setVehicleProperties')(configTravelNeed);
      this.set('editAPI.isNewBtnDisabled', false);
      this.set('editAPI.isEditBtnDisabled', false);
      this.set('editAPI.isDeleteBtnDisabled', false);
      this.set('editAPI.isEditing', false);
    },

    onEditRiderTravelNeedRecord() {
      const riderTravelNeedRecordToEdit = this.get('tableRef.checkedRows.firstObject');
      const configTravelNeed = this.get('configTravelNeeds').find(cftnd => {
        return cftnd.get('name') === riderTravelNeedRecordToEdit.get('travelNeedType.id');
      });
      this.set('riderTravelNeedRecord', riderTravelNeedRecordToEdit);
      this.set('origSelectedTravelNeedTypeName', riderTravelNeedRecordToEdit.get('travelNeedTypeName'));
      this.set('editAPI.isNewBtnDisabled', true);
      this.set('editAPI.isEditBtnDisabled', true);
      this.set('editAPI.isDeleteBtnDisabled', true);
      this.set('editAPI.isEditing', true);
      this.get('setVehicleProperties')(configTravelNeed);
    },

    confirmRiderTravelNeedDeletion(tip, riderTravelNeedRecord) {
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: tip,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          this.get('resetForm')();
          await riderTravelNeedRecord.destroyRecord();
          notifications.success('Rider Travel Need Successfully Deleted');
          this.set('editAPI.isNewBtnDisabled', false);
          this.set('editAPI.isEditBtnDisabled', true);
          this.set('editAPI.isDeleteBtnDisabled', true);
          this.set('editAPI.isEditing', false);
          tooltip.reset();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        }
      });
    },

    isLastConsumableTravelNeed() {
      const riderTravelNeeds = this.get('riderTravelNeeds') || [];
      const riderTravelNeedToDelete = this.get('tableRef.checkedRows.firstObject');
      const hasConsumableRemains = riderTravelNeeds.filter(travelNeed => travelNeed.get('id') !== riderTravelNeedToDelete.get('id')).find(travelNeed => travelNeed.get('travelNeedType.isConsumable') && travelNeed.get('count') > 0);
      if (!hasConsumableRemains) return true;
      return false;
    },

    showDeleteLastConsumableTravelNeedsWarningMessage() {
      const tooltip = this.get('tooltip');
      const message = 'Unable to delete. The rider must have at least one travel need with a Count value of 1 or more. This ensures trips booked for the rider indicate appropriate capacity.';
      tooltip.pushConfirmation({
        title: 'Rider Management',
        hasOverlay: true,
        tip: message,
        primaryActionText: 'OK',
        primaryAction: () => {
          tooltip.reset();
        }
      });
    },

    onDeleteRiderTravelNeedRecord() {
      const riderTravelNeedRecord = this.get('tableRef.checkedRows.firstObject');

      if (this.isLastConsumableTravelNeed()) {
        this.showDeleteLastConsumableTravelNeedsWarningMessage();
        return;
      }

      this.set('riderTravelNeedRecord', riderTravelNeedRecord);
      const tip = 'Are you sure you want to delete this travel need?';
      this.confirmRiderTravelNeedDeletion(tip, riderTravelNeedRecord);
    },

    async onUndoRiderTravelNeedsRecord() {
      const riderTravelNeedId = this.get('rider').getLastAccessedRiderTravelNeedId();
      const riderTravelNeedRecord = this.get('riderTravelNeedRecord');
      let isNewRecord = false;

      if (Ember.isPresent(riderTravelNeedId)) {
        await this.store.findRecord('rider-travel-need', riderTravelNeedId);
      }

      if (Ember.isPresent(riderTravelNeedRecord)) {
        const origVehicleCapacity = this.get('configTravelNeeds').find(travelNeed => travelNeed.get('name') === this.get('origSelectedTravelNeedTypeName'));
        isNewRecord = riderTravelNeedRecord.isNew;
        this.get('setVehicleProperties')(origVehicleCapacity);
        await riderTravelNeedRecord.rollbackAttributes();

        if (!Ember.isPresent(origVehicleCapacity)) {
          this.set('vehicleCapacityCount', '');
          this.set('vehicleCapacityType', '');
        }
      } else {
        this.get('resetForm')();
      }

      if (isNewRecord) {
        this.set('editAPI.isEditBtnDisabled', true);
        this.set('editAPI.isDeleteBtnDisabled', true);
      } else {
        this.set('editAPI.isEditBtnDisabled', false);
        this.set('editAPI.isDeleteBtnDisabled', false);
      }

      this.set('editAPI.isNewBtnDisabled', false);
      this.set('editAPI.isEditing', false);
    },

    onNewRiderTravelNeedRecord() {
      const riderTravelNeedRecord = this.get('store').createRecord('rider-travel-need', {
        count: 1,
        passengerType: this.store.peekRecord('passenger-type', 'client')
      });
      const riderTravelNeedType = this.get('store').createRecord('travel-need-type');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('origSelectedTravelNeedTypeName', null);
      riderTravelNeedRecord.set('travelNeedType', riderTravelNeedType);
      this.set('riderTravelNeedRecord', riderTravelNeedRecord);
      this.get('resetForm')();
      this.set('editAPI.isNewBtnDisabled', true);
      this.set('editAPI.isEditBtnDisabled', true);
      this.set('editAPI.isDeleteBtnDisabled', true);
      this.set('editAPI.isEditing', true);
    },

    validateRiderTravelNeed(riderTravelNeedRecord) {
      const riderTravelNeedTypeName = riderTravelNeedRecord.get('travelNeedTypeName');
      const loadTime = riderTravelNeedRecord.get('loadTime');
      const unloadTime = riderTravelNeedRecord.get('unloadTime');

      if (riderTravelNeedTypeName && !Ember.isEmpty(loadTime) && !Ember.isEmpty(unloadTime)) {
        return true;
      }

      return false;
    },

    onSaveRiderTravelNeedsRecord: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      const rider = this.get('riderChangeSet').get('_internalModel');
      const riderTravelNeedRecord = this.get('riderTravelNeedRecord');
      const isNewRecord = riderTravelNeedRecord.get('isNew');
      const isRTNValid = yield this.validateRiderTravelNeed(riderTravelNeedRecord);

      if (!isRTNValid) {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: 'TravelNeed Name, Load Time and Unload Time are required.',
          primaryActionText: 'Ok',
          primaryAction: () => {
            return tooltip.reset();
          }
        });
      } else {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: 'Are you sure you want to save these changes?',
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            try {
              riderTravelNeedRecord.set('rider', rider);
              const editOrNewRecord = riderTravelNeedRecord.get('isNew') ? 'added' : 'edited';
              const rTN = await riderTravelNeedRecord.save();
              notifications.success(`Rider Travel Need successfully ${editOrNewRecord}`);
              this.get('rider').setLastAccessedRiderTravelNeedId(rTN.id);

              if (isNewRecord) {
                this.set('editAPI.isNewBtnDisabled', false);
                this.set('editAPI.isEditBtnDisabled', true);
                this.set('editAPI.isDeleteBtnDisabled', true);
                this.set('editAPI.isEditing', false);
              } else {
                this.set('editAPI.isNewBtnDisabled', false);
                this.set('editAPI.isEditBtnDisabled', false);
                this.set('editAPI.isDeleteBtnDisabled', false);
                this.set('editAPI.isEditing', false);
              }

              tooltip.reset();
            } catch (e) {
              notifications.warning('Rider Travel Need failed to save');
              tooltip.reset();
              console.warn(e); // eslint-disable-line
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            tooltip.reset();
          }
        });
      }
    }),

    resetForm() {
      const riderTravelNeedRecord = this.get('riderTravelNeedRecord');

      if (riderTravelNeedRecord) {
        riderTravelNeedRecord.set('travelNeedType', null);
        riderTravelNeedRecord.set('loadTime', '');
        riderTravelNeedRecord.set('unloadTime', '');
      }

      this.set('vehicleCapacityType', '');
      this.set('vehicleCapacityCount', '');
    },

    setVehicleProperties(configTravelNeed) {
      if (configTravelNeed) {
        const configTravelNeedValue = configTravelNeed.get('value');
        this.set('vehicleCapacityCount', configTravelNeedValue.vehicleCapacityCount);
        this.set('vehicleCapacityType', configTravelNeedValue.vehicleCapacityType);
      }
    },

    setTravelNeedTypeLoadTimeAndVehicleAttributes(selectedConfigTravelNeed) {
      const riderTravelNeedRecord = this.get('riderTravelNeedRecord');
      const configTravelNeedName = selectedConfigTravelNeed.get('name');
      const travelNeedTypeRecord = this.get('store').peekRecord('travel-need-type', configTravelNeedName);
      riderTravelNeedRecord.set('travelNeedType', travelNeedTypeRecord);
      riderTravelNeedRecord.set('loadTime', travelNeedTypeRecord.get('loadTime'));
      riderTravelNeedRecord.set('unloadTime', travelNeedTypeRecord.get('unloadTime'));

      if (configTravelNeedName === PCA || configTravelNeedName === COMPANION) {
        const passengerTypeRecord = this.get('store').peekRecord('passenger-type', configTravelNeedName);

        if (Ember.isPresent(passengerTypeRecord)) {
          riderTravelNeedRecord.set('passengerType', passengerTypeRecord);
        }
      }

      this.get('setVehicleProperties')(selectedConfigTravelNeed);
    },

    actions: {
      onTravelNeedTypeChange(selectedConfigTravelNeed) {
        this.setTravelNeedTypeLoadTimeAndVehicleAttributes(selectedConfigTravelNeed);
      }

    }
  });

  _exports.default = _default;
});