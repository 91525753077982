define("adept-iq/pods/components/iq-widgets/zones-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/zones-widget/config", "adept-iq/utils/filters", "adept-iq/utils/sorts"], function (_exports, _component, _config, _filters, _sorts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['zones-widget', 'data-test-zones-widget'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    config: _config.default,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);
    },

    fetchDataQuery() {
      const params = {
        sorts: []
      };

      try {
        // this only applies for server-side pagination
        if (this.get('paginationEnabled')) {
          params.page = {
            offset: this.get('records.length'),
            limit: this.get('limit')
          };
        }

        const sort = this.buildSortParam();

        if (sort) {
          params.sorts.push(sort);
        }

        const includes = this.get('defaultIncludes');

        if (!Ember.isEmpty(includes)) {
          params.includes = includes;
        }

        const filters = this.setupFilterType();
        params.filter = (0, _filters.buildCompoundFilterNode)('and', filters);
        const modelName = this.get('config.modelName');
        return this.query(modelName, params);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Workspace view is not refreshed on selecting or deselecting' + error);
      }
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const activeData = store.peekAll(modelName) || [];
      const currentSchedule = this.get('workspace.currentSchedule');
      let filteredData = activeData.filter(filterFn).sort(compareFn);

      if (Ember.isEmpty(currentSchedule)) {
        return [];
      } //NYAAR-19157-Sorting is not working for the Name column


      if (!params.filter) {
        const zoneData = await store.findAll('zone');
        filteredData = zoneData.filter(filterFn).sort(compareFn);
      }

      return filteredData;
    },

    actions: {
      //NYAAR-17671  widgets are not maximized and cross icon should also not display in this widgets.
      onHeaderDoubleClick() {
        const maximizer = this.get('maximizer');
        maximizer.minimize();
      }

    }
  });

  _exports.default = _default;
});