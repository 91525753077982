define("adept-iq/pods/components/side-drawer/reconcile-schedule-unscheduled-trips/component", ["exports", "adept-iq/pods/components/side-drawer-container/component", "adept-iq/pods/components/side-drawer/reconcile-schedule-unscheduled-trips/config", "ember-concurrency", "ember-light-table", "adept-iq/utils/format-function", "adept-iq/utils/format-text-extension", "moment"], function (_exports, _component, _config, _emberConcurrency, _emberLightTable, _formatFunction, _formatTextExtension, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const pickupTypeArray = ['pickup', 'pick', 'p'];
  const dropoffTypeArray = ['dropoff', 'drop', 'd'];
  const breakTypeArray = ['break', 'b'];

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['reconcile-widget-base'],
    config: _config.default,
    selectedProvider: null,
    selectedRoute: null,
    callBack: Ember.computed.readOnly('stackFrame.options.action'),
    tripDetails: Ember.computed.readOnly('stackFrame.options.trip'),
    title: Ember.computed.readOnly('stackFrame.options.title'),
    tripDate: Ember.computed.alias('reconcile.reconcileDate'),
    currentPickupStop: Ember.computed.alias('tripDetails.pickupStopDetails'),
    currentDropoffStop: Ember.computed.alias('tripDetails.dropoffStopDetails'),
    reconcileNewRouteStartTime: Ember.computed.alias('reconcileNewRoute.actualStartTime'),
    reconcileNewRouteEndTime: Ember.computed.alias('reconcileNewRoute.actualEndTime'),
    reconcileTripDate: Ember.computed.alias('reconcile.reconcileDate'),
    canSave: Ember.computed.and('selectedProvider', 'selectedDateTimeRage.{pickTime,dropTime}', 'selectedRoute'),
    canUndo: Ember.computed.or('selectedProvider', 'selectedRoute', 'selectedDateTimeRage.{pickTime,dropTime}'),
    providerOptions: Ember.computed('activeContext.topActiveContext', 'selectedProvider', function () {
      return this.get('store').peekAll('provider');
    }),
    routeOptions: Ember.computed('routeIdsList', function () {
      const routeList = this.get('routeIdsList');

      if (!Ember.isEmpty(routeList) && routeList.length) {
        return routeList;
      }

      return [];
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'selectedDateTimeRage', {
        pickTime: null,
        dropTime: null,
        startofday: (0, _moment.default)(this.reconcileTripDate).startOf('day').toISOString(),
        endofday: (0, _moment.default)(this.reconcileTripDate).endOf('day').toISOString()
      });
      const stopsTable = new _emberLightTable.default(this.config.stopColumns);
      Ember.set(this, 'stopsTable', stopsTable);
      Ember.set(this, 'newRouteStops', []);
    },

    didInsertElement() {
      this._super(...arguments);

      this.setSideDrawerWidth('698px');
    },

    manageRouteDetails() {
      if (!this.selectedProvider || !this.selectedRoute) {
        return;
      }

      if (this.selectedDateTimeRage.pickTime && this.selectedDateTimeRage.dropTime) {
        this.manageRouteDetailsWithGvnTripDetails(this.newRouteStops);
      } else {
        this.manageRouteDetailsWithoutGvnTripDetails(this.newRouteStops);
      }

      this.setCurrentTripSelected();
    },

    manageRouteDetailsWithGvnTripDetails(stopList) {
      const newStopList = this.getScheduledTripList();
      const finalList = this.getSortedStopList(stopList, newStopList);
      this.setStopTable(finalList);
    },

    getScheduledTripList() {
      const stopList = [];
      const passenger = this.tripDetails.passenger;
      this.tripDetails.stops.map(stop => {
        const stopObj = Ember.Object.create(stop);
        const address = Ember.Object.create(stop.address);
        address.set('formattedCity', this.getFormattedCity(address));
        stopObj.set('address', address);
        stopObj.set('passenger', passenger);
        stopObj.set('formattedPromiseTimeAsTime', this.getFormattedPromiseTime(stopObj));
        stopObj.set('formattedRequestTimeAsTime', this.getFormattedRequestTime(stopObj));
        stopObj.set('formattedEtaAsTime', this.getFormattedEtaTime(stopObj));
        stopObj.set('tripId', this.tripDetails.tripId);
        this.setTimeDetails(stopObj, true);
        stopList.push(stopObj);
      });
      return stopList;
    },

    manageRouteDetailsWithoutGvnTripDetails(stopList) {
      const filteredList = this.getStopListExceptSchedluedTrip(stopList);
      let ordinal = 0;
      filteredList.map(stop => {
        ordinal++;
        stop.set('ordinal', ordinal);
      });
      this.setStopTable(filteredList);
    },

    setStopTable(stopList) {
      this.get('stopsTable').setRows([]);

      if (stopList && stopList.length > 0) {
        this.get('stopsTable').setRows(stopList);
      }
    },

    getStopListExceptSchedluedTrip(stopList) {
      return stopList.filter(stop => stop.tripId !== this.tripDetails.tripId);
    },

    setCurrentTripSelected() {
      const rows = Ember.get(this, 'stopsTable.rows');
      rows.map(row => {
        if (row.content.tripId === this.tripDetails.tripId) {
          row.set('selected', true);
        }
      });
    },

    setNewRouteStopTable() {
      const trips = this.store.peekAll(this.config.tripModel).filter(trip => {
        return trip.routeId === this.selectedRoute.routeId;
      });
      const stopList = this.getStopListByTrips(trips);
      const breakList = this.getBreakListByRoute(this.selectedRoute.routeId);
      const finalList = this.getSortedStopList(stopList, breakList);
      Ember.set(this, 'newRouteStops', finalList);
      this.manageRouteDetails();
    },

    getSortedStopList(stopList, otherList) {
      const mergedStopList = stopList.concat(otherList);
      const sortedStopList = this.sortList(mergedStopList, 'updatedEta', true);
      this.setOrdinals(sortedStopList);
      return sortedStopList;
    },

    setOrdinals(sortedStopList) {
      let ordinal = 0;
      sortedStopList.map(stop => {
        ordinal++;
        stop.set('ordinal', ordinal);
      });
    },

    sortList(list, key, isDate) {
      return list.sort(function (a, b) {
        if (isDate) {
          // eslint-disable-next-line no-nested-ternary
          return (0, _moment.default)(a[key]) > (0, _moment.default)(b[key]) ? 1 : (0, _moment.default)(b[key]) > (0, _moment.default)(a[key]) ? -1 : 0;
        } // eslint-disable-next-line no-nested-ternary


        return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
      });
    },

    getStopListByTrips(trips) {
      const stopList = [];

      if (!Ember.isEmpty(trips)) {
        trips.map(trip => {
          const stops = Ember.get(this, 'store').peekAll(this.config.stopModel).filter(stop => {
            return stop.tripId === trip.tripId;
          });

          if (!Ember.isEmpty(stops)) {
            stops.map(stop => {
              const addresses = Ember.get(this, 'store').peekAll(this.config.addressModel).filter(address => {
                return address.stopId === stop.stopId;
              })[0];
              stop.set('address', addresses);
              const passengers = Ember.get(this, 'store').peekAll(this.config.passengerModel).filter(passenger => {
                return passenger.passengerId === trip.passengerId;
              })[0];
              stop.set('passenger', passengers);
              this.setTimeDetails(stop);
              stopList.push(stop);
            });
            trip.set('stops', stopList);
          }
        });
      }

      return stopList;
    },

    setTimeDetails(stop) {
      let date = stop.get('eta') ? (0, _moment.default)(stop.get('eta')).format('MM-DD-YYYY') : (0, _moment.default)(this.reconcileTripDate).format('MM-DD-YYYY');

      if (this.selectedDateTimeRage.pickTime && this.selectedDateTimeRage.dropTime && stop.tripId === this.tripDetails.tripId) {
        date = (0, _moment.default)(this.reconcileTripDate).format('MM-DD-YYYY'); // for stops

        if (stop.type && pickupTypeArray.includes(stop.type.toLowerCase())) {
          stop.set('updatedEtaAsTime', (0, _moment.default)(this.selectedDateTimeRage.pickTime).format('hh:mm A'));
        } else if (stop.type && dropoffTypeArray.includes(stop.type.toLowerCase())) {
          stop.set('updatedEtaAsTime', (0, _moment.default)(this.selectedDateTimeRage.dropTime).format('hh:mm A'));
        }
      } else {
        // for stops
        // eslint-disable-next-line no-lonely-if
        if (stop.type && pickupTypeArray.includes(stop.type.toLowerCase())) {
          stop.set('updatedEtaAsTime', stop.get('formattedEtaAsTime'));
        } else if (stop.type && dropoffTypeArray.includes(stop.type.toLowerCase())) {
          stop.set('updatedEtaAsTime', stop.get('formattedEtaAsTime'));
        }
      } // for breaks


      if (stop.type && breakTypeArray.includes(stop.type.toLowerCase())) {
        stop.set('updatedEtaAsTime', stop.get('formattedEtaAsTime'));
      }

      stop.set('updatedEta', (0, _moment.default)(date + ' ' + stop.get('updatedEtaAsTime')));
    },

    getBreakListByRoute(routeId) {
      const breakList = [];
      const breaks = Ember.get(this, 'store').peekAll(this.config.stopModel).filter(breakStop => {
        return breakStop.routeId === routeId && breakStop.type === 'break';
      });
      breaks.map(breakData => {
        this.setTimeDetails(breakData);
        breakData.set('tripId', breakData.stopId);
        const addresses = Ember.get(this, 'store').peekAll(this.config.addressModel).filter(address => {
          return address.stopId === breakData.stopId;
        })[0];
        breakData.set('address', addresses);
        breakList.push(breakData);
      });
      return breakList;
    },

    getFormattedPromiseTime(stopObj) {
      const dt = Ember.get(stopObj, 'promiseTime');
      return dt ? (0, _moment.default)(dt).format('hh:mm A') : '';
    },

    getFormattedRequestTime(stopObj) {
      const dt = Ember.get(stopObj, 'requestTime');
      return dt ? (0, _moment.default)(dt).format('hh:mm A') : '';
    },

    getFormattedEtaTime(stopObj) {
      const dt = Ember.get(stopObj, 'eta');
      return dt ? (0, _moment.default)(dt).format('hh:mm A') : '';
    },

    getFormattedCity(addressObj) {
      const c = Ember.get(addressObj, 'city');

      if (Ember.isEmpty(c)) {
        const add = Ember.get(addressObj, 'formattedAddress');

        if (!Ember.isEmpty(add)) {
          const addStr = (0, _formatTextExtension.convertToPascalCase)(add);
          const addArray = addStr.split(',');
          return addArray[addArray.length - 2];
        }
      }

      return (0, _formatTextExtension.convertToPascalCase)(c);
    },

    getRoutesByGvnProvider(providerName) {
      this.get('fetchRouteIds').perform(providerName);
    },

    getRouteById(routeId) {
      this.get('fetchDestinationRouteDetails').perform(routeId);
    },

    resetTableRows() {
      Ember.set(this, 'reconcileNewRoute', []);
      Ember.set(this, 'newRouteStops', []);
      this.get('stopsTable').setRows([]);
    },

    showToolTip() {
      const tooltip = Ember.get(this, 'tooltip');
      const title = 'Schedule Trip to a Route';
      const tip = `Schedule Trip ${this.tripDetails.tripId} to Route ${this.selectedRoute.routeId}.`;
      const successMessage = `TRIP ${this.tripDetails.tripId} WAS SUCCESSFULLY SCHEDULED TO ROUTE ${this.selectedRoute.routeId}`;
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          return Ember.get(this, 'saveTask').perform().then(() => {
            this.send('cancel');
            Ember.get(this, 'workspace').popState('reconcileScheduleUnscheduledTrip');
            Ember.get(this, 'notifications').success(successMessage);
            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in scheduling trip, please try again.';
                break;
            }

            this.set('errorMessage', message);
            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    checkValidations() {
      if (this.get('selectedDateTimeRage.pickTime') && this.get('selectedDateTimeRage.dropTime')) {
        if (!_moment.default.utc(this.get('selectedDateTimeRage.dropTime')).isAfter(_moment.default.utc(this.get('selectedDateTimeRage.pickTime')))) {
          Ember.set(this, 'errorMessage', 'Pickup time can not be greater than or equal to dropoff time');
          return false;
        }

        if (_moment.default.utc(this.reconcileNewRouteStartTime).isAfter(_moment.default.utc(this.get('selectedDateTimeRage.pickTime')))) {
          Ember.set(this, 'errorMessage', 'Pickup time can not be less than route start time');
          return false;
        }

        if (_moment.default.utc(this.get('selectedDateTimeRage.dropTime')).isAfter(_moment.default.utc(this.reconcileNewRouteEndTime))) {
          Ember.set(this, 'errorMessage', 'Dropoff time can not be greater than route end time');
          return false;
        }
      }

      return true;
    },

    saveTask: (0, _emberConcurrency.task)(function* () {
      const tripAdapter = this.store.adapterFor(this.config.tripModel);
      const payload = {
        routeId: this.selectedRoute.routeId,
        tripId: this.tripDetails.tripId,
        providerName: this.selectedProvider.id,
        pickupTime: this.selectedDateTimeRage.pickTime,
        dropoffTime: this.selectedDateTimeRage.dropTime,
        userId: this.session.data.authenticated.userId
      };

      try {
        return yield tripAdapter.patchTrip(payload, 'schedule trip', this.tripDetails.route.routeId);
      } catch (e) {
        throw e;
      }
    }),
    fetchDestinationRouteDetails: (0, _emberConcurrency.task)(function* (routeId) {
      let routePayload = null;
      const routeData = yield Ember.get(this, 'store').query(this.config.routeModel, {
        routeId
      });

      if (Ember.isEmpty(routeData)) {
        Ember.set(this, 'reconcileNewRoute', []);
        return;
      }

      routeData.map(data => {
        routePayload = data;
      });
      Ember.set(this, 'reconcileNewRoute', routePayload);
      this.setNewRouteStopTable();
    }),
    fetchRouteIds: (0, _emberConcurrency.task)(function* () {
      const routeIds = [];
      const dateRange = (0, _formatFunction.getDateRangeForSearch)(this.reconcileTripDate);
      const routeIdsArray = yield Ember.get(this, 'store').query(this.config.routeIdModel, {
        providerName: this.selectedProvider.id,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate
      });

      if (Ember.isEmpty(routeIdsArray) || !routeIdsArray.length) {
        Ember.set(this, 'routeIdsList', []);
        return;
      }

      routeIdsArray.map(data => {
        routeIds.push(data);
      });
      Ember.set(this, 'routeIdsList', routeIds);
    }),
    actions: {
      onSaveClick() {
        if (this.checkValidations()) {
          Ember.set(this, 'errorMessage', null);
          this.showToolTip();
        }
      },

      onUndoClick() {
        this.setProperties({
          selectedProvider: null,
          selectedRoute: null,
          'selectedDateTimeRage.pickTime': null,
          'selectedDateTimeRage.dropTime': null
        });
        this.resetTableRows();
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
        Ember.set(this, 'canUndo', true);
        this.manageRouteDetails();
      },

      onChangeProvider(provider) {
        this.setProperties({
          selectedProvider: provider,
          selectedRoute: null // 'selectedDateTimeRage.pickTime': null,
          // 'selectedDateTimeRage.dropTime': null

        });
        this.resetTableRows();
        this.getRoutesByGvnProvider(provider.id);
      },

      onChangeRoute(route) {
        this.setProperties({
          selectedRoute: route // 'selectedDateTimeRage.pickTime': null,
          // 'selectedDateTimeRage.dropTime': null

        });
        this.resetTableRows();
        this.getRouteById(route.routeId);
      },

      cancel() {
        if (this.callBack) {
          this.callBack(true);
        }
      }

    }
  });

  _exports.default = _default;
});