define("adept-iq/models/trip-route-assignment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    ordinal: attr('number'),
    context: attr('string'),
    // scheduling or dispatching
    operation: attr('string', {
      defaultValue: ''
    }),
    user: attr('string', {
      defaultValue: ''
    }),
    timestamp: attr('date'),
    trip: belongsTo('trip', {
      inverse: 'routeAssignments'
    }),
    route: belongsTo('route')
  });

  _exports.default = _default;
});