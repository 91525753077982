define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/log/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DRIVER_LOG_VALIDATION = void 0;
  const DRIVER_LOG_VALIDATION = {
    description: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Description',
      message: '{description} is required.'
    })]
  };
  _exports.DRIVER_LOG_VALIDATION = DRIVER_LOG_VALIDATION;
});