define("adept-iq/tests/factories/avlm-rider", ["ember-data-factory-guy", "faker"], function (_emberDataFactoryGuy, _faker) {
  "use strict";

  _emberDataFactoryGuy.default.define('avlm-rider', {
    sequences: {
      firstNames: () => _faker.default.name.firstName(),
      lastNames: () => _faker.default.name.lastName(),
      middleNames: () => _faker.default.name.firstName()
    },
    default: {
      firstName: _emberDataFactoryGuy.default.generate('firstNames'),
      lastName: _emberDataFactoryGuy.default.generate('lastNames'),
      middleName: _emberDataFactoryGuy.default.generate('middleNames'),
      email: 'test@test.com',
      passengerTypeName: 'client'
    }
  });
});