define("adept-iq/pods/components/side-drawer/box-widget-settings/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COLUMNS = {
    routeColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'status',
          editable: true,
          hidden: false,
          format: 'hh:mm A',
          defaultWidth: 75
        },
        filterTypeId: 'stringEq',
        filterValues: ['Scheduled']
      }
    },
    tripColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'status',
          editable: true,
          hidden: false,
          defaultWidth: 75
        },
        filterTypeId: 'stringEq',
        filterValues: ['Scheduled']
      }
    }
  };
  const BOX_COLOR = {
    orange: 2,
    red: 4
  };
  var _default = [{
    name: 'Trips',
    modelName: 'iq-trip',
    columns: COLUMNS.tripColumns,
    displayOptions: {
      selected: {
        id: 'otp',
        name: 'OTP Status',
        label: 'OTP',
        valueKey: 'otp',
        valueCategory: {
          label: 'Late',
          value: 'L'
        },
        valueCategories: [{
          label: 'On Time',
          value: 'O'
        }, {
          label: 'Late',
          value: 'L'
        }, {
          label: 'In Danger',
          value: 'D'
        }, {
          label: 'Early',
          value: 'E'
        }],
        metricOption: {
          id: 'count',
          name: 'Count'
        },
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      },
      options: [{
        id: 'otp',
        name: 'OTP Status',
        label: 'OTP',
        valueKey: 'otp',
        valueCategories: [{
          label: 'On Time',
          value: 'O'
        }, {
          label: 'Late',
          value: 'L'
        }, {
          label: 'In Danger',
          value: 'D'
        }, {
          label: 'Early',
          value: 'E'
        }],
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      }]
    },
    title: '',
    boxColor: BOX_COLOR
  }, {
    name: 'Routes',
    modelName: 'route',
    columns: COLUMNS.tripColumns,
    displayOptions: {
      selected: {
        id: 'otp',
        name: 'OTP Status',
        label: 'OTP',
        valueKey: 'otp',
        valueCategory: {
          label: 'Late',
          value: 'L'
        },
        valueCategories: [{
          label: 'On Time',
          value: 'O'
        }, {
          label: 'Late',
          value: 'L'
        }, {
          label: 'In Danger',
          value: 'D'
        }, {
          label: 'Early',
          value: 'E'
        }],
        metricOption: {
          id: 'count',
          name: 'Count'
        },
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      },
      options: [{
        id: 'otp',
        name: 'OTP Status',
        label: 'OTP',
        valueKey: 'otp',
        valueCategories: [{
          label: 'On Time',
          value: 'O'
        }, {
          label: 'Late',
          value: 'L'
        }, {
          label: 'In Danger',
          value: 'D'
        }, {
          label: 'Early',
          value: 'E'
        }],
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      }]
    },
    title: '',
    boxColor: BOX_COLOR
  }];
  _exports.default = _default;
});