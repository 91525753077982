define("adept-iq/tests/factories/trip-route-assignment", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const dayMoment = (0, _moment.default)().startOf('day').add(6, 'hours');

  _emberDataFactoryGuy.default.define('trip-route-assignment', {
    sequences: {
      ordinals: num => `${num}`
    },
    default: {
      ordinal: _emberDataFactoryGuy.default.generate('ordinals'),
      context: 'dispatching',
      user: '',
      timestamp: dayMoment.toDate()
    }
  });
});