define("adept-iq/services/permission-layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    permissions: null,
    categories: null,
    userPermissionHash: null,

    async getPermissions() {
      const store = this.get('store');
      let permissions = store.peekAll('sso-permission');

      if (permissions.length === 0) {
        try {
          permissions = await store.findAll('sso-permission');
        } catch (e) {
          console.error(e); //eslint-disable-line
        }
      }

      this.set('permissions', permissions);
      const userPerms = this.get('session.data.authenticated.permissions');
      this.configureUserHash(userPerms);
    },

    permInUserHash(permId, permIds) {
      let permGranted = false;
      const userPermHash = this.get('userPermissionHash');

      if (userPermHash) {
        if (Ember.isBlank(permId) && permIds) {
          permGranted = permIds.some(id => userPermHash[id]);
        } else if (Ember.isArray(permId)) {
          permGranted = permId.reduce((granted, eachPermId) => {
            return granted || userPermHash[eachPermId];
          }, false);
        } else permGranted = userPermHash[permId];
      }

      return permGranted;
    },

    configureUserHash(permissions) {
      // need to think of a better way to index the perm hash
      const permIds = Array.from(Array(250).keys());
      const hash = permissions.reduce((acu, perm) => {
        if (permIds) {
          if (permIds.includes(perm)) acu[perm] = true;
        } else {
          acu[perm] = false;
        }

        return acu;
      }, {});
      this.set('userPermissionHash', hash);
    },

    permissionsTotal(category) {
      const permissions = this.get('permissions');
      return permissions.filter(p => p.get('category') === category).length;
    }

  });

  _exports.default = _default;
});