define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/side-drawer/index", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/side-drawer/shrink"], function (_exports, _react, _shrink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const SideDrawer = props => {
    const [isShrinked, setIsShrinked] = _react.default.useState(false);

    const {
      setFilterEnabled,
      className
    } = props;
    return _react.default.createElement(_react.default.Fragment, null, isShrinked && _react.default.createElement(_shrink.default, {
      setIsShrinked: setIsShrinked
    }), !isShrinked && _react.default.createElement("div", {
      className: "ember-modal-dialog ember-modal-dialog side-drawer-modal ember-modal-dialog-target-attachment-left emd-target-attachment-left ember-view"
    }, _react.default.createElement("div", {
      className: `${className} g-side-drawer ember-view`
    }, _react.default.createElement("div", {
      className: "g-side-drawer-header ember-view"
    }, _react.default.createElement("h1", {
      className: "g-side-drawer-header-title"
    }, props.title), _react.default.createElement("div", {
      className: "g-side-drawer-header-buttons"
    }, _react.default.createElement("button", {
      className: "g-side-drawer-header-button",
      title: "Shrink",
      "data-test-toggle-side-drawer": "",
      onClick: () => setIsShrinked(true)
    }, _react.default.createElement("i", {
      className: "fa fa-2x fa-angle-double-left",
      "aria-hidden": "true"
    })), _react.default.createElement("button", {
      className: "g-side-drawer-header-button",
      onClick: () => setFilterEnabled(false),
      "data-test-close-side-drawer": "",
      title: "Close"
    }, _react.default.createElement("i", {
      className: "fa fa-2x fa-times",
      "aria-hidden": "true"
    })))), _react.default.createElement("div", {
      className: "g-side-drawer-body ember-view"
    }, props.children))));
  };

  var _default = SideDrawer;
  _exports.default = _default;
});