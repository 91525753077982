define("adept-iq/adapters/reconcile-verify-route", ["exports", "adept-iq/adapters/-reconciliationService"], function (_exports, _reconciliationService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reconciliationService.default.extend({
    buildURL(modelName
    /*, id, snapshot, requestType, query*/
    ) {
      return this._buildUrlForPath(modelName);
    },

    _buildUrlForPath() {
      const host = this.get('host');
      return `${host}/verify`;
    }

  });

  _exports.default = _default;
});