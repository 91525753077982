define("adept-iq/pods/components/iq-widgets/users-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/users-widget/config", "adept-iq/config/api-urls", "lodash", "adept-iq/config/mapped-permIds"], function (_exports, _component, _config, _apiUrls, _lodash, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['users-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    editComponent: 'iq-widgets/users-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,

    // @TODO: saveRecordFunction shall be moved to a service later on
    // This save is specific to saving drivers and all related models.
    saveRecordFunction(records) {
      // using custom api call because sso api is non standard
      // and in-consistent.
      const userAPICall = (method, data, url, dataType) => {
        const session = this.get('session');
        const ajax = this.get('ajax');
        return ajax.request(_apiUrls.API.ssoService.host + url, {
          dataType: 'text',
          contentType: dataType,
          method: method,
          data: data,
          headers: {
            'Content-Type': dataType,
            Accept: dataType,
            Authorization: `Bearer ${session.data.authenticated.token}`
          }
        });
      };

      const saveStatus = (userId, status) => {
        return userAPICall('PUT', {
          status: status
        }, `/user/${userId}/status/${status}`, 'application/json');
      };

      const saveRoles = (userId, roles) => {
        const idRoles = _lodash.default.map(roles, role => {
          return role.roleName;
        }) || [];
        return userAPICall('GET', null, `/user/${userId}/roles`, 'text').then(currentRolesString => {
          const currentRoles = JSON.parse(currentRolesString);
          const removeRoles = _lodash.default.difference(currentRoles.roles, idRoles) || [];
          const addRoles = _lodash.default.difference(idRoles, currentRoles.roles) || [];
          return Promise.all(removeRoles.map(removeRole => {
            return userAPICall('DELETE', null, `/user/${userId}/roles/${removeRole}`, 'text');
          })).then(() => {
            return Promise.all(addRoles.map(addRole => {
              return userAPICall('PUT', null, `/user/${userId}/roles/${addRole}`, 'text');
            }));
          });
        });
      };

      const addNewUser = record => {
        const idRoles = _lodash.default.map(record.roleNames, role => {
          return role.roleName;
        }); // sendMail defaulted to false because of a bug in api


        const data = {
          firstName: record.firstName,
          lastName: record.lastName,
          email: record.email,
          status: record.status,
          roles: idRoles,
          sendEmail: true
        };
        return userAPICall('POST', data, '/user', 'application/json');
      };

      this.set('editModal.errors', []);
      return new Promise((resolve, reject) => {
        if (!Ember.isEmpty(records)) {
          records.forEach(record => {
            if (record.isNew) {
              addNewUser(record, this).then(() => {
                record.destroyRecord();
              }).catch(e => {
                // error we get when user gets created but return 500 because
                // of send email problem
                if (e.status === 500) {
                  record.destroyRecord();
                }

                reject(e);
              });
            } else {
              saveStatus(record.id, record.status, this).then(() => {
                saveRoles(record.id, record.roleNames, this).then(() => {
                  record.reload().then(() => {
                    record.set('isForceDirty', false);
                    this.get('activeContext.topActiveContext').get('refreshTableContent').perform([this.get('config.modelName')]);
                  });
                });
              }).catch(e => {
                reject(e);
              });
            }
          });
        }
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('tableActions', [{
        id: 'newUser',
        permId: _mappedPermIds.default.newUser,
        name: 'New User',
        action: function () {
          const newModel = this.get('store').createRecord(this.get('config.modelName'));
          this.set('editModal.editableRecords', []);
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [newModel], this.get('saveRecordFunction').bind(this));
        }.bind(this)
      }]);
      this.set('singleActions', [{
        id: 'edit',
        permId: _mappedPermIds.default.editUser,
        name: 'Edit',
        action: model => {
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [model], this.get('saveRecordFunction').bind(this));
        }
      }]);
      this.set('bulkActions', [{
        id: 'bulkEdit',
        permId: _mappedPermIds.default.editUser,
        name: 'Bulk Edit',
        action: models => {
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, models, this.get('saveRecordFunction').bind(this));
        }
      }]);
    }

  });

  _exports.default = _default;
});