define("adept-iq/classes/markers/agency", ["exports", "adept-iq/classes/markers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    style: 'location',
    label: 'Agency',
    isActive: false,
    agencyMarker: Ember.computed.alias('record'),
    lat: Ember.computed.alias('agencyMarker.lat'),
    lng: Ember.computed.alias('agencyMarker.lng'),
    options: Ember.computed.alias('agencyMarker.options')
  });

  _exports.default = _default;
});