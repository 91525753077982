define("adept-iq/tests/factories/trip", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const normalStart = (0, _moment.default)().startOf('day').add(6, 'hours');
  const evening = (0, _moment.default)().startOf('day').add(13, 'hours');
  const night = (0, _moment.default)().startOf('day').add(18, 'hours');
  const tripStatus = ['waitlisted'];

  _emberDataFactoryGuy.default.define('trip', {
    sequences: {
      tripIds: num => `${num}`,
      tripStatuses: num => tripStatus[num % tripStatus.length]
    },
    default: {
      tripId: _emberDataFactoryGuy.default.generate('tripIds'),
      status: _emberDataFactoryGuy.default.generate('tripStatuses'),
      promiseTime: normalStart.toDate()
    },
    traits: {
      morning: {
        promiseTime: normalStart.toDate()
      },
      evening: {
        promiseTime: evening.toDate()
      },
      night: {
        promiseTime: night.toDate()
      },
      noshowRequest: {
        status: 'noshowRequest'
      }
    }
  });
});