define("adept-iq/pods/components/form-components/activity-log-entry/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    config: null,
    editableSections: Ember.computed.readOnly('config.editableSections'),
    userActivitySelected: null,
    details: '',
    isDetailsDisabled: Ember.computed('userActivitySelected', function () {
      const activty = this.get('userActivitySelected');
      return Ember.isEmpty(activty);
    }),
    route: Ember.computed.readOnly('workspace.topOptions.route'),

    init() {
      this._super(...arguments);
    },

    filteredSections: Ember.computed('editableSections', function () {
      const sections = Ember.copy(this.get('editableSections'), true) || [];
      return sections.map(section => {
        return section;
      }).filter(section => section.fields.length > 0);
    })
  });

  _exports.default = _default;
});