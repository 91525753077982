define("adept-iq/models/user-activity-log-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    category: attr('string'),
    displayName: attr('string')
  });

  _exports.default = _default;
});