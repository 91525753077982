define("adept-iq/pods/components/iq-widgets/delete-booking-form/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "adept-iq/config/api-urls", "adept-iq/models/trip"], function (_exports, _component, _apiUrls, _trip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['cancel-booking-form-side-drawer'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    booking: Ember.inject.service(),
    workspace: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    bookingId: null,
    isProgressShown: false,
    editableSections: null,
    note: null,
    canSave: true,

    init() {
      this._super(...arguments);

      this.set('bookingId', this.get('editModal.editableRecords')[0].get('id'));
      this.set('editableSections', []);
    },

    didInsertElement() {
      this._super(...arguments);
    },

    async deleteBookingRecord() {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const session = this.get('session');
      const bookingId = this.get('bookingId');
      const filteredTrips = this.get('booking.currentTrips').filter(trip => bookingId === trip.get('booking.id'));
      const tripIds = filteredTrips.map(t => t.get('id'));
      const tripStatus = filteredTrips.every(t => t.get('status') === _trip.TRIP_CANCELED_STATUS.toLowerCase());
      const payload = tripIds.length && tripStatus ? JSON.stringify({
        doCancel: false
      }) : JSON.stringify({
        doCancel: true
      });

      try {
        await this.get('ajax').post(_apiUrls.API.bookingService.host + '/booking/' + bookingId, {
          method: 'DELETE',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${session.data.authenticated.token}`
          },
          data: payload
        });
        await this.get('booking').deleteTrips(bookingId);
        const [bookings, trips] = await this.get('apiContext').loadBookingsForSelectedExternalRiders();
        this.set('booking.currentBookings', bookings);
        this.set('booking.currentTrips', trips);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('editModal').reset();
        this.get('editModal').close();
        this.get('notifications').success('Booking successfully deleted');
      } catch (error) {
        this.get('notifications').warning('FAILED TO DELETED BOOKING ID ' + bookingId + error.message);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('editModal').reset();
        this.get('editModal').close();
      }
    },

    actions: {
      onUndoClick() {
        const lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          const records = this.get('editableRecords') || [];
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(_ref => {
            let {
              record,
              properties
            } = _ref;
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onSaveClick() {
        this.toggleProperty('isProgressShown');
      },

      onXButtonClick() {
        this.toggleProperty('isProgressShown');
      },

      onCloseClick() {
        this.get('editModal').close();
      },

      onCancelClick() {
        this.toggleProperty('isProgressShown');
      },

      onConfirmClick() {
        this.deleteBookingRecord();
      },

      onNoteChange(note) {
        this.set('note', note);
      }

    }
  });

  _exports.default = _default;
});