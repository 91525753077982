define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/log/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "lodash", "adept-iq/config/environment", "adept-iq/config/placeholders", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/log/validation"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _lodash, _environment, _placeholders, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_LOG_TYPE_MAP = {
    addDriverLog: true,
    editDriverLog: true,
    deleteDriverLog: true
  };

  var _default = Ember.Component.extend({
    classNames: ['driver-log-view'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    tableRef: null,
    activityLogtableRef: null,
    disableForm: true,
    dateTimeFormat: _environment.default.dateTimeFormat,
    selectedDriver: Ember.computed.readOnly('editAPI.selectedRecord'),
    selectedDriverLog: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    isNewLog: Ember.computed.readOnly('editAPI.newBtn'),
    logQueryParams: null,
    providerOptions: Ember.computed('', function () {
      return this.get('store').peekAll('provider').filter(provider => {
        return provider.get('userCanAccessProvider');
      });
    }),
    driverLogChangeSet: Ember.computed('selectedDriverLog', function () {
      const selectedDriverLog = (0, _unwrapProxy.unwrapProxy)(this.get('selectedDriverLog'));
      return this.setDefaultProperties(selectedDriverLog);
    }),

    async init() {
      this._super(...arguments);

      const driver = this.get('selectedDriver');
      this.set('activityQueryParams', {
        driverId: `${driver.get('id')}`,
        eventLog: true
      });
      this.set('logQueryParams', {
        filter: `eq(driverId,${driver.get('id')})`
      });
      this.get('refreshTask').perform();
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const tableRef = this.get('tableRef'); // ember concurrency tasks

      const onDeleteRecord = this.get('onDeleteRecord');
      const onSaveRecord = this.get('onSaveRecord');
      const onValidateAction = this.get('validateDriverLog'); // normal functions

      const onUndoRecord = this.get('onUndoRecord').bind(this);
      const onNewRecord = this.get('onNewRecord').bind(this);
      const onEditRecord = this.get('onEditRecord').bind(this);
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveRecord,
          newAction: onNewRecord,
          deleteAction: onDeleteRecord,
          editAction: onEditRecord,
          undoAction: onUndoRecord,
          selectedDriverBelongsTableRef: tableRef,
          hasChanges,
          onValidateAction,
          isChangesHappened: false
        });
      });
      this.addObserver('hasPendingChanges', this.hasPendingChangesObserver);
      this.get('refreshTask').perform().then(() => {
        Ember.run.later(() => {
          this.resetForm();
        }, 1000);
      });
    },

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_LOG_TYPE_MAP[event.get('actionType')];
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const editAPI = this.get('editAPI');
      const isNewLog = this.get('isNewLog');
      const createDate = isNewLog ? new Date() : null;
      let pendingDriverLogRecord = this.get('pendingDriverLogRecord');

      if (record) {
        pendingDriverLogRecord = record;
        this.resetForm();
      } else if (!pendingDriverLogRecord) {
        pendingDriverLogRecord = store.createRecord('driver-log');
        this.set('pendingDriverLogRecord', pendingDriverLogRecord);
      }

      if (pendingDriverLogRecord.get('isNew')) {
        pendingDriverLogRecord.setProperties({
          description: '',
          provider: '',
          driver: editAPI.selectedRecord,
          createDate
        });
      }

      return new _emberChangeset.default(pendingDriverLogRecord, (0, _emberChangesetValidations.default)(_validation.DRIVER_LOG_VALIDATION), _validation.DRIVER_LOG_VALIDATION);
    },

    hasPendingChanges: Ember.computed('driverLogChangeSet.changes.length', function () {
      const driverLogChangeSet = this.get('driverLogChangeSet');
      const changes = driverLogChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      const driverLogChangeSet = this.get('driverLogChangeSet');

      if (driverLogChangeSet.get('isNew')) {
        const pendingChanges = this.get('driverLogChangeSet');
        const fields = (0, _lodash.flatten)(pendingChanges.changes).map(change => change.key);
        const isEveyPrePoulatedFieldsChange = fields.length ? fields.every(field => field.includes('createDate')) : false;
        if (isEveyPrePoulatedFieldsChange) return !isEveyPrePoulatedFieldsChange;
        return !!this.get('hasPendingChanges');
      }

      return !!this.get('hasPendingChanges');
    },

    hasPendingChangesObserver() {
      const editAPI = this.get('editAPI');
      const hasPendingChanges = this.get('hasPendingChanges');
      Ember.run.scheduleOnce('afterRender', () => {
        editAPI.setProperties({
          isChangesHappened: hasPendingChanges
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedDriverLog = this.get('selectedDriverLog');

      if (selectedDriverLog) {
        if (!selectedDriverLog.get('isNew') && !selectedDriverLog.get('isDeleted')) {
          yield selectedDriverLog.reload();
        }
      }

      const tableRef = this.get('tableRef');
      const activityLogtableRef = this.get('activityLogtableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      if (activityLogtableRef) Ember.run.scheduleOnce('afterRender', activityLogtableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        const records = this.get('tableRef.records');

        if (!records.length) {
          editAPI.buttonSettings(true, false, true, true, true);
        } else {
          editAPI.buttonSettings();
        }
      });
    },

    validateDriverLog: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverLogChangeSet = this.get('driverLogChangeSet');
      yield driverLogChangeSet.validate();
      const errors = driverLogChangeSet.errors;

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.reset();
          }

        });
        return false;
      }

      return true;
    }),
    onSaveRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const driverLogChangeSet = this.get('driverLogChangeSet');
      const tooltip = this.get('tooltip'); // validation

      const isValidDriverLog = yield this.validateDriverLog.perform();

      if (isValidDriverLog) {
        try {
          yield driverLogChangeSet.save();
          notifications.success('Record successfully saved.');
          this.set('pendingDriverLogRecord', null);
          Ember.run.later(() => {
            this.get('refreshTask').perform().then(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
              this.resetForm();
              tooltip.reset();
            });
          }, 1000);
          return driverLogChangeSet;
        } catch (e) {
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line
        }
      }
    }),
    onDeleteLogRecord: (0, _emberConcurrency.task)(function* () {
      const selectedDriverLog = this.get('selectedDriverLog');
      const notifications = this.get('notifications');

      try {
        yield selectedDriverLog.destroyRecord();
        notifications.success('Record successfully deleted');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.resetForm();
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
          });
        }, 1000);
      } catch (e) {
        notifications.warning('Record failed to delete');
        console.warn(e); // eslint-disable-line
      }
    }),
    onDeleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedDriverLog = this.get('selectedDriverLog');
      const tooltip = this.get('tooltip');
      if (!selectedDriverLog) return;
      yield tooltip.pushConfirmation({
        title: 'Manage Driver',
        tip: 'Are you sure you want to delete this record?',
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        },

        primaryActionText: 'Confirm',
        primaryAction: () => {
          this.get('onDeleteLogRecord').perform();
          return tooltip.reset();
        }
      });
    }),

    onUndoRecord() {
      const driverLogChangeSet = this.get('driverLogChangeSet');

      if (driverLogChangeSet) {
        driverLogChangeSet.rollback();
      }

      if (Ember.isEmpty(driverLogChangeSet.get('id'))) {
        driverLogChangeSet.set('createDate', new Date());
      }
    },

    onNewRecord() {
      const selectedRow = this.get('selectedRow');
      const editAPI = this.get('editAPI');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      const driverLogChangeSet = this.get('driverLogChangeSet');
      driverLogChangeSet.set('createDate', new Date());
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onEditRecord() {
      const selectedRow = this.get('selectedRow');
      const editAPI = this.get('editAPI');
      if (Ember.isEmpty(selectedRow)) return;
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    actions: {
      onSaveRecord() {
        return this.onSaveRecord.perform();
      }

    }
  });

  _exports.default = _default;
});