define("adept-iq/pods/components/side-drawer/map-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    mapService: Ember.inject.service('map'),
    // map-context layers are ephemeral, so use `id` for selection state
    selectedLayerId: null,
    onShrinkClick: null,
    currentState: null,
    title: 'Map Layers',
    componentName: 'filterMap',
    // layers: computed.readOnly('mapService.topMapContext.layers'),
    layers: Ember.computed('mapService.topMapContext.layers', 'mapService.workspace.dashboard.{name,id}', function () {
      const activeWorkspace = Ember.get(this, 'mapService.workspace.dashboard.name');
      const activeWorkspaceId = Ember.get(this, 'mapService.workspace.dashboard.id');
      const layers = Ember.get(this, 'mapService.topMapContext.layers');
      if (activeWorkspace === 'Playback' && activeWorkspaceId === 'workspaces-playback/playback') return layers.filter(layer => layer.id === 'vehicle-events');
      return layers;
    }),
    visibilitySection: Ember.computed('mapService.workspace.dashboard.{name,id}', function () {
      const activeWorkspace = Ember.get(this, 'mapService.workspace.dashboard.name');
      const activeWorkspaceId = Ember.get(this, 'mapService.workspace.dashboard.id');
      if (activeWorkspace === 'Playback' && activeWorkspaceId === 'workspaces-playback/playback') return true;
      return false;
    }),
    selectedLayer: Ember.computed('layers', 'selectedLayerId', function () {
      const layerId = this.get('selectedLayerId');
      if (Ember.isNone(layerId)) return null;
      const layers = this.get('layers');
      if (Ember.isEmpty(layers)) return null;
      return layers.findBy('id', layerId);
    }),
    isAgencyLayer: Ember.computed.equal('selectedLayerId', 'agency'),
    disabled: Ember.computed.not('selectedLayerId'),

    didInsertElement() {
      this._super(...arguments);

      this.loadCurrentState();
    },

    loadCurrentState() {
      const currentState = this.get('currentState');
      const layers = this.get('layers');

      if (currentState) {
        this.selectLayer(layers[currentState.index]); // this.set('selectedLayerId', currentState.selectedLayerId); on shrink function call
      } else {
        if (Ember.isEmpty(layers)) return;
        this.selectLayer(layers[0]);
      }
    },

    selectLayer(layer) {
      this.set('selectedLayerId', layer.id);
    },

    calIndex() {
      const layers = this.get('layers');
      const selectedLayer = this.get('selectedLayerId');

      for (let i = 0; i < layers.length; i++) {
        if (layers[i].id === selectedLayer) {
          return i;
        }
      }
    },

    updateCurrentState() {
      let currentState = this.get('currentState');
      const index = this.calIndex();
      currentState = {
        index
      };
      this.set('currentState', currentState);
    },

    actions: {
      onRefreshClick() {
        this.updateCurrentState();
        this.onShrinkClick();
      },

      onLayerSelect(layer) {
        this.selectLayer(layer);
        this.updateCurrentState();
      },

      onLayerVisibilityCheck(event) {
        const layer = this.get('selectedLayer');
        this.get('mapService').setLayerVisibility(layer.id, event.target.checked);
      },

      onTransparencySliderChange(value) {
        const layer = this.get('selectedLayer');
        this.get('mapService').setLayerOpacity(layer.id, value);
      },

      onShrinkClick() {
        this.updateCurrentState();
        this.onShrinkClick();
      }

    }
  });

  _exports.default = _default;
});