define("adept-iq/adapters/perm-sso-role", ["exports", "adept-iq/adapters/-sso-ssoService"], function (_exports, _ssoSsoService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssoSsoService.default.extend({
    buildURL(modelName
    /*, id, snapshot, requestType, query*/
    ) {
      return this._buildUrlForPath(modelName);
    },

    _buildUrlForPath() {
      const host = this.get('host');
      return `${host}/role`;
    }

  });

  _exports.default = _default;
});