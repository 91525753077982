define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/row-range-renderer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rowRangeRenderer = void 0;

  /* eslint-disable no-continue */

  /* eslint-disable no-lonely-if */

  /* eslint-disable prefer-const */

  /* eslint-disable no-unused-vars */

  /* eslint-disable no-unused-expressions */

  /**
       * Default implementation of cellRangeRenderer used by Grid.
       * This renderer supports cell-caching while the user is scrolling.
       */
  const rowRangeRenderer = rowRenderer => _ref => {
    let {
      cellCache,
      cellRenderer,
      columnSizeAndPositionManager,
      columnStartIndex,
      columnStopIndex,
      deferredMeasurementCache,
      horizontalOffsetAdjustment,
      isScrolling,
      parent,
      // Grid (or List or Table)
      rowSizeAndPositionManager,
      rowStartIndex,
      rowStopIndex,
      styleCache,
      verticalOffsetAdjustment,
      visibleColumnIndices,
      visibleRowIndices,
      scrollToRow,
      preScrollToRow,
      isArrowKeyPressing,
      arrowKeyCellCache
    } = _ref;
    const renderedRows = []; // Browsers have native size limits for elements (eg Chrome 33M pixels, IE 1.5M pixes).
    // User cannot scroll beyond these size limitations.
    // In order to work around this, ScalingCellSizeAndPositionManager compresses offsets.
    // We should never cache styles for compressed offsets though as this can lead to bugs.
    // See issue #576 for more.

    const areOffsetsAdjusted = columnSizeAndPositionManager.areOffsetsAdjusted() || rowSizeAndPositionManager.areOffsetsAdjusted();
    const canCacheStyle = !isScrolling && !areOffsetsAdjusted;

    for (let rowIndex = rowStartIndex; rowIndex <= rowStopIndex; rowIndex++) {
      const rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(rowIndex);
      const renderedCells = [];
      const rowKey = `${rowIndex}`;

      for (let columnIndex = columnStartIndex; columnIndex <= columnStopIndex; columnIndex++) {
        const columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(columnIndex);
        const isCellVisible = columnIndex >= visibleColumnIndices.start && columnIndex <= visibleColumnIndices.stop && rowIndex >= visibleRowIndices.start && rowIndex <= visibleRowIndices.stop;
        const cellKey = `${rowIndex}-${columnIndex}`;
        let cellStyle, renderedCell; // Cache style objects so shallow-compare doesn't re-render unnecessarily.

        if (canCacheStyle && styleCache[cellKey]) {
          cellStyle = styleCache[cellKey];
        } else {
          // In deferred mode, cells will be initially rendered before we know their size.
          // Don't interfere with CellMeasurer's measurements by setting an invalid size.
          if (deferredMeasurementCache && !deferredMeasurementCache.has(rowIndex, columnIndex)) {
            // Position not-yet-measured cells at top/left 0,0,
            // And give them width/height of 'auto' so they can grow larger than the parent Grid if necessary.
            // Positioning them further to the right/bottom influences their measured size.
            cellStyle = {
              height: 'auto',
              left: 0,
              position: 'absolute',
              top: 0,
              width: 'auto'
            };
          } else {
            cellStyle = {
              height: rowDatum.size,
              left: columnDatum.offset + horizontalOffsetAdjustment,
              position: 'absolute',
              top: 0,
              width: columnDatum.size,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            };
            styleCache[cellKey] = cellStyle;
          }
        }

        const cellRendererParams = {
          columnIndex,
          isScrolling,
          isVisible: isCellVisible,
          key: cellKey,
          parent,
          rowIndex,
          style: cellStyle
        };

        const cellRendererWrapper = params => {
          if (params.rowIndex + 1 !== scrollToRow && isArrowKeyPressing && arrowKeyCellCache) {
            if (!arrowKeyCellCache[params.key]) {
              arrowKeyCellCache[params.key] = cellRenderer(params);
            }

            return arrowKeyCellCache[params.key];
          }

          if (arrowKeyCellCache && arrowKeyCellCache[params.key]) {
            delete arrowKeyCellCache[params.key];
          }

          return cellRenderer(params);
        }; // Avoid re-creating cells while scrolling.
        // This can lead to the same cell being created many times and can cause performance issues for "heavy" cells.
        // If a scroll is in progress- cache and reuse cells.
        // This cache will be thrown away once scrolling completes.
        // However if we are scaling scroll positions and sizes, we should also avoid caching.
        // This is because the offset changes slightly as scroll position changes and caching leads to stale values.
        // For more info refer to issue #395


        if (isScrolling && !horizontalOffsetAdjustment && !verticalOffsetAdjustment) {
          if (!cellCache[cellKey]) {
            cellCache[cellKey] = cellRendererWrapper(cellRendererParams);
          }

          renderedCell = cellCache[cellKey]; // If the user is pressing arrow key, try caching the cell.
        } else {
          renderedCell = cellRendererWrapper(cellRendererParams);
        }

        if (renderedCell === null || renderedCell === false) {
          continue;
        }

        renderedCells.push(renderedCell);
      }

      const isRowVisible = rowIndex >= visibleRowIndices.start && rowIndex <= visibleRowIndices.stop;
      let rowStyle; // Cache style objects so shallow-compare doesn't re-render unnecessarily.

      if (canCacheStyle && styleCache[rowKey]) {
        rowStyle = styleCache[rowKey];
      } else {
        rowStyle = {
          height: rowDatum.size,
          left: 0,
          position: 'absolute',
          right: 0,
          top: rowDatum.offset + verticalOffsetAdjustment
        };
        styleCache[rowKey] = rowStyle;
      }

      const rowRendererParams = {
        children: renderedCells,
        isScrolling,
        isVisible: isRowVisible,
        key: rowKey,
        parent,
        rowIndex,
        style: rowStyle
      }; // The cache block is commented out - as caching rows when scrolling horizontally wont
      // render correctly when scrolling to new columns.
      // If re-enabled, its cache key should contain the range of column indecies that it shows.

      const renderedRow = rowRenderer(rowRendererParams); // Avoid re-creating cells while scrolling.
      // This can lead to the same cell being created many times and can cause performance issues for "heavy" cells.
      // If a scroll is in progress- cache and reuse cells.
      // This cache will be thrown away once scrolling completes.
      // However if we are scaling scroll positions and sizes, we should also avoid caching.
      // This is because the offset changes slightly as scroll position changes and caching leads to stale values.
      // For more info refer to issue #395

      /*
          if (
            isScrolling &&
            !horizontalOffsetAdjustment &&
            !verticalOffsetAdjustment
          ) {
            if (!cellCache[rowKey]) {
              cellCache[rowKey] = rowRenderer(rowRendererParams);
            }
             renderedRow = cellCache[rowKey];
             // If the user is no longer scrolling, don't cache cells.
            // This makes dynamic cell content difficult for users and would also lead to a heavier memory footprint.
          } else {
            renderedRow = rowRenderer(rowRendererParams);
          }
          */

      if (renderedRow === null) {
        continue;
      }

      renderedRows.push(renderedRow);
    }

    return renderedRows;
  };

  _exports.rowRangeRenderer = rowRangeRenderer;
});