define("adept-iq/classes/polylines/stops/base", ["exports", "adept-iq/classes/polylines/base", "adept-iq/models/stop-point"], function (_exports, _base, _stopPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STOP_STYLE_PREFIX = 'stop';

  var _default = _base.default.extend({
    // `BasePolyline` overrides
    style: Ember.computed.readOnly('stopPolylineStyle'),
    pattern: Ember.computed.readOnly('stopPolylineStyle'),
    // local properties
    stopStatus: 'planned',
    otp: null,
    isPlanned: Ember.computed.equal('stopStatus', _stopPoint.PLANNED_STOP_STATUS),
    isPerformed: Ember.computed.equal('stopStatus', _stopPoint.PERFORMED_STOP_STATUS),
    isPending: Ember.computed.equal('stopStatus', _stopPoint.PENDING_STOP_STATUS),
    stopPolylineStyle: Ember.computed('isPerformed', 'statusStylePrefix', 'otpStyleSuffix', function () {
      if (this.get('isPerformed')) {
        return [STOP_STYLE_PREFIX, 'Performed'].join('');
      }

      const statusStyleSuffix = this.get('statusStyleSuffix');
      const otpStyleSuffix = this.get('otpStyleSuffix');
      const style = [STOP_STYLE_PREFIX, statusStyleSuffix, otpStyleSuffix].join('');
      return style;
    }),
    statusStyleSuffix: Ember.computed('stopStatus', function () {
      switch (this.get('stopStatus')) {
        case _stopPoint.PENDING_STOP_STATUS:
          return 'Pending';

        case _stopPoint.PERFORMED_STOP_STATUS:
          return 'Performed';

        case _stopPoint.PLANNED_STOP_STATUS:
        default:
          return 'Planned';
      }
    }),
    otpStyleSuffix: Ember.computed('otp', function () {
      switch (this.get('otp')) {
        case 'L':
          return 'Late';

        case 'D':
          return 'Danger';

        default:
          return null;
      }
    })
  });

  _exports.default = _default;
});