define("adept-iq/pods/components/iq-widgets/routes-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/routes-form-widget/config", "adept-iq/pods/components/iq-widgets/routes-form-widget/scheduleConfig", "moment", "adept-iq/mixins/version-check", "adept-iq/models/dashboard", "adept-iq/utils/unwrapProxy"], function (_exports, _component, _config, _scheduleConfig, _moment, _versionCheck, _dashboard, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_versionCheck.default, {
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    classNames: ['data-test-route-form-widget', 'route-form-widget'],
    onRouteRecordChanged: Ember.observer('editableRecords.firstObject.route.{tripLimit,distanceLimit}', function () {
      const tripLimit = this.get('editableRecords.firstObject.route.tripLimit');
      const distanceLimit = this.get('editableRecords.firstObject.route.distanceLimit');
      this.set('saveDisable', false);

      if (!tripLimit || !distanceLimit) {
        this.set('saveDisable', true);
      }
    }),

    init() {
      this._super(...arguments);

      const role = this.get('workspace.dashboardModel.role');

      if (role === _dashboard.SCHEDULE_ROLENAME) {
        this.set('editableSections', _scheduleConfig.default.editableSections);
      } // initialize for observer


      this.get('editableRecords.firstObject');
    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords');
      this.set('editModal.errors', []);
      records.forEach(record => {
        const rdv = record.get('latestRouteVehicleDriver'); // make sure we apply the date to the plannedStartTime and plannedEndTime

        const plannedStartTime = (0, _moment.default)(rdv.get('startTime'));
        const plannedEndTime = (0, _moment.default)(rdv.get('endTime'));
        const firstStopMaxTime = record.get('firstStopMaxTime');
        const lastStopTime = record.get('lastStopTime');
        const routeMaxTimeLimit = record.get('routeMaxTimeLimit');
        const routeTimeDiff = plannedEndTime.diff(plannedStartTime, 'minutes');

        if (firstStopMaxTime && plannedStartTime.isSameOrAfter(firstStopMaxTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Route start time cannot be after start time of first Stop');
        }

        if (lastStopTime && lastStopTime.isSameOrAfter(plannedEndTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Route end time cannot be before end time of last stop');
        }

        if (routeTimeDiff > routeMaxTimeLimit) {
          valid = false;
          this.get('editModal.errors').pushObject(`Route length of ${routeTimeDiff} exceeds config Route Max Time Limit of ${routeMaxTimeLimit} minutes`);
        }

        if (plannedStartTime.isAfter(plannedEndTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Start Time cannot be after End Time');
        }
      });
      return valid;
    },

    validateRecordsForScheduling() {
      let valid = true;
      const records = this.get('editableRecords');
      const routeMaxParam = this.get('store').peekRecord('cs-config-item', 'config-Route_parameters/routeMaxExtension');
      const routeMaxExtension = routeMaxParam ? parseInt(routeMaxParam.get('value'), 10) : 60; // minutes

      this.set('editModal.errors', []);
      records.forEach(record => {
        // make sure we apply the date to the plannedStartTime and plannedEndTime
        const plannedStartTime = (0, _moment.default)(record.get('plannedStartTime'));
        const plannedEndTime = (0, _moment.default)(record.get('plannedEndTime'));
        const originalPlannedStartTime = (0, _moment.default)(record.get('plannedStartTime'));
        const originalPlannedEndTime = (0, _moment.default)(record.get('plannedEndTime'));

        if (plannedStartTime.isAfter(plannedEndTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Start Time cannot be after End Time');
        }

        const routeMaxAdjustments = routeMaxExtension;
        const startTimeDiff = plannedStartTime.diff(originalPlannedStartTime, 'minutes');

        if (Math.abs(startTimeDiff) > routeMaxAdjustments) {
          valid = false;
          this.get('editModal.errors').pushObject(`Start Time cannot be adjusted more than ${routeMaxAdjustments} minutes`);
        }

        const endTimeDiff = plannedEndTime.diff(originalPlannedEndTime, 'minutes');

        if (Math.abs(endTimeDiff) > routeMaxAdjustments) {
          valid = false;
          this.get('editModal.errors').pushObject(`End Time cannot be adjusted more than ${routeMaxAdjustments} minutes`);
        }

        if (Ember.isEmpty(record.get('tripLimit'))) {
          record.set('tripLimit', 0);
        }

        if (Ember.isEmpty(record.get('distanceLimit'))) {
          record.set('distanceLimit', 0);
        }
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        const records = this.get('editableRecords') || [];
        const role = this.get('workspace.dashboardModel.role');
        this.set('editModal.errors', []);

        if (role === _dashboard.DISPATCH_ROLENAME) {
          records.forEach(record => {
            const route = (0, _unwrapProxy.unwrapProxy)(record.get('route'));
            const latestRouteVehicleDriver = (0, _unwrapProxy.unwrapProxy)(record.get('latestRouteVehicleDriver'));
            route.rollbackAttributes();
            latestRouteVehicleDriver.rollbackAttributes();
            record.rollbackAttributes();
          });
          return;
        }

        records.forEach(record => {
          record.rollbackAttributes();
        });
      },

      async onApplyClick() {
        const role = this.get('workspace.dashboardModel.role');

        if (role === _dashboard.DISPATCH_ROLENAME) {
          const dispatchRoute = this.get('editableRecords.firstObject');
          const lockRouteAction = this.get('lockRouteAction').bind(this);
          const unlockRouteAction = this.get('unlockRouteAction').bind(this);
          this.set('dispatchRouteToCheck', dispatchRoute);

          const closeCallback = () => {
            this.send('onCloseClick');
          }; // Check dispatchRoute version first


          await this.get('versionCheckOperation').perform(closeCallback.bind(this));

          if (this.validateRecords()) {
            try {
              await lockRouteAction();
              const title = this.get('title');
              this.get('service').applyWithCallback(title, async () => await unlockRouteAction(0));
            } catch (err) {
              await unlockRouteAction();
              return;
            }
          }

          return;
        }

        if (this.validateRecordsForScheduling()) {
          const title = this.get('title');
          this.get('service').apply(title);
        }
      },

      onCloseClick() {
        this.actions.onUndoClick.call(this);
        this.get('service').close();
      }

    }
  });

  _exports.default = _default;
});