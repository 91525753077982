define("adept-iq/scenarios/avlm-core-service", ["exports", "ember-data-factory-guy"], function (_exports, _emberDataFactoryGuy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Just for fun, set the log level ( to 1 ) and see all FactoryGuy response info in console
  _emberDataFactoryGuy.Scenario.settings({
    logLevel: 0 // 1 is the max for now, default is 0

  });

  class _default extends _emberDataFactoryGuy.Scenario {
    run() {
      const dsVehicles = this.store.peekAll('vehicle').toArray();
      const dsRoutes = this.store.peekAll('dispatch-route').toArray();
      const routes = this.store.peekAll('route');
      routes.forEach((route, index) => {
        const dsRoute = dsRoutes[index];
        const dsVehicle = dsVehicles[index];
        const driver = dsRoute.get('assignedDriver.content');

        if (index % 3 === 0) {
          (0, _emberDataFactoryGuy.make)('avlm-emergency-message', 'emergency', {
            vehicle: dsVehicle,
            route,
            driver
          });
          (0, _emberDataFactoryGuy.make)('avlm-emergency-message', {
            vehicle: dsVehicle,
            route,
            driver
          });
        }

        (0, _emberDataFactoryGuy.make)('avlm-canned-message', 'recieved', {
          vehicle: dsVehicle,
          route,
          driver
        });
      });
    }

  }

  _exports.default = _default;
});