define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/availability/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1pKdVyEn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n\"],[1,[27,\"generic-components/availabilities\",null,[[\"availabilities\",\"availabilityRef\",\"modelName\",\"shouldDisplayValidations\"],[[23,[\"availabilities\"]],[23,[\"availabilityRef\"]],[23,[\"modelName\"]],[23,[\"shouldDisplayValidations\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"mt-2\"],[9],[0,\"\\n  \"],[1,[27,\"iq-widgets/vehicle-driver-manager-widget/tabs/activity-log/table\",null,[[\"filterEventTypeFunction\",\"queryParams\",\"tableRef\"],[[27,\"action\",[[22,0,[]],[23,[\"filterEventTypeFunction\"]]],null],[23,[\"activityQueryParams\"]],[23,[\"tableRef\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/availability/template.hbs"
    }
  });

  _exports.default = _default;
});