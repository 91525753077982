define("adept-iq/pods/components/reoptimize-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qEkC6ebr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"secondary-window\",null,[[\"title\",\"onXButtonClick\",\"disableXButtonClick\",\"isLoading\"],[\"Reoptimization\",[27,\"action\",[[22,0,[]],\"onXButtonClick\"],null],[23,[\"disableActions\"]],false]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"reoptimize-wrapper\"],[9],[0,\"\\n    \"],[2,\" operation list \"],[0,\"\\n    \"],[1,[27,\"reoptimize-modal/operation-list\",null,[[\"currentSchedule\",\"disableList\",\"onItemSelect\"],[[23,[\"currentSchedule\"]],[23,[\"disableActions\"]],[27,\"action\",[[22,0,[]],\"onItemSelect\"],null]]]],false],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"operation-view-wrapper\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"currentComponentPath\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"component\",[[27,\"concat\",[\"reoptimize-modal/operation-views/\",[23,[\"currentComponentPath\"]]],null]],[[\"currentSchedule\",\"tableRef\",\"currentAPITask\"],[[23,[\"currentSchedule\"]],[23,[\"tableRef\"]],[23,[\"currentAPITask\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/reoptimize-modal/template.hbs"
    }
  });

  _exports.default = _default;
});