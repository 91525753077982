define("adept-iq/classes/work-queues/widget-refresh", ["exports", "adept-iq/classes/work-queue", "adept-iq/config/environment"], function (_exports, _workQueue, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _workQueue.default.extend({
    name: 'wgt-refresh',
    workspace: null,

    // settings defined in `config/environment.js`
    init() {
      this._super(...arguments);

      this.setProperties(_environment.default.work['wgt-refresh']);
    },

    isDisabled: Ember.computed.readOnly('workspace.isRoadSupervisorModeEnabled'),

    perform(jobs) {
      let count = 0;
      jobs.forEach(job => {
        const {
          collection,
          dataJoin
        } = job;
        const changed = dataJoin.join(collection, {
          sync: true
        });

        if (changed) {
          count += 1;
        }
      });
      return count;
    }

  });

  _exports.default = _default;
});