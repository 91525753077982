define("adept-iq/pods/components/iq-widgets/delete-booking-form/delete-reason/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CK5BdPgR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"passanger-list-form\"],[9],[0,\"\\n  \"],[7,\"table\"],[11,\"class\",\"g-side-drawer-panel-form compact\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-widget-readonly-section\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"th\"],[11,\"class\",\"text-right\"],[9],[0,\"Reason\"],[10],[0,\"\\n        \"],[7,\"td\"],[9],[0,\"\\n          \"],[1,[27,\"textarea\",null,[[\"class\",\"rows\",\"placeholder\",\"maxlength\",\"oninput\",\"value\"],[\"form-control\",\"2\",\"Please mention Reason\",\"150\",[27,\"action\",[[22,0,[]],\"onNotesChange\",[23,[\"note\"]]],null],[23,[\"note\"]]]]],false],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"hint pull-right\"],[9],[1,[21,\"notesCharactersLength\"],false],[0,\"/150\"],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/delete-booking-form/delete-reason/template.hbs"
    }
  });

  _exports.default = _default;
});