define("adept-iq/pods/components/iq-widgets/reportlist-widget/hyperlink-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['solution-table-base-cell', 'data-test-solution-table-base-cell']
  });

  _exports.default = _default;
});