define("adept-iq/pods/components/side-drawer/add-widget/widget-button-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['add-widget-section'],
    displayButtons: true,
    widgetSection: null,
    isOpen: true,
    actions: {
      onSectionButtonClick() {
        // expand / contract the button
        this.toggleProperty('displayButtons');
      },

      onWidgetButtonClick(widgetType) {
        this.get('addWidget')(widgetType);
      },

      onHeaderClick() {
        this.toggleProperty('isOpen');
      }

    }
  });

  _exports.default = _default;
});