define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-address/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    title: 'Matching Trips',
    defaultSortId: 'tripId',
    defaultSortAsc: true,
    reconcileSearchTables: true,
    showSelectedRowLength: false,
    noRecordsFound: false,
    columns: [{
      label: 'Trip Date',
      id: 'tripDate',
      valuePath: 'tripDate',
      index: 0,
      type: 'text',
      width: '100px',
      resizable: true,
      minResizeWidth: 100,
      searchable: true
    }, {
      label: 'Trip ID',
      valuePath: 'tripId',
      id: 'tripId',
      index: 1,
      type: 'text',
      width: '60px',
      resizable: true,
      minResizeWidth: 60,
      searchable: true
    }, {
      id: 'status',
      index: 2,
      label: 'Status',
      type: 'text',
      valuePath: 'status',
      width: '80px',
      resizable: true,
      minResizeWidth: 80,
      searchable: true
    }, {
      id: 'clientName',
      index: 3,
      type: 'text',
      label: 'Client Name',
      valuePath: 'fullName',
      width: '147px',
      resizable: true,
      minResizeWidth: 147,
      searchable: true
    }, {
      id: 'actualDepartTime',
      index: 4,
      type: 'time',
      label: 'Pick Time',
      valuePath: 'actualDepartTime',
      format: 'hh:mm A',
      width: '80px',
      resizable: true,
      minResizeWidth: 80
    }, {
      id: 'actualArrivalTime',
      index: 5,
      type: 'time',
      label: 'Drop Time',
      valuePath: 'actualArrivalTime',
      format: 'hh:mm A',
      width: '80px',
      resizable: true,
      minResizeWidth: 80
    }, {
      id: 'pickAddress',
      index: 6,
      type: 'text',
      label: 'Pick Address',
      valuePath: 'pickAddress',
      width: '270px',
      resizable: true,
      minResizeWidth: 279
    }, {
      id: 'dropAddress',
      index: 7,
      type: 'text',
      label: 'Drop Address',
      valuePath: 'dropAddress',
      width: '290px',
      resizable: true,
      minResizeWidth: 290
    }, {
      id: 'providerName',
      index: 8,
      type: 'text',
      label: 'Provider ID',
      valuePath: 'providerName',
      width: '100px',
      resizable: true,
      minResizeWidth: 100
    }, {
      id: 'routeId',
      index: 9,
      type: 'text',
      label: 'Route ID',
      valuePath: 'routeid',
      width: '100px',
      resizable: true,
      minResizeWidth: 100
    }]
  };
  _exports.default = _default;
});