define("adept-iq/classes/rules/eligibility-rules/itp-blackout-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type", "moment", "lodash"], function (_exports, _baseRule, _ruleResultType, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ItpBlackoutRule = void 0;
  const FROM_DATE_PROPERTY_NAME = 'From Date(MM/DD/YYYY)';
  const FROM_TIME_PROPERTY_NAME = 'From Time(HH:MM a)';
  const TO_DATE_PROPERTY_NAME = 'To Date(MM/DD/YYYY)';
  const TO_TIME_PROPERTY_NAME = 'To Time(HH:MM a)';
  const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';
  const DATE_FORMAT = 'MM/DD/YY';
  const TIME_FORMAT = 'hh:mm A';
  const RECURRENCE_PROPERTY_NAME = 'Recurs Daily(Yes/No)';

  class ItpBlackoutRule extends _baseRule.default {
    constructor() {
      super();
    }

    doEvaluate(ruleContext) {
      const {
        bookingService,
        store
      } = ruleContext;
      const selectedRiders = bookingService.get('selectedRiders');
      const itpBlockouts = store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-ITP_Blockoutdate';
      });
      const normalizedItpBlockouts = itpBlockouts.map(config => {
        const normalizedConfig = config.toJSON();
        const normalizedValue = {};
        (0, _lodash.forEach)(config.value, (value, key) => {
          normalizedValue[key.trim().toLowerCase()] = value;
        });
        normalizedConfig.value = normalizedValue;
        return normalizedConfig;
      });
      const containsBlockoutDate = selectedRiders.every(rider => {
        return this.checkRiderBlockoutDate(rider, bookingService, normalizedItpBlockouts);
      });
      if (containsBlockoutDate) return _ruleResultType.RESULT_TYPE_YES;
      return _ruleResultType.RESULT_TYPE_NO;
    }

    checkRiderBlockoutDate(riderExternal, bookingService, itpBlockouts) {
      const activeBooking = bookingService.get('activeBooking');
      const legs = activeBooking.get('legs');
      return legs.every(leg => {
        const travelTime = (0, _moment.default)(leg.get('requestTime')).toDate();
        return itpBlockouts.some(_ref => {
          let {
            value
          } = _ref;
          const recurProperty = value[RECURRENCE_PROPERTY_NAME.toLowerCase()] || '';

          if (recurProperty.toLowerCase() === 'yes') {
            const fromDateValue = value[FROM_DATE_PROPERTY_NAME.toLowerCase()];
            const fromTimeValue = value[FROM_TIME_PROPERTY_NAME.toLowerCase()];
            const toDateValue = value[TO_DATE_PROPERTY_NAME.toLowerCase()];
            const toTimeValue = value[TO_TIME_PROPERTY_NAME.toLowerCase()];
            const travelRequestDate = fromDateValue.length <= 8 ? (0, _moment.default)(leg.get('requestTime')).format(DATE_FORMAT) : (0, _moment.default)(leg.get('requestTime')).format('MM/DD/YYYY');
            const travelRequestTime = (0, _moment.default)(leg.get('requestTime')).format(TIME_FORMAT);
            const isDateBetween = (0, _moment.default)(travelRequestDate, DATE_FORMAT).isBetween((0, _moment.default)(fromDateValue, DATE_FORMAT), (0, _moment.default)(toDateValue, DATE_FORMAT), undefined, '[]'); // eslint-disable-line

            const isTimeBetween = (0, _moment.default)(travelRequestTime, TIME_FORMAT).isBetween((0, _moment.default)(fromTimeValue, TIME_FORMAT), (0, _moment.default)(toTimeValue, TIME_FORMAT), undefined, '[]'); // eslint-disable-line

            return isDateBetween && isTimeBetween;
          }

          const fromDateValue = value[FROM_DATE_PROPERTY_NAME.toLowerCase()] + ' ' + value[FROM_TIME_PROPERTY_NAME.toLowerCase()];
          const toDateValue = value[TO_DATE_PROPERTY_NAME.toLowerCase()] + ' ' + value[TO_TIME_PROPERTY_NAME.toLowerCase()];
          const blockoutStartDate = (0, _moment.default)(fromDateValue, DATE_TIME_FORMAT).toDate();
          const blockoutEndDate = (0, _moment.default)(toDateValue, DATE_TIME_FORMAT).toDate();
          const before = blockoutStartDate <= travelTime;
          const after = blockoutEndDate >= travelTime;
          return before && after;
        });
      });
    }

  }

  _exports.ItpBlackoutRule = ItpBlackoutRule;
  ItpBlackoutRule.RULE_NAME = 'ItpBlackoutRule';
});