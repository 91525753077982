define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/pre-polyline", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/map", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api"], function (_exports, _react, _map, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PrePolyline = () => {
    const {
      map
    } = _react.default.useContext(_map.MapContext);

    const {
      eventData
    } = (0, _api.useAPI)();
    const points = eventData.filter(event => event.displayLat !== 'N/A' && event.displayLng !== 'N/A').map(event => [Number(event.displayLat), Number(event.displayLng)]);

    _react.default.useEffect(() => {
      if (!map) return;
      const polyline = window.L.polyline(points, {
        color: '#A9B2B5',
        opacity: 1,
        weight: 3
      });
      polyline.addTo(map.native);
      return () => map.native.removeLayer(polyline);
    }, [eventData]);

    return _react.default.createElement(_react.default.Fragment, null);
  };

  var _default = PrePolyline;
  _exports.default = _default;
});