define("adept-iq/tests/factories/avlm-zone", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const names = ['Zone 1', 'Zone 2', 'Zone 3', 'Zone 4', 'Zone 5', 'Zone 6', 'Zone 7', 'Zone 8'];
  const types = [{
    type: 'Burrough'
  }, {
    type: 'AAR'
  }, {
    type: 'User'
  }];

  _emberDataFactoryGuy.default.define('avlm-zone', {
    sequences: {
      zoneNames: num => names[num % names.length],
      zoneTypes: num => types[num % types.length].type
    },
    polymorphic: false,
    default: {
      name: _emberDataFactoryGuy.default.generate('zoneNames'),
      type: _emberDataFactoryGuy.default.generate('zoneTypes')
    }
  });
});