define("adept-iq/pods/components/side-drawer/system-config/no-show-reason-categories/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['no-show-reason-categories'],
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    workspace: Ember.inject.service(),
    noShowReasonRec: null,
    disableForm: true,
    isNewNoShowReason: false,
    noShowReasonChanges: false,
    disableBtns: null,
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    disableUndobtn: Ember.computed.readOnly('disableSavebtn'),
    noShowReasonFormChanges: Ember.computed('noShowReasonChanges', 'noShowReasonRec.{id,name,code,displayName,minutesFromPromiseTimeStart,minutesFromPromiseTimeEnd,driverNeedsApproval}', function () {
      const noShowReasonChanges = this.get('noShowReasonChanges');

      if (noShowReasonChanges) {
        return true;
      }

      return false;
    }),
    disableSavebtn: Ember.computed('disableForm', 'noShowReasonRec.{id,name,displayName,code}', function () {
      const disableForm = this.get('disableForm');

      if (disableForm) {
        return true;
      }

      const hasValues = Object.values(this.get('noShowReasonRec')).some(input => !Ember.isEmpty(input));
      return !hasValues;
    }),
    // @TODO: transform into computed property similar to route master
    selectedRowChange: Ember.observer('selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      this.setDefaultProperties(selectedRow);
      this.buttonSettings();

      if (!selectedRow) {
        this.buttonSettings(false, true, true);
      }
    }),

    init() {
      this._super(...arguments);

      this.set('trueFalseOptions', [{
        value: true,
        display: 'true'
      }, {
        value: false,
        display: 'false'
      }]);
      this.set('noShowReasonRec', []);
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      const fields = {
        name: '',
        displayName: '',
        code: '',
        minutesFromPromiseTimeStart: 0,
        minutesFromPromiseTimeEnd: 0,
        driverNeedsApproval: false
      };

      if (Ember.isPresent(record)) {
        Object.entries(fields).forEach(key => {
          fields[key[0]] = record.get(key[0]);
        });
      }

      this.setProperties({
        'disableForm': true,
        'noShowReasonChanges': false,
        'noShowReasonRec': Ember.Object.create(fields)
      });
    },

    buttonSettings() {
      let newbtn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let editbtn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      let deletebtn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      const disableBtns = {
        new: newbtn,
        edit: editbtn,
        delete: deletebtn
      };
      this.set('disableBtns', disableBtns);
    },

    save: (0, _emberConcurrency.task)(function* () {
      const record = this.buildNoShowReasonData();
      return yield record.save();
    }).drop(),

    buildNoShowReasonData() {
      let record = null;
      const store = this.get('store');
      const value = this.get('noShowReasonRec');
      const displayName = value.get('displayName');
      const camelizedName = Ember.String.camelize(displayName);
      const isNewNoShowReason = this.get('isNewNoShowReason');

      if (isNewNoShowReason) {
        const name = camelizedName.replace(/[_\W]+/g, '_');
        const data = {
          category: 'config-System_Configuration-no_show_reason_categories',
          type: 'object',
          displayName,
          name,
          value
        };
        record = store.createRecord('cs-config-item', data);
      } else {
        const name = value.get('name');
        const id = `config-System_Configuration-no_show_reason_categories/${name}`;
        record = store.peekRecord('cs-config-item', id);
        record.set('displayName', displayName);
        record.set('value', value);
      }

      return record;
    },

    delete: (0, _emberConcurrency.task)(function* () {
      const record = this.get('selectedRow');
      const notifications = this.get('notifications');

      try {
        const id = `config-System_Configuration-no_show_reason_categories/${record.id}`;
        const configItemRecord = this.get('store').peekRecord('cs-config-item', id);
        yield configItemRecord.destroyRecord();
        const noShowCategoryRecord = this.get('store').peekRecord('no-show-reason-category', record.id);
        yield noShowCategoryRecord.unloadRecord();
        this.get('tableRef').onUncheckAll();
        this.get('tableRef').refreshData(); //to refresh table data

        notifications.success('Record successfully deleted.');
      } catch (error) {
        console.error(error); // eslint-disable-line

        notifications.warning('Record failed to delete.');
      }
    }).drop(),

    async validateNoShowReason(noShowReason) {
      const notifications = this.get('notifications');
      const records = this.get('tableRef.records');
      const id = this.get('selectedRow.id');
      const minutesFromPromiseTimeStart = noShowReason.get('minutesFromPromiseTimeStart');
      const minutesFromPromiseTimeEnd = noShowReason.get('minutesFromPromiseTimeEnd');
      const timeStart = Ember.isEmpty(minutesFromPromiseTimeStart) ? 0 : parseInt(minutesFromPromiseTimeStart, 10);
      const timeEnd = Ember.isEmpty(minutesFromPromiseTimeEnd) ? 9999 : parseInt(minutesFromPromiseTimeEnd, 10);
      let isValid = true;

      if (minutesFromPromiseTimeStart.length > 3 || minutesFromPromiseTimeEnd.length > 3) {
        notifications.warning('Promise time start/end have a maximum value of 999.');
        isValid = false;
      }

      if (isValid && minutesFromPromiseTimeStart > minutesFromPromiseTimeEnd && !Ember.isEmpty(minutesFromPromiseTimeEnd)) {
        notifications.warning('Promise time start must be less than or equal to promise time end.');
        isValid = false;
      }

      if (isValid) {
        records.forEach(record => {
          const currentId = record.get('id');
          const currentTimeStart = Ember.isEmpty(record.get('minutesFromPromiseTimeStart')) ? 0 : parseInt(record.get('minutesFromPromiseTimeStart'), 10);
          const currentTimeEnd = Ember.isEmpty(record.get('minutesFromPromiseTimeEnd')) ? 9999 : parseInt(record.get('minutesFromPromiseTimeEnd'), 10);

          if (currentId !== id) {
            if (timeStart >= currentTimeStart && timeStart <= currentTimeEnd || timeEnd >= currentTimeStart && timeEnd <= currentTimeEnd) {
              isValid = false;
              notifications.warning('Promise time start/end overlaps with another range.');
            }
          }
        });
      }

      if (Ember.isEmpty(noShowReason.get('displayName')) || noShowReason.get('displayName') === '') {
        notifications.warning('Display Name cannot be empty.');
        isValid = false;
      }

      if (Ember.isEmpty(noShowReason.get('code')) || noShowReason.get('code') === '') {
        notifications.warning('Code cannot be empty.');
        isValid = false;
      }

      return isValid;
    },

    async refreshNoShowReasonDOM(noShowReason) {
      const tableRef = this.get('tableRef');
      await noShowReason.reload();
      this.get('tableRef').onUncheckAll();
      this.get('tableRef').refreshData();
      Ember.run.later(() => {
        Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData'); // refresh data
      }, 500);
      this.set('noShowReasonChanges', false);
    },

    async resetNoShowReasonDOM() {
      const selectedRow = this.get('selectedRow');
      this.setDefaultProperties(selectedRow);
      this.buttonSettings();

      if (!selectedRow) {
        this.buttonSettings(false, true, true);
      }

      this.set('noShowReasonChanges', false);
    },

    onSaveNoShowReason() {
      const value = this.get('noShowReasonRec');
      return this.validateNoShowReason(value).then(isValid => {
        if (isValid) {
          const notifications = this.get('notifications');
          const tableRef = this.get('tableRef');
          return this.get('save').perform().then(noShowReason => {
            tableRef.onUncheckAll();
            notifications.success('Record successfully saved');
            this.refreshNoShowReasonDOM(noShowReason);
          }).catch(() => {
            notifications.warning('Record failed to save');
          });
        }
      });
    },

    actions: {
      newNoShowReason() {
        this.setDefaultProperties();
        this.setProperties({
          isNewNoShowReason: true,
          disableForm: false,
          noShowReasonChanges: false
        });
        this.buttonSettings(true, true, true);
      },

      editNoShowReason() {
        const selectedRow = this.get('selectedRow');
        this.setDefaultProperties(selectedRow);
        this.setProperties({
          isNewNoShowReason: false,
          disableForm: false,
          noShowReasonChanges: false
        });
        this.buttonSettings(true, true, true);
      },

      deleteNoShowReason() {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'System Configuration',
          hasoverlay: true,
          tip: 'Are you sure you want to Delete these changes?',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('delete').perform().then(() => {
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',

          secondaryAction() {
            return tooltip.reset();
          }

        });
      },

      async saveNoShowReason() {
        this.onSaveNoShowReason();
      },

      async confirmNoShowReason() {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'System Configuration',
          hasOverlay: true,
          tip: 'Are you sure you want to save these changes?',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            this.onSaveNoShowReason().then(() => {
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',

          secondaryAction() {
            return tooltip.reset();
          }

        });
      },

      undoNoShowReason() {
        this.resetNoShowReasonDOM();
      },

      onNoShowReasonDropDownChanges(keyToSet, selectedObj) {
        if (keyToSet) {
          this.set(keyToSet, selectedObj.value);
        }

        this.set('noShowReasonChanges', true);
      },

      onNoShowReasonFormChanges() {
        this.set('noShowReasonChanges', true);
      }

    }
  });

  _exports.default = _default;
});