define("adept-iq/pods/components/side-drawer/activity-log/vehicle-activity-log/component", ["exports", "ember-concurrency", "adept-iq/pods/components/side-drawer/activity-log/vehicle-activity-log/config", "adept-iq/pods/components/side-drawer/activity-log/base-activity-log/component", "adept-iq/config/environment"], function (_exports, _emberConcurrency, _config, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VEHICLE_LOGGING_TYPE = 'vehicle';

  var _default = _component.default.extend({
    tagName: '',
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    columns: _config.columns,
    vehicle: Ember.computed.readOnly('stackFrame.options.vehicle'),
    route: Ember.computed.readOnly(_environment.default.APP.avlmLite ? 'vehicle.latestRoute' : 'vehicle.latestDsRoute.route'),
    displayName: Ember.computed.readOnly('stackFrame.options.displayName'),
    title: Ember.computed.alias('displayName'),
    table: null,
    isLoading: Ember.computed.alias('fetchEventsTask.isRunning'),
    startDate: Ember.computed.alias('workspace.startDate'),
    endDate: Ember.computed.alias('workspace.endDate'),
    onFilteredEventsChange: Ember.observer('filteredEvents', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateTableRows');
    }),
    fetchEventsTask: (0, _emberConcurrency.task)(function* () {
      const store = this.store;
      const vehicleId = this.get('vehicle.id');
      const activityLogAdapter = this.get('store').adapterFor('activity-log');

      function fetchFullRecords(activityLogs) {
        return Promise.all(activityLogs.data.map(activityLog => {
          const driver = store.peekRecord('driver', activityLog.attributes.driverId);
          const vehicle = store.peekRecord('vehicle', activityLog.attributes.vehicleId);

          if (driver) {
            activityLog.attributes.driver = driver;
          } else if (activityLog.attributes.driverId && parseInt(activityLog.attributes.driverId, 10) > 0) {
            store.findRecord('driver', activityLog.attributes.driverId).then(driverModel => {
              activityLog.attributes.driver = driverModel;
            });
          }

          if (vehicle) {
            activityLog.attributes.vehicle = vehicle;
          } else if (activityLog.attributes.vehicleId && parseInt(activityLog.attributes.vehicleId, 10) > 0) {
            store.findRecord('vehicle', activityLog.attributes.vehicleId).then(vehicleModel => {
              activityLog.attributes.vehicle = vehicleModel;
            });
          }
        })).then(() => {
          return activityLogs;
        });
      } // clear events table


      this.set('events', []);
      yield activityLogAdapter.getActivityLogs({
        vehicleId,
        eventLog: true
      }).then(async activityLogs => {
        return fetchFullRecords(activityLogs);
      }).then(activityLogs => {
        this.processActivityLogs(activityLogs, VEHICLE_LOGGING_TYPE);
      });
    }).drop()
  });

  _exports.default = _default;
});