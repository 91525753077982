define("adept-iq/pods/components/side-drawer/system-config/ivr/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.displayOnlyMessages = void 0;
  const displayOnlyMessages = {
    mainMenuStandard: 'For touch tone-only, press 7. What would you like to do? Say schedule to schedule a new trip with a representative. Say cancel to cancel an existing trip. Say information for information about your scheduled trips. Say bus to speak to a representative about the bus schedule. Say help for help with this system. Say repeat to repeat these options. Say representative to speak to a customer representative during normal business hours or press 0 at anytime during this call. If you prefer to enter your responses on your telephone keypad, press 7.',
    mainMenuIssues: 'For touch tone-only, press 7. What would you like to do? to schedule a new trip with a representative, say schedule or press 1. to cancel an existing trip, say cancel or press 2. for information about your scheduled trips, say information or press 3. to speak to a representative about the bus schedule, say bus or press 6. for help with this system, say help or press star. to repeat these options, say repeat or press 9. Say representative to speak to a customer representative during normal business hours or press 0 at anytime during this call. If you prefer to enter your responses on your telephone keypad, press 7.',
    mainMenuTouchToneOnly: 'Press 1 to schedule a new trip with a representative. Press 2 to cancel an existing trip. Press 3 for information about your scheduled trips. Press 6 to speak to a representative about the bus schedule. To hear instructions at any time, press the star key. Press 9 to repeat these options. Press 0 to speak to a customer representative during normal business hours at anytime during this call.',
    mainMenuHelpSpeech: 'Please listen carefully to each option, and speak your responses clearly. If your prefer to use your telephone\'s touch-tone keypad, press 7.  Also if are calling from an area with loud background noise, we suggest that you press 7.',
    mainMenuHelpTouchToneOnly: 'Please listen carefully to each option, and enter your response on your telephone keypad.',
    confirmStandard: 'We think you have said: <speech-to-text result> Is this correct?',
    confirmIssues: 'We think you have said: <speech-to-text result> If this is correct, say yes or press 1 If this is not correct, say no or press 2',
    confirmDidNotUnderstand: 'We did not understand your response. Press 7 to disable speech recognition and only use your touch tone keypad. Afterward you will be returned to the previous options. We think you have said: <speech-to-text result> Is this correct?',
    confirmHelp: 'Please listen carefully to what we thought you said.  If it is what you said, say yes.  If it is not what you said, say no. If you prefer to use your telephone\'s touch-tone keypad, press 7.  Also if you are calling from an area with loud background noise, we suggest that you press 7.',
    riderIdStandard: 'What is your rider ID For instructions, say help If you do not know your rider ID, say representative. To return to the main menu, say main menu.',
    riderIdIssues: 'What is your rider ID For instructions, say help If you do not know your rider ID, say representative. To return to the main menu, say main menu.',
    riderIdTouchToneOnly: 'Please enter your rider ID for the scheduled pickup, followed by the pound key. For instructions, press star pound. If you do not know your rider ID, press 0 pound. To return to the main menu, press 8 pound.',
    confirmRiderIdStandard: 'You have entered rider ID: <# # # ...> Is this correct?',
    confirmRiderIdIssues: 'You have entered rider ID: <# # # ...> If this is correct, say yes or press 1 If this is not correct, say no or press 2',
    confirmRiderIdTouchToneOnly: 'You have entered rider ID: <# # # ...> If this is correct, press 1. To re-enter, press 2.',
    confirmRiderIdHelpSpeech: 'Please listen carefully to the rider id that we received and say yes if it is correct or no if it is not correct.',
    confirmRiderIdHelpTouchToneOnly: 'Please listen carefully to the rider id that we received and press 1 if it is correct or 2 if it is not correct using your telephone\'s keypad.',
    wait: 'Please wait ... System not responding.  Transfering you to a representative.',
    invalidRiderIdNotFound: 'The rider ID you entered was not found.',
    invalidRiderIdNoTrips: 'There are no scheduled trips for the next <number of booking days> days for the rider ID you entered. For trips scheduled for later dates, you will need to try your call again later or speak to one of our staff.',
    invalidRiderIdStandardMenu: 'Say try again to try entering the rider id again. Say main menu to go to the main menu. Say representative to speak to a customer representative during normal business hours. Say repeat to repeat these options.',
    invalidRiderIdIssuesMenu: 'The rider ID you entered was not found. to try entering the rider id again, say try again or press 1. to go to the main menu, say main menu or press 8. to speak to a customer representative during normal business hours, say representative or press 0. to repeat these options, say repeat or press 9.',
    invalidRiderIdTouchToneOnlyMenu: 'The rider ID you entered was not found. Press 1 to try entering the rider id again. Press 8 to go to the main menu. Press 0 to speak to a customer representative during normal business hours. Press 9 to repeat these options.',
    invalidRiderIdHelpSpeech: 'The rider Id you entered is not valid and cannot be used. Say try again to enter a correct rider ID or 8 to return to the main menu.',
    invalidRiderIdHelpTouchToneOnly: 'The rider Id you entered is not valid and cannot be used. Press 1 to enter a correct rider ID or 8 to return to the main menu.',
    tripListHeader: 'The following is a list of <trip count> scheduled trips for the next <number of booking days> calendar days.',
    tripListTripSummary: 'Pickup scheduled for <pickup date>. The pickup window is between <pickup window start time> and <pickup window end time>. The pickup address is <pickup street number and name>. The destination address is <dropoff street number and name>.',
    tripListFirstTripStandardMenu: 'Say next to go to the next trip. Say cancel to cancel this trip. Say main menu to go to the main menu.',
    tripListFirstTripIssuesMenu: 'to go to the next trip, say next or press 1. to cancel this trip, say cancel or press 2. to go to the main menu, say main menu or press 8.',
    tripListFirstTripTouchToneOnlyMenu: 'Press 1 to go to the next trip. Press 2 to cancel this trip. Press 8 to go to the main menu.',
    tripListStandardMenu: 'Say next to go to the next trip. Say cancel to cancel this trip. Say previous to go to the previous trip. Say main menu to go to the main menu.',
    tripListIssuesMenu: 'to go to the next trip, say next or press 1. to cancel this trip, say cancel or press 2. to go to the previous trip, say previous or press 4. to go to the main menu, say main menu or press 8.',
    tripListTouchToneOnlyMenu: 'Press 1 to go to the next trip. Press 2 to cancel this trip. Press 4 to go to the previous trip. Press 8 to go to the main menu.',
    tripListHelp: 'You are being provided details about a specific trip that you have scheduled for the selected client id.  You can choose to cancel this trip or you can move to the next trip you have scheduled.',
    tripListEmpty: 'There are no scheduled trips for the next <number of booking days> days for the rider ID you entered. For trips scheduled for later dates, you will need to try your call again later or speak to one of our staff.',
    tripListEndOfList: 'There are no more scheduled trips.',
    postCancelHeader: 'This trip has been cancelled.',
    postCancelStandard: 'Your cancellation number is: <# # # ...> Would you like to repeat the cancellation number?',
    postCancelIssues: 'Your cancellation number is: <# # # ...> Would you like to repeat the cancellation number? Say yes or press 1. Otherwise say no or press 2 to continue.',
    postCancelTouchToneOnly: 'Your cancellation number is: <# # # ...> To repeat the cancellation number, press 1. Otherwise press 2 to continue.',
    postCancelHelpSpeech: 'Please listen carefully to your cancellation number and record it for future reference. When you are ready , say continue to return to the list of trips.',
    postCancelHelpTouchToneOnly: 'Please listen carefully to your cancellation number and record it for future reference. When you are ready , press 2 to return to the list of trips.',
    transferOutput: 'Please wait while you are transfered to a representative.',
    didNotUnderstand: 'We did not understand your response.'
  };
  _exports.displayOnlyMessages = displayOnlyMessages;
});