define("adept-iq/adapters/-rms-riderManagement", ["exports", "adept-iq/adapters/application", "adept-iq/config/api-urls"], function (_exports, _application, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    defaultSerializer: '-rms-riderManagement',
    host: _apiUrls.API.riderManagementService.host,
    iquxServiceHost: _apiUrls.API.iquxService.host,

    /**
     * Modified Performance Improvement, the API in the backend uses avlmMessage rather than
     * avlmCannedMessage, and avlm-canned-message need to update avlmService.
     * @param id
     * @param modelName
     * @param snapshot
     * @returns {string}
     */
    urlForFindRecord(id, modelName) {
      const host = this.get('host');
      const baseUrl = `${host}/${modelName}/${id}`;
      return baseUrl;
    }

  });

  _exports.default = _default;
});