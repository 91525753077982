define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel/index", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/session", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel/searchModeSwitcher", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel/datetimePicker", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel/searchByVehicle", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel/searchByLocation", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel/confirm-box", "moment"], function (_exports, _react, _panel, _session, _api, _searchModeSwitcher, _datetimePicker, _searchByVehicle, _searchByLocation, _confirmBox, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const SearchPanel = () => {
    const {
      token,
      providers,
      notifications
    } = _react.default.useContext(_session.SessionContext);

    const timezone = _moment.default.tz.guess();

    const {
      setRvdData,
      doSearch,
      setEventData,
      setVehicleData,
      setIsRvdPreloaded,
      query,
      setQuery
    } = (0, _api.useAPI)();
    const {
      searchMode,
      date,
      setDate,
      startTime,
      setStartTime,
      endTime,
      setEndTime,
      setCanSubmit,
      selectedRvd,
      setSelectedRvd,
      isLoadingEvent,
      isLoadingVehicle,
      setIsLoadingEvent,
      setIsLoadingVehicle,
      setLocation,
      location,
      radiusInFeet,
      radiusInMiles,
      radiusMode,
      setRadiusInMiles,
      setRadiusInFeet,
      setIsRunning,
      setIsStarted,
      setIsPaused,
      setIsEnded,
      setSpeedMode,
      setIsPinCursor
    } = (0, _panel.usePanel)();

    const resetAll = () => {
      setLocation({});
      setDate();
      setStartTime();
      setEndTime();
      setCanSubmit(false);
      setRvdData([]);
      setEventData([]);
      setVehicleData([]);
      setSelectedRvd({});
      setRadiusInMiles(0);
      setRadiusInFeet(0);
      setIsRvdPreloaded(false);
      setIsRunning(false);
      setIsStarted(false);
      setIsPaused(false);
      setIsEnded(false);
      setSpeedMode(_panel.speedModes.x1);
      setIsPinCursor(false);

      if (query) {
        query.cancel();
        setQuery(null);
      }

      if (isLoadingEvent || isLoadingVehicle) {
        setIsLoadingEvent(false);
        setIsLoadingVehicle(false);
      }
    };

    const searchEvents = () => {
      let callback = () => setIsLoadingEvent(false);

      setEventData([]);
      setVehicleData([]);

      if (searchMode === _panel.searchModes.byVehicle) {
        setIsLoadingEvent(true);
      } else {
        setSelectedRvd({});
        setIsLoadingVehicle(true);

        callback = () => {
          setIsLoadingVehicle(false);
        };
      }

      const radius = radiusMode === _panel.radiusModes.miles ? radiusInMiles : radiusInFeet;
      const feetOrMile = radiusMode === _panel.radiusModes.miles ? 'mi' : 'ft';
      doSearch(startTime, endTime, timezone, token, providers, notifications, searchMode, selectedRvd, location, radius, feetOrMile, callback);
    };

    _react.default.useEffect(() => {
      if (!date) {
        setCanSubmit(false);
        return;
      }

      const d = new Date(date);

      const _startTime = (0, _moment.default)(d).startOf('day').toISOString();

      const _endTime = (0, _moment.default)(d).endOf('day').toISOString();

      setStartTime(_startTime);
      setEndTime(_endTime);
      setCanSubmit(true);
    }, [date]);

    _react.default.useEffect(() => {
      resetAll();
    }, [searchMode, providers]);

    return _react.default.createElement("div", {
      className: "flex-body-left"
    }, _react.default.createElement(_confirmBox.default, null), _react.default.createElement(_searchModeSwitcher.default, null), _react.default.createElement("div", {
      className: "playback-widget-form"
    }, _react.default.createElement("div", {
      className: "form-container"
    }, _react.default.createElement(_datetimePicker.default, null), _react.default.createElement("div", null, _react.default.createElement("hr", null)), searchMode === _panel.searchModes.byVehicle ? _react.default.createElement(_searchByVehicle.default, null) : _react.default.createElement(_searchByLocation.default, null), _react.default.createElement("div", {
      className: "form-row mt-4"
    }, _react.default.createElement("div", {
      className: "btn-group"
    }, _react.default.createElement("button", {
      className: "btn default",
      type: "button",
      onClick: () => {
        resetAll();
      }
    }, "Clear"), _react.default.createElement("button", {
      type: "button",
      disabled: !selectedRvd.vehicleId && !location.position || location.position && radiusInMiles <= 0 && radiusInFeet <= 0,
      onClick: () => {
        searchEvents();
      },
      className: "btn default"
    }, "Search"))))));
  };

  var _default = SearchPanel;
  _exports.default = _default;
});