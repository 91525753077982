define("adept-iq/pods/dashboard/modals/edit-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L+Uk+kay",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"containerClassNames\",\"overlayClass\",\"targetAttachment\",\"hasOverlay\"],[\"edit-widget-modal\",\"edit-widget-overlay\",\"left\",false]],{\"statements\":[[0,\"\\n  \"],[1,[27,\"dashboard/modals/edit-form/resize-handle\",null,[[\"onResizeModal\"],[[27,\"action\",[[22,0,[]],\"resizeModal\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"editModal\",\"editComponent\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"component\",[[23,[\"editModal\",\"editComponent\"]]],[[\"editableRecords\"],[[23,[\"editableRecords\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/dashboard/modals/edit-form/template.hbs"
    }
  });

  _exports.default = _default;
});