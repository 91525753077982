define("adept-iq/services/reconcile", ["exports", "lodash", "adept-iq/config/api-urls"], function (_exports, _lodash, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    reconcileLambda: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    host: _apiUrls.API.ReconciliationNewService.host,
    reconcileTripDetails: null,
    reconcileSelectedRouteId: null,
    reconcileSelectedRoute: null,
    reconcileRouteMap: null,
    reconcileStopPointMap: null,
    reconcileVehicle: null,
    reconcileDriver: null,
    reconcileDate: null,
    lastRowClickedInTripTable: null,
    reconcileMode: null,
    selectedSearchRoute: null,
    reconcileProviderName: null,
    reconcileSelectedTripId: null,
    selectedSearchedTrip: null,
    reconcileRouteInfo: null,
    selectedTripRow: null,
    showSearchPopUp: false,
    reconcileTaxiData: null,
    isRouteUpdated: false,
    isLoading: false,
    reconcileRVDResponse: null,
    routeResponseFromTripDate: null,
    verifyRouteErrors: false,
    closePopupHandler: null,
    verifyRouteIsChecked: false,
    serviceModeTaxiType: 'Taxi',
    routeOptionEnabled: Ember.computed.empty('reconcile.selectedProvider'),
    isDeletedRoute: Ember.computed.alias('reconcileRouteDetails.isDeleted'),
    isCompletedRoute: Ember.computed.alias('reconcileRouteDetails.isCompleted'),
    reconcilePassenger: Ember.computed.alias('reconcileTrip.passenger'),
    reconcileDrivers: Ember.computed('reconcileRVDResponse', 'reconcileRouteInfo', function () {
      const rvdResponse = this.get('reconcileRVDResponse') || [];
      const reconcileRouteInfo = this.get('reconcileRouteInfo') || [];
      const driverOptions = rvdResponse.filter(rvd => {
        return rvd.driver && rvd.driver.id !== reconcileRouteInfo.driver.id;
      }).map(driver => {
        return driver.driver;
      });
      return driverOptions;
    }),
    reconcileVehicles: Ember.computed('reconcileRVDResponse', 'reconcileRouteInfo', function () {
      const rvdResponse = this.get('reconcileRVDResponse') || [];
      const reconcileRouteInfo = this.get('reconcileRouteInfo') || [];
      const vehicleOptions = rvdResponse.filter(rvd => {
        return rvd.vehicle && rvd.vehicle.id !== reconcileRouteInfo.vehicle.id;
      }).map(vehicle => {
        return vehicle.vehicle;
      });
      return vehicleOptions;
    }),
    reconcileDriverOptions: Ember.computed('reconcileProviderName', function () {
      const rvdResponse = this.get('reconcileRVDResponse') || [];
      const providerName = this.get('reconcileProviderName');
      const driverOptions = rvdResponse.filter(rvd => {
        return rvd.vehicle && rvd.vehicle && rvd.vehicle.providername === providerName;
      }).map(vehicle => {
        return vehicle.driver;
      });
      return driverOptions;
    }),
    reconcileVehicleOptions: Ember.computed('reconcileProviderName', function () {
      const rvdResponse = this.get('reconcileRVDResponse') || [];
      const providerName = this.get('reconcileProviderName');
      const vehicleOptions = rvdResponse.filter(rvd => {
        return rvd.vehicle && rvd.vehicle.providername === providerName;
      }).map(vehicle => {
        return vehicle.vehicle;
      });
      return vehicleOptions;
    }),
    reconcileLateTrips: Ember.computed('reconcileTripDetails', function () {
      const stopList = Ember.get(this, 'reconcileTripDetails');
      const pendingStatusTypes = ['scheduled', 's'];
      const stopTypes = ['pickup', 'pick', 'p'];

      if (!Ember.isEmpty(stopList)) {
        const filteredData = stopList.filter(stop => !Ember.isEmpty(stop.tripId) && !Ember.isEmpty(stop.type) && stopTypes.includes(stop.type.toLowerCase()) && !Ember.isEmpty(stop.status) && pendingStatusTypes.includes(stop.status.toLowerCase()));
        filteredData.map(data => {
          data.otpCaption = parseInt(data.otpStatus, 10) === -1 ? 'Late' : 'Early';
        });
        return filteredData;
      }

      return [];
    }),

    async fetchReconcileTrips(routeId) {
      const reconcileTripRes = await this.reconcileLambda.getReconcileTripsByRouteId.perform(routeId);

      if (Ember.isPresent(reconcileTripRes)) {
        this.set('reconcileTripDetails', reconcileTripRes);
        const reconcileStopPointMap = (0, _lodash.groupBy)(reconcileTripRes.map(tripDetails => {
          return {
            tripId: tripDetails.tripId,
            stopPointId: tripDetails.stopPointId,
            lat: tripDetails.address ? tripDetails.address.latitude : '',
            lng: tripDetails.address ? tripDetails.address.longitude : '',
            type: tripDetails.type,
            address: tripDetails.address,
            trip: tripDetails
          };
        }), 'tripId');
        this.set('reconcileStopPointMap', reconcileStopPointMap);
      }
    },

    async fetchReconcileRouteInfo(routeId) {
      const reconcileRouteRes = await this.reconcileLambda.getReconcileRouteById.perform(routeId);

      if (Ember.isPresent(reconcileRouteRes)) {
        this.set('reconcileRouteInfo', reconcileRouteRes[0]);
        await this.fetchReconcileTrips(routeId);
      }
    },

    getDriverOptions(rvdResponse, selectedOption) {
      return rvdResponse.filter(rvDriver => rvDriver.driver && rvDriver.driver.badgenumber !== selectedOption.badgenumber).map(driver => driver.driver);
    },

    getVehicleOptions(rvdResponse, selectedOption) {
      return rvdResponse.filter(rvVehicle => rvVehicle.vehicle && rvVehicle.vehicle.callsign !== selectedOption.callsign).map(vehicle => vehicle.vehicle);
    },

    resetProperties() {
      Ember.set(this, 'updateRouteInformation', false);
    },

    closePopups() {
      if (this.get('workspace.stackDepth')) {
        this.get('workspace').popState();
      }
    },

    //Building the Put Method API Call for Route Info
    updateReconcileRouteInfo(routePayload) {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const session = this.get('session');

      try {
        if (routePayload) {
          return this.get('ajax').put(this.get('host') + '/route', {
            method: 'PUT',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: routePayload
          });
        }
      } catch (error) {
        return error;
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    updateReconcileTripsInfo(tripPayload) {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const session = this.get('session');

      try {
        if (tripPayload) {
          return this.get('ajax').patch(this.get('host') + '/trip', {
            method: 'PATCH',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: tripPayload
          });
        }
      } catch (error) {
        return error;
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    verifyRoute(routeId) {
      const url = this.get('host') + '/verify-route?';
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      return new Promise(async (resolve, reject) => {
        this.get('ajax').request(this.reconcileLambda.buildUrlWithStartAndEndDate(url) + `&routeId=${routeId}`, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
          }
        }).then(res => {
          resolve(res);
          this.get('workspace').set('isGlobalSpinnerVisible', false);
        }).catch(err => {
          reject(err);
          this.get('workspace').set('isGlobalSpinnerVisible', false);
        });
      });
    },

    deleteReconcileRouteInfo(routePayload, type) {
      const session = this.get('session');
      return this.get('ajax').patch(this.get('host') + `/route?type=${type}`, {
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: routePayload
      });
    },

    saveNewTrip(newTripPayload) {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const session = this.get('session');

      try {
        if (newTripPayload) {
          return this.get('ajax').post(this.get('host') + '/trip', {
            method: 'POST',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: newTripPayload
          });
        }
      } catch (error) {
        return error;
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    saveNewReconcileRoute(newRoutePayload) {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const session = this.get('session');

      try {
        if (newRoutePayload) {
          return this.get('ajax').post(this.get('host') + '/route', {
            method: 'POST',
            contentType: 'application/json',
            headers: {
              Authorization: `Bearer ${session.data.authenticated.token}`
            },
            data: newRoutePayload
          });
        }
      } catch (error) {
        return error;
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    addRouteBreak(payload, type) {
      const session = this.get('session');
      return this.get('ajax').post(this.get('host') + `/${type}`, {
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: payload
      });
    },

    updateTripTransferToAnotherRoute(tripPayload) {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const session = this.get('session');

      try {
        if (tripPayload) {
          return this.get('ajax').put(this.get('host') + '/trip', {
            method: 'PUT',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: tripPayload
          });
        }
      } catch (error) {
        return error;
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    }

  });

  _exports.default = _default;
});