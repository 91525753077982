define("adept-iq/utils/vehicleCapacity", ["exports", "adept-iq/models/travel-need-type"], function (_exports, _travelNeedType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notEnoughSeatsTripInRoute = _exports.notEnoughCapacity = _exports.missingNonConsumable = _exports.isUnderCapacity = _exports.currentStopCapacity = void 0;

  // one type is capable then it is capable.
  function flexSeatsIsCapable(travelNeed, flexSeats) {
    return flexSeats.some(flexSeat => {
      return Object.entries(travelNeed).every(_ref => {
        let [travelNeedTypeName, travelNeedCount] = _ref;
        const capacityCount = isNaN(flexSeat[travelNeedTypeName]) ? 0 : flexSeat[travelNeedTypeName];
        const requestCount = isNaN(travelNeedCount) ? 0 : travelNeedCount;
        return capacityCount >= requestCount;
      });
    });
  }

  const notEnoughCapacity = (travelNeedCounts, capacityCounts) => {
    if (!capacityCounts) return false;
    const notEnough = Object.entries(travelNeedCounts).some(_ref2 => {
      let [travelNeedTypeName, travelNeedCount] = _ref2;
      const capacityCount = isNaN(capacityCounts[travelNeedTypeName]) ? 0 : capacityCounts[travelNeedTypeName];
      const requestCount = isNaN(travelNeedCount) ? 0 : travelNeedCount;
      return capacityCount < requestCount;
    });

    if (notEnough) {
      // Search flexSeats instead
      if (capacityCounts.flexSeats && capacityCounts.flexSeats.length > 0) {
        return !flexSeatsIsCapable(travelNeedCounts, capacityCounts.flexSeats);
      }
    }

    return notEnough;
  };

  _exports.notEnoughCapacity = notEnoughCapacity;

  const missingNonConsumable = (tripNonConsumables, vehicleNonConsumables, travelNeedTypes) => {
    const missingArray = [];
    if (Ember.isEmpty(tripNonConsumables)) return missingArray;
    Object.entries(tripNonConsumables).forEach(_ref3 => {
      let [travelNeedTypeName] = _ref3;
      const travelNeedType = travelNeedTypes.find(obj => obj.id === travelNeedTypeName);

      if (!vehicleNonConsumables.hasOwnProperty(travelNeedType.get('vehicleCapacityType.id'))) {
        missingArray.push({
          id: travelNeedTypeName,
          displayName: travelNeedType.get('displayName')
        });
      }
    });
    return missingArray;
  };
  /**
   * will travel need have enough capacity for this flex seat group
   * @param travelNeed                //trip travel needs
   * @param nonConsumableTravelNeeds  //trip travel non-consumable Needs
   * @param flexSeat                  //vehicle flexible seats
   * @param nonConsumables            //vehicle non-consumable equipments
   * @returns {Boolean}
   * @private
   */


  _exports.missingNonConsumable = missingNonConsumable;

  function flexSeatHaveCapacity(travelNeed, nonConsumableTravelNeeds, flexSeat, nonConsumables) {
    const haveCapacityCount = Object.entries(travelNeed).every(_ref4 => {
      let [travelNeedTypeName, travelNeedCount] = _ref4;
      const capacityCount = flexSeat[travelNeedTypeName] || 0;
      return capacityCount >= travelNeedCount;
    });
    const haveNonConsumableEquipments = Object.entries(nonConsumableTravelNeeds).every(_ref5 => {
      let [travelNeedTypeName] = _ref5;
      const capacity = nonConsumables[travelNeedTypeName]; //noConsumable equipments would record under vehicle capacity group 0 and 0 count;

      return capacity !== null && capacity >= 0;
    });
    return haveCapacityCount && haveNonConsumableEquipments;
  }

  const isUnderCapacity = (dispatchRoute, vehicleCapacityCounts) => {
    const clusters = dispatchRoute.get('orderedClusters').toArray();
    const flexSeats = vehicleCapacityCounts ? vehicleCapacityCounts.flexSeats : null;
    const nonConsumables = vehicleCapacityCounts ? vehicleCapacityCounts.nonConsumables : null;
    let haveCapacity = true;
    if (clusters.length < 1) return !haveCapacity; // have to check max travel needs for each cluster with all possible flex seats

    haveCapacity = clusters.every(cluster => {
      const travelNeed = cluster.get('maxVehicleCapacityNeedCounts');
      const nonConsumableTravelNeeds = cluster.get('nonConsumableTravelNeeds');
      let haveFlexCapacity = false;
      if (!travelNeed || !flexSeats || flexSeats.length < 1) return true;
      haveFlexCapacity = flexSeats.some(flexSeat => {
        return flexSeatHaveCapacity(travelNeed, nonConsumableTravelNeeds, flexSeat, nonConsumables);
      });
      return haveFlexCapacity;
    });
    return !haveCapacity;
  }; // returns the current capacity available before stopPoint


  _exports.isUnderCapacity = isUnderCapacity;

  const currentStopCapacity = (stopPoint, dispatchRoute, vehicle) => {
    const orderedStops = dispatchRoute.get('orderedStops');
    const capacityCounts = vehicle ? vehicle.get('capacityCounts') : {};
    const currentTravelNeedCapacity = {
      ambulatory: capacityCounts.ambulatory || 0,
      wheelchair: capacityCounts.wheelchair || 0
    };

    for (const stop of orderedStops) {
      if (stop.id === stopPoint.get('id')) {
        break;
      }

      const tripTravelNeedCounts = stop.get('trip.travelNeedCounts');

      if (stop.get('isPick')) {
        currentTravelNeedCapacity.ambulatory -= tripTravelNeedCounts.ambulatory;
        currentTravelNeedCapacity.wheelchair -= tripTravelNeedCounts.wheelchair;
      } else if (stop.get('isDrop')) {
        currentTravelNeedCapacity.ambulatory += tripTravelNeedCounts.ambulatory;
        currentTravelNeedCapacity.wheelchair += tripTravelNeedCounts.wheelchair;
      }
    }

    return currentTravelNeedCapacity;
  }; // return trips not having enough seats given new travelNeedItems for stopPoint


  _exports.currentStopCapacity = currentStopCapacity;

  const notEnoughSeatsTripInRoute = (travelNeedItems, stopPoint, dispatchRoute, vehicle) => {
    const ambulatoryTravelNeedItem = travelNeedItems.find(item => item.type === _travelNeedType.AMBULATORY_TRAVELNEED_TYPE);
    const wheelchairTravelNeedItem = travelNeedItems.find(item => item.type === _travelNeedType.WHEELCHAIR_TRAVELNEED_TYPE);
    const orderedStops = dispatchRoute.get('orderedStops');
    const capacityCounts = vehicle ? vehicle.get('capacityCounts') : {};
    const currentTripId = stopPoint.get('trip.tripId');
    const flexSeats = capacityCounts.flexSeats;
    let tripsInDanger = [];
    let minimumTripsInDanger = []; // go through all the flex seats possibilities

    const atLeastOnePass = flexSeats.some(flexSeat => {
      const currentTravelNeedCapacity = {
        ambulatory: flexSeat.ambulatory || 0,
        wheelchair: flexSeat.wheelchair || 0
      };
      tripsInDanger = [];

      for (const stop of orderedStops) {
        const tripTravelNeedCounts = stop.get('trip.travelNeedCounts');
        const stopTripId = stop.get('trip.tripId');

        if (stopTripId === currentTripId) {
          if (stop.get('isPick')) {
            currentTravelNeedCapacity.ambulatory -= ambulatoryTravelNeedItem.count;
            currentTravelNeedCapacity.wheelchair -= wheelchairTravelNeedItem.count;
          } else if (stop.get('isDrop')) {
            currentTravelNeedCapacity.ambulatory += ambulatoryTravelNeedItem.count;
            currentTravelNeedCapacity.wheelchair += wheelchairTravelNeedItem.count;
          }
        } else if (stopTripId !== currentTripId) {
          if (stop.get('isPick')) {
            currentTravelNeedCapacity.ambulatory -= tripTravelNeedCounts.ambulatory;
            currentTravelNeedCapacity.wheelchair -= tripTravelNeedCounts.wheelchair;
          } else if (stop.get('isDrop')) {
            currentTravelNeedCapacity.ambulatory += tripTravelNeedCounts.ambulatory;
            currentTravelNeedCapacity.wheelchair += tripTravelNeedCounts.wheelchair;
          }
        }

        if (stop.get('isPick')) {
          if (currentTravelNeedCapacity.ambulatory < 0 || currentTravelNeedCapacity.wheelchair < 0) {
            tripsInDanger.pushObject(stopTripId);
          }
        }
      } // this flex seat fits. exit.


      if (tripsInDanger.length === 0) {
        return true;
      } // does not fit for this flex seat. keep the best possibilities


      if (minimumTripsInDanger.length === 0 || minimumTripsInDanger.length > tripsInDanger.length) {
        minimumTripsInDanger = tripsInDanger;
      }

      return false;
    });
    if (atLeastOnePass) return [];
    return minimumTripsInDanger;
  };

  _exports.notEnoughSeatsTripInRoute = notEnoughSeatsTripInRoute;
});