define("adept-iq/topic-transforms/driver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload
  /*, ENV*/
  ) {
    const transformedPayloads = [];
    if (!payload.data || !payload.data.relationships) return transformedPayloads;
    const {
      availabilities,
      attributes
    } = payload.data.relationships;

    if (availabilities) {
      // rename key availabilities -> driverAvailabilities
      delete payload.data.relationships.availabilities;
      payload.data.relationships.driverAvailabilities = availabilities;
    }

    if (attributes) {
      delete payload.data.relationships.attributes;
      payload.data.relationships.driverAttributes = attributes;
    }

    transformedPayloads.push(payload);
    return transformedPayloads;
  }
});