define("adept-iq/adapters/stop-point", ["exports", "adept-iq/adapters/-dispatch", "adept-iq/config/api-urls"], function (_exports, _dispatch, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    session: Ember.inject.service(),
    iquxServiceHost: _apiUrls.API.iquxService.host,

    // Adapted from https://github.com/emberjs/data/blob/v3.1.1/addon/-private/adapters/build-url-mixin.js#L89-L109
    urlForUpdateRecord(id, modelName) {
      let path;
      let url = [];
      const host = Ember.get(this, 'host');
      const prefix = this.urlPrefix();

      if (modelName) {
        path = this.pathForType(modelName);

        if (path) {
          url.push(path);
        }
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      return url;
    },

    handleResponse(status, headers, payload, requestData) {
      let newStatus = status;
      let newPayload = payload;

      if (status === 400 && Ember.isPresent(payload.validation)) {
        newStatus = 422;
        newPayload = {
          errors: [{
            detail: payload.message
          }]
        };
      }

      return this._super(newStatus, headers, newPayload, requestData);
    },

    urlForFindRecord(id, modelName
    /*snapshot*/
    ) {
      const model = Ember.String.camelize(modelName);
      const iquxServiceHost = this.get('iquxServiceHost');
      const baseUrl = `${iquxServiceHost}/${model}/${id}`;
      return baseUrl;
    },

    /**
     * this function get an stop point and calculates the max available capacity during the stopPoint trip.
     *  based on previous and future shared trips. it can be calculated with  new travel needs overwritten
     *  default leg.travelNeeds if provided.
    @param stopPointId the stopPoint id that needs to be calculated
    @param customTravelNeeds the travelNeeds are need to be overwritten.
    @returns stopPoint available capacity (or deficiencies), and list the trips that are not going to be serviceable (if any).
     */
    getStopPointCapacity(stopPointId) {
      let customTravelNeeds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      const session = this.get('session');
      let payload = null;

      if (customTravelNeeds) {
        const customStopPointsPayload = [{
          id: stopPointId,
          travelNeeds: [{
            travelNeedTypeName: 'ambulatory',
            count: customTravelNeeds.ambulatory
          }, {
            travelNeedTypeName: 'wheelchair',
            count: customTravelNeeds.wheelchair
          }]
        }];
        payload = {
          data: {
            attributes: {
              stopPoints: customStopPointsPayload
            },
            type: 'travelNeedRequirement'
          }
        };
      }

      return this.get('ajaxService').post(_apiUrls.API.dispatchService.host + '/stop-point/' + stopPointId + '/capacity-check', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: payload
      });
    }

  });

  _exports.default = _default;
});