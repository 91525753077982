define("adept-iq/tests/factories/avlm-rider-event", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const date = (0, _moment.default)().startOf('day').add(8, 'hours');
  const ahead = (0, _moment.default)().startOf('day').add(9, 'hours');

  _emberDataFactoryGuy.default.define('avlm-rider-event', {
    polymorphic: false,
    // manually flag this model as NOT polymorphic
    default: {
      anchor: 'Y',
      type: 'D',
      noteForDriver: 'Enter from the Back',
      scheduledTime: date.toDate(),
      promisedTime: date.toDate(),
      serviceStartTime: date.toDate(),
      serviceEndTime: ahead.toDate()
    }
  });
});