define("adept-iq/tests/factories/no-show-reason-code", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('no-show-reason-code', {
    default: {
      code: 'C',
      externalCode: 'C',
      description: 'Generic Cancel'
    }
  });
});