define("adept-iq/models/reconcile-verify-route", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    routeId: attr('string'),
    providerName: attr('string'),
    isVerified: attr('boolean'),
    actualStartTime: attr('date'),
    actualEndTime: attr('date'),
    plannedStartTime: attr('date'),
    plannedEndTime: attr('date'),
    type: attr('string'),
    status: attr('string'),
    errors: attr('object')
  });

  _exports.default = _default;
});