define("adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/config", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/config", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/session", "lodash"], function (_exports, _react, _config, _config2, _session, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.usePanel = _exports.speedModes = _exports.searchModes = _exports.radiusModes = _exports.labelDisplayModes = _exports.filterOperations = _exports.PanelContextProvider = _exports.PanelContext = void 0;
  const searchModes = {
    byVehicle: 'byVehicle',
    byLocation: 'byLocation',
    byLocationVehicle: 'byLocationVehicle'
  };
  _exports.searchModes = searchModes;
  const speedModes = {
    x1: '1x',
    x2: '2x',
    x4: '4x',
    x8: '8x'
  };
  _exports.speedModes = speedModes;
  const labelDisplayModes = {
    always: 'always',
    onLeftClick: 'onLeftClick'
  };
  _exports.labelDisplayModes = labelDisplayModes;
  const radiusModes = {
    miles: 'miles',
    feet: 'feet'
  };
  _exports.radiusModes = radiusModes;
  const filterOperations = {
    eq: 'eq',
    neq: 'neq',
    contains: 'contains',
    empty: 'empty'
  };
  _exports.filterOperations = filterOperations;

  const PanelContext = _react.default.createContext({
    isLoadingRvd: false
  });

  _exports.PanelContext = PanelContext;

  const PanelContextProvider = _ref => {
    let {
      children
    } = _ref;

    const [searchMode, setSearchMode] = _react.default.useState(searchModes.byVehicle);

    const [date, setDate] = _react.default.useState();

    const [startTime, setStartTime] = _react.default.useState();

    const [endTime, setEndTime] = _react.default.useState();

    const [canSubmit, setCanSubmit] = _react.default.useState(false);

    const [selectedRvd, setSelectedRvd] = _react.default.useState({});

    const [sliderIndex, setSliderIndex] = _react.default.useState(0);

    const [sliderIndexMax, setSliderIndexMax] = _react.default.useState(0);

    const [speedMode, setSpeedMode] = _react.default.useState(speedModes.x1);

    const [isRunning, setIsRunning] = _react.default.useState(false);

    const [isPaused, setIsPaused] = _react.default.useState(false);

    const [isEnded, setIsEnded] = _react.default.useState(false);

    const [isStarted, setIsStarted] = _react.default.useState(false);

    const [movingVehicle, setMovingVehicle] = _react.default.useState();

    const [isLoadingRvd, setIsLoadingRvd] = _react.default.useState(false);

    const [isLoadingEvent, setIsLoadingEvent] = _react.default.useState(false);

    const [isLoadingVehicle, setIsLoadingVehicle] = _react.default.useState(false);

    const [mapFilterEnabled, setMapFilterEnabled] = _react.default.useState(false);

    const [vehicleTableFilterEnabled, setVehicleTableFilterEnabled] = _react.default.useState(false);

    const [eventTableFilterEnabled, setEventTableFilterEnabled] = _react.default.useState(false);

    const [labelDisplayMode, setLabelDisplayMode] = _react.default.useState(labelDisplayModes.onLeftClick);

    const [confirmStack, setConfirmStack] = _react.default.useState([]);

    const [isPinCursor, setIsPinCursor] = _react.default.useState(false);

    const [location, setLocation] = _react.default.useState({});

    const [radiusMode, setRadiusMode] = _react.default.useState(radiusModes.miles);

    const [radiusInMiles, setRadiusInMiles] = _react.default.useState(0);

    const [radiusInFeet, setRadiusInFeet] = _react.default.useState(0);

    const [eventSearchText, setEventSearchText] = _react.default.useState('');

    const [vehicleSearchText, setVehicleSearchText] = _react.default.useState('');

    const [vehicleTableData, setVehicleTableData] = _react.default.useState([]);

    const [eventTableData, setEventTableData] = _react.default.useState([]);

    const {
      widgetState,
      setWidgetState
    } = _react.default.useContext(_session.SessionContext);

    const [highlightedRowIndex, setHighlightedRowIndex] = _react.default.useState(-1);

    const togglePinCursor = () => {
      setIsPinCursor(!isPinCursor);
    };

    const defaultMapEventFilters = _config.VehicleEventOptions.map(option => {
      const savedMapEventFilters = widgetState.mapEventFilters || [];

      const savedOption = _lodash.default.find(savedMapEventFilters, o => o.id === option.id);

      return { ...option,
        isChecked: savedOption ? savedOption.isChecked : true
      };
    });

    const [mapEventFilters, setMapEventFilters] = _react.default.useState(defaultMapEventFilters);

    const saveWidgetMapEventFilters = filters => {
      setWidgetState({ ...widgetState,
        mapEventFilters: filters.map(o => {
          return {
            id: o.id,
            isChecked: o.isChecked
          };
        })
      });
    };

    const saveEventTableColumns = columns => {
      setWidgetState({ ...widgetState,
        eventTableColumns: columns.map(o => {
          return {
            id: o.id,
            isChecked: o.isChecked,
            isLabeledInMap: o.isLabeledInMap,
            filterOp: o.filterOp,
            filterValue: o.filterValue
          };
        })
      });
    };

    const saveVehicleTableColumns = columns => {
      setWidgetState({ ...widgetState,
        vehiclesTableColumns: columns.map(o => {
          return {
            id: o.id,
            isChecked: o.isChecked,
            filterOp: o.filterOp,
            filterValue: o.filterValue
          };
        })
      });
    };

    const toggleMapEventFilter = (id, value) => {
      const newFilters = [...mapEventFilters];
      const updateFilter = newFilters.find(filter => filter.id === id);

      if (updateFilter) {
        updateFilter.isChecked = value;
      }

      setMapEventFilters(newFilters);
      saveWidgetMapEventFilters(newFilters);
    };

    const defaultEventTableColumns = _config2.default.vehicleEventsTableColumns.map(option => {
      const savedEventTableColumns = widgetState.eventTableColumns || [];

      const savedOption = _lodash.default.find(savedEventTableColumns, o => o.id === option.id);

      return { ...option,
        isChecked: savedOption ? savedOption.isChecked : true,
        isLabeledInMap: savedOption ? savedOption.isLabeledInMap : true,
        filterOp: savedOption ? savedOption.filterOp : '',
        filterValue: savedOption ? savedOption.filterValue : ''
      };
    });

    const [eventTableColumns, setEventTableColumns] = _react.default.useState(defaultEventTableColumns);

    const toggleEventTableColumn = (id, value) => {
      const newColumns = [...eventTableColumns];
      const columnToUpdate = newColumns.find(column => column.id === id);

      if (columnToUpdate) {
        columnToUpdate.isChecked = value;
      }

      setEventTableColumns(newColumns);
      saveEventTableColumns(newColumns);
    };

    const toggleEventTableLabels = (id, value) => {
      const newColumns = [...eventTableColumns];
      const columnToUpdate = newColumns.find(column => column.id === id);

      if (columnToUpdate) {
        columnToUpdate.isLabeledInMap = value;
      }

      setEventTableColumns(newColumns);
      saveEventTableColumns(newColumns);
    };

    const toggleAllEventTableColumns = value => {
      const newColumns = [...eventTableColumns];
      newColumns.forEach(column => {
        column.isChecked = value;
      });
      setEventTableColumns(newColumns);
      saveEventTableColumns(newColumns);
    };

    const toggleAllEventTableLabels = value => {
      const newColumns = [...eventTableColumns];
      newColumns.forEach(column => {
        column.isLabeledInMap = value;
      });
      setEventTableColumns(newColumns);
      saveEventTableColumns(newColumns);
    };

    const defaultVehicleTableColumns = _config2.default.vehiclesTableColumns.map(option => {
      const savedEventTableColumns = widgetState.vehiclesTableColumns || [];

      const savedOption = _lodash.default.find(savedEventTableColumns, o => o.id === option.id);

      return { ...option,
        isChecked: savedOption ? savedOption.isChecked : true,
        filterOp: savedOption ? savedOption.filterOp : '',
        filterValue: savedOption ? savedOption.filterValue : ''
      };
    });

    const [vehicleTableColumns, setVehicleTableColumns] = _react.default.useState(defaultVehicleTableColumns);

    const toggleVehicleTableColumn = (id, value) => {
      const newColumns = [...vehicleTableColumns];
      const columnToUpdate = newColumns.find(column => column.id === id);

      if (columnToUpdate) {
        columnToUpdate.isChecked = value;
      }

      setVehicleTableColumns(newColumns);
      saveVehicleTableColumns(newColumns);
    };

    const toggleAllVehicleTableColumns = value => {
      const newColumns = [...vehicleTableColumns];
      newColumns.forEach(column => {
        column.isChecked = value;
      });
      setVehicleTableColumns(newColumns);
      saveVehicleTableColumns(newColumns);
    };

    const updateEventColumn = (id, update) => {
      const newColumns = [...eventTableColumns];
      const columnIndexToUpdate = newColumns.findIndex(column => column.id === id);
      newColumns[columnIndexToUpdate] = update;
      setEventTableColumns(newColumns);
      saveVehicleTableColumns(newColumns);
    };

    const updateVehicleColumn = (id, update) => {
      const newColumns = [...vehicleTableColumns];
      const columnIndexToUpdate = newColumns.findIndex(column => column.id === id);
      newColumns[columnIndexToUpdate] = update;
      setVehicleTableColumns(newColumns);
      saveVehicleTableColumns(newColumns);
    };

    const syncStatus = () => {
      if (!movingVehicle) return;
      setIsStarted(movingVehicle.isStarted());
      setIsRunning(movingVehicle.isRunning());
      setIsEnded(movingVehicle.isEnded());
      setIsPaused(movingVehicle.isPaused());
    };

    const startPlay = () => {
      if (!movingVehicle) return;

      if (!movingVehicle.isStarted() || movingVehicle.isEnded()) {
        movingVehicle.start();
      } else if (!movingVehicle.isRunning()) {
        movingVehicle.resume();
      }

      syncStatus();
    };

    const pausePlay = () => {
      if (!movingVehicle) return;
      movingVehicle.pause();
      syncStatus();
    };

    const setToPlay = targetIndex => {
      if (!movingVehicle || targetIndex !== 0 && !targetIndex) return;
      setSliderIndex(targetIndex);

      if (!movingVehicle.isStarted() || movingVehicle.isEnded()) {
        movingVehicle.start();
        movingVehicle.setTo(targetIndex);
        movingVehicle.pause();
      } else {
        movingVehicle.setTo(targetIndex);
      }

      syncStatus();
    };

    const forwardPlay = () => {
      if (!movingVehicle) return;
      setToPlay(Math.min(sliderIndex + 10, sliderIndexMax));
    };

    const backwardPlay = () => {
      if (!movingVehicle) return; // AIQ-2102 if less than 10 rows played, don't set it back

      if (sliderIndex - 10 >= 0) {
        setToPlay(Math.max(sliderIndex - 10, 0));
      }
    };

    const speedPlay = mode => {
      if (!movingVehicle) return;
      setSpeedMode(mode);

      switch (mode) {
        case speedModes.x1:
          movingVehicle.setSpeed(1);
          break;

        case speedModes.x2:
          movingVehicle.setSpeed(2);
          break;

        case speedModes.x4:
          movingVehicle.setSpeed(4);
          break;

        case speedModes.x8:
          movingVehicle.setSpeed(8);
          break;

        default:
          movingVehicle.setSpeed(1);
      }

      syncStatus();
    };

    return _react.default.createElement(PanelContext.Provider, {
      value: {
        searchMode,
        setSearchMode,
        date,
        setDate,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        canSubmit,
        setCanSubmit,
        selectedRvd,
        setSelectedRvd,
        sliderIndex,
        setSliderIndex,
        sliderIndexMax,
        setSliderIndexMax,
        speedMode,
        setSpeedMode,
        isRunning,
        setIsRunning,
        isPaused,
        setIsPaused,
        isEnded,
        setIsEnded,
        isStarted,
        setIsStarted,
        movingVehicle,
        setMovingVehicle,
        startPlay,
        pausePlay,
        setToPlay,
        forwardPlay,
        backwardPlay,
        speedPlay,
        isLoadingRvd,
        setIsLoadingRvd,
        isLoadingEvent,
        setIsLoadingEvent,
        isLoadingVehicle,
        setIsLoadingVehicle,
        mapFilterEnabled,
        setMapFilterEnabled,
        vehicleTableFilterEnabled,
        setVehicleTableFilterEnabled,
        eventTableFilterEnabled,
        setEventTableFilterEnabled,
        setEventTableColumns,
        mapEventFilters,
        toggleMapEventFilter,
        eventTableColumns,
        toggleEventTableColumn,
        toggleVehicleTableColumn,
        toggleAllEventTableColumns,
        toggleAllVehicleTableColumns,
        toggleEventTableLabels,
        toggleAllEventTableLabels,
        updateEventColumn,
        updateVehicleColumn,
        vehicleTableColumns,
        setVehicleTableColumns,
        labelDisplayMode,
        setLabelDisplayMode,
        confirmStack,
        setConfirmStack,
        isPinCursor,
        setIsPinCursor,
        togglePinCursor,
        location,
        setLocation,
        radiusInFeet,
        setRadiusInFeet,
        radiusInMiles,
        setRadiusInMiles,
        radiusMode,
        setRadiusMode,
        eventSearchText,
        setEventSearchText,
        vehicleSearchText,
        setVehicleSearchText,
        vehicleTableData,
        eventTableData,
        setVehicleTableData,
        setEventTableData,
        highlightedRowIndex,
        setHighlightedRowIndex
      }
    }, children);
  };

  _exports.PanelContextProvider = PanelContextProvider;

  const usePanel = () => {
    const context = _react.default.useContext(PanelContext);

    return context;
  };

  _exports.usePanel = usePanel;
});