define("adept-iq/pods/components/form-components/address-vehicle-place-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VJVGye9m",
    "block": "{\"symbols\":[\"address\"],\"statements\":[[7,\"input\"],[12,\"class\",[28,[\"address-search-input \",[21,\"inputClass\"]]]],[11,\"placeholder\",\"Search Address...\"],[12,\"value\",[21,\"selectedValue\"]],[12,\"oninput\",[27,\"action\",[[22,0,[]],\"onInput\"],[[\"value\"],[\"target.value\"]]]],[12,\"onfocus\",[27,\"action\",[[22,0,[]],\"onInputFocus\"],null]],[12,\"onblur\",[27,\"action\",[[22,0,[]],\"onInputBlur\"],null]],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isExpanded\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"address-search-dropdown\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"or\",[[23,[\"searchTask\",\"isRunning\"]],[23,[\"searchPlaceTask\",\"isRunning\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"loading-message\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fa fa-circle-o-notch fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" Loading...\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[23,[\"searchResults\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"address-search-result\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onAddressSelect\",[22,1,[]]],null]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isPlaceNameDropdown\"]]],null,{\"statements\":[[0,\"            \"],[1,[22,1,[\"alias\"]],false],[0,\" - \"],[1,[22,1,[\"fullAddress\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[22,1,[\"fullAddress\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"no-results-message\"],[9],[0,\"No results found.\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/address-vehicle-place-search/template.hbs"
    }
  });

  _exports.default = _default;
});