define("adept-iq/workers/avl-ping", [], function () {
  "use strict";

  self.addEventListener('message', function (m) {
    const {
      vehicleId,
      timestamp,
      lat,
      lng,
      url,
      token
    } = m.data;
    return fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        data: {
          type: 'avl',
          attributes: {
            vehicleId,
            timestamp,
            lat,
            lng
          }
        }
      })
    });
  }, false);
});