define("adept-iq/topic-transforms/avlm-route-break", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload
  /*, ENV*/
  ) {
    const transformedPayloads = [];
    const {
      route,
      schedule,
      address
    } = payload.data.relationships; // create placeholders so ember won't do a get

    if (schedule) {
      payload.data.relationships.avlmSchedule = schedule;
      delete payload.data.relationships.schedule;
    }

    if (route) {
      payload.data.relationships.avlmRoute = route;
      delete payload.data.relationships.route;
    }

    if (address) {
      payload.data.relationships.avlmAddress = address;
      delete payload.data.relationships.address;
    }

    transformedPayloads.push({
      data: {
        id: payload.data.id,
        type: 'avlm-route-break'
      }
    });
    transformedPayloads.push({
      data: {
        id: `B${payload.data.id}`,
        type: 'avlm-stop-point',
        attributes: payload.data.attributes,
        relationships: {
          stopRoute: {
            data: {
              type: 'avlm-route',
              id: route.data.id
            }
          },
          routeBreak: {
            data: {
              type: 'avlm-route-break',
              id: payload.data.id
            }
          }
        }
      }
    });
    transformedPayloads.push(payload);
    return transformedPayloads;
  }
});