define("adept-iq/pods/components/form-components/location-pick/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bemap
  } = window;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    addressService: Ember.inject.service('address'),
    notifications: Ember.inject.service(),
    mapService: Ember.inject.service('map'),
    geocode: Ember.inject.service(),
    searchResults: null,
    disabled: false,
    // Component properties to control address search and marker/pin display.
    removeAddress: false,
    removeMarker: false,
    activeMarker: null,
    selectedStartLocation: null,
    selectedEndLocation: null,
    // Component properties to control styleing od address search and marker/pin.
    styleAddressCell: '',
    styleAddress: '',
    styleMarker: '',
    // Component properties to provide result back to called function.
    onPickSearch: null,
    onAddressSearch: null,
    isHidePicker: false,
    // Fetch map from workspace, is map widget is used.
    map: Ember.computed.readOnly('workspace.activeContext.topActiveContext.map'),
    mapResetLayers: Ember.computed.readOnly('workspace.activeContext.topActiveContext.mapResetLayers'),
    selectedAddress: Ember.computed('selectAddress', 'selectedStartLocation', 'selectedEndLocation', function () {
      if (Ember.get(this, 'selectAddress')) return Ember.get(this, 'selectAddress');

      if (Ember.get(this, 'selectedStartLocation')) {
        this.addPinMarker(Ember.get(this, 'selectedStartLocation').latlng);
        return Ember.get(this, 'selectedStartLocation');
      }

      if (Ember.get(this, 'selectedEndLocation')) {
        this.addPinMarker(Ember.get(this, 'selectedEndLocation').latlng);
        return Ember.get(this, 'selectedEndLocation');
      }

      return [];
    }),
    resetLocation: Ember.observer('canResetAddress', 'workspace.activeContext.topActiveContext.resetLocation', function () {
      if (Ember.get(this, 'canResetAddress') || Ember.get(this, 'workspace.activeContext.topActiveContext.resetLocation')) {
        Ember.set(this, 'selectAddress', []);
        const map = Ember.get(this, 'map.native');
        const layers = Ember.get(this, 'mapResetLayers');
        if (map && layers) map.eachLayer(layer => {
          if (layer.myCustomID) layer.remove();
        });

        if (Ember.get(this, 'workspace.activeContext.topActiveContext.resetLocation')) {
          Ember.$('html,body,.tomtom-map').removeClass('pinpoint-cursor');
          Ember.set(this, 'workspace.activeContext.topActiveContext.resetLocation', false);
          this.notifyPropertyChange('workspace.activeContext.topActiveContext.resetLocation');
        }
      }
    }),
    resetMapFlag: Ember.observer('canReset', 'workspace.activeContext.topActiveContext.resetMapFlag', function () {
      if (Ember.get(this, 'canReset') || Ember.get(this, 'workspace.activeContext.topActiveContext.resetMapFlag')) {
        const map = Ember.get(this, 'map.native');
        const layers = Ember.get(this, 'mapResetLayers');
        if (map && layers) map.eachLayer(layer => {
          if (layer.myCustomID) layer.remove();
        });

        if (Ember.get(this, 'workspace.activeContext.topActiveContext.resetMapFlag')) {
          Ember.$('html,body,.tomtom-map').removeClass('pinpoint-cursor');
          Ember.set(this, 'workspace.activeContext.topActiveContext.resetMapFlag', false);
          this.notifyPropertyChange('workspace.activeContext.topActiveContext.resetMapFlag');
        }
      }
    }),
    searchTask: (0, _emberConcurrency.task)(function* (term) {
      const searchText = term.toString().trim(); // can't do a blank search

      if (Ember.isEmpty(searchText)) {
        return;
      } // TODO: change center for fuzzySearch


      const results = yield this.get('addressService').fuzzySearch(searchText, {
        limit: 25
      });
      return results;
    }),

    didInsertElement() {
      this._super(...arguments);
    },

    init() {
      this._super(...arguments);
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    addressLocated(address) {
      Ember.set(this, 'selectAddress', address);
      this.onPickSearch(address);
    },

    searchCenterObserver(coord) {
      const searchCenter = coord;
      Ember.get(this, 'addressService').reverseGeocode(searchCenter).then(this.addressLocated.bind(this));
    },

    addPinMarker(latlng) {
      if (!latlng || !latlng.lat || !latlng.lng) {
        // this.get('notifications').warning('Unable to mark address on map latitute and longitude not present');
        return;
      }

      const map = this.get('map.native');
      const pintPointIcon = {
        icon: new bemap.Icon({
          src: '/map-icons/icon-pinpoint.svg',
          anchorX: 0.5,
          anchorY: 1,
          height: 30,
          width: 30,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          scale: 1
        }),
        keyboard: false,
        interactive: false
      };
      const coord = new bemap.Coordinate(latlng.lng, latlng.lat);
      const tomtomMarker = new bemap.Marker(coord, pintPointIcon);
      map.panTo(new window.L.LatLng(latlng.lat, latlng.lng));
      Ember.set(this, 'geocode.activeMarker', tomtomMarker);
      Ember.set(this, 'mapService.topMapContext.lat', latlng.lat);
      Ember.set(this, 'mapService.topMapContext.lng', latlng.lng);
      Ember.set(this, 'mapService.topMapContext.centerLat', latlng.lat);
      Ember.set(this, 'mapService.topMapContext.centerLng', latlng.lng);
    },

    setSearchCenter(coord) {
      if (Ember.get(this, 'searchCenter')) {
        Ember.get(this, 'searchCenter').remove();
      }

      const map = Ember.get(this, 'map.native');
      if (!map) return;
      Ember.set(this, 'searchCenter', this.addPinMarker(coord));
      Ember.set(this, 'markerLocation', { ...coord,
        lon: coord.lng
      });
      this.searchCenterObserver(coord);
    },

    actions: {
      onAddressSelected(record) {
        const lat = record.get('lat');
        const lng = record.get('lng');
        this.set('selectAddress', record);
        this.onAddressSearch(record);

        if (!this.get('isHidePicker')) {
          this.addPinMarker({
            lat,
            lng
          });
        }
      },

      pickOnMap() {
        const mapNative = this.get('map.native');
        const map = this.get('map');

        if (this.get('geocode.activeMarker')) {
          map.removeMarker(this.get('geocode.activeMarker'));
        }

        Ember.set(this, 'isSearchingLocation', !Ember.get(this, 'isSearchingLocation'));

        if (Ember.get(this, 'isSearchingLocation')) {
          Ember.$('html,body,.tomtom-map').addClass('pinpoint-cursor');
          mapNative.on('click', e => {
            if (mapNative) {
              this.setSearchCenter(e.latlng);
              Ember.set(this, 'isSearchingLocation', false);
              Ember.$('html,body,.tomtom-map').removeClass('pinpoint-cursor');
              mapNative.off('click');
            }
          });
        } else {
          Ember.$('html,body,.tomtom-map').removeClass('pinpoint-cursor');
          mapNative.off('click');
        }
      }

    }
  });

  _exports.default = _default;
});