define("adept-iq/utils/regex-functions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.REGEX_REMOVE_TEXT_FORMAT = _exports.REGEX_REMOVE_NUMBER_FORMAT = _exports.REGEX_REMOVE_DECIMAL_FORMAT = _exports.REGEX_ONLY_TEXT_FORMAT = _exports.REGEX_NUMBER = _exports.REGEX_HHMM = _exports.REGEX_FLOAT = _exports.REGEX_EMAIL = void 0;
  const REGEX_HHMM = /^\d?\d?(:\d?\d?)?$/im;
  _exports.REGEX_HHMM = REGEX_HHMM;
  const REGEX_NUMBER = /^\d*$/im;
  _exports.REGEX_NUMBER = REGEX_NUMBER;
  const REGEX_FLOAT = /^\d*\.*\d*$/im;
  _exports.REGEX_FLOAT = REGEX_FLOAT;
  const REGEX_EMAIL = /([0-9a-z]+@[a-z.]+\.[a-zA-Z0-9-.])\w+/im;
  _exports.REGEX_EMAIL = REGEX_EMAIL;
  const REGEX_REMOVE_TEXT_FORMAT = /[^0-9.]+/; //remove alphabet

  _exports.REGEX_REMOVE_TEXT_FORMAT = REGEX_REMOVE_TEXT_FORMAT;
  const REGEX_REMOVE_DECIMAL_FORMAT = /[^0-9]/g; //remove alphabet and decimal(.)

  _exports.REGEX_REMOVE_DECIMAL_FORMAT = REGEX_REMOVE_DECIMAL_FORMAT;
  const REGEX_REMOVE_NUMBER_FORMAT = /\d+/g; //remove numbers(0-9) form string

  _exports.REGEX_REMOVE_NUMBER_FORMAT = REGEX_REMOVE_NUMBER_FORMAT;
  const REGEX_ONLY_TEXT_FORMAT = /^[a-zA-Z]+$/; //remove numbers and special character form string

  _exports.REGEX_ONLY_TEXT_FORMAT = REGEX_ONLY_TEXT_FORMAT;
});