define("adept-iq/initializers/objectProxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /* appInstance */
  initialize() {
    Ember.ObjectProxy.reopen({
      unknownProperty(key) {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }

        const content = this.content;

        if (content && (content.isDestroying || content.isDestroyed)) {
          return;
        }

        return this._super(key);
      }

    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});