define("adept-iq/pods/components/side-drawer/donut-chart-settings/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COLUMNS = {
    routeColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'status',
          editable: true,
          hidden: false,
          format: 'hh:mm A',
          defaultWidth: 75
        },
        filterTypeId: 'stringEq',
        filterValues: ['Scheduled']
      }
    },
    tripColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'status',
          editable: true,
          hidden: false,
          defaultWidth: 75
        },
        filterTypeId: 'stringEq',
        filterValues: ['Scheduled']
      }
    },
    stopColumns: {
      type: {
        id: 'type',
        config: {
          id: 'type',
          type: 'text',
          label: 'Type',
          valuePath: 'bsStop.type'
        },
        filterTypeId: 'stringNotEq',
        filterValues: ['route-start']
      }
    }
  };
  const VisualOptions = [{
    id: 'value',
    name: 'Show Value',
    isChecked: true
  }, {
    id: 'percentage',
    name: 'Show Percentage',
    isChecked: false
  }, {
    id: 'total',
    name: 'Show Total',
    isChecked: true
  }];
  const LegendPlacement = {
    selected: {
      name: 'Display On Right',
      position: 'right'
    },
    options: [{
      name: 'Display On Right',
      position: 'right'
    }, {
      name: 'Display On Left',
      position: 'left'
    }, {
      name: 'Display On Top',
      position: 'top'
    }, {
      name: 'Display On Bottom',
      position: 'bottom'
    }]
  };
  const SortOrder = {
    selected: {
      name: 'Ascending',
      sortAsc: true
    },
    options: [{
      name: 'Ascending',
      sortAsc: true
    }, {
      name: 'Descending',
      sortAsc: false
    }]
  };
  var _default = [{
    name: 'Trips',
    modelName: 'iq-trip',
    columns: COLUMNS.tripColumns,
    displayOptions: [{
      id: 'slicedBy',
      name: 'Sliced By',
      selected: {
        id: 'otp',
        name: 'OTP Status',
        valueKey: 'otp',
        valueCategories: [{
          label: 'On Time',
          value: 'O'
        }, {
          label: 'Late',
          value: 'L'
        }, {
          label: 'In Danger',
          value: 'D'
        }, {
          label: 'Early',
          value: 'E'
        }]
      },
      options: [{
        id: 'status',
        name: 'Trip Status',
        valueKey: 'status',
        valueCategories: [{
          label: 'Scheduled',
          value: 'Scheduled'
        }, {
          label: 'Waitlist',
          value: 'Waitlist'
        }]
      }, {
        id: 'otp',
        name: 'OTP Status',
        valueKey: 'otp',
        valueCategories: [{
          label: 'On Time',
          value: 'O'
        }, {
          label: 'Late',
          value: 'L'
        }, {
          label: 'In Danger',
          value: 'D'
        }, {
          label: 'Early',
          value: 'E'
        }]
      }, {
        id: 'type',
        name: 'Trip Type',
        valueKey: 'type',
        valueCategories: [{
          label: 'Subscription',
          value: 'subscription'
        }, {
          label: 'Single',
          value: 'single'
        }]
      }]
    }],
    title: '',
    visualOptions: VisualOptions,
    sortOrder: SortOrder,
    legendPlacement: LegendPlacement
  }, {
    name: 'Routes',
    modelName: 'route',
    columns: COLUMNS.routeColumns,
    displayOptions: [{
      id: 'slicedBy',
      name: 'Sliced By',
      selected: {
        id: 'otp',
        name: 'OTP Status',
        valueKey: 'otp',
        valueCategories: [{
          label: 'On Time',
          value: 'O'
        }, {
          label: 'Late',
          value: 'L'
        }, {
          label: 'In Danger',
          value: 'D'
        }, {
          label: 'Early',
          value: 'E'
        }]
      },
      options: [{
        id: 'otp',
        name: 'OTP Status',
        valueKey: 'otp',
        valueCategories: [{
          label: 'On Time',
          value: 'O'
        }, {
          label: 'Late',
          value: 'L'
        }, {
          label: 'In Danger',
          value: 'D'
        }, {
          label: 'Early',
          value: 'E'
        }]
      }]
    }],
    title: '',
    visualOptions: VisualOptions,
    sortOrder: SortOrder,
    legendPlacement: LegendPlacement
  }, {
    name: 'Stops',
    modelName: 'iq-stop',
    columns: COLUMNS.stopColumns,
    displayOptions: [{
      id: 'slicedBy',
      name: 'Sliced By',
      selected: {
        id: 'type',
        name: 'Stop Type',
        valueKey: 'bsStop.type',
        valueCategories: [{
          label: 'Pick Up',
          value: 'pick'
        }, {
          label: 'Drop',
          value: 'drop'
        }, {
          label: 'Break',
          value: 'break'
        }, {
          label: 'Route Start',
          value: 'route-start'
        }, {
          label: 'Route End',
          value: 'route-end'
        }]
      },
      options: [{
        id: 'type',
        name: 'Stop Type',
        valueKey: 'bsStop.type',
        valueCategories: [{
          label: 'Pick Up',
          value: 'pick'
        }, {
          label: 'Drop',
          value: 'drop'
        }, {
          label: 'Break',
          value: 'break'
        }, {
          label: 'Route Start',
          value: 'route-start'
        }, {
          label: 'Route End',
          value: 'route-end'
        }]
      }]
    }],
    title: '',
    visualOptions: VisualOptions,
    sortOrder: SortOrder,
    legendPlacement: LegendPlacement
  }];
  _exports.default = _default;
});