define("adept-iq/pods/components/iq-widgets/new-booking-form/selected-item-schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YB6JNDcl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[9],[0,\"Schedule \"],[1,[23,[\"option\",\"name\"]],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/new-booking-form/selected-item-schedule/template.hbs"
    }
  });

  _exports.default = _default;
});