define("adept-iq/pods/components/generic-widgets/ember-react-widget/classes/filter-column", ["exports", "ember-light-table/classes/Column", "adept-iq/config/column-types", "adept-iq/config/filter-types", "adept-iq/utils/filters"], function (_exports, _Column, _columnTypes, _filterTypes, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FilterColumn extends _Column.default {}

  _exports.default = FilterColumn;
  FilterColumn.reopen({
    startDate: null,
    id: null,
    config: null,
    filterTypeId: null,
    filterValues: null,
    valuePathMap: Ember.computed('valuePaths', function () {
      const valuePaths = this.get('valuePaths');
      if (!valuePaths) return {};
      const valuePathMap = valuePaths.reduce((obj, _ref) => {
        let {
          modelName,
          valuePath
        } = _ref;
        obj[modelName] = valuePath;
        return obj;
      }, {});
      return valuePathMap;
    }),
    columnType: Ember.computed('config.type', function () {
      const columnTypeId = this.get('config.type');

      const columnType = _columnTypes.default.findBy('id', columnTypeId);

      if (!columnType) {
        /*eslint-disable */
        throw `no column type with id '${columnTypeId}'`;
        /*eslint-enable */
      }

      return columnType;
    }),
    filterType: Ember.computed('filterTypeId', function () {
      const filterTypeId = this.get('filterTypeId');
      const filterType = _filterTypes.filterTypesHash[filterTypeId];

      if (!filterType) {
        return _filterTypes.nullFilter;
      }

      return filterType;
    }),
    shouldDisableFilterInput: Ember.computed('filterType', 'hidden', function () {
      const filterType = this.get('filterType');
      const hidden = this.get('hidden');
      return filterType.arity === 0 || hidden;
    }),
    filterTypes: Ember.computed('columnType', function () {
      const columnType = this.get('columnType');
      return columnType.filterTypes;
    }),
    isFiltered: Ember.computed('filterType', 'filterValues', function () {
      const filterType = this.get('filterType');
      const filterValues = this.get('filterValues');
      return (0, _filters.testFilterValues)(filterType, filterValues);
    }),
    filterNode: Ember.computed('filterType', 'filterValues', 'config.{alternateValuePath,valuePath,format}', function () {
      const filterType = this.get('filterType');
      const startDate = this.get('startDate');
      let filterValues = this.get('filterValues') || [];
      if (!(0, _filters.testFilterValues)(filterType, filterValues)) return null;
      filterValues = filterValues.map(filterValue => filterType.parseValue(filterValue, startDate));
      const valuePath = this.get('config.alternateValuePath') ? this.get('config.alternateValuePath') : this.get('config.valuePath');
      return (0, _filters.buildValueFilterNode)(filterType, valuePath, filterValues, this.get('config.format'));
    })
  });
});