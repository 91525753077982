define("adept-iq/models/avlm-driver", ["exports", "ember-data", "adept-iq/utils/workspace-availability"], function (_exports, _emberData, _workspaceAvailability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    workspace: Ember.inject.service(),
    badgeNumber: attr('string'),
    firstName: attr('string'),
    middleName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    avlmRouteVehicleDrivers: hasMany('avlm-route-vehicle-driver'),
    workspaceStartTime: Ember.computed.alias('workspace.startDate'),
    workspaceEndTime: Ember.computed.alias('workspace.endDate'),
    driverId: Ember.computed.alias('badgeNumber'),
    latestVehicle: Ember.computed.alias('latestRouteVehicleDriver.vehicle'),
    latestRoute: Ember.computed.alias('latestRouteVehicleDriver.route'),
    avlmDriverId: Ember.computed('badgeNumber', 'latestRoute', function () {
      const routeName = this.get('latestRoute.trackingId');
      if (routeName && routeName.indexOf('SUP-') > -1) return '';
      return this.get('badgeNumber');
    }),
    latestRouteVehicleDriver: Ember.computed('currentRouteVehicleDrivers.@each.startTime', function () {
      const routeVehicleDrivers = this.get('currentRouteVehicleDrivers');
      if (!routeVehicleDrivers) return [];
      return routeVehicleDrivers.sortBy('startTime').get('lastObject');
    }),
    currentRouteVehicleDrivers: Ember.computed('avlmRouteVehicleDrivers.@each.startTime', 'workspaceStartTime', 'workspaceEndTime', function () {
      let routeVehicleDrivers = this.get('avlmRouteVehicleDrivers');
      const startTime = this.get('workspaceStartTime');
      const endTime = this.get('workspaceEndTime');
      if (!routeVehicleDrivers) return [];
      routeVehicleDrivers = routeVehicleDrivers.filter(routeVehicleDriver => {
        if (routeVehicleDriver.get('route.status') === 'C' || routeVehicleDriver.get('route.status') === 'Completed') {
          (0, _workspaceAvailability.processTimeConstraints)(routeVehicleDriver.get('route.tripStartTime'), routeVehicleDriver.get('route.tripEndTime'), startTime, endTime);
        } else {
          return true;
        }
      });
      return routeVehicleDrivers;
    }),
    fullName: Ember.computed('firstName', 'middleName', 'lastName', function () {
      const firstName = this.get('firstName') || '';
      const middleName = this.get('middleName') || '';
      const lastName = this.get('lastName') || '';
      return `${firstName} ${middleName} ${lastName}`.replace(/\s+/, ' ');
    })
  });

  _exports.default = _default;
});