define("adept-iq/tests/factories/schedule-configuration", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('schedule-configuration', {
    default: {
      displayName: 'Default configuration',
      name: 'default',
      description: 'This is a default scheduling configuration',
      isReadOnly: true
    }
  });
});