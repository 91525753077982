define("adept-iq/pods/components/generic-components/table/cells/radio-button/component", ["exports", "adept-iq/pods/components/generic-components/table/cells/base-cell/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['table-radio-button-cell', 'data-test-table-radio-button-cell']
  });

  _exports.default = _default;
});