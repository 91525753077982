define("adept-iq/pods/components/rider-management-widget/rider-activity-log/rider-log-form/component", ["exports", "adept-iq/pods/components/rider-management-widget/rider-activity-log/rider-log-form/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['rider-activity-log-form'],
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    config: _config.default,
    details: null,
    userActivitySelected: null,

    async init() {
      this._super(...arguments);
    },

    validateRecords() {
      let valid = true;
      const notifications = this.get('notifications');
      const activity = this.get('userActivitySelected');
      const details = this.get('details');

      if (Ember.isEmpty(activity)) {
        valid = false;
        notifications.warning('Must Select an Activity');
      }

      if (Ember.isEmpty(details)) {
        notifications.warning('Must Enter Details');
        valid = false;
      }

      return valid;
    },

    actions: {
      closeContactInfoDialog() {
        this.set('canShowActivityLogFormModal', false);
      },

      onValueChange(valuePath, options) {
        let value = options;

        if (typeof options === 'object') {
          value = options.get('name');
        }

        this.set(valuePath, value);
      },

      onTick() {
        if (!this.validateRecords()) return;
        const activity = this.get('userActivitySelected');
        const details = this.get('details');
        const tooltip = this.get('tooltip');
        const title = 'Activity Log';
        const riderId = this.get('riderId');
        const notifications = this.get('notifications');
        const self = this;
        const actions = [{
          activity,
          loggingType: 'riderGeneric',
          details
        }];
        this.set('canShowActivityLogFormModal', false);
        tooltip.pushConfirmation({
          tip: 'Are you sure you want to add an entry to the activity log?',
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          secondaryActionText: 'Cancel',
          primaryAction: () => {
            const activityLogAdapter = self.get('store').adapterFor('activity-log');
            const createBookingActivity = activityLogAdapter.createGenericRiderActivity(riderId, actions);
            return createBookingActivity.then(() => {
              notifications.success('New Activity Log Entry Added Successfully');
              tooltip.reset();
              self.refreshTask.perform();
            }).catch(() => {
              notifications.warning('Failed to add Activity Log');
              tooltip.reset();
            });
          },

          secondaryAction() {
            tooltip.reset();
          }

        });
      }

    }
  });

  _exports.default = _default;
});