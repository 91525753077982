define("adept-iq/models/avlm-trip-rider", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    status: attr('string'),
    avlmSchedule: belongsTo('avlm-schedule'),
    avlmTrip: belongsTo('avlm-trip'),
    avlmRider: belongsTo('avlm-rider')
  });

  _exports.default = _default;
});