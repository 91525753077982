define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel/index", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel/backward", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel/forward", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel/play", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel/pause", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel/slider", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel/speed", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel"], function (_exports, _react, _backward, _forward, _play, _pause, _slider, _speed, _panel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PlaybackPanel = () => {
    const {
      isRunning,
      isStarted,
      isEnded
    } = (0, _panel.usePanel)();
    return _react.default.createElement("fieldset", null, _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: "playback-widget-panel"
    }, _react.default.createElement("table", {
      className: "playback-slider-table"
    }, _react.default.createElement("caption", {
      className: "playback-slider-label"
    }), _react.default.createElement("tbody", {
      style: {
        display: 'flex'
      }
    }, _react.default.createElement("tr", {
      className: "panel-row"
    }, _react.default.createElement("td", null, _react.default.createElement(_backward.default, null), isStarted && isRunning && !isEnded ? _react.default.createElement(_pause.default, null) : _react.default.createElement(_play.default, null), _react.default.createElement(_forward.default, null)), _react.default.createElement("td", null, _react.default.createElement(_slider.default, null)), _react.default.createElement("td", {
      style: {
        width: '150px'
      }
    }, _react.default.createElement(_speed.default, null))))))));
  };

  var _default = PlaybackPanel;
  _exports.default = _default;
});