define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/road-call/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/road-call/road-call-validation", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/road-call/road-call-route-search-config", "adept-iq/config/api-urls", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/road-call/road-call-driver-search-config", "adept-iq/utils/workspace-availability", "moment", "lodash", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _roadCallValidation, _roadCallRouteSearchConfig, _apiUrls, _roadCallDriverSearchConfig, _workspaceAvailability, _moment, _lodash, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_ROAD_TYPE_MAP = {
    addVehicleRoadCall: true,
    editVehicleRoadCall: true,
    deleteVehicleRoadCall: true
  };
  const TODAY = new Date();

  var _default = Ember.Component.extend({
    classNames: ['road-call-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    showErrorMsg: false,
    oldCallDate: null,
    disableForm: true,
    newVehicleInput: false,
    tableRef: null,
    activityLogTableRef: null,
    timeFormat: 'm/d/Y',
    // vehicle Road Call Placeholders
    callDatePlaceholder: _placeholders.US_DATE_PLACEHOLDER,
    codePlaceholder: _placeholders.ROAD_CALL_CODE_PLACEHOLDER,
    codeDescriptionPlaceholder: _placeholders.ROAD_CALL_CODE_DESCRIPTION_PLACEHOLDER,
    driverIdPlaceholder: _placeholders.SELECT_DRIVER_ID_PLACEHOLDER,
    driverLastNamePlaceholder: _placeholders.DRIVER_LAST_NAME_PLACEHOLDER,
    driverFirstNamePlaceholder: _placeholders.DRIVER_FIRST_NAME_PLACEHOLDER,
    driverCdlNumberPlaceholder: _placeholders.DRIVER_CDL_PLACEHOLDER,
    routeIdPlaceholder: _placeholders.ROUTE_ID_PLACEHOLDER,
    mileagePlaceholder: _placeholders.MILEAGE_PLACEHOLDER,
    notesPlaceholder: _placeholders.NOTES_PLACEHOLDER,
    newVehiclePlaceholder: _placeholders.ROAD_CALL_NEW_VEHICLE_PLACEHOLDER,
    // Passed into the table view
    editAPI: null,
    selectedVehicle: Ember.computed.alias('editAPI.selectedRecord'),
    selectedRoadCall: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRoadCallRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    onCallDateChange: Ember.observer('roadCallChangeSet.{callDate}', function () {
      const roadCallChangeSet = this.get('roadCallChangeSet');
      const callDate = this.get('roadCallChangeSet.callDate');
      const RouteIdName = this.get('roadCallChangeSet.route.name');
      const prevCallDate = this.get('oldCallDate');

      if (!(0, _moment.default)(callDate).isSame((0, _moment.default)(prevCallDate), 'day')) {
        this.set('oldCallDate', callDate);

        if (RouteIdName) {
          roadCallChangeSet.set('roadCallChangeSet.route', null);
          roadCallChangeSet.set('roadCallChangeSet.route.name', null);
        }
      }
    }),
    maxDate: null,

    async init() {
      this._super(...arguments);

      this.initializeQuery();
      this.setupDropDownValues();
      this.setMaxDate();
    },

    initializeQuery() {
      const vehicle = this.get('selectedVehicle');
      this.set('pendingRoadCallRecord');
      this.set('activityQueryParams', {
        vehicleId: `${vehicle.get('id')}`,
        eventLog: true
      });
      this.set('roadCallQueryParams', {
        filter: `eq(vehicleId,${vehicle.get('id')})`
      });
    },

    setMaxDate() {
      this.set('maxDate', TODAY);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveRoadCallRecord = this.get('onSaveRoadCallRecord');
      const onNewRoadCallRecord = this.get('onNewRoadCallRecord').bind(this);
      const onEditRoadCallRecord = this.get('onEditRoadCallRecord').bind(this);
      const onDeleteRoadCallRecord = this.get('onDeleteRoadCallRecord');
      const onUndoRoadCallRecord = this.get('onUndoRoadCallRecord').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveRoadCallRecord,
          newAction: onNewRoadCallRecord,
          deleteAction: onDeleteRoadCallRecord,
          editAction: onEditRoadCallRecord,
          undoAction: onUndoRoadCallRecord,
          onValidateAction,
          hasChanges,
          isChangesHappened: false
        });
      });
      this.addObserver('hasPendingChanges', this.hasPendingChangesObserver);
      this.set('newVehicleInput', false);
      this.get('refreshTask').perform().then(() => {
        Ember.run.later(() => {
          this.resetForm();
        }, 1000);
      });
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedVehicle = this.get('selectedVehicle');

      if (!selectedVehicle.get('isNew')) {
        yield selectedVehicle.reload();
      }

      const tableRef = this.get('tableRef');
      const activityLogTableRef = this.get('activityLogTableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      if (activityLogTableRef) Ember.run.scheduleOnce('afterRender', activityLogTableRef, 'refreshData');
    }),
    roadCallChangeSet: Ember.computed('selectedRoadCall', 'tableRef.records.[]', function () {
      const roadCall = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRoadCall'));
      return this.setDefaultProperties(roadCall);
    }),

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_ROAD_TYPE_MAP[event.get('actionType')];
    },

    hasPendingChanges: Ember.computed('roadCallChangeSet.changes.length', function () {
      const roadCallChangeSet = this.get('roadCallChangeSet');
      const changes = roadCallChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      const roadCallChangeSet = this.get('roadCallChangeSet');

      if (roadCallChangeSet.get('isNew')) {
        const pendingChanges = this.get('roadCallChangeSet');
        const fields = (0, _lodash.flatten)(pendingChanges.changes).map(change => change.key);
        const isEveyPrePoulatedFieldsChange = fields.length ? fields.every(field => field.includes('callDate')) : false;
        if (isEveyPrePoulatedFieldsChange) return !isEveyPrePoulatedFieldsChange;
        return !!this.get('hasPendingChanges');
      }

      return !!this.get('hasPendingChanges');
    },

    hasPendingChangesObserver() {
      const editAPI = this.get('editAPI');
      const hasPendingChanges = this.get('hasPendingChanges');
      Ember.run.scheduleOnce('afterRender', () => {
        editAPI.setProperties({
          isChangesHappened: hasPendingChanges
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
    },

    disableRouteButton: Ember.computed('roadCallChangeSet.{callDate}', function () {
      const callDate = this.get('roadCallChangeSet.callDate');

      if (Ember.isEmpty(callDate)) {
        return true;
      }
    }),
    disableNewRoadCallButton: Ember.computed('roadCallChangeSet.{newVehicleId}', function () {
      const vehicleId = this.get('roadCallChangeSet.newVehicleId');

      if (Ember.isEmpty(vehicleId)) {
        return true;
      }
    }),

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let roadCallRecord = record || this.get('pendingRoadCallRecord');
      const selectedVehicle = this.get('selectedVehicle');

      if (record) {
        this.resetForm();
      } else if (Ember.isEmpty(roadCallRecord)) {
        const store = this.get('store');
        roadCallRecord = store.createRecord('vehicle-road-call', {
          vehicle: selectedVehicle
        });
        this.set('pendingRoadCallRecord', roadCallRecord);
      }

      if (roadCallRecord.get('isNew')) {
        roadCallRecord.setProperties({
          callDate: TODAY,
          mileage: null,
          notes: null,
          roadCallCodeName: null,
          provider: null,
          route: null,
          driver: null
        });
      }

      this.set('showErrorMsg', false);
      this.set('oldCallDate', roadCallRecord.get('callDate'));
      return new _emberChangeset.default(roadCallRecord, (0, _emberChangesetValidations.default)(_roadCallValidation.VALIDATIONS), _roadCallValidation.VALIDATIONS);
    },

    setupDropDownValues() {
      const store = this.get('store');
      const dropDownModels = ['road-call-code-name'];
      dropDownModels.forEach(model => {
        const records = store.peekAll(model);
        this.set(model, records);
      });
    },

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        const records = this.get('tableRef.records');

        if (!records.length) {
          editAPI.buttonSettings(true, false, true, true, true);
        } else {
          editAPI.buttonSettings();
        }
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const roadCallChangeSet = this.get('roadCallChangeSet');
      yield roadCallChangeSet.validate();
      const errors = roadCallChangeSet.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveRoadCallRecord: (0, _emberConcurrency.task)(function* () {
      const roadCallChangeSet = this.get('roadCallChangeSet');
      const tooltip = this.get('tooltip');
      roadCallChangeSet.set('driverCdlNumber', roadCallChangeSet.get('driver.license.license')); // validation

      const isValid = yield this.validate.perform();

      if (isValid) {
        const notifications = this.get('notifications');
        const vehicleRoadCallRecord = (0, _unwrapProxy.unwrapProxy)(roadCallChangeSet);

        try {
          yield vehicleRoadCallRecord.save();
          notifications.success('Record successfully saved.');
          this.set('pendingRoadCallRecord', null);
          this.get('refreshTask').perform().then(() => {
            Ember.run.later(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
              this.resetForm();
              tooltip.reset();
            }, 1000);
          });
          return vehicleRoadCallRecord;
        } catch (e) {
          notifications.warning('Failed to Save Vehicle Road Call Record');
          console.warn(e); // eslint-disable-line
        }
      }
    }),

    onNewRoadCallRecord() {
      const selectedRoadCallRow = this.get('selectedRoadCallRow');
      const editAPI = this.get('editAPI');

      if (selectedRoadCallRow) {
        selectedRoadCallRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onDeleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRoadCall = this.get('selectedRoadCall');
      const notifications = this.get('notifications');

      try {
        yield selectedRoadCall.destroyRecord();
        notifications.success('Vehicle Road Call Record successfully deleted');
        this.get('refreshTask').perform().then(() => {
          Ember.run.later(() => {
            this.resetForm();
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
          }, 1000);
        });
      } catch (e) {
        notifications.warning('Vehicle Road Call Record failed to delete');
      }
    }),
    onDeleteRoadCallRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRoadCall = this.get('selectedRoadCall');
      const tooltip = this.get('tooltip');
      const selectedVehicle = this.get('selectedVehicle');
      if (!selectedRoadCall) return;
      yield tooltip.pushConfirmation({
        title: 'Vehicle Management',
        tip: `Are you sure you want to delete road call record for vehicle ${selectedVehicle.get('name')}?`,
        warning: true,
        hasOverlay: true,
        secondaryActionText: 'Cancel',
        secondaryAction: () => tooltip.reset(),
        primaryActionText: 'Confirm',
        primaryAction: () => {
          this.get('onDeleteRecord').perform();
          return tooltip.reset();
        }
      });
    }),

    onEditRoadCallRecord() {
      const editAPI = this.get('editAPI');
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onUndoRoadCallRecord() {
      const roadCallChangeSet = this.get('roadCallChangeSet');

      if (roadCallChangeSet) {
        roadCallChangeSet.rollback();
      }
    },

    async selectiveIdChange(roadCallChangeSet, route, path) {
      roadCallChangeSet.set(path, route);
    },

    buildRouteParam(callDate) {
      const shcedules = this.get('store').peekAll('schedule');
      const momentDate = (0, _moment.default)(callDate);
      const filteredSchedule = shcedules.find(schedule => (0, _workspaceAvailability.processTimeConstraints)(momentDate.startOf('day').toDate(), momentDate.endOf('day').toDate(), schedule.get('start'), schedule.get('end')));
      return `eq(scheduleId,${filteredSchedule ? filteredSchedule.get('id') : -1})`;
    },

    actions: {
      onSaveRoadCallRecord() {
        return this.onSaveRoadCallRecord.perform();
      },

      onPowerSelectChange(valuePath, selected) {
        this.set(valuePath, selected);
      },

      onInputValueChange(valuePath, value) {
        this.set(valuePath, value);
      },

      findNewVehicleRoadCall() {
        const notifications = this.get('notifications');
        const roadCallChangeSet = this.get('roadCallChangeSet');
        const newVehicleId = roadCallChangeSet.get('newVehicleId');
        const session = this.get('session');
        const self = this;
        const store = this.get('store');
        const refreshTask = this.get('refreshTask');
        const tooltip = this.get('tooltip');
        return this.get('ajax').post(`${_apiUrls.API.vehicleService.host}/vehicle?filter=eq(name,'${newVehicleId}')`, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        }).then(response => {
          if (response.data.length !== 0) {
            const editAPI = self.get('editAPI');
            const newVehicle = store.peekRecord('vehicle', response.data[0].id);
            const newVehicleName = newVehicle.get('name');
            const panelTitle = `Edit Vehicle ${newVehicleName}`;
            editAPI.set('title', panelTitle);
            editAPI.set('selectedRecord', (0, _unwrapProxy.unwrapProxy)(newVehicle));
            this.initializeQuery();
            refreshTask.perform().then(() => {
              Ember.run.later(() => {
                this.resetForm();
              }, 1000);
            });
          } else {
            tooltip.pushConfirmation({
              title: 'Manage Vehicle',
              tip: `Vehicle ${newVehicleId}  does not exist.`,
              primaryActionText: 'OK',
              primaryAction: () => {
                return tooltip.reset();
              }
            });
          }
        }).catch(function (e) {
          notifications.warning('Invalid Vehicle ID.');
          console.warn(e); // eslint-disable-line
        });
      },

      openRouteModal(roadCallDate) {
        const selectiveIdChange = this.get('selectiveIdChange').bind(this);
        const roadCallChangeSet = this.get('roadCallChangeSet');
        const callDate = roadCallChangeSet.get('callDate');
        const path = 'route';
        const queryParam = this.get('buildRouteParam').call(this, callDate);
        const routeIdObject = Ember.Object.create({
          title: 'Find Route',
          queryParams: {
            filter: queryParam
          },
          hasOverlay: true,
          config: _roadCallRouteSearchConfig.default,
          primaryActionText: 'Select',
          className: 'route-id',
          primaryAction: record => {
            selectiveIdChange(roadCallDate, record, path);
          }
        });
        this.set('modalAPI', routeIdObject);
      },

      openDriverModal(roadCall) {
        const selectiveIdChange = this.get('selectiveIdChange').bind(this);
        const path = 'driver';
        const routeIdObject = Ember.Object.create({
          title: 'Find Driver',
          hasOverlay: true,
          config: _roadCallDriverSearchConfig.default,
          primaryActionText: 'Select',
          className: 'driver-id',
          primaryAction: record => {
            selectiveIdChange(roadCall, record, path);
          }
        });
        this.set('modalAPI', routeIdObject);
      },

      onDateValueChange(valuePath, value) {
        this.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});