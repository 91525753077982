define("adept-iq/tests/factories/passenger-type", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  /* eslint-disable no-use-before-define */
  _emberDataFactoryGuy.default.define('passenger-type', {
    polymorphic: false,
    // manually flag this model as NOT polymorphic
    sequences: {
      types: num => passengerTypes[num % passengerTypes.length].type,
      names: num => passengerTypes[num % passengerTypes.length].name
    },
    default: {
      name: _emberDataFactoryGuy.default.generate('names'),
      type: _emberDataFactoryGuy.default.generate('types')
    },
    traits: {
      client: {
        name: 'client',
        type: 'configItem'
      }
    }
  });

  const passengerTypes = [
  /* Config Passenger Type Fixtures */
  {
    type: 'configItem',
    id: 'config-System_Configuration-passenger_types/client',
    name: 'client'
  }, {
    type: 'configItem',
    id: 'config-System_Configuration-passenger_types/companion',
    name: 'companion'
  }, {
    type: 'configItem',
    id: 'config-System_Configuration-passenger_types/pca',
    name: 'pca'
  }];
});