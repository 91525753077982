define("adept-iq/serializers/dwh-route", ["exports", "adept-iq/serializers/-dwh-dwhService"], function (_exports, _dwhDwhService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dwhDwhService.default.extend({
    modelName: 'dwh-route'
  });

  _exports.default = _default;
});