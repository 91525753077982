define("adept-iq/classes/rules/eligibility-rules/rider-distance-condition-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type", "lodash"], function (_exports, _baseRule, _ruleResultType, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RiderDistanceConditionRule = void 0;

  //check rider has distance conditions
  class RiderDistanceConditionRule extends _baseRule.default {
    constructor() {
      super();
    }

    doEvaluate(ruleContext) {
      const {
        bookingService
      } = ruleContext;
      const selectedRiders = bookingService.get('selectedRiders');
      const filteredRiders = selectedRiders.filter(rider => {
        const allConditions = (0, _lodash.flatten)(rider.get('eligibility.categories').map(category => category.conditions || []));
        return allConditions.length !== 0;
      });
      const riderHasDistanceConditions = filteredRiders.every(rider => {
        const allConditions = (0, _lodash.flatten)(rider.get('eligibility.categories').map(category => category.conditions || []));
        const distanceConditions = allConditions.filter(condition => condition.toUpperCase().includes('BLOCKS') || condition.toUpperCase().includes('STAIRS'));
        return distanceConditions.length > 0;
      });
      if (riderHasDistanceConditions) return _ruleResultType.RESULT_TYPE_YES;
      return _ruleResultType.RESULT_TYPE_NO;
    }

  }

  _exports.RiderDistanceConditionRule = RiderDistanceConditionRule;
  RiderDistanceConditionRule.RULE_NAME = 'RiderDistanceConditionRule';
});