define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/fuel-cap/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/fuel-cap/validation", "adept-iq/config/placeholders", "lodash", "adept-iq/utils/guid"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset, _emberChangesetValidations, _validation, _placeholders, _lodash, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['vehicle-lookup-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // placeholder
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    tableRef: null,
    description: null,
    editAPI: null,
    disableForm: true,
    showErrorMsg: false,
    currentDisplayName: null,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    vehicleFuelCapChangeSet: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),
    hasPendingChanges: Ember.computed('vehicleFuelCapChangeSet.changes.length', function () {
      const vehicleFuelCapChangeSet = this.get('vehicleFuelCapChangeSet');
      const changes = vehicleFuelCapChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      return this.get('hasPendingChanges');
    },

    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleFuelCapRecord = this.get('onSaveVehicleFuelCapRecord');
      const onNewVehicleFuelCapRecord = this.get('onNewVehicleFuelCapRecord').bind(this);
      const onEditVehicleFuelCapRecord = this.get('onEditVehicleFuelCapRecord').bind(this);
      const refreshTask = this.get('refreshTask');
      const onUndoVehicleFuelCapRecord = this.get('onUndoVehicleFuelCapRecord').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      const hasRelationship = this.get('hasRelationship').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleFuelCapRecord,
          newAction: onNewVehicleFuelCapRecord,
          editAction: onEditVehicleFuelCapRecord,
          undoAction: onUndoVehicleFuelCapRecord,
          deleteAction: null,
          onValidateAction,
          hasChanges,
          refreshTask,
          hasRelationship
        });
        refreshTask.perform();
      });
    },

    willDestroyElement() {
      const records = this.get('tableRef.records') || [];
      records.map(record => {
        if (record.get('tableRef')) {
          record.set('tableRef');
        }
      });

      this._super(...arguments);
    },

    hasRelationship() {
      const store = this.get('store');
      const tooltip = this.get('tooltip');
      const description = this.get('vehicleFuelCapChangeSet.description') || '';
      const vehicleTypes = store.peekAll('vehicle-type').filter(record => record.get('vehicleFuelCap.description') === description);

      if (vehicleTypes.length) {
        tooltip.pushConfirmation({
          title: 'Lookup',
          hasoverlay: true,
          tip: `There are ${vehicleTypes.length} active vehicle(s) with this Vehicle Fuel Cap description. You must either change the Vehicle Fuel Cap description on these vehicle(s) or delete these vehicle(s) prior to changing the Vehicle Fuel Cap description.`,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.reset();
          }

        });
      }

      return vehicleTypes.length;
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const tableRef = this.get('tableRef');
      let vehicleFuelCapObj = this.get('pendingRecord');

      if (record) {
        vehicleFuelCapObj = record;
        this.resetForm();
      }

      if (Ember.isEmpty(record)) {
        const data = {
          category: 'config-System_Configuration-vehicle_fuel_caps',
          type: 'object'
        };
        vehicleFuelCapObj = store.createRecord('cs-config-item', data);
        this.set('pendingRecord', vehicleFuelCapObj);
      }

      if (vehicleFuelCapObj.get('isNew')) {
        vehicleFuelCapObj.setProperties({
          description: null,
          tableRef: tableRef
        });
      }

      this.set('showErrorMsg', false);
      const tableRefRecords = this.get('tableRef.records') || [];
      tableRefRecords.map(tableRefRecord => {
        if (!tableRefRecord.get('tableRef')) {
          tableRefRecord.set('tableRef', tableRef);
        }
      });
      return new _emberChangeset.default(vehicleFuelCapObj, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('vehicleFuelCapChangeSet');

      if (selectedRecord) {
        if (!selectedRecord.get('isNew') && !selectedRecord.get('isDeleted')) {
          yield selectedRecord.data.reload();
        }
      }

      const tableRef = this.get('tableRef');
      this.resetForm();
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        editAPI.buttonSettings();
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const VehicleFuelCapRecord = this.get('vehicleFuelCapChangeSet');
      yield VehicleFuelCapRecord.validate();
      const errors = VehicleFuelCapRecord.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveVehicleFuelCapRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');
      const activityIdGuid = (0, _guid.guid)();
      if (!isValid) return;
      const notifications = this.get('notifications');
      const description = this.get('vehicleFuelCapChangeSet.description') || '';
      const value = {
        description: description
      };
      const nameGuidConverted = `${description}-${activityIdGuid}`;
      const name = (0, _lodash.camelCase)(nameGuidConverted);
      const vehicleFuelCapChangeSet = this.get('vehicleFuelCapChangeSet');
      let vehicleFuelCapRecord = this.get('vehicleFuelCapChangeSet');

      if (vehicleFuelCapRecord.get('isNew')) {
        vehicleFuelCapRecord = (0, _unwrapProxy.unwrapProxy)(this.get('vehicleFuelCapChangeSet'));
        vehicleFuelCapRecord.set('name', name);
      } else {
        const id = this.get('vehicleFuelCapChangeSet.id');
        const store = this.get('store');
        const record = `config-System_Configuration-vehicle_fuel_caps/${id}`;
        vehicleFuelCapRecord = store.peekRecord('cs-config-item', record);
      }

      vehicleFuelCapRecord.set('displayName', description);
      vehicleFuelCapRecord.set('description', description);
      vehicleFuelCapRecord.set('value', value);

      try {
        yield vehicleFuelCapRecord.save(); // changeset holds changes, execute the changes due to lookups using unorthodox saving practice
        // execute to change underlying object and rollback to remove internal changes list

        vehicleFuelCapChangeSet.execute();
        vehicleFuelCapChangeSet.rollback();
        notifications.success('Lookup record successfully saved.');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
            this.resetForm();
            tooltip.reset();
          });
        }, 500);
      } catch (response) {
        if (response.errors[0].status === '409') {
          tooltip.pushConfirmation({
            title: 'Manage Driver/Vehicle',
            tip: 'Description cannot duplicate an existing Description.',
            primaryActionText: 'OK',
            primaryAction: () => {
              return tooltip.reset();
            }
          });
        } else {
          notifications.warning('Lookup record failed to save.');
        }

        if (vehicleFuelCapRecord.rollbackAttributes) {
          vehicleFuelCapRecord.rollbackAttributes();
        }

        console.warn(response); // eslint-disable-line
      }
    }).drop(),

    onEditVehicleFuelCapRecord() {
      const editAPI = this.get('editAPI');
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onUndoVehicleFuelCapRecord() {
      const vehicleFuelCapChangeSet = this.get('vehicleFuelCapChangeSet');
      const editAPI = this.get('editAPI');

      if (vehicleFuelCapChangeSet) {
        vehicleFuelCapChangeSet.rollback();
      }

      this.set('disableForm', true);
      editAPI.buttonSettings(false, false, false, true, true);
    },

    onNewVehicleFuelCapRecord() {
      const editAPI = this.get('editAPI');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    actions: {
      onSaveVehicleFuelCapRecord() {
        return this.onSaveVehicleFuelCapRecord.perform();
      },

      onInputValueChange(record, valuePath, value) {
        this.set('currentDisplayName', value);
        record.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});