define("adept-iq/classes/polylines/stops/iq", ["exports", "adept-iq/classes/polylines/stops/base", "adept-iq/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    record: null,
    iqStop: Ember.computed.alias('record'),
    // `BasePolyline` overrides
    isActive: true,
    points: Ember.computed.readOnly('iqStop.currentPolyline.coords'),
    // `BaseStopPolyline` overrides
    stopStatus: Ember.computed.readOnly('stopPoint.status'),
    otp: Ember.computed.readOnly('iqStop.otp'),
    // local properties

    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    stopPoint: _environment.default.APP.avlmLite ? Ember.computed.readOnly('iqStop.avlmStopPoint') : Ember.computed.readOnly('iqStop.stopPoint')
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

  });

  _exports.default = _default;
});