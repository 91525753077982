define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel/searchByLocation", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/search-panel/addressPicker"], function (_exports, _react, _api, _panel, _addressPicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const SearchByLocation = () => {
    const {
      isRvdPreloaded
    } = (0, _api.useAPI)();
    const {
      setIsPinCursor,
      setLocation,
      radiusInFeet,
      setRadiusInFeet,
      radiusInMiles,
      setRadiusInMiles,
      radiusMode,
      setRadiusMode
    } = (0, _panel.usePanel)();

    const resetAll = () => {
      setLocation({});
      setRadiusInMiles(0);
      setRadiusInFeet(0);
    };

    _react.default.useEffect(() => {
      if (!isRvdPreloaded) {
        resetAll();
      }
    }, [isRvdPreloaded]);

    return _react.default.createElement("fieldset", {
      disabled: !isRvdPreloaded
    }, _react.default.createElement("div", {
      className: "form-panel-header"
    }, _react.default.createElement("span", {
      className: "form-panel-header-caret"
    }, _react.default.createElement("svg", {
      width: "11",
      height: "15",
      viewBox: "0 0 11 15",
      xmlns: "http://www.w3.org/2000/svg",
      className: "icon-block"
    }, _react.default.createElement("title", null, "Combined Shape"), _react.default.createElement("path", {
      d: "M5.5 0C8.538 0 11 2.223 11 5.928 11 9.634 6.009 15 5.5 15 4.991 15 0 10.29 0 5.928l.001-.158C.001 2.223 2.5 0 5.5 0zm0 3.75c-1.105 0-2 .84-2 1.875 0 1.036.895 1.875 2 1.875s2-.84 2-1.875c0-1.036-.895-1.875-2-1.875z",
      fill: "#263238",
      fillRule: "evenodd"
    }))), _react.default.createElement("h2", {
      className: "form-panel-title"
    }, "Select by Location")), _react.default.createElement("div", {
      className: "form-panel-body"
    }, _react.default.createElement("table", {
      className: "form-panel-form mb-0"
    }, _react.default.createElement("tbody", null, _react.default.createElement("tr", null, _react.default.createElement("td", {
      className: "td:first-child left"
    }, "Address"), _react.default.createElement("td", {
      className: "edit-cell"
    }, _react.default.createElement("div", {
      className: "d-flex align-items-center"
    }, _react.default.createElement(_addressPicker.default, null), _react.default.createElement("button", {
      onClick: () => setIsPinCursor(true),
      className: "btnbtn-outline-secondaryplaces-inline-button"
    }, _react.default.createElement("i", {
      className: "fa fa-map-marker",
      "aria-hidden": "true"
    })))), _react.default.createElement("td", {
      className: "button-cell"
    })), _react.default.createElement("tr", null, _react.default.createElement("td", {
      className: "vertical-align-top"
    }, "Radius"), _react.default.createElement("td", null, _react.default.createElement("div", {
      className: "radius"
    }, _react.default.createElement("input", {
      type: "radio",
      value: "miles",
      checked: radiusMode === _panel.radiusModes.miles,
      onChange: () => {
        setRadiusMode(_panel.radiusModes.miles);
        setRadiusInFeet(0);
      }
    }), _react.default.createElement("label", {
      htmlFor: "checkbox"
    }), _react.default.createElement("input", {
      placeholder: "0",
      type: "text",
      id: "miles",
      disabled: radiusMode === _panel.radiusModes.feet,
      onChange: e => {
        const r = e.target.value.replace(/\D/g, '');
        setRadiusInMiles(r);
      },
      value: radiusInMiles
    }), "miles"), _react.default.createElement("div", {
      className: "radius mt-10"
    }, _react.default.createElement("input", {
      type: "radio",
      value: "feet",
      checked: radiusMode === _panel.radiusModes.feet,
      onChange: () => {
        setRadiusMode(_panel.radiusModes.feet);
        setRadiusInMiles(0);
      }
    }), _react.default.createElement("label", {
      htmlFor: "checkbox"
    }), _react.default.createElement("input", {
      placeholder: "0",
      type: "text",
      id: "feet",
      disabled: radiusMode === _panel.radiusModes.miles,
      onChange: e => {
        const r = e.target.value.replace(/\D/g, '');
        setRadiusInFeet(r);
      },
      value: radiusInFeet
    }), "feet")))))));
  };

  var _default = SearchByLocation;
  _exports.default = _default;
});