define("adept-iq/serializers/etanav-route-break", ["exports", "adept-iq/serializers/-dispatch", "adept-iq/config/environment"], function (_exports, _dispatch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      return this._super(...arguments);
    },

    normalize(typeClass, hash) {
      const {
        routeBreak
      } = hash.relationships || {}; // Avoid 2.4.1 errors

      if (routeBreak && _environment.default.APP.avlmLite) {
        delete hash.relationships.routeBreak;
        hash.relationships.avlmRouteBreak = routeBreak;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});