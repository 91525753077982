define("adept-iq/classes/active-contexts/reconcile/graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dwhTripNode = _exports.dwhStopPointNode = _exports.dwhRouteNode = _exports.default = _exports.activeContextNodes = _exports.activeContextGraph = void 0;
  const dwhRouteNode = {
    id: 'dwh-route',
    modelName: 'dwh-route',
    links: [{
      type: 'hasMany',
      nodeId: 'dwh-trip',
      path: 'dwhTrips'
    }, {
      type: 'hasMany',
      nodeId: 'dwh-stop-point',
      path: 'dwhStops'
    }],
    providerFilterKey: false
  };
  _exports.dwhRouteNode = dwhRouteNode;
  const dwhTripNode = {
    id: 'dwh-trip',
    modelName: 'dwh-trip',
    links: [{
      type: 'hasMany',
      nodeId: 'dwh-stop-point',
      path: 'dwhStops'
    }],
    providerFilterKey: false
  };
  _exports.dwhTripNode = dwhTripNode;
  const dwhStopPointNode = {
    id: 'dwh-stop-point',
    modelName: 'dwh-stop-point',
    links: [{
      type: 'belongsTo',
      nodeId: 'dwh-route',
      path: 'dwhRoute'
    }, {
      type: 'belongsTo',
      nodeId: 'dwh-trip',
      path: 'dwhTrip'
    }],
    providerFilterKey: false
  };
  _exports.dwhStopPointNode = dwhStopPointNode;
  const activeContextNodes = [dwhRouteNode, dwhTripNode, dwhStopPointNode];
  _exports.activeContextNodes = activeContextNodes;
  const activeContextGraph = activeContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.activeContextGraph = activeContextGraph;
  var _default = activeContextGraph;
  _exports.default = _default;
});