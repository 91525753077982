define("adept-iq/pods/components/table/cells/date-cell/component", ["exports", "adept-iq/pods/components/table/cells/base-cell/component", "adept-iq/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['table-trip-eta-cell'],
    classNameBindings: ['row.otp'],
    format: _environment.default.dateTimeFormat.dateTimeMoment,
    dateFormat: Ember.computed('column.format', 'format', function () {
      const columnFormat = this.get('column.format');
      if (columnFormat) return columnFormat;
      return this.get('format');
    })
  });

  _exports.default = _default;
});