define("adept-iq/serializers/role-permission", ["exports", "adept-iq/serializers/-reconciliationService"], function (_exports, _reconciliationService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reconciliationService.default.extend({
    normalize(modelClass, resourceHash) {
      if (resourceHash.attributes) {
        if (!Ember.isNone(resourceHash.attributes.roleId)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.permSsoRole = {
            data: {
              type: 'perm-sso-role',
              id: resourceHash.attributes.roleId
            }
          };
          resourceHash.relationships = relationships;
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    pushPayloads(store, item) {
      if (!Ember.isEmpty(item)) {
        store.pushPayload({
          data: {
            id: item.id,
            type: 'role-permission',
            attributes: {
              permissionId: item.permissionId,
              roleId: item.roleId,
              category: item.Permission.category
            }
          }
        });
      }
    },

    normalizeQueryResponse(store, clazz, payload) {
      // workaround for this API not returns payload type
      const currentPayload = {
        data: []
      };
      const data = payload.length ? payload : [payload];

      for (let i = 0; i < data.length; i++) {
        currentPayload.data.push({
          id: data[i].id,
          type: 'role-permission',
          roleId: data[i].roleId,
          permissionId: data[i].permissionId
        });
        this.pushPayloads(store, data[i]);
      }

      return this._super(store, clazz, currentPayload);
    }

  });

  _exports.default = _default;
});