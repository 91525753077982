define("adept-iq/initializers/ember-data-factory-guy", ["exports", "ember-data-factory-guy/utils/manual-setup"], function (_exports, _manualSetup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ember-data-factory-guy',
    after: 'ember-data',
    initialize: function (application) {
      if (arguments.length > 1) {
        application = arguments[1];
      }

      let container = application.__container__;

      if (container) {
        // in fastboot container is null
        (0, _manualSetup.default)(container);
      }
    }
  };
  _exports.default = _default;
});