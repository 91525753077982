define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/violation/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALIDATION = void 0;
  const VALIDATION = {
    description: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Description',
      message: '{description} is required.'
    })],
    incidentDate: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Incident Date',
      message: '{description} is required.'
    })]
  };
  _exports.VALIDATION = VALIDATION;
});