define("adept-iq/pods/components/reconcile-search-passenger/component", ["exports", "ember-light-table", "adept-iq/pods/components/reconcile-search-passenger/config", "moment", "adept-iq/config/notifications"], function (_exports, _emberLightTable, _config, _moment, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_DEBOUNCE_TIME = 1200;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    classNames: ['reconcile-search-passenger'],
    config: _config.default,
    onCloseClick: null,
    isEmptySearchTable: false,
    contextMenuCallback: null,
    activePassenger: 'Active',
    inactivePassenger: 'Inactive',
    showOnlyActiveExternalPassenger: true,
    updateReconcileSearchedPassenger: Ember.observer('reconcileSearchedPassenger', function () {
      this.set('isEmptySearchTable', false);
      const searchedPassenger = Ember.get(this, 'reconcileSearchedPassenger');
      this.set('searchedPassenger', searchedPassenger);
      this.get('searchPassenger').setRows(searchedPassenger);

      if (Ember.get(this, 'searchPassenger').isEmpty) {
        this.set('isEmptySearchTable', true);
      }
    }),

    init() {
      this._super(...arguments);

      this.createSearchPassengerTable();
      Ember.set(this, 'isDisabledSearch', true);
      Ember.set(this, 'isDisabledAddNewTrip', true);
      Ember.set(this, 'showOnlyActiveExternalPassenger', true);
    },

    createSearchPassengerTable() {
      const searchPassengerTableColumn = this.config.searchPassengerColumns;
      searchPassengerTableColumn.forEach(column => {
        if (['date', 'datetime', 'time', 'datetimeflatpickr'].includes(column.dataType) && column.format && typeof column.format === 'string') {
          const formatDate = column.format;

          column.format = value => {
            return Ember.isEmpty(value) ? value : (0, _moment.default)(value).format(formatDate);
          };
        }
      });
      const searchPassenger = new _emberLightTable.default(searchPassengerTableColumn);
      this.set('searchPassenger', searchPassenger);
    },

    didInsertElement() {},

    createContextMenuOptions() {},

    willDestroyElement() {
      this._super(...arguments);
    },

    onSearchPassenger: async function () {
      const passengerId = this.get('passengerId');
      const passengerFirstName = this.get('firstName');
      const passengerLastName = this.get('lastName');
      const passengerPhoneNumber = null;
      const isShowActiveRider = this.get('showOnlyActiveExternalPassenger');

      try {
        let riders;

        if (!Ember.isBlank(passengerId)) {
          this.get('workspace').set('isGlobalSpinnerVisible', true);
          riders = await this.store.find('rider-external', passengerId);
          this.get('workspace').set('isGlobalSpinnerVisible', false);
        }

        const params = {
          firstName: passengerFirstName ? passengerFirstName : '',
          lastName: passengerLastName ? passengerLastName : '',
          phoneNumber: passengerPhoneNumber,
          activeOnly: isShowActiveRider
        };

        if (passengerFirstName || passengerLastName) {
          this.get('workspace').set('isGlobalSpinnerVisible', true);
          riders = await this.store.query('rider-external', params);
          this.get('workspace').set('isGlobalSpinnerVisible', false);
        }

        if (Ember.isBlank(riders) || Ember.isEmpty(riders)) {
          this.set('reconcileSearchedPassenger', []);
          this.get('notifications').info(_notifications.default.BOOKING.NO_MATCHES);
          return;
        }

        if (this.get('booking.hasMoreExternalRiders')) {
          this.get('notifications').warning('Too many matching results were found. Displaying the first 300 results. Please refine the search parameters');
          this.set('reconcileSearchedPassenger', riders.slice(0, 300));
          return;
        }

        riders = riders.length ? riders : [riders];
        this.set('reconcileSearchedPassenger', riders);
      } catch (e) {
        this.set('reconcileSearchedPassenger', []);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    buildPassengerDetail(res) {
      return {
        passengerId: res.id,
        firstName: res.firstName,
        lastName: res.lastName,
        phoneNumber: res.formattedPhoneNumber,
        address: res.places,
        travelNeeds: res.travelNeeds,
        serviceNeeds: res.serviceNeeds,
        loadTime: res.loadTime,
        UnloadTime: res.unloadTime,
        middleName: res.middleName,
        fullName: res.fullName
      };
    },

    actions: {
      onCloseButtonClick() {
        this.onCloseClick();
      },

      onUndoButtonClick() {},

      onSearchTextChange(type, valuePath) {
        Ember.set(this, 'isDisableNamesField', false);
        Ember.set(this, 'isDisablePassField', false);
        Ember.set(this, 'isDisabledSearch', false);

        if (!valuePath) {
          this.get('searchPassenger').setRows([]);
          Ember.set(this, 'selectedRow', null);
          return;
        }

        switch (type) {
          case _config.default.PASSENGER_ID:
            Ember.set(this, 'passengerId', valuePath.trim());

            if (valuePath) {
              Ember.set(this, 'isDisableNamesField', true);
              this.send('onPassengerSearch');
            }

            break;

          case _config.default.FIRST_NAME:
            Ember.set(this, 'firstName', valuePath.trim());

            if (valuePath) {
              Ember.set(this, 'passengerId', '');
              Ember.set(this, 'isDisablePassField', true);
              this.send('onPassengerSearch');
            }

            break;

          case _config.default.LAST_NAME:
            Ember.set(this, 'lastName', valuePath.trim());

            if (valuePath) {
              Ember.set(this, 'passengerId', '');
              Ember.set(this, 'isDisablePassField', true);
              this.send('onPassengerSearch');
            }

            break;

          default:
        }
      },

      async onRowClick(row) {
        const passengerFirstName = this.get('firstName');
        const passengerLastName = this.get('lastName');

        if (passengerFirstName || passengerLastName) {
          const riderById = await this.store.queryRecord('rider-external', row.get('id'));
          const selectedRow = this.buildPassengerDetail(riderById);
          Ember.set(this, 'selectedRow', selectedRow);
        } else {
          const selectedRow = this.buildPassengerDetail(row.content);
          Ember.set(this, 'selectedRow', selectedRow);
        }

        Ember.set(this, 'isDisabledAddNewTrip', false);
      },

      onAddNewTrip() {
        const selectedRow = this.get('selectedRow');

        if (selectedRow) {
          this.get('workspace').pushState('reconcileAddNewTrip', {
            selectedRow,
            action: this.contextMenuCallback
          });
          this.onCloseClick();
        }
      },

      resetSearch() {
        Ember.set(this, 'firstName', '');
        Ember.set(this, 'lastName', '');
        Ember.set(this, 'passengerId', '');
        this.get('searchPassenger').setRows([]);
        Ember.set(this, 'selectedRow', null);
        Ember.set(this, 'isDisableNamesField', false);
        Ember.set(this, 'isDisablePassField', false);
        Ember.set(this, 'showOnlyActiveExternalPassenger', true);
        Ember.set(this, 'isEmptySearchTable', false);
      },

      onCheckboxToggle(isChecked) {
        Ember.set(this, 'selectedRow', null);
        this.set('showOnlyActiveExternalPassenger', isChecked);
        const firstName = Ember.get(this, 'firstName');
        const lastName = Ember.get(this, 'lastName');

        if (firstName) {
          this.send('onSearchTextChange', _config.default.FIRST_NAME, firstName);
        }

        if (lastName) {
          this.send('onSearchTextChange', _config.default.LAST_NAME, lastName);
        }
      },

      onPassengerSearch() {
        Ember.run.debounce(this, 'onSearchPassenger', DEFAULT_DEBOUNCE_TIME);
      }

    }
  });

  _exports.default = _default;
});