define("adept-iq/pods/components/iq-widgets/permissions-users-widget/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const cellComponent = 'generic-components/table/cells/check-box';
  const columnComponent = 'generic-components/table/columns/check-box';
  const rowComponent = 'table/rows/action-row';
  var _default = {
    component: 'iq-widgets/users-widget',
    cellComponent,
    rowComponent,
    columnComponent,
    modelName: 'perm-sso-user',
    title: 'Users',
    columns: [{
      id: 'firstName',
      type: 'text',
      label: 'First Name',
      valuePath: 'firstName',
      searchable: true,
      resizable: true,
      hidden: false,
      minResizeWidth: 105,
      width: '105px'
    }, {
      id: 'lastName',
      type: 'text',
      label: 'Last Name',
      valuePath: 'lastName',
      searchable: true,
      resizable: true,
      hidden: false,
      minResizeWidth: 105,
      width: '105px'
    }, {
      id: 'calculatedStatus',
      type: 'text',
      label: 'Status',
      valuePath: 'calculatedStatus',
      searchable: true,
      resizable: true,
      hidden: false,
      minResizeWidth: 85,
      width: '85px',
      format: function (valuePath) {
        return valuePath.charAt(0).toUpperCase() + valuePath.substr(1);
      }
    }, {
      id: 'roleNamesStr',
      type: 'text',
      label: 'Role',
      valuePath: 'roleNamesStr',
      searchable: true,
      resizable: true,
      hidden: false,
      minResizeWidth: 170,
      width: '170px'
    }, {
      id: 'passwordChangedDate',
      type: 'date',
      label: 'Password Last Changed',
      valuePath: 'passwordChangedDate',
      searchable: true,
      resizable: true,
      hidden: false,
      minResizeWidth: 120,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      width: '120px'
    }, {
      id: 'userName',
      type: 'text',
      label: 'Username',
      valuePath: 'userName',
      searchable: true,
      resizable: true,
      hidden: false,
      minResizeWidth: 150,
      width: '150px'
    }, {
      id: 'providerNamesStr',
      type: 'text',
      label: 'Provider',
      valuePath: 'providerNamesStr',
      searchable: true,
      resizable: true,
      hidden: false,
      minResizeWidth: 90,
      width: '90px'
    }]
  };
  _exports.default = _default;
});