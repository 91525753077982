define("adept-iq/serializers/lock", ["exports", "adept-iq/serializers/-dispatch"], function (_exports, _dispatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    attrs: {
      dispatchRoute: {
        serialize: false
      },
      status: {
        serialize: false
      },
      owner: {
        serialize: false
      },
      expirationTime: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      return json;
    },

    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});