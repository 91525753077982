define("adept-iq/tests/factories/fare-type", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const fareTypes = [
  /* Config Elgibility Type Fixtures */
  {
    name: 'credit'
  }, {
    name: 'cash'
  }, {
    name: 'bits'
  }];

  _emberDataFactoryGuy.default.define('fare-type', {
    sequences: {
      names: num => fareTypes[num % fareTypes.length].name
    },
    default: {
      name: _emberDataFactoryGuy.default.generate('names')
    }
  });
});