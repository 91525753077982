define("adept-iq/tests/factories/subscription-group", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('subscription-group', {
    sequences: {
      groupIds: num => num
    },
    default: {
      groupId: _emberDataFactoryGuy.default.generate('groupIds'),
      description: 'It\'s a group of Arthur Rojas ahhhhh!!!!!!!'
    }
  });
});