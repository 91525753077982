define("adept-iq/tests/factories/sso-role", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('sso-role', {
    sequences: {
      ids: num => num
    },
    default: {
      roleName: 'user',
      name: 'user',
      status: 'active',
      id: _emberDataFactoryGuy.default.generate('ids')
    },
    traits: {
      admin: {
        roleName: 'admin',
        name: 'Admin',
        displayName: 'Admin'
      },
      user: {
        roleName: 'user',
        name: 'User',
        displayName: 'User'
      }
    }
  });
});