define("adept-iq/pods/components/timeline-picker/time-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    classNames: ['bar', 'data-test-bar'],

    /* one 24-hour day */
    numIncrements: 24,
    startDateAbbrev: Ember.computed('startDate', function () {
      return this.get('startDate').toDateString();
    }),

    didInsertElement() {
      this._super(...arguments);

      const parentEl = this.$(); // To keep the zoomPercent related to the original size.

      this.set('baseLineWidth', parentEl.width());
      parentEl.css('width', `${parentEl.width() * this.get('zoomPercent') / 100}px`);
      this.set('parentWidth', parentEl.width());
      this.resetAllElements();
    },

    setDates(startDate, endDate) {
      Ember.run.scheduleOnce('afterRender', this, 'onDatesChange', startDate, endDate);
    },

    resetAllElements() {
      this.insertTimeScale();
      this.insertTimeIncrements();
      this.setLeftHandle();
      this.setRightHandle();
      this.setNowMarker();
      this.insertOperatingBox();
    },

    insertTimeScale() {
      const rowEl = this.$('#time-scale-row'); // Clear out the old cells before we add new ones.

      rowEl.empty();
      const numIncrements = this.get('numIncrements');
      const cellWidth = Math.floor(this.get('parentWidth') / numIncrements);
      const startEndCell = `<td style="width:${cellWidth / 2}px"></td>`;
      rowEl.append(startEndCell);

      for (let i = 1; i < numIncrements; i++) {
        const cell = `<td class="time-scale-cell" style="width:${cellWidth}px">${i}:00</td>`;
        rowEl.append(cell);
      }

      rowEl.append(startEndCell);
    },

    insertTimeIncrements() {
      const rowEl = this.$('#increment-row');
      rowEl.empty();
      const numIncrements = this.get('numIncrements');
      const cellWidth = Math.floor(this.get('parentWidth') / numIncrements);
      const cell = `<td class="time-bar-increment" style="width:${cellWidth}px"></td>`;

      for (let i = 0; i < numIncrements; i++) {
        rowEl.append(cell);
      }
    },

    setLeftHandle() {
      const leftHandle = this.$('.time-bar-handle-left');
      const startDate = this.get('startDate');
      const decimalTime = startDate.getHours() + startDate.getMinutes() / 60;
      const leftPosition = Math.floor(this.get('parentWidth') / 24 * decimalTime);
      leftHandle.css('left', `${leftPosition}px`);
    },

    setRightHandle() {
      const rightHandle = this.$('.time-bar-handle-right');
      const offset = rightHandle.width();
      const endDate = this.get('endDate');
      const decimalTime = endDate.getHours() + endDate.getMinutes() / 60;
      const rightPosition = Math.floor(this.get('parentWidth') / 24 * decimalTime) - offset;
      rightHandle.css('left', `${rightPosition}px`);
    },

    setNowMarker() {
      const newMarker = this.$('.time-bar-now-marker'); // Arbitrarily picking 12:00PM,

      const leftPosition = Math.floor(this.get('parentWidth') / 24 * 12);
      newMarker.css('left', `${leftPosition}px`);
    },

    insertOperatingBox() {
      const operatingBox = this.$('.time-bar-operating-box'); // The agency begins operations at 9:00am.

      const leftPosition = Math.floor(this.get('parentWidth') / 24 * 9); // THe agency operates 8 hours / day.

      const boxWidth = Math.floor(this.get('parentWidth') / 24 * 8);
      operatingBox.css('left', `${leftPosition}px`);
      operatingBox.css('width', `${boxWidth}px`);
    },

    startDateObserver: Ember.observer('startDate', function () {
      this.setLeftHandle();
    }),
    endDateObserver: Ember.observer('endDate', function () {
      this.setRightHandle();
    }),
    zoomObserver: Ember.observer('zoomPercent', function () {
      const parentEl = this.$();
      parentEl.css('width', `${this.get('baseLineWidth') * this.get('zoomPercent') / 100}px`);
      this.set('parentWidth', parentEl.width());
      this.resetAllElements();
    }),

    dragEnd(event) {
      const target = this.$(`#${event.originalEvent.target.id}`);
      const startingPosition = parseInt(target.css('left').replace(/px/, ''), 10);
      const newPosition = startingPosition + event.originalEvent.offsetX;

      switch (event.originalEvent.target.id) {
        case 'left-handle':
          {
            // Gives a fractional hour.
            const startDate = new Date(this.get('startDate').getTime());
            const newTime = this.convertTime(newPosition / (this.get('parentWidth') / 24));
            startDate.setHours(newTime.hours, newTime.minutes, 0);
            this.setDates(startDate, this.get('endDate'));
            break;
          }

        case 'right-handle':
          {
            const endDate = new Date(this.get('endDate').getTime());
            const newTime = this.convertTime(newPosition / (this.get('parentWidth') / 24));
            endDate.setHours(newTime.hours, newTime.minutes, 0);
            this.setDates(this.get('startDate'), endDate);
            break;
          }

        case 'now-marker':
          {
            target.css('left', `${newPosition}px`);
            break;
          }

        default:
          break;
      }
    },

    convertTime(decimal) {
      const hours = Math.floor(decimal / 24);
      const minutes = Math.floor(decimal * 60);
      return {
        hours: hours,
        minutes: minutes
      };
    }

  });

  _exports.default = _default;
});