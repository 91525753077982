define("adept-iq/pods/components/side-drawer/reconcile-rescue-route/component", ["exports", "moment", "ember-light-table", "ember-concurrency", "adept-iq/pods/components/side-drawer/reconcile-rescue-route/config"], function (_exports, _moment, _emberLightTable, _emberConcurrency, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    reconcile: Ember.inject.service(),
    reconcileLambda: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    classNames: ['reconcile-widget-base'],
    routeId: null,
    onCloseClick: null,
    timeFormat: 'm-d-Y h:i K',
    title: 'Rescue Route',
    lateTrips: Ember.computed.alias('reconcile.reconcileLateTrips'),
    oldRouteId: Ember.computed.readOnly('reconcile.reconcileSelectedRouteId'),
    callBack: Ember.computed.readOnly('stackFrame.options.action'),
    reconcileTripDate: Ember.computed.readOnly('reconcile.reconcileDate'),
    reconcileRouteInfo: Ember.computed.readOnly('reconcile.reconcileRouteInfo'),
    reconcileProviderName: Ember.computed.alias('reconcile.reconcileProviderName'),
    reconcileMode: Ember.computed.alias('reconcile.reconcileMode'),
    overrideVehicleOptions: Ember.computed.alias('reconcile.reconcileVehicles'),
    overrideDriverOptions: Ember.computed.alias('reconcile.reconcileDrivers'),
    canUndo: Ember.computed.or('routeId', 'selectedDateTimeRage.{starttime,endtime}', 'overrideSelectedDriver', 'overrideSelectedVehicle'),
    canSave: Ember.computed.and('routeId', 'selectedDateTimeRage.{starttime,endtime}', 'overrideSelectedDriver', 'overrideSelectedVehicle'),
    selectedTrips: Ember.computed.alias('tripsTable.selectedRows'),

    init() {
      this._super(...arguments);

      const table = new _emberLightTable.default(_config.tripColumns);
      this.set('tripsTable', table);

      if (this.lateTrips) {
        this.lateTrips.map(item => {
          item.isChecked = false;
        });
      }

      this.get('tripsTable').setRows(this.lateTrips);
      Ember.set(this, 'selectedDateTimeRage', {
        starttime: null,
        endtime: null,
        startforendtime: (0, _moment.default)(this.reconcileTripDate).startOf('day').toISOString(),
        startofday: (0, _moment.default)(this.reconcileTripDate).startOf('day').toISOString(),
        endofday: (0, _moment.default)(this.reconcileTripDate).endOf('day').toISOString()
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.setSideDrawerWidth('600px');
      this.get('systemConfig').set('showRightPanel', true);
    },

    saveRescueRouteTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const reconcileRescueRouteAdapter = store.adapterFor('reconcile-route');
      const vehicleData = this.get('overrideSelectedVehicle');
      const driverData = this.get('overrideSelectedDriver');
      const trips = this.get('lateTrips');
      const starttime = this.get('selectedDateTimeRage').starttime;
      const endTime = this.get('selectedDateTimeRage').endtime;
      const newRouteId = this.get('routeId');
      const tripData = [];

      if (trips) {
        trips.map(items => {
          if (items && items.isChecked) {
            tripData.push(items.tripId);
          }
        });
      }

      const payload = {
        id: newRouteId,
        type: 'Rescue Replacement',
        serviceMode: this.reconcileMode,
        plannedStartTime: starttime,
        plannedEndTime: endTime,
        providerName: this.reconcileProviderName,
        userId: this.session.data.authenticated.userId,
        vehicle: {
          id: vehicleData.vehicleId
        },
        driver: {
          id: driverData.driverId
        },
        trips: tripData
      };

      try {
        return yield reconcileRescueRouteAdapter.postRoute(payload, payload.type, this.get('oldRouteId'));
      } catch (e) {
        throw e;
      }
    }),

    showToolTip() {
      const rows = this.get('tripsTable.rows');
      const tripData = [];

      if (rows) {
        rows.map(data => {
          if (data && data.get('tripId') && data.get('isChecked')) {
            tripData.push(data);
          }
        });
      }

      const tooltip = this.get('tooltip');
      const title = 'Rescue Route';
      const tip = 'You are rescuing ' + tripData.length + ` late trips from Route ${this.get('oldRouteId')} to new Route ${this.get('routeId')}.`;
      const successMsg = 'Successfully transferred ' + tripData.length + ` trips to new route ${this.get('routeId')}`;
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('saveRescueRouteTask').perform().then(() => {
            this.send('cancel');
            this.get('workspace').popState('rescueRouteReconcile');
            tooltip.reset();
            this.get('notifications').success(successMsg);
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              case 409:
                message = `The route name ${this.get('routeId')} is already taken, please try another.`;
                break;

              default:
                message = 'There was a problem creating the route, please try again.';
                break;
            }

            this.set('errorMessage', message);
            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    unloadPendingTrips() {
      const tripsRow = this.get('selectedTrips');

      if (!Ember.isEmpty(tripsRow)) {
        tripsRow.map(tripRow => {
          const tripData = tripRow.get('content');
          const stops = Ember.get(this, 'store').peekAll('reconcile-stop').filter(stop => {
            return stop.tripId === tripData.tripId;
          });

          if (stops.length) {
            stops.map(stop => {
              this.store.unloadRecord(stop);
            });
            const tripRecord = Ember.get(this, 'store').peekRecord('reconcile-trip', tripData.tripId);

            if (!Ember.isEmpty(tripRecord)) {
              this.store.unloadRecord(tripRecord);
            }
          }
        });
        Ember.set(this, 'reconcile.reconcileSelectedTripId', null);
      }
    },

    computeFullName(rvd) {
      const driver = rvd.driver;
      return `${driver.firstname} ${driver.lastname}`;
    },

    actions: {
      onUndoClick() {
        const rows = this.get('tripsTable.rows');

        if (rows) {
          rows.map(data => {
            if (data) {
              data.set('isChecked', false);
            }
          });
        }

        this.get('tripsTable').setRows(rows);
        this.setProperties({
          routeId: null,
          'selectedDateTimeRage.starttime': null,
          'selectedDateTimeRage.endtime': null,
          trip: null,
          overrideSelectedDriver: null,
          overrideSelectedVehicle: null,
          driverStartTime: null,
          driverEndTime: null
        });
      },

      async onSaveClick() {
        if (Ember.isEmpty(this.selectedTrips)) {
          Ember.set(this, 'errorMessage', 'Please select at least one trip');
          return;
        }

        Ember.set(this, 'errorMessage', null);

        if (this.get('routeId')) {
          this.showToolTip();
        }
      },

      onChangeDriver(driver) {
        this.set('overrideSelectedDriver', driver);
        const reconcileRVD = this.get('reconcile.reconcileRVDResponse');
        const foundRVD = reconcileRVD.find(rvd => rvd.driver.id === driver.id);
        const fullName = this.computeFullName(foundRVD);
        const plannedStartTime = (0, _moment.default)(foundRVD.route.plannedstarttime).format('hh:mm A');
        const plannedEndTime = (0, _moment.default)(foundRVD.route.plannedendtime).format('hh:mm A');
        this.set('driverStartTime', plannedStartTime);
        this.set('driverEndTime', plannedEndTime);
        this.set('overrideSelectedDriver.fullName', fullName);
      },

      onChangeVehicle(vehicle) {
        this.set('overrideSelectedVehicle', vehicle);
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
      },

      cancel() {
        this.unloadPendingTrips();

        if (this.callBack) {
          this.callBack(true);
        }
      }

    }
  });

  _exports.default = _default;
});