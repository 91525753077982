define("adept-iq/validators/validate-duplicate-field", ["exports", "ember-changeset-validations/utils/validation-errors"], function (_exports, _validationErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateDuplicateField = validateDuplicateField;

  function validateDuplicateField() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return (key, value, oldValue, changes, content) => {
      const {
        message
      } = options;
      const description = value ? value : '';
      const id = content.get('id');
      const tableRef = content.get('tableRef.records') || []; //vallidation for duplicate description

      const lookupRecord = tableRef.filter(record => {
        const previousDescription = record.get(key) ? record.get(key) : '';
        return previousDescription.toLowerCase() === description.toLowerCase();
      }).find(rec => !id || rec.get('id') !== id);

      if (lookupRecord) {
        return (0, _validationErrors.default)(key, {
          context: {
            value,
            message
          }
        });
      }

      return true;
    };
  }
});