define("adept-iq/models/driver-19-a-review", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    examDate: attr('date'),
    provider: belongsTo('provider'),
    providerName: Ember.computed.readOnly('provider.name'),
    driver: belongsTo('driver'),
    examTypeName: belongsTo('exam-type-name')
  });

  _exports.default = _default;
});