define("adept-iq/pods/components/iq-widgets/routes-widget/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/routes-widget',
    rowComponent: 'iq-widgets/routes-widget/routes-row',
    modelName: 'dispatch-route',
    title: 'Routes',
    defaultSortId: 'name',
    defaultSortAsc: true,
    // ember-react-widget properties
    widgetName: 'routeWidget',
    selectedRowLimit: 5,
    maxLimitByRouteSelection: 5,
    columns: [{
      id: 'name',
      type: 'uuid',
      // adept 4 is no longer part of the system, route's will include characters
      label: 'Name',
      valuePath: 'name',
      valuePreview: '22',
      mapValuePath: 'route.name',
      editable: true,
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'vehicle',
      type: 'uuid',
      label: 'Vehicle ID',
      valuePath: 'vehicleName',
      mapValuePath: 'assignedVehicle.name',
      searchable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'driverId',
      type: 'uuid',
      label: 'Driver ID',
      valuePath: 'driverId',
      mapValuePath: 'assignedDriver.driverId',
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'driverLastName',
      type: 'text',
      label: 'Driver Last Name',
      valuePath: 'driverLastName',
      mapValuePath: 'assignedDriver.lastName',
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'driverFirstName',
      type: 'text',
      label: 'Driver First Name',
      valuePath: 'driverFirstName',
      mapValuePath: 'assignedDriver.firstName',
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'driverPhoneNumber',
      type: 'text',
      label: 'Driver Phone Number',
      valuePath: 'rvdDriverPhoneNumber',
      mapValuePath: 'assignedDriverPhoneNumber',
      searchable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'status',
      type: 'text',
      label: 'Status',
      valuePath: 'routeStatus',
      valuePreview: 'Planned',
      mapValuePath: 'computedStatus',
      editable: true,
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 75
    }, {
      id: 'plannedStartTime',
      type: 'time',
      label: 'Planned Start Time',
      valuePath: 'plannedStartDateTime',
      mapValuePath: 'plannedStartTime',
      valuePreview: '09:00',
      editable: true,
      hidden: true,
      format: 'HH:mm',
      searchable: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'actualStartTime',
      type: 'time',
      label: 'Actual Start Time',
      valuePath: 'actualStartDateTime',
      mapValuePath: 'route.actualStartTimestamp',
      valuePreview: '09:05',
      hidden: true,
      searchable: true,
      format: 'HH:mm',
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'plannedBreaks',
      type: 'number',
      label: 'Planned Breaks',
      valuePath: 'plannedBreaksCount',
      //need to handle json
      mapValuePath: 'plannedDriverBreaks',
      cellDesc: 'Driver Breaks',
      hidden: true
    }, {
      id: 'vehicleType',
      type: 'text',
      label: 'Vehicle Type',
      valuePath: 'vehicleTypeNameToDisplay',
      mapValuePath: 'assignedVehicle.vehicleType.name',
      searchable: true,
      hidden: true
    }, {
      id: 'plannedEndTime',
      type: 'time',
      label: 'Planned End Time',
      valuePath: 'plannedEndDateTime',
      mapValuePath: 'plannedEndTime',
      valuePreview: '04:00',
      editable: true,
      format: 'HH:mm',
      searchable: true,
      hidden: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'actualEndTime',
      type: 'time',
      label: 'Actual End Time',
      valuePath: 'actualEndDateTime',
      mapValuePath: 'route.actualEndTimestamp',
      valuePreview: '04:35',
      format: 'HH:mm',
      searchable: true,
      hidden: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'plannedStartDate',
      type: 'date',
      label: 'Planned Start Date',
      valuePath: 'plannedStartDateTime',
      mapValuePath: 'plannedStartTime',
      valuePreview: '2018-05-15',
      editable: true,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      hidden: true,
      cellItem: 'DateLocalCellItem'
    }, {
      id: 'actualStartDate',
      type: 'date',
      label: 'Actual Start Date',
      valuePath: 'actualStartDateTime',
      mapValuePath: 'route.actualStartTimestamp',
      valuePreview: '2018-05-15',
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      hidden: true,
      cellItem: 'DateLocalCellItem'
    }, {
      id: 'plannedEndDate',
      type: 'date',
      label: 'Planned End Date',
      valuePath: 'plannedEndDateTime',
      mapValuePath: 'plannedEndTime',
      valuePreview: '2018-05-15',
      editable: true,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      hidden: true,
      cellItem: 'DateLocalCellItem'
    }, {
      id: 'actualEndDate',
      type: 'date',
      label: 'Actual End Date',
      valuePath: 'actualEndDateTime',
      mapValuePath: 'route.actualEndTimestamp',
      valuePreview: '2018-05-15',
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      hidden: true,
      cellItem: 'DateLocalCellItem'
    }, {
      id: 'otpStatus',
      type: 'string',
      label: 'OTP Status',
      valuePath: 'otpStatus',
      mapValuePath: 'route.otpLabel',
      valuePreview: 'On Time',
      searchable: true,
      highlightable: true,
      cellItem: 'OTPValueCellItem'
    }, {
      id: 'otp',
      type: 'number',
      label: 'OTP',
      valuePath: 'otpWithSign',
      mapValuePath: 'route.otpValueWithSign',
      customSortPath: 'otp',
      hidden: true,
      highlightable: true
    }, {
      id: 'provider',
      type: 'string',
      label: 'Provider',
      valuePath: 'provider',
      mapValuePath: 'provider.name',
      valuePreview: 'MAG',
      hidden: true,
      searchable: true,
      highlightable: false
    }, {
      id: 'routeType',
      type: 'string',
      label: 'Type',
      valuePath: 'type',
      mapValuePath: 'route.type',
      hidden: true,
      searchable: true,
      highlightable: false
    }, {
      id: 'breakdownTime',
      type: 'date',
      format: `${_environment.default.dateTimeFormat.dateTimeMoment}`,
      label: 'Breakdown Time',
      valuePath: 'breakdownTime',
      mapValuePath: 'assignedVehicle.vehicleBreakdowns.firstObject.time',
      hidden: true,
      searchable: false,
      highlightable: false,
      cellItem: 'DateLocalCellItem'
    }, {
      id: 'breakdownLocation',
      type: 'text',
      label: 'Breakdown Location',
      valuePath: 'breakdownLocation',
      mapValuePath: 'assignedVehicle.vehicleBreakdowns.firstObject.place.address.fullAddress',
      hidden: true,
      searchable: false,
      highlightable: false
    }, {
      id: 'breakdownReason',
      type: 'text',
      label: 'Breakdown Reason',
      valuePath: 'breakdownReason',
      mapValuePath: 'assignedVehicle.vehicleBreakdowns.firstObject.type.displayName',
      hidden: true,
      searchable: false,
      highlightable: false
    }, {
      id: 'mode',
      type: 'text',
      label: 'Scheduling Mode',
      valuePath: 'mode',
      mapValuePath: 'route.schedulingMode',
      hidden: true,
      searchable: false,
      highlightable: false
    }, {
      id: 'replacedRoute',
      type: 'uuid',
      label: 'Replaced Route Name',
      valuePath: 'replacedRouteName',
      mapValuePath: 'route.replacedRoute.name',
      hidden: true,
      searchable: true,
      defaultWidth: 50
    }],
    traversal: {
      tripWidget: {
        links: [{
          type: 'stringEq',
          field: 'id',
          valuePath: 'routeId'
        }]
      },
      vehicleWidget: {
        links: [{
          type: 'stringEq',
          field: 'vehicleId',
          valuePath: 'id'
        }]
      },
      alertWidget: {
        links: [{
          type: 'eq',
          field: 'vehicleId',
          valuePath: 'vehicleId'
        }]
      },
      stopWidget: {
        links: [{
          type: 'stringEq',
          field: 'id',
          valuePath: 'routeId'
        }]
      },
      messageWidget: {
        links: [{
          type: 'eq',
          field: 'vehicleId',
          valuePath: 'vehicleId'
        }]
      },
      riderWidget: {
        links: [{
          type: 'inObjKeys',
          valuePath: 'routes'
        }]
      }
    },
    implicit: {
      conditions: [{
        type: 'neRQL',
        field: 'dispatchRoutePk',
        value: [null]
      }, {
        type: 'stringEq',
        field: 'scheduleStatus',
        value: ['exported']
      }]
    },
    // record id stored as routeId
    transform: {
      id: 'routeId'
    }
  };
  _exports.default = _default;
});