define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/activity-log/table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/activity-log/table/config", "lodash"], function (_exports, _component, _config, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    config: _config.default,
    store: Ember.inject.service(),
    filterEventTypeFuction: null,

    init() {
      this._super(...arguments);

      this.get('tableColumns').shift();
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    fetchDataQuery() {
      const filterEventTypeFuction = this.get('filterEventTypeFunction');
      return this._super(...arguments).then(records => {
        if (!filterEventTypeFuction) return records;
        return records.filter(filterEventTypeFuction);
      });
    },

    actions: {
      onColumnClick(column) {
        if (column.sorted) {
          this.set('sortDirection', column.ascending ? 'asc' : 'desc');
        }

        const valuePath = column.get('valuePath');
        const rowsToSort = (0, _lodash.orderBy)(this.get('table.rows'), row => {
          return row.get(valuePath);
        }, [this.get('sortDirection')]);
        this.set('table.rows', rowsToSort);
      }

    }
  });

  _exports.default = _default;
});