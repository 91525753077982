define("adept-iq/pods/components/form-components/driverenum/component", ["exports", "adept-iq/mixins/fetchAssignableVehicleDrivers"], function (_exports, _fetchAssignableVehicleDrivers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fetchAssignableVehicleDrivers.default, {
    classNames: ['form-components-select'],
    store: Ember.inject.service(),
    drivers: null,
    value: null,
    disabled: false,
    extra: null,
    dataFromModel: false,
    options: Ember.computed.alias('extra.options'),
    allowMultiple: Ember.computed.alias('extra.allowMultiple'),
    onlyAssignableDrivers: Ember.computed.readOnly('extra.onlyAssignableDrivers'),
    optionModelName: Ember.computed.alias('extra.optionModelName'),
    provider: Ember.computed.readOnly('record.route.provider'),
    //used in fetch vehicle driver mixin
    plannedStartTime: Ember.computed.readOnly('record.replacementStartTime'),
    //used in fetch vehicle driver mixin
    plannedEndTime: Ember.computed.readOnly('record.replacementEndTime'),

    //used in fetch vehicle driver mixin
    init() {
      this._super(...arguments);

      const onlyAssignableDrivers = this.get('onlyAssignableDrivers');

      if (onlyAssignableDrivers) {
        this.get('fetchDriversTask').perform();
      }
    },

    availableDrivers: Ember.computed('plannedStartTime', 'plannedEndTime', 'drivers.[]', function () {
      const onlyAssignableDrivers = this.get('onlyAssignableDrivers');
      if (!onlyAssignableDrivers) return this.get('store').peekAll(this.get('extra.optionModelName'));
      const drivers = this.get('drivers');
      if (!drivers || !drivers.length) return [];

      if (this.get('record.computedStatus') === 'Breakdown') {
        drivers.push(this.get('record.assignedDriver'));
        return drivers;
      }
    }),
    selected: Ember.computed('value', 'options', function () {
      if (!Ember.isNone(this.get('extra.optionModelName')) && !Ember.isNone(this.get('options'))) {
        return this.get('options').find(option => {
          return option.id === this.get('value.id');
        });
      }

      return false;
    }),
    actions: {
      onChange(option) {
        this.get('onChange')(option);
      }

    }
  });

  _exports.default = _default;
});