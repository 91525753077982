define("adept-iq/pods/components/generic-components/panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['generic-panel'],
    classNameBindings: ['isCollapsible', 'isInitial', 'isCollapsed', 'isExpanded'],
    title: null,
    isCollapsible: false,
    collapseState: 'initial',
    isInitial: Ember.computed.equal('collapseState', 'initial'),
    isCollapsed: Ember.computed.equal('collapseState', 'collapsed'),
    isExpanded: Ember.computed.equal('collapseState', 'expanded'),
    // Passed in attributes for panel header buttons
    // should think about turning this into a contextual component
    onDeleteButtonClick: null,
    onSaveButtonClick: null,
    onEditButtonClick: null,
    onAddButtonClick: null,
    disableHeaderActions: null,
    disableSaveBtn: false,
    disableEditBtn: false,
    disableDeleteBtn: false,
    disableAddBtn: false,

    toggleCollapse() {
      const collapseState = this.get('collapseState');

      if (collapseState === 'collapsed') {
        this.set('collapseState', 'expanded');
      } else {
        this.set('collapseState', 'collapsed');
      }
    },

    actions: {
      onAddButtonClick() {
        this.get('onAddButtonClick')();
      },

      onEditButtonClick() {
        this.get('onEditButtonClick')();
      },

      onDeleteButtonClick() {
        this.get('onDeleteButtonClick')();
      },

      onSaveButtonClick() {
        this.get('onSaveButtonClick')();
      },

      onHeaderClick() {
        if (this.get('isCollapsible')) this.toggleCollapse();
      }

    }
  });

  _exports.default = _default;
});