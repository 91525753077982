define("adept-iq/pods/components/side-drawer/system-config/ada-boundaries/ada-boundaries-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'agency-service',
    title: 'ADA Boundaries',
    showSelectedRowLength: false,
    defaultSortId: 'description',
    fetchModelData: true,
    defaultSortAsc: false,
    hideWidgetControls: false,
    columns: [{
      id: 'name',
      index: 0,
      type: 'string',
      label: 'Name',
      valuePath: 'agencyServiceName',
      defaultWidth: 870,
      searchable: true
    }]
  };
  _exports.default = _default;
});