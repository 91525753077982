define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/health/component", ["exports", "ember-concurrency", "ember-changeset", "moment", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _emberChangeset, _moment, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_HEALTH_TYPE_MAP = {
    addDriverHealth: true,
    editDriverHealth: true,
    deleteDriverHealth: true
  };
  const ACTIVE_STATUS = 'active';
  const PushProvider = [];

  var _default = Ember.Component.extend({
    classNames: ['driver-health-view'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    driverHealthChangeSet: null,
    // placeholders
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    NOTES_PLACEHOLDER: _placeholders.NOTES_PLACEHOLDER,
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    // passed into view
    editAPI: null,
    selectedDriver: Ember.computed.readOnly('editAPI.selectedRecord'),
    isDriverHealthNew: Ember.computed.readOnly('driverHealthChangeSet.isNew'),
    tableRef: null,
    activityHealthTableRef: null,
    activityQueryParams: null,
    healthQueryParams: null,
    disableOtherPanel: Ember.computed('driverHealthChangeSet.otherHealthCheck', function () {
      const otherHealthCheckLen = this.get('driverHealthChangeSet.otherHealthCheck.length') || 0;

      if (otherHealthCheckLen <= 0) {
        this.clearOtherForm();
      }

      return otherHealthCheckLen <= 0;
    }),
    disableDiabetes: Ember.computed('driverHealthChangeSet.diabetes', function () {
      const diabetes = !this.get('driverHealthChangeSet.diabetes');

      if (diabetes) {
        this.clearDiabetesForm();
      }

      return diabetes;
    }),
    // once a user un checks high blood pressure to clear fields / disable fields
    disableHighBloodPressure: Ember.computed('driverHealthChangeSet.highBloodPressure', function () {
      const highBloodPressure = !this.get('driverHealthChangeSet.highBloodPressure');

      if (highBloodPressure) {
        this.clearHighBloodForm();
      }

      return highBloodPressure;
    }),

    init() {
      this._super(...arguments);

      const selectedDriver = this.get('selectedDriver');
      const setProviderDropDowns = this.get('setProviderDropDowns').bind(this);
      const driverHealth = selectedDriver.get('driverHealths.firstObject');
      setProviderDropDowns();
      this.get('setupDropDowns').perform();
      this.set('activityQueryParams', {
        driverId: `${selectedDriver.get('id')}`,
        eventLog: true
      });
      this.set('driverQueryParams', {
        filter: `eq(driverId,${selectedDriver.get('id')})`
      });
      this.set('driverHealthChangeSet', this.setDefaultProperties(driverHealth));
      const defaulProviderName = this.get('driverHealthChangeSet.provider.id');
      PushProvider.push(defaulProviderName);
      this.get('refreshTask').perform();
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onSaveDriverRecord = this.get('onSaveDriverHealthRecord');
      const onValidateAction = this.get('validateDriverHealth'); // normal functions

      const onUndoDriverRecord = this.get('onUndoDriverHealthRecord').bind(this);
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverRecord,
          undoAction: onUndoDriverRecord,
          deleteAction: null,
          editAction: null,
          newAction: null,
          hasChanges,
          onValidateAction,
          isChangesHappened: false
        });
        editAPI.buttonSettings(false, false, false, false, false);
      });
      this.addObserver('hasPendingChanges', this.hasPendingChangesObserver);
    },

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_HEALTH_TYPE_MAP[event.get('actionType')];
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const selectedDriver = this.get('selectedDriver');
      const providerOptions = this.get('providerOptions');

      if (record === null) {
        this.recordNull = true;
      }

      let pendingDriverHealth = record;

      if (Ember.isEmpty(pendingDriverHealth)) {
        pendingDriverHealth = store.createRecord('driver-health', {
          driver: selectedDriver,
          provider: providerOptions.firstObject
        });
      }

      this.set('showValidations', false);
      this.resetForm();
      return new _emberChangeset.default(pendingDriverHealth);
    },

    hasPendingChanges: Ember.computed('driverHealthChangeSet.changes.length', function () {
      const driverHealthChangeSet = this.get('driverHealthChangeSet');
      const changes = driverHealthChangeSet.get('changes') || [];
      return changes.length;
    }),

    // the only view to not have any required information to be entered
    hasChanges() {
      return !!this.get('hasPendingChanges');
    },

    hasPendingChangesObserver() {
      const editAPI = this.get('editAPI');
      const hasPendingChanges = this.get('hasPendingChanges');
      Ember.run.scheduleOnce('afterRender', () => {
        editAPI.setProperties({
          isChangesHappened: hasPendingChanges
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const driverHealth = this.get('driverHealthChangeSet.data');

      if (driverHealth) {
        if (!driverHealth.get('isNew') && !driverHealth.get('isDeleted')) {
          yield driverHealth.reload();
        }
      }

      const tableRef = this.get('tableRef');
      const activityHealthTableRef = this.get('activityHealthTableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      if (activityHealthTableRef) Ember.run.scheduleOnce('afterRender', activityHealthTableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        editAPI.buttonSettings(false, false, false, false, false);
      });
    },

    setupDropDowns: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const driverPhysicalTypeOptions = yield store.findAll('driver-physical-type-name');
      this.set('driverPhysicalTypeOptions', driverPhysicalTypeOptions);
    }),

    setProviderDropDowns() {
      const store = this.get('store');
      const providerOptions = store.peekAll('provider').filter(provider => {
        return provider.get('userCanAccessProvider') && provider.get('status') && provider.get('status').toLowerCase() === ACTIVE_STATUS;
      });
      this.set('providerOptions', providerOptions);
    },

    // Driver Provider CRUD OPERATIONS
    onSaveDriverHealthRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const selectedDriver = this.get('selectedDriver');
      const pendingDriverHealth = this.get('driverHealthChangeSet');
      const tooltip = this.get('tooltip'); // validation

      const isValidDriverHealth = yield this.validateDriverHealth.perform();

      if (isValidDriverHealth) {
        pendingDriverHealth.set('driver', selectedDriver);

        try {
          yield pendingDriverHealth.save();
          tooltip.reset();
          notifications.success('Record successfully saved.');
          Ember.run.later(() => {
            this.get('refreshTask').perform();
          }, 1000);
          return pendingDriverHealth;
        } catch (e) {
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line
        }
      }
    }),

    onUndoDriverHealthRecord() {
      const driverHealthChangeSet = this.get('driverHealthChangeSet');
      const providerOptions = this.get('providerOptions');

      if (driverHealthChangeSet) {
        if (this.ValueChange === true) {
          this.ValueChange = false;
          this.onAnyValueChangeToFalse();
          driverHealthChangeSet.rollback();
        } else {
          const secondLastProvider = PushProvider[PushProvider.length - 2];
          this.recordNull = false;
          providerOptions.forEach(providerData => {
            if (providerData.id === secondLastProvider) {
              this.onProviderChange(providerData, 1);
              PushProvider.push(providerData.id);
            }
          });
        }
      }
    },

    clearDiabetesForm() {
      const driverHealthChangeSet = this.get('driverHealthChangeSet');
      Ember.run.next(() => {
        driverHealthChangeSet.setProperties({
          prevDiabetesCheckDate: null,
          lastDiabetesCheckDate: null,
          nextDiabetesCheckDate: null
        });
      });
    },

    clearHighBloodForm() {
      const driverHealthChangeSet = this.get('driverHealthChangeSet');
      Ember.run.next(() => {
        driverHealthChangeSet.setProperties({
          bloodPressure: null,
          prevBloodPressureDate: null,
          lastBPDate: null,
          nextBPDate: null
        });
      });
    },

    clearOtherForm() {
      const driverHealthChangeSet = this.get('driverHealthChangeSet');
      Ember.run.next(() => {
        driverHealthChangeSet.setProperties({
          otherHealthCheckNotes: null,
          prevOtherHealthCheckDate: null,
          lastOtherHealthCheckDate: null,
          nextOtherHealthCheckDate: null
        });
      });
    },

    validateDriverHealth: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverHealthChangeSet = this.get('driverHealthChangeSet');
      yield driverHealthChangeSet.validate();
      const DriverHealthValid = driverHealthChangeSet.errors;
      const errors = DriverHealthValid;

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.reset();
          }

        });
        return false;
      }

      return true;
    }),

    onProviderChange(selectedProvider) {
      let untoClick = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      let driverHealthChangeSet = this.get('driverHealthChangeSet');
      const selectedDriver = this.get('selectedDriver');
      const previousProviderName = this.get('driverHealthChangeSet.provider.name');

      if (untoClick === 0) {
        PushProvider.push(selectedProvider.id);
      }

      if (previousProviderName !== selectedProvider.name) {
        const driverHealths = selectedDriver.get('driverHealths') || [];
        const driverHealth = driverHealths.find(driverHealthInfo => driverHealthInfo.get('provider.name') === selectedProvider.name);
        driverHealthChangeSet = this.setDefaultProperties(driverHealth);
        this.set('driverHealthChangeSet', driverHealthChangeSet);
      }

      driverHealthChangeSet.set('provider', selectedProvider);
    },

    onAnyValueChange() {
      this.ValueChange = true;
    },

    onAnyValueChangeToFalse() {
      this.ValueChange = false;
    },

    actions: {
      onPowerSelectChange(selected) {
        this.onAnyValueChangeToFalse();
        this.onProviderChange(selected);
      },

      onPhysicalSelectChange(valuePath, selected) {
        if (this.recordNull !== true) {
          this.onAnyValueChange();
        }

        this.set(valuePath, selected);
      },

      onInputValueChange(record, valuePath, value) {
        if (this.recordNull !== true) {
          this.onAnyValueChange();
        }

        record.set(valuePath, value);
      },

      onInputMaskValueChange(record, valuePath, useMaskVal, maskObj) {
        if (this.recordNull !== true) {
          this.onAnyValueChange();
        }

        if (useMaskVal) record.set(valuePath, maskObj.masked.replace(/_/im, ''));else record.set(valuePath, maskObj.unmasked);
      },

      onDateValueChange(record, valuePath, value) {
        if (this.recordNull !== true) {
          this.onAnyValueChange();
        }

        const isoDate = (0, _moment.default)(value).toISOString();
        record.set(valuePath, isoDate);
      },

      onCheckboxChange(record, valuePath, event) {
        if (this.recordNull !== true) {
          this.onAnyValueChange();
        }

        const checked = event.target.checked;
        record.set(valuePath, checked);
      }

    }
  });

  _exports.default = _default;
});