define("adept-iq/pods/components/timeline-picker/relative-time-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XrYV7ajc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"time-picker-wrapper\"],[9],[0,\"\\n  \"],[7,\"strong\"],[9],[0,\"-\"],[10],[0,\"\\n  \"],[1,[27,\"timeline-picker/relative-time-input\",null,[[\"value\",\"onUpdateValue\"],[[27,\"readonly\",[[23,[\"minusValue\"]]],null],[27,\"action\",[[22,0,[]],\"onUpdateMinusValue\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"time-picker-wrapper\"],[9],[0,\"\\n  \"],[7,\"strong\"],[9],[0,\"+\"],[10],[0,\"\\n  \"],[1,[27,\"timeline-picker/relative-time-input\",null,[[\"value\",\"onUpdateValue\"],[[27,\"readonly\",[[23,[\"plusValue\"]]],null],[27,\"action\",[[22,0,[]],\"onUpdatePlusValue\"],null]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/timeline-picker/relative-time-picker/template.hbs"
    }
  });

  _exports.default = _default;
});