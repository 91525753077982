define("adept-iq/transforms/ss-date", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // scheduling API uses a slightly different date format
  const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZZ';

  var _default = _emberData.default.DateTransform.extend({
    serialize(date) {
      if (date instanceof Date && !isNaN(date)) {
        return (0, _moment.default)(date).format(DATE_FORMAT);
      }

      return null;
    }

  });

  _exports.default = _default;
});