define("adept-iq/serializers/vehicle", ["exports", "adept-iq/serializers/-vehicle", "lodash"], function (_exports, _vehicle, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _vehicle.default.extend({
    attrs: {
      etaRoute: {
        serialize: false
      },
      displayStatus: {
        serialize: false
      },
      vehicleRouteExecEvent: {
        serialize: false
      },
      startingGarage: {
        serialize: false
      },
      endingGarage: {
        serialize: false
      },
      driverAppVersion: {
        serialize: false
      },
      otp: {
        serialize: false
      },
      otpWithSign: {
        serialize: false
      },
      revenue: {
        serialize: false
      },
      rvdStartTime: {
        serialize: false
      },
      rvdEndTime: {
        serialize: false
      },
      rvdHistory: {
        serialize: false
      },
      inProgress: {
        serialize: false
      },
      routeStatus: {
        serialize: false
      },
      isCompleted: {
        serialize: false
      },
      currentRouteName: {
        serialize: false
      },
      currentRoutePk: {
        serialize: false
      },
      driverId: {
        serialize: false
      },
      hasPowerOn: {
        serialize: false
      },
      isGpsFailure: {
        serialize: false
      },
      isCommFailure: {
        serialize: false
      },
      isLeaveGarageSignOff: {
        serialize: false
      },
      isOnBreak: {
        serialize: false
      },
      currentDriver: {
        serialize: false
      },
      replyToMessageId: {
        serialize: false
      },
      preReplyId: {
        serialize: false
      },
      emergency: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      if (json.data && json.data.relationships) {
        json.data.relationships = json.data.relationships || {};
        delete json.data.relationships.breakdownType;
        delete json.data.relationships.iqVehicle;
        delete json.data.attributes.avlLocation;
        delete json.data.attributes.mobileId;
        delete json.data.attributes.version;
        const providerRelationship = json.data.relationships.provider;
        const {
          vehicleType,
          startGarage,
          endGarage,
          leaseCompany
        } = json.data.relationships;
        const {
          nonRevenueVehicle
        } = json.data.attributes;

        if (!Ember.isNone(providerRelationship)) {
          providerRelationship.data.type = 'providerName';
          json.data.relationships.providerName = json.data.relationships.provider;
          delete json.data.relationships.provider;
        }

        if (!Ember.isNone(vehicleType)) {
          vehicleType.data.type = 'vehicleTypeName';
          json.data.relationships.vehicleTypeName = json.data.relationships.vehicleType;
          delete json.data.relationships.vehicleType;
        }

        if (!Ember.isNone(startGarage)) {
          json.data.relationships.startGaragePlace = json.data.relationships.startGarage;
          delete json.data.relationships.startGarage;
        }

        if (!Ember.isNone(endGarage)) {
          json.data.relationships.endGaragePlace = json.data.relationships.endGarage;
          delete json.data.relationships.endGarage;
        }

        if (!Ember.isNone(leaseCompany)) {
          leaseCompany.data.type = 'leaseCompanyName';
          json.data.relationships.leaseCompanyName = json.data.relationships.leaseCompany;
          delete json.data.relationships.leaseCompany;
        }

        if (!Ember.isNone(nonRevenueVehicle)) {
          json.data.attributes.nonRevenueVehicle = !nonRevenueVehicle;
        }
      }

      return json;
    },

    // currently vehicles included payload returns vehicleTypeName & providerName
    // this means the console will spew a bunch of warning messages for missing these models
    // we currently don't have a way to remove these problems especially we can't traverse the array as that in itself is a code smell
    // @FIXME: find a way to address this data situation
    // I left this code as a reminder for this small low priority problem
    // normalizeResponse: function(store, primaryModelClass, payload) {
    //   delete payload.included;
    //   return payload;
    // },
    normalizeResponse(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      delete payload.included;
      return this._super(...arguments);
    },

    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};
      const {
        startGaragePlace,
        endGaragePlace,
        leaseCompanyName,
        providerName,
        vehicleTypeName
      } = resourceHash.relationships;
      const {
        inspections
      } = resourceHash.relationships;
      const {
        nonRevenueVehicle
      } = resourceHash.attributes;

      if (inspections) {
        inspections.data.forEach(dot => {
          dot.type = 'vehicleDotInspection';
        });
      }

      if (resourceHash.attributes) {
        if (!Ember.isNone(resourceHash.attributes.vehicleTypeName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.vehicleType = {
            data: {
              type: 'vehicle-type',
              id: resourceHash.attributes.vehicleTypeName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.vehicleTypeName;
        }

        if (!Ember.isNone(resourceHash.attributes.providerName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.provider = {
            data: {
              type: 'provider',
              id: resourceHash.attributes.providerName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.providerName;
        }

        const routeActualEndTime = resourceHash.attributes.routeActualEndTime;
        const routePk = resourceHash.attributes.routePk;

        if (Ember.isPresent(routeActualEndTime) && Ember.isPresent(routePk)) {
          if (Ember.isPresent(routeActualEndTime[routePk])) {
            resourceHash.attributes.isCompleted = true;
          } else {
            resourceHash.attributes.isCompleted = false;
          }
        }
      }

      if (providerName) {
        resourceHash.relationships.providerName.data.type = 'provider';
        resourceHash.relationships.provider = (0, _lodash.cloneDeep)(resourceHash.relationships.providerName);
        delete resourceHash.relationships.providerName;
      }

      if (vehicleTypeName) {
        resourceHash.relationships.vehicleTypeName.data.type = 'vehicle-type';
        resourceHash.relationships.vehicleType = (0, _lodash.cloneDeep)(resourceHash.relationships.vehicleTypeName);
        delete resourceHash.relationships.vehicleTypeName;
      }

      if (!Ember.isNone(startGaragePlace)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.startGarage = startGaragePlace;
        delete resourceHash.relationships.startGaragePlace;
      }

      if (!Ember.isNone(endGaragePlace)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.endGarage = (0, _lodash.cloneDeep)(endGaragePlace);
        delete resourceHash.relationships.endGaragePlace;
      }

      if (!Ember.isNone(leaseCompanyName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.leaseCompany = {
          data: {
            type: 'lease-company',
            id: leaseCompanyName.data.id
          }
        };
        delete resourceHash.relationships.leaseCompanyName;
      }

      if (Ember.isPresent(nonRevenueVehicle)) {
        resourceHash.attributes.nonRevenueVehicle = !nonRevenueVehicle;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      const relationships = payload.data.relationships ? payload.data.relationships : {};

      if (Ember.isPresent(payload.data.attributes.availabilities)) {
        const availabilities = Array.isArray(payload.data.attributes.availabilities) ? payload.data.attributes.availabilities : [payload.data.attributes.availabilities];
        availabilities.forEach(availability => {
          const vehicleAvailability = {
            type: 'vehicle-availability',
            id: availability.id,
            attributes: availability,
            relationships: {
              vehicle: {
                data: {
                  type: 'vehicle',
                  id: payload.data.id
                }
              }
            }
          };
          payload.included = payload.included || [];
          payload.included.push(vehicleAvailability);
        });
      }

      if (Ember.isPresent(payload.data.attributes.type)) {
        relationships.vehicleType = {
          data: {
            type: 'vehicle-type',
            id: payload.data.attributes.type,
            name: payload.data.attributes.type,
            displayName: payload.data.attributes.type
          }
        };
        delete payload.data.attributes.type;
      }

      if (Ember.isPresent(payload.data.attributes.lastGpsPingLat) && Ember.isPresent(payload.data.attributes.lastGpsPingLng)) {
        payload.data.attributes.avlLocation = {
          lat: parseFloat(payload.data.attributes.lastGpsPingLat),
          lng: parseFloat(payload.data.attributes.lastGpsPingLng),
          timestamp: payload.data.attributes.lastGpsPingTime
        };
      }

      if (Ember.isPresent(payload.data.attributes.status)) {
        payload.data.attributes.displayStatus = payload.data.attributes.status;
        delete payload.data.attributes.status;
      }

      if (Ember.isPresent(payload.data.attributes.vehicleStatus)) {
        payload.data.attributes.status = payload.data.attributes.vehicleStatus;
        delete payload.data.attributes.vehicleStatus;
      } // If no rvdHistory we need to reset the value in ember data.


      if (!Ember.isPresent(payload.data.attributes.rvdHistory)) {
        payload.data.attributes.rvdHistory = null;
      }

      if (!Ember.isPresent(payload.data.attributes.emergency)) {
        payload.data.attributes.emergency = null;
      }

      if (!Ember.isPresent(payload.data.attributes.preReplyId)) {
        payload.data.attributes.preReplyId = null;
      }

      if (!Ember.isPresent(payload.data.attributes.replyToMessageId)) {
        payload.data.attributes.replyToMessageId = null;
      }

      const isRoadSupervisor = payload.data.attributes.routeName && payload.data.attributes.routeName.includes('SUP');
      payload.data.attributes.otp = payload.data.attributes.otpCode || payload.data.attributes.otpStatus;
      payload.data.attributes.driverBadgeNumber = isRoadSupervisor ? payload.data.attributes.driverPk : payload.data.attributes.driverId;
      payload.data.attributes.driverFullName = payload.data.attributes.driverName; //TODO can make it better

      if (payload.data.attributes.vehicleStateEvent) {
        switch (payload.data.attributes.vehicleStateEvent) {
          case 'commFailure':
            payload.data.attributes.isCommFailure = true;
            break;

          case 'commRestored':
            payload.data.attributes.isCommFailure = false;
            break;

          case 'gpsFailure':
            payload.data.attributes.isGpsFailure = true;
            break;

          case 'leaveGarageSignOff':
            payload.data.attributes.isLeaveGarageSignOff = true;
            break;

          default:
            break;
        }
      }

      if (payload.data.attributes.driverStateEvent) {
        switch (payload.data.attributes.driverStateEvent) {
          case 'signOn':
            payload.data.attributes.isLeaveGarageSignOff = false;
            break;

          case 'signOff':
            // Do nothing for signOff because it might not even in drving state
            break;

          case 'emergencyOn':
            break;

          case 'emergencyOff':
            break;

          default:
            break;
        }
      }

      if (payload.data.attributes.vehicleRouteExecEvent) {
        switch (payload.data.attributes.vehicleRouteExecEvent) {
          case 'onBreak':
            payload.data.attributes.isOnBreak = true;
            break;

          case 'offBreak':
            payload.data.attributes.isOnBreak = false;
            break;

          default:
            break;
        }
      }

      const driverPk = payload.data.attributes.driverPk;
      const provider = payload.data.attributes.provider;
      const routeName = payload.data.attributes.routeName;
      const routePk = payload.data.attributes.routePk;

      if (routePk && routeName) {
        payload.data.attributes.currentRouteName = routeName;
        payload.data.attributes.currentRoutePk = routePk;
      }

      if (driverPk) {
        relationships.currentDriver = {
          data: {
            type: 'driver',
            id: `${driverPk}`
          }
        };
      }

      if (provider) {
        relationships.provider = {
          data: {
            type: 'provider',
            id: provider
          }
        };
        delete payload.data.attributes.provider;
      }

      payload.data.relationships = relationships;
      return payload;
    }

  });

  _exports.default = _default;
});