define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-client/client-search-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    title: 'Search',
    defaultSortId: 'tripId',
    defaultSortAsc: true,
    reconcileSearchTables: true,
    showSelectedRowLength: false,
    noRecordsFound: false,
    columns: [{
      id: 'tripId',
      index: 0,
      type: 'text',
      label: 'Trip Id',
      valuePath: 'tripId',
      width: '85px',
      resizable: true,
      minResizeWidth: 50
    }, {
      id: 'groupId',
      index: 1,
      type: 'text',
      label: 'Group ID',
      valuePath: 'groupId',
      resizable: true,
      width: '85px',
      minResizeWidth: 50
    }, {
      id: 'subscriptionId',
      index: 2,
      type: 'text',
      label: 'Subscription ID',
      valuePath: 'subscriptionId',
      resizable: true,
      width: '85px',
      minResizeWidth: 50
    }, {
      id: 'riderId',
      index: 3,
      type: 'text',
      label: 'Client ID',
      valuePath: 'riderId',
      resizable: true,
      width: '85px',
      minResizeWidth: 50
    }, {
      id: 'status',
      index: 4,
      type: 'text',
      label: 'Status',
      valuePath: 'status',
      resizable: true,
      width: '80px',
      minResizeWidth: 50
    }, {
      id: 'tripdate',
      index: 5,
      type: 'date',
      label: 'Trip Date',
      valuePath: 'tripDate',
      resizable: true,
      minResizeWidth: 50,
      width: '85px'
    }]
  };
  _exports.default = _default;
});