define("adept-iq/serializers/route-vehicle-driver", ["exports", "adept-iq/serializers/-dispatch", "lodash"], function (_exports, _dispatch, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    normalize(modelClass, resourceHash) {
      const relationships = resourceHash.relationships ? resourceHash.relationships : {};

      if (!Ember.isNone(relationships) && Ember.isNone(relationships.vehicle)) {
        relationships.vehicle = {
          data: null
        };
      }

      if (!Ember.isNone(relationships) && Ember.isNone(relationships.driver)) {
        relationships.driver = {
          data: null
        };
      }

      resourceHash.relationships = relationships;

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    serialize(snapshot
    /*, options*/
    ) {
      const json = this._super(...arguments);

      const dispatchRouteRelationship = _lodash.default.get(json, 'data.relationships.dispatchRoute.data', {});

      if (!_lodash.default.isEmpty(dispatchRouteRelationship)) {
        const dispatchRouteIncluded = _lodash.default.cloneDeep(dispatchRouteRelationship);

        json.included = [];
        dispatchRouteIncluded.attributes = {
          version: snapshot.record.dispatchRoute.get('latestRouteVehicleDriver.dispatchRouteVersion')
        };
        json.included.push(dispatchRouteIncluded);
      }

      return json;
    }

  });

  _exports.default = _default;
});