define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/cdl-classification/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/cdl-classification/validation", "adept-iq/config/placeholders", "lodash", "adept-iq/utils/guid"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset, _emberChangesetValidations, _validation, _placeholders, _lodash, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['driver-lookup-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // Global Placeholder
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    tableRef: null,
    description: null,
    editAPI: null,
    disableForm: true,
    showErrorMsg: false,
    currentDisplayName: null,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    driverCdlChangeSet: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),
    hasPendingChanges: Ember.computed('driverCdlChangeSet.changes.length', function () {
      const driverCdlChangeSet = this.get('driverCdlChangeSet');
      const changes = driverCdlChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      return this.get('hasPendingChanges');
    },

    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveDriverCdlRecord = this.get('onSaveDriverCdlRecord');
      const onNewDriverCdlRecord = this.get('onNewDriverCdlRecord').bind(this);
      const onEditDriverCdlRecord = this.get('onEditDriverCdlRecord').bind(this);
      const onUndoDriverCdlRecord = this.get('onUndoDriverCdlRecord').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      const hasRelationship = this.get('hasRelationship').bind(this);
      const refreshTask = this.get('refreshTask');
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverCdlRecord,
          newAction: onNewDriverCdlRecord,
          editAction: onEditDriverCdlRecord,
          undoAction: onUndoDriverCdlRecord,
          deleteAction: null,
          hasChanges,
          hasRelationship,
          onValidateAction,
          refreshTask
        });
        refreshTask.perform();
      });
    },

    willDestroyElement() {
      const records = this.get('tableRef.records') || [];
      records.map(record => {
        if (record.get('tableRef')) {
          record.set('tableRef');
        }
      });

      this._super(...arguments);
    },

    hasRelationship() {
      const store = this.get('store');
      const tooltip = this.get('tooltip');
      const description = this.get('driverCdlChangeSet.description') || '';
      const driver = store.peekAll('driver').filter(record => record.get('license.classificationName.description') === description);

      if (driver.length) {
        tooltip.pushConfirmation({
          title: 'Lookup',
          hasoverlay: true,
          tip: `There are ${driver.length} active Driver(s) with this Driver CDL Classification description. You must either change the Driver CDL Classification description on these Driver(s) or delete these Driver(s) prior to changing the Driver CDL Classification description.`,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.reset();
          }

        });
      }

      return driver.length;
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const tableRef = this.get('tableRef');
      let driverCdlObj = this.get('pendingRecord');

      if (record) {
        driverCdlObj = record;
        this.resetForm();
      }

      if (Ember.isEmpty(record)) {
        const data = {
          category: 'config-System_Configuration-CDL_classifications',
          type: 'object'
        };
        driverCdlObj = store.createRecord('cs-config-item', data);
        this.set('pendingRecord', driverCdlObj);
      }

      if (driverCdlObj.get('isNew')) {
        driverCdlObj.setProperties({
          description: null,
          tableRef: tableRef
        });
      }

      this.set('showErrorMsg', false);
      const tableRefRecords = this.get('tableRef.records') || [];
      tableRefRecords.map(tableRefRecord => {
        if (!tableRefRecord.get('tableRef')) {
          tableRefRecord.set('tableRef', tableRef);
        }
      });
      return new _emberChangeset.default(driverCdlObj, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('driverCdlChangeSet');

      if (selectedRecord) {
        if (!selectedRecord.get('isNew') && !selectedRecord.get('isDeleted')) {
          yield selectedRecord.data.reload();
        }
      }

      const tableRef = this.get('tableRef');
      this.resetForm();
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        editAPI.buttonSettings();
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverCdlRecord = this.get('driverCdlChangeSet');
      yield driverCdlRecord.validate();
      const errors = driverCdlRecord.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveDriverCdlRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');
      const activityIdGuid = (0, _guid.guid)();
      if (!isValid) return;
      const notifications = this.get('notifications');
      const description = this.get('driverCdlChangeSet.description') || '';
      const value = {
        displayName: description,
        description: description
      };
      const nameGuidConverted = `${description}-${activityIdGuid}`;
      const name = (0, _lodash.camelCase)(nameGuidConverted);
      const driverCdlChangeSet = this.get('driverCdlChangeSet');
      let driverCdlRecord = this.get('driverCdlChangeSet');

      if (driverCdlRecord.get('isNew')) {
        driverCdlRecord = (0, _unwrapProxy.unwrapProxy)(this.get('driverCdlChangeSet'));
        driverCdlRecord.set('name', name);
      } else {
        const id = this.get('driverCdlChangeSet.id');
        const store = this.get('store');
        const record = `config-System_Configuration-CDL_classifications/${id}`;
        driverCdlRecord = store.peekRecord('cs-config-item', record);
      }

      driverCdlRecord.set('displayName', description);
      driverCdlRecord.set('description', description);
      driverCdlRecord.set('value', value);

      try {
        yield driverCdlRecord.save(); // changeset holds changes, execute the changes due to lookups using unorthodox saving practice
        // execute to change underlying object and rollback to remove internal changes list

        driverCdlChangeSet.execute();
        driverCdlChangeSet.rollback();
        notifications.success('Lookup record successfully saved.');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
            tooltip.reset();
          });
        }, 500);
      } catch (response) {
        if (response.errors[0].status === '409') {
          tooltip.pushConfirmation({
            title: 'Manage Driver/Vehicle',
            tip: 'Description cannot duplicate an existing Description.',
            primaryActionText: 'OK',
            primaryAction: () => {
              return tooltip.reset();
            }
          });
        } else {
          notifications.warning('Lookup record failed to save.');
        }

        if (driverCdlRecord.rollbackAttributes) {
          driverCdlRecord.rollbackAttributes();
        }

        console.warn(response); // eslint-disable-line
      }
    }).drop(),

    onEditDriverCdlRecord() {
      const editAPI = this.get('editAPI');
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onUndoDriverCdlRecord() {
      const driverCdlChangeSet = this.get('driverCdlChangeSet');
      const editAPI = this.get('editAPI');

      if (driverCdlChangeSet) {
        driverCdlChangeSet.rollback();
      }

      this.set('disableForm', true);
      editAPI.buttonSettings(false, false, false, true, true);
    },

    onNewDriverCdlRecord() {
      const editAPI = this.get('editAPI');
      const selectedRow = this.get('selectedRow');

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    actions: {
      onSaveDriverCdlRecord() {
        return this.onSaveDriverCdlRecord.perform();
      },

      onInputValueChange(record, valuePath, value) {
        this.set('currentDisplayName', value);
        record.set(valuePath, value);
      }

    }
  });

  _exports.default = _default;
});