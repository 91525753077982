define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/table-filter", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/side-drawer", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/config"], function (_exports, _react, _panel, _sideDrawer, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const TableFilter = props => {
    const {
      table,
      setFilterEnabled
    } = props;
    const {
      toggleAllVehicleTableColumns,
      toggleAllEventTableColumns,
      vehicleTableColumns,
      eventTableColumns,
      toggleEventTableColumn,
      toggleVehicleTableColumn,
      updateEventColumn,
      updateVehicleColumn,
      setVehicleTableColumns,
      setEventTableColumns
    } = (0, _panel.usePanel)();

    const [isEyeOpened, setIsEyeOpened] = _react.default.useState(true);

    const toggleAllTableColumns = table === _config.default.tableTypes.events ? toggleAllEventTableColumns : toggleAllVehicleTableColumns;
    const toggleTableColumn = table === _config.default.tableTypes.events ? toggleEventTableColumn : toggleVehicleTableColumn;
    const tableColumns = table === _config.default.tableTypes.events ? eventTableColumns : vehicleTableColumns;
    const setTableColumns = table === _config.default.tableTypes.events ? setEventTableColumns : setVehicleTableColumns;
    const updateColumn = table === _config.default.tableTypes.events ? updateEventColumn : updateVehicleColumn;
    const filterConditions = tableColumns.filter(column => column.filterOp !== '');

    const toggleEyeStatus = () => {
      toggleAllTableColumns(!isEyeOpened);
      setIsEyeOpened(!isEyeOpened);
    };

    const resetAllFilters = () => {
      tableColumns.forEach(column => {
        column.filterOp = '';
        column.filterValue = '';
      });
      setTableColumns([...tableColumns]);
    };

    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_sideDrawer.default, {
      title: 'Playback Filters',
      setFilterEnabled: setFilterEnabled,
      className: 'playback-filter-settings-side-drawer'
    }, _react.default.createElement("div", {
      className: "g-side-drawer-body ember-view"
    }, _react.default.createElement("div", {
      className: "side-drawer-subheader data-test-filter-side-bar-header"
    }, _react.default.createElement("span", {
      className: "reorder-column"
    }), _react.default.createElement("span", {
      className: "visibility-column",
      onClick: toggleEyeStatus
    }, isEyeOpened ? _react.default.createElement("i", {
      className: "fa fa-eye",
      "aria-hidden": "true"
    }) : _react.default.createElement("i", {
      className: "fa fa-eye-slash",
      "aria-hidden": "true"
    })), _react.default.createElement("span", {
      className: "header-label-column"
    }, "Column"), _react.default.createElement("span", {
      className: "filter-type-column"
    }, "Filter"), _react.default.createElement("div", null, _react.default.createElement("button", {
      className: "header-button-outline data-test-clear-filter-button",
      disabled: filterConditions.length === 0,
      onClick: () => resetAllFilters(),
      type: "button"
    }, "Clear All", ' ', _react.default.createElement("i", {
      className: "fa fa-times-circle",
      "aria-hidden": "true"
    })))), _react.default.createElement("div", {
      className: "side-drawer-table-wrapper data-test-filter-side-bar-table"
    }, _react.default.createElement("div", {
      className: "filter-settings-table ember-view"
    }, tableColumns.map(column => {
      return _react.default.createElement("div", {
        key: `${table}_${column.id}`,
        className: "sortable-item filter-setting-row ember-view"
      }, _react.default.createElement("div", {
        role: "button",
        tabIndex: "0",
        "data-sortable-handle": "",
        className: "setting-icon ember-view"
      }), _react.default.createElement("input", {
        "data-filter-checkbox-id": "playback-eventType active",
        className: "display-checkbox",
        key: column.id,
        value: column.value,
        "data-test-visibility-column": "",
        type: "checkbox",
        checked: column.isChecked,
        onChange: e => toggleTableColumn(column.id, e.target.checked)
      }), _react.default.createElement("div", {
        className: "setting-item"
      }, column.label), _react.default.createElement("div", {
        className: "setting-item-type"
      }, _react.default.createElement("select", {
        disabled: !column.isChecked,
        className: "ember-power-select-trigger ember-basic-dropdown-trigger ember-view",
        value: column.filterOp,
        onChange: e => {
          column.filterOp = e.target.value;
          updateColumn(column.id, column);
        }
      }, _react.default.createElement("option", {
        value: "",
        disabled: true,
        hidden: true
      }), _react.default.createElement("option", {
        value: _panel.filterOperations.eq
      }, "="), _react.default.createElement("option", {
        value: _panel.filterOperations.neq
      }, "!="), _react.default.createElement("option", {
        value: _panel.filterOperations.contains
      }, "Contains"), _react.default.createElement("option", {
        value: _panel.filterOperations.empty
      }, "Empty"))), _react.default.createElement("div", {
        colSpan: "2",
        className: "setting-item-value"
      }, _react.default.createElement("input", {
        disabled: !column.filterOp || column.filterOp === _panel.filterOperations.empty,
        value: column.filterValue,
        onChange: e => {
          column.filterValue = e.target.value;
          updateColumn(column.id, column);
        },
        className: "data-test-filterValue-column",
        type: "text"
      })));
    })), _react.default.createElement("span", {
      "aria-live": "polite",
      className: "visually-hidden"
    })))));
  };

  var _default = TableFilter;
  _exports.default = _default;
});