define("adept-iq/pods/components/rider-management-widget/rider-travel-needs/table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/rider-management-widget/rider-travel-needs/table/config", "adept-iq/utils/sorts", "adept-iq/utils/filters"], function (_exports, _component, _config, _sorts, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    config: _config.default,
    rider: Ember.inject.service(),
    recordSetter: Ember.observer('riderTravelNeeds', function () {
      const filteredData = this.getRiderTravelNeeds('rider-travel-need');
      this.set('records', filteredData);
    }),

    init() {
      this._super(...arguments);

      this.set('isSearchAllowed', false);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    getRiderTravelNeeds(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const riderTravelNeed = this.get('riderTravelNeeds.firstObject');
      const activeData = store.peekAll(modelName) || [];
      const riderId = Ember.isPresent(riderTravelNeed) ? riderTravelNeed.get('rider.id') : null;
      let filteredData = activeData.filter(filterFn).sort(compareFn);
      filteredData = filteredData.filter(data => {
        return data.get('rider.id') === riderId;
      });
      return filteredData;
    },

    query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const filteredData = this.getRiderTravelNeeds(modelName, params);
      return filteredData;
    },

    actions: {
      onRowClick(currentRow) {
        this._super(currentRow);

        this.get('OnTableRowClick')(currentRow);
        this.get('rider').setLastAccessedRiderTravelNeedId(currentRow.get('id'));
      }

    }
  });

  _exports.default = _default;
});