define("adept-iq/classes/rules/eligibility-rules/group-booking-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type"], function (_exports, _baseRule, _ruleResultType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GroupBookingRule = void 0;

  class GroupBookingRule extends _baseRule.default {
    doEvaluate(ruleContext) {
      const {
        bookingService
      } = ruleContext;
      if (this.isGroupBooking(bookingService)) return _ruleResultType.RESULT_TYPE_YES;
      return _ruleResultType.RESULT_TYPE_NO;
    }

    isGroupBooking(bookingService) {
      const selectedRiders = bookingService.get('selectedRiders');
      return selectedRiders.length > 1;
    }

  }

  _exports.GroupBookingRule = GroupBookingRule;
  GroupBookingRule.RULE_NAME = 'GroupBookingRule';
});