define("adept-iq/topic-transforms/avlm-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload, ENV) {
    // remove for now. don't need it.
    delete payload.data.relationships.source;
    delete payload.data.attributes.scheduleId;
    payload.included = payload.included.filter(p => p.type !== 'avlm-alert-source');
    payload.data.attributes.isNewEmergencyReceived = true; // adding new field for default sorting by priority column

    if (payload.data.attributes.priority) {
      if (payload.data.attributes.priority.toLowerCase() === 'critical') {
        payload.data.attributes.priorityId = 'A';
      } else if (payload.data.attributes.priority.toLowerCase() === 'high') {
        payload.data.attributes.priorityId = 'B';
      } else if (payload.data.attributes.priority.toLowerCase() === 'medium') {
        payload.data.attributes.priorityId = 'C';
      } else if (payload.data.attributes.priority.toLowerCase() === 'low') {
        payload.data.attributes.priorityId = 'D';
      }
    }

    if (ENV.APP.avlmLite && !payload.data.relationships.hasOwnProperty('route')) {
      if (payload.data.attributes.routeId) {
        let routeData = {
          type: 'avlmRoute',
          id: payload.data.attributes.routeId
        };
        routeData = {
          'data': routeData
        };
        payload.data.relationships.avlmRoute = routeData;
      }
    }

    if (ENV.APP.avlmLite && !payload.data.relationships.hasOwnProperty('vehicle')) {
      if (payload.data.attributes.vehicleId) {
        let vehicleData = {
          type: 'avlmVehicle',
          id: payload.data.attributes.vehicleId
        };
        vehicleData = {
          'data': vehicleData
        };
        payload.data.relationships.avlmVehicle = vehicleData;
      }
    }

    if (!ENV.APP.avlmLite && !payload.data.relationships.hasOwnProperty('route')) {
      if (payload.data.attributes.routeId) {
        let routeData = {
          type: 'route',
          id: payload.data.attributes.routeId
        };
        routeData = {
          'data': routeData
        };
        payload.data.relationships.route = routeData;
      }
    }

    if (!ENV.APP.avlmLite && !payload.data.relationships.hasOwnProperty('vehicle')) {
      if (payload.data.attributes.vehicleId) {
        let vehicleData = {
          type: 'vehicle',
          id: payload.data.attributes.vehicleId
        };
        vehicleData = {
          'data': vehicleData
        };
        payload.data.relationships.vehicle = vehicleData;
      }
    }

    if (!ENV.APP.avlmLite && !payload.data.relationships.hasOwnProperty('driver')) {
      if (payload.data.attributes.driverId) {
        let driverData = {
          type: 'driver',
          id: payload.data.attributes.driverId
        };
        driverData = {
          'data': driverData
        };
        payload.data.relationships.driver = driverData;
      }
    }

    return payload;
  }
});