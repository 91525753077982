define("adept-iq/models/passenger-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PCA_PASSENGER_TYPE = _exports.COMPANION_PASSENGER_TYPE = _exports.CLIENT_PASSENGER_TYPE = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;
  const COMPANION_PASSENGER_TYPE = 'companion';
  _exports.COMPANION_PASSENGER_TYPE = COMPANION_PASSENGER_TYPE;
  const PCA_PASSENGER_TYPE = 'pca';
  _exports.PCA_PASSENGER_TYPE = PCA_PASSENGER_TYPE;
  const CLIENT_PASSENGER_TYPE = 'client';
  _exports.CLIENT_PASSENGER_TYPE = CLIENT_PASSENGER_TYPE;

  var _default = Model.extend({
    name: attr('string'),
    riderTravelNeeds: hasMany('rider-travel-need'),
    subscriptionTravelNeeds: hasMany('subscription-travel-need'),
    legTravelNeeds: hasMany('leg-travel-need'),
    isPca: Ember.computed.equal('name', 'pca'),
    isCompanion: Ember.computed.equal('name', 'companion'),
    isClient: Ember.computed.equal('name', 'client')
  });

  _exports.default = _default;
});