define("adept-iq/serializers/reconcile-vehicle", ["exports", "adept-iq/serializers/-reconciliationService"], function (_exports, _reconciliationService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reconciliationService.default.extend({
    normalize(modelClass, resourceHash) {
      // resourceHash.id = resourceHash.attributes.startTime;
      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeQueryResponse(store, clazz, payload) {
      return this._super(store, clazz, payload);
    },

    modelNameFromPayloadKey(key) {
      if (key === 'vehicle') {
        return 'reconcile-vehicle';
      }

      return this._super(key);
    }

  });

  _exports.default = _default;
});