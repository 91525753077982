define("adept-iq/pods/components/iq-widgets/navigation-widget/config/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BENOMAD_SCHEDULE_COLOR = _exports.BENOMAD_PRIMARY_COLOR = _exports.BENOMAD_PLANNED_DARK_COLOR = _exports.BENOMAD_PLANNED_COLOR = _exports.BENOMAD_PERFORMED_COLOR = _exports.BENOMAD_PENDING_COLOR = _exports.BENOMAD_OTP_LATE_COLOR = _exports.BENOMAD_OTP_DANGER_COLOR = _exports.BENOMAD_DISTANCE_COLOR = void 0;
  const bemap = window.bemap;
  const BENOMAD_PLANNED_COLOR = new bemap.Color(169, 178, 181, 1); // grey color

  _exports.BENOMAD_PLANNED_COLOR = BENOMAD_PLANNED_COLOR;
  const BENOMAD_DISTANCE_COLOR = new bemap.Color(48, 62, 68, 1); // dark-grey color

  _exports.BENOMAD_DISTANCE_COLOR = BENOMAD_DISTANCE_COLOR;
  const BENOMAD_PLANNED_DARK_COLOR = new bemap.Color(85, 94, 99, 1); // dark-grey color

  _exports.BENOMAD_PLANNED_DARK_COLOR = BENOMAD_PLANNED_DARK_COLOR;
  const BENOMAD_PENDING_COLOR = new bemap.Color(0, 103, 252, 1); // blue color

  _exports.BENOMAD_PENDING_COLOR = BENOMAD_PENDING_COLOR;
  const BENOMAD_PERFORMED_COLOR = new bemap.Color(97, 95, 96, 1); // dark-grey color

  _exports.BENOMAD_PERFORMED_COLOR = BENOMAD_PERFORMED_COLOR;
  const BENOMAD_OTP_DANGER_COLOR = new bemap.Color(227, 98, 27, 1); // orange color

  _exports.BENOMAD_OTP_DANGER_COLOR = BENOMAD_OTP_DANGER_COLOR;
  const BENOMAD_OTP_LATE_COLOR = new bemap.Color(209, 55, 48, 1); // red color

  _exports.BENOMAD_OTP_LATE_COLOR = BENOMAD_OTP_LATE_COLOR;
  const BENOMAD_SCHEDULE_COLOR = new bemap.Color(255, 167, 1, 1); // orange-yellowish color

  _exports.BENOMAD_SCHEDULE_COLOR = BENOMAD_SCHEDULE_COLOR;
  const BENOMAD_PRIMARY_COLOR = new bemap.Color(124, 179, 66, 1); // app primary color

  _exports.BENOMAD_PRIMARY_COLOR = BENOMAD_PRIMARY_COLOR;
  var _default = {
    BENOMAD_PLANNED_COLOR,
    BENOMAD_PLANNED_DARK_COLOR,
    BENOMAD_PENDING_COLOR,
    BENOMAD_PERFORMED_COLOR,
    BENOMAD_PRIMARY_COLOR,
    BENOMAD_OTP_DANGER_COLOR,
    BENOMAD_OTP_LATE_COLOR,
    BENOMAD_DISTANCE_COLOR,
    BENOMAD_SCHEDULE_COLOR
  };
  _exports.default = _default;
});