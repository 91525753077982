define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/index", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/search-button", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/action-button", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/filter-button", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/playback-table", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/config"], function (_exports, _react, _panel, _api, _searchButton, _actionButton, _filterButton, _playbackTable, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PlaybackTables = () => {
    const {
      searchMode,
      eventSearchText,
      setEventSearchText,
      vehicleSearchText,
      setVehicleSearchText
    } = (0, _panel.usePanel)();
    const {
      eventData,
      vehicleData
    } = (0, _api.useAPI)();

    const [eventTotalCount, setEventTotalCount] = _react.default.useState(0);

    const [displayEventCount, setDisplayEventCount] = _react.default.useState(0);

    const [vehicleTotalCount, setVehicleTotalCount] = _react.default.useState(0);

    const [displayVehicleCount, setDisplayVehicleCount] = _react.default.useState(0);

    const [tableWidth, setTableWidth] = _react.default.useState(1700);

    const [tableHeight, setTableHeight] = _react.default.useState(255);

    const [leftTableWidth, setLeftTableWidth] = _react.default.useState(500);

    const [rightTableWidth, setRightTableWidth] = _react.default.useState(1700);

    const [leftTableHeight, setLeftTableHeight] = _react.default.useState(255);

    const [rightTableHeight, setRightTableHeight] = _react.default.useState(255);

    const leftTableRef = _react.default.useRef();

    const rightTableRef = _react.default.useRef();

    const tableRef = _react.default.useRef();

    _react.default.useLayoutEffect(() => {
      if (tableRef.current) {
        setTableWidth(tableRef.current.offsetWidth);
        setTableHeight(tableRef.current.offsetHeight);
      }

      if (leftTableRef.current) {
        setLeftTableWidth(leftTableRef.current.offsetWidth);
        setLeftTableHeight(leftTableRef.current.offsetHeight);
      }

      if (rightTableRef.current) {
        setRightTableWidth(rightTableRef.current.offsetWidth);
        setRightTableHeight(rightTableRef.current.offsetHeight);
      }
    });

    _react.default.useEffect(() => {
      if (eventData.length > 0) {
        setDisplayEventCount(eventData.length);
        setEventTotalCount(eventData[0].totalcount);
      } else {
        setDisplayEventCount(0);
        setEventTotalCount(0);
      }
    }, [eventData]);

    _react.default.useEffect(() => {
      if (vehicleData.length > 0) {
        setVehicleTotalCount(vehicleData[0].totalcount);
      } else {
        setDisplayVehicleCount(0);
        setVehicleTotalCount(0);
      }
    }, [vehicleData]);

    return _react.default.createElement(_react.default.Fragment, null, searchMode === _panel.searchModes.byVehicle && _react.default.createElement("div", {
      className: "grid-table-all"
    }, _react.default.createElement("div", {
      className: "playback-widget-table-header"
    }, _react.default.createElement("h5", {
      className: "playback-widget-table-title"
    }, "Vehicle Events (", displayEventCount, "/", eventTotalCount, ")"), _react.default.createElement("div", {
      className: "playback-widget-controls"
    }, _react.default.createElement(_searchButton.default, {
      searchText: eventSearchText,
      setSearchText: setEventSearchText
    }), _react.default.createElement(_actionButton.default, {
      table: _config.default.tableTypes.events
    }), _react.default.createElement(_filterButton.default, {
      table: _config.default.tableTypes.events
    }))), _react.default.createElement("div", {
      className: "table-wrapper playback-widget-table-body",
      ref: tableRef
    }, _react.default.createElement(_playbackTable.default, {
      table: _config.default.tableTypes.events,
      height: tableHeight,
      width: tableWidth,
      setDisplayCount: setDisplayEventCount
    }))), searchMode === _panel.searchModes.byLocation && _react.default.createElement("div", {
      className: "table-wrapper"
    }, _react.default.createElement("div", {
      className: "grid-table-left"
    }, _react.default.createElement("div", {
      className: "playback-widget-table-header"
    }, _react.default.createElement("h5", {
      className: "playback-widget-table-title"
    }, "Vehicles (", displayVehicleCount, "/", vehicleTotalCount, ")"), _react.default.createElement("div", {
      className: "playback-widget-controls"
    }, _react.default.createElement(_searchButton.default, {
      searchText: vehicleSearchText,
      setSearchText: setVehicleSearchText
    }), _react.default.createElement(_actionButton.default, {
      table: _config.default.tableTypes.vehicles
    }), _react.default.createElement(_filterButton.default, {
      table: _config.default.tableTypes.vehicles
    }))), _react.default.createElement("div", {
      className: "table-wrapper playback-widget-table-body",
      ref: leftTableRef
    }, _react.default.createElement(_playbackTable.default, {
      table: _config.default.tableTypes.vehicles,
      height: leftTableHeight,
      width: leftTableWidth,
      setDisplayCount: setDisplayVehicleCount
    }))), _react.default.createElement("div", {
      className: "grid-table-right"
    }, _react.default.createElement("div", {
      className: "playback-widget-table-header"
    }, _react.default.createElement("h5", {
      className: "playback-widget-table-title"
    }, "Vehicle Events (", displayEventCount, "/", eventTotalCount, ")"), _react.default.createElement("div", {
      className: "playback-widget-controls"
    }, _react.default.createElement(_searchButton.default, {
      searchText: eventSearchText,
      setSearchText: setEventSearchText
    }), _react.default.createElement(_actionButton.default, {
      table: _config.default.tableTypes.events
    }), _react.default.createElement(_filterButton.default, {
      table: _config.default.tableTypes.events
    }))), _react.default.createElement("div", {
      className: "table-wrapper playback-widget-table-body",
      ref: rightTableRef
    }, _react.default.createElement(_playbackTable.default, {
      table: _config.default.tableTypes.events,
      height: rightTableHeight,
      width: rightTableWidth,
      setDisplayCount: setDisplayEventCount
    })))));
  };

  var _default = PlaybackTables;
  _exports.default = _default;
});