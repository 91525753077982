define("adept-iq/pods/components/rider-management-widget/activity-log/table/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: null,
    modelName: 'event',
    title: 'Activity',
    defaultSortId: 'time',
    defaultSortAsc: false,
    queryModelData: true,
    showSelectedRowLength: false,
    columns: [{
      id: 'activity',
      index: 0,
      type: 'text',
      label: 'Activity',
      defaultWidth: 140,
      sortable: true,
      valuePath: 'collectedEventLog.firstObject.activity',
      searchable: true
    }, {
      id: 'time',
      index: 1,
      type: 'date',
      label: 'Date / Time',
      defaultWidth: 160,
      format: `${_environment.default.dateTimeFormat.dateTimeMoment}`,
      valuePath: 'time',
      searchable: true,
      sortable: true
    }, {
      id: 'detail',
      index: 2,
      type: 'text',
      label: 'Text',
      defaultWidth: 800,
      sortable: true,
      searchable: true,
      valuePath: 'collectedEventLog.firstObject.details'
    }]
  };
  _exports.default = _default;
});