define("adept-iq/pods/components/iq-widgets/drivers-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/drivers-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'driver',
    defaultSortId: 'driverId',
    defaultSortAsc: false,
    title: 'Drivers',
    // ember-react-widget properties
    widgetName: 'driverWidget',
    initialLimit: 5000,
    paginationLimit: 20,
    maxLimitByRouteSelection: 5,
    maxLimitWithoutRouteAssociation: 20,
    selectedRowLimit: 20,
    columns: [{
      id: 'driverId',
      index: 0,
      type: 'uuid',
      label: 'ID',
      valuePath: 'driverId',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'lastName',
      index: 1,
      type: 'text',
      label: 'Last Name',
      valuePath: 'lastName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 125
    }, {
      id: 'firstName',
      index: 2,
      type: 'text',
      label: 'First Name',
      valuePath: 'firstName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 125
    }, {
      id: 'availability',
      index: 4,
      type: 'enum-date',
      label: 'Availability',
      valuePath: 'availability',
      cellDesc: 'Availabilities',
      editable: false,
      hidden: true,
      cellItem: 'AvailabilityCell'
    }, {
      id: 'active',
      index: 5,
      type: 'boolean',
      label: 'Active',
      valuePath: 'active',
      editable: true,
      hidden: true,
      defaultWidth: 50
    }],
    traversal: {
      tripWidget: {
        links: [{
          type: 'exist',
          field: 'routeStartTime',
          valuePath: 'routeId'
        }]
      },
      routeWidget: {
        links: [{
          type: 'exist',
          field: 'routeStartTime',
          valuePath: 'routeId'
        }]
      },
      alertWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'driverPk'
        }]
      },
      stopWidget: {
        links: [{
          type: 'exist',
          field: 'routeStartTime',
          valuePath: 'routeId'
        }]
      },
      messageWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'vehicleId'
        }]
      }
    }
  };
  _exports.default = _default;
});