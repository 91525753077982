define("adept-iq/tests/factories/avlm-trip", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const tripStatus = ['A', 'Waitlisted'];

  _emberDataFactoryGuy.default.define('avlm-trip', {
    sequences: {
      requestTimes: () => (0, _moment.default)().startOf('day').add(8, 'hours').toDate(),
      tripStatuses: num => tripStatus[num % tripStatus.length],
      externalIds: num => num
    },
    default: {
      requestedTime: _emberDataFactoryGuy.default.generate('requestTimes'),
      status: _emberDataFactoryGuy.default.generate('tripStatuses'),
      otp: 'L',
      otpValue: 234,
      fare: '3',
      noShowCodeId: '234',
      externalId: _emberDataFactoryGuy.default.generate('externalIds'),
      otpStatus: 'Late'
    }
  });
});