define("adept-iq/utils/widget-placement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computeMask = computeMask;
  _exports.computeMaxSpacePairs = computeMaxSpacePairs;
  _exports.computeWidgetPlacements = computeWidgetPlacements;

  /**
   * Computes a list of possible locations that will fit a widget of specified
   * minimum width and height; scans left to right, then down the rows.
   *
   * @param {Dashboard} dashboard
   * @param {integer} gridWidth
   * @param {integer} minWidth
   * @param {integer} minHeight
   */

  /**
   * Computes max width and height for a widget placed at each location in mask.
   *
   * @param {Array} mask
   */
  function computeMaxSpacePairs(mask) {
    const width = mask.length;
    const pairs = [];

    for (let i = width - 1; i >= 0; i--) {
      const height = mask[i].length;
      pairs[i] = [];

      for (let j = height - 1; j >= 0; j--) {
        let maxWidth, maxHeight;

        if (mask[i][j]) {
          maxWidth = 0;
        } else if (i === width - 1) {
          maxWidth = 1;
        } else {
          maxWidth = pairs[i + 1][j][0] + 1;
        }

        if (mask[i][j]) {
          maxHeight = 0;
        } else if (j === height - 1) {
          maxHeight = Infinity;
        } else {
          maxHeight = pairs[i][j + 1][1] + 1;
        }

        pairs[i][j] = [maxWidth, maxHeight];
      }
    }

    return pairs;
  }
  /**
   * Computes an array mask with non-zero value iff grid location is occupied.
   *
   * @param {Dashboard} dashboard
   * @param {integer} gridWidth
   */


  function computeMask(dashboard, gridWidth) {
    const widgets = dashboard.get('widgets'); // find horiz limit of existing widgets

    let xMax = widgets.reduce((max, widget) => {
      const x = widget.get('x');
      const width = widget.get('width');
      return Math.max(max, x + width);
    }, 0); // grid may be wider than right-most widget's edge

    xMax = Math.max(xMax, gridWidth); // allow one extra row below bottom-most widget

    const yMax = 1 + widgets.reduce((max, widget) => {
      const y = widget.get('y');
      const height = widget.get('height');
      return Math.max(max, y + height);
    }, 0); // allow one extra row in mask

    const mask = [];

    for (let i = 0; i < xMax; i++) {
      mask[i] = [];

      for (let j = 0; j < yMax; j++) {
        mask[i][j] = 0;
      }
    } // shade values for existing widgets in layout


    widgets.forEach(widget => {
      const {
        x,
        y,
        height,
        width
      } = widget.getProperties(['x', 'y', 'height', 'width']);

      for (let i = x; i < x + width; i++) {
        for (let j = y; j < y + height; j++) {
          mask[i][j] = 1;
        }
      }
    });
    return mask;
  }

  function computeWidgetPlacements(dashboard, gridWidth, minWidth, minHeight) {
    const mask = computeMask(dashboard, gridWidth);
    const freeSpacePairs = computeMaxSpacePairs(mask);
    const locations = [];
    const height = mask[0].length;

    for (let j = 0; j < height; j++) {
      for (let i = 0; i < gridWidth; i++) {
        const [maxWidth, maxHeight] = freeSpacePairs[i][j];

        if (maxWidth >= minWidth && maxHeight >= minHeight) {
          locations.push({
            x: i,
            y: j,
            maxWidth,
            maxHeight
          });
        }
      }
    }

    return locations;
  }
});