define("adept-iq/adapters/rider-external", ["exports", "ember-data", "adept-iq/config/api-urls"], function (_exports, _emberData, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTAdapter = _emberData.default.RESTAdapter;

  var _default = RESTAdapter.extend({
    host: _apiUrls.API.riderManagementService.host,
    session: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax'),

    pathForType() {
      return 'nyct-rider-long';
    },

    urlForQuery() {
      return `${_apiUrls.API.bookingService.host}/rider-external/ShortVer?`;
    },

    queryRecord(store, type, id) {
      const session = this.get('session');
      return this.get('ajaxService').request(_apiUrls.API.riderManagementService.host + '/nyct-rider-long/' + id, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    query(store, type, params) {
      const session = this.get('session');
      return this.get('ajaxService').request(_apiUrls.API.riderManagementService.host + '/nyct-rider-short?' + params, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    queryRiderShort(query) {
      const session = this.get('session');
      return this.get('ajaxService').request(_apiUrls.API.riderManagementService.host + '/nyct-rider-short?' + query, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    headers: Ember.computed('session.data.authenticated.token', function () {
      const session = this.get('session');
      return {
        'Authorization': `Bearer ${session.data.authenticated.token}`
      };
    })
  });

  _exports.default = _default;
});