define("adept-iq/pods/components/side-drawer/blockout-alternative-addresses/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editableSections: null,
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    errors: null,
    alternativeAddresses: null,
    selectedAddress: null,
    leg: Ember.computed.readOnly('stackFrame.options.leg'),
    place: Ember.computed.readOnly('stackFrame.options.place'),
    message: Ember.computed.readOnly('stackFrame.options.message'),
    blockoutRegion: Ember.computed.readOnly('stackFrame.options.blockoutRegion'),
    showAltAddressMarker: Ember.computed.readOnly('stackFrame.options.showAltAddressMarker'),
    cancelAlternativeAddressOption: Ember.computed.readOnly('stackFrame.options.cancelAlternativeAddressOption'),
    updateAlternativeAddressSelection: Ember.computed.readOnly('stackFrame.options.updateAlternativeAddressSelection'),
    noSelection: Ember.computed('selectedAddress', function () {
      const selectedAddress = this.get('selectedAddress');
      return Ember.isEmpty(selectedAddress);
    }),

    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.setDefaultData();
      this.get('systemConfig').set('targetAttachment', 'right');
    },

    willDestroyElement() {
      this._super(...arguments);

      this.get('systemConfig').set('targetAttachment');
    },

    setDefaultData() {
      const blockoutRegion = this.get('blockoutRegion');
      const blockoutAlternativeAddresses = blockoutRegion.get('value.addresses') || [];
      const addresses = [];
      blockoutAlternativeAddresses.forEach(blockoutAlternativeAddress => {
        const fullAddress = [];

        if (Ember.isPresent(blockoutAlternativeAddress.streetNumber)) {
          fullAddress.push(`${blockoutAlternativeAddress.streetNumber} ${blockoutAlternativeAddress.streetAddress}`);
        } else {
          fullAddress.push(blockoutAlternativeAddress.streetAddress);
        }

        fullAddress.push(blockoutAlternativeAddress.locality);
        fullAddress.push(blockoutAlternativeAddress.region);
        fullAddress.push(blockoutAlternativeAddress.postalCode);
        fullAddress.push(blockoutAlternativeAddress.country);
        addresses.push(Ember.Object.create({
          streetNumber: blockoutAlternativeAddress.streetNumber,
          streetAddress: blockoutAlternativeAddress.streetAddress,
          region: blockoutAlternativeAddress.region,
          locality: blockoutAlternativeAddress.locality,
          postalCode: blockoutAlternativeAddress.postalCode,
          lat: blockoutAlternativeAddress.lat,
          lng: blockoutAlternativeAddress.lng,
          country: blockoutAlternativeAddress.country,
          fullAddress: fullAddress.join(', ')
        }));
      });
      this.set('alternativeAddresses', addresses);
    },

    addClassForSelectedAddress(selectedAddressIndex) {
      const addressRowClass = '.address-row';
      const selectedAddressClass = `.selectedAddress${selectedAddressIndex}`;
      const selectedAddress = Ember.$(selectedAddressClass);
      const addressRows = Ember.$(addressRowClass);
      addressRows.removeClass('selected');
      selectedAddress.addClass('selected');
    },

    actions: {
      onCloseClick() {
        const place = this.get('place');
        this.get('systemConfig').set('targetAttachment');
        this.cancelAlternativeAddressOption(place);
        this.onCloseClick();
      },

      onAddressSelect(address, index) {
        this.set('selectedAddress', address);
        this.addClassForSelectedAddress(index);
        this.showAltAddressMarker(address);
      },

      onConfirmClick() {
        const selectedAddress = this.get('selectedAddress');
        const place = this.get('place');

        if (Ember.isPresent(selectedAddress)) {
          this.updateAlternativeAddressSelection(selectedAddress, place);
          this.get('systemConfig').set('targetAttachment');
          this.cancelAlternativeAddressOption(place);
          this.onCloseClick();
        }
      },

      onCancelClick() {
        const place = this.get('place');
        this.get('systemConfig').set('targetAttachment');
        this.cancelAlternativeAddressOption(place);
        this.onCloseClick();
      }

    }
  });

  _exports.default = _default;
});