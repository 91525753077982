define("adept-iq/models/reconcile-stop", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    stopId: attr('string'),
    status: attr('string'),
    type: attr('string'),
    eta: attr('date'),
    otpValue: attr('number'),
    otpStatus: attr('string'),
    anchor: attr('boolean'),
    startTime: attr('date'),
    endTime: attr('date'),
    arriveTime: attr('date'),
    promiseTime: attr('date'),
    requestTime: attr('date'),
    actualArriveTime: attr('date'),
    //for stops
    actualDepartTime: attr('date'),
    //for stops
    performed: attr('string'),
    arriveType: attr('string'),
    breakType: attr('string'),
    tripId: attr('number'),
    routeId: attr('string'),
    ordinal: attr('number'),
    odometerArrival: attr('number'),
    odometerDeparture: attr('number'),
    // Relationship bindings
    address: attr('object'),
    // to be changed to belongsTo reconcile-address
    usedAttributes: hasMany('reconcile-used-attribute'),
    // It is to be updated when proper data mapping is received
    isWheelchair: Ember.computed('usedAttributes', function () {
      return false;
    }),
    stopTime: Ember.computed('actualArriveTime', 'actualDepartTime', function () {
      const startTime = Ember.get(this, 'actualArriveTime');
      const endTime = Ember.get(this, 'actualDepartTime');
      let mins = 0;

      if (startTime && endTime && (0, _moment.default)(startTime).isBefore((0, _moment.default)(endTime))) {
        const duration = _moment.default.duration((0, _moment.default)(endTime).diff((0, _moment.default)(startTime)));

        mins = duration.asMinutes();
      }

      return mins;
    }),
    stopType: Ember.computed('status', 'stopId', function () {
      const id = this.get('stopId');
      const status = this.get('status');

      if (id.includes('DP') || status === 'DP') {
        return 'deployStop';
      } else if (id.includes('P') || status === 'P') {
        return 'pick';
      } else if (id.includes('D') || status === 'D') {
        return 'drop';
      } else if (id.includes('GP') || status === 'GP') {
        return 'pulloutGarage';
      } else if (id.includes('GD') || status === 'GD') {
        return 'pullinGarage';
      } else if (id.includes('B') || status === 'B') {
        return 'break';
      }

      return null;
    }),
    mapId: Ember.computed('status', 'stopType', function () {
      const status = this.get('status');
      const stopType = this.get('stopType');
      const isWheelchair = this.get('isWheelchair');
      let id = null;

      if (status === 'Depart') {
        id = 'performedStop';
        return id;
      }

      if (isWheelchair) {
        id = 'wheelchair';
        return id;
      }

      if (status === 'No Show') {
        id = 'noshow';
        return id;
      }

      switch (stopType) {
        case 'break':
          id = 'break';
          break;

        case 'pick':
          id = 'pick';
          break;

        case 'drop':
          id = 'drop';
          break;

        case 'deployStop':
          id = 'deployStop';
          break;

        case 'pulloutGarage':
          id = 'pulloutGarage';
          break;

        case 'pullinGarage':
          id = 'pullinGarage';
          break;

        default:
          break;
      }

      return id;
    }),
    formattedActualArriveTime: Ember.computed('actualArriveTime', function () {
      const dt = Ember.get(this, 'actualArriveTime');
      return dt ? (0, _moment.default)(dt).format('MM/DD/YYYY hh:mm A') : '';
    }),
    formattedActualDepartTime: Ember.computed('actualDepartTime', function () {
      const dt = Ember.get(this, 'actualDepartTime');
      return dt ? (0, _moment.default)(dt).format('MM/DD/YYYY hh:mm A') : '';
    }),
    formattedPromiseTime: Ember.computed('promiseTime', function () {
      const dt = Ember.get(this, 'promiseTime');
      return dt ? (0, _moment.default)(dt).format('MM/DD/YYYY hh:mm A') : '';
    }),
    formattedPromiseTimeAsTime: Ember.computed('promiseTime', function () {
      const dt = Ember.get(this, 'promiseTime');
      return dt ? (0, _moment.default)(dt).format('hh:mm A') : '';
    }),
    formattedRequestTimeAsTime: Ember.computed('requestTime', function () {
      const dt = Ember.get(this, 'requestTime');
      return dt ? (0, _moment.default)(dt).format('hh:mm A') : '';
    }),
    formattedDate: Ember.computed('actualArriveTime', function () {
      const dt = Ember.get(this, 'actualArriveTime');
      return dt ? (0, _moment.default)(dt).format('MM/DD/YYYY') : '';
    }),
    formattedEta: Ember.computed('eta', function () {
      const dt = Ember.get(this, 'eta');
      return dt ? (0, _moment.default)(dt).format('MM/DD/YYYY hh:mm A') : '';
    }),
    formattedEtaAsTime: Ember.computed('eta', function () {
      const dt = Ember.get(this, 'eta');
      return dt ? (0, _moment.default)(dt).format('hh:mm A') : '';
    })
  });

  _exports.default = _default;
});