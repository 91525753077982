define("adept-iq/pods/components/reoptimize-modal/operation-views/consolidate-routes/component", ["exports", "ember-concurrency", "moment", "adept-iq/mixins/async-schedule-operation"], function (_exports, _emberConcurrency, _moment, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    classNames: ['consolidate-routes'],
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    isTaskRunning: Ember.computed.alias('consolidateRoutes.isRunning'),
    // Passed In Attributes
    title: null,
    currentSchedule: null,
    tableRef: null,
    refreshData: null,
    checkedRows: Ember.computed.alias('tableRef.checkedRows'),
    disableConsolidateRoutesButton: Ember.computed.lt('checkedRows.length', 1),

    init() {
      this._super(...arguments);
    },

    // be able to adjust from selected rows
    consolidateRoutes: (0, _emberConcurrency.task)(function* (routes) {
      const currentSchedule = this.get('currentSchedule');
      const routeIds = routes.map(route => parseInt(route.get('id'), 10));
      let result;

      try {
        const options = {
          operationData: {
            'routes': routeIds
          }
        };
        result = yield this.createScheduleAsyncOperation.perform(currentSchedule, 'routeConsolidation', options);

        if (result) {
          this.get('notifications').success('Short routes successfully consolidated.');
          return routes.forEach(async record => {
            record.set('isAdjusted', true);

            try {
              const activityLogAdapter = this.get('store').adapterFor('activity-log');
              const userInfo = {
                content: {
                  details: 'Route was consolidated as part of scheduling.',
                  activity: 'Consolidate',
                  userId: this.session.data.authenticated.userId,
                  actualTime: (0, _moment.default)().toDate(),
                  type: 'genericLogging'
                }
              };
              const routeInfo = Ember.Object.create({
                context: {
                  route: {
                    schedule: {
                      id: currentSchedule.get('id')
                    },
                    id: record.get('id')
                  }
                }
              });
              await activityLogAdapter.createRouteActivity(userInfo, routeInfo);
            } catch (err) {
              return Promise.resolve();
            }
          });
        }

        this.get('notifications').warning('Short routes failed to be consolidated.');
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          this.get('notifications').warning('Short routes failed to be consolidated.');
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),
    actions: {
      onConsolidateRoutesClick() {
        const routes = this.get('checkedRows') || [];
        this.consolidateRoutes.perform(routes);
      }

    }
  });

  _exports.default = _default;
});