define("adept-iq/models/avlm-rider", ["exports", "ember-data", "adept-iq/utils/format-text-extension"], function (_exports, _emberData, _formatTextExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    firstName: attr('string'),
    middleName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    bookingClientId: attr('string'),
    phoneNumbers: hasMany('avlm-phone-number'),
    passengerType: belongsTo('passenger-type'),
    avlmRiderEvents: hasMany('avlm-rider-event'),
    anchoredRiderEvent: Ember.computed('avlmRiderEvents.@each.anchor', function () {
      const anchorRiderEvent = this.get('avlmRiderEvents').filterBy('anchor', 'Y').firstObject;
      if (!anchorRiderEvent) return [];
      return anchorRiderEvent;
    }),
    anchorStop: Ember.computed('anchoredRiderEvent', function () {
      const anchorRiderEvent = this.get('anchoredRiderEvent');

      if (anchorRiderEvent.type === 'P') {
        return 'Pick';
      }

      return 'Drop';
    }),
    formattedPhoneNumbers: Ember.computed('phoneNumbers.[]', function () {
      const phoneNumbers = [];
      this.get('phoneNumbers').forEach(function (item) {
        phoneNumbers.push((0, _formatTextExtension.formatTextExtension)('valueItem', `${item.type}: ${item.get('formattedPhoneNumber')}`));
      });
      return {
        values: phoneNumbers
      };
    })
  });

  _exports.default = _default;
});