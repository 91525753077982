define("adept-iq/tests/factories/vehicle-type", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const vehicleCategories = ['Revenge', 'DeadReckoning', 'Bus', 'Taxi', 'Car'];

  _emberDataFactoryGuy.default.define('vehicle-type', {
    sequences: {
      vehicleTypes: num => vehicleCategories[num % vehicleCategories.length]
    },
    default: {
      name: _emberDataFactoryGuy.default.generate('vehicleTypes')
    }
  });
});