define("adept-iq/pods/components/side-drawer/compose-role/permission-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AxH5JBYk",
    "block": "{\"symbols\":[\"permission\"],\"statements\":[[7,\"div\"],[11,\"class\",\"permissions-table\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"permission-row\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"cell-label\"],[9],[0,\"Enable / Disable \"],[1,[21,\"category\"],false],[0,\" permissions\"],[10],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"class\",\"type\",\"checked\",\"click\"],[\"permission-input\",\"checkbox\",[23,[\"toggleAll\"]],[27,\"action\",[[22,0,[]],\"onToggleAll\",[23,[\"toggleAll\"]],[23,[\"permissions\"]],[23,[\"valuePath\"]]],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"permissions\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"permission-row\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"cell-label\"],[9],[1,[22,1,[\"description\"]],false],[10],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"class\",\"type\",\"checked\",\"click\"],[\"permission-input\",\"checkbox\",[27,\"get\",[[23,[\"rolePermHash\"]],[22,1,[\"parsedId\"]]],null],[27,\"action\",[[22,0,[]],\"onCheckboxChange\",[22,1,[]],[23,[\"valuePath\"]]],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/side-drawer/compose-role/permission-section/template.hbs"
    }
  });

  _exports.default = _default;
});