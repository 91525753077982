define("adept-iq/mixins/disable-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isDisabled: Ember.computed('status', function () {
      const status = this.get('status');

      if (status) {
        switch (status.toUpperCase()) {
          case 'ACTIVE':
            return false;

          case 'PENDING':
            return false;

          default:
            return true;
        }
      }

      return true;
    })
  });

  _exports.default = _default;
});