define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/gridwidget", ["exports", "@babel/runtime/helpers/esm/extends", "react", "react-dom", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cellrenderer", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/grid-context-menu", "react-virtualized", "react-sortable-hoc", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/row-range-renderer"], function (_exports, _extends2, _react, _reactDom, _cellrenderer, _gridContextMenu, _reactVirtualized, _reactSortableHoc, _rowRangeRenderer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable prefer-const */

  /* eslint-disable no-unused-vars */

  /* eslint-disable no-unused-expressions */
  const createRef = _react.default.createRef;
  const AutoSizer = _reactVirtualized.default.AutoSizer;
  const MultiGrid = _reactVirtualized.default.MultiGrid;
  const Grid = _reactVirtualized.default.Grid;
  const InfiniteLoader = _reactVirtualized.default.InfiniteLoader;
  const ArrowKeyStepper = _reactVirtualized.default.ArrowKeyStepper;

  const SortableMultiGrid = _reactSortableHoc.default.sortableContainer(MultiGrid, {
    withRef: true
  });

  const Row = _ref => {
    let {
      children,
      style
    } = _ref;
    return _react.default.createElement("div", {
      className: "SortableMultiGrid__Row",
      style: style
    }, children);
  };

  const SortableRow = _reactSortableHoc.default.sortableElement(Row);

  const arrayMove = _reactSortableHoc.default.arrayMove;
  const sortableRowRenderer = (0, _rowRangeRenderer.rowRangeRenderer)(props => _react.default.createElement(SortableRow, (0, _extends2.default)({
    key: props.rowIndex
  }, props, {
    index: props.rowIndex
  })));
  const fixedRowRenderer = (0, _rowRangeRenderer.rowRangeRenderer)(props => _react.default.createElement(Row, (0, _extends2.default)({
    key: props.rowIndex
  }, props, {
    index: props.rowIndex
  })));
  const DEFAULT_COLUMN_WIDTH = 100; // ToDO

  const CHECKBOX_COLUMN_WIDTH = 25; // ToDO

  const GEARCOG_COLUMN_WIDTH = 35; // ToDO

  const MIN_COLUMN_WIDTH = 50;
  const BATCH_SIZE = 20;
  const LOADER_THRESHOLD = 20;
  const initialContextMenuState = {
    rowIndex: -1,
    //
    columnIndex: -1,
    positionX: 0,
    positionY: 0,
    visible: false
  };

  class GridWidget extends _react.default.Component {
    constructor(props) {
      super(props);
      const {
        config,
        visibleColumns,
        widgetState,
        modelName,
        isMaximized,
        isTraversalEnabled,
        selectAllChecked
      } = props;
      const widgetStateSortedColumn = config.columns.filter(cfg => cfg.id === widgetState.sortId);
      let sortId = config.defaultSortId || 'id';

      if (!Ember.isEmpty(widgetStateSortedColumn)) {
        sortId = widgetStateSortedColumn[0].id;
      }

      this.state = {
        data: [],
        loading: false,
        columns: visibleColumns,
        columnwidths: {},
        resizing: false,
        selectedRowLimit: config.selectedRowLimit,
        sortId: sortId,
        sortAsc: widgetState.sortAsc || config.defaultSortAsc || true,
        reordering: false,
        contextMenuState: initialContextMenuState,
        checkedRowIds: props.checkedRowIds,
        disabledRowIds: props.disabledRowIds,
        hiddenRowIds: props.hiddenRowIds,
        isFurtherSelectionAllowed: props.isFurtherSelectionAllowed,
        selectedRowIndex: -1,
        selectedColumnIndex: -1,
        width: props.width,
        height: props.height,
        widgetInstanceId: props.widgetInstanceId,
        showGearIcon: props.showGearIcon,
        useTabletWidth: props.useTabletWidth,
        isMaximized,
        isTraversalEnabled,
        selectAllChecked
      };
      this.config = config;
      this.isArrowKeyPressing = false;
      this.preScrollToRow = -1;
      this.preScrollToColumn = -1;
      this.arrowKeyPressingTimeoutId = null;
      this.arrowKeyCellCache = {};
      this.domNode = null;
      this.multiGridRef = null;
      this.infiniteLoaderRef = null;
      this.headerCellClickHandler = this.headerCellClickHandler.bind(this);
      this.resizeRow = this.resizeRow.bind(this);
      this.getColumnWidth = this.getColumnWidth.bind(this);
      this._cellRenderer = this._cellRenderer.bind(this);
      this._rowRenderer = this._rowRenderer.bind(this);
      this._getSortableContainer = this._getSortableContainer.bind(this);
      this.resizeStop = this.resizeStop.bind(this);
      this.reorderRows = this.reorderRows.bind(this);
      this.onContextMenuClick = this.onContextMenuClick.bind(this);
      this.onTableCellClick = this.onTableCellClick.bind(this);
      this.menuItemCallback = this.menuItemCallback.bind(this);
      this._infiniteLoaderChildFunction = this._infiniteLoaderChildFunction.bind(this);
      this.isRowLoaded = this.isRowLoaded.bind(this);
      this.checkBtnClickHandler = this.checkBtnClickHandler.bind(this);
      this.checkBtnAllClickHandler = this.checkBtnAllClickHandler.bind(this);
      this._selectCell = this._selectCell.bind(this);
      this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
      this.documentClickHandler = this.documentClickHandler.bind(this);
      this.loadMoreRowsHandler = this.loadMoreRowsHandler.bind(this);
      this.cellRightClickForTablet = this.cellRightClickForTablet.bind(this);
      this.documentRightClickHandler = this.documentRightClickHandler.bind(this);
      this.init();
    }

    init() {
      let columnIncrementerValue = 1;
      const {
        widgetState
      } = this.props;
      const {
        columnwidths,
        columns,
        showGearIcon,
        useTabletWidth
      } = this.state;
      columnwidths[0] = CHECKBOX_COLUMN_WIDTH;

      if (showGearIcon) {
        columnwidths[1] = GEARCOG_COLUMN_WIDTH;
        columnIncrementerValue = 2;
      }

      columns.forEach((column, index) => {
        const savedWidth = widgetState.columns && widgetState.columns[column.id] ? widgetState.columns[column.id].width : null;
        const configWidth = useTabletWidth && column.defaultTabletWidth ? column.defaultTabletWidth : column.defaultWidth;
        const defaultWidth = savedWidth || configWidth;
        columnwidths[index + columnIncrementerValue] = defaultWidth || DEFAULT_COLUMN_WIDTH;
      });
    }

    onArrowKeyPress(scrollToRow, scrollToColumn) {
      if (scrollToRow >= 0 && (this.preScrollToRow !== scrollToRow || this.preScrollToColumn !== scrollToColumn)) {
        if (this.arrowKeyPressingTimeoutId) {
          clearTimeout(this.arrowKeyPressingTimeoutId);
          this.arrowKeyPressingTimeoutId = null;
        }

        this.preScrollToRow = scrollToRow;
        this.preScrollToColumn = scrollToColumn;
        this.isArrowKeyPressing = true;
        this.arrowKeyPressingTimeoutId = setTimeout(() => {
          if (this.isArrowKeyPressing) {
            this.isArrowKeyPressing = false;
          }

          this.arrowKeyPressingTimeoutId = null;
        }, 200);
      }
    }

    onKeyPressHandler(event) {
      if (event.keyCode === 32) {
        // space key
        this.checkBtnClickHandler(this.state.selectedRowIndex);
        event.preventDefault();
      }
    }

    componentDidMount() {}

    componentWillUnmount() {}

    documentClickHandler(event) {
      if (this.isClickedOutsideContainer(event)) {
        this.hideContextMenu();
      }
    }

    documentRightClickHandler(event) {
      this.hideContextMenu();
    } // check mouse is clicked outside the widget container


    isClickedOutsideContainer(event) {
      const $target = Ember.$(event.target);
      return !$target.closest('.global-dropdown-context').length;
    }

    loadMoreRowsHandler(_ref2) {
      let {
        startIndex,
        stopIndex
      } = _ref2;
      const {
        onLazyLoader
      } = this.props;
      const {
        sortId,
        sortAsc
      } = this.state;
      const pageSize = stopIndex - startIndex + 1;
      const offset = stopIndex;

      if (offset > pageSize) {
        onLazyLoader(offset, pageSize, sortId, sortAsc);
      }

      this.setState({
        arrowKeyRow: null
      });
    }

    menuItemCallback(_ref3) {
      let {
        name,
        action
      } = _ref3;
      this.hideContextMenu();
      const {
        contextMenuState,
        data,
        checkedRowIds
      } = this.state;
      const {
        fetchModelbyWidgetRecord,
        fetchModelsbyCheckedRowIds
      } = this.props;
      const {
        rowIndex
      } = contextMenuState;

      if (checkedRowIds.length > 1) {
        const selectedRowData = data.length ? this.getRowData(data, rowIndex) : null;
        const selectedModel = fetchModelbyWidgetRecord(selectedRowData);
        const selectedModels = fetchModelsbyCheckedRowIds(checkedRowIds);
        const selectedInCheckedRowIds = checkedRowIds.find(currentId => {
          return currentId.id === selectedRowData.id;
        }); // check if selected row was the one right clicked

        if (Ember.isPresent(selectedInCheckedRowIds)) {
          action && action(selectedModels);
        } else {
          action && Ember.isPresent(selectedModel) && action(selectedModel);
        }
      } else {
        const selectedRowData = data.length ? this.getRowData(data, rowIndex) : null;
        const selectedModel = fetchModelbyWidgetRecord(selectedRowData);
        action && Ember.isPresent(selectedModel) && action(selectedModel);
      }
    }

    onContextMenuClick(event, rowIndex, columnIndex) {
      event.stopPropagation();
      this.setState({
        selectedRowIndex: rowIndex,
        selectedColumnIndex: columnIndex
      }, () => {
        this.multiGridRef.forceUpdateGrids();
      });
      const {
        onRowRightClick
      } = this.props;
      if (!onRowRightClick) return;
      const parentContainerCssClass = `.${GridWidget.containerCssClass}`;
      const widgetContainerOffset = Ember.$(event.target).closest(parentContainerCssClass).offset(); // const positionX = window.pageXOffset + event.clientX - widgetContainerOffset.left;
      // const positionY = window.pageYOffset + event.clientY - widgetContainerOffset.top + 50;

      const positionX = window.pageXOffset + event.clientX;
      const positionY = window.pageYOffset + event.clientY;
      const {
        data
      } = this.state;
      const selectedRowData = data.length ? this.getRowData(data, rowIndex) : null;
      onRowRightClick(selectedRowData).then(menusItems => {
        this.updateContextMenuState({
          rowIndex,
          columnIndex,
          positionX,
          positionY,
          menusItems,
          visible: true
        }); // Avoid duplicate event lister, so remove it before add a new one.

        document.removeEventListener('click', this.documentClickHandler, false);
        document.addEventListener('click', this.documentClickHandler);
        document.removeEventListener('contextmenu', this.documentRightClickHandler, false);
        document.addEventListener('contextmenu', this.documentRightClickHandler);
      });
    }

    updateContextMenuState(updatedState) {
      const contextMenuState = { ...this.state.contextMenuState,
        ...updatedState
      };
      this.setState({
        contextMenuState
      }, () => {
        this.multiGridRef.forceUpdateGrids();
      });
    }

    onTableCellClick(selectedRowIndex, selectedColumnIndex) {
      this.setState({
        selectedRowIndex,
        selectedColumnIndex
      }, () => this.multiGridRef.forceUpdateGrids());
      this.hideContextMenu();
    }

    hideContextMenu() {
      if (this.state.contextMenuState !== initialContextMenuState) {
        this.setState({
          contextMenuState: initialContextMenuState
        }, () => {
          this.multiGridRef.forceUpdateGrids();
          document.removeEventListener('click', this.documentClickHandler, false);
          document.removeEventListener('contextmenu', this.documentRightClickHandler, false);
        });
      }
    }
    /**
     * This is the method that will be called by components using
     * this Widget (Since the external system is non-react we cant rerender by passing the data as props
     * @param data
     */


    updateWidgetData(widgetData) {
      const {
        hiddenRowIds
      } = this.state;
      const data = widgetData.filter(_ref4 => {
        let {
          id
        } = _ref4;
        return !hiddenRowIds.includes(id);
      });
      this.setState({
        data
      }, () => {
        this.multiGridRef && this.multiGridRef.forceUpdateGrids();
      });
    }

    updateVisibleColumns(visibleColumns) {
      const columnwidths = this.calculateColumnWidth(visibleColumns);
      this.setState({
        columns: visibleColumns,
        columnwidths
      }, () => {
        this.multiGridRef && this.multiGridRef.forceUpdateGrids();
      });
    }

    calculateColumnWidth(visibleColumns) {
      let columnIncrementerValue = 1;
      let {
        columnwidths,
        showGearIcon,
        useTabletWidth
      } = this.state;
      const {
        widgetState
      } = this.props;
      const calculatedColumnWidthsCount = Object.keys(columnwidths).length;
      const visibleColumnWidthCount = visibleColumns.length + (showGearIcon ? 2 : 1);

      if (calculatedColumnWidthsCount !== visibleColumnWidthCount) {
        columnwidths = {};
      } // update columns width


      columnwidths[0] = CHECKBOX_COLUMN_WIDTH;

      if (showGearIcon) {
        columnwidths[1] = GEARCOG_COLUMN_WIDTH;
        columnIncrementerValue = 2;
      }

      visibleColumns.forEach((column, index) => {
        const savedWidth = widgetState.columns && widgetState.columns[column.id] ? widgetState.columns[column.id].width : null;
        const configWidth = useTabletWidth && column.defaultTabletWidth ? column.defaultTabletWidth : column.defaultWidth;
        const defaultWidth = savedWidth || configWidth || DEFAULT_COLUMN_WIDTH;
        columnwidths[index + columnIncrementerValue] = columnwidths[index + columnIncrementerValue] || defaultWidth;
      });
      return columnwidths;
    }

    updateSelectionDataAndVisibility(widgetData, checkedRowIds, disabledRowIds, isFurtherSelectionAllowed, hiddenRowIds, visibleColumns, totalWidgetCount, isTraversalEnabled, selectAllChecked) {
      const data = widgetData.filter(_ref5 => {
        let {
          id
        } = _ref5;
        return !hiddenRowIds.includes(id);
      });
      const columnwidths = this.calculateColumnWidth(visibleColumns);
      const updatedState = {
        data,
        columns: visibleColumns,
        columnwidths,
        checkedRowIds: checkedRowIds,
        disabledRowIds: disabledRowIds,
        isFurtherSelectionAllowed: isFurtherSelectionAllowed,
        hiddenRowIds,
        isTraversalEnabled,
        selectAllChecked,
        totalWidgetCount
      };
      this.setState(updatedState, () => {
        this.multiGridRef && this.multiGridRef.recomputeGridSize() && this.multiGridRef.forceUpdateGrids();
      });
    }

    resizeRow(_ref6) {
      let {
        columnIndex,
        deltaX
      } = _ref6;
      this.setState(prevState => {
        const {
          columnwidths,
          columns,
          showGearIcon
        } = prevState;
        let columnWidth = columnwidths[columnIndex];
        columnWidth = Math.max(MIN_COLUMN_WIDTH, columnWidth + deltaX);
        columnwidths[columnIndex] = columnWidth; // update dashboard widget state

        const index = showGearIcon ? columnIndex - 2 : columnIndex - 1;
        const columnId = columns[index].id;
        const {
          onWidgetStateUpdateHandler
        } = this.props;
        const _columns = {};
        _columns[columnId] = {
          width: columnWidth
        };
        onWidgetStateUpdateHandler({
          columns: _columns
        });
        return {
          columnwidths,
          resizing: true
        };
      }, () => {
        this.multiGridRef.recomputeGridSize({
          columnIndex
        });
      });
    }

    resizeStop() {
      this.setState({
        resizing: false
      }, () => {
        this.multiGridRef.forceUpdateGrids();
      });
    }

    reorderRows(oldIndex, newIndex) {
      const {
        rowReorderHandler
      } = this.props;
      const cache = [...this.state.data];
      this.setState({
        data: arrayMove(this.state.data, oldIndex, newIndex),
        cache
      }, () => {
        this.multiGridRef.forceUpdateGrids();
        rowReorderHandler(oldIndex, newIndex);
      });
    }

    rollbackCache() {
      const {
        cache
      } = this.state;

      if (cache) {
        // eslint-disable-next-line no-undefined
        this.setState({
          data: cache,
          cache: undefined
        }, () => {
          this.multiGridRef.forceUpdateGrids();
        });
      }
    }

    forceUpdateGrids() {
      this.multiGridRef.forceUpdateGrids();
    }

    headerCellClickHandler(columnInfo) {
      let {
        sortId: prevSortId,
        sortAsc
      } = this.state;
      let sortId = columnInfo.id; //set ascending or decending flag

      if (sortId === prevSortId) {
        sortAsc = !sortAsc;
      } else {
        sortAsc = true;
      }

      this.setState({
        sortAsc,
        sortId
      }, () => {
        this.multiGridRef.forceUpdateGrids();
      });
      const {
        onSortClickHandler
      } = this.props;
      onSortClickHandler && onSortClickHandler(sortId, sortAsc, this.state.data.length);
    }

    checkBtnClickHandler(rowIndex, selected) {
      let {
        data
      } = this.state;
      let selectedRowData = this.getRowData(data, rowIndex);
      const {
        rowCheckBtnSelectHandler,
        rowCheckBtnUnSelectHandler
      } = this.props;

      if (selected) {
        rowCheckBtnSelectHandler(selectedRowData);
      } else {
        rowCheckBtnUnSelectHandler(selectedRowData);
      }
    }

    checkBtnAllClickHandler(value) {
      const {
        data,
        isTraversalEnabled
      } = this.state;
      const {
        onUnCheckAllHandler,
        onCheckAllHandler
      } = this.props; //call ember component's callback

      if (isTraversalEnabled) {
        onUnCheckAllHandler();
      }

      if (value) {
        onCheckAllHandler();
      } else {
        onUnCheckAllHandler();
      }
    }

    isRowLoaded(_ref7) {
      let {
        index
      } = _ref7;
      const {
        data
      } = this.state;
      const isloaded = !!data[index];
      return isloaded;
    }

    _selectCell(_ref8) {
      let {
        scrollToColumn,
        scrollToRow
      } = _ref8;
      this.setState({
        selectedRowIndex: scrollToRow,
        selectedColumnIndex: scrollToColumn,
        arrowKeyRow: scrollToRow
      });
    } //give some space for sorting indicator


    getColumnWidth(_ref9) {
      let {
        index
      } = _ref9;
      const {
        columnwidths,
        width
      } = this.state;
      const sum = Object.keys(columnwidths).reduce((_sum, i) => _sum + columnwidths[i], 0);

      if (index === Object.keys(columnwidths).length - 1 && sum < width) {
        return columnwidths[index] + width - sum; //modify right-most cell width
      }

      return columnwidths[index];
    }

    updateDimension(width, height) {
      this.setState({
        width,
        height
      });
    }

    updateWidgetState(widgetState) {
      const {
        config
      } = this.props;
      const widgetStateSortedColumn = config.columns.filter(cfg => cfg.id === widgetState.sortId);

      if (!Ember.isEmpty(widgetStateSortedColumn)) {
        const sortId = widgetStateSortedColumn[0].id;
        const sortAsc = widgetState.sortAsc;
        const reordering = widgetState.reordering || false;
        this.setState({
          sortId,
          sortAsc,
          reordering
        });
      }
    }

    updateRowItemSelection(checkedRowIds, disabledRowIds, isFurtherSelectionAllowed, hiddenRowIds) {
      this.setState({
        checkedRowIds: checkedRowIds,
        disabledRowIds: disabledRowIds,
        isFurtherSelectionAllowed: isFurtherSelectionAllowed,
        hiddenRowIds
      });
    }

    cellRightClickForTablet(event, rowIndex, columnIndex) {
      event.stopPropagation();
      this.setState({
        selectedRowIndex: rowIndex,
        selectedColumnIndex: columnIndex
      }, () => {
        this.multiGridRef.forceUpdateGrids();
      });
      const {
        onRowRightClick
      } = this.props;
      if (!onRowRightClick) return;
      const parentContainerCssClass = `.${GridWidget.containerCssClass}`;
      const widgetContainerOffset = Ember.$(event.target).closest(parentContainerCssClass).offset(); // const positionX = window.pageXOffset + event.clientX - widgetContainerOffset.left;
      // const positionY = window.pageYOffset + event.clientY - widgetContainerOffset.top + 50;

      const positionX = window.pageXOffset + event.clientX;
      const positionY = window.pageYOffset + event.clientY;
      const {
        data
      } = this.state;
      const selectedRowData = data.length ? this.getRowData(data, rowIndex) : null;
      onRowRightClick(selectedRowData).then(menusItems => {
        this.updateContextMenuState({
          rowIndex,
          columnIndex,
          positionX,
          positionY,
          menusItems,
          visible: true
        }); // Avoid duplicate event lister, so remove it before add a new one.

        document.removeEventListener('click', this.documentClickHandler, false);
        document.addEventListener('click', this.documentClickHandler);
        document.removeEventListener('contextmenu', this.documentRightClickHandler, false);
        document.addEventListener('contextmenu', this.documentRightClickHandler);
      });
    }

    getRowData(data, rowIndex) {
      return data[rowIndex - 1];
    }

    _isRowSortable(index) {
      return index >= 0;
    }

    _getSortableContainer() {
      this.domNode = _reactDom.default.findDOMNode(this);
      return this.domNode;
    }

    _rowRenderer(props, scrollToRow, preScrollToRow) {
      const isArrowKeyPressing = this.isArrowKeyPressing;
      const arrowKeyCellCache = props.parent === this.multiGridRef._bottomRightGrid ? this.arrowKeyCellCache : null;

      if (props.parent === this.multiGridRef._bottomRightGrid && this.state.reordering) {
        return sortableRowRenderer(props);
      }

      const children = fixedRowRenderer({ ...props,
        scrollToRow,
        preScrollToRow,
        isArrowKeyPressing,
        arrowKeyCellCache
      });
      return children;
    }

    _cellRenderer(props) {
      const {
        key,
        isScrolling
      } = props; // Added rowStyleHandler which gets passed through {...props}

      if (isScrolling) {
        this.hideContextMenu();
      }

      return _react.default.createElement(_cellrenderer.default, (0, _extends2.default)({
        cellKey: key
      }, props, this.state, {
        config: this.config,
        resizeStop: this.resizeStop,
        onContextMenuClick: this.onContextMenuClick,
        resizeRow: this.resizeRow,
        onTableCellClick: this.onTableCellClick,
        headerCellClickHandler: this.headerCellClickHandler,
        checkBtnClickHandler: this.checkBtnClickHandler,
        checkBtnAllClickHandler: this.checkBtnAllClickHandler,
        cellRightClickForTablet: this.cellRightClickForTablet
      }));
    }

    renderMultiGridWithNoData() {
      const {
        columns,
        showGearIcon
      } = this.state;
      const rowCount = 1;
      const columnIncrementerValue = showGearIcon ? 2 : 1;
      return _react.default.createElement(AutoSizer, null, _ref10 => {
        let {
          width,
          height
        } = _ref10;
        return _react.default.createElement(MultiGrid, {
          ref: ref => {
            this.multiGridRef = ref;
          },
          cellRenderer: this._cellRenderer,
          columnWidth: this.getColumnWidth,
          columnCount: columns.length + columnIncrementerValue,
          rowHeight: 23,
          rowCount: rowCount,
          height: height,
          width: width
        });
      });
    }

    renderMultiGrid(onRowsRendered, registerChild, scrollToColumn, scrollToRow, preScrollToRow, onSectionRendered) {
      const {
        data,
        columns,
        showGearIcon
      } = this.state;
      const columnIncrementerValue = showGearIcon ? 2 : 1;
      const rowCount = data.length + 1;
      const fixedRowCount = 1;
      let {
        rowStyleHandler
      } = this.props;
      this._onRowsRendered = onRowsRendered;
      this._onSectionRendered = onSectionRendered;
      const arrowKeyRow = this.isArrowKeyPressing ? scrollToRow : null;
      return _react.default.createElement(AutoSizer, null, _ref11 => {
        let {
          width,
          height
        } = _ref11;
        return _react.default.createElement(SortableMultiGrid, {
          ref: sortableGrid => {
            if (sortableGrid) {
              const multiGrid = sortableGrid.getWrappedInstance();
              this.multiGridRef = multiGrid;
              registerChild(multiGrid);
            }
          },
          onSortEnd: _ref12 => {
            let {
              oldIndex,
              newIndex
            } = _ref12;
            this.reorderRows(oldIndex, newIndex);
          },
          scrollToRow: arrowKeyRow,
          getContainer: this._getSortableContainer,
          onSectionRendered: _ref13 => {
            let {
              rowStartIndex,
              rowStopIndex,
              columnStartIndex,
              columnStopIndex
            } = _ref13;

            this._onRowsRendered({
              startIndex: rowStartIndex,
              stopIndex: rowStopIndex
            });
          },
          fixedRowCount: fixedRowCount,
          fixedColumnCount: 0,
          cellRangeRenderer: props => this._rowRenderer(props, scrollToRow, preScrollToRow),
          cellRenderer: _ref14 => {
            let {
              columnIndex,
              key,
              rowIndex,
              style,
              isScrolling
            } = _ref14;
            return this._cellRenderer({
              columnIndex,
              key,
              rowIndex,
              scrollToColumn,
              scrollToRow,
              style,
              rowStyleHandler,
              isScrolling
            });
          },
          columnWidth: this.getColumnWidth,
          columnCount: columns.length + columnIncrementerValue,
          rowHeight: 23,
          rowCount: rowCount,
          height: height,
          width: width
        });
      });
    }

    _infiniteLoaderChildFunction(_ref15) {
      let {
        onRowsRendered,
        registerChild,
        scrollToRow,
        scrollToColumn,
        preScrollToRow,
        onSectionRendered
      } = _ref15;
      return this.renderMultiGrid(onRowsRendered, registerChild, scrollToColumn, scrollToRow, preScrollToRow, onSectionRendered);
    }

    renderMultiGridWithArrowStepper() {
      const {
        selectedColumnIndex,
        selectedRowIndex,
        columns,
        data,
        showGearIcon,
        totalWidgetCount
      } = this.state;

      if (!data.length) {
        return this.renderMultiGridWithNoData();
      }

      const {
        config
      } = this.props;
      const batchSize = config.paginationLimit || BATCH_SIZE;
      const rowCount = totalWidgetCount > 0 && data.length + batchSize > totalWidgetCount ? data.length + 1 : data.length + batchSize;
      const columnIncrementerValue = showGearIcon ? 2 : 1;
      return _react.default.createElement(InfiniteLoader, {
        isRowLoaded: this.isRowLoaded,
        loadMoreRows: this.loadMoreRowsHandler,
        rowCount: rowCount,
        minimumBatchSize: batchSize,
        threshold: LOADER_THRESHOLD,
        ref: ref => {
          this.infiniteLoaderRef = ref;
        }
      }, _ref16 => {
        let {
          onRowsRendered,
          registerChild
        } = _ref16;
        return _react.default.createElement(ArrowKeyStepper, {
          className: 'arrow-key-stepper',
          columnCount: columns.length + columnIncrementerValue,
          isControlled: false // onScrollToChange={this._selectCell} //This is used when isControlled set to true
          ,
          mode: 'cells',
          rowCount: rowCount,
          scrollToColumn: selectedColumnIndex,
          scrollToRow: selectedRowIndex,
          rowGetter: _ref17 => {
            let {
              index
            } = _ref17;
            return this.state.data[index];
          }
        }, _ref18 => {
          let {
            onSectionRendered,
            scrollToRow,
            scrollToColumn
          } = _ref18;
          const preScrollToRow = this.preScrollToRow;
          this.onArrowKeyPress(scrollToRow, scrollToColumn);
          return this._infiniteLoaderChildFunction({
            onRowsRendered,
            registerChild,
            scrollToColumn,
            scrollToRow,
            preScrollToRow,
            onSectionRendered
          });
        });
      });
    }

    render() {
      const {
        contextMenuState,
        data,
        columns,
        columnwidths,
        useTabletWidth
      } = this.state;
      const {
        menusItems,
        visible
      } = contextMenuState;
      const containerStyle = {
        width: `${this.state.width}px`,
        height: `${this.state.height}px`
      };
      let widgetInstanceId = this.state.widgetInstanceId;
      let headerColumnTotalWidth = 0;
      columns.forEach((column, index) => {
        const defaultWidth = useTabletWidth && column.defaultTabletWidth ? column.defaultTabletWidth : column.defaultWidth;
        const columnWidth = defaultWidth || columnwidths[index];
        headerColumnTotalWidth += columnWidth;
      });
      const widgetClassName = !data.length ? `${GridWidget.containerCssClass} empty-widget` : GridWidget.containerCssClass;
      const containerClassNames = headerColumnTotalWidth < this.state.width ? `${widgetClassName} small-widget` : widgetClassName;
      return _react.default.createElement("div", null, _react.default.createElement("div", {
        id: `${widgetInstanceId}-container`,
        ref: containerRef => {
          this.containerRef = containerRef;
        },
        className: containerClassNames,
        onKeyDown: this.onKeyPressHandler,
        style: containerStyle
      }, this.renderMultiGridWithArrowStepper()), menusItems && menusItems.length && visible && _react.default.createElement(_gridContextMenu.default, (0, _extends2.default)({
        onMenuItemClick: this.menuItemCallback
      }, contextMenuState)));
    }

  }

  GridWidget.containerCssClass = 'widget-container';
  var _default = GridWidget;
  _exports.default = _default;
});