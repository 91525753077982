define("adept-iq/serializers/cancellation", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    keyForAttribute: function (attr) {
      return attr;
    },

    serialize(snapshot, options) {
      const json = this._super(snapshot, options);

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.cancelTypeName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.cancelType = {
          data: {
            type: 'cancel-type',
            id: resourceHash.attributes.cancelTypeName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.cancelTypeName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});