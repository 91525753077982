define("adept-iq/tests/factories/place", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const placeTypes = ['primary', 'favorite'];

  _emberDataFactoryGuy.default.define('place', {
    sequences: {
      placeTypes: num => placeTypes[num % placeTypes.length]
    },
    default: {
      placeCategoryTypeName: _emberDataFactoryGuy.default.generate('placeTypes'),
      geoCodingMethod: null
    }
  });
});