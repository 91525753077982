define("adept-iq/classes/tree-nodes/category", ["exports", "adept-iq/classes/tree-nodes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    type: 'category',
    configCategory: null,
    isCategory: true,
    isExpandable: true,
    // TODO: get this from config service somehow
    isCollection: Ember.computed('children.@each.type', function () {
      const children = this.get('children');

      if (Ember.isEmpty(children)) {
        return false;
      }

      return children.every(child => {
        return child.get('type') === 'object';
      });
    }),
    isModified: Ember.computed('children.length', 'visibleChildren.length', function () {
      return this.get('children.length') !== this.get('visibleChildren.length');
    }),
    isSearchMatch: Ember.computed('label', 'searchText', function () {
      const searchText = this.get('searchText');
      if (Ember.isBlank(searchText)) return true;
      const normalizedSearchText = searchText.toLowerCase();
      const label = this.get('label');

      if (Ember.isPresent(label)) {
        const normalizedLabel = label.toLowerCase();
        if (normalizedLabel.includes(normalizedSearchText)) return true;
      }
    })
  });

  _exports.default = _default;
});