define("adept-iq/adapters/trip", ["exports", "adept-iq/adapters/-ss-schedulingService", "adept-iq/config/api-urls", "adept-iq/transforms/object"], function (_exports, _ssSchedulingService, _apiUrls, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    iquxServiceHost: _apiUrls.API.iquxService.host,
    dispatchServiceHost: _apiUrls.API.dispatchService.host,

    async findRecord(store, type, id
    /*, snapshot*/
    ) {
      const iquxResponse = await this._super(...arguments);

      if (iquxResponse.included && iquxResponse.included.some(entity => entity.type === 'dispatchRoute')) {
        const tripCluster = await this.fetchTripClusterFromDispatch(id);

        for (const stopPoint of tripCluster.data) {
          const index = iquxResponse.included.findIndex(entity => entity.type === stopPoint.type && `${entity.id}` === `${stopPoint.id}`);

          if (index > -1) {
            iquxResponse.included[index] = stopPoint;
          }
        }

        for (const obj of tripCluster.included) {
          if (obj.type === 'route' || _object.default.type === 'cluster' || _object.default.type === 'dispatchRoute') {
            const index = iquxResponse.included.findIndex(entity => entity.type === obj.type && `${entity.id}` === `${obj.id}`);

            if (index === -1) {
              iquxResponse.included.push(obj);
            } else {
              iquxResponse.included[index] = obj;
            }
          }
        }
      }

      return iquxResponse;
    },

    urlForFindRecord(id) {
      const model = 'trip';
      const iquxServiceHost = this.get('iquxServiceHost');
      const baseUrl = `${iquxServiceHost}/${model}/${id}`;
      return baseUrl;
    },

    async fetchTripClusterFromDispatch(id) {
      const session = this.get('session');
      const dispatchServiceHost = this.get('dispatchServiceHost');
      const requestUrl = `${dispatchServiceHost}/stop-point?filter=eq(tripId,'${id}')&include=cluster,cluster.dispatchRoute`;
      const tripCluster = await this.get('ajaxService').request(requestUrl, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
      return tripCluster;
    }

  });

  _exports.default = _default;
});