define("adept-iq/serializers/cluster", ["exports", "adept-iq/serializers/-dispatch"], function (_exports, _dispatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    normalizeSingleResponse(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      payload.included = payload.included || [];
      const providerIndex = payload.included.findIndex(entity => entity.type === 'providerName');

      if (providerIndex > -1) {
        payload.included.splice(providerIndex, 1);
      }

      const normalized = this._super(...arguments);

      return normalized;
    }

  });

  _exports.default = _default;
});