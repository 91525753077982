define("adept-iq/serializers/avlm-alert", ["exports", "adept-iq/serializers/-avlm-avlmService"], function (_exports, _avlmAvlmService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      if (payload.data.attributes) {
        try {
          const relationships = payload.data.relationships ? payload.data.relationships : {};
          payload.data.attributes.body = payload.data.attributes.alertDescription;
          payload.data.attributes.companyId = payload.data.attributes.provider;
          payload.data.attributes.timestamp = payload.data.attributes.time;

          if (payload.data.attributes.category === 'emergency' && payload.data.attributes.state === 'active') {
            payload.data.attributes.isNewEmergencyReceived = true;
          }

          if (payload.data.attributes.vehicleId) {
            relationships.avlmVehicle = {
              data: {
                type: 'avlm-vehicle',
                id: `${payload.data.attributes.vehicleId}`
              }
            };
          }

          if (payload.data.attributes.routeId) {
            relationships.avlmRoute = {
              data: {
                type: 'avlm-route',
                id: `${payload.data.attributes.routeId}`
              }
            };
          }

          if (payload.data.attributes.driverPk) {
            const driverAttributes = {};
            driverAttributes.badgeNumber = payload.data.attributes.driverId;
            driverAttributes.firstName = payload.data.attributes.firstName;
            driverAttributes.lastName = payload.data.attributes.lastName;
            relationships.avlmDriver = {
              data: {
                type: 'avlm-driver',
                id: `${payload.data.attributes.driverPk}`
              }
            };
            const driverPayLoad = {
              id: payload.data.attributes.driverPk,
              type: 'avlm-driver',
              attributes: driverAttributes
            };
            payload.included = payload.included || [];
            payload.included.push(driverPayLoad);
          }

          payload.data.relationships = relationships;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }

      return payload;
    }

  });

  _exports.default = _default;
});