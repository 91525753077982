define("adept-iq/classes/rules/eligibility-rules/user-override-conditions-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type"], function (_exports, _baseRule, _ruleResultType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UserOverrideConditionsRule = void 0;

  class UserOverrideConditionsRule extends _baseRule.default {
    doEvaluate(ruleContext) {
      // overrideCondition flag is set in overrideConditionPreActionHandler (new-booking-form)
      if (ruleContext.overrideCondition) {
        return _ruleResultType.RESULT_TYPE_YES;
      }

      return _ruleResultType.RESULT_TYPE_NO;
    }

  }

  _exports.UserOverrideConditionsRule = UserOverrideConditionsRule;
  UserOverrideConditionsRule.RULE_NAME = 'UserOverrideConditionsRule';
});