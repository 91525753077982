define("adept-iq/pods/components/reconcile-column-editor/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: 'Reconcile',
    editColumnTableColumns: [{
      label: 'Field',
      valuePath: 'fieldId',
      resizable: true,
      minResizeWidth: 50
    }, {
      label: 'Editable',
      resizable: false,
      minResizeWidth: 50,
      cellComponent: 'generic-components/table/cells/check-box'
    }]
  };
  _exports.default = _default;
});