define("adept-iq/serializers/rider-phone-number", ["exports", "adept-iq/serializers/-rms-riderManagement"], function (_exports, _rmsRiderManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rmsRiderManagement.default.extend({
    attrs: {
      isSoftDeleted: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});