define("adept-iq/pods/components/iq-widgets/ember-react-playback/context/session", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SessionContext = void 0;

  const SessionContext = _react.default.createContext();

  _exports.SessionContext = SessionContext;
});