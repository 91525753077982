define("adept-iq/serializers/rider-service-need", ["exports", "adept-iq/serializers/-rms-riderManagement"], function (_exports, _rmsRiderManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rmsRiderManagement.default.extend({
    attrs: {
      isSoftDeleted: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      if (!Ember.isNone(json.data.relationships)) {
        const serviceNeedTypeNameRelationship = json.data.relationships.serviceNeedType;

        if (!Ember.isNone(serviceNeedTypeNameRelationship) && !Ember.isNone(serviceNeedTypeNameRelationship.data)) {
          serviceNeedTypeNameRelationship.data.type = 'serviceNeedTypeName';
          json.data.relationships.serviceNeedTypeName = json.data.relationships.serviceNeedType;
          delete json.data.relationships.serviceNeedType;
        }
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.relationships) && !Ember.isNone(resourceHash.relationships.serviceNeedTypeName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.serviceNeedType = {
          data: {
            type: 'service-need-type',
            id: resourceHash.relationships.serviceNeedTypeName.data.id
          }
        };
        delete resourceHash.relationships.serviceNeedTypeName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});