define("adept-iq/tests/factories/etanav-route", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('etanav-route', {
    default: {
      secondsToNext: '234',
      inService: 'true',
      version: '8e3ec40f-76b0-4509-8a1e-eb935d0d3124'
    }
  });
});