define("adept-iq/classes/polylines/routes/performed/base", ["exports", "adept-iq/classes/polylines/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // `BasePolyline` overrides
    style: Ember.computed.readOnly('performedRouteStyle'),
    // local properties
    performedRouteStyle: Ember.computed('baseRouteStyle', function () {
      return this.get('baseRouteStyle') + 'Performed';
    })
  });

  _exports.default = _default;
});