define("adept-iq/tests/factories/segment-stop", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  _emberDataFactoryGuy.default.define('segment-stop', {
    polymorphic: false,
    default: {
      type: 'pick',
      notes: 'stop notes',
      plannedTime: (0, _moment.default)().startOf('day').add(8, 'hours').toDate(),
      onboardCount: 2
    },
    traits: {
      break: {
        parentType: 'break',
        stopType: 'break'
      },
      pick: {
        type: 'pick'
      },
      drop: {
        type: 'drop',
        plannedTime: (0, _moment.default)().startOf('day').add(16, 'hours').toDate()
      }
    }
  });
});