define("adept-iq/adapters/no-show-reason-code", ["exports", "adept-iq/adapters/cs-config-model"], function (_exports, _csConfigModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigModel.default.extend({
    categoryId: 'config-System_Configuration-no_show_reason_codes'
  });

  _exports.default = _default;
});