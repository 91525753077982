define("adept-iq/config/zone-polygon", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // holds the zone polygon for walla walla
  // used by zone-validation.js

  /*eslint-disable */
  const zone_polygon_walla_walla = [[46.070180, -118.414163], [46.093803, -118.307092], [46.108669, -118.296996], [46.104824, -118.246489], [46.082908, -118.247049], [46.027789, -118.303751], [46.032074, -118.412725], [46.076793, -118.422319]]; // new york zone polygon

  const zone_polygon_nyct = [[40.499749, -74.267362], [40.550902, -74.255046], [40.642260, -74.198743], [40.694442, -74.061676], [40.933719, -73.918490], [40.879357, -73.750985], [40.738197, -73.676134], [40.577767, -73.737748], [40.481574, -74.131030], [40.482369, -74.258910]];
  const zone_polygon = eval(_environment.default.APP.mapValidationPolygon);
  var _default = zone_polygon;
  /*eslint-enable */

  _exports.default = _default;
});