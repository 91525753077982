define("adept-iq/adapters/cs-config-model", ["exports", "adept-iq/adapters/-cs-configService"], function (_exports, _csConfigService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigService.default.extend({
    namespace: 'config',
    categoryId: null,

    buildURL(modelName, id) {
      const host = this.get('host');
      const namespace = this.get('namespace');
      const categoryId = this.get('categoryId');
      const baseUrl = `${host}/${namespace}/${categoryId}`;
      return Ember.isPresent(id) ? `${baseUrl}/${id}` : baseUrl;
    },

    updateRecord() {
      throw new Error('cannot update a cs-config-model; update the associated cs-config-item instead');
    }

  });

  _exports.default = _default;
});