define("adept-iq/classes/rules/eligibility-rules/full-eligibility-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type", "adept-iq/models/cs-config-category"], function (_exports, _baseRule, _ruleResultType, _csConfigCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FullEligibilityRule = void 0;

  class FullEligibilityRule extends _baseRule.default {
    doEvaluate(ruleContext) {
      const {
        bookingService,
        systemConfigService
      } = ruleContext;
      const fullEligibilityCategories = systemConfigService.findEligibilityCategories(_csConfigCategory.ELIGIBILITY_CATEGORY_TYPES.FULL); // full eligibility not configured,  should pass the rule

      if (fullEligibilityCategories.length === 0) {
        return _ruleResultType.RESULT_TYPE_YES;
      }

      const selectedRiders = bookingService.get('selectedRiders');
      const containsFullEligibility = selectedRiders.every(rider => {
        return this.checkRiderFullEligibility(rider, fullEligibilityCategories);
      });
      if (containsFullEligibility) return _ruleResultType.RESULT_TYPE_YES;
      return _ruleResultType.RESULT_TYPE_NO;
    }

    checkRiderFullEligibility(riderExternal, eligibilityCategories) {
      const FULL_CATEGORY_NAMES = eligibilityCategories.map(category => {
        const categoryName = category.value.name || category.value.Name;
        return categoryName.trim().toLowerCase();
      });
      const riderEligibilityCategories = riderExternal.get('eligibility.categories');
      return riderEligibilityCategories.every(category => {
        return FULL_CATEGORY_NAMES.includes(category.type.toLowerCase());
      });
    }

  }

  _exports.FullEligibilityRule = FullEligibilityRule;
  FullEligibilityRule.RULE_NAME = 'FullEligibilityRule';
});