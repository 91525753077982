define("adept-iq/pods/components/reoptimize-modal/operation-views/illegal-to-waitlist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RLzpVrV9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isTaskRunning\"]]],null,{\"statements\":[[1,[21,\"generic-components/ring-loader\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[11,\"class\",\"illegal-to-waitlist-main\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"body-wrapper\"],[9],[0,\"\\n    \"],[7,\"h3\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"button-holder\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"illegal-to-waitlist-button\"],[12,\"disabled\",[21,\"disableApplyButton\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onUpdateClick\"],null]],[9],[7,\"span\"],[9],[0,\"Update\"],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/reoptimize-modal/operation-views/illegal-to-waitlist/template.hbs"
    }
  });

  _exports.default = _default;
});