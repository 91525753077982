define("adept-iq/mixins/lookup-table-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    validateDescription(vehicleLookupRecord) {
      const tooltip = this.get('tooltip');
      const description = vehicleLookupRecord.get('description') ? vehicleLookupRecord.get('description') : vehicleLookupRecord.get('displayName'); //vallidation for duplicate description

      const vehicleLookup = this.get('tableRef.records').filter(record => record.get('description') === description || record.get('displayName') === description).find(rec => rec.get('id') !== vehicleLookupRecord.get('id'));

      if (vehicleLookup) {
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: 'Description cannot duplicate an existing Description.',
          primaryActionText: 'OK',
          primaryAction: () => {
            return tooltip.reset();
          }
        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }

  });

  _exports.default = _default;
});