define("adept-iq/pods/components/iq-widgets/navigation-widget/config/polyline", ["exports", "adept-iq/pods/components/iq-widgets/navigation-widget/config/colors"], function (_exports, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // don't use opacity in these presets; it may cause polyline opacity to be
  // reset if the active style changes
  const {
    bemap
  } = window;
  var _default = {
    // these generic styles should go away
    solidPlanned: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_COLOR
    }),
    solidNavigation: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PENDING_COLOR
    }),
    solidGreen: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PRIMARY_COLOR
    }),
    solidPerformed: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PERFORMED_COLOR
    }),
    solid: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_COLOR
    }),
    solidLate: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_LATE_COLOR
    }),
    solidDanger: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_DANGER_COLOR
    }),
    dotted: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_PLANNED_DARK_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    dottedLate: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_LATE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    dottedDanger: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_OTP_DANGER_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    }),
    dottedDistance: new bemap.LineStyle({
      width: 3,
      color: _colors.BENOMAD_DISTANCE_COLOR,
      type: bemap.LineStyle.TYPE.DASH
    })
  };
  _exports.default = _default;
});