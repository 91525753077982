define("adept-iq/serializers/reconcile-route", ["exports", "adept-iq/serializers/-reconciliationService"], function (_exports, _reconciliationService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reconciliationService.default.extend({
    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    pushPayloads(store, item) {
      if (!Ember.isEmpty(item.attributes.vehicle.id)) {
        store.pushPayload({
          data: {
            id: item.attributes.vehicle.vehicleId,
            type: 'reconcile-vehicle',
            attributes: { ...item.attributes.vehicle
            }
          }
        });
      }

      if (!Ember.isEmpty(item.attributes.driver.id)) {
        store.pushPayload({
          data: {
            id: item.attributes.driver.driverId,
            type: 'reconcile-driver',
            attributes: { ...item.attributes.driver
            }
          }
        });
      }

      if (!Ember.isEmpty(item.attributes.start.id)) {
        store.pushPayload({
          data: {
            id: item.attributes.start.id,
            type: 'reconcile-stop',
            attributes: { ...item.attributes.start,
              stopId: item.attributes.start.id
            }
          }
        });

        if (!Ember.isEmpty(item.attributes.start.address.id)) {
          store.pushPayload({
            data: {
              id: item.attributes.start.id,
              type: 'reconcile-address',
              attributes: { ...item.attributes.start.address,
                addressId: item.attributes.start.id,
                stopId: item.attributes.start.id
              }
            }
          });
        }
      }

      if (!Ember.isEmpty(item.attributes.end.id)) {
        store.pushPayload({
          data: {
            id: item.attributes.end.id,
            type: 'reconcile-stop',
            attributes: { ...item.attributes.end,
              stopId: item.attributes.end.id
            }
          }
        });

        if (!Ember.isEmpty(item.attributes.end.address.id)) {
          store.pushPayload({
            data: {
              id: item.attributes.end.id,
              type: 'reconcile-address',
              attributes: { ...item.attributes.end.address,
                addressId: item.attributes.end.id,
                stopId: item.attributes.end.id
              }
            }
          });
        }
      }

      if (!Ember.isEmpty(item.attributes.breaks)) {
        item.attributes.breaks.map(breakStop => {
          if (!Ember.isEmpty(breakStop.id)) {
            store.pushPayload({
              data: {
                id: breakStop.id,
                type: 'reconcile-stop',
                attributes: { ...breakStop,
                  stopId: breakStop.id,
                  routeId: item.attributes.routeId
                }
              }
            });

            if (!Ember.isEmpty(breakStop.address.id)) {
              store.pushPayload({
                data: {
                  id: breakStop.address.id,
                  type: 'reconcile-address',
                  attributes: { ...breakStop.address,
                    addressId: breakStop.address.id,
                    stopId: breakStop.id
                  }
                }
              });
            }
          }
        });
      }

      if (!Ember.isEmpty(item.attributes.trips)) {
        item.attributes.trips.map(trip => {
          if (!Ember.isEmpty(trip.id)) {
            store.pushPayload({
              data: {
                id: trip.id,
                type: 'reconcile-trip',
                attributes: { ...trip,
                  tripId: trip.id,
                  routeId: item.attributes.routeId,
                  passengerId: !Ember.isEmpty(trip.passenger) && trip.passenger.passengerId ? trip.passenger.passengerId : 0
                }
              }
            });

            if (!Ember.isEmpty(trip.passenger) && trip.passenger.id) {
              store.pushPayload({
                data: {
                  id: trip.passenger.passengerId,
                  type: 'reconcile-passenger',
                  attributes: { ...trip.passenger
                  }
                }
              });
            }

            if (!Ember.isEmpty(trip.price)) {
              store.pushPayload({
                data: {
                  id: trip.id,
                  type: 'reconcile-price',
                  attributes: { ...trip.price,
                    priceId: trip.id,
                    tripId: trip.id
                  }
                }
              });
            }

            if (!Ember.isEmpty(trip.stops)) {
              trip.stops.map(stop => {
                if (!Ember.isEmpty(stop.id)) {
                  store.pushPayload({
                    data: {
                      id: stop.id,
                      type: 'reconcile-stop',
                      attributes: { ...stop,
                        stopId: stop.id,
                        tripId: trip.id,
                        routeId: item.attributes.routeId
                      }
                    }
                  });

                  if (!Ember.isEmpty(stop.address)) {
                    store.pushPayload({
                      data: {
                        id: stop.address.id,
                        type: 'reconcile-address',
                        attributes: { ...stop.address,
                          addressId: stop.address.id,
                          stopId: stop.id
                        }
                      }
                    });
                  }
                }

                if (!Ember.isEmpty(stop.usedAttributes)) {
                  stop.usedAttributes.map(usedAttribute => {
                    if (!Ember.isEmpty(usedAttribute.id)) {
                      store.pushPayload({
                        data: {
                          id: usedAttribute.id,
                          type: 'reconcile-used-attribute',
                          attributes: { ...usedAttribute,
                            attributeId: usedAttribute.id
                          }
                        }
                      });
                    }
                  });
                }
              });
            }
          }
        });
      }
    },

    normalizeQueryResponse(store, clazz, payload) {
      // workaround for this API not returns payload type
      const resultedPayload = [];

      const _data = [].concat(payload.data);

      payload.data = _data;

      if (!Ember.isEmpty(payload.data)) {
        payload.data.forEach(item => {
          this.pushPayloads(store, item);
          item.id = item.attributes.routeId;
          resultedPayload.push(item);
        });
        payload.data = resultedPayload;
      }

      return this._super(store, clazz, payload);
    },

    modelNameFromPayloadKey(key) {
      if (key === 'route') {
        return 'reconcile-route';
      }

      return this._super(key);
    }

  });

  _exports.default = _default;
});