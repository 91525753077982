define("adept-iq/serializers/vehicle-type", ["exports", "adept-iq/serializers/cs-config-model"], function (_exports, _csConfigModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function updateRelationship(relationships, relationshipName, id, type, store) {
    if (id) {
      relationships[relationshipName] = {
        data: {
          id,
          type
        }
      };
      store.push({
        data: {
          id,
          type
        }
      });
    }
  }

  var _default = _csConfigModel.default.extend({
    modelName: 'vehicle-type',

    normalizeArrayResponse(store
    /*, primaryModelClass, payload, id, requestType */
    ) {
      const normalized = this._super(...arguments);

      if (Ember.isPresent(normalized.data)) {
        normalized.data.forEach(datum => {
          datum.attributes = datum.attributes || {};
          datum.relationships = datum.relationships || {};
          const {
            rearAxelId,
            vehicleWheelBaseTypeId,
            vehicleTireSizeId,
            vehicleModelId,
            vehicleChassisId,
            vehicleBodyWidthId,
            vehicleFuelCapId,
            vehicleFuelTypeId,
            vehicleBodyTypeId,
            vehicleTransmissionId
          } = datum.attributes; // Avoid ember store send out query requests.

          updateRelationship(datum.relationships, 'rearAxel', rearAxelId, 'rear-axel-name', store);
          updateRelationship(datum.relationships, 'vehicleBodyType', vehicleBodyTypeId, 'vehicle-body-type', store);
          updateRelationship(datum.relationships, 'vehicleBodyWidth', vehicleBodyWidthId, 'vehicle-body-width', store);
          updateRelationship(datum.relationships, 'vehicleChassis', vehicleChassisId, 'vehicle-chassis', store);
          updateRelationship(datum.relationships, 'vehicleFuelCap', vehicleFuelCapId, 'vehicle-fuel-cap', store);
          updateRelationship(datum.relationships, 'vehicleFuelType', vehicleFuelTypeId, 'vehicle-fuel-type', store);
          updateRelationship(datum.relationships, 'vehicleModel', vehicleModelId, 'vehicle-model-name', store);
          updateRelationship(datum.relationships, 'vehicleWheelBaseType', vehicleWheelBaseTypeId, 'vehicle-wheelbase-type', store);
          updateRelationship(datum.relationships, 'vehicleTireSize', vehicleTireSizeId, 'vehicle-tire-size', store);
          updateRelationship(datum.relationships, 'vehicleTransmission', vehicleTransmissionId, 'vehicle-transmission', store);
        });
      }

      return normalized;
    },

    normalizeAttributes(attributes) {
      // copy `displayName` -> `name`
      if (!Ember.isNone(attributes)) attributes.name = attributes.displayName;
      return attributes;
    }

  });

  _exports.default = _default;
});