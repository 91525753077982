define("adept-iq/pods/components/rider-management-widget/rider-eligibility/eligibility-form-modal-dialog/component", ["exports", "moment", "lodash"], function (_exports, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['rider-relationship-options-select'],
    rider: Ember.inject.service(),
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    riderEligibilityRecord: null,
    prevEligibilityRecord: null,
    riderEligibilityCategoryDescription: null,
    riderEligibilityConditionDescription: null,
    eligibilityCondition: null,
    riderEligibilityCondition: null,
    aboveDegree: null,
    belowDegree: null,
    minimumNumberFt: null,
    headerTitle: null,
    eligibilityCategoryOptions: Ember.computed('configEligibilityCategories', 'riderChangeSet.{riderEligibilities,riderEligibilities.[]}', function () {
      const configEligibilityCategories = this.get('configEligibilityCategories') || [];
      const existingRiderEligibilityCategories = this.get('riderChangeSet.riderEligibilities') || [];
      const existingEligibilityCategoryIDs = existingRiderEligibilityCategories.map(ec => ec.get('eligibilityCategory.id'));

      if (configEligibilityCategories) {
        return configEligibilityCategories.filter(configCategory => {
          return !existingEligibilityCategoryIDs.includes(configCategory.get('name'));
        });
      }

      return [];
    }),
    eligibilityConditionOptions: Ember.computed('configEligibilityConditionsTypes', 'riderChangeSet.{riderEligibilities,riderEligibilities.[]}', function () {
      const configEligibilityConditionsTypes = this.get('configEligibilityConditionsTypes') || [];
      return configEligibilityConditionsTypes;
    }),

    async init() {
      this._super(...arguments);

      this.setEligibilityCategoryAttribute = this.setEligibilityCategoryAttribute.bind(this);
      this.setEligibilityCategoryDescription = this.setEligibilityCategoryDescription.bind(this);
      this.setEligibilityConditionAttribute = this.setEligibilityConditionAttribute.bind(this);
      this.setEligibilityConditionDescription = this.setEligibilityConditionDescription.bind(this);
      const configEligibilityCategories = await this.get('rider').getConfigEligibilityCategories();
      const configEligibilityConditionsTypes = await this.get('rider').getConfigEligibilityConditionsTypes();
      this.setProperties({
        configEligibilityCategories,
        configEligibilityConditionsTypes
      });
      this.set('headerTitle', this.get('isCreatingNewEligibility') ? 'Add Eligibility' : 'Edit Eligibility');

      if (this.get('isEditing')) {
        const eligibilityCategoryName = this.get('riderEligibilityRecord.eligibilityCategory.displayName');
        const eligibilityCategoryOptions = this.get('eligibilityCategoryOptions');
        const eligibilitySelectedCategory = eligibilityCategoryOptions.filter(ele => ele.displayName === eligibilityCategoryName);
        const eligibilityCategoryTypeValue = eligibilitySelectedCategory.length > 0 ? eligibilitySelectedCategory[0].value : null;
        const eligibilityConditionName = this.get('riderEligibilityRecord.eligibilityCondition.displayName');
        const eligibilityConditionOptions = this.get('eligibilityConditionOptions');
        const eligibilitySelectedConditionOptions = eligibilityConditionOptions.filter(ele => ele.displayName === eligibilityConditionName);

        if (Ember.isPresent(eligibilityCategoryTypeValue) && eligibilityCategoryTypeValue.conditional.toUpperCase() === 'YES') {
          this.set('riderEligibilityCondition', 'YES');
          const eligibilityConditionTypeValue = eligibilitySelectedConditionOptions[0].value;
          this.set('riderEligibilityCategoryDescription', Ember.isPresent(eligibilityCategoryTypeValue) ? eligibilityCategoryTypeValue.description : '');
          this.set('riderEligibilityConditionDescription', eligibilityConditionTypeValue.description);
          this.set('eligibilityCondition', eligibilityConditionTypeValue);

          if (eligibilityConditionTypeValue.displayName.toLowerCase() === 'weather') {
            this.set('eligibilityCondition.name', 'weather');
            const AboveDegree = eligibilityConditionTypeValue['Above(°F)'];
            const belowDegree = eligibilityConditionTypeValue['Below(°F)'];
            this.set('riderEligibilityConditionDescription', eligibilitySelectedConditionOptions[0].description);
            this.set('aboveDegree', AboveDegree);
            this.set('belowDegree', belowDegree);
          }

          if (eligibilityConditionTypeValue.displayName.toLowerCase() === 'itp walking distance') {
            this.set('eligibilityCondition.name', 'itp walking distance');
            this.set('minimumNumberFt', eligibilityConditionTypeValue['Minimum Number(ft)']);
            this.set('riderEligibilityConditionDescription', eligibilitySelectedConditionOptions[0].description);
          }
        } else {
          this.set('riderEligibilityCategoryDescription', Ember.isPresent(eligibilityCategoryTypeValue) ? eligibilityCategoryTypeValue.description : '');
        }
      }
    },

    setEligibilityConditionAttribute(configEligibilityConditionType) {
      let isCSType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      const riderEligibilityRecord = this.get('riderEligibilityRecord');

      if (isCSType) {
        const configEligibilityConditionName = configEligibilityConditionType.get('name');
        const eligibilityConditionRecord = this.get('store').peekRecord('eligibility-condition', configEligibilityConditionName);
        riderEligibilityRecord.set('origEligCondName', configEligibilityConditionName);
        riderEligibilityRecord.set('eligibilityCondition', eligibilityConditionRecord);
      } else {
        const configEligibilityConditionName = configEligibilityConditionType.get('id');
        const eligibilityConditionRecord = configEligibilityConditionType;
        riderEligibilityRecord.set('origEligCondName', configEligibilityConditionName);
        riderEligibilityRecord.set('eligibilityCondition', eligibilityConditionRecord);
      }

      this.get('setEligibilityConditionDescription')(configEligibilityConditionType, isCSType);
    },

    setEligibilityConditionDescription(selectedEligibilityConditionType, isCSType) {
      const configEligibilityConditionTypeValue = isCSType ? selectedEligibilityConditionType.get('value') : selectedEligibilityConditionType.get('data');
      const description = configEligibilityConditionTypeValue ? configEligibilityConditionTypeValue.description : null;
      const displayName = selectedEligibilityConditionType.get('displayName');
      const displayNameLower = displayName ? displayName.toLowerCase() : '';
      this.set('riderEligibilityConditionDescription', description);

      if (displayNameLower === 'weather') {
        const AboveDegree = configEligibilityConditionTypeValue['Above(°F)'];
        const belowDegree = configEligibilityConditionTypeValue['Below(°F)'];
        this.set('riderEligibilityConditionDescription', description);
        this.set('aboveDegree', AboveDegree);
        this.set('belowDegree', belowDegree);
      }

      if (displayNameLower === 'itp walking distance') {
        this.set('minimumNumberFt', configEligibilityConditionTypeValue['Minimum Number(ft)']);
        this.set('riderEligibilityConditionDescription', description);
      }
    },

    setEligibilityCategoryDescription(selectedConfigEligibilityCategory, isCSType) {
      const riderEligibilityRecord = this.get('riderEligibilityRecord');
      const configEligibilityCategoryValue = isCSType ? selectedConfigEligibilityCategory.get('value') : selectedConfigEligibilityCategory.get('data');
      this.set('riderEligibilityCategoryDescription', configEligibilityCategoryValue.description);
      this.set('riderEligibilityCondition', configEligibilityCategoryValue.conditional.toUpperCase());

      if (this.get('riderEligibilityCondition') === 'NO') {
        this.set('riderEligibilityConditionDescription', '');
        riderEligibilityRecord.set('origEligCondName');
        riderEligibilityRecord.set('eligibilityCondition');
      }
    },

    setEligibilityCategoryAttribute(configEligibilityCategory) {
      let isCSType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      const riderEligibilityRecord = this.get('riderEligibilityRecord');

      if (isCSType) {
        const configEligibilityCategoryName = configEligibilityCategory.get('name');
        const eligibilityCategoryRecord = this.get('store').peekRecord('eligibility-category', configEligibilityCategoryName);
        riderEligibilityRecord.set('origEligCateName', configEligibilityCategoryName);
        riderEligibilityRecord.set('eligibilityCategory', eligibilityCategoryRecord);
      } else {
        const configEligibilityCategoryName = configEligibilityCategory.get('id');
        const eligibilityCategoryRecord = configEligibilityCategory;
        riderEligibilityRecord.set('origEligCateName', configEligibilityCategoryName);
        riderEligibilityRecord.set('eligibilityCategory', eligibilityCategoryRecord);
      }

      this.get('setEligibilityCategoryDescription')(configEligibilityCategory, isCSType);
    },

    validateRiderEligibilityRequiredField(riderEligibilityRecord) {
      const tooltip = this.get('tooltip');
      const startAndEndDateErrors = this.validateStartAndEndDate(riderEligibilityRecord);
      const categoryConditionalErrors = this.validateCategoryConditional(riderEligibilityRecord);
      const errors = (0, _lodash.compact)([].concat(startAndEndDateErrors).concat(categoryConditionalErrors));

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Rider Management',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            tooltip.popConfirmation();
          }

        });
        return false;
      }

      return true;
    },

    validateEligibilityDatesCannotOverlap(riderEligibilityRecord) {
      const riderId = riderEligibilityRecord.get('rider.id');
      const fromDate = (0, _moment.default)(riderEligibilityRecord.from).format('MM-DD-YYYY');
      const toDate = (0, _moment.default)(riderEligibilityRecord.to).format('MM-DD-YYYY');
      const eligibilityRecords = this.store.peekAll('rider-eligibility').toArray();
      const matchedRecord = eligibilityRecords.some(record => {
        if (record.id && record.get('rider.id') === riderId) {
          const existingFromDate = (0, _moment.default)(record.from).format('MM-DD-YYYY');
          const existingToDate = (0, _moment.default)(record.to).format('MM-DD-YYYY');

          if (record.id !== riderEligibilityRecord.id) {
            return existingFromDate === fromDate || existingToDate === toDate;
          }
        }
      });

      if (matchedRecord) {
        return true;
      }

      return false;
    },

    validateStartAndEndDate(riderEligibilityRecord) {
      const startDate = riderEligibilityRecord.get('from');
      const endDate = riderEligibilityRecord.get('to');
      const errors = [];

      if (!startDate) {
        errors.push({
          key: 'from',
          validation: ['Start Date is required']
        });
      }

      if (!endDate) {
        errors.push({
          key: 'to',
          validation: ['End Date is required']
        });
      }

      return errors;
    },

    validateCategoryConditional(riderEligibilityRecord) {
      const errors = [];
      const eligibilityCategory = riderEligibilityRecord.get('eligibilityCategory');
      const eligibilityCondition = riderEligibilityRecord.get('eligibilityCondition');
      const categoryExist = Ember.isPresent(eligibilityCategory.get('id'));
      const conditional = categoryExist && eligibilityCategory.get('data.conditional').toUpperCase() === 'YES';

      if (!categoryExist) {
        errors.push({
          key: 'eligibilityCategory',
          validation: ['Category is required']
        });
      }

      if (categoryExist && conditional && !Ember.isPresent(eligibilityCondition.get('id'))) {
        errors.push({
          key: 'eligibilityCondition',
          validation: ['Condition is required']
        });
      }

      return errors;
    },

    validateEndDateGreaterThanStartDate(riderEligibilityRecord) {
      const startDate = (0, _moment.default)(riderEligibilityRecord.from).toDate();
      const endDate = (0, _moment.default)(riderEligibilityRecord.to).toDate();

      if (startDate && endDate) {
        const endDateValidate = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

        if (endDateValidate < 0) {
          return true;
        }

        return false;
      }
    },

    showValidateToolTip(message) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Rider Management',
        tip: message,
        primaryActionText: 'Ok',
        primaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    undoEligibility() {
      let rollbackNew = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const riderEligibilityRecord = this.get('riderEligibilityRecord');
      const prevEligibilityRecord = this.get('prevEligibilityRecord');

      if (Ember.isPresent(prevEligibilityRecord.eligibilityCategory.get('content')) || Ember.isPresent(prevEligibilityRecord.eligibilityCondition.get('content')) || Ember.isPresent(prevEligibilityRecord.from) || Ember.isPresent(prevEligibilityRecord.to)) {
        riderEligibilityRecord.set('eligibilityCategory', prevEligibilityRecord.eligibilityCategory);
        riderEligibilityRecord.set('eligibilityCondition', prevEligibilityRecord.eligibilityCondition);
        riderEligibilityRecord.set('from', prevEligibilityRecord.from);
        riderEligibilityRecord.set('to', prevEligibilityRecord.to);
        this.setEligibilityCategoryAttribute(riderEligibilityRecord.get('eligibilityCategory'), false);
        this.setEligibilityConditionAttribute(riderEligibilityRecord.get('eligibilityCondition'), false);
      } else if (riderEligibilityRecord.isNew && rollbackNew) {
        riderEligibilityRecord.rollbackAttributes();
      }
    },

    actions: {
      closeEligibilityInfoDialog() {
        this.undoEligibility(true);
        this.set('canShowRiderEligibilityFormModal', false);
      },

      onEligibilityCategoryChange(configEligibilityCategory) {
        this.setEligibilityCategoryAttribute(configEligibilityCategory, true);
      },

      onEligibilityConditionTypeChange(configEligibilityConditionType) {
        this.setEligibilityConditionAttribute(configEligibilityConditionType, true);
      },

      onDateValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onUndoEligibility() {
        this.undoEligibility(false);
      },

      onTick() {
        const riderEligibilityRecord = this.get('riderEligibilityRecord');
        const eligibilityRequiredFields = this.validateRiderEligibilityRequiredField(riderEligibilityRecord);
        const endDateGreaterThanStartDate = this.validateEndDateGreaterThanStartDate(riderEligibilityRecord);

        if (endDateGreaterThanStartDate) {
          this.showValidateToolTip('End Date must be after Start Date');
        } else if (eligibilityRequiredFields) {
          this.get('onRiderReasonEligibilitySave')();
        }
      }

    }
  });

  _exports.default = _default;
});