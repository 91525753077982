define("adept-iq/classes/active-contexts/reconcile/reconcile", ["exports", "ember-concurrency", "lodash", "adept-iq/classes/active-contexts/base/base", "adept-iq/classes/active-contexts/reconcile/graph", "adept-iq/classes/active-contexts/reconcile/map-graph", "adept-iq/utils/flattenData"], function (_exports, _emberConcurrency, _lodash, _base, _graph, _mapGraph, _flattenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // overridden values based on extending sub classes
    // computed.readOnly('workspaceContext._structuredWorkspace')
    graph: null,
    nodes: null,
    emptyImplicitHash: null,
    consumedModelNames: Ember.computed('workspace.dashboardInstance.widgets.@each.models', function () {
      const widgets = this.get('workspace.dashboardInstance.widgets');

      const widgetModels = _lodash.default.reduce(widgets, (acc, widget) => _lodash.default.union(acc, widget.get('models')), []);

      return widgetModels;
    }),
    // flattened data sets for easy binding & interation
    activeData: Ember.computed('structuredActiveData', function () {
      const structuredActiveData = this.get('structuredActiveData');
      return (0, _flattenData.flattenData)(structuredActiveData);
    }),

    getActiveData() {
      if (this.get('unloadTask.isRunning')) {
        return {
          'dwh-route': [],
          'dwh-trip': [],
          'dwh-stop-point': []
        };
      }

      const store = this.get('store');
      const checkedItems = this.get('checkedItems');
      const workspaceData = {
        'dwh-route': store.peekAll('dwh-route').toArray(),
        'dwh-trip': store.peekAll('dwh-trip').toArray(),
        'dwh-stop-point': store.peekAll('dwh-stop-point').toArray()
      };
      return this._computeActiveContext(workspaceData, checkedItems);
    },

    init() {
      this._super(...arguments);

      this.set('checkedItems', []);
      this.set('refreshedModelNames', []);
      this.set('mapGraph', _mapGraph.mapContextGraph);
      this.set('mapNodes', _mapGraph.mapContextNodes);
      this.set('graph', _graph.activeContextGraph);
      this.set('nodes', _graph.activeContextNodes);

      const nodes = _graph.activeContextNodes.reduce((acu, node) => {
        acu[node.modelName] = {};
        return acu;
      }, {});

      this.set('emptyImplicitHash', nodes);
      this.startRefreshQueue();
    },

    destroy() {
      this._super(...arguments);
    },

    willDestroyElement() {
      if (this.get('setWidgetRecordsTask.isRunning')) {
        this.get('setWidgetRecordsTask').cancelAll();
      }
    },

    // Overwrite base clearCheckedItems
    clearCheckedItems() {
      let refreshAfterClear = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      const checkedItems = this.get('checkedItems');

      for (const checkedItem of checkedItems) {
        this.toggleHighLight(checkedItem.record, false);
      }

      this.set('checkedItems', []);

      if (refreshAfterClear) {
        this.refreshAll();
      }
    },

    async clearAndRefreshAll() {
      await this.get('unloadTask').perform();
    },

    unloadTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const checkedItems = this.get('checkedItems');
      const sleepTime = 500;

      if (!Ember.isEmpty(checkedItems)) {
        this.clearCheckedItems(false);
      }

      store.peekAll('dwh-stop-point').toArray().forEach(record => {
        record.transitionTo('deleted.saved');
      });
      store.peekAll('dwh-trip').toArray().forEach(record => {
        record.transitionTo('deleted.saved');
      });
      store.peekAll('dwh-route').toArray().forEach(record => {
        record.transitionTo('deleted.saved');
      });
      store.unloadAll('dwh-stop-point');
      store.unloadAll('dwh-trip');
      store.unloadAll('dwh-route');
      this.clearAll();
      yield (0, _emberConcurrency.timeout)(sleepTime);
      this.refreshAll();
    }).keepLatest()
  });

  _exports.default = _default;
});