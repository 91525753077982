define("adept-iq/services/window-resize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    debounceInterval: 250,

    /*eslint-disable */
    _onResize: undefined,

    /*eslint-enable */
    init() {
      this._super(...arguments);

      this._onResize = event => {
        const debounceInterval = this.get('debounceInterval');
        Ember.run.debounce(this, '_resizeHandler', event, debounceInterval);
      };

      this._initialiseResizeListener();
    },

    destroy() {
      this._super(...arguments);

      this._destroyResizeListener();
    },

    _initialiseResizeListener() {
      window.addEventListener('resize', this._onResize);
    },

    _destroyResizeListener() {
      window.removeEventListener('resize', this._onResize);
    },

    _resizeHandler(event) {
      this.trigger('didResize', event);
    }

  });

  _exports.default = _default;
});