define("adept-iq/pods/components/iq-widgets/vehicle-events-widget/stops-row/component", ["exports", "adept-iq/pods/components/table/rows/text-formatted-row/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    attributeBindings: ['draggable'],
    draggable: 'true',
    // TODO: delete or revise when API and/or model is updated.
    otp: Ember.computed.alias('row.otp')
  });

  _exports.default = _default;
});