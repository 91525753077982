define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/equipment/equipment-validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALIDATIONS = void 0;
  const VALIDATIONS = {
    maintenanceLocationName: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Maintenance Location',
      message: '{description} is required'
    })],
    type: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Type',
      message: '{description} is required'
    })]
  };
  _exports.VALIDATIONS = VALIDATIONS;
});