define("adept-iq/models/reconcile-route-id", ["exports", "ember-data", "moment", "adept-iq/utils/format-text-extension"], function (_exports, _emberData, _moment, _formatTextExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    routeId: attr('string'),
    providerName: attr('string'),
    serviceMode: attr('string'),
    status: attr('string'),
    type: attr('string'),
    plannedStartTime: attr('date'),
    plannedEndTime: attr('date'),
    actualStartTime: attr('date'),
    actualEndTime: attr('date'),
    odometerStart: attr('number'),
    odometerEnd: attr('number'),
    notes: attr('string'),
    driverId: attr('string'),
    vehicleId: attr('string'),
    maxMilesPerHour: attr('number'),
    maxTripsPerHour: attr('number'),
    breakdownLocation: attr('string'),
    breakdownTime: attr('date'),
    breakdownReason: attr('string'),
    isCompleted: attr('boolean'),
    isDeleted: attr('boolean'),
    verifiedDate: attr('date'),
    verifiedBy: attr('string'),
    closedDate: attr('date'),
    closedBy: attr('string'),
    reopenedDate: attr('date'),
    reopenedBy: attr('string'),
    routeReopenedDate: attr('date'),
    routeReopenedBy: attr('string'),
    vehicle: attr('object'),
    driver: attr('object'),
    start: attr('object'),
    end: attr('object'),
    isRouteDateClosed: Ember.computed.notEmpty('closedDate'),
    isKeeperRoute: Ember.computed('type', function () {
      return Ember.get(this, 'type') && Ember.get(this, 'type').toLowerCase() === 'keeper' ? 'Yes' : 'No';
    }),
    isRegularRoute: Ember.computed('type', function () {
      return !Ember.get(this, 'type') || Ember.get(this, 'type').toLowerCase() === 'regular';
    }),
    tripDate: Ember.computed('plannedStartTime', function () {
      const dt = Ember.get(this, 'plannedStartTime');
      return dt ? (0, _moment.default)(dt).format('MM-DD-YYYY') : '';
    }),
    formattedStartTime: Ember.computed('plannedStartTime', function () {
      const dt = Ember.get(this, 'plannedStartTime');
      return dt ? (0, _moment.default)(dt).format('hh:mm A') : '';
    }),
    formattedEndTime: Ember.computed('plannedEndTime', function () {
      const dt = Ember.get(this, 'plannedEndTime');
      return dt ? (0, _moment.default)(dt).format('hh:mm A') : '';
    }),
    formattedActualStartTime: Ember.computed('actualStartTime', function () {
      const dt = Ember.get(this, 'actualStartTime');
      return dt ? (0, _moment.default)(dt).format('hh:mm A') : '';
    }),
    formattedActualEndTime: Ember.computed('actualEndTime', function () {
      const dt = Ember.get(this, 'actualEndTime');
      return dt ? (0, _moment.default)(dt).format('hh:mm A') : '';
    }),
    formattedBreakdownTime: Ember.computed('breakdownTime', function () {
      const dt = Ember.get(this, 'breakdownTime');
      return dt ? (0, _moment.default)(dt).format('MM-DD-YYYY hh:mm A') : '';
    }),
    formattedStatus: Ember.computed('status', function () {
      const s = Ember.get(this, 'status');
      return s ? (0, _formatTextExtension.convertToCapitalize)(s) : '';
    }),
    formattedDriverShiftStartTime: Ember.computed('driver.shiftStartTime', function () {
      const dt = Ember.get(this, 'driver.shiftStartTime');
      return dt ? dt : '';
    }),
    formattedDriverShiftEndTime: Ember.computed('driver.shiftEndTime', function () {
      const dt = Ember.get(this, 'driver.shiftEndTime');
      return dt ? dt : '';
    }),
    shiftLength: Ember.computed('actualStartTime', 'actualEndTime', function () {
      let length = '00:00';
      const stDt = Ember.get(this, 'actualStartTime');
      const enDt = Ember.get(this, 'actualEndTime');

      if (stDt && enDt) {
        const duration = _moment.default.duration((0, _moment.default)(enDt).diff((0, _moment.default)(stDt)));

        const hours = parseInt(duration.asHours(), 10) < 10 ? `0${parseInt(duration.asHours(), 10)}` : parseInt(duration.asHours(), 10);
        const minutes = parseInt(duration.minutes(), 10) < 10 ? `0${parseInt(duration.minutes(), 10)}` : parseInt(duration.minutes(), 10);
        length = [hours, minutes].join(':');
      }

      return length;
    })
  });

  _exports.default = _default;
});