define("adept-iq/pods/components/table/cells/user-editable-enum/component", ["exports", "adept-iq/pods/components/table/cells/base-cell/component", "adept-iq/utils/save-records"], function (_exports, _component, _saveRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KEYCODE_TAB = 9;
  const KEYCODE_ENTER = 13;
  const KEYCODE_ESCAPE = 27;

  var _default = _component.default.extend({
    classNames: ['table-user-editable-enum-cell'],
    classNameBindings: ['canFocus'],
    isEditing: false,
    value: null,
    dropdownClass: null,
    dropdownAPI: null,
    options: Ember.computed.alias('column.options'),
    allowMultiple: Ember.computed.alias('column.allowMultiple'),
    canFocus: Ember.computed.not('isEditing'),
    isDoubleClick: Ember.computed.and('row.isEditing', 'column.isEditing'),
    onIsEditingChange: Ember.observer('isDoubleClick', function () {
      if (this.get('isDoubleClick')) {
        this.startEditing();
      }
    }),

    didInsertElement() {
      this._super(...arguments);

      this.set('dropdownClass', `${this.elementId}-dropdown`);
    },

    startEditing() {
      if (this.get('isEditing')) return;
      this.set('isEditing', true);
      const row = this.get('row');
      const valuePath = this.get('column.valuePath');
      const value = row.get(valuePath);
      this.set('value', Ember.copy(value));
      Ember.run.scheduleOnce('afterRender', () => {
        this.get('dropdownAPI').actions.open(); // Close on click outside.

        Ember.$(document).on('click', e => {
          Ember.run.scheduleOnce('afterRender', () => {
            const componentElements = [this.element, Ember.$(this.get('dropdownClass')).get(0)];
            const isClickInside = componentElements.any(element => {
              return element && element.contains(e.target);
            });
            if (isClickInside) return;
            this.saveChanges();
            Ember.$(document).off('click');
          });
        });
      });
    },

    saveChanges() {
      if (!this.get('isEditing')) return;
      this.set('isEditing', false);
      const row = this.get('row');
      const valuePath = this.get('column.valuePath');
      const value = this.get('value');
      row.set(valuePath, value);
      (0, _saveRecords.default)([row.content.record]);
    },

    discardChanges() {
      if (!this.get('isEditing')) return;
      this.set('isEditing', false);
      const row = this.get('row');
      const valuePath = this.get('column.valuePath');
      const value = row.get(valuePath);
      this.set('value', Ember.copy(value));
    },

    actions: {
      onChange(value) {
        this.set('value', value);

        if (this.get('allowMultiple') === false) {
          this.saveChanges();
        }
      },

      onKeydown(api, event) {
        const {
          keyCode
        } = event; // Allow default enter to select and escape to close drop down when open.

        if (api.isOpen) {
          event.stopPropagation();
          return;
        }

        if (keyCode === KEYCODE_ESCAPE) {
          this.discardChanges();
        }

        if (keyCode === KEYCODE_TAB || keyCode === KEYCODE_ENTER) {
          this.saveChanges();
        }
      },

      onDidInsertDropdownElement(dropdownAPI) {
        this.set('dropdownAPI', dropdownAPI);
      }

    }
  });

  _exports.default = _default;
});