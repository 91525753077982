define("adept-iq/pods/components/iq-widgets/subscription-widget/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/subscription-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'subscription',
    defaultSortId: 'subscriptionDatetime',
    defaultSortAsc: true,
    checkBoxColumn: false,
    isFormControls: true,
    title: 'Subscriptions',
    columns: [{
      id: 'subscriptionId',
      type: 'uuid',
      label: 'ID',
      valuePath: 'id',
      searchable: true,
      defaultWidth: 100,
      hidden: true,
      unAvailable: true
    }, {
      id: 'subscriptionRequestedTime',
      index: 0,
      type: 'text',
      label: 'Request/Time',
      valuePath: 'requestTimeForDisplay',
      editable: false,
      searchable: true,
      hidden: false,
      format: 'hh:mm A',
      defaultWidth: 160
    }, {
      id: 'subscriptionExternalId',
      index: 1,
      type: 'text',
      label: 'Subscription ID',
      valuePath: 'id',
      searchable: true,
      editable: true,
      hidden: false,
      defaultWidth: 140
    }, {
      id: 'subscriptionGroupId',
      index: 2,
      type: 'text',
      label: 'Group ID',
      valuePath: 'group.groupId',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 140
    }, {
      id: 'subscriptionStatus',
      index: 3,
      type: 'text',
      label: 'Status',
      valuePath: 'computedStatus',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 120
    }, {
      id: 'subscriptionRiderId',
      index: 4,
      type: 'text',
      label: 'Passenger ID',
      valuePath: 'rider.riderId',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 120
    }, {
      id: 'subscriptionRiderFirstName',
      index: 5,
      type: 'text',
      label: 'First Name',
      valuePath: 'rider.firstName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'subscriptionRiderLastName',
      index: 6,
      type: 'text',
      label: 'Last Name',
      valuePath: 'rider.lastName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'subscriptionPickupaddress',
      index: 7,
      type: 'text',
      label: 'Pick Address ',
      valuePath: 'origin.address.simpleAddress',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 140
    }, {
      id: 'subscriptionPickCity',
      index: 8,
      type: 'text',
      label: 'Pick City',
      valuePath: 'origin.address.locality',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 120
    }, {
      id: 'pickupZone',
      index: 9,
      type: 'text',
      label: 'Pickup Zone',
      valuePath: 'origin.address.zoneName.valueDisplayName',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 120
    }, {
      id: 'subscriptionStartDate',
      index: 10,
      type: 'datetime',
      label: 'Start Date',
      valuePath: 'startDate',
      editable: true,
      searchable: true,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      defaultWidth: 140
    }, {
      id: 'subscriptionDatetime',
      index: 11,
      type: 'datetime',
      label: 'End Date',
      valuePath: 'endDate',
      editable: false,
      searchable: false,
      hidden: false,
      format: `${_environment.default.dateTimeFormat.dateMoment}`,
      defaultWidth: 140
    }, {
      id: 'subscriptionDropoffaddress',
      index: 12,
      type: 'text',
      label: 'Drop off Address ',
      valuePath: 'destination.address.simpleAddress',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 140
    }, {
      id: 'subscriptionDropoffCity',
      index: 13,
      type: 'text',
      label: 'Drop off City',
      valuePath: 'destination.address.locality',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 120
    }, {
      id: 'dropoffZone',
      index: 14,
      type: 'text',
      label: 'Drop off Zone',
      valuePath: 'destination.address.zoneName.valueDisplayName',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 120
    }]
  };
  _exports.default = _default;
});