define("adept-iq/models/avlm-route-break", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    plannedStartTime: attr('date'),
    plannedEndTime: attr('date'),
    routeIndex: attr('string'),
    scheduledTime: attr('date'),
    promisedTime: attr('date'),
    serviceStartTime: attr('date'),
    serviceEndTime: attr('date'),
    eta: attr('date'),
    status: attr('string'),
    avlmSchedule: belongsTo('avlm-schedule'),
    avlmRoute: belongsTo('avlm-route'),
    avlmAddress: belongsTo('avlm-address'),
    avlmStopPoint: belongsTo('avlm-stop-point'),
    etaNavRouteBreak: belongsTo('etanav-route-break')
  });

  _exports.default = _default;
});