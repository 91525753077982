define("adept-iq/models/rider-contact-info", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    store: Ember.inject.service(),
    name: attr('string', {
      defaultValue: ''
    }),
    email: attr('string', {
      defaultValue: ''
    }),
    phoneNumber: attr('string', {
      defaultValue: ''
    }),
    extension: attr('string'),
    address: attr('string', {
      defaultValue: ''
    }),
    relationship: attr('string', {
      defaultValue: ''
    }),
    contactType: attr('string', {
      defaultValue: ''
    }),
    mailPreferenceEnabled: attr('boolean'),
    isSoftDeleted: attr('boolean', {
      defaultValue: false
    }),
    loggingMeta: attr(),
    reason: attr('string'),
    rider: belongsTo('rider'),
    displayContactType: Ember.computed('contactType', function () {
      const contactType = this.get('contactType');
      return (0, _lodash.capitalize)(contactType);
    }),
    formattedPhoneNumber: Ember.computed('phoneNumber', function () {
      const phoneNumber = this.get('phoneNumber');

      if (!Ember.isEmpty(phoneNumber)) {
        const areaCode = phoneNumber.substring(0, 3);
        const phone = phoneNumber.substring(3, 10);
        const extension = this.get('extension');
        const formatedPhone = phone.match(/\d{3}(?=\d{2,3})|\d+/g).join('-');

        if (extension) {
          return `(${areaCode}) ${formatedPhone}-#${extension}`;
        }

        return `(${areaCode}) ${formatedPhone}`;
      }

      return '';
    }),
    reasonDisplayName: Ember.computed('reason', function () {
      const reason = this.get('reason');
      const csReasonName = `config-System_Configuration-contact_reason_types/${reason}`;
      const riderContactReasonType = this.get('store').peekRecord('cs-config-item', csReasonName);
      return Ember.isEmpty(riderContactReasonType) ? '' : riderContactReasonType.get('value.displayName');
    })
  });

  _exports.default = _default;
});