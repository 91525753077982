define("adept-iq/pods/components/iq-widgets/passengers-booking-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/passengers-booking-widget/config", "adept-iq/pods/components/iq-widgets/passengers-booking-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/config/mapped-permIds", "adept-iq/config/notifications", "adept-iq/models/cs-config-category"], function (_exports, _component, _config, _avlmConfig, _environment, _mappedPermIds, _notifications, _csConfigCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];
  const ROWS_LIMIT = 300;

  var _default = _component.default.extend({
    classNames: ['passengers-booking-widget'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    booking: Ember.inject.service(),
    report: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    session: Ember.inject.service(),
    workspace: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    canShowHeader: false,
    editComponent: 'iq-widgets/passengers-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    newBookingComponent: 'iq-widgets/new-booking-form',
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);

      this.get('tableColumns').firstObject.component = null;
      this.get('tableColumns').popObject();
      this.get('tableColumns').lastObject.resizable = false;
      this.set('limit', ROWS_LIMIT);
    },

    didInsertElement() {
      this._super(...arguments);

      if (this.get('setPassengerBookingTable')) {
        this.get('setPassengerBookingTable')(this.get('table'));
      }

      this.set('activityLog', {
        id: 'activityLog',
        name: 'Open rider activity log in new tab',
        permId: _mappedPermIds.default.accessBookingWorkspace,
        action: async model => {
          const permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.accessRiderManagementWorkspace, null);
          const riderId = model.get('id');
          const viewPage = 'Activity Log';
          const url = `/dashboard/Rider%20Management/workspaces-riderManagement%2FriderManagement?riderId=${riderId}&viewPage=${viewPage}`;

          if (!permGranted) {
            this.get('tooltip').pushConfirmation({
              title: 'Activity Log',
              tip: "You do not have permission to access the rider's activity log. Please contact your system administrator.",
              hasOverlay: true,
              primaryActionText: 'OK',
              primaryAction: () => {
                this.get('tooltip').reset();
              }
            });
          } else {
            window.open(url, '_blank');
          }
        }
      });
      this.set('tripHistoryReport', {
        id: 'tripHistoryReport',
        name: 'Open rider trip history report in popup',
        permId: _mappedPermIds.default.accessBookingWorkspace,
        action: async model => {
          const permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.accessReportsWorkspace, null);

          if (permGranted) {
            const riderId = model.get('riderId');
            const reportService = this.get('report');
            return reportService.openRiderTripHistoryReportPopup(riderId);
          }

          this.get('tooltip').pushConfirmation({
            title: 'Trip History Report',
            tip: "You do not have permission to access the rider's trip history report. Please contact your system administrator.",
            hasOverlay: true,
            primaryActionText: 'OK',
            primaryAction: () => {
              this.get('tooltip').reset();
            }
          });
          return false;
        }
      });
    },

    unSelectAllRows() {
      const tableRows = this.get('table.rows');
      tableRows.setEach('selected', false);
      tableRows.setEach('isChecked', false);
    },

    updateSelectedRiderList(riderRecord, add) {
      if (add) {
        this.get('booking').addSelectedRider(riderRecord);
      } else {
        this.get('booking').removeSelectedRider(riderRecord);
      }

      this.get('onPassengerWidgetRowClick')(riderRecord);
    },

    checkIfRiderIsInActive(riderRecord) {
      const status = riderRecord.get('status');

      if (status === 'ACTIVE' || status === 'PENDING') {
        return false;
      }

      return true;
    },

    checkRiderHasEligibilityInSysConfig(riderRecord) {
      const riderEligibilityCategories = riderRecord.get('eligibility.categories') || [];

      if (riderEligibilityCategories.length === 0) {
        return true;
      }

      const containsRiderCategory = riderEligibilityCategories.every(riderCategory => {
        const riderEligibilityCategoryList = this.get('systemConfig').findEligibilityCategories(riderCategory.type.toLowerCase());
        const CATEGORY_NAMES = riderEligibilityCategoryList.map(category => {
          const valueObj = category.value;
          const categoryName = valueObj.name || valueObj.Name;
          return categoryName.trim().toLowerCase();
        });
        return CATEGORY_NAMES.includes(riderCategory.type.toLowerCase());
      }); // check rider has an eligibility that match an eligibility in System Configuration

      if (!containsRiderCategory) return true;
      return false;
    },

    checkRiderHasInValidEligibility(riderRecord) {
      const riderEligibilityCategories = riderRecord.get('eligibility.categories') || [];

      if (riderEligibilityCategories.length === 0) {
        return true;
      }

      const denyEligibilityCategories = this.get('systemConfig').findEligibilityCategories(_csConfigCategory.ELIGIBILITY_CATEGORY_TYPES.DENY);
      const DENY_CATEGORY_NAMES = denyEligibilityCategories.map(category => {
        const valueObj = category.value;
        const categoryName = valueObj.name || valueObj.Name;
        return categoryName.trim().toLowerCase();
      });
      const containsDenyCategory = riderEligibilityCategories.every(riderCategory => {
        return DENY_CATEGORY_NAMES.includes(riderCategory.type.toLowerCase());
      });
      const containsRiderCategory = riderEligibilityCategories.every(riderCategory => {
        const riderEligibilityCategoryList = this.get('systemConfig').findEligibilityCategories(riderCategory.type.toLowerCase());
        const CATEGORY_NAMES = riderEligibilityCategoryList.map(category => {
          const valueObj = category.value;
          const categoryName = valueObj.name || valueObj.Name;
          return categoryName.trim().toLowerCase();
        });
        return CATEGORY_NAMES.includes(riderCategory.type.toLowerCase());
      }); // if eligibility category has full name and type then we can allow user to create booking
      // so, checking the rider eligibility category in the config-item

      if (!containsRiderCategory) {
        this.get('notifications').warning('Passenger has an eligibility that does not match an eligibility in System Configuration.');
        return true;
      }

      if (containsDenyCategory) {
        this.get('notifications').info(_notifications.default.INVALID_ELIGIBILITY);
        return true;
      }

      return false;
    },

    async createBookingOnDoubleClick() {
      const isBookingEditMode = false;
      const newBookingComponent = this.get('newBookingComponent');
      const doubleClickedRider = this.get('booking.selectedRiders.firstObject');
      const booking = await this.get('booking').createBookingRecord(false);
      this.set('booking.activeBooking', booking);
      this.get('editModal').open(newBookingComponent, [isBookingEditMode, doubleClickedRider, booking]);
    },

    /**
     * set the checked riders for future use(NYAAR-18858)
     * @param {*} row(row which is clicked)
     * @param {*} riderRecord(data about the rider)
     */
    setCheckedRiders(row, riderRecord) {
      const isChecked = row.get('isChecked');

      if (isChecked) {
        this.get('booking.checkedRiders').pushObject(riderRecord);
        return;
      }

      this.get('booking.checkedRiders').removeObject(riderRecord);
    },

    forceSingleSelection(row, selectedRows, filteredRows) {
      const selectedRiderId = row.get('id');
      selectedRows.forEach(selectedRow => {
        if (selectedRow.get('id') !== selectedRiderId) {
          const riderRecord = this.get('records').findBy('id', selectedRow.get('id'));
          this.updateSelectedRiderList(riderRecord, false);
          filteredRows.push(selectedRow);
        }
      });
    },

    actions: {
      async onRowClick(row, event) {
        const rows = this.get('table.rows');
        const selectedRows = rows.filter(rowArr => rowArr.isChecked);
        const filteredRows = rows.filter(rowArr => !rowArr.isChecked); // this is a hack because light-table has a bug with shift select. It bypass single selection
        // even when parameter is set to not have multi-select

        this.forceSingleSelection(row, selectedRows, filteredRows);
        filteredRows.setEach('isChecked', false);
        filteredRows.setEach('selected', false);

        try {
          const riderRecord = this.get('records').findBy('id', row.get('id'));
          this.set('booking.isPassengerRowChecked', true);
          this.set('booking.passengerRowSelected', true);

          if (this.checkIfRiderIsInActive(riderRecord)) {
            this.get('notifications').info(_notifications.default.BOOKING.IN_ACTIVE);
            return;
          }

          this.get('workspace').set('isGlobalSpinnerVisible', true);
          await this.get('booking').fetchEligibilityForRider(riderRecord);

          if (Ember.$(event.target).is('[type="checkbox"]')) {
            const isChecked = event.target.checked;

            if (isChecked) {
              if (this.checkRiderHasInValidEligibility(riderRecord)) {
                this.get('workspace').set('isGlobalSpinnerVisible', false);
                return;
              }
            }

            this.updateSelectedRiderList(riderRecord, isChecked);
            row.set('isChecked', isChecked);
            row.set('selected', isChecked);
            this.setCheckedRiders(row, riderRecord);
            return;
          }

          if (this.checkRiderHasInValidEligibility(riderRecord)) {
            this.get('workspace').set('isGlobalSpinnerVisible', false);
            return;
          }

          const riderExists = this.get('booking.selectedRiders').find(record => {
            return record.get('id') === riderRecord.get('id');
          });
          row.set('isChecked', !riderExists);
          row.set('selected', !riderExists);
          this.updateSelectedRiderList(riderRecord, !riderExists);
          this.setCheckedRiders(row, riderRecord);
        } catch (err) {
          let message = 'Failed to create booking. Passenger information cannot be retrieved.';

          if (err.status === 401) {
            message = 'Session expired , Please login again';
            window.location.assign('/login');
          }

          this.get('notifications').warning(message);
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          row.set('isChecked', false);
          row.set('selected', false);
          return;
        }
      },

      onRowDoubleClick(row) {
        const riderRecord = this.get('records').findBy('id', row.get('id'));

        if (this.checkIfRiderIsInActive(riderRecord)) {
          this.get('notifications').info(_notifications.default.BOOKING.IN_ACTIVE);
          return;
        }

        this.get('booking').clearSelectedRiders();
        this.unSelectAllRows();
        this.updateSelectedRiderList(riderRecord, true);
        row.set('isChecked', true);
        row.set('selected', true);
        this.createBookingOnDoubleClick();
      },

      onRowRightClick() {
        this.set('dynamicSingleActions', [this.get('activityLog'), this.get('tripHistoryReport')]);

        this._super(...arguments);
      }

    }
  });

  _exports.default = _default;
});