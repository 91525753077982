define("adept-iq/serializers/avlm-vehicle-radius", ["exports", "adept-iq/serializers/-playbackService"], function (_exports, _playbackService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _playbackService.default.extend({
    normalize(modelClass, resourceHash) {
      resourceHash.id = resourceHash.attributes.vehicleid ? `${resourceHash.attributes.vehicleId}` : resourceHash.id;

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    modelNameFromPayloadKey(key) {
      if (key === 'radius-vehicle') {
        return 'avlm-vehicle-radius';
      }

      return this._super(key);
    },

    normalizeQueryResponse(store, clazz, payload) {
      const resultedPayload = [];

      const _data = [].concat(payload.data);

      payload.data = _data; // workaround for this API not returns payload type

      if (!Ember.isEmpty(payload.data)) {
        payload.data.forEach(item => {
          const data = item.attributes;

          if (!Ember.isEmpty(data)) {
            const keys = Object.keys(data);
            let vehicle = {
              driverid: '',
              vehicleid: '',
              otp: '',
              drivername: '',
              type: ''
            };
            if (data.vehicle) vehicle = data.vehicle;

            if (keys.includes('lat') && keys.includes('lng') && data.lng !== null && data.lat !== null) {
              item.attributes = { ...data,
                receivedAt: data.receivedat,
                vehicleId: vehicle.vehicleid,
                driverId: vehicle.driverid,
                otp: vehicle.otp,
                driverName: vehicle.drivername,
                vehicleType: vehicle.type,
                provider: data.vehicle.provider
              };
              item.type = 'radius-vehicle';
              item.id = data.vehicle.id;
              resultedPayload.push(item);
            }
          }
        });
        payload.data = resultedPayload;
      }

      return this._super(store, clazz, payload);
    }

  });

  _exports.default = _default;
});