define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/position/validation", ["exports", "ember-changeset-validations/validators", "adept-iq/validators/validate-duplicate-field"], function (_exports, _validators, _validateDuplicateField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALIDATIONS = void 0;
  const VALIDATIONS = {
    displayName: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Description',
      message: '{description} is required'
    }), (0, _validateDuplicateField.validateDuplicateField)({
      message: 'Description cannot duplicate an existing Description.'
    })]
  };
  _exports.VALIDATIONS = VALIDATIONS;
});