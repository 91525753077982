define("adept-iq/pods/components/side-drawer/map-label-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iWXtuRqW",
    "block": "{\"symbols\":[\"label\"],\"statements\":[[7,\"table\"],[11,\"id\",\"map-filter-preview\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"th\"],[9],[0,\"\\n        \"],[7,\"h4\"],[9],[0,\"Label Preview\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"labelData\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"isVisible\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[7,\"td\"],[9],[7,\"b\"],[9],[1,[22,1,[\"label\"]],false],[10],[0,\": \"],[1,[22,1,[\"valuePreview\"]],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/side-drawer/map-label-preview/template.hbs"
    }
  });

  _exports.default = _default;
});