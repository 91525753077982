define("adept-iq/serializers/rider-travel-need", ["exports", "adept-iq/serializers/-rms-riderManagement", "adept-iq/serializers/rider"], function (_exports, _rmsRiderManagement, _rider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rmsRiderManagement.default.extend({
    serialize() {
      const json = this._super(...arguments);

      json.data.type = 'riderTravelNeed';

      if (!Ember.isNone(json.data.relationships)) {
        const riderRelationship = json.data.relationships.rider;

        if (!Ember.isNone(riderRelationship)) {
          riderRelationship.data.type = 'rider';
        }

        const travelNeedTypeRelationship = json.data.relationships.travelNeedType;

        if (!Ember.isNone(travelNeedTypeRelationship)) {
          travelNeedTypeRelationship.data.type = 'travelNeedTypeName';
          json.data.relationships.travelNeedTypeName = json.data.relationships.travelNeedType;
          delete json.data.relationships.travelNeedType;
        }

        const passengerTypeRelationship = json.data.relationships.passengerType;

        if (!Ember.isNone(passengerTypeRelationship)) {
          passengerTypeRelationship.data.type = 'passengerTypeName';
          json.data.relationships.passengerTypeName = json.data.relationships.passengerType;
          delete json.data.relationships.passengerType;
        }
      }

      return json;
    },

    payloadKeyFromModelName(modelName) {
      return modelName;
    },

    normalize(modelClass, resourceHash) {
      if (resourceHash && resourceHash.relationships && !Ember.isNone(resourceHash.relationships.travelNeedTypeName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.travelNeedType = {
          data: {
            type: 'travel-need-type',
            id: resourceHash.relationships.travelNeedTypeName.data.id
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.relationships.travelNeedTypeName;
      }

      if (resourceHash && resourceHash.relationships && !Ember.isNone(resourceHash.relationships.passengerTypeName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.passengerType = {
          data: {
            type: 'passenger-type',
            id: resourceHash.relationships.passengerTypeName.data.id
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.relationships.passengerTypeName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeResponse: function (store, primaryModelClass, payload) {
      const riderSerializer = new _rider.default();
      const data = Ember.makeArray(payload.data);

      for (let i = 0; i < data.length; i++) {
        data[i].type = 'rider-travel-need';

        if (!Ember.isNone(data[i].relationships.rider) && !Ember.isNone(data[i].relationships.rider.data)) {
          data[i].relationships.rider.data.type = 'rider';
        }

        if (!Ember.isNone(data[i].relationships.travelNeedTypeName.data)) {
          data[i].relationships.travelNeedType = data[i].relationships.travelNeedTypeName;
          data[i].relationships.travelNeedType.data.type = 'travel-need-type';
          delete data[i].relationships.travelNeedTypeName;
        }

        if (!Ember.isNone(data[i].relationships.passengerTypeName.data)) {
          data[i].relationships.passengerType = data[i].relationships.passengerTypeName;
          data[i].relationships.passengerType.data.type = 'passenger-type';
          delete data[i].relationships.passengerTypeName;
        }
      }

      if (!Ember.isNone(payload.included) && !Ember.isNone(payload.included.length)) {
        for (let i = 0; i < payload.included.length; i++) {
          const include = payload.included[i];

          if (include.type === 'rider') {
            riderSerializer.normalizeResponse(store, primaryModelClass, {
              data: include
            });
          }

          if (include.type === 'travelNeedTypeName') {
            include.type = 'travel-need-type';
          }

          if (include.type === 'passengerTypeName') {
            include.type = 'passenger-type';
          }
        }
      }

      return payload;
    }
  });

  _exports.default = _default;
});