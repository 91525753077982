define("adept-iq/models/speed-region-address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    streetNumber: attr('string'),
    streetAddress: attr('string'),
    locality: attr('string', {
      defaultValue: ''
    }),
    subLocality: attr('string'),
    region: attr('string', {
      defaultValue: ''
    }),
    postalCode: attr('string', {
      defaultValue: ''
    }),
    country: attr('string', {
      defaultValue: ''
    }),
    lat: attr('number'),
    lng: attr('number'),
    isSoftDeletedRow: attr('boolean', {
      defaultValue: false
    }),
    isNewRow: attr('boolean', {
      defaultValue: false
    }),
    fullAddress: Ember.computed('streetNumber', 'streetAddress', 'locality', 'subLocality', 'region', 'postalCode', 'alias', function () {
      const streetNumber = this.get('streetNumber');
      const streetAddress = this.get('streetAddress');
      const localityOrSubLocality = this.get('locality') || this.get('subLocality') || '';
      const region = this.get('region');
      const postalCode = this.get('postalCode');
      const address = []; // this code is to avoid ', , ,' in leg form component when we bind empty address Record

      if (Ember.isEmpty(streetNumber) && Ember.isEmpty(localityOrSubLocality) && Ember.isEmpty(postalCode)) {
        return '';
      }

      address.push(streetNumber);
      address.push(streetAddress);
      const result = [address.join(' ')];
      result.push(localityOrSubLocality);
      result.push(region);
      result.push(postalCode);
      return result.join(', ');
    }).readOnly()
  });

  _exports.default = _default;
});