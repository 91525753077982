define("adept-iq/classes/polylines/base", ["exports", "md5"], function (_exports, _md) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const dynamicProperties = ['style', 'pattern', 'points', 'opacity', 'label', 'isHidden'];

  var _default = Ember.Object.extend(Ember.Evented, {
    dynamicProperties,
    id: null,
    isActive: false,
    isHidden: false,
    // dynamic polylines are expected to change coordinates at any time,
    // e.g. navigationInfo; there is a performance cost associated
    isDynamic: true,
    points: null,
    opacity: 1.0,
    label: null,
    layer: null,
    layerType: null,
    style: null,
    // components/iq-widgets/map-widget/config/polyline.js
    pattern: null,
    // components/iq-widgets/map-widget/config/patterns.js
    options: null,
    // overrides polyline style
    polyline: null,
    // tomtom polyline
    decorator: null,
    // tomtom decorator
    pointsDigest: Ember.computed('points', function () {
      const points = this.get('points');
      return (0, _md.default)(JSON.stringify(points));
    })
  });

  _exports.default = _default;
});