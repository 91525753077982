define("adept-iq/pods/components/generic-widgets/tree-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mw9Hv/CD",
    "block": "{\"symbols\":[\"node\"],\"statements\":[[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"tree-widget-loading-spinner\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-circle-o-notch fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[23,[\"flattenedNodes\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"generic-widgets/tree-widget/tree-widget-row\",null,[[\"node\",\"isSearchEnabled\"],[[27,\"readonly\",[[22,1,[]]],null],[27,\"readonly\",[[23,[\"isSearchEnabled\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/generic-widgets/tree-widget/template.hbs"
    }
  });

  _exports.default = _default;
});