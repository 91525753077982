define("adept-iq/config/itinerary-action", ["exports", "adept-iq/config/icon-paths"], function (_exports, _iconPaths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ITINERARY_TRAVEL_MODE = _exports.ITINERARY_ACTION_MAP = void 0;
  const ITINERARY_TRAVEL_MODE = {
    LIGHT_RAIL: '0',
    SUBWAY: '1',
    RAIL: '2',
    BUS: '3',
    FERRY: '4',
    CABLE_CAR: '5',
    GONDOLA: '6',
    FUNICULAR: '7',
    PARATRANSIT: '101',
    WALK: '100',
    TAXI: '102'
  };
  _exports.ITINERARY_TRAVEL_MODE = ITINERARY_TRAVEL_MODE;
  const ITINERARY_ACTION_MAP = {
    [ITINERARY_TRAVEL_MODE.LIGHT_RAIL]: {
      action: 'lightrail',
      icon: _iconPaths.default.requestdetails.busicon
    },
    [ITINERARY_TRAVEL_MODE.SUBWAY]: {
      action: 'subway',
      icon: _iconPaths.default.requestdetails.busicon
    },
    [ITINERARY_TRAVEL_MODE.RAIL]: {
      action: 'rail',
      icon: _iconPaths.default.requestdetails.busicon
    },
    [ITINERARY_TRAVEL_MODE.BUS]: {
      action: 'bus',
      icon: _iconPaths.default.requestdetails.busicon
    },
    [ITINERARY_TRAVEL_MODE.FERRY]: {
      action: 'ferry',
      icon: _iconPaths.default.requestdetails.busicon
    },
    [ITINERARY_TRAVEL_MODE.CABLE_CAR]: {
      action: 'cablecar',
      icon: _iconPaths.default.requestdetails.busicon
    },
    [ITINERARY_TRAVEL_MODE.GONDOLA]: {
      action: 'gondola',
      icon: _iconPaths.default.requestdetails.busicon
    },
    [ITINERARY_TRAVEL_MODE.FUNICULAR]: {
      action: 'funicular',
      icon: _iconPaths.default.requestdetails.busicon
    },
    [ITINERARY_TRAVEL_MODE.PARATRANSIT]: {
      action: 'paratransit',
      icon: _iconPaths.default.requestdetails.paratransiticon
    },
    [ITINERARY_TRAVEL_MODE.WALK]: {
      action: 'walk',
      icon: _iconPaths.default.requestdetails.walkicon
    },
    [ITINERARY_TRAVEL_MODE.TAXI]: {
      action: 'taxi',
      icon: _iconPaths.default.requestdetails.taxiicon
    }
  };
  _exports.ITINERARY_ACTION_MAP = ITINERARY_ACTION_MAP;
});