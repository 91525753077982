define("adept-iq/pods/components/side-drawer/trip-transfer/base-cell/component", ["exports", "adept-iq/pods/components/side-drawer/trip-transfer/base-cell/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['solution-table-base-cell', 'data-test-solution-table-base-cell'],
    attributeBindings: ['tabindex'],
    layout: _template.default,
    solution: Ember.computed.readOnly('row.content'),
    isDisabled: false,
    isExceeded: Ember.computed.alias('solution.extensionExceeded'),
    tooltipText: Ember.computed('isExceeded', function () {
      const isExceeded = this.get('isExceeded');
      const solution = this.get('solution');

      if (Ember.isNone(isExceeded)) {
        return null;
      }

      if (isExceeded) {
        return `Transfer extends route beyond planned end time.
      Planned End Time: ${solution.plannedEndTime},
      Route End ETA: ${solution.prevEndEta},
      Solution Route End ETA: ${solution.endEta},
      Change the route end time and try again`;
      }
    }),
    displayTooltip: Ember.computed('tooltipText', 'isExceeded', function () {
      const tooltipText = this.get('tooltipText');
      const isExceeded = this.get('isExceeded');
      return Ember.isPresent(tooltipText) && isExceeded;
    }),
    tabindex: 0
  });

  _exports.default = _default;
});