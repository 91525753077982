define("adept-iq/tests/factories/avlm-vehicle-info", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('avlm-vehicle-info', {
    default: {
      deviceType: 'Android',
      deviceVersion: '2.1.1.3.4',
      token: 'f4KZnGWgMRU:APA91bEnGrtcc0ifPc3jOifDYjZ7dFBVNWUb08H8K2jZJ-BDKZ3zATVQALAUIj1iVxCs7pMs9vp1nPAZvKudCbg7BRCSYEexvJ_FInDe1iV8msNOuE-S7CSE-uKYdWWnD-rosH9j6Kh_',
      appVersion: '1.0.181',
      deviceHardwareId: 'd8bfafd8d7282801',
      cellularProvider: 'telus',
      cellularType: '3G'
    }
  });
});