define("adept-iq/classes/polylines/routes/base", ["exports", "adept-iq/classes/polylines/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BASE_ROUTE_STYLE = void 0;
  const BASE_ROUTE_STYLE = 'route';
  _exports.BASE_ROUTE_STYLE = BASE_ROUTE_STYLE;

  var _default = _base.default.extend({
    pattern: Ember.computed.readOnly('style'),
    // `BasePolyline` overrides
    style: Ember.computed.readOnly('baseRouteStyle'),
    // local properties
    baseRouteStyle: BASE_ROUTE_STYLE
  });

  _exports.default = _default;
});