define("adept-iq/tests/factories/leg", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const purposeArray = ['work', 'medical', 'school'];

  _emberDataFactoryGuy.default.define('leg', {
    sequences: {
      requestTimes: () => (0, _moment.default)().startOf('day').add(8, 'hours').toDate(),
      purposes: num => purposeArray[num % purposeArray.length]
    },
    default: {
      requestTime: _emberDataFactoryGuy.default.generate('requestTimes'),
      anchor: 'pick',
      // default pick anchor
      purpose: _emberDataFactoryGuy.default.generate('purposes'),
      notes: 'some notes'
    },
    traits: {
      drop: {
        // eslint-disable-next-line no-return-assign
        anchor: f => f.anchor = 'drop'
      }
    }
  });
});