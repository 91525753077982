define("adept-iq/topic-transforms/subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload
  /*, ENV*/
  ) {
    if (!payload.data || !payload.data.relationships) return payload;
    const {
      originPlace,
      destinationPlace,
      travelNeeds
    } = payload.data.relationships;
    payload.data.type = 'subscription';

    if (originPlace) {
      delete payload.data.relationships.originPlace;
      payload.data.relationships.origin = originPlace;
    }

    if (destinationPlace) {
      delete payload.data.relationships.destinationPlace;
      payload.data.relationships.destination = destinationPlace;
    }

    if (travelNeeds) {
      delete payload.data.relationships.travelNeeds;
      payload.data.relationships.subscriptionTravelNeeds = travelNeeds;
    }

    return payload;
  }
});