define("adept-iq/pods/components/schedule-generate-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4MJbw1wf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isWaitlisted\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"overlayClass\",\"translucentOverlay\"],[\"loading-modal-styles-overlay\",true]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"tooltip-modal\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"tooltip-modal-header\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"tooltip-modal-body\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"tooltip-text\"],[9],[0,\"\\n          \"],[7,\"p\"],[9],[1,[21,\"description\"],false],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"spinner\"],[9],[0,\"\\n            \"],[7,\"i\"],[11,\"class\",\"fa fa-2x fa-spinner fa-pulse\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"progress\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"progress-bar progress-bar-striped progress-bar-animated\"],[11,\"role\",\"progressbar\"],[12,\"aria-valuenow\",[28,[[21,\"progress\"]]]],[11,\"aria-valuemin\",\"0\"],[11,\"aria-valuemax\",\"100\"],[12,\"style\",[28,[\"width: \",[21,\"progress\"],\"%\"]]],[9],[1,[21,\"progress\"],false],[0,\"%\"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/schedule-generate-modal/template.hbs"
    }
  });

  _exports.default = _default;
});