define("adept-iq/pods/components/side-drawer/rescue-route/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tripColumns = _exports.rowComponent = void 0;
  const rowComponent = 'side-drawer/rescue-route/table/row';
  _exports.rowComponent = rowComponent;
  const tripColumns = [{
    id: 'checkbox',
    valuePath: 'checked',
    cellComponent: 'side-drawer/rescue-route/table/cells/check-box',
    component: 'side-drawer/rescue-route/table/columns/check-box',
    sortable: false,
    width: '30px',
    minResizeWidth: 50
  }, {
    label: 'Status',
    valuePath: 'otp',
    resizable: true,
    sortable: false,
    highlightable: true,
    minResizeWidth: 50
  }, {
    label: 'Trip ID',
    valuePath: 'tripId',
    resizable: true,
    sortable: false,
    minResizeWidth: 100
  }, {
    label: 'Last Name',
    valuePath: 'rider.lastName',
    resizable: true,
    sortable: false,
    minResizeWidth: 100
  }, {
    label: 'Anchor ETA',
    valuePath: 'anchorStop.stopPoint.etaNavStopPoint.eta',
    cellComponent: 'table/cells/trip-eta',
    resizable: true,
    sortable: false,
    highlightable: true,
    minResizeWidth: 75
  }];
  _exports.tripColumns = tripColumns;
});