define("adept-iq/classes/markers/stops/stop-point", ["exports", "adept-iq/classes/markers/stops/base", "adept-iq/utils/unwrapProxy"], function (_exports, _base, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const OTP_CODE_LATE = 'L';
  const OTP_CODE_DANGER = 'D'; // see `app/pods/components/iq-widgets/map-widget/config/marker.js`

  const NOSHOW_STYLE = 'noshow';
  const BREAK_STYLE = 'break';
  const OTP_STYLE_SUFFIX_LATE = 'Late';
  const OTP_STYLE_SUFFIX_DANGER = 'Danger';
  const NEXT_STYLE_SUFFIX = 'Next';
  const WHEELCHAIR_STYLE_SUFFIX = 'Wheelchair';
  const PERFORMED_STYLE_SUFFIX = 'Performed';

  var _default = _base.default.extend({
    record: null,
    // this is a stop-point
    // `BaseMarker` overrides
    isActive: true,
    lat: Ember.computed.readOnly('record.lat'),
    lng: Ember.computed.readOnly('record.lng'),
    // `BaseStopMarker` overrides
    stopPoint: Ember.computed.readOnly('record'),
    travelNeeds: Ember.computed.readOnly('record.trip.segment.leg.legTravelNeeds'),
    stopType: Ember.computed.readOnly('record.stopType'),
    otp: Ember.computed.readOnly('record.otp'),
    isWheelchair: Ember.computed.readOnly('hasWheelchairTravelNeed'),
    stopId: Ember.computed.readOnly('record.id'),
    computedStatus: Ember.computed.readOnly('record.computedStatus'),
    isPerformed: Ember.computed.readOnly('record.isPerformed'),
    isNoShow: Ember.computed.readOnly('record.isNoShow'),
    isPick: Ember.computed.readOnly('record.isPick'),
    isDrop: Ember.computed.readOnly('record.isDrop'),
    isBreak: Ember.computed.readOnly('record.isBreak'),
    isGarage: Ember.computed.readOnly('record.isGarage'),
    isGaragePullIn: Ember.computed.readOnly('record.isGaragePullin'),
    isGaragePullOut: Ember.computed.readOnly('record.isGaragePullout'),
    isDeploy: Ember.computed.equal('stopType', 'deployStop'),
    isStopHidden: Ember.computed.and('isNoShow', 'isDrop'),
    label: Ember.computed.oneWay('iqStopLabel'),
    isHighLighted: Ember.computed.or('record.{isHighLighted,trip.isHighLighted,trip.rider.isHighLighted}'),
    isNext: Ember.computed('stopId', 'currentStopId', function () {
      const stopId = this.get('stopId');
      return Ember.isPresent(stopId) && stopId === this.get('currentStopId');
    }),
    // local properties
    currentStopId: Ember.computed('record.dispatchRoute.currentStopOrdinalId', function () {
      return this.get('record.dispatchRoute.currentStopOrdinalId');
    }),
    hasWheelchairTravelNeed: Ember.computed('travelNeeds.@each.{isWheelchair,travelNeedTypeName}', function () {
      const travelNeeds = (0, _unwrapProxy.unwrapProxy)(this.get('travelNeeds'));
      if (Ember.isEmpty(travelNeeds)) return false;
      return travelNeeds.any(travelNeed => travelNeed.get('isWheelchair'));
    }),
    iqStopLabel: Ember.computed('stopType', 'id', function () {
      const stopType = this.get('stopType');
      const stopId = this.get('id');
      return `${Ember.String.capitalize(stopType)} stop ${stopId}`;
    }),
    stopLabel: Ember.computed('stopType', function () {
      const stopType = this.get('stopType');
      return `${Ember.String.capitalize(stopType)} stop`;
    }),
    stopStyle: Ember.computed('isNoShow', 'isBreak', 'isPerformed', 'typeStylePrefix', 'otpStyleSuffix', 'nextStyleSuffix', 'wheelchairStyleSuffix', function () {
      if (this.get('isNoShow')) return NOSHOW_STYLE;
      if (this.get('isBreak')) return BREAK_STYLE;
      const typeStylePrefix = this.get('typeStylePrefix');

      if (this.get('isPerformed')) {
        return [typeStylePrefix, PERFORMED_STYLE_SUFFIX].join('');
      }

      const suffixes = [this.get('otpStyleSuffix'), this.get('nextStyleSuffix'), this.get('wheelchairStyleSuffix')].compact();
      const style = [typeStylePrefix, ...suffixes].join('');
      return style;
    }),
    typeStylePrefix: Ember.computed('stopType', function () {
      switch (this.get('stopType')) {
        case 'pick':
          return 'pick';

        case 'deployStop':
          return 'deployStop';

        case 'drop':
          return 'drop';

        case 'pullinGarage':
        case 'pulloutGarage':
          return 'garage';
        // both types use same icon

        default:
          // we don't care about break, noshow, etc.
          return null;
      }
    }),
    otpStyleSuffix: Ember.computed('otp', function () {
      switch (this.get('otp')) {
        case OTP_CODE_LATE:
          return OTP_STYLE_SUFFIX_LATE;

        case OTP_CODE_DANGER:
          return OTP_STYLE_SUFFIX_DANGER;

        default:
          return null;
      }
    }),
    nextStyleSuffix: Ember.computed('isNext', function () {
      return this.get('isNext') ? NEXT_STYLE_SUFFIX : null;
    }),
    wheelchairStyleSuffix: Ember.computed('isWheelchair', 'isGarage', function () {
      // garage icons do not have a wheelchair version
      if (this.get('isGarage')) return null;
      return this.get('isWheelchair') ? WHEELCHAIR_STYLE_SUFFIX : null;
    })
  });

  _exports.default = _default;
});