define("adept-iq/serializers/avlm-canned-message", ["exports", "adept-iq/serializers/-avlm-avlmService", "adept-iq/models/avlm-emergency-message", "adept-iq/config/environment"], function (_exports, _avlmAvlmService, _avlmEmergencyMessage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EMERGENCY_PRIORITY_CODE = void 0;
  const EMERGENCY_PRIORITY_CODE = 'E';
  _exports.EMERGENCY_PRIORITY_CODE = EMERGENCY_PRIORITY_CODE;

  var _default = _avlmAvlmService.default.extend({
    attrs: {
      responseMessage: {
        serialize: false
      },
      ackTime: {
        serialize: false
      },
      driverEndPointType: {
        serialize: false
      }
    },

    serialize() {
      const json = this._super(...arguments);

      let driver = null;

      if (json.data.relationships) {
        if (json.data.relationships.driver) {
          driver = this.store.peekRecord('driver', json.data.relationships.driver.data.id);
        }
      }

      const responseType = json.data.attributes.responseTypeText;
      json.data.attributes.dispatcher = {
        endPointType: 'sender'
      };
      json.data.attributes.responseType = {
        statusCode: _avlmEmergencyMessage.CANNED_RESPONSE_TYPE_CODES[responseType],
        status: responseType
      };

      if (driver) {
        json.data.attributes.driver = {
          endPointType: 'recipient',
          id: driver.get('driverId')
        };
      }

      if (!json.data.attributes.deletedTime) delete json.data.attributes.deletedTime;
      if (!json.data.attributes.readTime) delete json.data.attributes.readTime;
      if (!json.data.attributes.deliveredTime) delete json.data.attributes.deliveredTime;
      if (!json.data.attributes.type) delete json.data.attributes.type;
      delete json.data.attributes.responseTypeText;
      delete json.data.relationships;
      return json;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      const relationships = payload.data.relationships ? payload.data.relationships : {};
      const payloadType = 'avlm-canned-message'; //const messageId = payload.data.attributes.id;
      // When trying to read from menu action, this show wierd message. Commenting it out
      // payload.data.attributes.messageNr = payload.data.attributes.message;

      payload.data.attributes.responseTypeText = payload.data.attributes.responseType;
      payload.data.attributes.responseType = payload.data.attributes.responseTypeLabel;
      payload.data.attributes.responseMessage = payload.data.attributes.responseReceived;
      payload.data.attributes.ackTime = payload.data.attributes.responseReceivedTime;
      payload.data.attributes.createdTime = payload.data.attributes.sentTime;
      payload.data.attributes.isNewMessageReceived = true;

      if (payload.data.attributes.replyToMessageId) {
        payload.data.attributes.replyToMsgId = payload.data.attributes.replyToMessageId;
      } else {
        payload.data.attributes.replyToMsgId = payload.data.attributes.id;
      }

      if (payload.data.attributes.message) {
        const parts = payload.data.attributes.message.split(':');

        if (parts.length > 1) {
          payload.data.attributes.subject = parts[0];
          payload.data.attributes.body = parts[1];
        } else {
          payload.data.attributes.body = payload.data.attributes.message;
        }
      }

      if (payload.data.attributes.vehicleId) {
        relationships[_environment.default.APP.avlmLite ? 'avlmVehicle' : 'vehicle'] = {
          data: {
            type: _environment.default.APP.avlmLite ? 'avlm-vehicle' : 'vehicle',
            id: `${payload.data.attributes.vehicleId}`
          }
        };
      }

      if (payload.data.attributes.routeId) {
        relationships[_environment.default.APP.avlmLite ? 'avlmRoute' : 'route'] = {
          data: {
            type: _environment.default.APP.avlmLite ? 'avlm-route' : 'route',
            id: `${payload.data.attributes.routeId}`
          }
        };
      }

      if (payload.data.attributes.driverPk) {
        relationships[_environment.default.APP.avlmLite ? 'avlmDriver' : 'driver'] = {
          data: {
            type: _environment.default.APP.avlmLite ? 'avlm-driver' : 'driver',
            id: `${payload.data.attributes.driverPk}`
          }
        };
      }

      if (payload.data.attributes.alertId) {
        relationships.alert = {
          data: {
            type: 'avlm-alert',
            id: `${payload.data.attributes.alertId}`
          }
        };
      }

      if (!_environment.default.APP.avlmLite && payload.data.attributes.routeVehicleDriverId) {
        relationships.avlmRouteVehicleDriver = {
          data: {
            type: 'avlm-route-vehicle-driver',
            // No check for AVLM Model? Same Check Ashraf TODO
            id: `${payload.data.attributes.routeVehicleDriverId}`
          }
        };
      }

      if (payload.data.attributes.templateId) {
        relationships.template = {
          data: {
            type: 'avlm-canned-message-template',
            id: `${payload.data.attributes.templateId}`
          }
        };
      }

      if (payload.data.attributes.replyToMessageId) {
        const originalMessage = {
          id: payload.data.attributes.replyToMessageId,
          type: `${payloadType}`,
          attributes: {
            body: payload.data.attributes.body
          }
        };
        payload.included = payload.included || [];
        payload.included.push(originalMessage);
      } // Duplicate canned-message to emergency-message


      if (payload.data.attributes.priority === EMERGENCY_PRIORITY_CODE) {
        const eRelationships = { ...relationships
        };

        if (payload.data.attributes.replyToMessageId) {
          eRelationships.replyToMsg = {
            data: {
              type: 'avlm-emergency-message',
              id: `${payload.data.attributes.replyToMessageId}`
            }
          };
        }

        const emergencyMessagePayload = {
          id: payload.data.id,
          type: 'avlm-emergency-message',
          attributes: payload.data.attributes,
          relationships: eRelationships
        };
        payload.included = payload.included || [];
        payload.included.push(emergencyMessagePayload);
      }

      payload.data.relationships = relationships;
      return payload;
    }

  });

  _exports.default = _default;
});