define("adept-iq/pods/components/iq-widgets/form-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/form-widget/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editModal: Ember.inject.service(),
    isShrunken: false,
    service: Ember.computed.alias('editModal'),
    actions: {
      onShrinkClick() {
        this.toggleProperty('isShrunken');
      }

    }
  });

  _exports.default = _default;
});