define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel/forward", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api"], function (_exports, _react, _panel, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Forward = () => {
    const {
      forwardPlay
    } = (0, _panel.usePanel)();
    const {
      eventData
    } = (0, _api.useAPI)();

    const [isDisabled, setIsDisabled] = _react.default.useState(true);

    _react.default.useEffect(() => {
      if (eventData.length > 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }, [eventData]);

    return _react.default.createElement("button", {
      className: "btn circle-btn",
      disabled: isDisabled,
      onClick: () => forwardPlay()
    }, _react.default.createElement("span", null, isDisabled ? _react.default.createElement("svg", {
      width: "26",
      height: "25",
      viewBox: "0 0 26 25",
      xmlns: "http://www.w3.org/2000/svg"
    }, _react.default.createElement("title", null, "foward/disabled"), _react.default.createElement("defs", null, _react.default.createElement("linearGradient", {
      x1: "50%",
      y1: "0%",
      x2: "50%",
      y2: "100%",
      id: "a"
    }, _react.default.createElement("stop", {
      stopColor: "#F9F9F9",
      offset: "0%"
    }), _react.default.createElement("stop", {
      stopColor: "#D1D1D1",
      offset: "100%"
    }))), _react.default.createElement("g", {
      transform: "translate(.636 .11)",
      fill: "none",
      fillRule: "evenodd"
    }, _react.default.createElement("circle", {
      stroke: "#B4B4B4",
      fill: "url(#a)",
      cx: "12.442",
      cy: "12.442",
      r: "11.942"
    }), _react.default.createElement("path", {
      d: "M11.558 9.371a.533.533 0 01.578.115l2.407 2.331a.679.679 0 01-.005.986l-2.419 2.331a.505.505 0 01-.56.115c-.205-.081-.302-.273-.302-.485V9.856c0-.212.096-.404.3-.485z",
      fill: "#263238",
      fillRule: "nonzero",
      opacity: ".495"
    }))) : _react.default.createElement("svg", {
      width: "26px",
      height: "25px",
      viewBox: "0 0 26 25",
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg"
    }, _react.default.createElement("title", null, "forward"), _react.default.createElement("desc", null, "Created with Sketch."), _react.default.createElement("defs", null, _react.default.createElement("linearGradient", {
      x1: "50%",
      y1: "0%",
      x2: "50%",
      y2: "100%",
      id: "linearGradient-1"
    }, _react.default.createElement("stop", {
      stopColor: "#F9F9F9",
      offset: "0%"
    }), _react.default.createElement("stop", {
      stopColor: "#D1D1D1",
      offset: "100%"
    }))), _react.default.createElement("g", {
      id: "Playback-2.4-Release",
      stroke: "none",
      fill: "none",
      fillRule: "evenodd"
    }, _react.default.createElement("g", {
      id: "forward",
      transform: "translate(0.414389, 0.110400)"
    }, _react.default.createElement("circle", {
      id: "Oval-Copy",
      stroke: "#B4B4B4",
      fill: "url(#linearGradient-1)",
      cx: "12.4415977",
      cy: "12.4415977",
      r: "11.9415977"
    }), _react.default.createElement("g", {
      id: "rewind-button",
      transform: "translate(12.791677, 12.381918) scale(-1, 1) translate(-12.791677, -12.381918) translate(10.791677, 8.881918)",
      fill: "#263238",
      fillRule: "nonzero"
    }, _react.default.createElement("path", {
      d: "M3.23374837,0.489046647 C3.02893683,0.407943299 2.81234485,0.453180226 2.65600145,0.60391613 L0.248750676,2.93539505 C-0.0336435351,3.20778598 -0.0289313552,3.64803822 0.253462856,3.92059071 L2.67249408,6.25239275 C2.82883748,6.40296709 3.02809537,6.44836558 3.23290691,6.36710067 C3.43738186,6.28599732 3.5349913,6.09438662 3.5349913,5.88193463 L3.5349913,0.974212693 C3.53482301,0.761760695 3.4387282,0.569826874 3.23374837,0.489046647 Z",
      id: "Path"
    })))))));
  };

  var _default = Forward;
  _exports.default = _default;
});