define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/suspension/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "moment", "adept-iq/utils/unwrapProxy", "lodash", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/suspension/validation", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/suspension/driver-violation-search-config", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _moment, _unwrapProxy, _lodash, _validation, _driverViolationSearchConfig, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_SUSPENSION_TYPE_MAP = {
    addDriverSuspension: true,
    editDriverSuspension: true,
    deleteDriverSuspension: true
  };
  const SUSPENSION_FIELDS = ['startDate', 'endDate'];

  var _default = Ember.Component.extend({
    classNames: ['driver-suspension-view'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    tableRef: null,
    activitySuspensionTableRef: null,
    disableForm: true,
    // placeholders
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    NOTES_PLACEHOLDER: _placeholders.NOTES_PLACEHOLDER,
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    // passed into view
    editAPI: null,
    selectedDriver: Ember.computed.readOnly('editAPI.selectedRecord'),
    selectedSuspension: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    suspensionQueryParams: null,
    endDate: Ember.computed('driverSuspensionChangeSet.{startDate,endDate}', function () {
      const driverSuspensionChangeSet = this.get('driverSuspensionChangeSet');
      const startDate = (0, _moment.default)(driverSuspensionChangeSet.get('startDate')).format('YYYY-MM-DD');
      const endDate = (0, _moment.default)(driverSuspensionChangeSet.get('endDate')).format('YYYY-MM-DD');
      return (0, _moment.default)(startDate).isAfter(endDate) ? null : driverSuspensionChangeSet.get('endDate');
    }),

    async init() {
      this._super(...arguments);

      const driver = this.get('selectedDriver');
      this.set('activityQueryParams', {
        driverId: `${driver.get('id')}`,
        eventLog: true
      });
      this.set('suspensionQueryParams', {
        filter: `eq(driverId,${driver.get('id')})`
      });
      this.get('refreshTask').perform();
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const tableRef = this.get('tableRef'); // ember concurrency tasks

      const onSaveDriverRecord = this.get('onSaveDriverSuspensionRecord');
      const onDeleteDriverSuspensionRecord = this.get('onDeleteDriverSuspensionRecord');
      const onValidateAction = this.get('validateDriverSuspension'); // normal functions

      const onUndoDriverRecord = this.get('onUndoDriverSuspensionRecord').bind(this);
      const onNewDriverSuspensionRecord = this.get('onNewDriverSuspensionRecord').bind(this);
      const onEditDriverSuspensionRecord = this.get('onEditDriverSuspensionRecord').bind(this);
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverRecord,
          newAction: onNewDriverSuspensionRecord,
          deleteAction: onDeleteDriverSuspensionRecord,
          editAction: onEditDriverSuspensionRecord,
          selectedDriverBelongsTableRef: tableRef,
          hasChanges,
          undoAction: onUndoDriverRecord,
          onValidateAction,
          isChangesHappened: false
        });
      });
      this.addObserver('hasPendingChanges', this.hasPendingChangesObserver);
      this.get('refreshTask').perform().then(() => {
        Ember.run.later(() => {
          this.resetForm();
        }, 1000);
      });
    },

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_SUSPENSION_TYPE_MAP[event.get('actionType')];
    },

    driverSuspensionChangeSet: Ember.computed('selectedSuspension', function () {
      const suspension = (0, _unwrapProxy.unwrapProxy)(this.get('selectedSuspension'));
      return this.setDefaultProperties(suspension);
    }),
    hasPendingChanges: Ember.computed('driverSuspensionChangeSet.changes.length', function () {
      const driverSuspensionChangeSet = this.get('driverSuspensionChangeSet');
      const changes = driverSuspensionChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      const driverSuspensionChangeSet = this.get('driverSuspensionChangeSet');

      if (driverSuspensionChangeSet.get('isNew')) {
        const pendingChanges = this.get('driverSuspensionChangeSet');
        const fields = (0, _lodash.flatten)(pendingChanges.changes).map(change => change.key);
        const isEveyPrePoulatedFieldsChange = fields.length ? (0, _lodash.every)(fields, field => SUSPENSION_FIELDS.includes(field)) : false;
        if (isEveyPrePoulatedFieldsChange) return !isEveyPrePoulatedFieldsChange;
        return !!this.get('hasPendingChanges');
      }

      return !!this.get('hasPendingChanges');
    },

    hasPendingChangesObserver() {
      const editAPI = this.get('editAPI');
      const hasPendingChanges = this.get('hasPendingChanges');
      Ember.run.scheduleOnce('afterRender', () => {
        editAPI.setProperties({
          isChangesHappened: hasPendingChanges
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
    },

    driverViolationIncidentDate: Ember.computed('driverSuspensionChangeSet.driverViolation.incidentDate', function () {
      const incidentDate = this.get('driverSuspensionChangeSet.driverViolation.incidentDate');
      return incidentDate ? incidentDate : null;
    }),

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const store = this.get('store');
      const defaultDate = (0, _moment.default)(new Date());
      let pendingDriverSuspensionRecord = this.get('pendingDriverSuspensionRecord');

      if (record) {
        pendingDriverSuspensionRecord = record;
        this.resetForm();
      } else if (!pendingDriverSuspensionRecord) {
        pendingDriverSuspensionRecord = store.createRecord('driver-suspension');
        this.set('pendingDriverSuspensionRecord', pendingDriverSuspensionRecord);
      }

      if (pendingDriverSuspensionRecord.get('isNew')) {
        pendingDriverSuspensionRecord.setProperties({
          startDate: defaultDate.startOf('day').toDate(),
          endDate: defaultDate.endOf('day').toDate(),
          description: null,
          driverViolation: null,
          notes: null
        });
      }

      this.set('showValidations', false);
      this.setMinDate(pendingDriverSuspensionRecord.get('startDate'));
      return new _emberChangeset.default(pendingDriverSuspensionRecord, (0, _emberChangesetValidations.default)(_validation.DRIVER_SUSPENSION_VALIDATION), _validation.DRIVER_SUSPENSION_VALIDATION);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedSuspension = this.get('selectedSuspension');

      if (selectedSuspension) {
        if (!selectedSuspension.get('isNew') && !selectedSuspension.get('isDeleted')) {
          yield selectedSuspension.reload();
        }
      }

      const tableRef = this.get('tableRef');
      const activitySuspensionTableRef = this.get('activitySuspensionTableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      if (activitySuspensionTableRef) Ember.run.scheduleOnce('afterRender', activitySuspensionTableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        const records = this.get('tableRef.records');

        if (!records.length) {
          editAPI.buttonSettings(true, false, true, true, true);
        } else {
          editAPI.buttonSettings();
        }
      });
    },

    onSaveDriverSuspensionRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const selectedDriver = this.get('selectedDriver');
      const pendingDriverSuspensionRecord = this.get('driverSuspensionChangeSet');
      const tooltip = this.get('tooltip'); // validation

      const isValidDriverSuspension = yield this.validateDriverSuspension.perform();

      if (isValidDriverSuspension) {
        pendingDriverSuspensionRecord.set('driver', selectedDriver);

        try {
          yield pendingDriverSuspensionRecord.save();
          notifications.success('Record successfully saved.');
          this.set('pendingDriverSuspensionRecord', null);
          Ember.run.later(() => {
            this.get('refreshTask').perform().then(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
              this.resetForm();
              tooltip.reset();
            });
          }, 1000);
          return pendingDriverSuspensionRecord;
        } catch (e) {
          notifications.warning('Record failed to save');
          const driverSuspensionRecord = this.get('pendingDriverSuspensionRecord');
          const editDriverSuspensionRecord = this.get('selectedSuspension');
          selectedDriver.rollbackAttributes();
          if (editDriverSuspensionRecord) editDriverSuspensionRecord.rollbackAttributes();
          driverSuspensionRecord.rollbackAttributes();
          console.warn(e); // eslint-disable-line
        }
      }
    }),

    onUndoDriverSuspensionRecord() {
      const driverSuspensionChangeSet = this.get('driverSuspensionChangeSet');
      const editDriverSuspensionRecord = this.get('selectedSuspension');

      if (driverSuspensionChangeSet) {
        driverSuspensionChangeSet.rollback();
      }

      if (editDriverSuspensionRecord) {
        editDriverSuspensionRecord.rollbackAttributes();
      }

      this.setMinDate(driverSuspensionChangeSet.get('startDate'));
    },

    onNewDriverSuspensionRecord() {
      const selectedRow = this.get('selectedRow');
      const defaultDate = (0, _moment.default)().startOf('day').toDate();

      if (selectedRow) {
        selectedRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.set('disableForm', false);
      this.setMinDate(defaultDate);
      this.get('editAPI').buttonSettings(true, true, true, false, false); //False to enable the button, TRUE to disable
    },

    onEditDriverSuspensionRecord() {
      const selectedRow = this.get('selectedRow');
      if (Ember.isEmpty(selectedRow)) return;
      this.get('editAPI').buttonSettings(true, true, true, false, false);
      this.set('disableForm', false);
    },

    onDeleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedSuspension = this.get('selectedSuspension');
      const notifications = this.get('notifications');

      try {
        yield selectedSuspension.destroyRecord();
        notifications.success('Record successfully deleted');
        Ember.run.later(() => {
          this.get('refreshTask').perform().then(() => {
            this.resetForm();
          });
        }, 1000);
      } catch (e) {
        notifications.warning('Record failed to delete');
        console.warn(e); // eslint-disable-line
      }
    }),
    onDeleteDriverSuspensionRecord: (0, _emberConcurrency.task)(function* () {
      const selectedSuspension = this.get('selectedSuspension');
      const tooltip = this.get('tooltip');
      if (!selectedSuspension) return;
      yield tooltip.pushConfirmation({
        title: 'Manage Driver',
        tip: 'Are you sure you want to delete this record?',
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        },

        primaryActionText: 'Confirm',
        primaryAction: () => {
          this.get('onDeleteRecord').perform();
          return tooltip.reset();
        }
      });
    }),
    validateDriverSuspension: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverSuspensionChangeSet = this.get('driverSuspensionChangeSet');
      const driverViolation = (0, _unwrapProxy.unwrapProxy)(driverSuspensionChangeSet.get('driverViolation'));
      yield driverSuspensionChangeSet.validate();
      const startDate = driverSuspensionChangeSet.get('startDate');
      const endDate = driverSuspensionChangeSet.get('endDate');

      if (startDate && endDate) {
        if ((0, _moment.default)(endDate).isBefore((0, _moment.default)(startDate))) {
          driverSuspensionChangeSet.addError('endDate', {
            value: endDate,
            validation: 'End Date must be after Start Date.'
          });
        }
      }

      if (driverViolation) {
        const incidentDate = (0, _moment.default)(driverViolation.get('incidentDate')).startOf('day');
        const momentStartDate = (0, _moment.default)(startDate).startOf('day');

        if ((0, _moment.default)(momentStartDate).isBefore((0, _moment.default)(incidentDate))) {
          driverSuspensionChangeSet.addError('startDate', {
            value: startDate,
            validation: 'The suspension\'s Start Date must be equal to or after the associated violation\'s Incident Date.'
          });
        }
      }

      const driverSuspensionValid = driverSuspensionChangeSet.errors;
      const errors = driverSuspensionValid;

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.reset();
          }

        });
        return false;
      }

      return true;
    }),

    async onViolationChange(violation) {
      let path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'driverViolation';
      const driverSuspensionChangeSet = this.get('driverSuspensionChangeSet');
      driverSuspensionChangeSet.set(path, violation);
    },

    setMinDate(minDate) {
      const endDateInstance = this.get('endDateInstance');

      if (endDateInstance) {
        endDateInstance.set('minDate', minDate);
      }
    },

    actions: {
      onSaveRecord() {
        return this.onSaveDriverSuspensionRecord.perform();
      },

      onDatePickerReady(valuPath, instance) {
        this.set(valuPath, instance);
      },

      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onStartDateValueChange(record, valuePath, value) {
        if (value) {
          const isoDate = (0, _moment.default)(value).startOf('day').toISOString();
          record.set(valuePath, isoDate);
          this.setMinDate(isoDate);
        } else {
          record.set(valuePath, value);
        }
      },

      onDateValueChange(record, valuePath, value) {
        if (value) {
          const isoDate = (0, _moment.default)(value).startOf('day').toISOString();
          record.set(valuePath, isoDate);
        } else {
          record.set(valuePath, value);
        }
      },

      async openViolationModal() {
        const onVehicleChange = this.get('onViolationChange').bind(this);
        const selectedDriver = this.get('selectedDriver');
        const driverLicense = this.get('selectedDriver.license');
        const store = this.get('store');
        const queryParams = {
          filter: `eq(driverLicenseId,${driverLicense.get('id')})`
        };
        const driverViolations = await store.query('driver-violation', queryParams);
        const tooltip = this.get('tooltip');

        if (Ember.isPresent(driverViolations)) {
          const searchVehicleObj = Ember.Object.create({
            title: `Driver ${selectedDriver.get('driverId')} Violations`,
            queryParams,
            hasOverlay: true,
            config: _driverViolationSearchConfig.default,
            primaryActionText: 'Select',
            className: 'driver-violation-search',
            primaryAction: record => {
              onVehicleChange(record);
            }
          });
          this.set('modalAPI', searchVehicleObj);
        } else {
          tooltip.pushConfirmation({
            title: `Driver ${selectedDriver.get('driverId')} Violations`,
            tip: 'No violations currently exist for the driver.',
            primaryActionText: 'OK',

            primaryAction() {
              return tooltip.reset();
            }

          });
        }
      }

    }
  });

  _exports.default = _default;
});