define("adept-iq/pods/components/side-drawer/unperform-break-stop/locations/component", ["exports", "ember-concurrency", "adept-iq/utils/zone-validation"], function (_exports, _emberConcurrency, _zoneValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function cleanFieldString(text) {
    return typeof text === 'undefined' ? '' : text;
  }

  var _default = Ember.Component.extend({
    classNames: ['locations-input'],
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    notifications: Ember.inject.service(),
    map: Ember.inject.service(),
    value: null,
    addressOptions: null,
    notesReadOnly: false,
    addressType: null,
    address: null,
    addressLabel: Ember.computed.readOnly('field.label'),
    aliasPath: Ember.computed.readOnly('extra.aliasPath'),
    streetNumberPath: Ember.computed.readOnly('extra.streetNumberPath'),
    streetAddressPath: Ember.computed.readOnly('extra.streetAddressPath'),
    tomtomFormattedAddressPath: Ember.computed.readOnly('extra.tomtomFormattedAddressPath'),
    premisePath: Ember.computed.readOnly('extra.premisePath'),
    streetNamePath: Ember.computed.readOnly('extra.streetNamePath'),
    localityPath: Ember.computed.readOnly('extra.localityPath'),
    regionPath: Ember.computed.readOnly('extra.regionPath'),
    subRegionPath: Ember.computed.readOnly('extra.subRegionPath'),
    postalCodePath: Ember.computed.readOnly('extra.postalCodePath'),
    countryPath: Ember.computed.readOnly('extra.countryPath'),
    notesPath: Ember.computed.readOnly('extra.notesPath'),
    freeformAddressPath: Ember.computed.readOnly('extra.freeformAddressPath'),
    defaultOptionsPath: Ember.computed.readOnly('extra.defaultOptionsPath'),
    useOptionRecord: Ember.computed.readOnly('extra.useOptionRecord'),
    useRecordWithId: Ember.computed.readOnly('extra.useRecordWithId'),
    locationsPath: 'latlng',
    addressRecord: null,
    selected: null,

    geocodeAddressChange() {
      const record = this.get('addressRecord');
      const address = this.get('geocode.address');

      if (!Ember.isEmpty(address)) {
        this.convertTTAddressToModel(record, address).then(() => {
          this.disableAddressSearchOnMap(record, record.get('type'));
        });
      }
    },

    saveReverseGeocodeAddress() {
      this.geocodeAddressChange();
      this.removeObserver('geocode.address', this.saveReverseGeocodeAddress);
    },

    validateAddresses() {
      const record = this.get('address');
      this.set('editModal.errors', []);

      if (record.get('location.content.lat') === 0 || record.get('location.content.lng') === 0) {
        this.get('editModal.errors').pushObject('Address does not have lat/lng. Please select position on the map.');
      }
    },

    didInsertElement() {
      this._super(...arguments);
    },

    init() {
      this._super(...arguments);

      const place = this.get('value');
      this.set('address', place);
      this.set('addressOptions', []);
      this.get('addressOptions').push(place);
    },

    willDestroyElement() {
      this.get('geocode').deactivateGeocode();

      this._super(...arguments);
    },

    addressSearchResults(results) {
      this.set('addressOptions', results);
    },

    searchAddress: (0, _emberConcurrency.task)(function* (term) {
      const result = yield this.get('geocode').geoCodingApi(term);
      this.addressSearchResults(result);
    }),
    convertTTAddressToModel: function (record, ttAddress) {
      const useOptionRecord = this.get('useOptionRecord');
      const geocode = this.get('geocode') || [];

      if (Ember.isNone(ttAddress.id)) {
        const valuePath = this.get('streetNumberPath');
        const options = {
          record,
          valuePath,
          useOptionRecord
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(cleanFieldString(ttAddress.address.streetNumber), options);
        options.valuePath = this.get('premisePath');
        this.get('onChange')(cleanFieldString(ttAddress.premise), options);
        options.valuePath = this.get('streetNamePath');
        this.get('onChange')(cleanFieldString(ttAddress.address.streetName), options);
        options.valuePath = this.get('localityPath');
        this.get('onChange')(cleanFieldString(ttAddress.address.municipality), options);
        options.valuePath = this.get('regionPath');
        this.get('onChange')(cleanFieldString(ttAddress.address.countrySubdivisionName), options);
        options.valuePath = this.get('subRegionPath');
        this.get('onChange')(cleanFieldString(ttAddress.address.countrySecondarySubdivision), options);
        options.valuePath = this.get('postalCodePath');
        this.get('onChange')(cleanFieldString(ttAddress.address.postalCode), options);
        options.valuePath = this.get('countryPath');
        this.get('onChange')(cleanFieldString(ttAddress.address.countryCode), options);
        options.valuePath = this.get('streetAddressPath');
        this.get('onChange')(cleanFieldString(ttAddress.address.streetName), options);

        if (this.get('freeformAddressPath')) {
          options.valuePath = this.get('freeformAddressPath');
          this.get('onChange')(cleanFieldString(ttAddress.address.freeformAddress), options);
        }

        record.set('address.content.locations.firstObject.lat', ttAddress.position.lat);
        record.set('address.content.locations.firstObject.lng', ttAddress.position.lon);
        record.set('address.content.locations.firstObject.geoNode', ttAddress.position.geoNode);
        return geocode.calculateZone(ttAddress.position.lat, ttAddress.position.lon).then(result => {
          if (!Ember.isNone(result)) {
            record.set('address.content.locations.firstObject.geoNode', result.geonode);
          } else {
            this.get('notifications').warning(`CAN'T GET GEONODE FOR ${ttAddress.address.freeformAddress}`);
          }

          geocode.activateGeocode(false, record, 'pick');
          this.validateAddresses();
          this.set('addressOptions', ttAddress);
        });
      }

      const valuePath = this.get('streetNumberPath');
      const options = {
        record,
        valuePath,
        useOptionRecord
      };
      this.set('record.isForceDirty', true);
      this.get('onChange')(cleanFieldString(ttAddress.streetNumber), options);
      options.valuePath = this.get('premisePath');
      this.get('onChange')(cleanFieldString(ttAddress.premise), options);
      options.valuePath = this.get('streetNamePath');
      this.get('onChange')(cleanFieldString(ttAddress.streetAddress), options);
      options.valuePath = this.get('localityPath');
      this.get('onChange')(cleanFieldString(ttAddress.locality), options);
      options.valuePath = this.get('regionPath');
      this.get('onChange')(cleanFieldString(ttAddress.countrySubdivisionName), options);
      options.valuePath = this.get('subRegionPath');
      this.get('onChange')(cleanFieldString(ttAddress.countrySecondarySubdivision), options);
      options.valuePath = this.get('postalCodePath');
      this.get('onChange')(cleanFieldString(ttAddress.postalCode), options);
      options.valuePath = this.get('countryPath');
      this.get('onChange')(cleanFieldString(ttAddress.countryCode), options);

      if (this.get('freeformAddressPath')) {
        options.valuePath = this.get('freeformAddressPath');
        this.get('onChange')(cleanFieldString(ttAddress.address.freeformAddress), options);
      }

      record.set('address.content.locations.firstObject.lat', ttAddress.locations.firstObject.lat);
      record.set('address.content.locations.firstObject.lng', ttAddress.locations.firstObject.lng);
      record.set('address.content.locations.firstObject.geoNode', ttAddress.locations.firstObject.geoNode);
      return geocode.calculateZone(ttAddress.locations.firstObject.lat, ttAddress.locations.firstObject.lng).then(result => {
        if (!Ember.isNone(result)) {
          record.set('address.content.locations.firstObject.geoNode', result.geonode);
        } else {
          this.get('notifications').warning(`CAN'T GET GEONODE FOR ${ttAddress.address.freeformAddress}`);
        }

        geocode.activateGeocode(false, record, 'pick');
        this.validateAddresses();
        this.set('addressOptions', ttAddress);
      });
    },
    clearAddressModel: function (record) {
      const valuePath = this.get('streetNumberPath');
      const options = {
        record,
        valuePath
      };
      this.get('onChange')('', options);
      options.valuePath = this.get('streetNamePath');
      this.get('onChange')('', options);
      options.valuePath = this.get('localityPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('regionPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('subRegionPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('postalCodePath');
      this.get('onChange')('', options);
      options.valuePath = this.get('countryPath');
      this.get('onChange')('', options);
      record.set('lat', 0);
      record.set('lng', 0);
    },

    enableAddressSearchOnMap(record) {
      Ember.$('html,body,.tomtom-map').addClass('custom-cursor');
      this.get('geocode').activateGeocode(true, record, 'pick');
      this.set('addressRecord', record);
      this.addObserver('geocode.address', this.geocodeAddressChange);
    },

    disableAddressSearchOnMap(record) {
      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
      this.get('geocode').activateGeocode(false, record, 'pick');
      this.set('addressRecord', null);
      this.removeObserver('geocode.address', this.geocodeAddressChange);
    },

    actions: {
      onInput(record, valuePath, value) {
        const options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value, options);
      },

      onAddressSelected(record, valuePath, value) {
        const boundingBox = this.get('map.boundingBox');

        if (this.get('useRecordWithId') && !Ember.isNone(value.get('id'))) {
          this.set('record.place', value);
          this.set('addresses', [value]);
        } else {
          if (!(0, _zoneValidation.insideZone)([value.position.lat, value.position.lon], boundingBox)) {
            this.get('notifications').warning('Invalid Zone');
            return;
          }

          this.convertTTAddressToModel(record, value).then(() => {
            // reposition flag
            this.get('geocode').saveNewGeocode(record.get('address.position'), value);
          });
        }
      },

      onPickOnMap(record) {
        const activeGeocode = this.get('geocode.activeGeocode');

        if (activeGeocode) {
          this.disableAddressSearchOnMap(record, 'pick');
        } else {
          this.enableAddressSearchOnMap(record, 'pick');
        }
      },

      handleKeydown(record, valuePath, dropdown, e) {
        if (e.keyCode !== 13) {
          return;
        }

        const text = e.target.value;
        const options = {
          record,
          valuePath: 'streetAddress'
        }; // address has been forced without geocodes
        // this.set('record.isForceDirty', true);

        this.clearAddressModel(record); // this.set('record.isForceDirty', true);

        this.get('onChange')(text, options);
        this.set('addressRecord', record);
        this.validateAddresses();
      }

    }
  });

  _exports.default = _default;
});