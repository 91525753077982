define("adept-iq/pods/dashboard/modals/edit-form/resize-handle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HOsE7ZcK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"edit-modal-resize-handle\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/dashboard/modals/edit-form/resize-handle/template.hbs"
    }
  });

  _exports.default = _default;
});