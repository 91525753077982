define("adept-iq/pods/components/iq-widgets/vehicle-event-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/vehicle-event-form-widget/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        if (record.get('odometer').length <= 0) {
          this.get('editModal.errors').pushObject('Odometer cannot be empty');
          valid = false;
        }

        if (record.get('odometer').length > 0 && Ember.typeOf(parseInt(record.get('odometer'), 10)) !== 'number') {
          this.get('editModal.errors').pushObject('Odometer must be numeric');
          valid = false;
        }
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {},

      onApplyClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
        }
      }

    }
  });

  _exports.default = _default;
});