define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/filter-button", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/config", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/table-filter"], function (_exports, _react, _config, _panel, _tableFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const FilterButton = props => {
    const {
      table
    } = props;
    const {
      vehicleTableFilterEnabled,
      setVehicleTableFilterEnabled,
      eventTableFilterEnabled,
      setEventTableFilterEnabled
    } = (0, _panel.usePanel)();
    const filterEnabled = table === _config.default.tableTypes.events ? eventTableFilterEnabled : vehicleTableFilterEnabled;
    const setFilterEnabled = table === _config.default.tableTypes.events ? setEventTableFilterEnabled : setVehicleTableFilterEnabled;
    return _react.default.createElement("div", null, _react.default.createElement("button", {
      className: "playback-widget-button-new ",
      onClick: () => setFilterEnabled(true),
      "data-test-map-filter-button": true
    }, _react.default.createElement("i", {
      className: "fa fa-filter ",
      "aria-hidden": "true"
    })), filterEnabled && _react.default.createElement(_tableFilter.default, {
      table: table,
      setFilterEnabled: setFilterEnabled
    }));
  };

  var _default = FilterButton;
  _exports.default = _default;
});