define("adept-iq/pods/components/iq-widgets/subscription-form/subscription-form/component", ["exports", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/config/placeholders", "adept-iq/models/route-template"], function (_exports, _moment, _unwrapProxy, _placeholders, _routeTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['subscription-form'],
    statusOptions: null,
    suspensionTypes: null,
    activeFromMinDate: null,
    activeToMinDate: null,
    statusFlag: true,
    booking: Ember.inject.service(),
    subscriptionService: Ember.inject.service('subscription'),
    store: Ember.inject.service(),
    //placeholders
    TIME_PLACEHOLDER: _placeholders.TIME_PLACEHOLDER,

    setMaxDate() {
      const minDate = (0, _moment.default)(this.get('subscription.startDate')).startOf('day').toDate();
      const maxDate = (0, _moment.default)(this.get('subscription.endDate')).endOf('day').toDate();
      this.set('maxDate', maxDate);
      this.set('minDate', minDate);
      this.set('activeToMinDate', minDate);
    },

    suspendedDateVisibility: Ember.computed('subscription.exclusions.firstObject.suspend', function () {
      return !this.get('subscription.exclusions.firstObject.suspend');
    }),

    subscriptionInShiftBreak(pickTimeMoment, dropTimeMoment, shiftBreaks) {
      const workingDay = pickTimeMoment ? pickTimeMoment.format('YYYY-MM-DD') : null;
      let inShiftBreak = false;
      shiftBreaks.forEach(shiftBreak => {
        const breakStart = (0, _moment.default)(`${workingDay} ${shiftBreak.get('promisedStart')}`, 'YYYY-MM-DD HH:mm');
        const breakEnd = (0, _moment.default)(breakStart);
        breakEnd.add(shiftBreak.get('minutesPlannedDuration'), 'minutes');

        if (breakStart.isBetween(pickTimeMoment, dropTimeMoment, null, '[]') || breakEnd.isBetween(pickTimeMoment, dropTimeMoment, null, '[]') || pickTimeMoment.isBetween(breakStart, breakEnd, null, '[]')) {
          inShiftBreak = true;
        }
      });
      return inShiftBreak;
    },

    filteredRouteTemplates: Ember.computed('routeTemplates', 'notHavePickAndDropTime', 'subscription.latestRecurrence.{selectedDOWHolidays,subscriptionStartDate,subscriptionEndDate}', function () {
      const routeTemplates = this.get('routeTemplates');
      const notHavePickAndDropTime = this.get('notHavePickAndDropTime');
      const recurrencePattern = this.get('subscription.latestRecurrence');
      const selectedDOWHolidays = recurrencePattern.get('selectedDOWHolidays');
      const subscriptionStartDate = (0, _moment.default)(recurrencePattern.get('subscriptionStartDate'));
      const subscriptionEndDate = (0, _moment.default)(recurrencePattern.get('subscriptionEndDate'));
      const pickTimeMoment = this.get('subscriptionService.pickTimeMoment');
      const dropTimeMoment = this.get('subscriptionService.dropTimeMoment');
      const workingDay = pickTimeMoment ? pickTimeMoment.format('YYYY-MM-DD') : null;

      if (notHavePickAndDropTime) {
        return [];
      }

      const availableRouteTemplates = routeTemplates.filter(routeTemplate => {
        const active = routeTemplate.get('active');
        const availabilities = [];
        if (!active) return false;
        selectedDOWHolidays.forEach(day => {
          const availability = routeTemplate[`${day}Availability`];

          if (Ember.isPresent(availability)) {
            const shiftBreaks = availability.get('shiftBreaks');
            const startTime = availability.get('startTime');
            const endTime = availability.get('endTime');
            const shiftStartMoment = (0, _moment.default)(availability.get('shiftStart'));
            const routeLength = availability.get('routeLength');
            const startShift = (0, _moment.default)(`${workingDay} ${shiftStartMoment.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');
            const endShift = (0, _moment.default)(startShift);
            const duration = routeLength.split(':');
            const hours = duration[0];
            const minutes = duration.length > 1 ? duration[1] : 0;
            endShift.add(hours, 'hours');
            endShift.add(minutes, 'minutes');

            if (subscriptionStartDate.isSameOrAfter(startTime) && subscriptionEndDate.isSameOrBefore(endTime) && pickTimeMoment.isSameOrAfter(startShift) && dropTimeMoment.isSameOrBefore(endShift) && !this.subscriptionInShiftBreak(pickTimeMoment, dropTimeMoment, shiftBreaks)) {
              availabilities.push(day);
            }
          }
        });
        return availabilities.length === selectedDOWHolidays.length;
      });
      return availableRouteTemplates;
    }),
    isNotManual: Ember.computed('subscription.isManualSchedulingMode', function () {
      const isManualSchedulingMode = this.get('subscription.isManualSchedulingMode');
      return !isManualSchedulingMode;
    }),
    noActiveDates: Ember.computed('subscription.recurrencePatterns.@each.{selectedDOWHolidays,subscriptionStartDate,subscriptionEndDate}', function () {
      const recurrencePatterns = this.get('subscription.recurrencePatterns').toArray();
      let noActiveDates = true;
      recurrencePatterns.forEach(pattern => {
        const selectedDOWHolidays = pattern.get('selectedDOWHolidays');
        const subscriptionStartDate = pattern.get('subscriptionStartDate');
        const subscriptionEndDate = pattern.get('subscriptionEndDate');

        if (Ember.isPresent(subscriptionStartDate) && Ember.isPresent(subscriptionEndDate) && selectedDOWHolidays.length > 0) {
          noActiveDates = false;
        }
      });
      return noActiveDates;
    }),
    notHavePickAndDropTime: Ember.computed('isNotManual', 'subscriptionService.{pickTimeMoment,dropTimeMoment}', function () {
      const isNotManual = this.get('isNotManual');
      const pickTimeMoment = this.get('subscriptionService.pickTimeMoment');
      const dropTimeMoment = this.get('subscriptionService.dropTimeMoment');
      return isNotManual || !pickTimeMoment || !dropTimeMoment;
    }),

    init() {
      this._super(...arguments);

      this.setMaxDate();
      this.setProperties({
        statusOptions: [{
          id: 'active',
          name: 'Active'
        }, {
          id: 'inactive',
          name: 'Inactive'
        }, {
          id: 'denied',
          name: 'Denied'
        }],
        suspensionTypes: [{
          id: 'suspension',
          name: 'Suspension'
        }, {
          id: 'clientRequested',
          name: 'Client Requested'
        }]
      });
      const subscriptionStatus = this.get('subscription.status') ? this.get('subscription.status') : 'active';
      const supensionType = this.get('subscription.exclusions.firstObject.type') ? this.get('subscription.exclusions.firstObject.type') : 'suspension';
      const routeTemplates = this.get('store').peekAll('route-template');
      this.set('routeTemplates', routeTemplates);
      this.set('subscription.status', subscriptionStatus);
      this.set('subscription.exclusions.firstObject.type', supensionType);
      this.set('activeFromMinDate', this.getActiveSuspendMinDate('startDate'));
      this.set('activeToMinDate', this.getActiveSuspendMinDate('endDate'));
      this.get('setupManualSubscription')();
      this.suspendCheck();
    },

    // NYAAR-13554 Subscription - From date is not displayed upon editing the subscription
    getActiveSuspendMinDate(dateType) {
      const minDate = this.get('isEditMode') ? this.getMinDate(this.get(`subscription.${dateType}`)) : 'today';
      return minDate;
    },

    suspendCheck() {
      const exclusion = this.get('subscription.exclusions.firstObject');
      const suspensionChecked = exclusion.get('suspend');

      if (!suspensionChecked) {
        exclusion.set('startDate', null);
        exclusion.set('endDate', null);
      }
    },

    getMinDate(date) {
      const currentDate = (0, _moment.default)().startOf('day');
      return (0, _moment.default)(date).diff(currentDate) > 0 ? 'today' : date;
    },

    convertToRegularDateTime(value, originalDateTime) {
      // keep the original date for comparison. We only care about the time
      const dateTimeMoment = originalDateTime ? (0, _moment.default)(originalDateTime).startOf('day') : (0, _moment.default)().startOf('day');
      const timeArray = value.masked.split(':');
      dateTimeMoment.add(timeArray[0], 'hours');
      dateTimeMoment.add(timeArray[1], 'minutes');
      return dateTimeMoment;
    },

    checkValidateOrder(type) {
      const pickTimeMoment = this.get('subscriptionService.pickTimeMoment');
      const dropTimeMoment = this.get('subscriptionService.dropTimeMoment');
      this.set('overrideErrorText', '');
      let isValid = true;

      if (pickTimeMoment.isSameOrAfter(dropTimeMoment)) {
        this.get('booking').showErrorMessage('Dropoff Time must be after Pickup Time.');
        isValid = false;
      }

      if (!isValid) {
        if (type === 'pick') {
          Ember.run.schedule('afterRender', () => {
            this.set('subscriptionService.pickTime');
            this.set('subscriptionService.pickTimeMoment');
            this.set('subscription.originPlannedEta');
          });
        } else {
          Ember.run.schedule('afterRender', () => {
            this.set('subscriptionService.dropTime');
            this.set('subscriptionService.dropTimeMoment');
            this.set('subscription.destinationPlannedEta');
          });
        }
      }

      return isValid;
    },

    validateManualRoute() {
      const routeTemplate = (0, _unwrapProxy.unwrapProxy)(this.get('subscription.routeTemplate'));
      const filteredRouteTemplates = this.get('filteredRouteTemplates');
      if (this.get('isNotManual') || Ember.isEmpty(routeTemplate)) return;
      const templateFound = filteredRouteTemplates.find(template => {
        return template.id === routeTemplate.id;
      });

      if (Ember.isEmpty(templateFound)) {
        this.set('subscription.routeTemplate');
      }
    },

    actions: {
      toggleCalendar(flatrRef) {
        flatrRef.toggle();
      },

      onSuspendCheckBoxClick(path, event) {
        this.set(path, event.target.checked);
        this.suspendCheck();
        const isSuspend = this.get(path);

        if (!isSuspend) {
          const exclusion = this.get('subscription.exclusions.firstObject');
          exclusion.set('startDate', null);
          exclusion.set('endDate', null);
        }
      },

      onManualCheckBoxClick(path, event) {
        if (event.target.checked) {
          this.set(path, _routeTemplate.MANUAL_SCHEDULING_MODE);
        } else {
          this.set(path, '');
          this.set('subscriptionService.pickTime');
          this.set('subscriptionService.pickTimeMoment');
          this.set('subscriptionService.dropTime');
          this.set('subscriptionService.dropTimeMoment');
          this.set('subscription.schedulingMode', _routeTemplate.AUTOMATIC_SCHEDULING_MODE);
          this.set('subscription.originPlannedEta');
          this.set('subscription.destinationPlannedEta');
          this.set('subscription.routeTemplate');
        }
      },

      onCheckBoxClick(path, event) {
        this.set(path, event.target.checked);
        this.suspendCheck();
        this.validateManualRoute();
      },

      onDateChange(path, type, value) {
        const selectedDate = (0, _moment.default)(value[0]);
        const rTime = this.get('leg.requestTime');
        const currentDateAndTime = (0, _moment.default)(rTime);
        selectedDate.hour(currentDateAndTime.hour());
        selectedDate.minutes(currentDateAndTime.minutes());
        selectedDate.seconds(currentDateAndTime.seconds());
        const selectedDateObjectWithTime = selectedDate.toDate();
        const currentDate = (0, _moment.default)().startOf('day');

        if (path.indexOf('startDate') > -1) {
          const prevStartDate = type === 'Subscription' ? this.get('booking.previousSubscriptionStartDate') : this.get('booking.previousSubscriptionSuspendStartDate');
          this.get('booking').validateDateNotToBePastDate(selectedDateObjectWithTime, currentDate, prevStartDate, type, 'From');
        } else {
          const prevEndDate = type === 'Subscription' ? this.get('booking.previousSubscriptionEndDate') : this.get('booking.previousSubscriptionSuspendEndDate');
          this.get('booking').validateDateNotToBePastDate(selectedDateObjectWithTime, currentDate, prevEndDate, type, 'To');
        } // suspend from date should be less than or equal to suspend to date


        const exclusion = this.get('subscription.exclusions.firstObject');

        if (path.indexOf('startDate') > -1) {
          const exclusionStartDate = selectedDate.startOf('day');
          const exclusionEndDate = (0, _moment.default)(exclusion.get('endDate')).startOf('day');

          if (exclusionStartDate.isAfter(exclusionEndDate)) {
            this.get('booking').showErrorMessage(`Please select valid ${type} From date.`);
          } // NYAAR-18080 Suspend date calendar should show the month according to the active date and not blank


          if (exclusion.get('startDate') && selectedDate.isAfter(exclusion.get('startDate'))) {
            exclusion.set('startDate', null);
          }

          if (exclusion.get('endDate') && selectedDate.isAfter(exclusion.get('endDate'))) {
            exclusion.set('endDate', null);
          }
        } else {
          const exclusionStartDate = (0, _moment.default)(exclusion.get('startDate')).startOf('day');
          const exclusionEndDate = selectedDate.startOf('day');

          if (exclusionStartDate.isAfter(exclusionEndDate)) {
            this.get('booking').showErrorMessage(`Please select valid ${type} To date.`);
          } // NYAAR-18080 Suspend date calendar should show the month according to the active date and not blank


          if (exclusion.get('startDate') && selectedDate.isBefore(exclusion.get('startDate'))) {
            exclusion.set('startDate', null);
          }

          if (exclusion.get('endDate') && selectedDate.isBefore(exclusion.get('endDate'))) {
            exclusion.set('endDate', null);
          }
        }

        this.set(path, selectedDateObjectWithTime);
        this.setMaxDate();
        this.validateManualRoute();
      },

      onChangePickTime(value) {
        this.set('subscriptionService.pickTime', value.unmasked);

        if (value.unmasked.length < 4) {
          this.set('subscriptionService.pickTimeMoment');
          this.set('subscription.originPlannedEta');
          return;
        }

        const pickTimeMoment = this.convertToRegularDateTime(value, this.get('subscriptionService.previousOriginPlannedEta'));
        const dropTimeMoment = this.get('subscriptionService.dropTimeMoment');
        this.set('subscriptionService.pickTimeMoment', pickTimeMoment);
        this.set('subscription.originPlannedEta', pickTimeMoment.toDate());

        if (dropTimeMoment) {
          this.checkValidateOrder('pick');
        }

        this.validateManualRoute();
      },

      onChangeDropTime(value) {
        this.set('subscriptionService.dropTime', value.unmasked);

        if (value.unmasked.length < 4) {
          this.set('subscriptionService.dropTimeMoment');
          this.set('subscription.destinationPlannedEta');
          return;
        }

        const dropTimeMoment = this.convertToRegularDateTime(value, this.get('subscriptionService.previousDestinationPlannedEta'));
        const pickTimeMoment = this.get('subscriptionService.pickTimeMoment');
        this.set('subscriptionService.dropTimeMoment', dropTimeMoment);
        this.set('subscription.destinationPlannedEta', dropTimeMoment.toDate());

        if (pickTimeMoment) {
          this.checkValidateOrder('drop');
        }

        this.validateManualRoute();
      },

      onRouteTemplateChange(subscription, routeTemplate) {
        subscription.set('routeTemplate', routeTemplate);
      }

    }
  });

  _exports.default = _default;
});