define("adept-iq/pods/components/rider-management-widget/rider-contact-information/rider-phone-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/rider-management-widget/rider-contact-information/rider-phone-table/config", "adept-iq/utils/sorts", "adept-iq/utils/filters"], function (_exports, _component, _config, _sorts, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import serviceWindow from '../../../../adapters/service-window';
  var _default = _component.default.extend({
    config: _config.default,
    isNewOrEditing: false,
    recordSetter: Ember.observer('riderPhoneNumbers', function () {
      Ember.run.debounce(this, 'refreshTable', 500);
    }),

    refreshTable() {
      this.clearData();
      this.refreshData();
    },

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    async query(modelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const store = this.get('store');
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const activeData = store.peekAll(modelName) || [];
      const riderId = this.get('riderChangeSet.id');
      let filteredData = activeData.filter(filterFn).sort(compareFn);
      filteredData = filteredData.filter(data => {
        return data.get('rider.id') === riderId && !data.get('isSoftDeleted');
      });
      return filteredData;
    },

    actions: {
      onPlusIconClick() {
        this.get('createNewPhoneNumberRecord')();
      },

      onDeleteIconClick() {
        const phoneRecord = this.get('checkedRows.firstObject');
        this.get('deletePhoneRecord')(phoneRecord);
      },

      onEditIconClick() {
        const phoneRecord = this.get('checkedRows.firstObject');
        this.get('editPhoneRecord')(phoneRecord);
      }

    }
  });

  _exports.default = _default;
});