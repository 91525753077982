define("adept-iq/pods/components/side-drawer/system-config/contract-management/table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/side-drawer/system-config/contract-management/table/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    classNames: ['contract-management-table'],
    config: _config.default,
    paginationEnabled: true,
    enableWarningRowChangeAlert: true,

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    hasPendingChanges: Ember.computed('contractFormChanges', function () {
      const contractFormChanges = this.get('contractFormChanges') || false;
      return contractFormChanges;
    }),

    /**
     * this method override the save record method in generic table component
     * @param record
     * @returns {*}
     */
    saveRecord(record) {
      const saveRecordTask = this.get('saveRecordTask');
      if (saveRecordTask) return saveRecordTask.perform();
      return record.save();
    },

    fetchDataQuery() {
      return this._super(...arguments).then(records => {
        records.forEach(rec => {
          const provider = this.store.peekRecord('provider', rec.providerName);
          const providerName = provider ? provider.get('name') : rec.providerName;
          rec.set('providerName', providerName);
        });
        return records;
      });
    }

  });

  _exports.default = _default;
});