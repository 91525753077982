define("adept-iq/models/vehicle-event", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    trackingId: attr('string'),
    vehicleId: attr('string'),
    type: attr('string'),
    lat: attr('number'),
    lng: attr('number'),
    odo: attr('number'),
    note: attr('string'),
    preCheckedId: attr('number'),
    postCheckedId: attr('number'),
    content: attr('object'),
    vehicle: attr('object'),
    message: attr('string'),
    // TODO: map these to `avlm-driver` associations
    driver: attr('object'),
    timestamp: attr('date'),
    receivedAt: attr('date'),
    heading: attr('number'),
    speed: attr('number'),
    time: Ember.computed('timestamp', function () {
      return (0, _moment.default)(this.get('timestamp')).format('HH:mm A');
    }),
    latlng: Ember.computed('lat', 'lng', function () {
      if (!Ember.isNone(this.get('lat')) && !Ember.isNone(this.get('lng'))) {
        return [`${this.get('lat')}`, `${this.get('lng')}`];
      }

      return null;
    }),
    eventType: attr('string', {
      defaultValue: 'GPS Ping'
    }),
    // Below are the attribute added under story NYAAR-8097 and Issue-NYAAR-9671
    // TODO: update the model according to api
    routeId: attr('number'),
    //belongsTo('avlm-route'),
    stopId: attr('string'),
    stopType: attr('string', {
      defaultValue: 'N/A'
    }),
    stopAddress: attr('string', {
      defaultValue: 'N/A'
    }),
    passengerLName: attr('string'),
    passengerFName: attr('string'),
    passengerId: attr('number'),
    isReconciled: attr('boolean', {
      defaultValue: false
    }),
    tripId: attr('number'),
    eventId: Ember.computed('eventId', function () {
      return (0, _moment.default)(this.get('timestamp')).format() + this.get('vehicleId');
    })
  });

  _exports.default = _default;
});