define("adept-iq/utils/find-row-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findRowElement = findRowElement;
  _exports.findRowRecordByElement = findRowRecordByElement;

  function findRowElement(targetElement) {
    while (targetElement.tagName.toLowerCase() !== 'tr' && targetElement.tagName.toLowerCase() !== 'body') {
      /*eslint-disable */
      targetElement = targetElement.parentElement;
      /*eslint-enable */
    }

    return targetElement.tagName.toLowerCase() === 'tr' ? targetElement : null;
  }

  function findRowRecordByElement(targetElement, tableRows) {
    const rowElement = findRowElement(targetElement);

    if (Ember.isBlank(rowElement)) {
      return;
    }

    const node = rowElement.attributes.getNamedItem('data-row-id');

    if (Ember.isBlank(node)) {
      return;
    }

    return tableRows.findBy('rowId', node.value);
  }
});