define("adept-iq/config/mapped-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertSubscriptionStatus = _exports.convertBookingStatus = void 0;
  const convertBookingStatus = {
    scheduled: 'Scheduled',
    requested: 'Requested',
    promised: 'Promised',
    dispatched: 'Dispatched',
    active: 'Active',
    completed: 'Completed',
    callback: 'Callback',
    willCall: 'Will call',
    failed: 'Failed',
    refused: 'Refused',
    canceled: 'Canceled',
    cancelled: 'Canceled',
    lateCancelled: 'Late Canceled',
    archived: 'Archived',
    unscheduled: 'Unscheduled',
    waitlisted: 'Waitlisted',
    illegal: 'Illegal',
    noShowRequest: 'No Show Request',
    noShow: 'No Show'
  };
  _exports.convertBookingStatus = convertBookingStatus;
  const convertSubscriptionStatus = {
    scheduled: 'Scheduled',
    requested: 'Requested',
    promised: 'Promised',
    dispatched: 'Dispatched',
    active: 'Active',
    inactive: 'Inactive',
    denied: 'Denied',
    completed: 'Completed',
    callback: 'Callback',
    willCall: 'Will call',
    failed: 'Failed',
    canceled: 'Canceled',
    cancelled: 'Canceled',
    lateCancelled: 'Late Cancelled',
    archived: 'Archived',
    unscheduled: 'Unscheduled',
    waitlisted: 'Waitlisted',
    illegal: 'Illegal',
    noShowRequest: 'No Show Request',
    noShow: 'No Show'
  };
  _exports.convertSubscriptionStatus = convertSubscriptionStatus;
});