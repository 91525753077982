define("adept-iq/classes/rules/eligibility-rules/assessment-trip-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type"], function (_exports, _baseRule, _ruleResultType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AssessmentTripRule = void 0;

  class AssessmentTripRule extends _baseRule.default {
    async doEvaluate() {
      return _ruleResultType.RESULT_TYPE_NO;
    }

  }

  _exports.AssessmentTripRule = AssessmentTripRule;
  AssessmentTripRule.RULE_NAME = 'AssessmentTripRule';
});