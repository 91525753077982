define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/playback-table", ["exports", "react", "react-virtualized", "react-draggable", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/session", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-tables/config", "moment", "adept-iq/config/environment"], function (_exports, _react, _reactVirtualized, _reactDraggable, _api, _panel, _session, _config, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MIN_COLUMN_WIDTH = 50;
  const Table = _reactVirtualized.default.Table;
  const Column = _reactVirtualized.default.Column;
  const defaultTableRowRenderer = _reactVirtualized.default.defaultTableRowRenderer;

  const PlaybackTable = props => {
    const tableRef = _react.default.useRef();

    const {
      token,
      providers,
      notifications
    } = _react.default.useContext(_session.SessionContext);

    const {
      table,
      setDisplayCount,
      width,
      height
    } = props;
    const {
      eventData,
      vehicleData,
      doSearch,
      setEventData
    } = (0, _api.useAPI)();
    const {
      sliderIndex,
      setToPlay,
      isLoadingEvent,
      isLoadingVehicle,
      isRunning,
      vehicleTableColumns,
      eventTableColumns,
      setSelectedRvd,
      selectedRvd,
      startTime,
      endTime,
      setIsLoadingEvent,
      eventSearchText,
      vehicleSearchText,
      setEventTableData,
      setVehicleTableData,
      highlightedRowIndex,
      setHighlightedRowIndex
    } = (0, _panel.usePanel)();

    const [displayData, setDisplayData] = _react.default.useState(table === _config.default.tableTypes.events ? eventData : vehicleData);

    const configColumns = table === _config.default.tableTypes.events ? eventTableColumns.filter(column => column.isChecked) : vehicleTableColumns.filter(column => column.isChecked);
    const defaultWidths = configColumns.reduce((w, column) => {
      const columnLength = configColumns.length > 0 ? configColumns.length : 1;
      w[column.valuePath] = Math.max(width / columnLength, MIN_COLUMN_WIDTH);
      return w;
    }, {}); // Minus 40 for radio button

    const defaultTotalWidth = Object.values(defaultWidths).reduce((sum, w) => sum + w, 0);

    const [widths, setWidths] = _react.default.useState(defaultWidths);

    const [totalWidth, setTotalWidth] = _react.default.useState(defaultTotalWidth);

    const timezone = _moment.default.tz.guess();

    const matchColumnFilter = (rowData, column) => {
      const {
        filterOp,
        filterValue
      } = column;
      if (filterOp !== _panel.filterOperations.empty && filterValue === '' || filterOp === '') return true;

      switch (filterOp) {
        case _panel.filterOperations.eq:
          return rowData[column.valuePath].toString().toLowerCase() === filterValue.toLowerCase();

        case _panel.filterOperations.neq:
          return rowData[column.valuePath].toString().toLowerCase() !== filterValue.toLowerCase();

        case _panel.filterOperations.contains:
          return rowData[column.valuePath].toString().toLowerCase().includes(filterValue.toLowerCase());

        case _panel.filterOperations.empty:
          return rowData[column.valuePath] === 'N/A';

        default:
          return true;
      }
    };

    const onRowClick = _ref => {
      let {
        index
      } = _ref;

      if (table === _config.default.tableTypes.events) {
        const _index = displayData[index]?._index;

        if (_index >= 0) {
          setToPlay(_index);
        }
      }
    };

    const noRowsRenderer = () => {
      const eventMessage = 'No events found';
      const vehicleMessage = 'No vehicles found';

      const loadingIcon = _react.default.createElement("i", {
        className: "fa fa-circle-o-notch fa-spin fa-lg fa-fw"
      });

      return _react.default.createElement(_react.default.Fragment, null, table === _config.default.tableTypes.events && _react.default.createElement("div", {
        className: "loadingMessage"
      }, isLoadingEvent ? loadingIcon : eventMessage), table === _config.default.tableTypes.vehicles && _react.default.createElement("div", {
        className: "loadingMessage"
      }, isLoadingVehicle ? loadingIcon : vehicleMessage));
    };

    const resizeRow = _ref2 => {
      let {
        key,
        deltaX
      } = _ref2;
      const prevWidths = widths;
      const curWidths = { ...prevWidths,
        [key]: Math.max(prevWidths[key] + deltaX, MIN_COLUMN_WIDTH)
      };
      setWidths(curWidths);
      setTotalWidth(Object.values(curWidths).reduce((sum, w) => sum + w, 0));
    };

    _react.default.useEffect(() => {
      if (table === _config.default.tableTypes.events && tableRef.current) {
        let previousItem = null;
        let nextItem = null;
        let currItem = null;
        const sliderIndexNo = Number(sliderIndex);
        displayData.map((d, i) => {
          if (d._index < sliderIndexNo) {
            previousItem = i;
          }

          if (d._index === sliderIndexNo) {
            currItem = i;
          }

          if (nextItem === null && d._index > sliderIndexNo) {
            nextItem = i;
          }
        });

        if (currItem === null) {
          currItem = previousItem !== null ? previousItem : nextItem;
        }

        if (currItem !== null && currItem >= 0) {
          setHighlightedRowIndex(currItem);
          tableRef.current.scrollToRow(currItem);
          tableRef.current.forceUpdate();
        }
      }
    }, [sliderIndex]);

    _react.default.useEffect(() => {
      if (table === _config.default.tableTypes.events && eventSearchText !== '') {
        const seachFields = eventTableColumns.map(column => column.valuePath);
        const searchText = eventSearchText.toLowerCase();

        const _displayData = eventData.filter(event => {
          // Check filter first
          for (let i = 0; i < eventTableColumns.length; i++) {
            if (eventTableColumns[i].isChecked && !matchColumnFilter(event, eventTableColumns[i])) return false;
          }

          for (let i = 0; i < seachFields.length; i++) {
            const context = event[seachFields[i]] ? event[seachFields[i]].toString().toLowerCase() : '';
            if (context.includes(searchText)) return true;
          }

          return false;
        });

        setDisplayData(_displayData);
      } else if (table === _config.default.tableTypes.vehicles && vehicleSearchText !== '') {
        const seachFields = vehicleTableColumns.map(column => column.valuePath);
        const searchText = vehicleSearchText.toLowerCase();

        const _displayData = vehicleData.filter(vehicle => {
          // Check filter first
          for (let i = 0; i < vehicleTableColumns.length; i++) {
            if (vehicleTableColumns[i].isChecked && !matchColumnFilter(vehicle, vehicleTableColumns[i])) return false;
          }

          for (let i = 0; i < seachFields.length; i++) {
            const context = vehicle[seachFields[i]] ? vehicle[seachFields[i]].toString().toLowerCase() : '';
            if (context.includes(searchText)) return true;
          }

          return false;
        });

        setDisplayData(_displayData);
      } else {
        const _data = table === _config.default.tableTypes.events ? eventData : vehicleData;

        const columns = table === _config.default.tableTypes.events ? eventTableColumns : vehicleTableColumns;

        const _displayData = _data.filter(d => {
          d.localTimeDisplay = (0, _moment.default)(d.converteddate).format(_environment.default.dateTimeFormat.timeMoment);

          for (let i = 0; i < columns.length; i++) {
            if (columns[i].isChecked && !matchColumnFilter(d, columns[i])) return false;
          }

          return true;
        });

        setDisplayData(_displayData);
      }
    }, [eventSearchText, vehicleSearchText, eventData, vehicleData, vehicleTableColumns, eventTableColumns]);

    _react.default.useEffect(() => {
      setDisplayCount(displayData.length);

      if (table === _config.default.tableTypes.events) {
        setEventTableData(displayData);
      } else {
        setVehicleTableData(displayData);
      }
    }, [displayData]);

    _react.default.useEffect(() => {
      setWidths(defaultWidths);
      setTotalWidth(defaultTotalWidth);
    }, [width]);

    const headerRenderer = _ref3 => {
      let {
        dataKey,
        label
      } = _ref3;
      return _react.default.createElement(_react.default.Fragment, {
        key: dataKey
      }, _react.default.createElement("div", {
        className: "ReactVirtualized__Table__headerTruncatedText"
      }, _react.default.createElement("span", null, label)), _react.default.createElement(_reactDraggable.default, {
        axis: "x",
        defaultClassName: "DragHandle",
        defaultClassNameDragging: "DragHandleActive",
        onDrag: (event, _ref4) => {
          let {
            deltaX
          } = _ref4;
          return resizeRow({
            key: dataKey,
            deltaX
          });
        },
        position: {
          x: 0
        },
        zIndex: 999
      }, _react.default.createElement("span", {
        className: "PlaybackDragHandleIcon"
      })));
    };

    const rowRenderer = rowProps => {
      const {
        index,
        style
      } = rowProps;

      if (table === _config.default.tableTypes.events) {
        const _index = displayData[index]._index;

        if (Number(_index) === Number(sliderIndex) || highlightedRowIndex === index) {
          style.backgroundColor = '#95d095';
          style.color = '#fff';
        }
      }

      return _react.default.cloneElement(defaultTableRowRenderer(rowProps));
    };

    const searchEvents = rowData => {
      const {
        vehicleId,
        vehicleName,
        driverId,
        badgenumber,
        routeId,
        routeName,
        provider
      } = rowData;
      const _selectedRvd = {
        vehicleId,
        vehicleName,
        driverId,
        badgenumber,
        routeId,
        routeName,
        provider
      }; // Avoid double clicking

      if (isLoadingEvent || isRunning) return; // If events is loaded and use click on same vehicle then remove the data.

      if (selectedRvd.vehicleId === _selectedRvd.vehicleId) {
        setSelectedRvd({});
        setEventData([]);
        return;
      } // Clear Events First


      setEventData([]);
      setSelectedRvd(_selectedRvd);
      setIsLoadingEvent(true);

      const callback = () => {
        setIsLoadingEvent(false);
      };

      doSearch(startTime, endTime, timezone, token, providers, notifications, _panel.searchModes.byLocationVehicle, _selectedRvd, null, null, null, callback);
    };

    return _react.default.createElement(Table, {
      ref: tableRef,
      width: totalWidth,
      height: height,
      headerHeight: 40,
      rowHeight: 30,
      rowCount: displayData.length,
      rowGetter: _ref5 => {
        let {
          index
        } = _ref5;
        return displayData[index];
      },
      onRowClick: onRowClick,
      rowRenderer: rowRenderer,
      noRowsRenderer: noRowsRenderer
    }, table === _config.default.tableTypes.vehicles && _react.default.createElement(Column, {
      width: 40,
      dataKey: "radio",
      label: "",
      cellRenderer: _ref6 => {
        let {
          rowData
        } = _ref6;
        return _react.default.createElement("input", {
          checked: selectedRvd && selectedRvd.vehicleId === rowData.vehicleId,
          onChange: () => {// Do nothing in onChange because we need uncheck radio button.
          },
          onClick: () => searchEvents(rowData),
          type: "radio"
        });
      }
    }), configColumns.map(column => _react.default.createElement(Column, {
      headerRenderer: headerRenderer,
      key: column.valuePath,
      dataKey: column.valuePath,
      label: column.label,
      width: widths[column.valuePath],
      style: {
        border: '0px'
      }
    })));
  };

  var _default = PlaybackTable;
  _exports.default = _default;
});