define("adept-iq/classes/rules/itinerary-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NO_SOLUTION = _exports.FIXED_ROUTE = _exports.FEEDER = void 0;
  const NO_SOLUTION = 0;
  _exports.NO_SOLUTION = NO_SOLUTION;
  const FIXED_ROUTE = 1;
  _exports.FIXED_ROUTE = FIXED_ROUTE;
  const FEEDER = 2;
  _exports.FEEDER = FEEDER;
});