define("adept-iq/pods/components/side-drawer/add-widget/widget-button-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cBuZe+tp",
    "block": "{\"symbols\":[\"widgetType\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"add-widget-section-header \",[27,\"if\",[[27,\"not\",[[23,[\"isOpen\"]]],null],\"is-closed\"],null]]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onHeaderClick\"],null]],[9],[0,\"\\n  \"],[7,\"h4\"],[11,\"class\",\"add-widget-section-title\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-sort-down\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n    \"],[1,[23,[\"widgetSection\",\"title\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"add-widget-section-body\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"widgetSection\",\"widgetTypes\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"add-widget-button\"],[9],[0,\"\\n        \"],[7,\"div\"],[12,\"class\",[28,[\"widget-type-\",[27,\"lowercase\",[[22,1,[\"name\"]]],null],\" widget-icon\"]]],[9],[0,\"\\n          \"],[7,\"img\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"p\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"onWidgetButtonClick\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/side-drawer/add-widget/widget-button-section/template.hbs"
    }
  });

  _exports.default = _default;
});