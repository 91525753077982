define("adept-iq/mixins/password-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    password: Ember.computed.readOnly('userDetails.password'),
    requireValidation: Ember.computed.gte('password.length', 1),
    passwordUnchanged: Ember.computed.not('requireValidation'),
    containsUppercase: Ember.computed('password', function () {
      const pass = this.get('password');
      if (!pass) return false;
      return pass.match(/[A-Z]/);
    }),
    containsLowercase: Ember.computed('password', function () {
      const pass = this.get('password');
      if (!pass) return false;
      return pass.match(/[a-z]/);
    }),
    containsNumber: Ember.computed('password', function () {
      const pass = this.get('password');
      if (!pass) return false;
      return pass.match(/[0-9]/);
    }),
    containsMoreThanEight: Ember.computed.gte('password.length', 6),
    containsSymbol: Ember.computed('password', function () {
      const pass = this.get('password');
      if (!pass) return false;
      return pass.match(/[~!@#$%^&*()_\-+={};:'"|\\<>,.?/]/);
    }),
    isPasswordValid: Ember.computed.and('containsUppercase', 'containsLowercase', 'containsNumber', 'containsMoreThanEight', 'containsSymbol'),
    isPasswordValidOrUnchanged: Ember.computed.or('passwordUnchanged', 'isPasswordValid')
  });

  _exports.default = _default;
});