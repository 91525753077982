define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/extra-spacer-cell-item", ["exports", "lodash", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-item"], function (_exports, _lodash, _cellItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExtraSpacerCellItem extends _cellItem.default {
    constructor(props) {
      super(props);
    }

    render() {
      const {
        data,
        style,
        columnIndex,
        rowIndex,
        columnInfo,
        selectedRowIndex,
        selectedColumnIndex
      } = this.props;
      let classNames = this.getStylesClasses(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex);
      const rowSelClasses = this.getRowStyleClasses(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, '');
      classNames = rowSelClasses + '  ' + classNames;

      const cellStyle = _lodash.default.merge({}, style);

      cellStyle.left = cellStyle.left + cellStyle.width;
      cellStyle.width = '100%';
      return React.createElement("span", {
        role: "none",
        className: classNames,
        style: cellStyle
      });
    }

    getStylesClasses(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex) {
      let cellColorCSSClass = (rowIndex - 1) % 2 ? 'even-cell' : 'odd-cell';
      cellColorCSSClass = this.isRowSelected(rowIndex, selectedRowIndex) ? 'selected-row' : cellColorCSSClass;
      cellColorCSSClass = this.isColumnSelected(rowIndex, columnIndex, selectedRowIndex, selectedColumnIndex) ? 'selected-column' : cellColorCSSClass;
      const classNames = `Cell ${cellColorCSSClass}`;
      return classNames;
    }

  }

  _exports.default = ExtraSpacerCellItem;
});