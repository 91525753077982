define("adept-iq/pods/components/iq-widgets/history-routes-widget/stubs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    historyRouteMockData: [{
      tripId: '15001510',
      groupId: '5',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001511',
      groupId: '6',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001512',
      groupId: '7',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001513',
      groupId: '8',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001514',
      groupId: '9',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001515',
      groupId: '10',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001516',
      groupId: '11',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001517',
      groupId: '12',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001518',
      groupId: '13',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001519',
      groupId: '14',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001520',
      groupId: '15',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001521',
      groupId: '16',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001522',
      groupId: '17',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001510',
      groupId: '5',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001511',
      groupId: '6',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001512',
      groupId: '7',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001513',
      groupId: '8',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001514',
      groupId: '9',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001515',
      groupId: '10',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001516',
      groupId: '11',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001517',
      groupId: '12',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001518',
      groupId: '13',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001519',
      groupId: '14',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001520',
      groupId: '15',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001521',
      groupId: '16',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }, {
      tripId: '15001522',
      groupId: '17',
      clientName: 'David',
      status: 'scheduled',
      type: 'pickup',
      eta: '8:20',
      target: 'achievable',
      number: '123',
      address: 'NYCT',
      city: 'NYCT',
      arriveTime: '10:40',
      arriveType: 'pickup',
      performed: 'yes',
      actualTime: '10:00',
      stopTime: '8:00',
      mileage: '25',
      ambSeats: '1',
      wcSeats: '2',
      clients: '2',
      pca: '5',
      lateStatus: 'L'
    }]
  };
  _exports.default = _default;
});