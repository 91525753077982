define("adept-iq/adapters/lock", ["exports", "adept-iq/adapters/-dispatch", "adept-iq/config/api-urls", "ember-ajax/errors", "adept-iq/config/mapped-permIds"], function (_exports, _dispatch, _apiUrls, _errors, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    // not using "ajax" so there won't be conflict with the base's ajax calls
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),

    async doLockRoute(dispatchRoute) {
      let persist = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      let forwardError = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let logging = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      let thresholdTTL = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 45;
      let ttl = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
      const ajax = this.get('ajaxService');
      const session = this.get('session');
      const notifications = this.get('notifications');
      const timeoutParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeLockTimeout');
      const routeLockTimeout = timeoutParam ? parseInt(timeoutParam.get('value'), 10) : 1800;
      const json = {
        data: {
          type: 'lock',
          id: dispatchRoute.get('id'),
          attributes: {
            entityId: dispatchRoute.get('id'),
            entityType: 'dispatchRoute',
            timeToLive: ttl ? ttl : routeLockTimeout,
            persist: persist,
            logging: logging,
            thresholdTTL: thresholdTTL
          }
        }
      };

      try {
        const response = await ajax.post(_apiUrls.API.dispatchService.host + '/lock', {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          },
          data: json
        });

        if (response.data && response.data.length > 0) {
          return response.data[0];
        }
      } catch (error) {
        notifications.warning('Obtain route lock failed at this time, please retry operation.');

        if (forwardError) {
          throw error;
        }
      }
    },

    async doUnlockRoute(dispatchRoute) {
      const ajax = this.get('ajaxService');
      const session = this.get('session');
      const notifications = this.get('notifications');
      const userId = session.data.authenticated.userId;
      const dispatchRouteLock = this.get('store').peekRecord('lock', dispatchRoute.get('id'));
      const user = this.get('store').peekRecord('sso-user', userId);
      const permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.unlockOtherUserLocks, null);

      if (Ember.isNone(dispatchRouteLock)) {
        return;
      }

      if (userId !== dispatchRouteLock.get('owner.id') && !user.get('isAdmin') && !permGranted) {
        notifications.info('You do not have permission to unlock this route.');
        return;
      }

      if (!Ember.isNone(dispatchRouteLock)) {
        try {
          await ajax.delete(_apiUrls.API.dispatchService.host + '/lock/dispatchRoute/' + dispatchRouteLock.get('id'), {
            method: 'DELETE',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          });
        } catch (error) {
          if ((0, _errors.isNotFoundError)(error)) {
            // lock can be unlock from server so avoid pop up banner.
            return;
          }

          notifications.warning(`Error removing route lock, ${error.message}`);
          throw error;
        }
      }
    },

    async forceUnlockRoute(dispatchRoute) {
      let logging = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      const ajax = this.get('ajaxService');
      const session = this.get('session');
      const notifications = this.get('notifications');
      const userId = session.data.authenticated.userId;
      const dispatchRouteLock = this.get('store').peekRecord('lock', dispatchRoute.get('id'));
      const user = this.get('store').peekRecord('sso-user', userId);
      const permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.unlockOtherUserLocks, null);

      if (Ember.isNone(dispatchRouteLock)) {
        return;
      }

      if (userId !== dispatchRouteLock.get('owner.id') && !user.get('isAdmin') && !permGranted) {
        notifications.info('You do not have permission to unlock this route.');
        return;
      }

      if (!Ember.isNone(dispatchRouteLock)) {
        try {
          await ajax.delete(_apiUrls.API.dispatchService.host + '/lock/force/dispatchRoute/' + dispatchRouteLock.get('id') + `?logging=${logging}`, {
            method: 'DELETE',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          });
        } catch (error) {
          if ((0, _errors.isNotFoundError)(error)) {
            // lock can be unlock from server so avoid pop up banner.
            return;
          }

          notifications.warning(`Error removing route lock, ${error.message}`);
          throw error;
        }
      }
    }

  });

  _exports.default = _default;
});