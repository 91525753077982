define("adept-iq/pods/register/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['invitationId'],

    /*eslint-disable */
    invitationId: undefined,
    data: undefined,

    /*eslint-enable */
    invitation: Ember.computed.alias('data.invitation'),
    errors: Ember.computed.alias('data.errors')
  });

  _exports.default = _default;
});