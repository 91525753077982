define("adept-iq/services/gps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_OPTIONS = {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 5000
  }; // rather than including `Ember.Evented` mixin, we implement its interface
  // ourselves, wrapping `navigator.geolocation.watchPosition` for each sub;
  // this way we don't need a global "on/off" setting

  var _default = Ember.Service.extend({
    watches: null,

    getLocation() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const mergedOptions = Object.assign({}, DEFAULT_OPTIONS, options);
      return new Ember.RSVP.Promise((resolve, reject) => {
        window.navigator.geolocation.getCurrentPosition(pos => {
          resolve(pos);
        }, reject, mergedOptions);
      });
    },

    onLocationChange(eventHandler) {
      return this._createWatch(eventHandler);
    },

    offLocationChange(eventHandler) {
      return this._clearWatch(eventHandler);
    },

    _createWatch(eventHandler) {
      if (!this.watches) {
        this.watches = [];
      }

      const watch = this.watches.findBy('eventHandler', eventHandler);
      if (watch) return watch.id;
      const id = window.navigator.geolocation.watchPosition(pos => {
        eventHandler(pos);
      }, () => {// NOOP
      }, DEFAULT_OPTIONS);
      this.watches.pushObject({
        id,
        eventHandler
      });
      return id;
    },

    _clearWatch(eventHandler) {
      if (!this.watches) {
        this.watches = [];
      }

      const watch = this.watches.findBy('eventHandler', eventHandler);

      if (watch) {
        window.navigator.geolocation.clearWatch(watch.id);
        this.watches.removeObject(watch);
      }
    }

  });

  _exports.default = _default;
});