define("adept-iq/pods/components/iq-widgets/driver-breaks-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/driver-breaks-form-widget/config", "adept-iq/utils/unwrapProxy", "moment", "lodash", "adept-iq/mixins/version-check"], function (_exports, _component, _config, _unwrapProxy, _moment, _lodash, _versionCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FIXED_BREAK_TYPE = 'fixed';
  const TITLE_BREAK = 'Breaks';
  const ESTIMATEDSTART = 'estimatedStart';

  var _default = _component.default.extend(_versionCheck.default, {
    classNames: ['driver-break-form-widget'],
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    notifications: Ember.inject.service(),
    geocode: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.updateMinimumTimeDuration();
    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const route = (0, _unwrapProxy.unwrapProxy)(record.get('route'));
        const estimatedStartMoment = (0, _moment.default)(record.get('requestTime'));
        const estimatedEndMoment = (0, _moment.default)(record.get('projectedEndDate'));
        const routePlannedStartTime = (0, _moment.default)(route.get('plannedStartTime'));
        const routeActualEndTime = route.get('actualEndTimestamp') ? (0, _moment.default)(route.get('actualEndTimestamp')) : null;
        const routeOrdinal = (0, _unwrapProxy.unwrapProxy)(route.get('dispatchRoute')).editBreakRouteDuration(record);

        if (routeOrdinal === -1) {
          valid = false;
          this.get('editModal.errors').pushObject('Cannot find a route position for the break start time.');
        }

        record.set('plannedRouteOrdinal', routeOrdinal);

        if (Ember.isNone(record.get('breakType.id'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Must select a break type.');
        }

        if (record.get('breakType.id') === FIXED_BREAK_TYPE && Ember.isEmpty(record.get('place.location.lat'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Fixed break type must have an address.');
        }

        if (estimatedStartMoment.isSameOrAfter(estimatedEndMoment)) {
          valid = false;
          this.get('editModal.errors').pushObject('Break start time must be before Break end time.');
        }

        if (estimatedStartMoment.isBefore(routePlannedStartTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Break start time must be after the route\'s planned start time.');
        }

        if (routeActualEndTime) {
          if (estimatedEndMoment.isAfter(routeActualEndTime)) {
            valid = false;
            this.get('editModal.errors').pushObject('Break end time must be before the route\'s actual end time.');
          }
        }

        record.set('estimatedStart', estimatedStartMoment.toDate());
        record.set('estimatedEnd', estimatedEndMoment.toDate());
      });
      return valid;
    },

    updateMinimumTimeDuration() {
      const editableSections = this.get('editableSections');
      const updatedTableSections = editableSections.map(tableSection => {
        if (tableSection.title === TITLE_BREAK) {
          tableSection.fields.map(field => {
            if (field.id === ESTIMATEDSTART) {
              const record = this.get('editableRecords')[0];
              field.extra.minTimePath = (0, _moment.default)(record.get('route.plannedStartTime')).isSameOrBefore(record.get('route.actualStartTimestamp')) ? 'route.plannedStartTime' : 'route.actualStartTimestamp';
            }

            return field;
          });
        }

        return tableSection;
      });
      this.set('editableSections', updatedTableSections);
    },

    rollback(model) {
      const changedAttributeModel = model.changedAttributes();

      if (changedAttributeModel) {
        (0, _lodash.forEach)(changedAttributeModel, function (attributevalues, attributeName) {
          model.set(attributeName, attributevalues[0]);
        });
      }
    },

    undoForEditBreak() {
      const lastUndoState = this.get('editModal.undoHistory').popObject();
      lastUndoState.forEach(_ref => {
        let {
          record,
          properties
        } = _ref;

        if (properties.hasOwnProperty('country')) {
          this.rollback((0, _unwrapProxy.unwrapProxy)(record.get('location')));
          this.rollback((0, _unwrapProxy.unwrapProxy)(record.get('address')));
        }

        record.setProperties(properties);
      });
      this.get('geocode').deactivateGeocode();
      this.set('isLastUndoStateCommitted', true);
    },

    callSetRecordValue(record, valuePath, value, options) {
      this.get('service').setRecordValue(record, valuePath, value, options);
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        const undoHistory = this.get('editModal.undoHistory');
        if (Ember.isPresent(undoHistory)) while (undoHistory.length > 0) this.undoForEditBreak();
      },

      async onApplyClick() {
        const records = this.get('editableRecords.firstObject');
        const dispatchRoute = records.get('route.dispatchRoute');
        const lockRouteAction = this.get('lockRouteAction').bind(this);
        const unlockRouteAction = this.get('unlockRouteAction').bind(this);
        this.set('dispatchRouteToCheck', dispatchRoute);

        const closeCallback = () => {
          this.send('onCloseClick');
        };

        await this.get('versionCheckOperation').perform(closeCallback.bind(this));

        if (this.validateRecords()) {
          try {
            await lockRouteAction();
            const title = this.get('title');
            this.get('service').applyWithCallback(title, unlockRouteAction);
          } catch (err) {
            await unlockRouteAction();
            return;
          }
        }
      },

      onCloseClick() {
        const records = this.get('editableRecords') || [];
        records.forEach(record => {
          const driverBreak = record; // restore the original break times

          this.rollback(driverBreak);
        });
        const undoHistory = this.get('editModal.undoHistory');
        if (Ember.isPresent(undoHistory)) while (undoHistory.length > 0) this.undoForEditBreak();
        this.get('service').close();
      },

      onCellValueChange(record, valuePath, value, options) {
        if (valuePath === 'notes') {
          // fast changes to a field messes up the lastUndoHistory
          Ember.run.debounce(this, 'callSetRecordValue', record, valuePath, value, options, 300);
        } else {
          this.get('service').setRecordValue(record, valuePath, value, options);
        }
      }

    }
  });

  _exports.default = _default;
});