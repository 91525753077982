define("adept-iq/adapters/trip-payment", ["exports", "adept-iq/adapters/-avlm-avlmService"], function (_exports, _avlmAvlmService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // there is no `trip-payment` model; this adapter is a container for AJAX call
  var _default = _avlmAvlmService.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    // TODO: remove this if possible
    routeExecEvent: Ember.inject.service('routeexecevent'),

    createTripPaymentEvent(trip) {
      const session = this.get('session');
      const uri = this.buildURL('trip-payment'); // TODO: try to replace this with actual model

      const routeExecEvent = this.get('routeExecEvent');
      const data = routeExecEvent.tripPaymentData();
      const preparedData = routeExecEvent.prepareTripPaymentData(data, trip);
      return this.get('ajax').post(uri, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: preparedData
      });
    }

  });

  _exports.default = _default;
});