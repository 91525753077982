define("adept-iq/pods/components/schedule-generate-modal/component", ["exports", "lodash", "ember-concurrency", "adept-iq/mixins/async-schedule-operation"], function (_exports, _lodash, _emberConcurrency, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    equal
  } = Ember.computed;
  const TIMEOUT_DELAY = 10000; // 10 second tries

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    timeout: TIMEOUT_DELAY,
    // passed in from stackFrame
    currentSchedule: readOnly('stackFrame.options.currentSchedule'),
    radioValue: readOnly('stackFrame.options.radioValue'),
    provider: readOnly('stackFrame.options.provider'),
    isReoptimized: equal('radioValue', 'reoptimize'),
    isWaitlisted: equal('radioValue', 'waitlist'),
    title: 'Reoptimization',
    description: Ember.computed('isWaitlisted', function () {
      const isWaitlisted = this.get('isWaitlisted');
      return isWaitlisted && 'Attempting to waitlist trips in progress';
    }),
    progress: 0,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.onLoadTask.perform();
    },

    didRender() {
      this._super(...arguments);
    },

    onReset() {
      this.get('workspace').popState('schedule-generate');
    },

    onLoadTask: (0, _emberConcurrency.task)(function* () {
      const isReoptimized = this.get('isReoptimized');
      const onGenerateSchedule = this.onGenerateSchedule;
      const attemptToWaitlistTrips = this.attemptToWaitlistTrips;

      try {
        if (isReoptimized) {
          this.set('workspace.triggeredReoptimize', true);
          this.set('workspace.reoptimizeInProgress', true);
          yield onGenerateSchedule.perform();
        } else yield attemptToWaitlistTrips.perform();
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          // re-throw the non-cancelation error
          console.error(e); // eslint-disable-line no-console
        }
      } finally {
        yield (0, _emberConcurrency.timeout)(2000);
        this.set('workspace.triggeredReoptimize', false);
        this.set('workspace.reoptimizeInProgress', false);
        this.onReset();
      }
    }),
    onGenerateSchedule: (0, _emberConcurrency.task)(function* () {
      const schedule = this.get('currentSchedule');
      const provider = this.get('provider');
      const options = {};
      let operationType = 'generate';
      let res = null;

      if (provider !== 'all') {
        operationType = 'reoptimizeWithConditions';
        options.operationData = {
          conditions: {
            provider: provider
          }
        };
      }

      try {
        res = yield this.createScheduleAsyncOperation.perform(schedule, operationType, options);

        if (res) {
          this.get('notifications').success('SCHEDULE SUCCESSFULLY REOPTIMIZED');
        } else {
          this.get('notifications').warning('SCHEDULE FAILED TO REOPTIMIZE');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          // re-throw the non-cancelation error
          this.get('notifications').warning('SCHEDULE FAILED TO REOPTIMIZE');
          console.warn(e); // eslint-disable-line no-console

          throw e;
        }
      }

      return Promise.resolve(res);
    }),
    attemptToWaitlistTrips: (0, _emberConcurrency.task)(function* () {
      const schedule = this.get('currentSchedule');
      const progress = this.get('progress');
      let res = null;
      let resSuccess = false;

      try {
        res = yield this.createScheduleAsyncOperation.perform(schedule, 'scheduleWaitlist', {
          outputResult: true
        });
        resSuccess = res.state === 'success';

        if (!resSuccess) {
          const resResults = _lodash.default.get(res, 'results', '');

          const scheduleId = _lodash.default.get(res, 'jsonData.relationships.schedule.data.id', '');

          const successError = `Error: Schedule ${scheduleId} does not contain any trips with statuses: waitlisted`;
          resSuccess = resResults.startsWith(successError);
        }

        if (resSuccess) {
          if (Ember.isPresent(progress)) this.set('progress', 100);
          this.get('notifications').success('SUCCESSFULLY ATTEMPTED TO SCHEDULE ALL WAITLIST TRIPS');
        } else {
          this.get('notifications').warning('FAILED ATTEMPT TO SCHEDULE ALL WAITLIST TRIPS');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          // re-throw the non-cancelation error
          this.get('notifications').warning('FAILED ATTEMPT TO SCHEDULE ALL WAITLIST TRIPS');
          throw e;
        }
      }

      return Promise.resolve(resSuccess);
    })
  });

  _exports.default = _default;
});