define("adept-iq/pods/components/iq-widgets/permissions-users-widget/table/cells/base-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-base-cell', 'data-test-table-base-cell'],
    classNameBindings: ['alertCategory', 'data-test-alertCategory']
  });

  _exports.default = _default;
});