define("adept-iq/adapters/reconcile-stop", ["exports", "adept-iq/adapters/-reconciliationService"], function (_exports, _reconciliationService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reconciliationService.default.extend({
    buildURL(modelName
    /*, id, snapshot, requestType, query*/
    ) {
      return this._buildUrlForPath(modelName);
    },

    _buildUrlForPath() {
      const host = this.get('host');
      return `${host}/stop`;
    },

    postStop(routePayload, type) {
      const session = this.get('session');
      const json = {
        type,
        attributes: routePayload
      };
      return this.get('ajaxService').post(this.get('host') + '/stop', {
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    }

  });

  _exports.default = _default;
});