define("adept-iq/serializers/etanav-route", ["exports", "adept-iq/serializers/-dispatch", "adept-iq/config/environment"], function (_exports, _dispatch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      if (requestType === 'findRecord') {
        const data = payload.data;
        let garageStops = null;
        payload.data.type = 'etanav-route'; // Now findRecord from iqux-service

        const stopPoints = payload.included;

        if (payload.data && !Ember.isBlank(stopPoints)) {
          garageStops = stopPoints.filter(stop => stop.type === 'etanavStopPoint' && (stop.id.includes('GP') || stop.id.includes('GD')));
          garageStops.forEach(stopPoint => {
            let stopType = 'pullinGarage';
            let parentType = 'pullinGarage';
            let routeOrdinal;

            if (stopPoint.id.includes('GP')) {
              stopType = 'pulloutGarage';
              parentType = 'pulloutGarage';
              routeOrdinal = '0';
            }

            payload.included.push({
              id: stopPoint.id,
              type: _environment.default.APP.avlmLite ? 'avlm-stop-point' : 'stop-point',
              attributes: {
                stopType: stopType,
                parentType: parentType,
                routeOrdinal: routeOrdinal,
                status: stopPoint.attributes.status,
                dwell: stopPoint.attributes.dwell,
                lat: stopPoint.attributes.lat,
                lng: stopPoint.attributes.lng,
                eta: stopPoint.attributes.eta
              },
              relationships: {
                stopRoute: {
                  data: {
                    type: _environment.default.APP.avlmLite ? 'avlm-route' : 'route',
                    id: data.id
                  }
                }
              }
            });
          });
        }
      }

      return this._super(...arguments);
    },

    normalize(typeClass, hash) {
      if (_environment.default.APP.avlmLite) {
        const {
          route,
          routeBreaks,
          vehicle,
          stopPoints
        } = hash.relationships || {};

        if (route) {
          this.dasherizeRelationType(route);
          hash.relationships.avlmRoute = route;
          delete hash.relationships.route;
        }

        if (routeBreaks) {
          this.dasherizeRelationShipCollection(routeBreaks);
          hash.relationships.etaRouteBreaks = routeBreaks;
          delete hash.relationships.routeBreaks;
        }

        if (vehicle) {
          this.dasherizeRelationType(vehicle);
          hash.relationships.avlmVehicle = vehicle;
          delete hash.relationships.vehicle;
        }

        if (stopPoints) {
          this.dasherizeRelationShipCollection(stopPoints);
          hash.relationships.etaStops = stopPoints;
          delete hash.relationships.stopPoints;
        } // we are keeping the dispatchRoute as id so
        // only update 1 eta-route record per dispatchRoute


        if (route) {
          hash.id = route.data.id;
        }
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});