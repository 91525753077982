define("adept-iq/classes/active-contexts/permission/permission", ["exports", "lodash", "adept-iq/classes/active-contexts/base/base", "adept-iq/classes/active-contexts/permission/graph", "adept-iq/classes/active-contexts/permission/map-graph"], function (_exports, _lodash, _base, _graph, _mapGraph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // overridden values based on extending sub classes
    // computed.readOnly('workspaceContext._structuredWorkspace')
    graph: null,
    nodes: null,
    emptyImplicitHash: null,
    permSsoUsers: null,
    permSsoRoles: null,
    workspaceData: Ember.computed.readOnly('workspaceContext._structuredWorkspace'),
    permissionActiveData: Ember.computed('permSsoUsers.[]', 'permSsoRoles.[]', 'checkedItems.[]', function () {
      const permSSoUsers = this.get('permSsoUsers') || [];
      const permSsoRoles = this.get('permSsoRoles') || [];
      const checkedItems = this.get('checkedItems') || [];
      const permissionData = {
        'perm-sso-user': permSSoUsers,
        'perm-sso-role': permSsoRoles
      };

      const data = this._computeActiveContext(permissionData, checkedItems);

      return data;
    }),
    consumedModelNames: Ember.computed('workspace.dashboardInstance.widgets.@each.models', function () {
      const widgets = this.get('workspace.dashboardInstance.widgets');

      const widgetModels = _lodash.default.reduce(widgets, (acc, widget) => _lodash.default.union(acc, widget.get('models')), []);

      return widgetModels;
    }),

    init() {
      this._super(...arguments);

      this.set('checkedItems', []);
      this.set('refreshedModelNames', []);
      this.set('mapGraph', _mapGraph.mapContextGraph);
      this.set('mapNodes', _mapGraph.mapContextNodes);
      this.set('graph', _graph.activeContextGraph);
      this.set('nodes', _graph.activeContextNodes);

      const nodes = _graph.activeContextNodes.reduce((acu, node) => {
        acu[node.modelName] = {};
        return acu;
      }, {});

      this.set('emptyImplicitHash', nodes);
      this.startRefreshQueue();
    },

    destroy() {
      this._super(...arguments);
    },

    willDestroyElement() {
      if (this.get('setWidgetRecordsTask.isRunning')) {
        this.get('setWidgetRecordsTask').cancelAll();
      }
    },

    // Overwrite base clearCheckedItems
    clearCheckedItems() {
      const checkedItems = this.get('checkedItems');

      for (const checkedItem of checkedItems) {
        this.toggleHighLight(checkedItem.record, false);
      }

      this.set('checkedItems', []);
      this.get('widget').deselectAllWidgets();
      this.notifyPropertyChange('implicitData');
      this.refreshAll();
    }

  });

  _exports.default = _default;
});