define("adept-iq/pods/components/rider-management-widget/rider-contact-information/new-phone-number-dialog/component", ["exports", "adept-iq/config/placeholders"], function (_exports, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['add-rider-phone-detail-form'],
    tooltip: Ember.inject.service(),
    rider: Ember.inject.service(),
    store: Ember.inject.service(),
    PHONE_NUMBER_PLACEHOLDER: _placeholders.PHONE_NUMBER_PLACEHOLDER,
    selectedType: null,
    phoneTypeOptions: Ember.computed('configRiderPhoneTypes', 'riderPhoneNumbers.[]', async function () {
      const configRiderPhoneTypes = this.get('configRiderPhoneTypes') || [];
      const riderPhoneRecordType = this.get('riderPhoneNumbers') || [];
      const savedRiderPhoneRecordType = riderPhoneRecordType.map(type => {
        return type.get('type');
      }); // ['main','home'']

      const filterPhoneTypes = configRiderPhoneTypes.filter(phoneType => !savedRiderPhoneRecordType.includes(phoneType.get('name')));
      return filterPhoneTypes;
    }),

    async init() {
      this._super(...arguments);

      const configRiderPhoneTypes = await this.get('rider').getConfigRiderPhoneTypes();
      const curRiderPhoneRecord = this.get('riderPhoneRecord');
      const curPhoneTypeName = curRiderPhoneRecord.get('type');

      if (curPhoneTypeName) {
        const phoneType = configRiderPhoneTypes.find(record => record.get('name') === curPhoneTypeName);
        this.set('selectedType', phoneType);
      }

      this.setProperties({
        configRiderPhoneTypes
      });
    },

    actions: {
      onPhoneTypeChange(riderPhoneRecord, option) {
        this.set('selectedType', option);
        riderPhoneRecord.set('type', option.get('name'));
      },

      onPhoneValueChange(record, maskObj) {
        // match[0] = area code
        // match[1] = first part of 3 digit phone number
        // match[2] = second part of 4 digit phone number
        const match = maskObj.masked.replace(/_/, '').split('-');
        const phone = record;
        phone.set('areaCode', match[0]);
        phone.set('phoneNumber', match[1] + match[2]);
        phone.set('phone', match[1] + match[2]);
      },

      closePhoneDialog() {
        this.set('canShowRiderPhoneNumberModal', false);
        this.riderPhoneRecord.rollbackAttributes();
      },

      onTick() {
        const tooltip = this.get('tooltip');
        const phone = this.riderPhoneRecord.get('unformattedPhoneNumber');
        const type = this.riderPhoneRecord.get('type');

        if (!phone || phone.length !== 10) {
          tooltip.pushConfirmation({
            title: 'Rider Management',
            tip: 'Phone number is required.',
            primaryActionText: 'Ok',
            primaryAction: () => {
              return tooltip.reset();
            }
          });
        } else if (!Ember.isPresent(type)) {
          tooltip.pushConfirmation({
            title: 'Rider Management',
            tip: 'Phone number must have a type.',
            primaryActionText: 'Ok',
            primaryAction: () => {
              return tooltip.reset();
            }
          });
        } else {
          tooltip.pushConfirmation({
            title: 'Rider Management',
            tip: 'Are you sure you want to save these changes?',
            primaryActionText: 'Confirm',
            primaryAction: () => {
              this.get('onRiderPhoneNumberSave')();
              this.set('canShowRiderPhoneNumberModal', false);
              return tooltip.reset();
            },
            secondaryActionText: 'Cancel',
            secondaryAction: () => {
              return tooltip.reset();
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});