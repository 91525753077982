define("adept-iq/instance-initializers/ember-hifi", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    const {
      emberHifi,
      environment = 'development'
    } = _environment.default;
    const options = {
      emberHifi,
      environment
    };
    application.register('config:hifi', options, {
      instantiate: false
    });
    application.inject('service:hifi', 'options', 'config:hifi');
  }

  var _default = {
    name: 'ember-hifi',
    initialize
  };
  _exports.default = _default;
});