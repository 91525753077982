define("adept-iq/tests/factories/eligibility-type", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  /* eslint-disable no-use-before-define */
  _emberDataFactoryGuy.default.define('eligibility-type', {
    sequences: {
      names: num => eligibilityTypes[num % eligibilityTypes.length].name
    },
    default: {
      name: _emberDataFactoryGuy.default.generate('names')
    }
  });

  const eligibilityTypes = [
  /* Config Elgibility Type Fixtures */
  {
    name: 'disability'
  }, {
    name: 'medical'
  }, {
    name: 'senior'
  }];
});