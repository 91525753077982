define("adept-iq/serializers/leg", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    serialize() {
      const json = this._super(...arguments);

      const serviceWindowRelationship = json.data.relationships.serviceWindow;
      const fundingAgencyRelationship = json.data.relationships.fundingAgency;
      const tripPurposeRelationship = json.data.relationships.tripPurpose;
      const booking = json.data.relationships.booking;

      if (!Ember.isNone(serviceWindowRelationship) && !Ember.isNone(serviceWindowRelationship.data)) {
        serviceWindowRelationship.data.type = 'serviceWindowName';
        json.data.relationships.serviceWindowName = json.data.relationships.serviceWindow;
        delete json.data.relationships.serviceWindow;
      }

      if (!Ember.isNone(fundingAgencyRelationship)) {
        if (!Ember.isNone(fundingAgencyRelationship.data)) {
          fundingAgencyRelationship.data.type = 'fundingAgencyName';
          json.data.relationships.fundingAgencyName = json.data.relationships.fundingAgency;
        }

        delete json.data.relationships.fundingAgency;
      }

      if (!Ember.isNone(tripPurposeRelationship)) {
        if (!Ember.isNone(tripPurposeRelationship.data)) {
          tripPurposeRelationship.data.type = 'tripPurposeName';
          json.data.relationships.tripPurposeName = json.data.relationships.tripPurpose;
        }

        delete json.data.relationships.tripPurpose;
      }

      if (!Ember.isNone(json.data.id) && !Ember.isNone(booking)) {
        delete json.data.relationships.booking;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.relationships)) {
        if (!Ember.isNone(resourceHash.relationships.serviceWindowName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.serviceWindow = {
            data: {
              type: 'service-window',
              id: resourceHash.relationships.serviceWindowName.data.id
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.serviceWindowName;
        }

        if (!Ember.isNone(resourceHash.relationships.travelNeeds)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.legTravelNeeds = resourceHash.relationships.travelNeeds;
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.travelNeeds;
        }

        if (!Ember.isNone(resourceHash.relationships.serviceNeeds)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.legServiceNeeds = resourceHash.relationships.serviceNeeds;
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.serviceNeeds;
        }
      }

      if (!Ember.isNone(resourceHash.attributes.serviceWindowName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.serviceWindow = {
          data: {
            type: 'service-window',
            id: resourceHash.attributes.serviceWindowName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.serviceWindowName;
      }

      if (!Ember.isNone(resourceHash.relationships.fundingAgencyName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.fundingAgency = {
          data: {
            type: 'funding-agency',
            id: resourceHash.relationships.fundingAgencyName.data.id
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.relationships.fundingAgencyName;
      }

      if (!Ember.isNone(resourceHash.relationships.tripPurposeName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.tripPurpose = {
          data: {
            type: 'trip-purpose',
            id: resourceHash.relationships.tripPurposeName.data.id
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.relationships.tripPurposeName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});