define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/tables/road-call/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'vehicleRoadCall',
    title: 'Road Call',
    defaultSortId: 'id',
    defaultSortAsc: true,
    selectFirstRow: true,
    queryModelData: true,
    tooltipTitle: 'Manage Vehicle',
    columns: [{
      id: 'callDate',
      index: 1,
      type: 'date',
      label: 'Call Date',
      valuePath: 'callDate',
      defaultWidth: 120,
      searchable: true,
      valuePreview: '2020-05-15',
      format: `${_environment.default.dateTimeFormat.dateMoment}`
    }, {
      id: 'code',
      index: 3,
      type: 'string',
      label: 'Code',
      valuePath: 'roadCallCodeName.code',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'description',
      index: 4,
      type: 'text',
      label: 'Code Description',
      valuePath: 'roadCallCodeName.description',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'driverLastName',
      index: 5,
      type: 'text',
      label: 'Driver Last Name',
      valuePath: 'driver.lastName',
      defaultWidth: 125,
      readonlyCell: true,
      editable: true,
      searchable: true
    }, {
      id: 'driverFirstName',
      index: 6,
      type: 'text',
      label: 'Driver First Name',
      valuePath: 'driver.firstName',
      defaultWidth: 125,
      readonlyCell: true,
      editable: true,
      searchable: true
    }, {
      id: 'driverCdlNumber',
      index: 7,
      type: 'string',
      label: 'Driver CDL Number',
      valuePath: 'driver.license.license',
      defaultWidth: 135,
      searchable: true
    }, {
      id: 'routeId',
      index: 8,
      type: 'string',
      label: 'Route ID',
      valuePath: 'route.name',
      defaultWidth: 90,
      searchable: true
    }, {
      id: 'mileage',
      index: 9,
      type: 'number',
      label: 'Mileage',
      valuePath: 'mileage',
      defaultWidth: 90,
      searchable: true
    }, {
      id: 'notes',
      index: 10,
      type: 'text',
      label: 'Notes',
      valuePath: 'notes',
      defaultWidth: 90,
      searchable: true
    }, {
      id: 'driverId',
      index: 11,
      type: 'text',
      label: 'Driver ID',
      valuePath: 'driver.driverId',
      defaultWidth: 90,
      searchable: true
    }]
  };
  _exports.default = _default;
});