define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-client/component", ["exports", "adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-client/client-config", "adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/reconcile-search-client/client-search-config"], function (_exports, _clientConfig, _clientSearchConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CLIENT_FIELD_MAPPER = {
    selectedClientId: 'clientId',
    selectedFirstName: 'firstName',
    selectedLastName: 'lastName'
  };

  var _default = Ember.Component.extend({
    reconcileLambda: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    clientTable: null,
    clientTableSearch: null,
    selectedClientId: null,
    selectedFirstName: null,
    selectedLastName: null,
    addressTable: null,
    clientConfig: _clientConfig.default,
    clientSearchConfig: _clientSearchConfig.default,
    canSearchClientTrips: Ember.computed.notEmpty('selectedClientIdFromTable'),
    canEnableSearchAndClear: Ember.computed.or('selectedClientId', 'selectedFirstName', 'selectedLastName'),

    init() {
      this._super(...arguments);

      this.set('fetchClients', this.get('fetchClients').bind(this));
      this.set('fetchRoutesByClient', this.get('fetchRoutesByClient').bind(this));
      this.set('onRowClickSetClient', this.get('onRowClickSetClient').bind(this));
      this.set('clientConfig.title', '');
      this.set('clientSearchConfig.title', '');
    },

    isClientValueChanged() {
      const clientFields = Object.keys(CLIENT_FIELD_MAPPER);
      return clientFields.some(field => Ember.isPresent(this.get(field)));
    },

    async fetchClients() {
      if (!this.get('selectedSearchMode') || !this.isClientValueChanged()) {
        return [];
      }

      const searchMode = this.get('selectedSearchMode').toLowerCase();
      const queryParams = {};
      Object.keys(CLIENT_FIELD_MAPPER).forEach(clientField => {
        const clientFieldKey = CLIENT_FIELD_MAPPER[clientField];
        const clientFieldValue = this.get(clientField);

        if (clientFieldValue) {
          queryParams[clientFieldKey] = clientFieldValue;
        }
      });
      const clients = await this.reconcileLambda.getReconcileRoutesAndTripsInfoByClientId.perform(searchMode, queryParams);

      if (!clients.length) {
        this.set('clientConfig.title', '');
        this.set('clientConfig.noRecordsFound', true);
      } else {
        this.set('clientConfig.title', `${Ember.String.capitalize(searchMode)}`);
        this.set('clientConfig.noRecordsFound', false);
      }

      return clients;
    },

    async fetchRoutesByClient() {
      const clientId = this.get('selectedClientIdFromTable');

      if (!this.get('selectedSearchMode') || !clientId) {
        return [];
      }

      const searchMode = this.get('selectedSearchMode').toLowerCase();
      const routes = await this.reconcileLambda.getReconcileTripsInfoByClientId.perform(searchMode, clientId);

      if (!routes.length) {
        this.set('clientSearchConfig.title', '');
        this.set('clientSearchConfig.noRecordsFound', true);
      } else {
        this.set('clientSearchConfig.title', 'Client Trips');
        this.set('clientSearchConfig.noRecordsFound', false);
      }

      const updatedResponse = routes.map(res => {
        return { ...res,
          tripDate: this.get('reconcile.reconcileDate')
        };
      });
      return updatedResponse;
    },

    onRowClickSetClient(row) {
      this.set('selectedClientIdFromTable', row.get('riderId'));
    },

    updateSearchProperty(value, item) {
      Ember.set(this, item, value);
      this.notifyPropertyChange(item);
    },

    actions: {
      onSearchRoutesByClient() {
        this.set('selectedClientIdFromTable', null);
        this.get('clientTable').refreshData();
      },

      onSearchByClient() {
        this.get('clientTableSearch').refreshData();
      },

      clearSearchClient() {
        this.setProperties({
          selectedClientId: null,
          selectedFirstName: null,
          selectedLastName: null,
          selectedClientIdFromTable: null
        });
        this.get('clientTable').clearData();
        this.get('clientTableSearch').clearData();
        this.set('clientConfig.title', '');
        this.set('clientSearchConfig.title', '');
        this.set('clientConfig.noRecordsFound', false);
        this.set('clientSearchConfig.noRecordsFound', false);
        this.set('routeId', null);
      },

      onInputTextChange(valuePath, searchText) {
        Ember.run.debounce(this, 'updateSearchProperty', searchText, valuePath, 200);
        this.set('clientConfig.noRecordsFound', false);
      }

    }
  });

  _exports.default = _default;
});