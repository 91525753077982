define("adept-iq/pods/components/side-drawer/assign-vehicle-driver-to-route/component", ["exports", "ember-concurrency", "moment", "adept-iq/config/mapped-permIds", "lodash", "adept-iq/utils/vehicleCapacity", "adept-iq/mixins/fetchAssignableVehicleDrivers", "adept-iq/utils/availability", "adept-iq/utils/unwrapProxy"], function (_exports, _emberConcurrency, _moment, _mappedPermIds, _lodash, _vehicleCapacity, _fetchAssignableVehicleDrivers, _availability, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // sort out proxies
  var _default = Ember.Component.extend(_fetchAssignableVehicleDrivers.default, {
    classNames: ['assign-rvd-side-drawer'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    vehiclesTable: null,
    currentState: null,
    onShrinkClick: null,
    onCloseClick: null,
    canCancel: true,
    driverPhoneNumber: null,
    drivers: null,
    selectedDriver: null,
    defaultSelectedVehicle: null,
    vehicleSort: null,
    refreshFlag: false,
    filterVehiclesByHoliday: true,
    filterDriversByHoliday: true,
    filterDriversByShiftTime: true,
    dispatchRoute: Ember.computed.readOnly('stackFrame.options.dispatchRoute'),
    route: Ember.computed.readOnly('dispatchRoute.route'),
    // scheduling route,
    placeholderVehicle: Ember.computed.readOnly('route.vehicle'),
    provider: Ember.computed.readOnly('route.provider'),
    plannedStartTime: Ember.computed.readOnly('route.plannedStartTime'),
    //used in fetch vehicle driver mixin
    plannedEndTime: Ember.computed.readOnly('route.plannedEndTime'),
    //used in fetch vehicle driver mixin
    hasSelectedVehicle: Ember.computed.bool('selectedVehicle.id'),
    hasSelectedDriver: Ember.computed.bool('selectedDriver.id'),
    canAssignVehicleDriver: Ember.computed.and('hasSelectedVehicle', 'hasSelectedDriver', 'notPlaceholder'),
    routeVehicleDriver: Ember.computed.alias('dispatchRoute.latestRouteVehicleDriver'),
    dispatchRouteVersion: Ember.computed.readOnly('stackFrame.options.dispatchRoute.version'),

    init() {
      this._super(...arguments);
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('selectedDriver', currentState.selectedDriver);
        this.set('selectedVehicle', currentState.selectedVehicle);
        this.set('driverPhoneNumber', currentState.driverPhoneNumber);
      }
    },

    didInsertElement() {
      this._super(...arguments); // side panel width just for assign-vehicle-driver-to-route


      this.setSideDrawerWidth('600px');
      const dispatchRoute = this.get('dispatchRoute');
      this.set('dispatchRouteVersionSnapshot', dispatchRoute.get('version'));
      this.refresh();
    },

    didReceiveAttrs() {
      this._super(...arguments);
    },

    canSave: Ember.computed('selectedVehicle.placeholder', 'selectedDriver', 'routeVehicleDriver', 'driverPhoneNumber', function () {
      const vehicleId = this.get('selectedVehicle.name');
      const driverId = this.get('selectedDriver.driverId');
      const currentVehicleId = this.get('routeVehicleDriver.vehicle.name');
      const currentDriverId = this.get('routeVehicleDriver.driver.driverId');
      const currentDriverPhoneNumber = this.get('routeVehicleDriver.driverPhoneNumber');
      const driverPhoneNumber = this.get('driverPhoneNumber');

      if (vehicleId === currentVehicleId && currentDriverId === driverId && (currentDriverPhoneNumber === driverPhoneNumber || Ember.isEmpty(driverPhoneNumber)) || this.get('selectedVehicle.placeholder')) {
        return false;
      }

      return true;
    }),
    driverAvailability: Ember.computed('selectedDriver.driverAvailabilities.@each.{startTime,endTime}', function () {
      const driverAvailabilities = this.get('selectedDriver.driverAvailabilities');
      if (Ember.isEmpty(driverAvailabilities)) return null;
      const plannedStart = (0, _moment.default)();
      const plannedEnd = (0, _moment.default)().endOf('day');
      const solution = (0, _availability.solveAvailability)(plannedStart, plannedEnd, driverAvailabilities);
      if (Ember.isEmpty(solution)) return null;
      return solution[0];
    }),
    driverAvailabilityShiftStart: Ember.computed('driverAvailability', function () {
      const availability = this.get('driverAvailability');
      if (Ember.isEmpty(availability) || Ember.isEmpty(availability.matchingAvailability)) return null;
      return availability.matchingAvailability.get('formattedShiftStart');
    }),
    driverAvailabilityShiftEnd: Ember.computed('driverAvailability', function () {
      const availability = this.get('driverAvailability');
      if (Ember.isEmpty(availability) || Ember.isEmpty(availability.matchingAvailability)) return null;
      return availability.matchingAvailability.get('formattedShiftEnd');
    }),

    refresh() {
      this.get('fetchDriversTask').perform();
      this.get('fetchVehiclesTask').perform(); // driver can only be set in dispatch

      const driver = this.get('routeVehicleDriver.driver'); // vehicle can come from dispatch or scheduling

      const vehicle = (0, _unwrapProxy.unwrapProxy)(this.get('routeVehicleDriver.vehicle')) || (0, _unwrapProxy.unwrapProxy)(this.get('placeholderVehicle'));
      const driverPhoneNumber = this.get('routeVehicleDriver.driverPhoneNumber');
      this.set('defaultSelectedVehicle', (0, _unwrapProxy.unwrapProxy)(vehicle));
      const currentState = this.get('currentState');

      if (Ember.isEmpty(currentState)) {
        this.setProperties({
          selectedDriver: (0, _unwrapProxy.unwrapProxy)(driver),
          selectedVehicle: (0, _unwrapProxy.unwrapProxy)(vehicle),
          driverPhoneNumber
        });
        this.toggleProperty('refreshFlag');
      } else {
        this.loadCurrentState();
      }
    },

    resetRVD() {
      const routeVehicleDriver = this.get('routeVehicleDriver');

      if (routeVehicleDriver) {
        routeVehicleDriver.rollbackAttributes();
      }
    },

    driverOptions: Ember.computed('selectedVehicle.provider.id', 'drivers.@each.providerId', function () {
      const providerId = this.get('selectedVehicle.provider.id');
      const selectedDriver = this.get('selectedDriver');
      let drivers = this.get('drivers') || []; // always show selected driver

      if (selectedDriver && !drivers.includes(selectedDriver)) {
        drivers.unshift(selectedDriver);
      } // if a vehicle is selected, do not show drivers from other providers


      if (Ember.isPresent(providerId)) {
        drivers = drivers.filterBy('provider.id', providerId);
      }

      const options = [{
        driver: null,
        isNoDriver: true
      }];
      drivers.forEach(driver => {
        options.push({
          driver,
          isNoDriver: false
        });
      });
      return options;
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const route = this.get('route');
      const dispatchRoute = this.get('route.dispatchRoute');
      const vehicle = this.get('selectedVehicle');
      const capacityCounts = this.get('selectedVehicle.capacityCounts');
      const driver = this.get('selectedDriver');
      const dispatchRouteVersion = this.get('dispatchRouteVersion');
      const driverPhoneNumber = this.get('driverPhoneNumber');
      const assignVehicleOverCapacityPerm = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.assignVehicleOverCapacity, null);
      let routeVehicleDriver = this.get('routeVehicleDriver');

      if (!routeVehicleDriver) {
        routeVehicleDriver = store.createRecord('route-vehicle-driver', {
          startTime: route.get('plannedStartTime'),
          endTime: route.get('plannedEndTime'),
          dispatchRoute: this.get('dispatchRoute'),
          dispatchSchedule: this.get('dispatchRoute.dispatchSchedule')
        });
      }

      routeVehicleDriver.set('driverPhoneNumber', driverPhoneNumber);
      routeVehicleDriver.set('vehicle', vehicle);
      routeVehicleDriver.set('driver', driver);
      routeVehicleDriver.set('driverPhoneNumber', driverPhoneNumber);
      routeVehicleDriver.set('dispatchRouteVersion', dispatchRouteVersion);
      routeVehicleDriver.set('dispatchTakeover', true);
      this.set('routeVehicleDriver', routeVehicleDriver); //todo: re-visit after addressing NYAAR-10966
      //Determining if the user has the permission, plus vehicle does not have capacity in order to pass parameter

      const travelNeedCounts = this.get('dispatchRoute.maxTravelNeedCounts');
      let options = null; //assignVehicleOverCapacityPerm is also a pre-condition for hasEnoughCapacity, we want to pass the param only if both are fulfilled

      if (assignVehicleOverCapacityPerm && Ember.isPresent(travelNeedCounts) && vehicle !== null) {
        const hasEnoughCapacity = !(0, _vehicleCapacity.isUnderCapacity)(dispatchRoute, capacityCounts);
        options = hasEnoughCapacity ? null : {
          ignoreCapacityViolations: assignVehicleOverCapacityPerm
        };
      }

      try {
        yield routeVehicleDriver.save({
          adapterOptions: options
        });
      } catch (err) {
        this.resetRVD();
        throw err;
      }
    }),

    async validateVehicle(dispatchScheduleId, vehicleId, plannedStart, plannedEnd) {
      const queryOptions = {
        filter: `and(eq(dispatchScheduleId,${dispatchScheduleId}),eq(vehicleId,'${vehicleId}'))`,
        include: 'dispatchRoute'
      };
      const rvdHistory = await this.get('store').query('route-vehicle-driver', queryOptions);
      let isAvailable = true;
      let routeId = null;
      rvdHistory.forEach(rvd => {
        const start = rvd.startTime;
        const end = rvd.endTime;

        if (!rvd.get('vehicle.isCompleted') && (rvd.get('vehicle.id') === vehicleId && (0, _moment.default)(start).isBetween(plannedStart, plannedEnd, null, '[)') || (0, _moment.default)(end).isBetween(plannedStart, plannedEnd, null, '(]'))) {
          isAvailable = false;
          routeId = rvd.get('dispatchRoute.route.id');
        }
      });
      return {
        isAvailable,
        routeId
      };
    },

    async validateSelectedVehicle(vehicleId) {
      let isAvailable = true;
      await this.get('fetchVehiclesTask').perform();
      const vehicles = this.get('vehicles');

      if (Ember.isEmpty(vehicles.findBy('id', vehicleId))) {
        this.set('currentState', null);
        this.refresh();
        isAvailable = false;
      }

      return {
        isAvailable,
        routeId: null
      };
    },

    async validateDriver(dispatchScheduleId, driverId, plannedStart, plannedEnd) {
      const queryOptions = {
        filter: `and(eq(dispatchScheduleId,${dispatchScheduleId}),eq(driverId,'${driverId}'))`,
        include: 'dispatchRoute'
      };
      const rvdHistory = await this.get('store').query('route-vehicle-driver', queryOptions);
      let isAvailable = true;
      let routeId = null;
      rvdHistory.forEach(rvd => {
        const start = rvd.startTime;
        const end = rvd.endTime;

        if (!rvd.get('driver.isCompleted') && (rvd.get('driver.id') === driverId && (0, _moment.default)(start).isBetween(plannedStart, plannedEnd, null, '[)') || (0, _moment.default)(end).isBetween(plannedStart, plannedEnd, null, '(]'))) {
          isAvailable = false;
          routeId = rvd.get('dispatchRoute.route.id');
        }
      });
      return {
        isAvailable,
        routeId
      };
    },

    async validateSelectedDriver(driverId) {
      let isAvailable = true;
      await this.get('fetchDriversTask').perform();
      const drivers = this.get('drivers');

      if (Ember.isEmpty(drivers.findBy('id', driverId))) {
        this.set('currentState', null);
        this.refresh();
        isAvailable = false;
      }

      return {
        isAvailable,
        routeId: null
      };
    },

    actions: {
      onShrinkClick() {
        let currentState = this.get('currentState');
        const selectedDriver = this.get('selectedDriver');
        const selectedVehicle = this.get('selectedVehicle');
        const driverPhoneNumber = this.get('driverPhoneNumber'); // // save current manual settings

        currentState = {
          selectedDriver,
          selectedVehicle,
          driverPhoneNumber
        };
        this.set('currentState', currentState);
        this.onShrinkClick();
      },

      onColumnClick(column) {
        const valuePath = column.valuePath;
        const sortOrder = column.ascending ? 'asc' : 'desc';
        this.set('vehicleSort.valuePath', valuePath);
        this.set('vehicleSort.sortOrder', sortOrder);
      },

      onUpdatePhoneNumber(val) {
        const regexNumeric = /^\d+$/;
        const isAllNumbers = regexNumeric.test(val);

        if (isAllNumbers) {
          this.set('driverPhoneNumber', val);
        } else if (Ember.isEmpty(val)) {
          this.set('driverPhoneNumber', '');
        } else {
          this.notifyPropertyChange('driverPhoneNumber');
        }
      },

      onDriverOptionSelect(option) {
        this.set('selectedDriver', option.driver);
      },

      async onAssignVehicleDriverButtonClick() {
        const tooltip = this.get('tooltip');
        const vehicleId = this.get('selectedVehicle.name');
        const driverId = this.get('selectedDriver.driverId');
        /*eslint-disable */

        const driverPhoneNumber = this.get('driverPhoneNumber') ? this.get('driverPhoneNumber') : '\"\"';
        const currentVehicleId = this.get('routeVehicleDriver.vehicle.name');
        const currentDriverId = this.get('routeVehicleDriver.driver.driverId');
        const currentDriverPhoneNumber = this.get('routeVehicleDriver.driverPhoneNumber') ? this.get('routeVehicleDriver.driverPhoneNumber') : '\"\"';
        /*eslint-enable */

        const routeId = this.get('route.name');
        const dispatchRoute = this.get('route.dispatchRoute');
        const defaultVehicleType = this.get('defaultSelectedVehicle.vehicleType.name');
        const selectedVehicleType = this.get('selectedVehicle.vehicleType.name');
        const isDiffVehicle = vehicleId !== currentVehicleId;
        const isDiffDriver = driverId !== currentDriverId;
        const travelNeedCounts = this.get('route.travelNeedCounts');
        const capacityCounts = this.get('selectedVehicle.capacityCounts');
        const title = 'Assign Vehicle & Driver';
        const lockRouteAction = this.get('lockRouteAction').bind(this);
        const unlockRouteAction = this.get('unlockRouteAction').bind(this);
        this.set('dispatchRouteToCheck', dispatchRoute);

        try {
          await this.get('versionCheckOperation').perform(this.onCloseClick.bind(this));
          await lockRouteAction();
          let notification = '';
          let tip = '';
          let isDiffVehicleType = false;
          let warning = false;
          const errors = [];

          if (this.get('defaultSelectedVehicle') !== null) {
            isDiffVehicleType = defaultVehicleType !== selectedVehicleType;
          }

          this.set('errorMessage', null);

          if (Ember.isPresent(vehicleId) && isDiffVehicle) {
            tip = 'You are assigning'; // adding vehicle

            if (isDiffVehicle) {
              tip = `${tip} Vehicle ${vehicleId}`;
              notification = `Vehicle ${vehicleId}`;
            }
          }

          if (Ember.isPresent(driverId) && isDiffDriver) {
            if (Ember.isEmpty(tip)) {
              tip = 'You are assigning';
            }

            if (Ember.isPresent(vehicleId) && isDiffVehicle) {
              tip = `${tip} and`;
              notification = `${notification} and`;
            } // adding driver


            if (isDiffDriver) {
              tip = `${tip} Driver ${driverId}`;
              notification = `${notification} Driver ${driverId}`;
            }
          }

          if (Ember.isPresent(driverId) && isDiffDriver || isDiffVehicle && Ember.isPresent(vehicleId)) {
            tip = `${tip} to Route ${routeId}`;
            notification = `${notification} assigned to Route ${routeId}`;
          }

          if (!driverId && isDiffDriver || !vehicleId && isDiffVehicle) {
            if (Ember.isEmpty(tip)) {
              tip = 'You are';
            } else {
              tip += ' and';
            } // removing driver / vehicle


            if (isDiffVehicle && isDiffDriver && Ember.isEmpty(driverId) && Ember.isEmpty(vehicleId)) {
              tip = `You are removing Vehicle ${currentVehicleId} and Driver ${currentDriverId} from Route ${routeId}`;
              notification = `Vehicle ${currentVehicleId} and Driver ${currentDriverId} removed from Route ${routeId}`;
            } else if (Ember.isNone(driverId) && Ember.isPresent(currentDriverId)) {
              tip = `${tip} removing Driver ${currentDriverId} from Route ${routeId}`;
              notification = `${notification} removed Driver ${currentDriverId} from Route ${routeId}`;
            } else if (Ember.isNone(vehicleId) && Ember.isPresent(currentVehicleId)) {
              tip = `${tip} removing Vehicle ${currentVehicleId} from Route ${routeId}`;
              notification = `${notification} and removed Vehicle ${currentVehicleId} from Route ${routeId}`;
            }
          }

          let isNotEnough = false;

          if (Ember.isPresent(travelNeedCounts) && Ember.isPresent(capacityCounts)) {
            isNotEnough = (0, _vehicleCapacity.isUnderCapacity)(dispatchRoute, capacityCounts);
          }

          if (isNotEnough) {
            errors.push({
              validation: 'You are about to assign an incompatible vehicle. All trips that cannot be serviced will be waitlisted.'
            });
            warning = true;
          }

          if (isDiffVehicleType) {
            errors.push({
              validation: 'The selected vehicle type does not match the original vehicle type.'
            });
            warning = true;
          }

          if (Ember.isEmpty(tip) && driverPhoneNumber !== currentDriverPhoneNumber) {
            tip = `You are changing the driver phone number from ${currentDriverPhoneNumber} to ${driverPhoneNumber}`;
            notification = 'Driver phone number changed';
          } // Re-validate vehicle to make sure it is not already assigned because backend
          // is currently not doing so. This helps with race condition


          const targetVehicleId = this.get('selectedVehicle.id');

          if (Ember.isPresent(targetVehicleId) && isDiffVehicle) {
            const vehicleResult = await this.validateSelectedVehicle(targetVehicleId);

            if (!vehicleResult.isAvailable) {
              if (Ember.isNone(vehicleResult.routeId)) {
                this.get('notifications').warning(`Vehicle ${vehicleId} has already been assigned to another route`);
              } else {
                const route = await this.get('store').findRecord('route', vehicleResult.routeId); // the reason for this delay is because normalization takes a bit of time to populate model

                Ember.run.later(this, function () {
                  this.get('notifications').warning(`Vehicle ${vehicleId} has already been assigned to route ${route.get('name')}`);
                }, 600);
              }

              return;
            }
          } // Re-validate driver to make sure it is not already assigned because backend
          // is currently not doing so


          const targetDriverId = this.get('selectedDriver.id');

          if (Ember.isPresent(targetDriverId) && isDiffDriver) {
            const driverResult = await this.validateSelectedDriver(targetDriverId);

            if (!driverResult.isAvailable) {
              if (Ember.isNone(driverResult.routeId)) {
                this.get('notifications').warning(`Driver ${driverId} has already been assigned to another route`);
              } else {
                const route = await this.get('store').findRecord('route', driverResult.routeId); // the reason for this delay is because normalization takes a bit of time to populate model

                Ember.run.later(this, function () {
                  this.get('notifications').warning(`Driver ${driverId} has already been assigned to route ${route.get('name')}`);
                }, 600);
              }

              return;
            }
          }

          tooltip.pushConfirmation({
            tip,
            errors,
            title,
            hasOverlay: true,
            warning,
            primaryActionText: 'Confirm',
            primaryAction: async () => {
              return this.get('saveTask').perform().then(() => {
                this.get('notifications').success(`${notification} successfully`);
              }).catch(error => {
                let msg = `${notification} failed`;
                const e = error.errors.get('firstObject');

                if (!_lodash.default.isEmpty(e.detail)) {
                  if (e.detail.includes('modified by another request')) msg = `UNABLE TO UPDATE ROUTE ${this.get('route.name')}, BECAUSE IT HAS BEEN MODIFIED BY ANOTHER REQUEST.`;else if (e.detail.includes('overlap with another route')) msg = `UNABLE TO UPDATE ROUTE ${this.get('route.name')}, BECAUSE ASSIGNMENT OVERLAPS WITH ANOTHER ROUTE.`;else if (e.detail.includes('is(are) locked')) msg = 'Cannot modify route vehicle driver.  The requested vehicle/driver is(are) locked';else if (e.status === '408') msg = msg + ', Please try again.';
                }

                this.get('notifications').warning(msg);
              }).finally(async () => {
                await unlockRouteAction(0);
                tooltip.reset();
                this.get('workspace').requestWootricSurvey('on_vehicleDriverAssignment');
                return this.onCloseClick();
              });
            },
            secondaryActionText: 'Cancel',
            secondaryAction: async () => {
              this.resetRVD();
              await unlockRouteAction(0);
              tooltip.reset();
              return;
            },
            closeAction: async () => {
              await unlockRouteAction(0);
            }
          });
        } catch (e) {
          await unlockRouteAction();
        }
      },

      onRefreshButtonClick() {
        this.set('currentState', null);
        this.refresh();
      },

      onSelectedVehicle(vehicle) {
        this.set('selectedVehicle', vehicle);
      }

    }
  });

  _exports.default = _default;
});