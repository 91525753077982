define("adept-iq/pods/components/generic-components/base-table/component", ["exports", "resize-observer-polyfill"], function (_exports, _resizeObserverPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-component'],
    classNameBindings: ['disabled'],
    // override with per-widget static configuration
    config: null,

    didInsertElement() {
      this._super(...arguments);

      this._resizeObserver = new _resizeObserverPolyfill.default(() => {
        Ember.run.debounce(this, 'onWidgetResize', 250);
      });

      this._resizeObserver.observe(this.$()[0]);
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this._resizeObserver) {
        this._resizeObserver.disconnect();

        this._resizeObserver = null;
      }
    },

    // override this to resize 3rd party integrations (e.g. map)
    onWidgetResize() {}

  });

  _exports.default = _default;
});