define("adept-iq/pods/components/side-drawer/edit-trip-widget/booking-map/component", ["exports", "adept-iq/pods/components/generic-widgets/map-widget/component", "adept-iq/utils/geolib"], function (_exports, _component, _geolib) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-unused-vars */
  const pintPointIcon = {
    icon: window.L.icon({
      iconUrl: '/map-icons/icon-pinpoint.svg',
      iconSize: [48, 48],
      iconAnchor: [24, 48]
    }),
    keyboard: false,
    interactive: false
  };
  const arrowHeadLineConfig = {
    patterns: [{
      offset: 0,
      repeat: 0,
      symbol: window.L.Symbol.arrowHead({
        headAngle: -290,
        // flips arrow around so it looks right
        pixelSize: 16,
        polygon: true,
        pathOptions: {
          weight: 2,
          stroke: true,
          fill: true,
          fillColor: 'black',
          fillOpacity: 1,
          color: 'black',
          opacity: 1
        }
      })
    }]
  };
  const thickArrowHeadLineConfig = {
    patterns: [{
      offset: 0,
      repeat: 0,
      symbol: window.L.Symbol.arrowHead({
        headAngle: -290,
        // flips arrow around so it looks right
        pixelSize: 16,
        polygon: true,
        pathOptions: {
          weight: 2,
          stroke: true,
          fill: true,
          fillOpacity: 0,
          opacity: 0
        }
      })
    }]
  };
  const polyAndDecKeyCoordinates = [{
    path: '_parts',
    x1: 'x',
    y1: 'y'
  }, {
    path: '_paths',
    x1: 'lat',
    y1: 'lng'
  }, {
    path: '_parts',
    x1: 'x',
    y1: 'y'
  }, {
    path: '_paths',
    x1: 'lat',
    y1: 'lng'
  }];

  var _default = _component.default.extend(Ember.Evented, {
    booking: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    notifications: Ember.inject.service(),
    map: Ember.inject.service(),
    layerGroup: null,

    init() {
      this._super(...arguments);

      this.setProperties({
        layerGroup: new window.L.LayerGroup()
      });
    },

    didInsertElement() {
      const map = this.get('map');
      const center = map.get('center');

      this._super(...arguments);

      this.get('map.native').setView({
        lat: center.lat,
        lng: center.lon
      }, 12);
      this.activeGeocode();
      this.onChangePickDropAddress();
      const editmodelwidth = Ember.$('.edit-widget-modal').width();
      Ember.$('.edit-widget-modal').width('25%');
      Ember.$('.booking-map-container').css({
        'width': 'calc(95% - ' + editmodelwidth + 'px)'
      }).css({
        'height': 'calc(100% - 20%)',
        'left': editmodelwidth + 'px)'
      });
      this.set('booking.bookingMap', this.get('map'));
    },

    activeGeocode: Ember.observer('geocode.activeGeo', function () {
      const map = this.get('map.native');
      const geocodeService = this.get('geocode');
      const activeGeocode = geocodeService.get('activeGeo');
      if (!map) return;

      if (activeGeocode) {
        map.on('click', function (event) {
          geocodeService.saveNewGeocode(event.latlng);
        });
      } else {
        map.off('click');
      }
    }),

    doDrawMarkersAndPolylines() {
      const booking = this.get('booking');
      const skipSetView = booking.get('skipSetView');
      this.drawMarkersAndPolylines(skipSetView);
      booking.set('skipSetView', false);
    },

    onChangePickDropAddress: Ember.observer('record.dropPlace.address.freeformAddress', 'record.pickPlace.address.freeformAddress', function () {
      Ember.run.debounce(this, this.doDrawMarkersAndPolylines, 300);
    }),
    onMarkerPinRemove: Ember.observer('booking.setMarkerPinOff', function () {
      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
    }),

    drawMarkersAndPolylines() {
      let skipSetView = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (!Ember.isPresent(this.get('map'))) {
        return;
      }

      this.get('layerGroup').clearLayers();
      const pick = this.get('record.pick');
      const drop = this.get('record.drop');
      this.drawMarker(pick, skipSetView);
      this.drawMarker(drop, skipSetView);
      this.drawPolyline(pick, drop);
    },

    drawMarker(latLngObj) {
      let skipSetView = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const map = this.get('map');
      const marker = window.L.marker([latLngObj.get('lat'), latLngObj.get('lng')], pintPointIcon);
      this.get('layerGroup').addLayer(marker).addTo(this.get('map.native'));

      if (!skipSetView) {
        this.get('map.native').setView(marker._latlng, map.getZoom());
      }
    },

    drawPolyline(pick, drop) {
      const tooltipOptions = {
        opacity: 0.7,
        direction: 'top',
        sticky: true
      }; // Drawing reversly to make arrow head correct

      const toLatLng = {
        lat: drop.get('lat'),
        lng: drop.get('lng')
      };
      const fromLatLng = {
        lat: pick.get('lat'),
        lng: pick.get('lng')
      };
      const distance = (0, _geolib.getDistance)(toLatLng, fromLatLng) * 3.281 / 5280; // convert to miles

      const polyline = window.L.polyline([[drop.get('lat'), drop.get('lng')], [pick.get('lat'), pick.get('lng')]], {
        color: 'black',
        endOffset: '300'
      });
      const thickInvisiblePolyline = window.L.polyline([[drop.get('lat'), drop.get('lng')], [pick.get('lat'), pick.get('lng')]], {
        opacity: 0,
        weight: 25,
        endOffset: '300'
      });
      const polylineDecorator = window.L.polylineDecorator(polyline, arrowHeadLineConfig);
      const thickPolylineDecorator = window.L.polylineDecorator(thickInvisiblePolyline, thickArrowHeadLineConfig); // create a thicker invisible line so hovering around the polyline will be friendlier. We don't want to remove popup
      // as we move the mouse a tiny bit.

      thickInvisiblePolyline.bindTooltip(`<span>Distance: <br>${distance.toFixed(3)} mi</span>`, tooltipOptions);
      this.get('layerGroup').addLayer(polyline).addTo(this.get('map.native'));
      this.get('layerGroup').addLayer(polylineDecorator).addTo(this.get('map.native'));
      this.get('layerGroup').addLayer(thickInvisiblePolyline).addTo(this.get('map.native'));
      this.get('layerGroup').addLayer(thickPolylineDecorator).addTo(this.get('map.native'));
      const polyAndDecorators = [polyline, polylineDecorator, thickInvisiblePolyline, thickPolylineDecorator]; //set arrow head - NYAAR-14549

      this.get('layerGroup').removeLayer(polyline).addTo(this.get('map.native'));
      this.get('layerGroup').removeLayer(polylineDecorator).addTo(this.get('map.native'));
      this.get('layerGroup').removeLayer(thickInvisiblePolyline).addTo(this.get('map.native'));
      this.get('layerGroup').removeLayer(thickPolylineDecorator).addTo(this.get('map.native'));
      this.shortenPolylineAndDecorator(polyAndDecorators);
      this.get('layerGroup').addLayer(polyline).addTo(this.get('map.native'));
      this.get('layerGroup').addLayer(polylineDecorator).addTo(this.get('map.native'));
      this.get('layerGroup').addLayer(thickInvisiblePolyline).addTo(this.get('map.native'));
      this.get('layerGroup').addLayer(thickPolylineDecorator).addTo(this.get('map.native'));
    },

    shortenPolylineAndDecorator(polyAndDecorators) {
      let factor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.2;
      const isParts = !polyAndDecorators[0]._parts || polyAndDecorators[0]._parts.length === 0;
      const isPaths = !polyAndDecorators[1]._paths || polyAndDecorators[1]._paths.length === 0;
      const isThickParts = !polyAndDecorators[2]._parts || polyAndDecorators[2]._parts.length === 0;
      const isThickPaths = !polyAndDecorators[3]._paths || polyAndDecorators[3]._paths.length === 0;

      if (isParts || isPaths || isThickParts || isThickPaths) {
        return;
      }

      polyAndDecKeyCoordinates.forEach((pdk, index) => {
        const firstCoordinate = polyAndDecorators[index][pdk.path][0][0];
        const secondCoordinate = polyAndDecorators[index][pdk.path][0][1];
        const x1 = firstCoordinate[pdk.x1];
        const y1 = firstCoordinate[pdk.y1];
        const x2 = secondCoordinate[pdk.x1];
        const y2 = secondCoordinate[pdk.y1];
        const newStartX = x1 + factor * (x2 - x1);
        const newStartY = y1 + factor * (y2 - y1);
        const endFactor = 1 - factor;
        const newEndX = x1 + endFactor * (x2 - x1);
        const newEndY = y1 + endFactor * (y2 - y1);
        polyAndDecorators[index][pdk.path][0][0][pdk.x1] = newStartX;
        polyAndDecorators[index][pdk.path][0][0][pdk.y1] = newStartY;
        polyAndDecorators[index][pdk.path][0][1][pdk.x1] = newEndX;
        polyAndDecorators[index][pdk.path][0][1][pdk.y1] = newEndY;
      });
    },

    destroy() {
      this._super(...arguments);

      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
    }

  });

  _exports.default = _default;
});