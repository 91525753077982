define("adept-iq/topic-transforms/avlm-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload
  /*, ENV*/
  ) {
    const transformedPayloads = [];
    const {
      vehicle,
      alert,
      driver,
      route,
      schedule
    } = payload.data.relationships;

    if (vehicle) {
      payload.data.relationships.avlmVehicle = vehicle;
      delete payload.data.relationships.vehicle;
    }

    if (driver) {
      payload.data.relationships.avlmDriver = driver;
      delete payload.data.relationships.driver;
    }

    if (alert) {
      payload.data.relationships.avlmAlert = alert;
      delete payload.data.relationships.alert;
    }

    if (route) {
      payload.data.relationships.avlmRoute = route;
      delete payload.data.relationships.route;
    }

    if (schedule) {
      payload.data.relationships.avlmSchedule = schedule;
      delete payload.data.relationships.schedule;
    }

    transformedPayloads.push(payload);
    return transformedPayloads;
  }
});