define("adept-iq/pods/components/iq-widgets/ember-react-playback/component", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/react-component", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/session"], function (_exports, _react, _reactComponent, _playback, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reactComponent.default.extend({
    classNames: ['dashboard-widget-new dashboard-widget playback-widget map-widget'],
    session: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    notifications: Ember.inject.service(),
    widget: null,
    width: Ember.computed.alias('widget.width'),
    height: Ember.computed.alias('widget.height'),
    state: Ember.computed.alias('widget.state'),
    providersList: Ember.computed.alias('workspaceContext._selectedProviders'),
    mapService: Ember.inject.service('map'),
    onProviderChange: Ember.observer('providersList', function () {
      this.renderPlayback();
    }),

    init() {
      this._super(...arguments);
    },

    renderPlayback() {
      this.reactRender(_react.default.createElement(_session.SessionContext.Provider, {
        value: {
          token: this.get('session.data.authenticated.token'),
          providers: this.get('providersList'),
          notifications: this.get('notifications'),
          widgetState: this.get('state'),
          setWidgetState: this.get('setWidgetState').bind(this),
          bemapMainCtx: this.get('mapService.bemapMainCtx'),
          bemapSite: this.get('mapService.site'),
          bemapCenter: this.get('mapService.center')
        }
      }, _react.default.createElement(_playback.default, null)));
    },

    setWidgetState(widgetState) {
      this.set('state', widgetState);
    },

    didInsertElement() {
      this.renderPlayback();
    },

    didRender() {
      this._super(...arguments);
    },

    willDestroyElement() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});