define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/index", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/map", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/pre-polyline", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/post-polyline", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/current-polyline", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/gpsPingLayer", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/movingMarker", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/stops-layer", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/pinCursor", "adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/regionWithRadius", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/session", "adept-iq/config/api-urls"], function (_exports, _react, _map2, _panel, _prePolyline, _postPolyline, _currentPolyline, _gpsPingLayer, _movingMarker, _stopsLayer, _pinCursor, _regionWithRadius, _session, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    iqMap
  } = _apiUrls.API;
  const {
    bemap
  } = window;
  const MAX_ZOOM = 17;

  const mapApiCall = (site, token) => {
    try {
      const apiUrl = `${iqMap.host}/site?filter=eq(name,'${site}')`;
      const controller = new AbortController();
      const signal = controller.signal;
      const promise = new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(apiUrl, {
            headers: {
              Authorization: `Bearer ${token}`
            },
            signal
          });
          const data = await response.json();
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });

      promise.cancel = () => controller.abort();

      return promise;
    } catch (err) {
      // Simulate null data.
      return Promise.resolve({
        data: []
      });
    }
  };

  const getMinCoord = boundingBox => {
    let minCoord = boundingBox[0];
    boundingBox.forEach(point => {
      if (point[0] <= minCoord[0] && point[1] <= minCoord[1]) {
        minCoord = point;
      }
    });
    return minCoord;
  };

  const getMaxCoord = boundingBox => {
    let maxCoord = boundingBox[0];
    boundingBox.forEach(point => {
      if (point[0] >= maxCoord[0] && point[1] >= maxCoord[1]) {
        maxCoord = point;
      }
    });
    return maxCoord;
  };

  const Map = () => {
    const {
      isPinCursor,
      selectedRvd
    } = (0, _panel.usePanel)();

    const mapElement = _react.default.useRef();

    const [mapLongitude, setMapLongitude] = _react.default.useState(40.730610);

    const [mapLatitude, setMapLatitude] = _react.default.useState(-73.935242);

    const [mapZoom, setMapZoom] = _react.default.useState(12);

    const [bbox, setBbox] = _react.default.useState();

    const [map, setMap] = _react.default.useState();

    const [movingMarkerLatLng, setMovingMarkerLatLng] = _react.default.useState({});

    const {
      bemapMainCtx,
      token,
      bemapSite
    } = _react.default.useContext(_session.SessionContext);

    const increaseZoom = () => {
      if (mapZoom < MAX_ZOOM) {
        setMapZoom(mapZoom + 1);
      }
    };

    const decreaseZoom = () => {
      if (mapZoom > 1) {
        setMapZoom(mapZoom - 1);
      }
    };

    const updateMap = () => {
      map.native.setCenter([parseFloat(mapLongitude), parseFloat(mapLatitude)]);
      map.native.setZoom(mapZoom);
    };

    _react.default.useEffect(() => {
      const _map = new window.bemap.LeafletMap(bemapMainCtx, 'playback-map').defaultLayers();

      const initMap = async () => {
        const result = await mapApiCall(encodeURIComponent(`${bemapSite}`), token);
        const dataAttributes = result.data[0].attributes; // TODO: see if we need to change to use box instead of zoom

        const boundingBox = dataAttributes.boundingBox.coordinates[0];
        const minCoord = getMinCoord(boundingBox);
        const maxCoord = getMaxCoord(boundingBox);
        const center = dataAttributes.center.coordinates[0][0];

        const _bbox = new bemap.BoundingBox(minCoord[0], minCoord[1], maxCoord[0], maxCoord[1]);

        setMapLongitude(center[0]);
        setMapLatitude(center[1]);
        setBbox(_bbox);
        setMap(_map);
      };

      initMap();
      return () => _map.remove();
    }, []);

    _react.default.useEffect(() => {
      if (map && bbox) {
        map.moveToBoundingBox(bbox);
      }
    }, [map, bbox]);

    return _react.default.createElement(_map2.MapContext.Provider, {
      value: {
        map,
        movingMarkerLatLng,
        setMovingMarkerLatLng,
        setMapLongitude,
        setMapLatitude,
        increaseZoom,
        decreaseZoom,
        updateMap
      }
    }, _react.default.createElement("div", {
      className: "flex-body-right"
    }, _react.default.createElement("div", {
      ref: mapElement,
      id: "playback-map",
      className: "tomtom-map playback"
    }), _react.default.createElement(_prePolyline.default, null), _react.default.createElement(_currentPolyline.default, null), _react.default.createElement(_postPolyline.default, null), _react.default.createElement(_gpsPingLayer.default, null), _react.default.createElement(_stopsLayer.default, null), _react.default.createElement(_movingMarker.default, null), !selectedRvd.vehicleId && _react.default.createElement(_regionWithRadius.default, null), isPinCursor && _react.default.createElement(_pinCursor.default, null)));
  };

  var _default = Map;
  _exports.default = _default;
});