define("adept-iq/classes/road-supervisor-api", ["exports", "fetch", "moment", "pako", "ember-concurrency", "adept-iq/config/environment", "adept-iq/config/api-urls", "adept-iq/utils/geolib"], function (_exports, _fetch, _moment, _pako, _emberConcurrency, _environment, _apiUrls, _geolib) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_LATLNG = [_environment.default.tomtom.search.center.lat, _environment.default.tomtom.search.center.lon];
  const LOCATION_PING_INTERVAL = 20000;
  const ETA_GET_INTERVAL = 120 * 1000; //every 2 min will check ETA time

  const PING_WORKER_PATH = '/workers/avl-ping.js';

  function unpackBuffer(arr) {
    const inflated = _pako.default.inflate(arr);

    const guidanceJSON = String.fromCharCode.apply(null, inflated);

    try {
      return JSON.parse(guidanceJSON);
    } catch (e) {
      // bad json
      return null;
    }
  }

  const RoadSupervisorApi = Ember.Object.extend({
    previousLocationObj: null,
    currentLocationObj: null,
    tmpLocationObj: null,
    cord: DEFAULT_LATLNG,
    geoLocation: null,
    currentRoute: null,
    polyline: null,
    guidance: null,
    isNavigationScreen: false,
    lastSyncTime: null,

    initialize(_ref) {
      let {
        driver,
        vehicle,
        location
      } = _ref;
      this.driver = driver;
      this.vehicle = vehicle;
      this.cord = location || DEFAULT_LATLNG;
      const dashboardInfo = localStorage.getItem('dashboard-info');
      const parsedDashboardInfo = JSON.parse(dashboardInfo);

      if (parsedDashboardInfo && parsedDashboardInfo.accessData) {
        const accessDataObj = parsedDashboardInfo.accessData;
        this.driver.badgeNr = accessDataObj.driverId;
        this.driver.id = accessDataObj.driverId;
        this.vehicle.callsign = accessDataObj.vehicleId;
        this.vehicle.id = accessDataObj.vehicleId;
        this.provider = accessDataObj.provider;
      }

      if (localStorage.getItem('currentRouteRS') && localStorage.getItem('currentRouteRSID')) {
        const currentRoute = JSON.parse(localStorage.getItem('currentRouteRS'));
        this.set('currentRoute', { ...currentRoute,
          id: localStorage.getItem('currentRouteRSID')
        });
        this.prepare();
      } else {
        this.prepare().then(() => this.apiCreateRoute()).then(() => this.get('execRouteEventTask').perform()).then(() => this.apiExecuteDriverEvent({
          type: 'signOn'
        }));
      }
    },

    setWayPoint(waypoint) {
      this.set('isNavigationScreen', true);
      this.apiAddStopToRoute(waypoint).then(() => this.pingAndGetPolyLine.perform().catch(e => {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      })).then(() => this.startEtaGet());
    },

    pingAndGetPolyLine: (0, _emberConcurrency.task)(function* () {
      const sleepTime = 3000;
      let data;
      let failedCount = 0; // ping at least once before getting polyline

      yield this.apiExecutePingEvent();
      yield (0, _emberConcurrency.timeout)(1000); // ensure original get succeeds; this can take a few tries

      do {
        data = yield this.apiGetUpdatedPolyline();

        if (!data) {
          failedCount++;
          yield (0, _emberConcurrency.timeout)(sleepTime);
        }

        if (!this.get('isNavigationScreen')) {
          break;
        }
      } while (!data && failedCount < 3);

      if (data) return data; // original is backwards, so this needs to succeed too

      do {
        data = yield this.apiETAGetPolyline();
        if (!data) yield (0, _emberConcurrency.timeout)(sleepTime);
      } while (!data);

      return data;
    }).restartable(),
    pingAndUpdatePolyLine: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'guidance', null);
      const sleepTime = 3000;
      let data; // ping at least once before getting polyline

      yield this.apiExecutePingEvent();

      do {
        data = yield this.apiGetUpdatedPolyline();
        if (!data) yield (0, _emberConcurrency.timeout)(sleepTime);
      } while (!data);

      return data;
    }).restartable(),
    execRouteEventTask: (0, _emberConcurrency.task)(function* (args) {
      const sleepTime = 3000;
      let result;

      do {
        result = yield this.apiExecuteRouteEvent(args);
        if (!result) yield (0, _emberConcurrency.timeout)(sleepTime);
      } while (!result);

      return result;
    }).enqueue(),

    clearWayPoint(isDeparted) {
      if (!isDeparted) {
        // Not clear polyline after arrived.
        Ember.set(this, 'polyline', null);
      }

      Ember.set(this, 'guidance', null);
      this.set('isNavigationScreen', false);
      clearTimeout(this._polyLineTimer);
      this.endEtaGet();

      if (isDeparted === false) {
        return this.get('execRouteEventTask').perform({
          type: 'pullIn'
        });
      }

      this.get('execRouteEventTask').perform({
        type: 'depart'
      }).then(async () => {
        await this.apiGetUpdatedPolyline(true);
      });

      if (this.get('pingAndGetPolyLine.isRunning')) {
        this.get('pingAndGetPolyLine').cancelAll();
      }

      if (this.get('pingAndUpdatePolyLine.isRunning')) {
        this.get('pingAndUpdatePolyLine').cancelAll();
      }

      return;
    },

    setLocation(loc) {
      this.geoLocation = loc;
      const location = [loc.coords.latitude, loc.coords.longitude];
      this.cord = location;
    },

    setLocationHistory(lat, lng, speed, heading, timestamp) {
      const temp = this.get('tmpLocationObj');

      if (Ember.isEmpty(temp)) {
        this.set('tmpLocationObj', {
          lat,
          lng,
          speed,
          heading,
          timestamp
        });
      } else if (timestamp - temp.timestamp > 4000 && speed > 5) {
        this.set('previousLocationObj', {
          'previousLat': temp.lat,
          'previousLng': temp.lng,
          'previousSpeed': temp.speed,
          'previousHeading': temp.heading,
          'previousTimeStamp': temp.timestamp
        });
        this.set('tmpLocationObj', {
          lat,
          lng,
          speed,
          heading,
          timestamp
        });
      }

      this.set('currentLocationObj', {
        'currentLat': lat,
        'currentLng': lng,
        'currentSpeed': speed,
        'currentHeading': heading,
        'currentTimeStamp': timestamp
      });
    },

    async prepare() {
      const token = await this.apiGetAccessToken();
      return token;
    },

    startLocationPing() {
      if (this._interval) return;
      this._interval = setInterval(() => {
        this.sendWorkerPing();
      }, LOCATION_PING_INTERVAL);
    },

    sendWorkerPing() {
      let pingWorker = this.get('pingWorker');

      if (!pingWorker) {
        pingWorker = new Worker(PING_WORKER_PATH);
        this.set('pingWorker', pingWorker);
      }

      pingWorker.postMessage({
        timestamp: (0, _moment.default)().toISOString(),
        vehicleId: this.vehicle.id,
        lat: this.cord[0],
        lng: this.cord[1],
        token: this.token,
        url: `${_apiUrls.API.avlService.host}/avl`
      });
    },

    endLocationPing() {
      if (!this._interval) return;
      clearTimeout(this._interval);
      this._interval = null;
    },

    startEtaGet() {
      // Get sync immediatly once.
      this.apiUpdatedETAGet().then(() => {
        const temp = this.get('tmpLocationObj');
        console.log('Sync ETA...'); //eslint-disable-line no-console

        this.set('lastSyncTime', temp.timestamp);
      });
      if (this._etaGetInterval) return;
      this._etaGetInterval = setInterval(async () => {
        const temp = this.get('tmpLocationObj'); // tempLocation only updateds when moving.

        if (temp && temp.timestamp > this.get('lastSyncTime')) {
          await this.apiUpdatedETAGet();
          this.set('lastSyncTime', temp.timeStamp);
        }
      }, ETA_GET_INTERVAL);
    },

    endEtaGet() {
      if (!this._etaGetInterval) return;
      clearTimeout(this._etaGetInterval);
      this._etaGetInterval = null;
    },

    async apiExecutePingEvent() {
      const time = (0, _moment.default)().toISOString();
      const body = {
        data: {
          type: 'avl',
          attributes: {
            vehicleId: this.vehicle.id,
            timestamp: time,
            lat: this.cord[0],
            lng: this.cord[1]
          }
        }
      };
      const res = await this.makeRequest(`${_apiUrls.API.avlService.host}/avl`, {
        method: 'POST',
        body
      });
      return res;
    },

    async apiGetAccessToken() {
      const headers = {
        Authorization: 'Basic aXEtZHJpdmVyQGRkc3dpcmVsZXNzLmNvbTp3dWJUNnhmc1dwWGJRZS9a'
      };
      const res = await (0, _fetch.default)(`${_apiUrls.API.ssoService.host}/login`, {
        headers
      });
      const {
        token
      } = await res.json();
      this.token = token;
      return token;
    },

    async apiAddStopToRoute(waypoint) {
      const time = (0, _moment.default)().toISOString();
      const {
        driverScheduleId,
        vehicleScheduleId
      } = this.currentRoute;
      const body = {
        data: {
          type: 'route',
          id: this.currentRoute.id,
          attributes: {
            state: 'active',
            version: this.currentRoute.version,
            driver: {
              id: this.driver.id,
              badgeNr: this.driver.badgeNr || this.currentRoute.driver.badgeNr
            },
            vehicle: {
              id: this.vehicle.id,
              callsign: this.vehicle.callsign || this.currentRoute.vehicle.callsign
            },
            driverId: this.driver.id,
            trackingId: this.currentRoute.trackingId,
            vehicleId: this.vehicle.id,
            deploy: [{
              address: {
                streetNr: waypoint.streetNumber || '',
                streetName: waypoint.streetAddress || '',
                city: waypoint.locality || '',
                state: waypoint.region || '',
                postCode: waypoint.postalCode || '',
                alias: waypoint.alias || '',
                formattedAddress: waypoint.fullAddress,
                coord: {
                  lat: waypoint.lat,
                  lng: waypoint.lng
                }
              },
              dwellTime: 240,
              eta: time
            }],
            requestedProvider: {
              // is this right?
              type: 'dedicated',
              id: '1',
              name: '1'
            },
            driverScheduleId,
            vehicleScheduleId
          },
          links: {
            self: 'https://11gpajx0kl.execute-api.us-west-2.amazonaws.com/production/route'
          }
        }
      };
      const pendingStops = await this.apiGetPendingStops();
      pendingStops.forEach(async stop => {
        await this.get('execRouteEventTask').perform({
          stop,
          type: 'depart'
        });
      });
      const res = await this.makeRequest(`${_apiUrls.API.avlmService.host}/route/${this.currentRoute.id}`, {
        method: 'PATCH',
        body
      });
      const {
        data,
        error
      } = res;

      if (error) {
        await this.apiCreateRoute();
        await this.get('execRouteEventTask').perform();
        await this.apiExecuteDriverEvent({
          type: 'signOn'
        });
        await this.apiAddStopToRoute(waypoint);
      } else if (data) {
        const latestAddedStopObj = data.attributes.deploy.sortBy('index').lastObject; // FIXME: is this necessary?

        this.set('currentRoute', { ...this.currentRoute,
          deployId: latestAddedStopObj.deployId,
          eta: latestAddedStopObj.eta
        });
      }

      return true;
    },

    async apiCreateRoute() {
      const body = {
        timeStamp: (0, _moment.default)().toISOString(),
        badgeNr: this.driver.badgeNr || 3000,
        callsign: this.vehicle.callsign || 2001,
        currLat: this.cord[0],
        currLng: this.cord[1],
        include: 'guidance',
        provider: this.provider || 'special'
      };
      const res = await this.makeRequest(`${_apiUrls.API.avlmService.host}/route`, {
        body
      });
      if (!res || !res.data) return null;
      const {
        data
      } = res;
      localStorage.setItem('currentRouteRS', JSON.stringify(data[0].attributes));
      localStorage.setItem('currentRouteRSID', data[0].id);
      this.set('currentRoute', { ...data[0].attributes,
        id: data[0].id
      });
      return data[0].id;
    },

    async apiGetPendingStops() {
      const body = {};
      const res = await this.makeRequest(`${_apiUrls.API.etaService.host}/route/${this.currentRoute.id}`, {
        body
      });

      if (res.data && res.data.attributes && res.data.attributes.stopPoints) {
        return res.data.attributes.stopPoints.filter(stopPoint => {
          return stopPoint.id.startsWith('DP') && stopPoint.status === 'pending';
        });
      }

      return [];
    },

    async apiExecuteRouteEvent() {
      let values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        type: 'pullOut'
      };
      const time = (0, _moment.default)().toISOString();
      if (!this.currentRoute) return true;
      const {
        driver,
        vehicle
      } = this.currentRoute;
      const body = {
        data: {
          type: 'routeExecEvent',
          attributes: {
            routeId: this.currentRoute.id,
            content: {
              type: values.type,
              actualTime: time
            },
            driver: {
              id: driver.id,
              badgeNr: driver.badgeNr
            },
            vehicle: {
              id: vehicle.id,
              callsign: vehicle.callsign,
              location: {
                coord: {
                  lat: this.cord[0],
                  lng: this.cord[1]
                },
                time
              },
              mov: {
                speed: 50,
                heading: 0
              },
              odo: 0
            },
            source: {
              type: 'driverApp'
            }
          }
        }
      };

      if (values.type === 'depart') {
        if (values.stop) {
          // preform stops for prevoius pending stop
          body.data.attributes.content.stopId = values.stop.id;
          body.data.attributes.content.eta = values.stop.eta;
        } else {
          body.data.attributes.content.stopId = this.currentRoute.deployId;
          body.data.attributes.content.eta = this.currentRoute.eta;
        }
      }

      const res = await this.makeRequest(`${_apiUrls.API.avlmService.host}/route-exec-event`, {
        method: 'POST',
        body
      });
      if (Ember.isNone(res)) return false;
      return true;
    },

    async apiExecuteDriverEvent() {
      let values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        type: 'signOn'
      };
      const time = (0, _moment.default)().toISOString();
      const {
        driverId,
        id,
        driverScheduleId,
        vehicleScheduleId,
        driver,
        vehicle
      } = this.currentRoute;
      const {
        badgeNr
      } = driver;
      const body = {
        data: {
          type: 'driverStateEvent',
          attributes: {
            driverId,
            routeId: id,
            driverScheduleId,
            vehicleScheduleId,
            type: values.type,
            badgeNr,
            time,
            vehicle: {
              id: vehicle.id,
              callsign: vehicle.callsign,
              location: {
                coord: {
                  lat: this.cord[0],
                  lng: this.cord[1]
                },
                time
              },
              mov: {
                speed: 50,
                heading: 0
              },
              odo: 0,
              provider: {
                type: 'dedicated',
                id: this.provider || 'special',
                name: this.provider || 'special'
              }
            }
          }
        }
      };
      const res = await this.makeRequest(`${_apiUrls.API.avlmService.host}/driver-state-event`, {
        method: 'POST',
        body
      });
      return res;
    },

    async apiETAGetPolyline() {
      const routeId = this.currentRoute.id;
      const body = {
        include: 'polyline,guidance'
      };
      const res = await this.makeRequest(`${_apiUrls.API.etaService.host}/route/${routeId}`, {
        body
      });
      if (!res || !res.data || !res.data.attributes || !res.data.attributes.stopPoints || res.data.attributes.stopPoints.length === 0) return null;
      const currentRoute = res.data.attributes.stopPoints.find(stop => stop.status === 'pending');
      const {
        polyline,
        guidance
      } = currentRoute;
      const result = {
        polyline,
        guidance
      };
      this.set('polyline', polyline);

      if (guidance) {
        const unpackedGuidance = unpackBuffer(guidance.data);
        this.set('guidance', unpackedGuidance);
        result.guidance = unpackedGuidance;
      } //Added code to update latest perform stop ETA time.


      const {
        stopPoints
      } = res.data.attributes;

      if (stopPoints && stopPoints.length > 2) {
        this.set('currentRoute', { ...this.currentRoute,
          eta: stopPoints[stopPoints.length - 2].eta
        });
      }

      return result;
    },

    async apiUpdatedETAGet() {
      const routeId = this.currentRoute.id;
      const body = {
        include: 'polyline,guidance'
      };
      const res = await this.makeRequest(`${_apiUrls.API.etaService.host}/navigation-info/${routeId}`, {
        body
      });
      if (!res || !res.data) return null;
      const {
        stopPoints
      } = res.data.attributes;

      if (stopPoints && stopPoints.length > 1) {
        this.set('currentRoute', { ...this.currentRoute,
          eta: stopPoints[stopPoints.length - 2].eta
        });
      }

      return true;
    },

    async apiGetUpdatedPolyline(isExit) {
      const routeId = this.currentRoute.id;
      let body = {
        include: 'polyline,guidance'
      };
      const previousObject = this.get('previousLocationObj');
      const currentObject = this.get('currentLocationObj');
      let heading;

      if (previousObject && currentObject) {
        heading = (0, _geolib.getHeading)({
          lat: previousObject.previousLat,
          lng: previousObject.previousLng
        }, {
          lat: currentObject.currentLat,
          lng: currentObject.currentLng
        });
      }

      if (currentObject) {
        // Added this condition to pass the latest vehicle location to ETA server.
        // Without sending previous object to avoid backward polyline issue.
        body = {
          'currHeading': heading || currentObject.currentHeading,
          'currLat': currentObject.currentLat,
          'currLng': currentObject.currentLng,
          'currSpeed': Math.max(currentObject.currentSpeed, 5),
          //single location need speed > 5
          'currTimestamp': _moment.default.utc().format(),
          'include': 'polyline,guidance'
        };
      }

      const res = await this.makeRequest(`${_apiUrls.API.etaService.host}/navigation-info/${routeId}/updatedNavInfo`, {
        body
      });
      if (!res || !res.data || !res.data.attributes || !res.data.attributes.current) return null;
      const {
        polyline,
        guidance
      } = res.data.attributes.current;
      const result = {
        polyline
      };
      if (!isExit) this.set('polyline', polyline);

      if (guidance) {
        const unpackedGuidance = unpackBuffer(guidance.data);
        if (!isExit) this.set('guidance', unpackedGuidance);
        result.guidance = unpackedGuidance;
      } //Added code to update latest perform stop ETA time.


      const {
        stopPoints
      } = res.data.attributes;

      if (stopPoints && stopPoints.length > 2) {
        this.set('currentRoute', { ...this.currentRoute,
          eta: stopPoints[stopPoints.length - 2].eta
        });
      }

      return result;
    },

    async makeRequest(url, props) {
      let params = '';

      if (props.body) {
        if ('method' in props && props.method !== 'GET') {
          props.body = JSON.stringify(props.body);
        } else {
          params = Ember.$.param(props.body);
          delete props.body;
        }
      }

      try {
        const res = await (0, _fetch.default)(`${url}?${params}`, Object.assign({
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
          }
        }, props));

        if (res.status === 200) {
          return res.text().then(data => data ? JSON.parse(data) : {});
        }

        if (res.status === 404) {
          return res.text().then(error => error ? JSON.parse(error) : {});
        } // any other status just return {}


        return {};
      } catch (err) {
        return null;
      }
    }

  });
  let instance;
  RoadSupervisorApi.reopenClass({
    getRoleName() {
      return 'roadsup';
    },

    getConfigId() {
      return 'config-UI_restrictions-roadsup/restrictions';
    },

    getSingleton() {
      if (!instance) {
        instance = RoadSupervisorApi.create();
      }

      return instance;
    }

  });
  var _default = RoadSupervisorApi;
  _exports.default = _default;
});