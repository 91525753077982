define("adept-iq/serializers/polyline", ["exports", "adept-iq/serializers/-dispatch", "adept-iq/config/environment"], function (_exports, _dispatch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      return this._super(...arguments);
    },

    normalize(typeClass, hash) {
      if (_environment.default.APP.avlmLite && hash.relationships) {
        const {
          avlmRoute,
          avlmStopPoint
        } = hash.relationships; // we are keeping the route and stopPoint as id so
        // only update 1 eta-route record per combination.
        // if stopPoint does not exist then use route
        // if route does not exist too then keep original

        if (avlmRoute && avlmStopPoint) {
          hash.id = `${avlmRoute.data.id}-${avlmStopPoint.data.id}`;
        } else if (avlmRoute) {
          hash.id = avlmRoute.data.id;
        } else {
          // eslint-disable-next-line no-console
          console.warn('Missing required avlm route relationship.');
        }

        return this._super(...arguments);
      }

      if (hash.relationships) {
        const {
          route,
          stopPoint
        } = hash.relationships; // we are keeping the route and stopPoint as id so
        // only update 1 eta-route record per combination.
        // if stopPoint does not exist then use route
        // if route does not exist too then keep original

        if (route && stopPoint) {
          hash.id = `${route.data.id}-${stopPoint.data.id}`; // route has only one relationship to navigation polyline.

          delete hash.relationships.route;
        } else if (route) {
          hash.id = route.data.id;
        } else {
          // eslint-disable-next-line no-console
          console.warn('Missing required dispatch route relationship.');
        }
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});