define("adept-iq/models/lock", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    entityType: attr('string'),
    entityId: attr('string'),
    timeToLive: attr('number'),
    expirationTime: attr('date'),
    status: attr('string', {
      defaultValue: 'unlocked'
    }),
    persist: attr('boolean', {
      defaultValue: false
    }),
    owner: belongsTo('sso-user'),
    dispatchRoute: belongsTo('dispatch-route'),
    route: belongsTo('route'),
    isLocked: Ember.computed('status', 'expirationTime', function () {
      const hasExpiration = this.get('timeToLive') > 0;
      const status = this.get('status');
      const currentTime = (0, _moment.default)();
      const expirationTimeMoment = (0, _moment.default)(this.get('expirationTime'));
      return status === 'locked' && (!hasExpiration || expirationTimeMoment.isAfter(currentTime));
    })
  });

  _exports.default = _default;
});