define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-check-box", ["exports", "react", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/base-cell-item"], function (_exports, _react, _baseCellItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CellCheckBox extends _baseCellItem.default {
    constructor(props) {
      super(props);
    }

    render() {
      const {
        rowIndex,
        isChecked,
        isDisabled,
        checkBtnClickHandler
      } = this.props;
      return _react.default.createElement("input", {
        type: "checkbox",
        checked: isChecked,
        disabled: isDisabled,
        onChange: event => {
          checkBtnClickHandler(rowIndex, event.target.checked);
        }
      });
    }

  }

  _exports.default = CellCheckBox;
});