define("adept-iq/topic-transforms/rider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(payload
  /*, ENV*/
  ) {
    if (!payload.data || !payload.data.relationships) return payload;
    payload.data.type = 'rider';
    const {
      eligibilities,
      travelNeeds,
      phoneNumbers,
      places
    } = payload.data.relationships;

    if (eligibilities) {
      delete payload.data.relationships.eligibilities;
      payload.data.relationships.riderEligibilities = eligibilities;
    }

    if (travelNeeds) {
      delete payload.data.relationships.travelNeeds;
      payload.data.relationships.riderTravelNeeds = travelNeeds;
    }

    if (phoneNumbers) {
      delete payload.data.relationships.phoneNumbers;
      payload.data.relationships.riderPhoneNumbers = phoneNumbers;
    }

    if (places) {
      delete payload.data.relationships.places;
      payload.data.relationships.riderPlaces = places;
    }

    return payload;
  }
});