define("adept-iq/classes/data-joins/map/marker", ["exports", "adept-iq/classes/data-joins/map/base", "lodash", "adept-iq/pods/components/iq-widgets/map-widget/config/marker", "adept-iq/pods/components/iq-widgets/map-widget/config/marker-highlight", "adept-iq/pods/components/iq-widgets/map-widget/config/marker-leaflet"], function (_exports, _base, _lodash, _marker, _markerHighlight, _markerLeaflet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bemap
  } = window;
  const MARKER_UPDATE_THROTTLE = 10000; // do not update marker more than once per N ms

  const ON_BREAK_TYPE = 'onBreak';
  const OFF_BREAK_TYPE = 'offBreak';

  var _default = _base.default.extend({
    onContextMenu: null,
    onDblClick: null,
    options: null,
    queueName: 'map-marker',

    refresh(node) {
      const isMaximized = this.get('options.isMaximized');
      const isJoined = node.get('isJoined');
      const isHidden = node.get('isHidden');
      const marker = isMaximized ? node.get('markerMaximized') : node.get('marker'); // float break do not have lat/lng so need to get from estimatedBreaklat/Lng

      const lat = node.get('lat') || node.get('stopPoint.lat');
      const lng = node.get('lng') || node.get('stopPoint.lng');

      if (!isJoined || isHidden || Ember.isNone(lat) || Ember.isNone(lng)) {
        if (marker) {
          if (node.isBreak) {
            return this.processRemoveBreakMarker(node, isMaximized);
          }

          return this.removeMarker(node, isMaximized);
        }

        return false;
      }

      if (node.get('layer.isHighLighted')) node.set('isHighLighted', node.get('layer.isHighLighted'));

      if (!marker) {
        if (node.isBreak) {
          // process breaks separately because this single node and stopPoint record
          // can possible have 2 separate markers. break on and break off
          return this.processCreateBreakMarker(node, isMaximized);
        }

        return this.createMarker(node, isMaximized);
      }

      if (node.isBreak) {
        // process breaks separately because this single node and stopPoint record
        // can possible have 2 separate markers. break on and break off
        return this.processUpdateBreakMarker(node, isMaximized);
      }

      return this.updateMarker(node, isMaximized);
    },

    processRemoveBreakMarker(node, isMaximized) {
      this.removeBreakMarker(node, ON_BREAK_TYPE, isMaximized);
      this.removeBreakMarker(node, OFF_BREAK_TYPE, isMaximized);
    },

    processCreateBreakMarker(node, isMaximized) {
      const breakOnLat = node.get('lat');
      const breakOnLng = node.get('lng'); // create the break on marker

      this.createBreakMarker(node, ON_BREAK_TYPE, breakOnLat, breakOnLng, isMaximized);
      const breakOffLat = node.get('record.actualDepartLat');
      const breakOffLng = node.get('record.actualDepartLng'); // create the break off marker

      this.createBreakMarker(node, OFF_BREAK_TYPE, breakOffLat, breakOffLng, isMaximized);
    },

    processUpdateBreakMarker(node, isMaximized) {
      const breakOnLat = node.get('lat');
      const breakOnLng = node.get('lng'); // update the break on marker

      this.updateBreakMarker(node, ON_BREAK_TYPE, breakOnLat, breakOnLng, isMaximized);
      const breakOffMarker = node.get('breakOffMarker');
      const breakOffLat = node.get('record.actualDepartLat');
      const breakOffLng = node.get('record.actualDepartLng'); // create/update/delete the break off marker

      if (Ember.isNone(breakOffMarker) && !Ember.isNone(breakOffLat) && !Ember.isNone(breakOffLng)) {
        this.createBreakMarker(node, OFF_BREAK_TYPE, breakOffLat, breakOffLng, isMaximized);
      } else if (!Ember.isNone(breakOffMarker) && (Ember.isNone(breakOffLat) || Ember.isNone(breakOffLng))) {
        this.removeBreakMarker(node, OFF_BREAK_TYPE, isMaximized);
      } else {
        this.updateBreakMarker(node, OFF_BREAK_TYPE, breakOffLat, breakOffLng, isMaximized);
      }
    },

    createBreakMarker(node, breakType, lat, lng, isMaximized) {
      const vehicleCluster = this.get('vehicleCluster');
      if (Ember.isNone(lat) || Ember.isNone(lng)) return;

      const options = this._computeMarkerOptions(node);

      const highlightOptions = this._computeMarkerHighLightOptions(node);

      const icon = new bemap.Icon(options.icon);
      const coord = new bemap.Coordinate(lng, lat);
      const map = this.get('map');
      const marker = new bemap.Marker(coord, {
        icon
      });

      if (breakType === ON_BREAK_TYPE) {
        if (isMaximized) {
          node.set('markerMaximized', marker);
        } else {
          node.set('marker', marker);
        }
      } else {
        node.set('breakOffMarker', marker);
      }

      if (vehicleCluster && node.get('isGrouped')) {
        map.addMarker(marker, {
          layer: vehicleCluster
        });
        node.set('inCluster', true);
      } else {
        map.addMarker(marker);
        node.set('inCluster', false);
      }

      if (node.get('isHighLighted') && marker.native._icon) {
        marker.native.setIcon(highlightOptions.icon);
      } else if (marker.native._icon) {
        marker.setIcon(icon);
      } // append iq info for active context callback


      const record = node.get('record');
      marker.native.iq = {
        isActive: node.get('isActive'),
        modelName: record ? record.constructor.modelName : null,
        modelId: record ? record.get('id') : null
      };
      this.initZIndexAndRotationAngleToMarker(node, marker); // bind popup & tooltip

      const label = node.get('label');

      if (label) {
        this.addPopUpAndTooltipToMarker(node, marker, label);
      }

      this.bindClickHandlersToMarker(node, marker);
      const zIndexOffset = node.get('zIndexOffset');
      const rotationAngle = node.get('rotationAngle');

      if (breakType === ON_BREAK_TYPE) {
        node.cache = {
          lat,
          lng,
          label,
          rotationAngle,
          zIndexOffset
        };
      } else {
        node.breakOffCache = {
          lat,
          lng,
          label,
          rotationAngle,
          zIndexOffset
        };
      }

      return true;
    },

    createMarker(node, isMaximized) {
      const vehicleCluster = this.get('vehicleCluster');
      const lat = node.get('lat');
      const lng = node.get('lng');

      const options = this._computeMarkerOptions(node);

      const highlightOptions = this._computeMarkerHighLightOptions(node);

      const icon = new bemap.Icon(options.icon);
      const coord = new bemap.Coordinate(lng, lat);
      const map = this.get('map');
      const marker = new bemap.Marker(coord, {
        icon
      });

      if (isMaximized) {
        node.set('markerMaximized', marker);
      } else {
        node.set('marker', marker);
      }

      if (vehicleCluster && node.get('isGrouped')) {
        map.addMarker(marker, {
          layer: vehicleCluster
        });
        node.set('inCluster', true);
      } else {
        map.addMarker(marker);
        node.set('inCluster', false);
      }

      if (node.get('isHighLighted') && marker.native._icon) {
        marker.native.setIcon(highlightOptions.icon);
      } else if (marker.native._icon) {
        marker.setIcon(icon);
      } // append iq info for active context callback


      const record = node.get('record');
      marker.native.iq = {
        isActive: node.get('isActive'),
        modelName: record ? record.constructor.modelName : null,
        modelId: record ? record.get('id') : null
      };
      this.initZIndexAndRotationAngleToMarker(node, marker); // bind popup & tooltip

      const label = node.get('label');

      if (label) {
        this.addPopUpAndTooltipToMarker(node, marker, label);
      }

      this.bindClickHandlersToMarker(node, marker);
      const zIndexOffset = node.get('zIndexOffset');
      const rotationAngle = node.get('rotationAngle');
      node.cache = {
        lat,
        lng,
        label,
        rotationAngle,
        zIndexOffset
      };
      return true;
    },

    updateBreakMarker(node, breakType, lat, lng, isMaximized) {
      const vehicleCluster = this.get('vehicleCluster');
      const cache = breakType === ON_BREAK_TYPE ? node.cache : node.breakOffCache;
      const needUpdateNow = !node.get('isGrouped') || node.get('isGrouped') && !node.get('inCluster'); // Update not Groupded vehicle everytime

      const map = this.get('map');
      let marker;
      let isDirty = false; // Check marker last update time

      const lastUpdatedAt = node.get('lastUpdatedAt');
      const t = new Date().getTime();
      if (Ember.isNone(lat) || Ember.isNone(lng)) return;

      if (!needUpdateNow && lastUpdatedAt && t - lastUpdatedAt < MARKER_UPDATE_THROTTLE) {
        // skip marker update
        return;
      }

      if (breakType === ON_BREAK_TYPE) {
        marker = isMaximized ? node.get('markerMaximized') : node.get('marker');
      } else {
        marker = node.get('breakOffMarker');
      } // update position


      const options = this._computeMarkerOptions(node);

      const highlightOptions = this._computeMarkerHighLightOptions(node);

      const coord = new bemap.Coordinate(lng, lat);

      if (lat !== cache.lat || lng !== cache.lng) {
        marker.setCoordinate(coord);
        cache.lat = lat;
        cache.lng = lng;
        isDirty = true;
      } // update marker icon


      if (Ember.isNone(options) || Ember.isNone(options.icon)) return false;

      if (!node.get('isGrouped') && node.get('inCluster')) {
        vehicleCluster.native.removeLayer(marker.native);
        map.addMarker(marker);
        node.set('inCluster', false);
        isDirty = true;
      } else if (node.get('isGrouped') && !node.get('inCluster')) {
        vehicleCluster.native.addLayer(marker.native);
        map.removeLayer(marker);
        node.set('inCluster', true);
        isDirty = true;
      } // update marker icon


      const iconDigest = JSON.stringify(options);

      if (iconDigest !== cache.iconDigest) {
        const icon = new bemap.Icon(options.icon);

        if (node.get('isHighLighted')) {
          marker.native.setIcon(highlightOptions.icon);
        } else {
          marker.native.setIcon(options.leaflet.icon);
          marker.setIcon(icon);
        }

        cache.iconDigest = iconDigest;
        isDirty = true;
      }

      if (node.get('isHighLighted') && marker.native._icon) {
        window.L.DomUtil.addClass(marker.native._icon, 'high-lighted');
      } else if (marker.native._icon) {
        window.L.DomUtil.removeClass(marker.native._icon, 'high-lighted');
      } // update front-to-back z-index, e.g. render vehicles on top of stops


      const zIndexOffset = node.get('zIndexOffset');

      if (!Ember.isNone(zIndexOffset) && zIndexOffset !== cache.zIndexOffset) {
        marker.native.setZIndexOffset(zIndexOffset);
        cache.zIndex = zIndexOffset;
        isDirty = true;
      } // update icon rotation, e.g. vehicle heading


      const rotationAngle = node.get('rotationAngle');

      if (!Ember.isNone(rotationAngle) && rotationAngle !== cache.rotationAngle) {
        marker.native.setRotationAngle(rotationAngle / 2);
        cache.rotationAngle = rotationAngle;
        isDirty = true;
      } // once isDirty property is true for any different icon change, rotation angle, lat-lng, or zindex
      // update the vehicle cluster


      if (isDirty) {
        vehicleCluster.native.refreshClusters(marker.native); // not working natively, either it refreshes when child marker updates possibly?
      } // update opacity; emergency/highlighted vehicles are always full opacity


      const isEmergencyVehicle = node.get('record.isGrouped');
      const opacity = isEmergencyVehicle ? 1.0 : node.get('opacity');

      if (opacity !== cache.opacity) {
        marker.native.setOpacity(opacity);
        cache.opacity = opacity;
        isDirty = true;
      }

      const setIsDirty = () => {
        isDirty = true;
      };

      this.setLabelAndPopUp(node, cache, marker, setIsDirty);
      node.set('lastUpdatedAt', t);
      return isDirty;
    },

    updateMarker(node, isMaximized) {
      const vehicleCluster = this.get('vehicleCluster');
      const marker = isMaximized ? node.get('markerMaximized') : node.get('marker');
      const cache = node.cache;
      const map = this.get('map');
      const needUpdateNow = !node.get('isGrouped') || node.get('isGrouped') && !node.get('inCluster'); // Update not Groupded vehicle everytime

      let isDirty = false; // Check marker last update time

      const lastUpdatedAt = node.get('lastUpdatedAt');
      const t = new Date().getTime();

      if (!needUpdateNow && lastUpdatedAt && t - lastUpdatedAt < MARKER_UPDATE_THROTTLE) {
        // skip marker update
        return;
      } // update position


      const lat = node.get('lat');
      const lng = node.get('lng');

      const options = this._computeMarkerOptions(node);

      const highlightOptions = this._computeMarkerHighLightOptions(node);

      const coord = new bemap.Coordinate(lng, lat);

      if (lat !== cache.lat || lng !== cache.lng) {
        marker.setCoordinate(coord);
        cache.lat = lat;
        cache.lng = lng;
        isDirty = true;
      }

      if (Ember.isNone(options) || Ember.isNone(options.icon)) return false;
      const _popup = marker.native._popup;

      if (!node.get('isGrouped') && node.get('inCluster')) {
        vehicleCluster.native.removeLayer(marker.native);
        map.addMarker(marker);
        node.set('inCluster', false); // benomad library clobbers the popup during remvoing of markers,
        // we will add this again on switching from cluster to non-clusters

        if (!Ember.isNone(_popup) && Ember.isNone(marker.native._popup)) {
          marker.native._popup = _popup;
        }

        isDirty = true;
      } else if (node.get('isGrouped') && !node.get('inCluster')) {
        vehicleCluster.native.addLayer(marker.native);
        map.removeLayer(marker);
        node.set('inCluster', true); // benomad library clobbers the popup during remvoing of markers,
        // we will add this again on switching from cluster to non-clusters

        if (!Ember.isNone(_popup) && Ember.isNone(marker.native._popup)) {
          marker.native._popup = _popup;
        }

        isDirty = true;
      } // benomad library clobbers the highlighted icon,
      // we should add this after removing and adding of markers
      // update marker icon


      const iconDigest = JSON.stringify(options);

      if (iconDigest !== cache.iconDigest) {
        const icon = new bemap.Icon(options.icon);

        if (node.get('isHighLighted')) {
          marker.native.setIcon(highlightOptions.icon);
        } else {
          marker.native.setIcon(options.leaflet.icon);
          marker.setIcon(icon);
        }

        cache.iconDigest = iconDigest;
        isDirty = true;
      }

      if (node.get('isHighLighted') && marker.native._icon) {
        window.L.DomUtil.addClass(marker.native._icon, 'high-lighted');
      } else if (marker.native._icon) {
        window.L.DomUtil.removeClass(marker.native._icon, 'high-lighted');
      } // update front-to-back z-index, e.g. render vehicles on top of stops


      const zIndexOffset = node.get('zIndexOffset');

      if (!Ember.isNone(zIndexOffset) && zIndexOffset !== cache.zIndexOffset) {
        marker.native.setZIndexOffset(zIndexOffset);
        cache.zIndex = zIndexOffset;
        isDirty = true;
      } // update icon rotation, e.g. vehicle heading


      const rotationAngle = node.get('rotationAngle');

      if (!Ember.isNone(rotationAngle) && rotationAngle !== cache.rotationAngle) {
        marker.native.setRotationAngle(rotationAngle / 2);
        cache.rotationAngle = rotationAngle;
        isDirty = true;
      } // once isDirty property is true for any different icon change, rotation angle, lat-lng, or zindex
      // update the vehicle cluster


      if (isDirty) {
        vehicleCluster.native.refreshClusters(marker.native); // not working natively, either it refreshes when child marker updates possibly?
      } // update opacity; emergency/highlighted vehicles are always full opacity


      const isEmergencyVehicle = node.get('record.isGrouped');
      const opacity = isEmergencyVehicle ? 1.0 : node.get('opacity');

      if (opacity !== cache.opacity) {
        marker.native.setOpacity(opacity);
        cache.opacity = opacity;
        isDirty = true;
      }

      const setIsDirty = () => {
        isDirty = true;
      };

      this.setLabelAndPopUp(node, cache, marker, setIsDirty);
      node.set('lastUpdatedAt', t);
      return isDirty;
    },

    removeBreakMarker(node, breakType, isMaximized) {
      const vehicleCluster = this.get('vehicleCluster');
      const map = this.get('map');
      let marker = node.get('breakOffMarker');

      if (breakType === ON_BREAK_TYPE) {
        marker = isMaximized ? node.get('markerMaximized') : node.get('marker');
      }

      if (Ember.isNone(marker)) return;
      marker.native.iq = null;
      marker.native.off('contextmenu');
      marker.native.off('dblclick');

      if (vehicleCluster && node.get('inCluster')) {
        vehicleCluster.native.removeLayer(marker.native);
      }

      map.removeMarker(marker);

      if (breakType === ON_BREAK_TYPE) {
        node.cache = null;
        node.set('marker', null);
      } else {
        node.set('breakOffMarker', null);
        node.set('breakOffCache', null);
      }

      return true;
    },

    removeMarker(node, isMaximized) {
      const vehicleCluster = this.get('vehicleCluster');
      const marker = isMaximized ? node.get('markerMaximized') : node.get('marker');
      const map = this.get('map');
      marker.native.iq = null;
      marker.native.off('contextmenu');
      marker.native.off('dblclick');

      if (vehicleCluster && node.get('isGrouped')) {
        map.removeMarker(marker, {
          layer: vehicleCluster
        });
        vehicleCluster.native.removeLayer(marker.native);
      } else {
        map.removeMarker(marker);
      }

      node.cache = null;
      node.set('marker', null);
      return true;
    },

    /**
     *
     * @param node
     * @param marker
     * @param label
     */
    addPopUpAndTooltipToMarker(node, marker, label) {
      // popup label if display label set to 'always'
      if (node.get('layer.isDisplayMapIconLabel')) {
        marker.native.bindPopup(label, {
          closeButton: false,
          autoClose: false,
          closeOnClick: false,
          autoPan: false
        }).openPopup();
      } else {
        marker.native.bindPopup(label, {
          closeButton: false
        });
      }

      marker.native.bindTooltip(label);
    },

    /**
     *
     * @param node
     * @param marker
     */
    bindClickHandlersToMarker(node, marker) {
      // bind click handlers
      marker.native.on('contextmenu', e => {
        node.trigger('contextmenu', e);

        if (this.onContextMenu) {
          Ember.run(() => this.onContextMenu(e, node));
        }
      });
      marker.native.on('dblclick', e => {
        node.trigger('dblclick', e);

        if (this.onDblClick) {
          window.L.DomEvent.stop(e);
          Ember.run(() => this.onDblClick(e, node));
        }
      });
    },

    /**
     *
     * @param node
     * @param marker
     */
    initZIndexAndRotationAngleToMarker(node, marker) {
      // init z-index
      const zIndexOffset = node.get('zIndexOffset');

      if (!Ember.isNone(zIndexOffset)) {
        marker.native.setZIndexOffset(zIndexOffset);
      } // init rotation angle


      const rotationAngle = node.get('rotationAngle');

      if (!Ember.isNone(rotationAngle)) {
        marker.native.setRotationAngle(rotationAngle / 2);
      }
    },

    /**
     *
     * @param node
     * @param cache
     * @param marker
     * @param cb // setIsDirty Callback
     */
    setLabelAndPopUp(node, cache, marker, cb) {
      // update popup & tooltip
      const label = node.get('label');

      if (label !== cache.label) {
        if (!label || label === '<br/>') {
          marker.native.closePopup();
          marker.native.unbindTooltip();
        } else if (!cache.label) {
          marker.native.bindTooltip(label);
        } else {
          marker.native.setPopupContent(label);
          marker.native.setTooltipContent(label);
        }

        cache.label = label;
        cb();
      }
    },

    _computeMarkerOptions(node) {
      const optionSets = [];
      const opacity = node.get('opacity');

      if (!Ember.isNone(opacity)) {
        optionSets.push({
          opacity
        });
      }

      optionSets.push({
        highlight: node.get('isHighLighted')
      });
      const nodeOptions = node.get('options');

      if (nodeOptions) {
        optionSets.push(nodeOptions);
      }

      const style = node.get('style');
      const layerType = node.get('layerType');

      if (style) {
        const markerOptions = _marker.default[style];

        if (!markerOptions) {
          throw new Error(`no marker style found with key '${style}'`);
        }

        optionSets.push(markerOptions);
      } else if (layerType && layerType.style) {
        const layerTypeOptions = _marker.default[layerType.style];

        if (!layerTypeOptions) {
          throw new Error(`no marker style found with key ${layerType.style}`);
        }

        optionSets.push(layerTypeOptions);
      }

      if (style) {
        const leaflet = {
          leaflet: _markerLeaflet.default[style]
        };

        if (node.isBreak) {
          const isDispatchDashboard = this.get('workspace.isDispatchDashboard');
          const isFloating = isDispatchDashboard ? node.stopPoint.get('routeBreak.breakType.isFloating') : node.isFloatingBreak;
          const polyline = isDispatchDashboard ? node.stopPoint.get('polylinePoints') : null;

          if (isFloating && Ember.isNone(polyline)) {
            leaflet.leaflet.icon.options.className = 'floating-break-no-location';
          }
        }

        optionSets.push(leaflet);
      }

      return _lodash.default.defaultsDeep({}, ...optionSets);
    },

    _computeMarkerHighLightOptions(node) {
      const optionSets = [];
      const opacity = node.get('opacity');

      if (!Ember.isNone(opacity)) {
        optionSets.push({
          opacity
        });
      }

      const nodeOptions = node.get('options');

      if (nodeOptions) {
        optionSets.push(nodeOptions);
      }

      const style = node.get('style');
      const layerType = node.get('layerType');

      if (style) {
        const markerOptions = _markerHighlight.default[style];

        if (node.isBreak) {
          const isDispatchDashboard = this.get('workspace.isDispatchDashboard');
          const isFloating = isDispatchDashboard ? node.stopPoint.get('routeBreak.breakType.isFloating') : node.isFloatingBreak;
          const polyline = isDispatchDashboard ? node.stopPoint.get('polylinePoints') : null;

          if (isFloating && Ember.isNone(polyline)) {
            markerOptions.icon.options.className = 'floating-break-no-location';
          }
        }

        if (!markerOptions) {
          throw new Error(`no marker style found with key '${style}'`);
        }

        optionSets.push(markerOptions);
      } else if (layerType && layerType.style) {
        const layerTypeOptions = _marker.default[layerType.style];

        if (!layerTypeOptions) {
          throw new Error(`no marker style found with key ${layerType.style}`);
        }

        optionSets.push(layerTypeOptions);
      }

      return _lodash.default.defaultsDeep({}, ...optionSets);
    }

  });

  _exports.default = _default;
});