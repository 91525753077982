define("adept-iq/tests/factories/avlm-schedule", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const date = (0, _moment.default)();

  _emberDataFactoryGuy.default.define('avlm-schedule', {
    default: {
      startTime: date.toDate(),
      endTime: date.toDate(),
      name: date.format('YYYY-MM-DD')
    }
  });
});