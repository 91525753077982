define("adept-iq/pods/components/road-supervisor-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['road-supervisor-modal'],
    roadSupervisor: Ember.inject.service(),
    isWaived: false,
    instance: Ember.computed.readOnly('roadSupervisor.instance'),
    address: Ember.computed.alias('roadSupervisor.address'),
    actions: {
      onAgreeButtonClick() {
        this.set('isWaived', true);
        this.set('loading', true);
        this.roadSupervisor.enter().finally(() => this.set('loading', false));
      },

      onExitButtonClick() {
        this.roadSupervisor.set('isNavigating', false);
        this.roadSupervisor.exit();
      }

    }
  });

  _exports.default = _default;
});