define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/side-drawer/shrink", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Shrink = props => {
    const {
      setIsShrinked
    } = props;
    return _react.default.createElement("div", {
      className: "ember-modal-dialog side-drawer-modal side-drawer-shrunken data-test-shrunken-side-drawer ember-modal-dialog-target-attachment-left emd-target-attachment-left ember-view"
    }, _react.default.createElement("div", {
      className: "side-drawer-buttons btn-toolbar justify-content-between side-drawer-shrink-btn"
    }, _react.default.createElement("button", {
      className: "side-drawer-button",
      onClick: () => setIsShrinked(false),
      "data-test-toggle-side-drawer": ""
    }, _react.default.createElement("span", null, _react.default.createElement("i", {
      className: "fa fa-angle-double-right",
      "aria-hidden": "true"
    })))));
  };

  var _default = Shrink;
  _exports.default = _default;
});