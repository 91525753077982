define("adept-iq/pods/components/generic-components/solution-preview-table/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rowComponent: 'table/rows/colored-row',
    sortDirection: 'asc',

    init() {
      this._super(...arguments);
    },

    actions: {
      onColumnClick(column) {
        if (column.sorted) {
          this.set('sortDirection', column.ascending ? 'asc' : 'desc');
        }

        const valuePath = column.get('valuePath');
        const rowsToSort = (0, _lodash.orderBy)(this.get('solutionPreviewTable.rows'), row => {
          return row.get(valuePath);
        }, [this.get('sortDirection')]);
        this.set('solutionPreviewTable.rows', rowsToSort);
      }

    }
  });

  _exports.default = _default;
});