define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/dot-inspection/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/dot-inspection/dot-inspection-validation", "adept-iq/config/placeholders", "lodash"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _dotInspectionValidation, _placeholders, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_DOT_TYPE_MAP = {
    addVehicleDotInspection: true,
    editVehicleDotInspection: true,
    deleteVehicleDotInspection: true
  };
  const ACTIVE_STATUS = 'active';

  var _default = Ember.Component.extend({
    classNames: ['dot-inspection-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    showErrorMsg: false,
    tableRef: null,
    dotInspectionTableRef: null,
    providerPlaceholder: _placeholders.PROVIDER_PLACEHOLDER,
    categoryPlaceholder: _placeholders.INSPECTION_CATEGORY_PLACEHOLDER,
    notesPlaceholder: _placeholders.NOTES_PLACEHOLDER,
    failedDotPlaceholder: _placeholders.FAILEDDOT_PLACEHOLDER,
    datePlaceholder: _placeholders.US_DATE_PLACEHOLDER,
    mileagePlaceholder: _placeholders.MILEAGE_PLACEHOLDER,
    // passed into view
    editAPI: null,
    selectedVehicle: Ember.computed.readOnly('editAPI.selectedRecord'),
    selectedDOTInspection: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedDotInspectionRow: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),

    async init() {
      const vehicle = this.get('selectedVehicle');

      this._super(...arguments);

      this.get('refreshTask').perform();
      this.set('activityQueryParams', {
        vehicleId: `${vehicle.get('id')}`,
        eventLog: true
      });
      this.set('dotQueryParams', {
        filter: `eq(vehicleId,${vehicle.get('id')})`
      });
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveDotInspectionRecord = this.get('onSaveDotInspectionRecord');
      const onNewDotInspectionRecord = this.get('onNewDotInspectionRecord').bind(this);
      const onEditDotInspectionRecord = this.get('onEditDotInspectionRecord').bind(this);
      const onDeleteDotInspectionRecord = this.get('onDeleteDotInspectionRecord');
      const onUndoDotInspectionRecord = this.get('onUndoDotInspectionRecord').bind(this);
      const onValidateAction = this.get('validate');
      const hasChanges = this.get('hasChanges').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDotInspectionRecord,
          newAction: onNewDotInspectionRecord,
          deleteAction: onDeleteDotInspectionRecord,
          editAction: onEditDotInspectionRecord,
          undoAction: onUndoDotInspectionRecord,
          onValidateAction,
          hasChanges,
          isChangesHappened: false
        });
      });
      this.addObserver('hasPendingChanges', this.hasPendingChangesObserver);
      this.get('refreshTask').perform().then(() => {
        Ember.run.later(() => {
          this.resetForm();
        }, 1000);
      });
    },

    dotInspectionChangeSet: Ember.computed('selectedDOTInspection', function () {
      const dotInspection = (0, _unwrapProxy.unwrapProxy)(this.get('selectedDOTInspection'));
      return this.setDefaultProperties(dotInspection);
    }),
    hasPendingChanges: Ember.computed('dotInspectionChangeSet.changes.length', function () {
      const dotInspectionChangeSet = this.get('dotInspectionChangeSet');
      const changes = dotInspectionChangeSet.get('changes') || [];
      return changes.length;
    }),

    hasChanges() {
      const dotInspectionChangeSet = this.get('dotInspectionChangeSet');

      if (dotInspectionChangeSet.get('isNew')) {
        const pendingChanges = this.get('dotInspectionChangeSet');
        const fields = (0, _lodash.flatten)(pendingChanges.changes).map(change => change.key);
        const isEveyPrePoulatedFieldsChange = fields.length ? fields.every(field => field.includes('inspectionDate')) : false;
        if (isEveyPrePoulatedFieldsChange) return !isEveyPrePoulatedFieldsChange;
        return !!this.get('hasPendingChanges');
      }

      return !!this.get('hasPendingChanges');
    },

    hasPendingChangesObserver() {
      const editAPI = this.get('editAPI');
      const hasPendingChanges = this.get('hasPendingChanges');
      Ember.run.scheduleOnce('afterRender', () => {
        editAPI.setProperties({
          isChangesHappened: hasPendingChanges
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeObserver('hasOverallPendingChanges', this.hasPendingChangesObserver);
    },

    filterEventTypeFunction(event) {
      if (!event) return false;
      return EVENT_DOT_TYPE_MAP[event.get('actionType')];
    },

    setDefaultProperties() {
      let record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const editAPI = this.get('editAPI');
      const store = this.get('store');
      let pendingDotInspectionRecord = this.get('pendingDotInspectionRecord');

      if (record) {
        pendingDotInspectionRecord = record;
        this.resetForm();
      } else if (!pendingDotInspectionRecord) {
        pendingDotInspectionRecord = store.createRecord('vehicle-dot-inspection');
        this.set('pendingDotInspectionRecord', pendingDotInspectionRecord);
      }

      if (pendingDotInspectionRecord.get('isNew')) {
        const defaultDate = new Date();
        pendingDotInspectionRecord.setProperties({
          inspectionDate: defaultDate,
          oosDate: null,
          nextInspectionDate: null,
          provider: null,
          mileage: null,
          inspectionCategory: null,
          failedDot: null,
          notes: null
        });
      } else {
        Ember.run.scheduleOnce('afterRender', editAPI, 'buttonSettings');
      }

      this.set('showErrorMsg', false);
      this.set('showValidations', false);
      return new _emberChangeset.default(pendingDotInspectionRecord, (0, _emberChangesetValidations.default)(_dotInspectionValidation.VALIDATIONS), _dotInspectionValidation.VALIDATIONS);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedVehicle = this.get('selectedVehicle');
      const store = this.get('store');
      const providers = store.peekAll('provider').filter(provider => {
        return provider.get('userCanAccessProvider') && provider.get('status') && provider.get('status').toLowerCase() === ACTIVE_STATUS;
      });
      this.set('providers', providers);
      const inspectCategories = yield store.findAll('inspection-category');
      this.set('inspectionCategories', inspectCategories);

      if (!selectedVehicle.get('isNew')) {
        yield selectedVehicle.reload();
      }

      const tableRef = this.get('tableRef');
      const dotInspectionTableRef = this.get('dotInspectionTableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      if (dotInspectionTableRef) Ember.run.scheduleOnce('afterRender', dotInspectionTableRef, 'refreshData');
    }),

    resetForm() {
      const editAPI = this.get('editAPI');
      Ember.run.next(() => {
        this.set('disableForm', true);
        const records = this.get('tableRef.records');

        if (!records.length) {
          editAPI.buttonSettings(true, false, true, true, true);
        } else {
          editAPI.buttonSettings();
        }
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const dotInspectionChangeSet = this.get('dotInspectionChangeSet');
      yield dotInspectionChangeSet.validate();
      this.set('bothDateValiationError', false);
      const nextInspectionDate = dotInspectionChangeSet.get('nextInspectionDate');
      const oosDate = dotInspectionChangeSet.get('oosDate');

      if (!nextInspectionDate && !oosDate) {
        this.set('bothDateValiationError', true);
        dotInspectionChangeSet.errors.pushObject({
          validation: 'Either an OOS Date or a Next Inspection Date is required'
        });
      }

      const errors = dotInspectionChangeSet.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveDotInspectionRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      const tooltip = this.get('tooltip');

      if (isValid) {
        const notifications = this.get('notifications');
        const pendingDotInspectionRecord = this.get('dotInspectionChangeSet');
        const selectedVehicle = this.get('selectedVehicle');
        pendingDotInspectionRecord.set('vehicle', selectedVehicle);

        try {
          yield pendingDotInspectionRecord.save();
          notifications.success('Record successfully saved.');
          this.set('pendingDotInspectionRecord', null);
          this.get('refreshTask').perform().then(() => {
            Ember.run.later(() => {
              this.set('tableRef.config.selectFirstRow', true);
              this.get('tableRef.firstRowSelectedTask').perform();
              this.resetForm();
              return tooltip.reset();
            }, 1000);
            return pendingDotInspectionRecord;
          });
        } catch (e) {
          notifications.warning('Failed to Save Vehicle DOT Inspection Record');
          console.warn(e); // eslint-disable-line
        }
      }
    }),

    onNewDotInspectionRecord() {
      const editAPI = this.get('editAPI');
      const selectedDotInspectionRow = this.get('selectedDotInspectionRow');

      if (selectedDotInspectionRow) {
        selectedDotInspectionRow.set('selected', false);
        this.set('tableRef.config.selectFirstRow', false);
      }

      this.get('tableRef').refreshData(); //to refresh table data

      editAPI.buttonSettings(true, true, true, false, false); //False to enable the button, TRUE to disable

      this.set('disableForm', false);
    },

    onDeleteRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const selectedDOTInspection = this.get('selectedDOTInspection');

      try {
        yield selectedDOTInspection.destroyRecord();
        notifications.success('Record successfully deleted');
        this.get('refreshTask').perform().then(() => {
          Ember.run.later(() => {
            this.resetForm();
            this.set('tableRef.config.selectFirstRow', true);
            this.get('tableRef.firstRowSelectedTask').perform();
          }, 1000);
        });
      } catch (e) {
        notifications.warning('Record failed to delete');
        console.warn(e); // eslint-disable-line
      }
    }),
    onDeleteDotInspectionRecord: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const selectedVehicle = this.get('selectedVehicle');
      const selectedDOTInspection = this.get('selectedDOTInspection');
      if (!selectedDOTInspection) return;
      yield tooltip.pushConfirmation({
        title: 'Vehicle Management',
        tip: `Are you sure you want to delete inspection record for vehicle ${selectedVehicle.get('name')}?`,
        warning: true,
        hasOverlay: true,
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        primaryActionText: 'Confirm',
        primaryAction: () => {
          this.get('onDeleteRecord').perform();
          return tooltip.reset();
        }
      });
    }),

    onEditDotInspectionRecord() {
      const editAPI = this.get('editAPI');
      editAPI.buttonSettings(true, true, true, false, false);
      this.set('disableForm', false);
    },

    onUndoDotInspectionRecord() {
      const dotInspectionChangeSet = this.get('dotInspectionChangeSet');

      if (dotInspectionChangeSet) {
        dotInspectionChangeSet.rollback();
        this.set('bothDateValiationError', false);
      }
    },

    actions: {
      onPowerSelectChange(valuePath, selected) {
        this.set(valuePath, selected);
      },

      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onDateValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onSaveDotInspectionRecord() {
        return this.onSaveDotInspectionRecord.perform();
      }

    }
  });

  _exports.default = _default;
});