define("adept-iq/classes/rules/eligibility-rules/eligibility-rule-builder", ["exports", "adept-iq/classes/rules/eligibility-rules/full-eligibility-rule", "adept-iq/classes/rules/eligibility-rules/assessment-trip-rule", "adept-iq/classes/rules/eligibility-rules/user-override-conditions-rule", "adept-iq/classes/rules/eligibility-rules/taxi-allowed-rule", "adept-iq/classes/rules/eligibility-rules/rider-prefer-taxi-rule", "adept-iq/classes/rules/eligibility-rules/deny-eligibility-suspension-rule", "adept-iq/classes/rules/eligibility-rules/all-conditions-pass-rule", "adept-iq/classes/rules/eligibility-rules/rider-distance-condition-rule", "adept-iq/classes/rules/eligibility-rules/no-eligibility-rule", "adept-iq/classes/rules/eligibility-rules/group-booking-rule", "adept-iq/classes/rules/eligibility-rules/user-override-permissions-rule", "adept-iq/classes/rules/eligibility-rules/itp-enabled-rule", "adept-iq/classes/rules/eligibility-rules/itp-blackout-rule", "adept-iq/classes/rules/eligibility-rules/itp-conditions-rule", "adept-iq/classes/rules/eligibility-rules/itp-request-rule", "adept-iq/classes/rules/eligibility-rules/itp-resulttype-rule", "adept-iq/classes/rules/eligibility-rules/itp-include-feeder-rule", "adept-iq/classes/rules/eligibility-rules/conditional-eligibility-rule", "adept-iq/classes/rules/eligibility-rules/itp-user-override-permissions-rule", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type"], function (_exports, _fullEligibilityRule, _assessmentTripRule, _userOverrideConditionsRule, _taxiAllowedRule, _riderPreferTaxiRule, _denyEligibilitySuspensionRule, _allConditionsPassRule, _riderDistanceConditionRule, _noEligibilityRule, _groupBookingRule, _userOverridePermissionsRule, _itpEnabledRule, _itpBlackoutRule, _itpConditionsRule, _itpRequestRule, _itpResulttypeRule, _itpIncludeFeederRule, _conditionalEligibilityRule, _itpUserOverridePermissionsRule, _baseRule, _ruleResultType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EligibilityRuleBuilder {
    constructor() {}
    /**
     *
     * @param noTripActionHandler
     * @param itpRequestFailureHandler
     * @param taxiRequestHandler
     * @param paraTransitHandler
     * @param overrideConditionPreActionHandler
     * @param overridePermissionHandler
     * @param itpRequestHandler
     * @param errorHandler
     * @returns
     */


    buildRuleGraph(noTripActionHandler, itpRequestFailureHandler, taxiRequestHandler, paraTransitHandler, overrideConditionPreActionHandler, overridePermissionHandler, itpRequestHandler, errorHandler) {
      const fullEligibilityRule = new _fullEligibilityRule.FullEligibilityRule();
      const assessmentTripRule = new _assessmentTripRule.AssessmentTripRule();
      const conditionalEligibilityRule = new _conditionalEligibilityRule.ConditionalEligibilityRule();
      const userOverrideConditionsRule = new _userOverrideConditionsRule.UserOverrideConditionsRule();
      const taxiAllowedRule = new _taxiAllowedRule.TaxiAllowedRule();
      const riderPreferTaxiRule = new _riderPreferTaxiRule.RiderPreferTaxiRule();
      const denyEligibilitySuspensionRule = new _denyEligibilitySuspensionRule.DenyEligibilitySuspensionRule();
      const allConditionsPassRule = new _allConditionsPassRule.AllConditionsPassRule();
      const riderDistanceConditionRule = new _riderDistanceConditionRule.RiderDistanceConditionRule();
      const noEligibilityRule = new _noEligibilityRule.NoEligibilityRule();
      const groupBookingRule = new _groupBookingRule.GroupBookingRule();
      const userOverridePermissionsRule = new _userOverridePermissionsRule.UserOverridePermissionsRule();
      const itpEnabledRule = new _itpEnabledRule.ItpEnabledRule();
      const itpBlackoutRule = new _itpBlackoutRule.ItpBlackoutRule();
      const itpConditionsRule = new _itpConditionsRule.ItpConditionsRule();
      const itpRequestRule = new _itpRequestRule.ItpRequestRule();
      const itpResultTypeRule = new _itpResulttypeRule.ItpResultTypeRule();
      const itpIncludeFeederRule = new _itpIncludeFeederRule.ItpIncludeFeederRule();
      const itpUserOverridePermissionsRule = new _itpUserOverridePermissionsRule.ITPUserOverridePermissionsRule(); // fullEligibilityRule

      this.configureRule(fullEligibilityRule, taxiAllowedRule, assessmentTripRule); // taxiAllowedRule

      this.configureRule(taxiAllowedRule, riderPreferTaxiRule, paraTransitHandler); //assessmentTripRule

      this.configureRule(assessmentTripRule, taxiAllowedRule, conditionalEligibilityRule); //riderPreferTaxiRule

      this.configureRule(riderPreferTaxiRule, taxiRequestHandler, paraTransitHandler); //conditionalEligibilityRule

      this.configureRule(conditionalEligibilityRule, allConditionsPassRule, denyEligibilitySuspensionRule); // denyEligibilitySuspensionRule

      this.configureRule(denyEligibilitySuspensionRule, noTripActionHandler, noEligibilityRule); // noEligibilityRule

      this.configureRule(noEligibilityRule, noTripActionHandler); //allConditionsPassRule

      this.configureRuleWithErrorHandler(allConditionsPassRule, taxiAllowedRule, userOverridePermissionsRule, errorHandler); // userOverridePermissionsRule

      this.configureRule(userOverridePermissionsRule, userOverrideConditionsRule, overridePermissionHandler); // userOverrideConditionsRule

      this.configureRuleWithPreAction(userOverrideConditionsRule, riderDistanceConditionRule, noTripActionHandler, overrideConditionPreActionHandler); // riderDistanceConditionRule

      this.configureRule(riderDistanceConditionRule, groupBookingRule, taxiAllowedRule); // groupBookingRule

      this.configureRule(groupBookingRule, taxiAllowedRule, itpEnabledRule); // itpEnabledRule

      this.configureRule(itpEnabledRule, itpBlackoutRule, taxiAllowedRule); // itpBlackoutRule

      this.configureRule(itpBlackoutRule, taxiAllowedRule, itpConditionsRule); // itpConditionsRule

      this.configureRule(itpConditionsRule, itpRequestRule, taxiAllowedRule); //itpRequestRule

      this.configureRule(itpRequestRule, itpResultTypeRule, itpRequestFailureHandler); //itpResultTypeRule

      this.configureItpResultRule(itpResultTypeRule, itpIncludeFeederRule, itpUserOverridePermissionsRule, taxiAllowedRule); //itpIncludeFeederRule

      this.configureRule(itpIncludeFeederRule, itpUserOverridePermissionsRule, taxiAllowedRule); //itpUserOverridePermissionsRule

      this.configureRule(itpUserOverridePermissionsRule, taxiAllowedRule, itpRequestHandler);
      return fullEligibilityRule;
    }

    configureRule(rule, yesRule, noRule) {
      this.addRuleOutCome(rule, yesRule, _ruleResultType.RESULT_TYPE_YES);
      this.addRuleOutCome(rule, noRule, _ruleResultType.RESULT_TYPE_NO);
    }
    /**
     * before the rule is executed, the preActionHandler will be executed
     * @param rule
     * @param yesRule
     * @param noRule
     * @param preActionHandler
     */


    configureRuleWithPreAction(rule, yesRule, noRule, preActionHandler) {
      this.addRuleOutCome(rule, yesRule, _ruleResultType.RESULT_TYPE_YES);
      this.addRuleOutCome(rule, noRule, _ruleResultType.RESULT_TYPE_NO);
      rule.addPreRuleAction(preActionHandler);
    }

    configureRuleWithErrorHandler(rule, yesRule, noRule, errorHandler) {
      this.addRuleOutCome(rule, yesRule, _ruleResultType.RESULT_TYPE_YES);
      this.addRuleOutCome(rule, noRule, _ruleResultType.RESULT_TYPE_NO);
      this.addRuleOutCome(rule, errorHandler, _ruleResultType.RESULT_TYPE_ERROR);
    }

    configureItpResultRule(rule, feederRule, fixedRule, noSolutionRule) {
      this.addRuleOutCome(rule, feederRule, _ruleResultType.RESULT_TYPE_FEEDER);
      this.addRuleOutCome(rule, fixedRule, _ruleResultType.RESULT_TYPE_FIXED_ROUTE);
      this.addRuleOutCome(rule, noSolutionRule, _ruleResultType.RESULT_TYPE_NO);
    }

    addRuleOutCome(rule, ruleOrAction, type) {
      if (!ruleOrAction) return;

      if (this.isRule(ruleOrAction)) {
        rule.addOutComeRule(type, ruleOrAction);
      } else {
        rule.addOutcomeAction(type, ruleOrAction);
      }
    }

    isRule(rule) {
      return rule instanceof _baseRule.default;
    }
    /**
     * called from your service or component
     * @param rootRule
     * @param eligibilityRuleContext
     */


    execute(rootRule, eligibilityRuleContext) {
      rootRule.execute(eligibilityRuleContext);
    }

  }

  _exports.default = EligibilityRuleBuilder;
});