define("adept-iq/pods/components/side-drawer/reconcile-add-new-route/component", ["exports", "moment", "adept-iq/pods/components/side-drawer/reconcile-add-new-route/config", "ember-concurrency", "lodash"], function (_exports, _moment, _config, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    reconcile: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    reconcileLambda: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    classNames: ['reconcile-widget-base'],
    vehicles: null,
    routeId: null,
    timeFormat: 'm-d-Y h:i K',
    reconcileProviderName: Ember.computed.readOnly('reconcile.reconcileProviderName'),
    reconcileTripDate: Ember.computed.readOnly('reconcile.reconcileDate'),
    selectedRouteId: Ember.computed.readOnly('routeId'),
    reconcileRVDResponse: Ember.computed.readOnly('reconcile.reconcileRVDResponse'),
    // Reset map and address picker
    canResetAddress: Ember.computed.alias('workspace.activeContext.topActiveContext.resetLocation'),
    // routeInformation: computed.alias('reconcile.reconcileRouteInfo'),
    canSave: Ember.computed.alias('routeId', 'selectedDateTimeRange.{startTime,endTime}', 'selectedVehicle', 'selectedDriver', 'selectedStartLocation', 'selectedEndLocation'),
    canUndo: Ember.computed.or('routeId', 'selectedDateTimeRange.{startTime,endTime}', 'selectedVehicle', 'selectedDriver', 'selectedStartLocation', 'selectedEndLocation', 'selectedStartZone', 'selectedEndZone', 'startAddressIdentifier', 'endAddressIdentifier', 'startAddressNotes', 'endAddressNotes', 'selectedRouteType'),
    computeFullName: Ember.computed('selectedDriver', function () {
      const selectedDriver = this.get('selectedDriver');
      const firstName = selectedDriver.firstname;
      const middleName = selectedDriver.middlename;
      const lastName = selectedDriver.lastname;
      const fullName = `${firstName} ${middleName} ${lastName}`;
      return fullName;
    }),
    routeTypeOptions: Ember.computed('selectedRouteType', function () {
      return _config.default.routeTypes;
    }),
    vehicleOptions: Ember.computed('reconcile.reconcileVehicleOptions', function () {
      const vehicleOptions = this.get('reconcile.reconcileVehicleOptions') || [];
      return vehicleOptions;
    }),
    driverOptions: Ember.computed('reconcile.reconcileDriverOptions', function () {
      const driverOptions = this.get('reconcile.reconcileDriverOptions') || [];
      return driverOptions;
    }),
    zoneOptions: Ember.computed('selectedStartZone', 'selectedEndZone', function () {
      const zones = this.store.peekAll('zone');
      return zones.map(zone => zone);
    }),

    init() {
      this._super();

      Ember.set(this, 'selectedRadioValue', 'start');
      Ember.set(this, 'selectedDateTimeRange', {
        startTime: null,
        endTime: null,
        startOfDay: (0, _moment.default)(this.reconcileTripDate).startOf('day').toISOString(),
        endOfDay: (0, _moment.default)(this.reconcileTripDate).endOf('day').toISOString()
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.setSideDrawerWidth('470px');
      this.get('systemConfig').set('showRightPanel', true);
    },

    generateId() {
      return Math.floor(Math.random() * 1000);
    },

    buildRoutePayloadForNewRoute(reconcileDate, startAddressId, endAddressId) {
      return {
        'deleted$1': false,
        'endAddressId': endAddressId,
        'startAddressId': startAddressId,
        'op$1': 'c',
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'plannedStartTime': this.get('selectedDateTimeRange.startTime'),
        'plannedEndTime': this.get('selectedDateTimeRange.endTime'),
        'id': this.get('routeId'),
        'providerName': this.get('reconcileProviderName'),
        'scheduleId': null,
        'status': null,
        'trackingId': null,
        'actualStartTime': null,
        'actualEndTime': null,
        'alertId': null,
        'otpValue': null,
        'otpStatus': null,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      };
    },

    buildStartAddressPayloadForNewRoute(address, reconcileDate, startAddressId) {
      return {
        'country': address.country ? address.country : null,
        'streetNumber': address.streetNumber ? address.streetNumber : null,
        'subLocality': address.subLocality ? address.subLocality : null,
        'deleted$1': false,
        'postalCode': address.postalCode ? address.postalCode : null,
        'locality': address.locality ? address.locality : null,
        'op$1': 'c',
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'premise': address.premise ? address.premise : null,
        'streetAddress': address.streetAddress ? address.streetAddress : null,
        'id': startAddressId,
        'zoneName': this.get('selectedStartZone.zoneId'),
        'region': address.region ? address.region : null,
        'alias': this.get('startAddressIdentifier') ? this.get('startAddressIdentifier') : null,
        'subRegion': address.subRegion ? address.subRegion : null,
        'notes': address.notes ? address.notes : null,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      };
    },

    buildEndAddressPayloadForNewRoute(address, reconcileDate, endAddressId) {
      return {
        'country': address.country ? address.country : null,
        'streetNumber': address.streetNumber ? address.streetNumber : null,
        'subLocality': address.subLocality ? address.subLocality : null,
        'deleted$1': false,
        'postalCode': address.postalCode ? address.postalCode : null,
        'locality': address.locality ? address.locality : null,
        'op$1': 'c',
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'premise': address.premise ? address.premise : null,
        'streetAddress': address.streetAddress ? address.streetAddress : null,
        'id': endAddressId,
        'zoneName': this.get('selectedEndZone.zoneId'),
        'region': address.region ? address.region : null,
        'alias': this.get('endAddressIdentifier') ? this.get('endAddressIdentifier') : null,
        'subRegion': address.subRegion ? address.subRegion : null,
        'notes': address.notes ? address.notes : null,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      };
    },

    buildStartPlacePayloadForNewRoute(reconcileDate, startAddressId) {
      return {
        'id': this.generateId(),
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'placeCategoryTypeName': 'garage',
        'addressId': startAddressId,
        'locationId': this.generateId(),
        'geocodingMethod': 'something',
        'op$1': 'c',
        'deleted$1': false
      };
    },

    buildEndPlacePayloadForNewRoute(reconcileDate, endAddressId) {
      return {
        'id': this.generateId(),
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'placeCategoryTypeName': 'garage',
        'addressId': endAddressId,
        'locationId': this.generateId(),
        'geocodingMethod': 'something',
        'op$1': 'c',
        'deleted$1': false
      };
    },

    buildRvdPayloadForNewRoute(reconcileDate) {
      return {
        'createdAt': reconcileDate,
        'updatedAt': reconcileDate,
        'routeId': this.get('routeId'),
        'driverId': this.get('selectedDriver.id'),
        'deleted$1': false,
        'startTime': this.get('selectedDateTimeRange.startTime'),
        'id': this.generateId(),
        'vehicleId': this.get('selectedVehicle.id'),
        'endTime': this.get('selectedDateTimeRange.endTime'),
        'op$1': 'c',
        'scheduleId': null,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      };
    },

    buildReconcilePayloadForAddRoute() {
      const reconcileDate = new Date().toISOString();
      const startAddress = this.get('selectedStartLocation');
      const endAddress = this.get('selectedEndLocation');
      const startAddressId = this.generateId();
      const endAddressId = this.generateId();
      return {
        'route': this.buildRoutePayloadForNewRoute(reconcileDate, startAddressId, endAddressId),
        'address': [this.buildStartAddressPayloadForNewRoute(startAddress, reconcileDate, startAddressId), this.buildEndAddressPayloadForNewRoute(endAddress, reconcileDate, endAddressId)],
        'place': [this.buildStartPlacePayloadForNewRoute(reconcileDate, startAddressId), this.buildEndPlacePayloadForNewRoute(reconcileDate, endAddressId)],
        'rvd': this.buildRvdPayloadForNewRoute(reconcileDate)
      };
    },

    saveNewRouteTask: (0, _emberConcurrency.task)(function* () {
      try {
        const newRoutePayload = this.buildReconcilePayloadForAddRoute();
        const reconcile = this.get('reconcile');
        yield reconcile.saveNewReconcileRoute(newRoutePayload);
      } catch (error) {
        throw error;
      }
    }),

    showToolTip() {
      const tooltip = this.get('tooltip');
      const title = 'Add New Route';
      const tip = 'Create Route ID ' + this.get('routeId') + '?';
      const successMsg = 'SUCCESSFULLY CREATED NEW ROUTE ' + this.get('routeId');
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('saveNewRouteTask').perform().then(() => {
            this.send('saveRouteSuccess');
            Ember.set(this, 'workspace.activeContext.topActiveContext.resetLocation', true);
            this.get('workspace').popState('reconcileAddNewRoute');
            tooltip.reset();
            this.get('notifications').success(successMsg);
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              case 409:
                message = `The route name ${this.get('routeId')} is already taken, please try another.`;
                break;

              default:
                message = 'There was a problem creating the route, please try again.';
                break;
            }

            this.set('errorMessage', message);
            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    calculateShiftLength() {
      let routeLength = 0;
      const startTime = this.get('selectedDateTimeRange').startTime;
      const endTime = this.get('selectedDateTimeRange').endTime;

      if (startTime && endTime) {
        const duration = _moment.default.duration((0, _moment.default)(endTime).diff((0, _moment.default)(startTime)));

        const hours = parseInt(duration.asHours(), 10) < 10 ? `0${parseInt(duration.asHours(), 10)}` : parseInt(duration.asHours(), 10);
        const minutes = parseInt(duration.minutes(), 10) < 10 ? `0${parseInt(duration.minutes(), 10)}` : parseInt(duration.minutes(), 10);
        routeLength = [hours, minutes].join(':');
      }

      Ember.set(this, 'routeLength', routeLength);
    },

    setDriverFields(selectedOption) {
      const routeResponse = this.get('reconcile.reconcileRVDResponse');
      const foundRouteInfo = routeResponse.find(response => {
        return response.driver && response.driver.id === selectedOption.id;
      });
      const actualStartTime = (0, _moment.default)(foundRouteInfo.route.plannedstarttime).format('hh:mm A');
      const actualEndTime = (0, _moment.default)(foundRouteInfo.route.plannedendtime).format('hh:mm A');
      Ember.set(this, 'selectedDriver', selectedOption);
      Ember.set(this, 'driverStartTime', actualStartTime);
      Ember.set(this, 'driverEndTime', actualEndTime);
      const fullName = this.get('computeFullName');
      Ember.set(this, 'fullName', fullName);
    },

    async setVehicleFields() {
      const store = this.get('store');
      let selectedVehicle = this.get('selectedVehicle');
      const vehicleDetails = store.peekRecord('vehicle', selectedVehicle.id);
      selectedVehicle = { ...selectedVehicle,
        vehicleType: vehicleDetails.vehicleTypeName,
        ambulatorySeats: vehicleDetails.noOfAmbulatorySeats,
        wheelchairSeats: vehicleDetails.noOfWheelChairSeats,
        flexSeats: vehicleDetails.noOfFlexSeats ? vehicleDetails.noOfFlexSeats.length : 0,
        wideWheelchair: vehicleDetails.noOfWideWheelChairSeats && vehicleDetails.noOfWideWheelChairSeats !== 0
      };
      this.set('selectedVehicle', selectedVehicle);
    },

    actions: {
      onUndoClick() {
        const startLocation = Ember.get(this, 'selectedStartLocation');
        const endLocation = Ember.get(this, 'selectedEndLocation');

        if (startLocation || endLocation) {
          this.set('canResetAddress', true);
        }

        this.setProperties({
          routeId: null,
          selectedDriver: null,
          selectedVehicle: null,
          selectedStartLocation: null,
          selectedEndLocation: null,
          selectedRadioValue: 'start',
          driverStartTime: null,
          driverEndTime: null,
          'selectedDateTimeRange.startTime': null,
          'selectedDateTimeRange.endTime': null,
          routeLength: null,
          startAddressIdentifier: null,
          endAddressIdentifier: null,
          selectedStartZone: null,
          selectedEndZone: null,
          startAddressNotes: null,
          endAddressNotes: null,
          fullName: null,
          selectedRouteType: null
        });
      },

      onRouteTypeSelect(option) {
        Ember.set(this, 'selectedRouteType', option);
      },

      onSaveClick() {
        if (this.get('routeId')) {
          this.showToolTip();
        }
      },

      onVehicleOptionSelect(option) {
        Ember.set(this, 'selectedVehicle', option);
        const reconcile = this.get('reconcile');
        const rvdResponse = reconcile.get('reconcileRVDResponse');
        const vehicleOptions = reconcile.get('getVehicleOptions')(rvdResponse, option);
        this.set('vehicleOptions', vehicleOptions);
        this.setVehicleFields();
      },

      onDriverOptionSelect(option) {
        const reconcile = this.get('reconcile');
        const rvdResponse = reconcile.get('reconcileRVDResponse');
        const driverOptions = reconcile.get('getDriverOptions')(rvdResponse, option);
        this.set('driverOptions', driverOptions);
        Ember.set(this, 'selectedDriver', option);
        this.setDriverFields(option);
      },

      onChangeTime(valuePath, value) {
        const valueTime = (0, _moment.default)(value[0]).format('hh:mm');
        const selectedDate = (0, _moment.default)(this.get('reconcileTripDate')).format('MMM D, YYYY');
        const momentTime = (0, _moment.default)(selectedDate + ' ' + valueTime);
        this.set(valuePath, momentTime.toISOString());
        this.calculateShiftLength();
      },

      onRadioButtonClick(val) {
        this.set('selectedRadioValue', val);
      },

      onChangeStartLocation(record) {
        this.set('selectedStartLocation', record);
      },

      onChangeEndLocation(record) {
        this.set('selectedEndLocation', record);
      },

      onZoneOptionsSelect(isStart, option) {
        if (isStart) {
          Ember.set(this, 'selectedStartZone', option);
        } else {
          Ember.set(this, 'selectedEndZone', option);
        }
      },

      async saveRouteSuccess() {
        Ember.set(this, 'workspace.activeContext.topActiveContext.resetLocation', true);
        const reconcile = this.get('reconcile');
        const response = await this.reconcileLambda.getRouteVehicleDriversByTripDate.perform();
        const reconcileRouteMap = (0, _lodash.groupBy)(response.map(rvd => rvd.route), 'providername');
        reconcile.set('reconcileRouteMap', reconcileRouteMap);
        reconcile.set('reconcileRVDResponse', response);
      }

    }
  });

  _exports.default = _default;
});