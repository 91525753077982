define("adept-iq/pods/components/rider-management-widget/rider-certification/verification/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['rider-relationship-options-select'],
    store: Ember.inject.service(),
    rider: Ember.inject.service(),
    verificationMethodOptions: null,

    init() {
      this._super(...arguments);

      this.setProperties({
        verificationMethodOptions: ['By Mail', 'By Phone', 'By Email', 'In Person']
      });
    }

  });

  _exports.default = _default;
});