define("adept-iq/pods/components/side-drawer/reconcile-edit-trip/component", ["exports", "moment", "adept-iq/pods/components/side-drawer/reconcile-edit-trip/stubs", "ember-concurrency"], function (_exports, _moment, _stubs, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PCA = 'pca';
  const SERVICE_ANIMAL = 'serviceanimal';

  var _default = Ember.Component.extend({
    classNames: ['reconcile-widget-base'],
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    stubs: _stubs.default,
    onCloseClick: null,
    serviceModeTaxi: 'Taxi',
    travelNeedsOptionsCount: null,
    isEnableCheckbox: false,
    defaultPca: null,
    defaultServiceAnimal: null,
    reconcileStopPointMap: Ember.computed.alias('reconcile.reconcileStopPointMap'),
    selectedTripId: Ember.computed.alias('reconcile.reconcileSelectedTripId'),
    selectedDate: Ember.computed.alias('reconcile.reconcileDate'),
    reconcileMode: Ember.computed.alias('reconcile.reconcileMode'),
    tripDetails: Ember.computed.alias('reconcile.selectedTripRow'),
    passengerDetails: Ember.computed.alias('reconcile.selectedTripRow.riderDetails'),
    reconcilePrice: Ember.computed.alias('reconcile.reconcilePrice'),
    pickupAddress: Ember.computed.alias('reconcile.reconcilePickupAddress'),
    dropoffAddress: Ember.computed.alias('reconcile.reconcileDropOffAddress'),
    pickupStop: Ember.computed.alias('reconcile.reconcilePickupStop'),
    dropoffStop: Ember.computed.alias('reconcile.reconcileDropOffStop'),
    callBack: Ember.computed.readOnly('stackFrame.options.action'),
    selectedTrip: Ember.computed.alias('stackFrame.options.trip'),
    isEditStart: Ember.computed.alias('isEditingStart'),
    reconcileRouteStartTime: Ember.computed.readOnly('reconcile.reconcileRouteDetails.actualStartTime'),
    reconcileRouteEndTime: Ember.computed.readOnly('reconcile.reconcileRouteDetails.actualEndTime'),
    canPlus: Ember.computed('travelNeedsOptions', 'travelNeedsOptionsCount', function () {
      return Ember.get(this, 'travelNeedsOptionsCount') < Ember.get(this, 'travelNeedsOptions').length;
    }),
    pickupArriveTime: Ember.computed('pickupStop', function () {
      return Ember.get(this, 'pickupStop.actualArriveTime');
    }),
    dropoffArriveTime: Ember.computed('dropoffStop', function () {
      return Ember.get(this, 'dropoffStop.actualArriveTime');
    }),
    tripDate: Ember.computed('tripDetails.serviceStartTime', function () {
      const selectedTripDate = Ember.get(this, 'tripDetails.serviceStartTime');

      if (selectedTripDate) {
        return (0, _moment.default)(selectedTripDate).format('MM-DD-YYYY');
      }

      const reconcileDate = Ember.get(this, 'reconcile.reconcileDate');

      if (reconcileDate) {
        return (0, _moment.default)(reconcileDate).format('MM-DD-YYYY');
      }

      return '';
    }),
    anchorOptions: Ember.computed('workspaceData', 'selectedAnchor', function () {
      const options = [];
      const anchorOptionsData = _stubs.default.anchorData;
      anchorOptionsData.map(item => {
        options.push(item);
      });
      return options;
    }),
    companionOptions: Ember.computed('workspaceData', 'selectedNoOfCompanions', function () {
      const options = [];
      const companions = _stubs.default.companions;
      companions.map(item => {
        options.push(item);
      });
      return options;
    }),
    cloneSelectedNeedsOptions: Ember.computed('selectedNeedsOptions', function () {
      const selectedNeedsOptions = this.get('selectedNeedsOptions');

      if (selectedNeedsOptions) {
        return selectedNeedsOptions.map(sno => {
          return Ember.Object.create({ ...sno
          });
        });
      }

      return [];
    }),
    travelNeedOptionsWithFilter: Ember.computed('cloneSelectedNeedsOptions.@each.name', 'travelNeedsOptions', function () {
      const travelNeedsOptions = this.get('travelNeedsOptions').map(e => {
        return e;
      });
      const filteredOptions = travelNeedsOptions.filter(sno => sno.name.toLowerCase() !== PCA && sno.name.toLowerCase() !== SERVICE_ANIMAL);
      const selectedValueInTravelNeeds = this.get('cloneSelectedNeedsOptions') || null;
      const travelNeedOptions = filteredOptions.filter(e => {
        return !selectedValueInTravelNeeds.find(stn => {
          return stn.name.toLowerCase() === e.name.toLowerCase();
        });
      });
      return travelNeedOptions.map(tno => {
        return { ...tno,
          name: tno.name.toUpperCase()
        };
      });
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'shiftTimeRange', {
        pickTime: null,
        dropTime: null,
        startofday: (0, _moment.default)(this.tripDate).startOf('day').toISOString(),
        endofday: (0, _moment.default)(this.tripDate).endOf('day').toISOString()
      });
      const travelNeedsOptions = this.store.peekAll('cs-config-item').filter(config => {
        return config.category === 'config-System_Configuration-travel_need_types';
      });
      travelNeedsOptions.map(option => {
        return option.data.displayName;
      });
      this.set('travelNeedsOptions', travelNeedsOptions);
      this.setDefaultTravelNeeds();
      this.updateValues();
      this.set('defaultPca', this.get('isEnablePCA'));
      this.set('defaultServiceAnimal', this.get('isServiceAnimalEnable'));
      this.defaultSelectedTravelNeeds();
    },

    didInsertElement() {
      this._super(...arguments);

      this.setSideDrawerWidth('470px');
      this.get('systemConfig').set('showRightPanel', true);
    },

    defaultSelectedTravelNeeds() {
      const travelNeedDetails = this.get('tripDetails.eventAttribute.attribute');

      if (travelNeedDetails) {
        const travelNeed = travelNeedDetails.map(e => {
          return {
            name: e.type,
            cnt: null
          };
        });
        this.set('selectedTravelNeedsForPayload', travelNeed);
      } else {
        this.set('selectedTravelNeedsForPayload', []);
      }
    },

    getSelectedPickOrDropAddress(type) {
      const reconcileStopPointMap = this.get('reconcileStopPointMap');
      const selectedTripId = this.get('selectedTripId');
      return reconcileStopPointMap[selectedTripId].find(ele => ele.type.toLowerCase() === type);
    },

    defaultPcaAndServiceAnimalValue(atrType) {
      if (this.get('defaultServiceAnimal') !== null && this.get('defaultPca') !== null) {
        return atrType === PCA ? this.get('defaultPca') : this.get('defaultServiceAnimal');
      }

      const tripDetails = this.get('tripDetails.eventAttribute.attribute') || [];
      const isEnable = tripDetails.find(atr => atr.type.toLowerCase() === atrType);

      if (isEnable) {
        return true;
      }

      return false;
    },

    updateValues() {
      const passDetails = this.get('passengerDetails');
      const pickupNotes = this.get('pickupAddress.notes') || '';
      const dropoffNotes = this.get('dropoffAddress.notes') || '';
      const tripDetails = this.get('tripDetails');
      const pickAddress = this.getSelectedPickOrDropAddress('pick');
      const dropAddress = this.getSelectedPickOrDropAddress('drop');
      this.setProperties({
        isEditingStart: false,
        selectedAnchor: {
          id: 'pick',
          'name': 'Pick'
        },
        selectedNoOfCompanions: {
          id: tripDetails.cmp,
          name: tripDetails.cmp,
          value: tripDetails.cmp
        },
        passengerDetails: passDetails,
        isDisableDatePicker: true,
        fareValue: this.get('tripDetails').tripPayment.fare,
        pickNotes: pickupNotes,
        dropNotes: dropoffNotes,
        loadTime: passDetails ? passDetails.loadTime : null,
        unloadTime: passDetails ? passDetails.unloadTime : null,
        isEnablePCA: this.defaultPcaAndServiceAnimalValue(PCA),
        isServiceAnimalEnable: this.defaultPcaAndServiceAnimalValue(SERVICE_ANIMAL),
        selectedPickLocation: {
          freeformAddress: pickAddress ? pickAddress.address.formattedaddress : '',
          lat: pickAddress ? pickAddress.address.latitude : '',
          lng: pickAddress ? pickAddress.address.longitude : ''
        },
        selectedDropLocation: {
          freeformAddress: dropAddress ? dropAddress.address.formattedaddress : '',
          lat: dropAddress ? dropAddress.address.latitude : '',
          lng: dropAddress ? dropAddress.address.longitude : ''
        }
      });
      this.calculateFare();
    },

    setDefaultTravelNeeds() {
      const travelNeedDetails = this.get('tripDetails.eventAttribute.attribute');

      if (travelNeedDetails) {
        const travelNeeds = travelNeedDetails.map(e => {
          return {
            name: e.type,
            cnt: null
          };
        });
        this.setProperties({
          selectedNoOfCompanions: {
            id: 0,
            name: 0,
            value: 0
          },
          selectedNeedsOptions: this.convertToEmberObject(travelNeeds)
        });
      } else {
        this.set('cloneSelectedNeedsOptions', []);
      }
    },

    convertToEmberObject(travelNeeds) {
      return travelNeeds.filter(tn => {
        return tn.name.toLowerCase() !== PCA && tn.name.toLowerCase() !== SERVICE_ANIMAL;
      }).map(e => {
        return Ember.Object.create(e);
      });
    },

    calculateFare() {
      let fare = 0;
      const companioncount = this.get('selectedNoOfCompanions').value ? this.get('selectedNoOfCompanions').value : 1;
      const responseFareAmount = this.get('fareValue');
      fare += companioncount * responseFareAmount;
      Ember.set(this, 'expectedFare', `$${fare}`);
      return fare;
    },

    buildArriveAndDepartEventResForPayload(pickTripRes, dropTripRes, reconcileDate) {
      return [{
        'id': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.id : '',
        'routeId': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.routeid : '',
        'driverId': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.driverid : '',
        'vehicleId': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.vehicleid : '',
        'scheduleId': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.scheduleid : '',
        'stopPointId': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.stoppointid : null,
        'odo': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.odo : null,
        'tripId': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.tripid : null,
        'eventType': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.eventtype : null,
        'actionType': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.actiontype : null,
        'speed': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.speed : null,
        'heading': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.heading : null,
        'createdAt': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.createdat : pickTripRes.createdAt,
        'updatedAt': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.updatedat : pickTripRes.updatedAt,
        'receivedAt': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.receivedat : '',
        'dbVersion': null,
        'op$1': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.__op : '',
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': pickTripRes.arriveEvent ? pickTripRes.arriveEvent.reconciliationnotes : null
      }, {
        'id': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.id : '',
        'routeId': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.routeid : '',
        'driverId': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.driverid : '',
        'vehicleId': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.vehicleid : '',
        'scheduleId': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.scheduleid : '',
        'stopPointId': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.stoppointid : null,
        'odo': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.odo : null,
        'tripId': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.tripid : null,
        'eventType': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.eventtype : null,
        'actionType': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.actiontype : null,
        'speed': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.speed : null,
        'heading': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.heading : null,
        'createdAt': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.createdat : dropTripRes.createdAt,
        'updatedAt': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.updatedat : dropTripRes.updatedAt,
        'receivedAt': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.receivedat : '',
        'dbVersion': null,
        'op$1': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.__op : '',
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': dropTripRes.arriveEvent ? dropTripRes.arriveEvent.reconciliationnotes : null
      }, {
        'id': pickTripRes.departEvent ? pickTripRes.departEvent.id : '',
        'routeId': pickTripRes.departEvent ? pickTripRes.departEvent.routeid : '',
        'driverId': pickTripRes.departEvent ? pickTripRes.departEvent.driverid : '',
        'vehicleId': pickTripRes.departEvent ? pickTripRes.departEvent.vehicleid : '',
        'scheduleId': pickTripRes.departEvent ? pickTripRes.departEvent.scheduleid : '',
        'stopPointId': pickTripRes.departEvent ? pickTripRes.departEvent.stoppointid : null,
        'odo': pickTripRes.departEvent ? pickTripRes.departEvent.odo : null,
        'tripId': pickTripRes.departEvent ? pickTripRes.departEvent.tripid : null,
        'eventType': pickTripRes.departEvent ? pickTripRes.departEvent.eventtype : null,
        'actionType': pickTripRes.departEvent ? pickTripRes.departEvent.actiontype : null,
        'speed': pickTripRes.departEvent ? pickTripRes.departEvent.speed : null,
        'heading': pickTripRes.departEvent ? pickTripRes.departEvent.heading : null,
        'createdAt': pickTripRes.departEvent ? pickTripRes.departEvent.createdat : pickTripRes.createdAt,
        'updatedAt': pickTripRes.departEvent ? pickTripRes.departEvent.updatedat : pickTripRes.updatedAt,
        'receivedAt': pickTripRes.departEvent ? pickTripRes.departEvent.receivedat : '',
        'dbVersion': null,
        'op$1': pickTripRes.departEvent ? pickTripRes.departEvent.__op : '',
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': pickTripRes.departEvent ? pickTripRes.departEvent.reconciliationnotes : null
      }, {
        'id': dropTripRes.departEvent ? dropTripRes.departEvent.id : '',
        'routeId': dropTripRes.departEvent ? dropTripRes.departEvent.routeid : '',
        'driverId': dropTripRes.departEvent ? dropTripRes.departEvent.driverid : '',
        'vehicleId': dropTripRes.departEvent ? dropTripRes.departEvent.vehicleid : '',
        'scheduleId': dropTripRes.departEvent ? dropTripRes.departEvent.scheduleid : '',
        'stopPointId': dropTripRes.departEvent ? dropTripRes.departEvent.stoppointid : null,
        'odo': dropTripRes.departEvent ? dropTripRes.departEvent.odo : null,
        'tripId': dropTripRes.departEvent ? dropTripRes.departEvent.tripid : null,
        'eventType': dropTripRes.departEvent ? dropTripRes.departEvent.eventtype : null,
        'actionType': dropTripRes.departEvent ? dropTripRes.departEvent.actiontype : null,
        'speed': dropTripRes.departEvent ? dropTripRes.departEvent.speed : null,
        'heading': dropTripRes.departEvent ? dropTripRes.departEvent.heading : null,
        'createdAt': dropTripRes.departEvent ? dropTripRes.departEvent.createdat : dropTripRes.createdAt,
        'updatedAt': dropTripRes.departEvent ? dropTripRes.departEvent.updatedat : dropTripRes.updatedAt,
        'receivedAt': dropTripRes.departEvent ? dropTripRes.departEvent.receivedat : '',
        'dbVersion': null,
        'op$1': dropTripRes.departEvent ? dropTripRes.departEvent.__op : '',
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': dropTripRes.departEvent ? dropTripRes.departEvent.reconciliationnotes : null
      }];
    },

    buildStartAddressResForPayload(pickAddress, tripResponse, reconcileDate) {
      return {
        'id': tripResponse.address.id,
        'streetName': pickAddress.streetAddress || tripResponse.address.streetname,
        'streetNumber': pickAddress.streetNumber || tripResponse.address.streetnumber,
        'postalCode': pickAddress.postalCode || tripResponse.address.postalcode,
        'locality': pickAddress.locality || tripResponse.address.locality,
        'region': pickAddress.region || tripResponse.address.region,
        'latitude': pickAddress.lat || tripResponse.address.latitude,
        'longitude': pickAddress.lng || tripResponse.address.longitude,
        'formattedAddress': pickAddress.freeformAddress || tripResponse.address.formattedaddress,
        'zoneId': tripResponse.address.zoneid,
        'createdAt': tripResponse.address.createdat || tripResponse.createdAt,
        'updatedAt': tripResponse.address.updatedat || tripResponse.updatedAt,
        'op$1': tripResponse.address.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': tripResponse.address.reconciliationnotes
      };
    },

    buildEndAddressResForPayload(dropAddress, tripResponse, reconcileDate) {
      return {
        'id': tripResponse.address.id,
        'streetName': dropAddress.streetAddress || tripResponse.address.streetname,
        'streetNumber': dropAddress.streetNumber || tripResponse.address.streetnumber,
        'postalCode': dropAddress.postalCode || tripResponse.address.postalcode,
        'locality': dropAddress.locality || tripResponse.address.locality,
        'region': dropAddress.region || tripResponse.address.region,
        'latitude': dropAddress.lat || tripResponse.address.latitude,
        'longitude': dropAddress.lng || tripResponse.address.longitude,
        'formattedAddress': dropAddress.freeformAddress || tripResponse.address.formattedaddress,
        'zoneId': tripResponse.address.zoneid,
        'createdAt': tripResponse.address.createdat || tripResponse.createdAt,
        'updatedAt': tripResponse.address.updatedat || tripResponse.updatedAt,
        'op$1': tripResponse.address.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': tripResponse.address.reconciliationnotes
      };
    },

    buildRiderEventResForPayload(pickTripRes, dropTripRes, reconcileDate) {
      return [{
        'id': pickTripRes.riderEvent.id,
        'stopPointId': pickTripRes.riderEvent.stoppointid,
        'scheduledTime': this.get('shiftTimeRange.pickTime') || pickTripRes.riderEvent.scheduledtime,
        'type': pickTripRes.riderEvent.type,
        'riderId': pickTripRes.riderEvent.riderid,
        'anchor': pickTripRes.riderEvent.anchor,
        'promisedTime': pickTripRes.riderEvent.promisedtime,
        'serviceStartTime': this.get('shiftTimeRange.pickTime') || pickTripRes.riderEvent.servicestarttime,
        'serviceEndTime': this.get('shiftTimeRange.dropTime') || pickTripRes.riderEvent.serviceendtime,
        'deleted': pickTripRes.riderEvent.deleted,
        'scheduleId': pickTripRes.scheduleId,
        'createdAt': pickTripRes.riderEvent.createdat || pickTripRes.createdAt,
        'updatedAt': pickTripRes.riderEvent.updatedat || pickTripRes.updatedAt,
        'dbVersion': null,
        'op$1': pickTripRes.riderEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': pickTripRes.riderEvent.reconciliationnotes
      }, {
        'id': dropTripRes.riderEvent.id,
        'stopPointId': dropTripRes.riderEvent.stoppointid,
        'scheduledTime': this.get('shiftTimeRange.dropTime') || dropTripRes.riderEvent.scheduledtime,
        'type': dropTripRes.riderEvent.type,
        'riderId': dropTripRes.riderEvent.riderid,
        'anchor': dropTripRes.riderEvent.anchor,
        'promisedTime': dropTripRes.riderEvent.promisedtime,
        'serviceStartTime': this.get('shiftTimeRange.pickTime') || dropTripRes.riderEvent.servicestarttime,
        'serviceEndTime': this.get('shiftTimeRange.dropTime') || dropTripRes.riderEvent.serviceendtime,
        'deleted': dropTripRes.riderEvent.deleted,
        'scheduleId': dropTripRes.scheduleId,
        'createdAt': dropTripRes.riderEvent.createdat || dropTripRes.createdAt,
        'updatedAt': dropTripRes.riderEvent.updatedat || dropTripRes.updatedAt,
        'dbVersion': null,
        'op$1': dropTripRes.riderEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': dropTripRes.riderEvent.reconciliationnotes
      }];
    },

    buildAttributeResForPayload(tripResponse, reconcileDate) {
      const selectedTravelNeedsForPayload = this.get('selectedTravelNeedsForPayload');

      if (selectedTravelNeedsForPayload) {
        return selectedTravelNeedsForPayload.map(atr => {
          return {
            'id': atr.id || '',
            'type': atr.name,
            'createdAt': atr.createdat || tripResponse.createdAt,
            'updatedAt': atr.updatedat || tripResponse.updatedAt,
            'dbVersion': null,
            'op$1': atr.__op || '',
            'deleted$1': false,
            'reconcileDate': reconcileDate,
            'isReconciled': true,
            'reconciliationNotes': atr.reconciliationnotes
          };
        });
      }

      return [{
        'id': '',
        'type': '',
        'createdAt': tripResponse.createdAt,
        'updatedAt': tripResponse.updatedAt,
        'dbVersion': null,
        'op$1': '',
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': ''
      }];
    },

    buildStopPointResForPayload(pickTripRes, dropTripRes, reconcileDate) {
      return [{
        'id': pickTripRes.stopPointId,
        'tripId': pickTripRes.tripId,
        'routeIndex': null,
        'addressId': pickTripRes.address.id,
        'clusterId': null,
        'eta': pickTripRes.eta,
        'dwellTime': null,
        'clusterIndex': null,
        'scheduleId': pickTripRes.scheduleId,
        'status': pickTripRes.status,
        'createdAt': pickTripRes.createdAt || pickTripRes.createdAt,
        'updatedAt': pickTripRes.updatedAt || pickTripRes.updatedAt,
        'dbVersion': null,
        'op$1': pickTripRes.riderEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      }, {
        'id': dropTripRes.stopPointId,
        'tripId': dropTripRes.tripId,
        'routeIndex': null,
        'addressId': dropTripRes.address.id,
        'clusterId': null,
        'eta': dropTripRes.eta,
        'dwellTime': null,
        'clusterIndex': null,
        'scheduleId': dropTripRes.scheduleId,
        'status': dropTripRes.status,
        'createdAt': dropTripRes.createdAt || dropTripRes.createdAt,
        'updatedAt': dropTripRes.updatedAt || dropTripRes.updatedAt,
        'dbVersion': null,
        'op$1': dropTripRes.riderEvent.__op,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': 'reconciled'
      }];
    },

    buildRouteResFromRVDForPayload(rvdResponse, tripResponse, reconcileDate) {
      return {
        'deleted$1': false,
        'endAddressId': rvdResponse.route.endaddressid,
        'startAddressId': rvdResponse.route.startaddressid,
        'version': null,
        'op$1': rvdResponse.route.__op,
        'createdAt': rvdResponse.route.createdat || tripResponse.createdAt,
        'plannedStartTime': this.get('shiftTimeRange.pickTime') || rvdResponse.route.plannedstarttime,
        'plannedEndTime': this.get('shiftTimeRange.dropTime') || rvdResponse.route.plannedendtime,
        'id': rvdResponse.route.id,
        'providerName': rvdResponse.route.providername,
        'scheduleId': rvdResponse.route.scheduleid,
        'status': rvdResponse.route.status,
        'trackingId': rvdResponse.route.trackingid,
        'updatedAt': rvdResponse.route.updatedat || tripResponse.updatedAt,
        'actualStartTime': rvdResponse.route.actualstarttime,
        'actualEndTime': rvdResponse.route.actualendtime,
        'alertId': rvdResponse.route.alertid,
        'otpValue': rvdResponse.route.otpvalue,
        'otpStatus': rvdResponse.route.otpstatus,
        'reconcileDate': reconcileDate,
        'isReconciled': true,
        'reconciliationNotes': rvdResponse.route.reconciliationnotes
      };
    },

    buildRiderResForPayload(tripResponse, reconcileDate) {
      return {
        'id': tripResponse.riderDetails ? tripResponse.riderDetails.id : null,
        'name': tripResponse.riderDetails ? tripResponse.riderDetails.name : null,
        'firstName': tripResponse.riderDetails ? tripResponse.riderDetails.firstname : null,
        'lastName': tripResponse.riderDetails ? tripResponse.riderDetails.lastname : null,
        'middleName': tripResponse.riderDetails ? tripResponse.riderDetails.middlename : null,
        'passengerTypeName': tripResponse.riderDetails ? tripResponse.riderDetails.passengertypename : null,
        'bookingClientId': tripResponse.riderDetails ? tripResponse.riderDetails.bookingclientid : null,
        'tripId': tripResponse.riderDetails ? tripResponse.riderDetails.tripId : null,
        'providerName': tripResponse.riderDetails ? tripResponse.riderDetails.providername : null,
        'op$1': tripResponse.riderDetails ? tripResponse.riderDetails.__op || '' : '',
        'createdAt': tripResponse.riderDetails ? tripResponse.riderDetails.createdat : tripResponse.createdAt,
        'updatedAt': tripResponse.riderDetails ? tripResponse.riderDetails.updatedat : tripResponse.updatedAt,
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true
      };
    },

    buildTripResForPayload(tripResponse, reconcileDate) {
      return {
        'id': tripResponse.tripId,
        'routeId': tripResponse.routeId,
        'status': tripResponse.status,
        'externalId': tripResponse.externalTripId,
        'scheduleId': tripResponse.scheduleId,
        'createdAt': tripResponse.createdAt || tripResponse.createdAt,
        'updatedAt': tripResponse.updatedAt || tripResponse.updatedAt,
        'op$1': tripResponse.tripPayment.__op || '',
        'deleted$1': false,
        'reconcileDate': reconcileDate,
        'isReconciled': true
      };
    },

    buildEventAttributesForPayload(pickTripRes, dropTripRes, reconcileDate) {
      return [{
        'id': pickTripRes.eventAttribute ? pickTripRes.eventAttribute.eventid : '',
        'eventId': pickTripRes.eventAttribute ? pickTripRes.eventAttribute.eventid : '',
        'attributeId': pickTripRes.eventAttribute ? pickTripRes.eventAttribute.attributeid : '',
        'attributeCount': pickTripRes.eventAttribute ? pickTripRes.eventAttribute.attributecount : '',
        'scheduleId': pickTripRes.eventAttribute ? pickTripRes.eventAttribute.scheduleid : pickTripRes.arriveEvent.scheduleid,
        'createdAt': pickTripRes.eventAttribute ? pickTripRes.eventAttribute.createdat : pickTripRes.createdAt,
        'updatedAt': pickTripRes.eventAttribute ? pickTripRes.eventAttribute.updatedat : pickTripRes.updatedAt,
        'deletedAt': null,
        'deleted$1': false,
        'dbVersion': null,
        'op$1': pickTripRes.eventAttribute ? pickTripRes.eventAttribute.__op || '' : '',
        'reconcileDate': reconcileDate,
        'isReconciled': true
      }, {
        'id': dropTripRes.eventAttribute ? dropTripRes.eventAttribute.eventid : '',
        'eventId': dropTripRes.eventAttribute ? dropTripRes.eventAttribute.eventid : '',
        'attributeId': dropTripRes.eventAttribute ? dropTripRes.eventAttribute.attributeid : '',
        'attributeCount': dropTripRes.eventAttribute ? dropTripRes.eventAttribute.attributecount : '',
        'scheduleId': dropTripRes.eventAttribute ? dropTripRes.eventAttribute.scheduleid : dropTripRes.arriveEvent.scheduleid,
        'createdAt': dropTripRes.eventAttribute ? dropTripRes.eventAttribute.createdat : dropTripRes.createdAt,
        'updatedAt': dropTripRes.eventAttribute ? dropTripRes.eventAttribute.updatedat : dropTripRes.updatedAt,
        'deletedAt': null,
        'deleted$1': false,
        'dbVersion': null,
        'op$1': dropTripRes.eventAttribute ? dropTripRes.eventAttribute.__op || '' : '',
        'reconcileDate': reconcileDate,
        'isReconciled': true
      }];
    },

    buildReconcileTripPayload() {
      const reconcileRouteInfo = this.get('reconcile.reconcileRouteInfo');
      const reconcileRVDResponse = this.get('reconcile.reconcileRVDResponse').find(route => route.routeid === parseInt(reconcileRouteInfo.routeid, 10));
      const selectedTripRow = this.get('reconcile.lastRowClickedInTripTable');
      const reconcileDate = new Date().toISOString();
      const pickAddress = this.get('selectedPickLocation');
      const dropAddress = this.get('selectedDropLocation');
      const pickTripRes = this.get('reconcile.reconcileTripDetails').find(res => res.tripId === selectedTripRow.tripId && res.type.toLowerCase() === 'pick');
      const dropTripRes = this.get('reconcile.reconcileTripDetails').find(res => res.tripId === selectedTripRow.tripId && res.type.toLowerCase() === 'drop');
      return {
        'events': this.buildArriveAndDepartEventResForPayload(pickTripRes, dropTripRes, reconcileDate),
        'address': [this.buildStartAddressResForPayload(pickAddress, pickTripRes, reconcileDate), this.buildEndAddressResForPayload(dropAddress, dropTripRes, reconcileDate)],
        'riderEvents': this.buildRiderEventResForPayload(pickTripRes, dropTripRes, reconcileDate),
        'attributes': this.buildAttributeResForPayload(selectedTripRow, reconcileDate),
        'stopPoint': this.buildStopPointResForPayload(pickTripRes, dropTripRes, reconcileDate),
        'rider': this.buildRiderResForPayload(selectedTripRow, reconcileDate),
        'route': this.buildRouteResFromRVDForPayload(reconcileRVDResponse, selectedTripRow, reconcileDate),
        'trip': this.buildTripResForPayload(selectedTripRow, reconcileDate),
        'eventAttributes': this.buildEventAttributesForPayload(pickTripRes, dropTripRes, reconcileDate)
      };
    },

    editTripTask: (0, _emberConcurrency.task)(function* () {
      this.send('onRefreshFare');
      const reconcile = this.get('reconcile');
      const payload = this.buildReconcileTripPayload();

      try {
        const putTripResponse = yield reconcile.updateReconcileTripsInfo(payload);
        return putTripResponse;
      } catch (e) {
        throw e;
      }
    }),

    showToolTip(title, tip, successMsg) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('editTripTask').perform().then(() => {
            this.send('cancel');
            this.get('workspace').popState('reconcileEditAdditionalTripInfo');
            tooltip.reset();
            this.get('notifications').success(successMsg);
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in edit trip, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    validateLoadUnloadTime(type, value) {
      if (value > 15) {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'Edit Trip',
          tip: type + ' Time cannot be greater than 15 minutes',
          primaryActionText: 'Ok',
          primaryAction: () => {
            this.resetLoadUnloadValues(type, '');
            return tooltip.reset();
          },
          closeAction: () => {
            this.resetLoadUnloadValues(type, '');
            return tooltip.reset();
          },
          hasOverlay: true
        });
      } else {
        this.resetLoadUnloadValues(type, value);
      }
    },

    resetLoadUnloadValues(type, value) {
      if (type === 'Load') {
        Ember.set(this, 'loadTime', value);
      } else {
        Ember.set(this, 'unloadTime', value);
      }
    },

    unloadCurrentTrip() {
      if (this.reconcileMode === this.serviceModeTaxi) {
        const record = this.store.peekRecord('reconcile-trip', this.tripDetails.get('tripId'));

        if (record) {
          this.store.unloadRecord(record);
          Ember.set(this, 'reconcile.reconcileSelectedTripId', null);
        }
      }
    },

    checkValidations() {
      if (this.reconcileMode !== this.serviceModeTaxi) {
        if (this.get('shiftTimeRange.pickTime') && this.get('shiftTimeRange.dropTime')) {
          if (!_moment.default.utc(this.get('shiftTimeRange.dropTime')).isAfter(_moment.default.utc(this.get('shiftTimeRange.pickTime')))) {
            Ember.set(this, 'errorMessage', 'Pickup time can not be greater than or equal to dropoff time');
            return false;
          }

          if (_moment.default.utc(this.reconcileRouteStartTime).isAfter(_moment.default.utc(this.get('shiftTimeRange.pickTime')))) {
            Ember.set(this, 'errorMessage', 'Pickup time can not be less than route start time');
            return false;
          }

          if (_moment.default.utc(this.get('shiftTimeRange.dropTime')).isAfter(_moment.default.utc(this.reconcileRouteEndTime))) {
            Ember.set(this, 'errorMessage', 'Dropoff time can not be greater than route end time');
            return false;
          }
        }
      }

      const selectedGroupOptions = Ember.get(this, 'cloneSelectedNeedsOptions').filter(item => item.selected);

      if (selectedGroupOptions) {
        const nameArray = selectedGroupOptions.map(item => item.selected.id);
        const isDuplicate = nameArray.some((item, idx) => nameArray.indexOf(item) !== idx);

        if (isDuplicate) {
          Ember.set(this, 'errorMessage2', 'Duplicate travel needs option selected');
          return false;
        }
      }

      return true;
    },

    generateRandomId() {
      return Math.floor(Math.random() * 10000);
    },

    buildPcaAndServiceAnimalTravelNeedForPayload(travelNeedType, checkBoxCheckedValue) {
      const passengerTravelNeeds = this.get('selectedTravelNeedsForPayload') || [];
      const foundTravelNeed = passengerTravelNeeds.find(travelNeed => travelNeed.name.toLowerCase() === travelNeedType);

      if (checkBoxCheckedValue && foundTravelNeed) {
        return;
      }

      if (checkBoxCheckedValue && !foundTravelNeed) {
        passengerTravelNeeds.pushObject({
          id: this.generateRandomId(),
          name: travelNeedType.toUpperCase(),
          cnt: Ember.get(this, 'travelNeedsOptionsCount')
        });
        return;
      }

      passengerTravelNeeds.removeObject(foundTravelNeed);
    },

    actions: {
      onAnchorSelect(option) {
        if (this.get('shiftTimeRange.pickTime') === null || this.get('shiftTimeRange.dropTime') === null) {
          this.set('isDisableDatePicker', false);
        }

        Ember.set(this, 'selectedAnchor', option);
      },

      onServiceNeedsSelect(option) {
        Ember.set(this, 'selectedNeedsOptions', option);
      },

      onSelectCompanion(option) {
        Ember.set(this, 'selectedNoOfCompanions', option);
        Ember.set(this, 'isEditingStart', true);
      },

      onSelectTravelNeeds(index, option) {
        Ember.set(this, 'isEditingStart', true);
        const travelNeedsGroup = Ember.get(this, 'cloneSelectedNeedsOptions')[index];
        Ember.set(travelNeedsGroup, 'selected', option);
      },

      onUndoClick() {
        const pickAddress = this.get('selectedPickLocation');
        const dropAddress = this.get('selectedDropLocation');

        if (pickAddress || dropAddress) {
          Ember.set(this, 'canReset', true);
        }

        Ember.set(this, 'isEditingStart', false);
        this.updateValues();
        this.set('shiftTimeRange.pickTime', null);
        this.set('shiftTimeRange.dropTime', null);
        this.setDefaultTravelNeeds();
        this.defaultSelectedTravelNeeds();
      },

      onSaveClick() {
        const isValid = this.checkValidations();

        if (!isValid) {
          return;
        }

        Ember.set(this, 'errorMessage', null);
        Ember.set(this, 'errorMessage2', null);
        const tripDetails = this.get('tripDetails');
        const title = 'Edit Trip';
        const tip = 'Would you like to commit your changes?';
        const successMsg = 'TRIP ' + tripDetails.tripId + ' EDITED SUCCESSFULLY';
        this.showToolTip(title, tip, successMsg);
      },

      onTimeChange(valuePath, value) {
        Ember.set(this, 'isEditingStart', true);
        const valueTime = (0, _moment.default)(value[0]).format('hh:mm A');
        const selectedDate = (0, _moment.default)(this.get('tripDate')).format('MMM D, YYYY');
        const momentTime = (0, _moment.default)(selectedDate + ' ' + valueTime);
        this.set(valuePath, momentTime.toISOString());
      },

      onChangePickLocation(record) {
        Ember.set(this, 'isEditingStart', true);
        this.set('selectedPickLocation', record);
      },

      onChangeDropLocation(record) {
        Ember.set(this, 'isEditingStart', true);
        this.set('selectedDropLocation', record);
      },

      onPcaChanged() {
        Ember.set(this, 'isEditingStart', true);
        this.toggleProperty('isEnablePCA');
        this.buildPcaAndServiceAnimalTravelNeedForPayload(PCA, this.get('isEnablePCA'));
      },

      onDeleteTravelNeeds(selectedNeed) {
        Ember.set(this, 'isEditingStart', true);
        const selectedNeedsOptions = this.get('cloneSelectedNeedsOptions');
        const foundTravelNeed = selectedNeedsOptions.find(sno => sno.name === selectedNeed.name || null);
        selectedNeedsOptions.removeObject(foundTravelNeed);
        Ember.set(this, 'travelNeedsOptionsCount', Ember.get(this, 'travelNeedsOptionsCount'));
      },

      oninputTextChange(type, value) {
        Ember.set(this, 'isEditingStart', true);

        switch (type) {
          case _stubs.default.PICK_NOTES:
            Ember.set(this, 'pickNotes', value);
            break;

          case _stubs.default.DROP_NOTES:
            Ember.set(this, 'dropNotes', value);
            break;

          case _stubs.default.LOAD_TIME:
            this.validateLoadUnloadTime('Load', value);
            break;

          case _stubs.default.UNLOAD_TIME:
            this.validateLoadUnloadTime('Unload', value);
            break;

          default:
        }
      },

      onCloseButtonClick() {
        this.onCloseClick();
      },

      addTravelNeedItem() {
        Ember.set(this, 'isEditingStart', true);
        this.get('cloneSelectedNeedsOptions').pushObject(Ember.Object.create({
          cnt: null,
          name: ''
        }));
      },

      onRefreshFare() {
        this.calculateFare();
      },

      cancel() {
        this.unloadCurrentTrip();

        if (this.callBack) {
          this.callBack(true);
        }
      },

      onServiceAnimalChanged() {
        Ember.set(this, 'isEditingStart', true);
        this.toggleProperty('isServiceAnimalEnable');
        this.buildPcaAndServiceAnimalTravelNeedForPayload(SERVICE_ANIMAL, this.get('isServiceAnimalEnable'));
      },

      onTravelNeedOptionSelect(valuePath, value) {
        this.set('isEditingStart', true);
        valuePath.set('name', value.name);
        this.get('selectedTravelNeedsForPayload').push({
          id: this.generateRandomId(),
          cnt: null,
          name: value.name
        });
      }

    }
  });

  _exports.default = _default;
});