define("adept-iq/pods/components/generic-components/table/cells/check-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aCR+UHYi",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n  \"],[7,\"input\"],[11,\"class\",\"can-focus\"],[12,\"disabled\",[21,\"disableRowSelection\"]],[12,\"checked\",[23,[\"row\",\"selected\"]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onToggleCheckbox\"],null]],[11,\"type\",\"checkbox\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/generic-components/table/cells/check-box/template.hbs"
    }
  });

  _exports.default = _default;
});