define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback/loader", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel"], function (_exports, _react, _panel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Loader = props => {
    const {
      message
    } = props;
    const {
      isLoadingRvd
    } = (0, _panel.usePanel)();
    return _react.default.createElement(_react.default.Fragment, null, isLoadingRvd && _react.default.createElement("div", {
      className: "confirm-dialog_rvd",
      style: {
        padding: '5px'
      }
    }, _react.default.createElement("center", null, _react.default.createElement("p", {
      style: {
        color: 'white',
        marginBottom: '8px'
      }
    }, message), _react.default.createElement("i", {
      style: {
        color: 'white'
      },
      className: "fa fa-circle-o-notch fa-spin fa-lg fa-fw"
    }), " ")));
  };

  var _default = Loader;
  _exports.default = _default;
});