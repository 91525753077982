define("adept-iq/data/routeTypeData", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.data = void 0;
  const data = [{
    'id': '1',
    'attributes': {
      'id': 'Verify Route',
      'name': 'Verify Route'
    }
  }, {
    'id': '2',
    'attributes': {
      'id': 'Reopen Route',
      'name': 'Reopen Route'
    }
  }, {
    'id': '3',
    'attributes': {
      'id': 'Report Breakdown',
      'name': 'Report Breakdown'
    }
  }, {
    'id': '4',
    'attributes': {
      'id': 'Breakdown Replacement',
      'name': 'Breakdown Replacement'
    }
  }, {
    'id': '5',
    'attributes': {
      'id': 'Rescue',
      'name': 'Rescue'
    }
  }];
  _exports.data = data;
});