define("adept-iq/pods/components/iq-widgets/view-itinerary-modal/trip-request-details/component", ["exports", "lodash", "moment"], function (_exports, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TIME_FORMAT = 'hh:mm A';

  var _default = Ember.Component.extend({
    startCasedServiceMode: Ember.computed('tripBooking.serviceMode', function () {
      const serviceMode = this.get('tripBooking.serviceMode');
      return (0, _lodash.startCase)(serviceMode);
    }),
    promiseTime: Ember.computed('tripBooking.{promiseTime,travelMode}', function () {
      const promiseTime = this.get('tripBooking.promiseTime');
      return promiseTime ? (0, _moment.default)(promiseTime).format(TIME_FORMAT) : '';
    }),

    init() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});