define("adept-iq/classes/markers/vehicles/road-supervisor", ["exports", "adept-iq/classes/markers/vehicles/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    rsVehicle: Ember.computed.alias('record'),
    // `BaseMarker` overrides
    lat: Ember.computed.readOnly('rsVehicle.lat'),
    lng: Ember.computed.readOnly('rsVehicle.lng'),
    rotationAngle: Ember.computed.readOnly('rsVehicleRotationAngle'),
    // BaseVehicleMarker overrides
    isMoving: Ember.computed.bool('rsVehicle.heading'),
    isRoadSupervisor: true,
    isLoggedInRoadSupervisor: Ember.computed('rsVehicle.id', 'myRSVehicleId', function () {
      if (this.get('rsVehicle.id') === this.get('myRSVehicleId')) {
        return true;
      }

      return false;
    }),
    // local properties
    rsVehicleRotationAngle: Ember.computed('rsVehicle.heading', function () {
      const heading = this.get('rsVehicle.heading');
      if (Ember.isNone(heading)) return null; // TODO: check that this calculation is correct

      return heading / 2;
    }),
    myRSVehicleId: Ember.computed('', function () {
      const dashboardInfo = localStorage.getItem('dashboard-info');
      if (!dashboardInfo) return null;

      try {
        const parsedDashboardInfo = JSON.parse(dashboardInfo);

        if (parsedDashboardInfo && parsedDashboardInfo.accessData) {
          return parsedDashboardInfo.accessData.vehicleId;
        }
      } catch (e) {
        // malformed JSON
        return null;
      }

      return null;
    })
  });

  _exports.default = _default;
});