define("adept-iq/data/driverData", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.data = void 0;
  const data = [{
    'id': '1',
    'attributes': {
      'id': 7591
    }
  }, {
    'id': '2',
    'attributes': {
      'id': 7592
    }
  }, {
    'id': '3',
    'attributes': {
      'id': 7593
    }
  }];
  _exports.data = data;
});