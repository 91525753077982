define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/maintenance/maintenance-validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALIDATIONS = void 0;
  const VALIDATIONS = {
    mileage: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Mileage',
      message: '{description} is required.'
    })],
    maintenanceDate: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Date',
      message: '{description} is required.'
    })],
    acceptedDate: [(0, _validators.validatePresence)({
      presence: true,
      on: 'accepted',
      description: 'Fix Accepted Date',
      message: '{description} is required when Fix Accepted is selected.'
    })]
  };
  _exports.VALIDATIONS = VALIDATIONS;
});