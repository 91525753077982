define("adept-iq/models/reconcile-passenger", ["exports", "ember-data", "moment", "adept-iq/utils/format-text-extension"], function (_exports, _emberData, _moment, _formatTextExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  const DATE_FORMAT = 'MM/DD/YYYY';

  var _default = Model.extend({
    passengerId: attr('number'),
    status: attr('string'),
    firstName: attr('string'),
    middleName: attr('string'),
    lastName: attr('string'),
    type: attr('string'),
    email: attr('string'),
    externalId: attr('number'),
    phoneNumber: attr('string'),
    tripId: attr('number'),
    dateOfBirth: attr('date'),
    eligibilityStart: attr('date'),
    eligibilityEnd: attr('date'),
    address: attr('string'),
    city: attr('string'),
    travelneeds: attr('object'),
    formattedEligibilityStart: Ember.computed('eligibilityStart', function () {
      return Ember.get(this, 'eligibilityStart') ? (0, _moment.default)(Ember.get(this, 'eligibilityStart')).format(DATE_FORMAT) : '';
    }),
    formttedEligibilityEnd: Ember.computed('eligibilityEnd', function () {
      return Ember.get(this, 'eligibilityEnd') ? (0, _moment.default)(Ember.get(this, 'eligibilityEnd')).format(DATE_FORMAT) : '';
    }),
    formttedDateOfBirth: Ember.computed('dateOfBirth', function () {
      return Ember.get(this, 'dateOfBirth') ? (0, _moment.default)(Ember.get(this, 'dateOfBirth')).format(DATE_FORMAT) : '';
    }),
    // trip: belongsTo('reconcile-trip')
    clientName: Ember.computed('firstName', 'lastName', function () {
      const str = Ember.get(this, 'lastName') + ', ' + Ember.get(this, 'firstName');
      return (0, _formatTextExtension.convertToPascalCase)(str);
    })
  });

  _exports.default = _default;
});