define("adept-iq/serializers/driver-log", ["exports", "adept-iq/serializers/-driver"], function (_exports, _driver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _driver.default.extend({
    serialize() {
      const json = this._super(...arguments);

      if (json.data && json.data.relationships) {
        const providerRelationship = json.data.relationships.provider;

        if (providerRelationship && Ember.isNone(providerRelationship.data)) {
          delete json.data.relationships.provider;
        } else {
          providerRelationship.data.type = 'providerName';
          json.data.relationships.providerName = json.data.relationships.provider;
          delete json.data.relationships.provider;
        }
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      const relationships = resourceHash.relationships ? resourceHash.relationships : {};

      if (relationships.providerName) {
        relationships.provider = {
          data: {
            type: 'provider',
            id: relationships.providerName.data.id
          }
        };
        delete relationships.providerName;
        resourceHash.relationships = relationships;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});