define("adept-iq/pods/components/iq-widgets/view-itinerary-modal/component", ["exports", "moment", "adept-iq/config/itinerary-action"], function (_exports, _moment, _itineraryAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ITP_TRAVEL_MODE = 'itp';
  const TAXI_TRAVEL_MODE = 'taxi';
  const BUS_TYPE = 'bus';
  const PARATRANSIT_TRAVEL_MODE = 'paratransit';
  const TIME_FORMAT = 'hh:mm A';
  const DATE_FORMAT = 'ddd,MM/DD/YYYY';

  var _default = Ember.Component.extend({
    booking: Ember.inject.service(),
    editModal: Ember.inject.service(),
    tripDetails: null,
    returnTripDetails: null,
    returnTripDetailsVisible: false,
    selectedTravelMode: null,
    // eslint-disable-next-line ember/use-brace-expansion
    tripLegsInBooking: Ember.computed('editableRecords.firstObject.legs.[]', 'editableRecords.firstObject.legs', function () {
      const tripLegs = this.get('editableRecords.firstObject.legs').filterBy('purpose', 'trip');
      return tripLegs.sortBy('legOrdinal');
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    returnTripLegsInBooking: Ember.computed('editableRecords.firstObject.legs.[]', 'editableRecords.firstObject.legs', function () {
      const returnTripLegs = this.get('editableRecords.firstObject.legs').filterBy('purpose', 'return');
      return returnTripLegs.sortBy('legOrdinal');
    }),

    init() {
      this._super(...arguments);

      this.getTripAndReturnTripDetails();

      if (!Ember.isEmpty(this.get('returnTripLegsInBooking'))) {
        this.set('returnTripDetailsVisible', true);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.$('.edit-widget-modal').height('0');
    },

    getItpSegmentsForLegs() {
      const legs = this.get('editableRecords.firstObject.legs');
      const itpRequestResult = legs.map((leg, legIndex) => {
        return this.getItpSegmentResults(leg, legIndex);
      });
      return itpRequestResult;
    },

    /**
     *
     * @param {*} leg  (array of objects)
     * @param {*} legIndex (number)
     * @returns itp segments results for itp booking => action,description,etc
     */
    getItpSegmentResults(leg, legIndex) {
      const segments = leg.get('segments').sortBy('id');
      const itineraryLastIndex = segments.length - 1;
      const itineraryFirstIndex = 0;
      const itpSegmentResults = {
        legIndex: legIndex,
        result: {
          segments: segments.map((segment, index) => {
            const isParaTransitTravelMode = segment.get('isParaTransitTravelMode');
            const segmentTrips = segment.get('trips').toArray();
            const pickTripStop = segmentTrips.get('firstObject.pick');
            const dropTripStop = segmentTrips.get('firstObject.drop');
            const segmentPickStop = segment.get('pick');
            const segmentDropStop = segment.get('drop');
            const pickAddress = segmentPickStop.get('place.address');
            const dropAddress = segmentDropStop.get('place.address');
            const pickStop = segment.get('stops.firstObject');
            const dropStop = segment.get('stops.firstObject');
            const startPlannedEta = pickTripStop.get('plannedEta') || segmentPickStop.get('plannedEta');
            const endPlannedEta = dropTripStop.get('plannedEta') || segmentDropStop.get('plannedEta');
            const pickNotes = segment.get('pick.notes');
            const dropNotes = segment.get('drop.notes');
            const startAddress = pickNotes && !isParaTransitTravelMode && index !== itineraryFirstIndex ? pickNotes : pickAddress.get('simpleAddress');
            const endAddress = dropNotes && !isParaTransitTravelMode && index !== itineraryLastIndex ? dropNotes : dropAddress.get('simpleAddress');
            const busDescription = this.getBusDetails(segment.get('travelMode'));
            return {
              action: this.convertTravelModeToAction(segment.get('travelMode')),
              description: busDescription,
              end: {
                coordinates: {
                  lat: dropStop.get('lat'),
                  lng: dropStop.get('lng')
                },
                description: dropNotes,
                id: '',
                time: endPlannedEta,
                type: '',
                address: endAddress
              },
              length: '',
              start: {
                coordinates: {
                  lat: pickStop.get('lat'),
                  lng: pickStop.get('lng')
                },
                description: pickNotes,
                id: '',
                time: startPlannedEta,
                type: '',
                address: startAddress
              },
              promiseTime: segment.get('trips.firstObject.promiseTime')
            };
          })
        }
      };
      return itpSegmentResults;
    },

    convertTravelModeToAction(travelMode) {
      let travelModeInUpperCase = travelMode.toUpperCase();
      const isBusTravelMode = travelMode.match(BUS_TYPE);

      if (isBusTravelMode) {
        travelModeInUpperCase = BUS_TYPE.toUpperCase();
      }

      return parseInt(_itineraryAction.ITINERARY_TRAVEL_MODE[travelModeInUpperCase], 10);
    },

    /**
     *
     * @param segment model
     * @returns string => 'itp' or  'taxi' or 'paratransit'
     */
    convertSegmentToTravelMode(segment) {
      if (segment.isTaxiTravelMode) {
        return TAXI_TRAVEL_MODE;
      }

      return PARATRANSIT_TRAVEL_MODE;
    },

    /**
     *when booking the itp-booking,bus travel mode is saved with bus related information(bus number)
     * @param {*} travelMode
     * @returns string(bus number)
     */
    getBusDetails(travelMode) {
      const isBusTravelMode = travelMode.match(BUS_TYPE); //bus travelmode is saved with bus number

      if (isBusTravelMode) {
        const busDescription = travelMode.split(' ')[1];
        return busDescription;
      }
    },

    /**
      *
      * @param {*} tripDetails(array of objects)
      * @returns trip request details => tripdate,segments,address,etc
      */
    getTripAndReturnTripRequestDetails(tripDetails) {
      const tripRequestDetails = tripDetails.map(tb => {
        const reqTime = tb.get('requestTime');
        const segments = tb.get('segments').sortBy('id');
        const serviceMode = segments.length > 1 ? ITP_TRAVEL_MODE : this.convertSegmentToTravelMode(tb.get('segment'));
        return {
          tripDate: (0, _moment.default)(reqTime).format(DATE_FORMAT),
          requestTime: reqTime,
          formattedRequestTime: (0, _moment.default)(reqTime).format(TIME_FORMAT),
          promiseTime: tb.get('sortedSegment.trips.firstObject.promiseTime'),
          travelMode: tb.get('segment.travelMode'),
          serviceMode: serviceMode,
          segments: segments.map(segment => {
            const pickAddress = segment.get('pick.place.address');
            const dropAddress = segment.get('drop.place.address');
            return {
              pickAddress: pickAddress.get('fullAddress'),
              dropAddress: dropAddress.get('fullAddress'),
              tripRequestPickAddress: pickAddress.get('simpleAddress'),
              tripRequestDropAddress: dropAddress.get('simpleAddress'),
              promiseTime: (0, _moment.default)(segment.get('trips.firstObject.promiseTime')),
              startTime: (0, _moment.default)(segment.get('tripStartTime')).format(TIME_FORMAT),
              arriveTime: (0, _moment.default)(segment.get('tripEndTime')).format(TIME_FORMAT)
            };
          })
        };
      });
      return tripRequestDetails;
    },

    getTripAndReturnTripDetails() {
      const itpRequestResults = this.getItpSegmentsForLegs() || [];
      const tripRequestDetails = this.getTripAndReturnTripRequestDetails(this.get('tripLegsInBooking'));
      const returnTripDetails = this.getTripAndReturnTripRequestDetails(this.get('returnTripLegsInBooking'));
      this.setItp([...tripRequestDetails, ...returnTripDetails], itpRequestResults);
      this.set('tripDetails', tripRequestDetails);
      this.set('returnTripDetails', returnTripDetails);
    },

    /**
     * set itp results for trips and return trips
     * @param {*} trips (trips and return trip)
     * @param {*} itpRequestResults (itp results)
     */
    setItp(trips, itpRequestResults) {
      const itpInfoMap = {};
      itpRequestResults.forEach(itpInfo => {
        itpInfoMap[itpInfo.legIndex] = itpInfo;
      });
      trips.forEach((trip, index) => {
        const {
          result: itpRequestResult
        } = itpInfoMap[index] || {};
        const itinerarySegments = itpRequestResult && itpRequestResult.segments || [];
        trip.itinerarySegments = itinerarySegments;
      });
    },

    actions: {
      onOkClick() {
        this.editModal.close();
      }

    }
  });

  _exports.default = _default;
});