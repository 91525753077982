define("adept-iq/utils/name-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = splitName;

  function splitName(fullName) {
    const result = {
      firstName: null,
      middleName: null,
      lastName: null
    };

    if (typeof fullName !== 'undefined' && fullName !== null) {
      const middleName = [];
      fullName.split(' ').forEach((namePart, index, collection) => {
        if (index === 0) {
          if (collection.length === 1) {
            // only have last name
            result.lastName = namePart;
          } else {
            // first namePart is firstName
            result.firstName = namePart;
          }
        } else if (index + 1 < collection.length) {
          // not the last namePart, add to middleName
          middleName.push(namePart);
        } else {
          // the last part of the name, set as lastName
          result.lastName = namePart;
        }
      });

      if (middleName.length > 0) {
        result.middleName = middleName.join(' ');
      }
    }

    return result;
  }
});