define("adept-iq/models/dispatch-route", ["exports", "ember-data", "adept-iq/utils/format-text-extension", "lodash", "adept-iq/utils/unwrapProxy", "moment", "adept-iq/models/vehicle", "adept-iq/models/stop-point"], function (_exports, _emberData, _formatTextExtension, _lodash, _unwrapProxy, _moment, _vehicle, _stopPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ROUTE_STARTED_STATUS = _exports.ROUTE_SIGNED_ON_STATUS = _exports.ROUTE_SIGNED_OFF_STATUS = _exports.ROUTE_SCHEDULED_STATUS = _exports.ROUTE_ON_BREAK_STATUS = _exports.ROUTE_NEW_STATUS = _exports.ROUTE_LOCKED_STATUS = _exports.ROUTE_IN_PROGRESS_STATUS = _exports.ROUTE_ENDED_STATUS = _exports.ROUTE_EMERGENCY_STATUS = _exports.ROUTE_DISPATCHED_STATUS = _exports.ROUTE_COMPLETED_STATUS = _exports.ROUTE_BREAKDOWN_STATUS = _exports.ROUTE_AWAITING_STATUS = _exports.ROUTE_ARCHIVED_STATUS = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  const ROUTE_SCHEDULED_STATUS = 'Scheduled';
  _exports.ROUTE_SCHEDULED_STATUS = ROUTE_SCHEDULED_STATUS;
  const ROUTE_NEW_STATUS = 'New';
  _exports.ROUTE_NEW_STATUS = ROUTE_NEW_STATUS;
  const ROUTE_DISPATCHED_STATUS = 'Dispatched';
  _exports.ROUTE_DISPATCHED_STATUS = ROUTE_DISPATCHED_STATUS;
  const ROUTE_SIGNED_ON_STATUS = 'Signed on';
  _exports.ROUTE_SIGNED_ON_STATUS = ROUTE_SIGNED_ON_STATUS;
  const ROUTE_STARTED_STATUS = 'Started';
  _exports.ROUTE_STARTED_STATUS = ROUTE_STARTED_STATUS;
  const ROUTE_ENDED_STATUS = 'Route ended';
  _exports.ROUTE_ENDED_STATUS = ROUTE_ENDED_STATUS;
  const ROUTE_IN_PROGRESS_STATUS = 'In progress';
  _exports.ROUTE_IN_PROGRESS_STATUS = ROUTE_IN_PROGRESS_STATUS;
  const ROUTE_EMERGENCY_STATUS = 'Emergency';
  _exports.ROUTE_EMERGENCY_STATUS = ROUTE_EMERGENCY_STATUS;
  const ROUTE_BREAKDOWN_STATUS = 'Breakdown';
  _exports.ROUTE_BREAKDOWN_STATUS = ROUTE_BREAKDOWN_STATUS;
  const ROUTE_ON_BREAK_STATUS = 'On break';
  _exports.ROUTE_ON_BREAK_STATUS = ROUTE_ON_BREAK_STATUS;
  const ROUTE_COMPLETED_STATUS = 'Route ended';
  _exports.ROUTE_COMPLETED_STATUS = ROUTE_COMPLETED_STATUS;
  const ROUTE_SIGNED_OFF_STATUS = 'Signed off';
  _exports.ROUTE_SIGNED_OFF_STATUS = ROUTE_SIGNED_OFF_STATUS;
  const ROUTE_AWAITING_STATUS = 'Awaiting status';
  _exports.ROUTE_AWAITING_STATUS = ROUTE_AWAITING_STATUS;
  const ROUTE_ARCHIVED_STATUS = 'Archived';
  _exports.ROUTE_ARCHIVED_STATUS = ROUTE_ARCHIVED_STATUS;
  const ROUTE_LOCKED_STATUS = 'Locked';
  _exports.ROUTE_LOCKED_STATUS = ROUTE_LOCKED_STATUS;

  var _default = Model.extend({
    session: Ember.inject.service(),
    orderedClustersSortKeys: ['routeOrdinal'],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    lockedForOptimization: attr('boolean'),
    version: attr('string'),
    avlmVersion: attr('string'),
    affinities: attr(),
    schedulingMode: attr('string'),
    provider: belongsTo('provider'),
    route: belongsTo('route'),
    dispatchRouteLock: belongsTo('lock'),
    performedPolyline: belongsTo('polyline'),
    scheduledPolyline: belongsTo('schedule-polyline'),
    dispatchSchedule: belongsTo('dispatch-schedule'),
    routeVehicleDrivers: hasMany('route-vehicle-driver'),
    // Workaround for ember-data was not able to sync the relationship properly.
    clusters: hasMany('cluster', {
      async: false
    }),
    _clusters: Ember.computed('clusters', 'clusters.@each.{dispatchRoute,stopPoints}', function () {
      // Some deleted cluster still remain in array, and reordered cluster won't be deleted, but having empty stopPoints
      return this.get('clusters').filterBy('dispatchRoute.id').filter(c => c.get('stopPoints.length') > 0);
    }),
    // computedStatus from iqux-service
    routeStatus: attr('string'),
    // breakdown replacement attributes
    replacementRouteId: attr('string'),
    replacementStartTime: attr('date'),
    replacementEndTime: attr('date'),
    replacementDriver: belongsTo('driver'),
    replacementVehicle: belongsTo('vehicle'),
    replacementMoveAllTrips: attr('boolean'),
    breakdownPlace: belongsTo('place'),
    // used for map polyline layer filtering
    isNavigating: Ember.computed.not('isPerformed'),
    isPerformed: Ember.computed('route.actualEndTimestamp', function () {
      const isPerformed = Ember.isPresent(this.get('route.actualEndTimestamp'));
      return isPerformed;
    }),
    vehiclePolyline: Ember.computed.readOnly('route.navigationPolyline'),
    otp: Ember.computed.readOnly('route.otp'),
    lockedId: Ember.computed.readOnly('id'),
    status: Ember.computed.readOnly('computedStatus'),
    // keep status field untill it's uneeded
    stopPoints: Ember.computed.readOnly('route.stopPoints'),
    // used for pullout and pullin
    plannedStartTime: Ember.computed.alias('latestRouteVehicleDriver.startTime'),
    plannedEndTime: Ember.computed.alias('latestRouteVehicleDriver.endTime'),
    startGarage: Ember.computed.alias('assignedVehicle.startGarage'),
    endGarage: Ember.computed.alias('assignedVehicle.endGarage'),
    assignedDriverPhoneNumber: Ember.computed.alias('latestRouteVehicleDriver.driverPhoneNumber'),
    isEmptyRoute: Ember.computed.empty('clusters'),
    isPerformCoordEmpty: Ember.computed.empty('performedPolyline.coords'),

    /**
     * /**
      * @param {Object} param - stop point model represents the row the user clicked on.
      * @return {Array} - returns all unperformed stops between the pullout garage and k stops
    */
    findUnperformedStopsInSequence(stopPoint) {
      const dispatchRouteId = stopPoint.get('dispatchRoute.id');

      if (dispatchRouteId !== this.get('id')) {
        return [];
      }

      const routeOrdinal = stopPoint.get('routeOrdinal');
      const orderedStopsWithGaragesAndBreaks = this.get('orderedStopsWithGaragesAndBreaks') || [];
      const stops = orderedStopsWithGaragesAndBreaks.filter(stop => {
        if (!stop.get('trip.isWaitlisted') && !stop.get('isPerformed') && !stop.get('isBreakPerformed') && !stop.get('isGarage') && stop.get('routeOrdinal') > 0 && stop.get('routeOrdinal') <= routeOrdinal) {
          return stop;
        }
      });
      return stops.sortBy('routeOrdinal');
    },

    getFirstUnPeformedStop() {
      const orderedStopsWithGaragesAndBreaks = this.get('orderedStopsWithGaragesAndBreaks') || [];
      const stops = orderedStopsWithGaragesAndBreaks.filter(stop => {
        if (!stop.get('trip.isWaitlisted') && !stop.get('isPerformed') && !stop.get('isBreakPerformed') && !stop.get('isGarage') && stop.get('routeOrdinal') > 0) {
          return stop;
        }
      });
      return stops.sortBy('routeOrdinal').firstObject;
    },

    // get un-arrived or un-performed Stops in Sequence after ordinal param
    getUnArrivedStopsInSequence(ordinal) {
      const orderedStopsWithBreaks = this.get('orderedStopsWithBreaks') || [];
      const stops = orderedStopsWithBreaks.filter(stop => {
        if (!stop.get('isPerformedOrBeingPerformed') && !stop.get('isNoShow') && stop.get('routeOrdinal') > 0 && stop.get('routeOrdinal') > ordinal && !stop.get('trip.isWaitlisted') && !stop.get('trip.isCanceled')) {
          return stop;
        }
      });
      return stops;
    },

    getPrevStopPoint(ordinal) {
      const orderedStopsWithGaragesAndBreaks = this.get('orderedStopsWithGaragesAndBreaks') || [];
      if (ordinal <= 0) return;
      return orderedStopsWithGaragesAndBreaks.objectAt(ordinal - 1);
    },

    getNextStopPoint(ordinal) {
      const orderedStopsWithGaragesAndBreaks = this.get('orderedStopsWithGaragesAndBreaks') || [];
      if (ordinal < 0) return;
      return orderedStopsWithGaragesAndBreaks.objectAt(ordinal + 1);
    },

    editBreakRouteDuration(driverBreak) {
      const plannedDuration = parseInt(driverBreak.get('plannedDuration'), 10);
      const originalPlannedDuration = parseInt(driverBreak.get('originalPlannedDuration'), 10);
      const estimatedStartTime = (0, _moment.default)(driverBreak.get('requestTime'));
      const estimatedEndTime = (0, _moment.default)(driverBreak.get('projectedEndDate'));
      const stopPoint = driverBreak.get('stopPoint');
      const currentPosition = stopPoint.get('routeOrdinal');
      const prevStopPoint = this.getPrevStopPoint(currentPosition);
      const nextStopPoint = this.getNextStopPoint(currentPosition);
      const prevComputedStartTime = (0, _moment.default)(prevStopPoint.get('computedStartTime')).startOf('second');
      const prevActualDepartTime = (0, _moment.default)(prevStopPoint.get('actualDepartTime')).startOf('second');
      let minBreakStartTime = prevComputedStartTime;
      const maxBreakEndTime = (0, _moment.default)(nextStopPoint.get('availableTimeBeforeStop'));

      if (prevActualDepartTime.isValid() && prevActualDepartTime.isAfter(minBreakStartTime)) {
        minBreakStartTime = prevActualDepartTime;
      }

      let slack = Ember.isPresent(stopPoint.get('slackInMinutes')) ? parseInt(stopPoint.get('slackInMinutes'), 10) : 0;
      const stopEndTime = maxBreakEndTime.clone().add(slack, 'minutes').startOf('second');
      slack = Ember.isPresent(prevStopPoint.get('slackInMinutes')) ? slack + parseInt(prevStopPoint.get('slackInMinutes'), 10) : slack + 0;
      let routeOrdinal = -1;
      if (plannedDuration > originalPlannedDuration + slack) return routeOrdinal;

      if (estimatedStartTime.isSameOrAfter(minBreakStartTime) && estimatedEndTime.isSameOrBefore(stopEndTime)) {
        routeOrdinal = currentPosition;
      }

      return routeOrdinal;
    },

    // find a place to insert a driver break based on the requested time
    // the duration does not matter from new requirement
    findNewBreakRoutePosition(driverBreak) {
      const dispatchRoute = driverBreak.get('route.dispatchRoute');
      const etaRoute = (0, _unwrapProxy.unwrapProxy)(driverBreak.get('route.etaRoute'));
      const routePlannedStartTimeMoment = (0, _moment.default)(dispatchRoute.get('plannedStartTime'));
      const routePlannedStartTime = routePlannedStartTimeMoment;
      const estimatedStartTime = (0, _moment.default)(driverBreak.get('requestTime')).startOf('minute');
      const orderedStopsWithBreaks = this.get('orderedStopsWithBreaks') || [];
      const lastIndex = orderedStopsWithBreaks.length - 1;

      if (Ember.isEmpty(orderedStopsWithBreaks)) {
        if (Ember.isEmpty(etaRoute)) {
          return -2;
        }

        return 1;
      }

      let routeOrdinal = -1;
      let onBoardCount = 0;
      orderedStopsWithBreaks.forEach((currentStop, currentIndex) => {
        const isLastStop = currentIndex === lastIndex;
        const isFirstStop = currentIndex === 0;
        const stopStartTime = (0, _moment.default)(currentStop.get('computedStartTime'));
        const currentStopIsPerformedOrBeingPerformed = currentStop.get('isPerformedOrBeingPerformed'); // if break start time is before the first stop

        if (isFirstStop) {
          if (!currentStopIsPerformedOrBeingPerformed && estimatedStartTime.isBefore(stopStartTime) && estimatedStartTime.isAfter(routePlannedStartTime)) {
            routeOrdinal = 1;
          }
        } // continue if we have not found a spot


        if (routeOrdinal === -1) {
          if (isLastStop) {
            // if it is the last stop and requested time is after the current stop
            if (estimatedStartTime.isAfter(stopStartTime)) {
              routeOrdinal = currentStop.get('routeOrdinal') + 1;
            }
          } else {
            if (currentStop.stopType === 'pick') {
              onBoardCount++;
            }

            if (currentStop.stopType === 'drop') {
              onBoardCount--;
            }

            const nextStop = orderedStopsWithBreaks[currentIndex + 1];
            const nextStopStartTime = (0, _moment.default)(nextStop.get('computedStartTime'));
            const nextStopIsPerformedOrBeingPerformed = Ember.isPresent(nextStop) ? nextStop.get('isPerformedOrBeingPerformed') : false; // if there are no passenger on board and the break start time is after a stop

            if (onBoardCount === 0 && !nextStopIsPerformedOrBeingPerformed && estimatedStartTime.isAfter(stopStartTime) && estimatedStartTime.isSameOrBefore(nextStopStartTime)) {
              routeOrdinal = currentStop.get('routeOrdinal') + 1;
            }
          }
        }
      });
      return routeOrdinal;
    },

    allStopsPerformed() {
      const orderedStops = this.get('orderedStops');
      if (!orderedStops) return false; // empty route

      if (!orderedStops || orderedStops.length === 0) return false;
      const allStopsPerformed = orderedStops.every(stop => stop.get('isPerformed') || stop.get('isNoShow') || stop.get('trip.isWaitlisted') || stop.get('cluster.dispatchRoute.id') !== this.id);
      return allStopsPerformed;
    },

    allStopsUnPerformed() {
      const orderedStops = this.get('orderedStops');
      if (!orderedStops) return true; // empty route

      if (!orderedStops || orderedStops.length === 0) return true;
      const allStopsUnPerformed = orderedStops.every(stop => stop.get('notPerformed') || stop.get('isNoShow') || stop.get('trip.isWaitlisted') || stop.get('cluster.dispatchRoute.id') !== this.id);
      return allStopsUnPerformed;
    },

    isReplacementRouteCreated(records, routeName) {
      if (!records) return false; // empty route

      if (!records || records.length === 0) return false;
      const isRouteCreated = records.some(stop => stop.replacedRouteName && stop.replacedRouteName === routeName);
      return isRouteCreated;
    },

    navigationPolyline: Ember.computed('vehiclePolyline', 'orderedStops.@each.isPerformed', 'assignedVehicle.{lat,lng}', 'computedStatus', function () {
      const computedStatus = this.get('computedStatus'); // hide navigation polyline once the route has ended

      if (computedStatus === ROUTE_COMPLETED_STATUS) return null;
      const vehiclePolyline = (0, _unwrapProxy.unwrapProxy)(this.get('vehiclePolyline'));
      const lat = this.get('assignedVehicle.lat');
      const lng = this.get('assignedVehicle.lng'); // return navigation polyline as driver app is in use

      if (Ember.isPresent(lat) && Ember.isPresent(lng)) {
        return vehiclePolyline;
      } // edge case dispatcher performs stops for driver app or the driver app never sends actual gps pings
      // use stop polyline to give the dispatch an indication of the driver's direction


      const pulloutGarageStop = this.get('pulloutGarageStop');
      const orderedStops = this.get('orderedStops') || [];
      const currentPerformedStop = (0, _lodash.findLast)(orderedStops, stop => stop.get('isPerformed') && !stop.get('isBreak'));
      let stopPolyline = Ember.isPresent(currentPerformedStop) ? (0, _unwrapProxy.unwrapProxy)(currentPerformedStop.get('polyline')) : null; // started but none of the stops have been performed use garage pullout polyline

      if (!stopPolyline) {
        stopPolyline = Ember.isPresent(pulloutGarageStop) ? (0, _unwrapProxy.unwrapProxy)(pulloutGarageStop.get('polyline')) : null;
      }

      return Ember.isPresent(stopPolyline) ? stopPolyline : vehiclePolyline;
    }),
    performedPolylineDispatch: Ember.computed('performedPolyline.@each.coords', 'orderedStopsWithGaragesAndBreaks', function () {
      const isPerformed = this.get('isPerformed');
      const isPerformCoordEmpty = this.get('isPerformCoordEmpty');
      if (!isPerformed) return null;else if (!isPerformCoordEmpty) return this.get('performedPolyline.coords');
      const allStopsWithGaragesAndBreaks = this.get('orderedStopsWithGaragesAndBreaks');
      return allStopsWithGaragesAndBreaks.map((stop, index) => {
        if (index < allStopsWithGaragesAndBreaks.length - 1) return [[stop.lat, stop.lng], [allStopsWithGaragesAndBreaks[index + 1].lat, allStopsWithGaragesAndBreaks[index + 1].lng]];
        return [[stop.lat, stop.lng], [allStopsWithGaragesAndBreaks[0].lat, allStopsWithGaragesAndBreaks[0].lng]];
      });
    }),
    isCompleted: Ember.computed('status', 'pullinGarageStop', function () {
      const status = this.get('status');
      const pullinGarageStop = this.get('pullinGarageStop');
      if (status === ROUTE_COMPLETED_STATUS) return true;
      if (Ember.isPresent(pullinGarageStop) && pullinGarageStop.get('computedStatus') === _stopPoint.PULLED_IN_STOP_STATUS) return true;
      return false;
    }),
    isEmergency: Ember.computed('assignedVehicle.lastEmergencyMessage.{isEmergency,isAcknowledged}', function () {
      const vehicle = this.get('assignedVehicle');
      return vehicle ? vehicle.get('lastEmergencyMessage.isEmergency') || vehicle.get('lastEmergencyMessage.isAcknowledged') : false;
    }),
    isLocked: Ember.computed('dispatchRouteLock.{isLocked,status}', function () {
      return this.get('dispatchRouteLock.isLocked');
    }),
    lockedBy: Ember.computed('dispatchRouteLock.owner.firstName', function () {
      return `${this.get('dispatchRouteLock.owner.firstName')} ${this.get('dispatchRouteLock.owner.lastName')}`;
    }),
    hasStarted: Ember.computed('pulloutGarageStop.isPerformed', function () {
      const garageStop = this.get('pulloutGarageStop');
      if (!garageStop) return false;
      return garageStop.get('isPerformed');
    }),
    computedStartTime: Ember.computed('computedStatus', 'pulloutGarageStop.etaNavStopPoint.eta', function () {
      const computedStatus = this.get('computedStatus');

      if (computedStatus === ROUTE_SCHEDULED_STATUS) {
        return null;
      }

      const startTime = this.get('pulloutGarageStop.etaNavStopPoint.eta');
      if (!startTime) return null;
      return startTime;
    }),
    isLockedByOtherUser: Ember.computed('dispatchRouteLock.isLocked', function () {
      const userId = this.get('session').data.authenticated.userId;
      return this.get('dispatchRouteLock.isLocked') && this.get('dispatchRouteLock.owner.id') !== userId;
    }),
    pullinGarageStop: Ember.computed('stopPoints.[]', function () {
      const stopPoints = this.get('stopPoints');
      if (!stopPoints) return null;
      return stopPoints.find(stop => stop.get('stopType') === 'pullinGarage');
    }),
    pulloutGarageStop: Ember.computed('stopPoints.[]', function () {
      const stopPoints = this.get('stopPoints');
      if (!stopPoints) return null;
      return stopPoints.find(stop => stop.get('stopType') === 'pulloutGarage');
    }),
    orderedClusters: Ember.computed('_clusters', '_clusters.@each.tripStatus', function () {
      const clusters = this.get('_clusters') || [];
      return clusters.sortBy('routeOrdinal');
    }),
    // ordered stops includes break stop points
    // might be able to remove unnecessary cp orderedStopsWithBreaks
    orderedStops: Ember.computed('orderedClusters', 'orderedClusters.@each.orderedStops', function () {
      const orderedClusters = this.get('orderedClusters') || [];
      if (Ember.isEmpty(orderedClusters)) return null;
      return orderedClusters.reduce((arr, cluster) => {
        let stops = cluster.get('orderedStops') || [];
        stops = stops.filter(stop => stop.get('parentType') === 'trip' || stop.get('parentType') === 'break');
        arr.push(...stops);
        return arr;
      }, []).sortBy('routeOrdinal');
    }),
    orderedStopsWithBreaks: Ember.computed('orderedClusters', 'orderedClusters.@each.orderedStops', 'stopPoints.@each.routeOrdinal', function () {
      const breaks = [];
      const orderedClusters = this.get('orderedClusters');
      if (Ember.isEmpty(orderedClusters)) return [];
      const stopsWithBreaks = orderedClusters.reduce((arr, cluster) => {
        const stops = cluster.get('orderedStops'); // stops are not always cleared by socket updates when waitlisted

        stops.forEach(stop => {
          if (!stop.get('trip.isWaitlisted')) {
            arr.push(stop);
          }
        });
        return arr;
      }, breaks);
      return stopsWithBreaks.sortBy('routeOrdinal');
    }),
    firstUnDepartedStop: Ember.computed('orderedStops', 'orderedStops.@each.computedStatus', function () {
      const orderedStops = this.get('orderedStops');
      if (Ember.isEmpty(orderedStops)) return null;
      return orderedStops.find(stop => {
        if (stop.get('isPickOrDrop')) {
          return !stop.get('isPerformed');
        }

        return false;
      });
    }),
    orderedStopsWithGarages: Ember.computed('orderedClusters.@each.orderedStops', 'stopPoints.[]', function () {
      const pulloutGarage = this.get('pulloutGarageStop');
      const pullinGarage = this.get('pullinGarageStop');
      const orderedClusters = this.get('orderedClusters');
      if (Ember.isEmpty(orderedClusters)) return null;
      const stops = pulloutGarage ? [pulloutGarage] : [];
      const ordered = orderedClusters.reduce((arr, cluster) => {
        let orderedStops = cluster.get('orderedStops');
        orderedStops = orderedStops.filter(stop => stop.get('parentType') === 'trip');
        arr.push(...orderedStops);
        return arr;
      }, stops);

      if (pullinGarage) {
        ordered.push(pullinGarage);
      }

      return ordered;
    }),
    orderedStopsWithGaragesAndBreaks: Ember.computed('orderedClusters', 'orderedStops', 'stopPoints.@each.routeOrdinal', 'pulloutGarageStop', 'pullinGarageStop', function () {
      const pulloutGarage = this.get('pulloutGarageStop');
      const pullinGarage = this.get('pullinGarageStop');
      const stopsWithBreaks = this.get('orderedStops');
      const sortKey = 'routeOrdinal';
      const orderedEtaStops = pulloutGarage ? [pulloutGarage] : [];

      if (stopsWithBreaks) {
        orderedEtaStops.push(...stopsWithBreaks);
      }

      if (pulloutGarage) {
        pulloutGarage.set('routeOrdinal', 0);
      }

      if (pullinGarage) {
        const lastOrdinal = orderedEtaStops.get('lastObject.routeOrdinal');
        pullinGarage.set('routeOrdinal', lastOrdinal + 1);
        orderedEtaStops.push(pullinGarage);
      }

      return orderedEtaStops.sortBy(sortKey);
    }),
    firstUnperformedStop: Ember.computed('orderedStops', 'orderedStops.@each.computedStatus', function () {
      const orderedStops = this.get('orderedStops');
      if (Ember.isEmpty(orderedStops)) return null;
      return orderedStops.find(stop => {
        if (stop.get('isPickOrDrop')) {
          return Ember.isEmpty(stop.get('computedStatus'));
        }

        return false;
      });
    }),
    lastPerformedStop: Ember.computed('orderedStopsWithBreaks', 'orderedStops', 'orderedStops.@each.computedStatus', function () {
      const stops = !Ember.isEmpty(this.get('orderedStops')) ? this.get('orderedStops') : this.get('orderedStopsWithBreaks');
      if (Ember.isEmpty(stops)) return null;
      const order = stops.filter(stop => stop.get('computedStatus') === 'Departed' || stop.get('computedStatus') === 'Arrived' || stop.get('computedStatus') === 'Off break' || stop.get('computedStatus') === 'On break' || stop.get('computedStatus') === 'No showed' && stop.get('stopType') === 'pick');
      return order.pop();
    }),
    lastDropStop: Ember.computed('orderedStopsWithGaragesAndBreaks', function () {
      const stops = this.get('orderedStopsWithGaragesAndBreaks');
      if (Ember.isEmpty(stops)) return null;
      const stopLength = stops.length - 1;
      return stops[stopLength - 1]; // stop before garage pullin
    }),
    trips: Ember.computed('orderedStopsWithGaragesAndBreaks', function () {
      const stops = this.get('orderedStopsWithGaragesAndBreaks');
      if (Ember.isEmpty(stops)) return null;
      const orderedPickStops = stops.filterBy('isPick');
      return orderedPickStops.map(pick => (0, _unwrapProxy.unwrapProxy)(pick.get('trip')));
    }),
    routeBreaks: Ember.computed('orderedStopsWithGaragesAndBreaks', function () {
      const stops = this.get('orderedStopsWithGaragesAndBreaks');
      if (Ember.isEmpty(stops)) return null;
      return stops.filterBy('isBreak');
    }),
    unPerformedTrips: Ember.computed('trips', function () {
      const trips = this.get('trips') || [];
      return trips.filter(trip => trip.get('pickStopPoint.notPerformed') && trip.get('dropStopPoint.notPerformed'));
    }),
    plannedPolylinePoints: Ember.computed('orderedStopsWithGarages.@each.polylinePoints', function () {
      const stopPoints = this.get('orderedStopsWithGarages');
      if (Ember.isEmpty(stopPoints)) return []; // this returns an array of arrays; it _could_ be flattened, but tomtom
      // is ok with the data in this format, and this allows for discontiguity

      return stopPoints.reduce((arr, stopPoint) => {
        const points = stopPoint.get('polylinePoints');
        if (Ember.isEmpty(points)) return arr;
        arr.push(points);
        return arr;
      }, []);
    }),
    breakPolylinePoints: Ember.computed('orderedStopsWithGaragesAndBreaks.@each.polylinePoints', function () {
      const stopPoints = this.get('orderedStopsWithGaragesAndBreaks');
      if (Ember.isEmpty(stopPoints)) return []; // this returns an array of arrays; it _could_ be flattened, but tomtom
      // is ok with the data in this format, and this allows for discontiguity

      return stopPoints.reduce((polypoints, stopPoint) => {
        if (stopPoint.get('routeBreak.breakType.name') === 'floating' && stopPoint.get('stopType') === 'break') {
          if (stopPoint.get('actualDepartLat') && stopPoint.get('actualDepartLng')) {
            return [[stopPoint.get('lat'), stopPoint.get('lng')], [stopPoint.get('actualDepartLat'), stopPoint.get('actualDepartLng')]];
          }
        }

        return polypoints;
      }, []);
    }),
    currentStopOrdinalId: Ember.computed('orderedStopsWithGaragesAndBreaks.@each.computedStatus', function () {
      const orderedStops = this.get('orderedStopsWithGaragesAndBreaks');
      if (!orderedStops) return null;

      for (let i = 0; i < orderedStops.length; i++) {
        const status = orderedStops[i].get('computedStatus');

        if (Ember.isPresent(status) && status === _stopPoint.PENDING_STOP_STATUS) {
          return orderedStops[i].get('id');
        }
      }

      return null;
    }),
    scheduleLocked: Ember.computed('dispatchSchedule.locked', function () {
      return this.get('dispatchSchedule.locked');
    }),
    latestRouteVehicleDriver: Ember.computed('routeVehicleDrivers.@each.startTime', function () {
      const routeVehicleDrivers = (0, _unwrapProxy.unwrapProxy)(this.get('routeVehicleDrivers'));
      if (!routeVehicleDrivers) return null;
      return routeVehicleDrivers.sortBy('startTime').get('lastObject');
    }),
    assignedVehicle: Ember.computed('latestRouteVehicleDriver.vehicle', 'route.vehicle', function () {
      const rvdVehicle = this.get('latestRouteVehicleDriver.vehicle');

      if ((0, _unwrapProxy.unwrapProxy)(rvdVehicle)) {
        return rvdVehicle;
      }

      return this.get('route.vehicle');
    }),
    assignedDriver: Ember.computed('latestRouteVehicleDriver.driver', 'route.driver', function () {
      const rvdDriver = this.get('latestRouteVehicleDriver.driver');

      if ((0, _unwrapProxy.unwrapProxy)(rvdDriver)) {
        return rvdDriver;
      }

      return this.get('route.driver');
    }),
    actualDriverBreaks: Ember.computed('assignedVehicle.shiftBreaks.@each.{start,end}', function () {
      const driverBreaks = Ember.get(this, 'assignedVehicle.shiftBreaks');
      const driverBreaksTaken = [];

      if (!Ember.isEmpty(driverBreaks)) {
        const completedDriverBreaks = (0, _lodash.filter)(driverBreaks.toArray(), driverBreakEvent => {
          return !Ember.isEmpty(driverBreakEvent.get('end'));
        });
        completedDriverBreaks.forEach(function (item) {
          driverBreaksTaken.push((0, _formatTextExtension.formatTextExtension)('valueItem', `${item.get('breakType.name')}: ${item.get('start')} - ${item.get('end')}`));
        });
      }

      return {
        values: driverBreaksTaken
      };
    }),
    plannedDriverBreaks: Ember.computed('assignedVehicle.shiftBreaks.@each,{estimatedStart,estimatedEnd}', function () {
      const driverBreaks = [];
      const shiftBreaks = this.get('assignedVehicle.shiftBreaks');

      if (!Ember.isEmpty(shiftBreaks)) {
        shiftBreaks.forEach(function (item) {
          driverBreaks.push((0, _formatTextExtension.formatTextExtension)('valueItem', `${item.get('breakType.name')}: ${item.get('estimatedStart')} - ${item.get('estimatedEnd')}`));
        });
      }

      return {
        values: driverBreaks
      };
    }),
    tripStartTime: Ember.computed('_clusters.@each.tripStartTime', 'plannedStartTime', function () {
      const clusters = this.get('_clusters').toArray();
      let min = null;

      if (!Ember.isEmpty(clusters)) {
        clusters.forEach(cluster => {
          if (cluster.get('tripStartTime')) {
            if (min) {
              min = Math.min(cluster.get('tripStartTime').getTime(), min);
            } else {
              min = cluster.get('tripStartTime').getTime();
            }
          }
        });
      } else {
        min = this.get('plannedStartTime') ? this.get('plannedStartTime').getTime() : null;
      }

      return min ? new Date(min) : null;
    }),
    tripEndTime: Ember.computed('_clusters.@each.tripEndTime', 'plannedEndTime', function () {
      const clusters = this.get('_clusters').toArray();
      let max = this.get('plannedEndTime') ? this.get('plannedEndTime').getTime() : null;

      if (!Ember.isEmpty(clusters)) {
        clusters.forEach(cluster => {
          if (cluster.get('tripEndTime')) {
            max = Math.max(cluster.get('tripEndTime').getTime(), max);
          }
        });
      }

      return max ? new Date(max) : null;
    }),
    travelNeedItinerary: Ember.computed('orderedStops', function () {
      const initialCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0
      };
      const orderedStops = this.get('orderedStops');
      if (Ember.isEmpty(orderedStops)) return null;
      const itinerary = orderedStops.reduce((it, stopPoint) => {
        const travelNeedCounts = stopPoint.get('trip.travelNeedCounts');
        const stopType = stopPoint.get('stopType');
        if (stopType === 'break') return it;
        Object.entries(travelNeedCounts).forEach(_ref => {
          let [travelNeedTypeName, travelNeedTypeValue] = _ref;

          // We only care about count, some travelNeedType is not count we just ignored.
          if (!isNaN(parseInt(travelNeedTypeValue, 10))) {
            if (!initialCounts[travelNeedTypeName]) {
              initialCounts[travelNeedTypeName] = travelNeedTypeValue;
            } else {
              const count = travelNeedTypeValue;

              if (stopType === 'pick') {
                initialCounts[travelNeedTypeName] += count;
              } else if (stopType === 'drop') {
                initialCounts[travelNeedTypeName] -= count;
              }
            }
          }
        });
        it.push({ ...initialCounts
        });
        return it;
      }, [{ ...initialCounts
      }]);
      return itinerary;
    }),
    tnVehicleCapacityItinerary: Ember.computed('orderedStops', function () {
      const initialCounts = {};
      const orderedStops = this.get('orderedStops');
      if (Ember.isEmpty(orderedStops)) return null;
      const itinerary = orderedStops.reduce((it, stopPoint) => {
        const vehicleCapacityCounts = stopPoint.get('trip.vehicleCapacityCounts');
        const stopType = stopPoint.get('stopType');
        if (stopType === 'break') return it;
        Object.entries(vehicleCapacityCounts).forEach(_ref2 => {
          let [travelNeedTypeName, travelNeedTypeValue] = _ref2;

          // We only care about count, some travelNeedType is not count we just ignored.
          if (!isNaN(parseInt(travelNeedTypeValue, 10))) {
            if (!initialCounts[travelNeedTypeName]) {
              initialCounts[travelNeedTypeName] = travelNeedTypeValue;
            } else {
              const count = travelNeedTypeValue;

              if (stopType === 'pick') {
                initialCounts[travelNeedTypeName] += count;
              } else if (stopType === 'drop') {
                initialCounts[travelNeedTypeName] -= count;
              }
            }
          }
        });
        it.push({ ...initialCounts
        });
        return it;
      }, [{ ...initialCounts
      }]);
      return itinerary;
    }),
    totalTravelNeedCounts: Ember.computed('orderedStops', function () {
      const initialCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0,
        extraWideLift: 0,
        flexSeats: []
      };
      const maxCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0,
        extraWideLift: 0,
        flexSeats: []
      };
      let nonConsumableTravelNeeds = {};
      const orderedStops = this.get('orderedStops');
      if (Ember.isEmpty(orderedStops)) return null;
      orderedStops.forEach(stopPoint => {
        const travelNeedCounts = stopPoint.get('trip.travelNeedCounts');
        const stopType = stopPoint.get('stopType');
        if (Ember.isEmpty(travelNeedCounts)) return maxCounts;

        if (stopType === 'pick') {
          Object.entries(travelNeedCounts).forEach(_ref3 => {
            let [travelNeedTypeName] = _ref3;
            initialCounts[travelNeedTypeName] += travelNeedCounts[travelNeedTypeName];
          });
          nonConsumableTravelNeeds = (0, _lodash.merge)(nonConsumableTravelNeeds, travelNeedCounts.nonConsumables);
        } else if (stopType === 'drop') {
          Object.entries(travelNeedCounts).forEach(_ref4 => {
            let [travelNeedTypeName] = _ref4;
            initialCounts[travelNeedTypeName] -= travelNeedCounts[travelNeedTypeName];
          });
        }

        Object.entries(initialCounts).forEach(_ref5 => {
          let [travelNeedTypeName, count] = _ref5;
          maxCounts[travelNeedTypeName] = Math.max(maxCounts[travelNeedTypeName], count);
        });
      });
      return {
        nonConsumableTravelNeeds,
        maxTravelNeedCounts: maxCounts
      };
    }),
    nonConsumableTravelNeeds: Ember.computed.alias('totalTravelNeedCounts.nonConsumableTravelNeeds'),
    maxTravelNeedCounts: Ember.computed.alias('totalTravelNeedCounts.maxTravelNeedCounts'),
    //Using if/else statements to allow multiple possible scenarios
    computedStatus: Ember.computed('isEmergency', 'assignedDriver', 'assignedVehicle.vehicleStatus', 'route.{actualStartTimestamp,actualEndTimestamp,status}', 'latestRouteVehicleDriver.avlmDriverStateEvent.isSignedOn', 'routeStatus', function () {
      const routeStatus = this.get('routeStatus'); // return iqux-perf status directly

      if (routeStatus) return routeStatus;
      const currentStatus = this.get('route.status');
      const vehicleStatus = this.get('assignedVehicle.vehicleStatus');
      const driver = (0, _unwrapProxy.unwrapProxy)(this.get('assignedDriver'));
      const vehicle = (0, _unwrapProxy.unwrapProxy)(this.get('assignedVehicle'));
      const start = this.get('route.actualStartTimestamp');
      const end = this.get('route.actualEndTimestamp');
      const emergency = this.get('isEmergency');
      const signedOn = this.get('latestRouteVehicleDriver.avlmDriverStateEvent.isSignedOn'); // workaround that route status will be changed back to 'normal' if route got updated.
      // So if route started and status is normal we set the route status to Inprogress

      if (emergency) {
        return ROUTE_EMERGENCY_STATUS;
      } else if (vehicleStatus === _vehicle.VEHICLE_BREAKDOWN_STATUS) {
        return ROUTE_BREAKDOWN_STATUS;
      } else if (!driver && vehicle) {
        return ROUTE_SCHEDULED_STATUS;
      } else if (signedOn && !start) {
        return ROUTE_SIGNED_ON_STATUS;
      } else if (currentStatus === 'Pullout' && start && !end) {
        return ROUTE_STARTED_STATUS;
      } else if ((currentStatus === 'Arrive' || currentStatus === 'OffBreak' || currentStatus === 'Depart' || currentStatus === 'normal') && start && !end) {
        return ROUTE_IN_PROGRESS_STATUS;
      } else if (currentStatus === 'OnBreak') {
        return ROUTE_ON_BREAK_STATUS;
      } else if (end || currentStatus === 'Complete' || currentStatus === 'Pullin') {
        return ROUTE_COMPLETED_STATUS;
      } else if (currentStatus === 'normal' && driver && vehicle && !vehicle.placeholder) {
        return ROUTE_DISPATCHED_STATUS;
      }

      return ROUTE_AWAITING_STATUS; //Default case for anomalies
    })
  });

  _exports.default = _default;
});