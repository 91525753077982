define("adept-iq/pods/components/iq-widgets/no-show-reason-code/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'vehicle',
        type: 'text',
        label: 'Vehicle',
        valuePath: 'assignedVehicle.id',
        editable: false
      }]
    }, {
      title: 'Editable',
      fields: [{
        id: 'timestamp',
        type: 'datetime',
        label: 'Timestamp',
        valuePath: 'timestamp'
      }, {
        id: 'odometer',
        type: 'text',
        label: 'Odometer',
        valuePath: 'odometer'
      }]
    }]
  };
  _exports.default = _default;
});