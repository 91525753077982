define("adept-iq/adapters/driver-19-a-review", ["exports", "adept-iq/adapters/-driver"], function (_exports, _driver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _driver.default.extend({
    pathForType() {
      return 'driver-19a-review';
    }

  });

  _exports.default = _default;
});