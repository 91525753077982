define("adept-iq/pods/components/iq-widgets/new-booking-form/trip-details-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['trip-details-form'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    booking: Ember.inject.service(),
    isFieldDisabled: Ember.computed('disableRequestDate', 'isEditingUnscheduledBooking', function () {
      return this.get('disableRequestDate') || this.get('booking.isEditingUnscheduledBooking');
    }),
    hasMoreThanOneLeg: Ember.computed('legsInBooking.[]', function () {
      return this.get('legsInBooking.length') > 1;
    }),
    noOfLegs: Ember.computed('legsInBooking.[]', function () {
      return this.get('legsInBooking.length');
    }),

    canShowRemove(legIndex) {
      return this.get('hasMoreThanOneLeg') && legIndex !== 0;
    },

    init() {
      this._super(...arguments);
    },

    // we are just checking using postalCode because both nyct and tomtom returns that consistently
    validateIfPickAndDropArePresent() {
      let allLegsHavePickAndDrop = true;
      this.get('legsInBooking').forEach(leg => {
        if (leg.get('segments.firstObject.pick.place.address.postalCode') && leg.get('segments.lastObject.drop.place.address.postalCode')) {
          allLegsHavePickAndDrop = true;
        } else {
          allLegsHavePickAndDrop = false;
        }
      });
      return allLegsHavePickAndDrop;
    },

    actions: {
      onAddNewLegClick() {
        if (this.validateIfPickAndDropArePresent()) {
          this.get('onAddNewLegClick')();
        } else {
          this.get('booking').showErrorMessage('Please enter valid leg addresses.');
        }
      },

      onLegRemoveClick(leg) {
        this.get('onLegRemoveClick')(leg);
      }

    }
  });

  _exports.default = _default;
});