define("adept-iq/services/notification-initiation", ["exports", "adept-iq/config/api-urls", "lodash"], function (_exports, _apiUrls, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    async getResponsesByTag(notificationType, tag) {
      try {
        const response = await this.get('ajax').request(_apiUrls.API.initiationService.host + `/notification-response?notificationType=${notificationType}&tag=${tag}`, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
          }
        });
        return _lodash.default.get(response, 'data.attributes.sourceIds', {});
      } catch {
        return [];
      }
    }

  });

  _exports.default = _default;
});