define("adept-iq/pods/components/side-drawer/reconcile-edit-trip/stubs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    LOAD_TIME: 1,
    UNLOAD_TIME: 2,
    PICK_NOTES: 3,
    DROP_NOTES: 4,
    anchorData: [{
      'id': 'pick',
      'name': 'Pick'
    }, {
      'id': 'drop',
      'name': 'Drop'
    }],
    usedAttributes: [{
      'id': 'ambulatory',
      'name': 'Ambulatory',
      'value': 1
    }],
    travelNeeds: [{
      'id': 'braces',
      'name': 'Braces',
      'value': 0
    }, {
      'id': 'supportCane',
      'name': 'Support Cane',
      'value': 0
    }, {
      'id': 'crutches',
      'name': 'Crutches',
      'value': 0
    }, {
      'id': 'doubleWheelchair',
      'name': 'Double Wheelchair',
      'value': 0
    }],
    companions: [{
      'id': 0,
      'name': 0,
      'value': 0
    }, {
      'id': 1,
      'name': 1,
      'value': 1
    }, {
      'id': 2,
      'name': 2,
      'value': 2
    }]
  };
  _exports.default = _default;
});