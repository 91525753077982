define("adept-iq/utils/isRouteAssignable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isRouteAssignable = isRouteAssignable;

  function isRouteAssignable(route
  /*, time*/
  ) {
    if (Ember.isNone(Ember.get(route, 'assignedVehicle'))) {
      return false;
    }

    if (Ember.isNone(Ember.get(route, 'assignedDriver'))) {
      return false;
    }

    if (Ember.isNone(route.get('clusters')) || route.get('clusters').length === 0) {
      return false;
    } //TODO need to see how we can get promise date

    /*if (!isEmpty(vehicleRoutes)) {
        const currentVR = vehicleRoutes.find((vr) => {
            const { plannedStartTime, plannedEndTime } = getProperties(
                vr,
                'plannedStartTime',
                'plannedEndTime'
            );
             return moment(plannedEndTime).isAfter(time) && moment(plannedStartTime).isBefore(time);
        });
         if (currentVR) {
            const driverShifts = get(currentVR, 'driverShifts');
             if (!isEmpty(driverShifts)) {
                const currentDS = driverShifts.find((ds) => {
                    const { plannedStartTime, plannedEndTime } = getProperties(
                        ds,
                        'plannedStartTime',
                        'plannedEndTime'
                    );
                     return (
                        moment(plannedEndTime).isAfter(time) &&
                        moment(plannedStartTime).isBefore(time)
                    );
                });
                 if (!isEmpty(currentDS)) {
                    return false;
                }
            }
            return true;
        }
    }*/


    return true;
  }
});