define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/map/marker", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/map", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "adept-iq/pods/components/iq-widgets/ember-react-playback/hooks/usePrevious", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/session", "moment", "lodash"], function (_exports, _react, _map, _panel, _usePrevious, _api, _session, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Marker = props => {
    const {
      map
    } = _react.default.useContext(_map.MapContext);

    const [stop, setStop] = _react.default.useState();

    const {
      labelDisplayMode,
      sliderIndex,
      setSelectedRvd,
      setIsLoadingEvent,
      startTime,
      endTime
    } = (0, _panel.usePanel)();
    const {
      layer,
      position,
      popup,
      iconCfg,
      pIconCfg,
      index,
      vehicleId
    } = props;

    const [performed, setPerformed] = _react.default.useState(sliderIndex > index);

    const prevStates = (0, _usePrevious.default)({
      performed
    });

    const {
      token,
      providers,
      notifications
    } = _react.default.useContext(_session.SessionContext);

    const timezone = _moment.default.tz.guess();

    const {
      vehicleData,
      doSearch,
      setEventData
    } = (0, _api.useAPI)();

    _react.default.useEffect(() => {
      if (!map || !layer) return;
      const icon = {
        icon: window.L.icon(performed && pIconCfg ? pIconCfg : iconCfg)
      };
      const marker = window.L.marker(position, icon);
      marker.addTo(layer);

      if (popup) {
        if (labelDisplayMode === _panel.labelDisplayModes.always) {
          marker.bindPopup(popup, {
            closeButton: false,
            autoClose: false,
            closeOnClick: false
          }).openPopup();
        } else {
          marker.bindPopup(popup, {
            closeButton: true
          });
        }
      }

      if (!_lodash.default.isEmpty(vehicleId)) {
        marker.vehicleId = vehicleId;
        marker.on('click', function () {
          vehicleData.some(vehicle => {
            if (marker.vehicleId === vehicle._id) {
              const {
                vehicleName,
                driverId,
                badgenumber,
                routeId,
                routeName,
                provider
              } = vehicle;
              const _vehicleId = vehicle.vehicleId;
              const _selectedRvd = {
                vehicleId: _vehicleId,
                vehicleName,
                driverId,
                badgenumber,
                routeId,
                routeName,
                provider
              };

              const callback = () => {
                setIsLoadingEvent(false);
              };

              setEventData([]);
              setSelectedRvd(_selectedRvd);
              setIsLoadingEvent(true);
              doSearch(startTime, endTime, timezone, token, providers, notifications, 'byVehicle', _selectedRvd, null, null, null, callback);
              return true;
            }

            return false;
          });
        });
      }

      setStop(marker);
      return () => layer.removeLayer(marker);
    }, [layer, popup, labelDisplayMode]);

    _react.default.useEffect(() => {
      if (typeof prevStates === 'undefined' || typeof index === 'undefined') return;

      const _performed = sliderIndex > index;

      if (!prevStates.performed && _performed) {
        const _iconCfg = pIconCfg || iconCfg;

        const icon = window.L.icon(_iconCfg);
        stop.setIcon(icon);
      } else if (prevStates.performed && !_performed) {
        const icon = window.L.icon(iconCfg);
        stop.setIcon(icon);
      }

      setPerformed(_performed);
    }, [sliderIndex]);

    return _react.default.createElement(_react.default.Fragment, null);
  };

  var _default = Marker;
  _exports.default = _default;
});