define("adept-iq/pods/components/reconcile-unscheduled-trips/component", ["exports", "ember-light-table", "adept-iq/pods/components/reconcile-unscheduled-trips/config", "ember-concurrency", "moment", "adept-iq/utils/format-function", "adept-iq/config/mapped-permIds"], function (_exports, _emberLightTable, _config, _emberConcurrency, _moment, _formatFunction, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    interaction: Ember.inject.service(),
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    classNames: ['reconcile-widget-base'],
    layoutName: 'components/iq-widgets/reconcile-widget',
    config: _config.default,
    contextMenuContainer: null,
    contextMenuContainerPosition: null,
    contextMenuOptions: null,
    lastRowClicked: null,
    onCloseClick: null,
    isWaitlisted: true,
    contextMenuCallback: null,
    reconcileTripDate: Ember.computed.alias('reconcile.reconcileDate'),

    init() {
      this._super(...arguments);

      const config = this.get('config');
      const unschedulesTripTable = new _emberLightTable.default(config.tripTableColumnsUnscheduledTrips);
      this.set('unschedulesTripTable', unschedulesTripTable);
      Ember.get(this, 'fetchTripsTask').perform(config.WAITLISTED);
      this.configurePermissions();
    },

    didInsertElement() {
      this._super(...arguments);

      this.createContextMenu();
    },

    configurePermissions() {
      this.set('isScheduledTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.scheduleTripsRecon, null));
      this.set('isTripActivityLogAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.activityLog, null));
    },

    isValidOption(optionId) {
      switch (optionId) {
        case 'reconcileScheduleUnscheduledTrip':
          return this.isScheduledTripAllowed;

        case 'reconcileTripLog':
          return this.isTripActivityLogAllowed;

        default:
          break;
      }

      return false;
    },

    createContextMenu() {
      this.set('contextMenuOptions', []);
      this.get('config').contextMenuOptionsList.map(option => {
        this.get('contextMenuOptions').push(this.createContextMenuOptions(option.id, option.caption, option.dialogId, option.dialogTitle));
      });
    },

    createContextMenuOptions(optionId, optionCaption, dialogId, dialogTitle) {
      const disable = !this.isValidOption(optionId);
      let action = null;

      if (!disable) {
        action = trip => {
          this.set('reconcile.reconcileSelectedTripId', null);

          if (optionId === 'reconcileScheduleUnscheduledTrip') {
            this.unloadSelectedTrip(trip.get('tripId'));
          }

          this.get('workspace').pushState(optionId, {
            trip,
            title: dialogTitle,
            action: this.contextMenuCallback
          });
        };
      }

      return {
        id: optionId,
        name: optionCaption,
        permId: dialogId,
        disable,
        action
      };
    },

    updateTableOnSelection(type) {
      const config = this.get('config');

      switch (type) {
        case config.WAITLISTED:
          Ember.set(this, 'isWaitlisted', true);
          break;

        case config.WILLCALL:
          Ember.set(this, 'isWillCall', true);
          break;

        case config.CALLBACK:
          Ember.set(this, 'isCallBack', true);
          break;

        case config.CANCELLED:
          Ember.set(this, 'isCancel', true);
          break;

        case config.FAILED:
          Ember.set(this, 'isFailed', true);
          break;

        case config.ILLEGAL:
          Ember.set(this, 'isIllegal', true);
          break;

        default:
          break;
      }
    },

    resetAllHeaderOtpions() {
      this.setProperties({
        isWaitlisted: false,
        isWillCall: false,
        isCallBack: false,
        isCancel: false,
        isFailed: false,
        isIllegal: false
      });
    },

    unloadSelectedTrip(tripId) {
      const record = this.store.peekRecord('reconcile-trip', tripId);
      this.store.unloadRecord(record);
    },

    fetchTripsTask: (0, _emberConcurrency.task)(function* (status) {
      const dateRange = (0, _formatFunction.getDateRangeForSearch)(this.reconcileTripDate);
      const queryString = {
        status,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate
      };
      const response = yield Ember.get(this, 'store').query(this.config.modelName, queryString);
      response.map(trip => {
        trip.set('promiseTime', trip.promiseTime ? (0, _moment.default)(trip.promiseTime).format('MM-DD-YYYY hh:mm A') : '');
      });
      Ember.set(this, 'tripsData', response);
      const table = Ember.get(this, 'unschedulesTripTable');
      if (table) table.setRows(response);
    }).restartable(),
    actions: {
      onCloseButtonClick() {
        this.onCloseClick();
      },

      setContextMenuContainer(dropdown) {
        this.set('contextMenuContainer', dropdown);
      },

      onContextMenuRowClick(action) {
        const model = this.get('lastRowClicked');
        this.get('contextMenuContainer').actions.close();

        if (action.action) {
          action.action(model);
          this.onCloseClick();
        }
      },

      onRowClick(row) {
        this.set('lastRowClicked', row.get('content'));
        const rows = this.get('unschedulesTripTable.rows.content');
        rows.forEach(_row => {
          if (_row.get('id') === row.get('id')) {
            _row.set('selected', true);
          } else _row.set('selected', false);
        });
      },

      onRowRightClick(row, event) {
        const rows = this.get('unschedulesTripTable.rows.content');
        this.set('contextMenuContainerPosition', () => {
          return {
            style: {
              top: event.clientY,
              left: event.clientX
            }
          };
        });
        this.set('lastRowClicked', row.get('content'));
        rows.forEach(_row => {
          if (_row.get('id') === row.get('id')) {
            _row.set('selected', true);
          } else _row.set('selected', false);
        });
        this.get('contextMenuContainer').actions.open();
      },

      onSelectList(type) {
        this.resetAllHeaderOtpions();
        this.updateTableOnSelection(type);
        Ember.get(this, 'fetchTripsTask').perform(type);
      }

    }
  });

  _exports.default = _default;
});