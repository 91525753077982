define("adept-iq/pods/components/iq-widgets/gauge-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/chartjs-widget/component", "adept-iq/utils/filters", "adept-iq/pods/components/generic-widgets/column-widget/classes/filter-column"], function (_exports, _component, _filters, _filterColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    classNames: ['gauge-widget'],
    widget: null,
    options: Ember.computed.alias('widget.state.dataType'),

    /* Applies the choice of data source. */
    chartRecords: Ember.computed('options.modelName', 'workspaceContext.workspaceData.[]', function () {
      const modelName = this.get('options.modelName');
      if (Ember.isBlank(modelName)) return [];
      const wrappedRecords = this.get('workspaceContext.workspaceData').filterBy('modelName', modelName);
      return wrappedRecords.map(a => a.record);
    }),

    /* Builds the chosen filters. */
    filterFunction: Ember.computed('options.columns', function () {
      const filterColumns = Object.values(this.get('options.columns')).map(col => new _filterColumn.default(col));
      const args = filterColumns.map(column => {
        return column.get('filterNode');
      });
      return (0, _filters.buildFilterFunction)((0, _filters.buildCompoundFilterNode)('and', args));
    }),

    /* Applies the choice of filters. */
    filteredRecords: Ember.computed('chartRecords', 'filterFunction', function () {
      const chartRecords = this.get('chartRecords') || [];
      return chartRecords.filter(this.get('filterFunction'));
    }),

    /* Applies the choice of data to display. */
    displaySlice: Ember.computed('options.displayOptions.selected', 'filteredRecords', function () {
      const displaySelection = this.get('options.displayOptions.selected');

      if (Ember.isBlank(displaySelection) || Ember.isBlank(displaySelection.valueKey) || Ember.isBlank(displaySelection.valueCategory)) {
        return this.get('filteredRecords');
      }

      return this.get('filteredRecords').filterBy(displaySelection.valueKey, displaySelection.valueCategory.value);
    }),

    /* Applies the choice of operation (metric option) to apply to the selected records. */
    dialValue: Ember.computed('displaySlice', 'options.displayOptions.selected.metricOption', function () {
      const selectedMetric = this.get('options.displayOptions.selected.metricOption');
      if (Ember.isBlank(selectedMetric)) return this.get('displaySlice.length');

      switch (selectedMetric.id) {
        case 'count':
          return this.get('displaySlice.length');

        default:
          return 0;
      }
    }),
    minGaugeValue: Ember.computed('options.displayOptions.selected.metricOption', function () {
      const selectedMetric = this.get('options.displayOptions.selected.metricOption');
      if (Ember.isBlank(selectedMetric)) return 0;

      switch (selectedMetric.id) {
        case 'count':
          return 0;

        default:
          return 0;
      }
    }),

    /* Applies the box color visual options. */
    boxValues: Ember.computed('options.boxColor.{orange,red}', 'minGaugeValue', 'dialValue', function () {
      const min = this.get('minGaugeValue');
      const dialValue = this.get('dialValue');
      const boxColors = this.get('options.boxColor');
      const boxValues = [];
      boxValues.push(boxColors.orange - min);
      boxValues.push(boxColors.red - boxColors.orange);
      const maxValue = boxColors.orange - min + 2 * (boxColors.red - boxColors.orange);

      if (maxValue > dialValue) {
        boxValues.push(boxColors.red - boxColors.orange);
      } else {
        boxValues.push(boxColors.red - boxColors.orange + (dialValue - maxValue));
      }

      return boxValues;
    }),
    dialRange: Ember.computed('boxValues', 'minGaugeValue', function () {
      const boxValues = this.get('boxValues') || [];
      const min = this.get('minGaugeValue');
      const max = boxValues.reduce((a, b) => a + b, min);
      return {
        min: min,
        max: max
      };
    }),
    chartData: Ember.computed('boxValues', function () {
      const cData = {
        'datasets': [{
          'data': this.get('boxValues'),
          'backgroundColor': [// this.get('colors')[0],
          this.get('colors')[2], this.get('warningColor'), this.get('urgentColor')],
          'borderWidth': 0,
          'hoverBackgroundColor': [// this.get('colors')[0],
          this.get('colors')[2], this.get('warningColor'), this.get('urgentColor')],
          'hoverBorderWidth': 0
        }],
        labels: []
      };
      return cData;
    }),

    /* Applies the chart title input. */
    chartTitle: Ember.computed('options.name', function () {
      if (Ember.isBlank(this.get('options'))) {
        return 'Gauge Chart';
      } else if (Ember.isBlank(this.get('options.title'))) {
        return this.get('options.name') + ' Gauge Chart';
      }

      return this.get('options.title');
    }),

    /* Applies the visual options, value and/or percentage display. */
    dialDisplay: Ember.computed('dialValue', 'options.visualOptions', function () {
      let text;
      const visualOptions = this.get('options.visualOptions');
      const dialValue = this.get('dialValue');

      if (visualOptions.value.isChecked) {
        text = dialValue.toString();
      }

      if (visualOptions.percentage.isChecked && this.get('filteredRecords.length') !== 0) {
        text = text ? text + ', ' : '';
        text += Math.floor(100 * dialValue / this.get('filteredRecords.length')).toString() + '%';
      }

      return text || '';
    }),
    chartOptions: Ember.computed('dialRange', 'dialValue', 'dialDisplay', function () {
      const options = {
        gauge: true,
        labels: {
          dial: this.get('dialValue'),
          min: this.get('dialRange').min,
          max: this.get('dialRange').max
        },
        rotation: -1.0 * Math.PI,
        // start angle in radians
        circumference: Math.PI,
        // sweep angle in radians
        title: {
          'display': true,
          'text': this.get('dialDisplay'),
          'position': 'bottom'
        },
        tooltips: {
          'enabled': false
        },
        onResize: function
          /* chart, size */
        () {}
      };
      return options;
    }),
    actions: {
      onFilterButtonClick() {
        const workspace = this.get('workspace');
        let topState = workspace.get('topState');

        while (topState === 'gaugeWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        const displayName = 'Gauge Chart';
        const widget = this.get('widget');
        workspace.pushState('gaugeWidget', {
          displayName,
          widget
        });
      }

    }
  });

  _exports.default = _default;
});