define("adept-iq/pods/components/form-components/playback-address-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8RaxRONc",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"table\"],[11,\"class\",\"mt-3 places-table\"],[9],[0,\"\\n  \"],[7,\"tr\"],[9],[0,\"\\n    \"],[7,\"td\"],[11,\"class\",\"td:first-child left\"],[9],[0,\"Address\"],[10],[0,\"\\n    \"],[7,\"td\"],[11,\"class\",\"edit-cell\"],[11,\"data-test-address-field\",\"\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"class\",\"dropdownClass\",\"placeholder\",\"options\",\"search\",\"selected\",\"onchange\"],[\"data-test-power-select\",\"address-drop-down\",\"Address\",[23,[\"searchResults\"]],[27,\"perform\",[[23,[\"searchTask\"]]],null],[27,\"readonly\",[[23,[\"selectedAddress\"]]],null],[27,\"action\",[[22,0,[]],\"onAddressSelected\"],null]]],{\"statements\":[[0,\"      \"],[1,[22,1,[\"freeformAddress\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"td\"],[11,\"class\",\"button-cell\"],[9],[0,\"\\n      \"],[7,\"button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"pickOnMap\",[23,[\"address\"]]],null]],[11,\"class\",\"btn btn-outline-secondary places-inline-button\"],[12,\"disabled\",[21,\"isFixedType\"]],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fa fa-map-marker\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/playback-address-search/template.hbs"
    }
  });

  _exports.default = _default;
});