define("adept-iq/tests/factories/sso-permission", ["ember-data-factory-guy", "adept-iq/config/mapped-permIds"], function (_emberDataFactoryGuy, _mappedPermIds) {
  "use strict";

  const Categories = ['Route', 'Trip', 'Workspace', 'Stop', 'Messages', 'Vehicle'];
  const operations = Object.keys(_mappedPermIds.default); // console.log(operationHash);

  _emberDataFactoryGuy.default.define('sso-permission', {
    sequences: {
      categories: num => Categories[num % Categories.length],
      description: num => `${operations[num % operations.length]} ${Categories[num % Categories.length]}`
    },
    default: {
      category: _emberDataFactoryGuy.default.generate('categories'),
      description: _emberDataFactoryGuy.default.generate('description')
    }
  });
});