define("adept-iq/pods/components/iq-widgets/drivers-widget/avlmConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/drivers-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'avlm-driver',
    defaultSortId: 'driverId',
    defaultSortAsc: false,
    title: 'Drivers',
    columns: [{
      id: 'driverId',
      index: 0,
      type: 'uuid',
      label: 'ID',
      valuePath: 'driverId',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 30
    }, {
      id: 'lastName',
      index: 1,
      type: 'text',
      label: 'Last Name',
      valuePath: 'lastName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'firstName',
      index: 2,
      type: 'text',
      label: 'First Name',
      valuePath: 'firstName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'availability',
      index: 4,
      type: 'enum-date',
      label: 'Availability',
      valuePath: 'formattedAvailabilities',
      cellDesc: 'Availabilities',
      editable: false,
      unAvailable: true,
      searchable: true,
      hidden: true
    }, {
      id: 'active',
      index: 5,
      type: 'boolean',
      label: 'Active',
      valuePath: 'active',
      editable: true,
      hidden: true,
      unAvailable: true,
      defaultWidth: 50
    }, {
      id: 'preferredVehicle',
      index: 6,
      type: 'text',
      label: 'Preferred Vehicle ID',
      valuePath: 'preferredVehicleName',
      editable: false,
      unAvailable: true,
      hidden: true
    }]
  };
  _exports.default = _default;
});