define("adept-iq/pods/components/iq-widgets/ember-react-playback/components/playback-panel/slider", ["exports", "react", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/api", "adept-iq/pods/components/iq-widgets/ember-react-playback/context/panel", "moment", "adept-iq/config/environment"], function (_exports, _react, _api, _panel, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Slider = () => {
    const {
      eventData
    } = (0, _api.useAPI)();
    const {
      sliderIndex,
      setToPlay,
      setSliderIndex,
      setSliderIndexMax
    } = (0, _panel.usePanel)();

    const [startTime, setStartTime] = _react.default.useState('Start Time');

    const [endTime, setEndTime] = _react.default.useState('End Time');

    const [percentage, setPercentage] = _react.default.useState(0);

    const [position, setPosition] = _react.default.useState(10);

    const start = eventData.length > 0 ? eventData[0] : null;
    const end = eventData.length > 0 ? eventData[eventData.length - 1] : null;
    const total = eventData.length;

    const onSliderValueChange = event => {
      setToPlay(event.target.value);
    };

    _react.default.useEffect(() => {
      if (start && end) {
        const startDisplay = (0, _moment.default)(start.converteddate).format(_environment.default.dateTimeFormat.timeMoment);
        const endDisplay = (0, _moment.default)(end.converteddate).format(_environment.default.dateTimeFormat.timeMoment);
        setStartTime(startDisplay);
        setEndTime(endDisplay);
        setSliderIndexMax(total - 1);
      } else {
        setStartTime('Start Time');
        setEndTime('End Time');
        setSliderIndex(0);
        setSliderIndexMax(0);
      }
    }, [eventData]);

    _react.default.useEffect(() => {
      const _percentage = Number(sliderIndex * 100 / total);

      const _position = 8 - 0.12 * _percentage;

      setPercentage(_percentage);
      setPosition(_position);
    }, [sliderIndex]);

    return _react.default.createElement("div", {
      className: "playback-widget-slider"
    }, _react.default.createElement("div", null, _react.default.createElement("label", null, startTime)), _react.default.createElement("div", {
      className: "range-wrap"
    }, total > 0 ? _react.default.createElement("div", {
      className: "range-value",
      id: "rangeV",
      style: {
        left: `calc(${percentage}% + (${position}px))`
      }
    }, eventData[sliderIndex]?.localTime) : '', _react.default.createElement("input", {
      id: "range",
      type: "range",
      min: "0",
      max: total - 1,
      step: "1",
      value: sliderIndex,
      onChange: value => onSliderValueChange(value)
    })), _react.default.createElement("div", null, _react.default.createElement("label", null, endTime)));
  };

  var _default = Slider;
  _exports.default = _default;
});