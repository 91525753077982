define("adept-iq/pods/components/notification-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0sAmx+Rr",
    "block": "{\"symbols\":[\"component\",\"flash\",\"close\"],\"statements\":[[4,\"if\",[[23,[\"flashMessage\"]]],null,{\"statements\":[[4,\"flash-message\",null,[[\"flash\"],[[23,[\"flashMessage\"]]]],{\"statements\":[[0,\"    \"],[1,[27,\"svg-jar\",[[27,\"concat\",[\"ic-\",[22,2,[\"type\"]]],null]],[[\"class\"],[[27,\"concat\",[\"alert-icon icon-\",[22,2,[\"type\"]]],null]]]],false],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"alert-text\"],[9],[1,[22,2,[\"message\"]],false],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"alert-close\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[1,[27,\"svg-jar\",[\"ic-close\"],[[\"class\"],[\"icon-block\"]]],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],[22,3,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1,2,3]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/notification-bar/template.hbs"
    }
  });

  _exports.default = _default;
});