define("adept-iq/pods/components/form-components/boolean/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nescf5Oh",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"power-select\",null,[[\"selected\",\"options\",\"disabled\",\"searchEnabled\",\"onchange\"],[[27,\"readonly\",[[23,[\"selected\"]]],null],[27,\"readonly\",[[23,[\"options\"]]],null],[27,\"readonly\",[[23,[\"disabled\"]]],null],false,[27,\"action\",[[22,0,[]],\"onChange\"],null]]],{\"statements\":[[0,\"\\n  \"],[1,[22,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/boolean/template.hbs"
    }
  });

  _exports.default = _default;
});