define("adept-iq/scenarios/sso-service", ["exports", "ember-data-factory-guy"], function (_exports, _emberDataFactoryGuy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Just for fun, set the log level ( to 1 ) and see all FactoryGuy response info in console
  _emberDataFactoryGuy.Scenario.settings({
    logLevel: 0 // 1 is the max for now, default is 0

  });

  class _default extends _emberDataFactoryGuy.Scenario {
    run() {
      (0, _emberDataFactoryGuy.makeList)('sso-permission', 104);
      (0, _emberDataFactoryGuy.make)('sso-role', 'admin');
      (0, _emberDataFactoryGuy.make)('sso-role', 'user');
    }

  }

  _exports.default = _default;
});