define("adept-iq/tests/factories/avlm-canned-message-template", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  const messages = ['Clarify Message', 'Return to base', 'Go to Light Speed'];

  _emberDataFactoryGuy.default.define('avlm-canned-message-template', {
    sequences: {
      messages: num => messages[num % messages.length]
    },
    default: {
      body: _emberDataFactoryGuy.default.generate('messages'),
      messageNr: '1',
      status: 'A',
      type: 'E'
    }
  });
});