define("adept-iq/models/driver-phone-number", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    areaCode: attr('string'),
    phoneNumber: attr('string'),
    extension: attr('string'),
    type: attr('string', {
      defaultValue: 'client'
    }),
    description: attr('string', {
      defaultValue: 'Client phone number'
    }),
    mobileCarrier: attr('string', {
      defaultValue: 'mobile carrier'
    }),
    useForSMSNotifications: attr('boolean', {
      defaultValue: false
    }),
    useForVoiceNotifications: attr('boolean', {
      defaultValue: false
    }),
    unformattedPhoneNumber: attr(),
    driver: belongsTo('driver'),
    formattedPhoneNumber: Ember.computed('areaCode', 'phoneNumber', 'extension', function () {
      const phone = this.get('phoneNumber');
      const extension = this.get('extension');
      const areaCode = this.get('areaCode');

      if (Ember.isEmpty(phone)) {
        return '';
      } else if (extension) {
        return `(${areaCode}) ${phone} - x${extension}`;
      }

      return areaCode ? `(${areaCode}) ${phone}` : phone;
    })
  });

  _exports.default = _default;
});