define("adept-iq/pods/components/iq-widgets/noshow-event-stop-form-widget/config", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        label: 'ID',
        valuePath: 'id',
        editable: false
      }, {
        id: 'tripId',
        label: 'Trip ID',
        valuePath: 'trip.tripId',
        editable: false
      }]
    }, {
      title: 'Pick Stop',
      fields: [{
        id: 'riderFirstName',
        label: 'Route ID',
        valuePath: 'trip.route.name',
        editable: false
      }, {
        id: 'riderLastName',
        label: 'Driver ID',
        valuePath: 'trip.driver.driverId',
        editable: false
      }, {
        id: 'tripId',
        label: 'Passenger',
        valuePath: 'trip.rider.fullName',
        editable: false
      }, {
        id: 'fullPhoneNumber',
        index: 3,
        type: 'enum',
        label: 'Phone Number',
        valuePath: 'trip.segment.leg.rider.riderPhoneNumber.formattedPhoneNumber',
        cellDesc: 'Phone Numbers',
        editable: false,
        hidden: true
      }, {
        id: 'pickupaddress',
        label: 'Pickup Location ',
        valuePath: 'trip.pickStopPoint.place.address.simpleAddress',
        editable: false
      }, {
        id: 'requestedTime',
        type: 'date',
        label: 'Promise Time',
        valuePath: 'trip.promiseTime',
        editable: false,
        hidden: false,
        format: `${_environment.default.dateTimeFormat.dateTimeMoment}`,
        defaultWidth: 50
      }, {
        id: 'actualarrivetime',
        type: 'datetimeflatpickr',
        label: 'Actual Arrive Time',
        valuePath: 'trip.actualArriveTime',
        editable: false,
        hidden: false,
        format: `${_environment.default.dateTimeFormat.dateTimeMoment}`,
        defaultWidth: 50
      }]
    }, {
      title: 'No Show',
      fields: [{
        id: 'timestamp',
        type: 'datetimeflatpickr',
        label: 'Date(Time)',
        valuePath: 'noShow.timestamp',
        editable: true,
        hidden: false,
        format: `${_environment.default.dateTimeFormat.dateTimeFlatPickr}`,
        defaultWidth: 50,
        extra: {
          minTimePath: 'noShowAvailableTime',
          maxTimePath: 'noShow.timestamp'
        }
      }, {
        id: 'noShowReason',
        type: 'enum',
        label: 'No-show Reason',
        valuePath: 'noShow.noShowReasonCode',
        cellDesc: 'noShowReason',
        editable: true,
        hidden: false,
        extra: {
          optionModelName: 'no-show-reason-category',
          optionSearchPath: 'name',
          optionDisplayName: 'displayName',
          optionReadonlyCellPath: 'noShowDropDownReadonly'
        }
      }, {
        id: 'notes',
        type: 'text',
        value: 'Write a note here',
        editable: true,
        label: 'Note',
        valuePath: 'noShow.notes'
      }]
    }]
  };
  _exports.default = _default;
});