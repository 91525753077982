define("adept-iq/models/vehicle-type", ["exports", "ember-data", "lodash", "adept-iq/utils/format-text-extension"], function (_exports, _emberData, _lodash, _formatTextExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // third party libraries
  const {
    Model,
    belongsTo,
    hasMany,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    description: attr('string'),
    displayName: attr('string'),
    inServiceDate: attr('iso-date', {
      defaultValue: null
    }),
    endServiceDate: attr('iso-date', {
      defaultValue: null
    }),
    vehicleBodyLength: attr('number'),
    xWideLiftAvailable: attr('boolean', {
      defaultValue: null
    }),
    notes: attr('string'),
    vehicleBodyType: belongsTo('vehicle-body-type'),
    vehicleBodyWidth: belongsTo('vehicle-body-width'),
    vehicleChassis: belongsTo('vehicle-chassis'),
    vehicleFuelCap: belongsTo('vehicle-fuel-cap'),
    vehicleFuelType: belongsTo('vehicle-fuel-type'),
    vehicleModel: belongsTo('vehicle-model-name'),
    vehicleWheelBaseType: belongsTo('vehicle-wheelbase-type'),
    vehicleTireSize: belongsTo('vehicle-tire-size'),
    vehicleTransmission: belongsTo('vehicle-transmission'),
    rearAxel: belongsTo('rear-axel-name'),
    vehicleCapacityConfigs: hasMany('vehicle-capacity-config'),
    vehicles: hasMany('vehicle'),
    // TODO: Ankur -  Hardcoded will be remove to some configuration
    noOfAmbulatorySeats: Ember.computed.readOnly('capacityCounts.ambulatory'),
    noOfWideAmbulatorySeats: Ember.computed.readOnly('capacityCounts.wideAmbulatory'),
    noOfWheelChairSeats: Ember.computed.readOnly('capacityCounts.wheelchair'),
    noOfWideWheelChairSeats: Ember.computed.readOnly('capacityCounts.wideWheelchair'),
    noOfServiceAnimalSeats: Ember.computed.readOnly('capacityCounts.serviceAnimal'),
    noOfFlexSeats: Ember.computed.readOnly('capacityCounts.flexSeats'),
    extraWideLift: Ember.computed.gt('capacityCounts.extraWideLift', 0),
    noOfAmbulatoryFlexSeats: Ember.computed('capacityCounts.{flexSeats,ambulatory}', function () {
      const flexSeats = this.get('capacityCounts.flexSeats');

      if (flexSeats.length > 1) {
        return flexSeats.map(flexSeat => flexSeat.ambulatory).join(' / ');
      }

      return this.get('capacityCounts.ambulatory');
    }),
    noOfWheelChairFlexSeats: Ember.computed('capacityCounts.{flexSeats,wheelchair}', function () {
      const flexSeats = this.get('capacityCounts.flexSeats');

      if (flexSeats.length > 1) {
        return flexSeats.map(flexSeat => flexSeat.wheelchair).join(' / ');
      }

      return this.get('capacityCounts.wheelchair');
    }),
    capacityCounts: Ember.computed('vehicleCapacityConfigs.@each.{vehicleCapacityTypeName,count}', function () {
      const initialCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0,
        extraWideLift: 0,
        flexSeats: []
      };
      const vehicleCapacityConfigs = this.get('vehicleCapacityConfigs') || [];
      let capacityCount = initialCounts;
      const flexSeats = {};
      const nonConsumables = {};
      capacityCount = vehicleCapacityConfigs.reduce((obj, vehicleCapacityConfig) => {
        const vehicleCapacityTypeName = vehicleCapacityConfig.get('vehicleCapacityTypeName');
        const count = vehicleCapacityConfig.get('count');
        const groupNumber = vehicleCapacityConfig.get('group');
        obj[vehicleCapacityTypeName] = count; // collect the group capacity config

        let group = flexSeats[groupNumber];

        if (Ember.isNone(group)) {
          group = {
            group: groupNumber,
            wheelchair: 0,
            wideWheelchair: 0,
            ambulatory: 0,
            wideAmbulatory: 0,
            serviceAnimal: 0,
            extraWideLift: 0
          };
        }

        group[vehicleCapacityTypeName] = count;
        flexSeats[groupNumber] = group; // save non-consumable capacities

        if (groupNumber === '0' && count === 0) {
          nonConsumables[vehicleCapacityTypeName] = count;
        }

        return obj;
      }, initialCounts); // filter out none ambulatory or wheelchair types

      const filteredFlexSeats = (0, _lodash.pickBy)(flexSeats, value => {
        return value.ambulatory > 0 || value.wheelchair > 0;
      });
      capacityCount.flexSeats = Object.values(filteredFlexSeats);
      capacityCount.nonConsumables = nonConsumables;
      return capacityCount;
    }),
    capacityConfigGroup: Ember.computed('vehicleCapacityConfigs.@each.{group,count,vehicleCapacityType,isSavedNewRecord}', function () {
      let capacityConfigs = this.get('vehicleCapacityConfigs') ? this.get('vehicleCapacityConfigs').toArray() : []; //capacityConfigs.forEach(c => console.log('test', c.isSavedNewRecord, c.isNew));

      capacityConfigs = capacityConfigs.filter(c => !c.isNew || c.isSavedNewRecord);
      const groupSet = [];
      const groups = (0, _lodash.groupBy)(capacityConfigs, capacity => {
        return capacity.get('group');
      });
      Object.entries(groups).forEach(_ref => {
        let [group] = _ref;
        groupSet.push((0, _formatTextExtension.formatTextExtension)('valueItem', group));
      });
      return {
        header: null,
        values: groupSet
      };
    }),
    capacityConfigGroupSortKey: Ember.computed('capacityConfigGroup', function () {
      const groupsetValues = this.get('capacityConfigGroup');
      return groupsetValues.values.length > 1 ? `${groupsetValues.values.length} Groups` : `${groupsetValues.values.length}`;
    })
  });

  _exports.default = _default;
});