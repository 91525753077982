define("adept-iq/pods/index/modals/create-dashboard/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    classNames: ['create-dashboard-wrapper'],
    actions: {
      toggleModal() {
        this.transitionToRoute('index');
      }

    }
  });

  _exports.default = _default;
});