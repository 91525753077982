define("adept-iq/pods/components/iq-widgets/subscription-form/leg-form/component", ["exports", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/config/mapped-permIds"], function (_exports, _moment, _unwrapProxy, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DISPLAY_NAME = 'Display Name';
  const PHONE_NUMBER_PLACEHOLDER = 'Phone Number';
  const ORIGIN_NUMBER_DESCRIPTION = 'ORIGIN NUMBER';
  const DESTINATION_NUMBER_DESCRIPTION = 'DESTINATION NUMBER';
  const ANCHOR = 'Appt';

  var _default = Ember.Component.extend({
    classNames: ['leg-form'],
    anchorTypeOptions: null,
    serviceTypeOptions: null,
    rideshareTypeOptions: null,
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    booking: Ember.inject.service(),
    editModal: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    subscriptionService: Ember.inject.service('subscription'),
    permissionLayer: Ember.inject.service(),
    maxDate: null,
    minDate: null,
    isFirstLeg: false,
    markerType: null,
    disablePick: false,
    defaultFare: 0,
    editRideshareSubscriptionGranted: false,
    allowFundingAgency: false,
    originNumberDescription: ORIGIN_NUMBER_DESCRIPTION,
    destinationNumberDescription: DESTINATION_NUMBER_DESCRIPTION,
    phoneNumberPlaceHolder: PHONE_NUMBER_PLACEHOLDER,
    disableFareField: false,
    fareCategoryOptions: null,
    isNotFirstLeg: Ember.computed('isFirstLeg', function () {
      return !this.get('isFirstLeg');
    }),
    canShowRemoveButton: Ember.computed('isFirstLeg', 'leg.purpose', 'isReturnTrip', function () {
      return this.get('isReturnTrip');
    }),
    // Reg - NYAAR-18733
    legFareCategory: Ember.computed('booking.selectedRiders.firstObject.fareCategory', function () {
      const configFareCategories = this.get('booking').getSystemConfigFareCategories();
      const fareCategoryDisplayName = this.get('booking.selectedRiders.firstObject.fareCategory');
      const fareCategory = configFareCategories.find(configFareCategory => configFareCategory.get('displayName') === fareCategoryDisplayName);
      return fareCategory.get('value')[DISPLAY_NAME];
    }),
    originPhoneNumber: Ember.computed('subscription.phoneNumbers.[]', 'subscription.phoneNumbers.@each.{areaCode,phoneNumber,fullPhoneNumber}', function () {
      const phoneNumbers = this.get('subscription.phoneNumbers').toArray();
      const originNumber = this.filterPhoneNumbersBasedDescription(phoneNumbers, ORIGIN_NUMBER_DESCRIPTION);

      if (originNumber.length) {
        return originNumber.get('firstObject.fullPhoneNumber');
      }

      return '';
    }),
    destinationPhoneNumber: Ember.computed('subscription.phoneNumbers.[]', 'subscription.phoneNumbers.@each.{areaCode,phoneNumber,fullPhoneNumber}', function () {
      const phoneNumbers = this.get('subscription.phoneNumbers').toArray();
      const destinationNumber = this.filterPhoneNumbersBasedDescription(phoneNumbers, DESTINATION_NUMBER_DESCRIPTION);

      if (destinationNumber.length) {
        return destinationNumber.get('firstObject.fullPhoneNumber');
      }

      return '';
    }),
    fundingAgencyOptions: Ember.computed('leg.requestTime', async function () {
      const fundingAgencyOptions = [];
      const riderId = this.get('riderRecord.id');
      const requestLegTime = (0, _moment.default)(this.get('leg.requestTime'));
      const localRiderRecord = await this.store.findRecord('rider', riderId);
      const riderFundings = localRiderRecord.get('riderFundings');
      riderFundings.forEach(riderFunding => {
        const startDate = (0, _moment.default)(riderFunding.get('startDate')).startOf('day');
        const endDate = (0, _moment.default)(riderFunding.get('endDate')).endOf('day');

        if (requestLegTime.isBetween(startDate, endDate)) {
          fundingAgencyOptions.push(riderFunding.fundingAgency);
        }
      });
      return fundingAgencyOptions;
    }),

    // we should only show upto day  after tomorrow for booking
    setMaxDate() {
      const today = new Date();
      const maxDate = new Date(today);
      maxDate.setDate(maxDate.getDate() + 2);
      maxDate.setHours(23, 59);
      this.set('maxDate', maxDate); //regarding this ticket NYAAR-14603

      const minDate = this.get('isEditMode') ? this.get('leg.requestTime') : new Date().setHours(0, 0, 0, 0);
      this.set('minDate', minDate);
    },

    anchorObserver: Ember.observer('leg.anchor', 'legIndex', function () {
      if (this.get('leg.anchor') === ANCHOR || this.get('legIndex') === 0) {
        this.set('isFirstLeg', true);
      } else {
        this.set('isFirstLeg', false);
      }
    }),

    init() {
      this.set('disableFareField', !this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editFareSubscription, null));

      this._super(...arguments);

      const serviceTypeOptions = this.booking.getServiceTypeOptions();
      const fareCategoryOptions = this.booking.getFareCategoryOptions();
      const tripPurposeOptions = this.booking.getTripPurposeOptions();
      this.setProperties({
        anchorTypeOptions: [{
          id: 'pick',
          name: 'Pick'
        }, {
          id: 'drop',
          name: 'Appt'
        }],
        serviceTypeOptions,
        rideshareTypeOptions: [{
          id: 0,
          name: 'Allowed'
        }, {
          id: 2,
          name: 'Not Allowed'
        }],
        fareCategoryOptions,
        tripPurposeOptions
      });
      this.setMaxDate();
      this.isFirstLegValidation();
      this.defaultFare = this.get('leg.segments.firstObject.fare');
      const subscriptionNoSharing = this.get('subscription.noSharing') ? this.get('subscription.noSharing') : 0;
      this.set('allowFundingAgency', this.get('booking').getAllowFundingAgency());
      this.set('subscription.noSharing', subscriptionNoSharing);
      this.set('subscriptionService.noSharing', subscriptionNoSharing);
      this.set('editRideshareSubscriptionGranted', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editRideshareSubscription, null));
      this.updateLocationForAddressSelect = this.updateLocationForAddressSelect.bind(this);
    },

    selectedTime: Ember.computed('leg.requestTime', function () {
      return this.get('leg.requestTime');
    }),

    didInsertElement() {
      this._super(...arguments);
    },

    isFirstLegValidation() {
      if (this.get('legIndex') === 0 && this.get('leg.purpose') !== 'return') {
        this.set('isFirstLeg', true);
        this.set('disablePick', true);
      } else {
        this.set('isFirstLeg', false);
        this.set('disablePick', false);
      }
    },

    validateAddress(addressSearchResult, addressType) {
      const streetAddress = addressSearchResult.get('streetAddress');
      const postalCode = addressSearchResult.get('postalCode');
      const lat = addressSearchResult.get('lat');
      const lng = addressSearchResult.get('lng');

      if (lat === 0 || lng === 0) {
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressType} address.`);
        return false;
      }

      if (Ember.isNone(streetAddress) || Ember.isNone(postalCode)) {
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressType} address.`);
        return false;
      }

      if (Ember.isEmpty(streetAddress) || Ember.isEmpty(postalCode)) {
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressType} address.`);
        return false;
      }

      return true;
    },

    async updateLocationForAddressSelect(addressRecord, close, selectType) {
      const addressType = selectType === 'pick' ? `${selectType}up` : `${selectType}off`;
      const segment = selectType === 'pick' ? this.get('leg.segments.firstObject') : this.get('leg.segments.lastObject');
      const segmentStop = segment.get(`${selectType}`);
      const place = segment.get(`${selectType}.place`);
      let address = addressRecord;
      this.set('booking.canSave', true);

      if (!address) {
        address = segment.get(`${selectType}.place.address`);
        place.set('address', addressRecord);
        place.set('location', null);
        this.booking.setActiveAddress(address);
        return;
      }

      if (!this.validateAddress(address, addressType)) {
        return;
      }

      const lat = address.get('lat');
      const lng = address.get('lng');
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const beNomadAddress = address.get('beNomadAddress');
      const geoNode = await this.booking.getAddressGeoNode(lat, lng, beNomadAddress, addressType);

      if (!geoNode || Ember.isEmpty(geoNode.zone.zoneName) || geoNode && geoNode.zone.zoneName <= '0') {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.booking.showErrorMessage('Location must be within the agency service boundary.');
        return;
      }

      const zoneName = this.get('store').peekRecord('zone-type', geoNode.zone.zoneName);
      address.set('zName', zoneName.get('displayName'));
      address.set('zoneName', zoneName);

      if (!address.get('zoneName.name')) {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.booking.showErrorMessage('Location must be within the agency service boundary.');
        return;
      }

      const locationRecord = this.store.createRecord('location', {
        lat,
        lng
      });
      locationRecord.set('geoNode', geoNode.geonode);
      place.set('location', locationRecord);
      place.set('address', address);
      segmentStop.set('notes', address.notes || '');
      this.booking.setActiveAddress(address);
      if (close) close();
      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    filterPhoneNumbersBasedDescription(subscriptionPhoneNumbers, numberDescription) {
      const filteredPhoneNumbers = subscriptionPhoneNumbers.filter(number => {
        return number.get('description') === numberDescription;
      });
      return filteredPhoneNumbers;
    },

    actions: {
      onDateChange(value) {
        const rTime = this.get('leg.requestTime');
        const currentDateAndTime = (0, _moment.default)(rTime);
        const selectedDate = (0, _moment.default)(value[0]);
        selectedDate.hour(currentDateAndTime.hour());
        selectedDate.minutes(currentDateAndTime.minutes());
        selectedDate.seconds(currentDateAndTime.seconds());
        const selectedDateObjectWithTime = selectedDate.toDate();
        this.set('leg.requestTime', selectedDateObjectWithTime);
        const legIndexWithinTotalLegs = this.get('booking.activeBooking.legs').indexOf(this.get('leg'));
        this.get('validateRequestDateTime')(this.get('leg'), this.get('isReturnTrip'));

        if (legIndexWithinTotalLegs === 0) {
          this.get('onFirstLegDateChange')();
        }
      },

      onTimeChange(value) {
        const rTime = this.get('leg.requestTime');
        const currentDateAndTime = (0, _moment.default)(rTime);
        const selectedDate = (0, _moment.default)(value);
        const currentYear = currentDateAndTime.year();
        const currentMonth = currentDateAndTime.month();
        const currentDay = currentDateAndTime.date();
        selectedDate.year(currentYear);
        selectedDate.month(currentMonth);
        selectedDate.date(currentDay);
        const selectedDateObjectWithTime = selectedDate.toDate();
        this.set('leg.requestTime', selectedDateObjectWithTime);
        this.set('subscription.requestTime', selectedDateObjectWithTime);
        this.get('validateRequestDateTime')(this.get('leg'), this.get('isReturnTrip'));
      },

      onChangeServiceType(selectedType) {
        this.set('leg.serviceWindow', selectedType);
      },

      onPickAddressSelect(address, close) {
        this.updateLocationForAddressSelect(address, close, 'pick');
      },

      onDropAddressSelect(address, close) {
        this.updateLocationForAddressSelect(address, close, 'drop');
      },

      onPickOnMap(place, selectType) {
        const activeDragPlace = this.booking.get('activeDragPlace');
        this.set('booking.skipSetView', true);

        if (!(0, _unwrapProxy.unwrapProxy)(place.get('address'))) {
          this.get('booking').setEmptyAddressLocation(place);
        }

        if (!activeDragPlace) {
          this.booking.set('activeDragPlace', place);
        }

        this.booking.set('addressSelectType', selectType);
      },

      toggleCalendar() {
        this.calflatpickrRef.toggle();
      },

      toggleTime() {
        this.timeflatpickrRef.toggle();
      },

      onLegRemoveClick(leg) {
        this.get('onLegRemoveClick')(leg);
      },

      onRideshareChange(rideshareType) {
        this.set('subscription.noSharing', rideshareType.id);
        this.set('subscriptionService.noSharing', rideshareType.id);
      },

      onNumberChange(subscriptionPhoneNumbers, numberDescription, maskObj) {
        const areaCode = maskObj.unmasked.substring(0, 3).trim();
        const phoneNumber = maskObj.unmasked.substring(3).trim();
        const filteredPhoneNumbers = this.filterPhoneNumbersBasedDescription(subscriptionPhoneNumbers, numberDescription); // for new subscription or if the subscription does'nt have a phone number

        if (!filteredPhoneNumbers.length) {
          const phoneNumberObj = {
            type: 'HOME',
            areaCode: areaCode,
            phoneNumber: phoneNumber,
            extension: '',
            description: numberDescription,
            mobileCarrier: 'yes',
            useForSmsNotifications: false,
            useForVoiceNotifications: true
          };
          const phoneNumberRecord = this.get('store').createRecord('subscription-phone-number', phoneNumberObj);
          subscriptionPhoneNumbers.pushObject(phoneNumberRecord);
          return;
        }

        filteredPhoneNumbers.set('firstObject.areaCode', areaCode);
        filteredPhoneNumbers.set('firstObject.phoneNumber', phoneNumber);
      },

      onFareChange(segment, maskObj) {
        segment.set('fare', maskObj.unmasked);
      },

      onFareCategoryChange(subscription, fareCategory) {
        subscription.set('fareCategory', fareCategory);
        this.get('updateFare')();
      }

    }
  });

  _exports.default = _default;
});