define("adept-iq/pods/change-password-confirmation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jczq5e7t",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"change-password-confirmation\"],[9],[0,\"\\n\\n  \"],[7,\"h1\"],[9],[0,\"\\n    Password Changed\\n  \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"\\n        Your password has been successfully changed. Please use the new password to log in.\\n    \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn\"],[9],[0,\"OK\"],[3,\"action\",[[22,0,[]],\"redirectToLogin\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/change-password-confirmation/template.hbs"
    }
  });

  _exports.default = _default;
});