define("adept-iq/models/avlm-vehicle", ["exports", "ember-data", "adept-iq/mixins/otp-label", "adept-iq/utils/workspace-availability", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _otpLabel, _workspaceAvailability, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  const RS_ID_PREFIX = 'SUP';

  var _default = Model.extend(_otpLabel.default, {
    workspace: Ember.inject.service(),
    callSign: attr('string'),
    providerName: attr('string'),
    routeName: attr('string'),
    otp: attr('string'),
    vehicleStatus: attr('string'),
    replyToMessageId: attr('string'),
    currentDriver: belongsTo('avlm-driver'),
    isEmergency: Ember.computed.equal('vehicleStatus', 'Emergency Received'),
    isApprovedEmergency: Ember.computed.equal('vehicleStatus', 'Emergency Acknowledged'),
    isApprovedEmergencyPending: Ember.computed.equal('vehicleStatus', 'Pending'),
    provider: belongsTo('provider'),
    avlmRoute: belongsTo('avlm-route'),
    etaRoute: belongsTo('etanav-route'),
    vehicleInfo: belongsTo('avlm-vehicle-info'),
    cannedMessages: hasMany('avlm-canned-message'),
    emergencyMessages: hasMany('avlm-emergency-message'),
    avlmRouteVehicleDrivers: hasMany('avlm-route-vehicle-driver'),
    avlmEvents: hasMany('avlm-event'),
    avlmDriverApp: belongsTo(),
    // only store most current ping
    avlLocation: (0, _attributes.fragment)('avl-location'),
    lat: Ember.computed.readOnly('avlLocation.lat'),
    lng: Ember.computed.readOnly('avlLocation.lng'),
    heading: Ember.computed.readOnly('avlLocation.heading'),
    speed: Ember.computed.readOnly('avlLocation.speed'),
    odo: Ember.computed.readOnly('avlLocation.odo'),
    timestamp: Ember.computed.readOnly('avlLocation.timestamp'),
    workspaceStartTime: Ember.computed.alias('workspace.startDate'),
    workspaceEndTime: Ember.computed.alias('workspace.endDate'),
    name: Ember.computed.alias('callSign'),
    latestDriver: Ember.computed('latestRouteVehicleDriver.driver', 'currentDriver', function () {
      if (this.get('latestRouteVehicleDriver.driver')) return this.get('latestRouteVehicleDriver.driver');
      return this.get('currentDriver');
    }),
    latestRoute: Ember.computed.alias('latestRouteVehicleDriver.route'),
    latestRouteStatus: Ember.computed.alias('latestRouteVehicleDriver.route.status'),
    startAddress: Ember.computed.alias('latestRoute.startAddress'),
    endAddress: Ember.computed.alias('latestRoute.endAddress'),
    tripStartTime: Ember.computed.alias('latestRoute.tripStartTime'),
    tripEndTime: Ember.computed.alias('latestRoute.tripEndTime'),
    isOnBreak: attr('boolean'),
    driverId: attr('string', {
      readOnly: true
    }),
    driverBadgeNumber: attr('string', {
      readOnly: true
    }),
    driverFullName: attr('string', {
      readOnly: true
    }),
    isBroken: attr('boolean'),
    isCommFailure: attr('boolean'),
    isLeaveGarageSignOff: attr('boolean'),
    isGpsFailure: attr('boolean'),
    isRoadSupervisor: Ember.computed.equal('trackingIdPrefix', RS_ID_PREFIX),
    avlmAlert: hasMany('avlm-alert'),
    //IQUX-3143 on acknowledging emergency, isEmergencyVehicle is set to false, due to which two vehicles are displayed on map on selecting the route.
    isEmergencyVehicle: Ember.computed('isEmergency', 'isApprovedEmergency', function () {
      if (this.get('isEmergency') || this.get('isApprovedEmergency')) {
        return true;
      }

      return false;
    }),
    mapId: Ember.computed('isEmergencyVehicle', 'isApprovedEmergency', 'latestDriver', 'isOnBreak', 'isBroken', 'isCommFailure', 'isLeaveGarageSignOff', 'isGpsFailure', 'isRoadSupervisor', function () {
      // If the vehicle is on a break, and the vehicle breaks down at the same time, then the vehicle symbol that shall be displayed on the map is 'Vehicle broken down.'
      // If the vehicle is on a break, and the vehicle runs into an emergency at the same time, then the vehicle symbol that shall be displayed on the map is 'Vehicle Emergency'
      // If the vehicle is on a break and the route is late at the same time, then the vehicle symbol that shall be displayed on the map is 'Vehicle route started. OTP late'.
      // If the vehicle is on a break and route is in danger of being late, then the vehicle symbol that shall be displayed on the map is 'Vehicle route started. OTP getting late'.
      let id = 'avlm-vehicle';
      const otp = this.get('otp');
      const isOnBreak = this.get('isOnBreak');
      const isBroken = this.get('isBroken');
      const isGpsFailure = this.get('isGpsFailure');
      const isCommFailure = this.get('isCommFailure');
      const isLeaveGarageSignOff = this.get('isLeaveGarageSignOff');
      const isRoadSupervisor = this.get('isRoadSupervisor');
      const isEmergencyVehicle = this.get('isEmergencyVehicle');
      const isApprovedEmergency = this.get('isApprovedEmergency');

      if (isBroken) {
        id += '-breakdown';
        return id;
      }

      if (isEmergencyVehicle || isApprovedEmergency) {
        id += '-emergency';
        return id;
      }

      if (isCommFailure) {
        id += '-communication-failure';
        return id;
      } // These events priority are lower than emergency;


      switch (otp) {
        case 'D':
        case 'In Danger':
          id += '-danger';
          return id;

        case 'L':
        case 'Late':
          id += '-late';
          return id;

        default:
          break;
      }

      if (isOnBreak) {
        id += '-break';
        return id;
      }

      if (isGpsFailure) {
        id += '-gps-failure';
        return id;
      }

      if (isLeaveGarageSignOff) {
        id += '-not-signedin';
        return id;
      }

      if (isRoadSupervisor) {
        id += '-roadsup';
        return id;
      }

      return id;
    }),
    latestRouteVehicleDriver: Ember.computed('currentRouteVehicleDrivers.@each.startTime', function () {
      const routeVehicleDrivers = this.get('currentRouteVehicleDrivers');
      if (!routeVehicleDrivers) return [];
      return routeVehicleDrivers.sortBy('startTime').get('lastObject');
    }),
    currentRouteVehicleDrivers: Ember.computed('avlmRouteVehicleDrivers.[]', 'workspaceStartTime', 'workspaceEndTime', function () {
      let routeVehicleDrivers = this.get('avlmRouteVehicleDrivers');
      const startTime = this.get('workspaceStartTime');
      const endTime = this.get('workspaceEndTime');
      if (!routeVehicleDrivers) return [];
      routeVehicleDrivers = routeVehicleDrivers.filter(routeVehicleDriver => {
        if (routeVehicleDriver.get('route.status') === 'C' || routeVehicleDriver.get('route.status') === 'Completed') {
          return (0, _workspaceAvailability.processTimeConstraints)(routeVehicleDriver.get('route.tripStartTime'), routeVehicleDriver.get('route.tripEndTime'), startTime, endTime);
        }

        return true;
      });
      return routeVehicleDrivers;
    }),
    currentVehicleAlert: Ember.computed('avlmAlert.[]', 'workspaceStartTime', 'workspaceEndTime', function () {
      let vehicleAlerts = this.get('avlmAlert');
      const startTime = this.get('workspaceStartTime');
      const endTime = this.get('workspaceEndTime');
      if (!vehicleAlerts) return [];
      vehicleAlerts = vehicleAlerts.filter(vehicleAlert => {
        if ((0, _workspaceAvailability.processTimeConstraints)(vehicleAlert.get('timestamp'), vehicleAlert.get('timestamp'), startTime, endTime)) return vehicleAlert;
      });
      return vehicleAlerts;
    }),
    sortedAvlmEvents: Ember.computed('avlmEvents.[]', function () {
      return this.get('avlmEvents').sortBy('receivedAt');
    }),
    latestAvlmEvent: Ember.computed('sortedAvlmEvents', function () {
      return this.get('sortedAvlmEvents').lastObject;
    }),
    trackingIdPrefix: Ember.computed('latestRoute.trackingId', function () {
      const trackingId = this.get('latestRoute.trackingId');
      if (Ember.isNone(trackingId)) return null;
      return trackingId.split('-')[0];
    }),
    active: Ember.computed('latestDriver', function () {
      return this.get('latestDriver') ? 'Active' : 'Inactive';
    }),
    sortedEmergencyMessages: Ember.computed('emergencyMessages.[]', function () {
      return this.get('emergencyMessages').sortBy('createdTime');
    }),
    // contains an emergency message with status unresolved or acknowledged
    lastEmergencyMessage: Ember.computed('sortedEmergencyMessages.[]', function () {
      const emergencyMessage = this.get('sortedEmergencyMessages').lastObject;
      return emergencyMessage;
    }),
    latestReceivedEmergencyMessage: Ember.computed('sortedEmergencyMessages.[]', function () {
      const emergencyMessages = this.get('sortedEmergencyMessages').toArray();

      for (let i = emergencyMessages.length - 1; i >= 0; i--) {
        if (emergencyMessages[i].get('isEmergency') || emergencyMessages[i].get('isAcknowledged')) {
          return emergencyMessages[i];
        }
      }

      return null;
    }),
    point: Ember.computed.collect('lat', 'lng')
  });

  _exports.default = _default;
});