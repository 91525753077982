define("adept-iq/classes/rules/eligibility-rules/no-eligibility-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type"], function (_exports, _baseRule, _ruleResultType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NoEligibilityRule = void 0;

  class NoEligibilityRule extends _baseRule.default {
    doEvaluate(ruleContext) {
      const {
        bookingService
      } = ruleContext;
      const selectedRiders = bookingService.get('selectedRiders');
      const containsNoEligibility = selectedRiders.every(this.checkNoEligibility);
      if (containsNoEligibility) return _ruleResultType.RESULT_TYPE_YES;
      return _ruleResultType.RESULT_TYPE_NO;
    }

    checkNoEligibility(riderExternal) {
      const eligibilityCategories = riderExternal.get('eligibility.categories');
      return !eligibilityCategories || eligibilityCategories.length === 0;
    }

  }

  _exports.NoEligibilityRule = NoEligibilityRule;
  NoEligibilityRule.RULE_NAME = 'NoEligibilityRule';
});